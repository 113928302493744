import * as React from 'react'
import { Alert } from 'reactstrap'
import { IconWithText } from '@components/icon-with-text'
import { asDecimal, formatPrice, sumDecimalArray } from '@helpers/utils'
import { ReservationBookingPayment } from '@models/reception'

interface Props {
  returnedDepositPayments: ReservationBookingPayment[]
}

export const ReservationDepositReturnedLabel = ({ returnedDepositPayments }: Props): JSX.Element => {
  const totalReturnedAmount = sumDecimalArray(
    returnedDepositPayments.map(payment => asDecimal(payment.amount)),
  ).toString()

  return (
    <Alert className="py-1 px-2 alert-success-lighten d-inline-block">
      <IconWithText
        icon="uil-clock-five font-16"
        text={`Środki w wysokości ${formatPrice(totalReturnedAmount)} zwrócone`}
        textClass="font-11 fw-semi-bold"
      />
    </Alert>
  )
}
