import * as React from 'react'
import { EmailTemplate } from '@modules/cms/models'
import Table from '@components/table/table'
import { YesNoBadge } from '@components/badges/yes-no'
import { useModal } from '@components/modals/use-modal'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'

interface Props {
  emailTemplate: EmailTemplate
}

export const NotificationTemplatesEmailListRow: React.FC<Props> = ({ emailTemplate }) => {
  const user = useAuthenticatedUser()

  const [handleEdit] = useModal('NotificationTemplatesEmailModal', {
    emailTemplate,
  })

  return (
    <Table.Row>
      <Table.Cell>{emailTemplate.name}</Table.Cell>
      <Table.Cell>{emailTemplate.subject_pl || 'brak'}</Table.Cell>
      <Table.Cell>
        <code>{emailTemplate.key}</code>
      </Table.Cell>
      <Table.Cell>
        <YesNoBadge value={emailTemplate.is_active} />
      </Table.Cell>
      <Table.Cell className="text-right">
        {user.hasPerm(UserPermission.NotificationCanManage) && (
          <button className="action-icon btn-pure" title="Edytuj" onClick={handleEdit}>
            <i className="uil-edit text-muted font-18" />
          </button>
        )}
      </Table.Cell>
    </Table.Row>
  )
}
