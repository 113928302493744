import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@store/index'
import { Asset, AssetCategory } from '@models/cms-asset'
import { getAssetCategories, getAssets, removeAsset, removeAssetCategory } from '@store/actions/asset-actions'

export interface AssetState {
  assets: EntityState<Asset>
  assetCategories: EntityState<AssetCategory>
}

const assetCategoriesAdapter = createEntityAdapter<AssetCategory>()
const assetsAdapter = createEntityAdapter<Asset>()

const initialState: AssetState = {
  assets: assetsAdapter.getInitialState(),
  assetCategories: assetCategoriesAdapter.getInitialState(),
}

export const assetSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    updateAssetDetails(state, action: PayloadAction<Asset>) {
      assetsAdapter.upsertOne(state.assets, action.payload)
    },
    updateAssetCategoryDetails(state, action: PayloadAction<AssetCategory>) {
      assetCategoriesAdapter.upsertOne(state.assetCategories, action.payload)
    },
  },
  extraReducers: builder => {
    builder.addCase(getAssetCategories.fulfilled, (state, action) => {
      assetCategoriesAdapter.setAll(state.assetCategories, action.payload)
    })
    builder.addCase(getAssets.fulfilled, (state, action) => {
      assetCategoriesAdapter.setAll(state.assets, action.payload)
    })
    builder.addCase(removeAssetCategory.fulfilled, (state, action) => {
      assetCategoriesAdapter.removeOne(state.assetCategories, action.payload.id)
    })
    builder.addCase(removeAsset.fulfilled, (state, action) => {
      assetsAdapter.removeOne(state.assets, action.payload.id)
    })
  },
})

export const { updateAssetDetails, updateAssetCategoryDetails } = assetSlice.actions

export const { selectAll: assetCategoriesSelector } = assetCategoriesAdapter.getSelectors(
  (state: RootState) => state.assetState.assetCategories,
)
export const { selectAll: assetsSelector } = assetsAdapter.getSelectors((state: RootState) => state.assetState.assets)
export default assetSlice.reducer
