import * as React from 'react'
import { Button } from 'reactstrap'

interface ClientAddProps {
  handleToggleClientDialog: () => void
}

export const ClientAdd: React.FC<ClientAddProps> = ({ handleToggleClientDialog }) => (
  <Button color="dark" size="sm" type="button" onClick={handleToggleClientDialog}>
    {' '}
    Nowy klient{' '}
  </Button>
)
