import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAppDispatch } from '@store/index'
import { useFormRequest } from '@components/hooks/use-api-request'
import { PackageWholesaleProductDetails, PackageWholesaleProductKind } from '@modules/package-wholesale/models'
import { packageWholesaleProductCreate, packageWholesaleProductUpdate } from '@api/package-wholesale'
import { PackageWholesaleProductCreateForm } from '@modules/package-wholesale/products/create/form'
import { BaseModalProps } from '@components/modals/types'
import { updatePackageWholesaleProductDetails } from '@store/slices/package-wholesale-slice'
import { CustomReactSelectOption, makeDefaultSelectOption } from '@components/custom-react-select'
import { createSelectOption, formatPrice } from '@helpers/utils'
import { usePackageWholesaleAppData } from '@modules/package-wholesale/hooks/use-package-wholesale-app-data'
import { useSubscriptionKinds } from '@modules/subscription/hooks/use-subscription-kinds'
import { packageWholesaleProductsStatusOptions } from '@modules/package-wholesale/products/list/filter'
import { DepositPaymentDeadline } from '@models/dashboard'

const accommodationTypeOptions = [createSelectOption('Domek', 1), createSelectOption('Apartament', 2)]
const localizationKindOptions = [
  createSelectOption('Morze', 'sea'),
  createSelectOption('Góry', 'mountains'),
  createSelectOption('Uniwersalne', 'universal'),
]

interface Props extends BaseModalProps {
  packageWholesaleProductDetails?: PackageWholesaleProductDetails
}

interface AdditionalOptionFormInputs {
  kind: CustomReactSelectOption | null
  price_brutto: string
  description: string
}

export interface PackageWholesaleProductFormInputs {
  discount: string | number | null
  kind: PackageWholesaleProductKind
  name: string
  prefix: string
  subscription_kind: CustomReactSelectOption | null
  accommodation_type: CustomReactSelectOption | null
  localization_kind: CustomReactSelectOption | null
  deposit_amount: null | CustomReactSelectOption
  defaults_days: null | CustomReactSelectOption
  status: CustomReactSelectOption | null
  deposit_payment_deadline: DepositPaymentDeadline
  options: AdditionalOptionFormInputs[]
  with_cleaning_option: boolean
  with_parking: boolean
  with_grill: boolean
  with_towel: boolean
  with_client_change_data_option: boolean
  expire_after_years: number | null
  price_brutto: string
  subscription_kind_version: CustomReactSelectOption | null
  subscription_code_name_kind: string
}

export const PackageWholesaleProductCreateModal: React.FC<Props> = ({
  toggleIsVisible,
  packageWholesaleProductDetails,
}) => {
  const [image, setImage] = React.useState<File>()

  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { available_options, deposit_amount, defaults_days } = usePackageWholesaleAppData()

  const { subscriptionKindOptions, subscriptionKindVersionOptions } = useSubscriptionKinds()

  const depositAmountOptions = deposit_amount.map(amount => createSelectOption(formatPrice(amount), amount))
  const defaultsDaysOptions = defaults_days.map(amount => createSelectOption(amount.toString(), amount))

  const methods = useForm<PackageWholesaleProductFormInputs>({
    defaultValues: {
      deposit_payment_deadline: '12_months',
      kind: 'subscription',
      ...packageWholesaleProductDetails,
      deposit_amount: makeDefaultSelectOption(
        depositAmountOptions,
        packageWholesaleProductDetails?.deposit_amount || 750,
      ),
      defaults_days: makeDefaultSelectOption(defaultsDaysOptions, packageWholesaleProductDetails?.defaults_days || 6),
      status: makeDefaultSelectOption(packageWholesaleProductsStatusOptions, packageWholesaleProductDetails?.status),
      subscription_kind: makeDefaultSelectOption(
        subscriptionKindOptions,
        packageWholesaleProductDetails?.subscription_kind,
      ),
      subscription_kind_version: makeDefaultSelectOption(
        subscriptionKindVersionOptions,
        packageWholesaleProductDetails?.subscription_kind_version,
      ),
      accommodation_type: makeDefaultSelectOption(
        accommodationTypeOptions,
        packageWholesaleProductDetails?.accommodation_type_id,
      ),
      localization_kind: makeDefaultSelectOption(
        localizationKindOptions,
        packageWholesaleProductDetails?.localization_kind,
      ),
      options: [],
      discount: packageWholesaleProductDetails?.discount || '',
    },
  })

  const { isLoading, action: onSubmit } = useFormRequest(async () => {
    methods.clearErrors()
    const payload = methods.getValues()

    const response = await (!packageWholesaleProductDetails
      ? packageWholesaleProductCreate(image, payload)
      : packageWholesaleProductUpdate(packageWholesaleProductDetails.urls.details, image, payload))

    addSuccessMessage(
      'Sukces',
      !packageWholesaleProductDetails
        ? `Produkt "${response.name} został dodany`
        : `Produkt "${response.name} został zmieniony`,
    )
    toggleIsVisible()
    dispatch(updatePackageWholesaleProductDetails(response))
  }, methods.setError)

  React.useEffect(() => {
    if (!packageWholesaleProductDetails) return

    const addedOptions = packageWholesaleProductDetails?.options.map(option => {
      const appDataOption = available_options.find(el => el.kind === option.kind)

      return { ...option, kind: createSelectOption(appDataOption?.name, appDataOption?.kind) }
    })

    methods.setValue('options', addedOptions)
  }, [available_options, packageWholesaleProductDetails?.options])

  return (
    <FormProvider {...methods}>
      <ModalHeader toggle={toggleIsVisible}>
        {!packageWholesaleProductDetails ? (
          'Dodaj produkt'
        ) : (
          <>
            Edytuj produkt <span className="text-semi-strong">{packageWholesaleProductDetails.name}</span>
          </>
        )}
      </ModalHeader>
      <ModalBody className="pb-0">
        <PackageWholesaleProductCreateForm
          depositAmountOptions={depositAmountOptions}
          defaultsDaysOptions={defaultsDaysOptions}
          accommodationTypeOptions={accommodationTypeOptions}
          localizationKindOptions={localizationKindOptions}
          packageWholesaleProductDetails={packageWholesaleProductDetails}
          isLoading={isLoading}
          image={image}
          setImage={setImage}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>

        <SaveButton className="btn btn-green" isSaving={isLoading} onClick={onSubmit} />
      </ModalFooter>
    </FormProvider>
  )
}
