import * as React from 'react'
import classNames from 'classnames'

interface CustomSwitchProps {
  handleSwitchChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  label: React.ReactNode
  value?: boolean
  inputName?: string
  register?: any
  labelClassName?: string
  switchClassName?: string
  id?: string
  readOnly?: boolean
  labelDirection?: 'left' | 'right'
}

export const CustomSwitch: React.FC<CustomSwitchProps> = ({
  handleSwitchChange,
  label,
  value,
  inputName,
  register,
  readOnly,
  id,
  labelClassName,
  switchClassName,
  labelDirection = 'right',
}) => {
  const labelElement = (
    <label
      className={classNames('custom-control-label cursor-pointer', labelClassName)}
      htmlFor={id || inputName || 'custom-switch'}
    >
      {label}
    </label>
  )
  return (
    <div className={classNames('custom-control custom-switch', switchClassName)}>
      {labelDirection == 'left' && labelElement}
      <input
        name={inputName || 'custom-switch'}
        type="checkbox"
        className="custom-control-input cursor-pointer"
        id={id || inputName || 'custom-switch'}
        onChange={handleSwitchChange}
        checked={value}
        disabled={readOnly}
        {...(register ? register(inputName) : {})}
      />
      {labelDirection == 'right' && labelElement}
    </div>
  )
}
