import * as React from 'react'
import { Button, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { SaveButton } from '@hyper/button'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useForm } from 'react-hook-form'
import { endOfMonth, startOfMonth, startOfToday } from 'date-fns'
import { createReportTask } from '@store/actions/report-tasks-actions'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { BaseModalProps } from '@components/modals/types'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { Form } from '@hyper/forms/form'
import { useGroupedSellers } from '@components/hooks/use-grouped-sellers'
import { useGroupedSources } from '@components/hooks/use-grouped-sources'
import { SubscriptionContractStatusOptions } from '@modules/subscription-contract/consts'
import { FormSelect } from '@hyper/forms/form-select'

interface FormInputs {
  sell_date_after: Date
  sell_date_before: Date
  seller: CustomReactSelectOption | null
  status: CustomReactSelectOption[] | null
  source: CustomReactSelectOption | null
}

export const ReportsSubscriptionContractModal: React.FC<BaseModalProps> = ({ toggleIsVisible }) => {
  const reportUrl = useAppSelector(
    (state: RootState) => state.appState.appData.urls.reports.report_task_subscription_contract_report,
  )

  const dispatch = useAppDispatch()

  const sellerOptions = useGroupedSellers()
  const sourceOptions = useGroupedSources()

  const methods = useForm<FormInputs>({
    mode: 'all',
    defaultValues: {
      sell_date_after: startOfMonth(startOfToday()),
      sell_date_before: endOfMonth(startOfToday()),
      seller: null,
      status: null,
      source: null,
    },
  })

  const { isLoading, action: onSubmit } = useReportFormRequest(async (payload: FormInputs) => {
    const reportTask = await dispatch(
      createReportTask([
        reportUrl,
        {
          ...payload,
          seller: payload.seller?.value,
          source: payload.source?.value,
          status: payload.status?.map(statusOption => statusOption.value),
        },
      ]),
    )
    toggleIsVisible()
    return reportTask
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Raport subskrypcji</ModalHeader>
      <ModalBody>
        <Row>
          <DateFromToInput
            colSize={12}
            label="Data utworzenia"
            startDateName="sell_date_after"
            endDateName="sell_date_before"
          />
          <FormSelect
            options={SubscriptionContractStatusOptions}
            name="status"
            label="Status"
            selectProps={{ isClearable: true, isMulti: true }}
            formPlainProps={{ colSize: 12, formGroupClassName: 'mb-3' }}
          />
          <FormSelect
            options={sellerOptions}
            name="seller"
            label="Sprzedawca"
            formPlainProps={{ colSize: 6, formGroupClassName: 'mb-3' }}
          />
          <FormSelect
            options={sourceOptions}
            name="source"
            label="Źródło"
            formPlainProps={{ colSize: 6, formGroupClassName: 'mb-3' }}
          />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" label="Zleć raport" labelSaving="Zlecanie.." isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
