import * as React from 'react'
import { useDrop } from 'react-dnd'
import { TimelineBooking } from '@store/reducers/timeline-reducers'
import { Apartment } from '@models/apartment'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { setBookingModalWarning, showBookingModal } from '@store/actions/timeline-actions'
import timelineDragHelper from '@helpers/timeline-drag-helper'
import { TimelineResizerDirection } from '@modules/reservations/timeline/timeline-item-resizer'
import classNames from 'classnames'

interface Props {
  children: React.ReactElement
  allApartments: Apartment[]
  isSticky: boolean
}

export type TimelineItemDragType = 'RESERVATION_ITEM' | 'RESERVATION_ITEM_RESIZER' | 'TIMELINE_SCROLLBAR'

export interface TimelineItemDragObject {
  direction: never
  booking: TimelineBooking
}

export interface TimelineResizerDragObject {
  direction: TimelineResizerDirection
  booking: TimelineBooking
}

export interface ReservationItemDiff {
  newDateTo?: string
  newDateFrom?: string
  newApartmentID?: number
}

const TimelineContentDroppable: React.FC<Props> = ({ children, allApartments, isSticky }) => {
  const dispatch = useAppDispatch()
  const initialDragData = useAppSelector((state: RootState) => state.timelineState.initialDragData)
  const [, drop] = useDrop<TimelineItemDragObject | TimelineResizerDragObject, any, any>({
    accept: ['RESERVATION_ITEM', 'RESERVATION_ITEM_RESIZER'],
    drop: (dragObject, monitor) => {
      const diffOffset = monitor.getDifferenceFromInitialOffset()

      if (!diffOffset || !initialDragData) {
        return
      }

      const { x, y, renderedDates } = initialDragData
      const initialScrollPosition = { x, y }
      const { booking, direction } = dragObject

      const initialItemPositionLeft = timelineDragHelper.getInitialItemPosition(
        booking.apartment,
        allApartments,
        renderedDates,
        booking.date_from,
      )

      const initialItemPositionRight = timelineDragHelper.getInitialItemPosition(
        booking.apartment,
        allApartments,
        renderedDates,
        booking.date_to,
      )

      const bookingCord = timelineDragHelper.getBookingCord(diffOffset, initialItemPositionLeft, initialScrollPosition)

      const movedDays = timelineDragHelper.getResizeDays(
        diffOffset,
        initialItemPositionRight,
        initialItemPositionLeft,
        initialScrollPosition,
        direction,
        booking,
      )

      if (
        timelineDragHelper.shouldShowModal(
          monitor.getItemType() as TimelineItemDragType,
          dragObject,
          bookingCord,
          movedDays,
          allApartments,
        )
      ) {
        if (booking.selected_apartment && monitor.getItemType() === 'RESERVATION_ITEM') {
          dispatch(setBookingModalWarning(true))
        }

        dispatch(
          showBookingModal({
            booking,
            ...(monitor.getItemType() === 'RESERVATION_ITEM'
              ? timelineDragHelper.getBookingDiff(bookingCord, booking, allApartments)
              : {}),
            ...(monitor.getItemType() === 'RESERVATION_ITEM_RESIZER' && direction === 'right'
              ? { newDateTo: timelineDragHelper.getNewResizerDate(movedDays, booking.date_to) }
              : {}),
            ...(monitor.getItemType() === 'RESERVATION_ITEM_RESIZER' && direction === 'left'
              ? { newDateFrom: timelineDragHelper.getNewResizerDate(-movedDays, booking.date_from) }
              : {}),
          }),
        )
      }
    },
  })

  return (
    <div id="droppable" className={classNames('calendar-content', { 'is-sticky': isSticky })} ref={drop}>
      {children}
    </div>
  )
}

export default TimelineContentDroppable
