import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import {
  Subscription,
  SubscriptionAggregation,
  SubscriptionDetails,
  SubscriptionOptionPriceList,
  SubscriptionProduct,
  SubscriptionType,
} from '@modules/subscription/models'
import { getSubscriptionAppData, getSubscriptionProducts, getSubscriptions } from '@store/actions/subscription-actions'
import { RootState } from '@store/index'
import { UserPermission } from '@models/dashboard'

interface SubscriptionAppDataProductDefaults {
  change_client_data_price_brutto: string
  code_localization_convert_price_brutto: string
  zoo_borysew_tickets_single_price_brutto: string
  suntago_tickets_single_price_brutto: string
}

export type SubscriptionTypeStrategyOption =
  | 'single_change_data'
  | 'single_change_date'
  | 'zoo_borysew'
  | 'suntago'
  | 'change_data'
  | 'localization'
  | 'single_localization'
  | 'expiration_extend'
  | 'code_kind_convert'
  | 'upgrade_to_purple'

export interface SubscriptionTypeStrategy {
  allow_change_defaults_days: boolean
  has_universal_localization_code: boolean
  product_name: string
  allow_add_additional_clients: boolean
  can_add_manual_payment: boolean
  notifications: [string, string][]
  can_add_subscription_code_feature: boolean
  crud_permissions: {
    create: UserPermission
    destroy: UserPermission
    renew: UserPermission
    update: UserPermission
  }
  allow_merge_code: boolean
  allow_change_expire_after: boolean
  allow_menage_products: boolean
  allow_add_gastro_gift_to_product: boolean
  allow_add_package_vip_to_product: boolean
  allow_options: SubscriptionTypeStrategyOption[]
}

type SubscriptionTypes = {
  [key in SubscriptionType]: SubscriptionTypeStrategy
}

export interface SubscriptionAppData {
  subscription_code_name_kinds: [string, string][]
  subscription_option_price_list: SubscriptionOptionPriceList[]
  subscription_types: SubscriptionTypes
  default_subscription_expire_after: string
  deposit_amount: number[]
  products: SubscriptionProduct[]
  products_defaults: SubscriptionAppDataProductDefaults
  subscription_option_kinds: { key: string; value: string }[]
  status: 'unknown' | 'loading' | 'ready'
  client_kinds: [string, string][]
}

interface SubscriptionState {
  subscriptionDetails: SubscriptionDetails | undefined
  subscriptionProducts: EntityState<SubscriptionProduct>
  subscriptions: EntityState<Subscription>
  appData: SubscriptionAppData
  aggregation: SubscriptionAggregation | null
}

const subscriptionProductsAdapter = createEntityAdapter<SubscriptionProduct>()
const subscriptionsAdapter = createEntityAdapter<Subscription>()

const emptySubscriptionType: SubscriptionTypeStrategy = {
  can_add_subscription_code_feature: false,
  allow_merge_code: false,
  allow_change_defaults_days: false,
  allow_change_expire_after: true,
  allow_options: [],
  has_universal_localization_code: false,
  product_name: '',
  allow_add_additional_clients: false,
  notifications: [],
  can_add_manual_payment: false,
  crud_permissions: {
    update: UserPermission.Dummy,
    create: UserPermission.Dummy,
    destroy: UserPermission.Dummy,
    renew: UserPermission.Dummy,
  },
  allow_menage_products: false,
  allow_add_gastro_gift_to_product: false,
  allow_add_package_vip_to_product: false,
}

const initialState: SubscriptionState = {
  subscriptionProducts: subscriptionProductsAdapter.getInitialState(),
  subscriptions: subscriptionsAdapter.getInitialState(),
  subscriptionDetails: undefined,
  aggregation: null,
  appData: {
    subscription_code_name_kinds: [],
    subscription_option_price_list: [],
    subscription_types: {
      BOOKING_IMPROVEMENT: emptySubscriptionType,
      CAFETERIA: emptySubscriptionType,
      DEFAULT: emptySubscriptionType,
      WHOLESALE: emptySubscriptionType,
      WITH_BENEFIT_20: emptySubscriptionType,
      WITH_BENEFIT: emptySubscriptionType,
      GO_HOLIDAY: emptySubscriptionType,
      PRODUCT_PACKAGE: emptySubscriptionType,
    },
    default_subscription_expire_after: '2020-05-28',
    deposit_amount: [],
    client_kinds: [],
    products: [],
    subscription_option_kinds: [],
    products_defaults: {
      change_client_data_price_brutto: '',
      code_localization_convert_price_brutto: '',
      zoo_borysew_tickets_single_price_brutto: '',
      suntago_tickets_single_price_brutto: '',
    },
    status: 'unknown',
  },
}

const subscriptionSlice = createSlice({
  name: 'subscriptions',
  initialState: initialState,
  reducers: {
    setSubscriptionAppData(state, action: PayloadAction<SubscriptionAppData>) {
      state.appData = action.payload
    },
    setSubscriptionDetails(state, action: PayloadAction<SubscriptionDetails>) {
      state.subscriptionDetails = action.payload
      subscriptionsAdapter.updateOne(state.subscriptions, {
        id: action.payload.id,
        changes: action.payload,
      })
    },
    updateSubscriptionDetails(state, action: PayloadAction<SubscriptionDetails>) {
      if (state.subscriptionDetails?.id === action.payload.id) {
        state.subscriptionDetails = action.payload
      }
      subscriptionsAdapter.upsertOne(state.subscriptions, action.payload)
    },
    updateSubscriptionProductsBulk(state, action: PayloadAction<SubscriptionProduct[]>) {
      subscriptionProductsAdapter.upsertMany(state.subscriptionProducts, action.payload)
    },
    updateSubscriptionProductDetails(state, action: PayloadAction<SubscriptionProduct>) {
      subscriptionProductsAdapter.upsertOne(state.subscriptionProducts, action.payload)
      state.appData = {
        ...state.appData,
        products: state.appData.products.map(product => (product.id === action.payload.id ? action.payload : product)),
      }
    },
    clearSubscriptionDetails(state) {
      state.subscriptionDetails = undefined
    },
    clearSubscriptions(state) {
      subscriptionsAdapter.removeAll(state.subscriptions)
    },
  },
  extraReducers: builder => {
    builder.addCase(getSubscriptionAppData.fulfilled, (state, action) => {
      state.appData = { ...state.appData, ...action.payload, status: 'ready' }
    })
    builder.addCase(getSubscriptions.fulfilled, (state, action) => {
      subscriptionsAdapter.setAll(state.subscriptions, action.payload.results)
      state.aggregation = action.payload.aggregation
    })
    builder.addCase(getSubscriptionProducts.fulfilled, (state, action) => {
      subscriptionProductsAdapter.setAll(state.subscriptionProducts, action.payload)
    })
  },
})

export const {
  clearSubscriptions,
  updateSubscriptionProductDetails,
  updateSubscriptionDetails,
  updateSubscriptionProductsBulk,
  setSubscriptionDetails,
  setSubscriptionAppData,
  clearSubscriptionDetails,
} = subscriptionSlice.actions

export const { selectAll: subscriptionsSelector } = subscriptionsAdapter.getSelectors(
  (state: RootState) => state.subscriptionState.subscriptions,
)

export const { selectAll: subscriptionProductsSelector } = subscriptionProductsAdapter.getSelectors(
  (state: RootState) => state.subscriptionState.subscriptionProducts,
)

export const selectSubscriptionsAggregation = (state: RootState): SubscriptionAggregation | null =>
  state.subscriptionState.aggregation

export default subscriptionSlice.reducer
