import * as React from 'react'
import { PaymentRegistersFiltersParams } from '@modules/payment-registers/list/filters'
import Table, { TableHeaderValue } from '@components/table/table'
import { useAppDispatch, useAppSelector } from '@store/index'
import { clearPaymentRegisters, paymentRegistersSelector } from '@store/slices/cash-slice'
import { PaymentRegister } from '@models/payments'
import { PaymentRegisterCashboxDayTransactionsTableRow } from '@modules/payment-registers/cashbox-day-details/transactions/table/row'

interface Props {
  isLoading: boolean
  filters: PaymentRegistersFiltersParams
  setFilters: (filters: PaymentRegistersFiltersParams) => void
}

const headerValues: TableHeaderValue<PaymentRegister>[] = [
  { title: 'Lp.' },
  { title: 'Godzina', sortField: 'created' },
  { title: 'Typ' },
  { title: 'Nazwa operacji' },
  { title: 'Numer rezerwacji' },
  { title: 'Dodane przez' },
  { title: 'Kwota', sortField: 'amount' },
  { title: 'Status' },
]

export const PaymentRegisterCashboxDayTransactionsTable = ({ isLoading, filters, setFilters }: Props): JSX.Element => {
  const dispatch = useAppDispatch()

  const paymentRegisters = useAppSelector(paymentRegistersSelector)

  React.useEffect(
    () => () => {
      dispatch(clearPaymentRegisters())
    },
    [],
  )

  return (
    <Table
      className="table-hover w-100"
      headerValues={headerValues}
      isLoading={isLoading}
      filters={filters}
      setFilters={setFilters}
    >
      {paymentRegisters.map((paymentRegister, index) => (
        <PaymentRegisterCashboxDayTransactionsTableRow
          lp={index + 1}
          key={paymentRegister.id}
          paymentRegister={paymentRegister}
        />
      ))}
    </Table>
  )
}
