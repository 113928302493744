import { RootState, useAppSelector } from '@store/index'
import { Country } from '@models/dashboard'
import * as React from 'react'
import { createSelectOption } from '@helpers/utils'
import { CustomReactSelectOption } from '@components/custom-react-select'

interface Response {
  countriesOptions: CustomReactSelectOption[]
  defaultCountryOption: CustomReactSelectOption
  countries: Country[]
}

export const useCountries = (initialCountry?: string): Response => {
  const countries = useAppSelector((state: RootState) => state.appState.appData.countries)

  const countriesOptions = React.useMemo(
    () => countries.map((country: Country) => createSelectOption(country.name, country.id)),
    [countries],
  )

  const defaultCountryOption = React.useMemo(
    () =>
      countriesOptions.find((country: CustomReactSelectOption) => country.value === (initialCountry || 'PL')) ??
      countriesOptions[0],
    [countries],
  )

  return {
    countries,
    defaultCountryOption,
    countriesOptions,
  }
}
