import * as React from 'react'
import { ReceptionBookingCheckInPreviewPersonalData } from '@modules/reception/checkin/preview/personal-data/reception-booking-check-in-preview-personal-data'
import { ReceptionBookingDetails } from '@models/reception'
import { ModalBody, ModalFooter } from 'reactstrap'
import { ButtonWithIcon } from '@components/button-with-icon'
import { SaveButton } from '@hyper/button'
import { IconWithText } from '@components/icon-with-text'
import { ReceptionBookingCheckOutStepThanksBoxReceipt } from '@modules/reception/checkout/step-thanks/receipt'
import { ReceptionBookingCheckOutStepThanksBoxes } from '@modules/reception/checkout/step-thanks/step-thanks-boxes'
import { FormProvider, useForm } from 'react-hook-form'
import { ReservationCard } from '@modules/reservations/details/common/reservation-card'
import { ReceptionBookingCheckoutPreviewBill } from '@modules/reception/checkout/preview/reception-boooking-check-out-preview-bill'
import { useReceptionCheckOut } from '@modules/reception/checkout/step-settlement/use-reception-check-out'
import classname from 'classnames'

interface Props {
  bookingDetails: ReceptionBookingDetails
  onPreviewDialogClose: () => void
  onShowDefaultCheckoutModalStep: (step?: number) => void
}

export const ReceptionBookingCheckOutPreviewDialogContent = ({
  bookingDetails,
  onPreviewDialogClose,
  onShowDefaultCheckoutModalStep,
}: Props): JSX.Element => {
  const methods = useForm()

  const {
    restToPay,
    depositToReturn,
    totalDeposit,
    isPaid,
    checkOut,
    isCheckingOut,
    restToPayWithoutClimatic,
    confirmCheckedOut,
    isConfirming,
    isOnlyClimaticTaxLeft,
  } = useReceptionCheckOut(bookingDetails)

  const handleConfirm = async () => {
    if (!isPaid) {
      onShowDefaultCheckoutModalStep(3)
      return
    }

    const result = await checkOut()
    if (result) {
      await confirmCheckedOut()
      onPreviewDialogClose()
    }
  }

  return (
    <>
      <ModalBody className="reception__booking-check-out-preview__content">
        <FormProvider {...methods}>
          <div className="row">
            <div className="col-6 pr-1">
              <ReceptionBookingCheckInPreviewPersonalData bookingDetails={bookingDetails} />
              <ReservationCard title="Scenariusze rozmowy z Gościem:" cardClassName="mb-2">
                <ReceptionBookingCheckOutStepThanksBoxes />
              </ReservationCard>
            </div>
            <div className="col-6 pl-1">
              <ReceptionBookingCheckoutPreviewBill
                receptionBookingDetails={bookingDetails}
                restToPayWithoutClimatic={restToPayWithoutClimatic}
                restToPay={restToPay}
                totalDeposit={totalDeposit}
                depositToReturn={depositToReturn}
                isOnlyClimaticTaxLeft={isOnlyClimaticTaxLeft}
              />
              <ReceptionBookingCheckOutStepThanksBoxReceipt receptionBookingDetails={bookingDetails} />
            </div>
          </div>
        </FormProvider>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <ButtonWithIcon
          icon="uil-edit-alt font-15"
          text="Popraw dane"
          color="light"
          type="button"
          handleClick={() => onShowDefaultCheckoutModalStep()}
        />

        <SaveButton
          className={classname('btn', isPaid ? 'btn-green' : 'btn-danger')}
          label={
            <IconWithText
              icon={classname('font-16', { 'uil-check': isPaid })}
              text={isPaid ? 'Potwierdź i wymelduj' : 'Przejdź do widoku płatności'}
              wrapperClassNames="text-white"
            />
          }
          labelSaving="Potwierdzanie.."
          isSaving={isCheckingOut || isConfirming}
          onClick={handleConfirm}
        />
      </ModalFooter>
    </>
  )
}
