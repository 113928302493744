import React from 'react'
import { bandReaderEncodeCancel } from '@store/actions/bands-actions'
import { v4 as uuidv4 } from 'uuid'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useIntervalWhen } from 'rooks'
import { commonObjectGet, commonObjectPost } from '@store/actions/generic-actions'
import { useAppData } from '@components/hooks/use-app-data'
import { BandScanningResult, BandScanningStatus, BandsToScanQueueItem } from '@components/band-scanning/models'
import { ReceptionBookingDetails } from '@models/reception'
import { useAppDispatch, useAppSelector } from '@store/index'
import { setReceptionBookingDetails } from '@store/slices/reception-slice'
import { addMilliseconds } from 'date-fns'
import { receptionBookingDetailsSelector } from '@store/selectors/reception'

const AUTO_NEXT_BAND_SCANNING_TIME = 4000
const MAX_ERROR_OCCURRENCE_COUNT = 5

interface ScanningOption {
  refresh: boolean
}

interface Response {
  startScanning: (bandsIds: number[], hoursFrom: string, reader: number, { refresh }: ScanningOption) => void
  refreshBandScanQueue: () => void
  cancelScanning: () => void
  globalScanningStatus: BandScanningStatus
  bandsToScanQueue: BandsToScanQueueItem[]
  getBandStatus: (bandId: number) => BandScanningStatus
  nextScanDate: Date | null
}

let nextScanDateTimeout: NodeJS.Timeout

export const useBandScanner = (): Response => {
  const { urls } = useAppData()
  const dispatch = useAppDispatch()
  const bookingDetails = useAppSelector(receptionBookingDetailsSelector)

  const [scanningData, setScanningData] = React.useState<{ url: string; bandId: number } | null>(null)
  const [bandsToScanQueue, setBandsToScanQueue] = React.useState<BandsToScanQueueItem[]>([])
  const [errorCounter, setErrorCounter] = React.useState(0)
  const [nextScanDate, setNextScanDate] = React.useState<Date | null>(null)

  const getNextBandToScan = (bands: BandsToScanQueueItem[]) => bands.find(item => item.scanResult?.result !== 'SUCCESS')

  const startScanning = async (bandIds: number[], hoursFrom: string, reader: number, { refresh }: ScanningOption) => {
    setErrorCounter(0)
    setNextScanDate(null)
    clearTimeout(nextScanDateTimeout)

    const bandsToScan = bandIds
      .toSorted((a, b) => a - b)
      .map(bandId => ({
        bandId,
        hoursFrom,
        reader,
        isScanning: false,
        uuid: uuidv4(),
        scanResult: refresh ? null : bandsToScanQueue.find(item => item.bandId === bandId)?.scanResult || null,
      }))

    if (!bandsToScan.length) return
    setBandsToScanQueue(bandsToScan)

    const nextBandToScan = getNextBandToScan(bandsToScan)
    if (nextBandToScan) {
      await scanBand(nextBandToScan)
    }
  }

  const { action: scanBand } = useApiRequest(async (queueItem: BandsToScanQueueItem) => {
    setBandsToScanQueue(state => state.map(item => ({ ...item, isScanning: item.bandId === queueItem.bandId })))

    const response = await commonObjectPost<{ url: string; booking: ReceptionBookingDetails }>(urls.bands.encode_band, {
      hour_from: queueItem.hoursFrom,
      reader: queueItem.reader,
      bands: [queueItem.bandId],
      uuid: queueItem.uuid,
      checkout_apartment:
        bookingDetails.bands.every(band => band.state !== 'RELEASED') &&
        bandsToScanQueue.every(queueItem => !queueItem.scanResult),
    })

    dispatch(setReceptionBookingDetails(response.booking))
    setScanningData({ url: response.url, bandId: queueItem.bandId })
  })

  const scanNextBand = async () => {
    const bandId = scanningData?.bandId
    setScanningData(null)

    const restBandToScan = bandsToScanQueue.filter(band => band.bandId !== bandId)
    const nextBandToScan = getNextBandToScan(restBandToScan)
    if (nextBandToScan) {
      setNextScanDate(addMilliseconds(new Date(), AUTO_NEXT_BAND_SCANNING_TIME))

      nextScanDateTimeout = setTimeout(async () => {
        await scanBand(nextBandToScan)
        setNextScanDate(null)
      }, AUTO_NEXT_BAND_SCANNING_TIME)
    }
  }

  const { action: fetchScanningStatus } = useApiRequest(async () => {
    if (!scanningData) return

    const scanningResult = await commonObjectGet<BandScanningResult>(scanningData.url)

    const updateBandScanningStatus = () => {
      setBandsToScanQueue(state =>
        state.map(el => ({
          ...el,
          isScanning: false,
          scanResult: el.bandId === scanningData.bandId ? scanningResult : el.scanResult,
        })),
      )
    }

    if (['SUCCESS', 'CANCEL'].includes(scanningResult.result)) {
      updateBandScanningStatus()
      await scanNextBand()
    }

    if (['ERROR', 'UNKNOWN'].includes(scanningResult.result)) {
      if (errorCounter < MAX_ERROR_OCCURRENCE_COUNT) {
        setErrorCounter(state => state + 1)
      } else {
        updateBandScanningStatus()
        setScanningData(null)
      }
    }
  })

  const cancelScanning = async () => {
    const scanningUuid = bandsToScanQueue.find(item => item.isScanning)?.uuid
    if (!scanningUuid) return

    await bandReaderEncodeCancel({ uuid: scanningUuid })
    setScanningData(null)

    setBandsToScanQueue(state =>
      state.reduce(
        (prev, curr) => (curr.scanResult?.result === 'SUCCESS' ? [...prev, { ...curr, isScanning: false }] : prev),
        [],
      ),
    )
  }

  const getGlobalScanningStatus = () => {
    const scanningBand = bandsToScanQueue.find(item => item.isScanning)
    const lastScannedBand = bandsToScanQueue.findLast(item => item.scanResult)

    if (!bandsToScanQueue.length) return 'idle'
    return getBandStatus(scanningBand?.bandId || lastScannedBand?.bandId || bandsToScanQueue[0].bandId)
  }

  const getBandStatus = (bandId: number) => {
    if (!bandsToScanQueue.length) return 'idle'

    const bandData = bandsToScanQueue.find(item => item.bandId === bandId)

    if (bandData?.isScanning) return 'scanning'
    if (bandData && !bandData?.scanResult) return 'waiting'
    if (bandData?.scanResult?.result === 'SUCCESS') return 'success'
    if (bandData?.scanResult?.result === 'ERROR') return 'error'
    return 'idle'
  }

  const refreshBandScanQueue = () => {
    setBandsToScanQueue([])
  }

  React.useEffect(() => () => clearTimeout(nextScanDateTimeout), [])
  useIntervalWhen(fetchScanningStatus, 2000, !!scanningData)

  return {
    globalScanningStatus: getGlobalScanningStatus(),
    getBandStatus,
    startScanning,
    cancelScanning,
    bandsToScanQueue: bandsToScanQueue,
    nextScanDate,
    refreshBandScanQueue,
  }
}
