import { AnyAction, configureStore, ThunkDispatch } from '@reduxjs/toolkit'
import accountingState from '@store/slices/accounting-slice'
import cmsState from '@store/reducers/cms-reducers'
import crmState from '@store/reducers/crm-reducers'
import dashboardState from '@store/reducers/dashboard-reducers'
import feedingState from '@store/slices/feeding-slice'
import promotionsState from '@store/reducers/promotions-reducers'
import receptionState from '@store/slices/reception-slice'
import reportsState from '@store/slices/reports-slice'
import reservationsState from '@store/slices/reservations-slice'
import saleState from '@store/reducers/sale-reducers'
import shopState from '@store/slices/shop-slice'
import subscriptionState from '@store/slices/subscription-slice'
import timelineState from '@store/reducers/timeline-reducers'
import webSocketState from '@store/slices/websocket-slice'
import gastroCardState from '@store/slices/gastro-card-slice'
import accountState from '@store/slices/account-slice'
import packageState from '@store/slices/package-slice'
import modalState from '@store/slices/modal-slice'
import notificationState from '@store/slices/notification-slice'
import recommendationGiftState from '@store/slices/recommendation-gift-slice'
import reportTaskState from '@store/slices/report-task-slice'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { throwMiddleware } from '@store/throw-middleware'
import rcpState from '@store/slices/rcp-slice'
import packageWholesaleState from '@store/slices/package-wholesale-slice'
import promocodeTouristVoucherState from '@store/slices/promocode-tourist-voucher-slice'
import productsState from '@store/slices/products-slice'
import appState from '@store/slices/app-slice'
import barriersState from '@store/slices/barrier-slice'
import cmsReservationAppState from '@store/slices/cms-reservation-app-slice'
import houseKeepingState from '@store/slices/housekeeping-slice'
import cashState from '@store/slices/cash-slice'
import assetState from '@store/slices/assets-slice'
import subscriptionContractState from '@store/slices/subscription-contract-slice'
import packageVipState from '@store/slices/package-vip-slice'
import rentalState from '@store/slices/rental-slice'
import hrState from '@store/slices/hr-slice'
import cafeteriaState from '@store/slices/cafeteria-slice'
import promocodesState from '@store/slices/promocodes-slice'
import newFeedingState from '@store/slices/new-feeding-slice'
import floorHeatingState from '@store/slices/floor-heating-slice'
import ecommerceState from '@store/slices/ecommerce-slice'
import serviceBandState from '@store/slices/service-band-slice'
import { crmApi } from '@api/crm'
import { technicalOrdersApi } from '@api/technical-orders'
import { cashbackApi } from '@api/cashback'
import { unitsApi } from '@api/units'
import { bookingOptionsApi } from '@api/booking-options'
import { cleaningOrdersApi } from '@api/cleaning-orders'
import { improvementOrdersApi } from '@api/improvement-orders'
import { upSellBookingApi } from '@api/upSellBooking'
import { couponsApi } from '@api/coupons'
import { rodoAgreementsApi } from '@api/rodo-agreements'
import { upSellUsersApi } from '@api/upSellUsers'
import { bookingOffersApi } from '@api/booking-offers'
import { agreementsApi } from '@api/agreements'
import { genericVouchersApi } from '@api/generic-vouchers'
import { benefitProgramsApi } from '@api/benefit-programs'
import { endlessHolidayApi } from '@api/endless-holiday'

export const persistedReducer = {
  accountState,
  accountingState,
  appState,
  assetState,
  barriersState,
  cafeteriaState,
  cashState,
  hrState,
  cmsReservationAppState,
  cmsState,
  crmState,
  dashboardState,
  feedingState,
  newFeedingState,
  gastroCardState,
  houseKeepingState,
  serviceBandState,
  modalState,
  notificationState,
  packageState,
  packageVipState,
  packageWholesaleState,
  productsState,
  promocodeTouristVoucherState,
  promotionsState,
  rcpState,
  receptionState,
  recommendationGiftState,
  rentalState,
  reportTaskState,
  reportsState,

  reservationsState,
  promocodesState,
  saleState,
  shopState,
  subscriptionContractState,
  subscriptionState,
  timelineState,
  webSocketState,
  floorHeatingState,
  ecommerceState,
  [crmApi.reducerPath]: crmApi.reducer,
  [cashbackApi.reducerPath]: cashbackApi.reducer,
  [improvementOrdersApi.reducerPath]: improvementOrdersApi.reducer,
  [cleaningOrdersApi.reducerPath]: cleaningOrdersApi.reducer,
  [upSellBookingApi.reducerPath]: upSellBookingApi.reducer,
  [bookingOffersApi.reducerPath]: bookingOffersApi.reducer,
  [benefitProgramsApi.reducerPath]: benefitProgramsApi.reducer,
  [genericVouchersApi.reducerPath]: genericVouchersApi.reducer,
  [upSellUsersApi.reducerPath]: upSellUsersApi.reducer,
  [technicalOrdersApi.reducerPath]: technicalOrdersApi.reducer,
  [couponsApi.reducerPath]: couponsApi.reducer,
  [unitsApi.reducerPath]: unitsApi.reducer,
  [bookingOptionsApi.reducerPath]: bookingOptionsApi.reducer,
  [agreementsApi.reducerPath]: agreementsApi.reducer,
  [rodoAgreementsApi.reducerPath]: rodoAgreementsApi.reducer,
  [endlessHolidayApi.reducerPath]: endlessHolidayApi.reducer,
}

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      crmApi.middleware,
      couponsApi.middleware,
      cashbackApi.middleware,
      improvementOrdersApi.middleware,
      cleaningOrdersApi.middleware,
      upSellBookingApi.middleware,
      upSellUsersApi.middleware,
      benefitProgramsApi.middleware,
      bookingOffersApi.middleware,
      genericVouchersApi.middleware,
      technicalOrdersApi.middleware,
      unitsApi.middleware,
      bookingOptionsApi.middleware,
      rodoAgreementsApi.middleware,
      agreementsApi.middleware,
      endlessHolidayApi.middleware,
      throwMiddleware,
    ),
})

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = ThunkDispatch<RootState, any, AnyAction>

export interface AsyncThunkParams {
  state: RootState
  dispatch: AppDispatch
}

export const getState = () => store.getState()

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store
