import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import Col from 'reactstrap/lib/Col'
import { Label, Row } from 'reactstrap'
import DateRangeInput from '@components/date/date-range-input'
import { SubmitFilter } from '@modules/reception/common/filters/submit-filter'
import * as R from 'ramda'
import { HouseKeepingUserReportFiltersParams } from '@modules/reports/housekeeping-users-report'
import { useDebounce } from 'rooks'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { FormSelect } from '@hyper/forms/form-select'

interface FormInputs {
  finished_at_after: Date
  finished_at_before: Date
}

interface HousekeepingUserReportFiltersProps {
  defaultFilters: HouseKeepingUserReportFiltersParams
  filters: HouseKeepingUserReportFiltersParams
  setFilters: (filters: HouseKeepingUserReportFiltersParams) => void
  resortOptions: CustomReactSelectOption[]
}

export const HousekeepingUserReportFilters: React.FC<HousekeepingUserReportFiltersProps> = ({
  filters,
  defaultFilters,
  setFilters,
  resortOptions,
}) => {
  const methods = useFormContext<FormInputs>()

  const handleReset = () => {
    methods.reset()
    setFilters(defaultFilters)
  }

  const onSubmit = async (payload: FormInputs) => setFilters({ ...filters, ...payload })

  const values = { ...filters, ...methods.watch() }

  const onSubmitDebounced = useDebounce(values => onSubmit(values), 250)
  React.useEffect(() => {
    if (!R.equals(values, filters)) {
      onSubmitDebounced(values)
    }
  }, [values])

  return (
    <Row>
      <FormSelect
        options={resortOptions}
        name="resorts"
        label="Ośrodek"
        selectProps={{ isMulti: true }}
        formPlainProps={{ colSize: 3 }}
      />
      <Col className="col-auto" style={{ minWidth: 320 }}>
        <div className="d-flex">
          <Label className="mr-1">Zakres dat:</Label>
        </div>

        <DateRangeInput
          wrapperClassName="flex-grow-1"
          inputGroupClassName="form-group"
          startDateName="finished_at_after"
          endDateName="finished_at_before"
        />
      </Col>

      <SubmitFilter handleReset={handleReset} />
    </Row>
  )
}
