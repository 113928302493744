import * as React from 'react'
import { ReceptionCompleteCheckinFilters } from '@modules/reception/complete-checkin/reception-complete-checkin-filters'
import { useAppDispatch, useAppSelector } from '@store/index'
import { ReceptionCompleteCheckinList } from '@modules/reception/complete-checkin/reception-complete-checkin-list'
import { FeedingTypeFilter, ReceptionCheckType } from '@models/reception'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { getReceptionBookings } from '@store/actions/reception-actions'
import { startOfToday } from 'date-fns'
import { formatDate } from '@helpers/date-helper'
import { ReceptionWebSocketHandler } from '@modules/reception/reception-web-socket-handler'
import { useApiRequest } from '@components/hooks/use-api-request'
import { hasVip } from '@helpers/improvements'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { createSelectOption } from '@helpers/utils'
import { selectReceptionBookings, setReceptionBookings } from '@store/slices/reception-slice'

export interface ReceptionCompleteCheckinFiltersParams {
  sort_by: CustomReactSelectOption<string, 'name' | 'date_to' | 'days_count' | 'apartment.name'>
  sort_direction: CustomReactSelectOption<string, 'asc' | 'desc'>
  feeding: FeedingTypeFilter
  search: string
  only_vip: boolean
}

interface ReceptionCompleteCheckinProps {
  resortId: number
}

export const ReceptionCompleteCheckin: React.FC<ReceptionCompleteCheckinProps> = ({ resortId }) => {
  const defaultFilters: ReceptionCompleteCheckinFiltersParams = {
    sort_by: createSelectOption('Numer lokalu', 'apartment.name'),
    sort_direction: createSelectOption('Rosnąco', 'asc'),
    feeding: 'all',
    search: '',
    only_vip: false,
  }
  const [filters, setFilters] = React.useState<ReceptionCompleteCheckinFiltersParams>(defaultFilters)
  const [totalVip, setTotalVip] = React.useState(0)
  const dispatch = useAppDispatch()

  const receptionBookings = useAppSelector(selectReceptionBookings)
  const bookings = receptionBookings.filter(booking => booking.checked_in)

  const { isLoading, action: fetch } = useApiRequest(async () => {
    await dispatch(setReceptionBookings([]))
    await dispatch(getReceptionBookings(resortId, formatDate(startOfToday()), ReceptionCheckType.COMPLETE_CHECKIN))
  })

  React.useEffect(() => {
    fetch()
    return () => {
      dispatch(setReceptionBookings([]))
    }
  }, [resortId])

  React.useEffect(() => {
    if (!isLoading) {
      setTotalVip(bookings.filter(hasVip).length)
    }
  }, [bookings, isLoading])

  return (
    <>
      <ReceptionWebSocketHandler />

      <ReceptionCompleteCheckinFilters
        defaultFilters={defaultFilters}
        filters={filters}
        setFilters={setFilters}
        totalVip={totalVip}
      />

      <LoaderPlaceholder content={!isLoading}>
        <ReceptionCompleteCheckinList resortId={resortId} filters={filters} bookings={bookings} />
      </LoaderPlaceholder>
    </>
  )
}
