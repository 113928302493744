import * as React from 'react'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { UserPermission } from '@models/dashboard'
import { ProductCustomActionButton } from '@components/products/product-action-button'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { updateCafeteriaDetails } from '@store/slices/cafeteria-slice'
import { Cafeteria, CafeteriaDetails } from '@models/cafeteria'

interface Props {
  cafeteria: Cafeteria
}

export const CafeteriaCancelButton = ({ cafeteria }: Props): JSX.Element | null => {
  const dispatch = useAppDispatch()
  const user = useAuthenticatedUser()

  const { addSuccessMessage } = useNotificationHook()

  const { isLoading, action: handleAccept } = useApiRequest(async () => {
    dispatch(updateCafeteriaDetails(await commonObjectDelete<CafeteriaDetails>(cafeteria.urls.details)))
    addSuccessMessage('Sukces', `Kafeteria o nazwie ${cafeteria.name} została anulowana!`)
  })

  if (cafeteria.status === 'close' || !user.hasPerm(UserPermission.ProductsCafeteriaCanDelete)) return null

  return (
    <CommonObjectConfirmAction
      title="Potwierdź anulowanie kafeterii"
      isLoading={isLoading}
      handleAccept={handleAccept}
      message="Czy na pewno chcesz anulować wybraną kafeterię ? Operacja ta jest nieodwracalna."
    >
      <ProductCustomActionButton className="btn btn-danger" text="Anuluj" />
    </CommonObjectConfirmAction>
  )
}
