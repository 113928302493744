import * as React from 'react'
import { formatPrice } from '@helpers/utils'
import { BookingStatsTotals } from '@modules/reservations/models'
import { PercentageChangeIndicator } from '@components/percentage-change-indicator'
import { ReservationStatsBoxRow } from '@modules/reservations/stats-board/reservation-stats-box-row'

interface ReservationStatsBoxSubscriptionProps {
  stats: BookingStatsTotals
}

export const ReservationStatsBoxSubscription: React.FC<ReservationStatsBoxSubscriptionProps> = ({ stats }) => (
  <table className="table mb-0 table-sm">
    <tbody>
      <ReservationStatsBoxRow
        className="border-top-0"
        name="Liczba abonamentów:"
        value={
          <PercentageChangeIndicator
            baseValue={stats.subscription_today_count}
            valueToCompare={stats.subscription_yesterday_count}
          >
            {stats.subscription_count}
          </PercentageChangeIndicator>
        }
      />
      <ReservationStatsBoxRow name="Wartość abonamentów:" value={formatPrice(stats.subscription_amount)} />
      <ReservationStatsBoxRow name="Opcje dodatkowe:" value={formatPrice(stats.subscription_amount_additional)} />
      <ReservationStatsBoxRow name="Wpłacono:" value={formatPrice(stats.subscription_payin)} />
      <ReservationStatsBoxRow name="Abonamenty odbyte:" value={formatPrice(stats.subscription_done)} />
      <ReservationStatsBoxRow name="Abonamenty założone:" value={formatPrice(stats.subscription_created)} />
      <ReservationStatsBoxRow name="Abonamenty do wykorzystania:" value={formatPrice(stats.subscription_not_used)} />
      <ReservationStatsBoxRow
        name="Razem:"
        value={formatPrice(stats.subscription_not_used + stats.subscription_created + stats.subscription_done)}
      />
    </tbody>
  </table>
)
