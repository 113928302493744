import * as React from 'react'
import { useWebSocket } from '@components/hooks/use-web-socket'
import { ReportTask } from '@models/reports'
import { NotificationEvents } from '@models/dashboard'
import { useAppDispatch, useAppSelector } from '@store/index'
import { selectLastReportTask, setLastReportTask } from '@store/slices/report-task-slice'

export const UserReportsWebsocketHandler: React.FC = () => {
  const dispatch = useAppDispatch()
  const lastReportTask = useAppSelector(selectLastReportTask)

  useWebSocket<ReportTask>(
    (message, payload) => {
      switch (message) {
        case NotificationEvents.REPORT_TASK_CHANGE: {
          const reportTask = payload as ReportTask

          if (lastReportTask && reportTask.status === 'completed' && lastReportTask.token === reportTask.token) {
            dispatch(setLastReportTask(undefined))
            document.location.href = reportTask.url
          }

          break
        }
      }
    },
    [],
    [NotificationEvents.REPORT_TASK_CHANGE],
  )

  return null
}
