import * as React from 'react'
import { FormSelect } from '@hyper/forms/form-select'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useAppData } from '@components/hooks/use-app-data'
import { createSelectOption } from '@helpers/utils'

interface Props {
  resortId: number
  setFilters: any
}

export const TechnicalOrderTableUnitHeader: React.FC<Props> = ({ resortId, setFilters }) => {
  const { units } = useAppData()

  const unitKindsOptions: CustomReactSelectOption[] = React.useMemo(
    () =>
      Object.values<[string, string]>(
        units
          .filter(row => row.resort_id === resortId)
          .reduce((acc, row) => ({ ...acc, [row.kind_id]: [row.kind_id, row.kind] }), {}),
      ).map(row => createSelectOption(row[1], row[0])),
    [units],
  )

  const handleChange = (option: CustomReactSelectOption) => {
    setFilters(filters => ({ ...filters, unit__kind: option?.value ?? undefined }))
  }

  return (
    <FormSelect
      options={unitKindsOptions}
      name="unit"
      selectProps={{ isClearable: true, placeholder: 'Lokal', onChange: handleChange }}
      formPlainProps={{ colClassName: 'p-0 m-0', formGroupClassName: 'p-0 m-0 text-normal' }}
    />
  )
}
