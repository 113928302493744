import * as React from 'react'
import { CleaningOrder } from '@modules/housekeeping/models'
import { HousekeepingCleaningOrderToCheckRow } from '@modules/housekeeping/cleaning-orders/to-check/row'
import { TableViewType } from '@modules/technical-orders/common/table-view-switch'
import { HousekeepingCleaningOrderToCheckGroupedRow } from '@modules/housekeeping/cleaning-orders/to-check/grouped-row'
import { HousekeepingCleaningOrdersToCheckTableHeader } from '@modules/housekeeping/cleaning-orders/to-check/table-header'
import { HousekeepingNoOrdersRow } from '@modules/housekeeping/cleaning-orders/common/no-orders'

interface HousekeepingCleaningOrdersToCheckTableProps {
  cleaningOrders: CleaningOrder[]
  tableView: TableViewType
}

export const HousekeepingCleaningOrdersToCheckTable: React.FC<HousekeepingCleaningOrdersToCheckTableProps> = ({
  cleaningOrders,
  tableView,
}) => {
  const isListView = tableView === 'list'

  return (
    <table className="housekeeping__orders_table table table-striped">
      <HousekeepingCleaningOrdersToCheckTableHeader isListView={isListView} />

      <tbody>
        {!cleaningOrders.length ? (
          <HousekeepingNoOrdersRow colSpan={10} />
        ) : isListView ? (
          <>
            {cleaningOrders.map(cleaningOrder => (
              <HousekeepingCleaningOrderToCheckRow
                cleaningOrder={cleaningOrder}
                key={cleaningOrder.id}
                tableView={tableView}
              />
            ))}
          </>
        ) : (
          <HousekeepingCleaningOrderToCheckGroupedRow cleaningOrders={cleaningOrders} />
        )}
      </tbody>
    </table>
  )
}
