import * as React from 'react'
import { ImprovementsStatsDetails } from '@modules/reservations/models'
import { ReservationStatsTableHeader } from '@modules/reservations/statistics/common/common-stats-table-header'
import dateHelper from '@helpers/date-helper'

interface Props {
  details: ImprovementsStatsDetails[]
  hoveredDate: string | null
  handleHoveredElementChange: (date: string) => void
}

export const ImprovementStatsTableHeader = ({
  details,
  hoveredDate,
  handleHoveredElementChange,
}: Props): JSX.Element => {
  const headers = React.useMemo(
    () =>
      details.map((statsDetails: ImprovementsStatsDetails) => (
        <ReservationStatsTableHeader
          hoveredDate={hoveredDate}
          onHoverChange={handleHoveredElementChange}
          key={statsDetails.day}
          date={dateHelper.parseISODate(statsDetails.day)}
        />
      )),
    [details, hoveredDate],
  )

  return (
    <thead>
      <tr>
        <th className="bg-white reservation-stats__col__first" />
        {headers}
      </tr>
    </thead>
  )
}
