import classNames from 'classnames'
import * as React from 'react'

export type TableViewType = 'list' | 'grid'

interface Props {
  tableView: TableViewType
  setTableView: (type: TableViewType) => void
}

export const OrderTableViewSwitch: React.FC<Props> = ({ tableView = 'list', setTableView }) => {
  const handleSwitch = React.useCallback((type: TableViewType) => () => setTableView(type), [setTableView])

  return (
    <div className="ml-auto mt-1">
      <div className="d-flex">
        <ViewButton onClick={handleSwitch('list')} icon="uil-bars font-16" isActive={tableView === 'list'} />
        <ViewButton onClick={handleSwitch('grid')} icon="uil-grids font-16" isActive={tableView === 'grid'} />
      </div>
    </div>
  )
}

const ViewButton = ({ onClick, isActive, icon }) => (
  <button
    onClick={onClick}
    className={classNames(
      'shadow-none d-flex align-items-center justify-content-center rounded border px-1',
      isActive ? 'bg-dark-grey text-white' : 'bg-white text-muted',
    )}
  >
    <i className={icon} />
  </button>
)
