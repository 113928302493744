import { BookingStatus } from '@models/booking'

export const getBookingStatusClass = (status: BookingStatus, checked_in: boolean) => {
  if (checked_in) {
    return 'badge-pink'
  }

  if (status === 'close') {
    return 'badge-danger'
  }

  return (
    {
      initial: 'badge-info',
      removed: 'badge-danger',
      confirmed: 'badge-success',
      confirmed_canceled: 'badge-gray',
      unfinished_canceled: 'badge-gray',
      initial_cancelled: 'badge-gray',
    }[status] || 'badge-warning'
  )
}
