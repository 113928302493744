import * as React from 'react'
import classNames from 'classnames'

interface Props {
  title?: string | React.ReactElement
  titleClassName?: string
  children: React.ReactNode
}

export const ReceptionBookingBox: React.FC<Props> = ({ children, title, titleClassName }) => (
  <div className="p-3 mb-3 bg-platinum border-gray">
    {title && <strong className={classNames('d-block mb-2 font-15 text-primary', titleClassName)}>{title}</strong>}
    {children}
  </div>
)
