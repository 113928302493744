import * as React from 'react'
import { TechnicalOrder } from '@models/technical-orders'
import Table from '@components/table/table'
import { secondsToInterval, toDefaultDateTimeFormat } from '@helpers/date-helper'
import { TechnicalOrdersDoneTableRowWorkTimeLive } from '@modules/technical-orders/technical-orders-done/row-work-time-live'
import { IconWithText } from '@components/icon-with-text'
import classNames from 'classnames'

interface Props {
  technicalOrder: TechnicalOrder
}

export const TechnicalOrdersDoneTableRowWorkTime: React.FC<Props> = ({ technicalOrder }) => {
  const getCustomTotalWorkingTime = () => {
    if (!technicalOrder.custom_total_working_time) return ''
    const [hours, minutes, seconds] = technicalOrder.custom_total_working_time.split(':')
    return `${hours}h ${minutes}m ${seconds}s`
  }

  return (
    <Table.Cell className="vertical-align-middle" width={180}>
      {technicalOrder.status === 'in_progress' ? (
        <>
          {technicalOrder.is_paused ? (
            <span className="d-block">{secondsToInterval(technicalOrder.total_working_time)}</span>
          ) : (
            <TechnicalOrdersDoneTableRowWorkTimeLive technicalOrder={technicalOrder} />
          )}
          {technicalOrder.started_at && (
            <IconWithText
              icon={classNames({
                'uil-play': !technicalOrder.is_paused,
                'uil-pause': technicalOrder.is_paused,
              })}
              text={<span className="font-10">Start: {toDefaultDateTimeFormat(technicalOrder.started_at)}</span>}
            />
          )}
        </>
      ) : (
        <>
          <span className="text-strong">
            {technicalOrder.custom_total_working_time
              ? getCustomTotalWorkingTime()
              : technicalOrder.total_working_time
                ? secondsToInterval(technicalOrder.total_working_time)
                : '-'}
          </span>
          {technicalOrder.finished_at && (
            <span className="d-block font-10">Koniec: {toDefaultDateTimeFormat(technicalOrder.finished_at)}</span>
          )}
        </>
      )}
    </Table.Cell>
  )
}
