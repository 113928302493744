import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@store/index'
import { Agreement, AgreementDetails, HRAppData } from '@modules/hr/agreement/models'
import { getAgreementDetails, getAgreements, getHighPriorityAgreements } from '@store/actions/hr-action'

interface State {
  agreements: EntityState<Agreement>
  highPriorityAgreements: EntityState<Agreement>
  agreementDetails: null | AgreementDetails
}

const agreementsAdapter = createEntityAdapter<Agreement>()
const highPriorityAgreementsAdapter = createEntityAdapter<Agreement>()

const initialState: State = {
  agreements: agreementsAdapter.getInitialState(),
  highPriorityAgreements: highPriorityAgreementsAdapter.getInitialState(),
  agreementDetails: null,
}

export const hrSlice = createSlice({
  name: 'hr',
  initialState,
  reducers: {
    updateAgreementDetails(state, action: PayloadAction<AgreementDetails | null>) {
      if (state.agreementDetails && action.payload && state.agreementDetails.id !== action.payload.id) return

      state.agreementDetails = action.payload
      if (!action.payload) return

      const unshiftAgreement = (key: string, agreement: Agreement) => {
        if (!state[key].entities[agreement.id]) {
          state[key].ids.unshift(agreement.id)
        }
        state[key].entities[agreement.id] = agreement
      }

      if (action.payload.high_priority) {
        agreementsAdapter.removeOne(state.agreements, action.payload.id)
        unshiftAgreement('highPriorityAgreements', action.payload)
      } else {
        highPriorityAgreementsAdapter.removeOne(state.highPriorityAgreements, action.payload.id)
        unshiftAgreement('agreements', action.payload)
      }
    },
    clearAgreements(state) {
      agreementsAdapter.removeAll(state.agreements)
      highPriorityAgreementsAdapter.removeAll(state.highPriorityAgreements)
    },
    clearAgreementDetails(state) {
      state.agreementDetails = null
    },
    removeAgreement(state, action) {
      agreementsAdapter.removeOne(state.agreements, action.payload)
      highPriorityAgreementsAdapter.removeOne(state.highPriorityAgreements, action.payload)
    },
    upsertAgreement(state, action) {
      agreementsAdapter.upsertOne(state.agreements, action.payload)
    },
  },
  extraReducers: builder => {
    builder.addCase(getAgreements.fulfilled, (state, action) => {
      agreementsAdapter.setAll(state.agreements, action.payload)
    })
    builder.addCase(getHighPriorityAgreements.fulfilled, (state, action) => {
      highPriorityAgreementsAdapter.setAll(state.highPriorityAgreements, action.payload)
    })
    builder.addCase(getAgreementDetails.fulfilled, (state, action) => {
      state.agreementDetails = action.payload
    })
  },
})

export const { clearAgreements, updateAgreementDetails, upsertAgreement, clearAgreementDetails, removeAgreement } =
  hrSlice.actions

export const { selectAll: selectAgreements } = agreementsAdapter.getSelectors(
  (state: RootState) => state.hrState.agreements,
)

export const { selectAll: selectHighPriorityAgreements } = highPriorityAgreementsAdapter.getSelectors(
  (state: RootState) => state.hrState.highPriorityAgreements,
)

export const selectAgreementDetails = (state: RootState): AgreementDetails | null => state.hrState.agreementDetails
export const selectHRAppData = (state: RootState): HRAppData => state.hrState.appData

export default hrSlice.reducer
