import * as React from 'react'
import { ReceptionBooking } from '@models/reception'
import { useModal } from '@components/modals/use-modal'

interface ReceptionBookingCheckOutProps {
  booking: ReceptionBooking
  onClose: () => void
}

export const ReceptionBookingCheckOutPreview: React.FC<ReceptionBookingCheckOutProps> = ({ booking, onClose }) => {
  const [showCheckOutPreviewModal] = useModal('ReceptionBookingCheckOutPreviewDialog', { booking }, { onClose })

  React.useEffect(() => {
    showCheckOutPreviewModal()
  }, [])

  return null
}
