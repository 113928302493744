import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { differenceInDays, format, parseISO } from 'date-fns'
import { parseISODate } from '@helpers/date-helper'
import TimelineItemTooltipBlockadeDetails from '@modules/reservations/timeline/item-tooltip/timeline-item-tooltip-blockade-details'
import TimelineItemTooltipBookingDetails from '@modules/reservations/timeline/item-tooltip/timeline-item-tooltip-booking-details'
import { BookingStatus } from '@models/booking'
import TimelineItemTooltipBlockadeDetailsPlaceholder from '@modules/reservations/timeline/item-tooltip/timeline-item-tooltip-blockade-details-placeholder'
import TimelineItemTooltipDetailsPlaceholder from '@modules/reservations/timeline/item-tooltip/timeline-item-tooltip-details-placeholder'
import classNames from 'classnames'

interface Props {
  details: ReceptionBookingDetails | undefined
  status: BookingStatus
  isExtendedStay: boolean
  isReadOnly: boolean
}

export const TimelineItemTooltipDetails = ({ details, status, isReadOnly, isExtendedStay }: Props): JSX.Element => {
  if (!details)
    return status === 'blocked' ? (
      <TimelineItemTooltipBlockadeDetailsPlaceholder />
    ) : (
      <TimelineItemTooltipDetailsPlaceholder status={status} isExtendedStay={isExtendedStay} />
    )

  const reformatDate = (date: string): string => format(parseISO(date), 'dd.MM')

  const numberOfNights = differenceInDays(
    parseISODate(details.date_to) as Date,
    parseISODate(details.date_from) as Date,
  )

  return (
    <a href={details.urls.details} className={classNames({ 'pe-none': isReadOnly })}>
      {details.status === 'blocked' ? (
        <TimelineItemTooltipBlockadeDetails
          details={details}
          status={status}
          reformatDate={reformatDate}
          numberOfNights={numberOfNights}
        />
      ) : (
        <TimelineItemTooltipBookingDetails
          details={details}
          status={status}
          isExtendedStay={isExtendedStay}
          reformatDate={reformatDate}
          numberOfNights={numberOfNights}
        />
      )}
    </a>
  )
}
