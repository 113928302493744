import * as React from 'react'
import { FormPlain } from '@hyper/forms'
import { CustomInput } from 'reactstrap'
import { extractInnerRef } from '@helpers/forms'
import { SubscriptionOptionKindPrice, SubscriptionType } from '@modules/subscription/models'
import { useFormContext } from 'react-hook-form'
import { formatPriceShort } from '@helpers/utils'
import { useSubscriptionTypeStrategyProvider } from '@modules/subscription/hooks/use-subscription-type-provider'
import { EntranceTicketOption } from '@modules/subscription/create/options/entrance-ticket-option'

interface Props {
  isEditDisabled?: boolean
  prices: SubscriptionOptionKindPrice
  type: SubscriptionType
}

export const SubscriptionOptionsForm = ({ isEditDisabled, prices, type }: Props): JSX.Element => {
  const { register } = useFormContext()

  const { allow_options } = useSubscriptionTypeStrategyProvider(type)

  return (
    <div className="pb-2 col-md-12 pl-0">
      <strong className="px-2 my-1 font-15">Opcje dodatkowe:</strong>
      <div className="mb-2">
        {allow_options.includes('change_data') && (
          <FormPlain name="allow_change_client_data" formGroupClassName="mb-0">
            <CustomInput
              disabled={isEditDisabled}
              className="mt-2"
              type="checkbox"
              id="allow_change_client_data"
              label={`Możliwość zmiany danych ${prices.client_data ? formatPriceShort(prices.client_data) : ''}`}
              {...extractInnerRef(register('allow_change_client_data'))}
            />
          </FormPlain>
        )}
        {allow_options.includes('localization') && (
          <FormPlain name="allow_subscription_code_localization_convert" formGroupClassName="mb-0">
            <CustomInput
              disabled={isEditDisabled}
              className="mt-2"
              type="checkbox"
              id="allow_subscription_code_localization_convert"
              label={`Możliwość zmiany lokalizacji ${prices.localization ? formatPriceShort(prices.localization) : ''}`}
              {...extractInnerRef(register('allow_subscription_code_localization_convert'))}
            />
          </FormPlain>
        )}
      </div>
      {allow_options.includes('zoo_borysew') && (
        <EntranceTicketOption kind="zoo_borysew" prices={prices} isEditDisabled={isEditDisabled} />
      )}
      {allow_options.includes('suntago') && (
        <EntranceTicketOption kind="suntago" prices={prices} isEditDisabled={isEditDisabled} />
      )}
    </div>
  )
}
