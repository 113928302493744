import * as React from 'react'
import classname from 'classnames'
import { isToday } from 'date-fns'
import dateHelper from '@helpers/date-helper'

interface ReservationStatsTableDataCellProps {
  date: string
  children?: React.ReactNode
  className?: string
  onHoverChange: (date: string | null) => void
  hoveredDate: string | null
}

export const ReservationStatsTableDataCell: React.FC<ReservationStatsTableDataCellProps> = React.memo(
  ({ date, children, className, onHoverChange, hoveredDate }) => (
    <td
      onMouseEnter={() => onHoverChange(date)}
      onMouseLeave={() => onHoverChange(null)}
      className={classname(className, 'text-center reservation-stats__empty-cell', {
        'reservation-stats__col--today': isToday(dateHelper.parseISODate(date)),
        'reservation-stats__col--hovered': hoveredDate === date,
      })}
    >
      {children}
    </td>
  ),
)
