import * as React from 'react'
import { useForm } from 'react-hook-form'
import { Button, Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useFormRequest } from '@components/hooks/use-api-request'
import { CmsImprovement } from '@modules/cms/models'
import { FormInput, FormPlain } from '@hyper/forms'
import { updateImprovement } from '@api/cms'
import { useCmsAppData } from '@modules/cms/hooks/use-cms-app-data'
import { FileUploader, UploaderAcceptableFile } from '@components/file-uploader'
import { BaseModalProps } from '@components/modals/types'
import { useAppDispatch } from '@store/index'
import { setCmsResortImprovement } from '@store/slices/cms-reservation-app-slice'
import { Form } from '@hyper/forms/form'

interface Props extends BaseModalProps {
  improvement: CmsImprovement
}

export interface FormInputs {
  description: string
  name: string
  reservation_group_name: string
  group_id: number | null
}

export const CmsReservationAppImprovementModal: React.FC<Props> = ({ toggleIsVisible, improvement }) => {
  const methods = useForm<FormInputs>({
    defaultValues: improvement,
  })
  const { addSuccessMessage } = useNotificationHook()
  const [image, setImage] = React.useState()
  const dispatch = useAppDispatch()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    await dispatch(setCmsResortImprovement(await updateImprovement(improvement.urls.details, { ...payload, image })))

    addSuccessMessage('Sukces', `Udoskonalenie ${payload.name} zostało zmienione`)
    toggleIsVisible()
  }, methods.setError)
  const { improvement_groups } = useCmsAppData()
  const upload = file => setImage(file)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>
        Edytuj udoskonalenie <span className="text-semi-strong">{improvement.name}</span>
      </ModalHeader>
      <ModalBody className="pb-0">
        <Row>
          <FormInput label="Nazwa" name="name" />
          <FormInput label="Grupa" name="group_id" type="select">
            <option value="">brak</option>
            {improvement_groups.map(row => (
              <option key={row.id} value={row.id}>
                {row.name}
              </option>
            ))}
          </FormInput>
          <FormInput label="Grupa na widoku ulepszeń" name="reservation_group_name" />

          <FormInput label="Opis" name="description" type="textarea" />
          {(image || improvement?.image) && (
            <FormPlain name="image">
              <label>Aktualne zdjęcie:</label>
              <div className="mt-1">
                <img
                  src={image ? URL.createObjectURL(image) : improvement?.image}
                  className="avatar-xl img-thumbnail"
                />
              </div>
            </FormPlain>
          )}
          <Col md={12} className="mb-3">
            <FileUploader
              isLoading={isLoading}
              upload={upload}
              maxFiles={1}
              file={image}
              accept={UploaderAcceptableFile.Images}
              label="Przeciągnij i upuść zdjęcie lub kliknij by dodać zdjęcie"
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>

        <SaveButton className="btn btn-green" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
