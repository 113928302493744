import * as React from 'react'
import { Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useFormRequest } from '@components/hooks/use-api-request'
import { useForm } from 'react-hook-form'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { FormInput } from '@hyper/forms'
import { FileUploader } from '@components/file-uploader'
import { BaseModalProps } from '@components/modals/types'
import { Form } from '@hyper/forms/form'
import { v4 as uuidv4 } from 'uuid'
import { createDocuments } from '@store/actions/dashboard-actions'

interface Document {
  id: string
  file: File
}

interface Props extends BaseModalProps {
  handleOnSave: () => void
  url: string
  maxFiles?: number
}

export interface DocumentCreateModalFormInputs {
  files: File[]
  description: string
}

export const CreateDocumentModal: React.FC<Props> = ({ toggleIsVisible, handleOnSave, url, maxFiles = 1 }) => {
  const [documents, setDocuments] = React.useState<Document[]>([])

  const { addSuccessMessage } = useNotificationHook()

  const methods = useForm<DocumentCreateModalFormInputs>({
    defaultValues: { description: '', files: [] },
  })

  const isSingleFileUploader = maxFiles === 1

  const handleFilesUpload = (file: File) => {
    setDocuments(prevState => [...prevState, { file, id: uuidv4() }])
  }

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: DocumentCreateModalFormInputs) => {
    await createDocuments({ files: documents.map(document => document.file), url, description: payload.description })
    addSuccessMessage('Sukces', 'Dokument został utworzony!')
    handleOnSave()
    toggleIsVisible()
  }, methods.setError)

  const handleRemoveFile = (document: Document) => {
    setDocuments(prevState => prevState.filter(stateDocument => stateDocument.id !== document.id))
  }

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Dodaj dokument</ModalHeader>
      <ModalBody>
        <Row>
          {isSingleFileUploader && (
            <FormInput
              label="Opis:"
              labelProps={{ className: 'text-semi-strong' }}
              formGroupClassName="mb-1"
              name="description"
            />
          )}
          <Col md={12}>
            {!!documents.length && !isSingleFileUploader && (
              <>
                <p className="fw-semi-bold font-14 mb-1">Dodane dokumenty:</p>
                <ul className="list-unstyled custom-list">
                  {documents.map(document => (
                    <li key={document.id} className="d-flex">
                      <div className="col-8 pl-0">{document.file.name}</div>
                      <div className="col-4 pl-0 text-right">
                        <i className="uil-trash-alt cursor-pointer" onClick={() => handleRemoveFile(document)} />
                      </div>
                    </li>
                  ))}
                </ul>
              </>
            )}

            <FileUploader
              isLoading={isLoading}
              upload={handleFilesUpload}
              label={
                documents.length && !isSingleFileUploader ? 'Przeciągnij lub kliknij by dodać kolejne pliki' : undefined
              }
              file={isSingleFileUploader && documents.length ? documents[0].file : undefined}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <SaveButton role="submit" className="btn btn-green" label="Zapisz" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
