import * as React from 'react'
import { BeddingOrder, CleaningOrder, ImprovementOrder } from '@modules/housekeeping/models'
import { Button, ModalFooter, ModalHeader } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { FormProvider, useForm } from 'react-hook-form'
import ModalBody from 'reactstrap/lib/ModalBody'
import { commonObjectPut } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { FormHousekeepingOrderWorkerAssignField } from '@modules/housekeeping/cleaning-orders/common/form-housekeeping-order-worker-assign-field'
import { useFormRequest } from '@components/hooks/use-api-request'
import { BaseModalProps } from '@components/modals/types'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { HousekeepingWorkerRoles } from '@modules/housekeeping/users/utils'
import {
  HousekeepingOrdersToAssignBox,
  HousekeepingServiceKind,
} from '@modules/housekeeping/cleaning-orders/common/orders-to-assign-box'

interface Props extends BaseModalProps {
  selectedOrders: CleaningOrder[] | BeddingOrder[] | ImprovementOrder[]
  onlyHprCompany?: boolean
  resortId: number
  canSelectMultipleWorkers?: boolean
  workerRoles?: HousekeepingWorkerRoles[]
  serviceKind: HousekeepingServiceKind
}

export interface HousekeepingCleaningOrderGroupAssignModalFormInputs {
  users: (CustomReactSelectOption | string)[]
  company: CustomReactSelectOption | null
}

export const HousekeepingCleaningOrderGroupAssignModal: React.FC<Props> = ({
  selectedOrders,
  toggleIsVisible,
  onlyHprCompany,
  resortId,
  canSelectMultipleWorkers = true,
  workerRoles,
  serviceKind,
}) => {
  const methods = useForm<HousekeepingCleaningOrderGroupAssignModalFormInputs>({
    defaultValues: { users: [], company: null },
  })

  const { addSuccessNotification } = useNotificationHook()

  const { isLoading, action: onSubmit } = useFormRequest(
    async (payload: HousekeepingCleaningOrderGroupAssignModalFormInputs) => {
      for (const order of selectedOrders) {
        await commonObjectPut(order.urls.assign, {
          company: payload.company?.value,
          users: canSelectMultipleWorkers
            ? payload.users.filter(Boolean) || []
            : payload.users.map((user: CustomReactSelectOption) => user.value),
        })
      }
      addSuccessNotification('Zlecenia zostały przypisane')
      toggleIsVisible()
    },
    methods.setError,
  )

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalHeader className="px-3" toggle={toggleIsVisible}>
          Przypisz zlecenia ({selectedOrders.length})
        </ModalHeader>
        <ModalBody className="px-3 pt-0">
          <HousekeepingOrdersToAssignBox orders={selectedOrders} serviceKind={serviceKind} />
          <FormHousekeepingOrderWorkerAssignField
            resortId={resortId}
            onlyHprCompany={onlyHprCompany}
            label="Wybierz firmę"
            canSelectMultipleWorkers={canSelectMultipleWorkers}
            workerRoles={workerRoles}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="light" type="button" onClick={toggleIsVisible}>
            Zamknij
          </Button>
          <SaveButton className="btn btn-green" label="Przydziel" labelSaving="Przydzielanie.." isSaving={isLoading} />
        </ModalFooter>
      </form>
    </FormProvider>
  )
}
