import * as React from 'react'
import { ClientSelect } from '@components/client-select'
import { ClientUser } from '@models/clients'
import { useModal } from '@components/modals/use-modal'
import { SpinInput } from '@components/spin-input'
import { formatPrice } from '@helpers/utils'
import { ButtonWithIcon } from '@components/button-with-icon'
import { ReceptionBookingImprovementSubscriptionFormInputs } from '@modules/reception/checkin/step-improvements/improvement-subscription/modal/reception-booking-improvement-subscription-modal'
import { useFormContext, useWatch } from 'react-hook-form'
import { ImprovementAvailability } from '@models/booking'
import { getVoucherTotalPrice } from '@modules/reception/checkin/step-improvements/improvement-subscription/helpers'

interface Props {
  index: number
  onRemove?: () => void
  onUpdate: (client: ClientUser | null) => void
  subscriptionImprovementAvailability: ImprovementAvailability
}

export const ReceptionBookingImprovementSubscriptionModalVoucherRow = ({
  onRemove,
  onUpdate,
  index,
  subscriptionImprovementAvailability,
}: Props): React.ReactNode => {
  const [showClientDialog] = useModal('ClientDialog', { setClient: onUpdate })

  const { control } = useFormContext<ReceptionBookingImprovementSubscriptionFormInputs>()
  const voucher = useWatch({ control, name: `vouchers.${index}` })

  const totalPrice = getVoucherTotalPrice(voucher.amount, subscriptionImprovementAvailability)

  return (
    <div className="bg-light-grey py-2 mt-2">
      <div className="d-flex align-items-start">
        <div className="col-4 font-weight-bold pt-2">Osoba zamawiająca:</div>
        <ClientSelect
          prefetchedClientDetails={index === 0}
          additionalClientsElementsToShow={[]}
          client={voucher.client ?? undefined}
          setClient={onUpdate}
          handleToggleClientDialog={showClientDialog}
          label=""
          colSize={8}
          formGroupClassName="mb-0"
        />
      </div>
      <div className="d-flex align-items-center mt-1">
        <div className="col-4 font-weight-bold">Liczba voucherów:</div>
        <div className="pl-2 d-flex align-items-center">
          <SpinInput inputName={`vouchers.${index}.amount`} inputProps={{ min: 1, max: 999 }} />
          <span className="ml-2">
            Do zapłaty: <strong>{formatPrice(totalPrice)}</strong>
          </span>
        </div>
      </div>
      {onRemove && (
        <div className="col-8 ml-auto">
          <ButtonWithIcon
            icon="uil-trash-alt"
            text="Usuń"
            color="outline-danger"
            btnClass="btn btn-outline-danger btn-flat mt-2 d-block"
            handleClick={onRemove}
          />
        </div>
      )}
    </div>
  )
}
