import * as React from 'react'
import { TechnicalOrdersInProgressTable } from '@modules/technical-orders/technical-orders-in-progress/table'

interface Props {
  resortId: number
}

export const TechnicalOrdersInProgress: React.FC<Props> = ({ resortId }) => (
  <>
    <h4 className="text-danger">Zadania priorytetowe:</h4>
    <TechnicalOrdersInProgressTable
      priority={10}
      resortId={resortId}
      dataTestId="qa-table-technical-orders-high-priority"
    />
    <h4>Pozostałe zadania:</h4>
    <TechnicalOrdersInProgressTable
      priority={0}
      resortId={resortId}
      dataTestId="qa-table-technical-orders-normal-priority"
    />
  </>
)
