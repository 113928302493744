import * as React from 'react'
import Table from '@components/table/table'
import { useModal } from '@components/modals/use-modal'
import { SaveButton } from '@hyper/button'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { commonObjectPatch } from '@store/actions/generic-actions'
import { PackageCode } from '@models/package'
import { updatePackageDetails } from '@store/slices/package-slice'

interface Props {
  packageCode: PackageCode
}

export const PackageDetailsCodesRowActions: React.FC<Props> = ({ packageCode }) => {
  const packageDetails = useAppSelector((state: RootState) => state.packageState.packageDetails)

  const [handleConvert] = useModal('PackageCodeConvertModal', { packageCode, packageDetails })
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading: isRenewLoading, action: handleRenew } = useApiRequest(async () => {
    dispatch(updatePackageDetails(await commonObjectPatch(packageCode.urls.renew)))
    addSuccessMessage('Sukces', `Kod ${packageCode.code} został odnowiony!`)
  })

  return (
    <Table.Cell style={{ width: 10 }} className="text-nowrap">
      {packageCode.is_used && !packageCode.is_splitted && (
        <SaveButton
          type="button"
          isSaving={isRenewLoading}
          onClick={handleRenew}
          className="btn btn-xs btn-danger"
          label="Odnów"
          labelSaving="Odnawianie"
        />
      )}

      {!packageCode.is_used && packageCode.type === 'default' && (
        <button onClick={handleConvert} type="button" className="btn btn-xs btn-green">
          Konwertuj
        </button>
      )}
    </Table.Cell>
  )
}
