import * as React from 'react'
import { Document } from '@models/dashboard'
import { Card, CardBody, Row } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { DocumentListRow } from '@components/documents/docmuent-row'
import { ColAuto } from '@hyper/col-auto'
import { ButtonWithIcon } from '@components/button-with-icon'

const headerValues: TableHeaderValue[] = [
  {
    title: 'Nazwa',
  },
  {
    title: 'Dodano',
  },
  {
    title: 'Dodał',
  },
  {
    title: '',
  },
]

const defaultFilters: TableFilters = {
  ordering: '-created',
  page: 1,
  page_size: 50,
  search: '',
}

interface Props {
  documents: Document[]
  handleOnSave: () => void
  url: string
  isReadonly?: boolean
  title?: string
  addButtonText?: string
  maxFiles?: number
}

export const DocumentList: React.FC<Props> = ({
  documents,
  handleOnSave,
  url,
  isReadonly,
  title = 'Dokumenty',
  addButtonText = 'Dodaj dokument',
  maxFiles,
}) => {
  const [handleDocumentModal] = useModal('AddDocumentModal', { handleOnSave, url, maxFiles })
  const [filters, setFilters] = React.useState<TableFilters>(defaultFilters)

  return (
    <Card>
      <CardBody>
        <Row className="align-items-center mb-1">
          <ColAuto>
            <h4 className="my-0 text-secondary">{title}</h4>
          </ColAuto>
          {!isReadonly && (
            <ColAuto className="ml-auto col-auto">
              <ButtonWithIcon
                role="create-document-button"
                icon="uil-plus mr-1"
                text={addButtonText}
                handleClick={handleDocumentModal}
                color="outline-secondary"
              />
            </ColAuto>
          )}
        </Row>
        <Table
          showPagination={false}
          className="table-hover w-100"
          headerValues={headerValues}
          isLoading={false}
          filters={filters}
          setFilters={setFilters}
        >
          {documents.map(document => (
            <DocumentListRow
              isReadonly={isReadonly}
              key={document.id}
              document={document}
              handleOnSave={handleOnSave}
            />
          ))}
        </Table>
      </CardBody>
    </Card>
  )
}
