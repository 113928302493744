import * as React from 'react'
import { FormInput, FormPlain } from '@hyper/forms'
import { CustomInput, Label, Row } from 'reactstrap'
import { useFormContext } from 'react-hook-form'
import { FeedingSpecialGuestDialogFormInputs } from '@modules/feeding/special-guests/dialog/index'
import { RootState, useAppSelector } from '@store/index'
import DatePickerInput from '@components/date/date-picker-input'
import { extractInnerRef } from '@helpers/forms'

export const FeedingSpecialGuestDialogForm: React.FC = () => {
  const { register } = useFormContext<FeedingSpecialGuestDialogFormInputs>()
  const resorts = useAppSelector((state: RootState) => state.appState.appData.resorts)

  return (
    <Row>
      <FormInput label="Imię i nazwisko" name="name" />
      <FormInput label="Ośrodek" name="resorts" type="select" multiple={true}>
        {resorts.map(resort => (
          <option key={resort.id} value={resort.id}>
            {resort.name}
          </option>
        ))}
      </FormInput>
      <FormPlain name="date_to">
        <Label>Ważny do</Label>
        <DatePickerInput name="date_to" wrapperClassName="flex-grow" />
      </FormPlain>
      <FormPlain name="is_active">
        <CustomInput
          type="checkbox"
          id="is_active"
          label={<span className="text-semi-strong cursor-pointer">Aktywny</span>}
          value={true}
          {...extractInnerRef(register('is_active'))}
        />
      </FormPlain>
    </Row>
  )
}
