import * as React from 'react'
import { ClientDetails } from '@models/clients'
import { Card, CardBody, Col } from 'reactstrap'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { useSortedRows } from '@components/table/use-sorted-rows'
import { SubscriptionContract } from '@modules/subscription-contract/models'
import { SubscriptionContractRow } from '@modules/crm/clients/details-modal/subscription-contracts/subscription-contract-row'

export const headerValues: TableHeaderValue[] = [
  { title: 'Numer', sortField: 'number' },
  { title: 'Status', sortField: 'status' },
  { title: 'Źródło marketingowe' },
  { title: 'Data rozpoczęcia subskrypcji' },
  { title: 'Sprzedawca', sortField: 'seller' },
]

interface Props {
  client: ClientDetails
}

export const ClientDetailsModalSubscriptionContracts: React.FC<Props> = ({ client }) => {
  const [filters, setFilters] = React.useState<TableFilters>({
    search: '',
    ordering: 'number',
    page: 1,
    page_size: 9999,
  })

  const sortedSubscriptionContracts = useSortedRows<SubscriptionContract>(
    client.subscription_contracts,
    filters.ordering,
  )

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <h4 className="mb-2 text-secondary">Subskrypcje</h4>
          <Table
            className="table-hover w-100"
            headerValues={headerValues}
            showPagination={false}
            isLoading={false}
            filters={filters}
            emptyText="Brak subskrypcji"
            setFilters={setFilters}
          >
            {sortedSubscriptionContracts.map((subscriptionContract: SubscriptionContract) => (
              <SubscriptionContractRow key={subscriptionContract.id} subscriptionContract={subscriptionContract} />
            ))}
          </Table>
        </CardBody>
      </Card>
    </Col>
  )
}
