import * as React from 'react'
import { UncontrolledTooltip } from 'reactstrap'
import { PromocodeGroup } from '@models/promocode'

interface PromocodeGroupRowCreatedProps {
  group: PromocodeGroup
}

export const PromocodeGroupRowCreated: React.FC<PromocodeGroupRowCreatedProps> = ({ group }) => {
  const tooltipId = `tooltipCreated${group.id}`

  return (
    <>
      <span id={tooltipId}>
        <i className="uil-plus-square mr-1 text-muted font-18 text-strong" />
        {group.created_at}
      </span>

      <UncontrolledTooltip placement="top" target={tooltipId}>
        Utworzony przez <strong>{group.created_by}</strong>
      </UncontrolledTooltip>
    </>
  )
}
