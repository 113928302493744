import * as React from 'react'
import { useHousekeepingAppData } from '@modules/housekeeping/hooks/use-housekeeping-app-data'
import { FormCheckbox } from '@hyper/forms/form-checkbox'
import { useFormContext, useWatch } from 'react-hook-form'

interface Props {
  resortId: number
}

export const ReceptionTechnicalOrderStatusFormUsers: React.FC<Props> = ({ resortId }) => {
  const { housekeeping_users } = useHousekeepingAppData(resortId)
  const users = React.useMemo(
    () => housekeeping_users.filter(row => row.is_housekeeping_handyman),
    [housekeeping_users],
  )

  const { control } = useFormContext()

  const assignToUsers = useWatch({ control, name: 'assignToUsers' })

  return (
    <div className="ml-3 mb-1">
      <FormCheckbox name="assignToUsers" label="Przydziel zadanie do pracownika" />
      <span className="d-block font-11 text-body ml-3 mb-2">(opcjonalne)</span>
      {assignToUsers && (
        <div className="ml-3 mb-1">
          <strong className="d-block mb-1">Wybierz pracowników:</strong>
          {users.map(worker => (
            <span key={worker.id} title={worker.email}>
              <FormCheckbox name={`users.user__${worker.id}`} label={worker.name} className="mb-1" />
            </span>
          ))}
        </div>
      )}
    </div>
  )
}
