import * as React from 'react'
import { FeedingClient } from '@modules/feeding/models'
import { PriceBlock } from '@modules/reservations/list/reservation-list-payment-summary'
import { asDecimal, formatPrice, sumDecimalArray } from '@helpers/utils'
import Table from '@components/table/table'

interface Props {
  clients: FeedingClient[]
}

export const FeedingClientsTableFooter: React.FC<Props> = ({ clients }) => {
  const totalBrutto = sumDecimalArray(clients.map(row => asDecimal(row.price_brutto)))

  return (
    <Table.Row>
      <Table.Cell colSpan={6} className="text-right align-middle reservation-list__summary-cell">
        <strong className="mr-2">Razem w wybranym okresie:</strong>
      </Table.Cell>
      <Table.Cell colSpan={2} className="reservation-list__summary-cell">
        <PriceBlock title="Kwota zakupu:" price={formatPrice(totalBrutto.toString())} />
      </Table.Cell>
    </Table.Row>
  )
}
