import { FeedingTypeFilter, ReceptionBooking } from '@models/reception'

export const filterByFeeding = (booking: ReceptionBooking, feeding: FeedingTypeFilter): boolean => {
  switch (feeding) {
    case 'all':
      return true
    case 'without_feeding':
      return booking.feeding === null
    case 'with_feeding':
      return booking.feeding !== null
    case 'breakfast':
      return booking.feeding === 'breakfast'
    case 'dinner':
      return booking.feeding === 'dinner'
    case 'breakfast_with_dinner':
      return booking.feeding === 'breakfast_with_dinner'
    default:
      return false
  }
}
