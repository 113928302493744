import * as React from 'react'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useApiRequest } from '@components/hooks/use-api-request'
import { getPaymentAppData } from '@store/actions/cash-actions'
import { PaymentAppData } from '@models/payments'
import { setPaymentAppData } from '@store/slices/cash-slice'

export const usePaymentAppData = (): PaymentAppData => {
  const appData = useAppSelector((state: RootState) => state.cashState.appData)
  const dispatch = useAppDispatch()

  const { action: fetch } = useApiRequest(async () => await dispatch(getPaymentAppData()))

  React.useEffect(() => {
    if (appData.status === 'unknown') {
      fetch()
      dispatch(setPaymentAppData({ ...appData, status: 'loading' }))
    }
  }, [appData.status])

  return appData
}
