import * as React from 'react'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import * as R from 'ramda'
import { useWebSocket } from '@components/hooks/use-web-socket'
import { DashboardCheckStats, NotificationEvents } from '@models/dashboard'
import { useAppDispatch } from '@store/index'
import { updateDashboardCheckStats } from '@store/slices/app-slice'

interface Props {
  checkStats: DashboardCheckStats[]
}

export const CheckStats: React.FC<Props> = ({ checkStats }) => {
  const user = useAuthenticatedUser()
  const resorts = user.resorts.map(row => row.id)
  const dispatch = useAppDispatch()

  const checkStat = R.reduce(
    (acc, value) => {
      acc.day_in += value.day_in
      acc.day_out += value.day_out
      acc.day_in_realized += value.day_in_realized
      acc.day_out_realized += value.day_out_realized
      acc.day_in_remained += value.day_in_remained
      acc.day_out_in += value.day_out_in
      acc.day_out_remained += value.day_out_remained
      return acc
    },
    {
      day_in: 0,
      day_out: 0,
      day_in_realized: 0,
      day_out_realized: 0,
      day_in_remained: 0,
      day_out_in: 0,
      day_out_remained: 0,
    },
    checkStats.filter(row => resorts.includes(row.resort_id)),
  )

  useWebSocket<DashboardCheckStats[]>(
    (message, payload) => {
      switch (message) {
        case NotificationEvents.CHECK_STATS_CHANGE: {
          const checkStats = payload as DashboardCheckStats[]
          dispatch(updateDashboardCheckStats(checkStats))
          break
        }
      }
    },
    [],
    [NotificationEvents.CHECK_STATS_CHANGE],
  )

  return (
    <div className="booking-checkinfo d-flex align-items-center">
      <div>
        <span className="text-green-dark h5">{checkStat.day_in_realized}</span> /
        <small>{checkStat.day_in} zameldowania</small> <br />
        <span className="text-danger h5">{checkStat.day_out_realized}</span> /
        <small>{checkStat.day_out} wymeldowania</small>
      </div>
    </div>
  )
}
