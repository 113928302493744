import * as React from 'react'
import classnames from 'classnames'
import { Spinner } from '@hyper/spinner'

interface ContentLoaderProps {
  isLoading: boolean
  children: React.ReactNode
  className?: string
  spinnerClassName?: string
}

export const ContentLoader: React.FC<ContentLoaderProps> = ({ isLoading, children, className, spinnerClassName }) => (
  <div className="position-relative">
    <div className={classnames('content-loader', { 'content-loader--loading': isLoading }, className)}>{children}</div>
    <div
      className={classnames('content-loader__spinner', spinnerClassName, {
        'content-loader__spinner--visible': isLoading,
      })}
    >
      {isLoading && <div role="test-loading" data-testid="test-loading" />}
      <Spinner />
    </div>
  </div>
)
