import * as React from 'react'
import { FormProvider, UseFormReturn } from 'react-hook-form'

interface Props {
  methods: UseFormReturn<any, any>
  children: React.ReactNode
  onSubmit: (payload: any) => void
  className?: string
  role?: string
}

export const Form = ({ className, children, onSubmit, methods, role }: Props): JSX.Element => (
  <FormProvider {...methods}>
    <form className={className} role={role} autoComplete="off" onSubmit={methods.handleSubmit(onSubmit)}>
      {children}
    </form>
  </FormProvider>
)
