import * as React from 'react'
import { CleaningOrder } from '@modules/housekeeping/models'
import { useHousekeepingWorkerData } from '@modules/housekeeping/cleaning-orders/common/use-housekeeping-worker-data'

interface Props {
  cleaningOrder: CleaningOrder
}

export const HousekeepingOrderAssignedWorkers = ({ cleaningOrder }: Props): JSX.Element => {
  const { workers, company } = useHousekeepingWorkerData(
    cleaningOrder.users,
    cleaningOrder.resort,
    cleaningOrder.company_id,
  )
  const users = React.useMemo(() => workers.map(user => user.name).join(', '), [workers])

  return (
    <td className="housekeeping__orders_table__column" width={200}>
      {users}
      <br />
      <small className="font-10">{company?.name}</small>
    </td>
  )
}
