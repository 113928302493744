import * as React from 'react'
import { BenefitProgramDetails } from '@modules/benefit-program/models'
import { User } from '@models/account'
import { BenefitProgramDetailsUsersTableRow } from '@modules/benefit-program/details/users/table-row'

interface Props {
  benefitProgram: BenefitProgramDetails
}

export const BenefitProgramDetailsUsersTable: React.FC<Props> = ({ benefitProgram }) => (
  <div>
    {benefitProgram.users.map((user: User) => (
      <BenefitProgramDetailsUsersTableRow benefitProgram={benefitProgram} user={user} key={user.id} />
    ))}
  </div>
)
