import * as React from 'react'
import { useModal } from '@components/modals/use-modal'

interface Props {
  id: string
}

export const CashbackVouchersDetailsView: React.FC<Props> = ({ id }) => {
  const [handleDetails, hidePreview] = useModal('CashbackVoucherModal', { id })

  React.useEffect(() => {
    if (id) {
      handleDetails()

      return () => {
        hidePreview()
      }
    }
  }, [id])

  return null
}
