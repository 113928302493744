import * as React from 'react'
import Table, { TableHeaderValue } from '@components/table/table'
import { BookingOffersTableRow } from '@modules/booking-offers/table-row'
import { BookingOffer } from '@models/booking-offers'
import { BookingOffersFiltersParams } from '@modules/booking-offers/index'

interface Props {
  bookingOffers: BookingOffer[]
  setFilters: React.Dispatch<React.SetStateAction<BookingOffersFiltersParams>>
  filters: BookingOffersFiltersParams
  isLoading: boolean
}

const headers: TableHeaderValue[] = [
  { title: 'Ośrodek', sortField: 'resort__name' },
  { title: 'Zakwaterowanie', sortField: 'accommodation_type' },
  { title: 'Data pobytu', sortField: 'date_from' },
  { title: 'Ilość dób', sortField: 'days' },
  { title: 'Kwota', sortField: 'price_brutto' },
  { title: '' },
]

export const BookingOffersTable: React.FC<Props> = ({ isLoading, filters, setFilters, bookingOffers }) => (
  <Table
    className="table-hover w-100"
    headerValues={headers}
    isLoading={isLoading}
    filters={filters}
    setFilters={setFilters}
  >
    {bookingOffers.map(bookingOffer => (
      <BookingOffersTableRow key={bookingOffer.id} bookingOffer={bookingOffer} />
    ))}
  </Table>
)
