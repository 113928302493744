import { RootState, useAppSelector } from '@store/index'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import * as React from 'react'
import { EservicePinPad } from '@models/eservice'

export const useEservicePinPadsList = (): EservicePinPad[] => {
  const resortId = useAppSelector((state: RootState) => state.receptionState.resortDetails?.id)
  const eServicePinPads = useAppSelector((state: RootState) => state.appState.appData.eservice_pin_pads)

  const user = useAuthenticatedUser()

  return React.useMemo(() => {
    let pinPads = eServicePinPads

    if (resortId) {
      pinPads = pinPads.filter(row => row.resort_id === resortId)
    }

    return pinPads.filter(row => user.resort_ids.includes(row.resort_id))
  }, [user, eServicePinPads, resortId])
}
