import * as React from 'react'
import { NavigationTabs, NavigationTabsButton } from '@components/navigation-tabs'
import { ScrollableModalTab, useModalWithScrollableTabs } from '@components/hooks/use-modal-with-scrollable-tabs'

type Tab = 'data' | 'voucher' | 'history' | 'codes' | 'payments' | 'transactions'

interface RefObject<T> {
  readonly current: T | null
}

interface Props {
  dataRef: RefObject<HTMLElement>
  historyRef: RefObject<HTMLElement>
}

export const PackageVipDetailsTabs: React.FC<Props> = ({ dataRef, historyRef }) => {
  const [tab, setTab] = React.useState<Tab>('data')

  const tabs: ScrollableModalTab<Tab>[] = [
    { ref: dataRef, name: 'data' },
    { ref: historyRef, name: 'history' },
  ]

  const { handleTabClick } = useModalWithScrollableTabs<Tab>({
    modalId: 'package-vip-details',
    setTab,
    tabs,
  })

  const buttons: NavigationTabsButton[] = [
    {
      title: 'Dane podstawowe i voucher',
      isActive: tab == 'data',
      onClick: handleTabClick('data'),
    },
    {
      title: 'Historia i notatki',
      isActive: tab == 'history',
      onClick: handleTabClick('history'),
    },
  ]

  return (
    <div className="modal-details__tabs-wrapper">
      <NavigationTabs buttons={buttons} />
    </div>
  )
}
