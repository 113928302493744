import * as React from 'react'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { BookingAutocompleteResponse } from '@components/booking-autosuggest'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectGet } from '@store/actions/generic-actions'
import { PaginationResponse } from '@models/dashboard'
import { useAppData } from '@components/hooks/use-app-data'
import { FormSelect } from '@hyper/forms/form-select'
import { createSelectOption } from '@helpers/utils'
import { formatDate } from '@helpers/date-helper'

interface Props {
  selectedResort?: CustomReactSelectOption
  apartment?: string
  bookingDefaultValues?: BookingAutocompleteResponse
}

export const TechnicalOrderFormBookings: React.FC<Props> = ({ selectedResort, apartment }) => {
  const [availableBookings, setAvailableBookings] = React.useState<CustomReactSelectOption[]>(() => [])
  const { urls } = useAppData()

  const { action: fetchBookings } = useApiRequest(async () => {
    const { results } = await commonObjectGet<PaginationResponse<BookingAutocompleteResponse>>(
      urls.rent.booking_autocomplete,

      { resort: selectedResort?.value, apartment, mode: 'technical_order' },
    )
    setAvailableBookings(
      results.map(row =>
        createSelectOption(
          <>
            {row.name} ({row.reservation_number})
            <span className="font-11 d-block mt-1">
              {formatDate(row.date_from, 'dd.MM')}-{formatDate(row.date_to, 'dd.MM.yyyy')}
            </span>
          </>,
          row.reservation_number,
        ),
      ),
    )
    return results
  })

  useDidUpdateEffect(() => {
    if (apartment) {
      fetchBookings()
    }
  }, [apartment])

  return (
    <FormSelect
      label="Numer rezerwacji"
      labelSize={4}
      options={availableBookings}
      name="created_by_booking"
      selectProps={{ isClearable: true }}
    />
  )
}
