import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { FormInput } from '@hyper/forms'
import { useFormRequest } from '@components/hooks/use-api-request'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { FormProvider, useForm } from 'react-hook-form'
import { BaseModalProps } from '@components/modals/types'
import { commonObjectPost } from '@store/actions/generic-actions'
import { UpSellBooking } from '@models/upselling'
import { useUpsellBookingDetailsRefresh } from '@modules/upselling/upsell-booking-details/use-upsellbooking-details-refresh'

interface FormInputs {
  content: string
}

interface Props extends BaseModalProps {
  upsellBooking: UpSellBooking
}

export const UpsellBookingAddNoteModal: React.FC<Props> = ({ toggleIsVisible, upsellBooking }) => {
  const methods = useForm<FormInputs>({ defaultValues: { content: '' } })

  const { addSuccessMessage } = useNotificationHook()
  const { refresh } = useUpsellBookingDetailsRefresh()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    await commonObjectPost(upsellBooking.urls.notes, {
      content: payload.content,
    })

    addSuccessMessage('Sukces', 'Notatka została dodana')
    refresh()
    toggleIsVisible()
  }, methods.setError)

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggleIsVisible}>Dodaj notatkę</ModalHeader>
        <ModalBody>
          <FormInput label="Treść notatki" name="content" type="textarea" colClassName="px-0" />
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={toggleIsVisible}>
            Zamknij
          </Button>

          <SaveButton
            role="submit"
            className="btn btn-green"
            label="Dodaj"
            labelSaving="Dodawanie..."
            isSaving={isLoading}
          />
        </ModalFooter>
      </form>
    </FormProvider>
  )
}
