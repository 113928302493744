import * as React from 'react'
import { Button } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'
import { IconWithText } from '@components/icon-with-text'

export const NotificationTemplatesSmsCreateButton: React.FC = () => {
  const [toggleModal] = useModal('NotificationTemplatesSmsModal')

  return (
    <Button
      onClick={toggleModal}
      color="green-dark"
      type="button"
      role="subscription-create-button"
      className="d-block ml-auto"
    >
      <IconWithText icon="mdi mdi-plus-circle " text="Dodaj szablon SMS" />
    </Button>
  )
}
