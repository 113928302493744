import * as React from 'react'
import { ResortTabs } from '@components/resort-tabs'
import { PageTitle } from '@hyper/page-title'
import { useDocumentTitle } from '@helpers/utils'
import { NavigationPath } from '@models/routes'
import { resortsMapSelector } from '@store/selectors/dashboard'
import { useAppDispatch, useAppSelector } from '@store/index'
import { useParams } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import { TechnicalOrderCreateButton } from '@modules/technical-orders/technical-order-create-button'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { TechnicalOrderStatus } from '@models/technical-orders'
import { TechnicalOrdersTabs } from '@modules/technical-orders/technical-orders-tabs'
import { TechnicalOrdersTodo } from '@modules/technical-orders/technical-orders-todo'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { TechnicalOrdersOrdered } from '@modules/technical-orders/technical-orders-ordered'
import { TechnicalOrdersInProgress } from '@modules/technical-orders/technical-orders-in-progress'
import { TechnicalOrdersToAccept } from '@modules/technical-orders/technical-orders-to-accept'
import { TechnicalOrdersDone } from '@modules/technical-orders/technical-orders-done'
import { TechnicalOrdersDetailsView } from '@modules/technical-orders/details'
import { TechnicalOrderWebsocket } from '@modules/technical-orders/websocket'
import { setDashboardMobileView } from '@store/slices/app-slice'

interface Params {
  resort_id: string
  type: TechnicalOrderStatus
  id?: string
}

export const TechnicalOrdersView: React.FC = () => {
  const { resort_id, type, id = '' } = useParams() as unknown as Params
  const resortId = parseInt(resort_id, 10)
  const resort = useAppSelector(resortsMapSelector)[resortId]
  const pageTitle = `Usterki ${resort?.name || ''}`
  const dispatch = useAppDispatch()

  useDocumentTitle(pageTitle)
  useMenuActive(NavigationPath.TechnicalOrders)

  React.useEffect(() => {
    dispatch(setDashboardMobileView(true))

    return () => {
      dispatch(setDashboardMobileView(false))
    }
  }, [resortId])

  const user = useAuthenticatedUser()

  return (
    <>
      <TechnicalOrderWebsocket resortId={resortId} type={type} />
      <TechnicalOrdersDetailsView id={id} kind="technical_order" />
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitle} />
      <Card>
        <CardBody>
          <ResortTabs
            showAll={user.isTechnicalOrderManager}
            resortId={resortId}
            url={NavigationPath.TechnicalOrdersWithParams.replace(':type', type)}
          >
            <TechnicalOrderCreateButton resortId={resortId} />
          </ResortTabs>
          <TechnicalOrdersTabs resortId={resortId} type={type} />
          {type === 'todo' && <TechnicalOrdersTodo resortId={resortId} />}
          {type === 'ordered' && <TechnicalOrdersOrdered resortId={resortId} />}
          {type === 'in_progress' && <TechnicalOrdersInProgress resortId={resortId} />}
          {type === 'to_accept' && <TechnicalOrdersToAccept resortId={resortId} />}
          {type === 'done' && <TechnicalOrdersDone resortId={resortId} />}
        </CardBody>
      </Card>
    </>
  )
}
