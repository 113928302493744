import * as React from 'react'
import classNames from 'classnames'
import { ApartmentBookingFilter } from '@models/apartment'

interface FilterButtons {
  filter: ApartmentBookingFilter
  element: React.ReactNode
}

interface Props {
  isButtonActive: (key: ApartmentBookingFilter) => boolean
  toggleActiveFilter: (key: ApartmentBookingFilter) => () => void
  filters?: ApartmentBookingFilter[]
}

export const TimelineFilterButtons = ({ isButtonActive, toggleActiveFilter, filters }: Props): JSX.Element => {
  const buttons: FilterButtons[] = [
    {
      filter: 'unavailable',
      element: (
        <button
          type="button"
          className={classNames('btn mr-1 mb-1 calendar__button is-unavailable', {
            'is-filtered': isButtonActive('unavailable'),
          })}
          onClick={toggleActiveFilter('unavailable')}
        >
          Blokada
        </button>
      ),
    },
    {
      filter: 'confirmed',
      element: (
        <button
          type="button"
          className={classNames('btn mr-1 mb-1 calendar__button is-confirmed', {
            'is-filtered': isButtonActive('confirmed'),
          })}
          onClick={toggleActiveFilter('confirmed')}
        >
          Potwierdzona
        </button>
      ),
    },
    {
      filter: 'initial',
      element: (
        <button
          type="button"
          className={classNames('btn mr-1 mb-1 calendar__button is-initial', {
            'is-filtered': isButtonActive('initial'),
          })}
          onClick={toggleActiveFilter('initial')}
        >
          Wstępna
        </button>
      ),
    },
    {
      filter: 'checkedin',
      element: (
        <button
          type="button"
          className={classNames('btn mr-1 mb-1 calendar__button is-checked', {
            'is-filtered': isButtonActive('checkedin'),
          })}
          onClick={toggleActiveFilter('checkedin')}
        >
          Zameldowana
        </button>
      ),
    },
    {
      filter: 'close',
      element: (
        <button
          type="button"
          className={classNames('btn mr-1 mb-1 calendar__button is-paid', {
            'is-filtered': isButtonActive('close'),
          })}
          onClick={toggleActiveFilter('close')}
        >
          Rozliczona
        </button>
      ),
    },
    {
      filter: 'unfinished',
      element: (
        <button
          type="button"
          className={classNames('btn mr-1 mb-1 calendar__button is-unfinished', {
            'is-filtered': isButtonActive('unfinished'),
          })}
          onClick={toggleActiveFilter('unfinished')}
        >
          Niedokończona
        </button>
      ),
    },
    {
      filter: 'extended',
      element: (
        <button
          type="button"
          className={classNames('btn mr-1 mb-1 calendar__button is-extended', {
            'is-filtered': isButtonActive('extended'),
          })}
          onClick={toggleActiveFilter('extended')}
        >
          Przedłużone
        </button>
      ),
    },
    {
      filter: 'employee',
      element: (
        <button
          type="button"
          className={classNames('btn mr-1 mb-1 calendar__button is-employee', {
            'is-filtered': isButtonActive('employee'),
          })}
          onClick={toggleActiveFilter('employee')}
        >
          Pracownicze
        </button>
      ),
    },
  ]

  const toShow = filters ? buttons.filter(button => filters.includes(button.filter)) : buttons

  const buttonsToShow = toShow.map(el => <React.Fragment key={el.filter}>{el.element}</React.Fragment>)

  return <div className="d-flex flex-wrap">{buttonsToShow}</div>
}
