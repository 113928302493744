import * as React from 'react'
import { Col, Row } from 'reactstrap'
import { HousekeepingUserReportStatsTime } from '@modules/reports/housekeeping-user-report/stats/housekeeping-user-report-stats-time'
import { HousekeepingUserReportStatsTable } from '@modules/reports/housekeeping-user-report/stats/housekeeping-user-report-stats-table'
import { HouseKeepingCleaningOrderAggregation } from '@modules/reports/housekeeping-users-report/models'
import { HousekeepingUser } from '@models/reports'

interface Props {
  aggregation: HouseKeepingCleaningOrderAggregation
  user: HousekeepingUser | undefined
}

export const HousekeepingUserReportStats: React.FC<Props> = ({ aggregation, user }) => (
  <Row className="reports__total__row mx-0">
    <Col
      md={5}
      className="reports__total__column-white reports__total__column-white__header font-14 text-strong p-2 d-flex align-items-center text-center justify-content-center"
    >
      Podsumowanie
      <br />
      Pracownik: {user?.name || ''}
    </Col>
    <Col
      md={7}
      className="reports__total__column-grey reports__total__column-grey__header font-14 text-strong d-flex align-items-center text-center justify-content-center"
    >
      Wykonanych zleceń
    </Col>
    <Col md={5} className="reports__total__column-white py-2">
      <HousekeepingUserReportStatsTime aggregation={aggregation} />
    </Col>
    <Col md={7} className="reports__total__column-grey">
      <Row>
        <Col md={8} className="py-2">
          <HousekeepingUserReportStatsTable aggregation={aggregation} />
        </Col>
      </Row>
    </Col>
  </Row>
)
