import { useAppData } from '@components/hooks/use-app-data'
import { GuestWalletScope } from '@models/booking'

export interface ResortAvailableScope {
  key: GuestWalletScope
}

interface AvailableScope {
  [key: string]: ResortAvailableScope[]
}

const AVAILABLE_SCOPES: AvailableScope = {
  parkAndFun: [{ key: 'vending_machines_parkfun' }, { key: 'tickets_parkfun' }],
  shop: [{ key: 'shop' }],
  gastro: [
    { key: 'cafe' },
    { key: 'bistro' },
    { key: 'sweet_corner' },
    { key: 'beach_bar' },
    { key: 'itinerant_trade' },
  ],
  vendingMachines: [
    { key: 'vending_machines' },
    { key: 'vending_machines_gastronomy' },
    { key: 'vending_machines_entertainment' },
  ],
} as const

interface Response {
  availableShopScopes: ResortAvailableScope[]
  availableGastroScopes: ResortAvailableScope[]
  availableVendingMachinesScopes: ResortAvailableScope[]
  availableParkAndFunScopes: ResortAvailableScope[]
}

export const useResortWalletGroupedScope = (resortId: number): Response => {
  const availableResortScopes = useResortWalletScope(resortId)

  const filterScopes = (scope: ResortAvailableScope) =>
    availableResortScopes.some(resortScope => resortScope === scope.key)

  const availableShopScopes = AVAILABLE_SCOPES.shop.filter(filterScopes)
  const availableGastroScopes = AVAILABLE_SCOPES.gastro.filter(filterScopes)
  const availableVendingMachinesScopes = AVAILABLE_SCOPES.vendingMachines.filter(filterScopes)
  const availableParkAndFunScopes = AVAILABLE_SCOPES.parkAndFun.filter(filterScopes)

  return {
    availableShopScopes,
    availableGastroScopes,
    availableVendingMachinesScopes,
    availableParkAndFunScopes,
  }
}

export const useResortWalletScope = (resortId: number) => {
  const { resorts } = useAppData()
  const resort = resorts.find(resort => resort.id === resortId)

  return resort?.available_voucher_scopes ?? []
}
