import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit'
import { getEcommerceStats } from '@store/actions/ecommerce-actions'
import { EcommerceStatsAggregation, EcommerceStatsItem } from '@modules/ecommerce/models'
import { RootState } from '@store/index'

interface EcommerceState {
  stats: EntityState<EcommerceStatsItem>
  statsAggregation: EcommerceStatsAggregation | null
}

const statsAdapter = createEntityAdapter<EcommerceStatsItem>()

const initialState: EcommerceState = {
  stats: statsAdapter.getInitialState(),
  statsAggregation: null,
}

export const ecommerceSlice = createSlice({
  name: 'ecommerce',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getEcommerceStats.fulfilled, (state, action) => {
      statsAdapter.setAll(state.stats, action.payload.results)
      state.statsAggregation = action.payload.aggregation
    })
  },
})

export const { selectAll: selectEcommerceStats } = statsAdapter.getSelectors(
  (state: RootState) => state.ecommerceState.stats,
)

export const selectEcommerceStatsAggregation = (state: RootState): EcommerceStatsAggregation | null =>
  state.ecommerceState.statsAggregation

export default ecommerceSlice.reducer
