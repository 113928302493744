import { ReceptionBookingDetails } from '@models/reception'
import {
  createBookingImprovement,
  ImprovementActionPayload,
  updateBookingImprovement,
} from '@store/actions/reception-actions'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { ParkingKind } from '@models/booking'

export const useAddParkingImprovement = (booking: ReceptionBookingDetails) => {
  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()

  const addParkingImprovement = async (payload: ImprovementActionPayload, kind: ParkingKind) => {
    const parkingImprovement = booking.improvements.find(improvement => improvement.code === kind)
    if (parkingImprovement) {
      await dispatch(
        updateBookingImprovement(parkingImprovement.urls.details, [
          {
            ...payload,
            amount: parkingImprovement.amount + 1,
          },
        ]),
      )
      addSuccessNotification('Ulepszenie zostało zmienione!')
      return
    }

    await dispatch(createBookingImprovement(booking.urls.improvement_create, [payload]))
    addSuccessNotification('Ulepszenie zostało dodane')
  }

  return { addParkingImprovement }
}
