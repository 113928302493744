import * as React from 'react'
import { formatPriceShort } from '@helpers/utils'
import classnames from 'classnames'
import { ButtonWithIcon } from '@components/button-with-icon'

interface PurchasePaymentContainerProps {
  title: string
  icon: string
  price: string | number
  onConfirm: () => void
  confirmDisabled?: boolean
  onCancel: () => void
  children: React.ReactElement
}

export const PurchasePaymentContainer: React.FC<PurchasePaymentContainerProps> = ({
  title,
  icon,
  price,
  children,
  onCancel,
  onConfirm,
  confirmDisabled,
}) => (
  <div>
    <div className="d-flex justify-content-between">
      <div className="text-primary font-weight-bold">
        <i className={classnames('mr-2', icon)} />
        <span className="d-inline-block">{title}</span>
      </div>
      <span className="text-danger d-block font-weight-bold">{formatPriceShort(price)}</span>
    </div>
    <div className="my-3">{children}</div>
    <div className="d-flex shop-purchase__summary__buttons">
      <ButtonWithIcon icon="uil-times" text="Anuluj" color="light" btnClass="border" handleClick={onCancel} />
      <ButtonWithIcon
        icon="uil-check"
        text="Zatwierdź płatność"
        color="secondary"
        handleClick={onConfirm}
        disabled={confirmDisabled}
        btnClass="flex-fill"
      />
    </div>
  </div>
)
