import * as React from 'react'
import Table from '@components/table/table'
import { TechnicalOrdersTodoRowEnterWhereGuestAbsence } from '@modules/technical-orders/technical-orders-todo/row-enter-where-guest-absence'
import { TechnicalOrdersTodoRowTechnicalOrderEnterWhereGuestAbsenceRange } from '@modules/technical-orders/technical-orders-todo/row-technical-order-enter-where-guest-absence-range'
import { useAppData } from '@components/hooks/use-app-data'
import { TechnicalOrder } from '@models/technical-orders'

interface Props {
  technicalOrder: TechnicalOrder
  width?: number
  showPriority?: boolean
}

export const TechnicalOrdersTodoRowUnit: React.FC<Props> = ({ showPriority, technicalOrder, width = 130 }) => {
  const { units } = useAppData()
  const unit = React.useMemo(() => units.find(u => u.id === technicalOrder.unit_id), [units, technicalOrder.unit_id])

  return (
    <Table.Cell className="vertical-align-middle" style={{ width }}>
      <span className="d-block mb-1">{unit?.name}</span>
      <TechnicalOrdersTodoRowEnterWhereGuestAbsence technicalOrder={technicalOrder} />
      {!!technicalOrder.technical_order_enter_where_guest_absence_range.length && (
        <TechnicalOrdersTodoRowTechnicalOrderEnterWhereGuestAbsenceRange technicalOrder={technicalOrder} />
      )}
      {showPriority && <i title="Priorytet" className="uil-rocket font-16 text-danger" />}
    </Table.Cell>
  )
}
