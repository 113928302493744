import * as React from 'react'
import { HrWorker } from '@modules/hr/workers/models'
import classNames from 'classnames'
import declination from '@helpers/declination'

interface Props {
  worker: HrWorker
}

export const HrWorkerStatus = ({ worker }: Props): JSX.Element => {
  const statusDisplay = {
    hired: 'Zatrudniony',
    not_employed: 'Nie zatrudniony',
    former_employee: 'Były pracownik',
    contract_expires: `Umowa wygasa za ${worker.expiration_days} ${declination.day(worker.expiration_days)}`,
  }[worker.status]

  return (
    <span
      className={classNames({
        'badge badge-success': worker.status === 'hired',
        'badge badge-info-lighten': worker.status === 'not_employed',
        'badge badge-light': worker.status === 'former_employee',
        'badge badge-danger-lighten': worker.status === 'contract_expires',
      })}
    >
      {statusDisplay}
    </span>
  )
}
