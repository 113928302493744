import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useFormRequest } from '@components/hooks/use-api-request'
import { FormProvider, useForm } from 'react-hook-form'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { formatPriceShort } from '@helpers/utils'
import { useAppDispatch } from '@store/index'
import { startOfToday } from 'date-fns'
import { PackageDetails } from '@models/package'
import { PaymentCreateModalForm } from '@modules/package/details/payments/payment-create-modal/form'
import { BaseModalProps } from '@components/modals/types'
import { updatePackageDetails } from '@store/slices/package-slice'

interface Props extends BaseModalProps {
  packageDetails: PackageDetails
}

export interface PaymentCreateModalFormInputs {
  amount: string
  source: string
  description: string
  invoice_date: Date
  send_email_notification: boolean
}

export const PackagePaymentCreateModal: React.FC<Props> = ({ packageDetails, toggleIsVisible }) => {
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const methods = useForm<PaymentCreateModalFormInputs>({
    defaultValues: {
      amount: packageDetails.required_payment,
      invoice_date: startOfToday(),
      send_email_notification: true,
    },
  })

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: PaymentCreateModalFormInputs) => {
    dispatch(updatePackageDetails(await commonObjectPost<PackageDetails>(packageDetails.urls.payment_create, payload)))
    addSuccessMessage('Sukces', `Wpłata w kwocie ${formatPriceShort(payload.amount)} została dodana!`)
    toggleIsVisible()
  }, methods.setError)

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggleIsVisible}>Dodaj wpłatę</ModalHeader>
        <ModalBody>
          <PaymentCreateModalForm sendEmailNotificationOption={packageDetails.package_type === 'HPI'} />
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={toggleIsVisible}>
            Zamknij
          </Button>

          <SaveButton role="submit" className="btn btn-green" label="Zapisz" isSaving={isLoading} />
        </ModalFooter>
      </form>
    </FormProvider>
  )
}
