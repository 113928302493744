import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@store/index'
import { GastroCard, GastroCardAggregation, GastroCardDetails } from '@models/promotions'
import { createGastroCards, getGastroCardDetail, getGastroCards } from '@store/actions/gastro-card-actions'

interface GastroCardState {
  gastroCards: EntityState<GastroCard>
  gastroCardDetails: GastroCardDetails | undefined
  aggregation: GastroCardAggregation | null
}

const gastroCardsAdapter = createEntityAdapter<GastroCard>()

const initialState: GastroCardState = {
  gastroCards: gastroCardsAdapter.getInitialState(),
  gastroCardDetails: undefined,
  aggregation: null,
}

const gastroCardSlice = createSlice({
  name: 'gastroCard',
  initialState,
  reducers: {
    setGastroCardDetails(state, action: PayloadAction<GastroCardDetails | undefined>) {
      state.gastroCardDetails = action.payload
    },
    updateGastroCardDetails(state, action: PayloadAction<GastroCardDetails>) {
      const gastroCard = { ...action.payload, seller: action.payload.seller?.name || '' }

      if (state.gastroCardDetails?.id === action.payload.id) {
        state.gastroCardDetails = action.payload
      }

      gastroCardsAdapter.upsertOne(state.gastroCards, gastroCard)
    },
    clearGastroCards(state) {
      gastroCardsAdapter.removeAll(state.gastroCards)
    },
  },
  extraReducers: builder => {
    builder.addCase(getGastroCardDetail.fulfilled, (state, action) => {
      state.gastroCardDetails = action.payload
    })
    builder.addCase(getGastroCards.fulfilled, (state, action) => {
      gastroCardsAdapter.setAll(state.gastroCards, action.payload.results)
      state.aggregation = action.payload.aggregation ?? null
    })
    builder.addCase(createGastroCards.fulfilled, (state, action: PayloadAction<GastroCardDetails>) => {
      if (action.payload) {
        const gastroCard = { ...action.payload, seller: action.payload.seller?.name || '' }
        gastroCardsAdapter.upsertOne(state.gastroCards, gastroCard)
      }
    })
  },
})

export const { clearGastroCards, updateGastroCardDetails, setGastroCardDetails } = gastroCardSlice.actions

export const { selectAll: gastroCardsSelector } = gastroCardsAdapter.getSelectors(
  (state: RootState) => state.gastroCardState.gastroCards,
)

export const gastroCardDetailsSelector = (state: RootState) => state.gastroCardState.gastroCardDetails
export const selectGastroCardAggregation = (state: RootState): GastroCardAggregation | null =>
  state.gastroCardState.aggregation
export default gastroCardSlice.reducer
