import * as React from 'react'
import { CalendarProps } from 'react-date-range'
import Select from 'react-select'
import { createSelectOption } from '@helpers/utils'
import { CustomReactSelectOption, getReactSelectStyles, reactSelectComponents } from '@components/custom-react-select'
import { Col, Row } from 'reactstrap'

type ChangeShowDate = (value: Date | number | string, mode?: 'set' | 'setYear' | 'setMonth' | 'monthOffset') => void

export type DateRangeNavigatorYears = (years: number[]) => number[]

export const DateRangeNavigatorRenderer =
  (navigatorYearsRenderer?: DateRangeNavigatorYears) =>
  (focusedDate: Date, changeShownDate: ChangeShowDate, props: CalendarProps): JSX.Element => {
    const { locale, minDate, maxDate } = props

    const [upperYearLimit, lowerYearLimit] = [maxDate?.getFullYear(), minDate?.getFullYear()]

    const getYears = () => {
      const years =
        upperYearLimit && lowerYearLimit
          ? new Array(upperYearLimit - lowerYearLimit + 1).fill(upperYearLimit).map((year, index) => year - index)
          : []

      return navigatorYearsRenderer ? navigatorYearsRenderer(years) : years
    }

    const isSameYearAsFocusedYear = (date: Date | undefined) => date?.getFullYear() === focusedDate.getFullYear()

    const monthsOptions = [...Array(12).keys()]
      .map(i => locale?.localize?.month(i))
      .map((month, index) => createSelectOption(month, index))

    const yearsOptions = getYears().map(year => createSelectOption(year, year))

    const isOptionDisabled = (option: CustomReactSelectOption) =>
      (isSameYearAsFocusedYear(maxDate) && option.value > (maxDate?.getMonth() ?? -1)) ||
      (isSameYearAsFocusedYear(minDate) && option.value < (minDate?.getMonth() ?? 12))

    return (
      <Row className="mx-0">
        <Col md={6} className="py-2 pr-1 d-flex align-items-center" onClick={e => e.stopPropagation()}>
          <i
            className="uil-angle-left text-default font-18 cursor-pointer mr-1"
            onClick={() => changeShownDate(-1, 'monthOffset')}
          />
          <Select
            styles={selectStyles}
            value={monthsOptions.find(option => option.value === focusedDate.getMonth())}
            onChange={option => changeShownDate(String(option?.value), 'setMonth')}
            options={monthsOptions}
            components={reactSelectComponents}
            isSearchable={false}
            isOptionDisabled={isOptionDisabled}
            menuShouldScrollIntoView={true}
          />
        </Col>

        <Col md={6} className="py-2 pl-1 d-flex align-items-center" onClick={e => e.stopPropagation()}>
          <Select
            styles={selectStyles}
            value={yearsOptions.find(option => option.value === focusedDate.getFullYear())}
            onChange={option => changeShownDate(String(option?.value), 'setYear')}
            options={yearsOptions}
            components={reactSelectComponents}
            isSearchable={false}
            menuShouldScrollIntoView={true}
          />
          <i
            className="uil-angle-right text-default font-18 cursor-pointer ml-1"
            onClick={() => changeShownDate(+1, 'monthOffset')}
          />
        </Col>
      </Row>
    )
  }

const selectStyles = {
  ...getReactSelectStyles({ hasError: false }),
  dropdownIndicator: provided => ({ ...provided, padding: 0, paddingRight: '8px' }),
  control: provided => ({
    ...provided,
    minHeight: '30px',
    boxShadow: 'none',
    '&:hover': { cursor: 'pointer' },
    '&:active': { border: '1px solid rgb(61, 145, 255)' },
  }),
  container: provided => ({
    ...provided,
    width: '100%',
  }),
}
