import * as React from 'react'
import { BaseHousekeepingOrder } from '@modules/housekeeping/models'
import classNames from 'classnames'
import { ImprovementIcon } from '@components/improvement-icon'

interface Props {
  order: BaseHousekeepingOrder
}

export const HousekeepingCleaningOrdersRowKind = React.memo(({ order }: Props) => (
  <td className="housekeeping__orders_table__column" width={250}>
    <HousekeepingCleaningOrdersKind order={order} />
  </td>
))

export const HousekeepingCleaningOrdersKind = ({ order, className }: Props & { className?: string }) => {
  const hasVip = 'next_booking' in order ? order.next_booking?.has_package_vip : false

  const isDeparture = order.kind === 'DEPARTURE'

  return (
    <div className="d-flex align-items-center">
      <span
        className={classNames('badge text-semi-strong', className, {
          'badge-secondary': order.kind === 'ARRIVAL',
          'badge-dark': order.kind === 'DEPARTURE',
          'badge-danger': order.kind === 'DEPARTURE_ARRIVAL',
        })}
      >
        {order.kind === 'DEPARTURE_ARRIVAL' && <i className="uil-arrow-resize-diagonal font-11 mr-1" />}
        {order.kind === 'ARRIVAL' && <i className="mdi mdi-arrow-top-right font-11 mr-1" />}
        {order.kind === 'DEPARTURE' && <i className="mdi mdi-arrow-bottom-right font-11 mr-1" />}
        {order.kind_display}
      </span>

      {hasVip && !isDeparture ? (
        <ImprovementIcon improvementCode="package_vip" className="ml-1 font-15" />
      ) : (
        <>
          {!!order.extend_stay_time && (
            <ImprovementIcon
              improvementCode={order.extend_stay_time === '12:00:00' ? 'extend_stay_12' : 'extend_stay'}
              className="ml-1 font-14"
            />
          )}
          {!!order.early_check_in_time && (
            <ImprovementIcon improvementCode="early_check_in_13" className="ml-1 font-14" />
          )}
        </>
      )}
    </div>
  )
}
