import * as React from 'react'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { getCmsAppData } from '@store/actions/cms-actions'
import { CmsAppData } from '@models/cms'

export const useCmsAppData = (): CmsAppData => {
  const cmsAppData = useAppSelector((state: RootState) => state.cmsState.appData)
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (cmsAppData.status === 'unknown') {
      dispatch(getCmsAppData())
    }
  }, [cmsAppData.status])

  return cmsAppData
}
