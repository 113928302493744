import * as React from 'react'

interface Props {
  printUrl: string | null
}

export const PaymentRegisterReceptionReportCell = ({ printUrl }: Props): JSX.Element => (
  <div className="d-inline-block" style={{ width: 20 }}>
    {printUrl && (
      <a href={printUrl} className="text-default mr-1" target="_blank">
        <i className="uil-download-alt cursor-pointer font-15" title="Generuj raport zmiany" />
      </a>
    )}
  </div>
)
