import * as React from 'react'
import { SubscriptionCode, SubscriptionDetails } from '@modules/subscription/models'
import Table from '@components/table/table'
import { useModal } from '@components/modals/use-modal'
import { CopyToClipboard } from '@components/copy-to-clipboard'
import { formatPriceShort } from '@helpers/utils'
import { getYear } from 'date-fns'
import { parseISODate } from '@helpers/date-helper'

interface Props {
  createdYear: number
  subscriptionCode: SubscriptionCode
  subscriptionDetails: SubscriptionDetails
}

export const SubscriptionDetailsCodesRowCode: React.FC<Props> = ({
  subscriptionDetails,
  subscriptionCode,
  createdYear,
}) => {
  const [handleEditCode] = useModal('SubscriptionCodeEditModal', { subscriptionCode })

  return (
    <Table.Cell>
      <span onClick={handleEditCode} className="cursor-pointer">
        {subscriptionCode.code}
      </span>

      <CopyToClipboard className="uil-share-alt ml-1" value={subscriptionCode.code} />

      {subscriptionCode.subscription_kind_display !== subscriptionDetails.kind_display && (
        <div className="mt-1 font-11">{subscriptionCode.subscription_kind_display}</div>
      )}
      {subscriptionCode.name && <div className="mt-1 font-11">{subscriptionCode.name}</div>}
      {subscriptionCode.features.map(feature => (
        <div key={feature.id} className="mt-1 font-11">
          Dostępny:{' '}
          {feature.years.length === 0 ? (
            <strong>od dziś do {getYear(parseISODate(subscriptionDetails.expire_after) as Date)}</strong>
          ) : (
            <strong>{feature.years.map(year => createdYear + year).join(', ')}</strong>
          )}
          <br />
          {feature.gastro_gift && (
            <>
              Voucher Gastro <strong>{formatPriceShort(feature.gastro_gift)} </strong>
              <br />
            </>
          )}
          {feature.package_vip && (
            <>
              Pakiet VIP: <strong>{feature.package_vip}</strong>
              <br />
            </>
          )}
          {feature.code_with_benefit && (
            <>
              Dostęp do <strong>oferty specjalnej</strong>
            </>
          )}
        </div>
      ))}
    </Table.Cell>
  )
}
