import { BaseUrls, BaseObj } from '@models/base'
import { User } from '@models/account'

export type ServiceBandStatus = 'inactive' | 'active' | 'removed'

export const serviceBandStatusMapping: Record<ServiceBandStatus, string> = {
  inactive: 'Nieaktywna',
  active: 'Aktywna',
  removed: 'Skasowana',
}

export interface ServiceBand extends BaseObj {
  name: string
  status: ServiceBandStatus
  scopes: string[]
  resort: number
  initial_funds: number
  date_from: string
  date_to: string
  user: User
  owner: User
  removed_by: User
  urls: ServiceBandUrls
  is_removed: boolean
  created: string
  deleted_at: string
}

interface ServiceBandUrls extends BaseUrls {
  booking: string
  booking_change: string
}
