import * as React from 'react'
import { Button } from 'reactstrap'
import { RootState, useAppSelector } from '@store/index'
import { extractSelectOptionsValues, objectToUrlString } from '@helpers/utils'
import { useFormContext } from 'react-hook-form'
import { FeedingHosFormInputs } from '@modules/feeding/host/feeding-host-with-params-view'
import { formatDate } from '@helpers/date-helper'
import { useModal } from '@components/modals/use-modal'

export const FeedingHostFiltersButtons: React.FC = () => {
  const printUrl = useAppSelector((state: RootState) => state.appState.appData.urls.feeding.print)

  const { getValues } = useFormContext<FeedingHosFormInputs>()
  const values = getValues()

  const [handleSpecialGuestsOpen] = useModal('FeedingHostSpecialGuestsModal')
  const [handleWorkerGuestsOpen] = useModal('FeedingHostGuestsModal')

  return (
    <div className="ml-auto">
      <a
        href={`${printUrl}?${objectToUrlString({ ...extractSelectOptionsValues(values), date: formatDate(values.date) })}`}
        target="blank"
        className="btn btn-primary"
      >
        Drukuj listę
      </a>
      <Button
        onClick={event => handleWorkerGuestsOpen(event, { values: { ...values, guestsKind: 'worker' } })}
        className="mx-1"
      >
        Pracownicy
      </Button>
      <Button onClick={event => handleSpecialGuestsOpen(event, { values })}>Goście specjalni</Button>
    </div>
  )
}
