import * as React from 'react'
import Spinner from '@hyper/spinner'

interface Props {
  label: React.ReactNode
}

export const BandScanningInProgress = ({ label }: Props): JSX.Element => (
  <div className="d-flex align-items-center text-white">
    <div className="band__scanning-guest__action">
      <Spinner size="xxs" color="white" />
    </div>
    {label}
    <span className="d-block ml-auto col-4 px-0 fw-semi-bold">skanowanie opaski...</span>
  </div>
)
