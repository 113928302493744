import * as React from 'react'
import { ClientDetails, ClientUser } from '@models/clients'
import { DropdownItem } from 'reactstrap'
import { IconWithText } from '@components/icon-with-text'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApiRequest } from '@components/hooks/use-api-request'
import { updateClientDetails } from '@store/actions/crm-actions'
import { commonObjectPatch } from '@store/actions/generic-actions'
import classNames from 'classnames'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'

interface Props {
  selectedRows: ClientUser[]
  icon: string
  label: string
  is_blacklisted: boolean
  clearSelectedRows: () => void
  title: string
  message: string
}

export const CrmClientsOptionsBlackListBase: React.FC<Props> = ({
  selectedRows,
  clearSelectedRows,
  icon,
  title,
  message,
  label,
  is_blacklisted,
}) => {
  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()

  const { isLoading, action: handleAction } = useApiRequest(async () => {
    for (const client of selectedRows) {
      dispatch(
        updateClientDetails(
          await commonObjectPatch<ClientDetails>(client.urls.profile_details, {
            is_blacklisted,
          }),
        ),
      )
    }
    addSuccessNotification('Zmiany zostały zapisane!')
    clearSelectedRows()
  })

  return (
    <DropdownItem>
      <CommonObjectConfirmAction title={title} isLoading={isLoading} handleAccept={handleAction} message={message}>
        <IconWithText icon={classNames('uil text-bold', icon)} textClass="text-semi-strong" text={label} />
      </CommonObjectConfirmAction>
    </DropdownItem>
  )
}
