import { useModal } from '@components/modals/use-modal'
import React from 'react'
import { useApiRequest } from '@components/hooks/use-api-request'
import { RootState, useAppSelector } from '@store/index'
import { commonObjectGet } from '@store/actions/generic-actions'
import { ReceptionBookingDetails } from '@models/reception'
import { NavigationPath } from '@models/routes'
import { ReservationListType } from '@modules/reservations/list/models'
import { useNavigate } from 'react-router-dom'

export const useReservationDetailsPreview = (bookingId: string | undefined, tab: string | undefined) => {
  const bookingDetailsUrl = useAppSelector((state: RootState) => state.appState.appData.urls.reception.booking_details)

  const navigate = useNavigate()

  const redirectToReservationList = () => {
    navigate(NavigationPath.ReservationListWithParams.replace(':type', String(ReservationListType.Present)))
  }

  const [showDetailsModal] = useModal('ReceptionBookingDetailsModal', {}, { onClose: redirectToReservationList })

  const { action: openDetailsModal } = useApiRequest(async () => {
    if (!bookingId || !tab) return

    const bookingDetails = await commonObjectGet<ReceptionBookingDetails>(`${bookingDetailsUrl}${bookingId}/`)
    showDetailsModal(null, {
      booking: bookingDetails,
      tabs: ['feedingNew'],
      initialTab: tab === 'feeding' ? 'feedingNew' : tab,
      clearOnUnmount: true,
    })
  })

  React.useEffect(() => {
    openDetailsModal()
  }, [])
}
