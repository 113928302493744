import * as React from 'react'
import { Link } from 'react-router-dom'
import { DashboardLinkReservationsCreate } from '@components/left-menu/dashboard-link-reservations-create'

interface DashboardLinkProps {
  href: string
  className?: string
  children?: React.ReactNode
}

export const DashboardLink: React.FC<DashboardLinkProps> = ({ href, className, children }) => {
  const link = React.useMemo(() => href.replace(/^\/dashboard/i, ''), [href])

  if (href === '/dashboard/reservations/create/') {
    return <DashboardLinkReservationsCreate className={className}>{children}</DashboardLinkReservationsCreate>
  }

  const isNavigationPath = (): boolean => href.startsWith('/dashboard')

  return isNavigationPath() ? (
    <Link to={link} className={className}>
      {children}
    </Link>
  ) : (
    <a href={href} className={className}>
      {children}
    </a>
  )
}
