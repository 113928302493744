import * as React from 'react'
import { Transfer, TransferPackageDetails } from '@modules/accounting/refund/models'
import { Col, Label } from 'reactstrap'
import { FormInput, FormPlain } from '@hyper/forms'
import DatePickerInput from '@components/date/date-picker-input'
import { useForm } from 'react-hook-form'
import { SaveButton } from '@hyper/button'
import { commonObjectPut } from '@store/actions/generic-actions'
import { formatDate } from '@helpers/date-helper'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { TransferPackageEditTransferRemoveButton } from '@modules/accounting/refund/transfer-packages/edit/remove-button'
import { useFormRequest } from '@components/hooks/use-api-request'
import { accountNumberMask } from '@models/crm'
import { FormInputMasked } from '@hyper/forms/form-input-masked'
import { Form } from '@hyper/forms/form'

interface TransferPackageEditTransferProps {
  transfer: Transfer
  setTransferPackageDetails: (TransferPackageDetails) => void
}

interface FormInputs {
  name: string
  address: string
  city: string
  postcode: string
  account_number: string
  amount: string
  send_date: string
  title: string
}

export const TransferPackageEditTransfer: React.FC<TransferPackageEditTransferProps> = ({
  transfer,
  setTransferPackageDetails,
}) => {
  const methods = useForm<FormInputs>({ defaultValues: transfer })
  const { addSuccessNotification } = useNotificationHook()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    setTransferPackageDetails(
      await commonObjectPut<TransferPackageDetails>(transfer.urls.details, {
        ...payload,
        send_date: formatDate(payload.send_date),
      }),
    )
    addSuccessNotification('Dane do przelewu zostały zmienione')
  }, methods.setError)

  return (
    <Form methods={methods} className="row" onSubmit={onSubmit}>
      <Col md={12}>
        <h5 className="mb-0">{transfer.number}</h5>
        <hr className="mb-2" />
      </Col>
      <FormInput colSize={3} label="Odbiorca" name="name" />
      <FormInput colSize={3} label="Adres" name="address" />
      <FormInput colSize={3} label="Kod pocztowy" name="postcode" />
      <FormInput colSize={3} label="Miasto" name="city" />

      <FormInputMasked
        placeholder="__ ____ ____ ____ ____ ____ ____"
        colSize={6}
        mask={accountNumberMask}
        name="account_number"
        label="Numer konta"
      />

      <FormInput colSize={3} label="Kwota" name="amount" />
      <FormPlain colSize={3} name="received_date">
        <Label>Data przelewu</Label>
        <DatePickerInput name="send_date" wrapperClassName="flex-grow" />
      </FormPlain>
      <Col md={12} className="text-right">
        <TransferPackageEditTransferRemoveButton
          setTransferPackageDetails={setTransferPackageDetails}
          transfer={transfer}
        />

        <SaveButton isSaving={isLoading} className="btn btn-green" label="Zapisz" />
      </Col>
    </Form>
  )
}
