import * as React from 'react'
import { GastroCardDetails, GastroWalletItem } from '@models/promotions'
import { useAppDispatch } from '@store/index'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApiRequest } from '@components/hooks/use-api-request'
import { setGastroCardDetails } from '@store/slices/gastro-card-slice'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'

interface GastroCardWalletItemsRemoveProps {
  items: GastroWalletItem[]
  gastroCardDetails: GastroCardDetails
  handleClose: () => void
  children: React.ReactNode
}

export const GastroCardWalletItemsRemove: React.FC<GastroCardWalletItemsRemoveProps> = ({
  gastroCardDetails,
  items,
  handleClose,
  children,
}) => {
  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()

  const { isLoading, action: handleRemove } = useApiRequest(async (reason: string) => {
    dispatch(
      setGastroCardDetails(
        await commonObjectDelete<GastroCardDetails>(gastroCardDetails.wallet.urls.items_batch_remove, reason, {
          items: items.map(row => row.id),
        }),
      ),
    )
    addSuccessNotification('Pozycja została skasowana!')
    handleClose()
  })

  if (!gastroCardDetails) {
    return null
  }

  return (
    <CommonObjectConfirmAction
      handleAccept={handleRemove}
      title="Potwierdź operacje"
      isLoading={isLoading}
      message="Czy na pewno chcesz skasować wybraną pozycję"
      askForReason={true}
    >
      {children}
    </CommonObjectConfirmAction>
  )
}
