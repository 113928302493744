import * as React from 'react'
import { TechnicalOrder } from '@models/technical-orders'
import Table from '@components/table/table'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'

interface Props {
  technicalOrder: TechnicalOrder
}

export const TechnicalOrdersOrderedTableRowOrderedBy: React.FC<Props> = ({ technicalOrder }) => (
  <Table.Cell className="vertical-align-middle" style={{ width: 150 }}>
    {technicalOrder.ordered_by && (
      <>
        {technicalOrder.ordered_by.first_name} {technicalOrder.ordered_by.last_name}
        <span className="font-10 d-block">{toDefaultDateTimeFormat(technicalOrder.ordered_at)}</span>
      </>
    )}
  </Table.Cell>
)
