import * as React from 'react'
import { formatPrice } from '@helpers/utils'

interface Props {
  title: string
  price: string
}

export const ReservationBillExpandedAdditionalChargesRow = ({ title, price }: Props): JSX.Element => (
  <tr>
    <td>{title}</td>
    <td className="fw-semi-bold text-right">+ {formatPrice(price)}</td>
    <td width="50" />
  </tr>
)
