import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { apiQueryFunction } from '@api/base'
import { RootState } from '@store/index'
import { PaginationResponse } from '@models/dashboard'
import { BookingOffer } from '@models/booking-offers'
import { BookingOffersFiltersParams } from '@modules/booking-offers'
import { extractSelectOptionsValues } from '@helpers/utils'
import { ReceptionBookingDetails } from '@models/reception'

export const BOOKING_OFFER_TAG = 'bookingOffer'

export const bookingOffersApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: '' }),
  reducerPath: 'useBookingOffersApi',
  tagTypes: [BOOKING_OFFER_TAG],
  endpoints: builder => ({
    getBookingOffers: builder.query<BookingOffer[], BookingOffersFiltersParams>({
      queryFn: apiQueryFunction((state: RootState, params) => ({
        params: {
          ...(extractSelectOptionsValues(params) || {}),
        },
        method: 'GET',
        url: state.appState.appData.urls.booking_offers.booking_offers,
        responseTransformer: (response: PaginationResponse<BookingOffer>) => response.results,
      })),
      providesTags: (result: BookingOffer[]) =>
        result
          ? [...result.map(({ id }) => ({ type: BOOKING_OFFER_TAG, id }) as const), BOOKING_OFFER_TAG]
          : [BOOKING_OFFER_TAG],
    }),
    saleBookingOffer: builder.mutation<ReceptionBookingDetails, string>({
      invalidatesTags: [BOOKING_OFFER_TAG],

      queryFn: apiQueryFunction((state: RootState, url: string) => ({
        method: 'POST',
        url: url,
      })),
    }),
  }),
})

export const { useGetBookingOffersQuery, useSaleBookingOfferMutation } = bookingOffersApi
