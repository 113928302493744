import * as React from 'react'
import { TechnicalOrder } from '@models/technical-orders'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { ReceptionTechnicalOrdersTableRow } from '@modules/reception-technical-orders/row'

interface Props {
  showUsers: boolean
  technicalOrders: TechnicalOrder[]
  setFilters: React.Dispatch<React.SetStateAction<TableFilters>>
  filters: TableFilters
  dataTestId?: string
}

const headers: TableHeaderValue[] = [
  { title: 'Numer zadania', sortField: 'number', width: 200 },
  { title: 'Lokal', sortField: 'unit', width: 150 },
  { title: 'Status', sortField: 'status', width: 200 },
  { title: 'Usterka', sortField: 'name', width: 300 },
  { title: 'Zgłaszający', sortField: 'created_by__name', width: 250 },
  { title: 'Wykonawca', sortField: 'users', width: 250 },
  { title: '' },
]
export const ReceptionTechnicalOrdersTable: React.FC<Props> = ({
  dataTestId,
  showUsers,
  filters,
  setFilters,
  technicalOrders,
}) => {
  const tableHeaders = React.useMemo(
    () => (showUsers ? headers : headers.filter(header => header.sortField !== 'users')),
    [showUsers],
  )

  return (
    <Table
      dataTestId={dataTestId}
      headerClassName="bg-white border-top-0 vertical-align-middle"
      className="table-hover w-100 table-fixed"
      headerValues={tableHeaders}
      isLoading={false}
      filters={filters}
      setFilters={setFilters}
      showPagination={false}
    >
      {technicalOrders.map(technicalOrder => (
        <ReceptionTechnicalOrdersTableRow
          showUsers={showUsers}
          key={technicalOrder.id}
          technicalOrder={technicalOrder}
        />
      ))}
    </Table>
  )
}
