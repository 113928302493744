import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import classnames from 'classnames'

interface Props<T> {
  onItemSelection: (item: T) => void
  textRenderer: (item: T) => React.ReactNode
  isItemSelected?: (item: T) => boolean
  items: T[]
  maximumListHeight: number
  title: string
  emptyListElement?: React.ReactNode
}

export const LocalSelectionMapItemsList = <T extends { id: number }>({
  onItemSelection,
  textRenderer,
  isItemSelected,
  items,
  maximumListHeight,
  title,
  emptyListElement,
}: Props<T>): JSX.Element => (
  <div className="local-selection-modal__apartments-list">
    <p className="font-12 text-darker-gray">{title}</p>
    <div>
      <div className="overflow-auto" style={{ maxHeight: maximumListHeight }}>
        {items.map((item: T) => (
          <IconWithText
            key={item.id}
            text={textRenderer(item)}
            textClass="text-darker-gray font-12 fw-semi-bold w-100"
            wrapperClassNames={classnames('rounded user-select-none lh-initial py-1 pl-1 w-100', {
              'bg-white-smoke': isItemSelected?.(item),
            })}
            icon={classnames('text-primary uil-check mr-2 opacity-0', {
              'opacity-100': isItemSelected?.(item),
            })}
            onClick={() => onItemSelection(item)}
          />
        ))}

        {items.length === 0 && emptyListElement}
      </div>
    </div>
  </div>
)
