import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { ReservationGuestData } from '@modules/reservations/details/base-data/guest/reservation-guest-data'
import { ReceptionBooking } from '@models/reception'
import { ClientDetailsModalAgreements } from '@modules/crm/clients/details-modal/base-data/agreements'
import { ClientUser } from '@models/clients'
import { useUpsellBookingDetailsRefresh } from '@modules/upselling/upsell-booking-details/use-upsellbooking-details-refresh'

interface Props {
  booking: ReceptionBooking
  client: ClientUser
}

export const UpsellBookingDetailsReservationGuestData = ({ booking, client }: Props): JSX.Element => {
  const { refresh } = useUpsellBookingDetailsRefresh()

  return (
    <div className="col-4">
      <IconWithText
        icon="uil-user font-24"
        text="Dane gościa"
        wrapperClassNames="text-secondary fw-semi-bold"
        textClass="font-18"
      />

      <div className="mt-3">
        <ReservationGuestData withTitle={true} booking={booking} clientId={client.id} />
        <hr className="mx-n2" />
        <span>Wyrażone zgody:</span>
        <div className="font-11">
          <ClientDetailsModalAgreements client={client} onRuleUpdate={refresh} />
        </div>
      </div>
    </div>
  )
}
