import * as React from 'react'
import { Button } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'

export const PackageVipPaymentsAddCode: React.FC = () => {
  const [handleToggleDialog] = useModal('PackageVipPaymentsAddCodeDialog')

  return (
    <Button color="green-dark ml-1" onClick={handleToggleDialog}>
      Opłać kodem
    </Button>
  )
}
