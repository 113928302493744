import * as React from 'react'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { FormProvider, useForm } from 'react-hook-form'
import { Row } from 'reactstrap'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { ColAuto } from '@hyper/col-auto'
import { FormSelect } from '@hyper/forms/form-select'
import { createSelectOption } from '@helpers/utils'
import { GenericVoucherFiltersParams } from '@modules/generic-vouchers/list/index'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { CustomReactSelectOption } from '@components/custom-react-select'

interface Props {
  filters: GenericVoucherFiltersParams
  defaultFilters: GenericVoucherFiltersParams
  setFilters: React.Dispatch<React.SetStateAction<GenericVoucherFiltersParams>>
}

const statusOptions: CustomReactSelectOption[] = [
  createSelectOption('Wstępny', 'initial'),
  createSelectOption('Potwierdzony', 'confirmed'),
]

export const GenericVoucherListFilters: React.FC<Props> = ({ filters, defaultFilters, setFilters }) => {
  const methods = useForm({
    defaultValues: defaultFilters,
  })
  const onSubmit = async payload => setFilters({ ...filters, ...payload })

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  return (
    <Row>
      <FormProvider {...methods}>
        <ColAuto style={{ width: 250 }} className="pl-0">
          <FormSelect
            options={statusOptions}
            name="status"
            label="Status"
            selectProps={{ isSelectMulti: true, isClearable: true }}
          />
        </ColAuto>
        <ColAuto className="px-0">
          <DateFromToInput label="Data utworzenia" startDateName="created_after" endDateName="created_before" />
        </ColAuto>
        <ColAuto className="px-0">
          <DateFromToInput label="Data wydania" startDateName="issue_date_after" endDateName="issue_date_before" />
        </ColAuto>
        <SearchFilter />
      </FormProvider>
    </Row>
  )
}
