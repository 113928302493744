import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { SubmitFilter } from '@modules/reception/common/filters/submit-filter'
import { HousekeepingToOrderFiltersParams } from '@modules/housekeeping/cleaning-orders/to-order/index'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { Form } from '@hyper/forms/form'

import { HousekeepingCleaningOrderBaseFilters } from '@modules/housekeeping/cleaning-orders/common/improvements-filters'

interface HousekeepingFiltersProps {
  defaultFilters: HousekeepingToOrderFiltersParams
  filters: HousekeepingToOrderFiltersParams
  setFilters: (filters: HousekeepingToOrderFiltersParams) => void
}

export const HousekeepingToOrderFilters: React.FC<HousekeepingFiltersProps> = ({
  filters,
  defaultFilters,
  setFilters,
}) => {
  const methods = useFormContext<HousekeepingToOrderFiltersParams>()

  const onSubmit = (payload: HousekeepingToOrderFiltersParams) => setFilters(payload)

  const handleReset = () => {
    methods.reset()
    setFilters(defaultFilters)
  }

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  return (
    <Form methods={methods} onSubmit={onSubmit} className="row form form-style-filter w-100 mr-0">
      <HousekeepingCleaningOrderBaseFilters className="ml-4" />
      <div className="d-flex ml-auto align-items-center">
        <SubmitFilter hideLabel handleReset={handleReset} formGroupClassName="mb-0" />
        <SearchFilter setValue={methods.setValue} hideLabel formGroupClassName="mb-0 mr-2" />
      </div>
    </Form>
  )
}
