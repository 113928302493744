import * as React from 'react'
import { NotificationEvents } from '@models/dashboard'
import { useWebSocket } from '@components/hooks/use-web-socket'
import { BandReaderResult } from '@models/reception'

interface Props {
  requestedId: string | null
  onScanningResultMessage: (scanningData: BandReaderResult) => void
}

export const BandsWebSocketHandler: React.FC<Props> = ({ onScanningResultMessage, requestedId }) => {
  useWebSocket<BandReaderResult>(
    (message, payload) => {
      switch (message) {
        case NotificationEvents.BAND_READER_RESULT:
          if (payload.uuid === requestedId) {
            onScanningResultMessage(payload)
          }
      }
    },
    [requestedId],
    [NotificationEvents.BAND_READER_RESULT],
  )
  return null
}
