import * as React from 'react'
import { Col, Row } from 'reactstrap'
import { FormPlainError } from '@hyper/forms'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { CreateImprovementOrderModalFormImprovementsRow } from '@modules/housekeeping/cleaning-orders/create-improvement-order/form-improvements-row'
import { CreateImprovementOrderModalFormInputs } from '@modules/housekeeping/cleaning-orders/create-improvement-order/create-improvement-order-modal'

export const CreateImprovementOrderModalFormImprovements: React.FC = () => {
  const { control } = useFormContext<CreateImprovementOrderModalFormInputs>()

  const { fields, append, remove } = useFieldArray<CreateImprovementOrderModalFormInputs>({
    control,
    name: 'items',
  })

  const handleAdd = () => append({ code: null, amount: 1 })

  return (
    <Row className="bg-grey form-transparent mb-2" style={{ marginLeft: -15, marginRight: -15 }}>
      <div className="hr hr-grey mb-2" />
      <Col md={12}>
        <h5>Ulepszenia</h5>
      </Col>
      {fields.map((row, index) => (
        <CreateImprovementOrderModalFormImprovementsRow index={index} remove={remove} key={index} />
      ))}
      <Col md={12} className="text-right mt-1">
        <button className="btn-pure text-dark text-semi-strong" type="button" onClick={handleAdd}>
          <i className="mdi mdi-plus" /> Dodaj ulepszenie
        </button>
        <FormPlainError name="items" />
      </Col>
      <div className="hr hr-grey mt-2" />
    </Row>
  )
}
