import * as React from 'react'
import { formatPrice } from '@helpers/utils'
import { ReceptionBookingNewFeedingPriceTooltipDiscounts } from '@modules/reception/checkin/step-feeding/new-feeding/tooltip/reception-booking-new-feeding-price-tooltip-discounts'
import { ReceptionBookingNewFeedingPriceTooltipBestseller } from '@modules/reception/checkin/step-feeding/new-feeding/tooltip/reception-booking-new-feeding-price-tooltip-bestseller'
import { ReceptionBookingNewFeedingPriceTooltipHistory } from '@modules/reception/checkin/step-feeding/new-feeding/tooltip/reception-booking-new-feeding-price-tooltip-history'
import {
  FeedingTooltipDetails,
  NewFeedingCalculationDetails,
} from '@modules/reception/checkin/step-feeding/new-feeding/models'
import { useFormContext, useWatch } from 'react-hook-form'
import { getFeedingChanges } from '@modules/reception/checkin/step-feeding/new-feeding/utils'
import { useSelector } from 'react-redux'
import { selectInitialFeedingCalculations } from '@store/slices/new-feeding-slice'

interface Props {
  details: FeedingTooltipDetails | null
  calculation: NewFeedingCalculationDetails
}

export const ReceptionBookingNEwFeedingPriceTooltipContent = ({ details, calculation }: Props): JSX.Element => {
  const { control } = useFormContext()
  const feedings = useWatch({ control })

  const initialFeedingCalculations = useSelector(selectInitialFeedingCalculations)

  const changes = getFeedingChanges(feedings, initialFeedingCalculations?.details ?? [])

  const hasChanges = changes.add_feedings.length || changes.delete_feedings.length

  const discounts = React.useMemo(() => {
    if (calculation.is_paid_by_wallet) return []

    const detailsDiscounts = details?.discounts ?? []
    if (calculation.confirmed) return detailsDiscounts
    return hasChanges ? calculation.discounts : detailsDiscounts
  }, [hasChanges, details?.discounts, calculation.discounts])

  return (
    <div className="py-2 font-11">
      <div className="px-2 border-bottom border-gray pb-2">
        <p className="d-flex align-items-center justify-content-between mb-1">
          <strong>Cena sprzedaży:</strong>
          <strong>{formatPrice(calculation.price_brutto ?? '0')}</strong>
        </p>

        <p className="d-flex align-items-center justify-content-between mb-0">
          <div>
            Cena katalogowa
            {calculation.related_date
              ? ' w bestsellerze'
              : calculation.kind === 'dinner'
                ? ' samej obiadokolacji'
                : ' samego śniadania'}
            :
          </div>

          <span>
            {formatPrice(
              (calculation.related_date
                ? calculation.original_price_brutto_in_hb
                : calculation.original_price_brutto) ?? '0',
            )}
          </span>
        </p>
      </div>

      <ReceptionBookingNewFeedingPriceTooltipDiscounts discounts={discounts} />

      {calculation.related_date && (
        <ReceptionBookingNewFeedingPriceTooltipBestseller
          related_date={calculation.related_date}
          kind={calculation.kind}
        />
      )}

      <div className="position-relative">
        {details ? (
          <ReceptionBookingNewFeedingPriceTooltipHistory historyRows={details.history} />
        ) : (
          <p className="font-11 mt-3 text-center">Brak historii zmian wybranego posiłku.</p>
        )}
      </div>
    </div>
  )
}
