import * as React from 'react'
import { CustomInput, Row } from 'reactstrap'
import { FormInput, FormPlain } from '@hyper/forms'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { BarrierTicketModalFormDateTimeRange } from '@modules/barriers/barrier-ticket-modal/form-datetime-range'
import { BarrierTicket } from '@models/barriers'
import { extractInnerRef } from '@helpers/forms'
import { useFormContext } from 'react-hook-form'
import { BarrierTicketFormInputs } from '@modules/barriers/barrier-ticket-modal/index'
import { FormInputPhone } from '@hyper/forms/form-input-phone'

interface Props {
  ticket?: BarrierTicket
}

export const BarrierTicketModalForm = ({ ticket }: Props): JSX.Element => {
  const resorts = useAuthenticatedUser().resorts
  const { register } = useFormContext<BarrierTicketFormInputs>()

  return (
    <Row>
      <BarrierTicketModalFormDateTimeRange ticket={ticket} />
      <hr className="w-100 mt-0" />
      <Row className="w-100 mx-0">
        <FormInput label="Numer rejestracyjny pojazdu" name="register_number" type="text" colSize={6} />
        <FormInputPhone name="phone" colSize={6} label="Numer telefonu" />
      </Row>

      <hr className="w-100 mt-0" />
      <FormPlain colSize={12} name="resorts">
        <label className="d-block">Dostęp w ośrodku</label>

        <div className="d-flex flex-wrap">
          {resorts.map(resort => (
            <div key={resort.id} className="mb-1 d-block col-4 px-0">
              <CustomInput
                type="checkbox"
                id={`resorts-${resort.id}`}
                value={String(resort.id)}
                label={resort.name}
                {...extractInnerRef(register('resorts'))}
              />
            </div>
          ))}
        </div>
      </FormPlain>
      <hr className="w-100 mt-0" />
      <FormInput label="Opis pojazdu" name="description" type="textarea" />
    </Row>
  )
}
