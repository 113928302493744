import * as React from 'react'
import { BarrierTicket } from '@models/barriers'
import Table from '@components/table/table'
import { RootState, useAppSelector } from '@store/index'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { Tooltip } from 'reactstrap'
import { BarriersBaseListTableRowEdit } from '@modules/barriers/base-list/table-row-edit'
import { BarriersBaseListTableRowRemove } from '@modules/barriers/base-list/table-row-remove'
import { useToggle } from '@components/hooks/use-toggle'

interface Props {
  ticket: BarrierTicket
  name: string
  canChange: boolean
  canRemove: boolean
  canPrint: boolean
}

export const BarriersBaseListTableRow = ({ ticket, name, canChange, canRemove, canPrint }: Props): JSX.Element => {
  const resorts = useAppSelector((state: RootState) => state.appState.appData.resorts)
  const iconStyles = { opacity: !ticket.description ? 0.3 : 1 }
  const [tooltipOpen, toggleOpen] = useToggle()

  const resortList = React.useMemo(() => resorts.filter(row => ticket.resorts.includes(row.id)), [resorts, ticket])
  const tooltipId = `notes-tooltip-${ticket.id}`

  const handlePrintDocument = () => {
    window.open(ticket.urls.print, '_blank')
  }

  return (
    <Table.Row>
      <Table.Cell>{ticket.register_number}</Table.Cell>
      <Table.Cell>{ticket.kind_display}</Table.Cell>
      <Table.Cell>
        {resortList.map(row => (
          <div key={row.id}>{row.name}</div>
        ))}
      </Table.Cell>
      <Table.Cell>
        {toDefaultDateTimeFormat(ticket.datetime_from)} - {toDefaultDateTimeFormat(ticket.datetime_to)}
      </Table.Cell>
      <Table.Cell>
        {ticket.description && (
          <Tooltip
            placement="top"
            isOpen={tooltipOpen}
            target={tooltipId}
            toggle={toggleOpen}
            className="reception__notes-tooltip"
            autohide={true}
          >
            <div className="p-2 font-11">{ticket.description}</div>
          </Tooltip>
        )}
        <i className="uil-file-check-alt font-18 text-muted" style={iconStyles} id={tooltipId} />
      </Table.Cell>
      <Table.Cell className="text-right">
        {canChange && ticket.can_update && <BarriersBaseListTableRowEdit name={name} ticket={ticket} />}
        {canRemove && ticket.can_remove && <BarriersBaseListTableRowRemove ticket={ticket} />}
        {canPrint && ticket.urls.print && (
          <i
            className="uil-print cursor-pointer text-muted font-16 ml-1"
            title="Drukuj kartę parkingową"
            onClick={handlePrintDocument}
          />
        )}
      </Table.Cell>
    </Table.Row>
  )
}
