import * as React from 'react'
import { ReceptionBooking } from '@models/reception'
import { useModal } from '@components/modals/use-modal'
import classNames from 'classnames'

interface ReceptionNotificationSmsIconProps {
  booking: ReceptionBooking
}

export const ReceptionNotificationSmsIcon: React.FC<ReceptionNotificationSmsIconProps> = ({ booking }) => {
  const [handleToggle] = useModal('ReceptionNotificationSmsDialog', {
    booking,
  })

  if (!booking.phone) {
    return null
  }

  return (
    <i
      className={classNames('uil-comment-alt-message text-secondary font-16 mr-1 ml-2 cursor-pointer', {
        'not-clickable': !booking.sms_notifications_enabled,
      })}
      role="booking-sms-notification"
      title="Wyślij wiadomość SMS"
      onClick={handleToggle}
    />
  )
}
