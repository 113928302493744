import * as React from 'react'
import { Row } from 'reactstrap'
import { FormRadio, FormRadioGroup } from '@hyper/forms/form-radio'
import { AgreementCreateFormCollapsableStatements } from '@modules/hr/agreement/create/steps/collapsable-statements'
import { useFormContext, useWatch } from 'react-hook-form'
import { FormInput } from '@hyper/forms'
import DateRangeInput from '@components/date/date-range-input'
import { AlertDanger } from '@components/alerts'
import { AgreementFormInputs } from '@modules/hr/agreement/create/modal'
import { AgreementDocumentKey } from '@modules/hr/agreement/models'

interface Props {
  chunk?: AgreementDocumentKey
}

export const AgreementCreateFormStatements = ({ chunk }: Props): JSX.Element => {
  const { control, setValue } = useFormContext<AgreementFormInputs>()

  const nationalityVersion = useWatch({ control, name: 'nationality' })
  const isForeignerVersion = nationalityVersion.value.toLowerCase() !== 'pl'

  const [
    statementUnderEmploymentContract,
    statementOverEmploymentContractSecond,
    statementMaternityLeave,
    statementStudentStatus,
    statementRetirementPension,
    statementDisability,
  ] = useWatch({
    control,
    name: [
      'statement_under_employment_contract',
      'statement_over_employment_contract_second',
      'statement_maternity_leave',
      'statement_student_status',
      'statement_retirement_pension',
      'statement_disability',
    ],
  })

  return (
    <Row>
      {(!chunk || chunk === 'statements') && (
        <>
          <strong className="mx-auto mb-2">Oświadczam, że:</strong>

          <AgreementCreateFormCollapsableStatements
            label="Zatrudnienie"
            kind="statement_under_employment_contract"
            fields={[
              'statement_under_employment_lower_salary',
              'statement_over_employment_contract_second',
              'statement_over_employment_contract_second_salary',
              'statement_under_employment_contract',
              'statement_under_employment_contract_name',
            ]}
          >
            <FormRadioGroup
              formPlainProps={{ colSize: 10 }}
              name="statement_under_employment_contract"
              label={
                <span className="font-weight-normal">
                  <strong>Jestem / Nie jestem </strong> zatrudniony/-a na podstawie umowy o pracę z wynagrodzeniem
                  miesięcznym równym lub wyższym od minimalnego wynagrodzenia za pracę
                </span>
              }
            >
              <FormRadio name="statement_under_employment_contract" className="mr-2" value="yes" label="Jestem" />
              <FormRadio name="statement_under_employment_contract" value="no" label="Nie jestem" />
            </FormRadioGroup>

            {statementUnderEmploymentContract === 'yes' && (
              <FormInput
                name="statement_under_employment_contract_name"
                colSize={6}
                label="proszę o podanie nazwy pracodawny"
                labelProps={{ className: 'font-weight-normal' }}
              />
            )}

            <hr className="w-100 mt-0" />

            <FormRadioGroup
              formPlainProps={{ colSize: 10 }}
              name="statement_under_employment_lower_salary"
              label={
                <span className="font-weight-normal">
                  <strong>Jestem / Nie jestem </strong> zatrudniony/-a na podstawie umowy o pracę z wynagrodzeniem
                  miesięcznym niższym od minimalnego wynagrodzenia za pracę
                </span>
              }
            >
              <FormRadio name="statement_under_employment_lower_salary" className="mr-2" value="yes" label="Jestem" />
              <FormRadio name="statement_under_employment_lower_salary" value="no" label="Nie jestem" />
            </FormRadioGroup>

            <FormRadioGroup
              formPlainProps={{ colSize: 10 }}
              name="statement_over_employment_contract_second"
              label={
                <span className="font-weight-normal">
                  <strong>Jestem / Nie jestem </strong> zatrudniony/-a na podstawie innej umowy zlecenie, (a u obecnego
                  zleceniodawcy zleceniobiorca ma co najmniej dwie umowy zlecenie to proszę o podanie dokładnej kwoty
                  podstawy składek z umowy zlecenia
                </span>
              }
            >
              <FormRadio name="statement_over_employment_contract_second" className="mr-2" value="yes" label="Jestem" />
              <FormRadio name="statement_over_employment_contract_second" value="no" label="Nie jestem" />
            </FormRadioGroup>

            {statementOverEmploymentContractSecond === 'yes' && (
              <FormInput
                name="statement_over_employment_contract_second_salary"
                colSize={10}
                type="currency"
                setValue={setValue}
                labelProps={{ className: 'font-weight-normal' }}
              />
            )}
          </AgreementCreateFormCollapsableStatements>

          <AgreementCreateFormCollapsableStatements
            label="Działalność gospodarcza"
            kind="statement_non_agricultural"
            fields={['statement_non_agricultural']}
          >
            <FormRadioGroup
              name="statement_non_agricultural"
              label={
                <span className="font-weight-normal">
                  <strong>Prowadzę / nie prowadzę </strong>pozarolniczą działalność gospodarczą w okresie wykonywania
                  umowy zlecenia
                </span>
              }
            >
              <FormRadio name="statement_non_agricultural" className="mr-2" value="yes" label="Prowadzę" />
              <FormRadio name="statement_non_agricultural" value="no" label="Nie prowadzę" />
            </FormRadioGroup>
          </AgreementCreateFormCollapsableStatements>

          <AgreementCreateFormCollapsableStatements
            label="Inne tytuły ubezpieczeń"
            kind="job_leave"
            fields={[
              'statement_maternity_leave',
              'statement_maternity_leave_date_from',
              'statement_maternity_leave_date_to',
            ]}
          >
            <FormRadioGroup
              formPlainProps={{ colSize: 10 }}
              name="statement_maternity_leave"
              label={
                <span className="font-weight-normal">
                  <strong>Przebywam / Nie przebywam </strong>na urlopie MACIERZYŃSKIM / WYCHOWAWCZYM* w czasie
                  wykonywania umowy zlecenie
                </span>
              }
            >
              <FormRadio name="statement_maternity_leave" className="mr-2" value="yes" label="Przebywam" />
              <FormRadio name="statement_maternity_leave" value="no" label="Nie przebywam" />
            </FormRadioGroup>
            {statementMaternityLeave === 'yes' && (
              <div className="mx-2 mb-2">
                <label className="mb-1 font-weight-normal">proszę podać okres trwania urlopu macierzyńskiego</label>
                <DateRangeInput
                  startDateName="statement_maternity_leave_date_from"
                  endDateName="statement_maternity_leave_date_to"
                  wrapperClassName="is-range"
                  placeholder="Zaznacz okres"
                />
              </div>
            )}
          </AgreementCreateFormCollapsableStatements>

          <AgreementCreateFormCollapsableStatements
            label="Nauka/Szkoła"
            kind="academic"
            fields={['statement_student_status']}
          >
            <FormRadioGroup
              formPlainProps={{ colSize: 10 }}
              name="statement_student_status"
              label={
                <span className="font-weight-normal">
                  <strong>Jestem / Nie jestem </strong>uczniem/studentem, który nie ukończył 26 roku życia (posiadam
                  status ucznia/studenta)
                </span>
              }
            >
              <FormRadio name="statement_student_status" className="mr-2" value="yes" label="Jestem" />
              <FormRadio name="statement_student_status" value="no" label="Nie jestem" />
            </FormRadioGroup>

            {statementStudentStatus === 'yes' && (
              <AlertDanger icon="uil-exclamation-triangle" className="font-11 px-2 mx-2" iconClassName="mr-2 font-15">
                <div>
                  <span className="fw-semi-bold">
                    proszę dostarczyć skan/kopię ważnej legitymacji lub zaświadczenia z uczelni potwierdzające status
                    studenta**
                  </span>
                  <p className="font-11 mt-1 mb-0">
                    **do czasu dostarczenia jednego z wyżej wymienionych dokumentów, osoba jest traktowana jako
                    nieposiadająca statusu studenta.
                  </p>
                </div>
              </AlertDanger>
            )}
          </AgreementCreateFormCollapsableStatements>

          <AgreementCreateFormCollapsableStatements
            label="Renta, emerytura, stopień niepełnosprawności"
            kind="statement_retirement_pension"
            fields={[
              'statement_retirement_pension',
              'statement_retirement_pension_number',
              'statement_retirement_pension_organization',
              'statement_disability',
              'statement_disability_level',
            ]}
          >
            <FormRadioGroup
              formPlainProps={{ colSize: 10 }}
              name="statement_retirement_pension"
              label={
                <span className="font-weight-normal">
                  <strong>Posiadam / Nie posiadam </strong>ustalone prawo do świadczenia emerytalnego/rentowego
                </span>
              }
            >
              <FormRadio name="statement_retirement_pension" className="mr-2" value="yes" label="Posiadam" />
              <FormRadio name="statement_retirement_pension" value="no" label="Nie posiadam" />
            </FormRadioGroup>

            {statementRetirementPension === 'yes' && (
              <div className="d-flex">
                <FormInput
                  name="statement_retirement_pension_number"
                  colSize={4}
                  label="Nr. decyzji"
                  labelProps={{ className: 'font-weight-normal' }}
                />
                <FormInput
                  name="statement_retirement_pension_organization"
                  colSize={8}
                  label="Nazwa i adres organu emerytalnego/rentowego"
                  labelProps={{ className: 'font-weight-normal' }}
                />
              </div>
            )}

            <hr className="w-100 mt-0" />

            <FormRadioGroup
              formPlainProps={{ colSize: 10 }}
              name="statement_disability"
              label={
                <span className="font-weight-normal">
                  <strong>Posiadam / Nie posiadam </strong>orzeczenie o stopniu niepełnosprawności
                </span>
              }
            >
              <FormRadio name="statement_disability" className="mr-2" value="yes" label="Posiadam" />
              <FormRadio name="statement_disability" value="no" label="Nie posiadam" />
            </FormRadioGroup>

            {statementDisability === 'yes' && (
              <FormRadioGroup
                formPlainProps={{ colSize: 10 }}
                name="statement_disability_level"
                label={
                  <span className="font-weight-normal">
                    <strong>Posiadam / Nie posiadam </strong>orzeczenie o stopniu niepełnosprawności
                  </span>
                }
              >
                <FormRadio name="statement_disability_level" value="light" label="lekki" />
                <FormRadio name="statement_disability_level" value="medium" label="umiarkowany" className="mx-2" />
                <FormRadio name="statement_disability_level" value="high" label="znaczny" />
              </FormRadioGroup>
            )}
          </AgreementCreateFormCollapsableStatements>

          <AgreementCreateFormCollapsableStatements
            borderless={!isForeignerVersion}
            label="Ubezpieczenie dobrowolne"
            kind="insurance"
            fields={['statement_optional_insurance']}
          >
            <FormRadioGroup
              formPlainProps={{ colSize: 10 }}
              name="statement_optional_insurance"
              label={
                <span className="font-weight-normal">
                  <strong>Wnoszę / Nie wnoszę </strong>o objęcie mnie dobrowolnym ubezpieczeniem społecznym z tytułu
                  wykonywania umowy zlecenie”
                </span>
              }
            >
              <FormRadio name="statement_optional_insurance" className="mr-2" value="yes" label="Wnoszę" />
              <FormRadio name="statement_optional_insurance" value="no" label="Nie wnoszę" />
            </FormRadioGroup>
          </AgreementCreateFormCollapsableStatements>
          {isForeignerVersion && (
            <AgreementCreateFormCollapsableStatements
              borderless
              label="Informacje dodatkowe"
              kind="statement_foreign"
              fields={['statement_foreign']}
            >
              <FormRadioGroup
                formPlainProps={{ colSize: 10 }}
                name="statement_foreign"
                label={
                  <span className="font-weight-normal">
                    <strong>Jestem / Nie jestem </strong>rezydentem Polski (posiadam nieograniczony obowiązek podatkowy)
                    – mieszkam w Polsce (moje centrum interesów życiowych znajduje się w Polsce)
                  </span>
                }
              >
                <FormRadio name="statement_foreign" className="mr-2" value="yes" label="Wnoszę" />
                <FormRadio name="statement_foreign" value="no" label="Nie wnoszę" />
              </FormRadioGroup>
            </AgreementCreateFormCollapsableStatements>
          )}
        </>
      )}
    </Row>
  )
}
