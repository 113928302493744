import * as React from 'react'
import { FloorHeatingDetailsHistoryFilters } from '@modules/floor-heating/details/history/history-filters'
import { FloorHeatingDetailsHistoryChart } from '@modules/floor-heating/details/history/history-chart'
import { ContentLoader } from '@components/content-loader'
import { startOfToday, subDays } from 'date-fns'
import { useFloorHeatingAppData } from '@modules/floor-heating/use-floor-heating-app-data'
import { commonObjectGet } from '@store/actions/generic-actions'
import { useTableFilters } from '@components/table/use-table-filters'
import { EnhancedFloorHeatingMeasurement } from '@modules/floor-heating/models'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'

const defaultFilters = {
  date_from: subDays(startOfToday(), 14),
  date_to: startOfToday(),
} as any

interface Props {
  apartmentId: string | undefined
}

export const FloorHeatingDetailsHistoryChartBox = ({ apartmentId }: Props): JSX.Element => {
  const [chartData, setChartData] = React.useState<EnhancedFloorHeatingMeasurement[]>([])

  const { appData } = useFloorHeatingAppData()

  const fetchChartData = async payload => {
    if (!appData || !apartmentId) return

    setChartData(
      await commonObjectGet(appData.urls.aggregated.replace('replace-me', apartmentId), {
        date_from: payload.date_from,
        date_to: payload.date_to,
      }),
    )
  }

  const { isLoading, filters, setFilters } = useTableFilters({
    defaultFilters,
    action: fetchChartData,
  })

  useDidUpdateEffect(() => {
    fetchChartData(filters)
  }, [appData?.urls.aggregated])

  return (
    <div className="position-relative">
      <FloorHeatingDetailsHistoryFilters
        defaultFilters={defaultFilters}
        filters={filters}
        setFilters={setFilters}
        maxDate={startOfToday()}
      />

      <ContentLoader isLoading={isLoading}>
        <FloorHeatingDetailsHistoryChart measurements={chartData} />
        {chartData.length === 0 && (
          <p className="position-absolute" style={{ top: '45%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            Brak danych do wyświetlenia
          </p>
        )}
      </ContentLoader>
    </div>
  )
}
