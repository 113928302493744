import * as React from 'react'
import { EndlessHolidayTransactionsFilters } from '@modules/endless-holiday/transactions/filters'
import { EndlessHolidayTransactionsTable } from '@modules/endless-holiday/transactions/table'
import { BaseTableFilters } from '@components/table/use-handle-table-filters-change'
import { useQueryWithPaginationResponse } from '@components/hooks/use-query-hooks'
import {
  useGetEndlessHolidayTransactionDetailsQuery,
  useGetEndlessHolidayTransactionsQuery,
} from '@api/endless-holiday'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { ProductPackage, ProductPackageAggregation, ProductPackageDetails } from '@modules/endless-holiday/models'
import { EndlessHolidayViewParamsTransactionDetails } from '@modules/endless-holiday/transactions/view-details-modal'
import { TypedQueryResult } from '@api/base'
import { useAppData } from '@components/hooks/use-app-data'
import { ProductPackageSet } from '@models/products'

export interface EndlessHolidayTransactionFormInputs extends BaseTableFilters {
  status: CustomReactSelectOption[]
  source_marketing: CustomReactSelectOption | null
  accommodation_type: CustomReactSelectOption | null
  kind: CustomReactSelectOption | null
  created_before: Date | null
  created_after: Date | null
  payment_before: Date | null
  payment_after: Date | null
}

const defaultFilters: EndlessHolidayTransactionFormInputs = {
  ordering: '-created',
  page: 1,
  page_size: 10,
  search: '',
  status: [],
  source_marketing: null,
  accommodation_type: null,
  kind: null,
  created_before: null,
  created_after: null,
  payment_before: null,
  payment_after: null,
}

interface Props {
  id: string | undefined
  productPackageSets: ProductPackageSet[]
}

export const EndlessHolidayTransactions = ({ id, productPackageSets }: Props): React.ReactNode => {
  const { urls } = useAppData()
  const [filters, setFilters] = React.useState<EndlessHolidayTransactionFormInputs>(defaultFilters)

  const { data, aggregation, isLoading } = useQueryWithPaginationResponse<ProductPackage, ProductPackageAggregation>(
    useGetEndlessHolidayTransactionsQuery(filters),
    { withAggregation: true },
  )

  const { data: productDetails } = useGetEndlessHolidayTransactionDetailsQuery(
    `${urls.product_packages.product_package_list}${id}/`,
    { skip: !id },
  ) as TypedQueryResult<ProductPackageDetails>

  return (
    <div>
      {productDetails && <EndlessHolidayViewParamsTransactionDetails details={productDetails} />}
      <EndlessHolidayTransactionsFilters
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        productPackageSets={productPackageSets}
      />
      <EndlessHolidayTransactionsTable
        isLoading={isLoading}
        filters={filters}
        setFilters={setFilters}
        transactions={data}
        aggregation={aggregation}
        productPackageSets={productPackageSets}
      />
    </div>
  )
}
