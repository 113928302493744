import * as React from 'react'
import { TechnicalOrder } from '@models/technical-orders'
import Table from '@components/table/table'
import { Button } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'

interface Props {
  technicalOrder: TechnicalOrder
}

export const GuestsTechnicalOrdersTableRowActions: React.FC<Props> = ({ technicalOrder }) => {
  const [handleClick] = useModal('GuestsTechnicalOrderStatusModal', { technicalOrder })

  return (
    <Table.Cell className="text-right vertical-align-middle text-nowrap" style={{ width: 100 }}>
      {technicalOrder.status !== 'done' && (
        <Button color={technicalOrder.status === 'new' ? 'green' : 'primary'} onClick={handleClick}>
          Zmień status
        </Button>
      )}
    </Table.Cell>
  )
}
