import * as React from 'react'
import { Card, CardBody } from 'reactstrap'
import { usePaymentAppData } from '@modules/payment-registers/hooks/use-payment-app-data'
import { PaymentRegisterPointListRow } from '@modules/payment-registers/cashbox-day-details/payment-register-point-list/row'
import { PaymentDayReportDetails } from '@models/payments'
import { parseISODate, toDefaultDateFormat } from '@helpers/date-helper'
import { subDays } from 'date-fns'

interface Props {
  paymentDayReportDetails: PaymentDayReportDetails
}

export const PaymentRegisterPointList = ({ paymentDayReportDetails }: Props): JSX.Element => {
  const { payment_register_points } = usePaymentAppData()

  const points = payment_register_points.filter(row => row.resort_id === paymentDayReportDetails.resort_id)
  const paymentFromDay = React.useMemo(
    () => toDefaultDateFormat(subDays(parseISODate(paymentDayReportDetails.date) as Date, 1)),
    [paymentDayReportDetails.date],
  )

  return (
    <Card>
      <CardBody>
        <h4 className="text-secondary mb-0">Rozliczenie punktów</h4>
        <small>z dnia {paymentFromDay}</small>

        <table className="w-100">
          <tbody>
            {points.map(row => (
              <PaymentRegisterPointListRow paymentDayReportDetails={paymentDayReportDetails} key={row.id} point={row} />
            ))}
          </tbody>
        </table>
      </CardBody>
    </Card>
  )
}
