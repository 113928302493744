import * as React from 'react'
import { ClientDetails, ClientRuleKeyword, ClientUser } from '@models/clients'
import { useAppDispatch } from '@store/index'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectPatch } from '@store/actions/generic-actions'
import { setClientDetails } from '@store/actions/crm-actions'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { YesNoBadge } from '@components/badges/yes-no'
import { toTextDateTimeFormat } from '@helpers/date-helper'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import classNames from 'classnames'

interface Props {
  keyword: ClientRuleKeyword
  client: ClientUser
  acceptationDateClassName?: string
  isDisabled?: boolean
  onRuleUpdate?: () => void
}

export const ClientDetailsModalAgreementRow = ({
  keyword,
  client,
  acceptationDateClassName,
  isDisabled,
  onRuleUpdate,
}: Props) => {
  const user = useAuthenticatedUser()
  const dispatch = useAppDispatch()

  const rule = client.profile?.rules.find(rule => rule.keyword === keyword)

  const canUpdateRules = user.hasPerm(UserPermission.ClientCanChangeRules)

  const { action: toggleIsRuleRequired, isLoading } = useApiRequest(async () => {
    if (!rule) return

    const refreshedClientDetails = await commonObjectPatch<ClientDetails>(client.urls.rules, {
      [rule.keyword]: !rule.accepted,
    })

    dispatch(setClientDetails(refreshedClientDetails))
    onRuleUpdate?.()
  })

  return (
    <CommonObjectConfirmAction
      title={rule?.accepted ? 'Odznacz zgodę' : 'Zaznacz zgodę'}
      isLoading={isLoading}
      disabled={!canUpdateRules || isDisabled}
      handleAccept={toggleIsRuleRequired}
      message={
        rule?.accepted
          ? 'Czy na pewno chcesz usunąć zgodę wyrażoną przez gościa?'
          : 'Czy na pewno chcesz wyrazić zgodę w imieniu gościa?'
      }
    >
      <span>
        <YesNoBadge value={!!rule?.accepted} className={classNames({ 'cursor-pointer': canUpdateRules })} />
        {rule?.date && (
          <span className={classNames('font-10', acceptationDateClassName)}>{toTextDateTimeFormat(rule.date)}</span>
        )}
      </span>
    </CommonObjectConfirmAction>
  )
}
