import * as React from 'react'
import { FormSelect } from '@hyper/forms/form-select'
import { CleaningOrder, HousekeepingCleaningCompanyWorker } from '@modules/housekeeping/models'
import { createSelectOption, getById } from '@helpers/utils'
import { useHousekeepingCleaningCompanies } from '@modules/housekeeping/users/use-housekeeping-cleaning-companies'
import { CustomReactSelectOption } from '@components/custom-react-select'

interface Props {
  cleaningOrders: CleaningOrder[]
  resortId: number
}

export const HousekeepingOrderTableHeaderWorker = ({ cleaningOrders, resortId }: Props): JSX.Element => {
  const companies = useHousekeepingCleaningCompanies(resortId)

  const availableWorkers = React.useMemo(
    () =>
      cleaningOrders.reduce((acc, order: CleaningOrder) => {
        const workerCompany = getById(companies, order.company_id)
        if (!workerCompany) return acc

        const isAdded = (worker: HousekeepingCleaningCompanyWorker) =>
          acc.some((el: CustomReactSelectOption) => el.value === worker.id)

        const worker = workerCompany.workers.filter(worker => order.users.includes(worker.id) && !isAdded(worker))
        return [...acc, ...worker.map(worker => createSelectOption(worker.name, worker.id))]
      }, []),
    [cleaningOrders, resortId],
  )

  return (
    <th className="py-1 border-top-0 fw-semi-bold" style={{ width: 200 }}>
      <FormSelect
        options={availableWorkers}
        selectProps={{ placeholder: 'Wykonawca', isClearable: true, isSearchable: false }}
        name="user"
        formPlainProps={{ formGroupClassName: 'mb-0', colClassName: 'px-0' }}
      />
    </th>
  )
}
