import { ContextMenu } from '@components/context-menu'
import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { TimelineBooking } from '@store/reducers/timeline-reducers'
import timelineHelper from '@helpers/timeline-helper'
import { EditModeType } from '@modules/reservations/timeline/timeline-item-basic'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { useModal } from '@components/modals/use-modal'

interface Props {
  booking: TimelineBooking
  onEditModeChange: (editMode: EditModeType | null) => void
  isFiltered: boolean
}

export const TimelineItemContextMenuItems = ({ booking, onEditModeChange, isFiltered }: Props): JSX.Element => {
  const isEditAllowed = timelineHelper.isBookingEditable(booking) && !isFiltered

  const [showBlockadeDeleteModal] = useModal('TimelineBlockadeRemoveModal', { booking })

  const user = useAuthenticatedUser()

  const handleEditModeChange = (editMode: EditModeType) => () => {
    onEditModeChange(editMode)
  }

  return (
    <>
      {/*<ContextMenu.Item disabled={!isEditAllowed}>*/}
      {/*  <IconWithText icon="uil-repeat mr-2" text="Zamień rezerwacje" textClass="font-12" />*/}
      {/*</ContextMenu.Item>*/}
      <ContextMenu.Item disabled={!isEditAllowed} onClick={handleEditModeChange('item')}>
        <IconWithText icon="uil-expand-arrows-alt mr-2" text="Przesuń rezerwację" textClass="font-12" />
      </ContextMenu.Item>
      <ContextMenu.Item disabled={!isEditAllowed} onClick={handleEditModeChange('resizer')}>
        <IconWithText icon="uil-arrows-merge mr-2" text="Zmień długość pobytu" textClass="font-12" />
      </ContextMenu.Item>
      <ContextMenu.Item href={booking.urls.details} target="_blank">
        <IconWithText icon="uil-notes mr-2 text-white" text="Pokaż kartę rezerwacji" textClass="font-12 text-white" />
      </ContextMenu.Item>
      {user.hasPerm(UserPermission.ApartmentCanRemoveBlockApartment) && booking.status === 'blocked' && (
        <ContextMenu.Item onClick={showBlockadeDeleteModal}>
          <IconWithText icon="uil-trash-alt mr-2" text="Usuń blokadę" textClass="font-12" />
        </ContextMenu.Item>
      )}
    </>
  )
}
