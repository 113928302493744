import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@store/index'
import { ShopAppData, ShopWarehouseInvoice } from '@modules/shop/models'
import { Receipt, ShopProduct } from '@models/shop'
import {
  confirmShopReceipt,
  createShopReceipt,
  deleteShopReceipt,
  getShopAppData,
  getShopProducts,
  getShopReceipt,
  getWarehouseInvoices,
  recalculateShopReceipt,
  updateShopReceipt,
} from '@store/actions/shop-actions'

interface ShopState {
  appData: ShopAppData
  invoices: EntityState<ShopWarehouseInvoice>
  products: EntityState<ShopProduct>
  receipt: Receipt | undefined
}

const productsAdapter = createEntityAdapter<ShopProduct>()
const invoicesAdapter = createEntityAdapter<ShopWarehouseInvoice>()

const initialState: ShopState = {
  appData: {
    status: 'unknown',
    tickets_gross_value: 0,
    providers: [],
    warehouses: [],
  },
  receipt: undefined,
  invoices: invoicesAdapter.getInitialState(),
  products: productsAdapter.getInitialState(),
}

export const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    clearShopReceipt(state) {
      state.receipt = undefined
    },
    setShopReceipt(state, action: PayloadAction<Receipt>) {
      if (state.receipt?.id === action.payload.id) {
        state.receipt = action.payload
      }
    },
    setShopAppData(state, action: PayloadAction<ShopAppData>) {
      state.appData = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getShopAppData.fulfilled, (state, action) => {
      state.appData = { ...state.appData, ...action.payload, status: 'ready' }
    })
    builder.addCase(getWarehouseInvoices.fulfilled, (state, action) => {
      invoicesAdapter.setAll(state.invoices, action.payload)
    })
    builder.addCase(getShopProducts.fulfilled, (state, action) => {
      productsAdapter.setAll(state.products, action.payload)
    })
    builder.addCase(getShopReceipt.fulfilled, (state, action) => {
      state.receipt = action.payload
    })
    builder.addCase(createShopReceipt.fulfilled, (state, action) => {
      state.receipt = action.payload
    })
    builder.addCase(confirmShopReceipt.fulfilled, (state, action) => {
      state.receipt = action.payload
    })
    builder.addCase(recalculateShopReceipt.fulfilled, (state, action) => {
      state.receipt = action.payload
    })
    builder.addCase(updateShopReceipt.fulfilled, (state, action) => {
      state.receipt = action.payload
    })
    builder.addCase(deleteShopReceipt.fulfilled, state => {
      state.receipt = undefined
    })
  },
})

export const { clearShopReceipt, setShopReceipt, setShopAppData } = shopSlice.actions

export const { selectAll: shopWarehouseInvoicesSelector } = invoicesAdapter.getSelectors(
  (state: RootState) => state.shopState.invoices,
)

export const { selectAll: shopProductsSelectorSelector } = productsAdapter.getSelectors(
  (state: RootState) => state.shopState.products,
)

export default shopSlice.reducer
