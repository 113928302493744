import * as React from 'react'
import { NavigationTabs, NavigationTabsButton } from '@components/navigation-tabs'
import { ScrollableModalTab, useModalWithScrollableTabs } from '@components/hooks/use-modal-with-scrollable-tabs'

type Tab = 'data' | 'bookings' | 'products' | 'history_and_notes'

interface Props {
  dataRef: React.RefObject<HTMLElement>
  productsRef: React.RefObject<HTMLElement>
  bookingsRef: React.RefObject<HTMLElement>
  historyAndNotesRef: React.RefObject<HTMLElement>
}

export const ClientDetailsTabs: React.FC<Props> = ({ dataRef, historyAndNotesRef, bookingsRef, productsRef }) => {
  const [tab, setTab] = React.useState<Tab>('data')

  const tabs: ScrollableModalTab<Tab>[] = [
    { ref: dataRef, name: 'data' },
    { ref: bookingsRef, name: 'bookings' },
    { ref: historyAndNotesRef, name: 'history_and_notes' },
    { ref: productsRef, name: 'products' },
  ]

  const { handleTabClick } = useModalWithScrollableTabs<Tab>({ modalId: 'client-details-modal', setTab, tabs })

  const buttons: NavigationTabsButton[] = [
    {
      title: 'Dane konta',
      isActive: tab == 'data',
      onClick: handleTabClick('data'),
    },
    {
      title: 'Rezerwacje',
      isActive: tab == 'bookings',
      onClick: handleTabClick('bookings'),
    },
    {
      title: 'Produkty',
      isActive: tab == 'products',
      onClick: handleTabClick('products'),
    },
    {
      title: 'Historie zmian i notatki',
      isActive: tab == 'history_and_notes',
      onClick: handleTabClick('history_and_notes'),
    },
  ]

  return (
    <div className="modal-details__tabs-wrapper">
      <NavigationTabs buttons={buttons} />
    </div>
  )
}
