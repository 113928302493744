import * as React from 'react'
import { ReceptionBookingBox } from '@modules/reception/checkin/step-payments/boxes/reception-booking-box'
import { ReservationBookingPayment } from '@models/reception'
import { SaveButton } from '@hyper/button'
import { formatPrice } from '@helpers/utils'
import { Alert } from 'reactstrap'
import { IconWithText } from '@components/icon-with-text'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useAppDispatch } from '@store/index'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import Decimal from 'decimal.js'
import { setReceptionBookingDetails } from '@store/slices/reception-slice'

interface Props {
  payment: ReservationBookingPayment
  depositToReturn: Decimal
  onReturn: () => void
}

export const ReceptionBookingSettlementDepositReturnConfirmBox = ({
  payment,
  depositToReturn,
  onReturn,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()
  const { isLoading, action: handleDepositRefundConfirmation } = useApiRequest(async () => {
    dispatch(
      setReceptionBookingDetails(
        await commonObjectPost(payment.urls.deposit_return, {
          amount: depositToReturn.toString(),
        }),
      ),
    )

    onReturn()
    addSuccessNotification('Zwrot został potwierdzony')
  })

  return (
    <ReceptionBookingBox title={`Rozliczenie kaucji (${payment.source_display.toLowerCase()})`}>
      <div className="d-flex justify-content-between">
        <span>
          Kwota <strong>do zwrotu</strong>:
        </span>
        <strong>{formatPrice(depositToReturn.toString())}</strong>
      </div>
      {payment.last_card_numbers && (
        <div className="d-flex justify-content-between mt-1">
          <span>Nr. karty:</span>
          <strong className="letter-spacing-2">**** **** **** {payment.last_card_numbers}</strong>
        </div>
      )}
      {payment.source === 'creditcard' && (
        <Alert className="py-1 px-1 mt-3 alert-info">
          <IconWithText
            icon="uil-exclamation-circle font-15 align-self-start"
            text="Wymagane użycie karty płatniczej, z której pobrano kaucję."
            textClass="font-12"
          />
        </Alert>
      )}

      <SaveButton
        isSaving={isLoading}
        onClick={handleDepositRefundConfirmation}
        label="Potwierdź rozliczenie kaucji"
        labelSaving="Potwierdzanie..."
        className="w-100 btn btn-success fw-semi-bold"
      />
      {payment.source === 'creditcard' && (
        <p className="mb-0 mt-2">
          Wykonaj opcję <strong>Zwrot</strong> na terminalu płatniczym, w kwocie wskazanej powyżej.
        </p>
      )}
    </ReceptionBookingBox>
  )
}
