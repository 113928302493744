import * as React from 'react'
import { ClientDetails } from '@models/clients'
import { YesNoBadge } from '@components/badges/yes-no'
import { ClientDetailsModalBaseDataRow } from '@modules/crm/clients/details-modal/base-data/data-row'
import { toTextDateTimeFormat } from '@helpers/date-helper'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApiRequest } from '@components/hooks/use-api-request'
import { updateClientDetails } from '@store/actions/crm-actions'
import { commonObjectPatch } from '@store/actions/generic-actions'
import { UserPermission } from '@models/dashboard'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import classnames from 'classnames'

interface Props {
  client: ClientDetails
}

export const ClientDetailsModalBaseDataServicesRulesObjections: React.FC<Props> = ({ client }) => {
  const user = useAuthenticatedUser()

  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()

  const { isLoading, action: updateServicesRulesObjections } = useApiRequest(async () => {
    dispatch(
      updateClientDetails(
        await commonObjectPatch<ClientDetails>(client.profile.urls.details, {
          services_rules_objection: !client.profile.services_rules_objection,
        }),
      ),
    )
    addSuccessNotification('Zmiany zostały zapisane!')
  })

  const canChangeServicesRulesObjections = user.hasPerm(UserPermission.ClientCanChangeServicesRulesObjections)

  return (
    <ClientDetailsModalBaseDataRow name="Sprzeciw do zmiany regulaminu:">
      <CommonObjectConfirmAction
        title={
          client.profile.skip_service_charge
            ? 'Skasuj sprzeciw do zmiany regulaminu'
            : 'Dodaj sprzeciw do zmiany regulaminy'
        }
        isLoading={isLoading}
        handleAccept={canChangeServicesRulesObjections ? updateServicesRulesObjections : undefined}
        message={
          client.profile.skip_service_charge
            ? `Czy na pewno chcesz skasować sprzeciw gościa ${client.name} wyrażony wobec zmiany regulaminu?`
            : 'Czy klient wyraził sprzeciw wobec zmiany regulaminu?'
        }
      >
        <YesNoBadge
          value={client.profile.services_rules_objection}
          className={classnames('cursor-pointer', { 'not-clickable': !canChangeServicesRulesObjections })}
        />
        {client.profile.services_rules_objection_datetime && (
          <span className="font-10">{toTextDateTimeFormat(client.profile.services_rules_objection_datetime)}</span>
        )}
      </CommonObjectConfirmAction>
    </ClientDetailsModalBaseDataRow>
  )
}
