import { TypedQueryResult } from '@api/base'
import { CashbackVouchersAppData } from '@modules/products/cashback-vouchers/models'
import { useGetCashbackVouchersAppDataQuery } from '@api/cashback'

const emptyAppData: CashbackVouchersAppData = {
  notifications: [],
}

export const useCashbackVouchersAppData = (): CashbackVouchersAppData => {
  const { data: appData = emptyAppData } =
    useGetCashbackVouchersAppDataQuery() as TypedQueryResult<CashbackVouchersAppData>

  return appData
}
