import * as React from 'react'
import { HousekeepingOrderTableHeaderStatus } from '@modules/housekeeping/common/table-headers/table-header-status'
import { cleaningOrderStatusSelectOptions } from '@modules/housekeeping/models'

export const HousekeepingBeddingOrderTableHeaderCleaningStatus = (): JSX.Element => (
  <HousekeepingOrderTableHeaderStatus
    name="cleaningStatus"
    options={cleaningOrderStatusSelectOptions}
    placeholder="Status sprzątania"
  />
)
