import * as React from 'react'
import classNames from 'classnames'
import { Button } from 'reactstrap'
import { UpSellBookingStatus } from '@models/upselling'

interface Props {
  isButtonActive: (key: UpSellBookingStatus) => boolean
  toggleActiveFilter: (key: UpSellBookingStatus) => () => void
}

export const UpsellBookingsDateFiltersStatuses = ({ isButtonActive, toggleActiveFilter }: Props): JSX.Element => (
  <div className="d-flex flex-wrap">
    <Button
      color="info"
      className={classNames('shadow-none', { 'opacity-3': isButtonActive('contact_required') })}
      onClick={toggleActiveFilter('contact_required')}
    >
      Wymaga kontaktu
    </Button>
    <Button
      className={classNames('bg-danger-light ml-1 shadow-none border-0', {
        'opacity-3': isButtonActive('contact_required_again'),
      })}
      onClick={toggleActiveFilter('contact_required_again')}
    >
      Do ponownego kontaktu
    </Button>
    <Button
      className={classNames('bg-purple ml-1 shadow-none border-0', {
        'opacity-3': isButtonActive('sold'),
      })}
      onClick={toggleActiveFilter('sold')}
    >
      Sprzedany
    </Button>
    <Button
      color="light"
      className={classNames('ml-1 shadow-none', { 'opacity-3': isButtonActive('not_interested') })}
      onClick={toggleActiveFilter('not_interested')}
    >
      Niezainteresowany
    </Button>
  </div>
)
