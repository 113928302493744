import * as React from 'react'
import { BaseHousekeepingOrder } from '@modules/housekeeping/models'
import classNames from 'classnames'
import { getServiceKindIcon } from '@modules/housekeeping/cleaning-orders/common/tools'

interface Props {
  order: BaseHousekeepingOrder & { is_reopened: boolean }
  id: number
  width?: number
}

export const HousekeepingCleaningOrdersRowServiceKind = React.memo(({ id, order, width = 200 }: Props) => {
  const tooltipId = `tooltipServiceKind${id}`

  return (
    <td className="housekeeping__orders_table__column" width={width}>
      <span id={tooltipId} className="d-flex align-items-center">
        {order.service_kind && order.service_kind_display && (
          <>
            <i className={classNames(getServiceKindIcon(order.service_kind), 'font-18 mr-1')} />
            {order.service_kind_display}
          </>
        )}
      </span>
    </td>
  )
})
