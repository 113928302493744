import * as React from 'react'
import { CleaningOrder } from '@modules/housekeeping/models'
import { HousekeepingCleaningOrderAcceptedByRow } from '@modules/housekeeping/cleaning-orders/common/accepted-by'
import { HousekeepingCleaningOrderHasDamageRow } from '@modules/housekeeping/cleaning-orders/common/has-damage'
import { HousekeepingOrderWorkersRow } from '@modules/housekeeping/common/workers'
import { HousekeepingOrdersRowTotalTimeStarted } from '@modules/housekeeping/common/row-total-time-started'
import { HousekeepingCleaningOrdersRowKind } from '@modules/housekeeping/cleaning-orders/common/kind'
import { HousekeepingCleaningOrderOrderedByRow } from '@modules/housekeeping/cleaning-orders/common/ordered-by'
import { OrderNotes } from '@modules/housekeeping/cleaning-orders/common/row-notes'
import { HousekeepingCleaningOrderApartment } from '@modules/housekeeping/cleaning-orders/to-order/row-apartment'

interface HousekeepingCleaningOrderArchiveRowProps {
  cleaningOrder: CleaningOrder
}

export const HousekeepingCleaningOrderArchiveRow: React.FC<HousekeepingCleaningOrderArchiveRowProps> = ({
  cleaningOrder,
}) => (
  <tr className="housekeeping__orders_table__row">
    <HousekeepingCleaningOrderApartment cleaningOrder={cleaningOrder} showPriority={true} />
    <HousekeepingCleaningOrdersRowKind order={cleaningOrder} />
    <td className="housekeeping__orders_table__column">{cleaningOrder.status_display}</td>
    <HousekeepingOrderWorkersRow order={cleaningOrder} />

    <HousekeepingOrdersRowTotalTimeStarted totalTimeStarted={cleaningOrder.total_time_started} />
    <HousekeepingCleaningOrderOrderedByRow
      ordered_at={cleaningOrder.ordered_at}
      ordered_by={cleaningOrder.ordered_by}
    />
    <HousekeepingCleaningOrderHasDamageRow hasDamage={cleaningOrder.has_damage} />
    <td className="housekeeping__orders_table__column">
      <OrderNotes order={cleaningOrder} />
    </td>
    <HousekeepingCleaningOrderAcceptedByRow
      accepted_at={cleaningOrder.accepted_at}
      accepted_by={cleaningOrder.accepted_by}
    />
  </tr>
)
