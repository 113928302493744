import * as React from 'react'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { CmsAssetsTableRow } from '@modules/cms/assets/list/table-row'
import { AssetCategory } from '@models/cms-asset'
import { assetsSelector } from '@store/slices/assets-slice'
import { useAppSelector } from '@store/index'

interface Props {
  isLoading: boolean
}

const headerValues: TableHeaderValue<AssetCategory>[] = [
  { title: 'Nazwa', sortField: 'name' },
  { title: 'Klucz', sortField: 'key' },
  { title: 'Kategoria' },
  { title: 'Nazwa pliku', sortField: 'file' },
  { title: '' },
]

export const CmsAssetsTable = ({ isLoading }: Props): JSX.Element => {
  const assets = useAppSelector(assetsSelector)

  const [filters, setFilters] = React.useState<TableFilters>({
    ordering: 'name',
    page: 1,
    page_size: 9999,
    search: '',
  })

  return (
    <Table
      filters={filters}
      setFilters={setFilters}
      headerValues={headerValues}
      isLoading={isLoading}
      showPagination={false}
    >
      {assets.map(row => (
        <CmsAssetsTableRow key={row.id} asset={row} />
      ))}
    </Table>
  )
}
