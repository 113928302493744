import * as React from 'react'
import { Button, Card, CardBody } from 'reactstrap'
import { PackageWholesaleDetails } from '@modules/package-wholesale/models'
import { PackageWholesaleNotificationsTable } from '@modules/package-wholesale/details/notification/notifications-table'
import { useModal } from '@components/modals/use-modal'

interface Props {
  packageWholesaleDetails: PackageWholesaleDetails
}

export const PackageWholesaleDetailsNotifications: React.FC<Props> = ({ packageWholesaleDetails }) => {
  const [showMassNotificationsModal] = useModal('PackageWholesaleDetailsMassNotificationsModal', {
    packageWholesaleDetails,
  })

  return (
    <Card>
      <CardBody>
        <h4 className="mt-0 text-secondary mb-3">Historia powiadomień e-mail</h4>
        <PackageWholesaleNotificationsTable packageWholesaleDetails={packageWholesaleDetails} />
        <Button className="btn btn-primary d-block ml-auto px-3" onClick={showMassNotificationsModal} color="primary">
          Wyślij
        </Button>
      </CardBody>
    </Card>
  )
}
