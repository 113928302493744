import * as React from 'react'
import { ReservationDataRow } from '@modules/reservations/details/common/reservation-data-row'
import { YesNoBadge } from '@components/badges/yes-no'
import { FloorHeatingMeasurementUrls, FloorHeatingWindowState } from '@modules/floor-heating/models'
import { IconWithText } from '@components/icon-with-text'
import { useModal } from '@components/modals/use-modal'
import { FloorHeatingParameter } from '@modules/floor-heating/modals/parameter-change-modal'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { formatTemperature } from '@helpers/utils'

interface Props {
  windowState: FloorHeatingWindowState | null
  measurementUrls: FloorHeatingMeasurementUrls | null
}

export const FloorHeatingDetailsParameters = ({ windowState, measurementUrls }: Props): JSX.Element => {
  const user = useAuthenticatedUser()
  const [showParametersChangeModal] = useModal('FloorHeatingDetailsParametersModalChange')

  const handleParameterChange = (field: FloorHeatingParameter) => () => {
    showParametersChangeModal(null, {
      apartmentId: windowState?.apartment_name,
      field,
      measurementUrls,
    })
  }

  return (
    <div>
      <span className="font-18 mt-3 fw-semi-bold d-block">Parametry</span>
      <div className="d-flex">
        <div className="col-4 pl-0 mt-3">
          <ReservationDataRow name="Okna otwarte">
            {windowState?.details ? <YesNoBadge value={!!windowState?.details?.any_window_opened} /> : 'brak odczytu'}
          </ReservationDataRow>

          {user.hasPerm(UserPermission.FloorHeatingCanSetTemperature) && (
            <ReservationDataRow name="Temperatura">
              <IconWithText
                icon="uil-pen font-11"
                text="Ustaw temperaturę"
                textClass="font-11"
                onClick={handleParameterChange('temperature')}
              />
            </ReservationDataRow>
          )}

          {user.hasPerm(UserPermission.FloorHeatingCanSetScenario) && (
            <ReservationDataRow name="Scenariusz">
              <IconWithText
                icon="uil-pen font-11"
                text="Ustaw scenariusz"
                textClass="font-11"
                onClick={handleParameterChange('scenario')}
              />
            </ReservationDataRow>
          )}
        </div>
        <div className="col-4">
          {windowState?.details?.zones ? (
            <div className="bg-grey-light rounded border px-3 py-2">
              <strong>Szczegóły stref:</strong>
              <div className="row mt-2 mb-1">
                {windowState?.details?.zones.map(item => (
                  <div className="col-6" key={item.id}>
                    <YesNoBadge value={item.opened} /> - <span>{item.name}</span>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <span>&nbsp;</span>
          )}
        </div>
        <div className="col-4">
          <div className="bg-grey-light rounded border px-3 py-2">
            <strong>Scenariusze:</strong>
            <p className="mt-2 mb-1 font-12">
              <span>Tryb Zameldowanie: </span>
              <span className="fw-semi-bold pl-1">
                {formatTemperature(18)} - {formatTemperature(25)}
              </span>
            </p>
            <p className="font-12">
              <span>Tryb Wymeldowanie: </span>
              <span className="fw-semi-bold pl-1">
                {formatTemperature(18)} - {formatTemperature(19)}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
