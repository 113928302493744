import * as React from 'react'
import { Accept, useDropzone } from 'react-dropzone'
import Spinner from '@hyper/spinner'
import classnames from 'classnames'

export const UploaderAcceptableFile = {
  Images: { 'image/*': [] },
  PDF: { 'application/pdf': [] },
  XLS: { 'application/vnd.ms-excel': ['.xls'] },
  XLSX: { 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.xlsx'] },
}

const FileTypes = {
  'application/pdf': 'pdf',
  'images/*': 'jpg, png, webp',
  'application/vnd.ms-excel': 'xls',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'xlsx',
}

export type FileUploaderAccept = Accept

interface Props {
  isLoading: boolean
  maxFiles?: number
  upload: (file) => void
  label?: string
  labelClassName?: string
  accept?: FileUploaderAccept
  className?: string
  uploadIcon?: string
  fileIcon?: string
  file?: File
  withIcon?: boolean
}

export const FileUploader: React.FC<Props> = ({
  className,
  upload,
  isLoading,
  maxFiles,
  accept,
  label = 'Przeciągnij i upuść pliki lub kliknij by dodać pliki',
  labelClassName,
  file,
  fileIcon = 'uil-file-check',
  uploadIcon = 'uil-cloud-upload',
  withIcon = true,
}) => {
  const onDrop = React.useCallback(acceptedFiles => {
    acceptedFiles.forEach(file => {
      upload(file)
    })
  }, [])

  const filesTypes: string[] = []

  if (accept) {
    Object.keys(accept).forEach(row => {
      if (FileTypes[row]) {
        filesTypes.push(FileTypes[row])
      }
    })
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept, maxFiles })

  return (
    <section className={classnames('dropzone text-center', className)}>
      <div {...getRootProps()} className="dropzone__wrapper px-2">
        <input {...getInputProps()} />
        {isLoading ? (
          <Spinner size="xs" className="h3 text-muted" />
        ) : (
          withIcon && <i className={classnames('h3 text-muted', file ? fileIcon : uploadIcon)} />
        )}
        <h5 className={classnames('overflow-hidden', labelClassName)}>
          {file ? file.name : label}
          {!file && !!filesTypes.length && ` (format: ${filesTypes.join(', ')})`}
        </h5>
      </div>
    </section>
  )
}
