import * as React from 'react'
import { BookingGuestFeedingOption, ReceptionBookingDetails, ReceptionBookingGroup } from '@models/reception'
import dateHelper from '@helpers/date-helper'
import { ReceptionBookingGuest } from '@models/booking'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import * as R from 'ramda'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'

export interface FeedingsHandler {
  feedingOptions: Partial<BookingGuestFeedingOption>[]
  getFeedingsForGuest: (guestId: number) => Partial<BookingGuestFeedingOption>[]
  setFeedingOptionForGuest: (
    guestId: number | number[],
    dinner: boolean | undefined,
    breakfast: boolean | undefined,
    dates?: string[],
  ) => void
  updateMultipleFeedingsForGuests: (guestsIds: number[], feedings: Partial<BookingGuestFeedingOption>[]) => void
}

const useFeedingsWithDefaults = (booking: ReceptionBookingDetails) => {
  const user = useAuthenticatedUser()
  const [initialFeedingChanges, setInitialFeedingChanges] = React.useState<BookingGuestFeedingOption[]>([])
  const [feedingGroups, setFeedingGroups] = React.useState<number[]>([])
  const userCanChangeFeeding = user.hasPerm(UserPermission.BookingCanChangeFeeding)

  React.useEffect(() => {
    setFeedingGroups(booking.groups.map((group: ReceptionBookingGroup) => group.id))
  }, [booking.groups])

  const days = dateHelper.getEachDayFromRange(booking.date_from, booking.date_to)

  const getFeedingOptions = () =>
    booking.guests.reduce(
      (result: BookingGuestFeedingOption[], guest: ReceptionBookingGuest) => [
        ...result,
        ...createFeedingOptionForGuest(guest.id),
      ],
      [],
    )

  const createFeedingOptionForGuest = guestId =>
    days.map(day => {
      const feedingOption = booking.feeding_options.find(
        feedingOption => feedingOption.guest_id === guestId && feedingOption.date === day,
      )

      return {
        date: day,
        guest_id: guestId,
        dinner: feedingOption?.dinner || false,
        breakfast: feedingOption?.breakfast || false,
        is_breakfast_change_allowed: booking.status !== 'close' && (!feedingOption?.breakfast || userCanChangeFeeding),
        is_dinner_change_allowed: booking.status !== 'close' && (!feedingOption?.dinner || userCanChangeFeeding),
      }
    })

  const [feedingOptions, setFeedingOptions] = React.useState<Partial<BookingGuestFeedingOption>[]>(getFeedingOptions())

  React.useEffect(() => {
    setFeedingOptions(getFeedingOptions())
    setInitialFeedingChanges([])
  }, [booking.feeding_options])

  useDidUpdateEffect(() => {
    const changedFeedings: BookingGuestFeedingOption[] = R.difference(feedingOptions, getFeedingOptions())

    const feedingChanges = changedFeedings.map(
      changedFeeding =>
        getFeedingOptions()
          .map(a => {
            const feedingData = changedFeeding.date === a.date && changedFeeding.guest_id === a.guest_id

            return feedingData
              ? {
                  ...changedFeeding,
                  is_dinner_change_allowed: changedFeeding.dinner === a.dinner || userCanChangeFeeding,
                  is_breakfast_change_allowed: changedFeeding.breakfast === a.breakfast || userCanChangeFeeding,
                }
              : null
          })
          .filter(Boolean) as any,
    )

    setInitialFeedingChanges(feedingChanges)
  }, [feedingOptions])

  const getFeedingsForGuest = (guestId: number) =>
    feedingOptions.filter((feedingOption: BookingGuestFeedingOption) => feedingOption.guest_id === guestId)

  const setFeedingOptionForGuest = (
    guestId: number | number[],
    dinner: boolean | undefined,
    breakfast: boolean | undefined,
    dates?: string[],
  ) => {
    const datesToUpdate = typeof dates === 'undefined' ? days : dates

    const guestIds: number[] = Array.isArray(guestId) ? guestId : [guestId]

    setFeedingOptions(
      feedingOptions.map(feedingOption => {
        if (guestIds.includes(feedingOption.guest_id || 0) && datesToUpdate.includes(feedingOption.date as string)) {
          feedingOption = {
            ...feedingOption,
            dinner:
              typeof dinner !== 'undefined' && feedingOption.is_dinner_change_allowed ? dinner : feedingOption.dinner,
            breakfast:
              typeof breakfast !== 'undefined' && feedingOption.is_breakfast_change_allowed
                ? breakfast
                : feedingOption.breakfast,
          }
        }
        if (feedingOption.date === booking.date_to && feedingOption.is_dinner_change_allowed) {
          feedingOption = { ...feedingOption, dinner: false }
        }
        if (feedingOption.date === booking.date_from && feedingOption.is_breakfast_change_allowed) {
          feedingOption = { ...feedingOption, breakfast: false }
        }
        return feedingOption
      }),
    )
  }

  const updateMultipleFeedingsForGuests = (guestsIds: number[], feedings: Partial<BookingGuestFeedingOption>[]) => {
    const feedingsForSelectedGuests = guestsIds.reduce(
      (cum, guestId) => [
        ...cum,
        ...feedings.map((feedingOption: BookingGuestFeedingOption) => ({ ...feedingOption, guest_id: guestId })),
      ],
      [],
    )

    const newFeedings = feedingOptions.map(
      (feedingOption: BookingGuestFeedingOption) =>
        feedingsForSelectedGuests.find((feedingToChange: BookingGuestFeedingOption) => {
          const shouldChangeDinner =
            feedingToChange.dinner !== feedingOption.dinner && feedingOption.is_dinner_change_allowed

          const shouldChangeBreakfast =
            feedingToChange.breakfast !== feedingOption.breakfast && feedingOption.is_dinner_change_allowed

          return (
            feedingToChange.guest_id === feedingOption.guest_id &&
            feedingToChange.date === feedingOption.date &&
            (shouldChangeDinner || shouldChangeBreakfast)
          )
        }) || feedingOption,
    )

    setFeedingOptions(newFeedings)
  }

  const getFilledFeedings = () => {
    const feedings = feedingOptions.map(feedingOption => ({
      ...feedingOption,
      group_id: booking.guests.find(guest => feedingOption.guest_id === guest.id)?.group_id,
    }))

    return feedings.filter(
      feedingOptions => feedingOptions.group_id && !!feedingGroups.find(groupId => groupId === feedingOptions.group_id),
    )
  }

  const feedingsHandler: FeedingsHandler = {
    feedingOptions,
    getFeedingsForGuest,
    setFeedingOptionForGuest,
    updateMultipleFeedingsForGuests,
  }

  return {
    feedingsHandler,
    getFilledFeedings,
    feedingGroups,
    isInitialFeedingChanged: !!initialFeedingChanges.length,
  }
}

export default useFeedingsWithDefaults
