import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useForm } from 'react-hook-form'
import { BaseModalProps } from '@components/modals/types'
import { ReservationBookingPayment } from '@models/reception'
import { CustomReactSelectOption, makeDefaultSelectOption } from '@components/custom-react-select'
import { useFormRequest } from '@components/hooks/use-api-request'
import { Form } from '@hyper/forms/form'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { ReservationPaymentForm } from '@modules/reservations/details/payment-history/modals/payment/reservation-payment-add-form'
import { parseISODate } from '@helpers/date-helper'
import { paymentMethodOptions } from '@modules/reservations/details/load-notes/modals/reservation-load-note-add-modal'

export const ReservationPaymentModalHeader = {
  climatic: 'opłatę klimatyczną',
  installment: 'zaliczkę',
  deposit: 'kaucję',
}

interface FormInputs {
  source: CustomReactSelectOption | null
  amount: string
  invoice_date: Date | null
  description: string
}

interface Props extends BaseModalProps {
  bookingPayment: ReservationBookingPayment
}

export const ReservationPaymentEditModal: React.FC<Props> = ({ toggleIsVisible, bookingPayment }) => {
  const methods = useForm<FormInputs>({
    defaultValues: {
      amount: bookingPayment.amount,
      source: makeDefaultSelectOption(paymentMethodOptions, bookingPayment.source),
      description: bookingPayment.description,
      ...(['deposit', 'installment'].includes(bookingPayment.type) && {
        invoice_date: parseISODate(bookingPayment.invoice_date),
      }),
    },
  })

  // const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()

  const { action: handleSubmit, isLoading } = useFormRequest(async (payload: FormInputs) => {
    // await commonObjectPatch()
    // toggleIsVisible()
    console.log(payload)
    addSuccessMessage('Sukces', 'Wpłata została zmieniona!')
  }, methods.setError)

  return (
    <Form onSubmit={handleSubmit} methods={methods}>
      <ModalHeader toggle={toggleIsVisible}>Edytuj {ReservationPaymentModalHeader[bookingPayment.type]}</ModalHeader>
      <ModalBody>
        <ReservationPaymentForm canOmitSage={false} paymentKind={bookingPayment.type} />
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton role="submit" className="btn btn-green" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
