import * as React from 'react'
import { ReceptionBooking } from '@models/reception'
import { ReceptionBookingCheckOut } from '@modules/reception/checkout/reception-booking-check-out'
import { ButtonWithIcon } from '@components/button-with-icon'
import { ReceptionBookingCheckOutPreview } from '@modules/reception/checkout/preview/reception-booking-check-out-preview'
import { ReceptionBookingExtensionButton } from '@modules/reception/common/reception-booking-extension-button'

interface ReceptionToCheckOutRowButtonsProps {
  booking: ReceptionBooking
}

export const ReceptionToCheckOutRowButtons: React.FC<ReceptionToCheckOutRowButtonsProps> = ({ booking }) => {
  const [isDefaultCheckoutVisible, setIsDefaultCheckoutVisible] = React.useState(false)
  const [isCheckoutPreviewVisible, setIsCheckoutPreviewVisible] = React.useState(false)

  const handleCheckOut = action => () => {
    action(state => !state)
  }

  const buttonText = text => (booking.reception_action === 'check-out-started' ? 'Wymeldowywanie...' : text)

  return (
    <div className="text-right">
      {booking.checked_out_online ? (
        <ButtonWithIcon
          icon="uil-check font-16"
          text={buttonText('Potwierdź')}
          color="primary"
          btnClass="reception__bookings_table__row__checkout-button mr-2"
          handleClick={handleCheckOut(setIsCheckoutPreviewVisible)}
        />
      ) : (
        <ButtonWithIcon
          icon="uil-sign-out-alt font-16"
          text={buttonText('Wymelduj')}
          color="danger"
          btnClass="reception__bookings_table__row__checkout-button mr-2"
          handleClick={handleCheckOut(setIsDefaultCheckoutVisible)}
          type="button"
        />
      )}

      {isDefaultCheckoutVisible && (
        <ReceptionBookingCheckOut booking={booking} onClose={() => setIsDefaultCheckoutVisible(false)} />
      )}
      {isCheckoutPreviewVisible && (
        <ReceptionBookingCheckOutPreview booking={booking} onClose={() => setIsCheckoutPreviewVisible(false)} />
      )}
      <ReceptionBookingExtensionButton booking={booking} />

      {booking.reception_action === 'check-out-started' && (
        <div className="text-center mt-1 font-12">{booking.reception_user}</div>
      )}
    </div>
  )
}
