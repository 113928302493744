import * as React from 'react'
import Table from '@components/table/table'
import { GenericVoucher } from '@modules/generic-vouchers/models'
import classNames from 'classnames'
import { getGenericVoucherStatusColor } from '@modules/generic-vouchers/helpers'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { YesNoBadge } from '@components/badges/yes-no'
import { GenericVoucherIssueVoucherButton } from '@modules/generic-vouchers/common/issue-voucher-button'
import { RootState, useAppSelector } from '@store/index'

interface Props {
  genericVoucher: GenericVoucher
}

export const GenericVoucherTableRow: React.FC<Props> = ({ genericVoucher }) => {
  const promocodeAssigns = genericVoucher.promocode_assigns.at(-1)
  const navigate = useNavigate()
  const handleClick = React.useCallback(() => {
    const url = NavigationPath.GenericVoucherListWithParams.replace(':id', String(genericVoucher.id)).replace(
      ':kind',
      String(genericVoucher.kind),
    )
    navigate(url)
  }, [genericVoucher.id, genericVoucher.kind])
  const quickSearchUrl = useAppSelector((state: RootState) => state.appState.appData.urls.dashboard.quick_search_url)

  return (
    <Table.Row className="cursor-pointer" onClick={handleClick}>
      <Table.Cell className="vertical-align-middle">{genericVoucher.number}</Table.Cell>
      <Table.Cell className="vertical-align-middle">
        {genericVoucher.client_data.first_name} {genericVoucher.client_data.last_name}
        <br />
        {genericVoucher.client_data.email}
        <br />
        <span className="font-11">PESEL: {genericVoucher.client_data.pesel}</span>
      </Table.Cell>
      <Table.Cell className="vertical-align-middle">
        <span className={classNames('badge', getGenericVoucherStatusColor(genericVoucher.status))}>
          {genericVoucher.status_display}
        </span>
      </Table.Cell>
      <Table.Cell className="vertical-align-middle">{toDefaultDateFormat(genericVoucher.created)}</Table.Cell>
      <Table.Cell className="vertical-align-middle">
        <YesNoBadge value={!!promocodeAssigns?.issue_datetime} />
      </Table.Cell>
      <Table.Cell className="vertical-align-middle">
        <GenericVoucherIssueVoucherButton genericVoucher={genericVoucher} />
      </Table.Cell>
      <Table.Cell className="vertical-align-middle">
        <YesNoBadge value={!!promocodeAssigns?.promocode?.is_used} />
      </Table.Cell>
      <Table.Cell className="vertical-align-middle">
        {promocodeAssigns?.promocode?.used_at && toDefaultDateFormat(promocodeAssigns.promocode.used_at)}
        {promocodeAssigns?.promocode?.bookings.map(row => (
          <a href={quickSearchUrl + '?query=' + row} key={row} className="d-block mt-1 text-dark">
            {row}
          </a>
        ))}
      </Table.Cell>
    </Table.Row>
  )
}
