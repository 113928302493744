import * as React from 'react'
import Table from '@components/table/table'
import { Unit } from '@models/units'
import { useAppData } from '@components/hooks/use-app-data'
import { useAppSelector } from '@store/index'
import { accommodationTypesMapSelector } from '@store/selectors/dashboard'

interface Props {
  unit: Unit
}

export const UnitsTableRowType: React.FC<Props> = ({ unit }) => {
  const { unit_kinds } = useAppData()
  const accommodationTypesMap = useAppSelector(accommodationTypesMapSelector)

  const kind = React.useMemo(() => unit_kinds.find(kind => kind.id === unit.kind_id), [unit.kind_id, unit_kinds])

  if (unit.apartment) {
    const accommodationType = accommodationTypesMap[unit.apartment.accommodation_type_id]
    return (
      <Table.Cell className="vertical-align-middle">
        {kind?.name}
        <span className="d-block font-10">{accommodationType.name.split(' ')[1]}</span>
      </Table.Cell>
    )
  }

  return (
    <Table.Cell className="vertical-align-middle">
      {unit.name} <span className="d-block font-10">{kind?.name}</span>
    </Table.Cell>
  )
}
