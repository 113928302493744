import { BaseModalProps } from '@components/modals/types'
import { Agreement } from '@modules/hr/agreement/models'
import * as React from 'react'
import { Button, Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { useAppDispatch, useAppSelector } from '@store/index'
import { selectAgreementDetails, updateAgreementDetails } from '@store/slices/hr-slice'
import { AgreementDetailsModalTabs } from '@modules/hr/agreement/details/tabs'
import { HistoryBox } from '@modules/promotions/common/history-box'
import { AgreementDetailsNotes } from '@modules/hr/agreement/details/notes'
import { DocumentList } from '@components/documents/document-list'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { AgreementDetailsDeleteButton } from '@modules/hr/agreement/details/delete-button'
import { useModal } from '@components/modals/use-modal'
import { AgreementDetailsDocuments } from '@modules/hr/agreement/details/documents'
import { useAgreementDetails } from '@modules/hr/agreement/details/use-agreement-details'
import { useAgreementDownloadCheck } from '@modules/hr/agreement/details/use-agreement-download-check'
import { HrFinanceDetails } from '@modules/hr/common/details/finance-details-information'
import { HrAddressDetails } from '@modules/hr/common/details/address-details-information'
import { HrBaseDetails } from '@modules/hr/common/details/base-details-information'
import { SubscriptionDetailsInformationRow } from '@modules/subscription/details/information/row'
import classNames from 'classnames'
import { HrPositionDetails } from '@modules/hr/common/details/position-details-information'
import { AgreementDetailsAnnexes } from '@modules/hr/agreement/details/annexes'

interface Props extends BaseModalProps {
  agreement: Agreement
}

export const AgreementDetailsModal: React.FC<Props> = ({ toggleIsVisible, agreement }) => {
  const agreementDetails = useAppSelector(selectAgreementDetails)
  const user = useAuthenticatedUser()
  const dispatch = useAppDispatch()

  const [showEditModal] = useModal('AgreementEditModal')
  const { canDownload } = useAgreementDownloadCheck(agreement)

  const dataRef = React.useRef<HTMLElement>(null)
  const financeRef = React.useRef<HTMLElement>(null)
  const historyRef = React.useRef<HTMLElement>(null)
  const addressRef = React.useRef<HTMLElement>(null)
  const positionRef = React.useRef<HTMLElement>(null)
  const annexesRef = React.useRef<HTMLElement>(null)

  const isNotEditable = ['active', 'completed', 'resignation'].includes(agreementDetails?.status || agreement.status)
  const isToVerifyStatus = (agreementDetails?.status ?? agreement.status) === 'to_verify'
  const isVerifiedStatus = (agreementDetails?.status ?? agreement.status) === 'verified'

  const isReadonly =
    !user.hasPerm(UserPermission.HrAgreementCanEdit) ||
    isNotEditable ||
    isVerifiedStatus ||
    (isToVerifyStatus && !user.hasPerm(UserPermission.HrAgreementCanEditDuringVerification))

  const isJobPositionReadonly =
    !user.hasPerm(UserPermission.HrAgreementCanEdit) ||
    isNotEditable ||
    (isToVerifyStatus && !user.hasPerm(UserPermission.HrAgreementCanEditDuringVerification)) ||
    (isVerifiedStatus &&
      !user.hasPerm(UserPermission.HrAgreementCanEditStartDateInVerifiedStatus) &&
      !user.hasPerm(UserPermission.HrAgreementCanEditDuringVerification))

  const [showAgreementStatusChangeModal] = useModal('AgreementStatusChangeModal', { agreementDetails })

  const { refresh, isLoading } = useAgreementDetails({ agreement })

  React.useEffect(
    () => () => {
      dispatch(updateAgreementDetails(null))
    },
    [],
  )

  const showAgreementEditModal = (step: number) => () => {
    showEditModal(null, { step, agreementDetails })
  }

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>
        Umowa numer #<span className="text-semi-strong">{agreement.number}</span>
      </ModalHeader>
      <ModalBody className="modal-details">
        <LoaderPlaceholder content={!isLoading}>
          {agreementDetails && (
            <div className="modal-details__tabs-wrapper">
              <AgreementDetailsModalTabs
                annexesRef={annexesRef}
                dataRef={dataRef}
                financeRef={financeRef}
                addressRef={addressRef}
                positionRef={positionRef}
              />
              <Row>
                <Col md={6}>
                  <HrBaseDetails
                    onEdit={showAgreementEditModal(1)}
                    isReadonly={isReadonly}
                    data={agreementDetails}
                    ref={dataRef}
                  >
                    <SubscriptionDetailsInformationRow name="Status">
                      <span
                        className={classNames({
                          'badge badge-success-lighten': agreementDetails.status === 'active',
                          'badge badge-info-lighten': agreementDetails.status === 'draft',
                        })}
                      >
                        {agreementDetails.status_display}
                      </span>
                    </SubscriptionDetailsInformationRow>
                  </HrBaseDetails>

                  <HrAddressDetails
                    isReadonly={isReadonly}
                    addressData={agreementDetails}
                    ref={addressRef}
                    prefix=""
                    title="Adres zamieszkania"
                    onEdit={showAgreementEditModal(2)}
                  />

                  {agreementDetails.different_address && (
                    <HrAddressDetails
                      prefix="registered_"
                      isReadonly={isReadonly}
                      addressData={agreementDetails}
                      ref={addressRef}
                      title="Adres zameldowania"
                      onEdit={showAgreementEditModal(2)}
                    />
                  )}
                </Col>

                <Col md={6}>
                  <HrPositionDetails
                    isReadonly={isJobPositionReadonly}
                    positionData={agreementDetails}
                    annexedFields={agreementDetails.annexed_fields}
                    ref={positionRef}
                    onEdit={showAgreementEditModal(4)}
                    agreementId={agreementDetails.id}
                    showOriginalValue={true}
                  />
                  <HrFinanceDetails
                    isReadonly={isReadonly}
                    financeData={agreementDetails}
                    ref={financeRef}
                    onEdit={showAgreementEditModal(3)}
                  />
                </Col>
                <Col md={12}>
                  <AgreementDetailsAnnexes ref={annexesRef} agreementDetails={agreementDetails} />
                </Col>
                <Col md={6}>
                  <AgreementDetailsNotes agreementDetails={agreementDetails} />
                  <HistoryBox history={agreementDetails.history} ref={historyRef} />
                </Col>
                <Col md={6}>
                  <AgreementDetailsDocuments isReadonly={isReadonly} agreementDetails={agreementDetails} />
                  <DocumentList
                    isReadonly={isNotEditable}
                    title="Załączniki"
                    addButtonText="Dodaj załączniki"
                    documents={agreementDetails.attachments}
                    handleOnSave={refresh}
                    url={agreementDetails.urls.documents}
                    maxFiles={20}
                  />
                </Col>
              </Row>
            </div>
          )}
        </LoaderPlaceholder>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        {user.hasPerm(UserPermission.ShopCanExportReceipts) &&
          agreementDetails &&
          ['draft', 'draft_incomplete'].includes(agreementDetails.status) && (
            <AgreementDetailsDeleteButton agreementDetails={agreementDetails} toggleIsVisible={toggleIsVisible} />
          )}
        <div className="ml-auto">
          <Button className="btn btn-light mr-2" type="button" onClick={toggleIsVisible}>
            Zamknij
          </Button>
          {!!agreementDetails && agreementDetails.status !== 'draft_incomplete' && (
            <>
              {(user.hasPerm(UserPermission.HrAgreementCanChangeToAnyStatus) ||
                (user.hasPerm(UserPermission.HrAgreementCanEdit) &&
                  StatusesWithActiveStatusChange.includes(agreementDetails.status))) && (
                <Button className="btn btn-info mr-2" type="button" onClick={showAgreementStatusChangeModal}>
                  Zmień status umowy
                </Button>
              )}
              {canDownload && (
                <a className="btn btn-green" target="_blank" href={agreementDetails.urls.preview}>
                  Podgląd umowy
                </a>
              )}
            </>
          )}
        </div>
      </ModalFooter>
    </>
  )
}

const StatusesWithActiveStatusChange = ['draft', 'to_verify', 'verified', 'active']
