import * as React from 'react'
import { TechnicalOrder } from '@models/technical-orders'
import Table from '@components/table/table'
import { withTooltip } from '@components/with-tooltip'

interface Props {
  technicalOrder: TechnicalOrder
  isDragDisabled?: boolean
}

export const TechnicalOrdersInProgressTableRowRowDragIndicator: React.FC<Props> = ({ isDragDisabled }) => (
  <Table.Cell className="valign-middle" style={{ width: '40px', maxWidth: '40px' }}>
    {isDragDisabled ? (
      <IconWithTooltip
        tooltipId="order-draggin-disabled-tooltip"
        tooltipPlacement="right"
        tooltipMessage={
          <span className="font-11 text-left">
            Przeciąganie elementów nie jest możliwe gdy włączone są filtry lub sortowanie.
          </span>
        }
      />
    ) : (
      <i className="uil uil-elipsis-double-v-alt" />
    )}
  </Table.Cell>
)

const IconWithTooltip = withTooltip()(() => <i className="uil-exclamation-circle text-secondary font-15" />)
