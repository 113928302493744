import { useWatch } from 'react-hook-form'
import { PackageWholesaleProduct, PackageWholesaleProductKind } from '@modules/package-wholesale/models'
import { packageWholesaleProductsSelector } from '@store/slices/package-wholesale-slice'
import { useAppSelector } from '@store/index'

const variants = ['gold', 'silver', 'brown']

interface Response {
  products: PackageWholesaleProduct[]
  isProductDisabled: (product: PackageWholesaleProduct) => boolean
  isProductSelected: (product: PackageWholesaleProduct) => boolean
}

export const usePackageWholesaleProductList = (control): Response => {
  const packageWholesaleProducts = useAppSelector(packageWholesaleProductsSelector)

  const filteredProducts = packageWholesaleProducts.filter(product => product.status === 'available')

  const products = useWatch({ control, name: 'products' })

  const isSelected = (product: PackageWholesaleProduct) => products.map(String).includes(String(product.id))

  const isKindAdded = (product: PackageWholesaleProduct, condition: (product) => boolean) =>
    filteredProducts.some(filteredProduct => isSelected(filteredProduct) && condition(filteredProduct))

  const isDisabled = (product: PackageWholesaleProduct) => {
    const isGastroProduct = (item: PackageWholesaleProduct) =>
      ['gastro_card_boost', 'gastro_card', 'gastro_card_2024', 'gastro_card_2025'].includes(item.kind)

    const isGraySubscriptionProduct = (item: PackageWholesaleProduct) =>
      !isGastroProduct(item) && item.subscription_kind === 'gray'

    const hasAddedGrey = isKindAdded(product, isGraySubscriptionProduct)
    const hasAddedVariant = isKindAdded(product, item => variants.includes(item.subscription_kind))

    const hasAddedGastroProduct = (kind: PackageWholesaleProductKind) =>
      isKindAdded(product, product => product.kind === kind)

    return (
      (variants.includes(product.subscription_kind) && hasAddedGrey) ||
      (isGraySubscriptionProduct(product) && hasAddedVariant) ||
      (product.kind === 'gastro_card_boost' && hasAddedGastroProduct('gastro_card')) ||
      (product.kind === 'gastro_card' && hasAddedGastroProduct('gastro_card_boost'))
    )
  }

  return {
    products: filteredProducts,
    isProductDisabled: isDisabled,
    isProductSelected: isSelected,
  }
}
