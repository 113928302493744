import * as React from 'react'
import {
  BookingExtendedStayDaysOption,
  BookingExtendedStayKind,
  BookingExtendedStayOption,
  ReceptionBooking,
  ReceptionBookingDetails,
} from '@models/reception'
import { formatPriceShort } from '@helpers/utils'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { Col, Row } from 'reactstrap'
import { ReceptionBookingExtendedStayOptionDays } from '@modules/reception/extended-stay/reception-booking-extended-stay-option-days'
import { ReceptionBookingExtendedStayOption } from '@modules/reception/extended-stay/reception-booking-extended-stay-option'
import { ReceptionBookingExtendedStayOptionDaysPriceDetails } from '@modules/reception/extended-stay/reception-booking-extended-stay-option-days-price-details'
import { useFormContext, useWatch } from 'react-hook-form'

interface ReceptionBookingExtendedStayContentProps {
  options: BookingExtendedStayOption[] | undefined
  onOptionsChange: (options: BookingExtendedStayOption[]) => void
  totalPrice: number
  booking: ReceptionBookingDetails | ReceptionBooking
}

export const ReceptionBookingExtendedStayContent: React.FC<ReceptionBookingExtendedStayContentProps> = ({
  options,
  totalPrice,
  booking,
  onOptionsChange,
}) => {
  const { control } = useFormContext()
  const kind = useWatch({ control, name: 'kind' })

  const daysOption = options?.find(
    (option: BookingExtendedStayOption) => option.kind === BookingExtendedStayKind.DAYS,
  ) as BookingExtendedStayDaysOption

  return (
    <LoaderPlaceholder content={options} hideText={true}>
      {(options || []).map((option: BookingExtendedStayOption) => (
        <React.Fragment key={option.kind}>
          {option.kind === BookingExtendedStayKind.DAYS ? (
            <ReceptionBookingExtendedStayOptionDays
              booking={booking}
              option={option}
              onOptionsChange={onOptionsChange}
            />
          ) : (
            <ReceptionBookingExtendedStayOption option={option} />
          )}
        </React.Fragment>
      ))}
      <Row>
        <Col sm={12} className="p-0">
          <div className="hr hr-grey mb-3" />
        </Col>
      </Row>
      <Row>
        <Col md={10}>
          <strong className="font-16">Koszt przedłużenia</strong>
        </Col>
        <Col md={2} className="text-right text-danger font-18 text-strong">
          {formatPriceShort(totalPrice)}
        </Col>
      </Row>
      {daysOption && kind === BookingExtendedStayKind.DAYS && (
        <ReceptionBookingExtendedStayOptionDaysPriceDetails option={daysOption} />
      )}
    </LoaderPlaceholder>
  )
}
