import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { PurchasePaymentContainer } from '@modules/shop/pos/purchase-payments/purchase-payment-container'
import { ApartmentGuest, getGuestsForApartment, recalculateShopReceipt } from '@store/actions/shop-actions'
import { createSelectOption, formatPriceShort } from '@helpers/utils'
import { Row } from 'reactstrap'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { selectAllApartments } from '@store/selectors/timeline'
import { Apartment } from '@models/apartment'
import { useApartments } from '@components/hooks/use-apartments'
import { ShopPOSWithParamsFormInputs } from '@modules/shop/pos/shop-pos-with-params'
import { useAppData } from '@components/hooks/use-app-data'
import { FormSelect } from '@hyper/forms/form-select'
import { getShopPurchaseItemsPayload } from '@modules/shop/pos/purchase-payments/utils'
import { CustomReactSelectOption } from '@components/custom-react-select'

interface GuestAccountPaymentBoxProps {
  onCancel: () => void
  onConfirm: (apartmentId: number, guestId: number) => void
  resortId: string
  apartment?: Apartment
  restToPay: string
}

export const GuestAccountPaymentBox: React.FC<GuestAccountPaymentBoxProps> = ({
  onConfirm,
  onCancel,
  resortId,
  restToPay,
  apartment,
}) => {
  const [guests, setGuests] = React.useState<ApartmentGuest[]>([])
  const apartments = useAppSelector(selectAllApartments)

  const apartmentOptions = apartments.reduce((prev: CustomReactSelectOption[], curr: Apartment) => {
    if (curr.is_virtual) return prev
    if (curr.available || curr.temporary_not_available)
      return [...prev, createSelectOption(curr.name, curr.id.toString())]
    return prev
  }, [])

  useApartments(resortId)

  const { setValue, control, getValues } = useFormContext<ShopPOSWithParamsFormInputs>()
  const receipt = useAppSelector((state: RootState) => state.shopState.receipt)
  const appData = useAppData()

  const dispatch = useAppDispatch()

  const selectedApartment = useWatch({ control, name: 'apartment' })
  const guest = useWatch({ control, name: 'guest' })
  const apartmentId = selectedApartment?.value || apartment?.id

  const fetchGuestsForApartment = async () => {
    if (apartmentId) {
      setGuests(await getGuestsForApartment(resortId, apartmentId, appData.urls.wallet.account))
    }
  }

  React.useEffect(() => {
    if (receipt) {
      dispatch(
        recalculateShopReceipt([
          receipt.urls.details,
          {
            resort: receipt.resort,
            tickets: receipt.tickets,
            apartment: apartmentId,
            items: getShopPurchaseItemsPayload(getValues('addedProducts')),
          },
        ]),
      )
    }
  }, [apartmentId])

  React.useEffect(() => {
    fetchGuestsForApartment()
  }, [apartmentId])

  React.useEffect(() => {
    setValue('guest', null)
  }, [apartmentId])

  const guestOptions = guests.map((guest: ApartmentGuest) => ({
    value: String(guest.id),
    label: `${guest.name} (${formatPriceShort(guest.limit)})`,
  }))

  const handleConfirm = () => {
    if (!guest || !apartmentId) return
    onConfirm(apartmentId, guest.value)
  }

  return (
    <PurchasePaymentContainer
      title="Zakup na rachunek Gościa lokalu"
      icon="uil-estate"
      price={restToPay}
      confirmDisabled={!guest || !apartmentId}
      onConfirm={handleConfirm}
      onCancel={onCancel}
    >
      <Row>
        {!apartment && (
          <FormSelect
            name="apartment"
            label="Wybierz lokal"
            options={apartmentOptions}
            selectProps={{ placeholder: 'Nazwa lokalu' }}
            formPlainProps={{ colClassName: 'w-100 mt-2' }}
          />
        )}

        <FormSelect
          name="guest"
          label="Kto dokonuje zakupu"
          options={guestOptions}
          selectProps={{ placeholder: apartmentId ? 'Wybierz gościa' : 'Nie wybrano lokalu' }}
          formPlainProps={{ colClassName: 'w-100 mt-2' }}
        />
      </Row>
    </PurchasePaymentContainer>
  )
}
