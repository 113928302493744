import * as React from 'react'
import { PageTitle } from '@hyper/page-title'
import { createSelectOption, useDocumentTitle } from '@helpers/utils'
import { Card, CardBody } from 'reactstrap'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { getHouseKeepingUsersReport } from '@store/actions/report-actions'
import { endOfMonth, startOfMonth, startOfToday } from 'date-fns'
import { HousekeepingReportFilters } from '@modules/reports/housekeeping-users-report/housekeeping-report-filters'
import { HousekeepingReportTable } from '@modules/reports/housekeeping-users-report/housekeeping-report-table'
import { TableFilters } from '@components/table/table'
import { useReportsAppData } from '@modules/reports/hooks/use-reports-app-data'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { ButtonWithIcon } from '@components/button-with-icon'
import { useModal } from '@components/modals/use-modal'
import { useTableFilters } from '@components/table/use-table-filters'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useMultipleResorts } from '@components/hooks/use-multiple-resorts'
import { useForm } from 'react-hook-form'
import { Form } from '@hyper/forms/form'

export interface HouseKeepingUserReportFiltersParams extends TableFilters {
  resorts: CustomReactSelectOption[]
  finished_at_after?: Date | undefined
  finished_at_before?: Date | undefined
}

export interface HouseKeepingReportFiltersParams extends TableFilters {
  resorts: CustomReactSelectOption[]
  finished_at_after?: Date | undefined
  finished_at_before?: Date | undefined
}

const pageTitle = 'Housekeeping Raport'

const defaultFilters: HouseKeepingReportFiltersParams = {
  finished_at_after: startOfMonth(startOfToday()),
  finished_at_before: endOfMonth(startOfToday()),
  resorts: [createSelectOption('Wszystkie', 'all')],
  search: '',
  ordering: '-user_id',
  page: 1,
  page_size: 10,
}

export const HouseKeepingReportView: React.FC = () => {
  const dispatch = useAppDispatch()

  useDocumentTitle(pageTitle)
  useMenuActive(NavigationPath.HouseKeepingReport)
  useReportsAppData()

  const houseKeepingUsersReport = useAppSelector((state: RootState) => state.reportsState.houseKeepingUsersReport)
  const [showHousekeepingUsersReportModal] = useModal('ReportsHousekeepingUsersModal')

  const { isLoading, filters, setFilters } = useTableFilters<HouseKeepingReportFiltersParams>({
    defaultFilters,
    action: async filters => {
      await dispatch(getHouseKeepingUsersReport({ ...filters, resorts: getSelectedResortsIds(filters.resorts) }))
    },
  })

  const methods = useForm<HouseKeepingUserReportFiltersParams>({ defaultValues: filters })

  const { getResortOptions, getSelectedResortsIds } = useMultipleResorts({ methods, field: 'resorts' })

  const resortOptions = React.useMemo(() => getResortOptions({ withAllOption: true }), [])

  const handleReportModalShow = () => {
    showHousekeepingUsersReportModal(null, {
      finished_at_after: filters.finished_at_after,
      finished_at_before: filters.finished_at_before,
      resorts: filters.resorts,
    })
  }

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitle} />

      <Card>
        <CardBody>
          <Form
            methods={methods}
            onSubmit={payload => setFilters({ ...filters, ...payload })}
            className="form form-style-filter mb-2"
          >
            <HousekeepingReportFilters
              filters={filters}
              setFilters={setFilters}
              defaultFilters={defaultFilters}
              resortOptions={resortOptions}
            />
            <HousekeepingReportTable
              isLoading={isLoading}
              filters={filters}
              setFilters={setFilters}
              houseKeepingUsersReport={houseKeepingUsersReport}
            />
          </Form>

          <div className="text-right mt-3" onClick={handleReportModalShow}>
            <ButtonWithIcon icon="uil-download-alt" color="secondary" text="Pobierz" />
          </div>
        </CardBody>
      </Card>
    </>
  )
}
