import * as React from 'react'
import { Col } from 'reactstrap'
import { PhoneInput, defaultCountries, parseCountry } from 'react-international-phone'
import 'react-international-phone/style.css'
import { useAppData } from '@components/hooks/use-app-data'
import { CountryData } from 'react-international-phone/build/types'
import { useFormContext, useWatch } from 'react-hook-form'
import { FormPlainError } from '@hyper/forms/index'
import classnames from 'classnames'

interface Props {
  name: string
  colSize: number
  label: string
  countriesCodes?: string[]
}

export const FormInputPhone: React.FC<Props> = ({ colSize, name, label, countriesCodes }) => {
  const { setValue, control, formState, clearErrors } = useFormContext()

  const { countries } = useAppData()

  const availableCountries = React.useMemo(
    () =>
      defaultCountries
        .map(country => {
          const { iso2, format, dialCode, name } = parseCountry(country)

          if (countriesCodes?.length && !countriesCodes.includes(iso2.toLowerCase())) {
            return null
          }

          const polishCountryTranslation = countries.find(
            appDataCountry => appDataCountry.id.toLowerCase() === iso2.toLowerCase(),
          )?.name

          return [polishCountryTranslation || name, iso2, dialCode, format]
        })
        .filter(Boolean) as CountryData[],
    [countries, countriesCodes],
  )

  const handleChange = (phoneNumber: string, meta) => {
    const isEmpty = `+${meta.country.dialCode}` === phoneNumber
    if (isEmpty) return

    clearErrors(name)
    setValue(name, phoneNumber)
  }

  const value = useWatch({ control, name })

  const hasError = !!formState.errors[name]

  return (
    <Col md={colSize}>
      <label>{label}</label>
      <PhoneInput
        value={value}
        onChange={handleChange}
        className={classnames('custom-phone-input', { 'has-error': hasError })}
        defaultCountry="pl"
        countries={availableCountries}
      />
      <FormPlainError name={name} className="mb-1" />
    </Col>
  )
}
