import * as React from 'react'
import { Col, Row } from 'reactstrap'
import { FormInput } from '@hyper/forms'
import { asDecimal, formatPrice } from '@helpers/utils'
import { useFormContext, useWatch } from 'react-hook-form'
import { ButtonWithIcon } from '@components/button-with-icon'
import { useModal } from '@components/modals/use-modal'
import { CashCount } from '@modules/payment-registers/cash-count-modal'
import { cashCountEmptyValues, useCashCount } from '@modules/payment-registers/cash-count-modal/use-cash-count'
import { IconWithText } from '@components/icon-with-text'
import { useToggle } from '@components/hooks/use-toggle'

interface Props {
  total: number
  fieldName: 'cashbox_amount_user' | 'cards_amount_user'
  differenceFieldName: 'cards_difference_reason' | 'cash_difference_reason'
  resortId: number
}
export const UserCashBoxCloseDayUserAmount = ({
  total,
  fieldName,
  resortId,
  differenceFieldName,
}: Props): JSX.Element => {
  const { control, setValue } = useFormContext()
  const [showDifferenceReason, toggleShowDifferenceReason] = useToggle()
  const [cashCountValues, setCashCountValues] = React.useState<CashCount>(cashCountEmptyValues)
  const { totalCashCount } = useCashCount(cashCountValues)
  const safeAmount = useWatch({ control, name: fieldName })

  const diff = asDecimal(safeAmount).minus(asDecimal(total))
  const [handleCashCount] = useModal('CashCountModal', {
    setCashCountValues,
    cashCountValues,
    resort: resortId,
  })

  React.useEffect(() => {
    setValue(fieldName, Number(totalCashCount))
  }, [cashCountValues])

  React.useEffect(() => {
    setValue(differenceFieldName, '')
  }, [showDifferenceReason])

  return (
    <Row>
      <Col md={6}>
        <strong>Stan kasy rzeczywisty</strong>
      </Col>
      <FormInput
        colSize={6}
        name={fieldName}
        type="currency"
        inputClassName="text-right bg-white"
        readOnly={fieldName === 'cashbox_amount_user'}
      />
      {fieldName === 'cashbox_amount_user' && (
        <Col md={12} className="text-right mb-2">
          <ButtonWithIcon
            icon="uil-money-withdraw"
            color="outline-secondary"
            text="Przelicz gotówkę"
            handleClick={handleCashCount}
          />
        </Col>
      )}
      <Col md={6}>
        <strong>Różnica</strong>
      </Col>
      <Col md={6} className="text-right">
        <span className="font-weight-semibold">
          {diff.gt(asDecimal(0)) && '+'}
          {formatPrice(diff.toString())}
        </span>
      </Col>
      {!diff.eq(asDecimal(0)) &&
        (!showDifferenceReason ? (
          <Col md={12} className="mt-1">
            <IconWithText
              onClick={toggleShowDifferenceReason}
              icon="uil-plus"
              text="Dodaj wyjaśnienie"
              wrapperClassNames="text-semi-strong font-10 text-secondary cursor-pointer"
            />
          </Col>
        ) : (
          <FormInput
            name={differenceFieldName}
            inputProps={{ maxlength: 150 }}
            placeholder="Powód różnicy"
            formGroupClassName="mt-1"
          />
        ))}
    </Row>
  )
}
