import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { FormInput } from '@hyper/forms'
import { useFormRequest } from '@components/hooks/use-api-request'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useForm } from 'react-hook-form'
import { BaseModalProps } from '@components/modals/types'
import { ReceptionBookingDetails } from '@models/reception'
import { Form } from '@hyper/forms/form'
import { commonObjectPost } from '@store/actions/generic-actions'
import { getBookingVouchers, getBookingWalletDetails } from '@store/actions/reservation-actions'
import { useAppDispatch } from '@store/index'

interface FormInputs {
  code: string
}

interface Props extends BaseModalProps {
  booking: ReceptionBookingDetails
}

export const ReservationBistroVoucherUseModal: React.FC<Props> = ({ booking, toggleIsVisible }) => {
  const methods = useForm<FormInputs>()
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading, action: onSubmit } = useFormRequest(
    async (payload: FormInputs) => {
      await commonObjectPost<ReceptionBookingDetails>(booking.urls.reservation_booking_vouchers_use_code, payload)
      await dispatch(getBookingVouchers(booking))
      await dispatch(getBookingWalletDetails(booking))
      addSuccessMessage('Sukces', 'Kod został zrealizowany')
      toggleIsVisible()
    },
    methods.setError,
    { nonFieldErrorsAs: 'code' },
  )

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Zrealizuj kod</ModalHeader>
      <ModalBody>
        <Row>
          <FormInput label="Kod" name="code" />
        </Row>
      </ModalBody>

      <ModalFooter>
        <Button color="light" onClick={toggleIsVisible}>
          Zamknij
        </Button>

        <SaveButton role="submit" className="btn btn-green" label="Zapisz" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
