import * as React from 'react'
import { formatDate } from '@helpers/date-helper'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { useDocumentTitle } from '@helpers/utils'
import { PageTitle } from '@hyper/page-title'
import { PaymentRegisterUserCashboxTransactions } from '@modules/payment-registers/user-cashbox/transactions'
import { PaymentRegisterUserCashBoxSummary } from '@modules/payment-registers/user-cashbox/summary'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'

export const PaymentRegisterUserCashBoxView = (): JSX.Element | null => {
  const user = useAuthenticatedUser()

  const pageTitle = `Kasa dzień ${formatDate(user.paymentDayUserReport?.day_start, 'dd.MM')}`
  useMenuActive(NavigationPath.PaymentRegisterUserCashbox)
  useDocumentTitle(pageTitle)

  if (user.paymentDayUserReport) {
    return (
      <>
        <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitle} />
        <PaymentRegisterUserCashboxTransactions paymentDayUserReport={user.paymentDayUserReport} />
        <PaymentRegisterUserCashBoxSummary paymentDayUserReport={user.paymentDayUserReport} />
      </>
    )
  }
  return null
}
