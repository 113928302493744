import { CustomReactSelectOption } from '@components/custom-react-select'
import { TechnicalOrderStatus } from '@models/technical-orders'
import { createSelectOption } from '@helpers/utils'

export const technicalOrderStatus: CustomReactSelectOption<string, TechnicalOrderStatus>[] = [
  createSelectOption('Do zrobienia', 'todo'),
  createSelectOption('Zlecone', 'ordered'),
  createSelectOption('W toku', 'in_progress'),
  createSelectOption('Do sprawdzenia', 'to_accept'),
  createSelectOption('Wykonane', 'done'),
]
export const guestsTechnicalOrderStatus: CustomReactSelectOption<string, TechnicalOrderStatus>[] = [
  createSelectOption('Nowa', 'new'),
  createSelectOption('Otwarte', 'todo'),
  createSelectOption('Zamknięte', 'done'),
]
