import * as React from 'react'
import { Label, Row } from 'reactstrap'
import { FormPlain } from '@hyper/forms'
import DatePickerInput from '@components/date/date-picker-input'
import { FormSelect } from '@hyper/forms/form-select'
import { createSelectOption } from '@helpers/utils'
import { CustomReactSelectOption } from '@components/custom-react-select'

interface Props {
  checkTypeOptions: CustomReactSelectOption[]
}

export const ReceptionNotificationParams: React.FC<Props> = ({ checkTypeOptions }) => (
  <Row>
    <FormSelect options={checkTypeOptions} name="check_type" formPlainProps={{ colSize: 4 }} label="Goście" />
    <FormSelect options={FeedingOptions} name="feeding" formPlainProps={{ colSize: 4 }} label="Wyżywienie" />
    <FormPlain colSize={4} name="date">
      <Label>W dniu</Label>
      <DatePickerInput name="date" />
    </FormPlain>
  </Row>
)

const FeedingOptions = [
  createSelectOption('Wszystkie', 'all'),
  createSelectOption('Wykupione', 'with_feeding'),
  createSelectOption('Niewykupione', 'without_feeding'),
  createSelectOption('Śniadanie + Obiadokolacja', 'breakfast_with_dinner'),
  createSelectOption('Śniadanie', 'breakfast'),
  createSelectOption('Obiadokolacja', 'dinner'),
]
