import * as React from 'react'
import { YesNoWithIcon } from '@components/yes-no-with-icon'

interface Props {
  name: string
  isAccepted: boolean
}

export const TermStatus = ({ name, isAccepted }: Props): JSX.Element => (
  <div className="mb-1 d-flex justify-content-between align-items-center">
    <div>{name}</div>
    <YesNoWithIcon iconSize="font-16" value={isAccepted} wrapperClassNames="d-block" />
  </div>
)
