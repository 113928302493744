import * as React from 'react'
import { NotificationEvents } from '@models/dashboard'
import { useWebSocket } from '@components/hooks/use-web-socket'
import { useAppDispatch } from '@store/index'
import { CafeteriaDetails } from '@models/cafeteria'
import { updateCafeteriaDetails } from '@store/slices/cafeteria-slice'

export const CafeteriaWebSocketHandler: React.FC = () => {
  const dispatch = useAppDispatch()

  useWebSocket<CafeteriaDetails>(
    (message, payload) => {
      switch (message) {
        case NotificationEvents.CAFETERIA_CHANGE:
          dispatch(updateCafeteriaDetails(payload as CafeteriaDetails))
          break
      }
    },
    [],
    [NotificationEvents.CAFETERIA_CHANGE],
  )
  return null
}
