import * as React from 'react'
import { FormInput, FormPlain } from '@hyper/forms'
import { Col } from 'reactstrap'
import { IconWithTooltip } from '@components/icon-with-tooltip'
import { useFormContext, useWatch } from 'react-hook-form'
import { PackageWholesaleFormInputs } from '@modules/package-wholesale/create/modal'
import { FormCheckbox } from '@hyper/forms/form-checkbox'

export const PackageWholesaleCreateModalFormPassword = (): JSX.Element => {
  const { control } = useFormContext<PackageWholesaleFormInputs>()

  const isProtected = useWatch({ control, name: 'password_protected' })

  return (
    <Col md={10} className="d-flex align-items-center pl-0">
      <FormPlain colSize={8} name="password_protected" colClassName="px-0">
        <FormCheckbox
          name="password_protected"
          label={
            <span>
              Sprzedaż zabezpieczona hasłem
              <IconWithTooltip
                icon="uil-exclamation-circle ml-1"
                tooltipId="password-protected-tooltip-id"
                tooltipPlacement="right"
                tooltipMessage={
                  <span className="font-11 text-left d-block">
                    Dostęp do strony sprzedażowej będą miały tylko osoby posiadające wpisane hasło.
                  </span>
                }
                color="text-secondary"
              />
            </span>
          }
        />
      </FormPlain>
      <FormInput
        disabled={!isProtected}
        colClassName="pl-0"
        colSize={6}
        name="password"
        placeholder="Hasło dostępu"
        type="text"
      />
    </Col>
  )
}
