import * as React from 'react'
import { ClientDetails } from '@models/clients'
import { Card, CardBody, Row } from 'reactstrap'
import { ColAuto } from '@hyper/col-auto'
import { IconWithText } from '@components/icon-with-text'
import { ClientDetailsModalClientProfileCompanyDataTable } from './client-profile-company-data-table'
import { ClientDetailsModalClientProfileCompanyDataForm } from '@modules/crm/clients/details-modal/client-profile/client-profile-company-data-form'

interface Props {
  client: ClientDetails
}

export const ClientDetailsModalClientProfileCompanyData: React.FC<Props> = ({ client }) => {
  const [isEditMode, setIsEditMode] = React.useState(false)

  const toggleEditMode = () => setIsEditMode(state => !state)

  return (
    <Card className="card-grey">
      <CardBody className="card-body-grey">
        <Row>
          <ColAuto>
            <h5 className="mt-0">Dane do faktury</h5>
          </ColAuto>
          {!isEditMode && (
            <ColAuto className="col-auto ml-auto">
              <IconWithText
                icon="uil-edit-alt font-16"
                text="zmień"
                wrapperClassNames="cursor-pointer text-secondary"
                textClass="font-10 text-semi-strong"
                onClick={toggleEditMode}
              />
            </ColAuto>
          )}
        </Row>
        {isEditMode ? (
          <ClientDetailsModalClientProfileCompanyDataForm client={client} toggleEditMode={toggleEditMode} />
        ) : (
          <ClientDetailsModalClientProfileCompanyDataTable client={client} />
        )}
      </CardBody>
    </Card>
  )
}
