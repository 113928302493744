import * as React from 'react'
import { Col, Row } from 'reactstrap'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { usePinPadReport } from '@modules/reception/common/pin-pad/use-pin-pad-report'
import { SaveButton } from '@hyper/button'
import { IconWithText } from '@components/icon-with-text'
import { RootState, useAppSelector } from '@store/index'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { UserCashBoxCloseDayUserAmount } from '@modules/payment-registers/user-cashbox/close-day-modal/user-amount'
import { PaymentDayUserCloseReport } from '@models/payments'

interface Props {
  readOnly: boolean
  receptionWorkstationId: number
  eservicePadId: number
  dayCloseReport: PaymentDayUserCloseReport
  resortId: number
}

export const UserCashBoxCloseDayCardsSummary = ({
  readOnly,
  receptionWorkstationId,
  eservicePadId,
  resortId,
  dayCloseReport,
}: Props): JSX.Element => {
  const user = useAuthenticatedUser()
  const { addSuccessMessage } = useNotificationHook()
  const workstations = useAppSelector((state: RootState) => state.appState.appData.reception_workstations)

  const workStation = workstations.find(row => row.id === receptionWorkstationId)

  const [isLoading, handlePrintReport, eservicePinPad] = usePinPadReport({ userEservicePadId: eservicePadId })

  const onClick = async () => {
    if (user.hasPerm(UserPermission.EserviceCanUsePinPad)) {
      await handlePrintReport()
      addSuccessMessage('Uwaga!', `Wydruk raportu z terminala ${eservicePinPad?.name || ''} został zlecony`)
    } else {
      addSuccessMessage('Uwaga!', `Uwaga! Wydrukuj raport wysyłki z terminala  ${eservicePinPad?.name || ''}!`)
    }
  }

  return (
    <div className="p-2 pb-1">
      <Row className="mb-2  align-items-center">
        {!readOnly && (
          <>
            <Col md={12} className="text-right mb-1">
              {workStation?.name} {eservicePinPad && `(${eservicePinPad?.name})`}
            </Col>
            <Col md={12} className="text-right">
              <SaveButton
                isSaving={isLoading}
                onClick={onClick}
                type="button"
                label={<IconWithText icon="uil-lock" text="Zamknij terminal" />}
                labelSaving="Wysyłanie.."
                className="btn btn-outline-danger"
              />
            </Col>
          </>
        )}
      </Row>
      {!readOnly && (
        <UserCashBoxCloseDayUserAmount
          fieldName="cards_amount_user"
          resortId={resortId}
          total={dayCloseReport.cards_total}
          differenceFieldName="cards_difference_reason"
        />
      )}
    </div>
  )
}
