import { useApiRequest } from '@components/hooks/use-api-request'
import { ReportTask } from '@models/reports'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useHandleAxiosFormErrors } from '@helpers/handle-axios-errors'
import { useAppDispatch, useAppSelector } from '@store/index'
import {
  clearReportTaskGenerationError,
  selectReportGenerationError,
  setLastReportTask,
} from '@store/slices/report-task-slice'
import { PayloadAction } from '@reduxjs/toolkit'
import * as React from 'react'
import { ResponseHeaders } from '@models/base'
import { useModal } from '@components/modals/use-modal'
import { noop } from '@helpers/utils'
import { AxiosError } from 'axios'

export const useReportRequest = (asyncCallable, messageKind: 'notification' | 'modal' = 'modal') => {
  const { addSuccessMessage, addSuccessNotification } = useNotificationHook()
  const dispatch = useAppDispatch()

  return useApiRequest<ReportTask>(async (...params) => {
    try {
      const result: PayloadAction<ReportTask> = await asyncCallable(...params)

      const message = 'Raport zlecony do generowania, zostanie wysłany na Twój adres e-mail.'
      if (messageKind === 'modal') {
        addSuccessMessage('Sukces', message)
      } else {
        addSuccessNotification(message)
      }
      dispatch(setLastReportTask(result.payload))
      return result.payload
    } catch (e) {
      noop()
    }
  })
}

export const useReportFormRequest = (asyncCallable, setError, messageKind: 'notification' | 'modal' = 'modal') => {
  const handleAxiosFormErrors = useHandleAxiosFormErrors()
  const reportGenerationError = useAppSelector(selectReportGenerationError)
  const dispatch = useAppDispatch()

  const [showRegenerateReportConfirmationModal] = useModal('RegenerateUserReportConfirmationModal', null, {
    persist: true,
  })

  React.useEffect(() => {
    if (!reportGenerationError) return

    if (reportGenerationError.error.response?.headers[ResponseHeaders.API_ERROR_CODE] === 'similar_report_exists') {
      showRegenerateReportConfirmationModal(null, { reportGenerationError }, true)
    } else {
      handleAxiosFormErrors(reportGenerationError.error as AxiosError<Error>, setError, { showGlobalError: false })
    }

    dispatch(clearReportTaskGenerationError())
  }, [reportGenerationError])

  return useReportRequest(async (...params) => await asyncCallable(...params), messageKind)
}
