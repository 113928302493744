import { ReceptionBookingGuest } from '@models/booking'
import { TermStatus } from '@components/term-status'
import * as React from 'react'

interface Props {
  guest: ReceptionBookingGuest
  onShowDefaultCheckinModalStep: (step: number) => void
}

export const ReceptionBookingCheckInPreviewGuestRow = ({ guest, onShowDefaultCheckinModalStep }: Props) => {
  const scopes = guest.wallet_limits.map(limit => limit.scope)

  return (
    <div className="row mb-1 border-bottom font-11 col-11 py-2">
      <div className="reception__booking-check-in-preview__guest-badge__wrapper col-1">
        <i className="uil-user reception__booking-check-in-preview__guest-badge__icon" />
      </div>

      <div className="col-5">
        <p className="font-13 mb-1 text-strong">{guest.name}</p>
        <p className="mb-1">{guest.type_display}</p>
        <p className="mb-1">
          {guest.type === 'adult'
            ? guest.email || (
                <EmptyField title="uzupełnij adres" onShowDefaultCheckinModalStep={onShowDefaultCheckinModalStep} />
              )
            : '-'}
        </p>
        <p className="mb-1">
          {guest.type === 'adult'
            ? guest.phone || (
                <EmptyField title="uzupełnij telefon" onShowDefaultCheckinModalStep={onShowDefaultCheckinModalStep} />
              )
            : '-'}
        </p>
      </div>
      <div className="col-6">
        <TermStatus name="Zgoda RODO e-mail:" isAccepted={guest.rodo_email} />
        <TermStatus name="Zgoda RODO SMS:" isAccepted={guest.rodo_phone} />
        <TermStatus name="Zgoda RODO telefon:" isAccepted={guest.rodo_phone_call} />
        <TermStatus name="Zakupy kawiarnia:" isAccepted={scopes.includes('cafe')} />
        <TermStatus name="Zakupy bistro:" isAccepted={scopes.includes('bistro')} />
        <TermStatus name="Zakupy sklep:" isAccepted={scopes.includes('shop')} />
        <TermStatus name="Automaty:" isAccepted={scopes.includes('vending_machines')} />
      </div>
    </div>
  )
}

const EmptyField = ({ title, onShowDefaultCheckinModalStep }) => {
  const handleShowDefaultCheckInModalStep = () => onShowDefaultCheckinModalStep(2)
  return (
    <span className="text-secondary cursor-pointer" onClick={handleShowDefaultCheckInModalStep}>
      {title}
    </span>
  )
}
