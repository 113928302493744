import * as React from 'react'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { SaleAppData } from '@models/sale'
import { getSaleAppData } from '@store/actions/sale-actions'

export const useSaleAppData = (): SaleAppData => {
  const saleAppData = useAppSelector((state: RootState) => state.saleState.appData)
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (saleAppData.status === 'unknown') {
      dispatch(getSaleAppData())
    }
  }, [saleAppData.status])

  return saleAppData
}
