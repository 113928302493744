import * as React from 'react'
import { useForm } from 'react-hook-form'
import { TableFilters } from '@components/table/table'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { GastroCardDetails, GastroWalletItem } from '@models/promotions'
import { ColAuto } from '@hyper/col-auto'
import { GastroCardWalletItemsOptions } from '@modules/promotions/gastro-cards/gastro-card-details/gastro-card-wallet-items/gastro-card-wallet-items-options'

interface Props {
  defaultFilters: TableFilters
  setFilters: (filters: TableFilters) => void
  filters: TableFilters
  selectedRows: GastroWalletItem[]
  gastroCardDetails?: GastroCardDetails
  clearSelectedRows: () => void
}

export const GastroCardWalletItemsFilters: React.FC<Props> = ({
  defaultFilters,
  gastroCardDetails,
  filters,
  clearSelectedRows,
  selectedRows,
  setFilters,
}) => {
  const { setValue, watch, getValues, handleSubmit } = useForm<TableFilters>({
    defaultValues: defaultFilters,
  })

  React.useEffect(() => {
    onSubmit(getValues())
  }, [watch('search')])

  const onSubmit = payload => setFilters({ ...filters, ...payload })

  useHandleTableFiltersChange(watch, filters, onSubmit)

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="row form form-style-filter">
      {!!gastroCardDetails && (
        <ColAuto>
          <GastroCardWalletItemsOptions
            gastroCardDetails={gastroCardDetails}
            clearSelectedRows={clearSelectedRows}
            selectedRows={selectedRows}
          />
        </ColAuto>
      )}

      <SearchFilter hideLabel={true} setValue={setValue} colClassName="col-md-4" />
    </form>
  )
}
