import * as React from 'react'
import { Button, Col, Row } from 'reactstrap'
import { formatPrice } from '@helpers/utils'
import declination from '@helpers/declination'
import { PriceLoader } from '@modules/reception/checkin/step-feeding/price-loader'

interface Props {
  title: string
  price: string
  pricePerDay: string
  onClick: () => void
  mealsCount: number
  isSelected: boolean
  isCalculating?: boolean
}

export const ReceptionBookingNewFeedingFastSelectionBlock = ({
  title,
  price,
  pricePerDay,
  onClick,
  mealsCount,
  isSelected,
  isCalculating,
}: Props): JSX.Element => (
  <Col md={4}>
    <Row className="border mx-0 p-2 h-100">
      <Col md={6} className="text-primary px-0">
        <strong className="font-16">{title}</strong> <br />
        <span>dla wszystkich</span>
      </Col>
      <Col md={6} className="text-right">
        <PriceLoader
          isLoading={isCalculating}
          price={price}
          content={
            <>
              <p className="text-danger mb-0 font-weight-bold font-16">{formatPrice(price)}</p>
              <span className="font-11">{formatPrice(pricePerDay)} / doba</span>
            </>
          }
        />
      </Col>
      <div className="align-self-end">
        <Button onClick={onClick} className="btn btn-green mt-3 shadow-none">
          {isSelected ? 'Odznacz' : 'Zaznacz'} {mealsCount} {declination.meal(mealsCount)}
        </Button>
      </div>
    </Row>
  </Col>
)
