import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { FormProvider, useForm } from 'react-hook-form'
import { endOfMonth, startOfMonth, startOfToday } from 'date-fns'
import { SoldProductsReport } from '@store/actions/report-actions'
import { formatDate } from '@helpers/date-helper'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { ReceptionSellersFilter } from '@modules/reports/reception-sellers/reception-sellers-filter'
import { ReceptionSellersTable } from '@modules/reports/reception-sellers/reception-sellers-table'
import { PageTitle } from '@hyper/page-title'
import { Card, CardBody } from 'reactstrap'
import { useDocumentTitle } from '@helpers/utils'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { ResortTabs } from '@components/resort-tabs'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useAppData } from '@components/hooks/use-app-data'
import { commonObjectGet } from '@store/actions/generic-actions'

const pageTitle = 'Raport sprzedaży recepcji'

interface Params {
  resort_id: string
}

interface FormInputs {
  sell_date_after: Date
  sell_date_before: Date
  seller: CustomReactSelectOption | null
}

const defaultValues = {
  sell_date_after: startOfMonth(startOfToday()),
  sell_date_before: endOfMonth(startOfToday()),
  seller: null,
}

export const ReceptionSellersReport: React.FC = () => {
  const [soldProducts, setSoldProducts] = React.useState<SoldProductsReport[]>([])

  const { resort_id } = useParams() as unknown as Params
  const user = useAuthenticatedUser()
  const navigate = useNavigate()
  const appData = useAppData()

  React.useEffect(() => {
    navigate(`${NavigationPath.ReportsReceptionSellers}/${String(user.resorts[0].id)}`)
  }, [])

  const resortId = parseInt(resort_id, 10)

  const methods = useForm<FormInputs>({
    defaultValues,
  })

  const { sell_date_before, sell_date_after, seller } = methods.watch()

  React.useEffect(() => {
    fetchReportData()
  }, [sell_date_before, sell_date_after, seller, resortId])

  const { isLoading, action: fetchReportData } = useApiRequest(async () => {
    if (seller?.value) {
      setSoldProducts(
        await commonObjectGet<SoldProductsReport[]>(appData.urls.reports.stats_sale_item_dashboard_report, {
          sell_date_after: formatDate(sell_date_after),
          sell_date_before: formatDate(sell_date_before),
          seller: seller?.value,
          resort_id: resortId,
        }),
      )
    }
  })

  useDocumentTitle(pageTitle)

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitle} />
      <Card>
        <CardBody>
          <FormProvider {...methods}>
            <ResortTabs resortId={resortId} url={NavigationPath.ReportsReceptionSellersWithParams} />
            <ReceptionSellersFilter defaultValues={defaultValues} resortId={resortId} />
            {seller && <ReceptionSellersTable isLoading={isLoading} soldProducts={soldProducts} />}
          </FormProvider>
        </CardBody>
      </Card>
    </>
  )
}
