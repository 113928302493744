import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { SaveButton } from '@hyper/button'
import { EservicePinPad } from '@models/eservice'
import { ButtonWithIcon } from '@components/button-with-icon'

interface Props {
  eServicePinPad: EservicePinPad | undefined
  isRetrying: boolean
  isConfirming: boolean
  isDisabled?: boolean
  isPaymentConfirmationDisabled?: boolean
  onCancel: () => void
  onSubmit: () => void
  onRetry: () => void
  rejectionTitle?: string
}

export const ReceptionPinPadRefusedPaymentBox = ({
  eServicePinPad,
  isRetrying,
  isConfirming,
  isDisabled,
  isPaymentConfirmationDisabled,
  onCancel,
  onSubmit,
  onRetry,
  rejectionTitle = 'Płatność odrzucona',
}: Props): JSX.Element => (
  <div>
    <div className="hr hr-grey-2 mb-2 mt-2" />
    <div className="text-center">
      <p className="mb-1">
        Status płatności z: terminal <strong>{eServicePinPad?.name || ''}</strong>{' '}
      </p>
      <IconWithText icon="uil-times font-20" text={rejectionTitle} wrapperClassNames="text-danger text-semi-strong" />
    </div>
    <div className="reception__payment-box-buttons mt-2">
      <ButtonWithIcon
        btnClass="btn btn-light shadow-sm reception__payment-box-buttons--cancel"
        type="button"
        handleClick={onCancel}
        disabled={isDisabled}
        icon="uil-multiply"
        text="Anuluj"
      />
      <SaveButton
        disabled={isDisabled || isRetrying || isPaymentConfirmationDisabled}
        type="button"
        onClick={onSubmit}
        isSaving={isConfirming}
        className="btn btn-secondary shadow-sm flex-fill reception__payment-box-buttons--submit"
        label={<IconWithText icon="uil-check" text="Zatwierdź płatność" />}
        labelSaving="Zatwierdzanie..."
      />
      <SaveButton
        isSaving={isRetrying}
        className="btn btn-primary shadow-sm col-12 reception__payment-box-buttons--repeat"
        type="button"
        onClick={onRetry}
        disabled={isDisabled || isConfirming}
        label="Spróbuj ponownie"
        labelSaving="Użyj terminala..."
      />
    </div>
  </div>
)
