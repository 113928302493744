import * as React from 'react'
import { useForm } from 'react-hook-form'
import { Button, Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useApiRequest, useFormRequest } from '@components/hooks/use-api-request'
import { EmailTemplate, EmailTemplateDetails } from '@modules/cms/models'
import { commonObjectPut } from '@store/actions/generic-actions'
import { getEmailTemplateDetails, updateEmailTemplateDetails } from '@store/actions/cms-actions'
import { NotificationTemplatesEmailModalForm } from '@modules/cms/notification-templates/email/modal/form'
import { NotificationTemplatesEmailModalTestButton } from '@modules/cms/notification-templates/email/modal/test-button'
import { NotificationTemplatesEmailModalAttachments } from '@modules/cms/notification-templates/email/modal/attachments'
import { BaseModalProps } from '@components/modals/types'
import { languageType } from '@models/cms'
import { Form } from '@hyper/forms/form'

interface Props extends BaseModalProps {
  emailTemplate: EmailTemplate
}

export interface NotificationTemplatesEmailFormInputs {
  content_de: string
  content_en: string
  content_pl: string
  is_active: boolean
  key: string
  name: string
  subject_de: string
  subject_en: string
  subject_pl: string
}

export const NotificationTemplatesEmailModal: React.FC<Props> = ({ toggleIsVisible, emailTemplate }) => {
  const [language, setLanguage] = React.useState<languageType>('pl')
  const methods = useForm<NotificationTemplatesEmailFormInputs>({
    defaultValues: emailTemplate,
  })
  const emailTemplateDetails = useAppSelector((state: RootState) => state.cmsState.emailTemplateDetails)
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading: isDetailsLoading, action: fetch } = useApiRequest(
    async () => await dispatch(getEmailTemplateDetails(emailTemplate.urls.details)),
  )

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: NotificationTemplatesEmailFormInputs) => {
    dispatch(
      updateEmailTemplateDetails(await commonObjectPut<EmailTemplateDetails>(emailTemplate.urls.details, payload)),
    )

    addSuccessMessage('Sukces', 'Powiadomienie zostało zmienione!')
    toggleIsVisible()
  }, methods.setError)

  React.useEffect(() => {
    fetch()
  }, [])

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>
        Edytuj szablon <span className="text-strong">{emailTemplate.name}</span>
      </ModalHeader>
      <ModalBody className="pb-0">
        <NotificationTemplatesEmailModalForm
          language={language}
          onLanguageChange={setLanguage}
          emailTemplate={emailTemplate}
        />
        {emailTemplateDetails && !isDetailsLoading && (
          <NotificationTemplatesEmailModalAttachments emailTemplateDetails={emailTemplateDetails} />
        )}
      </ModalBody>

      <ModalFooter style={{ display: 'block' }}>
        <Row>
          <Col md={6} className="px-0">
            <NotificationTemplatesEmailModalTestButton language={language} emailTemplate={emailTemplate} />
          </Col>
          <Col md={6} className="text-right px-0">
            <Button color="light" type="button" onClick={toggleIsVisible}>
              Zamknij
            </Button>

            <SaveButton className="ml-1 btn btn-green" isSaving={isLoading} />
          </Col>
        </Row>
      </ModalFooter>
    </Form>
  )
}
