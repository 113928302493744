import * as React from 'react'
import Table from '@components/table/table'
import { useModal } from '@components/modals/use-modal'
import { RentalWarehouse } from '@modules/rental/models'
import { useAppData } from '@components/hooks/use-app-data'

interface Props {
  warehouse: RentalWarehouse
  index: number
}

export const RentalWarehouseTableRow = ({ warehouse, index }: Props): JSX.Element => {
  const [showProductModal] = useModal('RentalWarehouseCreateModal', { warehouse })

  const { resorts } = useAppData()

  const selectedWarehouse = resorts.find(resort => resort.id === warehouse.resort)

  return (
    <Table.Row key={warehouse.id}>
      <Table.Cell width={70}>{index + 1}</Table.Cell>
      <Table.Cell>{warehouse.name} </Table.Cell>
      <Table.Cell>{selectedWarehouse?.name ?? 'Brak'}</Table.Cell>
      <Table.Cell style={{ width: 40 }}>
        <i className="uil-pen font-14 lh-1 cursor-pointer" onClick={showProductModal} />
      </Table.Cell>
    </Table.Row>
  )
}
