import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useAppData } from '@components/hooks/use-app-data'
import { ReceptionBooking } from '@models/reception'

interface Props {
  booking: ReceptionBooking
}

export const VirtualPosLink = ({ booking }: Props): React.ReactNode => {
  const { urls } = useAppData()
  const user = useAuthenticatedUser()

  const handleOpenVirtualPos = () => {
    window.open(`${urls.wallet.virtual_pos}${booking.resort_id}/${booking.apartment.id}`, '_blank')
  }

  if (!user.is_superuser) return null
  return <IconWithText icon="uil-plus font-16" textClass="font-11" text="dodaj zakup" onClick={handleOpenVirtualPos} />
}
