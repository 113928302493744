import * as React from 'react'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'

export const PaymentRegisterCashboxView = (): null => {
  const user = useAuthenticatedUser()

  const navigate = useNavigate()

  React.useEffect(() => {
    navigate(NavigationPath.PaymentRegisterCashboxWithParam.replace(':resort_id', String(user.resorts[0].id)))
  }, [])

  return null
}
