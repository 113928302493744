import * as React from 'react'
import { BookingDocument, ReceptionBookingDetails } from '@models/reception'
import { IconWithText } from '@components/icon-with-text'
import { UncontrolledTooltip } from 'reactstrap'
import classnames from 'classnames'

interface Props {
  bookingDetails: ReceptionBookingDetails
  className?: string
  signatureClassName?: string
  signedDocumentsClassName?: string
  bookingDocumentsElement?: React.ReactNode
  afterSignature?: React.ReactNode
  beforeSignature?: React.ReactNode
  withDocuments?: boolean
}

export const ReceptionBookingCheckInPreviewPersonalDataSignature = ({
  bookingDetails,
  className,
  signatureClassName,
  signedDocumentsClassName,
  bookingDocumentsElement,
  beforeSignature,
  afterSignature,
  withDocuments = true,
}: Props): JSX.Element => (
  <div className={className}>
    <div className="d-flex">
      {beforeSignature}
      <div
        className={classnames(
          'position-relative reception__booking-check-in-preview__signature__wrapper',
          signatureClassName,
        )}
      >
        <img
          id="signature-preview-tooltip"
          className="w-100 h-100 position-absolute left-0 top-0"
          src={bookingDetails.booking_signature_url}
          alt="singature"
        />
      </div>
      {afterSignature}
    </div>

    <UncontrolledTooltip
      placement="top"
      target="signature-preview-tooltip"
      className={classnames(
        'reception__booking-check-in-preview__signature__preview-tooltip',
        signedDocumentsClassName,
      )}
    >
      <img src={bookingDetails.booking_signature_url} className="bg-white rounded w-100" alt="singature" />
    </UncontrolledTooltip>
    {bookingDocumentsElement}
    {withDocuments && !!bookingDetails.booking_documents.length && (
      <>
        <div className="d-inline-flex cursor-default" id="signed-document">
          <IconWithText icon="uil-file-check-alt font-14" textClass="font-12" text="Podpisane dokumenty" />
        </div>
        <UncontrolledTooltip placement="top" target="signed-document">
          <p className="font-11 text-left mb-1">Podpisane dokumenty:</p>
          <ul className="list-unstyled text-left mb-0">
            {bookingDetails.booking_documents.map((bookingDocument: BookingDocument) => (
              <li className="font-11 text-strong" key={bookingDocument.kind_display}>
                <IconWithText icon="uil-check font-16" text={bookingDocument.kind_display} />
              </li>
            ))}
          </ul>
        </UncontrolledTooltip>
      </>
    )}
  </div>
)
