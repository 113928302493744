import * as React from 'react'
import { RodoAgreement } from '@modules/crm/rodo-agreements/models'
import { RodoAgreementsConfirmedList } from '@modules/crm/rodo-agreements/benefits/list/confirmed/rodo-agreements-confirmed-list'
import { RodoAgreementsToVerifyList } from '@modules/crm/rodo-agreements/benefits/list/to-verify/rodo-agreements-to-verify-list'
import { useFormContext, useWatch } from 'react-hook-form'
import { ClientsRodoFormInputs } from '@modules/crm/rodo-agreements/benefits'

interface Props {
  rodoAgreements: RodoAgreement[]
  isUninitialized: boolean
}

export const RodoAgreementsList = ({ rodoAgreements, isUninitialized }: Props): JSX.Element => {
  const { control } = useFormContext<ClientsRodoFormInputs>()
  const searchValue = useWatch({ control, name: 'search' })

  const { confirmed, toVerify } = rodoAgreements.reduce(
    (previous, client) => {
      if (client.phone_confirm_date && client.email_confirm_date) {
        return { ...previous, confirmed: [...previous.confirmed, client] }
      }
      return { ...previous, toVerify: [...previous.toVerify, client] }
    },
    { confirmed: [], toVerify: [] },
  )

  return (
    <div className="client-rodo__wrapper mt-3">
      {!!confirmed.length && <RodoAgreementsConfirmedList rodoAgreements={confirmed} />}
      {!!toVerify.length && <RodoAgreementsToVerifyList rodoAgreements={toVerify} />}
      {!confirmed.length && !toVerify.length && searchValue && !isUninitialized && (
        <div className="ml-4 text-muted">Brak danych. Zmień kryteria wyszukiwania.</div>
      )}
    </div>
  )
}
