import { AsyncThunkParams, RootState } from '@store/index'
import { commonObjectGet, commonObjectPatch, commonObjectPost, wrapWithError } from '@store/actions/generic-actions'
import { setDashboardStandardPaginator } from '@store/actions/dashboard-actions'
import { PaginationResponse } from '@models/dashboard'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { PromocodeTouristVoucherDetails } from '@modules/promotions/promocode-tourist-voucher/models'
import { SubscriptionContract, SubscriptionContractDetails } from '@modules/subscription-contract/models'
import { SubscriptionContractCreateFormInputs } from '@modules/subscription-contract/create/modal'
import { extractSelectOptionsValues } from '@helpers/utils'
import { SubscriptionContractListFilterParams } from '@modules/subscription-contract/list/filter'

export const getSubscriptionContracts = createAsyncThunk<
  SubscriptionContract[],
  Partial<SubscriptionContractListFilterParams>,
  AsyncThunkParams
>('subscriptionContracts/getSubscriptionContracts', async (filters, { getState, dispatch }) => {
  const url = getState().appState.appData.urls.subscription_contract.subscription_contracts

  const data = await commonObjectGet<PaginationResponse<SubscriptionContract>>(url, {
    ...filters,
    ...extractSelectOptionsValues(filters),
    status: filters.status?.map(row => row.value),
    subscription_id: null,
  })

  dispatch(setDashboardStandardPaginator(data))

  return data.results
})

export const createSubscriptionContract = createAsyncThunk<
  SubscriptionContractDetails,
  SubscriptionContractCreateFormInputs,
  { state: RootState }
>(
  'subscriptionContracts/createSubscriptionContract',
  async (payload, { getState }) =>
    await wrapWithError(
      commonObjectPost<PromocodeTouristVoucherDetails>(
        getState().appState.appData.urls.subscription_contract.subscription_contracts,
        { ...extractSelectOptionsValues(payload), client: payload.client?.id },
      ),
    ),
)

export const updateSubscriptionContract = createAsyncThunk<
  SubscriptionContractDetails,
  [SubscriptionContractDetails, Partial<SubscriptionContractCreateFormInputs>]
>(
  'subscriptionContracts/updateSubscriptionContract',
  async ([subscriptionContractDetails, payload]) =>
    await wrapWithError(
      commonObjectPatch<PromocodeTouristVoucherDetails>(subscriptionContractDetails.urls.details, {
        ...extractSelectOptionsValues(payload),
        client: payload.client?.id,
      }),
    ),
)

export const getSubscriptionContractDetails = createAsyncThunk<
  SubscriptionContractDetails,
  SubscriptionContract,
  AsyncThunkParams
>(
  'subscriptionContracts/getSubscriptionContractDetails',
  async subscriptionContract => await commonObjectGet<SubscriptionContractDetails>(subscriptionContract.urls.details),
)
