import * as React from 'react'
import { ProductProFormInvoiceButton, ProductSalePageButton } from '@components/products/product-action-button'
import { SubscriptionDetails } from '@modules/subscription/models'
import { useModal } from '@components/modals/use-modal'

const ProductTypesWithProFormInvoice = ['DEFAULT', 'WHOLESALE']

interface Props {
  subscriptionDetails: SubscriptionDetails
}

export const SubscriptionDetailsActions = ({ subscriptionDetails }: Props): JSX.Element => {
  const [generateProformaInvoice] = useModal('SubscriptionGenerateProforma', { subscriptionDetails })

  return (
    <div className="mt-2 text-right">
      {subscriptionDetails.status !== 'cancelled' && (
        <span>
          {!subscriptionDetails.is_paid &&
            !subscriptionDetails.read_only &&
            ProductTypesWithProFormInvoice.includes(subscriptionDetails.type) && (
              <ProductProFormInvoiceButton onClick={generateProformaInvoice} className="mr-2" />
            )}
          <ProductSalePageButton saleUrl={subscriptionDetails.urls.sale} />
        </span>
      )}
    </div>
  )
}
