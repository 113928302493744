import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { useModal } from '@components/modals/use-modal'

interface ReceptionBandScanButtonProps {
  label?: string
}

export const ReceptionBandScanButton: React.FC<ReceptionBandScanButtonProps> = ({ label }) => {
  const [toggleScanBandDialogVisibility] = useModal('ReceptionBandScanDialog')

  return (
    <div className="text-right pr-2">
      <div className="text-info font-14 font-weight-bold cursor-pointer" onClick={toggleScanBandDialogVisibility}>
        <IconWithText icon="uil-capture" text={label || 'Skanuj opaskę'} iconSize="font-22" />
      </div>
    </div>
  )
}
