import * as React from 'react'
import { Button, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useForm } from 'react-hook-form'
import { endOfMonth, startOfMonth, startOfToday } from 'date-fns'
import { createReportTask } from '@store/actions/report-tasks-actions'
import ModalBody from 'reactstrap/lib/ModalBody'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { SaveButton } from '@hyper/button'
import { useSellersOptions } from '@components/hooks/use-grouped-sellers'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { BaseModalProps } from '@components/modals/types'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { Form } from '@hyper/forms/form'
import { promocodeTouristVoucherStatusOptions } from '@modules/promotions/promocode-tourist-voucher/consts'
import { FormSelect } from '@hyper/forms/form-select'

interface FormInputs {
  created_date_after: Date
  created_date_before: Date
  seller: CustomReactSelectOption | null
  status: CustomReactSelectOption[] | null
}

export const ReportPromocodeTouristVoucherModal: React.FC<BaseModalProps> = ({ toggleIsVisible }) => {
  const reportUrl = useAppSelector(
    (state: RootState) => state.appState.appData.urls.reports.report_task_promocode_tourist_voucher_report,
  )

  const dispatch = useAppDispatch()

  const methods = useForm<FormInputs>({
    mode: 'all',
    defaultValues: {
      created_date_after: startOfMonth(startOfToday()),
      created_date_before: endOfMonth(startOfToday()),
      seller: null,
      status: null,
    },
  })

  const { isLoading, action: onSubmit } = useReportFormRequest(async (payload: FormInputs) => {
    const reportTask = await dispatch(
      createReportTask([
        reportUrl,
        {
          ...payload,
          seller: payload.seller?.value,
          status: payload.status?.map(row => row.value),
        },
      ]),
    )
    toggleIsVisible()
    return reportTask
  }, methods.setError)

  const sellerOptions = useSellersOptions()

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Raport Vouchera Turystycznego</ModalHeader>
      <ModalBody>
        <Row>
          <DateFromToInput label="Data" startDateName="created_date_after" endDateName="created_date_before" />
          <FormSelect options={sellerOptions} name="seller" label="Sprzedawca" formPlainProps={{ colSize: 6 }} />
          <FormSelect
            options={promocodeTouristVoucherStatusOptions}
            name="status"
            label="Status"
            formPlainProps={{ colSize: 6 }}
            selectProps={{
              isSelectMulti: true,
              isClearable: true,
            }}
          />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" label="Zleć raport" labelSaving="Zlecanie.." isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
