import * as React from 'react'
import { FilterForm } from '@modules/rcp/user/events/filter-form'
import { UserBaseDetail } from '@modules/rcp/user'
import { EventsTable } from '@modules/rcp/user/events/events-table'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { rcpUserDetailSelector } from '@store/slices/rcp-slice'
import { useAppSelector } from '@store/index'

export const RcpUserEventsView: React.FC = () => {
  const user = useAppSelector(rcpUserDetailSelector)
  useMenuActive(NavigationPath.Rcp)

  return (
    <UserBaseDetail>
      {user && (
        <>
          <FilterForm user={user} />
          <EventsTable user={user} />
        </>
      )}
    </UserBaseDetail>
  )
}
