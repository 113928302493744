import * as React from 'react'
import { HrWorkersSearch } from '@modules/hr/workers/worker-search'
import { HrWorkerForm } from '@modules/hr/common/form/worker-data'
import { HrWorkerDetails } from '@modules/hr/workers/models'
import { useFormContext, useWatch } from 'react-hook-form'
import { HrWorkersCreateFormInputs } from '@modules/hr/workers/create/modal'

interface Props {
  workerDetails: HrWorkerDetails | undefined
}

export const HrWorkerCreateMainData = ({ workerDetails }: Props): JSX.Element => {
  const { control, setValue } = useFormContext<HrWorkersCreateFormInputs>()
  const isFormVisible = useWatch({ control, name: 'isFormVisible' })

  const handleIsFormVisibleChange = () => {
    setValue('selectedWorker', null)
    setValue('isFormVisible', true)
  }

  return (
    <div>
      {!workerDetails && <HrWorkersSearch onAddNewWorker={handleIsFormVisibleChange} />}
      {isFormVisible && (
        <div className="mt-3">
          <HrWorkerForm />
        </div>
      )}
    </div>
  )
}
