import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { PackageWholesaleFormInputs } from '@modules/package-wholesale/create/modal'
import { Row } from 'reactstrap'
import { PackageSummarySection, PackageSummarySectionDataRow } from '@modules/package/create/package-summary-section'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { IconWithText } from '@components/icon-with-text'
import { packageWholesaleProductsSelector } from '@store/slices/package-wholesale-slice'
import { PackageWholesaleProduct } from '@modules/package-wholesale/models'
import { ProductSaleSummaryInvoice } from '@components/sale/product-sale-summary-invoice'
import { usePackageWholesaleProductData } from '@modules/package-wholesale/use-product-data'
import { useAppSelector } from '@store/index'

export const PackageWholesaleCreateModalFormSummary = (): JSX.Element => {
  const packageWholesaleProducts = useAppSelector(packageWholesaleProductsSelector)
  const methods = useFormContext<PackageWholesaleFormInputs>()

  const data = methods.getValues()

  const products = packageWholesaleProducts.filter(product => {
    if (!Array.isArray(data.products)) return false
    return data.products.some(productId => product.id === parseInt(String(productId), 10))
  })

  const { getSubscriptionLeafletData, subscriptionKinds } = usePackageWholesaleProductData(data.products, products)

  return (
    <div className="pb-2">
      <h4 className="mt-0 text-secondary">Podsumowanie sprzedaży</h4>
      <Row>
        <PackageSummarySection title="Dane klienta" className="col-6">
          <PackageSummarySectionDataRow title="Imię i nazwisko:" content={data.name} />
          <PackageSummarySectionDataRow title="Adres e-mail:" content={data.email} />
          <PackageSummarySectionDataRow title="Numer telefonu:" content={data.phone} />
        </PackageSummarySection>
        {data.invoice_nip && <ProductSaleSummaryInvoice />}
      </Row>
      <div className="dropdown-divider" />
      <Row>
        <PackageSummarySection title="Produkty w pakiecie" className="col-12">
          <div className="d-flex flex-column">
            {!products.length && <span className="opacity-75">Brak dodanych produktów</span>}
            {products.map((product: PackageWholesaleProduct) => (
              <IconWithText
                key={product.id}
                icon="font-16 uil-check text-secondary"
                text={product.name}
                textClass="opacity-75"
                wrapperClassNames="d-block mb-1"
              />
            ))}
          </div>
          <PackageSummarySectionDataRow
            title="Data ważności:"
            content={toDefaultDateFormat(data.expire_after)}
            className="mt-2"
          />
          <PackageSummarySectionDataRow title="Przyjazny link:" content={data.slug} />

          {subscriptionKinds.map(kind => {
            const { inputName, label } = getSubscriptionLeafletData(kind)

            if (!data[inputName]) return null

            return (
              <PackageSummarySectionDataRow
                key={inputName}
                title={`${label}:`}
                content={data[inputName].name}
                className="mt-1"
              />
            )
          })}

          {!!data.leaflet_gastro_card && (
            <PackageSummarySectionDataRow
              title="Ulotka podwójnej radości:"
              content={data.leaflet_gastro_card.name}
              className="mt-1"
            />
          )}
          {!!data.leaflet_gastro_card_boost && (
            <PackageSummarySectionDataRow
              title="Ulotka Doładowania Smaku:"
              content={data.leaflet_gastro_card_boost.name}
              className="mt-1"
            />
          )}
          {!!data.leaflet_gastro_card_2024 && (
            <PackageSummarySectionDataRow
              title="Ulotka podwójnej radości 2024:"
              content={data.leaflet_gastro_card_2024.name}
              className="mt-1"
            />
          )}
          {!!data.leaflet_gastro_card_2025 && (
            <PackageSummarySectionDataRow
              title="Ulotka podwójnej radości 2025:"
              content={data.leaflet_gastro_card_2025.name}
              className="mt-1"
            />
          )}
        </PackageSummarySection>
      </Row>
      <div className="dropdown-divider" />
      <Row>
        <PackageSummarySection title="Informacje handlowe" className="col-6">
          <PackageSummarySectionDataRow
            title="Sprzedawca:"
            content={data.sellers?.map(row => row.label).join(',') ?? 'Brak'}
          />
        </PackageSummarySection>
      </Row>
    </div>
  )
}
