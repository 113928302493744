import * as React from 'react'
import { FormSelect } from '@hyper/forms/form-select'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useHousekeepingAppData } from '@modules/housekeeping/hooks/use-housekeeping-app-data'
import { createSelectOption } from '@helpers/utils'
import { useHousekeepingTechnicalCompanies } from '@modules/technical-orders/hooks/use-housekeeping-technical-companies'

interface SelectOption extends CustomReactSelectOption {
  type: 'user' | 'company'
}

interface Props {
  setFilters: any
  resortId: number
}

export const TechnicalOrderTableUsersHeader: React.FC<Props> = ({ resortId, setFilters }) => {
  const { housekeeping_users } = useHousekeepingAppData(resortId)
  const { companies: housekeeping_companies } = useHousekeepingTechnicalCompanies(resortId)

  const users = React.useMemo(
    () => housekeeping_users.filter(row => row.is_housekeeping_handyman),
    [housekeeping_users],
  )

  const serviceUsers = React.useMemo(
    () =>
      housekeeping_companies.reduce((acc: CustomReactSelectOption[], company) => {
        acc.push(...company.workers.map(row => createSelectOption(row.name, row.id)))
        return acc
      }, []),

    [housekeeping_companies],
  )

  const options = React.useMemo(
    () => [
      {
        label: 'Pracownicy',
        options: users.map(row => createSelectOption(row.name, row.id)),
      },
      {
        label: 'Serwisanci',
        options: serviceUsers,
      },
    ],
    [users],
  )

  const handleChange = (option: SelectOption) => {
    setFilters(state => ({ ...state, users: option?.value, company: undefined }))
  }

  return (
    <FormSelect
      options={options}
      name="contractor"
      selectProps={{ isClearable: true, placeholder: 'Wykonawca', onChange: handleChange }}
      formPlainProps={{ colClassName: 'p-0 m-0', formGroupClassName: 'p-0 m-0 text-normal', colStyle: { width: 140 } }}
    />
  )
}
