import * as React from 'react'
import { PromocodePrefix } from '@models/promocode'
import Table from '@components/table/table'
import { PromocodePrefixEditIcon } from '@modules/promocode/promocode-prefixes/promocode-prefix-edit-icon'
import { PromocodePrefixDeleteIcon } from '@modules/promocode/promocode-prefixes/promocode-prefix-delete-icon'
import { Tooltip } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'

interface Props {
  prefix: PromocodePrefix
}

export const PromocodePrefixesTableRow: React.FC<Props> = ({ prefix }) => {
  const [handleClick] = useModal('PromocodePrefixDetailsDialog', {
    promocodePrefix: prefix,
  })

  return (
    <Table.Row>
      <Table.Cell className="text-nowrap text-right" style={{ width: 90 }} onClick={handleClick}>
        <PromocodeValueWithDates prefix={prefix} />
      </Table.Cell>
      <Table.Cell className="cursor-pointer" onClick={handleClick}>
        {prefix.name}
      </Table.Cell>
      <Table.Cell className="cursor-pointer" onClick={handleClick}>
        {prefix.get_kind_display}
      </Table.Cell>
      <Table.Cell className="cursor-pointer" onClick={handleClick}>
        {prefix.promo_value}
      </Table.Cell>
      <Table.Cell className="cursor-pointer" onClick={handleClick}>
        {prefix.codes_amount - prefix.codes_amount_used} z {prefix.codes_amount}
      </Table.Cell>
      <Table.Cell className="text-nowrap text-right" style={{ width: 150 }}>
        <PromocodePrefixEditIcon promocodePrefix={prefix} />
        <PromocodePrefixDeleteIcon promocodePrefix={prefix} />
      </Table.Cell>
    </Table.Row>
  )
}

const PromocodeValueWithDates: React.FC<{ prefix: PromocodePrefix }> = ({ prefix }) => {
  const [tooltipOpen, setTooltipOpen] = React.useState(false)
  const toggle = () => setTooltipOpen(!tooltipOpen)

  const target = `prefixTooltip${prefix.id}`

  return (
    <>
      <span className="text-strong" id={target}>
        {prefix.value}
      </span>
      <Tooltip
        placement="right"
        isOpen={tooltipOpen}
        target={target}
        toggle={toggle}
        style={{ whiteSpace: 'pre-line', fontSize: 12 }}
      >
        Daty obowiązywania
        {'\n'}
        {'\n'}
        {prefix.dates.map(date => `${date.date_from} - ${date.date_to}`).join('\n')}
      </Tooltip>
    </>
  )
}
