import * as React from 'react'
import Table from '@components/table/table'
import { toDefaultDateFormat, toDefaultDateTimeFormat } from '@helpers/date-helper'
import { CleaningOrder } from '@modules/housekeeping/models'
import { HousekeepingCleaningOrderOrderedByRow } from '@modules/housekeeping/cleaning-orders/common/ordered-by'
import { HousekeepingOrderWorkersRow } from '@modules/housekeeping/common/workers'
import { getCleaningOrderStatusColor } from '@modules/housekeeping/helpers'
import classNames from 'classnames'

interface Props {
  cleaningOrder: CleaningOrder
}

export const UnitDetailsCleaningOrdersTableRow: React.FC<Props> = ({ cleaningOrder }) => (
  <Table.Row>
    <Table.Cell className="vertical-align-middle">{toDefaultDateFormat(cleaningOrder.date)}</Table.Cell>
    <Table.Cell className="vertical-align-middle">
      <span className={classNames('badge', `badge-${getCleaningOrderStatusColor(cleaningOrder.status)}`)}>
        {cleaningOrder.status_display}
      </span>
    </Table.Cell>
    <Table.Cell className="vertical-align-middle">{cleaningOrder.service_kind_display}</Table.Cell>
    <HousekeepingCleaningOrderOrderedByRow ordered_by={cleaningOrder.order_created_by} />
    <HousekeepingOrderWorkersRow order={cleaningOrder} />
    <Table.Cell className="vertical-align-middle">
      {cleaningOrder.finished_at && toDefaultDateTimeFormat(cleaningOrder.finished_at)}
    </Table.Cell>
  </Table.Row>
)
