import * as React from 'react'
import { asDecimal, formatPrice, getById } from '@helpers/utils'
import { useAnimatedPriceChange } from '@components/hooks/use-animated-price-change'
import { ReceptionBookingDetailsPrices } from '@models/reception'
import { useAppData } from '@components/hooks/use-app-data'

interface Props {
  bookingPrices: ReceptionBookingDetailsPrices
  numberOfNights: number
  accommodationTypeId: number
}

export const ReservationSidebarSummaryBoxTotalPrices = ({
  bookingPrices,
  accommodationTypeId,
  numberOfNights,
}: Props): React.ReactNode => {
  const { accommodation_types } = useAppData()
  const selectedAccommodation = getById(accommodation_types, accommodationTypeId)

  const totalPrice = useAnimatedPriceChange(bookingPrices.stay_charge)
  const pricePerPersonPerNight = useAnimatedPriceChange(
    bookingPrices
      ? asDecimal(bookingPrices.stay_charge_without_climatic)
          .div(asDecimal(selectedAccommodation?.display_max_guests ?? 1))
          .div(numberOfNights)
          .toString()
      : 0,
  )

  return (
    <div className="reservation-create__summary-box__prices p-2 mt-2">
      <div className="row text-white mb-1">
        <div className="col-8 fw-semi-bold font-13">Cena za osobę za noc:</div>
        <div className="col-4 font-14 font-weight-bold">{formatPrice(pricePerPersonPerNight)}</div>
      </div>
      <div className="row reservation-create__summary-box__prices__total">
        <div className="col-8 fw-semi-bold font-12">Łącznie do zapłaty:</div>
        <div className="col-4 font-weight-bold font-13">{formatPrice(totalPrice)}</div>
      </div>
    </div>
  )
}
