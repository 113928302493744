import * as React from 'react'
import { PromocodeGroup } from '@models/promocode'
import { Button } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { createReportTask } from '@store/actions/report-tasks-actions'

interface Props {
  promocodeGroup: PromocodeGroup
}

interface FormInputs {
  group: number
}

export const PromocodeGroupEditDialogExportButton = ({ promocodeGroup }: Props): JSX.Element => {
  const reportUrl = useAppSelector(
    (state: RootState) => state.appState.appData.urls.reports.report_promocode_group_codes,
  )
  const dispatch = useAppDispatch()

  const methods = useForm<FormInputs>({
    mode: 'all',
    defaultValues: {
      group: promocodeGroup.id,
    },
  })

  const { isLoading, action: handleExport } = useReportFormRequest(
    async () => await dispatch(createReportTask([reportUrl, methods.getValues()])),
    methods.setError,
  )

  return (
    <Button color="light" type="button" onClick={handleExport} className="mr-1">
      {isLoading ? 'Eksportowanie...' : 'Eksportuj'}
    </Button>
  )
}
