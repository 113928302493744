import * as React from 'react'
import { ReceptionBookingGuest } from '@models/booking'
import { ReceptionBookingDetails } from '@models/reception'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { useApiRequest } from '@components/hooks/use-api-request'

interface StepGuestsGuestListRowRemoveProps {
  guest: ReceptionBookingGuest
  children: React.ReactNode
  handleComplete?: () => void
  updateState: (booking: ReceptionBookingDetails) => void
  disabled?: boolean
}

export const StepGuestsGuestListRowRemove: React.FC<StepGuestsGuestListRowRemoveProps> = ({
  guest,
  children,
  updateState,
  handleComplete,
  disabled,
}) => {
  const { addSuccessNotification } = useNotificationHook()

  const { isLoading, action: handleAccept } = useApiRequest(async () => {
    updateState(await commonObjectDelete<ReceptionBookingDetails>(guest.urls.details))
    addSuccessNotification(`Gość ${guest.name} został skasowany!`)
    if (handleComplete) {
      handleComplete()
    }
  })

  return (
    <CommonObjectConfirmAction
      title={`Potwierdź skasowanie gościa ${guest.name}`}
      askForReason={false}
      isLoading={isLoading}
      handleAccept={handleAccept}
      message={`Czy na pewno chcesz skasować gościa ${guest.name} ?`}
      disabled={disabled}
    >
      <span className="cursor-pointer">{children}</span>
    </CommonObjectConfirmAction>
  )
}
