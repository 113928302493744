import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@store/index'
import { FloorHeatingAppData } from '@modules/floor-heating/models'

interface FloorHeatingState {
  appData: FloorHeatingAppData | null
}

const initialState: FloorHeatingState = {
  appData: null,
}

export const floorHeatingSlice = createSlice({
  name: 'floorHeating',
  initialState,
  reducers: {
    setFloorHeatingAppData(state, action: PayloadAction<FloorHeatingAppData>) {
      state.appData = action.payload
    },
  },
})

export const { setFloorHeatingAppData } = floorHeatingSlice.actions

export const selectFloorHeatingAppData = (state: RootState) => state.floorHeatingState.appData

export default floorHeatingSlice.reducer
