import * as React from 'react'
import { RootState, useAppSelector } from '@store/index'
import { BaseModalProps } from '@components/modals/types'
import { ReportNotConfigurable } from '@modules/reports/user-reports/report-not-configurable'

export const ReportsReservationLatePaymentModal: React.FC<BaseModalProps> = ({ toggleIsVisible }) => {
  const reportUrl = useAppSelector(
    (state: RootState) => state.appState.appData.urls.reports.report_task_reservation_late_payment_report,
  )

  return (
    <ReportNotConfigurable
      title="Rezerwacje - Raport spóźnionych wpłat"
      url={reportUrl}
      toggleIsVisible={toggleIsVisible}
    />
  )
}
