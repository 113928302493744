import { AsyncThunkParams } from '@store/index'
import { setDashboardStandardPaginator } from '@store/actions/dashboard-actions'
import { RefundBookingsFiltersParams } from '@modules/accounting/refund/bookings'
import { BookingRefund, TransferPackage } from '@modules/accounting/refund/models'
import { fetchBookingRefunds, fetchTransferPackages } from '@api/accounting'
import { TransferPackagesFiltersParams } from '@modules/accounting/refund/transfer-packages'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getBookingRefunds = createAsyncThunk<BookingRefund[], RefundBookingsFiltersParams, AsyncThunkParams>(
  'accounting/getBookingRefunds',
  async (params, { getState, dispatch }) => {
    const url = getState().appState.appData.urls.accounting.booking_refunds

    const data = await fetchBookingRefunds(url, params)
    dispatch(setDashboardStandardPaginator(data))

    return data.results
  },
)

export const getTransferPackages = createAsyncThunk<TransferPackage[], TransferPackagesFiltersParams, AsyncThunkParams>(
  'accounting/getTransferPackages',
  async (params, { getState, dispatch }) => {
    const url = getState().appState.appData.urls.accounting.transfer_packages

    const data = await fetchTransferPackages(url, { ...params, kind: params.kind.map(row => row.value) })
    dispatch(setDashboardStandardPaginator(data))

    return data.results
  },
)
