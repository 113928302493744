import * as React from 'react'
import { BookingRefund } from '@modules/accounting/refund/models'
import { RefundBookingsTableCellActionsReturn } from '@modules/accounting/refund/bookings/table-cell-actions-return'
import { RefundBookingsTableCellActionsNotification } from '@modules/accounting/refund/bookings/table-cell-actions-notification'
import { RefundBookingsTableCellActionsReturnP24 } from '@modules/accounting/refund/bookings/table-cell-actions-return-p24'
import { RefundBookingsTableCellActionsBlockP24 } from '@modules/accounting/refund/bookings/table-cell-actions-block-p24'
import { RefundBookingsTableCellActionsMoneyTransferP24 } from '@modules/accounting/refund/bookings/table-cell-actions-money-transfer-p24'

interface Props {
  booking: BookingRefund
}

export const RefundBookingsTableCellActions: React.FC<Props> = ({ booking }) => (
  <>
    <RefundBookingsTableCellActionsNotification booking={booking} />
    {booking.return_type === 'p24' ? (
      <>
        <RefundBookingsTableCellActionsMoneyTransferP24 booking={booking} />
        <RefundBookingsTableCellActionsBlockP24 booking={booking} />
        <RefundBookingsTableCellActionsReturnP24 booking={booking} />
      </>
    ) : (
      <RefundBookingsTableCellActionsReturn booking={booking} />
    )}
  </>
)
