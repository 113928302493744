import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { FormProvider, useForm } from 'react-hook-form'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { receptionBookingPaymentsAdd, ReceptionBookingPaymentsAddPayload } from '@store/actions/reception-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useFormRequest } from '@components/hooks/use-api-request'
import { commonObjectGet } from '@store/actions/generic-actions'
import { CardPaymentBox } from '@components/card-payment-box'
import { setReceptionBookingDetails } from '@store/slices/reception-slice'

interface Props {
  booking: ReceptionBookingDetails
  handleCancel: () => void
}

interface FormInputs {
  amount: string
}

export const ReceptionBookingCheckInStepPaymentsBoxPreauthorized: React.FC<Props> = ({ booking, handleCancel }) => {
  const methods = useForm<FormInputs>({
    mode: 'all',
    defaultValues: {
      amount: booking.required_deposit_amount,
    },
  })
  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()
  const onEserviceSuccess = async () =>
    dispatch(setReceptionBookingDetails(await commonObjectGet<ReceptionBookingDetails>(booking.urls.api_details)))

  const { isLoading, action: handleSubmit } = useFormRequest(async () => {
    const payload = methods.getValues()
    await dispatch(
      receptionBookingPaymentsAdd(booking.urls.payments, {
        ...payload,
        source: 'creditcard',
        type: 'deposit',
      }),
    )
    addSuccessNotification('Wpłata została dodana!')
  }, methods.setError)

  const context: Partial<ReceptionBookingPaymentsAddPayload> = {
    source: 'creditcard',
    type: 'deposit',
  }

  const content_type = useAppSelector((state: RootState) => state.appState.appData.content_types.booking)
  return (
    <FormProvider {...methods}>
      <CardPaymentBox
        content_type={content_type}
        object_id={booking.id}
        kind="booking_advance_with_climatic"
        restToPay={booking.required_deposit_amount}
        onSubmit={handleSubmit}
        context={context}
        onEserviceSuccess={onEserviceSuccess}
        onCancel={handleCancel}
        isSaving={isLoading}
      />
    </FormProvider>
  )
}
