import * as React from 'react'
import { UpSellUser } from '@models/upselling'
import Table from '@components/table/table'
import { useModal } from '@components/modals/use-modal'

interface Props {
  upSellUser: UpSellUser
}

export const UpSellUsersTableRow: React.FC<Props> = ({ upSellUser }) => {
  const [handleClick] = useModal('UpSellUserModal', { upSellUser })

  return (
    <Table.Row>
      <Table.Cell>{upSellUser.first_name}</Table.Cell>
      <Table.Cell>{upSellUser.last_name}</Table.Cell>
      <Table.Cell className="text-right">
        <button className="action-icon btn-pure" title="Edytuj" onClick={handleClick}>
          <i className="uil-plus text-muted font-18" />
        </button>
      </Table.Cell>
    </Table.Row>
  )
}
