import * as React from 'react'
import { BeddingOrder, BeddingOrderDetails, HousekeepingBeddingOrderType } from '@modules/housekeeping/models'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap'
import { getHousekeepingBeddingOrders } from '@store/actions/housekeeping-actions'
import { useAppDispatch } from '@store/index'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useModal } from '@components/modals/use-modal'
import { useApiRequest } from '@components/hooks/use-api-request'
import { updateHousekeepingBeddingOrderDetails } from '@store/slices/housekeeping-slice'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { IconWithText } from '@components/icon-with-text'

interface HousekeepingBeddingOrdersRowOptionsProps {
  selectedOrders: BeddingOrder[]
  resortId: number
}

export const HousekeepingBeddingOrdersRowOptions: React.FC<HousekeepingBeddingOrdersRowOptionsProps> = ({
  selectedOrders,
  resortId,
}) => {
  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()

  const { action: refreshOrders } = useApiRequest(
    async () =>
      await dispatch(
        getHousekeepingBeddingOrders([
          {
            resort: resortId,
          },
          HousekeepingBeddingOrderType.PRESENT,
        ]),
      ),
  )

  const [handleAssign] = useModal('HousekeepingCleaningOrderGroupAssignModal', {
    refreshOrders,
    selectedOrders: selectedOrders,
    onlyHprCompany: true,
    resortId,
    canSelectMultipleWorkers: false,
    workerRoles: ['bedding'],
    serviceKind: 'improvement',
  })

  const isDisabled = selectedOrders.length === 0

  const { isLoading, action: handleCloseAccept } = useApiRequest(async () => {
    for (const order of selectedOrders) {
      await dispatch(
        updateHousekeepingBeddingOrderDetails(await commonObjectPost<BeddingOrderDetails>(order.urls.finish_working)),
      )
    }
    addSuccessNotification('Zlecenia zostały zamknięte!')
  })

  return (
    <div className="mb-1">
      <UncontrolledButtonDropdown disabled={isDisabled}>
        <DropdownToggle color="light" caret={true} disabled={isDisabled}>
          <strong>zaznaczone ({selectedOrders.length})</strong>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={handleAssign}>
            <IconWithText icon="uil-exchange" text="Zmień osoby" />
          </DropdownItem>
          <CommonObjectConfirmAction
            isLoading={isLoading}
            handleAccept={handleCloseAccept}
            message={`Czy na pewno chcesz zamknać wybrane zlecenia ? Wybrano ${selectedOrders.length} zleceń`}
          >
            <DropdownItem>
              <i className="uil  uil-multiply mr-1 text-bold text-danger" />
              Zamknij
            </DropdownItem>
          </CommonObjectConfirmAction>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </div>
  )
}
