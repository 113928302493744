import * as React from 'react'

const TimelineItemTooltipBlockadeDetailsPlaceholder: React.FC = () => (
  <div>
    <div className="calendar-tooltip__upper">
      <header className="calendar-tooltip__el is-placeholder" />
      <div className="calendar-tooltip__el is-placeholder" />
      <div className="calendar-tooltip__el is-placeholder" />
    </div>
    <div className="calendar-tooltip__lower">
      <div className="calendar-tooltip__el is-placeholder" />
    </div>
  </div>
)

export default TimelineItemTooltipBlockadeDetailsPlaceholder
