import * as React from 'react'
import Table, { TableHeaderValue } from '@components/table/table'
import { RentalProduct, RentalProductFiltersParams } from '@modules/rental/models'
import { RentalProductsTableRow } from '@modules/rental/products/rental-products-table-row'

interface Props {
  products: RentalProduct[]
  setFilters: (filters: RentalProductFiltersParams) => void
  filters: RentalProductFiltersParams
  isLoading: boolean
}

const headerValues: TableHeaderValue[] = [
  { title: 'Lp.' },
  { sortField: 'name', title: 'Nazwa' },
  { title: 'Kod produktu' },
  { title: 'Status' },
  { title: 'Kategoria' },
  { title: 'Magazyn' },
  { title: 'Rezerwacja' },
  { title: '' },
]

export const RentalProductsTable: React.FC<Props> = ({ setFilters, products, filters, isLoading }) => (
  <Table
    className="table-hover w-100"
    headerValues={headerValues}
    isLoading={isLoading}
    filters={filters}
    setFilters={setFilters}
  >
    {products.map((product: RentalProduct, index: number) => (
      <RentalProductsTableRow key={product.id} product={product} index={index} />
    ))}
  </Table>
)
