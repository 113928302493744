import * as React from 'react'
import { UnitDetails } from '@models/units'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { TableFilters } from '@components/table/table'
import { UnitDetailsCleaningOrdersTable } from '@modules/units/details/cleaning-orders/table'
import { UnitDetailsCleaningOrdersFilters } from '@modules/units/details/cleaning-orders/filters'
import { useGetCleaningOrdersQuery } from '@api/cleaning-orders'
import { TypedQueryResult } from '@api/base'
import { CleaningOrder } from '@modules/housekeeping/models'
import { useModal } from '@components/modals/use-modal'
import { ButtonWithIcon } from '@components/button-with-icon'

interface Props {
  unit: UnitDetails
}
const defaultFilters: TableFilters = {
  search: '',
  ordering: '-date',
  page: 1,
  page_size: 19,
}

const defaultEmptyList = []

export const UnitDetailsCleaningOrders: React.FC<Props> = ({ unit }) => {
  const [filters, setFilters] = React.useState<TableFilters>(defaultFilters)

  const { data: cleaningOrders = defaultEmptyList, refetch } = useGetCleaningOrdersQuery({
    ...filters,
    apartment: unit.apartment?.id,
  }) as TypedQueryResult<CleaningOrder[]>

  const [handleClick] = useModal('CreateCleaningOrderModal', {
    resortId: unit.resort_id,
    apartmentId: unit.apartment?.id,
    onComplete: refetch,
    isReadonly: true,
  })

  return (
    <Card>
      <CardBody>
        <Row>
          <Col md={6}>
            <h4 className="text-secondary">Sprzątanie</h4>
          </Col>
          <Col md={6} className="text-right">
            <div className="ml-auto mb-1">
              <ButtonWithIcon handleClick={handleClick} icon="uil-plus" text="Zgłoś nowe sprzątanie" color="green" />
            </div>
          </Col>
        </Row>
        <UnitDetailsCleaningOrdersFilters defaultFilters={defaultFilters} filters={filters} setFilters={setFilters} />
        <UnitDetailsCleaningOrdersTable cleaningOrders={cleaningOrders} filters={filters} setFilters={setFilters} />
      </CardBody>
    </Card>
  )
}
