import * as React from 'react'
import { BeddingOrder } from '@modules/housekeeping/models'
import { CustomInput } from 'reactstrap'
import { HousekeepingOrderWorkersRow } from '@modules/housekeeping/common/workers'
import { HousekeepingBeddingOrdersRowActions } from '@modules/housekeeping/cleaning-orders/bedding/actions'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { IconWithText } from '@components/icon-with-text'
import { HousekeepingBeddingOrdersCleaningStatusCell } from '@modules/housekeeping/cleaning-orders/bedding/cleaning-status-cell'

interface HousekeepingBeddingOrdersRowProps {
  beddingOrder: BeddingOrder
  isChecked: boolean
  toggleSelectedOrder: (order: BeddingOrder, add: boolean) => void
}

export const HousekeepingBeddingOrdersRow: React.FC<HousekeepingBeddingOrdersRowProps> = ({
  beddingOrder,
  isChecked,
  toggleSelectedOrder,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    toggleSelectedOrder(beddingOrder, event.target.checked)

  return (
    <tr className="housekeeping__orders_table__row">
      <td className="housekeeping__orders_table__column housekeeping__orders_table__column__first">
        <CustomInput
          checked={isChecked}
          onChange={handleChange}
          type="checkbox"
          id={`bedding-order-row-${beddingOrder.id}`}
          value={beddingOrder.id}
        />
      </td>
      <td className="housekeeping__orders_table__column text-semi-strong">
        {beddingOrder.apartment.name}
        {!!beddingOrder.checked_in_booking && (
          <IconWithText
            icon="uil-chat-bubble-user"
            text="Goście w środku"
            wrapperClassNames="d-block text-dark mt-1 w-100"
          />
        )}
      </td>

      <HousekeepingOrderWorkersRow order={beddingOrder.cleaning_order} />
      <HousekeepingBeddingOrdersCleaningStatusCell cleaningOrder={beddingOrder.cleaning_order} />
      <HousekeepingOrderWorkersRow order={beddingOrder} />

      <td className="housekeeping__orders_table__column">
        {beddingOrder.status_display}{' '}
        {beddingOrder.status === 'FINISHED' && <small>({toDefaultDateTimeFormat(beddingOrder.finished_at)})</small>}
      </td>

      <td className="housekeeping__orders_table__column text-nowrap text-right">
        <HousekeepingBeddingOrdersRowActions beddingOrder={beddingOrder} />
      </td>
    </tr>
  )
}
