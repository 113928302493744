import * as React from 'react'

import { Nav, NavItem, NavLink } from 'reactstrap'
import classNames from 'classnames'

interface TabProps<T> {
  tabs: Tab<T>[]
  className?: string
}

interface Tab<T> {
  id: T
  name: string
}

interface Response<T> {
  Tabs: ({ tabs }: TabProps<T>) => JSX.Element
  isTabActive: (tabId: T) => boolean
  activeTab: T
}

export const useTabs = <T extends string>(initialTab: T): Response<T> => {
  const [activeTab, setActiveTab] = React.useState<T>(initialTab)

  const isTabActive = (tabId: T) => tabId === activeTab

  const handleTabClick = (tab: Tab<T>) => () => {
    setActiveTab(tab.id)
  }

  const Tabs = ({ tabs, className }: TabProps<T>) => (
    <Nav className={classNames('nav-bordered mb-2', className)} tabs={true}>
      {tabs.map(tab => (
        <NavItem key={tab.id}>
          <NavLink active={isTabActive(tab.id)} onClick={handleTabClick(tab)} className="cursor-pointer">
            {tab.name}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  )

  return {
    Tabs,
    isTabActive,
    activeTab,
  }
}
