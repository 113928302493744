import * as React from 'react'
import { totalSizeSelector } from '@store/selectors/dashboard'
import { TableFilters } from '@components/table/table'
import { ColAuto } from '@hyper/col-auto'
import { Input } from 'reactstrap'
import ReactPaginate from 'react-paginate'
import { useAppSelector } from '@store/index'

interface Props {
  filters: TableFilters
  onSetFilters: (filters) => void
}

export const TablePagination = ({ filters, onSetFilters }: Props) => {
  const totalSize = useAppSelector(totalSizeSelector)

  const from = totalSize === 0 ? 0 : filters.page === 1 ? 1 : filters.page_size * (filters.page - 1) + 1
  const to = Math.min(filters.page_size * filters.page, totalSize)

  const handlePerPageChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    onSetFilters({ ...filters, page_size: parseInt(event.target.value, 10), page: 1 })

  const handlePageChange = data => onSetFilters({ ...filters, page: data.selected + 1 })

  const pageCount = Math.ceil(totalSize / filters.page_size)

  if (!pageCount) return null

  return (
    <div className="d-flex align-items-center">
      <ColAuto>
        <Input onChange={handlePerPageChange} type="select" className="form-control-sm" value={filters.page_size}>
          {[10, 25, 50].map((page: number) => (
            <option key={page} value={page}>
              {page}
            </option>
          ))}
        </Input>
      </ColAuto>
      <span>
        Wpisów {from} do {to} z {totalSize}
      </span>
      <ColAuto className="ml-auto">
        <ReactPaginate
          containerClassName="pagination"
          breakClassName="page-item"
          breakLabel={<span className="page-link">...</span>}
          pageClassName="page-item"
          previousClassName="page-item"
          nextClassName="page-item"
          nextLabel="dalej"
          previousLabel="wstecz"
          pageLinkClassName="page-link"
          previousLinkClassName="page-link"
          nextLinkClassName="page-link"
          activeClassName="active"
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
        />
      </ColAuto>
    </div>
  )
}
