import * as React from 'react'
import { Col, CustomInput, Row } from 'reactstrap'
import { extractInnerRef } from '@helpers/forms'
import { useFormContext, useWatch } from 'react-hook-form'
import { Subscription } from '@modules/subscription/models'
import { MassNotificationsModalFormInputs } from '@modules/package-wholesale/details/notification/mass-notifications/mass-notifications-modal'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'

interface Props {
  subscriptions: Subscription[]
}

export const PackageWholesaleDetailsMassNotificationsClients = ({ subscriptions }: Props): JSX.Element => {
  const { control, setValue, register, formState } = useFormContext<MassNotificationsModalFormInputs>()

  const selectedSubscriptions = useWatch({ control, name: 'subscriptions' })
  const filters = useWatch({ control, name: 'filters' })

  const filteredSubscriptions = React.useMemo(
    () =>
      filters?.status && filters.status.value !== 'all'
        ? subscriptions.filter(subscription => subscription.status === filters.status.value)
        : subscriptions,
    [filters, subscriptions],
  )

  const allSelected = selectedSubscriptions.length === filteredSubscriptions.length

  const handleChange = () => {
    setValue(
      'subscriptions',
      allSelected ? [] : filteredSubscriptions.map((subscription: Subscription) => subscription.id.toString()),
    )
  }

  useDidUpdateEffect(() => {
    const newSelectedSubscriptions = selectedSubscriptions.reduce(
      (allSubscriptions, subscriptionId) =>
        filteredSubscriptions.some(sub => sub.id === parseInt(subscriptionId, 10))
          ? [...allSubscriptions, subscriptionId]
          : allSubscriptions,
      [],
    )

    setValue('subscriptions', newSelectedSubscriptions)
  }, [filters?.status])

  const sortedSubscriptions = React.useMemo(
    () => [...filteredSubscriptions].sort((a, b) => a.name.localeCompare(b.name)),
    [filteredSubscriptions],
  )

  return (
    <Row className="px-2">
      {filteredSubscriptions.length ? (
        <Col md={12} className="mb-2 text-semi-strong d-flex">
          <CustomInput
            onChange={handleChange}
            checked={allSelected}
            className="mr-3"
            type="checkbox"
            id="all"
            label={<span className="text-semi-strong">Wszyscy</span>}
          />
        </Col>
      ) : (
        <span className="text-semi-strong px-2 mb-2">Brak klientów</span>
      )}
      {sortedSubscriptions.map((subscription: Subscription) => (
        <Col md={6} key={subscription.id}>
          <div className="pl-2 mb-2">
            <CustomInput
              type="checkbox"
              id={subscription.id}
              label={
                <span className="text-semi-strong cursor-pointer">{`${subscription.name} (${subscription.number})`}</span>
              }
              value={subscription.id}
              {...extractInnerRef(register('subscriptions'))}
            />
          </div>
        </Col>
      ))}
      {formState.errors.subscriptions && (
        <div className="invalid-feedback d-block text-center">{formState.errors.subscriptions as any}</div>
      )}
    </Row>
  )
}
