import * as React from 'react'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApiRequest } from '@components/hooks/use-api-request'
import { removeBookingVoucher } from '@store/slices/reservations-slice'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { ReservationBookingVoucher } from '@models/reception'

interface Props {
  voucher: ReservationBookingVoucher
}
export const ReservationBistroVoucherRowActions = ({ voucher }: Props): JSX.Element => {
  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()

  const { isLoading, action: handleAccept } = useApiRequest(async () => {
    await commonObjectDelete(voucher.urls.details)
    dispatch(removeBookingVoucher(voucher.id))
    addSuccessNotification('Voucher został skasowany')
  })

  return (
    <CommonObjectConfirmAction isLoading={isLoading} handleAccept={handleAccept}>
      <i className="uil-trash-alt cursor-pointer" />
    </CommonObjectConfirmAction>
  )
}
