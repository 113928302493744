import * as React from 'react'
import { ReservationCard } from '@modules/reservations/details/common/reservation-card'
import { ReceptionBookingCheckBillDetails } from '@modules/reception/common/reception-booking-check-bill-details'
import { ReceptionBookingSettlementDeposit } from '@modules/reception/checkout/step-settlement/step-settlement-deposit'
import { ReceptionBookingSettlementSummary } from '@modules/reception/checkout/step-settlement/step-settlement-summary'
import { ReceptionBookingDetails } from '@models/reception'
import Decimal from 'decimal.js'
import { AlertDanger } from '@components/alerts'

interface Props {
  receptionBookingDetails: ReceptionBookingDetails
  restToPay: Decimal
  restToPayWithoutClimatic: Decimal
  depositToReturn: Decimal
  totalDeposit: Decimal
  isOnlyClimaticTaxLeft: boolean
}

export const ReceptionBookingCheckoutPreviewBill = ({
  receptionBookingDetails,
  restToPay,
  restToPayWithoutClimatic,
  depositToReturn,
  totalDeposit,
  isOnlyClimaticTaxLeft,
}: Props): JSX.Element => (
  <ReservationCard title="Rachunek" cardClassName="mb-2">
    <ReceptionBookingCheckBillDetails
      booking={receptionBookingDetails}
      readOnly={true}
      title="Rachunek za pobyt:"
      isOpen={false}
      restToPay={restToPay}
    />
    {isOnlyClimaticTaxLeft && (
      <AlertDanger className="py-1 mt-2 mb-0 font-12 px-1">Pozostała płatność za opłatę klimatyczną.</AlertDanger>
    )}
    <ReceptionBookingSettlementDeposit booking={receptionBookingDetails} depositToReturn={depositToReturn} />
    <ReceptionBookingSettlementSummary
      restToPay={restToPay}
      restToPayWithoutClimatic={restToPayWithoutClimatic}
      totalDeposit={totalDeposit}
      moneyToReturn={receptionBookingDetails.has_returnable_deposit}
      depositToReturn={depositToReturn}
    />
  </ReservationCard>
)
