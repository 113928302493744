import * as React from 'react'
import { NotificationEvents } from '@models/dashboard'
import { useWebSocket } from '@components/hooks/use-web-socket'
import { PackageVipDetails } from '@modules/promotions/package-vip/models'
import { updatePackageVipDetails } from '@store/slices/package-vip-slice'
import { useAppDispatch } from '@store/index'

export const PackageVipWebSocketHandler: React.FC = () => {
  const dispatch = useAppDispatch()

  useWebSocket<PackageVipDetails>(
    (message, payload) => {
      switch (message) {
        case NotificationEvents.PACKAGE_VIP_CHANGE:
          dispatch(updatePackageVipDetails(payload))
          break
      }
    },
    [],
    [NotificationEvents.PACKAGE_VIP_CHANGE],
  )

  return null
}
