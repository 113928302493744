import * as React from 'react'
import Table, { TableHeaderValue } from '@components/table/table'
import { BookingOption, BookingOptionsFiltersParams } from '@modules/promotions/booking-options/model'
import { BookingOptionsTableFooter } from '@modules/promotions/booking-options/table/booking-options-table-footer'
import { BookingOptionsTableRow } from '@modules/promotions/booking-options/table/booking-options-table-row'

interface Props {
  hideClient?: boolean
  hideFooter?: boolean
  isLoading: boolean
  filters: BookingOptionsFiltersParams
  setFilters: (filters: BookingOptionsFiltersParams) => void
  options: BookingOption[]
  showPagination: boolean
}

export const BookingOptionsTable: React.FC<Props> = ({
  showPagination = true,
  hideFooter,
  hideClient,
  options,
  setFilters,
  filters,
  isLoading,
}) => {
  const headerValues: TableHeaderValue<BookingOption>[] = React.useMemo(
    () =>
      [
        { sortField: 'invoice__reservation_number', title: 'Numer faktury' },
        { sortField: 'client__name', title: 'Klient' },
        { sortField: 'status', title: 'Status' },
        { sortField: 'price_brutto', title: 'Kwota netto' },
        { sortField: 'price_brutto', title: 'Kwota brutto' },
        { title: 'Zakupione opcje' },
        { sortField: 'created_by__name', title: 'Założona przez' },
        { sortField: 'seller__name', title: 'Sprzedawca' },
      ].filter(row => (hideClient ? row.sortField !== 'client__name' : true)),
    [hideClient],
  )
  return (
    <Table
      className="table-hover w-100"
      headerValues={headerValues}
      isLoading={isLoading}
      filters={filters}
      setFilters={setFilters}
      showPagination={showPagination}
    >
      {options.map(option => (
        <BookingOptionsTableRow hideClient={hideClient} key={option.id} option={option} />
      ))}
      {!!options.length && !hideFooter && <BookingOptionsTableFooter filters={filters} />}
    </Table>
  )
}
