import * as React from 'react'
import { FiltersHousekeepingApplicationFiltersParams } from '@modules/housekeeping/applications/filters'
import { useAppDispatch, useAppSelector } from '@store/index'
import { getApplicationFaults } from '@store/actions/housekeeping-actions'
import { HousekeepingApplicationFaultsTable } from '@modules/housekeeping/applications/fault/table'
import { useApiRequest } from '@components/hooks/use-api-request'
import { faultOrdersSelector } from '@store/slices/housekeeping-slice'

interface Props {
  filters: FiltersHousekeepingApplicationFiltersParams
  setFilters: (filters: FiltersHousekeepingApplicationFiltersParams) => void
  resortId: number
}

export const HousekeepingApplicationFaultsList: React.FC<Props> = ({ resortId, filters, setFilters }) => {
  const dispatch = useAppDispatch()

  const { isLoading, action: fetchApplicationFaults } = useApiRequest(
    async () => await dispatch(getApplicationFaults([filters, resortId])),
  )

  const faultOrders = useAppSelector(faultOrdersSelector)

  React.useEffect(() => {
    fetchApplicationFaults()
  }, [resortId, filters])

  return (
    <HousekeepingApplicationFaultsTable
      resortId={resortId}
      setFilters={setFilters}
      filters={filters}
      isLoading={isLoading}
      faultOrders={faultOrders}
    />
  )
}
