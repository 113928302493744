import * as React from 'react'
import { TechnicalOrder, TechnicalOrderStatus } from '@models/technical-orders'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useTechnicalOrderAccept } from '@modules/technical-orders/hooks/use-technical-order-accept'
import { useTechnicalOrderPause } from '@modules/technical-orders/hooks/use-technical-order-pause'
import { useTechnicalOrderStart } from '@modules/technical-orders/hooks/use-technical-order-start'

interface Props {
  selectedRows: TechnicalOrder[]
  clearSelectedRows: () => void
  tab: TechnicalOrderStatus
}

export const TechnicalOrdersTodoOptions: React.FC<Props> = ({ tab, selectedRows, clearSelectedRows }) => {
  const isDisabled = !selectedRows.length

  const user = useAuthenticatedUser()
  const technicalOrdersToAssign = React.useMemo(
    () =>
      selectedRows.filter(technicalOrder =>
        user.isTechnicalOrderManager ? true : !technicalOrder.forwarded_to_technical_order_manager,
      ),
    [selectedRows, user.isTechnicalOrderManager],
  )

  const technicalOrdersToClose = React.useMemo(() => selectedRows.filter(row => row.status !== 'done'), [selectedRows])

  const [handleAssign] = useModal('TechnicalOrderAssignModal', {
    technicalOrders: technicalOrdersToAssign,
    onComplete: clearSelectedRows,
  })
  const [handleClose] = useModal('TechnicalOrderCloseModal', {
    technicalOrders: technicalOrdersToClose,
    onComplete: clearSelectedRows,
  })

  const handleAccept = useTechnicalOrderAccept({ technicalOrders: selectedRows })
  const handlePause = useTechnicalOrderPause({ technicalOrders: selectedRows })
  const handleStart = useTechnicalOrderStart({ technicalOrders: selectedRows })

  return (
    <div className="mb-1 px-2">
      <UncontrolledButtonDropdown disabled={isDisabled}>
        <DropdownToggle color="light" caret={true} disabled={isDisabled}>
          <strong>zaznaczone ({selectedRows.length})</strong>
        </DropdownToggle>
        <DropdownMenu>
          {['todo'].includes(tab) && <DropdownItem onClick={handleAssign}>Przypisz zadania</DropdownItem>}
          {['todo', 'ordered', 'in_progress'].includes(tab) && (
            <DropdownItem onClick={handleClose}>Zamknij zadania</DropdownItem>
          )}
          {['ordered'].includes(tab) && <DropdownItem onClick={handleStart}>Rozpocznij zadania</DropdownItem>}
          {['in_progress'].includes(tab) && <DropdownItem onClick={handlePause}>Zatrzymaj zadania</DropdownItem>}
          {['to_accept'].includes(tab) && <DropdownItem onClick={handleAccept}>Akceptuj zadania</DropdownItem>}
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </div>
  )
}
