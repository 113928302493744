import * as React from 'react'
import { Col, Row } from 'reactstrap'
import classNames from 'classnames'

interface ReservationDataRowProps {
  name: React.ReactNode
  children: React.ReactNode
  className?: string
}

export const ReservationDataRow: React.FC<ReservationDataRowProps> = ({ name, className, children }) => (
  <Row className={classNames('mb-2', className)}>
    <Col md={4}>{name}</Col>
    <Col md={8}>{children}</Col>
  </Row>
)
