import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@store/index'
import {
  PackageWholesale,
  PackageWholesaleAggregation,
  PackageWholesaleAppData,
  PackageWholesaleDetails,
  PackageWholesaleProduct,
} from '@modules/package-wholesale/models'
import {
  getPackageWholesaleAppData,
  getPackageWholesaleDetails,
  getPackageWholesaleProducts,
  getPackageWholesales,
  updatePackageWholesale,
} from '@store/actions/package-wholesale-actions'

interface PackageWholesaleState {
  products: EntityState<PackageWholesaleProduct>
  packageWholesales: EntityState<PackageWholesale>
  packageWholesaleDetails: PackageWholesaleDetails | undefined
  appData: PackageWholesaleAppData
  aggregation: PackageWholesaleAggregation | null
}

const productsAdapter = createEntityAdapter<PackageWholesaleProduct>()
const packageWholesalesAdapter = createEntityAdapter<PackageWholesale>()

export const emptyPackageWholesaleState: PackageWholesaleState = {
  products: productsAdapter.getInitialState(),
  packageWholesaleDetails: undefined,
  packageWholesales: packageWholesalesAdapter.getInitialState(),
  aggregation: null,
  appData: {
    available_options: [],
    deposit_amount: [],
    defaults_days: [],
    notification_emails: [],
    status: 'unknown',
  },
}

const packageWholesaleSlice = createSlice({
  name: 'packageWholesale',
  initialState: emptyPackageWholesaleState,
  reducers: {
    updatePackageWholesaleProductsBulk(state, action: PayloadAction<PackageWholesaleProduct[]>) {
      productsAdapter.upsertMany(state.products, action.payload)
    },
    updatePackageWholesaleProductDetails(state, action: PayloadAction<PackageWholesaleProduct>) {
      productsAdapter.upsertOne(state.products, action.payload)
    },
    updatePackageWholesaleDetails(state, action: PayloadAction<PackageWholesaleDetails>) {
      if (state.packageWholesaleDetails?.id === action.payload.id) {
        state.packageWholesaleDetails = action.payload
      }
      packageWholesalesAdapter.upsertOne(state.packageWholesales, action.payload)
    },
    removePackageWholesaleProduct(state, action: PayloadAction<PackageWholesaleProduct>) {
      productsAdapter.removeOne(state.products, action.payload.id)
    },
    updatePackageWholesaleAppData(state, action: PayloadAction<PackageWholesaleAppData>) {
      state.appData = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(updatePackageWholesale.fulfilled, (state, action) => {
      if (state.packageWholesaleDetails?.id === action.payload.id) {
        state.packageWholesaleDetails = action.payload
      }
      packageWholesalesAdapter.upsertOne(state.packageWholesales, action.payload)
    })

    builder.addCase(getPackageWholesaleDetails.fulfilled, (state, action) => {
      state.packageWholesaleDetails = action.payload
    })

    builder.addCase(getPackageWholesaleAppData.fulfilled, (state, action) => {
      state.appData = { ...state.appData, ...action.payload, status: 'ready' }
    })
    builder.addCase(getPackageWholesales.fulfilled, (state, action) => {
      packageWholesalesAdapter.setAll(state.packageWholesales, action.payload.results)
      state.aggregation = action.payload.aggregation
    })
    builder.addCase(getPackageWholesaleProducts.fulfilled, (state, action) => {
      productsAdapter.setAll(state.products, action.payload)
    })
  },
})
export const { selectAll: packageWholesaleProductsSelector } = productsAdapter.getSelectors(
  (state: RootState) => state.packageWholesaleState.products,
)

export const { selectAll: packageWholesalesSelector } = packageWholesalesAdapter.getSelectors(
  (state: RootState) => state.packageWholesaleState.packageWholesales,
)

export const selectPackageWholesaleAggregation = (state: RootState): PackageWholesaleAggregation | null =>
  state.packageWholesaleState.aggregation

export const {
  updatePackageWholesaleProductsBulk,
  updatePackageWholesaleDetails,
  updatePackageWholesaleProductDetails,
  removePackageWholesaleProduct,
  updatePackageWholesaleAppData,
} = packageWholesaleSlice.actions

export default packageWholesaleSlice.reducer
