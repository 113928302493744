import * as React from 'react'
import { SubscriptionBooking, SubscriptionDetails } from '@modules/subscription/models'
import { useApiRequest } from '@components/hooks/use-api-request'
import { ReceptionBookingDetails } from '@models/reception'
import { commonObjectGet } from '@store/actions/generic-actions'
import { useModal } from '@components/modals/use-modal'
import { setSubscriptionDetails } from '@store/slices/subscription-slice'
import { useAppDispatch } from '@store/index'

interface Props {
  booking: SubscriptionBooking
  subscriptionDetails: SubscriptionDetails
}

export const SubscriptionDetailsBookingsRowChangeClientDate: React.FC<Props> = ({ subscriptionDetails, booking }) => {
  const [showModal] = useModal('ReservationChangeDatesDialog')
  const dispatch = useAppDispatch()

  const { action: onSuccess } = useApiRequest(async () =>
    dispatch(setSubscriptionDetails(await commonObjectGet<SubscriptionDetails>(subscriptionDetails.urls.details))),
  )
  const { isLoading, action: handleClick } = useApiRequest(async (event: React.SyntheticEvent) => {
    event.stopPropagation()
    const bookingDetails = await commonObjectGet<ReceptionBookingDetails>(booking.urls.details)

    showModal(event, { booking: bookingDetails, onSuccess })
  })

  return (
    <button disabled={isLoading} className="btn btn-xs btn-green" onClick={handleClick}>
      zmień datę
    </button>
  )
}
