import * as React from 'react'
import { TransferPackage } from '@modules/accounting/refund/models'

interface Props {
  transferPackage: TransferPackage
}

export const TransferPackagesTableTableCellActionsDownload: React.FC<Props> = ({ transferPackage }) =>
  transferPackage.status === 'generated' ? (
    <a href={transferPackage.urls.download} title="Pobierz paczke" className="mr-2">
      <span className="uil-download-alt text-muted font-16 uil" />
    </a>
  ) : null
