import * as React from 'react'

import { FormInput } from '@hyper/forms'
import { Row } from 'reactstrap'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useDebounce } from 'rooks'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { useFormContext, useWatch } from 'react-hook-form'
import { AgreementFormInputs } from '@modules/hr/agreement/create/modal'
import { AddressCode, AddressesResponse } from '@modules/hr/agreement/models'
import { createSelectOption } from '@helpers/utils'
import { CustomReactSelectOption, makeDefaultSelectOption } from '@components/custom-react-select'
import { FormSelect } from '@hyper/forms/form-select'
import { firstLetterToUpperCase } from '@helpers/text'
import { ContentLoader } from '@components/content-loader'
import { getState } from '@store/index'
import { HrAddress } from '@modules/hr/common/models'

const createSelectOptions = (
  codes: AddressCode[],
  name: keyof AddressCode,
  additionalCondition?: (code: AddressCode) => boolean,
) =>
  codes.reduce((options: CustomReactSelectOption[], code) => {
    const isAlreadyAdded = options.some(option => option.value === code[name])

    const canBeAdded = additionalCondition ? additionalCondition(code) : true

    return !isAlreadyAdded && canBeAdded
      ? [...options, createSelectOption(firstLetterToUpperCase(code[name] as string), code[name])]
      : options
  }, [])

interface Props {
  prefix: '' | 'registered_'
  rowClassName?: string
  addressData?: HrAddress | null
}

export const HrAddressFormRow = ({ rowClassName, prefix, addressData }: Props): JSX.Element => {
  const [addresses, setAddresses] = React.useState<AddressesResponse | null>(null)

  const getFormName = (inputName: keyof AgreementFormInputs) => `${prefix}${inputName}` as keyof AgreementFormInputs

  const { control, setValue } = useFormContext<AgreementFormInputs>()

  const [postcode, city, district, community, province] = useWatch({
    control,
    name: [
      getFormName('postcode'),
      getFormName('city'),
      getFormName('district'),
      getFormName('community'),
      getFormName('province'),
    ],
  }) as [
    CustomReactSelectOption | null,
    CustomReactSelectOption | null,
    CustomReactSelectOption | null,
    CustomReactSelectOption | null,
    CustomReactSelectOption | null,
  ]

  const { action: fetchAddresses, isLoading } = useApiRequest(async code => {
    if (!code) return
    const url = getState().appState.appData.urls.datasets.address
    setAddresses(await commonObjectPost<AddressesResponse>(url, { code }))
  })

  const debouncedFetchAddresses = React.useCallback(useDebounce(fetchAddresses, 1000), [])

  React.useEffect(() => {
    fetchAddresses(postcode)
  }, [])

  useDidUpdateEffect(() => {
    debouncedFetchAddresses(postcode)
  }, [postcode])

  const addressCodes = React.useMemo(() => {
    if (!addresses || !Object.values(addresses)[0]) return []

    return Object.values(addresses)[0].codes
  }, [addresses])

  const voivodeshipOptions = React.useMemo(() => createSelectOptions(addressCodes, 'voivodeship_name'), [addressCodes])

  const districtOptions = React.useMemo(
    () => createSelectOptions(addressCodes, 'district_name', code => code.voivodeship_name === province?.value),
    [addressCodes, province?.value],
  )

  const communityOptions = React.useMemo(
    () => createSelectOptions(addressCodes, 'commune_name', code => code.district_name === district?.value),
    [addressCodes, district?.value],
  )

  const townOptions = React.useMemo(
    () => createSelectOptions(addressCodes, 'town', code => code.commune_name === community?.value),
    [addressCodes, community?.value],
  )

  const updateValue = (fieldName: keyof AgreementFormInputs, options: CustomReactSelectOption[]) => {
    setValue(
      getFormName(fieldName),
      options.length === 1
        ? options[0]
        : addressData
          ? makeDefaultSelectOption(options, addressData[getFormName(fieldName)])
          : null,
    )
  }

  useDidUpdateEffect(() => {
    updateValue('province', voivodeshipOptions)
  }, [voivodeshipOptions])

  useDidUpdateEffect(() => {
    updateValue('district', districtOptions)
  }, [districtOptions])

  useDidUpdateEffect(() => {
    updateValue('community', communityOptions)
  }, [communityOptions])

  useDidUpdateEffect(() => {
    updateValue('post', townOptions)
    updateValue('city', townOptions)
  }, [townOptions])

  useDidUpdateEffect(() => {
    setValue(getFormName('street'), '')
    setValue(getFormName('street_house'), '')
    setValue(getFormName('street_apartment'), '')
  }, [city?.value])

  return (
    <ContentLoader isLoading={isLoading}>
      <Row className={rowClassName}>
        <FormInput
          inputClassName="bg-white"
          colSize={2}
          inputProps={{ autoComplete: 'new-password' }}
          label="Kod pocztowy"
          name={getFormName('postcode')}
          inputPattern={/[0-9]|[-]/u}
        />

        <FormSelect
          formPlainProps={{ colSize: 5 }}
          options={voivodeshipOptions}
          name={getFormName('province')}
          label="Województwo"
          selectProps={{ isDisabled: !postcode }}
        />

        <FormSelect
          formPlainProps={{ colSize: 5 }}
          options={districtOptions}
          name={getFormName('district')}
          label="Powiat"
          selectProps={{ isDisabled: !postcode }}
        />

        <FormSelect
          formPlainProps={{ colSize: 4 }}
          options={communityOptions}
          name={getFormName('community')}
          label="Gmina"
          selectProps={{ isDisabled: !district }}
        />

        <FormSelect
          formPlainProps={{ colSize: 4 }}
          options={townOptions}
          name={getFormName('city')}
          label="Miejscowość"
          selectProps={{ isDisabled: !community }}
        />

        <FormSelect
          formPlainProps={{ colSize: 4 }}
          options={townOptions}
          name={getFormName('post')}
          label="Poczta"
          selectProps={{ isDisabled: !community }}
        />

        <FormInput
          inputClassName="bg-white"
          colSize={6}
          label="Ulica"
          name={getFormName('street')}
          inputProps={{ autoComplete: 'new-password' }}
          disabled={!postcode}
        />

        <FormInput
          inputClassName="bg-white"
          colSize={3}
          label="Nr. domu"
          name={getFormName('street_house')}
          inputProps={{ autoComplete: 'new-password' }}
          disabled={!postcode}
        />
        <FormInput
          inputClassName="bg-white"
          colSize={3}
          label="Nr. lokalu"
          name={getFormName('street_apartment')}
          inputProps={{ autoComplete: 'new-password' }}
          disabled={!city}
        />
      </Row>
    </ContentLoader>
  )
}
