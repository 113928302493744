import * as React from 'react'
import { NavigationTabs, NavigationTabsButton } from '@components/navigation-tabs'

type Tab = 'data' | 'agreement' | 'notes' | 'history' | 'invites'

interface Props {
  dataRef: React.RefObject<HTMLElement>
  notesRef: React.RefObject<HTMLElement>
  invitesRef: React.RefObject<HTMLElement>
}

export const BenefitProgramDetailsTabs: React.FC<Props> = ({ dataRef, notesRef, invitesRef }) => {
  const [tab, setTab] = React.useState<Tab>('data')

  const handleTabClick = (tab: Tab) => () => {
    setTab(tab)

    const ref = {
      data: dataRef,
      agreement: dataRef,
      notes: notesRef,
      history: notesRef,
      invites: invitesRef,
    }[tab]

    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const buttons: NavigationTabsButton[] = [
    {
      title: 'Dane firmy',
      isActive: tab == 'data',
      onClick: handleTabClick('data'),
    },
    {
      title: <span className="text-nowrap">Warunki umowy</span>,
      isActive: tab == 'agreement',
      onClick: handleTabClick('agreement'),
    },
    {
      title: 'Pracownicy',
      isActive: tab == 'invites',
      onClick: handleTabClick('invites'),
    },
    {
      title: 'Notatki',
      isActive: tab == 'notes',
      onClick: handleTabClick('notes'),
    },
    {
      title: 'Historia zmian',
      isActive: tab == 'history',
      onClick: handleTabClick('history'),
    },
  ]

  return <NavigationTabs buttons={buttons} />
}
