import * as React from 'react'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'

export const UnitsDefaultView: React.FC = () => {
  const user = useAuthenticatedUser()

  const resort = user.resorts[0]
  const navigate = useNavigate()

  React.useEffect(() => {
    navigate(NavigationPath.UnitsWithParams.replace(':resort_id', String(resort.id)))
  }, [resort])

  return null
}
