import * as React from 'react'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'
import { DashboardLink } from '@components/left-menu/dashboard-link'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { IconWithText } from '@components/icon-with-text'
import { useModal } from '@components/modals/use-modal'
import { AuthenticatedUser, DashboardUrlsState } from '@models/dashboard'
import { ProfileDropdownRelease } from '@components/topbar/profile-dropdown-release'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectGet } from '@store/actions/generic-actions'
import { RootState, useAppSelector } from '@store/index'
import * as Sentry from '@sentry/react'
import { useToggle } from '@components/hooks/use-toggle'
interface ProfileDropdownProps {
  urls: DashboardUrlsState
}

export const ProfileDropdown: React.FC<ProfileDropdownProps> = ({ urls }) => {
  const [isOpen, toggleDropdown] = useToggle(false)
  const user = useAuthenticatedUser()

  const [handleDesktopClick] = useModal('AccountUserDesktopModal')

  const userDetailsUrl = useAppSelector((state: RootState) => state.appState.appData.urls.account.details)

  const { action: checkUser } = useApiRequest(async () => {
    const response = await commonObjectGet<AuthenticatedUser>(userDetailsUrl)
    if (response.email !== user.email) {
      Sentry.captureMessage(`Nieprawidłowa użytkownik : ${response.email} jest ${user.email}, refresh!`)
      document.location.reload()
    }
  })

  React.useEffect(() => {
    checkUser()
  }, [])

  return (
    <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
      <DropdownToggle data-toggle="dropdown" tag="button" className="nav-link dropdown-toggle nav-user arrow-none mr-0">
        <span className="account-user-avatar">
          <img src={require('@assets/images/users/avatar-1.jpg')} className="rounded-circle" alt="user" />
        </span>
        <span>
          <span className="account-user-name"> {user.fullname}</span>
          <span className="account-position">
            {user.isHijacked ? (
              <strong className="badge badge-danger">Zalogowany jako</strong>
            ) : user.isReceptionist ? (
              <>Recepcja ({user.receptionWorkstation?.name || 'brak'})</>
            ) : (
              user.receptionWorkstation?.name || 'Pracownik'
            )}
          </span>
        </span>
      </DropdownToggle>

      <DropdownMenu className="dropdown-menu-animated topbar-dropdown-menu profile-dropdown">
        <div onClick={toggleDropdown}>
          <div className="dropdown-header noti-title">
            <h6 className="text-overflow m-0">Witaj!</h6>
          </div>
          <DashboardLink href={urls.account.profile} className="dropdown-item notify-item">
            <IconWithText icon="uil uil-edit" text="Mój profil" />
          </DashboardLink>

          <div className="dropdown-item notify-item cursor-pointer" onClick={handleDesktopClick}>
            <IconWithText icon="uil uil-desktop" text="Stanowiska" />
          </div>

          <DashboardLink href={urls.account.password} className="dropdown-item notify-item">
            <IconWithText icon="uil uil-key-skeleton" text="Zmień hasło" />
          </DashboardLink>
          {user.isHijacked && <ProfileDropdownRelease />}

          <DashboardLink href={urls.account.logout} className="dropdown-item notify-item">
            <IconWithText icon="uil uil-signout" text="Wyloguj" />
          </DashboardLink>
        </div>
      </DropdownMenu>
    </Dropdown>
  )
}
