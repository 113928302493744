import * as React from 'react'
import { SubscriptionDetails } from '@modules/subscription/models'
import { SubscriptionSingleModalBase } from '@modules/subscription/options/subscription-single-modal-base'
import { BaseModalProps } from '@components/modals/types'

interface Props extends BaseModalProps {
  subscriptionDetails: SubscriptionDetails
}

export const SubscriptionSingleChangeDateModal: React.FC<Props> = ({ subscriptionDetails, toggleIsVisible }) => (
  <SubscriptionSingleModalBase
    title="Dodaj jednorazową zmianę daty w pakiecie"
    message="Jednorazowa opcja zmiany daty została dodana"
    amounts={[subscriptionDetails.product?.subscription_disposable_booking_date_change_price || '']}
    description="Opcja jednorazowej zmiany daty"
    toggleIsVisible={toggleIsVisible}
    subscriptionDetails={subscriptionDetails}
    url={subscriptionDetails.urls.subscription_single_date_change}
  />
)
