import * as React from 'react'
import { Col, Row } from 'reactstrap'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useFormContext, useWatch } from 'react-hook-form'
import { useApiRequest } from '@components/hooks/use-api-request'
import { setAuthenticatedUser } from '@store/slices/app-slice'
import { commonObjectPatch } from '@store/actions/generic-actions'
import { AuthenticatedUser } from '@models/dashboard'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useDidUpdate } from 'rooks'
import { FormSelect } from '@hyper/forms/form-select'

interface Props {
  receptionWorkstationsOptions: CustomReactSelectOption[]
}

export const PaymentUserOpenDayModalReceptionWorkstation = ({ receptionWorkstationsOptions }: Props): JSX.Element => {
  const { control } = useFormContext()
  const userDetailsUrl = useAppSelector((state: RootState) => state.appState.appData.urls.account.details)

  const reception_workstation = useWatch({
    name: 'reception_workstation',
    control,
  })
  const dispatch = useAppDispatch()
  const { action: resetDevices } = useApiRequest(async () => {
    dispatch(
      setAuthenticatedUser(
        await commonObjectPatch<AuthenticatedUser>(userDetailsUrl, {
          eservice_pad: null,
          band_reader: null,
          tablet: null,
        }),
      ),
    )
  })

  useDidUpdate(() => {
    resetDevices()
  }, [reception_workstation])

  return (
    <Row className="mx-0 align-items-center">
      <Col md={5}>
        <strong>Wybór stanowiska:</strong>
      </Col>
      <Col md={6}>
        <div className="d-flex align-items-center">
          <i className="uil-desktop text-muted font-16" />
          <div>
            <FormSelect
              options={receptionWorkstationsOptions}
              name="reception_workstation"
              label=""
              selectProps={{ isClearable: true, placeholder: 'Wybierz stanowisko' }}
              formPlainProps={{ colSize: 12, colStyle: { width: 300 } }}
            />
          </div>
        </div>
      </Col>
    </Row>
  )
}
