import * as React from 'react'
import { TextBlock } from '@modules/cms/models'
import Table from '@components/table/table'
import { useModal } from '@components/modals/use-modal'

interface Props {
  textBlock: TextBlock
}

export const CmsReservationAppTextBlocksRow: React.FC<Props> = ({ textBlock }) => {
  const [handleEdit] = useModal('CmsReservationAppTextBlockModal', { textBlock })

  return (
    <Table.Row>
      <Table.Cell>{textBlock.name}</Table.Cell>
      <Table.Cell>{textBlock.keyword}</Table.Cell>

      <Table.Cell className="text-right">
        <button className="action-icon btn-pure" title="Edytuj" onClick={handleEdit}>
          <i className="uil-edit text-muted font-18" />
        </button>
      </Table.Cell>
    </Table.Row>
  )
}
