import * as React from 'react'
import { Col, Row } from 'reactstrap'
import { Link, useParams } from 'react-router-dom'
import { ContentNotesCard } from '@components/content-notes/content-notes-card'
import { useAppData } from '@components/hooks/use-app-data'
import { HistoryBox } from '@modules/promotions/common/history-box'
import { TypedQueryResult } from '@api/base'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { useGetBenefitProgramDetailsQuery } from '@api/benefit-programs'
import { BenefitProgramDetails } from '@modules/benefit-program/models'
import { BenefitProgramDetailsInformation } from '@modules/benefit-program/details/information'
import { GenericProductPayments } from '@modules/generic-product-payments'
import { PaymentMethod } from '@models/payments'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { BenefitProgramDetailsTerms } from '@modules/benefit-program/details/terms'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { useDocumentTitle } from '@helpers/utils'
import { PageTitle } from '@hyper/page-title'
import { BenefitProgramDetailsTabs } from '@modules/benefit-program/details/tabs'
import { ButtonWithIcon } from '@components/button-with-icon'
import { useModal } from '@components/modals/use-modal'
import { useProductSaleConfirmationModal } from '@components/sale/use-product-sale-confirmation-modal'
import { BenefitProgramDetailsAgreements } from '@modules/benefit-program/details/agreemeents'
import { BenefitProgramDetailsInvites } from '@modules/benefit-program/details/invites'

const paymentMethods = [PaymentMethod.form_bank]

export const BenefitProgramDetailsView: React.FC = () => {
  const { id = '' } = useParams<{ id: string }>()

  const dataRef = React.useRef<HTMLDivElement>(null)
  const notesRef = React.useRef<HTMLDivElement>(null)
  const invitesRef = React.useRef<HTMLDivElement>(null)

  const contentType = useAppData().content_types.benefitprogram

  const user = useAuthenticatedUser()
  const canAddPayment = user.hasPerm(UserPermission.BenefitProgramsCanAddPayment)

  const { showConfirmationPackageCreation } = useProductSaleConfirmationModal({
    title: 'Pracownik został dodany do systemu',
    content: 'Przejdź do listy pracowników lub dodaj kolejnego',
    backText: 'Zakończ',
    detailsText: 'Dodaj kolejnego',
  })

  const [showAgreementCreationModal] = useModal('BenefitProgramAgreementCreateModal', {
    onCreate: () => showConfirmationPackageCreation(handleShowAddAgreementModal),
  })

  const {
    data: benefitProgramDetails,
    isLoading,
    refetch,
  } = useGetBenefitProgramDetailsQuery({
    id,
  }) as TypedQueryResult<BenefitProgramDetails>

  const pageTitle = 'Informacje o pracodawcy'
  useDocumentTitle(pageTitle)
  useMenuActive(NavigationPath.BenefitProgramList)

  const handleShowAddAgreementModal = () => {
    showAgreementCreationModal(null, { benefitProgramId: id })
  }

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: 'Pracodawcy', path: NavigationPath.BenefitProgramList, active: false },
          { label: pageTitle, path: '', active: true },
        ]}
        title={pageTitle}
      />

      <LoaderPlaceholder content={!isLoading}>
        {!!benefitProgramDetails && (
          <Row>
            <Col md={6}>
              <BenefitProgramDetailsTabs dataRef={dataRef} notesRef={notesRef} invitesRef={invitesRef} />
            </Col>
            <Col md={6} className="text-right">
              <Link to={NavigationPath.BenefitProgramList}>
                <ButtonWithIcon icon="uil-arrow-left" text="Wróć do listy" color="primary" />
              </Link>
            </Col>
            <div ref={dataRef} />
            <Col md={6}>
              <BenefitProgramDetailsInformation benefitProgram={benefitProgramDetails} />
            </Col>
            <Col md={6}>
              <BenefitProgramDetailsTerms benefitProgram={benefitProgramDetails} />
            </Col>
            <Col md={12}>
              <BenefitProgramDetailsInvites benefitProgram={benefitProgramDetails} ref={invitesRef} />
            </Col>
            <Col md={12}>
              <BenefitProgramDetailsAgreements
                ref={invitesRef}
                onAddEmployee={handleShowAddAgreementModal}
                benefitProgramId={benefitProgramDetails.id}
              />
            </Col>
            <Col md={12}>
              <GenericProductPayments
                addUrl={benefitProgramDetails.urls.payments}
                canAddPayment={canAddPayment}
                payments={benefitProgramDetails.payments}
                onSuccess={refetch}
                canDeletePayment={false}
                defaultAmount="100"
                paymentMethods={paymentMethods}
              />
            </Col>
            <Col md={6}>
              <div ref={notesRef} />

              <ContentNotesCard
                objectId={benefitProgramDetails.id}
                contentType={contentType}
                notes={benefitProgramDetails.notes}
                onNotesUpdate={refetch}
                readOnly={false}
              />
            </Col>
            <Col md={6}>
              <HistoryBox history={benefitProgramDetails.history} />
            </Col>
          </Row>
        )}
      </LoaderPlaceholder>
    </>
  )
}
