import * as React from 'react'
import { StepReservationBox } from '@modules/reservations/create/step-reservation-box'
import { ReceptionBookingCheck } from '@modules/reception/common/reception-booking-check'
import { FormProvider, useFormContext } from 'react-hook-form'
import { useApiRequest, useFormRequest } from '@components/hooks/use-api-request'
import { ReceptionReminder } from '@models/reception'
import { newUserPredicator, ReceptionCheckReminders } from '@modules/reception/common/reception-check-reminders'
import { useAppDispatch, useAppSelector } from '@store/index'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { receptionBookingDetailsSelector } from '@store/selectors/reception'
import { ReceptionBookingCheckInAddImprovementButton } from '@modules/reception/checkin/step-improvements/reception-booking-check-in-add-improvement-button'
import { commonObjectPatch } from '@store/actions/generic-actions'
import { ReservationCreateFormInputs } from '@modules/reservations/create/models'
import { setReceptionBookingDetails } from '@store/slices/reception-slice'

interface StepInsuranceProps {
  nextStep: () => void
  previousStep: () => void
  stepNumber: number
}

const reminders: ReceptionReminder[] = [
  {
    type: 'success',
    id: 'insurance_info',
    isClosable: true,
    children: (
      <>
        Klient może skorzystać z opcji możliwości rezygnacji z pobytu na wypadek nieprzewidzianych zdarzeń i cieszyć się
        komfortem możliwości <strong>rezygnacji do 3 dni przed planowym pobytem.</strong>. Korzystając z opcji
        rezygnacji z pobytu klient <strong>otrzyma zwrot 100% wartości wpłaconych kwot</strong> za rezerwację pobytu.
        <br />
        <br />
        <strong>Opcja rezygnacji jest bezzwrotna i kosztuje jedynie 10% wartości rezerwacji.</strong>
      </>
    ),
  },
]

const StepInsurance = ({ previousStep, nextStep, stepNumber }: StepInsuranceProps): JSX.Element | null => {
  const dispatch = useAppDispatch()
  const methods = useFormContext<ReservationCreateFormInputs>()
  const user = useAuthenticatedUser()
  const reservation = useAppSelector(receptionBookingDetailsSelector)

  const { isLoading: isSubmitting, action: onSubmit } = useFormRequest(async () => {
    nextStep()
  }, methods.setError)

  const { isLoading: isLoadingInsurance, action: toggleInsurance } = useApiRequest(async () => {
    if (reservation.urls) {
      dispatch(
        setReceptionBookingDetails(
          await commonObjectPatch(reservation.urls.update, { warranty: !reservation.warranty }),
        ),
      )
    }
  })

  return (
    <StepReservationBox
      title={`${stepNumber}. Wykup opcji rezygnacji i zwrotu całej kwoty`}
      footer={
        <ReceptionBookingCheck
          nextStep={nextStep}
          previousStep={previousStep}
          isSaving={isSubmitting}
          onSave={onSubmit}
        />
      }
    >
      <FormProvider {...methods}>
        <ReceptionCheckReminders required={!newUserPredicator(user)} reminders={reminders} />
        <div className="reservation-create__step-insurance__box">
          <div className="reservation-create__step-insurance__text">
            <div className="reservation-create__step-insurance__text-header">
              Opcja rezygnacji{' '}
              <span className="reservation-create__step-insurance__text-header-cost">
                +{reservation.warranty_costs} zł
              </span>
            </div>
            <div className="reservation-create__step-insurance__text-subheader">(prowizja + 15zł)</div>
            <div className="reservation-create__step-insurance__paragraph">
              Dodanie opcji rezygnacji zwiększy koszt rezerwacji o <strong>10% wartości rezerwacji</strong>
            </div>
          </div>
          {!reservation.warranty ? (
            <ReceptionBookingCheckInAddImprovementButton
              improvementPrice={reservation.warranty_costs}
              isSaving={isLoadingInsurance}
              onClick={toggleInsurance}
            />
          ) : (
            <div className="reservation-create__step-insurance__delete">
              <div
                className="cursor-pointer reservation-create__step-insurance__delete-button mr-2"
                onClick={toggleInsurance}
              >
                usuń opcję rezygnacji
                <i className="uil-trash-alt font-18 grey-400 ml-1" role="delete-insurance" />
              </div>
              <span className="text-semi-strong text-danger">-{reservation.warranty_costs} zł</span>
            </div>
          )}
        </div>
      </FormProvider>
    </StepReservationBox>
  )
}

export default StepInsurance
