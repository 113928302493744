import * as React from 'react'
import { ReceptionBookingCheckInStepAccessBandsRow } from '@modules/reception/checkin/step-access/reception-booking-check-in-step-access-bands-row'
import { ReceptionBand } from '@models/reception'
import { ReceptionBookingGuest } from '@models/booking'
import { CustomInput } from 'reactstrap'
import { useFormContext, useWatch } from 'react-hook-form'
import { ChangeEvent } from 'react'

interface Props {
  isScanning: boolean
  bands: ReceptionBand[]
  guests: ReceptionBookingGuest[]
}

export const ReceptionBookingCheckInStepAccessBandsListTable: React.FC<Props> = ({ isScanning, bands, guests }) => {
  const { control, setValue } = useFormContext()
  const selectedBands = useWatch({ control, name: 'bands' })
  const handleSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    setValue('bands', (event.target.checked ? bands.map(band => String(band.id)) : []) || [])
  }

  const bandList = bands.map((band: ReceptionBand) => {
    const guest = guests.find((guest: ReceptionBookingGuest) => guest.id === band.guest_id)

    if (guest) {
      return <ReceptionBookingCheckInStepAccessBandsRow disabled={isScanning} band={band} key={band.id} guest={guest} />
    }
  })

  return (
    <table className="table table-sm">
      <tbody>
        <tr className="reception__booking-check-in__access-step__table-row">
          <td
            className="text-semi-strong reception__booking-check-in__access-step__table-column border-top-0 pl-0"
            colSpan={2}
          >
            <CustomInput
              disabled={isScanning}
              id="select_all"
              type="checkbox"
              label="Wszyscy"
              checked={bands.length === selectedBands.length}
              onChange={handleSelectAll}
            />
          </td>
        </tr>
        {bandList}
      </tbody>
    </table>
  )
}
