import { getState } from '@store/index'
import { commonObjectDelete, commonObjectPost } from '@store/actions/generic-actions'

export async function bandReaderEncode(payload: {
  hour_from: string
  uuid: string
  bands: (string | number)[]
  reader: number
}): Promise<{ url: string; has_other_action_in_processing: boolean }> {
  return await commonObjectPost(getState().appState.appData.urls.bands.reader_encode, payload)
}

export async function runBandReader(payload: { reader: number; uuid: string }): Promise<string> {
  const url = getState().appState.appData.urls.bands.reader_request
  return await commonObjectPost(url, payload)
}

export async function bandReaderEncodeCancel(payload: { uuid: string }) {
  const url = getState().appState.appData.urls.bands.reader_encode
  return await commonObjectDelete(url, null, payload, undefined)
}
