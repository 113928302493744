import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { SpinInput } from '@components/spin-input'
import { asDecimal, formatPriceShort } from '@helpers/utils'
import { IconWithTooltip } from '@components/icon-with-tooltip'
import { FormPlain } from '@hyper/forms'
import { CustomInput } from 'reactstrap'
import { extractInnerRef } from '@helpers/forms'
import { SubscriptionOptionKindPrice } from '@modules/subscription/models'

interface Props {
  kind: string
  prices: SubscriptionOptionKindPrice
  isEditDisabled?: boolean
}

export const EntranceTicketOption: React.FC<Props> = ({ kind, prices, isEditDisabled }) => {
  const { register, control } = useFormContext()

  const [withEntranceTicketsName, entranceTicketsAmountName] = [`with_${kind}_tickets`, `${kind}_tickets_packs_amount`]
  const [areEntranceTicketsSelected, entranceTicketsAmount] = useWatch({
    control,
    name: [withEntranceTicketsName, entranceTicketsAmountName],
  })
  const entranceTicketsPrice = asDecimal(entranceTicketsAmount || 1)
    .mul(prices[kind] || 0)
    .toString()

  return (
    <div className="d-flex w-100">
      <FormPlain name={withEntranceTicketsName} colSize={4}>
        <CustomInput
          disabled={isEditDisabled}
          className="mt-2"
          type="checkbox"
          id={withEntranceTicketsName}
          label={
            <span className="text-nowrap">
              Bilety do {kind == 'zoo_borysew' ? 'ZOO Borysew' : 'Suntago'}{' '}
              {prices[kind] ? formatPriceShort(entranceTicketsPrice) : ''}
              <IconWithTooltip
                icon="uil-exclamation-circle ml-1"
                tooltipId="password-protected-tooltip-id"
                tooltipPlacement="right"
                tooltipMessage={
                  <span className="font-11 text-left">
                    <span>Zestaw składa się z 4 sztuk.</span> <br />
                    Cena obliczana na podstawie wybranej ilości zestawów.
                  </span>
                }
                color="text-secondary"
              />
            </span>
          }
          {...extractInnerRef(register(withEntranceTicketsName))}
        />
      </FormPlain>
      <div>
        <SpinInput
          inputName={entranceTicketsAmountName}
          inputProps={{
            min: 1,
            max: 100,
            disabled: !areEntranceTicketsSelected,
            style: { width: 70 },
          }}
        />
      </div>
    </div>
  )
}
