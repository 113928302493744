import * as React from 'react'
import { Row } from 'reactstrap'
import { PackageSummarySection, PackageSummarySectionDataRow } from '@modules/package/create/package-summary-section'
import { formatIban } from '@helpers/text'
import { useFormContext } from 'react-hook-form'
import { HrWorkersCreateFormInputs } from '@modules/hr/workers/create/modal'
import { toDefaultDateFormat } from '@helpers/date-helper'

export const HrWorkerSummaryData = (): JSX.Element => {
  const methods = useFormContext<HrWorkersCreateFormInputs>()
  const data = methods.getValues()

  return (
    <div>
      <Row>
        <PackageSummarySection title="Dane pracownika" className="col-8">
          <PackageSummarySectionDataRow title="Imię i nazwisko:">
            {data.first_name} {data.second_name} {data.last_name}
          </PackageSummarySectionDataRow>
          <PackageSummarySectionDataRow title="Obywatelstwo:">{data.nationality?.label}</PackageSummarySectionDataRow>
          <PackageSummarySectionDataRow title="PESEL:">{data.tax_id}</PackageSummarySectionDataRow>
          <PackageSummarySectionDataRow title="Data urodzenia:">
            {toDefaultDateFormat(data.birthday)}
          </PackageSummarySectionDataRow>
          <PackageSummarySectionDataRow title="Adres email:">{data.email}</PackageSummarySectionDataRow>
          <PackageSummarySectionDataRow title="Numer telefonu:">{data.phone}</PackageSummarySectionDataRow>
        </PackageSummarySection>
      </Row>
      <div className="dropdown-divider" />
      <Row>
        <PackageSummarySection title="Adres zamieszkania:" className="col-8">
          <PackageSummarySectionDataRow title="Ulica:">
            {data.street} nr domu: {data.street_house} nr. mieszkania: {data.street_apartment}
          </PackageSummarySectionDataRow>
          <PackageSummarySectionDataRow title="Miasto:">
            {data.city?.value} {data.postcode} poczta: {data.post?.value}
          </PackageSummarySectionDataRow>
          <PackageSummarySectionDataRow title="Powiat:">{data.district?.value}</PackageSummarySectionDataRow>
          <PackageSummarySectionDataRow title="Gmina:">{data.community?.value}</PackageSummarySectionDataRow>
          <PackageSummarySectionDataRow title="Województwo:">{data.province?.value}</PackageSummarySectionDataRow>
        </PackageSummarySection>

        {data.different_address && (
          <PackageSummarySection title="Adres zameldowania" className="col-8">
            <PackageSummarySectionDataRow title="Ulica:">
              {data.registered_street} nr domu: {data.registered_street_house} nr. mieszkania:{' '}
              {data.registered_street_apartment}
            </PackageSummarySectionDataRow>
            <PackageSummarySectionDataRow title="Miasto:">
              {data.registered_city?.value} {data.registered_postcode} poczta: {data.registered_post?.value}
              {data.registered_street_apartment}
            </PackageSummarySectionDataRow>
            <PackageSummarySectionDataRow title="Powiat:">
              {data.registered_district?.value}
            </PackageSummarySectionDataRow>
            <PackageSummarySectionDataRow title="Gmina:">
              {data.registered_community?.value}
            </PackageSummarySectionDataRow>
            <PackageSummarySectionDataRow title="Województwo:">
              {data.registered_province?.value}
            </PackageSummarySectionDataRow>
          </PackageSummarySection>
        )}
      </Row>
      <div className="dropdown-divider" />
      <Row>
        <PackageSummarySection title="Dane finansowe" className="col-8">
          <PackageSummarySectionDataRow title="Oddział NFZ:">
            {data.national_health_fund_branch}
          </PackageSummarySectionDataRow>
          <PackageSummarySectionDataRow title="Urząd skarbowy:">{data.tax_office?.value}</PackageSummarySectionDataRow>
          <PackageSummarySectionDataRow title="Nazwa banku:">{data.bank_name}</PackageSummarySectionDataRow>
          <PackageSummarySectionDataRow title="Numer rachunku bankowego:">
            {formatIban(data.account_number)}
          </PackageSummarySectionDataRow>
        </PackageSummarySection>
      </Row>
    </div>
  )
}
