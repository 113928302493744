import * as React from 'react'
import classNames from 'classnames'

interface ReceptionBookingFeedingExpandColProps {
  toggleExpand: () => void
  isExpanded: boolean
}

export const ReceptionBookingFeedingExpandCol: React.FC<ReceptionBookingFeedingExpandColProps> = ({
  toggleExpand,
  isExpanded,
}) => (
  <div
    className="text-muted cursor-pointer d-flex justify-content-end align-items-center"
    onClick={() => toggleExpand()}
  >
    <span className="font-12">{isExpanded ? 'zwiń' : 'dostosuj'}</span>
    <i
      className={classNames('uil cursor-pointer font-18 ml-2 uil-sliders-v-alt', {
        'uil-times': isExpanded,
      })}
    />
  </div>
)
