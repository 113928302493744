import * as React from 'react'
import { ColAuto } from '@hyper/col-auto'
import { Button, Col, FormGroup, Label, Row } from 'reactstrap'
import { FormInput } from '@hyper/forms'
import { FormProvider, useForm } from 'react-hook-form'
import DateRangeInput from '@components/date/date-range-input'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { RootState, useAppSelector } from '@store/index'
import { CashRegisterStateListModalToggle } from '@modules/sale/cash-register/list/state-list-modal/toggle'
import { CashRegisterReportModalToggle } from './report-modal/toggle'
import { CashRegisterAddModalToggle } from './add-modal/toggle'
import { FormSelect } from '@hyper/forms/form-select'

export const cashRegisterTypes: CustomReactSelectOption[] = [
  { value: 'KP', label: 'KP' },
  { value: 'KW', label: 'KW' },
  { value: 'PP', label: 'wpłata pracownika' },
  { value: 'WP', label: 'wypłata pracownika' },
  { value: 'WB', label: 'Wypłata do banku KW-Bank' },
  { value: 'W2', label: 'Wypłata do kawiarni' },
  { value: 'W3', label: 'Wypłata do bistro' },
  { value: 'P2', label: 'Wpłata z kawiarni' },
  { value: 'P3', label: 'Wpłata z bistro' },
  { value: 'W4', label: 'Wypłata do beachbar' },
  { value: 'W5', label: 'Wypłata do słodki kącik' },
  { value: 'WG', label: 'Wypłata Gastro' },
  { value: 'P4', label: 'Wpłata z beachbar' },
  { value: 'P5', label: 'Wpłata z słodki kącik' },
  { value: 'PG', label: 'Wpłata pracownika gastro' },
]

export interface CashRegisterListFilterParams {
  user?: CustomReactSelectOption
  company?: CustomReactSelectOption
  created_after?: Date
  created_before?: Date
  type?: CustomReactSelectOption
  amount?: string
  search: string
  ordering: string
  page: number
  page_size: number
}

interface Props {
  resortId: number
  defaultFilters: CashRegisterListFilterParams
  filters: CashRegisterListFilterParams
  setFilters: (filters: CashRegisterListFilterParams) => void
}

export const CashRegisterListFilter: React.FC<Props> = ({ resortId, defaultFilters, filters, setFilters }) => {
  const users = useAppSelector((state: RootState) => state.saleState.cashRegistersExtraData.users).map(
    ({ id, value }) => ({
      value: id,
      label: value,
    }),
  )
  const companies = useAppSelector((state: RootState) => state.saleState.cashRegistersExtraData.company).map(
    ({ id, value }) => ({
      value: id,
      label: value,
    }),
  )

  const methods = useForm<CashRegisterListFilterParams>({
    defaultValues: defaultFilters,
  })

  const handleReset = () => {
    methods.reset()
    setFilters(defaultFilters)
  }

  const onSubmit = async (payload: CashRegisterListFilterParams) => setFilters({ ...filters, ...payload })

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  return (
    <FormProvider {...methods}>
      <Row>
        <Col className="col-auto px-0">
          <FormSelect
            options={cashRegisterTypes}
            name="resort"
            label="Typ"
            selectProps={{ isClearable: true }}
            formPlainProps={{ colStyle: { width: 200 } }}
          />
        </Col>
        <Col className="col-auto px-0">
          <FormSelect
            options={users}
            name="user"
            label="Dodane przez"
            selectProps={{ isClearable: true }}
            formPlainProps={{ colStyle: { width: 200 } }}
          />
        </Col>
        <Col className="col-auto px-0">
          <FormSelect
            options={companies}
            name="company"
            label="Osoba/firma"
            selectProps={{ isClearable: true }}
            formPlainProps={{ colStyle: { width: 200 } }}
          />
        </Col>
        <ColAuto>
          <FormGroup>
            <label className="d-block">&nbsp;</label>
            <Button color="primary">Filtruj</Button>
            {methods.formState.isDirty && (
              <Button color="light" className="ml-1" type="button" onClick={handleReset}>
                Wyczyść
              </Button>
            )}
          </FormGroup>
        </ColAuto>
        <ColAuto className="ml-auto px-0 text-right">
          <Label className="d-block">&nbsp;</Label>
          <CashRegisterAddModalToggle mode="withdraw" resortId={resortId} />
          <CashRegisterAddModalToggle mode="deposit" className="ml-2" resortId={resortId} />
          <br />
          <CashRegisterStateListModalToggle className="ml-2 mt-1" />
          <CashRegisterReportModalToggle className="ml-2 mt-1" color="success" />
        </ColAuto>
      </Row>
      <Row>
        <ColAuto>
          <Label>Data</Label>
          <DateRangeInput isClearable={true} startDateName="created_after" endDateName="created_before" />
        </ColAuto>
        <ColAuto>
          <FormInput label="Kwota" colSize="col-auto" name="amount" type="number" />
        </ColAuto>
        <SearchFilter colClassName="col-md-2" />
      </Row>
    </FormProvider>
  )
}
