import * as React from 'react'
import { SubscriptionDetailsInformationRow } from '@modules/subscription/details/information/row'
import { Card, CardBody } from 'reactstrap'
import { GenericVoucherClientData } from '@modules/generic-vouchers/models'

interface Props {
  clientData: GenericVoucherClientData
}

export const GenericVoucherDetailsClient: React.FC<Props> = ({ clientData }) => (
  <Card>
    <CardBody>
      <h4 className="mt-0 text-secondary mb-3">Dane klienta</h4>
      <table className="table table-sm mb-0">
        <tbody>
          <SubscriptionDetailsInformationRow name="Imię i Nazwisko">
            {clientData.first_name} {clientData.last_name}
          </SubscriptionDetailsInformationRow>
          <SubscriptionDetailsInformationRow name="Adres e-mail">{clientData.email}</SubscriptionDetailsInformationRow>
          <SubscriptionDetailsInformationRow name="Numer telefonu">
            {clientData.phone}
          </SubscriptionDetailsInformationRow>
          <SubscriptionDetailsInformationRow name="Numer PESEL">{clientData.pesel}</SubscriptionDetailsInformationRow>
        </tbody>
      </table>
    </CardBody>
  </Card>
)
