import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { FormSelect } from '@hyper/forms/form-select'
import { ColAuto } from '@hyper/col-auto'
import { Button, Label } from 'reactstrap'
import DateRangeInput from '@components/date/date-range-input'
import { CouponsClientDetailsTableFilters } from '@modules/coupons/list/client-details/index'
import { CouponStatusesOptions } from '@modules/coupons/consts'

interface Props {
  filters: CouponsClientDetailsTableFilters
  setFilters: (filters: CouponsClientDetailsTableFilters) => void
}

export const CouponsClientDetailsFilters = ({ setFilters, filters }: Props) => {
  const methods = useForm<CouponsClientDetailsTableFilters>({ defaultValues: filters })

  const onSubmit = async (payload: CouponsClientDetailsTableFilters) => setFilters({ ...filters, ...payload })

  const handleReset = () => {
    methods.reset()
  }

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="form form-style-filter mb-2 d-flex" autoComplete="off">
        <div className="row">
          <FormSelect
            options={CouponStatusesOptions}
            name="status"
            label="Status"
            selectProps={{ isClearable: true }}
            formPlainProps={{ colSize: 'auto', colStyle: { width: 250 } }}
          />

          <div className="d-flex flex-wrap mb-2">
            <ColAuto>
              <Label>Data zakupu:</Label>
              <DateRangeInput
                isClearable={true}
                startDateName="purchase_date_after"
                endDateName="purchase_date_before"
                wrapperClassName="is-range"
              />
            </ColAuto>
            <ColAuto>
              <Label>Data ważności:</Label>
              <DateRangeInput
                isClearable={true}
                startDateName="expiration_date_after"
                endDateName="expiration_date_before"
                wrapperClassName="is-range"
              />
            </ColAuto>
            <ColAuto>
              <Label>Data wykorzystania:</Label>
              <DateRangeInput
                isClearable={true}
                startDateName="use_date_after"
                endDateName="use_date_before"
                wrapperClassName="is-range"
              />
            </ColAuto>
          </div>

          {methods.formState.isDirty && (
            <Button color="light" className="ml-1 btn-flat mt-label" type="button" onClick={handleReset}>
              Wyczyść
            </Button>
          )}
        </div>
        <div className="d-flex justify-content-end align-items-end ml-auto">
          <SearchFilter />
        </div>
      </form>
    </FormProvider>
  )
}
