import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApiRequest } from '@components/hooks/use-api-request'
import { updateHousekeepingCleaningOrderDetails } from '@store/slices/housekeeping-slice'
import { commonObjectPut } from '@store/actions/generic-actions'
import { CleaningOrder, CleaningOrderDetails } from '@modules/housekeeping/models'

export const useCleaningOrderUpdate = () => {
  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()

  const { isLoading, action: updateOrder } = useApiRequest(
    async (cleaningOrder: CleaningOrder, fields: { [key in keyof CleaningOrder]: any }, successMessage: string) => {
      dispatch(
        updateHousekeepingCleaningOrderDetails(
          await commonObjectPut<CleaningOrderDetails>(cleaningOrder.urls.details, {
            ...fields,
          }),
        ),
      )
      addSuccessNotification(successMessage)
    },
  )

  return {
    updateOrder,
    isLoading,
  }
}
