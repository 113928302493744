import * as React from 'react'
import { BeddingOrder, CleaningOrder, ImprovementOrder } from '@modules/housekeeping/models'
import { Button, ModalFooter, ModalHeader } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { SaveButton } from '@hyper/button'
import { useForm, useWatch } from 'react-hook-form'
import { commonObjectPut } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useFormRequest } from '@components/hooks/use-api-request'
import { BaseModalProps } from '@components/modals/types'
import { Form } from '@hyper/forms/form'
import { createSelectOption, extractSelectOptionsValues, getById } from '@helpers/utils'
import { FormCheckbox } from '@hyper/forms/form-checkbox'
import { FormSelect } from '@hyper/forms/form-select'
import { CustomReactSelectOption, makeDefaultSelectOption } from '@components/custom-react-select'
import { FormPlainError } from '@hyper/forms'
import { handleFormSubmit } from '@helpers/forms'
import { hasHousekeepingWorkerRoles, HousekeepingWorkerRoles } from '@modules/housekeeping/users/utils'
import { useHousekeepingCleaningCompanies } from '@modules/housekeeping/users/use-housekeeping-cleaning-companies'
import {
  HousekeepingOrdersToAssignBox,
  HousekeepingServiceKind,
} from '@modules/housekeeping/cleaning-orders/common/orders-to-assign-box'

const UserKey = 'user-'

interface Props<T> extends BaseModalProps {
  order: CleaningOrder | BeddingOrder | ImprovementOrder
  onOrderUpdate: (order: T) => void
  onlyHprCompany?: boolean
  workerRoles?: HousekeepingWorkerRoles[]
  serviceKind?: HousekeepingServiceKind
}

interface FormInputs {
  company: CustomReactSelectOption | null
}

export const HousekeepingCleaningOrderAssignModal = <T,>({
  order,
  toggleIsVisible,
  onOrderUpdate,
  onlyHprCompany,
  workerRoles = [],
  serviceKind,
}: Props<T>) => {
  const { addSuccessNotification } = useNotificationHook()

  const housekeeping_companies = useHousekeepingCleaningCompanies(order.resort)
  const companies = housekeeping_companies.filter(row => (onlyHprCompany ? row.is_hpr_company : true))

  const companiesOptions = companies.map(company => createSelectOption(company.name, company.id))

  const methods = useForm<FormInputs>({
    defaultValues: {
      company: makeDefaultSelectOption(companiesOptions, order.company_id),
      ...order.users.reduce((cum, userId) => ({ ...cum, [`${UserKey}${userId}`]: true }), {}),
    },
  })

  const company = useWatch({ control: methods.control, name: 'company' })

  const workers = React.useMemo(
    () =>
      (getById(companies, company?.value)?.workers ?? []).filter(worker =>
        workerRoles?.length ? hasHousekeepingWorkerRoles(worker, workerRoles) : true,
      ),
    [companies, company?.value],
  )

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    const updatedOrder = await commonObjectPut<T>(order.urls.assign, {
      ...extractSelectOptionsValues(payload),
      ...('service_kind' in order && { service_kind: order.service_kind }),
      users: Object.entries(payload).reduce(
        (cum, [key, value]) => (key.startsWith(UserKey) && value ? [...cum, key.split(UserKey)[1]] : cum),
        [],
      ),
    })

    onOrderUpdate(updatedOrder)

    addSuccessNotification('Zlecenia zostały przypisane')
    toggleIsVisible()
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader className="px-3" toggle={toggleIsVisible}>
        Przydziel zadanie
      </ModalHeader>
      <ModalBody className="pt-0 px-3">
        <HousekeepingOrdersToAssignBox orders={[order]} serviceKind={serviceKind} />
        <FormSelect
          label="Wybierz firmę"
          name="company"
          options={companiesOptions}
          formPlainProps={{ colClassName: 'px-0' }}
        />
        {workers.length ? (
          <>
            <p className="font-weight-bold mt-3 mb-1">Wybierz pracowników</p>
            {workers.map(worker => (
              <FormCheckbox
                key={worker.id}
                name={`${UserKey}${worker.id}`}
                label={worker.name}
                value={true}
                className="mb-1"
              />
            ))}
          </>
        ) : (
          <p>Brak dostępnych pracowników</p>
        )}
        <FormPlainError name="users" />
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton
          className="btn btn-green"
          label="Przydziel"
          labelSaving="Przydzielanie.."
          isSaving={isLoading}
          onClick={handleFormSubmit(methods, onSubmit)}
        />
      </ModalFooter>
    </Form>
  )
}
