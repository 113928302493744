import Table from '@components/table/table'
import * as React from 'react'
import { SubscriptionContractPeriod, SubscriptionContractPeriodInvoice } from '@modules/subscription-contract/models'
import { toDefaultDateFormat } from '@helpers/date-helper'
import classNames from 'classnames'
import { PaymentTableRowPayment } from '@modules/subscription-contract/details/payements/payment-table-row-payment'
import { PaymentTableRowPaymentAction } from '@modules/subscription-contract/details/payements/payment-table-row-payment-action'

interface Props {
  lp: number
  period: SubscriptionContractPeriod
}

export const PaymentTableRow = ({ lp, period }: Props): JSX.Element => (
  <Table.Row>
    <Table.Cell className="vertical-align-middle">{lp}.</Table.Cell>
    <Table.Cell className="vertical-align-middle">
      <ul className="list-unstyled p-0 m-0">
        {period.invoices.map((invoice: SubscriptionContractPeriodInvoice) => (
          <li className="mb-1" key={invoice.id}>
            {invoice.invoice_number}
          </li>
        ))}
      </ul>
    </Table.Cell>
    <Table.Cell className="vertical-align-middle">
      <span
        className={classNames('badge', {
          'badge-success-lighten': period.status === 'paid',
          'badge-danger-lighten': period.status === 'expired',
          'badge-warning-lighten': period.status === 'unpaid',
        })}
      >
        {period.status_display}
      </span>
    </Table.Cell>
    <Table.Cell className="vertical-align-middle">{toDefaultDateFormat(period.payment_date)}</Table.Cell>
    <PaymentTableRowPayment period={period} />
    <PaymentTableRowPaymentAction period={period} />
  </Table.Row>
)
