import * as React from 'react'
import { Alert } from 'reactstrap'

export const ReceptionBookingNewFeedingSummaryBestsellerAlert = (): JSX.Element => (
  <Alert className="py-1 px-1 alert-info ">
    <strong className="font-12">Uwaga! </strong>
    <span className="font-12">
      W przypadku nadania dodatkowego rabatu na posiłek zawierający się w <strong>Bestsellerze</strong>
      <strong className="feeding__bestseller ml-1">B</strong>, przyjmuje się kolejno: przeliczenie ceny jednostkowej w
      ramach promocji Bestseller, następnie odliczenie od niej rabatu.
    </span>
  </Alert>
)
