import * as React from 'react'
import { TechnicalOrderDetails } from '@models/technical-orders'
import { getTechnicalOrderStatusColor } from '@modules/technical-orders/helpers'
import { IconWithText } from '@components/icon-with-text'
import { toDefaultDateFormat, toDefaultDateTimeFormat } from '@helpers/date-helper'
import { getTechnicalOrdersTodoRowUserBy } from '@modules/technical-orders/technical-orders-todo/row-user-by'
import { TechnicalOrderPreviewModalRow } from '@modules/technical-orders/technical-order-preview-modal/row'
import classNames from 'classnames'
import { useAppSelector } from '@store/index'
import { resortsMapSelector } from '@store/selectors/dashboard'
import { useAppData } from '@components/hooks/use-app-data'
import { useTechnicalOrdersAppData } from '@modules/technical-orders/hooks/use-technical-orders-app-data'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'

interface Props {
  technicalOrderDetails: TechnicalOrderDetails
}

export const TechnicalOrderPreviewModalContent: React.FC<Props> = ({ technicalOrderDetails }) => {
  const resort = useAppSelector(resortsMapSelector)[technicalOrderDetails.resort_id]
  const user = useAuthenticatedUser()
  const { units } = useAppData()
  const { technical_order_types } = useTechnicalOrdersAppData()

  const technicalOrderType = React.useMemo(
    () => technical_order_types.find(u => u.id === technicalOrderDetails.technical_order_type_id),
    [technical_order_types, technicalOrderDetails.technical_order_type_id],
  )
  const unit = React.useMemo(
    () => units.find(u => u.id === technicalOrderDetails.unit_id),
    [units, technicalOrderDetails.unit_id],
  )

  return (
    <>
      <TechnicalOrderPreviewModalRow label="Status">
        <span
          className={classNames('badge text-lowercase', `badge-${getTechnicalOrderStatusColor(technicalOrderDetails)}`)}
        >
          {technicalOrderDetails.status_display}
        </span>
        {user.is_superuser && (
          <a href={technicalOrderDetails.urls.admin} className="ml-2 text-secondary">
            (admin)
          </a>
        )}
      </TechnicalOrderPreviewModalRow>
      <TechnicalOrderPreviewModalRow label="Data dodania">
        <IconWithText icon="uil-calendar-alt" text={toDefaultDateTimeFormat(technicalOrderDetails.created)} />
      </TechnicalOrderPreviewModalRow>
      <TechnicalOrderPreviewModalRow label="Zgłaszający">
        <IconWithText
          icon="uil-user"
          text={getTechnicalOrdersTodoRowUserBy({
            user: technicalOrderDetails.created_by,
            booking: technicalOrderDetails.created_by_booking,
          })}
        />
      </TechnicalOrderPreviewModalRow>
      <TechnicalOrderPreviewModalRow label="Ośrodek">
        <IconWithText icon="uil-location-point" text={resort.name} />
      </TechnicalOrderPreviewModalRow>
      <TechnicalOrderPreviewModalRow label="Miejsce wystąpienia usterki">
        <IconWithText icon="uil-estate" text={unit?.name} />
      </TechnicalOrderPreviewModalRow>
      <TechnicalOrderPreviewModalRow label="Nazwa usterki">
        <IconWithText icon="uil-bookmark" text={technicalOrderDetails.name} />
      </TechnicalOrderPreviewModalRow>
      <TechnicalOrderPreviewModalRow label="Typ usterki">
        {technicalOrderType?.name || 'Typ usterki do wskazania'}
      </TechnicalOrderPreviewModalRow>
      <TechnicalOrderPreviewModalRow label="Zadanie priorytetowe">
        {technicalOrderDetails.priority ? (
          <IconWithText direction="right" icon="uil-rocket text-danger font-16 ml-1" text="Tak" textClass="fw-bold" />
        ) : (
          'Nie'
        )}
      </TechnicalOrderPreviewModalRow>
      <TechnicalOrderPreviewModalRow label="Zadanie wymaga akceptacji">
        {technicalOrderDetails.is_supervisor_acceptance_required ? (
          <IconWithText direction="right" text="Tak" icon="uil-user-check" iconSize="font-16 ml-1 text-green-dark" />
        ) : (
          'Nie'
        )}
      </TechnicalOrderPreviewModalRow>
      <TechnicalOrderPreviewModalRow label="Możliwość wizyty">
        {technicalOrderDetails.enter_where_guest_absence ? (
          <>
            <IconWithText
              text="Pod nieobecność gości"
              icon="uil-chat-bubble-user"
              direction="right"
              iconSize="font-16 ml-1 text-danger"
            />
            {technicalOrderDetails.technical_order_enter_where_guest_absence_range.map(row => (
              <div key={row.id} className="d-block mt-1">
                <IconWithText
                  key={row.id}
                  text={`${toDefaultDateFormat(row.date)}, ${row.time_to.substring(0, 5)} - ${row.time_to.substring(0, 5)}`}
                  icon="uil-calendar-alt"
                  direction="right"
                  iconSize="font-16 ml-1 text-danger"
                />
              </div>
            ))}
          </>
        ) : (
          'Brak'
        )}
      </TechnicalOrderPreviewModalRow>
    </>
  )
}
