import * as React from 'react'
import { TechnicalOrder } from '@models/technical-orders'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { TechnicalOrdersTodoOptions } from '@modules/technical-orders/technical-orders-todo/options'
import { Col } from 'reactstrap'
import { useSelectableRows } from '@components/table/use-selectable-rows'
import { TechnicalOrdersInProgressTableRow } from '@modules/technical-orders/technical-orders-in-progress/row'
import { useGetTechnicalOrdersQuery } from '@api/technical-orders'
import { TechnicalOrdersTodoFilters } from '@modules/technical-orders/technical-orders-todo/filters'
import { TechnicalOrderTableUnitHeader } from '@modules/technical-orders/common/table-unit-header'
import { TechnicalOrderTableTypeHeader } from '@modules/technical-orders/common/table-type-header'
import { TechnicalOrderTableUsersHeader } from '@modules/technical-orders/common/table-users-header'
import { filterTableHeaders } from '@modules/technical-orders/helpers'
import { TableViewType, OrderTableViewSwitch } from '@modules/technical-orders/common/table-view-switch'
import { TechnicalOrderTableGrupped } from '@modules/technical-orders/common/table-grupped'
import { TypedQueryResult } from '@api/base'

const defaultFilters: TableFilters = {
  search: '',
  ordering: 'kind__position,position',
  page: 1,
  page_size: 9999,
}

interface Props {
  priority: 0 | 10
  resortId: number
  dataTestId: string
}
const emptyList = []

export const TechnicalOrdersInProgressTable: React.FC<Props> = ({ dataTestId, priority, resortId }) => {
  const [filters, setFilters] = React.useState<TableFilters>(defaultFilters)
  const [tableView, setTableView] = React.useState<TableViewType>('list')

  const { data: technicalOrders = emptyList } = useGetTechnicalOrdersQuery({
    resort: resortId,
    status: 'in_progress',
    priority,
    ...filters,
  }) as TypedQueryResult<TechnicalOrder[]>

  const headers: TableHeaderValue[] = React.useMemo(
    () =>
      filterTableHeaders(
        [
          { title: 'Zespół', sortField: 'staff' },
          { title: 'Nr zadania', sortField: 'number', width: 165 },
          { title: 'Lokalizacja', sortField: 'resort_id', width: 150 },
          { title: <TechnicalOrderTableUnitHeader resortId={resortId} setFilters={setFilters} />, width: 150 },
          { title: 'Czas pracy', width: 180 },
          { title: <TechnicalOrderTableTypeHeader setFilters={setFilters} />, width: 250 },
          {
            title: <TechnicalOrderTableUsersHeader setFilters={setFilters} resortId={resortId} />,
            sortField: 'users',
            width: 200,
          },
          { title: '', width: 70 },
          { title: '' },
        ],
        resortId,
        tableView,
      ),
    [resortId, tableView],
  )

  const { selectableHeader, selectableCell, clearSelectedRows, selectedRows } = useSelectableRows<TechnicalOrder>(
    technicalOrders,
    [],
    {
      tableHeaderClassName: 'bg-white border-top-0 vertical-align-middle',
      selectableCellClassName: 'vertical-align-middle',
    },
  )

  return (
    <>
      <TechnicalOrdersTodoFilters filters={filters} setFilters={setFilters} defaultFilters={defaultFilters}>
        <Col md={6}>
          <TechnicalOrdersTodoOptions
            tab="in_progress"
            clearSelectedRows={clearSelectedRows}
            selectedRows={selectedRows}
          />
        </Col>
        <OrderTableViewSwitch tableView={tableView} setTableView={setTableView} />
      </TechnicalOrdersTodoFilters>
      <Table
        headerClassName="bg-white border-top-0 vertical-align-middle"
        className="w-100 table-fixed"
        headerValues={headers}
        isLoading={false}
        striped={tableView === 'list'}
        filters={filters}
        setFilters={setFilters}
        showPagination={false}
        selectableHeader={tableView === 'list' && selectableHeader}
        data-testid={dataTestId}
      >
        {tableView === 'list' ? (
          technicalOrders.map(technicalOrder => (
            <TechnicalOrdersInProgressTableRow
              key={technicalOrder.id}
              showResort={!resortId}
              showDragHandler={false}
              showUsers={true}
              technicalOrder={technicalOrder}
            >
              {selectableCell(technicalOrder)}
            </TechnicalOrdersInProgressTableRow>
          ))
        ) : (
          <TechnicalOrderTableGrupped
            Row={TechnicalOrdersInProgressTableRow}
            technicalOrders={technicalOrders}
            resortId={resortId}
          />
        )}
      </Table>
    </>
  )
}
