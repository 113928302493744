import * as React from 'react'
import { ReservationCard } from '@modules/reservations/details/common/reservation-card'
import { ReceptionBookingDetails } from '@models/reception'
import { ReservationRoomBillRow } from '@modules/reservations/details/reservation-room-bill/reservation-room-bill-row'
import { ReservationRoomBillFooter } from '@modules/reservations/details/reservation-room-bill/reservation-room-bill-footer'
import { ContentLoader } from '@components/content-loader'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useAppDispatch, useAppSelector } from '@store/index'
import { selectReservationWalletTransactions } from '@store/slices/reservations-slice'
import { getWalletTransactions } from '@store/actions/reservation-actions'

interface Props {
  booking: ReceptionBookingDetails
}

export const ReservationRoomPurchases = ({ booking }: Props): JSX.Element => {
  const transactions = useAppSelector(selectReservationWalletTransactions)

  const dispatch = useAppDispatch()
  const { action: fetchTransactions, isLoading } = useApiRequest(async () => {
    dispatch(await getWalletTransactions(booking))
  })

  React.useEffect(() => {
    fetchTransactions()
  }, [])

  return (
    <ReservationCard title="Rachunek zakupów na pokój">
      <ContentLoader isLoading={isLoading}>
        <table className="table table-sm">
          <colgroup>
            <col width="40%" />
          </colgroup>
          <thead>
            <tr>
              <th className="border-0" />
              <th className="border-0 pl-3">Limit</th>
              <th className="border-0 text-right">Wykorzystano</th>
              <th className="border-0" />
            </tr>
          </thead>
          <tbody>
            {transactions.map(transaction => (
              <ReservationRoomBillRow key={transaction.id} transaction={transaction} booking={booking} />
            ))}
            {!transactions.length && (
              <tr>
                <td colSpan={4}>Brak osób uprawnionych</td>
              </tr>
            )}
          </tbody>
          <ReservationRoomBillFooter transactions={transactions} />
        </table>
      </ContentLoader>
    </ReservationCard>
  )
}
