import * as React from 'react'
import { ContextMenu } from '@components/context-menu'
import { HousekeepingCleaningOrdersRowKind } from '@modules/housekeeping/cleaning-orders/common/kind'
import { HousekeepingCleaningOrderOrderedByRow } from '@modules/housekeeping/cleaning-orders/common/ordered-by'
import { CleaningOrder } from '@modules/housekeeping/models'
import { HousekeepingCleaningOrderOrderedRowContextMenuItems } from '@modules/housekeeping/cleaning-orders/ordered/context-menu/row-context-menu-items'
import { HousekeepingCleaningOrderSelectServiceKind } from '@modules/housekeeping/cleaning-orders/to-order/select-service-kind'
import { HousekeepingOrderAssignedWorkers } from '@modules/housekeeping/cleaning-orders/common/assigned-workers'
import { HousekeepingCleaningOrderApartment } from '@modules/housekeeping/cleaning-orders/to-order/row-apartment'

interface Props {
  cleaningOrder: CleaningOrder
}

export const HousekeepingCleaningOrderOrderedContextMenuTableCells = ({ cleaningOrder }: Props): JSX.Element => (
  <ContextMenu menuItems={<HousekeepingCleaningOrderOrderedRowContextMenuItems cleaningOrder={cleaningOrder} />}>
    <table className="table-borderless">
      <tbody>
        <tr className="bg-inherit">
          <HousekeepingCleaningOrderApartment cleaningOrder={cleaningOrder} showCheckedIn={true} showPriority={true} />
          <HousekeepingCleaningOrdersRowKind order={cleaningOrder} />
          <HousekeepingCleaningOrderSelectServiceKind cleaningOrder={cleaningOrder} />
          <HousekeepingOrderAssignedWorkers cleaningOrder={cleaningOrder} />

          <HousekeepingCleaningOrderOrderedByRow
            ordered_at={cleaningOrder.ordered_at}
            ordered_by={cleaningOrder.ordered_by}
          />
        </tr>
      </tbody>
    </table>
  </ContextMenu>
)
