import * as React from 'react'
import { getBenefitProgramStatusColor, getBenefitProgramStatusIcon } from '@modules/benefit-program/helpers'
import { BenefitProgramAgreement } from '@modules/benefit-program/models'
import { BadgeWithIcon } from '@components/badges/BadgeWithIcon'

interface Props {
  benefitProgramAgreement: BenefitProgramAgreement
}

export const BenefitProgramAgreementStatusBadge: React.FC<Props> = ({ benefitProgramAgreement }) => (
  <div>
    <BadgeWithIcon
      icon={getBenefitProgramStatusIcon(benefitProgramAgreement.status)}
      title={benefitProgramAgreement.status_display}
      variant={getBenefitProgramStatusColor(benefitProgramAgreement.status)}
    />
  </div>
)
