import * as React from 'react'
import { ReceptionBookingDetailsImprovement } from '@models/booking'
import { SpinInput } from '@components/spin-input'
import { useApiRequest } from '@components/hooks/use-api-request'
import { deleteBookingImprovement } from '@store/actions/reception-actions'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { setReceptionBookingDetails } from '@store/slices/reception-slice'
import { commonObjectPut } from '@store/actions/generic-actions'
import { ReceptionBookingDetails } from '@models/reception'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { useDebounce } from 'rooks'
import { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'

interface Props {
  ticketImprovement: ReceptionBookingDetailsImprovement
  canChangeAmount: boolean
  amount: number
  isReadOnly?: boolean
}

export const ReceptionBookingAddedHpfTicketImprovementActions = ({
  ticketImprovement,
  amount,
  isReadOnly,
  canChangeAmount,
}: Props): React.ReactNode => {
  const { setValue } = useFormContext()

  const { addSuccessNotification, addErrorMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading: isDeleteLoading, action: handleDelete } = useApiRequest(async (deleteReason: string) => {
    await dispatch(deleteBookingImprovement(ticketImprovement.urls.details, deleteReason))
    addSuccessNotification('Ulepszenie zostało usunięte!')
    setValue('amount', 1)
  })

  const { action: updateImprovement } = useApiRequest(async (newAmount: number) => {
    try {
      dispatch(
        setReceptionBookingDetails(
          await commonObjectPut<ReceptionBookingDetails>(ticketImprovement.urls.details, [
            { amount: newAmount, code: ticketImprovement.code },
          ]),
        ),
      )
      addSuccessNotification('Ulepszenie zostało zmienione!')
    } catch (error) {
      addErrorMessage('Wystąpił błąd', error.response.data[0].amount[0])
    }
  })

  const debouncedUpdate = useDebounce(useCallback(updateImprovement, [ticketImprovement]), 300)

  useDidUpdateEffect(() => {
    debouncedUpdate(amount)
  }, [amount])

  return (
    <>
      <SpinInput inputName="amount" inputProps={{ min: 1, disabled: !canChangeAmount || isReadOnly }} />
      <CommonObjectConfirmAction
        disabled={isReadOnly}
        title="Potwierdź usunięcie biletu HPF"
        handleAccept={handleDelete}
        isLoading={isDeleteLoading}
        message="Czy na pewno chcesz usunąć bilet HPF?"
        askForReason={true}
      >
        <i className="uil-trash-alt cursor-pointer font-18 grey-400 ml-1" role="delete-improvement" />
      </CommonObjectConfirmAction>
    </>
  )
}
