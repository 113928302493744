import * as React from 'react'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectPost } from '@store/actions/generic-actions'
import { NotificationKind } from '@models/notifications'

export interface NotificationPreview {
  content: string
  subject: string
}

interface Response {
  fetchPreview: () => Promise<void>
  send: (onSend?: () => void) => Promise<void>
  isSending: boolean
  isFetching: boolean
  preview: NotificationPreview | null
}

export const useNotificationsTemplatePreview = (
  url: string | undefined,
  notificationId: number | undefined,
  notificationKind: NotificationKind,
): Response => {
  const [preview, setPreview] = React.useState<NotificationPreview | null>(null)

  const { action: fetchPreview, isLoading: isFetching } = useApiRequest(async () => {
    if (!url) return

    const response = await commonObjectPost<NotificationPreview>(url, {
      preview: true,
      notification: notificationId,
      notification_kind: notificationKind,
    })
    setPreview(response)
  })

  const { action: send, isLoading: isSending } = useApiRequest(async (onSend?: () => void) => {
    if (!url) return
    await commonObjectPost<NotificationPreview>(url, {
      preview: false,
      notification: notificationId,
      notification_kind: notificationKind,
    })
    onSend?.()
  })

  return { fetchPreview, send, isSending, isFetching, preview }
}
