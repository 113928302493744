import * as React from 'react'
import * as R from 'ramda'
import { ReceptionBookingFeedingList } from '@modules/reception/checkin/step-feeding/reception-booking-feeding-list'
import { ReceptionBookingDetails, ReceptionBookingGroup } from '@models/reception'
import { FeedingsHandler } from '@modules/reception/checkin/step-feeding/use-feedings-with-defaults'
import { formatPriceShort, groupByField } from '@helpers/utils'
import { ReceptionBookingGuest } from '@models/booking'
import { selectCalculatedFeedingPrices } from '@store/slices/reception-slice'
import { useAppSelector } from '@store/index'

interface GroupFeeding extends ReceptionBookingGroup {
  guests: ReceptionBookingGuest[]
}

interface ReceptionBookingFeedingProps {
  booking: ReceptionBookingDetails
  feedingsHandler: FeedingsHandler
}

export const ReceptionBookingFeeding: React.FC<ReceptionBookingFeedingProps> = ({ booking, feedingsHandler }) => {
  const calculatedFeedingPrices = useAppSelector(selectCalculatedFeedingPrices)
  const groupedGuests = groupByField(booking.guests, 'group_id')

  const groupsFeeding = Object.entries(groupedGuests).map(([key, value]) => ({
    ...booking.groups.find((group: ReceptionBookingGroup) => group.id === parseInt(key, 10)),
    guests: value,
  }))

  const getFeedingTotal = () => R.sum(calculatedFeedingPrices.map(data => parseFloat(data.total_price_brutto)))

  return (
    <>
      {groupsFeeding.map((feedingGroup: GroupFeeding) => (
        <>
          <div className="reception__booking-check-in__feeding mt-3" key={feedingGroup.id}>
            <div className="reception__booking-check-in__feeding__list">
              <ReceptionBookingFeedingList
                groupId={feedingGroup.id}
                booking={booking}
                feedingsHandler={feedingsHandler}
                guests={feedingGroup.guests}
                isSingleGroup={groupsFeeding.length === 1}
              />
            </div>
          </div>
          {booking.feeding_price_list_date ? (
            <div className="text-muted font-12">
              Wyżywienie liczone według polityki rabatowej z dnia: {booking.feeding_price_list_date}
            </div>
          ) : (
            <div className="text-muted font-12">Wyżywienie liczone według bieżącej polityki rabatowej</div>
          )}
        </>
      ))}
      {groupsFeeding.length > 1 && (
        <>
          <hr />
          <div className="d-flex justify-content-between">
            <strong className="d-block font-14">Wyżywienie razem:</strong>
            <strong className="d-block text-danger font-18">{formatPriceShort(getFeedingTotal())}</strong>
          </div>
        </>
      )}
    </>
  )
}
