import * as React from 'react'
import Table from '@components/table/table'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { useModal } from '@components/modals/use-modal'
import { AccountUsersListTableRowAcquire } from '@modules/account/users/list/table-row-acquire'
import { ReceptionistUser } from '@models/account'
import { useAppData } from '@components/hooks/use-app-data'
import { ReceptionistUsersListTableRowActive } from '@modules/account/receptionist-users/list/table-row-active'
import { NavigationPath } from '@models/routes'

interface Props {
  user: ReceptionistUser
}

export const ReceptionistUsersListTableRow: React.FC<Props> = ({ user }) => {
  const [handleEdit] = useModal('ReceptionistUserModal', { user })
  const [handleChangePassword] = useModal('AccountUserChangePassword', { url: user.urls.change_password })

  const { resorts } = useAppData()

  const userResort = React.useMemo(
    () =>
      resorts
        .filter(row => user.resorts.includes(row.id))
        .map(row => row.name)
        .join(', '),
    [resorts, user.resorts],
  )
  return (
    <Table.Row>
      <Table.Cell>
        {user.first_name} {user.last_name}
      </Table.Cell>
      <Table.Cell>{user.email}</Table.Cell>
      <Table.Cell>{userResort}</Table.Cell>
      <Table.Cell>{user.phone}</Table.Cell>
      <ReceptionistUsersListTableRowActive user={user} />
      <Table.Cell>{toDefaultDateTimeFormat(user.last_login)}</Table.Cell>
      <Table.Cell className="text-right">
        <i
          className="uil-keyhole-square-full cursor-pointer text-muted font-18 mr-2"
          title="Zmień hasło"
          onClick={handleChangePassword}
        />
        {user.is_active && (
          <AccountUsersListTableRowAcquire returnUrl={NavigationPath.ReceptionistUsers} acquire={user.urls.acquire} />
        )}

        <i className="uil-pen cursor-pointer text-muted font-18" onClick={handleEdit} />
      </Table.Cell>
    </Table.Row>
  )
}
