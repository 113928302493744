import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { ReceptionBookingDetails } from '@models/reception'
import { SaveButton } from '@hyper/button'
import { ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { useFormRequest } from '@components/hooks/use-api-request'
import { useAppDispatch, useAppSelector } from '@store/index'
import { updateReceptionBookingDetails } from '@store/actions/reception-actions'
import { commonObjectPut } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { FormSelect } from '@hyper/forms/form-select'
import { tagsOptionsSelector } from '@store/selectors/dashboard'
import { useGroupedSources } from '@components/hooks/use-grouped-sources'
import { useGroupedSellers, useSellersOptions } from '@components/hooks/use-grouped-sellers'
import { useGroupedPromotions } from '@components/hooks/use-grouped-promotions'
import { SubscriptionCreateFormInformationRecommender } from '@modules/subscription/create/form-information-recommender'
import { Form } from '@hyper/forms/form'

interface Props extends BaseModalProps {
  booking: ReceptionBookingDetails
}

interface FormInputs {
  tags: CustomReactSelectOption[]
  promotion_marketing?: CustomReactSelectOption
  seller?: CustomReactSelectOption
  source_marketing?: CustomReactSelectOption
  recommender_client: number | null
}

export const ReservationMarketingModal: React.FC<Props> = ({ booking, toggleIsVisible }) => {
  const tagsOptions = useAppSelector(tagsOptionsSelector)
  const sourcesOptions = useGroupedSources()
  const sellersList = useSellersOptions()
  const sellers = useGroupedSellers([])
  const promotions = useGroupedPromotions()
  const methods = useForm<FormInputs>({
    defaultValues: {
      tags: tagsOptions.filter(row => booking.tags.includes(row.label)),
      source_marketing: sourcesOptions.find(row => row.value === booking.source_marketing_id),
      seller: sellersList.find(row => row.value === booking.seller_id),
      promotion_marketing: promotions.find(row => row.value === booking.promotion_marketing_id),
    },
  })
  const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()
  const { isLoading, action: onSubmit } = useFormRequest(
    async (payload: FormInputs) => {
      console.log(payload)
      dispatch(
        updateReceptionBookingDetails(
          await commonObjectPut<ReceptionBookingDetails>(booking.urls.reservation_booking_details, {
            ...payload,
            tags: payload.tags.map(row => row.label),
            seller: payload.seller?.value,
            source_marketing: payload.source_marketing?.value,
            promotion_marketing: payload.promotion_marketing?.value,
          }),
        ),
      )

      addSuccessMessage()
      toggleIsVisible()
    },

    methods.setError,
    { showGlobalError: true },
  )

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Zmiana informacji marketingowych</ModalHeader>
      <ModalBody>
        <Row>
          <FormSelect
            name="tags"
            options={tagsOptions}
            selectProps={{ isSelectMulti: true, isClearable: true }}
            label="Tag"
          />
          <FormSelect options={sellers} name="seller" label="Sprzedawca" />

          <FormSelect options={promotions} name="promotion_marketing" label="Promocja marketingowa" />
          <FormSelect options={sourcesOptions} name="source_marketing" label="Źródło marketingowe" />

          <SubscriptionCreateFormInformationRecommender colSize={12} />
        </Row>
      </ModalBody>
      <ModalFooter>
        <button type="button" onClick={toggleIsVisible} className="btn btn-light">
          Anuluj
        </button>
        <SaveButton isSaving={isLoading} className="btn btn-green" />
      </ModalFooter>
    </Form>
  )
}
