import * as React from 'react'
import Table from '@components/table/table'
import { Subscription, SubscriptionType } from '@modules/subscription/models'
import { asDecimal } from '@helpers/utils'
import { useModal } from '@components/modals/use-modal'
import { useUpsellBookingDetailsRefresh } from '@modules/upselling/upsell-booking-details/use-upsellbooking-details-refresh'
import { useUpsellingProducts } from '@modules/upselling/upsell-booking-details/products/use-upselling-products'
import { ACCOMMODATION_TYPES } from '@helpers/consts'

interface Props {
  subscriptions: Subscription[]
  type: SubscriptionType
  statuses: string[]
  onProductsCountChange: (count: number, type: string) => void
}

export const UpsellBookingsDetailsSubscriptionProducts = ({
  subscriptions,
  statuses,
  type,
  onProductsCountChange,
}: Props): JSX.Element => {
  const { refresh } = useUpsellBookingDetailsRefresh()
  const [showSubscriptionDetailsModal] = useModal('SubscriptionDetailsModal', {}, { onClose: refresh })

  const handleShowSubscriptionDetailsClick = (subscription: Subscription) => () => {
    showSubscriptionDetailsModal(null, { subscription })
  }

  const products = useUpsellingProducts(subscriptions, statuses, 'type', type)

  React.useEffect(() => {
    onProductsCountChange(products.length, type)
  }, [products.length])

  const getSubscriptionTotalCodes = (product: Subscription) =>
    asDecimal(product.local_mountains).plus(product.local_sea).toString()

  const getSubscriptionLeftCodes = (product: Subscription) =>
    asDecimal(getSubscriptionTotalCodes(product)).minus(product.used_codes).toString()

  const productDescription = (product: Subscription) =>
    ({
      GO_HOLIDAY: product.client_kind_display,
    })[product.type] ?? (
      <span>
        {ACCOMMODATION_TYPES.HOUSES.includes(product.accommodation_type_id) ? 'Domek' : 'Apartament'} /{' '}
        {product.kind_display}
      </span>
    )

  return (
    <>
      {products.map((subscription: Subscription) => (
        <Table.Row key={subscription.id}>
          <Table.Cell className="align-middle">
            <span className="fw-semi-bold">{subscription.type_display}</span> <br />
            <small>Numer {subscription.number}</small>
          </Table.Cell>
          <Table.Cell className="align-middle">
            Pozostało{' '}
            <span className="fw-semi-bold">
              {getSubscriptionLeftCodes(subscription)} z {getSubscriptionTotalCodes(subscription)} kodów
            </span>
          </Table.Cell>
          <Table.Cell className="align-middle">{productDescription(subscription)}</Table.Cell>
          <Table.Cell className="text-right align-middle" onClick={handleShowSubscriptionDetailsClick(subscription)}>
            <i className="font-18 mr-2 uil-eye" />
          </Table.Cell>
        </Table.Row>
      ))}
    </>
  )
}
