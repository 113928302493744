import { useApiRequest } from '@components/hooks/use-api-request'
import { selectFloorHeatingAppData, setFloorHeatingAppData } from '@store/slices/floor-heating-slice'
import { commonObjectGet } from '@store/actions/generic-actions'
import { FloorHeatingAppData } from '@modules/floor-heating/models'
import * as React from 'react'
import { useAppDispatch, useAppSelector } from '@store/index'
import { useAppData } from '@components/hooks/use-app-data'

interface Response {
  isLoading: boolean
  appData: FloorHeatingAppData | null
}

export const useFloorHeatingAppData = (): Response => {
  const { urls } = useAppData()
  const floorHeatingAppData = useAppSelector(selectFloorHeatingAppData)

  const dispatch = useAppDispatch()

  const { isLoading, action: fetchFloorHeatingAppData } = useApiRequest(async () => {
    dispatch(setFloorHeatingAppData(await commonObjectGet<FloorHeatingAppData>(urls.meter.floor_heating_app_data)))
  })

  React.useEffect(() => {
    if (floorHeatingAppData !== null) return

    fetchFloorHeatingAppData()
  }, [])

  return {
    appData: floorHeatingAppData,
    isLoading,
  }
}
