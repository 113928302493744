import * as React from 'react'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { getReceptionAppData } from '@store/actions/reception-actions'
import { ReceptionAppData } from '@models/reception'
import { setReceptionAppData } from '@store/slices/reception-slice'

export const useReceptionAppData = (): ReceptionAppData => {
  const receptionAppData = useAppSelector((state: RootState) => state.receptionState.appData)
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (receptionAppData.status === 'unknown') {
      dispatch(getReceptionAppData())
      dispatch(setReceptionAppData({ ...receptionAppData, status: 'loading' }))
    }
  }, [receptionAppData.status])

  return receptionAppData
}
