import * as React from 'react'
import { FormInput, FormPlain } from '@hyper/forms'
import { FormSelect } from '@hyper/forms/form-select'
import { FormCheckbox } from '@hyper/forms/form-checkbox'
import { Row } from 'reactstrap'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { ReceptionistUser } from '@models/account'

interface Props {
  resortOptions: CustomReactSelectOption[]
  user?: ReceptionistUser
}

export const ReceptionistUserModalForm = ({ resortOptions, user }: Props): JSX.Element => (
  <Row>
    <FormInput name="first_name" label="Imię" colSize={6} />
    <FormInput name="last_name" label="Nazwisko" colSize={6} />
    {!user && (
      <>
        <FormInput name="email" type="email" label="Adres e-mail" colSize={12} />
        <FormInput name="password" type="password" label="Hasło" colSize={6} />
        <FormInput name="confirmed_password" type="password" label="Powtórz hasło" colSize={6} />
      </>
    )}

    <FormSelect options={resortOptions} name="resort" label="Ośrodek" />
    <FormInput name="phone" label="Numer telefonu" colSize={6} />
    <FormPlain name="is_active" colSize={12}>
      <FormCheckbox name="is_active" label="Aktywny" id="from_is_active" />
    </FormPlain>
  </Row>
)
