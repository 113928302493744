import * as React from 'react'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form'
import { ShopProductSelectionBox } from '@modules/shop/pos/product-selection/shop-product-selection-box'
import { ShopBox } from '@modules/shop/pos/shop-box'
import { useAppDispatch, useAppSelector } from '@store/index'
import { ShopPOSWithParamsFormInputs } from '@modules/shop/pos/shop-pos-with-params'
import { getShopProducts } from '@store/actions/shop-actions'
import { useShopAppData } from '@modules/shop/hooks/use-shop-app-data'
import { PaymentMethods } from '@modules/shop/models'
import { Apartment } from '@models/apartment'
import { getResortDetails } from '@store/actions/reception-actions'
import { shopProductsSelectorSelector } from '@store/slices/shop-slice'
import { ShopReceiptDetails } from '@modules/shop/pos/shop-receipt-details'

interface ShopContainerProps {
  resort_id: string
  availablePaymentMethods: PaymentMethods[]
  apartment?: Apartment
}

export const ShopContainer: React.FC<ShopContainerProps> = ({ resort_id, availablePaymentMethods, apartment }) => {
  const products = useAppSelector(shopProductsSelectorSelector)

  useShopAppData()

  const methods = useForm<ShopPOSWithParamsFormInputs>({
    shouldUnregister: true,
    defaultValues: {
      ticket: 0,
      onlyAvailable: true,
      selectedProduct: null,
      selectedProductOption: null,
      addedProducts: [],
      addedTickets: [],
      paymentEnabled: false,
      amount: '0',
      paymentCompleted: false,
      kind: 'receipt',
    },
  })

  const dispatch = useAppDispatch()

  React.useEffect(() => {
    dispatch(getShopProducts(resort_id))
    dispatch(getResortDetails(resort_id))
    methods.reset()
  }, [resort_id])

  const {
    append: addProduct,
    update: updateProduct,
    remove: removeProduct,
  } = useFieldArray<ShopPOSWithParamsFormInputs>({
    control: methods.control,
    name: 'addedProducts',
  })

  const [watchFieldArray, paymentEnabled, addedTickets] = useWatch({
    control: methods.control,
    name: ['addedProducts', 'paymentEnabled', 'addedTickets'],
  })

  const controlledAddedProducts = (watchFieldArray || []).filter(row => row.price_brutto)

  React.useEffect(() => {
    if (paymentEnabled && !controlledAddedProducts.length) {
      methods.setValue('paymentEnabled', false)
    }

    if (addedTickets && addedTickets.length && !controlledAddedProducts.length) {
      methods.setValue('addedTickets', [])
    }
  }, [controlledAddedProducts])

  const { remove: removeTicket, append: addTicket } = useFieldArray<ShopPOSWithParamsFormInputs>({
    control: methods.control,
    name: 'addedTickets',
  })

  return (
    <LoaderPlaceholder content={products}>
      <FormProvider {...methods}>
        {products && (
          <ShopProductSelectionBox
            updateProduct={updateProduct}
            addProduct={addProduct}
            products={products}
            resort={resort_id}
          />
        )}
        <ShopReceiptDetails
          removeTickets={removeTicket}
          addTicket={addTicket}
          addProduct={addProduct}
          removeProducts={removeProduct}
        />
        <ShopBox
          removeTicket={removeTicket}
          addTicket={addTicket}
          addedProducts={controlledAddedProducts}
          updateProduct={updateProduct}
          apartment={apartment}
          resort_id={resort_id}
          removeProduct={removeProduct}
          availablePaymentMethods={availablePaymentMethods}
        />
      </FormProvider>
    </LoaderPlaceholder>
  )
}
