import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@store/index'
import {
  PromocodeTouristVoucher,
  PromocodeTouristVoucherDetails,
} from '@modules/promotions/promocode-tourist-voucher/models'
import {
  createPromocodeTouristVoucher,
  getPromocodeTouristVoucher,
  getPromocodeTouristVouchers,
  updatePromocodeTouristVoucher,
} from '@store/actions/promocode-tourist-voucher-actions'

interface PromocodeTouristVoucherState {
  promocodeTouristVouchers: EntityState<PromocodeTouristVoucher>
  promocodeTouristVoucherDetails: PromocodeTouristVoucherDetails | undefined
}

const promocodeTouristVouchersAdapter = createEntityAdapter<PromocodeTouristVoucher>()

const initialState: PromocodeTouristVoucherState = {
  promocodeTouristVouchers: promocodeTouristVouchersAdapter.getInitialState(),
  promocodeTouristVoucherDetails: undefined,
}

export const promocodeTouristVoucherSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    updatePromocodeTouristVoucherDetails(state, action: PayloadAction<PromocodeTouristVoucherDetails>) {
      state.promocodeTouristVoucherDetails = action.payload
      promocodeTouristVouchersAdapter.upsertOne(state.promocodeTouristVouchers, action.payload)
    },
  },
  extraReducers: builder => {
    builder.addCase(getPromocodeTouristVouchers.fulfilled, (state, action) => {
      promocodeTouristVouchersAdapter.setAll(state.promocodeTouristVouchers, action.payload)
    })
    builder.addCase(createPromocodeTouristVoucher.fulfilled, (state, action) => {
      promocodeTouristVouchersAdapter.upsertOne(state.promocodeTouristVouchers, action.payload)
    })

    builder.addCase(updatePromocodeTouristVoucher.fulfilled, (state, action) => {
      state.promocodeTouristVoucherDetails = action.payload
      promocodeTouristVouchersAdapter.upsertOne(state.promocodeTouristVouchers, action.payload)
    })
    builder.addCase(getPromocodeTouristVoucher.fulfilled, (state, action) => {
      state.promocodeTouristVoucherDetails = action.payload
      promocodeTouristVouchersAdapter.upsertOne(state.promocodeTouristVouchers, action.payload)
    })
  },
})
export const { updatePromocodeTouristVoucherDetails } = promocodeTouristVoucherSlice.actions
export const { selectAll: promocodeTouristVouchersSelector } = promocodeTouristVouchersAdapter.getSelectors(
  (state: RootState) => state.promocodeTouristVoucherState.promocodeTouristVouchers,
)
export const promocodeTouristVoucherDetailsSelector = (state: RootState) =>
  state.promocodeTouristVoucherState.promocodeTouristVoucherDetails

export default promocodeTouristVoucherSlice.reducer
