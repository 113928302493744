import * as React from 'react'
import { ClientDetails } from '@models/clients'
import { Card, CardBody, Col } from 'reactstrap'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { GastroCard } from '@models/promotions'
import { ClientDetailsModalGastroCardsRow } from '@modules/crm/clients/details-modal/gastro-cards/gastro-cards-row'
import { useAppDispatch, useAppSelector } from '@store/index'
import { getClientDetails } from '@store/actions/crm-actions'
import { useSortedRows } from '@components/table/use-sorted-rows'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { gastroCardDetailsSelector } from '@store/slices/gastro-card-slice'
import { ClientDetailsModalGastroCardsTableFilters } from '@modules/crm/clients/details-modal/gastro-cards/filters'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { gastroCardsStatus } from '@modules/promotions/gastro-cards/gastro-cards-filters'
import { filterClientDetailsProductByStatus } from '@modules/crm/clients/details-modal/utils'

export interface ClientDetailsModalGastroCardFilters extends TableFilters {
  statuses: CustomReactSelectOption[]
}

const defaultFilters = {
  search: '',
  ordering: 'number',
  page: 1,
  page_size: 9999,
  statuses: gastroCardsStatus.filter(status => ['confirmed', 'initial'].includes(status.value)),
}

const headerValues: TableHeaderValue<GastroCard>[] = [
  { sortField: 'number', title: 'Nr vouchera' },
  { sortField: 'product_name', title: 'Produkt' },
  { sortField: 'sell_price', title: 'Kwota zakupu' },
  { sortField: 'status', title: 'Status' },
  { sortField: 'rest_to_use', title: 'Do wykorzystania' },
  { sortField: 'seller', title: 'Sprzedawca' },
  { sortField: 'created_by', title: 'Utworzony przez' },
]

interface Props {
  client: ClientDetails
}

export const ClientDetailsModalGastroCards: React.FC<Props> = ({ client }) => {
  const [filters, setFilters] = React.useState<ClientDetailsModalGastroCardFilters>(defaultFilters)
  const dispatch = useAppDispatch()
  const refreshClient = () => dispatch(getClientDetails(client.urls.details))

  const gastroCardDetails = useAppSelector(gastroCardDetailsSelector)

  const sortedGastroCards = useSortedRows<GastroCard>(client.gastro_cards, filters.ordering)

  const filteredGastroCards = React.useMemo(
    () => filterClientDetailsProductByStatus(sortedGastroCards, filters.statuses),
    [gastroCardDetails, filters.statuses],
  )

  useDidUpdateEffect(() => {
    refreshClient()
  }, [gastroCardDetails])

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <h4 className="mb-2 text-secondary">Vouchery Gastronomiczne</h4>
          <ClientDetailsModalGastroCardsTableFilters
            client={client}
            onClientRefresh={refreshClient}
            filters={filters}
            onFiltersChange={setFilters}
            defaultFilters={defaultFilters}
            gastroCardStatusOptions={gastroCardsStatus}
          />
          <Table
            className="table-hover w-100"
            headerValues={headerValues}
            showPagination={false}
            isLoading={false}
            filters={filters}
            emptyText="Brak voucherów gastronomicznych"
            setFilters={setFilters}
          >
            {filteredGastroCards.map(gastroCard => (
              <ClientDetailsModalGastroCardsRow key={gastroCard.id} gastroCard={gastroCard} />
            ))}
          </Table>
        </CardBody>
      </Card>
    </Col>
  )
}
