import * as React from 'react'
import { NotificationEvents } from '@models/dashboard'
import { useAppDispatch } from '@store/index'
import { getHousekeepingCleaningOrdersAggregation } from '@store/actions/housekeeping-actions'
import {
  BeddingOrderDetails,
  CleaningOrderDetails,
  CommonTask,
  HousekeepingCleaningOrderType,
  ImprovementOrderDetails,
  ReorderedTasks,
} from '@modules/housekeeping/models'
import { useWebSocket } from '@components/hooks/use-web-socket'
import {
  setHousekeepingCleaningOrders,
  updateHousekeepingBeddingOrderDetails,
  updateHousekeepingCleaningOrderDetails,
  updateHousekeepingCommonTask,
  updateHousekeepingImprovementOrderDetails,
} from '@store/slices/housekeeping-slice'

interface Props {
  resortId: number
  tab: HousekeepingCleaningOrderType
}

export const HousekeepingCleaningOrdersWebSocketHandler: React.FC<Props> = ({ resortId, tab }) => {
  const dispatch = useAppDispatch()

  useWebSocket<CleaningOrderDetails | BeddingOrderDetails | ImprovementOrderDetails | CommonTask | ReorderedTasks>(
    (message, payload) => {
      switch (message) {
        case NotificationEvents.COMMON_TASK_CHANGE:
          dispatch(getHousekeepingCleaningOrdersAggregation(resortId))
          if (payload.resort === resortId) {
            dispatch(updateHousekeepingCommonTask(payload as CommonTask))
          }
          break
        case NotificationEvents.BEDDING_ORDER_CHANGE:
          dispatch(getHousekeepingCleaningOrdersAggregation(resortId))
          if (payload.resort === resortId) {
            dispatch(updateHousekeepingBeddingOrderDetails(payload as BeddingOrderDetails))
          }
          break

        case NotificationEvents.CLEANING_ORDER_CHANGE:
          dispatch(getHousekeepingCleaningOrdersAggregation(resortId))
          if (payload.resort === resortId) {
            dispatch(updateHousekeepingCleaningOrderDetails(payload as CleaningOrderDetails))
          }
          break
        case NotificationEvents.IMPROVEMENT_ORDER_CHANGE:
          dispatch(getHousekeepingCleaningOrdersAggregation(resortId))
          if (payload.resort === resortId) {
            dispatch(updateHousekeepingImprovementOrderDetails(payload as ImprovementOrderDetails))
          }
          break
        case NotificationEvents.CLEANING_ORDER_TASK_REORDERED:
          if (payload.resort === resortId && tab === HousekeepingCleaningOrderType.ORDERED) {
            dispatch(setHousekeepingCleaningOrders((payload as ReorderedTasks).orders))
          }
          break
      }
    },
    [resortId],
    [
      NotificationEvents.COMMON_TASK_CHANGE,
      NotificationEvents.BEDDING_ORDER_CHANGE,
      NotificationEvents.CLEANING_ORDER_CHANGE,
      NotificationEvents.IMPROVEMENT_ORDER_CHANGE,
      NotificationEvents.CLEANING_ORDER_TASK_REORDERED,
    ],
  )
  return null
}
