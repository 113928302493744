import * as React from 'react'
import timelineHelper from '@helpers/timeline-helper'
import { CalendarDate } from '@store/reducers/timeline-reducers'
import { compareAsc, eachDayOfInterval } from 'date-fns'
import { formatDate } from '@helpers/date-helper'
import { RootState, useAppSelector } from '@store/index'

interface Props {
  renderedDates: CalendarDate[]
  colHeight: string
  colWidth: string
}

export const TimelineNotRentableDaysMask: React.FC<Props> = ({ renderedDates, colWidth, colHeight }) => {
  const rentDays = useAppSelector((state: RootState) => state.timelineState.rent_days)

  const datesToCompare = React.useMemo(
    () =>
      renderedDates
        .map(date => {
          const clearDate = new Date()
          clearDate.setFullYear(date.year, date.month - 1)
          return clearDate
        })
        .sort(compareAsc),
    [renderedDates],
  )

  const datesInRange = React.useMemo(
    () =>
      eachDayOfInterval({
        start: datesToCompare[0],
        end: datesToCompare[datesToCompare.length - 1],
      }).map(date => formatDate(date)),
    [datesToCompare],
  )

  const dates = React.useMemo(
    () => datesInRange.filter(el => rentDays.some(rentDay => rentDay.date === el && rentDay.block_reservation)),
    [datesInRange, rentDays],
  )

  return (
    <div>
      {dates.map((date: string) => (
        <div
          key={date}
          className="calendar-content__not-rentable-mask"
          style={{
            width: colWidth,
            height: colHeight,
            left: timelineHelper.getSelectedColLeftOffset(renderedDates[0].year, renderedDates[0].month, date),
          }}
        />
      ))}
    </div>
  )
}
