import * as React from 'react'
import { NavigationTabs, NavigationTabsButton } from '@components/navigation-tabs'
import { ScrollableModalTab, useModalWithScrollableTabs } from '@components/hooks/use-modal-with-scrollable-tabs'

type Tab = 'data' | 'finance' | 'address' | 'position' | 'agreements'

interface Props {
  dataRef: React.RefObject<HTMLElement>
  financeRef: React.RefObject<HTMLElement>
  addressRef: React.RefObject<HTMLElement>
  positionRef: React.RefObject<HTMLElement>
  agreementsRef: React.RefObject<HTMLElement>
}

export const HrWorkerDetailsTabs: React.FC<Props> = ({
  dataRef,
  financeRef,
  addressRef,
  positionRef,
  agreementsRef,
}) => {
  const [tab, setTab] = React.useState<Tab>('data')

  const tabs: ScrollableModalTab<Tab>[] = [
    { ref: dataRef, name: 'data' },
    { ref: financeRef, name: 'finance' },
    { ref: addressRef, name: 'address' },
    { ref: positionRef, name: 'position' },
    { ref: agreementsRef, name: 'agreements' },
  ]

  const { handleTabClick } = useModalWithScrollableTabs<Tab>({ modalId: 'agreement-modal', setTab, tabs })

  const buttons: NavigationTabsButton[] = [
    { title: 'Dane pracownika', isActive: tab == 'data', onClick: handleTabClick('data') },
    { title: 'Dane stanowiska', isActive: tab == 'position', onClick: handleTabClick('position') },
    { title: 'Dane adresowe', isActive: tab == 'address', onClick: handleTabClick('address') },
    { title: 'Dane finansowe', isActive: tab == 'finance', onClick: handleTabClick('finance') },
    { title: 'Historia współpracy', isActive: tab == 'agreements', onClick: handleTabClick('agreements') },
  ]

  return <NavigationTabs buttons={buttons} />
}
