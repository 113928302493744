import * as React from 'react'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { getNotificationsEmailTemplatesData } from '@store/actions/cms-actions'
import { EmailTemplate } from '@modules/cms/models'
import { NotificationTemplatesEmailListRow } from '@modules/cms/notification-templates/email/row'
import { NotificationTemplatesEmailFilters } from '@modules/cms/notification-templates/email/filters'
import { useTableFilters } from '@components/table/use-table-filters'

const headerValues: TableHeaderValue<EmailTemplate>[] = [
  {
    title: 'Nazwa',
    sortField: 'name',
  },
  {
    title: 'Tytuł',
    sortField: 'subject',
  },
  {
    title: 'Klucz',
    sortField: 'key',
  },
  {
    title: 'Aktywny?',
    sortField: 'is_active',
  },
  { title: '' },
]

const defaultFilters: TableFilters = {
  ordering: '',
  page: 0,
  page_size: 0,
  search: '',
}

export const NotificationTemplatesEmailList: React.FC = () => {
  const dispatch = useAppDispatch()
  const emailTemplates = useAppSelector((state: RootState) => state.cmsState.emailTemplates)

  const { isLoading, filters, setFilters } = useTableFilters<TableFilters>({
    defaultFilters,
    action: async filters => await dispatch(getNotificationsEmailTemplatesData(filters)),
  })

  return (
    <>
      <NotificationTemplatesEmailFilters setFilters={setFilters} filters={filters} defaultFilters={defaultFilters} />
      <Table
        showPagination={false}
        filters={filters}
        setFilters={setFilters}
        headerValues={headerValues}
        isLoading={isLoading}
      >
        {emailTemplates.map(row => (
          <NotificationTemplatesEmailListRow key={row.id} emailTemplate={row} />
        ))}
      </Table>
    </>
  )
}
