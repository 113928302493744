import * as React from 'react'
import { Button, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { SaveButton } from '@hyper/button'
import { ClientDetails, ClientUser } from '@models/clients'
import { FormProvider, useForm } from 'react-hook-form'
import { FormInput } from '@hyper/forms'
import { useFormRequest } from '@components/hooks/use-api-request'
import { BaseModalProps } from '@components/modals/types'
import { commonObjectPost, commonObjectPut } from '@store/actions/generic-actions'
import { RootState, useAppSelector } from '@store/index'
import { AlertDanger } from '@components/alerts'

interface ClientDialogProps extends BaseModalProps {
  client?: ClientUser
  setClient: (client: ClientUser) => void
  activeFields?: (keyof FormInputs)[]
}

interface FormInputs {
  email: string
  first_name: string
  last_name: string
  phone: string
  postcode: string
  city: string
  street: string
}

export const ClientDialog: React.FC<ClientDialogProps> = ({ client, toggleIsVisible, setClient, activeFields }) => {
  const url = useAppSelector((state: RootState) => state.appState.appData.urls.clients.clients)

  const methods = useForm<FormInputs>({
    defaultValues: {
      email: client?.email,
      first_name: client?.first_name,
      last_name: client?.last_name,
      phone: client?.phone,
      postcode: client?.profile?.postcode,
      street: client?.profile?.street,
      city: client?.profile?.city,
    },
  })

  const { isLoading, action: onSubmit } = useFormRequest(async payload => {
    const newClient = client
      ? await commonObjectPut<ClientDetails>(client.urls.update, payload)
      : await commonObjectPost<ClientDetails>(url, payload)
    setClient(newClient)
    toggleIsVisible()
  }, methods.setError)

  const disabledFields: Partial<Record<keyof FormInputs, boolean>> = Object.keys(methods.getValues()).reduce(
    (acc, cur) => ({ ...acc, [cur]: activeFields && !activeFields.includes(cur as keyof FormInputs) }),
    {},
  )

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggleIsVisible}>
          {client ? (
            <>
              <span className="text-semi-strong">Edycja danych klienta</span>{' '}
              <strong className="test-strong">
                {client.name} {client.email}
              </strong>
            </>
          ) : (
            'Dodaj klienta'
          )}
        </ModalHeader>
        <ModalBody>
          <Row>
            <FormInput
              colSize={12}
              name="first_name"
              label="Imię:"
              placeholder="Wpisz imię"
              registerParams={{ required: true }}
              disabled={disabledFields.first_name}
            />
            <FormInput
              colSize={12}
              name="last_name"
              label="Nazwisko:"
              placeholder="Wpisz nazwisko"
              registerParams={{ required: true }}
              disabled={disabledFields.last_name}
            />

            <FormInput
              colSize={12}
              disabled={(client && !client.can_change_email) || disabledFields.email}
              type="email"
              name="email"
              label="Adres e-mail:"
              placeholder="Wpisz adres e-mail"
              registerParams={{ required: true }}
            />

            {client && !client.can_change_email && (
              <AlertDanger
                className="p-1 mx-2 align-items-center"
                iconClassName="lh-initial"
                wrapperClassName="align-items-center"
              >
                <p className="font-11 mb-0">Nie masz uprawnień, aby edytować adres email tego użytkownika.</p>
              </AlertDanger>
            )}

            <FormInput
              colSize={12}
              type="text"
              name="phone"
              label="Numer telefonu:"
              placeholder="(__) ___-___-___"
              registerParams={{ required: true }}
              disabled={disabledFields.phone}
            />
            <FormInput
              colSize={12}
              name="street"
              label="Adres zamieszkania:"
              placeholder="ul. Przykładowa 15"
              disabled={disabledFields.street}
            />
            <FormInput
              colSize={5}
              type="text"
              name="postcode"
              label="Kod pocztowy:"
              placeholder="__-___"
              disabled={disabledFields.postcode}
            />
            <FormInput
              colSize={7}
              name="city"
              label="Miejscowość:"
              placeholder="Wpisz miejscowość"
              disabled={disabledFields.city}
            />
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={toggleIsVisible}>
            Zamknij
          </Button>

          <SaveButton label="Zapisz" isSaving={isLoading} />
        </ModalFooter>
      </form>
    </FormProvider>
  )
}
