import * as React from 'react'
import { Card, CardBody } from 'reactstrap'
import { GastroCardsTable } from '@modules/promotions/gastro-cards/gastro-cards-table'
import { GastroCardsFilters } from '@modules/promotions/gastro-cards/gastro-cards-filters'
import { GastroCardsExportButtons } from '@modules/promotions/gastro-cards/gastro-cards-export-buttons'
import { GastroCardWebSocketHandler } from '@modules/promotions/gastro-cards/gastro-card-web-socket-handler'
import * as queryString from 'querystring'
import { useSelectableRows } from '@components/table/use-selectable-rows'
import { GastroCard } from '@models/promotions'
import { getGastroCards } from '@store/actions/gastro-card-actions'
import { gastroCardsSelector } from '@store/slices/gastro-card-slice'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { useTableFilters } from '@components/table/use-table-filters'
import { useAppDispatch, useAppSelector } from '@store/index'
import { TableFilters } from '@components/table/table'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useProductsAppData } from '@modules/products/hooks/use-products-app-data'
import { useParams } from 'react-router-dom'
import { GastroProductKind } from '@models/products'
import { useGastroProductProvider } from '@modules/products/hooks/use-gastro-product-provider'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { PageTitleWithSize } from '@hyper/page-title-with-size'
import { getGastroProductApiUrl } from '@modules/promotions/gastro-generic-product/utils'
import { useAppData } from '@components/hooks/use-app-data'

const headers = [
  { sortField: 'code', title: 'Kod' },
  { sortField: 'client__first_name', title: 'Imię i nazwisko' },
  { sortField: 'status', title: 'Status' },
  { sortField: 'sell_price', title: 'Kwota zakupu' },
  { sortField: 'rest_to_use', title: 'Pozostało do wykorzystania' },
  { sortField: 'declared_sell_price', title: 'Deklarowana kwota zakupu' },
  { title: 'Bonus' },
  { title: 'Typ' },
  { sortField: 'source_marketing', title: 'Źródło' },
  { title: 'Miejsce wykorzystania' },
  { sortField: 'sell_date', title: 'Data utworzenia' },
  { sortField: 'seller__name', title: 'Sprzedawca' },
]

export interface GastroGenericProductFiltersParams extends TableFilters {
  status: CustomReactSelectOption[]
  seller: CustomReactSelectOption | null
  source_marketing: CustomReactSelectOption | null
  tags: CustomReactSelectOption[]
  payment_date_after: Date | null
  payment_date_before: Date | null
  sell_date_after: Date | null
  sell_date_before: Date | null
  is_removed: boolean
}

export const GastroGenericProductView: React.FC = () => {
  const { kind: kindParam = 'gastro_coupon' } = useParams<'kind'>()
  const kind = kindParam.replace(/-/g, '_') as any as GastroProductKind

  const { urls } = useAppData()
  const gastroProduct = useGastroProductProvider(kind)
  const pageTitle = `Voucher ${gastroProduct?.product_name}`

  const { q } = queryString.parse(location.search.replace('?', ''))

  const defaultFilters: GastroGenericProductFiltersParams = {
    ordering: '-sell_date',
    page: 1,
    page_size: 10,
    search: String(q || ''),
    payment_date_after: null,
    payment_date_before: null,
    sell_date_after: null,
    sell_date_before: null,
    seller: null,
    source_marketing: null,
    status: [],
    tags: [],
    is_removed: false,
  }
  useMenuActive(NavigationPath.PromotionsGastroCoupon.replace(':kind', kindParam))

  useProductsAppData()

  const allCards = useAppSelector(gastroCardsSelector)

  const cards = React.useMemo(() => allCards.filter(row => row.kind === kind), [kind, allCards])

  const dispatch = useAppDispatch()

  const url = getGastroProductApiUrl(kind as GastroProductKind, urls)

  const fetchList = async filters => {
    await dispatch(getGastroCards([{ ...filters, kind }, url]))
  }

  const {
    isLoading,
    filters,
    setFilters,
    updateFiltersRequest: fetchGastroCards,
  } = useTableFilters<GastroGenericProductFiltersParams>({
    defaultFilters,
    action: fetchList,
  })

  useDidUpdateEffect(() => {
    fetchList(filters)
  }, [kind])

  const { selectableHeader, selectableCell, selectedRows, clearSelectedRows } = useSelectableRows<GastroCard>(cards)

  return (
    <>
      <GastroCardWebSocketHandler kind={kind} />
      <PageTitleWithSize title={pageTitle} />
      <Card>
        <CardBody>
          <GastroCardsFilters
            defaultFilters={defaultFilters}
            filters={filters}
            setFilters={setFilters}
            fetchGastroCards={() => fetchGastroCards(filters)}
            selectedRows={selectedRows}
            kind={kind}
            clearSelectedRows={clearSelectedRows}
          />

          <div data-testid="qa-gastro-voucher-table">
            <GastroCardsTable
              selectableHeader={selectableHeader}
              selectableCell={selectableCell}
              gastroCards={cards}
              isLoading={isLoading}
              filters={filters}
              setFilters={setFilters}
              kind={kind}
              headers={headers}
              basePath={NavigationPath.PromotionsGastroCoupon.replaceParam(':kind', kindParam)}
              pathWithParams={NavigationPath.PromotionsGastroCouponWithParams.replaceParam(':kind', kindParam)}
            />
            <GastroCardsExportButtons filters={filters} kind={kind} />
          </div>
        </CardBody>
      </Card>
    </>
  )
}
