import * as React from 'react'
import { RodoAgreement } from '@modules/crm/rodo-agreements/models'
import { IconWithText } from '@components/icon-with-text'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { SaveButton } from '@hyper/button'
import { useBenefitIssue } from '@modules/crm/rodo-agreements/benefits/use-benefit-issue'

interface Props {
  rodoAgreement: RodoAgreement
}

export const RodoAgreementsConfirmedIssueRewardCell = ({ rodoAgreement }: Props): JSX.Element => {
  const { issueBenefit, isLoading } = useBenefitIssue()

  const handleRewardIssue = async () => {
    await issueBenefit(rodoAgreement)
  }

  return (
    <td>
      {rodoAgreement.benefit_transfer_date ? (
        <IconWithText
          wrapperClassNames="mr-2"
          icon="uil-check text-green-dark font-16 mr-2"
          text={
            <div className="text-left ml-1">
              <div className="text-green-dark fw-semi-bold">Benefit wydany</div>
              <div className="text-grey font-12">w dniu {toDefaultDateFormat(rodoAgreement.benefit_transfer_date)}</div>
            </div>
          }
        />
      ) : (
        <SaveButton
          className="btn btn-green shadow-none pl-2 pr-3 ml-auto py-0"
          labelSaving={
            <IconWithText
              text="Proszę czekać..."
              icon="uil-check font-16"
              textClass="border-left h-100 ml-1 pl-2 d-flex align-items-center py-2"
            />
          }
          isSaving={isLoading}
          onClick={handleRewardIssue}
          label={
            <IconWithText
              text="Wydaj benefit"
              icon="uil-check font-16"
              textClass="border-left h-100 ml-1 pl-2 d-flex align-items-center py-2 fw-semi-bold"
            />
          }
        />
      )}
    </td>
  )
}
