import * as React from 'react'
import { Card, CardBody } from 'reactstrap'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { PageTitle } from '@hyper/page-title'
import { RodoAgreementsAcquiringPointsTable } from '@modules/crm/rodo-agreements/point-of-acquirng/table/rodo-agreements-acquiring-points-table'
import { useQueryWithPaginationResponse } from '@components/hooks/use-query-hooks'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { RodoAgreementsAcquiringPointsFilters } from '@modules/crm/rodo-agreements/point-of-acquirng/table/rodo-agreements-acquiring-points-filters'
import { BaseTableFilters } from '@components/table/use-handle-table-filters-change'
import { useGetRodoAcquiringPointsQuery } from '@api/rodo-agreements'
import { RodoAcquiringPoint } from '@modules/crm/rodo-agreements/models'

const DefaultFilters = {
  ordering: 'name',
  page: 1,
  page_size: 25,
  search: '',
  status: null,
}

export interface RodoAgreementsAcquiringPointsFiltersFormInputs extends BaseTableFilters {
  status: CustomReactSelectOption | null
}

export const RodoAgreementsAcquiringPointsView: React.FC = () => {
  useMenuActive(NavigationPath.CrmClientsBenefitsForRodo)

  const [filters, setFilters] = React.useState<RodoAgreementsAcquiringPointsFiltersFormInputs>(DefaultFilters)

  const {
    data: points,
    isLoading,
    isFetching,
  } = useQueryWithPaginationResponse<RodoAcquiringPoint[]>(useGetRodoAcquiringPointsQuery(filters))

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: 'Formularze Rodo ', path: '', active: true }]} title="Formularze RODO" />
      <Card>
        <CardBody>
          <RodoAgreementsAcquiringPointsFilters
            setFilters={setFilters}
            filters={filters}
            defaultFilters={DefaultFilters}
          />
          <RodoAgreementsAcquiringPointsTable
            points={points}
            filters={filters}
            setFilters={setFilters}
            isLoading={isLoading || isFetching}
          />
        </CardBody>
      </Card>
    </>
  )
}
