import * as React from 'react'
import { useHousekeepingAppData } from '@modules/housekeeping/hooks/use-housekeeping-app-data'
import { createSelectOption } from '@helpers/utils'
import { HousekeepingCompanyDetails } from '@modules/housekeeping/models'
import { CustomReactSelectOption } from '@components/custom-react-select'

interface Response {
  companies: HousekeepingCompanyDetails[]
  companiesOptions: CustomReactSelectOption[]
}

export const useHousekeepingTechnicalCompanies = (resortId?: number): Response => {
  const { housekeeping_companies } = useHousekeepingAppData(resortId)

  const companies = React.useMemo(
    () => housekeeping_companies.filter(company => company.type === 'technical_company'),
    [housekeeping_companies],
  )

  const companiesOptions = companies.map(company => createSelectOption(company.name, company.id))

  return { companies, companiesOptions }
}
