import * as React from 'react'
import { Button, Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useApiRequest } from '@components/hooks/use-api-request'
import { BaseModalProps } from '@components/modals/types'

interface RemoveVipDialogProps extends BaseModalProps {
  onConfirm: () => void
}

export const ReceptionBookingCheckInImprovementRemoveVipDialog: React.FC<RemoveVipDialogProps> = ({
  toggleIsVisible,
  onConfirm,
}) => {
  const { isLoading, action: handleDeleteConfirm } = useApiRequest(async () => {
    onConfirm()
    toggleIsVisible()
  })

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>Odpinanie kodu VIP</ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <span className="font-13">
              Do czasu zameldowania Gość ma możliwość odpięcia wykupionego pakietu VIP i wykorzystanie go w innej
              rezerwacji.
              <strong> Koszt odpięcia pakietu VIP podczas meldowania lub po zameldowaniu wynosi 199zł.</strong>
            </span>
            <strong className="d-block mt-2">Czy na pewno chce odpiąć pakiet VIP od tej rezerwacji?</strong>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={toggleIsVisible}>
          Anuluj
        </Button>
        <SaveButton
          type="button"
          className="btn btn-danger"
          label="Odepnij i dolicz 299zł do rachunku"
          isSaving={isLoading}
          onClick={handleDeleteConfirm}
        />
      </ModalFooter>
    </>
  )
}
