import * as React from 'react'
import { ClientDetails, ClientProfileGuest } from '@models/clients'
import { Col, Collapse, Row } from 'reactstrap'
import { useToggle } from 'rooks'
import classNames from 'classnames'
import { ButtonWithIcon } from '@components/button-with-icon'
import { useModal } from '@components/modals/use-modal'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { ClientDetailsModalClientProfileGuestsRowContactConsent } from '@modules/crm/clients/details-modal/client-profile/guests/row-contact-consent'
import { useNotificationHook } from '@hyper/use-notification-hook'

interface Props {
  guest: ClientProfileGuest
  client: ClientDetails
}

enum contactTypeEnum {
  sms = 1,
  email,
}

export const ClientDetailsModalClientProfileGuestsRow: React.FC<Props> = ({ guest, client }) => {
  const [editModel, toggleEditMode] = useToggle(false)
  const [handleEdit] = useModal('ClientProfileGuestModal', { client, guest })
  const [smsButtonDisabled, setSmsButtonDisabled] = React.useState(false)
  const [emailButtonDisabled, setEmailButtonDisabled] = React.useState(false)
  const { addSuccessNotification } = useNotificationHook()

  const handleIconClick = React.useCallback(contactType => {
    if (contactType === contactTypeEnum.sms) {
      // TODO - send SMS message
      setSmsButtonDisabled(true)
      addSuccessNotification('Wiadomość SMS została wysłana')
    } else if (contactType === contactTypeEnum.email) {
      // TODO - send Email message
      setEmailButtonDisabled(true)
      addSuccessNotification('Wiadomość E-mail została wysłana')
    }
  }, [])

  return (
    <Row className="py-2" role="client-guest-row">
      <Col md={1}>
        <div className="avatar-xs">
          <span className="avatar-title font-14 text-center rounded-circle text-body text-strong bg-grey">
            <i className="uil-user" />
          </span>
        </div>
      </Col>
      <Col>
        <strong className="d-block">{guest.name}</strong>
        <span className="font-11">
          {guest.type_display} {guest.type !== 'adult' && `(ur. ${toDefaultDateFormat(guest.birthday)})`}
        </span>
      </Col>
      {guest.type === 'adult' ? (
        <Col md={6} className="pl-0">
          <ClientDetailsModalClientProfileGuestsRowContactConsent
            label="Zgoda na kontakt E-mail"
            contact_consent={guest.rodo_email}
            contact_consent_datetime={guest.rodo_email_accepted_datetime}
          />
          <ClientDetailsModalClientProfileGuestsRowContactConsent
            label="Zgoda na kontakt telefoniczny"
            contact_consent={guest.rodo_phone}
            contact_consent_datetime={guest.rodo_phone_accepted_datetime}
          />
          {!(guest.rodo_email && guest.rodo_phone) && (
            <Collapse isOpen={editModel}>
              <Row className="ml-0 mt-2 d-flex align-items-center">
                <p className="font-10 font-weight-light mb-0 mr-2">Prośba o zgodę na marketing</p>
                <ButtonWithIcon
                  role="client-guest-row-sms-button"
                  color="btn-link"
                  disabled={smsButtonDisabled || guest.rodo_phone}
                  icon="uil-comment-alt-message"
                  iconSize="font-18"
                  text="SMS"
                  btnClass="text-gold-light mr-1 font-11 p-0"
                  handleClick={() => handleIconClick(contactTypeEnum.sms)}
                />
                <ButtonWithIcon
                  role="client-guest-row-email-button"
                  color="btn-link"
                  disabled={emailButtonDisabled || guest.rodo_email}
                  icon="uil-envelope"
                  iconSize="font-18"
                  text="E-mail"
                  btnClass="text-gold-light font-11 p-0"
                  handleClick={() => handleIconClick(contactTypeEnum.email)}
                />
              </Row>
            </Collapse>
          )}
        </Col>
      ) : (
        <Col md={5} />
      )}
      <Col md={1} className="text-right pt-1">
        <i
          role="client-guest-row-expand"
          onClick={toggleEditMode}
          className={classNames('font-16 cursor-pointer', {
            'uil-angle-right': !editModel,
            'uil-angle-down': editModel,
          })}
        />
      </Col>
      <Col md={12} className="text-center">
        <Collapse isOpen={editModel} role="client-guest-row-collapsible-element">
          <div className="pt-3">
            <ButtonWithIcon
              icon="uil-trash-alt text-danger"
              text="Usuń z listy"
              color="outline-danger"
              btnClass="mr-1 btn-sm"
            />
            <ButtonWithIcon icon="uil-pen" text="Edytuj" btnClass="btn btn-green btn-sm" handleClick={handleEdit} />
          </div>
        </Collapse>
      </Col>
    </Row>
  )
}
