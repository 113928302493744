import * as React from 'react'
import { useApiRequest } from '@components/hooks/use-api-request'
import { uploadCmsImage } from '@api/cms'
import { FileUploader, UploaderAcceptableFile } from '@components/file-uploader'

interface Props {
  setState: (state: any) => void
  url: string
  id: number
}

export const CmsReservationAppImagesUploader: React.FC<Props> = ({ url, id, setState }) => {
  const { isLoading, action: upload } = useApiRequest(async file => setState(await uploadCmsImage(url, file, id)))

  return (
    <FileUploader
      isLoading={isLoading}
      upload={upload}
      accept={UploaderAcceptableFile.Images}
      label="Przeciągnij i upuść zdjęcie lub kliknij by dodać zdjęcie"
    />
  )
}
