import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { SaveButton } from '@hyper/button'
import { FeedingWorker } from '@modules/feeding/models'
import { FeedingWorkersDialogForm } from '@modules/feeding/workers/dialog/form'
import { endOfToday, startOfToday } from 'date-fns'
import { FeedingWorkersDialogTotalPrice } from '@modules/feeding/workers/dialog/total-price'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useFormRequest } from '@components/hooks/use-api-request'
import { BaseModalProps } from '@components/modals/types'
import { updateFeedingWorkerDetails } from '@store/slices/feeding-slice'
import { commonObjectPost } from '@store/actions/generic-actions'
import { Form } from '@hyper/forms/form'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { createInvoiceData, InvoiceData } from '@components/products/product-invoice-card'
import { extractSelectOptionsValues } from '@helpers/utils'

interface FeedingWorkersDialogProps extends BaseModalProps {
  onlyCard?: boolean
}

export interface FeedingWorkersDialogFormInputs extends InvoiceData {
  name: string
  cashbox: CustomReactSelectOption | null
  product: CustomReactSelectOption | null
  payment: CustomReactSelectOption | null
  department: CustomReactSelectOption | null
  date_from: Date
  date_to: Date
  resorts: CustomReactSelectOption[] | null
}

export const FeedingWorkersDialog: React.FC<FeedingWorkersDialogProps> = ({ toggleIsVisible, onlyCard }) => {
  const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()
  const url = useAppSelector((state: RootState) => state.appState.appData.urls.feeding.workers_list)

  const methods = useForm<FeedingWorkersDialogFormInputs>({
    defaultValues: {
      date_from: startOfToday(),
      date_to: endOfToday(),
      product: null,
      department: null,
      payment: null,
      invoice_type: 'company',
    },
  })

  const { isLoading, action: onSubmit } = useFormRequest(
    async (payload: FeedingWorkersDialogFormInputs) => {
      await dispatch(
        updateFeedingWorkerDetails(
          await commonObjectPost<FeedingWorker>(url, {
            ...extractSelectOptionsValues(payload),
            ...(!!payload.invoice_nip && { ...createInvoiceData(payload), invoice: true }),
          }),
        ),
      )
      addSuccessMessage('Sukces', `Wyżywienie dla ${payload.name} zostało sprzedane`)
      toggleIsVisible()
    },
    methods.setError,
    { showGlobalError: true },
  )

  return (
    <Form onSubmit={onSubmit} methods={methods}>
      <ModalHeader toggle={toggleIsVisible}>
        <span className="text-semi-strong">Sprzedaż wyżywienia</span> <strong>pracownikowi</strong>
      </ModalHeader>
      <ModalBody>
        <FeedingWorkersDialogForm onlyCard={onlyCard} />
        <FeedingWorkersDialogTotalPrice />
      </ModalBody>
      <ModalFooter style={{ borderTop: 0 }}>
        <Button color="light" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" label="Sprzedaj" labelSaving="Sprzedawanie" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
