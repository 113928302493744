import * as React from 'react'
import { BookingImprovementCode } from '@models/booking'
import classNames from 'classnames'

interface Props {
  improvementCode: BookingImprovementCode
  className?: string
}

export const ImprovementIcon = React.memo(({ improvementCode, className }: Props) => {
  switch (improvementCode) {
    case 'helicopter_flight':
      return <i className={classNames('icon-helicopter', className)} />
    case 'animals':
      return <i className={classNames('icon-zwierzeta', className)} />
    case 'bathtub':
      return <i className={classNames('icon-wanienka', className)} />
    case 'bed':
      return <i className={`uil uil-bed ${className || ''}`} />
    case 'bike':
      return <i className={classNames('icon-rowery', className)} />
    case 'chair':
      return <i className={classNames('icon-krzeselko', className)} />
    case 'grill':
      return <i className={classNames('icon-grill', className)} />
    case 'extend_stay_12':
      return <i className={classNames('icon-wym12', className)} />
    case 'extend_stay':
      return <i className={classNames('icon-wym20', className)} />
    case 'Laundry':
      return <i className={`uil uil-refresh ${className || ''}`} />
    case 'parking2':
      return <i className={classNames('icon-parking', className)} />
    case 'parking_large':
      return <i className={classNames('icon-parking-duze', className)} />
    case 'parking_vip':
      return <i className={classNames('icon-parking-gwarantowany', className)} />
    case 'towel':
      return <i className={classNames('icon-reczniki', className)} />
    case 'washing':
      return <i className={classNames('icon-sprzatanie', className)} />
    case 'package_vip':
      return <i className={classNames('icon-vip', className)} />
    case 'apartment_select':
      return <i className={classNames('icon-lokal', className)} />
    case 'game_console':
      return <i className={classNames('icon-konsola', className)} />
    case 'microwave':
      return <i className={classNames('icon-mikrofalowka', className)} />
    case 'early_check_in_13':
      return <i className={classNames('icon-meldowanie', className)} />
    case 'coffee_machine':
      return <i className={classNames('icon-ekspress', className)} />
    case 'smartwatch_promotion':
    case 'smartwatch':
      return <i className={classNames('icon-zegarek', className)} />
    case 'terrace-sail':
      return <i className={classNames('icon-zagiel', className)} />
    case 'fan':
      return <i className={classNames('icon-wentylator', className)} />
    case 'wine_standard':
      return <i className={classNames('mdi mdi-bottle-wine-outline', className)} />
    case 'hpf_ticket':
      return <i className={classNames('uil-ticket', className)} />
    case 'subscription_booking_improvement':
      return <i className={classNames('uil-tag-alt', className)} />
    default:
      return null
  }
})
