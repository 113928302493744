import { BaseObj, BaseUrls } from '@models/base'

export interface BookingSimple extends BaseObj {
  reservation_number: string
  date_from: string
  date_to: string
}

export type BookingStatus =
  | 'unfinished'
  | 'unfinished_canceled'
  | 'initial'
  | 'initial_waiting'
  | 'initial_cancelled'
  | 'confirmed'
  | 'confirmed_canceled'
  | 'system'
  | 'fake'
  | 'blocked'
  | 'close'

export const cancelledBookingStatus: BookingStatus[] = [
  'confirmed_canceled',
  'initial_cancelled',
  'unfinished_canceled',
]

export const bookingStatusList: [string, string][] = [
  ['unfinished', 'Niedokończona'],
  ['unfinished_canceled', 'Niedokończona anulowana'],
  ['initial', 'Wstępna'],
  ['initial_waiting', 'Wstępna (w koszyku)'],
  ['initial_cancelled', 'Wstępna anulowana'],
  ['confirmed', 'Potwierdzona'],
  ['confirmed_canceled', 'Potwierdzona anulowana'],
  ['close', 'Rozliczona'],
  ['checked_in', 'Zameldowana'],
]

export interface ReceptionBookingUrls extends BaseUrls {
  api_details: string
  reservation_collision: string
  extended_stay: string
  change_apartment: string
  change_date: string
  delete_block_apartment: string | null
  booking_confirm_check_in_online_start: string
  reservation_booking_promocode: string
  reservation_booking_tourist_voucher: string
  check_out_validate: string
}

export interface ReceptionBookingDetailsUrls extends ReceptionBookingUrls {
  booking_improvement_paid_by_code: string
  booking_kiosk_send_to_signature: string
  booking_payments: string
  booking_payments_deposit_checkout: string
  booking_reset_signature: string
  calculate_fake_meter: string
  cancel_unfinished: string
  car: string
  cart: string
  check_in_restore: string
  check_in_step_access: string
  check_in_step_bands: string
  check_in_step_documents: string
  check_in_step_guest: string
  check_in_step_verification: string
  check_out_step_bill: string
  check_out_step_complete: string
  check_out_step_information: string
  check_out_step_keys: string
  check_out_step_shorten: string
  check_status: string
  client_rules: string
  client_summary_url: string
  client_wallet_details: string
  damages: string
  extended_stay: string
  feeding_availability: string
  feeding_calculator: string
  finish: string
  group: string
  improvement_availability: string
  improvement_calculation: string
  improvement_create: string
  kiosk_notification: string
  load_notes: string
  media: string
  meters: string
  notification_email: string
  notification_sms: string
  notifications: string
  package_url?: string
  payments: string
  print_all: string
  print_bill: string
  print_covid: string
  print_hpf_tickets: string
  print_parking: string
  print_rodo: string
  print_stay_card: string
  print_tourist_voucher: string
  refresh_meters: string
  reservation_booking_deposit_return_account: string
  reservation_booking_details: string
  reservation_booking_extra_charges: string
  reservation_booking_send_to_erp: string
  reservation_booking_tourist_vouchers: string
  reservation_booking_vouchers: string
  reservation_booking_vouchers_use_code: string
  reservation_feeding_calculate: string
  reservation_feeding_discounts: string
  reservation_subscription_booking_improvement: string
  reservation_feeding_sale: string
  send_email_bill: string
  smart_watch: string
  status: string
  subscription_url?: string
  transfer_deposit: string
  update: string
  wallet_transactions: string
}

export type GuestWalletScope =
  | 'cafe'
  | 'bistro'
  | 'vending_machines'
  | 'vending_machines_entertainment'
  | 'vending_machines_gastronomy'
  | 'vending_machines_parkfun'
  | 'tickets_parkfun'
  | 'shop'
  | 'reception'
  | 'feeding'
  | 'sweet_corner'
  | 'itinerant_trade'
  | 'beach_bar'
  | 'improvements'

export interface ReceptionBookingGuestWalletLimits {
  limit: number | null
  scope: GuestWalletScope
  scope_display: string
}

export interface ReceptionBookingGuestPromotion {
  id: number
  promotion: string
  promotion_name: string
}

export interface ReceptionBookingGuest {
  date_from: string
  date_to: string
  email: string
  group_id: number
  birthday: string
  id: number
  name: string
  phone: string
  rodo_email: boolean
  rodo_phone: boolean
  rodo_phone_call: boolean
  promotions: ReceptionBookingGuestPromotion[]
  wallet_limits: ReceptionBookingGuestWalletLimits[]
  type: GuestType
  type_display: string
  urls: BaseUrls
}

export type ReceptionBookingServiceRoomDataMeterKind = 'default' | 'fake' | 'disable_fake' | 'custom'

export interface ReceptionBookingServiceRoomData {
  bands: number
  bands_returned: number
  car_register_numbers: string[]
  has_cold_water_meter: boolean
  has_heat_meter: boolean
  has_hot_water_meter: boolean
  has_power_meter: boolean
  heat_costs_current: string
  heat_costs_value: string
  heat_end: string
  heat_end_datetime: string
  heat_meter_kind: ReceptionBookingServiceRoomDataMeterKind
  heat_start: string
  heat_start_datetime: string
  heat_total_current: string
  hot_water_costs: string
  hot_water_costs_current: string
  hot_water_costs_value: string
  hot_water_end: string
  hot_water_end_datetime: string
  hot_water_meter_kind: ReceptionBookingServiceRoomDataMeterKind
  hot_water_start: string
  hot_water_start_datetime: string
  hot_water_total: string
  hot_water_total_current: string
  id: number
  id_card: string
  keys: number | null
  keys_returned: number | null
  power_costs_current: string
  power_costs_value: string
  power_end: string
  power_end_datetime: string
  power_meter_kind: ReceptionBookingServiceRoomDataMeterKind
  power_start: string
  power_start_datetime: string
  power_total_current: string
  urls: BaseUrls
  water_costs_current: string
  water_costs_value: string
  water_end: string
  water_end_datetime: string
  water_meter_kind: ReceptionBookingServiceRoomDataMeterKind
  water_start: string
  water_start_datetime: string
  water_total_current: string
}

export type ParkingKind = 'parking2' | 'parking_vip' | 'parking_large'

export type BookingImprovementCode =
  | 'Laundry'
  | 'animals'
  | 'apartment_select'
  | 'bathtub'
  | 'beauty_set'
  | 'bed'
  | 'bike'
  | 'chair'
  | 'coffee_machine'
  | 'cosmetics'
  | 'early_check_in_13'
  | 'extend_stay'
  | 'extend_stay_12'
  | 'fan'
  | 'fridge_magnet'
  | 'fruits'
  | 'game_console'
  | 'grill'
  | 'helicopter_flight'
  | 'hpf_ticket'
  | 'microwave'
  | 'package_vip'
  | 'package_vip_winter'
  | 'smartwatch'
  | 'smartwatch_promotion'
  | 'sweets'
  | 'terrace-sail'
  | 'towel'
  | 'towel_vip'
  | 'washing'
  | 'wine'
  | 'wine_premium'
  | 'wine_standard'
  | 'subscription_booking_improvement'
  | ParkingKind

export interface ReceptionBookingDetailsImprovement {
  is_paid_by_client_wallet: boolean
  amount: number
  original_single_price_brutto_first_item: string
  original_single_price_brutto: string
  original_price_brutto: string
  code: BookingImprovementCode
  created_at: string
  date_from: string
  date_to: string
  guest_name: string | null
  id: number
  name: string
  price_brutto: string
  urls: {
    details: string
  }
}

export interface ImprovementAvailability {
  code: BookingImprovementCode
  is_available: boolean
  price_brutto: string
  price_brutto_first_item: string
  calculate_every_day: boolean
  price_type_2: 'per_hour' | 'per_day' | 'per_booking'
  can_change_amount: boolean
  group_name: string
  name: string
}

export interface BookingApartmentMeters {
  heat: string
  heat_is_up_to_date: boolean
  heat_reading_date: string
  heat_costs: string

  hot_water: string
  hot_water_is_up_to_date: boolean
  hot_water_reading_date: string
  hot_water_costs: string

  water: string
  water_is_up_to_date: boolean
  water_fake_meter: boolean
  water_reading_date: string
  water_costs: string

  power: string
  power_fake_meter: boolean
  power_is_up_to_date: boolean
  power_reading_date: string
  power_costs: string
}

export interface BookingDay {
  base_price_brutto: string
  date: string
  id: number
  price_brutto: string
}

export type BookingPaymentType =
  | 'installment'
  | 'installment_rest'
  | 'climatic'
  | 'climatic_correction'
  | 'deposit'
  | 'deposit_return'
  | 'deposit_used'
  | 'deposit_move'

export type BookingPaymentSource =
  | 'bon'
  | 'creditcard_preauth'
  | 'polcard'
  | 'transfer'
  | 'creditcard'
  | 'cash'
  | 'payu'
  | 'p24'
  | 'form_bank'

export type GuestType = 'adult' | 'baby' | 'child' | 'children_5_12' | 'children_3_4'

export interface BandReader {
  new_scan_flow: boolean
  id: number
  resort_id: number
  name: string
  key: string
}
export type AccommodationTypeKind = 'house' | 'apartment'

export interface AccommodationType {
  urls: BaseUrls
  id: number
  name: string
  type: AccommodationTypeKind
  type_display: string
  display_max_guests: number
}

export type ResortSlug =
  | 'centrala'
  | 'kolobrzeg'
  | 'mielno'
  | 'niechorze'
  | 'pobierowo'
  | 'rowy'
  | 'ustronie-morskie'
  | 'uzdrowisko-cieplice-zdroj'

export interface Resort {
  symfonia_cashbox_id: string
  accommodation_types: AccommodationType[]
  has_band: boolean
  has_feeding: boolean
  id: number
  map: string
  name: string
  slug: ResortSlug
  urls: BaseUrls
  housekeeping_bedding_user_id: number | null
  available_voucher_scopes: GuestWalletScope[]
}

interface ResortDetailsUrls extends BaseUrls {
  resort_housekeeping_bedding_user: string
}

interface SmartWatch {
  id: number
  uid: string
  resort_id: number
}

export interface ResortMapSector {
  id: number
  map: string
  name: string
  display_name: string
  position: number
}

export interface ResortDetails extends Resort {
  smart_watches: SmartWatch[]
  improvements: ResortImprovement[]
  reception_working_time: string
  curfew_time: string
  dinner_time: string
  urls: ResortDetailsUrls
  breakfast_time: string
  map_svg_sectors: ResortMapSector[]
  map: string
  map_svg: string
}

export interface ResortImprovement {
  can_remove: boolean
  active: boolean
  hide: boolean
  calculate_every_day: boolean
  can_change_amount: boolean
  can_change_days: boolean
  code: BookingImprovementCode
  default_value_type: 'guests' | 'one' // guests - odpowiada ilośći gości, one - jeden
  group_name: string
  id: number
  is_feeding: boolean
  last_day_ignore: boolean
  limit: number
  name: string
  price_brutto: string
  price_brutto_first_item: string // w przypadku parkingu, cena za pierwsze udoskonalenie jest inna
  price_type: 'per_amount' | 'per_apartment'
  price_type_2: 'per_hour' | 'per_day' | 'per_booking'
  unit: string
}

export interface Tablet {
  id: number
  name: string
  resort_id: number
}
