import * as React from 'react'

interface ImprovementErrorProps {
  calculationError
}

export const ReceptionBookingCheckInStepImprovementError: React.FC<ImprovementErrorProps> = ({ calculationError }) => (
  <tr>
    <td colSpan={5} className="py-0 text-danger text-semi-strong">
      <span>{calculationError}</span>
    </td>
  </tr>
)
