import * as React from 'react'
import { Button, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { BaseModalProps } from '@components/modals/types'
import { BaseHistory } from '@models/dashboard'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { FormSelect } from '@hyper/forms/form-select'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { createSelectOption } from '@helpers/utils'
import { ClientHistoryRow } from '@components/client-history/client-history-row'
import { compareAsc, compareDesc } from 'date-fns'
import { parseISODate } from '@helpers/date-helper'

const SortOptions = [
  { value: 'asc', label: 'Od najnowszej' },
  { value: 'desc', label: 'Od najstarszej' },
  { value: 'user', label: 'Użytkownik' },
]

const defaultValues = {
  sort: SortOptions[0],
  user: null,
  keyword: null,
}

interface FormInputs {
  sort: CustomReactSelectOption
  keyword: CustomReactSelectOption | null
  user: CustomReactSelectOption | null
}

interface AddSellerModalProps extends BaseModalProps {
  history: BaseHistory[]
  title: string
  icons: Record<string, string>
}

export const HistoryListModal: React.FC<AddSellerModalProps> = ({ toggleIsVisible, history, title, icons }) => {
  const [historyToDisplay, setHistoryToDisplay] = React.useState<BaseHistory[]>(history)

  const methods = useForm<FormInputs>({ defaultValues })
  const filters = useWatch({ control: methods.control }) as FormInputs

  React.useEffect(() => {
    setHistoryToDisplay(history.filter(filterHistory(filters)).toSorted(sortHistory(filters)))
  }, [filters.keyword?.value, filters.user?.value, filters.sort?.value])

  const historyUsersOptions = history.reduce((users: CustomReactSelectOption[], historyItem: BaseHistory) => {
    if (users.some(user => user.value === historyItem.author) || !historyItem.author) return users
    return [...users, createSelectOption(historyItem.author, historyItem.author)]
  }, [])

  const operationKeywordOptions = history.reduce((options: CustomReactSelectOption[], historyItem: BaseHistory) => {
    if (options.some(kind => kind.value === historyItem.keyword) || !historyItem.keyword) return options
    return [...options, createSelectOption(historyItem.keyword_display, historyItem.keyword)]
  }, [])

  return (
    <FormProvider {...methods}>
      <ModalHeader toggle={toggleIsVisible}>{title}</ModalHeader>
      <ModalBody>
        <Row>
          <FormSelect
            label="Rodzaj operacji:"
            options={operationKeywordOptions}
            name="keyword"
            formPlainProps={{ colSize: 5 }}
            selectProps={{ isClearable: true }}
          />
          <FormSelect
            label="Osoba zmieniająca:"
            options={historyUsersOptions}
            name="user"
            formPlainProps={{ colSize: 4 }}
            selectProps={{ isClearable: true }}
          />
          <FormSelect label="Sortuj:" options={SortOptions} name="sort" formPlainProps={{ colSize: 3 }} />
        </Row>
        <div
          className="light-scrollbar mr-n1 pr-1"
          style={{ maxHeight: '60vh', overflowY: 'auto', overflowX: 'hidden' }}
        >
          {historyToDisplay.map((historyEntry, index) => (
            <ClientHistoryRow history={historyEntry} key={index} icons={icons} />
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button className="d-block ml-auto" color="light" onClick={toggleIsVisible}>
          Zamknij
        </Button>
      </ModalFooter>
    </FormProvider>
  )
}

const filterHistory = (filters: FormInputs) => (history: BaseHistory) => {
  if (filters.keyword?.value && history.keyword !== filters.keyword.value) return false
  if (filters.user?.value && history.author !== filters.user.value) return false
  return true
}

const sortHistory = (filters: FormInputs) => (a: BaseHistory, b: BaseHistory) => {
  const getCreatedDate = (history: BaseHistory) => parseISODate(history.created_at) as Date

  if (filters.sort?.value === 'asc') return compareAsc(getCreatedDate(b), getCreatedDate(a))
  if (filters.sort?.value === 'desc') return compareDesc(getCreatedDate(b), getCreatedDate(a))
  if (filters.sort?.value === 'user') return (a.author ?? '').localeCompare(b.author ?? '')

  return 0
}
