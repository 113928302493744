import * as React from 'react'
import { ColAuto } from '@hyper/col-auto'
import { Button, CustomInput, FormGroup, Label, Row } from 'reactstrap'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { FormProvider, useForm } from 'react-hook-form'
import { packageStatusList, PackageType } from '@models/package'
import { useGroupedSellers } from '@components/hooks/use-grouped-sellers'
import DateRangeInput from '@components/date/date-range-input'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { PackageCreateButton } from '../create'
import { extractInnerRef } from '@helpers/forms'
import { useSubscriptionKinds } from '@modules/subscription/hooks/use-subscription-kinds'
import { FormSelect } from '@hyper/forms/form-select'

export interface PackageListFilterParams {
  status?: CustomReactSelectOption[]
  seller?: CustomReactSelectOption
  created_after?: Date
  created_before?: Date
  confirmed_at_after?: Date
  confirmed_at_before?: Date
  kind?: CustomReactSelectOption[]
  has_zoo_borysew_tickets: boolean
  has_suntago_tickets: boolean
  search: string
  ordering: string
  page: number
  page_size: number
}

interface Props {
  defaultFilters: PackageListFilterParams
  filters: PackageListFilterParams
  setFilters: (filters: PackageListFilterParams) => void
  type: PackageType
}

export const PackageListFilter: React.FC<Props> = ({ defaultFilters, filters, setFilters, type }) => {
  const methods = useForm<PackageListFilterParams>({
    defaultValues: defaultFilters,
  })
  const { subscriptionKindOptions } = useSubscriptionKinds()

  const handleReset = () => {
    methods.reset()
    setFilters(defaultFilters)
  }

  React.useEffect(() => {
    handleReset()
  }, [type])

  const onSubmit = async (payload: PackageListFilterParams) => setFilters({ ...filters, ...payload })

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  const sellers = useGroupedSellers()

  return (
    <FormProvider {...methods}>
      <Row>
        <ColAuto className="px-0">
          <FormSelect
            options={packageStatusList}
            name="status"
            label="Status"
            selectProps={{ isSelectMulti: true, isClearable: true }}
            formPlainProps={{ colStyle: { width: 330 } }}
          />
        </ColAuto>
        <ColAuto className="px-0">
          <FormSelect
            options={sellers}
            name="seller"
            label="Sprzedawca"
            formPlainProps={{ colStyle: { width: 200 } }}
          />
        </ColAuto>
        <ColAuto>
          <FormGroup>
            <label className="d-block">&nbsp;</label>
            <Button color="primary">Filtruj</Button>
            {methods.formState.isDirty && (
              <Button color="light" className="ml-1" type="button" onClick={handleReset}>
                Wyczyść
              </Button>
            )}
          </FormGroup>
        </ColAuto>

        <ColAuto className="ml-auto px-0">
          <Label className="d-block">&nbsp;</Label>
          <PackageCreateButton />
        </ColAuto>
      </Row>
      <Row>
        <ColAuto>
          <Label>Okres</Label>
          <DateRangeInput isClearable={true} startDateName="created_after" endDateName="created_before" />
        </ColAuto>
        <ColAuto>
          <Label>Data płatności</Label>
          <DateRangeInput isClearable={true} startDateName="confirmed_at_after" endDateName="confirmed_at_before" />
        </ColAuto>
        <ColAuto>
          <FormSelect
            options={subscriptionKindOptions}
            name="kind"
            label="Rodzaj pakietu"
            selectProps={{ isClearable: true, isSelectMulti: true }}
            formPlainProps={{ colStyle: { width: 330 } }}
          />
        </ColAuto>
        {type === 'hpi' && (
          <Row>
            <ColAuto>
              <FormGroup>
                <Label for="has_zoo_borysew_tickets">&nbsp;</Label>
                <CustomInput
                  type="checkbox"
                  {...extractInnerRef(methods.register('has_zoo_borysew_tickets'))}
                  id="has_zoo_borysew_tickets"
                  className="text-default mt-2"
                  label="Pokaż tylko z biletami do Zoo Borysew"
                />
              </FormGroup>
            </ColAuto>
            <ColAuto>
              <FormGroup>
                <Label for="has_suntago_tickets">&nbsp;</Label>
                <CustomInput
                  type="checkbox"
                  {...extractInnerRef(methods.register('has_suntago_tickets'))}
                  id="has_suntago_tickets"
                  className="text-default mt-2"
                  label="Pokaż tylko z biletami do Suntago"
                />
              </FormGroup>
            </ColAuto>
          </Row>
        )}
        <SearchFilter colClassName="col-md-2" />
      </Row>
    </FormProvider>
  )
}
