import * as React from 'react'
import { ClientDetails } from '@models/clients'
import { Card, CardBody, Row } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'
import { IconWithText } from '@components/icon-with-text'
import { ColAuto } from '@hyper/col-auto'
import { ClientDetailsModalClientProfileGuestsRow } from '@modules/crm/clients/details-modal/client-profile/guests/row'
import { ModalBodyHr } from '@components/modals/modal-body-hr'

interface Props {
  client: ClientDetails
}

export const ClientDetailsModalClientProfileGuests: React.FC<Props> = ({ client }) => {
  const [handleGuestAdd] = useModal('ClientProfileGuestModal', { client })

  return (
    <Card>
      <CardBody className="pb-0">
        <Row className="mb-2">
          <ColAuto>
            <h5 className="mt-0">Osoby towarzyszące</h5>
          </ColAuto>
          <ColAuto className="col-auto ml-auto text-secondary text-semi-strong">
            <IconWithText
              icon="uil-plus-square mr-1 font-16 cursor-pointer"
              text="Dodaj osobę"
              textClass="font-11 cursor-pointer"
              onClick={handleGuestAdd}
            />
          </ColAuto>
        </Row>
        {client.profile.guests.map((guest, index) => (
          <React.Fragment key={guest.id}>
            {!!index && <ModalBodyHr colClassName="p-0" />}
            <ClientDetailsModalClientProfileGuestsRow guest={guest} client={client} />
          </React.Fragment>
        ))}
      </CardBody>
    </Card>
  )
}
