import * as React from 'react'
import { Promocode, PromocodeDetails } from '@models/promocode'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectPatch } from '@store/actions/generic-actions'
import { updatePromocodeCodeDetails } from '@store/slices/promocodes-slice'
import { YesNoBadge } from '@components/badges/yes-no'

interface PromocodeGroupCodeRowIsActiveProps {
  promocode: Promocode
}

export const PromocodeCodeActiveStatus: React.FC<PromocodeGroupCodeRowIsActiveProps> = ({ promocode }) => {
  const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()

  const { isLoading, action: handleAccept } = useApiRequest(async () => {
    dispatch(
      updatePromocodeCodeDetails(
        await commonObjectPatch<PromocodeDetails>(promocode.urls.details, {
          is_active: !promocode.is_active,
        }),
      ),
    )
    addSuccessMessage('Sukces', promocode.is_active ? 'Kod został deaktywowany' : 'Kod został aktywowany')
  })

  const title = promocode.is_active ? 'Deaktywacja kodu' : 'Aktywacja kodu'
  const message = promocode.is_active
    ? 'Czy na pewno chcesz deaktywować wybrany kod?'
    : 'Czy na pewno chcesz aktywować wybrany kod'

  return (
    <CommonObjectConfirmAction title={title} handleAccept={handleAccept} message={message} isLoading={isLoading}>
      <YesNoBadge value={promocode.is_active} textYes="aktywny" textNo="niekatywny" className="cursor-pointer" />
    </CommonObjectConfirmAction>
  )
}
