import * as React from 'react'
import { FormPlain } from '@hyper/forms'
import DatePickerInput from '@components/date/date-picker-input'
import { Label, Row } from 'reactstrap'
import { FormInputTime } from '@hyper/forms/form-input-time'
import { startOfToday } from 'date-fns'

interface Props {
  index: number
}

export const TechnicalOrderFormOptionsEnterTimeRangeRow: React.FC<Props> = ({ index }) => {
  const name = `technical_order_enter_where_guest_absence_range.${index}`
  return (
    <Row>
      <FormPlain colSize={12} name={`${name}.date`}>
        <Label>Wskaż dzień</Label>
        <DatePickerInput name={`${name}.date`} minDate={startOfToday()} wrapperClassName="flex-grow-1" />
      </FormPlain>
      <FormInputTime colSize={6} name={`${name}.time_from`} label="Między godziną" />
      <FormInputTime colSize={6} name={`${name}.time_to`} label="a godziną" />
    </Row>
  )
}
