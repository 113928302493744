import * as React from 'react'
import { FormInputMasked } from '@hyper/forms/form-input-masked'
import { accountNumberMask } from '@models/crm'
import { useFormContext, useWatch } from 'react-hook-form'
import { Col } from 'reactstrap'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { FormPlainError } from '@hyper/forms/index'
import classNames from 'classnames'

interface Props {
  formName: string
  label?: string
}

export const FormInputIbanAccountNumber = ({ formName, label = 'Nr. rachunku bankowego' }: Props): JSX.Element => {
  const { setValue, control, clearErrors, formState } = useFormContext()

  const [accountNumber, accountNumberNationalCode, restAccountNumber] = useWatch({
    control,
    name: [formName, 'accountNumberNationalCode', 'restAccountNumber'],
  })

  React.useEffect(() => {
    const accountNumberArray = accountNumber.split('')
    const nationalityCode = accountNumberArray.slice(0, 2).join('')
    const restAccountNumber = accountNumberArray.slice(2, accountNumberArray.length).join('')

    setValue('accountNumberNationalCode', nationalityCode)
    setValue('restAccountNumber', restAccountNumber)
  }, [])

  const handleAccountNationalCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('accountNumberNationalCode', event.target.value.toUpperCase())
  }

  useDidUpdateEffect(() => {
    clearErrors(formName)
    setValue(formName, `${accountNumberNationalCode}${restAccountNumber}`)
  }, [accountNumberNationalCode, restAccountNumber])

  const hasError = formState.errors[formName]

  return (
    <Col md={6}>
      <label>{label}</label>
      <div className="d-flex align-items-center">
        <FormInputMasked
          labelProps={{ className: 'd-none' }}
          placeholder="PL"
          colStyle={{ maxWidth: '43px' }}
          colClassName="mt-label px-0 mt-0"
          mask={[/[a-zA-Z]/, /[a-zA-Z]/]}
          name="accountNumberNationalCode"
          inputClassName={classNames('text-uppercase letter-spacing-2 px-1 text-center', { 'border-danger': hasError })}
          onChange={handleAccountNationalCodeChange}
          inputProps={{ defaultValue: '' }}
        />

        <FormInputMasked
          labelProps={{ className: 'd-none' }}
          placeholder="__ __ ____ ____ ____ ____ ____ ____"
          colSize={11}
          mask={accountNumberMask}
          name="restAccountNumber"
          inputClassName={classNames({ 'is-invalid': hasError })}
          inputProps={{ defaultValue: '' }}
        />
      </div>
      <FormPlainError name={formName} className="mt-n2 mb-2" />
    </Col>
  )
}
