import * as React from 'react'
import { RodoAgreement } from '@modules/crm/rodo-agreements/models'
import { RodoAgreementsListRow } from '@modules/crm/rodo-agreements/benefits/list/rodo-agreements-list-row'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  rodoAgreements: RodoAgreement[]
}

export const RodoAgreementsToVerifyList = ({ rodoAgreements }: Props): JSX.Element => (
  <div className="border rounded bg-light-grey px-2">
    <h5 className="fw-semi-bold">Dane do potwierdzenia i niedostępne:</h5>
    <table className="table table-sm table-borderless client-rodo__to-verify__table">
      <tbody>
        {rodoAgreements.map(rodoAgreement => (
          <RodoAgreementsListRow
            key={rodoAgreement.id}
            rodoAgreement={rodoAgreement}
            nameClass="text-dark"
            rowClassName="client-rodo__to-verify__table__row"
          >
            <td>
              <IconWithText icon="uil-times text-dark font-16" text="Niepotwierdzony" textClass="text-dark mr-1 ml-1" />
              {/*<IconWithText*/}
              {/*  wrapperClassNames="mr-2"*/}
              {/*  icon="uil-ban font-20 mr-2"*/}
              {/*  text={*/}
              {/*    <span className="text-left d-block ml-2">*/}
              {/*      Do odbioru <br />w HP&Resort*/}
              {/*    </span>*/}
              {/*  }*/}
              {/*/>*/}
            </td>
          </RodoAgreementsListRow>
        ))}
      </tbody>
    </table>
    <div />
  </div>
)
