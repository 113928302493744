import * as React from 'react'
import { Button, CustomInput, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { FormInput, FormPlain } from '@hyper/forms'
import { SaveButton } from '@hyper/button'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useForm } from 'react-hook-form'
import { createReportTask } from '@store/actions/report-tasks-actions'
import { extractInnerRef } from '@helpers/forms'
import { useGroupedSources } from '@components/hooks/use-grouped-sources'
import { tagsOptionsSelector } from '@store/selectors/dashboard'
import { lastActivity } from '@modules/crm/clients/list-view/filters'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { BaseModalProps } from '@components/modals/types'
import { Form } from '@hyper/forms/form'
import { FormSelect } from '@hyper/forms/form-select'

interface ReportsGeneratorClientProps extends BaseModalProps {
  params?: Partial<FormInputs>
}

interface FormInputs {
  date_joined_after: Date | null
  date_joined_before: Date | null
  is_blacklisted: boolean
  source_marketing_id: any
  last_activity: any
  tags: any[]
}

export const ReportsGeneratorClient: React.FC<ReportsGeneratorClientProps> = ({ toggleIsVisible, params }) => {
  const reportUrl = useAppSelector((state: RootState) => state.appState.appData.urls.reports.report_task_clients)
  const dispatch = useAppDispatch()

  const methods = useForm<FormInputs>({
    mode: 'all',
    defaultValues: {
      source_marketing_id: null,
      last_activity: null,
      tags: [],
      ...params,
    },
  })
  const sources = useGroupedSources()
  const tags = useAppSelector(tagsOptionsSelector)

  const { isLoading, action: onSubmit } = useReportFormRequest(async (payload: FormInputs) => {
    const reportTask = await dispatch(
      createReportTask([
        reportUrl,
        {
          ...payload,
          source_marketing_id: payload?.source_marketing_id?.value,
          last_activity: payload?.last_activity?.value,
          tags: payload?.tags?.map(row => row.value),
          is_blacklisted: payload.is_blacklisted || undefined,
        },
      ]),
    )
    toggleIsVisible()
    return reportTask
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Raport klientów</ModalHeader>
      <ModalBody>
        <Row>
          <DateFromToInput
            label="Data utworzenia konta"
            startDateName="date_joined_after"
            endDateName="date_joined_before"
          />
        </Row>
        <Row>
          <FormSelect options={sources} name="source_marketing_id" label="Źródło marketingowe" />
          <FormSelect options={tags} name="tags" label="Tagi" selectProps={{ isSelectMulti: true }} />
          <FormSelect
            options={lastActivity}
            name="last_activity"
            label="Ostatnia aktywność"
            selectProps={{ isSelectMulti: true }}
          />
          <FormInput name="skip_service_charge" label="Sprzeciw wobec opłaty eksploatacyjnej" type="select">
            <option value="">---------</option>
            <option value="true">Tak</option>
            <option value="false">Nie</option>
          </FormInput>
          <FormPlain name="only_polish_customer">
            <CustomInput
              className="cursor-pointer"
              type="checkbox"
              {...extractInnerRef(methods.register('is_blacklisted'))}
              id="is_blacklisted"
              label="Tylko Gości dodanych do czarnej listy"
            />
          </FormPlain>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" label="Zleć raport" labelSaving="Zlecanie.." isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
