import * as React from 'react'

interface Props {
  isLoading: boolean
  icon: string
}

export const IconWithLoader = ({ isLoading, icon }: Props): React.ReactNode => (
  <i className={isLoading ? 'spinner-border avatar-3xs' : icon} />
)
