export enum NavigationPath {
  AccountUsersList = '/account/users/list',
  AccountingRefundBookings = '/accounting/refund/bookings/',
  AccountingTransferPackages = '/accounting/transfer-packages/',
  AuthorizationLogin = '/login',
  AuthorizationLogout = '/authorization/logout/',
  AuthorizationPiCodeLogin = '/pin-code-login',
  AuthorizationResetPassword = '/authorization/reset-password/:token/',
  AuthorizationResetPasswordLink = '/authorization/reset-password-link/',
  BarriersSpecialTickets = '/barriers/special-tickets/',
  BarriersTickets = '/barriers/tickets/',
  BookingOffers = '/booking-offers/',
  BookingOptions = '/booking-options/',
  BookingOptionsWithParams = '/booking-options/:id/',
  CafeteriaList = '/cafeteria/list/',
  CafeteriaListWithParams = '/cafeteria/list/:id/',
  CallCenterUsers = '/account/call-center-users/',
  CashRegisterList = '/sale/cash-register/list',
  CashRegisterListWithParams = '/sale/cash-register/list/:resort_id',
  CashbackVouchers = '/products/cashback/',
  CashbackVouchersWithParams = '/products/cashback/:id/',
  CmsAssetCategories = '/cms/assets/categories/',
  CmsAssets = '/cms/assets/',
  CmsReservationApp = '/cms/reservation-app/:type',
  CouponsClient = '/coupons/list/:client_id',
  CouponsList = '/coupons/summary/',
  CouponsTypes = '/coupons/types/',
  CouponsTypesConfiguration = '/coupons/types-configuration/',
  CrmClients = '/crm/clients/',
  CrmClientsBenefitsForRodo = '/crm/clients-benefits-for-rodo/',
  CrmClientsWitParams = '/crm/clients/:client_id/',
  CrmRecommendationGifts = '/crm/recommendation-gifts/',
  CrmRecommendationGiftsWithParams = '/crm/recommendation-gifts/:id/',
  EcommerceStats = '/ecommerce/stats',
  Failure = '/failure',
  FeedingClients = '/feeding/clients/',
  FeedingHost = '/feeding/host/',
  FeedingHostWithParams = '/feeding/host/:resort_id/',
  FeedingSpecialGuest = '/feeding/special-guests/',
  FeedingWorker = '/feeding/workers/',
  FloorHeating = '/floor-heating/',
  FloorHeatingDetails = '/floor-heating/:apartment_name',
  GuestsTechnicalOrders = '/housekeeping/reception/guests-technical-orders/',
  GuestsTechnicalOrdersWithParams = '/housekeeping/reception/guests-technical-orders/:resort_id/',
  GuestsTechnicalOrdersWithParamsAndId = '/housekeeping/reception/guests-technical-orders/:resort_id/:id/',
  Home = '/',
  HouseKeepingReport = '/housekeeping/report/',
  HouseKeepingUserReport = '/housekeeping/report/:user_id/:resort_id',
  HousekeepingApplications = '/housekeeping/applications/',
  HousekeepingApplicationsWithParams = '/housekeeping/applications/:type/:resort_id/',
  HousekeepingCleaningOrders = '/housekeeping/cleaning-orders/',
  HousekeepingCleaningOrdersWithParams = '/housekeeping/cleaning-orders/:resort_id/:tab/',
  HousekeepingUsers = '/housekeeping/users/',
  HrAgreementList = '/hr/agreement/',
  HrWorkersList = '/hr/agreement/workers/',
  MediaReport = '/reports/media/',
  NotificationTemplates = '/cms/notification-templates/:type',
  PackageList = '/package/list/:type',
  PackageListWithParams = '/package/list/:type/:id/',
  PackageWholesaleGastroCards = '/package-wholesale/gastro-cards/',
  PackageWholesaleGastroCards2024 = '/package-wholesale/gastro-cards-2024/',
  PackageWholesaleGastroCards2024WithParams = '/package-wholesale/gastro-cards-2024/:id/',
  PackageWholesaleGastroCards2025 = '/package-wholesale/gastro-cards-2025/',
  PackageWholesaleGastroCards2025WithParams = '/package-wholesale/gastro-cards-2025/:id/',
  PackageWholesaleGastroCardsBoost = '/package-wholesale/gastro-cards-boost/',
  PackageWholesaleGastroCardsBoostWithParams = '/package-wholesale/gastro-cards-boost/:id/',
  PackageWholesaleGastroCardsWithParams = '/package-wholesale/gastro-cards/:id/',
  PackageWholesaleList = '/package-wholesale/list/',
  PackageWholesaleListWithParams = '/package-wholesale/list/:id/',
  PackageWholesalePackages = '/package-wholesale/packages/',
  PackageWholesalePackagesWithPrams = '/package-wholesale/packages/:id/',
  PackageWholesaleProductList = '/package-wholesale/products/list/',
  PaymentRegisterCashbox = '/payments-register/cashbox/',
  PaymentRegisterCashboxDayDetails = '/payments-register/cashbox/:resort_id/:day_id/',
  PaymentRegisterCashboxWithParam = '/payments-register/cashbox/:resort_id/',
  PaymentRegisterList = '/payments-register/list/',
  PaymentRegisterUserCashbox = '/payments-register/user-cashbox/',
  PromoCodeBaseList = '/other/promocode-list/:kind/',
  PromoCodePrefixes = '/other/promocode-prefixes/',
  PromoCodePromoCodes = '/other/promocode-list/promocodes/',
  PromoCodePromoCodesList = '/other/promocode-list/promocodes/:group_id/',
  PromocodeTouristVoucher = '/promotions/promocode-tourist-voucher/',
  PromotionsErliPromocode = '/promotions/erli-promocode/',
  PromotionsGastroCards = '/promotions/gastro-cards/',
  PromotionsGastroCardsWithParams = '/promotions/gastro-cards/:id/',
  PromotionsGastroCoupon = '/promotions/:kind/',
  PromotionsGastroCouponWithParams = '/promotions/:kind/:id/',
  PromotionsPackageVip = '/promotions/package-vip/',
  PromotionsPackageVipWithParams = '/promotions/package-vip/:id/',
  Rcp = '/rcp',
  RcpUserDetail = '/rcp/user/:id/detail/',
  RcpUserEvents = '/rcp/user/:id/events/',
  Reception = '/reception/',
  ReceptionBookingCheckIn = '/reception/checkin/:booking_id', // temporary
  ReceptionBookingCheckOut = '/reception/checkout/:booking_id/:step', // temporary
  ReceptionPos = '/reception/pos/:resort?/:local?',
  ReceptionTechnicalOrders = '/housekeeping/reception/technical-orders/',
  ReceptionTechnicalOrdersWithParams = '/housekeeping/reception/technical-orders/:resort_id/',
  ReceptionTechnicalOrdersWithParamsAndId = '/housekeeping/reception/technical-orders/:resort_id/:id/',
  ReceptionWithParams = '/reception/:resort_id/:check_type',
  ReceptionistUsers = '/account/receptionist-users/',
  RentalProductCategories = '/rental/product-categories/',
  RentalProducts = '/rental/products/',
  RentalWarehouses = '/rental/warehouses/',
  ReportsPaymentsReportClimatic = '/reports/payments/climatic/',
  ReportsPaymentsReportNormal = '/reports/payments/normal/',
  ReportsReceptionSellers = '/reports/reception-sellers',
  ReportsReceptionSellersWithParams = '/reports/reception-sellers/:resort_id/',
  ReportsStatsSaleItem = '/reports/stats-sale-item/:type',
  ReportsUserReport = '/reports/user-reports/',
  ReservationCreate = '/reservations/create/', // temporary
  ReservationCreateWithParams = '/reservations/create/:token', // temporary
  ReservationDetails = '/reservations/:booking_id/details/',
  ReservationDetailsPreview = '/reservations/:booking_id/details/preview/:tab',
  ReservationImprovementsStats = '/reservations/improvement-stats/',
  ReservationList = '/reservations/list/',
  ReservationListWithParams = '/reservations/list/:type',
  ReservationStats = '/reservations/stats/',
  ReservationStatsBoard = '/reservations/stats-board/',
  Reservations = '/reservations',
  ReservationsWithParams = '/reservations/:resort_id/:accommodation_type_id',
  RodoAgreementsAcquiringPoints = '/crm/clients-benefits-for-rodo/acquiring-points/',
  SellerList = '/seller/list',
  ServiceBands = '/bands/service-bands/',
  ShopPOS = '/shop/pos',
  ShopPOSWithParams = '/shop/pos/:resort_id',
  ShopPOSWithParamsAndToken = '/shop/pos/:resort_id/:token',
  ShopWarehouseInvoices = '/shop/warehouse/invoices/',
  SubscriptionCafeteriaListWithParams = '/subscription-cafeteria/list/:id/',
  SubscriptionProductPackageListWithParams = '/product-package/packages/:id/',
  SubscriptionProductPackageList = '/product-package/packages/',
  SubscriptionContractList = '/subscription-contract/list/',
  SubscriptionContractListWithParams = '/subscription-contract/list/:id/',
  SubscriptionGoHolidayList = '/subscription-go-holiday/list',
  SubscriptionGoHolidayListWithParams = '/subscription-go-holiday/list/:id/',
  SubscriptionGoHolidayProductList = '/subscription-go-holiday/product/list',
  SubscriptionList = '/subscription/list',
  SubscriptionListWithParams = '/subscription/list/:id/',
  SubscriptionProductList = '/subscription/product/list',
  SubscriptionBookingImprovementListWithParams = '/subscription-booking-improvement/list/:id/',
  SubscriptionBookingImprovementList = '/subscription-booking-improvement/list',
  SubscriptionWithBenefit20List = '/subscription-with-benefit-20/list',
  SubscriptionWithBenefit20ListWithParams = '/subscription-with-benefit-20/list/:id/',
  SubscriptionWithBenefit20ProductList = '/subscription-with-benefit-20/product/list',
  SubscriptionWithBenefitList = '/subscription-with-benefit/list',
  SubscriptionWithBenefitListWithParams = '/subscription-with-benefit/list/:id/',
  SubscriptionWithBenefitProductList = '/subscription-with-benefit/product/list',
  TechnicalOrders = '/housekeeping/technical-orders/',
  TechnicalOrdersWithParams = '/housekeeping/technical-orders/:resort_id/:type',
  TechnicalOrdersWithParamsAndId = '/housekeeping/technical-orders/:resort_id/:type/:id/',
  Unauthorized = '/unauthorized',
  Units = '/housekeeping/units/',
  UnitsWithParams = '/housekeeping/units/:resort_id/',
  UnitsWithParamsAndId = '/housekeeping/units/:resort_id/:id/',
  UpSellBookingDetails = '/upselling/upsell-bookings/:booking_id/details',
  UpSellBookings = '/upselling/upsell-bookings/',
  UpSellBookingsOutdatedWithParams = '/upselling/upsell-bookings/outdated/:type',
  UpSellBookingsWithParams = '/upselling/upsell-bookings/:type/',
  UpSellUsers = '/upselling/users/',
  GenericVoucherList = '/generic-voucher/:kind/',
  GenericVoucherListWithParams = '/generic-voucher/:kind/:id/',
  BenefitProgramDetails = '/benefit-programs/:id/',
  BenefitProgramList = '/benefit-programs/list/',
  BenefitProgramAgreementsDetails = '/benefit-programs/agreements/:id/',
  EndlessHoliday = '/product-package/endless-holiday',
  EndlessHolidayWithParams = '/product-package/endless-holiday/:id/',
  EndlessHolidayConfiguration = '/product-package/endless-holiday/konfiguracja/',
}

declare global {
  interface String {
    replaceParam(param: string, value: string | number): string
  }
}

String.prototype.replaceParam = function (param: string, value: string | number): string {
  return this.replace(param, value)
}
