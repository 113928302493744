import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { PromocodeTouristVoucher } from '@modules/promotions/promocode-tourist-voucher/models'
import { Button, Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useApiRequest } from '@components/hooks/use-api-request'
import { promocodeTouristVoucherDetailsSelector } from '@store/slices/promocode-tourist-voucher-slice'
import { getPromocodeTouristVoucher } from '@store/actions/promocode-tourist-voucher-actions'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { PromocodeTouristVoucherDetailsTabs } from '@modules/promotions/promocode-tourist-voucher/details-dialog/tabs'
import { HistoryBox } from '@modules/promotions/common/history-box'
import { ProductEmailNotifications } from '@modules/promotions/common/notification/notifications'
import { usePromotionsAppData } from '@modules/promotions/hooks/use-promotions-app-data'
import { PromocodeTouristVoucherDetailsInfo } from '@modules/promotions/promocode-tourist-voucher/details-dialog/info'
import { PromocodeTouristVoucherDetailsCodes } from '@modules/promotions/promocode-tourist-voucher/details-dialog/codes'
import { PromocodeTouristVoucherDetailsCancel } from '@modules/promotions/promocode-tourist-voucher/details-dialog/cancel'
import { CopyToClipboard } from '@components/copy-to-clipboard'
import { ContentNotesCard } from '@components/content-notes/content-notes-card'
import { createProductCardClient, ProductClientCard } from '@components/products/product-client-card'

interface Props extends BaseModalProps {
  promocodeTouristVoucher: PromocodeTouristVoucher
}

export const PromocodeTouristVoucherDetailsDialog: React.FC<Props> = ({ promocodeTouristVoucher, toggleIsVisible }) => {
  const dispatch = useAppDispatch()
  const promocodeTouristVoucherDetails = useAppSelector(promocodeTouristVoucherDetailsSelector)
  const contentType = useAppSelector((state: RootState) => state.appState.appData.content_types.promocodetouristvoucher)
  const { promocode_tourist_voucher_notifications } = usePromotionsAppData()

  const handleRefresh = async () => await dispatch(getPromocodeTouristVoucher(promocodeTouristVoucher))

  const { isLoading, action: fetch } = useApiRequest(async () => handleRefresh())

  React.useEffect(() => {
    fetch()
  }, [])

  const dataRef = React.useRef<HTMLDivElement>(null)
  const historyRef = React.useRef<HTMLDivElement>(null)
  const codesRef = React.useRef<HTMLDivElement>(null)
  const notesRef = React.useRef<HTMLDivElement>(null)

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>
        Voucher turystyczny {promocodeTouristVoucher.code}
        <CopyToClipboard className="uil-share-alt ml-1" value={promocodeTouristVoucher.code} />
      </ModalHeader>
      <ModalBody role="modal-body" className="modal-details">
        <LoaderPlaceholder content={!isLoading}>
          {promocodeTouristVoucherDetails && (
            <>
              <PromocodeTouristVoucherDetailsTabs
                dataRef={dataRef}
                historyRef={historyRef}
                codesRef={codesRef}
                notesRef={notesRef}
              />
              <div ref={dataRef} />
              <Row>
                <Col md={6}>
                  <PromocodeTouristVoucherDetailsInfo promocodeTouristVoucherDetails={promocodeTouristVoucherDetails} />
                  <HistoryBox history={promocodeTouristVoucherDetails.history} ref={historyRef} />
                  <div ref={codesRef} />
                  <PromocodeTouristVoucherDetailsCodes
                    promocodeTouristVoucherDetails={promocodeTouristVoucherDetails}
                  />
                </Col>
                <Col md={6}>
                  <ProductClientCard clientData={createProductCardClient(promocodeTouristVoucherDetails.client)} />
                  <div ref={notesRef} />
                  <ContentNotesCard
                    readOnly={false}
                    objectId={promocodeTouristVoucherDetails.id}
                    contentType={contentType}
                    onNotesUpdate={handleRefresh}
                    notes={promocodeTouristVoucherDetails.notes}
                  />
                  <ProductEmailNotifications
                    onSuccess={handleRefresh}
                    history={promocodeTouristVoucherDetails.notifications}
                    notificationEmails={promocode_tourist_voucher_notifications}
                    url={promocodeTouristVoucherDetails.urls.notifications}
                    email={promocodeTouristVoucherDetails.client?.email || ''}
                  />
                </Col>
              </Row>
            </>
          )}
        </LoaderPlaceholder>
      </ModalBody>
      <ModalFooter className="d-block">
        <Row>
          {promocodeTouristVoucherDetails && (
            <PromocodeTouristVoucherDetailsCancel promocodeTouristVoucherDetails={promocodeTouristVoucherDetails} />
          )}
          <Col md={6} className="text-right">
            <Button color="light" onClick={toggleIsVisible}>
              Zamknij
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </>
  )
}
