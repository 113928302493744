import * as React from 'react'
import { HouseKeepingCleaningOrderAggregation } from '@modules/reports/housekeeping-users-report/models'
import { HouseKeepingUserReportStatsTableRow } from '@modules/reports/housekeeping-user-report/stats/housekeeping-user-report-stats-table-row'

interface HousekeepingUserReportStatsTableProps {
  aggregation: HouseKeepingCleaningOrderAggregation
}

export const HousekeepingUserReportStatsTable: React.FC<HousekeepingUserReportStatsTableProps> = ({ aggregation }) => (
  <table className="w-100">
    <tbody>
      <HouseKeepingUserReportStatsTableRow
        label="Domki - sprzątanie"
        time={aggregation.house_cleaning_working_time}
        amount={aggregation.house_cleaning}
      />
      <HouseKeepingUserReportStatsTableRow
        label="Domki - odświeżanie"
        time={aggregation.house_refresh_working_time}
        amount={aggregation.house_refresh}
      />
      <HouseKeepingUserReportStatsTableRow
        label="Apartamenty - sprzątanie"
        time={aggregation.apartment_cleaning_working_time}
        amount={aggregation.apartment_cleaning}
      />
      <HouseKeepingUserReportStatsTableRow
        label="Apartamenty - odświeżanie"
        time={aggregation.apartment_refresh_working_time}
        amount={aggregation.apartment_refresh}
      />
      <HouseKeepingUserReportStatsTableRow
        label="Ulepszenia"
        time={aggregation.improvement_order_working_time}
        amount={aggregation.improvement_order}
      />
      <HouseKeepingUserReportStatsTableRow
        label="Pościelowy"
        time={aggregation.bedding_order_working_time}
        amount={aggregation.bedding_order}
      />
      <HouseKeepingUserReportStatsTableRow
        label="Części wspólne"
        time={aggregation.common_task_working_time}
        amount={aggregation.common_task}
      />
    </tbody>
  </table>
)
