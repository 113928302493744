import * as React from 'react'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { RentalProductCategoriesTableRow } from '@modules/rental/product-categories/rental-product-categories-table-row'
import { RentalProductCategory } from '@modules/rental/models'

interface Props {
  productCategories: RentalProductCategory[]
  setFilters: (filters: TableFilters) => void
  filters: TableFilters
  isLoading: boolean
}

const headerValues: TableHeaderValue[] = [{ title: 'Lp.' }, { sortField: 'name', title: 'Nazwa' }, { title: '' }]

export const RentalProductCategoriesTable: React.FC<Props> = ({
  setFilters,
  productCategories,
  filters,
  isLoading,
}) => (
  <Table
    className="table-hover w-100"
    headerValues={headerValues}
    isLoading={isLoading}
    filters={filters}
    setFilters={setFilters}
  >
    {productCategories.map((productCategory: RentalProductCategory, index: number) => (
      <RentalProductCategoriesTableRow key={productCategory.id} productCategory={productCategory} index={index} />
    ))}
  </Table>
)
