import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { apiQueryFunction } from '@api/base'
import { RootState } from '@store/index'
import { PaginationResponse } from '@models/dashboard'
import { extractSelectOptionsValues } from '@helpers/utils'
import { GenericVoucher, GenericVoucherDetails, GenericVoucherKind } from '@modules/generic-vouchers/models'
import { GenericVoucherFiltersParams } from '@modules/generic-vouchers/list'

const GENERIC_VOUCHER_TAG = 'genericVoucher'
type UpdateGenericVoucherParams = { data: any; url: string; method: 'POST' | 'PUT' }

export const genericVouchersApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: '' }),
  reducerPath: 'genericVouchersApi',
  tagTypes: [GENERIC_VOUCHER_TAG],
  endpoints: builder => ({
    getGenericVouchers: builder.query<
      GenericVoucher[],
      { params: GenericVoucherFiltersParams; kind: GenericVoucherKind }
    >({
      queryFn: apiQueryFunction((state: RootState, { params, kind }) => ({
        params: {
          ...(extractSelectOptionsValues(params) || {}),
        },
        method: 'GET',
        url: state.appState.appData.urls.generic_vouchers.generic_vouchers.replace('replace-me', kind),
        responseTransformer: (response: PaginationResponse<GenericVoucher>) => response.results,
      })),
      providesTags: (result: GenericVoucher[]) =>
        result
          ? [...result.map(({ id }) => ({ type: GENERIC_VOUCHER_TAG, id }) as const), GENERIC_VOUCHER_TAG]
          : [GENERIC_VOUCHER_TAG],
    }),
    getGenericVoucherDetails: builder.query<GenericVoucherDetails, { kind: GenericVoucherKind; id: string }>({
      providesTags: (result, error, { id }) => [{ type: GENERIC_VOUCHER_TAG, id }],
      queryFn: apiQueryFunction((state, { kind, id }) => ({
        method: 'GET',
        url: state.appState.appData.urls.generic_vouchers.generic_vouchers.replace('replace-me', kind) + id + '/',
      })),
    }),
    updateGenericVoucher: builder.mutation<GenericVoucherDetails, UpdateGenericVoucherParams>({
      invalidatesTags: [GENERIC_VOUCHER_TAG],

      queryFn: apiQueryFunction((_, { data, method, url }: UpdateGenericVoucherParams) => ({
        data: extractSelectOptionsValues(data),
        method,
        url,
      })),
    }),
  }),
})

export const { useGetGenericVouchersQuery, useGetGenericVoucherDetailsQuery, useUpdateGenericVoucherMutation } =
  genericVouchersApi
