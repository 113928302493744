import * as React from 'react'
import { useDocumentTitle } from '@helpers/utils'
import { PageTitle } from '@hyper/page-title'
import { Card, CardBody } from 'reactstrap'
import { TypedQueryResult } from '@api/base'
import { useGetUpSellUsersQuery } from '@api/upSellUsers'
import { UpSellUser } from '@models/upselling'
import { TableFilters } from '@components/table/table'
import { UpSellUsersTable } from '@modules/upselling/upsell-users/table'

const defaultFilters: TableFilters = {
  search: '',
  ordering: 'name',
  page: 1,
  page_size: 25,
}

const emptyList = []

export const UpSellUsersView: React.FC = () => {
  const { data: upSellUsers = emptyList, isLoading } = useGetUpSellUsersQuery({}) as TypedQueryResult<UpSellUser[]>
  const [filters, setFilters] = React.useState<TableFilters>(defaultFilters)

  const pageTitle = `Lista pracowników (${upSellUsers.length})`
  useDocumentTitle(pageTitle)

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitle} />
      <Card>
        <CardBody>
          <UpSellUsersTable isLoading={isLoading} upSellUsers={upSellUsers} setFilters={setFilters} filters={filters} />
        </CardBody>
      </Card>
    </>
  )
}
