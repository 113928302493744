import * as React from 'react'
import { ImprovementIcon } from '@components/improvement-icon'
import { ReceptionBookingImprovementsVipDetails } from '@modules/reception/checkin/step-improvements/vip/reception-booking-improvements-vip-details'
import classnames from 'classnames'
import { ReceptionBookingDetails } from '@models/reception'
import { ReceptionBookingCheckInImprovementVipActions } from '@modules/reception/checkin/step-improvements/vip/reception-booking-check-in-improvement-vip-actions'
import { ReceptionBookingCheckInImprovementVipCodeAdded } from '@modules/reception/checkin/step-improvements/vip/reception-booking-check-in-improvement-vip-code-added'
import { ImprovementAvailability } from '@models/booking'
import { ReceptionBookingCheckInStepImprovementsGroup } from '../reception-booking-check-in-step-improvements-group'

export const VipImprovementsCodes = ['package_vip', 'package_vip_winter']

interface Props {
  booking: ReceptionBookingDetails
  improvementsAvailability: ImprovementAvailability[]
  refetchAvailability: () => Promise<void>
}

export const ReceptionBookingCheckInImprovementsVip: React.FC<Props> = ({
  booking,
  improvementsAvailability,
  refetchAvailability,
}) => {
  const availableVipImprovement = (improvementsAvailability ?? []).find(
    improvement => VipImprovementsCodes.includes(improvement.code) && improvement.is_available,
  )

  const vipImprovement = booking.improvements.find(row => VipImprovementsCodes.includes(row.code))

  const isWinterVersion =
    (vipImprovement ? vipImprovement.code : availableVipImprovement?.code) === 'package_vip_winter'

  const isImprovementActive = !!vipImprovement

  const isVipCodeAdded = (booking.package_vip?.payment_method || null) !== null

  if (!availableVipImprovement) return null

  return (
    <ReceptionBookingCheckInStepImprovementsGroup title="Pakiet ulepszeń VIP">
      <tr
        className={classnames('bg-secondary reception__booking-check-in__improvement-step__vip', {
          'bg-white shadow-none reception__booking-check-in__improvement-step__vip--auto-height ': isImprovementActive,
        })}
      >
        <td className="p-0 reception__booking-check-in__improvement-step__vip__wrapper" colSpan={4}>
          <table className="table mb-0">
            <tbody>
              <tr>
                <td className="pl-1 align-top">
                  <div className="d-flex flex-row reception__booking-check-in__improvement-step__vip__name">
                    <ImprovementIcon
                      improvementCode="package_vip"
                      className={classnames('reception__booking-check-in__improvement-step__vip__icon font-14', {
                        'reception__booking-check-in__improvement-step__vip__icon--gold': isImprovementActive,
                      })}
                    />
                    <div className={classnames('pl-1', { 'text-white': !isImprovementActive })}>
                      <span className="font-14 text-nowrap fw-semi-bold">
                        {isWinterVersion ? 'Zimowy pakiet ulepszeń VIP' : 'Pakiet ulepszeń VIP'}
                      </span>
                    </div>
                  </div>
                </td>
                {isVipCodeAdded ? (
                  <ReceptionBookingCheckInImprovementVipCodeAdded booking={booking} />
                ) : (
                  <ReceptionBookingCheckInImprovementVipActions
                    refetchAvailability={refetchAvailability}
                    vipImprovement={vipImprovement}
                    availableVipImprovement={availableVipImprovement}
                    booking={booking}
                  />
                )}
              </tr>
              <tr>
                <td
                  colSpan={6}
                  className={classnames('reception__booking-check-in__improvement-step__vip__divider', {
                    'reception__booking-check-in__improvement-step__vip__divider--light': isImprovementActive,
                  })}
                />
              </tr>
              <ReceptionBookingImprovementsVipDetails
                vipImprovement={vipImprovement}
                availableVipImprovement={availableVipImprovement}
              />
            </tbody>
          </table>
        </td>
      </tr>
    </ReceptionBookingCheckInStepImprovementsGroup>
  )
}
