import * as React from 'react'
import { useAppDispatch, useAppSelector } from '@store/index'
import { getHousekeepingCleaningOrders } from '@store/actions/housekeeping-actions'
import { CleaningOrder, HousekeepingCleaningOrderType } from '@modules/housekeeping/models'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { HousekeepingCleaningOrdersToCheckTable } from '@modules/housekeeping/cleaning-orders/to-check/table'
import { useApiRequest } from '@components/hooks/use-api-request'
import { cleaningOrdersSelector } from '@store/slices/housekeeping-slice'
import { groupByPriority } from '@modules/housekeeping/cleaning-orders/ordered/utils'
import { extractSelectOptionsValues, sortObjectListByDate } from '@helpers/utils'
import { OrderTableViewSwitch, TableViewType } from '@modules/technical-orders/common/table-view-switch'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { HousekeepingToOrderFiltersParams } from '@modules/housekeeping/cleaning-orders/to-order'
import { useHousekeepingTableFilters } from '@modules/housekeeping/hooks/use-housekeeping-table-filters'
import { HousekeepingCleaningOrderBaseFilters } from '@modules/housekeeping/cleaning-orders/common/improvements-filters'

const defaultFilters = {
  is_vip: false,
  early_check_in: false,
  extend_stay: false,
  collision_locals: false,
}

interface HousekeepingCleaningOrdersToCheckProps {
  resortId: number
}

export const HousekeepingCleaningOrdersToCheck: React.FC<HousekeepingCleaningOrdersToCheckProps> = ({ resortId }) => {
  const [tableView, setTableView] = React.useState<TableViewType>('list')
  const [filteredCleaningOrders, setFilteredCleaningOrders] = React.useState<CleaningOrder[]>([])

  const dispatch = useAppDispatch()
  const cleaningOrders = useAppSelector(cleaningOrdersSelector)

  const methods = useForm<HousekeepingToOrderFiltersParams>({ defaultValues: defaultFilters })
  const filters = useWatch({ control: methods.control })

  const { getFiltered } = useHousekeepingTableFilters()

  const { isLoading, action: fetchHousekeepingCleaningOrders } = useApiRequest(async () => {
    await dispatch(getHousekeepingCleaningOrders([{ resort: resortId }, HousekeepingCleaningOrderType.TO_CHECK]))
  })

  React.useEffect(() => {
    fetchHousekeepingCleaningOrders()
  }, [resortId])

  const { normalOrders, highPriorityOrders } = React.useMemo(
    () => groupByPriority(filteredCleaningOrders),
    [filteredCleaningOrders],
  )

  const orders = [
    ...sortObjectListByDate(highPriorityOrders, 'finished_at'),
    ...sortObjectListByDate(normalOrders, 'finished_at'),
  ]

  React.useEffect(() => {
    setFilteredCleaningOrders(
      getFiltered(
        cleaningOrders.filter(row => row.status === 'FINISHED'),
        extractSelectOptionsValues(filters),
      ),
    )
  }, [cleaningOrders, filters])

  return (
    <>
      <FormProvider {...methods}>
        <HousekeepingCleaningOrderBaseFilters className="ml-4" />
      </FormProvider>

      <LoaderPlaceholder content={!isLoading}>
        <div className="d-flex justify-content-end">
          <OrderTableViewSwitch tableView={tableView} setTableView={setTableView} />
        </div>
        <HousekeepingCleaningOrdersToCheckTable cleaningOrders={orders} tableView={tableView} />
      </LoaderPlaceholder>
    </>
  )
}
