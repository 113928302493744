import * as React from 'react'
import { Col, Row } from 'reactstrap'
import { ProductCalculatorProvider } from '@modules/shop/pos/product-calculator-provider'
import { ShopPurchaseSummary } from '@modules/shop/pos/purchase-summary/shop-purchase-summary'
import { EnhancedShopProduct, ShopTicket } from '@modules/shop/pos/models'
import { ShopPurchasePayment } from '@modules/shop/pos/purchase-payments/shop-purchase-payment'
import { useFormContext } from 'react-hook-form'
import { ShopPOSWithParamsFormInputs } from '@modules/shop/pos/shop-pos-with-params'
import { ticketFactorSelector } from '@store/selectors/shop'
import { PaymentMethods } from '@modules/shop/models'
import { Apartment } from '@models/apartment'
import { useAppSelector } from '@store/index'

interface ShopBoxProps {
  resort_id: string
  removeProduct: (index) => void
  addedProducts: EnhancedShopProduct[]
  updateProduct: (index: number, product: EnhancedShopProduct) => void
  availablePaymentMethods: PaymentMethods[]
  apartment?: Apartment
  removeTicket: (number) => void
  addTicket: ({ value, id }: { value: number; id: string }) => void
}

export const ShopBox: React.FC<ShopBoxProps> = ({
  resort_id,
  removeProduct,
  updateProduct,
  addTicket,
  removeTicket,
  addedProducts,
  availablePaymentMethods,
  apartment,
}) => {
  const ticketFactor = useAppSelector(ticketFactorSelector)

  const { watch } = useFormContext<ShopPOSWithParamsFormInputs>()

  const tickets = (watch('addedTickets') as ShopTicket[]) || []
  const room = watch('apartment')

  return (
    <Row className="mt-3 shop-box">
      <ProductCalculatorProvider
        addedProducts={addedProducts}
        tickets={tickets}
        resortId={resort_id}
        room={room}
        render={({ totalPriceBrutto, packageVipDiscount, productsCalculation }) => (
          <>
            <Col xl={8}>
              <ShopPurchaseSummary
                addTicket={addTicket}
                removeTicket={removeTicket}
                resortId={parseInt(resort_id, 10)}
                addedProducts={addedProducts}
                removeProduct={removeProduct}
                updateProduct={updateProduct}
                productsCalculations={productsCalculation}
                ticketFactor={ticketFactor}
                restToPay={totalPriceBrutto}
                packageVipDiscount={packageVipDiscount}
              />
            </Col>

            <ShopPurchasePayment
              apartment={apartment}
              availablePaymentMethods={availablePaymentMethods}
              resortId={resort_id}
            />
          </>
        )}
      />
    </Row>
  )
}
