import * as React from 'react'
import { NotificationEvents } from '@models/dashboard'
import { useAppDispatch } from '@store/index'
import { useWebSocket } from '@components/hooks/use-web-socket'
import { BarrierTicket, BarrierTicketKind } from '@models/barriers'
import { removeBarrierTicket, updateBarrierTicket } from '@store/slices/barrier-slice'

interface Props {
  kind: BarrierTicketKind
}

export const BarrierTicketsWebSocketHandler: React.FC<Props> = ({ kind }) => {
  const dispatch = useAppDispatch()

  useWebSocket<BarrierTicket>(
    (message, payload) => {
      switch (message) {
        case NotificationEvents.BARRIER_TICKET_CHANGE:
          if (kind === payload.kind) {
            dispatch(updateBarrierTicket(payload))
          }
          break
        case NotificationEvents.BARRIER_TICKET_REMOVE:
          dispatch(removeBarrierTicket(payload))
          break
      }
    },
    [],
    [NotificationEvents.BARRIER_TICKET_CHANGE, NotificationEvents.BARRIER_TICKET_REMOVE],
  )
  return null
}
