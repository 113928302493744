import * as React from 'react'
import { formatPriceShort } from '@helpers/utils'
import { ReceptionBookingDetailsImprovement } from '@models/booking'
import classNames from 'classnames'

interface Props {
  ticketImprovement: ReceptionBookingDetailsImprovement | undefined
  price: string
}

export const ReceptionBookingHpfTicketImprovementInformation = ({
  ticketImprovement,
  price,
}: Props): React.ReactNode => (
  <>
    <div className="col-3 d-flex">
      <i className={classNames('uil-star font-15 mr-2', ticketImprovement ? 'text-secondary' : 'text-white')} />
      <div className="fw-semi-bold">
        Bilety do obiektów <br />
        Holiday Park & Fun
      </div>
    </div>
    <div className="col-4">
      <PriceItem wrapperClassName="font-12" title="Cena biletu" price={price} />
      <PriceItem price={60} title="Cena standardowa" priceClass="text-strikethrough" wrapperClassName="font-11" />
    </div>
    <div className="col-2">
      <img src={require('@assets/images/hpf-logo.svg')} alt="hpf ticket" height="40" />
    </div>
  </>
)

const PriceItem = ({ wrapperClassName, title, price, priceClass = '' }) => (
  <div className={classNames('d-flex', wrapperClassName)}>
    <strong className="col-6">{title}:</strong>
    <strong className={classNames('col-6 text-center', priceClass)}>{formatPriceShort(price)}</strong>
  </div>
)
