import * as React from 'react'
import Table from '@components/table/table'
import { PriceBlock } from '@modules/reservations/list/reservation-list-payment-summary'
import { formatPrice } from '@helpers/utils'
import { bruttoToNetto } from '@helpers/price'
import { BookingOptionsFiltersParams } from '@modules/promotions/booking-options/model'
import { useGetBookingOptionsAggregationQuery } from '@api/booking-options'
import { TypedQueryResult } from '@api/base'

interface Props {
  filters: BookingOptionsFiltersParams
}

export const BookingOptionsTableFooter: React.FC<Props> = ({ filters }) => {
  const { data: aggregation = { price_brutto: '0' } } = useGetBookingOptionsAggregationQuery(
    filters,
  ) as TypedQueryResult<{
    price_brutto: string
  }>

  return (
    <Table.Row>
      <Table.Cell colSpan={3} className="text-right align-middle reservation-list__summary-cell">
        <strong className="mr-2">Razem w wybranym okresie:</strong>
      </Table.Cell>
      <Table.Cell className="reservation-list__summary-cell">
        <PriceBlock title="Łączna kwota netto:" price={formatPrice(bruttoToNetto(aggregation.price_brutto, 23))} />
      </Table.Cell>

      <Table.Cell className="reservation-list__summary-cell">
        <PriceBlock title="Łączna kwota brutto:" price={formatPrice(aggregation.price_brutto)} />
      </Table.Cell>
      <Table.Cell colSpan={3} className="reservation-list__summary-cell" />
    </Table.Row>
  )
}
