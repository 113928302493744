import * as React from 'react'
import { RootState, useAppSelector } from '@store/index'
import { BaseModalProps } from '@components/modals/types'
import { ReportNotConfigurable } from '@modules/reports/user-reports/report-not-configurable'

export const ReportsGastroCouponModal: React.FC<BaseModalProps> = ({ toggleIsVisible }) => {
  const reportUrl = useAppSelector(
    (state: RootState) => state.appState.appData.urls.reports.report_task_gastro_coupon_report,
  )

  return (
    <ReportNotConfigurable toggleIsVisible={toggleIsVisible} url={reportUrl} title='Raport "Strzał w dziesiątkę"' />
  )
}
