import { SubscriptionCreateFormInputs } from '@modules/subscription/create/modal'
import { SubscriptionDetails, SubscriptionProduct, SubscriptionType } from '@modules/subscription/models'
import { commonObjectPost, commonObjectPut } from '@store/actions/generic-actions'
import store from '@store/index'
import { formatDate } from '@helpers/date-helper'
import { SubscriptionProductCreateFormInputs } from '@modules/subscription/products/create/modal'
import { extractSelectOptionsValues } from '@helpers/utils'
import { PackageVipType } from '@modules/promotions/package-vip/models'

export const subscriptionCreate = async (
  payload: SubscriptionCreateFormInputs,
  type: SubscriptionType,
): Promise<SubscriptionDetails> => {
  const url = store.getState().appState.appData.urls.subscription.subscriptions.replace('replace-me', type)

  const { zoo_borysew_tickets_packs_amount, client, clients, ...data } = payload

  return await commonObjectPost<SubscriptionDetails>(url, {
    ...data,
    client: client?.id,
    clients: clients.map(client => client.id),
    ...extractSelectOptionsValues(data),
    expire_after: formatDate(data.expire_after),
    ...(data.with_zoo_borysew_tickets && { zoo_borysew_tickets_packs_amount: zoo_borysew_tickets_packs_amount || 1 }),
  })
}

interface SubscriptionProductPayload extends Omit<SubscriptionProductCreateFormInputs, 'code_features'> {
  code_features: { years: string[]; gastro_gift: string; package_vip: null | PackageVipType }[]
}
export const subscriptionProductCreate = async (
  payload: SubscriptionProductPayload,
  type: SubscriptionType,
): Promise<SubscriptionProduct> =>
  await commonObjectPost<SubscriptionProduct>(
    store.getState().appState.appData.urls.subscription.subscription_products.replace('replace-me', type),
    {
      ...extractSelectOptionsValues(payload),
      type,
    },
  )

export const subscriptionProductUpdate = async (
  url: string,
  payload: SubscriptionProductPayload,
): Promise<SubscriptionProduct> =>
  await commonObjectPut<SubscriptionProduct>(url, {
    ...payload,
    status: payload.status?.value,
    kind: payload.kind?.value,
    kind_version: payload.kind_version?.value,
  })
