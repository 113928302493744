import * as React from 'react'
import { usePreviousImmediate } from 'rooks'
import { asDecimal } from '@helpers/utils'
import Decimal from 'decimal.js'

export const useAnimatedPriceChange = (value: string | number) => {
  const previousValue = usePreviousImmediate(value) || 0

  const initialInt = asDecimal(previousValue).div(0.01)
  const endInt = asDecimal(value).div(0.01)

  const [animatedValue, setAnimatedValue] = React.useState(asDecimal(previousValue).div(0.01))

  const increase = () => {
    let currentValue = animatedValue

    const increaseHandler = () => {
      const value = asDecimal(endInt).minus(currentValue)

      const time = Decimal.max(value.div(5), 1)

      if (currentValue.lt(endInt)) {
        currentValue = currentValue.plus(time)
        setAnimatedValue(state => state.plus(time))
        setTimeout(increaseHandler, 1)
      } else {
        setAnimatedValue(endInt)
      }
    }

    increaseHandler()
  }

  const decrease = () => {
    let currentValue = animatedValue

    const decreaseHandler = () => {
      const value = currentValue.minus(endInt)

      const time = Decimal.max(value.div(5), 1)

      if (currentValue.gt(endInt)) {
        currentValue = currentValue.minus(time)
        setAnimatedValue(state => state.minus(time))
        setTimeout(decreaseHandler, 1)
      } else {
        setAnimatedValue(endInt)
      }
    }

    decreaseHandler()
  }

  React.useEffect(() => {
    if (initialInt.gt(endInt)) {
      decrease()
    }

    if (endInt.gt(initialInt)) {
      increase()
    }
  }, [previousValue, value])

  return animatedValue.mul(0.01).toString()
}
