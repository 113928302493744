import * as React from 'react'
import { GastroCardDetails } from '@models/promotions'
import { Payment } from '@models/payments'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { useApiRequest } from '@components/hooks/use-api-request'

interface Props {
  payment: Payment
  onSuccess?: () => void
}

export const GenericProductPaymentsDelete: React.FC<Props> = ({ payment, onSuccess }) => {
  const { addSuccessMessage } = useNotificationHook()

  const { isLoading, action: handleAccept } = useApiRequest(async (reason: string) => {
    await commonObjectDelete<GastroCardDetails>(payment.urls.details, reason)
    onSuccess?.()

    addSuccessMessage('Sukces', 'Płatność została skasowana')
  })

  return (
    <CommonObjectConfirmAction
      title="Potwierdź skasowanie wpłaty"
      askForReason={true}
      isLoading={isLoading}
      handleAccept={handleAccept}
      message="Czy na pewno chcesz skasować wybraną wpłatę ? Operacja ta jest nieodwołalna"
    >
      <i className="text-muted font-14 cursor-pointer uil-trash-alt" />
    </CommonObjectConfirmAction>
  )
}
