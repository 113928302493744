import * as React from 'react'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap'
import { ColAuto } from '@hyper/col-auto'
import { CafeteriaCode, CafeteriaDetails } from '@models/cafeteria'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useAppDispatch } from '@store/index'
import { updateCafeteriaDetails } from '@store/slices/cafeteria-slice'
import { commonObjectDelete } from '@store/actions/generic-actions'

interface Props {
  selectedRows: CafeteriaCode[]
  cafeteria: CafeteriaDetails
  clearSelectedRows: () => void
}

export const CafeteriaDetailsCodesActions: React.FC<Props> = ({ clearSelectedRows, cafeteria, selectedRows }) => {
  const isDisabled = selectedRows.length === 0
  const dispatch = useAppDispatch()

  const { addSuccessMessage } = useNotificationHook()

  const { isLoading, action: handleAccept } = useApiRequest(async () => {
    dispatch(
      updateCafeteriaDetails(
        await commonObjectDelete<CafeteriaDetails>(cafeteria.urls.codes, null, {
          ids: selectedRows.map(row => row.id),
        }),
      ),
    )
    addSuccessMessage('Sukces', 'Kody zostały deaktywowane')
    clearSelectedRows()
  })

  return (
    <ColAuto className="mt-3">
      <UncontrolledButtonDropdown disabled={isDisabled}>
        <DropdownToggle color="light" caret={true} disabled={isDisabled}>
          <strong>zaznaczone ({selectedRows.length})</strong>
        </DropdownToggle>
        <DropdownMenu>
          <CommonObjectConfirmAction as={DropdownItem} isLoading={isLoading} handleAccept={handleAccept}>
            <i className="uil uil-lock-open-alt mr-1 text-bold" />
            Deaktywuj ({selectedRows.length})
          </CommonObjectConfirmAction>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </ColAuto>
  )
}
