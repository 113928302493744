import * as React from 'react'
import Table, { TableHeaderValue } from '@components/table/table'
import { RentalWarehouse, RentalWarehousesFiltersParams } from '@modules/rental/models'
import { RentalWarehouseTableRow } from '@modules/rental/warehouses/rental-warehouses-table-row'

interface Props {
  warehouses: RentalWarehouse[]
  setFilters: (filters: RentalWarehousesFiltersParams) => void
  filters: RentalWarehousesFiltersParams
  isLoading: boolean
}

const headerValues: TableHeaderValue[] = [
  { title: 'Lp.' },
  { sortField: 'name', title: 'Nazwa' },
  { title: 'Resort' },
  { title: '' },
]

export const RentalWarehousesTable: React.FC<Props> = ({ setFilters, warehouses, filters, isLoading }) => (
  <Table
    className="table-hover w-100"
    headerValues={headerValues}
    isLoading={isLoading}
    filters={filters}
    setFilters={setFilters}
  >
    {warehouses.map((warehouse: RentalWarehouse, index: number) => (
      <RentalWarehouseTableRow key={warehouse.id} warehouse={warehouse} index={index} />
    ))}
  </Table>
)
