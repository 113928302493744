import * as React from 'react'
import {
  ReceptionBookingCheckStep,
  ReceptionBookingCheckSteps,
} from '@modules/reception/common/reception-booking-check-steps'
import { ModalHeader } from 'reactstrap'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { ReceptionBooking } from '@models/reception'
import { ReceptionBookingExtendedStayTime } from '@modules/reception/extended-stay/reception-booking-extended-stay-time'
import { ReceptionBookingExtendedStayBandUpdate } from '@modules/reception/extended-stay/reception-booking-extended-stay-band-update'
import { receptionBookingDetailsSelector } from '@store/selectors/reception'
import { BaseModalProps } from '@components/modals/types'
import { useAppSelector } from '@store/index'

const checkInExtensionStep: ReceptionBookingCheckStep[] = [
  {
    step: 1,
    description: <>Wybierz czas przedłużenia</>,
  },
  {
    step: 2,
    description: <>Aktualizacja danych opaski</>,
  },
]

interface ReceptionBookingExtendedStayWizardProps extends BaseModalProps {
  initialStep?: number
  booking: ReceptionBooking
}

export const ReceptionBookingExtendedStayWizardDialog: React.FC<ReceptionBookingExtendedStayWizardProps> = ({
  booking,
  toggleIsVisible,
  initialStep,
}) => {
  const [step, setStep] = React.useState(initialStep || 1)

  const previousStep = () => setStep(Math.max(step - 1, 1))
  const nextStep = () => setStep(step + 1)

  const bookingDetails = useAppSelector(receptionBookingDetailsSelector)

  return (
    <>
      <ModalHeader
        toggle={toggleIsVisible}
        cssModule={{ 'modal-title': 'w-100', 'modal-header': 'd-flex align-items-center p-2' }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="font-18 text-semi-strong my-0">
            Przedłużenie rezerwacji <span className="text-strong"> {booking.reservation_number}</span> w lokalu
            <span className="text-strong"> {booking.apartment.name}</span>
          </h5>
        </div>
      </ModalHeader>
      <ReceptionBookingCheckSteps step={step} steps={checkInExtensionStep} />
      <LoaderPlaceholder content={booking}>
        {step === 1 && <ReceptionBookingExtendedStayTime nextStep={nextStep} booking={booking} />}
        {step === 2 && bookingDetails && (
          <ReceptionBookingExtendedStayBandUpdate
            previousStep={previousStep}
            nextStep={toggleIsVisible}
            booking={bookingDetails}
          />
        )}
      </LoaderPlaceholder>
    </>
  )
}
