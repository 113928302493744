import * as React from 'react'
import { SubscriptionDetails } from '@modules/subscription/models'
import { SubscriptionSingleModalBase } from '@modules/subscription/options/subscription-single-modal-base'
import { BaseModalProps } from '@components/modals/types'

interface Props extends BaseModalProps {
  subscriptionDetails: SubscriptionDetails
}

export const SubscriptionExpirationExtendModal: React.FC<Props> = ({ subscriptionDetails, toggleIsVisible }) => (
  <SubscriptionSingleModalBase
    title="Jednorazowe przedłużenie ważności kodu"
    message="Opcja przedłużenia ważności kodu została dodana"
    amounts={[subscriptionDetails.product?.expiration_extension_single_price_brutto ?? '0']}
    description="Opcja przedłużenia ważności"
    toggleIsVisible={toggleIsVisible}
    subscriptionDetails={subscriptionDetails}
    url={subscriptionDetails.urls.subscription_expiration_extension}
  />
)
