import { AsyncThunkParams } from '@store/index'
import { ReportsAppData, StatsSaleItem } from '@models/reports'
import { PaymentReport, PaymentsReportFiltersParams, StatsSaleItemFiltersParams } from '@modules/reports/models'
import { setDashboardStandardPaginator } from '@store/actions/dashboard-actions'
import {
  HouseKeepingUserReport,
  HouseKeepingUserReportAggregation,
  HouseKeepingUserReportItems,
} from '@modules/reports/housekeeping-users-report/models'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { commonObjectGet } from '@store/actions/generic-actions'
import { PaginationResponse, PaginationResponseWithAggregation } from '@models/dashboard'
import { TableFilters } from '@components/table/table'

export const getStatsSaleItems = createAsyncThunk<StatsSaleItem[], StatsSaleItemFiltersParams, AsyncThunkParams>(
  'reports/getStatsSaleItems',
  async (params, { getState, dispatch }) => {
    const data = await commonObjectGet<PaginationResponse<StatsSaleItem>>(
      getState().appState.appData.urls.reports.stats_sale_item,
      params,
    )

    dispatch(setDashboardStandardPaginator(data))
    return data.results
  },
)

export const getPaymentsReport = createAsyncThunk<PaymentReport[], PaymentsReportFiltersParams, AsyncThunkParams>(
  'reports/getPaymentsReport',
  async (params, { getState, dispatch }) => {
    const data = await commonObjectGet<PaginationResponse<PaymentReport>>(
      getState().appState.appData.urls.reports.payment_report,
      params,
    )

    dispatch(setDashboardStandardPaginator(data))
    return data.results
  },
)

export const getReportsAppData = createAsyncThunk<ReportsAppData, void, AsyncThunkParams>(
  'reports/getReportsAppData',
  async (_, { getState }) => {
    if (getState().reportsState.appData.status === 'unknown') {
      return await commonObjectGet<ReportsAppData>(getState().appState.appData.urls.reports.reports_app_data)
    }
    return getState().reportsState.appData
  },
)

export interface HouseKeepingUsersReportParams extends TableFilters {
  resorts: number[]
  finished_at_after?: Date | undefined
  finished_at_before?: Date | undefined
}

export const getHouseKeepingUsersReport = createAsyncThunk<
  HouseKeepingUserReport,
  HouseKeepingUsersReportParams,
  AsyncThunkParams
>('reports/getHouseKeepingUsersReport', async (params, { getState, dispatch }) => {
  const data = await commonObjectGet<
    PaginationResponseWithAggregation<HouseKeepingUserReportItems, HouseKeepingUserReportAggregation>
  >(getState().appState.appData.urls.reports.housekeeping_users_report, params)

  dispatch(setDashboardStandardPaginator(data))

  return { results: data.results, aggregation: data.aggregation }
})

export interface SoldProductsReport {
  accounting_name: string
  sum_total_price_brutto: number
  sum_quantity: number
}
