import * as React from 'react'
import { ReceptionCheckType } from '@models/reception'
import { Button } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'

interface ReceptionNotificationButtonProps {
  type: ReceptionCheckType
  date: Date
  resortId: number
}

export const ReceptionNotificationButton: React.FC<ReceptionNotificationButtonProps> = ({ type, date, resortId }) => {
  const [handleClick] = useModal('ReceptionNotificationDialog', {
    resortId,
    check_type: type,
    date,
  })

  return (
    <Button className="btn-info btn text-semi-strong" onClick={handleClick}>
      <>
        <i className="uil-comment-alt-message mr-1" />
        Wyślij powiadomienie
      </>
    </Button>
  )
}
