import * as React from 'react'
import Table from '@components/table/table'
import { asDecimal, formatPrice } from '@helpers/utils'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { PackageVip } from '@modules/promotions/package-vip/models'
import classNames from 'classnames'
import { useModal } from '@components/modals/use-modal'
import { TableSellerRow } from '@components/table-seller-row'
import { TableUserRow } from '@components/table-user-row'
import { StatusBadge } from '@modules/promotions/common/status-badge'

interface Props {
  packageVip: PackageVip
}

export const ClientDetailsModalPackageVipsRow: React.FC<Props> = ({ packageVip }) => {
  const [showPackageDetailsModal] = useModal('PackageVipDetails', { packageVip })

  const isPaymentRequired = React.useMemo(
    () => asDecimal(packageVip.required_payment).gt(asDecimal(0)),
    [packageVip.required_payment],
  )

  return (
    <Table.Row key={packageVip.id} onClick={showPackageDetailsModal} className="cursor-pointer">
      <Table.Cell>{packageVip.number}</Table.Cell>
      <Table.Cell>
        <StatusBadge element={packageVip} />
      </Table.Cell>
      <Table.Cell>{packageVip.payment_method_display}</Table.Cell>
      <Table.Cell
        className={classNames('text-strong', {
          'text-danger': isPaymentRequired,
        })}
      >
        {formatPrice(packageVip.required_payment)}
      </Table.Cell>
      <Table.Cell>
        <TableSellerRow
          sellerId={packageVip.seller_id}
          defaultValue={packageVip.seller ? String(packageVip.seller) : ''}
          className="d-block"
        />
      </Table.Cell>
      <Table.Cell>
        <TableUserRow userId={packageVip.created_by_id} defaultValue={packageVip.created_by} className="d-block" />

        <small className="font-10">{toDefaultDateTimeFormat(packageVip.created)}</small>
      </Table.Cell>
    </Table.Row>
  )
}
