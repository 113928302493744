import * as React from 'react'
import { Label, Row } from 'reactstrap'
import { Controller, useForm } from 'react-hook-form'
import { SubmitFilter } from '@modules/reception/common/filters/submit-filter'
import DateRangeInput from '@components/date/date-range-input'
import Col from 'reactstrap/lib/Col'
import * as R from 'ramda'
import { StatsSaleItemFiltersParams } from '@modules/reports/models'
import { ColAuto } from '@hyper/col-auto'
import { FormPlain } from '@hyper/forms'
import Select from 'react-select'
import { useGroupedSellers } from '@components/hooks/use-grouped-sellers'
import { RootState, useAppSelector } from '@store/index'
import { useGroupedSources } from '@components/hooks/use-grouped-sources'
import { useDebounce } from 'rooks'

interface StatsSaleItemFiltersProps {
  filters: StatsSaleItemFiltersParams
  setFilters: (filters: StatsSaleItemFiltersParams) => void
  defaultFilters: StatsSaleItemFiltersParams
}

export const StatsSaleItemFilters: React.FC<StatsSaleItemFiltersProps> = ({ setFilters, filters, defaultFilters }) => {
  const { handleSubmit, formState, reset, watch, control } = useForm<StatsSaleItemFiltersParams>({
    defaultValues: { ...filters },
  })

  const handleReset = () => {
    reset()
    setFilters(defaultFilters)
  }

  const promotions = useAppSelector((state: RootState) => state.reportsState.appData?.promotion_marketing) || []
  const categories = (
    useAppSelector((state: RootState) => state.reportsState.appData?.stats_sale_item_categories) || []
  ).map(category => ({
    label: category.name,
    value: category.id,
  }))

  const promotionList = [
    { label: 'Wszystko', value: null },
    ...promotions.map(promotion => ({
      label: promotion.name,
      value: promotion.id,
    })),
  ]

  const onSubmit = async (payload: StatsSaleItemFiltersParams) => setFilters({ ...filters, ...payload, page: 1 })

  const changes = watch()

  const values: StatsSaleItemFiltersParams = {
    ...filters,
    ...changes,

    page: filters.page,
    page_size: filters.page_size,
    ordering: filters.ordering,
    search: filters.search,
  }

  const onSubmitDebounced = useDebounce(values => onSubmit(values), 250)
  React.useEffect(() => {
    if (!R.equals(values, filters)) {
      onSubmitDebounced(values)
    }
  }, [values])

  const sellers = useGroupedSellers()
  const sources = useGroupedSources()

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form form-style-filter mb-2" autoComplete="off">
      <Row>
        <Col className="col-auto" style={{ minWidth: 320 }}>
          <div className="d-flex">
            <Label className="mr-1">Data Sprzedaży:</Label>
          </div>

          <DateRangeInput
            wrapperClassName="flex-grow-1"
            inputGroupClassName="form-group"
            startDateName="sell_date_after"
            endDateName="sell_date_before"
          />
        </Col>

        <ColAuto>
          <FormPlain colStyle={{ padding: 0, width: 200 }} name="seller" errors={formState.errors}>
            <label>Sprzedawca:</label>
            <Controller
              name="seller"
              control={control}
              render={({ field }) => <Select placeholder="Wybierz" options={sellers} {...field} />}
            />
          </FormPlain>
        </ColAuto>
        <ColAuto>
          <FormPlain colStyle={{ padding: 0, width: 200 }} name="promotion_marketing" errors={formState.errors}>
            <label>Promocja:</label>
            <Controller
              render={({ field }) => <Select placeholder="Wybierz" options={promotionList} {...field} />}
              name="promotion_marketing"
              control={control}
            />
          </FormPlain>
        </ColAuto>
        <ColAuto>
          <FormPlain colStyle={{ padding: 0, width: 200 }} name="source_marketing" errors={formState.errors}>
            <label>Źródło:</label>
            <Controller
              name="source_marketing"
              control={control}
              render={({ field }) => <Select placeholder="Wybierz" options={sources} {...field} />}
            />
          </FormPlain>
        </ColAuto>
        <ColAuto>
          <FormPlain colStyle={{ padding: 0, width: 200 }} name="category" errors={formState.errors}>
            <label>Kategoria:</label>
            <Controller
              name="category"
              control={control}
              render={({ field }) => <Select placeholder="Wybierz" options={categories} {...field} />}
            />
          </FormPlain>
        </ColAuto>
        <SubmitFilter handleReset={handleReset} />
      </Row>
    </form>
  )
}
