import { useModal } from '@components/modals/use-modal'
import { PackageDetails } from '@models/package'
import * as React from 'react'

interface Props {
  packageDetails: PackageDetails
}

export const PackageDetailsPaymentsListActions: React.FC<Props> = ({ packageDetails }) => {
  const [handleCreatePayment] = useModal('PackagePaymentCreateModal', { packageDetails })
  const [handleCreateInvoice] = useModal('PackageInvoiceCreateModal', { packageDetails })

  return (
    <div className="text-right">
      {!packageDetails.invoice_number && (
        <button type="button" className="btn btn-secondary btn-sm mr-1" onClick={handleCreateInvoice}>
          Wystaw fakturę
        </button>
      )}
      {!packageDetails.is_paid && (
        <button type="button" className="btn btn-green btn-sm" onClick={handleCreatePayment}>
          Opłać
        </button>
      )}
    </div>
  )
}
