import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { setAuthenticatedUser } from '@store/slices/app-slice'
import { commonObjectPatch } from '@store/actions/generic-actions'
import { AuthenticatedUser } from '@models/dashboard'
import { useApiRequest } from '@components/hooks/use-api-request'

export const useDeviceSelect = (
  name: 'eservice_pad_id' | 'band_reader_id' | 'tablet_id',
): [number | null, (value: number) => void, () => void] => {
  const userDetailsUrl = useAppSelector((state: RootState) => state.appState.appData.urls.account.details)
  const userDetails = useAppSelector((state: RootState) => state.appState.user)

  const deviceId = userDetails[name]

  const dispatch = useAppDispatch()

  const { action: setDeviceId } = useApiRequest(async newDeviceId => {
    await dispatch(
      setAuthenticatedUser(
        await commonObjectPatch<AuthenticatedUser>(userDetailsUrl, {
          [name.replace('_id', '')]: newDeviceId,
        }),
      ),
    )
  })

  const remove = async () => await setDeviceId(null)

  return [deviceId, setDeviceId, remove]
}
