import * as React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { PageTitle } from '@hyper/page-title'
import { useDocumentTitle } from '@helpers/utils'
import { Card, CardBody } from 'reactstrap'
import { ReservationTypes } from '@modules/reservations/list/reservation-types'
import { ReservationListType } from '@modules/reservations/list/models'
import { ReservationListFilters } from '@modules/reservations/list/reservation-list-filters'
import { ReservationListTable } from '@modules/reservations/list/reservation-list-table'
import { useAppDispatch, useAppSelector } from '@store/index'
import { getBookingList } from '@store/actions/reservation-actions'
import { ReservationListWebSocketHandler } from '@modules/reservations/list/reservation-list-web-socket-handler'
import TopBarProgress from 'react-topbar-progress-indicator'
import * as queryString from 'querystring'
import { ReservationListActions } from '@modules/reservations/list/reservation-list-actions'
import { TableFilters } from '@components/table/table'
import { useApiRequest } from '@components/hooks/use-api-request'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useDebounce } from 'rooks'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { reservationsBookingsSelector } from '@store/slices/reservations-slice'

interface Params {
  type: ReservationListType
}

export interface ReceptionListFiltersParams extends TableFilters {
  resort: CustomReactSelectOption[] | undefined
  accommodation_type: CustomReactSelectOption[] | undefined
  date_from?: Date
  date_to?: Date
  status: CustomReactSelectOption[] | undefined
  is_subscription_reservation: boolean
  is_package_reservation: boolean
  is_paid: boolean
  type?: ReservationListType
}

const getStatusForType = (type: ReservationListType) => {
  if (type === ReservationListType.Present) {
    return ['initial', 'confirmed', 'initial_waiting', 'checked_in']
  } else if (type === ReservationListType.Unfinished) {
    return ['unfinished']
  } else if (type === ReservationListType.Cancelled) {
    return ['unfinished_canceled', 'confirmed_canceled', 'initial_cancelled']
  } else if (type === ReservationListType.Close) {
    return ['close']
  } else if (type === ReservationListType.Unclosed) {
    return ['initial', 'confirmed']
  }
  return []
}
export const ReservationListWithParamsView: React.FC = () => {
  const { type: currentType } = useParams<'type'>() as Params
  const location = useLocation()
  const { q } = queryString.parse(location.search.replace('?', ''))
  const defaultFilters: ReceptionListFiltersParams = {
    accommodation_type: undefined,
    resort: undefined,
    status: undefined,
    is_paid: false,
    date_to: undefined,
    date_from: undefined,
    is_subscription_reservation: false,
    is_package_reservation: false,
    ordering: '-created_at',
    page: 1,
    page_size: 10,
    search: q || '',
  }

  useMenuActive(NavigationPath.ReservationListWithParams.replace(':type', 'present'))
  const [type, setType] = React.useState(currentType)
  const [filters, setFilters] = React.useState<ReceptionListFiltersParams>(defaultFilters)
  const dispatch = useAppDispatch()

  const pageTitle = 'Rezerwacje'
  useDocumentTitle(pageTitle)

  const statuses = getStatusForType(currentType)

  const reservations = useAppSelector(reservationsBookingsSelector)

  const { isLoading, action: fetchBookings } = useApiRequest(
    async (filters, type) => await dispatch(getBookingList({ ...filters, type })),
  )

  const fetchBookingsDebounced = React.useCallback(useDebounce(fetchBookings, 300), [])

  React.useEffect(() => {
    fetchBookingsDebounced(filters, type)
  }, [filters, type])

  React.useEffect(() => {
    if (type !== currentType) {
      setFilters({ ...defaultFilters })
      setType(currentType)
    }
  }, [currentType])

  const filteredReservations = reservations
    .filter(booking => statuses.includes(booking.status))
    .map(row => ({ ...row, idApartmentId: `${row.id}-${row.apartment?.id || ''}` })) // dodatkowe filtrowanie na wypadek dodania rezerwacji via websockety

  return (
    <>
      {!filters.search && <ReservationListWebSocketHandler />}
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitle} />

      <Card className="reservation-list">
        {isLoading && TopBarProgress && <TopBarProgress />}

        <CardBody>
          <ReservationTypes type={type} />
          <ReservationListFilters
            statuses={statuses}
            filters={filters}
            setFilters={setFilters}
            defaultFilters={defaultFilters}
            type={type}
          />
          <ReservationListTable
            isLoading={isLoading}
            filters={filters}
            setFilters={setFilters}
            reservations={filteredReservations}
            type={type}
          />
          <ReservationListActions ids={filteredReservations.map(row => row.id)} />
        </CardBody>
      </Card>
    </>
  )
}
