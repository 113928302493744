import * as React from 'react'
import { NotificationEvents } from '@models/dashboard'
import { useAppDispatch } from '@store/index'
import { useWebSocket } from '@components/hooks/use-web-socket'
import { PackageWholesaleDetails, PackageWholesaleProductDetails } from '@modules/package-wholesale/models'
import {
  updatePackageWholesaleDetails,
  updatePackageWholesaleProductDetails,
} from '@store/slices/package-wholesale-slice'
import { SubscriptionDetails } from '@modules/subscription/models'
import { updateSubscriptionDetails } from '@store/slices/subscription-slice'

export const PackageWholesaleWebSocketHandler: React.FC = () => {
  const dispatch = useAppDispatch()

  useWebSocket<PackageWholesaleProductDetails | PackageWholesaleDetails | SubscriptionDetails>(
    (message, payload) => {
      switch (message) {
        case NotificationEvents.SUBSCRIPTION_CHANGE:
          dispatch(updateSubscriptionDetails(payload as SubscriptionDetails))
          break
        case NotificationEvents.PACKAGE_WHOLESALE_PRODUCT_CHANGE:
          dispatch(updatePackageWholesaleProductDetails(payload as PackageWholesaleProductDetails))
          break
        case NotificationEvents.PACKAGE_WHOLESALE_CHANGE:
          dispatch(updatePackageWholesaleDetails(payload as PackageWholesaleDetails))
          break
      }
    },
    [],
    [
      NotificationEvents.PACKAGE_WHOLESALE_PRODUCT_CHANGE,
      NotificationEvents.PACKAGE_WHOLESALE_CHANGE,
      NotificationEvents.SUBSCRIPTION_CHANGE,
    ],
  )
  return null
}
