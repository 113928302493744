import * as React from 'react'
import { ACCOMMODATION_TYPES } from '@helpers/consts'
import { LocalSelectionMapItemsList } from '@modules/reservations/create/local-selection-map/local-selection-map-items-list'
import { AvailableApartment } from '@models/reservation-create'
import { sortByPosition } from '@helpers/utils'
import { IconWithTooltip } from '@components/icon-with-tooltip'
import { UncontrolledTooltip } from 'reactstrap'
import { isForClientWithBenefit } from '@modules/reservations/create/step-reservation-info/step-reservation-apartment-selection'

interface Props {
  maximumListHeight: number
  apartments: AvailableApartment[]
  selectedApartmentId: number | null
  onApartmentSelect: (apartmentId: number) => void
}

export const LocalSelectionMapApartmentsList = ({
  maximumListHeight,
  apartments,
  selectedApartmentId,
  onApartmentSelect,
}: Props): JSX.Element => {
  const handleApartmentSelection = (apartment: AvailableApartment) => {
    onApartmentSelect(apartment.id)
  }

  const isApartmentSelected = (apartment: AvailableApartment) => selectedApartmentId === apartment.id

  const renderApartmentText = (apartment: AvailableApartment) => (
    <div className="d-flex justify-content-between">
      <span>
        {apartment.apartment_id.startsWith('D') ? 'Domek' : 'Apartament'} numer {apartment.display_name}
      </span>

      <div className="d-flex align-items-center">
        {apartment.no_animals && (
          <div className="ml-1 mr-1">
            <IconWithTooltip
              icon="uil-flower text-warning font-14 lh-initial mr-0"
              tooltipId="local-selection-garden"
              tooltipPlacement="top"
              tooltipMessage={
                <span className="font-11 text-left">
                  <strong>Lokal bez zwierząt </strong>
                  <span className="text-nowrap">(dla alergików)</span>
                </span>
              }
              color="text-secondary"
            />
          </div>
        )}

        {isForClientWithBenefit(apartment) && (
          <div className="ml-1 mr-1">
            <IconWithTooltip
              icon="uil-star font-14 calendar__apartment-extras__description-row is-special-local h-auto"
              tooltipId="local-selection-subscription-owner"
              tooltipPlacement="top"
              tooltipMessage={
                <span className="font-11 text-left">
                  <strong>Lokal specjalny </strong>
                </span>
              }
            />
          </div>
        )}

        {ACCOMMODATION_TYPES.APARTMENTS.includes(apartment.accommodation_type_id) && apartment.has_garden_fixed && (
          <>
            <img
              src={require('@assets/images/grass_icon.svg')}
              alt="Ogródek"
              height="10"
              className="mr-2"
              id={`garden-${apartment.apartment_id}`}
            />
            <UncontrolledTooltip placement="top" target={`garden-${apartment.apartment_id}`}>
              <strong className="font-11 text-left">Lokal z ogrodem</strong>
            </UncontrolledTooltip>
          </>
        )}
      </div>
    </div>
  )

  const sortedApartments = React.useMemo(() => sortByPosition(apartments), [apartments])

  return (
    <LocalSelectionMapItemsList
      items={sortedApartments}
      onItemSelection={handleApartmentSelection}
      isItemSelected={isApartmentSelected}
      maximumListHeight={maximumListHeight}
      title="Wskaż lokal na mapie lub z listy poniżej:"
      textRenderer={renderApartmentText}
      emptyListElement={<p className="font-10 text-darker-gray">Brak dostępnych lokali</p>}
    />
  )
}
