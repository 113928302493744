import * as React from 'react'
import { HrAddressFormRow } from '@modules/hr/common/form/addresses-row'
import { Col, Row } from 'reactstrap'
import { FormCheckbox } from '@hyper/forms/form-checkbox'
import { useFormContext, useWatch } from 'react-hook-form'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { createSelectOption } from '@helpers/utils'
import { firstLetterToUpperCase } from '@helpers/text'
import { HrAddress } from '@modules/hr/common/models'

interface Props {
  addressData?: HrAddress | null
}

export const HrAddressForm = ({ addressData }: Props): JSX.Element => {
  const [isInitialized, setIsInitialized] = React.useState(false)

  const { control, setValue } = useFormContext()

  const differentAddress = useWatch({ control, name: 'different_address' })

  useDidUpdateEffect(() => {
    if (!differentAddress) {
      setValue('registered_street', null)
      setValue('registered_street_house', '')
      setValue('registered_street_apartment', '')
      setValue('registered_city', null)
      setValue('registered_district', null)
      setValue('registered_postcode', '')
      setValue('registered_post', null)
      setValue('registered_community', null)
      setValue('registered_province', null)
    }
  }, [differentAddress])

  const setDefaultFieldValue = (fieldName, value) => {
    if (!addressData || !addressData[fieldName]) return
    setValue(fieldName, value)
  }

  const initializeData = (prefix: string = '') => {
    if (!addressData) return

    const fieldName = field => `${prefix}${field}`

    setDefaultFieldValue(fieldName('street'), addressData[fieldName('street')])
    setDefaultFieldValue(fieldName('street_house'), addressData[fieldName('street_house')])
    setDefaultFieldValue(fieldName('street_apartment'), addressData[fieldName('street_apartment')])

    setDefaultFieldValue(
      fieldName('city'),
      createSelectOption(firstLetterToUpperCase(addressData[fieldName('city')]), addressData[fieldName('city')]),
    )

    setDefaultFieldValue(
      fieldName('district'),
      createSelectOption(
        firstLetterToUpperCase(addressData[fieldName('district')]),
        addressData[fieldName('district')],
      ),
    )

    setDefaultFieldValue(
      fieldName('post'),
      createSelectOption(firstLetterToUpperCase(addressData[fieldName('post')]), addressData[fieldName('post')]),
    )

    setDefaultFieldValue(
      fieldName('community'),
      createSelectOption(
        firstLetterToUpperCase(addressData[fieldName('community')]),
        addressData[fieldName('community')],
      ),
    )

    setDefaultFieldValue(
      fieldName('province'),
      createSelectOption(
        firstLetterToUpperCase(addressData[fieldName('province')]),
        addressData[fieldName('province')],
      ),
    )
  }

  React.useEffect(() => {
    initializeData('registered_')
    initializeData()
    setIsInitialized(true)
  }, [])

  return (
    <>
      <Row className="mt-n2">
        <Col md={12}>
          <h5>Adres zamieszkania</h5>
        </Col>
      </Row>
      <hr className="mx-n2 mt-0" />
      {isInitialized && (
        <>
          <HrAddressFormRow prefix="" addressData={addressData} />
          <div className="bg-grey border-top px-3" style={{ marginBottom: '-16px', marginInline: '-15px' }}>
            <FormCheckbox
              name="different_address"
              label="Adres zameldowania (jeśli inny niż adres zamieszkania)"
              id="different_address"
              className="py-2"
            />
            {differentAddress && (
              <HrAddressFormRow prefix="registered_" rowClassName="form-transparent mt-2" addressData={addressData} />
            )}
          </div>
        </>
      )}
    </>
  )
}
