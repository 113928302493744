import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { BaseModalProps } from '@components/modals/types'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useAppData } from '@components/hooks/use-app-data'
import { Tablet } from '@models/booking'
import { useAuthenticatedUser, User } from '@components/hooks/use-authenticated-user'
import { FormSelect } from '@hyper/forms/form-select'

const getDefaultTablet = (tabletOptions: CustomReactSelectOption[], user: User) => {
  if (!tabletOptions.length) return undefined

  const userTablet = tabletOptions.find(tablet => tablet.value === user.tabletId)

  return { value: userTablet?.value ?? tabletOptions[0].value, label: userTablet?.label ?? tabletOptions[0].label }
}
interface FormInputs {
  tablet: CustomReactSelectOption | undefined
}

interface Props extends BaseModalProps {
  onSendToTablet: (tabletId: number) => void
}

export const TabletSelectionModal: React.FC<Props> = ({ toggleIsVisible, onSendToTablet }: Props) => {
  const appData = useAppData()
  const user = useAuthenticatedUser()

  const tablets = appData.tablets
    .filter(tablet => tablet.resort_id === user.receptionWorkstation?.resort_id)
    .map((tablet: Tablet) => ({ value: tablet.id, label: tablet.name }))

  const methods = useForm<FormInputs>({
    defaultValues: {
      tablet: getDefaultTablet(tablets, user),
    },
  })

  const handleSendToTablet = () => {
    onSendToTablet(methods.getValues('tablet.value'))
  }

  return (
    <FormProvider {...methods}>
      <ModalHeader toggle={toggleIsVisible}>Wybór urządzenia do podpisu</ModalHeader>
      <ModalBody>
        <Row className="align-items-center">
          <div className="col-6 d-flex align-items-center justify-content-center py-4">
            <i className="icon-tablet text-success reception__tablet__icon" />
          </div>
          <FormSelect
            options={tablets}
            name="tablet"
            label="Wybierz tablet do podpisu:"
            formPlainProps={{ colSize: 6 }}
          />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Anuluj
        </Button>
        <Button className="btn btn-green shadow-none" onClick={handleSendToTablet}>
          <span>Wyślij na tablet</span>
        </Button>
      </ModalFooter>
    </FormProvider>
  )
}
