import * as React from 'react'
import { ReceptionBooking } from '@models/reception'
import Table from '@components/table/table'
import { formatDate } from '@helpers/date-helper'
import { formatPriceShort } from '@helpers/utils'
import { resortsMapSelector } from '@store/selectors/dashboard'
import classNames from 'classnames'
import { getBookingStatusClass } from '@modules/reservations/list/utils'
import { useAppSelector } from '@store/index'

interface Props {
  booking: ReceptionBooking
}

export const ClientDetailsModalBookingsRow: React.FC<Props> = ({ booking }) => {
  const resortsMap = useAppSelector(resortsMapSelector)

  return (
    <Table.Row key={booking.id}>
      <Table.Cell>
        <a className="table__link-row" href={booking.urls.details}>
          {booking.reservation_number}
        </a>
      </Table.Cell>
      <Table.Cell>
        <span className={classNames('badge', getBookingStatusClass(booking.status, booking.checked_in))}>
          {booking.checked_in && booking.status !== 'close' ? 'zameldowana' : booking.status_display}
        </span>
      </Table.Cell>
      <Table.Cell>{resortsMap[booking.resort_id]?.name}</Table.Cell>
      <Table.Cell>
        {formatDate(booking.date_from, 'dd.MM')}-{formatDate(booking.date_to, 'dd.MM.yyyy')}
      </Table.Cell>
      <Table.Cell>{formatPriceShort(booking.final_brutto)}</Table.Cell>
    </Table.Row>
  )
}
