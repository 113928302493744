import * as React from 'react'
import { Button, ModalFooter, ModalHeader } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { BaseModalProps } from '@components/modals/types'

interface Props extends BaseModalProps {
  onConfirm: () => void
  content: React.ReactNode
  title: React.ReactNode
}

export const ReceptionBookingExtendedStayCollisionWarningModal = ({
  toggleIsVisible,
  onConfirm,
  title,
  content,
}: Props) => {
  const handleConfirm = () => {
    onConfirm()
    toggleIsVisible()
  }

  return (
    <>
      <ModalHeader toggle={toggleIsVisible} className="bg-warning text-dark py-2 rounded-0">
        {title}
      </ModalHeader>
      <ModalBody>{content}</ModalBody>
      <ModalFooter>
        <Button color="light" onClick={toggleIsVisible}>
          Anuluj
        </Button>
        <Button color="warning" onClick={handleConfirm}>
          Chcę dodać mimo to
        </Button>
      </ModalFooter>
    </>
  )
}
