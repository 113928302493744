import * as React from 'react'
import { formatPrice } from '@helpers/utils'

interface Props {
  breakfast: string | null
  dinner: string | null
  total?: string
  name: string
}

export const ReceptionBookingNewFeedingPriceListRows = ({
  name,
  breakfast,
  dinner,
  total,
}: Props): React.ReactElement | null => (
  <tr>
    <td className="text-nowrap">{name}</td>
    {breakfast && <td className="text-center">{formatPrice(breakfast)}</td>}
    {dinner && <td className="text-center">{formatPrice(dinner)}</td>}
    {dinner && breakfast && total && <td className="text-center">{formatPrice(total)}</td>}
  </tr>
)
