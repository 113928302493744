import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { BaseModalProps } from '@components/modals/types'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { FormProvider, useForm } from 'react-hook-form'
import { useFormRequest } from '@components/hooks/use-api-request'
import { commonObjectPut } from '@store/actions/generic-actions'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { updateReceptionBookingDetails } from '@store/actions/reception-actions'
import { ReservationDepositReturnModalForm } from '@modules/reservations/details/deposit-return/modal-form'

interface Props extends BaseModalProps {
  booking: ReceptionBookingDetails
}

interface FormInputs {
  deposit_return_account_number: string
  deposit_return_address: string
  deposit_return_name: string
  deposit_return_postcode: string
  deposit_return_city: string
  deposit_transfer_required: boolean
}

export const ReservationDepositReturnModal: React.FC<Props> = ({ booking, toggleIsVisible }) => {
  const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()

  const methods = useForm<FormInputs>({
    defaultValues: {
      ...booking.online_checkin_data,
      deposit_transfer_required: booking.deposit_transfer_required,
    },
  })

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    await dispatch(
      updateReceptionBookingDetails(
        await commonObjectPut<ReceptionBookingDetails>(
          booking.urls.reservation_booking_deposit_return_account,
          payload,
        ),
      ),
    )
    addSuccessMessage('Sukces', 'Dane do zwrotu zostały zapisane')
    toggleIsVisible()
  }, methods.setError)

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggleIsVisible}>Edycja numeru rachunku do zwrotu</ModalHeader>
        <ModalBody>
          <ReservationDepositReturnModalForm />
        </ModalBody>
        <ModalFooter>
          <Button color="light" type="button" onClick={toggleIsVisible}>
            Zamknij
          </Button>
          <SaveButton className="btn btn-green" isSaving={isLoading} />
        </ModalFooter>
      </form>
    </FormProvider>
  )
}
