import * as React from 'react'
import { Link } from 'react-router-dom'

export const Logo: React.FC = () => (
  <Link to="/" className="logo text-center logo-light">
    <span className="logo-lg">
      <img src={require('@assets/images/logo_holiday.svg')} alt="" height="49" />
    </span>
    <span className="logo-sm">
      <img src={require('@assets/images/logo_sygnet.svg')} alt="" height="49" />
    </span>
  </Link>
)
