import * as React from 'react'
import { FaultOrderDetails } from '@modules/housekeeping/applications/models'
import { DetailsColRow } from '@hyper/details-col-row'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { IconWithText } from '@components/icon-with-text'
import classNames from 'classnames'

interface Props {
  faultOrder: FaultOrderDetails
}

export const HousekeepingApplicationFaultsPreviewModalTable: React.FC<Props> = ({ faultOrder }) => (
  <>
    <DetailsColRow
      label="Dodane"
      className="text-semi-strong py-1"
      value={
        <IconWithText
          icon="uil uil-calendar-alt"
          iconSize="font-15"
          text={toDefaultDateTimeFormat(faultOrder.created)}
        />
      }
    />
    <DetailsColRow
      label="Lokale"
      className="text-semi-strong py-1"
      value={
        <IconWithText icon="uil uil-estate" iconSize="font-15" text={faultOrder.apartment?.name || 'Część wspólna'} />
      }
    />
    <DetailsColRow
      label="Zgłaszający"
      className="text-semi-strong py-1"
      value={<IconWithText icon="uil uil-user" iconSize="font-15" text={faultOrder.created_by} />}
    />
    <DetailsColRow
      label="Nazwa usterki"
      className="text-semi-strong py-1"
      value={<IconWithText icon="uil uil-bookmark" iconSize="font-15" text={faultOrder.name} />}
    />

    <DetailsColRow
      label="Status"
      className="text-semi-strong py-1"
      value={
        <span
          className={classNames('badge', {
            'badge-dark': faultOrder.status === 'close',
            'badge-success': faultOrder.status === 'open',
          })}
        >
          {faultOrder.status_display}
        </span>
      }
    />
    <DetailsColRow className="text-semi-strong py-1" label="Wykonawca" value={faultOrder.executor_display} />
    <DetailsColRow
      className="text-semi-strong py-1"
      label="Wykonawca"
      value={`${toDefaultDateTimeFormat(faultOrder.finished_at)} przez ${faultOrder.finished_by || 'brak'}`}
    />
    <div className="hr hr-grey mb-3" style={{ marginLeft: -15, marginRight: -15 }} />
    <DetailsColRow
      label="Opis usterki"
      value={<span className="text-linebreaksbr">{faultOrder.description || 'brak'}</span>}
    />
    {faultOrder.description_after.trim() && (
      <>
        <div className="hr hr-grey mt-1 mb-3" style={{ marginLeft: -15, marginRight: -15 }} />
        <DetailsColRow
          label="Opis po wykonaniu"
          value={<span className="text-linebreaks_afterbr">{faultOrder.description_after}</span>}
        />
      </>
    )}
    {!!faultOrder.images.length && (
      <>
        <div className="hr hr-grey mt-1 mb-3" style={{ marginLeft: -15, marginRight: -15 }} />
        <DetailsColRow
          label="Zdjęcia"
          value={
            <div className="d-flex ">
              {faultOrder.images.map(image => (
                <a href={image.image} target="_blank" key={image.id} className="mr-1">
                  <img src={image.image} style={{ maxWidth: 150 }} alt="" className="img-thumbnail" />
                </a>
              ))}
            </div>
          }
        />
      </>
    )}
  </>
)
