import * as React from 'react'
import Table from '@components/table/table'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { BenefitProgramAgreement } from '@modules/benefit-program/models'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { BenefitProgramAgreementStatusBadge } from '@modules/benefit-program/common/benefit-program-agreement-status-badge'

interface Props {
  benefitProgramAgreement: BenefitProgramAgreement
}

export const BenefitProgramAgreementsTableRow: React.FC<Props> = ({ benefitProgramAgreement }) => {
  const navigate = useNavigate()

  const handleClick = React.useCallback(() => {
    navigate(NavigationPath.BenefitProgramAgreementsDetails.replaceParam(':id', String(benefitProgramAgreement.id)))
  }, [benefitProgramAgreement.id])

  return (
    <Table.Row className="cursor-pointer" onClick={handleClick}>
      <Table.Cell className="vertical-align-middle">{benefitProgramAgreement.number}</Table.Cell>
      <Table.Cell className="vertical-align-middle">{benefitProgramAgreement.client.name}</Table.Cell>
      <Table.Cell className="vertical-align-middle">
        {benefitProgramAgreement.client.email} <br />
        {benefitProgramAgreement.client.phone}
      </Table.Cell>
      <Table.Cell className="vertical-align-middle">{benefitProgramAgreement.kind_display}</Table.Cell>
      <Table.Cell className="vertical-align-middle">
        <BenefitProgramAgreementStatusBadge benefitProgramAgreement={benefitProgramAgreement} />
      </Table.Cell>
      <Table.Cell className="vertical-align-middle">
        {toDefaultDateFormat(benefitProgramAgreement.started_at)}
      </Table.Cell>
      <Table.Cell className="vertical-align-middle">
        <i className="uil-eye font-16 cursor-pointer" onClick={handleClick} />
      </Table.Cell>
    </Table.Row>
  )
}
