import { createSelectOption } from '@helpers/utils'

export const PackageVipTypeSelectOptions = [
  createSelectOption('Zimowy', 'winter'),
  createSelectOption('Standardowy', 'standard'),
]

export const PackageVipStatusesSelectOptions = [
  createSelectOption('Wstępny', 'initial'),
  createSelectOption('Potwierdzony', 'confirmed'),
  createSelectOption('Wykorzystany', 'completed'),
  createSelectOption('Skasowany', 'removed'),
  createSelectOption('Wstępny (anulowany)', 'initial_canceled'),
]
