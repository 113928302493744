export const truncateText = (text: string, maxSize: number, ellipsis = '...') => {
  if (text.length <= maxSize) {
    return text
  }

  return text.slice(0, maxSize) + ellipsis
}

export const formatIban = (value: string) =>
  value
    .replace(/[^\dA-Z]/gi, '')
    .toUpperCase()
    .replace(/(.{4})/g, '$1 ')
    .trim()

export const firstLetterToUpperCase = (text: string | null) => {
  if (text === null) return

  return text.charAt(0).toUpperCase() + text.slice(1)
}
