import Table from '@components/table/table'
import * as React from 'react'
import { SubscriptionContractFeature } from '@modules/subscription-contract/models'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { YesNoBadge } from '@components/badges/yes-no'
import { CopyToClipboard } from '@components/copy-to-clipboard'
import { SubscriptionContractFeaturesTableRowPromocodeIsUsed } from '@modules/subscription-contract/details/information/features-table-row-promocode-is-used'

interface Props {
  lp: number
  feature: SubscriptionContractFeature
}

export const SubscriptionContractFeaturesTableRow = ({ lp, feature }: Props): JSX.Element => {
  const bookings = feature.promocode?.bookings || []

  const booking = bookings.at(-1)

  return (
    <Table.Row>
      <Table.Cell className="vertical-align-middle">{lp}.</Table.Cell>
      <Table.Cell className="vertical-align-middle">{feature.kind_display}</Table.Cell>
      <Table.Cell className="vertical-align-middle">
        {feature.promocode ? (
          <a href={feature.promocode.urls.admin} target="_blank">
            {feature.promocode.code} <CopyToClipboard value={feature.promocode.code} />
          </a>
        ) : (
          'Brak'
        )}
      </Table.Cell>
      <Table.Cell className="vertical-align-middle">
        {booking ? <a href={`/admin/dashboard/quick-search/?query=${booking}`}>{booking}</a> : 'brak'}
      </Table.Cell>
      {!feature.promocode ? (
        <Table.Cell colSpan={2} />
      ) : (
        <>
          <Table.Cell className="vertical-align-middle">
            <YesNoBadge value={feature.promocode.is_active} />
          </Table.Cell>
          <SubscriptionContractFeaturesTableRowPromocodeIsUsed promocode={feature.promocode} />
        </>
      )}
      <Table.Cell className="vertical-align-middle">
        {feature.promocode?.expire_after ? toDefaultDateFormat(feature.promocode?.expire_after) : 'Brak'}
      </Table.Cell>
    </Table.Row>
  )
}
