import { PackageDetails } from '@models/package'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { CardBody, Col, Row } from 'reactstrap'
import * as R from 'ramda'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useFormRequest } from '@components/hooks/use-api-request'
import { commonObjectPatch } from '@store/actions/generic-actions'
import { FormInput } from '@hyper/forms'
import { SaveButton } from '@hyper/button'
import { updatePackageDetails } from '@store/slices/package-slice'
import { Form } from '@hyper/forms/form'

interface FormInputs {
  invoice_custom_number: string
  name: string
  email: string
  phone: string
  invoice_company: string
  invoice_nip: string
  invoice_city: string
  invoice_street: string
  invoice_postcode: string
}

interface Props {
  packageDetails: PackageDetails
  toggleEdit: () => void
}

export const PackageDetailsClientInformationForm: React.FC<Props> = ({ packageDetails, toggleEdit }) => {
  const methods = useForm<FormInputs>({
    defaultValues: R.pick<FormInputs, keyof FormInputs>(
      [
        'invoice_custom_number',
        'name',
        'email',
        'phone',
        'invoice_company',
        'invoice_nip',
        'invoice_city',
        'invoice_street',
        'invoice_postcode',
      ],
      packageDetails,
    ),
  })

  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    dispatch(updatePackageDetails(await commonObjectPatch<PackageDetails>(packageDetails.urls.details, payload)))
    addSuccessNotification('Zmiany zostały zapisane')
    toggleEdit()
  }, methods.setError)

  const renderFormInput = React.useCallback(
    (name: string, label: string, readOnly?: boolean) => (
      <FormInput
        labelProps={{ className: 'col-md-4' }}
        formGroupClassName="align-items-center row"
        inputClassName="col-md-8"
        label={label}
        name={name}
        readOnly={readOnly}
      />
    ),
    [],
  )

  const hasInvoice = packageDetails.has_invoice && !packageDetails.invoice_custom_number

  return (
    <CardBody>
      <Form methods={methods} className="form" onSubmit={onSubmit}>
        <h4 className="mt-0 text-secondary mb-3">Dane klienta</h4>
        {renderFormInput('name', 'Osoba kontaktowa')}
        {renderFormInput('email', 'Adres email')}
        {renderFormInput('phone', 'Nr telefonu')}
        <h4 className="text-secondary mb-3">Dane faktury</h4>
        {renderFormInput('invoice_custom_number', 'Numer faktury', hasInvoice)}
        {renderFormInput('invoice_company', 'Nazwa firmy', hasInvoice)}
        {renderFormInput('invoice_nip', 'NIP', hasInvoice)}
        {renderFormInput('invoice_street', 'Ulica', hasInvoice)}
        {renderFormInput('invoice_city', 'Miasto', hasInvoice)}
        {renderFormInput('invoice_postcode', 'Kod pocztowy', hasInvoice)}
        <Row>
          <Col md={12} className="text-right px-0">
            <button type="button" className="btn btn-light mr-1" onClick={toggleEdit}>
              Anuluj
            </button>
            <SaveButton isSaving={isLoading} label="Zapisz dane" className="btn btn-green" />
          </Col>
        </Row>
      </Form>
    </CardBody>
  )
}
