import { Apartment } from '@models/apartment'
import { ApplicationImage } from '@modules/housekeeping/models'
import { BaseUrls } from '@models/base'

export enum ApplicationTypes {
  faults = 'faults',
  issues = 'issues',
}

export type IssueOrderStatus = 'open' | 'close'
export type FaultOrderStatus = 'open' | 'close'
export type FaultOrderExecutor = 'everest' | 'hpr'

export interface FaultOrderDetails {
  apartment: Apartment | null
  created: string
  created_by: string
  description: string
  description_after: string
  executor: FaultOrderExecutor
  executor_display: string
  finished_at: string
  finished_by: string
  id: number
  images: ApplicationImage[]
  name: string
  number: string
  resort_id: number
  status: FaultOrderStatus
  status_display: string
  urls: BaseUrls
}

export interface ApplicationsAggregation {
  issues: number
  faults: number
}

export interface IssueOrderDetails {
  apartment: Apartment | null
  created: string
  created_by: string
  description: string
  finished_at: string
  finished_by: string
  id: number
  images: ApplicationImage[]
  is_created_by_client: boolean
  name: string
  number: string
  reservation_number: string
  resort_id: number
  status: IssueOrderStatus
  status_display: string
  urls: BaseUrls
}
