import * as React from 'react'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { BarrierTicket } from '@models/barriers'
import { FormPlain } from '@hyper/forms'
import { CustomInput } from 'reactstrap'
import { extractInnerRef } from '@helpers/forms'
import classNames from 'classnames'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { add, endOfToday, startOfToday } from 'date-fns'
import { useFormContext } from 'react-hook-form'
import { BarrierTicketFormInputs } from '@modules/barriers/barrier-ticket-modal/index'

interface Props {
  ticket?: BarrierTicket
}

export const BarrierTicketModalFormDateTimeRange = ({ ticket }: Props): JSX.Element => {
  const { setValue, watch, register } = useFormContext<BarrierTicketFormInputs>()

  const range = watch('range')

  useDidUpdateEffect(() => {
    if (range) {
      setValue('datetime_from', startOfToday())
      setValue('datetime_to', add(endOfToday(), { days: parseInt(range, 10) }))
    }
  }, [range])

  return ticket ? (
    <DateFromToInput
      label="Okres"
      startDateName="datetime_from"
      endDateName="datetime_to"
      colSize={8}
      isClearable={false}
    />
  ) : (
    <FormPlain name="range" colSize={12}>
      <label>Dostęp do dnia</label>
      <div className="d-flex flex-wrap">
        <CustomInput
          className="mr-3"
          type="radio"
          value={1}
          {...extractInnerRef(register('range'))}
          id="range_1_day"
          label="1 dzień"
        />
        <CustomInput
          className="mr-3"
          type="radio"
          value={3}
          {...extractInnerRef(register('range'))}
          id="range_3_days"
          label="3 dni"
        />
        <CustomInput
          className="mr-3"
          type="radio"
          value={7}
          {...extractInnerRef(register('range'))}
          id="range_7_days"
          label="7 dni"
        />
        <CustomInput
          className="mr-3"
          type="radio"
          value={14}
          {...extractInnerRef(register('range'))}
          id="range_14_days"
          label="14 dni"
        />
        <div className="d-flex align-items-center w-100 mt-2">
          <CustomInput
            type="radio"
            value={0}
            {...extractInnerRef(register('range'))}
            id="range_custom"
            label="wybierz z kalendarza"
          />
          <div className={classNames('col-7', { 'not-clickable': range !== '0' })}>
            <DateFromToInput
              label=""
              startDateName="datetime_from"
              endDateName="datetime_to"
              inputGroupClassName="mb-0"
              isClearable={false}
            />
          </div>
        </div>
      </div>
    </FormPlain>
  )
}
