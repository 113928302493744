import * as React from 'react'
import { NotificationTemplateSelect } from '@components/notification-box/notification-template-select'
import { IconWithText } from '@components/icon-with-text'
import { NotificationsEmptyMessage } from '@components/notification-box/notifications-empty-message'
import { NotificationMessage } from '@components/notification-box/notification-message'
import { NotificationMessages } from '@components/notification-box/notification-messages'
import { createSelectOption } from '@helpers/utils'
import { NotificationTemplate, SMSNotification } from '@models/notifications'

interface Props {
  notifications: SMSNotification[]
  notificationTemplates: NotificationTemplate[]
  previewUrl?: string
  onSend?: () => void
  isDisabled: boolean
}

export const NotificationSmsMessages = ({
  notifications,
  notificationTemplates,
  previewUrl,
  onSend,
  isDisabled,
}: Props): React.ReactNode => {
  const templateOptions = notificationTemplates.map(template =>
    createSelectOption(template.name, template.id, template.is_active === undefined ? false : !template.is_active),
  )

  return (
    <div>
      <NotificationTemplateSelect
        onSend={onSend}
        notificationKind="sms"
        previewUrl={previewUrl}
        name="sms_template"
        options={templateOptions}
        isDisabled={isDisabled}
        placeholder={
          <IconWithText
            icon="uil-comment-alt-message font-14"
            text="Wybierz treść SMS z szablonu"
            wrapperClassNames="text-muted"
            textClass="font-12"
          />
        }
      />
      <NotificationMessages
        noMessageComponent={NoMessageComponent}
        contentName="powiadomienia"
        notifications={notifications}
        renderNotification={notification => (
          <NotificationMessage
            notificationKind="sms"
            key={notification.id}
            title="Powiadomienie SMS"
            notification={notification}
            icon="uil-comment-alt-message"
          />
        )}
      />
    </div>
  )
}

const NoMessageComponent = (
  <NotificationsEmptyMessage image={require('@assets/images/no-sms-placeholder.svg')}>
    Brak powiadomień <br /> sms
  </NotificationsEmptyMessage>
)
