import * as React from 'react'
import { CleaningOrder } from '@modules/housekeeping/models'
import { HousekeepingCleaningOrdersRowKind } from '@modules/housekeeping/cleaning-orders/common/kind'
import { HousekeepingCleaningOrderSelectServiceKind } from '@modules/housekeeping/cleaning-orders/to-order/select-service-kind'
import { HousekeepingCleaningOrderOrderedByRow } from '@modules/housekeeping/cleaning-orders/common/ordered-by'
import { HousekeepingCleaningOrderOrderedRowActions } from '@modules/housekeeping/cleaning-orders/ordered/actions'
import { HousekeepingCleaningOrderApartment } from '@modules/housekeeping/cleaning-orders/to-order/row-apartment'
import { HousekeepingOrderGroupedRow } from '@modules/housekeeping/cleaning-orders/common/grouped-table/grouped-row'

interface HousekeepingCleaningOrderOrderedRowProps {
  cleaningOrders: CleaningOrder[]
}

export const HousekeepingCleaningOrderOrderedGroupedRows: React.FC<HousekeepingCleaningOrderOrderedRowProps> = ({
  cleaningOrders,
}) => (
  <HousekeepingOrderGroupedRow
    cleaningOrders={cleaningOrders}
    rowRenderer={cleaningOrder => (
      <tr key={cleaningOrder.id}>
        <HousekeepingCleaningOrderApartment cleaningOrder={cleaningOrder} showCheckedIn={true} showPriority={true} />
        <HousekeepingCleaningOrdersRowKind order={cleaningOrder} />
        <HousekeepingCleaningOrderSelectServiceKind cleaningOrder={cleaningOrder} />
        <HousekeepingCleaningOrderOrderedByRow
          ordered_at={cleaningOrder.ordered_at}
          ordered_by={cleaningOrder.ordered_by}
        />
        <HousekeepingCleaningOrderOrderedRowActions cleaningOrder={cleaningOrder} />
      </tr>
    )}
  />
)
