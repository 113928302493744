import * as React from 'react'
import { FormSelect } from '@hyper/forms/form-select'
import { createSelectOption } from '@helpers/utils'
import { CustomReactSelectOption } from '@components/custom-react-select'

interface SortByFilterProps {
  options: CustomReactSelectOption[]
}

export const SortByFilter: React.FC<SortByFilterProps> = ({ options }) => (
  <div className="d-flex col-3">
    <FormSelect
      options={options}
      name="sort_by"
      label="Sortuj według"
      formPlainProps={{ colSize: 7, colClassName: 'pl-0' }}
    />
    <FormSelect
      options={sortDirectionOptions}
      name="sort_direction"
      formPlainProps={{ colSize: 5, colStyle: { paddingTop: 23 }, colClassName: 'pr-0' }}
    />
  </div>
)

const sortDirectionOptions = [createSelectOption('Malejąco', 'desc'), createSelectOption('Rosnąco', 'asc')]
