import * as React from 'react'
import { useParams } from 'react-router-dom'
import { useDocumentTitle } from '@helpers/utils'
import { PageTitle } from '@hyper/page-title'
import { Card, CardBody } from 'reactstrap'
import { NavigationTabs, NavigationTabsButton } from '@components/navigation-tabs'
import { NavigationPath } from '@models/routes'
import { CmsReservationAppAccommodationTypes } from '@modules/cms/reservation-app/accommodation-types'
import { CmsReservationAppResorts } from '@modules/cms/reservation-app/resorts'
import { CmsReservationAppTextBlocks } from '@modules/cms/reservation-app/text-blocks'
import { CmsReservationAppImprovements } from '@modules/cms/reservation-app/improvements'
import { useMenuActive } from '@components/hooks/use-menu-active'

export type CmsReservationAppType = 'resort' | 'accommodation-type' | 'text-block' | 'improvements'

interface Params {
  type: CmsReservationAppType
}

export const CmsReservationAppView: React.FC = () => {
  const { type: currentType } = useParams<'type'>() as Params

  const pageTitle = 'Rezerwacje na www'
  useDocumentTitle(pageTitle)
  useMenuActive(NavigationPath.CmsReservationApp.replace(':type', 'resort'))

  const buttons: NavigationTabsButton[] = [
    {
      title: 'Ośrodki',
      isActive: currentType === 'resort',
      link: NavigationPath.CmsReservationApp.replace(':type', 'resort'),
    },
    {
      title: 'Zakwaterowania',
      isActive: currentType === 'accommodation-type',
      link: NavigationPath.CmsReservationApp.replace(':type', 'accommodation-type'),
    },
    {
      title: 'Bloki',
      isActive: currentType === 'text-block',
      link: NavigationPath.CmsReservationApp.replace(':type', 'text-block'),
    },
    {
      title: 'Udoskonalenia',
      isActive: currentType === 'improvements',
      link: NavigationPath.CmsReservationApp.replace(':type', 'improvements'),
    },
  ]

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitle} />
      <Card>
        <CardBody>
          <NavigationTabs buttons={buttons} />
          {currentType === 'resort' && <CmsReservationAppResorts />}
          {currentType === 'accommodation-type' && <CmsReservationAppAccommodationTypes />}
          {currentType === 'text-block' && <CmsReservationAppTextBlocks />}
          {currentType === 'improvements' && <CmsReservationAppImprovements />}
        </CardBody>
      </Card>
    </>
  )
}
