import * as React from 'react'
import { NavigationTabs, NavigationTabsButton } from '@components/navigation-tabs'
import { ScrollableModalTab, useModalWithScrollableTabs } from '@components/hooks/use-modal-with-scrollable-tabs'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { GastroCardDetails } from '@models/promotions'
import { useGastroProductProvider } from '@modules/products/hooks/use-gastro-product-provider'
import { GastroProductKind } from '@models/products'

type Tab = 'data' | 'history' | 'codes' | 'payments' | 'transactions' | 'notifications'

interface RefObject<T> {
  readonly current: T | null
}

interface Props {
  dataRef: RefObject<HTMLElement>
  historyRef: RefObject<HTMLElement>
  codesRef: RefObject<HTMLElement>
  paymentsRef: RefObject<HTMLElement>
  transactionsRef: RefObject<HTMLElement>
  notificationsRef: RefObject<HTMLElement>
  gastroCardDetails: GastroCardDetails
}

export const GastroDetailsTabs: React.FC<Props> = ({
  dataRef,
  notificationsRef,
  paymentsRef,
  codesRef,
  transactionsRef,
  historyRef,
  gastroCardDetails,
}) => {
  const [tab, setTab] = React.useState<Tab>('data')
  const gastroProduct = useGastroProductProvider(gastroCardDetails.kind as GastroProductKind)

  const tabs: ScrollableModalTab<Tab>[] = [
    { ref: dataRef, name: 'data' },
    { ref: paymentsRef, name: 'payments' },
    { ref: codesRef, name: 'codes' },
    { ref: transactionsRef, name: 'transactions' },
    { ref: historyRef, name: 'history' },
    { ref: notificationsRef, name: 'notifications' },
  ]

  const { handleTabClick } = useModalWithScrollableTabs<Tab>({
    modalId: 'gastro-card-details-dialog',
    setTab,
    tabs,
  })

  const buttons: NavigationTabsButton[] =
    gastroCardDetails.kind === 'normal'
      ? [
          {
            title: 'Dane podstawowe i voucher',
            isActive: tab == 'data',
            onClick: handleTabClick('data'),
          },

          {
            title: 'Kody dla znajomych',
            isActive: tab == 'codes',
            onClick: handleTabClick('codes'),
            isSuperuser: true,
          },
          {
            title: 'Płatności',
            isActive: tab == 'payments',
            onClick: handleTabClick('payments'),
          },
          {
            title: 'Transakcje',
            isActive: tab == 'transactions',
            onClick: handleTabClick('transactions'),
          },
          {
            title: 'Powiadomienia',
            isActive: tab == 'notifications',
            onClick: handleTabClick('notifications'),
          },
        ]
      : [
          {
            title: 'Dane podstawowe i voucher',
            isActive: tab == 'data',
            onClick: handleTabClick('data'),
          },
          {
            title: 'Płatności',
            isActive: tab == 'payments',
            onClick: handleTabClick('payments'),
          },
          {
            title: 'Transakcje',
            isActive: tab == 'transactions',
            onClick: handleTabClick('transactions'),
          },
        ]

  const user = useAuthenticatedUser()
  const filteredButtons = buttons
    .filter(button => (user.is_superuser ? true : !button.isSuperuser))
    .filter(row => (row.title === 'Płatności' ? gastroProduct.wallet_kind === 'fiscalization' : true))

  return (
    <div className="modal-details__tabs-wrapper">
      <NavigationTabs buttons={filteredButtons} />
    </div>
  )
}
