import * as React from 'react'

interface Props<T> {
  title: string
  renderItem: (item: T) => React.ReactNode
  items: T[]
}

export const ReservationSidebarSummaryBoxSection = <T,>({ renderItem, title, items }: Props<T>): React.ReactNode => {
  if (!items.length) return null

  return (
    <>
      <hr className="reservation-create__summary-box__separator my-2" />
      <div className="px-2">
        <h4 className="font-11 fw-semi-bold text-dark">{title}:</h4>
        {items.map(renderItem)}
      </div>
    </>
  )
}
