import * as React from 'react'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { getSubscriptionAppData } from '@store/actions/subscription-actions'
import { useApiRequest } from '@components/hooks/use-api-request'
import { setSubscriptionAppData, SubscriptionAppData } from '@store/slices/subscription-slice'

export const useSubscriptionAppData = (): SubscriptionAppData => {
  const appData = useAppSelector((state: RootState) => state.subscriptionState.appData)
  const dispatch = useAppDispatch()

  const { action: fetchSubscriptionAppData } = useApiRequest(async () => await dispatch(getSubscriptionAppData()))

  React.useEffect(() => {
    if (appData.status === 'unknown') {
      fetchSubscriptionAppData()
      dispatch(setSubscriptionAppData({ ...appData, status: 'loading' }))
    }
  }, [appData.status])

  return appData
}
