import * as React from 'react'
import { ColAuto } from '@hyper/col-auto'
import { Label } from 'reactstrap'
import { Link } from 'react-router-dom'
import { NavigationPath } from '@models/routes'

export const PackageWholesalePackagesFilterButtons = (): JSX.Element => (
  <ColAuto className="ml-auto px-0">
    <Label className="d-block">&nbsp;</Label>

    <Link to={NavigationPath.PackageWholesaleList} className="btn btn-light mr-2">
      Lista pakietów hurtowych
    </Link>
  </ColAuto>
)
