import * as React from 'react'
import { Col, Row } from 'reactstrap'
import { Link, useParams } from 'react-router-dom'
import { ContentNotesCard } from '@components/content-notes/content-notes-card'
import { useAppData } from '@components/hooks/use-app-data'
import { HistoryBox } from '@modules/promotions/common/history-box'
import { TypedQueryResult } from '@api/base'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { useGetBenefitProgramAgreementDetailsQuery } from '@api/benefit-programs'
import { BenefitProgramAgreementDetails } from '@modules/benefit-program/models'
import { NavigationPath } from '@models/routes'
import { useDocumentTitle } from '@helpers/utils'
import { PageTitle } from '@hyper/page-title'
import { BenefitProgramAgreementDetailsTabs } from '@modules/benefit-program/agreements/details/tabs'
import { ButtonWithIcon } from '@components/button-with-icon'
import { BenefitProgramAgreementDetailsInformation } from '@modules/benefit-program/agreements/details/information'
import { BenefitProgramAgreementDetailsTerms } from '@modules/benefit-program/agreements/details/terms'
import { BenefitProgramAgreementDetailsFeatures } from '@modules/benefit-program/agreements/details/features'

const pageTitle = 'Informacje o umowie'

const pageBreadCrumbItems = [
  { label: 'Pracodawcy', path: NavigationPath.BenefitProgramDetails, active: false },
  { label: pageTitle, path: '', active: true },
]

type Params = 'id'

export const BenefitProgramAgreementDetailsView: React.FC = () => {
  useDocumentTitle(pageTitle)

  const { id } = useParams<Params>()

  const dataRef = React.useRef<HTMLDivElement>(null)
  const notesRef = React.useRef<HTMLDivElement>(null)

  const {
    content_types: { benefitprogramagreement: contentType },
  } = useAppData()

  const {
    data: benefitProgramAgreementDetails,
    isLoading,
    refetch,
  } = useGetBenefitProgramAgreementDetailsQuery({
    id,
  }) as TypedQueryResult<BenefitProgramAgreementDetails>

  const programDetailsLink = NavigationPath.BenefitProgramDetails.replaceParam(
    ':id',
    benefitProgramAgreementDetails?.benefit_program.id ?? '',
  )

  return (
    <>
      <PageTitle breadCrumbItems={pageBreadCrumbItems} title={pageTitle} />
      <LoaderPlaceholder content={!isLoading}>
        <div className="d-flex justify-content-between">
          <BenefitProgramAgreementDetailsTabs dataRef={dataRef} notesRef={notesRef} />
          <Link to={programDetailsLink}>
            <ButtonWithIcon icon="uil-arrow-left" text="Wróć do pracodawcy" color="primary" />
          </Link>
        </div>

        {!!benefitProgramAgreementDetails && (
          <Row>
            <BenefitProgramAgreementDetailsInformation benefitProgramAgreement={benefitProgramAgreementDetails} />
            <Col md={6}>
              <BenefitProgramAgreementDetailsTerms benefitProgramAgreement={benefitProgramAgreementDetails} />
            </Col>
            <Col md={12}>
              <BenefitProgramAgreementDetailsFeatures benefitProgramAgreement={benefitProgramAgreementDetails} />
            </Col>
            <Col md={6}>
              <div ref={notesRef} />

              <ContentNotesCard
                objectId={benefitProgramAgreementDetails.id}
                contentType={contentType}
                notes={benefitProgramAgreementDetails.notes}
                onNotesUpdate={refetch}
                readOnly={false}
              />
            </Col>
            <Col md={6}>
              <HistoryBox history={benefitProgramAgreementDetails.history} />
            </Col>
          </Row>
        )}
      </LoaderPlaceholder>
    </>
  )
}
