import * as React from 'react'
import classNames from 'classnames'
import { useCopy } from '@components/hooks/use-copy'

interface Props {
  className?: string
  value: string
  icon?: string
}

export const CopyToClipboard: React.FC<Props> = ({ className, value, icon = 'uil-clipboard-alt' }) => {
  const { handleCopy, isCopied } = useCopy(value)

  return (
    <i
      className={classNames('uil cursor-pointer', className, icon, { 'text-success': isCopied })}
      onClick={handleCopy}
    />
  )
}
