import * as React from 'react'
import Table, { TableHeaderValue } from '@components/table/table'
import { Seller } from '@models/crm'
import { SellerListRow } from '@modules/seller/list/row'
import { SellerListFilterParams } from '@modules/seller/list/filter'

const headerValues: TableHeaderValue<Seller>[] = [
  { title: 'Nazwa', sortField: 'name' },
  { title: 'Płeć', sortField: 'gender' },
  { title: 'Dział', sortField: 'department' },
  { title: 'Grupa', sortField: 'group' },
  { title: 'Numer telefonu', sortField: 'phone' },
]

interface Props {
  sellers: Seller[]
  isLoading: boolean
  filters: SellerListFilterParams
  setFilters: (filters: SellerListFilterParams) => void
}

export const SellerListTable: React.FC<Props> = ({ isLoading, sellers, filters, setFilters }) => (
  <Table
    className="table-hover w-100"
    headerValues={headerValues}
    isLoading={isLoading}
    filters={filters}
    setFilters={setFilters}
  >
    {sellers.map(seller => (
      <SellerListRow key={seller.id} seller={seller} />
    ))}
  </Table>
)
