import * as React from 'react'
import { FormInput, FormPlain } from '@hyper/forms'
import { Label } from 'reactstrap'
import DatePickerInput from '@components/date/date-picker-input'
import { FormSelect } from '@hyper/forms/form-select'
import { useGroupedSellers } from '@components/hooks/use-grouped-sellers'

export const RecommendationGiftDialogBase: React.FC = () => {
  const sellers = useGroupedSellers([])

  return (
    <>
      <FormSelect options={sellers} name="seller" label="Sprzedawca" formPlainProps={{ colSize: 6 }} />

      <FormInput colSize={4} label="Imię i nazwisko osoby polecającej" name="recommending_person_name" />
      <FormInput colSize={4} label="Imię i nazwisko osoby poleconej" name="recommended_person_name" />
      <FormPlain colSize={4} name="received_date">
        <Label>Data otrzymania oświadczenia</Label>
        <DatePickerInput name="received_date" wrapperClassName="flex-grow-1" />
      </FormPlain>
      <FormInput colSize={6} label="Numer zakupu (polecającego)" name="recommending_related_number" />
      <FormInput colSize={6} label="Numer zakupu (poleconego)" name="recommended_related_number" />
    </>
  )
}
