import * as React from 'react'
import { Col, Row } from 'reactstrap'
import { formatDate, parseISODate } from '@helpers/date-helper'
import { NewFeedingCalculationDetails } from '@modules/reception/checkin/step-feeding/new-feeding/models'
import { ReceptionBookingNewFeedingDayRowCheckboxVariant } from '@modules/reception/checkin/step-feeding/new-feeding/day-row/reception-booking-new-feeding-day-row-checkbox-variant'

interface Calculation {
  breakfast: NewFeedingCalculationDetails | null
  dinner: NewFeedingCalculationDetails | null
}

interface Position {
  last: boolean
  first: boolean
}

interface Props {
  date: string
  calculationDetails: NewFeedingCalculationDetails[]
  position: Position
}

export const ReceptionBookingNewFeedingDayRow = ({ calculationDetails, date, position }: Props): JSX.Element | null => {
  const day = parseISODate(date)
  if (!day) return null

  const { breakfast, dinner }: Calculation = calculationDetails.reduce((acc, curr) => ({ ...acc, [curr.kind]: curr }), {
    breakfast: null,
    dinner: null,
  })

  return (
    <Row className="py-2 border-bottom border-light">
      <Col md={3} className="text-right">
        <div className="w-75">
          <span>{formatDate(day, 'EE')}</span>
          <span className="fw-semi-bold ml-1">{formatDate(day, 'd.LL')}</span>
        </div>
      </Col>
      <Col md={5}>
        {breakfast && (
          <ReceptionBookingNewFeedingDayRowCheckboxVariant
            isEdge={position.first}
            edgeText="Dodaj śniadanie"
            calculation={breakfast}
          />
        )}
      </Col>
      <Col md={4}>
        {dinner && (
          <ReceptionBookingNewFeedingDayRowCheckboxVariant
            isEdge={position.last}
            edgeText="Dodaj obiadokolację"
            calculation={dinner}
          />
        )}
      </Col>
    </Row>
  )
}
