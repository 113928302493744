import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { IconWithText } from '@components/icon-with-text'
import { ReceptionBookingCheckInPreviewPersonalDataRow } from '@modules/reception/checkin/preview/personal-data/reception-booking-check-in-preview-personal-data-row'
import { ReceptionBookingCheckInPreviewPersonalDataParkingRow } from '@modules/reception/checkin/preview/personal-data/reception-booking-check-in-preview-personal-data-parking-row'
import { ReceptionBookingCheckInPreviewPersonalDataSignature } from '@modules/reception/checkin/preview/personal-data/reception-booking-check-in-preview-personal-data-signature'

interface Props {
  bookingDetails: ReceptionBookingDetails
}

export const ReceptionBookingCheckInPreviewPersonalDataDocument = ({ bookingDetails }: Props): JSX.Element => (
  <>
    <ReceptionBookingCheckInPreviewPersonalDataRow
      title="Seria i numer dokumentu:"
      content={
        <IconWithText
          icon="uil-check font-16 lh-1"
          text={bookingDetails.service_room_data.id_card}
          wrapperClassNames="text-green-dark text-strong"
        />
      }
    />
    <ReceptionBookingCheckInPreviewPersonalDataRow
      title="Podpis Gościa:"
      content={
        <ReceptionBookingCheckInPreviewPersonalDataSignature
          bookingDetails={bookingDetails}
          className="row mx-0"
          signatureClassName="mr-2"
        />
      }
    />
    <ReceptionBookingCheckInPreviewPersonalDataParkingRow bookingDetails={bookingDetails} />
  </>
)
