import * as React from 'react'
import { ServiceBand } from '@modules/bands/service-bands/models'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { ButtonWithIcon } from '@components/button-with-icon'
import { deleteServiceBand } from '@store/actions/service-band-actions'
import { useAppDispatch } from '@store/index'

interface Props {
  serviceBand: ServiceBand
}

export const ServiceBandDeleteButton: React.FC<Props> = ({ serviceBand }) => {
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading, action: handleRemove } = useApiRequest(async () => {
    dispatch(await deleteServiceBand(serviceBand))
    addSuccessMessage('Sukces', 'Opaska testowa została usunięta')
  })

  return (
    <CommonObjectConfirmAction isLoading={isLoading} handleAccept={handleRemove}>
      <ButtonWithIcon icon="uil-trash-alt" color="danger" text="Usuń" />
    </CommonObjectConfirmAction>
  )
}
