import IconGeoAlt from '@components/icons/geo-alt'
import * as React from 'react'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { getResortMap } from '@store/actions/timeline-actions'
import { Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip } from 'reactstrap'
import { resortsMapSelector } from '@store/selectors/dashboard'

interface Props {
  resort: string
}

const TimelineMap: React.FC<Props> = ({ resort }) => {
  const dispatch = useAppDispatch()

  const resortName = useAppSelector(resortsMapSelector)[parseInt(resort, 10)]?.name || ''
  const map = useAppSelector((state: RootState) => state.timelineState.map)

  const [isDialog, setDialog] = React.useState(false)

  const handleHide = (): void => setDialog(false)

  const showDialog = (): void => setDialog(true)

  React.useEffect(() => {
    if (isDialog) {
      dispatch(getResortMap(resort, handleHide))
    }
  }, [resort, isDialog])

  return (
    <>
      <IconGeoAlt onClick={showDialog} classNames="cursor-pointer no-outline" />
      <UncontrolledTooltip placement="top" target="icon-geo-alt">
        Włącz widok mapy
      </UncontrolledTooltip>
      <Modal size="lg" isOpen={isDialog} toggle={handleHide}>
        <ModalHeader toggle={handleHide}>{resortName}</ModalHeader>
        <ModalBody>
          <div className="calendar-map" data-testid="calendarMap" dangerouslySetInnerHTML={{ __html: map }} />
        </ModalBody>
        <ModalFooter>
          <button type="button" className="btn btn-light" onClick={handleHide}>
            Zamknij
          </button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default TimelineMap
