import * as React from 'react'
import { useSellers } from '@components/hooks/use-grouped-sellers'

interface Props {
  sellerId: number | null
  className?: string
  defaultValue?: string
}

export const TableSellerRow: React.FC<Props> = ({ sellerId, className, defaultValue }) => {
  const sellers = useSellers()

  const soldBy = React.useMemo(() => {
    if (!sellerId) {
      return defaultValue || ''
    }

    const seller = sellers.find(row => row.id === sellerId)

    if (seller) {
      return `${seller.name} (${seller.department_display})`
    }

    return defaultValue || ''
  }, [sellers, sellerId, defaultValue])

  return <span className={className}>{soldBy}</span>
}
