import { useToggle } from '@components/hooks/use-toggle'
import { NewFeedingCalculations } from '@modules/reception/checkin/step-feeding/new-feeding/models'
import React from 'react'

interface Response {
  toggleIsPaymentByWalletSelected: () => void
  isPaymentByWalletSelected: boolean
}

export const useNewFeedingVoucherPayment = (initialFeedingCalculations: NewFeedingCalculations | null): Response => {
  const isSomeFeedingAlreadyPaidByWallet = !!initialFeedingCalculations?.details.some(
    calculation => calculation.is_paid_by_wallet,
  )

  const [isPaymentByWalletSelected, toggleIsPaymentByWalletSelected, setIsPaymentByWalletSelected] = useToggle(
    isSomeFeedingAlreadyPaidByWallet,
  )

  React.useEffect(() => {
    setIsPaymentByWalletSelected(isSomeFeedingAlreadyPaidByWallet)
  }, [isSomeFeedingAlreadyPaidByWallet])

  return {
    isPaymentByWalletSelected,
    toggleIsPaymentByWalletSelected,
  }
}
