import { useAppData } from '@components/hooks/use-app-data'
import * as React from 'react'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectGet } from '@store/actions/generic-actions'
import { ReceptionBookingDetails } from '@models/reception'

interface PriceList {
  adult: FeedingPriceListItem
  baby: FeedingPriceListItem
  child: FeedingPriceListItem
  children_3_4: FeedingPriceListItem
  children_5_12: FeedingPriceListItem
}

export interface FeedingPriceListItem {
  booking_guest_type_name: string
  breakfast_price_brutto: string | null
  breakfast_price_brutto_in_hb: string | null
  dinner_price_brutto: string | null
  dinner_price_brutto_in_hb: string | null
}

const guestKinds = ['baby', 'children_3_4', 'children_5_12', 'child', 'adult']

interface Response {
  availability: { hasBreakfast: boolean; hasDinner: boolean; hasBreakfastHb: boolean; hasDinnerHb: boolean }
  isLoading: boolean
  guestKinds: typeof guestKinds
  getPriceListItem: (kind: string) => FeedingPriceListItem | null
}

export const useNewFeedingPricelist = (bookingDetails: ReceptionBookingDetails): Response => {
  const [priceList, setPriceList] = React.useState<PriceList | null>(null)
  const { urls } = useAppData()

  const { action: fetchPricelist, isLoading } = useApiRequest(async () => {
    setPriceList(
      await commonObjectGet<PriceList>(urls.feeding.feeding_price_list, { resort: bookingDetails.resort_id }),
    )
  })

  React.useEffect(() => {
    fetchPricelist()
  }, [])

  const availability = priceList
    ? Object.values(priceList).reduce(
        (prev, object) => ({
          hasBreakfast: prev.hasBreakfast || object.breakfast_price_brutto,
          hasBreakfastHb: prev.hasBreakfastHb || object.breakfast_price_brutto_in_hb,
          hasDinner: prev.hasDinner || object.dinner_price_brutto,
          hasDinnerHb: prev.hasDinnerHb || object.dinner_price_brutto_in_hb,
        }),
        { hasBreakfast: false, hasBreakfastHb: false, hasDinner: false, hasDinnerHb: false },
      )
    : { hasBreakfast: false, hasBreakfastHb: false, hasDinner: false, hasDinnerHb: false }

  const getPriceListItem = (kind: string) => (priceList ? priceList[kind] : null)

  return {
    guestKinds,
    availability,
    isLoading,
    getPriceListItem,
  }
}
