import * as React from 'react'
import Table from '@components/table/table'
import { useModal } from '@components/modals/use-modal'
import { RodoAcquiringPoint } from '@modules/crm/rodo-agreements/models'

interface Props {
  acquiringPoint: RodoAcquiringPoint
}

export const RodoAgreementsAcquiringPointsTableRow = ({ acquiringPoint }: Props): JSX.Element => {
  const [handleEdit] = useModal('', { acquiringPoint })
  return (
    <Table.Row>
      <Table.Cell>''</Table.Cell>
      <Table.Cell className="text-right">
        <i className="uil-eye cursor-pointer text-muted font-18 mr-2" onClick={handleEdit} />
      </Table.Cell>
    </Table.Row>
  )
}
