import * as React from 'react'
import { CashbackVoucherDetails } from '@modules/products/cashback-vouchers/models'
import { ProductCardTitle } from '@components/products/product-card-title'
import { Card, CardBody, Col } from 'reactstrap'
import { SubscriptionDetailsInformationRow } from '@modules/subscription/details/information/row'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { NavigationPath } from '@models/routes'
import { Link } from 'react-router-dom'
import { getCashbackVoucherStatusColor } from '@modules/products/cashback-vouchers/helpers'
import classNames from 'classnames'
import { formatPrice } from '@helpers/utils'

interface Props {
  cashbackVoucherDetails: CashbackVoucherDetails
}

export const CashbackVoucherBaseData: React.FC<Props> = ({ cashbackVoucherDetails }) => (
  <>
    <Col md={6}>
      <Card>
        <CardBody>
          <ProductCardTitle
            title="Szczegóły vouchera rePLAY!"
            isEditAllowed={false}
            adminUrl={cashbackVoucherDetails.urls.admin}
          />
          <table className="table table-sm mb-0">
            <tbody>
              <SubscriptionDetailsInformationRow name="Status">
                <span
                  className={classNames(
                    'badge',
                    `badge-${getCashbackVoucherStatusColor(cashbackVoucherDetails.status)}`,
                  )}
                >
                  {cashbackVoucherDetails.status_display}
                </span>
              </SubscriptionDetailsInformationRow>

              <SubscriptionDetailsInformationRow name="Termin ważności">
                {toDefaultDateFormat(cashbackVoucherDetails.expire_after)}
              </SubscriptionDetailsInformationRow>
              <SubscriptionDetailsInformationRow name="Wartość vouchera">
                {formatPrice(cashbackVoucherDetails.custom_available_amount || cashbackVoucherDetails.available_amount)}
              </SubscriptionDetailsInformationRow>
              <SubscriptionDetailsInformationRow name="Pozostało do wykorzystania">
                {formatPrice(cashbackVoucherDetails.rest_to_use)}
              </SubscriptionDetailsInformationRow>
              <SubscriptionDetailsInformationRow name="Data utworzenia">
                {toDefaultDateFormat(cashbackVoucherDetails.created)}
              </SubscriptionDetailsInformationRow>
            </tbody>
          </table>
        </CardBody>
      </Card>
    </Col>
    <Col md={6}>
      <Card>
        <CardBody>
          <ProductCardTitle title="Dane klienta" isEditAllowed={false} />
          <table className="table table-sm mb-0">
            <tbody>
              <SubscriptionDetailsInformationRow name="Imię i Nazwisko">
                <Link
                  className="text-primary text-semi-strong"
                  to={NavigationPath.CrmClientsWitParams.replace(
                    ':client_id',
                    String(cashbackVoucherDetails.client.id),
                  )}
                >
                  {cashbackVoucherDetails.client.name}
                </Link>
              </SubscriptionDetailsInformationRow>
              <SubscriptionDetailsInformationRow name="Adres e-mail">
                {cashbackVoucherDetails.client.email}
              </SubscriptionDetailsInformationRow>
            </tbody>
          </table>
        </CardBody>
      </Card>
    </Col>
  </>
)
