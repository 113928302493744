import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import * as R from 'ramda'
import * as React from 'react'
import { getSellers } from '@store/actions/crm-actions'
import { useApiRequest } from '@components/hooks/use-api-request'
import { Seller } from '@models/crm'
import { createSelectOption } from '@helpers/utils'

export const useSellers = (): Seller[] => {
  const sellersStatus = useAppSelector((state: RootState) => state.crmState.sellersStatus)
  const sellers = useAppSelector((state: RootState) => state.crmState.sellers)
  const dispatch = useAppDispatch()

  const { action: fetch } = useApiRequest(async () => {
    if (sellersStatus === 'unknown') {
      await dispatch(getSellers())
    }
  })

  React.useEffect(() => {
    fetch()
  }, [sellers])

  return sellers
}

export interface SellersList {
  label: string
  value: number
  department: string
}

export const useSellersOptions = () => {
  const sellers = useSellers()
  return React.useMemo(
    () =>
      sellers
        .filter(row => row.name)
        .map(seller => ({
          label: seller.name,
          value: seller.id,
          department: seller.department_display,
        })),
    [sellers],
  )
}

export const useGroupedSellers = (predefinedOptions?: any) => {
  const sellersList = useSellersOptions()

  return React.useMemo(() => {
    const grouped = R.groupBy<SellersList>(R.prop('department'))(sellersList)
    const options: any[] = typeof predefinedOptions !== 'undefined' ? predefinedOptions : SellerDepartmentOptions

    Object.keys(grouped).map(key => {
      options.push({
        label: key,
        options: grouped[key],
      })
    })
    return options
  }, [sellersList, predefinedOptions])
}

export const SellerDepartmentOptions = [
  createSelectOption('Wszyscy', null),
  createSelectOption('Nieprzypisani', 'sellers_empty'),
  createSelectOption('Pracownicy BOK', 'sellers_bok'),
  createSelectOption('Pracownicy BOS', 'sellers_bos'),
  createSelectOption('Pracownicy B2B', 'sellers_b2b'),
  createSelectOption('Pracownicy Recepcji', 'sellers_reception'),
  createSelectOption('Opiekunowie w resorcie', 'sellers_resort_seller'),
  createSelectOption('Gastronomia', 'sellers_gastronomy'),
]
