import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useFormRequest } from '@components/hooks/use-api-request'
import { SaveButton } from '@hyper/button'
import { useAppData } from '@components/hooks/use-app-data'
import { CustomFileInput } from '@components/custom-file-input'
import { UploaderAcceptableFile } from '@components/file-uploader'
import { commonObjectPost } from '@store/actions/generic-actions'
import { AxiosError } from 'axios'
import classnames from 'classnames'
import { XMLImportErrorResponse, XMLImportErrorsAnalyzeTable } from '@components/xml-import-errors-analyze-table'
import { getRentalProducts } from '@store/actions/rental-warehouse-actions'
import { useAppDispatch } from '@store/index'
import { RentalProductFiltersParams } from '@modules/rental/models'
import { useRentalAppData } from '@modules/rental/use-rental-app-data'

interface ImportErrorLabel {
  category: string
  code: string
  name: string
  warehouse: string
}

export interface FormInputs {
  file: File | null
}

interface Props extends BaseModalProps {
  filters: RentalProductFiltersParams
}

export const RentalProductsImportModal: React.FC<Props> = ({ toggleIsVisible, filters }) => {
  const [importError, setImportError] = React.useState<XMLImportErrorResponse<ImportErrorLabel> | null>(null)

  const { urls } = useAppData()
  const { urls: rentalWarehouseAppDataUrls } = useRentalAppData()

  const dispatch = useAppDispatch()

  const methods = useForm<FormInputs>({ defaultValues: { file: null } })

  const handleError = (error: AxiosError) => {
    if (error.response?.status === 400) {
      setImportError(error.response.data as XMLImportErrorResponse<ImportErrorLabel>)
    }
  }

  const file = useWatch({ control: methods.control, name: 'file' })

  const { action: handleSubmit, isLoading } = useFormRequest(
    async () => {
      setImportError(null)
      methods.clearErrors()

      const formData = new FormData()
      formData.append('file', file ?? '')

      await commonObjectPost(urls.rental.products_import, formData)
      dispatch(await getRentalProducts(filters))

      toggleIsVisible()
    },
    null,
    { customErrorHandler: handleError },
  )

  React.useEffect(() => {
    setImportError(null)
  }, [file])

  return (
    <FormProvider {...methods}>
      <ModalHeader toggle={toggleIsVisible}>Import produktów</ModalHeader>
      <ModalBody className="pb-0">
        <Row>
          <CustomFileInput
            label={<Label rentalWarehouseAppDataUrls={rentalWarehouseAppDataUrls} />}
            inputName="file"
            formPlainProps={{ colSize: 12 }}
            fileUploaderClassName={classnames({ 'border border-danger': !!importError })}
            acceptFiles={UploaderAcceptableFile.XLSX}
          />
          <XMLImportErrorsAnalyzeTable importError={importError} />
        </Row>
      </ModalBody>
      <ModalFooter className="d-flex">
        <Button className="btn btn-light" type="button" onClick={toggleIsVisible}>
          Anuluj
        </Button>
        <SaveButton
          label="Zapisz"
          className="btn-green btn"
          isSaving={isLoading}
          disabled={!file}
          onClick={handleSubmit}
        />
      </ModalFooter>
    </FormProvider>
  )
}

const Label = ({ rentalWarehouseAppDataUrls }) => (
  <div className="d-flex justify-content-between w-100 align-items-center">
    <span>Plik z produktami do importu</span>
    <span className="font-10">
      <a href={rentalWarehouseAppDataUrls.products_import_example}>Przykładowy plik</a>
    </span>
  </div>
)
