import * as React from 'react'
import { NavigationPath } from '@models/routes'
import { useAppDispatch, useAppSelector } from '@store/index'
import { getRcpUserDetail } from '@store/actions/rcp-actions'
import { PageTitle } from '@hyper/page-title'
import { Card, CardBody } from 'reactstrap'
import Spinner from '@hyper/spinner'
import { Tabs } from '@modules/rcp/user/tabs'
import { useDocumentTitle } from '@helpers/utils'
import { useParams } from 'react-router-dom'
import { useApiRequest } from '@components/hooks/use-api-request'
import { rcpUserDetailSelector } from '@store/slices/rcp-slice'

interface Props {
  children?: React.ReactNode
}

export const UserBaseDetail: React.FC<Props> = ({ children }) => {
  const { id = '' } = useParams<'id'>()
  const dispatch = useAppDispatch()

  const user = useAppSelector(rcpUserDetailSelector)

  const { isLoading, action: fetch } = useApiRequest(async () => await dispatch(getRcpUserDetail(id)))

  React.useEffect(() => {
    fetch()
  }, [])

  const pageTitle = user ? `Pracownik ${user?.fullname}` : ''

  useDocumentTitle(pageTitle)

  return isLoading ? (
    <div className="text-center mt-5">
      <Spinner className="text-primary" />
    </div>
  ) : (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: 'Lista pracowników', path: NavigationPath.Rcp, active: false },
          { label: pageTitle, path: '', active: true },
        ]}
        title={pageTitle}
      />
      <Card>
        <CardBody>
          <Tabs user={user} />
          {children}
        </CardBody>
      </Card>
    </>
  )
}
