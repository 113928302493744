import { packageWholesalesSelector } from '@store/slices/package-wholesale-slice'
import { PackageWholesale } from '@modules/package-wholesale/models'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { useSellers } from '@components/hooks/use-grouped-sellers'
import { Seller } from '@models/crm'
import { useAppSelector } from '@store/index'

export const usePackageWholesaleList = (): PackageWholesale[] => {
  const packageList = useAppSelector(packageWholesalesSelector)
  const user = useAuthenticatedUser()
  const sellers = useSellers()

  if (
    user.hasPerm(UserPermission.PackageWholesaleCanViewAll) ||
    user.hasPerm(UserPermission.PackageWholesaleCanManageAll)
  ) {
    return packageList
  }

  if (
    user.hasPerm(UserPermission.PackageWholesaleCanManageGroup) ||
    user.hasPerm(UserPermission.PackageWholesaleCanViewGroup)
  ) {
    return packageList.filter(
      row =>
        (user.seller?.id ? row.sellers_ids.includes(user.seller.id) : false) ||
        isUserOwnerGroup(row.sellers_ids, sellers, user.seller),
    )
  }

  return packageList.filter(row => (user.seller?.id ? row.sellers_ids.includes(user.seller.id) : false))
}

const isUserOwnerGroup = (sellersIds: number[], sellers: Seller[], userSeller: Seller | undefined) => {
  const seller = sellers.find(row => sellersIds.includes(row.id))

  return seller?.group === userSeller?.group
}
