import * as React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { CafeteriaCode, CafeteriaDetails } from '@models/cafeteria'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { CafeteriaDetailsCodesRow } from '@modules/cafeteria/details/codes/row'
import { useSelectableRows } from '@components/table/use-selectable-rows'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { CafeteriaDetailsCodesActions } from '@modules/cafeteria/details/codes/actions'
import { CafeteriaDetailsCodesDownload } from '@modules/cafeteria/details/codes/download'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'

const headerValues: TableHeaderValue[] = [
  { title: 'Kod' },
  { title: 'Użyty' },
  { title: 'Aktywny' },
  { title: 'Rezerwacje' },
  { title: 'Data ważności' },
]
interface Props {
  cafeteria: CafeteriaDetails
}
const defaultFilters: TableFilters = {
  search: '',
  ordering: '',
  page: 1,
  page_size: 9999,
}
export const CafeteriaDetailsCodes = React.forwardRef<HTMLDivElement, Props>(({ cafeteria }, ref) => {
  const [filters, setFilters] = React.useState<TableFilters>(defaultFilters)
  const { selectableHeader, selectableCell, selectedRows, clearSelectedRows } = useSelectableRows<CafeteriaCode>(
    cafeteria.codes,
    cafeteria.codes.filter(row => row.is_used).map(row => row.id),
  )

  const handleSearchChange = (field, value) => setFilters({ ...filters, search: value })

  const filteredCodes = cafeteria.codes.filter(row =>
    filters.search ? row.code.includes(String(filters.search)) : true,
  )

  const user = useAuthenticatedUser()

  const allowToRemoveCodes = cafeteria.status !== 'close' && user.hasPerm(UserPermission.ProductsCafeteriaCanDelete)

  return (
    <div ref={ref}>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <h4 className="mt-0 text-secondary mb-3">Lista kodów</h4>
              <Row className="mx-0">
                {allowToRemoveCodes && (
                  <CafeteriaDetailsCodesActions
                    clearSelectedRows={clearSelectedRows}
                    selectedRows={selectedRows}
                    cafeteria={cafeteria}
                  />
                )}
                <SearchFilter setValue={handleSearchChange} />
              </Row>
              <Table
                maxHeight="330px"
                selectableHeader={selectableHeader}
                className="table-hover w-100"
                headerValues={headerValues}
                isLoading={false}
                showPagination={false}
                filters={filters}
                setFilters={setFilters}
              >
                {filteredCodes.map(row => (
                  <CafeteriaDetailsCodesRow key={row.id} code={row}>
                    {selectableCell(row)}
                  </CafeteriaDetailsCodesRow>
                ))}
              </Table>
              {user.hasPerm(UserPermission.ReportsCafeteriaCode) && (
                <div className="text-right mt-3">
                  <CafeteriaDetailsCodesDownload cafeteriaDetails={cafeteria} />
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
})
