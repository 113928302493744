import * as React from 'react'
import { ShopProduct } from '@models/shop'
import { KeyboardScanner } from '@components/keyboard-scanner'

interface ShopProductSelectScannerProps {
  products: ShopProduct[]
  handleAdd: (product: ShopProduct) => void
}

export const ShopProductSelectScanner: React.FC<ShopProductSelectScannerProps> = ({ products, handleAdd }) => {
  const productsWithBarcode = products.filter(product => product.barcode_ean)

  const onScanComplete = ean => {
    const foundedProduct = productsWithBarcode.find(product => product.barcode_ean === ean)

    if (foundedProduct) {
      handleAdd(foundedProduct)
    }
  }

  return <KeyboardScanner onScanComplete={onScanComplete} />
}
