import * as React from 'react'
import { ImprovementOrderItem } from '@modules/housekeeping/models'
import { ImprovementIcon } from '@components/improvement-icon'
import { ImprovementTooltip, useTooltipImprovementsGrouped } from '@modules/reception/common/improvement-tooltip'

interface Props {
  items: ImprovementOrderItem[]
  id: number
}

export const HousekeepingImprovementOrdersRowImprovements = React.memo(({ id, items }: Props) => {
  const { improvementsGrouped } = useTooltipImprovementsGrouped({ elements: items })

  const tooltipId = `receptionBookingImprovements-${id}`

  return (
    <ImprovementTooltip tooltipId={tooltipId} improvementsGrouped={improvementsGrouped}>
      {improvementsGrouped.map(improvement => (
        <ImprovementIcon
          key={improvement.code}
          improvementCode={improvement.code}
          className="text-secondary font-14 mr-1"
        />
      ))}
    </ImprovementTooltip>
  )
})
