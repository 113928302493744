export const promoTypes = [
  ['PERCENT', 'Procent - obniżenie wartości o x procent'],
  ['MINUS_AMOUNT', 'Wartość - obniżenie wartości o x złotych'],
  ['AMOUNT', 'Wartość - obniżenie wartości do x złotych'],
]
export const kinds = [
  ['VOUCHER', 'Voucher'],
  ['GIFT_VOUCHER', 'Voucher prezentowy (abonamenty)'],
  ['NORMAL', 'Normalny promocode'],
  ['BOCIAN', 'Klubu Bocian'],
  ['BENEFIT_SYSTEM', 'Benefit System'],
  ['RECOMMENDATION_VOUCHER', 'Voucher z Polecenia'],
]
