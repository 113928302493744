import * as React from 'react'
import { Card, CardBody } from 'reactstrap'
import { ProductCardTitle } from '@components/products/product-card-title'
import { PackageDetailsRow } from '@modules/package/details/information/row'
import { ClientCouponItem } from '@modules/coupons/models'
import { getStatusClassIcon } from '@modules/promotions/gastro-cards/utils'
import { BadgeWithIcon } from '@components/badges/BadgeWithIcon'
import { formatPrice } from '@helpers/utils'
import { toDefaultDateFormat, toDefaultDateTimeFormat } from '@helpers/date-helper'

interface Props {
  coupon: ClientCouponItem
}

export const CouponItemDetailsBaseDataCard: React.FC<Props> = ({ coupon }) => {
  const [badgeClass, badgeIcon] = getStatusClassIcon(coupon.status)

  return (
    <Card>
      <CardBody>
        <ProductCardTitle title="Szczegóły kuponu" isEditAllowed={false} />
        <div className="table-responsive overflow-hidden">
          <table className="table table-sm mb-2">
            <tbody>
              <PackageDetailsRow label="Status">
                <BadgeWithIcon icon={badgeIcon} className={badgeClass} title={coupon.status_display} />
              </PackageDetailsRow>
              <PackageDetailsRow label="Kod kuponu">{coupon.code}</PackageDetailsRow>
              <PackageDetailsRow label="Wartość">{formatPrice(coupon.value)}</PackageDetailsRow>
              <PackageDetailsRow label="Metoda płatności">{coupon.payment_method}</PackageDetailsRow>
              <PackageDetailsRow label="Data utworzenia">
                {toDefaultDateTimeFormat(coupon.purchase_date)}
              </PackageDetailsRow>
              <PackageDetailsRow label="Data ważności">
                {toDefaultDateTimeFormat(coupon.expiration_date)}
              </PackageDetailsRow>

              <PackageDetailsRow label="Data użycia">
                {coupon.use_date ? toDefaultDateFormat(coupon.use_date) : '-'}
              </PackageDetailsRow>
              <PackageDetailsRow label="Rezerwacja">{coupon.booking_number}</PackageDetailsRow>
              <PackageDetailsRow label="Numer faktury">
                {coupon.invoice ? coupon.invoice.reservation_number : '-'}
              </PackageDetailsRow>
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  )
}
