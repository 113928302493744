import * as React from 'react'
import { usePackageAppData } from '@modules/package/hooks/use-package-app-data'
import { SubscriptionKind } from '@modules/subscription/models'

export const HPIPoolInfo: React.FC = () => {
  const appData = usePackageAppData()

  const {
    free_house_sea_codes,
    free_apartment_sea_codes,
    free_house_mountains_codes,
    free_apartment_mountains_codes,
    codes,
  } = appData.hpi_source_packages

  return (
    <div className="mb-3 d-flex">
      <span className="mr-2">
        <strong className="mr-1">Domki (morze):</strong>
        <HPIPoolInfoRow values={free_house_sea_codes} />
      </span>
      <span className="mr-2">
        <strong className="mr-1">Apartamenty (morze):</strong>
        <HPIPoolInfoRow values={free_apartment_sea_codes} />
      </span>
      <span className="mr-2">
        <strong className="mr-1">Domki (góry):</strong>
        <HPIPoolInfoRow values={free_house_mountains_codes} />
      </span>
      <span className="mr-2">
        <strong className="mr-1">Apartamenty (góry):</strong>
        <HPIPoolInfoRow values={free_apartment_mountains_codes} />
      </span>
      <span className="p-1">
        <strong className="mr-1">Pula:</strong>
        <HPIPoolInfoRow values={codes} />
      </span>
    </div>
  )
}

interface PropsRow {
  values: Record<SubscriptionKind, number>
}

export const HPIPoolInfoRow = ({ values }: PropsRow): JSX.Element => (
  <div>
    Złoty: {values.gold} Srebrny: {values.silver}
    <br />
    Brązowy: {values.brown} Szary: {values.gray}
  </div>
)
