import * as React from 'react'
import { FormPlain } from '@hyper/forms'
import { useFormContext } from 'react-hook-form'
import { CmsResortDetails } from '@modules/cms/models'
import { Col } from 'reactstrap'
import { FileUploader, UploaderAcceptableFile } from '@components/file-uploader'

interface Props {
  resort: CmsResortDetails
  resortMap: File | undefined
  setResortMap: (image: File | undefined) => void
  isLoading: boolean
}

export const CmsReservationAppResortFormMapSvg = ({
  resort,
  resortMap,
  setResortMap,
  isLoading,
}: Props): JSX.Element => {
  const { clearErrors } = useFormContext()

  const upload = file => {
    setResortMap(file)
    clearErrors()
  }

  return (
    <>
      <FormPlain name="map_svg">
        <label className="d-block">Mapka SVG</label>
        {(resortMap || resort.resort_map) && (
          <div className="mt-1">
            <img
              src={resortMap ? URL.createObjectURL(resortMap) : resort.resort_map}
              className="avatar-xl img-thumbnail"
            />
          </div>
        )}
      </FormPlain>
      <Col md={12} className="mb-3">
        <FileUploader
          isLoading={isLoading}
          upload={upload}
          maxFiles={1}
          file={resortMap}
          accept={UploaderAcceptableFile.Images}
          label="Przeciągnij i upuść zdjęcie lub kliknij by dodać mapkę"
        />
      </Col>
    </>
  )
}
