import * as React from 'react'
import { Promocode, PromocodeDetails } from '@models/promocode'
import { Spinner, Tooltip } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'
import { useToggle } from '@components/hooks/use-toggle'
import { commonObjectGet } from '@store/actions/generic-actions'
import { useApiRequest } from '@components/hooks/use-api-request'

interface PromocodeGroupCodeRowSendProps {
  promocode: Promocode
}

export const PromocodeCodeSend: React.FC<PromocodeGroupCodeRowSendProps> = ({ promocode }) => {
  const [tooltipOpen, toggleTooltipOpen] = useToggle()
  const [promocodeDetails, setPromocodeDetails] = React.useState<PromocodeDetails>()

  const tooltipKey = `promocodeSendTooltip${promocode.id}`
  const { action: fetch } = useApiRequest(async () => {
    setPromocodeDetails(await commonObjectGet<PromocodeDetails>(promocode.urls.details))
  })

  React.useEffect(() => {
    if (tooltipOpen) {
      fetch()
    }
  }, [tooltipOpen])

  const icon = promocode.is_sent ? 'uil-envelope-redo' : 'uil-envelope-alt'
  const [handleToggle] = useModal('PromocodeSendDialog', { promocode })

  return (
    <>
      <i
        id={tooltipKey}
        className={`${icon} cursor-pointer font-18 text-muted mr-1`}
        onClick={handleToggle}
        title="Wyślij"
      />

      <Tooltip
        placement="top"
        isOpen={tooltipOpen}
        target={tooltipKey}
        toggle={toggleTooltipOpen}
        innerClassName="font-12"
      >
        {!promocodeDetails ? (
          <>
            <Spinner color="light" className="text-primary" size="sm" />
            <span className="ml-1">Pobieranie...</span>
          </>
        ) : (
          <>
            {promocodeDetails.logs
              .filter(log => log.kind === 'sent')
              .map(log => (
                <div key={log.id} className="mb-1">
                  Wysłano na e-mail: {log.description} <br />
                  Przez: <strong>{log.created_by}</strong>
                </div>
              )) || 'brak'}
            {!promocodeDetails.logs.length && 'Kod nie został jeszcze wysłany.'}
          </>
        )}
      </Tooltip>
    </>
  )
}
