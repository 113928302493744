import * as React from 'react'
import { NotificationEvents } from '@models/dashboard'
import { useAppDispatch } from '@store/index'
import { useWebSocket } from '@components/hooks/use-web-socket'
import { BookingRefund } from '@modules/accounting/refund/models'
import { addAccountingBookingRefund, updateAccountingBookingRefund } from '@store/slices/accounting-slice'

export const BookingRefundWebSocketHandler: React.FC = () => {
  const dispatch = useAppDispatch()

  useWebSocket<BookingRefund>(
    (message, payload) => {
      switch (message) {
        case NotificationEvents.BOOKING_REFUND_ADD:
          dispatch(addAccountingBookingRefund(payload))
          break
        case NotificationEvents.BOOKING_REFUND_CHANGE:
          dispatch(updateAccountingBookingRefund(payload))
          break
      }
    },
    [],
    [NotificationEvents.BOOKING_REFUND_ADD, NotificationEvents.BOOKING_REFUND_CHANGE],
  )
  return null
}
