import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { PromocodePrefixDialogFormInputs } from '@modules/promocode/promocode-prefixes/promocode-prefix-dialog/promocode-prefix-dialog'
import DateRangeInput from '@components/date/date-range-input'

interface PromocodePrefixDialogDateProps {
  handleRemoveDate: (id: number) => void
  index: number
}

export const PromocodePrefixDialogDate: React.FC<PromocodePrefixDialogDateProps> = ({ handleRemoveDate, index }) => {
  const handleRemove = React.useCallback(() => handleRemoveDate(index), [index, handleRemoveDate])

  const {
    formState: { errors },
  } = useFormContext<PromocodePrefixDialogFormInputs>()

  return (
    <div>
      <div className="d-flex align-items-center">
        <DateRangeInput startDateName={`dates[${index}].date_from`} endDateName={`dates[${index}].date_to`} />
        <div className="action-icon text-center">
          <i className="uil uil-trash-alt cursor-pointer font-18 text-muted" title="Skasuj" onClick={handleRemove} />
        </div>
      </div>
      <div className="invalid-feedback d-block mb-1">{errors[index]?.date_from || errors[index]?.date_to}</div>
    </div>
  )
}
