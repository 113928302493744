import * as React from 'react'
import { toDefaultDateFormat } from '@helpers/date-helper'

interface ReceptionBookingCheckInNoteInfoProps {
  created: string
  createdBy: string
}

export const ReceptionBookingCheckInNoteInfo: React.FC<ReceptionBookingCheckInNoteInfoProps> = ({
  created,
  createdBy,
}) => (
  <div className="font-10">
    <span className="d-block mb-1">
      <i className="uil-calendar-alt text-warning mr-1 " /> {toDefaultDateFormat(created)}
    </span>
    {createdBy && (
      <span className="d-block">
        <i className="uil-user text-warning mr-1 " /> {createdBy}
      </span>
    )}
  </div>
)
