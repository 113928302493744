import * as React from 'react'
import { Card, CardBody } from 'reactstrap'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import {
  PromocodeTouristVoucherFilters,
  PromocodeTouristVoucherFiltersParams,
} from '@modules/promotions/promocode-tourist-voucher/filters'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useDebounce } from 'rooks'
import { useAppDispatch, useAppSelector } from '@store/index'
import { getPromocodeTouristVouchers } from '@store/actions/promocode-tourist-voucher-actions'
import { PromocodeTouristVoucherTable } from '@modules/promotions/promocode-tourist-voucher/table'
import { promocodeTouristVouchersSelector } from '@store/slices/promocode-tourist-voucher-slice'
import { promocodeTouristVoucherStatusOptions } from '@modules/promotions/promocode-tourist-voucher/consts'
import { PageTitleWithSize } from '@hyper/page-title-with-size'

const defaultFilters: PromocodeTouristVoucherFiltersParams = {
  ordering: '-created_date',
  page: 1,
  page_size: 10,
  search: '',
  created_date_after: undefined,
  created_date_before: undefined,
  seller: undefined,
  status: promocodeTouristVoucherStatusOptions.filter(row =>
    ['initial', 'confirmed', 'outdated', 'canceled'].includes(row.value),
  ),
}

export const PromocodeTouristVoucherView = (): JSX.Element => {
  const [filters, setFilters] = React.useState<PromocodeTouristVoucherFiltersParams>(defaultFilters)
  const dispatch = useAppDispatch()

  const promocodeTouristVouchers = useAppSelector(promocodeTouristVouchersSelector)

  useMenuActive(NavigationPath.PromocodeTouristVoucher)

  const { isLoading, action: fetchPackageVips } = useApiRequest(
    async filters => await dispatch(getPromocodeTouristVouchers(filters)),
  )

  const fetchDebounced = React.useCallback(useDebounce(fetchPackageVips, 300), [])
  React.useEffect(() => {
    fetchDebounced(filters)
  }, [filters])

  return (
    <>
      <PageTitleWithSize title="Voucher Turystyczny" />
      <Card>
        <CardBody>
          <PromocodeTouristVoucherFilters filters={filters} setFilters={setFilters} defaultFilters={defaultFilters} />
          <PromocodeTouristVoucherTable
            promocodeTouristVouchers={promocodeTouristVouchers}
            isLoading={isLoading}
            filters={filters}
            setFilters={setFilters}
          />
        </CardBody>
      </Card>
    </>
  )
}
