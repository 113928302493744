import * as React from 'react'
import { BarrierTicket } from '@models/barriers'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useAppDispatch } from '@store/index'
import { removeBarrierTickets } from '@store/actions/barriers-actions'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'

interface Props {
  ticket: BarrierTicket
}

export const BarriersBaseListTableRowRemove = ({ ticket }: Props): JSX.Element => {
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading, action: handleRemoveEvent } = useApiRequest(async () => {
    await dispatch(removeBarrierTickets(ticket))
    addSuccessMessage('Sukces', 'Pojazd został skasowany!')
  })

  return (
    <CommonObjectConfirmAction isLoading={isLoading} handleAccept={handleRemoveEvent}>
      <i className="uil uil-trash-alt cursor-pointer text-muted font-16" title="Skasuj" />
    </CommonObjectConfirmAction>
  )
}
