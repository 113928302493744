import * as React from 'react'
import { ReceptionBooking } from '@models/reception'
import { formatPriceShort } from '@helpers/utils'
import { IconWithTooltip } from '@components/icon-with-tooltip'
import classNames from 'classnames'

interface ReceptionToCheckOutRowDepositProps {
  booking: ReceptionBooking
}

const paymentMethodNameMap = {
  payu: 'PayU',
  p24: 'Przelewy24',
  form_bank: 'Przelew bankowy',
  cash: 'Gotówka',
  creditcard_preauth: 'Preautoryzacja',
  creditcard: 'Karta kredytowa',
}

export const ReceptionToCheckOutRowDeposit: React.FC<ReceptionToCheckOutRowDepositProps> = ({ booking }) => {
  const icon =
    {
      payu: 'uil-transaction',
      p24: 'uil-transaction',
      form_bank: 'uil-university',
      cash: 'uil-money-bill',
      creditcard_preauth: 'uil-credit-card',
      creditcard: 'uil-credit-card',
    }[booking.deposit_sources[0]] || 'uil-money-bill'

  const tooltipMessage = React.useMemo(
    () => (
      <>
        <strong>Metoda płatności: </strong> {booking.deposit_sources.map(row => paymentMethodNameMap[row]).join(', ')}
      </>
    ),

    [booking.deposit_sources],
  )

  return (
    <>
      <div className="mb-1">Kaucja:</div>
      <IconWithTooltip
        color="default"
        icon={classNames(icon, 'uil text-muted font-16 mr-1')}
        tooltipMessage={tooltipMessage}
        tooltipId={`deposit-${booking.id}`}
        tooltipPlacement="bottom"
      >
        <strong>{formatPriceShort(booking.deposit)}</strong>
      </IconWithTooltip>
    </>
  )
}
