import * as React from 'react'
import { PaymentDayUserReport } from '@models/payments'
import Table from '@components/table/table'
import { asDecimal, formatPrice, sumDecimalArray } from '@helpers/utils'
import { PaymentRegisterReceptionRowSingle } from '@modules/payment-registers/cashbox-day-details/payment-register-reception/row-single'
import { useModal } from '@components/modals/use-modal'
import { PaymentRegistersFiltersParams } from '@modules/payment-registers/list/filters'
import { PaymentRegisterReceptionRowSingleWork } from '@modules/payment-registers/cashbox-day-details/payment-register-reception/row-single-work'
import classNames from 'classnames'
import { useToggle } from '@components/hooks/use-toggle'
import { IconWithText } from '@components/icon-with-text'
import { PaymentRegisterReceptionRowSingleStartAmount } from '@modules/payment-registers/cashbox-day-details/payment-register-reception/row-single-start-amount'
import { PaymentRegisterReceptionRowCashActions } from '@modules/payment-registers/cashbox-day-details/payment-register-reception/row-actions'
import { PaymentRegisterReceptionReportCell } from '@modules/payment-registers/cashbox-day-details/payment-register-reception/cell-report'
import { UncontrolledTooltip } from 'reactstrap'

interface Props {
  reports: PaymentDayUserReport[]
  user: string
  index: number
}

export const PaymentRegisterReceptionRow = ({ reports, user, index }: Props): JSX.Element => {
  const [showDetails, toggleShowDetails] = useToggle(false)

  const workingReport = reports.find(row => row.status === 'open')

  const totalInCash = workingReport?.cashbox_amount || 0

  const totalInCashWithoutStartAmount = React.useMemo(
    () => sumDecimalArray(reports.map(row => asDecimal(row.amount_sale_cash))).toString(),
    [reports, workingReport],
  )
  const totalInCardhWithoutStartAmount = React.useMemo(
    () => sumDecimalArray(reports.map(row => asDecimal(row.amount_sale_cards))).toString(),
    [reports, workingReport],
  )

  const totalCashboxAmount = reports.at(-1)?.safe_amount || 0
  const totalEndAmount = reports.at(-1)?.cashbox_amount_after_day_end || 0
  const totalStartAmount = reports.at(-1)?.start_amount || 0
  const totalAmount = React.useMemo(
    () => sumDecimalArray(reports.map(row => asDecimal(row.amount_sale))).toString(),
    [reports],
  )

  const [handleOpenModal] = useModal('TransactionListModal', {
    title: `pracownik ${user}`,
    params: { payment_day_user_report: reports.map(row => row.id) } as Partial<PaymentRegistersFiltersParams>,
  })

  const tableRowColor = index % 2 === 0 ? 'table-light' : ''

  return (
    <>
      <Table.Row className={tableRowColor}>
        <Table.Cell style={{ width: 250 }} className="align-middle">
          <div>{user}</div>
          {reports.length === 1 ? (
            !showDetails && <PaymentRegisterReceptionRowSingleWork report={reports[0]} />
          ) : (
            <span
              className={classNames('badge', {
                'badge-gray': !workingReport,
                'badge-success-lighten': !!workingReport,
              })}
            >
              <IconWithText icon="uil-clock" text={`${reports.length} zmiany `} />
            </span>
          )}
        </Table.Cell>
        <Table.Cell className="align-middle">
          {reports.length === 1 && <strong>{formatPrice(totalStartAmount)}</strong>}
          {reports.length === 1 && <PaymentRegisterReceptionRowSingleStartAmount report={reports[0]} />}
        </Table.Cell>
        <Table.Cell className="align-middle">
          <strong id={`reception-revenue-${index}`}>{formatPrice(totalAmount)}</strong>
          <UncontrolledTooltip placement="bottom" target={`reception-revenue-${index}`}>
            <div className="font-11">
              Gotówka: <strong>{formatPrice(totalInCashWithoutStartAmount)}</strong> <br />
              Karta: <strong>{formatPrice(totalInCardhWithoutStartAmount)}</strong>
            </div>
          </UncontrolledTooltip>
        </Table.Cell>
        <Table.Cell className="align-middle">
          <strong>{formatPrice(totalInCash)}</strong>
        </Table.Cell>
        <Table.Cell>
          {!!workingReport && reports.length === 1 && <PaymentRegisterReceptionRowCashActions report={workingReport} />}
        </Table.Cell>
        <Table.Cell className="align-middle">
          {!workingReport && <strong>{formatPrice(totalCashboxAmount)}</strong>}
        </Table.Cell>
        <Table.Cell className="align-middle">
          {!workingReport && <strong>{formatPrice(totalEndAmount)}</strong>}
        </Table.Cell>

        <Table.Cell style={{ width: 10 }} className="align-middle">
          <div className="d-flex justify-content-between">
            {reports.length === 1 && <PaymentRegisterReceptionReportCell printUrl={reports[0].urls.print} />}
            <i className="uil uil-eye cursor-pointer font-16" title="Podgląd" onClick={handleOpenModal} />
            {reports.length > 1 && (
              <i
                onClick={toggleShowDetails}
                className={classNames('ml-2 uil font-16 cursor-pointer', {
                  'uil-angle-right-b': !showDetails,
                  'uil-angle-down': showDetails,
                })}
              />
            )}
          </div>
        </Table.Cell>
      </Table.Row>
      {showDetails &&
        reports.map(row => (
          <PaymentRegisterReceptionRowSingle tableRowColor={tableRowColor} key={row.id} report={row} />
        ))}
    </>
  )
}
