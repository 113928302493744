import { createSelectOption } from '@helpers/utils'

export const BaseFeedingOptions = [
  createSelectOption('Śniadanie', 'breakfast'),
  createSelectOption('Obiadokolacja', 'dinner'),
]

export const FeedingKindsOptions = [
  ...BaseFeedingOptions,
  createSelectOption('Śniadanie + obiadokolacja', 'breakfast_with_dinner'),
]
