import * as React from 'react'
import classNames from 'classnames'
import { ButtonWithIcon } from '@components/button-with-icon'
import { ReceptionBookingDetailsImprovement } from '@models/booking'
import { ReceptionBookingDetails } from '@models/reception'

interface Props {
  ticketImprovement: ReceptionBookingDetailsImprovement
  onPrint: () => void
  isPrinted: boolean
  booking: ReceptionBookingDetails
}

export const ReceptionBookingCheckInStepHpfTicketPrintBanner: React.FC<Props> = ({
  ticketImprovement,
  onPrint,
  isPrinted,
  booking,
}) => {
  const handlePrint = () => {
    window.open(booking.urls.print_hpf_tickets, '_blank')
    onPrint()
  }

  return (
    <>
      <h4>Bilety do HPF</h4>
      <div
        className={classNames(
          'reception__booking-check-in__improvement-step__hpf__ticket-print-banner text-white p-2 rounded d-flex align-items-center justify-content-between',
          { 'opacity-5': isPrinted },
        )}
      >
        <div>
          Do rezerwacji dodano <strong>{ticketImprovement.amount}x bilet do Holiday Park & Fun</strong>.{' '}
          <strong>Pamiętaj, aby je wydać.</strong>
        </div>
        <ButtonWithIcon
          icon="uil-print font-16 lh-1"
          text="Drukuj bilety"
          btnClass="ml-5"
          color="primary"
          disabled={isPrinted}
          handleClick={handlePrint}
        />
      </div>
      <hr />
    </>
  )
}
