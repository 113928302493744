import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { asDecimal, formatPriceShort } from '@helpers/utils'
import Decimal from 'decimal.js'
import { Col, Row } from 'reactstrap'
import { ReceptionBookingCheckPaymentsBoxCheckoutCash } from '@modules/reception/common/reception-booking-check-payments-box-checkout/step-payments-box-checkout-cash'
import { ReceptionBookingCheckPaymentsBoxCheckoutCard } from '@modules/reception/common/reception-booking-check-payments-box-checkout/step-payments-box-checkout-card'
import { ButtonWithIcon } from '@components/button-with-icon'
import classNames from 'classnames'
import { ReceptionBookingBox } from '@modules/reception/checkin/step-payments/boxes/reception-booking-box'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { ReceptionBookingPaymentsAddPayload } from '@store/actions/reception-actions'

type PaymentBoxMethods = 'card' | 'cash' | undefined

interface Props {
  booking: ReceptionBookingDetails
  restToPay: Decimal
  title?: string
  children?: React.ReactNode
  paymentParams?: Partial<ReceptionBookingPaymentsAddPayload>
}

export const ReceptionBookingCheckPaymentsBoxCheckout: React.FC<Props> = ({
  booking,
  restToPay,
  title,
  children,
  paymentParams,
}) => {
  const [paymentMethod, setPaymentMethod] = React.useState<PaymentBoxMethods>(undefined)
  const user = useAuthenticatedUser()

  const handlePaymentMethodChange = (method: PaymentBoxMethods) => () => {
    if (paymentMethod !== undefined) return
    setPaymentMethod(method)
  }

  const handleCancel = () => setPaymentMethod(undefined)

  const isPaid = React.useMemo(() => restToPay.lessThanOrEqualTo(asDecimal(0)), [restToPay])

  const titleElement = isPaid ? undefined : (
    <strong className="d-flex justify-content-between">
      {title || 'Rachunek do zapłaty'}: <span className="text-danger">{formatPriceShort(restToPay.toString())}</span>
    </strong>
  )

  return (
    <ReceptionBookingBox title={titleElement}>
      {isPaid ? (
        <div className="text-success text-center text-strong font-18 pb-1">
          <i className="uil-check d-block font-36" /> Rachunek za pobyt opłacony w całości
        </div>
      ) : (
        <>
          <Row>
            {user.hasPerm(UserPermission.BookingCanAddCashPayment) && (
              <Col md={6}>
                <ButtonWithIcon
                  icon="uil-money-bill"
                  text="Gotówka"
                  handleClick={handlePaymentMethodChange('cash')}
                  color="primary"
                  btnClass={classNames('w-100', {
                    'opacity-5 cursor-not-allowed': paymentMethod === 'card',
                  })}
                />
              </Col>
            )}
            {user.hasPerm(UserPermission.BookingCanAddCreditcardPayment) && (
              <Col md={6}>
                <ButtonWithIcon
                  icon="uil-credit-card"
                  text="Karta"
                  handleClick={handlePaymentMethodChange('card')}
                  color="primary"
                  btnClass={classNames('w-100', {
                    'opacity-5 cursor-not-allowed': paymentMethod === 'cash',
                  })}
                />
              </Col>
            )}
          </Row>
          {children}
          {!!paymentMethod && <div className="hr hr-grey-2 mb-2 mt-2" />}
          {paymentMethod === 'cash' && (
            <ReceptionBookingCheckPaymentsBoxCheckoutCash
              handleCancel={handleCancel}
              booking={booking}
              restToPay={restToPay}
              paymentParams={{
                type: 'installment',
                with_climatic_tax: true,
                with_booking_damage: true,
                ...paymentParams,
              }}
            />
          )}
          {paymentMethod === 'card' && (
            <ReceptionBookingCheckPaymentsBoxCheckoutCard
              handleCancel={handleCancel}
              booking={booking}
              restToPay={restToPay}
              paymentParams={{
                with_climatic_tax: true,
                with_booking_damage: true,
                ...paymentParams,
              }}
            />
          )}
        </>
      )}
    </ReceptionBookingBox>
  )
}
