import * as React from 'react'
import { ReceptionBooking } from '@models/reception'

interface ReceptionCompleteCheckInRowGuestsProps {
  booking: ReceptionBooking
}

export const ReceptionCompleteCheckInRowGuests: React.FC<ReceptionCompleteCheckInRowGuestsProps> = ({ booking }) => (
  <>
    {booking.adults > 0 && (
      <div title="Dorośli" className="mb-1">
        <i className="uil-user text-muted font-16" /> {booking.adults}
      </div>
    )}
    {booking.children > 0 && (
      <div title="Dzieci i młodzież" className="mb-1">
        <i className="uil-kid text-muted font-16" /> {booking.children}
      </div>
    )}
    {booking.animals > 0 && (
      <div title="Zwierzęta">
        <i className="icon-zwierzeta text-muted font-16" /> {booking.animals}
      </div>
    )}
  </>
)
