import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { FeedingHosFormInputs } from '@modules/feeding/host/feeding-host-with-params-view'
import { FeedingHostOptions } from '@modules/feeding/models'
import { IconWithText } from '@components/icon-with-text'
import { fetchFeedingHostOptions } from '@api/feeding'
import { createSelectOption, extractSelectOptionsValues } from '@helpers/utils'
import { FormSelectWithCategories } from '@hyper/forms/form-select-with-categories'
import { useAppSelector } from '@store/index'
import { feedingClientsSelector, feedingWorkersSelector } from '@store/slices/feeding-slice'

const SearchCategoryOptions = [
  createSelectOption<string, keyof FeedingHostOptions>('Lokal', 'apartments'),
  createSelectOption<string, keyof FeedingHostOptions>('Rezerwacja', 'bookings'),
  createSelectOption<string, keyof FeedingHostOptions>('Imię i nazwisko', 'guests'),
]

export const FeedingHostFiltersApartments: React.FC = () => {
  const [options, setOptions] = React.useState<FeedingHostOptions | null>(null)
  const { getValues, control, setValue } = useFormContext<FeedingHosFormInputs>()
  const feedingsForWorkers = useAppSelector(feedingWorkersSelector)
  const feedingsForClient = useAppSelector(feedingClientsSelector)

  const selectedCategory = useWatch({ control, name: 'category' })

  const watchedParams = useWatch<FeedingHosFormInputs>({ name: ['resort', 'date', 'type'] })

  const fetchApartments = async () => {
    setOptions(await fetchFeedingHostOptions(extractSelectOptionsValues(getValues())))
  }

  const selectOptions =
    options && selectedCategory
      ? options[selectedCategory.value].map(el =>
          createSelectOption(el.name, el.id, false, { booking_id: el.booking_id }),
        )
      : []

  React.useEffect(() => {
    fetchApartments()
  }, [watchedParams, feedingsForWorkers, feedingsForClient])

  React.useEffect(() => {
    if (!selectedCategory) {
      setValue('category', SearchCategoryOptions[0])
    }
  }, [selectedCategory])

  const placeholderText =
    { apartments: 'Numer lokalu', guests: 'Imię i nazwisko', bookings: 'Numer rezerwacji' }[selectedCategory?.value] ??
    ''

  return (
    <FormSelectWithCategories
      formPlainProps={{ colSize: 4 }}
      formParams={{
        options: selectOptions,
        name: 'apartment',
        selectProps: { placeholder: <IconWithText icon="uil-search" text={placeholderText} /> },
      }}
      categoryFormParams={{
        options: SearchCategoryOptions,
        name: 'category',
        formPlainProps: { colStyle: { maxWidth: 150 } },
      }}
    />
  )
}
