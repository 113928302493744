import * as React from 'react'
import { FeedingTooltipHistoryRow } from '@modules/reception/checkin/step-feeding/new-feeding/models'
import { IconWithText } from '@components/icon-with-text'
import { formatPrice } from '@helpers/utils'
import classNames from 'classnames'
import { useToggle } from '@components/hooks/use-toggle'
import { toTextDateTimeFormat } from '@helpers/date-helper'

interface Props {
  historyRows: FeedingTooltipHistoryRow[]
}

export const ReceptionBookingNewFeedingPriceTooltipHistory = ({ historyRows }: Props): JSX.Element => {
  const [isFullHistoryShowed, toggleIsFullHistoryShowed] = useToggle()

  const { firstRow, middleRows, lastRow } = historyRows.reduce(
    (previousRows, currentRow, index) => {
      if (index === 0) return { ...previousRows, firstRow: currentRow }
      if (index === historyRows.length - 1) return { ...previousRows, lastRow: currentRow }
      return { ...previousRows, middleRows: [...previousRows.middleRows, currentRow] }
    },
    { firstRow: null, middleRows: [], lastRow: null },
  )

  return (
    <>
      <p className="fw-semi-bold text-left px-2 mt-2 mb-0">Historia zmian posiłku</p>

      {firstRow && <HistoryRow historyRow={firstRow} />}

      {!!middleRows.length && (
        <IconWithText
          icon="uil-ellipsis-v lh-1"
          text={isFullHistoryShowed ? 'Ukryj historię' : 'Zobacz pełną historię'}
          onClick={toggleIsFullHistoryShowed}
          wrapperClassNames="px-2 pt-2 w-100 font-weight-semibold"
        />
      )}

      {isFullHistoryShowed &&
        middleRows.map((historyRow: FeedingTooltipHistoryRow) => (
          <HistoryRow key={historyRow.id} historyRow={historyRow} />
        ))}

      {lastRow && <HistoryRow historyRow={lastRow} borderless />}
    </>
  )
}

const HistoryRow = ({ historyRow, borderless }: { historyRow: FeedingTooltipHistoryRow; borderless?: boolean }) => {
  const rowVariant = {
    add: {
      icon: 'uil-plus font-15 lh-1',
      color: 'text-darker-gray',
    },
    delete: {
      icon: 'uil-trash-alt font-15 lh-1',
      color: 'text-darker-gray',
    },
    payment: {
      icon: 'uil-check font-14 lh-1',
      color: 'text-success',
    },
    update: {
      icon: 'uil-exchange lh-1',
      color: 'text-darker-gray',
    },
  }[historyRow.keyword] as { icon: string; color: string }

  return (
    <Section
      title={historyRow.keyword_display}
      icon={rowVariant?.icon ?? 'uil-circle font-12'}
      variant={classNames('fw-semi-bold', rowVariant?.color)}
      total={historyRow.price_brutto_after_change ? formatPrice(historyRow.price_brutto_after_change) : '-'}
      borderless={borderless}
    >
      <p className="mt-1 text-light text-left mb-1" dangerouslySetInnerHTML={{ __html: historyRow.message }} />
      <div className="text-left">
        <span className="d-block">{historyRow.author}</span>
        <span>{toTextDateTimeFormat(historyRow.created_at)}</span>
      </div>
    </Section>
  )
}

interface SectionProps {
  icon?: string
  title?: string
  total?: string
  variant: string
  children: React.ReactNode
  onClick?: () => void
  className?: string
  borderless?: boolean
}

const Section = ({ icon, title, variant, total, children, onClick, className, borderless }: SectionProps) => (
  <div className="px-2 pt-2">
    <div className={classNames('d-flex justify-content-between align-items-center', className)}>
      {title && (
        <IconWithText
          icon={classNames('font-14', icon, variant)}
          text={title}
          textClass={classNames('text-nowrap', variant)}
          onClick={onClick}
        />
      )}
      {total && <span className={classNames('ml-3 text-white')}>{total}</span>}
    </div>
    <div className={classNames('pl-2 ml-1', { 'border-left': !borderless })}>{children}</div>
  </div>
)
