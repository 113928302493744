import * as React from 'react'
import { Collapse } from 'reactstrap'
import { ReceptionBookingNewFeedingSummaryGastroVoucherAlert } from '@modules/reception/checkin/step-feeding/new-feeding/summary/alerts/reception-booking-new-feeding-summary-gastro-voucher-alert'
import { ReceptionBookingNewFeedingSummaryBestsellerAlert } from '@modules/reception/checkin/step-feeding/new-feeding/summary/alerts/reception-booking-new-feeding-summary-bestseller-alert'
import { ReceptionBookingNewFeedingSummaryManualPriceChangeAlert } from '@modules/reception/checkin/step-feeding/new-feeding/summary/alerts/reception-booking-new-feeding-summary-manual-price-change-alert'

interface Props {
  isVisible: boolean
  isPaymentByWalletSelected: boolean
  hasDiscount: boolean
  hasFixedPrice: boolean
}

export const ReceptionBookingNewFeedingSummaryAlerts = ({
  isVisible,
  isPaymentByWalletSelected,
  hasDiscount,
  hasFixedPrice,
}: Props): JSX.Element => (
  <Collapse isOpen={isVisible}>
    <div className="border-bottom mb-2">
      {isPaymentByWalletSelected && <ReceptionBookingNewFeedingSummaryGastroVoucherAlert />}
      {hasDiscount && <ReceptionBookingNewFeedingSummaryBestsellerAlert />}
      {hasFixedPrice && <ReceptionBookingNewFeedingSummaryManualPriceChangeAlert />}
    </div>
  </Collapse>
)
