import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectPost } from '@store/actions/generic-actions'
import { Address, AddressesResponse } from '@modules/hr/agreement/models'
import * as React from 'react'
import { getState } from '@store/index'

interface Response {
  fetchAddressData: () => Promise<void>
  addressData: Address | null
}

interface Params {
  autoFetch?: boolean
  code?: string
}

export const useAddressesData = ({ autoFetch, code }: Params): Response => {
  const [addressData, setAddressData] = React.useState<Address | null>(null)

  const { action: fetch } = useApiRequest(async (code: string) => {
    const url = getState().appState.appData.urls.datasets.address
    const data = await commonObjectPost<AddressesResponse>(url, { code })

    if (!data || !Object.values(data)[0]) return
    setAddressData(Object.values(data)[0])
  })

  React.useEffect(() => {
    if (autoFetch && code) fetch(code)
  }, [])

  return {
    addressData,
    fetchAddressData: fetch,
  }
}
