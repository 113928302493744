import * as React from 'react'
import { FormInputNumber } from '@hyper/forms/form-input-number'
import { Col, Row } from 'reactstrap'

interface Props {
  apartmentName: string
}

export const ReceptionBookingCheckInStepAccessKeys: React.FC<Props> = ({ apartmentName }) => (
  <Row className="my-3">
    <Col
      md={4}
      className="reception__booking-check-in__access-step__key-icon d-flex justify-content-center align-items-center"
    >
      <i className="icon-klucz text-success" />
    </Col>
    <Col md={8}>
      <strong className="font-18 mt-3 d-block mb-2">Ilość kluczy wydanych Gościom:</strong>
      <FormInputNumber
        colStyle={{ padding: '0' }}
        colSize={4}
        minValue={1}
        maxValue={2}
        name="keys"
        label={`Klucze do lokalu ${apartmentName}`}
        labelProps={{
          className: 'd-block reception__booking-check-in__access-step__form__label',
        }}
        inputProps={{ className: 'text-center' }}
      />
    </Col>
  </Row>
)
