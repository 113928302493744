import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { getIntValue } from '@helpers/utils'
import classNames from 'classnames'
import Decimal from 'decimal.js'

interface Props {
  hourMin?: number
}

export const FormTimeSpinInput = ({ hourMin }: Props): React.ReactNode => {
  const { control } = useFormContext()

  const [hour, minute] = useWatch({ control, name: ['hour', 'minute'] })

  return (
    <div className="d-flex">
      <div className="d-flex flex-column align-items-center mr-1">
        <Input name="hour" value={hour} max={23} min={hourMin} />
      </div>

      <div className="d-flex flex-column align-items-center ml-1">
        <Input name="minute" value={minute} max={59} />
      </div>
    </div>
  )
}

const Input = ({ max, min = 0, value, name }) => {
  const { setValue } = useFormContext()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, '')

    if (value.length > 2 || getIntValue(value) > max) return
    setValue(name, value)
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.target.value
    let newValue: string | number = min

    try {
      newValue = Decimal.max(value.length === 1 ? `0${value}` : value, min).toString()
    } catch {
      newValue = min
    }

    setValue(name, value ? newValue : min || '00')
  }

  const handleIncrement = () => {
    const intValue = parseInt(value)
    if (intValue >= max) return

    const increasedValue = intValue + 1
    const newValue = increasedValue < 10 ? `0${increasedValue}` : increasedValue
    setValue(name, newValue)
  }

  const handleDecrement = () => {
    const intValue = parseInt(value)
    if (intValue <= min) return

    const decreasedValue = intValue - 1
    const newValue = decreasedValue < 10 ? `0${decreasedValue}` : decreasedValue
    setValue(name, newValue)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const unsupportedKeys = ['.', ',']

    if (unsupportedKeys.includes(e.key)) {
      e.preventDefault()
    }
  }

  const canIncrement = parseInt(value) < max
  const canDecrement = parseInt(value) > min

  return (
    <>
      <i
        className={classNames('uil-angle-up font-24 cursor-pointer', { 'pe-none opacity-5': !canIncrement })}
        onClick={handleIncrement}
      />
      <input
        className="band__configuration__time"
        type="number"
        max={max}
        min={min}
        value={value}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
      <i
        className={classNames('uil-angle-down font-24 cursor-pointer', { 'pe-none opacity-5': !canDecrement })}
        onClick={handleDecrement}
      />
    </>
  )
}
