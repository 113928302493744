import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useFormRequest } from '@components/hooks/use-api-request'
import { Button, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { SaveButton } from '@hyper/button'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { ServiceBandCreateForm } from '@modules/bands/service-bands/create-dialog/form'
import { useAppDispatch } from '@store/index'
import { createServiceBand } from '@store/actions/service-band-actions'
import { Form } from '@hyper/forms/form'
import { useAppData } from '@components/hooks/use-app-data'
import { createSelectOption } from '@helpers/utils'
import { useForm } from 'react-hook-form'
import { ClientSelect } from '@components/client-select'
import { useModal } from '@components/modals/use-modal'
import { ClientUser } from '@models/clients'

export interface ServiceBandCreateFormInputs {
  name: string
  owner: number | string | undefined
  initial_funds: number
  resort: CustomReactSelectOption
  scopes: CustomReactSelectOption
  date_from: Date
  date_to: Date
}

export const ServiceBandCreateDialog: React.FC<BaseModalProps> = ({ toggleIsVisible }) => {
  const [client, setClient] = React.useState<ClientUser | undefined>()
  const [handleToggleClientDialog] = useModal('ClientDialog', { setClient })

  const { resorts, voucher_scopes } = useAppData()
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const resortOptions = resorts.map(({ id, name }) => createSelectOption(name, id))
  const scopesOptions = voucher_scopes.map(({ id, name }) => createSelectOption(name, id))

  const methods = useForm<ServiceBandCreateFormInputs>()

  const {
    clearErrors,
    setError,
    formState: { errors },
  } = methods

  React.useEffect(() => clearErrors('owner'), [client])

  const { isLoading, action: onSubmit } = useFormRequest(
    async (payload: ServiceBandCreateFormInputs) => {
      await dispatch(createServiceBand({ ...payload, owner: client?.id }))
      addSuccessMessage(
        'Sukces',
        'Opaska została utworzona pomyślnie. Możesz przypisać opaskę w szczegółach opaski testowej.',
      )
      toggleIsVisible()
    },
    setError,
    { showGlobalError: true },
  )

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>Dodaj opaskę testową</ModalHeader>
      <Form methods={methods} onSubmit={onSubmit}>
        <ModalBody className="pb-0">
          <Row>
            <ServiceBandCreateForm resortOptions={resortOptions} scopeOptions={scopesOptions} />
            <ClientSelect
              label="Właściciel"
              setClient={setClient}
              client={client}
              handleToggleClientDialog={handleToggleClientDialog}
              error={errors.owner}
              colSize={6}
            />
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={toggleIsVisible}>
            Zamknij
          </Button>
          <SaveButton label="Zapisz" isSaving={isLoading} className="btn btn-green" />
        </ModalFooter>
      </Form>
    </>
  )
}
