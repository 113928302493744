import * as React from 'react'
import { Button, Col, Row } from 'reactstrap'
import { FeedingClientsFiltersParams } from '@modules/feeding/clients/index'
import { useModal } from '@components/modals/use-modal'

interface Props {
  filters: FeedingClientsFiltersParams
}

export const FeedingClientsExportButtons: React.FC<Props> = ({ filters }) => {
  const [handleFeedingClientReport] = useModal('ReportsGeneratorFeedingClient', {
    dateFrom: filters.date_from,
    dateTo: filters.date_to,
  })

  return (
    <Row>
      <Col md={12} className="text-right">
        <Button color="secondary" className="text-semi-strong" onClick={handleFeedingClientReport}>
          <i className="uil-download-alt mr-1" />
          Pobierz
        </Button>
      </Col>
    </Row>
  )
}
