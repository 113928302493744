import * as React from 'react'
import { BeddingOrder, CleaningOrder, ImprovementOrder } from '@modules/housekeeping/models'
import { IconWithText } from '@components/icon-with-text'
import { HousekeepingCleaningOrdersKind } from '@modules/housekeeping/cleaning-orders/common/kind'

export type HousekeepingServiceKind = 'cleaning' | 'bedding' | 'improvement' | 'refresh'
type Order = CleaningOrder | BeddingOrder | ImprovementOrder

interface Props {
  orders: Order[]
  serviceKind?: HousekeepingServiceKind
}

export const HousekeepingOrdersToAssignBox = ({ orders, serviceKind }: Props): JSX.Element => {
  const getServiceKindName = order =>
    ({
      CLEANING: 'Sprzątanie',
      REFRESH: 'Odświeżenie',
      bedding: 'Pościelowy',
      improvement: 'Ulepszenia',
    })[order.service_kind || serviceKind]

  return (
    <div className="bg-light-grey border-bottom mx-n3 px-3 mb-2 py-2">
      {orders.map(order => (
        <div key={order.id} className="row align-items-center">
          <IconWithText
            icon="uil-estate font-18 lh-1"
            text={order.apartment.name}
            textClass="fw-semi-bold font-14"
            wrapperClassNames="col-2"
          />

          <div className="d-flex align-items-center col-4">
            <HousekeepingCleaningOrdersKind className="mr-1" order={order} />
          </div>
          <div className="fw-semi-bold col-4">{getServiceKindName(order)}</div>
          {'priority' in order && order.priority > 0 && (
            <IconWithText
              text="Pilne"
              icon="uil-rocket"
              iconSize="font-16 text-danger"
              wrapperClassNames="text-danger text-semi-strong ml-1 lh-0 col-1"
            />
          )}
        </div>
      ))}
    </div>
  )
}
