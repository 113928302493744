import { TechnicalOrderAppData } from '@models/technical-orders'
import { useGetTechnicalOrderAppDataQuery } from '@api/technical-orders'
import { TypedQueryResult } from '@api/base'

const emptyAppData: TechnicalOrderAppData = {
  technical_order_types: [],
  technical_order_reject_reason: [],
  accepted_by_users: [],
}

export const useTechnicalOrdersAppData = (): TechnicalOrderAppData => {
  const { data: appData = emptyAppData } = useGetTechnicalOrderAppDataQuery() as TypedQueryResult<TechnicalOrderAppData>

  return appData
}
