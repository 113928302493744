import * as React from 'react'
import { CommonTask } from '@modules/housekeeping/models'
import { HousekeepingCommonTasksTableRow } from '@modules/housekeeping/cleaning-orders/common-tasks/table-row'
import { HousekeepingNoOrdersRow } from '@modules/housekeeping/cleaning-orders/common/no-orders'

interface Props {
  commonTasks: CommonTask[]
}

export const HousekeepingCommonTasksTable = ({ commonTasks }: Props): JSX.Element => (
  <table className="housekeeping__orders_table table table-striped">
    <thead>
      <tr>
        <th className="border-top-0">Lokal</th>
        <th className="border-top-0">Wykonawca</th>
        <th className="border-top-0">Czas pracy</th>
        <th className="border-top-0">Status</th>
        <th className="border-top-0" />
      </tr>
    </thead>
    <tbody>
      {!commonTasks.length ? (
        <HousekeepingNoOrdersRow colSpan={6} />
      ) : (
        <>
          {commonTasks.map(commonTask => (
            <HousekeepingCommonTasksTableRow commonTask={commonTask} key={commonTask.id} />
          ))}
        </>
      )}
    </tbody>
  </table>
)
