import * as React from 'react'
import { asDecimal, formatPrice } from '@helpers/utils'
import { WalletTransaction } from '@models/reception'

interface Props {
  transactions: WalletTransaction[]
}

export const ReservationRoomBillFooter = ({ transactions }: Props): JSX.Element => {
  const { totalAvailable, totalUsed } = React.useMemo(
    () =>
      transactions.reduce(
        (previousValue, currentValue) => ({
          totalAvailable: asDecimal(previousValue.totalAvailable).add(currentValue.available).toString(),
          totalUsed: asDecimal(previousValue.totalUsed).add(currentValue.used).toString(),
        }),
        { totalAvailable: '0', totalUsed: '0' },
      ),
    [transactions],
  )

  const leftToSpend = asDecimal(totalAvailable).minus(totalUsed).toString()

  return (
    <tfoot>
      <tr className="font-12">
        <td />
        <td className="bg-grey-light fw-semi-bold text-nowrap pl-3 border-left border-light">Dostępny limit</td>
        <td className="bg-grey-light text-right fw-semi-bold">{formatPrice(totalAvailable)}</td>
        <td className="bg-grey-light border-light border-right" />
      </tr>
      <tr className="font-12">
        <td className="border-0" />
        <td className="bg-grey-light fw-semi-bold pl-3 border-left border-light">Wykorzystano</td>
        <td className="bg-grey-light text-right fw-semi-bold">{formatPrice(totalUsed)}</td>
        <td className="bg-grey-light border-light border-right" />
      </tr>
      <tr className="font-14">
        <td className="border-0" />
        <td className="fw-semi-bold pl-3 border-left border-light border-bottom bg-grey">Pozostało</td>
        <td className="text-right fw-semi-bold border-bottom border-light bg-grey">{formatPrice(leftToSpend)}</td>
        <td className="border-bottom border-light border-right bg-grey" />
      </tr>
    </tfoot>
  )
}
