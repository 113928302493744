import * as React from 'react'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap'
import { CleaningOrder } from '@modules/housekeeping/models'
import { useModal } from '@components/modals/use-modal'

interface Props {
  selectedCleaningOrders: CleaningOrder[]
  resortId: number
}

export const HousekeepingRowOptions: React.FC<Props> = ({ resortId, selectedCleaningOrders }) => {
  const [handleAssign] = useModal('HousekeepingCleaningOrderGroupAssignModal', {
    selectedOrders: selectedCleaningOrders,
    resortId,
    serviceKind: 'cleaning',
  })

  const isDisabled = selectedCleaningOrders.length === 0

  return (
    <UncontrolledButtonDropdown disabled={isDisabled}>
      <DropdownToggle color="light" caret={true} disabled={isDisabled}>
        <strong>zaznaczone ({selectedCleaningOrders.length})</strong>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={handleAssign}>
          <i className="uil uil-truck mr-1 text-bold" />
          Przydziel
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  )
}
