import * as React from 'react'
import { NavigationTabs, NavigationTabsButton } from '@components/navigation-tabs'
import { NavigationPath } from '@models/routes'
import { TechnicalOrderAggregation, TechnicalOrderStatus } from '@models/technical-orders'
import { useGetTechnicalOrdersAggregationQuery } from '@api/technical-orders'

interface Props {
  resortId: number
  type: TechnicalOrderStatus
}

export const TechnicalOrdersTabs: React.FC<Props> = ({ type, resortId }) => {
  const url = NavigationPath.TechnicalOrdersWithParams.replace(':resort_id', String(resortId))

  const { data: aggregation = {} as TechnicalOrderAggregation } = useGetTechnicalOrdersAggregationQuery({
    resort: resortId,
  })

  const buttons: NavigationTabsButton[] = [
    {
      isActive: type === 'todo',
      title: `Do zlecenia (${aggregation?.todo || 0})`,
      link: url.replace(':type', String('todo' as TechnicalOrderStatus)),
    },
    {
      isActive: type === 'ordered',
      title: `Zlecone (${aggregation?.ordered || 0})`,
      link: url.replace(':type', String('ordered' as TechnicalOrderStatus)),
    },
    {
      isActive: type === 'in_progress',
      title: `W toku (${aggregation?.in_progress || 0})`,
      link: url.replace(':type', String('in_progress' as TechnicalOrderStatus)),
    },
    {
      isActive: type === 'to_accept',
      title: `Do sprawdzenia (${aggregation?.to_accept || 0})`,
      link: url.replace(':type', String('to_accept' as TechnicalOrderStatus)),
    },
    {
      isActive: type === 'done',
      title: 'Wykonane',
      link: url.replace(':type', String('done' as TechnicalOrderStatus)),
    },
  ]

  return <NavigationTabs buttons={buttons} />
}
