import * as React from 'react'
import { BeddingOrder, CleaningOrder, CommonTask, ImprovementOrder } from '@modules/housekeeping/models'
import { useInterval } from 'rooks'
import { HousekeepingOrdersRowTotalTimeStarted } from '@modules/housekeeping/common/row-total-time-started'

interface Props {
  order: CleaningOrder | BeddingOrder | ImprovementOrder | CommonTask
}

export const HousekeepingOrdersRowTotalTimeStartedLive: React.FC<Props> = ({ order }) => {
  const [totalTimeStarted, setTotalTimeStarted] = React.useState(order.total_time_started)

  const [startInterval, clearInterval] = useInterval(() => {
    setTotalTimeStarted(time => time + 1)
  }, 1000)

  React.useEffect(() => {
    startInterval()

    return clearInterval()
  }, [])

  return <HousekeepingOrdersRowTotalTimeStarted totalTimeStarted={totalTimeStarted} />
}
