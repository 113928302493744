import { asDecimal } from '@helpers/utils'
import { ReceptionBookingDetailsCartAvailablePaymentMethod, ReceptionBookingDetailsCartItem } from '@models/reception'
import { CartPaymentMethod, ReceptionBookingCartFormInputs } from '@modules/reception/cart/models'

export const createCartItemFormName = (item: ReceptionBookingDetailsCartItem) =>
  `${item.kind}-${item.booking_guest}-${item.id}`

export const getSelectedBookingCartItems = (
  items: ReceptionBookingDetailsCartItem[],
  formValues: ReceptionBookingCartFormInputs,
): ReceptionBookingDetailsCartItem[] =>
  Object.entries(formValues).reduce((prev, [formName, isSelected]) => {
    if (!isSelected) return prev

    const itemId = formName.split('-')[2]
    if (!itemId) return prev

    const item = items.find(item => item.id === parseInt(itemId) && !item.is_paid)
    return item ? [...prev, item] : prev
  }, [])

export interface CombinedCartItemsPrice {
  totalPrice: string
  originalTotalPrice: string
}

export const createCombinedCartItemsPrice = (items: ReceptionBookingDetailsCartItem[]): CombinedCartItemsPrice => {
  const { totalPrice, originalTotalPrice } = items.reduce(
    (prev, current) => ({
      totalPrice: asDecimal(prev.totalPrice).plus(current.price_brutto).toString(),
      originalTotalPrice: asDecimal(prev.originalTotalPrice).plus(current.price_brutto_without_discount).toString(),
    }),
    { totalPrice: '0', originalTotalPrice: '0' },
  )

  return {
    totalPrice,
    originalTotalPrice,
  }
}

export const isGastroVoucherCartPayment = (selectedPaymentMethod: CartPaymentMethod | null) =>
  ['gastro_card', 'gastro_coupon'].includes(selectedPaymentMethod ?? '')

export const isCartPaymentAvailable = (
  availablePayments: ReceptionBookingDetailsCartAvailablePaymentMethod[],
  payment: ReceptionBookingDetailsCartAvailablePaymentMethod,
) => availablePayments.includes(payment)
