import * as React from 'react'
import { getCancelSource } from '@store/actions/generic-actions'
import { CancelTokenSource } from 'axios'

export const REQUEST_CANCELED = 'request-canceled'

export const useCancelablePromise = (
  fn: (cancelToken: CancelTokenSource) => void,
  deps?: React.DependencyList,
): null => {
  React.useEffect(() => {
    const cancelToken = getCancelSource()
    fn(cancelToken)

    return () => cancelToken.cancel(REQUEST_CANCELED)
  }, deps || [])

  return null
}
