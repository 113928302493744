import * as React from 'react'
import { TableFilters } from '@components/table/table'
import { Label, Row } from 'reactstrap'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { ColAuto } from '@hyper/col-auto'
import DateRangeInput from '@components/date/date-range-input'
import { FormProvider, useForm } from 'react-hook-form'
import { FormSelect } from '@hyper/forms/form-select'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { cashbackVoucherStatusOptions } from '@modules/products/cashback-vouchers/table'

interface Props {
  filters: CashbackVouchersTableFiltersParams
  defaultFilters: CashbackVouchersTableFiltersParams
  setFilters: (filters: CashbackVouchersTableFiltersParams) => void
}

export interface CashbackVouchersTableFiltersParams extends TableFilters {
  expire_after_after: Date | null
  expire_after_before: Date | null
  status: CustomReactSelectOption[]
}

export const CashbackVouchersTableFilters: React.FC<Props> = ({ filters, defaultFilters, setFilters }) => {
  const methods = useForm<CashbackVouchersTableFiltersParams>({
    defaultValues: defaultFilters,
  })

  const onSubmit = async payload => setFilters({ ...filters, ...payload })

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  return (
    <FormProvider {...methods}>
      <Row>
        <ColAuto className="px-0">
          <FormSelect
            options={cashbackVoucherStatusOptions}
            name="status"
            label="Status"
            selectProps={{ isSelectMulti: true, isClearable: true }}
            formPlainProps={{ colStyle: { width: 330 } }}
          />
        </ColAuto>
        <ColAuto>
          <Label>Data ważności</Label>
          <DateRangeInput
            isClearable={true}
            startDateName="expire_after_after"
            endDateName="expire_after_before"
            wrapperClassName="is-range"
          />
        </ColAuto>
        <SearchFilter colClassName="col-md-2" />
      </Row>
    </FormProvider>
  )
}
