import * as React from 'react'
import { UncontrolledTooltip } from 'reactstrap'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { fetchDailyStats } from '@store/actions/timeline-actions'
import TimelineDayTooltipDetails from './timeline-day-tooltip-details'
import TimelineDayTooltipDetailsPlaceholder from './timeline-day-tooltip-details-placeholder'
import { useCancelablePromise } from '@components/hooks/use-cancelable-promise'

export interface TimelineDayTooltipProps {
  resortId: string
  accommodationTypeIds: string[]
  date: string
}

interface WrapperProps {
  accommodationTypeIds: string[]
  date: string
  scheduleUpdate: () => void
}

const Wrapper: React.FC<WrapperProps> = ({ accommodationTypeIds, date, scheduleUpdate }) => {
  const stats = useAppSelector((state: RootState) => state.timelineState.dailyStats)

  React.useEffect(() => {
    scheduleUpdate()
  }, [stats])

  return stats ? (
    <TimelineDayTooltipDetails stats={stats} accommodationTypeIds={accommodationTypeIds} date={date} />
  ) : (
    <TimelineDayTooltipDetailsPlaceholder date={date} />
  )
}

const TimelineDayTooltip: React.FC<TimelineDayTooltipProps> = ({ resortId, date, accommodationTypeIds }) => {
  const dispatch = useAppDispatch()
  const [tooltipOpen, setTooltipOpen] = React.useState(false)

  useCancelablePromise(
    cancelToken => {
      if (tooltipOpen) {
        dispatch(fetchDailyStats(resortId, date, accommodationTypeIds, cancelToken))
      }
    },
    [tooltipOpen],
  )

  const toggle = (): void => {
    setTooltipOpen(!tooltipOpen)
  }

  return (
    <UncontrolledTooltip
      delay={500}
      placement="top"
      isOpen={tooltipOpen}
      target={`day-tooltip-${date}`}
      className="calendar-day-tooltip"
      toggle={toggle}
      boundariesElement="window"
      autohide={false}
    >
      {({ scheduleUpdate }) => (
        <Wrapper scheduleUpdate={scheduleUpdate} accommodationTypeIds={accommodationTypeIds} date={date} />
      )}
    </UncontrolledTooltip>
  )
}

export default TimelineDayTooltip
