import * as React from 'react'
import Table from '@components/table/table'
import { ChangeEvent } from 'react'

interface RequiredSelectable {
  id: number
}

export function useSelectableRows<T extends RequiredSelectable>(
  elements: T[],
  nonSelectable: number[] = [],
  {
    tableHeaderClassName,
    selectableCellClassName,
  }: { tableHeaderClassName?: string; selectableCellClassName?: string } = {},
) {
  const [selectedRows, setSelectedRows] = React.useState<T[]>([])

  const handleAllSelect = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedRows(event.target.checked ? elements : [])
  }

  const handleSingleSelect = (e: ChangeEvent<HTMLInputElement>, newSelected: T) => {
    setSelectedRows(
      e.target.checked ? [...selectedRows, newSelected] : [...selectedRows.filter(item => item.id !== newSelected.id)],
    )
  }

  const clearSelectedRows = React.useCallback(() => setSelectedRows([]), [setSelectedRows])

  const selectableHeader = (
    <Table.Header className={tableHeaderClassName} width={10}>
      <input
        className="cursor-pointer"
        type="checkbox"
        onChange={handleAllSelect}
        checked={selectedRows.length === elements.length && elements.length > 0}
      />
    </Table.Header>
  )

  const isRowChecked = (element: T) => !!selectedRows.find(el => el.id === element.id)

  const selectableCell = (element: T) => (
    <Table.Cell style={{ width: 10 }} className={selectableCellClassName}>
      <input
        className="cursor-pointer"
        type="checkbox"
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          handleSingleSelect(event, element)
        }}
        onClick={event => {
          event.stopPropagation()
        }}
        checked={isRowChecked(element)}
        disabled={nonSelectable.includes(element.id)}
      />
    </Table.Cell>
  )

  React.useEffect(() => {
    setSelectedRows(rows => rows.filter(row => !!elements.find(x => x.id === row.id)))
  }, [elements])

  return {
    selectedRows,
    selectableHeader,
    selectableCell,
    clearSelectedRows,
  }
}
