import { RootState } from '@store/index'
import { commonObjectDelete, commonObjectGet } from '@store/actions/generic-actions'
import { Asset, AssetCategory } from '@models/cms-asset'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getAssetCategories = createAsyncThunk<AssetCategory[], void, { state: RootState }>(
  'assets/getAssetCategories',
  async (params, { getState }) => {
    const url = getState().appState.appData.urls.cms.asset_categories
    return await commonObjectGet<AssetCategory[]>(url, params)
  },
)
export const getAssets = createAsyncThunk<Asset[], void, { state: RootState }>(
  'assets/getAssets',
  async (params, { getState }) => {
    const url = getState().appState.appData.urls.cms.assets
    return await commonObjectGet<Asset[]>(url, params)
  },
)

export const removeAssetCategory = createAsyncThunk<AssetCategory, AssetCategory, {}>(
  'assets/removeAssetCategory',
  async payload => {
    await commonObjectDelete<Asset>(payload.urls.details)
    return payload
  },
)

export const removeAsset = createAsyncThunk<Asset, Asset, {}>('assets/removeAsset', async payload => {
  await commonObjectDelete<Asset>(payload.urls.details)
  return payload
})
