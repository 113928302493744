import * as React from 'react'
import { formatDate } from '@helpers/date-helper'
import { createBookingImprovement, ImprovementActionPayload } from '@store/actions/reception-actions'
import { BookingImprovementCode, ImprovementAvailability } from '@models/booking'
import { ReceptionBookingDetails } from '@models/reception'
import { ReceptionBookingCheckInAddImprovementButton } from '@modules/reception/checkin/step-improvements/reception-booking-check-in-add-improvement-button'
import { useFormContext, useWatch } from 'react-hook-form'
import { SpinInput } from '@components/spin-input'
import { ReceptionBookingCheckInImprovementDateInput } from '@modules/reception/checkin/step-improvements/reception-booking-check-in-improvement-date-input'
import { useHandleAxiosFormErrors } from '@helpers/handle-axios-errors'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import classNames from 'classnames'
import { useModal } from '@components/modals/use-modal'
import { CollisionCode, useExtendedStayCollision } from '@modules/reception/extended-stay/use-extended-stay-collision'

interface FormInputs {
  amount: number
  date_from: Date | null
  date_to: Date | null
  improvementCode: BookingImprovementCode
}

interface Props {
  booking: ReceptionBookingDetails
  onPriceUpdate: (price: string | undefined) => void
  onClose?: () => void
  addButtonDisabled?: boolean
  setError: React.Dispatch<React.SetStateAction<string | undefined>>
  handleDelete?: () => void
  improvementPrice?: string
  improvement: ImprovementAvailability
}

export const ReceptionBookingCheckInStepImprovementsActionColumns: React.FC<Props> = ({
  booking,
  onPriceUpdate,
  onClose,
  improvement,
  addButtonDisabled,
  setError,
  handleDelete,
  improvementPrice,
}) => {
  const { showCollisionModal, checkExtendingCollision } = useExtendedStayCollision()

  const handleAxiosFormErrors = useHandleAxiosFormErrors()
  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()

  const { getValues, control } = useFormContext<FormInputs>()
  const [date_from, date_to, amount] = useWatch({
    name: ['date_from', 'date_to', 'amount'],
    control,
  })

  const updatePrice = (result: string) => {
    onPriceUpdate(result)
    setError(undefined)
  }

  const getPayload = (): ImprovementActionPayload => {
    const [date_from, date_to, amount, code] = getValues(['date_from', 'date_to', 'amount', 'improvementCode'])
    return {
      code: code || improvement.code,
      amount,
      date_from: formatDate(date_from),
      date_to: formatDate(date_to),
    }
  }

  const calculateNewPrice = async () => {
    const payload = getPayload()
    if (!payload.date_from || !payload.date_to) {
      return
    }
    try {
      updatePrice(
        (
          await commonObjectPost<{ total_price: string; limit: number }>(booking.urls.improvement_calculation, [
            payload,
          ])
        ).total_price,
      )
    } catch (error) {
      if (error.response?.status === 400) {
        setError(Object.values(error.response.data[0]).join('. '))
      }
    }
  }

  useDidUpdateEffect(() => {
    calculateNewPrice()
  }, [date_from, date_to, improvement.code, amount || 1])

  const { isLoading, action: addImprovement } = useApiRequest(async () => {
    try {
      await dispatch(createBookingImprovement(booking.urls.improvement_create, [getPayload()]))
      addSuccessNotification('Ulepszenie zostało dodane')
      onClose?.()
    } catch (error) {
      handleAxiosFormErrors(error, (key, data) => setError(data.message), { showGlobalError: false })
    }
  })

  const [showBandExtensionInformationModal] = useModal('BandExtensionInformationModal', { onConfirm: addImprovement })
  const [showSmartWatchAddModal] = useModal('SmartWatchAddModal', { getPayload, booking })

  const handleAddImprovement = async () => {
    if (['early_check_in_13', 'extend_stay_12'].includes(improvement.code)) {
      const { collisionBooking } = await checkExtendingCollision(improvement.code as CollisionCode)

      collisionBooking
        ? showCollisionModal(improvement.code as CollisionCode, collisionBooking, showBandExtensionInformationModal)
        : showBandExtensionInformationModal()

      return
    }

    if (['smartwatch_promotion', 'smartwatch'].includes(improvement.code)) {
      showSmartWatchAddModal()
      return
    }

    await addImprovement()
  }

  const withDelete = handleDelete && typeof handleDelete === 'function'

  return (
    <>
      <td
        className={classNames('reception__booking-check-in__improvement-step__amount-col', {
          'reception__booking-check-in__improvement-step--inactive-col': !improvement.can_change_amount,
        })}
      >
        <SpinInput inputName="amount" inputProps={{ min: 1, disabled: !improvement.can_change_amount }} />
      </td>
      <td className="pb-0">
        <ReceptionBookingCheckInImprovementDateInput
          calculateEveryDay={improvement.calculate_every_day}
          booking={booking}
          disabled={improvement.price_type_2 !== 'per_day'}
        />
      </td>
      {withDelete && (
        <td className="reception__booking-check-in__improvement-step__table__data">
          <i
            className="uil-trash-alt cursor-pointer font-18 grey-400"
            onClick={handleDelete}
            role="delete-improvement"
          />
        </td>
      )}
      <td className="text-right">
        <ReceptionBookingCheckInAddImprovementButton
          disabled={addButtonDisabled}
          improvementPrice={improvementPrice}
          isSaving={isLoading}
          onClick={handleAddImprovement}
        />
      </td>
    </>
  )
}
