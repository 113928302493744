import * as React from 'react'
import {
  ControlledMenu,
  ControlledMenuProps,
  MenuItem,
  MenuItemProps,
  MenuState,
  useMenuState,
} from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/core.css'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { useClickOutside } from '@components/hooks/use-click-outside'
import classnames from 'classnames'

export type ContextMenuState = MenuState

export type ContextMenuProps = ControlledMenuProps

interface Props extends ContextMenuProps {
  children: React.ReactNode
  menuItems: React.ReactNode
  isDisabled?: boolean
  onMenuStateChange?: (menuState: ContextMenuState | undefined) => void
}

export const ContextMenu = ({
  children,
  menuItems,
  isDisabled,
  onMenuStateChange,
  ...contextMenuProps
}: Props): JSX.Element => {
  const [menuProps, toggleMenu] = useMenuState()
  const [anchorPoint, setAnchorPoint] = React.useState({ x: 0, y: 0 })

  const controlledMenuRef = React.useRef<HTMLDivElement>(null)

  const handleOpenContextMenu = e => {
    if (isDisabled) return
    e.preventDefault()
    setAnchorPoint({ x: e.clientX, y: e.clientY })
    toggleMenu(true)
  }

  useClickOutside(controlledMenuRef, () => toggleMenu(false))

  useDidUpdateEffect(() => {
    onMenuStateChange?.(menuProps.state)
  }, [menuProps.state])

  return (
    <div onContextMenu={handleOpenContextMenu} className="context-menu">
      {children}
      <ControlledMenu
        {...menuProps}
        anchorPoint={anchorPoint}
        onClose={() => toggleMenu(false)}
        ref={controlledMenuRef}
        {...contextMenuProps}
      >
        {menuItems}
      </ControlledMenu>
    </div>
  )
}

export interface ContextMenuItemProps extends Omit<MenuItemProps, 'className'> {
  children: React.ReactNode
  className?: string
}

ContextMenu.Item = ({ children, className, ...props }: ContextMenuItemProps) => (
  <MenuItem {...props} className={classnames({ 'pe-none': props.disabled }, className)}>
    {children}
  </MenuItem>
)
