import * as React from 'react'
import { SubscriptionCode } from '@modules/subscription/models'
import { Button, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useFormRequest } from '@components/hooks/use-api-request'
import { useForm } from 'react-hook-form'
import { useNotificationHook } from '@hyper/use-notification-hook'
import ModalBody from 'reactstrap/lib/ModalBody'
import { FormInput } from '@hyper/forms'
import { commonObjectPatch } from '@store/actions/generic-actions'
import { updateSubscriptionDetails } from '@store/slices/subscription-slice'
import { useAppDispatch } from '@store/index'
import { BaseModalProps } from '@components/modals/types'
import { Form } from '@hyper/forms/form'

interface Props extends BaseModalProps {
  subscriptionCode: SubscriptionCode
}

interface FormInputs {
  name: string
}

export const SubscriptionCodeEditModal: React.FC<Props> = ({ subscriptionCode, toggleIsVisible }) => {
  const methods = useForm<FormInputs>()
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    dispatch(updateSubscriptionDetails(await commonObjectPatch(subscriptionCode.urls.details, payload)))
    addSuccessMessage('Sukces', `Kod ${subscriptionCode.code} został zmieniony!`)
    toggleIsVisible()
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>
        Konwersja kodu <span className="text-semi-strong">{subscriptionCode.code}</span>
      </ModalHeader>
      <ModalBody>
        <Row>
          <FormInput name="name" type="text" />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>

        <SaveButton className="btn btn-green" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
