import * as React from 'react'
import { ButtonWithIcon } from '@components/button-with-icon'
import { ReceptionBookingNewFeedingFastSelectionBlock } from '@modules/reception/checkin/step-feeding/new-feeding/fast-selection/reception-booking-new-feeding-fast-selection-block'
import { Row } from 'reactstrap'
import {
  createDefaultNewFeedingFormValues,
  getCalculationDetailsWithoutEdgeMeals,
  getFeedingKindAvailability,
  getMealsAmount,
  isEmptyFeedingState,
  isMealUpcoming,
} from '@modules/reception/checkin/step-feeding/new-feeding/utils'
import Decimal from 'decimal.js'
import declination from '@helpers/declination'
import { ReceptionBookingDetails } from '@models/reception'
import { differenceInDays } from 'date-fns'
import { parseISODate } from '@helpers/date-helper'
import {
  NewFeedingCalculationDetails,
  NewFeedingCalculations,
  NewFeedingKind,
} from '@modules/reception/checkin/step-feeding/new-feeding/models'
import { useFormContext } from 'react-hook-form'
import { useAppSelector } from '@store/index'
import { selectExposedEdgeFeedings } from '@store/slices/new-feeding-slice'
import { asDecimal, sumDecimalArray } from '@helpers/utils'
import { useModal } from '@components/modals/use-modal'
import { useFastSelectionFullFeedingPrice } from '@modules/reception/checkin/step-feeding/new-feeding/fast-selection/use-fast-selection-full-feeding-price'

interface Props {
  feedingCalculations: NewFeedingCalculations
  booking: ReceptionBookingDetails
  initialCalculationDetails: NewFeedingCalculationDetails[]
}

export const ReceptionBookingNewFeedingFastSelection = ({
  feedingCalculations,
  booking,
  initialCalculationDetails,
}: Props): JSX.Element | null => {
  const methods = useFormContext()
  const exposedEdgeFeedings = useAppSelector(selectExposedEdgeFeedings)
  const [showFeedingDiscountModal] = useModal('ReceptionBookingNewFeedingDiscountModal', {
    bookingDetails: booking,
    onFeedingChange: methods.reset,
  })

  const [showCurrentFeedingPriceList] = useModal('ReceptionBookingNewFeedingPriceListModal', {
    bookingDetails: booking,
  })

  const [groupSelection, setGroupSelection] = React.useState({
    full: false,
    breakfast: false,
    dinner: false,
  })

  const { breakfastAmount, dinnerAmount } = getMealsAmount(feedingCalculations.details, exposedEdgeFeedings)

  const { setValue } = useFormContext()

  const numberOfNights = differenceInDays(
    parseISODate(booking.date_to) as Date,
    parseISODate(booking.date_from) as Date,
  )

  const updateFeeding = (feedingDetails: NewFeedingCalculationDetails[]) => {
    Object.entries(createDefaultNewFeedingFormValues(feedingDetails)).map(([key, value]) => setValue(key, value))
  }

  const selectFullForAll = () => {
    updateFeeding(
      getCalculationDetailsWithoutEdgeMeals(feedingCalculations.details, exposedEdgeFeedings).map(detail => ({
        ...detail,
        checked: !groupSelection.full,
      })),
    )

    setGroupSelection(state => ({ ...state, breakfast: !groupSelection.full, dinner: !groupSelection.full }))
  }

  const selectMealForAll = (kind: NewFeedingKind) => () => {
    const feedingDetails = getCalculationDetailsWithoutEdgeMeals(
      feedingCalculations.details,
      exposedEdgeFeedings,
      kind,
    ).reduce(
      (previousValue, currentValue) =>
        currentValue.kind === kind
          ? [...previousValue, { ...currentValue, checked: !groupSelection[kind] }]
          : previousValue,
      [],
    )

    updateFeeding(feedingDetails)
    setGroupSelection(state => ({ ...state, [kind]: !groupSelection[kind] }))
  }

  const { isBreakfastAvailable, isDinnerAvailable } = getFeedingKindAvailability(feedingCalculations.details)

  const isFastSelectionEnabled = React.useMemo(
    () =>
      isEmptyFeedingState(initialCalculationDetails) &&
      (isBreakfastAvailable || isDinnerAvailable) &&
      initialCalculationDetails.every(isMealUpcoming),
    [initialCalculationDetails, isDinnerAvailable, isBreakfastAvailable],
  )

  React.useEffect(() => {
    setGroupSelection(state => ({ ...state, full: groupSelection.breakfast && groupSelection.dinner }))
  }, [groupSelection.dinner, groupSelection.breakfast])

  const getTotalPriceForKind = (kind?: NewFeedingKind) => {
    const feedingDetails = getCalculationDetailsWithoutEdgeMeals(feedingCalculations.details, exposedEdgeFeedings, kind)

    return sumDecimalArray(
      feedingDetails.map(meal =>
        asDecimal(!kind ? meal.original_price_brutto_in_hb : meal.kind === kind ? meal.price_brutto : 0),
      ),
    ).toString()
  }

  const getPricePerDay = (kind?: NewFeedingKind) =>
    asDecimal(getTotalPriceForKind(kind))
      .div(differenceInDays(parseISODate(booking.date_to) as Date, parseISODate(booking.date_from) as Date))
      .toString()

  const {
    totalPrice: fullFeedingPrice,
    pricePerDay: fullFeedingPricePerDay,
    isCalculating: isCalculatingFullPrice,
  } = useFastSelectionFullFeedingPrice(booking)

  return (
    <div>
      <div className="d-flex justify-content-between">
        <div>
          <p className="text-secondary font-16 font-weight-bold mb-1">Panel wyżywienia</p>
          <strong className="text-default">
            Wyżywienie w trakcie pobytu ({numberOfNights} {declination.night(numberOfNights)}):
          </strong>
        </div>
        <div className="d-flex">
          <ButtonWithIcon
            icon="uil-info-circle font-14 lh-initial"
            text="Tabela cen"
            color="light"
            btnClass="btn-tall mr-2"
            handleClick={showCurrentFeedingPriceList}
          />

          <ButtonWithIcon
            icon="uil-percentage font-14 lh-initial"
            text="Rabatowanie"
            color="primary"
            btnClass="btn-tall"
            handleClick={showFeedingDiscountModal}
          />
        </div>
      </div>
      {isFastSelectionEnabled && (
        <Row className="mt-3">
          {isBreakfastAvailable && (
            <ReceptionBookingNewFeedingFastSelectionBlock
              onClick={selectMealForAll('breakfast')}
              price={getTotalPriceForKind('breakfast')}
              pricePerDay={getPricePerDay('breakfast')}
              title="Śniadanie"
              mealsCount={breakfastAmount}
              isSelected={groupSelection.breakfast}
            />
          )}
          {isDinnerAvailable && (
            <ReceptionBookingNewFeedingFastSelectionBlock
              onClick={selectMealForAll('dinner')}
              price={getTotalPriceForKind('dinner')}
              pricePerDay={getPricePerDay('dinner')}
              title="Obiadokolacje"
              mealsCount={dinnerAmount}
              isSelected={groupSelection.dinner}
            />
          )}
          {isDinnerAvailable && isBreakfastAvailable && (
            <ReceptionBookingNewFeedingFastSelectionBlock
              onClick={selectFullForAll}
              price={fullFeedingPrice}
              pricePerDay={fullFeedingPricePerDay}
              title="Pełne wyżywienie"
              isCalculating={isCalculatingFullPrice}
              mealsCount={Decimal.sum(breakfastAmount, dinnerAmount).toNumber()}
              isSelected={groupSelection.full}
            />
          )}
        </Row>
      )}
    </div>
  )
}
