import * as React from 'react'
import { NewFeedingCalculations } from '@modules/reception/checkin/step-feeding/new-feeding/models'
import { ReceptionBookingNewFeedingSummaryList } from '@modules/reception/checkin/step-feeding/new-feeding/summary/reception-booking-new-feeding-summary-list'
import { ReceptionBookingDetails } from '@models/reception'
import { ReceptionBookingNewFeedingSummaryFooter } from '@modules/reception/checkin/step-feeding/new-feeding/summary/reception-booking-new-feeding-summary-footer'
import { asDecimal } from '@helpers/utils'
import { IconWithText } from '@components/icon-with-text'
import classNames from 'classnames'
import { useToggle } from '@components/hooks/use-toggle'
import { ReceptionBookingNewFeedingSummaryAlerts } from '@modules/reception/checkin/step-feeding/new-feeding/summary/alerts/reception-booking-new-feeding-summary-alerts'

interface Props {
  booking: ReceptionBookingDetails
  calculations: NewFeedingCalculations
  isPaymentByWalletSelected: boolean
}

export const ReceptionBookingNewFeedingSummary = ({
  booking,
  calculations,
  isPaymentByWalletSelected,
}: Props): JSX.Element => {
  const [showExplanations, toggleShowExplanations] = useToggle(true)
  const isFeedingEmpty = Object.values(calculations.prices).every(price =>
    Array.isArray(price) ? true : asDecimal(price).eq(0),
  )

  const hasDiscount = calculations.details.some(
    calculation => !!calculation.related_date && calculation.discounts.length,
  )

  const hasFixedPrice = calculations.details.some(calculation => calculation.fixed_price_brutto)

  const isAnyExplanationAvailable = isPaymentByWalletSelected || hasDiscount || hasFixedPrice

  return (
    <div className="bg-grey py-2" style={{ marginInline: '-16px', paddingInline: '16px' }}>
      <div className="font-15 border-bottom pb-2 mb-2 d-flex justify-content-between">
        <strong className="text-secondary">Podsumowanie wyżywienia</strong>
        {isAnyExplanationAvailable && (
          <IconWithText
            icon={classNames('font-12 ', showExplanations ? 'uil-eye-slash' : 'uil-eye')}
            text={showExplanations ? 'Ukryj objaśnienia' : 'Pokaż objaśnienia'}
            textClass="font-11"
            onClick={toggleShowExplanations}
          />
        )}
      </div>

      {isAnyExplanationAvailable && (
        <ReceptionBookingNewFeedingSummaryAlerts
          isVisible={showExplanations}
          isPaymentByWalletSelected={isPaymentByWalletSelected}
          hasDiscount={hasDiscount}
          hasFixedPrice={hasFixedPrice}
        />
      )}

      {isFeedingEmpty ? (
        <p className="text-center text-muted font-14 mt-3">Brak dodanego wyżywienia</p>
      ) : (
        <ReceptionBookingNewFeedingSummaryList booking={booking} prices={calculations.prices} />
      )}

      <ReceptionBookingNewFeedingSummaryFooter priceToPay={calculations.prices.rest_to_pay} />
    </div>
  )
}
