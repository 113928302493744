import * as React from 'react'
import Spinner from '@hyper/spinner'
import classNames from 'classnames'

interface PriceLoaderProps {
  price?: any
  content: React.ReactNode
  spinnerClass?: string
  spinnerColor?: string
  isLoading?: boolean
}

export const PriceLoader: React.FC<PriceLoaderProps> = ({
  price,
  content,
  spinnerClass,
  spinnerColor,
  isLoading = false,
}) => (
  <div className="d-inline-block">
    {typeof price !== 'undefined' && !isLoading ? (
      content
    ) : (
      <Spinner
        className={classNames('inline-spinner', spinnerClass)}
        size="xs"
        color={spinnerColor}
        role="price-spinner"
      />
    )}
  </div>
)
