import { ReceptionBookingDetails, ReceptionReminder } from '@models/reception'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { ReceptionBookingCheck } from '@modules/reception/common/reception-booking-check'
import ModalBody from 'reactstrap/lib/ModalBody'
import { newUserPredicator, ReceptionCheckReminders } from '@modules/reception/common/reception-check-reminders'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { asDecimal } from '@helpers/utils'
import { AlertDanger } from '@components/alerts'
import { ReceptionBookingCheckInStepPaymentsContent } from '@modules/reception/checkin/step-payments/content'
import { isReservedByBooking } from '@modules/reception/checkin/preview/reception-booking-check-in-preview-bill'
import { Form } from '@hyper/forms/form'
import { useApiRequest } from '@components/hooks/use-api-request'
import { getBookingPayments } from '@store/actions/reservation-actions'
import { useAppDispatch } from '@store/index'

interface Props {
  nextStep: () => void
  previousStep: () => void
  booking: ReceptionBookingDetails
  title: string
}

interface FormInputs {
  check_payments: boolean
}

const reminders: ReceptionReminder[] = [
  {
    id: 'check_payments',
    isClosable: true,
    children: (
      <>
        Przy <strong>płatności kartą</strong> wydrukowane potwierdzenie płatności i{' '}
        <strong>wepnij do rozliczenia</strong> Gościa.
      </>
    ),
  },
]

export const ReceptionBookingCheckInStepPayments: React.FC<Props> = ({ booking, previousStep, nextStep, title }) => {
  const user = useAuthenticatedUser()
  const methods = useForm<FormInputs>({
    defaultValues: {
      check_payments: newUserPredicator(user),
    },
  })
  const dispatch = useAppDispatch()

  const { action: fetchPayments } = useApiRequest(async () => {
    if (booking) {
      dispatch(await getBookingPayments(booking))
    }
  })

  React.useEffect(() => {
    fetchPayments()
  }, [booking])

  const restToPay = React.useMemo(
    () => (isReservedByBooking(booking) ? asDecimal(booking.required_climatic_tax) : asDecimal(booking.rest_to_pay)),
    [booking.rest_to_pay, booking.damages, booking.source],
  )
  const [isNextStepDisabled, setIsNextStepDisabled] = React.useState(false)

  const isPaid = asDecimal(restToPay).add(booking.required_deposit_amount).lessThanOrEqualTo(0)

  const onSubmit = () => (isPaid ? nextStep() : setIsNextStepDisabled(true))

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      role="step-payments"
      className="reception__booking-check-in__payments_step"
    >
      <ModalBody className="pb-0">
        <h4>{title}</h4>
        <ReceptionCheckReminders reminders={reminders} required={!newUserPredicator(user)} />
        {isNextStepDisabled && !isPaid && (
          <AlertDanger>
            <span className="font-11 ml-1">
              Aby dokończyć proces meldowania <strong>wszystkie opłaty muszą zostać uregulowane</strong>.
            </span>
          </AlertDanger>
        )}
        <ReceptionBookingCheckInStepPaymentsContent booking={booking} />
      </ModalBody>
      <ReceptionBookingCheck
        nextStep={nextStep}
        previousStep={previousStep}
        isDisabled={isNextStepDisabled && !isPaid}
      />
    </Form>
  )
}
