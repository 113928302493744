import { commonObjectGet } from '@store/actions/generic-actions'
import {
  EnhancedFloorHeatingMeasurement,
  FloorHeatingMeasurement,
  FloorHeatingMeasurementUrls,
  FloorHeatingWindowState,
} from '@modules/floor-heating/models'
import * as React from 'react'
import { getEnhancedFloorMeasurements } from '@modules/floor-heating/utils'
import { useAppData } from '@components/hooks/use-app-data'
import { PaginationResponse } from '@models/dashboard'
import { setDashboardStandardPaginator } from '@store/actions/dashboard-actions'
import { useAppDispatch } from '@store/index'
import { FloorHeatingDetailsHistoryFilterParams } from '@modules/floor-heating/details/history/history-filters'

type MeasurementResponse = PaginationResponse<FloorHeatingMeasurement> & { urls: FloorHeatingMeasurementUrls }

interface Response {
  windowState: FloorHeatingWindowState | null
  measurements: EnhancedFloorHeatingMeasurement[]
  measurementUrls: FloorHeatingMeasurementUrls | null
  fetchMeasurements: (filters?: FloorHeatingDetailsHistoryFilterParams) => Promise<void>
}

export const useFloorHeatingDetails = (apartmentId: string | undefined, withPaginator = true): Response => {
  const appData = useAppData()
  const dispatch = useAppDispatch()
  const [measurementUrls, setMeasurementUrls] = React.useState<FloorHeatingMeasurementUrls | null>(null)
  const [localMeasurements, setLocalMeasurements] = React.useState<FloorHeatingMeasurement[]>([])
  const [windowState, setWindowState] = React.useState<FloorHeatingWindowState | null>(null)

  const enhanceMeasurements = getEnhancedFloorMeasurements(localMeasurements, windowState ? [windowState] : [])

  const fetchMeasurements = async (filters: FloorHeatingDetailsHistoryFilterParams) => {
    if (!apartmentId) return

    const response = await commonObjectGet<MeasurementResponse>(
      appData.urls.meter.floor_heating_measurements.replace('replace-me', apartmentId),
      filters,
    )

    setLocalMeasurements(response.results)
    setMeasurementUrls(response.urls)
    if (withPaginator) dispatch(setDashboardStandardPaginator(response))
    setWindowState(await commonObjectGet<FloorHeatingWindowState>(response.urls.windows))
  }

  return {
    measurements: enhanceMeasurements,
    measurementUrls,
    windowState,
    fetchMeasurements,
  }
}
