import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { Button, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { useAppDispatch } from '@store/index'
import { useForm } from 'react-hook-form'
import { endOfToday, setYear, startOfToday, startOfYear } from 'date-fns'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { createReportTask } from '@store/actions/report-tasks-actions'
import { Form } from '@hyper/forms/form'
import { FormSelect } from '@hyper/forms/form-select'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { SaveButton } from '@hyper/button'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { useAppData } from '@components/hooks/use-app-data'
import { useSelectWithAllOption } from '@helpers/use-select-with-all-option'
import { createSelectOption } from '@helpers/utils'

interface FormInputs {
  created_after: Date
  created_before: Date
  status: CustomReactSelectOption
}

export const ReportCashbackVoucherModal: React.FC<BaseModalProps> = ({ toggleIsVisible }) => {
  const { urls } = useAppData()
  const dispatch = useAppDispatch()

  const methods = useForm<FormInputs>({
    mode: 'all',
    defaultValues: {
      created_after: startOfYear(setYear(startOfToday(), 2024)),
      created_before: endOfToday(),
      status: { label: 'Wszystkie', value: 'all' },
    },
  })

  const { isLoading, action: onSubmit } = useReportFormRequest(async (payload: FormInputs) => {
    const status = payload.status.value

    const reportTask = await dispatch(
      createReportTask([
        urls.reports.report_task_cashback_voucher_report,
        { ...payload, status: status === 'all' ? null : status },
      ]),
    )
    toggleIsVisible()
    return reportTask
  }, methods.setError)

  const { options: allStatues } = useSelectWithAllOption(StatusOptions, methods, 'statuses')

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Raport voucherów rePLAY!</ModalHeader>
      <ModalBody>
        <Row>
          <DateFromToInput
            startDateName="created_after"
            endDateName="created_before"
            label="Data utworzenia"
            isClearable={false}
            inputGroupClassName="mb-2"
          />

          <FormSelect options={allStatues} name="status" label="Status" />
        </Row>
      </ModalBody>

      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" label="Zleć raport" labelSaving="Zlecanie.." isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}

const StatusOptions = [
  createSelectOption('Generowany', 'generate'),
  createSelectOption('Aktywny oczekujący', 'active'),
  createSelectOption('Aktywny', 'active_assign'),
  createSelectOption('Wygenerowany', 'generated'),
  createSelectOption('Nieaktywny', 'cancelled'),
  createSelectOption('Wykorzystany', 'used'),
  createSelectOption('Przeterminowany', 'outdated'),
]
