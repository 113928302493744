import * as React from 'react'
import { Tooltip } from 'reactstrap'
import { ReceptionRowNotesTooltipDetails } from '@modules/reception/common/reception-row-notes/reception-row-notes-tooltip-details'
import { TechnicalOrder } from '@models/technical-orders'
import { useGetTechnicalOrderDetailsQuery } from '@api/technical-orders'

interface Props {
  technicalOrder: TechnicalOrder
}

export const TechnicalOrdersNotes = ({ technicalOrder }: Props): JSX.Element => {
  const iconStyles = { opacity: !technicalOrder.notes_counter ? 0.3 : 1 }
  const [tooltipOpen, setTooltipOpen] = React.useState(false)
  const [handleGetDetails, { data: technicalOrderDetails }] = useGetTechnicalOrderDetailsQuery()

  const toggle = React.useCallback(() => {
    const isTooltipOpen = !tooltipOpen

    if (isTooltipOpen && !technicalOrderDetails) {
      handleGetDetails(technicalOrder.urls.details, false)
    }

    setTooltipOpen(isTooltipOpen)
  }, [tooltipOpen, technicalOrderDetails])

  const tooltipId = `notes-tooltip-${technicalOrder.id}`

  return (
    <>
      {!!technicalOrder.notes_counter && (
        <Tooltip
          placement="top"
          isOpen={tooltipOpen && !!technicalOrderDetails}
          target={tooltipId}
          toggle={toggle}
          className="reception__notes-tooltip"
          autohide={false}
        >
          {!!technicalOrderDetails && <ReceptionRowNotesTooltipDetails contentNotes={technicalOrderDetails.notes} />}
        </Tooltip>
      )}
      <i className="uil-file-check-alt font-18 text-muted mr-1" style={iconStyles} id={tooltipId} />
      {!!technicalOrder.notes_counter && <span className="font-14 text-muted">({technicalOrder.notes_counter})</span>}
    </>
  )
}
