import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import classnames from 'classnames'

export const ProductSalePageButton = ({ saleUrl, className = '' }): JSX.Element => (
  <a href={saleUrl} target="_blank">
    <button className={classnames('btn btn-primary btn-sm', className)}>
      <IconWithText icon="uil-globe font-16 lh-initial" text="Strona sprzedaży" wrapperClassNames="text-white" />
    </button>
  </a>
)

export const ProductProFormInvoiceButton = ({ onClick, className = '' }): JSX.Element => (
  <button className={classnames('btn btn-info btn-sm', className)} onClick={onClick}>
    <IconWithText icon="uil-invoice font-16 lh-initial" text="Faktura pro-forma" wrapperClassNames="text-white" />
  </button>
)

interface Props {
  className?: string
  icon?: string
  onClick?: () => void
  text?: string
}

export const ProductCustomActionButton = ({ className = '', text = '', icon = '', onClick }: Props) => (
  <button className={classnames('btn btn-secondary btn-sm', className)} onClick={onClick}>
    {icon ? (
      <IconWithText icon={classnames('font-16 lh-initial', icon)} text={text} wrapperClassNames="text-white" />
    ) : (
      <span>{text}</span>
    )}
  </button>
)
