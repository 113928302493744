import * as React from 'react'
import { ReceptionBooking } from '@models/reception'
import { ReceptionApartmentHousekeepingStatus } from '@modules/reception/common/reception-apartment-housekeeping-status'

interface ReceptionRowApartmentProps {
  booking: ReceptionBooking
  showHouseKeeping?: boolean
}

export const ReceptionRowApartment: React.FC<ReceptionRowApartmentProps> = ({ booking, showHouseKeeping }) => {
  if (!booking.apartment) {
    return null
  }

  return (
    <>
      <div className="mb-1 d-flex reception__bookings_table__row__apartment">
        <strong className="pr-1">{booking.apartment.name}</strong>
        {showHouseKeeping && <ReceptionApartmentHousekeepingStatus apartment={booking.apartment} />}
      </div>

      <a href={booking.urls.details} target="_blank" className="text-default">
        {booking.reservation_number}
      </a>
    </>
  )
}
