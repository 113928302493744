import * as React from 'react'
import { HrWorker } from '@modules/hr/workers/models'
import { useCountries } from '@components/hooks/use-countries'
import { getById } from '@helpers/utils'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { IconWithText } from '@components/icon-with-text'
import { useModal } from '@components/modals/use-modal'
import { HrWorkerStatus } from '@modules/hr/workers/worker-status'

interface Props {
  hrWorker: HrWorker
  onModalHide: () => void
}

export const HrWorkerDataPreview = ({ hrWorker, onModalHide }: Props): JSX.Element => {
  const [showWorkerDetails] = useModal('HrWorkerDetailsModal', { worker: hrWorker }, { persist: true })

  const { countries } = useCountries()
  const nationality = getById(countries, hrWorker.nationality)

  const showDetailsModal = () => {
    showWorkerDetails(null, { worker: hrWorker })
    onModalHide()
  }

  return (
    <div className="col-8 my-3 px-0">
      <DataRow title="Status:" content={<HrWorkerStatus worker={hrWorker} />} />
      <DataRow
        title="Imię i nazwisko:"
        content={
          <div className="d-flex">
            <span>
              {hrWorker.first_name} {hrWorker.last_name}
            </span>
            <IconWithText
              icon="uil-eye font-15 lh-0 ml-3"
              text="zobacz profil"
              wrapperClassNames="text-secondary text-nowrap font-11"
              onClick={showDetailsModal}
            />
          </div>
        }
      />
      <DataRow title="Obywatelstwo:" content={nationality?.name} />
      <DataRow title="PESEL:" content={hrWorker.tax_id} />
      <DataRow title="Data urodzenia:" content={toDefaultDateFormat(hrWorker.birthday)} />
      <DataRow title="Numer dowodu osobistego:" content={hrWorker.personal_id_number} />
      <DataRow title="Adres e-mail:" content={hrWorker.email} />
      <DataRow title="Numer telefonu:" content={hrWorker.phone} />
    </div>
  )
}

const DataRow = ({ title, content }) => (
  <div className="row mb-1">
    <div className="col-6">{title}</div>
    <div className="col-6 fw-semi-bold">{content}</div>
  </div>
)
