import { Cafeteria, CafeteriaAggregation, CafeteriaAppData, CafeteriaDetails } from '@models/cafeteria'
import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { getCafeteriaAppData, getCafeteriaDetails, getCafeterias } from '@store/actions/cafeterias-action'
import { RootState } from '@store/index'

interface State {
  appData: CafeteriaAppData
  cafeterias: EntityState<Cafeteria>
  cafeteriaDetails: null | CafeteriaDetails
  aggregation: CafeteriaAggregation | null
}

const cafeteriasAdapter = createEntityAdapter<Cafeteria>()

const initialState: State = {
  appData: {
    status: 'unknown',
    cafeteria_operators: [],
  },
  cafeterias: cafeteriasAdapter.getInitialState(),
  cafeteriaDetails: null,
  aggregation: null,
}

export const cafeteriaSlice = createSlice({
  name: 'cafeteria',
  initialState,
  reducers: {
    updateCafeteriaDetails(state, action: PayloadAction<CafeteriaDetails>) {
      if (state.cafeteriaDetails?.id === action.payload.id) {
        state.cafeteriaDetails = action.payload
      }
      cafeteriasAdapter.upsertOne(state.cafeterias, action.payload)
    },
    setCafeteriaAppData(state, action: PayloadAction<CafeteriaAppData>) {
      state.appData = action.payload
    },
    clearCafeterias(state) {
      cafeteriasAdapter.removeAll(state.cafeterias)
    },
    clearCafeteriaDetails(state) {
      state.cafeteriaDetails = null
    },
  },
  extraReducers: builder => {
    builder.addCase(getCafeteriaAppData.fulfilled, (state, action) => {
      state.appData = { ...state.appData, ...action.payload, status: 'ready' }
    })
    builder.addCase(getCafeterias.fulfilled, (state, action) => {
      cafeteriasAdapter.setAll(state.cafeterias, action.payload.results)
      state.aggregation = action.payload.aggregation
    })
    builder.addCase(getCafeteriaDetails.fulfilled, (state, action) => {
      state.cafeteriaDetails = action.payload
    })
  },
})

export const { clearCafeterias, updateCafeteriaDetails, clearCafeteriaDetails, setCafeteriaAppData } =
  cafeteriaSlice.actions
export const { selectAll: cafeteriasSelector } = cafeteriasAdapter.getSelectors(
  (state: RootState) => state.cafeteriaState.cafeterias,
)
export const cafeteriaDetailsSelector = (state: RootState) => state.cafeteriaState.cafeteriaDetails
export const selectCafeteriaAggregation = (state: RootState): CafeteriaAggregation | null =>
  state.cafeteriaState.aggregation

export default cafeteriaSlice.reducer
