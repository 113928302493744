import * as React from 'react'
import { UpSellBooking } from '@models/upselling'
import Table, { TableHeaderValue } from '@components/table/table'
import { UpSellBookingsTableRow } from '@modules/upselling/upsell-bookings/table/row'
import { UpsellBookingsFormInputs } from '@modules/upselling/upsell-bookings'

interface Props {
  upsellBookings: UpSellBooking[]
  isLoading: boolean
  filters: UpsellBookingsFormInputs
  setFilters: (filters: UpsellBookingsFormInputs) => void
  type: string
}

const headers: TableHeaderValue[] = [
  { title: 'Status' },
  { title: 'Termin Pobytu', sortField: 'booking__date_from' },
  { title: 'Gość', sortField: 'client__name' },
  { title: 'Numer rezerwacji' },
  { title: 'Lokal', sortField: 'booking__apartment__name' },
  { title: '' },
]

export const UpSellBookingsTable: React.FC<Props> = ({ upsellBookings, isLoading, filters, setFilters, type }) => (
  <Table
    className="table-hover w-100"
    headerValues={headers}
    isLoading={isLoading}
    filters={filters}
    setFilters={setFilters}
  >
    {upsellBookings.map(upsellBooking => (
      <UpSellBookingsTableRow key={upsellBooking.id} upsellBooking={upsellBooking} type={type} />
    ))}
  </Table>
)
