import * as React from 'react'
import { logInUser } from '@api/dashboard'
import { FormProvider, useForm } from 'react-hook-form'
import { LoginUIComponent } from '@components/login-ui-component'
import { FormInput } from '@hyper/forms'
import { SaveButton } from '@hyper/button'
import { Col, Row } from 'reactstrap'
import * as queryString from 'querystring'
import { Link, useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { useFormRequest } from '@components/hooks/use-api-request'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { setAppDataAndUser, setAuthStatus } from '@store/slices/app-slice'

interface FormInputs {
  username: string
  password: string
}

const { next } = queryString.parse(location.search.replace('?', ''))

export const LoginView = (): JSX.Element => {
  const isAuthenticated = useAppSelector((state: RootState) => state.appState.isAuthenticated)
  const [shouldRedirect, setShouldRedirect] = React.useState(isAuthenticated)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const methods = useForm<FormInputs>()

  const redirectTo = React.useCallback((url: string) => {
    if (url.startsWith('/dashboard')) {
      navigate(url.substring(10))
    } else {
      window.location.href = url
    }
  }, [])

  const {
    isLoading,
    action: onSubmit,
    state,
  } = useFormRequest(async (data: FormInputs) => {
    try {
      const response = await logInUser(data)
      await dispatch(setAppDataAndUser(response))
      await dispatch(setAuthStatus(true))

      if (next && !Array.isArray(next)) {
        redirectTo(next)
      } else if (response.next_url) {
        redirectTo(response.next_url)
      } else {
        setShouldRedirect(true)
      }
    } catch (error) {
      if (error.response?.status === 403) {
        methods.setError('username', {
          type: 'server',
          message: 'Nieprawidłowy login lub hasło',
        })
      } else {
        throw error
      }
    }
  }, methods.setError)

  React.useEffect(() => {
    if (isAuthenticated || (shouldRedirect && state === 'resolved')) {
      navigate(NavigationPath.Home)
    }
  }, [shouldRedirect, state, isAuthenticated])

  return (
    <LoginUIComponent>
      <FormProvider {...methods}>
        <h2 className="unauthorized__header">Logowanie do systemu PMS</h2>
        <p className="unauthorized__text">Zaloguj się używając przypisanych do Ciebie danych logowania.</p>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Row>
            <FormInput type="email" name="username" label="E-mail" tabIndex={1} placeholder="Wpisz email" />
            <Col md={12} className="text-right">
              <Link to={NavigationPath.AuthorizationResetPasswordLink} className="text-muted">
                Resetuj hasło
              </Link>
            </Col>
            <FormInput type="password" label="Hasło" tabIndex={2} placeholder="Wpisz hasło" name="password" />
          </Row>
          <SaveButton
            role="login"
            labelSaving="Logowanie..."
            isSaving={isLoading}
            label="Zaloguj się"
            type="submit"
            className="btn btn-secondary btn-block"
          />
        </form>
      </FormProvider>
    </LoginUIComponent>
  )
}
