import * as React from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApiRequest, useFormRequest } from '@components/hooks/use-api-request'
import { BaseModalProps } from '@components/modals/types'
import { Form } from '@hyper/forms/form'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { FormSelect } from '@hyper/forms/form-select'
import { asDecimal, createSelectOption, formatPriceShort } from '@helpers/utils'
import { parseISODate, toDefaultDateFormat } from '@helpers/date-helper'
import { commonObjectGet } from '@store/actions/generic-actions'
import { ContentLoader } from '@components/content-loader'
import { useAppData } from '@components/hooks/use-app-data'
import { ReceptionBookingDetails } from '@models/reception'
import { GastroCard } from '@models/promotions'
import { ClientDetails } from '@models/clients'
import { isAfter } from 'date-fns'
import { ReservationAddVoucherModalRow } from '@modules/reservations/details/vouchers/modals/add-modal/reservation-add-voucher-modal-row'
import { bookingClientWalletAssign } from '@store/actions/reception-actions-check'

interface FormInputs {
  gastro_card: CustomReactSelectOption | null
}

interface Props extends BaseModalProps {
  booking: ReceptionBookingDetails
}

export const ReservationAddVoucherModal: React.FC<Props> = ({ toggleIsVisible, booking }) => {
  const { urls } = useAppData()
  const { addSuccessMessage } = useNotificationHook()

  const [clientGastroCards, setClientGastroCards] = React.useState<GastroCard[]>([])

  const methods = useForm<FormInputs>({
    defaultValues: { gastro_card: null },
  })

  const selectedVoucher = useWatch({ control: methods.control, name: 'gastro_card' })

  const { isLoading, action: onSubmit } = useFormRequest(
    async (payload: FormInputs) => {
      await bookingClientWalletAssign(booking.id, payload.gastro_card?.context.code, booking.groups[0].id)
      addSuccessMessage('Sukces', 'Voucher został dodany')
      toggleIsVisible()
    },
    methods.setError,
    { nonFieldErrorsAs: 'gastro_card' },
  )

  const { action: fetchClientDetails, isLoading: isFetchingClientDetails } = useApiRequest(async () => {
    const clientDetails = await commonObjectGet<ClientDetails>(`${urls.clients.clients}${booking.client_id}/`)
    setClientGastroCards(clientDetails.gastro_cards)
  })

  const availableGastroCards = React.useMemo(
    () =>
      clientGastroCards.reduce((acc, card) => {
        const isVoucherActive = isAfter(
          parseISODate(booking.date_from) as Date,
          parseISODate(card.active_after) as Date,
        )
        const isVoucherEmpty = asDecimal(card.rest_to_use).lte(0)

        if (!isVoucherEmpty && isVoucherActive) {
          return [...acc, createSelectOption(card.product_name, card.id, false, card)]
        }

        return acc
      }, []),
    [clientGastroCards],
  )

  React.useEffect(() => {
    fetchClientDetails()
  }, [])

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Dodaj voucher</ModalHeader>
      <ModalBody className="pb-0">
        <ContentLoader isLoading={isFetchingClientDetails}>
          <span className="text-secondary font-16 fw-semi-bold">Dodaj voucher do rezerwacji</span>
          <p className="mt-2">
            Dodaj voucher przypisany do osoby zakładającej rezerwację <br /> lub wpisz kod vouchera ręcznie.
          </p>
          <FormSelect
            label="Wybierz voucher:"
            labelSize={4}
            labelProps={{ className: 'px-0' }}
            options={availableGastroCards}
            name="gastro_card"
            selectProps={{
              components: {
                IndicatorSeparator: () => null,
                ValueContainer: ({ children }) => <div className="d-flex px-2 align-items-center">{children}</div>,
                SingleValue: ({ data }) =>
                  (data as CustomReactSelectOption).context?.code || (data as CustomReactSelectOption)?.label,
              },
              formatOptionLabel: OptionLabel,
              isClearable: false,
              isCreatable: true,
              placeholder: 'Wpisz lub wybierz z listy',
            }}
          />
          {selectedVoucher && <ReservationAddVoucherModalRow selectedVoucher={selectedVoucher} />}
        </ContentLoader>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" isSaving={isLoading} label="Dodaj" />
      </ModalFooter>
    </Form>
  )
}

interface OptionLabelProps {
  label: string
  context: GastroCard
}

const OptionLabel = ({ label, context }: OptionLabelProps) => (
  <div className="text-default py-1 px-0">
    <strong>{label}</strong>
    {context && (
      <div className="d-flex justify-content-between font-11">
        <div>
          Ważny do <strong>{toDefaultDateFormat(context.expire_after)}</strong>
        </div>
        <div className="ml-2">
          Dostępne: <strong className="pl-1">{formatPriceShort(context.rest_to_use)}</strong>{' '}
          {context.promotion_amount && `z ${formatPriceShort(context.promotion_amount)}`}
        </div>
      </div>
    )}
  </div>
)
