import * as React from 'react'
import { ClientDetails } from '@models/clients'
import { languageList } from '@models/cms'

interface Props {
  client: ClientDetails
}

export const ClientDetailsModalClientProfilePersonalDataTable: React.FC<Props> = ({ client }) => {
  const languageName = React.useMemo(
    () => languageList.find(row => client.language === row.value)?.label || '',
    [client.language],
  )
  return (
    <table className="table table-sm mb-2">
      <colgroup>
        <col width="40%" />
        <col width="60%" />
      </colgroup>

      <tbody>
        <tr>
          <td>Imię:</td>
          <th className="text-semi-strong">{client.first_name}</th>
        </tr>
        <tr>
          <td>Nazwisko:</td>
          <th className="text-semi-strong">{client.last_name}</th>
        </tr>
        <tr>
          <td>Telefonu:</td>
          <th className="text-semi-strong">{client.phone}</th>
        </tr>
        <tr>
          <td>E-mail:</td>
          <th className="text-semi-strong">{client.email || 'brak'}</th>
        </tr>
        <tr>
          <td>Adres:</td>
          <th className="text-semi-strong">
            {client.profile.street} <br /> {client.profile.postcode} {client.profile.city},{' '}
            {client.profile.country_display}
          </th>
        </tr>
        <tr>
          <td>Język gościa:</td>
          <th className="text-semi-strong">{languageName}</th>
        </tr>
      </tbody>
    </table>
  )
}
