import * as React from 'react'
import { FormInput, FormPlain } from '@hyper/forms'
import { useFormContext } from 'react-hook-form'
import { ClientCreateModalFormInputs } from '@modules/crm/clients/create-modal/index'
import { Col, Row } from 'reactstrap'
import { CustomSwitch } from '@components/custom-switch'
import { GusButton } from '@components/gus-button'
import { CompanyDataFromGus } from '@models/sale'
import { RootState, useAppSelector } from '@store/index'
import { Country } from '@models/dashboard'

export const ClientCreateModalFormInvoice: React.FC = () => {
  const { setValue, watch, register, getValues } = useFormContext<ClientCreateModalFormInputs>()

  const invoice = watch('invoice') || false

  const getNip = () => getValues('invoice_nip')

  const handleGus = (companyData: CompanyDataFromGus, country = 'PL') => {
    setValue('invoice_company', companyData.name)
    setValue('invoice_street', companyData.street_address)
    setValue('invoice_postcode', companyData.postal_code)
    setValue('invoice_city', companyData.city)
    setValue('invoice_country', country)
  }
  const countries = useAppSelector((state: RootState) => state.appState.appData.countries)

  React.useEffect(() => {
    if (!invoice && getValues('invoice_nip')) {
      handleGus(
        {
          city: '',
          name: '',
          postal_code: '',
          street_address: '',
          tax_id: '',
        },
        '',
      )
    }
  }, [invoice])

  return (
    <Row>
      <FormPlain name="invoice">
        <CustomSwitch
          labelClassName="text-strong"
          label="Faktura dla Gościa?"
          register={register}
          inputName="invoice"
        />
      </FormPlain>

      {invoice && (
        <>
          <FormInput label="NIP" colSize={6} name="invoice_nip" />
          <Col md={6}>
            <div className="form-group">
              <label className="d-block">&nbsp;</label>
              <GusButton
                setCompanyData={handleGus}
                getNip={getNip}
                button={<button className="btn btn-primary">Pobierz z GUS</button>}
              />
            </div>
          </Col>

          <FormInput colSize={6} label="Nazwa firmy" name="invoice_company" />

          <FormInput colSize={6} label="Adres" name="invoice_street" />
          <FormInput colSize={6} label="Kod pocztowy" name="invoice_postcode" />
          <FormInput colSize={6} label="Miasto" name="invoice_city" />
          <FormInput colSize={6} name="invoice_country" label="Kraj" type="select">
            {countries.map((country: Country) => (
              <option value={country.id} key={country.id}>
                {country.name}
              </option>
            ))}
          </FormInput>
        </>
      )}
    </Row>
  )
}
