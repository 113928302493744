import * as React from 'react'
import { NavigationTabs, NavigationTabsButton } from '@components/navigation-tabs'
import { ScrollableModalTab, useModalWithScrollableTabs } from '@components/hooks/use-modal-with-scrollable-tabs'

type Tab = 'data'

interface Props {
  dataRef: React.RefObject<HTMLElement>
}

export const CouponItemDetailsTabs: React.FC<Props> = ({ dataRef }) => {
  const [tab, setTab] = React.useState<Tab>('data')

  const tabs: ScrollableModalTab<Tab>[] = [{ ref: dataRef, name: 'data' }]

  const { handleTabClick } = useModalWithScrollableTabs<Tab>({
    modalId: 'gastro-card-details-dialog',
    setTab,
    tabs,
  })

  const buttons: NavigationTabsButton[] = [
    {
      title: 'Dane podstawowe',
      isActive: tab == 'data',
      onClick: handleTabClick('data'),
    },
  ]

  return (
    <div className="modal-details__tabs-wrapper">
      <NavigationTabs buttons={buttons} />
    </div>
  )
}
