import * as React from 'react'
import { useModal } from '@components/modals/use-modal'
import { ButtonWithIcon } from '@components/button-with-icon'

interface CreateCleaningOrderProps {
  resortId: number
}

export const CreateCleaningOrder: React.FC<CreateCleaningOrderProps> = ({ resortId }) => {
  const [handleIsOpen] = useModal('CreateCleaningOrderModal', { resortId })

  return (
    <div className="ml-auto" style={{ marginTop: -5 }}>
      <ButtonWithIcon
        icon="uil-plus"
        text="Dodaj sprzątanie"
        handleClick={handleIsOpen}
        color="green"
        btnClass="btn-tall"
      />
    </div>
  )
}
