import * as React from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { PageTitle } from '@hyper/page-title'
import { RootState, useAppSelector } from '@store/index'
import { NavigationPath } from '@models/routes'
import { Card, CardBody } from 'reactstrap'
import { HousekeepingUserReportFilters } from '@modules/reports/housekeeping-user-report/housekeeping-user-report-filters'
import { endOfMonth, startOfMonth, startOfToday } from 'date-fns'
import { HouseKeepingUserReportFiltersParams } from '@modules/reports/housekeeping-users-report'
import { getUserCleaningOrdersReport } from '@modules/reports/api'
import { HousekeepingUserReportTable } from './housekeeping-user-report-table'
import { useReportsAppData } from '@modules/reports/hooks/use-reports-app-data'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { HouseKeepingCleaningOrdersReport } from '@modules/reports/housekeeping-users-report/models'
import { HousekeepingUserReportStats } from '@modules/reports/housekeeping-user-report/stats'
import { ButtonWithIcon } from '@components/button-with-icon'
import { useModal } from '@components/modals/use-modal'
import { Form } from '@hyper/forms/form'
import { useMultipleResorts } from '@components/hooks/use-multiple-resorts'
import { useForm } from 'react-hook-form'
import { createSelectOption } from '@helpers/utils'

type LocationState = Pick<HouseKeepingUserReportFiltersParams, 'finished_at_after' | 'finished_at_before' | 'resorts'>

interface Params {
  user_id: string
  resort_id: string
}

export const HouseKeepingUserReportView: React.FC = () => {
  const { user_id } = useParams() as unknown as Params
  const location = useLocation()
  const locationState = location.state as LocationState
  const pageTitle = 'Housekeeping - Raport Pracownika'

  const defaultFilters: HouseKeepingUserReportFiltersParams = {
    search: '',
    finished_at_after: locationState?.finished_at_after || startOfMonth(startOfToday()),
    finished_at_before: locationState?.finished_at_before || endOfMonth(startOfToday()),
    resorts: locationState?.resorts?.length ? locationState.resorts : [],
    ordering: '-ordered_at',
    page: 1,
    page_size: 10,
  }

  useMenuActive(NavigationPath.HouseKeepingUserReport)

  const [filters, setFilters] = React.useState<HouseKeepingUserReportFiltersParams>(defaultFilters)
  const [cleaningOrdersReport, setCleaningOrdersReport] = React.useState<HouseKeepingCleaningOrdersReport | null>(null)

  const houseKeepingUsers = useAppSelector((state: RootState) => state.reportsState.appData?.housekeeping_users) || []
  const url = useAppSelector((state: RootState) => state.appState.appData.urls.reports.housekeeping_users_report)

  const { isLoading, action: fetchCleaningOrdersReport } = useApiRequest(async () =>
    setCleaningOrdersReport(
      await getUserCleaningOrdersReport(`${url}${user_id}/`, {
        ...filters,
        resorts: getSelectedResortsIds(filters.resorts),
      }),
    ),
  )

  React.useEffect(() => {
    fetchCleaningOrdersReport()
  }, [filters])

  useReportsAppData()

  const user = houseKeepingUsers.find(row => row.id === parseInt(user_id, 10))

  const [showHousekeepingUserReportModal] = useModal('ReportsHousekeepingUserModal', {
    housekeepingUser: user,
    finished_at_after: filters.finished_at_after,
    finished_at_before: filters.finished_at_before,
    resorts: filters.resorts.length ? filters.resorts : [createSelectOption('Wszystkie', 'all')],
  })

  const methods = useForm<HouseKeepingUserReportFiltersParams>({
    defaultValues: filters,
  })

  const { getResortOptions, getSelectedResortsIds } = useMultipleResorts({
    methods,
    field: 'resorts',
  })
  const resortOptions = React.useMemo(() => getResortOptions({ withAllOption: true }), [])

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: pageTitle, path: NavigationPath.HouseKeepingReport, active: false },
          { label: user?.name || '', path: '', active: true },
        ]}
        title={pageTitle}
      />
      <Card>
        <CardBody>
          <div className="d-flex mb-3 justify-content-between">
            <strong className="text-secondary font-18">
              {user?.name} ({user?.position})
            </strong>
            <Link
              style={{ padding: '.28rem .8rem' }}
              to={NavigationPath.HouseKeepingReport}
              state={{ finished_at_after: filters.finished_at_after, finished_at_before: filters.finished_at_before }}
              className="btn btn-light btn-sm"
            >
              Wróć do listy
            </Link>
          </div>
          <Form
            methods={methods}
            onSubmit={payload => setFilters({ ...filters, ...payload })}
            className="form form-style-filter mb-2"
          >
            <HousekeepingUserReportFilters
              defaultFilters={defaultFilters}
              filters={filters}
              setFilters={setFilters}
              resortOptions={resortOptions}
            />
          </Form>
          {user && (
            <HousekeepingUserReportTable
              user={user}
              isLoading={isLoading}
              cleaningOrders={cleaningOrdersReport?.results || []}
              filters={filters}
              setFilters={setFilters}
            />
          )}
          {cleaningOrdersReport?.aggregation && (
            <HousekeepingUserReportStats aggregation={cleaningOrdersReport?.aggregation} user={user} />
          )}
          <div className="text-right mt-3" onClick={showHousekeepingUserReportModal}>
            <ButtonWithIcon icon="uil-download-alt" color="secondary" text="Pobierz" />
          </div>
        </CardBody>
      </Card>
    </>
  )
}
