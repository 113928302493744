import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { FormInput } from '@hyper/forms'
import { useFormRequest } from '@components/hooks/use-api-request'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { FormProvider, useForm } from 'react-hook-form'
import { BaseModalProps } from '@components/modals/types'
import { FormSelect } from '@hyper/forms/form-select'
import { ReceptionBookingDetails, ReceptionBookingLoadNote } from '@models/reception'
import { CustomReactSelectOption, makeDefaultSelectOption } from '@components/custom-react-select'
import { updateReceptionBookingDetails } from '@store/actions/reception-actions'
import { useAppDispatch } from '@store/index'
import { commonObjectPatch } from '@store/actions/generic-actions'
import { paymentMethodOptions } from '@modules/reservations/details/load-notes/modals/reservation-load-note-add-modal'

interface FormInputs {
  description: string
  price_brutto: string
  source: CustomReactSelectOption | null
}

interface Props extends BaseModalProps {
  note: ReceptionBookingLoadNote
}

export const ReservationLoadNoteEditModal: React.FC<Props> = ({ toggleIsVisible, note }) => {
  const methods = useForm<FormInputs>({
    defaultValues: {
      description: note.description,
      price_brutto: note.price_brutto,
      source: makeDefaultSelectOption(paymentMethodOptions, note.source),
    },
  })
  const { addSuccessMessage } = useNotificationHook()

  const dispatch = useAppDispatch()

  const { isLoading, action: onSubmit } = useFormRequest(async () => {
    const { source, ...payload } = methods.getValues()

    await dispatch(
      updateReceptionBookingDetails(
        await commonObjectPatch<ReceptionBookingDetails>(note.urls.details, {
          ...payload,
          source: source?.value,
        }),
      ),
    )

    addSuccessMessage('Sukces', 'Nota została zmieniona')
    toggleIsVisible()
  }, methods.setError)

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggleIsVisible}>Edycja noty</ModalHeader>
        <ModalBody>
          <Row>
            <FormInput colSize={4} name="price_brutto" type="currency" setValue={methods.setValue} label="Kwota" />
            <FormInput label="Opis dodatkowy" name="description" type="textarea" />
            <FormSelect name="source" label="Forma płatności" options={paymentMethodOptions} />
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={toggleIsVisible}>
            Zamknij
          </Button>

          <SaveButton role="submit" className="btn btn-green" label="Zapisz" isSaving={isLoading} />
        </ModalFooter>
      </form>
    </FormProvider>
  )
}
