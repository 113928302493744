import * as React from 'react'
import { FormCheckbox } from '@hyper/forms/form-checkbox'
import { TechnicalOrderFormOptionsEnterTimeRange } from '@modules/technical-orders/technical-order-modal/form-enter-time-range'
import { useFormContext, useWatch } from 'react-hook-form'
import { IconWithText } from '@components/icon-with-text'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'

interface Props {
  wrapperClassName?: string
  showIsSupervisorAcceptanceRequired: boolean
}

export const TechnicalOrderFormOptions: React.FC<Props> = ({
  showIsSupervisorAcceptanceRequired,
  wrapperClassName,
}) => {
  const { control, setValue } = useFormContext()
  const technicalOrderEnterWhereGuestAbsenceRange = useWatch({
    control,
    name: 'technicalOrderEnterWhereGuestAbsenceRange',
  })

  useDidUpdateEffect(() => {
    if (!technicalOrderEnterWhereGuestAbsenceRange) {
      setValue('technical_order_enter_where_guest_absence_range', [])
    }
  }, [technicalOrderEnterWhereGuestAbsenceRange])

  return (
    <div className={wrapperClassName}>
      <FormCheckbox
        name="priority"
        label={
          <IconWithText
            text="Zadanie priorytetowe"
            icon="uil-rocket"
            direction="right"
            iconSize="font-18 ml-1 text-danger"
          />
        }
        className="mb-2"
      />
      {showIsSupervisorAcceptanceRequired && (
        <FormCheckbox
          name="is_supervisor_acceptance_required"
          uncheckedDefaultValue={false}
          label={
            <IconWithText
              text="Usterka wymaga akceptacji przełożonego"
              icon="uil-user-check"
              direction="right"
              iconSize="font-18 ml-1 text-green-dark"
            />
          }
          className="mb-2"
        />
      )}
      <FormCheckbox
        name="enter_where_guest_absence"
        uncheckedDefaultValue={false}
        label={
          <IconWithText
            text="Wejście pod nieobecność gości"
            icon="uil-signout"
            direction="right"
            iconSize="font-18 ml-1 text-danger"
          />
        }
        className="mb-2"
      />
      <FormCheckbox
        name="technicalOrderEnterWhereGuestAbsenceRange"
        label={
          <IconWithText
            text="Wskaż przedział czasowy wizyty"
            icon="uil-clock-eight"
            direction="right"
            iconSize="font-18 ml-1 text-danger"
          />
        }
        className="mb-2"
      />
      {technicalOrderEnterWhereGuestAbsenceRange && <TechnicalOrderFormOptionsEnterTimeRange />}
    </div>
  )
}
