import * as React from 'react'
import { PageTitle } from '@hyper/page-title'
import { Card, CardBody } from 'reactstrap'
import { useDocumentTitle } from '@helpers/utils'
import { SellerListFilter, SellerListFilterParams } from '@modules/seller/list/filter'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useApiRequest } from '@components/hooks/use-api-request'
import { SellerListTable } from '@modules/seller/list/table'
import { getSellerList } from '@store/actions/crm-actions'
import { totalSizeSelector } from '@store/selectors/dashboard'
import { useDebounce } from 'rooks'
import { SellerWebSocketHandler } from '@modules/seller/web-socket-handler'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'

const defaultFilters: SellerListFilterParams = {
  search: '',
  department: undefined,
  ordering: '-created',
  page: 1,
  page_size: 10,
}

export const SellerList: React.FC = () => {
  const totalSize = useAppSelector(totalSizeSelector)

  const [filters, setFilters] = React.useState<SellerListFilterParams>({
    ...defaultFilters,
  })
  const dispatch = useAppDispatch()
  const sellers = useAppSelector((state: RootState) => state.crmState.sellers)
  useMenuActive(NavigationPath.SellerList)

  const { isLoading, action: fetchSellers } = useApiRequest(async filters => await dispatch(getSellerList(filters)))

  const fetchSellersDebounced = React.useCallback(useDebounce(fetchSellers, 300), [])
  React.useEffect(() => {
    fetchSellersDebounced(filters)
  }, [filters])

  const pageTitle = 'Lista Sprzedawców'
  const pageTitleWithSize = `${pageTitle} (${totalSize})`
  useDocumentTitle(pageTitleWithSize)

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitleWithSize} />
      <SellerWebSocketHandler />
      <Card>
        <CardBody>
          <SellerListFilter defaultFilters={defaultFilters} filters={filters} setFilters={setFilters} />
          <SellerListTable isLoading={isLoading} sellers={sellers} filters={filters} setFilters={setFilters} />
        </CardBody>
      </Card>
    </>
  )
}
