import * as React from 'react'
import { useModal } from '@components/modals/use-modal'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from '@store/index'
import { setReceptionBookingDetails } from '@store/slices/reception-slice'

export const ReservationCreateView: React.FC = () => {
  const dispatch = useAppDispatch()
  const { token } = useParams<'token'>()
  const [handleOpen] = useModal(
    'ReservationCreateModal',
    { token },
    {
      persist: true,
      onClose: React.useCallback(() => {
        dispatch(setReceptionBookingDetails(undefined))
      }, []),
    },
  )

  React.useEffect(() => {
    handleOpen()
  }, [])

  return null
}
