import * as React from 'react'
import { ReceptionBookingDetails, ReceptionReminder } from '@models/reception'
import { useForm } from 'react-hook-form'
import { ReceptionBookingCheck } from '@modules/reception/common/reception-booking-check'
import { newUserPredicator, ReceptionCheckReminders } from '@modules/reception/common/reception-check-reminders'
import { Col, ModalBody, Row } from 'reactstrap'
import { ReceptionBookingCheckBillDetails } from '@modules/reception/common/reception-booking-check-bill-details'
import { ReceptionBookingSettlementDeposit } from '@modules/reception/checkout/step-settlement/step-settlement-deposit'
import { ReceptionBookingSettlementSummary } from '@modules/reception/checkout/step-settlement/step-settlement-summary'
import { asDecimal } from '@helpers/utils'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useModal } from '@components/modals/use-modal'
import { ButtonWithIcon } from '@components/button-with-icon'
import { IconWithText } from '@components/icon-with-text'
import {
  CheckOutPayload,
  useReceptionCheckOut,
} from '@modules/reception/checkout/step-settlement/use-reception-check-out'
import { UserPermission } from '@models/dashboard'
import { ReceptionBookingCheckOutStepSettlementInvoiceNotes } from '@modules/reception/checkout/step-settlement/step-settlement-invoice-notes'
import { Form } from '@hyper/forms/form'
import { ReceptionBookingSettlementDepositReturns } from '@modules/reception/checkout/step-settlement/step-settlement-deposit-returns'
import { ReceptionBookingCheckOutStepSettlementPayment } from '@modules/reception/checkout/step-settlement/step-settlement-payment'

interface ReceptionBookingCheckOutStepSettlementProps {
  nextStep: () => void
  previousStep: () => void
  booking: ReceptionBookingDetails
}

interface FormInputs {
  check_card: boolean
  language: string
  invoice_note: string
}

const reminders: ReceptionReminder[] = [
  {
    id: 'check_card',
    isClosable: true,
    children: (
      <>
        Przy <strong>płatności kartą</strong> wydrukowane potwierdzenie płatności <strong>wepnij do rozliczenia</strong>{' '}
        Gościa.
      </>
    ),
  },
]

export const ReceptionBookingCheckOutStepSettlement: React.FC<ReceptionBookingCheckOutStepSettlementProps> = ({
  previousStep,
  nextStep,
  booking,
}) => {
  const user = useAuthenticatedUser()
  const methods = useForm<FormInputs>({
    defaultValues: {
      check_card: newUserPredicator(user),
      language: 'pl',
      invoice_note: '',
    },
  })
  const {
    depositToReturn,
    totalDeposit,
    restToPay,
    restToPayWithoutClimatic,
    checkOut,
    isCheckingOut,
    isPaid,
    isOnlyClimaticTaxLeft,
    restToPaymentWithDeposit,
  } = useReceptionCheckOut(booking)
  const [isNextStepDisabled, setIsNextStepDisabled] = React.useState(true)

  const onSubmit = async (payload: CheckOutPayload) => {
    const success = await checkOut(payload)
    if (success) {
      nextStep()
    }
  }

  React.useEffect(() => {
    if (booking.status === 'close' && asDecimal(booking.deposit_to_return).lessThanOrEqualTo(asDecimal(0))) {
      nextStep()
    }
  }, [booking.status, booking.deposit_to_return])

  const [showTransferDepositModal] = useModal('TransferDepositModal', { booking })

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      role="step-settlement"
      className="reception__booking-check-out__step-settlement"
    >
      <ModalBody className="pb-0">
        <h4>3. Rozliczenie rachunku</h4>
        <ReceptionCheckReminders reminders={reminders} required={!newUserPredicator(user)} />

        <div className="mb-2 pt-2">
          <Row>
            <Col md={6}>
              <ReceptionBookingCheckBillDetails
                booking={booking}
                readOnly={true}
                title="Rachunek za pobyt:"
                isOpen={false}
                restToPay={restToPay}
              />
              <ReceptionBookingSettlementDeposit booking={booking} depositToReturn={depositToReturn} />
              {booking.can_transfer_deposit &&
                user.hasPerm(UserPermission.ReceptionBookingCanTransferDeposit) &&
                (booking.deposit_move_to ? (
                  <IconWithText
                    icon="uil-money-insert font-15"
                    text={`Kaucja przeniesiona do rezerwacji nr. ${booking.deposit_move_to.reservation_number}`}
                    textClass="font-12"
                    wrapperClassNames="mt-2 text-success"
                  />
                ) : (
                  <ButtonWithIcon
                    color="primary"
                    icon="uil-money-insert font-16"
                    text="Przenieś niewykorzystaną kaucję"
                    btnClass="font-12 mt-2 text-white"
                    handleClick={showTransferDepositModal}
                  />
                ))}
              <ReceptionBookingSettlementSummary
                restToPay={restToPay}
                restToPayWithoutClimatic={restToPayWithoutClimatic}
                totalDeposit={totalDeposit}
                moneyToReturn={booking.has_returnable_deposit}
                depositToReturn={depositToReturn}
              />
            </Col>
            <Col md={6}>
              <ReceptionBookingCheckOutStepSettlementPayment
                booking={booking}
                restToPay={restToPaymentWithDeposit}
                isOnlyClimaticTaxLeft={isOnlyClimaticTaxLeft}
              />
              <ReceptionBookingSettlementDepositReturns
                booking={booking}
                setIsNextStepDisabled={setIsNextStepDisabled}
              />
              <ReceptionBookingCheckOutStepSettlementInvoiceNotes />
            </Col>
          </Row>
        </div>
      </ModalBody>
      <ReceptionBookingCheck
        isDisabled={isNextStepDisabled || !isPaid}
        isSaving={isCheckingOut}
        nextLabel="Następny krok"
        previousStep={previousStep}
      />
    </Form>
  )
}
