import * as React from 'react'
import { FormSelect } from '@hyper/forms/form-select'
import { HousekeepingCompanyDetails } from '@modules/housekeeping/models'
import { createSelectOption } from '@helpers/utils'
import { useHousekeepingCleaningCompanies } from '@modules/housekeeping/users/use-housekeeping-cleaning-companies'

interface Props {
  resortId: number
}

export const HousekeepingOrderRowSelectCompany: React.FC<Props> = ({ resortId }) => {
  const companies = useHousekeepingCleaningCompanies(resortId)

  const options = companies.map((company: HousekeepingCompanyDetails) => createSelectOption(company.name, company.id))

  return (
    <FormSelect
      options={options}
      name="company"
      formPlainProps={{ formGroupClassName: 'mb-0', colClassName: 'px-0' }}
    />
  )
}
