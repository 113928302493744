import * as React from 'react'
import { Card, CardBody } from 'reactstrap'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { ServiceBandFilters, ServiceBandFiltersParams } from '@modules/bands/service-bands/filters'
import { useAppDispatch, useAppSelector } from '@store/index'
import { getServiceBands } from '@store/actions/service-band-actions'
import { ServiceBandTable } from '@modules/bands/service-bands/table'
import { PageTitleWithSize } from '@hyper/page-title-with-size'
import { serviceBandsSelector } from '@store/slices/service-band-slice'
import { useTableFilters } from '@components/table/use-table-filters'

const defaultFilters: ServiceBandFiltersParams = {
  ordering: '-name',
  page: 1,
  page_size: 10,
  search: '',
  resort: null,
  is_removed: false,
}

export const ServiceBandView = (): JSX.Element => {
  const dispatch = useAppDispatch()

  const serviceBands = useAppSelector(serviceBandsSelector)

  useMenuActive(NavigationPath.ServiceBands)

  const { filters, setFilters, isLoading } = useTableFilters<ServiceBandFiltersParams>({
    defaultFilters,
    action: async filters => await dispatch(getServiceBands(filters)),
  })

  return (
    <>
      <PageTitleWithSize title="Opaski testowe" />
      <Card>
        <CardBody>
          <ServiceBandFilters filters={filters} setFilters={setFilters} defaultFilters={defaultFilters} />
          <ServiceBandTable
            serviceBands={serviceBands}
            isLoading={isLoading}
            filters={filters}
            setFilters={setFilters}
          />
        </CardBody>
      </Card>
    </>
  )
}
