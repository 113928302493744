import * as React from 'react'
import { ModalHeader } from 'reactstrap'
import {
  ReceptionBookingCheckStep,
  ReceptionBookingCheckSteps,
} from '@modules/reception/common/reception-booking-check-steps'
import { StepReservationInfo } from '@modules/reservations/create/step-reservation-info'
import { useAppDispatch, useAppSelector } from '@store/index'
import StepClientInfo from '@modules/reservations/create/step-client-info'
import StepImprovements from '@modules/reservations/create/step-improvements'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { AccommodationType, Resort } from '@models/booking'
import { parseISO } from 'date-fns'
import { createSelectOption } from '@helpers/utils'
import {
  LocalSelectionType,
  ReservationCreateFormInputs,
  ReservationCreateModalProps,
} from '@modules/reservations/create/models'
import { CalculateStepReservationInfoCalculate } from '@modules/reservations/create/step-reservation-info/calculate'
import { getReservationCreateDetails, getResortDetails } from '@store/actions/reception-actions'
import StepFeeding from '@modules/reservations/create/step-feeding'
import { receptionBookingDetailsSelector } from '@store/selectors/reception'
import StepInsurance from '@modules/reservations/create/step-insurance'
import StepSummary from '@modules/reservations/create/step-summary'
import { useStepsWithFeeding } from '@modules/reception/checkin/use-steps-with-feeding'
import { formatDate } from '@helpers/date-helper'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectPost } from '@store/actions/generic-actions'
import { setReceptionBookingDetails } from '@store/slices/reception-slice'
import { ClientUser } from '@models/clients'
import { useBookingFeedingAvailability } from '@components/hooks/use-booking-feeding-availability'
import { ReservationSidebarSummaryBox } from '@modules/reservations/create/sidebar-summary-box/sidebar-summary-box'
import { useModal } from '@components/modals/use-modal'
import { ContentLoader } from '@components/content-loader'

const steps: ReceptionBookingCheckStep[] = [
  { step: 1, description: <>Informacje o rezerwacji</> },
  { step: 2, description: <>Informacje o rezerwującym</> },
  { step: 3, description: <>Wybór ulepszeń</> },
  { step: 4, description: <>Wybór wyżywienia</> },
  { step: 5, description: <>Opcja rezygnacji</> },
  { step: 6, description: <>Podsumowanie rezerwacji</> },
]

export const ReservationCreateModal: React.FC<ReservationCreateModalProps> = ({ toggleIsVisible, token }) => {
  const [step, setStep] = React.useState(1)
  const previousStep = () => setStep(Math.max(step - 1, 1))
  const doublePreviousStep = () => setStep(Math.max(step - 2, 1))
  const nextStep = () => setStep(step + 1)
  const doubleNextStep = () => setStep(step + 2)
  const dispatch = useAppDispatch()
  const [client, setClient] = React.useState<ClientUser | undefined>(undefined)
  const reservation = useAppSelector(receptionBookingDetailsSelector)

  const resorts = useAuthenticatedUser().resorts

  const { action: cancelUnfinishedReservation, isLoading } = useApiRequest(async () => {
    await commonObjectPost(reservation.urls.cancel_unfinished)
  })

  const handleReturnToFirstStep = async () => {
    setStep(1)
    setClient(undefined)

    await cancelUnfinishedReservation()
    dispatch(setReceptionBookingDetails(undefined))
    methods.reset()
  }

  const handleClose = async () => {
    if (reservation?.urls?.cancel_unfinished) {
      await cancelUnfinishedReservation()
    }

    toggleIsVisible()
  }

  const [showDataResetModal] = useModal('ReservationCreateDataResetModal', { onSubmit: handleReturnToFirstStep })

  const methods = useForm<ReservationCreateFormInputs>({
    defaultValues: {
      accommodation_type: null,
      localSelection: null,
      apartment: null,
      resort: null,
      date_from: null,
      date_to: null,
      send_notification: true,

      email: null,
      name: null,
      phone: null,
      street: null,
      postcode: null,
      city: null,
      invoice_nip: '',
      invoice_company: '',
      invoice_street: '',
      invoice_postcode: '',
      invoice_city: '',
      invoice_type: 'company',
    },
  })

  const getSelectedResort = (resortId: number): Resort | undefined => resorts.find(resort => resortId === resort.id)
  const getSelectedAccommodation = (
    accommodationId: number,
    resort: Resort | undefined,
  ): AccommodationType | undefined => resort && resort.accommodation_types.find(el => el.id === accommodationId)

  const getReservationFromToken = async (token: string) => {
    const data = await dispatch(getReservationCreateDetails(token))
    if (data) {
      const resort = getSelectedResort(data.resort_id)
      const accommodation = getSelectedAccommodation(data.apartment.accommodation_type, resort)

      methods.setValue(
        'accommodation_type',
        accommodation ? createSelectOption(accommodation.name, accommodation.id) : null,
      )
      methods.setValue('resort', resort ? createSelectOption<string, number>(resort.name, resort.id) : null)
      methods.setValue(
        'localSelection',
        data.selected_apartment
          ? createSelectOption<string, string>('Płatny wybór lokalu', LocalSelectionType.PAYABLE)
          : null,
      )
      methods.setValue(
        'apartment',
        data.selected_apartment ? createSelectOption(data.apartment.name, data.apartment.id) : null,
      )
      methods.setValue('date_from', parseISO(data.date_from))
      methods.setValue('date_to', parseISO(data.date_to))

      methods.setValue('email', data.email)
      methods.setValue('name', data.name)
      methods.setValue('phone', data.phone)
      methods.setValue('street', data.street)
      methods.setValue('postcode', data.postcode)
      methods.setValue('city', data.city)
      methods.setValue('invoice_nip', data.invoice_nip)
      methods.setValue('invoice_company', data.invoice_company)
      methods.setValue('invoice_street', data.invoice_street)
      methods.setValue('invoice_postcode', data.invoice_postcode)
      methods.setValue('invoice_city', data.invoice_city)
      setStep(2)
    }
  }

  React.useEffect(() => {
    if (token) {
      getReservationFromToken(token)
    }
  }, [token])

  const { isAvailable: hasFeedingAvailable } = useBookingFeedingAvailability(step === 2 ? reservation : undefined)

  const { dynamicSteps, getDynamicStep } = useStepsWithFeeding(steps, hasFeedingAvailable, 4)

  const [resort, date_from, date_to, accommodation] = useWatch({
    control: methods.control,
    name: ['resort', 'date_from', 'date_to', 'accommodation_type'],
  })

  const fetchDetails = async () => {
    if (resort?.value && date_from && date_to && accommodation?.value) {
      await dispatch(getResortDetails(resort.value, accommodation.value, formatDate(date_from), formatDate(date_to)))
    }
  }

  React.useEffect(() => {
    fetchDetails()
  }, [resort?.value, date_from, date_to, accommodation?.value])

  const handleStepClick = (newStep: number) => {
    if (step === 1) return
    if (newStep === 1) {
      showDataResetModal()
    } else {
      setStep(newStep)
    }
  }

  const guestsAmount = reservation?.guests ? reservation.guests.length : 0

  return (
    <FormProvider {...methods}>
      <ContentLoader isLoading={isLoading}>
        <CalculateStepReservationInfoCalculate client={client} />
        <ModalHeader toggle={handleClose}>Dodaj nową rezerwację</ModalHeader>
        <ReceptionBookingCheckSteps
          step={step}
          steps={dynamicSteps}
          onStepClick={handleStepClick}
          clickableSteps={[1]}
        />
        <ReservationSidebarSummaryBox isVisible={step > 1} />
        {step === 1 && (
          <StepReservationInfo nextStep={nextStep} resorts={resorts} setClient={setClient} client={client} />
        )}
        {step === 2 && (
          <StepClientInfo nextStep={nextStep} previousStep={showDataResetModal} setClient={setClient} client={client} />
        )}
        {step === 3 && (
          <StepImprovements nextStep={guestsAmount ? nextStep : doubleNextStep} previousStep={previousStep} />
        )}
        {step === 4 && hasFeedingAvailable && (
          <StepFeeding reservation={reservation} nextStep={nextStep} previousStep={previousStep} />
        )}
        {step === getDynamicStep(5) && (
          <StepInsurance
            stepNumber={getDynamicStep(5)}
            nextStep={nextStep}
            previousStep={guestsAmount ? previousStep : doublePreviousStep}
          />
        )}
        {step === getDynamicStep(6) && (
          <StepSummary stepNumber={getDynamicStep(6)} previousStep={previousStep} closeModal={toggleIsVisible} />
        )}
      </ContentLoader>
    </FormProvider>
  )
}
