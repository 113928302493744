import * as React from 'react'
import Table, { TableHeaderValue } from '@components/table/table'
import { CallCenterUser, User } from '@models/account'
import { CallCenterUsersListTableRow } from '@modules/account/call-center-users/list/table-row'
import { CallCenterUsersTableFilters } from '@modules/account/call-center-users/list/index'

const headerValues: TableHeaderValue<User>[] = [
  { title: 'Imię i nazwisko', sortField: 'last_name' },
  { title: 'Login', sortField: 'email' },
  { title: 'Grupy' },
  { title: 'Telefon' },
  { title: 'Aktywny?' },
  { title: 'Ostatnie logowanie', sortField: 'last_login' },
  { title: '' },
]

interface Props {
  users: CallCenterUser[]
  isLoading: boolean
  filters: CallCenterUsersTableFilters
  setFilters: (filters: CallCenterUsersTableFilters) => void
}

export const CallCenterUsersListTable: React.FC<Props> = ({ isLoading, users, filters, setFilters }) => (
  <Table
    className="table-hover w-100"
    headerValues={headerValues}
    isLoading={isLoading}
    filters={filters}
    setFilters={setFilters}
  >
    {users.map(user => (
      <CallCenterUsersListTableRow key={user.id} user={user} />
    ))}
  </Table>
)
