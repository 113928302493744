import * as React from 'react'
import { BookingSmartWatchAssignment, ReceptionBookingDetails } from '@models/reception'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { BaseModalProps } from '@components/modals/types'
import { useAppDispatch } from '@store/index'
import { SaveButton } from '@hyper/button'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { setReceptionBookingDetails } from '@store/slices/reception-slice'

interface Props extends BaseModalProps {
  smartWatchAssignment: BookingSmartWatchAssignment
  refreshResortDetails: () => void
}

export const SmartWatchAddModalRemove = ({
  toggleIsVisible,
  smartWatchAssignment,
  refreshResortDetails,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()

  const { isLoading: isRemoveLoading, action: handleRemove } = useApiRequest(async () => {
    if (smartWatchAssignment) {
      dispatch(
        setReceptionBookingDetails(
          await commonObjectDelete<ReceptionBookingDetails>(smartWatchAssignment.urls.details),
        ),
      )
    }
    refreshResortDetails()
    toggleIsVisible()
    addSuccessNotification('Przypisanie zostało skasowane!')
  })

  return (
    <SaveButton
      isSaving={isRemoveLoading}
      className="btn btn-outline-danger"
      onClick={handleRemove}
      label="Skasuj przypisanie"
      labelSaving="Kasowanie"
    />
  )
}
