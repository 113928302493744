import * as React from 'react'
import { FormInput } from '@hyper/forms'
import { Row } from 'reactstrap'
import { clientKindOptions } from '@modules/crm/clients/details-modal/base-data/form'
import { FormSelect } from '@hyper/forms/form-select'

export const ClientCreateModalFormBase: React.FC = () => (
  <>
    <Row>
      <FormSelect options={clientKindOptions} name="client_kind" label="Rodzaj klienta" />
    </Row>
    <Row>
      <FormInput colSize={6} label="Imię" name="first_name" />
      <FormInput colSize={6} label="Nazwisko" name="last_name" />
      <FormInput colSize={6} label="Numer telefonu" name="phone" />
      <FormInput colSize={6} label="Adres email" name="email" />
    </Row>
  </>
)
