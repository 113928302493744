import * as React from 'react'
import Table from '@components/table/table'
import { RodoAgreementsAcquiringPointsFiltersFormInputs } from '@modules/crm/rodo-agreements/point-of-acquirng'
import { RodoAgreementsAcquiringPointsTableRow } from '@modules/crm/rodo-agreements/point-of-acquirng/table/rodo-agreements-acquiring-points-table-row'
import { RodoAcquiringPoint } from '@modules/crm/rodo-agreements/models'

const HeaderValues = [
  { title: 'Formularz', sortField: 'name' },
  { title: 'Status', sortField: 'status' },
  { title: 'Liczba zgód', sortField: 'agreements_count' },
  { title: '' },
]

interface Props {
  points: RodoAcquiringPoint[]
  filters: RodoAgreementsAcquiringPointsFiltersFormInputs
  setFilters: (filters: RodoAgreementsAcquiringPointsFiltersFormInputs) => void
  isLoading: boolean
}

export const RodoAgreementsAcquiringPointsTable = ({ points, setFilters, filters, isLoading }: Props): JSX.Element => (
  <Table filters={filters} setFilters={setFilters} headerValues={HeaderValues} isLoading={isLoading}>
    {points.map(row => (
      <RodoAgreementsAcquiringPointsTableRow key={row.id} acquiringPoint={row} />
    ))}
  </Table>
)
