import * as React from 'react'
import { SoldProductsReport } from '@store/actions/report-actions'
import { formatPrice } from '@helpers/utils'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { ReceptionSellersTableFooter } from '@modules/reports/reception-sellers/reception-sellers-table-footer'

interface Props {
  isLoading: boolean
  soldProducts: SoldProductsReport[]
}

const headerValues: TableHeaderValue[] = [
  {
    title: 'Produkty',
  },
  {
    title: 'Ilość jedn.',
  },
  {
    title: 'Wartość brutto',
  },
]

export const ReceptionSellersTable: React.FC<Props> = ({ isLoading, soldProducts }) => {
  const [filters, setFilters] = React.useState<TableFilters>({
    page_size: 9999,
    page: 1,
    ordering: 'accounting_number',
    search: '',
  })

  return (
    <Table
      className="table-hover w-100"
      headerValues={headerValues}
      isLoading={isLoading}
      footer={<ReceptionSellersTableFooter soldProducts={soldProducts} />}
      filters={filters}
      setFilters={setFilters}
    >
      {soldProducts.map(item => (
        <Table.Row key={item.accounting_name}>
          <Table.Cell>{item.accounting_name}</Table.Cell>
          <Table.Cell style={{ width: 120 }} className="text-right">
            {item.sum_quantity}
          </Table.Cell>
          <Table.Cell style={{ width: 145 }} className="text-right">
            {formatPrice(item.sum_total_price_brutto)}
          </Table.Cell>
        </Table.Row>
      ))}
    </Table>
  )
}
