import axios from 'axios'
import {
  CmsAccommodationTypeDetails,
  CmsImprovement,
  CmsResortDetails,
  EmailTemplateDetails,
} from '@modules/cms/models'
import { multiPartHeaders } from '@api/base'
import { CmsReservationAppResortMapFormInputs } from '@modules/cms/reservation-app/resort-modal/maps/modal'
import { CmsAssetFormInputs } from '@modules/cms/assets/list/modal'
import { Asset } from '@models/cms-asset'

const createAssetPayload = (payload: CmsAssetFormInputs, file: File | undefined) => {
  const formData = new FormData()
  if (file) {
    formData.append('file', file)
  }
  formData.append('name', payload.name)
  formData.append('key', payload.key)
  formData.append('category', String(payload.category))
  return formData
}

export const createAsset = async (url: string, payload: CmsAssetFormInputs, file: File | undefined): Promise<Asset> => {
  const { data } = await axios.post(url, createAssetPayload(payload, file), {
    headers: multiPartHeaders,
  })

  return data
}

export const updateAsset = async (url: string, payload: CmsAssetFormInputs, file: File | undefined): Promise<Asset> => {
  const { data } = await axios.patch(url, createAssetPayload(payload, file), {
    headers: multiPartHeaders,
  })

  return data
}
export const updateResortMapSector = async (
  url,
  map: File | undefined,
  payload: CmsReservationAppResortMapFormInputs,
): Promise<CmsResortDetails> => {
  const formData = new FormData()
  if (map) {
    formData.append('map', map)
  }
  formData.append('kind', 'new')

  Object.entries(payload).map(([key, value]) => {
    formData.append(key, value)
  })

  const { data } = await axios.patch(url, formData, {
    headers: multiPartHeaders,
  })

  return data
}

export const createResortMapSector = async (
  url,
  map: File | undefined,
  payload: CmsReservationAppResortMapFormInputs,
): Promise<CmsResortDetails> => {
  const formData = new FormData()
  if (map) {
    formData.append('map', map)
  }
  formData.append('kind', 'new')

  Object.entries(payload).map(([key, value]) => {
    formData.append(key, value)
  })

  const { data } = await axios.post(url, formData, {
    headers: multiPartHeaders,
  })

  return data
}

export const uploadCmsImage = async (url, file, id): Promise<CmsAccommodationTypeDetails | CmsResortDetails> => {
  const formData = new FormData()
  formData.append('image', file)
  formData.append('resort', id)
  formData.append('accommodation_type', id)

  const { data } = await axios.post(url, formData, {
    headers: multiPartHeaders,
  })

  return data
}
export const updateCmsResortMap = async (url: string, resort_map: File | undefined): Promise<CmsResortDetails> => {
  const formData = new FormData()

  if (resort_map) {
    formData.append('resort_map', resort_map)
  }

  const { data } = await axios.patch(url, formData, {
    headers: multiPartHeaders,
  })

  return data
}

const buildAttractionPayload = payload => {
  const formData = new FormData()
  if (payload.image) {
    formData.append('image', payload.image)
  }
  formData.append('title', payload.title)
  formData.append('subtitle', payload.subtitle)
  if (payload.resort) {
    formData.append('resort', payload.resort)
  }

  return formData
}

export const createAttraction = async (url, payload): Promise<CmsResortDetails> => {
  const { data } = await axios.post(url, buildAttractionPayload(payload), {
    headers: multiPartHeaders,
  })

  return data
}

export const updateAttraction = async (url, payload): Promise<CmsResortDetails> => {
  const { data } = await axios.patch(url, buildAttractionPayload(payload), {
    headers: multiPartHeaders,
  })

  return data
}

export const updateImprovement = async (url, payload): Promise<CmsImprovement> => {
  const formData = new FormData()
  if (payload.image) {
    formData.append('image', payload.image)
  }
  if (payload.group_id) {
    formData.append('group', payload.group_id)
  }

  formData.append('name', payload.name)
  formData.append('description', payload.description)

  const { data } = await axios.patch(url, formData, {
    headers: multiPartHeaders,
  })

  return data
}

export const uploadEmailTemplateAttachment = async (url, file, email_template): Promise<EmailTemplateDetails> => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('email_template', email_template)

  const { data } = await axios.post(url, formData, {
    headers: multiPartHeaders,
  })

  return data
}
