import * as React from 'react'
import { createSelectOption } from '@helpers/utils'
import { HousekeepingOrderTableHeaderStatus } from '@modules/housekeeping/common/table-headers/table-header-status'

const HousekeepingBeddingOrderStatusOptions = [
  ['PENDING', 'zlecone'],
  ['FINISHED', 'zakończony'],
]

const selectOptions = HousekeepingBeddingOrderStatusOptions.map(([key, name]) => createSelectOption(name, key))

export const HousekeepingBeddingOrderTableHeaderBeddingStatus = (): JSX.Element => (
  <HousekeepingOrderTableHeaderStatus name="beddingStatus" options={selectOptions} placeholder="Status pościelowego" />
)
