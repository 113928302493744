import * as React from 'react'
import { ApplicationImage } from '@modules/housekeeping/models'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { TopBarLoading } from '@components/topbar-loading'
import { useApiRequest } from '@components/hooks/use-api-request'

interface ApplicationImageUploaderImagesProps {
  images: ApplicationImage[]
  setImages: (images: ApplicationImage[]) => void
}

export const ApplicationImageUploaderImages: React.FC<ApplicationImageUploaderImagesProps> = ({
  images,
  setImages,
}) => {
  const { isLoading, action: handleRemoveImage } = useApiRequest(async (image: ApplicationImage) => {
    await commonObjectDelete(image.urls.details)
    setImages(images.filter(i => i.id !== image.id))
  })

  return (
    <div className="mt-2">
      {isLoading && <TopBarLoading />}

      {images.map(image => (
        <div className="card mb-1 mb-0 shadow-none border" key={image.id}>
          <div className="p-2">
            <div className="d-flex justify-content-between">
              <img style={{ maxWidth: 150 }} src={image.image} className="img-thumbnail" alt="" />
              <i className="uil cursor-pointer uil-multiply" onClick={() => handleRemoveImage(image)} />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
