import * as React from 'react'
import { useAppDispatch, useAppSelector } from '@store/index'
import { ProductsAppData } from '@models/products'
import { getProductsAppData } from '@store/actions/products-actions'
import { selectProductsAppData, setProductsAppData } from '@store/slices/products-slice'

export const useProductsAppData = (): ProductsAppData => {
  const productsAppData = useAppSelector(selectProductsAppData)
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (productsAppData.status === 'unknown') {
      dispatch(getProductsAppData())
      dispatch(setProductsAppData({ ...productsAppData, status: 'loading' }))
    }
  }, [productsAppData.status])

  return productsAppData
}
