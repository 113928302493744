import * as React from 'react'
import { SubscriptionDetailsInformationRow } from '@modules/subscription/details/information/row'
import { Card, CardBody } from 'reactstrap'
import { getStatusClassIcon } from '@modules/promotions/gastro-cards/utils'
import { useModal } from '@components/modals/use-modal'
import { ProductCardTitle } from '@components/products/product-card-title'
import { BadgeWithIcon } from '@components/badges/BadgeWithIcon'
import { SubscriptionContractDetails } from '@modules/subscription-contract/models'
import { SubscriptionContractDetailsActions } from '@modules/subscription-contract/details/information/actions'
import { formatPrice } from '@helpers/utils'
import { toDefaultDateFormat, toDefaultDateTimeFormat } from '@helpers/date-helper'
import { RootState, useAppSelector } from '@store/index'
import { PaymentCard } from '@components/payment-card-details-modal'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { PackageDetailsRow } from '@modules/package/details/information/row'

interface Props {
  subscriptionDetails: SubscriptionContractDetails
}

export const SubscriptionContractDetailsInformationBase = React.forwardRef<HTMLElement, Props>(
  ({ subscriptionDetails }, ref) => {
    const sources = useAppSelector((state: RootState) => state.reportsState.appData?.sources) || []
    const user = useAuthenticatedUser()

    const [badgeClass, badgeIcon] = React.useMemo(
      () => getStatusClassIcon(subscriptionDetails.status),
      [subscriptionDetails.status],
    )

    const [handleEdit] = useModal('SubscriptionContractCreateModal', {
      subscriptionDetails,
    })

    const sourceName = React.useMemo(
      () => sources.find(row => row.id === subscriptionDetails.source_marketing)?.name ?? 'Brak',
      [sources, subscriptionDetails.source_marketing],
    )

    const paymentCard = React.useMemo(
      () =>
        subscriptionDetails.payment_cards.find(
          (card: PaymentCard) => card.id === subscriptionDetails.main_payment_card_id,
        ),
      [subscriptionDetails.payment_cards, subscriptionDetails.main_payment_card_id],
    )

    const [showPaymentCardDetailsModal] = useModal('PaymentCardDetailsModal')

    const handleShowPaymentCardDetails = () => {
      if (paymentCard) showPaymentCardDetailsModal(null, { paymentCard })
    }

    return (
      <Card innerRef={ref}>
        <CardBody>
          <ProductCardTitle
            title="Szczegóły subskrypcji"
            adminUrl={subscriptionDetails.urls.admin}
            isEditAllowed={
              subscriptionDetails.status === 'initial' ||
              user.hasPerm(UserPermission.PromotionsSubscriptionContractCanEditConfirmed)
            }
            onEdit={handleEdit}
          />
          <table className="table table-sm mb-0">
            <tbody>
              <SubscriptionDetailsInformationRow name="Status">
                <BadgeWithIcon icon={badgeIcon} title={subscriptionDetails.status_display} className={badgeClass} />
              </SubscriptionDetailsInformationRow>
              <SubscriptionDetailsInformationRow name="Produkt">
                Subskrypcja {subscriptionDetails.plan_display}
              </SubscriptionDetailsInformationRow>
              <SubscriptionDetailsInformationRow name="Plan">
                {subscriptionDetails.plan_display}
              </SubscriptionDetailsInformationRow>
              {subscriptionDetails.status === 'cancelling' && (
                <SubscriptionDetailsInformationRow name="Planowana data anulowania">
                  {toDefaultDateTimeFormat(subscriptionDetails.repayment_date)}
                </SubscriptionDetailsInformationRow>
              )}
              <SubscriptionDetailsInformationRow name="Cena brutto">
                {formatPrice(subscriptionDetails.single_price_brutto)}
              </SubscriptionDetailsInformationRow>
              <SubscriptionDetailsInformationRow name="Źródło">{sourceName}</SubscriptionDetailsInformationRow>
              <SubscriptionDetailsInformationRow name="Sprzedawca">
                {subscriptionDetails.seller ?? 'Brak'}
              </SubscriptionDetailsInformationRow>
              <SubscriptionDetailsInformationRow name="Data rozpoczęcia">
                {subscriptionDetails.subscription_started
                  ? toDefaultDateTimeFormat(subscriptionDetails.subscription_started)
                  : 'Nie rozpoczęto'}
              </SubscriptionDetailsInformationRow>
              <SubscriptionDetailsInformationRow name="Data następnej wpłaty">
                {subscriptionDetails.repayment_date ? toDefaultDateFormat(subscriptionDetails.repayment_date) : 'Brak'}
              </SubscriptionDetailsInformationRow>
              <SubscriptionDetailsInformationRow name="Data utworzenia">
                {toDefaultDateFormat(subscriptionDetails.created)}
              </SubscriptionDetailsInformationRow>
              <SubscriptionDetailsInformationRow name="Data sprzedaży">
                {toDefaultDateFormat(subscriptionDetails.sell_date)}
              </SubscriptionDetailsInformationRow>

              <SubscriptionDetailsInformationRow name="Karta płatnicza">
                <span className={paymentCard && 'cursor-pointer'} onClick={handleShowPaymentCardDetails}>
                  {paymentCard?.masked_number ?? 'Brak'}
                </span>
              </SubscriptionDetailsInformationRow>

              <PackageDetailsRow label="Data wymaganej płatności">
                {subscriptionDetails.required_payment_date
                  ? toDefaultDateFormat(subscriptionDetails.required_payment_date)
                  : 'Brak'}
              </PackageDetailsRow>

              <SubscriptionDetailsInformationRow name="Auto anulowanie po">
                {subscriptionDetails.auto_cancel_after_date
                  ? toDefaultDateFormat(subscriptionDetails.auto_cancel_after_date)
                  : 'Brak'}
              </SubscriptionDetailsInformationRow>
            </tbody>
          </table>
          <SubscriptionContractDetailsActions subscriptionDetails={subscriptionDetails} />
        </CardBody>
      </Card>
    )
  },
)
