import * as React from 'react'
import { ReservationCard } from '@modules/reservations/details/common/reservation-card'
import { ReceptionBookingDetails } from '@models/reception'
import { ReservationBistroVoucherRow } from '@modules/reservations/details/bistro-vouchers/reservation-bistro-voucher-row'
import { ReservationBistroVouchersActions } from '@modules/reservations/details/bistro-vouchers/reservation-bistro-vouchers-actions'
import { useAppDispatch, useAppSelector } from '@store/index'
import { bookingVouchersSelector } from '@store/slices/reservations-slice'
import { useApiRequest } from '@components/hooks/use-api-request'
import { getBookingVouchers } from '@store/actions/reservation-actions'
import { ContentLoader } from '@components/content-loader'

interface Props {
  booking: ReceptionBookingDetails
}

export const ReservationBistroVouchers = ({ booking }: Props): JSX.Element => {
  const bookingVouchers = useAppSelector(bookingVouchersSelector)

  const dispatch = useAppDispatch()
  const { isLoading, action: fetch } = useApiRequest(async () => await dispatch(getBookingVouchers(booking)))

  React.useEffect(() => {
    fetch()
  }, [])

  return (
    <ReservationCard
      title="Vouchery Kawiarnia/Bistro"
      cardActions={<ReservationBistroVouchersActions booking={booking} />}
    >
      <ContentLoader isLoading={isLoading}>
        {bookingVouchers.length ? (
          <table className="table table-sm">
            <thead>
              <tr>
                <th className="border-0">Opis</th>
                <th className="border-0">Data dodania</th>
                <th className="border-0">Dodane przez</th>
                <th className="border-0">Kwota</th>
                <th className="border-0" />
              </tr>
            </thead>
            <tbody>
              {bookingVouchers.map(row => (
                <ReservationBistroVoucherRow key={row.id} voucher={row} />
              ))}
            </tbody>
          </table>
        ) : (
          <p className="text-center">Brak voucherów</p>
        )}
      </ContentLoader>
    </ReservationCard>
  )
}
