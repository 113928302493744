import * as React from 'react'
import { Button, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useFormRequest } from '@components/hooks/use-api-request'
import { useForm } from 'react-hook-form'
import { useNotificationHook } from '@hyper/use-notification-hook'
import ModalBody from 'reactstrap/lib/ModalBody'
import { FormInput } from '@hyper/forms'
import { useAppDispatch } from '@store/index'
import { PackageCode } from '@models/package'
import { commonObjectPut } from '@store/actions/generic-actions'
import { BaseModalProps } from '@components/modals/types'
import { updatePackageDetails } from '@store/slices/package-slice'
import { Form } from '@hyper/forms/form'

interface Props extends BaseModalProps {
  packageCode: PackageCode
}

interface FormInputs {
  type: string
}

export const PackageCodeConvertModal: React.FC<Props> = ({ packageCode, toggleIsVisible }) => {
  const methods = useForm<FormInputs>()
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    dispatch(updatePackageDetails(await commonObjectPut(packageCode.urls.convert, payload)))
    addSuccessMessage('Sukces', `Kod ${packageCode.code} został skonwertowany!`)
    toggleIsVisible()
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>{`Konwersja kodu ${packageCode.code}`}</ModalHeader>
      <ModalBody>
        <Row>
          <FormInput name="type" type="select">
            <option value="inactive">Nieaktywny</option>
          </FormInput>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>

        <SaveButton className="btn btn-green" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
