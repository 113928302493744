import * as React from 'react'
import { BeddingOrder, CleaningOrder, CommonTask, ImprovementOrder } from '@modules/housekeeping/models'
import { RootState, useAppSelector } from '@store/index'
import { useHousekeepingCleaningCompanies } from '@modules/housekeeping/users/use-housekeeping-cleaning-companies'
import { getById } from '@helpers/utils'

interface HousekeepingOrderWorkersRowProps {
  order: CleaningOrder | BeddingOrder | ImprovementOrder | CommonTask | null
}

export const HousekeepingOrderWorkersRow: React.FC<HousekeepingOrderWorkersRowProps> = ({ order }) => {
  const companies = useHousekeepingCleaningCompanies(order?.resort)
  const workers = useAppSelector((state: RootState) => state.houseKeepingState.cleaningWorkers)

  const { usersRows, company } = React.useMemo(() => {
    if (!order) return { userRows: [], company: null }

    if ('users' in order) {
      const users = order.users.map(user => workers.find(row => row.id === user)).filter(Boolean)
      const company = users.length ? getById(companies, users[0].company_id) : null
      const usersRows = users.map(worker => (worker ? <div key={worker.id}>{worker.name}</div> : null))

      return { usersRows, company }
    }

    return {
      userRows: order.user_id ? <div>{order.user_id}</div> : null,
      company: getById(companies, order.user_id),
    }
  }, [order, workers, companies])

  return (
    <td className="housekeeping__orders_table__column" width={200}>
      {usersRows}
      {company && <small className="font-10">{company.name}</small>}
    </td>
  )
}
