import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { useAppData } from '@components/hooks/use-app-data'

interface Props {
  accommodation_type_id: number
}

export const ReservationDataAccommodationType = ({ accommodation_type_id }: Props): JSX.Element => {
  const { accommodation_types } = useAppData()

  const accommodationType =
    accommodation_types.find(accommodationType => accommodationType.id === accommodation_type_id)?.name || 'brak'

  return (
    <IconWithText
      textClass="font-weight-bold"
      icon="uil-pathfinder-unite text-secondary me-2 font-16"
      text={accommodationType}
    />
  )
}
