import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { Button, ModalFooter, ModalHeader } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { toTextDateFormat } from '@helpers/date-helper'
import { BaseModalProps } from '@components/modals/types'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectPost } from '@store/actions/generic-actions'
import { SaveButton } from '@hyper/button'
import { updateReceptionBookingDetails } from '@store/actions/reception-actions'
import { useAppDispatch } from '@store/index'

interface Props extends BaseModalProps {
  bookingDetails: ReceptionBookingDetails
  onConfirm: () => void
}

export const ReceptionBookingCheckOutShorteningStayConfirmDialog: React.FC<Props> = ({
  bookingDetails,
  toggleIsVisible,
  onConfirm,
}) => {
  const dispatch = useAppDispatch()

  const { action: shortenStay, isLoading: isShorteningStay } = useApiRequest(async () => {
    await dispatch(updateReceptionBookingDetails(await commonObjectPost(bookingDetails.urls.check_out_step_shorten)))

    onConfirm()
    toggleIsVisible()
  })

  return (
    <>
      <ModalHeader className="bg-danger text-white">Potwierdź skrócenie pobytu</ModalHeader>
      <ModalBody>
        <div className="d-flex align-items-center pt-2">
          <i className="uil-exclamation-triangle text-danger font-36 mr-3 ml-2 pulse" />

          <div>
            <p>
              Data zakończenia pobytu <strong>({toTextDateFormat(bookingDetails.date_to)})</strong> nie kończy się{' '}
              <span className="text-nowrap">
                dzisiaj <strong>({toTextDateFormat(new Date())})</strong>
              </span>
              . Przejście dalej{' '}
              <span className="text-nowrap">
                spowoduje <strong>skrócenie pobytu.</strong>
              </span>
            </p>
            <strong>Potwierdź, że chcesz to zrobić?</strong>

            <strong className="mt-3 d-block text-danger">Operacja jest nieodwracalna!</strong>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={toggleIsVisible}>
          Anuluj
        </Button>
        <SaveButton
          isSaving={isShorteningStay}
          label="Potwierdź"
          labelSaving="Potwierdzanie..."
          disabled={isShorteningStay}
          className="btn btn-danger"
          onClick={shortenStay}
        />
      </ModalFooter>
    </>
  )
}
