import * as React from 'react'
import { ButtonWithIcon } from '@components/button-with-icon'
import { UpsellBookingsDateFilter } from '@modules/upselling/upsell-bookings/filters/upsell-bookings-date-filter'
import { FormInput } from '@hyper/forms'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { useModal } from '@components/modals/use-modal'
import { Button } from 'reactstrap'
import classNames from 'classnames'
import { useUpsellBookingsFilters } from '@modules/upselling/upsell-bookings/filters/use-upsell-bookings-filters'

interface Props {
  type: string
}

export const UpsellBookingsFilters = ({ type }: Props): JSX.Element => {
  const navigate = useNavigate()

  const { isStatusSelected, toggleStatus, filters, updateFilters } = useUpsellBookingsFilters()

  const [showFiltersModal] = useModal('UpsellBookingsDateFiltersModal', {
    initialFilters: filters,
    onFiltersChange: updateFilters,
  })

  const navigateToOutdated = () => {
    navigate(NavigationPath.UpSellBookingsOutdatedWithParams.replaceParam(':type', type))
  }

  const hasFilters = filters.length

  return (
    <div className="d-flex justify-content-between align-items-center mb-2">
      <div className="d-flex">
        <UpsellBookingsDateFilter name="contact_required_date" />
        <ButtonWithIcon
          icon="uil-history-alt text-danger"
          text="Zaległe"
          color="light"
          btnClass="btn-tall"
          handleClick={navigateToOutdated}
        />
        <FormInput
          name="search"
          placeholder="Szukaj kontaktu lub lokalu"
          colSize="4"
          formGroupClassName="border rounded bg-white flex-nowrap align-items-center mb-0"
          placeholderIcon="uil-search font-14 lh-0 ml-1"
          inputClassName="border-0 bg-transparent py-0"
          colStyle={{ minWidth: 250 }}
        />
      </div>
      <div className="d-flex">
        <Button
          color="info"
          onClick={toggleStatus('contact_required')}
          className={classNames('shadow-none', { 'opacity-3': hasFilters && !isStatusSelected('contact_required') })}
        >
          Wymaga kontaktu
        </Button>
        <Button
          onClick={toggleStatus('contact_required_again')}
          className={classNames('bg-danger-light ml-1 shadow-none border-0', {
            'opacity-3': hasFilters && !isStatusSelected('contact_required_again'),
          })}
        >
          Do ponownego kontaktu
        </Button>
        <Button
          onClick={toggleStatus('sold')}
          className={classNames('bg-purple ml-1 shadow-none border-0', {
            'opacity-3': hasFilters && !isStatusSelected('sold'),
          })}
        >
          Sprzedany
        </Button>
        <Button
          color="light"
          onClick={toggleStatus('not_interested')}
          className={classNames('ml-1 shadow-none', { 'opacity-3': hasFilters && !isStatusSelected('not_interested') })}
        >
          Niezainteresowany
        </Button>
        <ButtonWithIcon icon="uil-filter" text="Filtruj" color="transparent" handleClick={showFiltersModal} />
      </div>
    </div>
  )
}
