import * as React from 'react'
import { RecommendationGift } from '@models/crm'
import Table from '@components/table/table'
import { RecommendationGiftEditIcon } from '@modules/crm/recommendation-gifts/recommendation-gift-edit-icon'
import { RecommendationGiftDeleteIcon } from '@modules/crm/recommendation-gifts/recommendation-gift-delete-icon'
import { useModal } from '@components/modals/use-modal'
import { toDefaultDateFormat } from '@helpers/date-helper'
import classNames from 'classnames'
import { formatPriceShort } from '@helpers/utils'

interface RecommendationGiftsTableRowProps {
  recommendationGift: RecommendationGift
  selectableCell: (recommendationGift: RecommendationGift) => React.ReactElement
}

export const RecommendationGiftsTableRow: React.FC<RecommendationGiftsTableRowProps> = ({
  selectableCell,
  recommendationGift,
}) => {
  const [handleDetails] = useModal('RecommendationGiftDetailsDialog', { recommendationGift })

  return (
    <Table.Row key={recommendationGift.id}>
      {selectableCell(recommendationGift)}
      <Table.Cell onClick={handleDetails}>{recommendationGift.number}</Table.Cell>
      <Table.Cell onClick={handleDetails}>{recommendationGift.recommending_person_name}</Table.Cell>
      <Table.Cell onClick={handleDetails}>{recommendationGift.recommending_related_number}</Table.Cell>
      <Table.Cell onClick={handleDetails}>{recommendationGift.recommended_person_name}</Table.Cell>
      <Table.Cell onClick={handleDetails}>{recommendationGift.recommended_related_number}</Table.Cell>
      <Table.Cell className="font-12" onClick={handleDetails}>
        <code>{recommendationGift.account_number_formatted}</code>
      </Table.Cell>
      <Table.Cell onClick={handleDetails}>{toDefaultDateFormat(recommendationGift.transfer_by_day)}</Table.Cell>
      <Table.Cell onClick={handleDetails}>{formatPriceShort(recommendationGift.transfer_amount)}</Table.Cell>
      <Table.Cell onClick={handleDetails}>
        <span
          className={classNames('badge', {
            'badge-info-lighten': recommendationGift.transfer_status === 'initial',
            'badge-warning-lighten': recommendationGift.transfer_status === 'transfer_in_package',
            'badge-success-lighten': recommendationGift.transfer_status === 'transfer_sent',
          })}
        >
          {recommendationGift.transfer_status_display}
        </span>
      </Table.Cell>
      <Table.Cell className="text-nowrap">
        <RecommendationGiftEditIcon recommendationGift={recommendationGift} />
        {recommendationGift.transfer_status === 'initial' && (
          <RecommendationGiftDeleteIcon recommendationGiftDelete={recommendationGift} />
        )}
      </Table.Cell>
    </Table.Row>
  )
}
