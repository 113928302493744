import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { SubscriptionCode, SubscriptionDetails } from '@modules/subscription/models'
import { Button, Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useFormRequest } from '@components/hooks/use-api-request'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAppDispatch } from '@store/index'
import { commonObjectPost } from '@store/actions/generic-actions'
import { updateSubscriptionDetails } from '@store/slices/subscription-slice'
import { useForm } from 'react-hook-form'
import { FormInput } from '@hyper/forms'
import { Form } from '@hyper/forms/form'

interface Props extends BaseModalProps {
  code: SubscriptionCode
  onClose: () => void
  subscription: SubscriptionDetails
}

interface FormInputs {
  code: string
}

export const SubscriptionDetailsCodesGlobalMergeModal: React.FC<Props> = ({
  subscription,
  code,
  onClose,
  toggleIsVisible,
}) => {
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const methods = useForm<FormInputs>()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    await dispatch(
      updateSubscriptionDetails(
        await commonObjectPost<SubscriptionDetails>(subscription.urls.global_codes_merge, {
          main_code: code.id,
          ...payload,
        }),
      ),
    )

    addSuccessMessage(
      'Sukces',
      <>
        <p>Kody zostały skonwertowane i połączone w całość. Należy użyć kodu:</p>
        <strong className="h4 d-block">{code.code}</strong>
        <p>w wybranej rezerwacji</p>
      </>,
    )
    toggleIsVisible()
    onClose()
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Konwersja globalna kodów</ModalHeader>
      <ModalBody>
        <Row>
          <Col md={12}>
            <p>
              Do wybranego przez Ciebie kodu <strong>{code.code}</strong> zostanie użyty kod z innego pakietu. Po
              konwersji kod z innego pakietu będzie nieaktywny.
            </p>
          </Col>
          <FormInput name="code" />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>

        <SaveButton label="Konwertuj" labelSaving="Konwertowanie" className="btn btn-green" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
