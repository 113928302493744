import * as React from 'react'
import { secondsToInterval } from '@helpers/date-helper'

interface Props {
  totalTimeStarted?: number
  children?: React.ReactChild
}

export const HousekeepingOrdersRowTotalTimeStarted: React.FC<Props> = ({ totalTimeStarted, children }) => {
  let interval = ''
  try {
    interval = secondsToInterval(totalTimeStarted || 0)
  } catch (e) {
    console.warn(e)
  }

  return (
    <td className="housekeeping__orders_table__column" width={150}>
      {interval} {children}
    </td>
  )
}
