import * as React from 'react'
import { SubscriptionDetails } from '@modules/subscription/models'
import { useApiRequest } from '@components/hooks/use-api-request'
import { updateSubscriptionDetails } from '@store/slices/subscription-slice'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAppDispatch } from '@store/index'
import { ButtonWithIcon } from '@components/button-with-icon'
import { BaseModalProps } from '@components/modals/types'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'

interface Props extends BaseModalProps {
  subscriptionDetails: SubscriptionDetails
}

export const SubscriptionDetailsCancelButton = ({ subscriptionDetails, toggleIsVisible }: Props): JSX.Element => {
  const { addSuccessNotification } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading, action: handleRemove } = useApiRequest(async () => {
    dispatch(updateSubscriptionDetails(await commonObjectDelete<SubscriptionDetails>(subscriptionDetails.urls.details)))
    addSuccessNotification('Pakiet prywatny został skasowany')
    toggleIsVisible()
  })

  return (
    <CommonObjectConfirmAction isLoading={isLoading} handleAccept={handleRemove}>
      <ButtonWithIcon icon="uil-trash-alt" color="danger" text="Usuń" />
    </CommonObjectConfirmAction>
  )
}
