import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { BaseModalProps } from '@components/modals/types'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { ContentLoader } from '@components/content-loader'
import { useNewFeedingPricelist } from '@modules/reception/checkin/step-feeding/new-feeding/pricelist/use-new-feeding-pricelist'
import { ReceptionBookingNewFeedingPricelistCatalogPrices } from '@modules/reception/checkin/step-feeding/new-feeding/pricelist/reception-booking-new-feeding-pricelist-catalog-prices'
import { ReceptionBookingNewFeedingPricelistBestsellerPrices } from '@modules/reception/checkin/step-feeding/new-feeding/pricelist/reception-booking-new-feeding-pricelist-bestseller-prices'

interface Props extends BaseModalProps {
  bookingDetails: ReceptionBookingDetails
}

export const ReceptionBookingNewFeedingPriceListModal = ({
  bookingDetails,
  toggleIsVisible,
}: Props): React.ReactElement => {
  const { availability, isLoading, guestKinds, getPriceListItem } = useNewFeedingPricelist(bookingDetails)

  const { hasBreakfast, hasDinner, hasBreakfastHb, hasDinnerHb } = availability

  const headers = [
    { title: 'Śniadanie', image: require('@assets/images/breakfast.jpg'), available: hasBreakfast },
    { title: 'Obiadokolacje', image: require('@assets/images/dinner.jpg'), available: hasDinner },
    {
      title: 'Pełne wyżywienie',
      image: require('@assets/images/dinner_with_breakfast.jpg'),
      available: hasDinner && hasBreakfast,
    },
  ]

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>Aktualny cennik wyżywienia</ModalHeader>
      <ModalBody style={{ width: hasDinner && hasBreakfast ? 800 : 500 }}>
        <ContentLoader isLoading={isLoading}>
          <table className="table w-100 table-sm fw-semi-bold mb-0">
            <thead>
              <tr>
                <th className="border-top-0 border-bottom-0" />
                {headers.map(header => (
                  <PriceListMealHeader
                    key={header.title}
                    available={header.available}
                    image={header.image}
                    title={header.title}
                  />
                ))}
              </tr>
            </thead>
            <tbody>
              {guestKinds.map(kind => (
                <ReceptionBookingNewFeedingPricelistCatalogPrices key={kind} prices={getPriceListItem(kind)} />
              ))}
              {hasBreakfastHb && hasDinnerHb && (
                <ReceptionBookingNewFeedingPricelistBestsellerPrices
                  getPriceListItem={getPriceListItem}
                  guestKinds={guestKinds}
                />
              )}
            </tbody>
          </table>
        </ContentLoader>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-end" onClick={toggleIsVisible}>
        <Button color="light">Zamknij</Button>
      </ModalFooter>
    </>
  )
}

const PriceListMealHeader = ({ image, title, available }) => {
  if (!available) return null

  return (
    <th className="text-secondary text-center border-top-0 border-bottom-0">
      <img
        className="mb-3"
        src={image}
        alt="dinner_with_breakfast"
        height="75"
        width="100%"
        style={{ maxWidth: '160px' }}
      />
      <div>{title}</div>
    </th>
  )
}
