import * as React from 'react'
import { PageTitle } from '@hyper/page-title'
import { createSelectOption, formatPriceShort, useDocumentTitle } from '@helpers/utils'
import { Button, Card, CardBody, Col, Row } from 'reactstrap'
import { FormInput } from '@hyper/forms'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { debitAccount, getAccount } from '@modules/reception/pos/api'
import { ReceptionPosProduct } from '@modules/reception/pos/products'
import { SaveButton } from '@hyper/button'
import { Guest, ProductPos } from '@modules/reception/pos/models'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useDebounce } from 'rooks'
import { useCancelablePromise } from '@components/hooks/use-cancelable-promise'
import { useAppData } from '@components/hooks/use-app-data'
import { FormSelect } from '@hyper/forms/form-select'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useParams } from 'react-router-dom'
import { useApartments } from '@components/hooks/use-apartments'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { ContentLoader } from '@components/content-loader'

type QueryParams = 'local' | 'resort'

interface FormInputs {
  apartment: string
  resort: string
  guest: number
  source: CustomReactSelectOption | null
}

export const ReceptionPosView: React.FC = () => {
  const { local: queryLocal, resort: queryResort } = useParams<QueryParams>()
  const { voucher_scopes, resorts } = useAppData()
  const [guests, setGuests] = React.useState<Guest[]>([])
  const [products, setProducts] = React.useState<ProductPos[]>([DefaultProduct])

  const { addErrorNotification, addSuccessNotification } = useNotificationHook()
  const { apartments, isLoading: isFetchingApartments } = useApartments(queryResort)

  useDidUpdateEffect(() => {
    if (!queryLocal || !apartments.length) return

    const apartment = apartments.find(apartment => apartment.id === parseInt(queryLocal, 10))
    if (apartment) methods.setValue('apartment', apartment.name)
  }, [apartments])

  const pageTitle = 'Wirtualny POS'
  useDocumentTitle(pageTitle)

  const methods = useForm<FormInputs>({
    defaultValues: {
      source: null,
      resort: queryResort ?? '',
    },
  })

  const [apartment, resort] = useWatch({ control: methods.control, name: ['apartment', 'resort'] })

  const loadApartmentAccount = useDebounce(async cancelToken => {
    try {
      setGuests([])

      const { source, apartment, resort } = methods.getValues()
      const data = await getAccount(resort, apartment, source?.value, cancelToken)
      if (!data.length) {
        addErrorNotification('W wybranym pokoju nie znaleziono gości uprawnionych do zakupu na pokój')
      } else {
        setGuests(data)
      }
    } catch (error) {
      if (error.code == 'ERR_CANCELED') return

      addErrorNotification(error.response?.data.errors[0].detail)
    }
  }, 500)

  const handleProductChange = (product, index) =>
    setProducts(products.map((p, i) => (i === index ? { ...product } : { ...p })))

  const handleProductRemove = index => setProducts(products.filter((product, i) => i !== index))

  useCancelablePromise(
    cancelToken => {
      if (apartment && resort) {
        loadApartmentAccount(cancelToken)
      }
    },
    [apartment, resort],
  )

  const handleAddProduct = () => {
    setProducts([...products, DefaultProduct])
  }

  const voucherScopesOptions = voucher_scopes.map(scope => createSelectOption(scope.name, scope.id))

  const { isLoading, action: handleSave } = useApiRequest(async () => {
    try {
      const { guest, apartment, source } = methods.getValues()
      await debitAccount(
        resort,
        guest,
        apartment + ' ' + guests.find(row => row.id === guest)?.name || '',
        products,
        source?.value,
      )
      addSuccessNotification('Produkty zostały sprzedane')
    } catch (error) {
      addErrorNotification(error.response.data.errors[0].detail)
    }
  })

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: 'Recepcja', path: '/dashboard/reception', active: false },
          {
            label: pageTitle,
            path: '',
            active: true,
          },
        ]}
        title={pageTitle}
      />
      <ContentLoader isLoading={isFetchingApartments}>
        <Card>
          <FormProvider {...methods}>
            <CardBody>
              <Row>
                <Col md={12}>
                  <h5 className="text-dark mb-2">Wybierz portfel</h5>
                </Col>
                <Col className="col-3">
                  <FormSelect options={voucherScopesOptions} name="source" label="Źródło" />
                </Col>

                <FormInput colSize={3} name="resort" type="select" label="Ośrodek">
                  {resorts.map(resort => (
                    <option key={resort.id} value={resort.id}>
                      {resort.name}
                    </option>
                  ))}
                </FormInput>
                <FormInput colSize={3} name="apartment" label="Wpisz numer lokalu" />
                {!!guests.length && (
                  <FormInput
                    colSize={3}
                    name="guest"
                    type="select"
                    label="Gość"
                    registerParams={{ valueAsNumber: true }}
                  >
                    {guests.map(guest => (
                      <option key={guest.id} value={guest.id}>
                        {guest.name} ({formatPriceShort(guest.limit)})
                      </option>
                    ))}
                  </FormInput>
                )}
                {!!guests.length && (
                  <Col md={12}>
                    <h5 className="text-dark mb-2">Dodaj produkty</h5>

                    {products.map((product, index) => (
                      <ReceptionPosProduct
                        handleProductRemove={handleProductRemove}
                        handleProductChange={handleProductChange}
                        index={index}
                        key={index}
                        product={product}
                      />
                    ))}
                  </Col>
                )}
                <Col md={12} className="text-right">
                  {!!guests.length && (
                    <Button onClick={handleAddProduct} type="button" color="secondary" className="mr-1">
                      Dodaj produkt
                    </Button>
                  )}
                  <SaveButton
                    isSaving={isLoading}
                    type="button"
                    onClick={handleSave}
                    label="Sprzedaj"
                    labelSaving="Sprzedawanie.."
                  />
                </Col>
              </Row>
            </CardBody>
          </FormProvider>
        </Card>
      </ContentLoader>
    </>
  )
}

const DefaultProduct = {
  name: 'Produkt testowy',
  quantity: 1,
  tax: 23,
  price_brutto: 123,
}
