import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { ReceptionBookingCartItems } from '@modules/reception/cart/cart-items/reception-booking-cart-items'
import { ReceptionBookingCartLoader } from '@modules/reception/cart/reception-booking-cart-loader'
import { ReceptionBookingDetails, ReceptionBookingDetailsCart } from '@models/reception'
import { useContext } from 'react'
import { CartContext } from '@modules/reception/cart/cart-context'
import classnames from 'classnames'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectPost } from '@store/actions/generic-actions'
import { BaseCartPaymentResponse } from '@modules/reception/cart/models'
import Lottie from 'lottie-react'
import classNames from 'classnames'

interface Props {
  isPaymentSelected: boolean
  isSaleCompleted: boolean
  cart: ReceptionBookingDetailsCart
  setCart: (cart: ReceptionBookingDetailsCart) => void
  bookingDetails: ReceptionBookingDetails
}

export const ReceptionBookingCartSummaryBox = ({
  isPaymentSelected,
  isSaleCompleted,
  cart,
  setCart,
  bookingDetails,
}: Props): JSX.Element => {
  const { allRowCollapsed, toggleAllRowCollapsed } = useContext(CartContext)

  const isCartEmpty = !cart.items.length

  const { action: clearCart, isLoading } = useApiRequest(async () => {
    const response = await commonObjectPost<BaseCartPaymentResponse>(cart.urls.clear)
    setCart(response.cart_details)
  })

  return (
    <div className="col-8">
      <ReceptionBookingCartLoader isLoading={isPaymentSelected}>
        <div className={classnames('bg-white-smoke border rounded', { 'h-100': isCartEmpty })}>
          {isCartEmpty ? (
            <div className="d-flex align-items-center justify-content-center py-3 h-100 fw-semi-bold">
              {isSaleCompleted ? (
                <div>
                  <p className="font-24 text-center">Sprzedaż została zakończona pomyślnie.</p>
                  <Lottie animationData={require('@assets/animations/confetti-success.json')} style={{ height: 400 }} />
                </div>
              ) : (
                <IconWithText icon="uil-shopping-cart-alt font-20" text="Koszyk jest pusty" />
              )}
            </div>
          ) : (
            <>
              <div className="m-2">
                <strong className="text-secondary font-15">Podsumowanie koszyka</strong>
                <IconWithText
                  icon={classnames('lh-initial font-14', allRowCollapsed ? 'uil-plus' : 'uil-minus')}
                  text={allRowCollapsed ? 'rozwiń wszystko' : 'zwiń wszystko'}
                  textClass="font-12"
                  wrapperClassNames="ml-3"
                  onClick={toggleAllRowCollapsed}
                />
              </div>
              <ReceptionBookingCartItems cart={cart} bookingDetails={bookingDetails} />
            </>
          )}
        </div>
        {!isCartEmpty && (
          <div className="d-flex justify-content-between">
            <IconWithText
              icon="uil-print font-15"
              text="Drukuj rachunek"
              textClass="font-weight-semibold font-12"
              wrapperClassNames="mt-1"
            />
            <IconWithText
              icon={classNames('font-15 ml-1', { 'uil-trash-alt': !isLoading })}
              text={isLoading ? 'Czyszczenie...' : 'Wyczyść koszyk'}
              textClass="font-weight-semibold font-12"
              wrapperClassNames="mt-1 text-danger flex-row-reverse"
              onClick={clearCart}
            />
          </div>
        )}
      </ReceptionBookingCartLoader>
    </div>
  )
}
