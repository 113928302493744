import * as React from 'react'
import interceptorService from '@services/interceptor'
import { connect } from 'react-redux'
import { RootState } from '@store/index'
import { DashboardAppData } from '@models/dashboard'
import { getInitData } from '@store/actions/app-actions'
import { setAuthStatus, setIsFailure } from '@store/slices/app-slice'

interface Props {
  children: React.ReactNode
  getInitData: () => Promise<DashboardAppData>
  setIsFailure: () => void
  setIsNotAuthenticated: () => void
  isAuthenticated: boolean
}

interface State {
  loading: boolean
}

export class StartupViewComponent extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props)

    this.state = {
      loading: true,
    }
  }

  public async componentDidUpdate(prevProps: Readonly<Props>): Promise<void> {
    if (prevProps.isAuthenticated !== this.props.isAuthenticated && this.props.isAuthenticated) {
      await this.initializeData()
    }
  }

  public async componentDidMount(): Promise<void> {
    interceptorService.setup()
    await this.initializeData()
  }

  public render() {
    return this.state.loading ? null : this.props.children
  }

  public initializeData = async (): Promise<void> => {
    try {
      await this.props.getInitData()
    } catch (error) {
      if (error.message === 'Request failed with status code 403') {
        this.props.setIsNotAuthenticated()
      } else {
        this.props.setIsFailure()
      }
    }

    this.setState({ loading: false })
  }
}

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: state.appState.isAuthenticated,
})

const mapDispatchToProps = dispatch => ({
  getInitData: () => dispatch(getInitData(false)),
  setIsFailure: () => dispatch(setIsFailure()),
  setIsNotAuthenticated: () => dispatch(setAuthStatus(false)),
})

const StartupView = connect(mapStateToProps, mapDispatchToProps)(StartupViewComponent)

export default StartupView
