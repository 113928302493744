import * as React from 'react'
import { FeedingAppData } from '@modules/feeding/models'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { getFeedingAppData } from '@store/actions/feeding-actions'
import { setFeedingAppData } from '@store/slices/feeding-slice'

export const useFeedingAppData = (): FeedingAppData => {
  const feedingAppData = useAppSelector((state: RootState) => state.feedingState.appData)
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (feedingAppData.status === 'unknown') {
      dispatch(getFeedingAppData())
      dispatch(setFeedingAppData({ ...feedingAppData, status: 'loading' }))
    }
  }, [feedingAppData.status])

  return feedingAppData
}
