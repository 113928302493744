import { useToggle } from '@components/hooks/use-toggle'
import { useModal } from '@components/modals/use-modal'
import * as React from 'react'
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import { usePaymentAppData } from '@modules/payment-registers/hooks/use-payment-app-data'

interface Props {
  mode: 'withdraw' | 'deposit'
  className?: string
  resortId?: number
  disabled?: boolean
  addPoints?: boolean
  onSuccess?: () => void
}

export const CashRegisterAddModalToggle = ({
  mode,
  className,
  resortId,
  onSuccess,
  disabled = false,
  addPoints = true,
}: Props): JSX.Element => {
  const { payment_register_points } = usePaymentAppData()

  const points = payment_register_points.filter(row => (resortId ? row.resort_id === resortId : true))

  const types = {
    withdraw: [
      { type: 'WP', title: 'Wypłata pracownika' },
      { type: 'WG', title: 'Wypłata Gastro' },
    ],
    deposit: [
      { type: 'PP', title: 'Wpłata pracownika' },
      { type: 'PG', title: 'Wpłata pracownika gastro' },
    ],
  }

  types.withdraw.push({ type: 'WB', title: 'Wypłata do banku' })
  if (addPoints) {
    points.forEach(row => {
      types.withdraw.push({ type: row.kw_id, title: `Wypłata do ${row.name}` })
      types.deposit.push({ type: row.kp_id, title: `Wpłata z ${row.name}` })
    })
  }

  const [isOpen, toggleOpen] = useToggle()
  const [handleOpenModal] = useModal('CashRegisterAddModal', { resortId, onSuccess })
  const dropdownLabel = { withdraw: 'Dodaj KW', deposit: 'Dodaj KP' }[mode]

  const color = mode === 'deposit' ? 'green' : 'warning'

  return (
    <ButtonDropdown isOpen={isOpen} toggle={toggleOpen} disabled={disabled}>
      <DropdownToggle color={color} className={className} caret={true} disabled={disabled}>
        {dropdownLabel}
      </DropdownToggle>
      <DropdownMenu right>
        {types[mode].map(({ type, title }) => (
          <DropdownItem key={type} onClick={() => (!disabled ? handleOpenModal(null, { type, title }) : undefined)}>
            {title}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </ButtonDropdown>
  )
}
