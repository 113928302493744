import * as React from 'react'
import { Button, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { SaveButton } from '@hyper/button'
import { useForm } from 'react-hook-form'
import { BaseModalProps } from '@components/modals/types'
import { Form } from '@hyper/forms/form'
import { useFormRequest } from '@components/hooks/use-api-request'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { commonObjectPut } from '@store/actions/generic-actions'
import { FormInput } from '@hyper/forms'

interface FormInputs {
  password: string
  confirmed_password: string
}

interface Props extends BaseModalProps {
  url: string
}

export const AccountUserChangePassword: React.FC<Props> = ({ toggleIsVisible, url }) => {
  const { addSuccessNotification } = useNotificationHook()
  const methods = useForm<FormInputs>()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    await commonObjectPut(url, payload)
    addSuccessNotification('Hasło zostało ustawione')

    toggleIsVisible()
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Zmiana hasła</ModalHeader>
      <ModalBody>
        <Row>
          <FormInput name="password" type="password" label="Hasło" colSize={6} />
          <FormInput name="confirmed_password" type="password" label="Powtórz hasło" colSize={6} />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
