import * as React from 'react'
import { ReceptionToCheckOutRowButtonsExtendedStayRemove } from '@modules/reception/to-checkout/row/reception-to-check-out-row-buttons-extended-stay-remove'
import { Button } from 'reactstrap'
import { ReceptionToCheckOutRowButtonsExtendedStay } from '@modules/reception/to-checkout/row/reception-to-check-out-row-buttons-extended-stay'
import { ReceptionBooking } from '@models/reception'

interface Props {
  booking: ReceptionBooking
}

export const ReceptionBookingExtensionButton = ({ booking }: Props): JSX.Element =>
  booking.extended_stay ? (
    <ReceptionToCheckOutRowButtonsExtendedStayRemove booking={booking}>
      <Button
        color="secondary"
        className="reception__bookings_table__row__clock-button"
        type="button"
        title="Anuluj wydłużenie doby"
      >
        <i className="uil uil-clock font-16" />
      </Button>
    </ReceptionToCheckOutRowButtonsExtendedStayRemove>
  ) : (
    <ReceptionToCheckOutRowButtonsExtendedStay booking={booking} />
  )
