import * as React from 'react'
import Table, { TableHeaderValue } from '@components/table/table'
import { PaymentRegister } from '@models/payments'
import { CashboxSafeModalTableRow } from '@modules/payment-registers/cashbox-safe-modal/table-row'
import { CashboxSafeModalTableFooter } from '@modules/payment-registers/cashbox-safe-modal/table-footer'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { clearPaymentRegisters, paymentRegistersSelector } from '@store/slices/cash-slice'
import {
  CashboxSafeModalFilters,
  CashboxSafeModalFiltersParams,
} from '@modules/payment-registers/cashbox-safe-modal/filters'
import { endOfMonth, startOfMonth, startOfToday } from 'date-fns'
import { getPaymentRegisters } from '@store/actions/cash-actions'
import { useApiRequest } from '@components/hooks/use-api-request'
import Decimal from 'decimal.js'
import { asDecimal, formatPrice } from '@helpers/utils'
import { ColAuto } from '@hyper/col-auto'
import { IconWithText } from '@components/icon-with-text'
import { Row } from 'reactstrap'
import * as R from 'ramda'

const defaultFilters: CashboxSafeModalFiltersParams = {
  ordering: 'created',
  page: 1,
  page_size: 999999,
  search: '',
  created_after: startOfMonth(startOfToday()),
  created_before: endOfMonth(startOfToday()),
  type: null,
  created_by: null,
  kinds: [],
  skip_paginator: false,
}

const headerValues: TableHeaderValue<PaymentRegister>[] = [
  { title: 'Lp.' },
  { title: 'Data' },
  { title: 'Operacja' },
  { title: 'Dodane przez' },
  { title: 'Winien (KW)' },
  { title: 'Ma (KP)' },
  { title: 'Saldo po operacji' },
]

interface Props {
  resortId: number
}

export const CashboxSafeModalTable = ({ resortId }: Props): JSX.Element => {
  const [filters, setFilters] = React.useState<CashboxSafeModalFiltersParams>(defaultFilters)

  const paymentRegisters = useAppSelector(paymentRegistersSelector)

  const filteredPaymentRegisters = paymentRegisters.filter(paymentRegister => {
    if (!filters.created_by) return true

    if (paymentRegister.created_by) {
      return paymentRegister.created_by === filters.created_by.label
    }

    return paymentRegister.user === filters.created_by.label
  })

  const dispatch = useAppDispatch()

  React.useEffect(
    () => () => {
      dispatch(clearPaymentRegisters())
    },
    [],
  )

  const { isLoading, action: fetch } = useApiRequest(
    async () =>
      await dispatch(
        getPaymentRegisters({
          ...R.omit(['created_by'], filters),
          destination_resort: { value: resortId, label: resortId },
          view: 'cashbox',
        }),
      ),
  )

  React.useEffect(() => {
    fetch()
  }, [filters])

  const balanceBefore = useAppSelector((state: RootState) => state.cashState.balance.before)

  let balanceBeforeOperation: Decimal = asDecimal(balanceBefore)

  const total = filteredPaymentRegisters.length

  const rows = filteredPaymentRegisters.map((row, index) => {
    balanceBeforeOperation = balanceBeforeOperation.add(asDecimal(row.amount))
    return (
      <CashboxSafeModalTableRow
        lp={total - index}
        balanceBeforeOperation={balanceBeforeOperation}
        key={row.id}
        paymentRegister={row}
      />
    )
  })

  rows.reverse()

  return (
    <>
      <CashboxSafeModalFilters
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        paymentRegisters={filteredPaymentRegisters}
      />
      <Table
        className="table-hover w-100"
        headerValues={headerValues}
        isLoading={isLoading}
        filters={filters}
        showPagination={false}
        setFilters={setFilters}
        tableWrapperClassName="pb-0"
      >
        {filteredPaymentRegisters.length ? (
          rows
        ) : (
          <tr>
            <td className="text-center" colSpan={100}>
              Brak transakcji
            </td>
          </tr>
        )}
        <CashboxSafeModalTableFooter paymentRegisters={filteredPaymentRegisters} />
      </Table>
      <Row>
        <ColAuto className="col-auto ml-auto">
          <div className="d-flex align-items-center justify-content-end font-14">
            <strong>Saldo z poprzedniego okresu:</strong>
            <IconWithText
              icon="uil-usd-circle mr-2 ml-2 font-16"
              wrapperClassNames="text-semi-strong"
              text={formatPrice(balanceBefore)}
            />
          </div>
        </ColAuto>
      </Row>
    </>
  )
}
