import * as React from 'react'
import { ShopWarehouseInvoice, ShopWarehouseInvoicesFiltersParams } from '@modules/shop/models'
import { RootState, useAppSelector } from '@store/index'
import { toDefaultDateFormat } from '@helpers/date-helper'
import Table, { TableHeaderValue } from '@components/table/table'

interface ShopWarehouseInvoicesTableProps {
  invoices: ShopWarehouseInvoice[]
  setFilters: (filters: ShopWarehouseInvoicesFiltersParams) => void
  filters: ShopWarehouseInvoicesFiltersParams
  isLoading: boolean
}

const headerValues: TableHeaderValue<ShopWarehouseInvoice>[] = [
  {
    sortField: 'move__created',
    title: 'Data dostawy',
  },
  {
    sortField: 'date',
    title: 'Data FV',
  },
  {
    sortField: 'number',
    title: 'Numer faktury',
  },
  {
    sortField: 'provider__name',
    title: 'Dostawca',
  },
  {
    sortField: 'move__warehouse__name',
    title: 'Magazyn',
  },
  {
    title: '',
  },
]

export const ShopWarehouseInvoicesTable: React.FC<ShopWarehouseInvoicesTableProps> = ({
  setFilters,
  invoices,
  filters,
  isLoading,
}) => {
  const warehouses = useAppSelector((state: RootState) => state.shopState.appData?.warehouses)
  const providers = useAppSelector((state: RootState) => state.shopState.appData?.providers)

  return (
    <Table
      className="table-hover w-100"
      headerValues={headerValues}
      isLoading={isLoading}
      filters={filters}
      setFilters={setFilters}
    >
      {invoices.map(invoice => (
        <Table.Row key={invoice.id}>
          <Table.Cell>{toDefaultDateFormat(invoice.move_created)}</Table.Cell>
          <Table.Cell>{toDefaultDateFormat(invoice.date)}</Table.Cell>
          <Table.Cell>{invoice.number}</Table.Cell>
          <Table.Cell>{providers.find(provider => provider.id === invoice.provider_id)?.name}</Table.Cell>
          <Table.Cell>{warehouses.find(warehouse => warehouse.id === invoice.warehouse_id)?.name}</Table.Cell>
          <Table.Cell className="text-nowrap text-right" style={{ width: 100 }}>
            <a href={invoice.report_url} target="_blank" className="btn btn-xs btn-green">
              pobierz raport
            </a>
          </Table.Cell>
        </Table.Row>
      ))}
    </Table>
  )
}
