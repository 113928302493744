import * as React from 'react'
import { Button, ModalBody, ModalFooter } from 'reactstrap'
import { CmsResortDetails } from '@modules/cms/models'
import { useModal } from '@components/modals/use-modal'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { CmsReservationAppResortAttractionsDnd } from '@modules/cms/reservation-app/resort-modal/attractions/attractions-dnd'
import { BaseModalProps } from '@components/modals/types'

interface Props extends BaseModalProps {
  resort: CmsResortDetails
}

export const CmsReservationAppResortAttractions: React.FC<Props> = ({ resort, toggleIsVisible }) => {
  const [handleAdd] = useModal('CmsReservationAppAttractionModal', { resort })

  return (
    <>
      <ModalBody className="pt-0">
        <DndProvider backend={HTML5Backend}>
          <CmsReservationAppResortAttractionsDnd resort={resort} />
        </DndProvider>
        <p className="text-right mt-3">
          <button type="button" onClick={handleAdd} className="btn btn-sm btn-green">
            dodaj wpis
          </button>
        </p>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
      </ModalFooter>
    </>
  )
}
