import { AgreementDetails } from '@modules/hr/agreement/models'
import { UserPermission } from '@models/dashboard'
import { CustomReactSelectOption, makeDefaultSelectOption } from '@components/custom-react-select'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useAppSelector } from '@store/index'
import { resortOptionsSelector } from '@store/selectors/dashboard'
import { AgreementFormInputs } from '@modules/hr/agreement/create/modal'
import * as R from 'ramda'
import { parseISODate } from '@helpers/date-helper'
import { createSelectOption } from '@helpers/utils'
import { useCountries } from '@components/hooks/use-countries'
import { useHrAppData } from '@modules/hr/use-hr-app-data'
import { agreementCreateSteps } from '@modules/hr/agreement/create/steps/steps'
import { BaseHrFieldDefaults } from '@modules/hr/consts'

export const useAgreementWizardDefaults = (agreementDetails?: AgreementDetails) => {
  const hrAppData = useHrAppData()
  const user = useAuthenticatedUser()
  const { countriesOptions } = useCountries()

  const allResortOptions = useAppSelector(resortOptionsSelector)

  const resortOptions = user.hasPerm(UserPermission.HrAgreementCanSelectAnyResort)
    ? allResortOptions
    : (user.resorts.map(userResort =>
        allResortOptions.find(resortOption => resortOption.value === userResort.id),
      ) as CustomReactSelectOption[])

  const companiesOptions = hrAppData.companies.map(el => createSelectOption(el.name, el.id))
  const jobPositionOptions = hrAppData.job_positions.map(el => createSelectOption(el.name, el.id))
  const agentOptions = hrAppData.agents.map(el => createSelectOption(el.full_name, el.id))

  const fields = agreementCreateSteps.reduce((prev, curr) => [...prev, ...(curr.fields ?? [])], [])

  const defaultValues: AgreementFormInputs = {
    ...BaseHrFieldDefaults,
    ...(agreementDetails && R.pick<Partial<AgreementFormInputs>, AgreementDetails>(fields, agreementDetails)),
    account_number: agreementDetails?.account_number || 'PL',

    nationality: agreementDetails ? makeDefaultSelectOption(countriesOptions, agreementDetails.nationality) : null,
    birthday: agreementDetails ? parseISODate(agreementDetails.birthday) : null,

    agent: agreementDetails ? makeDefaultSelectOption(agentOptions, agreementDetails.agent) : null,
    company: agreementDetails ? makeDefaultSelectOption(companiesOptions, agreementDetails.company) : null,
    job_position: agreementDetails ? makeDefaultSelectOption(jobPositionOptions, agreementDetails.job_position) : null,
    resort: agreementDetails
      ? resortOptions.find(option => agreementDetails.resorts.includes(option.value))
      : user.resorts.length === 1
        ? resortOptions[0]
        : null,
    date_of_expiration: agreementDetails ? parseISODate(agreementDetails.date_of_expiration) : null,
    date_of_start: agreementDetails ? parseISODate(agreementDetails.date_of_start) : null,
    isFormVisible: !!agreementDetails,
  }

  return { defaultValues }
}
