import * as React from 'react'
import { useWebSocket } from '@components/hooks/use-web-socket'
import { NotificationEvents } from '@models/dashboard'
import { TechnicalOrderDetails, TechnicalOrderStatus } from '@models/technical-orders'
import { useAppDispatch } from '@store/index'
import { TECHNICAL_ORDER_TAG, technicalOrdersApi } from '@api/technical-orders'

interface Props {
  type: TechnicalOrderStatus
  resortId: number
}

export const TechnicalOrderWebsocket: React.FC<Props> = ({ resortId }) => {
  const dispatch = useAppDispatch()
  useWebSocket<TechnicalOrderDetails>(
    (message, payload) => {
      switch (message) {
        case NotificationEvents.TECHNICAL_ORDER_CHANGE:
          if (payload.resort_id === resortId) {
            try {
              dispatch(technicalOrdersApi.util.invalidateTags([TECHNICAL_ORDER_TAG]))
            } catch (e) {
              console.warn(e)
            }
            console.log('Technical order changed', payload)
          }
          break
      }
    },
    [],
    [NotificationEvents.TECHNICAL_ORDER_CHANGE],
  )
  return null
}
