import * as React from 'react'
import { Card, CardBody } from 'reactstrap'
import { ProductCardTitle } from '@components/products/product-card-title'
import { SubscriptionDetailsInformationRow } from '@modules/subscription/details/information/row'
import { HrAddress } from '@modules/hr/common/models'

interface Props {
  addressData: HrAddress
  title: string
  prefix: 'registered_' | ''
  isReadonly: boolean
  onEdit?: () => void
}

export const HrAddressDetails = React.forwardRef<HTMLElement, Props>(
  ({ addressData, title, prefix, isReadonly, onEdit }, ref) => (
    <Card innerRef={ref}>
      <CardBody>
        <ProductCardTitle title={title} isEditAllowed={!isReadonly} onEdit={onEdit} />
        <table className="table table-sm mb-0">
          <tbody>
            <SubscriptionDetailsInformationRow name="Ulica">
              {addressData[`${prefix}street`]}
            </SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="Numer domu / Numer mieszkania">
              {addressData[`${prefix}street_house`]}{' '}
              {addressData[`${prefix}street_apartment`] ? ` / ${addressData[`${prefix}street_apartment`]}` : ''}
            </SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="Miasto">
              {addressData[`${prefix}city`]} {addressData[`${prefix}postcode`]}, poczta: {addressData[`${prefix}post`]}
            </SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="Powiat">
              {addressData[`${prefix}district`]}
            </SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="Gmina">
              {addressData[`${prefix}community`]}
            </SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="Województwo">
              {addressData[`${prefix}province`]}
            </SubscriptionDetailsInformationRow>
          </tbody>
        </table>
      </CardBody>
    </Card>
  ),
)
