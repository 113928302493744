import * as React from 'react'
import Table from '@components/table/table'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { formatPriceShort } from '@helpers/utils'
import { Payment } from '@models/payments'
import { useModal } from '@components/modals/use-modal'

interface Props {
  payment: Payment
}

export const SubscriptionDetailsPaymentsRow = ({ payment }: Props): JSX.Element => {
  const [handleEdit] = useModal('SubscriptionPaymentUpdateModal', { payment })

  return (
    <Table.Row key={payment.id}>
      <Table.Cell>
        <a href={payment.urls.admin} target="_blank">
          {toDefaultDateFormat(payment.invoice_date)}
        </a>
      </Table.Cell>
      <Table.Cell onClick={handleEdit} className="cursor-pointer">
        {formatPriceShort(payment.amount)}
      </Table.Cell>
      <Table.Cell>{payment.source_display}</Table.Cell>
      <Table.Cell>{payment.user}</Table.Cell>
      <Table.Cell>{payment.description}</Table.Cell>
    </Table.Row>
  )
}
