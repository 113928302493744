import * as React from 'react'
import { Button, CustomInput, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { FormPlain } from '@hyper/forms'
import { SaveButton } from '@hyper/button'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useForm } from 'react-hook-form'
import { createReportTask } from '@store/actions/report-tasks-actions'
import { extractInnerRef } from '@helpers/forms'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { BaseModalProps } from '@components/modals/types'
import { Form } from '@hyper/forms/form'

interface FormInputs {
  is_active: boolean
}

export const ReportsGeneratorShopProduct: React.FC<BaseModalProps> = ({ toggleIsVisible }) => {
  const reportUrl = useAppSelector((state: RootState) => state.appState.appData.urls.reports.report_task_shop_product)
  const dispatch = useAppDispatch()

  const methods = useForm<FormInputs>({
    mode: 'all',
    defaultValues: {
      is_active: true,
    },
  })

  const { isLoading, action: onSubmit } = useReportFormRequest(async (payload: FormInputs) => {
    const reportTask = await dispatch(createReportTask([reportUrl, payload]))
    toggleIsVisible()

    return reportTask
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Raport produktów</ModalHeader>
      <ModalBody>
        <Row>
          <FormPlain name="only_polish_customer">
            <CustomInput
              className="cursor-pointer"
              type="checkbox"
              id="is_active"
              label="Aktywny"
              {...extractInnerRef(methods.register('is_active'))}
            />
          </FormPlain>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" label="Zleć raport" labelSaving="Zlecanie.." isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
