import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { SubscriptionCode, SubscriptionDetails } from '@modules/subscription/models'
import { Button, Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAppDispatch } from '@store/index'
import { commonObjectPost } from '@store/actions/generic-actions'
import { updateSubscriptionDetails } from '@store/slices/subscription-slice'

interface Props extends BaseModalProps {
  codes: SubscriptionCode[]
  onClose: () => void
  subscription: SubscriptionDetails
}

export const SubscriptionDetailsCodesConvertModal: React.FC<Props> = ({
  subscription,
  codes,
  onClose,
  toggleIsVisible,
}) => {
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading, action: handleClick } = useApiRequest(async () => {
    await dispatch(
      updateSubscriptionDetails(
        await commonObjectPost<SubscriptionDetails>(subscription.urls.codes_convert, {
          codes: codes.map(row => row.id),
        }),
      ),
    )

    addSuccessMessage(
      'Sukces',
      <>
        <p>Kody zostały skonwertowane i połączone w całość. Należy użyć kodu:</p>
        <strong className="h4 d-block">{codes[0].code}</strong>
        <p>w wybranej rezerwacji</p>
      </>,
    )
    toggleIsVisible()
    onClose()
  })

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>Konwersja kodów</ModalHeader>
      <ModalBody>
        <Row>
          <Col md={12}>
            <p>
              Podane poniżej kody zostaną skonwertowane. Po konwersji należy użyć kodu <strong>{codes[0].code}</strong>,
              pozostałe kody będą nieaktywne
            </p>
            <ul>
              {codes.map(row => (
                <li key={row.id}>{row.code}</li>
              ))}
            </ul>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>

        <SaveButton
          label="Konwertuj"
          labelSaving="Konwertowanie"
          onClick={handleClick}
          className="btn btn-green"
          isSaving={isLoading}
        />
      </ModalFooter>
    </>
  )
}
