import * as React from 'react'
import { TechnicalOrderDetails } from '@models/technical-orders'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { Button, ModalFooter } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import { useModal } from '@components/modals/use-modal'
import { ButtonWithIcon } from '@components/button-with-icon'

interface Props extends BaseModalProps {
  technicalOrderDetails: TechnicalOrderDetails
}

export const TechnicalOrderPreviewModalFooter: React.FC<Props> = ({ technicalOrderDetails, toggleIsVisible }) => {
  const user = useAuthenticatedUser()

  const canEdit = React.useMemo(() => {
    if (!['new', 'todo', 'ordered'].includes(technicalOrderDetails.status)) {
      return false
    }
    if (technicalOrderDetails.forwarded_to_technical_order_manager) {
      return user.isTechnicalOrderManager
    }
    return true
  }, [user.isTechnicalOrderManager, technicalOrderDetails.forwarded_to_technical_order_manager])

  const [handleAssignModal] = useModal(
    'TechnicalOrderAssignModal',
    { technicalOrders: [technicalOrderDetails] },
    {
      persist: true,
    },
  )

  const editModalName = React.useMemo(() => {
    if (technicalOrderDetails.kind === 'reception_technical_order') {
      return 'ReceptionTechnicalOrderModal'
    } else if (technicalOrderDetails.kind === 'guests_technical_order') {
      return 'GuestsTechnicalOrderModal'
    }
    return 'TechnicalOrderModal'
  }, [technicalOrderDetails.kind])

  const [handleEditModal] = useModal(
    editModalName,
    { technicalOrderDetails },
    {
      persist: true,
    },
  )
  const handleAssign = () => {
    toggleIsVisible()
    handleAssignModal()
  }

  const canAssignTechnicalOrder = canEdit && technicalOrderDetails.kind === 'technical_order'

  const handleEdit = () => {
    toggleIsVisible()
    handleEditModal()
  }

  return (
    <ModalFooter>
      {canEdit && (
        <ButtonWithIcon icon="uil-edit-alt" btnClass="mr-auto" color="light" text="Edytuj" handleClick={handleEdit} />
      )}
      <Button color="light" type="button" onClick={toggleIsVisible}>
        Zamknij
      </Button>
      {canAssignTechnicalOrder && (
        <Button color="green" type="button" onClick={handleAssign}>
          Przydziel zadanie
        </Button>
      )}
    </ModalFooter>
  )
}
