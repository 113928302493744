import * as React from 'react'
import { TechnicalOrdersOrderedDraggableTableRow } from '@modules/technical-orders/technical-orders-ordered/drag-and-drop/dragabble-row'
import { Droppable } from 'react-beautiful-dnd'
import { TechnicalOrder } from '@models/technical-orders'
import { TechnicalOrderOrderedDroppableIds } from '@modules/technical-orders/technical-orders-ordered/drag-and-drop/consts'
import { TechnicalOrdersOrderedDroppableEmptyRowPlaceholder } from '@modules/technical-orders/technical-orders-ordered/drag-and-drop/droppable-empty-row-placeholder'

interface Props {
  selectableCell: (order: TechnicalOrder) => React.ReactNode
  technicalOrders: TechnicalOrder[]
  resortId?: number
  priority: number
  isDragDisabled: boolean
  headers: React.ReactNode
  dataTestId?: string
}

export const TechnicalOrdersOrderedDroppableTable = ({
  selectableCell,
  technicalOrders,
  resortId,
  priority,
  dataTestId,
  isDragDisabled,
  headers,
}: Props): JSX.Element => (
  <table className="table table-striped w-100 table-fixed" data-testid={dataTestId}>
    {headers}
    {technicalOrders.length ? (
      <Droppable
        droppableId={
          priority > 0
            ? TechnicalOrderOrderedDroppableIds.TECHNICAL_ORDER_HIGH_PRIORITY
            : TechnicalOrderOrderedDroppableIds.TECHNICAL_ORDER_NORMAL_PRIORITY
        }
      >
        {provided => (
          <tbody {...provided.droppableProps} ref={provided.innerRef}>
            {technicalOrders.map((technicalOrder, index) => (
              <TechnicalOrdersOrderedDraggableTableRow
                key={technicalOrder.id}
                technicalOrder={technicalOrder}
                index={index}
                selectableCell={selectableCell}
                resortId={resortId}
                isDragDisabled={isDragDisabled}
              />
            ))}
            {provided.placeholder}
          </tbody>
        )}
      </Droppable>
    ) : (
      <TechnicalOrdersOrderedDroppableEmptyRowPlaceholder priority={priority} />
    )}
  </table>
)
