import * as React from 'react'
import { HouseKeepingUserReportAggregation } from '@modules/reports/housekeeping-users-report/models'
import { getHoursMinutesFromInterval, secondsToInterval } from '@helpers/date-helper'

interface Props {
  reportAggregation: HouseKeepingUserReportAggregation
  cols: string[]
}

export const HousekeepingReportTableFooter: React.FC<Props> = ({ reportAggregation, cols }) => (
  <table className="table w-100 table-borderless">
    <colgroup>
      {cols.map((col, index) => (
        <col key={index} width={col} />
      ))}
    </colgroup>
    <tbody>
      <tr>
        <td colSpan={4} className="text-right font-weight-bold vertical-align-middle">
          <span>Razem w wybranym okresie:</span>
        </td>
        <SingleCell title="Wykonanych zleceń" value={reportAggregation.total_orders} />
        <SingleCell
          title="Czas realizacji zadań w aplikacji"
          value={getHoursMinutesFromInterval(secondsToInterval(reportAggregation.total_time))}
        />
        <SingleCell title="Średnia dzienna ilość przygotowanych lokali" value={reportAggregation.total_average} />
        <SingleCell
          title="Lokali przygotowanych w zakładanym czasie"
          value={reportAggregation.total_orders_finished_in_assumed_time}
        />
        <SingleCell
          title="Czas pracy do wynagrodzenia"
          value={getHoursMinutesFromInterval(secondsToInterval(reportAggregation.total_working_time_to_pay))}
        />
      </tr>
    </tbody>
  </table>
)

const SingleCell = ({ title, value }) => (
  <td className="py-2 text-white reservation-list__summary-block">
    <span>{title}</span>
    <strong className="d-block mt-1">{value}</strong>
  </td>
)
