import * as React from 'react'
import { useDocumentTitle } from '@helpers/utils'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { PageTitle } from '@hyper/page-title'
import { Card, CardBody } from 'reactstrap'
import { CashbackVouchersTable } from '@modules/products/cashback-vouchers/table'
import { useParams } from 'react-router-dom'
import { CashbackVouchersDetailsView } from '@modules/products/cashback-vouchers/details'

export const CashbackVouchersView: React.FC = () => {
  const pageTitle = 'Vouchery rePLAY!'
  const { id } = useParams<'id'>()

  useDocumentTitle(pageTitle)
  useMenuActive(NavigationPath.CashbackVouchers)

  return (
    <>
      {id && <CashbackVouchersDetailsView id={id} />}

      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitle} />
      <Card>
        <CardBody>
          <CashbackVouchersTable />
        </CardBody>
      </Card>
    </>
  )
}
