import { ApplicationImage } from '@modules/housekeeping/models'
import { getState } from '@store/index'
import axios from 'axios'
import { multiPartHeaders } from '@api/base'

export interface HousekeepingOrdersPayload {
  resort: number
  date_after?: Date
  date_before?: Date
}

export interface HousekeepingCleaningOrdersPayload extends HousekeepingOrdersPayload {
  date?: Date
}

export const uploadApplicationImage = async (file): Promise<ApplicationImage> => {
  const url = getState().appState.appData.urls.housekeeping.application_image

  const formData = new FormData()
  formData.append('image', file)
  const { data } = await axios.post(url, formData, {
    headers: multiPartHeaders,
  })

  return data
}
