import * as React from 'react'
import { Button } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'

export const SubscriptionContractCreateButton: React.FC = () => {
  const [toggleModal] = useModal('SubscriptionContractCreateModal')

  return (
    <Button onClick={toggleModal} color="green-dark" type="button" role="subscription-create-button">
      <i className="mdi mdi-plus-circle mr-1" />
      Sprzedaj subskrypcję
    </Button>
  )
}
