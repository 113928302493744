import * as React from 'react'
import { asDecimal } from '@helpers/utils'
import { ReceptionBookingNewFeedingPriceListRows } from '@modules/reception/checkin/step-feeding/new-feeding/pricelist/reception-booking-new-feeding-pricelist-rows'
import { FeedingPriceListItem } from '@modules/reception/checkin/step-feeding/new-feeding/pricelist/use-new-feeding-pricelist'

interface Props {
  prices: FeedingPriceListItem | null
}

export const ReceptionBookingNewFeedingPricelistCatalogPrices = ({ prices }: Props): JSX.Element | null => {
  if (!prices) return null

  const totalPrice = asDecimal(prices.breakfast_price_brutto_in_hb ?? 0)
    .plus(prices.dinner_price_brutto_in_hb ?? 0)
    .toString()

  return (
    <ReceptionBookingNewFeedingPriceListRows
      name={prices.booking_guest_type_name}
      breakfast={prices.breakfast_price_brutto}
      dinner={prices.dinner_price_brutto}
      total={totalPrice}
    />
  )
}
