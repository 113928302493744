import * as React from 'react'
import { asDecimal, sumDecimalArray } from '@helpers/utils'
import { ExpandArrow } from '@components/hooks/use-expandable-items'
import { Collapse } from 'reactstrap'
import { ReceptionBookingGuest } from '@models/booking'
import { NewFeedingCalculationPriceItem } from '@modules/reception/checkin/step-feeding/new-feeding/models'
import { ReceptionBookingNewFeedingSummaryPriceItem } from '@modules/reception/checkin/step-feeding/new-feeding/summary/reception-booking-new-feeding-summary-price-item'
import { useAppSelector } from '@store/index'
import { selectIsDiscountPreviewActive } from '@store/slices/new-feeding-slice'
import { ReceptionBookingNewFeedingPrice } from '@modules/reception/checkin/step-feeding/new-feeding/reception-booking-new-price'

interface Props {
  isExpanded: boolean
  onExpand: () => void
  priceItems: NewFeedingCalculationPriceItem[]
  guest: ReceptionBookingGuest | undefined
}

export const ReceptionBookingNewFeedingSummaryGuestListItem = ({
  isExpanded,
  onExpand,
  guest,
  priceItems,
}: Props): JSX.Element | null => {
  const isDiscountPreviewActive = useAppSelector(selectIsDiscountPreviewActive)
  if (!guest) return null

  const totalPrice = sumDecimalArray(
    priceItems.map(item => asDecimal(isDiscountPreviewActive ? item.discount_amount : item.price_brutto)),
  ).toString()

  return (
    <li className="ml-2 fw-semi-bold">
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          {guest.name} <ExpandArrow isExpanded={isExpanded} onExpand={onExpand} />
        </div>
        <ReceptionBookingNewFeedingPrice price={totalPrice} />
      </div>
      <Collapse isOpen={isExpanded} className="col-12 pr-0">
        <ul className="p-0 list-unstyled">
          {priceItems.map((item: NewFeedingCalculationPriceItem) => (
            <ReceptionBookingNewFeedingSummaryPriceItem
              key={item.keyword}
              priceItem={item}
              isDiscountPreviewActive={isDiscountPreviewActive}
            />
          ))}
        </ul>
      </Collapse>
    </li>
  )
}
