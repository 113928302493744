import * as React from 'react'
import { CustomInput } from 'reactstrap'
import { ReceptionBookingGuest } from '@models/booking'
import { ReceptionBand } from '@models/reception'
import { extractInnerRef } from '@helpers/forms'
import { formatDate } from '@helpers/date-helper'
import { useFormContext } from 'react-hook-form'

interface ReceptionBookingCheckInStepAccessBandsRowProps {
  guest: ReceptionBookingGuest
  disabled: boolean
  band: ReceptionBand
}

export const ReceptionBookingCheckInStepAccessBandsRow: React.FC<ReceptionBookingCheckInStepAccessBandsRowProps> = ({
  guest,
  band,
  disabled,
}) => {
  const { register } = useFormContext()
  return (
    <tr className="reception__booking-check-in__access-step__table-row">
      <td className="text-semi-strong reception__booking-check-in__access-step__table-column pl-0">
        <div className="ml-3">
          <CustomInput
            disabled={disabled}
            type="checkbox"
            id={band.id}
            label={guest.name}
            value={band.id}
            name="bands"
            {...extractInnerRef(register('bands'))}
          />
        </div>
      </td>
      <td className="text-semi-strong reception__booking-check-in__access-step__table-column pl-0">
        {band.external_id}
        <div className="font-10">
          {band.state === 'RELEASED' && (
            <>
              {(band.datetime_to || band.datetime_from) && 'Aktywna'}
              {band.datetime_from && ` od ${formatDate(band.datetime_from, 'dd MMMM HH:mm')}`}
              {band.datetime_to && ` do ${formatDate(band.datetime_to, 'dd MMMM HH:mm')}`}
            </>
          )}
        </div>
      </td>
    </tr>
  )
}
