import * as React from 'react'
import { ReservationDropdown } from '@modules/reservations/details/common/reservation-dropdown'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { useApiRequest } from '@components/hooks/use-api-request'
import { ReceptionBookingDetails, ReceptionBookingLoadNote } from '@models/reception'
import { updateReceptionBookingDetails } from '@store/actions/reception-actions'
import { commonObjectDelete, commonObjectPost } from '@store/actions/generic-actions'
import { useAppDispatch } from '@store/index'
import { useModal } from '@components/modals/use-modal'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { getBookingLoadNotes } from '@store/actions/reservation-actions'

interface Props {
  note: ReceptionBookingLoadNote
  booking: ReceptionBookingDetails
}

export const ReservationLoadNotesRowActions = ({ note, booking }: Props): JSX.Element => {
  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()

  const [showPreviewModal] = useModal('ReservationLoadNotePreviewModal', { note })

  const { action: handleRemove, isLoading: isRemoving } = useApiRequest(async () => {
    await dispatch(updateReceptionBookingDetails(await commonObjectDelete<ReceptionBookingDetails>(note.urls.details)))
    dispatch(await getBookingLoadNotes(booking))
  })

  const { action: handlePrint } = useApiRequest(async () => {
    await commonObjectPost(note.urls.print)
    addSuccessNotification('Drukowanie zostało zlecone')
  })

  return (
    <ReservationDropdown toggleClassName="p-0">
      <ReservationDropdown.Item className="p-0">
        <CommonObjectConfirmAction handleAccept={handleRemove} isLoading={isRemoving}>
          <div className="pl-2 py-1">Usuń</div>
        </CommonObjectConfirmAction>
      </ReservationDropdown.Item>
      <ReservationDropdown.Item className="p-0">
        <a href={note.urls.admin} target="_blank" className="text-decoration-none d-block text-default pl-2 py-1">
          Edytuj
        </a>
      </ReservationDropdown.Item>
      <ReservationDropdown.Item onClick={handlePrint}>Drukuj</ReservationDropdown.Item>
      <ReservationDropdown.Item onClick={showPreviewModal}>Podgląd</ReservationDropdown.Item>
    </ReservationDropdown>
  )
}
