import * as React from 'react'
import { PaymentDayUserCloseReport, PaymentDayUserReport } from '@models/payments'
import { IconWithText } from '@components/icon-with-text'
import { Col, Row } from 'reactstrap'
import { formatPrice } from '@helpers/utils'
import { useModal } from '@components/modals/use-modal'
import { PaymentRegistersFiltersParams } from '@modules/payment-registers/list/filters'

interface Props {
  dayCloseReport: PaymentDayUserCloseReport
  paymentDayUserReport: PaymentDayUserReport
}

export const UserCashBoxCloseDayCashTransactions = ({ paymentDayUserReport, dayCloseReport }: Props): JSX.Element => {
  const [handleTransactionListOpen] = useModal('TransactionListModal')

  const baseParams: Partial<PaymentRegistersFiltersParams> = {
    payment_day_user_report: paymentDayUserReport.id,
    source: { value: 'cash', label: 'cash' },
  }

  const handleKpOpen = (event: React.SyntheticEvent) =>
    handleTransactionListOpen(event, {
      title: 'Sprzedaż KP gotówka',
      params: { ...baseParams, type: { value: 'KP', label: 'KP' } } as Partial<PaymentRegistersFiltersParams>,
    })
  const handleKwOpen = (event: React.SyntheticEvent) =>
    handleTransactionListOpen(event, {
      title: 'Sprzedaż KW gotówka',
      params: { ...baseParams, type: { value: 'KW', label: 'KW' } } as Partial<PaymentRegistersFiltersParams>,
    })

  return (
    <div className="p-2 pb-1">
      <IconWithText
        icon="uil-money-bill ml-1 font-15"
        text="Gotówka"
        wrapperClassNames="flex-row-reverse text-strong mb-2"
      />
      <Row className="mb-2 align-items-center">
        <Col md={8}>
          <span className="font-11">Stan początkowy kasetki:</span>
        </Col>
        <Col md={4} className="text-right text-strong">
          {formatPrice(dayCloseReport.cash_start_amount)}
        </Col>
      </Row>
      <Row className="mb-2 align-items-center">
        <Col md={8}>
          <strong className="text-semi-strong d-block mb-1">Wpłaty (KP):</strong>
          <span className="font-11">
            {dayCloseReport.cash_kp_count} transakcji{' '}
            <strong onClick={handleKpOpen} className="cursor-pointer">
              zobacz
            </strong>
          </span>
        </Col>
        <Col md={4} className="text-right text-strong font-15">
          {formatPrice(dayCloseReport.cash_kp_amount)}
        </Col>
      </Row>

      <Row className="mb-2 align-items-center">
        <Col md={8}>
          <strong className="text-semi-strong d-block mb-1">Wypłaty (KW):</strong>
          <span className="font-11">
            {dayCloseReport.cash_kw_count} transakcji{' '}
            <strong onClick={handleKwOpen} className="cursor-pointer">
              zobacz
            </strong>
          </span>
        </Col>
        <Col md={4} className="text-right text-strong font-15">
          {formatPrice(dayCloseReport.cash_kw_amount)}
        </Col>
      </Row>
    </div>
  )
}
