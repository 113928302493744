import * as React from 'react'
import { useAppDispatch, useAppSelector } from '@store/index'
import { getHousekeepingCleaningOrders } from '@store/actions/housekeeping-actions'
import { HousekeepingCleaningOrderType } from '@modules/housekeeping/models'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { useApiRequest } from '@components/hooks/use-api-request'
import { cleaningOrdersSelector } from '@store/slices/housekeeping-slice'
import { HousekeepingCleaningOrdersOrderedDroppableTables } from '@modules/housekeeping/cleaning-orders/ordered/droppable-tables'

interface HousekeepingCleaningOrdersOrderedProps {
  resortId: number
}

export const HousekeepingCleaningOrdersOrdered: React.FC<HousekeepingCleaningOrdersOrderedProps> = ({ resortId }) => {
  const cleaningOrders = useAppSelector(cleaningOrdersSelector)

  const dispatch = useAppDispatch()
  const { isLoading, action: fetchHousekeepingCleaningOrders } = useApiRequest(
    async () =>
      await dispatch(getHousekeepingCleaningOrders([{ resort: resortId }, HousekeepingCleaningOrderType.ORDERED])),
  )

  React.useEffect(() => {
    fetchHousekeepingCleaningOrders()
  }, [resortId])

  return (
    <LoaderPlaceholder content={!isLoading}>
      <HousekeepingCleaningOrdersOrderedDroppableTables cleaningOrders={cleaningOrders} resortId={resortId} />
    </LoaderPlaceholder>
  )
}
