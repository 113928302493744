import * as React from 'react'
import { ButtonWithIcon } from '@components/button-with-icon'
import { asDecimal, formatPrice } from '@helpers/utils'
import { ReceptionBookingClientWallet } from '@models/reception'
import classNames from 'classnames'
import { CartPaymentMethod } from '@modules/reception/cart/models'
import { UncontrolledTooltip } from 'reactstrap'
import { CombinedCartItemsPrice } from '@modules/reception/cart/utils'

interface Props {
  onPaymentSelection: (method: CartPaymentMethod, context: ReceptionBookingClientWallet) => void
  wallets: ReceptionBookingClientWallet[]
  isDisabled: boolean
  combinedCartPrice: CombinedCartItemsPrice
}

export const ReceptionBookingCartPaymentWallet = ({
  onPaymentSelection,
  wallets,
  isDisabled,
  combinedCartPrice,
}: Props): JSX.Element => {
  const isWalletDisabled = (wallet: ReceptionBookingClientWallet) =>
    asDecimal(wallet.available_amount).lt(combinedCartPrice.originalTotalPrice)

  const isPriceInformationShowed = (wallet: ReceptionBookingClientWallet) =>
    asDecimal(combinedCartPrice.totalPrice).lt(wallet.available_amount) &&
    asDecimal(combinedCartPrice.originalTotalPrice).gt(wallet.available_amount)

  return (
    <div className={classNames('px-3 bg-white-smoke border border-top-0 pt-2 pb-3', { 'not-clickable': isDisabled })}>
      <strong className="font-16">Płatność voucherem:</strong>
      {wallets.map((wallet: ReceptionBookingClientWallet) => (
        <ButtonWithIcon
          disabled={isWalletDisabled(wallet)}
          key={wallet.id}
          icon={classNames('uil-tag-alt lh-initial font-14', { 'opacity-75': isPriceInformationShowed(wallet) })}
          textClass="d-block w-100"
          color="warning"
          btnClass={classNames('btn-warning d-block w-100 text-left font-13 py-1', {
            'pe-none': isPriceInformationShowed(wallet),
          })}
          handleClick={() => onPaymentSelection(wallet.voucher_kind, wallet)}
          text={
            <WalletButtonContent
              wallet={wallet}
              isPriceInformationShowed={isPriceInformationShowed(wallet)}
              priceToPay={combinedCartPrice.originalTotalPrice}
            />
          }
        />
      ))}
    </div>
  )
}

const WalletButtonContent = ({ wallet, isPriceInformationShowed, priceToPay }) => (
  <div className="d-flex align-items-center justify-content-between">
    <div className={classNames({ 'pe-none opacity-75': isPriceInformationShowed })}>
      <strong className="font-13">{wallet.name}</strong>
      <span className="d-block font-11 font-weight-light">
        Dostępne środki: <strong>{formatPrice(wallet.available_amount)}</strong> z {formatPrice(wallet.start_amount)}
      </span>
    </div>
    {isPriceInformationShowed && (
      <>
        <i
          className="ml-1 uil-info-circle text-danger font-16 mr-1 ml-4 pe-auto"
          id={`tooltip-${wallet.id}`}
          onClick={(event: React.MouseEvent) => event.stopPropagation()}
        />
        <UncontrolledTooltip
          placement="top"
          target={`tooltip-${wallet.id}`}
          style={{ maxWidth: 300, padding: '10px 15px 10px 15px' }}
        >
          <div className="font-12 text-left">
            Brak wystarczających środków na voucherze.
            <p className="mb-0 mt-1">
              Dokonując zakupu voucherem Gość zapłaci kwotę bez rabatów w
              <strong className="text-nowrap"> wysokości {formatPrice(priceToPay)}</strong>
            </p>
          </div>
        </UncontrolledTooltip>
      </>
    )}
  </div>
)
