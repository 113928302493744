import { AsyncThunkParams, RootState } from '@store/index'
import { setDashboardStandardPaginator } from '@store/actions/dashboard-actions'
import { commonObjectGet, commonObjectPost } from '@store/actions/generic-actions'
import { RcpEventDay, RcpGroup, RcpMachine, RcpMonth, RcpUser, RcpUserDetail } from '@models/rcp'
import { createAsyncThunk } from '@reduxjs/toolkit'

export interface RcpUsersFilters {
  user_ids?: number[]
  date?: string
  resort?: string
  season?: string
  month?: string
  work_time?: string
  only_active?: boolean
  do_filter?: boolean
}

interface UserEventsResponse {
  events_days: RcpEventDay[]
  months: RcpMonth[]
  machines: RcpMachine[]
}

export const getUserEvents = createAsyncThunk<UserEventsResponse, string, AsyncThunkParams>(
  'rcp/getUserEvents',
  async (url, { getState }) => await commonObjectGet<UserEventsResponse>(url, getState().rcpState.userEventsDaysFilter),
)

export const getUserEventDayDetails = createAsyncThunk<RcpEventDay, string>(
  'rcp/getUserEventDayDetails',
  async url => await commonObjectGet<RcpEventDay>(url),
)

interface RcpUsersResult {
  users: RcpUser[]
  groups: RcpGroup[]
}

export const getRcpUsers = createAsyncThunk<RcpUsersResult, Partial<RcpUsersFilters>, AsyncThunkParams>(
  'rcp/getRcpUsers',
  async (params, { getState, dispatch }) => {
    const url = getState().appState.appData.urls.rcp.users
    const data = await commonObjectPost<RcpUsersResult>(url, {
      ...params,
      resort: params.resort === 'Wybierz' ? undefined : params.resort,
    })
    dispatch(setDashboardStandardPaginator({ count: data.users.length }))

    return data
  },
)

interface RcpUserDetailsResult {
  user: RcpUserDetail
  groups: RcpGroup[]
}

export const getRcpUserDetail = createAsyncThunk<RcpUserDetailsResult, string, { state: RootState }>(
  'rcp/getRcpUserDetail',
  async (id, { getState }) =>
    await commonObjectGet<RcpUserDetailsResult>(`${getState().appState.appData.urls.rcp.users}${id}/`),
)
