import * as React from 'react'
import { PageTitle } from '@hyper/page-title'
import { useDocumentTitle } from '@helpers/utils'
import { Card, CardBody } from 'reactstrap'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { CouponsClientsFilters } from '@modules/coupons/list/clients/coupons-clients-filters'
import { CouponsClientsTable } from '@modules/coupons/list/clients/coupons-clients-table'
import { useGetCouponClientsQuery } from '@api/coupons'
import { CouponClient } from '@modules/coupons/models'
import { BaseTableFilters } from '@components/table/use-handle-table-filters-change'
import { useQueryWithPaginationResponse } from '@components/hooks/use-query-hooks'

const pageTitle = 'Kupony - Lista'

const defaultFilters: BaseTableFilters = {
  ordering: '-created',
  page: 1,
  page_size: 10,
  search: '',
}

const emptyTable = []

export const CouponsListView: React.FC = () => {
  const [filters, setFilters] = React.useState<BaseTableFilters>(defaultFilters)

  useDocumentTitle(pageTitle)
  useMenuActive(NavigationPath.CouponsList)

  const { data: couponClients = emptyTable, isLoading } = useQueryWithPaginationResponse<CouponClient[]>(
    useGetCouponClientsQuery(filters),
  )

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitle} />
      <Card>
        <CardBody>
          <CouponsClientsFilters filters={filters} setFilters={setFilters} defaultFilters={defaultFilters} />
          <CouponsClientsTable
            isLoading={isLoading}
            filters={filters}
            setFilters={setFilters}
            couponClients={couponClients}
          />
        </CardBody>
      </Card>
    </>
  )
}
