import * as React from 'react'
import { PackageSummarySection, PackageSummarySectionDataRow } from '@modules/package/create/package-summary-section'
import { useFormContext } from 'react-hook-form'
import { ClientOptionPrice, ClientUser } from '@models/clients'
import { Row } from 'reactstrap'
import { ProductSaleSummaryInvoice } from '@components/sale/product-sale-summary-invoice'
import { BookingOptionCreateFormInputs } from '@modules/promotions/booking-options/create/booking-option-create-dialog'
import { useGetAvailableClientOptionsQuery } from '@api/booking-options'
import { TypedQueryResult } from '@api/base'

interface Props {
  client: ClientUser | undefined
}

export const BookingOptionCreateDialogSummaryStep = ({ client }: Props): JSX.Element => {
  const { data: options } = useGetAvailableClientOptionsQuery() as TypedQueryResult<ClientOptionPrice[]>

  const methods = useFormContext<BookingOptionCreateFormInputs>()
  const data = methods.getValues()

  const selectedOptions = Object.entries(data.options).reduce(
    (selected, [kind, { checked, amount }]) =>
      checked
        ? [...selected, { name: options?.find(option => option.kind === kind)?.kind_display ?? '', amount }]
        : selected,
    [],
  )

  return (
    <div className="pb-2">
      <h4 className="mt-0 text-secondary">Podsumowanie sprzedaży</h4>
      <Row>
        <PackageSummarySection title="Dane klienta" className="col-6">
          <PackageSummarySectionDataRow title="Imię i nazwisko:" content={client?.name} />
          <PackageSummarySectionDataRow title="Adres e-mail:" content={client?.email} />
          <PackageSummarySectionDataRow title="Numer telefonu:" content={client?.phone} />
        </PackageSummarySection>
        {data.invoice_nip && <ProductSaleSummaryInvoice />}
      </Row>

      <div className="dropdown-divider mx-n2" />

      <Row>
        <PackageSummarySection title="Parametry oferty" className="col-12">
          <PackageSummarySectionDataRow
            titleFlex="col-3"
            contentFlex="col-9"
            title="Wybrane opcje:"
            content={
              <ul className="list-unstyled mb-0">
                {selectedOptions.map(option => (
                  <li key={option.name}>
                    - {option.amount}x {option.name}
                  </li>
                ))}
              </ul>
            }
          />
        </PackageSummarySection>
      </Row>

      <div className="dropdown-divider mx-n2" />

      <Row>
        <PackageSummarySection title="Informacje handlowe" className="col-6">
          <PackageSummarySectionDataRow title="Sprzedawca:" content={data.seller?.label ?? 'Brak'} />
        </PackageSummarySection>
      </Row>
      <div className="dropdown-divider mx-n2" />
      <Row>
        <PackageSummarySection title="Link do płatności" className="col-12">
          <p className="opacity-75">
            Link do opłacenia sprzedaży zostanie wysłany na adres e-mail Gościa{' '}
            <strong className="text-dark">{client?.email}</strong>
          </p>
        </PackageSummarySection>
      </Row>
    </div>
  )
}
