import * as React from 'react'
import { TransferPackageDetails } from '@modules/accounting/refund/models'
import { TransferPackageEditTransfer } from '@modules/accounting/refund/transfer-packages/edit/transfer'

interface TransferPackageEditModalFormProps {
  transferPackageDetails: TransferPackageDetails
  setTransferPackageDetails: (TransferPackageDetails) => void
}

export const TransferPackageEditModalForm: React.FC<TransferPackageEditModalFormProps> = ({
  transferPackageDetails,
  setTransferPackageDetails,
}) => (
  <div>
    {transferPackageDetails?.transfers.map(transfer => (
      <TransferPackageEditTransfer
        setTransferPackageDetails={setTransferPackageDetails}
        key={transfer.id}
        transfer={transfer}
      />
    ))}
  </div>
)
