import * as React from 'react'
import { Button, ModalFooter } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { CmsReservationAppAccommodationTypeAttributesRow } from '@modules/cms/reservation-app/accommodation-type-modal/attributes-row'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useFormRequest } from '@components/hooks/use-api-request'
import { SaveButton } from '@hyper/button'
import { commonObjectPut } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { AccommodationTypeAttributes, CmsAccommodationTypeDetails } from '@modules/cms/models'
import { BaseModalProps } from '@components/modals/types'

interface Props extends BaseModalProps {
  accommodationTypeDetails: CmsAccommodationTypeDetails
  setAccommodationTypeDetails: (accommodationTypesDetails: CmsAccommodationTypeDetails) => void
}

interface FormInputsAttribute {
  key: string
  value: string
  description: string
}

interface FormInputs {
  attributes: FormInputsAttribute[]
}

export const CmsReservationAppAccommodationTypeAttributes: React.FC<Props> = ({
  toggleIsVisible,
  setAccommodationTypeDetails,
  accommodationTypeDetails,
}) => {
  const methods = useForm<FormInputs>({ defaultValues: accommodationTypeDetails })
  const { addSuccessNotification } = useNotificationHook()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    setAccommodationTypeDetails(
      await commonObjectPut<CmsAccommodationTypeDetails>(accommodationTypeDetails.urls.cms_details, {
        ...accommodationTypeDetails,
        ...payload,
      }),
    )
    addSuccessNotification('Zmiany zostały zapisane')
  }, methods.setError)

  const { append, remove, fields: attributes } = useFieldArray({ control: methods.control, name: 'attributes' })

  const handleAdd = () => append({} as AccommodationTypeAttributes)

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalBody className="pt-0">
          <table className="table">
            <tbody>
              {attributes.map((attribute, index) => (
                <CmsReservationAppAccommodationTypeAttributesRow key={attribute.id} index={index} remove={remove} />
              ))}
            </tbody>
          </table>
          <p className="text-right">
            <button className="btn-pure text-dark text-semi-strong" type="button" onClick={handleAdd}>
              <i className="mdi mdi-plus" /> Dodaj atrybut
            </button>
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="light" type="button" onClick={toggleIsVisible}>
            Zamknij
          </Button>
          <SaveButton className="btn btn-green" isSaving={isLoading} />
        </ModalFooter>
      </form>
    </FormProvider>
  )
}
