import * as React from 'react'
import { SaveButton } from '@hyper/button'
import { BaseModalProps } from '@components/modals/types'
import { UpSellUser } from '@models/upselling'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { Form } from '@hyper/forms/form'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { useHandleRtkQueryError } from '@components/hooks/use-handle-rtk-error'
import { useUpdateUpSelUserMutation } from '@api/upSellUsers'

interface Props extends BaseModalProps {
  upSellUser: UpSellUser
}

interface FormInputs {
  date_from: string
  date_to: string
}

export const UpSellUserModal: React.FC<Props> = ({ upSellUser, toggleIsVisible }) => {
  const methods = useForm<FormInputs>()
  const { addSuccessMessage } = useNotificationHook()

  const [updateUpSellPoll, { error, isError, isLoading }] = useUpdateUpSelUserMutation()
  useHandleRtkQueryError(error, isError, methods.setError)

  const onSubmit = async (payload: FormInputs) => {
    await updateUpSellPoll({ url: upSellUser.urls.generate_booking, data: payload }).unwrap()
    addSuccessMessage('Sukces', 'Rezerwacje zostały wygenerowane')

    toggleIsVisible()
  }

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>
        Wygeneruj rezerwacje dla {upSellUser.first_name} {upSellUser.last_name}
      </ModalHeader>
      <ModalBody>
        <DateFromToInput label="Zakres dat" startDateName="date_from" endDateName="date_to" colSize={12} />
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>

        <SaveButton className="btn btn-green" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
