import { CustomReactSelectOption } from '@components/custom-react-select'
import { BaseUrls } from '@models/base'

interface BlockedApartment {
  id: number
  name: string
}

interface BlockedBooking {
  id: number
  urls: BaseUrls
}

export interface BlockadeResultSuccess {
  apartment: BlockedApartment
  booking: BlockedBooking
}

interface BlockadeResultError {
  apartment: BlockedApartment
}

export interface BlockadeResult {
  success: BlockadeResultSuccess[]
  errors: BlockadeResultError[]
  error?: string
}

export type BlockadeDatesKind = 'empty' | 'selected'

export interface SelectedLocalRow {
  accommodation: CustomReactSelectOption<string, number> | null
  local_number: CustomReactSelectOption<string, number> | null
}

export const KindOptions: CustomReactSelectOption[] = [
  { value: 'booking', label: 'Booking' },
  { value: 'employee', label: 'Pracownicza' },
  { value: 'cafeteria', label: 'Kafeteria' },
  { value: 'event', label: 'Event' },
  { value: 'other', label: 'Inne' },
]
