import * as React from 'react'
import { getBenefitProgramStatusColor, getBenefitProgramStatusIcon } from '@modules/benefit-program/helpers'
import { BenefitProgram } from '@modules/benefit-program/models'
import { BadgeWithIcon } from '@components/badges/BadgeWithIcon'

interface Props {
  benefitProgram: BenefitProgram
}

export const BenefitProgramStatusBadge: React.FC<Props> = ({ benefitProgram }) => (
  <div>
    <BadgeWithIcon
      icon={getBenefitProgramStatusIcon(benefitProgram.status)}
      title={benefitProgram.status_display}
      variant={getBenefitProgramStatusColor(benefitProgram.status)}
    />
  </div>
)
