import * as React from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import { Resort } from '@models/booking'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'

interface ResortTabsProps {
  url: string
  resortId: number
  resorts?: Resort[]
  children?: React.ReactNode
  showAll?: boolean
}

export const ResortTabs: React.FC<ResortTabsProps> = ({ showAll, resortId, url, resorts, children }) => {
  const user = useAuthenticatedUser()

  const resortsToShow = [...(resorts || user.resorts)]

  if (showAll) {
    resortsToShow.unshift({ id: 0, name: 'Wszystkie' } as Resort)
  }

  return (
    <Nav tabs={true} className="nav-bordered mb-3 font-14">
      {resortsToShow.map(resort => (
        <ResortTab key={resort.id} active={resort.id === resortId} resort={resort} url={url} />
      ))}
      {children}
    </Nav>
  )
}

interface ResortTabProps {
  resort: Resort
  active: boolean
  url: string
}

export const ResortTab: React.FC<ResortTabProps> = ({ resort, active, url }) => {
  const navigate = useNavigate()

  const handleChange = (event: React.MouseEvent, resortId: number) => {
    event.preventDefault()
    navigate(url.replace(':resort_id', String(resortId)))
  }

  return (
    <NavItem key={resort.id}>
      <NavLink className="cursor-pointer" active={active} onClick={(e: React.MouseEvent) => handleChange(e, resort.id)}>
        {resort.name}
      </NavLink>
    </NavItem>
  )
}
