import * as React from 'react'
import classNames from 'classnames'
import Spinner from '@hyper/spinner'

interface Props {
  children: React.ReactNode
  isLoading: boolean
}

export const ReceptionBookingCartLoader = ({ children, isLoading }: Props): JSX.Element => (
  <>
    <div className={classNames('reception-booking-cart__loader h-100', { 'is-loading': isLoading })}>{children}</div>
    <div className={classNames('reception-booking-cart__loader__info bg-dark-light', { 'is-loading': isLoading })}>
      <Spinner size="md" color="white" className="d-block mb-3 mt-2 mx-auto" />
      <strong className="font-18">Trwa proces płatności</strong>
      <span className="font-12 d-block text-center mt-2">
        Dokończ lub anuluj płatność, <br />
        aby kontynuować
      </span>
    </div>
  </>
)
