import { NavigationPath } from '@models/routes'
import { RootState, useAppSelector } from '@store/index'
import * as React from 'react'
import { LayoutWrapper } from '@components/layout'
import ErrorBoundary from '@components/error-boundary'
import { Navigate } from 'react-router-dom'

interface Props {
  component: React.ComponentType<any>
  isLayout: boolean
  layoutWrapper?: any
  redirectTo?: NavigationPath
  componentParams?: any
}

export const PrivateRoute = (props: Props) => {
  const { isFailure, isAuthenticated } = useAppSelector((state: RootState) => state.appState)

  const Component = props.component
  const Wrapper = props.layoutWrapper ? props.layoutWrapper : LayoutWrapper

  return isAuthenticated && !isFailure ? (
    props.isLayout ? (
      <Wrapper>
        <ErrorBoundary>
          <Component {...props} {...props.componentParams} />
        </ErrorBoundary>
      </Wrapper>
    ) : (
      <ErrorBoundary>
        <Component {...props} {...props.componentParams} />
      </ErrorBoundary>
    )
  ) : (
    <Navigate
      replace={false}
      to={isFailure ? NavigationPath.Failure : props.redirectTo ? props.redirectTo : NavigationPath.Unauthorized}
    />
  )
}
