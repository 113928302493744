import * as React from 'react'
import { StatsSaleItemDashboardFiltersParams, StatsSaleItemDashboardState } from '@modules/reports/models'
import { RootState, useAppSelector } from '@store/index'
import { Row } from 'reactstrap'
import { StatsSaleItemDashboardRecentlySold } from '@modules/reports/stats-sale-item/dashboard/recently-sold'
import { StatsSaleItemDashboardFilters } from '@modules/reports/stats-sale-item/dashboard/filters'
import { endOfMonth, startOfMonth, startOfToday } from 'date-fns'
import { formatDate } from '@helpers/date-helper'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectGet } from '@store/actions/generic-actions'

export const StatsSaleItemDashboard: React.FC = () => {
  const [dashboardState, setDashboardState] = React.useState<undefined | StatsSaleItemDashboardState>(undefined)
  const url = useAppSelector((state: RootState) => state.appState.appData.urls.reports.stats_sale_item_dashboard)

  const defaultFilters: StatsSaleItemDashboardFiltersParams = {
    sell_date_after: startOfMonth(startOfToday()),
    sell_date_before: endOfMonth(startOfToday()),
  }
  const [filters, setFilters] = React.useState<StatsSaleItemDashboardFiltersParams>(defaultFilters)

  const { action: fetchDashboardState } = useApiRequest(async () => {
    const statsSaleItemDashboardState = await commonObjectGet<StatsSaleItemDashboardState>(url, {
      params: {
        ...filters,
        sell_date_before: formatDate(filters.sell_date_before),
        sell_date_after: formatDate(filters.sell_date_after),
      },
    })
    setDashboardState(statsSaleItemDashboardState)
  })

  React.useEffect(() => {
    fetchDashboardState()
  }, [filters])

  return (
    <>
      <StatsSaleItemDashboardFilters filters={filters} setFilters={setFilters} defaultFilters={defaultFilters} />

      <Row className="mt-3">
        <StatsSaleItemDashboardRecentlySold data={dashboardState?.recently_sold || []} />
      </Row>
    </>
  )
}
