import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { ReservationCard } from '@modules/reservations/details/common/reservation-card'
import { ButtonWithIcon } from '@components/button-with-icon'
import { asDecimal, formatPrice, sumDecimalArray } from '@helpers/utils'
import { ReservationExtraChargesRow } from '@modules/reservations/details/extra-charges/row'
import { useModal } from '@components/modals/use-modal'

interface Props {
  booking: ReceptionBookingDetails
}

export const ReservationExtraCharges = ({ booking }: Props): JSX.Element => {
  const totalPriceBrutto = sumDecimalArray(booking.extracharges.map(row => asDecimal(row.price_brutto)))

  const [handleClick] = useModal('ReservationExtraChargesModal', { booking })

  return (
    <ReservationCard
      title="Dopłaty"
      cardActions={
        booking.status !== 'close' && (
          <ButtonWithIcon text="Dodaj" icon="uil-plus" color="light" handleClick={handleClick} />
        )
      }
    >
      {booking.extracharges.length ? (
        <table className="table table-sm">
          <thead>
            <tr>
              <th className="border-0">Nazwa</th>
              <th className="border-0">Data dodania</th>
              <th className="border-0">Kwota</th>
              <th className="border-0" />
            </tr>
          </thead>
          <tbody>
            {booking.extracharges.map(row => (
              <ReservationExtraChargesRow key={row.id} extraCharge={row} />
            ))}
            <tr>
              <td />
              <td className="text-strong text-right">razem</td>
              <td className="text-strong">{formatPrice(totalPriceBrutto.toString())}</td>
              <td width={10} />
            </tr>
          </tbody>
        </table>
      ) : (
        <p className="text-center">Brak dopłat</p>
      )}
    </ReservationCard>
  )
}
