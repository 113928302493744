import * as React from 'react'
import { Label, Row } from 'reactstrap'
import { useFormContext } from 'react-hook-form'
import { ClientSelect } from '@components/client-select'
import { ClientUser } from '@models/clients'
import { useModal } from '@components/modals/use-modal'
import { InvoiceForm } from '@components/invoice-form'
import { createInvoiceData } from '@components/products/product-invoice-card'
import { FormPlain } from '@hyper/forms'
import { useGroupedSources } from '@components/hooks/use-grouped-sources'
import { useGroupedSellers } from '@components/hooks/use-grouped-sellers'
import DatePickerInput from '@components/date/date-picker-input'
import { startOfToday } from 'date-fns'
import { SubscriptionContractCreateFormInputs } from '@modules/subscription-contract/create/modal'
import { SubscriptionContractDetails } from '@modules/subscription-contract/models'
import { FormSelect } from '@hyper/forms/form-select'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { UserPermission } from '@models/dashboard'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'

interface Props {
  subscriptionDetails?: SubscriptionContractDetails
  plansOptions: CustomReactSelectOption[]
  initialClient?: ClientUser
}

export const SubscriptionContractCreateFormClientData: React.FC<Props> = ({
  plansOptions,
  subscriptionDetails,
  initialClient,
}) => {
  const [client, setClient] = React.useState<ClientUser | undefined>(initialClient)

  const user = useAuthenticatedUser()

  const {
    formState: { errors },
    setValue,
    clearErrors,
  } = useFormContext<SubscriptionContractCreateFormInputs>()

  const sources = useGroupedSources()
  const sellers = useGroupedSellers([])

  const [handleToggleClientDialog] = useModal('ClientDialog', { setClient })

  React.useEffect(() => {
    if (!client) return
    setValue('client', client)
    clearErrors()
  }, [client])

  return (
    <>
      {subscriptionDetails?.status !== 'confirmed' && (
        <>
          <Row>
            <ClientSelect
              setClient={setClient}
              client={client}
              handleToggleClientDialog={handleToggleClientDialog}
              error={errors.client}
              isDisabled={!!subscriptionDetails}
            />
          </Row>

          <InvoiceForm
            client={subscriptionDetails?.client ?? client}
            invoiceData={subscriptionDetails && createInvoiceData(subscriptionDetails)}
          />
        </>
      )}

      <Row className="mt-3">
        {!subscriptionDetails && (
          <FormSelect options={plansOptions} name="plan" label="Plan" formPlainProps={{ colSize: 6 }} />
        )}
        <FormSelect options={sellers} name="seller" label="Sprzedawca" formPlainProps={{ colSize: 6 }} />
        <FormPlain colSize={6} name="created_date">
          <Label>Data sprzedaży subskrypcji</Label>
          <DatePickerInput
            name="sell_date"
            isDisabled={subscriptionDetails?.status == 'confirmed'}
            wrapperClassName="flex-grow-1"
            minDate={startOfToday()}
          />
        </FormPlain>
        <FormSelect label="Źródło" options={sources} formPlainProps={{ colSize: 6 }} name="source_marketing" />

        {subscriptionDetails && (
          <FormPlain name="auto_cancel_after_date" colSize={6}>
            <label>Auto anulowanie po</label>
            <DatePickerInput
              name="auto_cancel_after_date"
              isClearable={true}
              wrapperClassName="flex-grow-1"
              isDisabled={!user.hasPerm(UserPermission.PromotionsSubscriptionCanChangeAutoCancelAfterDate)}
            />
          </FormPlain>
        )}
      </Row>
    </>
  )
}
