import * as React from 'react'
import { useTimeoutWhen } from 'rooks'

interface Response {
  handleCopy: (event: React.MouseEvent<HTMLButtonElement>) => void
  isCopied: boolean
}

export const useCopy = (value: string): Response => {
  const [isCopied, setIsCopied] = React.useState(false)

  useTimeoutWhen(() => setIsCopied(false), 1500, isCopied)

  const handleCopy = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      navigator.clipboard.writeText(value)
      setIsCopied(state => !state)
      event.stopPropagation()
    },
    [value, isCopied],
  )

  return {
    isCopied,
    handleCopy,
  }
}
