import * as React from 'react'
import { ReceptionToCheckinFilters } from '@modules/reception/to-checkin/reception-to-checkin-filters'
import { useAppDispatch, useAppSelector } from '@store/index'
import { ReceptionToCheckinList } from '@modules/reception/to-checkin/reception-to-checkin-list'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { formatDate } from '@helpers/date-helper'
import { getReceptionBookings } from '@store/actions/reception-actions'
import { ReceptionCheckType } from '@models/reception'
import { ReceptionWebSocketHandler } from '@modules/reception/reception-web-socket-handler'
import * as queryString from 'querystring'
import { useApiRequest } from '@components/hooks/use-api-request'
import { hasVip } from '@helpers/improvements'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { createSelectOption } from '@helpers/utils'
import { selectReceptionBookings, setReceptionBookings } from '@store/slices/reception-slice'

export interface ReceptionToCheckinFiltersParams {
  date_from: Date
  initial: boolean
  search: string
  sort_by: CustomReactSelectOption<string, 'arrival_time' | 'name' | 'days_count' | 'apartment.name'>
  sort_direction: CustomReactSelectOption<string, 'asc' | 'desc'>
  only_vip: boolean
}

interface ReceptionToCheckinProps {
  resortId: number
  receptionDateFilter: Date
}

export const ReceptionToCheckin: React.FC<ReceptionToCheckinProps> = ({ resortId, receptionDateFilter }) => {
  const { q } = queryString.parse(location.search.replace('?', ''))

  const defaultFilters: ReceptionToCheckinFiltersParams = {
    date_from: receptionDateFilter,
    sort_by: createSelectOption('Numer lokalu', 'apartment.name'),
    sort_direction: createSelectOption('Rosnąco', 'asc'),
    search: String(q || ''),
    initial: true,
    only_vip: false,
  }

  const [filters, setFilters] = React.useState<ReceptionToCheckinFiltersParams>(defaultFilters)
  const [totalVip, setTotalVip] = React.useState(0)
  const dispatch = useAppDispatch()

  const receptionBookings = useAppSelector(selectReceptionBookings)

  const bookings = receptionBookings.filter(booking => !booking.checked_in)

  const { isLoading, action: fetch } = useApiRequest(async () => {
    await dispatch(setReceptionBookings([]))
    await dispatch(getReceptionBookings(resortId, formatDate(filters.date_from), ReceptionCheckType.TO_CHECKIN))
  })

  React.useEffect(() => {
    fetch()
    return () => {
      dispatch(setReceptionBookings([]))
    }
  }, [formatDate(filters.date_from), resortId])

  React.useEffect(() => {
    if (!isLoading) {
      setTotalVip(bookings.filter(hasVip).length)
    }
  }, [bookings, isLoading])

  return (
    <>
      <ReceptionWebSocketHandler />

      <ReceptionToCheckinFilters
        defaultFilters={defaultFilters}
        filters={filters}
        setFilters={setFilters}
        totalVip={totalVip}
      />

      <LoaderPlaceholder content={!isLoading}>
        <ReceptionToCheckinList resortId={resortId} filters={filters} bookings={bookings} />
      </LoaderPlaceholder>
    </>
  )
}
