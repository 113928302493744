import * as React from 'react'
import classNames from 'classnames'

interface Props {
  name: string
  enabled: boolean
  className?: string
}

export const StepGuestsItemStatus = ({ name, className, enabled }: Props): JSX.Element => (
  <div className={classNames('d-flex align-items-center font-12 mb-1', className)}>
    <i
      className={classNames('text-dark', {
        'uil-check': enabled,
        'uil-multiply': !enabled,
      })}
    />
    <span className="d-block ml-1 text-nowrap">{name}</span>
  </div>
)
