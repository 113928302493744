import { ReceptionBookingDetailsImprovementDetails } from '@models/reception'
import { Col, Row } from 'reactstrap'
import { formatPriceShort } from '@helpers/utils'
import * as React from 'react'

export interface ImprovementsSummaryProps {
  improvements: ReceptionBookingDetailsImprovementDetails[]
}

const ImprovementsSummary = ({ improvements }: ImprovementsSummaryProps) => (
  <div>
    <h5>Wybrane ulepszenia:</h5>
    {improvements.map(imp => (
      <Row key={imp.id} className="reservation-create__step-summary__text-light mb-2">
        <Col md={7} className="reservation-create__step-summary__with-checkmark">
          <i className="uil-check mr-1 text-gold-light font-18 reservation-create__step-summary__checkmark" />
          {imp.name}
        </Col>
        <Col md={5} className="text-strong">
          {formatPriceShort(imp.price_brutto)}
        </Col>
      </Row>
    ))}
  </div>
)

export default ImprovementsSummary
