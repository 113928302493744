import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { ReceptionBookingDetails } from '@models/reception'
import { SaveButton } from '@hyper/button'
import { Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { useFormRequest } from '@components/hooks/use-api-request'
import { FormInput } from '@hyper/forms'
import { useAppDispatch } from '@store/index'
import { updateReceptionBookingDetails } from '@store/actions/reception-actions'
import { commonObjectPut } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'

interface Props extends BaseModalProps {
  booking: ReceptionBookingDetails
}

interface FormInputs {
  discount_feeding: number
}

export const ReservationFeedingPromotionModal: React.FC<Props> = ({ booking, toggleIsVisible }) => {
  const methods = useForm<FormInputs>({ defaultValues: { discount_feeding: booking.discount_feeding } })
  const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()

  const { isLoading, action: onSubmit } = useFormRequest(
    async (payload: FormInputs) => {
      dispatch(
        updateReceptionBookingDetails(
          await commonObjectPut<ReceptionBookingDetails>(booking.urls.reservation_booking_details, payload),
        ),
      )

      addSuccessMessage()
      toggleIsVisible()
    },

    methods.setError,
    { showGlobalError: true },
  )

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggleIsVisible}>Zmiana rabatu na wyżywienie</ModalHeader>
        <ModalBody>
          <Row>
            <Col md={12}>
              <div className="alert alert-info">
                Uwaga, rabat zostanie nałożony tylko na obecne wyżywienie, dla nowego wyżywienia nie będzie dodawany
              </div>
            </Col>
            <FormInput label="Rabat na wyżywienie" name="discount_feeding" type="select">
              {[0, 5, 10, 15, 20, 25, 30, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100].map(row => (
                <option value={row} key={row}>
                  {row}%
                </option>
              ))}
            </FormInput>
          </Row>
        </ModalBody>
        <ModalFooter>
          <button type="button" onClick={toggleIsVisible} className="btn btn-light">
            Anuluj
          </button>
          <SaveButton isSaving={isLoading} className="btn btn-green" />
        </ModalFooter>
      </form>
    </FormProvider>
  )
}
