import * as React from 'react'
import { CleaningOrderDetails } from '@modules/housekeeping/models'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'

interface HousekeepingCleaningOrderDetailsSummaryProps {
  cleaningOrderDetails: CleaningOrderDetails
}

export const HousekeepingCleaningOrderDetailsSummary: React.FC<HousekeepingCleaningOrderDetailsSummaryProps> = ({
  cleaningOrderDetails,
}) => (
  <div className="border p-1 rounded mb-2">
    <h5 className="header-title mb-1">Dane podstawowe</h5>

    <div className="table-responsive">
      <table className="table table-sm mb-2">
        <colgroup>
          <col width="40%" />
          <col width="60%" />
        </colgroup>

        <tbody>
          <tr>
            <td>Lokal</td>
            <td className="text-right">{cleaningOrderDetails.apartment.name}</td>
          </tr>
          <tr>
            <td>Data sprzątania</td>
            <td className="text-right">{toDefaultDateTimeFormat(cleaningOrderDetails.finished_at)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
)
