import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { FaultOrderDetails } from '@modules/housekeeping/applications/models'
import { HousekeepingApplicationFaultsPreviewModalTable } from '@modules/housekeeping/applications/fault/preview-modal/table'
import { BaseModalProps } from '@components/modals/types'

interface Props extends BaseModalProps {
  faultOrder: FaultOrderDetails
  handleEdit: () => void
}

export const HousekeepingApplicationFaultsPreviewModal: React.FC<Props> = ({
  toggleIsVisible,
  faultOrder,
  handleEdit,
}) => (
  <>
    <ModalHeader toggle={toggleIsVisible}>
      <span className="text-semi-strong">Szczegóły usterki</span> <strong>{faultOrder.number}</strong>
    </ModalHeader>
    <ModalBody>
      <HousekeepingApplicationFaultsPreviewModalTable faultOrder={faultOrder} />
    </ModalBody>
    <ModalFooter>
      <Button
        color="light"
        type="button"
        onClick={() => {
          handleEdit()
          toggleIsVisible()
        }}
      >
        Edytuj
      </Button>
      <Button color="green" type="button" onClick={toggleIsVisible}>
        Zamknij
      </Button>
    </ModalFooter>
  </>
)
