import * as React from 'react'
import { Card, CardBody, Col } from 'reactstrap'
import { RodoAgreement } from '@modules/crm/rodo-agreements/models'
import { ProductCardTitle } from '@components/products/product-card-title'
import { IconWithText } from '@components/icon-with-text'
import { PackageDetailsRow } from '@modules/package/details/information/row'
import { YesNoBadge } from '@components/badges/yes-no'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import classNames from 'classnames'
import { SaveButton } from '@hyper/button'
import { useBenefitIssue } from '@modules/crm/rodo-agreements/benefits/use-benefit-issue'

interface Props {
  rodoAgreement: RodoAgreement
}

export const RodoAgreementsModalStatuses = ({ rodoAgreement }: Props): React.ReactNode => {
  const { isLoading, issueBenefit } = useBenefitIssue()

  return (
    <Col md={6}>
      <Card>
        <CardBody>
          <ProductCardTitle
            title={<IconWithText icon="uil-check" text="Status" textClass="fw-semi-bold" />}
            isEditAllowed={false}
          />
          <table className="table w-100 table-sm mb-0">
            <tbody>
              <PackageDetailsRow label="Zgoda e-mail">
                <Status date={rodoAgreement.email_confirm_date} />
              </PackageDetailsRow>
              <PackageDetailsRow label="Zgoda SMS">
                <Status date={rodoAgreement.phone_confirm_date} />
              </PackageDetailsRow>
              <PackageDetailsRow label="Benefit wydany">
                {rodoAgreement.benefit_transfer_date ? (
                  <Status date={rodoAgreement.benefit_transfer_date}>
                    <small className="font-11 pt-1">
                      przez {rodoAgreement.benefit_transfer_by} <br />
                    </small>
                  </Status>
                ) : (
                  <SaveButton
                    className="btn btn-green btn-tall"
                    labelSaving="Proszę czekać..."
                    label={<IconWithText text="Wydaj benefit" icon="uil-check font-16" />}
                    isSaving={isLoading}
                    onClick={() => issueBenefit(rodoAgreement)}
                    disabled={!rodoAgreement.phone_confirm_date || !rodoAgreement.email_confirm_date}
                  />
                )}
              </PackageDetailsRow>
            </tbody>
          </table>
        </CardBody>
      </Card>
    </Col>
  )
}

const Status = ({ date, children }: { date: string | null; children?: React.ReactNode }) => (
  <div className={classNames('d-flex justify-content-end', children ? 'align-items-start' : 'align-items-center')}>
    <div className="d-flex flex-column justify-content-start pr-2 text-right">
      {!!date && <span className="d-block font-12">{toDefaultDateTimeFormat(date)}</span>}
      {children}
    </div>
    <YesNoBadge value={!!date} />
  </div>
)
