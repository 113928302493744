import * as React from 'react'
import classNames from 'classnames'
import { Calendar, CalendarProps } from 'react-date-range'
import { pl } from 'date-fns/locale'
import { DateRangeNavigatorRenderer } from '@components/date/data-range-navigator-renderer'
import { isAfter, isBefore, isValid, setHours } from 'date-fns'
import { handleEnterPress } from '@helpers/utils'
import { formatDate } from '@helpers/date-helper'
import { useNewFormContext } from '@hyper/forms/hooks/use-new-form-context'
import { UseFormClearErrors, UseFormSetError } from 'react-hook-form/dist/types/form'
import { Button } from 'reactstrap'

export type DatePickerProps = Partial<CalendarProps>

interface Props extends Omit<DatePickerProps, 'maxDate' | 'minDate'> {
  date: Date | undefined
  onChange: (range) => void
  minDate?: Date | null
  maxDate?: Date | null
  placeholder?: string
  className?: string
  inputClassName?: string
  wrapperClassName?: string
  isDisabled?: boolean
  isInvalid?: boolean
  isClearable?: boolean
  dateFormat?: string
  onShownDateChange?: (date: Date) => void
  name?: string
  withInput?: boolean
  withIcon?: boolean
  withClose?: boolean
}

const DatePickerComponent: React.FC<Props> = ({
  name,
  date,
  onChange,
  className,
  inputClassName,
  isDisabled,
  wrapperClassName,
  isInvalid,
  minDate,
  maxDate,
  placeholder,
  dateFormat,
  isClearable,
  onShownDateChange,
  withInput,
  withIcon,
  withClose,
  ...calendarProps
}) => {
  let setError: UseFormSetError<any>
  let clearErrors: UseFormClearErrors<any>

  const methods = useNewFormContext()

  if (methods) {
    setError = methods.setError
    clearErrors = methods.clearErrors
  }

  const pickerRef = React.useRef<HTMLDivElement>(null)
  const [isOpen, setOpen] = React.useState(false)

  const onPageClick = (event: MouseEvent) => {
    if (isOpen && !pickerRef.current?.contains(event.target as Node)) {
      setOpen(false)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  React.useEffect(() => {
    if (isOpen) {
      document.body.addEventListener('click', onPageClick)
    }

    return () => document.body.removeEventListener('click', onPageClick)
  }, [isOpen])

  const showDatePicker = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation()
    if (!isDisabled) {
      setOpen(true)
    }
  }

  const handleClearDate = (): void => {
    onChange(null)
  }

  const onDateChange = (event: Date): void => {
    onChange(event)
    if (isOpen) {
      if (name) clearErrors(name)
      setOpen(false)
    }
  }

  const onMonthChange = (event: Date): void => {
    if (onShownDateChange) {
      onShownDateChange(event)
      if (isOpen) {
        setOpen(false)
      }
    }
  }

  const setFormError = () => {
    if (name) setError(name, { message: 'Nieprawidłowa data' })
  }

  const handleDateChange = event => {
    const date = new Date(event.target.value)

    if (!isValid(date)) {
      onChange(null)
      setFormError()
      return
    }

    onChange(setHours(date, 0))

    if ((minDate && isBefore(date, minDate)) || (maxDate && isAfter(date, maxDate))) {
      setFormError()
      return
    }

    if (name) clearErrors(name)
  }

  return (
    <div className={classNames('date-picker d-flex align-items-center', wrapperClassName)} role="date-picker">
      <span
        className={classNames('form-control date-picker__date', inputClassName, {
          'cursor-pointer': !withInput,
          'is-invalid': isInvalid,
        })}
        {...(!withInput && { onClick: showDatePicker })}
      >
        {withIcon && <i className="uil-calender pr-2 cursor-pointer" onClick={showDatePicker} />}

        {withInput ? (
          <input
            className="date-picker__input"
            type="date"
            min={minDate ? formatDate(minDate, 'yyyy-MM-dd') : undefined}
            max={maxDate ? formatDate(maxDate, 'yyyy-MM-dd') : undefined}
            value={formatDate(date)}
            onChange={handleDateChange}
            onKeyDown={handleEnterPress.bind(handleClose)}
            disabled={isDisabled}
          />
        ) : date ? (
          formatDate(date, dateFormat || 'yyyy-MM-dd')
        ) : (
          <span className="date-picker__placeholder">{placeholder || 'Wybierz datę'}</span>
        )}
      </span>
      {isClearable && date && <span className="date-picker__clear" onClick={handleClearDate} />}
      {isOpen && (
        <div className="date-picker__wrapper" ref={pickerRef}>
          <Calendar
            date={date}
            focusedRange={[0, 0]}
            locale={pl}
            onChange={onDateChange}
            className={className || ''}
            minDate={minDate || undefined}
            maxDate={maxDate || undefined}
            onShownDateChange={onMonthChange}
            navigatorRenderer={DateRangeNavigatorRenderer()}
            {...calendarProps}
          />
          {withClose && (
            <div className="ml-auto bg-white d-flex justify-content-end pr-2 pb-2">
              <Button color="success" onClick={() => setOpen(false)}>
                zamknij
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default DatePickerComponent
