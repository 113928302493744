import * as React from 'react'
import { Button, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { SaveButton } from '@hyper/button'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useForm } from 'react-hook-form'
import { createReportTask } from '@store/actions/report-tasks-actions'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { PaymentRegistersFiltersParams } from '@modules/payment-registers/list/filters'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { paymentRegisterUsersOptionsSelector } from '@store/selectors/payment'
import { BaseModalProps } from '@components/modals/types'
import { useSaleAppData } from '@modules/sale/hooks/use-sale-app-data'
import { useAppData } from '@components/hooks/use-app-data'
import { Form } from '@hyper/forms/form'
import { FormSelect } from '@hyper/forms/form-select'
import { createSelectOption } from '@helpers/utils'
import { PaymentRegisterTypesOptions } from '@modules/payment-registers/consts'

interface Props extends BaseModalProps {
  filters?: PaymentRegistersFiltersParams
}

interface FormInputs {
  created_after: Date | undefined
  created_before: Date | undefined
  source_resort: CustomReactSelectOption | null
  source: CustomReactSelectOption | null
  kind: CustomReactSelectOption | null
  type: CustomReactSelectOption | null
  user: CustomReactSelectOption | null
}

export const ReportsPaymentRegisterModal: React.FC<Props> = ({ toggleIsVisible, filters }) => {
  const dispatch = useAppDispatch()
  const url = useAppSelector((state: RootState) => state.appState.appData.urls.reports.report_task_payment_register)
  const { payment_register_kinds } = useSaleAppData()
  const paymentRegisterKinds = payment_register_kinds.map(([value, label]) => ({ value, label }))

  const methods = useForm<FormInputs>({
    mode: 'all',
    defaultValues: {
      created_after: new Date(),
      created_before: new Date(),
      ...filters,
    },
  })

  const { isLoading, action: onSubmit } = useReportFormRequest(async (payload: FormInputs) => {
    const reportTask = await dispatch(
      createReportTask([
        url,
        {
          created_after: payload.created_after,
          created_before: payload.created_before,
          kind: payload.kind?.value,
          type: payload.type?.value,
          source_resort: payload.source_resort?.value,
          user: payload.user?.value,
          source: payload.source?.value,
        },
      ]),
    )
    toggleIsVisible()

    return reportTask
  }, methods.setError)

  const user = useAuthenticatedUser()
  const paymentRegisterUsers = useAppSelector(paymentRegisterUsersOptionsSelector)

  const { resorts } = useSaleAppData()
  const { payment_sources } = useAppData()

  const sourceOptions: CustomReactSelectOption[] = payment_sources.map(row => createSelectOption(row.name, row.id))

  const resortOptions = resorts
    .filter(row => user.resort_ids_with_hq.includes(row.id))
    .map(resort => createSelectOption(resort.name, resort.id))

  return (
    <Form onSubmit={onSubmit} methods={methods}>
      <ModalHeader toggle={toggleIsVisible}>Raport rejestru płatności</ModalHeader>
      <ModalBody>
        <Row>
          <DateFromToInput label="Data wpłaty" startDateName="created_after" endDateName="created_before" />
          <FormSelect
            options={resortOptions}
            name="source_resort"
            label="Lokalizacja"
            selectProps={{ isClearable: true }}
          />
          <FormSelect
            options={PaymentRegisterTypesOptions}
            name="type"
            label="Typ płatności"
            selectProps={{ isClearable: true }}
          />
          <FormSelect
            options={paymentRegisterKinds}
            name="kind"
            label="Typ operacji"
            selectProps={{ isClearable: true }}
          />
          <FormSelect
            options={sourceOptions}
            name="source"
            label="Metoda płatności"
            selectProps={{ isClearable: true }}
          />
          <FormSelect
            options={paymentRegisterUsers}
            name="user"
            label="Pracownik"
            selectProps={{ isClearable: true }}
          />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" label="Zleć raport" labelSaving="Zlecanie.." isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
