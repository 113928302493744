import * as React from 'react'
import { NavigationTabs, NavigationTabsButton } from '@components/navigation-tabs'
import { NavigationPath } from '@models/routes'

export type UpSellBookingsTab = 'planned' | 'guest_in_resort' | 'booking_closed'

interface Props {
  type: UpSellBookingsTab
  path: NavigationPath
}

export const UpSellBookingsTabs: React.FC<Props> = ({ type, path }) => {
  const buttons: NavigationTabsButton[] = [
    {
      isActive: type === 'planned',
      title: 'Planowane przyjazdy',
      link: path.replaceParam(':type', 'planned'),
    },
    {
      isActive: type === 'guest_in_resort',
      title: 'Goście w Resorcie',
      link: path.replaceParam(':type', 'guest_in_resort'),
    },
    {
      isActive: type === 'booking_closed',
      title: 'Zakończone Rezerwacje',
      link: path.replaceParam(':type', 'booking_closed'),
    },
  ]

  return <NavigationTabs buttons={buttons} />
}
