import * as React from 'react'
import classname from 'classnames'
import { ReservationStatsTableCells } from '@modules/reservations/models'

interface ReservationStatsPlanningReportRowProps {
  rowClassName?: string
  dataCells: ReservationStatsTableCells
  title: string
}

export const ReservationStatsPlanningReportRow: React.FC<ReservationStatsPlanningReportRowProps> = ({
  title,
  dataCells,
  rowClassName,
}) => (
  <tr className={classname('reservation-stats__row__report', rowClassName)}>
    <td className="font-12 align-middle reservation-stats__col__first pl-5">
      <strong className="text-gold-light">{title}</strong>
    </td>
    {dataCells.data}
    {dataCells.summary.component}
  </tr>
)
