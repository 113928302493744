import * as React from 'react'
import { Button } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'

export const PromocodePrefixCreate: React.FC = () => {
  const [handleToggle] = useModal('PromocodePrefixDialog')

  return (
    <Button color="green" onClick={handleToggle}>
      <i className="mdi mdi-plus-circle mr-1" /> Dodaj prefiks
    </Button>
  )
}
