import * as React from 'react'
import { NotificationEvents } from '@models/dashboard'
import { useAppDispatch } from '@store/index'
import { useWebSocket } from '@components/hooks/use-web-socket'
import { updateClientDetails } from '@store/actions/crm-actions'
import { ClientDetails } from '@models/clients'

export const ClientWebSocketHandler: React.FC = () => {
  const dispatch = useAppDispatch()

  useWebSocket<ClientDetails>(
    (message, payload) => {
      switch (message) {
        case NotificationEvents.CLIENT_CHANGE:
          dispatch(updateClientDetails(payload as ClientDetails))
          break
      }
    },
    [],
    [NotificationEvents.CLIENT_CHANGE],
  )
  return null
}
