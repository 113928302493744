import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { useFeedingDiscountPreview } from '@modules/reception/checkin/step-feeding/new-feeding/use-feeding-discount-preview'

export const ReceptionBookingNewFeedingDiscountPreviewIndicator = (): React.ReactNode => {
  const { isDiscountPreviewActive } = useFeedingDiscountPreview()

  return (
    <div className="border rounded d-flex px-1 align-items-center mb-1 bg-grey-light">
      <IconWithText icon="uil-top-arrow-from-top font-16 lh-0" text="SHIFT" textClass="font-weight-bold font-11" />
      {isDiscountPreviewActive ? (
        <span className="font-11 ml-1">- wyświetlasz kwoty oszczędności</span>
      ) : (
        <span className="font-11 ml-1">- użyj, aby wyświetlić kwoty oszczędności</span>
      )}
    </div>
  )
}
