import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useForm } from 'react-hook-form'
import { commonObjectPut } from '@store/actions/generic-actions'
import { ResortDetails } from '@models/booking'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useFormRequest } from '@components/hooks/use-api-request'
import { BaseModalProps } from '@components/modals/types'
import { Form } from '@hyper/forms/form'
import { setResortDetails } from '@store/slices/reception-slice'
import { FormSelect } from '@hyper/forms/form-select'
import { createSelectOption } from '@helpers/utils'
import { CustomReactSelectOption, makeDefaultSelectOption } from '@components/custom-react-select'
import { useHousekeepingCleaningCompanies } from '@modules/housekeeping/users/use-housekeeping-cleaning-companies'

interface FormInputs {
  housekeeping_bedding_user: CustomReactSelectOption | null
}

export const BeddingUserDialog: React.FC<BaseModalProps> = ({ toggleIsVisible }) => {
  const resortDetails = useAppSelector((state: RootState) => state.receptionState.resortDetails)
  const workers = useAppSelector((state: RootState) => state.houseKeepingState.cleaningWorkers)

  const dispatch = useAppDispatch()

  const housekeeping_companies = useHousekeepingCleaningCompanies(resortDetails?.id || 0)
  const { addSuccessNotification } = useNotificationHook()

  const hprCompaniesIds = housekeeping_companies.reduce(
    (cleaningCompanies, cleaningCompany) =>
      cleaningCompany.is_hpr_company ? [...cleaningCompanies, cleaningCompany.id] : cleaningCompanies,
    [],
  )

  const companyWorkersOptions = workers.reduce(
    (companyWorkers, companyWorker) =>
      hprCompaniesIds.includes(companyWorker.company_id) && companyWorker.is_housekeeping_bedding
        ? [...companyWorkers, createSelectOption(companyWorker.name, companyWorker.id)]
        : companyWorkers,
    [],
  )

  const methods = useForm<FormInputs>({
    defaultValues: {
      housekeeping_bedding_user: makeDefaultSelectOption(
        companyWorkersOptions,
        resortDetails?.housekeeping_bedding_user_id,
      ),
    },
  })

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    if (!resortDetails) return

    dispatch(
      setResortDetails(
        await commonObjectPut<ResortDetails>(resortDetails.urls.resort_housekeeping_bedding_user, {
          housekeeping_bedding_user: payload.housekeeping_bedding_user?.value,
        }),
      ),
    )
    addSuccessNotification('Pościelowny został zmieniony')
    toggleIsVisible()
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>
        <span className="text-semi-strong">Zmiana pościelowego w</span>{' '}
        <strong className="text-strong">{resortDetails?.name}</strong>
      </ModalHeader>
      <ModalBody>
        <FormSelect options={companyWorkersOptions} name="housekeeping_bedding_user" />
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" label="Przypisz" labelSaving="Przypisywanie.." isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
