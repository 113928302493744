import * as React from 'react'

import { format, parseISO } from 'date-fns'
import { EventRowSingleEventEdit } from '@modules/rcp/user/events/event-row-single-event-edit'
import { EventRowSingleEventRemove } from '@modules/rcp/user/events/event-row-single-event-remove'
import { RcpEvent, RcpMachine } from '@models/rcp'
import { useToggle } from '@components/hooks/use-toggle'

interface EventRowSingleEventProps {
  date: string
  event: RcpEvent
  machines: RcpMachine[]
  hasError: boolean
}

export const EventRowSingleEvent: React.FC<EventRowSingleEventProps> = ({ event, machines, date, hasError }) => {
  const checkTime = event.check_time ? format(parseISO(event.check_time), 'HH:mm') : ''
  const [isEdited, handleEditClick] = useToggle()

  const machine = machines.find((mach: RcpMachine) => mach.id === event.machine_id)

  return (
    <>
      {isEdited && (
        <EventRowSingleEventEdit
          onCancel={handleEditClick}
          hasError={hasError}
          date={date}
          event={event}
          machines={machines}
        />
      )}
      {!isEdited && (
        <tr className={`rcp__events-table__tr-primary-middle ${hasError ? 'has-errors' : ''}`}>
          <td>{event.get_check_type_display}</td>
          <td className="text-semi-strong">{event.is_start ? checkTime : ''}</td>
          <td className="text-semi-strong">
            {event.is_end ? checkTime : ''}
            {event.is_start && (
              <span className="action-icon">
                <i className=" uil-corner-right-down text-dark" />
              </span>
            )}
          </td>
          <td />
          <td className="text-semi-strong">{event.is_start ? machine?.name : ''}</td>
          <td className="text-semi-strong">
            {event.is_end ? machine?.name : ''}
            {event.is_start && (
              <span className="action-icon">
                <i className="uil-corner-right-down text-dark" />
              </span>
            )}
          </td>
          <td>
            <button className="action-icon btn-pure" title="Edytuj" onClick={handleEditClick}>
              <i className="uil-edit text-muted font-18" />
            </button>
            {!event.is_fake && <EventRowSingleEventRemove event={event} />}
          </td>
          <td />
        </tr>
      )}
    </>
  )
}
