import * as React from 'react'
import { useModal } from '@components/modals/use-modal'
import { NavigationPath } from '@models/routes'
import { Link } from 'react-router-dom'

export const CmsAssetCategoriesCreateButton = (): JSX.Element => {
  const [handleClick] = useModal('CmsAssetCategoryModal')

  return (
    <div className="text-right mb-3">
      <Link to={NavigationPath.CmsAssets} className="btn btn-light mr-2">
        Wróć do listy
      </Link>
      <button onClick={handleClick} className="btn btn-green">
        Utwórz kategorię
      </button>
    </div>
  )
}
