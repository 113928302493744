import * as React from 'react'
import Table from '@components/table/table'
import { Package } from '@models/package'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { useModal } from '@components/modals/use-modal'
import { formatPriceShort } from '@helpers/utils'
import { PackageDetailsBaseInformationCancel } from '@modules/package/details/information/base/cancel'
import { StatusBadge } from '@modules/promotions/common/status-badge'

interface Props {
  packageData: Package
}

export const PackageListTableRow: React.FC<Props> = ({ packageData }) => {
  const [handleDetails] = useModal('PackageDetailsModal', {
    packageData,
  })

  return (
    <Table.Row onDoubleClick={handleDetails} className="cursor-pointer" role="details-row">
      <Table.Cell>{packageData.number}</Table.Cell>
      <Table.Cell>
        {packageData.invoice_company} <br />
      </Table.Cell>
      <Table.Cell className="text-nowrap">{packageData.invoice_nip}</Table.Cell>
      <Table.Cell>
        <StatusBadge element={packageData} />
      </Table.Cell>
      <Table.Cell>{packageData.invoice_number}</Table.Cell>
      <Table.Cell>{toDefaultDateFormat(packageData.confirmed_at)}</Table.Cell>
      <Table.Cell>{packageData.seller}</Table.Cell>
      <Table.Cell>{formatPriceShort(packageData.price_netto)}</Table.Cell>
      <Table.Cell>{formatPriceShort(packageData.price_brutto)}</Table.Cell>
      <Table.Cell>{packageData.houses_sea}</Table.Cell>
      <Table.Cell>{packageData.apartments_sea}</Table.Cell>
      <Table.Cell>{packageData.houses_mountains}</Table.Cell>
      <Table.Cell>{packageData.apartments_mountains}</Table.Cell>
      <Table.Cell>
        <PackageDetailsBaseInformationCancel packageData={packageData} />
      </Table.Cell>
    </Table.Row>
  )
}
