import * as React from 'react'
import { ModalConfig, ModalParams, ModalsMap, ModalTypes } from '@components/modals/types'
import { Modal } from 'reactstrap'
import { useAppDispatch } from '@store/index'
import { setHideModal, setModalIsOpen } from '@store/slices/modal-slice'

interface Props {
  modal: ModalTypes
  params: ModalParams
  config?: ModalConfig
  isOpen: boolean
}

export const ModalComponentRow: React.FC<Props> = ({ modal, isOpen, params, config }) => {
  const dispatch = useAppDispatch()

  const [ModalContent, options] = ModalsMap[modal]

  const onClose = React.useCallback(() => {
    config?.onClose?.()
    return dispatch(setHideModal({ modal }))
  }, [modal, config])
  const toggleIsOpen = React.useCallback(() => dispatch(setModalIsOpen({ modal, isOpen: !isOpen })), [modal, isOpen])

  return (
    <Modal
      key={modal}
      isOpen={isOpen}
      toggle={toggleIsOpen}
      onClosed={onClose}
      role="modal"
      {...{ backdrop: 'static', ...options }}
    >
      <ModalContent {...params} toggleIsVisible={toggleIsOpen} />
    </Modal>
  )
}
