import * as React from 'react'
import { Improvement } from '@models/reservation-create'
import { PriceItem } from '@models/reception'
import { formatPrice } from '@helpers/utils'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'

interface Props {
  improvements: PriceItem<Improvement>
}

export const ReservationBillExpandedImprovement = ({ improvements }: Props): JSX.Element => (
  <table className="table table-sm col-9 ml-auto mb-0 font-12 table-striped">
    <tbody>
      {improvements.items.map((improvement: Improvement) => (
        <tr key={improvement.id}>
          <td>
            {improvement.name} {improvement.amount > 1 && <span>x{improvement.amount}</span>}
          </td>
          <td>{toDefaultDateTimeFormat(improvement.created)}</td>
          <td className="fw-semi-bold text-right">+ {formatPrice(improvement.price_brutto)}</td>
          <td width="50" />
        </tr>
      ))}
    </tbody>
  </table>
)
