import * as React from 'react'
import { GenericVoucherKind } from '@modules/generic-vouchers/models'
import { useModal } from '@components/modals/use-modal'

interface Props {
  id: string
  kind: GenericVoucherKind
}

export const GenericVoucherDetails: React.FC<Props> = ({ kind, id }) => {
  const [handleDetails, hidePreview] = useModal('GenericVoucherDetailsModal')

  const showPreview = React.useCallback(async () => {
    handleDetails(null, { kind, id }, true)
  }, [kind, id])

  React.useEffect(() => {
    showPreview()

    return () => {
      hidePreview()
    }
  }, [])

  return null
}
