import * as React from 'react'
import DOMPurify from 'dompurify'

interface Props {
  content: string
}

export const CustomHTML = ({ content }: Props): JSX.Element => {
  const cleanHtml = React.useMemo(() => DOMPurify.sanitize(content), [content])

  return <div dangerouslySetInnerHTML={{ __html: cleanHtml }} />
}
