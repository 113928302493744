import * as React from 'react'
import { DragSourceMonitor, DropTargetMonitor, useDrag, useDrop } from 'react-dnd'

interface DragItem {
  index: number
  id: string
  type: string
}

interface Props {
  id: number
  index: number
  moveItem: (sourceId: number, destinationId: number) => void
  className: string
  onDrop?: () => void
  children?: React.ReactNode
}

export const DragReorder: React.FC<Props> = React.memo((props: Props) => {
  const ref = React.useRef(null)
  const [isEditable, setEditable] = React.useState(true)

  const [, dropRef] = useDrop({
    accept: 'DRAG_REORDER',
    hover: (item: DragItem, monitor: DropTargetMonitor) => {
      const offset = monitor.getClientOffset()
      if (!ref.current || !offset || !isEditable) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = props.index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      props.moveItem(dragIndex, hoverIndex)
      item.index = hoverIndex

      setEditable(false)
      setTimeout(() => {
        setEditable(true)
      }, 500)
    },
    drop: () => {
      props.onDrop?.()
    },
  })

  const [{ isDragging }, dragRef] = useDrag({
    type: 'DRAG_REORDER',
    item: () => ({ id: props.id, index: props.index }),
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  dragRef(dropRef(ref))

  return (
    <div className={props.className} ref={ref} style={{ opacity: isDragging ? 0 : 1 }}>
      {props.children}
    </div>
  )
})
