import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { TechnicalOrder } from '@models/technical-orders'
import { Form } from '@hyper/forms/form'
import { SaveButton } from '@hyper/button'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { useHandleRtkQueryError } from '@components/hooks/use-handle-rtk-error'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useForm } from 'react-hook-form'
import { useCreateOrUpdateTechnicalOrderMutation } from '@api/technical-orders'
import { TechnicalOrderRejectModalForm } from '@modules/technical-orders/technical-order-reject-modal/form'
export interface TechnicalOrderRejectModalProps {
  technicalOrder: TechnicalOrder
  url: string
  successMessage: string
  title: string
}
type Props = BaseModalProps & TechnicalOrderRejectModalProps
interface FormInputs {
  reason: string
}

export const TechnicalOrderRejectModal: React.FC<Props> = ({
  technicalOrder,
  url,
  toggleIsVisible,
  title,
  successMessage,
}) => {
  const methods = useForm<FormInputs>()
  const { addSuccessNotification } = useNotificationHook()

  const [updateTechnicalOrder, { error, isError, isLoading }] = useCreateOrUpdateTechnicalOrderMutation()

  const onSubmit = async (payload: FormInputs) => {
    await updateTechnicalOrder({
      data: payload,
      url,
    }).unwrap()

    addSuccessNotification(successMessage)
    toggleIsVisible()
  }

  useHandleRtkQueryError(error, isError, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>
        {title} {technicalOrder.number}
      </ModalHeader>
      <ModalBody>
        <TechnicalOrderRejectModalForm />
      </ModalBody>

      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
