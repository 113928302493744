import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { apiQueryFunction } from '@api/base'
import { extractSelectOptionsValues } from '@helpers/utils'
import { PaginationResponse } from '@models/dashboard'
import { RootState } from '@store/index'
import { TableFilters } from '@components/table/table'
import { ClientCouponItem, CouponClient, CouponType, CouponTypeConfiguration } from '@modules/coupons/models'
import { CouponTypesFilters } from '@modules/coupons/types'
import { CouponsTypeFormInputs } from '@modules/coupons/types/modal/coupon-type-create-modal'
import { CouponsClientDetailsTableFilters } from '@modules/coupons/list/client-details'
import { CouponsTypeConfigurationFormInputs } from '@modules/coupons/types-configuration/modal/coupon-type-configuration-create-modal'
import { CouponItemEditFormInputs } from '@modules/coupons/list/client-details/modal/coupon-item-edit-modal'

const COUPON_TYPES_TAG = 'couponTypes'
const COUPON_TYPES_CONFIGURATIONS_TAG = 'couponTypesConfigurations'
const COUPON_CLIENT_ITEMS_TAG = 'couponClientItems'
const COUPON_CLIENTS_TAG = 'couponClients'

interface UpdateParams<T> {
  url?: string
  data: T
}

export const couponsApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: '' }),
  reducerPath: 'couponsApi',
  tagTypes: [COUPON_CLIENTS_TAG, COUPON_TYPES_TAG, COUPON_TYPES_CONFIGURATIONS_TAG, COUPON_CLIENT_ITEMS_TAG],
  endpoints: builder => ({
    getCouponClients: builder.query<PaginationResponse<CouponClient>, TableFilters>({
      queryFn: apiQueryFunction((state: RootState, data: TableFilters) => ({
        params: { ...(extractSelectOptionsValues(data) || {}) },
        method: 'GET',
        url: state.appState.appData.urls.coupons.summary,
      })),
      providesTags: ({ results }: PaginationResponse<CouponClient>) =>
        results
          ? [
              ...results.map(({ client_id }) => ({ type: COUPON_CLIENTS_TAG, id: client_id }) as const),
              COUPON_CLIENTS_TAG,
            ]
          : [COUPON_CLIENTS_TAG],
    }),
    getCouponTypes: builder.query<CouponType[], CouponTypesFilters>({
      queryFn: apiQueryFunction((state: RootState, data: TableFilters) => ({
        params: { ...(extractSelectOptionsValues(data) || {}) },
        method: 'GET',
        url: state.appState.appData.urls.coupons.types,
        responseTransformer: (response: PaginationResponse<CouponType>) => response.results,
      })),
      providesTags: (result: CouponType[]) =>
        result
          ? [...result.map(({ id }) => ({ type: COUPON_TYPES_TAG, id }) as const), COUPON_TYPES_TAG]
          : [COUPON_TYPES_TAG],
    }),
    getCouponTypesConfigurations: builder.query<CouponTypeConfiguration[], TableFilters>({
      queryFn: apiQueryFunction((state: RootState, data: TableFilters) => ({
        params: { ...(extractSelectOptionsValues(data) || {}) },
        method: 'GET',
        url: state.appState.appData.urls.coupons.types_configurations,
        responseTransformer: (response: PaginationResponse<CouponTypeConfiguration>) => response.results,
      })),
      providesTags: (result: CouponTypeConfiguration[]) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: COUPON_TYPES_CONFIGURATIONS_TAG, id }) as const),
              COUPON_TYPES_CONFIGURATIONS_TAG,
            ]
          : [COUPON_TYPES_CONFIGURATIONS_TAG],
    }),
    updateClientCouponItems: builder.mutation<ClientCouponItem, UpdateParams<CouponItemEditFormInputs>>({
      invalidatesTags: [COUPON_CLIENT_ITEMS_TAG],

      queryFn: apiQueryFunction((state: RootState, { url, data }: UpdateParams<CouponItemEditFormInputs>) => ({
        data: extractSelectOptionsValues(data),
        method: 'PATCH',
        url: url,
      })),
    }),
    getClientCouponItems: builder.query<
      PaginationResponse<ClientCouponItem>,
      [string, CouponsClientDetailsTableFilters]
    >({
      queryFn: apiQueryFunction((state: RootState, [url, data]) => ({
        params: { ...(extractSelectOptionsValues(data) || {}) },
        method: 'GET',
        url,
      })),
      providesTags: ({ results }: PaginationResponse<ClientCouponItem>) =>
        results
          ? [...results.map(({ id }) => ({ type: COUPON_CLIENT_ITEMS_TAG, id }) as const), COUPON_CLIENT_ITEMS_TAG]
          : [COUPON_CLIENT_ITEMS_TAG],
    }),
    createOrUpdateCouponType: builder.mutation<CouponType, UpdateParams<CouponsTypeFormInputs>>({
      invalidatesTags: [COUPON_TYPES_TAG],

      queryFn: apiQueryFunction((state: RootState, { url, data }: UpdateParams<CouponsTypeFormInputs>) => ({
        data: extractSelectOptionsValues(data),
        method: url ? 'PUT' : 'POST',
        url: url || state.appState.appData.urls.coupons.types,
      })),
    }),

    createOrUpdateCouponTypeConfiguration: builder.mutation<
      CouponTypeConfiguration,
      UpdateParams<CouponsTypeConfigurationFormInputs>
    >({
      invalidatesTags: [COUPON_TYPES_CONFIGURATIONS_TAG],

      queryFn: apiQueryFunction(
        (state: RootState, { url, data }: UpdateParams<CouponsTypeConfigurationFormInputs>) => ({
          data: extractSelectOptionsValues(data),
          method: url ? 'PUT' : 'POST',
          url: url || state.appState.appData.urls.coupons.types_configurations,
        }),
      ),
    }),
  }),
})

export const {
  useGetCouponClientsQuery,
  useGetCouponTypesQuery,
  useGetCouponTypesConfigurationsQuery,
  useCreateOrUpdateCouponTypeMutation,
  useGetClientCouponItemsQuery,
  useUpdateClientCouponItemsMutation,
  useCreateOrUpdateCouponTypeConfigurationMutation,
} = couponsApi
