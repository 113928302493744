import * as React from 'react'
import { Button, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { SaveButton } from '@hyper/button'
import { useForm } from 'react-hook-form'
import { BaseModalProps } from '@components/modals/types'
import { Form } from '@hyper/forms/form'
import { FormSelect } from '@hyper/forms/form-select'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useFormRequest } from '@components/hooks/use-api-request'
import { commonObjectPut } from '@store/actions/generic-actions'
import { ContentLoader } from '@components/content-loader'
import { FloorHeatingMeasurementUrls } from '@modules/floor-heating/models'
import { createSelectOption } from '@helpers/utils'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { FloorHeatingDetailsTemperatureParameterChange } from '@modules/floor-heating/modals/temperature-change'
import { useFloorHeatingAppData } from '@modules/floor-heating/use-floor-heating-app-data'

interface FormInputs {
  scenario: CustomReactSelectOption | null
  isGlobalSetting: boolean
  zone_7_temperature: string
  zone_8_temperature: string
  zone_9_temperature: string
  zone_a_temperature: string
}

export type FloorHeatingParameter = 'scenario' | 'temperature'

interface Props extends BaseModalProps {
  apartmentId: string
  field: FloorHeatingParameter
  measurementUrls: FloorHeatingMeasurementUrls | null
}

export const FloorHeatingDetailsParametersModalChange: React.FC<Props> = ({
  toggleIsVisible,
  apartmentId,
  field,
  measurementUrls,
}) => {
  const { appData: floorHeatingAppData, isLoading: isFetchingFloorHeatingAppData } = useFloorHeatingAppData()
  const { addSuccessNotification } = useNotificationHook()

  const methods = useForm<FormInputs>({
    defaultValues: {
      scenario: null,
      isGlobalSetting: true,
      zone_7_temperature: '18',
      zone_8_temperature: '18',
      zone_9_temperature: '18',
      zone_a_temperature: '18',
    },
  })

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    if (!measurementUrls) return

    if (field === 'scenario') {
      await commonObjectPut(measurementUrls.scenario, { scenario_code: payload.scenario?.value })

      addSuccessNotification('Scenariusz został zmieniony!')
    }

    if (field === 'temperature') {
      const globalTemperature = payload.isGlobalSetting ? payload.zone_7_temperature : null
      await commonObjectPut(measurementUrls.temperature, {
        zone_7_temperature: globalTemperature ?? payload.zone_7_temperature,
        zone_8_temperature: globalTemperature ?? payload.zone_8_temperature,
        zone_9_temperature: globalTemperature ?? payload.zone_9_temperature,
        zone_a_temperature: globalTemperature ?? payload.zone_a_temperature,
      })

      addSuccessNotification('Temperatura została zmieniona!')
    }

    toggleIsVisible()
  }, methods.setError)

  const scenariosOption = (floorHeatingAppData?.available_scenarios ?? []).map(scenario =>
    createSelectOption(scenario.name, scenario.mc_comp_id),
  )

  return (
    <Form onSubmit={onSubmit} methods={methods}>
      <ModalHeader toggle={toggleIsVisible}>Zmiana parametrów ogrzewania lokalu {apartmentId}</ModalHeader>
      <ContentLoader isLoading={isFetchingFloorHeatingAppData}>
        <ModalBody>
          <Row>
            {field === 'scenario' && (
              <FormSelect
                options={scenariosOption}
                name="scenario"
                label="Scenariusz"
                selectProps={{ placeholder: 'Wybierz scenariusz' }}
              />
            )}
            {field === 'temperature' && <FloorHeatingDetailsTemperatureParameterChange />}
          </Row>
        </ModalBody>
      </ContentLoader>

      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
