import * as React from 'react'
import { ClientDetails, ClientUser } from '@models/clients'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectGet } from '@store/actions/generic-actions'
import { FormSelect } from '@hyper/forms/form-select'
import { Row } from 'reactstrap'
import { ReservationCreateFormInputs } from '@modules/reservations/create/models'
import { useFormContext } from 'react-hook-form'
import { createSelectOption } from '@helpers/utils'
import { parseISODate } from '@helpers/date-helper'
import { SubscriptionCodeFeature } from '@modules/subscription/models'

interface Props {
  client: ClientUser
}

export const StepClientSubscriptions: React.FC<Props> = ({ client }) => {
  const [clientDetails, setClientDetails] = React.useState<ClientDetails>()
  const { action: fetchClientDetails } = useApiRequest(async () => {
    setClientDetails(await commonObjectGet<ClientDetails>(client.urls.details))
  })

  React.useEffect(() => {
    fetchClientDetails(client)
  }, [client.urls.details])

  const { resetField } = useFormContext<ReservationCreateFormInputs>()

  React.useEffect(() => () => resetField('promocode'), [])

  const subscriptions = clientDetails?.subscriptions.filter(subscription => subscription.status === 'confirmed') || []
  const genericVouchers = clientDetails?.generic_vouchers.filter(row => row.status === 'confirmed') || []

  const getUsageYear = (features: SubscriptionCodeFeature[], created: string) => {
    const createdYear = parseISODate(created)?.getFullYear() || 0

    return features
      .map(feature =>
        feature.years.length
          ? `- do wykorzystania w ${feature.years.map(year => createdYear + year).join(', ')}`
          : '- bez ograniczeń dat',
      )
      .join('. ')
  }

  const options = React.useMemo(() => {
    const subscriptionCodes = subscriptions.map(subscription => ({
      label: `${subscription.type_display}: ${subscription.number}`,
      options: subscription.codes.reduce(
        (codes, code) =>
          code.is_used
            ? codes
            : [
                ...codes,
                createSelectOption(`${code.code} ${getUsageYear(code.features, subscription.created)}`, code.code),
              ],
        [],
      ),
    }))

    const genericVoucherCodes = genericVouchers.map(genericVoucher => ({
      label: `Voucher: ${genericVoucher.number}`,
      options: genericVoucher.promocode_assigns
        .filter(row => row.promocode && !row.promocode.is_used)
        .map(row => ({
          value: row.promocode?.code || '',
          label: row.promocode?.code || '',
        })),
    }))

    return subscriptionCodes.concat(genericVoucherCodes)
  }, [subscriptions])

  return (
    <Row>
      <FormSelect
        options={options}
        name="promocode"
        label="Kody promocyjne:"
        formPlainProps={{ formGroupClassName: 'px-0' }}
        selectProps={{
          isClearable: true,
          isCreatable: true,
          noOptionsMessage: () => 'Brak przypisanych kodów',
          placeholder: 'Wpisz lub wybierz kod',
        }}
      />
    </Row>
  )
}
