import * as React from 'react'
import { ReceptionBookingDetailsPrices } from '@models/reception'
import { asDecimal } from '@helpers/utils'
import { ReservationBillExpandedAdditionalChargesRow } from '@modules/reservations/details/bill/additional-charges/reservation-bill-expanded-additional-charges-row'

interface Props {
  prices: ReceptionBookingDetailsPrices
}

export const ReservationBillExpandedAdditionalCharges = ({ prices }: Props): JSX.Element => {
  const hasServiceCharge = asDecimal(prices.service_charge_brutto).gt(0)

  return (
    <table className="table table-sm col-9 ml-auto mb-0 font-12 table-striped">
      <tbody>
        <ReservationBillExpandedAdditionalChargesRow
          title="Opłata klimatyczna"
          price={prices.climatic.total_price_brutto}
        />
        {hasServiceCharge && (
          <ReservationBillExpandedAdditionalChargesRow
            title="Opłata eksploatacyjna za części wspólne"
            price={prices.service_charge_brutto}
          />
        )}
      </tbody>
    </table>
  )
}
