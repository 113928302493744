import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useFormRequest } from '@components/hooks/use-api-request'
import { useForm } from 'react-hook-form'
import { ClientCreateModalFormBase } from '@modules/crm/clients/create-modal/form-base'
import { ClientCreateModalFormAddress } from '@modules/crm/clients/create-modal/form-address'
import { ModalBodyHr } from '@components/modals/modal-body-hr'
import { ClientCreateModalFormMarketing } from '@modules/crm/clients/create-modal/form-marketing'
import { ClientCreateModalFormInvoice } from '@modules/crm/clients/create-modal/form-invoice'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { commonObjectPost } from '@store/actions/generic-actions'
import { ClientDetails } from '@models/clients'
import { updateClientDetails } from '@store/actions/crm-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { BaseModalProps } from '@components/modals/types'
import { useCountries } from '@components/hooks/use-countries'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { Form } from '@hyper/forms/form'

export interface ClientCreateModalFormInputs {
  first_name: string
  last_name: string
  phone: string
  email: string
  client_kind: CustomReactSelectOption | null
  invoice: boolean
  invoice_city: string
  invoice_company: string
  invoice_country: string
  invoice_country_display: string
  invoice_nip: string
  invoice_postcode: string
  invoice_street: string
  postcode: string
  source_marketing_id: number | null
  street: string
  source_marketing: any
  country: CustomReactSelectOption
}

export const ClientCreateModal: React.FC<BaseModalProps> = ({ toggleIsVisible }) => {
  const { countriesOptions, defaultCountryOption } = useCountries()

  const methods = useForm<ClientCreateModalFormInputs>({
    defaultValues: {
      invoice: false,
      country: defaultCountryOption,
    },
  })
  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()

  const url = useAppSelector((state: RootState) => state.appState.appData.urls.clients.clients)

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: ClientCreateModalFormInputs) => {
    dispatch(
      updateClientDetails(
        await commonObjectPost<ClientDetails>(url, {
          ...payload,
          client_kind: payload.client_kind?.value,
          source_marketing: payload.source_marketing?.value,
          country: payload.country.value,
        }),
        true,
      ),
    )
    addSuccessNotification('Klient został dodany')
    toggleIsVisible()
  }, methods.setError)

  return (
    <Form onSubmit={onSubmit} methods={methods}>
      <ModalHeader toggle={toggleIsVisible}>Dodaj Gościa</ModalHeader>
      <ModalBody>
        <ClientCreateModalFormBase />
        <ModalBodyHr />
        <ClientCreateModalFormAddress countriesOptions={countriesOptions} />
        <ModalBodyHr />
        <ClientCreateModalFormInvoice />
        <ModalBodyHr />
        <ClientCreateModalFormMarketing />
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Anuluj
        </Button>
        <SaveButton className="btn btn-green" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
