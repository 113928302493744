import * as React from 'react'
import { TechnicalOrder } from '@models/technical-orders'
import Table from '@components/table/table'
import { Button } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'

interface Props {
  technicalOrder: TechnicalOrder
}

export const TechnicalOrdersDoneTableRowActions: React.FC<Props> = ({ technicalOrder }) => {
  const [handleEditTime] = useModal('TechnicalOrderWorkTimeModal', { technicalOrder })

  return (
    <Table.Cell className="text-right vertical-align-middle text-nowrap">
      <Button color="light" onClick={handleEditTime}>
        Edytuj czas
      </Button>
    </Table.Cell>
  )
}
