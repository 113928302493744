import * as React from 'react'
import { getReactSelectStyles } from '@components/custom-react-select'
import { FormSelect, FormSelectProps } from '@hyper/forms/form-select'
import { FormPlain, FormPlainProps } from '@hyper/forms/index'
import { useFormContext, useWatch } from 'react-hook-form'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'

interface Props {
  formPlainProps: Omit<FormPlainProps, 'name'>
  formParams: FormSelectProps
  categoryFormParams: FormSelectProps
}

export const FormSelectWithCategories = ({ formParams, categoryFormParams, formPlainProps }: Props): JSX.Element => {
  const { control, setValue } = useFormContext()
  const selectedCategory = useWatch({ control, name: categoryFormParams.name })

  useDidUpdateEffect(() => {
    setValue(formParams.name, null)
  }, [selectedCategory])

  return (
    <FormPlain {...formPlainProps} name={formParams.name} formGroupClassName="d-flex">
      <FormSelect
        {...formParams}
        formPlainProps={{
          ...formParams.formPlainProps,
          colSize: 'col-auto',
          colStyle: { ...formParams.formPlainProps?.colStyle, paddingInline: 0 },
        }}
        selectProps={{ ...formParams.selectProps, selectStyles: selectStyles }}
      />
      <FormSelect
        {...categoryFormParams}
        selectProps={{ ...categoryFormParams.selectProps, selectStyles: categorySelectStyles }}
        formPlainProps={{
          ...categoryFormParams.formPlainProps,
          colSize: 'col-auto',
          colStyle: { ...categoryFormParams.formPlainProps?.colStyle, paddingInline: 0 },
        }}
      />
    </FormPlain>
  )
}

const selectStyles = {
  ...getReactSelectStyles({ hasError: false }),
  control: provided => ({
    ...provided,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: 'none',
    height: 35,
    minHeight: 35,
    boxShadow: 'none',
    borderLeft: '1px solid #DEE2E6',
    borderTop: '1px solid #DEE2E6',
    borderBottom: '1px solid #DEE2E6',
    '&:hover': {
      cursor: 'pointer',
      borderColor: '#DEE2E6',
    },
  }),
}

const categorySelectStyles = {
  ...getReactSelectStyles({ hasError: false }),
  control: provided => ({
    ...provided,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeftColor: '#DEE2E6',
    height: 35,
    boxShadow: 'none',
    minHeight: 35,
    border: '1px solid #DEE2E6',
    '&:hover': {
      cursor: 'pointer',
      borderColor: '#DEE2E6',
    },
  }),
}
