import * as React from 'react'
import { useTechnicalOrderPreviewModal } from '@modules/technical-orders/hooks/use-technical-order-preview-modal'
import { DraggableTableRow } from '@components/table/draggable-table-row'
import { TechnicalOrdersOrderedTableRow } from '@modules/technical-orders/technical-orders-ordered/row'
import { TechnicalOrder } from '@models/technical-orders'

interface Props {
  technicalOrder: TechnicalOrder
  resortId?: number
  selectableCell: (order: TechnicalOrder) => React.ReactNode
  index: number
  isDragDisabled: boolean
}

export const TechnicalOrdersOrderedDraggableTableRow = ({
  technicalOrder,
  resortId,
  selectableCell,
  index,
  isDragDisabled,
}: Props): JSX.Element => {
  const showPreview = useTechnicalOrderPreviewModal({ technicalOrder })

  return (
    <DraggableTableRow
      rowProps={{ onDoubleClick: showPreview }}
      draggableProps={{
        index,
        draggableId: `${technicalOrder.id}`,
        isDragDisabled,
      }}
    >
      <TechnicalOrdersOrderedTableRow
        showUsers={true}
        showResort={!resortId}
        technicalOrder={technicalOrder}
        isDragDisabled={isDragDisabled}
        showDragHandler={true}
      >
        {selectableCell(technicalOrder)}
      </TechnicalOrdersOrderedTableRow>
    </DraggableTableRow>
  )
}
