import * as React from 'react'
import { Button, Col, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { SaveButton } from '@hyper/button'
import { ClientUser } from '@models/clients'
import { useForm } from 'react-hook-form'
import {
  GastroCard,
  GastroCardActiveAfterKind,
  GastroCardDetails,
  GastroCardKind,
  InvoiceType,
} from '@models/promotions'
import { SellersList, useSellersOptions } from '@components/hooks/use-grouped-sellers'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useFormRequest } from '@components/hooks/use-api-request'
import { useGroupedSources } from '@components/hooks/use-grouped-sources'
import { BaseModalProps } from '@components/modals/types'
import { commonObjectPut } from '@store/actions/generic-actions'
import { updateGastroCardDetails } from '@store/slices/gastro-card-slice'
import { createGastroCards } from '@store/actions/gastro-card-actions'
import { CustomReactSelectOption, makeDefaultSelectOption } from '@components/custom-react-select'
import { startOfToday } from 'date-fns'
import {
  ReceptionBookingCheckStep,
  ReceptionBookingCheckSteps,
  showStepError,
} from '@modules/reception/common/reception-booking-check-steps'
import { GastroCardDialogDataStep } from '@modules/promotions/gastro-cards/gastro-card-dialog/gastro-card-dialog-data-step'
import { GastroCardDialogSummaryStep } from '@modules/promotions/gastro-cards/gastro-card-dialog/gastro-card-dialog-summary-step'
import { useProductSaleConfirmationModal } from '@components/sale/use-product-sale-confirmation-modal'
import { unwrapResult } from '@reduxjs/toolkit'
import { useAppDispatch } from '@store/index'
import * as R from 'ramda'
import { extractSelectOptionsValues } from '@helpers/utils'
import { Form } from '@hyper/forms/form'
import { useMultipleResorts } from '@components/hooks/use-multiple-resorts'
import { useLocation, useNavigate } from 'react-router-dom'

const steps: ReceptionBookingCheckStep[] = [
  {
    step: 1,
    description: 'Parametry sprzedaży',
    fields: ['client', 'resorts', 'declared_sell_price'],
  },
  {
    step: 2,
    description: 'Podsumowanie sprzedaży',
  },
]

interface Props extends BaseModalProps {
  refresh?: () => void
  gastroCard?: GastroCard
  isSellerReadonly?: boolean
  gastroCardClient?: ClientUser
  kind: GastroCardKind
}

export interface GastroCardDialogFormInputs {
  seller: SellersList | undefined
  client: ClientUser
  sell_date: Date
  active_after_kind: GastroCardActiveAfterKind
  source_marketing: any
  invoice_type: InvoiceType
  declared_sell_price: number
  invoice_nip: string
  invoice_company: string
  invoice_street: string
  invoice_postcode: string
  invoice_city: string
  is_departure_voucher_promotion: boolean
  expire_after_years: number
  resorts: CustomReactSelectOption[]
}

export const GastroCardDialog: React.FC<Props> = ({
  toggleIsVisible,
  isSellerReadonly,
  gastroCard,
  gastroCardClient,
  refresh,
}) => {
  const [step, setStep] = React.useState(1)
  const [client, setClient] = React.useState<ClientUser | undefined>(gastroCardClient)

  const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()
  const { showConfirmationPackageCreation } = useProductSaleConfirmationModal()
  const navigate = useNavigate()
  const location = useLocation()

  const sources = useGroupedSources()

  const gastroCardDefaultValues = React.useMemo(
    () =>
      gastroCard
        ? R.pick(
            [
              'client',
              'sell_date',
              'active_after_kind',
              'source_marketing',
              'invoice_type',
              'declared_sell_price',
              'invoice_nip',
              'invoice_company',
              'invoice_street',
              'invoice_postcode',
              'invoice_city',
              'expire_after_years',
              'is_departure_voucher_promotion',
            ],
            gastroCard,
          )
        : {},
    [gastroCard],
  )

  const methods = useForm<GastroCardDialogFormInputs>({
    defaultValues: {
      active_after_kind: 'next_year',
      sell_date: startOfToday(),
      ...gastroCardDefaultValues,
      source_marketing: sources.find(row => row.value === gastroCard?.source_marketing),
      seller: makeDefaultSelectOption(useSellersOptions(), gastroCard?.seller_id),
      expire_after_years: gastroCard?.expire_after_years ?? 0,
      resorts: [],
    },
  })

  const { getResortOptions, getSelectedResortsIds, getSelectedResortsOptions } = useMultipleResorts({
    methods,
    field: 'resorts',
  })

  const resortOptions = React.useMemo(() => getResortOptions({ withAllOption: true }), [])

  React.useEffect(
    () =>
      methods.setValue(
        'resorts',
        getSelectedResortsOptions(resortOptions, gastroCard?.resorts ?? [], { withAllOption: true }),
      ),
    [gastroCard?.resorts],
  )

  React.useEffect(() => methods.clearErrors('client'), [client])

  const { isLoading, action: onSubmit } = useFormRequest(async data => {
    try {
      data = {
        ...data,
        seller: data.seller?.value,
        source_marketing: data.source_marketing?.value,
        resorts: getSelectedResortsIds(data.resorts),
        client: client?.id,
        kind: 'normal',
      }

      if (gastroCard) {
        dispatch(
          updateGastroCardDetails(
            await commonObjectPut<GastroCardDetails>(gastroCard.urls.details, extractSelectOptionsValues(data)),
          ),
        )
        addSuccessMessage('Sukces', 'Dane zostały zmienione.')
      } else {
        const resultAction = await dispatch<any>(createGastroCards(extractSelectOptionsValues(data)))
        const gastroCard = unwrapResult(resultAction)
        showConfirmationPackageCreation(() => {
          navigate(location.pathname + `/${gastroCard.id}`)
        })
      }

      refresh?.()
      toggleIsVisible()
    } catch (error) {
      console.log(error)
      showStepError(setStep, steps, error)
      throw error
    }
  }, methods.setError)

  const handlePrevStep = () => setStep(curr => Math.max(1, curr - 1))
  const handleNextStep = () => setStep(curr => curr + 1)

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>{gastroCard ? 'Edytuj' : 'Dodaj'} Voucher Gastro</ModalHeader>
      {!gastroCard && <ReceptionBookingCheckSteps step={step} steps={steps} />}
      <Form onSubmit={onSubmit} methods={methods}>
        <ModalBody className="pb-0">
          {step === 1 && (
            <GastroCardDialogDataStep
              resortOptions={resortOptions}
              gastroCard={gastroCard}
              isSellerReadonly={isSellerReadonly}
              client={client}
              setClient={setClient}
            />
          )}
          {step === 2 && <GastroCardDialogSummaryStep client={client} />}
        </ModalBody>
        <ModalFooter className="d-block">
          <Row>
            <Col md={6} className="p-0">
              {step !== 1 && (
                <Button onClick={handlePrevStep} className="btn btn-light">
                  Wstecz
                </Button>
              )}
            </Col>
            <Col md={6} className="text-right p-0">
              {gastroCard || step === 2 ? (
                <SaveButton
                  role="submit"
                  labelSaving={gastroCard ? 'Zapisywanie...' : 'Sprzedawanie...'}
                  label={gastroCard ? 'Zapisz' : 'Sprzedaj'}
                  className="btn btn-green"
                  isSaving={isLoading}
                />
              ) : (
                <Button onClick={handleNextStep} className="btn btn-green" role="next-step" disabled={!client}>
                  Następny krok
                </Button>
              )}
            </Col>
          </Row>
        </ModalFooter>
      </Form>
    </>
  )
}
