import { createAsyncThunk } from '@reduxjs/toolkit'
import { ListDataWithAggregation, PaginationResponseWithAggregation } from '@models/dashboard'
import { AsyncThunkParams } from '@store/index'
import { commonObjectGet } from '@store/actions/generic-actions'
import { extractSelectOptionsValues } from '@helpers/utils'
import { setDashboardStandardPaginator } from '@store/actions/dashboard-actions'
import { EcommerceStatsAggregation, EcommerceStatsItem, EcommerceStatsParams } from '@modules/ecommerce/models'

export const getEcommerceStats = createAsyncThunk<
  ListDataWithAggregation<EcommerceStatsItem[], EcommerceStatsAggregation>,
  EcommerceStatsParams,
  AsyncThunkParams
>('ecommerce/getEcommerceStats', async (filters, { getState, dispatch }) => {
  const data = await commonObjectGet<PaginationResponseWithAggregation<EcommerceStatsItem, EcommerceStatsAggregation>>(
    getState().appState.appData.urls.ecommerce.stats,
    extractSelectOptionsValues(filters),
  )

  dispatch(setDashboardStandardPaginator(data))
  return { results: data.results, aggregation: data.aggregation }
})
