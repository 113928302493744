import * as React from 'react'
import { EventRow } from '@modules/rcp/user/events/event-row'
import { EventsSummary } from '@modules/rcp/user/events/events-summary'
import { RcpEventDay, RcpUserDetail } from '@models/rcp'
import { rcpEventDaySelector, rcpMachinesSelector } from '@store/slices/rcp-slice'
import { useAppSelector } from '@store/index'

interface EventsTableProps {
  user: RcpUserDetail
}

export const EventsTable: React.FC<EventsTableProps> = ({ user }) => {
  const machines = useAppSelector(rcpMachinesSelector)
  const eventsDays = useAppSelector(rcpEventDaySelector)

  return (
    <table className="table table-centered rcp__events-table">
      <colgroup>
        <col width="12%" />
        <col width="12%" />
        <col width="12%" />
        <col width="12%" />
        <col width="15%" />
        <col width="15%" />
        <col width="15%" />
        <col width="7%" />
      </colgroup>
      <thead className="thead-light">
        <tr>
          <th>Dzień</th>
          <th>Start</th>
          <th>Koniec</th>
          <th>Czas pracy</th>
          <th>Miejsce startu</th>
          <th>Miejsce Końca</th>
          <th>Uwagi</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {eventsDays.map((eventDay: RcpEventDay, index: number) => (
          <EventRow user={user} key={eventDay.date} eventDay={eventDay} machines={machines} index={index} />
        ))}
        {eventsDays && <EventsSummary eventsDays={eventsDays} user={user} />}
      </tbody>
    </table>
  )
}
