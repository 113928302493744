import * as React from 'react'
import { GastroCardDetailsView } from '@modules/promotions/gastro-cards/details-view'
import { PackageDetailsView } from '@modules/package/list/details-view'
import { PackageWholesaleDetailsView } from '@modules/package-wholesale/list/details-view'
import { PackageVipDetailsView } from '@modules/promotions/package-vip/details_view'

interface Props {
  model: string
  id: string
}

export const SearchbarDetails = ({ id, model }: Props): JSX.Element | null => {
  if (model === 'gastrocard') {
    return <GastroCardDetailsView gastro_card_id={id} kind="normal" />
  } else if (model === 'package') {
    return <PackageDetailsView package_id={id} />
  } else if (model === 'packagewholesale') {
    return <PackageWholesaleDetailsView package_wholesale_id={id} />
  } else if (model === 'packagevip') {
    return <PackageVipDetailsView id={parseInt(id, 10)} />
  }

  return null
}
