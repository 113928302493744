import * as React from 'react'
import Table from '@components/table/table'
import { EnhancedFloorHeatingMeasurement } from '@modules/floor-heating/models'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { YesNoBadge } from '@components/badges/yes-no'
import { formatTemperature } from '@helpers/utils'

interface Props {
  measurement: EnhancedFloorHeatingMeasurement
}

export const FloorHeatingDetailsHistoryTableRow: React.FC<Props> = ({ measurement }) => (
  <Table.Row className="cursor-pointer" role="details-row">
    <Table.Cell className="align-middle"> {toDefaultDateTimeFormat(measurement.date_timestamp)}</Table.Cell>
    <Table.Cell className="align-middle">
      {measurement.urls.booking ? (
        <a href={measurement.urls.booking}>
          <YesNoBadge value={true} />
        </a>
      ) : (
        <YesNoBadge value={false} />
      )}
    </Table.Cell>
    <Table.Cell className="align-middle">
      <YesNoBadge value={!measurement.error} />
    </Table.Cell>
    <Table.Cell className="align-middle">{formatTemperature(measurement.zone_7_temperature)}</Table.Cell>
    <Table.Cell className="align-middle">{formatTemperature(measurement.zone_8_temperature)}</Table.Cell>
    <Table.Cell className="align-middle">{formatTemperature(measurement.zone_9_temperature)}</Table.Cell>
    <Table.Cell className="align-middle">{formatTemperature(measurement.zone_a_temperature)}</Table.Cell>
    <Table.Cell className="align-middle">{measurement.error ? measurement.error.description : ''}</Table.Cell>
  </Table.Row>
)
