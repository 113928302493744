import * as React from 'react'
import { Button, Col, Row } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'

export const PackageVipExportButtons: React.FC = () => {
  const [handleIsOpen] = useModal('ReportsGeneratorPackageVip')

  return (
    <Row>
      <Col md={12} className="text-right">
        <Button color="secondary" className="text-semi-strong" onClick={handleIsOpen}>
          <i className="uil-download-alt mr-1" />
          Pobierz
        </Button>
      </Col>
    </Row>
  )
}
