import * as React from 'react'
import { ReservationStatsTableCells } from '@modules/reservations/models'
import { ReservationStatsTableExpandedRow } from '@modules/reservations/statistics/booking-stats/stats-details/stats-details-table/reservation-stats-table-expanded-row'

interface ReservationStatsFeedingRowsProps {
  getCells: (element: string) => ReservationStatsTableCells
}

export const ReservationStatsFeedingRows: React.FC<ReservationStatsFeedingRowsProps> = ({ getCells }) => (
  <>
    <ReservationStatsTableExpandedRow title="Sprzedanych śniadań" cells={getCells('breakfast')} />
    <ReservationStatsTableExpandedRow
      title="Potencjał do sprzedaży śniadań"
      cells={getCells('breakfast_sales_potential')}
    />
    <ReservationStatsTableExpandedRow title="Sprzedanych obiadokolacji" cells={getCells('dinner')} />
    <ReservationStatsTableExpandedRow
      title="Potencjał do sprzedaży obiadokolacji"
      cells={getCells('dinner_sales_potential')}
    />
  </>
)
