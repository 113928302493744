import * as React from 'react'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { PaymentTableRow } from '@modules/subscription-contract/details/payements/payment-table-row'
import { SubscriptionContractDetails, SubscriptionContractPeriod } from '@modules/subscription-contract/models'

const headerValues: TableHeaderValue[] = [
  { title: 'LP' },
  { title: 'Numer faktury' },
  { title: 'Status' },
  { title: 'Data planowanej wpłaty' },
  { title: 'Wpłata' },
  { title: '' },
]

const defaultFilters: TableFilters = {
  search: '',
  ordering: '',
  page: 1,
  page_size: 9999,
}

interface Props {
  subscriptionDetails: SubscriptionContractDetails
}

export const PaymentTable = ({ subscriptionDetails }: Props): JSX.Element => {
  const [filters, setFilters] = React.useState<TableFilters>(defaultFilters)

  return (
    <Table
      maxHeight="330px"
      className="table-hover w-100"
      headerValues={headerValues}
      isLoading={false}
      showPagination={false}
      filters={filters}
      setFilters={setFilters}
    >
      {subscriptionDetails.periods.map((period: SubscriptionContractPeriod, index: number) => (
        <PaymentTableRow key={period.id} lp={index + 1} period={period} />
      ))}
    </Table>
  )
}
