import * as React from 'react'
import { CleaningOrderDetails } from '@modules/housekeeping/models'
import { HousekeepingCleaningOrderDetailsNotesRow } from '@modules/housekeeping/cleaning-orders/details/notes/row'

interface Props {
  cleaningOrderDetails: CleaningOrderDetails
}

export const HousekeepingCleaningOrderDetailsNotes: React.FC<Props> = ({ cleaningOrderDetails }) => (
  <>
    <h4 className="mb-0">Notatki</h4>
    <hr className="mt-1" />
    <div className="inbox-widget">
      {cleaningOrderDetails.notes.map(note => (
        <HousekeepingCleaningOrderDetailsNotesRow key={note.id} note={note} />
      ))}
      {!cleaningOrderDetails.notes.length && <p className="text-center">Brak notatek</p>}
    </div>
  </>
)
