import * as React from 'react'
import { Col, Row } from 'reactstrap'

interface Props {
  title: React.ReactNode
  value: React.ReactNode
}

export const DataRow = ({ title, value }: Props) => (
  <Row className="align-items-center py-1 text-semi-strong">
    <Col md={6}>{title}</Col>
    <Col md={6}>{value}</Col>
  </Row>
)
