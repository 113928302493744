import * as React from 'react'
import { Button, Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useSellersOptions } from '@components/hooks/use-grouped-sellers'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { ClientDetails, ClientUser } from '@models/clients'
import { useFormRequest } from '@components/hooks/use-api-request'
import { CustomReactSelectOption, makeDefaultSelectOption } from '@components/custom-react-select'
import { BaseModalProps } from '@components/modals/types'
import {
  ReceptionBookingCheckStep,
  ReceptionBookingCheckSteps,
  showStepError,
} from '@modules/reception/common/reception-booking-check-steps'
import { useProductSaleConfirmationModal } from '@components/sale/use-product-sale-confirmation-modal'
import { useModal } from '@components/modals/use-modal'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { extractSelectOptionsValues } from '@helpers/utils'
import { createInvoiceData, InvoiceData } from '@components/products/product-invoice-card'
import { BookingOptionDetails } from '../model'
import { BookingOptionCreateDialogDataStep } from '@modules/promotions/booking-options/create/booking-option-create-dialog-data-step'
import { BookingOptionCreateDialogSummaryStep } from '@modules/promotions/booking-options/create/booking-option-create-dialog-summary-step'
import { useCreateBookingOptionMutation, useUpdateBookingOptionMutation } from '@api/booking-options'

export interface BookingOptionCreateFormInputs extends InvoiceData {
  type: CustomReactSelectOption
  options: { [key: string]: { checked: boolean; amount: number } }
  seller: CustomReactSelectOption | null
  client: ClientUser
}

const steps: ReceptionBookingCheckStep[] = [
  { step: 1, description: 'Parametry sprzedaży', fields: ['client', 'sell_date', 'options'] },
  { step: 2, description: 'Podsumowanie sprzedaży' },
]

interface Props extends BaseModalProps {
  optionDetails?: BookingOptionDetails
  clientDetails?: ClientDetails
}

export const BookingOptionCreateDialog: React.FC<Props> = ({ clientDetails, toggleIsVisible, optionDetails }) => {
  const [showBookingOptionDetails] = useModal('BookingOptionDetailsDialog', {}, { persist: true })

  const [step, setStep] = React.useState(1)
  const [client, setClient] = React.useState<ClientUser | undefined>(clientDetails || optionDetails?.client)

  const { addSuccessMessage } = useNotificationHook()
  const { showConfirmationPackageCreation } = useProductSaleConfirmationModal()

  const [createBookingOption] = useCreateBookingOptionMutation()
  const [updateBookingOption] = useUpdateBookingOptionMutation()

  const methods = useForm<BookingOptionCreateFormInputs>({
    defaultValues: {
      ...(optionDetails && { client: optionDetails?.client, ...createInvoiceData(optionDetails) }),
      options: optionDetails
        ? optionDetails.option_kinds.reduce(
            (options, option) =>
              options[option]
                ? { ...options, [option]: { ...options[option], amount: options[option].amount + 1 } }
                : { ...options, [option]: { checked: true, amount: 1 } },
            {},
          )
        : {},
      seller: makeDefaultSelectOption(useSellersOptions(), optionDetails?.seller_id),
    },
  })

  const { isLoading, action: onSubmit } = useFormRequest(
    async ({ options, ...formData }: BookingOptionCreateFormInputs) => {
      const optionKinds = Object.entries(options).reduce(
        (options, [kind, { checked, amount }]) =>
          checked ? [...options, ...[...Array(amount).keys()].map(() => kind)] : options,
        [],
      )

      const payload = { ...(extractSelectOptionsValues(formData) as {}), client: client?.id, option_kinds: optionKinds }

      try {
        if (optionDetails) {
          await updateBookingOption({ url: optionDetails.urls.details, data: payload })
          addSuccessMessage('Sukces', 'Dane zostały zmienione.')
        } else {
          const option = await createBookingOption(payload).unwrap()
          showConfirmationPackageCreation(() => showBookingOptionDetails(null, { option }))
        }
        toggleIsVisible()
      } catch (error) {
        showStepError(setStep, steps, error)
        throw error
      }
    },
    methods.setError,
  )

  const handlePrevStep = () => setStep(curr => Math.max(1, curr - 1))
  const handleNextStep = () => setStep(curr => curr + 1)

  const formData = useWatch({ control: methods.control })

  const isNextStepDisabled = React.useMemo(() => {
    if (step === 1) {
      const isAnyOptionSelected = formData.options && Object.values(formData.options).some(value => value?.checked)

      return !isAnyOptionSelected || !client || !formData.seller
    }

    return false
  }, [formData, step, client])

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggleIsVisible}>
          {optionDetails ? 'Edytuj opcję rezerwacji' : 'Dodaj opcję do rezerwacji'}
        </ModalHeader>
        {!optionDetails && <ReceptionBookingCheckSteps step={step} steps={steps} />}
        <ModalBody className="pb-0">
          {step === 1 && (
            <BookingOptionCreateDialogDataStep
              showClient={!clientDetails}
              client={client}
              onClientSet={setClient}
              optionDetails={optionDetails}
            />
          )}
          {step === 2 && <BookingOptionCreateDialogSummaryStep client={client} />}
        </ModalBody>
        <ModalFooter className="d-block">
          <Row>
            <Col md={6} className="p-0">
              {step !== 1 && (
                <Button onClick={handlePrevStep} className="btn btn-light">
                  Wstecz
                </Button>
              )}
            </Col>
            <Col md={6} className="text-right p-0">
              {optionDetails || step === 2 ? (
                <SaveButton
                  role="submit"
                  labelSaving={optionDetails ? 'Zapisywanie...' : 'Sprzedawanie...'}
                  label={optionDetails ? 'Zapisz' : 'Sprzedaj'}
                  className="btn btn-green"
                  isSaving={isLoading}
                />
              ) : (
                <Button
                  onClick={handleNextStep}
                  className="btn btn-green"
                  role="next-step"
                  disabled={isNextStepDisabled}
                >
                  Następny krok
                </Button>
              )}
            </Col>
          </Row>
        </ModalFooter>
      </form>
    </FormProvider>
  )
}
