import * as React from 'react'
import { ModalConfig, ModalParams, ModalTypes } from '@components/modals/types'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { findModalSelector, setModalIsOpen, setShowModal } from '@store/slices/modal-slice'

export const useModal = (modalName: ModalTypes, modalParams?: ModalParams, config?: ModalConfig) => {
  const dispatch = useAppDispatch()
  const hasModal = !!useAppSelector((state: RootState) => findModalSelector(state, modalName))

  React.useEffect(
    () => () => {
      config?.persist ? null : dispatch(setModalIsOpen({ modal: modalName, isOpen: false }))
    },
    [],
  )

  const toggleIsOpen = React.useCallback(
    (event?, optionalParams?: ModalParams, showModal?: boolean) => {
      event?.preventDefault?.()

      if (hasModal && !showModal) {
        return hideModal()
      } else {
        return dispatch(
          setShowModal({ modal: modalName, params: { ...optionalParams, ...modalParams }, isOpen: true, config }),
        )
      }
    },
    [modalName, hasModal, modalParams],
  )

  const hideModal = React.useCallback(() => dispatch(setModalIsOpen({ modal: modalName, isOpen: false })), [modalName])

  return [toggleIsOpen, hideModal]
}
