import * as React from 'react'
import { ClientDetails } from '@models/clients'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useAppDispatch } from '@store/index'
import { updateClientDetails } from '@store/actions/crm-actions'
import { commonObjectPatch } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { YesNoBadge } from '@components/badges/yes-no'
import { ClientDetailsModalBaseDataRow } from '@modules/crm/clients/details-modal/base-data/data-row'
import classnames from 'classnames'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'

interface Props {
  client: ClientDetails
}

export const ClientDetailsModalBaseDataServiceCharge: React.FC<Props> = ({ client }) => {
  const user = useAuthenticatedUser()

  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()

  const { isLoading, action: handleRemove } = useApiRequest(async () => {
    dispatch(
      updateClientDetails(
        await commonObjectPatch<ClientDetails>(client.profile.urls.details, {
          skip_service_charge: !client.profile.skip_service_charge,
        }),
      ),
    )
    addSuccessNotification('Zmiany zostały zapisane!')
  })

  const canChangeSkipServiceCharge = user.hasPerm(UserPermission.ClientCanSkipServiceCharge)

  return (
    <ClientDetailsModalBaseDataRow name="Sprzeciw wobec opłaty eksploatacyjnej:">
      <CommonObjectConfirmAction
        title={
          client.profile.skip_service_charge
            ? 'Skasuj sprzeciw wobec opłaty eksploatacyjnej'
            : 'Dodaj sprzeciw wobec opłaty eksploatacyjnej'
        }
        isLoading={isLoading}
        handleAccept={canChangeSkipServiceCharge ? handleRemove : undefined}
        message={
          client.profile.skip_service_charge ? (
            <>
              Czy na pewno chcesz skasować sprzeciw gościa {client.name} wyrażony wobec opłaty eksploatacyjnej?
              <br />
              <br />
              Zmiana ta <b>nie spowoduje</b> cofnięcia sprzeciwu na już założonych rezerwacjach.
              <br />
              <br />
              Można go wycofać bezpośrednio na każdej z rezerwacji.
            </>
          ) : (
            'Czy klient wyraził sprzeciw wobec aktualizacji regulaminu zawierającego informację o wprowadzeniu opłaty eksploatacyjnej drogą mailową lub inną formą pisemną?'
          )
        }
      >
        <YesNoBadge
          value={client.profile.skip_service_charge}
          className={classnames('cursor-pointer', { 'not-clickable': !canChangeSkipServiceCharge })}
        />
      </CommonObjectConfirmAction>
    </ClientDetailsModalBaseDataRow>
  )
}
