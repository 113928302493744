import * as React from 'react'
import { ProductCustomActionButton, ProductSalePageButton } from '@components/products/product-action-button'
import { PackageVipDetails } from '@modules/promotions/package-vip/models'
import { useModal } from '@components/modals/use-modal'

interface Props {
  packageVipDetails: PackageVipDetails
}

export const PackageVipActions = ({ packageVipDetails }: Props): JSX.Element => {
  const [handleAssignment] = useModal('PackageVipAssignmentDialog', { packageVipDetails })

  return (
    <div className="mt-1 text-right">
      {!packageVipDetails.has_invoice && <ProductSalePageButton saleUrl={packageVipDetails.urls.sale} />}
      {packageVipDetails.status === 'confirmed' && packageVipDetails.assignments.length === 0 && (
        <ProductCustomActionButton onClick={handleAssignment} text="Przypisz" icon="uil-link-alt" />
      )}
    </div>
  )
}
