import * as React from 'react'
import { PromocodeGroup, PromocodePrefix } from '@models/promocode'
import { Spinner, Tooltip } from 'reactstrap'
import { RootState, useAppSelector } from '@store/index'
import { commonObjectGet } from '@store/actions/generic-actions'

interface PromocodeGroupTableRowNameProps {
  group: PromocodeGroup
}

export const PromocodeGroupTableRowName: React.FC<PromocodeGroupTableRowNameProps> = ({ group }) => {
  const tooltipId = `tooltipGroupName${group.id}`
  const [tooltipOpen, setTooltipOpen] = React.useState(false)
  const [prefix, setPrefix] = React.useState<PromocodePrefix | undefined>(undefined)

  const toggle = () => setTooltipOpen(!tooltipOpen)

  const url = useAppSelector((state: RootState) => state.appState.appData.urls.rent.promocode_prefixes)

  const fetchDetails = async () => setPrefix(await commonObjectGet<PromocodePrefix>(`${url}${group.prefix_id}`))
  React.useEffect(() => {
    if (tooltipOpen && !prefix && group.prefix_id) {
      fetchDetails()
    }
  }, [tooltipOpen])

  return (
    <>
      <span id={tooltipId}>
        {group.name} ({group.codes_amount_used} z {group.codes_amount})
        {!group.is_disposable && <span className="uil uil-redo ml-2 text-info" />}
      </span>
      <Tooltip placement="top" isOpen={tooltipOpen} target={tooltipId} toggle={toggle} innerClassName="font-12">
        <div className="font-12">
          {group.prefix_id ? (
            typeof prefix === 'undefined' ? (
              <>
                <Spinner color="light" className="text-primary" size="sm" />
                <span className="ml-1">Pobieranie...</span>
              </>
            ) : (
              <>
                <div className="mb-2 text-center text-muted">Daty obowiązywania</div>
                {!prefix.dates.length && 'brak'}
                {prefix.dates.map(dates => (
                  <div key={`${dates.date_from}-${dates.date_to}`}>{`${dates.date_from} - ${dates.date_to}`}</div>
                ))}
                {!group.is_disposable && <div className="mt-2 text-strong">Kody wielokrotnego użytku</div>}
              </>
            )
          ) : (
            'Brak danych'
          )}
        </div>
      </Tooltip>
    </>
  )
}
