import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import Col from 'reactstrap/lib/Col'
import { Label, Row } from 'reactstrap'
import DateRangeInput from '@components/date/date-range-input'
import { SubmitFilter } from '@modules/reception/common/filters/submit-filter'
import * as R from 'ramda'
import {
  HouseKeepingReportFiltersParams,
  HouseKeepingUserReportFiltersParams,
} from '@modules/reports/housekeeping-users-report/index'
import { FormSelect } from '@hyper/forms/form-select'
import { CustomReactSelectOption } from '@components/custom-react-select'

interface HousekeepingReportFiltersProps {
  defaultFilters: HouseKeepingReportFiltersParams
  filters: HouseKeepingReportFiltersParams
  setFilters: (filters: HouseKeepingReportFiltersParams) => void
  resortOptions: CustomReactSelectOption[]
}

export const HousekeepingReportFilters: React.FC<HousekeepingReportFiltersProps> = ({
  filters,
  defaultFilters,
  setFilters,
  resortOptions,
}) => {
  const methods = useFormContext()

  const handleReset = () => {
    methods.reset()
    setFilters(defaultFilters)
  }

  const onSubmit = async (payload: HouseKeepingUserReportFiltersParams) => setFilters({ ...filters, ...payload })

  const methodValues = useWatch({ control: methods.control })

  const values = {
    ...filters,
    ...methodValues,
    page: filters.page,
    page_size: filters.page_size,
    ordering: filters.ordering,
    search: filters.search,
  }

  React.useEffect(() => {
    if (!R.equals(values, filters)) onSubmit(values)
  }, [values])

  return (
    <Row>
      <FormSelect
        options={resortOptions}
        name="resorts"
        label="Ośrodek"
        selectProps={{ isMulti: true }}
        formPlainProps={{ colSize: 3 }}
      />

      <Col className="col-auto" style={{ minWidth: 320 }}>
        <div className="d-flex">
          <Label className="mr-1">Zakres dat:</Label>
        </div>

        <DateRangeInput
          wrapperClassName="flex-grow-1"
          inputGroupClassName="form-group"
          startDateName="finished_at_after"
          endDateName="finished_at_before"
        />
      </Col>
      <SubmitFilter handleReset={handleReset} />
    </Row>
  )
}
