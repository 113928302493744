import * as React from 'react'
import { ClientOption } from '@models/clients'
import { YesNoBadge } from '@components/badges/yes-no'

interface Props {
  option: ClientOption
  index: number
}

export const BookingOptionDetailsOptionsRow: React.FC<Props> = ({ option, index }) => (
  <tr>
    <td>{index}.</td>
    <td>
      <a href={option.urls.admin} target="_blank">
        {option.kind_display}
      </a>
    </td>
    <td>
      <YesNoBadge value={!!option.amount && option.amount_left === 0} />
    </td>
    <td>
      {option.bookings.map(booking => (
        <a href={booking.urls.details} key={booking.id}>
          {booking.reservation_number}
        </a>
      ))}
      {!option.bookings.length && '-'}
    </td>
  </tr>
)
