import { useApiRequest } from '@components/hooks/use-api-request'
import { useDocumentTitle } from '@helpers/utils'
import { getCashRegisters } from '@store/actions/sale-actions'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import * as React from 'react'
import { CashRegisterListFilter, CashRegisterListFilterParams } from '@modules/sale/cash-register/list/filter'
import { PageTitle } from '@hyper/page-title'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { CashRegisterListTable } from '@modules/sale/cash-register/list/table'
import { useSaleAppData } from '@modules/sale/hooks/use-sale-app-data'
import { ButtonWithIcon } from '@components/button-with-icon'
import { useModal } from '@components/modals/use-modal'
import { totalSizeSelector } from '@store/selectors/dashboard'
import { useDebounce } from 'rooks'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { useParams } from 'react-router-dom'
import { ResortTabs } from '@components/resort-tabs'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'

const defaultFilters: CashRegisterListFilterParams = {
  created_after: undefined,
  created_before: undefined,
  user: undefined,
  company: undefined,
  type: undefined,
  amount: '',
  search: '',
  ordering: '-created',
  page: 1,
  page_size: 10,
}
interface Params {
  resort_id: string
}
export const CashRegisterListWithParamsView = (): JSX.Element => {
  const totalSize = useAppSelector(totalSizeSelector)
  const pageTitle = 'Kasa'
  const pageTitleWithSize = pageTitle + ` (${totalSize})`
  useDocumentTitle(pageTitleWithSize)
  useMenuActive(NavigationPath.CashRegisterList)
  const user = useAuthenticatedUser()

  const { resort_id } = useParams<'resort_id'>() as Params
  const resortId = parseInt(resort_id, 10)

  const [filters, setFilters] = React.useState<CashRegisterListFilterParams>({
    ...defaultFilters,
  })
  const dispatch = useAppDispatch()
  const cashRegisters = useAppSelector((state: RootState) => state.saleState.cashRegisters)

  const { isLoading, action: fetchSubscriptions } = useApiRequest(
    async (filters, resortId) => await dispatch(getCashRegisters({ ...filters, resort: resortId })),
  )
  useSaleAppData()

  const fetchSubscriptionsDebounced = React.useCallback(useDebounce(fetchSubscriptions, 300), [])
  React.useEffect(() => {
    fetchSubscriptionsDebounced(filters, resortId)
  }, [filters, resortId])

  const [handleClick] = useModal('ReportsGeneratorCashRegister', { filters })

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitleWithSize} />
      <Card>
        <CardBody>
          <ResortTabs resortId={resortId} url={NavigationPath.CashRegisterListWithParams} resorts={user.resorts} />

          <CashRegisterListFilter
            resortId={resortId}
            defaultFilters={defaultFilters}
            filters={filters}
            setFilters={setFilters}
          />
          <CashRegisterListTable
            isLoading={isLoading}
            cashRegisters={cashRegisters}
            filters={filters}
            setFilters={setFilters}
          />
          <Row>
            <Col md={12} className="text-right">
              <ButtonWithIcon handleClick={handleClick} icon="uil-download-alt" color="secondary" text="Eksportuj" />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}
