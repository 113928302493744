import * as React from 'react'
import { TechnicalOrdersOrderedTableRow } from '@modules/technical-orders/technical-orders-ordered/row'
import Table from '@components/table/table'
import { TechnicalOrder } from '@models/technical-orders'
import { useTechnicalOrderPreviewModal } from '@modules/technical-orders/hooks/use-technical-order-preview-modal'
import classNames from 'classnames'

interface Props {
  technicalOrder: TechnicalOrder
  children?: React.ReactNode
  showResort: boolean
  rowClassName?: string
  showUsers: boolean
}

export const TechnicalOrdersOrderedGroupedTableRow = ({
  children,
  technicalOrder,
  showUsers,
  showResort,
  rowClassName,
}: Props): JSX.Element => {
  const handlePreview = useTechnicalOrderPreviewModal({ technicalOrder })

  return (
    <Table.Row onDoubleClick={handlePreview} className={classNames('cursor-pointer', rowClassName)}>
      <TechnicalOrdersOrderedTableRow
        technicalOrder={technicalOrder}
        showUsers={showUsers}
        showResort={showResort}
        isDragDisabled={true}
        showDragHandler={false}
      >
        {children}
      </TechnicalOrdersOrderedTableRow>
    </Table.Row>
  )
}
