import * as React from 'react'

interface Props {
  name: string
  children: React.ReactNode
}

export const UpsellBookingsDetailsProductsSaleBaseRow = ({ name, children }: Props): JSX.Element => (
  <tr>
    <td className="align-middle">
      <span className="fw-semi-bold">{name}</span>
    </td>
    <td className="text-right">{children}</td>
  </tr>
)
