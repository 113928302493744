import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@store/index'
import {
  createRentalProduct,
  createRentalProductCategory,
  createRentalWarehouse,
  getRentalAppData,
  getRentalProductCategories,
  getRentalProducts,
  getRentalWarehouses,
  removeRentalProduct,
  removeRentalProductCategory,
  removeRentalWarehouse,
  updateRentalProduct,
  updateRentalProductCategory,
  updateRentalWarehouse,
} from '@store/actions/rental-warehouse-actions'
import {
  RentalAppData,
  RentalProduct,
  RentalProductCategory,
  RentalProductDetails,
  RentalWarehouse,
} from '@modules/rental/models'

interface RentalWarehouseState {
  products: EntityState<RentalProduct>
  productCategories: EntityState<RentalProductCategory>
  warehouses: EntityState<RentalWarehouse>
  productDetails: RentalProductDetails | null
  appData: RentalAppData
}

const rentalProductsAdapter = createEntityAdapter<RentalProduct>({
  sortComparer: (product_a, product_b) => product_b.id - product_a.id,
})

const rentalWarehousesAdapter = createEntityAdapter<RentalWarehouse>({
  sortComparer: (a, b) => b.id - a.id,
})

const rentalProductCategoriesAdapter = createEntityAdapter<RentalProductCategory>({
  sortComparer: (a, b) => b.id - a.id,
})

const initialState: RentalWarehouseState = {
  products: rentalProductsAdapter.getInitialState(),
  productCategories: rentalProductCategoriesAdapter.getInitialState(),
  warehouses: rentalWarehousesAdapter.getInitialState(),
  productDetails: null,
  appData: {
    status: 'unknown',
    product_categories: [],
    warehouses: [],
    urls: {
      products_import_example: '',
    },
  },
}

const rentalSlice = createSlice({
  name: 'rentalWarehouse',
  initialState,
  reducers: {
    setRentalProductDetails(state, action) {
      state.productDetails = action.payload
    },
    handleRentalProductChangeEvent(state, action: PayloadAction<RentalProduct>) {
      rentalProductsAdapter.upsertOne(state.products, action.payload)
    },
    handleRentalProductAddEvent(state, action: PayloadAction<RentalProduct>) {
      rentalProductsAdapter.upsertOne(state.products, action.payload)
    },
    setRentalAppData(state, action: PayloadAction<RentalAppData>) {
      state.appData = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getRentalAppData.fulfilled, (state, action) => {
      state.appData = { ...state.appData, ...action.payload, status: 'ready' }
    })

    builder.addCase(getRentalProducts.fulfilled, (state, action) => {
      rentalProductsAdapter.setAll(state.products, action.payload)
    })
    builder.addCase(createRentalProduct.fulfilled, (state, action) => {
      rentalProductsAdapter.upsertOne(state.products, action.payload)
    })
    builder.addCase(updateRentalProduct.fulfilled, (state, action) => {
      rentalProductsAdapter.updateOne(state.products, {
        id: action.payload.id,
        changes: action.payload,
      })

      if (state.productDetails?.id === action.payload.id) {
        state.productDetails = { ...state.productDetails, ...action.payload, urls: state.productDetails.urls }
      }
    })
    builder.addCase(removeRentalProduct.fulfilled, (state, action) => {
      rentalProductsAdapter.removeOne(state.products, action.payload.id)
    })

    builder.addCase(getRentalWarehouses.fulfilled, (state, action) => {
      rentalWarehousesAdapter.setAll(state.warehouses, action.payload)
    })
    builder.addCase(createRentalWarehouse.fulfilled, (state, action) => {
      rentalWarehousesAdapter.upsertOne(state.warehouses, action.payload)
    })
    builder.addCase(updateRentalWarehouse.fulfilled, (state, action) => {
      rentalWarehousesAdapter.updateOne(state.warehouses, {
        id: action.payload.id,
        changes: action.payload,
      })
    })
    builder.addCase(removeRentalWarehouse.fulfilled, (state, action) => {
      rentalWarehousesAdapter.removeOne(state.warehouses, action.payload.id)
    })

    builder.addCase(getRentalProductCategories.fulfilled, (state, action) => {
      rentalProductCategoriesAdapter.setAll(state.productCategories, action.payload)
    })

    builder.addCase(createRentalProductCategory.fulfilled, (state, action) => {
      rentalProductCategoriesAdapter.upsertOne(state.productCategories, action.payload)
    })
    builder.addCase(updateRentalProductCategory.fulfilled, (state, action) => {
      rentalProductCategoriesAdapter.updateOne(state.productCategories, {
        id: action.payload.id,
        changes: action.payload,
      })
    })
    builder.addCase(removeRentalProductCategory.fulfilled, (state, action) => {
      rentalProductCategoriesAdapter.removeOne(state.productCategories, action.payload.id)
    })
  },
})

export const {
  setRentalProductDetails,
  handleRentalProductChangeEvent,
  handleRentalProductAddEvent,
  setRentalAppData,
} = rentalSlice.actions

export const selectRentalProductDetails = (state: RootState) => state.rentalState.productDetails

export const { selectAll: selectRentalProducts } = rentalProductsAdapter.getSelectors(
  (state: RootState) => state.rentalState.products,
)

export const { selectAll: selectRentalWarehouses } = rentalWarehousesAdapter.getSelectors(
  (state: RootState) => state.rentalState.warehouses,
)

export const { selectAll: selectRentalProductCategories } = rentalProductCategoriesAdapter.getSelectors(
  (state: RootState) => state.rentalState.productCategories,
)

export default rentalSlice.reducer
