import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'

interface Props extends BaseModalProps {
  onSubmit: () => void
}

export const ReservationCreateDataResetModal: React.FC<Props> = ({ toggleIsVisible, onSubmit }) => {
  const handleSubmit = () => {
    onSubmit()
    toggleIsVisible()
  }

  return (
    <>
      <ModalHeader toggle={toggleIsVisible} className="text-dark py-2 rounded-0 lh-initial">
        Uwaga
      </ModalHeader>
      <ModalBody>
        <p className="fw-semi-bold">Powrót do kroku 1 spowoduje usunięcie wszystkich dotychczasowych zmian.</p>
        <p className="font-11 fw-semi-bold">
          <span className="text-danger">Operacja jest nieodwracalna</span>
          <br />
          Nadal chcesz przejść do kroku 1?
        </p>
      </ModalBody>

      <ModalFooter className="d-flex">
        <Button color="light" onClick={toggleIsVisible}>
          Anuluj
        </Button>

        <Button onClick={handleSubmit} color="primary">
          Przejdź do kroku 1
        </Button>
      </ModalFooter>
    </>
  )
}
