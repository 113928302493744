import * as React from 'react'
import Table from '@components/table/table'
import { useModal } from '@components/modals/use-modal'
import { SubscriptionContract } from '@modules/subscription-contract/models'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { TableSellerRow } from '@components/table-seller-row'
import { useReportsAppData } from '@modules/reports/hooks/use-reports-app-data'
import { StatusBadge } from '@modules/promotions/common/status-badge'

interface Props {
  subscriptionContract: SubscriptionContract
}

export const SubscriptionContractRow: React.FC<Props> = ({ subscriptionContract }) => {
  const { sources } = useReportsAppData()

  const [showSubscriptionContractDetails] = useModal('SubscriptionContractDetailsModal', {
    subscription: subscriptionContract,
  })

  const sourceName = React.useMemo(
    () => sources.find(row => row.id === subscriptionContract.source_marketing)?.name ?? 'Brak',
    [sources, subscriptionContract.source_marketing],
  )

  return (
    <Table.Row key={subscriptionContract.id} onClick={showSubscriptionContractDetails} className="cursor-pointer">
      <Table.Cell>{subscriptionContract.number}</Table.Cell>
      <Table.Cell>
        <StatusBadge element={subscriptionContract} />
      </Table.Cell>
      <Table.Cell>{sourceName}</Table.Cell>
      <Table.Cell>
        {subscriptionContract.subscription_started
          ? toDefaultDateTimeFormat(subscriptionContract.subscription_started)
          : 'Nie rozpoczęto'}
      </Table.Cell>
      <Table.Cell>
        <TableSellerRow sellerId={subscriptionContract.seller_id} defaultValue="Brak" />
      </Table.Cell>
    </Table.Row>
  )
}
