import * as React from 'react'
import { CommonTask } from '@modules/housekeeping/models'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApiRequest } from '@components/hooks/use-api-request'
import { updateHousekeepingCommonTask } from '@store/slices/housekeeping-slice'
import { commonObjectPost } from '@store/actions/generic-actions'
import { SaveButton } from '@hyper/button'
import { Button } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'

interface Props {
  commonTask: CommonTask
}

export const HousekeepingCommonTasksTableRowActions = ({ commonTask }: Props): JSX.Element => {
  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()
  const user = useAuthenticatedUser()

  const { isLoading, action } = useApiRequest(async (url, message) => {
    await dispatch(updateHousekeepingCommonTask(await commonObjectPost<CommonTask>(url)))

    addSuccessNotification(message)
  })

  const handleAccept = () => action(commonTask.urls.accept, 'Zlecenie zostało zaakceptowane')
  const handleClose = () => action(commonTask.urls.stop_working, 'Zlecenie zostało zakończone')
  const handlePause = () => action(commonTask.urls.pause_working, 'Zlecenie zostało wstrzymane')
  const handleStart = () => action(commonTask.urls.start_working, 'Zlecenie zostało rozpoczęte')
  const [handleEditTime] = useModal('SpecialWorkingTimeModal', {
    commonTask,
  })

  return (
    <td className="housekeeping__orders_table__column text-right">
      {commonTask.status === 'FINISHED' && (
        <>
          <SaveButton
            label="Akceptuj"
            labelSaving="Akceptacja.."
            isSaving={isLoading}
            className="mr-1 btn btn-green btn-sm mr-1"
            onClick={handleAccept}
          />
          <Button color="green" className="btn-sm mr-1" onClick={handleEditTime}>
            Edytuj czas
          </Button>
        </>
      )}
      {commonTask.is_paused ? (
        <>
          <Button color="green" className="btn-sm mr-1" onClick={handleClose}>
            Zamknij
          </Button>
          <Button color="green" className="btn-sm mr-1" onClick={handleStart}>
            Start
          </Button>
        </>
      ) : (
        commonTask.status === 'IN_PROGRESS' && (
          <>
            <Button color="green" className="btn-sm mr-1" onClick={handleClose}>
              Zamknij
            </Button>
            <Button color="warning" className="btn-sm mr-1" onClick={handlePause}>
              Pauza
            </Button>
          </>
        )
      )}
      {commonTask.status !== 'FINISHED' && user.hasPerm(UserPermission.HousekeepingCanEditAcceptedOrders) && (
        <Button color="green" className="btn-sm mr-1" onClick={handleEditTime}>
          Edytuj czas
        </Button>
      )}
    </td>
  )
}
