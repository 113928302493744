import * as React from 'react'
import classNames from 'classnames'

interface ReservationStatsBoxRowProps {
  name: string
  value: string | React.ReactChild
  className?: string
}

export const ReservationStatsBoxRow: React.FC<ReservationStatsBoxRowProps> = ({ name, value, className }) => (
  <tr>
    <td className={classNames('pl-0', className)}>{name}</td>
    <td className={classNames('text-right text-strong font-15 pr-0', className)}>{value}</td>
  </tr>
)
