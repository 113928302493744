import * as React from 'react'
import classNames from 'classnames'
import { ContentNote } from '@models/dashboard'
import { useApiRequest } from '@components/hooks/use-api-request'
import { updateNote } from '@store/actions/dashboard-actions'
import { Dispatch, SetStateAction } from 'react'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'

export type ContentNoteCardState = 'default' | 'edit'

interface Props {
  note: ContentNote
  isVisible: boolean
  onContentNoteCardStateChange: Dispatch<SetStateAction<ContentNoteCardState>>
  onNoteChange?: (note: ContentNote) => void
}

export const ContentNotesCardContentMenu = ({
  isVisible,
  onContentNoteCardStateChange,
  note,
  onNoteChange,
}: Props): JSX.Element => {
  const handleOptionClick = (state: ContentNoteCardState) => () => {
    onContentNoteCardStateChange(state)
  }

  const { action: handleNotePin } = useApiRequest(async () => {
    const updatedNote = await updateNote(note.urls.details, { is_pinned: !note.pinned_datetime })
    onNoteChange?.(updatedNote)
  })

  const { action: handleNoteRemove, isLoading } = useApiRequest(async () => {
    const updatedNote = await commonObjectDelete<ContentNote>(note.urls.details)
    onNoteChange?.(updatedNote)
  })

  return (
    <div className={classNames('reservation-details__notes-box__actions', { 'is-visible': isVisible })}>
      <i
        onClick={handleNotePin}
        title={note.pinned_datetime ? 'Usuń oznaczenie' : 'Oznacz jako ważna'}
        className={classNames('reservation-details__notes-box__actions__item uil-bookmark', {
          'text-danger': note.pinned_datetime,
          'not-clickable': note.is_removed,
        })}
      />
      <i
        onClick={handleOptionClick('edit')}
        title="Edytuj notatkę"
        className={classNames('reservation-details__notes-box__actions__item uil-edit-alt', {
          'not-clickable': !note.can_change,
        })}
      />
      <div
        className={classNames('reservation-details__notes-box__actions__item p-0', {
          'not-clickable': !note.can_remove,
        })}
      >
        <CommonObjectConfirmAction
          headerClassName="bg-white"
          buttonAcceptClassName="btn btn-danger"
          submitLabel="Usuń"
          disabled={!note.can_remove}
          handleAccept={handleNoteRemove}
          isLoading={isLoading}
          message={
            <span>
              <strong>Usuwasz notatkę</strong>
              <br />
              <span>Czy usunąć notatkę bezpowrotnie?</span>
            </span>
          }
        >
          <i title="Usuń notatkę" className={classNames(' uil-trash-alt p-1 d-block', {})} />
        </CommonObjectConfirmAction>
      </div>
    </div>
  )
}
