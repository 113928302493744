import * as React from 'react'
import { EmailTemplateDetails } from '@modules/cms/models'
import { useApiRequest } from '@components/hooks/use-api-request'
import { uploadEmailTemplateAttachment } from '@api/cms'
import { FileUploader } from '@components/file-uploader'
import { useAppDispatch } from '@store/index'
import { updateEmailTemplateDetails } from '@store/actions/cms-actions'

interface Props {
  emailTemplateDetails: EmailTemplateDetails
}

export const NotificationTemplatesEmailModalAttachmentsUploader = ({ emailTemplateDetails }: Props): JSX.Element => {
  const dispatch = useAppDispatch()
  const { isLoading, action: upload } = useApiRequest(async file =>
    dispatch(
      updateEmailTemplateDetails(
        await uploadEmailTemplateAttachment(emailTemplateDetails.urls.attachment, file, emailTemplateDetails.id),
      ),
    ),
  )

  return (
    <div className="mt-2 mb-2">
      <FileUploader
        isLoading={isLoading}
        upload={upload}
        label="Przeciągnij i upuść plik lub kliknij by dodać załącznik"
      />
    </div>
  )
}
