import * as React from 'react'
import { BandScanningConfigurationItem } from '@components/band-scanning/configuration/band-scanning-configuration-item'
import { IconWithText } from '@components/icon-with-text'
import { useModal } from '@components/modals/use-modal'

import { ReceptionBookingDetails } from '@models/reception'
import { useFormContext, useWatch } from 'react-hook-form'
import { BandScannerFormInputs } from '@components/band-scanning/band-scanner'
import { useBandReadersList } from '@modules/reception/common/band-reader-select/use-band-readers-list'

interface Props {
  booking: ReceptionBookingDetails
  isEditDisabled: boolean
}

export const BandScanningConfiguration = ({ booking, isEditDisabled }: Props): JSX.Element => {
  const bandReaders = useBandReadersList()

  const [showTimeConfigurationModal] = useModal('BandScanningTimeConfigurationModal')
  const [showScannerConfigurationModal] = useModal('BandScanningScannerConfigurationModal')

  const { getValues, setValue, control } = useFormContext<BandScannerFormInputs>()
  const [hoursFrom, reader] = useWatch({ control, name: ['hour_from', 'reader'] })

  const handleShowTimeConfigurationModal = () => {
    showTimeConfigurationModal(null, {
      hoursFrom: getValues('hour_from'),
      onHoursFromChange: (value: string) => setValue('hour_from', value),
    })
  }

  const handleShowScannerConfigurationModal = () => {
    showScannerConfigurationModal(null, {
      bandReader: reader,
      onBandReaderChange: (value: number) => setValue('reader', value),
    })
  }

  const currentReader = React.useMemo(
    () => bandReaders.find(bandReader => bandReader.id === reader),
    [bandReaders, reader],
  )

  return (
    <div>
      <h5 className="text-secondary">Ustawienia kodowania:</h5>
      <div className="border bg-grey rounded px-2 py-1">
        <BandScanningConfigurationItem
          content={<strong>{booking.apartment.name}</strong>}
          title="Lokal:"
          icon="uil-estate font-16"
        />
        <hr className="band__configuration__hr" />
        <BandScanningConfigurationItem
          content={<strong>{hoursFrom}</strong>}
          title="Dostępność od:"
          icon="uil-clock font-16"
          after={
            <IconWithText
              icon="uil-edit-alt"
              text="zmień"
              textClass="font-11 fw-semi-bold mr-1"
              flexClassName="flex-row-reverse"
              isDisabled={isEditDisabled}
              onClick={handleShowTimeConfigurationModal}
            />
          }
        />
        <hr className="band__configuration__hr" />
        <BandScanningConfigurationItem
          content={<strong>{currentReader?.name || 'brak'}</strong>}
          title="Wybrany czytnik:"
          icon="uil-capture font-16"
          after={
            <IconWithText
              isDisabled={isEditDisabled}
              icon="uil-edit-alt"
              text="zmień"
              textClass="font-11 fw-semi-bold mr-1"
              flexClassName="flex-row-reverse"
              onClick={handleShowScannerConfigurationModal}
            />
          }
        />
      </div>
    </div>
  )
}
