import { CleaningOrder } from '@modules/housekeeping/models'
import { useCleaningOrderUpdate } from '@modules/housekeeping/cleaning-orders/to-order/actions/use-order-update'

export const useOrderPriorityChange = () => {
  const { updateOrder, isLoading } = useCleaningOrderUpdate()

  const changeOrderPriority = async (cleaningOrder: CleaningOrder, priority: number) => {
    await updateOrder(cleaningOrder, { priority }, 'Priorytet został zmieniony')
  }

  return {
    changeOrderPriority,
    isLoading,
  }
}
