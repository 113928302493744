import * as React from 'react'
import Table from '@components/table/table'
import { YesNoBadge } from '@components/badges/yes-no'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectPatch } from '@store/actions/generic-actions'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import Spinner from '@hyper/spinner'
import { ReceptionistUser } from '@models/account'
import { updateReceptionistUserDetails } from '@store/slices/account-slice'

interface Props {
  user: ReceptionistUser
}

export const ReceptionistUsersListTableRowActive: React.FC<Props> = ({ user }) => {
  const { addSuccessNotification } = useNotificationHook()
  const dispatch = useAppDispatch()
  const { isLoading, action: handleClick } = useApiRequest(async () => {
    dispatch(
      updateReceptionistUserDetails(
        await commonObjectPatch<ReceptionistUser>(user.urls.details, { is_active: !user.is_active }),
      ),
    )
    addSuccessNotification('Zmiany zostały zapisane')
  })

  return (
    <Table.Cell>
      {isLoading ? (
        <Spinner size="xxs" className="text-muted" />
      ) : (
        <YesNoBadge value={user.is_active} onClick={handleClick} className="cursor-pointer" />
      )}
    </Table.Cell>
  )
}
