import * as React from 'react'

import DatePickerComponent, { DatePickerProps } from '@components/date/date-picker'
import { Controller } from 'react-hook-form'
import classNames from 'classnames'
import { useNewFormContext } from '@hyper/forms/hooks/use-new-form-context'

interface Props extends Omit<DatePickerProps, 'maxDate' | 'minDate'> {
  control?: any
  name: any
  inputClassName?: string
  inputGroupClassName?: string
  isDisabled?: boolean
  wrapperClassName?: string
  minDate?: Date | null
  maxDate?: Date | null
  placeholder?: string
  dateFormat?: string
  isClearable?: boolean
  errors?: any
  withInput?: boolean
}

const DatePickerInput: React.FC<Props> = ({
  errors,
  control,
  name,
  inputClassName,
  inputGroupClassName,
  isDisabled,
  wrapperClassName,
  minDate,
  maxDate,
  placeholder,
  dateFormat,
  isClearable,
  withInput,
  ...calendarProps
}) => {
  const methods = useNewFormContext()

  if (methods) {
    control = methods.control
  }

  return (
    <div className={classNames('date-picker-input', inputGroupClassName, { disabled: isDisabled })}>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <DatePickerComponent
            date={field.value}
            onChange={field.onChange}
            inputClassName={classNames(inputClassName)}
            wrapperClassName={wrapperClassName}
            isInvalid={fieldState.invalid}
            isDisabled={isDisabled}
            minDate={minDate}
            maxDate={maxDate}
            placeholder={placeholder}
            dateFormat={dateFormat}
            isClearable={isClearable && !isDisabled}
            name={name}
            withInput={withInput}
            withIcon={true}
            {...calendarProps}
          />
        )}
      />
    </div>
  )
}

export default DatePickerInput
