import * as React from 'react'
import { useForm } from 'react-hook-form'
import { Button, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { SaveButton } from '@hyper/button'
import { FormInput } from '@hyper/forms'
import { updatePackageVipDetails } from '@store/slices/package-vip-slice'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useFormRequest } from '@components/hooks/use-api-request'
import { BaseModalProps } from '@components/modals/types'
import { commonObjectPost } from '@store/actions/generic-actions'
import { PackageVipDetails } from '@modules/promotions/package-vip/models'
import { Form } from '@hyper/forms/form'

interface FormInputs {
  code: string
}

export const PackageVipPaymentsAddCodeDialog: React.FC<BaseModalProps> = ({ toggleIsVisible }) => {
  const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()

  const methods = useForm<FormInputs>()
  const packageVipDetails = useAppSelector((state: RootState) => state.packageVipState.packageVipDetails)

  const { isLoading, action: onSubmit } = useFormRequest(async payload => {
    if (packageVipDetails) {
      const data = await commonObjectPost<PackageVipDetails>(packageVipDetails.urls.code, { ...payload })
      dispatch(updatePackageVipDetails(data))
      addSuccessMessage('Sukces', 'Pakiet został opłacony kodem!')
      toggleIsVisible()
    }
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Opłać kodem</ModalHeader>
      <ModalBody>
        <Row>
          <FormInput label="Kod" name="code" />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton label="Zapisz" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
