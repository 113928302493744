import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { ReceptionBooking, ReceptionBookingDetails } from '@models/reception'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { ModalHeader } from 'reactstrap'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectPost } from '@store/actions/generic-actions'
import { ReceptionBookingCheckInStepAccess } from '@modules/reception/checkin/step-access'
import { ReceptionBookingCheckInPreviewDialogContent } from '@modules/reception/checkin/reception-booking-check-in-preview-dialog-content'
import { useToggle } from '@components/hooks/use-toggle'
import classname from 'classnames'
import { receptionBookingDetailsSelector } from '@store/selectors/reception'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { useAppSelector } from '@store/index'

interface Props extends BaseModalProps {
  booking: ReceptionBooking
}

export const ReceptionBookingCheckInPreviewDialog: React.FC<Props> = ({ booking, toggleIsVisible }) => {
  const [receptionBookingDetails, setReceptionBookingDetails] = React.useState<ReceptionBookingDetails | null>(null)
  const [isAccessStepVisible, toggleIsAccessStepVisible] = useToggle()

  const bookingDetails = useAppSelector(receptionBookingDetailsSelector)

  const { action: fetchReceptionBookingDetails } = useApiRequest(async () => {
    setReceptionBookingDetails(
      await commonObjectPost<ReceptionBookingDetails>(booking.urls.booking_confirm_check_in_online_start),
    )
  })

  React.useEffect(() => {
    fetchReceptionBookingDetails()
  }, [])

  useDidUpdateEffect(() => {
    setReceptionBookingDetails(bookingDetails)
  }, [bookingDetails])

  return (
    <LoaderPlaceholder content={receptionBookingDetails} className={classname({ 'p-5': !receptionBookingDetails })}>
      <ModalHeader toggle={toggleIsVisible} className="py-2">
        Potwierdź meldowanie {booking.name} ({booking.reservation_number})
      </ModalHeader>
      {receptionBookingDetails && (
        <div>
          {isAccessStepVisible ? (
            <ReceptionBookingCheckInStepAccess
              booking={receptionBookingDetails}
              title={`Wydanie gościom ${receptionBookingDetails.bands.length ? 'opasek' : 'kluczy'}`}
              handleIsOpen={toggleIsVisible}
              previousStep={toggleIsAccessStepVisible}
              previousLabel="Wróć do karty meldowania"
            />
          ) : (
            <ReceptionBookingCheckInPreviewDialogContent
              bookingDetails={receptionBookingDetails}
              onPreviewDialogClose={toggleIsVisible}
              onNextStep={toggleIsAccessStepVisible}
            />
          )}
        </div>
      )}
    </LoaderPlaceholder>
  )
}
