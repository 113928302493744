import * as React from 'react'
import { useModal } from '@components/modals/use-modal'
import { useAppData } from '@components/hooks/use-app-data'
import { useGetBookingOptionDetailsQuery } from '@api/booking-options'
import { TypedQueryResult } from '@api/base'
import { BookingOptionDetails } from '@modules/promotions/booking-options/model'

interface Props {
  id: string
}

export const BookingOptionsDetailsView: React.FC<Props> = ({ id }) => {
  const { urls } = useAppData()

  const [handleDetails, hidePreview] = useModal('BookingOptionDetailsDialog')
  const { data: option } = useGetBookingOptionDetailsQuery(
    `${urls.client_options.client_options_purchases}${id}/`,
  ) as TypedQueryResult<BookingOptionDetails>

  React.useEffect(() => {
    if (option) {
      handleDetails(null, { option }, true)

      return () => {
        if (!id) {
          hidePreview()
        }
      }
    }
  }, [option, id])

  return null
}
