import * as React from 'react'
import { ShopProduct } from '@models/shop'
import notFoundImage from '@assets/images/not-found.png'
import { formatPriceShort } from '@helpers/utils'
import classNames from 'classnames'

interface ShopProductSelectOptionProps {
  product: ShopProduct
}

export const ShopProductSelectOption: React.FC<ShopProductSelectOptionProps> = ({ product }) => (
  <div className="d-flex w-100">
    <img src={product.image || notFoundImage} className="mr-2 product-select__option__image" alt="" />
    <div className="d-flex justify-content-between flex-column w-100">
      <span
        className={classNames('d-block font-12 text-semi-strong', {
          'text-secondary': product.kind === 'service',
          'text-info': product.kind === 'product',
        })}
      >
        {product.kind_display}
      </span>
      <span className="d-block text-bold text-wrap">{product.name}</span>
      <span className="d-block mt-1 text-danger text-bold font-13">{formatPriceShort(product.price_brutto)}</span>
      <div className="d-flex justify-content-between text-muted font-12 w-100">
        <div>{product.barcode_ean && <span className="">Kod produktu: {product.barcode_ean}</span>}</div>

        {product.in_warehouse && (
          <div
            className={classNames(' text-right', {
              'text-danger text-bold': product.available_amount === 0,
            })}
          >
            {product.available_amount} szt.
          </div>
        )}
      </div>
    </div>
  </div>
)
