import React from 'react'

export enum Variant {
  Success,
  Warning,
  Error,
}

export interface NotificationMessageModalConfig {
  isModalCloseAllowed?: boolean
}

export interface NotificationMessage {
  action?: (onHide: () => void) => React.ReactNode
  buttonText?: string
  id: string
  modalConfig?: NotificationMessageModalConfig
  message: React.ReactNode
  onBeforeClose?: () => void
  title: React.ReactNode
  variant: Variant
}
