import * as React from 'react'
import Table from '@components/table/table'
import { formatPrice } from '@helpers/utils'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { Subscription } from '@modules/subscription/models'
import { RootState, useAppSelector } from '@store/index'
import { BadgeWithIcon } from '@components/badges/BadgeWithIcon'
import { TableSellerRow } from '@components/table-seller-row'
import { TableUserRow } from '@components/table-user-row'
import { useSubscriptionOptions } from '@modules/subscription/hooks/use-subscription-options'
import { StatusBadge } from '@modules/promotions/common/status-badge'
import { IconWithText } from '@components/icon-with-text'
import { useDetailsModal } from '@components/hooks/use-details-modal'
import { NavigationPath } from '@models/routes'

const ECOMMERCE_SOURCE_ID = 34

interface Props {
  subscription: Subscription
}

export const SubscriptionListTableRow: React.FC<Props> = ({ subscription }) => {
  const sources = useAppSelector((state: RootState) => state.reportsState.appData?.sources) || []

  const sourceName = React.useMemo(
    () => sources.find(row => row.id === subscription.source_marketing_id)?.name,
    [sources, subscription.source_marketing_id],
  )

  const { handleClientDetails } = useDetailsModal(
    ['SubscriptionDetailsModal', { subscription }],
    NavigationPath.SubscriptionList,
    NavigationPath.SubscriptionListWithParams,
    subscription.id,
  )

  const { hasOption } = useSubscriptionOptions(subscription)

  const isCreatedViaEcommerce = subscription.source_marketing_id === ECOMMERCE_SOURCE_ID

  return (
    <Table.Row onDoubleClick={handleClientDetails} className="cursor-pointer" role="details-row">
      <Table.Cell className="align-middle">{subscription.number}</Table.Cell>
      <Table.Cell className="align-middle">{sourceName}</Table.Cell>
      <Table.Cell className="align-middle">
        {subscription.name} <br /> <span className="font-10">{subscription.email}</span>
      </Table.Cell>
      <Table.Cell className="align-middle">
        <StatusBadge element={subscription} />
      </Table.Cell>
      <Table.Cell className="align-middle">{formatPrice(subscription.total_price_brutto)}</Table.Cell>
      <Table.Cell className="align-middle">
        {formatPrice(subscription.total_price_brutto_without_used_booking)}
      </Table.Cell>
      <Table.Cell className="align-middle">{formatPrice(subscription.required_payment)}</Table.Cell>
      <Table.Cell className="align-middle">{subscription.type_value}</Table.Cell>
      {subscription.type !== 'BOOKING_IMPROVEMENT' && (
        <Table.Cell className="align-middle">
          {hasOption('client_data') && <BadgeWithIcon icon="uil-check" title="Zmiana danych" variant="success" />}{' '}
          {hasOption('localization') ||
            (hasOption('localization_unlimited') && (
              <BadgeWithIcon icon="uil-check" title="Konwersja kodów" variant="success" />
            ))}
          {hasOption('zoo_borysew') && (
            <BadgeWithIcon icon="uil-check" title="Bilety do Zoo Borysew" variant="success" />
          )}
          {hasOption('suntago') && <BadgeWithIcon icon="uil-check" title="Bilety do Suntago" variant="success" />}
        </Table.Cell>
      )}
      <Table.Cell className="align-middle">
        <TableUserRow
          userId={subscription.created_by_id}
          defaultValue={
            <IconWithText
              icon={isCreatedViaEcommerce ? 'uil-user' : 'uil-user-square'}
              text={`${subscription.created_by || 'Nowy klient'} ${isCreatedViaEcommerce ? '(Klient)' : ''}`}
            />
          }
          className="d-block"
        />
        <small className="font-10">{toDefaultDateTimeFormat(subscription.created)}</small>
      </Table.Cell>
      <Table.Cell className="align-middle">
        <TableSellerRow sellerId={subscription.seller_id} defaultValue={subscription.seller} />
      </Table.Cell>
    </Table.Row>
  )
}
