import * as React from 'react'
import { Col, Row } from 'reactstrap'
import { ProductCustomActionButton } from '@components/products/product-action-button'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useAppDispatch } from '@store/index'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { RentalProductDetails } from '@modules/rental/models'
import { setRentalProductDetails } from '@store/slices/rental-slice'

interface Props {
  productDetails: RentalProductDetails
}

export const RentalProductDetailsActions = ({ productDetails }: Props) => {
  const dispatch = useAppDispatch()

  const { addSuccessNotification } = useNotificationHook()

  const { action: withdrawnProduct, isLoading: isProductWithdrawnProcessing } = useApiRequest(async reason => {
    dispatch(setRentalProductDetails(await commonObjectPost(productDetails.urls.withdrawn, { reason })))
    addSuccessNotification('Produkt został wycofany.')
  })

  const { action: returnProduct, isLoading: isProductReturnProcessing } = useApiRequest(async reason => {
    dispatch(setRentalProductDetails(await commonObjectPost(productDetails.urls.return, { reason })))
    addSuccessNotification('Produkt został wycofany.')
  })

  const { action: restoreProduct } = useApiRequest(async () => {
    dispatch(setRentalProductDetails(await commonObjectPost(productDetails.urls.restore)))
  })

  return (
    <Row className="mt-2 mx-0 justify-content-end">
      {productDetails.status !== 'withdrawn' && (
        <Col md={3} className="pl-0 pr-1">
          <CommonObjectConfirmAction
            isLoading={isProductWithdrawnProcessing}
            handleAccept={withdrawnProduct}
            askForReason
            message=""
          >
            <ProductCustomActionButton
              text="Wycofaj"
              icon="uil-minus"
              className="bg-danger border-0 w-100 shadow-none"
            />
          </CommonObjectConfirmAction>
        </Col>
      )}
      {productDetails.status === 'withdrawn' && (
        <Col md={3} className="px-1">
          <ProductCustomActionButton
            onClick={restoreProduct}
            text="Przywróć"
            icon="uil-redo"
            className="bg-secondary border-0 w-100 shadow-none"
          />
        </Col>
      )}
      {productDetails.status === 'rented' && (
        <Col md={3} className="px-1">
          <CommonObjectConfirmAction
            isLoading={isProductReturnProcessing}
            handleAccept={returnProduct}
            askForReason
            message=""
          >
            <ProductCustomActionButton
              text="Zwróć"
              icon="uil-bring-front"
              className="bg-primary border-0 w-100 shadow-none"
            />
          </CommonObjectConfirmAction>
        </Col>
      )}
    </Row>
  )
}
