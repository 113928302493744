import * as React from 'react'

interface Props {
  isListView: boolean
}

export const HousekeepingCleaningOrdersToCheckTableHeader = ({ isListView }: Props): JSX.Element => (
  <thead>
    <tr>
      {!isListView && <TableHeader name="Zespół" width={200} />}
      <TableHeader name="Lokal" width={200} />
      <TableHeader name="Rodzaj" width={250} />
      <TableHeader name="Typ" width={150} />
      <TableHeader name="Najbliższa rezerwacja" width={170} />
      <TableHeader name="Pościelowy" width={150} />
      <TableHeader name="Ulepszenia" width={150} />
      {isListView && <TableHeader name="Wykonawca" width={150} />}
      <TableHeader name="Czas pracy" width={150} />
      <TableHeader name="Uwagi" />
      <TableHeader name="" width={400} />
    </tr>
  </thead>
)

const TableHeader = ({ name, width }: { name: string; width?: number }) => (
  <th className="border-top-0" style={{ minWidth: width || undefined }}>
    {name}
  </th>
)
