import * as React from 'react'
import { SubscriptionDetails, SubscriptionTouristVoucher } from '@modules/subscription/models'
import { Button, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { FormInput } from '@hyper/forms'
import { useFormRequest } from '@components/hooks/use-api-request'
import { useAppDispatch } from '@store/index'
import { updateSubscriptionDetails } from '@store/slices/subscription-slice'
import { commonObjectPost, commonObjectPut } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useForm } from 'react-hook-form'
import { BaseModalProps } from '@components/modals/types'
import { Form } from '@hyper/forms/form'

interface Props extends BaseModalProps {
  subscriptionDetails: SubscriptionDetails
  touristVoucher?: SubscriptionTouristVoucher
}

interface FormInputs {
  amount: string
  code: string
  name: string
}

export const SubscriptionTouristVoucherModal: React.FC<Props> = ({
  subscriptionDetails,
  toggleIsVisible,
  touristVoucher,
}) => {
  const methods = useForm<FormInputs>({ defaultValues: { ...touristVoucher } })
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    dispatch(
      updateSubscriptionDetails(
        touristVoucher
          ? await commonObjectPut<SubscriptionDetails>(touristVoucher.urls.details, payload)
          : await commonObjectPost<SubscriptionDetails>(subscriptionDetails.urls.tourist_vouchers, payload),
      ),
    )

    addSuccessMessage(
      'Sukces',
      touristVoucher
        ? `Bon turystyczny o kodzie ${payload.code} został zmieniony!`
        : `Bon turystyczny o kodzie ${payload.code} został dodany!`,
    )
    toggleIsVisible()
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>
        {touristVoucher ? `Edytuj bon turystyczny ${touristVoucher.code}` : 'Dodaj bon turystyczny'}
      </ModalHeader>
      <ModalBody>
        <Row>
          <FormInput colSize={6} label="Numer autoryzacji przez ZUS" name="code" />
          <FormInput colSize={6} label="Posiadacz bonu turystycznego" name="name" />
          <FormInput colSize={6} label="Kwota" name="amount" />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={toggleIsVisible}>
          Zamknij
        </Button>

        <SaveButton role="submit" className="btn btn-green" label="Zapisz" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
