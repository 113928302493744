import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { apiQueryFunction } from '@api/base'
import { RootState } from '@store/index'
import { PaginationResponse, PaginationResponseWithAggregation } from '@models/dashboard'
import { extractSelectOptionsValues } from '@helpers/utils'
import {
  BenefitProgram,
  BenefitProgramAggregation,
  BenefitProgramAgreement,
  BenefitProgramAgreementDetails,
  BenefitProgramAppData,
  BenefitProgramDetails,
} from '@modules/benefit-program/models'
import { TableFilters } from '@components/table/table'
import { BenefitProgramAgreementCreateField } from '@modules/benefit-program/agreements/create/field-row'
import { provideListTags } from '@api/query-helper'

const BENEFIT_PROGRAM_AGREEMENT_TAG = 'benefitProgramAgreements'
const BENEFIT_PROGRAM_TAG = 'benefitPrograms'
type UpdateBenefitProgramParams = { data: any; url: string; method: 'POST' | 'PUT' }

export const benefitProgramsApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: '' }),
  reducerPath: 'benefitProgramsApi',
  tagTypes: [BENEFIT_PROGRAM_TAG, BENEFIT_PROGRAM_AGREEMENT_TAG],
  endpoints: builder => ({
    getBenefitProgramsAggregation: builder.query<BenefitProgramAggregation, { params: TableFilters }>({
      queryFn: apiQueryFunction((state: RootState, { params }) => ({
        params: {
          ...(extractSelectOptionsValues(params) || {}),
          page_size: 1,
        },
        method: 'GET',
        url: state.appState.appData.urls.benefit_programs.benefit_programs,
        responseTransformer: (response: PaginationResponseWithAggregation<BenefitProgram, BenefitProgramAggregation>) =>
          response.aggregation,
      })),
      providesTags: () => [BENEFIT_PROGRAM_TAG],
    }),

    getBenefitPrograms: builder.query<BenefitProgram[], { params: TableFilters }>({
      queryFn: apiQueryFunction((state: RootState, { params }) => ({
        params: {
          ...(extractSelectOptionsValues(params) || {}),
        },
        method: 'GET',
        url: state.appState.appData.urls.benefit_programs.benefit_programs,
        responseTransformer: (response: PaginationResponse<BenefitProgram>) => response.results,
      })),
      providesTags: provideListTags(BENEFIT_PROGRAM_TAG, BENEFIT_PROGRAM_TAG),
    }),
    getBenefitProgramDetails: builder.query<BenefitProgramDetails, { id: string }>({
      providesTags: (result, error, { id }) => [{ type: BENEFIT_PROGRAM_TAG, id }],
      queryFn: apiQueryFunction((state, { id }) => ({
        method: 'GET',
        url: state.appState.appData.urls.benefit_programs.benefit_programs + id + '/',
      })),
    }),
    getBenefitProgramAppData: builder.query<BenefitProgramAppData, void>({
      queryFn: apiQueryFunction(state => ({
        method: 'GET',
        url: state.appState.appData.urls.benefit_programs.app_data,
      })),
    }),
    createOrUpdateBenefitProgram: builder.mutation<BenefitProgramDetails, UpdateBenefitProgramParams>({
      invalidatesTags: [BENEFIT_PROGRAM_TAG],

      queryFn: apiQueryFunction((state, { data, method, url }: UpdateBenefitProgramParams) => ({
        data: extractSelectOptionsValues(data),
        method,
        url: url ? url : state.appState.appData.urls.benefit_programs.benefit_programs,
      })),
    }),
    getBenefitProgramAgreementDetails: builder.query<BenefitProgramAgreementDetails, { id: string }>({
      providesTags: (result, error, { id }) => [{ type: BENEFIT_PROGRAM_AGREEMENT_TAG, id }],
      queryFn: apiQueryFunction((state, { id }) => ({
        method: 'GET',
        url: state.appState.appData.urls.benefit_programs.benefit_program_agreements + id + '/',
      })),
    }),
    getBenefitProgramAgreements: builder.query<PaginationResponse<BenefitProgramAgreement>, { params: TableFilters }>({
      queryFn: apiQueryFunction((state: RootState, { params }) => ({
        params: {
          ...(extractSelectOptionsValues(params) || {}),
        },
        method: 'GET',
        url: state.appState.appData.urls.benefit_programs.benefit_program_agreements,
      })),
      providesTags: (data: PaginationResponse<BenefitProgramAgreement>) =>
        provideListTags(BENEFIT_PROGRAM_AGREEMENT_TAG, BENEFIT_PROGRAM_AGREEMENT_TAG)(data.results),
    }),
    createBenefitProgramAgreement: builder.mutation<
      BenefitProgramAgreement,
      { url: string; payload: BenefitProgramAgreementCreateField[] }
    >({
      invalidatesTags: [BENEFIT_PROGRAM_AGREEMENT_TAG, BENEFIT_PROGRAM_TAG],
      queryFn: apiQueryFunction(
        (_, { url, payload }: { url: string; payload: BenefitProgramAgreementCreateField[] }) => ({
          data: payload,
          method: 'POST',
          url,
        }),
      ),
    }),
  }),
})

export const {
  useGetBenefitProgramsAggregationQuery,
  useCreateOrUpdateBenefitProgramMutation,
  useGetBenefitProgramsQuery,
  useGetBenefitProgramDetailsQuery,
  useGetBenefitProgramAppDataQuery,
  useGetBenefitProgramAgreementDetailsQuery,
  useGetBenefitProgramAgreementsQuery,
  useCreateBenefitProgramAgreementMutation,
} = benefitProgramsApi
