import * as React from 'react'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { FormProvider, useForm } from 'react-hook-form'
import { ReceptionBookingDetails } from '@models/reception'
import { useModal } from '@components/modals/use-modal'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { FormSelect } from '@hyper/forms/form-select'
import { createSelectOption, formatPriceShort } from '@helpers/utils'

interface FormInputs {
  vipType: CustomReactSelectOption
}

interface Props {
  onDelete: (reason: string) => void
  booking: ReceptionBookingDetails
  improvementPrice: string
}

export const ReceptionBookingCheckInImprovementsVipActive: React.FC<Props> = ({
  onDelete,
  booking,
  improvementPrice,
}) => {
  const [toggleAddingCodeDialog] = useModal('ReceptionBookingCheckInImprovementAddVipDialog', {
    booking,
  })

  const options = [
    createSelectOption(`Dolicz do rachunku +${formatPriceShort(improvementPrice)}`, improvementPrice),
    createSelectOption('Opłać kodem', 'code'),
  ]

  const methods = useForm<FormInputs>({
    defaultValues: {
      vipType: options[0],
    },
  })

  const { vipType } = methods.watch()

  const vipCodeSelected = vipType.value === 'code'

  return (
    <FormProvider {...methods}>
      <td className="reception__booking-check-in__improvement-step__vip__last-col">
        <div className="d-flex align-items-center">
          <CommonObjectConfirmAction
            title="Potwierdź usunięcie pakietu VIP"
            handleAccept={onDelete}
            message="Czy na pewno chcesz usunąć pakiet vip?"
            askForReason={true}
          >
            <i className="uil-trash-alt cursor-pointer font-18 grey-400 mr-3" role="delete-improvement" />
          </CommonObjectConfirmAction>
          <div className="w-100">
            <FormSelect
              options={options}
              name="vipType"
              selectProps={{ isSearchable: false }}
              formPlainProps={{ formGroupClassName: 'mb-0' }}
            />
            {vipCodeSelected && (
              <span
                className="text-secondary cursor-pointer font-12 text-left d-block font-weight-bold mt-2"
                onClick={toggleAddingCodeDialog}
              >
                <i className="uil-plus mr-1" />
                Dodaj kod
              </span>
            )}
          </div>
        </div>
      </td>
    </FormProvider>
  )
}
