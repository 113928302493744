import * as React from 'react'
import { Button } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'
import { PaymentMethod } from '@models/payments'

interface Props {
  url: string
  onSuccess?: () => void
  defaultAmount?: string
  paymentMethods?: PaymentMethod[]
}

export const GenericProductPaymentsAdd: React.FC<Props> = ({ paymentMethods, url, defaultAmount, onSuccess }) => {
  const [handleToggleDialog] = useModal('GenericProductPaymentsAddDialog', {
    paymentMethods,
    url,
    onSuccess,
    amount: defaultAmount,
  })

  return (
    <Button color="green-dark" onClick={handleToggleDialog}>
      Dodaj wpłatę
    </Button>
  )
}
