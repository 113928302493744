import * as React from 'react'
import Table from '@components/table/table'
import { CouponTypeConfiguration } from '@modules/coupons/models'
import { useModal } from '@components/modals/use-modal'
import { useResortById } from '@components/hooks/use-resorts-and-accommodation-types-options'

interface Props {
  couponTypeConfiguration: CouponTypeConfiguration
}

export const CouponTypesConfigurationTableRow = ({ couponTypeConfiguration }: Props): JSX.Element => {
  const [showConfigurationModal] = useModal('CouponTypeConfigurationCreateModal', { couponTypeConfiguration })

  const resort = useResortById(couponTypeConfiguration.resort)

  return (
    <Table.Row>
      <Table.Cell>{couponTypeConfiguration.name}</Table.Cell>
      <Table.Cell>{resort?.name}</Table.Cell>
      <Table.Cell>{`${couponTypeConfiguration.purchase_hour_from} - ${couponTypeConfiguration.purchase_hour_to}`}</Table.Cell>
      <Table.Cell>{`${couponTypeConfiguration.usage_hour_from} - ${couponTypeConfiguration.usage_hour_to}`}</Table.Cell>
      <Table.Cell>{couponTypeConfiguration.gopos_points_of_sale.join(', ')}</Table.Cell>
      <Table.Cell style={{ width: 40 }}>
        <i className="uil-pen font-14 lh-1 cursor-pointer" onClick={showConfigurationModal} />
      </Table.Cell>
    </Table.Row>
  )
}
