import * as React from 'react'
import { FaultOrderDetails } from '@modules/housekeeping/applications/models'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useModal } from '@components/modals/use-modal'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { useApiRequest } from '@components/hooks/use-api-request'

interface FaultOrderCellActionsProps {
  faultOrder: FaultOrderDetails
}

export const FaultOrderCellActions: React.FC<FaultOrderCellActionsProps> = ({ faultOrder }) => {
  const { addSuccessMessage } = useNotificationHook()

  const { isLoading: isDeleting, action: handleRemoveEvent } = useApiRequest(async () => {
    await commonObjectDelete(faultOrder.urls.details)
    addSuccessMessage('Sukces', 'Usterka została skasowana')
  })

  const [handleEdit] = useModal(
    'HousekeepingApplicationFaultsCreateModal',
    {
      faultOrder,
      resortId: faultOrder.resort_id,
    },
    { persist: true },
  )

  const [handlePreview] = useModal('HousekeepingApplicationFaultsPreviewModal', {
    faultOrder,
    handleEdit,
  })

  return (
    <>
      <span onClick={handlePreview}>
        <i className="uil uil-eye cursor-pointer ml-2 text-muted" title="Podgląd" />
      </span>
      <span onClick={handleEdit}>
        <i className="uil uil-pen cursor-pointer ml-2 text-muted" title="Edytuj" />
      </span>

      <CommonObjectConfirmAction isLoading={isDeleting} handleAccept={handleRemoveEvent}>
        <i className="uil uil-trash-alt cursor-pointer ml-2 text-muted" title="Skasuj" />
      </CommonObjectConfirmAction>
    </>
  )
}
