import * as React from 'react'
import { Button } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'
import { GastroCardKind } from '@models/promotions'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useGastroProductProvider } from '@modules/products/hooks/use-gastro-product-provider'
import { GastroProductKind } from '@models/products'

interface Props {
  kind: GastroCardKind
}
export const GastroCardAdd: React.FC<Props> = ({ kind }) => {
  const [showGastroCardDialog] = useModal('GastroCardDialog', { kind })
  const [showGastroCouponDialog] = useModal('GastroGenericProductDialog', { kind })

  const user = useAuthenticatedUser()

  const isGastroCoupon = kind !== 'normal'
  const gastroProductProvider = useGastroProductProvider(kind as GastroProductKind)

  if (!user.hasPerm(gastroProductProvider?.crud_permissions.create)) return null

  const showCardDialog = () => {
    isGastroCoupon ? showGastroCouponDialog() : showGastroCardDialog()
  }

  return (
    <Button onClick={showCardDialog} color="green-dark" type="button" className="mx-2">
      <i className="mdi mdi-plus-circle mr-1" />
      Dodaj kartę
    </Button>
  )
}
