import * as React from 'react'
import { ReceptionBooking } from '@models/reception'
import { Col, CustomInput, Row } from 'reactstrap'
import { extractInnerRef } from '@helpers/forms'
import { useFormContext, useWatch } from 'react-hook-form'
import { ACCOMMODATION_TYPES } from '@helpers/consts'
import { NotificationGuestFilter, ReceptionNotificationDialogFormInputs } from '@modules/reception/notification/index'
import { FormPlainError } from '@hyper/forms'

interface ReceptionNotificationGuestsProps {
  bookings: ReceptionBooking[]
}

export const ReceptionNotificationGuests: React.FC<ReceptionNotificationGuestsProps> = ({ bookings }) => {
  const { setValue, control, register } = useFormContext<ReceptionNotificationDialogFormInputs>()
  const checkedValue = useWatch({ control, name: 'filter' })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('filter', checkedValue === event.target.id ? null : (event.target.id as NotificationGuestFilter))
  }

  const sortedBookings = React.useMemo(() => {
    const sortByPosition = arr => arr.sort((a, b) => a.apartment.position - b.apartment.position)

    const { houses, apartments } = bookings.reduce(
      (acc, curr) =>
        ACCOMMODATION_TYPES.HOUSES.includes(curr.accommodation_type_id)
          ? { ...acc, houses: [...acc.houses, curr] }
          : { ...acc, apartments: [...acc.apartments, curr] },
      { houses: [], apartments: [] },
    )

    return [...sortByPosition(houses), ...sortByPosition(apartments)]
  }, [bookings])

  return (
    <Row>
      {!!bookings.length && (
        <Col md={12} className="mb-2 text-semi-strong d-flex">
          <CustomInput
            onChange={handleChange}
            checked={checkedValue === 'all'}
            className="mr-3"
            type="checkbox"
            id="all"
            label={<span className="text-semi-strong">Wszyscy</span>}
          />
          <CustomInput
            onChange={handleChange}
            checked={checkedValue === 'houses'}
            id="houses"
            className="mr-3"
            type="checkbox"
            label={<span className="text-semi-strong">Tylko domki</span>}
          />
          <CustomInput
            onChange={handleChange}
            checked={checkedValue === 'apartments'}
            type="checkbox"
            id="apartments"
            label={<span className="text-semi-strong">Tylko apartamenty</span>}
          />
        </Col>
      )}
      <input type="checkbox" className="d-none" {...register('bookings')} />
      {!bookings.length && (
        <Col md={12} className="text-center">
          Brak rezerwacji w wybranym terminie
        </Col>
      )}
      {sortedBookings.map(booking => (
        <Col md={6} key={`${booking.id}-${booking.apartment?.id}`}>
          <div className="pl-2 mb-2">
            <CustomInput
              disabled={!booking.phone || !booking.sms_notifications_enabled}
              type="checkbox"
              id={`${booking.id}-${booking.apartment.id}`}
              label={
                <span className="text-semi-strong">
                  {booking.apartment.name} - {booking.name}
                </span>
              }
              value={booking.id}
              {...extractInnerRef(register('bookings'))}
            />
          </div>
        </Col>
      ))}
      <FormPlainError name="bookings" />
    </Row>
  )
}
