import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { AvailableUpSellBookingStatus, UpSellBookingStatus } from '@models/upselling'
import { UpsellBookingsFormInputs } from '@modules/upselling/upsell-bookings'

export type UpsellBookingsFilter = Pick<
  UpsellBookingsFormInputs,
  'only_without_improvement' | 'only_without_feedings'
> &
  UpSellBookingStatus

interface Response {
  updateFilters: (filters: UpsellBookingsFilter[]) => void
  toggleStatus: (status: UpSellBookingStatus) => () => void
  isStatusSelected: (status: UpSellBookingStatus) => boolean
  filters: UpsellBookingsFilter[]
}

export const useUpsellBookingsFilters = (): Response => {
  const { control, setValue } = useFormContext<UpsellBookingsFormInputs>()
  const [selectedStatuses, onlyWithoutImprovements, onlyWithoutFeedings] = useWatch({
    control,
    name: ['status', 'only_without_improvement', 'only_without_feedings'],
  })

  const isStatusSelected = (status: UpSellBookingStatus) => selectedStatuses.includes(status)

  const toggleStatus = React.useCallback(
    (status: UpSellBookingStatus) => () => {
      if (isStatusSelected(status)) {
        setValue(
          'status',
          selectedStatuses.filter(selectedStatus => selectedStatus !== status),
        )
        return
      }

      setValue('status', [...selectedStatuses, status])
    },
    [selectedStatuses],
  )

  const updateFilters = (filters: UpsellBookingsFilter[]) => {
    const { status, only_without_feedings, only_without_improvement } = filters.reduce(
      (prev, filter) => {
        if (AvailableUpSellBookingStatus.includes(filter)) {
          return { ...prev, status: [...prev.status, filter] }
        }

        if (['only_without_improvement', 'only_without_feedings'].includes(filter)) {
          return { ...prev, [filter]: true }
        }

        return prev
      },
      {
        status: [],
        only_without_improvement: false,
        only_without_feedings: false,
      },
    )

    setValue('status', status)
    setValue('only_without_improvement', only_without_improvement)
    setValue('only_without_feedings', only_without_feedings)
  }

  return {
    isStatusSelected,
    toggleStatus,
    filters: [
      ...selectedStatuses,
      ...(onlyWithoutImprovements ? ['only_without_improvement'] : []),
      ...(onlyWithoutFeedings ? ['only_without_feedings'] : []),
    ] as UpsellBookingsFilter[],
    updateFilters,
  }
}
