import * as React from 'react'
import { ReceptionBookingFeeding } from '@modules/reception/checkin/step-feeding/reception-booking-feeding'
import { ReceptionBookingDetails } from '@models/reception'
import useFeedingsWithDefaults from '@modules/reception/checkin/step-feeding/use-feedings-with-defaults'
import { FormProvider, useForm } from 'react-hook-form'
import { ReceptionBookingFeedingPayByClientWalletFormInputs } from '@modules/reception/reception-booking-feeding-pay-by-client-wallet-checkbox'

interface ReceptionBookingDetailsFeedingProps {
  booking: ReceptionBookingDetails
  onSave?: () => void
}

export const ReceptionBookingDetailsFeeding: React.FC<ReceptionBookingDetailsFeedingProps> = ({ booking }) => {
  const { feedingsHandler } = useFeedingsWithDefaults(booking)

  const methods = useForm<ReceptionBookingFeedingPayByClientWalletFormInputs>({
    defaultValues: {
      pay_by_client_wallet: booking.improvements.some(row => row.is_paid_by_client_wallet),
    },
  })

  return (
    <FormProvider {...methods}>
      <ReceptionBookingFeeding booking={booking} feedingsHandler={feedingsHandler} />
    </FormProvider>
  )
}
