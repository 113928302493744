import * as React from 'react'

export const useFloatingScrollbar = () => {
  const tableContainerRef = React.useRef<HTMLDivElement>(null)
  const scrollbarRef = React.useRef<HTMLDivElement>(null)

  const handleFloatingScrollbarScrollEvent = event => {
    if (!tableContainerRef.current) return
    tableContainerRef.current.scrollLeft = event.target.scrollLeft
  }

  const handleTableContainerScrollEvent = () => {
    if (!tableContainerRef.current || !scrollbarRef.current) return
    scrollbarRef.current.scrollLeft = tableContainerRef.current.scrollLeft
  }

  const handleWheelEvent = event => {
    if (!tableContainerRef.current || !scrollbarRef.current) return
    tableContainerRef.current.scrollLeft = tableContainerRef.current.scrollLeft + event.deltaX
    scrollbarRef.current.scrollLeft = scrollbarRef.current.scrollLeft + event.deltaX
  }

  React.useLayoutEffect(() => {
    if (!scrollbarRef.current) return
    scrollbarRef.current.addEventListener('scroll', handleFloatingScrollbarScrollEvent)

    return () => {
      if (!scrollbarRef.current) return
      scrollbarRef.current.removeEventListener('scroll', handleFloatingScrollbarScrollEvent)
    }
  }, [tableContainerRef.current])

  React.useLayoutEffect(() => {
    if (!tableContainerRef.current) return
    tableContainerRef.current.addEventListener('wheel', handleWheelEvent)
    tableContainerRef.current.addEventListener('scroll', handleTableContainerScrollEvent)

    return () => {
      if (!tableContainerRef.current) return
      tableContainerRef.current.removeEventListener('wheel', handleWheelEvent)
      tableContainerRef.current.removeEventListener('scroll', handleTableContainerScrollEvent)
    }
  }, [tableContainerRef.current])

  const FloatingScrollbar = React.useCallback(
    () => (
      <div className="floating-scrollbar__wrapper">
        <div className="overflow-auto floating-scrollbar" ref={scrollbarRef}>
          <div className="floating-scrollbar__inside" style={{ width: tableContainerRef?.current?.scrollWidth ?? 0 }} />
        </div>
      </div>
    ),
    [],
  )

  return {
    FloatingScrollbar,
    tableContainerRef,
  }
}
