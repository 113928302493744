import * as React from 'react'
import { FormInput, FormPlain } from '@hyper/forms'
import { FormSelect } from '@hyper/forms/form-select'
import { FormCheckbox } from '@hyper/forms/form-checkbox'
import { housekeepingRoleOptions } from '@modules/housekeeping/users/modal/index'
import { Row } from 'reactstrap'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { HousekeepingUser } from '@modules/housekeeping/models'
import { OptionsOrGroups } from 'react-select'

interface Props {
  resortOptions: CustomReactSelectOption[]
  companiesOptions: OptionsOrGroups<any, any>
  user?: HousekeepingUser
  userLanguages: CustomReactSelectOption[]
}

export const HousekeepingUserModalForm = ({
  resortOptions,
  user,
  companiesOptions,
  userLanguages,
}: Props): JSX.Element => (
  <Row>
    <FormInput name="first_name" label="Imię" colSize={6} />
    <FormInput name="last_name" label="Nazwisko" colSize={6} />
    {!user && (
      <>
        <FormInput name="email" type="email" label="Adres e-mail" colSize={12} />
        <FormInput name="password" type="password" label="Hasło" colSize={6} />
        <FormInput name="confirmed_password" type="password" label="Powtórz hasło" colSize={6} />
      </>
    )}
    <FormSelect
      options={userLanguages}
      name="housekeeping_app_language"
      label="Język"
      formPlainProps={{ colSize: 6 }}
    />
    <FormSelect options={resortOptions} name="resort" label="Ośrodek" formPlainProps={{ colSize: 6 }} />
    <FormSelect options={companiesOptions} name="housekeeping_company" label="Firma" formPlainProps={{ colSize: 6 }} />
    <FormSelect
      options={housekeepingRoleOptions}
      name="roles"
      label="Rola"
      selectProps={{ isSelectMulti: true, isClearable: true }}
    />
    <FormInput name="phone" label="Numer telefonu" colSize={6} />
    <FormInput name="rcp_badge" label="RCP Id" colSize={6} />
    <FormPlain name="is_active" colSize={6} hideErrors={true}>
      <FormCheckbox name="is_active" label="Aktywny" id="from_is_active" />
    </FormPlain>
  </Row>
)
