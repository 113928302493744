import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { PackageListFilterParams } from '@modules/package/list/filter'
import { ColAuto } from '@hyper/col-auto'
import { FormInput } from '@hyper/forms'
import { Button, Row } from 'reactstrap'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { RootState, useAppSelector } from '@store/index'
import { startOfToday } from 'date-fns'
import { TableFilters } from '@components/table/table'
import { useModal } from '@components/modals/use-modal'
import { ButtonWithIcon } from '@components/button-with-icon'

export interface PaymentRegisterCashboxFiltersParams extends TableFilters {
  year: string
  resort: string
}

interface Props {
  filters: PaymentRegisterCashboxFiltersParams
  setFilters: (filters: PaymentRegisterCashboxFiltersParams) => void
  defaultFilters: PaymentRegisterCashboxFiltersParams
  resortId: number
}

export const PaymentRegisterCashboxFilters = ({
  filters,
  setFilters,
  defaultFilters,
  resortId,
}: Props): JSX.Element => {
  const methods = useForm<PaymentRegisterCashboxFiltersParams>({
    defaultValues: defaultFilters,
  })
  const today = startOfToday()
  const seasons = useAppSelector((state: RootState) => state.appState.appData.dates.seasons).filter(
    row => row <= today.getFullYear(),
  )

  const onSubmit = async (payload: PackageListFilterParams) => setFilters({ ...filters, ...payload })
  const [handleSafeModal] = useModal('CashboxSafeModal', { resortId })
  const [handleCashCount] = useModal('CashCountModal', { resort: resortId, allowDownloadReport: true })

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  return (
    <FormProvider {...methods}>
      <Row>
        <ColAuto className="px-0">
          <FormInput name="year" label="Wybierz rok" type="select">
            {seasons.map(row => (
              <option value={row} key={row}>
                {row}
              </option>
            ))}
          </FormInput>
        </ColAuto>
        <ColAuto className="ml-auto">
          <label className="d-block">&nbsp;</label>
          <Button onClick={handleSafeModal} color="primary" className="mr-1">
            Stan sejfu
          </Button>
          <ButtonWithIcon
            icon="uil-money-withdraw"
            color="outline-secondary"
            text="Przelicz gotówkę"
            handleClick={handleCashCount}
          />
        </ColAuto>
      </Row>
    </FormProvider>
  )
}
