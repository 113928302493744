import * as React from 'react'
import Table from '@components/table/table'
import { ReportTask } from '@models/reports'
import { UserReportsTasksTableRowActionsCancel } from '@modules/reports/user-reports/user-reports-tasks-table-row/actions/cancel'
import { UserReportsTasksTableRowActionsRestart } from '@modules/reports/user-reports/user-reports-tasks-table-row/actions/restart'
import { UncontrolledTooltip } from 'reactstrap'
import { useTimeoutWhen } from 'rooks'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'

const TIME_TO_SHOW_REPORT_GENERATION_INFORMATION = 3000

interface Props {
  reportTask: ReportTask
}

export const UserReportsTasksTableRowActions = ({ reportTask }: Props): JSX.Element => {
  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false)

  const isGenerating = reportTask.status === 'processing' || reportTask.status === 'waiting'

  useDidUpdateEffect(() => {
    if (!isGenerating) setIsTooltipOpen(false)
  }, [isGenerating])

  useTimeoutWhen(
    () => {
      setIsTooltipOpen(isGenerating)
    },
    TIME_TO_SHOW_REPORT_GENERATION_INFORMATION,
    isGenerating,
  )

  return (
    <Table.Cell id={`report-tooltip-${reportTask.id}`}>
      {reportTask.status === 'completed' && (
        <a href={reportTask.url} className="action-icon" title="Pobierz">
          <i className="uil uil-download-alt text-muted" />
        </a>
      )}

      {reportTask.status !== 'completed' && (
        <>
          <UncontrolledTooltip
            isOpen={isTooltipOpen}
            popperClassName="zi-0"
            placement="left"
            target={`report-tooltip-${reportTask.id}`}
            style={{ width: '225px', maxWidth: '225px' }}
          >
            <>
              <p className="font-weight-semibold text-right mb-1 font-12">Raport w trakcie generowania</p>
              <p className="font-weight-light font-10 text-right mb-0">
                Może to potrwać dłuższą chwilę <br />w zależności od skomplikowania zapytania
              </p>
            </>
          </UncontrolledTooltip>
          <UserReportsTasksTableRowActionsRestart reportTask={reportTask} />
          {reportTask.status !== 'deleted' && <UserReportsTasksTableRowActionsCancel reportTask={reportTask} />}
        </>
      )}
    </Table.Cell>
  )
}
