import * as React from 'react'
import { Button, CustomInput, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import ModalBody from 'reactstrap/lib/ModalBody'
import { FormProvider, useForm } from 'react-hook-form'
import { endOfMonth, startOfMonth, startOfToday } from 'date-fns'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { createReportTask } from '@store/actions/report-tasks-actions'
import { FormInput, FormPlain } from '@hyper/forms'
import { extractInnerRef } from '@helpers/forms'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { BaseModalProps } from '@components/modals/types'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'

interface FormInputs {
  stay_after: Date
  stay_before: Date
  date_from_range: boolean
  created_at_after: Date | null
  created_at_before: Date | null
  resort: number | null
}

export const ReportsReservationBillModal: React.FC<BaseModalProps> = ({ toggleIsVisible }) => {
  const reportUrl = useAppSelector(
    (state: RootState) => state.appState.appData.urls.reports.report_task_reservation_bill_report,
  )
  const dispatch = useAppDispatch()
  const user = useAuthenticatedUser()

  const methods = useForm<FormInputs>({
    mode: 'all',
    defaultValues: {
      stay_after: startOfMonth(startOfToday()),
      stay_before: endOfMonth(startOfToday()),
      date_from_range: false,
      created_at_after: null,
      created_at_before: null,
      resort: null,
    },
  })

  const { isLoading, action: onSubmit } = useReportFormRequest(async (payload: FormInputs) => {
    const reportTask = await dispatch(createReportTask([reportUrl, payload]))
    toggleIsVisible()

    return reportTask
  }, methods.setError)

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggleIsVisible}>Rezerwacje - Płatności na pokój (zbiorczy)</ModalHeader>
        <ModalBody>
          <Row>
            <DateFromToInput label="Termin pobytu" startDateName="stay_after" endDateName="stay_before" />
            <FormPlain name="date_from_range" errors={{}} colClassName="mb-2">
              <CustomInput
                className="mt-2"
                type="checkbox"
                {...extractInnerRef(methods.register('date_from_range'))}
                id="date_from_range"
                label="Uwzględnij również rezerwacje rozpoczęte"
              />
            </FormPlain>

            <DateFromToInput label="Data utworzenia" startDateName="created_at_after" endDateName="created_at_before" />
          </Row>
          <Row>
            <FormInput colSize={6} name="resort" label="Ośrodek" type="select">
              <option value="">Wszystkie</option>
              {user.resorts.map(resort => (
                <option value={resort.id} key={resort.id}>
                  {resort.name}
                </option>
              ))}
            </FormInput>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="light" type="button" onClick={toggleIsVisible}>
            Zamknij
          </Button>
          <SaveButton className="btn btn-green" label="Zleć raport" labelSaving="Zlecanie.." isSaving={isLoading} />
        </ModalFooter>
      </form>
    </FormProvider>
  )
}
