import * as React from 'react'
import { useDocumentTitle } from '@helpers/utils'
import { PageTitle } from '@hyper/page-title'
import { Card, CardBody } from 'reactstrap'
import { useAppDispatch, useAppSelector } from '@store/index'
import { RecommendationGiftsTable } from '@modules/crm/recommendation-gifts/recommendation-gifts-table'
import { RecommendationGiftFilters } from '@modules/crm/recommendation-gifts/recommendation-gift-filters'
import { TableFilters } from '@components/table/table'
import { getRecommendationGifts } from '@store/actions/recommendation-gift-actions'
import { recommendationGiftsSelector } from '@store/slices/recommendation-gift-slice'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { RecommendationGiftsWebSocketHandler } from '@modules/crm/recommendation-gifts/web-socket-handler'
import { useParams } from 'react-router-dom'
import { RecommendationGiftDetailsView } from '@modules/crm/recommendation-gifts/recommendation-gifts-details-view'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useTableFilters } from '@components/table/use-table-filters'

const pageTitle = 'Prezenty za polecenie'

export interface RecommendationGiftsFilters extends TableFilters {
  transfer_by_day: Date | null
  kind: CustomReactSelectOption[]
  transfer_status: CustomReactSelectOption[]
  seller: CustomReactSelectOption | null
  received_date: Date | null
}

const defaultFilters: RecommendationGiftsFilters = {
  search: '',
  ordering: '-transfer_date',
  page: 1,
  page_size: 10,
  received_date: null,
  transfer_by_day: null,
  transfer_status: [],
  kind: [],
  seller: null,
}

export const RecommendationGiftsView: React.FC = () => {
  useMenuActive(NavigationPath.CrmRecommendationGifts)
  useDocumentTitle(pageTitle)

  const { id: recommendation_gift_id } = useParams<'id'>()

  const dispatch = useAppDispatch()

  const recommendationGifts = useAppSelector(recommendationGiftsSelector)

  const { isLoading, filters, setFilters } = useTableFilters<RecommendationGiftsFilters>({
    defaultFilters,
    action: async filters => await dispatch(getRecommendationGifts(filters)),
  })

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitle} />
      <Card>
        <CardBody>
          <RecommendationGiftFilters defaultFilters={defaultFilters} filters={filters} setFilters={setFilters} />
          <RecommendationGiftsWebSocketHandler />
          {recommendation_gift_id && <RecommendationGiftDetailsView recommendation_gift_id={recommendation_gift_id} />}

          <RecommendationGiftsTable
            filters={filters}
            setFilters={setFilters}
            isLoading={isLoading}
            recommendationGifts={recommendationGifts}
          />
        </CardBody>
      </Card>
    </>
  )
}
