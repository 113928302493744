import * as React from 'react'
import { useModal } from '@components/modals/use-modal'
import { useLocation } from 'react-router-dom'

export const useDetailsModal = (
  modalParams: Parameters<typeof useModal>,
  basePath: string,
  pathWithDetailsParam: string,
  objectId: number,
  idParamsName = ':id',
) => {
  const [showModal] = useModal(...modalParams)
  const location = useLocation()

  const showDetailsModal = () => {
    showModal()

    if (!location.pathname.startsWith(basePath)) return
    window.history.replaceState({}, '', `/dashboard${pathWithDetailsParam.replaceParam(idParamsName, objectId)}`)
  }

  return {
    handleClientDetails: showDetailsModal,
  }
}

export const useDetailsModalUnmountParamsClear = (objectId: number) => {
  React.useEffect(
    () => () => {
      window.history.replaceState({}, '', window.location.href.replace(new RegExp(`${objectId}/$`), ''))
    },
    [],
  )
}
