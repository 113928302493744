import { createSelectOption } from '@helpers/utils'

export const PaymentRegisterTypesOptions = [
  { value: 'KW', label: 'KW' },
  { value: 'KP', label: 'KP' },
]

export const PaymentRegisterKindOptions = {
  KW: [
    createSelectOption('Wypłata pracownika', 'WP'),
    createSelectOption('Wypłata do banku KW-Bank', 'WB'),
    createSelectOption('Wyplata do kasetki (zasilenie z sejfu)', 'receptionist_payin_safebox'),
    createSelectOption('Niedobór pracownika', 'receptionist_payout_missing'),
  ],
  KP: [
    createSelectOption('Wpłata pracownika', 'PP'),
    createSelectOption('Wpłata z kasetki', 'receptionist_payout_safebox'),
    createSelectOption('Wpłata do sejfu - z kasetki pracownika', 'safebox_payout_end_day'),
    createSelectOption('Nadwyżka pracownika', 'safebox_over_amount_payin'),
    createSelectOption('Wpłata utargu z punktu', 'point_revenue'),
  ],
}
