import { TableFilters } from '@components/table/table'
import * as React from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Label, Row } from 'reactstrap'
import { ColAuto } from '@hyper/col-auto'
import DateRangeInput from '@components/date/date-range-input'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { IconWithText } from '@components/icon-with-text'
import { createSelectOption, formatPrice } from '@helpers/utils'
import { RootState, useAppSelector } from '@store/index'
import { Form } from '@hyper/forms/form'
import { FormSelect } from '@hyper/forms/form-select'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { PaymentRegisterKindOptions, PaymentRegisterTypesOptions } from '@modules/payment-registers/consts'
import { PaymentRegister } from '@models/payments'

export interface CashboxSafeModalFiltersParams extends TableFilters {
  created_after: Date
  created_before: Date
  type: CustomReactSelectOption | null
  kinds: CustomReactSelectOption[]
  created_by: CustomReactSelectOption | null
}

interface Props {
  setFilters: (filters: CashboxSafeModalFiltersParams) => void
  filters: CashboxSafeModalFiltersParams
  defaultFilters: CashboxSafeModalFiltersParams
  paymentRegisters: PaymentRegister[]
}

export const CashboxSafeModalFilters = ({
  defaultFilters,
  filters,
  setFilters,
  paymentRegisters,
}: Props): JSX.Element => {
  const methods = useForm<CashboxSafeModalFiltersParams>({
    defaultValues: defaultFilters,
  })

  const type = useWatch({ control: methods.control, name: 'type' })

  const onSubmit = async (payload: CashboxSafeModalFiltersParams) => setFilters({ ...filters, ...payload })
  const balanceAfter = useAppSelector((state: RootState) => state.cashState.balance.after)

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  const users = paymentRegisters.reduce((users: CustomReactSelectOption[], paymentRegister) => {
    if (users.some(user => paymentRegister.created_by_id === user.value)) return users
    return [...users, createSelectOption(paymentRegister.created_by, paymentRegister.created_by_id)]
  }, [])

  return (
    <Form methods={methods} onSubmit={onSubmit} className="form form-style-filter mb-2">
      <Row className="mt-3 align-items-end">
        <ColAuto>
          <Label>Okres rozliczenia</Label>
          <DateRangeInput startDateName="created_after" endDateName="created_before" wrapperClassName="is-range" />
        </ColAuto>
        <FormSelect
          options={users}
          name="created_by"
          label="Dodane przez"
          formPlainProps={{ formGroupClassName: 'mb-0', colStyle: { maxWidth: '200px' } }}
          selectProps={{ isClearable: true }}
        />
        <FormSelect
          options={PaymentRegisterTypesOptions}
          name="type"
          label="Typ operacji"
          formPlainProps={{ formGroupClassName: 'mb-0', colStyle: { maxWidth: '115px' } }}
          selectProps={{ isClearable: true }}
        />
        <FormSelect
          options={PaymentRegisterKindOptions[type?.value] ?? []}
          name="kinds"
          label="Rodzaj operacji"
          formPlainProps={{ colSize: 3, formGroupClassName: 'mb-0' }}
          selectProps={{ isClearable: true, isSelectMulti: true }}
        />
        <ColAuto className="col-auto ml-auto mt-3">
          <div className="d-flex align-items-center justify-content-end font-14">
            <strong>Saldo końcowe:</strong>
            <IconWithText
              icon="uil-usd-circle mr-2 ml-2 font-16"
              wrapperClassNames="text-semi-strong"
              text={formatPrice(balanceAfter)}
            />
          </div>
        </ColAuto>
      </Row>
    </Form>
  )
}
