import * as React from 'react'
import { ReceptionBookingDetails, ReceptionReminder } from '@models/reception'
import ModalBody from 'reactstrap/lib/ModalBody'
import { newUserPredicator, ReceptionCheckReminders } from '@modules/reception/common/reception-check-reminders'
import { FormProvider, useForm } from 'react-hook-form'
import { getReceptionBookingsAggregation } from '@store/actions/reception-actions'
import { ReceptionBookingCheckInStepAccessInfo } from '@modules/reception/checkin/step-access/step-access-info'
import { useAppDispatch, useAppSelector } from '@store/index'
import { ReceptionBookingCheckInStepAccessKeys } from '@modules/reception/checkin/step-access/reception-booking-check-in-step-access-keys'
import { ReceptionBookingCheckInStepAccessBands } from '@modules/reception/checkin/step-access/reception-booking-check-in-step-access-bands'
import { ReceptionBookingCheck } from '@modules/reception/common/reception-booking-check'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { resortsMapSelector } from '@store/selectors/dashboard'
import { commonObjectPatch, commonObjectPost } from '@store/actions/generic-actions'
import { useFormRequest } from '@components/hooks/use-api-request'
import { removeReceptionBooking, setReceptionBookingDetails } from '@store/slices/reception-slice'
import { ReceptionBookingCheckInStepHpfTicketPrintBanner } from '@modules/reception/checkin/step-access/tickets/reception-booking-check-in-step-hpf-ticket-print-banner'

interface ReceptionBookingCheckInStepAccessProps {
  nextStep?: () => void
  previousStep: () => void
  booking: ReceptionBookingDetails
  handleIsOpen: () => void
  title: string
  previousLabel?: string
}

interface FormInputs {
  check_map: boolean
  print_hpf_tickets: boolean
  keys: number
}

const reminders: ReceptionReminder[] = [
  {
    id: 'check_map',
    isClosable: true,
    children: (
      <>
        Pamiętaj, aby wydać Gościowi <strong>mapę ośrodka</strong> z zaznaczonym domkiem.
      </>
    ),
  },
]

export const ReceptionBookingCheckInStepAccess: React.FC<ReceptionBookingCheckInStepAccessProps> = ({
  booking,
  previousStep,
  nextStep,
  handleIsOpen,
  title,
  previousLabel,
}) => {
  const [isPrinted, setIsPrinted] = React.useState(false)

  const user = useAuthenticatedUser()
  const methods = useForm<FormInputs>({
    defaultValues: {
      keys: 1,
      check_map: newUserPredicator(user),
      print_hpf_tickets: false,
    },
  })

  const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()

  const { isLoading, action: onSubmit } = useFormRequest(
    async (payload: FormInputs) => {
      await commonObjectPatch<ReceptionBookingDetails>(booking.urls.check_in_step_access, {
        check_map: payload.check_map,
        ...(!booking.bands.length && { keys: payload.keys }),
      })
      dispatch(removeReceptionBooking(booking))
      addSuccessMessage(
        'Meldowanie zakończone',
        `Meldunek rezerwacji o numerze ${booking.reservation_number} (${booking.name}) został zakończony!`,
      )
      dispatch(await getReceptionBookingsAggregation())
      handleIsOpen()
      await commonObjectPost<ReceptionBookingDetails>(booking.urls.check_status, { action: 'check-in-finished' })
    },
    methods.setError,
    { showGlobalError: true },
  )

  const resort = useAppSelector(resortsMapSelector)[booking.resort_id]

  const updateState = (bookingDetails: ReceptionBookingDetails) => dispatch(setReceptionBookingDetails(bookingDetails))

  const hpfTicket = booking.improvements.find(el => el.code === 'hpf_ticket')
  const handlePrint = () => setIsPrinted(true)

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        role="step-access"
        className="reception__booking-check-in__access-step"
      >
        <ModalBody className="pb-0">
          {hpfTicket && (
            <ReceptionBookingCheckInStepHpfTicketPrintBanner
              ticketImprovement={hpfTicket}
              onPrint={handlePrint}
              isPrinted={isPrinted}
              booking={booking}
            />
          )}

          <h4>{title}</h4>
          <ReceptionCheckReminders reminders={reminders} required={!newUserPredicator(user)} />
          {resort?.has_band ? (
            <ReceptionBookingCheckInStepAccessBands booking={booking} updateState={updateState} />
          ) : (
            <ReceptionBookingCheckInStepAccessKeys apartmentName={booking.apartment.name} />
          )}
        </ModalBody>
        <ReceptionBookingCheckInStepAccessInfo />
        <ReceptionBookingCheck
          isSaving={isLoading}
          nextLabel="Zakończ meldowanie"
          nextLabelSaving="Meldowanie gościa.."
          nextStep={nextStep}
          previousStep={previousStep}
          previousLabel={previousLabel}
          isDisabled={hpfTicket && !isPrinted}
        />
      </form>
    </FormProvider>
  )
}
