import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { BaseModalProps } from '@components/modals/types'
import { useApiRequest } from '@components/hooks/use-api-request'
import { deleteShopReceipt } from '@store/actions/shop-actions'
import { useAppDispatch } from '@store/index'
import { NavigationPath } from '@models/routes'
import { useNavigate } from 'react-router-dom'
import { Receipt } from '@models/shop'

interface Props extends BaseModalProps {
  receipt: Receipt
}

export const ShopReceiptNotFulfilledModal: React.FC<Props> = ({ receipt, toggleIsVisible }) => {
  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const { action: clearShopBasket, isLoading } = useApiRequest(async () => {
    dispatch(deleteShopReceipt(receipt))

    toggleIsVisible()
  })

  const returnToShopView = () => {
    navigate(
      NavigationPath.ShopPOSWithParamsAndToken.replace(':resort_id', receipt.resort.toString()).replace(
        ':token',
        receipt.token,
      ),
    )
    toggleIsVisible()
  }

  return (
    <>
      <ModalHeader className="bg-warning text-dark py-2 rounded-0 lh-initial">Niezakończona sprzedaż</ModalHeader>
      <ModalBody>
        <p>
          Ostatnia sprzedaż w sklepie nie została sfinalizowana. <br />W koszyku nadal znajdują się dodane przedmioty.
        </p>
        <p>
          Musisz <strong>dodać wpłaty</strong> ze sprzedaży lub <strong>zresetować</strong> zawartość koszyka.
        </p>
      </ModalBody>

      <ModalFooter className="d-flex">
        <Button color="light" onClick={clearShopBasket}>
          Chcę wyczyścić zawartość koszyka
        </Button>

        <SaveButton
          isSaving={isLoading}
          label="Chcę sfinalizować sprzedaż"
          labelSaving="Generowanie..."
          className="btn btn-warning"
          onClick={returnToShopView}
        />
      </ModalFooter>
    </>
  )
}
