import * as React from 'react'
import {
  HousekeepingImprovementOrderType,
  ImprovementOrder,
  ImprovementOrderDetails,
} from '@modules/housekeeping/models'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap'
import { getHousekeepingImprovementOrders } from '@store/actions/housekeeping-actions'
import { useAppDispatch } from '@store/index'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useModal } from '@components/modals/use-modal'
import { useApiRequest } from '@components/hooks/use-api-request'
import { updateHousekeepingImprovementOrderDetails } from '@store/slices/housekeeping-slice'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'

interface Props {
  selectedOrders: ImprovementOrder[]
  resortId: number
}

export const HousekeepingImprovementOrdersRowOptions: React.FC<Props> = ({ selectedOrders, resortId }) => {
  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()
  const { action: refreshOrders } = useApiRequest(async () => {
    await dispatch(getHousekeepingImprovementOrders([{ resort: resortId }, HousekeepingImprovementOrderType.PRESENT]))
  })

  const [handleAssign] = useModal('HousekeepingCleaningOrderGroupAssignModal', {
    refreshOrders,
    selectedOrders: selectedOrders,
    onlyHprCompany: true,
    resortId,
    serviceKind: 'bedding',
  })

  const isDisabled = selectedOrders.length === 0
  const { isLoading, action: handleCloseAccept } = useApiRequest(async () => {
    for (const order of selectedOrders) {
      dispatch(
        updateHousekeepingImprovementOrderDetails(
          await commonObjectPost<ImprovementOrderDetails>(order.urls.finish_working),
        ),
      )
    }

    addSuccessNotification('Zlecenia zostały zamknięte!')
  })

  return (
    <div>
      <UncontrolledButtonDropdown disabled={isDisabled}>
        <DropdownToggle color="light" caret={true} disabled={isDisabled}>
          <strong>zaznaczone ({selectedOrders.length})</strong>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={handleAssign}>
            <i className="uil uil-truck mr-1 text-bold" />
            Przydziel
          </DropdownItem>
          <CommonObjectConfirmAction
            isLoading={isLoading}
            handleAccept={handleCloseAccept}
            message={`Czy na pewno chcesz zamknać wybrane zlecenia ? Wybrano ${selectedOrders.length} zleceń`}
          >
            <DropdownItem>
              <i className="uil  uil-multiply mr-1 text-bold text-danger" />
              Zamknij
            </DropdownItem>
          </CommonObjectConfirmAction>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </div>
  )
}
