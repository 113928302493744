import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { ReceptionBookingDetails } from '@models/reception'
import { SaveButton } from '@hyper/button'
import { Label, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { useFormRequest } from '@components/hooks/use-api-request'
import { FormInput, FormPlain } from '@hyper/forms'
import { useAppDispatch } from '@store/index'
import { updateReceptionBookingDetails } from '@store/actions/reception-actions'
import { commonObjectPut } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { parseISODate } from '@helpers/date-helper'
import DatePickerInput from '@components/date/date-picker-input'
import { FormCheckbox } from '@hyper/forms/form-checkbox'
import { ColAuto } from '@hyper/col-auto'

interface Props extends BaseModalProps {
  booking: ReceptionBookingDetails
}

interface FormInputs {
  disable_auto_cancel_date: Date | undefined | null
  disable_auto_cancel: boolean
}

export const ReservationDisableAutoCancelModal: React.FC<Props> = ({ booking, toggleIsVisible }) => {
  const methods = useForm<FormInputs>({
    defaultValues: {
      disable_auto_cancel: booking.disable_auto_cancel,
      disable_auto_cancel_date: booking.disable_auto_cancel_date
        ? parseISODate(booking.disable_auto_cancel_date)
        : undefined,
    },
  })
  const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()

  const { isLoading, action: onSubmit } = useFormRequest(
    async (formData: FormInputs) => {
      dispatch(
        updateReceptionBookingDetails(
          await commonObjectPut<ReceptionBookingDetails>(booking.urls.reservation_booking_details, formData),
        ),
      )

      addSuccessMessage()
      toggleIsVisible()
    },

    methods.setError,
    { showGlobalError: true },
  )

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggleIsVisible}>Zmiana ustawień auto-anulowania</ModalHeader>
        <ModalBody>
          <Row>
            <ColAuto className="mb-3 mx-2">
              <FormCheckbox name="disable_auto_cancel" label="Zablokuj auto-anulowanie" />
            </ColAuto>
            <FormPlain name="disable_auto_cancel_date">
              <Label>Wyłącz auto-anulowanie do dnia</Label>
              <DatePickerInput
                control={methods.control}
                name="disable_auto_cancel_date"
                wrapperClassName="flex-grow-1"
              />
            </FormPlain>
            <FormInput label="Powód zmiany" name="reason" type="textarea" />
          </Row>
        </ModalBody>
        <ModalFooter>
          <button type="button" onClick={toggleIsVisible} className="btn btn-light">
            Anuluj
          </button>
          <SaveButton isSaving={isLoading} className="btn btn-green" />
        </ModalFooter>
      </form>
    </FormProvider>
  )
}
