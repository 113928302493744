import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import { SaveButton } from '@hyper/button'
import { Form } from '@hyper/forms/form'
import { useForm, useWatch } from 'react-hook-form'
import { useCreateBenefitProgramAgreementMutation, useGetBenefitProgramDetailsQuery } from '@api/benefit-programs'
import { BenefitProgramAgreementCreateField } from '@modules/benefit-program/agreements/create/field-row'
import { useHandleRtkQueryError } from '@components/hooks/use-handle-rtk-error'
import { BenefitProgramDetails } from '@modules/benefit-program/models'
import { TypedQueryResult } from '@api/base'
import { EmailInvite, handleEmailInvitationErrors } from '@components/email-invite/email-invite'

interface FormInputs {
  emails: BenefitProgramAgreementCreateField[]
}

interface Props extends BaseModalProps {
  onCreate: () => void
  benefitProgramId: number
}

export const BenefitProgramAgreementCreateModal = ({
  onCreate,
  toggleIsVisible,
  benefitProgramId,
}: Props): React.ReactNode => {
  const { data: benefitProgramDetails } = useGetBenefitProgramDetailsQuery(
    { id: benefitProgramId },
    { skip: !benefitProgramId },
  ) as TypedQueryResult<BenefitProgramDetails>

  const methods = useForm<FormInputs>({ defaultValues: { emails: [{ value: '' }] } })
  const emails = useWatch({ control: methods.control, name: 'emails' })

  const [createBenefitProgramAgreement, { isLoading, error, isError }] = useCreateBenefitProgramAgreementMutation()
  useHandleRtkQueryError(error, isError, handleEmailInvitationErrors(methods.setError))

  const handleSubmit = async (data: FormInputs) => {
    if (!benefitProgramDetails) return

    await createBenefitProgramAgreement({
      url: benefitProgramDetails.urls.invite,
      payload: { emails: data.emails.map(({ value }) => value).filter(Boolean) },
    }).unwrap()

    onCreate()
    toggleIsVisible()
  }

  const isFormFilled = emails.every(field => field.value)

  return (
    <Form methods={methods} onSubmit={handleSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Zaproś pracownika</ModalHeader>
      <ModalBody>
        <EmailInvite
          title="Wpisz adres e-mail pracownika"
          subtitle="Na podany adres e-mail pracownik otrzyma dalsze instrukcje jak przystąpić do programu."
          invites={benefitProgramDetails?.invites ?? []}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="ligh" onClick={toggleIsVisible}>
          Anuluj
        </Button>
        <SaveButton isSaving={isLoading} label="Dodaj" className="btn btn-green" disabled={!isFormFilled} />
      </ModalFooter>
    </Form>
  )
}
