import * as React from 'react'
import { Button, Col, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { FormInput } from '@hyper/forms'
import { SaveButton } from '@hyper/button'
import { FormProvider, useForm } from 'react-hook-form'
import ModalBody from 'reactstrap/lib/ModalBody'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useFormRequest } from '@components/hooks/use-api-request'
import { deleteSeller, updateSellerDetails } from '@store/actions/crm-actions'
import { commonObjectDelete, commonObjectPatch, commonObjectPost } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { Seller } from '@models/crm'
import { departments } from '@modules/seller/list/filter'
import { ButtonWithIcon } from '@components/button-with-icon'
import { BaseModalProps } from '@components/modals/types'
import * as R from 'ramda'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'

interface AddSellerModalProps extends BaseModalProps {
  seller?: Seller
}

interface FormInputs {
  name: string
  group: string
  department: 'BOK' | 'BOS' | 'B2B' | 'reception' | 'director' | 'other'
  gender: 'male' | 'female'
  phone: string
}

export const AddSellerModal: React.FC<AddSellerModalProps> = ({ toggleIsVisible, seller }) => {
  const methods = useForm<FormInputs>({
    mode: 'all',
    defaultValues: R.pick<FormInputs, keyof FormInputs>(
      ['name', 'group', 'department', 'gender', 'phone'],
      seller || {},
    ),
  })

  const url = useAppSelector((state: RootState) => state.appState.appData.urls.crm.sellers)

  const dispatch = useAppDispatch()

  const { addSuccessNotification } = useNotificationHook()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    dispatch(
      updateSellerDetails(
        !seller
          ? await commonObjectPost<Seller>(url, {
              ...payload,
            })
          : await commonObjectPatch<Seller>(seller.urls.details, {
              ...payload,
            }),
      ),
    )
    addSuccessNotification(!seller ? 'Sprzedawca został dodany' : 'Sprzedawca został edytowany')

    toggleIsVisible()
  }, methods.setError)

  const removeSeller = async () => {
    if (seller) {
      dispatch(deleteSeller(await commonObjectDelete<Seller>(seller.urls.details)))
      addSuccessNotification('Sprzedawca został usunięty')
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggleIsVisible}>{seller ? 'Edytuj Sprzedawcę' : 'Dodaj Sprzedawcę'}</ModalHeader>
        <ModalBody>
          <Row>
            <FormInput label="Imię i nazwisko" name="name" />
            <FormInput label="Grupa" name="group" />
            <FormInput label="Dział" name="department" type="select">
              {departments.map(el => (
                <option value={el.value} key={el.value}>
                  {el.label}
                </option>
              ))}
            </FormInput>
            <FormInput label="Płeć" name="gender" type="select">
              <option value="female">kobieta</option>
              <option value="male">mężczyzna</option>
            </FormInput>
            <FormInput label="Numer telefonu" name="phone" />
          </Row>
        </ModalBody>
        <ModalFooter style={{ display: 'block' }}>
          <Row>
            <Col md={6} className="px-0">
              {seller && (
                <CommonObjectConfirmAction
                  title="Potwierdź usunięcie Sprzedawcy"
                  message="Czy na pewno chcesz usunąć wybranego Sprzedawcę? Operacja ta jest nieodwracalna."
                  handleAccept={removeSeller}
                >
                  <ButtonWithIcon icon="uil-trash-alt" color="danger" text="Usuń" />
                </CommonObjectConfirmAction>
              )}
            </Col>
            <Col md={6} className="text-right px-0">
              <Button className="btn btn-light mr-1" type="button" onClick={toggleIsVisible}>
                Anuluj
              </Button>
              <SaveButton label="Zapisz" className="btn-green btn" isSaving={isLoading} />
            </Col>
          </Row>
        </ModalFooter>
      </form>
    </FormProvider>
  )
}
