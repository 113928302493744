import * as React from 'react'
import { Button, Label, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import { useForm, useWatch } from 'react-hook-form'
import { SaveButton } from '@hyper/button'
import { useFormRequest } from '@components/hooks/use-api-request'
import { FormInput, FormPlain } from '@hyper/forms'
import { CustomReactSelectOption, makeDefaultSelectOption } from '@components/custom-react-select'
import { commonObjectPatch } from '@store/actions/generic-actions'
import { Form } from '@hyper/forms/form'
import { FormSelect } from '@hyper/forms/form-select'
import { AgreementDetails } from '@modules/hr/agreement/models'
import { updateAgreementDetails } from '@store/slices/hr-slice'
import { useDispatch } from 'react-redux'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { AgreementStatusOptions } from '@modules/hr/consts'
import DatePickerInput from '@components/date/date-picker-input'
import { startOfToday } from 'date-fns'
import { AGREEMENTS_API_TAG_IDS, agreementsApi } from '@api/agreements'

interface FormInputs {
  status: CustomReactSelectOption | null
  date_of_start: Date | null
}

interface Props extends BaseModalProps {
  agreementDetails: AgreementDetails
}

export const AgreementStatusChangeModal: React.FC<Props> = ({ agreementDetails, toggleIsVisible }): JSX.Element => {
  const dispatch = useDispatch()
  const user = useAuthenticatedUser()

  const { addSuccessNotification } = useNotificationHook()

  const AgreementStatusAvailableOptions = AgreementStatusOptions.filter(option => {
    if (agreementDetails.nationality === 'PL') {
      return !['draft_incomplete', 'verified', 'to_verify'].includes(option.value)
    }
    return option.value !== 'draft_incomplete'
  })

  const methods = useForm<FormInputs>({
    defaultValues: {
      status: makeDefaultSelectOption(AgreementStatusOptions, agreementDetails.status),
      date_of_start: null,
    },
  })

  const { action: handleSubmit, isLoading } = useFormRequest(async (payload: FormInputs) => {
    methods.clearErrors()

    dispatch(
      updateAgreementDetails(
        await commonObjectPatch<AgreementDetails>(agreementDetails.urls.details, {
          status: payload.status?.value,
          ...(shouldEnterNewStartDate && { date_of_start: payload.date_of_start }),
        }),
      ),
    )

    dispatch(agreementsApi.util?.invalidateTags([AGREEMENTS_API_TAG_IDS.DETAILS, AGREEMENTS_API_TAG_IDS.LIST]))
    addSuccessNotification('Status umowy został zmieniony.')

    toggleIsVisible()
  }, methods.setError)

  const [selectedStatus, newStartDate] = useWatch({ control: methods.control, name: ['status', 'date_of_start'] })

  const isToVerifyOptionEnabled = () => {
    if (agreementDetails.nationality === 'PL') return false
    return agreementDetails.status === 'draft'
  }

  const isVerifiedOptionEnabled = () => {
    if (agreementDetails.nationality === 'PL') return false
    return agreementDetails.status === 'to_verify' && user.hasPerm(UserPermission.HrAgreementCanVerify)
  }

  const isActiveOptionEnabled = () => {
    if (agreementDetails.nationality === 'PL' && agreementDetails.status === 'draft') return true
    return agreementDetails.status === 'verified'
  }

  const isResignationOptionEnabled = () => ['draft', 'to_verify', 'verified'].includes(agreementDetails.status)

  const isCompletedOptionEnabled = () => agreementDetails.status === 'active'

  const isOptionDisabled = (option: CustomReactSelectOption) => {
    const indexOfCurrentStatus = AgreementStatusAvailableOptions.findIndex(
      status => status.value === agreementDetails.status,
    )

    const indexOfOption = AgreementStatusAvailableOptions.findIndex(status => status.value === option.value)

    if (indexOfOption <= indexOfCurrentStatus && !user.hasPerm(UserPermission.HrAgreementStatusCanRevoke)) {
      return true
    }

    return (
      indexOfOption > indexOfCurrentStatus &&
      ((option.value === 'to_verify' && !isToVerifyOptionEnabled()) ||
        (option.value === 'verified' && !isVerifiedOptionEnabled()) ||
        (option.value === 'active' && !isActiveOptionEnabled()) ||
        (option.value === 'completed' && !isCompletedOptionEnabled()) ||
        (option.value === 'resignation' && !isResignationOptionEnabled()))
    )
  }

  const shouldEnterNewStartDate = agreementDetails.status === 'to_verify' && selectedStatus?.value === 'verified'

  return (
    <Form methods={methods} onSubmit={handleSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Zmień status umowy {agreementDetails.number}</ModalHeader>
      <ModalBody>
        <FormSelect
          name="status"
          options={AgreementStatusAvailableOptions}
          label="Status"
          selectProps={{ isOptionDisabled }}
        />
        {shouldEnterNewStartDate && (
          <FormPlain name="date_of_conclusion">
            <Label>Data rozpoczęcia umowy</Label>
            <DatePickerInput
              name="date_of_start"
              wrapperClassName="flex-grow-1"
              minDate={user.hasPerm(UserPermission.HrAgreementCanSetRetroactiveDate) ? null : startOfToday()}
              dateFormat="dd LLLL yyyy"
              withInput
            />
          </FormPlain>
        )}
        <FormInput label="Proszę podać powód operacji" name="reason" />
      </ModalBody>
      <ModalFooter>
        <Button className="btn btn-light" type="button" onClick={toggleIsVisible}>
          Anuluj
        </Button>
        <SaveButton
          label="Zastosuj"
          className="btn-green btn"
          isSaving={isLoading}
          disabled={shouldEnterNewStartDate && !newStartDate}
        />
      </ModalFooter>
    </Form>
  )
}
