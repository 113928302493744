import * as React from 'react'
import { DropdownItem } from 'reactstrap'
import { SubscriptionDetails, SubscriptionKind, SubscriptionOptionKind } from '@modules/subscription/models'
import { useModal } from '@components/modals/use-modal'

interface Props {
  subscriptionDetails: SubscriptionDetails
}

export const SubscriptionDetailsCodeConversions = ({ subscriptionDetails }: Props): React.ReactNode => {
  const [showConversionModal] = useModal('SubscriptionCodeKindConvertModal', {}, { persist: true })

  const handleConversionClick = (optionKind: SubscriptionOptionKind) => () => {
    showConversionModal(null, { subscriptionDetails, optionKind })
  }

  const couldAddAnotherConversion = (kind: SubscriptionOptionKind) =>
    subscriptionDetails.options.filter(option => option.kind === kind).length < subscriptionDetails.codes.length

  const allHighestConversionInUse = !couldAddAnotherConversion('single_code_kind_convert_to_gold')

  if (allHighestConversionInUse) return null

  const conversions = ConversionsMap.reduce((previous, conversion) => {
    if (conversion.convertableFrom.includes(subscriptionDetails.kind) && couldAddAnotherConversion(conversion.kind)) {
      return [...previous, conversion]
    }
    return previous
  }, [])

  return conversions.map(conversion => (
    <DropdownItem key={conversion.kind} onClick={handleConversionClick(conversion.kind)}>
      {conversion.name}
    </DropdownItem>
  ))
}

interface ConversionMapItem {
  name: string
  kind: SubscriptionOptionKind
  convertableFrom: SubscriptionKind[]
}

const ConversionsMap: ConversionMapItem[] = [
  {
    name: 'Konwersja kodu na srebrny',
    kind: 'single_code_kind_convert_to_silver',
    convertableFrom: ['platinum', 'gray', 'brown'],
  },
  {
    name: 'Konwersja kodu na złoty',
    kind: 'single_code_kind_convert_to_gold',
    convertableFrom: ['platinum', 'gray', 'brown', 'silver'],
  },
]
