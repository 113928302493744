import * as React from 'react'
import { Col, Row } from 'reactstrap'
import { StepGuestsWalletScopes } from '@modules/reception/checkin/step-guests/step-guests-guest-form/wallet/scopes-list'
import { useFormContext, useWatch } from 'react-hook-form'
import { StepGuestsGuestFormInputs } from '@modules/reception/checkin/step-guests/step-guests-guest-form/models'
import { GuestWalletScope, ReceptionBookingGuest } from '@models/booking'
import { useResortWalletScope } from '@modules/reception/checkin/step-guests/step-guests-guest-form/wallet/use-resort-wallet-scope'

interface Props {
  guest: ReceptionBookingGuest | undefined
  resortId: number
}

export const StepGuestsGuestFormWallet = ({ guest, resortId }: Props): JSX.Element => {
  const {
    control,
    setValue,
    formState: { touchedFields },
  } = useFormContext<StepGuestsGuestFormInputs>()

  const guestType = useWatch({ control, name: 'type' })

  const availableScopes = useResortWalletScope(resortId)

  const defaultWalletLimits = React.useMemo(
    () =>
      availableScopes.reduce((acc, scope) => {
        const isAdult = guestType.value === 'adult'
        const scopeData = DEFAULT_SCOPES.find(({ scope: defaultScope }) => defaultScope === scope)
        return scopeData ? { ...acc, [scope]: { enabled: isAdult, limit: null, ...scopeData } } : acc
      }, {}),
    [guestType],
  )

  React.useEffect(() => {
    if (!guest?.id && !touchedFields.wallet_limits) {
      setValue('wallet_limits', defaultWalletLimits)
    }
  }, [guestType])

  return (
    <>
      <Row>
        <Col md={12}>
          <strong>Dostęp do zakupów:</strong>
        </Col>
      </Row>
      <StepGuestsWalletScopes resortId={resortId} />
    </>
  )
}

interface DefaultScope {
  scope: GuestWalletScope
  scope_display: string
}

const DEFAULT_SCOPES: DefaultScope[] = [
  { scope: 'shop', scope_display: 'Sklep' },
  { scope: 'bistro', scope_display: 'Bistro' },
  { scope: 'cafe', scope_display: 'Kawiarnia' },
  { scope: 'sweet_corner', scope_display: 'Słodki Kącik' },
  { scope: 'beach_bar', scope_display: 'Beach Bar' },
  { scope: 'itinerant_trade', scope_display: 'Wózek' },
  { scope: 'vending_machines', scope_display: 'Automaty (Vendingowe)' },
  { scope: 'vending_machines_gastronomy', scope_display: 'Automaty (gastronomiczne)' },
  { scope: 'vending_machines_entertainment', scope_display: 'Automaty (rozrywkowe)' },
  { scope: 'vending_machines_parkfun', scope_display: 'Automaty Holiday Park & Fun' },
  { scope: 'tickets_parkfun', scope_display: 'Bilety Holiday Park & Fun' },
]
