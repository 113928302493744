import * as React from 'react'
import { PaymentDayUserCloseReport, PaymentDayUserReport } from '@models/payments'
import { UserCashBoxCloseDayCardsTransactions } from '@modules/payment-registers/user-cashbox/close-day-modal/cards/transactions'
import { UserCashBoxCloseDayCardsSummary } from '@modules/payment-registers/user-cashbox/close-day-modal/cards/summary'

interface Props {
  dayCloseReport: PaymentDayUserCloseReport
  readOnly: boolean
  paymentDayUserReport: PaymentDayUserReport
}

export const UserCashBoxCloseDayCards = ({ readOnly, paymentDayUserReport, dayCloseReport }: Props): JSX.Element => (
  <div className="rounded bg-grey ml-2 w-50">
    <UserCashBoxCloseDayCardsTransactions paymentDayUserReport={paymentDayUserReport} dayCloseReport={dayCloseReport} />
    <div className="hr hr-grey-2 mb-1" />
    <UserCashBoxCloseDayCardsSummary
      dayCloseReport={dayCloseReport}
      eservicePadId={paymentDayUserReport.eservice_pad_id}
      receptionWorkstationId={paymentDayUserReport.reception_workstation_id}
      readOnly={readOnly}
      resortId={paymentDayUserReport.resort_id}
    />
  </div>
)
