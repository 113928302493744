import * as React from 'react'
import { NotificationEvents, SystemNotification } from '@models/dashboard'
import { useAppDispatch } from '@store/index'
import { useWebSocket } from '@components/hooks/use-web-socket'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { updateUserSystemNotifications } from '@store/slices/app-slice'

export const NotificationBarWebSocketHandler: React.FC = () => {
  const dispatch = useAppDispatch()
  const { addInfoNotification } = useNotificationHook()

  useWebSocket<SystemNotification>(
    (message, payload) => {
      switch (message) {
        case NotificationEvents.SYSTEM_NOTIFICATION_CHANGE:
          dispatch(updateUserSystemNotifications(payload))
          addInfoNotification(payload.title)
          break
      }
    },
    [],
    [NotificationEvents.SYSTEM_NOTIFICATION_CHANGE],
  )

  return null
}
