import * as React from 'react'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { formatPrice } from '@helpers/utils'
import { ReservationLoadNotesRowActions } from '@modules/reservations/details/load-notes/reservation-load-notes-row-actions'
import { ReceptionBookingDetails, ReceptionBookingLoadNote } from '@models/reception'

interface Props {
  note: ReceptionBookingLoadNote
  booking: ReceptionBookingDetails
}

export const ReservationLoadNotesRow = ({ note, booking }: Props): JSX.Element => (
  <tr>
    <td className="align-middle">{toDefaultDateTimeFormat(note.created)}</td>
    <td className="align-middle">{note.created_by}</td>
    <td className="align-middle">{formatPrice(note.price_brutto)}</td>
    <td className="text-right">
      <ReservationLoadNotesRowActions note={note} booking={booking} />
    </td>
  </tr>
)
