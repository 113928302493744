import * as React from 'react'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { ButtonWithIcon } from '@components/button-with-icon'
import { BaseModalProps } from '@components/modals/types'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { HrWorker } from '@modules/hr/workers/models'
import { useRemoveHrWorkerMutation } from '@api/agreements'

interface Props extends BaseModalProps {
  worker: HrWorker
}

export const HrWorkerDeleteButton = ({ worker, toggleIsVisible }: Props): JSX.Element => {
  const { addSuccessNotification } = useNotificationHook()

  const [removeWorker] = useRemoveHrWorkerMutation()

  const { isLoading, action: handleRemove } = useApiRequest(async (reason: string) => {
    await removeWorker({ worker, reason }).unwrap()
    addSuccessNotification('Pracownik został skasowany')
    toggleIsVisible()
  })

  return (
    <CommonObjectConfirmAction isLoading={isLoading} handleAccept={handleRemove} askForReason>
      <ButtonWithIcon icon="uil-trash-alt font-16" color="outline-danger" text="Usuń pracownika" />
    </CommonObjectConfirmAction>
  )
}
