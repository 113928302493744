import * as React from 'react'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  resortId: number
}

export const HousekeepingApplicationsAddButton: React.FC<Props> = ({ resortId }) => {
  const [toggleFaultDialog] = useModal('HousekeepingApplicationFaultsCreateModal', { resortId })
  const [toggleIssueDialog] = useModal('HousekeepingApplicationIssueCreateModal', { resortId })

  return (
    <div className="ml-auto mb-1">
      <UncontrolledButtonDropdown direction="down" group={true}>
        <DropdownToggle color="primary" caret={true} className="text-semi-strong btn-sm">
          <i className="uil-plus mr-1" />
          Dodaj
        </DropdownToggle>
        <DropdownMenu right={true}>
          <DropdownItem onClick={toggleFaultDialog}>
            <IconWithText icon="uil-wrench text-muted font-15" textClass="text-semi-strong" text="Usterka" />
          </DropdownItem>
          <DropdownItem divider={true} />
          <DropdownItem onClick={toggleIssueDialog}>
            <IconWithText
              icon="uil-exclamation-triangle text-muted font-15"
              textClass="text-semi-strong"
              text="Zgłoszenie gościa"
            />
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </div>
  )
}
