import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { PackageSummarySection, PackageSummarySectionDataRow } from '@modules/package/create/package-summary-section'
import { Row } from 'reactstrap'
import {
  EndlessHolidayCreateFormInputs,
  EndlessHolidayProduct,
} from '@modules/endless-holiday/transactions/create/modal'
import { EndlessHolidayFormSummaryProductRow } from '@modules/endless-holiday/transactions/create/steps/summary/summary-product-row'
import classNames from 'classnames'

export const EndlessHolidayFormSummary = (): React.ReactNode => {
  const { control } = useFormContext<EndlessHolidayCreateFormInputs>()
  const data = useWatch({ control })

  return (
    <div className="px-3 pb-3">
      <Row>
        <PackageSummarySection title="Dane klienta" className="col-6">
          <>
            <PackageSummarySectionDataRow title="Imię i nazwisko:" content={data.client?.name} />
            <PackageSummarySectionDataRow title="Adres e-mail:" content={data.client?.email} />
            <PackageSummarySectionDataRow title="Numer telefonu:" content={data.client?.phone} />
          </>
        </PackageSummarySection>
      </Row>
      <div className="dropdown-divider mx-n3" />
      <Row>
        <PackageSummarySection title="Informacje o ofercie" className="col-6">
          {data.products?.map((product: EndlessHolidayProduct, index: number) => (
            <EndlessHolidayFormSummaryProductRow
              className={classNames({ 'mt-2': index > 0 })}
              key={product.subscription}
              product={product}
            />
          ))}
        </PackageSummarySection>
      </Row>
      <div className="dropdown-divider mx-n3" />
      <Row>
        <PackageSummarySection title="Informacje handlowe" className="col-6">
          <PackageSummarySectionDataRow title="Sprzedawca:" content={data.seller?.label ?? 'brak'} />
          <PackageSummarySectionDataRow title="Źródło marketingowe:" content={data.source_marketing?.label ?? 'brak'} />
        </PackageSummarySection>
      </Row>
      <div className="dropdown-divider mx-n3" />
      <Row>
        <PackageSummarySection title="Link do oferty" className="col-12">
          <p className="opacity-75 mb-0">
            Link do konfiguracji oferty zostanie wysłany na podany adres e-mail.
            <strong className="text-dark">{data.client?.email}</strong>
          </p>
        </PackageSummarySection>
      </Row>
    </div>
  )
}
