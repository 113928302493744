import * as React from 'react'
import { useModal } from '@components/modals/use-modal'
import { ButtonWithIcon } from '@components/button-with-icon'

export const TechnicalOrdersDoneReportDownloadButton: React.FC = () => {
  const [showTechnicalOrdersReportModal] = useModal('ReportTechnicalOrdersModal')

  return (
    <ButtonWithIcon
      color="secondary"
      icon="uil-download-alt mr-1"
      text="Eksportuj"
      btnClass="ml-auto d-block"
      handleClick={showTechnicalOrdersReportModal}
    />
  )
}
