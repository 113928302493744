import * as React from 'react'
import { StepReservationBox } from '@modules/reservations/create/step-reservation-box'
import { ReceptionBookingCheck } from '@modules/reception/common/reception-booking-check'
import { FormProvider, useFormContext } from 'react-hook-form'
import { useFormRequest } from '@components/hooks/use-api-request'
import { ReceptionReminder } from '@models/reception'
import { newUserPredicator, ReceptionCheckReminders } from '@modules/reception/common/reception-check-reminders'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { ReceptionBookingCheckInStepImprovementsList } from '@modules/reception/checkin/step-improvements/reception-booking-check-in-step-improvements-list'
import { receptionBookingDetailsSelector } from '@store/selectors/reception'
import { ReservationCreateFormInputs } from '@modules/reservations/create/models'
import { useAppSelector } from '@store/index'

interface StepImprovementsProps {
  nextStep: () => void
  previousStep: () => void
}

const reminders: ReceptionReminder[] = [
  {
    id: 'check_additional_improvements_and_feeding',
    isClosable: true,
    children: (
      <>
        Zaproponuj wykup
        <strong> dodatkowych ulepszeń.</strong>
      </>
    ),
  },
  {
    id: 'check_vip',
    isClosable: true,
    children: (
      <>
        Zaproponuj wykup
        <strong> pakietu VIP</strong>
      </>
    ),
  },
]

const StepImprovements = ({ previousStep, nextStep }: StepImprovementsProps): JSX.Element | null => {
  const methods = useFormContext<ReservationCreateFormInputs>()
  const user = useAuthenticatedUser()
  const reservation = useAppSelector(receptionBookingDetailsSelector)

  const { isLoading, action: onSubmit } = useFormRequest(async () => {
    nextStep()
  }, methods.setError)

  if (!reservation) {
    return null
  }

  return (
    <StepReservationBox
      title="3. Wybór ulepszeń"
      footer={
        <ReceptionBookingCheck nextStep={nextStep} previousStep={previousStep} isSaving={isLoading} onSave={onSubmit} />
      }
    >
      <FormProvider {...methods}>
        <ReceptionCheckReminders
          required={!newUserPredicator(user)}
          reminders={reminders.filter(row => (reservation.has_package_vip ? row.id !== 'check_vip' : true))}
        />
        <ReceptionBookingCheckInStepImprovementsList booking={reservation} disabledImprovements={['hpf_ticket']} />
      </FormProvider>
    </StepReservationBox>
  )
}

export default StepImprovements
