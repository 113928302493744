import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@store/index'
import { ServiceBand } from '@modules/bands/service-bands/models'
import {
  createServiceBand,
  getServiceBand,
  getServiceBands,
  updateServiceBand,
  deleteServiceBand,
} from '@store/actions/service-band-actions'

interface ServiceBandState {
  serviceBands: EntityState<ServiceBand>
  serviceBandDetails: ServiceBand | undefined
}

const serviceBandsAdapter = createEntityAdapter<ServiceBand>()

const initialState: ServiceBandState = {
  serviceBands: serviceBandsAdapter.getInitialState(),
  serviceBandDetails: undefined,
}

export const serviceBandSlice = createSlice({
  name: 'serviceBand',
  initialState,
  reducers: {
    updateServiceBandDetails(state, action: PayloadAction<ServiceBand>) {
      state.serviceBandDetails = action.payload
      serviceBandsAdapter.upsertOne(state.serviceBands, action.payload)
    },
  },
  extraReducers: builder => {
    builder.addCase(getServiceBands.fulfilled, (state, action) => {
      serviceBandsAdapter.setAll(state.serviceBands, action.payload)
    })
    builder.addCase(createServiceBand.fulfilled, (state, action) => {
      serviceBandsAdapter.upsertOne(state.serviceBands, action.payload)
    })
    builder.addCase(updateServiceBand.fulfilled, (state, action) => {
      state.serviceBandDetails = action.payload
      serviceBandsAdapter.upsertOne(state.serviceBands, action.payload)
    })
    builder.addCase(getServiceBand.fulfilled, (state, action) => {
      state.serviceBandDetails = action.payload
    })
    builder.addCase(deleteServiceBand.fulfilled, (state, action) => {
      serviceBandsAdapter.removeOne(state.serviceBands, action.meta.arg.id)
    })
  },
})
export const { updateServiceBandDetails } = serviceBandSlice.actions
export const { selectAll: serviceBandsSelector } = serviceBandsAdapter.getSelectors(
  (state: RootState) => state.serviceBandState.serviceBands,
)
export const serviceBandDetailsSelector = (state: RootState) => state.serviceBandState.serviceBandDetails

export default serviceBandSlice.reducer
