import * as React from 'react'
import ModalBody from 'reactstrap/lib/ModalBody'
import { FormProvider, useForm } from 'react-hook-form'
import { ReceptionBookingDetails, ReceptionReminder } from '@models/reception'
import { ReceptionBookingCheck } from '@modules/reception/common/reception-booking-check'
import { newUserPredicator, ReceptionCheckReminders } from '@modules/reception/common/reception-check-reminders'
import { ReceptionBookingCheckInStepImprovementsList } from '@modules/reception/checkin/step-improvements/reception-booking-check-in-step-improvements-list'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'

const reminders: ReceptionReminder[] = [
  {
    id: 'check_additional_improvements_and_feeding',
    isClosable: true,
    children: (
      <>
        Zaproponuj wykup
        <strong> dodatkowych ulepszeń.</strong>
      </>
    ),
  },
  {
    id: 'check_vip',
    isClosable: true,
    children: (
      <>
        Zaproponuj wykup
        <strong> pakietu VIP</strong>
      </>
    ),
  },
]

interface ReceptionBookingCheckInStepImprovementsProps {
  booking: ReceptionBookingDetails
  previousStep: () => void
  nextStep: () => void
}

export const ReceptionBookingCheckInStepImprovements: React.FC<ReceptionBookingCheckInStepImprovementsProps> = ({
  booking,
  nextStep,
  previousStep,
}) => {
  const user = useAuthenticatedUser()
  const methods = useForm({
    defaultValues: {
      check_additional_improvements_and_feeding: newUserPredicator(user),
      check_vip: newUserPredicator(user),
    },
  })

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(nextStep)}>
        <ModalBody>
          <h4>4. Wybór ulepszeń</h4>
          <ReceptionCheckReminders
            required={!newUserPredicator(user)}
            reminders={reminders.filter(row => (booking.has_package_vip ? row.id !== 'check_vip' : true))}
          />
          <ReceptionBookingCheckInStepImprovementsList booking={booking} />
        </ModalBody>
        <ReceptionBookingCheck nextStep={nextStep} previousStep={previousStep} />
      </form>
    </FormProvider>
  )
}
