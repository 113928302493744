import * as React from 'react'
import { TransferPackage } from '@modules/accounting/refund/models'

interface Props {
  transferPackage: TransferPackage
}

export const TransferPackagesTableTableCellActionsExport: React.FC<Props> = ({ transferPackage }) => (
  <a href={transferPackage.urls.export} title="Eksportuj" className="mr-2">
    <span className="uil-export text-muted font-16 uil" />
  </a>
)
