import * as React from 'react'
import Table from '@components/table/table'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { CleaningOrderBooking } from '@modules/housekeeping/models'

interface Props {
  nextBooking: CleaningOrderBooking | null
}

export const TechnicalOrdersTodoRowNextBooking: React.FC<Props> = ({ nextBooking }) => (
  <Table.Cell className="vertical-align-middle" style={{ width: 150 }}>
    {nextBooking && (
      <>
        {toDefaultDateFormat(nextBooking.date_from)}
        <span className="font-10 d-block">({nextBooking.reservation_number})</span>
      </>
    )}
  </Table.Cell>
)
