import * as React from 'react'
import { Card, CardBody, CardHeader } from 'reactstrap'
import { FloorHeatingDetailsHistoryChartBox } from '@modules/floor-heating/details/history/history-chart-box'
import { FloorHeatingDetailsHistoryTable } from '@modules/floor-heating/details/history/history-table'

interface Props {
  apartmentId: string | undefined
}

export const FloorHeatingDetailsHistory = ({ apartmentId }: Props): JSX.Element => (
  <Card>
    <CardHeader className="fw-semi-bold font-18">Historia</CardHeader>
    <CardBody>
      <FloorHeatingDetailsHistoryChartBox apartmentId={apartmentId} />
      <FloorHeatingDetailsHistoryTable apartmentId={apartmentId} />
    </CardBody>
  </Card>
)
