import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { formatPriceShort } from '@helpers/utils'
import classNames from 'classnames'

interface Props {
  booking: ReceptionBookingDetails
  defaultIsOpen: boolean
  readOnly?: boolean
}

const ReceptionBookingCheckOutBillDetailsImprovements: React.FC<Props> = ({ booking, defaultIsOpen, readOnly }) => {
  const [isOpen, setIsOpen] = React.useState(false)

  React.useEffect(() => {
    setIsOpen(defaultIsOpen)
  }, [defaultIsOpen])

  const handleOpen = () => setIsOpen(!isOpen)

  return (
    <>
      <tr className="reception__booking-check-out__bill-step__table__row">
        <td className="reception__booking-check-out__bill-step__table__column text-grey text-semi-strong">
          Ulepszenia
        </td>
        <td className="reception__booking-check-out__bill-step__table__column text-right reception__booking-check-out__bill-step__table__column--is-costs">
          {formatPriceShort(booking.improvements_brutto)}
        </td>
        <td className="reception__booking-check-out__bill-step__table__column" />
        {!readOnly && (
          <td className="reception__booking-check-out__bill-step__table__column text-right p-0">
            {!!booking.improvements.length && (
              <i
                onClick={handleOpen}
                className={classNames('font-18 cursor-pointer', {
                  'uil-angle-right': !isOpen,
                  'uil-angle-down': isOpen,
                })}
              />
            )}
          </td>
        )}
      </tr>

      {isOpen && (
        <>
          {booking.improvements.map(bookingImprovement => (
            <tr
              key={bookingImprovement.id}
              className="reception__booking-check-out__bill-step__table__row reception__booking-check-out__bill-step__table__row--is-sub"
            >
              <td className="reception__booking-check-out__bill-step__table__column text-gray reception__booking-check-out__bill-step__table__column--is-sub">
                {bookingImprovement.name}
              </td>
              <td className="reception__booking-check-out__bill-step__table__column text-right text-gray">
                {formatPriceShort(bookingImprovement.price_brutto)}
              </td>
              <td className="reception__booking-check-out__bill-step__table__column" />
              {!readOnly && <td className="reception__booking-check-out__bill-step__table__column" />}
            </tr>
          ))}
        </>
      )}
    </>
  )
}

export default ReceptionBookingCheckOutBillDetailsImprovements
