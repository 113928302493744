import * as React from 'react'
import { PageTitle } from '@hyper/page-title'
import { useDocumentTitle } from '@helpers/utils'
import { Card, CardBody } from 'reactstrap'
import { useAppDispatch, useAppSelector } from '@store/index'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useDebounce } from 'rooks'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { getRentalWarehouses } from '@store/actions/rental-warehouse-actions'
import { RentalWarehousesFiltersParams } from '@modules/rental/models'
import { RentalWarehousesTable } from '@modules/rental/warehouses/rental-warehouses-table'
import { selectRentalWarehouses } from '@store/slices/rental-slice'
import { RentalWarehousesFilters } from '@modules/rental/warehouses/rental-warehouses-filters'

const pageTitle = 'Lista magazynów'

const defaultFilters: RentalWarehousesFiltersParams = {
  ordering: '-created',
  page: 1,
  page_size: 10,
  search: '',
  resort: null,
}

export const RentalWarehousesView: React.FC = () => {
  const [filters, setFilters] = React.useState<RentalWarehousesFiltersParams>(defaultFilters)

  const dispatch = useAppDispatch()
  useDocumentTitle(pageTitle)
  useMenuActive(NavigationPath.RentalWarehouses)

  const warehouses = useAppSelector(selectRentalWarehouses)

  const { isLoading, action: fetchWarehouses } = useApiRequest(
    async filters => await dispatch(getRentalWarehouses(filters)),
  )

  const fetchRentalWarehouses = React.useCallback(useDebounce(fetchWarehouses, 300), [])

  React.useEffect(() => {
    fetchRentalWarehouses(filters)
  }, [filters])

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitle} />
      <Card>
        <CardBody>
          <RentalWarehousesFilters filters={filters} setFilters={setFilters} defaultFilters={defaultFilters} />
          <RentalWarehousesTable
            isLoading={isLoading}
            filters={filters}
            setFilters={setFilters}
            warehouses={warehouses}
          />
        </CardBody>
      </Card>
    </>
  )
}
