import * as React from 'react'
import { BenefitProgram } from '@modules/benefit-program/models'
import Table from '@components/table/table'
import { formatPriceShort } from '@helpers/utils'
import { getBenefitProgramTotal } from '@modules/benefit-program/helpers'

interface Props {
  benefitProgram: BenefitProgram
}

export const BenefitProgramTableRowTotal: React.FC<Props> = ({ benefitProgram }) => {
  const total = React.useMemo(() => getBenefitProgramTotal(benefitProgram), [benefitProgram])

  return (
    <Table.Cell className="vertical-align-middle">
      {formatPriceShort(total)}
      <span className="d-block font-10">miesięcznie</span>
    </Table.Cell>
  )
}
