import { createAsyncThunk } from '@reduxjs/toolkit'
import { AsyncThunkParams } from '@store/index'
import { PaginationResponse } from '@models/dashboard'
import { setDashboardStandardPaginator } from '@store/actions/dashboard-actions'
import {
  commonObjectDelete,
  commonObjectGet,
  commonObjectPatch,
  commonObjectPost,
  wrapWithError,
} from '@store/actions/generic-actions'
import {
  RentalAppData,
  RentalProduct,
  RentalProductCategory,
  RentalProductDetails,
  RentalProductFiltersParams,
  RentalWarehouse,
  RentalWarehousesFiltersParams,
} from '@modules/rental/models'
import { RentalProductFormInputs } from '@modules/rental/products/modals/rental-products-create-modal'
import { RentalWarehouseFormInputs } from '@modules/rental/warehouses/modals/rental-warehouse-create-modal'
import { TableFilters } from '@components/table/table'
import { RentalProductCategoryFormInputs } from '@modules/rental/product-categories/modals/rental-product-category-create-modal'

const getFormattedPayload = payload => ({
  ...payload,
  category: payload.category?.value ?? null,
  status: payload.status?.value ?? null,
  warehouse: payload.warehouse?.value ?? null,
})

export const getRentalAppData = createAsyncThunk<RentalAppData, void, AsyncThunkParams>(
  'rental/getRentalWarehouseAppData',
  async (_, { getState }) => {
    const appData = getState().rentalState.appData
    if (appData.status === 'unknown') {
      return await commonObjectGet<RentalAppData>(getState().appState.appData.urls.rental.app_data)
    }
    return appData
  },
)

export const getRentalProducts = createAsyncThunk<
  RentalProduct[],
  Partial<RentalProductFiltersParams>,
  AsyncThunkParams
>('rental/getRentalProducts', async (filters, { getState, dispatch }) => {
  const url = getState().appState.appData.urls.rental.products

  const data = await commonObjectGet<PaginationResponse<RentalProduct>>(url, getFormattedPayload(filters))
  dispatch(setDashboardStandardPaginator({ ...data }))
  return data.results
})

export const createRentalProduct = createAsyncThunk<RentalProductDetails, [string, RentalProductFormInputs]>(
  'rental/createRentalProduct',
  async ([url, payload]) =>
    await wrapWithError(commonObjectPost<RentalProductDetails>(url, getFormattedPayload(payload))),
)

export const updateRentalProduct = createAsyncThunk<RentalProduct, [string, Partial<RentalProductFormInputs>]>(
  'rental/updateRentalProduct',
  async ([url, payload]) => await wrapWithError(commonObjectPatch<RentalProduct>(url, getFormattedPayload(payload))),
)

export const removeRentalProduct = createAsyncThunk<RentalProduct, RentalProduct>(
  'rental/removeRentalProduct',
  async (product: RentalProduct) => {
    await wrapWithError(commonObjectDelete(product.urls.details))
    return product
  },
)

export const getRentalWarehouses = createAsyncThunk<
  RentalWarehouse[],
  Partial<RentalWarehousesFiltersParams>,
  AsyncThunkParams
>('rental/getRentalWarehouses', async (filters, { getState, dispatch }) => {
  const url = getState().appState.appData.urls.rental.warehouses

  const data = await commonObjectGet<PaginationResponse<RentalWarehouse>>(url, { resort: filters.resort?.value })
  dispatch(setDashboardStandardPaginator({ ...data }))
  return data.results
})

export const createRentalWarehouse = createAsyncThunk<RentalWarehouse, [string, RentalWarehouseFormInputs]>(
  'rental/createRentalWarehouse',
  async ([url, payload]) =>
    await wrapWithError(commonObjectPost<RentalWarehouse>(url, { name: payload.name, resort: payload.resort?.value })),
)

export const updateRentalWarehouse = createAsyncThunk<RentalWarehouse, [string, Partial<RentalWarehouseFormInputs>]>(
  'rental/updateRentalWarehouse',
  async ([url, payload]) =>
    await wrapWithError(commonObjectPatch<RentalWarehouse>(url, { name: payload.name, resort: payload.resort?.value })),
)

export const removeRentalWarehouse = createAsyncThunk<RentalWarehouse, RentalWarehouse>(
  'rental/removeRentalWarehouse',
  async (warehouse: RentalWarehouse) => {
    await wrapWithError(commonObjectDelete(warehouse.urls.details))
    return warehouse
  },
)

export const getRentalProductCategories = createAsyncThunk<
  RentalProductCategory[],
  Partial<TableFilters>,
  AsyncThunkParams
>('rental/getRentalProductCategories', async (filters, { getState, dispatch }) => {
  const url = getState().appState.appData.urls.rental.product_categories

  const data = await commonObjectGet<PaginationResponse<RentalProductCategory>>(url, filters)
  dispatch(setDashboardStandardPaginator({ ...data }))
  return data.results
})

export const createRentalProductCategory = createAsyncThunk<
  RentalProductCategory,
  [string, RentalProductCategoryFormInputs]
>(
  'rental/createRentalProductCategory',
  async ([url, payload]) => await wrapWithError(commonObjectPost<RentalProductCategory>(url, payload)),
)

export const updateRentalProductCategory = createAsyncThunk<
  RentalProductCategory,
  [string, Partial<RentalProductCategoryFormInputs>]
>(
  'rental/updateRentalProductCategory',
  async ([url, payload]) => await wrapWithError(commonObjectPatch<RentalProductCategory>(url, payload)),
)

export const removeRentalProductCategory = createAsyncThunk<RentalProductCategory, RentalProductCategory>(
  'rental/removeRentalProductCategory',
  async (productCategory: RentalProductCategory) => {
    await wrapWithError(commonObjectDelete(productCategory.urls.details))
    return productCategory
  },
)
