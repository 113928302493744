import * as React from 'react'
import { Button, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { SaveButton } from '@hyper/button'
import { useAppDispatch } from '@store/index'
import { useForm } from 'react-hook-form'
import { createReportTask } from '@store/actions/report-tasks-actions'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { packageStatusList } from '@models/package'
import { useGroupedSellers } from '@components/hooks/use-grouped-sellers'
import { PackageListFilterParams } from '@modules/package/list/filter'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { BaseModalProps } from '@components/modals/types'
import { endOfMonth, startOfMonth, startOfToday } from 'date-fns'
import { Form } from '@hyper/forms/form'
import { FormSelect } from '@hyper/forms/form-select'

interface Props extends BaseModalProps {
  url: string
  title: string
  filters?: PackageListFilterParams
}

interface FormInputs {
  created_after: Date | null
  created_before: Date | null
  search: string
  seller: CustomReactSelectOption
  status: CustomReactSelectOption[]
}

export const ReportsGeneratorPackagesModal: React.FC<Props> = ({ toggleIsVisible, title, url, filters }) => {
  const dispatch = useAppDispatch()

  const methods = useForm<FormInputs>({
    mode: 'all',
    defaultValues: {
      created_after: startOfMonth(startOfToday()),
      created_before: endOfMonth(startOfToday()),
      ...filters,
    },
  })

  const { isLoading, action: onSubmit } = useReportFormRequest(async (payload: FormInputs) => {
    const reportTask = await dispatch(
      createReportTask([
        url,
        {
          ...payload,
          status: payload.status?.map(row => row.value),
          seller: payload.seller?.value,
        },
      ]),
    )
    toggleIsVisible()

    return reportTask
  }, methods.setError)

  const sellers = useGroupedSellers()

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>{title}</ModalHeader>
      <ModalBody>
        <Row>
          <FormSelect
            options={packageStatusList}
            name="status"
            label="Status"
            selectProps={{ isSelectMulti: true }}
            formPlainProps={{ colStyle: { width: 330 } }}
          />

          <FormSelect
            options={sellers}
            name="seller"
            label="Sprzedawca"
            formPlainProps={{ colStyle: { width: 200 } }}
          />

          <DateFromToInput label="Okres" startDateName="created_after" endDateName="created_before" />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" label="Zleć raport" labelSaving="Zlecanie.." isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
