import * as R from 'ramda'
import * as React from 'react'

export function useSortedRows<T>(rows: T[], sortedField: string): T[] {
  const sorter = React.useCallback(
    (elements: T[]) => {
      const method = sortedField.indexOf('-') === 0 ? R.ascend : R.descend
      return R.sortWith<T>([method(R.prop(sortedField.replace('-', '')))])(elements)
    },
    [sortedField],
  )

  return React.useMemo(() => sorter(rows), [sorter, rows])
}
