import { useBenefitProgramAppData } from '@modules/benefit-program/hooks/use-benefit-program-app-data'
import * as React from 'react'
import { createSelectOption } from '@helpers/utils'

export const useBenefitProgramAdvisorsOptions = () => {
  const { benefit_program_advisors } = useBenefitProgramAppData()
  return React.useMemo(
    () =>
      benefit_program_advisors.map(advisor =>
        createSelectOption(`${advisor.first_name} ${advisor.last_name}`, advisor.id),
      ),
    [benefit_program_advisors],
  )
}
