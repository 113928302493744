import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { ContentLoader } from '@components/content-loader'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { SortOrders, useSortedTable } from '@components/table/use-sorted-table'
import classNames from 'classnames'
import { TablePagination } from '@components/table/table-pagination'

const tablePageSizeOptions = [10, 25, 30, 50, 100].map(amount => ({ value: amount, label: amount }))

export interface PaginationFilters {
  page_size: number
  page: number
  skip_paginator?: boolean
}

interface FormInputs {
  pageSize: CustomReactSelectOption
}

export interface TableFilters extends PaginationFilters {
  ordering: string
  search: string | string[]
}

interface Table {
  dataTestId?: string
  className?: string
  cols?: string[]
  striped?: boolean
  headerValues: TableHeaderValue<any>[]
  headersRenderer?: (headerValue: TableHeaderValue<any>) => React.ReactNode
  headerClassName?: string
  selectableHeader?: React.ReactNode
  footer?: React.ReactNode
  noDataIndicator?: React.ReactElement | string
  isLoading?: boolean
  filters: TableFilters
  setFilters: (filters) => void
  children: React.ReactNode
  scrollable?: boolean
  showPagination?: boolean
  emptyText?: string
  tableWrapperClassName?: string
  maxHeight?: string
}

const Table = ({
  cols,
  headerValues,
  headersRenderer,
  headerClassName,
  selectableHeader,
  footer,
  className,
  noDataIndicator,
  isLoading = false,
  filters,
  setFilters,
  children,
  scrollable,
  emptyText = 'Brak danych',
  tableWrapperClassName,
  showPagination = true,
  striped = true,
  maxHeight,
  dataTestId,
}: Table) => {
  const methods = useForm<FormInputs>({
    defaultValues: { pageSize: tablePageSizeOptions.find(row => row.value === filters.page_size) },
  })
  const { handleSort, getSortingOrder } = useSortedTable(setFilters, filters)

  const colgroups = cols?.map((col, index) => <col key={index} width={col} />)

  return (
    <FormProvider {...methods}>
      <ContentLoader isLoading={isLoading}>
        <div
          className={classNames(
            'table__wrapper',
            {
              'table__wrapper--scrollable mb-3': scrollable,
              'table__wrapper--scrollable-y': maxHeight,
              'pb-3': !maxHeight && tableWrapperClassName !== 'pb-0',
            },
            tableWrapperClassName,
          )}
          style={maxHeight ? { maxHeight } : undefined}
        >
          <table
            className={classNames('table', className, {
              'table-striped': striped,
            })}
            data-testid={dataTestId}
          >
            {!!colgroups && <colgroup>{colgroups}</colgroup>}
            <thead>
              <tr>
                {selectableHeader}
                {headerValues.map((el: TableHeaderValue, index) => (
                  <Table.Header
                    key={index}
                    className={classNames('thead-light', headerClassName, el.className)}
                    sortField={el.sortField}
                    sorted={getSortingOrder(el.sortField)}
                    onSort={handleSort}
                    width={el.width}
                  >
                    {headersRenderer?.(el) || el.title}
                  </Table.Header>
                ))}
              </tr>
            </thead>
            <tbody>
              {(!!React.Children.toArray(children).filter(el => el).length && children) || noDataIndicator || (
                <tr>
                  <td className="text-center" colSpan={100}>
                    {emptyText}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {footer}
        </div>
      </ContentLoader>
      {showPagination && <TablePagination filters={filters} onSetFilters={setFilters} />}
    </FormProvider>
  )
}

export interface TableHeaderValue<T = void> {
  title: string | JSX.Element
  search?: boolean
  sortField?: keyof T | string
  className?: string
  width?: string | number
}

export interface TableHeader extends React.HTMLAttributes<HTMLTableHeaderCellElement> {
  sortField?: string
  onSort?: (field: string) => void
  sorted?: SortOrders
  width?: string | number
}

Table.Header = ({ sortField, onSort, sorted, width, ...props }: TableHeader) => {
  const handleSort = () => {
    if (!sortField || !onSort) return
    onSort(sortField)
  }

  return (
    <th className={classNames('table__header', props.className)} style={{ width }}>
      <div className={classNames('d-flex align-items-center', { 'cursor-pointer': sortField })} onClick={handleSort}>
        {props.children}
        {sortField && (
          <div className="position-relative">
            <i
              role="sort-desc"
              className={classNames('uil-angle-up font-20 table__sort-indicator table__sort-indicator__desc', {
                'is-active': sorted === SortOrders.DESC,
              })}
            />
            <i
              role="sort-asc"
              className={classNames('uil-angle-down font-20 table__sort-indicator table__sort-indicator__asc', {
                'is-active': sorted === SortOrders.ASC,
              })}
            />
          </div>
        )}
      </div>
    </th>
  )
}

Table.Row = React.forwardRef(
  (props: React.TdHTMLAttributes<HTMLTableRowElement>, ref: React.Ref<HTMLTableRowElement>) => (
    <tr {...props} ref={ref}>
      {props.children}
    </tr>
  ),
)
Table.Cell = (props: React.TdHTMLAttributes<HTMLTableDataCellElement>) => <td {...props}>{props.children}</td>

export default Table
