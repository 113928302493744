import * as React from 'react'
import { ReservationRoomBillTransactionsListModalRow } from '@modules/reservations/details/reservation-room-bill/modals/reservation-room-bill-transactions-list-modal-row'
import { ReceptionBookingDetails, WalletTransactionBill, WalletTransactionBillItem } from '@models/reception'
import { useExpandableItems } from '@components/hooks/use-expandable-items'
import { Collapse } from 'reactstrap'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import classnames from 'classnames'
import { CopyToClipboard } from '@components/copy-to-clipboard'
import { useSelectableRows } from '@components/table/use-selectable-rows'
import { ReservationRoomBillTransactionsListModalMultiActions } from '@modules/reservations/details/reservation-room-bill/modals/reservation-room-bill-transactions-list-modal-mutli-actions'

interface Props {
  bill: WalletTransactionBill
  expandableRowClassName?: string
  booking: ReceptionBookingDetails
}

export const ReservationRoomBillTransactionsListTable: React.FC<Props> = ({
  booking,
  bill,
  expandableRowClassName,
}) => {
  const { toggleExpanded, ExpandArrow, isExpanded } = useExpandableItems()

  const { selectableHeader, selectableCell, selectedRows, clearSelectedRows } =
    useSelectableRows<WalletTransactionBillItem>(bill.items)

  return (
    <>
      <div className={classnames('d-flex justify-content-between align-items-center p-2', expandableRowClassName)}>
        <p className="mb-1 fw-semi-bold">
          {bill.source_display} {toDefaultDateTimeFormat(bill.created_at)} <br />
          <span className="font-12">
            {bill.guest_name} {bill.bill_id}
            <CopyToClipboard className="ml-1" value={bill.bill_id} />
          </span>
        </p>
        <ExpandArrow onExpand={toggleExpanded(bill.id)} isExpanded={isExpanded(bill.id)} />
      </div>

      <Collapse isOpen={isExpanded(bill.id)}>
        <ReservationRoomBillTransactionsListModalMultiActions
          selectedRows={selectedRows}
          removeUrl={booking.urls.wallet_transactions}
          clearSelectedRows={clearSelectedRows}
        />
        <table className="table w-100 table-striped mb-0" key={bill.id}>
          <thead>
            <tr>
              {selectableHeader}
              <TableHeadCell name="Nazwa" />
              <TableHeadCell name="Data sprzedaży" />
              <TableHeadCell name="Opłacone" />
              <TableHeadCell name="Opłacone przez porfel" />
              <TableHeadCell name="VAT" />
              <TableHeadCell name="Ilość" />
              <TableHeadCell name="Cena" />
              <TableHeadCell />
            </tr>
          </thead>
          <tbody>
            {bill.items.map(billItem => (
              <ReservationRoomBillTransactionsListModalRow
                key={billItem.id}
                item={billItem}
                selectableCell={selectableCell}
              />
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={8} />
            </tr>
          </tfoot>
        </table>
      </Collapse>
    </>
  )
}

const TableHeadCell = ({ name }: { name?: React.ReactNode }) => (
  <th className="font-weight-normal border-0" style={{ background: '#f1f3fa' }}>
    {name}
  </th>
)
