import * as React from 'react'
import { FormPlain } from '@hyper/forms'
import { Col, CustomInput, Label } from 'reactstrap'
import DatePickerInput from '@components/date/date-picker-input'
import { useFormContext } from 'react-hook-form'
import { extractInnerRef } from '@helpers/forms'
import { startOfToday, add, endOfToday } from 'date-fns'

interface Props {
  canOmitSage?: boolean
}

export const ReservationPaymentInstalment = ({ canOmitSage }: Props): JSX.Element => {
  const { register } = useFormContext()
  const minDate = add(startOfToday(), { days: -2 })

  return (
    <>
      <FormPlain colSize={6} name="payment_date">
        <Label>Data wpłaty</Label>
        <DatePickerInput name="invoice_date" wrapperClassName="flex-grow-1" minDate={minDate} maxDate={endOfToday()} />
      </FormPlain>
      {canOmitSage && (
        <Col md={6} style={{ marginTop: 32 }}>
          <CustomInput
            type="checkbox"
            id="symphony-omit"
            label="Pomiń symfonię"
            {...extractInnerRef(register('skip_sage'))}
          />
        </Col>
      )}
    </>
  )
}
