import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { IconWithText } from '@components/icon-with-text'
import { ReservationBillExpandedMediaRow } from '@modules/reservations/details/bill/bill-media/reservation-bill-expanded-media-row'

interface Props {
  booking: ReceptionBookingDetails
}

export const ReservationBillExpandedMedia = ({ booking }: Props): JSX.Element | null => {
  if (!booking.service_room_data) return null

  return (
    <table className="table table-sm col-10 ml-auto mb-0 font-12 table-striped">
      <tbody>
        <ReservationBillExpandedMediaRow
          title={<IconWithText icon="text-primary uil-tear font-15" text="licznik wody" textClass="font-weight-bold" />}
          unit="m³"
          startValue={booking.service_room_data.water_start}
          endValue={booking.service_room_data.water_end}
          currentUse={booking.service_room_data.water_total_current}
          endUse={booking.service_room_data.water_total_current}
          price={booking.media_water_brutto}
        />
        <ReservationBillExpandedMediaRow
          title={
            <IconWithText
              icon="text-danger uil-raindrops font-15"
              text="licznik wody ciepłej"
              textClass="font-weight-bold"
            />
          }
          unit="m³"
          startValue={booking.service_room_data.hot_water_start}
          endValue={booking.service_room_data.hot_water_end}
          currentUse={booking.service_room_data.hot_water_total_current}
          endUse={booking.service_room_data.hot_water_total_current}
          price={booking.media_hot_water_brutto}
        />
        <ReservationBillExpandedMediaRow
          title={
            <IconWithText icon="text-warning uil-bolt-alt font-15" text="licznik prądu" textClass="font-weight-bold" />
          }
          unit="kWh"
          startValue={booking.service_room_data.power_start}
          endValue={booking.service_room_data.power_end}
          currentUse={booking.service_room_data.power_total_current}
          endUse={booking.service_room_data.power_end}
          price={booking.media_power_brutto}
        />
        <ReservationBillExpandedMediaRow
          title={
            <IconWithText
              icon="text-danger opacity-5 uil-bolt-alt font-15"
              text="licznik ogrzewania"
              textClass="font-weight-bold"
            />
          }
          unit="GJ"
          startValue={booking.service_room_data.heat_start}
          endValue={booking.service_room_data.heat_end}
          currentUse={booking.service_room_data.heat_costs_current}
          endUse={booking.service_room_data.heat_costs_current}
          price={booking.media_heat_brutto}
        />
      </tbody>
    </table>
  )
}
