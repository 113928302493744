import * as React from 'react'
import { Button, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { SaveButton } from '@hyper/button'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useForm } from 'react-hook-form'
import { createReportTask } from '@store/actions/report-tasks-actions'
import { endOfMonth, startOfMonth, startOfToday } from 'date-fns'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { FormInput } from '@hyper/forms'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { BaseModalProps } from '@components/modals/types'
import { Form } from '@hyper/forms/form'

interface FormInputs {
  date_to_after: Date | null
  date_to_before: Date | null
  resorts: number | ''
}

export const ReportsGeneratorFeedingSpecialGuest: React.FC<BaseModalProps> = ({ toggleIsVisible }) => {
  const reportUrl = useAppSelector(
    (state: RootState) => state.appState.appData.urls.reports.report_task_feeding_special_guest,
  )
  const dispatch = useAppDispatch()
  const user = useAuthenticatedUser()

  const methods = useForm<FormInputs>({
    mode: 'all',
    defaultValues: {
      resorts: '',
      date_to_after: startOfMonth(startOfToday()),
      date_to_before: endOfMonth(startOfToday()),
    },
  })

  const { isLoading, action: onSubmit } = useReportFormRequest(async (payload: FormInputs) => {
    const reportTask = await dispatch(createReportTask([reportUrl, payload]))
    toggleIsVisible()
    return reportTask
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>
        <span className="text-semi-string">Raport wyżywienia</span> <strong>goście specjalni</strong>
      </ModalHeader>
      <ModalBody>
        <Row>
          <FormInput label="Ośrodek:" type="select" name="resorts">
            <option value="">Wszystkie</option>
            {user.resorts.map(resort => (
              <option value={resort.id} key={resort.id}>
                {resort.name}
              </option>
            ))}
          </FormInput>

          <DateFromToInput label="Data do" startDateName="date_to_after" endDateName="date_to_before" />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" label="Zleć raport" labelSaving="Zlecanie.." isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
