import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { PaymentReport } from '@modules/reports/models'
import {
  getHouseKeepingUsersReport,
  getPaymentsReport,
  getReportsAppData,
  getStatsSaleItems,
} from '@store/actions/report-actions'
import { RootState } from '@store/index'
import { HouseKeepingUserReport } from '@modules/reports/housekeeping-users-report/models'
import { ReportsAppData, StatsSaleItem } from '@models/reports'

interface ReportState {
  statsStaleItems: EntityState<StatsSaleItem>
  paymentsReport: EntityState<PaymentReport>
  houseKeepingUsersReport: HouseKeepingUserReport
  appData: ReportsAppData
}

const paymentReportsAdapter = createEntityAdapter<PaymentReport>()
const statsStaleItemsReportAdapter = createEntityAdapter<StatsSaleItem>()

const initialState: ReportState = {
  paymentsReport: paymentReportsAdapter.getInitialState(),
  houseKeepingUsersReport: {
    results: [],
    aggregation: null,
  },
  statsStaleItems: statsStaleItemsReportAdapter.getInitialState(),
  appData: {
    sources: [],
    status: 'unknown',
    stats_sale_item_categories: [],
    housekeeping_users: [],
    promotion_marketing: [],
  },
}

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setReportsAppData(state, action: PayloadAction<ReportsAppData>) {
      state.appData = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getPaymentsReport.fulfilled, (state, action) => {
      paymentReportsAdapter.setAll(state.paymentsReport, action.payload)
    })
    builder.addCase(getHouseKeepingUsersReport.fulfilled, (state, action) => {
      state.houseKeepingUsersReport = action.payload
    })
    builder.addCase(getStatsSaleItems.fulfilled, (state, action) => {
      statsStaleItemsReportAdapter.setAll(state.statsStaleItems, action.payload)
    })
    builder.addCase(getReportsAppData.fulfilled, (state, action) => {
      state.appData = { ...action.payload, status: 'ready' }
    })
  },
})

export const { selectAll: paymentsReportSelector } = paymentReportsAdapter.getSelectors(
  (state: RootState) => state.reportsState.paymentsReport,
)

export const { selectAll: statsStaleItemsSelector } = statsStaleItemsReportAdapter.getSelectors(
  (state: RootState) => state.reportsState.statsStaleItems,
)

export const { setReportsAppData } = reportsSlice.actions
export default reportsSlice.reducer
