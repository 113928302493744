import { createSelector } from 'reselect'
import { RootState } from '@store/index'
import { AccommodationType, Resort } from '@models/booking'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { DashboardTag } from '@models/dashboard'

export type ResortsMap = {
  [key: number]: Resort
}

export type AccommodationTypeMap = {
  [key: number]: AccommodationType
}

export const accommodationTypesMapSelector = createSelector(
  (state: RootState) => state.appState.appData.accommodation_types,
  (accommodation_types): AccommodationTypeMap =>
    accommodation_types.reduce((curr, val) => ({ ...curr, [val.id]: val }), {}),
)
export const resortsSelector = (state: RootState) => state.appState.appData.resorts
export const resortOptionsSelector = createSelector(resortsSelector, (resorts): CustomReactSelectOption[] =>
  resorts.map(row => ({ label: row.name, value: row.id })),
)

export const resortsMapSelector = createSelector(
  resortsSelector,
  (resorts): ResortsMap => resorts.reduce((curr, val) => ({ ...curr, [val.id]: val }), {}),
)

export const tagsOptionsSelector = createSelector(
  (state: RootState) => state.appState.appData.tags,
  (tags: DashboardTag[]): CustomReactSelectOption[] => [
    ...(tags || [])
      .filter(tag => tag.name)
      .map(tag => ({
        label: tag.name,
        value: tag.id,
      })),
  ],
)

export const totalSizeSelector = createSelector(
  (state: RootState) => state.dashboardState.standardPaginator,
  state => state?.count || 0,
)

export const websocketChannelsSelector = createSelector(
  (state: RootState) => state.webSocketState.channels,
  channels => channels,
)
