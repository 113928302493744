import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { ReservationDataStayDates } from '@modules/reservations/details/base-data/booking/reservation-data-stay-dates'
import { ReservationDataAccommodationType } from '@modules/reservations/details/base-data/booking/reservation-data-accommodation-type'
import { ReservationDataResort } from '@modules/reservations/details/base-data/booking/reservation-data-resort'
import { Button } from 'reactstrap'
import { ReceptionToCheckInRowImprovements } from '@modules/reception/to-checkin/row/reception-to-check-in-row-improvements'
import { useModal } from '@components/modals/use-modal'
import { ReceptionBookingDetailsTab } from '@modules/reception/details/reception-booking-details-tabs'
import classNames from 'classnames'
import { getUpsellBookingStatusColor } from '@modules/upselling/upsell-bookings/helpers'
import { UpSellBookingDetails } from '@models/upselling'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { useUpsellBookingDetailsRefresh } from '@modules/upselling/upsell-booking-details/use-upsellbooking-details-refresh'

interface Props {
  upsellBookingDetails: UpSellBookingDetails
}

export const UpsellBookingDetailsReservationBookingData = ({ upsellBookingDetails }: Props): JSX.Element => {
  const { refresh } = useUpsellBookingDetailsRefresh()

  const [showDetailsModal] = useModal('ReceptionBookingDetailsModal', {}, { onClose: refresh })

  const showDetails = (tabs: ReceptionBookingDetailsTab[]) => () => {
    showDetailsModal(null, { booking: upsellBookingDetails.booking, initialTab: tabs[0], tabs: tabs })
  }

  return (
    <div className="col-8 border-right">
      <IconWithText
        icon="uil-calender font-24"
        text="Dane rezerwacji"
        wrapperClassNames="text-secondary fw-semi-bold"
        textClass="font-18"
      />

      <div className="mt-3">
        <div className="row mb-3">
          <div className="col-5">
            <span>Numer rezerwacji:</span>
            <strong className="d-block font-18 mt-1">
              {upsellBookingDetails.booking.reservation_number}{' '}
              <i className="uil-eye ml-2" onClick={showDetails(['data', 'improvements', 'feedingNew'])} />
            </strong>
          </div>
          <div className="col-7">
            <span className="d-block">Lista ulepszeń:</span>
            <div className="d-flex align-items-center font-18 mt-1">
              <div className="mt-1 mr-1">
                <ReceptionToCheckInRowImprovements booking={upsellBookingDetails.booking} showLabel={false} />
              </div>
              <Button color="secondary" className="py-1 px-2" onClick={showDetails(['improvements', 'feedingNew'])}>
                <i className="uil-plus lh-1" />
              </Button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-5 d-flex flex-column">
            <IconWithText
              icon="uil-estate text-secondary me-2 font-16"
              text={`Lokal ${upsellBookingDetails.booking.apartment.name}`}
              textClass="font-weight-bold mr-1"
              wrapperClassNames="mb-1"
            />

            <ReservationDataAccommodationType
              accommodation_type_id={upsellBookingDetails.booking.accommodation_type_id}
            />

            <IconWithText
              icon="uil-analysis text-secondary me-2 font-16"
              text="Status zlecenia"
              textClass="font-weight-bold mr-1"
              wrapperClassNames="mt-3"
            />
          </div>

          <div className="col-7 d-flex flex-column">
            <ReservationDataResort resortId={1} />
            <ReservationDataStayDates booking={upsellBookingDetails.booking} />
            <div className="mt-3">
              <span
                className={classNames(
                  'badge px-1',
                  `badge-${getUpsellBookingStatusColor(upsellBookingDetails.status)}`,
                )}
              >
                {upsellBookingDetails.status_display}
              </span>
              <span className="font-11 pl-2">
                {upsellBookingDetails.status === 'contact_required_again' &&
                  upsellBookingDetails.contact_required_date &&
                  toDefaultDateFormat(upsellBookingDetails.contact_required_date)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
