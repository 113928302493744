import * as React from 'react'
import { useForm } from 'react-hook-form'
import { useHandleAxiosFormErrors } from '@helpers/handle-axios-errors'
import { resetPassword } from '@api/dashboard'
import { LoginUIComponent } from '@components/login-ui-component'
import { Row } from 'reactstrap'
import { FormInput } from '@hyper/forms'
import { SaveButton } from '@hyper/button'
import { useParams } from 'react-router-dom'
import { useApiRequest } from '@components/hooks/use-api-request'
import { Form } from '@hyper/forms/form'

interface FormInputs {
  new_password: string
  confirmed_password: string
}

export const AuthorizationResetPasswordView: React.FC = () => {
  const { token } = useParams<'token'>()

  const [isComplete, setIsComplete] = React.useState(false)
  const methods = useForm<FormInputs>()
  const handleAxiosFormErrors = useHandleAxiosFormErrors()

  const { isLoading, action: onSubmit } = useApiRequest(async (data: FormInputs) => {
    try {
      await resetPassword(token, data)
      setIsComplete(true)
    } catch (error) {
      handleAxiosFormErrors(error, methods.setError, {
        nonFieldErrorsAs: 'new_password',
        showGlobalError: false,
      })
    }
  })

  return (
    <LoginUIComponent>
      <h2 className="unauthorized__header">Resetowanie hasła </h2>
      {isComplete ? (
        <p>Hasło zostało zresetowane.</p>
      ) : (
        <Form methods={methods} onSubmit={onSubmit}>
          <p className="unauthorized__text">Wpisz nowe hasło.</p>
          <Row>
            <FormInput type="password" name="new_password" label="Nowe hasło:" placeholder="Nowe hasło" />
            <FormInput
              type="password"
              name="confirmed_password"
              label="Powtórz nowe hasło:"
              placeholder="Powtórz nowe hasło"
            />
          </Row>
          <SaveButton
            role="login"
            labelSaving="Resetowanie..."
            isSaving={isLoading}
            label="Resetuj hasło"
            type="submit"
            className="btn btn-secondary btn-block"
          />
        </Form>
      )}
    </LoginUIComponent>
  )
}
