import * as React from 'react'
import { TechnicalOrdersOrderedGroupedTableRow } from '@modules/technical-orders/technical-orders-ordered/row-grouped'
import { TechnicalOrderTableGrupped } from '@modules/technical-orders/common/table-grupped'
import { TechnicalOrder } from '@models/technical-orders'

interface Props {
  headers: React.ReactNode
  technicalOrders: TechnicalOrder[]
  resortId: number
  dataTestId?: string
}

export const TechnicalOrdersOrderedGroupedTable = ({
  dataTestId,
  headers,
  technicalOrders,
  resortId,
}: Props): JSX.Element => (
  <table className="table w-100" data-testid={dataTestId}>
    {headers}
    <tbody>
      <TechnicalOrderTableGrupped
        Row={TechnicalOrdersOrderedGroupedTableRow}
        technicalOrders={technicalOrders}
        resortId={resortId}
      />
    </tbody>
  </table>
)
