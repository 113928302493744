import { ClientProfileRule, ClientRule, ClientRuleKeyword } from '@models/clients'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectGet, commonObjectPatch } from '@store/actions/generic-actions'

interface UpdateRulePayload {
  [key: string]: boolean
}

interface UseRulesResponse {
  updateClientRules: (rules: UpdateRulePayload) => Promise<ClientProfileRule>
  fetchClientRules: () => Promise<ClientProfileRule>
  isFetchingClientRules: boolean
  isUpdatingClientRules: boolean
}

export const useClientRules = (url: string): UseRulesResponse => {
  const { action: fetchClientRules, isLoading: isFetchingClientRules } = useApiRequest(
    async () => await commonObjectGet<ClientProfileRule>(url),
  )

  const { action: updateClientRules, isLoading: isUpdatingClientRules } = useApiRequest(
    async rules => await commonObjectPatch<ClientProfileRule>(url, rules),
  )

  return {
    fetchClientRules,
    updateClientRules,
    isFetchingClientRules,
    isUpdatingClientRules,
  }
}

export const findClientRule = (rules: ClientRule[], keyword: ClientRuleKeyword) =>
  rules.find((rule: ClientRule) => rule.keyword === keyword)
