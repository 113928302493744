import * as React from 'react'
import { PromocodePrefix } from '@models/promocode'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { PromocodePrefixesTableRow } from '@modules/promocode/promocode-prefixes/promocode-prefixes-table-row'

interface PromocodePrefixesTableProps {
  filters: TableFilters
  isLoading: boolean
  setFilters: (filters: TableFilters) => void
  prefixes: PromocodePrefix[]
}

export const headerValues: TableHeaderValue[] = [
  {
    sortField: 'value',
    title: 'Prefiks',
  },
  {
    sortField: 'name',
    title: 'Nazwa',
  },
  {
    sortField: 'get_kind_display',
    title: 'Typ',
  },
  {
    sortField: 'promo_value',
    title: 'Wartość',
  },
  {
    sortField: 'codes_amount',
    title: 'Ilość kodów',
  },
  {
    title: '',
  },
]

export const PromocodePrefixesTable: React.FC<PromocodePrefixesTableProps> = ({
  isLoading,
  prefixes,
  filters,
  setFilters,
}) => (
  <Table
    className="table-hover w-100"
    headerValues={headerValues}
    isLoading={isLoading}
    filters={filters}
    setFilters={setFilters}
  >
    {prefixes.map(prefix => (
      <PromocodePrefixesTableRow key={prefix.id} prefix={prefix} />
    ))}
  </Table>
)
