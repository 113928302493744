import * as React from 'react'
import { format, parseISO } from 'date-fns'
import { BadgeWithIcon } from '@components/badges/BadgeWithIcon'
import { EventRowDetail } from '@modules/rcp/user/events/event-row-detail'
import { RcpEventDay, RcpMachine, RcpUserDetail } from '@models/rcp'
import { useToggle } from '@components/hooks/use-toggle'
import classNames from 'classnames'
import { secondsToInterval } from '@helpers/date-helper'

interface EventRowProps {
  user: RcpUserDetail
  index: number
  machines: RcpMachine[]
  eventDay: RcpEventDay
}

export const EventRow: React.FC<EventRowProps> = ({ eventDay, machines, index, user }) => {
  const formattedDay = format(parseISO(eventDay.date), 'dd.MM')
  const [isOpen, toggleIsOpen] = useToggle()

  return (
    <>
      <tr
        className={classNames('cursor-pointer', {
          'rcp__events-table__tr-primary-top': isOpen,
          odd: index % 2,
          even: !(index % 2),
          'text-danger has-errors rcp__events-table__has-errors': eventDay.errors,
        })}
        onClick={toggleIsOpen}
      >
        <td className={eventDay.errors ? 'rcp__events-table__has-errors__column-day' : ''}>{formattedDay}</td>
        <td>{eventDay.start_time}</td>
        <td>{eventDay.end_time}</td>
        <td>{eventDay.work_time && secondsToInterval(parseInt(eventDay.work_time, 10))}</td>
        <td>
          {eventDay.terminal_start &&
            machines.find((machine: RcpMachine) => machine.id === eventDay.terminal_start)?.name}
        </td>
        <td>
          {eventDay.terminal_end && machines.find((machine: RcpMachine) => machine.id === eventDay.terminal_end)?.name}
        </td>
        <td>{eventDay.errors && <BadgeWithIcon icon="uil-ban" title="Brak zdarzenia" variant="danger" />}</td>
        <td className="text-right text-semi-strong">
          <button className="btn-pure" title="Pokaż szczegóły">
            <i className={`mdi ${isOpen ? 'mdi-chevron-down' : 'mdi-chevron-right'} action-icon cursor-pointer`} />
          </button>
        </td>
      </tr>
      {isOpen && <EventRowDetail hasError={eventDay.errors} eventDay={eventDay} machines={machines} user={user} />}
    </>
  )
}
