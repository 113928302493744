import * as React from 'react'
import { Apartment } from '@models/apartment'
import { UncontrolledTooltip } from 'reactstrap'
import { toDefaultDateFormat } from '@helpers/date-helper'

interface Props {
  apartment: Apartment
}

export const TimelineSidebarApartmentLabel = ({ apartment }: Props): React.ReactNode => {
  const isSpecialLocal = apartment.tags.some(tag => tag.keyword === 'for_client_with_benefit')
  const isWithoutAnimals = apartment.no_animals
  const hasGarden = apartment.has_garden

  const isWithLabel = isSpecialLocal || isWithoutAnimals || hasGarden

  if (!isWithLabel) return null

  return (
    <>
      <div className="d-flex mr-1 h-100" id={`apartment-name-${apartment.id}`}>
        {isSpecialLocal && <div className="calendar__apartment-extras is-special-local" />}
        {isWithoutAnimals && <div className="calendar__apartment-extras has-animals" />}
        {hasGarden && <div className="calendar__apartment-extras has-garden" data-testid="local-with-garden" />}
      </div>

      <UncontrolledTooltip
        target={`apartment-name-${apartment.id}`}
        placement="top"
        className="calendar__apartment-extras__description-tooltip"
      >
        {isSpecialLocal && (
          <Description
            text="Lokal specjalny"
            image={<i className="uil-star font-18 mr-1 calendar__apartment-extras__description-row is-special-local" />}
          />
        )}
        {isWithoutAnimals && (
          <Description text="Lokal dla alergików" image={<i className="uil-flower font-18 text-warning mr-1" />} />
        )}
        {hasGarden && (
          <Description
            text={`Lokal z ogródkiem ${apartment.with_garden_from ? `od ${toDefaultDateFormat(apartment.with_garden_from)}` : ''}`}
            image={<img src={require('@assets/images/grass_icon.svg')} alt="Ogródek" height="12" className="mr-1" />}
          />
        )}
      </UncontrolledTooltip>
    </>
  )
}

const Description = ({ text, image }) => (
  <div className="row align-items-center calendar__apartment-extras__description-row">
    <div className="col-2">{image}</div>
    <div className="text-left font-11 col-10">
      <div className="text-nowrap">{text}</div>
    </div>
  </div>
)
