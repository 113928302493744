import * as React from 'react'
import { IconWithText, Props } from '@components/icon-with-text'
import classNames from 'classnames'

interface YesNoWithIconProps extends Partial<Props> {
  value: boolean
}

export const YesNoWithIcon: React.FC<YesNoWithIconProps> = ({ value, wrapperClassNames, iconSize, textClass }) => (
  <IconWithText
    icon={classNames('uil', { 'uil-check': value, 'uil-times': !value })}
    iconSize={classNames(iconSize, { 'text-success': value, 'text-danger': !value })}
    text={value ? 'tak' : 'nie'}
    wrapperClassNames={classNames('mr-1', wrapperClassNames)}
    textClass={textClass}
  />
)
