import * as React from 'react'
import { CmsResortDetails, ResortAttraction } from '@modules/cms/models'
import { reOrderArray } from '@helpers/utils'
import { CmsReservationAppResortAttractionsEl } from '@modules/cms/reservation-app/resort-modal/attractions/attractions-el'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useAppDispatch } from '@store/index'
import { setCmsResort } from '@store/slices/cms-reservation-app-slice'

interface Props {
  resort: CmsResortDetails
}

export const CmsReservationAppResortAttractionsDnd = ({ resort }: Props) => {
  const [attractions, setAttractions] = React.useState<ResortAttraction[]>([])
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    setAttractions(resort.reservation_attractions)
  }, [resort])

  const moveItem = (sourceId: number, destinationId: number) =>
    setAttractions(reOrderArray<ResortAttraction>(attractions, sourceId, destinationId))

  const onDrop = async () =>
    dispatch(
      setCmsResort(
        await commonObjectPost<CmsResortDetails>(
          resort.urls.resort_attractions_position,
          Object.fromEntries(attractions.map((row, index) => [row.id, index])),
        ),
      ),
    )

  return (
    <div className="mt-2 d-flex flex-wrap">
      {attractions.map((attraction, i) => (
        <CmsReservationAppResortAttractionsEl
          onDrop={onDrop}
          resort={resort}
          attraction={attraction}
          key={attraction.id}
          index={i}
          moveItem={moveItem}
        />
      ))}
    </div>
  )
}
