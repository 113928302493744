import * as React from 'react'

interface Props {
  title: React.ReactNode
  cells: React.ReactNode
  children?: React.ReactElement
}

export const ImprovementsStatsTableExpandedRow = ({ title, cells }: Props) => (
  <tr>
    <td className="font-12 align-middle pl-4 reservation-stats__col__first">{title}</td>
    {cells}
  </tr>
)
