import * as React from 'react'
import { CommonTask } from '@modules/housekeeping/models'
import { HousekeepingOrdersRowTotalTimeStartedLive } from '@modules/housekeeping/common/row-total-time-started-live'
import { HousekeepingOrdersRowTotalTimeStarted } from '@modules/housekeeping/common/row-total-time-started'
import { HousekeepingCommonTasksTableRowActions } from '@modules/housekeeping/cleaning-orders/common-tasks/table-row-actions'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  commonTask: CommonTask
}

export const HousekeepingCommonTasksTableRow = ({ commonTask }: Props): JSX.Element => (
  <tr className="housekeeping__orders_table__row">
    <td className="housekeeping__orders_table__column text-semi-strong">{commonTask.name}</td>
    <td className="housekeeping__orders_table__column">{commonTask.user}</td>
    {commonTask.special_working_time ? (
      <td className="housekeeping__orders_table__column">{commonTask.special_working_time}</td>
    ) : commonTask.status === 'IN_PROGRESS' && !commonTask.is_paused ? (
      <HousekeepingOrdersRowTotalTimeStartedLive order={commonTask} />
    ) : (
      <HousekeepingOrdersRowTotalTimeStarted totalTimeStarted={commonTask.total_time_started}>
        <IconWithText icon="uil-pause font-15" text="pauza" textClass="text-semi-strong ml-05" flexClassName="d-flex" />
      </HousekeepingOrdersRowTotalTimeStarted>
    )}
    <td className="housekeeping__orders_table__column">{commonTask.status_display}</td>
    <HousekeepingCommonTasksTableRowActions commonTask={commonTask} />
  </tr>
)
