import * as React from 'react'
import { Button, ModalFooter, ModalHeader } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { FormInput } from '@hyper/forms'
import { SaveButton } from '@hyper/button'
import { useForm } from 'react-hook-form'
import { useAppDispatch } from '@store/index'
import { useFormRequest } from '@components/hooks/use-api-request'
import { BaseModalProps } from '@components/modals/types'
import { updateGastroCardDetails } from '@store/slices/gastro-card-slice'
import { commonObjectPost } from '@store/actions/generic-actions'
import { GastroCardDetails } from '@models/promotions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { Form } from '@hyper/forms/form'

interface FormInputs {
  code_amount: number
}

interface Props extends BaseModalProps {
  gastroCardDetails: GastroCardDetails
}

export const GastroCardCodeCreateDialog: React.FC<Props> = ({ toggleIsVisible, gastroCardDetails }) => {
  const methods = useForm<FormInputs>({
    defaultValues: { code_amount: 100 },
    mode: 'all',
  })
  const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()

  const { isLoading, action: onSubmit } = useFormRequest(async payload => {
    dispatch(updateGastroCardDetails(await commonObjectPost<GastroCardDetails>(gastroCardDetails.urls.codes, payload)))
    addSuccessMessage('Sukces', 'Kod dla znajomych został wygenerowany')
    toggleIsVisible()
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Dodaj kod</ModalHeader>
      <ModalBody>
        <FormInput label="Kwota" name="code_amount" />
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton label="Zapisz" className="btn btn-green" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
