import * as React from 'react'
import Table from '@components/table/table'
import { ClientCouponItem } from '@modules/coupons/models'
import { CopyToClipboard } from '@components/copy-to-clipboard'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { formatPriceShort } from '@helpers/utils'
import { useModal } from '@components/modals/use-modal'
import { BadgeWithIcon } from '@components/badges/BadgeWithIcon'
import { getStatusClassIcon } from '@modules/promotions/gastro-cards/utils'

interface Props {
  coupon: ClientCouponItem
}

export const CouponsClientDetailsTableRow = ({ coupon }: Props): JSX.Element => {
  const [showCouponEditModal] = useModal('CouponItemEditDetailsModal', { coupon })
  const [showCouponDetailsModal] = useModal('CouponItemDetailsModal', { coupon })

  const [badgeClass, badgeIcon] = getStatusClassIcon(coupon.status)

  const handleShowCouponEditModal = (event: React.MouseEvent) => {
    event.stopPropagation()
    showCouponEditModal(null, { coupon })
  }

  return (
    <Table.Row className="cursor-pointer" onClick={showCouponDetailsModal}>
      <Table.Cell>
        {coupon.code ? (
          <>
            {coupon.code}
            <CopyToClipboard className="uil-share-alt ml-1" value={coupon.code} />
          </>
        ) : (
          '-'
        )}
      </Table.Cell>
      <Table.Cell>
        <BadgeWithIcon icon={badgeIcon} className={badgeClass} title={coupon.status_display} />
      </Table.Cell>
      <Table.Cell>{coupon.coupon_type_name}</Table.Cell>
      <Table.Cell>{formatPriceShort(coupon.value)}</Table.Cell>
      <Table.Cell>
        {coupon.payment_method}
        {coupon.transaction_id && <small className="d-block">({coupon.transaction_id})</small>}
      </Table.Cell>
      <Table.Cell>{coupon.invoice ? coupon.invoice.reservation_number : '-'}</Table.Cell>
      <Table.Cell>{toDefaultDateTimeFormat(coupon.purchase_date)}</Table.Cell>
      <Table.Cell>{toDefaultDateTimeFormat(coupon.expiration_date)}</Table.Cell>
      <Table.Cell>{coupon.use_date ? toDefaultDateTimeFormat(coupon.use_date) : '-'}</Table.Cell>
      <Table.Cell>{coupon.booking_number}</Table.Cell>
      <Table.Cell style={{ width: 40 }} onClick={handleShowCouponEditModal}>
        <i className="uil-pen font-14 lh-1 cursor-pointer" />
      </Table.Cell>
    </Table.Row>
  )
}
