import * as React from 'react'
import { Col, Row } from 'reactstrap'
import { differenceInDays } from 'date-fns'
import { parseISODate, toDefaultDateFormat } from '@helpers/date-helper'
import { ReceptionBookingDetails } from '@models/reception'
import declination from '@helpers/declination'
import { ReservationType } from '@modules/reservations/details/reservation-data/reservation-type'

interface StepVerificationBookingInfoProps {
  booking: ReceptionBookingDetails
}

export const StepVerificationBookingInfo: React.FC<StepVerificationBookingInfoProps> = ({ booking }) => {
  const dateFrom = React.useMemo(() => parseISODate(booking.date_from) || 0, [booking.date_from])
  const dateTo = React.useMemo(() => parseISODate(booking.date_to) || 0, [booking.date_to])

  const numberOfNights = differenceInDays(dateTo, dateFrom)

  return (
    <div className="font-14">
      <strong className="mb-3 d-block">Skrócony opis rezerwacji</strong>
      <div className="text-muted">
        <Row className="mb-2">
          <Col md={5}>Lokal:</Col>
          <Col md={7} className="text-strong">
            {booking.apartment.name}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md={5}>Nr rezerwacji:</Col>
          <Col md={7} className="text-strong">
            <a href={booking.urls.details} className="text-muted" target="_blank">
              {booking.reservation_number}
            </a>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md={5}>Typ rezerwacji:</Col>
          <Col md={7} className="text-strong">
            <ReservationType booking={booking} />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md={5}>Imię i nazwisko:</Col>
          <Col md={7} className="text-strong">
            {booking.name}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md={5}>Długość pobytu</Col>
          <Col md={7} className="text-strong">
            {numberOfNights} {declination.night(numberOfNights)}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md={5}>Data wymeldowania</Col>
          <Col md={7} className="text-strong">
            {toDefaultDateFormat(booking.date_to)}
          </Col>
        </Row>
      </div>
    </div>
  )
}
