import * as React from 'react'
import { Button, CustomInput, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { ReceptionBookingDetails } from '@models/reception'
import { useAppDispatch } from '@store/index'
import { withTooltip } from '@components/with-tooltip'
import { ReservationChangeDates, UpdateReservationDatePayload } from '@modules/reservations/models'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { SaveButton } from '@hyper/button'
import { ReservationDateSelection } from '@modules/reservations/details/reservation-data/reservation-date-selection'
import { parseISODate } from '@helpers/date-helper'
import { extractInnerRef } from '@helpers/forms'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { commonObjectGet, commonObjectPut } from '@store/actions/generic-actions'
import { useFormRequest } from '@components/hooks/use-api-request'
import { updateReceptionBookingDetails } from '@store/actions/reception-actions'
import { Form } from '@hyper/forms/form'
import { BaseModalProps } from '@components/modals/types'

interface Props extends BaseModalProps {
  booking: ReceptionBookingDetails
  onSuccess?: () => void
}

export const ReservationChangeDatesDialog: React.FC<Props> = ({ booking, toggleIsVisible, onSuccess }) => {
  const [availableDates, setAvailableDates] = React.useState<string[]>([])
  const { addErrorMessage, addSuccessMessage } = useNotificationHook()

  const user = useAuthenticatedUser()
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    ;(async () => {
      const changeDates = await commonObjectGet<ReservationChangeDates>(booking.urls.change_date)
      if (!changeDates.can_change_booking_date) {
        addErrorMessage('Błąd', 'Nie można zmienić daty tej rezerwacji.')
        toggleIsVisible()
      }
      setAvailableDates(changeDates.available_dates)
    })()
  }, [dispatch])

  const methods = useForm<UpdateReservationDatePayload>({
    defaultValues: {
      date_from: parseISODate(booking.date_from) || undefined,
      date_to: parseISODate(booking.date_to) || undefined,
      force: false,
      recalculate_warranty: false,
      extend_stay_discount: false,
      extend_stay_full: false,
    },
    mode: 'all',
  })

  const { isLoading, action: onSubmit } = useFormRequest(
    async (data: UpdateReservationDatePayload) => {
      await dispatch(
        updateReceptionBookingDetails(await commonObjectPut<ReceptionBookingDetails>(booking.urls.change_date, data)),
      )
      addSuccessMessage()
      onSuccess?.()
      toggleIsVisible()
    },
    methods.setError,
    {
      nonFieldErrorsAs: 'date_from',
    },
  )

  const InputWithTooltip = React.useMemo(() => withTooltip({ type: 'checkbox', className: 'my-2' })(CustomInput), [])

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Zmiana daty pobytu</ModalHeader>
      <ModalBody>
        <ReservationDateSelection availableDates={availableDates} />
        <CustomInput
          type="checkbox"
          className="my-2"
          label="Przelicz opcję rezygnacji"
          id="recalculate-warranty"
          {...extractInnerRef(methods.register('recalculate_warranty'))}
        />
        <InputWithTooltip
          tooltipId="force-tooltip"
          tooltipMessage="Musisz posiadać prawa administratora"
          disabled={!user.hasPerm(UserPermission.RentBookingCanChangeDateWithForce)}
          label="Zmiana w trybie administratora"
          id="force"
          {...extractInnerRef(methods.register('force'))}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
