import * as React from 'react'
import { PurchasePaymentContainer } from '@modules/shop/pos/purchase-payments/purchase-payment-container'
import { BandDetails } from '@models/band'
import { PurchaseBandPaymentContent } from '@modules/shop/pos/purchase-payments/band/purchase-band-payment-content'

interface PurchaseBandPaymentProps {
  onCancel: () => void
  restToPay: string
  onSubmit: (bookingGuestId: number) => void
}

export const PurchaseBandPayment: React.FC<PurchaseBandPaymentProps> = ({ onCancel, onSubmit, restToPay }) => {
  const [bandDetails, setBandDetails] = React.useState<BandDetails | undefined>(undefined)

  const handleConfirm = () => {
    if (bandDetails?.guest) {
      onSubmit(bandDetails?.guest.id)
    }
  }

  return (
    <PurchasePaymentContainer
      title="Płatność opaską na lokal"
      icon="uil-capture"
      price={restToPay}
      confirmDisabled={!bandDetails?.guest}
      onConfirm={handleConfirm}
      onCancel={onCancel}
    >
      <PurchaseBandPaymentContent onBandDetailsChange={setBandDetails} bandDetails={bandDetails} />
    </PurchasePaymentContainer>
  )
}
