import * as React from 'react'
import { Row } from 'reactstrap'
import { useFormContext } from 'react-hook-form'
import { SubscriptionCreateFormInputs } from '@modules/subscription/create/modal'
import { ClientSelect } from '@components/client-select'
import { ClientUser } from '@models/clients'
import { useModal } from '@components/modals/use-modal'
import { InvoiceForm } from '@components/invoice-form'
import { createInvoiceData } from '@components/products/product-invoice-card'
import { SubscriptionDetails, SubscriptionType } from '@modules/subscription/models'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { useSubscriptionTypeStrategyProvider } from '@modules/subscription/hooks/use-subscription-type-provider'

interface Props {
  subscriptionDetails?: SubscriptionDetails
  type: SubscriptionType
  initialClient?: ClientUser | null
}

export const SubscriptionCreateFormClientData: React.FC<Props> = ({ initialClient, subscriptionDetails, type }) => {
  const [client, setClient] = React.useState<ClientUser | undefined>(initialClient ?? undefined)

  const [additionalClients, setAdditionalClients] = React.useState<ClientUser[]>(
    subscriptionDetails?.clients.filter(row => row.id !== subscriptionDetails?.client_id) ?? [],
  )

  const {
    formState: { errors },
    setValue,
    clearErrors,
  } = useFormContext<SubscriptionCreateFormInputs>()

  const [handleToggleClientDialog] = useModal('ClientDialog', { setClient })

  const { allow_add_additional_clients } = useSubscriptionTypeStrategyProvider(type)

  React.useEffect(() => {
    if (!client) return
    setValue('client', client)
    clearErrors()
  }, [client])

  React.useEffect(() => {
    setValue('clients', additionalClients)
  }, [additionalClients])

  const user = useAuthenticatedUser()

  const isClientEditDisabled =
    !!subscriptionDetails &&
    subscriptionDetails?.status !== 'initial' &&
    !user.hasPerm(UserPermission.SubscriptionCanChangeClient)

  const handleAddAdditionalClient = (client: ClientUser) => setAdditionalClients(client ? [client] : [])

  const additionalClientsEmail = additionalClients.map(el => el.email)

  return (
    <>
      <Row>
        <ClientSelect
          setClient={setClient}
          client={client}
          handleToggleClientDialog={handleToggleClientDialog}
          error={errors.client}
          isDisabled={isClientEditDisabled}
          emailsToOmit={additionalClientsEmail.length ? additionalClientsEmail : []}
        />

        {allow_add_additional_clients && (
          <ClientSelect
            setClient={handleAddAdditionalClient}
            client={additionalClients[0] ?? undefined}
            handleToggleClientDialog={handleToggleClientDialog}
            error={errors.client}
            isDisabled={isClientEditDisabled}
            label="Dodatkowy Klient"
            emailsToOmit={client ? [client.email] : []}
          />
        )}
      </Row>

      <InvoiceForm client={client} invoiceData={subscriptionDetails && createInvoiceData(subscriptionDetails)} />
    </>
  )
}
