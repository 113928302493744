import * as React from 'react'
import { TechnicalOrder } from '@models/technical-orders'
import { useLocation, useNavigate } from 'react-router-dom'

export const useTechnicalOrderPreviewModal = ({ technicalOrder }: { technicalOrder: TechnicalOrder }) => {
  const navigate = useNavigate()
  const location = useLocation()

  return React.useCallback(() => {
    navigate(location.pathname + `/${technicalOrder.id}`)
  }, [navigate, location, technicalOrder.id])
}
