import { DropResult } from 'react-beautiful-dnd'
import { getById } from '@helpers/utils'
import { TechnicalOrderOrderedDroppableIds } from '@modules/technical-orders/technical-orders-ordered/drag-and-drop/consts'
import { useApiRequest } from '@components/hooks/use-api-request'
import { TechnicalOrder } from '@models/technical-orders'
import { commonObjectPost } from '@store/actions/generic-actions'
import { TECHNICAL_ORDER_TAG, technicalOrdersApi, useGetTechnicalOrdersQuery } from '@api/technical-orders'
import { useAppDispatch } from '@store/index'
import { TypedQueryResult } from '@api/base'
import * as React from 'react'

interface Response {
  isReordering: boolean
  onDragEnd: (dropResult: DropResult) => void
}

const emptyTable = []
export const useTechnicalOrderDnd = (resortId: number): Response => {
  const { data: technicalOrders = emptyTable, isFetching } = useGetTechnicalOrdersQuery({
    resort: resortId,
    status: 'ordered',
  }) as TypedQueryResult<TechnicalOrder[]>

  const { highPriorityOrders, lowPriorityOrders } = React.useMemo(
    () =>
      technicalOrders.reduce(
        (orders, order) => {
          if (order.priority > 0) return { ...orders, highPriorityOrders: [...orders.highPriorityOrders, order] }
          if (order.priority === 0) return { ...orders, lowPriorityOrders: [...orders.lowPriorityOrders, order] }
          return orders
        },
        { lowPriorityOrders: [], highPriorityOrders: [] },
      ),
    [technicalOrders],
  )

  const dispatch = useAppDispatch()

  const { isLoading: isReordering, action: reorder } = useApiRequest(
    async (order: TechnicalOrder, payload: { position: number; priority: number }) => {
      await commonObjectPost<TechnicalOrder[]>(order.urls.position, payload)
      dispatch(technicalOrdersApi.util.invalidateTags([TECHNICAL_ORDER_TAG]))
    },
  )

  const onDragEnd = async (dropData: DropResult) => {
    if (!dropData.destination) return

    const draggedOrder = getById([...highPriorityOrders, ...lowPriorityOrders], dropData.draggableId)

    if (!draggedOrder) return

    if (
      dropData.destination?.droppableId === TechnicalOrderOrderedDroppableIds.TECHNICAL_ORDER_HIGH_PRIORITY_EMPTY ||
      dropData.destination?.droppableId === TechnicalOrderOrderedDroppableIds.TECHNICAL_ORDER_NORMAL_PRIORITY_EMPTY
    ) {
      await reorder(draggedOrder, {
        position: 0,
        priority:
          dropData.destination?.droppableId === TechnicalOrderOrderedDroppableIds.TECHNICAL_ORDER_HIGH_PRIORITY_EMPTY
            ? 10
            : 0,
      })
      return
    }

    const destinationOrders =
      dropData.destination?.droppableId === TechnicalOrderOrderedDroppableIds.TECHNICAL_ORDER_HIGH_PRIORITY
        ? highPriorityOrders
        : lowPriorityOrders

    if (destinationOrders[dropData.destination.index]) {
      const destinationOrder = destinationOrders[dropData.destination.index]

      const position =
        dropData.destination?.droppableId === dropData.source.droppableId
          ? dropData.destination.index > dropData.source.index
            ? destinationOrders[dropData.destination.index + 1]?.position ||
              (destinationOrders.at(-1)?.position ?? 0) + 1
            : destinationOrder.position
          : destinationOrder.position

      await reorder(draggedOrder, {
        position,
        priority: destinationOrder?.priority,
      })

      return
    }

    const destinationOrder = destinationOrders[Math.max(dropData.destination.index - 1, 0)]

    await reorder(draggedOrder, {
      position: destinationOrder.position + 1,
      priority: destinationOrder?.priority,
    })
  }

  return {
    isReordering: isReordering || isFetching,
    onDragEnd,
  }
}
