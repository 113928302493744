import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { FormPlain, FormPlainError } from '@hyper/forms'
import { Input } from 'reactstrap'
import classNames from 'classnames'
import { extractInnerRef } from '@helpers/forms'

interface Props {
  name: string
}

export const CashCountModalRowInput = ({ name }: Props): JSX.Element => {
  const {
    setValue,
    getValues,
    register,
    control,
    formState: { errors },
  } = useFormContext()

  const set = (value: number) => {
    const newValue = Math.max(getValues(name) + value, 0)
    setValue(name, newValue)
  }
  const handlePlus = () => set(+1)
  const handleMinus = () => set(-1)

  const newValue = useWatch({ control, name })

  React.useEffect(() => {
    if (getValues(name) !== parseInt(newValue, 10)) {
      setValue(name, parseInt(newValue, 10))
    }
  }, [newValue])

  return (
    <FormPlain colSize={4} colClassName="pl-0" name={name}>
      <Input
        {...extractInnerRef(
          register(name, {
            valueAsNumber: true,
          }),
        )}
        type="number"
        min={0}
        step={1}
        pattern="^[-\d]\d*$"
        className={classNames('form__input__number w-100 text-center', {
          'is-invalid': !!errors[name],
        })}
      />
      <div className="d-flex justify-content-between mt-1">
        <div className="btn btn-primary mr-1 text-strong text-white cursor-pointer" onClick={handleMinus}>
          <i className="uil-minus" />
        </div>
        <div className="btn btn-primary text-strong text-white cursor-pointer" onClick={handlePlus}>
          <i className="uil-plus" />
        </div>
      </div>

      <FormPlainError name={name} />
    </FormPlain>
  )
}
