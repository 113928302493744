import * as React from 'react'
import { ColAuto } from '@hyper/col-auto'
import { FormProvider, useForm } from 'react-hook-form'
import DateRangeInput from '@components/date/date-range-input'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { Button, Label, Row } from 'reactstrap'
import { ReportListFilterParams } from '@modules/reports/user-reports/index'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { FormGroup } from 'reactstrap/lib'
import { selectReportAggregation } from '@store/slices/report-task-slice'
import { createSelectOption } from '@helpers/utils'
import { FormSelect } from '@hyper/forms/form-select'
import { useAppSelector } from '@store/index'

interface Props {
  defaultFilters: ReportListFilterParams
  filters: ReportListFilterParams
  setFilters: (filters: ReportListFilterParams) => void
}

export const ReportListFilters: React.FC<Props> = ({ defaultFilters, filters, setFilters }) => {
  const user = useAuthenticatedUser()
  const reportAggregation = useAppSelector(selectReportAggregation)

  const userOptions = React.useMemo(
    () =>
      reportAggregation?.users
        .filter(aggregationUser => !!aggregationUser.value)
        .map(aggregationUser => createSelectOption(aggregationUser.value, aggregationUser.id)) ?? [],
    [reportAggregation?.users],
  )

  const kindOptions = React.useMemo(
    () => reportAggregation?.kinds.map(reportKind => createSelectOption(reportKind.value, reportKind.key)) ?? [],
    [reportAggregation?.kinds],
  )

  const methods = useForm<ReportListFilterParams>({
    defaultValues: defaultFilters,
  })

  const handleReset = () => {
    methods.reset()
    setFilters(defaultFilters)
  }

  const handleFilter = () => {
    onSubmit(methods.getValues())
  }

  const onSubmit = async (payload: ReportListFilterParams) => {
    setFilters({ ...filters, ...payload })
  }

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  return (
    <FormProvider {...methods}>
      <Row>
        <ColAuto className="px-0">
          <Label>Data utworzenia</Label>
          <DateRangeInput
            isClearable={true}
            startDateName="created_after"
            endDateName="created_before"
            wrapperClassName="is-range"
          />
        </ColAuto>

        {user.is_superuser && (
          <>
            <ColAuto className="px-0">
              <FormSelect
                options={userOptions}
                name="created_by"
                label="Autor"
                selectProps={{ isClearable: true }}
                formPlainProps={{ colStyle: { width: 200 } }}
              />
            </ColAuto>
            <ColAuto className="px-0">
              <FormSelect
                options={kindOptions}
                name="kind"
                label="Rodzaj"
                selectProps={{ isClearable: true }}
                formPlainProps={{ colStyle: { width: 200 } }}
              />
            </ColAuto>
          </>
        )}

        <ColAuto>
          <FormGroup>
            <label className="d-block">&nbsp;</label>
            <Button color="primary" onClick={handleFilter}>
              Filtruj
            </Button>
            {methods.formState.isDirty && (
              <Button color="light" className="ml-1" type="button" onClick={handleReset}>
                Wyczyść
              </Button>
            )}
          </FormGroup>
        </ColAuto>
      </Row>
    </FormProvider>
  )
}
