import * as React from 'react'
import classNames from 'classnames'

interface AlertDangerProps {
  children: React.ReactNode
  className?: string
  icon?: string
  iconClassName?: string
  wrapperClassName?: string
}

export const AlertDanger: React.FC<AlertDangerProps> = ({
  children,
  icon,
  iconClassName,
  className,
  wrapperClassName,
}) => (
  <div className={classNames('alert alert-danger', className)} role="alert">
    <div className={classNames('d-flex', wrapperClassName)}>
      <i className={classNames(icon || 'uil-exclamation-triangle', 'mr-1', iconClassName)} />
      <div>{children}</div>
    </div>
  </div>
)
