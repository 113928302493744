import * as React from 'react'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { ReservationBlockadeFormInputs } from '@modules/reservations/blockade/reservation-blockade-modal'
import { IconWithText } from '@components/icon-with-text'
import { ReservationBlockadeLocalRow } from '@modules/reservations/blockade/via-dates/reservation-blockade-local-row'
import { useModal } from '@components/modals/use-modal'
import { AccommodationType } from '@models/booking'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useAppDispatch, useAppSelector } from '@store/index'
import { selectResortDetails } from '@store/selectors/reception'
import { getResortDetails } from '@store/actions/reception-actions'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { createSelectOption, getIntValue } from '@helpers/utils'
import { useAvailableApartments } from '@modules/reservations/use-available-apartments'
import { formatDate } from '@helpers/date-helper'
import {
  ACCOMMODATION_TYPES,
  HOUSE_85_ACCOMMODATION_TYPE,
  HOUSE_85_WITH_GARDEN_ACCOMMODATION_TYPE,
} from '@helpers/consts'

interface Props {
  accommodationTypes: AccommodationType[]
}

export const ReservationBlockadeViaDatesSelectedOption = ({ accommodationTypes }: Props): JSX.Element => {
  const { apartments, fetchApartments } = useAvailableApartments()

  const dispatch = useAppDispatch()
  const resortDetails = useAppSelector(selectResortDetails)

  const methods = useFormContext<ReservationBlockadeFormInputs>()
  const { fields, append, remove, update } = useFieldArray({ control: methods.control, name: 'selected_local_rows' })

  const [showLocalsMap] = useModal('LocalSelectionMapModal', { submitLabel: 'Potwierdź blokadę lokalu' })

  const [dateFrom, dateTo, resort, selectedLocals] = useWatch({
    control: methods.control,
    name: ['date_from', 'date_to', 'resort', 'selected_local_rows'],
  })

  const formattedDateFrom = formatDate(dateFrom)
  const formattedDateTo = formatDate(dateTo)

  const handleAppend = (
    accommodation: CustomReactSelectOption | null = null,
    local_number: CustomReactSelectOption | null = null,
  ) => {
    append({ accommodation, local_number })
  }

  const handleRemove = (index: number) => {
    if (fields.length > 1) {
      remove(index)
      return
    }

    update(index, { accommodation: null, local_number: null })
  }

  const handleApartmentSelection = (apartmentId: number, accommodationType: CustomReactSelectOption | null, index) => {
    const apartment = apartments.find(el => el.id === apartmentId)
    if (!apartment) return

    update(index, {
      accommodation: accommodationType,
      local_number: createSelectOption(apartment.display_name, apartment.id),
    })
  }

  const handleShowLocalsMap = (index: number) => {
    const accommodation = selectedLocals[index].accommodation
    showLocalsMap(null, {
      apartments: getApartments(index),
      accommodation: accommodationTypes.find(el => el.id === accommodation?.value),
      defaultSelectedApartmentId: null,
      onApartmentSelect: apartment => handleApartmentSelection(apartment, accommodation, index),
    })
  }

  const unselectedApartments = React.useMemo(
    () =>
      apartments
        .filter(apartment => apartment.is_available)
        .filter(apartment => !selectedLocals.some(selected => selected.local_number?.value === apartment.id)),
    [apartments, selectedLocals],
  )

  const getApartments = (index: number) => {
    const accommodationId = selectedLocals[index]?.accommodation?.value

    if (!accommodationId) return []

    return unselectedApartments.filter(apartment => {
      if (
        ACCOMMODATION_TYPES.HOUSES.includes(apartment.accommodation_type_id) &&
        ACCOMMODATION_TYPES.HOUSES.includes(accommodationId)
      ) {
        if (accommodationId === HOUSE_85_WITH_GARDEN_ACCOMMODATION_TYPE) {
          return apartment.has_garden_fixed
        }

        if (accommodationId === HOUSE_85_ACCOMMODATION_TYPE) {
          return !apartment.has_garden_fixed
        }
      }

      return apartment.accommodation_type_id === getIntValue(accommodationId)
    })
  }

  useDidUpdateEffect(() => {
    methods.resetField('selected_local_rows')
  }, [formattedDateFrom, formattedDateTo, resort.value])

  React.useEffect(() => {
    if (!fields.length) handleAppend()
  }, [fields])

  React.useEffect(() => {
    if (parseInt(resort.value, 10) !== resortDetails?.id) {
      dispatch(getResortDetails(resort.value))
    }
  }, [resort.value])

  React.useEffect(() => {
    fetchApartments({ resort: resort.value, date_from: dateFrom, date_to: dateTo })
  }, [resort.value, formattedDateFrom, formattedDateTo])

  const isMapForIndexAvailable = (index: number) => !!selectedLocals[index]?.accommodation

  const isLastRowFilled = !!selectedLocals.at(-1)?.accommodation && !!selectedLocals.at(-1)?.local_number

  const isRemovable = React.useCallback(
    (index: number) =>
      index > 0 || selectedLocals[index]?.accommodation !== null || selectedLocals[index]?.local_number !== null,
    [selectedLocals],
  )

  return (
    <>
      <div style={{ minHeight: 70 }}>
        {fields.map((field, index) => (
          <ReservationBlockadeLocalRow
            accommodationTypes={accommodationTypes}
            apartments={getApartments(index)}
            key={field.id}
            index={index}
            onDelete={handleRemove}
            isRemovable={isRemovable(index)}
            {...(isMapForIndexAvailable(index) && { onMapClick: () => handleShowLocalsMap(index) })}
          />
        ))}
      </div>

      {!!unselectedApartments.length && isLastRowFilled && (
        <IconWithText
          icon="uil-plus font-16 lh-initial"
          text="Blokuj kolejny lokal w tym terminie"
          textClass="font-12 text-semi-strong"
          wrapperClassNames="text-secondary mb-2"
          onClick={() => handleAppend()}
        />
      )}
    </>
  )
}
