import * as React from 'react'
import { ReceptionBooking } from '@models/reception'
import { ReceptionRowApartment } from '@modules/reception/common/reception-row-apartment'
import { ReceptionRowName } from '@modules/reception/common/reception-row-name'
import { ReceptionRowRestToPay } from '@modules/reception/common/reception-row-rest-to-pay'
import { ReceptionToCheckOutRowDepartureTime } from '@modules/reception/to-checkout/row/reception-to-check-out-row-departure-time'
import { ReceptionToCheckOutRowDeposit } from '@modules/reception/to-checkout/row/reception-to-check-out-row-deposit'
import { ReceptionRowNotes } from '@modules/reception/common/reception-row-notes'
import { ReceptionToCheckOutRowButtons } from '@modules/reception/to-checkout/row/reception-to-check-out-row-buttons'
import classNames from 'classnames'
import { formatDate, parseISODate, toDefaultDateTimeFormat } from '@helpers/date-helper'
import { formatDistanceToNow, isPast, parseISO } from 'date-fns'
import { pl } from 'date-fns/locale'

interface ReceptionToCheckOutRowProps {
  booking: ReceptionBooking
  date: Date
}

export const ReceptionToCheckOutRow: React.FC<ReceptionToCheckOutRowProps> = ({ booking, date }) => {
  const isTodayCheckout = React.useMemo(() => booking.date_to === formatDate(date), [date, booking.date_to])

  const plannedDepartureTime = React.useMemo(() => {
    if (isTodayCheckout) {
      return null
    }

    const isPastDate = isPast(parseISODate(booking.date_to) || 0)
    return (
      <>
        {formatDate(booking.date_to, 'd MMMM yyyy')} ({!isPastDate && 'za '}
        {formatDistanceToNow(parseISO(booking.date_to + ' ' + booking.departure_time), { locale: pl })}
        {isPastDate && ' temu'})
      </>
    )
  }, [booking, isTodayCheckout])

  return (
    <tr
      className={classNames('reception__bookings_table__row', {
        'reception__bookings_table__row--is-extended-stay': booking.extended_stay,
        'reception__bookings_table__row--is-check-out-started': booking.reception_action === 'check-out-started',
      })}
      role="booking-to-check-out"
    >
      <td className="reception__bookings_table__column reception__bookings_table__column__first">
        <ReceptionRowApartment booking={booking} />
      </td>
      <td className="reception__bookings_table__column">
        <ReceptionRowName booking={booking} />
      </td>
      <td className="reception__bookings_table__column">
        {isTodayCheckout && <ReceptionToCheckOutRowDepartureTime booking={booking} />}
      </td>

      <td className="reception__bookings_table__column">
        <ReceptionRowRestToPay booking={booking} />
      </td>
      <td className="reception__bookings_table__column">
        {booking.status !== 'close' && <ReceptionToCheckOutRowDeposit booking={booking} />}
      </td>
      <td className="valign-middle reception__bookings_table__column">
        <ReceptionRowNotes booking={booking} />
      </td>
      <td className="valign-middle reception__bookings_table__column text-right font-10">
        {!isTodayCheckout && booking.status !== 'close' && (
          <>
            Planowany wyjazd: <br />
            {plannedDepartureTime}
          </>
        )}
      </td>
      <td style={{ width: 10 }} className="text-nowrap reception__bookings_table__column">
        {booking.status !== 'close' ? (
          <ReceptionToCheckOutRowButtons booking={booking} />
        ) : (
          <div className="pt-2 text-center">
            <i className="uil-arrow-circle-right mr-1" />
            wymeldowana przez <br />
            <span className="font-11">
              {booking.closed_by} <br /> {toDefaultDateTimeFormat(booking.close_datetime)}
            </span>
          </div>
        )}
      </td>
    </tr>
  )
}
