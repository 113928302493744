import * as React from 'react'
import Table from '@components/table/table'
import { SubscriptionContractDetails, SubscriptionContractPeriod } from '@modules/subscription-contract/models'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useAppDispatch } from '@store/index'
import { updateSubscriptionContractDetails } from '@store/slices/subscription-contract-slice'
import { commonObjectPut } from '@store/actions/generic-actions'
import Spinner from '@hyper/spinner'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'

interface Props {
  period: SubscriptionContractPeriod
}

export const PaymentTableRowPaymentAction = ({ period }: Props): JSX.Element => {
  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()
  const user = useAuthenticatedUser()

  const { isLoading, action: handleClick } = useApiRequest(async () => {
    dispatch(updateSubscriptionContractDetails(await commonObjectPut<SubscriptionContractDetails>(period.urls.payment)))
    addSuccessNotification('Zlecenie zostało wysłane')
  })

  if (!user.hasPerm(UserPermission.PromotionsSubscriptionContractCanExecuteRecurringPayment)) {
    return <Table.Cell className="vertical-align-middle" />
  }

  return (
    <Table.Cell className="vertical-align-middle">
      {period.status !== 'paid' &&
        (isLoading ? (
          <Spinner size="xs" />
        ) : (
          <i className="uil-transaction cursor-pointer font-18" onClick={handleClick} />
        ))}
    </Table.Cell>
  )
}
