import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { BookingImprovementsAddedRow } from '@modules/reception/checkin/step-improvements/booking-improvements-added/row'
import { VipImprovementsCodes } from '@modules/reception/checkin/step-improvements/vip/reception-booking-check-in-improvements-vip'
import { HPFTicketImprovementCode } from '@modules/reception/checkin/step-improvements/tickets/hpf/reception-booking-hpf-ticket-improvement'
import { SubscriptionBookingImprovementCode } from '@modules/reception/checkin/step-improvements/improvement-subscription/reception-booking-improvement-subscription'

interface Props {
  booking: ReceptionBookingDetails
}

export const BookingImprovementsAdded = ({ booking }: Props): JSX.Element => {
  const addedImprovements = booking.improvements.filter(
    improvement =>
      !VipImprovementsCodes.includes(improvement.code) &&
      improvement.code !== HPFTicketImprovementCode &&
      improvement.code !== SubscriptionBookingImprovementCode,
  )

  return (
    <>
      {addedImprovements.map(bookingImprovement => (
        <BookingImprovementsAddedRow
          key={bookingImprovement.id}
          booking={booking}
          bookingImprovement={bookingImprovement}
        />
      ))}
    </>
  )
}
