import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useNewFeedingCalculations } from '@modules/reception/checkin/step-feeding/new-feeding/use-new-feeding-calculations'
import { Button } from 'reactstrap'
import { IconWithText } from '@components/icon-with-text'
import {
  BookingStatusesWithAvailableFeedingEdition,
  getFeedingChanges,
} from '@modules/reception/checkin/step-feeding/new-feeding/utils'
import { SaveButton } from '@hyper/button'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { useCartSave } from '@modules/reception/checkin/step-feeding/new-feeding/use-cart-save'
import { ReceptionBookingNewFeedingContent } from '@modules/reception/checkin/step-feeding/new-feeding/reception-booking-new-feeding-content'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { useNewFeedingVoucherPayment } from '@modules/reception/checkin/step-feeding/new-feeding/use-new-feeding-voucher-payment'

interface FormInputs {
  [key: string]: boolean
}

interface Props {
  booking: ReceptionBookingDetails
  onClose: () => void
}

export const ReceptionBookingNewFeeding = ({ booking, onClose }: Props): JSX.Element => {
  const user = useAuthenticatedUser()

  const methods = useForm<FormInputs>()
  const feedings = useWatch({ control: methods.control })

  const { feedingCalculations, initialFeedingCalculations, fetchFeedingCalculations } = useNewFeedingCalculations(
    booking,
    methods,
  )

  const feedingChanges = React.useMemo(() => {
    if (!initialFeedingCalculations?.details) return null

    return getFeedingChanges(feedings, initialFeedingCalculations.details)
  }, [feedings, initialFeedingCalculations?.details])

  const canSaveWithoutCartProcessing = true

  const { isPaymentByWalletSelected, toggleIsPaymentByWalletSelected } =
    useNewFeedingVoucherPayment(initialFeedingCalculations)

  const { saveCart: handleSave, isSaving } = useCartSave(
    booking,
    feedingChanges,
    !!canSaveWithoutCartProcessing,
    fetchFeedingCalculations,
    isPaymentByWalletSelected,
  )

  const canSaveChanges =
    user.hasPerm(UserPermission.BookingFeedingCanChange) || user.hasPerm(UserPermission.BookingFeedingCanAdd)

  const isSubmitDisabled = !canSaveChanges || !BookingStatusesWithAvailableFeedingEdition.includes(booking.status)

  return (
    <LoaderPlaceholder content={feedingCalculations && initialFeedingCalculations}>
      <FormProvider {...methods}>
        {feedingCalculations && initialFeedingCalculations && (
          <ReceptionBookingNewFeedingContent
            booking={booking}
            isPaymentByWalletSelected={isPaymentByWalletSelected}
            toggleIsPaymentByWalletSelected={toggleIsPaymentByWalletSelected}
            feedingCalculations={feedingCalculations}
            initialFeedingCalculations={initialFeedingCalculations}
          />
        )}
      </FormProvider>

      <div className="d-flex justify-content-between mt-3">
        <Button color="light" onClick={onClose}>
          Zamknij
        </Button>
        <SaveButton
          className="btn btn-green"
          labelSaving="Proszę czekać..."
          onClick={handleSave}
          isSaving={isSaving}
          disabled={isSubmitDisabled}
          label={
            <IconWithText
              icon={`font-15 ${canSaveWithoutCartProcessing ? 'uil-check' : 'uil-usd-circle'}`}
              text={canSaveWithoutCartProcessing ? 'Zatwierdź zmiany' : 'Podsumowanie i płatność'}
            />
          }
        />
      </div>
    </LoaderPlaceholder>
  )
}
