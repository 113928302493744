import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { PackageVip, PackageVipAggregation, PackageVipDetails } from '@modules/promotions/package-vip/models'
import { getPackagesVip, getPackageVipDetails } from '@store/actions/promotions-actions'
import { RootState } from '@store/index'

const packageVipAdapter = createEntityAdapter<PackageVip>()

export interface ModalState {
  packageVips: EntityState<PackageVip>
  packageVipDetails: PackageVipDetails | null
  aggregation: PackageVipAggregation | null
}

const initialState: ModalState = {
  packageVips: packageVipAdapter.getInitialState(),
  packageVipDetails: null,
  aggregation: null,
}

export const packageVipSlice = createSlice({
  name: 'packageVip',
  initialState,
  reducers: {
    setPackageVipDetails(state, action: PayloadAction<PackageVipDetails | null>) {
      state.packageVipDetails = action.payload
    },
    addPackageVip(state, action: PayloadAction<PackageVipDetails>) {
      packageVipAdapter.upsertOne(state.packageVips, {
        ...action.payload,
        seller: action.payload.seller?.name || '',
      })
    },
    updatePackageVipDetails(state, action: PayloadAction<PackageVipDetails>) {
      if (state.packageVipDetails?.id === action.payload.id) {
        state.packageVipDetails = action.payload
      }
      packageVipAdapter.updateOne(state.packageVips, {
        id: action.payload.id,
        changes: { ...action.payload, seller: action.payload.seller?.name || '' },
      })
    },
  },
  extraReducers: builder => {
    builder.addCase(getPackagesVip.fulfilled, (state, action) => {
      packageVipAdapter.setAll(state.packageVips, action.payload.results)
      state.aggregation = action.payload.aggregation
    })
    builder.addCase(getPackageVipDetails.fulfilled, (state, action) => {
      state.packageVipDetails = action.payload
      packageVipAdapter.updateOne(state.packageVips, {
        id: action.payload.id,
        changes: { ...action.payload, seller: action.payload.seller?.name || '' },
      })
    })
  },
})

export const { updatePackageVipDetails, setPackageVipDetails, addPackageVip } = packageVipSlice.actions

export const { selectAll: packageVipsSelector } = packageVipAdapter.getSelectors(
  (state: RootState) => state.packageVipState.packageVips,
)

export const selectPackagesVipAggregation = (state: RootState): PackageVipAggregation | null =>
  state.packageVipState.aggregation

export default packageVipSlice.reducer
