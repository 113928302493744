import { ContentNote } from '@models/dashboard'
import * as React from 'react'
import { useFormRequest } from '@components/hooks/use-api-request'
import { updateNote } from '@store/actions/dashboard-actions'
import { useForm } from 'react-hook-form'
import { Form } from '@hyper/forms/form'
import { ContentNoteForm } from '@components/content-notes/content-note-form'

interface FormInputs {
  content: string
}

interface Props {
  note: ContentNote
  onNoteChange?: (note: ContentNote) => void
  onAbort: () => void
}

export const ContentNotesCardContentEdit = ({ note, onNoteChange, onAbort }: Props): JSX.Element => {
  const methods = useForm<FormInputs>({ defaultValues: { content: note.content } })

  const { action: onSubmit, isLoading } = useFormRequest(async (payload: FormInputs) => {
    const updatedNote = await updateNote(note.urls.details, { content: payload.content })
    onNoteChange?.(updatedNote)
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ContentNoteForm
        className="mb-2"
        onAbort={onAbort}
        submitColor="outline-green"
        submitText="Zapisz notatkę"
        submitIcon="uil-plus font-15"
        isLoading={isLoading}
      />
    </Form>
  )
}
