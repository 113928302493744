import * as React from 'react'
import { ReceptionBookingNewFeedingPriceListRows } from '@modules/reception/checkin/step-feeding/new-feeding/pricelist/reception-booking-new-feeding-pricelist-rows'
import { FeedingPriceListItem } from '@modules/reception/checkin/step-feeding/new-feeding/pricelist/use-new-feeding-pricelist'

interface Props {
  guestKinds: string[]
  getPriceListItem: (kind: string) => FeedingPriceListItem | null
}

export const ReceptionBookingNewFeedingPricelistBestsellerPrices = ({
  guestKinds,
  getPriceListItem,
}: Props): JSX.Element | null => (
  <>
    <tr>
      <td className="text-secondary pt-4" colSpan={4}>
        <strong>
          Ceny jednostkowe wyżywienia w ramach promocji{' '}
          <strong className="feeding__bestseller text-default">Bestseller</strong>
        </strong>
      </td>
    </tr>

    {guestKinds.map(kind => {
      const prices = getPriceListItem(kind)
      if (!prices) return null

      return (
        <ReceptionBookingNewFeedingPriceListRows
          key={kind}
          name={prices.booking_guest_type_name}
          breakfast={prices.breakfast_price_brutto_in_hb ?? '0'}
          dinner={prices.dinner_price_brutto_in_hb ?? '0'}
        />
      )
    })}
  </>
)
