import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { asDecimal, formatPercentage, formatPriceShort } from '@helpers/utils'
import { NewFeedingCalculationDiscount } from '@modules/reception/checkin/step-feeding/new-feeding/models'
import classNames from 'classnames'

const MAXIMUM_AUTOMATIC_VALUE = 25

interface Props {
  discounts: NewFeedingCalculationDiscount[]
}

export const ReceptionBookingNewFeedingPriceTooltipDiscounts = ({ discounts }: Props): JSX.Element | null => {
  if (!discounts.length) return null

  const getDiscount = (discount: NewFeedingCalculationDiscount) =>
    discount.has_subscription_contract ? discount.value_for_subscriber : discount.value

  const getDiscountValue = (discount: NewFeedingCalculationDiscount) => {
    const value = getDiscount(discount)
    return discount.kind === 'percentage' ? formatPercentage(value) : formatPriceShort(value)
  }

  const totalAutomaticDiscounts = discounts.reduce(
    (total, discount) =>
      discount.is_automatic_discount && discount.kind === 'percentage' ? total.add(getDiscount(discount)) : total,
    asDecimal(0),
  )

  const hasMaxValueOfAutomaticDiscounts = totalAutomaticDiscounts.gt(MAXIMUM_AUTOMATIC_VALUE)

  const hasPromocodeDiscountType = discounts.some(discount => discount.discount_type === 'promocode')

  const isDiscountCrossed = (discount: NewFeedingCalculationDiscount) =>
    hasPromocodeDiscountType && discount.is_automatic_discount && discount.discount_type !== 'promocode'

  return (
    <div className="p-2 border-bottom border-gray d-flex justify-content-start flex-column">
      <DiscountRow title="Zastosowane rabaty:" titleClass="fw-semi-bold mb-1" valueClass="fw-bold" />

      {discounts.map((discount: NewFeedingCalculationDiscount, index: number) => (
        <DiscountRow
          key={discount.id ?? index}
          title={
            <IconWithText
              icon="uil-check text-success font-15"
              text={discount.name}
              textClass={classNames('text-left', {
                'text-decoration-line-through opacity-5': isDiscountCrossed(discount),
              })}
            />
          }
          value={getDiscountValue(discount)}
          valueClass={isDiscountCrossed(discount) ? 'text-decoration-line-through opacity-5' : ''}
        />
      ))}

      {hasMaxValueOfAutomaticDiscounts && (
        <span className="feeding__automatic-discount-label">Max. wartość rabatu automatycznego to -40%</span>
      )}
    </div>
  )
}

interface DiscountProps {
  title: React.ReactNode
  titleClass?: string
  valueClass?: string
  value?: React.ReactNode
}

const DiscountRow = ({ title, titleClass, valueClass, value }: DiscountProps) => (
  <div className="d-flex align-items-center justify-content-sm-between">
    <span className={titleClass}>{title}</span>
    <span className={valueClass}>{value}</span>
  </div>
)
