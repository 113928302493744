import * as React from 'react'
import { useForm } from 'react-hook-form'
import { ColAuto } from '@hyper/col-auto'
import { FormInput } from '@hyper/forms'
import { Button, FormGroup, Label } from 'reactstrap'
import DateRangeInput from '@components/date/date-range-input'
import { TransferPackagesFiltersParams } from '@modules/accounting/refund/transfer-packages/index'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { Form } from '@hyper/forms/form'
import { FormSelect } from '@hyper/forms/form-select'

interface RefundBookingsFiltersProps {
  filters: TransferPackagesFiltersParams
  defaultFilters: TransferPackagesFiltersParams
  setFilters: any
}

export const transferKindsOptions: CustomReactSelectOption[] = [
  { value: 'bookings', label: 'Zwrot kaucji' },
  { value: 'recommendation_gifts', label: 'Prezenty za polecenie' },
]

export const TransferPackagesFilters: React.FC<RefundBookingsFiltersProps> = ({
  setFilters,
  defaultFilters,
  filters,
}) => {
  const methods = useForm<TransferPackagesFiltersParams>({
    defaultValues: defaultFilters,
  })

  const onSubmit = payload => setFilters({ ...filters, ...payload })

  const handleReset = () => {
    methods.reset()
    setFilters(defaultFilters)
  }

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  return (
    <Form methods={methods} onSubmit={onSubmit} className="row form form-style-filter">
      <ColAuto>
        <FormInput colClassName="p-0" type="select" label="Status" name="status">
          <option value="">Wszystkie</option>
          <option value="initial">Nowa</option>
          <option value="generated">Wygenerowana</option>
          <option value="entered">Wprowadzona</option>
          <option value="sent">Wysłana</option>
        </FormInput>
      </ColAuto>
      <ColAuto className="px-0">
        <FormSelect
          options={transferKindsOptions}
          name="kind"
          label="Rodzaj"
          selectProps={{ isClearable: true, isSelectMulti: true }}
          formPlainProps={{ colStyle: { width: 330 } }}
        />
      </ColAuto>
      <ColAuto>
        <Label>Data wyjazdu</Label>
        <DateRangeInput startDateName="created_after" endDateName="created_before" />
      </ColAuto>

      <ColAuto>
        <FormGroup>
          <label className="d-block">&nbsp;</label>
          <Button color="primary">Filtruj</Button>
          {methods.formState.isDirty && (
            <Button color="light" className="ml-1" type="button" onClick={handleReset}>
              Wyczyść
            </Button>
          )}
        </FormGroup>
      </ColAuto>
      <SearchFilter colClassName="col-md-2" />
    </Form>
  )
}
