import * as React from 'react'
import { FileUploader, UploaderAcceptableFile } from '@components/file-uploader'

interface Props {
  files: File[]
  setFiles: any
}

export const PackageWholesaleDetailsMassNotificationsFiles = ({ files, setFiles }: Props): JSX.Element => {
  const upload = (file: File) => {
    setFiles(_files => [..._files, file])
  }

  const handleDelete = (file: File) => {
    setFiles(files.filter(row => row !== file))
  }

  return (
    <>
      {!!files.length && (
        <div className="mt-2 mb-2">
          <strong className="d-block mb-2">Lista załączników</strong>
          <ul>
            {files.map((file, index) => (
              <li key={index}>
                {file.name}{' '}
                <i
                  className="uil uil-trash-alt ml-2 font-16 text-muted cursor-pointer"
                  onClick={() => handleDelete(file)}
                />
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className="mt-2 mb-2">
        <FileUploader
          upload={upload}
          label="Przeciągnij i upuść plik lub kliknij by dodać załącznik"
          accept={UploaderAcceptableFile.PDF}
          isLoading={false}
        />
      </div>
    </>
  )
}
