import * as React from 'react'
import { CustomInput } from 'reactstrap'
import { CustomInputProps } from 'reactstrap/es/CustomInput'
import classNames from 'classnames'

interface Props extends Omit<CustomInputProps, 'type' | 'id'> {
  error?: string
  errorClassName?: string
  id: string
  indeterminate?: boolean
  label?: React.ReactNode
}

export const ControlledFormCheckbox = ({
  error,
  errorClassName,
  id,
  indeterminate,
  label,
  ...inputProps
}: Props): React.ReactElement => {
  const checkboxRef = React.useRef<HTMLInputElement | null>(null)

  React.useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = !!indeterminate
    }
  }, [indeterminate])

  return (
    <div>
      <CustomInput type="checkbox" {...inputProps} label={label} id={id} innerRef={checkboxRef} indeterminate={true} />
      {error && <div className={classNames('invalid-feedback d-block', errorClassName)}>{error}</div>}
    </div>
  )
}
