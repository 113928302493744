import { commonObjectGet } from '@store/actions/generic-actions'
import { StatsSaleItem } from '@models/reports'
import { getState } from '@store/index'
import { formatDate } from '@helpers/date-helper'
import { ImprovementAvailability } from '@models/booking'

export const getStatsSaleItem = async (sell_date: Date): Promise<StatsSaleItem[]> => {
  const url = getState().appState.appData.urls.reports.stats_sale_item_user
  return await commonObjectGet<StatsSaleItem[]>(url, { sell_date: formatDate(sell_date) })
}

export const getImprovementsAvailability = async (url: string): Promise<ImprovementAvailability[]> =>
  await commonObjectGet<ImprovementAvailability[]>(url)
