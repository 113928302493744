import { LeftMenu } from '@components/left-menu'
import { Topbar } from '@components/topbar'
import * as React from 'react'
import { RootState, useAppSelector } from '@store/index'
import classNames from 'classnames'

interface Props {
  children: any
}

export const LayoutWrapper: React.FC<Props> = ({ children }) => {
  const isMobileView = useAppSelector((state: RootState) => state.appState.isMobileView)

  return (
    <div className="wrapper">
      <Topbar search={true} stats={true} showMenu={true} showNotification={true} />
      <LeftMenu />
      <div className={classNames('content-page', { 'is-mobile-view': isMobileView })}>
        <div className="content">{children}</div>
      </div>
    </div>
  )
}
