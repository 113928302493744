import * as React from 'react'
import Table from '@components/table/table'
import { asDecimal, formatPercentage, formatPriceShort } from '@helpers/utils'
import { useModal } from '@components/modals/use-modal'
import { useUpsellBookingDetailsRefresh } from '@modules/upselling/upsell-booking-details/use-upsellbooking-details-refresh'
import { GastroCard } from '@models/promotions'
import { GastroProductKind } from '@models/products'
import { useUpsellingProducts } from '@modules/upselling/upsell-booking-details/products/use-upselling-products'
import { isGastroGenericProduct } from '@modules/promotions/gastro-cards/utils'

interface Props {
  gastroCards: GastroCard[]
  kind: GastroProductKind
  statuses: string[]
  onProductsCountChange: (count: number, type: string) => void
}

export const UpsellBookingsDetailsGastroProducts = ({
  gastroCards,
  kind,
  statuses,
  onProductsCountChange,
}: Props): JSX.Element => {
  const { refresh } = useUpsellBookingDetailsRefresh()

  const [showSubscriptionDetailsModal] = useModal('GastroCardDetailsDialog', {}, { onClose: refresh })

  const handleShowGastroCardDetailsClick = (gastroCard: GastroCard) => () => {
    showSubscriptionDetailsModal(null, { gastroCard })
  }

  const products = useUpsellingProducts(gastroCards, statuses, 'kind', kind)

  React.useEffect(() => {
    onProductsCountChange(products.length, kind)
  }, [products.length])

  const getPercentageAmount = (product: GastroCard) =>
    formatPercentage(
      asDecimal(product.sell_price).gt(0)
        ? asDecimal(product.rest_to_use).mul(100).div(product.sell_price).toString()
        : '100',
    )

  return (
    <>
      {products.map((gastroCard: GastroCard) => (
        <Table.Row key={gastroCard.id}>
          <Table.Cell className="align-middle">
            <span className="fw-semi-bold">{gastroCard.product_name}</span> <br />
            <small>Numer {gastroCard.code}</small>
          </Table.Cell>
          <Table.Cell className="align-middle">
            Pozostało <span className="fw-semi-bold">{getPercentageAmount(gastroCard)} środków</span>
          </Table.Cell>
          <Table.Cell className="align-middle">
            {isGastroGenericProduct(gastroCard.kind) && (
              <span>Kwota {formatPriceShort(gastroCard.declared_sell_price)}</span>
            )}
          </Table.Cell>
          <Table.Cell className="text-right align-middle" onClick={handleShowGastroCardDetailsClick(gastroCard)}>
            <i className="font-18 mr-2 uil-eye" />
          </Table.Cell>
        </Table.Row>
      ))}
    </>
  )
}
