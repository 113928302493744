import * as React from 'react'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { getHousekeepingBeddingOrders } from '@store/actions/housekeeping-actions'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { HousekeepingBeddingOrdersTable } from '@modules/housekeeping/cleaning-orders/bedding/table'
import { BeddingOrder, HousekeepingBeddingOrderType } from '@modules/housekeeping/models'
import { HousekeepingBeddingUser } from '@modules/housekeeping/cleaning-orders/bedding/bedding-user'
import { HousekeepingBeddingOrdersRowOptions } from '@modules/housekeeping/cleaning-orders/improvement/row-options'
import { useApiRequest } from '@components/hooks/use-api-request'
import { beddingOrdersSelector } from '@store/slices/housekeeping-slice'
import { FormProvider, useForm } from 'react-hook-form'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { HousekeepingBeddingOrdersFilters } from '@modules/housekeeping/cleaning-orders/bedding/filters'
import { searchBeddingOrders } from '@modules/housekeeping/cleaning-orders/common/filtering'
import { useHousekeepingTableFilters } from '@modules/housekeeping/hooks/use-housekeeping-table-filters'
import { IconWithText } from '@components/icon-with-text'

export interface HousekeepingBeddingOrderFiltersParams {
  beddingStatus: CustomReactSelectOption | null
  cleaningStatus: CustomReactSelectOption | null
  local: CustomReactSelectOption | null
  search: string
}

const defaultFilters: HousekeepingBeddingOrderFiltersParams = {
  beddingStatus: null,
  cleaningStatus: null,
  local: null,
  search: '',
}

interface HousekeepingBeddingOrdersProps {
  resortId: number
}

export const HousekeepingBeddingOrders: React.FC<HousekeepingBeddingOrdersProps> = ({ resortId }) => {
  const [selectedOrders, setSelectedOrders] = React.useState<BeddingOrder[]>([])

  const [filters, setFilters] = React.useState<HousekeepingBeddingOrderFiltersParams>(defaultFilters)

  const methods = useForm<HousekeepingBeddingOrderFiltersParams>({ defaultValues: filters })

  const beddingOrders = useAppSelector(beddingOrdersSelector)
  const workers = useAppSelector((state: RootState) => state.houseKeepingState.cleaningWorkers)
  const dispatch = useAppDispatch()

  const { getFiltered } = useHousekeepingTableFilters()

  const { isLoading, action: fetchHousekeepingBeddingOrders } = useApiRequest(
    async () =>
      await dispatch(getHousekeepingBeddingOrders([{ resort: resortId }, HousekeepingBeddingOrderType.PRESENT])),
  )

  React.useEffect(() => {
    fetchHousekeepingBeddingOrders()
  }, [resortId])

  const filterBeddingStatus = (order: BeddingOrder) =>
    !!(filters.beddingStatus && filters.beddingStatus?.value !== order.status)

  const filterCleaningStatus = (order: BeddingOrder) =>
    !!(filters.cleaningStatus && filters.cleaningStatus?.value !== order.cleaning_order?.status)

  const additionalFilters = (order: BeddingOrder) => filterBeddingStatus(order) || filterCleaningStatus(order)

  const filteredBeddingOrders = searchBeddingOrders(
    getFiltered(
      beddingOrders.filter(row => ['PENDING', 'IN_PROGRESS', 'FINISHED'].includes(row.status)),
      filters,
      additionalFilters,
    ),
    filters.search,
    workers,
    ['apartment.name'],
  )

  return (
    <LoaderPlaceholder content={!isLoading}>
      <FormProvider {...methods}>
        <HousekeepingBeddingOrdersFilters filters={filters} setFilters={setFilters} defaultFilters={defaultFilters} />
        <div className="d-flex justify-content-between">
          <HousekeepingBeddingOrdersRowOptions resortId={resortId} selectedOrders={selectedOrders} />
          <HousekeepingBeddingUser />
        </div>
        <HousekeepingBeddingOrdersTable
          selectedOrders={selectedOrders}
          setSelectedOrders={setSelectedOrders}
          beddingOrders={filteredBeddingOrders}
        />
        {!!filteredBeddingOrders.length && (
          <IconWithText
            icon="uil-info-circle text-warning font-18"
            text="Niezrealizowane zadania są usuwane po 3 dniach."
            textClass="fw-semi-bold font-12"
          />
        )}
      </FormProvider>
    </LoaderPlaceholder>
  )
}
