import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import * as R from 'ramda'
import { useDebounce } from 'rooks'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { SubmitFilter } from '@modules/reception/common/filters/submit-filter'
import { Row } from 'reactstrap'
import { HousekeepingBeddingOrderFiltersParams } from '@modules/housekeeping/cleaning-orders/bedding/index'

interface Props {
  filters: HousekeepingBeddingOrderFiltersParams
  setFilters: (filters: HousekeepingBeddingOrderFiltersParams) => void
  defaultFilters: HousekeepingBeddingOrderFiltersParams
}

export const HousekeepingBeddingOrdersFilters: React.FC<Props> = ({ filters, setFilters, defaultFilters }) => {
  const methods = useFormContext<HousekeepingBeddingOrderFiltersParams>()

  const values = useWatch({ control: methods.control })

  const onSubmitDebounced = useDebounce(values => setFilters(values), 250)

  React.useEffect(() => {
    if (!R.equals(values, filters)) {
      onSubmitDebounced(values)
    }
  }, [values])

  const handleReset = () => {
    methods.reset()
    setFilters(defaultFilters)
  }

  return (
    <Row className="d-flex w-100 justify-content-end mx-0">
      <SubmitFilter hideLabel handleReset={handleReset} />
      <SearchFilter hideLabel colClassName="col-auto px-0" formGroupClassName="mb-0" />
    </Row>
  )
}
