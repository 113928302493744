import axios from 'axios'
import { DashboardAppDataWithUser } from '@models/dashboard'

interface LoginPayload {
  username: string
  password: string
}

interface PinCodeLoginPayload {
  pin_code: string
}

interface ResetPasswordLinkPayload {
  email: string
}

interface ResetPasswordPayload {
  new_password: string
  confirmed_password: string
}

interface LoginUserResponse {
  next_url: string
}

export const logInUser = async (payload: LoginPayload): Promise<LoginUserResponse & DashboardAppDataWithUser> => {
  const response = await axios.post('/dashboard-api/account/login/', {
    ...payload,
  })
  return response.data
}

export const pinCodeLogInUser = async (payload: PinCodeLoginPayload): Promise<LoginUserResponse> => {
  const response = await axios.post('/api/account/pin-code-login/', {
    ...payload,
  })
  return response.data
}

export const resetPasswordLink = async (payload: ResetPasswordLinkPayload) => {
  await axios.post('/api/account/reset-password-link/admin/', {
    ...payload,
  })
}
export const resetPassword = async (token, payload: ResetPasswordPayload) => {
  await axios.post(`/api/account/reset-password/${token}/admin/`, {
    ...payload,
  })
}
