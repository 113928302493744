import { Agreement } from '@modules/hr/agreement/models'
import { subDays } from 'date-fns'
import { parseISODate } from '@helpers/date-helper'

export const getAgreementAnnexNumber = (annexNumber: string) => `#${annexNumber}`

export const canAddAgreementAnnex = (agreement: Agreement) => ['active'].includes(agreement.status)

export const getMaximumAgreementAnnexDate = (agreement: Agreement) =>
  subDays(parseISODate(agreement.date_of_expiration) as Date, 1)
