import * as React from 'react'
import { Row } from 'reactstrap'
import { FormInput } from '@hyper/forms'
import { FormProvider, useForm } from 'react-hook-form'
import { ReceptionBookingCheckInNoteInfo } from '@modules/reception/common/reception-note-popup/reception-booking-check-in-note-info'
import { formatDate } from '@helpers/date-helper'
import { saveNote } from '@store/actions/dashboard-actions'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { getReceptionBookingDetails } from '@store/actions/reception-actions'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useFormRequest } from '@components/hooks/use-api-request'

interface FormInputs {
  content: string
}

interface ReceptionBookingCheckInAddNoteProps {
  closeEditMode: () => void
  bookingId: number
  bookingDetailsUrl: string
}

export const ReceptionBookingCheckInAddNote: React.FC<ReceptionBookingCheckInAddNoteProps> = ({
  closeEditMode,
  bookingId,
  bookingDetailsUrl,
}) => {
  const methods = useForm<FormInputs>()
  const { addSuccessNotification } = useNotificationHook()
  const createNoteUrl = useAppSelector((state: RootState) => state.appState.appData.urls.dashboard.content_note_create)

  const contentType = useAppSelector((state: RootState) => state.appState.appData.content_types.booking)
  const user = useAuthenticatedUser()

  const dispatch = useAppDispatch()

  const { isLoading, action: handleAddNotes } = useFormRequest(async (payload: FormInputs) => {
    await saveNote(createNoteUrl, contentType, bookingId, payload.content)
    dispatch(getReceptionBookingDetails(bookingDetailsUrl))
    addSuccessNotification('Notatka została zapisana!')
    closeEditMode()
  }, methods.setError)

  return (
    <FormProvider {...methods}>
      <div>
        <div className="px-3 pt-2 pre">
          <ReceptionBookingCheckInNoteInfo
            created={formatDate(new Date(), "yyyy-MM-dd HH:mm'")}
            createdBy={user.fullname}
          />
        </div>
        <Row className="px-3">
          <FormInput
            name="content"
            type="textarea"
            inputClassName="bg-transparent border-0 text-white px-0 font-10"
            colClassName="my-1"
            inputProps={{
              autoFocus: true,
              rows: '6',
              style: { resize: 'none', overflowX: 'hidden', whiteSpace: 'pre-wrap' },
            }}
          />
        </Row>
        <div className="text-center my-1 cursor-pointer font-10" onClick={closeEditMode}>
          <div className="hr hr-white mb-1" />
          <i className="uil-times mr-1" /> Anuluj
        </div>
        <div className="hr hr-white" />
        <div
          className="text-center cursor-pointer bg-dark-grey py-2 font-10"
          onClick={methods.handleSubmit(handleAddNotes)}
          role="submit"
        >
          <i className="uil-check mr-1" />
          {isLoading ? <span>Zapisywanie...</span> : <span>Zapisz notatkę</span>}
        </div>
      </div>
    </FormProvider>
  )
}
