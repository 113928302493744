import * as React from 'react'
import { Transfer } from '@modules/accounting/refund/models'
import { asDecimal, formatPrice, sumDecimalArray } from '@helpers/utils'
import { PriceBlock } from '@modules/reservations/list/reservation-list-payment-summary'
import Table from '@components/table/table'

interface TransferPackagePreviewModalTableFooterProps {
  transfers: Transfer[]
}

export const TransferPackagePreviewModalTableFooter: React.FC<TransferPackagePreviewModalTableFooterProps> = ({
  transfers,
}) => {
  const totalAmount = sumDecimalArray(transfers.map(transfer => asDecimal(transfer.amount)))

  return (
    <Table.Row>
      <Table.Cell colSpan={5} className="text-right align-middle reservation-list__summary-cell">
        <strong className="mr-2">Razem do zwrotu:</strong>
      </Table.Cell>

      <Table.Cell className="reservation-list__summary-cell" colSpan={2}>
        <PriceBlock title="kwota:" price={formatPrice(totalAmount.toString())} />
      </Table.Cell>
      <Table.Cell className="reservation-list__summary-cell" />
    </Table.Row>
  )
}
