import * as React from 'react'
import { TransferPackage } from '@modules/accounting/refund/models'
import { useModal } from '@components/modals/use-modal'

interface Props {
  transferPackage: TransferPackage
}

export const TransferPackagesTableTableCellActionsDetails: React.FC<Props> = ({ transferPackage }) => {
  const [handlePreview] = useModal('TransferPackagePreviewModal', {
    transferPackage,
  })

  return <i className="uil uil-eye cursor-pointer font-16 mr-2 text-muted" title="Podgląd" onClick={handlePreview} />
}
