import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { IconWithText } from '@components/icon-with-text'
import { CopyToClipboardTooltip } from '@components/copy-to-clipboard-tooltip'

export const getReservationTypeName = (booking: ReceptionBookingDetails): string =>
  booking.is_package_reservation
    ? 'Pakiet firmowy'
    : booking.is_subscription_reservation
      ? 'Abonament'
      : booking.promocode
        ? 'Zrabatowana'
        : 'Standardowa'

interface Props {
  booking: ReceptionBookingDetails
  showCode?: boolean
}

export const ReservationType = ({ booking, showCode }: Props): React.ReactNode => (
  <>
    <span className="font-weight-bold mr-1">{getReservationTypeName(booking)}</span>
    {showCode && booking.promocode && (
      <CopyToClipboardTooltip
        value={booking.promocode.code}
        tooltipId={`${booking.promocode.code}-${booking.id}`}
        className="px-0"
      >
        <span className="font-10 fw-semi-bold">({booking.promocode.code})</span>
      </CopyToClipboardTooltip>
    )}

    {booking.is_package_reservation && (
      <a href={booking.urls.package_url} target="_blank" className="text-default">
        <IconWithText
          icon="uil-eye font-12"
          text="Idź do pakietu"
          textClass="fw-semi-bold font-10"
          wrapperClassNames="ml-1"
        />
      </a>
    )}

    {booking.is_subscription_reservation && (
      <a href={booking.urls.subscription_url} target="_blank" className="text-default">
        <IconWithText
          icon="uil-eye font-12"
          text="Idź do abonamentu"
          textClass="fw-semi-bold font-10"
          wrapperClassNames="ml-1"
        />
      </a>
    )}
  </>
)
