import * as React from 'react'
import { useModal } from '@components/modals/use-modal'
import { ButtonWithIcon } from '@components/button-with-icon'

export const ServiceBandAdd: React.FC = () => {
  const [handleClick] = useModal('ServiceBandCreateDialog')

  return (
    <ButtonWithIcon
      icon="mdi mdi-plus-circle mr-1"
      color="green-dark"
      text="Dodaj opaskę testową"
      handleClick={handleClick}
    />
  )
}
