import * as React from 'react'
import { Button, Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useHandleAxiosFormErrors } from '@helpers/handle-axios-errors'
import { useForm } from 'react-hook-form'
import { FormInput } from '@hyper/forms'
import { formatPriceShort } from '@helpers/utils'
import Spinner from '@hyper/spinner'
import { bookingPackageVipPayByCode } from '@store/actions/reception-actions-check'
import { ReceptionBookingDetails } from '@models/reception'
import { useAppDispatch } from '@store/index'
import { useApiRequest } from '@components/hooks/use-api-request'
import { BaseModalProps } from '@components/modals/types'
import { Form } from '@hyper/forms/form'
import { setReceptionBookingDetails } from '@store/slices/reception-slice'

interface FormInputs {
  code: string
}

interface Props extends BaseModalProps {
  booking: ReceptionBookingDetails
}

export const ReceptionBookingCheckInImprovementAddVipDialog: React.FC<Props> = ({ toggleIsVisible, booking }) => {
  const methods = useForm<FormInputs>()

  const [isChecking, setIsChecking] = React.useState(false)
  const dispatch = useAppDispatch()
  const handleAxiosFormErrors = useHandleAxiosFormErrors()

  const { isLoading, action: onSubmit } = useApiRequest(async (payload: FormInputs) => {
    try {
      setIsChecking(true)

      dispatch(setReceptionBookingDetails(await bookingPackageVipPayByCode(booking.id, payload.code)))
      toggleIsVisible()
    } catch (error) {
      setIsChecking(false)

      handleAxiosFormErrors(error, methods.setError, { showGlobalError: false })
    }
  })

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Dodawanie opłaty kodem</ModalHeader>
      <ModalBody>
        <Row className="position-relative">
          <div className="reception__booking-check-in__improvement-step__vip__vip-code__status">
            {isChecking && (
              <Spinner
                className="text-primary reception__booking-check-in__improvement-step__vip__vip-code__loader"
                size="xs"
              />
            )}
          </div>
          <FormInput placeholder="Wprowadź kod" label="Kod pakietowy / vip:" name="code" inputClassName="pr-4" />
        </Row>
        <Row>
          <Col className="text-primary">
            <i className="uil-info-circle font-15 mr-1" />
            <span className="font-12"> Kod na pobyt w apartamencie (+ dopłata {formatPriceShort(100)})</span>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Anuluj
        </Button>
        <SaveButton type="submit" className="btn btn-green" label="Zastosuj" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
