import * as React from 'react'
import { BadgeWithIcon } from '@components/badges/BadgeWithIcon'
import { BandScanningResult } from '@components/band-scanning/models'

interface Props {
  label: React.ReactNode
  scanningResult: BandScanningResult | undefined | null
}

export const BandScanningError = ({ label, scanningResult }: Props): JSX.Element => (
  <div className="d-flex align-items-center">
    <div className="band__scanning-guest__action">
      <BadgeWithIcon icon="mdi mdi-block-helper font-11" variant="danger" />
    </div>
    <div>{label}</div>
    {scanningResult?.error && (
      <div className="font-10 ml-auto text-danger fw-semi-bold col-4 px-0">
        {scanningResult.error || 'Wystąpił błąd kodowania opaski'}
      </div>
    )}
  </div>
)
