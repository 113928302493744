import * as React from 'react'
import { HouseKeepingUserReportFiltersParams } from '@modules/reports/housekeeping-users-report'
import { secondsToInterval, toDefaultDateTimeFormat } from '@helpers/date-helper'
import classNames from 'classnames'
import Table, { TableHeaderValue } from '@components/table/table'
import { IconWithText } from '@components/icon-with-text'
import { HouseKeepingCleaningOrder } from '@modules/reports/housekeeping-users-report/models'
import { HousekeepingUser } from '@models/reports'
import { HousekeepingUserReportApartmentCell } from '@modules/reports/housekeeping-user-report/housekeeping-user-report-apartment-cell'

interface HousekeepingUserReportTableProps {
  user: HousekeepingUser
  isLoading: boolean
  cleaningOrders: HouseKeepingCleaningOrder[]
  filters: HouseKeepingUserReportFiltersParams
  setFilters: (filters: HouseKeepingUserReportFiltersParams) => void
}

const headerValues: TableHeaderValue[] = [
  {
    sortField: 'apartment_id',
    title: 'Lokal',
  },
  {
    sortField: 'resort',
    title: 'Ośrodek',
  },
  {
    sortField: 'service_kind',
    title: 'Zlecenie',
  },
  {
    sortField: 'ordered_at',
    title: 'Data zlecenie',
  },
  {
    sortField: 'work_started_at',
    title: 'Rozpoczęcie',
  },
  {
    sortField: 'finished_at',
    title: 'Zakończenie',
  },
  {
    sortField: 'total_working_time',
    title: 'Czas pracy',
  },
  {
    sortField: 'is_finished_in_assumed_time',
    title: 'W zakładanym czasie pracy',
  },
]

export const HousekeepingUserReportTable: React.FC<HousekeepingUserReportTableProps> = ({
  user,
  filters,
  setFilters,
  isLoading,
  cleaningOrders,
}) => (
  <Table
    className="table-hover w-100"
    headerValues={headerValues}
    isLoading={isLoading}
    filters={filters}
    setFilters={setFilters}
    showPagination={false}
  >
    {cleaningOrders.map(row => (
      <Table.Row key={row.id}>
        <HousekeepingUserReportApartmentCell user={user} cleaningOrder={row} />
        <Table.Cell>{row.resort}</Table.Cell>
        <Table.Cell>{row.service_name}</Table.Cell>
        <Table.Cell>{toDefaultDateTimeFormat(row.ordered_at)}</Table.Cell>
        <Table.Cell>
          <IconWithText
            icon="text-secondary uil-arrow-down-right font-16"
            text={toDefaultDateTimeFormat(row.work_started_at) || 'brak'}
          />
        </Table.Cell>
        <Table.Cell>
          <IconWithText
            icon="text-secondary uil-arrow-down-right font-16"
            text={toDefaultDateTimeFormat(row.finished_at) || 'brak'}
          />
        </Table.Cell>
        <Table.Cell>
          <IconWithText icon="text-secondary uil-stopwatch font-16" text={secondsToInterval(row.total_working_time)} />
        </Table.Cell>
        <Table.Cell className="text-center">
          <span
            className={classNames('badge font-11', {
              'badge-success-lighten': row.is_finished_in_assumed_time,
              'badge-danger-lighten': !row.is_finished_in_assumed_time,
            })}
          >
            <i
              className={classNames('mr-1', {
                'uil-check-circle text-success': row.is_finished_in_assumed_time,
                'uil-ban text-danger': !row.is_finished_in_assumed_time,
              })}
            />
            <span className="font-weight-bold">{row.is_finished_in_assumed_time ? 'tak' : 'nie'}</span>
          </span>
        </Table.Cell>
      </Table.Row>
    ))}
  </Table>
)
