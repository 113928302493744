import * as React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { defaultFrontendTime, formatDate, parseISODate } from '@helpers/date-helper'
import { PaymentDayUserCloseReport, PaymentDayUserReport } from '@models/payments'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectGet } from '@store/actions/generic-actions'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { UserCashBoxCloseDayCash } from '@modules/payment-registers/user-cashbox/close-day-modal/cash'
import { UserCashBoxCloseDayCards } from '@modules/payment-registers/user-cashbox/close-day-modal/cards'
import { UserCashBoxCloseDaySummary } from '@modules/payment-registers/user-cashbox/close-day-modal/summary'
import { PaymentRegisterWebSocketHandler } from '@modules/payment-registers/list/payment-register-web-socket-handler'
import { startOfToday } from 'date-fns'

interface Props {
  paymentDayUserReport: PaymentDayUserReport
}

export const PaymentRegisterUserCashBoxSummary = ({ paymentDayUserReport }: Props): JSX.Element => {
  const [dayCloseReport, setDayCloseReport] = React.useState<PaymentDayUserCloseReport>()

  const fetch = async () =>
    setDayCloseReport(await commonObjectGet<PaymentDayUserCloseReport>(paymentDayUserReport.urls.close))

  const { isLoading, action: fetchDayReport } = useApiRequest(async () => await fetch())

  React.useEffect(() => {
    fetchDayReport()
  }, [paymentDayUserReport])

  const date = parseISODate(paymentDayUserReport.day_start) || startOfToday()

  return (
    <Card>
      <CardBody>
        <h4 className="text-secondary mb-1">Podsumowanie dnia {formatDate(date, 'dd.MM')}</h4>
        <small className="d-block mb-2">
          od godziny {formatDate(paymentDayUserReport.day_start, defaultFrontendTime)}
        </small>
        <PaymentRegisterWebSocketHandler onEvent={fetch} />

        <LoaderPlaceholder content={!isLoading}>
          {dayCloseReport && (
            <Row>
              <Col md={12} className="d-flex justify-content-between">
                <UserCashBoxCloseDayCash
                  paymentDayUserReport={paymentDayUserReport}
                  readOnly={true}
                  dayCloseReport={dayCloseReport}
                />
                <UserCashBoxCloseDayCards
                  readOnly={true}
                  paymentDayUserReport={paymentDayUserReport}
                  dayCloseReport={dayCloseReport}
                />
              </Col>
              <UserCashBoxCloseDaySummary dayCloseReport={dayCloseReport} />
            </Row>
          )}
        </LoaderPlaceholder>
      </CardBody>
    </Card>
  )
}
