import * as React from 'react'
import { createSelectOption } from '@helpers/utils'
import { FormSelect } from '@hyper/forms/form-select'

export const HousekeepingKindOptions = [
  ['ARRIVAL', 'Przyjazd'],
  ['DEPARTURE', 'Wyjazd'],
  ['DEPARTURE_ARRIVAL', 'Przyjazd/Wyjazd'],
]

const selectOptions = HousekeepingKindOptions.map(([key, name]) => createSelectOption(name, key))

export const HousekeepingOrderTableHeaderKind = (): JSX.Element => (
  <th className="py-1 border-top-0 fw-semi-bold" style={{ width: 250 }}>
    <FormSelect
      options={selectOptions}
      selectProps={{ placeholder: 'Rodzaj', isClearable: true, isSearchable: false }}
      name="kind"
      formPlainProps={{ formGroupClassName: 'mb-0', colClassName: 'px-0' }}
    />
  </th>
)
