import * as React from 'react'
import * as R from 'ramda'
import classNames from 'classnames'
import { PageTitle } from '@hyper/page-title'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { Link, useParams } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import Timeline from '@modules/reservations/timeline/timeline'
import dateHelper, { formatDate } from '@helpers/date-helper'
import { lastDayOfMonth, parseISO } from 'date-fns'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import TimelineCompactSwitcher from '@modules/reservations/timeline/timeline-compact-switcher'
import { useApartments } from '@components/hooks/use-apartments'
import { CalendarDate } from '@store/reducers/timeline-reducers'
import TimelineActions from '@modules/reservations/timeline/timeline-actions'
import { TopBarLoading } from '@components/topbar-loading'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { UserPermission } from '@models/dashboard'
import { useAppData } from '@components/hooks/use-app-data'
import { getBookingsData } from '@store/actions/timeline-actions'
import {
  GroupedAccommodationType,
  useGroupedAccommodationTypes,
} from '@modules/reservations/use-grouped-accommodation-types'

interface HistoryParams {
  resort_id: string
  accommodation_type_id: string
}

export const ReservationView: React.FC = () => {
  const { resort_id, accommodation_type_id } = useParams() as unknown as HistoryParams

  useMenuActive(NavigationPath.Reservations)

  const user = useAuthenticatedUser()
  const { resorts: appDataResort } = useAppData()

  const [isFetchingBookings, setFetchingBookings] = React.useState(true)

  const dispatch = useAppDispatch()
  const resorts = user.hasPerm(UserPermission.BookingCalendarCanViewAllResorts) ? appDataResort : user.resorts

  const activeResort = resorts.find(resort => resort.id.toString() === resort_id)
  const { groupedAccommodationTypes } = useGroupedAccommodationTypes(activeResort)

  const accommodationTypeIds = React.useMemo(() => accommodation_type_id.split('-'), [accommodation_type_id])

  const { isLoading: isFetchingApartments, fetchApartments } = useApartments(resort_id, accommodationTypeIds, false)

  const calendarDates = useAppSelector((state: RootState) => state.timelineState.calendarDates.dates)

  React.useEffect(() => {
    const asyncDispatch = async () => {
      setFetchingBookings(true)
      const lastCalendarDate: CalendarDate = R.last(calendarDates) as CalendarDate
      await fetchApartments()
      await dispatch(
        getBookingsData(
          resort_id,
          accommodationTypeIds,
          formatDate(dateHelper.createDateFormat(calendarDates[0].year, calendarDates[0].month, 1), 'yyyy-MM-01'),
          formatDate(
            lastDayOfMonth(parseISO(dateHelper.createDateFormat(lastCalendarDate.year, lastCalendarDate.month, 1))),
          ),
        ),
      )
      setFetchingBookings(false)
    }

    if (calendarDates.length) {
      asyncDispatch()
    }
  }, [calendarDates, resort_id, accommodation_type_id])

  const isAccommodationSelected = (accommodation: GroupedAccommodationType) =>
    accommodation.id === accommodation_type_id

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: 'Rezerwacje', path: '', active: true }]} title="Rezerwacje" />
      <div className="card" style={{ marginBottom: '-60px' }} role="card">
        <div className="card-body">
          <ul className="nav nav-tabs nav-bordered">
            {resorts.map(resort => (
              <li className="nav-item" key={resort.id}>
                <Link
                  to={`${NavigationPath.Reservations}/${resort.id}/${groupedAccommodationTypes[0].id}/`}
                  className={classNames('nav-link py-2', {
                    active: resort.id.toString() === resort_id,
                  })}
                >
                  {resort.name}
                </Link>
              </li>
            ))}
            <TimelineCompactSwitcher />
          </ul>
          <div className="pt-2 pb-1">
            <div className="btn-group w-100" role="group">
              {groupedAccommodationTypes.map(accommodation => (
                <Link
                  key={accommodation.id}
                  to={`${NavigationPath.Reservations}/${resort_id}/${accommodation.id}`}
                  className={`btn text-semi-strong calendar__house-type-button d-flex align-items-center  justify-content-center ${
                    isAccommodationSelected(accommodation) ? 'btn-secondary' : 'btn-inactive'
                  }`}
                >
                  {accommodation.name}
                </Link>
              ))}
            </div>
          </div>
          <TimelineActions resortId={resort_id} />
          <span className="calendar__today-full-date">{formatDate(new Date(), 'iii d MMMM yyyy')}</span>
          {(isFetchingApartments || isFetchingBookings) && <TopBarLoading />}
          <Timeline accommodationTypeIds={accommodationTypeIds} resort={resort_id} />
        </div>
      </div>
    </>
  )
}
