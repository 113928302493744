import * as React from 'react'
import { PaymentRegister } from '@models/payments'
import Table from '@components/table/table'
import { RootState, useAppSelector } from '@store/index'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'

interface Props {
  paymentRegister: PaymentRegister
}

export const PaymentRegisterTableRowUser = ({ paymentRegister }: Props): JSX.Element => {
  const workstations = useAppSelector((state: RootState) => state.appState.appData.reception_workstations)
  const { addSuccessNotification } = useNotificationHook()

  const workStation = React.useMemo(
    () => workstations.find(row => row.id === paymentRegister.reception_workstation_id)?.name,
    [paymentRegister.reception_workstation_id, workstations],
  )

  const { action: handlePrint } = useApiRequest(async () => {
    if (paymentRegister.urls.print) {
      await commonObjectPost(paymentRegister.urls.print)
      addSuccessNotification('Wydruk został zlecony')
    }
  })

  return (
    <Table.Cell>
      {paymentRegister.urls.print && <i className="uil uil-print mr-1 cursor-pointer" onClick={handlePrint} />}

      {paymentRegister.created_by || paymentRegister.user}
      {workStation && <span className="font-11 d-block mt-1">({workStation})</span>}
    </Table.Cell>
  )
}
