import * as React from 'react'
import Table from '@components/table/table'
import { PriceBlock } from '@modules/reservations/list/reservation-list-payment-summary'
import { formatPrice } from '@helpers/utils'
import { useSelector } from 'react-redux'
import { selectCafeteriaAggregation } from '@store/slices/cafeteria-slice'

export const CafeteriaListTableFooter = () => {
  const aggregation = useSelector(selectCafeteriaAggregation)

  return (
    <Table.Row>
      <Table.Cell colSpan={4} className="text-right align-middle reservation-list__summary-cell">
        <strong className="mr-2">Razem w wybranym okresie:</strong>
      </Table.Cell>
      <Table.Cell className="reservation-list__summary-cell">
        <PriceBlock title="Łączna kwota:" price={formatPrice(aggregation?.residence_brutto_sum ?? 0)} />
      </Table.Cell>
      <Table.Cell colSpan={1} className="reservation-list__summary-cell" />
    </Table.Row>
  )
}
