import * as React from 'react'
import { FormPlain, FormPlainProps } from '@hyper/forms/index'
import { CustomReactSelect, CustomReactSelectProps } from '@components/custom-react-select'
import { LabelProps } from 'reactstrap/es/Label'
import { OptionsOrGroups } from 'react-select'

export interface FormSelectProps {
  label?: React.ReactNode
  options: OptionsOrGroups<any, any>
  formPlainProps?: Partial<FormPlainProps>
  labelProps?: LabelProps
  name: string
  selectProps?: Partial<CustomReactSelectProps>
  labelSize?: number
}

export const FormSelect = ({
  label,
  formPlainProps,
  name,
  options,
  selectProps,
  labelProps,
  labelSize,
}: FormSelectProps): JSX.Element => (
  <FormPlain name={name} hideErrors {...formPlainProps} labelSize={labelSize} label={label} labelProps={labelProps}>
    <CustomReactSelect
      fieldName={name}
      options={options}
      noOptionsMessage={noOptionsMessage}
      {...selectProps}
      label=""
    />
  </FormPlain>
)

const noOptionsMessage = () => 'Brak opcji'
