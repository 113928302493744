import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { Button, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { useAppDispatch } from '@store/index'
import { useForm, useWatch } from 'react-hook-form'
import { endOfMonth, startOfMonth, startOfToday } from 'date-fns'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { createReportTask } from '@store/actions/report-tasks-actions'
import { Form } from '@hyper/forms/form'
import { FormSelect } from '@hyper/forms/form-select'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { createSelectOption, extractSelectOptionsValues } from '@helpers/utils'
import { SaveButton } from '@hyper/button'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { useMultipleResorts } from '@components/hooks/use-multiple-resorts'
import { useAppData } from '@components/hooks/use-app-data'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { useSelectWithAllOption } from '@helpers/use-select-with-all-option'
import { useTechnicalOrderTypesSelectOptions } from '@modules/technical-orders/hooks/use-technical-order-types-select-options'

interface FormInputs {
  date_before: Date
  date_after: Date
  resorts: CustomReactSelectOption[]
  unit_kinds: CustomReactSelectOption[]
  technical_order_types: CustomReactSelectOption[]
}

export const ReportTechnicalOrdersModal: React.FC<BaseModalProps> = ({ toggleIsVisible }) => {
  const { units, urls } = useAppData()

  const dispatch = useAppDispatch()

  const orderTypesOptions = useTechnicalOrderTypesSelectOptions()

  const methods = useForm<FormInputs>({
    mode: 'all',
    defaultValues: {
      date_after: startOfMonth(startOfToday()),
      date_before: endOfMonth(startOfToday()),
      unit_kinds: [createSelectOption('Wszystkie', 'all')],
      technical_order_types: [createSelectOption('Wszystkie', 'all')],
      resorts: [createSelectOption('Wszystkie', 'all')],
    },
  })

  const { getResortOptions, getSelectedResortsIds } = useMultipleResorts({ methods, field: 'resorts' })

  const resortOptions = getResortOptions({ withAllOption: true })
  const selectedResorts = useWatch({ control: methods.control, name: 'resorts' })

  const unitKindsOptions = React.useMemo(
    () =>
      units.reduce((options, unit) => {
        const selectedResortIds = getSelectedResortsIds(selectedResorts)

        if (
          selectedResortIds.includes(unit.resort_id) &&
          !options.some((option: CustomReactSelectOption) => option.value === unit.kind_id)
        ) {
          return [...options, ...(unit.kind_id ? [createSelectOption(unit.kind, unit.kind_id)] : [])]
        }

        return options
      }, []),
    [selectedResorts, getSelectedResortsIds, units],
  )

  const { options: allUnitKindsOptions, selectedOptionsValues: selectedUnitKinds } = useSelectWithAllOption(
    unitKindsOptions,
    methods,
    'unit_kinds',
  )

  const { options: allTechnicalOrderTypesOptions, selectedOptionsValues: selectedTechnicalOrderTypes } =
    useSelectWithAllOption(orderTypesOptions, methods, 'technical_order_types')

  useDidUpdateEffect(() => {
    methods.setValue('unit_kinds', [])
  }, [selectedResorts])

  const { isLoading, action: onSubmit } = useReportFormRequest(async (payload: FormInputs) => {
    const reportTask = await dispatch(
      createReportTask([
        urls.reports.report_task_technical_order_report,
        {
          ...extractSelectOptionsValues(payload),
          resorts: getSelectedResortsIds(payload.resorts),
          unit_kinds: selectedUnitKinds,
          technical_order_types:
            payload.technical_order_types.length === 1 && payload.technical_order_types[0].value === 'all'
              ? []
              : selectedTechnicalOrderTypes,
        },
      ]),
    )
    toggleIsVisible()
    return reportTask
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Raport ulepszonych kodów</ModalHeader>
      <ModalBody>
        <Row>
          <DateFromToInput
            startDateName="date_after"
            endDateName="date_before"
            label="Data zamknięcia zdania"
            isClearable={false}
          />
          <FormSelect options={resortOptions} name="resorts" selectProps={{ isSelectMulti: true }} label="Resorty" />
          <FormSelect
            options={allUnitKindsOptions}
            name="unit_kinds"
            label="Rodzaje jednostek"
            selectProps={{ isSelectMulti: true }}
          />
          <FormSelect
            options={allTechnicalOrderTypesOptions}
            name="technical_order_types"
            label="Typy usterek"
            selectProps={{ isSelectMulti: true }}
          />
        </Row>
      </ModalBody>

      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" label="Zleć raport" labelSaving="Zlecanie.." isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
