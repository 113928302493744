import * as React from 'react'
import { SubscriptionContractDetails, SubscriptionContractPromocode } from '@modules/subscription-contract/models'
import Table from '@components/table/table'
import { YesNoBadge } from '@components/badges/yes-no'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { useAppDispatch } from '@store/index'
import { updateSubscriptionContractDetails } from '@store/slices/subscription-contract-slice'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useApiRequest } from '@components/hooks/use-api-request'
import Spinner from '@hyper/spinner'

interface Props {
  promocode: SubscriptionContractPromocode
}

export const SubscriptionContractFeaturesTableRowPromocodeIsUsed = ({ promocode }: Props): JSX.Element => {
  const user = useAuthenticatedUser()
  const canRenewPromocode =
    promocode.is_used && user.hasPerm(UserPermission.PromotionsSubscriptionContractCanRenewPromocode)
  const dispatch = useAppDispatch()

  const { action: handleRenewCode, isLoading } = useApiRequest(async () => {
    dispatch(
      updateSubscriptionContractDetails(await commonObjectPost<SubscriptionContractDetails>(promocode.urls.renew)),
    )
  })

  return (
    <Table.Cell className="vertical-align-middle">
      {isLoading ? (
        <Spinner size="xs" color="white" className="inline-spinner mr-1" />
      ) : (
        <YesNoBadge value={promocode.is_used} onClick={canRenewPromocode ? handleRenewCode : undefined} />
      )}
    </Table.Cell>
  )
}
