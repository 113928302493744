import { Seller } from '@models/crm'
import {
  CrmActionTypesKeys,
  SetClientDetailsAction,
  SetClientsAction,
  SetSellersAction,
  SetSellersStatusAction,
} from '@store/actions-types/crm-actions-types'
import { RootState } from '@store/index'
import { setDashboardStandardPaginator } from '@store/actions/dashboard-actions'
import { SetDashboardStandardPaginatorAction } from '@store/actions-types/dashboard-actions-types'
import { commonObjectGet } from '@store/actions/generic-actions'
import { formatDate } from '@helpers/date-helper'
import { PaginationResponse } from '@models/dashboard'
import { ClientDetails, ClientUser } from '@models/clients'
import { CrmClientsFiltersParams } from '@modules/crm/clients/list-view/filters'
import { SellerListFilterParams } from '@modules/seller/list/filter'
import { ThunkAction } from 'redux-thunk'

export function getSellers(): ThunkAction<Promise<void>, RootState, null, SetSellersAction | SetSellersStatusAction> {
  return async (dispatch, getState) => {
    const sellersStatus = getState().crmState.sellersStatus
    const sellersUrl = getState().appState.appData.urls.crm.sellers

    if (sellersStatus === 'unknown' && sellersUrl) {
      await dispatch(setSellersStatus('loading'))

      const data = await commonObjectGet<PaginationResponse<Seller>>(sellersUrl, {
        page_size: 10000,
      })
      await dispatch(setSellers(data.results))
    }
  }
}

export function getSellerList(
  filters?: SellerListFilterParams | undefined,
): ThunkAction<Promise<void>, RootState, null, SetSellersAction | SetDashboardStandardPaginatorAction> {
  return async (dispatch, getState) => {
    const sellersUrl = getState().appState.appData.urls.crm.sellers

    const formatFilters = filters ? { ...filters, department: filters?.department?.value } : {}

    const data = await commonObjectGet<PaginationResponse<Seller>>(sellersUrl, {
      ...formatFilters,
    })
    await dispatch(setSellers(data.results))
    dispatch(setDashboardStandardPaginator({ ...data }))
  }
}

export function setSellers(payload: Seller[]): SetSellersAction {
  return {
    type: CrmActionTypesKeys.SET_SELLERS,
    payload,
  }
}

export function setSellersStatus(payload: 'unknown' | 'ready' | 'loading'): SetSellersStatusAction {
  return {
    type: CrmActionTypesKeys.SET_SELLERS_STATUS,
    payload,
  }
}

export function setCrmClients(payload: ClientUser[]): SetClientsAction {
  return {
    type: CrmActionTypesKeys.SET_CLIENTS,
    payload,
  }
}

export function getCrmClients(
  filters?: CrmClientsFiltersParams,
  ids?: string[] | string,
): ThunkAction<Promise<void>, RootState, null, SetClientsAction | SetDashboardStandardPaginatorAction> {
  return async (dispatch, getState) => {
    const url = getState().appState.appData.urls.clients.clients

    const data = await commonObjectGet<PaginationResponse<ClientUser>>(url, {
      ...filters,
      id: ids,
      is_blacklisted: filters?.is_blacklisted || undefined,
      date_joined_after: formatDate(filters?.date_joined_after),
      date_joined_before: formatDate(filters?.date_joined_before),
      source_marketing_id: filters?.source_marketing_id?.value,
      last_activity: filters?.last_activity?.value,
    })
    dispatch(setDashboardStandardPaginator(data))

    await dispatch(setCrmClients(data.results))
  }
}

export function setClientDetails(payload: ClientDetails | undefined): SetClientDetailsAction {
  return {
    type: CrmActionTypesKeys.SET_CLIENT_DETAILS,
    payload,
  }
}

export function getClientDetails(url: string): ThunkAction<Promise<void>, RootState, null, SetClientDetailsAction> {
  return async dispatch => {
    await dispatch(setClientDetails(await commonObjectGet<ClientDetails>(url)))
  }
}

export function updateClientDetails(
  payload: ClientDetails,
  addToList?: boolean,
): ThunkAction<Promise<void>, RootState, null, SetClientDetailsAction | SetClientsAction> {
  return async (dispatch, getState) => {
    const clients = getState().crmState.clients
    const clientDetails = getState().crmState.clientDetails

    if (clientDetails?.id === payload.id) {
      dispatch(setClientDetails(payload))
    }

    if (addToList && !clients.some(row => row.id === payload.id)) {
      dispatch(setCrmClients([payload, ...clients]))
    } else {
      dispatch(setCrmClients(clients.map(row => (row.id === payload.id ? payload : row))))
    }
  }
}

export function updateSellerDetails(payload: Seller): ThunkAction<Promise<void>, RootState, null, SetSellersAction> {
  return async (dispatch, getState) => {
    const sellers = getState().crmState.sellers

    if (!sellers.some(row => row.id === payload.id)) {
      dispatch(setSellers([payload, ...sellers]))
    } else {
      dispatch(setSellers(sellers.map(row => (row.id === payload.id ? payload : row))))
    }
  }
}

export function deleteSeller(payload: Seller): ThunkAction<void, RootState, null, SetSellersAction> {
  return async (dispatch, getState) => {
    const sellers = getState().crmState.sellers

    dispatch(setSellers(sellers.filter(row => row.id !== payload.id)))
  }
}
