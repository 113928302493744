import * as React from 'react'
import { barrierTicketsSelector } from '@store/slices/barrier-slice'
import Table, { TableHeaderValue } from '@components/table/table'
import { BarrierTicket } from '@models/barriers'
import { BarriersBaseListTableRow } from '@modules/barriers/base-list/table-row'
import { BarriersBaseListFilterParams } from '@modules/barriers/base-list/filter'
import { useAppSelector } from '@store/index'

interface Props {
  isLoading: boolean
  filters: BarriersBaseListFilterParams
  setFilters: (filters: BarriersBaseListFilterParams) => void
  name: string
  canChange: boolean
  canRemove: boolean
  canPrint: boolean
}

const headerValues: TableHeaderValue<BarrierTicket>[] = [
  { title: 'Numer rejestracyjny', sortField: 'register_number' },
  { title: 'Typ' },
  { title: 'Ośrodek' },
  { title: 'Dostępne w dniach', sortField: 'datetime_from' },
  { title: 'Opis' },
  { title: '' },
]

export const BarriersBaseListTable = ({
  filters,
  name,
  isLoading,
  setFilters,
  canChange,
  canRemove,
  canPrint,
}: Props): JSX.Element => {
  const tickets = useAppSelector(barrierTicketsSelector)

  const filteredTickets = filters.only_active_cars ? tickets.filter(row => row.is_active) : tickets

  return (
    <Table
      className="table-hover w-100"
      headerValues={headerValues}
      isLoading={isLoading}
      filters={filters}
      setFilters={setFilters}
    >
      {filteredTickets.map(ticket => (
        <BarriersBaseListTableRow
          name={name}
          key={ticket.id}
          ticket={ticket}
          canChange={canChange}
          canRemove={canRemove}
          canPrint={canPrint}
        />
      ))}
    </Table>
  )
}
