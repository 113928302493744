import * as React from 'react'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { YesNoBadge } from '@components/badges/yes-no'
import { formatPrice } from '@helpers/utils'
import { WalletTransaction, WalletTransactionBillItem } from '@models/reception'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectDelete, commonObjectPost } from '@store/actions/generic-actions'
import classnames from 'classnames'
import { useAppDispatch } from '@store/index'
import { setReservationWalletTransactions } from '@store/slices/reservations-slice'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import Spinner from '@hyper/spinner'

interface Props {
  item: WalletTransactionBillItem
  selectableCell: (row: WalletTransactionBillItem) => React.ReactElement
}

export const ReservationRoomBillTransactionsListModalRow = ({ item, selectableCell }: Props): JSX.Element => {
  const { addSuccessNotification } = useNotificationHook()

  const dispatch = useAppDispatch()

  const { action: handleDelete, isLoading } = useApiRequest(async () => {
    dispatch(setReservationWalletTransactions(await commonObjectDelete<WalletTransaction[]>(item.urls.details)))
    addSuccessNotification('Pozycja została usunięta.')
  })

  const { action: handleRestore } = useApiRequest(async () => {
    dispatch(setReservationWalletTransactions(await commonObjectPost<WalletTransaction[]>(item.urls.details)))
    addSuccessNotification('Pozycja została przywrócona.')
  })

  const { isLoading: isLoadingPayByVoucher, action: handlePayByVoucher } = useApiRequest(async () => {
    if (item.is_paid_by_client_wallet) return
    dispatch(setReservationWalletTransactions(await commonObjectPost<WalletTransaction[]>(item.urls.pay_by_voucher)))
    addSuccessNotification('Pozycja została opłacona.')
  })

  return (
    <tr>
      {selectableCell(item)}
      <td className="py-2 align-middle">
        <span className={classnames({ 'text-strikethrough text-danger': item.deleted })}>{item.name}</span>
        {item.deleted && <span className="font-11 d-block">Usunięte przez: {item.deleted_by}</span>}
      </td>
      <td className="py-2 align-middle">
        <span className="text-nowrap">{toDefaultDateTimeFormat(item.date)}</span>
      </td>
      <td className="py-2 align-middle text-center">
        <YesNoBadge value={item.is_paid} />
      </td>
      <td className="py-2 align-middle text-center">
        {isLoadingPayByVoucher ? (
          <Spinner size="xxs" />
        ) : (
          <YesNoBadge
            value={item.is_paid_by_client_wallet}
            onClick={handlePayByVoucher}
            className={classnames('cursor-pointer', { 'cursor-default': item.is_paid_by_client_wallet })}
          />
        )}
      </td>
      <td className="py-2 align-middle">{item.vat_rate}%</td>
      <td className="py-2 align-middle">
        {item.quantity} {item.unit}
      </td>
      <td className="py-2 align-middle">{formatPrice(item.price)}</td>
      <td className="py-2 align-middle">
        <div className="d-flex">
          {item.deleted ? (
            <CommonObjectConfirmAction
              isLoading={isLoading}
              message="Czy na pewno chcesz przywrócić tę pozycję?"
              handleAccept={handleRestore}
            >
              <i className="uil-history-alt cursor-pointer" />
            </CommonObjectConfirmAction>
          ) : (
            <CommonObjectConfirmAction
              isLoading={isLoading}
              message="Czy na pewno chcesz usunąć tę pozycję?"
              handleAccept={handleDelete}
            >
              <i className="uil-trash-alt cursor-pointer" />
            </CommonObjectConfirmAction>
          )}
        </div>
      </td>
    </tr>
  )
}
