import { Row } from 'reactstrap'
import * as React from 'react'
import classnames from 'classnames'

interface SaleSummarySectionProps {
  title: React.ReactNode
  className: string
  children: React.ReactNode
}

export const PackageSummarySection = ({ title, className, children }: SaleSummarySectionProps) => (
  <div className={className}>
    <h5 className="font-weight-bold">{title}</h5>
    {children}
  </div>
)

interface SaleSummaryDataRowProps {
  title: React.ReactNode
  content?: React.ReactNode
  className?: string
  titleFlex?: string
  contentFlex?: string
  children?: React.ReactNode
}

export const PackageSummarySectionDataRow = ({
  title,
  titleFlex,
  contentFlex,
  content,
  children,
  className,
}: SaleSummaryDataRowProps) => (
  <Row className={className}>
    <p className={classnames('mb-1', titleFlex ?? 'col-6')}>{title}</p>
    <div className={classnames('mb-1 font-weight-bold text-nowrap', contentFlex ?? 'col-6')}>{children || content}</div>
  </Row>
)
