import * as React from 'react'
import { ReportTask } from '@models/reports'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAppDispatch } from '@store/index'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { updateUserReportTask } from '@store/slices/report-task-slice'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'

interface Props {
  reportTask: ReportTask
}
export const UserReportsTasksTableRowActionsCancel = ({ reportTask }: Props): JSX.Element => {
  const dispatch = useAppDispatch()

  const { addSuccessMessage } = useNotificationHook()

  const handleAccept = async () => {
    dispatch(updateUserReportTask(await commonObjectDelete<ReportTask>(reportTask.urls.details)))
    addSuccessMessage('Sukces', 'Raport został skasowany')
  }

  return (
    <CommonObjectConfirmAction handleAccept={handleAccept}>
      <i className="uil uil-trash-alt cursor-pointer text-muted" />
    </CommonObjectConfirmAction>
  )
}
