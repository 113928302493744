import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import classNames from 'classnames'
import { useKey } from 'rooks'
import { BandScanningStatus } from '@components/band-scanning/models'

interface Props {
  isScanning: boolean
  pulse: boolean
  onScan: () => void
  globalScanningStatus: BandScanningStatus
  nextScanDate: Date | null
  progressText: string
  isWithProgress: boolean
  disabled: boolean
}

export const BandScanningSubmit = ({
  isScanning,
  onScan,
  pulse,
  globalScanningStatus,
  isWithProgress,
  progressText,
  disabled,
}: Props): JSX.Element => {
  const handleSpaceKeydown = (event: KeyboardEvent) => {
    if (event.repeat) return
    event.preventDefault()
    onScan()
  }

  useKey('Space', handleSpaceKeydown, { when: !isScanning })

  const submitText = () => {
    if (globalScanningStatus === 'error') return 'Ponów kodowanie'
    if (isWithProgress) return progressText
    return 'Rozpocznij kodowanie'
  }

  return (
    <div>
      <button
        type="button"
        onClick={onScan}
        className={classNames('band__scanning-guest__submit p-2 rounded', {
          pulse: (pulse || isWithProgress) && !isScanning,
          'is-progress': isWithProgress,
          'not-clickable': disabled,
        })}
      >
        <IconWithText icon="uil-capture font-18 text-white" text={submitText()} textClass="text-white" />
        <div className="d-inline-block bg-white rounded px-1 font-10 text-default">Spacja</div>
      </button>
    </div>
  )
}
