import * as React from 'react'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'

interface Props {
  accepted_by: string
  accepted_at: string
}

export const HousekeepingCleaningOrderAcceptedByRow = React.memo(({ accepted_at, accepted_by }: Props) => (
  <td className="housekeeping__orders_table__column text-nowrap">
    {accepted_by}
    <div className="font-12">{accepted_at && `(${toDefaultDateTimeFormat(accepted_at)})`}</div>
  </td>
))
