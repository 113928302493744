import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { PromocodePrefixDialogDates } from '@modules/promocode/promocode-prefixes/promocode-prefix-dialog/promocode-prefix-dialog-dates'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { PromocodePrefix, PromocodePrefixDate } from '@models/promocode'
import { FormProvider, useForm } from 'react-hook-form'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { PromocodePrefixDialogForm } from '@modules/promocode/promocode-prefixes/promocode-prefix-dialog/promocode-prefix-dialog-form'
import { commonObjectPatch, commonObjectPost } from '@store/actions/generic-actions'
import { formatDate } from '@helpers/date-helper'
import { useFormRequest } from '@components/hooks/use-api-request'
import { BaseModalProps } from '@components/modals/types'
import { updatePromocodePrefixDetails } from '@store/slices/promocodes-slice'
import { useAppData } from '@components/hooks/use-app-data'
import { CustomReactSelectOption } from '@components/custom-react-select'

interface PromocodePrefixDialogProps extends BaseModalProps {
  promocodePrefix?: PromocodePrefix
}

export interface PromocodePrefixDialogFormInputs {
  is_disposable: boolean
  description: string
  name: string
  value: string
  dates_all: string
  dates: PromocodePrefixDate[]
  promo_type: string
  kind: string
  resorts: CustomReactSelectOption[]
  accommodation_types: string[]

  promo_value: number
  max_days_enabled: boolean
  max_days: number
}

export const PromocodePrefixDialog: React.FC<PromocodePrefixDialogProps> = ({ toggleIsVisible, promocodePrefix }) => {
  const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()
  const createUrl = useAppSelector((state: RootState) => state.appState.appData.urls.rent.promocode_prefixes)

  const { resorts } = useAppData()

  const resortsOptions: CustomReactSelectOption[] = resorts.map(resort => ({
    value: resort.id,
    label: resort.name,
  }))

  const methods = useForm<PromocodePrefixDialogFormInputs>({
    defaultValues: {
      is_disposable: true,
      ...promocodePrefix,
      resorts: resortsOptions.filter(row => promocodePrefix?.resorts.includes(row.value)),
      dates_all: '',
      max_days_enabled: promocodePrefix?.max_days !== 0,
      accommodation_types: promocodePrefix?.accommodation_types.map(row => String(row)),
    },
  })

  const { isLoading, action: onSubmit } = useFormRequest(async (data: PromocodePrefixDialogFormInputs) => {
    const payload = {
      ...data,
      resorts: data.resorts.map((row: CustomReactSelectOption) => row.value),
      max_days: data.max_days_enabled ? data.max_days : 0,
      dates: data?.dates.map((d: PromocodePrefixDate) => ({
        date_from: formatDate(d.date_from),
        date_to: formatDate(d.date_to),
      })),
    }

    const promocodePrefixDetails = promocodePrefix
      ? await commonObjectPatch<PromocodePrefix>(promocodePrefix.urls.update, payload)
      : await commonObjectPost<PromocodePrefix>(createUrl, payload)

    dispatch(updatePromocodePrefixDetails(promocodePrefixDetails))

    if (promocodePrefix) {
      addSuccessMessage('Prefiks został zmieniony', 'Prefiks został zmieniony i jest już widoczny na liście prefiksów.')
    } else {
      addSuccessMessage('Prefiks został dodany', 'Utworzony prefiks jest już widoczny na liście prefiksów.')
    }
    toggleIsVisible()
  }, methods.setError)

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
        <ModalHeader toggle={toggleIsVisible}>{promocodePrefix ? 'Edytuj prefiks' : 'Wygeneruj prefix'}</ModalHeader>
        <ModalBody className="pb-0">
          <PromocodePrefixDialogForm resortsOptions={resortsOptions} />
          <PromocodePrefixDialogDates />
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={toggleIsVisible}>
            Anuluj
          </Button>
          <SaveButton label="Zapisz" className="ml-1 btn-green btn" isSaving={isLoading} />
        </ModalFooter>
      </form>
    </FormProvider>
  )
}
