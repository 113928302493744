import * as React from 'react'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { FormProvider, useForm } from 'react-hook-form'
import { Row } from 'reactstrap'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { ColAuto } from '@hyper/col-auto'
import { FormSelect } from '@hyper/forms/form-select'
import { createSelectOption } from '@helpers/utils'
import { BookingOffersFiltersParams } from '@modules/booking-offers/index'
import { useResortSelectOptions } from '@components/hooks/use-resort-select-options'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { RootState, useAppSelector } from '@store/index'
import declination from '@helpers/declination'

interface Props {
  filters: BookingOffersFiltersParams
  defaultFilters: BookingOffersFiltersParams
  setFilters: React.Dispatch<React.SetStateAction<BookingOffersFiltersParams>>
}

const daysOptions = [2, 3, 4, 5, 6, 7].map(days => createSelectOption(`${days} ${declination.night(days)}`, days))

export const BookingOffersFilters: React.FC<Props> = ({ filters, defaultFilters, setFilters }) => {
  const methods = useForm({
    defaultValues: defaultFilters,
  })
  const resortOptions = useResortSelectOptions()
  const accommodationTypes = useAppSelector((state: RootState) => state.appState.appData.accommodation_types)
  const accommodationTypesOptions: CustomReactSelectOption[] = accommodationTypes.map(row =>
    createSelectOption(row.name, row.id),
  )
  const onSubmit = async payload => setFilters({ ...filters, ...payload })

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  return (
    <Row>
      <FormProvider {...methods}>
        <ColAuto className="pl-0">
          <DateFromToInput label="Zakres dat" startDateName="date_from" endDateName="date_to" />
        </ColAuto>
        <ColAuto className="px-0">
          <FormSelect options={daysOptions} name="days" label="Długość pobytu" selectProps={{ isClearable: true }} />
        </ColAuto>
        <ColAuto style={{ width: 250 }} className="px-0">
          <FormSelect options={resortOptions} name="resort" label="Ośrodek" selectProps={{ isClearable: true }} />
        </ColAuto>
        <ColAuto style={{ width: 300 }} className="px-0">
          <FormSelect
            options={accommodationTypesOptions}
            name="accommodation_type"
            label="Zakwaterowanie"
            selectProps={{ isSelectMulti: true, isClearable: true }}
          />
        </ColAuto>
      </FormProvider>
    </Row>
  )
}
