import * as React from 'react'
import { useAppData } from '@components/hooks/use-app-data'
import { CustomReactSelectOption, CustomReactSelectOptionGroup } from '@components/custom-react-select'
import { createSelectOption } from '@helpers/utils'

export const useUnitsSelectOptions = (
  resortId: number,
): {
  options: CustomReactSelectOptionGroup[]
  getSelectedOption: (unitId: number) => CustomReactSelectOption | undefined
} => {
  const { units, unit_kinds } = useAppData()

  const options: CustomReactSelectOptionGroup[] = React.useMemo(
    () =>
      unit_kinds.map(kind => ({
        label: kind.name,
        options: units
          .filter(row => row.kind_id === kind.id && row.resort_id === resortId)
          .map(row => createSelectOption(row.name, row.id)),
      })),
    [units, resortId],
  )

  const getSelectedOption = React.useCallback(
    (unitId: number) =>
      options
        .map(row => row.options)
        .flat()
        .find(row => row.value === unitId),
    [units],
  )

  return { options, getSelectedOption }
}
