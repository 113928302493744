import * as React from 'react'
import { PaymentRegister } from '@models/payments'
import Table from '@components/table/table'
import { toDefaultDateTimeFormatWithBrake } from '@helpers/date-helper'
import { asDecimal, formatPrice } from '@helpers/utils'
import Decimal from 'decimal.js'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { commonObjectPost } from '@store/actions/generic-actions'

interface Props {
  paymentRegister: PaymentRegister
  balanceBeforeOperation: Decimal
  lp: number
}

export const CashboxSafeModalTableRow = ({ lp, paymentRegister, balanceBeforeOperation }: Props): JSX.Element => {
  const { addSuccessNotification } = useNotificationHook()

  const { action: handlePrint } = useApiRequest(async () => {
    if (paymentRegister.urls.print) {
      await commonObjectPost(paymentRegister.urls.print)
      addSuccessNotification('Wydruk został zlecony')
    }
  })

  return (
    <Table.Row role="details-row">
      <Table.Cell>{lp}.</Table.Cell>
      <Table.Cell>{toDefaultDateTimeFormatWithBrake(paymentRegister.created, 'd-block font-10')}</Table.Cell>
      <Table.Cell>
        {paymentRegister.description || paymentRegister.kind_display}
        {paymentRegister.urls.print && (
          <i
            className="uil-print text-default cursor-pointer font-12 ml-1"
            title="Drukuj dokument"
            onClick={handlePrint}
          />
        )}
        <span className="font-11 d-block">{paymentRegister.type}</span>
      </Table.Cell>
      <Table.Cell>{paymentRegister.created_by || paymentRegister.user}</Table.Cell>
      <Table.Cell className="border-right border-left">
        {paymentRegister.type == 'KW' && formatPrice(Decimal.abs(asDecimal(paymentRegister.amount)).toString())}
      </Table.Cell>
      <Table.Cell className="border-right">
        {paymentRegister.type == 'KP' && formatPrice(paymentRegister.amount)}
      </Table.Cell>
      <Table.Cell className="text-right text-strong">{formatPrice(balanceBeforeOperation.toString())}</Table.Cell>
    </Table.Row>
  )
}
