import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '@store/index'
import { commonObjectGet } from '@store/actions/generic-actions'
import { CmsAccommodationTypeDetails, CmsImprovement, CmsResortDetails, TextBlock } from '@modules/cms/models'
import { CmsReservationAppImprovementsFiltersParams } from '@modules/cms/reservation-app/improvements-filters'

export const getCmsResorts = createAsyncThunk<CmsResortDetails[], void, { state: RootState }>(
  'cms-reservation-app/getCmsResorts',
  async (_, { getState }) => await commonObjectGet<CmsResortDetails[]>(getState().appState.appData.urls.cms.resorts),
)
export const getCmsAccommodationTypes = createAsyncThunk<CmsAccommodationTypeDetails[], void, { state: RootState }>(
  'cms-reservation-app/getCmsAccommodationTypes',
  async (_, { getState }) =>
    await commonObjectGet<CmsAccommodationTypeDetails[]>(getState().appState.appData.urls.cms.accommodation_types),
)

export const getCmsTextBlocks = createAsyncThunk<TextBlock[], string, { state: RootState }>(
  'cms-reservation-app/getCmsTextBlocks',
  async (category, { getState }) =>
    await commonObjectGet<TextBlock[]>(getState().appState.appData.urls.cms.text_blocks, {
      category,
    }),
)

export const getCmsResortImprovements = createAsyncThunk<
  CmsImprovement[],
  CmsReservationAppImprovementsFiltersParams,
  { state: RootState }
>(
  'cms-reservation-app/getCmsResortImprovements',
  async (params, { getState }) =>
    await commonObjectGet<CmsImprovement[]>(getState().appState.appData.urls.cms.improvements, params),
)
