import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { BaseTableFilters } from '@components/table/use-handle-table-filters-change'
import { CommonTableFilters } from '@components/table/common-table-filters'
import { ButtonWithIcon } from '@components/button-with-icon'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { useModal } from '@components/modals/use-modal'

interface Props {
  filters: BaseTableFilters
  defaultFilters: BaseTableFilters
  setFilters: (filters: BaseTableFilters) => void
}

export const CouponsTypesConfigurationsFilters = ({ setFilters, filters, defaultFilters }: Props) => {
  const [showCouponTypeConfigurationModal] = useModal('CouponTypeConfigurationCreateModal')
  const methods = useForm<BaseTableFilters>({ defaultValues: filters })

  const navigate = useNavigate()

  const navigateBack = () => {
    navigate(NavigationPath.CouponsTypes)
  }

  return (
    <FormProvider {...methods}>
      <div className="d-flex justify-content-between mb-2">
        <ButtonWithIcon
          handleClick={navigateBack}
          icon="uil-angle-left font-14"
          text="Wróć do listy typów"
          color="light"
          btnClass="btn-tall"
        />
        <ButtonWithIcon
          icon="uil-plus"
          text="Dodaj konfigurację typu kuponu"
          handleClick={showCouponTypeConfigurationModal}
          color="green-dark"
        />
      </div>

      <CommonTableFilters
        filters={filters}
        defaultFilters={defaultFilters}
        setFilters={setFilters}
        searchParams={{ hideLabel: true, formGroupClassName: 'mb-0' }}
        searchWrapperParams={{ className: 'col-auto' }}
        clearParams={{ className: 'mt-0 ml-auto' }}
        rowClassName="justify-content-end align-items-center mb-2"
      />
    </FormProvider>
  )
}
