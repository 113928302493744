import * as React from 'react'
import ModalBody from 'reactstrap/lib/ModalBody'
import { ReceptionBookingCheck } from '@modules/reception/common/reception-booking-check'
import { ReceptionBookingCheckInStepAccessBands } from '@modules/reception/checkin/step-access/reception-booking-check-in-step-access-bands'
import { ReceptionBookingDetails } from '@models/reception'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { setReceptionBookingDetails } from '@store/slices/reception-slice'

interface ReceptionBookingExtendedStayBandUpdateProps {
  previousStep: () => void
  nextStep: () => void
  booking: ReceptionBookingDetails
}

export const ReceptionBookingExtendedStayBandUpdate: React.FC<ReceptionBookingExtendedStayBandUpdateProps> = ({
  previousStep,
  nextStep,
  booking,
}) => {
  const dispatch = useAppDispatch()
  const updateState = (bookingDetails: ReceptionBookingDetails) => dispatch(setReceptionBookingDetails(bookingDetails))
  const { addSuccessMessage } = useNotificationHook()

  const onSubmit = (event: React.FormEvent) => {
    nextStep()
    addSuccessMessage('Sukces', 'Rezerwacja została przedłużona!')
    event.preventDefault()
  }

  return (
    <form onSubmit={onSubmit}>
      <ModalBody>
        <h4 className="mb-4">2. Aktualizacja danych opaski</h4>
        <ReceptionBookingCheckInStepAccessBands updateState={updateState} booking={booking} />
      </ModalBody>
      <ReceptionBookingCheck previousStep={previousStep} nextLabel="Zakończ przedłużanie" />
    </form>
  )
}
