import * as React from 'react'
import { CleaningOrderDetails, CleaningOrderDetailsHousekeepingNote } from '@modules/housekeeping/models'
import { OrderNotesTooltipDetailsRow } from './row-notes-tooltip-details-row'

interface Props {
  cleaningOrder: CleaningOrderDetails
}

export const OrderNotesTooltipDetails: React.FC<Props> = ({ cleaningOrder }) => {
  const [showAll, setShowAll] = React.useState(false)
  const [notes, setNotes] = React.useState<CleaningOrderDetailsHousekeepingNote[]>([])

  const handleOnClick = () => setShowAll(!showAll)

  React.useEffect(() => {
    if (showAll) {
      setNotes(cleaningOrder.notes)
    } else {
      setNotes([cleaningOrder.notes[0]])
    }
  }, [showAll, cleaningOrder.notes])

  return (
    <div className="reception__notes-tooltip__details">
      <div className="reception__notes-tooltip__info">Ostatnia notatka:</div>
      {notes.map((note: CleaningOrderDetailsHousekeepingNote) => (
        <OrderNotesTooltipDetailsRow note={note} key={note.id} />
      ))}
      {cleaningOrder.notes.length > 1 && (
        <div className="reception__notes-tooltip__details__all text-semi-strong cursor-pointer" onClick={handleOnClick}>
          Wszystkie notatki ({cleaningOrder.notes.length})
        </div>
      )}
    </div>
  )
}
