import * as React from 'react'
import { useForm } from 'react-hook-form'
import { RodoAgreementsAcquiringPointsFiltersFormInputs } from '@modules/crm/rodo-agreements/point-of-acquirng'
import { BaseModalProps } from '@components/modals/types'
import { Form } from '@hyper/forms/form'
import { Button, Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import {
  ReceptionBookingCheckStep,
  ReceptionBookingCheckSteps,
} from '@modules/reception/common/reception-booking-check-steps'
import { SaveButton } from '@hyper/button'
import { useFormRequest } from '@components/hooks/use-api-request'
import { useCreateAcquiringPointMutation } from '@api/rodo-agreements'
import { RodoAgreementsAcquiringPointCreateModalBaseData } from '@modules/crm/rodo-agreements/point-of-acquirng/create-modal/rodo-agreements-acquiring-point-create-modal-base-data'
import { RodoAgreementsAcquiringPointCreateModalThanksData } from '@modules/crm/rodo-agreements/point-of-acquirng/create-modal/rodo-agreements-acquiring-point-create-modal-thanks-data'

const steps: ReceptionBookingCheckStep[] = [
  { step: 1, description: 'Konfiguracja formularza', fields: [] },
  { step: 2, description: 'Konfiguracja podziękowania', fields: [] },
]

interface FormInputs {}

interface Props extends BaseModalProps {}

export const RodoAgreementsAcquiringPointCreateModal = ({ toggleIsVisible }: Props) => {
  const [step, setStep] = React.useState(1)

  const methods = useForm<RodoAgreementsAcquiringPointsFiltersFormInputs>({ defaultValues: {} })

  const [createAcquiringPoint] = useCreateAcquiringPointMutation()

  const { action: onSubmit, isLoading } = useFormRequest(async (payload: FormInputs) => {
    await createAcquiringPoint(payload)
  }, methods.setError)

  const handleNextStep = () => {
    setStep(step + 1)
  }

  const handlePrevStep = () => {
    setStep(step - 1)
  }

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Dodaj formularz</ModalHeader>
      <ReceptionBookingCheckSteps step={step} steps={steps} />
      <ModalBody className="pb-0">
        {step === 1 && <RodoAgreementsAcquiringPointCreateModalBaseData />}
        {step === 2 && <RodoAgreementsAcquiringPointCreateModalThanksData />}
      </ModalBody>

      <ModalFooter style={{ display: 'block' }}>
        <Row>
          <Col md={6} className="p-0">
            {step !== 1 && (
              <Button onClick={handlePrevStep} className="btn btn-light">
                Wstecz
              </Button>
            )}
          </Col>
          <Col md={6} className="text-right p-0">
            {step === 2 ? (
              <SaveButton
                role="submit"
                labelSaving="Proszę czekać..."
                label="Zapisz"
                className="btn btn-green"
                isSaving={isLoading}
              />
            ) : (
              <Button onClick={handleNextStep} className="btn btn-green" role="next-step">
                Następny krok
              </Button>
            )}
          </Col>
        </Row>
      </ModalFooter>
    </Form>
  )
}
