import * as React from 'react'
import { TransferPackage, TransferPackageDetails } from '@modules/accounting/refund/models'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAppDispatch } from '@store/index'
import { formatPriceShort } from '@helpers/utils'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useApiRequest } from '@components/hooks/use-api-request'
import { updateAccountingTransferPackage } from '@store/slices/accounting-slice'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'

interface Props {
  transferPackage: TransferPackage
}

export const TransferPackagesTableTableCellActionsSent: React.FC<Props> = ({ transferPackage }) => {
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading, action: onAccept } = useApiRequest(
    React.useCallback(async () => {
      const transferPackageDetails = await commonObjectPost<TransferPackageDetails>(transferPackage.urls.sent)
      addSuccessMessage('Sukces', `Paczka o numerze ${transferPackage.id} została oznaczona jako wysłana`)
      dispatch(updateAccountingTransferPackage(transferPackageDetails))
    }, [transferPackage]),
  )

  return transferPackage.status === 'generated' ? (
    <CommonObjectConfirmAction
      isLoading={isLoading}
      handleAccept={onAccept}
      message={`Potwierdź wykonanie zwrotu paczki numer ${transferPackage.id} w kwocie ${formatPriceShort(
        transferPackage.total_amount,
      )}`}
    >
      <span className="uil uil-truck font-16 text-muted cursor-pointer" title="Oznacz paczkę jako wysłaną" />
    </CommonObjectConfirmAction>
  ) : null
}
