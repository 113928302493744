import * as React from 'react'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useFormRequest } from '@components/hooks/use-api-request'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { SaveButton } from '@hyper/button'
import { FormInput } from '@hyper/forms'
import { commonObjectPost } from '@store/actions/generic-actions'
import { RootState, useAppSelector } from '@store/index'
import { FormProvider, useForm } from 'react-hook-form'
import { ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { formatPrice } from '@helpers/utils'
import { BaseModalProps } from '@components/modals/types'
import { usePaymentAppData } from '@modules/payment-registers/hooks/use-payment-app-data'
import { FormSelect } from '@hyper/forms/form-select'

interface FormInputs {
  resort: CustomReactSelectOption
  worker: CustomReactSelectOption
  amount: number
  description: string
}

interface Props extends BaseModalProps {
  type: string
  title: string
  resortId?: number
  onSuccess?: () => void
}
export const CashRegisterAddModal: React.FC<Props> = ({ toggleIsVisible, type, title, resortId, onSuccess }) => {
  const resorts = useAuthenticatedUser()
    .resorts.filter(row => !resortId || row.id === resortId)
    .map(({ id, name }) => ({ value: id, label: name }))

  const { payment_cash_register_workers } = usePaymentAppData()

  const workers: CustomReactSelectOption[] = payment_cash_register_workers.map(({ code, name }) => ({
    value: code,
    label: name,
  }))

  const { addSuccessMessage } = useNotificationHook()

  const methods = useForm<FormInputs>({
    defaultValues: {
      resort: resorts[0],
    },
  })

  const url = useAppSelector((state: RootState) => state.appState.appData.urls.sale.cash_registers)
  const { isLoading, action: onSubmit } = useFormRequest(
    async (payload: FormInputs) => {
      await commonObjectPost(url, {
        ...payload,
        resort: payload.resort?.value,
        worker: payload.worker?.value,
        type,
      })
      addSuccessMessage('Sukces', `Operacja na kwotę ${formatPrice(payload.amount)} została dodana!`)
      onSuccess?.()

      toggleIsVisible()
    },
    methods.setError,
    { showGlobalError: true },
  )

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggleIsVisible}>{title}</ModalHeader>
        <ModalBody>
          <Row>
            <FormSelect options={resorts} name="resort" label="Ośrodek" />
            <FormInput colSize={12} label="Kwota" name="amount" type="currency" setValue={methods.setValue} />
            <FormSelect options={workers} name="worker" label="Pracownik" />
            <FormInput label="Notatka" name="description" type="textarea" />
          </Row>
        </ModalBody>
        <ModalFooter>
          <SaveButton isSaving={isLoading} label="Dodaj" labelSaving="Dodawanie..." />
        </ModalFooter>
      </form>
    </FormProvider>
  )
}
