import * as React from 'react'
import { DownloadButtons } from '@components/downloads-buttons'
import { RootState, useAppSelector } from '@store/index'
import { objectToUrlString } from '@helpers/utils'

interface ReservationListActionsProps {
  ids: number[]
}

export const ReservationListActions: React.FC<ReservationListActionsProps> = ({ ids }) => {
  const booking_export = useAppSelector((state: RootState) => state.appState.appData.urls.rent.booking_export)

  const params = {
    ids: ids.join(','),
  }

  const getExportLink = (format: 'xls' | 'pdf') => `${booking_export}?format=${format}&${objectToUrlString(params)}`

  return (
    <div className="text-right mt-1">
      <DownloadButtons disabled={!ids.length} exportPdf={getExportLink('pdf')} exportXls={getExportLink('xls')} />
    </div>
  )
}
