import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { RentalProductCategoriesTableActions } from '@modules/rental/product-categories/rental-product-categories-table-actions'
import { TableFilters } from '@components/table/table'

interface Props {
  filters: TableFilters
  setFilters: (filters: TableFilters) => void
}

export const RentalProductCategoriesFilters = ({ setFilters, filters }: Props) => {
  const methods = useForm<TableFilters>({ defaultValues: filters })

  const onSubmit = async (payload: TableFilters) => setFilters({ ...filters, ...payload })

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="form form-style-filter mb-2" autoComplete="off">
        <div className="d-flex justify-content-end align-items-end">
          <SearchFilter formGroupClassName="mb-2" />
          <RentalProductCategoriesTableActions />
        </div>
      </form>
    </FormProvider>
  )
}
