import * as React from 'react'
import { ReceptionBooking, ReceptionBookingDetails } from '@models/reception'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { ReceptionBookingCheckInStepAccessBands } from '@modules/reception/checkin/step-access/reception-booking-check-in-step-access-bands'
import { useAppDispatch, useAppSelector } from '@store/index'
import { getReceptionBookingDetails, updateReceptionBookingDetails } from '@store/actions/reception-actions'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { receptionBookingDetailsSelector } from '@store/selectors/reception'
import { BaseModalProps } from '@components/modals/types'

interface ScanningDialogProps extends BaseModalProps {
  booking: ReceptionBooking
}

export const ScanningDialog: React.FC<ScanningDialogProps> = ({ booking, toggleIsVisible }) => {
  const dispatch = useAppDispatch()
  const updateState = (bookingDetails: ReceptionBookingDetails) =>
    dispatch(updateReceptionBookingDetails(bookingDetails))
  const bookingDetails = useAppSelector(receptionBookingDetailsSelector)

  React.useEffect(() => {
    dispatch(getReceptionBookingDetails(booking.urls.api_details))
  }, [booking])

  const isLoading = booking.id !== bookingDetails?.id

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>Aktualizacja opasek</ModalHeader>
      <ModalBody>
        <LoaderPlaceholder content={!isLoading}>
          {!isLoading && bookingDetails && (
            <ReceptionBookingCheckInStepAccessBands updateState={updateState} booking={bookingDetails} />
          )}
        </LoaderPlaceholder>
      </ModalBody>
      <ModalFooter>
        <Button color="green" onClick={toggleIsVisible}>
          Zamknij
        </Button>
      </ModalFooter>
    </>
  )
}
