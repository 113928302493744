import * as React from 'react'
import { BookingDiscount, PriceItem } from '@models/reception'
import { formatPrice } from '@helpers/utils'

interface Props {
  discounts: PriceItem<BookingDiscount>
}

export const ReservationBillExpandedDiscounts = ({ discounts }: Props): JSX.Element => (
  <table className="table table-sm col-9 ml-auto mb-0 font-12 table-striped">
    <tbody>
      {discounts.items.map((discount: BookingDiscount) => (
        <tr key={discount.name}>
          <td>{discount.name}</td>
          <td className="fw-semi-bold text-right">- {formatPrice(discount.price_brutto)}</td>
          <td width="50" />
        </tr>
      ))}
    </tbody>
  </table>
)
