import * as React from 'react'
import { Row } from 'reactstrap'
import { FormInput, FormPlain } from '@hyper/forms'
import DatePickerInput from '@components/date/date-picker-input'
import { PaymentMethod, paymentMethods } from '@models/payments'
import { startOfToday, subDays } from 'date-fns'
import { FormCheckbox } from '@hyper/forms/form-checkbox'

interface Props {
  sendEmailNotificationOption: boolean
  amountReadonly?: boolean
}

export const PaymentCreateModalForm: React.FC<Props> = ({ sendEmailNotificationOption, amountReadonly }) => {
  const sources = paymentMethods.filter((source: [PaymentMethod, string]) =>
    [PaymentMethod.form_bank].includes(source[0]),
  )

  const minInvoiceDate = subDays(startOfToday(), 3)

  return (
    <Row>
      <FormInput colSize={5} label="Kwota" name="amount" readOnly={amountReadonly} />

      <FormPlain name="invoice_date" colSize={7}>
        <label>Data wpłaty</label>
        <DatePickerInput minDate={minInvoiceDate} maxDate={startOfToday()} name="invoice_date" />
      </FormPlain>

      <FormInput label="Typ płatności" name="source" type="select">
        {sources.map(source => (
          <option key={source[0]} value={source[0]}>
            {source[1]}
          </option>
        ))}
      </FormInput>

      <FormInput label="Opis" name="description" />
      {sendEmailNotificationOption && (
        <FormPlain name="" colSize={12}>
          <FormCheckbox name="send_email_notification" label="Automatyczna wysyłka kodów po opłaceniu" />
        </FormPlain>
      )}
    </Row>
  )
}
