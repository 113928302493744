import { CustomReactSelectOption } from '@components/custom-react-select'
import { BenefitProgramAgreementKind, BenefitProgramStatus } from '@modules/benefit-program/models'
import { createSelectOption } from '@helpers/utils'

export const benefitProgramStatusOptions: CustomReactSelectOption<string, BenefitProgramStatus>[] = [
  createSelectOption('Aktywny', 'active'),
  createSelectOption('Nieaktywny', 'inactive'),
]
export const benefitProgramAgreementStatusOptions: CustomReactSelectOption<string, BenefitProgramStatus>[] = [
  createSelectOption('Aktywny', 'active'),
  createSelectOption('Nieaktywny', 'inactive'),
]
export const benefitProgramAgreementKindsOptions: CustomReactSelectOption<string, BenefitProgramAgreementKind>[] = [
  createSelectOption('Benefit domek', 'house'),
  createSelectOption('Benefit apartament', 'apartment'),
]
