import * as React from 'react'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap'
import { Agreement, AnnexTypeId } from '@modules/hr/agreement/models'
import { useModal } from '@components/modals/use-modal'
import { IconWithText } from '@components/icon-with-text'
import { Direction } from 'reactstrap/es/Dropdown'
import { useNotificationHook } from '@hyper/use-notification-hook'

interface Props {
  agreement: Agreement
  trigger: React.ReactNode
  direction?: Direction
}

export const AgreementAnnexDropdown = ({ agreement, trigger, direction = 'left' }: Props) => {
  const { addWarningMessage } = useNotificationHook()

  const [showHourlyRateChangeModal] = useModal('AgreementHourlyRateAnnexModal', { agreement })
  const [showContractWithdrawalModal] = useModal('AgreementWithdrawalAnnexModal', { agreement })

  const handleAnnexClick = () => {
    const annexDocumentTypes: AnnexTypeId[] = ['task_and_earning_change', 'earning_change', 'task_change']

    const hasInactiveAnnex = agreement.annexes.find(
      annex => annexDocumentTypes.includes(annex.document_type) && annex.status !== 'active',
    )

    if (hasInactiveAnnex) {
      addWarningMessage(
        'Uwaga',
        <div>
          <p className="font-14">Do umowy przypisany jest aneks, który nie został aktywowany.</p>
          <span className="font-12">Zaktualizuj status dokumentu lub go usuń.</span>
        </div>,
      )
      return
    }
    showHourlyRateChangeModal()
  }

  const handleContractWithdrawalClick = () => {
    const statusesWithAvailableResignation = ['to_verify', 'verified', 'active']
    const canResign = statusesWithAvailableResignation.includes(agreement.status)
    const hasResignationAnnex = agreement.annexes.find(annex => annex.document_type === 'resignation')

    if (!canResign || hasResignationAnnex) {
      addWarningMessage(
        'Uwaga',
        <div>
          <p className="font-14">
            {hasResignationAnnex
              ? 'Umowa posiada już złożoną rezygnację.'
              : 'Nie można złożyć rezygnacji w obecnym statusie umowy.'}
          </p>
        </div>,
      )
      return
    }

    showContractWithdrawalModal()
  }

  return (
    <UncontrolledButtonDropdown direction={direction}>
      <DropdownToggle color="transparent" className="text-semi-strong text-default px-0 shadow-none">
        {trigger}
      </DropdownToggle>
      <DropdownMenu className="py-0">
        <DropdownItem onClick={handleAnnexClick} className="px-2 py-0">
          <IconWithText icon="uil-file-plus font-16" text="Aneks" wrapperClassNames="my-1" />
        </DropdownItem>
        <hr className="my-0" />
        <DropdownItem onClick={handleContractWithdrawalClick} className="px-2 py-0">
          <IconWithText icon="uil-file-times font-16" text="Rezygnacja" wrapperClassNames="my-1" />
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  )
}
