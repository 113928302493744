import * as React from 'react'
import { TechnicalOrder } from '@models/technical-orders'
import Table from '@components/table/table'
import { TechnicalOrdersTodoRowUnit } from '@modules/technical-orders/technical-orders-todo/row-unit'
import { TechnicalOrdersTodoRowName } from '@modules/technical-orders/technical-orders-todo/row-name'
import { TechnicalOrdersOrderedTableRowNotes } from '@modules/technical-orders/technical-orders-ordered/row-notes'
import { TechnicalOrdersTodoRowNumber } from '@modules/technical-orders/technical-orders-todo/row-number'
import { TechnicalOrdersTodoRowResort } from '@modules/technical-orders/technical-orders-todo/row-resort'
import { TechnicalOrdersToAcceptTableRowActions } from '@modules/technical-orders/technical-orders-to-accept/row-actions'
import { TechnicalOrdersTodoRowUsers } from '@modules/technical-orders/technical-orders-ordered/row-users'
import { TechnicalOrdersDoneTableRowWorkTime } from '@modules/technical-orders/technical-orders-done/row-work-time'
import { useTechnicalOrderPreviewModal } from '@modules/technical-orders/hooks/use-technical-order-preview-modal'
import { TechnicalOrdersInProgressTableRowRowDragIndicator } from '@modules/technical-orders/technical-orders-ordered/drag-and-drop/row-drag-indicator'

interface Props {
  technicalOrder: TechnicalOrder
  children: React.ReactNode
  showResort: boolean
  showDragHandler: boolean
  showUsers: boolean
}

export const TechnicalOrdersToAcceptTableRow: React.FC<Props> = ({
  showDragHandler,
  children,
  showResort,
  technicalOrder,
  showUsers,
}) => {
  const handlePreview = useTechnicalOrderPreviewModal({ technicalOrder })

  return (
    <Table.Row onDoubleClick={handlePreview} className="cursor-pointer">
      {showDragHandler && <TechnicalOrdersInProgressTableRowRowDragIndicator technicalOrder={technicalOrder} />}
      {children}
      <TechnicalOrdersTodoRowNumber technicalOrder={technicalOrder} showCreatedAt={true} />
      {showResort && <TechnicalOrdersTodoRowResort technicalOrder={technicalOrder} />}
      <TechnicalOrdersTodoRowUnit technicalOrder={technicalOrder} />
      <TechnicalOrdersDoneTableRowWorkTime technicalOrder={technicalOrder} />
      <TechnicalOrdersTodoRowName technicalOrder={technicalOrder} />
      {showUsers && <TechnicalOrdersTodoRowUsers technicalOrder={technicalOrder} />}
      <TechnicalOrdersOrderedTableRowNotes technicalOrder={technicalOrder} />
      <TechnicalOrdersToAcceptTableRowActions technicalOrder={technicalOrder} />
    </Table.Row>
  )
}
