import * as React from 'react'
import { ReceptionBooking, ReceptionBookingDetails } from '@models/reception'
import { Tooltip } from 'reactstrap'
import { ReceptionRowNotesTooltipDetails } from '@modules/reception/common/reception-row-notes/reception-row-notes-tooltip-details'
import { commonObjectGet } from '@store/actions/generic-actions'

interface ReceptionRowNotes {
  booking: ReceptionBooking
}

export const ReceptionRowNotes: React.FC<ReceptionRowNotes> = ({ booking }) => {
  const iconStyles = { opacity: !booking.has_notes ? 0.3 : 1 }
  const [tooltipOpen, setTooltipOpen] = React.useState(false)
  const [bookingDetails, setBookingDetails] = React.useState<ReceptionBookingDetails | undefined>()

  const toggle = React.useCallback(() => {
    const isTooltipOpen = !tooltipOpen

    if (isTooltipOpen && !bookingDetails) {
      ;(async () => {
        setBookingDetails(await commonObjectGet<ReceptionBookingDetails>(booking.urls.api_details))
      })()
    }

    setTooltipOpen(isTooltipOpen)
  }, [tooltipOpen, bookingDetails])

  const tooltipId = `notes-tooltip-${booking.id}`

  return (
    <>
      {booking.has_notes && (
        <Tooltip
          placement="top"
          isOpen={tooltipOpen && !!bookingDetails}
          target={tooltipId}
          toggle={toggle}
          className="reception__notes-tooltip"
          autohide={false}
        >
          {bookingDetails && <ReceptionRowNotesTooltipDetails contentNotes={bookingDetails.notes} />}
        </Tooltip>
      )}
      <i className="uil-file-check-alt font-18 text-muted" style={iconStyles} id={tooltipId} />
    </>
  )
}
