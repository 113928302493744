import * as React from 'react'
import { useAppDispatch } from '@store/index'
import { Card, CardBody } from 'reactstrap'
import { PackageWholesaleWebSocketHandler } from '@modules/package-wholesale/web-socket-handler'
import { PackageWholesaleListFilter, PackageWholesaleListFilterParams } from '@modules/package-wholesale/list/filter'
import { getPackageWholesales } from '@store/actions/package-wholesale-actions'
import { packageWholesaleStatusList } from '@modules/package-wholesale/models'
import { PackageWholesaleListTable } from '@modules/package-wholesale/list/table'
import { useParams } from 'react-router-dom'
import { PackageWholesaleDetailsView } from '@modules/package-wholesale/list/details-view'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { usePackageWholesaleList } from '@modules/package-wholesale/hooks/use-package-wholesale-list'
import { useTableFilters } from '@components/table/use-table-filters'
import { PageTitleWithSize } from '@hyper/page-title-with-size'

const defaultFilters: PackageWholesaleListFilterParams = {
  search: '',
  ordering: '-created',
  created_after: undefined,
  created_before: undefined,
  page: 1,
  page_size: 10,
  has_zoo_borysew_tickets: false,
  has_suntago_tickets: false,
  sellers: undefined,
  status: packageWholesaleStatusList.filter(row => ['open', 'close'].includes(row.value)),
}

export const PackageWholesaleListView: React.FC = () => {
  const { id: package_wholesale_id } = useParams<'id'>()

  const dispatch = useAppDispatch()

  useMenuActive(NavigationPath.PackageWholesaleList)

  const { isLoading, filters, setFilters } = useTableFilters<PackageWholesaleListFilterParams>({
    defaultFilters,
    action: async filters => await dispatch(getPackageWholesales(filters)),
  })

  const packageWholesales = usePackageWholesaleList().filter(row =>
    filters.status?.length ? filters.status?.some(status => status.value === row.status) : true,
  )

  return (
    <>
      <PageTitleWithSize title="Lista pakietów hurtowych" />
      <Card>
        <PackageWholesaleWebSocketHandler />
        <CardBody>
          {package_wholesale_id && <PackageWholesaleDetailsView package_wholesale_id={package_wholesale_id} />}

          <PackageWholesaleListFilter filters={filters} setFilters={setFilters} defaultFilters={defaultFilters} />
          <PackageWholesaleListTable
            isLoading={isLoading}
            packageWholesales={packageWholesales}
            filters={filters}
            setFilters={setFilters}
          />
        </CardBody>
      </Card>
    </>
  )
}
