import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { PaymentDayReportDetails, PaymentDayUserReport } from '@models/payments'
import { useForm } from 'react-hook-form'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useFormRequest } from '@components/hooks/use-api-request'
import { useModal } from '@components/modals/use-modal'
import { Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { FormInput } from '@hyper/forms'
import { ButtonWithIcon } from '@components/button-with-icon'
import { SaveButton } from '@hyper/button'
import { commonObjectPost } from '@store/actions/generic-actions'
import { updatePaymentDayReportDetails } from '@store/slices/cash-slice'
import { cashCountEmptyValues, useCashCount } from '@modules/payment-registers/cash-count-modal/use-cash-count'
import { CashCount } from '@modules/payment-registers/cash-count-modal'
import { Form } from '@hyper/forms/form'

interface Props extends BaseModalProps {
  report: PaymentDayUserReport
  kind: 'receptionist_payin' | 'receptionist_payout'
}

interface FormInputs {
  amount: number
}

export const PaymentDAyUserReportOperationModal: React.FC<Props> = ({ kind, report, toggleIsVisible }) => {
  const methods = useForm<FormInputs>()
  const [cashCountValues, setCashCountValues] = React.useState<CashCount>(cashCountEmptyValues)
  const { totalCashCount } = useCashCount(cashCountValues)

  const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()
  const { isLoading, action: onSubmit } = useFormRequest(
    async (payload: FormInputs) => {
      dispatch(
        updatePaymentDayReportDetails(
          await commonObjectPost<PaymentDayReportDetails>(report.urls.operations, { amount: payload.amount, kind }),
        ),
      )
      addSuccessMessage('Sukces', kind === 'receptionist_payin' ? 'Zasilenie zostało dodane' : 'Wypłata została dodana')
      toggleIsVisible()
    },
    methods.setError,
    { showGlobalError: true },
  )

  const [handleCashCount] = useModal('CashCountModal', {
    setCashCountValues,
    cashCountValues,
    resort: report.resort_id,
  })

  React.useEffect(() => {
    methods.setValue('amount', Number(totalCashCount))
  }, [cashCountValues])

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>{kind === 'receptionist_payin' ? 'Zasil' : 'Wypłać'}</ModalHeader>
      <ModalBody>
        <Row>
          <FormInput label="Kwota" colSize={6} name="amount" type="currency" setValue={methods.setValue} />
          <Col md={6}>
            <label className="d-block">&nbsp;</label>
            <ButtonWithIcon
              icon="uil-money-withdraw"
              color="outline-secondary"
              text="Przelicz gotówkę"
              handleClick={handleCashCount}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <button type="button" onClick={toggleIsVisible} className="btn mr-1 btn-light">
          Anuluj
        </button>
        <SaveButton isSaving={isLoading} className="btn btn-green" />
      </ModalFooter>
    </Form>
  )
}
