import * as React from 'react'
import { ContentNote } from '@models/dashboard'
import { ReceptionRowNotesTooltipNote } from '@modules/reception/common/reception-row-notes/reception-row-notes-tooltip-note'

interface ReceptionRowNotesTooltipDetailsProps {
  contentNotes: ContentNote[]
}

export const ReceptionRowNotesTooltipDetails: React.FC<ReceptionRowNotesTooltipDetailsProps> = ({ contentNotes }) => {
  const [notes, setNotes] = React.useState<ContentNote[]>([contentNotes[0]])

  const handleOnClick = () => setNotes(notes.length === 1 ? contentNotes : [contentNotes[0]])

  return (
    <div className="reception__notes-tooltip__details">
      <div className="reception__notes-tooltip__info">Ostatnia notatka:</div>
      {notes.map((note: ContentNote) => (
        <ReceptionRowNotesTooltipNote note={note} key={note.id} />
      ))}
      {contentNotes.length > 1 && (
        <div className="reception__notes-tooltip__details__all text-semi-strong cursor-pointer" onClick={handleOnClick}>
          Wszystkie notatki ({contentNotes.length})
        </div>
      )}
    </div>
  )
}
