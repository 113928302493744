import * as React from 'react'
import { ReceptionBooking } from '@models/reception'
import { ReceptionToCheckInRowArrivalTime } from '@modules/reception/to-checkin/row/reception-to-check-in-row-arrival-time'
import { ReceptionToCheckInRowImprovements } from '@modules/reception/to-checkin/row/reception-to-check-in-row-improvements'
import { ReceptionToCheckInRowButtons } from '@modules/reception/to-checkin/row/reception-to-check-in-row-buttons'
import { ReceptionRowApartment } from '@modules/reception/common/reception-row-apartment'
import { ReceptionRowName } from '@modules/reception/common/reception-row-name'
import { ReceptionRowDates } from '@modules/reception/common/reception-row-dates'
import { ReceptionRowNotes } from '@modules/reception/common/reception-row-notes'
import classNames from 'classnames'
import { ReceptionCompleteCheckInRowActions } from '@modules/reception/complete-checkin/row/reception-complete-check-in-row-actions'

interface ReceptionToCheckInRowProps {
  booking: ReceptionBooking
  hasBands: boolean
}

export const ReceptionToCheckInRow: React.FC<ReceptionToCheckInRowProps> = ({ booking, hasBands }) => (
  <tr
    className={classNames('reception__bookings_table__row', {
      'reception__bookings_table__row--is-initial': ['initial', 'initial_waiting'].includes(booking.status),
      'reception__bookings_table__row--is-check-in-started': booking.reception_action === 'check-in-started',
    })}
    role="booking-check-in"
  >
    <td className="reception__bookings_table__column reception__bookings_table__column__first">
      <ReceptionRowApartment booking={booking} showHouseKeeping={true} />
    </td>
    <td className="reception__bookings_table__column">
      <ReceptionRowName booking={booking} />
    </td>
    <td className="reception__bookings_table__column">
      <ReceptionRowDates booking={booking} />
    </td>
    {booking.status === 'confirmed' && (
      <>
        <td className="reception__bookings_table__column">
          <ReceptionToCheckInRowArrivalTime booking={booking} />
        </td>
        <td className="reception__bookings_table__column">
          <ReceptionToCheckInRowImprovements booking={booking} />
        </td>
        <td className="valign-middle reception__bookings_table__column">
          <ReceptionRowNotes booking={booking} />
        </td>
        <td className="valign-middle reception__bookings_table__column" style={{ width: 10 }}>
          {hasBands && <ReceptionCompleteCheckInRowActions booking={booking} />}
        </td>

        <td style={{ width: 10 }} className="text-nowrap text-right reception__bookings_table__column valign-middle">
          <ReceptionToCheckInRowButtons booking={booking} />
        </td>
      </>
    )}
    {['initial', 'initial_waiting'].includes(booking.status) && (
      <>
        <td className="valign-middle text-right reception__bookings_table__column">
          {/*<ReceptionRowNotes booking={booking} />*/}
        </td>
        <td colSpan={4} className="text-nowrap text-right reception__bookings_table__column">
          {/*<ReceptionToCheckInRowNotificationButtons />*/}
        </td>
      </>
    )}
  </tr>
)
