import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@store/index'
import { BookingRefund, TransferPackage } from '@modules/accounting/refund/models'
import { getBookingRefunds, getTransferPackages } from '@store/actions/accounting-actions'

interface BarrierState {
  bookingRefunds: EntityState<BookingRefund>
  transferPackages: EntityState<TransferPackage>
}

const bookingRefundsAdapter = createEntityAdapter<BookingRefund>()
const transferPackagesAdapter = createEntityAdapter<TransferPackage>()

const initialState: BarrierState = {
  transferPackages: transferPackagesAdapter.getInitialState(),
  bookingRefunds: bookingRefundsAdapter.getInitialState(),
}

export const accountingSlice = createSlice({
  name: 'accounting',
  initialState,
  reducers: {
    updateAccountingBookingRefund(state, action: PayloadAction<BookingRefund>) {
      bookingRefundsAdapter.updateOne(state.bookingRefunds, { id: action.payload.id, changes: action.payload })
    },
    updateAccountingTransferPackage(state, action: PayloadAction<TransferPackage>) {
      transferPackagesAdapter.updateOne(state.transferPackages, { id: action.payload.id, changes: action.payload })
    },
    addAccountingBookingRefund(state, action: PayloadAction<BookingRefund>) {
      bookingRefundsAdapter.upsertOne(state.bookingRefunds, action.payload)
    },
  },
  extraReducers: builder => {
    builder.addCase(getBookingRefunds.fulfilled, (state, action) => {
      bookingRefundsAdapter.setAll(state.bookingRefunds, action.payload)
    })
    builder.addCase(getTransferPackages.fulfilled, (state, action) => {
      transferPackagesAdapter.setAll(state.transferPackages, action.payload)
    })
  },
})
export const { updateAccountingBookingRefund, updateAccountingTransferPackage, addAccountingBookingRefund } =
  accountingSlice.actions
export const { selectAll: bookingRefundsSelector } = bookingRefundsAdapter.getSelectors(
  (state: RootState) => state.accountingState.bookingRefunds,
)
export const { selectAll: transferPackagesSelector } = transferPackagesAdapter.getSelectors(
  (state: RootState) => state.accountingState.transferPackages,
)

export default accountingSlice.reducer
