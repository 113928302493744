import { CustomReactSelectOption } from '@components/custom-react-select'
import { createSelectOption } from '@helpers/utils'
import { Resort } from '@models/booking'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UseFormReturn } from 'react-hook-form'
import { useSelectWithAllOption } from '@helpers/use-select-with-all-option'

interface Response {
  getResortOptions: ({ withAllOption }) => CustomReactSelectOption[]
  getSelectedResortsIds: (resorts: CustomReactSelectOption[]) => number[]
  getSelectedResortsOptions: (
    resortOptions: CustomReactSelectOption[],
    selectedIds: number[],
    { withAllOption },
  ) => CustomReactSelectOption[]
}

interface Props {
  field: string
  methods: UseFormReturn<any, any>
}

export const useMultipleResorts = ({ methods, field }: Props): Response => {
  const user = useAuthenticatedUser()

  const userResortOptions = user.resorts.map((resort: Resort) => createSelectOption(resort.name, resort.id))
  const { options } = useSelectWithAllOption(userResortOptions, methods, field)

  const getResortOptions = ({ withAllOption }) => (withAllOption ? options : userResortOptions)

  const getSelectedResortsIds = (resorts: CustomReactSelectOption[]) => {
    const hasAllSelected = resorts.some((selectedResort: CustomReactSelectOption) => selectedResort.value === 'all')

    return hasAllSelected ? user.resorts.map(resort => resort.id) : resorts.map(resortOption => resortOption.value)
  }

  const getSelectedResortsOptions = (
    resortOptions: CustomReactSelectOption[],
    selectedIds: number[],
    { withAllOption = false },
  ) => {
    const optionLength = withAllOption ? resortOptions.length - 1 : resortOptions.length

    return optionLength === selectedIds.length
      ? [resortOptions[0]]
      : resortOptions.filter(row => selectedIds.includes(row.value))
  }

  return {
    getResortOptions,
    getSelectedResortsIds,
    getSelectedResortsOptions,
  }
}
