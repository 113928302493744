import * as React from 'react'
import { PackageSummarySection, PackageSummarySectionDataRow } from '@modules/package/create/package-summary-section'
import { useFormContext } from 'react-hook-form'
import { InvoiceType } from '@models/promotions'

interface FormInputs {
  invoice_nip: string
  invoice_company: string
  invoice_street: string
  invoice_postcode: string
  invoice_city: string
  invoice_type: InvoiceType
}

export const ProductSaleSummaryInvoice = (): JSX.Element => {
  const methods = useFormContext<FormInputs>()
  const data = methods.getValues()

  return (
    <PackageSummarySection
      title={
        <span>
          Dane do faktury
          {data.invoice_type === 'individual' && (
            <span className="font-weight-light ml-1 font-12">(osoba prywatna)</span>
          )}
        </span>
      }
      className="col-6"
    >
      <PackageSummarySectionDataRow
        titleFlex="col-4"
        contentFlex="col-8"
        title={data.invoice_type === 'individual' ? 'PESEL:' : 'NIP:'}
        content={data.invoice_nip}
      />

      <PackageSummarySectionDataRow
        titleFlex="col-4"
        contentFlex="col-8"
        title="Nazwa firmy:"
        content={data.invoice_company}
      />

      <PackageSummarySectionDataRow
        titleFlex="col-4"
        contentFlex="col-8"
        title="Adres firmy:"
        content={
          <div>
            <p className="mb-1">ul. {data.invoice_street}</p>
            <p className="mb-1">
              {data.invoice_postcode}, {data.invoice_city}
            </p>
            <p className="mb-1">Polska</p>
          </div>
        }
      />
    </PackageSummarySection>
  )
}
