import * as React from 'react'
import { useAppDispatch } from '@store/index'
import { NotificationEvents } from '@models/dashboard'
import { useWebSocket } from '@components/hooks/use-web-socket'
import { ReceptionBookingDetails } from '@models/reception'
import { updateReceptionBookingDetails } from '@store/actions/reception-actions'

export const ReservationListWebSocketHandler: React.FC = () => {
  const dispatch = useAppDispatch()

  useWebSocket<ReceptionBookingDetails>(
    (message, payload) => {
      switch (message) {
        case NotificationEvents.BOOKING_CHANGE: {
          dispatch(updateReceptionBookingDetails(payload))
          break
        }
        case NotificationEvents.BOOKING_ADD: {
          dispatch(updateReceptionBookingDetails(payload))
          break
        }
      }
    },
    [],
    [NotificationEvents.BOOKING_CHANGE, NotificationEvents.BOOKING_ADD],
  )

  return null
}
