import * as React from 'react'
import { Package, PackageDetails } from '@models/package'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { UserPermission } from '@models/dashboard'
import { updatePackageDetails } from '@store/slices/package-slice'
import { ProductCustomActionButton } from '@components/products/product-action-button'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'

interface Props {
  packageData: Package
}

export const PackageDetailsBaseInformationCancel = ({ packageData }: Props): JSX.Element | null => {
  const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()
  const user = useAuthenticatedUser()

  const { isLoading, action: handleAccept } = useApiRequest(async () => {
    dispatch(updatePackageDetails(await commonObjectDelete<PackageDetails>(packageData.urls.details)))
    addSuccessMessage('Sukces', `Pakiet firmowy o numerze ${packageData.number} został anulowany!`)
  })

  const permission = {
    HPR: UserPermission.PackageCanDelete,
    HPI: UserPermission.PackageHpiCanDelete,
  }[packageData.package_type]

  if (!user.hasPerm(permission)) {
    return null
  }

  if (packageData.status === 'cancelled') {
    return null
  }

  return (
    <CommonObjectConfirmAction
      title="Potwierdź anulowanie pakietu"
      isLoading={isLoading}
      handleAccept={handleAccept}
      message="Czy na pewno chcesz anulować wybrany pakiet ? Operacja ta jest nieodwracalna"
    >
      <ProductCustomActionButton className="btn btn-danger" text="Anuluj" />
    </CommonObjectConfirmAction>
  )
}
