import * as React from 'react'
import { FormPlain } from '@hyper/forms'
import { CustomInput, Label } from 'reactstrap'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { extractInnerRef } from '@helpers/forms'
import { useFormContext } from 'react-hook-form'

export const OptionsInput: React.FC = () => {
  const user = useAuthenticatedUser()

  const { register } = useFormContext()

  return (
    <FormPlain name="options" colSize={6}>
      <Label>Statusy:</Label>
      <CustomInput
        className="mt-2"
        type="checkbox"
        id="cancelled"
        label="Anulowane"
        {...extractInnerRef(register('cancelled'))}
      />
      <CustomInput
        className="mt-2"
        type="checkbox"
        id="incomplete"
        label="Niedokonczone"
        {...extractInnerRef(register('incomplete'))}
      />
      <CustomInput
        className="mt-2"
        type="checkbox"
        id="is_subscription_reservation"
        label="Abonamentowe"
        {...extractInnerRef(register('is_subscription_reservation'))}
      />
      {user.hasPerm(UserPermission.CanViewSpecialReservation) && (
        <CustomInput
          className="mt-2"
          type="checkbox"
          label="Specjalne"
          {...extractInnerRef('is_special_reservation')}
        />
      )}
    </FormPlain>
  )
}
