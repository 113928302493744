import * as React from 'react'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { FormProvider, useForm } from 'react-hook-form'
import { Row } from 'reactstrap'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { ColAuto } from '@hyper/col-auto'
import { BenefitProgramCreateButton } from '@modules/benefit-program/list/create'
import { FormSelect } from '@hyper/forms/form-select'
import { useBenefitProgramAdvisorsOptions } from '@modules/benefit-program/hooks/use-benefit-program-advisors-options'
import { benefitProgramStatusOptions } from '@modules/benefit-program/constants'
import { BenefitProgramTableFilters } from '@modules/benefit-program/list/index'

interface Props {
  filters: BenefitProgramTableFilters
  defaultFilters: BenefitProgramTableFilters
  setFilters: React.Dispatch<React.SetStateAction<BenefitProgramTableFilters>>
}

export const BenefitProgramListFilters: React.FC<Props> = ({ filters, defaultFilters, setFilters }) => {
  const advisorsOptions = useBenefitProgramAdvisorsOptions()

  const methods = useForm({
    defaultValues: defaultFilters,
  })
  const onSubmit = async payload => setFilters({ ...filters, ...payload })

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  return (
    <FormProvider {...methods}>
      <Row>
        <ColAuto className="ml-auto px-2">
          <BenefitProgramCreateButton />
        </ColAuto>
      </Row>
      <Row className="mr-0">
        <ColAuto style={{ width: 250 }} className="pl-0">
          <FormSelect options={advisorsOptions} name="advisor" label="Opiekun" selectProps={{ isClearable: true }} />
        </ColAuto>
        <ColAuto style={{ width: 250 }} className="pl-0">
          <FormSelect
            options={benefitProgramStatusOptions}
            name="status"
            label="Status"
            selectProps={{ isSelectMulti: true, isClearable: true }}
          />
        </ColAuto>
        <SearchFilter />
      </Row>
    </FormProvider>
  )
}
