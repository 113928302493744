import * as React from 'react'
import { useModal } from '@components/modals/use-modal'
import { ButtonWithIcon } from '@components/button-with-icon'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'

export const CouponsTypesTableActions = (): JSX.Element => {
  const [showCouponTypeModal] = useModal('CouponTypeCreateModal')

  const navigate = useNavigate()

  const handleNavigateToConfiguration = () => {
    navigate(NavigationPath.CouponsTypesConfiguration)
  }

  return (
    <div className="d-flex justify-content-end">
      <ButtonWithIcon
        icon="uil-plus"
        text="Dodaj typ kuponu"
        handleClick={showCouponTypeModal}
        color="green-dark"
        btnClass="text-right d-block"
      />
      <ButtonWithIcon
        icon="uil-angle-right font-14 ml-1 mr-0"
        text="Konfiguracja typów"
        handleClick={handleNavigateToConfiguration}
        color="light"
        btnClass="ml-2"
        iconWrapperClassName="flex-row-reverse"
      />
    </div>
  )
}
