import * as React from 'react'
import { RootState, useAppSelector } from '@store/index'
import { useModal } from '@components/modals/use-modal'

export const HousekeepingBeddingUser: React.FC = () => {
  const resortDetails = useAppSelector((state: RootState) => state.receptionState.resortDetails)
  const workers = useAppSelector((state: RootState) => state.houseKeepingState.cleaningWorkers)

  const beddingUser = workers.find(row => row.id === resortDetails?.housekeeping_bedding_user_id)

  const [handleIsOpen] = useModal('BeddingUserDialog')

  return (
    <div className="d-flex align-items-center">
      <strong className="text-semi-strong">Pościelowy:</strong>
      <strong className="mx-1">{beddingUser?.name || 'brak'}</strong>
      <span>
        (
        <span onClick={handleIsOpen} className="cursor-pointer">
          zmień
        </span>
        )
      </span>
    </div>
  )
}
