import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { toDefaultTimeFormat } from '@helpers/date-helper'
import { useAppData } from '@components/hooks/use-app-data'
import { PaymentDayUserReport } from '@models/payments'

interface Props {
  report: PaymentDayUserReport
}

export const PaymentRegisterReceptionRowSingleWork = ({ report }: Props): JSX.Element => {
  const { reception_workstations } = useAppData()

  const receptionWorkstation = reception_workstations.find(row => row.id === report.reception_workstation_id)
  return (
    <>
      <span className="badge badge-gray">
        <IconWithText
          icon="uil-clock"
          text={
            <>
              {toDefaultTimeFormat(report.day_start)} {report.day_end && <>- {toDefaultTimeFormat(report.day_end)}</>}
            </>
          }
        />
      </span>
      {report.status === 'open' ? (
        <span className="badge badge-success-lighten ml-1">
          <IconWithText icon="uil-check-circle" text={receptionWorkstation?.name || ''} />
        </span>
      ) : (
        <span className="ml-2">{receptionWorkstation?.name || ''}</span>
      )}
    </>
  )
}
