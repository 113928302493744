import { PinPadKind, ReceptionPinPad } from '@modules/reception/common/pin-pad/index'
import { ReceptionPinPadRefusedPaymentBox } from '@modules/reception/common/pin-pad/reception-pin-pad-refused-payment-box'
import * as React from 'react'
import { EservicePinPad } from '@models/eservice'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useModal } from '@components/modals/use-modal'
import { useEservicePinPadsList } from '@modules/reception/common/eservice-pin-pad-select/use-eservice-pin-pads-list'
import { RootState, useAppSelector } from '@store/index'

interface PaymentRejection {
  attempt: number
  title: string
}

interface Props {
  abortLabel?: string
  amount: string
  content_type: number
  context?: any
  eServicePinPad?: EservicePinPad
  isConfirming: boolean
  isDisabled?: boolean
  kind: PinPadKind
  object_id: number
  onCancel?: () => void
  onEserviceSuccess?: () => void
  onPaymentConfirm: () => void
  onPaymentRefuse?: () => void
  preauth_payment?: number
}

export const ReceptionPinPadRepeatablePayments = ({
  abortLabel,
  amount,
  content_type,
  context,
  eServicePinPad,
  isConfirming,
  isDisabled,
  kind,
  object_id,
  onCancel,
  onEserviceSuccess,
  onPaymentConfirm,
  onPaymentRefuse,
  preauth_payment,
}: Props) => {
  const [paymentRejections, setPaymentRejections] = React.useState<PaymentRejection[]>([])
  const isBooking = useAppSelector((state: RootState) => state.appState.appData.content_types.booking) === content_type

  const user = useAuthenticatedUser()

  const defaultEServicePindPand = useEservicePinPadsList().find(
    (pinPad: EservicePinPad) => pinPad.id === user.eservicePadId,
  )
  eServicePinPad = eServicePinPad || defaultEServicePindPand

  const handleError = (title?: string) => {
    setPaymentRejections(rejections => [
      ...rejections,
      { title: title ?? 'Płatność odrzucona', attempt: rejections.length + 1 },
    ])
    onPaymentRefuse?.()
  }

  const [showSuccessfullyPaymentResultModal] = useModal(
    'ReceptionPinPadPaymentSuccessModal',
    { amount, isBooking },
    {
      persist: true,
    },
  )

  const handlePaymentAbort = () =>
    setPaymentRejections(rejections => [
      ...rejections,
      {
        title: 'Płatność anulowana',
        attempt: rejections.length + 1,
      },
    ])

  const onSuccess = () => {
    showSuccessfullyPaymentResultModal()
    onEserviceSuccess?.()
    onCancel?.()
  }

  return (
    <ReceptionPinPad
      amount={amount}
      content_type={content_type}
      kind={kind}
      object_id={object_id}
      preauth_payment={preauth_payment}
      onError={handleError}
      context={context}
      onCancel={onCancel}
      onAbort={handlePaymentAbort}
      onSuccess={onSuccess}
      isDisabled={paymentRejections.length > 0 || isDisabled}
      abortLabel={abortLabel}
    >
      {(handlePaymentSubmit, handlePaymentCancel, isLoading) =>
        paymentRejections.length ? (
          <div className="reception__pin-pad-content">
            {paymentRejections.map(rejection => (
              <ReceptionPinPadRefusedPaymentBox
                key={rejection.attempt}
                eServicePinPad={eServicePinPad}
                onSubmit={onPaymentConfirm}
                onCancel={handlePaymentCancel}
                onRetry={handlePaymentSubmit}
                isConfirming={rejection.attempt === paymentRejections.length && isConfirming}
                isRetrying={rejection.attempt === paymentRejections.length && isLoading}
                isDisabled={rejection.attempt < paymentRejections.length}
                isPaymentConfirmationDisabled={rejection.attempt < 2}
                rejectionTitle={rejection.title}
              />
            ))}
          </div>
        ) : null
      }
    </ReceptionPinPad>
  )
}
