import * as React from 'react'
import { BookingRefund } from '@modules/accounting/refund/models'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAppDispatch } from '@store/index'
import { commonObjectPut } from '@store/actions/generic-actions'
import { useApiRequest } from '@components/hooks/use-api-request'
import { updateAccountingBookingRefund } from '@store/slices/accounting-slice'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'

interface Props {
  booking: BookingRefund
}

export const RefundBookingsTableCellActionsBlockP24: React.FC<Props> = ({ booking }) => {
  const { addSuccessNotification } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading, action: onAccept } = useApiRequest(
    React.useCallback(async () => {
      const message =
        booking.deposit_transfer_status === 'p24_initial' ? 'Blokada została włączona' : 'Blokada została wyłączona'

      dispatch(
        updateAccountingBookingRefund(
          await commonObjectPut<BookingRefund>(booking.urls.block, {
            deposit_transfer_status: booking.deposit_transfer_status === 'p24_initial' ? 'p24_blocked' : 'p24_initial',
          }),
        ),
      )
      addSuccessNotification(message)
    }, [booking]),
  )

  if (booking.deposit_transfer_status === 'p24_initial' || booking.deposit_transfer_status === 'p24_blocked') {
    return (
      <CommonObjectConfirmAction
        handleAccept={onAccept}
        isLoading={isLoading}
        message={
          booking.deposit_transfer_status === 'p24_initial'
            ? 'Czy na pewno chcesz zablokować zwrot automatyczny?'
            : 'Czy na pewno chcesz odblokować zwrot automatyczny?'
        }
      >
        {booking.deposit_transfer_status === 'p24_initial' ? (
          <span className="uil uil-lock text-muted font-16 cursor-pointer mr-1" title="Zalbokuj zwrot" />
        ) : (
          <span className="uil uil-lock-open-alt text-muted font-16 cursor-pointer mr-1" title="Zalbokuj zwrot" />
        )}
      </CommonObjectConfirmAction>
    )
  }

  return null
}
