import * as React from 'react'
import { CashbackVoucher } from '@modules/products/cashback-vouchers/models'
import Table from '@components/table/table'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { formatPrice } from '@helpers/utils'
import classNames from 'classnames'
import { getCashbackVoucherStatusColor } from '@modules/products/cashback-vouchers/helpers'
import { useDetailsModal } from '@components/hooks/use-details-modal'
import { NavigationPath } from '@models/routes'

interface Props {
  cashbackVoucher: CashbackVoucher
}

export const CashbackVouchersTableRow: React.FC<Props> = ({ cashbackVoucher }) => {
  const { handleClientDetails } = useDetailsModal(
    ['CashbackVoucherModal', { id: cashbackVoucher.id }],
    NavigationPath.CashbackVouchers,
    NavigationPath.CashbackVouchersWithParams,
    cashbackVoucher.id,
  )

  return (
    <Table.Row onDoubleClick={handleClientDetails} className="cursor-pointer" role="details-row">
      <Table.Cell>{cashbackVoucher.number}</Table.Cell>
      <Table.Cell>
        <span className={classNames('badge', `badge-${getCashbackVoucherStatusColor(cashbackVoucher.status)}`)}>
          {cashbackVoucher.status_display}
        </span>
      </Table.Cell>
      <Table.Cell>
        {cashbackVoucher.client.name} <span className="font-10 d-block">{cashbackVoucher.client.email}</span>
      </Table.Cell>
      <Table.Cell>
        {cashbackVoucher.source_booking.reservation_number}
        <span className="font-10 d-block">
          {toDefaultDateFormat(cashbackVoucher.source_booking.date_from)} -{' '}
          {toDefaultDateFormat(cashbackVoucher.source_booking.date_to)}
        </span>
      </Table.Cell>
      <Table.Cell>{formatPrice(cashbackVoucher.rest_to_use)}</Table.Cell>
      <Table.Cell>
        {cashbackVoucher.assign_booking ? (
          <>
            {cashbackVoucher.assign_booking.reservation_number}
            <span className="font-10 d-block">
              {toDefaultDateFormat(cashbackVoucher.assign_booking.date_from)} -{' '}
              {toDefaultDateFormat(cashbackVoucher.assign_booking.date_to)}
            </span>
          </>
        ) : (
          'brak'
        )}
      </Table.Cell>
      <Table.Cell>{toDefaultDateFormat(cashbackVoucher.expire_after)}</Table.Cell>
    </Table.Row>
  )
}
