import * as React from 'react'
import { Badge, Col, Row } from 'reactstrap'
import classNames from 'classnames'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { useModal } from '@components/modals/use-modal'
import { Agreement, AgreementAnnex } from '@modules/hr/agreement/models'
import { useHrAppData } from '@modules/hr/use-hr-app-data'
import { getById } from '@helpers/utils'
import { getAgreementAnnexNumber } from '@modules/hr/agreement/annexes/helpers'

interface Props {
  annex: AgreementAnnex
  agreement: Agreement
  isSubsection?: boolean
  isFirstChild?: boolean
}

export const AgreementAnnexCard = ({ annex, agreement, isSubsection, isFirstChild }: Props): JSX.Element => {
  const { annex_types } = useHrAppData()
  const [showDetailsModal] = useModal('AgreementAnnexDetailsModal', { annex, agreement })

  const annexType = getById(annex_types, annex.document_type)
  const sectionStyles = isSubsection ? subSectionsStyles : undefined
  const isActive = annex.status === 'active'

  return (
    <Row
      className={classNames(
        'align-items-center mb-1 border mr-0 position-relative',
        isActive ? 'bg-white' : 'bg-light-grey',
      )}
      style={sectionStyles?.row}
    >
      {isSubsection && <div className={classNames('hr__annex-list-indent', { 'is-first': isFirstChild })} />}
      <div className="col-2" style={sectionStyles?.status}>
        <Badge className={classNames('px-2', isActive ? 'bg-success text-white' : 'bg-white-smoke text-default')}>
          {isActive ? 'Aktywny' : 'Nieaktywny'}
        </Badge>
      </div>
      <Item title="Numer aneksu" value={getAgreementAnnexNumber(annex.number)} style={sectionStyles?.number} />
      <Item title="Typ aneksu" value={annexType?.name} style={sectionStyles?.type} />
      <Item title="Obowiązuje od" value={toDefaultDateFormat(annex.effective_date)} />
      <Col md={1} className="text-right" style={sectionStyles?.detailsIcon}>
        <i className="uil-eye font-16 cursor-pointer" onClick={showDetailsModal} />
      </Col>
    </Row>
  )
}

const Item = ({ title, value, style = {} }) => (
  <div className="col-3 p-2" style={style}>
    <small className="d-block font-11">{title}</small>
    <strong>{value}</strong>
  </div>
)

const subSectionsStyles = {
  row: { marginLeft: 50 },
  status: { maxWidth: 110 },
  number: { minWidth: 313 },
  type: { maxWidth: 200 },
  detailsIcon: { marginLeft: 49, paddingRight: '0px !important' },
} as const
