import * as React from 'react'
import { TechnicalOrdersDoneTable } from '@modules/technical-orders/technical-orders-done/table'
import { TechnicalOrdersDoneReportDownloadButton } from '@modules/technical-orders/technical-orders-done/report-download-button'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'

interface Props {
  resortId: number
}

export const TechnicalOrdersDone: React.FC<Props> = ({ resortId }) => {
  const user = useAuthenticatedUser()

  return (
    <div>
      <TechnicalOrdersDoneTable resortId={resortId} />
      {user.hasPerm(UserPermission.ReportTechnicalOrderReport) && <TechnicalOrdersDoneReportDownloadButton />}
    </div>
  )
}
