import { LoginUIComponent } from '@components/login-ui-component'
import { NavigationPath } from '@models/routes'
import * as React from 'react'

export const FailureView: React.FC = () => (
  <LoginUIComponent>
    <h2 className="unauthorized__header">Wystąpił błąd</h2>
    <p className="unauthorized__text">
      Nie udało sie pobrać kluczowych danych dla poprawnego działania serwisu. Spróbuj zalogować się później.
    </p>
    <a href={`/dashboard${NavigationPath.AuthorizationLogin}`} className="btn btn-secondary btn-block">
      Zaloguj się
    </a>
  </LoginUIComponent>
)
