import * as React from 'react'
import { useDocumentTitle } from '@helpers/utils'
import { PageTitle } from '@hyper/page-title'
import { Card, CardBody } from 'reactstrap'
import { useAppDispatch, useAppSelector } from '@store/index'
import { getFeedingSpecialGuests } from '@store/actions/feeding-actions'
import { FeedingSpecialGuestTable } from '@modules/feeding/special-guests/table'
import { TableFilters } from '@components/table/table'
import { useApiRequest } from '@components/hooks/use-api-request'
import { FeedingSpecialGuestFilters } from '@modules/feeding/special-guests/filters'
import { useDebounce } from 'rooks'
import { feedingSpecialGuestsSelector } from '@store/slices/feeding-slice'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'

export interface FeedingSpecialGuestFiltersParams extends TableFilters {
  is_active: 'true' | 'false' | ''
}
export const FeedingSpecialGuest: React.FC = () => {
  const defaultFilters: FeedingSpecialGuestFiltersParams = {
    ordering: '-created_at',
    page: 1,
    page_size: 10,
    search: '',
    is_active: '',
  }
  const [filters, setFilters] = React.useState<FeedingSpecialGuestFiltersParams>(defaultFilters)
  const { isLoading, action: fetchFeedingSpecialGuests } = useApiRequest(
    async filters => await dispatch(getFeedingSpecialGuests(filters)),
  )

  useMenuActive(NavigationPath.FeedingSpecialGuest)

  const pageTitle = 'Wyżywienie goście specjalni'
  useDocumentTitle(pageTitle)

  const dispatch = useAppDispatch()

  const fetchFeedingSpecialGuestsDebounced = React.useCallback(useDebounce(fetchFeedingSpecialGuests, 300), [])
  React.useEffect(() => {
    fetchFeedingSpecialGuestsDebounced(filters)
  }, [filters])

  const specialGuests = useAppSelector(feedingSpecialGuestsSelector)

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitle} />

      <Card>
        <CardBody>
          <FeedingSpecialGuestFilters defaultFilters={defaultFilters} filters={filters} setFilters={setFilters} />

          <FeedingSpecialGuestTable
            isLoading={isLoading}
            filters={filters}
            setFilters={setFilters}
            clients={specialGuests}
          />
        </CardBody>
      </Card>
    </>
  )
}
