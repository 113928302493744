import * as React from 'react'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { Unit } from '@models/units'
import { UnitsTableRow } from './row'
import { UnitsTableHeadersType } from './table-headers/type'
import { UnitsTableHeadersHousekeepingStatus } from './table-headers/housekeeping_status'

interface Props {
  units: Unit[]
  setFilters: React.Dispatch<React.SetStateAction<TableFilters>>
  filters: TableFilters
  dataTestId?: string
  isLoading: boolean
}

export const UnitsTable: React.FC<Props> = ({ isLoading, dataTestId, filters, setFilters, units }) => {
  const headers: TableHeaderValue[] = React.useMemo(
    () => [
      { title: <UnitsTableHeadersType setFilters={setFilters} /> },
      { title: 'Nazwa jednostki', sortField: 'kind__position,position' },
      { title: <UnitsTableHeadersHousekeepingStatus setFilters={setFilters} /> },
      { title: 'Liczba usterek', sortField: 'technical_order_count' },
      { title: 'Najbliższy pobyt', sortField: 'next_booking__date_from' },
      { title: '' },
    ],
    [setFilters],
  )
  return (
    <Table
      dataTestId={dataTestId}
      headerClassName="bg-white border-top-0 vertical-align-middle"
      className="table-hover w-100"
      headerValues={headers}
      isLoading={isLoading}
      filters={filters}
      setFilters={setFilters}
    >
      {units.map(unit => (
        <UnitsTableRow key={unit.id} unit={unit} />
      ))}
    </Table>
  )
}
