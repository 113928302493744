import * as React from 'react'

import { Button, Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { CopyToClipboard } from '@components/copy-to-clipboard'
import { getDashboardAppUrl } from '@helpers/utils'
import { NavigationPath } from '@models/routes'
import { useApiRequest } from '@components/hooks/use-api-request'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { getPackageWholesaleDetails, getPackageWholesaleProducts } from '@store/actions/package-wholesale-actions'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { PackageWholesaleDetailsTabs } from '@modules/package-wholesale/details/tabs'
import { PackageWholesaleDetailsNotes } from '@modules/package-wholesale/details/notes'
import { PackageWholesaleDetailsNotifications } from '@modules/package-wholesale/details/notification/notifications'
import { PackageWholesaleDetailsClientInformation } from '@modules/package-wholesale/details/client'
import { PackageWholesaleDetailsBaseInformation } from '@modules/package-wholesale/details/base'
import { PackageWholesaleDetailsSubscriptions } from './subscriptions'
import { BaseModalProps } from '@components/modals/types'
import { PackageWholesaleDetailsGastroCards } from '@modules/package-wholesale/details/gastro_cards'
import { BasePackageWholesale } from '@modules/package-wholesale/models'
import { PackageWholesaleDetailsAnalysis } from '@modules/package-wholesale/details/analysis'
import { HistoryBox } from '@modules/promotions/common/history-box'
import { GastroCardWebSocketHandler } from '@modules/promotions/gastro-cards/gastro-card-web-socket-handler'

interface Props extends BaseModalProps {
  packageWholesale: BasePackageWholesale
}

export const PackageWholesaleDetailsModal: React.FC<Props> = ({ packageWholesale, toggleIsVisible }) => {
  const dispatch = useAppDispatch()
  const packageWholesaleDetails = useAppSelector(
    (state: RootState) => state.packageWholesaleState.packageWholesaleDetails,
  )
  const notesRef = React.useRef<HTMLDivElement>(null)
  const informationRef = React.useRef<HTMLDivElement>(null)
  const subscriptionsRef = React.useRef<HTMLDivElement>(null)
  const analysisRef = React.useRef<HTMLDivElement>(null)

  const { isLoading, action: fetch } = useApiRequest(async () => await refreshPackage())

  const refreshPackage = async () => await dispatch(getPackageWholesaleDetails(packageWholesale.urls.details))

  React.useEffect(() => {
    fetch()
    dispatch(getPackageWholesaleProducts({ page_size: 9999 }))
  }, [])

  const copyValue = React.useMemo(
    () =>
      `${getDashboardAppUrl()}${NavigationPath.PackageWholesaleListWithParams.replace(
        ':id',
        String(packageWholesale.id),
      )}`,
    [packageWholesale],
  )

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>
        Pakiet hurtowy <span className="text-semi-strong">{packageWholesale.number}</span>
        <CopyToClipboard className="uil-share-alt ml-1" value={copyValue} />
      </ModalHeader>
      <ModalBody className="modal-details">
        <LoaderPlaceholder content={!isLoading}>
          {packageWholesaleDetails && (
            <>
              <div className="modal-details__tabs-wrapper">
                <PackageWholesaleDetailsTabs
                  subscriptionsRef={subscriptionsRef}
                  notesRef={notesRef}
                  informationRef={informationRef}
                  analysisRef={analysisRef}
                />
              </div>
              <div ref={informationRef} />
              <Row>
                <Col md={6}>
                  <PackageWholesaleDetailsBaseInformation packageWholesaleDetails={packageWholesaleDetails} />
                </Col>
                <Col md={6}>
                  <PackageWholesaleDetailsClientInformation packageWholesaleDetails={packageWholesaleDetails} />
                </Col>
              </Row>
              <div ref={subscriptionsRef} />
              <Row>
                <Col md={12}>
                  <PackageWholesaleDetailsSubscriptions subscriptions={packageWholesaleDetails.subscriptions} />
                  <PackageWholesaleDetailsGastroCards gastroCards={packageWholesaleDetails.gastro_cards}>
                    {!!packageWholesaleDetails.gastro_cards.length && <GastroCardWebSocketHandler kind="wholesale" />}
                  </PackageWholesaleDetailsGastroCards>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <PackageWholesaleDetailsAnalysis
                    packageWholesaleDetails={packageWholesaleDetails}
                    ref={analysisRef}
                  />
                </Col>
              </Row>
              <div ref={notesRef} />
              <Row>
                <Col md={6}>
                  <PackageWholesaleDetailsNotes packageWholesaleDetails={packageWholesaleDetails} />
                  <PackageWholesaleDetailsNotifications packageWholesaleDetails={packageWholesaleDetails} />
                </Col>
                <Col md={6}>
                  <HistoryBox history={packageWholesaleDetails.history} title="Historia zmian" />
                </Col>
              </Row>
            </>
          )}
        </LoaderPlaceholder>
      </ModalBody>
      <ModalFooter>
        <Button className="btn btn-light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
      </ModalFooter>
    </>
  )
}
