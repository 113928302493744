import * as React from 'react'
import { Alert } from 'reactstrap'

export const ReceptionBookingNewFeedingSummaryGastroVoucherAlert = (): JSX.Element => (
  <Alert className="py-1 px-1 alert-info ">
    <strong className="font-12">Uwaga! </strong>
    <span className="font-12">
      Opłacenie wyżywienia <strong>voucherem gastronomicznym</strong>
      <strong className="feeding__voucher mx-1" title="Opłacony przez voucher gastronomiczny">
        <i className="uil-tag-alt" />
      </strong>
      powoduje naliczenie bieżących cen katalogowych. Wszystkie dotychczas nadane ceny i rabaty zostaną wycofane. Jeżeli
      suma wartości wybranego wyżywienia przekracza wartość vouchera, konieczna jest dopłata innym sposobem płatności.
      Wycofanie pobrania środków z vouchera, przywraca ustawione wcześniej ceny i rabaty{' '}
      <span className="text-nowrap">(z momentu zamówienia posiłków).</span>
    </span>
  </Alert>
)
