import * as React from 'react'
import { StepBillDamagesSingleSummaryElement } from '@modules/reception/checkout/step-bill/step-bill-damages-single-summary-element'
import { ReceptionBookingDamage } from '@models/reception'

interface Props {
  damages: ReceptionBookingDamage[]
}

export const StepBillDamagesSummary: React.FC<Props> = ({ damages }) => {
  if (!damages.length) {
    return null
  }

  return (
    <>
      <div className="hr hr-grey my-3" />
      <strong className="d-block mb-2">Opłaty dodatkowe</strong>
      <ul className="list-unstyled">
        {damages.map((damage: ReceptionBookingDamage) => (
          <StepBillDamagesSingleSummaryElement damage={damage} key={damage.id} />
        ))}
      </ul>
    </>
  )
}
