import * as React from 'react'

export const useScrollableTabs = (tabRefs: React.RefObject<HTMLElement>[], onTabChange: (tab: string) => void) => {
  React.useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null

    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        if (entry.isIntersecting) {
          if (timeoutId) {
            clearTimeout(timeoutId)
            timeoutId = null
          }

          timeoutId = setTimeout(() => {
            onTabChange(entry.target.id)
          }, 300)
        }
      })
    }

    const observer = new IntersectionObserver(observerCallback, { threshold: 0.8, rootMargin: '-10% 0% -20% 0%' })

    const observeTab = (ref: React.RefObject<HTMLElement>) => {
      if (ref.current) observer.observe(ref.current)
    }

    const unobserveTab = (ref: React.RefObject<HTMLElement>) => {
      if (ref.current) observer.unobserve(ref.current)
    }

    tabRefs.forEach(observeTab)

    return () => {
      if (timeoutId) clearTimeout(timeoutId)

      tabRefs.forEach(unobserveTab)
    }
  }, [])
}
