import * as React from 'react'
import { NotificationEvents } from '@models/dashboard'
import { useAppDispatch } from '@store/index'
import { useWebSocket } from '@components/hooks/use-web-socket'
import { SubscriptionDetails, SubscriptionProduct, SubscriptionType } from '@modules/subscription/models'
import { updateSubscriptionDetails, updateSubscriptionProductDetails } from '@store/slices/subscription-slice'

interface Props {
  type: SubscriptionType
}

export const SubscriptionWebSocketHandler: React.FC<Props> = ({ type }) => {
  const dispatch = useAppDispatch()

  useWebSocket<SubscriptionDetails | SubscriptionProduct>(
    (message, payload) => {
      switch (message) {
        case NotificationEvents.SUBSCRIPTION_CHANGE:
          if ((payload as SubscriptionDetails).type === type) {
            dispatch(updateSubscriptionDetails(payload as SubscriptionDetails))
          }
          break
        case NotificationEvents.SUBSCRIPTION_PRODUCT_CHANGE:
          dispatch(updateSubscriptionProductDetails(payload as SubscriptionProduct))
          break
      }
    },
    [],
    [NotificationEvents.SUBSCRIPTION_CHANGE, NotificationEvents.SUBSCRIPTION_PRODUCT_CHANGE],
  )
  return null
}
