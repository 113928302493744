import * as React from 'react'
import { ModalBody } from 'reactstrap'
import classNames from 'classnames'
import { CUSTOM_AXIOS_ERROR } from '@helpers/handle-axios-errors'

export interface ReceptionBookingCheckStep {
  step: number
  title?: string
  description: React.ReactNode
  fields?: string[]
}

interface Props {
  step: number
  steps: ReceptionBookingCheckStep[]
  onStepClick?: (step: number) => void
  clickableSteps?: number[]
}

export const ReceptionBookingCheckSteps: React.FC<Props> = ({ step, steps, onStepClick, clickableSteps }) => {
  const isClickable = (step: number) => !!onStepClick && !!clickableSteps?.includes(step)

  const handleStepClick = (clickedStep: number) => () => {
    if (clickableSteps?.includes(clickedStep) && onStepClick) {
      onStepClick(clickedStep)
    }
  }

  return (
    <ModalBody className="bg-grey reception__booking-check-in__steps" role="steps">
      <div className="reception__booking-check-in__steps__wrapper">
        {steps.map((value: ReceptionBookingCheckStep) => (
          <div
            key={value.step}
            className={classNames('reception__booking-check-in__steps__step', {
              'is-active': step === value.step,
              'cursor-pointer': isClickable(value.step),
            })}
            onClick={handleStepClick(value.step)}
          >
            <div
              className={classNames('reception__booking-check-in__steps__step__dot-wrapper', {
                'is-active': step === value.step,
                'is-complete': step > value.step,
              })}
            >
              <div
                className={classNames('reception__booking-check-in__steps__step__dot', {
                  'is-active': step === value.step,
                  'is-complete': step > value.step,
                })}
              />
            </div>

            <strong className="reception__booking-check-in__steps__step__name" title={value.title}>
              Krok <span className="text-strong">{value.step}</span>
            </strong>

            <span className="reception__booking-check-in__steps__step__description">{value.description}</span>
          </div>
        ))}
      </div>
    </ModalBody>
  )
}

export const showStepError = (
  setStep: (step: number) => void,
  steps: ReceptionBookingCheckStep[],
  error,
  nonFieldErrorsAs?: string,
) => {
  const keys = Object.keys(error.name === CUSTOM_AXIOS_ERROR ? JSON.parse(error.message) : error.response?.data)
  if (keys.length) {
    const key = keys[0] === 'non_field_errors' && nonFieldErrorsAs ? nonFieldErrorsAs : keys[0]
    const showStep = steps.find(row => row.fields?.includes(key))
    if (showStep) {
      setStep(showStep.step)
    }
  }
}
