import * as React from 'react'
import { useModal } from '@components/modals/use-modal'
import { ButtonWithIcon } from '@components/button-with-icon'

interface Props {
  name: string
  createUrl: string
}

export const BarriersBaseListCreate = ({ name, createUrl }: Props): JSX.Element => {
  const [handleClick] = useModal('BarrierTicketModal', { name, createUrl })

  return (
    <div className="text-right mb-1">
      <ButtonWithIcon icon="uil-plus mr-1" text={`Dodaj ${name}`} btnClass="btn btn-green" handleClick={handleClick} />
    </div>
  )
}
