import * as React from 'react'
import { useDeferredValue } from 'react'
import { Col, FormGroup, Label, Row } from 'reactstrap'
import DateRangeInput from '@components/date/date-range-input'
import { AccommodationType, Resort } from '@models/booking'
import { useFormContext } from 'react-hook-form'
import { ReservationCreateFormInputs } from '@modules/reservations/create/models'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { createSelectOption } from '@helpers/utils'
import { receptionBookingDetailsSelector } from '@store/selectors/reception'
import { StepReservationApartmentSelection } from '@modules/reservations/create/step-reservation-info/step-reservation-apartment-selection'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { FormPlainError } from '@hyper/forms'
import { selectReservationAvailableApartments } from '@store/slices/reception-slice'
import { FetchAvailableApartmentsParams, getAvailableApartments } from '@store/actions/reception-actions'
import { useApiRequest } from '@components/hooks/use-api-request'
import { StepClientBox } from '@modules/reservations/create/client-box'
import { ClientUser } from '@models/clients'
import { startOfToday } from 'date-fns'
import { FormSelect } from '@hyper/forms/form-select'
import { StepClientSubscriptions } from '@modules/reservations/create/step-reservation-info/step-client-subscriptions'
import {
  ACCOMMODATION_TYPES,
  HOUSE_85_ACCOMMODATION_TYPE,
  HOUSE_85_WITH_GARDEN_ACCOMMODATION_TYPE,
} from '@helpers/consts'

interface Props {
  resorts: Resort[]
  isStepActive: (name: string) => boolean
  onSubmitDisabledChange: (isDisabled: boolean) => void
  client: ClientUser | undefined
  setClient: (client: ClientUser | undefined) => void
}

export const StepReservationApartmentBaseInformation: React.FC<Props> = ({
  resorts,
  isStepActive,
  onSubmitDisabledChange,
  setClient,
  client,
}) => {
  const allApartments = useAppSelector(selectReservationAvailableApartments)
  const reservation = useAppSelector(receptionBookingDetailsSelector)
  const dispatch = useAppDispatch()

  const { watch, resetField, clearErrors, formState } = useFormContext<ReservationCreateFormInputs>()
  const { resort, date_from, date_to, accommodation_type, promocode } = watch()

  const lastSeason = Math.max(...useAppSelector((state: RootState) => state.appState.appData.dates.seasons))

  const availableApartments = allApartments.filter(apartment => {
    if (!accommodation_type?.value || !apartment.is_available) return false

    if (
      ACCOMMODATION_TYPES.HOUSES.includes(apartment.accommodation_type_id) &&
      ACCOMMODATION_TYPES.HOUSES.includes(accommodation_type.value)
    ) {
      if (accommodation_type.value === HOUSE_85_WITH_GARDEN_ACCOMMODATION_TYPE) {
        return apartment.has_garden_fixed
      }

      if (accommodation_type.value === HOUSE_85_ACCOMMODATION_TYPE) {
        return !apartment.has_garden_fixed
      }
    }

    return accommodation_type.value === apartment.accommodation_type_id
  })

  const resortOptions = resorts.map((resort: Resort) => createSelectOption<string, number>(resort.name, resort.id))

  const accommodationTypeOptions = React.useMemo(() => {
    const accommodationTypesForResort = resorts.find((el: Resort) => el.id === resort?.value)
    return (
      accommodationTypesForResort?.accommodation_types.map((el: AccommodationType) =>
        createSelectOption<string, number>(el.name, el.id),
      ) || []
    )
  }, [resort])

  const { isLoading: isFetchingApartments, action: fetchAvailableApartments } = useApiRequest(
    async (params: FetchAvailableApartmentsParams) => {
      await dispatch(getAvailableApartments(params))
    },
  )

  React.useEffect(() => {
    if (
      resort?.value !== reservation?.resort_id ||
      accommodation_type?.value !== reservation?.apartment?.accommodation_type
    ) {
      resetField('date_from')
      resetField('date_to')
    }
  }, [resort, accommodation_type])

  React.useEffect(() => {
    if (!date_from || !date_to) {
      resetField('resort')
      resetField('apartment')
      resetField('accommodation_type')
      resetField('localSelection')
    }
  }, [date_from, date_to])

  useDidUpdateEffect(() => {
    resetField('apartment')
  }, [accommodation_type])

  useDidUpdateEffect(() => {
    clearErrors()
    if (resort?.value && accommodation_type?.value && date_from && date_to) {
      fetchAvailableApartments({
        client: client?.id,
        resort: resort?.value,
        accommodation_type: accommodation_type?.value,
        date_from,
        date_to,
        code: promocode?.value,
      })
    }
  }, [resort, accommodation_type, date_from, date_to, promocode])

  const isDataFilled = !!resort && !!accommodation_type && !!date_from && !!date_to
  const isDateInvalid = useDeferredValue(!isFetchingApartments && isDataFilled && availableApartments.length <= 0)

  React.useEffect(() => onSubmitDisabledChange(isDateInvalid), [isDateInvalid])

  return (
    <>
      <Row>
        <Col md={7} className="px-0">
          <StepClientBox client={client} setClient={setClient} isDisabled={false} colSize={12} />
        </Col>
        {client && (
          <Col md={5} className="pt-5">
            <StepClientSubscriptions client={client} />
          </Col>
        )}
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup className="mb-0 w-100">
            <Label htmlFor="date_range" className="d-flex justify-content-between">
              <span>Zakres dat:</span>
              {resort?.value && date_to && date_from && accommodation_type?.value && (
                <span className="font-11 font-weight-normal align-self-end">
                  <span>{isFetchingApartments ? '...' : availableApartments.length} wolnych lokali</span>
                </span>
              )}
            </Label>
            <DateRangeInput
              inputGroupClassName="date-range--full-width"
              inputClassName="text-dark"
              minDate={startOfToday()}
              maxDate={new Date(lastSeason, 12, 31)}
              startDateName="date_from"
              endDateName="date_to"
              isInvalid={isDateInvalid}
              isClearable={true}
              navigatorYearsRenderer={years => years.sort((a, b) => a - b)}
            />
            {isDateInvalid && !formState.errors.date_from && (
              <div className="invalid-feedback d-block">Wybrany termin jest niedostępny</div>
            )}
            <FormPlainError name="date_from" />
          </FormGroup>
        </Col>

        <FormSelect
          formPlainProps={{ colSize: 6 }}
          label="Ośrodek:"
          options={resortOptions}
          name="resort"
          selectProps={{ placeholder: 'Wybierz ośrodek', isDisabled: !isStepActive('date_to') }}
        />
      </Row>

      <Row className="mt-2">
        <FormSelect
          formPlainProps={{ colSize: 6 }}
          label="Typ lokalu:"
          options={accommodationTypeOptions}
          name="accommodation_type"
          selectProps={{
            placeholder: 'Wybierz typ lokalu',
            isDisabled: !isStepActive('resort'),
            noOptionsMessage: () => 'Brak wolnych lokali',
          }}
        />

        <StepReservationApartmentSelection isStepActive={isStepActive} availableApartments={availableApartments} />
      </Row>
    </>
  )
}
