import * as React from 'react'
import { formatPrice } from '@helpers/utils'

interface Props {
  restToPay: string
}

export const ReservationBillSummary = ({ restToPay }: Props): JSX.Element => (
  <tr>
    <td colSpan={5} className="p-0">
      <table className="table table-sm col-9 ml-auto mb-0 bg-grey-light border border-top-0 border-light">
        <tbody>
          <tr className="bg-grey font-14 fw-semi-bold">
            <td className="pl-3">Pozostało do zapłaty</td>
            <td className="text-right">{formatPrice(restToPay)}</td>
            <td width="35" />
          </tr>
        </tbody>
      </table>
    </td>
  </tr>
)
