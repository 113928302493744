import { CashRegister, CompanyDataFromGus, SaleAppData } from '@models/sale'
import store, { RootState } from '@store/index'
import axios from 'axios'
import { ThunkAction } from 'redux-thunk'
import { commonObjectGet } from '@store/actions/generic-actions'
import {
  SaleActionTypesKeys,
  SetCashRegistersAction,
  SetCashRegistersExtraDataAction,
  SetSaleAppDataAction,
} from '@store/actions-types/sale-actions-types'
import { SetDashboardStandardPaginatorAction } from '@store/actions-types/dashboard-actions-types'
import { formatDate } from '@helpers/date-helper'
import { setDashboardStandardPaginator } from './dashboard-actions'
import { PaginationResponseWithAggregation } from '@models/dashboard'
import { CashRegisterListFilterParams } from '@modules/sale/cash-register/list/filter'
import { CashRegistersExtraData } from '@store/reducers/sale-reducers'

export async function getCompanyDataFromGus(nip: string): Promise<CompanyDataFromGus> {
  const url = store.getState().appState.appData.urls.sale.nip_search

  const { data } = await axios.get(url, {
    params: {
      nip,
    },
  })

  return data
}

export function setCashRegistersAction(payload: CashRegister[]): SetCashRegistersAction {
  return {
    payload,
    type: SaleActionTypesKeys.SET_CASH_REGISTERS,
  }
}

export function setCashRegistersExtraDataAction(payload: CashRegistersExtraData): SetCashRegistersExtraDataAction {
  return {
    payload,
    type: SaleActionTypesKeys.SET_CASH_REGISTERS_DATA,
  }
}

export function getCashRegisters(
  filters: CashRegisterListFilterParams,
): ThunkAction<
  void,
  RootState,
  null,
  SetCashRegistersAction | SetDashboardStandardPaginatorAction | SetCashRegistersExtraDataAction
> {
  return async (dispatch, getState) => {
    const url = getState().appState.appData.urls.sale.cash_registers

    const data = await commonObjectGet<PaginationResponseWithAggregation<CashRegister, CashRegistersExtraData>>(url, {
      ...filters,
      created_after: formatDate(filters?.created_after),
      created_before: formatDate(filters?.created_before),
      type: filters?.type?.value,
      company: filters?.company?.value,
    })

    dispatch(setCashRegistersAction(data.results))
    dispatch(setCashRegistersExtraDataAction(data.aggregation))
    dispatch(setDashboardStandardPaginator({ ...data }))
  }
}

export function getSaleAppData(): ThunkAction<Promise<SaleAppData>, RootState, null, SetSaleAppDataAction> {
  return async (dispatch, getState) => {
    const url = getState().appState.appData.urls.sale.app_data
    const appData = getState().saleState.appData

    if (appData.status === 'unknown') {
      dispatch(setSaleAppData({ ...appData, status: 'loading' }))

      const data = await commonObjectGet<SaleAppData>(url)

      dispatch(setSaleAppData({ ...data, status: 'ready' }))
      return data
    }
    return appData
  }
}

export function setSaleAppData(payload: SaleAppData): SetSaleAppDataAction {
  return { type: SaleActionTypesKeys.SET_APP_DATA, payload }
}
