import * as React from 'react'

import {
  addDays,
  addMonths,
  eachDayOfInterval,
  format,
  getDaysInMonth,
  intervalToDuration,
  isBefore,
  isToday,
  isWeekend,
  parseISO,
  set,
  startOfToday,
  subMonths,
} from 'date-fns'
import { CalendarDate } from '@store/reducers/timeline-reducers'
import { pl } from 'date-fns/locale'

const dateHelper = {
  createDateFormat: (y: number, m: number, d: number): string =>
    `${y}-${dateHelper.getFormat00(m)}-${dateHelper.getFormat00(d)}`,
  getDaysInMonth: (y: number, m: number): number => getDaysInMonth(parseISO(dateHelper.createDateFormat(y, m, 1))),
  daysArray: (m: number, y: number): number[] =>
    Array.from({ length: dateHelper.getDaysInMonth(y, m) }, (_, i) => i + 1),
  getDayName: (y: number, m: number, d: number, dateFormat = 'cccccc'): string =>
    format(parseISO(dateHelper.createDateFormat(y, m, d)), dateFormat, {
      locale: pl,
    }),
  getMonthName: (y: number, m: number, dateFormat = 'LLLL'): string =>
    format(parseISO(dateHelper.createDateFormat(y, m, 1)), dateFormat, {
      locale: pl,
    }),
  isWeekendDay: (y: number, m: number, d: number): boolean => isWeekend(parseISO(dateHelper.createDateFormat(y, m, d))),
  isToday: (y: number, m: number, d: number): boolean => isToday(parseISO(dateHelper.createDateFormat(y, m, d))),
  isFirstDay: (y: number, m: number, d: number): boolean => isToday(parseISO(dateHelper.createDateFormat(y, m, d))),
  getFormat00: (n: number): string => (n < 10 ? '0' : '') + n,
  isNextYear: (m: number): boolean => m > 12,
  isPrevYear: (m: number): boolean => m < 1,
  getPrevMonth: (y: number, m: number, d: number): Date => subMonths(parseISO(dateHelper.createDateFormat(y, m, d)), 1),
  getNextMonth: (y: number, m: number, d: number): Date => addMonths(parseISO(dateHelper.createDateFormat(y, m, d)), 1),
  parseISODate: (value: string | Date): Date => (typeof value === 'string' ? parseISO(value) : value),
  getPrevCalendarDate: (calendarDate: CalendarDate): CalendarDate => ({
    month: dateHelper.isPrevYear(calendarDate.month - 1) ? 12 : calendarDate.month - 1,
    year: dateHelper.isPrevYear(calendarDate.month - 1) ? calendarDate.year - 1 : calendarDate.year,
  }),
  getNextCalendarDate: (calendarDate: CalendarDate): CalendarDate => ({
    month: dateHelper.isNextYear(calendarDate.month + 1) ? 1 : calendarDate.month + 1,
    year: dateHelper.isNextYear(calendarDate.month + 1) ? calendarDate.year + 1 : calendarDate.year,
  }),
  getMiddleCalendarDate: (calendarDates: CalendarDate[]): CalendarDate =>
    calendarDates[Math.floor(calendarDates.length / 2)],
  isBeforeOrToday: (date: string): boolean => isBefore(parseISO(date), startOfToday()) || isToday(parseISO(date)),
  getEachDayFromRange: (dateFrom: string, dateTo: string): string[] =>
    eachDayOfInterval({ start: parseISO(dateFrom), end: parseISO(dateTo) }).map(date => formatDate(date)),
}

export default dateHelper
export const formatDate = (date: Date | undefined | string | null, dateFormat = 'yyyy-MM-dd'): string => {
  if (date === null) {
    return ''
  }
  if (typeof date === 'string') {
    return format(parseISO(date), dateFormat, { locale: pl })
  }
  if (typeof date !== 'undefined') {
    return format(date, dateFormat, { locale: pl })
  }
  return date ? date : ''
}

export const parseISODate = (value: string | Date | null | undefined): Date | null => {
  if (!value) {
    return null
  }
  if (typeof value === 'string') {
    return parseISO(value)
  }
  return value
}

export const defaultFrontendDate = 'dd.MM.yyyy'
export const defaultFrontendDateTime = 'dd.MM.yyyy HH:mm'
export const defaultFrontendTime = 'HH:mm'
export const dateWithShortTextMonth = 'dd MMM yyyy'
export const dateWithFullTextMonth = 'dd MMMM yyyy'

export const toDefaultDateTimeFormatWithBrake = (value: string | Date | null, breakClassName = 'd-block') => {
  const date = parseISODate(value)
  return (
    <>
      {toDefaultDateFormat(date)}
      <div className={breakClassName}>{toDefaultTimeFormat(date)}</div>
    </>
  )
}

export const toTextDateFormat = (value: string | Date | null) => formatDate(parseISODate(value), 'd LLLL RRRR')

export const toTextDateTimeFormat = (value: string | Date | null) =>
  toTextDateFormat(value) + ' godz.: ' + toDefaultTimeFormat(value)

export const toDefaultDateTimeFormat = (value: string | Date | null) =>
  formatDate(parseISODate(value), defaultFrontendDateTime)

export const toDefaultDateFormat = (value: string | Date | null | undefined) =>
  formatDate(parseISODate(value || null), defaultFrontendDate)

export const toDefaultTimeFormat = (value: string | Date | null | undefined, format = defaultFrontendTime) =>
  formatDate(parseISODate(value || null), format)

export const secondsToInterval = seconds => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 })

  const hours = (duration?.hours || 0) + (duration?.days || 0) * 24

  return `${String(hours).padStart(2, '0')}h ${String(duration.minutes).padStart(2, '0')}m ${String(
    duration.seconds,
  ).padStart(2, '0')}s`
}

export const getHoursMinutesFromInterval = (interval: string) => {
  const [hours, minutes] = interval.split(' ')

  return `${hours} ${minutes}`
}

export const createCombinedDateWithTime = (date: string, time: string): Date => {
  const [hours, minutes] = time.split(':')
  return set(parseISODate(date) || 0, {
    hours: parseInt(hours, 10),
    minutes: parseInt(minutes, 10),
  })
}

export const getNextBusinessDay = (startOf: Date) => {
  let nextBusinessDay: Date | null = null
  let offset = 1

  while (!nextBusinessDay) {
    const nextDate = addDays(startOf, offset)
    if (isWeekend(nextDate)) {
      offset += 1
    } else {
      nextBusinessDay = nextDate
    }
  }

  return nextBusinessDay
}

export const formatTime = (time: string, timeFormat = 'HH:mm'): string => {
  const [hours, minutes, seconds] = time.split(':').map(Number)

  const result = set(new Date(), { hours, minutes, seconds })
  return format(result, timeFormat)
}
