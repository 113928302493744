import * as React from 'react'
import { ColAuto } from '@hyper/col-auto'
import { Button, FormGroup, Row } from 'reactstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { FormSelect } from '@hyper/forms/form-select'
import { ReceptionistUsersTableFilters } from '@modules/account/receptionist-users/list/index'
import { YesNoOptions } from '@helpers/consts'

interface Props {
  defaultFilters: ReceptionistUsersTableFilters
  filters: ReceptionistUsersTableFilters
  setFilters: (filters: ReceptionistUsersTableFilters) => void
}

export const ReceptionistUsersListTableFilter: React.FC<Props> = ({ defaultFilters, filters, setFilters }) => {
  const methods = useForm<ReceptionistUsersTableFilters>({
    defaultValues: defaultFilters,
  })

  const handleReset = () => {
    methods.reset()
    setFilters(defaultFilters)
  }

  const onSubmit = async payload => setFilters({ ...filters, ...payload })

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  return (
    <FormProvider {...methods}>
      <Row>
        <ColAuto className="px-0">
          <FormSelect
            options={YesNoOptions}
            name="is_active"
            label="Aktywny?"
            selectProps={{ isClearable: true }}
            formPlainProps={{ colStyle: { width: 200 } }}
          />
        </ColAuto>
        <ColAuto>
          <FormGroup>
            <label className="d-block">&nbsp;</label>
            <Button color="primary">Filtruj</Button>
            {methods.formState.isDirty && (
              <Button color="light" className="ml-1" type="button" onClick={handleReset}>
                Wyczyść
              </Button>
            )}
          </FormGroup>
        </ColAuto>
      </Row>
      <Row>
        <SearchFilter colClassName="col-md-2" />
      </Row>
    </FormProvider>
  )
}
