import * as React from 'react'
import { NavigationTabs, NavigationTabsButton } from '@components/navigation-tabs'
import { ScrollableModalTab, useModalWithScrollableTabs } from '@components/hooks/use-modal-with-scrollable-tabs'

type Tab = 'history' | 'information' | 'subscriptions' | 'gastroCards'

interface Props {
  informationRef: React.RefObject<HTMLElement>
  subscriptionsRef: React.RefObject<HTMLElement>
  gastroCardsRef: React.RefObject<HTMLElement>
  historyRef: React.RefObject<HTMLElement>
}

export const EndlessHolidayDetailsTabs: React.FC<Props> = ({
  subscriptionsRef,
  gastroCardsRef,
  informationRef,
  historyRef,
}) => {
  const [tab, setTab] = React.useState<Tab>('information')

  const tabs: ScrollableModalTab<Tab>[] = [
    { ref: informationRef, name: 'information' },
    { ref: historyRef, name: 'history' },
    { ref: subscriptionsRef, name: 'subscriptions' },
    { ref: gastroCardsRef, name: 'gastroCards' },
  ]

  const { handleTabClick } = useModalWithScrollableTabs<Tab>({ modalId: 'package-wholesale-modal', setTab, tabs })

  const buttons: NavigationTabsButton[] = [
    {
      title: 'Dane podstawowe',
      isActive: tab == 'information',
      onClick: handleTabClick('information'),
    },
    {
      title: 'Pakiety prywatne',
      isActive: tab == 'subscriptions',
      onClick: handleTabClick('subscriptions'),
    },
    {
      title: 'Kupony gastro',
      isActive: tab == 'gastroCards',
      onClick: handleTabClick('gastroCards'),
    },

    {
      title: 'Historia',
      isActive: tab == 'history',
      onClick: handleTabClick('history'),
    },
  ]

  return <NavigationTabs buttons={buttons} />
}
