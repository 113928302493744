import * as React from 'react'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { RodoAgreement } from '@modules/crm/rodo-agreements/models'
import { useTransferRodoAgreementBenefitMutation } from '@api/rodo-agreements'
import { useApiRequest } from '@components/hooks/use-api-request'

interface Response {
  issueBenefit: (agreement: RodoAgreement) => Promise<void>
  isLoading: boolean
}

export const useBenefitIssue = (): Response => {
  const { addSuccessMessage } = useNotificationHook()

  const [transferBenefit] = useTransferRodoAgreementBenefitMutation()

  const { action: issueBenefit, isLoading } = useApiRequest(async (agreement: RodoAgreement) => {
    await transferBenefit(agreement).unwrap()
    addSuccessMessage(
      <span>
        Benefit został <br /> wykorzystany
      </span>,
      <span>
        Wydanie benefitu zostało <br />
        zapisane w systemie
      </span>,
    )
  })

  return {
    issueBenefit,
    isLoading,
  }
}
