import * as React from 'react'
import { getHousekeepingCleaningOrders } from '@store/actions/housekeeping-actions'
import { CleaningOrder, HousekeepingCleaningOrderType } from '@modules/housekeeping/models'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { HousekeepingToOrderFilters } from '@modules/housekeeping/cleaning-orders/to-order/filters'
import { HousekeepingRowOptions } from '@modules/housekeeping/cleaning-orders/to-order/row-options'
import { HousekeepingCleaningOrdersToOrderTable } from '@modules/housekeeping/cleaning-orders/to-order/table'
import { FormProvider, useForm } from 'react-hook-form'
import { searchClearingOrders } from '@modules/housekeeping/cleaning-orders/common/filtering'
import { useApiRequest } from '@components/hooks/use-api-request'
import { startOfToday } from 'date-fns'
import { cleaningOrdersSelector } from '@store/slices/housekeeping-slice'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useHousekeepingTableFilters } from '@modules/housekeeping/hooks/use-housekeeping-table-filters'

interface HousekeepingCleaningOrdersToOrderProps {
  resortId: number
}

export interface HousekeepingToOrderFiltersParams {
  local: CustomReactSelectOption | null
  kind: CustomReactSelectOption | null
  date: Date
  search: string
  service_kind: CustomReactSelectOption | null
  is_vip: boolean
  early_check_in: boolean
  extend_stay: boolean
  collision_locals: boolean
}

const defaultFilters: HousekeepingToOrderFiltersParams = {
  date: startOfToday(),
  search: '',
  local: null,
  kind: null,
  service_kind: null,
  is_vip: false,
  early_check_in: false,
  extend_stay: false,
  collision_locals: false,
}

export const HousekeepingCleaningOrdersToOrder: React.FC<HousekeepingCleaningOrdersToOrderProps> = ({ resortId }) => {
  const [filters, setFilters] = React.useState<HousekeepingToOrderFiltersParams>(defaultFilters)
  const [selectedCleaningOrders, setSelectedCleaningOrders] = React.useState<CleaningOrder[]>([])
  const [filteredCleaningOrders, setFilteredCleaningOrders] = React.useState<CleaningOrder[]>([])

  const methods = useForm<HousekeepingToOrderFiltersParams>({
    defaultValues: filters,
  })

  const dispatch = useAppDispatch()
  const { action: fetchHousekeepingCleaningOrders, isLoading } = useApiRequest(async () => {
    await dispatch(
      getHousekeepingCleaningOrders([{ resort: resortId, date: filters.date }, HousekeepingCleaningOrderType.TO_ORDER]),
    )
    setSelectedCleaningOrders([])
  })

  React.useEffect(() => {
    fetchHousekeepingCleaningOrders()
  }, [filters.date, resortId])

  const cleaningOrders = useAppSelector(cleaningOrdersSelector)

  const { getFiltered, getSorted } = useHousekeepingTableFilters()

  const workers = useAppSelector((state: RootState) => state.houseKeepingState.cleaningWorkers)

  React.useEffect(() => {
    setFilteredCleaningOrders(
      searchClearingOrders(
        getSorted(
          getFiltered(
            cleaningOrders.filter(
              row => !row.company_id && (row.status === 'PENDING' || row.status === 'REOPENED') && !row.users.length,
            ),
            filters,
          ),
        ),
        filters.search,
        workers,
      ),
    )
    setSelectedCleaningOrders([])
  }, [cleaningOrders, filters])

  return (
    <>
      <FormProvider {...methods}>
        <div className="d-flex align-items-center">
          <HousekeepingRowOptions selectedCleaningOrders={selectedCleaningOrders} resortId={resortId} />
          <HousekeepingToOrderFilters filters={filters} setFilters={setFilters} defaultFilters={defaultFilters} />
        </div>
      </FormProvider>

      <LoaderPlaceholder content={!isLoading}>
        <HousekeepingCleaningOrdersToOrderTable
          methods={methods}
          selectedCleaningOrders={selectedCleaningOrders}
          setSelectedCleaningOrders={setSelectedCleaningOrders}
          cleaningOrders={filteredCleaningOrders}
        />
      </LoaderPlaceholder>
    </>
  )
}
