import * as React from 'react'
import { Button, Col, ModalFooter, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'

interface ReceptionBookingCheckFooterProps {
  nextStep?: () => void
  previousStep?: () => void
  type?: 'button' | 'submit'
  isSaving?: boolean
  isDisabled?: boolean
  nextLabel?: string
  previousLabel?: string
  nextLabelSaving?: string
  onSave?: (event?: React.MouseEvent) => void
}

export const ReceptionBookingCheck: React.FC<ReceptionBookingCheckFooterProps> = ({
  nextStep,
  nextLabel,
  isSaving,
  isDisabled,
  type,
  previousStep,
  previousLabel = 'Wstecz',
  nextLabelSaving,
  onSave,
}) => (
  <ModalFooter style={{ display: 'block' }}>
    <Row>
      <Col md={6} className="p-0">
        {previousStep && (
          <Button onClick={previousStep} className="btn btn-light" role="check-previous-step">
            {previousLabel}
          </Button>
        )}
      </Col>
      <Col md={6} className="text-right p-0">
        {(nextStep || nextLabel) && (
          <SaveButton
            role="check-next-step"
            type={type}
            labelSaving={nextLabelSaving}
            label={nextLabel || 'Następny krok'}
            className="btn btn-green"
            isSaving={isSaving || false}
            disabled={isDisabled}
            onClick={onSave}
          />
        )}
      </Col>
    </Row>
  </ModalFooter>
)
