import * as React from 'react'
import { FormInput } from '@hyper/forms'
import { Row } from 'reactstrap'
import { PackageCreateFormInvoice } from '@modules/package/create/form-invoice'
import { PackageType } from '@models/package'

interface Props {
  type: PackageType
}

export const PackageCreateFormClientData: React.FC<Props> = ({ type }) => (
  <>
    <Row>
      <FormInput label="Imię i nazwisko" colSize={6} name="name" />
    </Row>
    <Row>
      <FormInput label="Telefon" colSize={6} name="phone" />
      <FormInput label="Email" colSize={6} name="email" />
      {type !== PackageType.HPR && <FormInput label="Własny numer faktury" colSize={6} name="invoice_custom_number" />}
    </Row>
    <PackageCreateFormInvoice />
  </>
)
