import { Col, Row } from 'reactstrap'
import { formatPhoneNumber } from '@helpers/utils'
import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'

export interface ClientSummaryProps {
  reservation: ReceptionBookingDetails
}

const ClientSummary = ({ reservation }: ClientSummaryProps) => (
  <>
    <h5>Osoba rezerwująca:</h5>
    <Row className="reservation-create__step-summary__text-light mb-2">
      <Col md={5}>Imie i nazwisko:</Col>
      <Col md={7} className="text-strong">
        {reservation.name}{' '}
        {reservation.has_package_vip && <span className="reservation-create__step-summary__vip">VIP</span>}
      </Col>
    </Row>
    <Row className="reservation-create__step-summary__text-light mb-2">
      <Col md={5}>Adres e-mail:</Col>
      <Col md={7} className="text-strong">
        {reservation.email}
      </Col>
    </Row>
    <Row className="reservation-create__step-summary__text-light mb-2">
      <Col md={5}>Numer telefonu:</Col>
      <Col md={7} className="text-strong">
        {formatPhoneNumber(reservation.phone)}
      </Col>
    </Row>
    <Row className="reservation-create__step-summary__text-light mb-2">
      <Col md={5}>Adres:</Col>
      <Col md={7} className="text-strong">
        <div className="mb-1">{reservation.street}</div>
        <div className="mb-1">
          {reservation.postcode}, {reservation.city}
        </div>
        <div>{reservation.country}</div>
      </Col>
    </Row>
  </>
)

export default ClientSummary
