import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useForm } from 'react-hook-form'
import { FeedingClient, FeedingClientPaymentMethod, FeedingStartBy, FeedingType } from '@modules/feeding/models'
import { endOfToday, startOfToday } from 'date-fns'
import { GuestsFeedingClientsCreateDialogGuests } from '@modules/feeding/clients/create/guests'
import { GuestsFeedingClientsCreateDialogTotalPrice } from '@modules/feeding/clients/create/total-price'
import { GuestsFeedingClientsCreateDialogForm } from '@modules/feeding/clients/create/form'
import { FeedingClientCalculateGuestPayload } from '@api/feeding'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useFormRequest } from '@components/hooks/use-api-request'
import { BaseModalProps } from '@components/modals/types'
import { updateFeedingClientDetails } from '@store/slices/feeding-slice'
import { commonObjectPost } from '@store/actions/generic-actions'
import { Form } from '@hyper/forms/form'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { createInvoiceData, InvoiceData } from '@components/products/product-invoice-card'
import { createSelectOption, extractSelectOptionsValues } from '@helpers/utils'

interface FeedingClientsCreateDialogProps extends BaseModalProps {
  onlyCard?: boolean
}

export interface FeedingClientsCreateDialogFormInputs extends InvoiceData {
  date_from: Date
  date_to: Date
  discount: CustomReactSelectOption | null
  guests: FeedingClientCalculateGuestPayload[]
  kind: CustomReactSelectOption<FeedingType, string> | null
  name: string
  payment_method: CustomReactSelectOption<FeedingClientPaymentMethod, string> | null
  resort: CustomReactSelectOption | null
  start_by: CustomReactSelectOption<FeedingStartBy, string> | null
}

export const FeedingClientsCreateDialog: React.FC<FeedingClientsCreateDialogProps> = ({
  toggleIsVisible,
  onlyCard,
}) => {
  const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()
  const url = useAppSelector((state: RootState) => state.appState.appData.urls.feeding.clients)

  const methods = useForm<FeedingClientsCreateDialogFormInputs>({
    defaultValues: {
      date_from: startOfToday(),
      date_to: endOfToday(),
      guests: [{ name: '', age: 'adult' }],
      invoice_type: 'company',
      discount: createSelectOption('Brak', 0),
    },
  })

  const { isLoading, action: onSubmit } = useFormRequest(
    async (payload: FeedingClientsCreateDialogFormInputs) => {
      const { invoice_type, invoice_nip, invoice_company, invoice_street, invoice_postcode, invoice_city, ...data } =
        payload

      await dispatch(
        updateFeedingClientDetails(
          await commonObjectPost<FeedingClient>(url, {
            ...extractSelectOptionsValues(data),
            ...(!!payload.invoice_nip && { ...createInvoiceData(payload), invoice: true }),
          }),
        ),
      )
      addSuccessMessage('Sukces', `Wyżywienie dla ${payload.name} zostało sprzedane`)
      toggleIsVisible()
    },
    methods.setError,
    { showGlobalError: true },
  )

  return (
    <Form onSubmit={onSubmit} methods={methods}>
      <ModalHeader toggle={toggleIsVisible}>
        <span className="text-semi-strong">Sprzedaż wyżywienia</span> <strong>gościowi</strong>
      </ModalHeader>
      <ModalBody>
        <GuestsFeedingClientsCreateDialogForm onlyCard={onlyCard || false} />
        <GuestsFeedingClientsCreateDialogGuests />
        <GuestsFeedingClientsCreateDialogTotalPrice />
      </ModalBody>
      <ModalFooter style={{ borderTop: 0 }}>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" label="Sprzedaj" labelSaving="Sprzedawanie" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
