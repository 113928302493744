import * as React from 'react'
import { Col, Row } from 'reactstrap'

interface DetailsColRowProps {
  className?: string
  label: React.ReactNode | string
  value: React.ReactNode | string
}

export const DetailsColRow: React.FC<DetailsColRowProps> = ({ className, label, value }) => (
  <Row className={className || 'mb-3'}>
    <Col md={5}>
      <strong>{label}:</strong>
    </Col>
    <Col md={7}>{value}</Col>
  </Row>
)
