import * as React from 'react'
import { useDragLayer } from 'react-dnd'
import { Apartment } from '@models/apartment'
import TimelineItemBasicDragger from '@modules/reservations/timeline/timeline-item-basic-dragger'
import TimelineItemResizerDragger from '@modules/reservations/timeline/timeline-item-resizer-dragger'
import { TimelineItemDragType } from '@modules/reservations/timeline/timeline-content-droppable'

interface Props {
  allApartments: Apartment[]
}

const TimelineDragLayer: React.FC<Props> = ({ allApartments }) => {
  const { type, item, isDragging, offsetDiff } = useDragLayer(monitor => ({
    type: monitor.getItemType(),
    item: monitor.getItem(),
    isDragging: monitor.isDragging(),
    offsetDiff: monitor.getDifferenceFromInitialOffset(),
  }))

  if (!isDragging || !offsetDiff) {
    return null
  }

  switch (type as TimelineItemDragType) {
    case 'RESERVATION_ITEM':
      return <TimelineItemBasicDragger offsetDiff={offsetDiff} item={item} apartments={allApartments} />
    case 'RESERVATION_ITEM_RESIZER':
      return <TimelineItemResizerDragger offsetDiff={offsetDiff} item={item} apartments={allApartments} />
    default:
      return null
  }
}

export default TimelineDragLayer
