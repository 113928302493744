import * as React from 'react'
import { TechnicalOrder } from '@models/technical-orders'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { GuestsTechnicalOrdersTableRow } from '@modules/guests-technical-orders/row'

interface Props {
  technicalOrders: TechnicalOrder[]
  setFilters: React.Dispatch<React.SetStateAction<TableFilters>>
  filters: TableFilters
  showUsers: boolean
  dataTestId?: string
}

const headers: TableHeaderValue[] = [
  { title: 'Numer zadania', sortField: 'number' },
  { title: 'Lokal', sortField: 'unit' },
  { title: 'Status', sortField: 'status' },
  { title: 'Zgłoszenie', sortField: 'name' },
  { title: 'Zgłaszający', sortField: 'created_by__name' },
  { title: 'Wykonawca', sortField: 'users' },
  { title: '' },
  { title: ' ' },
]

export const GuestsTechnicalOrdersTable: React.FC<Props> = ({
  dataTestId,
  showUsers,
  filters,
  setFilters,
  technicalOrders,
}) => {
  const tableHeaders = React.useMemo(
    () => (showUsers ? headers : headers.filter(header => header.sortField !== 'users')),
    [showUsers],
  )
  return (
    <Table
      headerClassName="bg-white border-top-0 vertical-align-middle"
      className="table-hover w-100"
      headerValues={tableHeaders}
      isLoading={false}
      filters={filters}
      dataTestId={dataTestId}
      setFilters={setFilters}
      showPagination={false}
      cols={showUsers ? ['15%', '10%', '10%', '21%', '17%', '17%', '10%'] : ['15%', '10%', '10%', '21%', '34%', '10%']}
    >
      {technicalOrders.map(technicalOrder => (
        <GuestsTechnicalOrdersTableRow showUsers={showUsers} key={technicalOrder.id} technicalOrder={technicalOrder} />
      ))}
    </Table>
  )
}
