import * as React from 'react'
import { BaseHistoryParamsChange } from '@models/dashboard'
import classNames from 'classnames'

interface Props {
  change: BaseHistoryParamsChange
  isFirst: boolean
}

export const ClientHistoryChangeTitleRow = ({ change, isFirst }: Props) => (
  <tr>
    <Cell isFirst={isFirst} value={change.field_name_display} />
    <Cell isFirst={isFirst} value={change.field_name_display} />
  </tr>
)

const Cell = ({ isFirst, value }) => (
  <td className="px-1 pt-1">
    <div
      title={value}
      className={classNames(
        'reservation-details__history__changes__box__value text-truncate fw-semi-bold cursor-default font-11',
        { 'is-first': isFirst },
      )}
    >
      {value}
    </div>
  </td>
)
