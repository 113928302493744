import * as React from 'react'
import { Button, ModalFooter, ModalHeader } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { SaveButton } from '@hyper/button'
import { useForm } from 'react-hook-form'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { BaseModalProps } from '@components/modals/types'
import { createSelectOption } from '@helpers/utils'
import { Form } from '@hyper/forms/form'
import { useFormRequest } from '@components/hooks/use-api-request'
import { useAppData } from '@components/hooks/use-app-data'
import * as R from 'ramda'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { commonObjectPost, commonObjectPut } from '@store/actions/generic-actions'
import { CallCenterUser } from '@models/account'
import { updateCallCenterUserDetails } from '@store/slices/account-slice'
import { CallCenterUserModalForm } from '@modules/account/call-center-users/modal/form'

interface FormInputs {
  first_name: string
  last_name: string
  email: string
  groups: CustomReactSelectOption[]
  phone: string
  is_active: boolean
  password: string
  confirmed_password: string
}

interface Props extends BaseModalProps {
  user?: CallCenterUser
}

export const CallCenterUserModal: React.FC<Props> = ({ toggleIsVisible, user }) => {
  const { urls, call_center_groups } = useAppData()
  const callCenterGroupsOptions = call_center_groups.map(({ id, name }) => createSelectOption(name, id))

  const { addSuccessNotification } = useNotificationHook()
  const dispatch = useAppDispatch()
  const methods = useForm<FormInputs>({
    mode: 'all',
    defaultValues: {
      ...R.pick<FormInputs, any>(['first_name', 'last_name', 'is_active', 'phone'], {
        ...user,
      }),
      groups: user ? callCenterGroupsOptions.filter(row => user.groups.includes(row.value)) : [],
    },
  })

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    const data = {
      ...payload,
      groups: payload.groups.map(row => row.value),
    }
    if (user) {
      dispatch(updateCallCenterUserDetails(await commonObjectPut<CallCenterUser>(user.urls.details, data)))
      addSuccessNotification('Zmiany zostały zapisane')
    } else {
      dispatch(
        updateCallCenterUserDetails(await commonObjectPost<CallCenterUser>(urls.account.call_center_users, data)),
      )
      addSuccessNotification('Pracownik został dodany')
    }
    toggleIsVisible()
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>
        {user ? `Edytuj pracownika: ${user.first_name} ${user.last_name}` : 'Dodaj pracownika'}
      </ModalHeader>
      <ModalBody>
        <CallCenterUserModalForm user={user} callCenterGroupsOptions={callCenterGroupsOptions} />
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
