import * as React from 'react'
import { BenefitProgramAgreementDetails } from '@modules/benefit-program/models'
import { ProductCardTitle } from '@components/products/product-card-title'
import { Card, CardBody } from 'reactstrap'
import { BenefitProgramAgreementDetailsFeaturesTable } from '@modules/benefit-program/agreements/details/features/table'

interface Props {
  benefitProgramAgreement: BenefitProgramAgreementDetails
}

export const BenefitProgramAgreementDetailsFeatures: React.FC<Props> = ({ benefitProgramAgreement }) => (
  <Card>
    <CardBody>
      <ProductCardTitle icon="uil-file-check-alt" title="Lista benefitów" />
      <BenefitProgramAgreementDetailsFeaturesTable benefitProgramAgreement={benefitProgramAgreement} />
    </CardBody>
  </Card>
)
