import * as React from 'react'
import { ModalComponentRow } from '@components/modals/modal-component-row'
import { modalsSelector } from '@store/slices/modal-slice'
import { useAppSelector } from '@store/index'

export const ModalComponent: React.FC = () => {
  const modals = useAppSelector(modalsSelector)

  return (
    <>
      {modals.map(({ modal, params, config, isOpen }) => (
        <ModalComponentRow config={config} key={modal} isOpen={isOpen} modal={modal} params={params} />
      ))}
    </>
  )
}
