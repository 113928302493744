import * as React from 'react'
import { Card, CardBody } from 'reactstrap'
import { ProductCardTitle } from '@components/products/product-card-title'
import { SubscriptionDetailsInformationRow } from '@modules/subscription/details/information/row'
import { BenefitProgramAgreementDetails } from '@modules/benefit-program/models'
import { BenefitProgramAgreementStatusBadge } from '@modules/benefit-program/common/benefit-program-agreement-status-badge'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { asDecimal, formatPriceShort } from '@helpers/utils'

interface Props {
  benefitProgramAgreement: BenefitProgramAgreementDetails
}

export const BenefitProgramAgreementDetailsTerms: React.FC<Props> = ({ benefitProgramAgreement }) => {
  const workerPrice = benefitProgramAgreement.benefit_program.benefit_apartment_price_worker || '0'
  const employerPrice = benefitProgramAgreement.benefit_program.benefit_apartment_price_employer || '0'
  const total = React.useMemo(
    () => asDecimal(workerPrice).plus(asDecimal(employerPrice)).toString(),
    [workerPrice, employerPrice],
  )

  return (
    <Card>
      <CardBody>
        <ProductCardTitle icon="uil-check font-20" title={<span className="text-semi-strong">Wybrany plan</span>} />
        <table className="table table-sm mb-0">
          <tbody>
            <SubscriptionDetailsInformationRow name="Status" borderless={true}>
              <BenefitProgramAgreementStatusBadge benefitProgramAgreement={benefitProgramAgreement} />
            </SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="Data rozpoczęcia">
              {toDefaultDateFormat(benefitProgramAgreement.started_at)}
            </SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="Kosz benefitu">
              <strong className="d-block f-13">{formatPriceShort(total)}</strong>
              <div className="font-11">Składka pracownika: {formatPriceShort(workerPrice)}</div>
              <div className="font-11">Składka pracodawcy: {formatPriceShort(employerPrice)}</div>
            </SubscriptionDetailsInformationRow>
            <tr>
              <td colSpan={2} />
            </tr>
          </tbody>
        </table>
      </CardBody>
    </Card>
  )
}
