import * as React from 'react'
import { Card, CardBody } from 'reactstrap'
import { PageTitle } from '@hyper/page-title'
import { useDocumentTitle } from '@helpers/utils'
import { PaymentRegistersFilters, PaymentRegistersFiltersParams } from '@modules/payment-registers/list/filters'
import { endOfToday, startOfToday } from 'date-fns'
import { useAppDispatch } from '@store/index'
import { PaymentRegistersTable } from '@modules/payment-registers/list/table'
import { ButtonWithIcon } from '@components/button-with-icon'
import { useModal } from '@components/modals/use-modal'
import { PaymentRegisterWebSocketHandler } from '@modules/payment-registers/list/payment-register-web-socket-handler'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { getPaymentRegisters } from '@store/actions/cash-actions'
import { clearPaymentRegisters } from '@store/slices/cash-slice'
import { useTableFilters } from '@components/table/use-table-filters'

const defaultFilters: PaymentRegistersFiltersParams = {
  ordering: '-created',
  page: 1,
  source_resort: null,
  destination_resort: null,
  user: null,
  source: null,
  page_size: 10,
  search: '',
  created_after: startOfToday(),
  created_before: endOfToday(),
  type: null,
  kind: null,
}

export const PaymentRegistersView = (): JSX.Element => {
  const pageTitle = 'Rejestr transakcji'
  useDocumentTitle(pageTitle)
  const dispatch = useAppDispatch()

  const { isLoading, filters, setFilters } = useTableFilters<PaymentRegistersFiltersParams>({
    defaultFilters,
    action: async filters => await dispatch(getPaymentRegisters({ ...filters, view: 'payment-register-view' })),
  })

  useMenuActive(NavigationPath.PaymentRegisterList)

  const [handleExport] = useModal('ReportsPaymentRegisterModal', {
    filters,
  })

  React.useEffect(
    () => () => {
      dispatch(clearPaymentRegisters())
    },
    [],
  )

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitle} />
      <Card>
        <CardBody>
          <PaymentRegisterWebSocketHandler />
          <PaymentRegistersFilters setFilters={setFilters} filters={filters} defaultFilters={defaultFilters} />
          <PaymentRegistersTable isLoading={isLoading} filters={filters} setFilters={setFilters} />

          <div className="text-right" onClick={handleExport}>
            <ButtonWithIcon icon="uil-download-alt" color="secondary" text="Pobierz" />
          </div>
        </CardBody>
      </Card>
    </>
  )
}
