import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import {
  RcpEventDay,
  RcpGroup,
  RcpMachine,
  RcpMonth,
  RcpStateUserEventsDayFilter,
  RcpUser,
  RcpUserDetail,
} from '@models/rcp'
import { RootState } from '@store/index'
import { getRcpUserDetail, getRcpUsers, getUserEventDayDetails, getUserEvents } from '@store/actions/rcp-actions'

export interface RcpState {
  users: EntityState<RcpUser>
  groups: RcpGroup[]
  userEventsDaysFilter: RcpStateUserEventsDayFilter

  months: RcpMonth[]
  machines: RcpMachine[]
  userEventsDays: EntityState<RcpEventDay>
  userDetail: RcpUserDetail | undefined
}

const rcpEventDayAdapter = createEntityAdapter<RcpEventDay>({ selectId: row => row.urls.details })
const rcpUsersAdapter = createEntityAdapter<RcpUser>()

const initialState: RcpState = {
  machines: [],
  months: [],
  userEventsDaysFilter: {
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  },
  users: rcpUsersAdapter.getInitialState(),
  userEventsDays: rcpEventDayAdapter.getInitialState(),
  groups: [],
  userDetail: undefined,
}

export const rcpSlice = createSlice({
  name: 'rcp',
  initialState,
  reducers: {
    setUserEventsDaysFilter(state, action: PayloadAction<RcpStateUserEventsDayFilter>) {
      state.userEventsDaysFilter = action.payload
    },
    updateUserEventDayDetails(state, action: PayloadAction<RcpEventDay>) {
      rcpEventDayAdapter.upsertOne(state.userEventsDays, action.payload)
    },
  },
  extraReducers: builder => {
    builder.addCase(getRcpUserDetail.fulfilled, (state, action) => {
      state.userDetail = action.payload.user
      state.groups = action.payload.groups
    })
    builder.addCase(getUserEvents.pending, state => {
      rcpEventDayAdapter.removeAll(state.userEventsDays)
      state.months = []
      state.machines = []
    })
    builder.addCase(getUserEvents.fulfilled, (state, action) => {
      state.months = action.payload.months
      state.machines = action.payload.machines
      rcpEventDayAdapter.setAll(state.userEventsDays, action.payload.events_days)
    })
    builder.addCase(getUserEventDayDetails.fulfilled, (state, action) => {
      rcpEventDayAdapter.upsertOne(state.userEventsDays, action.payload)
    })
    builder.addCase(getRcpUsers.fulfilled, (state, action) => {
      rcpUsersAdapter.setAll(state.users, action.payload.users)
      state.groups = action.payload.groups
    })
  },
})

export const { setUserEventsDaysFilter, updateUserEventDayDetails } = rcpSlice.actions

export const { selectAll: rcpUsersSelector } = rcpUsersAdapter.getSelectors((state: RootState) => state.rcpState.users)
export const { selectAll: rcpEventDaySelector } = rcpEventDayAdapter.getSelectors(
  (state: RootState) => state.rcpState.userEventsDays,
)

export const rcpGroupsSelector = (state: RootState) => state.rcpState.groups
export const rcpMonthsSelector = (state: RootState) => state.rcpState.months
export const rcpMachinesSelector = (state: RootState) => state.rcpState.machines
export const rcpUserDetailSelector = (state: RootState) => state.rcpState.userDetail
export const rcpUserEventsDaysFilter = (state: RootState) => state.rcpState.userEventsDaysFilter
export default rcpSlice.reducer
