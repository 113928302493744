import { useApiRequest } from '@components/hooks/use-api-request'
import * as React from 'react'
import { useAppDispatch } from '@store/index'
import { ContentNotesCard } from '@components/content-notes/content-notes-card'
import { getSubscriptionContractDetails } from '@store/actions/subscription-contract-actions'
import { SubscriptionContractDetails } from '@modules/subscription-contract/models'
import { useAppData } from '@components/hooks/use-app-data'

interface Props {
  subscriptionDetails: SubscriptionContractDetails
}

export const SubscriptionContractDetailsNotes: React.FC<Props> = ({ subscriptionDetails }) => {
  const { subscriptioncontract } = useAppData().content_types

  const dispatch = useAppDispatch()

  const { action: handleRefresh } = useApiRequest(
    async () => await dispatch(getSubscriptionContractDetails(subscriptionDetails)),
  )

  return (
    <div data-testid="package-details-notes">
      <ContentNotesCard
        readOnly={false}
        objectId={subscriptionDetails.id}
        contentType={subscriptioncontract}
        onNotesUpdate={handleRefresh}
        notes={subscriptionDetails.notes}
      />
    </div>
  )
}
