import { ClientUser } from '@models/clients'
import { commonObjectGet } from '@store/actions/generic-actions'
import { getState } from '@store/index'

const minimalValueLength = 3

export const clientSearchQuery = async (search, emailsToOmit: string[] = []): Promise<ClientUser[]> => {
  const url = getState().appState.appData.urls.clients.clients

  if (!search || search.length < minimalValueLength) {
    return []
  }

  try {
    const data = await commonObjectGet<any>(url, { search: search })

    return data.results.filter((item: ClientUser) => !emailsToOmit.some(client => client === item.email))
  } catch (e) {
    return []
  }
}
