import * as React from 'react'
import { Col, Row } from 'reactstrap'

interface Props {
  colClassName?: string
}

export const ModalBodyHr: React.FC<Props> = ({ colClassName }) => (
  <Row style={{ marginLeft: -15, marginRight: -15 }}>
    <Col md={12} className={colClassName || 'px-0 py-3'}>
      <div className="hr hr-grey" />
    </Col>
  </Row>
)
