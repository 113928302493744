import * as React from 'react'
import { getStatusClassIcon } from '@modules/promotions/gastro-cards/utils'
import { formatPrice } from '@helpers/utils'
import { useModal } from '@components/modals/use-modal'
import { BadgeWithIcon } from '@components/badges/BadgeWithIcon'
import { ProductCardTitle } from '@components/products/product-card-title'
import { PackageDetailsRow } from '@modules/package/details/information/row'
import { BookingOptionDetails } from '@modules/promotions/booking-options/model'
import { Card, CardBody } from 'reactstrap'
import { ProductSalePageButton } from '@components/products/product-action-button'
import { TableSellerRow } from '@components/table-seller-row'
import { useAppData } from '@components/hooks/use-app-data'
import { bruttoToNetto } from '@helpers/price'

interface Props {
  optionDetails: BookingOptionDetails
}

export const BookingOptionDetailsBaseData = React.forwardRef<HTMLDivElement, Props>(({ optionDetails }, ref) => {
  const [showEditDialog] = useModal('BookingOptionCreateDialog', { optionDetails })
  const { urls } = useAppData()

  const [badgeClass, badgeIcon] = getStatusClassIcon(optionDetails.status)

  return (
    <Card innerRef={ref}>
      <CardBody>
        <ProductCardTitle
          title="Dane szczegółowe"
          isEditAllowed={optionDetails.status === 'initial'}
          onEdit={showEditDialog}
          adminUrl={optionDetails.urls.admin}
        />
        <div className="table-responsive">
          <table className="table table-sm mb-2">
            <tbody>
              <PackageDetailsRow label="Status">
                <BadgeWithIcon icon={badgeIcon} title={optionDetails.status_display} className={badgeClass} />
              </PackageDetailsRow>

              <PackageDetailsRow label="Kwota łączna brutto">
                {formatPrice(optionDetails.price_brutto)}
              </PackageDetailsRow>

              <PackageDetailsRow label="Kwota łączna netto">
                {formatPrice(bruttoToNetto(optionDetails.price_brutto, 23))}
              </PackageDetailsRow>

              <PackageDetailsRow label="Założono przez">{optionDetails.created_by}</PackageDetailsRow>

              <PackageDetailsRow label="Sprzedawca">
                <TableSellerRow sellerId={optionDetails.seller_id} />
              </PackageDetailsRow>
              <PackageDetailsRow label="Numer faktury">
                {optionDetails.invoice ? (
                  <a
                    href={urls.dashboard.quick_search_url + '?query=' + optionDetails.invoice.reservation_number}
                    target="_blank"
                  >
                    {optionDetails.invoice.reservation_number}
                  </a>
                ) : (
                  'Brak'
                )}
              </PackageDetailsRow>
            </tbody>
          </table>
        </div>
        <ProductSalePageButton saleUrl={optionDetails.urls.sale} className="ml-auto d-block" />
      </CardBody>
    </Card>
  )
})
