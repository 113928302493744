import * as React from 'react'
import Table from '@components/table/table'
import { RcpUsersTableRow } from './rcp-users-table-row'
import { UsersFilters } from '@modules/rcp/users/index'
import { usePaginateRows } from '@components/table/use-pagination'
import { RcpUser } from '@models/rcp'

export const headerValues = [
  {
    sortField: 'badge',
    title: 'RCP ID',
  },
  {
    sortField: 'ext_badge',
    title: 'Numer księgowy',
  },
  {
    sortField: 'name',
    title: 'Nazwisko, Imie',
  },
  {
    sortField: 'city',
    title: 'Ośrodek',
  },
  {
    sortField: 'has_errors',
    title: 'Czas uzupełniony',
  },
  {
    sortField: 'time',
    title: 'Czas pracy',
  },
]

interface RcpUsersTableProps {
  isLoading: boolean
  users: RcpUser[]
  filters: UsersFilters
  setFilters: (filters: UsersFilters) => void
}

export const RcpUsersTable: React.FC<RcpUsersTableProps> = ({ isLoading, users, filters, setFilters }) => {
  const paginatedUsers = usePaginateRows<RcpUser>(users, filters.page, filters.page_size)

  return (
    <Table
      className="table-hover w-100"
      headerValues={headerValues}
      isLoading={isLoading}
      filters={filters}
      setFilters={setFilters}
    >
      {paginatedUsers.map(row => (
        <RcpUsersTableRow key={row.id} user={row} />
      ))}
    </Table>
  )
}
