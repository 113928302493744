import * as React from 'react'
import { PageTitle } from '@hyper/page-title'
import { Card, CardBody } from 'reactstrap'
import { useDocumentTitle } from '@helpers/utils'
import { ReceptionCheckTypes } from '@modules/reception/reception-check-types'
import { useParams } from 'react-router-dom'
import { ReceptionToCheckin } from '@modules/reception/to-checkin/reception-to-checkin'
import { ReceptionCheckType } from '@models/reception'
import { ReceptionToCheckout } from '@modules/reception/to-checkout/reception-to-checkout'
import { ReceptionCompleteCheckin } from '@modules/reception/complete-checkin/reception-complete-checkin'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { getResortDetails } from '@store/actions/reception-actions'
import { ReceptionBandScanButton } from '@modules/reception/reception-band-scan-button'
import { NavigationPath } from '@models/routes'
import { ResortTabs } from '@components/resort-tabs'
import { parseISODate } from '@helpers/date-helper'
import { startOfToday } from 'date-fns'
import * as queryString from 'querystring'
import { HousekeepingApplicationsAddButton } from '@modules/housekeeping/applications/add-button'
import { resortsMapSelector } from '@store/selectors/dashboard'
import { useReceptionAppData } from '@modules/reception/hooks/use-reception-app-data'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { setReceptionDateFilter, setReceptionResortFilter } from '@store/slices/reception-slice'

interface Params {
  resort_id: string
  check_type: string
}

export const ReceptionWithParamsView: React.FC = () => {
  const { booking_date } = queryString.parse(location.search.replace('?', ''))

  const { resort_id, check_type } = useParams() as unknown as Params
  const resortId = parseInt(resort_id, 10)
  const dispatch = useAppDispatch()
  useMenuActive(NavigationPath.Reception)

  const bookingDate =
    (booking_date && typeof booking_date === 'string' ? parseISODate(booking_date) : startOfToday()) || startOfToday()

  const receptionDateFilter = useAppSelector((state: RootState) => state.receptionState.receptionDateFilter)
  const resort = useAppSelector(resortsMapSelector)[resortId]

  useReceptionAppData()

  React.useEffect(() => {
    dispatch(setReceptionDateFilter(bookingDate))
  }, [])

  React.useEffect(() => {
    dispatch(setReceptionResortFilter(resortId))
    dispatch(getResortDetails(resortId))
  }, [resortId])

  const pageTitle = `Recepcja ${resort?.name || ''}`
  useDocumentTitle(pageTitle)

  const nextHistoryRoute = NavigationPath.ReceptionWithParams.replace(':check_type', String(check_type))

  if (!receptionDateFilter) {
    return null
  }

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitle} />

      <Card className="reception">
        <CardBody>
          <ResortTabs resortId={resortId} url={nextHistoryRoute}>
            <HousekeepingApplicationsAddButton resortId={resortId} />
          </ResortTabs>
          <ReceptionCheckTypes resortId={resortId} checkType={check_type} />
          {!!resort?.has_band && <ReceptionBandScanButton />}
          {check_type === ReceptionCheckType.TO_CHECKIN && (
            <ReceptionToCheckin resortId={resortId} receptionDateFilter={receptionDateFilter} />
          )}
          {check_type === ReceptionCheckType.COMPLETE_CHECKIN && <ReceptionCompleteCheckin resortId={resortId} />}
          {check_type === ReceptionCheckType.TO_CHECKOUT && (
            <ReceptionToCheckout resortId={resortId} receptionDateFilter={receptionDateFilter} />
          )}
        </CardBody>
      </Card>
    </>
  )
}
