import * as React from 'react'
import { SubscriptionDetailsInformationRow } from '@modules/subscription/details/information/row'
import { asDecimal, formatPriceShort } from '@helpers/utils'

interface Props {
  name: string
  workerPrice: string | null
  employerPrice: string | null
}

export const BenefitProgramDetailsTermsPriceRow: React.FC<Props> = ({ name, workerPrice, employerPrice }) =>
  workerPrice &&
  employerPrice && (
    <SubscriptionDetailsInformationRow
      name={
        <>
          Cena benefitu <strong>{name}</strong>
        </>
      }
    >
      <strong className="d-block font-15">
        {formatPriceShort(asDecimal(workerPrice).plus(asDecimal(employerPrice)).toString())}
      </strong>
      <span className="font-11">
        Pracodawca: {formatPriceShort(employerPrice)} / Pracownik: {formatPriceShort(workerPrice)}
      </span>
    </SubscriptionDetailsInformationRow>
  )
