import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { Button } from 'reactstrap'
import { SaveButton } from '@hyper/button'

interface Props {
  onDecline: () => void
  onConfirm: () => void
  isSaving?: boolean
  confirmDisabled?: boolean
}

export const ReceptionBookingCartPaymentBaseActions = ({
  onDecline,
  onConfirm,
  confirmDisabled,
  isSaving,
}: Props): JSX.Element => (
  <div className="mt-3">
    <SaveButton
      onClick={onConfirm}
      className="btn btn-secondary w-100 mb-2 text-left align-items-center"
      label={<IconWithText text="Potwierdź płatność" icon="uil-check font-14" textClass="lh-initial" />}
      isSaving={!!isSaving}
      disabled={confirmDisabled}
    />
    <Button color="light" type="button" className="w-100 text-left align-items-center" onClick={onDecline}>
      <IconWithText text="Anuluj płatność" icon="uil-times font-14" textClass="lh-initial" />
    </Button>
  </div>
)
