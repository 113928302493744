import * as React from 'react'
import { ButtonWithIcon } from '@components/button-with-icon'
import { FormInput } from '@hyper/forms'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'

interface Props {
  type: string
}

export const UpsellBookingsOutdatedFilters = ({ type }: Props): JSX.Element => {
  const navigate = useNavigate()

  const navigateToCurrent = () => {
    navigate(NavigationPath.UpSellBookingsWithParams.replaceParam(':type', type))
  }

  return (
    <div className="d-flex justify-content-between align-items-center mb-2">
      <div className="d-flex">
        <ButtonWithIcon
          icon="uil-calender"
          text="Dziś"
          color="light"
          btnClass="btn-tall"
          handleClick={navigateToCurrent}
        />
        <FormInput
          name="search"
          placeholder="Szukaj kontaktu lub lokalu"
          colSize="4"
          formGroupClassName="border rounded bg-white flex-nowrap align-items-center mb-0"
          placeholderIcon="uil-search font-14 lh-0 ml-1"
          inputClassName="border-0 bg-transparent py-0"
          colStyle={{ minWidth: 250 }}
        />
      </div>
    </div>
  )
}
