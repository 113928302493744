import * as React from 'react'
import { Button, Col, FormGroup, Row } from 'reactstrap'
import { ServiceBandAdd } from '@modules/bands/service-bands/add'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { useForm } from 'react-hook-form'
import { ColAuto } from '@hyper/col-auto'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { Form } from '@hyper/forms/form'
import { useAppData } from '@components/hooks/use-app-data'
import { createSelectOption } from '@helpers/utils'
import { FormSelect } from '@hyper/forms/form-select'
import { TableFilters } from '@components/table/table'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { FormCheckbox } from '@hyper/forms/form-checkbox'

export interface ServiceBandFiltersParams extends TableFilters {
  resort: CustomReactSelectOption | null
  is_removed: boolean
}

interface Props {
  setFilters: (filters: ServiceBandFiltersParams) => void
  filters: ServiceBandFiltersParams
  defaultFilters: ServiceBandFiltersParams
}

export const ServiceBandFilters = ({ filters, setFilters, defaultFilters }: Props): JSX.Element => {
  const methods = useForm<ServiceBandFiltersParams>({
    defaultValues: defaultFilters,
  })

  const onSubmit = async (payload: ServiceBandFiltersParams) => setFilters({ ...filters, ...payload })
  const handleReset = () => {
    methods.reset()
    setFilters(defaultFilters)
  }

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  const { resorts } = useAppData()
  const resortOptions = resorts.map(({ id, name }) => createSelectOption(name, id))

  const user = useAuthenticatedUser()
  const canAdd = user.hasPerm(UserPermission.ServiceBandCanCreate)

  return (
    <Form methods={methods} onSubmit={onSubmit} className="form form-style-filter">
      <Row>
        <Col md={10} className="row">
          <ColAuto>
            <FormSelect
              options={resortOptions}
              name="resort"
              label="Ośrodek"
              selectProps={{ isClearable: true, placeholder: 'Wybierz ośrodek' }}
            />
          </ColAuto>
          <ColAuto>
            <FormGroup>
              <label className="d-block">&nbsp;</label>
              <Button color="primary">Filtruj</Button>
              {methods.formState.isDirty && (
                <Button color="light" className="ml-1" type="button" onClick={handleReset}>
                  Wyczyść
                </Button>
              )}
            </FormGroup>
          </ColAuto>
        </Col>
        {canAdd && (
          <Col md={2} className="text-right px-0">
            <ServiceBandAdd />
          </Col>
        )}
      </Row>
      <Row>
        <ColAuto>
          <FormCheckbox name="is_removed" label="Pokaż też skasowane" className="text-default mt-4" />
        </ColAuto>
        <SearchFilter />
      </Row>
    </Form>
  )
}
