import * as React from 'react'
import classNames from 'classnames'
import { UpSellBookingDetails, UpSellPollOption } from '@models/upselling'

interface Props {
  option: UpSellPollOption
  pollId: number
  upSellBooking: UpSellBookingDetails
  onPollUpdate: ({ url, data }: { url: string; data: { option: number; poll: number } }) => void
}

export const UpsellBookingsDetailsPoolOption = ({
  option,
  pollId,
  upSellBooking,
  onPollUpdate,
}: Props): JSX.Element => {
  const handleQuestionAnswer = (poll: number, option: number) => () => {
    onPollUpdate({ url: upSellBooking.urls.poll, data: { poll, option } })
  }

  const isQuestionAnswered = (pollId: number, optionId: number) =>
    upSellBooking.upsell_poll_answers.some(answer => answer.option_id === optionId && pollId === answer.poll_id)

  return (
    <button
      key={option.id}
      onClick={handleQuestionAnswer(pollId, option.id)}
      className={classNames('upsell__survey__item', {
        'is-selected': isQuestionAnswered(pollId, option.id),
      })}
    >
      <span className="upsell__survey__item__circle" />
      <i className="uil-check text-default upsell__survey__item__check" />
      {option.text}
    </button>
  )
}
