import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { CrmAppData } from '@models/crm'
import { apiQueryFunction } from '@api/base'

export const crmApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: '' }),
  reducerPath: 'crmApi',
  endpoints: builder => ({
    getCrmAppData: builder.query<CrmAppData, string>({
      queryFn: apiQueryFunction((_, url: string) => ({ method: 'GET', url })),
    }),
  }),
})
export const { useGetCrmAppDataQuery } = crmApi
