import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { parseISODate, toDefaultDateFormat } from '@helpers/date-helper'
import declination from '@helpers/declination'
import { differenceInDays } from 'date-fns'

interface Props<T> {
  booking: T
}

export const ReservationDataStayDates = <T extends { date_from: string; date_to: string }>({
  booking,
}: Props<T>): JSX.Element => {
  const numberOfNights = differenceInDays(
    parseISODate(booking.date_to) as Date,
    parseISODate(booking.date_from) as Date,
  )

  return (
    <IconWithText
      textClass="font-weight-bold"
      icon="uil-calender text-secondary me-2 font-16"
      text={
        <span>
          {toDefaultDateFormat(booking.date_from)} <i className="uil-arrow-right text-secondary font-16" />{' '}
          {toDefaultDateFormat(booking.date_to)}{' '}
          <span className="font-weight-normal font-12">
            ({numberOfNights} {declination.night(numberOfNights)})
          </span>
        </span>
      }
    />
  )
}
