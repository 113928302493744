import * as React from 'react'
import { ClientDetails } from '@models/clients'
import { Card, CardBody, Col } from 'reactstrap'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { PackageVip } from '@modules/promotions/package-vip/models'
import { ClientDetailsModalPackageVipsRow } from '@modules/crm/clients/details-modal/packages-vip/package-vips-row'
import { getClientDetails } from '@store/actions/crm-actions'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useSortedRows } from '@components/table/use-sorted-rows'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { ClientDetailsModalPackageVipsTableFilters } from '@modules/crm/clients/details-modal/packages-vip/filters'
import { createSelectOption } from '@helpers/utils'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { filterClientDetailsProductByStatus } from '@modules/crm/clients/details-modal/utils'

const PackageVipStatusOptions = [
  createSelectOption('Wstępny', 'initial'),
  createSelectOption('Potwierdzony', 'confirmed'),
  createSelectOption('Wykorzystany', 'completed'),
  createSelectOption('Skasowany', 'removed'),
]

const defaultFilters = {
  search: '',
  ordering: 'number',
  page: 1,
  page_size: 9999,
  statuses: PackageVipStatusOptions.filter(status => ['confirmed', 'initial'].includes(status.value)),
}

const headerValues: TableHeaderValue<PackageVip>[] = [
  { sortField: 'number', title: 'Nr pakietu', search: true },
  { sortField: 'status', title: 'Status' },
  { sortField: 'price_brutto', title: 'Forma płatności' },
  { sortField: 'required_payment', title: 'Pozostało do zapłaty' },
  { sortField: 'seller', title: 'Sprzedawca' },
  { sortField: 'created_by', title: 'Utworzony przez' },
]

export interface ClientDetailsModalPackageVipFilters extends TableFilters {
  statuses: CustomReactSelectOption[]
}

interface Props {
  client: ClientDetails
}

export const ClientDetailsModalPackageVips: React.FC<Props> = ({ client }) => {
  const [filters, setFilters] = React.useState<ClientDetailsModalPackageVipFilters>(defaultFilters)
  const dispatch = useAppDispatch()

  const packageVipDetails = useAppSelector((state: RootState) => state.packageVipState.packageVipDetails)

  const refreshClient = () => dispatch(getClientDetails(client.urls.details))

  useDidUpdateEffect(() => {
    refreshClient()
  }, [packageVipDetails])

  const sortedPackages = useSortedRows<PackageVip>(client.packages_vip, filters.ordering)

  const filteredPackages = React.useMemo(
    () => filterClientDetailsProductByStatus(sortedPackages, filters.statuses),
    [sortedPackages, filters.statuses],
  )

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <h4 className="mb-2 text-secondary">Pakiet VIP</h4>
          <ClientDetailsModalPackageVipsTableFilters
            client={client}
            filters={filters}
            defaultFilters={defaultFilters}
            onClientRefresh={refreshClient}
            onFiltersChange={setFilters}
            packageVipStatusOptions={PackageVipStatusOptions}
          />
          <Table
            className="table-hover w-100"
            headerValues={headerValues}
            showPagination={false}
            isLoading={false}
            filters={filters}
            emptyText="Brak pakietów VIP"
            setFilters={setFilters}
          >
            {filteredPackages.map((packageVip: PackageVip) => (
              <ClientDetailsModalPackageVipsRow key={packageVip.id} packageVip={packageVip} />
            ))}
          </Table>
        </CardBody>
      </Card>
    </Col>
  )
}
