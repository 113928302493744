import * as React from 'react'
import Spinner from '@hyper/spinner'

interface Props {
  isLoading: boolean
  onReject: () => void
  onConfirm: () => void
}

export const InlineConfirmReject = ({ isLoading, onConfirm, onReject }: Props): JSX.Element => (
  <>
    <button
      onClick={onConfirm}
      className="btn btn-success border-0 shadow-none  p-1 rounded-0 d-flex align-items-center justify-content-start"
      disabled={isLoading}
    >
      {isLoading ? <Spinner size="xs" color="white" className="inline-spinner" /> : <i className="uil-check" />}
    </button>
    <button className="btn btn-danger border-0 shadow-none  p-1 rounded-left-0" onClick={onReject}>
      <i className="uil-times" />
    </button>
  </>
)
