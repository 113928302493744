import * as React from 'react'
import { Collapse } from 'reactstrap'
import { asDecimal, formatPrice, sumDecimalArray } from '@helpers/utils'
import { useExpandableItems } from '@components/hooks/use-expandable-items'
import { useContext } from 'react'
import { CartContext } from '@modules/reception/cart/cart-context'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { ReceptionBookingDetailsCartPayment } from '@models/reception'
import { ReceptionBookingCartPaymentItem } from '@modules/reception/cart/cart-items/payments/reception-booking-cart-payment-item'

interface Props {
  payments: ReceptionBookingDetailsCartPayment[]
}

export const ReceptionBookingCartPayments = ({ payments }: Props): JSX.Element => {
  const { allRowCollapsed } = useContext(CartContext)
  const { isExpanded, toggleExpanded, ExpandArrow, setExpanded } = useExpandableItems()

  useDidUpdateEffect(() => {
    if (!allRowCollapsed) {
      setTimeout(() => {
        setExpanded('payments', !allRowCollapsed)
      }, 300)
      return
    }

    setExpanded('payments', !allRowCollapsed)
  }, [allRowCollapsed])

  const totalPayments = sumDecimalArray(payments.map(payment => asDecimal(payment.amount))).toString()

  return (
    <li className="row border-bottom align-items-center reception-booking-cart__expandable is-payment">
      <div className="col-7 d-flex py-1 align-items-center reception-booking-cart__payments">
        <strong>Wpłaty</strong>
        {!!payments.length && (
          <ExpandArrow
            isExpanded={isExpanded('payments')}
            onExpand={toggleExpanded('payments')}
            className="lh-0 ml-1"
          />
        )}
      </div>
      <div className="col-3" />
      <div className="col-2 font-weight-bold text-success">{formatPrice(totalPayments)}</div>
      <Collapse isOpen={isExpanded('payments')} className="w-100 overflow-hidden">
        <ul className="list-unstyled">
          {payments.map(payment => (
            <ReceptionBookingCartPaymentItem key={payment.id} payment={payment} />
          ))}
        </ul>
      </Collapse>
    </li>
  )
}
