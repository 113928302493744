import * as React from 'react'
import { Button } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'

export const AgreementCreateButton: React.FC = () => {
  const [toggleModal] = useModal('AgreementCreateModal', {})

  return (
    <Button onClick={toggleModal} color="green-dark" type="button" className="d-flex align-items-center btn-tall mr-2">
      <i className="mdi mdi-plus-circle" />
    </Button>
  )
}
