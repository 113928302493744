import * as React from 'react'
import { ReceptionBooking } from '@models/reception'
import { ImprovementIcon } from '@components/improvement-icon'
import { ImprovementTooltip, useTooltipImprovementsGrouped } from '@modules/reception/common/improvement-tooltip'

interface ReceptionToCheckInRowImprovementsProps {
  booking: ReceptionBooking
  showLabel?: boolean
}

export const ReceptionToCheckInRowImprovements: React.FC<ReceptionToCheckInRowImprovementsProps> = ({
  showLabel = true,
  booking,
}) => {
  const { improvementsGrouped, improvements } = useTooltipImprovementsGrouped({ elements: booking.improvements })

  const improvementsLength = React.useMemo(
    () => (booking.has_package_vip ? improvements.length + 1 : improvements.length),
    [improvements, booking.has_package_vip],
  )

  const tooltipId = `receptionBookingImprovements-${booking.id}`

  return (
    <>
      {showLabel && (
        <div className="mb-1 d-block">
          Ulepszenia: <strong>{improvementsLength}</strong>
        </div>
      )}
      <div className="d-inline">
        <ImprovementTooltip tooltipId={tooltipId} improvementsGrouped={improvementsGrouped}>
          {booking.has_package_vip && (
            <span className="mr-1" title="Pakiet VIP">
              <ImprovementIcon improvementCode="package_vip" className="text-secondary" />
            </span>
          )}
          {improvementsGrouped.map((improvement, i) => (
            <ImprovementIcon improvementCode={improvement.code} className="text-secondary mr-1" key={i} />
          ))}
        </ImprovementTooltip>
      </div>
    </>
  )
}
