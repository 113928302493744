import * as React from 'react'
import { ReceptionBookingDetails, ReceptionReminder } from '@models/reception'
import { FormProvider, useForm } from 'react-hook-form'
import { ReceptionBookingCheck } from '@modules/reception/common/reception-booking-check'
import ModalBody from 'reactstrap/lib/ModalBody'
import { newUserPredicator, ReceptionCheckReminders } from '@modules/reception/common/reception-check-reminders'
import { ReceptionBookingCheckOutStepThanksBoxes } from '@modules/reception/checkout/step-thanks/step-thanks-boxes'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { ReceptionBookingCheckOutStepThanksBoxReceipt } from '@modules/reception/checkout/step-thanks/receipt'
import { useReceptionCheckOut } from '@modules/reception/checkout/step-settlement/use-reception-check-out'

interface ReceptionBookingCheckOutStepThanksProps {
  booking: ReceptionBookingDetails
  nextStep: () => void
  handleIsOpen: () => void
}

const reminders: ReceptionReminder[] = [
  {
    id: 'check_package',
    isClosable: true,
    children: (
      <>
        Pamiętaj, że <strong>paczka pożegnalna</strong> powinna być wydana <strong>podczas wymeldowania.</strong>
      </>
    ),
  },
  {
    id: 'check_feedback',
    isClosable: true,
    children: (
      <>
        Poproś Gościa o <strong>krótki feedback</strong> na temat pobytu, <strong>co mu się podobało</strong> i do czego
        miał <strong>zastrzeżenia.</strong>
      </>
    ),
  },
]

interface FormInputs {
  check_package: boolean
  check_feedback: boolean
  language: string
}

export const ReceptionBookingCheckOutStepThanks: React.FC<ReceptionBookingCheckOutStepThanksProps> = ({
  nextStep,
  booking,
  handleIsOpen,
}) => {
  const user = useAuthenticatedUser()
  const methods = useForm<FormInputs>({
    defaultValues: {
      language: 'pl',
      check_feedback: newUserPredicator(user),
      check_package: newUserPredicator(user),
    },
  })

  const { confirmCheckedOut, isConfirming } = useReceptionCheckOut(booking)

  const onSubmit = async () => {
    await confirmCheckedOut()
    handleIsOpen()
  }

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        role="step-thanks"
        className="reception__booking-check-out__step-thanks"
      >
        <ModalBody className="py-0">
          <ReceptionBookingCheckOutStepThanksBoxReceipt receptionBookingDetails={booking} />
          <h4>4. Zapraszamy za rok</h4>
          <ReceptionCheckReminders reminders={reminders} required={!newUserPredicator(user)} />

          <ReceptionBookingCheckOutStepThanksBoxes />
        </ModalBody>
        <ReceptionBookingCheck
          isSaving={isConfirming}
          nextLabel="Zakończ wymeldowanie"
          nextLabelSaving="Wymeldowanie gościa.."
          nextStep={nextStep}
        />
      </form>
    </FormProvider>
  )
}
