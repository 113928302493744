import Decimal from 'decimal.js'
import { sub } from 'date-fns'
import { asDecimal } from '@helpers/utils'

export const calculateMeterCosts = (initialValue, finalValue, unitPrice) => {
  const start = asDecimal(initialValue)
  const end = asDecimal(finalValue)
  const factor = asDecimal(unitPrice)
  return {
    consumption: Decimal.max(end.minus(start), asDecimal(0)).toString(),
    amount: Decimal.max(asDecimal(0), end.minus(start).mul(factor)).toString(),
  }
}
export const isOutOfDateReadingTime = (readingDateTime: Date): boolean => {
  const requiredLastReadingTime = sub(new Date(), { minutes: 40 })

  return readingDateTime < requiredLastReadingTime
}
