import * as React from 'react'
import Table from '@components/table/table'
import { SubscriptionBooking, SubscriptionBookingCode, SubscriptionDetails } from '@modules/subscription/models'
import { formatPrice } from '@helpers/utils'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { resortsMapSelector } from '@store/selectors/dashboard'
import { useAppSelector } from '@store/index'
import { SubscriptionDetailsBookingsRowChangeClientData } from '@modules/subscription/details/bookings-row-change-client-data'
import { SubscriptionDetailsBookingsRowChangeClientDate } from '@modules/subscription/details/bookings-row-change-client-date'

interface Props {
  booking: SubscriptionBooking
  index: number
  subscriptionDetails: SubscriptionDetails
}

export const SubscriptionDetailsBookingsRow: React.FC<Props> = ({ booking, index, subscriptionDetails }) => {
  const resortsMap = useAppSelector(resortsMapSelector)

  return (
    <Table.Row key={booking.id}>
      <Table.Cell>{index + 1}.</Table.Cell>
      <Table.Cell>
        <a href={booking.urls.admin} target="_blank">
          <div>{booking.name}</div>
          <span>{booking.reservation_number}</span>
          <span className="d-flex flex-column font-10">
            {booking.codes.map((code: SubscriptionBookingCode) => (
              <div key={code.id}>{code.code}</div>
            ))}
          </span>
        </a>
      </Table.Cell>
      <Table.Cell>{booking.status_display}</Table.Cell>
      <Table.Cell>{booking.promocode}</Table.Cell>
      <Table.Cell>{resortsMap[booking.resort_id]?.name}</Table.Cell>
      <Table.Cell>{formatPrice(booking.total_payment)}</Table.Cell>
      <Table.Cell>
        {toDefaultDateTimeFormat(booking.created_at)}
        <small className="d-block">synch: {toDefaultDateTimeFormat(booking.last_synchronization)}</small>
      </Table.Cell>
      <Table.Cell>
        {booking.subscription_allow_change_client_data && (
          <SubscriptionDetailsBookingsRowChangeClientData booking={booking} subscriptionDetails={subscriptionDetails} />
        )}{' '}
        {booking.subscription_allow_change_client_date && (
          <SubscriptionDetailsBookingsRowChangeClientDate booking={booking} subscriptionDetails={subscriptionDetails} />
        )}
      </Table.Cell>
    </Table.Row>
  )
}
