import * as React from 'react'

interface Props {
  title?: string
  content: React.ReactNode
}

export const ReceptionBookingCheckInPreviewPersonalDataRow = ({ title = '', content }: Props): JSX.Element => (
  <div className="row mb-2">
    <div className="col-5">{title}</div>
    <div className="col-7 text-semi-strong">{content}</div>
  </div>
)
