import * as React from 'react'
import { ClientUser } from '@models/clients'
import Table from '@components/table/table'
import classNames from 'classnames'
import { ClientDetailsModalAgreementRow } from '@modules/crm/clients/details-modal/base-data/agreement-row'
import { useDetailsModal } from '@components/hooks/use-details-modal'
import { NavigationPath } from '@models/routes'

interface Props {
  client: ClientUser
  selectableCell: React.ReactElement
  isSelected: boolean
}

export const CrmClientsTableRow: React.FC<Props> = ({ isSelected, client, selectableCell }) => {
  const { handleClientDetails } = useDetailsModal(
    ['ClientDetailsModal', { client }],
    NavigationPath.CrmClients,
    NavigationPath.CrmClientsWitParams,
    client.id,
    ':client_id',
  )

  return (
    <Table.Row
      className={classNames('cursor-pointer', {
        'table-active': isSelected,
      })}
      onDoubleClick={handleClientDetails}
      role="client-details"
    >
      {selectableCell}
      <Table.Cell>{client.name}</Table.Cell>
      <Table.Cell>{client.profile?.client_kind_display}</Table.Cell>
      <Table.Cell>{client.phone}</Table.Cell>
      <Table.Cell>{client.email}</Table.Cell>
      <Table.Cell>
        {client.profile?.is_blacklisted && (
          <span className="badge badge-danger-lighten">
            <i className="uil-ban mr-1" />
            tak
          </span>
        )}
      </Table.Cell>
      <Table.Cell>
        <ClientDetailsModalAgreementRow
          client={client}
          keyword="phone_call_rules"
          acceptationDateClassName="d-block mt-1 w-50"
          isDisabled={!client.phone}
        />
      </Table.Cell>
      <Table.Cell>
        <ClientDetailsModalAgreementRow
          client={client}
          keyword="sms_rules"
          acceptationDateClassName="d-block mt-1 w-50"
          isDisabled={!client.phone}
        />
      </Table.Cell>
      <Table.Cell>
        <ClientDetailsModalAgreementRow
          client={client}
          keyword="email_rules"
          acceptationDateClassName="d-block mt-1 w-50"
        />
      </Table.Cell>
    </Table.Row>
  )
}
