import { TechnicalOrder } from '@models/technical-orders'
import { TableHeaderValue } from '@components/table/table'
import { TableViewType } from '@modules/technical-orders/common/table-view-switch'

export const getTechnicalOrderStatusColor = (technicalOrder: TechnicalOrder) => {
  switch (technicalOrder.status) {
    case 'in_progress':
      return 'warning-lighten'
    case 'ordered':
      return 'warning-lighten'
    case 'done':
      return 'success-lighten'
    case 'todo':
      return 'danger-lighten'
    case 'new':
      return 'dark-lighten'
    default:
      return 'info-lighten'
  }
}

export const filterTableHeaders = (
  headers: TableHeaderValue[],
  resortId: number,
  tableViewType: TableViewType = 'list',
) =>
  headers
    .filter(row => (resortId ? row.sortField !== 'resort_id' : row))
    .filter(row => (tableViewType === 'grid' ? row.sortField !== 'users' : row.sortField !== 'staff'))

export const getDefaultSelectedUsers = (technicalOrderDetails?: TechnicalOrder) =>
  technicalOrderDetails?.users
    .map(row => row.id)
    .reduce((users, userId) => ({ ...users, [`user__${userId}`]: true }), {}) ?? {}

export const getSelectedUsersPayload = (usersPayload: { [key: string]: boolean }) =>
  Object.entries(usersPayload).reduce((userIds, [key, value]) => {
    if (value) return [...userIds, parseInt(key.split('__')[1], 10)]
    return userIds
  }, [])
