import * as React from 'react'
import { PageTitle } from '@hyper/page-title'
import { useDocumentTitle } from '@helpers/utils'
import { Card, CardBody } from 'reactstrap'
import { useAppDispatch, useAppSelector } from '@store/index'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useDebounce } from 'rooks'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { getRentalProductCategories } from '@store/actions/rental-warehouse-actions'
import { selectRentalProductCategories } from '@store/slices/rental-slice'
import { RentalProductCategoriesFilters } from '@modules/rental/product-categories/rental-product-categories-filters'
import { RentalProductCategoriesTable } from '@modules/rental/product-categories/rental-product-categories-table'
import { TableFilters } from '@components/table/table'

const pageTitle = 'Lista kategorii produktów'

const defaultFilters: TableFilters = {
  ordering: '-created',
  page: 1,
  page_size: 10,
  search: '',
}

export const RentalProductCategoriesView: React.FC = () => {
  const [filters, setFilters] = React.useState<TableFilters>(defaultFilters)

  const dispatch = useAppDispatch()
  useDocumentTitle(pageTitle)
  useMenuActive(NavigationPath.RentalProductCategories)

  const productCategories = useAppSelector(selectRentalProductCategories)

  const { isLoading, action: fetchProductCategories } = useApiRequest(
    async filters => await dispatch(getRentalProductCategories(filters)),
  )

  const fetchRentalProductCategories = React.useCallback(useDebounce(fetchProductCategories, 300), [])

  React.useEffect(() => {
    fetchRentalProductCategories(filters)
  }, [filters])

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitle} />
      <Card>
        <CardBody>
          <RentalProductCategoriesFilters filters={filters} setFilters={setFilters} />
          <RentalProductCategoriesTable
            isLoading={isLoading}
            filters={filters}
            setFilters={setFilters}
            productCategories={productCategories}
          />
        </CardBody>
      </Card>
    </>
  )
}
