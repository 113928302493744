import * as React from 'react'
import classNames from 'classnames'
import { BookingStatsDetails, StatsCellContent } from '@modules/reservations/models'

interface Props {
  content: StatsCellContent<BookingStatsDetails>
  onClick: () => void
  color?: string
}

export const ReservationStatsReportCell = ({ content, onClick, color = 'text-gold-light' }: Props): JSX.Element => (
  <div className="d-flex justify-content-center align-items-center cursor-pointer" onClick={onClick}>
    <strong className={color}>
      {content.statsDetails[content.element]}
      {content.options?.tableCellOption?.unit}
    </strong>
    <i className={classNames('uil-download-alt font-14 ml-1', color)} title="Pobierz raport dzienny" />
  </div>
)
