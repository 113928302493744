import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@store/index'
import { FeedingAppData, FeedingClient, FeedingSpecialGuest, FeedingWorker } from '@modules/feeding/models'
import {
  getFeedingAppData,
  getFeedingClients,
  getFeedingSpecialGuests,
  getFeedingWorkers,
} from '@store/actions/feeding-actions'

interface FeedingState {
  clients: EntityState<FeedingClient>
  specialGuests: EntityState<FeedingSpecialGuest>
  workers: EntityState<FeedingWorker>
  appData: FeedingAppData
}

const clientsAdapter = createEntityAdapter<FeedingClient>()
const specialGuestsAdapter = createEntityAdapter<FeedingSpecialGuest>()
const workersAdapter = createEntityAdapter<FeedingWorker>()

const initialState: FeedingState = {
  clients: clientsAdapter.getInitialState(),
  specialGuests: specialGuestsAdapter.getInitialState(),
  workers: workersAdapter.getInitialState(),
  appData: {
    departments: [],
    feeding_worker_products: [],
    payment_methods: [],
    status: 'unknown',
    start_by: [],
  },
}

export const feedingSlice = createSlice({
  name: 'feeding',
  initialState,
  reducers: {
    setFeedingAppData(state, action: PayloadAction<FeedingAppData>) {
      state.appData = action.payload
    },
    updateFeedingClientDetails(state, action: PayloadAction<FeedingClient>) {
      clientsAdapter.upsertOne(state.clients, action.payload)
    },
    updateFeedingWorkerDetails(state, action: PayloadAction<FeedingWorker>) {
      workersAdapter.upsertOne(state.workers, action.payload)
    },
    updateFeedingSpecialGuestDetails(state, action: PayloadAction<FeedingSpecialGuest>) {
      specialGuestsAdapter.upsertOne(state.specialGuests, action.payload)
    },
  },
  extraReducers: builder => {
    builder.addCase(getFeedingAppData.fulfilled, (state, action) => {
      state.appData = { ...action.payload, status: 'ready' }
    })
    builder.addCase(getFeedingClients.fulfilled, (state, action) => {
      clientsAdapter.setAll(state.clients, action.payload)
    })
    builder.addCase(getFeedingSpecialGuests.fulfilled, (state, action) => {
      specialGuestsAdapter.setAll(state.specialGuests, action.payload)
    })
    builder.addCase(getFeedingWorkers.fulfilled, (state, action) => {
      workersAdapter.setAll(state.workers, action.payload)
    })
  },
})

export const { selectAll: feedingClientsSelector } = clientsAdapter.getSelectors(
  (state: RootState) => state.feedingState.clients,
)
export const { selectAll: feedingSpecialGuestsSelector } = specialGuestsAdapter.getSelectors(
  (state: RootState) => state.feedingState.specialGuests,
)
export const { selectAll: feedingWorkersSelector } = workersAdapter.getSelectors(
  (state: RootState) => state.feedingState.workers,
)

export const {
  updateFeedingClientDetails,
  updateFeedingWorkerDetails,
  updateFeedingSpecialGuestDetails,
  setFeedingAppData,
} = feedingSlice.actions

export default feedingSlice.reducer
