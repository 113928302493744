import * as React from 'react'
import { PaymentRegistersFiltersParams } from '@modules/payment-registers/list/filters'
import { PaymentRegister } from '@models/payments'
import Table, { TableHeaderValue } from '@components/table/table'
import { PaymentRegistersTableRow } from '@modules/payment-registers/list/table-row'
import { useAppDispatch, useAppSelector } from '@store/index'
import { clearPaymentRegisters, paymentRegistersSelector } from '@store/slices/cash-slice'
import { PaymentRegistersTableSummer } from '@modules/payment-registers/list/table-summer'

interface Props {
  filters: PaymentRegistersFiltersParams
  setFilters: (filters: PaymentRegistersFiltersParams) => void
  isLoading: boolean
}

const headerValues: TableHeaderValue<PaymentRegister>[] = [
  { title: 'Data', sortField: 'created' },
  { title: 'Typ' },
  { title: 'Nazwa operacji' },
  { title: 'Numer rezerwacji' },
  { title: 'Lokalizacja' },
  { title: 'Dodane przez' },
  { title: 'Kwota', sortField: 'amount' },
  { title: 'Status' },
  { title: '' },
]

export const PaymentRegistersTable = ({ filters, setFilters, isLoading }: Props): JSX.Element => {
  const dispatch = useAppDispatch()

  const paymentRegisters = useAppSelector(paymentRegistersSelector)

  React.useEffect(
    () => () => {
      dispatch(clearPaymentRegisters())
    },
    [],
  )

  return (
    <Table
      className="table-hover w-100"
      headerValues={headerValues}
      isLoading={isLoading}
      filters={filters}
      setFilters={setFilters}
    >
      {paymentRegisters.map(paymentRegister => (
        <PaymentRegistersTableRow key={paymentRegister.id} paymentRegister={paymentRegister} />
      ))}
      <PaymentRegistersTableSummer />
    </Table>
  )
}
