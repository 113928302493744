import * as React from 'react'
import { CleaningOrder } from '@modules/housekeeping/models'
import { HousekeepingCleaningOrderAcceptedRow } from '@modules/housekeeping/cleaning-orders/accepted/row'
import { HousekeepingOrderTableHeaderKind } from '@modules/housekeeping/common/table-headers/table-header-kind'
import { HousekeepingOrderTableHeaderServiceKind } from '@modules/housekeeping/common/table-headers/table-header-service-kind'
import { HousekeepingNoOrdersRow } from '@modules/housekeeping/cleaning-orders/common/no-orders'

interface HousekeepingCleaningOrdersAcceptedTableProps {
  cleaningOrders: CleaningOrder[]
}

export const HousekeepingCleaningOrdersAcceptedTable: React.FC<HousekeepingCleaningOrdersAcceptedTableProps> = ({
  cleaningOrders,
}) => (
  <table className="housekeeping__orders_table table table-striped">
    <thead>
      <tr>
        <th className="border-top-0">Lokal</th>
        <HousekeepingOrderTableHeaderKind />
        <HousekeepingOrderTableHeaderServiceKind />
        <th className="border-top-0">Zlecone przez</th>
        <th className="border-top-0">Wykonawca</th>
        <th className="border-top-0">Czas pracy</th>
        <th className="border-top-0" style={{ width: 10 }}>
          Akceptacja
        </th>
        <th className="border-top-0" style={{ width: 10 }} />
      </tr>
    </thead>
    <tbody>
      {!cleaningOrders.length ? (
        <HousekeepingNoOrdersRow colSpan={8} />
      ) : (
        <>
          {cleaningOrders.map(cleaningOrder => (
            <HousekeepingCleaningOrderAcceptedRow cleaningOrder={cleaningOrder} key={cleaningOrder.id} />
          ))}
        </>
      )}
    </tbody>
  </table>
)
