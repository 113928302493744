import * as React from 'react'
import { SubscriptionBooking, SubscriptionDetails } from '@modules/subscription/models'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { useSortedRows } from '@components/table/use-sorted-rows'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { useFilteredRows } from '@components/table/use-filtered-rows'
import { SubscriptionDetailsBookingsRow } from '@modules/subscription/details/bookings-row'

interface Props {
  subscriptionDetails: SubscriptionDetails
}

const headerValues: TableHeaderValue<SubscriptionBooking>[] = [
  { title: 'LP' },
  { title: 'Numer rezerwacji', sortField: 'reservation_number', search: true },
  { title: 'Status', sortField: 'status' },
  { title: 'Kod', sortField: 'promocode' },
  { title: 'Ośrodek', sortField: 'resort_id' },
  { title: 'Wpłata', sortField: 'total_payment' },
  { title: 'Utworzono', sortField: 'created_at' },
  { title: '', sortField: '' },
]

const defaultFilters: TableFilters = {
  search: '',
  ordering: '-is_used',
  page: 1,
  page_size: 9999,
}

export const SubscriptionDetailsBookings = React.forwardRef<HTMLElement, Props>(({ subscriptionDetails }, ref) => {
  const [filters, setFilters] = React.useState<TableFilters>(defaultFilters)

  const setValue = (_, search) => setFilters({ ...filters, search })

  const filteredRows = useFilteredRows<SubscriptionBooking>(
    subscriptionDetails.bookings,
    headerValues,
    String(filters.search),
  )
  const bookings = useSortedRows<SubscriptionBooking>(filteredRows, filters.ordering)

  return (
    <Card innerRef={ref}>
      <CardBody>
        <Row className="align-items-center">
          <Col className="col-auto col-md-4">
            <span className="h4 text-secondary">Powiązane rezerwacje</span>
          </Col>
          <SearchFilter hideLabel={true} setValue={setValue} colClassName="col-md-2" />
        </Row>
        <Table
          className="table-hover w-100 overflow-hidden"
          headerValues={headerValues}
          isLoading={false}
          showPagination={false}
          filters={filters}
          setFilters={setFilters}
        >
          {bookings.map((booking, index) => (
            <SubscriptionDetailsBookingsRow
              key={booking.id}
              index={index}
              booking={booking}
              subscriptionDetails={subscriptionDetails}
            />
          ))}
        </Table>
      </CardBody>
    </Card>
  )
})
