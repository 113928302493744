import * as React from 'react'
import Spinner from '@hyper/spinner'
import { FormPlainError } from '@hyper/forms'
import classnames from 'classnames'

interface HtmlEditorLazyProps {
  contentHtml: string
  setContent: (content: string) => void
  disabled?: boolean
  className?: string
  name?: string
  errors?: any
  onInit?: () => void
}

const HtmlEditor = React.lazy(() => import('@components/html-editor/html-editor'))

export const HtmlEditorLazy: React.FC<HtmlEditorLazyProps> = ({
  contentHtml,
  setContent,
  disabled,
  className,
  name,
  errors,
  onInit,
}) => {
  const hasFormError = errors && name

  return (
    <React.Suspense
      fallback={
        <div className="p-2 text-center">
          <Spinner />
        </div>
      }
    >
      <div className={classnames({ 'border rounded border-danger': hasFormError && errors[name] })}>
        <HtmlEditor
          className={className}
          setContent={setContent}
          disabled={disabled}
          contentHtml={contentHtml}
          onInit={onInit}
        />
      </div>
      {hasFormError && <FormPlainError name="content" errors={errors} />}
    </React.Suspense>
  )
}
