import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'

export const FeedingHostView: React.FC = () => {
  const user = useAuthenticatedUser()

  const navigate = useNavigate()

  React.useEffect(() => {
    navigate(NavigationPath.FeedingHostWithParams.replace(':resort_id', String(user.resorts[0].id)))
  }, [])

  return null
}
