import * as React from 'react'
import { FormSelect } from '@hyper/forms/form-select'
import { Row } from 'reactstrap'
import { ClientDetailsBookingsFilters } from '@modules/crm/clients/details-modal/bookings/bookings'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { FormProvider, useForm } from 'react-hook-form'
import { SubmitFilter } from '@modules/reception/common/filters/submit-filter'
import { CustomReactSelectOption } from '@components/custom-react-select'

interface Props {
  filters: ClientDetailsBookingsFilters
  defaultFilters: ClientDetailsBookingsFilters
  onFiltersChange: (filters: ClientDetailsBookingsFilters) => void
  bookingStatusOptions: CustomReactSelectOption[]
}

export const ClientDetailsModalBookingsFilters = ({
  filters,
  defaultFilters,
  onFiltersChange,
  bookingStatusOptions,
}: Props): JSX.Element => {
  const methods = useForm<ClientDetailsBookingsFilters>({
    defaultValues: filters,
  })

  const handleReset = () => {
    methods.reset()
    onFiltersChange(defaultFilters)
  }

  useHandleTableFiltersChange(methods.watch, filters, onFiltersChange)

  return (
    <FormProvider {...methods}>
      <Row>
        <FormSelect
          options={bookingStatusOptions}
          name="statuses"
          label="Status"
          selectProps={{ isSelectMulti: true, isClearable: true }}
          formPlainProps={{ colSize: 6 }}
        />

        <SubmitFilter handleReset={handleReset} />
      </Row>
    </FormProvider>
  )
}
