import * as React from 'react'
import classNames from 'classnames'

export interface ReceptionCheckReminderProps {
  children: React.ReactNode
  id: string
  register?: any
  errors: any
  type?: 'success' | 'info' | 'danger'
  initialValue: boolean
  required: boolean
  isClosable?: boolean
}

export const ReceptionCheckReminder: React.FC<ReceptionCheckReminderProps> = ({
  type = 'info',
  register,
  errors,
  children,
  initialValue,
  id,
  required,
  isClosable,
}) => {
  const [isComplete, setIsComplete] = React.useState(initialValue)
  const handleClose = () => setIsComplete(true)

  const alertClass =
    {
      info: 'alert-success-lighten',
      success: 'alert-success',
      danger: 'alert-danger',
    } ?? 'alert-success-lighten'

  return (
    <div
      className={classNames('alert font-11 reception__check-reminder pl-0 mb-1', alertClass[type], {
        'reception__check-reminder--is-complete': isComplete,
        'reception__check-reminder--has-error': !!errors[id],
      })}
      role="alert"
    >
      <div className="reception__check-reminder__items">
        <div className="reception__check-reminder__items__icon mx-2">
          <i
            className={classNames({
              'uil-exclamation-circle': ['info', 'danger'].includes(type),
              'uil-volume': type === 'success',
            })}
          />
        </div>
        <div>{children}</div>
        {isClosable && (
          <div className="reception__check-reminder__items__close">
            <label
              className="close reception__check-reminder__items__close__button"
              onClick={handleClose}
              role={`reminder-${id}`}
            >
              {!!register && (
                <input
                  className="d-none"
                  type="checkbox"
                  {...register(id, {
                    required: required,
                  })}
                />
              )}
              <span>zrobione</span>
              <i className="ml-1 uil-check-circle font-14" />
            </label>
          </div>
        )}
      </div>
    </div>
  )
}
