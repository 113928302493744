import * as React from 'react'
import classNames from 'classnames'

interface Props {
  children: React.ReactNode
  image: string
  className?: string
}

export const NotificationsEmptyMessage = ({ children, image, className = 'mt-n1' }: Props): React.ReactNode => (
  <div className={classNames('d-flex align-items-center', className)}>
    <img src={image} alt="" height="60" className="pl-3" />
    <span className="text-muted pl-2 pt-2">{children}</span>
  </div>
)
