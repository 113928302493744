import * as React from 'react'
import { ContentNotesCardFooter } from '@components/content-notes/content-notes-card-footer'
import { useToggle } from '@components/hooks/use-toggle'

const INITIAL_AMOUNT_OF_NOTIFICATIONS_TO_SHOW = 3

interface Props<T> {
  notifications: T[]
  renderNotification: (notification: T) => React.ReactNode
  contentName: string
  noMessageComponent: React.ReactNode
}

export const NotificationMessages = <T,>({
  notifications,
  renderNotification,
  contentName,
  noMessageComponent,
}: Props<T>): React.ReactNode => {
  const [allNotificationsShowed, toggleAllNotificationsShowed] = useToggle(false)

  const initialNotifications = notifications.slice(0, INITIAL_AMOUNT_OF_NOTIFICATIONS_TO_SHOW)
  const notificationsToShow = allNotificationsShowed ? notifications : initialNotifications

  return (
    <div>
      {notifications.length ? (
        <div style={{ maxHeight: '600px' }} className="overflow-auto pb-3 light-scrollbar mr-n1 pr-1">
          {notificationsToShow.map(renderNotification)}
        </div>
      ) : (
        noMessageComponent
      )}
      {notifications.length > INITIAL_AMOUNT_OF_NOTIFICATIONS_TO_SHOW && (
        <div className="mx-n3 mb-n3">
          <ContentNotesCardFooter
            contentName={contentName}
            onShowAllNotesClick={toggleAllNotificationsShowed}
            allNotesLength={notifications.length}
            allNotesShowed={allNotificationsShowed}
          />
        </div>
      )}
    </div>
  )
}
