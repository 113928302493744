import { createSelectOption } from '@helpers/utils'
import { SubscriptionContractPlans } from '@modules/subscription-contract/models'

export const SubscriptionContractStatusOptions = [
  createSelectOption('Wstępna', 'initial'),
  createSelectOption('Wstępna (przywrócona)', 'initial_resume'),
  createSelectOption('Wstępna (anulowana)', 'initial_cancelled'),
  createSelectOption('Potwierdzona', 'confirmed'),
  createSelectOption('Skasowana', 'removed'),
  createSelectOption('Zakończona', 'completed'),
  createSelectOption('Anulowana', 'cancelled'),
  createSelectOption('W trakcie anulowania', 'cancelling'),
]

export const getPlanOptions = (plans: SubscriptionContractPlans, onlyIsActive = false) =>
  Object.entries(plans)
    .filter(row => (onlyIsActive ? row[1].is_active : true))
    .map(([key, plan]) => createSelectOption(plan.name, key))
