import * as React from 'react'
import { NotificationEvents } from '@models/dashboard'
import { useAppDispatch } from '@store/index'
import { useWebSocket } from '@components/hooks/use-web-socket'
import { RecommendationGifDetails } from '@models/crm'
import { addRecommendationGiftDetails, updateRecommendationGiftDetails } from '@store/slices/recommendation-gift-slice'

export const RecommendationGiftsWebSocketHandler: React.FC = () => {
  const dispatch = useAppDispatch()

  useWebSocket<RecommendationGifDetails>(
    (message, payload) => {
      switch (message) {
        case NotificationEvents.RECOMMENDATION_GIFT_ADD:
          dispatch(addRecommendationGiftDetails(payload))
          break
        case NotificationEvents.RECOMMENDATION_GIFT_CHANGE:
          dispatch(updateRecommendationGiftDetails(payload))
          break
      }
    },
    [],
    [NotificationEvents.RECOMMENDATION_GIFT_CHANGE, NotificationEvents.RECOMMENDATION_GIFT_ADD],
  )
  return null
}
