import { PackageDetails } from '@models/package'
import * as React from 'react'
import { PackageDetailsRow } from '@modules/package/details/information/row'
import { CardBody, Row } from 'reactstrap'
import { ColAuto } from '@hyper/col-auto'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  packageDetails: PackageDetails
  toggleEdit: () => void
}

export const PackageDetailsClientInformationTable: React.FC<Props> = ({ packageDetails, toggleEdit }) => (
  <CardBody>
    <Row>
      <ColAuto>
        <h4 className="mt-0 text-secondary mb-3">Dane klienta</h4>
      </ColAuto>
      <ColAuto className="col-auto ml-auto">
        <IconWithText
          icon="uil-edit-alt font-16"
          text="zmień"
          wrapperClassNames="cursor-pointer text-secondary"
          textClass="font-10 text-semi-strong"
          onClick={toggleEdit}
        />
      </ColAuto>
    </Row>
    <table className="table table-sm mb-0">
      <tbody>
        <PackageDetailsRow label="Osoba kontaktowa">{packageDetails.name}</PackageDetailsRow>
        <PackageDetailsRow label="Adres email">{packageDetails.email}</PackageDetailsRow>
        <PackageDetailsRow label="Nr telefonu">{packageDetails.phone}</PackageDetailsRow>
      </tbody>
    </table>
    <h4 className="text-secondary mb-3">Dane faktury</h4>
    <table className="table table-sm mb-0">
      <tbody>
        {Boolean(packageDetails.invoice_custom_number) && (
          <PackageDetailsRow label="Numer faktury">{packageDetails.invoice_custom_number}</PackageDetailsRow>
        )}
        <PackageDetailsRow label="Nazwa firmy">{packageDetails.invoice_company}</PackageDetailsRow>
        <PackageDetailsRow label="NIP">{packageDetails.invoice_nip}</PackageDetailsRow>
        <PackageDetailsRow label="Ulica">{packageDetails.invoice_street}</PackageDetailsRow>
        <PackageDetailsRow label="Miasto">{`${packageDetails.invoice_city}, ${packageDetails.invoice_postcode}`}</PackageDetailsRow>
      </tbody>
    </table>
  </CardBody>
)
