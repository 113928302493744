import * as React from 'react'
import { Button, Col, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import { ReceptionBookingDetails } from '@models/reception'
import { ReceptionBookingCheckInDepositDetails } from '@modules/reception/checkin/step-payments/reception-booking-check-in-deposit-details'
import { ReceptionBookingCheckInStepPaymentsBoxDeposit } from '@modules/reception/checkin/step-payments/step-payments-box-deposit'

interface Props extends BaseModalProps {
  booking: ReceptionBookingDetails
}

export const ReservationDepositPaymentModal: React.FC<Props> = ({ toggleIsVisible, booking }) => (
  <>
    <ModalHeader toggle={toggleIsVisible}>Pobierz kaucję</ModalHeader>
    <ModalBody className="bg-white-smoke d-flex align-items-start">
      <Col md={6}>
        <ReceptionBookingCheckInDepositDetails booking={booking} title="Podsumowanie" />
      </Col>
      <Col md={6}>
        <ReceptionBookingCheckInStepPaymentsBoxDeposit booking={booking} />
      </Col>
    </ModalBody>
    <ModalFooter>
      <Button color="light" type="button" onClick={toggleIsVisible}>
        Anuluj
      </Button>
    </ModalFooter>
  </>
)
