import * as React from 'react'
import { CalendarDate } from '@store/reducers/timeline-reducers'
import { Apartment } from '@models/apartment'
import timelineHelper from '@helpers/timeline-helper'
import { RootState, useAppSelector } from '@store/index'

interface Props {
  renderedDates: CalendarDate[]
  apartments: Apartment[]
}

const TimelineMask: React.FC<Props> = ({ renderedDates, apartments }) => {
  const isMask = useAppSelector((state: RootState) => state.timelineState.isMask)

  return isMask ? (
    <div
      className="calendar-content__mask"
      role="calendar-content-mask"
      style={{
        width: timelineHelper.getTodayLeftOffset(renderedDates[0].year, renderedDates[0].month),
        height: timelineHelper.getColHeight(apartments),
        maxWidth: timelineHelper.getMaskMaxWidth(renderedDates),
      }}
    />
  ) : null
}

export default TimelineMask
