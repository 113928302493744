import * as React from 'react'
import { useModal } from '@components/modals/use-modal'
import { commonObjectGet } from '@store/actions/generic-actions'
import { RootState, useAppSelector } from '@store/index'
import { PackageVip } from '@modules/promotions/package-vip/models'

interface Props {
  id: number
}

export const PackageVipDetailsView = ({ id }: Props): null => {
  const [handleDetails] = useModal('PackageVipDetails')
  const url = useAppSelector((state: RootState) => state.appState.appData.urls.promotions.package_vip)

  const show = async () => {
    const packageVip = await commonObjectGet<PackageVip>(`${url}${id}/`)
    handleDetails(null, { packageVip }, true)
  }

  React.useEffect(() => {
    show()
  }, [])

  return null
}
