import * as React from 'react'
import Table from '@components/table/table'
import { useModal } from '@components/modals/use-modal'
import { useRentalAppData } from '@modules/rental/use-rental-app-data'
import { RentalProduct, RentalProductCategory, RentalProductWarehouse } from '@modules/rental/models'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'

interface Props {
  product: RentalProduct
  index: number
}

export const RentalProductsTableRow = ({ product, index }: Props): JSX.Element => {
  const { product_categories, warehouses } = useRentalAppData()
  const user = useAuthenticatedUser()

  const [showProductDetailsModal] = useModal('RentalProductDetailsModal', { product })
  const [showProductModal] = useModal('RentalProductCreateModal', { product })

  const category = product_categories.find((category: RentalProductCategory) => category.id === product.category)
  const warehouse = warehouses.find((warehouse: RentalProductWarehouse) => warehouse.id === product.warehouse)

  return (
    <Table.Row key={product.id} onDoubleClick={showProductDetailsModal} className="cursor-pointer">
      <Table.Cell width={70}>{index + 1}</Table.Cell>
      <Table.Cell>{product.name}</Table.Cell>
      <Table.Cell>{product.code}</Table.Cell>
      <Table.Cell>{product.status_display}</Table.Cell>
      <Table.Cell>{category?.name ?? 'Brak'}</Table.Cell>
      <Table.Cell>{warehouse?.name ?? 'Brak'}</Table.Cell>
      <Table.Cell>{product.booking_reservation_number ?? 'Brak'}</Table.Cell>
      <Table.Cell style={{ width: 40 }}>
        {user.hasPerm(UserPermission.RentalProductCanManage) && (
          <i className="uil-pen font-14 lh-1 cursor-pointer" onClick={showProductModal} />
        )}
      </Table.Cell>
    </Table.Row>
  )
}
