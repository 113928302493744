import * as React from 'react'
import { useAppDispatch } from '@store/index'
import { Payment } from '@models/payments'
import { PackageVipDetails } from '@modules/promotions/package-vip/models'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApiRequest } from '@components/hooks/use-api-request'
import { updatePackageVipDetails } from '@store/slices/package-vip-slice'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'

interface PackageVipPaymentDeleteProps {
  payment: Payment
  packageVipDetails: PackageVipDetails
}

export const PackageVipPaymentDelete: React.FC<PackageVipPaymentDeleteProps> = ({ payment, packageVipDetails }) => {
  const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()

  const { isLoading, action: handleAccept } = useApiRequest(async (reason: string) => {
    const data = await commonObjectDelete<PackageVipDetails>(packageVipDetails.urls.payments, reason, {
      id: payment.id,
    })

    dispatch(updatePackageVipDetails(data))
    addSuccessMessage('Sukces', 'Płatność została skasowana')
  })

  return (
    <CommonObjectConfirmAction
      title="Potwierdź skasowanie wpłaty"
      isLoading={isLoading}
      askForReason={true}
      handleAccept={handleAccept}
      message="Czy na pewno chcesz skasować wybraną wpłatę ? Operacja ta jest nieodwołalna"
    >
      <i className="text-muted font-14 cursor-pointer uil-trash-alt" />
    </CommonObjectConfirmAction>
  )
}
