import * as React from 'react'
import { FormPlain } from '@hyper/forms'
import { useFormContext } from 'react-hook-form'
import AsyncSelect from 'react-select/async'
import { ClientUser } from '@models/clients'
import { ClientSelectComponents } from '@components/client-select-components'
import { clientSearchQuery } from '@api/account'

interface Props {
  colSize?: number
}

interface FormInputs {
  recommender_client: number | null
}

export const SubscriptionCreateFormInformationRecommender: React.FC<Props> = ({ colSize }) => {
  const { setValue } = useFormContext<FormInputs>()

  const onSuggestionsFetchRequested = async (value: string): Promise<ClientUser[]> => await clientSearchQuery(value)

  const onChange = (data: ClientUser) => setValue('recommender_client', data?.id || null)

  return (
    <FormPlain colSize={colSize || 4} name="recommender_client">
      <label>Polecający</label>
      <AsyncSelect
        styles={{
          menu: provided => ({ ...provided, zIndex: 9999 }),
          valueContainer: provided => ({ ...provided, display: 'flex' }),
        }}
        defaultOptions={[]}
        loadOptions={onSuggestionsFetchRequested}
        placeholder="Wpisz adres e-mail"
        isClearable={true}
        components={ClientSelectComponents}
        onChange={onChange}
      />
    </FormPlain>
  )
}
