import * as React from 'react'
import { useAppDispatch } from '@store/index'
import { setCalendarDates } from '@store/actions/timeline-actions'
import dateHelper from '@helpers/date-helper'
import IconChevronDoubleLeft from '@components/icons/chevron-double-left'
import IconChevronDoubleRight from '@components/icons/chevron-double-right'
import DatePickerComponent from '@components/date/date-picker'
import { addMonths, getMonth, getYear, parseISO, subMonths } from 'date-fns'
import { CalendarDate } from '@store/reducers/timeline-reducers'

interface Props {
  calendarDate: CalendarDate
}

export const TimelineMonthTitleView: React.FC<Props> = ({ calendarDate }) => {
  const dispatch = useAppDispatch()
  const [date, setDate] = React.useState(createDate(calendarDate))

  React.useEffect(() => {
    setDate(createDate(calendarDate))
  }, [calendarDate])

  const setPreviousMonth = (): void => {
    setDate(subMonths(date, 1))
    dispatch(setCalendarDates(dateHelper.getPrevCalendarDate(calendarDate), 'middle'))
  }

  const setNextMonth = (): void => {
    setDate(addMonths(date, 1))
    dispatch(setCalendarDates(dateHelper.getNextCalendarDate(calendarDate), 'middle'))
  }

  const onShowDateChange = (date: Date): void => {
    setDate(date)
    dispatch(setCalendarDates({ month: getMonth(date) + 1, year: getYear(date) }, 'middle'))
  }

  function createDate(date: CalendarDate): Date {
    return parseISO(dateHelper.createDateFormat(date.year, date.month, 1))
  }

  const onChange = () => {
    // dummy
  }

  return (
    <div className="mt-2 mb-1 d-flex align-items-center justify-content-center" data-testid="month-title">
      <button type="button" className="btn btn-pure calendar__month-button" onClick={setPreviousMonth}>
        <IconChevronDoubleLeft />
      </button>
      <DatePickerComponent
        date={date}
        onChange={onChange}
        dateFormat="LLLL yyyy"
        inputClassName="calendar__full-month-name"
        wrapperClassName="is-with-month-name without-days calendar__timeline-wrapper"
        onShownDateChange={onShowDateChange}
      />
      <button type="button" className="btn btn-pure calendar__month-button" onClick={setNextMonth}>
        <IconChevronDoubleRight />
      </button>
    </div>
  )
}
