import { RootState, useAppSelector } from '@store/index'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import * as React from 'react'

export const useBandReadersList = () => {
  const resortId = useAppSelector((state: RootState) => state.receptionState.resortDetails?.id)
  const bandReaders = useAppSelector((state: RootState) => state.appState.appData.band_readers)

  const user = useAuthenticatedUser()

  return React.useMemo(() => {
    let readers = bandReaders

    if (resortId) {
      readers = readers.filter(row => row.resort_id === resortId)
    }

    return readers.filter(row => user.resort_ids.includes(row.resort_id))
  }, [user, bandReaders, resortId])
}
