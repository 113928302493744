import * as React from 'react'
import { Col, Row } from 'reactstrap'
import { uploadApplicationImage } from '@api/housekeeping'
import { ApplicationImage } from '@modules/housekeeping/models'
import { ApplicationImageUploaderImages } from '@modules/housekeeping/applications/images-uploader/images'
import { useFormContext } from 'react-hook-form'
import { useApiRequest } from '@components/hooks/use-api-request'
import { TopBarLoading } from '@components/topbar-loading'
import { FileUploader, UploaderAcceptableFile } from '@components/file-uploader'

interface Props {
  defaultImages: ApplicationImage[]
}

interface FormInputs {
  images: number[]
}

export const ApplicationImageUploader: React.FC<Props> = ({ defaultImages }) => {
  const [images, setImages] = React.useState<ApplicationImage[]>(defaultImages)
  const { setValue } = useFormContext<FormInputs>()
  const { isLoading, action: upload } = useApiRequest(async file => {
    const image = await uploadApplicationImage(file)
    setImages(prev => [...prev, { ...image }])
  })

  React.useEffect(() => {
    setValue(
      'images',
      images.map(row => row.id),
    )
  }, [images])

  return (
    <Row>
      {isLoading && <TopBarLoading />}

      <Col md={12}>
        <FileUploader
          isLoading={isLoading}
          upload={upload}
          accept={UploaderAcceptableFile.Images}
          label="Przeciągnij i upuść zdjęcie lub kliknij by dodać zdjęcie"
        />
        <ApplicationImageUploaderImages setImages={setImages} images={images} />
      </Col>
    </Row>
  )
}
