import * as React from 'react'
import { TechnicalOrderResult } from '@models/technical-orders'
import { TechnicalOrderCloseFormInputs } from '@modules/technical-orders/technical-order-close-modal/index'
import { FormPlain } from '@hyper/forms'
import { ButtonWithIcon } from '@components/button-with-icon'
import classNames from 'classnames'
import { useFormContext } from 'react-hook-form'

interface Props {
  result: TechnicalOrderResult
}

export const TechnicalOrderCloseFormResult: React.FC<Props> = ({ result }) => {
  const { setValue } = useFormContext<TechnicalOrderCloseFormInputs>()
  const handleResultSuccess = React.useCallback(() => {
    setValue('result', 'success')
  }, [setValue])

  const handleResultReject = React.useCallback(() => {
    setValue('result', 'rejected')
  }, [setValue])

  return (
    <FormPlain
      name="result"
      label="Czy zadanie zostało wykonane?"
      formGroupClassName="px-0"
      labelSize={5}
      labelProps={{ className: 'px-0' }}
    >
      <div className="text-right d-flex justify-content-between">
        <ButtonWithIcon
          handleClick={handleResultSuccess}
          icon="uil-thumbs-up"
          text="Zrobione"
          color="green"
          btnClass={classNames('w-50 mr-1', { 'opacity-5': result !== 'success' })}
        />
        <ButtonWithIcon
          handleClick={handleResultReject}
          icon="uil-thumbs-down"
          text="Odrzucone"
          color="danger"
          btnClass={classNames('w-50', { 'opacity-5': result !== 'rejected' })}
        />
      </div>
    </FormPlain>
  )
}
