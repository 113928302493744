import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { ReceptionBookingCheckInAddImprovementButton } from '@modules/reception/checkin/step-improvements/reception-booking-check-in-add-improvement-button'
import {
  calculateImprovementPrice,
  ImprovementActionPayload,
  ImprovementPriceCalculation,
} from '@store/actions/reception-actions'
import { useAddParkingImprovement } from '@modules/reception/checkin/step-improvements/use-add-improvement'
import { useApiRequest } from '@components/hooks/use-api-request'
import { ParkingKind } from '@models/booking'

interface Props {
  booking: ReceptionBookingDetails
  kind?: ParkingKind
}

export const StepGuestsCarsAddParking: React.FC<Props> = ({ booking, kind = 'parking2' }) => {
  const [improvementPrice, setImprovementPrice] = React.useState('')
  const { addParkingImprovement } = useAddParkingImprovement(booking)

  const payload: ImprovementActionPayload = {
    code: kind,
    amount: 1,
    date_from: booking.date_from,
    date_to: booking.date_to,
  }

  const calculateNewPrice = async () => {
    const data = flatImprovementPriceCalculation(
      (await calculateImprovementPrice(booking.urls.improvement_calculation, [payload])).items,
    )
    setImprovementPrice(data[0].price)
  }

  React.useEffect(() => {
    calculateNewPrice()
  }, [])

  const { isLoading, action: handleAddClick } = useApiRequest(async () => await addParkingImprovement(payload, kind))

  return (
    <ReceptionBookingCheckInAddImprovementButton
      disabled={false}
      label="Dodaj parking"
      improvementPrice={improvementPrice}
      isSaving={isLoading}
      onClick={handleAddClick}
    />
  )
}

const flatImprovementPriceCalculation = (items: ImprovementPriceCalculation[]): ImprovementPriceCalculation[] =>
  items.reduce<ImprovementPriceCalculation[]>((improvements, improvementCalculation) => {
    if (improvements.some(row => row.code === improvementCalculation.code)) {
      return improvements.map(row =>
        row.code === improvementCalculation.code ? { ...row, price: row.price + improvementCalculation.price } : row,
      )
    } else {
      return [...improvements, improvementCalculation]
    }
  }, [])
