import * as React from 'react'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApiRequest } from '@components/hooks/use-api-request'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { ButtonWithIcon } from '@components/button-with-icon'
import { BookingOption } from '@modules/promotions/booking-options/model'
import { BaseModalProps } from '@components/modals/types'
import { useDeleteBookingOptionMutation } from '@api/booking-options'

interface Props extends BaseModalProps {
  option: BookingOption
}

export const BookingOptionDetailsDelete: React.FC<Props> = ({ toggleIsVisible, option }) => {
  const { addSuccessMessage } = useNotificationHook()

  const [handleDelete] = useDeleteBookingOptionMutation()

  const { isLoading, action: handleRemove } = useApiRequest(async reason => {
    handleDelete({ url: option.urls.details, reason })
    addSuccessMessage('Sukces', 'Opcja została skasowana')
    toggleIsVisible()
  })

  return (
    <CommonObjectConfirmAction askForReason={true} isLoading={isLoading} handleAccept={handleRemove}>
      <ButtonWithIcon icon="uil-trash-alt" color="danger" text="Usuń" />
    </CommonObjectConfirmAction>
  )
}
