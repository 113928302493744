import * as React from 'react'
import Table from '@components/table/table'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { BenefitProgram } from '@modules/benefit-program/models'
import { TableSellerRow } from '@components/table-seller-row'
import { BenefitProgramTableRowTotal } from '@modules/benefit-program/list/table-row-total'
import { BenefitProgramStatusBadge } from '@modules/benefit-program/common/benefit-program-status-badge'
import { BenefitProgramAdvisor } from '@modules/benefit-program/common/benefit-program-advisor'

interface Props {
  benefitProgram: BenefitProgram
}

export const BenefitProgramTableRow: React.FC<Props> = ({ benefitProgram }) => {
  const navigate = useNavigate()
  const handleClick = React.useCallback(() => {
    const url = NavigationPath.BenefitProgramDetails.replace(':id', String(benefitProgram.id))
    navigate(url)
  }, [benefitProgram.id])

  return (
    <Table.Row className="cursor-pointer" onClick={handleClick}>
      <Table.Cell className="vertical-align-middle">{benefitProgram.number}</Table.Cell>
      <Table.Cell className="vertical-align-middle">{benefitProgram.name}</Table.Cell>
      <Table.Cell className="vertical-align-middle">
        <BenefitProgramStatusBadge benefitProgram={benefitProgram} />
      </Table.Cell>
      <Table.Cell className="vertical-align-middle">
        <strong className="d-block">
          {benefitProgram.active_apartment_agreements + benefitProgram.active_house_agreements}
        </strong>
        <span className="d-block font-10">Domek: {benefitProgram.active_house_agreements}</span>
        <span className="d-block font-10">Apartament: {benefitProgram.active_apartment_agreements}</span>
      </Table.Cell>
      <BenefitProgramTableRowTotal benefitProgram={benefitProgram} />
      <Table.Cell className="vertical-align-middle">
        <TableSellerRow sellerId={benefitProgram.seller_id} />
      </Table.Cell>
      <Table.Cell className="vertical-align-middle">
        <BenefitProgramAdvisor advisorId={benefitProgram.advisor_id} />
      </Table.Cell>
      <Table.Cell className="vertical-align-middle">
        <i className="uil-eye font-16 cursor-pointer" onClick={handleClick} />
      </Table.Cell>
    </Table.Row>
  )
}
