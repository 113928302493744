import { AsyncThunkParams } from '@store/index'
import { commonObjectGet } from '@store/actions/generic-actions'
import { setDashboardStandardPaginator } from '@store/actions/dashboard-actions'
import { ListDataWithAggregation, PaginationResponseWithAggregation } from '@models/dashboard'
import { Package, PackageAggregation, PackageDetails, PackageType } from '@models/package'
import { PackageListFilterParams } from '@modules/package/list/filter'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { PackageAppData, PackagesStats } from '@store/slices/package-slice'

type Stats = { stats: PackagesStats }

type PackageResponse = PaginationResponseWithAggregation<Package, PackageAggregation> & Stats
type PackageResponseData = ListDataWithAggregation<Package[], PackageAggregation> & Stats

export const getPackages = createAsyncThunk<
  PackageResponseData,
  [PackageListFilterParams, PackageType],
  AsyncThunkParams
>('packages/getPackages', async ([filters, type], { getState, dispatch }) => {
  const url = getState().appState.appData.urls.package.packages

  const data = await commonObjectGet<PackageResponse>(url, {
    ...filters,
    package_type: (type || '').toLocaleUpperCase(),
    status: filters.status?.map(row => row.value),
    seller: filters.seller?.value,
    kind: filters.kind?.map(row => row.value),
  })

  dispatch(setDashboardStandardPaginator(data))

  return { results: data.results, aggregation: data.aggregation, stats: data.stats }
})

export const getPackageAppData = createAsyncThunk<PackageAppData, void, AsyncThunkParams>(
  'packages/getPackageAppData',
  async (_, { getState }) => {
    if (getState().packageState.appData.status === 'unknown') {
      return await commonObjectGet<PackageAppData>(getState().appState.appData.urls.package.package_app_data)
    }
    return getState().packageState.appData
  },
)

export const getPackageDetails = createAsyncThunk<PackageDetails, string>(
  'packages/getPackageDetails',
  async url => await commonObjectGet<PackageDetails>(url),
)
