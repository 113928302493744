import * as React from 'react'
import { useAppDispatch, useAppSelector } from '@store/index'
import { setWebSocketData } from '@store/slices/websocket-slice'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { websocketChannelsSelector } from '@store/selectors/dashboard'

const protocol = document.location.protocol === 'http:' ? 'ws' : 'wss'
const port = document.location.port ? `:${document.location.port}` : ''

export const socketUrl = `${protocol}://${document.location.hostname}${port}/ws/dashboard/`

export const WebSocketHandler = (): null => {
  const dispatch = useAppDispatch()
  const user = useAuthenticatedUser()
  const channels = useAppSelector(websocketChannelsSelector)
  const [webSocket, setWebSocket] = React.useState<WebSocket | null>(null)

  React.useEffect(() => {
    if (user.isAuthenticated) {
      setWebSocket(connect())
    }

    return () => webSocket?.close()
  }, [user.isAuthenticated])

  React.useEffect(() => {
    if (webSocket && webSocket.readyState === 1) {
      webSocket.send(JSON.stringify({ payload: { channels }, event: 'join_channels' }))
    }
  }, [channels, webSocket?.readyState])

  const connect = () => {
    const webSocket = new WebSocket(socketUrl)

    webSocket.onmessage = (e: MessageEvent) => {
      const data = JSON.parse(e.data)
      if (data.event) {
        dispatch(setWebSocketData(data.event))
      }
    }

    webSocket.onclose = () => {
      setTimeout(connect, 3000)
    }

    return webSocket
  }

  return null
}
