import * as React from 'react'
import { ReservationStatsTableCells } from '@modules/reservations/models'

interface ReservationStatsTableExpandedRowProps {
  title: React.ReactNode
  cells: ReservationStatsTableCells
  children?: React.ReactElement
}

export const ReservationStatsTableExpandedRow: React.FC<ReservationStatsTableExpandedRowProps> = ({ title, cells }) => (
  <tr>
    <td className="font-12 align-middle pl-4 reservation-stats__col__first">{title}</td>
    {cells.data}
    {cells.summary.component}
  </tr>
)
