import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { ClientUser } from '@models/clients'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useModal } from '@components/modals/use-modal'
import { useForm, useWatch } from 'react-hook-form'
import { useFormRequest } from '@components/hooks/use-api-request'
import { Button, Col, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { ClientSelect } from '@components/client-select'
import { SaveButton } from '@hyper/button'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { PromocodeTouristVoucherCreateForm } from '@modules/promotions/promocode-tourist-voucher/create-dialog/form'
import { formatPrice } from '@helpers/utils'
import { useAppDispatch } from '@store/index'
import { createPromocodeTouristVoucher } from '@store/actions/promocode-tourist-voucher-actions'
import { Form } from '@hyper/forms/form'

export interface PromocodeTouristVoucherCreateFormInputs {
  seller: CustomReactSelectOption
  client: number | string | undefined
  source_marketing: CustomReactSelectOption
  tourist_voucher_amount: number
  tourist_voucher_payment_code: string
  tourist_voucher_code: string
  created_date: string
}

export const PromocodeTouristVoucherCreateDialog: React.FC<BaseModalProps> = ({ toggleIsVisible }) => {
  const [client, setClient] = React.useState<ClientUser | undefined>()

  const { addSuccessMessage } = useNotificationHook()
  const [handleToggleClientDialog] = useModal('ClientDialog', { setClient })

  const dispatch = useAppDispatch()

  const methods = useForm<PromocodeTouristVoucherCreateFormInputs>()

  const {
    clearErrors,
    setError,
    formState: { errors },
  } = methods

  React.useEffect(() => clearErrors('client'), [client])

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: PromocodeTouristVoucherCreateFormInputs) => {
    await dispatch(createPromocodeTouristVoucher({ ...payload, client: client?.id }))
    addSuccessMessage()
    toggleIsVisible()
  }, setError)

  const tourist_voucher_amount = useWatch({ control: methods.control, name: 'tourist_voucher_amount' })

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>Dodaj Bon Turystyczny</ModalHeader>

      <Form methods={methods} onSubmit={onSubmit}>
        <ModalBody className="pb-0">
          <Row>
            <ClientSelect
              setClient={setClient}
              client={client}
              handleToggleClientDialog={handleToggleClientDialog}
              error={errors.client}
            />
            <PromocodeTouristVoucherCreateForm />
            <Col className="text-right mb-2" md={12}>
              Zostanie utworzony voucher w kwocie <strong>{formatPrice(tourist_voucher_amount || 0)}</strong>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={toggleIsVisible}>
            Zamknij
          </Button>

          <SaveButton label="Zapisz" isSaving={isLoading} className="btn btn-green" />
        </ModalFooter>
      </Form>
    </>
  )
}
