import * as React from 'react'
import { ClientDetails } from '@models/clients'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { BookingOptionsTable } from '@modules/promotions/booking-options/table/booking-options-table'
import { useGetBookingOptionsQuery } from '@api/booking-options'
import { TypedQueryResult } from '@api/base'
import { BookingOption, BookingOptionsFiltersParams } from '@modules/promotions/booking-options/model'
import { ColAuto } from '@hyper/col-auto'
import { useModal } from '@components/modals/use-modal'

interface Props {
  client: ClientDetails
}
const emptyList = []
export const ClientDetailsModalClientOptionPurchases: React.FC<Props> = ({ client }) => {
  const defaultFilters: BookingOptionsFiltersParams = React.useMemo(
    () => ({
      ordering: '-invoice',
      client: client.id,
      page: 1,
      page_size: 999,
      search: '',
      status: [],
      seller: null,
      option_kinds: [],
    }),
    [client.id],
  )
  const [filters, setFilters] = React.useState<BookingOptionsFiltersParams>(defaultFilters)
  const { data = emptyList, isFetching } = useGetBookingOptionsQuery(filters) as TypedQueryResult<BookingOption[]>
  const [handeAddNewOption] = useModal('BookingOptionCreateDialog', { clientDetails: client })

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <Row className="mb-2">
            <ColAuto className="col-auto">
              <h4 className="mb-2 text-secondary">Opcje do rezerwacji</h4>
            </ColAuto>
            <ColAuto className="ml-auto col-auto">
              <button type="button" className="btn btn btn-outline-secondary" onClick={handeAddNewOption}>
                <i className="uil-plus mr-1" />
                Dodaj nowe opcje
              </button>
            </ColAuto>
          </Row>
          <BookingOptionsTable
            showPagination={false}
            hideFooter={true}
            hideClient={true}
            options={data}
            isLoading={isFetching}
            filters={filters}
            setFilters={setFilters}
          />
        </CardBody>
      </Card>
    </Col>
  )
}
