import * as React from 'react'
import { useForm } from 'react-hook-form'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { BaseModalProps } from '@components/modals/types'
import { useProductSaleConfirmationModal } from '@components/sale/use-product-sale-confirmation-modal'
import { Form } from '@hyper/forms/form'
import { useHandleRtkQueryError } from '@components/hooks/use-handle-rtk-error'
import { useCreateOrUpdateBenefitProgramMutation } from '@api/benefit-programs'
import { InvoiceData } from '@components/products/product-invoice-card'
import { BenefitProgramCreateFormBaseData } from '@modules/benefit-program/create/form-basedata'
import {
  ReceptionBookingCheckStep,
  ReceptionBookingCheckSteps,
  showStepError,
} from '@modules/reception/common/reception-booking-check-steps'
import { BenefitProgramCreateFormParams } from '@modules/benefit-program/create/form-params'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { BenefitProgramDetails } from '@modules/benefit-program/models'
import { BenefitProgramCreateFormSummary } from '@modules/benefit-program/create/form-summary'
import { CustomReactSelectOption } from '@components/custom-react-select'

interface BenefitProgramRepresentative {
  email: string
  name: string
  phone: string
}

export interface BenefitProgramCreateInputs extends InvoiceData {
  name: string
  invoice: boolean
  users: BenefitProgramRepresentative[]
  benefit_apartment_price_employer: string
  benefit_apartment_price_worker: string
  benefit_house_price_employer: string
  benefit_house_price_worker: string
  seller: CustomReactSelectOption | null
  advisor: CustomReactSelectOption | null
}

const steps: ReceptionBookingCheckStep[] = [
  {
    step: 1,
    description: 'Dane podstawowe',
    fields: [
      'name',
      'invoice_nip',
      'invoice_company',
      'invoice_street',
      'invoice_postcode',
      'invoice_city',
      'seller',
      'advisor',
      'users',
    ],
  },
  {
    step: 2,
    description: 'Parametry sprzedaży',
    fields: [
      'benefit_apartment_price_employer',
      'benefit_apartment_price_worker',
      'benefit_house_price_employer',
      'benefit_house_price_worker',
    ],
  },
  {
    step: 3,
    description: 'Podsumowanie',
  },
]

export const BenefitProgramCreateModal: React.FC<BaseModalProps> = ({ toggleIsVisible }) => {
  const [step, setStep] = React.useState(1)
  const navigate = useNavigate()

  const { showConfirmationPackageCreation } = useProductSaleConfirmationModal()

  const methods = useForm<BenefitProgramCreateInputs>({
    defaultValues: {
      invoice_type: 'company',
      invoice: true,
      users: [{ name: '', phone: '', email: '' }],
    },
  })

  const [createOrUpdateBenefitProgram, { error, isError, isLoading }] = useCreateOrUpdateBenefitProgramMutation()
  useHandleRtkQueryError(error, isError, methods.setError)

  React.useEffect(() => {
    if (error) showStepError(setStep, steps, error)
  }, [error, setStep, steps])

  const onSubmit = async (payload: BenefitProgramCreateInputs) => {
    const benefitProgram = (await createOrUpdateBenefitProgram({
      data: payload,
      method: 'POST',
    }).unwrap()) as BenefitProgramDetails

    showConfirmationPackageCreation(() => {
      navigate(NavigationPath.BenefitProgramDetails.replace(':id', String(benefitProgram.id)))
    })
    toggleIsVisible()
  }

  const handlePrevStep = () => setStep(curr => Math.max(1, curr - 1))
  const handleNextStep = () => setStep(curr => curr + 1)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Dodaj MyHolidayBenefit</ModalHeader>
      <ReceptionBookingCheckSteps step={step} steps={steps} />

      <ModalBody className="px-0">
        {step === 1 && <BenefitProgramCreateFormBaseData />}
        {step === 2 && <BenefitProgramCreateFormParams />}
        {step === 3 && <BenefitProgramCreateFormSummary />}
      </ModalBody>
      <ModalFooter className="d-flex">
        {step !== 1 && (
          <Button onClick={handlePrevStep} className="btn btn-light mr-auto">
            Wstecz
          </Button>
        )}
        {step === 3 ? (
          <SaveButton
            role="submit"
            labelSaving="Dodawanie..."
            label="Dodaj"
            className="btn btn-green"
            isSaving={isLoading}
          />
        ) : (
          <Button onClick={handleNextStep} className="btn btn-green" role="next-step">
            Następny krok
          </Button>
        )}
      </ModalFooter>
    </Form>
  )
}
