import * as React from 'react'
import { Button } from 'reactstrap/lib'
import { useFormContext, useWatch } from 'react-hook-form'
import { addDays, subDays } from 'date-fns'
import DatePickerComponent from '@components/date/date-picker'
import { dateWithShortTextMonth } from '@helpers/date-helper'

interface Props {
  name: string
}

export const UpsellBookingsDateFilter = ({ name }: Props): JSX.Element => {
  const { control, setValue } = useFormContext()
  const date = useWatch({ control, name }) as Date

  const showNextDay = () => {
    changeDate(addDays(date, 1))
  }

  const showPreviousDay = () => {
    changeDate(subDays(date, 1))
  }

  const changeDate = (date: Date) => {
    setValue(name, date)
  }

  return (
    <div className="d-flex mr-2">
      <Button color="light btn-tall mr-1" onClick={showPreviousDay}>
        <i className="uil-angle-left font-18 lh-0" />
      </Button>
      <DatePickerComponent
        dateFormat={dateWithShortTextMonth}
        date={date}
        onChange={changeDate}
        withClose
        inputClassName="border-0 fw-semi-bold"
        wrapperClassName="bg-light pb-0 mb-0 btn-tall rounded d-flex align-items-center without-min-width"
      />
      <Button color="light btn-tall ml-1" onClick={showNextDay}>
        <i className="uil-angle-right font-18 lh-0" />
      </Button>
    </div>
  )
}
