import * as React from 'react'
import { Button, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useForm, useWatch } from 'react-hook-form'
import { endOfMonth, startOfMonth, startOfToday } from 'date-fns'
import { createReportTask } from '@store/actions/report-tasks-actions'
import ModalBody from 'reactstrap/lib/ModalBody'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { SaveButton } from '@hyper/button'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { BaseModalProps } from '@components/modals/types'
import { FormSelect } from '@hyper/forms/form-select'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { Form } from '@hyper/forms/form'
import { useResortsAndAccommodationTypesOptions } from '@components/hooks/use-resorts-and-accommodation-types-options'

interface FormInputs {
  date_after: Date
  date_before: Date
  resort: CustomReactSelectOption | null
  accommodation_type: CustomReactSelectOption | null
}

export const ReportsBookingsStatisticsModal: React.FC<BaseModalProps> = ({ toggleIsVisible }) => {
  const dispatch = useAppDispatch()

  const { report_task_reservation_stats } = useAppSelector((state: RootState) => state.appState.appData.urls.reports)

  const methods = useForm<FormInputs>({
    defaultValues: {
      date_after: startOfMonth(startOfToday()),
      date_before: endOfMonth(startOfToday()),
      resort: null,
      accommodation_type: null,
    },
  })

  const { isLoading, action: onSubmit } = useReportFormRequest(async () => {
    methods.clearErrors()
    const payload: FormInputs = methods.getValues()

    const reportTask = await dispatch(
      createReportTask([
        report_task_reservation_stats,
        {
          ...payload,
          resort: payload.resort?.value,
          accommodation_type: payload.accommodation_type?.value,
        },
      ]),
    )
    toggleIsVisible()

    return reportTask
  }, methods.setError)

  const [selectedResort, selectedAccommodationType] = useWatch({
    control: methods.control,
    name: ['resort', 'accommodation_type'],
  })

  const { getAccommodationTypesOptions, getResortOptions } = useResortsAndAccommodationTypesOptions()
  const accommodationTypeOptions = getAccommodationTypesOptions(selectedResort?.value)
  const resortOptions = getResortOptions(null, selectedAccommodationType?.value)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Raport rezerwacje - statystyki</ModalHeader>
      <ModalBody>
        <Row>
          <DateFromToInput label="Data rozpoczęcia rezerwacji" startDateName="date_after" endDateName="date_before" />
          <FormSelect
            name="resort"
            options={resortOptions}
            label="Ośrodek"
            formPlainProps={{ colSize: 6 }}
            selectProps={{ isOptionDisabled: (option: CustomReactSelectOption) => !!option.disabled }}
          />
          <FormSelect
            name="accommodation_type"
            options={accommodationTypeOptions}
            label="Rodzaj zakwaterowania"
            formPlainProps={{ colSize: 6 }}
            selectProps={{ isOptionDisabled: (option: CustomReactSelectOption) => !!option.disabled }}
          />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton
          className="btn btn-green"
          label="Zleć raport"
          labelSaving="Zlecanie.."
          isSaving={isLoading}
          onClick={onSubmit}
        />
      </ModalFooter>
    </Form>
  )
}
