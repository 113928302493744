import * as React from 'react'
import { BenefitProgramDetails } from '@modules/benefit-program/models'
import { Card, CardBody } from 'reactstrap'
import { ProductCardTitle } from '@components/products/product-card-title'
import { SubscriptionDetailsInformationRow } from '@modules/subscription/details/information/row'
import { getBenefitProgramTotal } from '@modules/benefit-program/helpers'
import { BenefitProgramDetailsTermsPriceRow } from '@modules/benefit-program/details/terms/price-row'
import { BenefitProgramStatusBadge } from '@modules/benefit-program/common/benefit-program-status-badge'
import { useSellers } from '@components/hooks/use-grouped-sellers'
import { formatPriceShort } from '@helpers/utils'
import { BenefitProgramAdvisor } from '@modules/benefit-program/common/benefit-program-advisor'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { startOfToday } from 'date-fns'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  benefitProgram: BenefitProgramDetails
}

export const BenefitProgramDetailsTerms: React.FC<Props> = ({ benefitProgram }) => {
  const total = React.useMemo(() => getBenefitProgramTotal(benefitProgram), [benefitProgram])
  const sellers = useSellers()

  const soldBy = React.useMemo(() => {
    if (!benefitProgram.seller_id) {
      return ''
    }

    const seller = sellers.find(row => row.id === benefitProgram.seller_id)

    if (seller) {
      return `${seller.name} (${seller.department_display})`
    }

    return ''
  }, [sellers, benefitProgram.seller_id])

  return (
    <Card>
      <CardBody>
        <ProductCardTitle
          title={<IconWithText icon="uil-check font-20" text="Warunki umowy" />}
          isEditAllowed={false}
        />
        <table className="table table-sm border-radius">
          <tbody>
            <SubscriptionDetailsInformationRow name="Status umowy" borderless={true}>
              <BenefitProgramStatusBadge benefitProgram={benefitProgram} />
            </SubscriptionDetailsInformationRow>

            <BenefitProgramDetailsTermsPriceRow
              name="domek"
              workerPrice={benefitProgram.benefit_house_price_worker}
              employerPrice={benefitProgram.benefit_house_price_employer}
            />

            <BenefitProgramDetailsTermsPriceRow
              name="apartament"
              workerPrice={benefitProgram.benefit_apartment_price_worker}
              employerPrice={benefitProgram.benefit_apartment_price_employer}
            />
            <SubscriptionDetailsInformationRow name="Sprzedawca">{soldBy}</SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="Opiekun">
              <BenefitProgramAdvisor advisorId={benefitProgram.advisor_id} />
            </SubscriptionDetailsInformationRow>
          </tbody>
        </table>

        <div className="bg-grey rounded">
          <table className="table-sm mb-0 w-100 border-bottom">
            <tbody>
              <tr>
                <td className="text-semi-strong border-bottom pt-2">
                  Aktywnych benefitów:
                  <span className="text-normal font-11"> (stan na: {toDefaultDateFormat(startOfToday())})</span>
                </td>
                <td className="text-right border-bottom pt-2">
                  {benefitProgram.active_house_agreements + benefitProgram.active_apartment_agreements}
                </td>
              </tr>
              <tr>
                <td className="border-bottom">Benefit domek:</td>
                <td className="text-right border-bottom">{benefitProgram.active_house_agreements}</td>
              </tr>
              <tr>
                <td>Benefit apartament:</td>
                <td className="text-right">{benefitProgram.active_apartment_agreements}</td>
              </tr>
            </tbody>
          </table>
          <div className="bg-grey-dark rounded-bottom border-top">
            <table className="table-sm mb-0 w-100 text-secondary font-15">
              <tbody>
                <tr>
                  <td className="text-strong">Opłata miesięczna:</td>
                  <td className="text-right text-strong">{formatPriceShort(total.toString())}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}
