import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { ServiceBand } from '@modules/bands/service-bands/models'
import { Button, Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { useAppDispatch, useAppSelector } from '@store/index'
import { useApiRequest } from '@components/hooks/use-api-request'
import { serviceBandDetailsSelector } from '@store/slices/service-band-slice'
import { getServiceBand } from '@store/actions/service-band-actions'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { ServiceBandDetailsInfo } from '@modules/bands/service-bands/details-dialog/info'
import { useAppData } from '@components/hooks/use-app-data'
import { ServiceBandAssign } from '@modules/bands/service-bands/details-dialog/assign'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { isBefore, startOfToday } from 'date-fns'
import { parseISODate } from '@helpers/date-helper'

interface Props extends BaseModalProps {
  serviceBand: ServiceBand
}

export const ServiceBandDetailsDialog: React.FC<Props> = ({ toggleIsVisible, serviceBand }) => {
  const { voucher_scopes } = useAppData()
  const dispatch = useAppDispatch()
  const serviceBandDetails = useAppSelector(serviceBandDetailsSelector)
  const { isLoading, action: fetch } = useApiRequest(async () => await dispatch(getServiceBand(serviceBand)))

  const user = useAuthenticatedUser()
  const canUpdate = user.hasPerm(UserPermission.ServiceBandCanUpdate)
  React.useEffect(() => {
    fetch()
  }, [])

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>Opaska testowa {serviceBand.name}</ModalHeader>
      <ModalBody role="modal-body" className="modal-details">
        <LoaderPlaceholder content={!isLoading}>
          {serviceBandDetails && (
            <>
              <ServiceBandDetailsInfo serviceBandDetails={serviceBandDetails} scopes={voucher_scopes} />
              {canUpdate && !isBefore(parseISODate(serviceBandDetails.date_to) as Date, startOfToday()) && (
                <ServiceBandAssign serviceBand={serviceBand} />
              )}
            </>
          )}
        </LoaderPlaceholder>
      </ModalBody>
      <ModalFooter className="d-block">
        <Row>
          <Col md={6} className="text-right">
            <Button color="light" onClick={toggleIsVisible}>
              Zamknij
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </>
  )
}
