import * as React from 'react'
import { UpsellBookingsLayout } from '@modules/upselling/upsell-bookings-layout'
import { UpsellBookingDetailsReservationData } from '@modules/upselling/upsell-booking-details/reservation-data/reservation-data'
import { UpsellBookingsDetailsReservations } from '@modules/upselling/upsell-booking-details/reservations/upsell-bookings-details-reservations'
import { UpsellBookingsDetailsNotes } from '@modules/upselling/upsell-booking-details/upsell-bookings-details-notes'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { useLazyGetUpsellBookingDetailsQuery } from '@api/upSellBooking'
import { TypedLazyQueryResult } from '@api/base'
import { UpSellBookingDetails } from '@models/upselling'
import { ContentLoader } from '@components/content-loader'
import { UpsellBookingsDetailsProducts } from '@modules/upselling/upsell-booking-details/products/upsell-bookings-details-products'
import { UpsellBookingsDetailsPool } from '@modules/upselling/upsell-booking-details/pool/upsell-bookings-details-pool'

type Params = 'booking_id'

export const UpsellBookingDetailsView = (): JSX.Element => {
  const { booking_id } = useParams<Params>()
  const location = useLocation()
  const navigate = useNavigate()

  const productsSectionRef = React.useRef<HTMLDivElement>(null)

  const handleNavigationBack = () => {
    if (location.state?.type) {
      navigate(NavigationPath.UpSellBookingsWithParams.replaceParam(':type', location.state.type), {
        state: { date: details?.contact_required_date },
      })
      return
    }
    navigate(NavigationPath.UpSellBookings)
  }

  const [fetchDetails, { data: details, isLoading, isFetching }] =
    useLazyGetUpsellBookingDetailsQuery() as TypedLazyQueryResult<UpSellBookingDetails>

  const onSaleClick = () => {
    if (productsSectionRef.current) {
      productsSectionRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  React.useEffect(() => {
    if (booking_id) {
      fetchDetails(`/dashboard-api/upselling/upsell-bookings/${booking_id}/`)
    }
  }, [booking_id])

  return (
    <UpsellBookingsLayout onNavigationBack={handleNavigationBack}>
      <ContentLoader isLoading={isLoading} spinnerClassName="is-centered">
        {!!details && (
          <>
            <UpsellBookingDetailsReservationData upsellBooking={details} onSaleClick={onSaleClick} />
            <UpsellBookingsDetailsReservations bookings={details.client.bookings} />
            <UpsellBookingsDetailsProducts upsellBooking={details} ref={productsSectionRef} />
            <UpsellBookingsDetailsPool upsellBooking={details} isFetching={isFetching} />
            <UpsellBookingsDetailsNotes upsellBooking={details} />
          </>
        )}
      </ContentLoader>
    </UpsellBookingsLayout>
  )
}
