import * as React from 'react'
import { TechnicalOrder } from '@models/technical-orders'
import Table from '@components/table/table'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { IconWithText } from '@components/icon-with-text'
import { IconWithTooltip } from '@components/icon-with-tooltip'
import { useTechnicalOrdersAppData } from '@modules/technical-orders/hooks/use-technical-orders-app-data'

interface Props {
  technicalOrder: TechnicalOrder
  showCreatedAt?: boolean
  className?: string
  width?: number
}

export const TechnicalOrdersTodoRowNumber: React.FC<Props> = ({ technicalOrder, showCreatedAt, width = 165 }) => {
  const { technical_order_reject_reason } = useTechnicalOrdersAppData()

  const rejectReason = React.useMemo(
    () => technical_order_reject_reason.find(row => row.id === technicalOrder.technical_order_reject_reason_id),
    [technical_order_reject_reason, technicalOrder.technical_order_reject_reason_id],
  )

  return (
    <Table.Cell className="vertical-align-middle" style={{ width }}>
      <span className="d-block text-semi-strong">
        {technicalOrder.number}
        {technicalOrder.rejected_at && (
          <IconWithTooltip
            tooltipId={`rejected-tooltip-${technicalOrder.id}`}
            color="text-danger font-16"
            icon="uil-repeat"
            className="ml-1"
            tooltipMessage={
              <span className="font-10 text-muted">
                Zadanie skierowane przez{' '}
                <span className="text-white">
                  {technicalOrder.rejected_by?.first_name} {technicalOrder.rejected_by?.last_name}
                </span>{' '}
                w dniu <span className="text-white">{toDefaultDateTimeFormat(technicalOrder.rejected_at)}</span> do
                ponownego wykonania. Powód:
                <span className="text-white"> {rejectReason?.name}</span>
              </span>
            }
          />
        )}
      </span>
      {!!technicalOrder.priority && !showCreatedAt && (
        <div className="d-block">
          <IconWithText
            text="Priorytet"
            icon="uil-rocket"
            iconSize="font-16 text-danger"
            wrapperClassNames="text-danger text-semi-strong"
          />
        </div>
      )}
      {showCreatedAt && <span className="font-10">Dodano: {toDefaultDateTimeFormat(technicalOrder.created)}</span>}
    </Table.Cell>
  )
}
