import * as React from 'react'

export const useDidUpdateEffect = (func: (...args: any) => void, deps: any[]) => {
  const didMountRef = React.useRef(false)

  React.useEffect(() => {
    if (didMountRef.current) {
      func()
      return
    }
    didMountRef.current = true
  }, deps)
}
