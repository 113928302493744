import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import * as R from 'ramda'

import {
  ApplicationsAggregation,
  FaultOrderDetails,
  IssueOrderDetails,
} from '@modules/housekeeping/applications/models'
import {
  getApplicationFaults,
  getApplicationIssues,
  getApplicationsAggregation,
  getHousekeepingAppData,
  getHousekeepingBeddingOrders,
  getHousekeepingCleaningOrders,
  getHousekeepingCleaningOrdersAggregation,
  getHousekeepingCommonTask,
  getHousekeepingImprovementOrders,
  getHousekeepingUsers,
} from '@store/actions/housekeeping-actions'
import { RootState } from '@store/index'
import {
  BeddingOrder,
  CleaningOrder,
  CleaningOrdersAggregation,
  CommonTask,
  HousekeepingAppData,
  HousekeepingCompany,
  HousekeepingCleaningCompanyWorker,
  HousekeepingUser,
  ImprovementOrder,
} from '@modules/housekeeping/models'

interface HousekeepingState {
  applicationsAggregation: ApplicationsAggregation
  issueOrders: EntityState<IssueOrderDetails>
  housekeepingCompanies: EntityState<HousekeepingCompany>
  commonTasks: EntityState<CommonTask>
  users: EntityState<HousekeepingUser>
  faultOrders: EntityState<FaultOrderDetails>
  improvementOrders: EntityState<ImprovementOrder>
  beddingOrders: EntityState<BeddingOrder>
  cleaningOrders: EntityState<CleaningOrder>
  appData: HousekeepingAppData
  cleaningOrdersAggregation: CleaningOrdersAggregation
  cleaningWorkers: HousekeepingCleaningCompanyWorker[]
}

const cleaningCompaniesAdapter = createEntityAdapter<HousekeepingCompany>()
const commonTasksAdapter = createEntityAdapter<CommonTask>()
const issueOrdersAdapter = createEntityAdapter<IssueOrderDetails>()
const faultOrdersAdapter = createEntityAdapter<FaultOrderDetails>()
const improvementOrdersAdapter = createEntityAdapter<ImprovementOrder>()
const beddingOrdersAdapter = createEntityAdapter<BeddingOrder>()
const housekeepingUsersAdapter = createEntityAdapter<HousekeepingUser>()
const cleaningOrdersAdapter = createEntityAdapter<CleaningOrder>()

const initialState: HousekeepingState = {
  applicationsAggregation: {
    faults: 0,
    issues: 0,
  },
  housekeepingCompanies: cleaningCompaniesAdapter.getInitialState(),
  commonTasks: commonTasksAdapter.getInitialState(),
  issueOrders: issueOrdersAdapter.getInitialState(),
  faultOrders: faultOrdersAdapter.getInitialState(),
  improvementOrders: improvementOrdersAdapter.getInitialState(),
  beddingOrders: beddingOrdersAdapter.getInitialState(),
  cleaningOrders: cleaningOrdersAdapter.getInitialState(),
  users: housekeepingUsersAdapter.getInitialState(),
  appData: {
    housekeeping_users: [],
    housekeeping_companies: [],
    fault_order_executors: [],
    fault_order_status: [],
    improvements_codes: [],
    issue_order_status: [],
    resort: undefined,
    status: 'unknown',
  },
  cleaningWorkers: [],
  cleaningOrdersAggregation: {
    improvements: 0,
    accepted: 0,
    archive: 0,
    bedding: 0,
    cleaning: 0,
    ordered: 0,
    to_check: 0,
    to_order: 0,
  },
}

export const housekeepingSlice = createSlice({
  name: 'housekeeping',
  initialState,
  reducers: {
    updateHousekeepingIssueOrder(state, action: PayloadAction<IssueOrderDetails>) {
      issueOrdersAdapter.upsertOne(state.issueOrders, action.payload)
    },

    updateHousekeepingFaultOrder(state, action: PayloadAction<FaultOrderDetails>) {
      faultOrdersAdapter.upsertOne(state.faultOrders, action.payload)
    },
    updateHousekeepingImprovementOrderDetails(state, action: PayloadAction<ImprovementOrder>) {
      improvementOrdersAdapter.upsertOne(state.improvementOrders, action.payload)
    },
    updateHousekeepingBeddingOrderDetails(state, action: PayloadAction<BeddingOrder>) {
      beddingOrdersAdapter.upsertOne(state.beddingOrders, action.payload)
    },
    updateHousekeepingCommonTask(state, action: PayloadAction<CommonTask>) {
      commonTasksAdapter.upsertOne(state.commonTasks, action.payload)
    },
    setHouseKeepingAppDataStatus(state, action: PayloadAction<'unknown' | 'loading' | 'ready'>) {
      state.appData.status = action.payload
      if (action.payload === 'loading') {
        state.appData.resort = undefined
      }
    },
    updateHousekeepingCleaningOrderDetails(state, action: PayloadAction<CleaningOrder>) {
      cleaningOrdersAdapter.upsertOne(state.cleaningOrders, action.payload)
    },
    setHousekeepingCleaningOrdersAggregation(state, action: PayloadAction<CleaningOrdersAggregation>) {
      state.cleaningOrdersAggregation = action.payload
    },
    setHousekeepingCleaningOrders(state, action: PayloadAction<CleaningOrder[]>) {
      cleaningOrdersAdapter.setAll(state.cleaningOrders, action.payload)
    },
    updateHousekeepingUserDetails(state, action: PayloadAction<HousekeepingUser>) {
      housekeepingUsersAdapter.upsertOne(state.users, action.payload)
    },
  },
  extraReducers: builder => {
    builder.addCase(getHousekeepingAppData.fulfilled, (state, action) => {
      state.appData = { ...action.payload, status: 'ready' }
      state.cleaningWorkers = R.flatten(action.payload.housekeeping_companies.map(company => company.workers))
    })
    builder.addCase(getApplicationsAggregation.fulfilled, (state, action) => {
      state.applicationsAggregation = action.payload
    })
    builder.addCase(getHousekeepingUsers.fulfilled, (state, action) => {
      housekeepingUsersAdapter.setAll(state.users, action.payload[0])
      cleaningCompaniesAdapter.setAll(state.housekeepingCompanies, action.payload[1])
    })
    builder.addCase(getApplicationIssues.fulfilled, (state, action) => {
      issueOrdersAdapter.setAll(state.issueOrders, action.payload)
    })
    builder.addCase(getApplicationFaults.fulfilled, (state, action) => {
      faultOrdersAdapter.setAll(state.faultOrders, action.payload)
    })
    builder.addCase(getHousekeepingImprovementOrders.fulfilled, (state, action) => {
      const { orders, aggregation } = action.payload
      state.cleaningOrdersAggregation = aggregation
      improvementOrdersAdapter.setAll(state.improvementOrders, orders)
    })
    builder.addCase(getHousekeepingBeddingOrders.fulfilled, (state, action) => {
      const { orders, aggregation } = action.payload
      state.cleaningOrdersAggregation = aggregation
      beddingOrdersAdapter.setAll(state.beddingOrders, orders)
    })
    builder.addCase(getHousekeepingCleaningOrders.fulfilled, (state, action) => {
      const { orders, aggregation } = action.payload
      state.cleaningOrdersAggregation = aggregation
      cleaningOrdersAdapter.setAll(state.cleaningOrders, orders)
    })
    builder.addCase(getHousekeepingCommonTask.fulfilled, (state, action) => {
      const { orders, aggregation } = action.payload
      state.cleaningOrdersAggregation = aggregation
      commonTasksAdapter.setAll(state.commonTasks, orders)
    })
    builder.addCase(getHousekeepingCleaningOrdersAggregation.fulfilled, (state, action) => {
      state.cleaningOrdersAggregation = action.payload
    })
  },
})

export const {
  updateHousekeepingIssueOrder,
  updateHousekeepingFaultOrder,
  updateHousekeepingImprovementOrderDetails,
  updateHousekeepingBeddingOrderDetails,
  updateHousekeepingCommonTask,
  updateHousekeepingCleaningOrderDetails,
  setHousekeepingCleaningOrders,
  setHouseKeepingAppDataStatus,
  updateHousekeepingUserDetails,
} = housekeepingSlice.actions

export const { selectAll: issueOrdersSelector } = issueOrdersAdapter.getSelectors(
  (state: RootState) => state.houseKeepingState.issueOrders,
)
export const { selectAll: housekeepingUsersSelector } = housekeepingUsersAdapter.getSelectors(
  (state: RootState) => state.houseKeepingState.users,
)
export const { selectAll: faultOrdersSelector } = faultOrdersAdapter.getSelectors(
  (state: RootState) => state.houseKeepingState.faultOrders,
)
export const { selectAll: improvementOrdersSelector } = improvementOrdersAdapter.getSelectors(
  (state: RootState) => state.houseKeepingState.improvementOrders,
)
export const { selectAll: beddingOrdersSelector } = beddingOrdersAdapter.getSelectors(
  (state: RootState) => state.houseKeepingState.beddingOrders,
)
export const { selectAll: cleaningOrdersSelector } = cleaningOrdersAdapter.getSelectors(
  (state: RootState) => state.houseKeepingState.cleaningOrders,
)
export const { selectAll: commonTasksSelector } = commonTasksAdapter.getSelectors(
  (state: RootState) => state.houseKeepingState.commonTasks,
)
export const { selectAll: housekeepingCompaniesSelector } = cleaningCompaniesAdapter.getSelectors(
  (state: RootState) => state.houseKeepingState.housekeepingCompanies,
)
export default housekeepingSlice.reducer
