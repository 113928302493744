import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { ReservationDropdown } from '@modules/reservations/details/common/reservation-dropdown'
import { IconWithText } from '@components/icon-with-text'
import { CustomSwitch } from '@components/custom-switch'
import { useApiRequest } from '@components/hooks/use-api-request'
import classNames from 'classnames'
import { DropdownItem } from 'reactstrap'
import { useAppDispatch } from '@store/index'
import { setReceptionBookingDetails } from '@store/slices/reception-slice'
import { commonObjectPut } from '@store/actions/generic-actions'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { isBookingEditable } from '@modules/reservations/details/utils'

interface ReservationDataActionsProps {
  booking: ReceptionBookingDetails
}

export const ReservationNotificationsActions: React.FC<ReservationDataActionsProps> = ({ booking }) => {
  const dispatch = useAppDispatch()

  const updateBooking = async (payload: Partial<ReceptionBookingDetails> & { reason: string }) => {
    dispatch(setReceptionBookingDetails(await commonObjectPut(booking.urls.reservation_booking_details, payload)))
  }

  const { action: updateSmsNotificationMute, isLoading: isUpdatingSms } = useApiRequest(async (reason: string) => {
    await updateBooking({ sms_notifications_enabled: !booking.sms_notifications_enabled, reason })
  })

  const { action: updateEmailNotificationMute, isLoading: isUpdatingEmail } = useApiRequest(async (reason: string) => {
    await updateBooking({ email_notifications_enabled: !booking.email_notifications_enabled, reason })
  })

  const canEditBooking = isBookingEditable(booking)

  return (
    <ReservationDropdown controlled dropdownMenuProps={{ modifiers }}>
      <CommonObjectConfirmAction
        isLoading={isUpdatingSms}
        askForReason
        handleAccept={updateEmailNotificationMute}
        message="Czy na pewno chcesz wykonać wybraną akcję?"
        disabled={!canEditBooking}
      >
        <DropdownItem className="pl-2 py-1">
          <CustomSwitch
            inputName="email_notifications_send"
            labelClassName="is-small"
            value={booking.email_notifications_enabled}
            label={
              <IconWithText
                isLoading={isUpdatingEmail}
                icon={classNames(!booking.email_notifications_enabled ? 'uil-bell-slash' : 'uil-bell text-secondary')}
                text={`${booking.email_notifications_enabled ? 'Włączone' : 'Wyłączone'} powiadomienia e-mail`}
                textClass="font-11 font-weight-normal"
              />
            }
          />
        </DropdownItem>
      </CommonObjectConfirmAction>

      <CommonObjectConfirmAction
        isLoading={isUpdatingSms}
        askForReason
        handleAccept={updateSmsNotificationMute}
        message="Czy na pewno chcesz wykonać wybraną akcję?"
        disabled={!canEditBooking}
      >
        <DropdownItem className="pl-2 py-1">
          <CustomSwitch
            inputName="sms_notifications_send"
            labelClassName="is-small"
            value={booking.sms_notifications_enabled}
            label={
              <IconWithText
                isLoading={isUpdatingSms}
                icon={classNames(!booking.sms_notifications_enabled ? 'uil-bell-slash' : 'uil-bell text-secondary')}
                text={`${booking.sms_notifications_enabled ? 'Włączone' : 'Wyłączone'} powiadomienia SMS`}
                textClass="font-11 font-weight-normal"
              />
            }
          />
        </DropdownItem>
      </CommonObjectConfirmAction>
    </ReservationDropdown>
  )
}

const modifiers = {
  preventOverflow: {
    enabled: true,
    boundariesElement: 'viewport',
    padding: 50,
  },
} as const
