import * as React from 'react'
import { PageTitle } from '@hyper/page-title'
import { Card, CardBody } from 'reactstrap'
import { PromocodeGroupsTable } from '@modules/promocode/promocode-list/promocode-groups/promocode-groups-table'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { PromocodesTab, PromocodesTabs } from '@modules/promocode/promocode-list/promocodes-tabs'
import { PromocodeCodesTable } from '@modules/promocode/promocode-list/promocode-codes/promocode-codes-table'

export const PromoCodeListView: React.FC = () => {
  useMenuActive(NavigationPath.PromoCodePromoCodes)

  const [tab, setTab] = React.useState<PromocodesTab>('groups')

  return (
    <>
      <PageTitle
        breadCrumbItems={[{ label: 'Kody promocyjne', path: '', active: true }]}
        title={`${tab === 'groups' ? 'Grupy' : 'Lista'} kodów promocyjnych i voucherów`}
      />
      <Card>
        <CardBody>
          <PromocodesTabs tab={tab} onTabChange={setTab} />
          {tab === 'groups' && <PromocodeGroupsTable />}
          {tab === 'codes' && <PromocodeCodesTable />}
        </CardBody>
      </Card>
    </>
  )
}
