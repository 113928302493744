import * as React from 'react'
import { getById } from '@helpers/utils'
import { formatDate, parseISODate } from '@helpers/date-helper'
import declination from '@helpers/declination'
import { useAppData } from '@components/hooks/use-app-data'
import { ReceptionBookingDetails } from '@models/reception'
import { ReservationSidebarSummaryBoxDataRow } from '@modules/reservations/create/sidebar-summary-box/sidebar-summary-box-data-row'

interface Props {
  bookingDetails: ReceptionBookingDetails
  numberOfNights: number
}

export const ReservationSidebarSummaryBoxBaseData = ({ bookingDetails, numberOfNights }: Props): React.ReactNode => {
  const { accommodation_types, resorts } = useAppData()

  const selectedAccommodation = getById(accommodation_types, bookingDetails.accommodation_type_id)
  const selectedResort = getById(resorts, bookingDetails.resort_id)

  return (
    <div className="pt-2 px-2">
      <h4 className="mt-1 mb-2 font-15 fw-semi-bold text-dark">Podsumowanie pobytu:</h4>
      <ReservationSidebarSummaryBoxDataRow
        icon="uil-calender"
        title="Termin pobytu:"
        content={
          <>
            <span className="font-10">
              ({numberOfNights} {declination.night(numberOfNights)}){' '}
            </span>
            <span className="font-11">
              {formatDate(parseISODate(bookingDetails.date_from), 'dd.MM')} -{' '}
              {formatDate(bookingDetails.date_to, 'dd.MM')}
            </span>
          </>
        }
      />
      <ReservationSidebarSummaryBoxDataRow icon="uil-map-marker" title="Lokalizacja:" content={selectedResort?.name} />
      <ReservationSidebarSummaryBoxDataRow
        icon="uil-pathfinder-unite"
        title="Lokal:"
        content={`${selectedAccommodation?.name} (${bookingDetails.apartment.name})`}
      />
      <ReservationSidebarSummaryBoxDataRow
        icon="uil-user"
        title="Liczba Gości:"
        content={bookingDetails.guests.length}
      />
    </div>
  )
}
