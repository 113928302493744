import * as React from 'react'
import { TechnicalOrder } from '@models/technical-orders'
import Table from '@components/table/table'
import { useTechnicalOrderInfo } from '@modules/technical-orders/hooks/use-technical-order-info'

interface Props {
  technicalOrders: TechnicalOrder[]
  resortId: number
  Row: React.FC<any>
}

export const TechnicalOrderTableGrupped: React.FC<Props> = ({ technicalOrders, resortId, Row }) => {
  const grouppedTechnicalOrders: TechnicalOrder[][] = React.useMemo(
    () =>
      Object.values(
        technicalOrders.reduce((acc, technicalOrder) => {
          const key = technicalOrder.users.map(row => row.id).join(', ')
          if (!acc[key]) {
            acc[key] = []
          }
          acc[key].push(technicalOrder)
          return acc
        }, {}),
      ),
    [technicalOrders],
  )

  return grouppedTechnicalOrders.length ? (
    grouppedTechnicalOrders.map((technicalOrdersRow, groupIndex) =>
      technicalOrdersRow.map((technicalOrder, index) => (
        <Row
          showDragHandler={false}
          showUsers={false}
          key={technicalOrder.id}
          showResort={!resortId}
          technicalOrder={technicalOrder}
          rowClassName={groupIndex % 2 !== 0 ? 'bg-grey-light' : ''}
        >
          {!index && (
            <TechnicalOrderTableGruppedUsers technicalOrder={technicalOrder} rowSpan={technicalOrdersRow.length} />
          )}
        </Row>
      )),
    )
  ) : (
    <tr>
      <td className="text-center" colSpan={100}>
        Brak danych
      </td>
    </tr>
  )
}

interface TechnicalOrderTableGruppedUsersProps {
  technicalOrder: TechnicalOrder
  rowSpan: number
}

const TechnicalOrderTableGruppedUsers: React.FC<TechnicalOrderTableGruppedUsersProps> = ({
  rowSpan,
  technicalOrder,
}) => {
  const handleDoubleClick = React.useCallback((e: React.MouseEvent) => {
    e.stopPropagation()
  }, [])

  const { company } = useTechnicalOrderInfo(technicalOrder)
  const usersName = React.useMemo(
    () => technicalOrder.users.map(row => `${row.first_name} ${row.last_name}`).join(', '),
    [technicalOrder.users],
  )

  return (
    <Table.Cell rowSpan={rowSpan} className="cursor-default border-right" onDoubleClick={handleDoubleClick}>
      <div className="font-14 mb-1 text-strong text-dark">{usersName}</div>
      <span className="font-11 d-block mb-2">{rowSpan} zleconych</span>
      {!!company && <span className="d-block font-10">Firma: {company.name}</span>}
    </Table.Cell>
  )
}
