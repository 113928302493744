import * as React from 'react'
import Table, { TableHeaderValue } from '@components/table/table'
import { PromocodeTouristVoucher } from '@modules/promotions/promocode-tourist-voucher/models'
import { PromocodeTouristVoucherFiltersParams } from '@modules/promotions/promocode-tourist-voucher/filters'
import { PromocodeTouristVoucherTableRow } from '@modules/promotions/promocode-tourist-voucher/table-row'
import { useReportsAppData } from '@modules/reports/hooks/use-reports-app-data'

interface Props {
  isLoading: boolean
  filters: PromocodeTouristVoucherFiltersParams
  setFilters: (filters: PromocodeTouristVoucherFiltersParams) => void
  promocodeTouristVouchers: PromocodeTouristVoucher[]
}

const headerValues: TableHeaderValue<PromocodeTouristVoucher>[] = [
  {
    sortField: 'code',
    title: 'Kod',
  },
  {
    sortField: 'client__first_name',
    title: 'Imię i nazwisko',
  },
  {
    sortField: 'status',
    title: 'Status',
  },
  {
    sortField: 'tourist_voucher_amount',
    title: 'Kwota vouchera',
  },
  {
    sortField: 'source_marketing',
    title: 'Źródło marketingowe',
  },
  {
    sortField: 'created_date',
    title: 'Data utworzenia',
  },
  {
    sortField: 'seller__name',
    title: 'Sprzedawca',
  },
]

export const PromocodeTouristVoucherTable: React.FC<Props> = ({
  promocodeTouristVouchers,
  setFilters,
  filters,
  isLoading,
}) => {
  const { sources } = useReportsAppData()

  return (
    <Table
      className="table-hover w-100"
      headerValues={headerValues}
      isLoading={isLoading}
      filters={filters}
      setFilters={setFilters}
    >
      {promocodeTouristVouchers.map(promocodeTouristVoucher => (
        <PromocodeTouristVoucherTableRow
          key={promocodeTouristVoucher.id}
          sources={sources}
          promocodeTouristVoucher={promocodeTouristVoucher}
        />
      ))}
    </Table>
  )
}
