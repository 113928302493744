import * as React from 'react'

import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { getCafeteriaAppData } from '@store/actions/cafeterias-action'
import { CafeteriaAppData } from '@models/cafeteria'
import { setCafeteriaAppData } from '@store/slices/cafeteria-slice'

export const useCafeteriaAppData = (): CafeteriaAppData => {
  const appData = useAppSelector((state: RootState) => state.cafeteriaState.appData)
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (appData.status === 'unknown') {
      dispatch(getCafeteriaAppData())
      dispatch(setCafeteriaAppData({ ...appData, status: 'loading' }))
    }
  }, [])

  return appData
}
