import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProductsAppData, ProductsAppDataGastroProduct } from '@models/products'
import { getProductsAppData } from '@store/actions/products-actions'
import { RootState } from '@store/index'
import { UserPermission } from '@models/dashboard'
import { ProductsAppDataSubscriptionContractPlan } from '@modules/subscription-contract/models'
import { GenericVoucherStrategy } from '@modules/generic-vouchers/models'

interface ProductsState {
  appData: ProductsAppData
}
const emptyGenericVoucherStrategy: GenericVoucherStrategy = {
  email_notifications: [],
  kind: 'bocian',
  name: '',
}

const emptyGastroProduct: ProductsAppDataGastroProduct = {
  can_define_scopes: false,
  product_name: '',
  can_set_source_marketing: false,

  wallet_kind: 'fiscalization',
  allow_pay_by_cash: false,
  crud_permissions: {
    list_ecommerce: UserPermission.Dummy,
    notification: UserPermission.Dummy,
    can_change_declared_sell_price: UserPermission.Dummy,
    create: UserPermission.Dummy,
    destroy: UserPermission.Dummy,
    list: UserPermission.Dummy,
    status: UserPermission.Dummy,
    partial_update: UserPermission.Dummy,
    retrieve: UserPermission.Dummy,
    update: UserPermission.Dummy,
    cancel: UserPermission.Dummy,
  },
  minimal_value: '',
  assign_type: 'client',
  kind: 'gastro_card_boost',
  notifications: [],
}

const subscriptionContractPlans: ProductsAppDataSubscriptionContractPlan = {
  name: '',
  single_price_brutto: '0',
  is_active: true,
}

export const emptyProductsState: ProductsState = {
  appData: {
    gastro_card_reason_for_creation: [],
    client_option_purchase_mails: [],
    subscription_kind_versions: [],
    subscription_kind: [],
    gastro_card_tags: [],
    status: 'unknown',
    subscription_contract_plans: {
      plan_1_old: { ...subscriptionContractPlans },
      plan_1: { ...subscriptionContractPlans },
      plan_2: { ...subscriptionContractPlans },
      plan_3: { ...subscriptionContractPlans },
      plan_4: { ...subscriptionContractPlans },
      plan_5: { ...subscriptionContractPlans },
      plan_6: { ...subscriptionContractPlans },
      plan_7: { ...subscriptionContractPlans },
      plan_8: { ...subscriptionContractPlans },
    },
    generic_voucher_strategies: {
      bocian: { ...emptyGenericVoucherStrategy, kind: 'bocian' },
    },
    product_package_sets: [],
    gastro_products: {
      gastro_card_dynamic: {
        ...emptyGastroProduct,
        kind: 'gastro_card_dynamic',
      },
      gastro_card_compensation: {
        ...emptyGastroProduct,
        kind: 'gastro_card_compensation',
      },
      gastro_card: {
        ...emptyGastroProduct,
        kind: 'gastro_card',
      },
      gastro_card_boost: {
        ...emptyGastroProduct,
        kind: 'gastro_card_boost',
      },
      gastro_card_2024: {
        ...emptyGastroProduct,
        kind: 'gastro_card_2024',
      },
      gastro_card_2025: {
        ...emptyGastroProduct,
        kind: 'gastro_card_2025',
      },
      gastro_card_2026: {
        ...emptyGastroProduct,
        kind: 'gastro_card_2026',
      },
      gastro_card_2026_october: {
        ...emptyGastroProduct,
        kind: 'gastro_card_2026_october',
      },
      gastro_card_2026_november: {
        ...emptyGastroProduct,
        kind: 'gastro_card_2026_november',
      },
      gastro_card_2027: {
        ...emptyGastroProduct,
        kind: 'gastro_card_2027',
      },
      gastro_card_2024_wholesale: {
        ...emptyGastroProduct,
        kind: 'gastro_card_2024_wholesale',
      },
      gastro_card_2025_wholesale: {
        ...emptyGastroProduct,
        kind: 'gastro_card_2025_wholesale',
      },
      gastro_card_boost_wholesale: {
        ...emptyGastroProduct,
        kind: 'gastro_card_boost_wholesale',
      },
      gastro_coupon: {
        ...emptyGastroProduct,
        kind: 'gastro_coupon',
      },
    },
  },
}

const productsSlice = createSlice({
  name: 'products',
  initialState: emptyProductsState,
  reducers: {
    setProductsAppData(state, action: PayloadAction<ProductsAppData>) {
      state.appData = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getProductsAppData.fulfilled, (state, action) => {
      state.appData = { ...state.appData, ...action.payload, status: 'ready' }
    })
  },
})

export const { setProductsAppData } = productsSlice.actions
export const selectProductsAppData = (state: RootState): ProductsAppData => state.productsState.appData
export default productsSlice.reducer
