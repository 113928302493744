import * as React from 'react'
import { BandReader } from '@components/band-scanning/band-reader'
import { useModal } from '@components/modals/use-modal'

export const ReceptionBookingCheckInStepAccessBandsNotConfigured: React.FC = () => {
  const [handleDesktopClick] = useModal('AccountUserDesktopModal')

  return (
    <div className="d-flex align-items-center">
      <BandReader status="error" />
      <div className="ml-5">
        <strong className="fw-semi-bold font-15">Brak wybranego czytnika.</strong>
        <button className="d-block btn btn-tall btn-primary mt-2" onClick={handleDesktopClick}>
          Przejdź do konfiguracji
        </button>
      </div>
    </div>
  )
}
