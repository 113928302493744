import * as React from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { BandScanningConfiguration } from '@components/band-scanning/configuration/band-scanning-configuration'
import { BandScanningGuestsList } from '@components/band-scanning/guests-list/band-scanning-guests-list'
import { BandScanningActions } from '@components/band-scanning/band-scanning-actions'
import { Col, Row } from 'reactstrap'
import { BandReader } from '@components/band-scanning/band-reader'
import { useBandScanner } from '@components/band-scanning/use-band-scanner'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { ReceptionBand, ReceptionBookingDetails } from '@models/reception'

const TOP_SCANNER_OFFSET = 85
const isBandReleased = (band: ReceptionBand) => band.state === 'RELEASED'

export interface BandScannerFormInputs {
  hour_from: string
  reader: number | null
  bands: { [key: string]: boolean }
}

interface Props {
  booking: ReceptionBookingDetails
}

export const BandScanner = ({ booking }: Props): JSX.Element => {
  const [topOffset, setTopOffset] = React.useState<number>(0)

  const user = useAuthenticatedUser()

  const scannerContainerRef = React.useRef<HTMLDivElement>(null)

  const methods = useForm<BandScannerFormInputs>({
    defaultValues: {
      hour_from: booking.arrival_time,
      reader: user.bandReaderId,
      bands: booking.bands.reduce((prev, band) => ({ ...prev, [`band__${band.id}`]: isBandReleased(band) }), {}),
    },
  })

  const {
    startScanning,
    refreshBandScanQueue,
    cancelScanning,
    globalScanningStatus,
    bandsToScanQueue,
    getBandStatus,
    nextScanDate,
  } = useBandScanner()

  const [bands, hourFrom, reader] = useWatch({ control: methods.control, name: ['bands', 'hour_from', 'reader'] })
  const isScanInProgress = globalScanningStatus === 'scanning'

  const handleScanningBandRefChange = (ref: HTMLDivElement) => {
    if (ref && scannerContainerRef.current) {
      const bodyRect = scannerContainerRef.current.getBoundingClientRect(),
        elemRect = ref.getBoundingClientRect(),
        offset = elemRect.top - bodyRect.top

      setTopOffset(offset)
    }
  }

  const selectedBands = Object.entries(bands).reduce((selected, [formName, isSelected]) => {
    if (!isSelected) return selected

    const [, bandId] = formName.split('__')
    return [...selected, parseInt(bandId)]
  }, [])

  const handleScan = () => {
    if (!reader) return
    const bandsToScan = selectedBands.filter(
      bandId => !booking.bands.find(bookingBand => bookingBand.id === bandId && isBandReleased(bookingBand)),
    )

    startScanning(bandsToScan, hourFrom, reader, { refresh: false })
  }

  const canRescan = () => {
    const bookingScannedBands = booking.bands.filter(isBandReleased)
    if (isScanInProgress) return false

    return !!bookingScannedBands.length || !!bandsToScanQueue.length
  }

  const hasBandToScan = selectedBands.some(bandId => {
    const isBandReleased = booking.bands.find(bookingBand => bookingBand.id === bandId)?.state === 'RELEASED'
    if (isBandReleased) return false

    const bandToScanInQueue = bandsToScanQueue.find(bandToScan => bandToScan.bandId === bandId)
    if (!bandToScanInQueue || !bandToScanInQueue.scanResult) return true
    return bandToScanInQueue.scanResult?.result !== 'SUCCESS'
  })

  const handleRescan = (bandId: number) => {
    if (!reader) return
    startScanning([bandId], hourFrom, reader, { refresh: false })
  }

  return (
    <FormProvider {...methods}>
      <Row>
        <Col md={8} className="ml-auto">
          <BandScanningConfiguration isEditDisabled={isScanInProgress || !!nextScanDate} booking={booking} />
        </Col>
      </Row>
      <div className="mb-3 position-relative" ref={scannerContainerRef}>
        <Col md={4} className="d-flex justify-content-center">
          <div className="band__reader__wrapper" style={{ top: Math.max(topOffset - TOP_SCANNER_OFFSET, 0) }}>
            <BandReader status={globalScanningStatus} />
          </div>
        </Col>
        <Col md={8} className="ml-auto pr-0">
          <BandScanningGuestsList
            ref={handleScanningBandRefChange}
            bookingDetails={booking}
            bandsToScanQueue={bandsToScanQueue}
            getBandStatus={getBandStatus}
            nextScanDate={nextScanDate}
            onRescan={handleRescan}
          />
          <BandScanningActions
            bookingDetails={booking}
            globalScanningStatus={globalScanningStatus}
            isScanning={isScanInProgress}
            onScan={handleScan}
            onRescan={refreshBandScanQueue}
            onCancel={cancelScanning}
            canRescan={canRescan()}
            nextScanDate={nextScanDate}
            hasBandToScan={hasBandToScan}
          />
        </Col>
      </div>
    </FormProvider>
  )
}
