import * as React from 'react'
import { TechnicalOrderDetails } from '@models/technical-orders'
import { TechnicalOrderPreviewModalRow } from '@modules/technical-orders/technical-order-preview-modal/row'
import { HistoryBox } from '@modules/promotions/common/history-box'

interface Props {
  technicalOrderDetails: TechnicalOrderDetails
}

export const TechnicalOrderPreviewModalHistory: React.FC<Props> = ({ technicalOrderDetails }) => (
  <TechnicalOrderPreviewModalRow label="Historia zmian">
    <HistoryBox history={technicalOrderDetails.history} asCard={false} />
  </TechnicalOrderPreviewModalRow>
)
