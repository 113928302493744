import { HousekeepingCleaningCompanyWorker } from '@modules/housekeeping/models'

export type HousekeepingWorkerRoles = 'handyman' | 'gardener' | 'bedding'

const getHousekeepingWorkerRoles = (user: HousekeepingCleaningCompanyWorker) =>
  [
    ...(user.is_housekeeping_bedding ? ['bedding'] : []),
    ...(user.is_housekeeping_gardener ? ['gardener'] : []),
    ...(user.is_housekeeping_handyman ? ['handyman'] : []),
  ] as HousekeepingWorkerRoles[]

export const hasHousekeepingWorkerRoles = (
  worker: HousekeepingCleaningCompanyWorker,
  roles: HousekeepingWorkerRoles[],
) => getHousekeepingWorkerRoles(worker).some(role => roles.includes(role))
