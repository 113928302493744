import * as React from 'react'
import { Input } from 'reactstrap'
import { useFormContext } from 'react-hook-form'
import { extractInnerRef } from '@helpers/forms'
import { InputProps } from 'reactstrap/es/Input'
import classnames from 'classnames'
import { asDecimal, getFloatValue, getIntValue, isInt } from '@helpers/utils'

interface Props {
  inputName: string
  inputProps?: InputProps
}

export const SpinInput: React.FC<Props> = ({ inputName, inputProps }) => {
  const step = inputProps?.step ? inputProps.step : 1
  const valueProvider = isInt(step) ? getIntValue : getFloatValue

  const { watch, setValue, register } = useFormContext()

  const amount = watch(inputName)

  const handleIncrement = () => {
    handleChange(asDecimal(valueProvider(amount)).plus(step).toString())
  }

  const handleDecrement = () => {
    handleChange(asDecimal(valueProvider(amount)).minus(step).toString())
  }

  const handleChange = (value: string | number) => {
    const valueToSet = valueProvider(value)

    if (inputProps?.max !== undefined && asDecimal(valueToSet).gt(valueProvider(inputProps?.max))) {
      setValue(inputName, inputProps?.max)
      return
    }

    if (inputProps?.min !== undefined && asDecimal(valueToSet).lt(valueProvider(inputProps?.min))) {
      setValue(inputName, inputProps?.min)
      return
    }

    setValue(inputName, valueToSet)
  }

  return (
    <div className="spin-input__wrapper">
      <div className={classnames('spin-input__actions', { 'not-clickable': inputProps?.disabled })}>
        <div className="spin-input__actions__increment" role={`${inputName}-plus`} onClick={handleIncrement}>
          <button className="spin-input__increment" type="button" />
        </div>
        <div className="spin-input__actions__decrement" role={`${inputName}-minus`} onClick={handleDecrement}>
          <button className="spin-input__decrement" type="button" />
        </div>
      </div>
      <Input
        type="number"
        autoComplete="off"
        step={step}
        {...extractInnerRef(
          register(inputName, {
            valueAsNumber: true,
            onBlur: event => handleChange(event.target.value),
          }),
        )}
        value={amount}
        {...inputProps}
      />
    </div>
  )
}
