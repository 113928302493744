import * as React from 'react'
import { Apartment } from '@models/apartment'
import classNames from 'classnames'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { useModal } from '@components/modals/use-modal'
import { getApartmentHousekeepingStatusColor } from '@modules/reception/helpers'

interface ReceptionApartmentHousekeepingStatusProps {
  apartment: Apartment
  onStatusUpdate?: () => void
}

export const ReceptionApartmentHousekeepingStatus: React.FC<ReceptionApartmentHousekeepingStatusProps> = ({
  apartment,
  onStatusUpdate,
}) => {
  const badgeClass = getApartmentHousekeepingStatusColor(apartment?.housekeeping_status)

  const canChangeStatus = useAuthenticatedUser().hasPerm(UserPermission.HousekeepingCanChangeApartmentStatus)

  const [toggleIsOpen] = useModal('ApartmentHousekeepingStatusDialog', {
    apartment,
    onStatusUpdate,
  })

  return (
    <span
      onClick={canChangeStatus ? toggleIsOpen : undefined}
      className={classNames('badge', badgeClass, {
        'cursor-pointer': canChangeStatus,
      })}
    >
      <i className="mdi mdi-checkbox-blank-circle text-white" /> {apartment?.housekeeping_status_display}
    </span>
  )
}
