import * as React from 'react'
import { Row } from 'reactstrap'
import { PackageSummarySection, PackageSummarySectionDataRow } from '@modules/package/create/package-summary-section'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { useFormContext, useWatch } from 'react-hook-form'
import { asDecimal, formatPercentage, formatPrice } from '@helpers/utils'
import { ProductSaleSummaryInvoice } from '@components/sale/product-sale-summary-invoice'
import { GastroProductInfo, ProductsAppDataGastroProduct } from '@models/products'
import { GastroGenericProductDialogFormInputs } from '@modules/promotions/gastro-generic-product/dialog/gastro-generic-product-dialog'
import { getGastroGenericProductTag } from '@modules/promotions/gastro-generic-product/utils'
import { useProductsAppData } from '@modules/products/hooks/use-products-app-data'

interface Props {
  gastroProduct: ProductsAppDataGastroProduct
  gastroProductInfo: GastroProductInfo
}

export const GastroGenericProductDialogSummaryStep = ({ gastroProduct, gastroProductInfo }: Props): JSX.Element => {
  const { control } = useFormContext<GastroGenericProductDialogFormInputs>()

  const { gastro_card_tags } = useProductsAppData()

  const data = useWatch({ control })

  const amountToSpend = asDecimal(data.declared_sell_price).mul(gastroProductInfo.promotion_multiplier).toString()

  const isClientAssignProductType = gastroProduct.assign_type === 'client'

  const clientData = isClientAssignProductType ? data.client : data.booking

  const percentageBonus = asDecimal(gastroProductInfo.promotion_multiplier).minus(1).mul(100).toString()

  return (
    <div className="pb-2">
      <h4 className="mt-0 text-secondary">Podsumowanie sprzedaży</h4>
      <Row>
        <PackageSummarySection title="Dane klienta" className={data.invoice_nip ? 'col-6' : 'col-8'}>
          {!isClientAssignProductType && (
            <PackageSummarySectionDataRow title="Numer rezerwacji:" content={data.booking?.reservation_number} />
          )}

          <PackageSummarySectionDataRow title="Imię i nazwisko:" content={clientData?.name} />
          <PackageSummarySectionDataRow title="Adres e-mail:" content={clientData?.email} />
          <PackageSummarySectionDataRow title="Numer telefonu:" content={clientData?.phone} />
        </PackageSummarySection>
        {data.invoice_nip && <ProductSaleSummaryInvoice />}
      </Row>

      <div className="dropdown-divider" />
      <Row>
        <PackageSummarySection title="Informacje o voucherze" className="col-8">
          {!!gastroProductInfo.tags.length && (
            <PackageSummarySectionDataRow
              title="Typ produktu:"
              content={gastroProductInfo.tags
                .map(tag => getGastroGenericProductTag(tag, gastro_card_tags)?.name)
                .join(', ')}
            />
          )}
          <PackageSummarySectionDataRow
            title="Aktywny od dnia:"
            content={toDefaultDateFormat(gastroProductInfo.active_after)}
          />
          <PackageSummarySectionDataRow
            title="Wygasa w dniu:"
            content={toDefaultDateFormat(gastroProductInfo.expire_after)}
          />
          <PackageSummarySectionDataRow
            title="Kwota zakupu:"
            content={
              <>
                {formatPrice(asDecimal(data.declared_sell_price).toString())}{' '}
                <span className="font-weight-normal font-12">
                  - do wykorzystania <span className="font-weight-semibold">{formatPrice(amountToSpend)}</span> (bonus{' '}
                  <span className="font-weight-semibold">{formatPercentage(percentageBonus)}</span>)
                </span>
              </>
            }
          />
          {data.reason_for_creation && (
            <PackageSummarySectionDataRow title="Powód utworzenia vouchera:" content={data.reason_for_creation.label} />
          )}
          <PackageSummarySectionDataRow title="Data utworzenia:" content={toDefaultDateFormat(data.sell_date)} />

          {data.auto_cancel_after_date && (
            <PackageSummarySectionDataRow
              title="Auto anulowanie po:"
              content={toDefaultDateFormat(data.auto_cancel_after_date)}
            />
          )}
        </PackageSummarySection>
      </Row>

      {gastroProduct.wallet_kind === 'fiscalization' && (
        <>
          <div className="dropdown-divider" />
          <Row>
            <PackageSummarySection title="Informacje handlowe" className="col-12">
              <Row>
                <PackageSummarySectionDataRow title="Sprzedawca:" content={data.seller?.label} className="col-6" />
              </Row>
            </PackageSummarySection>
          </Row>
          <div className="dropdown-divider" />
          <Row>
            <PackageSummarySection title="Link do płatności" className="col-12">
              <p className="opacity-75">
                Link do opłacenia sprzedaży zostanie wysłany na adres e-mail Gościa{' '}
                <strong className="text-dark">{data.booking?.email}</strong>
              </p>
            </PackageSummarySection>
          </Row>
        </>
      )}
    </div>
  )
}
