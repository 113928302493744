import * as React from 'react'
import Table from '@components/table/table'
import { ReceptionBooking } from '@models/reception'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { useAppData } from '@components/hooks/use-app-data'
import { ReceptionToCheckInRowImprovements } from '@modules/reception/to-checkin/row/reception-to-check-in-row-improvements'
import { formatPrice } from '@helpers/utils'
import { useModal } from '@components/modals/use-modal'
import { useUpsellBookingDetailsRefresh } from '@modules/upselling/upsell-booking-details/use-upsellbooking-details-refresh'

interface Props {
  booking: ReceptionBooking
}

export const UpsellBookingsDetailsReservationRow = ({ booking }: Props): JSX.Element => {
  const { resorts } = useAppData()
  const resort = resorts.find(resort => resort.id === booking.resort_id)

  const { refresh } = useUpsellBookingDetailsRefresh()

  const [showDetailsModal] = useModal(
    'ReceptionBookingDetailsModal',
    { booking, tabs: ['data', 'improvements', 'feedingNew'] },
    { onClose: refresh },
  )

  return (
    <Table.Row>
      <Table.Cell>
        {toDefaultDateFormat(booking.date_from)} - {toDefaultDateFormat(booking.date_to)}
      </Table.Cell>
      <Table.Cell>{resort?.name ?? ''}</Table.Cell>
      <Table.Cell>
        <ReceptionToCheckInRowImprovements booking={booking} showLabel={false} />
      </Table.Cell>
      <Table.Cell>{formatPrice(booking.final_brutto)}</Table.Cell>
      <Table.Cell className="text-right">
        <i className="font-18 mr-2 uil-eye" onClick={showDetailsModal} />
      </Table.Cell>
    </Table.Row>
  )
}
