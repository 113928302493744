import * as React from 'react'
import { FormInput, FormPlain } from '@hyper/forms'
import { Button, Col, Row } from 'reactstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { ProductPos } from '@modules/reception/pos/models'
import * as R from 'ramda'

interface ReceptionPosProductProps {
  product: ProductPos
  index: number
  handleProductRemove: (index: number) => void
  handleProductChange: (product: ProductPos, index: number) => void
}

interface FormInputs {
  name: string
  quantity: number
  tax: number
  price_brutto: number
}

export const ReceptionPosProduct: React.FC<ReceptionPosProductProps> = ({
  product,
  handleProductChange,
  index,
  handleProductRemove,
}) => {
  const methods = useForm<FormInputs>({
    defaultValues: product,
  })

  const changes = methods.watch()

  React.useEffect(() => {
    if (!R.equals(changes, product)) {
      handleProductChange(changes, index)
    }
  }, [changes])

  const handleRemove = () => handleProductRemove(index)

  return (
    <Row>
      <FormProvider {...methods}>
        <FormInput colSize={3} name="name" label="Nazwa produktu" />
        <FormInput colSize={3} name="quantity" label="Ilość sztuk" />
        <FormInput colSize={2} name="tax" label="Podatek" />
        <FormInput colSize={3} name="price_brutto" label="Cena brutto" />
        <Col md={1} className="text-right">
          <FormPlain name="irrelevant">
            <label className="d-block">&nbsp;</label>
            <Button type="button" color="danger" onClick={handleRemove}>
              <i className="uil uil-trash-alt cursor-pointer" title="Skasuj" />
            </Button>
          </FormPlain>
        </Col>
      </FormProvider>
    </Row>
  )
}
