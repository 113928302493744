import * as React from 'react'
import { Button, ModalBody, ModalFooter, Row } from 'reactstrap'
import { CmsResortDetails } from '@modules/cms/models'
import { BaseModalProps } from '@components/modals/types'
import { FormProvider, useForm } from 'react-hook-form'
import { CmsReservationAppResortFormMapSvg } from '@modules/cms/reservation-app/resort-modal/form-map-svg'
import { SaveButton } from '@hyper/button'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useFormRequest } from '@components/hooks/use-api-request'
import { updateCmsResortMap } from '@api/cms'
import { setCmsResort } from '@store/slices/cms-reservation-app-slice'
import { useAppDispatch } from '@store/index'
import { CmsReservationAppResortMapsSectors } from '@modules/cms/reservation-app/resort-modal/maps/sectors'

interface Props extends BaseModalProps {
  resort: CmsResortDetails
}

export const CmsReservationAppResortMaps: React.FC<Props> = ({ resort, toggleIsVisible }) => {
  const methods = useForm()
  const { addSuccessNotification } = useNotificationHook()
  const [resortMap, setResortMap] = React.useState<File>()
  const dispatch = useAppDispatch()

  const { isLoading, action: onSubmit } = useFormRequest(async () => {
    dispatch(setCmsResort(await updateCmsResortMap(resort.urls.cms_details, resortMap)))
    addSuccessNotification('Zmiany zostały zapisane')
  }, methods.setError)

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalBody>
          <Row>
            <CmsReservationAppResortFormMapSvg
              resort={resort}
              resortMap={resortMap}
              setResortMap={setResortMap}
              isLoading={isLoading}
            />
            <CmsReservationAppResortMapsSectors resort={resort} />
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="light" type="button" onClick={toggleIsVisible}>
            Zamknij
          </Button>
          <SaveButton className="btn btn-green" isSaving={isLoading} />
        </ModalFooter>
      </form>
    </FormProvider>
  )
}
