import * as React from 'react'
import { formatPrice } from '@helpers/utils'
import classNames from 'classnames'
import { ReceptionBookingCartItemPaidIcon } from '@modules/reception/cart/cart-items/reception-booking-cart-item-paid-icon'
import { ReceptionBookingDetailsCartPayment } from '@models/reception'
import { toTextDateFormat } from '@helpers/date-helper'

interface Props {
  wrapperClassName?: string
  payment: ReceptionBookingDetailsCartPayment
}

export const ReceptionBookingCartPaymentItem = ({ payment }: Props): JSX.Element => (
  <li className={classNames('row border-top mx-0 align-items-center reception-booking-cart__selectable')}>
    <div className="col-10 pl-5 reception-booking-cart__expandable__item is-nested py-1">
      <ReceptionBookingCartItemPaidIcon>
        <span className="fw-semi-bold font-12">
          {payment.description ? `${payment.source_display} - ${payment.description}, ` : `${payment.source_display}, `}
          {toTextDateFormat(payment.created_at)}
        </span>
      </ReceptionBookingCartItemPaidIcon>
    </div>
    <div className="col-2 text-success font-12">{formatPrice(payment.amount)}</div>
  </li>
)
