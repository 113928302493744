import * as React from 'react'
import { TransferPackage, TransferPackageDetails } from '@modules/accounting/refund/models'
import { Button, Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { commonObjectGet } from '@store/actions/generic-actions'
import { TransferPackagePreviewModalTable } from '@modules/accounting/refund/transfer-packages/preview/table'
import { TransferPackagePreviewModalSummary } from '@modules/accounting/refund/transfer-packages/preview/summary'
import { RootState, useAppSelector } from '@store/index'
import { HistoryBox } from '@modules/promotions/common/history-box'
import { BaseModalProps } from '@components/modals/types'
import { ContentNotesCard } from '@components/content-notes/content-notes-card'

interface TransferPackagePreviewModalProps extends BaseModalProps {
  transferPackage: TransferPackage
}

export const TransferPackagePreviewModal: React.FC<TransferPackagePreviewModalProps> = ({
  toggleIsVisible,
  transferPackage,
}) => {
  const [transferPackageDetails, setTransferPackageDetails] = React.useState<TransferPackageDetails | undefined>(
    undefined,
  )
  const contentType = useAppSelector((state: RootState) => state.appState.appData.content_types.transferpackage)

  const fetchTransferPackagesDetails = async () =>
    setTransferPackageDetails(await commonObjectGet<TransferPackageDetails>(transferPackage.urls.details))

  React.useEffect(() => {
    fetchTransferPackagesDetails()
  }, [])

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>
        <span className="text-semi-strong">Szczegóły paczki</span> #<strong>{transferPackage.id}</strong>
      </ModalHeader>
      <ModalBody>
        <LoaderPlaceholder content={transferPackageDetails}>
          {transferPackageDetails && (
            <Row>
              <Col md={4}>
                <TransferPackagePreviewModalSummary transferPackage={transferPackageDetails} />
                <HistoryBox history={transferPackageDetails.history} />
              </Col>
              <Col md={8}>
                <ContentNotesCard
                  objectId={transferPackageDetails.id}
                  contentType={contentType}
                  notes={transferPackageDetails.notes}
                  onNotesUpdate={fetchTransferPackagesDetails}
                  readOnly={false}
                />
              </Col>
              <TransferPackagePreviewModalTable transferPackage={transferPackageDetails} />
            </Row>
          )}
        </LoaderPlaceholder>
      </ModalBody>
      <ModalFooter>
        <Button color="green" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
      </ModalFooter>
    </>
  )
}
