import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { ReceptionBookingDetails } from '@models/reception'
import { ReservationDataRow } from '@modules/reservations/details/common/reservation-data-row'
import { toDefaultDateFormat, toDefaultTimeFormat } from '@helpers/date-helper'
import { useSellers } from '@components/hooks/use-grouped-sellers'
import { ReservationDataTags } from '@modules/reservations/details/base-data/booking/reservation-data-tags'
import { ReservationDataImprovements } from '@modules/reservations/details/base-data/booking/reservation-data-improvements'
import { ReservationDataCheckInDocuments } from '@modules/reservations/details/base-data/booking/reservation-data-check-in-documents'
import { ReservationDataAutoCancel } from '@modules/reservations/details/base-data/booking/reservation-data-auto-cancel'
import { ReservationDataWarranty } from '@modules/reservations/details/base-data/booking/reservation-data-warranty'
import { ReservationType } from '@modules/reservations/details/reservation-data/reservation-type'
import { ReservationDataSummaryUrl } from '@modules/reservations/details/base-data/booking/reservation-data-summary-url'
import { TableUserRow } from '@components/table-user-row'

interface Props {
  booking: ReceptionBookingDetails
}

export const ReservationDataExpandedContent = ({ booking }: Props): JSX.Element => {
  const sellers = useSellers()

  const seller = sellers.find(seller => seller.user_id === booking.created_by_id)

  return (
    <div className="border-top overflow-hidden">
      <ReservationDataRow
        name={<IconWithText icon="uil-package" text="Typ rezerwacji:" />}
        className="border-bottom py-2 mr-3"
      >
        <ReservationType booking={booking} showCode />
      </ReservationDataRow>
      <ReservationDataImprovements booking={booking} />
      <ReservationDataCheckInDocuments booking={booking} />
      <ReservationDataWarranty booking={booking} />
      <ReservationDataAutoCancel booking={booking} />
      <ReservationDataSummaryUrl booking={booking} />
      <ReservationDataTags booking={booking} />
      <ReservationDataRow name={<IconWithText icon="uil-plus-square" text="Utworzona:" />} className="mr-3">
        <div>
          <span className="fw-semi-bold">{toDefaultDateFormat(booking.created_at)} </span>
          <span className="font-weight-light">{toDefaultTimeFormat(booking.created_at)}</span>
          <TableUserRow
            userId={booking.created_by_id}
            defaultValue="samodzielnie na stronie"
            className="ml-2 d-inline-flex align-items-center"
          >
            <span className="ml-1">
              {booking.data?.created_in === 'reservation-app-new' && 'nowy system rezerwacji'}
              {booking.data?.created_in === 'dashboard-app' && 'panel'}
            </span>
          </TableUserRow>
        </div>
        <IconWithText
          wrapperClassNames="d-flex mt-1"
          icon="uil-user font-24"
          text={
            <>
              <span className="d-block fw-semi-bold">{seller?.name}</span>
              <span className="font-11 font-weight-light">{seller?.department_display}</span>
            </>
          }
        />
      </ReservationDataRow>
    </div>
  )
}
