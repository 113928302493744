import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { ImprovementAvailability } from '@models/booking'
import { useCancelablePromise } from '@components/hooks/use-cancelable-promise'
import { useApiRequest } from '@components/hooks/use-api-request'
import { ImprovementPriceCalculation } from '@store/actions/reception-actions'
import { commonObjectPost } from '@store/actions/generic-actions'
import { BookingImprovementsAvailableAdditionalAdd } from '@modules/reception/checkin/step-improvements/booking-improvements-available/additional-add'
import { groupByField } from '@helpers/utils'
import { ReceptionBookingCheckInStepImprovementsGroup } from '@modules/reception/checkin/step-improvements/reception-booking-check-in-step-improvements-group'
import { ReceptionBookingCheckInStepSingleImprovement } from '@modules/reception/checkin/step-improvements/reception-booking-check-in-step-single-improvement'
import { VipImprovementsCodes } from '@modules/reception/checkin/step-improvements/vip/reception-booking-check-in-improvements-vip'
import { HPFTicketImprovementCode } from '@modules/reception/checkin/step-improvements/tickets/hpf/reception-booking-hpf-ticket-improvement'
import { SubscriptionBookingImprovementCode } from '@modules/reception/checkin/step-improvements/improvement-subscription/reception-booking-improvement-subscription'

interface Props {
  booking: ReceptionBookingDetails
  improvementsAvailability: ImprovementAvailability[]
}

export const BookingImprovementsAvailable = ({ booking, improvementsAvailability }: Props): JSX.Element => {
  const [calculatedPrices, setCalculatedPrices] = React.useState<ImprovementPriceCalculation[]>([])
  const [isLoaded, setIsLoaded] = React.useState(false)

  const isImprovementAdded = (improvementCode: string) => booking.improvements.some(row => row.code === improvementCode)

  const availableImprovements = improvementsAvailability.filter(
    improvement =>
      !isImprovementAdded(improvement.code) &&
      !VipImprovementsCodes.includes(improvement.code) &&
      improvement.code !== HPFTicketImprovementCode &&
      improvement.code !== SubscriptionBookingImprovementCode,
  )

  const { action: calculatePrices } = useApiRequest(async cancelToken => {
    if (!availableImprovements.length) return

    setCalculatedPrices(
      (
        await commonObjectPost<{ items: ImprovementPriceCalculation[] }>(
          booking.urls.improvement_calculation,
          availableImprovements.map(availableImprovement => ({
            code: availableImprovement.code,
            amount: 1,
            date_to: booking.date_to,
            date_from: booking.date_from,
          })),
          cancelToken,
        )
      ).items,
    )

    setIsLoaded(true)
  })

  useCancelablePromise(calculatePrices, [availableImprovements.length])

  const groupedImprovements = groupByField<ImprovementAvailability>(availableImprovements, 'group_name')

  return (
    <LoaderPlaceholder content={isLoaded} className="p-2">
      {Object.entries(groupedImprovements).map(([name, improvements]) => (
        <ReceptionBookingCheckInStepImprovementsGroup key={name} title={name}>
          {improvements.map(improvement => {
            const calculatedPrice = calculatedPrices.find(el => el.code === improvement.code)
            return (
              <ReceptionBookingCheckInStepSingleImprovement
                key={improvement.code}
                booking={booking}
                totalPriceBrutto={calculatedPrice?.price || '0'}
                price_brutto={calculatedPrice?.original_single_price_brutto || '0'}
                price_brutto_first_item={calculatedPrice?.original_single_price_brutto_first_item || '0'}
                improvement={improvement}
              />
            )
          })}
        </ReceptionBookingCheckInStepImprovementsGroup>
      ))}
      <BookingImprovementsAvailableAdditionalAdd
        calculatedPrices={calculatedPrices}
        booking={booking}
        improvementsAvailability={improvementsAvailability}
      />
    </LoaderPlaceholder>
  )
}
