import * as React from 'react'
import { EmailTemplateDetails } from '@modules/cms/models'
import { NotificationTemplatesEmailModalAttachmentsUploader } from '@modules/cms/notification-templates/email/modal/attachments-uploader'
import { NotificationTemplatesEmailModalAttachmentsList } from '@modules/cms/notification-templates/email/modal/attachments-list'

interface Props {
  emailTemplateDetails: EmailTemplateDetails
}

export const NotificationTemplatesEmailModalAttachments = ({ emailTemplateDetails }: Props): JSX.Element => (
  <>
    <NotificationTemplatesEmailModalAttachmentsList emailTemplateDetails={emailTemplateDetails} />
    <NotificationTemplatesEmailModalAttachmentsUploader emailTemplateDetails={emailTemplateDetails} />
  </>
)
