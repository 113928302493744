import * as React from 'react'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { PackageWholesaleDetails } from '@modules/package-wholesale/models'

interface Props {
  packageWholesaleDetails: PackageWholesaleDetails
}

export const PackageWholesaleNotificationsTable: React.FC<Props> = ({ packageWholesaleDetails }) => (
  <table className="table table-sm mt-1">
    <tbody>
      {packageWholesaleDetails.notifications.length ? (
        packageWholesaleDetails.notifications.map(notification => (
          <tr key={notification.id}>
            <td>
              <a href={notification.urls.preview} target="_blank">
                {notification.kind_display}
              </a>
            </td>
            <td>{toDefaultDateTimeFormat(notification.created)}</td>
          </tr>
        ))
      ) : (
        <tr>
          <td className="text-center">Brak powiadomień</td>
        </tr>
      )}
    </tbody>
  </table>
)
