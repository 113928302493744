import * as React from 'react'
import { ReceptionBooking, ReceptionBookingDetails } from '@models/reception'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { useAppDispatch } from '@store/index'
import { updateReceptionBooking, updateReceptionBookingDetails } from '@store/actions/reception-actions'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'

interface Props {
  booking: ReceptionBooking
  children: React.ReactNode
}

export const ReceptionToCheckOutRowButtonsExtendedStayRemove: React.FC<Props> = ({ booking, children }) => {
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading, action: handleRemoveEvent } = useApiRequest(async () => {
    const newBooking = await commonObjectDelete<ReceptionBookingDetails>(booking.urls.extended_stay)
    await dispatch(updateReceptionBooking(newBooking))
    await dispatch(updateReceptionBookingDetails(newBooking))
    addSuccessMessage('Sukces', 'Przedłużenie zostało skasowane')
  })

  return (
    <CommonObjectConfirmAction
      isLoading={isLoading}
      handleAccept={handleRemoveEvent}
      message="Czy na pewno chcesz anulować wydłużenie doby ? Operacja ta jest nieodwracalna"
    >
      <span>{children}</span>
    </CommonObjectConfirmAction>
  )
}
