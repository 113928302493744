import * as React from 'react'
import Table, { TableHeaderValue } from '@components/table/table'
import { CouponTypesTableRow } from '@modules/coupons/types/table/coupon-types-table-row'
import { CouponType } from '@modules/coupons/models'
import { CouponTypesFilters } from '@modules/coupons/types'

interface Props {
  couponTypes: CouponType[]
  setFilters: (filters: CouponTypesFilters) => void
  filters: CouponTypesFilters
  isLoading: boolean
}

const headerValues: TableHeaderValue[] = [
  { sortField: 'name', title: 'Nazwa kuponu' },
  { title: 'Lokalizacja' },
  { title: 'Możliwe wykorzystanie w godzinach' },
  { title: 'Możliwy zakup w godzinach' },
  { title: 'Aktywny' },
  { title: '' },
]

export const CouponTypesTable: React.FC<Props> = ({ setFilters, couponTypes, filters, isLoading }) => (
  <Table
    className="table-hover w-100"
    headerValues={headerValues}
    isLoading={isLoading}
    filters={filters}
    setFilters={setFilters}
  >
    {couponTypes.map((couponType: CouponType) => (
      <CouponTypesTableRow key={couponType.id} couponType={couponType} />
    ))}
  </Table>
)
