import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { PackageSummarySection, PackageSummarySectionDataRow } from '@modules/package/create/package-summary-section'
import { Row } from 'reactstrap'
import { ProductSaleSummaryInvoice } from '@components/sale/product-sale-summary-invoice'
import { SubscriptionContractCreateFormInputs } from '@modules/subscription-contract/create/modal'
import { toDefaultDateFormat } from '@helpers/date-helper'

export const SubscriptionContractCreateFormSummary = (): JSX.Element => {
  const { getValues } = useFormContext<SubscriptionContractCreateFormInputs>()

  const data = getValues()

  return (
    <div className="pb-2">
      <h4 className="mt-0 text-secondary">Podsumowanie sprzedaży</h4>
      <Row>
        <PackageSummarySection title="Dane klienta" className="col-6">
          <PackageSummarySectionDataRow title="Imię i nazwisko:" content={data.client?.name} />
          <PackageSummarySectionDataRow title="Adres e-mail:" content={data.client?.email} />
          <PackageSummarySectionDataRow title="Numer telefonu:" content={data.client?.phone} />
          <PackageSummarySectionDataRow
            title="Data sprzedaży subskrypcji:"
            content={toDefaultDateFormat(data.sell_date)}
          />
          <PackageSummarySectionDataRow title="Plan:" content={data.plan?.label} />
          <PackageSummarySectionDataRow
            title="Auto anulowanie po:"
            content={data.auto_cancel_after_date ? toDefaultDateFormat(data.auto_cancel_after_date) : 'Brak'}
          />
        </PackageSummarySection>

        {data.invoice_nip && <ProductSaleSummaryInvoice />}
      </Row>
      <div className="dropdown-divider" />
      <Row>
        <PackageSummarySection title="Informacje handlowe" className="col-12">
          <Row>
            <div className="col-6">
              <PackageSummarySectionDataRow title="Sprzedawca:" content={data.seller?.label ?? 'Brak'} />
            </div>
            <div className="col-6">
              <PackageSummarySectionDataRow title="Źródło:" content={data.source_marketing?.label ?? 'Brak'} />
            </div>
          </Row>
        </PackageSummarySection>
      </Row>
      <div className="dropdown-divider" />
      <Row>
        <PackageSummarySection title="Link do płatności" className="col-12">
          <p className="opacity-75">
            Link do opłacenia sprzedaży zostanie wysłany na adres e-mail Gościa{' '}
            <strong className="text-dark">{data.client?.email}</strong>
          </p>
        </PackageSummarySection>
      </Row>
    </div>
  )
}
