import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { ReceptionBookingDetails } from '@models/reception'
import { SaveButton } from '@hyper/button'
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { useFormRequest } from '@components/hooks/use-api-request'
import { useAppDispatch } from '@store/index'
import { Form } from '@hyper/forms/form'
import { updateReceptionBookingDetails } from '@store/actions/reception-actions'
import { commonObjectPut } from '@store/actions/generic-actions'
import { FormTimeSpinInput } from '@hyper/forms/form-time-spin-input'
import { getIntValue } from '@helpers/utils'

interface Props extends BaseModalProps {
  booking: ReceptionBookingDetails
}

interface FormInputs {
  hour: string
  minute: string
}

export const ReservationDeclaredArrivalTimeModal: React.FC<Props> = ({ booking, toggleIsVisible }) => {
  const [hour, minute] = (booking.declared_arrival_time ?? booking.arrival_time).split(':')

  const methods = useForm<FormInputs>({
    defaultValues: { hour, minute },
  })

  const dispatch = useAppDispatch()

  const { isLoading, action: onSubmit } = useFormRequest(
    async (payload: FormInputs) => {
      await dispatch(
        updateReceptionBookingDetails(
          await commonObjectPut<ReceptionBookingDetails>(booking.urls.reservation_booking_details, {
            declared_arrival_time: `${payload.hour}:${payload.minute}`,
          }),
        ),
      )

      toggleIsVisible()
    },

    methods.setError,
    { showGlobalError: true },
  )

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Deklarowana godzina przyjazdu</ModalHeader>
      <ModalBody className="d-flex align-items-center justify-content-center">
        <FormTimeSpinInput hourMin={getIntValue(booking.arrival_time.split(':')[0])} />
      </ModalBody>
      <ModalFooter>
        <button type="button" onClick={toggleIsVisible} className="btn btn-light">
          Anuluj
        </button>
        <SaveButton isSaving={isLoading} className="btn btn-green" />
      </ModalFooter>
    </Form>
  )
}
