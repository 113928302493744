import * as React from 'react'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { useSortedRows } from '@components/table/use-sorted-rows'
import { Button, Card, CardBody, Col, Row } from 'reactstrap'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { useFilteredRows } from '@components/table/use-filtered-rows'
import { PackageEntranceTicket } from '@models/package'
import { PackageDetailsEntranceCodesRow } from '@modules/package/details/entrance-codes/row'
import { BadgeWithIcon } from '@components/badges/BadgeWithIcon'

interface Props {
  title: string
  entranceTickets: PackageEntranceTicket[]
  codesActive?: boolean
  downloadUrl: string
}

const defaultFilters: TableFilters = {
  search: '',
  ordering: '-is_used',
  page: 1,
  page_size: 9999,
}

export const PackageDetailsEntranceCodes = ({ title, entranceTickets, downloadUrl, codesActive = true }: Props) => {
  const [filters, setFilters] = React.useState<TableFilters>(defaultFilters)

  const setValue = (_, search) => setFilters({ ...filters, search })

  const relatedCodeExist = entranceTickets.some(ticket => !!ticket.related_code)

  const headerValues: TableHeaderValue<PackageEntranceTicket>[] = [
    { title: 'LP', sortField: '' },
    { title: 'Kod', search: true, sortField: 'code' },
    ...(relatedCodeExist ? [{ title: 'Kod powiązany', search: true, sortField: 'related_code' }] : []),
    { title: 'Status', sortField: 'status' },
    { title: 'Data wykorzystania', sortField: 'used_at' },
    { title: 'Data wygaśnięcia', sortField: 'expire_after' },
    { title: '' },
  ]

  const filteredRows = useFilteredRows<PackageEntranceTicket>(entranceTickets, headerValues, String(filters.search))

  const sortedCodes = useSortedRows<PackageEntranceTicket>(filteredRows, filters.ordering)

  return (
    <Card>
      <CardBody>
        <Row className="align-items-center">
          <Col className="col-auto col-md-8">
            <span className="h4 text-secondary">{title}</span>
            {!codesActive && (
              <BadgeWithIcon
                icon="uil-ban"
                variant="danger-dark"
                title=" Kody są nieaktywne (należy potwierdzić pakiet)"
                className="ml-1 badge rounded-pill"
              />
            )}
          </Col>
          <SearchFilter hideLabel={true} setValue={setValue} colClassName="col-md-2" />
        </Row>
        <Table
          maxHeight="340px"
          className="table-hover table-sm w-100"
          headerValues={headerValues}
          isLoading={false}
          showPagination={false}
          filters={filters}
          setFilters={setFilters}
        >
          {sortedCodes.map((ticket, index) => (
            <PackageDetailsEntranceCodesRow
              index={index + 1}
              ticket={ticket}
              key={ticket.id}
              withRelatedCodeField={relatedCodeExist}
            />
          ))}
        </Table>
        <a href={downloadUrl} target="_blank" className="text-white">
          <Button className="btn btn-secondary btn-sm ml-auto d-block mt-3">
            <i className="uil-download-alt font-14 mr-1" />
            Pobierz
          </Button>
        </a>
      </CardBody>
    </Card>
  )
}
