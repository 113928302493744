import * as React from 'react'
import { Modal, ModalBody } from 'reactstrap'

interface Props {
  isOpen: boolean
  handleClose: () => void
  message?: React.ReactNode
  title?: React.ReactNode
  buttonText?: string
  action?: (onHide: () => void) => React.ReactNode
  onBeforeClose?: () => void
}

export const ErrorModal = ({ isOpen, handleClose, message, title, buttonText, action, onBeforeClose }: Props) => {
  const [isShow, setIsShow] = React.useState(isOpen)

  const handleHide = () => {
    onBeforeClose?.()
    setIsShow(false)
  }

  return (
    <Modal size="sm" isOpen={isShow} toggle={handleHide} onClosed={handleClose} role="error-modal">
      <ModalBody className="modal-colored-header bg-danger text-center">
        <i className="uil-ban h1" />
        <h4 className="mt-2">{title || 'Błąd!'}</h4>
        <p className="mt-3">{message || 'Wystąpił błąd, prosimy spróbować ponownie później'}</p>
        {action?.(handleHide) ?? (
          <button type="button" className="btn btn-light my-2" data-dismiss="modal" onClick={handleHide}>
            {buttonText || 'OK'}
          </button>
        )}
      </ModalBody>
    </Modal>
  )
}
