import * as React from 'react'
import { BandReaderResult, ReceptionBookingDetails } from '@models/reception'
import { FormProvider, useForm } from 'react-hook-form'
import { ReceptionBandScanButton } from '@modules/reception/reception-band-scan-button'
import { bandReaderEncode, bandReaderEncodeCancel } from '@store/actions/bands-actions'
import { v4 as uuidv4 } from 'uuid'
import classNames from 'classnames'
import { useCounter, useIntervalWhen, useTimeoutWhen } from 'rooks'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { ReceptionBookingCheckInStepAccessBandsHourFrom } from '@modules/reception/checkin/step-access/reception-booking-check-in-step-access-bands-hour-from'
import { useApiRequest, useFormRequest } from '@components/hooks/use-api-request'
import { IconWithText } from '@components/icon-with-text'
import { ButtonWithIcon } from '@components/button-with-icon'
import { Button } from 'reactstrap'
import { commonObjectGet } from '@store/actions/generic-actions'
import { ReceptionBookingCheckInStepAccessBandsListTable } from '@modules/reception/checkin/step-access/reception-booking-check-in-step-access-bands-list-table'

const ERROR_TIMEOUT = 60000
const SECONDS_TIMEOUT = 1000

interface FormInputs {
  hour_from: string
  bands: string | string[]
  select_all: boolean
}

interface Props {
  booking: ReceptionBookingDetails
  bandReaderId: number
}

export const ReceptionBookingCheckInStepAccessBandsList: React.FC<Props> = ({ booking, bandReaderId }) => {
  const [isScanning, setIsScanning] = React.useState(false)
  const [isCompleted, setIsCompleted] = React.useState(false)

  const [requestedId, setRequestedId] = React.useState<null | string>(null)
  const [detailsUrl, setDetailsUrl] = React.useState<string | null>(null)

  const { value: counterValue, decrement: countDown, reset: resetCounter } = useCounter(ERROR_TIMEOUT / 1000)

  const { addErrorMessage, addSuccessMessage } = useNotificationHook()

  const methods = useForm<FormInputs>({
    defaultValues: {
      bands: booking.bands.map(band => String(band.id)),
      hour_from: booking.arrival_time || '16:00',
    },
  })

  const bands = methods.watch('bands')

  const handleClear = () => {
    setIsScanning(false)
    setRequestedId(null)
    resetCounter()
  }

  const updateScanningStatus = () => {
    if (counterValue % 2 === 0) {
      handleScanningFallback()
    }

    if (counterValue > 0) {
      countDown()
    }

    if (counterValue === 0) {
      handleClear()
    }
  }

  useIntervalWhen(updateScanningStatus, SECONDS_TIMEOUT, isScanning, true)

  const { action: handleScanningFallback } = useApiRequest(async () => {
    if (!detailsUrl) return
    handleScanningResult(await commonObjectGet<BandReaderResult>(detailsUrl))
  })

  const { action: handleCancel } = useApiRequest(async () => {
    if (requestedId) {
      await bandReaderEncodeCancel({ uuid: requestedId })
    }

    handleClear()
    setIsCompleted(false)
  })

  const { action: handleScanning } = useFormRequest(async () => {
    if (!bands.length) {
      return
    }

    setIsCompleted(false)
    setIsScanning(true)

    const uuid = uuidv4()
    setRequestedId(uuid)
    const response = await bandReaderEncode({
      hour_from: methods.getValues('hour_from'),
      reader: bandReaderId,
      bands: typeof bands === 'string' ? [bands] : [...bands],
      uuid,
    })

    setDetailsUrl(response.url)
  }, methods.setError)

  const handleScanningResult = (bandReaderResult: BandReaderResult) => {
    if (bandReaderResult.status !== 'COMPLETED') return

    if (bandReaderResult.result === 'ERROR') {
      addErrorMessage('Błąd podczas kodowania opasek', bandReaderResult.error_details)
      setIsCompleted(false)
      handleClear()
    }

    if (bandReaderResult.result === 'SUCCESS') {
      addSuccessMessage('Sukces', 'Skanowanie opasek zostało zakończone')
      setIsCompleted(true)
      handleClear()
    }
  }

  useTimeoutWhen(
    () => {
      addErrorMessage('Wystąpił błąd', 'Nie udało się zakodować opasek, spróbuj ponownie.')
      handleClear()
    },
    ERROR_TIMEOUT,
    isScanning,
  )

  return (
    <div>
      <FormProvider {...methods}>
        <ReceptionBookingCheckInStepAccessBandsHourFrom />
        <span className="font-11 d-block mb-1">Wskaż Gości, którym zakodujesz opaski:</span>
        <ReceptionBookingCheckInStepAccessBandsListTable
          bands={booking.bands}
          guests={booking.guests}
          isScanning={isScanning}
        />
      </FormProvider>

      {isCompleted && (
        <div className="alert alert-success alert-success-lighten font-11">
          <IconWithText icon="uil-check" text="Goście otrzymali zakodowane opaski" />
        </div>
      )}

      <div className="d-flex justify-content-between">
        <div className="d-flex">
          {isScanning ? (
            <div className="d-flex align-items-center">
              <Button type="button" color="secondary" className="mr-2" onClick={handleCancel}>
                Anuluj
              </Button>
              <div>
                <strong>Trwa skanowanie</strong> ({counterValue}s do końca)
              </div>
            </div>
          ) : (
            <ButtonWithIcon
              icon="uil-capture font-14"
              text="Rozpocznij skanowanie"
              handleClick={handleScanning}
              color="primary"
              btnClass={classNames({
                'opacity-5': !(bands || []).length,
              })}
            />
          )}
        </div>
        <ReceptionBandScanButton label="Sprawdź opaskę" />
      </div>
    </div>
  )
}
