import * as React from 'react'
import { FormCheckbox } from '@hyper/forms/form-checkbox'
import { NewFeedingCalculationDetails } from '@modules/reception/checkin/step-feeding/new-feeding/models'
import { ReceptionBookingNewFeedingPriceTooltip } from '@modules/reception/checkin/step-feeding/new-feeding/tooltip/reception-booking-new-feeding-price-tooltip'
import classNames from 'classnames'
import { ReceptionBookingNewFeedingCheckboxLabelContextMenu } from '@modules/reception/checkin/step-feeding/new-feeding/day-row/context-menu/reception-booking-new-feeding-day-row-checkbox-label-context-menu'
import { isMealUneaten, isMealUpcoming } from '@modules/reception/checkin/step-feeding/new-feeding/utils'
import { useAppSelector } from '@store/index'
import { selectIsDiscountPreviewActive } from '@store/slices/new-feeding-slice'
import { ReceptionBookingNewFeedingPrice } from '@modules/reception/checkin/step-feeding/new-feeding/reception-booking-new-price'

interface Props {
  calculation: NewFeedingCalculationDetails
  name: string
  children?: React.ReactNode
}

export const ReceptionBookingNewFeedingDayRowCheckbox = ({ calculation, name, children }: Props) => {
  const isDiscountPreviewActive = useAppSelector(selectIsDiscountPreviewActive)
  const isAddedAndPaid = calculation.checked && calculation.confirmed
  const isAddedAndUnpaid = calculation.checked && !calculation.confirmed

  const isUneaten = isMealUneaten(calculation)
  const isUpcoming = isMealUpcoming(calculation)

  const price = isDiscountPreviewActive ? calculation.discount_amount : calculation.price_brutto

  return (
    <div className="d-flex align-items-center">
      <div className="pr-1">
        <FormCheckbox
          name={name}
          value={true}
          disabled={!calculation.can_edit}
          className={classNames({
            'pe-none': !calculation.can_edit,
            'opacity-5': calculation.used || isUneaten || !isUpcoming,
            'checkbox-label-disabled-opacity-1': !calculation.can_edit,
          })}
          label={
            <ReceptionBookingNewFeedingCheckboxLabelContextMenu calculation={calculation}>
              <ReceptionBookingNewFeedingPrice
                className={classNames('fw-semi-bold', {
                  'text-danger': isAddedAndUnpaid,
                })}
                price={price}
              />
            </ReceptionBookingNewFeedingCheckboxLabelContextMenu>
          }
        />
      </div>
      {children}
      <ReceptionBookingNewFeedingPriceTooltip
        calculation={calculation}
        iconClassName={classNames({
          'uil-restaurant font-13 lh-0 text-danger': isUneaten,
          'uil-restaurant font-13 lh-0 text-success': calculation.used,
          'uil-check font-15 lh-0': isAddedAndPaid && !calculation.used && !isUneaten,
          'uil-exclamation-circle font-15 lh-0': isAddedAndUnpaid && !calculation.used && isUneaten,
        })}
      />
      {calculation.related_date && (
        <strong className="feeding__bestseller" title="Posiłek w promocji Bestseller">
          B
        </strong>
      )}
      {calculation.is_paid_by_wallet && (
        <strong className="feeding__voucher" title="Opłacony przez voucher gastronomiczny">
          <i className="uil-tag-alt" />
        </strong>
      )}
    </div>
  )
}
