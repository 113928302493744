import * as React from 'react'
import { FormInput } from '@hyper/forms'
import { RootState, useAppSelector } from '@store/index'

export const SourceInput: React.FC = () => {
  const bookingSources = useAppSelector((state: RootState) => state.appState.appData.booking_sources)

  return (
    <FormInput colSize={6} multiple={true} type="select" label="Źródło" name="source">
      {bookingSources.map(bookingSource => (
        <option value={bookingSource.id} key={bookingSource.id}>
          {bookingSource.name}
        </option>
      ))}
    </FormInput>
  )
}
