import * as React from 'react'
import { ReceptionToCheckOutFiltersParams } from '@modules/reception/to-checkout/reception-to-checkout'
import { useForm } from 'react-hook-form'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { SubmitFilter } from '@modules/reception/common/filters/submit-filter'
import { SortByFilter } from '@modules/reception/common/filters/sort-by-filter'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { startOfToday } from 'date-fns'
import { formatDate } from '@helpers/date-helper'
import { ColAuto } from '@hyper/col-auto'
import { FormPlain } from '@hyper/forms'
import { CustomInput } from 'reactstrap'
import { extractInnerRef } from '@helpers/forms'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { DatePickerForm } from '@modules/reception/common/filters/date-picker-form'
import { Form } from '@hyper/forms/form'
import { createSelectOption } from '@helpers/utils'
import { setReceptionDateFilter } from '@store/slices/reception-slice'

interface ReceptionToCheckOutFiltersProps {
  defaultFilters: ReceptionToCheckOutFiltersParams
  filters: ReceptionToCheckOutFiltersParams
  setFilters: (filters: ReceptionToCheckOutFiltersParams) => void
  totalVip: number
}

export const ReceptionToCheckOutFilters: React.FC<ReceptionToCheckOutFiltersProps> = ({
  filters,
  setFilters,
  defaultFilters,
  totalVip,
}) => {
  const methods = useForm<ReceptionToCheckOutFiltersParams>({
    defaultValues: filters,
  })
  const dispatch = useAppDispatch()

  const receptionDateFilter = useAppSelector((state: RootState) => state.receptionState.receptionDateFilter)
  const receptionBookingsAggregation = useAppSelector(
    (state: RootState) => state.receptionState.receptionBookingsAggregation,
  )

  const totalToCheckOut = React.useMemo(
    () => (receptionBookingsAggregation?.to_checkout || 0) + (receptionBookingsAggregation?.complete_checkin || 0),
    [receptionBookingsAggregation],
  )

  React.useEffect(() => {
    const filterDateTo = methods.getValues('date_to')
    if (filterDateTo && formatDate(filterDateTo) !== formatDate(receptionDateFilter) && receptionDateFilter) {
      methods.setValue('date_to', receptionDateFilter)
    }
  }, [])

  const onSubmit = (payload: ReceptionToCheckOutFiltersParams) => {
    setFilters(payload)

    if (payload.date_to && payload.date_to !== receptionDateFilter) {
      dispatch(setReceptionDateFilter(payload.date_to))
    }
  }

  const handleReset = () => {
    methods.reset()
    setFilters(defaultFilters)
    dispatch(setReceptionDateFilter(startOfToday()))
  }

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  return (
    <Form methods={methods} onSubmit={onSubmit} className="row form form-style-filter mb-1">
      <DatePickerForm name="date_to" control={methods.control} label="Zakończenie rezerwacji:" />
      <SortByFilter options={sortByOptions} />
      <ColAuto>
        <FormPlain colClassName="p-0" name="only_vip" errors={{}}>
          <label className="d-block mt-1">&nbsp;</label>
          <CustomInput
            id="only_vip"
            className="mt-2 text-default"
            type="checkbox"
            label={`Tylko VIP (${totalVip})`}
            {...extractInnerRef(methods.register('only_vip'))}
          />
        </FormPlain>
      </ColAuto>

      <ColAuto>
        <FormPlain colClassName="p-0" name="all_checkouts" errors={{}}>
          <label className="d-block mt-1">&nbsp;</label>
          <CustomInput
            className="mt-2 text-default"
            role="filters-all_checkouts"
            type="checkbox"
            {...extractInnerRef(methods.register('all_checkouts'))}
            id="all_checkouts"
            label={`Wszyscy do wymeldowania (${totalToCheckOut})`}
          />
        </FormPlain>
      </ColAuto>

      <SubmitFilter handleReset={handleReset} />
      <SearchFilter />
    </Form>
  )
}

const sortByOptions = [
  createSelectOption('Godzina wyjazdu', 'departure_time'),
  createSelectOption('Imię i nazwisko', 'name'),
  createSelectOption('Długość pobytu', 'days_count'),
  createSelectOption('Numer lokalu', 'apartment.name'),
]
