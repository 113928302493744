import * as React from 'react'
import { ReceptionCheckReminder } from '@modules/reception/common/reception-check-reminder'
import { useAppData } from '@components/hooks/use-app-data'
import { useGetCashbackVoucherQuery } from '@api/cashback'
import { ReceptionBookingDetails } from '@models/reception'
import { TypedQueryResult } from '@api/base'
import { CashbackVoucher } from '@modules/products/cashback-vouchers/models'
import { formatPriceShort } from '@helpers/utils'
import { FormCheckbox } from '@hyper/forms/form-checkbox'
import { useFormContext } from 'react-hook-form'

interface Props {
  booking: ReceptionBookingDetails
}

export const ReceptionBookingCheckOutStepBillCashbackVoucher: React.FC<Props> = ({ booking }) => {
  const { configuration } = useAppData()
  const { register } = useFormContext()

  const { data: cashbackVouchers = [] } = useGetCashbackVoucherQuery({
    source_booking__reservation_number: booking.reservation_number,
    status: [{ value: 'generate' }],
  }) as TypedQueryResult<CashbackVoucher[]>

  const cashbackVoucher = cashbackVouchers.length === 1 ? cashbackVouchers[0] : null

  if (!cashbackVoucher) {
    return null
  }

  if (!configuration.cashback_vouchers) {
    return null
  }

  return (
    <div className="bg-grey rounded px-3 py-2">
      <strong className="d-block mb-3 font-14 text-dark">Voucher rePLAY!</strong>
      <ReceptionCheckReminder
        initialValue={false}
        errors={{}}
        required={true}
        id="cashback_voucher"
        isClosable={true}
        register={register}
      >
        Opowiedz Gościowi o <strong>voucherze rePLAY!</strong>. Zaproponuj wgląd w regulamin.
      </ReceptionCheckReminder>
      <p className="my-2 text-semi-strong">
        Zebrane środki na Voucherze rePLAY! {cashbackVoucher.number} to{' '}
        {formatPriceShort(cashbackVoucher.available_amount)}
      </p>
      <FormCheckbox name="cashback_voucher_accepted" label="Gość chce zakupić voucher za kwotę 0.01 zł" />
    </div>
  )
}
