import * as React from 'react'
import { Col, Row } from 'reactstrap'
import { FormInput } from '@hyper/forms'

export const PaymentUserOpenDayModalStartAmount = (): JSX.Element => (
  <Row className="mx-0 align-items-center">
    <Col md={5}>
      <strong>Stan początkowy kasetki:</strong>
    </Col>
    <Col md={6}>
      <div className="d-flex align-items-center">
        <i className="uil-usd-circle text-muted font-16" />
        <div>
          <FormInput
            name="start_amount"
            readOnly={true}
            registerParams={{ valueAsNumber: true }}
            formGroupClassName="m-0"
          />
        </div>
        <div>zł</div>
      </div>
    </Col>
  </Row>
)
