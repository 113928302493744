import * as React from 'react'
import { AlertDanger } from '@components/alerts'

type DepositSpendStatus = 'almost-used' | 'used'

interface Props {
  spendLimit: DepositSpendStatus
}

export const ReservationMinimumDepositLabel = ({ spendLimit }: Props): JSX.Element => (
  <AlertDanger
    iconClassName="font-16"
    className="d-inline-block px-2 py-1 font-11 fw-semi-bold"
    wrapperClassName="align-items-center"
  >
    Kaucja {spendLimit === 'almost-used' ? 'prawie wykorzystana' : 'wykorzystana'}, należy zwiększyć środki.
  </AlertDanger>
)
