import * as React from 'react'

interface Props {
  label: React.ReactNode
}

export const BandScanningWaiting = ({ label }: Props): JSX.Element => (
  <div className="d-flex align-items-center">
    <div className="band__scanning-guest__action">
      <i className="uil-hourglass text-secondary font-16" />
    </div>
    {label}
  </div>
)
