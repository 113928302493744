import * as React from 'react'
import { createSelectOption } from '@helpers/utils'
import { FormSelect } from '@hyper/forms/form-select'

const Options = [createSelectOption('Domek', 'house'), createSelectOption('Apartament', 'apartment')]

export const HousekeepingOrderTableHeaderLocal = (): JSX.Element => (
  <th className="py-1 border-top-0 fw-semi-bold pl-0" style={{ width: 200 }}>
    <FormSelect
      options={Options}
      selectProps={{ placeholder: 'Lokal', isClearable: true, isSearchable: false }}
      name="local"
      formPlainProps={{ formGroupClassName: 'mb-0', colClassName: 'px-0' }}
    />
  </th>
)
