import Table from '@components/table/table'
import * as React from 'react'
import { Document } from '@models/dashboard'
import { UncontrolledTooltip } from 'reactstrap'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { useApiRequest } from '@components/hooks/use-api-request'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'

interface DocumentListRowProps {
  document: Document
  handleOnSave: () => void
  isReadonly?: boolean
}

export const DocumentListRow: React.FC<DocumentListRowProps> = ({ isReadonly, document, handleOnSave }) => {
  const { addSuccessMessage } = useNotificationHook()

  const { action: handleDelete } = useApiRequest(async () => {
    await commonObjectDelete(document.urls.details)
    handleOnSave()
    addSuccessMessage('Sukces', 'Dokument został usunięty!')
  })

  const toolTipId = `document-row-tooltip-${document.id}`

  return (
    <Table.Row role="document-row">
      <Table.Cell>
        <UncontrolledTooltip placement="top" target={toolTipId} autohide={false}>
          {document.description}
        </UncontrolledTooltip>
        <code id={toolTipId}>{document.file_name}</code>
      </Table.Cell>
      <Table.Cell>{toDefaultDateTimeFormat(document.created)}</Table.Cell>
      <Table.Cell>{document.created_by}</Table.Cell>
      <Table.Cell className="text-nowrap text-right">
        {!isReadonly && <i className="uil-trash-alt cursor-pointer" title="Usuń" onClick={handleDelete} />}
        <a href={document.urls.download} title="Pobierz" className="text-default ml-1">
          <i className="uil-print" />
        </a>
      </Table.Cell>
    </Table.Row>
  )
}
