import * as React from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { TimelineBooking } from '@store/reducers/timeline-reducers'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { Apartment } from '@models/apartment'
import { resetBookingModalData, setEditModeForItem, updateBookingApartment } from '@store/actions/timeline-actions'
import { SaveButton } from '@hyper/button'
import { format, parseISO } from 'date-fns'
import { ReservationItemDiff } from '@modules/reservations/timeline/timeline-content-droppable'
import { useApiRequest } from '@components/hooks/use-api-request'
import { selectAllApartments } from '@store/selectors/timeline'

export interface BookingModalData {
  booking: TimelineBooking
  newDateTo?: string
  newDateFrom?: string
  newApartmentID?: number
}

const TimelineBookingModal: React.FC = () => {
  const dispatch = useAppDispatch()
  const [isModal, setModal] = React.useState(false)

  const modalData = useAppSelector((state: RootState) => state.timelineState.bookingModalData)
  const modalDataWarning = useAppSelector((state: RootState) => state.timelineState.bookingModalDataWarning)
  const apartments = useAppSelector(selectAllApartments)

  React.useEffect(() => {
    if (modalData) {
      setModal(!modalDataWarning)
    }
  }, [modalData, modalDataWarning])

  const getDiff = () => {
    if (modalData) {
      return [
        ...(modalData.newDateFrom ? [{ newDateFrom: modalData.newDateFrom }] : []),
        ...(modalData.newDateTo ? [{ newDateTo: modalData.newDateTo }] : []),
        ...(modalData.newApartmentID ? [{ newApartmentID: modalData.newApartmentID }] : []),
      ]
    } else {
      return []
    }
  }

  const toggleModal = async (): Promise<void> => {
    if (isModal) {
      await dispatch(resetBookingModalData())
    }

    setModal(!isModal)
  }

  const getNewApartmentName = (): Apartment | undefined => apartments.find(ap => ap.id === modalData?.newApartmentID)

  const parseBookingDate = (date: string): string => format(parseISO(date), 'dd.MM')

  const mapDiffsToCurrentReservation = (diff: ReservationItemDiff) => {
    if (!modalData) {
      return ''
    }

    const key = Object.keys(diff)[0]
    switch (key) {
      case 'newDateTo':
        return <span>Data wyjazdu: {parseBookingDate(modalData.booking.date_to)}</span>
      case 'newDateFrom':
        return <span>Data przyjazdu: {parseBookingDate(modalData.booking.date_from)}</span>
      case 'newApartmentID':
        return <span>Lokal: {modalData.booking.apartment.name}</span>
    }
  }

  const mapDiffsToNewReservation = (diff: ReservationItemDiff) => {
    const key = Object.keys(diff)[0]
    switch (key) {
      case 'newDateTo':
        return <span>Data wyjazdu: {parseBookingDate(diff.newDateTo || '')}</span>
      case 'newDateFrom':
        return <span>Data przyjazdu: {parseBookingDate(diff.newDateFrom || '')}</span>
      case 'newApartmentID':
        return <span>Lokal: {getNewApartmentName()?.name || diff.newApartmentID}</span>
    }
  }

  const { isLoading: isSaving, action: handleChange } = useApiRequest(async () => {
    if (!modalData) {
      return
    }

    const { booking, newDateTo, newDateFrom, newApartmentID } = modalData
    await dispatch(
      updateBookingApartment({
        url: booking.urls.change_apartment,
        dateTo: booking.date_to !== newDateTo && newDateTo ? newDateTo : booking.date_to,
        dateFrom: booking.date_from !== newDateFrom && newDateFrom ? newDateFrom : booking.date_from,
        apartmentId: booking.apartment.id !== newApartmentID && newApartmentID ? newApartmentID : booking.apartment.id,
      }),
    )
    await dispatch(resetBookingModalData())
    await dispatch(setEditModeForItem(null))
    setModal(false)
  })

  return (
    <Modal size="md" isOpen={isModal} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>Potwierdź zmianę rezerwacji</ModalHeader>
      <ModalBody>
        <strong className="mb-2 d-block">
          Czy na pewno chcesz zmienić rezerwację {modalData?.booking.reservation_number}?
        </strong>
        <div className="d-flex justify-content-between w-75 pb-1">
          <span>Obecna rezerwacja:</span>
          <ul className="m-0">
            {getDiff().map((diff, i) => (
              <li key={i} className="text-semi-strong">
                {mapDiffsToCurrentReservation(diff)}
              </li>
            ))}
          </ul>
        </div>
        <div className="d-flex justify-content-between w-75">
          <span>Nowa rezerwacja:</span>
          <ul className="m-0">
            {getDiff().map((diff, i) => (
              <li key={i} className="text-semi-strong">
                {mapDiffsToNewReservation(diff)}
              </li>
            ))}
          </ul>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={toggleModal} disabled={isSaving}>
          Anuluj
        </Button>
        <SaveButton isSaving={isSaving} className="btn btn-green" label="Potwierdź" onClick={handleChange} />
      </ModalFooter>
    </Modal>
  )
}

export default TimelineBookingModal
