import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@store/index'
import { BarrierTicket } from '@models/barriers'
import {
  createBarrierTickets,
  getBarrierTickets,
  removeBarrierTickets,
  updateBarrierTickets,
} from '@store/actions/barriers-actions'

interface BarrierState {
  tickets: EntityState<BarrierTicket>
}

const barrierTicketsAdapter = createEntityAdapter<BarrierTicket>()

const initialState: BarrierState = {
  tickets: barrierTicketsAdapter.getInitialState(),
}

export const barriersSlice = createSlice({
  name: 'barriers',
  initialState,
  reducers: {
    clearTicketsAction(state) {
      barrierTicketsAdapter.removeAll(state.tickets)
    },
    removeBarrierTicket(state, action: PayloadAction<BarrierTicket>) {
      barrierTicketsAdapter.removeOne(state.tickets, action.payload.id)
    },
    updateBarrierTicket(state, action: PayloadAction<BarrierTicket>) {
      barrierTicketsAdapter.upsertOne(state.tickets, action.payload)
    },
  },
  extraReducers: builder => {
    builder.addCase(getBarrierTickets.fulfilled, (state, action) => {
      barrierTicketsAdapter.setAll(state.tickets, action.payload)
    })
    builder.addCase(removeBarrierTickets.fulfilled, (state, action) => {
      barrierTicketsAdapter.removeOne(state.tickets, action.payload.id)
    })
    builder.addCase(createBarrierTickets.fulfilled, (state, action) => {
      barrierTicketsAdapter.upsertOne(state.tickets, action.payload)
    })
    builder.addCase(updateBarrierTickets.fulfilled, (state, action) => {
      barrierTicketsAdapter.upsertOne(state.tickets, action.payload)
    })
  },
})

export const { clearTicketsAction, updateBarrierTicket, removeBarrierTicket } = barriersSlice.actions

export const { selectAll: barrierTicketsSelector } = barrierTicketsAdapter.getSelectors(
  (state: RootState) => state.barriersState.tickets,
)

export default barriersSlice.reducer
