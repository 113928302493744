import { OptionProps, SelectComponentsConfig, SingleValueProps } from 'react-select'
import * as React from 'react'

export const ClientSelectComponents: SelectComponentsConfig<any, false, any> = {
  Option: (props: OptionProps<any, false, any>) => (
    <div className="cursor-pointer" style={{ padding: '8px' }} ref={props.innerRef} {...props.innerProps}>
      {props.data.first_name} {props.data.last_name} ({props.data.email})
    </div>
  ),
  SingleValue: (props: SingleValueProps<any>) => props.data.email,
  NoOptionsMessage: () => (
    <div className="text-center" style={{ padding: '8px' }}>
      Brak wyników
    </div>
  ),
}
