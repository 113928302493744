import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { formatPrice } from '@helpers/utils'
import { Collapse } from 'reactstrap'
import { ExpandArrow } from '@components/hooks/use-expandable-items'

interface Props {
  title: string
  subtitle?: string
  price: string
  editLabel?: string
  onEdit?: () => void
  onExpand?: () => void
  isExpanded?: boolean
  children?: React.ReactNode
  pricePrefix?: string
}

export const ReservationBillMainRow = ({
  title,
  subtitle,
  price,
  editLabel,
  onEdit,
  onExpand,
  isExpanded,
  children,
  pricePrefix,
}: Props): JSX.Element => (
  <>
    <tr>
      <td className="align-middle">
        <p className="font-weight-bold mb-0 font-13">{title}</p>
        {subtitle && <span className="font-12">{subtitle}</span>}
      </td>
      <td className="align-middle">
        {onEdit && editLabel && (
          <IconWithText icon="uil-edit-alt" text={editLabel} textClass="font-12 fw-semi-bold" onClick={onEdit} />
        )}
      </td>
      <td className="align-middle text-right">
        {pricePrefix} <strong>{formatPrice(price)}</strong>
      </td>
      <td className="align-middle" width={35}>
        {onExpand && <ExpandArrow isExpanded={isExpanded} onExpand={onExpand} />}
      </td>
    </tr>
    {children && (
      <tr>
        <td className="p-0 border-0" colSpan={4}>
          <Collapse isOpen={isExpanded}>{children}</Collapse>
        </td>
      </tr>
    )}
  </>
)
