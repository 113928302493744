import * as React from 'react'
import classnames from 'classnames'
import { ImprovementAvailability, ReceptionBookingDetailsImprovement } from '@models/booking'

interface ReceptionBookingImprovementsVipDetailsProps {
  vipImprovement: ReceptionBookingDetailsImprovement | undefined
  availableVipImprovement: ImprovementAvailability | undefined
}

export const ReceptionBookingImprovementsVipDetails: React.FC<ReceptionBookingImprovementsVipDetailsProps> = ({
  vipImprovement,
  availableVipImprovement,
}) => {
  const isImprovementAdded = !!vipImprovement
  const [detailsVisible, setDetailsVisible] = React.useState(false)
  const toggleDetailsVisibility = () => setDetailsVisible(state => !state)

  const details = improvements[
    vipImprovement ? vipImprovement.code : availableVipImprovement?.code || 'package_vip'
  ].map((detail: string) => (
    <div
      key={detail}
      className={classnames('py-1 reception__booking-check-in__improvement-step__vip__details__single', {
        'text-white reception__booking-check-in__improvement-step__vip__details__single--dark-border':
          !isImprovementAdded,
      })}
    >
      <i className="uil-check font-18" />
      <span className="font-12 d-block pl-1">{detail}</span>
    </div>
  ))

  const toggleDetailsText = (detailsVisible && 'Ukryj szczegóły') || 'Pokaż szczegóły'

  return (
    <tr>
      <td colSpan={4} className="pb-1">
        <div>
          <div
            className={classnames('pl-2 d-inline-flex cursor-pointer my-1', {
              'text-white': !isImprovementAdded,
            })}
            onClick={toggleDetailsVisibility}
          >
            <span className="font-12">{toggleDetailsText}</span>
            <i
              className={classnames('uil-angle-down font-16 ml-1', {
                'reception__booking-check-in__improvement-step__vip__details--visible': detailsVisible,
              })}
            />
          </div>
          {detailsVisible && (
            <div className="reception__booking-check-in__improvement-step__vip__details">{details}</div>
          )}
        </div>
      </td>
    </tr>
  )
}

const improvements = {
  package_vip: [
    'Bezpłatna możliwość wybrania domku',
    'Możliwość późniejszego wymeldowania - do godz. 12.00',
    'Możliwość wcześniejszego zameldowania - od godz. 13.00',
    'Grill elektryczny',
    'Mikrofalówka',
    '4 ręczniki plażowe VIP',
    'Zestaw ekskluzywnych kosmetyków',
    'Wino czerwone premium',
    'Oznaczenie domku VIP - flaga',
    'Owoce - banany, pomarańcze, kiwi, owoce sezonowe',
    'Dziecko jako animator przez 3 godziny',
    'Wynajem rowerów (10 wypożyczeń)',
    'Rabat 50% (maks. 500zł) w bistro HPR w godzinach 10:00 – 12:00',
    'Warsztaty kulinarne dla dzieci 5x(Rabat -50%)',
    'Okulary VR (Rabat -50%)',
    'Krówki VIP',
    'Opaski na rękę VIP',
    'Pamiątkowy magnes',
    'Loża VIP (Rabat -50%)',
    'Parking VIP po opłaceniu standardowego miejsca',
  ],
  package_vip_winter: [
    'Bezpłatna możliwość wyboru lokalu',
    'Możliwość wcześniejszego zameldowania - od godz. 13.00',
    'Możliwość późniejszego wymeldowania - do godz. 12.00',
    'Grill elektryczny',
    'Mikrofalówka',
    'Ekspres do kawy z zestawem 20 kapsułek',
    'Wynajem rowerów (10 wypożyczeń, w miarę dostępności)',
    'Wino czerwone premium',
    'Owoce – banany, pomarańcze, owoce sezonowe',
    'Zestaw ekskluzywnych kosmetyków',
    'Ręczniki VIP',
    'Oznaczenie domku flagą VIP',
    'Krówki VIP',
    'Opaski na rękę VIP',
    'Pamiątkowy magnes',
    'Dziecko jako animator przez 3 godziny',
    'Warsztaty kulinarne dla dzieci 5x',
    'Strefa VIP w kawiarni (w miarę dostępności)',
    '2 miejsca parkingowe VIP (w miarę dostępności)',
    'Loża VIP w kawiarni (rabat 50%, raz na pobyt)',
    'Rabat -50% w bistro HPR przez pierwsze 1,5h od otwarcia (w miarę dostępności, max. 150 zł)',
    'Rabat -20% w sklepiku HPR (max. 300 zł)',
    'Recepcja VIP poza standardową kolejką*',
  ],
}
