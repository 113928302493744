import * as React from 'react'
import { PageTitle } from '@hyper/page-title'
import { Card, CardBody } from 'reactstrap'
import { useDocumentTitle } from '@helpers/utils'
import { useAppDispatch, useAppSelector } from '@store/index'
import { useSubscriptionAppData } from '@modules/subscription/hooks/use-subscription-app-data'
import { useApiRequest } from '@components/hooks/use-api-request'
import { totalSizeSelector } from '@store/selectors/dashboard'
import { useDebounce } from 'rooks'
import { TableFilters } from '@components/table/table'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { housekeepingUsersSelector } from '@store/slices/housekeeping-slice'
import { getHousekeepingUsers } from '@store/actions/housekeeping-actions'
import { HousekeepingUsersListTableFilter } from '@modules/housekeeping/users/list/filter'
import { HousekeepingUsersListTable } from '@modules/housekeeping/users/list/table'
import { useModal } from '@components/modals/use-modal'
import { ButtonWithIcon } from '@components/button-with-icon'

export interface HousekeepingUsersListTableFilters extends TableFilters {
  resort: CustomReactSelectOption | null
  housekeeping_company: CustomReactSelectOption | null
  roles: CustomReactSelectOption[]
  is_active: CustomReactSelectOption | null
}

const defaultFilters: HousekeepingUsersListTableFilters = {
  resort: null,
  housekeeping_company: null,
  roles: [],
  is_active: null,
  search: '',
  page: 1,
  ordering: 'email',
  page_size: 10,
}

const pageTitle = 'Pracownicy housekeeping'

export const HousekeepingUsersListView: React.FC = () => {
  const totalSize = useAppSelector(totalSizeSelector)
  const pageTitleWithSize = pageTitle + ` (${totalSize})`

  useMenuActive(NavigationPath.HousekeepingUsers)
  useSubscriptionAppData()
  useDocumentTitle(pageTitleWithSize)

  const dispatch = useAppDispatch()
  const users = useAppSelector(housekeepingUsersSelector)

  const [filters, setFilters] = React.useState<HousekeepingUsersListTableFilters>(defaultFilters)

  const [handleAdd] = useModal('HousekeepingUserModal')

  const { isLoading, action: fetchUsers } = useApiRequest(
    async filters => await dispatch(getHousekeepingUsers(filters)),
  )

  const fetchDebounce = React.useCallback(useDebounce(fetchUsers, 300), [])

  React.useEffect(() => {
    fetchDebounce(filters)
  }, [filters])

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitleWithSize} />
      <Card>
        <CardBody>
          <div className="text-right mb-2">
            <ButtonWithIcon color="green" handleClick={handleAdd} icon="mdi mdi-plus-circle" text="Dodaj pracownika" />
          </div>
          <HousekeepingUsersListTableFilter defaultFilters={defaultFilters} filters={filters} setFilters={setFilters} />
          <HousekeepingUsersListTable isLoading={isLoading} users={users} filters={filters} setFilters={setFilters} />
        </CardBody>
      </Card>
    </>
  )
}
