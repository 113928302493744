import * as React from 'react'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { FormProvider, useForm } from 'react-hook-form'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { NotificationTemplatesSmsListTableFilters } from '@modules/cms/notification-templates/sms/index'
import { Button } from 'reactstrap'
import { TableFilters } from '@components/table/table'

interface Props {
  defaultFilters: TableFilters
  filters: TableFilters
  setFilters: (filters: TableFilters) => void
}

export const NotificationTemplatesEmailFilters = ({ setFilters, filters, defaultFilters }: Props): JSX.Element => {
  const methods = useForm<NotificationTemplatesSmsListTableFilters>({ defaultValues: defaultFilters })

  const handleReset = () => {
    methods.reset()
    setFilters(defaultFilters)
  }

  const onSubmit = async payload => setFilters({ ...filters, ...payload })

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  return (
    <FormProvider {...methods}>
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          {methods.formState.isDirty && (
            <Button color="light" className="ml-1 btn-flat mt-label" type="button" onClick={handleReset}>
              Wyczyść
            </Button>
          )}
        </div>

        <SearchFilter colClassName="col-3 pr-0" />
      </div>
    </FormProvider>
  )
}
