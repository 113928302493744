import * as React from 'react'
import { TransferPackage } from '@modules/accounting/refund/models'
import { useModal } from '@components/modals/use-modal'

interface Props {
  transferPackage: TransferPackage
}

export const TransferPackagesTableTableCellActionsEdit: React.FC<Props> = ({ transferPackage }) => {
  const [toggleEdit] = useModal('TransferPackageEditModal', { transferPackage })

  return transferPackage.status === 'initial' ? (
    <span className="uil-pen cursor-pointer text-muted font-16 uil mr-2" onClick={toggleEdit} />
  ) : null
}
