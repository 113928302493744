import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import { useForm } from 'react-hook-form'
import { SaveButton } from '@hyper/button'
import { CustomReactSelectOption, makeDefaultSelectOption } from '@components/custom-react-select'
import { ClientCouponItem } from '@modules/coupons/models'
import { FormSelect } from '@hyper/forms/form-select'
import { CouponStatusesOptions } from '@modules/coupons/consts'
import { useHandleRtkQueryError } from '@components/hooks/use-handle-rtk-error'
import { useUpdateClientCouponItemsMutation } from '@api/coupons'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { Form } from '@hyper/forms/form'

interface Props extends BaseModalProps {
  coupon: ClientCouponItem
}

export interface CouponItemEditFormInputs {
  status: CustomReactSelectOption | null
}

export const CouponItemEditDetailsModal: React.FC<Props> = ({ toggleIsVisible, coupon }) => {
  const { addSuccessNotification } = useNotificationHook()

  const methods = useForm<CouponItemEditFormInputs>({
    mode: 'onChange',
    defaultValues: {
      status: makeDefaultSelectOption(CouponStatusesOptions, coupon.status),
    },
  })

  const [updateClientCouponsListItem, { error, isError, isLoading }] = useUpdateClientCouponItemsMutation()
  useHandleRtkQueryError(error, isError, methods.setError)

  const onSubmit = async (data: CouponItemEditFormInputs) => {
    await updateClientCouponsListItem({ url: coupon.urls.details, data }).unwrap()
    addSuccessNotification('Zmiany zostały zapisane')
    toggleIsVisible()
  }

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Edytuj kupon</ModalHeader>
      <ModalBody className="pb-0">
        <Row>
          <FormSelect options={AvailableEditStatusOptions} name="status" label="Status:" />
        </Row>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <div className="ml-auto">
          <Button className="btn btn-light mr-2" type="button" onClick={toggleIsVisible}>
            Anuluj
          </Button>
          <SaveButton label="Zapisz" className="btn-green btn" isSaving={isLoading} />
        </div>
      </ModalFooter>
    </Form>
  )
}

const AvailableEditStatusOptions = CouponStatusesOptions.filter(option =>
  ['used', 'expired', 'canceled'].includes(option.value),
)
