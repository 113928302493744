import * as React from 'react'
import { ShopProduct } from '@models/shop'
import { useFormContext } from 'react-hook-form'
import { ShopProductSelect } from '@modules/shop/pos/product-selection/shop-product-select'
import { v4 as uuidv4 } from 'uuid'
import classnames from 'classnames'
import { ShopPOSWithParamsFormInputs } from '@modules/shop/pos/shop-pos-with-params'
import { CustomInput } from 'reactstrap'
import { extractInnerRef } from '@helpers/forms'
import { FormPlainError } from '@hyper/forms'
import { ShopProductSelectScanner } from '@modules/shop/pos/product-selection/shop-product-select-scanner'
import { EnhancedShopProduct } from '@modules/shop/pos/models'
import { useShopParking } from '@modules/shop/pos/product-selection/product-configuration-modals/use-shop-parking'

interface Props {
  products: ShopProduct[]
  updateProduct: (index: number, product: EnhancedShopProduct) => void
  addProduct: (product: EnhancedShopProduct) => void
  resort: string
}

export const ShopProductSelectionBox: React.FC<Props> = ({ addProduct, products, updateProduct, resort }) => {
  const { addParking } = useShopParking(resort)
  const { watch, getValues, register } = useFormContext<ShopPOSWithParamsFormInputs>()
  const addedProducts = getValues('addedProducts')

  const [paymentCompleted, paymentEnabled] = watch(['paymentCompleted', 'paymentEnabled'])

  const handleAdd = (product: ShopProduct | null) => {
    const alreadyAddedProduct = addedProducts.find(row => row.id === product?.id)

    if (product?.type === 'parking') {
      addParking(addProduct, product)
      return
    }

    if (alreadyAddedProduct) {
      const index = alreadyAddedProduct ? addedProducts.indexOf(alreadyAddedProduct) : -1
      updateProduct(index, { ...alreadyAddedProduct, amount: alreadyAddedProduct.amount + 1 })
    } else {
      addProduct({ ...product, listId: uuidv4(), amount: 1 } as EnhancedShopProduct)
    }
  }

  const onlyAvailable = watch('onlyAvailable') || getValues('onlyAvailable')

  return (
    <div className={classnames('bg-white-smoke p-3 border', { 'not-clickable': paymentCompleted })}>
      {!paymentEnabled && <ShopProductSelectScanner products={products} handleAdd={handleAdd} />}
      <div className="d-flex align-items-end flex-wrap">
        <div className="mr-1">
          <ShopProductSelect
            handleProductAdd={handleAdd}
            products={products.filter(product =>
              onlyAvailable ? !product.in_warehouse || product.available_amount > 0 : true,
            )}
          />
          <FormPlainError name="product" />
        </div>

        <div className="pb-1">
          <CustomInput
            type="checkbox"
            id="onlyAvailable"
            label={<span className="text-semi-strong cursor-pointer">Tylko dostępne produkty</span>}
            {...extractInnerRef(register('onlyAvailable', {}))}
          />
        </div>
      </div>
    </div>
  )
}
