import * as React from 'react'
import { Promocode } from '@models/promocode'
import Table, { TableHeaderValue } from '@components/table/table'
import { useAppDispatch, useAppSelector } from '@store/index'
import { clearPromocodes, promocodeCodesSelector } from '@store/slices/promocodes-slice'
import { getPromocodeCodes } from '@store/actions/promocode-actions'
import { extractSelectOptionsValues } from '@helpers/utils'
import { useTableFilters } from '@components/table/use-table-filters'
import { PromocodeCodesTableFilters } from '@modules/promocode/promocode-list/promocode-codes/promocode-codes-table-filters'
import { PromocodeCodesTableRow } from '@modules/promocode/promocode-list/promocode-codes/row/promocode-codes-table-row'
import { BaseTableFilters } from '@components/table/use-handle-table-filters-change'

export interface PromocodeCodesTableFormInputs extends BaseTableFilters {
  group: string
  is_used: boolean
}

export const headerValues: TableHeaderValue[] = [
  { title: 'Kod' },
  { title: 'Grupa' },
  { title: 'Status' },
  { title: 'Data ważności' },
  { title: '' },
]

interface Props {
  groupId?: string
  search?: string
  additionalFilters?: React.ReactNode
}

export const PromocodeCodesTable = ({ groupId = '', search = '', additionalFilters }: Props) => {
  const defaultFilters: PromocodeCodesTableFormInputs = {
    ordering: '-prefix',
    page: 1,
    page_size: 30,
    search: search,
    group: groupId ?? '',
    is_used: false,
  }

  const dispatch = useAppDispatch()

  const codes = useAppSelector(promocodeCodesSelector)

  const action = React.useCallback(
    async filters => await dispatch(getPromocodeCodes(extractSelectOptionsValues(filters))),
    [],
  )

  const { isLoading, filters, setFilters } = useTableFilters({ defaultFilters, action })

  React.useEffect(() => {
    action(defaultFilters)

    return () => {
      dispatch(clearPromocodes())
    }
  }, [])

  return (
    <>
      <PromocodeCodesTableFilters filters={filters} setFilters={setFilters} defaultFilters={defaultFilters}>
        {additionalFilters}
      </PromocodeCodesTableFilters>
      <Table
        className="table-hover w-100"
        headerValues={headerValues}
        isLoading={isLoading}
        filters={filters}
        setFilters={setFilters}
      >
        {codes.map((code: Promocode) => (
          <PromocodeCodesTableRow code={code} key={code.id} />
        ))}
      </Table>
    </>
  )
}
