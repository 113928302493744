import * as React from 'react'
import Table from '@components/table/table'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { RcpUser } from '@models/rcp'
import { secondsToInterval } from '@helpers/date-helper'

interface RcpUsersTableRowProps {
  user: RcpUser
}

export const RcpUsersTableRow: React.FC<RcpUsersTableRowProps> = ({ user }) => {
  const navigate = useNavigate()
  const handleClick = React.useCallback(() => {
    navigate(NavigationPath.RcpUserEvents.replace(':id', String(user.id)) + `?month=${user.month}&year=${user.season}`)
  }, [user])

  return (
    <Table.Row key={user.id} onClick={handleClick} className="cursor-pointer">
      <Table.Cell className="text-nowrap" style={{ width: 90 }}>
        {user.badge}
      </Table.Cell>
      <Table.Cell>{user.ext_badge}</Table.Cell>
      <Table.Cell>{user.name}</Table.Cell>
      <Table.Cell>{user.city}</Table.Cell>
      <Table.Cell>
        {user.has_errors ? (
          <span className="badge mt-0 mb-0 badge-danger-lighten h5">
            <i className="uil-ban" /> Nie
          </span>
        ) : (
          <span className="badge mt-0 mb-0 badge-success-lighten h5">
            <i className="uil-check-circle" /> Tak
          </span>
        )}
      </Table.Cell>
      <Table.Cell>
        {user.time === null ? (
          <span className="badge badge-danger-lighten h5 mt-0 mb-0">
            <i className="uil-ban" /> Uzupełnij czas
          </span>
        ) : (
          secondsToInterval(user.time)
        )}
      </Table.Cell>
    </Table.Row>
  )
}
