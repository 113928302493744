import * as React from 'react'
import { SubscriptionDetailsInformationRow } from '@modules/subscription/details/information/row'
import { Card, CardBody } from 'reactstrap'
import * as R from 'ramda'
import { InvoiceType } from '@models/promotions'

export interface InvoiceData {
  invoice_nip: string
  invoice_type: InvoiceType
  invoice_company: string
  invoice_street: string
  invoice_postcode: string
  invoice_city: string
}

interface Props {
  invoiceData: InvoiceData
}

export const ProductInvoiceCard: React.FC<Props> = ({ invoiceData }) => {
  if (!invoiceData.invoice_nip) return null

  return (
    <Card>
      <CardBody>
        <h4 className="mt-0 text-secondary mb-3">Dane faktury</h4>
        <table className="table table-sm mb-0">
          <tbody>
            <SubscriptionDetailsInformationRow
              name={invoiceData.invoice_type === 'company' ? 'Nazwa firmy' : 'Imię i nazwisko'}
            >
              {invoiceData.invoice_company}
            </SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="Adres">
              {invoiceData.invoice_street}
            </SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="Kod pocztowy">
              {invoiceData.invoice_postcode}
            </SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="Miasto">
              {invoiceData.invoice_city}
            </SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name={invoiceData.invoice_type === 'company' ? 'NIP' : 'PESEL'}>
              {invoiceData.invoice_nip}
            </SubscriptionDetailsInformationRow>
          </tbody>
        </table>
      </CardBody>
    </Card>
  )
}

export const createInvoiceData = <T extends InvoiceData>(element: T): InvoiceData =>
  R.pick(
    ['invoice_nip', 'invoice_type', 'invoice_company', 'invoice_street', 'invoice_postcode', 'invoice_city'],
    element,
  )
