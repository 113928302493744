import * as React from 'react'
import { FormCheckbox } from '@hyper/forms/form-checkbox'
import { ImprovementIcon } from '@components/improvement-icon'
import classNames from 'classnames'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  className?: string
  id?: string
}

export const HousekeepingCleaningOrderBaseFilters = ({ className, id }: Props): JSX.Element => (
  <div className={classNames('d-flex align-items-center', className)}>
    <FormCheckbox
      id={id}
      name="is_vip"
      label={
        <span>
          Tylko VIP <ImprovementIcon improvementCode="package_vip" />
        </span>
      }
    />
    <FormCheckbox
      name="early_check_in"
      className="ml-3"
      id={id}
      label={
        <div className="d-flex align-items-center">
          <span className="d-block mr-1">Tylko wcześniejsze przyjazdy</span>
          <ImprovementIcon className="font-18 lh-0" improvementCode="early_check_in_13" />
        </div>
      }
    />
    <FormCheckbox
      id={id}
      name="extend_stay"
      className="ml-3"
      label={
        <div className="d-flex align-items-center">
          <span className="d-block mr-1">Tylko późniejsze wymeldowanie</span>
          <ImprovementIcon className="font-18 lh-0" improvementCode="extend_stay_12" />
        </div>
      }
    />

    <FormCheckbox
      id={id}
      name="collision_locals"
      className="ml-3"
      label={
        <IconWithText
          icon="uil-arrows-merge font-22 ml-1 text-danger"
          text="Lokale kolizyjne"
          wrapperClassNames="flex-row-reverse"
        />
      }
    />
  </div>
)
