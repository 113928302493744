import * as React from 'react'
import { TechnicalOrder } from '@models/technical-orders'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { useSelectableRows } from '@components/table/use-selectable-rows'
import { TechnicalOrdersTodoRow } from '@modules/technical-orders/technical-orders-todo/row'
import { Col } from 'reactstrap'
import { TechnicalOrdersTodoOptions } from '@modules/technical-orders/technical-orders-todo/options'
import { useGetTechnicalOrdersQuery } from '@api/technical-orders'
import { TechnicalOrdersTodoFilters } from '@modules/technical-orders/technical-orders-todo/filters'
import { TechnicalOrderTableUnitHeader } from '@modules/technical-orders/common/table-unit-header'
import { TechnicalOrderTableTypeHeader } from '@modules/technical-orders/common/table-type-header'
import { TypedQueryResult } from '@api/base'

interface Props {
  resortId: number
}

const defaultFilters: TableFilters = {
  search: '',
  ordering: 'kind__position,position',
  page: 1,
  page_size: 9999,
}

const defaultEmptyList = []
export const TechnicalOrdersTodo: React.FC<Props> = ({ resortId }) => {
  const [filters, setFilters] = React.useState<TableFilters>(defaultFilters)

  const headers: TableHeaderValue<TechnicalOrder>[] = React.useMemo(
    () =>
      [
        {
          title: 'Numer zadania',
          sortField: 'number',
        },
        {
          title: 'Lokalizacja',
          sortField: 'resort_id',
        },
        {
          title: <TechnicalOrderTableUnitHeader resortId={resortId} setFilters={setFilters} />,
          sortField: '',
        },
        {
          title: <TechnicalOrderTableTypeHeader setFilters={setFilters} />,
          sortField: '',
        },
        {
          title: 'Zgłaszający',
          sortField: 'created_by__name',
        },
        {
          title: 'Najbliższy pobyt',
          sortField: 'unit__next_booking__date_from',
        },
        {
          title: ' ',
        },
        {
          title: '',
          sortField: '',
        },
      ].filter(row => (resortId ? row.sortField !== 'resort_id' : row)),

    [resortId],
  )

  const { data: technicalOrders = defaultEmptyList } = useGetTechnicalOrdersQuery({
    resort: resortId,
    status: 'todo',
    ...filters,
  }) as TypedQueryResult<TechnicalOrder[]>

  const { selectableHeader, selectableCell, clearSelectedRows, selectedRows } = useSelectableRows<TechnicalOrder>(
    technicalOrders,
    [],
    {
      tableHeaderClassName: 'bg-white border-top-0 vertical-align-middle',
      selectableCellClassName: 'vertical-align-middle',
    },
  )

  return (
    <>
      <TechnicalOrdersTodoFilters
        searchClassName="ml-auto"
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
      >
        <Col md={6}>
          <TechnicalOrdersTodoOptions tab="todo" clearSelectedRows={clearSelectedRows} selectedRows={selectedRows} />
        </Col>
      </TechnicalOrdersTodoFilters>
      <Table
        headerClassName="bg-white border-top-0 vertical-align-middle"
        className="table-hover w-100"
        headerValues={headers}
        cols={['3%', '10%', '10%', '20%', '15%', '10%', '1%', '19%']}
        isLoading={false}
        showPagination={false}
        filters={filters}
        setFilters={setFilters}
        selectableHeader={selectableHeader}
      >
        {technicalOrders.map(technicalOrder => (
          <TechnicalOrdersTodoRow key={technicalOrder.id} showResort={!resortId} technicalOrder={technicalOrder}>
            {selectableCell(technicalOrder)}
          </TechnicalOrdersTodoRow>
        ))}
      </Table>
    </>
  )
}
