import * as React from 'react'
import { DownloadButtons } from '@components/downloads-buttons'
import { objectToUrlString } from '@helpers/utils'
import { formatDate } from '@helpers/date-helper'
import { RootState, useAppSelector } from '@store/index'
import { ReceptionCheckType } from '@models/reception'

interface ReceptionDownloadButtonProps {
  ids: number[]
  resortId: number
  type: ReceptionCheckType
  date: Date
}

export const ReceptionDownloadButton: React.FC<ReceptionDownloadButtonProps> = ({ date, ids, resortId, type }) => {
  const booking_export = useAppSelector((state: RootState) => state.appState.appData.urls.reception.booking_export)

  const params = {
    ids: ids.join(','),
    check_status: type,
    resort: resortId,
    booking_date: formatDate(date),
  }

  const getExportLink = (format: 'xls' | 'pdf') => `${booking_export}?format=${format}&${objectToUrlString(params)}`

  return <DownloadButtons disabled={!ids.length} exportPdf={getExportLink('pdf')} exportXls={getExportLink('xls')} />
}
