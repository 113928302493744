import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { CashboxSafeModalActions } from '@modules/payment-registers/cashbox-safe-modal/actions'
import { CashboxSafeModalTable } from '@modules/payment-registers/cashbox-safe-modal/table'
import { CashboxSafeModalWebSocketHandler } from '@modules/payment-registers/cashbox-safe-modal/web-socket-handler'
import { resortsMapSelector } from '@store/selectors/dashboard'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectGet } from '@store/actions/generic-actions'
import { SafeboxStats } from '@models/payments'
import { RootState, useAppSelector } from '@store/index'

interface Props extends BaseModalProps {
  resortId: number
}

export const CashboxSafeModal: React.FC<Props> = ({ toggleIsVisible, resortId }) => {
  const resort = useAppSelector(resortsMapSelector)[resortId]
  const [safeboxStats, setSafeboxStats] = React.useState<SafeboxStats>()
  const url = useAppSelector((state: RootState) => state.appState.appData.urls.payment.safebox_stats)

  const { action: fetch } = useApiRequest(async () => {
    setSafeboxStats(await commonObjectGet<SafeboxStats>(url, { resort: resortId }))
  })

  React.useEffect(() => {
    fetch()
  }, [])

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>Podsumowanie stanu sejfu w {resort?.name}</ModalHeader>
      <ModalBody className="pt-0">
        <CashboxSafeModalWebSocketHandler resortId={resortId} />
        {safeboxStats && <CashboxSafeModalActions resortId={resortId} safeboxStats={safeboxStats} onSuccess={fetch} />}
        <CashboxSafeModalTable resortId={resortId} />
      </ModalBody>
      <ModalFooter className="d-block">
        <Row>
          <Col md={6} className="px-0">
            {/*<ButtonWithIcon icon="uil-import" color="gold-dark" text="Pobierz raport" btnClass="mr-2" />*/}
            {/*<ButtonWithIcon icon="uil-print" color="light" text="Drukuj raport" />*/}
          </Col>

          <Col md={6} className="text-right px-0">
            <button type="button" onClick={toggleIsVisible} className="btn btn-light">
              Zamknij
            </button>
          </Col>
        </Row>
      </ModalFooter>
    </>
  )
}
