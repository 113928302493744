import * as React from 'react'
import { updateUserDetails } from '@store/slices/account-slice'
import { FormProvider, useForm } from 'react-hook-form'
import { Button, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { FormInput } from '@hyper/forms'
import { useFormRequest } from '@components/hooks/use-api-request'
import { commonObjectPut } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAppDispatch } from '@store/index'
import { BaseModalProps } from '@components/modals/types'
import { User } from '@models/account'

interface Props extends BaseModalProps {
  user: User
}

interface FormInputs {
  pin_code: string
}

export const AccountUserSetPinCodeModal: React.FC<Props> = ({ user, toggleIsVisible }) => {
  const methods = useForm<FormInputs>()
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading, action: onSubmit } = useFormRequest(async payload => {
    dispatch(updateUserDetails(await commonObjectPut(user.urls.pin_code, payload)))
    addSuccessMessage('Sukces', payload.pin_code ? 'Kod PIN został ustawiony' : 'Kod PIN został wyłączony')
  }, methods.setError)

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggleIsVisible}>Ustaw kod PIN</ModalHeader>
        <ModalBody className="pb-0">
          <Row>
            <FormInput label="Kod pin" name="pin_code" />
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="light" type="button" onClick={toggleIsVisible}>
            Zamknij
          </Button>

          <SaveButton className="btn btn-green" isSaving={isLoading} />
        </ModalFooter>
      </form>
    </FormProvider>
  )
}
