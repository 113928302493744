import 'core-js/stable'

import '@assets/scss/app.scss'
import '@assets/scss/icons.scss'
import '@iconscout/unicons/css/line.css'

import store from '@store/index'
import * as React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, useLocation } from 'react-router-dom'
import StartupView from './startup'
import { AppRoutes } from '@routes/index'
import { NotificationHandler } from '@hyper/notification-service'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import TopBarProgress from 'react-topbar-progress-indicator'

import axios from 'axios'
import { WebSocketHandler } from '@components/web-socket-handler'
import { ModalComponent } from '@components/modals/modal-component'
import { UserReportsWebsocketHandler } from '@modules/reports/user-reports-websocket-handler'
import * as queryString from 'querystring'
import { formatDate } from '@helpers/date-helper'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useModal } from '@components/modals/use-modal'
import { useUnfinishedShopReceiptCheck } from '@modules/shop/pos/use-unfinished-shop-receipt-check'
import TagManager from 'react-gtm-module'

axios.defaults.headers.get['Accept'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.put['Accept'] = 'application/json'
axios.defaults.headers.patch['Accept'] = 'application/json'
axios.defaults.headers.delete['Accept'] = 'application/json'
axios.defaults.withCredentials = true
axios.interceptors.request.use(config => {
  config.paramsSerializer = params => {
    const payload = {}
    Object.entries(params).map(([key, value]) => {
      if (value instanceof Date) {
        payload[key] = formatDate(value, 'yyyy-MM-dd HH:mm')
      } else {
        payload[key] = value
      }
    })
    return queryString.stringify(payload)
  }

  return config
})

TopBarProgress?.config({
  barThickness: 7,
  barColors: { '0': 'rgba(198, 174, 126, 1)', '1.0': 'rgba(148, 118, 72, 1)' },
  shadowBlur: 5,
})

if (process.env.GOOGLE_TAG_MANAGER) {
  TagManager.initialize({ gtmId: process.env.GOOGLE_TAG_MANAGER })
}

const AppContainer = (props: React.PropsWithChildren<any>): JSX.Element => {
  const user = useAuthenticatedUser()
  const location = useLocation()

  useUnfinishedShopReceiptCheck()

  const [handlePaymentUserCloseDayModal] = useModal('UserCashboxCloseDayModal', {}, { persist: true })
  const [handlePaymentUserOpenDayModal] = useModal('PaymentUserOpenDayModal', {}, { persist: true })

  React.useEffect(() => {
    if (!user.isAuthenticated || !user.isReceptionist) return

    if (user.showPaymentUserCloseDayModal) {
      handlePaymentUserCloseDayModal(null, { paymentDayUserReport: user.paymentDayUserReport, allowClose: false })
    } else if (user.showPaymentUserOpenDayModal) {
      handlePaymentUserOpenDayModal(null, {
        paymentDayUserReport: user.paymentDayUserReport,
        allowClose: false,
      })
    }
  }, [user.isAuthenticated, user.paymentDayUserReport?.id, user.isReceptionist])

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return props.children
}

if (process.env.SENTRY_DASHBOARD_APP_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DASHBOARD_APP_DSN,
    environment: process.env.NODE_ENV,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.3,
  })
}

export default class AppView extends React.Component<{}, {}> {
  public render() {
    return (
      <Provider store={store}>
        <StartupView>
          <BrowserRouter basename="/dashboard/">
            <AppContainer>
              <ModalComponent />
              <WebSocketHandler />
              <UserReportsWebsocketHandler />
              <NotificationHandler />
              <AppRoutes />
            </AppContainer>
          </BrowserRouter>
        </StartupView>
      </Provider>
    )
  }
}
