import * as React from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Button, Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import {
  ReceptionBookingCheckStep,
  ReceptionBookingCheckSteps,
} from '@modules/reception/common/reception-booking-check-steps'
import { SaveButton } from '@hyper/button'
import { BaseModalProps } from '@components/modals/types'
import { Form } from '@hyper/forms/form'
import { ClientUser } from '@models/clients'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { EndlessHolidayFormSummary } from '@modules/endless-holiday/transactions/create/steps/summary/summary'
import { EndlessHolidayFormClientData } from '@modules/endless-holiday/transactions/create/steps/client'
import { EndlessHolidayFormProductData } from '@modules/endless-holiday/transactions/create/steps/products/product'
import { useEndlessHolidayCreate } from '@modules/endless-holiday/transactions/create/use-endless-holiday-create'
import { EndlessHolidayCreateModalSummaryFooter } from '@modules/endless-holiday/transactions/create/modal-footer-summary'
import { InvoiceData } from '@components/products/product-invoice-card'

export interface EndlessHolidayProduct {
  amount: number
  gastro_card: string
  subscription: string
  year: CustomReactSelectOption
  accommodation_type: CustomReactSelectOption
  options: CustomReactSelectOption[]
}

export interface EndlessHolidayCreateFormInputs extends InvoiceData {
  year: CustomReactSelectOption | null
  client: ClientUser | undefined
  seller: CustomReactSelectOption | null
  source_marketing: CustomReactSelectOption | null
  products: EndlessHolidayProduct[]
  product_package_set: CustomReactSelectOption | null
}

const steps: ReceptionBookingCheckStep[] = [
  { step: 1, description: 'Dane klienta', fields: ['product_package_set', 'client', 'source_marketing', 'seller'] },
  { step: 2, description: 'Parametry sprzedaży', fields: ['products'] },
  { step: 3, description: 'Podsumowanie sprzedaży' },
]

export const EndlessHolidayCreateModal: React.FC<BaseModalProps> = ({ toggleIsVisible }) => {
  const [step, setStep] = React.useState(1)
  const methods = useForm<EndlessHolidayCreateFormInputs>({
    defaultValues: { products: [] },
  })

  const { isLoading, create } = useEndlessHolidayCreate(steps, setStep, methods.setError)

  const handleNextStep = () => setStep(curr => curr + 1)
  const handlePrevStep = () => setStep(curr => Math.max(1, curr - 1))

  const [client, selectedPackageSet, products, seller, sourceMarketing] = useWatch({
    control: methods.control,
    name: ['client', 'product_package_set', 'products', 'seller', 'source_marketing'],
  })

  const isNextStepEnabled = () => {
    if (step === 1) return !!selectedPackageSet && !!client && !!seller && !!sourceMarketing
    if (step === 2) return !!products.length && products.every(product => !!product.subscription)
    return true
  }

  return (
    <Form methods={methods} onSubmit={create(toggleIsVisible)}>
      <ModalHeader toggle={toggleIsVisible}>Dodaj Wakacje w nieskończoność</ModalHeader>
      <ReceptionBookingCheckSteps step={step} steps={steps} />
      <ModalBody className="pb-0 px-0">
        {step === 1 && <EndlessHolidayFormClientData initialClient={client as ClientUser} />}
        {step === 2 && <EndlessHolidayFormProductData />}
        {step === 3 && <EndlessHolidayFormSummary />}
      </ModalBody>
      {step > 1 && <EndlessHolidayCreateModalSummaryFooter />}
      <ModalFooter className="d-block">
        <Row>
          <Col md={6} className="p-0">
            {step !== 1 && (
              <Button onClick={handlePrevStep} className="btn btn-light">
                Wstecz
              </Button>
            )}
          </Col>
          <Col md={6} className="text-right p-0">
            {step === 3 ? (
              <SaveButton
                role="submit"
                labelSaving="Proszę czekać..."
                label="Sprzedaj"
                className="btn btn-green"
                isSaving={isLoading}
              />
            ) : (
              <Button
                onClick={handleNextStep}
                className="btn btn-green"
                color="green"
                role="next-step"
                disabled={!isNextStepEnabled()}
              >
                Następny krok
              </Button>
            )}
          </Col>
        </Row>
      </ModalFooter>
    </Form>
  )
}
