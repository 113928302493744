import React from 'react'
import { formatPriceShort, handleEnterPress } from '@helpers/utils'
import { Input } from 'reactstrap'
import { extractInnerRef } from '@helpers/forms'
import { useFormContext, useWatch } from 'react-hook-form'
import { UserPermission } from '@models/dashboard'
import { SaveButton } from '@hyper/button'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { IconWithText } from '@components/icon-with-text'
import classnames from 'classnames'
import { ButtonWithIcon } from '@components/button-with-icon'
import { ReceptionPinPadRepeatablePayments } from '@modules/reception/common/pin-pad/reception-pin-pad-repeatable-payments'
import { PinPadKind } from '@modules/reception/common/pin-pad'

interface FormInputs {
  amount: string
}

interface Props {
  restToPay: string | number
  onSubmit: () => void
  context?: any
  onCancel: () => void
  isSaving: boolean
  isSubmitDisabled?: boolean
  onEserviceSuccess?: () => void
  kind: PinPadKind
  object_id: number
  content_type: number
  isSentToEserviceDisabled?: boolean
  abortLabel?: string
}

export const CardPaymentBox: React.FC<Props> = ({
  restToPay,
  onEserviceSuccess,
  context,
  onSubmit,
  onCancel,
  isSaving,
  object_id,
  content_type,
  kind,
  isSubmitDisabled,
  isSentToEserviceDisabled,
  abortLabel = 'Anuluj',
}) => {
  const [isPaymentRefused, setIsPaymentRefused] = React.useState(false)
  const {
    register,
    setValue,
    control,
    formState: { errors },
  } = useFormContext<FormInputs>()

  const amount = useWatch({ control, name: 'amount' })

  const user = useAuthenticatedUser()

  const isEServiceAvailable = user.hasPerm(UserPermission.EserviceCanUsePinPad) && !!user.eservicePadId

  React.useEffect(() => {
    setValue('amount', String(restToPay))
  }, [restToPay])

  return (
    <>
      <div className="d-flex justify-content-between mb-2 align-items-center">
        <strong className="text-primary font-14">
          <i className="uil-credit-card mr-2" />
          Do zapłaty kartą:
        </strong>
        <strong className="text-strong text-danger font-14">{formatPriceShort(restToPay)}</strong>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <span>Kwota pobrania:</span>
        <Input
          type="number"
          step={0.01}
          autoComplete="off"
          {...extractInnerRef(register('amount'))}
          disabled={isSaving}
          onKeyDown={handleEnterPress.bind(onSubmit)}
          className="w-50 shop-purchase__amount"
          data-testid="amount-input"
        />
      </div>
      {errors.amount && <div className="invalid-feedback d-block mb-1">{errors.amount.message}</div>}
      <div className="reception__payment-box-buttons">
        {isEServiceAvailable ? (
          <ReceptionPinPadRepeatablePayments
            abortLabel={abortLabel}
            amount={amount}
            content_type={content_type}
            context={context}
            isConfirming={isSaving}
            isDisabled={isSentToEserviceDisabled}
            kind={kind}
            object_id={object_id}
            onCancel={onCancel}
            onEserviceSuccess={onEserviceSuccess}
            onPaymentConfirm={onSubmit}
            onPaymentRefuse={() => setIsPaymentRefused(true)}
          />
        ) : (
          <>
            <ButtonWithIcon
              type="button"
              handleClick={onCancel}
              disabled={isPaymentRefused}
              btnClass={classnames('btn btn-light shadow-sm reception__payment-box-buttons--cancel', {
                'opacity-5': isPaymentRefused,
              })}
              icon="uil-multiply"
              text={abortLabel}
            />
            <SaveButton
              type="button"
              onClick={onSubmit}
              isSaving={isSaving}
              disabled={isSubmitDisabled}
              className="btn btn-secondary shadow-sm flex-fill reception__payment-box-buttons--submit"
              label={<IconWithText icon="uil-check" text="Potwierdź płatność" />}
              labelSaving="Zatwierdzanie..."
            />
          </>
        )}
      </div>
    </>
  )
}
