import * as React from 'react'
import { TechnicalOrder } from '@models/technical-orders'
import { TechnicalOrdersTodoRowNextBooking } from '@modules/technical-orders/technical-orders-todo/row-next-booking'
import { TechnicalOrdersTodoRowUnit } from '@modules/technical-orders/technical-orders-todo/row-unit'
import { TechnicalOrdersTodoRowName } from '@modules/technical-orders/technical-orders-todo/row-name'
import { TechnicalOrdersOrderedTableRowOrderedBy } from '@modules/technical-orders/technical-orders-ordered/row-ordered-by'
import { TechnicalOrdersOrderedTableRowNotes } from '@modules/technical-orders/technical-orders-ordered/row-notes'
import { TechnicalOrdersOrderedTableRowActions } from '@modules/technical-orders/technical-orders-ordered/row-actions'
import { TechnicalOrdersTodoRowNumber } from '@modules/technical-orders/technical-orders-todo/row-number'
import { TechnicalOrdersTodoRowResort } from '@modules/technical-orders/technical-orders-todo/row-resort'
import { TechnicalOrdersTodoRowUsers } from '@modules/technical-orders/technical-orders-ordered/row-users'
import { TechnicalOrdersTodoRowUserBy } from '@modules/technical-orders/technical-orders-todo/row-user-by'
import { TechnicalOrdersInProgressTableRowRowDragIndicator } from '@modules/technical-orders/technical-orders-ordered/drag-and-drop/row-drag-indicator'

interface Props {
  technicalOrder: TechnicalOrder
  children?: React.ReactNode
  showResort: boolean
  rowClassName?: string
  showUsers: boolean
  isDragDisabled?: boolean
  showDragHandler: boolean
}

export const TechnicalOrdersOrderedTableRow: React.FC<Props> = ({
  showResort,
  children,
  technicalOrder,
  showUsers,
  isDragDisabled,
  showDragHandler,
}) => (
  <>
    {showDragHandler && (
      <TechnicalOrdersInProgressTableRowRowDragIndicator
        technicalOrder={technicalOrder}
        isDragDisabled={isDragDisabled}
      />
    )}
    {children}
    <TechnicalOrdersTodoRowNumber technicalOrder={technicalOrder} showCreatedAt={false} />
    {showResort && <TechnicalOrdersTodoRowResort technicalOrder={technicalOrder} />}
    <TechnicalOrdersTodoRowUnit technicalOrder={technicalOrder} />
    <TechnicalOrdersTodoRowNextBooking nextBooking={technicalOrder.next_booking} />
    <TechnicalOrdersTodoRowName technicalOrder={technicalOrder} />
    {showUsers && <TechnicalOrdersTodoRowUsers technicalOrder={technicalOrder} />}
    <TechnicalOrdersTodoRowUserBy user={technicalOrder.created_by} booking={technicalOrder.created_by_booking} />
    <TechnicalOrdersOrderedTableRowOrderedBy technicalOrder={technicalOrder} />
    <TechnicalOrdersOrderedTableRowNotes technicalOrder={technicalOrder} />
    <TechnicalOrdersOrderedTableRowActions technicalOrder={technicalOrder} />
  </>
)
