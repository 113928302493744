import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { apiQueryFunction } from '@api/base'
import { RootState } from '@store/index'
import { RodoAcquiringPoint, RodoAgreement, RodoAgreementDetails } from '@modules/crm/rodo-agreements/models'
import { provideListTags } from '@api/query-helper'
import { PaginationResponse } from '@models/dashboard'

export const RODO_AGREEMENT_TAGS = {
  LIST: 'rodoAgreementsList',
  DETAILS: 'rodoAgreementDetails',
}

export const rodoAgreementsApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: '' }),
  reducerPath: 'rodoAgreements',
  tagTypes: [RODO_AGREEMENT_TAGS.DETAILS, RODO_AGREEMENT_TAGS.LIST],
  endpoints: builder => ({
    getRodoAgreements: builder.query<RodoAgreement[], string>({
      queryFn: apiQueryFunction((state: RootState, search: string) => ({
        method: 'GET',
        url: state.appState.appData.urls.rules.rodo_agreements,
        params: { search },
        responseTransformer: (response: PaginationResponse<RodoAgreement>) => response.results,
      })),
      providesTags: provideListTags(RODO_AGREEMENT_TAGS.LIST, RODO_AGREEMENT_TAGS.LIST),
    }),
    getRodoAgreementDetails: builder.query<RodoAgreementDetails, string>({
      providesTags: [RODO_AGREEMENT_TAGS.DETAILS],
      queryFn: apiQueryFunction((_: void, url: string) => ({
        method: 'GET',
        url: url,
      })),
    }),
    updateRodoAgreementClient: builder.mutation<void, RodoAgreement>({
      invalidatesTags: [RODO_AGREEMENT_TAGS.DETAILS, RODO_AGREEMENT_TAGS.LIST],
      queryFn: apiQueryFunction((_, { url, data }: { url: string; data: Partial<RodoAgreement> }) => ({
        method: 'PATCH',
        url,
        data,
      })),
    }),
    transferRodoAgreementBenefit: builder.mutation<void, RodoAgreement>({
      invalidatesTags: [RODO_AGREEMENT_TAGS.DETAILS, RODO_AGREEMENT_TAGS.LIST],
      queryFn: apiQueryFunction((_, agreement: RodoAgreement) => ({
        method: 'POST',
        url: agreement.urls.benefit_transfer,
      })),
    }),
    getRodoAcquiringPoints: builder.query<RodoAcquiringPoint[], string>({
      queryFn: apiQueryFunction((state: RootState, search: string) => ({
        method: 'GET',
        url: state.appState.appData.urls.rules.rodo_agreements,
        params: { search },
        responseTransformer: (response: PaginationResponse<RodoAcquiringPoint>) => response.results,
      })),
      providesTags: provideListTags(RODO_AGREEMENT_TAGS.LIST, RODO_AGREEMENT_TAGS.LIST),
    }),
    createAcquiringPoint: builder.mutation<void, RodoAcquiringPoint>({
      invalidatesTags: [RODO_AGREEMENT_TAGS.LIST],
      queryFn: apiQueryFunction((state: RootState, data: Partial<RodoAcquiringPoint>) => ({
        method: 'POST',
        url: state.appState.appData.urls.rules.rodo_agreements,
        data,
        formData: true,
      })),
    }),
  }),
})

export const {
  useGetRodoAgreementsQuery,
  useGetRodoAgreementDetailsQuery,
  useUpdateRodoAgreementClientMutation,
  useCreateAcquiringPointMutation,
  useGetRodoAcquiringPointsQuery,
  useTransferRodoAgreementBenefitMutation,
} = rodoAgreementsApi
