import * as React from 'react'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { getPackageWholesaleAppData } from '@store/actions/package-wholesale-actions'
import { PackageWholesaleAppData } from '@modules/package-wholesale/models'
import { updatePackageWholesaleAppData } from '@store/slices/package-wholesale-slice'

export const usePackageWholesaleAppData = (): PackageWholesaleAppData => {
  const appData = useAppSelector((state: RootState) => state.packageWholesaleState.appData)
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (appData.status === 'unknown') {
      dispatch(getPackageWholesaleAppData())
      dispatch(updatePackageWholesaleAppData({ ...appData, status: 'loading' }))
    }
  }, [appData.status])

  return appData
}
