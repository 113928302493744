import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import * as React from 'react'
import { NotificationEvents } from '@models/dashboard'
import { addChannels, removeChannels } from '@store/slices/websocket-slice'

type WebSocketCallback<T> = (message: NotificationEvents, payload: T) => void

export function useWebSocket<T>(cb: WebSocketCallback<T>, deps: any[], channels: string[]) {
  const payload = useAppSelector((state: RootState) => state.webSocketState.payload)
  const message = useAppSelector((state: RootState) => state.webSocketState.message)
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    dispatch(addChannels(channels))
    return () => {
      dispatch(removeChannels(channels))
    }
  }, [])

  React.useEffect(() => {
    if (message) {
      cb(message, payload)
    }
  }, [payload, message, ...deps])

  return null
}
