import * as React from 'react'
import { Button, Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { useAppDispatch, useAppSelector } from '@store/index'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { useApiRequest } from '@components/hooks/use-api-request'
import { HistoryBox } from '@modules/promotions/common/history-box'
import { CopyToClipboard } from '@components/copy-to-clipboard'
import { BaseModalProps } from '@components/modals/types'
import { SubscriptionContract } from '@modules/subscription-contract/models'
import { selectSubscriptionContractDetails } from '@store/slices/subscription-contract-slice'
import { SubscriptionContractDetailsTabs } from '@modules/subscription-contract/details/tabs'
import { createProductCardClient, ProductClientCard } from '@components/products/product-client-card'
import { getSubscriptionContractDetails } from '@store/actions/subscription-contract-actions'
import { createInvoiceData, ProductInvoiceCard } from '@components/products/product-invoice-card'
import { SubscriptionContractDetailsInformationBase } from '@modules/subscription-contract/details/information/base'
import { SubscriptionContractDetailsNotes } from '@modules/subscription-contract/details/notes'
import { SubscriptionContractPeriodicRow } from '@modules/subscription-contract/details/payements/periodic-row'
import { ProductEmailNotifications } from '@modules/promotions/common/notification/notifications'
import { usePromotionsAppData } from '@modules/promotions/hooks/use-promotions-app-data'
import { SubscriptionContractDetailsCancel } from '@modules/subscription-contract/details/cancel'
import { SubscriptionContractDetailsFeaturesRow } from '@modules/subscription-contract/details/information/features'
import { SubscriptionContractDetailsResume } from '@modules/subscription-contract/details/resume'
import { getDashboardAppUrl } from '@helpers/utils'
import { NavigationPath } from '@models/routes'

interface SubscriptionDetailsProps extends BaseModalProps {
  subscription: SubscriptionContract
}

export const SubscriptionContractDetailsModal: React.FC<SubscriptionDetailsProps> = ({
  subscription,
  toggleIsVisible,
}) => {
  const subscriptionDetails = useAppSelector(selectSubscriptionContractDetails)

  const notificationsRef = React.useRef<HTMLDivElement>(null)
  const dataRef = React.useRef<HTMLElement>(null)
  const benefitRef = React.useRef<HTMLDivElement>(null)
  const paymentsRef = React.useRef<HTMLDivElement>(null)
  const historyRef = React.useRef<HTMLElement>(null)

  const { subscription_contract_notifications } = usePromotionsAppData()

  const dispatch = useAppDispatch()

  const { isLoading, action: fetch } = useApiRequest(
    async () => await dispatch(getSubscriptionContractDetails(subscription)),
  )

  React.useEffect(() => {
    fetch()
  }, [])

  const copyValue = React.useMemo(
    () =>
      `${getDashboardAppUrl()}${NavigationPath.SubscriptionContractListWithParams.replace(
        ':id',
        String(subscription.id.toString()),
      )}`,
    [subscription],
  )

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>
        Subskrypcja <span className="text-semi-strong">{subscription.number}</span>
        <CopyToClipboard className="uil-share-alt ml-1" value={copyValue} />
      </ModalHeader>
      <ModalBody className="modal-details">
        <LoaderPlaceholder content={!isLoading}>
          {subscriptionDetails && (
            <>
              <div className="modal-details__tabs-wrapper">
                <SubscriptionContractDetailsTabs
                  dataRef={dataRef}
                  benefitRef={benefitRef}
                  paymentsRef={paymentsRef}
                  historyRef={historyRef}
                  notificationRef={notificationsRef}
                />
              </div>

              <Row>
                <Col md={6}>
                  <SubscriptionContractDetailsInformationBase subscriptionDetails={subscriptionDetails} ref={dataRef} />
                </Col>
                <Col md={6}>
                  <ProductClientCard clientData={createProductCardClient(subscriptionDetails.client)} />
                  <ProductInvoiceCard invoiceData={createInvoiceData(subscriptionDetails)} />
                  <SubscriptionContractDetailsNotes subscriptionDetails={subscriptionDetails} />
                </Col>
              </Row>
              <SubscriptionContractDetailsFeaturesRow ref={benefitRef} features={subscriptionDetails.features} />
              <SubscriptionContractPeriodicRow ref={paymentsRef} subscriptionDetails={subscriptionDetails} />
              <Row>
                <Col md={6}>
                  <ProductEmailNotifications
                    onSuccess={fetch}
                    history={subscriptionDetails.notifications}
                    notificationEmails={subscription_contract_notifications}
                    url={subscriptionDetails.urls.notifications}
                    email={subscriptionDetails.client?.email || ''}
                    ref={notificationsRef}
                  />
                </Col>
                <Col md={6}>
                  <HistoryBox history={subscriptionDetails.history} ref={historyRef} />
                </Col>
              </Row>
            </>
          )}
        </LoaderPlaceholder>
      </ModalBody>
      <ModalFooter style={{ display: 'block' }}>
        <Row>
          <Col md={6}>
            {subscriptionDetails && ['initial', 'confirmed'].includes(subscriptionDetails.status) && (
              <SubscriptionContractDetailsCancel
                subscriptionContractDetails={subscriptionDetails}
                toggleIsVisible={toggleIsVisible}
              />
            )}
            {subscriptionDetails && ['cancelled', 'cancelling'].includes(subscriptionDetails?.status) && (
              <SubscriptionContractDetailsResume subscriptionContractDetails={subscriptionDetails} />
            )}
          </Col>
          <Col md={6} className="ml-auto text-right pr-0">
            <Button className="btn btn-light" type="button" onClick={toggleIsVisible}>
              Zamknij
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </>
  )
}
