import * as React from 'react'
import { Button, CustomInput, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import { BadgeWithIcon } from '@components/badges/BadgeWithIcon'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { ApartmentBookingFilter } from '@models/apartment'
import { IconWithText } from '@components/icon-with-text'
import { setActiveFilter } from '@store/actions/timeline-actions'
import { TimelineFilterButtons } from '@modules/reservations/timeline/filters/timeline-filter-buttons'
import { TimelineLocalFilterButtons } from '@modules/reservations/timeline/filters/timeline-local-filter-buttons'

const filters = {
  unavailable: 'Blokada',
  confirmed: 'Potwierdzone',
  confirmedBeforeApFee: 'Potwierdzone do 1.06',
  initial: 'Wstępne',
  checkedin: 'Zameldowane',
  close: 'Rozliczone',
  unfinished: 'Niedokończone',
  extended: 'Przedłużone',
  employee: 'Pracownicze',
  hide_unavailable: 'Ukryte niedostępne lokale',
  show_excluded_from_sale: 'Pokazane lokale wyłączone ze sprzedaży',
  show_temporary_excluded_from_sale: 'Pokazane tymczasowo wyłączone ze sprzedaży',
  only_joined: 'Tylko łączone rezerwacje',
  animals: 'Bez zwierząt',
  with_garden: 'Tylko z ogródkiem',
  without_garden: 'Tylko bez ogródka',
  special_local: 'Specjalne',
}

export const TimelineFiltersModal: React.FC<BaseModalProps> = ({ toggleIsVisible }) => {
  const [newFilters, setNewFilters] = React.useState<ApartmentBookingFilter[]>([])

  const activeFilters = useAppSelector((state: RootState) => state.timelineState.activeFilters)

  const dispatch = useAppDispatch()

  const handleSubmit = () => {
    dispatch(setActiveFilter(newFilters))
    toggleIsVisible()
  }

  const handleClearFilters = () => {
    setNewFilters([])
  }

  const toggleActiveFilter = (filter: ApartmentBookingFilter) => () => {
    if (newFilters.includes(filter)) {
      setNewFilters(newFilters.filter(el => el !== filter))
      return
    }

    setNewFilters(filters => [...filters, filter])
  }

  const isButtonFiltered = (filter: ApartmentBookingFilter) => newFilters.includes(filter)

  React.useEffect(() => {
    setNewFilters(activeFilters)
  }, [activeFilters])

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>Zaawansowane filtrowanie</ModalHeader>
      <ModalBody className="px-0 pb-0">
        <div className="px-2">
          <div className="d-flex justify-content-between">
            <h5 className="text-secondary my-0 font-weight-semibold">Aktywne filtry:</h5>
            {!!newFilters.length && (
              <IconWithText
                icon="uil-times font-14"
                text="Wyczyść filtry"
                textClass="font-12"
                onClick={handleClearFilters}
              />
            )}
          </div>
          <div className="d-flex flex-wrap">
            {!newFilters.length && <span className="mt-2">Brak wybranych filtrów.</span>}
            {newFilters.map((filterKey: ApartmentBookingFilter) => (
              <BadgeWithIcon
                key={filterKey}
                className="d-inline-flex flex-row-reverse p-1 align-items-center text-default mr-2 mt-2 cursor-pointer"
                icon="mdi-close mdi ml-2 font-12"
                title={filters[filterKey]}
                variant="light"
                onClick={toggleActiveFilter(filterKey)}
              />
            ))}
          </div>
        </div>
        <div className="mt-2 p-2 border-top bg-grey-light">
          <div className="mt-2">
            <div className="text-default mb-1 font-weight-bold">Filtruj rezerwacje:</div>
            <TimelineFilterButtons isButtonActive={isButtonFiltered} toggleActiveFilter={toggleActiveFilter} />
          </div>
          <CustomInput
            className="mt-2 mr-4"
            type="checkbox"
            id="only_joined"
            label="Pokaż tylko łączone"
            checked={newFilters.includes('only_joined')}
            onClick={toggleActiveFilter('only_joined')}
          />
        </div>

        <div className="p-2 border-top bg-grey-light">
          <div className="mt-2">
            <strong className="text-default d-block mb-1">Filtruj Lokale:</strong>
            <TimelineLocalFilterButtons
              isButtonActive={isButtonFiltered}
              toggleActiveFilter={toggleActiveFilter}
              isCompact={false}
            />
          </div>
          <div className="d-flex mt-2 flex-wrap">
            <CustomInput
              className="mt-2 mr-4"
              type="checkbox"
              id="show_excluded_from_sale"
              label="Pokaż wyłączone ze sprzedaży"
              checked={newFilters.includes('show_excluded_from_sale')}
              onClick={toggleActiveFilter('show_excluded_from_sale')}
            />
            <CustomInput
              className="mt-2 mr-4"
              type="checkbox"
              id="show_temporary_excluded_from_sale"
              label="Pokaż tymczasowo wyłączone ze sprzedaży"
              checked={newFilters.includes('show_temporary_excluded_from_sale')}
              onClick={toggleActiveFilter('show_temporary_excluded_from_sale')}
            />
            <CustomInput
              className="mt-2 mr-4"
              type="checkbox"
              id="hide_unavailable"
              label="Ukryj niedostępne lokale"
              checked={newFilters.includes('hide_unavailable')}
              onClick={toggleActiveFilter('hide_unavailable')}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="d-block">
        <Row className="justify-content-end">
          <Button onClick={toggleIsVisible} className="btn btn-light mr-2">
            Anuluj
          </Button>
          <Button className="btn btn-green" onClick={handleSubmit}>
            Zastosuj
          </Button>
        </Row>
      </ModalFooter>
    </>
  )
}
