import * as React from 'react'
import { PromocodeTouristVoucher } from '@modules/promotions/promocode-tourist-voucher/models'
import Table from '@components/table/table'
import { formatPriceShort } from '@helpers/utils'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { TableSellerRow } from '@components/table-seller-row'
import { useModal } from '@components/modals/use-modal'
import { Source } from '@models/reports'
import { CopyToClipboard } from '@components/copy-to-clipboard'
import { StatusBadge } from '@modules/promotions/common/status-badge'

interface Props {
  promocodeTouristVoucher: PromocodeTouristVoucher
  sources: Source[]
}

export const PromocodeTouristVoucherTableRow = ({ sources, promocodeTouristVoucher }: Props): JSX.Element => {
  const sourceMarketing = React.useMemo(
    () => sources.find(row => row.id === promocodeTouristVoucher.source_marketing_id)?.name || '',
    [sources, promocodeTouristVoucher.source_marketing_id],
  )

  const [handleClick] = useModal('PromocodeTouristVoucherDetailsDialog', { promocodeTouristVoucher })

  return (
    <Table.Row onClick={handleClick} className="cursor-pointer">
      <Table.Cell>
        {promocodeTouristVoucher.code}{' '}
        <CopyToClipboard className="uil-share-alt ml-1" value={promocodeTouristVoucher.code} />
      </Table.Cell>
      <Table.Cell>{promocodeTouristVoucher.client?.name}</Table.Cell>
      <Table.Cell>
        <StatusBadge element={promocodeTouristVoucher} />
      </Table.Cell>
      <Table.Cell>{formatPriceShort(promocodeTouristVoucher.tourist_voucher_amount)}</Table.Cell>
      <Table.Cell>{sourceMarketing || 'brak'}</Table.Cell>
      <Table.Cell>{toDefaultDateFormat(promocodeTouristVoucher.created_date)}</Table.Cell>
      <Table.Cell>
        <TableSellerRow sellerId={promocodeTouristVoucher.seller_id} defaultValue="" className="d-block" />
      </Table.Cell>
    </Table.Row>
  )
}
