import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { CommonTableFilters } from '@components/table/common-table-filters'
import { FormSelect } from '@hyper/forms/form-select'
import { BookingOptionStatusesOptions } from '@modules/promotions/booking-options/consts'
import { useGroupedSellers } from '@components/hooks/use-grouped-sellers'
import { BookingOptionsFiltersParams } from '@modules/promotions/booking-options/model'
import { ButtonWithIcon } from '@components/button-with-icon'
import { useModal } from '@components/modals/use-modal'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { useGetAvailableClientOptionsQuery } from '@api/booking-options'
import { TypedQueryResult } from '@api/base'
import { ClientOptionPrice } from '@models/clients'
import { createSelectOption } from '@helpers/utils'
import { Row } from 'reactstrap'
import { ColAuto } from '@hyper/col-auto'

interface Props {
  defaultFilters: BookingOptionsFiltersParams
  filters: BookingOptionsFiltersParams
  setFilters: (filters: BookingOptionsFiltersParams) => void
}

const emptyTable = []
export const BookingOptionsTableFilters: React.FC<Props> = ({ filters, setFilters, defaultFilters }) => {
  const [showOptionCreationWizard] = useModal('BookingOptionCreateDialog')
  const methods = useForm<BookingOptionsFiltersParams>({ defaultValues: filters })
  const { data: options = emptyTable } = useGetAvailableClientOptionsQuery() as TypedQueryResult<ClientOptionPrice[]>

  const clientOptions = React.useMemo(
    () => options.map(option => createSelectOption(option.kind_display, option.kind)),
    [options],
  )
  const sellersOption = useGroupedSellers()

  return (
    <FormProvider {...methods}>
      <ButtonWithIcon
        icon="uil-plus"
        text="Dodaj opcję"
        color="green"
        btnClass="ml-auto d-block"
        handleClick={showOptionCreationWizard}
      />
      <Row>
        <ColAuto>
          <Row>
            <DateFromToInput label="Okres" startDateName="created_after" endDateName="created_before" />
          </Row>
        </ColAuto>
        <FormSelect
          options={clientOptions}
          name="option_kinds"
          selectProps={{ isSelectMulti: true, isClearable: true }}
          label="Opcje"
          formPlainProps={{ colStyle: { maxWidth: 500 } }}
        />
      </Row>
      <CommonTableFilters
        defaultFilters={defaultFilters}
        filters={filters}
        setFilters={setFilters}
        searchParams={{ hideLabel: true }}
        clearParams={{ className: 'mt-0 mb-1r' }}
        rowClassName="justify-content-between align-items-end"
        searchWrapperParams={{ className: 'col-3 mb-0' }}
      >
        <div className="d-flex">
          <FormSelect
            options={BookingOptionStatusesOptions}
            label="Statusy"
            name="status"
            selectProps={{ isSelectMulti: true }}
            formPlainProps={{ colStyle: { maxWidth: 300 } }}
          />

          <FormSelect
            options={sellersOption}
            name="seller"
            label="Sprzedawca"
            selectProps={{ isClearable: true }}
            formPlainProps={{ colStyle: { maxWidth: 250 } }}
          />
        </div>
      </CommonTableFilters>
    </FormProvider>
  )
}
