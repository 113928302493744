import * as React from 'react'
import { PaymentDayUserReport } from '@models/payments'
import { useModal } from '@components/modals/use-modal'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'

interface Props {
  report: PaymentDayUserReport
}

export const PaymentRegisterReceptionRowSingleStartAmount = ({ report }: Props): JSX.Element | null => {
  const [handleChangeStartAmount] = useModal('PaymentRegisterReceptionCasketStartModal', {
    report,
  })

  const user = useAuthenticatedUser()
  return user.hasPerm(UserPermission.CashPaymentDayUserReportCanChangeStartAmount) ? (
    <i
      className="uil uil-pen cursor-pointer text-semi-strong font-11 text-secondary ml-1"
      onClick={handleChangeStartAmount}
    />
  ) : null
}
