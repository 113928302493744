import * as React from 'react'
import { ButtonWithIcon } from '@components/button-with-icon'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { CafeteriaDetails } from '@models/cafeteria'
import { createReportTask } from '@store/actions/report-tasks-actions'

interface Props {
  cafeteriaDetails: CafeteriaDetails
}

export const CafeteriaDetailsCodesDownload = ({ cafeteriaDetails }: Props): JSX.Element => {
  const { report_task_cafeteria_code } = useAppSelector((state: RootState) => state.appState.appData.urls.reports)

  const dispatch = useAppDispatch()

  const { isLoading, action: handleClick } = useReportFormRequest(
    async () => await dispatch(createReportTask([report_task_cafeteria_code, { cafeteria: cafeteriaDetails.id }])),
    'notification',
  )

  return (
    <ButtonWithIcon
      isLoading={isLoading}
      handleClick={handleClick}
      icon="uil-download-alt"
      btnSize="sm"
      color="secondary"
      text="Pobierz"
    />
  )
}
