import * as React from 'react'
import { FeedingApartmentOptionsGuest, FeedingSpecialGuestEnter, FeedingWorkerGuest } from '@modules/feeding/models'
import { SaveButton } from '@hyper/button'
import { commonObjectPut } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'

interface FeedingHostGuestsModalRowButtonsProps {
  guest: FeedingSpecialGuestEnter | FeedingWorkerGuest | FeedingApartmentOptionsGuest
}

export const FeedingHostGuestsModalRowButtons: React.FC<FeedingHostGuestsModalRowButtonsProps> = ({ guest }) => {
  const [isCouponSaving, setIsCouponSaving] = React.useState(false)
  const [isBandSaving, setIsBandSaving] = React.useState(false)
  const [bandIssue, setBandIssue] = React.useState(guest.enter?.band)
  const [couponIssue, setCouponIssue] = React.useState(guest.enter?.coupon)
  const { addSuccessNotification } = useNotificationHook()

  const process = async (payload, message) => {
    if (guest.enter) {
      await commonObjectPut(guest.enter.urls.details, payload)
      addSuccessNotification(message)
    }
  }

  const handleCouponClick = async () => {
    setIsCouponSaving(true)
    await process({ coupon: true }, 'Kupon został wydany')
    setIsCouponSaving(false)
    setCouponIssue(true)
  }

  const handleBandClick = async () => {
    setIsBandSaving(true)
    await process({ band: true }, 'Opaska została wydana')
    setIsBandSaving(false)
    setBandIssue(true)
  }

  return (
    <td className="text-nowrap">
      {bandIssue ? (
        <button className="btn-dark btn-sm mr-1 btn" disabled={true}>
          Opaska wydana
        </button>
      ) : (
        <SaveButton
          onClick={handleBandClick}
          isSaving={isBandSaving}
          disabled={!guest.enter?.enter}
          className="btn-dark btn-xs mr-1 btn"
          label="Wydaj opaskę"
          labelSaving="Wydawanie"
        />
      )}
      {couponIssue ? (
        <button className="btn-dark btn-sm mr-1 btn" disabled={true}>
          Kupon wydany
        </button>
      ) : (
        <SaveButton
          onClick={handleCouponClick}
          isSaving={isCouponSaving}
          disabled={!guest.enter?.enter}
          className="btn-light btn-xs btn"
          label="Wydaj kupon"
          labelSaving="Wydawanie"
        />
      )}
    </td>
  )
}
