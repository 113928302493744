import * as React from 'react'
import { useAppDispatch, useAppSelector } from '@store/index'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { Card, CardBody } from 'reactstrap'
import { PackageWholesalePackagesFilterButtons } from '@modules/package-wholesale/packages/filter-buttons'
import { GastroCardsFiltersParams } from '@modules/promotions/gastro-cards/models'
import { clearGastroCards, gastroCardsSelector } from '@store/slices/gastro-card-slice'
import { GastroCardWebSocketHandler } from '@modules/promotions/gastro-cards/gastro-card-web-socket-handler'
import { GastroCardsTable } from '@modules/promotions/gastro-cards/gastro-cards-table'
import { GastroCardsFilters } from '@modules/promotions/gastro-cards/gastro-cards-filters'
import { GastroCardsExportButtons } from '@modules/promotions/gastro-cards/gastro-cards-export-buttons'
import { useSelectableRows } from '@components/table/use-selectable-rows'
import { GastroCard, GastroCardKind } from '@models/promotions'
import { useTableFilters } from '@components/table/use-table-filters'
import { useAppData } from '@components/hooks/use-app-data'
import { isGastroGenericProduct } from '@modules/promotions/gastro-cards/utils'
import { getGastroProductApiUrl } from '@modules/promotions/gastro-generic-product/utils'
import { GastroProductKind } from '@models/products'
import { getGastroCards } from '@store/actions/gastro-card-actions'
import { PageTitleWithSize } from '@hyper/page-title-with-size'

interface Props {
  kind: GastroCardKind
  name: string
}

export const PackageWholesaleGastroCardsView = ({ kind, name }: Props): JSX.Element => {
  const defaultFilters: GastroCardsFiltersParams = {
    is_removed: false,
    ordering: '-sell_date',
    page: 1,
    page_size: 10,
    source_marketing: undefined,
    search: '',
    payment_date_after: undefined,
    payment_date_before: undefined,
    sell_date_after: undefined,
    sell_date_before: undefined,
    seller: undefined,
    status: [],
    kind: kind,
  }
  const { urls } = useAppData()

  const gastroCardsUrl = isGastroGenericProduct(kind)
    ? getGastroProductApiUrl(kind as GastroProductKind, urls)
    : urls.promotions.gastro_cards

  const dispatch = useAppDispatch()

  const allGastroCards = useAppSelector(gastroCardsSelector)

  useMenuActive(NavigationPath.PackageWholesaleList)

  const { isLoading, filters, setFilters, updateFiltersRequest } = useTableFilters<GastroCardsFiltersParams>({
    defaultFilters,
    action: async filters => await dispatch(getGastroCards([filters, gastroCardsUrl])),
  })

  const gastroCards = React.useMemo(
    () =>
      allGastroCards.filter(row =>
        filters.status?.length ? filters.status?.some(status => status.value === row.status) : true,
      ),
    [allGastroCards],
  )

  const fetchGastroCards = () => {
    updateFiltersRequest(filters)
  }

  React.useEffect(
    () => () => {
      dispatch(clearGastroCards())
    },
    [],
  )

  const { selectableHeader, selectableCell, selectedRows, clearSelectedRows } =
    useSelectableRows<GastroCard>(gastroCards)

  const path = NavigationPaths[kind] ?? { basePath: '', pathWithParams: '' }

  return (
    <>
      <PageTitleWithSize title={`Lista sprzedanych pakietów ${name}`} />
      <Card>
        <GastroCardWebSocketHandler kind={kind} />
        <CardBody>
          <GastroCardsFilters
            kind={kind}
            defaultFilters={defaultFilters}
            filters={filters}
            setFilters={setFilters}
            fetchGastroCards={fetchGastroCards}
            selectedRows={selectedRows}
            clearSelectedRows={clearSelectedRows}
          >
            <PackageWholesalePackagesFilterButtons />
          </GastroCardsFilters>

          <div data-testid="qa-gastro-voucher-table">
            <GastroCardsTable
              selectableHeader={selectableHeader}
              selectableCell={selectableCell}
              gastroCards={gastroCards}
              isLoading={isLoading}
              filters={filters}
              setFilters={setFilters}
              basePath={path.basePath}
              pathWithParams={path.pathWithParams}
            />
            <GastroCardsExportButtons filters={filters} kind={kind} />
          </div>
        </CardBody>
      </Card>
    </>
  )
}

const NavigationPaths = {
  wholesale: {
    basePath: NavigationPath.PackageWholesaleGastroCards,
    pathWithParams: NavigationPath.PackageWholesaleGastroCardsWithParams,
  },
  gastro_card_boost_wholesale: {
    basePath: NavigationPath.PackageWholesaleGastroCardsBoost,
    pathWithParams: NavigationPath.PackageWholesaleGastroCardsBoostWithParams,
  },
  gastro_card_2024_wholesale: {
    basePath: NavigationPath.PackageWholesaleGastroCards2024,
    pathWithParams: NavigationPath.PackageWholesaleGastroCards2024WithParams,
  },
  gastro_card_2025_wholesale: {
    basePath: NavigationPath.PackageWholesaleGastroCards2025,
    pathWithParams: NavigationPath.PackageWholesaleGastroCards2025WithParams,
  },
}
