import {
  APARTMENT_40_ACCOMMODATION_TYPE,
  APARTMENT_40_WITH_GARDEN_ACCOMMODATION_TYPE,
  HOUSE_85_ACCOMMODATION_TYPE,
  HOUSE_85_WITH_GARDEN_ACCOMMODATION_TYPE,
} from '@helpers/consts'
import { AccommodationType, Resort } from '@models/booking'

export interface GroupedAccommodationType {
  id: string
  name: string
  accommodationIds: number[]
}

interface Response {
  groupedAccommodationTypes: GroupedAccommodationType[]
}

export const useGroupedAccommodationTypes = (activeResort: Resort | undefined): Response => {
  const groupedAccommodationTypes = (activeResort?.accommodation_types ?? []).reduce(
    (prev: GroupedAccommodationType[], accommodationType: AccommodationType) => {
      const houses85 = [HOUSE_85_ACCOMMODATION_TYPE, HOUSE_85_WITH_GARDEN_ACCOMMODATION_TYPE]
      const apartments40 = [APARTMENT_40_ACCOMMODATION_TYPE, APARTMENT_40_WITH_GARDEN_ACCOMMODATION_TYPE]

      const getUpdatedTab = (name: string) => {
        const alreadyAddedTab = prev.find(tab => tab.name === name)

        if (alreadyAddedTab) {
          return prev.map(tab =>
            tab.name == name
              ? {
                  ...tab,
                  accommodationIds: [...tab.accommodationIds, accommodationType.id],
                  id: `${tab.id}-${accommodationType.id}`,
                }
              : tab,
          )
        } else {
          return [...prev, { name: name, accommodationIds: [accommodationType.id], id: `${accommodationType.id}` }]
        }
      }

      if (houses85.includes(accommodationType.id)) return getUpdatedTab('Domek 85m²')
      if (apartments40.includes(accommodationType.id)) return getUpdatedTab('Apartament 40m²')

      return getUpdatedTab(accommodationType.name)
    },
    [],
  )

  return {
    groupedAccommodationTypes,
  }
}
