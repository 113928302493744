import * as React from 'react'
import { Card, CardBody } from 'reactstrap'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { PageTitleWithSize } from '@hyper/page-title-with-size'
import { BookingOptionsTable } from '@modules/promotions/booking-options/table/booking-options-table'
import { BookingOption, BookingOptionsFiltersParams } from '@modules/promotions/booking-options/model'
import { TypedQueryResult } from '@api/base'
import { BookingOptionStatusesOptions } from '@modules/promotions/booking-options/consts'
import { BookingOptionsTableFilters } from '@modules/promotions/booking-options/table/booking-options-table-filters'
import { useGetBookingOptionsQuery } from '@api/booking-options'
import { BookingOptionsDetailsView } from '@modules/promotions/booking-options/details'
import { useParams } from 'react-router-dom'

const defaultFilters: BookingOptionsFiltersParams = {
  ordering: '-invoice',
  page: 1,
  page_size: 10,
  search: '',
  status: BookingOptionStatusesOptions.slice(0, 2),
  seller: null,
  option_kinds: [],
}

const emptyList = []

export const BookingOptionsView: React.FC = () => {
  const { id = '' } = useParams<'id'>()
  const [filters, setFilters] = React.useState<BookingOptionsFiltersParams>(defaultFilters)
  useMenuActive(NavigationPath.BookingOptions)

  const { data = emptyList, isFetching } = useGetBookingOptionsQuery(filters) as TypedQueryResult<BookingOption[]>

  return (
    <>
      <PageTitleWithSize title="Opcje do rezerwacji" />
      <Card>
        <CardBody>
          {id && <BookingOptionsDetailsView id={id} />}
          <BookingOptionsTableFilters setFilters={setFilters} filters={filters} defaultFilters={defaultFilters} />
          <BookingOptionsTable
            showPagination={true}
            options={data}
            isLoading={isFetching}
            filters={filters}
            setFilters={setFilters}
          />
        </CardBody>
      </Card>
    </>
  )
}
