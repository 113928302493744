import { Agreement } from '@modules/hr/agreement/models'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'

interface Response {
  canDownload: boolean
}
export const useAgreementDownloadCheck = (agreement: Agreement): Response => {
  const user = useAuthenticatedUser()
  const isPolishEmployeeWorker = agreement.nationality === 'PL'

  const hasDraftStatus = ['draft', 'draft_incomplete'].includes(agreement.status)
  const hasPolishFilledAgreementStatus = ['active', 'completed'].includes(agreement.status)
  const hasForeignerFilledAgreementStatus = ['verified', 'active', 'completed'].includes(agreement.status)

  const isDownloadAvailable = () => {
    if (!user.hasPerm(UserPermission.HrAgreementCanDownload)) {
      return false
    }

    if (user.hasPerm(UserPermission.HrAgreementCanDownloadForAnyNationForAnyStatus)) {
      return true
    }

    if (hasDraftStatus) {
      return user.hasPerm(UserPermission.HrAgreementCanDownloadInStatusDraft)
    }

    return isPolishEmployeeWorker ? hasPolishFilledAgreementStatus : hasForeignerFilledAgreementStatus
  }

  return {
    canDownload: isDownloadAvailable(),
  }
}
