import declination from '@helpers/declination'
import IconJournalText from '@components/icons/journal-text'
import * as React from 'react'
import classNames from 'classnames'
import { BookingStatus } from '@models/booking'
import { ReceptionBookingDetails } from '@models/reception'
import { KindOptions } from '@modules/reservations/blockade/models'

interface Props {
  details: ReceptionBookingDetails
  status: BookingStatus
  numberOfNights: number
  reformatDate: (date: string) => string
}

const TimelineItemTooltipBlockadeDetails: React.FC<Props> = ({ details, status, reformatDate, numberOfNights }) => {
  const bookingStatusMapper = (): string => {
    switch (status) {
      case 'system':
      case 'fake':
      case 'blocked':
        return 'Niedostępny'
      default:
        return ''
    }
  }

  const blockadeKindLabel = KindOptions.find(option => option.value === details.kind)?.label

  return (
    <>
      <div className="calendar-tooltip__upper">
        <header className="calendar-tooltip__el">
          <strong>{details.reservation_number} </strong>
          Blokada {blockadeKindLabel ? `(${blockadeKindLabel})` : ''}
        </header>
        <div
          className={classNames(
            `calendar-tooltip__status justify-content-between align-items-center d-flex is-${status}`,
          )}
        >
          <span>{bookingStatusMapper()}</span>
        </div>
        <div className="calendar-tooltip__el">
          {reformatDate(details.date_from)} - {reformatDate(details.date_to)} ({numberOfNights}{' '}
          {declination.night(numberOfNights)})
        </div>
      </div>
      <div className="calendar-tooltip__lower">
        <div className="calendar-tooltip__el justify-content-between align-items-center d-flex">
          <span>Notatka:</span>
          <IconJournalText classNames={classNames('calendar-tooltip__icon', { 'is-darker': !details.notes.length })} />
        </div>
      </div>
    </>
  )
}

export default TimelineItemTooltipBlockadeDetails
