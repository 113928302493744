import * as React from 'react'
import { PageTitle } from '@hyper/page-title'
import { noop, useDocumentTitle } from '@helpers/utils'
import { NavigationPath } from '@models/routes'
import { Card, CardBody } from 'reactstrap'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { BookingStats, BookingStatsParams } from '@modules/reservations/models'
import { formatDate } from '@helpers/date-helper'
import { addDays, startOfToday, subDays } from 'date-fns'
import { commonObjectGet } from '@store/actions/generic-actions'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { createReportTask } from '@store/actions/report-tasks-actions'
import { ButtonWithIcon } from '@components/button-with-icon'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { UserPermission } from '@models/dashboard'
import { CommonStatsBoxDetails } from '@modules/reservations/statistics/common/common-stats-box-details'
import { ReservationStatsTable } from '@modules/reservations/statistics/booking-stats/stats-details/stats-details-table/reservation-stats-table'
import { useTimeCounter } from '@components/hooks/use-time-counter'

const initialDates = {
  date_after: subDays(startOfToday(), 3),
  date_before: addDays(startOfToday(), 14),
}

const REFRESH_INTERVAL_IN_SECONDS = 60
const pageTitle = 'Statystyki'

const breadCrumbItems = [
  {
    label: 'Rezerwacje',
    path: NavigationPath.ReservationList,
    active: false,
  },
  { label: pageTitle, path: '', active: true },
]

export const ReservationStatsView: React.FC = () => {
  const [stats, setStats] = React.useState<BookingStats | undefined>(undefined)
  const [params, setParams] = React.useState<Partial<BookingStatsParams>>({})
  const dispatch = useAppDispatch()

  useDocumentTitle(pageTitle)
  useMenuActive(NavigationPath.ReservationStats)

  const url = useAppSelector((state: RootState) => state.appState.appData.urls.rent.booking_stats)
  const reportUrl = useAppSelector(
    (state: RootState) => state.appState.appData.urls.reports.report_task_reservation_stats,
  )

  const { isLoading, action: fetchStats } = useApiRequest(async (data: Partial<BookingStatsParams> = {}) => {
    stopCounter()
    try {
      const params: Partial<BookingStatsParams> = {
        ...data,
        date_after: formatDate(data.date_after) || formatDate(initialDates.date_after),
        date_before: formatDate(data.date_before) || formatDate(initialDates.date_before),
      }

      setStats(await commonObjectGet<BookingStats>(url, params))
    } finally {
      clearCounter()
      startCounter()
    }
  })

  const { Counter, startCounter, stopCounter, clearCounter } = useTimeCounter({
    runOnInit: true,
    actionSecond: REFRESH_INTERVAL_IN_SECONDS,
    onTimeElapsed: () => {
      fetchStats(params)
    },
  })

  const { action: handleReportClick } = useReportFormRequest(
    async () => ({
      payload: await dispatch(
        createReportTask([
          reportUrl,
          {
            ...params,
            date_after: params.date_after || initialDates.date_after,
            date_before: params.date_before || initialDates.date_before,
          },
        ]),
      ),
    }),
    noop,
    'notification',
  )

  React.useEffect(() => {
    fetchStats()
  }, [])

  const handleDetailsFilter = (params: Partial<BookingStatsParams>) => {
    fetchStats(params)
    setParams(params)
  }

  return (
    <>
      <PageTitle breadCrumbItems={breadCrumbItems} title={pageTitle} />
      <Card className="h-100">
        <CardBody className="d-flex flex-column">
          <LoaderPlaceholder content={stats}>
            {stats && (
              <>
                <strong className="mb-2 d-block text-secondary font-18">Rezerwacje szczegółowo:</strong>
                <CommonStatsBoxDetails
                  onFilter={handleDetailsFilter}
                  isLoading={isLoading}
                  resortViewPermission={UserPermission.BookingStatisticsCanViewAllResorts}
                  initialDates={initialDates}
                >
                  <ReservationStatsTable stats={stats} />
                </CommonStatsBoxDetails>
                <Counter className="time-counter__statistics" />
                <p className="text-right">
                  <ButtonWithIcon
                    icon="uil-download-alt mr-1"
                    color="secondary"
                    text="Pobierz"
                    handleClick={handleReportClick}
                  />
                </p>
              </>
            )}
          </LoaderPlaceholder>
        </CardBody>
      </Card>
    </>
  )
}
