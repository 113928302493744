import { createSelector } from 'reselect'
import { RootState } from '@store/index'
import * as R from 'ramda'
import { ReceptionBookingDetails } from '@models/reception'

export type ImprovementsNameMap = {
  [key: string]: string
}

export const selectResortDetails = (state: RootState) => state.receptionState.resortDetails

export const improvementsNameMapSelector = createSelector(
  selectResortDetails,
  (resortDetails): ImprovementsNameMap =>
    R.reduce(
      (acc, value) => {
        acc[value.code] = value.name

        return acc
      },
      {},
      resortDetails?.improvements || [],
    ),
)

export const receptionBookingDetailsSelector = createSelector(
  (state: RootState) => state.receptionState.receptionBookingDetails,
  (details: ReceptionBookingDetails) => details,
)
