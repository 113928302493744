import * as React from 'react'
import { ClientDetails } from '@models/clients'

interface Props {
  client: ClientDetails
}

export const ClientDetailsModalClientProfileCompanyDataTable: React.FC<Props> = ({ client }) => {
  if (!client.profile.invoice) {
    return <strong className="text-center d-block mt-1">Nie wybrano faktury</strong>
  }

  return (
    <table className="table table-sm mb-2">
      <colgroup>
        <col width="40%" />
        <col width="60%" />
      </colgroup>

      <tbody>
        <tr>
          <td>{client.profile.invoice_type === 'company' ? 'NIP:' : 'PESEL:'}</td>
          <th className="text-semi-strong">{client.profile.invoice_nip}</th>
        </tr>
        <tr>
          <td>{client.profile.invoice_type === 'company' ? 'Nawa firmy:' : 'Imię i nazwisko:'}</td>
          <th className="text-semi-strong">{client.profile.invoice_company}</th>
        </tr>
        <tr>
          <td>Adres:</td>
          <th className="text-semi-strong">
            {client.profile.invoice_street} <br /> {client.profile.invoice_postcode} {client.profile.invoice_city}{' '}
            {client.profile.invoice_country_display}
          </th>
        </tr>
      </tbody>
    </table>
  )
}
