import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { BaseTableFilters } from '@components/table/use-handle-table-filters-change'
import { CommonTableFilters, CommonTableFiltersProps } from '@components/table/common-table-filters'

export type FloorHeatingListFilterParams = BaseTableFilters

type Props = CommonTableFiltersProps<FloorHeatingListFilterParams>

export const FloorHeatingListFilter: React.FC<Props> = ({ defaultFilters, filters, setFilters }) => {
  const methods = useForm<FloorHeatingListFilterParams>({ defaultValues: defaultFilters })

  return (
    <FormProvider {...methods}>
      <CommonTableFilters
        defaultFilters={defaultFilters}
        filters={filters}
        setFilters={setFilters}
        rowClassName="justify-content-end"
      />
    </FormProvider>
  )
}
