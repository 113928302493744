import * as React from 'react'
import { ReceptionRowDates } from '@modules/reception/common/reception-row-dates'
import { BandReaderSelect } from '@modules/reception/common/band-reader-select'
import { useTimeoutWhen } from 'rooks'
import { runBandReader } from '@store/actions/bands-actions'
import { BandReaderResult, ReceptionBooking } from '@models/reception'
import { ReceptionBookingGuest } from '@models/booking'
import classNames from 'classnames'
import { v4 as uuidv4 } from 'uuid'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useApiRequest } from '@components/hooks/use-api-request'
import { BandsWebSocketHandler } from '@components/band-scanning/web-socket-handler'

const SCANNING_TIMOUT_MS = 15_000

export enum ScanningStatus {
  SCANNING = 'SCANNING',
  FOUND = 'FOUND',
  NOT_FOUND = 'NOT_FOUND',
  TIMEOUT = 'TIMEOUT',
}

export interface BandReaderData {
  receptionBookingGuest: ReceptionBookingGuest
  booking: ReceptionBooking
}

interface BandScanningProps {
  onBandReaderDataChange: (resultData: BandReaderData) => void
  bandReaderData: BandReaderData | null
}

export const BandScanning: React.FC<BandScanningProps> = ({ onBandReaderDataChange, bandReaderData }) => {
  const [scanningStatus, setScanningStatus] = React.useState(ScanningStatus.SCANNING)
  const [requestedId, setRequestedId] = React.useState<null | string>(null)

  const { bandReaderId } = useAuthenticatedUser()

  const handleScanningResult = (bandReaderResult: BandReaderResult) => {
    if (bandReaderResult.result === 'NOT_FOUND' || !bandReaderResult.booking) {
      setScanningStatus(ScanningStatus.NOT_FOUND)
      return
    }

    const readerData = {
      receptionBookingGuest: bandReaderResult.booking_guests[0],
      booking: bandReaderResult.booking,
    }

    onBandReaderDataChange(readerData)
    setScanningStatus(ScanningStatus.FOUND)
  }

  const { action: scanBands } = useApiRequest(async (uuid: string) => {
    setRequestedId(uuid)
    setScanningStatus(ScanningStatus.SCANNING)
    await runBandReader({ reader: parseInt(String(bandReaderId), 10), uuid })
  })

  useTimeoutWhen(
    () => {
      setScanningStatus(ScanningStatus.TIMEOUT)
    },
    SCANNING_TIMOUT_MS,
    scanningStatus === ScanningStatus.SCANNING,
  )

  React.useEffect(() => {
    if (bandReaderId) {
      scanBands(uuidv4())
    }
  }, [bandReaderId])

  return (
    <>
      <BandsWebSocketHandler requestedId={requestedId} onScanningResultMessage={handleScanningResult} />
      <div className="d-flex align-items-center">
        {scanningStatus === ScanningStatus.SCANNING && <Message icon="uil-capture" title="Skanuję opaskę" />}
        {scanningStatus === ScanningStatus.TIMEOUT && (
          <Message icon="uil-times" title="Problem z połączeniem z czytnikiem" />
        )}
        {scanningStatus === ScanningStatus.FOUND && !!bandReaderData && (
          <>
            <BandIcon icon="uil-check" />
            <div className="ml-3 w-50">
              <DataRow name="Numer lokalu:" value={bandReaderData.booking.apartment.name} />
              <DataRow name="Imię i nazwisko:" value={bandReaderData.receptionBookingGuest.name} />
              <DataRow name="Data pobytu:" value={<ReceptionRowDates booking={bandReaderData.booking} />} />
            </div>
          </>
        )}
        {scanningStatus === ScanningStatus.NOT_FOUND && <Message icon="uil-times" title="Nie znaleziono opaski" />}
      </div>
      <div className="mt-3 text-right">
        <BandReaderSelect />
      </div>
    </>
  )
}

const Message = ({ icon, title }) => (
  <>
    <BandIcon icon={icon} />
    <span className="d-block text-muted font-weight-bold font-14 ml-3">{title}</span>
  </>
)

const DataRow = ({ name, value }) => (
  <div className="mb-2">
    <span className="d-block">{name}</span>
    <span className="d-block font-weight-bold my-1">{value}</span>
  </div>
)

const BandIcon = ({ icon }) => (
  <div className="position-relative">
    <i className="icon-opaska text-success reception__band-reader__external-icon" />
    <i className={classNames('reception__band-reader__internal-icon', icon)} />
  </div>
)
