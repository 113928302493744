import * as React from 'react'
import { PromocodeGroup } from '@models/promocode'
import { useAppDispatch, useAppSelector } from '@store/index'
import { deletePromocodeGroup } from '@store/actions/promocode-actions'
import { Button, Col, CustomInput, Label, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { FormInput, FormPlain } from '@hyper/forms'
import { useForm } from 'react-hook-form'
import { parseISODate } from '@helpers/date-helper'
import { SaveButton } from '@hyper/button'
import DatePickerInput from '@components/date/date-picker-input'
import { extractInnerRef } from '@helpers/forms'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useFormRequest } from '@components/hooks/use-api-request'
import { ButtonWithIcon } from '@components/button-with-icon'
import { Form } from '@hyper/forms/form'
import { BaseModalProps } from '@components/modals/types'
import { useModal } from '@components/modals/use-modal'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { promocodePrefixesSelector, updatePromocodeGroupDetails } from '@store/slices/promocodes-slice'
import { commonObjectPut } from '@store/actions/generic-actions'

interface Props extends BaseModalProps {
  promocodeGroup: PromocodeGroup
}

interface FormInputs {
  prefix: string
  name: string
  description: string
  valid_forever: boolean
  expire_after: string | Date | null
}

export const PromocodeGroupEditDialog: React.FC<Props> = ({ promocodeGroup, toggleIsVisible }) => {
  const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()

  const prefix = useAppSelector(promocodePrefixesSelector).find(p => p.id === promocodeGroup.prefix_id)

  const methods = useForm<FormInputs>({
    defaultValues: {
      expire_after: promocodeGroup.expire_after
        ? parseISODate(promocodeGroup.expire_after)
        : promocodeGroup.expire_after,
      valid_forever: !promocodeGroup.expire_after,
      description: promocodeGroup.description,
      name: promocodeGroup.name,
      prefix: prefix?.name,
    },
  })
  const validForever = methods.watch('valid_forever')

  const handleRemove = () => {
    dispatch(deletePromocodeGroup(promocodeGroup))
  }

  const [handleGenerateNewCodes] = useModal('PromocodeGroupGenerateCodesDialog', { promocodeGroup }, { persist: true })

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    dispatch(updatePromocodeGroupDetails(await commonObjectPut<PromocodeGroup>(promocodeGroup.urls.details, payload)))
    addSuccessMessage('Sukces', 'Grupa kodów została zmieniona!')
    toggleIsVisible()
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Edytuj grupę</ModalHeader>
      <ModalBody>
        <Row>
          <FormInput readOnly={true} colSize={6} label="Prefiks" name="prefix" />

          <FormInput colSize={6} label="Nazwa grupy" name="name" />

          <FormPlain colSize={6} name="expire_after">
            <Label>Ważny do</Label>
            <DatePickerInput
              name="expire_after"
              wrapperClassName="flex-grow-1"
              inputGroupClassName={validForever ? 'disabled' : ''}
              isDisabled={validForever}
            />
          </FormPlain>

          <FormPlain colSize={6} name="valid_forever" errors={{}}>
            <label className="d-block mt-1">&nbsp;</label>
            <CustomInput
              className="mt-2"
              type="checkbox"
              id="valid_forever"
              label="Bezterminowo"
              {...extractInnerRef(methods.register('valid_forever'))}
            />
          </FormPlain>
        </Row>

        <Row>
          <Col sm={12} className="p-0">
            <div className="hr hr-grey mb-3" />
          </Col>
        </Row>

        {!promocodeGroup.only_one_code && (
          <Row>
            <Col md={6} className="text-strong mb-2 pt-1">
              Pozostała liczba kodów:
            </Col>
            <Col md={6} className="mb-2">
              <strong>
                {promocodeGroup.codes_amount - promocodeGroup.codes_amount_used} z {promocodeGroup.codes_amount}
              </strong>

              <Button onClick={handleGenerateNewCodes} className="btn btn-primary ml-3" color="primary" type="button">
                Generuj dodatkowe kody
              </Button>
            </Col>
            <FormInput type="textarea" label="Opis" name="description" inputProps={{ rows: 4 }} />
          </Row>
        )}
      </ModalBody>

      <ModalFooter style={{ display: 'block' }}>
        <Row>
          <Col md={6}>
            <CommonObjectConfirmAction isLoading={false} handleAccept={handleRemove}>
              <ButtonWithIcon icon="uil-trash-alt mr-1" color="light" text="Usuń" />
            </CommonObjectConfirmAction>
          </Col>
          <Col md={6} className="text-right">
            <Button color="light" type="button" onClick={toggleIsVisible} className="mr-1">
              Anuluj
            </Button>
            <SaveButton className="btn btn-green" isSaving={isLoading} label="Zapisz" labelSaving="Zapisywanie.." />
          </Col>
        </Row>
      </ModalFooter>
    </Form>
  )
}
