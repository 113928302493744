import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { ReceptionBookingDetails } from '@models/reception'
import { Button } from 'reactstrap'
import { ReceptionBookingCheckInPreviewPersonalDataSignature } from '@modules/reception/checkin/preview/personal-data/reception-booking-check-in-preview-personal-data-signature'
import classnames from 'classnames'

interface Props {
  booking: ReceptionBookingDetails
  isSignatureResetting: boolean
  isSignatureRemoving: boolean
  onSignatureReset: () => void
  onSignatureRemove: () => void
  onShowSigningInProgressModal: () => void
}

export const ReceptionBookingCheckInStepDocumentsSignature = ({
  booking,
  isSignatureResetting,
  isSignatureRemoving,
  onSignatureReset,
  onSignatureRemove,
  onShowSigningInProgressModal,
}: Props): JSX.Element => (
  <div>
    {booking.booking_signature_url ? (
      <>
        <ReceptionBookingCheckInPreviewPersonalDataSignature
          bookingDetails={booking}
          className="mt-2 ml-2"
          signatureClassName="mb-1"
          bookingDocumentsElement={
            !booking.booking_documents.length && <span className="font-12">Trwa generowanie dokumentów...</span>
          }
          beforeSignature={<i className="uil-check text-green-dark font-20 mr-2 mt-1" />}
          afterSignature={
            <IconWithText
              icon={classnames('font-14 ml-1', { 'uil-edit-alt': !isSignatureResetting })}
              textClass="font-12"
              text={isSignatureResetting ? 'proszę czekać...' : 'popraw'}
              wrapperClassNames="ml-2 mb-1 flex-row-reverse"
              onClick={() => onSignatureReset()}
            />
          }
        />
        <IconWithText
          icon={classnames('font-16', { 'uil-times': !isSignatureRemoving })}
          textClass="font-12"
          text={isSignatureRemoving ? 'Usuwanie...' : 'Usuń podpis'}
          wrapperClassNames="ml-2"
          onClick={onSignatureRemove}
        />
      </>
    ) : (
      <Button className="mt-2 ml-3 p-1 rounded" onClick={onShowSigningInProgressModal} color="primary">
        <IconWithText
          icon="uil-pen"
          text="Wywołaj okno podpisu"
          textClass="font-12 text-semi-strong"
          iconSize="font-16"
        />
      </Button>
    )}
  </div>
)
