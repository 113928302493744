import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { useApiRequest } from '@components/hooks/use-api-request'
import { ReceptionBookingDetails } from '@models/reception'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { useAppDispatch } from '@store/index'
import { setReceptionBookingDetails } from '@store/slices/reception-slice'

interface Props {
  onRescan: () => void
  bookingDetails: ReceptionBookingDetails
}

export const BandScanningRescanAction = ({ bookingDetails, onRescan }: Props): React.ReactNode => {
  const dispatch = useAppDispatch()

  const { action: clearBands, isLoading } = useApiRequest(async () => {
    const newBookingDetails = await commonObjectDelete<ReceptionBookingDetails>(bookingDetails.urls.check_in_step_bands)

    dispatch(
      setReceptionBookingDetails({
        ...newBookingDetails,
        bands: bookingDetails.bands.map(band => ({ ...band, state: 'None' })),
      }),
    )
    onRescan()
  })

  return (
    <CommonObjectConfirmAction
      isLoading={isLoading}
      handleAccept={clearBands}
      message={
        <div>
          <strong className="d-block mb-1">Dotychczasowe kodowanie opasek zostanie wyczyszczone.</strong>
          <span>Czy potwierdzasz usunięcie kodowania wszystkich opasek Gości?</span>
        </div>
      }
    >
      <IconWithText
        icon="uil-redo text-default font-18 lh-0"
        text="Rozpocznij proces od nowa"
        textClass="text-default fw-semi-bold font-11"
        wrapperClassNames="mt-2 cursor-pointer"
      />
    </CommonObjectConfirmAction>
  )
}
