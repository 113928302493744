import * as React from 'react'
import { PageTitle } from '@hyper/page-title'
import { useDocumentTitle } from '@helpers/utils'
import { Card, CardBody } from 'reactstrap'
import { ErliPromocodeFilters } from '@modules/promotions/erli-promocode/erli-promocode-filters'
import { ErliPromocodeTable } from '@modules/promotions/erli-promocode/erli-promocode-table'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { getErliPromocodes } from '@store/actions/promotions-actions'
import { TableFilters } from '@components/table/table'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useDebounce } from 'rooks'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'

const pageTitle = 'Kody ERLI'

export interface ErliPromocodeFiltersParams extends TableFilters {
  created_after: Date | undefined
  created_before: Date | undefined
  is_used: boolean | null
}

export const ErliPromocodeView: React.FC = () => {
  const defaultFilters: ErliPromocodeFiltersParams = {
    created_after: undefined,
    created_before: undefined,
    is_used: null,
    ordering: '-created',
    page: 1,
    page_size: 10,
    search: '',
  }
  const [filters, setFilters] = React.useState<ErliPromocodeFiltersParams>(defaultFilters)
  useMenuActive(NavigationPath.PromotionsErliPromocode)

  const erliPromocodes = useAppSelector((state: RootState) => state.promotionsState.erliPromocodes)

  useDocumentTitle(pageTitle)
  const dispatch = useAppDispatch()

  const { isLoading, action: fetchErliPromocode } = useApiRequest(
    async filters => await dispatch(getErliPromocodes(filters)),
  )

  const fetchErliPromocodeDebounced = React.useCallback(useDebounce(fetchErliPromocode, 300), [])
  React.useEffect(() => {
    fetchErliPromocodeDebounced(filters)
  }, [filters])

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitle} />
      <Card>
        <CardBody>
          <ErliPromocodeFilters filters={filters} setFilters={setFilters} defaultFilters={defaultFilters} />

          <ErliPromocodeTable
            isLoading={isLoading}
            filters={filters}
            setFilters={setFilters}
            erliPromocodes={erliPromocodes || []}
          />
        </CardBody>
      </Card>
    </>
  )
}
