import * as React from 'react'
import { FaultOrderDetails } from '@modules/housekeeping/applications/models'
import { FiltersHousekeepingApplicationFiltersParams } from '@modules/housekeeping/applications/filters'
import { useHousekeepingAppData } from '@modules/housekeeping/hooks/use-housekeeping-app-data'
import Table, { TableHeaderValue } from '@components/table/table'
import { HousekeepingApplicationExportButtons } from '@modules/housekeeping/applications/application-export-buttons'
import { UserPermission } from '@models/dashboard'
import { RootState, useAppSelector } from '@store/index'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { HousekeepingApplicationFaultsTableRow } from '@modules/housekeeping/applications/fault/table-row'

const headerValues: TableHeaderValue<FaultOrderDetails>[] = [
  { title: 'Numer', sortField: 'number' },
  { title: 'Dodano', sortField: 'created' },
  { title: 'Lokal' },
  { title: 'Zgłaszający' },
  { title: 'Nazwa usterki' },
  { title: 'Status' },
  { title: 'Wykonawca' },
  { title: 'Zamknięte' },
  { title: '' },
]

interface Props {
  isLoading: boolean
  faultOrders: FaultOrderDetails[]
  setFilters: (filters: FiltersHousekeepingApplicationFiltersParams) => void
  filters: FiltersHousekeepingApplicationFiltersParams
  resortId: number
}

export const HousekeepingApplicationFaultsTable: React.FC<Props> = ({
  setFilters,
  faultOrders,
  filters,
  isLoading,
  resortId,
}) => {
  const faultOrdersUrl = useAppSelector(
    (state: RootState) => state.appState.appData.urls.reports.report_task_fault_order,
  )
  const appData = useHousekeepingAppData(resortId)

  return (
    <LoaderPlaceholder content={appData.status === 'ready'}>
      <Table
        className="table-hover w-100"
        headerValues={headerValues}
        isLoading={isLoading}
        filters={filters}
        setFilters={setFilters}
      >
        {faultOrders.map(faultOrder => (
          <HousekeepingApplicationFaultsTableRow
            faultOrderExecutors={appData.fault_order_executors}
            faultOrder={faultOrder}
            key={faultOrder.id}
          />
        ))}
      </Table>
      <HousekeepingApplicationExportButtons
        filters={filters}
        resort={resortId}
        permissionRequired={UserPermission.ReportsFaultOrder}
        title="Raport usterek"
        url={faultOrdersUrl}
      />
    </LoaderPlaceholder>
  )
}
