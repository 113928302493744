import * as React from 'react'
import { ReportTask } from '@models/reports'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import Table, { TableHeaderValue } from '@components/table/table'
import { UserReportsTasksTableRow } from '@modules/reports/user-reports/user-reports-tasks-table-row'
import { ReportListFilterParams } from '@modules/reports/user-reports/index'

interface UserReportsTasksTableProps {
  reportTasks: ReportTask[]
  isLoading: boolean
  setFilters: (filters: ReportListFilterParams) => void
  filters: ReportListFilterParams
}

const createHeaderValues = (isSuperUser: boolean): TableHeaderValue<ReportTask>[] => {
  const cols = [
    {
      sortField: 'created',
      title: 'Data utworzenia',
    },
    {
      sortField: 'kind',
      title: 'Rodzaj',
    },
  ]

  if (isSuperUser) {
    cols.push({
      sortField: 'created_by',
      title: 'Autor',
    })
  }

  return [
    ...cols,
    {
      sortField: 'status',
      title: 'Status',
    },
    {
      sortField: 'rows',
      title: 'Wierszy',
    },
    {
      title: 'Odbiorcy',
    },
    {
      title: 'Akcje',
    },
  ]
}

export const UserReportsTasksTable: React.FC<UserReportsTasksTableProps> = ({
  reportTasks,
  isLoading,
  setFilters,
  filters,
}) => {
  const isSuperUser = useAuthenticatedUser().is_superuser

  return (
    <Table
      className="table-hover w-100"
      headerValues={createHeaderValues(isSuperUser)}
      isLoading={isLoading}
      filters={filters}
      setFilters={setFilters}
    >
      {reportTasks.map(reportTask => (
        <UserReportsTasksTableRow reportTask={reportTask} key={reportTask.id} isSuperUser={isSuperUser} />
      ))}
    </Table>
  )
}
