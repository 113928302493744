import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { Col, Collapse, Row } from 'reactstrap'
import { ExpandArrow, useExpandableItems } from '@components/hooks/use-expandable-items'
import { ReservationDataExpandedContent } from '@modules/reservations/details/base-data/booking/reservation-data-expanded-content'
import { ReservationGuestExpandedContent } from '@modules/reservations/details/base-data/guest/reservation-guest-expanded-content'

interface Props {
  booking: ReceptionBookingDetails
}

export const ReservationBaseDataExpandable = React.forwardRef<HTMLElement, Props>(({ booking }) => {
  const { toggleExpanded, isExpanded } = useExpandableItems<'details'>()

  const hasExpandedDetails = isExpanded('details')

  return (
    <>
      <Collapse isOpen={hasExpandedDetails}>
        <Row>
          <Col md={8} className="border-right pt-1 pr-0">
            <ReservationDataExpandedContent booking={booking} />
          </Col>
          <Col md={4} className="pt-1 pl-0">
            <ReservationGuestExpandedContent booking={booking} />
          </Col>
        </Row>
      </Collapse>
      <Row className="border-top mt-3 pt-2 justify-content-center">
        <button
          onClick={toggleExpanded('details')}
          className="border-0 shadow-none bg-transparent text-default font-12 fw-semi-bold align-items-center d-flex"
        >
          {hasExpandedDetails ? 'Ukryj' : 'Pokaż'} dodatkowe parametry
          <ExpandArrow isExpanded={hasExpandedDetails} />
        </button>
      </Row>
    </>
  )
})
