import * as React from 'react'
import { NavigationTabsButton } from '@components/navigation-tabs/index'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

interface NavigationTabsLinkProps {
  button: NavigationTabsButton
  className: string
}

export const NavigationTabsLink: React.FC<NavigationTabsLinkProps> = ({ button, className }) => {
  const linkClassName = classNames(
    'navigation-tabs__button btn text-semi-strong',
    {
      'btn-secondary': button.isActive,
      'btn-light': !button.isActive,
    },
    button.className,
    className,
  )

  if (button.link) {
    return (
      <Link className={linkClassName} to={button.link}>
        {button.title}
      </Link>
    )
  } else {
    return (
      <button className={linkClassName} type="button" onClick={button.onClick}>
        {button.title}
      </button>
    )
  }
}
