import * as React from 'react'
import { UnitDetails } from '@models/units'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { TableFilters } from '@components/table/table'
import { useGetTechnicalOrdersQuery } from '@api/technical-orders'
import { TypedQueryResult } from '@api/base'
import { TechnicalOrder } from '@models/technical-orders'
import { TechnicalOrderCreateButton } from '@modules/technical-orders/technical-order-create-button'
import { UnitDetailsTechnicalOrdersTable } from '@modules/units/details/technical-orders/table'
import { UnitDetailsTechnicalOrdersFilters } from '@modules/units/details/technical-orders/filters'

interface Props {
  unit: UnitDetails
}

const defaultFilters: TableFilters = {
  search: '',
  ordering: 'finished_at',
  page: 1,
  page_size: 10,
}

const defaultEmptyList = []

export const UnitDetailsTechnicalOrders: React.FC<Props> = ({ unit }) => {
  const [filters, setFilters] = React.useState<TableFilters>(defaultFilters)
  const { data: technicalOrders = defaultEmptyList } = useGetTechnicalOrdersQuery({
    unit: unit.id,
    ...filters,
  }) as TypedQueryResult<TechnicalOrder[]>

  return (
    <Card>
      <CardBody>
        <Row>
          <Col md={6}>
            <h4 className="text-secondary">Lista usterek</h4>
          </Col>
          <Col md={6} className="text-right">
            <TechnicalOrderCreateButton unitId={unit.id} resortId={unit.resort_id} />
          </Col>
        </Row>
        <UnitDetailsTechnicalOrdersFilters defaultFilters={defaultFilters} filters={filters} setFilters={setFilters} />
        <UnitDetailsTechnicalOrdersTable filters={filters} setFilters={setFilters} technicalOrders={technicalOrders} />
      </CardBody>
    </Card>
  )
}
