import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { TechnicalOrder } from '@models/technical-orders'
import { Form } from '@hyper/forms/form'
import { SaveButton } from '@hyper/button'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { useHandleRtkQueryError } from '@components/hooks/use-handle-rtk-error'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useForm } from 'react-hook-form'
import { useCreateOrUpdateTechnicalOrderMutation } from '@api/technical-orders'
import * as R from 'ramda'
import { TechnicalOrderWorkTimeModalForm } from '@modules/technical-orders/technical-order-work-time-modal/form'

interface Props extends BaseModalProps {
  technicalOrder: TechnicalOrder
}

interface FormInputs {
  custom_total_working_time: string
}

export const TechnicalOrderWorkTimeModal: React.FC<Props> = ({ technicalOrder, toggleIsVisible }) => {
  const methods = useForm<FormInputs>({
    defaultValues: {
      ...R.pick<FormInputs, keyof FormInputs>(['custom_total_working_time'], {
        ...technicalOrder,
      }),
    },
  })
  const { addSuccessNotification } = useNotificationHook()

  const [updateTechnicalOrder, { error, isError, isLoading }] = useCreateOrUpdateTechnicalOrderMutation()

  const onSubmit = async (payload: FormInputs) => {
    await updateTechnicalOrder({
      data: {
        custom_total_working_time: payload.custom_total_working_time || null,
      },
      url: technicalOrder.urls.custom_total_working_time,
    }).unwrap()

    addSuccessNotification('Zmiany zostały zapisane pomyślnie!')
    toggleIsVisible()
  }

  useHandleRtkQueryError(error, isError, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Edytuj czas</ModalHeader>
      <ModalBody>
        <TechnicalOrderWorkTimeModalForm />
      </ModalBody>

      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
