import * as React from 'react'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { PackageVip } from '@modules/promotions/package-vip/models'
import { ReceptionBookingDetails } from '@models/reception'
import { useModal } from '@components/modals/use-modal'

interface ImprovementVipCodeAddedProps {
  booking: ReceptionBookingDetails
}

const getPackageVipPaymentMethod = (package_vip: PackageVip | null) => {
  switch (package_vip?.payment_method) {
    case 'transfer':
      return 'Opłacono kodem VIP'
    case 'package_code':
      return 'Opłacono kodem firmowy'
    case 'subscription_code':
      return 'Opłacone kodem abonamentowym'
    case 'transfer_package_code':
      return 'Opłacono kodem firmowy (wymagana dopłata)'
    case 'transfer_subscription_code':
      return 'Opłacone kodem abonamentowym (wymagana dopłata)'
    default:
      return 'Opłacone'
  }
}

export const ReceptionBookingCheckInImprovementVipCodeAdded: React.FC<ImprovementVipCodeAddedProps> = ({ booking }) => {
  const handleUnpinCode = () => {
    // TODO add handler for code remove

    toggleDeleteConfirmationDialogVisibility()
  }
  const [toggleDeleteConfirmationDialogVisibility] = useModal('ReceptionBookingCheckInImprovementRemoveVipDialog', {
    onConfirm: handleUnpinCode,
  })

  const packageVipImprovement = booking.improvements.find(row => row.code === 'package_vip')

  return (
    <>
      <td className="text-success font-12 text-right text-semi-strong align-middle">
        <i className="uil-check cursor-pointer font-18 grey-400 mr-1" />
        <span>
          {getPackageVipPaymentMethod(booking.package_vip)} w dniu{' '}
          {toDefaultDateFormat(packageVipImprovement?.created_at || '')}
        </span>
      </td>
      <td colSpan={2} className="text-danger font-12 text-right text-semi-strong align-middle">
        <div onClick={toggleDeleteConfirmationDialogVisibility} className="cursor-pointer d-inline-block">
          <i className="uil-trash-alt cursor-pointer font-18 grey-400 mr-1" role="unpin-vip-improvement" />
          <span>Odepnij kod VIP</span>
        </div>
      </td>
    </>
  )
}
