import * as React from 'react'
import { formatPrice } from '@helpers/utils'
import classNames from 'classnames'
import { ReceptionBookingDetailsCartItem } from '@models/reception'
import { FormCheckbox } from '@hyper/forms/form-checkbox'
import { createCartItemFormName } from '@modules/reception/cart/utils'
import { useFormContext, useWatch } from 'react-hook-form'
import { ReceptionBookingCartItemPaidIcon } from '@modules/reception/cart/cart-items/reception-booking-cart-item-paid-icon'

interface Props {
  meal: ReceptionBookingDetailsCartItem
}

export const ReceptionBookingCartFeedingGuestMeal = ({ meal }: Props): JSX.Element => {
  const formName = createCartItemFormName(meal)

  const { control } = useFormContext()

  const isSelected = useWatch({ control, name: formName })
  return (
    <div
      className={classNames('row border-top mx-0 align-items-center reception-booking-cart__selectable', {
        'is-selected': isSelected,
      })}
    >
      <div className="col-7 pl-5 reception-booking-cart__expandable__item is-nested py-1">
        {meal.is_paid ? (
          <ReceptionBookingCartItemPaidIcon>
            <span className="font-weight-normal">{meal.name}</span>
          </ReceptionBookingCartItemPaidIcon>
        ) : (
          <FormCheckbox name={formName} label={<span className="font-weight-normal">{meal.name}</span>} />
        )}
      </div>
      <div className="col-3">{formatPrice(meal.price_brutto_without_discount)}</div>
      <div className="col-2">{formatPrice(meal.price_brutto)}</div>
    </div>
  )
}
