import * as React from 'react'
import { CustomInput, FormGroup, Label, Row } from 'reactstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { ColAuto } from '@hyper/col-auto'
import { packageWholesaleStatusList } from '@modules/package-wholesale/models'
import { Link } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { PackageWholesaleCreateButton } from '@modules/package-wholesale/create'
import { useGroupedSellers } from '@components/hooks/use-grouped-sellers'
import DateRangeInput from '@components/date/date-range-input'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { TableFilters } from '@components/table/table'
import { extractInnerRef } from '@helpers/forms'
import { FormSelect } from '@hyper/forms/form-select'
import { createSelectOption } from '@helpers/utils'

export interface PackageWholesaleListFilterParams extends TableFilters {
  status: CustomReactSelectOption[] | undefined
  sellers: CustomReactSelectOption | undefined
  created_after: Date | undefined
  created_before: Date | undefined
  has_zoo_borysew_tickets: boolean
  has_suntago_tickets: boolean
}

interface Props {
  defaultFilters: PackageWholesaleListFilterParams
  filters: PackageWholesaleListFilterParams
  setFilters: (filters: PackageWholesaleListFilterParams) => void
}

export const PackageWholesaleListFilter: React.FC<Props> = ({ defaultFilters, filters, setFilters }) => {
  const methods = useForm<PackageWholesaleListFilterParams>({
    defaultValues: defaultFilters,
  })

  const onSubmit = async (payload: PackageWholesaleListFilterParams) => setFilters({ ...filters, ...payload })

  const user = useAuthenticatedUser()

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  const sellers = useGroupedSellers()

  return (
    <FormProvider {...methods}>
      <Row>
        <FormSelect
          name="status"
          label="Status"
          selectProps={{ isSelectMulti: true }}
          options={packageWholesaleStatusList}
          formPlainProps={{ colSize: 3 }}
        />
        <FormSelect options={sellers} name="sellers" label="Sprzedawca" formPlainProps={{ colSize: 2 }} />
        <FormSelect
          options={AccommodateTypeOptions}
          name="accommodation_type"
          label="Zakwaterowanie"
          selectProps={{ isClearable: true }}
          formPlainProps={{ colSize: 2 }}
        />
        <ColAuto className="ml-auto px-0">
          <Label className="d-block">&nbsp;</Label>
          {user.hasPerm(UserPermission.PackageWholesaleProductCanView) && (
            <Link to={NavigationPath.PackageWholesaleProductList} className="btn btn-light mr-2">
              Lista produktów
            </Link>
          )}
          <PackageWholesaleCreateButton />
        </ColAuto>
      </Row>
      <Row>
        <ColAuto className="ml-auto px-0">
          <Link to={NavigationPath.PackageWholesalePackages} className="btn btn-light mr-2">
            Sprzedane pakiety pobytowe
          </Link>
          <Link to={NavigationPath.PackageWholesaleGastroCards} className="btn btn-light mr-2">
            Sprzedane pakiety podwójna radość
          </Link>
          <Link to={NavigationPath.PackageWholesaleGastroCardsBoost} className="btn btn-light mr-2">
            Sprzedane pakiety doładowanie smaku
          </Link>
          <Link to={NavigationPath.PackageWholesaleGastroCards2024} className="btn btn-light mr-2">
            Sprzedane pakiety podwójna radość 2024
          </Link>
          <Link to={NavigationPath.PackageWholesaleGastroCards2025} className="btn btn-light mr-2">
            Sprzedane pakiety podwójna radość 2025
          </Link>
        </ColAuto>
      </Row>
      <Row>
        <ColAuto>
          <Label>Okres</Label>
          <DateRangeInput
            isClearable={true}
            startDateName="created_after"
            endDateName="created_before"
            wrapperClassName="is-range"
          />
        </ColAuto>
        <ColAuto>
          <FormGroup>
            <Label for="has_zoo_borysew_tickets">&nbsp;</Label>
            <CustomInput
              type="checkbox"
              {...extractInnerRef(methods.register('has_zoo_borysew_tickets'))}
              id="has_zoo_borysew_tickets"
              className="text-default mt-2"
              label="Pokaż tylko z biletami do Zoo Borysew"
            />
          </FormGroup>
        </ColAuto>
        <ColAuto>
          <FormGroup>
            <Label for="has_suntago_tickets">&nbsp;</Label>
            <CustomInput
              type="checkbox"
              {...extractInnerRef(methods.register('has_suntago_tickets'))}
              id="has_suntago_tickets"
              className="text-default mt-2"
              label="Pokaż tylko z biletami do Suntago"
            />
          </FormGroup>
        </ColAuto>
        <SearchFilter />
      </Row>
    </FormProvider>
  )
}

const AccommodateTypeOptions = [createSelectOption('Domek', 1), createSelectOption('Apartament', 2)]
