import * as React from 'react'
import Table from '@components/table/table'
import { formatPrice } from '@helpers/utils'
import { RootState, useAppSelector } from '@store/index'

export const CashRegisterListTableSummaryRow: React.FC = () => {
  const total_amount = useAppSelector((state: RootState) => state.saleState.cashRegistersExtraData.total_amount)

  return (
    <Table.Row>
      <Table.Cell colSpan={6} className="text-right align-middle package-list__summary-cell">
        <strong className="mr-2">Razem w wybranym okresie:</strong>
      </Table.Cell>
      <Table.Cell className="package-list__summary-cell">
        <div className="text-white package-list__summary-block">
          <strong>{formatPrice(total_amount)}</strong>
        </div>
      </Table.Cell>
      <Table.Cell className="package-list__summary-cell">
        <div className="package-list__summary-block" />
      </Table.Cell>
    </Table.Row>
  )
}
