import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { TechnicalOrder } from '@models/technical-orders'
import { SaveButton } from '@hyper/button'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useCreateOrUpdateReceptionTechnicalOrderMutation } from '@api/technical-orders'
import { useHandleRtkQueryError } from '@components/hooks/use-handle-rtk-error'
import { ReceptionTechnicalOrderFormInputs } from '@modules/reception-technical-orders/reception-technical-order-modal'
import { useForm } from 'react-hook-form'
import { Form } from '@hyper/forms/form'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { ReceptionTechnicalOrderStatusForm } from '@modules/reception-technical-orders/reception-technical-order-status-modal/form'
import { getSelectedUsersPayload } from '@modules/technical-orders/helpers'

interface Props extends BaseModalProps {
  technicalOrder: TechnicalOrder
}

export interface ReceptionTechnicalOrderStatusFormInputs {
  action: 'close' | 'forward_to_technical_order' | 'forward_to_guests_technical_order'
  sub_action: 'close' | 'delete'
}

export const ReceptionTechnicalOrderStatusModal: React.FC<Props> = ({ technicalOrder, toggleIsVisible }) => {
  const methods = useForm<ReceptionTechnicalOrderStatusFormInputs>()

  const { addSuccessNotification } = useNotificationHook()

  const [createOrUpdateTechnicalOrder, { error, isError, isLoading }] =
    useCreateOrUpdateReceptionTechnicalOrderMutation()

  const onSubmit = async (payload: ReceptionTechnicalOrderFormInputs) => {
    await createOrUpdateTechnicalOrder({
      data: { ...payload, users: payload.users ? getSelectedUsersPayload(payload.users) : [] },
      id: technicalOrder.id,
      url: technicalOrder.urls.switch_status,
    }).unwrap()
    addSuccessNotification('Status został zmieniony')
    toggleIsVisible()
  }

  useHandleRtkQueryError(error, isError, methods.setError)
  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Zmień status usterki {technicalOrder.number}</ModalHeader>
      <ModalBody>
        <ReceptionTechnicalOrderStatusForm resortId={technicalOrder.resort_id} />
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Anuluj
        </Button>
        <SaveButton className="btn btn-green" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
