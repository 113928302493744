import * as React from 'react'
import { Col, Row } from 'reactstrap'
import { PromocodePrefixDialogDate } from '@modules/promocode/promocode-prefixes/promocode-prefix-dialog/promocode-prefix-dialog-date'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { PromocodePrefixDialogFormInputs } from '@modules/promocode/promocode-prefixes/promocode-prefix-dialog/promocode-prefix-dialog'
import { PromocodePrefixDate } from '@models/promocode'
import { endOfMonth, startOfMonth, startOfToday } from 'date-fns'
import { FormPlainError } from '@hyper/forms'

export const PromocodePrefixDialogDates: React.FC = () => {
  const { control, clearErrors } = useFormContext<PromocodePrefixDialogFormInputs>()

  const { append, remove: handleRemoveDate, fields: dates } = useFieldArray({ control: control, name: 'dates' })

  const handleAddDate = () => {
    append({
      date_from: startOfMonth(startOfToday()),
      date_to: endOfMonth(startOfToday()),
    } as const as PromocodePrefixDate)
    clearErrors('dates_all')
  }

  return (
    <Row className="bg-grey form-transparent" style={{ marginLeft: -15, marginRight: -15 }}>
      <div className="hr hr-grey mb-2" />
      <Col sm={12}>
        <h5>Daty obowiązywania</h5>
        <div className="mb-1">
          {!dates.length ? (
            <div className="text-center">Brak dat</div>
          ) : (
            dates.map((promocodePrefixDate, index) => (
              <PromocodePrefixDialogDate key={index} index={index} handleRemoveDate={handleRemoveDate} />
            ))
          )}
        </div>
      </Col>
      <Col sm={12}>
        <div className="hr hr-grey mb-2 p-0" />
      </Col>
      <Col sm={12} className="text-center">
        <button className="btn-pure text-dark text-semi-strong" type="button" onClick={handleAddDate}>
          <i className="mdi mdi-plus" /> Dodaj przedział
        </button>
        <FormPlainError name="dates" />
        <FormPlainError name="dates_all" />
      </Col>
      <div className="hr hr-grey mt-2" />
    </Row>
  )
}
