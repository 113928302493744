import * as React from 'react'
import { Button } from 'reactstrap'
import { useToggle } from '@components/hooks/use-toggle'
import classNames from 'classnames'
import { useAppData } from '@components/hooks/use-app-data'

export const UpsellBookingHeaderMenu = (): JSX.Element => {
  const { urls } = useAppData()
  const [isVisible, toggleIsVisible] = useToggle(false)

  const handleLogout = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
  }

  return (
    <div className={classNames('upsell__header__menu__wrapper', { 'is-visible': isVisible })} onClick={toggleIsVisible}>
      <Button color="transparent" className="shadow-none" onClick={toggleIsVisible}>
        <i className="uil-bars font-18" />
      </Button>
      <div
        className={classNames('upsell__header__menu__content', { 'is-visible': isVisible })}
        onClick={e => e.stopPropagation()}
      >
        <div className="d-flex align-items-center justify-content-center py-1">
          <img src={require('@assets/images/logo_sygnet.svg')} alt="" height="30" />
          <span className="font-18 ml-2 fw-semi-bold">Holiday Park & Resort</span>
        </div>

        <Button color="primary" onClick={handleLogout} className="w-100 mt-auto">
          <a href={urls.account.logout} className="text-white">
            Wyloguj
          </a>
        </Button>
      </div>
    </div>
  )
}
