import * as React from 'react'
import { FormInput } from '@hyper/forms'
import { asDecimal, formatPrice } from '@helpers/utils'
import { useFormContext } from 'react-hook-form'
import { PackageWholesaleProductFormInputs } from '@modules/package-wholesale/products/create/modal'

interface Props {
  factor: number
}

export const PackageWholesaleProductCreateFormGastroCard = ({ factor }: Props): JSX.Element => {
  const { watch } = useFormContext<PackageWholesaleProductFormInputs>()

  const priceBrutto = watch('price_brutto')

  const priceToUse = priceBrutto ? asDecimal(priceBrutto).mul(asDecimal(factor)).toString() : asDecimal(0).toString()

  return (
    <div className="col-8 d-flex">
      <FormInput label="Kwota zakupu" name="price_brutto" colSize={7} colClassName="pl-0" type="currency" />

      <div className="col-5 pl-0">
        <p>Kwota do wykorzystania:</p>
        {priceToUse ? formatPrice(priceToUse) : '-'}
      </div>
    </div>
  )
}
