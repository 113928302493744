import * as React from 'react'
import { useAppDispatch, useAppSelector } from '@store/index'
import { useSubscriptionAppData } from '@modules/subscription/hooks/use-subscription-app-data'
import { getSubscriptionProducts } from '@store/actions/subscription-actions'
import { useDocumentTitle } from '@helpers/utils'
import { PageTitle } from '@hyper/page-title'
import { Card, CardBody } from 'reactstrap'
import { SubscriptionWebSocketHandler } from '@modules/subscription/web-socket-handler'
import {
  SubscriptionProductListFilter,
  SubscriptionProductListFilterParams,
} from '@modules/subscription/products/list/filter'
import { SubscriptionProductListTable } from '@modules/subscription/products/list/table'
import { totalSizeSelector } from '@store/selectors/dashboard'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { packageWholesaleProductsStatusOptions } from '@modules/package-wholesale/products/list/filter'
import { subscriptionProductsSelector } from '@store/slices/subscription-slice'
import { SubscriptionType } from '@modules/subscription/models'
import { useTableFilters } from '@components/table/use-table-filters'

const defaultFilters: SubscriptionProductListFilterParams = {
  search: '',
  status: packageWholesaleProductsStatusOptions.find(row => row.value === 'available'),
  ordering: '-created',
  page: 1,
  page_size: 10,
  kind: [],
}

interface Props {
  type: SubscriptionType
}

export const SubscriptionProductListView: React.FC<Props> = ({ type }) => {
  const totalSize = useAppSelector(totalSizeSelector)
  const dispatch = useAppDispatch()
  const subscriptionProducts = useAppSelector(subscriptionProductsSelector)

  useSubscriptionAppData()
  useMenuActive(NavigationPath.SubscriptionList)

  const { isLoading, filters, setFilters } = useTableFilters<SubscriptionProductListFilterParams>({
    defaultFilters,
    action: async filters => await dispatch(getSubscriptionProducts([filters, type])),
  })

  const pageTitle = 'Lista produktów'
  const pageTitleWithSize = pageTitle + ` (${totalSize})`
  useDocumentTitle(pageTitleWithSize)

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitleWithSize} />
      <Card>
        <SubscriptionWebSocketHandler type={type} />
        <CardBody>
          <SubscriptionProductListFilter
            type={type}
            filters={filters}
            setFilters={setFilters}
            defaultFilters={defaultFilters}
          />
          <SubscriptionProductListTable
            isLoading={isLoading}
            subscriptionProducts={subscriptionProducts}
            filters={filters}
            setFilters={setFilters}
          />
        </CardBody>
      </Card>
    </>
  )
}
