import { createSelectOption } from '@helpers/utils'
import { CustomReactSelectOption } from '@components/custom-react-select'
import * as React from 'react'
import { useTechnicalOrdersAppData } from '@modules/technical-orders/hooks/use-technical-orders-app-data'

export const useTechnicalOrderTypesSelectOptions = (): CustomReactSelectOption[] => {
  const { technical_order_types } = useTechnicalOrdersAppData()

  return React.useMemo(
    () => technical_order_types.map(row => createSelectOption(row.name, row.id)),
    [technical_order_types],
  )
}
