import * as React from 'react'
import { CalendarDate, TimelineBooking, TimelineReceptionBooking } from '@store/reducers/timeline-reducers'
import timelineHelper from '@helpers/timeline-helper'
import { Apartment, ApartmentBookingFilter } from '@models/apartment'
import TimelineItemWrapperView from '@modules/reservations/timeline/timeline-item-wrapper'
import { add, isAfter, isBefore, parseISO } from 'date-fns'
import dateHelper from '@helpers/date-helper'
import { RootState, useAppSelector } from '@store/index'
import { useWindowScroll } from '@components/hooks/use-window-scroll'
import { groupByField } from '@helpers/utils'

interface Props {
  bookings: TimelineBooking[]
  renderedDates: CalendarDate[]
  allApartments: Apartment[]
  infiniteScrollerRef: React.RefObject<HTMLDivElement>
  filters: ApartmentBookingFilter[]
  resortId: string
}

interface Range {
  minDate: Date
  maxDate: Date
}

const margin = 3

const getApartment = (booking: TimelineBooking, apartments: Apartment[]) =>
  apartments.find(apartment => booking.apartment_id === apartment.id)

const isApartmentInRange = (booking: TimelineBooking, apartmentsInRange: Apartment[]): boolean =>
  apartmentsInRange.some(apartment => booking.apartment_id === apartment.id)

const isDateInRange = (date: string, range: Range): boolean =>
  isAfter(parseISO(date), range.minDate) && isBefore(parseISO(date), range.maxDate)

const isDateLongInRange = (dateFrom: string, dateTo: string, range: Range): boolean =>
  isBefore(parseISO(dateFrom), range.minDate) && isAfter(parseISO(dateTo), range.maxDate)

const isBookingInRange = (booking: TimelineBooking, range: Range, apartmentsInRange: Apartment[]): boolean =>
  (isDateInRange(booking.date_from, range) ||
    isDateInRange(booking.date_to, range) ||
    isDateLongInRange(booking.date_from, booking.date_to, range)) &&
  isApartmentInRange(booking, apartmentsInRange)

const TimelineVirtualizeItems: React.FC<Props> = props => {
  const scrollLeft = useAppSelector((state: RootState) => state.timelineState.scrollPosition)
  const editingBookingItem = useAppSelector((state: RootState) => state.timelineState.editingBookingItem)
  useWindowScroll(50)

  const bookingsInRange = React.useMemo(() => {
    if (props.infiniteScrollerRef.current) {
      const range = getDisplayedDatesRange()
      const apartments = getApartmentsInRange()
      return props.bookings.filter(
        booking => isBookingInRange(booking, range, apartments) || editingBookingItem?.id === booking.id,
      )
    } else {
      return []
    }
  }, [getDisplayedDatesRange(), getApartmentsInRange(), props.infiniteScrollerRef.current])

  function getDisplayedDatesRange(): Range {
    if (!props.infiniteScrollerRef.current) {
      const now = new Date()
      return {
        minDate: now,
        maxDate: now,
      }
    }

    const { offsetWidth } = props.infiniteScrollerRef.current
    const { month, year } = props.renderedDates[0]
    const right = Math.floor((scrollLeft + offsetWidth) / timelineHelper.getDayWidthMargin())
    const left = Math.floor(scrollLeft / timelineHelper.getDayWidthMargin())
    const min = add(parseISO(dateHelper.createDateFormat(year, month, 1)), { days: left - margin })
    const max = add(parseISO(dateHelper.createDateFormat(year, month, 1)), { days: right + margin })
    return {
      minDate: min,
      maxDate: max,
    }
  }

  function getApartmentsInRange() {
    if (!props.infiniteScrollerRef.current) {
      return []
    }

    const { top: offsetTop } = props.infiniteScrollerRef.current.getBoundingClientRect()
    const scrollOffset = offsetTop > 0 ? 0 : Math.abs(offsetTop)

    const height = window.innerHeight
    const top = Math.floor(scrollOffset / timelineHelper.getRowHeight()) - margin
    const bottom = Math.floor((scrollOffset + height) / timelineHelper.getRowHeight()) + margin
    return props.allApartments.filter((ap, i) => i >= top && i <= bottom)
  }

  const joinedBookingsIds = React.useMemo(() => {
    if (!props.filters.includes('only_joined')) return []

    const groupedByEmail = groupByField(bookingsInRange, 'email')
    const joinedBookings = new Set<number>()

    Object.values(groupedByEmail).forEach(bookings =>
      bookings.forEach(booking => {
        bookings.forEach((timelineReceptionBooking: TimelineReceptionBooking) => {
          const isJoined = timelineReceptionBooking.date_to === booking.date_from
          if (isJoined) {
            joinedBookings.add(timelineReceptionBooking.id).add(booking.id)
          }
        })
      }),
    )

    return Array.from(joinedBookings)
  }, [bookingsInRange, props.filters])

  return (
    <>
      {bookingsInRange.map(booking => {
        const apartment = getApartment(booking, props.allApartments)
        if (!apartment) return null

        return (
          <TimelineItemWrapperView
            resortId={props.resortId}
            key={booking.id}
            apartment={apartment}
            allApartments={props.allApartments}
            booking={booking}
            filters={props.filters}
            renderedDates={props.renderedDates}
            joinedBookingIds={joinedBookingsIds}
          />
        )
      })}
    </>
  )
}

export default TimelineVirtualizeItems
