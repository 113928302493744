import * as React from 'react'
import { GastroCardDetails } from '@models/promotions'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { GastroCardCodeCreate } from '@modules/promotions/gastro-cards/gastro-card-details/card-codes/gastro-card-code-create'
import { formatPrice } from '@helpers/utils'
import { YesNoBadge } from '@components/badges/yes-no'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'

interface GastroCardCodesProps {
  gastroCardDetails: GastroCardDetails
}

export const GastroCardCodes: React.FC<GastroCardCodesProps> = ({ gastroCardDetails }) => {
  const user = useAuthenticatedUser()
  return (
    <Card>
      <CardBody>
        <Row>
          <Col md={5}>
            <h4 className="mt-0 text-secondary mb-3">Kody dla znajomych</h4>
          </Col>
          <Col md={7} className="text-right">
            {!gastroCardDetails.is_removed && <GastroCardCodeCreate gastroCardDetails={gastroCardDetails} />}
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mt-2">
            <table className="table table-centered table-sm table-hover table-striped">
              <thead className="thead-light">
                <tr>
                  <th>Kod</th>
                  <th>Kwota</th>
                  <th>Status</th>
                  <th>Data wykorzystania</th>
                  <th>Numer rezerwacji</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {!gastroCardDetails.codes.length ? (
                  <tr>
                    <td colSpan={6} className="text-center">
                      Brak kodów
                    </td>
                  </tr>
                ) : (
                  gastroCardDetails.codes.map(code => (
                    <tr key={code.id}>
                      <td>
                        {user.is_superuser ? (
                          <a href={code.urls.admin}>
                            <code>{code.code}</code>
                          </a>
                        ) : (
                          <code>{code.code}</code>
                        )}
                      </td>
                      <td>{formatPrice(code.value)}</td>
                      <td>
                        <YesNoBadge value={!code.is_used} textNo="wykorzystany" textYes="niewykorzystany" />
                      </td>
                      <td>{code.used_at && toDefaultDateTimeFormat(code.used_at)}</td>
                      <td>{code.used_in}</td>
                      <td />
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}
