import { useApiRequest } from '@components/hooks/use-api-request'
import * as React from 'react'
import { useAppDispatch } from '@store/index'
import { ContentNotesCard } from '@components/content-notes/content-notes-card'
import { useAppData } from '@components/hooks/use-app-data'
import { AgreementDetails } from '@modules/hr/agreement/models'
import { getAgreementDetails } from '@store/actions/hr-action'

interface Props {
  agreementDetails: AgreementDetails
}

export const AgreementDetailsNotes: React.FC<Props> = ({ agreementDetails }) => {
  const { agreement: contentType } = useAppData().content_types

  const dispatch = useAppDispatch()

  const { action: handleRefresh } = useApiRequest(async () => await dispatch(getAgreementDetails(agreementDetails.id)))

  return (
    <div data-testid="package-details-notes">
      <ContentNotesCard
        readOnly={false}
        objectId={agreementDetails.id}
        contentType={contentType}
        onNotesUpdate={handleRefresh}
        notes={agreementDetails.notes}
      />
    </div>
  )
}
