import * as React from 'react'
import { useApiRequest } from '@components/hooks/use-api-request'
import { TopBarLoading } from '@components/topbar-loading'
import { commonObjectGet } from '@store/actions/generic-actions'
import { RootState, useAppSelector } from '@store/index'
import { useModal } from '@components/modals/use-modal'
import { PackageDetails } from '@models/package'

interface Props {
  package_id: string
}

export const PackageDetailsView: React.FC<Props> = ({ package_id }) => {
  const packagesUrl = useAppSelector((state: RootState) => state.appState.appData.urls.package.packages)
  const [handleDetails, hideModal] = useModal('PackageDetailsModal')

  const { isLoading, action: fetchPackage } = useApiRequest<PackageDetails>(
    async () => await commonObjectGet<PackageDetails>(`${packagesUrl}${package_id}/`),
  )

  const showPackageDetails = async () => {
    const packageData = await fetchPackage()

    if (packageData) {
      handleDetails(
        null,
        {
          packageData,
        },
        true,
      )
    }
  }

  React.useEffect(() => {
    showPackageDetails()
    return () => {
      hideModal()
    }
  }, [package_id])

  return isLoading ? <TopBarLoading /> : null
}
