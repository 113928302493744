import * as React from 'react'
import { ClientDetails } from '@models/clients'
import { FormInput, FormPlain } from '@hyper/forms'
import { FormProvider, useForm } from 'react-hook-form'
import { Col, CustomInput, Row } from 'reactstrap'
import { useFormRequest } from '@components/hooks/use-api-request'
import { SaveButton } from '@hyper/button'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { updateClientDetails } from '@store/actions/crm-actions'
import { commonObjectPatch } from '@store/actions/generic-actions'
import { GusButton } from '@components/gus-button'
import { CompanyDataFromGus } from '@models/sale'
import * as R from 'ramda'
import { extractInnerRef } from '@helpers/forms'
import { InvoiceType } from '@models/promotions'
import { Country } from '@models/dashboard'
import { ButtonWithIcon } from '@components/button-with-icon'

interface Props {
  client: ClientDetails
  toggleEditMode: () => void
}

interface FormInputs {
  invoice_type: InvoiceType
  invoice: boolean
  invoice_city: string
  invoice_company: string
  invoice_country: string
  invoice_nip: string
  invoice_postcode: string
  invoice_street: string
}

export const ClientDetailsModalClientProfileCompanyDataForm: React.FC<Props> = ({ toggleEditMode, client }) => {
  const methods = useForm<FormInputs>({
    defaultValues: {
      ...R.pick<FormInputs, any>(
        [
          'invoice_city',
          'invoice_company',
          'invoice_country',
          'invoice_nip',
          'invoice_type',
          'invoice_postcode',
          'invoice_street',
          'invoice',
        ],
        {
          ...client.profile,
        },
      ),
      invoice_type: client.profile.invoice_type || 'company',
    },
  })

  const countries = useAppSelector((state: RootState) => state.appState.appData.countries)
  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()

  const updateInvoice = async (payload: FormInputs) => {
    await dispatch(updateClientDetails(await commonObjectPatch<ClientDetails>(client.profile.urls.details, payload)))
    addSuccessNotification('Zmiany zostały zapisane')
    toggleEditMode()
  }

  const { isLoading: isAdding, action: addInvoice } = useFormRequest(updateInvoice, methods.setError)
  const { isLoading: isRemoving, action: removeInvoice } = useFormRequest(updateInvoice, methods.setError)

  const getNip = () => methods.getValues('invoice_nip')

  const handleGus = (companyData: CompanyDataFromGus) => {
    methods.setValue('invoice_company', companyData.name)
    methods.setValue('invoice_street', companyData.street_address)
    methods.setValue('invoice_postcode', companyData.postal_code)
    methods.setValue('invoice_city', companyData.city)
    methods.setValue('invoice_country', 'PL')
  }

  const invoiceType = methods.watch('invoice_type')

  const handleInvoiceAdd = async () => {
    await addInvoice({ ...methods.getValues(), invoice: true })
  }

  const handleInvoiceRemove = async () => {
    await removeInvoice({ ...methods.getValues(), invoice: false })
  }

  return (
    <FormProvider {...methods}>
      <Row className="mt-3">
        <FormPlain formGroupClassName="align-items-center row" name="invoice_type">
          <label className="col-md-4">Rodzaj faktura</label>
          <div className="col-md-8 pl-0 d-flex">
            <CustomInput
              type="radio"
              value="company"
              {...extractInnerRef(methods.register('invoice_type'))}
              id="invoice_type_company"
              label="Firma"
            />
            <CustomInput
              className="ml-2"
              type="radio"
              value="individual"
              {...extractInnerRef(methods.register('invoice_type'))}
              id="invoice_type_individual"
              label="Osoba prywatna"
            />
          </div>
        </FormPlain>

        <FormInput
          labelProps={{ className: 'col-md-4' }}
          formGroupClassName="align-items-center row"
          inputClassName="col-md-4 mr-1"
          label={invoiceType === 'company' ? 'NIP' : 'PESEL'}
          name="invoice_nip"
          afterInput={
            invoiceType === 'company' && (
              <GusButton
                setCompanyData={handleGus}
                getNip={getNip}
                button={<button className="btn btn-primary">Pobierz z GUS</button>}
              />
            )
          }
        />
        <FormInput
          labelProps={{ className: 'col-md-4' }}
          formGroupClassName="align-items-center row"
          inputClassName="col-md-8"
          label={invoiceType === 'company' ? 'Nazwa firmy' : 'Imię i nazwisko'}
          name="invoice_company"
        />

        <FormInput
          labelProps={{ className: 'col-md-4' }}
          formGroupClassName="align-items-center row"
          inputClassName="col-md-8"
          label="Adres"
          name="invoice_street"
        />
        <Col md={4} />
        <FormInput colSize={3} colClassName="px-0" name="invoice_postcode" />
        <FormInput colSize={5} colClassName="pr-0" name="invoice_city" />
        <Col md={4} />
        <FormInput colSize={8} colClassName="px-0" name="invoice_country" type="select" inputClassName="bg-white">
          {countries.map((country: Country) => (
            <option value={country.id} key={country.id}>
              {country.name}
            </option>
          ))}
        </FormInput>
      </Row>
      <Row>
        <Col sm={12} className="p-0">
          <div className="hr hr-grey mb-3" />
        </Col>
      </Row>
      <Row>
        {client.profile.invoice && (
          <div className="col-6">
            <ButtonWithIcon
              icon="uil-trash-alt"
              text="Usuń"
              isLoading={isRemoving}
              btnClass="btn-danger d-flex align-items-center"
              loadingText="Usuwanie..."
              handleClick={handleInvoiceRemove}
            />
          </div>
        )}
        <Col md={6} className="text-right px-0 ml-auto">
          <button type="button" className="btn btn-light mr-1" onClick={toggleEditMode}>
            Anuluj
          </button>
          <SaveButton isSaving={isAdding} label="Zapisz dane" className="btn btn-green" onClick={handleInvoiceAdd} />
        </Col>
      </Row>
    </FormProvider>
  )
}
