import * as React from 'react'
import { SubscriptionDetails } from '@modules/subscription/models'
import { BaseModalProps } from '@components/modals/types'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAppDispatch } from '@store/index'
import { useForm, useWatch } from 'react-hook-form'
import { useFormRequest } from '@components/hooks/use-api-request'
import { updateSubscriptionDetails } from '@store/slices/subscription-slice'
import { commonObjectPost } from '@store/actions/generic-actions'
import { Form } from '@hyper/forms/form'
import { Button, CustomInput, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { SpinInput } from '@components/spin-input'
import { asDecimal, formatPriceShort } from '@helpers/utils'
import { FormPlain, FormPlainError } from '@hyper/forms'
import { extractInnerRef } from '@helpers/forms'
import { PackageEntranceTicketKind } from '@models/package'

const FREE_OF_CHARGE_KIND_PRICE = '0.01'

interface FormInputs {
  amount: number
  kind: 'free_of_charge' | 'extra_sale'
}

interface Props extends BaseModalProps {
  subscriptionDetails: SubscriptionDetails
  ticketKind: PackageEntranceTicketKind
}

export const SubscriptionEntranceTickets: React.FC<Props> = ({ subscriptionDetails, ticketKind, toggleIsVisible }) => {
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const methods = useForm<FormInputs>({
    defaultValues: { amount: 1, kind: 'extra_sale' },
  })
  const [amount, kind] = useWatch({ control: methods.control, name: ['amount', 'kind'] })

  const isFreeOfCharge = kind === 'free_of_charge'
  const singlePrice =
    ticketKind === 'zoo_borysew'
      ? subscriptionDetails.product?.zoo_borysew_tickets_single_price_brutto
      : subscriptionDetails.product?.suntago_tickets_single_price_brutto
  const totalTicketPrice = asDecimal(singlePrice).mul(amount)
  const totalWithFreeOfChargeTicketPrice = asDecimal(FREE_OF_CHARGE_KIND_PRICE)

  const freeOfChargeOptionsExists = subscriptionDetails.options.filter(
    option => option.kind === ticketKind && option.price_brutto === FREE_OF_CHARGE_KIND_PRICE,
  ).length
  const extraSaleOptionsExists = subscriptionDetails.options.filter(
    option => option.kind === ticketKind && option.price_brutto !== FREE_OF_CHARGE_KIND_PRICE,
  ).length

  const isOptionActive =
    !subscriptionDetails.package_wholesale || (!freeOfChargeOptionsExists && !extraSaleOptionsExists)

  const isFreeOfChargeActive = isOptionActive || (freeOfChargeOptionsExists && !extraSaleOptionsExists)

  const isExtraSaleActive = isOptionActive || (extraSaleOptionsExists && !freeOfChargeOptionsExists)

  const { isLoading, action: onSubmit } = useFormRequest(async () => {
    methods.clearErrors()
    const newDetails = await commonObjectPost<SubscriptionDetails>(
      subscriptionDetails.urls.subscription_entrance_tickets,
      {
        amount,
        free_of_charge: isFreeOfCharge,
        price: isFreeOfCharge ? totalWithFreeOfChargeTicketPrice.toString() : totalTicketPrice.toString(),
        kind: ticketKind,
      },
    )

    dispatch(updateSubscriptionDetails(newDetails))
    addSuccessMessage('Sukces', 'Opcja została dodana')
    toggleIsVisible()
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>
        Dodaj bilety do wstępu <span className="text-semi-strong">{subscriptionDetails.number}</span>
      </ModalHeader>
      <ModalBody>
        <div className="d-flex align-items-center flex-wrap">
          <label className="mr-3 fw-semi-bold mb-0">Ilość paczek biletów</label>
          <SpinInput inputName="amount" inputProps={{ min: 1, max: 100 }} />
          <FormPlainError className="w-100 mt-1" name="amount" />
        </div>
        <FormPlain name="kind" formGroupClassName="mt-2 mb-0" colClassName="px-0">
          <label className="mb-2 fw-semi-bold">Rodzaj sprzedaży:</label>
          <CustomInput
            disabled={!isExtraSaleActive}
            type="radio"
            value="extra_sale"
            id="extra_sale"
            {...extractInnerRef(methods.register('kind'))}
            className="mb-1 d-flex align-items-center"
            label={
              <div className="fw-semi-bold">
                Sprzedaż dodatkowa{' '}
                <strong>{totalTicketPrice.isNaN() ? '-' : formatPriceShort(totalTicketPrice.toString())}</strong>
              </div>
            }
          />
          <CustomInput
            disabled={!isFreeOfChargeActive}
            label={
              <span className="fw-semi-bold">
                Wydanie biletów za <strong>{formatPriceShort(totalWithFreeOfChargeTicketPrice.toString())}</strong>
              </span>
            }
            type="radio"
            value="free_of_charge"
            id="free_of_charge"
            {...extractInnerRef(methods.register('kind'))}
          />
        </FormPlain>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton role="submit" className="btn btn-green" label="Zapisz" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
