import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { HousekeepingCleaningOrderToOrderRowFormInputs } from '@modules/housekeeping/cleaning-orders/to-order/row'
import { FormPlainError } from '@hyper/forms'
import { HousekeepingCompanyDetails } from '@modules/housekeeping/models'
import { FormCheckbox } from '@hyper/forms/form-checkbox'
import { useHousekeepingCleaningCompanies } from '@modules/housekeeping/users/use-housekeeping-cleaning-companies'

interface Props {
  resortId: number
}

export const HousekeepingOrderRowSelectWorkers: React.FC<Props> = ({ resortId }) => {
  const { control } = useFormContext<HousekeepingCleaningOrderToOrderRowFormInputs>()
  const selectedCompany = useWatch({ control, name: 'company' })

  const companies = useHousekeepingCleaningCompanies(resortId)
  const company = companies.find((company: HousekeepingCompanyDetails) => company.id === selectedCompany?.value)

  if (!company) {
    return <span className="text-center">--</span>
  }

  return (
    <>
      {company.workers.map((worker, index) => (
        <div key={worker.id} className="mb-1 font-12">
          <FormCheckbox
            value={worker.id}
            id={worker.id}
            name={`users.${index}`}
            label={<span className="text-semi-strong cursor-pointer">{worker.name}</span>}
          />
        </div>
      ))}
      {!company.workers.length && 'Brak pracowników'}
      <FormPlainError name="users" />
    </>
  )
}
