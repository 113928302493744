import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { PromocodeTouristVoucherDetails } from '@modules/promotions/promocode-tourist-voucher/models'
import { Button, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAppDispatch } from '@store/index'
import { useFormRequest } from '@components/hooks/use-api-request'
import { FormProvider, useForm } from 'react-hook-form'
import { FormInputMasked } from '@hyper/forms/form-input-masked'
import { commonObjectPut } from '@store/actions/generic-actions'
import { updatePromocodeTouristVoucherDetails } from '@store/slices/promocode-tourist-voucher-slice'
import { touristVoucherPaymentCodeMask } from '@modules/promotions/promocode-tourist-voucher/types'

interface Props extends BaseModalProps {
  promocodeTouristVoucherDetails: PromocodeTouristVoucherDetails
}

interface FormInputs {
  tourist_voucher_payment_code: string
}

export const PromocodeTouristVoucherCodesAddDialog: React.FC<Props> = ({
  promocodeTouristVoucherDetails,
  toggleIsVisible,
}) => {
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const methods = useForm<FormInputs>()
  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    dispatch(
      updatePromocodeTouristVoucherDetails(
        await commonObjectPut<PromocodeTouristVoucherDetails>(promocodeTouristVoucherDetails.urls.code, payload),
      ),
    )
    toggleIsVisible()
    addSuccessMessage()
  }, methods.setError)

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggleIsVisible}>Voucher turystyczny {promocodeTouristVoucherDetails.code}</ModalHeader>
        <ModalBody>
          <Row>
            <FormInputMasked
              placeholder="____-____-____-____"
              mask={touristVoucherPaymentCodeMask}
              name="tourist_voucher_payment_code"
              control={methods.control}
              label="Kod obsługi płatności"
            />
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="light" type="button" onClick={toggleIsVisible}>
            Zamknij
          </Button>
          <SaveButton isSaving={isLoading} className="btn btn-green" />
        </ModalFooter>
      </form>
    </FormProvider>
  )
}
