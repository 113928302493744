import * as React from 'react'
import { BookingRefund } from '@modules/accounting/refund/models'
import { RefundBookingsFiltersParams } from '@modules/accounting/refund/bookings/index'
import { RefundBookingsTableActions } from '@modules/accounting/refund/bookings/table-actions'
import Table, { TableHeaderValue } from '@components/table/table'
import { useSelectableRows } from '@components/table/use-selectable-rows'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { Row } from 'reactstrap'
import { RefundBookingsTableRow } from '@modules/accounting/refund/bookings/table-row'

interface RefundBookingsTableProps {
  bookingRefunds: BookingRefund[]
  isLoading: boolean
  filters: RefundBookingsFiltersParams
  setFilters: (filters: RefundBookingsFiltersParams) => void
}

const headerValues: TableHeaderValue<BookingRefund>[] = [
  { title: 'Status', sortField: 'deposit_transfer_status' },
  { title: 'Ośrodek', sortField: 'resort_id' },
  { title: 'Data wyjazdu', sortField: 'date_to' },
  { title: 'Data rozliczenia', sortField: 'close_datetime' },
  { title: 'Do zwrotu' },
  { title: 'Kaucja' },
  { title: 'Wykorzystana' },
  { title: 'Numer rezerwacji', sortField: 'reservation_number' },
  { title: 'Klient', sortField: 'name' },
  { title: '' },
]

export const RefundBookingsTable: React.FC<RefundBookingsTableProps> = ({
  bookingRefunds,
  isLoading,
  filters,
  setFilters,
}) => {
  const nonSelectable = bookingRefunds
    .filter(
      row =>
        ![
          'waiting_for_client_data',
          'missing_client_data',
          'client_data_complete',
          'p24_pending',
          'p24_completed',
          'p24_error',
        ].includes(row.deposit_transfer_status),
    )
    .map(row => row.id)

  const { selectableHeader, selectableCell, selectedRows, clearSelectedRows } = useSelectableRows<BookingRefund>(
    bookingRefunds,
    nonSelectable,
  )

  const handleSearchChange = (field, value) => setFilters({ ...filters, search: value })

  return (
    <>
      <Row className="mx-0">
        <RefundBookingsTableActions selectedRows={selectedRows} clearSelectedRows={clearSelectedRows} />
        <SearchFilter setValue={handleSearchChange} />
      </Row>
      <Table
        filters={filters}
        setFilters={setFilters}
        headerValues={headerValues}
        selectableHeader={selectableHeader}
        isLoading={isLoading}
      >
        {bookingRefunds.map(bookingRefund => (
          <RefundBookingsTableRow
            key={bookingRefund.id}
            bookingRefund={bookingRefund}
            selectableCell={selectableCell}
          />
        ))}
      </Table>
    </>
  )
}
