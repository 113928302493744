import * as React from 'react'
import { CustomInput } from 'reactstrap'
import classNames from 'classnames'
import { ReceptionBookingGuest } from '@models/booking'
import classnames from 'classnames'

interface ReceptionFeedingGuestSelectProps {
  guests: ReceptionBookingGuest[]
  feedingsForGuestId: number | undefined
  onSelectedGuestsChange: (selectedGuests: number[]) => void
  label: string
  className?: string
}

export const ReceptionFeedingGuestSelect: React.FC<ReceptionFeedingGuestSelectProps> = ({
  guests,
  feedingsForGuestId,
  onSelectedGuestsChange,
  label,
  className,
}) => {
  const [selectedGuests, setSelectedGuests] = React.useState<number[]>([])
  const [expanded, setExpanded] = React.useState(false)

  const toggleExpanded = () => {
    if (expanded) setSelectedGuests([])

    setExpanded(!expanded)
  }

  const isSelected = guestId => selectedGuests.includes(guestId)
  const allGuestsSelected = selectedGuests.length === guests.length

  const handleGuestFeedingChange = () => {
    if (!selectedGuests.length) return
    onSelectedGuestsChange(selectedGuests)
    toggleExpanded()
  }

  const handleSelectingAllGuests = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked) setSelectedGuests([])
    else setSelectedGuests(guests.map((guest: ReceptionBookingGuest) => guest.id))
  }

  const handleGuestSelection = (event: React.ChangeEvent<HTMLInputElement>, guestId: number) => {
    if (!selectedGuests.includes(guestId) && event.target.checked) setSelectedGuests([...selectedGuests, guestId])
    else setSelectedGuests(selectedGuests.filter(selected => selected !== guestId))
  }

  const options = guests.map((guest: ReceptionBookingGuest) => (
    <CustomInput
      key={guest.id}
      type="checkbox"
      id={`${feedingsForGuestId}-${guest.id}`}
      label={guest.name}
      className="py-1 cursor-pointer"
      checked={isSelected(guest.id)}
      onChange={event => handleGuestSelection(event, guest.id)}
    />
  ))

  const selectTitle = () => {
    if (allGuestsSelected) return 'Cała grupa'
    if (!selectedGuests.length) return 'wybierz'
    if (selectedGuests.length === 1)
      return guests.find((guest: ReceptionBookingGuest) => guest.id === selectedGuests[0])?.name
    return `${selectedGuests.length} Gości`
  }

  return (
    <div className={classnames('d-flex align-items-center', className)}>
      <span className="text-semi-strong font-14 d-block mr-2">{label}</span>
      <div className="reception__booking-check-in__feeding__guest-select__wrapper">
        <div
          className="d-flex align-items-center justify-content-between p-1 cursor-pointer border border-light "
          onClick={toggleExpanded}
        >
          <span>{selectTitle()}</span>
          <i className="uil uil-angle-down text-muted font-18" />
        </div>
        <div
          className={classNames(
            'position-absolute bottom-left p-1 reception__booking-check-in__feeding__guest-select__list',
            { 'reception__booking-check-in__feeding__guest-select__list--visible': expanded },
          )}
        >
          <CustomInput
            type="checkbox"
            id={`${feedingsForGuestId}-all`}
            label="Cała grupa"
            className="py-1 cursor-pointer"
            onChange={handleSelectingAllGuests}
            checked={allGuestsSelected}
          />
          {options}
        </div>
      </div>
      <div
        className={classNames('ml-2 reception__booking-check-in__feeding__guest-select__action', {
          'reception__booking-check-in__feeding__guest-select__action--visible': expanded,
        })}
      >
        <i
          role="submit-change-for-guest-button"
          className={classNames('uil uil-check font-18 cursor-pointer text-success', {
            'text-muted': !selectedGuests.length,
          })}
          onClick={handleGuestFeedingChange}
        />
        <i className="uil uil-times text-danger font-18 cursor-pointer" onClick={toggleExpanded} />
      </div>
    </div>
  )
}
