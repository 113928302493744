import * as React from 'react'
import { FeedingSpecialGuest } from '@modules/feeding/models'
import { FeedingSpecialGuestExportButtons } from '@modules/feeding/special-guests/export-buttons'
import Table, { TableHeaderValue } from '@components/table/table'
import { TableRowFeedingSpecialGuestTableRow } from '@modules/feeding/special-guests/table-row'
import { FeedingSpecialGuestFiltersParams } from '@modules/feeding/special-guests/index'

interface FeedingSpecialGuestTableProps {
  isLoading: boolean
  clients: FeedingSpecialGuest[]
  setFilters: (filters: FeedingSpecialGuestFiltersParams) => void
  filters: FeedingSpecialGuestFiltersParams
}

const headerValues: TableHeaderValue<FeedingSpecialGuest>[] = [
  {
    sortField: 'name',
    title: 'Imię i nazwisko',
  },
  {
    sortField: 'resort',
    title: 'Ośrodek',
  },
  {
    sortField: 'date_to',
    title: 'Waże do',
  },
  {
    sortField: 'is_active',
    title: 'Aktywny?',
  },
]

export const FeedingSpecialGuestTable: React.FC<FeedingSpecialGuestTableProps> = ({
  setFilters,
  clients,
  filters,
  isLoading,
}) => (
  <>
    <Table
      className="table-hover w-100"
      headerValues={headerValues}
      isLoading={isLoading}
      filters={filters}
      setFilters={setFilters}
    >
      {clients.map(specialGuest => (
        <TableRowFeedingSpecialGuestTableRow specialGuest={specialGuest} key={specialGuest.id} />
      ))}
    </Table>
    <FeedingSpecialGuestExportButtons />
  </>
)
