import * as React from 'react'
import Table from '@components/table/table'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { HousekeepingUser } from '@modules/housekeeping/models'
import { useAppData } from '@components/hooks/use-app-data'
import { HousekeepingUsersListTableRowActive } from '@modules/housekeeping/users/list/table-row-active'
import { useAppSelector } from '@store/index'
import { housekeepingCompaniesSelector } from '@store/slices/housekeeping-slice'
import { useModal } from '@components/modals/use-modal'
import { housekeepingRoleOptions } from '@modules/housekeeping/users/modal'
import { AccountUsersListTableRowAcquire } from '@modules/account/users/list/table-row-acquire'
import { NavigationPath } from '@models/routes'

interface Props {
  user: HousekeepingUser
}

export const HousekeepingUsersListTableRow: React.FC<Props> = ({ user }) => {
  const { resorts } = useAppData()

  const userResort = React.useMemo(
    () =>
      resorts
        .filter(row => user.resorts.includes(row.id))
        .map(row => row.name)
        .join(', '),
    [resorts, user.resorts],
  )

  const housekeepingCompany = useAppSelector(housekeepingCompaniesSelector).find(
    row => row.id === user.housekeeping_company,
  )?.name

  const [handleEdit] = useModal('HousekeepingUserModal', { user })
  const [handleChangePassword] = useModal('AccountUserChangePassword', { url: user.urls.change_password })
  const roles = housekeepingRoleOptions
    .filter(row => user[row.value])
    .map(row => row.label)
    .join(', ')

  return (
    <Table.Row>
      <Table.Cell>
        {user.first_name} {user.last_name}
      </Table.Cell>
      <Table.Cell>{user.email}</Table.Cell>
      <Table.Cell>{user.rcp_badge}</Table.Cell>
      <Table.Cell>{roles}</Table.Cell>
      <Table.Cell>{userResort}</Table.Cell>
      <Table.Cell>{housekeepingCompany}</Table.Cell>
      <Table.Cell>{user.phone}</Table.Cell>
      <HousekeepingUsersListTableRowActive user={user} />
      <Table.Cell>{toDefaultDateTimeFormat(user.last_login)}</Table.Cell>
      <Table.Cell className="text-right">
        <i
          className="uil-keyhole-square-full cursor-pointer text-muted font-18 mr-2"
          title="Zmień hasło"
          onClick={handleChangePassword}
        />
        {user.is_active && (
          <AccountUsersListTableRowAcquire returnUrl={NavigationPath.HousekeepingUsers} acquire={user.urls.acquire} />
        )}

        <i className="uil-pen cursor-pointer text-muted font-18" onClick={handleEdit} />
      </Table.Cell>
    </Table.Row>
  )
}
