import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { ReceptionTechnicalOrderStatusFormInputs } from '@modules/reception-technical-orders/reception-technical-order-status-modal/index'
import { FormPlain } from '@hyper/forms'
import { ButtonWithIcon } from '@components/button-with-icon'
import classNames from 'classnames'

export const ReceptionTechnicalOrderStatusFormCloseResult: React.FC = () => {
  const { setValue, control } = useFormContext<ReceptionTechnicalOrderStatusFormInputs>()
  const handleSubActionClose = React.useCallback(() => {
    setValue('sub_action', 'close')
  }, [setValue])

  const handleSubActionDelete = React.useCallback(() => {
    setValue('sub_action', 'delete')
  }, [setValue])

  const action = useWatch({ control, name: 'action' })
  const subAction = useWatch({ control, name: 'sub_action' })

  if (action !== 'close') {
    return null
  }

  return (
    <FormPlain
      name="result"
      label="Czy zadanie zostało wykonane?"
      formGroupClassName="px-0"
      labelSize={5}
      labelProps={{ className: 'px-0' }}
    >
      <div className="text-right d-flex justify-content-between">
        <ButtonWithIcon
          handleClick={handleSubActionClose}
          icon="uil-thumbs-up"
          text="Zrobione"
          color="green"
          btnClass={classNames('w-50 mr-1', { 'opacity-5': subAction !== 'close' })}
        />
        <ButtonWithIcon
          handleClick={handleSubActionDelete}
          icon="uil-trash"
          text="Usuń"
          color="dark"
          btnClass={classNames('w-50', { 'opacity-5': subAction !== 'delete' })}
        />
      </div>
    </FormPlain>
  )
}
