import * as React from 'react'
import { BarrierTicket } from '@models/barriers'
import { useModal } from '@components/modals/use-modal'

interface Props {
  ticket: BarrierTicket
  name: string
}

export const BarriersBaseListTableRowEdit = ({ ticket, name }: Props): JSX.Element => {
  const [handleClick] = useModal('BarrierTicketModal', { ticket, name })
  return <i onClick={handleClick} className="uil uil-pen cursor-pointer text-muted mr-1 font-16" title="Edytuj" />
}
