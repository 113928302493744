import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@store/index'
import { Package, PackageAggregation, PackageDetails } from '@models/package'
import { getPackageAppData, getPackageDetails, getPackages } from '@store/actions/package-actions'
import { SubscriptionKind } from '@modules/subscription/models'

interface PackageAppDataPricesPrice {
  accommodation_type: 'apartments' | 'houses'
  days: number
  id: number
  price_brutto: string
  price_netto: string
  type: 'HPI' | 'HPR'
  type_display: string
}

interface PackageAppDataPrices {
  values: PackageAppDataPricesPrice[]
  package_zoo_borysew_tickets_default_price_brutto: string
}

interface PackageAppDataHpiSourcePackages {
  codes: Record<SubscriptionKind, number>
  free_apartment_mountains_codes: Record<SubscriptionKind, number>
  free_apartment_sea_codes: Record<SubscriptionKind, number>
  free_house_mountains_codes: Record<SubscriptionKind, number>
  free_house_sea_codes: Record<SubscriptionKind, number>
}

export interface PackagesStats {
  package_count: number
  package_created: number
  package_created_count: number
  package_done: number
  package_code_count: number
  package_done_count: number
  package_not_used: number
  package_payin: number
  package_total: number
  package_total_netto: number
  apartments_sea: number
  houses_sea: number
  houses_mountains: number
  apartments_mountains: number
}

export interface PackageAppData {
  default_package_expire_after: string
  deposit_amount: number[]
  hpi_source_packages: PackageAppDataHpiSourcePackages
  notification_emails: [string, string][]
  prices: PackageAppDataPrices
  status: 'unknown' | 'loading' | 'ready'
}

interface PackageState {
  appData: PackageAppData
  packages: EntityState<Package>
  stats: PackagesStats
  packageDetails: PackageDetails | undefined
  aggregation: PackageAggregation | null
}

const packagesAdapter = createEntityAdapter<Package>()

export const emptyPackageState: PackageState = {
  packages: packagesAdapter.getInitialState(),
  packageDetails: undefined,
  aggregation: null,
  stats: {
    apartments_mountains: 0,
    apartments_sea: 0,
    houses_mountains: 0,
    houses_sea: 0,
    package_code_count: 0,
    package_count: 0,
    package_created: 0,
    package_created_count: 0,
    package_done: 0,
    package_done_count: 0,
    package_not_used: 0,
    package_payin: 0,
    package_total: 0,
    package_total_netto: 0,
  },
  appData: {
    notification_emails: [],
    hpi_source_packages: {
      codes: { brown: 0, gray: 0, silver: 0, gold: 0, platinum: 0 },
      free_apartment_mountains_codes: { brown: 0, gray: 0, silver: 0, gold: 0, platinum: 0 },
      free_apartment_sea_codes: { brown: 0, gray: 0, silver: 0, gold: 0, platinum: 0 },
      free_house_mountains_codes: { brown: 0, gray: 0, silver: 0, gold: 0, platinum: 0 },
      free_house_sea_codes: { brown: 0, gray: 0, silver: 0, gold: 0, platinum: 0 },
    },
    default_package_expire_after: '2020-05-28',
    prices: {
      values: [],
      package_zoo_borysew_tickets_default_price_brutto: '',
    },
    status: 'unknown',
    deposit_amount: [],
  },
}

const packageSlice = createSlice({
  name: 'packages',
  initialState: emptyPackageState,
  reducers: {
    setPackageDetails(state, action: PayloadAction<PackageDetails>) {
      state.packageDetails = action.payload
    },
    setPackageAppData(state, action: PayloadAction<Partial<PackageAppData>>) {
      state.appData = { ...state.appData, ...action.payload }
    },
    updatePackageDetails(state, action: PayloadAction<PackageDetails>) {
      if (state.packageDetails?.id === action.payload.id) {
        state.packageDetails = action.payload
      }

      packagesAdapter.upsertOne(state.packages, action.payload)
    },
  },
  extraReducers: builder => {
    builder.addCase(getPackageDetails.fulfilled, (state, action) => {
      state.packageDetails = action.payload
    })
    builder.addCase(getPackages.fulfilled, (state, action) => {
      packagesAdapter.setAll(state.packages, action.payload.results)
      state.aggregation = action.payload.aggregation
      state.stats = action.payload.stats
    })
    builder.addCase(getPackageAppData.fulfilled, (state, action) => {
      state.appData = { ...action.payload, status: 'ready' }
    })
  },
})

export const { updatePackageDetails, setPackageAppData } = packageSlice.actions

export const selectPackageAggregation = (state: RootState): PackageAggregation | null => state.packageState.aggregation

export const { selectAll: packagesSelector } = packagesAdapter.getSelectors(
  (state: RootState) => state.packageState.packages,
)

export default packageSlice.reducer
