import * as React from 'react'
import { Button, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { SaveButton } from '@hyper/button'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useForm } from 'react-hook-form'
import { createReportTask } from '@store/actions/report-tasks-actions'
import { endOfMonth, startOfMonth, startOfToday } from 'date-fns'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { BaseModalProps } from '@components/modals/types'
import { Form } from '@hyper/forms/form'
import { FormSelect } from '@hyper/forms/form-select'

interface FormInputs {
  created_after: Date | null
  created_before: Date | null
  resort: CustomReactSelectOption | undefined
  payment: CustomReactSelectOption | undefined
}

export const SHOP_PAYMENT_OPTIONS: CustomReactSelectOption[] = [
  {
    value: 'card',
    label: 'Karta',
  },
  {
    value: 'cash',
    label: 'Gotówka',
  },
  {
    value: 'wallet',
    label: 'Na pokój',
  },
  {
    value: 'band',
    label: 'Opaska',
  },
]

export const ReportsGeneratorShopReceipt: React.FC<BaseModalProps> = ({ toggleIsVisible }) => {
  const reportUrl = useAppSelector((state: RootState) => state.appState.appData.urls.reports.report_task_receipt)
  const dispatch = useAppDispatch()

  const methods = useForm<FormInputs>({
    mode: 'all',
    defaultValues: {
      created_after: startOfMonth(startOfToday()),
      created_before: endOfMonth(startOfToday()),
    },
  })

  const resorts = useAuthenticatedUser().resorts.map(({ id, name }) => ({
    value: id,
    label: name,
  }))

  const { isLoading, action: onSubmit } = useReportFormRequest(async (payload: FormInputs) => {
    const reportTask = await dispatch(
      createReportTask([
        reportUrl,
        {
          ...payload,
          resort: payload.resort?.value,
          payment: payload.payment?.value,
        },
      ]),
    )
    toggleIsVisible()
    return reportTask
  }, methods.setError)

  return (
    <Form onSubmit={onSubmit} methods={methods}>
      <ModalHeader toggle={toggleIsVisible}>Sklep - Raport Sprzedaży (paragony)</ModalHeader>
      <ModalBody>
        <Row>
          <FormSelect options={resorts} name="resort" selectProps={{ isClearable: true }} label="Ośrodek" />
          <FormSelect
            options={SHOP_PAYMENT_OPTIONS}
            name="payment"
            selectProps={{ isClearable: true }}
            label="Sposób płatności"
          />

          <DateFromToInput label="Data dodania" startDateName="created_after" endDateName="created_before" />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" label="Zleć raport" labelSaving="Zlecanie.." isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
