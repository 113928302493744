import * as React from 'react'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { AssetCategory } from '@models/cms-asset'
import { UpsellBookingsTabs, UpsellingTab } from '@modules/upselling/common/upsell-bookings-tabs'
import { ReceptionBooking } from '@models/reception'
import { UpsellBookingsDetailsReservationRow } from '@modules/upselling/upsell-booking-details/reservations/upsell-bookings-details-reservation-row'

type UpsellingReservationTab = 'upcoming' | 'closed'

const Tabs = [
  { icon: 'uil-file-upload-alt', name: 'Rezerwacje nadchodzące', kind: 'upcoming' as const },
  { icon: 'uil-file-check-alt', name: 'Rezerwacje zakończone', kind: 'closed' as const },
]

const headerValues: TableHeaderValue<AssetCategory>[] = [
  { title: 'Termin pobytu' },
  { title: 'Resort' },
  { title: 'Ulepszenia' },
  { title: 'Wartość rezerwacji' },
  { title: '' },
]

interface Props {
  bookings: ReceptionBooking[]
}

export const UpsellBookingsDetailsReservations = ({ bookings }: Props): JSX.Element => {
  const [activeTab, setActiveTab] = React.useState<UpsellingTab<UpsellingReservationTab>>(Tabs[0])

  const [filters, setFilters] = React.useState<TableFilters>({
    ordering: 'date_from',
    page: 1,
    page_size: 9999,
    search: '',
  })

  const reservations = bookings.filter(booking => {
    if (activeTab.kind === 'upcoming') {
      return ['initial', 'initial_waiting', 'confirmed'].includes(booking.status)
    }

    return booking.status === 'close'
  })

  const handleTabsChange = (tab: UpsellingTab<string>) => {
    setActiveTab(tab as UpsellingTab<UpsellingReservationTab>)
  }

  return (
    <div className="mt-3 mx-n3 px-3 bg-white pt-3">
      <UpsellBookingsTabs activeTab={activeTab} tabs={Tabs} onTabsChange={handleTabsChange} />
      <div>
        <Table filters={filters} setFilters={setFilters} headerValues={headerValues}>
          {reservations.map(reservation => (
            <UpsellBookingsDetailsReservationRow key={reservation.id} booking={reservation} />
          ))}
        </Table>
      </div>
    </div>
  )
}
