import * as React from 'react'
import classnames from 'classnames'
import { ReceptionBooking } from '@models/reception'
import { asDecimal, formatPrice, sumDecimalArray } from '@helpers/utils'
import { sum } from 'ramda'
import Table from '@components/table/table'

interface PriceBlock {
  title: string
  price: string | number
  className?: string
}

export const PriceBlock = ({ title, price, className }: PriceBlock) => (
  <div className={classnames('p-2 text-white reservation-list__summary-block', className)}>
    <span className="d-block mb-1">{title}</span>
    <strong>{price}</strong>
  </div>
)

interface ReservationListPaymentSummaryProps {
  reservations: ReceptionBooking[]
}

export const ReservationListPaymentSummary = ({ reservations }: ReservationListPaymentSummaryProps) => {
  const finalGross = formatPrice(
    sumDecimalArray(
      reservations.map(reservation =>
        asDecimal(reservation.final_brutto).plus(asDecimal(reservation.climatic_tax_brutto)),
      ),
    ).toNumber(),
  )

  const paidIn = formatPrice(
    sumDecimalArray(
      reservations.map(reservation => asDecimal(reservation.final_brutto).minus(asDecimal(reservation.rest_to_pay))),
    ).toNumber(),
  )

  const restToPay = formatPrice(sum(reservations.map(reservation => parseFloat(reservation.rest_to_pay))))

  if (!reservations.length) return null

  return (
    <Table.Row>
      <Table.Cell colSpan={6} className="text-right align-middle reservation-list__summary-cell">
        <strong className="mr-2">Razem w wybranym okresie:</strong>
      </Table.Cell>
      <Table.Cell className="reservation-list__summary-cell">
        <PriceBlock title="Należności:" price={finalGross} />
      </Table.Cell>
      <Table.Cell className="reservation-list__summary-cell">
        <PriceBlock title="Wpłaty:" price={paidIn} className="border-left border-right" />
      </Table.Cell>
      <Table.Cell className="reservation-list__summary-cell">
        <PriceBlock title="Do zapłaty:" price={restToPay} />
      </Table.Cell>
      <Table.Cell className="reservation-list__summary-cell" />
    </Table.Row>
  )
}
