import * as React from 'react'
import { NavigationTabs, NavigationTabsButton } from '@components/navigation-tabs'
import { NavigationPath } from '@models/routes'

interface AccountingRefundTabsProps {
  active: 'bookings' | 'transfers'
}

export const AccountingRefundTabs: React.FC<AccountingRefundTabsProps> = ({ active }) => {
  const buttons: NavigationTabsButton[] = [
    {
      title: 'Kaucje do zwrotu',
      isActive: active === 'bookings',
      link: NavigationPath.AccountingRefundBookings,
    },
    {
      title: 'Paczki przelewów',
      isActive: active === 'transfers',
      link: NavigationPath.AccountingTransferPackages,
    },
  ]
  return <NavigationTabs buttons={buttons} />
}
