import * as React from 'react'
import { ReservationCard } from '@modules/reservations/details/common/reservation-card'
import { ReceptionBookingDetails } from '@models/reception'
import { useAppData } from '@components/hooks/use-app-data'
import { Country } from '@models/dashboard'
import { ReceptionBookingCheckInPreviewPersonalDataRow } from '@modules/reception/checkin/preview/personal-data/reception-booking-check-in-preview-personal-data-row'
import { ImprovementIcon } from '@components/improvement-icon'
import { ReceptionNotificationSmsIcon } from '@modules/reception/common/reception-notification-sms-icon'
import { ReceptionNotificationEmailIcon } from '@modules/reception/common/reception-notification-email-icon'

interface Props {
  bookingDetails: ReceptionBookingDetails
  children?: React.ReactNode
}

export const ReceptionBookingCheckInPreviewPersonalData = ({ bookingDetails, children }: Props): JSX.Element => {
  const appData = useAppData()

  const country = appData.countries.find((country: Country) => country.id === bookingDetails.country)?.name ?? ''

  return (
    <ReservationCard title="Dane osobowe" cardClassName="mb-2">
      <ReceptionBookingCheckInPreviewPersonalDataRow
        title="Imię i nazwisko:"
        content={
          <div>
            {bookingDetails.name}
            {bookingDetails.package_vip && (
              <ImprovementIcon improvementCode="package_vip" className="text-secondary ml-1" />
            )}
          </div>
        }
      />
      <ReceptionBookingCheckInPreviewPersonalDataRow
        title="Telefon:"
        content={
          <div>
            {bookingDetails.phone}
            <ReceptionNotificationSmsIcon booking={bookingDetails} />
          </div>
        }
      />
      <ReceptionBookingCheckInPreviewPersonalDataRow
        title="Email:"
        content={
          <div>
            <span className="mr-1">{bookingDetails.email}</span>
            <ReceptionNotificationEmailIcon booking={bookingDetails} />
          </div>
        }
      />
      <ReceptionBookingCheckInPreviewPersonalDataRow
        title="Adres:"
        content={
          <div>
            <div className="mb-1">ul. {bookingDetails.street}</div>
            <div>
              {bookingDetails.postcode}, {bookingDetails.city}, {country}
            </div>
          </div>
        }
      />
      <ReceptionBookingCheckInPreviewPersonalDataRow title="Język Gościa:" content={country} />
      {children}
    </ReservationCard>
  )
}
