import * as React from 'react'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useApiRequest } from '@components/hooks/use-api-request'
import { PromotionsAppData } from '@store/reducers/promotions-reducers'
import { getPromotionsAppData } from '@store/actions/promotions-actions'

export const usePromotionsAppData = (): PromotionsAppData => {
  const appData = useAppSelector((state: RootState) => state.promotionsState.appData)
  const dispatch = useAppDispatch()

  const { action: fetchPromotionsAppData } = useApiRequest(async () => await dispatch(getPromotionsAppData()))

  React.useEffect(() => {
    if (appData.status === 'unknown') {
      fetchPromotionsAppData()
    }
  }, [appData.status])

  return appData
}
