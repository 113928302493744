import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { ReceptionBookingDetails, ReceptionBookingGroup } from '@models/reception'
import { StepGuestsGuestForm } from '@modules/reception/checkin/step-guests/step-guests-guest-form'
import { ButtonWithIcon } from '@components/button-with-icon'
import { DropTargetMonitor, useDrop } from 'react-dnd'
import { ReceptionBookingGuest } from '@models/booking'
import { commonObjectPut } from '@store/actions/generic-actions'
import { StepGuestGroupDroppableGhost } from '@modules/reception/checkin/step-guests/group/step-guest-group-droppable-ghost'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useHandleAxiosServerErrors } from '@helpers/handle-axios-errors'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useModal } from '@components/modals/use-modal'

interface DropItem {
  type: string
  guest: ReceptionBookingGuest
}

enum GroupDialogActions {
  ADD = 'add',
  EDIT = 'edit',
}

interface StepGuestGroupProps {
  name: string
  booking: ReceptionBookingDetails
  updateState: (booking: ReceptionBookingDetails) => void
  children: React.ReactElement | React.ReactElement[]
  group: ReceptionBookingGroup
}

export const StepGuestGroup: React.FC<StepGuestGroupProps> = ({ name, booking, updateState, children, group }) => {
  const [guestFormVisible, setGuestFormVisible] = React.useState(false)
  const [isGuestUpdating, setIsGuestUpdating] = React.useState(false)
  const { addSuccessNotification } = useNotificationHook()
  const handleAxiosServerErrors = useHandleAxiosServerErrors()
  const formWrapper = React.useRef<HTMLDivElement>(null)
  const user = useAuthenticatedUser()

  const handleDrop = async (dropData: DropItem) => {
    const { guest } = dropData

    if (guest.group_id === group.id) return

    setIsGuestUpdating(true)
    try {
      updateState(
        await commonObjectPut<ReceptionBookingDetails>(guest.urls.details, {
          type: guest.type,
          name: guest.name,
          group_id: group.id,
        }),
      )
      addSuccessNotification(`Przeniesiono gościa ${guest?.name} do grupy ${group.name}.`)
    } catch (error) {
      handleAxiosServerErrors(error)
    } finally {
      setIsGuestUpdating(false)
    }
  }

  const [{ hovered, draggedGuest }, drop] = useDrop({
    accept: 'BOOKING_GUEST',
    drop: handleDrop,
    collect: (monitor: DropTargetMonitor<DropItem>) => ({
      hovered: monitor.isOver() && monitor.getItem()?.guest.group_id !== group.id,
      draggedGuest: monitor.getItem()?.guest,
    }),
  })

  const toggleGuestFormVisibility = () => setGuestFormVisible(state => !state)

  const [toggleGuestGroupDialogVisibility] = useModal('StepGuestGroupDialog')

  const openGuestDialogForAction = (action: GroupDialogActions) => {
    toggleGuestGroupDialogVisibility(null, {
      group: action === GroupDialogActions.EDIT ? group : null,
      booking,
    })
  }

  React.useEffect(() => {
    formWrapper.current?.scrollIntoView({ block: 'center' })
  }, [guestFormVisible])

  return (
    <div className="reservation-guests__group" ref={drop} role="guest-drop-section">
      <div className="d-flex justify-content-between mb-2">
        <div className="d-flex align-items-center">
          <h4 className="d-inline-block text-secondary font-15">{name}</h4>
          {user.is_superuser && (
            <>
              <IconWithText
                icon="uil-edit-alt"
                iconSize="font-15"
                text="edytuj grupę"
                wrapperClassNames="mx-3 cursor-pointer text-semi-strong text-muted"
                onClick={() => openGuestDialogForAction(GroupDialogActions.EDIT)}
              />

              <IconWithText
                icon="uil-plus"
                iconSize="font-15"
                text="utwórz nową grupę"
                wrapperClassNames="cursor-pointer text-semi-strong text-muted"
                onClick={() => openGuestDialogForAction(GroupDialogActions.ADD)}
              />
            </>
          )}
        </div>
        {!guestFormVisible && (
          <ButtonWithIcon
            icon="uil-plus lh-initial"
            text="Dodaj Gościa"
            handleClick={toggleGuestFormVisibility}
            color="light"
            btnClass="btn-flat"
          />
        )}
      </div>
      <StepGuestGroupDroppableGhost
        isHovered={hovered}
        isGuestUpdating={isGuestUpdating}
        guest={draggedGuest}
        targetGroup={group}
      />
      {children}
      {guestFormVisible && (
        <StepGuestsGuestForm
          updateState={updateState}
          booking={booking}
          handleCancel={toggleGuestFormVisibility}
          ref={formWrapper}
          group={group}
        />
      )}
    </div>
  )
}
