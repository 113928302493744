import * as React from 'react'
import { Col, Row } from 'reactstrap'
import classNames from 'classnames'
import TextInput from 'react-autocomplete-input'
import 'react-autocomplete-input/dist/bundle.css'
import { Controller, useFormContext } from 'react-hook-form'
import { ReceptionNotificationDialogFormInputs } from '@modules/reception/notification'
import { FormPlainError } from '@hyper/forms'

interface ReceptionNotificationContentProps {
  disabled?: boolean
  content: string
  className?: string
  info?: React.ReactNode
}

export const notificationContentVariables = ['imieNazwisko', 'numerRezerwacji', 'dataPrzyjazdu', 'dataWyjazdu']

export const ReceptionNotificationContent: React.FC<ReceptionNotificationContentProps> = ({
  disabled,
  className,
  content,
  info,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<ReceptionNotificationDialogFormInputs>()

  /* eslint-disable @typescript-eslint/ban-ts-comment */

  return (
    <>
      <Controller
        control={control}
        name="content"
        render={({ field }) => (
          <TextInput
            {...field}
            options={notificationContentVariables}
            // @ts-ignore
            role="booking-sms-notification-message"
            type="textarea"
            disabled={disabled}
            className={classNames('text-semi-strong', className, { 'is-invalid': errors.content })}
          />
        )}
      />
      <FormPlainError name="content" />

      <Row className=" mt-1">
        <Col md={6} className=" font-12 text-semi-strong">
          {content.length} / {Math.ceil(content.length / 160)}
        </Col>
        {info}
      </Row>
    </>
  )
}
