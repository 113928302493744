import { ReceptionBookingDetails } from '@models/reception'
import { asDecimal, formatPrice, formatPriceShort } from '@helpers/utils'
import * as React from 'react'
import { parseISODate, toDefaultDateFormat } from '@helpers/date-helper'
import { IconWithTooltip } from '@components/icon-with-tooltip'
import Decimal from 'decimal.js'
import { compareAsc } from 'date-fns'

export interface InfoSummaryProps {
  reservation: ReceptionBookingDetails
}

const InfoSummary = ({ reservation }: InfoSummaryProps) => {
  const lowestClimaticPrice = React.useMemo(() => {
    const prices = reservation.prices.climatic.items.reduce((previousValue, climaticItem) => {
      const price = asDecimal(climaticItem.single_price_brutto)
      return price.gt(0) ? [...previousValue, price] : previousValue
    }, [])

    return formatPriceShort(Decimal.min(...(prices.length ? prices : [asDecimal(0)])).toString())
  }, [reservation.prices.climatic])

  const requiredPayment = reservation.prices.payments_summary.required.installment
  const restToPay = reservation.prices.payments_summary.rest.installment

  const requiredPaymentDate = toDefaultDateFormat(reservation.prices.payments_summary.required.required_date)

  const restToPayDate = React.useMemo(() => {
    const dates = [
      ...(reservation.prices.payments_summary.rest.required_date
        ? [parseISODate(reservation.prices.payments_summary.rest.required_date) as Date]
        : []),
    ].sort(compareAsc)

    return toDefaultDateFormat(dates[0])
  }, [reservation.prices.payments_summary.rest])

  return (
    <>
      <h5>Informacje dla rezerwującego</h5>
      <InfoSummaryRow shouldShow={asDecimal(requiredPayment).gt(0)}>
        <span>
          Płatność w wysokości <strong>{formatPrice(requiredPayment)}</strong> do <strong>{requiredPaymentDate}</strong>
        </span>
      </InfoSummaryRow>
      <InfoSummaryRow shouldShow={asDecimal(restToPay).gt(0)}>
        <span>
          Płatność w wysokości <strong>{formatPrice(restToPay)}</strong> do <strong>{restToPayDate}</strong>
        </span>
      </InfoSummaryRow>
      <InfoSummaryRow>
        Płatność <strong>kaucji</strong> w wysokości{' '}
        <strong>{formatPriceShort(reservation.prices.deposit_amount)}</strong>
        {reservation.prices.payments_summary?.rest.required_date_deposit && (
          <>
            <span> do </span>
            <strong>{toDefaultDateFormat(reservation.prices.payments_summary.rest.required_date_deposit)}</strong>
          </>
        )}
      </InfoSummaryRow>
      <InfoSummaryRow>
        Zameldowanie od godziny <strong>{reservation.arrival_time}</strong>,<br />
        Wymeldowanie do godziny <strong>{reservation.departure_time}</strong>
      </InfoSummaryRow>
      <InfoSummaryRow>
        Opłata klimatyczna <strong>od {lowestClimaticPrice} / os. za dzień</strong> <br />
        płatna podczas zameldowania
        <IconWithTooltip
          icon="uil-exclamation-circle text-secondary font-13 ml-1"
          tooltipPlacement="top"
          tooltipMessage={
            <ul className="px-3 py-2 mb-0 font-500 font-12">
              {reservation.prices.climatic.items.map(climaticItem => (
                <li key={climaticItem.name} className="text-nowrap text-left">
                  {climaticItem.name}: {formatPriceShort(climaticItem.single_price_brutto)}
                </li>
              ))}
            </ul>
          }
          tooltipId="email-preview"
          color="primary"
        />
      </InfoSummaryRow>
      <InfoSummaryRow>
        Opłata eksploatacyjna{' '}
        <strong>od {formatPrice(reservation.prices.single_service_price_brutto_from)} / os. za pobyt</strong> <br />
        płatna podczas zameldowania
      </InfoSummaryRow>
      <InfoSummaryRow>
        <strong>Anulowanie</strong> rezerwacji obciąża 100% jeśli pozostało mniej niż 30 dni
      </InfoSummaryRow>
    </>
  )
}

const InfoSummaryRow = ({ children, shouldShow = true }) => {
  if (!shouldShow) return null

  return (
    <div className="mb-1 reservation-create__step-summary__with-volume">
      <i className="uil-volume alert-success-lighten reservation-create__step-summary__volume" />
      {children}
    </div>
  )
}

export default InfoSummary
