import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Button, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useFormRequest } from '@components/hooks/use-api-request'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { BaseModalProps } from '@components/modals/types'
import { FormInput } from '@hyper/forms'
import { CommonTask } from '@modules/housekeeping/models'
import { updateHousekeepingCommonTask } from '@store/slices/housekeeping-slice'
import { commonObjectPatch } from '@store/actions/generic-actions'

export interface FormInputs {
  special_working_time: string
}

interface Props extends BaseModalProps {
  commonTask: CommonTask
}

export const SpecialWorkingTimeModal: React.FC<Props> = ({ commonTask, toggleIsVisible }) => {
  const methods = useForm<FormInputs>({
    defaultValues: {
      special_working_time: commonTask.special_working_time || '',
    },
  })
  const { addSuccessNotification } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    await dispatch(
      updateHousekeepingCommonTask(
        await commonObjectPatch<CommonTask>(commonTask.urls.details, {
          special_working_time: payload.special_working_time || null,
        }),
      ),
    )

    addSuccessNotification('Zmiany zostały zapisane')
    toggleIsVisible()
  }, methods.setError)

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggleIsVisible}>Edycja czasu pracy</ModalHeader>
        <ModalBody>
          <Row>
            <FormInput label="Czas pracy" placeholder="HH:MM:SS" name="special_working_time" />
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="light" type="button" onClick={toggleIsVisible}>
            Zamknij
          </Button>

          <SaveButton className="btn btn-green" isSaving={isLoading} />
        </ModalFooter>
      </form>
    </FormProvider>
  )
}
