import * as React from 'react'
import { useForm } from 'react-hook-form'
import { SubmitFilter } from '@modules/reception/common/filters/submit-filter'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import DateRangeInput from '@components/date/date-range-input'
import { HousekeepingArchiveFiltersParams } from '@modules/housekeeping/cleaning-orders/archive/index'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { Form } from '@hyper/forms/form'

interface HousekeepingArchiveFiltersProps {
  defaultFilters: HousekeepingArchiveFiltersParams
  filters: HousekeepingArchiveFiltersParams
  setFilters: (filters: HousekeepingArchiveFiltersParams) => void
}

export const HousekeepingArchiveFilters: React.FC<HousekeepingArchiveFiltersProps> = ({
  setFilters,
  filters,
  defaultFilters,
}) => {
  const methods = useForm<HousekeepingArchiveFiltersParams>({
    defaultValues: filters,
  })

  const onSubmit = (payload: HousekeepingArchiveFiltersParams) => setFilters(payload)

  const handleReset = () => {
    methods.reset()
    setFilters(defaultFilters)
  }

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  return (
    <Form methods={methods} onSubmit={onSubmit} className="d-flex form form-style-filter">
      <DateRangeInput startDateName="date_after" endDateName="date_before" />
      <SubmitFilter hideLabel handleReset={handleReset} />
      <SearchFilter hideLabel formGroupClassName="mb-0" colClassName="col-auto pr-0" />
    </Form>
  )
}
