import * as React from 'react'
import {
  FeedingExpandedRowReadOnly,
  ReceptionBookingFeedingExpandedRow,
} from '@modules/reception/checkin/step-feeding/reception-booking-feeding-expanded-row'
import { BookingGuestFeedingOption, ReceptionBookingDetails, SingleCalculatedFeedingPrice } from '@models/reception'
import * as R from 'ramda'

interface ReceptionBookingFeedingExpandedProps {
  feedingOptions: Partial<BookingGuestFeedingOption>[]
  prices: SingleCalculatedFeedingPrice[]
  booking: ReceptionBookingDetails
  setFeedingOptionForGuest: (
    guestId: number,
    dinner: boolean | undefined,
    breakfast: boolean | undefined,
    dates?: string[],
  ) => void
  readOnly: FeedingExpandedRowReadOnly
}

export const ReceptionBookingFeedingExpanded: React.FC<ReceptionBookingFeedingExpandedProps> = ({
  prices,
  feedingOptions,
  setFeedingOptionForGuest,
  booking,
  readOnly,
}) => {
  const feedingRows = feedingOptions.map((feedingOption: BookingGuestFeedingOption) => {
    const totalPriceGross = R.sum(
      prices
        .filter((priceData: SingleCalculatedFeedingPrice) => priceData.date === feedingOption.date)
        .map(row => row.price_brutto),
    )
    return (
      <ReceptionBookingFeedingExpandedRow
        isFirstDay={feedingOption.date == booking.date_from}
        isLastDay={feedingOption.date == booking.date_to}
        price={totalPriceGross}
        readOnly={readOnly}
        feedingOption={feedingOption}
        setFeedingOptionForGuest={setFeedingOptionForGuest}
        key={`${feedingOption.date}-${feedingOption.guest_id}`}
      />
    )
  })

  return (
    <table className="table table-borderless mb-1">
      <tbody>{feedingRows}</tbody>
    </table>
  )
}
