import * as React from 'react'
import { useAppDispatch } from '@store/index'
import { clearTicketsAction } from '@store/slices/barrier-slice'
import { extractSelectOptionsValues, useDocumentTitle } from '@helpers/utils'
import { PageTitle } from '@hyper/page-title'
import { Card, CardBody } from 'reactstrap'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { useApiRequest } from '@components/hooks/use-api-request'
import { getBarrierTickets } from '@store/actions/barriers-actions'
import { useDebounce } from 'rooks'
import { BarriersBaseListTable } from '@modules/barriers/base-list/table'
import { BarriersBaseListCreate } from '@modules/barriers/base-list/create'
import { BarriersBaseListFilter, BarriersBaseListFilterParams } from '@modules/barriers/base-list/filter'

interface Props {
  pageTitle: string
  menuLink: NavigationPath
  fetchUrl: string
  name: string
  showOnlyActiveCarsFilter: boolean
  canAdd: boolean
  canChange: boolean
  canRemove: boolean
  canPrint: boolean
}

const defaultFilters: BarriersBaseListFilterParams = {
  search: '',
  ordering: '-created',
  page: 1,
  page_size: 10,
  resorts: [],
  kind: null,
  only_active_cars: true,
}

export const BarriersBaseList = ({
  pageTitle,
  fetchUrl,
  showOnlyActiveCarsFilter,
  name,
  menuLink,
  canAdd,
  canChange,
  canRemove,
  canPrint,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch()
  useDocumentTitle(pageTitle)
  useMenuActive(menuLink)

  const [filters, setFilters] = React.useState<BarriersBaseListFilterParams>({ ...defaultFilters })

  const { isLoading, action: fetchTickets } = useApiRequest(async filters => {
    await dispatch(getBarrierTickets([fetchUrl, extractSelectOptionsValues(filters)]))
  })

  const fetchTicketsDebounced = React.useCallback(useDebounce(fetchTickets, 300), [])

  React.useEffect(() => {
    fetchTicketsDebounced(filters)
  }, [filters])

  React.useEffect(
    () => () => {
      dispatch(clearTicketsAction())
    },
    [],
  )

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitle} />
      <Card>
        <CardBody>
          {canAdd && <BarriersBaseListCreate createUrl={fetchUrl} name={name} />}
          <BarriersBaseListFilter
            defaultFilters={defaultFilters}
            filters={filters}
            setFilters={setFilters}
            showOnlyActiveCarsFilter={showOnlyActiveCarsFilter}
          />
          <BarriersBaseListTable
            name={name}
            isLoading={isLoading}
            filters={filters}
            setFilters={setFilters}
            canChange={canChange}
            canRemove={canRemove}
            canPrint={canPrint}
          />
        </CardBody>
      </Card>
    </>
  )
}
