import * as React from 'react'
import { CleaningOrder, CleaningOrderDetails, HousekeepingCleaningOrderType } from '@modules/housekeeping/models'
import { Button } from 'reactstrap'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useAppDispatch } from '@store/index'
import { getHousekeepingCleaningOrders } from '@store/actions/housekeeping-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { OrderNotes } from '@modules/housekeeping/cleaning-orders/common/row-notes'
import { useModal } from '@components/modals/use-modal'
import { HousekeepingCleaningOrderRowActionsSetPriority } from '@modules/housekeeping/cleaning-orders/to-order/actions/actions-priority'
import { useApiRequest } from '@components/hooks/use-api-request'
import { updateHousekeepingCleaningOrderDetails } from '@store/slices/housekeeping-slice'

interface HousekeepingCleaningOrderOrderedRowActionsProps {
  cleaningOrder: CleaningOrder
}

export const HousekeepingCleaningOrderOrderedRowActions: React.FC<HousekeepingCleaningOrderOrderedRowActionsProps> = ({
  cleaningOrder,
}) => {
  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()

  const handleRevertStatus = async () => await process(cleaningOrder.urls.revert_status, 'Zlecenie zostało wycofane')
  const handleStart = async () => await process(cleaningOrder.urls.start_working, 'Zlecenie zostało rozpoczęte')

  const handleOrderUpdate = (order: CleaningOrderDetails) => {
    dispatch(updateHousekeepingCleaningOrderDetails(order))
  }

  const [handleIsAssignOpen] = useModal('HousekeepingCleaningOrderAssignModal', {
    order: cleaningOrder,
    onOrderUpdate: handleOrderUpdate,
  })

  const { action: process } = useApiRequest(async (url, message) => {
    dispatch(updateHousekeepingCleaningOrderDetails(await commonObjectPost<CleaningOrderDetails>(url)))
    dispatch(
      getHousekeepingCleaningOrders([
        {
          resort: cleaningOrder.resort,
        },
        HousekeepingCleaningOrderType.ORDERED,
      ]),
    )

    addSuccessNotification(message)
  })

  return (
    <td className="housekeeping__orders_table__column text-nowrap text-right" width={350}>
      <OrderNotes order={cleaningOrder} />
      <HousekeepingCleaningOrderRowActionsSetPriority cleaningOrder={cleaningOrder} isCompact />

      <Button color="green" className="btn-sm" onClick={handleStart}>
        Rozpocznij
      </Button>
      <Button color="warning" className="text-white mx-1 btn-sm" onClick={handleIsAssignOpen}>
        Zmień osoby
      </Button>
      <Button color="danger" onClick={handleRevertStatus} className="btn-sm">
        Cofnij
      </Button>
    </td>
  )
}
