import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { BenefitProgramDetails } from '@modules/benefit-program/models'
import { useForm } from 'react-hook-form'
import { useCreateOrUpdateBenefitProgramMutation } from '@api/benefit-programs'
import { useHandleRtkQueryError } from '@components/hooks/use-handle-rtk-error'
import { Form } from '@hyper/forms/form'
import { Button, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { FormInput } from '@hyper/forms'
import { SaveButton } from '@hyper/button'
import { BenefitProgramCreateInputs } from '@modules/benefit-program/create'
import { useNotificationHook } from '@hyper/use-notification-hook'

interface Props extends BaseModalProps {
  benefitProgram: BenefitProgramDetails
}

export const BenefitProgramUserAddModal: React.FC<Props> = ({ benefitProgram, toggleIsVisible }) => {
  const methods = useForm<BenefitProgramCreateInputs>()
  const { addSuccessMessage } = useNotificationHook()

  const [createOrUpdateBenefitProgram, { error, isError, isLoading }] = useCreateOrUpdateBenefitProgramMutation()
  useHandleRtkQueryError(error, isError, methods.setError)

  const onSubmit = async (payload: BenefitProgramCreateInputs) => {
    await createOrUpdateBenefitProgram({
      data: payload,
      url: benefitProgram.urls.users,
      method: 'POST',
    }).unwrap()
    addSuccessMessage('Sukces', 'Osoba reprezentująca została dodana')

    toggleIsVisible()
  }

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Dodaj użytkownika</ModalHeader>
      <ModalBody>
        <Row>
          <FormInput name="first_name" label="Imię" colSize={6} />
          <FormInput name="last_name" label="Nazwisko" colSize={6} />
          <FormInput name="email" label="Adres e-mail" colSize={12} />
          <FormInput name="password" type="password" label="Hasło" colSize={6} />
          <FormInput name="confirmed_password" type="password" label="Powtórz hasło" colSize={6} />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
