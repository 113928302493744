import * as React from 'react'
import { TechnicalOrder } from '@models/technical-orders'
import Table from '@components/table/table'
import { Button } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'

interface Props {
  technicalOrder: TechnicalOrder
  children: React.ReactNode
}

export const ReceptionTechnicalOrdersTableRowActions: React.FC<Props> = ({ technicalOrder, children }) => {
  const [handleClick] = useModal('ReceptionTechnicalOrderStatusModal', { technicalOrder })

  return (
    <Table.Cell className="text-right vertical-align-middle text-nowrap">
      <div className="d-flex align-items-center justify-content-end">
        {children}
        <div style={{ minWidth: 120 }}>
          {!technicalOrder.forwarded_to_technical_order && (
            <Button color="green" onClick={handleClick}>
              Zmień status
            </Button>
          )}
        </div>
      </div>
    </Table.Cell>
  )
}
