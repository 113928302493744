import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectPost } from '@store/actions/generic-actions'
import { ReceptionBookingDetails } from '@models/reception'

interface Response {
  getFreshBooking: () => Promise<ReceptionBookingDetails>
  isRefreshing: boolean
}

export const useMetersRefresh = (booking: ReceptionBookingDetails): Response => {
  const { action: gerFreshBooking, isLoading: isRefreshing } = useApiRequest(
    async () => await commonObjectPost<ReceptionBookingDetails>(booking.urls.refresh_meters),
  )

  return { getFreshBooking: gerFreshBooking, isRefreshing }
}
