import * as React from 'react'
import { UpsellProductsForSale } from '@modules/upselling/upsell-booking-details/products/consts'
import { UpsellBookingsGastroProductSaleRow } from '@modules/upselling/upsell-booking-details/products/sale-card/upsell-bookings-gastro-product-sale-row'
import { GastroProductKind } from '@models/products'
import { ClientUser } from '@models/clients'
import { UpsellBookingsSubscriptionProductSaleRow } from '@modules/upselling/upsell-booking-details/products/sale-card/upsell-bookings-subscription-product-sale-row'
import { SubscriptionType } from '@modules/subscription/models'
import { UpsellBookingsSubscriptionContractProductSaleRow } from '@modules/upselling/upsell-booking-details/products/sale-card/upsell-bookings-subscription-contract-product-sale-row'

interface Props {
  name: string
  kind: string
  client: ClientUser
}

export const UpsellBookingsDetailsProductsSaleCardRow = ({ name, kind, client }: Props): JSX.Element => (
  <>
    {UpsellProductsForSale.gastro.some(product => product.kind === kind) && (
      <UpsellBookingsGastroProductSaleRow kind={kind as GastroProductKind} client={client} name={name} />
    )}

    {UpsellProductsForSale.subscription.some(product => product.type === kind) && (
      <UpsellBookingsSubscriptionProductSaleRow type={kind as SubscriptionType} client={client} name={name} />
    )}
    {kind === UpsellProductsForSale.subscriptionContract.kind && (
      <UpsellBookingsSubscriptionContractProductSaleRow client={client} name={name} />
    )}
  </>
)
