import * as React from 'react'
import { getGastroGenericProductTag } from '@modules/promotions/gastro-generic-product/utils'
import { GastroProductInfo } from '@models/products'
import { useProductsAppData } from '@modules/products/hooks/use-products-app-data'
import classNames from 'classnames'
import { BadgeWithIcon } from '@components/badges/BadgeWithIcon'

interface Props {
  gastroProductInfo: GastroProductInfo
  className?: string
  withSubscriptionContract?: boolean
}

export const GastroGenericProductData = ({
  gastroProductInfo,
  className,
  withSubscriptionContract = true,
}: Props): React.ReactNode => {
  const { gastro_card_tags } = useProductsAppData()

  const hasSubscription = gastroProductInfo.tags.includes('subscription_offer')

  return (
    <div className={classNames('row align-items-start mx-0', className)}>
      {withSubscriptionContract && (
        <Data title="Status subskrypcji:" className="mb-2">
          <BadgeWithIcon
            icon={hasSubscription ? 'mdi-check mdi' : 'mdi-close mdi'}
            title={hasSubscription ? 'aktywna' : 'brak'}
            variant={hasSubscription ? 'success' : 'danger'}
            className="px-1"
          />
        </Data>
      )}
      <Data title="Typ produktu">
        <ul className="list-unstyled mb-0">
          {gastroProductInfo.tags.map((tag, index) => (
            <li key={tag}>
              {getGastroGenericProductTag(tag, gastro_card_tags)?.name}{' '}
              {index < gastroProductInfo.tags.length - 1 && ','}
            </li>
          ))}
        </ul>
      </Data>
    </div>
  )
}

const Data = ({ className = '', title, children }) => (
  <>
    <div className={classNames('text-semi-strong col-5', className)}>{title}</div>
    <div className="col-7">{children}</div>
  </>
)
