import * as React from 'react'
import { asDecimal, formatPrice, sumDecimalArray } from '@helpers/utils'
import { PriceBlock } from '@modules/reservations/list/reservation-list-payment-summary'
import { SoldProductsReport } from '@store/actions/report-actions'
import * as R from 'ramda'

interface Props {
  soldProducts: SoldProductsReport[]
}

export const ReceptionSellersTableFooter: React.FC<Props> = ({ soldProducts }) => {
  const totalQuantity = R.sum(soldProducts.map(item => item.sum_quantity))
  const totalPriceBrutto = sumDecimalArray(soldProducts.map(item => asDecimal(item.sum_total_price_brutto)))

  return (
    <div className="reservation-list__summary d-flex align-items-center justify-content-center pr-5">
      <strong className="mr-2">Razem w wybranym okresie:</strong>
      <div className="d-inline-flex">
        <PriceBlock title="Ilość" price={String(totalQuantity)} />
        <PriceBlock title="Kwota brutto" price={formatPrice(totalPriceBrutto.toString())} />
      </div>
    </div>
  )
}
