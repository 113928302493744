import * as React from 'react'
import { ColAuto } from '@hyper/col-auto'
import { FormGroup, Input, Label } from 'reactstrap'
import { useAppDispatch, useAppSelector } from '@store/index'
import { useLocation } from 'react-router-dom'
import { getUserEvents } from '@store/actions/rcp-actions'
import { useForm } from 'react-hook-form'
import { extractInnerRef } from '@helpers/forms'
import { useDebounce } from 'rooks'
import { RcpMonth, RcpUserDetail } from '@models/rcp'
import { rcpMonthsSelector, setUserEventsDaysFilter } from '@store/slices/rcp-slice'

interface FilterFormProps {
  user: RcpUserDetail
}

interface FormInputs {
  range: string
}

export const FilterForm: React.FC<FilterFormProps> = ({ user }) => {
  const dispatch = useAppDispatch()
  const query = new URLSearchParams(useLocation().search)

  const queryYear = query.get('year') ? query.get('year') : new Date().getFullYear()
  const queryMonth = query.get('month') ? query.get('month') : new Date().getMonth() + 1

  const { register, watch } = useForm<FormInputs>({
    defaultValues: {
      range: `${queryMonth}-${queryYear}`,
    },
  })

  const monthList = useAppSelector(rcpMonthsSelector)

  const range = watch('range')

  const onRangeUpdate = useDebounce((month, year) => {
    dispatch(setUserEventsDaysFilter({ month: parseInt(month, 10), year: parseInt(year, 10) }))
    dispatch(getUserEvents(user.urls.events))
  }, 150)

  React.useEffect(() => {
    if (range) {
      const [month, year] = range.split('-')

      onRangeUpdate(month, year)
    }
  }, [range])

  return (
    <form className="row form mb-1">
      <ColAuto>
        <FormGroup>
          <Label>Wybierz miesiąc</Label>
          <Input style={{ width: 200 }} type="select" className="form-control" {...extractInnerRef(register('range'))}>
            {monthList.map((month: RcpMonth) => (
              <option key={month.month_year} value={month.month_year}>
                {month.month_verbose} {month.year}
              </option>
            ))}
          </Input>
        </FormGroup>
      </ColAuto>
    </form>
  )
}
