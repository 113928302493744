import * as React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FormPlainError } from '@hyper/forms'
import Select, { OptionsOrGroups, Props, StylesConfig } from 'react-select'
import CreatableSelect from 'react-select/creatable'

export const makeDefaultSelectOption = (
  options: CustomReactSelectOption[],
  baseValue: string | undefined | number | null | boolean,
  defaultValue: CustomReactSelectOption | undefined = undefined,
): CustomReactSelectOption | null => options.find(option => option.value === baseValue) || defaultValue || null

export interface CustomReactSelectOption<L = any, V = any> {
  label: L
  value: V
  disabled?: boolean
  context?: any
}

export interface CustomReactSelectOptionGroup {
  label: string
  options: CustomReactSelectOption[]
}

export interface CustomReactSelectProps extends Props {
  label?: React.ReactNode
  labelClassName?: string
  onKeyDown?: any
  fieldName: string
  isSelectMulti?: boolean
  options: OptionsOrGroups<any, any>
  selectStyles?: StylesConfig
  isCreatable?: boolean
}

export const CustomReactSelect: React.FC<CustomReactSelectProps> = ({
  label,
  labelClassName,
  onKeyDown,
  fieldName,
  noOptionsMessage,
  placeholder,
  options,
  isSelectMulti,
  selectStyles,
  isCreatable,
  ...params
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const hasError = !!errors[fieldName]

  const SelectComponent = isCreatable ? CreatableSelect : Select

  return (
    <>
      {label && (
        <label htmlFor={fieldName} className={labelClassName}>
          {label}
        </label>
      )}
      <Controller
        control={control}
        name={fieldName}
        render={({ field }) => (
          <SelectComponent
            onKeyDown={onKeyDown}
            isMulti={!!isSelectMulti}
            styles={{ ...getReactSelectStyles({ hasError }), ...selectStyles }}
            options={options}
            placeholder={placeholder || 'Wybierz..'}
            components={reactSelectComponents}
            inputId={fieldName}
            noOptionsMessage={noOptionsMessage}
            {...(isCreatable && {
              formatCreateLabel: userInput => `Wybierz własny: ${userInput.toUpperCase()}`,
            })}
            {...field}
            {...params}
          />
        )}
      />
      <FormPlainError errors={errors} name={fieldName} />
    </>
  )
}

export const getReactSelectStyles = ({ hasError }: { hasError: boolean }) => ({
  menu: provided => ({ ...provided, marginTop: 0, zIndex: 9999 }),
  singleValue: provided => ({ ...provided, color: '#313a46' }),
  option: (provided, { isSelected, isDisabled }) => ({
    ...provided,
    backgroundColor: isSelected ? '#dee2e6' : null,
    opacity: '1',
    borderBottom: '1px solid  #dee2e6',
    color: isDisabled ? '#cbcbcb' : isSelected ? 'text-dark' : 'text-muted',
    cursor: isDisabled ? 'initial' : 'pointer',
    '&:hover': { backgroundColor: isDisabled ? 'initial' : '#dee2e6' },
  }),
  menuList: provided => ({ ...provided, marginTop: 0, marginBottom: 0, paddingTop: 0, paddingBottom: 0 }),
  control: provided => ({
    ...provided,
    minHeight: '35px',
    border: `1px solid ${hasError ? '#dd3535' : '#dee2e6'}`,
    cursor: 'pointer',
    boxShadow: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    svg: { height: '15px', paddingRight: '5px' },
    div: {
      paddingInline: 0,
    },
  }),
})

export const reactSelectComponents = {
  IndicatorSeparator: () => null,
}
