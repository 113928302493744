import * as React from 'react'
import { Col, ModalBody, Row } from 'reactstrap'
import { newUserPredicator, ReceptionCheckReminders } from '@modules/reception/common/reception-check-reminders'
import { ReceptionReminder } from '@models/reception'
import classNames from 'classnames'
import { ReceptionBookingCheck } from '@modules/reception/common/reception-booking-check'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'

interface Props {
  modalTitle: string
  reminders: ReceptionReminder[]
  accessItemIcon: string
  children: React.ReactElement
  nextStep: () => void
  isSaving: boolean
  smallIcon?: boolean
}

export const ReceptionBookingCheckOutAccessItem: React.FC<Props> = ({
  modalTitle,
  reminders,
  accessItemIcon,
  children,
  nextStep,
  isSaving,
  smallIcon,
}) => {
  const user = useAuthenticatedUser()

  return (
    <>
      <ModalBody className="pb-0">
        {modalTitle && <h4>{modalTitle}</h4>}
        <ReceptionCheckReminders reminders={reminders} required={!newUserPredicator(user)} />
        <Row>
          <Col
            md={4}
            className={classNames('reception__booking-check-in__access-step__key-icon', {
              'reception__booking-check-in__access-step__key-icon--small': smallIcon,
            })}
          >
            <i className={classNames('text-success', accessItemIcon)} />
          </Col>
          <Col className="mt-3 ml-3">{children}</Col>
        </Row>
      </ModalBody>
      <ReceptionBookingCheck nextStep={nextStep} isSaving={isSaving} />
    </>
  )
}
