import * as React from 'react'
import { useAppSelector } from '@store/index'
import { receptionBookingDetailsSelector } from '@store/selectors/reception'
import { formatPrice } from '@helpers/utils'
import { differenceInDays } from 'date-fns'
import classNames from 'classnames'
import { parseISODate } from '@helpers/date-helper'
import { ReservationSidebarSummaryBoxBaseData } from '@modules/reservations/create/sidebar-summary-box/sidebar-summary-box-base-data'
import { ReservationSidebarSummaryBoxDataRow } from '@modules/reservations/create/sidebar-summary-box/sidebar-summary-box-data-row'
import { ReservationSidebarSummaryBoxSection } from '@modules/reservations/create/sidebar-summary-box/sidebar-summary-box-section'
import { ReservationSidebarSummaryBoxTotalPrices } from '@modules/reservations/create/sidebar-summary-box/sidebar-summary-box-total-prices'

interface Props {
  isVisible: boolean
}

export const ReservationSidebarSummaryBox = ({ isVisible }: Props): React.ReactNode => {
  const bookingDetails = useAppSelector(receptionBookingDetailsSelector)

  if (!isVisible || !bookingDetails) return null

  const numberOfNights = differenceInDays(
    parseISODate(bookingDetails.date_to) as Date,
    parseISODate(bookingDetails.date_from) as Date,
  )

  const bookingPrices = Object.keys(bookingDetails.prices).length ? bookingDetails.prices : null
  const improvements = bookingPrices ? bookingPrices.improvements.items : []

  return (
    <div className={classNames('reservation-create__summary-box__wrapper', { 'is-visible': isVisible })}>
      <div className="reservation-create__summary-box">
        <ReservationSidebarSummaryBoxBaseData bookingDetails={bookingDetails} numberOfNights={numberOfNights} />

        <ReservationSidebarSummaryBoxSection
          title="Wybrane ulepszenia"
          items={improvements}
          renderItem={improvement => (
            <ReservationSidebarSummaryBoxDataRow
              key={improvement.id}
              icon="uil-check"
              title={`${improvement.name} ${improvement.amount > 1 ? `x${improvement.amount}` : ''}`}
              content={formatPrice(improvement.price_brutto)}
            />
          )}
        />

        {bookingPrices && !!bookingPrices.feeding.items.length && (
          <>
            <hr className="reservation-create__summary-box__separator my-2" />
            <div className="px-2">
              <ReservationSidebarSummaryBoxDataRow
                icon="uil-restaurant"
                title="Wyżywienie"
                content={formatPrice(bookingPrices?.feeding.total_price_brutto ?? 0)}
              />
            </div>
          </>
        )}

        {bookingDetails.warranty && (
          <>
            <hr className="reservation-create__summary-box__separator my-2" />
            <div className="px-2">
              <ReservationSidebarSummaryBoxDataRow
                icon="uil-file-shield-alt"
                title="Opcja rezygnacji"
                content={formatPrice(bookingPrices?.warranty_price_brutto ?? 0)}
              />
            </div>
          </>
        )}

        <ReservationSidebarSummaryBoxSection
          title="Zastosowane zniżki"
          items={bookingDetails.promocode ? [bookingDetails.promocode] : []}
          renderItem={promocode => (
            <ReservationSidebarSummaryBoxDataRow
              key={promocode.code}
              icon="uil-tag-alt"
              title="Użyty kod"
              content={promocode.code}
            />
          )}
        />

        <ReservationSidebarSummaryBoxTotalPrices
          bookingPrices={bookingDetails.prices}
          numberOfNights={numberOfNights}
          accommodationTypeId={bookingDetails.accommodation_type_id}
        />
      </div>
    </div>
  )
}
