import * as React from 'react'
import { Col, Row } from 'reactstrap'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { FeedingClientsCreateDialogFormInputs } from '@modules/feeding/clients/create/index'
import { GuestsRowGuestsFeedingClientsCreateDialogGuestsRow } from '@modules/feeding/clients/create/guests-row'

export const GuestsFeedingClientsCreateDialogGuests: React.FC = () => {
  const { control } = useFormContext<FeedingClientsCreateDialogFormInputs>()
  const { fields, append, remove } = useFieldArray<FeedingClientsCreateDialogFormInputs>({
    control,
    name: 'guests',
  })

  const handleAddGuest = () => append({ name: '', age: 'adult' })

  return (
    <Row className="bg-grey form-transparent" style={{ marginLeft: -15, marginRight: -15 }}>
      <div className="hr hr-grey mb-2" />
      <Col sm={12} className="mb-2">
        <h5>Lista gości</h5>
      </Col>
      {fields.map((guest, index) => (
        <GuestsRowGuestsFeedingClientsCreateDialogGuestsRow remove={remove} index={index} key={index} />
      ))}
      <div className="hr hr-grey mt-2" />
      <Col sm={12} className="text-center pt-2">
        <button className="btn-pure text-dark text-semi-strong" type="button" onClick={handleAddGuest}>
          <i className="mdi mdi-plus" /> Dodaj gościa
        </button>
      </Col>
      <div className="hr hr-grey mt-2" />
    </Row>
  )
}
