import * as React from 'react'
import { TechnicalOrdersToAcceptTable } from '@modules/technical-orders/technical-orders-to-accept/table'

interface Props {
  resortId: number
}

export const TechnicalOrdersToAccept: React.FC<Props> = ({ resortId }) => (
  <>
    <h4 className="text-danger">Zadania priorytetowe:</h4>
    <TechnicalOrdersToAcceptTable priority={10} resortId={resortId} />
    <h4>Pozostałe zadania:</h4>
    <TechnicalOrdersToAcceptTable priority={0} resortId={resortId} />
  </>
)
