import * as React from 'react'
import classnames from 'classnames'
import { formatPriceShort } from '@helpers/utils'
import { ReceptionBookingCheckInImprovementsVipActive } from '@modules/reception/checkin/step-improvements/vip/reception-booking-check-in-improvements-vip-active'
import { deleteBookingImprovement } from '@store/actions/reception-actions'
import { useAppDispatch } from '@store/index'
import { ReceptionBookingDetails } from '@models/reception'
import { ImprovementAvailability, ReceptionBookingDetailsImprovement } from '@models/booking'
import { ReceptionBookingCheckInAddImprovementButton } from '@modules/reception/checkin/step-improvements/reception-booking-check-in-add-improvement-button'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useModal } from '@components/modals/use-modal'
import { commonObjectPost } from '@store/actions/generic-actions'
import { setReceptionBookingDetails } from '@store/slices/reception-slice'

interface ReceptionBookingCheckInImprovementActionsProps {
  booking: ReceptionBookingDetails
  vipImprovement: ReceptionBookingDetailsImprovement | undefined
  availableVipImprovement: ImprovementAvailability | undefined
  refetchAvailability: () => Promise<void>
}

export const ReceptionBookingCheckInImprovementVipActions: React.FC<ReceptionBookingCheckInImprovementActionsProps> = ({
  booking,
  vipImprovement,
  availableVipImprovement,
  refetchAvailability,
}) => {
  const dispatch = useAppDispatch()
  const isAlreadyAdded = !!vipImprovement

  const { addSuccessNotification } = useNotificationHook()

  const priceBrutto = vipImprovement ? vipImprovement.price_brutto : availableVipImprovement?.price_brutto || '0'

  const { isLoading, action: handleAddVip } = useApiRequest(async () => {
    if (!availableVipImprovement) return

    const updatedBooking = await commonObjectPost<ReceptionBookingDetails>(booking.urls.improvement_create, [
      { code: availableVipImprovement.code, date_from: booking.date_from, date_to: booking.date_to, amount: 1 },
    ])

    await refetchAvailability()

    dispatch(setReceptionBookingDetails(updatedBooking))

    addSuccessNotification('Pakiet VIP został dodany')
  })

  const handleDelete = async (reason: string) => {
    if (!vipImprovement) return

    await dispatch(deleteBookingImprovement(vipImprovement.urls.details, reason))
    await refetchAvailability()
    addSuccessNotification('Pakiet VIP został usunięty')
  }

  const [showBandExtensionInformationModal] = useModal('BandExtensionInformationModal', { onConfirm: handleAddVip })

  return (
    <>
      <td
        width={150}
        className={classnames('reception__booking-check-in__improvement-step__vip__prices__description', {
          'text-white': !isAlreadyAdded,
        })}
      >
        <div className="font-12">
          <span className="fw-semi-bold">Cena pakietu:</span>
          <span className="d-block font-11">Cena standardowa:</span>
          <span className="d-block font-11">Oszczędność</span>
        </div>
      </td>
      <td>
        <div
          className={classnames('font-12 reception__booking-check-in__improvement-step__vip__prices__amount', {
            'text-white': !isAlreadyAdded,
          })}
        >
          <span className="fw-semi-bold">{formatPriceShort(priceBrutto)}</span>
          <del className="font-11 d-block">{formatPriceShort(4390)}</del>
          <span className="font-11 d-block">{formatPriceShort(4390 - parseInt(priceBrutto))}</span>
        </div>
      </td>
      {isAlreadyAdded ? (
        <ReceptionBookingCheckInImprovementsVipActive
          onDelete={handleDelete}
          booking={booking}
          improvementPrice={priceBrutto}
        />
      ) : (
        <td className="reception__booking-check-in__improvement-step__vip__last-col">
          <ReceptionBookingCheckInAddImprovementButton
            improvementPrice={formatPriceShort(priceBrutto)}
            isSaving={isLoading}
            label="Dodaj"
            onClick={showBandExtensionInformationModal}
            btnClass="bg-primary rounded"
          />
          <div className="reception__booking-check-in__improvement-step__vip__bg">
            <i className="icon-vipBg" />
          </div>
        </td>
      )}
    </>
  )
}
