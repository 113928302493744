import * as React from 'react'
import { Button, ModalBody, ModalFooter } from 'reactstrap'
import { CmsResortDetails } from '@modules/cms/models'
import { CmsReservationAppResortFaqRow } from './faq-row'
import { useModal } from '@components/modals/use-modal'
import { BaseModalProps } from '@components/modals/types'

interface Props extends BaseModalProps {
  resort: CmsResortDetails
}

export const CmsReservationAppResortFaq: React.FC<Props> = ({ resort, toggleIsVisible }) => {
  const [handleAdd] = useModal('CmsReservationAppFaqModal', { resort })

  return (
    <>
      <ModalBody className="pt-0">
        {resort.faqs.map(faq => (
          <CmsReservationAppResortFaqRow resort={resort} faq={faq} key={faq.id} />
        ))}
        <p className="text-right mt-3">
          <button type="button" onClick={handleAdd} className="btn btn-sm btn-green">
            dodaj wpis
          </button>
        </p>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
      </ModalFooter>
    </>
  )
}
