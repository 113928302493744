import * as React from 'react'
import { ImprovementsStatsDetailsStatsItem } from '@modules/reservations/models'
import { withTooltip } from '@components/with-tooltip'
import { IconWithText } from '@components/icon-with-text'
import classnames from 'classnames'

type StatsItemKind = 'in' | 'out' | 'today'

interface Props {
  statsItem: ImprovementsStatsDetailsStatsItem
  tooltipId: string
  kind: StatsItemKind
  tooltipMessage: React.ReactNode
  onClick: () => void
}

export const ImprovementsStatsCell = ({ tooltipId, statsItem, kind, tooltipMessage, onClick }: Props): JSX.Element => (
  <IconWithDetailsTooltip
    tooltipId={tooltipId}
    text={statsItem[kind]}
    tooltipPlacement="top"
    tooltipMessage={tooltipMessage}
    onClick={onClick}
    {...propsForKind[kind]}
    wrapperClassNames={classnames(propsForKind[kind].wrapperClassNames, { 'opacity-5': statsItem[kind] === 0 })}
  />
)

const IconWithDetailsTooltip = withTooltip()(IconWithText)

const propsForKind = {
  in: {
    icon: 'uil-arrow-down-right font-13 lh-1',
    wrapperClassNames: 'text-success badge badge-success-lighten rounded m-01',
  },
  out: {
    icon: 'uil-arrow-up-right font-13 lh-1',
    wrapperClassNames: 'text-info badge badge-info-lighten rounded m-01',
  },
  today: {
    icon: 'uil-arrow-down font-13 lh-1',
    wrapperClassNames: 'badge badge-dark-lighten rounded m-01',
  },
}
