import * as React from 'react'
import { UpSellBookingDetails } from '@models/upselling'
import { UpsellBookingsDetailsProductsSaleCard } from '@modules/upselling/upsell-booking-details/products/sale-card/upsell-bookings-details-products-sale-card'
import { UpsellBookingsDetailsProductsListCard } from '@modules/upselling/upsell-booking-details/products/products-card/upsell-bookings-details-products-list-card'

interface Props {
  upsellBooking: UpSellBookingDetails
}

export const UpsellBookingsDetailsProducts = React.forwardRef(
  ({ upsellBooking }: Props, ref: React.RefObject<HTMLDivElement>): JSX.Element => {
    const [listExpanded, setListExpanded] = React.useState(false)

    const toggleListExpanded = () => {
      setListExpanded(state => !state)
    }

    return (
      <div className="my-3 mx-n3 px-3 bg-white pt-3 pb-1" ref={ref}>
        <div className="d-flex">
          <UpsellBookingsDetailsProductsSaleCard
            upsellBooking={upsellBooking}
            listExpanded={listExpanded}
            toggleListExpanded={toggleListExpanded}
          />
          <UpsellBookingsDetailsProductsListCard upsellBooking={upsellBooking} listExpanded={listExpanded} />
        </div>
      </div>
    )
  },
)
