import * as React from 'react'
import { PromocodeGroup, PromocodePrefix } from '@models/promocode'
import { Button, Col, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { PromocodePrefixDetailsDialogDates } from '@modules/promocode/promocode-prefixes/promocode-prefix-details-dialog'
import { formatDistanceToNow, parseISO } from 'date-fns'
import { pl } from 'date-fns/locale'
import { DetailsColRow } from '@hyper/details-col-row'
import { yearsPronunciation } from '@helpers/pronunciation'
import { PromocodeGroupEditDialogExportButton } from '@modules/promocode/promocode-list/promocode-edit/promocode-group-edit-dialog-export-button'
import { BaseModalProps } from '@components/modals/types'
import { ContentLoader } from '@components/content-loader'
import { useModal } from '@components/modals/use-modal'
import { RootState, useAppSelector } from '@store/index'
import { commonObjectGet } from '@store/actions/generic-actions'

interface Props extends BaseModalProps {
  promocodeGroup: PromocodeGroup
}

export const PromocodeGroupDetailsDialog: React.FC<Props> = ({ toggleIsVisible, promocodeGroup }) => {
  const [prefix, setPrefix] = React.useState<PromocodePrefix>()
  const url = useAppSelector((state: RootState) => state.appState.appData.urls.rent.promocode_prefixes)

  const fetchPrefix = async () => setPrefix(await commonObjectGet<PromocodePrefix>(`${url}${promocodeGroup.prefix_id}`))

  React.useEffect(() => {
    fetchPrefix()
  }, [])

  const [handleEdit] = useModal('PromocodeGroupEditDialog', { promocodeGroup }, { persist: true })

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>{promocodeGroup.name}</ModalHeader>
      <ModalBody>
        <ContentLoader isLoading={typeof prefix === 'undefined'}>
          <>
            {prefix && (
              <>
                <DetailsColRow
                  label="Prefiks"
                  value={<span className="text-semi-strong"> {prefix.name}</span>}
                  className="mb-3 mt-1"
                />
                <DetailsColRow
                  label="Wartość"
                  value={
                    <span className="text-semi-strong">
                      <i className="uil uil-usd-circle font-16 text-muted" /> {prefix.promo_value_display}
                    </span>
                  }
                />
              </>
            )}
            <DetailsColRow
              label="Data utworzenia"
              value={
                <span className="text-semi-strong">
                  <i className="uil-plus-square font-16 text-muted" /> {promocodeGroup.created_at} przez{' '}
                  {promocodeGroup.created_by}
                </span>
              }
            />
            <DetailsColRow
              label="Ważny do"
              value={
                <span className="text-semi-strong">
                  <i className="uil uil-calendar-alt font-16 text-muted" />

                  {promocodeGroup.relative_date_years ? (
                    `+ ${promocodeGroup.relative_date_years} ${yearsPronunciation(promocodeGroup.relative_date_years)}`
                  ) : promocodeGroup.expire_after ? (
                    <>
                      {promocodeGroup.expire_after} (
                      {formatDistanceToNow(parseISO(String(promocodeGroup.expire_after)), { locale: pl })})
                    </>
                  ) : (
                    'Bezterminowy'
                  )}
                </span>
              }
            />
            <DetailsColRow
              label="Pozostała liczba kodów"
              value={
                <span className="text-semi-strong">
                  {promocodeGroup.codes_amount - promocodeGroup.codes_amount_used} z {promocodeGroup.codes_amount}
                </span>
              }
            />
            <Row>
              <Col sm={12} className="p-0">
                <div className="hr hr-grey mb-3" />
              </Col>
            </Row>
            <DetailsColRow
              label="Opis"
              value={<span className="text-linebreaksbr">{promocodeGroup.description}</span>}
            />
            <Row>
              <Col sm={12} className="p-0">
                <div className="hr hr-grey mb-3" />
              </Col>
            </Row>
            {prefix && (
              <DetailsColRow
                label="Daty obowiązywania"
                className="mb-0"
                value={<PromocodePrefixDetailsDialogDates promocodePrefix={prefix} />}
              />
            )}
          </>
        </ContentLoader>
      </ModalBody>

      <ModalFooter style={{ display: 'block' }}>
        <Row>
          <Col md={6} className="px-0">
            <PromocodeGroupEditDialogExportButton promocodeGroup={promocodeGroup} />
          </Col>
          <Col md={6} className="px-0 text-right">
            <Button color="light" onClick={handleEdit} className="mr-1">
              Edytuj
            </Button>
            <Button color="green" onClick={toggleIsVisible}>
              Zamknij
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </>
  )
}
