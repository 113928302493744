import * as React from 'react'
import { BandScanningGuest } from '@components/band-scanning/guests-list/band-scanning-guest'
import { ReceptionBookingDetails } from '@models/reception'
import { BandScanningStatus, BandsToScanQueueItem } from '@components/band-scanning/models'
import { BandScanningGuestsListHeader } from '@components/band-scanning/guests-list/band-scanning-guests-list-header'

interface Props {
  bookingDetails: ReceptionBookingDetails
  bandsToScanQueue: BandsToScanQueueItem[]
  getBandStatus: (bandId: number) => BandScanningStatus
  nextScanDate: Date | null
  onRescan: (bandId: number) => void
}

export const BandScanningGuestsList = React.forwardRef(
  (
    { bookingDetails, bandsToScanQueue, getBandStatus, nextScanDate, onRescan }: Props,
    ref: React.RefObject<HTMLDivElement>,
  ): JSX.Element => {
    const [refFor, setRefFor] = React.useState<number | null>(null)

    const currentlyScanningBand = bandsToScanQueue.find(({ isScanning }) => isScanning)

    const bands = bookingDetails.bands.reduce((prev, curr) => {
      const guest = bookingDetails.guests.find(guest => guest.id === curr.guest_id)
      return [...prev, { guest, band: curr }]
    }, [])

    const getScanningResultForBand = (bandId: number) => {
      const band = bandsToScanQueue.find(({ bandId: id }) => id === bandId)
      return band?.scanResult
    }

    React.useEffect(() => {
      if (!bandsToScanQueue.length) return

      setRefFor(currentlyScanningBand?.bandId || null)
    }, [currentlyScanningBand?.bandId])

    return (
      <div className="mt-4">
        <BandScanningGuestsListHeader bookingBands={bookingDetails.bands} />
        <div className="my-2">
          {bands.map(({ band, guest }) => (
            <BandScanningGuest
              ref={refFor === band.id ? ref : undefined}
              key={band.id}
              band={band}
              guest={guest}
              status={band.state === 'RELEASED' ? 'success' : getBandStatus(band.id)}
              isChangeDisabled={!!currentlyScanningBand || !!nextScanDate}
              scanningResult={getScanningResultForBand(band.id)}
              onRescan={onRescan}
            />
          ))}
        </div>
      </div>
    )
  },
)
