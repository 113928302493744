import * as React from 'react'
import Table from '@components/table/table'
import classNames from 'classnames'
import { asDecimal, formatPriceShort } from '@helpers/utils'
import { useAppSelector } from '@store/index'
import { selectPackageAggregation } from '@store/slices/package-slice'

const TAX_DIVIDER = 1.23

interface SummaryBlock {
  value: number | string
  className?: string
}

export const SummaryBlock = ({ value, className }: SummaryBlock) => (
  <div className={classNames('text-white package-list__summary-block', className)}>
    <strong>{value}</strong>
  </div>
)

export const PackageListTableSummaryRow: React.FC = () => {
  const aggregation = useAppSelector(selectPackageAggregation)

  const priceNettoSum = asDecimal(aggregation?.price_brutto_sum).div(TAX_DIVIDER).toString()

  return (
    <Table.Row>
      <Table.Cell colSpan={7} className="text-right align-middle package-list__summary-cell">
        <strong className="mr-2">Razem w wybranym okresie:</strong>
      </Table.Cell>
      <Table.Cell className="package-list__summary-cell">
        <SummaryBlock value={formatPriceShort(priceNettoSum ?? 0)} className="border-right" />
      </Table.Cell>
      <Table.Cell className="package-list__summary-cell">
        <SummaryBlock value={formatPriceShort(aggregation?.price_brutto_sum ?? 0)} className="border-right" />
      </Table.Cell>
      <Table.Cell className="package-list__summary-cell">
        <SummaryBlock value={aggregation?.houses_sea_sum ?? 0} className="border-right" />
      </Table.Cell>
      <Table.Cell className="package-list__summary-cell">
        <SummaryBlock value={aggregation?.apartments_sea_sum ?? 0} className="border-right" />
      </Table.Cell>
      <Table.Cell className="package-list__summary-cell">
        <SummaryBlock value={aggregation?.houses_mountains_sum ?? 0} className="border-right" />
      </Table.Cell>
      <Table.Cell className="package-list__summary-cell">
        <SummaryBlock value={aggregation?.houses_sea_sum ?? 0} />
      </Table.Cell>
      <Table.Cell className="package-list__summary-cell" />
    </Table.Row>
  )
}
