import * as React from 'react'
import Table from '@components/table/table'
import { TechnicalOrder } from '@models/technical-orders'
import { useTechnicalOrderInfo } from '@modules/technical-orders/hooks/use-technical-order-info'

interface Props {
  technicalOrder: TechnicalOrder
  width?: number
}

export const TechnicalOrdersTodoRowUsers: React.FC<Props> = ({ technicalOrder, width }) => {
  const { company } = useTechnicalOrderInfo(technicalOrder)

  const usersName = React.useMemo(
    () =>
      technicalOrder.users.map((row, index) => (
        <span key={row.id} title={row.email}>
          {row.first_name} {row.last_name}
          {index !== technicalOrder.users.length - 1 && ', '}
        </span>
      )),

    [technicalOrder.users],
  )

  return (
    <Table.Cell className="vertical-align-middle" style={{ width }}>
      {usersName} {!!company && <span className="d-block font-10">{company.name}</span>}
    </Table.Cell>
  )
}
