import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { ApplicationTypes } from '@modules/housekeeping/applications/models'

export const HousekeepingApplicationsView: React.FC = () => {
  const navigate = useNavigate()
  const user = useAuthenticatedUser()

  React.useEffect(() => {
    navigate(
      NavigationPath.HousekeepingApplicationsWithParams.replace(':resort_id', String(user.resorts[0].id)).replace(
        ':type',
        String(ApplicationTypes.faults),
      ),
    )
  }, [])

  return null
}
