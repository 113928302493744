import * as React from 'react'
import { HouseKeepingCleaningOrderAggregation } from '@modules/reports/housekeeping-users-report/models'
import { getHoursMinutesFromInterval, secondsToInterval } from '@helpers/date-helper'

interface HousekeepingUserReportStatsTimeProps {
  aggregation: HouseKeepingCleaningOrderAggregation
}

export const HousekeepingUserReportStatsTime: React.FC<HousekeepingUserReportStatsTimeProps> = ({ aggregation }) => (
  <div className="d-flex justify-content-around">
    <div>
      <span className="font-14 text-semi-strong">Łączny czas pracy</span>
      <strong className="d-block font-20">do wynagrodzenia:</strong>
      <strong className="font-36 mt-2">
        {getHoursMinutesFromInterval(secondsToInterval(aggregation.total_working_time_to_pay))}
      </strong>
    </div>
    <div>
      <span className="font-14 text-semi-strong">Czas realizacji</span>
      <strong className="d-block font-20">zadań w aplikacji:</strong>
      <strong className="font-36 mt-2">
        {getHoursMinutesFromInterval(secondsToInterval(aggregation.total_working_time))}
      </strong>
    </div>
  </div>
)
