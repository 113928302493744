import * as React from 'react'
import { useForm } from 'react-hook-form'
import { EservicePinPad } from '@models/eservice'
import { BandReader, Tablet } from '@models/booking'

interface Props {
  defaultValue: number | undefined
  devices: EservicePinPad[] | BandReader[] | Tablet[]
  setDevice: (device: number) => void
  toggleEdit: () => void
  clearValue: () => void
}

interface FormInputs {
  device: number | undefined
}

export const AccountUserDesktopModalDeviceEdit = ({
  toggleEdit,
  clearValue,
  defaultValue,
  devices,
  setDevice,
}: Props): JSX.Element => {
  const { register, getValues } = useForm<FormInputs>({
    defaultValues: {
      device: defaultValue,
    },
  })

  const handleRemove = React.useCallback(async () => {
    await clearValue()
    toggleEdit()
  }, [clearValue, toggleEdit])

  const handleAccept = async () => {
    const deviceId = getValues('device')
    if (deviceId) {
      await setDevice(deviceId)
    }
  }

  return (
    <>
      <div className="font-11">Ustaw tymczasowo</div>
      <div className="d-flex align-items-center">
        <select {...register('device', { valueAsNumber: true })} className="form-control form-control-sm">
          {devices.map(device => (
            <option key={device.key} value={device.id}>
              {device.name}
            </option>
          ))}
        </select>
        <i className="uil-check cursor-pointer font-18 mx-1" onClick={handleAccept} />
        <i className="uil-multiply cursor-pointer font-18" onClick={handleRemove} />
      </div>
    </>
  )
}
