import * as React from 'react'
import { NotificationTemplatesSmsCreateButton } from '@modules/cms/notification-templates/sms/create-button'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { FormSelect } from '@hyper/forms/form-select'
import { ColAuto } from '@hyper/col-auto'
import { YesNoOptions } from '@helpers/consts'
import { SmsTemplateGroupOptions } from '@modules/cms/notification-templates/sms/modal'
import { FormProvider, useForm } from 'react-hook-form'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { NotificationTemplatesSmsListTableFilters } from '@modules/cms/notification-templates/sms/index'
import { Button } from 'reactstrap'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'

interface Props {
  defaultFilters: NotificationTemplatesSmsListTableFilters
  filters: NotificationTemplatesSmsListTableFilters
  setFilters: (filters: NotificationTemplatesSmsListTableFilters) => void
}

export const NotificationTemplatesSmsFilters = ({ setFilters, filters, defaultFilters }: Props): JSX.Element => {
  const methods = useForm<NotificationTemplatesSmsListTableFilters>({ defaultValues: defaultFilters })
  const user = useAuthenticatedUser()

  const handleReset = () => {
    methods.reset()
    setFilters(defaultFilters)
  }

  const onSubmit = async payload => setFilters({ ...filters, ...payload })

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  return (
    <FormProvider {...methods}>
      {user.hasPerm(UserPermission.NotificationCanManage) && <NotificationTemplatesSmsCreateButton />}
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <FormSelect
            label="Grupa"
            options={SmsTemplateGroupOptions}
            name="group"
            formPlainProps={{ colStyle: { width: 200, maxWidth: 200 } }}
            selectProps={{ isClearable: true }}
          />
          <ColAuto className="px-0">
            <FormSelect
              options={YesNoOptions}
              name="is_active"
              label="Aktywny"
              selectProps={{ isClearable: true }}
              formPlainProps={{ colStyle: { width: 200 } }}
            />
          </ColAuto>
          {methods.formState.isDirty && (
            <Button color="light" className="ml-1 btn-flat mt-label" type="button" onClick={handleReset}>
              Wyczyść
            </Button>
          )}
        </div>

        <SearchFilter colClassName="col-3 pr-0" />
      </div>
    </FormProvider>
  )
}
