import * as React from 'react'
import Table from '@components/table/table'
import { FloorHeatingListFilterParams } from '@modules/floor-heating/list/filter'
import { FloorHeatingListTableRow } from '@modules/floor-heating/list/table-row'
import { EnhancedFloorHeatingMeasurement, FloorHeatingAvailableZones } from '@modules/floor-heating/models'
import { useFloorHeatingAppData } from '@modules/floor-heating/use-floor-heating-app-data'

interface Props {
  measurements: EnhancedFloorHeatingMeasurement[]
  isLoading: boolean
  filters: FloorHeatingListFilterParams
  setFilters: (filters: FloorHeatingListFilterParams) => void
}

export const FloorHeatingListTable: React.FC<Props> = ({ isLoading, measurements, filters, setFilters }) => {
  const { appData } = useFloorHeatingAppData()
  const headerValues = getHeaderValues(appData?.available_zones)

  return (
    <Table
      className="table-hover w-100"
      headerValues={headerValues}
      isLoading={isLoading}
      filters={filters}
      setFilters={setFilters}
    >
      {measurements.map(measurement => (
        <FloorHeatingListTableRow key={measurement.apartment_name} measurement={measurement} />
      ))}
    </Table>
  )
}

const getHeaderValues = (availableZones: FloorHeatingAvailableZones | undefined) => [
  { title: 'Id. apartamentu', sortField: 'apartment_name' },
  { title: 'Goście w środku' },
  { title: 'Data odczytu' },
  { title: 'Odczyt poprawny' },
  { title: 'Otwarte okna' },
  { title: availableZones?.zone_7_temperature ?? 'Strefa 7' },
  { title: availableZones?.zone_8_temperature ?? 'Strefa 8' },
  { title: availableZones?.zone_9_temperature ?? 'Strefa 9' },
  { title: availableZones?.zone_a_temperature ?? 'Strefa A' },
  { title: 'Błąd' },
  { title: '' },
]
