import * as React from 'react'
import { TransferPackage, TransferPackageDetails } from '@modules/accounting/refund/models'
import { commonObjectGet } from '@store/actions/generic-actions'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { TransferPackageEditModalForm } from '@modules/accounting/refund/transfer-packages/edit/form'
import { TransferPackageEditModalGenerateButton } from '@modules/accounting/refund/transfer-packages/edit/generate-button'
import { BaseModalProps } from '@components/modals/types'

interface Props extends BaseModalProps {
  transferPackage: TransferPackage
}

export const TransferPackageEditModal: React.FC<Props> = ({ transferPackage, toggleIsVisible }) => {
  const [transferPackageDetails, setTransferPackageDetails] = React.useState<TransferPackageDetails | undefined>(
    undefined,
  )

  const fetchTransferPackagesDetails = async () =>
    setTransferPackageDetails(await commonObjectGet<TransferPackageDetails>(transferPackage.urls.details))

  React.useEffect(() => {
    fetchTransferPackagesDetails()
  }, [])

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>
        <span className="text-semi-strong">Edycja paczki</span> #<strong>{transferPackage.id}</strong>
      </ModalHeader>
      <ModalBody>
        <LoaderPlaceholder content={transferPackageDetails}>
          {transferPackageDetails && (
            <TransferPackageEditModalForm
              setTransferPackageDetails={setTransferPackageDetails}
              transferPackageDetails={transferPackageDetails}
            />
          )}
        </LoaderPlaceholder>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <TransferPackageEditModalGenerateButton transferPackage={transferPackage} toggleIsVisible={toggleIsVisible} />
      </ModalFooter>
    </>
  )
}
