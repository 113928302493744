import * as React from 'react'
import { TechnicalOrder } from '@models/technical-orders'
import Table from '@components/table/table'
import { TechnicalOrdersTodoRowUnit } from '@modules/technical-orders/technical-orders-todo/row-unit'
import { TechnicalOrdersTodoRowName } from '@modules/technical-orders/technical-orders-todo/row-name'
import { TechnicalOrdersOrderedTableRowNotes } from '@modules/technical-orders/technical-orders-ordered/row-notes'
import { TechnicalOrdersTodoRowNumber } from '@modules/technical-orders/technical-orders-todo/row-number'
import { TechnicalOrdersTodoRowResort } from '@modules/technical-orders/technical-orders-todo/row-resort'
import { TechnicalOrdersTodoRowUsers } from '@modules/technical-orders/technical-orders-ordered/row-users'
import { TechnicalOrdersDoneTableRowActions } from '@modules/technical-orders/technical-orders-done/row-actions'
import { TechnicalOrdersDoneTableRowWorkTime } from '@modules/technical-orders/technical-orders-done/row-work-time'
import { TechnicalOrdersTodoRowUserBy } from '@modules/technical-orders/technical-orders-todo/row-user-by'
import { useTechnicalOrderPreviewModal } from '@modules/technical-orders/hooks/use-technical-order-preview-modal'

interface Props {
  technicalOrder: TechnicalOrder
  showResort: boolean
}

export const TechnicalOrdersDoneTableRow: React.FC<Props> = ({ showResort, technicalOrder }) => {
  const handlePreview = useTechnicalOrderPreviewModal({ technicalOrder })

  return (
    <Table.Row onDoubleClick={handlePreview} className="cursor-pointer">
      <TechnicalOrdersTodoRowNumber technicalOrder={technicalOrder} />
      {showResort && <TechnicalOrdersTodoRowResort technicalOrder={technicalOrder} />}
      <TechnicalOrdersTodoRowUnit technicalOrder={technicalOrder} />
      <TechnicalOrdersDoneTableRowWorkTime technicalOrder={technicalOrder} />
      <TechnicalOrdersTodoRowName technicalOrder={technicalOrder} />
      <TechnicalOrdersTodoRowUsers technicalOrder={technicalOrder} />
      {technicalOrder.is_supervisor_acceptance_required ? (
        <TechnicalOrdersTodoRowUserBy user={technicalOrder.accepted_by} />
      ) : (
        <Table.Cell className="vertical-align-middle" width={150}>
          Zadanie nie wymaga akceptacji
        </Table.Cell>
      )}
      <TechnicalOrdersOrderedTableRowNotes technicalOrder={technicalOrder} />
      <TechnicalOrdersDoneTableRowActions technicalOrder={technicalOrder} />
    </Table.Row>
  )
}
