import * as React from 'react'
import {
  ReceptionBookingDetails,
  ReceptionTransfer,
  ReceptionTransferBooking,
  ReceptionTransferDeposit,
} from '@models/reception'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { useApiRequest, useFormRequest } from '@components/hooks/use-api-request'
import { commonObjectGet, commonObjectPost } from '@store/actions/generic-actions'
import { CustomReactSelect, CustomReactSelectOption } from '@components/custom-react-select'
import { ReceptionCheckReminders } from '@modules/reception/common/reception-check-reminders'
import { FormProvider, useForm } from 'react-hook-form'
import { SaveButton } from '@hyper/button'
import { asDecimal, formatPrice } from '@helpers/utils'
import { AlertDanger } from '@components/alerts'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { setReceptionBookingDetails } from '@store/slices/reception-slice'

const defaultReminder = [
  {
    id: 'irreversible_deposit_transfer',
    children: (
      <>
        Operacja przeniesienia kaucji <strong>jest nieodwracalna!</strong>
      </>
    ),
  },
]

const entireDepositTransferReminder = {
  id: 'deposit_transfer_reminder',
  isClosable: true,
  children: (
    <>
      Poinformuj klienta, że dopłata wynika z faktu, że <strong>kaucja opłacona jako preautoryzacja</strong> jest
      przenoszona w całości na kolejną rezerwację.
    </>
  ),
}

const createTransferBookingSelectOption = (transferBooking: ReceptionTransferBooking) => ({
  value: transferBooking.id,
  label: `${transferBooking.resort_name} | ${transferBooking.apartment_name} | ${transferBooking.reservation_number}`,
})

interface FormInputs {
  target_booking: CustomReactSelectOption | undefined
}

interface Props extends BaseModalProps {
  booking: ReceptionBookingDetails
}

export const TransferDepositModal: React.FC<Props> = ({ booking, toggleIsVisible }) => {
  const [reminders, setReminders] = React.useState(defaultReminder)
  const [receptionTransfer, setReceptionTransfer] = React.useState<ReceptionTransferDeposit | null>(null)

  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const methods = useForm<FormInputs>({
    defaultValues: {
      target_booking: undefined,
    },
  })

  const { action: fetchTransferDepositDetails } = useApiRequest(async () => {
    setReceptionTransfer(await commonObjectGet(booking.urls.transfer_deposit))
  })

  const { action: handleSubmit, isLoading: isSaving } = useFormRequest(async () => {
    const targetBooking = receptionTransfer?.bookings.find(
      transferBooking => transferBooking.id === methods.getValues('target_booking')?.value,
    )

    dispatch(
      setReceptionBookingDetails(
        await commonObjectPost(booking.urls.transfer_deposit, {
          target_booking: targetBooking?.id,
        }),
      ),
    )

    addSuccessMessage('Sukces', `Kaucja została przeniesiona do rezerwacji nr. ${targetBooking?.reservation_number}`)
    toggleIsVisible()
  }, methods.setError)

  React.useEffect(() => {
    fetchTransferDepositDetails()
  }, [])

  React.useEffect(() => {
    const isCreditCardPreauth = receptionTransfer?.transfers.some(
      (transfer: ReceptionTransfer) =>
        transfer.payment.source === 'creditcard_preauth' && asDecimal(transfer.amount).gt(0),
    )

    if (isCreditCardPreauth) {
      setReminders(state => [...state, entireDepositTransferReminder])
    }
  }, [receptionTransfer?.transfers])

  React.useEffect(() => {
    if (receptionTransfer?.bookings.length === 1) {
      methods.setValue('target_booking', createTransferBookingSelectOption(receptionTransfer.bookings[0]))
    }
  }, [receptionTransfer?.bookings])

  const bookingOptions =
    receptionTransfer?.bookings.map((singleBooking: ReceptionTransferBooking) =>
      createTransferBookingSelectOption(singleBooking),
    ) || []

  const isPaymentRequired = asDecimal(receptionTransfer?.rest_to_pay).gt(0)

  return (
    <LoaderPlaceholder content={receptionTransfer}>
      <ModalHeader toggle={toggleIsVisible} className="py-1">
        Przeniesienie kaucji
      </ModalHeader>
      {receptionTransfer && (
        <ModalBody>
          <FormProvider {...methods}>
            <ReceptionCheckReminders reminders={reminders} required={false} />

            {isPaymentRequired && (
              <AlertDanger
                icon="uil-exclamation-triangle"
                className="font-11 px-2"
                iconClassName="align-self-center mr-2 font-15"
              >
                Po przeniesieniu kaucji wymagana będzie dopłata do bieżącej rezerwacji w wysokości{' '}
                <strong>{formatPrice(receptionTransfer.rest_to_pay)}</strong>
              </AlertDanger>
            )}

            <CustomReactSelect
              placeholder="Wybierz rezerwację"
              noOptionsMessage={() => 'Brak rezerwacji'}
              fieldName="target_booking"
              label={`Przenieś niewykorzystaną kaucję w wysokości ${formatPrice(
                receptionTransfer.transfer_amount,
              )} do rezerwacji:`}
              options={bookingOptions}
            />
          </FormProvider>
        </ModalBody>
      )}
      <ModalFooter className="d-flex justify-content-between">
        <Button color="light" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton
          isSaving={isSaving}
          labelSaving="Przenoszenie.."
          className="btn btn-green"
          label="Przenieś kaucję"
          onClick={handleSubmit}
        />
      </ModalFooter>
    </LoaderPlaceholder>
  )
}
