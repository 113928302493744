import * as React from 'react'
import { PackageVip, PackageVipFiltersParams } from '@modules/promotions/package-vip/models'
import Table, { TableHeaderValue } from '@components/table/table'
import { PackageVipTableRow } from '@modules/promotions/package-vip/package-vip-table-row'
import { PackageVipTableFooter } from '@modules/promotions/package-vip/package-vip-table-footer'

interface PackageVipTableProps {
  isLoading: boolean
  filters: PackageVipFiltersParams
  setFilters: (filters: PackageVipFiltersParams) => void
  packages: PackageVip[]
}

const headerValues: TableHeaderValue<PackageVip>[] = [
  {
    sortField: 'number',
    title: 'Numer',
  },
  {
    sortField: 'client__first_name',
    title: 'Imię i nazwisko',
  },
  {
    sortField: 'type',
    title: 'Rodzaj',
  },
  {
    sortField: 'status',
    title: 'Status',
  },
  {
    sortField: 'payment_method',
    title: 'Forma płatności',
  },
  {
    sortField: 'price_brutto',
    title: 'Cena',
  },
  {
    sortField: 'required_payment',
    title: 'Pozostało do zapłaty',
  },
  {
    sortField: 'sell_date',
    title: 'Data sprzedaży',
  },
  {
    sortField: 'seller__name',
    title: 'Sprzedawca',
  },
]

export const PackageVipTable: React.FC<PackageVipTableProps> = ({ packages, setFilters, filters, isLoading }) => (
  <Table
    className="table-hover w-100"
    headerValues={headerValues}
    isLoading={isLoading}
    filters={filters}
    setFilters={setFilters}
  >
    {packages.map(packageVip => (
      <PackageVipTableRow key={packageVip.id} packageVip={packageVip} />
    ))}
    {!!packages.length && <PackageVipTableFooter />}
  </Table>
)
