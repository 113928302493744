import * as React from 'react'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { CleaningOrder, ImprovementOrder } from '@modules/housekeeping/models'
import { UnitDetailsImprovementOrdersTableRow } from '@modules/units/details/improvement-orders/row'

const headerValues: TableHeaderValue<CleaningOrder>[] = [
  { title: 'Data zlecenia', sortField: 'date' },
  { title: 'Status', sortField: 'status' },
  { title: 'Zlecający', sortField: 'ordered_by__name' },
  { title: 'Wykonawca' },
  { title: 'Data zakończenia', sortField: 'finished_at' },
]

interface Props {
  improvementOrders: ImprovementOrder[]
  filters: TableFilters
  setFilters: React.Dispatch<React.SetStateAction<TableFilters>>
}

export const UnitDetailsImprovementOrdersTable: React.FC<Props> = ({ improvementOrders, filters, setFilters }) => (
  <Table
    className="table-hover w-100"
    headerValues={headerValues}
    isLoading={false}
    filters={filters}
    setFilters={setFilters}
  >
    {improvementOrders.map(improvementOrder => (
      <UnitDetailsImprovementOrdersTableRow key={improvementOrder.id} improvementOrder={improvementOrder} />
    ))}
  </Table>
)
