import * as React from 'react'
import { TransferPackageDetails } from '@modules/accounting/refund/models'
import { formatPriceShort } from '@helpers/utils'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { Card, CardBody, Col } from 'reactstrap'
import { formatIban } from '@helpers/text'
import Table, { TableFilters } from '@components/table/table'
import { TransferPackagePreviewModalTableFooter } from '@modules/accounting/refund/transfer-packages/preview/table-footer'

interface Props {
  transferPackage: TransferPackageDetails
}

const headerValues = [
  {
    sortField: 'reservation_number',
    title: 'Numer rezerwacji',
  },
  {
    sortField: 'name',
    title: 'Odbiorca',
  },
  {
    sortField: 'address',
    title: 'Adres',
  },
  {
    sortField: 'account_number',
    title: 'Numer konta',
  },
  {
    sortField: 'title',
    title: 'Tytuł',
  },
  {
    sortField: 'amount',
    title: 'Kwota',
  },
  {
    sortField: 'send_date',
    title: 'Data wysyłki',
  },
]

export const TransferPackagePreviewModalTable: React.FC<Props> = ({ transferPackage }) => {
  const [filters, setFilters] = React.useState<TableFilters>({
    ordering: '-name',
    page: 1,
    search: '',
    page_size: 9999,
  })
  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <h4 className="mb-0">Przelewy</h4>
          <hr className="mt-1" />

          <Table
            className="table-hover w-100"
            headerValues={headerValues}
            isLoading={false}
            showPagination={false}
            filters={filters}
            setFilters={setFilters}
          >
            {transferPackage.transfers.map(transfer => (
              <Table.Row key={transfer.id}>
                <Table.Cell>
                  <a href={transfer.details_url}>{transfer.number}</a>
                </Table.Cell>
                <Table.Cell>{transfer.name}</Table.Cell>
                <Table.Cell>
                  {transfer.address}
                  {transfer.postcode} {transfer.city}
                </Table.Cell>
                <Table.Cell>{formatIban(transfer.account_number || '')}</Table.Cell>
                <Table.Cell>{transfer.title}</Table.Cell>
                <Table.Cell>{formatPriceShort(transfer.amount)}</Table.Cell>
                <Table.Cell>{toDefaultDateFormat(transfer.send_date)}</Table.Cell>
              </Table.Row>
            ))}
            <TransferPackagePreviewModalTableFooter transfers={transferPackage.transfers} />
          </Table>
        </CardBody>
      </Card>
    </Col>
  )
}
