import * as React from 'react'
import { useModal } from '@components/modals/use-modal'
import { ButtonWithIcon } from '@components/button-with-icon'

export const RentalWarehousesTableActions = (): JSX.Element => {
  const [showWarehouseModal] = useModal('RentalWarehouseCreateModal')

  return (
    <div className="ml-2 mb-2">
      <ButtonWithIcon icon="uil-plus" text="Dodaj magazyn" handleClick={showWarehouseModal} color="green-dark" />
    </div>
  )
}
