import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  icon: string
  title: string
  content: React.ReactNode
  after?: React.ReactNode
}

export const BandScanningConfigurationItem = ({ icon, title, content, after }: Props) => (
  <div className="d-flex align-items-center">
    <div className="col-6 px-0">
      <IconWithText icon={icon} text={title} />
    </div>
    <div className="col-3">{content}</div>
    <div className="col-3 text-center">{after}</div>
  </div>
)
