import { createSelector } from 'reselect'
import { PaymentRegisterUser } from '@models/payments'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { paymentRegisterUsersSelector } from '@store/slices/cash-slice'

export const paymentRegisterUsersOptionsSelector = createSelector(
  paymentRegisterUsersSelector,
  (users: PaymentRegisterUser[]): CustomReactSelectOption[] =>
    users.map(row => ({
      value: row.id,
      label: row.value,
    })),
)
