import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { SubmitFilter } from '@modules/reception/common/filters/submit-filter'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { FormSelect } from '@hyper/forms/form-select'
import { RentalProductFiltersParams, RentalWarehousesFiltersParams } from '@modules/rental/models'
import { RentalWarehousesTableActions } from '@modules/rental/warehouses/rental-warehouses-table-actions'
import { useAppData } from '@components/hooks/use-app-data'
import { createSelectOption } from '@helpers/utils'

interface Props {
  filters: RentalWarehousesFiltersParams
  setFilters: (filters: RentalWarehousesFiltersParams) => void
  defaultFilters: RentalWarehousesFiltersParams
}

export const RentalWarehousesFilters = ({ setFilters, filters, defaultFilters }: Props) => {
  const { resorts } = useAppData()

  const resortOptions = resorts.map(resort => createSelectOption(resort.name, resort.id))

  const methods = useForm<RentalProductFiltersParams>({ defaultValues: filters })

  const handleReset = () => {
    methods.reset()
    setFilters(defaultFilters)
  }

  const onSubmit = async (payload: RentalProductFiltersParams) => setFilters({ ...filters, ...payload })

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="form form-style-filter mb-2" autoComplete="off">
        <div className="d-flex align-items-end justify-content-between">
          <FormSelect
            options={resortOptions}
            name="resort"
            label="Ośrodek"
            formPlainProps={{ colSize: 3, colClassName: 'pl-0' }}
            selectProps={{ isClearable: true }}
          />

          <div className="d-flex align-items-end">
            <SubmitFilter handleReset={handleReset} formGroupClassName="mb-2" />
            <SearchFilter formGroupClassName="mb-2" />
            <RentalWarehousesTableActions />
          </div>
        </div>
      </form>
    </FormProvider>
  )
}
