import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { UpsellBookingsStatusChangeFormInputs } from '@modules/upselling/upsell-bookings/status-change/upsell-bookings-status-change-modal'
import { Button } from 'reactstrap'
import classNames from 'classnames'
import { UpSellBookingStatus } from '@models/upselling'

interface Props {
  color: string
  title: string
  status: UpSellBookingStatus
}

export const UpsellBookingsStatusChangeButton: React.FC<Props> = ({ color, title, status }: Props) => {
  const { setValue, control } = useFormContext<UpsellBookingsStatusChangeFormInputs>()

  const activeStatus = useWatch({ control, name: 'status' })

  const handleStatusChange = () => {
    setValue('status', status)
  }

  return (
    <Button
      outline
      color="light"
      className={classNames('w-100 mb-1', { 'opacity-5': activeStatus !== status })}
      onClick={handleStatusChange}
    >
      <span className={color}>{title}</span>
    </Button>
  )
}
