import * as React from 'react'
import { ModalBody, Row } from 'reactstrap'
import { ReceptionBookingCheckInStepAccessInfoBlock } from '@modules/reception/checkin/step-access/step-access-info-block'
import { RootState, useAppSelector } from '@store/index'
import { formatPriceShort } from '@helpers/utils'

export const ReceptionBookingCheckInStepAccessInfo: React.FC = () => {
  const damageKeys = useAppSelector(
    (state: RootState) => state.appState.appData.configuration.reservation_booking_damage_keys_price_brutto,
  )
  const damageBands = useAppSelector(
    (state: RootState) => state.appState.appData.configuration.reservation_booking_damage_bands_price_brutto,
  )
  const resortDetails = useAppSelector((state: RootState) => state.receptionState.resortDetails)

  return (
    <ModalBody className="bg-grey reception__booking-check-in__access-step__info p-4">
      <strong className="d-block font-18 mb-4 text-center">Najważniejsze informacje dla gościa</strong>
      <Row className="mb-3">
        <ReceptionBookingCheckInStepAccessInfoBlock icon="uil-glass-martini-alt" title="Drink za zgody RODO">
          Przypomnij Gościowi o{' '}
          <strong>
            darmowym <br /> drinku
          </strong>{' '}
          po akceptacji zgód <strong>RODO</strong>.
        </ReceptionBookingCheckInStepAccessInfoBlock>

        {resortDetails?.has_feeding && (
          <ReceptionBookingCheckInStepAccessInfoBlock icon="uil-clock-two" title="Godziny działania restauracji">
            Śniadanie (bufet) <strong>{resortDetails?.breakfast_time}</strong> <br />
            Obiadokolacja (bufet) <strong>{resortDetails?.dinner_time}</strong>
          </ReceptionBookingCheckInStepAccessInfoBlock>
        )}
        <ReceptionBookingCheckInStepAccessInfoBlock icon="uil-clock-eight" title="Godziny pracy recepcji">
          Recepcja jest do dyspozycji <br /> w godzinach <strong>{resortDetails?.reception_working_time}</strong>
        </ReceptionBookingCheckInStepAccessInfoBlock>

        <ReceptionBookingCheckInStepAccessInfoBlock icon="uil-microphone-slash" title="Cisza nocna">
          <strong>Godziny ciszy nocnej</strong> obowiązują <br /> w godzinach{' '}
          <strong>{resortDetails?.curfew_time}</strong>
        </ReceptionBookingCheckInStepAccessInfoBlock>
        <ReceptionBookingCheckInStepAccessInfoBlock icon="icon-opaska" title="Zgubienie opaski lub kluczy">
          Opłaty za zgubienie <strong>opaski ({formatPriceShort(damageBands)})</strong> <br /> lub{' '}
          <strong>kluczy ({formatPriceShort(damageKeys)})</strong>.
        </ReceptionBookingCheckInStepAccessInfoBlock>

        <ReceptionBookingCheckInStepAccessInfoBlock icon="uil-wrench" title="Prośby o naprawy i sprzątanie">
          Wszelkie prośby kierujemy za <br /> pośrednictwem <strong>recepcji</strong>.
        </ReceptionBookingCheckInStepAccessInfoBlock>
      </Row>
    </ModalBody>
  )
}
