import * as React from 'react'
import { ServiceBand } from '@modules/bands/service-bands/models'
import Table from '@components/table/table'
import { formatPriceShort } from '@helpers/utils'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { useModal } from '@components/modals/use-modal'
import { ServiceBandDeleteButton } from '@modules/bands/service-bands/delete-dialog'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { ServiceBandStatusBadge } from '@modules/bands/service-bands/common/status'

interface Props {
  serviceBand: ServiceBand
}

export const ServiceBandTableRow = ({ serviceBand }: Props): JSX.Element => {
  const [handleClick] = useModal('ServiceBandDetailsDialog', { serviceBand: serviceBand })
  const user = useAuthenticatedUser()
  const canDelete = user.hasPerm(UserPermission.ServiceBandCanDelete) && !serviceBand.is_removed

  return (
    <Table.Row onDoubleClick={handleClick} className="cursor-pointer">
      <Table.Cell>{serviceBand.name}</Table.Cell>
      <Table.Cell>
        {serviceBand.owner.first_name} {serviceBand.owner.last_name}
      </Table.Cell>
      <Table.Cell>
        <ServiceBandStatusBadge status={serviceBand.status} />
      </Table.Cell>
      <Table.Cell>{serviceBand.resort}</Table.Cell>
      <Table.Cell>{formatPriceShort(serviceBand.initial_funds)}</Table.Cell>
      <Table.Cell>{toDefaultDateFormat(serviceBand.date_from)}</Table.Cell>
      <Table.Cell>{toDefaultDateFormat(serviceBand.date_to)}</Table.Cell>
      <Table.Cell>{canDelete && <ServiceBandDeleteButton serviceBand={serviceBand} />}</Table.Cell>
    </Table.Row>
  )
}
