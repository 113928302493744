import * as React from 'react'
import { asDecimal, formatPriceShort, sumDecimalArray } from '@helpers/utils'
import classNames from 'classnames'
import { UncontrolledTooltip } from 'reactstrap'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { getReceptionBookingPayments } from '@modules/reception/common/reception-booking-check-bill-details/tools'
import Decimal from 'decimal.js'
import { useAppSelector } from '@store/index'
import { reservationBookingPaymentsVisibleSelector } from '@store/slices/reservations-slice'

interface Props {
  label: string
  isDeposit: boolean
  defaultIsOpen: boolean
  readOnly?: boolean
  rowClassName?: string
}

const ReceptionBookingCheckOutBillDetailsPayments: React.FC<Props> = ({
  label,
  isDeposit,
  readOnly,
  defaultIsOpen,
  rowClassName,
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const bookingPayments = useAppSelector(reservationBookingPaymentsVisibleSelector)

  const handleOpen = () => setIsOpen(!isOpen)

  React.useEffect(() => {
    setIsOpen(defaultIsOpen)
  }, [defaultIsOpen])

  const payments = React.useMemo(() => getReceptionBookingPayments(bookingPayments, isDeposit), [bookingPayments])

  const total: Decimal = React.useMemo(
    () => sumDecimalArray(payments.map(payment => asDecimal(payment.amount))),
    [bookingPayments],
  )

  return (
    <>
      <tr className={classNames('reception__booking-check-out__bill-step__table__row', rowClassName)}>
        <td className="reception__booking-check-out__bill-step__table__column text-grey">
          {label} ({payments.length})
        </td>
        <td className="reception__booking-check-out__bill-step__table__column text-right reception__booking-check-out__bill-step__table__column--is-payin">
          <span className={classNames({ 'text-success': isDeposit })}>{formatPriceShort(total.toString())}</span>
        </td>
        <td className="reception__booking-check-out__bill-step__table__column" />
        {!readOnly && (
          <td className="reception__booking-check-out__bill-step__table__column text-right p-0">
            {!!payments.length && (
              <i
                onClick={handleOpen}
                className={classNames('font-18 cursor-pointer', {
                  'uil-angle-right': !isOpen,
                  'uil-angle-down': isOpen,
                })}
              />
            )}
          </td>
        )}
      </tr>
      {isOpen && (
        <>
          {payments.map(payment => (
            <tr
              key={payment.id}
              className={classNames(
                'reception__booking-check-out__bill-step__table__row reception__booking-check-out__bill-step__table__row--is-sub',
                rowClassName,
              )}
            >
              <td className="reception__booking-check-out__bill-step__table__column reception__booking-check-out__bill-step__table__column--is-sub">
                {payment.type === 'deposit' ? 'Wpłata' : payment.type_display}{' '}
                {toDefaultDateFormat(payment.invoice_date)}
              </td>
              <td className="reception__booking-check-out__bill-step__table__column text-right">
                {formatPriceShort(payment.amount)}
              </td>
              <td className="reception__booking-check-out__bill-step__table__column p-0 text-right">
                <i className="uil-money-insert font-14 text-gray" id={`payment-details-${payment.id}`} />
                <UncontrolledTooltip placement="right" target={`payment-details-${payment.id}`}>
                  <div className="text-center font-10">
                    <strong className="d-block mb-1">Wpłacono {toDefaultDateFormat(payment.invoice_date)}</strong>
                    {payment.source_display} <br />
                    {payment.description}
                    <strong className="d-block mt-1">{payment.user}</strong>
                  </div>
                </UncontrolledTooltip>
              </td>
              {!readOnly && <td className="reception__booking-check-out__bill-step__table__column" />}
            </tr>
          ))}
        </>
      )}
    </>
  )
}

export default ReceptionBookingCheckOutBillDetailsPayments
