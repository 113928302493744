import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { Button } from 'reactstrap'
import { updateReceptionBookingDetails } from '@store/actions/reception-actions'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { formatDate } from '@helpers/date-helper'
import { startOfToday } from 'date-fns'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { useApiRequest } from '@components/hooks/use-api-request'

interface ReceptionBookingDetailsDataProps {
  booking: ReceptionBookingDetails
}

export const ReceptionBookingDetailsDataButtons: React.FC<ReceptionBookingDetailsDataProps> = ({ booking }) => {
  const dispatch = useAppDispatch()
  const user = useAuthenticatedUser()
  const { addSuccessMessage } = useNotificationHook()

  const { isLoading, action: handleCheckInRestoreAccept } = useApiRequest(async (reason: string) => {
    await dispatch(
      updateReceptionBookingDetails(
        await commonObjectPost<ReceptionBookingDetails>(booking.urls.check_in_restore, { reason }),
      ),
    )

    addSuccessMessage('Sukces', 'Rezerwacja została cofnięta')
  })

  const canRollback =
    user.hasPerm(UserPermission.BookingCheckInRestore) ||
    (booking.date_to === formatDate(startOfToday()) && user.hasPerm(UserPermission.BookingCheckInRestoreToday))

  return (
    <div className="mb-3 text-right">
      {booking.status === 'close' && canRollback && (
        <CommonObjectConfirmAction
          isLoading={isLoading}
          askForReason={true}
          handleAccept={handleCheckInRestoreAccept}
          message="Czy na pewno chcesz cofnąć rozliczenie rezerwacji ? Pamietaj że cofnięcie rozliczenia należy zgłosić księgowości"
        >
          <Button color="danger">Cofnij rozliczenie</Button>
        </CommonObjectConfirmAction>
      )}
    </div>
  )
}
