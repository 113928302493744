import * as React from 'react'
import { BadgeWithIcon } from '@components/badges/BadgeWithIcon'
import { ReceptionBand } from '@models/reception'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'

interface Props {
  label: React.ReactNode
  band: ReceptionBand
}

export const BandScanningSuccess = ({ label, band }: Props): JSX.Element => (
  <div className="d-flex align-items-center">
    <div className="band__scanning-guest__action">
      <BadgeWithIcon icon="mdi mdi-check-circle font-12" variant="success" />
    </div>
    <div>{label}</div>
    <div className="font-10 ml-auto col-8 px-0 text-right">
      Nr. opaski {band.external_id || <strong>BRAK</strong>} <br />
      Aktywna od {toDefaultDateTimeFormat(band.datetime_from)} <br />
      <span style={{ marginLeft: 41 }}>do {toDefaultDateTimeFormat(band.datetime_to)}</span>
    </div>
  </div>
)
