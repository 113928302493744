import axios, { CancelTokenSource } from 'axios'
import { formatDate } from '@helpers/date-helper'
import { CUSTOM_AXIOS_ERROR } from '@helpers/handle-axios-errors'

export const commonObjectGet = async function <T>(
  url: string,
  params?: any,
  cancelTokenSource?: CancelTokenSource,
): Promise<T> {
  const cancelOpts = { cancelToken: cancelTokenSource?.token }
  const { data } = await axios.get(url, {
    params,
    ...cancelOpts,
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
  })
  return data
}

export const commonObjectDelete = async function <T>(
  url: string,
  reason?,
  payload?,
  cancelTokenSource?: CancelTokenSource,
  params?,
): Promise<T> {
  const cancelOpts = { cancelToken: cancelTokenSource?.token }
  const { data } = await axios.delete(url, {
    data: {
      reason,
      ...parsePayload(payload),
    },
    params,
    ...cancelOpts,
  })
  return data
}

export const commonObjectPost = async function <T>(
  url: string,
  payload?,
  cancelTokenSource?: CancelTokenSource,
  params?,
): Promise<T> {
  const { data } = await axios.post(url, parsePayload(payload), { cancelToken: cancelTokenSource?.token, params })
  return data
}

export const wrapWithError = async (promise: any): Promise<any> => {
  try {
    return await promise
  } catch (error) {
    throw {
      name: CUSTOM_AXIOS_ERROR,
      message: JSON.stringify(error.response.data),
    }
  }
}

export const commonObjectPut = async function <T>(
  url: string,
  payload?,
  cancelTokenSource?: CancelTokenSource,
  params?,
): Promise<T> {
  const { data } = await axios.put(url, parsePayload(payload), { cancelToken: cancelTokenSource?.token, params })
  return data
}

export const commonObjectPatch = async function <T>(
  url: string,
  payload?,
  cancelTokenSource?: CancelTokenSource,
): Promise<T> {
  const cancelOpts = { cancelToken: cancelTokenSource?.token }
  const { data } = await axios.patch(url, parsePayload(payload), cancelOpts)
  return data
}

export function getCancelSource(): CancelTokenSource {
  return axios.CancelToken.source()
}

export const parsePayload = payload => {
  if (!payload) {
    return payload
  }

  Object.entries(payload).forEach(([key, value]) => {
    if (value instanceof Date) {
      payload[key] = formatDate(value, 'yyyy-MM-dd HH:mm')
    }
  })
  return payload
}

export const downloadBlobFile = async (url: string, params: any) => {
  const response = await axios.get(url, {
    params,
    responseType: 'blob',
  })

  const temp = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }))
  const link = document.createElement('a')
  link.href = temp
  link.target = '_blank'
  link.download = response.headers['content-disposition'].split('filename=')[1]
  link.click()

  return response
}
