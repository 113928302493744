import { createSelectOption } from '@helpers/utils'

export const CouponStatusesOptions = [
  createSelectOption('Wstępny', 'initial'),
  createSelectOption('Aktywny', 'active'),
  createSelectOption('Wykorzystany', 'used'),
  createSelectOption('Przeterminowany', 'expired'),
  createSelectOption('Anulowany', 'canceled'),
]

export const PointOfSaleTypes = [createSelectOption('Kawiarnia', 'cafe'), createSelectOption('Bistro', 'bistro')]
