import * as React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { SubscriptionContractFeature } from '@modules/subscription-contract/models'
import { SubscriptionContractFeaturesTable } from '@modules/subscription-contract/details/information/features-table'

interface Props {
  features: SubscriptionContractFeature[]
}

export const SubscriptionContractDetailsFeaturesRow = React.forwardRef<HTMLDivElement, Props>(({ features }, ref) => (
  <div ref={ref}>
    <Row>
      <Col md={12}>
        <Card>
          <CardBody>
            <h4 className="mt-0 text-secondary mb-3 text-semi-strong">Lista benefitów</h4>
            <SubscriptionContractFeaturesTable features={features} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  </div>
))
