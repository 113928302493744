import * as React from 'react'
import { FormInput } from '@hyper/forms'
import { ReceptionBookingDetails } from '@models/reception'

interface StepVerificationBookingDocumentsProps {
  booking: ReceptionBookingDetails
}

export const StepVerificationBookingDocuments: React.FC<StepVerificationBookingDocumentsProps> = ({ booking }) => (
  <FormInput
    colClassName="px-0"
    label={`Numer dowodu Gościa (${booking.name})`}
    inputClassName="reception__booking-check-in__verification_step__input"
    name="id_card"
  />
)
