import * as React from 'react'
import { UpSellBookingsTab, UpSellBookingsTabs } from '@modules/upselling/upsell-bookings/upsell-bookings-tabs'
import { useParams } from 'react-router-dom'
import { UpsellBookingsLayout } from '@modules/upselling/upsell-bookings-layout'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useDebouncedValue } from 'rooks'
import { TypedQueryResult } from '@api/base'
import { UpSellOutdatedBooking } from '@models/upselling'
import { UpsellBookingsOutdatedFilters } from '@modules/upselling/upsell-booking-outdated/filters/upsell-bookings-outdated-filters'
import { NavigationPath } from '@models/routes'
import { UpsellBookingsOutdatedTable } from '@modules/upselling/upsell-booking-outdated/table/upsell-bookings-outdated-table'
import { useGetUpSellOutdatedBookingsQuery } from '@api/upSellBooking'

interface Params {
  type: UpSellBookingsTab
}

export interface UpsellBookingsOutdatedFormInputs {
  mode: UpSellBookingsTab
  search: string
}

export const UpSellBookingsOutdatedView: React.FC = () => {
  const { type } = useParams() as unknown as Params

  const defaultFilters: UpsellBookingsOutdatedFormInputs = {
    mode: type,
    search: '',
  }

  const methods = useForm<UpsellBookingsOutdatedFormInputs>({ defaultValues: defaultFilters })
  const formValues = useWatch({ control: methods.control })
  const search = useDebouncedValue(formValues.search, 500)

  const {
    data: upSellOutdatedBookings = [],
    isLoading,
    isFetching,
  } = useGetUpSellOutdatedBookingsQuery({ search, mode: type }) as TypedQueryResult<UpSellOutdatedBooking[]>

  return (
    <UpsellBookingsLayout>
      <div className="mt-3">
        <FormProvider {...methods}>
          <UpSellBookingsTabs type={type} path={NavigationPath.UpSellBookingsOutdatedWithParams} />
          <UpsellBookingsOutdatedFilters type={type} />
          <UpsellBookingsOutdatedTable
            isLoading={isLoading || isFetching}
            bookings={upSellOutdatedBookings}
            type={type}
          />
        </FormProvider>
      </div>
    </UpsellBookingsLayout>
  )
}
