import * as React from 'react'
import { ModalHeader } from 'reactstrap'
import { ReceptionBookingCheckInNotes } from '@modules/reception/common/reception-note-popup/reception-booking-check-in-notes'
import { ReceptionBookingDetails } from '@models/reception'

interface ReceptionCheckHeaderProps {
  title: React.ReactNode
  handleIsOpen: () => void
  bookingDetails: ReceptionBookingDetails | undefined
}

export const ReceptionCheckHeader: React.FC<ReceptionCheckHeaderProps> = ({ title, handleIsOpen, bookingDetails }) => {
  const [notesVisible, setNotesVisible] = React.useState(!!bookingDetails?.notes?.length)
  const toggleNotesVisibility = () => setNotesVisible(!notesVisible)

  React.useEffect(() => {
    if (bookingDetails && bookingDetails.notes) {
      setNotesVisible(!!bookingDetails.notes.length)
    }
  }, [bookingDetails?.notes])

  return (
    <>
      <ModalHeader
        toggle={handleIsOpen}
        tag="div"
        cssModule={{ 'modal-title': 'w-100', 'modal-header': 'd-flex align-items-center p-2' }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="font-18 text-semi-strong">{title}</h5>
          {!notesVisible && (
            <div className="cursor-pointer mr-4 d-flex align-items-center" onClick={toggleNotesVisibility}>
              <i className="uil-file-check-alt text-muted mr-1 font-18" />
              <strong>Pokaż notatki</strong>
            </div>
          )}
        </div>
      </ModalHeader>
      {notesVisible && bookingDetails?.notes && (
        <ReceptionBookingCheckInNotes toggleNotesVisibility={toggleNotesVisibility} booking={bookingDetails} />
      )}
    </>
  )
}
