import * as React from 'react'
import { ReservationCard } from '@modules/reservations/details/common/reservation-card'
import { ReceptionBookingDetails } from '@models/reception'
import { ButtonWithIcon } from '@components/button-with-icon'
import { ReservationLoadNotesRow } from '@modules/reservations/details/load-notes/reservation-load-notes-row'
import { useModal } from '@components/modals/use-modal'
import { CustomSwitch } from '@components/custom-switch'
import { useToggle } from '@components/hooks/use-toggle'
import { useAppDispatch, useAppSelector } from '@store/index'
import { bookingLoadNotesSelector } from '@store/slices/reservations-slice'
import { useApiRequest } from '@components/hooks/use-api-request'
import { getBookingLoadNotes } from '@store/actions/reservation-actions'
import { ContentLoader } from '@components/content-loader'

interface Props {
  booking: ReceptionBookingDetails
}

export const ReservationLoadNotes = ({ booking }: Props): JSX.Element => {
  const [showHidden, toggleHide] = useToggle()

  const loadNotes = useAppSelector(bookingLoadNotesSelector)

  const dispatch = useAppDispatch()
  const { action: fetchLoadNotes, isLoading } = useApiRequest(async () => {
    dispatch(await getBookingLoadNotes(booking))
  })
  const [showAddNoteModal] = useModal('ReservationLoadNoteAddModal', { booking })

  React.useEffect(() => {
    fetchLoadNotes()
  }, [])

  const hiddenCount = loadNotes.filter(note => note.hide).length

  const loadNotesFiltered = React.useMemo(
    () => loadNotes.filter(note => showHidden || !note.hide),
    [loadNotes, showHidden],
  )

  return (
    <ReservationCard
      title="Noty obciążające"
      cardActions={<ButtonWithIcon text="Dodaj" icon="uil-plus" color="light" handleClick={showAddNoteModal} />}
    >
      <ContentLoader isLoading={isLoading}>
        {!!hiddenCount && (
          <CustomSwitch
            id="load-notes"
            labelClassName="text-semi-strong"
            label={`Pokaż ukryte (${hiddenCount})`}
            handleSwitchChange={toggleHide}
            value={showHidden}
          />
        )}
        {loadNotesFiltered.length ? (
          <table className="table table-sm">
            <thead>
              <tr>
                <th className="border-0">Data dodania</th>
                <th className="border-0">Dodane przez</th>
                <th className="border-0">Kwota</th>
                <th className="border-0" />
              </tr>
            </thead>
            <tbody>
              {loadNotesFiltered.map(note => (
                <ReservationLoadNotesRow key={note.id} note={note} booking={booking} />
              ))}
            </tbody>
          </table>
        ) : (
          <p className="text-center">Brak not obciążających</p>
        )}
      </ContentLoader>
    </ReservationCard>
  )
}
