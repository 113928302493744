import { TechnicalOrder } from '@models/technical-orders'
import { useCreateOrUpdateTechnicalOrderMutation } from '@api/technical-orders'
import * as React from 'react'
import { useNotificationHook } from '@hyper/use-notification-hook'

export const useTechnicalOrderAccept = ({ technicalOrders }: { technicalOrders: TechnicalOrder[] }) => {
  const [updateTechnicalOrder] = useCreateOrUpdateTechnicalOrderMutation()
  const { addSuccessNotification } = useNotificationHook()

  return React.useCallback(async () => {
    for (const technicalOrder of technicalOrders) {
      await updateTechnicalOrder({
        data: {},
        url: technicalOrder.urls.accept,
      }).unwrap()
    }
    addSuccessNotification('Zadanie zostało zaakceptowane')
  }, [technicalOrders, updateTechnicalOrder])
}
