import * as React from 'react'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { ReportsAppData } from '@models/reports'
import { getReportsAppData } from '@store/actions/report-actions'
import { setReportsAppData } from '@store/slices/reports-slice'

export const useReportsAppData = (): ReportsAppData => {
  const reportsAppData = useAppSelector((state: RootState) => state.reportsState.appData)
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (reportsAppData.status === 'unknown') {
      dispatch(getReportsAppData())
      dispatch(setReportsAppData({ ...reportsAppData, status: 'loading' }))
    }
  }, [reportsAppData.status])

  return reportsAppData
}
