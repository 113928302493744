import * as React from 'react'
import { Row } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { SubmitFilter } from '@modules/reception/common/filters/submit-filter'
import Col from 'reactstrap/lib/Col'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { FeedingSpecialGuestFiltersParams } from '@modules/feeding/special-guests/index'
import { FormInput } from '@hyper/forms'
import { Button } from 'reactstrap/lib'
import { useModal } from '@components/modals/use-modal'
import { Form } from '@hyper/forms/form'

interface Props {
  filters: FeedingSpecialGuestFiltersParams
  setFilters: (filters: FeedingSpecialGuestFiltersParams) => void
  defaultFilters: FeedingSpecialGuestFiltersParams
}

export const FeedingSpecialGuestFilters: React.FC<Props> = ({ setFilters, filters, defaultFilters }) => {
  const methods = useForm<FeedingSpecialGuestFiltersParams>({
    defaultValues: filters,
  })

  const handleReset = () => {
    methods.reset()
    setFilters(defaultFilters)
  }
  const [handleIsOpen] = useModal('FeedingSpecialGuestDialog')

  const onSubmit = async (payload: FeedingSpecialGuestFiltersParams) => setFilters({ ...filters, ...payload })

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  return (
    <Form onSubmit={onSubmit} className="form form-style-filter" methods={methods}>
      <Row>
        <FormInput inputId="is_active_filter" label="Aktywny?" colSize={2} type="select" name="is_active">
          <option value="">Wszyscy</option>
          <option value="true">Aktywni</option>
          <option value="false">Nieaktywni</option>
        </FormInput>

        <SubmitFilter handleReset={handleReset} />

        <Col className="col-auto ml-auto">
          <label className="d-block">&nbsp;</label>
          <Button color="green" onClick={handleIsOpen}>
            Dodaj gościa
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="text-right">
          <SearchFilter colClassName="col-md-2" />
        </Col>
      </Row>
    </Form>
  )
}
