import * as React from 'react'
import { SystemNotification } from '@models/dashboard'
import classNames from 'classnames'
import { useAppDispatch } from '@store/index'
import { formatDistanceToNow, parseISO } from 'date-fns'
import { pl } from 'date-fns/locale'
import { systemNotificationRead, systemNotificationRemove } from '@store/actions/app-actions'

interface SystemNotificationRowProps {
  systemNotification: SystemNotification
}

export const SystemNotificationRow: React.FC<SystemNotificationRowProps> = ({ systemNotification }) => {
  const dispatch = useAppDispatch()

  const handleRemove = React.useCallback(
    () => dispatch(systemNotificationRemove(systemNotification.urls.update)),
    [systemNotification],
  )

  const handleRead = React.useCallback(
    () => dispatch(systemNotificationRead([systemNotification.urls.update, !systemNotification.read])),
    [systemNotification],
  )

  const created = React.useMemo(
    () => formatDistanceToNow(parseISO(String(systemNotification.created)), { locale: pl }),
    [systemNotification.created],
  )

  return (
    <div className="dropdown-item notify-item py-2">
      <div className="notify-icon bg-primary">
        <i className="uil-comment-exclamation" />
      </div>
      <div className="notify-details">
        <div className="d-flex justify-content-between">
          <span
            className={classNames({
              'text-strong': !systemNotification.read,
            })}
          >
            {systemNotification.url ? (
              <a target="_blank" href={systemNotification.url}>
                {systemNotification.title}
              </a>
            ) : (
              systemNotification.title
            )}
          </span>
          <div>
            <i className="uil-multiply cursor-pointer" onClick={handleRemove} />
            <i
              className={classNames('ml-1 uil-check-circle cursor-pointer', {
                'text-success': systemNotification.read,
              })}
              onClick={handleRead}
            />
          </div>
        </div>
        {systemNotification.url ? (
          <a target="_blank" href={systemNotification.url} className="d-block mb-1">
            <small className="text-muted">{systemNotification.message}</small>
          </a>
        ) : (
          <small className="text-muted">{systemNotification.message}</small>
        )}
        <small className="text-muted font-10">{created}</small>
      </div>
    </div>
  )
}
