import * as React from 'react'
import { PackageWholesaleProduct } from '@modules/package-wholesale/models'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAppDispatch } from '@store/index'
import { removePackageWholesaleProduct } from '@store/slices/package-wholesale-slice'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'

interface Props {
  packageWholesaleProduct: PackageWholesaleProduct
}

export const PackageWholesaleProductListTableRowRemove: React.FC<Props> = ({ packageWholesaleProduct }) => {
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading, action: handleAccept } = useApiRequest(
    React.useCallback(async () => {
      await commonObjectDelete(packageWholesaleProduct.urls.details)
      await dispatch(removePackageWholesaleProduct(packageWholesaleProduct))
      addSuccessMessage('Sukces', `Produkt ${packageWholesaleProduct.name} została skasowany`)
    }, [packageWholesaleProduct]),
  )

  return (
    <CommonObjectConfirmAction
      title="Potwierdź skasowanie produktu"
      askForReason={false}
      isLoading={isLoading}
      handleAccept={handleAccept}
      message="Czy na pewno chcesz skasować wybrany produkt ? Operacja ta jest nieodwołalna"
    >
      <span className="font-16">
        <i className="uil uil-trash-alt cursor-pointer text-muted" title="Skasuj" />
      </span>
    </CommonObjectConfirmAction>
  )
}
