import { useHousekeepingCleaningCompanies } from '@modules/housekeeping/users/use-housekeeping-cleaning-companies'
import { getById } from '@helpers/utils'
import { HousekeepingCleaningCompanyWorker, HousekeepingCompanyDetails } from '@modules/housekeeping/models'

interface Response {
  workers: HousekeepingCleaningCompanyWorker[]
  company: HousekeepingCompanyDetails | undefined
}

export const useHousekeepingWorkerData = (users: number[], resortId: number, companyId: number): Response => {
  const companies = useHousekeepingCleaningCompanies(resortId)
  const company = getById(companies, companyId)

  const workers = users.reduce((acc: HousekeepingCleaningCompanyWorker[], orderUserId: number) => {
    const worker = company?.workers.find(worker => worker.id === orderUserId)
    return worker ? [...acc, worker] : acc
  }, [])

  return { workers, company }
}
