import * as React from 'react'
import { useDocumentTitle } from '@helpers/utils'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { PageTitle } from '@hyper/page-title'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { ButtonWithIcon } from '@components/button-with-icon'
import { ResortTabs } from '@components/resort-tabs'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useParams } from 'react-router-dom'
import {
  PaymentRegisterCashboxFilters,
  PaymentRegisterCashboxFiltersParams,
} from '@modules/payment-registers/cashbox/filters'
import { parseISO, startOfToday } from 'date-fns'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useAppDispatch, useAppSelector } from '@store/index'
import { getPaymentDayReports } from '@store/actions/cash-actions'
import { paymentDayReportsSelector } from '@store/slices/cash-slice'
import * as R from 'ramda'
import { PaymentDayReport } from '@models/payments'
import { PaymentRegisterCashboxWithMonth } from '@modules/payment-registers/cashbox/month'
import { CashboxWebSocketHandler } from '@modules/payment-registers/cashbox/cashbox-web-socket-handler'
import { LoaderPlaceholder } from '@components/loader-placeholder'

interface Params {
  resort_id: string
}

export const PaymentRegisterCashboxWithParams = (): JSX.Element => {
  const pageTitle = 'Kasa'
  useDocumentTitle(pageTitle)
  useMenuActive(NavigationPath.PaymentRegisterCashbox)
  const user = useAuthenticatedUser()
  const { resort_id } = useParams() as unknown as Params
  const resortId = parseInt(resort_id, 10)
  const defaultFilters: PaymentRegisterCashboxFiltersParams = {
    year: String(startOfToday().getFullYear()),
    resort: resort_id,
    search: '',
    ordering: '-date',
    page: 1,
    page_size: 10,
  }
  const [filters, setFilters] = React.useState<PaymentRegisterCashboxFiltersParams>(defaultFilters)
  const dispatch = useAppDispatch()

  const handleExport = () => console.log('noop')

  const { isLoading, action: fetch } = useApiRequest(
    async () => await dispatch(getPaymentDayReports({ ...filters, resort: resort_id })),
  )

  const paymentDayReports = useAppSelector(paymentDayReportsSelector).filter(row => row.resort_id === resortId)

  const groupedByMonths = Object.entries<PaymentDayReport[]>(
    R.groupBy((row: PaymentDayReport) => parseISO(row.date).getMonth())(paymentDayReports),
  )
  groupedByMonths.reverse()

  React.useEffect(() => {
    fetch()
  }, [filters, resort_id])

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitle} />
      <Card>
        <CardBody>
          <CashboxWebSocketHandler resortId={resortId} />
          <ResortTabs resortId={resortId} url={NavigationPath.PaymentRegisterCashboxWithParam} resorts={user.resorts} />
          <PaymentRegisterCashboxFilters
            filters={filters}
            setFilters={setFilters}
            defaultFilters={defaultFilters}
            resortId={resortId}
          />
          <LoaderPlaceholder content={!isLoading}>
            <Row className="payment-cashbox mx-0">
              {groupedByMonths.map(([month, rows]) => (
                <PaymentRegisterCashboxWithMonth key={month} month={month} rows={rows} />
              ))}
              <Col md={12} className="payment-cashbox__day-rows" />
            </Row>
          </LoaderPlaceholder>
          <div className="text-right mt-3" onClick={handleExport}>
            <ButtonWithIcon icon="uil-download-alt" color="secondary" text="Pobierz" />
          </div>
        </CardBody>
      </Card>
    </>
  )
}
