import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap'
import { useFormContext } from 'react-hook-form'
import { ReceptionBookingCheckInStepDocumentsFormInputs } from '@modules/reception/checkin/step-documents'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  booking: ReceptionBookingDetails
}

export const ReceptionBookingCheckOutStepThanksBoxReceiptPrint = ({ booking }: Props): JSX.Element => {
  const { watch } = useFormContext<ReceptionBookingCheckInStepDocumentsFormInputs>()

  const language = watch('language')
  const getUrl = (compact: boolean) => `${booking.urls.print_bill}?language=${language}${compact ? '&compact=1' : ''}`

  return (
    <UncontrolledButtonDropdown group={false} className="shadow-none d-block">
      <a href={getUrl(true)} target="_blank" className="shadow-none btn btn-light rounded-right-0">
        <IconWithText icon="uil-print" text="Drukuj kompaktowy" />
      </a>
      <DropdownToggle split={true} color="light" className="rounded-left-0 shadow-none" />
      <DropdownMenu>
        <DropdownItem target="_blank" href={getUrl(true)}>
          Drukuj kompaktowy
        </DropdownItem>
        <DropdownItem target="_blank" href={getUrl(false)}>
          Drukuj pełny
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  )
}
