import * as React from 'react'
import { PageTitle } from '@hyper/page-title'
import { Card, CardBody } from 'reactstrap'
import { useAppDispatch, useAppSelector } from '@store/index'
import { useDocumentTitle } from '@helpers/utils'
import { FeedingClientsFilters } from '@modules/feeding/clients/filters'
import { getFeedingClients } from '@store/actions/feeding-actions'
import { FeedingClientsTable } from '@modules/feeding/clients/table'
import { Button } from 'reactstrap/lib'
import { useModal } from '@components/modals/use-modal'
import { TableFilters } from '@components/table/table'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useDebounce } from 'rooks'
import { feedingClientsSelector } from '@store/slices/feeding-slice'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'

const pageTitle = 'Wyżywienie goście'

export interface FeedingClientsFiltersParams extends TableFilters {
  date_from: Date | undefined
  date_to: Date | undefined
}

const defaultFilters: FeedingClientsFiltersParams = {
  date_from: undefined,
  date_to: undefined,
  ordering: '-created_at',
  page: 1,
  page_size: 10,
  search: '',
}

export const FeedingClientsView: React.FC = () => {
  const [filters, setFilters] = React.useState<FeedingClientsFiltersParams>(defaultFilters)

  const clients = useAppSelector(feedingClientsSelector)

  useDocumentTitle(pageTitle)
  const dispatch = useAppDispatch()
  useMenuActive(NavigationPath.FeedingClients)

  const { isLoading, action: fetchFeedingClients } = useApiRequest(
    async filters => await dispatch(getFeedingClients(filters)),
  )

  const fetchFeedingClientsDebounced = React.useCallback(useDebounce(fetchFeedingClients, 300), [])
  React.useEffect(() => {
    fetchFeedingClientsDebounced(filters)
  }, [filters])

  const [feedingClientsToggleIsOpen] = useModal('FeedingClientsCreateDialog')

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitle} />
      <Card>
        <CardBody>
          <div className="text-right">
            <Button color="green" onClick={feedingClientsToggleIsOpen}>
              Sprzedaj wyżywienie
            </Button>
          </div>

          <FeedingClientsFilters filters={filters} setFilters={setFilters} defaultFilters={defaultFilters} />

          <FeedingClientsTable
            isLoading={isLoading}
            filters={filters}
            setFilters={setFilters}
            clients={clients || []}
          />
        </CardBody>
      </Card>
    </>
  )
}
