import * as React from 'react'
import { Draggable, DraggableProps } from 'react-beautiful-dnd'
import Table from '@components/table/table'
import { HTMLAttributes } from 'react'

interface Props {
  draggableProps: Omit<DraggableProps, 'children'>
  children: React.ReactNode
  rowProps?: HTMLAttributes<HTMLTableRowElement>
}

export const DraggableTableRow = ({ draggableProps, children, rowProps }: Props) => (
  <Draggable {...draggableProps}>
    {(provided, snapshot) => (
      <Table.Row
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        {...rowProps}
        style={{
          ...provided.draggableProps.style,
          ...(snapshot.isDragging && { display: 'table', border: '1px solid #eef2f7' }),
        }}
      >
        {children}
      </Table.Row>
    )}
  </Draggable>
)
