import * as React from 'react'
import { PaymentDayUserCloseReport, PaymentDayUserReport } from '@models/payments'
import { Col, Row } from 'reactstrap'
import { asDecimal, formatPrice } from '@helpers/utils'
import { IconWithText } from '@components/icon-with-text'
import { useModal } from '@components/modals/use-modal'
import { PaymentRegistersFiltersParams } from '@modules/payment-registers/list/filters'

interface Props {
  dayCloseReport: PaymentDayUserCloseReport
  paymentDayUserReport: PaymentDayUserReport
}

export const UserCashBoxCloseDayCardsTransactions = ({ paymentDayUserReport, dayCloseReport }: Props): JSX.Element => {
  const [handleTransactionListOpen] = useModal('TransactionListModal')

  const baseParams: Partial<PaymentRegistersFiltersParams> = {
    payment_day_user_report: paymentDayUserReport.id,
    source: { value: 'creditcard', label: 'creditcard' },
  }

  const handleKpOpen = (event: React.SyntheticEvent) =>
    handleTransactionListOpen(event, {
      title: 'Sprzedaż KP karta',
      params: { ...baseParams, type: { value: 'KP', label: 'KP' } } as Partial<PaymentRegistersFiltersParams>,
    })
  const handleKwOpen = (event: React.SyntheticEvent) =>
    handleTransactionListOpen(event, {
      title: 'Sprzedaż KW karta',
      params: { ...baseParams, type: { value: 'KW', label: 'KW' } } as Partial<PaymentRegistersFiltersParams>,
    })

  return (
    <div className="p-2 pb-1">
      <IconWithText
        icon="uil-credit-card ml-1 font-15"
        text="Karta płatnicza"
        wrapperClassNames="flex-row-reverse text-strong mb-2"
      />
      <Row className="mb-2 align-items-center">
        <Col md={8}>
          <span className="font-11">Ilość transakcji:</span>
        </Col>
        <Col md={4} className="text-right text-strong">
          {asDecimal(dayCloseReport.cards_kp_count).plus(asDecimal(dayCloseReport.cards_kw_amount)).toString()}
        </Col>
      </Row>
      <Row className="mb-2 align-items-center">
        <Col md={8}>
          <strong className="text-semi-strong d-block mb-1">Wpłaty (KP):</strong>
          <span className="font-11">
            {dayCloseReport.cards_kp_count} transakcji{' '}
            <strong onClick={handleKpOpen} className="cursor-pointer">
              zobacz
            </strong>
          </span>
        </Col>
        <Col md={4} className="text-right text-strong font-15">
          {formatPrice(dayCloseReport.cards_kp_amount)}
        </Col>
      </Row>
      <Row className="mb-2 align-items-center invisible">
        <Col md={8}>
          <strong className="text-semi-strong d-block mb-1">Wypłaty (KW):</strong>
          <span className="font-11">
            {dayCloseReport.cards_kw_count} transakcji{' '}
            <strong onClick={handleKwOpen} className="cursor-pointer">
              zobacz
            </strong>
          </span>
        </Col>
        <Col md={4} className="text-right text-strong font-15">
          {formatPrice(dayCloseReport.cards_kw_amount)}
        </Col>
      </Row>
    </div>
  )
}
