import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useEservicePinPadsList } from '@modules/reception/common/eservice-pin-pad-select/use-eservice-pin-pads-list'
import * as React from 'react'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectPost } from '@store/actions/generic-actions'
import { EservicePinPad, EservicePinPadAction } from '@models/eservice'

interface Props {
  userEservicePadId?: number
}

export const usePinPadReport = ({ userEservicePadId }: Props): [boolean, () => void, EservicePinPad | undefined] => {
  const user = useAuthenticatedUser()
  const pinPads = useEservicePinPadsList()

  if (!userEservicePadId) {
    userEservicePadId = user.eservicePadId
  }

  const eservicePinPad = React.useMemo(
    () => pinPads.find(row => row.id === userEservicePadId),
    [pinPads, userEservicePadId],
  )

  const { isLoading, action: handlePrintReport } = useApiRequest(async () => {
    if (eservicePinPad) {
      return await commonObjectPost<EservicePinPadAction>(eservicePinPad.urls.report)
    }
  })

  return [isLoading, handlePrintReport, eservicePinPad]
}
