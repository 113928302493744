import * as React from 'react'
import { TechnicalOrder, TechnicalOrderResult } from '@models/technical-orders'
import { BaseModalProps } from '@components/modals/types'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Form } from '@hyper/forms/form'
import { SaveButton } from '@hyper/button'
import { useForm, useWatch } from 'react-hook-form'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useCreateOrUpdateTechnicalOrderMutation } from '@api/technical-orders'
import { useHandleRtkQueryError } from '@components/hooks/use-handle-rtk-error'
import { TechnicalOrderCloseForm } from '@modules/technical-orders/technical-order-close-modal/form'
import * as R from 'ramda'
interface Props extends BaseModalProps {
  technicalOrders: TechnicalOrder[]
}

export interface TechnicalOrderCloseFormInputs {
  result: TechnicalOrderResult
  technical_order_reject_reason: number | null
  note: string
  images: number[]
}

export const TechnicalOrderCloseModal: React.FC<Props> = ({ toggleIsVisible, technicalOrders }) => {
  const technicalOrderDetails = technicalOrders.length === 1 ? technicalOrders[0] : undefined
  const methods = useForm<TechnicalOrderCloseFormInputs>({
    defaultValues: R.pick<TechnicalOrderCloseFormInputs, keyof TechnicalOrderCloseFormInputs>(
      ['result', 'technical_order_reject_reason', 'note', 'images'],
      technicalOrderDetails ?? {},
    ),
  })

  const { addSuccessNotification } = useNotificationHook()

  const [updateTechnicalOrder, { error, isError, isLoading }] = useCreateOrUpdateTechnicalOrderMutation()

  const onSubmit = async (payload: TechnicalOrderCloseFormInputs) => {
    for (const technicalOrder of technicalOrders) {
      await updateTechnicalOrder({
        data: payload,
        url: technicalOrder.urls.finish,
        partial: false,
      }).unwrap()
    }

    addSuccessNotification('Zmiany zostały zapisane pomyślnie!')
    toggleIsVisible()
  }

  const result = useWatch({ control: methods.control, name: 'result' })

  React.useEffect(() => {
    if (result === 'success') {
      methods.setValue('technical_order_reject_reason', null)
      methods.setValue('note', '')
      methods.setValue('images', [])
    }
  }, [result])

  useHandleRtkQueryError(error, isError, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>
        {technicalOrderDetails ? `Zamknij zadanie ${technicalOrderDetails.number}` : 'Zamknij zadanie'}
      </ModalHeader>
      <ModalBody>
        <TechnicalOrderCloseForm result={result} />
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Anuluj
        </Button>
        <SaveButton
          className="btn btn-green"
          label="Zamknij zadanie"
          disabled={result === 'unknown'}
          isSaving={isLoading}
        />
      </ModalFooter>
    </Form>
  )
}
