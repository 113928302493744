import * as React from 'react'
import Decimal from 'decimal.js'
import { asDecimal, formatPriceShort } from '@helpers/utils'
import classNames from 'classnames'

interface ReceptionBookingSettlementSummaryProps {
  moneyToReturn: boolean
  restToPay: Decimal
  restToPayWithoutClimatic: Decimal
  depositToReturn: Decimal
  totalDeposit: Decimal
}

export const ReceptionBookingSettlementSummary: React.FC<ReceptionBookingSettlementSummaryProps> = ({
  moneyToReturn,
  depositToReturn,
  restToPay,
  restToPayWithoutClimatic,
  totalDeposit,
}) => {
  const hasReturn = React.useMemo(() => depositToReturn.greaterThan(asDecimal(0)), [depositToReturn])

  const restToPayWithDeposit = React.useMemo(
    () => Decimal.max(asDecimal(0), restToPay.minus(totalDeposit)),
    [totalDeposit, restToPay],
  )

  return (
    <div className="mt-3">
      <strong className="font-14 text-strong d-block mb-2">Wyliczenie pozostałej kwoty:</strong>
      <table className="reception__booking-check-out__bill-step__table">
        <tbody>
          <tr className="reception__booking-check-out__bill-step__table__row">
            <td className="reception__booking-check-out__bill-step__table__column text-grey">Rachunek za pobyt</td>
            <td
              className={classNames('reception__booking-check-out__bill-step__table__column text-right text-strong', {
                'reception__booking-check-out__bill-step__table__column--is-costs':
                  restToPayWithoutClimatic.greaterThan(asDecimal(0)),
              })}
            >
              {formatPriceShort(restToPayWithoutClimatic.toString())}
            </td>
            <td className="reception__booking-check-out__bill-step__table__column" />
            <td className="reception__booking-check-out__bill-step__table__column" width={25} />
          </tr>
          <tr className="reception__booking-check-out__bill-step__table__row">
            <td className="reception__booking-check-out__bill-step__table__column text-grey">Pozostała kwota kaucji</td>
            <td className="reception__booking-check-out__bill-step__table__column text-right text-strong">
              {formatPriceShort(totalDeposit.toString())}
            </td>
            <td className="reception__booking-check-out__bill-step__table__column" />
            <td className="reception__booking-check-out__bill-step__table__column" width={25} />
          </tr>
          {!hasReturn && (
            <tr className="reception__booking-check-out__bill-step__table__row bg-platinum text-strong">
              <td className="reception__booking-check-out__bill-step__table__column text-grey">Pozostało do zapłaty</td>
              <td
                className={classNames('reception__booking-check-out__bill-step__table__column text-right text-strong', {
                  'reception__booking-check-out__bill-step__table__column--is-costs': restToPayWithDeposit.greaterThan(
                    asDecimal(0),
                  ),
                })}
              >
                {formatPriceShort(restToPayWithDeposit.toString())}
              </td>
              <td className="reception__booking-check-out__bill-step__table__column" />
              <td className="reception__booking-check-out__bill-step__table__column" width={25} />
            </tr>
          )}
          {hasReturn && (
            <tr className="reception__booking-check-out__bill-step__table__row bg-platinum text-strong">
              <td className="reception__booking-check-out__bill-step__table__column text-grey">
                Kwota do zwrotu {moneyToReturn ? '' : 'przelewem'}:
              </td>
              <td className="reception__booking-check-out__bill-step__table__column text-success text-right text-strong">
                {formatPriceShort(depositToReturn.toString())}
              </td>
              <td className="reception__booking-check-out__bill-step__table__column" />
              <td className="reception__booking-check-out__bill-step__table__column" width={25} />
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}
