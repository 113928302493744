import * as React from 'react'
import { formatPrice } from '@helpers/utils'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { ReservationBistroVoucherRowActions } from '@modules/reservations/details/bistro-vouchers/reservation-bistro-voucher-row-actions'
import { ReservationBookingVoucher } from '@models/reception'

interface Props {
  voucher: ReservationBookingVoucher
}
export const ReservationBistroVoucherRow = ({ voucher }: Props): JSX.Element => (
  <tr>
    <td className="align-middle">{voucher.description}</td>
    <td className="align-middle">{toDefaultDateTimeFormat(voucher.created)}</td>
    <td className="align-middle">{voucher.created_by_display}</td>
    <td className="align-middle">{formatPrice(voucher.amount)}</td>
    <td className="text-right">
      <ReservationBistroVoucherRowActions voucher={voucher} />
    </td>
  </tr>
)
