import { LocalizationKind } from '@models/package'
import { BaseHistory, ContentNote, DepositPaymentDeadline } from '@models/dashboard'
import { EmailNotification } from '@models/notifications'
import { Subscription, SubscriptionKind } from '@modules/subscription/models'
import { BaseUrls } from '@models/base'
import { GastroCard } from '@models/promotions'

type PackageWholesaleStatus = 'open' | 'close'

export type PackageWholesaleProductKind =
  | 'subscription'
  | 'gastro_card'
  | 'gastro_card_boost'
  | 'gastro_card_2024'
  | 'gastro_card_2025'

export const gastroCardKinds: PackageWholesaleProductKind[] = [
  'gastro_card',
  'gastro_card_2024',
  'gastro_card_2025',
  'gastro_card_boost',
]

export type PackageWholesaleProductOptionKind = 'zoo_borysew'

interface PackageWholesaleProductOption {
  kind: PackageWholesaleProductOptionKind
  price_brutto: string
  description: string
}

export interface PackageWholesaleProduct {
  kind_display: string
  kind: PackageWholesaleProductKind
  subscription_kind_display: string
  subscription_kind_version_display: string
  subscription_kind_version: number | null
  subscription_kind: SubscriptionKind
  id: number
  name: string
  accommodation_type_id: number
  localization_kind: LocalizationKind
  localization_kind_display: string
  price_brutto: string
  status: 'unavailable' | 'available'
  status_display: string
  deposit_payment_deadline: DepositPaymentDeadline
  options: PackageWholesaleProductOption[]
  deposit_amount: number
  defaults_days: number
  with_cleaning_option: boolean
  with_parking: boolean
  with_towel: boolean
  with_grill: boolean
  with_client_change_data_option: boolean
  expire_after_years: number
  urls: BaseUrls
}

interface PackageWholesaleUrls {
  details: string
  notifications: string
  sale: string
}

export interface BasePackageWholesale {
  urls: BaseUrls
  number: string
  id: number
}

export interface PackageWholesaleAggregation {
  apartments_mountains_all_sum: string
  apartments_mountains_sum: string
  apartments_sea_all_sum: string
  apartments_sea_sum: string
  gastro_card_total_price_brutto_all_sum: string
  gastro_card_total_price_brutto_sum: string
  houses_mountains_all_sum: string
  houses_mountains_sum: string
  houses_sea_all_sum: string
  houses_sea_sum: string
  total_price_brutto_all_sum: string
  total_price_brutto_sum: string
}

export interface PackageWholesale {
  apartments_mountains: number
  apartments_mountains_all: number
  apartments_sea: number
  apartments_sea_all: number
  created_by: string
  expire_after: string
  gastro_card_total_price_brutto: string
  gastro_card_total_price_brutto_all: string
  houses_mountains: number
  houses_mountains_all: number
  houses_sea: number
  houses_sea_all: number
  id: number
  invoice_company: string
  invoice_nip: string
  is_active: boolean
  kinds: string[]
  kinds_display: string[]
  name: string
  number: string
  price_brutto: string
  sellers_ids: number[]
  status: PackageWholesaleStatus
  status_display: string
  subscription_total_price_brutto: string
  subscription_total_price_brutto_all: string
  token: string
  total_price_brutto: string
  total_price_brutto_all: string
  urls: PackageWholesaleUrls
}

export interface PackageWholesaleProductDetails extends PackageWholesaleProduct {
  image: string
  standard_price_brutto: string
  subscription_code_name_kind: string
  subscription_code_name_kind_display: string
  discount: number | null
}

interface PackageWholesaleDetailsUrls extends PackageWholesaleUrls {
  admin: string
  mass_email_notification: string
  mass_email_notification_test: string
  counter: string
}

export interface PackageWholesaleDetails extends PackageWholesale {
  created: string
  discount: number | null | string
  display_name: string
  email: string
  gastro_cards: GastroCard[]
  history: BaseHistory[]
  invoice_city: string
  invoice_company: string
  invoice_postcode: string
  invoice_street: string
  leaflet_gastro_card: string
  leaflet_gastro_card_boost: string
  leaflet_gastro_card_2024: string
  leaflet_gastro_card_2025: string
  leaflet_subscription: string
  leaflet_subscription_brown: string
  leaflet_subscription_gold: string
  leaflet_subscription_silver: string
  notes: ContentNote[]
  notifications: EmailNotification[]
  password: string | null
  phone: string
  products: number[]
  seller: string
  sellers: string[]
  slug: string
  subscriptions: Subscription[]
  urls: PackageWholesaleDetailsUrls
}

export const packageWholesaleStatusList: { value: PackageWholesaleStatus; label: string }[] = [
  {
    value: 'open',
    label: 'Otwarty',
  },
  {
    value: 'close',
    label: 'Zamknięty',
  },
]

interface PackageWholesaleAvailableOption {
  kind: string
  name: string
}

export interface PackageWholesaleAppData {
  available_options: PackageWholesaleAvailableOption[]
  deposit_amount: number[]
  defaults_days: number[]
  notification_emails: [string, string][]
  status: 'unknown' | 'loading' | 'ready'
}
