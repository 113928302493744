import * as React from 'react'
import { PromocodePrefix } from '@models/promocode'
import { deletePromocodePrefixes } from '@store/actions/promocode-actions'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { useApiRequest } from '@components/hooks/use-api-request'

interface PromocodePrefixDeleteProps {
  promocodePrefix: PromocodePrefix
  children: React.ReactNode
}

export const PromocodePrefixDelete: React.FC<PromocodePrefixDeleteProps> = ({ promocodePrefix, children }) => {
  const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()

  const { isLoading, action: handleAccept } = useApiRequest(async () => {
    dispatch(deletePromocodePrefixes([promocodePrefix.id]))
    addSuccessMessage('Sukces', 'Prefiks został skasowany.')
  })

  return (
    <CommonObjectConfirmAction
      title="Potwierdź skasowanie prefiksu."
      handleAccept={handleAccept}
      isLoading={isLoading}
      message="Czy na pewno chcesz skasować wybrany prefiks ? Operacja ta jest nieodwracalna."
    >
      {children}
    </CommonObjectConfirmAction>
  )
}
