import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { SubmitFilter } from '@modules/reception/common/filters/submit-filter'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { HousekeepingCleaningOrdersAcceptedFiltersParams } from '@modules/housekeeping/cleaning-orders/accepted/index'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'

interface HousekeepingCleaningOrdersAcceptedFiltersProps {
  defaultFilters: HousekeepingCleaningOrdersAcceptedFiltersParams
  filters: HousekeepingCleaningOrdersAcceptedFiltersParams
  setFilters: (filters: HousekeepingCleaningOrdersAcceptedFiltersParams) => void
}

export const HousekeepingCleaningOrdersAcceptedFilters: React.FC<HousekeepingCleaningOrdersAcceptedFiltersProps> = ({
  setFilters,
  filters,
  defaultFilters,
}) => {
  const { handleSubmit, reset, setValue, watch } = useFormContext<HousekeepingCleaningOrdersAcceptedFiltersParams>()

  const onSubmit = (payload: HousekeepingCleaningOrdersAcceptedFiltersParams) => setFilters(payload)

  const handleReset = () => {
    reset()
    setFilters(defaultFilters)
  }

  useHandleTableFiltersChange(watch, filters, onSubmit)

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="d-flex justify-content-end form form-style-filter"
      autoComplete="off"
    >
      <SubmitFilter hideLabel handleReset={handleReset} formGroupClassName="mb-0" />
      <div>
        <SearchFilter hideLabel setValue={setValue} formGroupClassName="mb-0" />
      </div>
    </form>
  )
}
