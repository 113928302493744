import * as React from 'react'
import { PageTitle } from '@hyper/page-title'
import { useDocumentTitle } from '@helpers/utils'
import { Card, CardBody } from 'reactstrap'
import { useAppDispatch, useAppSelector } from '@store/index'
import { PaymentsReportFiltersParams } from '@modules/reports/models'
import { PaymentsReportFilters } from '@modules/reports/payments-report/payments-report-filters'
import { PaymentsReportsTable } from '@modules/reports/payments-report/payments-report-table'
import { getPaymentsReport } from '@store/actions/report-actions'
import { Button } from 'reactstrap/lib'
import { useModal } from '@components/modals/use-modal'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { paymentsReportSelector } from '@store/slices/reports-slice'
import { useTableFilters } from '@components/table/use-table-filters'

const pageTitle = 'Raport płatności'

interface Props {
  kind: 'climatic' | 'normal'
  url: NavigationPath
}

export const PaymentsReportView: React.FC<Props> = ({ kind, url }) => {
  const defaultFilters: PaymentsReportFiltersParams = {
    booking__resort: '',
    created_at_after: undefined,
    created_at_before: undefined,
    user: undefined,
    source: '',
    type: '',
    ordering: '-created_at',
    page: 1,
    page_size: 10,
    search: '',
    kind,
  }
  useMenuActive(url)
  const dispatch = useAppDispatch()
  useDocumentTitle(pageTitle)

  const { isLoading, filters, setFilters } = useTableFilters({
    defaultFilters,
    action: async filters => await dispatch(getPaymentsReport(filters)),
  })

  const paymentsReport = useAppSelector(paymentsReportSelector)

  const [handleReport] = useModal('ReportsGeneratorBookingPayment', { kind })

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitle} />
      <Card>
        <CardBody>
          <PaymentsReportFilters filters={filters} setFilters={setFilters} defaultFilters={defaultFilters} />

          <PaymentsReportsTable
            isLoading={isLoading}
            filters={filters}
            setFilters={setFilters}
            paymentsReport={paymentsReport}
          />
          <Button onClick={handleReport} color="secondary" className="ml-auto d-flex">
            Pobierz raport
          </Button>
        </CardBody>
      </Card>
    </>
  )
}
