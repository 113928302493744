import * as React from 'react'
import { Card, CardBody } from 'reactstrap'
import { PageTitle } from '@hyper/page-title'
import { useNavigate, useParams } from 'react-router-dom'
import { ButtonWithIcon } from '@components/button-with-icon'
import { NavigationPath } from '@models/routes'
import { FloorHeatingDetailsHistory } from '@modules/floor-heating/details/history/history'
import { FloorHeatingDetailsParameters } from '@modules/floor-heating/details/parameters'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { useFloorHeatingDetails } from '@modules/floor-heating/details/use-floor-heating-details'

type Params = 'apartment_name'

export const FloorHeatingDetailsView: React.FC = () => {
  const { apartment_name } = useParams<Params>()
  const { windowState, measurementUrls, fetchMeasurements } = useFloorHeatingDetails(apartment_name, false)

  React.useEffect(() => {
    fetchMeasurements()
  }, [])

  const title = `Szczegóły pomiarów lokalu ${apartment_name}`

  const navigate = useNavigate()
  const handleMoveToTheList = () => {
    navigate(NavigationPath.FloorHeating)
  }

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: title, path: '', active: true }]} title={title} />
      <Card>
        <CardBody>
          <ButtonWithIcon
            icon="uil-angle-left font-15"
            text="Wróć do listy"
            color="light"
            handleClick={handleMoveToTheList}
          />
          <LoaderPlaceholder content={windowState}>
            <FloorHeatingDetailsParameters windowState={windowState} measurementUrls={measurementUrls} />
          </LoaderPlaceholder>
        </CardBody>
      </Card>
      <FloorHeatingDetailsHistory apartmentId={apartment_name} />
    </>
  )
}
