import { createAsyncThunk } from '@reduxjs/toolkit'
import { commonObjectDelete, commonObjectPatch, commonObjectPost } from '@store/actions/generic-actions'
import { AuthenticatedUser, DashboardAppDataWithUser } from '@models/dashboard'
import { HttpResources } from '@models/http-resources'
import { AsyncThunkParams } from '@store/index'

export const getInitData = createAsyncThunk<DashboardAppDataWithUser, boolean | undefined, AsyncThunkParams>(
  'app/getInitData',
  async (force = false, { getState }) => {
    if (getState().appState.appData.status === 'unknown' || force) {
      return await commonObjectPost<DashboardAppDataWithUser>(HttpResources.DashboardAppData, undefined, undefined, {
        reqId: Math.random(),
      })
    }
    return { ...getState().appState.appData, user: null }
  },
)

export const systemNotificationRemove = createAsyncThunk<AuthenticatedUser, string, {}>(
  'app/systemNotificationRemove',
  async url => await commonObjectDelete<AuthenticatedUser>(url),
)

export const systemNotificationRead = createAsyncThunk<AuthenticatedUser, [string, boolean], {}>(
  'app/systemNotificationRead',
  async ([url, read]) => await commonObjectPatch<AuthenticatedUser>(url, { read }),
)
