import * as React from 'react'
import classNames from 'classnames'

export type Direction = 'left' | 'right'

export interface Props {
  icon: string
  iconSize?: string
  onClick?: () => void
  text: React.ReactNode
  textClass?: string
  wrapperClassNames?: string
  direction?: Direction
  flexClassName?: string
  isDisabled?: boolean
  tooltip?: string
  role?: React.AriaRole
  isLoading?: boolean
  loadingText?: string
}

export const IconWithText = React.memo(
  ({
    icon,
    text,
    direction = 'left',
    iconSize = '',
    wrapperClassNames,
    onClick,
    textClass,
    flexClassName = 'd-inline-flex ',
    isDisabled = false,
    tooltip = '',
    role,
    isLoading,
    loadingText,
  }: Props) => (
    <div
      className={classNames(flexClassName, 'align-items-center', wrapperClassNames, {
        'not-clickable': isDisabled,
        'cursor-pointer': !!onClick && !isDisabled && !isLoading,
      })}
      title={tooltip}
      onClick={isDisabled || isLoading ? undefined : onClick}
      role={role}
    >
      {direction == 'left' && (
        <i className={classNames({ 'mr-1': text }, isLoading ? 'spinner-border avatar-3xs' : icon, iconSize)} />
      )}
      <span className={classNames(textClass)}>{isLoading ? loadingText : text}</span>
      {direction == 'right' && (
        <i className={classNames({ 'mr-1': text }, isLoading ? 'spinner-border avatar-3xs' : icon, iconSize)} />
      )}
    </div>
  ),
)
