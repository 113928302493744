import * as React from 'react'
import { DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap'
import { BookingRefund } from '@modules/accounting/refund/models'
import { RefundBookingsTableActionsPackage } from '@modules/accounting/refund/bookings/table-actions-package'
import { RefundBookingsTableActionsNotification } from '@modules/accounting/refund/bookings/table-actions-notification'
import { ColAuto } from '@hyper/col-auto'
import { RefundBookingsTableActionsSendRefund } from '@modules/accounting/refund/bookings/table-actions-send-refund'
import { RefundBookingsTableActionsBlockRefund } from '@modules/accounting/refund/bookings/table-actions-block-refund'
import { RefundBookingsTableActionsMarkAsSent } from '@modules/accounting/refund/bookings/table-actions-mask-as-sent'

interface Props {
  selectedRows: BookingRefund[]
  clearSelectedRows: () => void
}

export const RefundBookingsTableActions: React.FC<Props> = ({ selectedRows, clearSelectedRows }) => {
  const isDisabled = selectedRows.length === 0

  return (
    <ColAuto className="mt-3">
      <UncontrolledButtonDropdown disabled={isDisabled}>
        <DropdownToggle color="light" caret={true} disabled={isDisabled}>
          <strong>zaznaczone ({selectedRows.length})</strong>
        </DropdownToggle>
        <DropdownMenu>
          <RefundBookingsTableActionsNotification clearSelectedRows={clearSelectedRows} selectedRows={selectedRows} />
          <RefundBookingsTableActionsPackage
            name="bookings"
            clearSelectedRows={clearSelectedRows}
            selectedRows={selectedRows.filter(row => row.deposit_transfer_status === 'client_data_complete')}
          />
          <RefundBookingsTableActionsSendRefund selectedRows={selectedRows} clearSelectedRows={clearSelectedRows} />
          <RefundBookingsTableActionsBlockRefund selectedRows={selectedRows} clearSelectedRows={clearSelectedRows} />
          <RefundBookingsTableActionsMarkAsSent selectedRows={selectedRows} clearSelectedRows={clearSelectedRows} />
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </ColAuto>
  )
}
