import * as React from 'react'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { CashBoxCloseConfirmationModal } from '@modules/payment-registers/user-cashbox/close-day-modal/confirmation/cash-box-close-confirmation-modal'
import { setAuthenticatedUser } from '@store/slices/app-slice'
import { useAppDispatch } from '@store/index'
import { PaymentDayUserReport } from '@models/payments'
import { AuthenticatedUser } from '@models/dashboard'

interface Result {
  showConfirmationModal: (paymentDayUserReport: PaymentDayUserReport, authenticatedUser: AuthenticatedUser) => void
}

export const useCashBoxCloseDayConfirmationModal = (): Result => {
  const { addSuccessMessage } = useNotificationHook()

  const dispatch = useAppDispatch()

  const onDayClose = async (authenticatedUser: AuthenticatedUser) => {
    dispatch(setAuthenticatedUser(authenticatedUser))
  }

  const showConfirmationModal = (paymentDayUserReport: PaymentDayUserReport, authenticatedUser: AuthenticatedUser) => {
    addSuccessMessage(
      'Sukces',
      <span>
        Dzień został zamknięty,
        <br /> pobierz raport zmiany.
      </span>,
      (onHide, messageId) => (
        <CashBoxCloseConfirmationModal
          onHide={onHide}
          messageId={messageId}
          onDayClose={() => onDayClose(authenticatedUser)}
          paymentDayReportDetailsUrl={paymentDayUserReport.urls.details}
        />
      ),
      () => onDayClose(authenticatedUser),
      { isModalCloseAllowed: false },
    )
  }

  return {
    showConfirmationModal,
  }
}
