import * as React from 'react'
import Table, { TableHeaderValue } from '@components/table/table'
import { GenericVoucher } from '@modules/generic-vouchers/models'
import { GenericVoucherFiltersParams } from '@modules/generic-vouchers/list/index'
import { GenericVoucherTableRow } from '@modules/generic-vouchers/list/table-row'

interface Props {
  genericVouchers: GenericVoucher[]
  setFilters: React.Dispatch<React.SetStateAction<GenericVoucherFiltersParams>>
  filters: GenericVoucherFiltersParams
  isLoading: boolean
}

const headers: TableHeaderValue[] = [
  { title: 'Numer', sortField: 'number' },
  { title: 'Klient', sortField: 'client__first_name' },
  { title: 'Status', sortField: 'status' },
  { title: 'Data rejestracji', sortField: 'created' },
  { title: 'Wydany?', sortField: '' },
  { title: 'Data wydania', sortField: '' },
  { title: 'Użyty?', sortField: '' },
  { title: 'Data użycia', sortField: '' },
]

export const GenericVoucherTable: React.FC<Props> = ({ isLoading, filters, setFilters, genericVouchers }) => (
  <Table
    className="table-hover w-100"
    headerValues={headers}
    isLoading={isLoading}
    filters={filters}
    setFilters={setFilters}
  >
    {genericVouchers.map(genericVoucher => (
      <GenericVoucherTableRow key={genericVoucher.id} genericVoucher={genericVoucher} />
    ))}
  </Table>
)
