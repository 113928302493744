import * as React from 'react'
import { PackageDetailsRow } from '@modules/package/details/information/row'
import classNames from 'classnames'
import { toDefaultDateFormat, toDefaultDateTimeFormat } from '@helpers/date-helper'
import { CardBody, Row } from 'reactstrap'
import { ColAuto } from '@hyper/col-auto'
import { IconWithText } from '@components/icon-with-text'
import { PackageWholesaleDetails } from '@modules/package-wholesale/models'
import { formatPrice } from '@helpers/utils'
import { bruttoToNetto } from '@helpers/price'
import { packageWholesaleProductsSelector } from '@store/slices/package-wholesale-slice'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { CustomFileInput } from '@components/custom-file-input'
import { ProductSalePageButton } from '@components/products/product-action-button'
import { usePackageWholesaleProductData } from '@modules/package-wholesale/use-product-data'
import { YesNoBadge } from '@components/badges/yes-no'
import { CopyToClipboard } from '@components/copy-to-clipboard'
import { useAppSelector } from '@store/index'

interface Props {
  packageWholesaleDetails: PackageWholesaleDetails
  toggleEdit: () => void
}

export const PackageWholesaleDetailsBaseInformationTable: React.FC<Props> = ({
  packageWholesaleDetails,
  toggleEdit,
}) => {
  const user = useAuthenticatedUser()
  const products = useAppSelector(packageWholesaleProductsSelector)

  const statusColor = packageWholesaleDetails.status === 'open' ? 'badge-success-lighten' : 'badge-danger-lighten'

  const packageWholesaleProducts = React.useMemo(
    () => products.filter(row => packageWholesaleDetails.products.includes(row.id)),
    [products, packageWholesaleDetails],
  )

  const addedProductsIds = React.useMemo(
    () => packageWholesaleProducts.map(el => el.id),
    [packageWholesaleProducts, products],
  )

  const { kinds, subscriptionKinds, getSubscriptionLeafletData } = usePackageWholesaleProductData(
    addedProductsIds,
    products,
  )

  return (
    <CardBody>
      <Row>
        <ColAuto>
          <h4 className="mt-0 text-secondary mb-3">Szczegóły pakietu</h4>
        </ColAuto>
        {user.is_superuser && (
          <ColAuto>
            <a className="h6 btn-pure text-primary ml-1" target="_blank" href={packageWholesaleDetails.urls.admin}>
              (admin)
            </a>
          </ColAuto>
        )}
        {packageWholesaleDetails.status === 'open' && (
          <ColAuto className="col-auto ml-auto">
            <IconWithText
              icon="uil-edit-alt font-16"
              text="zmień"
              wrapperClassNames="cursor-pointer text-secondary"
              textClass="font-10 text-semi-strong"
              onClick={toggleEdit}
            />
          </ColAuto>
        )}
      </Row>
      <table className="table table-sm mb-0">
        <tbody>
          <PackageDetailsRow label="Status">
            <span className={classNames('badge', statusColor)}>{packageWholesaleDetails.status_display}</span>
          </PackageDetailsRow>
          <PackageDetailsRow label="Zabezpieczone hasłem">
            <YesNoBadge value={!!packageWholesaleDetails.password} />
          </PackageDetailsRow>
          {!!packageWholesaleDetails.password && (
            <PackageDetailsRow label="Hasło">{packageWholesaleDetails.password}</PackageDetailsRow>
          )}
          {kinds.includes('subscription') && (
            <>
              <PackageDetailsRow label="Cena netto pakietów opłaconych">
                {formatPrice(bruttoToNetto(packageWholesaleDetails.total_price_brutto, 8))}
              </PackageDetailsRow>
              <PackageDetailsRow label="Cena netto pakietów zdeklarowanych">
                {formatPrice(bruttoToNetto(packageWholesaleDetails.total_price_brutto_all, 8))}
              </PackageDetailsRow>

              <PackageDetailsRow label="Ilość domków (morze)">
                {packageWholesaleDetails.houses_sea} / {packageWholesaleDetails.houses_sea_all}
              </PackageDetailsRow>
              <PackageDetailsRow label="Ilość apartamentów (morze)">
                {packageWholesaleDetails.apartments_sea} / {packageWholesaleDetails.apartments_sea_all}
              </PackageDetailsRow>

              <PackageDetailsRow label="Ilość domków (góry)">
                {packageWholesaleDetails.houses_mountains} / {packageWholesaleDetails.houses_mountains_all}
              </PackageDetailsRow>
              <PackageDetailsRow label="Ilość apartamentów (góry)">
                {packageWholesaleDetails.apartments_mountains} / {packageWholesaleDetails.apartments_mountains_all}
              </PackageDetailsRow>
            </>
          )}
          <PackageDetailsRow label="Sprzedawca">
            {packageWholesaleDetails.sellers.join(', ') ?? 'Brak'}
          </PackageDetailsRow>
          <PackageDetailsRow label="Data ważności">
            {toDefaultDateFormat(packageWholesaleDetails.expire_after)}
          </PackageDetailsRow>
          <PackageDetailsRow label="Utworzone przez">
            {`${packageWholesaleDetails.created_by}, ${toDefaultDateTimeFormat(packageWholesaleDetails.created)}`}
          </PackageDetailsRow>

          {subscriptionKinds.map(kind => {
            const { label, inputName } = getSubscriptionLeafletData(kind)
            if (!packageWholesaleDetails[inputName] || !kinds.includes('subscription')) return null
            return (
              <PackageDetailsRow label={label} key={kind.subscription_kind}>
                <CustomFileInput.Link url={packageWholesaleDetails[inputName]} />
              </PackageDetailsRow>
            )
          })}

          {packageWholesaleDetails.leaflet_gastro_card && kinds.includes('gastro_card') && (
            <PackageDetailsRow label="Ulotka podwójnej radości">
              <CustomFileInput.Link url={packageWholesaleDetails.leaflet_gastro_card} />
            </PackageDetailsRow>
          )}

          {packageWholesaleDetails.leaflet_gastro_card_boost && kinds.includes('gastro_card_boost') && (
            <PackageDetailsRow label="Ulotka Doładowania Smaku">
              <CustomFileInput.Link url={packageWholesaleDetails.leaflet_gastro_card_boost} />
            </PackageDetailsRow>
          )}

          {packageWholesaleDetails.leaflet_gastro_card_2024 && kinds.includes('gastro_card_2024') && (
            <PackageDetailsRow label="Ulotka podwójnej radości 2024">
              <CustomFileInput.Link url={packageWholesaleDetails.leaflet_gastro_card_2024} />
            </PackageDetailsRow>
          )}

          {packageWholesaleDetails.leaflet_gastro_card_2025 && kinds.includes('gastro_card_2025') && (
            <PackageDetailsRow label="Ulotka podwójnej radości 2025">
              <CustomFileInput.Link url={packageWholesaleDetails.leaflet_gastro_card_2025} />
            </PackageDetailsRow>
          )}

          {packageWholesaleDetails.discount && kinds.includes('subscription') && (
            <PackageDetailsRow label="Rabat">{packageWholesaleDetails.discount}%</PackageDetailsRow>
          )}

          {packageWholesaleDetails.slug && (
            <PackageDetailsRow label="Przyjazny link">
              <span>
                {packageWholesaleDetails.slug}
                <CopyToClipboard className="uil-share-alt ml-1" value={packageWholesaleDetails.urls.sale} />
              </span>
            </PackageDetailsRow>
          )}

          <PackageDetailsRow label="Dostępne produkty">
            {packageWholesaleProducts.map(product => (
              <div key={product.id} className="mb-1">
                {product.name}
              </div>
            ))}
          </PackageDetailsRow>
        </tbody>
      </table>
      <div className="text-right mt-1">
        {packageWholesaleDetails.status === 'open' && (
          <ProductSalePageButton saleUrl={packageWholesaleDetails.urls.sale} />
        )}
      </div>
    </CardBody>
  )
}
