import * as React from 'react'
import { toDefaultDateTimeFormatWithBrake } from '@helpers/date-helper'
import { RootState, useAppSelector } from '@store/index'
import { IssueOrderDetails } from '@modules/housekeeping/applications/models'
import { FiltersHousekeepingApplicationFiltersParams } from '@modules/housekeeping/applications/filters'
import { truncateText } from '@helpers/text'
import classNames from 'classnames'
import { IssueOrderCellActions } from '@modules/housekeeping/applications/issue/table-actions'
import { HousekeepingApplicationExportButtons } from '@modules/housekeeping/applications/application-export-buttons'
import { UserPermission } from '@models/dashboard'
import Table, { TableHeaderValue } from '@components/table/table'

interface Props {
  resortId: number
  isLoading: boolean
  issuesOrders: IssueOrderDetails[]
  setFilters: (filters: FiltersHousekeepingApplicationFiltersParams) => void
  filters: FiltersHousekeepingApplicationFiltersParams
}

const headerValues: TableHeaderValue<IssueOrderDetails>[] = [
  {
    sortField: 'number',
    title: 'Numer',
  },
  {
    sortField: 'created',
    title: 'Dodano',
  },
  {
    sortField: 'apartment',
    title: 'Lokal',
  },
  {
    sortField: '',
    title: 'Numer rezerwacji',
  },
  {
    sortField: 'created_by',
    title: 'Zgłaszający',
  },
  {
    sortField: 'name',
    title: 'Nazwa zgłoszenia',
  },
  {
    sortField: 'status',
    title: 'Status',
  },
  {
    sortField: 'finished_at',
    title: 'Zamknięte',
  },
  {
    sortField: '',
    title: '',
  },
]

export const HousekeepingApplicationIssuesTable: React.FC<Props> = ({
  setFilters,
  issuesOrders,
  resortId,
  filters,
  isLoading,
}) => {
  const issueOrdersUrl = useAppSelector(
    (state: RootState) => state.appState.appData.urls.reports.report_task_issue_order,
  )

  return (
    <>
      <Table
        className="table-hover w-100"
        headerValues={headerValues}
        isLoading={isLoading}
        filters={filters}
        setFilters={setFilters}
      >
        {issuesOrders.map(order => (
          <Table.Row key={order.id}>
            <Table.Cell>{order.number}</Table.Cell>
            <Table.Cell>{toDefaultDateTimeFormatWithBrake(order.created)}</Table.Cell>
            <Table.Cell className="text-strong">
              {order.apartment?.name || 'Część wspólna'}
              {order.is_created_by_client && (
                <strong className="text-danger font-10 d-block" title="Klient zgłosił w aplikacji">
                  Zgłoszenie klienta
                </strong>
              )}
            </Table.Cell>
            <Table.Cell>{order.reservation_number}</Table.Cell>
            <Table.Cell>{order.created_by}</Table.Cell>
            <Table.Cell title={order.name}>{truncateText(order.name, 20)}</Table.Cell>
            <Table.Cell>
              <span
                className={classNames('badge', {
                  'badge-dark': order.status === 'close',
                  'badge-success': order.status === 'open',
                })}
              >
                {order.status_display}
              </span>
            </Table.Cell>
            <Table.Cell>{order.finished_at ? toDefaultDateTimeFormatWithBrake(order.finished_at) : ''}</Table.Cell>
            <Table.Cell className="text-nowrap text-right font-16">
              <IssueOrderCellActions issueOrder={order} />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table>
      <HousekeepingApplicationExportButtons
        filters={filters}
        resort={resortId}
        permissionRequired={UserPermission.ReportsIssueOrder}
        title="Raport usterek"
        url={issueOrdersUrl}
      />
    </>
  )
}
