import * as React from 'react'
import { Card, CardBody, Row } from 'reactstrap'
import { PackageDetailsRow } from '@modules/package/details/information/row'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { ColAuto } from '@hyper/col-auto'
import { IconWithText } from '@components/icon-with-text'
import { useModal } from '@components/modals/use-modal'
import { RentalProductDetails } from '@modules/rental/models'
import { RentalProductDetailsActions } from '@modules/rental/products/modals/details/rental-product-details-actions'
import { useRentalAppData } from '@modules/rental/use-rental-app-data'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'

interface Props {
  productDetails: RentalProductDetails
}

export const RentalProductDetailsBaseDataTable = ({ productDetails }: Props) => {
  const [showEditModal] = useModal('RentalProductCreateModal', { product: productDetails })
  const user = useAuthenticatedUser()

  const { product_categories, warehouses } = useRentalAppData()

  const productWarehouse = warehouses.find(warehouse => warehouse.id === productDetails.warehouse)
  const productCategory = product_categories.find(category => category.id === productDetails.category)

  const canManageRentalProduct = user.hasPerm(UserPermission.RentalProductCanManage)

  return (
    <Card>
      <CardBody>
        <Row className="align-items-center mb-2">
          <ColAuto>
            <h4 className="text-secondary">Dane produktu</h4>
          </ColAuto>
          <ColAuto className="col-auto ml-auto">
            {canManageRentalProduct && (
              <IconWithText
                icon="uil-pen font-16"
                text="edytuj"
                wrapperClassNames="cursor-pointer text-secondary"
                textClass="font-10 text-semi-strong"
                onClick={showEditModal}
              />
            )}
          </ColAuto>
        </Row>
        <table className="table table-sm mb-0">
          <tbody>
            <PackageDetailsRow label="Nazwa produktu">{productDetails.name}</PackageDetailsRow>
            <PackageDetailsRow label="Status">{productDetails.status_display}</PackageDetailsRow>
            <PackageDetailsRow label="Kod">{productDetails.code}</PackageDetailsRow>
            <PackageDetailsRow label="Data utworzenia">
              {toDefaultDateTimeFormat(productDetails.created)}
            </PackageDetailsRow>
            <PackageDetailsRow label="Kategoria">{productCategory?.name ?? 'Brak'}</PackageDetailsRow>
            <PackageDetailsRow label="Magazyn">{productWarehouse?.name ?? 'Brak'}</PackageDetailsRow>
            <PackageDetailsRow label="Rezerwacja">
              {productDetails.booking_reservation_number ?? 'Nie przypisano'}
            </PackageDetailsRow>
          </tbody>
        </table>
        {canManageRentalProduct && <RentalProductDetailsActions productDetails={productDetails} />}
      </CardBody>
    </Card>
  )
}
