import * as React from 'react'
import classNames from 'classnames'

interface Props {
  children: React.ReactNode
  url: string
  className?: string
}

export const ReceptionBookingCheckInStepDocumentsPrintsButton: React.FC<Props> = ({ children, className, url }) => (
  <a
    href={url}
    target="_blank"
    className={classNames('reception__booking-check-in__documents-step__prints__button', className)}
  >
    {children}
    <i className="uil-print reception__booking-check-in__documents-step__prints__button__print-icon" />
  </a>
)
