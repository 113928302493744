import * as React from 'react'
import { Collapse } from 'reactstrap'
import { ReceptionBookingNewFeedingGuestRowHeader } from '@modules/reception/checkin/step-feeding/new-feeding/guest/reception-booking-new-feeding-guest-row-header'
import { ReceptionBookingNewFeedingDayRows } from '@modules/reception/checkin/step-feeding/new-feeding/day-row/reception-booking-new-feeding-day-rows'
import { NewFeedingCalculationDetails } from '@modules/reception/checkin/step-feeding/new-feeding/models'
import { ReceptionBookingGuest } from '@models/booking'
import { ReceptionBookingDetails } from '@models/reception'
import { ReceptionBookingNewFeedingGuestRowCollapsableActions } from '@modules/reception/checkin/step-feeding/new-feeding/guest/reception-booking-new-feeding-guest-row-collapsable-actions'

interface Props {
  isExpanded: boolean
  calculationDetails: NewFeedingCalculationDetails[]
  initialCalculationDetails: NewFeedingCalculationDetails[]
  booking: ReceptionBookingDetails
  guest: ReceptionBookingGuest
}

export const ReceptionBookingNewFeedingGuestRowCollapsable = ({
  calculationDetails,
  initialCalculationDetails,
  isExpanded,
  guest,
  booking,
}: Props): JSX.Element => (
  <Collapse isOpen={isExpanded}>
    <div className="py-3 px-0 bg-white border rounded mx-3 d-flex">
      <div className="col-10">
        <ReceptionBookingNewFeedingGuestRowHeader calculationDetails={calculationDetails} />
        <ReceptionBookingNewFeedingDayRows calculationDetails={calculationDetails} />
      </div>
      {/*<ReceptionBookingNewFeedingGuestDiscountBox calculationDetails={calculationDetails} />*/}
    </div>
    <hr />
    <ReceptionBookingNewFeedingGuestRowCollapsableActions
      booking={booking}
      initialCalculationDetails={initialCalculationDetails}
      guest={guest}
    />
  </Collapse>
)
