import * as React from 'react'
import classname from 'classnames'

interface ReservationStatsTableSummaryRowProps {
  children: React.ReactElement
  className?: string
}

export const ReservationStatsTableSummaryRow: React.FC<ReservationStatsTableSummaryRowProps> = ({
  children,
  className,
}) => <td className={classname('font-weight-bold reservation-stats__col__last', className)}>{children}</td>
