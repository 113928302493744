import * as React from 'react'
import { Button, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { SaveButton } from '@hyper/button'
import { useAppDispatch } from '@store/index'
import { useForm } from 'react-hook-form'
import { createReportTask } from '@store/actions/report-tasks-actions'
import { endOfMonth, startOfMonth, startOfToday } from 'date-fns'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { FormInput } from '@hyper/forms'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { BaseModalProps } from '@components/modals/types'
import { Form } from '@hyper/forms/form'

interface Props extends BaseModalProps {
  dateFrom?: Date
  dateTo?: Date
  url: string
  title: string
  resort?: number
}

interface FormInputs {
  date_from: Date | null
  date_to: Date | null
  resort: number | ''
  status: string
}

export const ReportsApplicationOrders: React.FC<Props> = ({
  toggleIsVisible,
  resort,
  url,
  title,
  dateFrom,
  dateTo,
}) => {
  const dispatch = useAppDispatch()
  const user = useAuthenticatedUser()

  const methods = useForm<FormInputs>({
    mode: 'all',
    defaultValues: {
      resort: resort || '',
      status: '',
      date_from: dateFrom || startOfMonth(startOfToday()),
      date_to: dateTo || endOfMonth(startOfToday()),
    },
  })

  const { isLoading, action: onSubmit } = useReportFormRequest(async (payload: FormInputs) => {
    const reportTask = await dispatch(
      createReportTask([
        url,
        {
          ...payload,
          status: payload.status || undefined,
        },
      ]),
    )
    toggleIsVisible()
    return reportTask
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>{title}</ModalHeader>
      <ModalBody>
        <Row>
          {!resort && (
            <FormInput label="Ośrodek:" type="select" name="resort">
              <option value="">Wszystkie</option>
              {user.resorts.map(resort => (
                <option value={resort.id} key={resort.id}>
                  {resort.name}
                </option>
              ))}
            </FormInput>
          )}

          <DateFromToInput label="Data" startDateName="date_from" endDateName="date_to" />
          <FormInput name="status" label="Status" type="select">
            <option value="">Wszystkie</option>
            <option value="open">Otwarte</option>
            <option value="close">Zamknięte</option>
          </FormInput>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" label="Zleć raport" labelSaving="Zlecanie.." isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
