import * as React from 'react'
import { ReservationDataRow } from '@modules/reservations/details/common/reservation-data-row'
import { IconWithText } from '@components/icon-with-text'
import { ClientProfileRule, ClientRuleKeyword } from '@models/clients'
import classnames from 'classnames'

interface Rule {
  keyword: ClientRuleKeyword
  text: string
}

const rules: Rule[] = [
  { keyword: 'sms_rules', text: 'SMS' },
  { keyword: 'phone_call_rules', text: 'Telefon' },
  { keyword: 'email_rules', text: 'Email' },
]

interface ReceptionBookingDetailsDataProps {
  clientProfileRule: ClientProfileRule
}

export const ReceptionBookingDetailsRules: React.FC<ReceptionBookingDetailsDataProps> = ({ clientProfileRule }) => {
  const hasRule = (keyword: ClientRuleKeyword) =>
    clientProfileRule?.rules.find(rule => rule.keyword === keyword)?.accepted

  return (
    <>
      <ReservationDataRow name="Klient:">
        <strong>
          {clientProfileRule?.client.first_name} {clientProfileRule?.client.last_name}
        </strong>
      </ReservationDataRow>
      <ReservationDataRow name="Zgody RODO">
        {rules.map(rule => (
          <IconWithText
            key={rule.keyword}
            icon={classnames(
              'font-15 font-weight-bold',
              hasRule(rule.keyword as ClientRuleKeyword) ? 'uil-check text-success' : 'uil-times text-danger',
            )}
            text={rule.text}
            textClass="lh-initial"
            wrapperClassNames="mr-3"
          />
        ))}
      </ReservationDataRow>
    </>
  )
}
