import * as React from 'react'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { FormProvider, useForm } from 'react-hook-form'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useFormRequest } from '@components/hooks/use-api-request'
import { commonObjectPatch, commonObjectPost } from '@store/actions/generic-actions'
import { Button, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { FormInput } from '@hyper/forms'
import { BaseModalProps } from '@components/modals/types'
import { AssetCategory } from '@models/cms-asset'
import { updateAssetCategoryDetails } from '@store/slices/assets-slice'

interface Props extends BaseModalProps {
  category?: AssetCategory
}

interface CmsAssetCategoryFormInputs {
  name: string
}

export const CmsAssetCategoryModal: React.FC<Props> = ({ toggleIsVisible, category }) => {
  const url = useAppSelector((state: RootState) => state.appState.appData.urls.cms.asset_categories)
  const methods = useForm<CmsAssetCategoryFormInputs>({ defaultValues: { ...category } })
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: CmsAssetCategoryFormInputs) => {
    dispatch(
      updateAssetCategoryDetails(
        category
          ? await commonObjectPatch<AssetCategory>(category.urls.details, payload)
          : await commonObjectPost<AssetCategory>(url, payload),
      ),
    )
    addSuccessMessage(
      'Sukces',
      category ? 'Kategoria została zmieniona' : `Kategoria o nazwie "${payload.name}" została dodana`,
    )
    toggleIsVisible()
  }, methods.setError)

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggleIsVisible}>{category ? 'Edytuj kategorię' : 'Utwórz kategorię'}</ModalHeader>
        <ModalBody>
          <Row>
            <FormInput label="Nazwa kategorii" colSize={12} name="name" />
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="light" type="button" onClick={toggleIsVisible}>
            Zamknij
          </Button>
          <SaveButton className="btn btn-green" isSaving={isLoading} />
        </ModalFooter>
      </form>
    </FormProvider>
  )
}
