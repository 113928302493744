import * as React from 'react'
import { useParams } from 'react-router-dom'
import { getReceptionBookingDetails, getReceptionBookingUrl, getResortDetails } from '@store/actions/reception-actions'
import { useAppDispatch, useAppSelector } from '@store/index'
import { ReceptionWebSocketHandler } from '@modules/reception/reception-web-socket-handler'
import { useModal } from '@components/modals/use-modal'
import { receptionBookingDetailsSelector } from '@store/selectors/reception'

export const ReceptionBookingCheckOutView: React.FC = () => {
  const { booking_id, step } = useParams<'booking_id' | 'step'>()
  const dispatch = useAppDispatch()

  const booking = useAppSelector(receptionBookingDetailsSelector)

  const [toggleIsOpen] = useModal('ReceptionBookingCheckOutDialog')

  const fetchBooking = async () => {
    const bookingDetails = await dispatch(getReceptionBookingDetails(getReceptionBookingUrl(booking_id || '')))
    toggleIsOpen(null, {
      booking: bookingDetails,
      initialStep: parseInt(step || '1', 10),
    })
  }

  React.useEffect(() => {
    if (booking) {
      dispatch(getResortDetails(booking.resort_id, booking.accommodation_type_id))
    }
  }, [booking])

  React.useEffect(() => {
    fetchBooking()
  }, [])

  // Widok tymczasowy do testów itp

  return <ReceptionWebSocketHandler />
}
