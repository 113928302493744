import * as React from 'react'
import { useForm } from 'react-hook-form'
import { Button, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { SaveButton } from '@hyper/button'
import { useFormRequest } from '@components/hooks/use-api-request'
import { BaseModalProps } from '@components/modals/types'
import { CafeteriaDetails } from '@models/cafeteria'
import { useCafeteriaAppData } from '@modules/cafeteria/hooks/use-cafeteria-app-data'
import { updateCafeteriaDetails } from '@store/slices/cafeteria-slice'
import { commonObjectPost } from '@store/actions/generic-actions'
import { FormInput, FormPlain } from '@hyper/forms'
import { FormInputNumber } from '@hyper/forms/form-input-number'
import { useModal } from '@components/modals/use-modal'
import { useProductSaleConfirmationModal } from '@components/sale/use-product-sale-confirmation-modal'
import DatePickerInput from '@components/date/date-picker-input'
import { startOfToday } from 'date-fns'
import { FormSelect } from '@hyper/forms/form-select'
import { createSelectOption, extractSelectOptionsValues } from '@helpers/utils'
import { subscriptionKindOptions } from '@modules/subscription/models'
import { Form } from '@hyper/forms/form'
import { DepositPaymentDeadline } from '@models/dashboard'
import { useAppData } from '@components/hooks/use-app-data'

export interface CafeteriaFormInputs {
  name: string
  localization_kind?: CustomReactSelectOption
  accommodation_type?: CustomReactSelectOption
  operator?: CustomReactSelectOption
  residence_brutto: string
  codes_amount: number
  deposit_payment_deadline: DepositPaymentDeadline
  expire_after: Date
  max_days: number
  kind?: CustomReactSelectOption
}

const accommodationTypeOptions = [createSelectOption('Domek', 1), createSelectOption('Apartament', 2)]
const localizationKindOptions = [createSelectOption('Morze', 'sea'), createSelectOption('Góry', 'mountains')]

export const CafeteriaCreateModal: React.FC<BaseModalProps> = ({ toggleIsVisible }) => {
  const { cafeteria_operators } = useCafeteriaAppData()
  const createUrl = useAppSelector((state: RootState) => state.appState.appData.urls.cafeteria.cafeterias)
  const operators = React.useMemo(
    () => cafeteria_operators.map(row => ({ label: row.name, value: row.id })),
    [cafeteria_operators],
  )
  const [showCafeteriaDetails] = useModal('CafeteriaDetailsModal', null, { persist: true })
  const { deposit_payment_deadline } = useAppData()

  const dispatch = useAppDispatch()
  const { showConfirmationPackageCreation } = useProductSaleConfirmationModal()

  const methods = useForm<CafeteriaFormInputs>({ defaultValues: { max_days: 5 } })

  const { isLoading, action: onSubmit } = useFormRequest(
    async (payload: CafeteriaFormInputs) => {
      const cafeteria = await commonObjectPost<CafeteriaDetails>(createUrl, extractSelectOptionsValues(payload))
      dispatch(updateCafeteriaDetails(cafeteria))
      showConfirmationPackageCreation(() => showCafeteriaDetails(null, { cafeteria }))
      toggleIsVisible()
    },
    methods.setError,
    { showGlobalError: true },
  )

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Dodaj kafeterie</ModalHeader>
      <ModalBody>
        <Row>
          <FormInput name="name" label="Nazwa" />
          <FormInput label="Wartość pobytu" name="residence_brutto" type="currency" colSize={6} />
          <FormInput label="Długość pobytu (doby)" name="max_days" type="number" colSize={6} />
          <FormInputNumber label="Ilość kodów do wygenerowania" name="codes_amount" />
          <FormSelect
            options={operators}
            name="operator"
            label="Operator"
            formPlainProps={{ colSize: 6 }}
            selectProps={{ placeholder: 'Wybierz operatora' }}
          />
          <FormSelect
            options={subscriptionKindOptions}
            name="kind"
            label="Rodzaj pakietu"
            formPlainProps={{ colSize: 6 }}
          />
          <FormPlain colSize={6} name="expire_after" label="Data ważności">
            <DatePickerInput minDate={startOfToday()} name="expire_after" wrapperClassName="flex-grow" />
          </FormPlain>
          <FormInput label="Termin wpłaty kaucji" name="deposit_payment_deadline" colSize={4} type="select">
            {deposit_payment_deadline.map(([value, label]) => (
              <option value={value} key={value}>
                {label}
              </option>
            ))}
          </FormInput>
          <FormSelect
            formPlainProps={{ colSize: 6 }}
            options={accommodationTypeOptions}
            name="accommodation_type"
            label="Zakwaterowanie"
          />
          <FormSelect
            formPlainProps={{ colSize: 6 }}
            options={localizationKindOptions}
            name="localization_kind"
            label="Lokalizacja"
          />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
