import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { useForm } from 'react-hook-form'
import { Form } from '@hyper/forms/form'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { FormTimeSpinInput } from '@hyper/forms/form-time-spin-input'

interface FormInputs {
  hour: string
  minute: string
}

interface Props extends BaseModalProps {
  hoursFrom: string
  onHoursFromChange: (time: string) => void
}

export const BandScanningTimeConfigurationModal: React.FC<Props> = ({
  toggleIsVisible,
  hoursFrom,
  onHoursFromChange,
}) => {
  const methods = useForm<FormInputs>({
    defaultValues: {
      hour: hoursFrom ? hoursFrom.split(':')[0] : '16',
      minute: hoursFrom ? hoursFrom.split(':')[1] : '00',
    },
  })

  const onSubmit = (payload: FormInputs) => {
    onHoursFromChange(`${payload.hour}:${payload.minute}`)
    toggleIsVisible()
  }

  return (
    <Form onSubmit={onSubmit} methods={methods}>
      <ModalHeader toggle={toggleIsVisible}>Wskaż dostępność opasek</ModalHeader>
      <ModalBody className="d-flex flex-column align-items-center">
        <strong className="mb-2 d-block">Opaski aktywne od godziny:</strong>
        <FormTimeSpinInput />
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Anuluj
        </Button>
        <Button className="btn btn-green">Zatwierdź</Button>
      </ModalFooter>
    </Form>
  )
}
