import * as React from 'react'
import { SubscriptionDetails } from '@modules/subscription/models'
import { Button, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useFormRequest } from '@components/hooks/use-api-request'
import { useForm } from 'react-hook-form'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { createSelectOption, extractSelectOptionsValues, formatPrice, formatPriceShort } from '@helpers/utils'
import { useAppDispatch } from '@store/index'
import { updateSubscriptionDetails } from '@store/slices/subscription-slice'
import { startOfToday, subDays } from 'date-fns'
import { BaseModalProps } from '@components/modals/types'
import { Form } from '@hyper/forms/form'
import { FormInput, FormPlain } from '@hyper/forms'
import DatePickerInput from '@components/date/date-picker-input'
import { PaymentMethod, paymentMethods } from '@models/payments'
import { FormSelect } from '@hyper/forms/form-select'
import { CustomReactSelectOption } from '@components/custom-react-select'

interface Props extends BaseModalProps {
  subscriptionDetails: SubscriptionDetails
}

export interface PaymentCreateModalFormInputs {
  amount: CustomReactSelectOption
  source: CustomReactSelectOption
  description: string
  invoice_date: Date
  seller: CustomReactSelectOption | null
}

export const SubscriptionPaymentCreateModal: React.FC<Props> = ({ subscriptionDetails, toggleIsVisible }) => {
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const amountOptions = [
    createSelectOption(formatPrice(subscriptionDetails.required_payment), subscriptionDetails.required_payment),
  ]

  const methods = useForm<PaymentCreateModalFormInputs>({
    defaultValues: {
      amount: amountOptions[0],
      invoice_date: startOfToday(),
    },
  })

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: PaymentCreateModalFormInputs) => {
    dispatch(
      updateSubscriptionDetails(
        await commonObjectPost<SubscriptionDetails>(
          subscriptionDetails.urls.payments,
          extractSelectOptionsValues(payload),
        ),
      ),
    )
    addSuccessMessage('Sukces', `Wpłata w kwocie ${formatPriceShort(payload.amount.value)} została dodana!`)
    toggleIsVisible()
  }, methods.setError)

  const sources = paymentMethods.filter((source: [PaymentMethod, string]) =>
    [PaymentMethod.form_bank, PaymentMethod.polcard].includes(source[0]),
  )

  const sourceOptions = sources.map(([value, label]) => createSelectOption(label, value))

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Dodaj wpłatę</ModalHeader>
      <ModalBody>
        <Row>
          <FormSelect
            options={amountOptions}
            name="amount"
            selectProps={{ isDisabled: true }}
            label="Kwota"
            formPlainProps={{ colSize: 6 }}
          />
          <FormPlain name="invoice_date" colSize={6}>
            <label>Data wpłaty</label>
            <DatePickerInput maxDate={startOfToday()} minDate={subDays(startOfToday(), 3)} name="invoice_date" />
          </FormPlain>
          <FormSelect options={sourceOptions} name="source" label="Typ płatności" />
          <FormInput label="Numer transakcji bankowej" name="description" />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton role="submit" className="btn btn-green" label="Zapisz" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
