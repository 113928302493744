import * as React from 'react'
import { UpSellBookingsTab, UpSellBookingsTabs } from '@modules/upselling/upsell-bookings/upsell-bookings-tabs'
import { useLocation, useParams } from 'react-router-dom'
import { useGetUpSellBookingsQuery } from '@api/upSellBooking'
import { UpSellBookingsTable } from '@modules/upselling/upsell-bookings/table'
import { TableFilters } from '@components/table/table'
import { UpsellBookingsLayout } from '@modules/upselling/upsell-bookings-layout'
import { UpsellBookingsFilters } from '@modules/upselling/upsell-bookings/filters/upsell-bookings-filters'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { startOfToday } from 'date-fns'
import { useDebouncedValue } from 'rooks'
import { UpSellBooking, UpSellBookingStatus } from '@models/upselling'
import { NavigationPath } from '@models/routes'
import { parseISODate } from '@helpers/date-helper'
import { useQueryWithPaginationResponse } from '@components/hooks/use-query-hooks'

export interface UpsellingRouteState {
  date: string
}

interface Params {
  type: UpSellBookingsTab
}

export interface UpsellBookingsFormInputs extends TableFilters {
  contact_required_date: Date
  mode: UpSellBookingsTab
  status: UpSellBookingStatus[]
  only_without_improvement: boolean
  only_without_feedings: boolean
}

export const UpSellBookingsView: React.FC = () => {
  const { type } = useParams() as unknown as Params
  const { state } = useLocation() as { state: UpsellingRouteState }

  const defaultFilters: UpsellBookingsFormInputs = {
    ordering: '',
    page: 1,
    page_size: 25,
    search: '',
    mode: type,
    status: [],
    only_without_improvement: false,
    only_without_feedings: false,
    contact_required_date: state?.date ? (parseISODate(state.date) as Date) : startOfToday(),
  }

  const methods = useForm<UpsellBookingsFormInputs>({ defaultValues: defaultFilters })
  const formValues = useWatch({ control: methods.control })

  const [search] = useDebouncedValue(formValues.search, 500)

  const {
    data: upsellBookings,
    isLoading,
    isFetching,
  } = useQueryWithPaginationResponse<UpSellBooking[]>(
    useGetUpSellBookingsQuery({
      ...formValues,
      search,
      page: formValues.mode === type ? formValues.page : 1,
      mode: type,
    }),
  )

  return (
    <UpsellBookingsLayout>
      <div className="mt-3">
        <FormProvider {...methods}>
          <UpSellBookingsTabs type={type} path={NavigationPath.UpSellBookingsWithParams} />
          <UpsellBookingsFilters type={type} />
          <UpSellBookingsTable
            type={type}
            upsellBookings={upsellBookings}
            isLoading={isLoading || isFetching}
            filters={formValues as UpsellBookingsFormInputs}
            setFilters={methods.reset}
          />
        </FormProvider>
      </div>
    </UpsellBookingsLayout>
  )
}
