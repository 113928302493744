export interface BaseObj {
  id: number
}

export interface BaseUrls {
  details: string
}

export interface BaseDictionary {
  id: string
  name: string
}

export enum ResponseHeaders {
  API_ERROR_CODE = 'api-error-code',
}
