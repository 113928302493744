import * as React from 'react'
import { formatPrice } from '@helpers/utils'
import { BookingDay } from '@models/booking'
import { toTextDateFormat } from '@helpers/date-helper'

interface Props {
  days: BookingDay[]
}

export const ReservationBillExpandedStayDays = ({ days }: Props): JSX.Element => (
  <table className="table table-sm col-9 ml-auto mb-0 font-12 table-striped">
    <tbody>
      {days.map((day: BookingDay) => (
        <tr key={day.id}>
          <td className="pl-2">{toTextDateFormat(day.date)}</td>
          <td className="fw-semi-bold text-right">+ {formatPrice(day.price_brutto)}</td>
          <td width="50" />
        </tr>
      ))}
    </tbody>
  </table>
)
