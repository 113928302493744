import * as React from 'react'
import { UpsellBookingsHeader } from '@modules/upselling/common/upsell-bookings-header'

interface Props {
  children: React.ReactNode
  onNavigationBack?: () => void
}

export const UpsellBookingsLayout = ({ children, onNavigationBack }: Props): JSX.Element => (
  <div className="upsell__view">
    <UpsellBookingsHeader onNavigationBack={onNavigationBack} />
    <div className="px-3 pb-3">{children}</div>
  </div>
)
