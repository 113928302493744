import * as React from 'react'
import { Card, CardBody } from 'reactstrap'
import { GastroCardsTable } from '@modules/promotions/gastro-cards/gastro-cards-table'
import { GastroCardsFilters } from '@modules/promotions/gastro-cards/gastro-cards-filters'
import { GastroCardsExportButtons } from '@modules/promotions/gastro-cards/gastro-cards-export-buttons'
import { GastroCardWebSocketHandler } from '@modules/promotions/gastro-cards/gastro-card-web-socket-handler'
import { GastroCardsFiltersParams } from '@modules/promotions/gastro-cards/models'
import * as queryString from 'querystring'
import { useSelectableRows } from '@components/table/use-selectable-rows'
import { GastroCard } from '@models/promotions'
import { getGastroCards } from '@store/actions/gastro-card-actions'
import { gastroCardsSelector } from '@store/slices/gastro-card-slice'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { useTableFilters } from '@components/table/use-table-filters'
import { useAppDispatch, useAppSelector } from '@store/index'
import { PageTitleWithSize } from '@hyper/page-title-with-size'

const kind = 'normal'

export const GastroCardsView: React.FC = () => {
  const { q } = queryString.parse(location.search.replace('?', ''))

  const defaultFilters: GastroCardsFiltersParams = {
    is_removed: false,
    ordering: '-sell_date',
    page: 1,
    page_size: 10,
    source_marketing: undefined,
    search: String(q || ''),
    payment_date_after: undefined,
    payment_date_before: undefined,
    sell_date_after: undefined,
    sell_date_before: undefined,
    seller: undefined,
    status: [],
    kind,
  }
  useMenuActive(NavigationPath.PromotionsGastroCards)

  const cards = useAppSelector(gastroCardsSelector)
  const gastroCards = React.useMemo(() => cards.filter(row => row.kind === kind), [cards])
  const dispatch = useAppDispatch()

  const {
    isLoading,
    filters,
    setFilters,
    updateFiltersRequest: fetchGastroCards,
  } = useTableFilters<GastroCardsFiltersParams>({
    defaultFilters,
    action: async filters => await dispatch(getGastroCards([filters, undefined])),
  })

  const { selectableHeader, selectableCell, selectedRows, clearSelectedRows } =
    useSelectableRows<GastroCard>(gastroCards)

  return (
    <>
      <GastroCardWebSocketHandler kind={kind} />
      <PageTitleWithSize title="Podwójna radość" />
      <Card>
        <CardBody>
          <GastroCardsFilters
            defaultFilters={defaultFilters}
            filters={filters}
            setFilters={setFilters}
            fetchGastroCards={() => fetchGastroCards(filters)}
            selectedRows={selectedRows}
            kind={kind}
            clearSelectedRows={clearSelectedRows}
          />

          <div data-testid="qa-gastro-voucher-table">
            <GastroCardsTable
              selectableHeader={selectableHeader}
              selectableCell={selectableCell}
              gastroCards={gastroCards}
              isLoading={isLoading}
              filters={filters}
              setFilters={setFilters}
              kind={kind}
              basePath={NavigationPath.PromotionsGastroCards}
              pathWithParams={NavigationPath.PromotionsGastroCardsWithParams}
            />
            <GastroCardsExportButtons filters={filters} kind={kind} />
          </div>
        </CardBody>
      </Card>
    </>
  )
}
