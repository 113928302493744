import * as React from 'react'
import { Label } from 'reactstrap'
import { parseISODate } from '@helpers/date-helper'
import { ReceptionBookingDetails } from '@models/reception'
import Col from 'reactstrap/lib/Col'
import DateRangeInput from '@components/date/date-range-input'

interface StepGuestsGuestFormDatesProps {
  booking: ReceptionBookingDetails
}

export const StepGuestsGuestFormDates: React.FC<StepGuestsGuestFormDatesProps> = ({ booking }) => {
  const minDate = React.useMemo(() => parseISODate(booking.date_from), [booking.date_from])
  const maxDate = React.useMemo(() => parseISODate(booking.date_to), [booking.date_to])

  return (
    <Col md={6}>
      <div className="d-flex">
        <Label className="mr-2">Pobyt w dniach:</Label>
        <span className="font-10 text-semi-strong align-self-end mb-05">pozostaw puste jeśli cały pobyt</span>
      </div>

      <DateRangeInput
        isClearable={true}
        minDate={minDate}
        maxDate={maxDate}
        placeholder="Cały pobyt"
        wrapperClassName="flex-grow-1"
        inputGroupClassName="form-group"
        startDateName="date_from"
        endDateName="date_to"
      />
    </Col>
  )
}
