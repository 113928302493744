import * as React from 'react'
import { useDrag, useDragLayer, useDrop } from 'react-dnd'
import styleHelper from '@helpers/style-helper'
import { getEmptyImage } from 'react-dnd-html5-backend'
import classNames from 'classnames'
import { RootState, useAppSelector } from '@store/index'

interface Props {
  infiniteScrollerRef: React.RefObject<HTMLDivElement>
}

const TimelineScrollbar: React.FC<Props> = ({ infiniteScrollerRef }) => {
  const isMobileView = useAppSelector((state: RootState) => state.appState.isMobileView)
  const scrollHandleWidth = 100
  const { type, diffOffset, isDragging } = useDragLayer(monitor => ({
    type: monitor.getItemType(),
    diffOffset: monitor.getDifferenceFromInitialOffset(),
    isDragging: monitor.isDragging(),
  }))

  const [, dropRef] = useDrop({
    accept: ['TIMELINE_SCROLLBAR'],
  })

  const [, dragRef, preview] = useDrag({
    type: 'TIMELINE_SCROLLBAR',
  })

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (infiniteScrollerRef.current) {
        infiniteScrollerRef.current.scrollLeft = infiniteScrollerRef.current.scrollLeft + getMovementPercent()
      }
    }, 50)
    return () => {
      clearInterval(interval)
    }
  }, [getLeftStyle()])

  React.useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [])

  function getScrollbarWidth(): number {
    return infiniteScrollerRef.current ? infiniteScrollerRef.current.offsetWidth : 0
  }

  function getMovementPercent(): number {
    const possibleMovement = (getScrollbarWidth() - scrollHandleWidth) / 2
    return (getLeftStyle() / possibleMovement) * 100
  }

  function getLeftStyle(): number {
    if (type !== 'TIMELINE_SCROLLBAR' || !diffOffset) {
      return 0
    }

    const diff = diffOffset.x
    return Math.abs(diff * 2) <= getScrollbarWidth() - scrollHandleWidth
      ? diff
      : diff < 0
        ? (getScrollbarWidth() - scrollHandleWidth) / -2
        : (getScrollbarWidth() - scrollHandleWidth) / 2
  }

  function isDraggingScrollbar(): boolean {
    return isDragging && type === 'TIMELINE_SCROLLBAR'
  }

  return (
    <div
      className={classNames('calendar-scrollbar__drag-area', { 'is-dragging': isDraggingScrollbar() })}
      style={{ width: styleHelper.valueToPX(getScrollbarWidth()) }}
      ref={dropRef}
    >
      <div className={classNames('calendar-scrollbar__wrapper', { 'is-mobile': isMobileView })}>
        <div
          className="calendar-scrollbar__inner"
          ref={dragRef}
          style={{ left: styleHelper.valueToPX(getLeftStyle() * 2) }}
        />
      </div>
    </div>
  )
}

export default TimelineScrollbar
