import * as React from 'react'
import { calculateShopProduct } from '@store/actions/shop-actions'
import { EnhancedShopProduct, ShopTicket } from '@modules/shop/pos/models'
import * as R from 'ramda'
import { useDebounce } from 'rooks'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useCancelablePromise } from '@components/hooks/use-cancelable-promise'
import { useFormContext, useWatch } from 'react-hook-form'
import { ShopPOSWithParamsFormInputs } from '@modules/shop/pos/shop-pos-with-params'
import { useAppData } from '@components/hooks/use-app-data'
import { useApiRequest } from '@components/hooks/use-api-request'
import { parsePayload } from '@store/actions/generic-actions'

export interface ProductCalculation {
  productId: number
  priceBrutto: string
}

interface Props {
  addedProducts: EnhancedShopProduct[]
  tickets: ShopTicket[]
  resortId: string
  room: CustomReactSelectOption | null
  render: (element: ProductCalculatorProviderState) => React.ReactElement
}

interface ProductCalculatorProviderState {
  totalPriceBrutto: string
  packageVipDiscount: boolean
  productsCalculation: ProductCalculation[]
}

export const ProductCalculatorProvider: React.FC<Props> = ({ room, render, resortId, addedProducts, tickets }) => {
  const [calculation, setCalculation] = React.useState<ProductCalculatorProviderState>({
    productsCalculation: [],
    totalPriceBrutto: '0',
    packageVipDiscount: false,
  })

  const appData = useAppData()

  const { control } = useFormContext<ShopPOSWithParamsFormInputs>()
  const paymentEnabled = useWatch({ control, name: 'paymentEnabled' })

  const calculatePrices = useDebounce(async (products, tickets, cancelToken) => {
    const productWithAmount = products.filter(product => !!product.amount)

    try {
      const result = await calculateShopProduct(
        {
          resort: resortId,
          items: productWithAmount.map(row => ({
            amount: row.amount,
            product: row.id,
            discount: 0,
            params: row.params ? parsePayload(row.params) : null,
          })),
          tickets: R.sum(tickets.map(row => row.value)),
          payment: 'cash',
          apartment: room?.value,
        },
        cancelToken,
        appData.urls.shop.sell_product_calculation,
      )

      setCalculation({
        totalPriceBrutto: result.total_brutto,
        productsCalculation: result.products.map(calculation => ({
          productId: calculation.product,
          priceBrutto: calculation.price_brutto,
        })),
        packageVipDiscount: result.package_vip_discount,
      })
    } catch (e) {
      console.error(e)
    }
  }, 250)

  const { action: calculate } = useApiRequest(calculatePrices)

  useCancelablePromise(
    cancelToken => {
      if (addedProducts.length) {
        calculate(addedProducts, tickets, cancelToken)
      } else {
        setCalculation({
          productsCalculation: [],
          totalPriceBrutto: '0',
          packageVipDiscount: false,
        })
      }
    },
    [addedProducts, tickets, resortId, paymentEnabled],
  )

  return render(calculation)
}
