import { FieldValue, useFormContext } from 'react-hook-form'
import { useAppDispatch } from '@store/index'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { BookingStatsDetails, StatsCellContent } from '@modules/reservations/models'
import { createReportTask } from '@store/actions/report-tasks-actions'
import { ReservationStatsFormInputs } from '@modules/reservations/statistics/common/common-stats-box-details'

interface Response {
  generateReport: (
    content: StatsCellContent<BookingStatsDetails>,
    url: string,
    dataProvider?: (formValues: FieldValue<ReservationStatsFormInputs>) => {},
  ) => Promise<void>
}

export const useStatsReportGenerator = (): Response => {
  const { getValues, setError } = useFormContext()

  const dispatch = useAppDispatch()

  const { action: generateReport } = useReportFormRequest(
    async (
      content: StatsCellContent<BookingStatsDetails>,
      url: string,
      dataProvider?: (formValues: FieldValue<ReservationStatsFormInputs>) => ReservationStatsFormInputs,
    ) => {
      const formValues = getValues()
      await dispatch(
        createReportTask([
          url,
          dataProvider
            ? dataProvider(formValues)
            : {
                date: content.statsDetails.date,
                action_kind: content.element,
                ...(formValues.resort?.value && { resort: formValues.resort.value }),
              },
        ]),
      )
    },
    setError,
    'notification',
  )

  return {
    generateReport,
  }
}
