import * as React from 'react'
import { ReceptionBookingGuest } from '@models/booking'
import { ReceptionBookingDetails, ReceptionBookingGroup } from '@models/reception'
import { StepGuestsGuestListRow } from '@modules/reception/checkin/step-guests/step-guests-guest-list-row'
import { StepGuestGroup } from './group/step-guest-group'
import { groupByField } from '@helpers/utils'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { StepGuestsBands } from '@modules/reception/checkin/step-guests/bands'
import { StepGuestsCars } from '@modules/reception/checkin/step-guests/cars'
import { StepVerificationVoucher } from '@modules/reception/checkin/step-verification/step-verification-voucher/step-verification-voucher'

interface GroupSection {
  group: ReceptionBookingGroup
  guests: ReceptionBookingGuest[]
}

interface StepGuestsListProps {
  guests: ReceptionBookingGuest[]
  booking: ReceptionBookingDetails
  updateState: (booking: ReceptionBookingDetails) => void
  hideVouchers?: boolean
  hideCars?: boolean
}

export const StepGuestsList: React.FC<StepGuestsListProps> = ({
  hideVouchers,
  hideCars,
  guests,
  booking,
  updateState,
}) => {
  const groups = groupByField(guests, 'group_id')

  const groupsForBooking = booking.groups.map((group: ReceptionBookingGroup) => ({
    group,
    guests: groups[group.id] || [],
  }))

  const guestGroups = groupsForBooking.map((groupSection: GroupSection) => (
    <StepGuestGroup
      name={groupSection.group.name}
      booking={booking}
      updateState={updateState}
      group={groupSection.group}
      key={groupSection.group.id}
    >
      <table className="reservation-guests__table table table-striped mb-0">
        <tbody>
          {groupSection.guests.map((guest: ReceptionBookingGuest) => (
            <StepGuestsGuestListRow
              updateState={updateState}
              key={guest.id}
              guest={guest}
              booking={booking}
              group={groupSection.group}
            />
          ))}
          <StepGuestsBands booking={booking} />
          {!hideCars && (
            <>
              <StepGuestsCars booking={booking} group={groupSection.group} />
              <StepGuestsCars booking={booking} group={groupSection.group} kind="parking_large" />
              <StepGuestsCars booking={booking} group={groupSection.group} kind="parking_vip" />
            </>
          )}
          {!hideVouchers && <StepVerificationVoucher booking={booking} group={groupSection.group} />}
        </tbody>
      </table>
    </StepGuestGroup>
  ))

  return <DndProvider backend={HTML5Backend}>{guestGroups}</DndProvider>
}
