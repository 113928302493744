import * as React from 'react'
import { CmsResortDetails, ResortFaq } from '@modules/cms/models'
import { useModal } from '@components/modals/use-modal'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAppDispatch } from '@store/index'
import { setCmsResort } from '@store/slices/cms-reservation-app-slice'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'

interface Props {
  faq: ResortFaq
  resort: CmsResortDetails
}

export const CmsReservationAppResortFaqRow: React.FC<Props> = ({ faq, resort }) => {
  const dispatch = useAppDispatch()
  const [handleEdit] = useModal('CmsReservationAppFaqModal', { faq, resort })
  const { addSuccessMessage } = useNotificationHook()

  const { isLoading, action: handleAccept } = useApiRequest(async () => {
    dispatch(setCmsResort(await commonObjectDelete<CmsResortDetails>(faq.urls.details)))
    addSuccessMessage('Sukces', 'Wpis został skasowany')
  })

  return (
    <div className="card mb-1 mr-1 mb-0 shadow-none border">
      <div className="p-1">
        <div className="text-strong mb-1">
          {faq.title}

          <i className="uil-pen ml-1 font-14 cursor-pointer" onClick={handleEdit} />
          <CommonObjectConfirmAction isLoading={isLoading} handleAccept={handleAccept}>
            <i className="uil-multiply ml-1 font-14 cursor-pointer" />
          </CommonObjectConfirmAction>
        </div>
        <div className="text-semi-strong mb-1">{faq.subtitle}</div>
        <div className="text-linebreaksbr">{faq.description}</div>
      </div>
    </div>
  )
}
