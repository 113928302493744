import * as React from 'react'
import { TechnicalOrder } from '@models/technical-orders'
import { useInterval } from 'rooks'
import { secondsToInterval } from '@helpers/date-helper'
import { differenceInSeconds, parseISO } from 'date-fns'

interface Props {
  technicalOrder: TechnicalOrder
}

export const TechnicalOrdersDoneTableRowWorkTimeLive: React.FC<Props> = ({ technicalOrder }) => {
  const [totalTimeStarted, setTotalTimeStarted] = React.useState(
    () =>
      technicalOrder.total_working_time + differenceInSeconds(new Date(), parseISO(technicalOrder.started_at || '')),
  )

  const [startInterval, clearInterval] = useInterval(() => {
    setTotalTimeStarted(time => time + 1)
  }, 1000)

  React.useEffect(() => {
    startInterval()

    return clearInterval()
  }, [])

  return <div>{secondsToInterval(totalTimeStarted || 0)}</div>
}
