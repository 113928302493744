import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { ReservationDataRowsProvider } from '@modules/reservations/details/reservation-data/reservation-data-rows-provider'
import { ReservationDataRow } from '@modules/reservations/details/common/reservation-data-row'
import { ReservationListTableRowStatus } from '@modules/reservations/list/common/status'
import { ReservationPeriodRow } from '@modules/reservations/details/reservation-data/reservation-period-row'
import { ReceptionBookingCheckInPreviewPersonalDataSignature } from '@modules/reception/checkin/preview/personal-data/reception-booking-check-in-preview-personal-data-signature'
import { useModal } from '@components/modals/use-modal'
import { useReportsAppData } from '@modules/reports/hooks/use-reports-app-data'
import { useSellersOptions } from '@components/hooks/use-grouped-sellers'
import { ReservationDataItem } from './reservation-data-item'
import { KindOptions } from '@modules/reservations/blockade/models'
import { formatTime } from '@helpers/date-helper'
import { ReservationType } from '@modules/reservations/details/reservation-data/reservation-type'

interface ReservationDataRowsProps {
  booking: ReceptionBookingDetails
  children?: React.ReactNode
}

export const ReservationBaseDataRows: React.FC<ReservationDataRowsProps> = ({ children, booking }) => {
  const [handleMarketing] = useModal('ReservationMarketingModal', { booking })
  const [showDeclaredArrivalTime] = useModal('ReservationDeclaredArrivalTimeModal', { booking } as any)
  const { sources } = useReportsAppData()
  const sellersList = useSellersOptions()

  const marketingSource = React.useMemo(
    () => sources.find(row => row.id === booking.source_marketing_id)?.name || 'brak',
    [sources, booking.source_marketing_id],
  )

  const seller = React.useMemo(
    () => sellersList.find(row => row.value === booking.seller_id)?.label || 'brak',
    [sellersList, booking.seller_id],
  )

  const kind = KindOptions.find(kindOption => kindOption.value === booking.kind)

  return (
    <ReservationDataRowsProvider
      booking={booking}
      render={({ apartmentData, reservationNumber, createdDate, createdBy, resort, accommodationType }) => (
        <>
          <ReservationDataRow name="Status:">
            <ReservationListTableRowStatus booking={booking} />
          </ReservationDataRow>
          {booking.status === 'blocked' && <ReservationDataRow name="Typ blokady:">{kind?.label}</ReservationDataRow>}
          <ReservationDataRow name="Lokal:">{apartmentData}</ReservationDataRow>
          <ReservationDataRow name="Nr rezerwacji:">{reservationNumber}</ReservationDataRow>
          <ReservationDataRow name="Typ rezerwacji:">
            <ReservationType booking={booking} showCode={false} />
          </ReservationDataRow>
          <ReservationDataRow name="Lokalizacja:">
            <strong>{resort}</strong>
          </ReservationDataRow>
          <ReservationDataRow name="Typ lokalu:">
            <strong>{accommodationType}</strong>
          </ReservationDataRow>
          <ReservationDataRow name="Pobyt:">
            <ReservationPeriodRow booking={booking} />
          </ReservationDataRow>
          {booking.booking_signature_url && (
            <ReservationDataRow name="Podpis gościa:">
              <ReceptionBookingCheckInPreviewPersonalDataSignature
                bookingDetails={booking}
                className="row mx-0"
                signatureClassName="mr-2"
              />
            </ReservationDataRow>
          )}
          <ReservationDataRow name="Deklarowany przyjazd:">
            <ReservationDataItem onChange={showDeclaredArrivalTime}>
              {booking.declared_arrival_time && <strong>{formatTime(booking.declared_arrival_time)}</strong>}
            </ReservationDataItem>
          </ReservationDataRow>
          <ReservationDataRow name="Sprzedawca:">
            <ReservationDataItem onChange={handleMarketing}>
              <strong>{seller}</strong>
            </ReservationDataItem>
          </ReservationDataRow>

          <ReservationDataRow name="Źródło marketingowe:">
            <ReservationDataItem onChange={handleMarketing}>
              <strong>{marketingSource}</strong>
            </ReservationDataItem>
          </ReservationDataRow>
          <ReservationDataRow name="Polecający:">
            <ReservationDataItem onChange={handleMarketing}>
              {booking.recommender_client_display && <strong>{booking.recommender_client_display}</strong>}
            </ReservationDataItem>
          </ReservationDataRow>
          <ReservationDataRow name="Data utworzenia:">{createdDate}</ReservationDataRow>
          <ReservationDataRow name="Utworzona przez:">{createdBy}</ReservationDataRow>
          {children}
        </>
      )}
    />
  )
}
