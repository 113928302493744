import * as React from 'react'
import { BookingRefund } from '@modules/accounting/refund/models'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAppDispatch } from '@store/index'
import { commonObjectPut } from '@store/actions/generic-actions'
import { useApiRequest } from '@components/hooks/use-api-request'
import { updateAccountingBookingRefund } from '@store/slices/accounting-slice'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'

interface Props {
  booking: BookingRefund
}

export const RefundBookingsTableCellActionsMoneyTransferP24: React.FC<Props> = ({ booking }) => {
  const { addSuccessNotification } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading, action: onAccept } = useApiRequest(
    React.useCallback(async () => {
      dispatch(
        updateAccountingBookingRefund(
          await commonObjectPut<BookingRefund>(booking.urls.block, {
            deposit_transfer_status: 'missing_client_data',
          }),
        ),
      )
      addSuccessNotification('Zwrot został oznaczony jako przelew')
    }, [booking]),
  )

  if (booking.deposit_transfer_status !== 'p24_initial') {
    return null
  }
  return (
    <CommonObjectConfirmAction isLoading={isLoading} handleAccept={onAccept}>
      <span className="uil  uil-money-insert text-muted font-16 cursor-pointer mr-1" title="Oznacz jako przelew" />
    </CommonObjectConfirmAction>
  )
}
