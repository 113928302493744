import * as React from 'react'
import { Row } from 'reactstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { SubmitFilter } from '@modules/reception/common/filters/submit-filter'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { FormSelect } from '@hyper/forms/form-select'
import { RentalProductFiltersParams } from '@modules/rental/models'
import { useRentalAppData } from '@modules/rental/use-rental-app-data'
import {
  getRentalCategoriesOptions,
  getRentalWarehousesOptions,
  rentalProductStatusOptions,
} from '@modules/rental/consts'
import { RentalProductTableActions } from '@modules/rental/products/rental-products-table-actions'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'

interface Props {
  filters: RentalProductFiltersParams
  setFilters: (filters: RentalProductFiltersParams) => void
  defaultFilters: RentalProductFiltersParams
}

export const RentalProductsFilters = ({ setFilters, filters, defaultFilters }: Props) => {
  const { warehouses, product_categories } = useRentalAppData()
  const user = useAuthenticatedUser()

  const categoriesOptions = getRentalCategoriesOptions(product_categories)
  const warehouseOptions = getRentalWarehousesOptions(warehouses)

  const methods = useForm<RentalProductFiltersParams>({ defaultValues: filters })

  const handleReset = () => {
    methods.reset()
    setFilters(defaultFilters)
  }

  const onSubmit = async (payload: RentalProductFiltersParams) => setFilters({ ...filters, ...payload })

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="form form-style-filter mb-2" autoComplete="off">
        {user.hasPerm(UserPermission.RentalProductCanManage) && (
          <Row>
            <RentalProductTableActions filters={filters} />
          </Row>
        )}
        <div className="d-flex">
          <FormSelect
            options={rentalProductStatusOptions}
            name="status"
            label="Status"
            formPlainProps={{ colSize: 3, colClassName: 'pl-0' }}
            selectProps={{ isClearable: true }}
          />
          <FormSelect
            options={warehouseOptions}
            name="warehouse"
            label="Magazyn"
            formPlainProps={{ colSize: 3 }}
            selectProps={{ isClearable: true }}
          />
          <FormSelect
            options={categoriesOptions}
            name="category"
            label="Kategoria"
            formPlainProps={{ colSize: 3 }}
            selectProps={{ isClearable: true }}
          />
          <SubmitFilter handleReset={handleReset} />
          <SearchFilter />
        </div>
      </form>
    </FormProvider>
  )
}
