import * as React from 'react'
import { ReceptionBooking, ReceptionBookingsWithAggregations, ReceptionCheckType } from '@models/reception'
import { Button, Col, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { SaveButton } from '@hyper/button'
import { useForm, useWatch } from 'react-hook-form'
import { ReceptionNotificationContent } from '@modules/reception/common/reception-notification-content'
import { ReceptionNotificationParams } from '@modules/reception/notification/reception-notification-params'
import { ReceptionNotificationGuests } from '@modules/reception/notification/reception-notification-guests'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { ReceptionNotificationContentInfo } from './reception-notification-content-info'
import { createCombinedDateWithTime, formatDate } from '@helpers/date-helper'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApiRequest, useFormRequest } from '@components/hooks/use-api-request'
import { BaseModalProps } from '@components/modals/types'
import { ACCOMMODATION_TYPES } from '@helpers/consts'
import { Form } from '@hyper/forms/form'
import { commonObjectGet, commonObjectPost } from '@store/actions/generic-actions'
import { useAppData } from '@components/hooks/use-app-data'
import { createSelectOption, extractSelectOptionsValues } from '@helpers/utils'
import { CustomReactSelectOption, makeDefaultSelectOption } from '@components/custom-react-select'
import { FormSelect } from '@hyper/forms/form-select'
import { useSmsTemplates } from '@components/hooks/use-sms-templates'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { FormPlain } from '@hyper/forms'
import { FormCheckbox } from '@hyper/forms/form-checkbox'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { isBefore } from 'date-fns'

export type NotificationGuestFilter = 'all' | 'houses' | 'apartments'

const CheckTypeOptions = [
  createSelectOption('Przyjazd', ReceptionCheckType.TO_CHECKIN),
  createSelectOption('Zameldowani', ReceptionCheckType.COMPLETE_CHECKIN),
  createSelectOption('Wyjazd', ReceptionCheckType.TO_CHECKOUT),
]

interface ReceptionNotificationDialogProps extends BaseModalProps {
  check_type: ReceptionCheckType
  resortId: number
  date: Date
}

export interface ReceptionNotificationDialogFormInputs {
  content: string
  template: CustomReactSelectOption | null
  check_type: CustomReactSelectOption | null
  date: Date
  filter: NotificationGuestFilter | null
  bookings: string[]
  feeding: CustomReactSelectOption
}

export const ReceptionNotificationDialog: React.FC<ReceptionNotificationDialogProps> = ({
  resortId,
  check_type,
  date,
  toggleIsVisible,
}) => {
  const [bookings, setBookings] = React.useState<ReceptionBooking[]>([])

  const { addSuccessMessage } = useNotificationHook()
  const user = useAuthenticatedUser()

  const appData = useAppData()

  const methods = useForm<ReceptionNotificationDialogFormInputs>({
    defaultValues: {
      check_type: makeDefaultSelectOption(CheckTypeOptions, check_type),
      date,
      content: '',
      template: null,
      bookings: [],
      filter: null,
      feeding: createSelectOption('Wszystkie', 'all'),
    },
    mode: 'all',
  })

  const availableBookings = React.useMemo(
    () =>
      bookings.filter(booking =>
        isBefore(new Date(), createCombinedDateWithTime(booking.date_to, booking.departure_time)),
      ),
    [bookings],
  )

  const { bookings: selectedBookings, date: selectedDate, check_type: selectedCheckType, content } = methods.watch()

  const { filter, feeding } = useWatch({ control: methods.control })

  const { templateOptions } = useSmsTemplates({ setValue: methods.setValue, control: methods.control })

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: ReceptionNotificationDialogFormInputs) => {
    await commonObjectPost(appData.urls.reception.mass_sms_notification, {
      ...extractSelectOptionsValues(payload),
      template: payload.template || undefined,
    })
    addSuccessMessage('Wiadomość SMS została wysłana', 'Wiadomość SMS do gości została poprawnie wysłana')
    toggleIsVisible()
  }, methods.setError)

  React.useEffect(() => {
    if (!filter) {
      methods.setValue('bookings', [])
      return
    }

    const guestsWithPhone = availableBookings?.filter(booking => booking.phone && booking.sms_notifications_enabled)
    const getIds = (booking: ReceptionBooking) => String(booking.id)

    const availableFilters = {
      all: guestsWithPhone,
      houses: guestsWithPhone.filter(booking =>
        ACCOMMODATION_TYPES.HOUSES.includes(booking.apartment.accommodation_type),
      ),
      apartments: guestsWithPhone.filter(booking =>
        ACCOMMODATION_TYPES.APARTMENTS.includes(booking.apartment.accommodation_type),
      ),
    }

    methods.setValue('bookings', availableFilters[filter].map(getIds))
  }, [filter])

  const { isLoading: isBookingsLoading, action: fetchBookings } = useApiRequest(async () => {
    const { bookings } = await commonObjectGet<ReceptionBookingsWithAggregations>(appData.urls.reception.bookings, {
      resort: resortId,
      booking_date: formatDate(selectedDate),
      check_status: selectedCheckType?.value,
      feeding: feeding?.value,
    })
    setBookings(bookings)
  })

  React.useEffect(() => {
    if (methods.formState.errors.bookings && selectedBookings.filter(booking => booking).length) {
      methods.clearErrors('bookings')
    }
  }, [methods.formState.errors.bookings, selectedBookings])

  useDidUpdateEffect(() => {
    fetchBookings()
  }, [selectedCheckType?.value, selectedDate, feeding?.value])

  return (
    <Form methods={methods} onSubmit={onSubmit} className="reception__notification_sms_dialog">
      <ModalHeader toggle={toggleIsVisible}>Wyślij powiadomienie SMS do gości</ModalHeader>
      <ModalBody className="modal-border-bottom">
        <ReceptionNotificationParams checkTypeOptions={CheckTypeOptions} />
        <LoaderPlaceholder content={!isBookingsLoading} hideText={true}>
          <ReceptionNotificationGuests bookings={availableBookings} />
        </LoaderPlaceholder>
      </ModalBody>
      <ModalBody className="modal-border-bottom">
        <h5 className="modal-title">Treść powiadomienia SMS</h5>
      </ModalBody>
      <ModalBody className="bg-grey">
        <ReceptionNotificationContent
          className="reception__notification_sms_dialog__content"
          content={content}
          info={<ReceptionNotificationContentInfo />}
        />
      </ModalBody>
      <ModalFooter className="d-block">
        <Row>
          <FormSelect
            options={templateOptions}
            name="template"
            selectProps={{ placeholder: 'Wybierz szablon', noOptionsMessage: () => 'Brak dostępnych szablonów' }}
            formPlainProps={{ colSize: 12, colClassName: 'pl-0' }}
          />
          {user.hasPerm(UserPermission.ReceptionMassSmsCanIgnoreContentLimit) && (
            <FormPlain name="ignore_content_limit" colSize={5} colClassName="pl-0 mt-1">
              <FormCheckbox
                name="ignore_content_limit"
                label="Pomiń ograniczenie ilości znaków"
                id="ignore_content_limit"
              />
            </FormPlain>
          )}

          <Col md={7} className="text-right pr-0">
            <Button color="light" onClick={toggleIsVisible}>
              Anuluj
            </Button>

            <SaveButton
              label="Wyślij SMS"
              disabled={!availableBookings.length}
              role="notification-submit"
              className="btn btn-green ml-1"
              labelSaving="Wysyłanie..."
              isSaving={isLoading}
            />
          </Col>
        </Row>
      </ModalFooter>
    </Form>
  )
}
