import * as React from 'react'
import { CleaningOrder } from '@modules/housekeeping/models'
import { UncontrolledTooltip } from 'reactstrap'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  cleaningOrder: CleaningOrder
  showPriority?: boolean
  showCheckedIn?: boolean
}

export const HousekeepingCleaningOrderApartment: React.FC<Props> = ({ showCheckedIn, cleaningOrder, showPriority }) => {
  const tooltipId = `tooltipLastCleaning${cleaningOrder.id}`

  return (
    <td className="housekeeping__orders_table__column text-semi-strong" width={200}>
      {cleaningOrder.apartment.name}
      <div>
        <i className="uil-info-circle font-15" id={tooltipId} />
        {showPriority && cleaningOrder.priority > 0 && (
          <IconWithText
            text="Pilne"
            icon="uil-rocket"
            iconSize="font-16 text-danger"
            wrapperClassNames="text-danger text-semi-strong ml-1 lh-0"
          />
        )}
        <UncontrolledTooltip placement="top" target={tooltipId}>
          <div className="font-11 text-left">
            <div className="mb-1 fw-semi-bold">Ostatnie sprzątanie:</div>
            {toDefaultDateTimeFormat(cleaningOrder.apartment.housekeeping_last_cleaning)}
          </div>
        </UncontrolledTooltip>
        {cleaningOrder.is_reopened && (
          <IconWithText icon="uil-redo" text="Cofnięte" wrapperClassNames="text-danger ml-1" />
        )}
      </div>
      {showCheckedIn && !!cleaningOrder.checked_in_booking && (
        <IconWithText
          icon="uil-chat-bubble-user font-16"
          text="Goście w środku"
          wrapperClassNames="d-block text-dark"
        />
      )}
    </td>
  )
}
