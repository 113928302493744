import * as React from 'react'
import { FormSelect } from '@hyper/forms/form-select'
import { useTechnicalOrderTypesSelectOptions } from '@modules/technical-orders/hooks/use-technical-order-types-select-options'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { TableFilters } from '@components/table/table'

interface Props {
  setFilters: React.Dispatch<React.SetStateAction<TableFilters>>
}

export const TechnicalOrderTableTypeHeader: React.FC<Props> = ({ setFilters }) => {
  const technicalOrderTypesOptions = useTechnicalOrderTypesSelectOptions()

  const handleChange = (option: CustomReactSelectOption) => {
    setFilters(filters => ({ ...filters, technical_order_type: option?.value ?? undefined }))
  }

  return (
    <FormSelect
      options={technicalOrderTypesOptions}
      name="technical_order_type"
      selectProps={{ isClearable: true, placeholder: 'Usterka', onChange: handleChange }}
      formPlainProps={{ colClassName: 'p-0 m-0', formGroupClassName: 'p-0 m-0 text-normal' }}
    />
  )
}
