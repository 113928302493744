import * as React from 'react'
import { FormPlain } from '@hyper/forms'
import DatePickerInput from '@components/date/date-picker-input'
import { FeedingHostFiltersButtons } from '@modules/feeding/host/feeding-host-filters-buttons'
import { FeedingHostFiltersApartments } from '@modules/feeding/host/feeding-host-filters-apartments'
import { FormSelect } from '@hyper/forms/form-select'
import { BaseFeedingOptions } from '@modules/feeding/consts'

export const FeedingHostFilters: React.FC = () => (
  <div className="bg-white-smoke p-3 border">
    <div className="d-flex align-items-start">
      <FormPlain colSize="col-auto" name="date" colStyle={{ display: 'contents' }}>
        <DatePickerInput name="date" wrapperClassName="flex-grow-1" />
      </FormPlain>
      <FeedingHostFiltersApartments />
      <FormSelect options={BaseFeedingOptions} name="type" formPlainProps={{ colSize: 'col-auto' }} />
      <FeedingHostFiltersButtons />
    </div>
  </div>
)
