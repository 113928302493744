import * as React from 'react'
import { FormInput } from '@hyper/forms'
import { FormSelect } from '@hyper/forms/form-select'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { CustomReactSelectOption } from '@components/custom-react-select'

interface Props {
  scopeOptions: CustomReactSelectOption[]
}

export const ServiceBandUpdateForm: React.FC<Props> = ({ scopeOptions }) => (
  <>
    <FormInput label="Limit środków" type="number" name="initial_funds" colSize={6} />
    <FormSelect
      label="Kategorie"
      options={scopeOptions}
      name="scopes"
      selectProps={{ isClearable: true, isSelectMulti: true }}
      formPlainProps={{ colSize: 6 }}
    />
    <DateFromToInput label="Data ważności" startDateName="date_from" endDateName="date_to" colSize={12} />
  </>
)
