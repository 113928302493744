import { AppDispatch, RootState } from '@store/index'
import { ReportAggregation, ReportTask } from '@models/reports'
import { setDashboardStandardPaginator } from '@store/actions/dashboard-actions'
import { commonObjectGet, commonObjectPost } from '@store/actions/generic-actions'
import { PaginationResponseWithAggregation } from '@models/dashboard'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ReportListFilterParams } from '@modules/reports/user-reports'

export const getUserReportTasks = createAsyncThunk<
  PaginationResponseWithAggregation<ReportTask, ReportAggregation>,
  Partial<ReportListFilterParams>,
  { dispatch: AppDispatch; state: RootState }
>('reports/getUserReportTasks', async (params, { dispatch, getState }) => {
  const url = getState().appState.appData.urls.reports.user_reports

  const data = await commonObjectGet<PaginationResponseWithAggregation<ReportTask, ReportAggregation>>(url, params)

  dispatch(setDashboardStandardPaginator(data))
  return data
})

export const createReportTask = createAsyncThunk<ReportTask, [string, any]>(
  'reports/createReportTask',
  async ([url, payload], { rejectWithValue }) => {
    try {
      return await commonObjectPost<ReportTask>(url, payload)
    } catch (error) {
      if (error.response) return rejectWithValue({ error, context: { url, payload } })
      throw error
    }
  },
)
