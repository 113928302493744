import { CleaningOrderStatus, ImprovementOrderStatus } from '@modules/housekeeping/models'

export const getCleaningOrderStatusColor = (status: CleaningOrderStatus) => {
  switch (status) {
    case 'CLEANING':
    case 'REOPENED':
      return 'warning-lighten'
    case 'ACCEPTED':
    case 'FINISHED':
    case 'ARCHIVED':
      return 'success-lighten'
    case 'PENDING':
      return 'danger-lighten'
    default:
      return 'info-lighten'
  }
}
export const getImprovementOrderStatusColor = (status: ImprovementOrderStatus) => {
  switch (status) {
    case 'IN_PROGRESS':
      return 'warning-lighten'
    case 'FINISHED':
    case 'ARCHIVED':
      return 'success-lighten'
    case 'NEW':
    case 'PENDING':
      return 'danger-lighten'
    default:
      return 'info-lighten'
  }
}
