import * as React from 'react'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap'
import { PackageCode, PackageDetails } from '@models/package'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useAppDispatch } from '@store/index'
import { commonObjectPut } from '@store/actions/generic-actions'
import { updatePackageDetails } from '@store/slices/package-slice'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'

interface Props {
  selectedRows: PackageCode[]
  clearSelectedRows: () => void
  packageDetails: PackageDetails
}

export const PackageDetailsCodesOptions = ({ selectedRows, clearSelectedRows, packageDetails }: Props): JSX.Element => {
  const { addSuccessMessage } = useNotificationHook()

  const dispatch = useAppDispatch()

  const { isLoading, action: onAccept } = useApiRequest(async () => {
    dispatch(
      updatePackageDetails(
        await commonObjectPut<PackageDetails>(packageDetails.urls.remove_codes, {
          codes: selectedRows.map(row => row.id),
        }),
      ),
    )

    addSuccessMessage('Wybrane kody zostały skasowane')
    clearSelectedRows()
  })

  return (
    <UncontrolledButtonDropdown disabled={!selectedRows.length}>
      <DropdownToggle color="light" caret={true} disabled={!selectedRows.length}>
        <strong>zaznaczone ({selectedRows.length})</strong>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem>
          <CommonObjectConfirmAction
            title="Potwierdź operacje"
            isLoading={isLoading}
            handleAccept={onAccept}
            message="Czy na pewno chcesz wykonać wybraną akcję? Operacja ta jest nieodwołalna"
          >
            <i className="uil uil-trash-alt mr-1 text-danger text-bold" />
            Skasuj
          </CommonObjectConfirmAction>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  )
}
