import * as React from 'react'
import { ReceptionBookingDetails, ReceptionReminder } from '@models/reception'
import ModalBody from 'reactstrap/lib/ModalBody'
import { newUserPredicator, ReceptionCheckReminders } from '@modules/reception/common/reception-check-reminders'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { Col, CustomInput, Row } from 'reactstrap'
import { ReceptionBookingCheck } from '../../common/reception-booking-check'
import { ReceptionBookingCheckInStepDocumentsMeters } from '@modules/reception/checkin/step-documents/step-documents-meters'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { FormPlain } from '@hyper/forms'
import { ClientRule } from '@models/clients'
import { findClientRule, useClientRules } from '@components/hooks/use-client-rules'
import { ContentLoader } from '@components/content-loader'
import { extractInnerRef } from '@helpers/forms'
import { ReceptionBookingCheckInStepDocumentsKind } from './step-documents-kind'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectPost } from '@store/actions/generic-actions'
import { BookingApartmentMeters } from '@models/booking'

const reminders: ReceptionReminder[] = [
  {
    id: 'check_document_language',
    isClosable: true,
    children: (
      <>
        Zwróć uwagę, aby dokumenty <strong>drukować w języku zrozumiałym dla Gościa.</strong>
      </>
    ),
  },
  {
    id: 'check_meters',
    isClosable: true,
    children: (
      <>
        <strong>Poproś Gościa,</strong> aby zwrócił uwagę <strong>czy liczniki działają poprawnie.</strong>
      </>
    ),
  },
]

interface ReceptionBookingCheckInStepDocumentsProps {
  nextStep: () => void
  previousStep: () => void
  booking: ReceptionBookingDetails
}

export interface ReceptionBookingCheckInStepDocumentsFormInputs {
  check_document_language: boolean
  check_meters: boolean
  language: 'pl'
  water_start: string
  hot_water_start: string
  heat_start: string
  power_start: string
  document_kind: 'print' | 'signature'
  email_rules: boolean
  sms_rules: boolean
  phone_call_rules: boolean
}

export const ReceptionBookingCheckInStepDocuments: React.FC<ReceptionBookingCheckInStepDocumentsProps> = ({
  booking,
  previousStep,
  nextStep,
}) => {
  const user = useAuthenticatedUser()
  const [meters, setMeters] = React.useState<BookingApartmentMeters | undefined>(undefined)

  const [alreadyCheckedRules, setAlreadyCheckedRules] = React.useState({
    sms_rules: false,
    email_rules: false,
    phone_call_rules: false,
  })

  const methods = useForm<ReceptionBookingCheckInStepDocumentsFormInputs>({
    shouldUnregister: false,
    defaultValues: {
      language: 'pl',
      check_meters: newUserPredicator(user),
      check_document_language: newUserPredicator(user),
      email_rules: false,
      sms_rules: false,
      phone_call_rules: false,
      document_kind: 'signature',
    },
  })

  const { fetchClientRules, updateClientRules, isFetchingClientRules } = useClientRules(booking.urls.client_rules)

  const onSubmit = async () => {
    const [email_rules, sms_rules, phone_call_rules] = methods.getValues([
      'email_rules',
      'sms_rules',
      'phone_call_rules',
    ])
    await updateRules({ email_rules, sms_rules, phone_call_rules })
    nextStep()
  }

  const fetchRules = async () => {
    const { rules } = await fetchClientRules()
    const formRules = createFormRules(rules)
    setAlreadyCheckedRules(formRules)
    Object.entries(formRules).forEach(([key, value]) => {
      methods.setValue(key as keyof ReceptionBookingCheckInStepDocumentsFormInputs, value)
    })
  }

  const updateRules = async rule => {
    const { rules } = await updateClientRules(rule)
    Object.entries(createFormRules(rules)).forEach(([key, value]) => {
      methods.setValue(key as keyof ReceptionBookingCheckInStepDocumentsFormInputs, value)
    })
  }

  const createFormRules = (rules: ClientRule[]) => ({
    sms_rules: !!findClientRule(rules, 'sms_rules')?.accepted,
    email_rules: !!findClientRule(rules, 'email_rules')?.accepted,
    phone_call_rules: !!findClientRule(rules, 'phone_call_rules')?.accepted,
  })

  const { action: fetchMeters, isLoading: isFetchingMeters } = useApiRequest(async () => {
    setMeters(await commonObjectPost<BookingApartmentMeters>(booking.urls.meters))
  })

  React.useEffect(() => {
    fetchRules()
    fetchMeters()
  }, [])

  const isDocumentSignActive = useWatch({ control: methods.control, name: 'document_kind' }) === 'signature'

  return (
    <ContentLoader isLoading={isFetchingClientRules || isFetchingMeters}>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="reception__booking-check-in__documents-step"
          role="step-documents"
        >
          <ModalBody>
            <h4>3. Wydrukuj niezbędne dokumenty</h4>
            <ReceptionCheckReminders reminders={reminders} required={!newUserPredicator(user)} />
            <Row className="pt-2">
              <Col md={7}>
                {meters && (
                  <ReceptionBookingCheckInStepDocumentsMeters
                    booking={booking}
                    meters={meters}
                    onMetersRefresh={fetchMeters}
                  />
                )}
              </Col>
              <Col md={5}>
                <ReceptionBookingCheckInStepDocumentsKind user={user} booking={booking} onRulesFetch={fetchRules} />
                <Row className="d-flex mt-2">
                  <strong className="col-12 mb-2">Rezerwujący wyraził zgodę RODO:</strong>
                  <FormPlain name="email_rules" colSize="col-5 pl-2 mr-3">
                    <CustomInput
                      disabled={alreadyCheckedRules.email_rules || isDocumentSignActive}
                      type="checkbox"
                      id="email_rules"
                      label={<span className="text-semi-strong cursor-pointer">E-mail</span>}
                      {...extractInnerRef(methods.register('email_rules'))}
                    />
                  </FormPlain>
                  <FormPlain name="sms_rules" colSize="col-5 mr-3">
                    <CustomInput
                      disabled={alreadyCheckedRules.sms_rules || isDocumentSignActive}
                      type="checkbox"
                      id="sms_rules"
                      label={<span className="text-semi-strong cursor-pointer">SMS</span>}
                      {...extractInnerRef(methods.register('sms_rules'))}
                    />
                  </FormPlain>
                  <FormPlain name="sms_rules" colSize="col-5">
                    <CustomInput
                      disabled={alreadyCheckedRules.phone_call_rules || isDocumentSignActive}
                      type="checkbox"
                      id="phone_call_rules"
                      label={<span className="text-semi-strong cursor-pointer">Telefon</span>}
                      {...extractInnerRef(methods.register('phone_call_rules'))}
                    />
                  </FormPlain>
                </Row>
              </Col>
            </Row>
          </ModalBody>
          <ReceptionBookingCheck nextStep={nextStep} previousStep={previousStep} />
        </form>
      </FormProvider>
    </ContentLoader>
  )
}
