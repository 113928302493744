import * as React from 'react'
import { GastroCardDetails, GastroWalletItem } from '@models/promotions'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'
import { GastroCardWalletItemsRemove } from '@modules/promotions/gastro-cards/gastro-card-details/gastro-card-wallet-items/gastro-card-wallet-items-remove'

interface GastroCardWalletItemsOptionsProps {
  selectedRows: GastroWalletItem[]
  clearSelectedRows: () => void
  gastroCardDetails: GastroCardDetails
}

export const GastroCardWalletItemsOptions: React.FC<GastroCardWalletItemsOptionsProps> = ({
  selectedRows,
  clearSelectedRows,
  gastroCardDetails,
}) => {
  const isDisabled = !selectedRows.length
  const handleMoveClose = () => clearSelectedRows()

  const [handleMove] = useModal('GastroCardWalletItemsMove', {
    handleMoveClose,
    gastroCardDetails,
    items: selectedRows,
  })

  const handleClose = () => {
    clearSelectedRows()
  }

  return (
    <div className="mb-1">
      <UncontrolledButtonDropdown disabled={isDisabled}>
        <DropdownToggle color="light" caret={true} disabled={isDisabled}>
          <strong>zaznaczone ({selectedRows.length})</strong>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={handleMove}>
            <i className="uil uil-truck mr-1 text-danger text-bold" />
            Przenieś
          </DropdownItem>
          <DropdownItem>
            <GastroCardWalletItemsRemove
              gastroCardDetails={gastroCardDetails}
              handleClose={handleClose}
              items={selectedRows}
            >
              <i className="uil uil-trash-alt mr-1 text-danger text-bold" />
              Skasuj
            </GastroCardWalletItemsRemove>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </div>
  )
}
