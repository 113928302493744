import * as React from 'react'
import { format, parseISO } from 'date-fns'
import { SaveButton } from '@hyper/button'
import { useForm } from 'react-hook-form'
import { useFormRequest } from '@components/hooks/use-api-request'
import { commonObjectPost, commonObjectPut } from '@store/actions/generic-actions'
import { RcpEvent, RcpEventDay, RcpMachine } from '@models/rcp'
import { useAppDispatch, useAppSelector } from '@store/index'
import { rcpUserEventsDaysFilter, updateUserEventDayDetails } from '@store/slices/rcp-slice'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { FormInputTime } from '@hyper/forms/form-input-time'

interface EventRowSingleEventEditProps {
  onCancel: () => void
  date: string
  event: RcpEvent
  machines: RcpMachine[]
  hasError: boolean
}

interface FormInputs {
  check_time: string
  check_place: number
}

export const EventRowSingleEventEdit: React.FC<EventRowSingleEventEditProps> = ({
  event,
  machines,
  date,
  hasError,
  onCancel,
}) => {
  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()
  const filters = useAppSelector(rcpUserEventsDaysFilter)
  const {
    register,
    control,
    getValues,
    setError,
    formState: { errors },
  } = useForm<FormInputs>({
    defaultValues: {
      check_time: event.check_time ? format(parseISO(event.check_time), 'HH:mm') : '',
      check_place: event.machine_id,
    },
  })

  const { isLoading, action: handleSave } = useFormRequest(async () => {
    if (event.is_fake) {
      dispatch(
        updateUserEventDayDetails(
          await commonObjectPost<RcpEventDay>(
            event.urls.create,
            { ...getValues(), date, check_type: event.check_type },
            undefined,
            filters,
          ),
        ),
      )
    } else {
      dispatch(
        updateUserEventDayDetails(
          await commonObjectPut<RcpEventDay>(event.urls.update, getValues(), undefined, filters),
        ),
      )
    }
    addSuccessNotification('Zmiany zostały zapisane')
    onCancel()
  }, setError)

  return (
    <tr className={`rcp__events-table__edit-row rcp__events-table__tr-primary-middle ${hasError ? 'has-errors' : ''}`}>
      <td>{event.get_check_type_display}</td>
      {!event.is_start && <td />}
      <td>
        <FormInputTime name="check_time" colStyle={{ maxWidth: 90 }} colClassName="my-0 px-0" control={control} />
        {errors.check_time && <div className="invalid-feedback d-block">{errors.check_time?.message}</div>}
      </td>
      {event.is_start && (
        <td>
          <span className="action-icon">
            {' '}
            <i className="uil-corner-right-down text-dark" />
          </span>
        </td>
      )}
      <td>Miejsce:</td>
      {!event.is_start && <td />}
      <td>
        <select style={{ maxWidth: 200 }} {...register('check_place')} className="form-control">
          {machines.map(machine => (
            <option key={machine.id} value={machine.id}>
              {' '}
              {machine.name}{' '}
            </option>
          ))}
        </select>
        {errors.check_place && <div className="invalid-feedback d-block">{errors.check_place?.message}</div>}
      </td>
      {event.is_start && (
        <td>
          <span className="action-icon">
            {' '}
            <i className=" uil-corner-right-down text-dark" />
          </span>
        </td>
      )}
      <td>
        <SaveButton className="btn btn-sm btn-green" onClick={handleSave} isSaving={isLoading} />{' '}
        <button className="btn btn-sm btn-light" onClick={onCancel}>
          Anuluj
        </button>
      </td>
      <td />
    </tr>
  )
}
