import * as React from 'react'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import {
  BookingStatsDetails,
  ReservationStatsTableCells,
  StatsCellContent,
  StatsTableOptions,
} from '@modules/reservations/models'
import { ReservationStatsReportCell } from '@modules/reservations/statistics/booking-stats/stats-details/stats-details-table/reservation-stats-report-cell'
import { ReservationStatsTableExpandedRow } from '@modules/reservations/statistics/booking-stats/stats-details/stats-details-table/reservation-stats-table-expanded-row'
import { useAppData } from '@components/hooks/use-app-data'
import { useStatsReportGenerator } from '@modules/reservations/statistics/booking-stats/stats-details/stats-details-table/use-stats-report-generator'
import { ReservationStatsFormInputs } from '@modules/reservations/statistics/common/common-stats-box-details'

interface Props {
  getCells: (element: string, options?: StatsTableOptions<BookingStatsDetails>) => ReservationStatsTableCells
}

export const ReservationStatsAccommodationParkingVipRow = ({ getCells }: Props): JSX.Element => {
  const user = useAuthenticatedUser()
  const { urls } = useAppData()

  const { generateReport } = useStatsReportGenerator()

  const handleReportGenerate = async (content: StatsCellContent<BookingStatsDetails>) => {
    await generateReport(
      content,
      urls.reports.report_task_booking_parking_vip,
      (formValues: ReservationStatsFormInputs) => ({
        date: content.statsDetails.date,
        ...(formValues.resort?.value && { resort: formValues.resort.value }),
        ...(formValues.accommodation_type?.value && { accommodation: formValues.accommodation_type.value }),
      }),
    )
  }

  const getReportDataCells = field => {
    if (!user.hasPerm(UserPermission.ReportsBookingParkingVip)) return getCells(field)

    return getCells(field, {
      tableCellOption: {
        wrapper: content => (
          <ReservationStatsReportCell
            content={content}
            onClick={() => handleReportGenerate(content)}
            color="text-default"
          />
        ),
      },
    })
  }

  return (
    <ReservationStatsTableExpandedRow
      title="Parking gwarantowany - miejsca sprzedane"
      cells={getReportDataCells('bought_parking_vip_spots')}
    />
  )
}
