import * as React from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Button, Col, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import ModalBody from 'reactstrap/lib/ModalBody'
import { useAppDispatch, useAppSelector } from '@store/index'
import { getReceptionBookingDetails } from '@store/actions/reception-actions'
import { ReceptionNotificationContent } from '@modules/reception/common/reception-notification-content'
import { ReceptionNotificationContentInfo } from '@modules/reception/notification/reception-notification-content-info'
import { ReceptionBooking, ReceptionBookingDetails } from '@models/reception'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useFormRequest } from '@components/hooks/use-api-request'
import { receptionBookingDetailsSelector } from '@store/selectors/reception'
import { BaseModalProps } from '@components/modals/types'
import { commonObjectPost } from '@store/actions/generic-actions'
import { Form } from '@hyper/forms/form'
import { FormSelect } from '@hyper/forms/form-select'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useSmsTemplates } from '@components/hooks/use-sms-templates'

interface ReceptionNotificationSmsDialogProps extends BaseModalProps {
  booking: ReceptionBooking
  smsSentSuccessfully?: (updatedBookingData: ReceptionBookingDetails) => void
}

interface FormInputs {
  content: string
  template: CustomReactSelectOption | null
}

export const ReceptionNotificationSmsDialog: React.FC<ReceptionNotificationSmsDialogProps> = ({
  booking,
  smsSentSuccessfully = null,
  toggleIsVisible,
}) => {
  const dispatch = useAppDispatch()
  const methods = useForm<FormInputs>({
    defaultValues: {
      content: '',
      template: null,
    },
  })
  const { addSuccessMessage } = useNotificationHook()
  const { templateOptions } = useSmsTemplates({ setValue: methods.setValue, control: methods.control })

  const bookingDetails = useAppSelector(receptionBookingDetailsSelector)

  const [content] = useWatch({ control: methods.control, name: ['content', 'template'] })

  React.useEffect(() => {
    dispatch(getReceptionBookingDetails(booking.urls.api_details))
  }, [])

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    const updatedBookingData = await commonObjectPost<ReceptionBookingDetails>(bookingDetails?.urls.notification_sms, {
      content: payload.content,
    })
    toggleIsVisible()
    addSuccessMessage('Wiadomość SMS została wysłana', `Wiadomość SMS do ${booking.name} została poprawnie wysłana`)
    smsSentSuccessfully?.(updatedBookingData)
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit} className="reception__notification_sms_dialog">
      <ModalHeader toggle={toggleIsVisible}>
        Wiadomość SMS do {booking.name} ({booking.phone})
      </ModalHeader>
      <LoaderPlaceholder content={bookingDetails}>
        <ModalBody className="bg-grey">
          <ReceptionNotificationContent
            className="reception__notification_sms_dialog__content"
            content={content}
            info={<ReceptionNotificationContentInfo />}
          />
        </ModalBody>
        <ModalFooter style={{ display: 'block' }}>
          <Row>
            <FormSelect
              options={templateOptions}
              name="template"
              selectProps={{ placeholder: 'Wybierz szablon', noOptionsMessage: () => 'Brak dostępnych szablonów' }}
              formPlainProps={{ colSize: 5, colClassName: 'pl-0', formGroupClassName: 'mb-0' }}
            />

            <Col md={7} className="text-right pr-0">
              <Button color="light" onClick={toggleIsVisible}>
                Anuluj
              </Button>

              <SaveButton
                label="Wyślij SMS"
                role="notification-submit"
                className="btn btn-green ml-1"
                labelSaving="Wysyłanie..."
                isSaving={isLoading}
              />
            </Col>
          </Row>
        </ModalFooter>
      </LoaderPlaceholder>
    </Form>
  )
}
