import { useApiRequest } from '@components/hooks/use-api-request'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { formatPrice } from '@helpers/utils'
import { CashRegisterState } from '@models/sale'
import { commonObjectGet } from '@store/actions/generic-actions'
import { RootState, useAppSelector } from '@store/index'
import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'

export const CashRegisterStateListModal: React.FC<BaseModalProps> = ({ toggleIsVisible }) => {
  const url = useAppSelector((state: RootState) => state.appState.appData.urls.sale.cash_box_status)
  const [cashRegisterStateList, setCashRegisterStateList] = React.useState<CashRegisterState[]>([])

  const { isLoading, action: fetchCashRegisterState } = useApiRequest(async () =>
    setCashRegisterStateList(await commonObjectGet(url)),
  )

  React.useEffect(() => {
    fetchCashRegisterState()
  }, [])

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>Stan kas</ModalHeader>
      <ModalBody>
        <LoaderPlaceholder content={!isLoading}>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Lp.</th>
                <th>Ośrodek</th>
                <th>Stan</th>
              </tr>
            </thead>
            <tbody>
              {cashRegisterStateList.map(({ resort, value }, index) => (
                <tr key={resort}>
                  <td>{index + 1}</td>
                  <td>{resort}</td>
                  <td>{value === null ? 'błąd odczytu' : formatPrice(value)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </LoaderPlaceholder>
      </ModalBody>
      <ModalFooter>
        <Button type="button" color="light" onClick={toggleIsVisible}>
          Zamknij
        </Button>
      </ModalFooter>
    </>
  )
}
