import * as React from 'react'
import { Row } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { SubmitFilter } from '@modules/reception/common/filters/submit-filter'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { FormInput } from '@hyper/forms'
import { TableFilters } from '@components/table/table'
import { ColAuto } from '@hyper/col-auto'
import { Form } from '@hyper/forms/form'

export interface CmsReservationAppImprovementsFiltersParams extends TableFilters {
  resort: string
  is_feeding: string
}

interface Props {
  filters: CmsReservationAppImprovementsFiltersParams
  setFilters: (filters: CmsReservationAppImprovementsFiltersParams) => void
  defaultFilters: CmsReservationAppImprovementsFiltersParams
}

export const CmsReservationAppImprovementsFilters: React.FC<Props> = ({ setFilters, filters, defaultFilters }) => {
  const user = useAuthenticatedUser()

  const methods = useForm<CmsReservationAppImprovementsFiltersParams>({
    defaultValues: filters,
  })

  const handleReset = () => {
    methods.reset()
    setFilters(defaultFilters)
  }

  const onSubmit = async (payload: CmsReservationAppImprovementsFiltersParams) => setFilters({ ...filters, ...payload })

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  return (
    <Form methods={methods} onSubmit={onSubmit} className="form form-style-filter mb-2">
      <Row>
        <ColAuto>
          <FormInput label="Ośrodek:" type="select" name="resort">
            <option value="">Wszystkie</option>
            {user.resorts.map(resort => (
              <option value={resort.id} key={resort.id}>
                {resort.name}
              </option>
            ))}
          </FormInput>
        </ColAuto>
        <ColAuto>
          <FormInput label="Wyżywienie:" type="select" name="is_feeding">
            <option value="">Wszystkie</option>
            <option value="true">tak</option>
            <option value="false">nie</option>
          </FormInput>
        </ColAuto>
        <SubmitFilter handleReset={handleReset} />
        <SearchFilter colClassName="col-md-2" />
      </Row>
    </Form>
  )
}
