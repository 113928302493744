import * as React from 'react'
import { ContentNote } from '@models/dashboard'
import { ContentNotesCardContentMessage } from '@components/content-notes/content-notes-card-content-message'
import { ContentNotesCardContentEdit } from '@components/content-notes/content-notes-card-content-edit'
import {
  ContentNoteCardState,
  ContentNotesCardContentMenu,
} from '@components/content-notes/content-notes-card-content-menu'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { useHoverMenu } from '@components/hooks/use-hover-menu'

interface Props {
  note: ContentNote
  onNoteChange?: (note: ContentNote) => void
  className?: string
  readonly: boolean
  onNoteToEditChange?: (note: ContentNote) => void
}

export const ContentNotesCardContent = ({ note, onNoteChange, className, readonly }: Props): JSX.Element => {
  const [contentCardState, setContentCardState] = React.useState<ContentNoteCardState>('default')

  const { handleMouseLeave, handleMouseEnter, isMenuVisible, reset } = useHoverMenu(readonly)

  const setDefaultContentCardState = () => {
    setContentCardState('default')
  }

  const handleNoteChange = (note: ContentNote) => {
    onNoteChange?.(note)
    setDefaultContentCardState()
  }

  useDidUpdateEffect(() => reset(), [contentCardState])

  const handleUpdate = (note: ContentNote) => {
    reset()
    onNoteChange?.(note)
  }

  return (
    <>
      {contentCardState === 'default' && (
        <div
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className="position-relative overflow-hidden"
        >
          <ContentNotesCardContentMessage note={note} className={className} />
          <ContentNotesCardContentMenu
            onNoteChange={handleUpdate}
            note={note}
            isVisible={isMenuVisible}
            onContentNoteCardStateChange={setContentCardState}
          />
        </div>
      )}
      {contentCardState === 'edit' && (
        <ContentNotesCardContentEdit note={note} onAbort={setDefaultContentCardState} onNoteChange={handleNoteChange} />
      )}
    </>
  )
}
