import * as React from 'react'
import { Col, Row } from 'reactstrap'
import { formatPrice } from '@helpers/utils'
import { PaymentDayUserCloseReport } from '@models/payments'
import { UserCashBoxCloseDayUserAmount } from '@modules/payment-registers/user-cashbox/close-day-modal/user-amount'

interface Props {
  dayCloseReport: PaymentDayUserCloseReport
  readOnly: boolean
  resortId: number
}

export const UserCashBoxCloseDayCashSummary = ({ readOnly, dayCloseReport, resortId }: Props): JSX.Element => (
  <div className="p-2 pb-1">
    <strong className="text-secondary d-block mb-2 font-14">Podsumowanie:</strong>

    <Row className="mb-2">
      <Col md={8}>
        <strong className="text-semi-strong d-block">Stan kasy wg. systemu:</strong>
        <span className="font-10">Stan początkowy kasetki + Wpłaty (KP) Gotówka - Wypłaty (KW) Gotówka</span>
      </Col>
      <Col md={4} className="text-right text-strong font-15">
        {formatPrice(dayCloseReport.cash_total)}
      </Col>
    </Row>
    {!readOnly && (
      <UserCashBoxCloseDayUserAmount
        resortId={resortId}
        fieldName="cashbox_amount_user"
        total={dayCloseReport.cash_total}
        differenceFieldName="cash_difference_reason"
      />
    )}
  </div>
)
