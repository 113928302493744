import * as React from 'react'
import { Button } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'

export const PromocodeCreate: React.FC = () => {
  const [handleShowDialog] = useModal('PromocodeCreateDialog')

  return (
    <Button color="green" onClick={handleShowDialog} className="btn-tall mr-2">
      <i className="mdi mdi-plus-circle mr-1" />
      Dodaj kod
    </Button>
  )
}
