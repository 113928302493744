import * as React from 'react'
import { Button, Col, CustomInput, FormGroup, Input, Label } from 'reactstrap'
import { ColAuto } from '@hyper/col-auto'
import { RootState, useAppSelector } from '@store/index'
import { useForm } from 'react-hook-form'
import { extractInnerRef } from '@helpers/forms'
import { UsersFilters } from '@modules/rcp/users/index'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { rcpGroupsSelector } from '@store/slices/rcp-slice'

interface UsersFilterProps {
  defaultFilters: UsersFilters
  filters: UsersFilters
  setFilters: (filters: UsersFilters) => void
}

export const UsersFilter: React.FC<UsersFilterProps> = ({ defaultFilters, filters, setFilters }) => {
  const { register, handleSubmit, reset, formState, watch, setValue } = useForm<UsersFilters>({
    defaultValues: defaultFilters,
  })

  const months = useAppSelector((state: RootState) => state.appState.appData.dates.months)
  const seasons = useAppSelector((state: RootState) => state.appState.appData.dates.seasons)

  const handleReset = () => {
    reset()
    setFilters(defaultFilters)
  }

  const resorts = useAppSelector(rcpGroupsSelector)

  const onSubmit = payload => setFilters({ ...filters, ...payload })

  useHandleTableFiltersChange(watch, filters, onSubmit)

  return (
    <form className="row form-style-filter form mb-3" onSubmit={handleSubmit(onSubmit)}>
      <ColAuto>
        <FormGroup>
          <Label>Ośrodek</Label>
          <Input type="select" {...extractInnerRef(register('resort'))}>
            <option value="">Wybierz</option>
            {resorts.map(resort => (
              <option key={resort.id} value={resort.name}>
                {resort.name}
              </option>
            ))}
          </Input>
        </FormGroup>
      </ColAuto>
      <ColAuto>
        <FormGroup>
          <Label>Sezon</Label>
          <Input type="select" {...extractInnerRef(register('season'))}>
            {seasons.map((season: number) => (
              <option key={season} value={season}>
                {season}
              </option>
            ))}
          </Input>
        </FormGroup>
      </ColAuto>
      <ColAuto>
        <FormGroup>
          <Label>Miesiąc</Label>
          <Input type="select" {...extractInnerRef(register('month'))}>
            {months.map((month: [number, string]) => (
              <option key={month[0]} value={month[0]}>
                {month[1]}
              </option>
            ))}
          </Input>
        </FormGroup>
      </ColAuto>
      <ColAuto>
        <FormGroup>
          <Label>Czas pracy</Label>
          <Input type="select" {...extractInnerRef(register('work_time'))}>
            <option value="">---</option>
            <option value="complete">Uzupełniony</option>
            <option value="incomplete">Nieuzupełniony</option>
          </Input>
        </FormGroup>
      </ColAuto>
      <ColAuto>
        <FormGroup>
          <label className="d-block">&nbsp;</label>
          <Button color="primary" className="mr-1">
            Filtruj
          </Button>
          {formState.isDirty && (
            <Button color="light" onClick={handleReset} type="button">
              Wyczyść
            </Button>
          )}
        </FormGroup>
      </ColAuto>
      <ColAuto className="ml-auto">
        <FormGroup>
          <label className="d-block">&nbsp;</label>
          <SearchFilter setValue={setValue} />
        </FormGroup>
      </ColAuto>
      <Col sm={12}>
        <CustomInput
          {...extractInnerRef(register('only_active'))}
          id="only_active"
          type="checkbox"
          className="text-default"
          label="Pokaż tylko aktywnych pracowników"
          name="only_active"
        />
      </Col>
    </form>
  )
}
