import * as React from 'react'
import { Card, CardBody } from 'reactstrap'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { Subscription } from '@modules/subscription/models'
import { PackageWholesaleDetailsGastroCardsRow } from '@modules/package-wholesale/details/gastro_cards/row'
import { GastroCard } from '@models/promotions'

const headerValues: TableHeaderValue<Subscription>[] = [
  { title: 'Numer', sortField: 'number' },
  { title: 'Klient', sortField: 'email' },
  { title: 'Status', sortField: 'status' },
  { title: 'Zadeklarowana kwota' },
  { title: 'Data założenia', sortField: 'created' },
]

interface Props {
  gastroCards: GastroCard[]
  children?: React.ReactNode
}

export const PackageWholesaleDetailsGastroCards = ({ gastroCards, children }: Props): JSX.Element => {
  const setFilters = () => console.log('fake SetFilters')
  const filters: TableFilters = {
    search: '',
    ordering: '-created',
    page: 1,
    page_size: 9999,
  }

  return (
    <Card>
      {children}
      <CardBody>
        <h4 className="mt-0 text-secondary mb-3">Kupony gastro</h4>
        <Table
          className="table-hover w-100"
          headerValues={headerValues}
          isLoading={false}
          showPagination={false}
          filters={filters}
          setFilters={setFilters}
        >
          {gastroCards.map((gastroCard: GastroCard) => (
            <PackageWholesaleDetailsGastroCardsRow key={gastroCard.id} gastroCard={gastroCard} />
          ))}
        </Table>
      </CardBody>
    </Card>
  )
}
