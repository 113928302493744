import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { CommonTableFilters } from '@components/table/common-table-filters'
import { FormCheckbox } from '@hyper/forms/form-checkbox'
import { ColAuto } from '@hyper/col-auto'
import { PromocodeCodesTableFormInputs } from '@modules/promocode/promocode-list/promocode-codes/promocode-codes-table'

interface Props {
  defaultFilters: PromocodeCodesTableFormInputs
  filters: PromocodeCodesTableFormInputs
  setFilters: (filters: PromocodeCodesTableFormInputs) => void
  children: React.ReactNode
}

export const PromocodeCodesTableFilters: React.FC<Props> = ({ filters, setFilters, defaultFilters, children }) => {
  const methods = useForm<PromocodeCodesTableFormInputs>({ defaultValues: filters })

  return (
    <FormProvider {...methods}>
      {children}
      <CommonTableFilters
        defaultFilters={defaultFilters}
        filters={filters}
        setFilters={setFilters}
        searchParams={{ hideLabel: true }}
        clearParams={{ className: 'mt-0' }}
        rowClassName="justify-content-end align-items-baseline"
        searchWrapperParams={{ className: 'col-3' }}
      >
        <ColAuto className="col-auto mr-auto">
          <FormCheckbox
            name="is_used"
            label="Pokaż wykorzystane"
            className="mt-label"
            checked={methods.getValues('is_used')}
          />
        </ColAuto>
      </CommonTableFilters>
    </FormProvider>
  )
}
