import * as React from 'react'
import { useExpandableItems } from '@components/hooks/use-expandable-items'
import {
  ResortAvailableScope,
  useResortWalletGroupedScope,
} from '@modules/reception/checkin/step-guests/step-guests-guest-form/wallet/use-resort-wallet-scope'
import {
  ExpandableScope,
  FormScope,
  StepGuestsWalletScopesItem,
} from '@modules/reception/checkin/step-guests/step-guests-guest-form/wallet/scopes-list-item'

interface Props {
  resortId: number
}

export const StepGuestsWalletScopes = ({ resortId }: Props): JSX.Element => {
  const { toggleExpanded, isExpanded } = useExpandableItems<ExpandableScope>()

  const { availableGastroScopes, availableVendingMachinesScopes, availableShopScopes, availableParkAndFunScopes } =
    useResortWalletGroupedScope(resortId)

  const getAvailableScopes = (scopes: FormScope[], resortScopes: ResortAvailableScope[]) =>
    scopes.filter(scope => resortScopes.some(availableScope => availableScope.key === scope.key))

  const gastroScopes = getAvailableScopes(GastroScopes, availableGastroScopes)
  const vendingMachineScopes = getAvailableScopes(VendingMachinesScopes, availableVendingMachinesScopes)
  const shopScopes = getAvailableScopes(ShopScopesFormNames, availableShopScopes)
  const parkAndFunScopes = getAvailableScopes(HolidayParkFunScopes, availableParkAndFunScopes)

  return (
    <table className="table table-striped mt-3 border border-bottom-0">
      <tbody>
        {!!availableGastroScopes.length && (
          <StepGuestsWalletScopesItem
            title="Punkty Gastronomiczne"
            expandableScope="gastro"
            isExpanded={isExpanded('gastro')}
            toggleIsExpanded={toggleExpanded('gastro')}
            formScopes={gastroScopes}
            totalScopesAmount={availableGastroScopes.length}
          />
        )}

        {!!availableVendingMachinesScopes.length && (
          <StepGuestsWalletScopesItem
            title="Automaty"
            expandableScope="vendingMachines"
            isExpanded={isExpanded('vendingMachines')}
            toggleIsExpanded={toggleExpanded('vendingMachines')}
            formScopes={vendingMachineScopes}
            totalScopesAmount={availableVendingMachinesScopes.length}
          />
        )}

        {!!availableShopScopes.length && (
          <StepGuestsWalletScopesItem
            title="Sklep"
            expandableScope="shop"
            isExpanded={isExpanded('shop')}
            toggleIsExpanded={toggleExpanded('shop')}
            formScopes={shopScopes}
            totalScopesAmount={availableShopScopes.length}
          />
        )}

        {!!availableParkAndFunScopes.length && (
          <StepGuestsWalletScopesItem
            title="Holiday Park & Fun"
            expandableScope="parkAndFun"
            isExpanded={isExpanded('parkAndFun')}
            toggleIsExpanded={toggleExpanded('parkAndFun')}
            formScopes={parkAndFunScopes}
            totalScopesAmount={availableParkAndFunScopes.length}
          />
        )}
      </tbody>
    </table>
  )
}

const GastroScopes: FormScope[] = [
  { title: 'Kawiarnia', key: 'cafe', scopeFormName: 'wallet_limits.cafe.enabled' },
  { title: 'Bistro', key: 'bistro', scopeFormName: 'wallet_limits.bistro.enabled' },
  { title: 'Słodki kącik', key: 'sweet_corner', scopeFormName: 'wallet_limits.sweet_corner.enabled' },
  { title: 'Brach bar', key: 'beach_bar', scopeFormName: 'wallet_limits.beach_bar.enabled' },
  { title: 'Wózek', key: 'itinerant_trade', scopeFormName: 'wallet_limits.itinerant_trade.enabled' },
]

const VendingMachinesScopes: FormScope[] = [
  {
    title: 'Automaty vendingowe',
    key: 'vending_machines',
    scopeFormName: 'wallet_limits.vending_machines.enabled',
    limitFormName: 'wallet_limits.vending_machines.limit',
  },
  {
    title: 'Automaty rozrywkowe',
    key: 'vending_machines_entertainment',
    scopeFormName: 'wallet_limits.vending_machines_entertainment.enabled',
    limitFormName: 'wallet_limits.vending_machines_entertainment.limit',
  },
  {
    title: 'Automaty gastronomiczne',
    key: 'vending_machines_gastronomy',
    scopeFormName: 'wallet_limits.vending_machines_gastronomy.enabled',
    limitFormName: 'wallet_limits.vending_machines_gastronomy.limit',
  },
]

const ShopScopesFormNames: FormScope[] = [{ title: 'Sklep', key: 'shop', scopeFormName: 'wallet_limits.shop.enabled' }]

const HolidayParkFunScopes: FormScope[] = [
  {
    title: 'Automaty Holiday Park & Fun',
    key: 'vending_machines_parkfun',
    scopeFormName: 'wallet_limits.vending_machines_parkfun.enabled',
    limitFormName: 'wallet_limits.vending_machines_parkfun.limit',
  },
  {
    title: 'Bilety Holiday Park & Fun',
    key: 'tickets_parkfun',
    scopeFormName: 'wallet_limits.tickets_parkfun.enabled',
  },
]
