import * as React from 'react'
import { ColAuto } from '@hyper/col-auto'
import { Label, Row } from 'reactstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { ButtonWithIcon } from '@components/button-with-icon'
import { useModal } from '@components/modals/use-modal'
import { FormSelect } from '@hyper/forms/form-select'

export interface SellerListFilterParams {
  department?: CustomReactSelectOption
  search: string
  ordering: string
  page: number
  page_size: number
}

interface Props {
  defaultFilters: SellerListFilterParams
  filters: SellerListFilterParams
  setFilters: (filters: SellerListFilterParams) => void
}

export const departments: CustomReactSelectOption[] = [
  {
    value: 'BOK',
    label: 'BOK',
  },
  {
    value: 'BOS',
    label: 'BOS',
  },
  {
    value: 'B2B',
    label: 'B2B',
  },
  {
    value: 'reception',
    label: 'Recepcja',
  },
  {
    value: 'resort_seller',
    label: 'Opiekun w resorcie',
  },
  {
    value: 'director',
    label: 'Dyrektor',
  },
  {
    value: 'gastronomy',
    label: 'Gastronomia',
  },
  {
    value: 'other',
    label: 'Inne',
  },
]

export const SellerListFilter: React.FC<Props> = ({ defaultFilters, filters, setFilters }) => {
  const methods = useForm<SellerListFilterParams>({
    defaultValues: defaultFilters,
  })

  const onSubmit = async (payload: SellerListFilterParams) => setFilters({ ...filters, ...payload })

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  const [handleSeller] = useModal('AddSellerModal')

  return (
    <FormProvider {...methods}>
      <Row>
        <ColAuto className="px-0">
          <FormSelect
            options={departments}
            name="seller"
            label="Dział"
            selectProps={{ isClearable: true }}
            formPlainProps={{ colStyle: { width: 200 } }}
          />
        </ColAuto>
        <ColAuto className="col-auto ml-auto">
          <Label className="d-block">&nbsp;</Label>

          <ButtonWithIcon
            icon="uil-plus mr-1"
            text="Dodaj Sprzedawcę"
            btnClass="btn btn-green-dark ml-auto"
            handleClick={handleSeller}
            role="create-guest"
          />
        </ColAuto>
      </Row>
      <Row>
        <SearchFilter hideLabel={true} />
      </Row>
    </FormProvider>
  )
}
