import * as React from 'react'
import { Row } from 'reactstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { ColAuto } from '@hyper/col-auto'
import { Link } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { PackageWholesaleProductCreateButton } from '@modules/package-wholesale/products/create'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { createSelectOption } from '@helpers/utils'
import { FormSelect } from '@hyper/forms/form-select'

export interface PackageWholesaleProductListFilterParams {
  search: string
  ordering: string
  page: number
  page_size: number
  status: undefined | CustomReactSelectOption
}

interface Props {
  defaultFilters: PackageWholesaleProductListFilterParams
  filters: PackageWholesaleProductListFilterParams
  setFilters: (filters: PackageWholesaleProductListFilterParams) => void
}
export const packageWholesaleProductsStatusOptions: CustomReactSelectOption[] = [
  createSelectOption('W sprzedaży', 'available'),
  createSelectOption('Wycofany', 'unavailable'),
]
export const PackageWholesaleProductListFilter: React.FC<Props> = ({ defaultFilters, filters, setFilters }) => {
  const methods = useForm<PackageWholesaleProductListFilterParams>({
    defaultValues: defaultFilters,
  })

  const onSubmit = async (payload: PackageWholesaleProductListFilterParams) => setFilters({ ...filters, ...payload })

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)
  const user = useAuthenticatedUser()

  return (
    <FormProvider {...methods}>
      <Row>
        <ColAuto className="ml-auto">
          {user.hasPerm(UserPermission.PackageWholesaleProductCanManage) && (
            <Link to={NavigationPath.PackageWholesaleList} className="btn btn-light mr-2">
              Wróć do listy pakietów
            </Link>
          )}

          <PackageWholesaleProductCreateButton />
        </ColAuto>
      </Row>
      <Row>
        <ColAuto className="px-0">
          <FormSelect
            options={packageWholesaleProductsStatusOptions}
            name="status"
            label="Status"
            selectProps={{ isClearable: true }}
            formPlainProps={{ colStyle: { width: 200 } }}
          />
        </ColAuto>
        <SearchFilter />
      </Row>
    </FormProvider>
  )
}
