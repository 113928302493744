import * as React from 'react'
import { Subscription, SubscriptionType } from '@modules/subscription/models'
import Table from '@components/table/table'
import { SubscriptionListFilterParams } from '@modules/subscription/list/filter'
import { SubscriptionListTableRow } from '@modules/subscription/list/table-row'
import { SubscriptionsTableFooter } from '@modules/subscription/list/table-footer'

const getHeaderValues = (type: SubscriptionType) => [
  { title: 'Numer', sortField: 'number' },
  { title: 'Źródło', sortField: 'source_marketing__name' },
  { title: 'Klient', sortField: 'email' },
  { title: 'Status', sortField: 'status' },
  { title: 'Kwota brutto' },
  { title: 'Kwota niewykorzystana' },
  { title: 'Pozostało do zapłaty' },
  { title: 'Przyjazdów' },
  ...(type !== 'BOOKING_IMPROVEMENT' ? [{ title: 'Opcje' }] : []),
  { title: 'Założono przez', sortField: 'created' },
  { title: 'Sprzedawca', sortField: 'seller__name' },
]

interface Props {
  subscriptions: Subscription[]
  isLoading: boolean
  filters: SubscriptionListFilterParams
  setFilters: (filters: SubscriptionListFilterParams) => void
  type: SubscriptionType
}

export const SubscriptionListTable: React.FC<Props> = ({ isLoading, subscriptions, filters, setFilters, type }) => (
  <Table
    className="table-hover w-100"
    headerValues={getHeaderValues(type)}
    isLoading={isLoading}
    filters={filters}
    setFilters={setFilters}
  >
    {subscriptions.map(subscription => (
      <SubscriptionListTableRow key={subscription.id} subscription={subscription} />
    ))}
    {!!subscriptions.length && <SubscriptionsTableFooter />}
  </Table>
)
