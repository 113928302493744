import * as React from 'react'
import { ReceptionBookingCartPaymentBasic } from '@modules/reception/cart/payment-box/payment-options/reception-booking-cart-payment-basic'
import { ReceptionBookingCartPaymentDeposit } from '@modules/reception/cart/payment-box/payment-options/reception-booking-cart-payment-deposit'
import { ReceptionBookingCartPaymentWallet } from '@modules/reception/cart/payment-box/payment-options/reception-booking-cart-payment-wallet'
import classNames from 'classnames'
import { ReceptionBookingClientWallet, ReceptionBookingDetailsCartAvailablePaymentMethod } from '@models/reception'
import { CartPaymentMethod } from '@modules/reception/cart/models'
import { CombinedCartItemsPrice, isCartPaymentAvailable } from '@modules/reception/cart/utils'

export type CartPaymentMethodContext = ReceptionBookingClientWallet

interface Props {
  onPaymentSelection: (method: CartPaymentMethod, context?: CartPaymentMethodContext) => void
  isDisabled: boolean
  isWalletDisabled: boolean
  isDepositDisabled: boolean
  wallets: ReceptionBookingClientWallet[]
  combinedCartPrice: CombinedCartItemsPrice
  availablePaymentMethods: ReceptionBookingDetailsCartAvailablePaymentMethod[]
}

export const ReceptionBookingCartPaymentMethods = ({
  onPaymentSelection,
  wallets,
  isDisabled,
  isWalletDisabled,
  isDepositDisabled,
  combinedCartPrice,
  availablePaymentMethods,
}: Props): JSX.Element => {
  const hasPayment = (method: ReceptionBookingDetailsCartAvailablePaymentMethod) =>
    isCartPaymentAvailable(availablePaymentMethods, method)

  const isCashAvailable = hasPayment('cash')
  const isCreditCardAvailable = hasPayment('creditcard')
  const isWalletAvailable = hasPayment('wallet')
  const isDepositAvailable = hasPayment('deposit')

  return (
    <div className={classNames({ 'not-clickable': isDisabled })}>
      {(isCashAvailable || isCreditCardAvailable) && (
        <ReceptionBookingCartPaymentBasic
          onPaymentSelection={onPaymentSelection}
          isCashAvailable={isCashAvailable}
          isCreditCardAvailable={isCreditCardAvailable}
        />
      )}

      {isDepositAvailable && (
        <ReceptionBookingCartPaymentDeposit onPaymentSelection={onPaymentSelection} isDisabled={isDepositDisabled} />
      )}

      {!!wallets.length && isWalletAvailable && (
        <ReceptionBookingCartPaymentWallet
          onPaymentSelection={onPaymentSelection}
          wallets={wallets}
          combinedCartPrice={combinedCartPrice}
          isDisabled={isWalletDisabled}
        />
      )}
    </div>
  )
}
