import * as React from 'react'
import { Button, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { SaveButton } from '@hyper/button'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useForm } from 'react-hook-form'
import { createReportTask } from '@store/actions/report-tasks-actions'
import { endOfMonth, startOfMonth, startOfToday } from 'date-fns'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { BaseModalProps } from '@components/modals/types'
import { SHOP_PAYMENT_OPTIONS } from '@modules/reports/user-reports/reports-generator-shop-receipt'
import { createSelectOption } from '@helpers/utils'
import { Form } from '@hyper/forms/form'
import { FormSelect } from '@hyper/forms/form-select'
import { useResortSelectOptions } from '@components/hooks/use-resort-select-options'

const KIND_OPTIONS = [createSelectOption('Produkt', 'product'), createSelectOption('Usługa', 'service')]

interface FormInputs {
  created_after: Date | null
  created_before: Date | null
  resort: CustomReactSelectOption | undefined
  payment: CustomReactSelectOption | undefined
  kind: CustomReactSelectOption | undefined
}

export const ReportsGeneratorShopReceiptProducts: React.FC<BaseModalProps> = ({ toggleIsVisible }) => {
  const reportUrl = useAppSelector(
    (state: RootState) => state.appState.appData.urls.reports.report_task_receipt_product,
  )
  const dispatch = useAppDispatch()

  const methods = useForm<FormInputs>({
    mode: 'all',
    defaultValues: {
      created_after: startOfMonth(startOfToday()),
      created_before: endOfMonth(startOfToday()),
    },
  })

  const resorts = useResortSelectOptions()

  const { isLoading, action: onSubmit } = useReportFormRequest(async (payload: FormInputs) => {
    const reportTask = await dispatch(
      createReportTask([
        reportUrl,
        {
          ...payload,
          resort: payload.resort?.value,
          payment: payload.payment?.value,
          kind: payload.kind?.value,
        },
      ]),
    )
    toggleIsVisible()
    return reportTask
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Raport sprzedaży sklepowej</ModalHeader>
      <ModalBody>
        <Row>
          <FormSelect options={resorts} name="resort" label="Ośrodek" selectProps={{ isClearable: true }} />
          <FormSelect options={KIND_OPTIONS} name="kind" label="Typ" selectProps={{ isClearable: true }} />
          <FormSelect
            options={SHOP_PAYMENT_OPTIONS}
            name="payment"
            label="Sposób płatności"
            selectProps={{ isClearable: true }}
          />

          <DateFromToInput label="Data sprzedaży" startDateName="created_after" endDateName="created_before" />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" label="Zleć raport" labelSaving="Zlecanie.." isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
