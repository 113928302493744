import * as React from 'react'
import { CleaningOrder } from '@modules/housekeeping/models'
import { HousekeepingCleaningOrderToOrderRowActionsOrder } from '@modules/housekeeping/cleaning-orders/to-order/housekeeping-cleaning-order-to-order-row-actions-order'
import { OrderNotes } from '@modules/housekeeping/cleaning-orders/common/row-notes'
import { HousekeepingCleaningOrderRowActionsSetPriority } from '@modules/housekeeping/cleaning-orders/to-order/actions/actions-priority'
import { useModal } from '@components/modals/use-modal'

interface HousekeepingCleaningOrderToOrderRowActionsProps {
  cleaningOrder: CleaningOrder
}

export const HousekeepingCleaningOrderToOrderRowActions: React.FC<HousekeepingCleaningOrderToOrderRowActionsProps> = ({
  cleaningOrder,
}) => {
  const [showNotesModal] = useModal('HousekeepingOrderNoteModal', { order: cleaningOrder })

  return (
    <td style={{ minWidth: 305 }} className="text-nowrap text-right housekeeping__orders_table__column">
      <div className="d-flex align-items-center justify-content-end">
        <OrderNotes order={cleaningOrder} onClick={showNotesModal} />
        <HousekeepingCleaningOrderRowActionsSetPriority cleaningOrder={cleaningOrder} />
        <HousekeepingCleaningOrderToOrderRowActionsOrder cleaningOrder={cleaningOrder} />
      </div>
    </td>
  )
}
