import * as React from 'react'
import { ColAuto } from '@hyper/col-auto'
import { FormGroup, Input } from 'reactstrap'
import classNames from 'classnames'
import { UseFormSetValue } from 'react-hook-form/dist/types/form'
import { extractInnerRef } from '@helpers/forms'
import { useNewFormContext } from '@hyper/forms/hooks/use-new-form-context'

export interface SearchFilterProps {
  setValue?: UseFormSetValue<any>
  colClassName?: string
  hideLabel?: boolean
  formGroupClassName?: string
  defaultValue?: string
}

export const SearchFilter: React.FC<SearchFilterProps> = ({
  setValue,
  formGroupClassName,
  colClassName,
  hideLabel,
  defaultValue,
}) => {
  const methods = useNewFormContext()

  const handleChange = event => {
    if (!setValue) return

    setValue('page', 1)
    setValue('search', event.target.value.trim(), { shouldDirty: true })
  }

  return (
    <ColAuto className={classNames('ml-auto', colClassName)}>
      <FormGroup className={formGroupClassName}>
        {!hideLabel && <label className="d-block">&nbsp;</label>}
        <Input
          type="text"
          role="search"
          autoComplete="one-time-code"
          placeholder="Wpisz szukaną frazę"
          defaultValue={defaultValue}
          onChange={handleChange}
          {...(methods?.register && { ...extractInnerRef(methods.register('search')) })}
        />
      </FormGroup>
    </ColAuto>
  )
}
