import * as React from 'react'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { getHousekeepingImprovementOrders } from '@store/actions/housekeeping-actions'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { HousekeepingImprovementOrderType, ImprovementOrder } from '@modules/housekeeping/models'
import { HousekeepingImprovementOrdersTable } from '@modules/housekeeping/cleaning-orders/improvement/table'
import { useApiRequest } from '@components/hooks/use-api-request'
import { improvementOrdersSelector } from '@store/slices/housekeeping-slice'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { HousekeepingImprovementsOrdersFilters } from '@modules/housekeeping/cleaning-orders/improvement/filters'
import { HousekeepingImprovementOrdersRowOptions } from '@modules/housekeeping/cleaning-orders/bedding/row-options'
import { searchImprovementsOrders } from '@modules/housekeeping/cleaning-orders/common/filtering'
import { useHousekeepingTableFilters } from '@modules/housekeeping/hooks/use-housekeeping-table-filters'
import { IconWithText } from '@components/icon-with-text'

const statusesToShow = ['NEW', 'PENDING', 'IN_PROGRESS', 'FINISHED']

export interface HousekeepingImprovementsOrderFiltersParams {
  local: CustomReactSelectOption | null
  status: CustomReactSelectOption | null
  improvements: CustomReactSelectOption[]
  search: string
}

const defaultFilters: HousekeepingImprovementsOrderFiltersParams = {
  local: null,
  status: null,
  search: '',
  improvements: [],
}

interface Props {
  resortId: number
}

export const HousekeepingImprovementOrders: React.FC<Props> = ({ resortId }) => {
  const improvementOrders = useAppSelector(improvementOrdersSelector)
  const workers = useAppSelector((state: RootState) => state.houseKeepingState.cleaningWorkers)

  const [selectedOrders, setSelectedOrders] = React.useState<ImprovementOrder[]>([])
  const [filters, setFilters] = React.useState<HousekeepingImprovementsOrderFiltersParams>(defaultFilters)

  const methods = useForm<HousekeepingImprovementsOrderFiltersParams>({
    defaultValues: filters,
  })

  const dispatch = useAppDispatch()
  const { isLoading, action: fetchOrders } = useApiRequest(
    async () =>
      await dispatch(
        getHousekeepingImprovementOrders([{ resort: resortId }, HousekeepingImprovementOrderType.PRESENT]),
      ),
  )

  React.useEffect(() => {
    fetchOrders()
  }, [resortId])

  const { getFiltered } = useHousekeepingTableFilters()

  const selectedImprovements = useWatch({ control: methods.control, name: 'improvements' })

  const filterImprovements = (order: ImprovementOrder) =>
    selectedImprovements.length &&
    !selectedImprovements.some(selectedImprovement =>
      order.items.some(orderItem => orderItem.code === selectedImprovement.value),
    )

  const filterStatus = (order: ImprovementOrder) => !!(filters.status && filters.status?.value !== order.status)

  const additionalFilters = (order: ImprovementOrder) => filterImprovements(order) || filterStatus(order)

  const filteredImprovementOrders = searchImprovementsOrders(
    getFiltered(
      (improvementOrders || []).filter(row => statusesToShow.includes(row.status)),
      filters,
      additionalFilters,
    ),
    filters.search,
    workers,
  )

  return (
    <LoaderPlaceholder content={!isLoading}>
      <div className="d-flex justify-content-between">
        <FormProvider {...methods}>
          <HousekeepingImprovementOrdersRowOptions resortId={resortId} selectedOrders={selectedOrders} />
          <HousekeepingImprovementsOrdersFilters
            filters={filters}
            setFilters={setFilters}
            defaultFilters={defaultFilters}
          />
        </FormProvider>
      </div>
      <HousekeepingImprovementOrdersTable
        methods={methods}
        selectedOrders={selectedOrders}
        setSelectedOrders={setSelectedOrders}
        improvementOrders={filteredImprovementOrders}
      />
      {!!filteredImprovementOrders.length && (
        <IconWithText
          icon="uil-info-circle text-warning font-18"
          text="Niezrealizowane zadania są usuwane po 3 dniach."
          textClass="fw-semi-bold font-12"
        />
      )}
    </LoaderPlaceholder>
  )
}
