import { useModal } from '@components/modals/use-modal'
import { ShopParkingParams } from '@modules/shop/pos/product-selection/product-configuration-modals/shop-parking-configuration-modal'
import { ShopProduct } from '@models/shop'
import { EnhancedShopProduct } from '@modules/shop/pos/models'
import { v4 as uuidv4 } from 'uuid'

type AddAction = (product: ShopProduct) => void
type UpdateAction = (index: number, product: EnhancedShopProduct) => void

interface Response {
  addParking: (addShopProduct: AddAction, product: ShopProduct) => void
  updateParking: (updateShopProduct: UpdateAction, addedProduct: EnhancedShopProduct, index: number) => void
}

export const useShopParking = (resort: string): Response => {
  const [showParkingConfigurationModal] = useModal('ShopParkingConfigurationModal')

  const onAddParking = (addShopProduct: AddAction, product: ShopProduct) => (configuration: ShopParkingParams) => {
    addShopProduct?.({ ...product, listId: uuidv4(), amount: 1, params: configuration } as EnhancedShopProduct)
  }

  const addParking = (addShopProduct: AddAction, product: ShopProduct) => {
    showParkingConfigurationModal(null, { product, onChange: onAddParking(addShopProduct, product), resort })
  }

  const onEditParking =
    (updateShopProduct: UpdateAction, addedProduct: EnhancedShopProduct, index: number) =>
    (configuration: ShopParkingParams) => {
      updateShopProduct(index, { ...addedProduct, params: configuration })
    }

  const updateParking = (updateShopProduct: UpdateAction, addedProduct: EnhancedShopProduct, index: number) => {
    showParkingConfigurationModal(null, {
      product: addedProduct,
      onChange: onEditParking(updateShopProduct, addedProduct, index),
      resort,
    })
  }

  return {
    addParking,
    updateParking,
  }
}
