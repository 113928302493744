import * as React from 'react'
import { BeddingOrder, CleaningOrder, CommonTask, ImprovementOrder } from '@modules/housekeeping/models'
import { HousekeepingCleaningOrderArchiveRow } from '@modules/housekeeping/cleaning-orders/archive/row'
import { HousekeepingBeddingOrderArchiveRow } from '@modules/housekeeping/cleaning-orders/archive/bedding-row'
import { HousekeepingImprovementOrderArchiveRow } from './improvement-row'
import { HousekeepingCommonTaskArchiveRow } from '@modules/housekeeping/cleaning-orders/archive/common-task-row'
import { HousekeepingNoOrdersRow } from '@modules/housekeeping/cleaning-orders/common/no-orders'

interface Props {
  cleaningOrders: CleaningOrder[]
  beddingOrders: BeddingOrder[]
  improvementOrders: ImprovementOrder[]
  commonTaskOrders: CommonTask[]
}

export const HousekeepingCleaningOrdersArchiveTable: React.FC<Props> = ({
  cleaningOrders,
  beddingOrders,
  improvementOrders,
  commonTaskOrders,
}) => {
  const total = cleaningOrders.length + beddingOrders.length + commonTaskOrders.length + improvementOrders.length
  return (
    <table className="housekeeping__orders_table table table-striped">
      <thead>
        <tr>
          <th className="border-top-0">Lokal</th>
          <th className="border-top-0">Rodzaj</th>
          <th className="border-top-0">Status</th>
          <th className="border-top-0">Pracownik</th>
          <th className="border-top-0">Czas pracy</th>
          <th className="border-top-0">Zlecone przez</th>
          <th className="text-center border-top-0">Uszkodzenia</th>
          <th className="border-top-0" style={{ width: 10 }} />
          <th className="border-top-0" style={{ width: 10 }}>
            Akceptacja
          </th>
        </tr>
      </thead>
      <tbody>
        {!total && <HousekeepingNoOrdersRow colSpan={9} />}
        {cleaningOrders.map(cleaningOrder => (
          <HousekeepingCleaningOrderArchiveRow cleaningOrder={cleaningOrder} key={cleaningOrder.id} />
        ))}
        {beddingOrders.map(beddingOrder => (
          <HousekeepingBeddingOrderArchiveRow beddingOrder={beddingOrder} key={beddingOrder.id} />
        ))}
        {improvementOrders.map(improvementOrder => (
          <HousekeepingImprovementOrderArchiveRow improvementOrder={improvementOrder} key={improvementOrder.id} />
        ))}
        {commonTaskOrders.map(commonTask => (
          <HousekeepingCommonTaskArchiveRow commonTask={commonTask} key={commonTask.id} />
        ))}
      </tbody>
    </table>
  )
}
