import * as React from 'react'
import { BenefitProgramDetails } from '@modules/benefit-program/models'
import { Button } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'
import { SubscriptionDetailsInformationRow } from '@modules/subscription/details/information/row'
import { BenefitProgramDetailsUsersTable } from '@modules/benefit-program/details/users/table'

interface Props {
  benefitProgram: BenefitProgramDetails
}

export const BenefitProgramDetailsUsers: React.FC<Props> = ({ benefitProgram }) => {
  const [handleAddUser] = useModal('BenefitProgramUserAddModal', { benefitProgram })

  return (
    <SubscriptionDetailsInformationRow name="Osoby reprezentujące">
      <BenefitProgramDetailsUsersTable benefitProgram={benefitProgram} />
      <Button color="green-dark" className="btn-xs" onClick={handleAddUser}>
        Dodaj osobę
      </Button>
    </SubscriptionDetailsInformationRow>
  )
}
