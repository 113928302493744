import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  icon: string
  title: string
  content: React.ReactNode
}

export const ReservationSidebarSummaryBoxDataRow = ({ icon, title, content }: Props) => (
  <div className="d-flex justify-content-between mt-1">
    <IconWithText icon={icon} text={title} textClass="fw-semi-bold font-11" />
    <div className="text-dark font-weight-bold font-11">{content}</div>
  </div>
)
