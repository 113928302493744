import * as React from 'react'
import { Card, CardBody } from 'reactstrap'
import { useAppDispatch, useAppSelector } from '@store/index'
import { getPackagesVip } from '@store/actions/promotions-actions'
import { PackageVipFilters } from '@modules/promotions/package-vip/package-vip-filters'
import { PackageVipFiltersParams } from '@modules/promotions/package-vip/models'
import { PackageVipTable } from '@modules/promotions/package-vip/package-vip-table'
import { PackageVipWebSocketHandler } from '@modules/promotions/package-vip/package-vip-web-socket-handler'
import { PackageVipExportButtons } from '@modules/promotions/package-vip/package-vip-export-buttons'
import { useReportsAppData } from '@modules/reports/hooks/use-reports-app-data'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { packageVipsSelector } from '@store/slices/package-vip-slice'
import { useTableFilters } from '@components/table/use-table-filters'
import { useParams } from 'react-router-dom'
import { PackageVipDetailsView } from '@modules/promotions/package-vip/details_view'
import { PageTitleWithSize } from '@hyper/page-title-with-size'
import { PackageVipStatusesSelectOptions } from '@modules/promotions/package-vip/utils'

const defaultFilters: PackageVipFiltersParams = {
  ordering: '-sell_date',
  page: 1,
  page_size: 10,
  search: '',
  sell_date_after: undefined,
  sell_date_before: undefined,
  seller: null,
  status: PackageVipStatusesSelectOptions.slice(0, 2),
  type: null,
}

export const PackageVipView: React.FC = () => {
  const packagesVip = useAppSelector(packageVipsSelector)

  const { id } = useParams<'id'>()

  const dispatch = useAppDispatch()
  useMenuActive(NavigationPath.PromotionsPackageVip)

  const {
    isLoading,
    filters,
    setFilters,
    updateFiltersRequest: fetchPackageVips,
  } = useTableFilters<PackageVipFiltersParams>({
    defaultFilters,
    action: async filters => {
      await dispatch(getPackagesVip(filters))
    },
  })

  useReportsAppData()

  return (
    <>
      <PackageVipWebSocketHandler />
      <PageTitleWithSize title="Pakiety VIP" />
      {id && <PackageVipDetailsView id={parseInt(id, 10)} />}

      <Card>
        <CardBody>
          <PackageVipFilters
            fetchPackageVips={() => fetchPackageVips(filters)}
            setFilters={setFilters}
            filters={filters}
            defaultFilters={defaultFilters}
          />
          <PackageVipTable packages={packagesVip} isLoading={isLoading} filters={filters} setFilters={setFilters} />
          <PackageVipExportButtons />
        </CardBody>
      </Card>
    </>
  )
}
