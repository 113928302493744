import * as React from 'react'
import { FormInput, FormPlain } from '@hyper/forms'
import { Label, Row } from 'reactstrap'
import DatePickerInput from '@components/date/date-picker-input'
import { startOfToday, subYears } from 'date-fns'
import { FormInputMasked } from '@hyper/forms/form-input-masked'
import { PESEL_MASK } from '@models/crm'
import { FormSelect } from '@hyper/forms/form-select'
import { useAppData } from '@components/hooks/use-app-data'
import { FormInputPhone } from '@hyper/forms/form-input-phone'
import { AvailableAgreementsCountries, getAvailableAgreementsCountriesOptions } from '@modules/hr/consts'
import { useFormContext } from 'react-hook-form'

const minBirthdayDate = subYears(startOfToday(), 100)
const maxBirthdayDate = subYears(startOfToday(), 16)

export const HrWorkerForm = (): JSX.Element => {
  const { countries } = useAppData()
  const { setValue } = useFormContext()

  const countriesOptions = getAvailableAgreementsCountriesOptions(countries)

  return (
    <Row>
      <FormInput colSize={4} label="Imię" name="first_name" inputPattern={/\p{L}/u} />
      <FormInput colSize={4} label="Drugie imię" name="second_name" inputPattern={/\p{L}/u} />
      <FormInput colSize={4} label="Nazwisko" name="last_name" inputPattern={/\p{L}/u} />
      <FormSelect formPlainProps={{ colSize: 4 }} options={countriesOptions} label="Obywatelstwo" name="nationality" />
      <FormInputMasked
        placeholder="___________"
        colSize={4}
        mask={PESEL_MASK}
        name="tax_id"
        label="PESEL"
        inputClassName="letter-spacing-2"
      />
      <FormPlain colSize={4} name="birthday">
        <Label>Data urodzenia</Label>
        <DatePickerInput
          name="birthday"
          wrapperClassName="flex-grow-1"
          maxDate={maxBirthdayDate}
          minDate={minBirthdayDate}
          shownDate={maxBirthdayDate}
          dateFormat="dd LLLL yyyy"
          withInput
        />
      </FormPlain>
      <FormInput
        colSize={4}
        label="Numer dowodu osobistego"
        name="personal_id_number"
        registerParams={{
          onChange: event => setValue(event.target.name, event.target.value.toUpperCase()),
        }}
      />
      <FormInput colSize={4} label="Adres e-mail" name="email" />
      <FormInputPhone name="phone" colSize={4} label="Numer telefonu" countriesCodes={AvailableAgreementsCountries} />
    </Row>
  )
}
