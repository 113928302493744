import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { ReceptionBookingDetails } from '@models/reception'
import { SaveButton } from '@hyper/button'
import { ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { useFormRequest } from '@components/hooks/use-api-request'
import { FormInput } from '@hyper/forms'
import { useAppDispatch } from '@store/index'
import { updateReceptionBookingDetails } from '@store/actions/reception-actions'
import { commonObjectPut } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'

interface Props extends BaseModalProps {
  booking: ReceptionBookingDetails
}

interface FormInputs {
  discount_note: string
  discount: number
}

const notes = [
  'przenosiny',
  'reklamacja',
  'TOUR operator',
  'pracownik',
  'VIP',
  'polecenie',
  'rekompensata',
  'rabat na 2021 rok',
  'rabat na 2020 rok',
]
export const ReservationHprDiscountModal: React.FC<Props> = ({ booking, toggleIsVisible }) => {
  const methods = useForm<FormInputs>({
    defaultValues: { discount_note: booking.discount_note, discount: booking.discount },
  })
  const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()

  const { isLoading, action: onSubmit } = useFormRequest(
    async (payload: FormInputs) => {
      dispatch(
        updateReceptionBookingDetails(
          await commonObjectPut<ReceptionBookingDetails>(booking.urls.reservation_booking_details, payload),
        ),
      )

      addSuccessMessage()
      toggleIsVisible()
    },

    methods.setError,
    { showGlobalError: true },
  )

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggleIsVisible}>Zmiana rabatu HPR</ModalHeader>
        <ModalBody>
          <Row>
            <FormInput label="Rabat" name="discount" type="select">
              {[0, 5, 11, 10, 15, 20, 25, 30, 33, 35, 45, 40, 50, 60, 70, 75, 100].map(row => (
                <option value={row} key={row}>
                  {row}%
                </option>
              ))}
            </FormInput>
            <FormInput label="Powód nadania" name="discount_note" type="select">
              {notes.map(row => (
                <option key={row}>{row}</option>
              ))}
            </FormInput>
          </Row>
        </ModalBody>
        <ModalFooter>
          <button type="button" onClick={toggleIsVisible} className="btn btn-light">
            Anuluj
          </button>
          <SaveButton isSaving={isLoading} className="btn btn-green" />
        </ModalFooter>
      </form>
    </FormProvider>
  )
}
