import * as React from 'react'
import Table, { TableHeaderValue } from '@components/table/table'
import { PaymentRegister } from '@models/payments'
import { TransactionListModalTableFooter } from '@modules/payment-registers/transaction-list-modal/table-footer'
import { TransactionListModalTableRow } from '@modules/payment-registers/transaction-list-modal/table-row'
import { PaymentRegistersFiltersParams } from '@modules/payment-registers/list/filters'
import { usePaginateRows } from '@components/table/use-pagination'

interface Props {
  paymentRegisters: PaymentRegister[]
  isLoading: boolean
  filters: PaymentRegistersFiltersParams
  setFilters: (filters: PaymentRegistersFiltersParams) => void
}

const headerValues: TableHeaderValue<PaymentRegister>[] = [
  { title: 'Data', sortField: 'created' },
  { title: 'Nazwa operacji' },
  { title: 'Numer rezerwacji' },
  { title: 'Dodane przez' },
  { title: 'Kwota', sortField: 'amount' },
  { title: '' },
]

export const TransactionListModalTable = ({ paymentRegisters, isLoading, setFilters, filters }: Props): JSX.Element => {
  const paginatedRows = usePaginateRows<PaymentRegister>(paymentRegisters, filters.page, filters.page_size)

  return (
    <Table
      className="table-hover w-100"
      headerValues={headerValues}
      isLoading={isLoading}
      filters={filters}
      setFilters={setFilters}
    >
      {paginatedRows.map(paymentRegister => (
        <TransactionListModalTableRow key={paymentRegister.id} paymentRegister={paymentRegister} />
      ))}
      {paymentRegisters.length && <TransactionListModalTableFooter paymentRegisters={paymentRegisters} />}
    </Table>
  )
}
