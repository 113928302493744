import * as React from 'react'
import { Row } from 'reactstrap'
import { FormInput } from '@hyper/forms'
import { useGroupedSellers } from '@components/hooks/use-grouped-sellers'
import { FormSelect } from '@hyper/forms/form-select'

export const PackageWholesaleCreateModalFormPersonalData = (): JSX.Element => {
  const sellers = useGroupedSellers([])

  return (
    <Row>
      <FormInput label="Imię i nazwisko" colSize={6} name="name" />
      <FormInput label="Telefon" colSize={6} name="phone" />
      <FormInput label="Email" colSize={6} name="email" />
      <FormSelect
        options={sellers}
        name="sellers"
        selectProps={{ isSelectMulti: true, isClearable: true }}
        label="Sprzedawca"
      />
    </Row>
  )
}
