import * as React from 'react'
import { Button } from 'reactstrap'
import classNames from 'classnames'

interface LoadMoreListProps<T> {
  limit?: number
  className?: string
  options: T[]
  children: (row: T) => React.ReactElement
  emptyListLabel?: string
}

export const LoadMoreList = <T,>({
  limit = 5,
  options = [],
  children,
  className,
  emptyListLabel = 'Lista jest pusta',
}: LoadMoreListProps<T>) => {
  const [offset, setOffset] = React.useState(limit)

  const scrollToRef = React.useRef<HTMLDivElement>(null)
  const elementsToShow = React.useMemo(() => options.slice(0, offset), [options, offset, limit])
  const leftToBeShown = Math.max(options.length - elementsToShow.length, 0)

  const handleShowMore = () => {
    if (leftToBeShown <= 0) return
    setOffset(offset + limit)
  }

  React.useEffect(() => {
    if (offset === limit) return
    scrollToRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }, [leftToBeShown])

  return (
    <div className={classNames('overflow-auto light-scrollbar mr-n1 pr-1', className)}>
      {elementsToShow && !!elementsToShow.length ? (
        elementsToShow.map(row => children(row))
      ) : (
        <p>{emptyListLabel || 'Lista jest pusta'}</p>
      )}
      {!!leftToBeShown && (
        <Button
          color="btn-link"
          size="btn-sm"
          className="p-0 ml-1 mb-1"
          onClick={handleShowMore}
          role="load-more-list-button"
        >
          Pokaż kolejne ({leftToBeShown})
        </Button>
      )}
      <div ref={scrollToRef} />
    </div>
  )
}
