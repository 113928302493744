import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import dateHelper, { formatDate, parseISODate } from '@helpers/date-helper'
import { setSelectedCols } from '@store/actions/timeline-actions'
import { compareAsc } from 'date-fns'
import { Apartment } from '@models/apartment'

interface Response {
  selectedCols: string[]
  selectedRows: Apartment[]
  updateSelectedCols: (day: number, month: number, year: number) => void
  isColSelected: (day: number, month: number, year: number) => boolean
}

export const useTimelineSelection = (): Response => {
  const selectedCols = useAppSelector((state: RootState) => state.timelineState.selectedCols)
  const selectedRows = useAppSelector((state: RootState) => state.timelineState.selectedRows)

  const dispatch = useAppDispatch()

  const updateSelectedCols = (day: number, month: number, year: number) => {
    const newDate = formatDate(new Date(year, month - 1, day))

    if (selectedCols.includes(newDate)) {
      dispatch(setSelectedCols([]))
      return
    }

    if (selectedCols.length === 0) {
      dispatch(setSelectedCols([...selectedCols, newDate]))
    } else if (selectedCols.length === 1) {
      const sortedDates = [selectedCols[0], newDate].sort((a, b) =>
        compareAsc(parseISODate(a) as Date, parseISODate(b) as Date),
      )
      dispatch(setSelectedCols(dateHelper.getEachDayFromRange(sortedDates[0], sortedDates[1])))
    }
  }

  const isColSelected = (day: number, month: number, year: number) =>
    selectedCols.includes(formatDate(new Date(year, month - 1, day)))

  return {
    selectedCols,
    selectedRows,
    updateSelectedCols,
    isColSelected,
  }
}
