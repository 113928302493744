import * as React from 'react'
import { CSSProperties } from 'react'
import classNames from 'classnames'
import timelineDragHelper from '@helpers/timeline-drag-helper'
import timelineHelper from '@helpers/timeline-helper'
import { XYCoord } from 'react-dnd'
import store, { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { CalendarDate } from '@store/reducers/timeline-reducers'
import { TimelineItemDragObject } from '@modules/reservations/timeline/timeline-content-droppable'
import { Apartment } from '@models/apartment'
import { setInitialDragData } from '@store/actions/timeline-actions'

interface Props {
  offsetDiff: XYCoord
  item: TimelineItemDragObject
  apartments: Apartment[]
}

const TimelineItemBasicDragger: React.FC<Props> = props => {
  const dispatch = useAppDispatch()
  const [initialScroll, setInitialScroll] = React.useState<XYCoord>({ x: 0, y: 0 })
  const [initialDates, setInitialDates] = React.useState<CalendarDate[]>([])
  const renderedDates = useAppSelector((state: RootState) => state.timelineState.calendarDates.dates)
  const isCompactView = useAppSelector((state: RootState) => state.timelineState.isCompactView)

  React.useEffect(() => {
    dispatch(
      setInitialDragData({
        ...initialScroll,
        renderedDates: initialDates,
      }),
    )
  }, [initialDates, initialScroll])

  React.useEffect(() => {
    setInitialScroll({
      y: window.scrollY,
      x: store.getState().timelineState.scrollPosition,
    })
    setInitialDates(renderedDates)
  }, [])

  if (!initialDates.length) {
    return null
  }

  const initialItemPositionLeft = timelineDragHelper.getInitialItemPosition(
    props.item.booking.apartment,
    props.apartments,
    initialDates,
    props.item.booking.date_from,
  )

  const getBookingStyle = (): CSSProperties => {
    const bookingCord = timelineDragHelper.getBookingCord(props.offsetDiff, initialItemPositionLeft, initialScroll)
    return {
      ...timelineDragHelper.getBookingStyle(bookingCord),
      width: timelineHelper.getItemWidth(props.item.booking.days_count),
    }
  }

  return (
    <div
      className={classNames('calendar-content__item is-dragged', { 'is-compact': isCompactView })}
      style={getBookingStyle()}
    />
  )
}

export default TimelineItemBasicDragger
