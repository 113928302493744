import * as React from 'react'

interface StepGuestsCarsListAddCarButtonProps {
  maxCars: number
  carsAmount: number
  handleAddCar: () => void
}

export const StepGuestsCarsListAddCarButton: React.FC<StepGuestsCarsListAddCarButtonProps> = ({
  carsAmount,
  maxCars,
  handleAddCar,
}) => {
  if (carsAmount >= maxCars) {
    return null
  }

  return (
    <div className="d-flex justify-content-between align-items-center ml-auto">
      <div className="mr-2 font-10">
        Dostępne {carsAmount} z {maxCars}
      </div>
      <button className="btn btn-outline-light btn-sm" onClick={handleAddCar} role="cars-add">
        <i className="uil-plus mr-1" />
        Dodaj auto
      </button>
    </div>
  )
}
