import * as React from 'react'
import Table from '@components/table/table'
import { CouponClient } from '@modules/coupons/models'
import { Button } from 'reactstrap'
import { formatPriceShort } from '@helpers/utils'
import { IconWithText } from '@components/icon-with-text'
import { Link } from 'react-router-dom'
import { NavigationPath } from '@models/routes'

interface Props {
  couponClient: CouponClient
}

export const CouponsClientsTableRow = ({ couponClient }: Props): JSX.Element => (
  <Table.Row>
    <Table.Cell className="align-middle">
      <div>
        <span className="d-block">{couponClient.name}</span>
        <small>{couponClient.email}</small>
      </div>
    </Table.Cell>
    <Table.Cell className="align-middle">{couponClient.count}</Table.Cell>
    <Table.Cell className="align-middle">{formatPriceShort(couponClient.value_total)}</Table.Cell>
    <Table.Cell className="align-middle">{formatPriceShort(couponClient.value_unused_total)}</Table.Cell>
    <Table.Cell style={{ width: 230 }}>
      <Link
        to={NavigationPath.CouponsClient.replace(':client_id', couponClient.client_id.toString())}
        className="d-flex justify-content-end"
        state={{ clientName: couponClient.name }}
      >
        <Button color="light" title="Szczegółowa lista kuponów klienta" className="py-1">
          <IconWithText
            icon="uil-angle-right font-16 lh-1 ml-2"
            text="Lista szczegółowa"
            wrapperClassNames="flex-row-reverse"
          />
        </Button>
      </Link>
    </Table.Cell>
  </Table.Row>
)
