import { ErliPromocode } from '@modules/promotions/erli-promocode/models'
import { PromotionsAppData } from '@store/reducers/promotions-reducers'

export enum PromotionsActionTypesKeys {
  SET_ERLI_PROMOCODES = 'SET_ERLI_PROMOCODES',
  SET_PROMOTIONS_APP_DATA = 'SET_PROMOTIONS_APP_DATA',
}

export interface SetPromotionsAppDataAction {
  type: PromotionsActionTypesKeys.SET_PROMOTIONS_APP_DATA
  payload: PromotionsAppData
}

export interface SetErliPromocodesAction {
  type: PromotionsActionTypesKeys.SET_ERLI_PROMOCODES
  payload: ErliPromocode[] | undefined
}

export type PromotionsActionsTypes = SetErliPromocodesAction | SetPromotionsAppDataAction
