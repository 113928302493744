import * as React from 'react'
import { Card, CardBody, Col, Label, Row } from 'reactstrap'
import { StatsSaleItemDashboardFiltersParams } from '@modules/reports/models'
import { useForm } from 'react-hook-form'
import DateRangeInput from '@components/date/date-range-input'
import { SubmitFilter } from '@modules/reception/common/filters/submit-filter'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { Form } from '@hyper/forms/form'

interface StatsSaleItemDashboardFiltersProps {
  defaultFilters: StatsSaleItemDashboardFiltersParams
  filters: StatsSaleItemDashboardFiltersParams
  setFilters: (filters: StatsSaleItemDashboardFiltersParams) => void
}

interface FormInputs {
  sell_date_after: Date | undefined
  sell_date_before: Date | undefined
}

export const StatsSaleItemDashboardFilters: React.FC<StatsSaleItemDashboardFiltersProps> = ({
  defaultFilters,
  setFilters,
  filters,
}) => {
  const methods = useForm<FormInputs>({
    defaultValues: filters,
  })

  const handleReset = () => {
    methods.reset()
    setFilters(defaultFilters)
  }

  const onSubmit = async (payload: FormInputs) => setFilters(payload)

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  return (
    <Row>
      <Col md={12}>
        <Card>
          <CardBody>
            <Form methods={methods} onSubmit={onSubmit} className="form form-style-filter">
              <Row>
                <Col className="col-auto" style={{ minWidth: 320 }}>
                  <div className="d-flex">
                    <Label className="mr-1">Okres sprzedaży:</Label>
                  </div>

                  <DateRangeInput
                    wrapperClassName="flex-grow-1"
                    inputGroupClassName="form-group"
                    startDateName="sell_date_after"
                    endDateName="sell_date_before"
                  />
                </Col>
                <SubmitFilter handleReset={handleReset} />
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}
