import * as React from 'react'
import { paymentRegistersSelector } from '@store/slices/cash-slice'
import Table, { TableHeaderValue } from '@components/table/table'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { PaymentRegistersFiltersParams } from '@modules/payment-registers/list/filters'
import { PaymentRegister } from '@models/payments'
import { PaymentRegisterUserCashBoxTransactionsTableRow } from '@modules/payment-registers/user-cashbox/transactions/transactions-table-row'
import { PaymentRegisterUserCashboxTransactionsTableFooter } from '@modules/payment-registers/user-cashbox/transactions/transactions-table-footer'
import { formatDate } from '@helpers/date-helper'
import { useAppSelector } from '@store/index'

const headerValues: TableHeaderValue<PaymentRegister>[] = [
  { title: 'Godzina', sortField: 'created' },
  { title: 'Nazwa operacji' },
  { title: 'Typ' },
  { title: 'Numer rezerwacji' },
  { title: 'Kwota', sortField: 'amount' },
  { title: 'Status' },
]

interface Props {
  isLoading: boolean
  filters: PaymentRegistersFiltersParams
  setFilters: (filters: PaymentRegistersFiltersParams) => void
  date: Date
}

export const PaymentRegisterUserCashBoxTransactionsTable = ({
  isLoading,
  filters,
  setFilters,
  date,
}: Props): JSX.Element => {
  const user = useAuthenticatedUser()
  const dateStr = formatDate(date)
  const paymentRegisters = useAppSelector(paymentRegistersSelector).filter(
    row => row.user_id === user.id && formatDate(row.created) === dateStr,
  )

  return (
    <Table
      className="table-hover w-100"
      headerValues={headerValues}
      isLoading={isLoading}
      filters={filters}
      setFilters={setFilters}
    >
      {paymentRegisters.length ? (
        paymentRegisters.map(paymentRegister => (
          <PaymentRegisterUserCashBoxTransactionsTableRow key={paymentRegister.id} paymentRegister={paymentRegister} />
        ))
      ) : (
        <tr>
          <td className="text-center" colSpan={100}>
            Brak transakcji
          </td>
        </tr>
      )}
      <PaymentRegisterUserCashboxTransactionsTableFooter paymentRegisters={paymentRegisters} />
    </Table>
  )
}
