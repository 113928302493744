import * as React from 'react'
import { CartSelection } from '@modules/reception/cart/payment-box/reception-booking-cart-payment-box'
import { ReceptionBookingCartPaymentCash } from '@modules/reception/cart/payment-box/payment-options-content/reception-booking-cart-payment-cash'
import { ReceptionBookingCartPaymentGastroCard } from '@modules/reception/cart/payment-box/payment-options-content/reception-booking-cart-payment-gastro-card'
import { ReceptionBookingCartPaymentWallet } from '@modules/reception/cart/payment-box/payment-options-content/reception-booking-cart-payment-wallet'
import { ReceptionBookingCartUrls, ReceptionBookingDetailsCart } from '@models/reception'
import { CartPaymentMethodContext } from '@modules/reception/cart/payment-box/payment-options/reception-booking-cart-payment-methods'
import { CartPaymentMethod } from '@modules/reception/cart/models'
import { ReceptionBookingCartPaymentBand } from '@modules/reception/cart/payment-box/payment-options-content/reception-booking-cart-payment-band'
import { ReceptionBookingCartPaymentCard } from '@modules/reception/cart/payment-box/payment-options-content/reception-booking-cart-payment-card'

interface Props {
  selectedPayment: CartPaymentMethod
  onPaymentMethodChange: (value: null) => void
  cartSelection: CartSelection
  cartUrls: ReceptionBookingCartUrls
  onCartUpdate: (cart: ReceptionBookingDetailsCart) => void
  paymentContext: CartPaymentMethodContext | null
  bookingId: number
}

export const ReceptionBookingCartPayment = ({
  selectedPayment,
  onPaymentMethodChange,
  cartSelection,
  cartUrls,
  onCartUpdate,
  paymentContext,
  bookingId,
}: Props): JSX.Element => {
  const handleDecline = () => onPaymentMethodChange(null)

  if (selectedPayment === 'cash')
    return (
      <ReceptionBookingCartPaymentCash
        onDecline={handleDecline}
        cartSelection={cartSelection}
        url={cartUrls.payment_cash}
        onCartUpdate={onCartUpdate}
      />
    )

  if (selectedPayment === 'card')
    return (
      <ReceptionBookingCartPaymentCard
        onDecline={handleDecline}
        cartSelection={cartSelection}
        url={cartUrls.payment_card}
        onCartUpdate={onCartUpdate}
        bookingId={bookingId}
      />
    )

  if (selectedPayment === 'wallet')
    return (
      <ReceptionBookingCartPaymentWallet
        onDecline={handleDecline}
        cartSelection={cartSelection}
        url={cartUrls.payment_deposit}
        onCartUpdate={onCartUpdate}
      />
    )

  if (selectedPayment === 'band') {
    return (
      <ReceptionBookingCartPaymentBand
        onDecline={handleDecline}
        cartSelection={cartSelection}
        url={cartUrls.payment_deposit}
        onCartUpdate={onCartUpdate}
      />
    )
  }

  if (['gastro_card', 'gastro_coupon'].includes(selectedPayment) && paymentContext)
    return (
      <ReceptionBookingCartPaymentGastroCard
        cartSelection={cartSelection}
        onCartUpdate={onCartUpdate}
        onDecline={handleDecline}
        clientWallet={paymentContext}
        url={cartUrls.payment_wallet}
      />
    )

  return <span>{selectedPayment}</span>
}
