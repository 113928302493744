import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { CommonTableFilters } from '@components/table/common-table-filters'
import { RodoAgreementsAcquiringPointsFiltersFormInputs } from '@modules/crm/rodo-agreements/point-of-acquirng'
import { FormSelect } from '@hyper/forms/form-select'
import { RodoAgreementsAcquiringPointsAddButton } from '@modules/crm/rodo-agreements/point-of-acquirng/table/rodo-agreement-acquirings-points-add-button'

interface Props {
  filters: RodoAgreementsAcquiringPointsFiltersFormInputs
  defaultFilters: RodoAgreementsAcquiringPointsFiltersFormInputs
  setFilters: (filters: RodoAgreementsAcquiringPointsFiltersFormInputs) => void
}

export const RodoAgreementsAcquiringPointsFilters = ({ setFilters, defaultFilters, filters }: Props) => {
  const methods = useForm<RodoAgreementsAcquiringPointsFiltersFormInputs>({ defaultValues: filters })

  return (
    <FormProvider {...methods}>
      <div className="d-flex justify-content-between">
        <FormSelect
          options={StatusOptions}
          name="status"
          formPlainProps={{ colSize: 2, formGroupClassName: 'mb-0', colClassName: 'px-0' }}
          label="Status"
          selectProps={{ placeholder: 'Wszystkie', isClearable: true }}
        />
        <RodoAgreementsAcquiringPointsAddButton />
      </div>

      <CommonTableFilters
        filters={filters}
        defaultFilters={defaultFilters}
        setFilters={setFilters}
        searchWrapperParams={{ className: 'col-auto mt-1' }}
        searchParams={{ hideLabel: true, formGroupClassName: 'mb-1' }}
        clearParams={{ className: 'ml-auto mt-1' }}
        rowClassName="justify-content-end"
      />
    </FormProvider>
  )
}

const StatusOptions = [
  { value: 'active', label: 'Aktywne' },
  { value: 'inactive', label: 'Nieaktywne' },
]
