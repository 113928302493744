import * as React from 'react'
import { ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import { RodoAgreement } from '@modules/crm/rodo-agreements/models'
import { ButtonWithIcon } from '@components/button-with-icon'
import { SaveButton } from '@hyper/button'
import { IconWithText } from '@components/icon-with-text'
import { FormInput } from '@hyper/forms'
import { useForm } from 'react-hook-form'
import { useFormRequest } from '@components/hooks/use-api-request'
import { Form } from '@hyper/forms/form'
import { useUpdateRodoAgreementClientMutation } from '@api/rodo-agreements'

interface FormInputs {
  first_name: string
  last_name: string
  email: string
  phone: string
}

interface Props extends BaseModalProps {
  rodoAgreement: RodoAgreement
}

export const RodoAgreementsModalClientDataEdit: React.FC<Props> = ({ toggleIsVisible, rodoAgreement }) => {
  const methods = useForm({
    defaultValues: {
      first_name: rodoAgreement.first_name,
      last_name: rodoAgreement.last_name,
      email: rodoAgreement.email,
      phone: rodoAgreement.phone,
    },
  })

  const [updateData] = useUpdateRodoAgreementClientMutation()

  const { action: onSubmit, isLoading } = useFormRequest(async (data: FormInputs) => {
    await updateData({ url: rodoAgreement.urls.details, data }).unwrap()
    toggleIsVisible()
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader>
        Edycja klient {rodoAgreement.first_name} {rodoAgreement.last_name}
      </ModalHeader>
      <ModalBody>
        <Row>
          <FormInput name="first_name" label="Imię" />
          <FormInput name="last_name" label="Nazwisko" />
          <FormInput name="email" label="E-mail" disabled={!!rodoAgreement.email_confirm_date} />
          <FormInput name="phone" label="Telefon" disabled={!!rodoAgreement.phone_confirm_date} />
        </Row>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <ButtonWithIcon color="light" icon="uil-times font-16 lh-1" text="Anuluj" handleClick={toggleIsVisible} />
        <SaveButton
          className="btn btn-green"
          labelSaving="Proszę czekać..."
          label={<IconWithText text="Zapisz" icon="uil-check font-16" />}
          isSaving={isLoading}
        />
      </ModalFooter>
    </Form>
  )
}
