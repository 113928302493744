import * as React from 'react'
import classNames from 'classnames'

interface Props {
  title: string
  value: React.ReactElement
  className?: string
}

export const ReceptionBookingCartPaymentInfoRow = ({ title, value, className }: Props): JSX.Element => (
  <div className={classNames('d-flex justify-content-between', className)}>
    <span className="font-11">{title}</span>
    <span className="font-12">{value}</span>
  </div>
)
