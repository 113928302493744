import * as React from 'react'
import { HousekeepingCleaningOrderGroupAssignModalFormInputs } from '@modules/housekeeping/cleaning-orders/to-order/group-assign-modal'
import { FormPlain } from '@hyper/forms'
import { useFormContext, useWatch } from 'react-hook-form'
import { FormSelect } from '@hyper/forms/form-select'
import { HousekeepingCompanyDetails } from '@modules/housekeeping/models'
import { createSelectOption, getById } from '@helpers/utils'
import { FormCheckbox } from '@hyper/forms/form-checkbox'
import { hasHousekeepingWorkerRoles, HousekeepingWorkerRoles } from '@modules/housekeeping/users/utils'
import { useHousekeepingCleaningCompanies } from '@modules/housekeeping/users/use-housekeeping-cleaning-companies'

interface Props {
  onlyHprCompany?: boolean
  label?: string
  resortId: number
  canSelectMultipleWorkers?: boolean
  workerRoles?: HousekeepingWorkerRoles[]
}

export const FormHousekeepingOrderWorkerAssignField: React.FC<Props> = ({
  onlyHprCompany,
  resortId,
  label,
  canSelectMultipleWorkers = true,
  workerRoles = [],
}) => {
  const { clearErrors, control } = useFormContext<HousekeepingCleaningOrderGroupAssignModalFormInputs>()

  const selectedCompany = useWatch({ control, name: 'company' })
  const users = useWatch({ control, name: 'users' })

  React.useEffect(() => {
    clearErrors('users')
  }, [selectedCompany, users])

  const housekeeping_companies = useHousekeepingCleaningCompanies(resortId)
  const companies = housekeeping_companies.filter(row => (onlyHprCompany ? row.is_hpr_company : true))
  const company = getById(companies, selectedCompany?.value)

  const companyOptions = companies.map((company: HousekeepingCompanyDetails) =>
    createSelectOption(company.name, company.id),
  )

  const workerOptions = (company?.workers || []).reduce((options, worker) => {
    const option = createSelectOption(worker.name, worker.id)
    if (!workerRoles.length) return [...options, option]

    return hasHousekeepingWorkerRoles(worker, workerRoles) ? [...options, option] : options
  }, [])

  return (
    <>
      <FormSelect options={companyOptions} name="company" label={label} formPlainProps={{ colClassName: 'px-0' }} />
      {company && (
        <FormPlain name="users" colClassName="px-0">
          {company.workers.length ? (
            <p className="font-weight-bold mb-1">Wybierz pracowników</p>
          ) : (
            <p>Brak pracowników w wybranej firmie.</p>
          )}
          {!canSelectMultipleWorkers && !!company.workers.length ? (
            <FormSelect options={workerOptions} name="users.0" formPlainProps={{ colClassName: 'px-0' }} />
          ) : (
            company.workers.map((worker, index) => (
              <div key={worker.id} className="mb-1">
                <FormCheckbox
                  name={`users.${index}`}
                  id={`worker-${worker.id}`}
                  label={worker.name}
                  value={worker.id}
                />
              </div>
            ))
          )}
        </FormPlain>
      )}
    </>
  )
}
