import React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { useFormContext } from 'react-hook-form'
import { ShopPOSWithParamsFormInputs } from '@modules/shop/pos/shop-pos-with-params'
import { NavigationPath } from '@models/routes'
import { useNavigate } from 'react-router-dom'
import { clearShopReceipt } from '@store/slices/shop-slice'
import { useAppDispatch } from '@store/index'
import classNames from 'classnames'

interface PurchasePaymentSuccessBoxProps {
  confirmationUrl: string
  documentsPrintUrl: string
  resortId: string
}

export const PurchasePaymentSuccessBox: React.FC<PurchasePaymentSuccessBoxProps> = ({
  confirmationUrl,
  documentsPrintUrl,
  resortId,
}) => {
  const { reset } = useFormContext<ShopPOSWithParamsFormInputs>()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleReset = () => {
    dispatch(clearShopReceipt())
    navigate(NavigationPath.ShopPOSWithParams.replace(':resort_id', resortId))
    reset()
  }

  React.useEffect(() => {
    if (confirmationUrl) window.open(confirmationUrl, '_blank')
  }, [confirmationUrl])

  return (
    <div className="text-center">
      <div className="text-success font-weight-bold border-t">
        <i className=" uil-thumbs-up font-24" />
        <p className="my-1 font-14">
          Produkt / usługa została <br />
          opłacona w całości
        </p>
      </div>
      {(confirmationUrl || documentsPrintUrl) && (
        <>
          <p className="my-3 font-weight-bold col-9 mx-auto">
            Wydrukuj potwierdzenie zakupu {documentsPrintUrl ? 'wraz z dodatkowymi dokumentami.' : ''}
          </p>
          <div className="col-8 mx-auto">
            {confirmationUrl && <DocumentLink url={confirmationUrl} title="Drukuj potwierdzenie" color="btn-green" />}
            {documentsPrintUrl && <DocumentLink url={documentsPrintUrl} title="Drukuj dokumenty" color="btn-primary" />}
          </div>
        </>
      )}

      <p className="mt-3 text-center">
        <button className="btn btn-sm btn-secondary" onClick={handleReset}>
          Nowe zakupy
        </button>
      </p>
    </div>
  )
}

const DocumentLink = ({ title, url, color }) => (
  <a
    href={url}
    target="_blank"
    className={classNames('btn mb-2 w-100 btn-tall d-flex align-items-center justify-content-center', color)}
  >
    <IconWithText icon="uil-print" text={title} />
  </a>
)
