import * as React from 'react'
import { FormPlain } from '@hyper/forms'
import { Col } from 'reactstrap'
import { FileUploader, UploaderAcceptableFile } from '@components/file-uploader'
import { PackageWholesaleProductDetails } from '@modules/package-wholesale/models'
import { useFormContext } from 'react-hook-form'
import { PackageWholesaleProductFormInputs } from '@modules/package-wholesale/products/create/modal'

interface Props {
  isLoading: boolean
  image: File | undefined
  setImage: (image: File | undefined) => void
  packageWholesaleProductDetails?: PackageWholesaleProductDetails
}

export const PackageWholesaleProductCreateFormImage = ({
  isLoading,
  setImage,
  image,
  packageWholesaleProductDetails,
}: Props): JSX.Element => {
  const { clearErrors } = useFormContext<PackageWholesaleProductFormInputs>()

  const upload = file => {
    setImage(file)
    clearErrors()
  }

  return (
    <>
      {(image || packageWholesaleProductDetails?.image) && (
        <FormPlain name="image">
          <label>Aktualne zdjęcie:</label>
          <div className="mt-1">
            <img
              src={image ? URL.createObjectURL(image) : packageWholesaleProductDetails?.image}
              className="avatar-xl img-thumbnail"
            />
          </div>
        </FormPlain>
      )}
      <Col md={12} className="mb-3">
        <FileUploader
          isLoading={isLoading}
          upload={upload}
          maxFiles={1}
          file={image}
          accept={UploaderAcceptableFile.Images}
          label="Przeciągnij i upuść zdjęcie lub kliknij by dodać zdjęcie"
        />
      </Col>
    </>
  )
}
