import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { TechnicalOrder } from '@models/technical-orders'
import { Form } from '@hyper/forms/form'
import { SaveButton } from '@hyper/button'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { useHandleRtkQueryError } from '@components/hooks/use-handle-rtk-error'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useForm } from 'react-hook-form'
import { useCreateOrUpdateTechnicalOrderMutation } from '@api/technical-orders'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { TechnicalOrderAssignForm } from '@modules/technical-orders/technical-order-assign-modal/form'
import * as R from 'ramda'
import { TechnicalOrderFormOptions } from '@modules/technical-orders/technical-order-modal/form-options'
import { ModalBodyHr } from '@components/modals/modal-body-hr'
import { getDefaultSelectedUsers, getSelectedUsersPayload } from '@modules/technical-orders/helpers'
import { parseISODate } from '@helpers/date-helper'

interface Props extends BaseModalProps {
  technicalOrders: TechnicalOrder[]
  onlyAssign?: boolean
}

interface FormInputs {
  company: CustomReactSelectOption | undefined
  users: { [key: string]: boolean }
  priority: boolean
  is_supervisor_acceptance_required: boolean
  enter_where_guest_absence: boolean
  forwarded_to_technical_order_manager: boolean
  forwarded_to_resort: boolean
}

export const TechnicalOrderAssignModal: React.FC<Props> = ({ technicalOrders, onlyAssign, toggleIsVisible }) => {
  const technicalOrderDetails = technicalOrders.length === 1 ? technicalOrders[0] : undefined

  const methods = useForm<FormInputs>({
    defaultValues: {
      enter_where_guest_absence: false,
      is_supervisor_acceptance_required: false,
      technicalOrderEnterWhereGuestAbsenceRange:
        !!technicalOrderDetails?.technical_order_enter_where_guest_absence_range.length,
      ...R.pick<FormInputs, keyof FormInputs>(
        [
          'enter_where_guest_absence',
          'is_supervisor_acceptance_required',
          'forwarded_to_resort',
          'forwarded_to_technical_order_manager',
        ],
        {
          ...technicalOrderDetails,
        },
      ),
      technical_order_enter_where_guest_absence_range:
        technicalOrderDetails?.technical_order_enter_where_guest_absence_range.map(row => ({
          ...row,
          date: parseISODate(row.date),
        })),
      users: getDefaultSelectedUsers(technicalOrderDetails),
      priority: technicalOrderDetails?.priority === 10,
    },
  })

  const { addSuccessNotification } = useNotificationHook()

  const [updateTechnicalOrder, { error, isError, isLoading }] = useCreateOrUpdateTechnicalOrderMutation()

  const onSubmit = async (payload: FormInputs) => {
    const users = getSelectedUsersPayload(payload.users)

    for (const technicalOrder of technicalOrders) {
      await updateTechnicalOrder({
        data:
          technicalOrderDetails && onlyAssign
            ? { users, company: payload.company?.value }
            : { ...payload, priority: payload.priority ? 10 : 0, users },
        partial: !technicalOrderDetails,
        url: technicalOrder.urls.assign,
      }).unwrap()
    }

    addSuccessNotification('Zmiany zostały zapisane pomyślnie!')
    toggleIsVisible()
  }

  useHandleRtkQueryError(error, isError, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>
        {technicalOrderDetails ? `Przydziel zadanie (${technicalOrderDetails.number})` : 'Przydziel zadania'}
      </ModalHeader>
      <ModalBody>
        <TechnicalOrderAssignForm
          resortId={technicalOrderDetails?.resort_id || technicalOrders[0]?.resort_id || 0}
          initialCompany={technicalOrderDetails?.company_id || technicalOrders[0]?.company_id || 0}
        />
      </ModalBody>
      {!!technicalOrderDetails && !onlyAssign && (
        <ModalBody className="bg-grey pt-0 pb-1">
          <ModalBodyHr colClassName="mt-0 pb-2 px-0" />
          <TechnicalOrderFormOptions showIsSupervisorAcceptanceRequired={true} />
        </ModalBody>
      )}
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" label="Zleć zadanie" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
