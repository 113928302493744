import * as React from 'react'
import Table, { TableHeaderValue } from '@components/table/table'
import { CmsImprovement } from '@modules/cms/models'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useApiRequest } from '@components/hooks/use-api-request'
import { CmsReservationAppImprovementsRow } from '@modules/cms/reservation-app/improvements-row'
import { useCmsAppData } from '@modules/cms/hooks/use-cms-app-data'
import { useFilteredRows } from '@components/table/use-filtered-rows'
import { useSortedRows } from '@components/table/use-sorted-rows'
import {
  CmsReservationAppImprovementsFilters,
  CmsReservationAppImprovementsFiltersParams,
} from '@modules/cms/reservation-app/improvements-filters'
import { useDebounce, useTimeoutWhen } from 'rooks'
import { cmsResortImprovementsSelector, setCmsResortImprovements } from '@store/slices/cms-reservation-app-slice'
import { getCmsResortImprovements } from '@store/actions/cms-reservation-app-actions'
import { commonObjectPost } from '@store/actions/generic-actions'
import { reOrderArray } from '@helpers/utils'
import { DraggableTableWrapper } from '@components/table/draggable-table-wrapper'

const headerValues: TableHeaderValue<CmsImprovement>[] = [
  {
    title: 'Nazwa',
    search: true,
    sortField: 'name',
  },
  {
    title: 'Ośrodek',
  },
  {
    title: 'Grupa',
  },
  { title: '' },
]

const defaultFilters = {
  is_feeding: '',
  ordering: '-position',
  resort: '',
  page: 0,
  page_size: 0,
  search: '',
}
export const CmsReservationAppImprovements: React.FC = () => {
  const [isPositionUpdating, setPositionUpdating] = React.useState(false)
  const positionUrl = useAppSelector((state: RootState) => state.appState.appData.urls.cms.improvements_position)

  useTimeoutWhen(() => setPositionUpdating(false), 300, isPositionUpdating)
  const [filters, setFilters] = React.useState<CmsReservationAppImprovementsFiltersParams>(defaultFilters)
  const improvements = useAppSelector(cmsResortImprovementsSelector)
  const dispatch = useAppDispatch()

  const { isLoading, action: fetch } = useApiRequest(async filters => await dispatch(getCmsResortImprovements(filters)))

  const fetchDebounced = React.useCallback(useDebounce(fetch, 300), [])
  React.useEffect(() => {
    fetchDebounced(filters)
  }, [filters])

  const { improvement_groups } = useCmsAppData()

  const filteredImprovements = useFilteredRows<CmsImprovement>(improvements, headerValues, String(filters.search))
  const orderedImprovements = useSortedRows<CmsImprovement>(filteredImprovements, filters.ordering)

  const { action: handleDrop } = useApiRequest(async data => {
    if (!filters.resort) {
      return false
    }
    setPositionUpdating(true)
    const source = data.source.index
    const destination = data.destination.index

    dispatch(
      setCmsResortImprovements(
        await commonObjectPost<CmsImprovement[]>(
          positionUrl,
          reOrderArray(improvements, source, destination)
            .map((node, index) => ({ ...node, position: index }))
            .reduce((nodes, node) => ({ ...nodes, [node.id]: node.position }), {}),
        ),
      ),
    )
  })

  return (
    <DraggableTableWrapper
      dragDropContextProps={{ onDragEnd: handleDrop }}
      droppableProps={{ droppableId: 'droppable-page-nodes' }}
    >
      <CmsReservationAppImprovementsFilters filters={filters} setFilters={setFilters} defaultFilters={defaultFilters} />
      <Table
        showPagination={false}
        filters={filters}
        setFilters={setFilters}
        headerValues={headerValues}
        isLoading={isLoading || isPositionUpdating}
      >
        {orderedImprovements.map((row, index) => (
          <CmsReservationAppImprovementsRow
            index={index}
            improvement_groups={improvement_groups}
            key={row.id}
            improvement={row}
          />
        ))}
      </Table>
    </DraggableTableWrapper>
  )
}
