import * as React from 'react'
import { SubscriptionDetails } from '@modules/subscription/models'
import { Col, Row } from 'reactstrap'
import { SubscriptionDetailsInformationBase } from '@modules/subscription/details/information/base'
import { SubscriptionDetailsNotes } from '@modules/subscription/details/notes'
import { createProductCardClient, ProductClientCard } from '@components/products/product-client-card'
import { SubscriptionDetailsInformationClient } from '@modules/subscription/details/information/client'
import { createInvoiceData, ProductInvoiceCard } from '@components/products/product-invoice-card'
import { SubscriptionDetailsInformationOptions } from '@modules/subscription/details/options'

interface Props {
  subscriptionDetails: SubscriptionDetails
}

export const SubscriptionDetailsInformation = React.forwardRef<HTMLDivElement, Props>(
  ({ subscriptionDetails }, ref) => {
    const additionalClients = subscriptionDetails.clients.filter(client => client.id !== subscriptionDetails.client_id)
    return (
      <div ref={ref}>
        <Row>
          <Col md={6}>
            <SubscriptionDetailsInformationBase subscriptionDetails={subscriptionDetails} />
            {subscriptionDetails.type !== 'BOOKING_IMPROVEMENT' && (
              <SubscriptionDetailsInformationOptions subscriptionDetails={subscriptionDetails} />
            )}
          </Col>
          <Col md={6}>
            {subscriptionDetails.client ? (
              <>
                <ProductClientCard
                  clientData={createProductCardClient(subscriptionDetails.client)}
                  canViewClientDetails={!!subscriptionDetails.client_id && !subscriptionDetails.read_only}
                />

                {!!additionalClients.length && (
                  <ProductClientCard
                    label="Dane dodatkowego klienta"
                    clientData={createProductCardClient(additionalClients[0])}
                    canViewClientDetails={false}
                  />
                )}
              </>
            ) : (
              <SubscriptionDetailsInformationClient subscriptionDetails={subscriptionDetails} />
            )}
            <ProductInvoiceCard invoiceData={createInvoiceData(subscriptionDetails)} />
            <SubscriptionDetailsNotes subscriptionDetails={subscriptionDetails} />
          </Col>
        </Row>
      </div>
    )
  },
)
