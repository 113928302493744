import * as React from 'react'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useDispatch } from 'react-redux'
import { ButtonWithIcon } from '@components/button-with-icon'
import { BaseModalProps } from '@components/modals/types'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { AgreementDetails } from '@modules/hr/agreement/models'
import { clearAgreementDetails, removeAgreement } from '@store/slices/hr-slice'

interface Props extends BaseModalProps {
  agreementDetails: AgreementDetails
}

export const AgreementDetailsDeleteButton = ({ agreementDetails, toggleIsVisible }: Props): JSX.Element => {
  const { addSuccessNotification } = useNotificationHook()
  const dispatch = useDispatch()

  const { isLoading, action: handleRemove } = useApiRequest(async (reason: string) => {
    await commonObjectDelete<AgreementDetails>(agreementDetails.urls.details, { reason })
    dispatch(clearAgreementDetails())
    dispatch(removeAgreement(agreementDetails.id))
    addSuccessNotification('Umowa została skasowana')
    toggleIsVisible()
  })

  return (
    <CommonObjectConfirmAction isLoading={isLoading} handleAccept={handleRemove} askForReason>
      <ButtonWithIcon icon="uil-trash-alt" color="outline-danger" text="Usuń" />
    </CommonObjectConfirmAction>
  )
}
