import * as React from 'react'
import { Card, CardBody } from 'reactstrap'
import { IconWithText } from '@components/icon-with-text'
import { useTabs } from '@components/tabs'
import { EmailNotification, NotificationKind, NotificationTemplate, SMSNotification } from '@models/notifications'
import { NotificationEmailMessages } from '@components/notification-box/notification-email-messages'
import { NotificationSmsMessages } from '@components/notification-box/notification-sms-messages'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { FormProvider, useForm } from 'react-hook-form'

interface FormInputs {
  email_template: CustomReactSelectOption | null
  sms_template: CustomReactSelectOption | null
}

const AvailableTabs = [
  { id: 'email' as const, name: 'Email' },
  { id: 'sms' as const, name: 'SMS' },
]

interface Props {
  emailNotifications?: EmailNotification[]
  smsNotifications?: SMSNotification[]
  emailTemplates?: NotificationTemplate[]
  smsTemplates?: NotificationTemplate[]
  emailUrl?: string
  smsUrl?: string
  onNotificationSent?: () => void
  smsDisabled?: boolean
  emailDisabled?: boolean
}

export const NotificationBox = ({
  emailNotifications,
  smsNotifications,
  emailTemplates,
  smsTemplates,
  emailUrl,
  smsUrl,
  onNotificationSent,
  smsDisabled,
  emailDisabled,
}: Props): React.ReactNode => {
  const withEmail = !!emailNotifications?.length || !!emailTemplates?.length || !!emailUrl
  const withSms = !!smsNotifications?.length || !!smsTemplates?.length || !!smsUrl

  const { Tabs, isTabActive } = useTabs<NotificationKind>(withEmail ? 'email' : 'sms')

  const methods = useForm<FormInputs>({ defaultValues: { email_template: null, sms_template: null } })

  const TabsToShow = AvailableTabs.filter(tab => {
    if (tab.id === 'email') return withEmail
    if (tab.id === 'sms') return withSms
    return false
  })

  const handleNotificationSend = () => {
    methods.reset()
    onNotificationSent?.()
  }

  return (
    <FormProvider {...methods}>
      <Card>
        <CardBody>
          <h4 className="mt-0 text-secondary mb-3">
            <IconWithText icon="uil-envelope-check font-20" text="Wysłane powiadomienia" textClass="fw-semi-bold" />
          </h4>
          <Tabs tabs={TabsToShow} />
          {isTabActive('email') && (
            <NotificationEmailMessages
              isDisabled={!!emailDisabled}
              onSend={handleNotificationSend}
              previewUrl={emailUrl}
              notificationTemplates={emailTemplates ?? []}
              notifications={emailNotifications ?? []}
            />
          )}

          {isTabActive('sms') && (
            <NotificationSmsMessages
              isDisabled={!!smsDisabled}
              onSend={handleNotificationSend}
              previewUrl={smsUrl}
              notificationTemplates={smsTemplates ?? []}
              notifications={smsNotifications ?? []}
            />
          )}
        </CardBody>
      </Card>
    </FormProvider>
  )
}
