import * as React from 'react'
import { useExpandableItems } from '@components/hooks/use-expandable-items'
import { Col, Row } from 'reactstrap'
import { ReceptionBookingGuest } from '@models/booking'
import { asDecimal, sumDecimalArray } from '@helpers/utils'
import { ReceptionBookingNewFeedingGuestRowCollapsable } from '@modules/reception/checkin/step-feeding/new-feeding/guest/reception-booking-new-feeding-guest-row-collapsable'
import declination from '@helpers/declination'
import {
  createDefaultNewFeedingFormValues,
  getCalculationDetailsWithoutEdgeMeals,
  getSelectedFeedings,
  getSelectedMealsAmount,
} from '@modules/reception/checkin/step-feeding/new-feeding/utils'
import { useFormContext, useWatch } from 'react-hook-form'
import {
  NewFeedingCalculationDetails,
  NewFeedingKind,
} from '@modules/reception/checkin/step-feeding/new-feeding/models'
import { ReceptionBookingDetails } from '@models/reception'
import { useAppSelector } from '@store/index'
import { selectExposedEdgeFeedings, selectIsDiscountPreviewActive } from '@store/slices/new-feeding-slice'
import { ControlledFormCheckbox } from '@hyper/forms/controlled-form-checkbox'
import { ReceptionBookingNewFeedingPrice } from '@modules/reception/checkin/step-feeding/new-feeding/reception-booking-new-price'

interface FullGuestFeedingState {
  dinner: boolean
  breakfast: boolean
  breakfastIndeterminate: boolean
  dinnerIndeterminate: boolean
}

interface Props {
  guest: ReceptionBookingGuest | undefined
  booking: ReceptionBookingDetails
  calculationDetails: NewFeedingCalculationDetails[]
  initialCalculationDetails: NewFeedingCalculationDetails[]
}

export const ReceptionBookingNewFeedingGuestRow = ({
  guest,
  calculationDetails,
  initialCalculationDetails,
  booking,
}: Props): JSX.Element | null => {
  const exposedEdgeFeedings = useAppSelector(selectExposedEdgeFeedings)
  const isDiscountPreviewActive = useAppSelector(selectIsDiscountPreviewActive)
  const { setValue, control } = useFormContext()

  const feedings = useWatch({ control })

  const { toggleExpanded, ExpandArrow, isExpanded } = useExpandableItems<number>()

  const [fullFeedingState, setFullFeedingState] = React.useState<FullGuestFeedingState>({
    breakfast: false,
    dinner: false,
    breakfastIndeterminate: false,
    dinnerIndeterminate: false,
  })

  const feedingsWithoutEdgeMeals = React.useCallback(
    (kind: NewFeedingKind) => getCalculationDetailsWithoutEdgeMeals(calculationDetails, exposedEdgeFeedings, kind),
    [calculationDetails, exposedEdgeFeedings],
  )

  const handleFullMealChange = (kind: NewFeedingKind) => () => {
    const feedings = feedingsWithoutEdgeMeals(kind).reduce(
      (previous, feeding) =>
        feeding.kind === kind && feeding.booking_guest === guest?.id && feeding.can_edit
          ? [...previous, { ...feeding, checked: !fullFeedingState[kind] }]
          : previous,
      [],
    )

    Object.entries(createDefaultNewFeedingFormValues(feedings)).forEach(([key, value]) => setValue(key, value))
  }

  const canSelectFullFeeding = React.useCallback(
    (kind: NewFeedingKind) => feedingsWithoutEdgeMeals(kind).some(feeding => feeding.can_edit && kind === feeding.kind),
    [calculationDetails, exposedEdgeFeedings],
  )

  const getFeedingStateForKind = (kind: NewFeedingKind) => {
    const selectedFeedings = getSelectedFeedings(feedings)
    const feedingsForKind = feedingsWithoutEdgeMeals(kind)

    const selectedAmounts = selectedFeedings.reduce((amount, selectedFeeding) => {
      if (selectedFeeding.kind !== kind) return amount

      const calculation = feedingsForKind.find(
        (feedingCalculation: NewFeedingCalculationDetails) =>
          feedingCalculation.booking_guest === selectedFeeding.booking_guest &&
          selectedFeeding.kind === feedingCalculation.kind,
      )

      return calculation ? (amount += 1) : amount
    }, 0)

    const allMealAmounts = feedingsForKind.filter(
      (feedingCalculation: NewFeedingCalculationDetails) =>
        feedingCalculation.booking_guest === guest?.id && kind === feedingCalculation.kind,
    ).length

    const isSelected = asDecimal(selectedAmounts).gt(0)

    return {
      kindSelection: isSelected && selectedAmounts === allMealAmounts,
      indeterminateSelection: isSelected && asDecimal(selectedAmounts).lt(allMealAmounts),
    }
  }

  React.useEffect(() => {
    const dinnerFeedingState = getFeedingStateForKind('dinner')
    const breakfastFeedingState = getFeedingStateForKind('breakfast')

    setFullFeedingState({
      dinner: dinnerFeedingState.kindSelection,
      breakfast: breakfastFeedingState.kindSelection,
      dinnerIndeterminate: dinnerFeedingState.indeterminateSelection,
      breakfastIndeterminate: breakfastFeedingState.indeterminateSelection,
    })
  }, [feedings, exposedEdgeFeedings, calculationDetails])

  if (!guest) return null

  const totalPrice = sumDecimalArray(
    calculationDetails.reduce(
      (calculations, calculation) =>
        calculation.checked
          ? [
              ...calculations,
              asDecimal(isDiscountPreviewActive ? calculation.discount_amount : calculation.price_brutto),
            ]
          : calculations,
      [],
    ),
  ).toString()

  const { breakfastsAmount, dinnerAmounts } = getSelectedMealsAmount(calculationDetails)

  return (
    <div className="bg-grey-light border mb-1 rounded">
      <div className="px-4 py-2">
        <Row className="d-flex align-items-center">
          <Col md={3}>
            <p className="text-dark mb-1 font-weight-bold">{guest.name}</p>
            <span>{guest.type_display}</span>
          </Col>
          <Col md={4} className="pl-0" style={{ maxWidth: '235px' }}>
            <ControlledFormCheckbox
              id={`full_breakfast_${guest.id}`}
              type="checkbox"
              label={<Label title="Śniadania" subtitle={`${breakfastsAmount} ${declination.meal(breakfastsAmount)}`} />}
              onChange={handleFullMealChange('breakfast')}
              checked={fullFeedingState.breakfast}
              disabled={!canSelectFullFeeding('breakfast')}
              indeterminate={fullFeedingState.breakfastIndeterminate}
            />
          </Col>
          <Col md={3} className="pl-0">
            <ControlledFormCheckbox
              id={`full_dinner_${guest.id}`}
              type="checkbox"
              label={<Label title="Obiadokolacje" subtitle={`${dinnerAmounts} ${declination.meal(dinnerAmounts)}`} />}
              onChange={handleFullMealChange('dinner')}
              checked={fullFeedingState.dinner}
              disabled={!canSelectFullFeeding('dinner')}
              indeterminate={fullFeedingState.dinnerIndeterminate}
            />
          </Col>
          <Col md={2} className="text-right d-flex align-items-center justify-content-end">
            <strong className="text-danger mr-3">
              <ReceptionBookingNewFeedingPrice price={totalPrice} />
            </strong>
            <ExpandArrow isExpanded={isExpanded(guest.id)} onExpand={toggleExpanded(guest.id)} />
          </Col>
        </Row>
      </div>
      <ReceptionBookingNewFeedingGuestRowCollapsable
        guest={guest}
        booking={booking}
        isExpanded={isExpanded(guest.id)}
        calculationDetails={calculationDetails}
        initialCalculationDetails={initialCalculationDetails}
      />
    </div>
  )
}

const Label = ({ title, subtitle }) => (
  <div>
    <p className="text-dark mb-0 font-weight-bold">{title}</p>
    <span className="font-weight-normal font-12">{subtitle}</span>
  </div>
)
