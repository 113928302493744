import * as React from 'react'
import { Button, Label, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import { SaveButton } from '@hyper/button'
import { Agreement, AnnexTypeId } from '@modules/hr/agreement/models'
import { Form } from '@hyper/forms/form'
import { useForm, useWatch } from 'react-hook-form'
import { AgreementHourlyRateInput } from '@modules/hr/agreement/create/steps/job-data/hourly-rate-input'
import { asDecimal, getById } from '@helpers/utils'
import { useHrAppData } from '@modules/hr/use-hr-app-data'
import { FormInput, FormPlain } from '@hyper/forms'
import DatePickerInput from '@components/date/date-picker-input'
import { addMonths, min, startOfMonth, startOfToday } from 'date-fns'
import { useCreateAgreementAnnexMutation } from '@api/agreements'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAgreementDetails } from '@modules/hr/agreement/details/use-agreement-details'
import { useHandleRtkQueryError } from '@components/hooks/use-handle-rtk-error'
import { getMaximumAgreementAnnexDate } from '@modules/hr/agreement/annexes/helpers'

interface FormInputs {
  hourly_rate: string
  responsibilities: string
  effective_date: Date
}

interface Props extends BaseModalProps {
  agreement: Agreement
}

export const AgreementHourlyRateAnnexModal = ({ toggleIsVisible, agreement }: Props): JSX.Element => {
  const { refresh } = useAgreementDetails({ agreement, withClear: false, immediateFetch: false })

  const { job_positions } = useHrAppData()
  const position = getById(job_positions, agreement.job_position)
  const { addSuccessMessage } = useNotificationHook()

  const methods = useForm<FormInputs>({
    defaultValues: {
      hourly_rate: agreement.hourly_rate,
      responsibilities: position?.description || '',
      effective_date: min([getMaximumAgreementAnnexDate(agreement), startOfMonth(addMonths(startOfToday(), 1))]),
    },
  })
  const [createAgreementAnnex, { isLoading, error, isError }] = useCreateAgreementAnnexMutation()

  useHandleRtkQueryError(error, isError, methods.setError)

  const [hourlyRate, responsibilities] = useWatch({
    control: methods.control,
    name: ['hourly_rate', 'responsibilities'],
  })

  const earningsChanged = !asDecimal(hourlyRate).eq(methods.formState.defaultValues?.hourly_rate ?? 0)
  const responsibilitiesChanged = responsibilities !== methods.formState.defaultValues?.responsibilities

  const onSubmit = async (payload: FormInputs) => {
    let annexType: AnnexTypeId | null = null

    if (earningsChanged) annexType = 'earning_change' as const
    if (responsibilitiesChanged) annexType = 'task_change' as const
    if (earningsChanged && responsibilitiesChanged) annexType = 'task_and_earning_change' as const

    if (!annexType) return

    await createAgreementAnnex({
      url: agreement.urls.create_annex,
      payload: { ...payload, document_type: annexType, agreement: agreement.id },
    }).unwrap()

    refresh()
    addSuccessMessage('Sukces', 'Aneks został dodany.')

    toggleIsVisible()
  }

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Aneks do umowy nr. {agreement.number}</ModalHeader>
      <ModalBody className="rounded">
        <FormPlain colClassName="p-0" name="effective_date">
          <Label>Data wejścia zmiany w życie</Label>
          <DatePickerInput
            name="effective_date"
            minDate={startOfToday()}
            maxDate={getMaximumAgreementAnnexDate(agreement)}
          />
        </FormPlain>
        <AgreementHourlyRateInput position={position} colClassName="col-12 px-0" />
        <FormInput
          name="responsibilities"
          label="Zakres obowiązków"
          type="textarea"
          placeholder="Zakres stanowiska, nowe obowiązki, itp."
          colClassName="mt-2 px-0"
          inputProps={{ cols: 5 }}
        />
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <Button color="light" onClick={toggleIsVisible}>
          Anuluj
        </Button>
        <SaveButton
          isSaving={isLoading}
          className="btn btn-green"
          label="Zapisz"
          disabled={!earningsChanged && !responsibilitiesChanged}
        />
      </ModalFooter>
    </Form>
  )
}
