import * as React from 'react'
import { ReceptionBookingCheckInStepDocumentsPrintsButton } from '@modules/reception/checkin/step-documents/step-documents-print-button'
import { hasCars } from '@modules/reception/common/tools'
import { ReceptionBookingCheckInDocument, ReceptionBookingDetails } from '@models/reception'
import { useFormContext, useWatch } from 'react-hook-form'
import { ReceptionBookingCheckInStepDocumentsFormInputs } from '@modules/reception/checkin/step-documents/index'
import { DocumentsLanguageSelect } from '@modules/reception/common/documents-language-select'
import { IconWithText } from '@components/icon-with-text'
import { HPFTicketImprovementCode } from '@modules/reception/checkin/step-improvements/tickets/hpf/reception-booking-hpf-ticket-improvement'

interface Props {
  booking: ReceptionBookingDetails
  showLanguageSelect?: boolean
}

export const ReceptionBookingCheckInStepDocumentsPrints: React.FC<Props> = ({ booking, showLanguageSelect = true }) => {
  const { control } = useFormContext<ReceptionBookingCheckInStepDocumentsFormInputs>()

  const language = useWatch({ control, name: 'language' })

  const getUrl = url => `${url}?language=${language}`

  const isCheckInDocuments = keyword =>
    booking.check_in_documents.some(
      (checkInDocument: ReceptionBookingCheckInDocument) => checkInDocument.keyword === keyword,
    )

  const documentsToShow = React.useMemo(
    () =>
      documents(getUrl, booking).reduce(
        (printableDocuments, printableDocument) =>
          isCheckInDocuments(printableDocument.keyword) || !printableDocument.defaultHidden
            ? [
                ...printableDocuments,
                <PrintableDocument
                  key={printableDocument.keyword}
                  title={printableDocument.title}
                  url={printableDocument.url}
                />,
              ]
            : printableDocuments,
        [],
      ),
    [getUrl, language, booking],
  )

  return (
    <>
      {showLanguageSelect && (
        <div className="mb-2">
          <DocumentsLanguageSelect />
        </div>
      )}

      {booking.status === 'close' ? (
        <a
          href={getUrl(booking.urls.print_bill)}
          target="_blank"
          className="reception__booking-check-out__bill-step__print mt-2"
        >
          <IconWithText
            icon="uil-print reception__booking-check-out__bill-step__print__icon"
            text="Drukuj rachunek dla Gościa"
          />
        </a>
      ) : (
        <>
          <ReceptionBookingCheckInStepDocumentsPrintsButton
            className="reception__booking-check-in__documents-step__prints__button--main"
            url={getUrl(booking.urls.print_all)}
          >
            Drukuj wszystkie
          </ReceptionBookingCheckInStepDocumentsPrintsButton>
          {documentsToShow}
        </>
      )}
    </>
  )
}

export const PrintableDocument = ({ title, url }) => (
  <ReceptionBookingCheckInStepDocumentsPrintsButton url={url}>
    <IconWithText
      icon="uil-corner-down-right mr-1 reception__booking-check-in__documents-step__prints__button__line-icon"
      text={title}
    />
  </ReceptionBookingCheckInStepDocumentsPrintsButton>
)

const documents = (getUrl, booking) => [
  {
    keyword: 'information_clause',
    title: 'Klauzula informacyjna',
    url: getUrl(booking.urls.print_information_clause),
  },
  { keyword: 'stay_card', title: 'Karta pobytu', url: getUrl(booking.urls.print_stay_card) },
  {
    keyword: 'parking_card',
    title: 'Karta parkingowa',
    url: getUrl(booking.urls.print_parking),
    defaultHidden: !hasCars(booking),
  },
  {
    keyword: 'tourist_voucher',
    title: 'Bon turystyczny',
    url: getUrl(booking.urls.print_tourist_voucher),
    defaultHidden: !booking.has_tourist_voucher,
  },
  { keyword: 'covid', title: 'Covid', url: getUrl(booking.urls.print_covid), defaultHidden: true },
  {
    keyword: 'hpf_tickets',
    title: 'Bilety HPF',
    url: getUrl(booking.urls.print_hpf_tickets),
    defaultHidden: !booking.improvements.some(improvement => improvement.code === HPFTicketImprovementCode),
  },
]
