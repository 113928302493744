import * as React from 'react'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { EndlessHolidayCreateFormInputs } from '@modules/endless-holiday/transactions/create/modal'
import { ButtonWithIcon } from '@components/button-with-icon'
import {
  EndlessHolidayAvailableAccommodationTypeOptions,
  EndlessHolidayFormProductDataRow,
  EndlessHolidayOptions,
} from '@modules/endless-holiday/transactions/create/steps/products/product-row'
import classNames from 'classnames'
import { useProductsAppData } from '@modules/products/hooks/use-products-app-data'
import { useGetProductPackageSetDetailsQuery } from '@api/endless-holiday'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { TypedQueryResult } from '@api/base'
import { ProductPackageSetDetails, ProductPackageSetProduct } from '@modules/endless-holiday/models'
import { createSelectOption, getById } from '@helpers/utils'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { FormPlainError } from '@hyper/forms'

export const EndlessHolidayFormProductData = () => {
  const { product_package_sets } = useProductsAppData()
  const { control } = useFormContext<EndlessHolidayCreateFormInputs>()

  const { fields, append, remove } = useFieldArray({ control, name: 'products' })

  const selectedProductPackageSet = useWatch({ control, name: 'product_package_set' })

  const productPackageSet = getById(product_package_sets, selectedProductPackageSet?.value)

  const { data: packageSetDetails } = useGetProductPackageSetDetailsQuery(productPackageSet?.urls.details, {
    skip: !productPackageSet,
  }) as TypedQueryResult<ProductPackageSetDetails>

  const handleAddEmptyProduct = () => {
    if (!packageSetDetails?.products) return

    const yearOptions = getYearsOptions(EndlessHolidayAvailableAccommodationTypeOptions[0].value)

    append({
      gastro_card: gastroProducts[1].id.toString(),
      subscription: getSubscriptionProducts(
        yearOptions[0].value,
        EndlessHolidayAvailableAccommodationTypeOptions[0].value.toString(),
      )[0].id.toString(),
      accommodation_type: EndlessHolidayAvailableAccommodationTypeOptions[0],
      amount: 1,
      options: [EndlessHolidayOptions[1]],
      year: yearOptions[0],
    })
  }

  const handleRemoveProduct = (index: number) => () => {
    remove(index)
  }

  const getYearsOptions = React.useCallback(
    (accommodationType: number) => {
      if (!packageSetDetails?.products.length) return []

      const productYears = packageSetDetails.products.reduce(
        (prev: CustomReactSelectOption<number, number>[], product: ProductPackageSetProduct) => {
          const years = product.stay_in_years

          const isAlreadyAdded = prev.some(year => year.value === years?.[0])

          if (!years?.length || isAlreadyAdded || product.accommodation_type !== accommodationType) return prev

          return [...prev, createSelectOption(years[0], years[0])]
        },
        [],
      )

      return productYears.sort((a, b) => a.value - b.value)
    },
    [packageSetDetails],
  )

  const gastroProducts = React.useMemo(
    () =>
      (packageSetDetails?.products || []).filter(
        product => product.kind === 'gastro_card_product_package_endless_holiday',
        [],
      ),
    [packageSetDetails],
  )

  const getSubscriptionProducts = (year: number, accommodationType: string) =>
    (packageSetDetails?.products || []).filter(
      product =>
        product.stay_in_years?.includes(year) && parseInt(accommodationType, 10) === product.accommodation_type,
    )

  return (
    <LoaderPlaceholder content={productPackageSet}>
      <div className="px-3 pb-3">
        <h4 className="text-secondary fw-semi-bold">Wybrane produkty:</h4>
        {!fields.length && <p className="text-center">Żaden produkt nie został jeszcze dodany.</p>}
        {fields.map((product, index) => (
          <EndlessHolidayFormProductDataRow
            getYearOptions={getYearsOptions}
            gastroProducts={gastroProducts}
            className={classNames({ 'mt-2': index > 0 })}
            key={product.id}
            onRemove={handleRemoveProduct(index)}
            getSubscriptionProducts={getSubscriptionProducts}
            index={index}
          />
        ))}
        <FormPlainError name="products" className="mt-2" />
        <ButtonWithIcon
          icon="uil-plus"
          text="Dodaj produkt"
          btnClass="btn col-3 mx-auto d-block mt-2 btn-tall"
          color="green"
          handleClick={handleAddEmptyProduct}
        />
      </div>
    </LoaderPlaceholder>
  )
}
