import * as React from 'react'
import { useDocumentTitle } from '@helpers/utils'
import { PageTitle } from '@hyper/page-title'
import { Card, CardBody } from 'reactstrap'
import { AccountingRefundTabs } from '@modules/accounting/refund/tabs'
import { RefundBookingsFilters } from '@modules/accounting/refund/bookings/filters'
import { useAppDispatch, useAppSelector } from '@store/index'
import { getBookingRefunds } from '@store/actions/accounting-actions'
import { RefundBookingsTable } from '@modules/accounting/refund/bookings/table'
import { TableFilters } from '@components/table/table'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { bookingRefundsSelector } from '@store/slices/accounting-slice'
import { BookingRefundWebSocketHandler } from '@modules/accounting/refund/web-socket-handler'
import { useTableFilters } from '@components/table/use-table-filters'

export interface RefundBookingsFiltersParams extends TableFilters {
  resort: string
  date_to_after: undefined | Date
  date_to_before: undefined | Date
  deposit_transfer_status: string
  type: string
}

const defaultFilters: RefundBookingsFiltersParams = {
  ordering: '-close_datetime',
  page: 1,
  resort: '',
  page_size: 30,
  type: '',
  search: '',
  date_to_after: undefined,
  date_to_before: undefined,
  deposit_transfer_status: '',
}
export const RefundBookingsView: React.FC = () => {
  useMenuActive(NavigationPath.AccountingRefundBookings)
  const bookingRefunds = useAppSelector(bookingRefundsSelector)
  const dispatch = useAppDispatch()

  const pageTitle = 'Kaucje do zwrotu'
  useDocumentTitle(pageTitle)

  const { isLoading, filters, setFilters } = useTableFilters<RefundBookingsFiltersParams>({
    defaultFilters,
    action: async filters => await dispatch(getBookingRefunds(filters)),
  })

  const filteredBookingRefunds = (bookingRefunds || []).filter(row => row.deposit_transfer_status != 'transfer_sent')

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitle} />
      <Card>
        <CardBody>
          <BookingRefundWebSocketHandler />
          <AccountingRefundTabs active="bookings" />
          <RefundBookingsFilters setFilters={setFilters} filters={filters} defaultFilters={defaultFilters} />
          <RefundBookingsTable
            bookingRefunds={filteredBookingRefunds}
            isLoading={isLoading}
            filters={filters}
            setFilters={setFilters}
          />
        </CardBody>
      </Card>
    </>
  )
}
