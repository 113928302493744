import * as React from 'react'
import { PaymentDayReportDetails, PaymentRegisterPoint } from '@models/payments'
import { ButtonWithIcon } from '@components/button-with-icon'
import { useModal } from '@components/modals/use-modal'
import { IconWithText } from '@components/icon-with-text'
import { formatPrice } from '@helpers/utils'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import classNames from 'classnames'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useApiRequest } from '@components/hooks/use-api-request'

interface Props {
  point: PaymentRegisterPoint
  paymentDayReportDetails: PaymentDayReportDetails
}

export const PaymentRegisterPointListRow = ({ paymentDayReportDetails, point }: Props): JSX.Element | null => {
  const paymentDayPointReport = paymentDayReportDetails.point_reports.find(row => row.point_id === point.id)
  const { addSuccessNotification } = useNotificationHook()

  const [handleClick] = useModal('PaymentRegisterPointModal', { point, paymentDayReportDetails, paymentDayPointReport })

  const { action: handlePrint } = useApiRequest(async () => {
    if (paymentDayPointReport) {
      await commonObjectPost(paymentDayPointReport.urls.print)
      addSuccessNotification('Wydruk został zlecony')
    }
  })

  if (!point.is_active && !paymentDayPointReport) {
    return null
  }

  return (
    <tr>
      <td className="align-middle text-strong">{point.name}</td>
      <td>
        {paymentDayPointReport && (
          <>
            <strong>{paymentDayPointReport.author_display}</strong>{' '}
            <span className="font-11">({toDefaultDateTimeFormat(paymentDayPointReport.created)})</span>
          </>
        )}
      </td>
      <td>
        {paymentDayPointReport && (
          <IconWithText
            icon="uil-usd-circle font-16"
            wrapperClassNames="text-strong"
            text={formatPrice(paymentDayPointReport.amount)}
          />
        )}
      </td>
      <td className="text-right align-middle pb-2">
        <ButtonWithIcon
          handleClick={handleClick}
          btnClass={classNames('btn', {
            'btn-green': !paymentDayPointReport,
            'btn-light': !!paymentDayPointReport,
          })}
          icon="uil-plus"
          text={paymentDayPointReport ? 'Edytuj utarg (KP)' : 'Wpłać utarg (KP)'}
        />
        {!!paymentDayPointReport && (
          <button className="ml-1 btn btn-green" onClick={handlePrint}>
            <i className="uil uil-print" />
          </button>
        )}
      </td>
    </tr>
  )
}
