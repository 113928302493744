const declination = {
  person: (num: number): string => (num >= 5 ? 'osób' : num >= 2 ? 'osoby' : 'osoba'),
  meal: (num: number): string =>
    num === 1 ? 'posiłek' : num % 10 === 2 || num % 10 === 3 || num % 10 === 4 ? 'posiłki' : 'posiłków',
  day: (num: number): string => (num > 1 ? 'dni' : 'dzień'),
  night: (num: number): string => (num >= 5 || num === 0 ? 'dób' : num >= 2 ? 'doby' : 'doba'),
  left: (num: number): string => (num > 1 || num === 0 ? 'pozostało' : 'pozostała'),
}

export default declination
