import * as React from 'react'
import Table from '@components/table/table'
import { toDefaultDateFormat, toDefaultDateTimeFormatWithBrake } from '@helpers/date-helper'
import { formatPriceShort } from '@helpers/utils'
import { RefundBookingsTableCellActions } from '@modules/accounting/refund/bookings/table-cell-actions'
import { resortsMapSelector } from '@store/selectors/dashboard'
import { BookingRefund } from '@modules/accounting/refund/models'
import { useAppSelector } from '@store/index'

interface Props {
  bookingRefund: BookingRefund
  selectableCell: (row: BookingRefund) => React.ReactElement
}

export const RefundBookingsTableRow = ({ bookingRefund, selectableCell }: Props): JSX.Element => {
  const resortsMap = useAppSelector(resortsMapSelector)
  return (
    <Table.Row key={bookingRefund.id}>
      {selectableCell(bookingRefund)}
      <Table.Cell>{bookingRefund.deposit_transfer_status_display}</Table.Cell>
      <Table.Cell>{resortsMap[bookingRefund.resort_id]?.name || 'brak'}</Table.Cell>
      <Table.Cell>{toDefaultDateFormat(bookingRefund.date_to)}</Table.Cell>
      <Table.Cell>{toDefaultDateTimeFormatWithBrake(bookingRefund.close_datetime)}</Table.Cell>
      <Table.Cell>{formatPriceShort(bookingRefund.deposit_to_return)}</Table.Cell>
      <Table.Cell>{formatPriceShort(bookingRefund.total_deposit_payin)}</Table.Cell>
      <Table.Cell>{formatPriceShort(bookingRefund.deposit_amount_used)}</Table.Cell>
      <Table.Cell>
        <a href={bookingRefund.urls.details} target="_blank" className="text-primary">
          {bookingRefund.reservation_number}
        </a>
      </Table.Cell>
      <Table.Cell>{bookingRefund.name}</Table.Cell>
      <Table.Cell className="text-nowrap text-right">
        <RefundBookingsTableCellActions booking={bookingRefund} />
      </Table.Cell>
    </Table.Row>
  )
}
