import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { apiQueryFunction } from '@api/base'
import { extractSelectOptionsValues } from '@helpers/utils'
import { RootState } from '@store/index'
import { HrWorker, HrWorkerDetails } from '@modules/hr/workers/models'
import { provideListTags } from '@api/query-helper'
import { HrWorkersCreateFormInputs } from '@modules/hr/workers/create/modal'
import { HrWorkerListFilterParams } from '@modules/hr/workers/list/filters'
import { PaginationResponse } from '@models/dashboard'
import { AgreementAnnex, HRAppData } from '@modules/hr/agreement/models'

export const AGREEMENTS_API_TAG = 'agreements'
export const AGREEMENTS_API_TAG_IDS = {
  LIST: 'LIST',
  DETAILS: 'DETAILS',
}

export const agreementsApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: '' }),
  reducerPath: 'agreementsApi',
  tagTypes: [AGREEMENTS_API_TAG, AGREEMENTS_API_TAG_IDS.DETAILS],
  endpoints: builder => ({
    getHrAppData: builder.query<HRAppData, void>({
      queryFn: apiQueryFunction((state: RootState) => ({
        method: 'GET',
        url: state.appState.appData.urls.hr.app_data,
      })),
    }),
    getHrWorkers: builder.query<PaginationResponse<HrWorker>, HrWorkerListFilterParams>({
      queryFn: apiQueryFunction((state: RootState, data: HrWorkerListFilterParams) => ({
        params: extractSelectOptionsValues(data),
        method: 'GET',
        url: state.appState.appData.urls.hr.hr_workers,
      })),
      providesTags: data => provideListTags(AGREEMENTS_API_TAG, AGREEMENTS_API_TAG_IDS.LIST)(data?.results),
    }),
    upsertHrWorker: builder.mutation({
      queryFn: apiQueryFunction(
        (state: RootState, { url, data }: { url?: string; data: HrWorkersCreateFormInputs }) => ({
          data: extractSelectOptionsValues(data),
          method: url ? 'PATCH' : 'POST',
          url: url ?? state.appState.appData.urls.hr.hr_workers,
        }),
      ),
      invalidatesTags: [AGREEMENTS_API_TAG_IDS.LIST, AGREEMENTS_API_TAG_IDS.DETAILS],
    }),
    getHrWorkerDetails: builder.query<HrWorkerDetails, string>({
      queryFn: apiQueryFunction((_, url: string) => ({
        method: 'GET',
        url,
      })),
      providesTags: [AGREEMENTS_API_TAG_IDS.DETAILS],
    }),
    removeHrWorker: builder.mutation<void, string>({
      queryFn: apiQueryFunction((_, { worker, reason }: { worker: HrWorker; reason: string }) => ({
        method: 'DELETE',
        url: worker.urls.details,
        data: { reason },
      })),
      invalidatesTags: [AGREEMENTS_API_TAG_IDS.LIST, AGREEMENTS_API_TAG_IDS.DETAILS],
    }),
    restoreHrWorker: builder.mutation<void, string>({
      queryFn: apiQueryFunction((_, { worker, reason }: { worker: HrWorkerDetails; reason: string }) => ({
        method: 'POST',
        url: worker.urls.restore_removed_profile,
        data: { reason },
      })),
      invalidatesTags: [AGREEMENTS_API_TAG_IDS.LIST, AGREEMENTS_API_TAG_IDS.DETAILS],
    }),
    createAgreementAnnex: builder.mutation<void, string>({
      queryFn: apiQueryFunction((_, { url, payload }) => ({
        method: 'POST',
        url: url,
        data: payload,
      })),
      invalidatesTags: [AGREEMENTS_API_TAG_IDS.LIST, AGREEMENTS_API_TAG_IDS.DETAILS],
    }),
    updateAgreementAnnexStatus: builder.mutation<void, string>({
      queryFn: apiQueryFunction((_, { url, payload }) => ({
        method: 'PATCH',
        url: url,
        data: payload,
      })),
      invalidatesTags: [AGREEMENTS_API_TAG_IDS.LIST, AGREEMENTS_API_TAG_IDS.DETAILS],
    }),
    removeAgreementAnnex: builder.mutation<void, string>({
      queryFn: apiQueryFunction((_, annex: AgreementAnnex) => ({
        method: 'DELETE',
        url: annex.urls.details,
      })),
      invalidatesTags: [AGREEMENTS_API_TAG_IDS.LIST, AGREEMENTS_API_TAG_IDS.DETAILS],
    }),
  }),
})

export const {
  useGetHrWorkersQuery,
  useGetHrAppDataQuery,
  useGetHrWorkerDetailsQuery,
  useRemoveHrWorkerMutation,
  useRestoreHrWorkerMutation,
  useUpsertHrWorkerMutation,
  useCreateAgreementAnnexMutation,
  useLazyGetHrWorkerDetailsQuery,
  useUpdateAgreementAnnexStatusMutation,
  useRemoveAgreementAnnexMutation,
} = agreementsApi
