import styleHelper from '@helpers/style-helper'
import dateHelper, { parseISODate } from '@helpers/date-helper'
import { Apartment } from '@models/apartment'
import { CalendarDate, TimelineBooking } from '@store/reducers/timeline-reducers'
import { differenceInCalendarDays, getMonth, getYear, isBefore, parseISO } from 'date-fns'
import store from '@store/index'
import { EditModeType } from '@modules/reservations/timeline/timeline-item-basic'

export const timelineHelper = {
  dayMargin: 1,
  itemsMargin: 3,

  createCalendarDates: (calendarDate: CalendarDate): CalendarDate[] => [
    ...(store.getState().timelineState.isCompactView
      ? [dateHelper.getPrevCalendarDate(dateHelper.getPrevCalendarDate(calendarDate))]
      : []),
    dateHelper.getPrevCalendarDate(calendarDate),
    calendarDate,
    dateHelper.getNextCalendarDate(calendarDate),
    ...(store.getState().timelineState.isCompactView
      ? [dateHelper.getNextCalendarDate(dateHelper.getNextCalendarDate(calendarDate))]
      : []),
  ],
  getDefaultCalendarDate: (): CalendarDate => ({
    month: getMonth(Date.now()) + 1, // return month 0-indexed
    year: getYear(Date.now()),
  }),
  getDefaultCalendarDates: (): CalendarDate[] =>
    timelineHelper.createCalendarDates(timelineHelper.getDefaultCalendarDate()),
  getDayWidth: (): number => (store.getState().timelineState.isCompactView ? 21 : 30),
  getDayWidthMargin: (): number => timelineHelper.dayMargin + timelineHelper.getDayWidth(),
  getRowWidth: (renderedDates: CalendarDate[]): string => {
    const width = timelineHelper.getDayWidthMargin() * timelineHelper.getRenderedDaysNumber(renderedDates)
    return styleHelper.valueToPX(width)
  },
  getRowHeight: (): number => (store.getState().timelineState.isCompactView ? 20 : 25),
  getColHeight: (apartments: Apartment[]): string =>
    styleHelper.valueToPX(apartments.length * timelineHelper.getRowHeight()),
  getColWidth: (): string => styleHelper.valueToPX(timelineHelper.getDayWidthMargin()),
  getColLeftOffset: (index: number): string => styleHelper.valueToPX(timelineHelper.getDayWidthMargin() * index),
  getFirstDayLeftOffset: (firstDate: CalendarDate, date: CalendarDate): string => {
    const parseDate = (year: number, month: number): Date => parseISO(dateHelper.createDateFormat(year, month, 1))
    const diff = differenceInCalendarDays(parseDate(date.year, date.month), parseDate(firstDate.year, firstDate.month))
    const width = diff * timelineHelper.getDayWidthMargin()
    return styleHelper.valueToPX(diff > 0 ? width : 0)
  },
  getTodayLeftOffset: (year: number, month: number): string => {
    const diff = differenceInCalendarDays(Date.now(), parseISO(dateHelper.createDateFormat(year, month, 1)))
    const width = diff * timelineHelper.getDayWidthMargin()
    return styleHelper.valueToPX(diff > 0 ? width : 0)
  },
  getSelectedColLeftOffset: (year: number, month: number, dateTo: string): string => {
    const diff = differenceInCalendarDays(
      parseISODate(dateTo) as Date,
      parseISO(dateHelper.createDateFormat(year, month, 1)),
    )

    const width = diff * timelineHelper.getDayWidthMargin()
    return styleHelper.valueToPX(diff > 0 ? width : 0)
  },
  getScrollTodayPos: (year: number, month: number): number => {
    const diff = differenceInCalendarDays(Date.now(), parseISO(dateHelper.createDateFormat(year, month, 1)))
    return diff * timelineHelper.getDayWidthMargin() - 6 * timelineHelper.getDayWidthMargin()
  },
  getRenderedDaysNumber: (renderedDates: CalendarDate[]): number =>
    renderedDates.reduce((total, el) => total + dateHelper.getDaysInMonth(el.year, el.month), 0),
  getMaskMaxWidth: (renderedDates: CalendarDate[]): string =>
    styleHelper.valueToPX(timelineHelper.getRenderedDaysNumber(renderedDates) * timelineHelper.getDayWidthMargin()),
  getItemWidth: (reservationTime: number): string => {
    // subtract to get a margin between items
    const itemWidth = reservationTime * timelineHelper.getDayWidthMargin() - timelineHelper.itemsMargin
    return styleHelper.valueToPX(itemWidth)
  },
  getItemTopOffset: (apartment: Apartment | undefined, allApartments: Apartment[]): string => {
    const apartmentIndex = allApartments.findIndex(ap => ap.id === apartment?.id)
    return styleHelper.valueToPX(timelineHelper.getRowHeight() * apartmentIndex)
  },
  getItemLeftOffset: (renderDates: CalendarDate[], dateFrom: string): string => {
    const diff = differenceInCalendarDays(
      parseISO(dateFrom),
      parseISO(dateHelper.createDateFormat(renderDates[0].year, renderDates[0].month, 1)),
    )

    const leftOffSet = timelineHelper.getDayWidthMargin() * diff

    // need to move by day width to get proper slices at the ends
    // add margin between items
    const offset = leftOffSet + timelineHelper.getDayWidth() / 2 + timelineHelper.itemsMargin
    return styleHelper.valueToPX(offset)
  },
  getItemExtendedOffset: (renderDates: CalendarDate[], dateTo: string): string => {
    const day = differenceInCalendarDays(
      parseISO(dateTo),
      parseISO(dateHelper.createDateFormat(renderDates[0].year, renderDates[0].month, 1)),
    )
    return styleHelper.valueToPX(timelineHelper.getDayWidthMargin() * day)
  },
  isBookingEditable: (booking: TimelineBooking): boolean => ['initial', 'confirmed'].includes(booking.status),
  isBookingBeforeApFee: (createdDate: string) => isBefore(parseISO(createdDate), parseISO('2021-06-01')),

  getEditAbortIconPosition: (
    editType: EditModeType,
    renderDates: CalendarDate[],
    dateFrom: string,
    dateTo: string,
    reservationTime: number,
    apartment: Apartment | undefined,
    allApartments: Apartment[],
  ) => {
    if (editType === null) return
    const ICON_SIZE = 20

    return {
      resizer: {
        left: styleHelper.valueToPX(
          styleHelper.pxToValue(timelineHelper.getItemLeftOffset(renderDates, dateFrom)) +
            styleHelper.pxToValue(timelineHelper.getItemWidth(reservationTime)) / 2 -
            ICON_SIZE / 2,
        ),
        top: styleHelper.valueToPX(
          styleHelper.pxToValue(timelineHelper.getItemTopOffset(apartment, allApartments)) + ICON_SIZE,
        ),
      },
      item: {
        left: styleHelper.valueToPX(
          styleHelper.pxToValue(timelineHelper.getItemExtendedOffset(renderDates, dateTo)) + ICON_SIZE / 2.5,
        ),
        top: timelineHelper.getItemTopOffset(apartment, allApartments),
      },
    }[editType]
  },
}

export default timelineHelper
