import * as React from 'react'
import { FormPlain } from '@hyper/forms'
import { extractInnerRef } from '@helpers/forms'
import { CustomInput } from 'reactstrap'
import { useFormContext } from 'react-hook-form'
import { GuestsTechnicalOrderStatusFormInputs } from '@modules/guests-technical-orders/guests-technical-order-status-modal/index'

export const GuestsTechnicalOrderStatusFormNew: React.FC = () => {
  const { register } = useFormContext<GuestsTechnicalOrderStatusFormInputs>()
  return (
    <FormPlain name="action" label="Wybierz jedną z opcji" labelSize={5}>
      <div>
        <CustomInput
          type="radio"
          className="mb-2 mt-2"
          value="forward_to_reception_technical_order"
          {...extractInnerRef(register('action'))}
          id="forward_to_reception_technical_order"
          label='Przekaż do "Usterki"'
        />
        <CustomInput
          type="radio"
          className="mb-2"
          value="open"
          {...extractInnerRef(register('action'))}
          id="open"
          label="Oznacz jako otwarte"
        />
        <CustomInput
          type="radio"
          value="delete"
          className="mb-2"
          {...extractInnerRef(register('action'))}
          id="close"
          label="Oznacz jako usunięte"
        />
      </div>
    </FormPlain>
  )
}
