import * as React from 'react'
import { Button, ModalBody, ModalFooter } from 'reactstrap'
import { CmsReservationAppImagesUploader } from '@modules/cms/reservation-app/common/images/images-uploader'
import { AccommodationTypeImage, ResortImage } from '@modules/cms/models'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import CmsReservationAppImagesDnd from '@modules/cms/reservation-app/common/images/images-dnd'
import { BaseModalProps } from '@components/modals/types'

interface Props extends BaseModalProps {
  url: string
  images: AccommodationTypeImage[] | ResortImage[]
  id: number
  setState: (state: any) => void
  positionUrl: string
}

export const CmsReservationAppImages: React.FC<Props> = ({
  positionUrl,
  images,
  url,
  id,
  setState,
  toggleIsVisible,
}) => (
  <>
    <ModalBody className="pt-0">
      <CmsReservationAppImagesUploader id={id} url={url} setState={setState} />
      <DndProvider backend={HTML5Backend}>
        <CmsReservationAppImagesDnd positionUrl={positionUrl} images={images} setState={setState} />
      </DndProvider>
    </ModalBody>
    <ModalFooter>
      <Button color="light" type="button" onClick={toggleIsVisible}>
        Zamknij
      </Button>
    </ModalFooter>
  </>
)
