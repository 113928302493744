import * as React from 'react'
import { ReceptionBookingCheckInAddImprovementButton } from '@modules/reception/checkin/step-improvements/reception-booking-check-in-add-improvement-button'
import { formatPriceShort } from '@helpers/utils'
import { useFormContext, useWatch } from 'react-hook-form'
import { useApiRequest } from '@components/hooks/use-api-request'
import { createBookingImprovement } from '@store/actions/reception-actions'
import { useSelector } from 'react-redux'
import { receptionBookingDetailsSelector } from '@store/selectors/reception'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'

interface Props {
  price: string
}

export const ReceptionBookingHpfTicketImprovementAdd = ({ price }: Props): React.ReactNode => {
  const booking = useSelector(receptionBookingDetailsSelector)

  const { addSuccessNotification } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { control } = useFormContext()

  const amount = useWatch({ control, name: 'amount' })

  const { isLoading, action: addImprovement } = useApiRequest(async () => {
    await dispatch(createBookingImprovement(booking.urls.improvement_create, [{ code: 'hpf_ticket', amount: amount }]))
    addSuccessNotification('Ulepszenie zostało dodane')
  })

  return (
    <ReceptionBookingCheckInAddImprovementButton
      improvementPrice={formatPriceShort(price)}
      isSaving={isLoading}
      label="Dodaj"
      onClick={addImprovement}
      btnClass="bg-primary rounded shadow-none border-0"
    />
  )
}
