import * as React from 'react'
import { Card, CardBody } from 'reactstrap'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { PageTitle } from '@hyper/page-title'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { TypedQueryResult } from '@api/base'
import { RodoAgreement } from '@modules/crm/rodo-agreements/models'
import { RodoAgreementsSearch } from '@modules/crm/rodo-agreements/benefits/rodo-agreements-search'
import { RodoAgreementsList } from '@modules/crm/rodo-agreements/benefits/list/rodo-agreements-list'
import { useDebouncedValue } from 'rooks'
import { useGetRodoAgreementsQuery } from '@api/rodo-agreements'

export interface ClientsRodoFormInputs {
  search: string
  temporarySearch: string
}

export const CrmClientsBenefitsForRodoView: React.FC = () => {
  useMenuActive(NavigationPath.CrmClientsBenefitsForRodo)

  const methods = useForm<ClientsRodoFormInputs>({
    defaultValues: { search: '' },
  })

  const searchValue = useWatch({ control: methods.control, name: 'search' })
  const [debouncedSearchValue] = useDebouncedValue(searchValue, 500)

  const {
    data: rodoAgreements = [],
    isLoading,
    isFetching,
    isUninitialized,
  } = useGetRodoAgreementsQuery(debouncedSearchValue, {
    skip: !debouncedSearchValue || debouncedSearchValue.length < 4,
    refetchOnMountOrArgChange: true,
  }) as TypedQueryResult<RodoAgreement[]>

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: 'Zgody RODO ', path: '', active: true }]} title="Zgody RODO" />
      <Card>
        <CardBody>
          <FormProvider {...methods}>
            <RodoAgreementsSearch isLoading={isLoading || isFetching} />
            <RodoAgreementsList rodoAgreements={rodoAgreements} isUninitialized={isUninitialized} />
          </FormProvider>
        </CardBody>
      </Card>
    </>
  )
}
