import * as React from 'react'
import { NavigationTabs, NavigationTabsButton } from '@components/navigation-tabs'

export const AvailableReceptionBookingDetailsTab = [
  'improvements',
  'documents',
  'data',
  'guests',
  'payments',
  'feedingNew',
  'feeding',
] as const

export type ReceptionBookingDetailsTab = (typeof AvailableReceptionBookingDetailsTab)[number]

interface Props {
  setTab: (tabName: ReceptionBookingDetailsTab) => void
  activeTab: ReceptionBookingDetailsTab
  hasFeeding: boolean
  tabs: ReceptionBookingDetailsTab[]
}

export const ReceptionBookingDetailsTabs: React.FC<Props> = ({ setTab, activeTab, hasFeeding, tabs }) => {
  const hasTab = (receptionBookingDetailsTab: ReceptionBookingDetailsTab) =>
    tabs.some(tab => receptionBookingDetailsTab === tab)

  const buttons: NavigationTabsButton[] = []

  if (hasTab('data')) {
    buttons.push({
      title: 'Dane rezerwacji',
      isActive: activeTab === 'data',
      onClick: () => setTab('data'),
    })
  }

  if (hasTab('improvements')) {
    buttons.push({
      title: 'Udoskonalenia',
      isActive: activeTab === 'improvements',
      onClick: () => setTab('improvements'),
    })
  }

  if (hasTab('guests')) {
    buttons.push({
      title: 'Goście',
      isActive: activeTab === 'guests',
      onClick: () => setTab('guests'),
    })
  }

  if (hasFeeding && hasTab('feeding')) {
    buttons.push({
      title: 'Wyżywienie',
      isActive: activeTab === 'feeding',
      onClick: () => setTab('feeding'),
    })
  }

  if (hasFeeding && hasTab('feedingNew')) {
    buttons.push({
      title: 'Wyżywienie',
      isActive: activeTab === 'feedingNew',
      onClick: () => setTab('feedingNew'),
    })
  }

  if (hasTab('documents')) {
    buttons.push({
      title: 'Dokumenty',
      isActive: activeTab === 'documents',
      onClick: () => setTab('documents'),
    })
  }

  if (hasTab('payments')) {
    buttons.push({
      title: 'Płatności',
      isActive: activeTab === 'payments',
      onClick: () => setTab('payments'),
    })
  }

  return <NavigationTabs buttons={buttons} />
}
