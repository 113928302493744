import * as React from 'react'
import Table from '@components/table/table'
import { useModal } from '@components/modals/use-modal'
import { CmsAssetsTableRowRemove } from '@modules/cms/assets/list/table-row-remove'
import { Asset } from '@models/cms-asset'
import { assetCategoriesSelector } from '@store/slices/assets-slice'
import { useAppSelector } from '@store/index'

interface Props {
  asset: Asset
}

export const CmsAssetsTableRow = ({ asset }: Props): JSX.Element => {
  const [handleEdit] = useModal('CmsAssetModal', { asset })
  const categories = useAppSelector(assetCategoriesSelector)

  const category =
    React.useMemo(() => categories.find(row => row.id === asset.category_id)?.name, [asset, categories]) || ''

  return (
    <Table.Row>
      <Table.Cell>{asset.name}</Table.Cell>
      <Table.Cell>
        <a target="_blank" href={asset.urls.redirect}>
          <code>{asset.key}</code>
        </a>
      </Table.Cell>
      <Table.Cell>{category}</Table.Cell>
      <Table.Cell>
        <a href={asset.file} target="_blank">
          {asset.filename}
        </a>
      </Table.Cell>
      <Table.Cell className="text-right">
        <i className="uil-pen cursor-pointer text-muted font-18 mr-2" onClick={handleEdit} />
        <CmsAssetsTableRowRemove asset={asset} />
      </Table.Cell>
    </Table.Row>
  )
}
