import * as React from 'react'
import { Col, CustomInput, Row } from 'reactstrap'
import { extractInnerRef } from '@helpers/forms'
import { useFormContext } from 'react-hook-form'
import { PackageWholesaleFormInputs } from '@modules/package-wholesale/create/modal'
import { FormPlainError } from '@hyper/forms'
import { formatPriceShort } from '@helpers/utils'
import { yearsPronunciation } from '@helpers/pronunciation'
import { usePackageWholesaleProductList } from '@modules/package-wholesale/use-product-list'

export const PackageWholesaleCreateModalFormProducts = (): JSX.Element => {
  const { register, control } = useFormContext<PackageWholesaleFormInputs>()

  const { isProductDisabled, isProductSelected, products } = usePackageWholesaleProductList(control)

  return (
    <Row className="mt-2">
      <Col md={12}>
        <label className="d-block mb-2 mt-2">Produkty w pakiecie</label>
        <FormPlainError className="mb-2 mt-n1" name="products" />
      </Col>
      {products.map(product => (
        <Col md={6} key={product.id}>
          <div className="pl-2 mb-2">
            <CustomInput
              disabled={isProductDisabled(product)}
              type="checkbox"
              id={`product-${product.id}`}
              defaultChecked={isProductSelected(product)}
              label={
                <span className="text-semi-strong cursor-pointer">
                  {product.name}{' '}
                  <small>
                    ({product.kind === 'subscription' && `${formatPriceShort(product.price_brutto)} / `}
                    {product.expire_after_years} {yearsPronunciation(product.expire_after_years)})
                  </small>
                </span>
              }
              value={product.id}
              {...extractInnerRef(register('products'))}
            />
          </div>
        </Col>
      ))}
    </Row>
  )
}
