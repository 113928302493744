import * as React from 'react'
import { ModalBody } from 'reactstrap'
import { formatPriceShort } from '@helpers/utils'
import { BaseModalProps } from '@components/modals/types'

interface Props extends BaseModalProps {
  amount: string
  isBooking: boolean
}

export const ReceptionPinPadPaymentSuccessModal: React.FC<Props> = ({ toggleIsVisible, amount, isBooking }: Props) => (
  <ModalBody className="modal-colored-header bg-success text-center">
    <i className="uil-money-withdraw h1" />
    <h4 className="mt-2">
      Poprawnie dokonano
      <br />
      wpłaty kartą:
    </h4>
    <h3>{formatPriceShort(amount)}</h3>
    <p className="mt-3">
      Druk KP zostanie wydrukowany automatycznie. {isBooking && 'Wepnij go do rozliczenia rezerwacji.'}
    </p>
    <button type="button" className="btn btn-light my-2" data-dismiss="modal" onClick={toggleIsVisible}>
      Rozumiem
    </button>
  </ModalBody>
)
