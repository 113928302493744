import * as React from 'react'
import Table, { TableHeaderValue } from '@components/table/table'
import { PackageWholesaleDetailsSubscriptionsRow } from '@modules/package-wholesale/details/subscriptions/row'
import { Subscription, SubscriptionStatus } from '@modules/subscription/models'
import { Card, CardBody } from 'reactstrap'
import { useSubscriptionAppData } from '@modules/subscription/hooks/use-subscription-app-data'
import { FormSelect } from '@hyper/forms/form-select'
import { createSelectOption } from '@helpers/utils'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { CommonTableFilters } from '@components/table/common-table-filters'
import { BaseTableFilters } from '@components/table/use-handle-table-filters-change'

const StatusOptions = [
  createSelectOption<string, SubscriptionStatus>('Potwierdzony', 'confirmed'),
  createSelectOption<string, SubscriptionStatus>('Wstępny', 'initial'),
  createSelectOption<string, SubscriptionStatus>('Niedokończony', 'unfinished'),
]

interface SubscriptionFilters extends BaseTableFilters {
  status: CustomReactSelectOption[]
}

const headerValues: TableHeaderValue<Subscription>[] = [
  { title: 'Numer', sortField: 'number' },
  { title: 'Klient', sortField: 'email' },
  { title: 'Status', sortField: 'status' },
  { title: 'Kwota brutto' },
  { title: 'Pozostało do zapłaty' },
  { title: 'Produkt' },
  { title: 'Ilość kodów', sortField: 'type_value' },
  { title: 'Opcje' },
  { title: 'Data założenia', sortField: 'created' },
]

interface Props {
  subscriptions: Subscription[]
  defaultStatusesToShow?: SubscriptionStatus[]
}

export const PackageWholesaleDetailsSubscriptions = ({
  subscriptions,
  defaultStatusesToShow = ['confirmed', 'initial'],
}: Props): React.ReactNode => {
  const defaultFilters: SubscriptionFilters = {
    search: '',
    ordering: '-created',
    page: 1,
    page_size: 9999,
    status: StatusOptions.filter(status => defaultStatusesToShow?.includes(status.value)),
  }

  const methods = useForm<SubscriptionFilters>({ defaultValues: defaultFilters })

  const { products } = useSubscriptionAppData()

  const filters = useWatch({ control: methods.control }) as SubscriptionFilters

  const handleFiltersChange = (filters: SubscriptionFilters) => {
    methods.setValue('status', filters.status)
  }

  const filteredSubscriptions = subscriptions.filter(subscription =>
    filters.status?.map(status => status.value).includes(subscription.status),
  )

  return (
    <Card>
      <CardBody>
        <FormProvider {...methods}>
          <h4 className="mt-0 text-secondary mb-3">Pakiety prywatne</h4>

          <CommonTableFilters
            filters={filters}
            defaultFilters={defaultFilters}
            setFilters={handleFiltersChange}
            withSearch={false}
          >
            <FormSelect
              options={StatusOptions}
              label="Status"
              name="status"
              selectProps={{ isMulti: true }}
              formPlainProps={{ colClassName: 'pr-0', colStyle: { maxWidth: 390 } }}
            />
          </CommonTableFilters>
          <Table
            className="table-hover"
            headerValues={headerValues}
            isLoading={false}
            showPagination={false}
            filters={filters}
            tableWrapperClassName="table-responsive"
            setFilters={handleFiltersChange}
          >
            {filteredSubscriptions.map(subscription => (
              <PackageWholesaleDetailsSubscriptionsRow
                products={products}
                key={subscription.id}
                subscription={subscription}
              />
            ))}
          </Table>
        </FormProvider>
      </CardBody>
    </Card>
  )
}
