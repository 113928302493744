import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { FormCheckbox } from '@hyper/forms/form-checkbox'
import { FormInput } from '@hyper/forms'
import classNames from 'classnames'
import { formatPriceShort, handleEnterPress, noop } from '@helpers/utils'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { ExtendedStayFormInputs } from '@modules/reception/extended-stay/reception-booking-extended-stay-dialog'

interface Props {
  originalPriceBrutto: string
}

export const ReceptionBookingExtendedStayOptionDaysCustomPrice: React.FC<Props> = ({ originalPriceBrutto }) => {
  const { control, setValue } = useFormContext<ExtendedStayFormInputs>()

  const withCustomStayPrice = useWatch({ control, name: 'with_custom_stay_price' })

  useDidUpdateEffect(() => {
    if (!withCustomStayPrice) setValue('custom_apartment_day_price', null)
  }, [withCustomStayPrice])

  return (
    <div className="mb-3">
      <div className="d-flex align-items-center justify-content-betweenmt-n2">
        <FormCheckbox
          name="with_custom_stay_price"
          label={
            <span>
              Wprowadź kwotę za dobę <small>(kwota za pobyt bez dodatkowych opłat)</small>
            </span>
          }
        />
        <div
          className={classNames('d-flex align-items-center justify-content-end', {
            'not-clickable': !withCustomStayPrice,
          })}
        >
          <FormInput
            name="custom_apartment_day_price"
            type="currency"
            formGroupClassName="mb-0"
            colClassName="pr-0"
            placeholder="0.00"
            inputClassName="text-right"
            colSize={7}
            onKeyDown={handleEnterPress.bind(noop)}
          />
          <span className="fw-semi-bold ml-2">zł</span>
        </div>
      </div>
      <div className="text-right mt-1 mr-2">(cena katalogowa za dobę: {formatPriceShort(originalPriceBrutto)})</div>
    </div>
  )
}
