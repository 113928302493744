import * as React from 'react'
import { PageTitle } from '@hyper/page-title'
import { Card, CardBody } from 'reactstrap'
import { AccountingRefundTabs } from '@modules/accounting/refund/tabs'
import { useDocumentTitle } from '@helpers/utils'
import { transferKindsOptions, TransferPackagesFilters } from '@modules/accounting/refund/transfer-packages/filters'
import { useAppDispatch, useAppSelector } from '@store/index'
import { TransferPackagesTable } from '@modules/accounting/refund/transfer-packages/table'
import { getTransferPackages } from '@store/actions/accounting-actions'
import { TableFilters } from '@components/table/table'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useDebounce } from 'rooks'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { transferPackagesSelector } from '@store/slices/accounting-slice'
import { CustomReactSelectOption } from '@components/custom-react-select'

export interface TransferPackagesFiltersParams extends TableFilters {
  status: string
  created_after: undefined | Date
  created_before: undefined | Date
  kind: CustomReactSelectOption[]
}

const defaultFilters: TransferPackagesFiltersParams = {
  ordering: '-created',
  page: 1,
  status: '',
  page_size: 30,
  search: '',
  created_after: undefined,
  created_before: undefined,
  kind: transferKindsOptions,
}
export const TransferPackagesView: React.FC = () => {
  const [filters, setFilters] = React.useState<TransferPackagesFiltersParams>(defaultFilters)
  const transferPackages = useAppSelector(transferPackagesSelector)
  const dispatch = useAppDispatch()
  useMenuActive(NavigationPath.AccountingTransferPackages)

  const { isLoading, action: fetchTransferPackages } = useApiRequest(
    async filters => await dispatch(getTransferPackages(filters)),
  )

  const fetchTransferPackagesDebounced = React.useCallback(useDebounce(fetchTransferPackages, 300), [])
  React.useEffect(() => {
    fetchTransferPackagesDebounced(filters)
  }, [filters])

  const pageTitle = 'Paczki przelewów'
  useDocumentTitle(pageTitle)

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitle} />
      <Card>
        <CardBody>
          <AccountingRefundTabs active="transfers" />
          <TransferPackagesFilters setFilters={setFilters} filters={filters} defaultFilters={defaultFilters} />
          <TransferPackagesTable
            transferPackages={transferPackages}
            isLoading={isLoading}
            filters={filters}
            setFilters={setFilters}
          />
        </CardBody>
      </Card>
    </>
  )
}
