import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { ReceptionBookingNewFeedingGuestRow } from '@modules/reception/checkin/step-feeding/new-feeding/guest/reception-booking-new-feeding-guest-row'
import { getById, groupByField } from '@helpers/utils'
import { NewFeedingCalculationDetails } from '@modules/reception/checkin/step-feeding/new-feeding/models'
import { ReceptionBookingNewFeedingOpenCartButton } from '@modules/reception/checkin/step-feeding/new-feeding/reception-booking-new-feeding-open-cart-button'
import { ReceptionBookingNewFeedingDiscountPreviewIndicator } from '@modules/reception/checkin/step-feeding/new-feeding/discounts/reception-booking-new-feeding-discount-preview-indicator'

interface Props {
  booking: ReceptionBookingDetails
  calculationDetails: NewFeedingCalculationDetails[]
  initialCalculationDetails: NewFeedingCalculationDetails[]
  hasUnclosedCart: boolean
}

export const ReceptionBookingNewFeedingGuests = ({
  booking,
  calculationDetails,
  initialCalculationDetails,
  hasUnclosedCart,
}: Props): JSX.Element => {
  const calculationEntries = React.useMemo(
    () => Object.entries(calculationDetails ? groupByField(calculationDetails, 'booking_guest') : {}),
    [calculationDetails],
  )

  const getGuestById = React.useCallback(
    (guestId: string) => getById(booking.guests, guestId),
    [booking.guests, calculationDetails],
  )

  return (
    <div className="mt-3 pb-3">
      <div className="d-flex justify-content-between">
        <p className="font-16 font-weight-bold">Goście rezerwacji</p>
        <ReceptionBookingNewFeedingDiscountPreviewIndicator />
      </div>

      <div className="position-relative" style={hasUnclosedCart ? { minHeight: 280 } : {}}>
        {calculationEntries.map(([guestId, calculationsForGuest]) => (
          <ReceptionBookingNewFeedingGuestRow
            booking={booking}
            calculationDetails={calculationsForGuest}
            initialCalculationDetails={initialCalculationDetails}
            guest={getGuestById(guestId)}
            key={guestId}
          />
        ))}
        {hasUnclosedCart && (
          <div className="feeding__unclosed-cart-banner">
            <div className="col-6 d-flex flex-column justify-content-center">
              <p className="text-center font-14">
                W koszyku pozostały niezakończone transakcje. <br />
                Do czasu ich sfinalizowania dalsza edycja wyżywienia nie jest możliwa.
              </p>
              <ReceptionBookingNewFeedingOpenCartButton booking={booking} className="mt-3" />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
