import * as React from 'react'
import { useParams } from 'react-router-dom'
import { useDocumentTitle } from '@helpers/utils'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { PageTitle } from '@hyper/page-title'
import { useAppData } from '@components/hooks/use-app-data'
import { useGetUnitDetailsQuery } from '@api/units'
import { TypedQueryResult } from '@api/base'
import { UnitDetails } from '@models/units'
import { UnitDetailsInfo } from '@modules/units/details/info'
import { UnitDetailsTechnicalOrders } from '@modules/units/details/technical-orders'
import { UnitDetailsCleaningOrders } from '@modules/units/details/cleaning-orders'
import { UnitDetailsImprovementOrders } from '@modules/units/details/improvement-orders'

interface Params {
  id: string
  resort_id: string
}
export const UnitDetailsView: React.FC = () => {
  const { id, resort_id } = useParams() as unknown as Params

  const { urls } = useAppData()

  const { data: unitDetails } = useGetUnitDetailsQuery(
    `${urls.housekeeping.units}${id}/`,
  ) as TypedQueryResult<UnitDetails>

  const pageTitle = `Karta jednostki ${unitDetails?.name || ''}`

  useDocumentTitle(pageTitle)
  useMenuActive(NavigationPath.Units)

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          {
            label: 'Lista jednostek',
            path: NavigationPath.UnitsWithParams.replace('resort_id', resort_id),
            active: false,
          },
          { label: pageTitle, path: '', active: true },
        ]}
        title={pageTitle}
      />
      {!!unitDetails && (
        <>
          <UnitDetailsInfo unit={unitDetails} />
          <UnitDetailsTechnicalOrders unit={unitDetails} />
          <UnitDetailsCleaningOrders unit={unitDetails} />
          <UnitDetailsImprovementOrders unit={unitDetails} />
        </>
      )}
    </>
  )
}
