import * as React from 'react'
import { CleaningOrder, CleaningOrderDetails } from '@modules/housekeeping/models'
import { Button } from 'reactstrap'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useHandleAxiosServerErrors } from '@helpers/handle-axios-errors'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useModal } from '@components/modals/use-modal'
import { OrderNotes } from '@modules/housekeeping/cleaning-orders/common/row-notes'
import { HousekeepingCleaningOrderRowActionsSetPriority } from '@modules/housekeeping/cleaning-orders/to-order/actions/actions-priority'
import { updateHousekeepingCleaningOrderDetails } from '@store/slices/housekeeping-slice'

interface Props {
  cleaningOrder: CleaningOrder
}

export const HousekeepingCleaningOrderCleaningRowActions: React.FC<Props> = ({ cleaningOrder }) => {
  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()
  const handleAxiosServerErrors = useHandleAxiosServerErrors()

  const process = async (url, message) => {
    try {
      handleOrderUpdate(await commonObjectPost<CleaningOrderDetails>(url))
      addSuccessNotification(message)
    } catch (error) {
      handleAxiosServerErrors(error)
    }
  }

  const handleStart = () => process(cleaningOrder.urls.start_working, 'Zlecenie zostało wznowione!')
  const handlePause = () => process(cleaningOrder.urls.pause_working, 'Zlecenie zostało wstrzymane!')
  const handleClose = () => process(cleaningOrder.urls.stop_working, 'Zlecenie zostało zamknięte!')
  const handleRevertStatus = () => process(cleaningOrder.urls.revert_status, 'Zlecenie zostało wycofane!')

  const handleOrderUpdate = (order: CleaningOrderDetails) => {
    dispatch(updateHousekeepingCleaningOrderDetails(order))
  }

  const [handleAssign] = useModal('HousekeepingCleaningOrderAssignModal', {
    order: cleaningOrder,
    onOrderUpdate: handleOrderUpdate,
  })

  return (
    <td className="housekeeping__orders_table__column text-nowrap text-right">
      <OrderNotes order={cleaningOrder} />
      <HousekeepingCleaningOrderRowActionsSetPriority cleaningOrder={cleaningOrder} isCompact />
      <Button color="green" onClick={handleClose} className="btn-sm">
        Zamknij
      </Button>
      {cleaningOrder.is_paused ? (
        <Button color="green" onClick={handleStart} className="ml-1 btn-sm">
          Start
        </Button>
      ) : (
        <Button color="warning" onClick={handlePause} className="text-white ml-1 btn-sm">
          Pauza
        </Button>
      )}
      <Button color="warning" className="text-white ml-1 btn-sm" onClick={handleAssign}>
        Zmień osoby
      </Button>
      <Button color="danger" onClick={handleRevertStatus} className="btn-sm ml-1">
        Cofnij
      </Button>
    </td>
  )
}
