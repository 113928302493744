import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import { ReservationRoomBillTransactionsListTable } from '@modules/reservations/details/reservation-room-bill/modals/reservation-room-bill-transactions-list-modal-table'
import classnames from 'classnames'
import { selectReservationWalletTransactions } from '@store/slices/reservations-slice'
import { ReceptionBookingDetails } from '@models/reception'
import { useAppSelector } from '@store/index'

interface Props extends BaseModalProps {
  transactionId: number
  booking: ReceptionBookingDetails
}

export const ReservationRoomBillTransactionsListModal: React.FC<Props> = ({
  booking,
  toggleIsVisible,
  transactionId,
}) => {
  const transactions = useAppSelector(selectReservationWalletTransactions)

  const transaction = React.useMemo(
    () => transactions.find(transaction => transaction.id === transactionId),
    [transactionId, transactions],
  )

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>Lista transakcji</ModalHeader>
      <ModalBody>
        <table className="table mb-0">
          <tbody>
            {transaction?.bills.map((bill, index) => (
              <tr key={bill.id}>
                <td className={classnames('p-0', { 'border-top-0': index === 0 })}>
                  <ReservationRoomBillTransactionsListTable
                    booking={booking}
                    bill={bill}
                    expandableRowClassName={classnames({ 'bg-grey-light': index % 2 === 0 })}
                  />
                </td>
              </tr>
            ))}

            {!transaction?.bills.length && <p className="text-center mb-0">Brak wykonanych transakcji</p>}
          </tbody>
        </table>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={toggleIsVisible}>
          Zamknij
        </Button>
      </ModalFooter>
    </>
  )
}
