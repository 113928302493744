import * as React from 'react'
import { formatDate } from '@helpers/date-helper'
import classname from 'classnames'
import { isToday, isWeekend } from 'date-fns'

interface Props {
  date: Date
  onHoverChange: (date: string | null) => void
  hoveredDate: string | null
}

export const ReservationStatsTableHeader: React.FC<Props> = ({ date, onHoverChange, hoveredDate }) => (
  <th
    onMouseEnter={() => onHoverChange(formatDate(date))}
    onMouseLeave={() => onHoverChange(null)}
    className={classname('text-center py-1 px-2 reservation-stats__header__days', {
      'reservation-stats__header--weekend': isWeekend(date) && !isToday(date),
      'reservation-stats__header--today': isToday(date),
      'reservation-stats__col--hovered': formatDate(date) === hoveredDate,
    })}
  >
    <div className="reservation-stats__header__indicator" />
    <span className="font-weight-bold d-block">{formatDate(date, 'dd.MM')}</span>
    <span className="font-weight-light">{formatDate(date, 'ccc')}</span>
  </th>
)
