import * as React from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Button, Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useFormRequest } from '@components/hooks/use-api-request'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { PackageWholesaleCreateModalForm } from '@modules/package-wholesale/create/form'
import { useAppDispatch } from '@store/index'
import { getPackageWholesaleProducts } from '@store/actions/package-wholesale-actions'
import { packageWholesaleCreate } from '@api/package-wholesale'
import { BaseModalProps } from '@components/modals/types'
import { updatePackageWholesaleDetails } from '@store/slices/package-wholesale-slice'
import { add, startOfToday } from 'date-fns'
import { formatDate } from '@helpers/date-helper'
import {
  ReceptionBookingCheckStep,
  ReceptionBookingCheckSteps,
  showStepError,
} from '@modules/reception/common/reception-booking-check-steps'
import { PackageWholesaleCreateModalFormSummary } from '@modules/package-wholesale/create/form-summary'
import { useProductSaleConfirmationModal } from '@components/sale/use-product-sale-confirmation-modal'
import { useModal } from '@components/modals/use-modal'
import { Form } from '@hyper/forms/form'

const fieldsToOmit: [keyof PackageWholesaleFormInputs] = ['password_protected']

const steps: ReceptionBookingCheckStep[] = [
  {
    step: 1,
    description: 'Parametry sprzedaży',
    fields: ['name', 'products', 'sellers'],
  },
  {
    step: 2,
    description: 'Podsumowanie sprzedaży',
  },
]

export interface PackageWholesaleFormInputs {
  email: string
  expire_after: Date
  invoice_city: string
  invoice_company: string
  invoice_nip: string
  invoice_postcode: string
  invoice_street: string
  leaflet_gastro_card: File | undefined
  leaflet_gastro_card_boost: File | undefined
  leaflet_gastro_card_2024: File | undefined
  leaflet_gastro_card_2025: File | undefined
  leaflet_subscription: File | undefined
  leaflet_subscription_brown: File | undefined
  leaflet_subscription_gold: File | undefined
  leaflet_subscription_silver: File | undefined
  name: string
  password: string
  password_protected: boolean
  phone: string
  products: string[]
  sellers: CustomReactSelectOption[]
  slug: string
}

export const PackageWholesaleCreateModal: React.FC<BaseModalProps> = ({ toggleIsVisible }) => {
  const [step, setStep] = React.useState(1)
  const methods = useForm<PackageWholesaleFormInputs>({
    defaultValues: {
      products: [],
      expire_after: add(startOfToday(), { days: 7 }),
      password: '',
      password_protected: false,
    },
  })

  const { showConfirmationPackageCreation } = useProductSaleConfirmationModal()
  const [showPackageWholesaleDetails] = useModal('PackageWholesaleDetailsModal')

  const dispatch = useAppDispatch()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: PackageWholesaleFormInputs) => {
    const formData = new FormData()

    try {
      Object.entries(payload).forEach(([field, data]) => {
        if (fieldsToOmit.includes(field as keyof PackageWholesaleFormInputs)) return

        if (field === 'sellers') {
          if (!data) return
          data.forEach(value => {
            formData.append('sellers', value.value)
          })
        } else if (field === 'expire_after') {
          formData.append('expire_after', formatDate(data))
        } else if (field === 'products') {
          data.forEach(product => {
            formData.append(field, product)
          })
        } else {
          formData.append(field, data)
        }
      })

      const packageWholesaleDetails = await packageWholesaleCreate(formData)
      showConfirmationPackageCreation(() =>
        showPackageWholesaleDetails(null, { packageWholesale: packageWholesaleDetails }),
      )

      dispatch(updatePackageWholesaleDetails(packageWholesaleDetails))
      toggleIsVisible()
    } catch (error) {
      showStepError(setStep, steps, error)
      throw error
    }
  }, methods.setError)

  React.useEffect(() => {
    dispatch(getPackageWholesaleProducts({ page_size: 9999 }))
  }, [])

  const data = useWatch({ control: methods.control })

  const handlePrevStep = () => setStep(curr => Math.max(1, curr - 1))
  const handleNextStep = () => setStep(curr => curr + 1)

  const isNextStepDisabled = React.useMemo(() => {
    if (step === 1) return !data.name || !data.products?.length
  }, [data, step])

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Sprzedaj pakiet hurtowy</ModalHeader>
      <ReceptionBookingCheckSteps step={step} steps={steps} />
      <ModalBody className="pb-0">
        {step === 1 && <PackageWholesaleCreateModalForm />}
        {step === 2 && <PackageWholesaleCreateModalFormSummary />}
      </ModalBody>
      <ModalFooter className="d-block">
        <Row>
          <Col md={6} className="p-0">
            {step !== 1 && (
              <Button onClick={handlePrevStep} className="btn btn-light">
                Wstecz
              </Button>
            )}
          </Col>
          <Col md={6} className="text-right p-0">
            {step === 2 ? (
              <SaveButton
                role="submit"
                labelSaving="Sprzedawanie..."
                label="Sprzedaj"
                className="btn btn-green"
                isSaving={isLoading}
              />
            ) : (
              <Button onClick={handleNextStep} className="btn btn-green" role="next-step" disabled={isNextStepDisabled}>
                Następny krok
              </Button>
            )}
          </Col>
        </Row>
      </ModalFooter>
    </Form>
  )
}
