import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { Card, CardBody } from 'reactstrap'
import { ContentNote } from '@models/dashboard'
import { useToggle } from '@components/hooks/use-toggle'
import { ContentNoteAdd } from '@components/content-notes/content-note-add'
import { ContentNotesCardFooter } from '@components/content-notes/content-notes-card-footer'
import { ContentNotesCardContent } from '@components/content-notes/content-notes-card-content'
import { compareDesc } from 'date-fns'
import { parseISODate } from '@helpers/date-helper'
import { ContentNoteCardActions } from '@components/content-notes/content-note-card-actions'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { NotificationsEmptyMessage } from '@components/notification-box/notifications-empty-message'

const INITIAL_AMOUNT_OF_NOTES_TO_SHOW = 3

interface FormInputs {
  showRemovedNotes: boolean
}

interface Props {
  notes: ContentNote[]
  onNotesUpdate: (note: ContentNote) => void
  readOnly: boolean
  objectId: number
  contentType: number
  title?: string
  hideCard?: boolean
  withActions?: boolean
}

export const ContentNotesCard = ({
  notes,
  objectId,
  title,
  contentType,
  onNotesUpdate,
  hideCard,
  readOnly,
  withActions = false,
}: Props): JSX.Element => {
  const methods = useForm<FormInputs>({ defaultValues: { showRemovedNotes: false } })
  const showRemovedNotes = useWatch({ control: methods.control, name: 'showRemovedNotes' })

  const allNotes = React.useMemo(
    () =>
      [...notes].sort((a, b) =>
        compareDesc(parseISODate(a.pinned_datetime) ?? 1, parseISODate(b.pinned_datetime) ?? 1),
      ),
    [notes],
  )

  const initialNotes = allNotes.reduce((notes, note) => {
    if (notes.length < INITIAL_AMOUNT_OF_NOTES_TO_SHOW && (!note.is_removed || showRemovedNotes)) {
      return [...notes, note]
    }
    return notes
  }, [])

  const filterDeletedNotes = (note: ContentNote) => {
    if (note.is_removed) return showRemovedNotes
    return true
  }

  const [allNotesShowed, toggleAllNotesShowed] = useToggle(false)
  const [notesToShow, setNotesToShow] = React.useState<ContentNote[]>([])
  const [noteToEdit, setNoteToEdit] = React.useState<ContentNote | null>(null)

  const hasMoreNotesToShow = allNotes.filter(filterDeletedNotes).length > INITIAL_AMOUNT_OF_NOTES_TO_SHOW

  const updateNotesToShow = () => {
    toggleAllNotesShowed()
    setNotesToShow((allNotesShowed ? initialNotes : allNotes).filter(filterDeletedNotes))
  }

  React.useEffect(() => {
    setNotesToShow((allNotesShowed ? allNotes : initialNotes).filter(filterDeletedNotes))
  }, [allNotes, showRemovedNotes])

  const CardWrapper = React.useMemo(() => (hideCard ? React.Fragment : Card), [hideCard])
  const CardBodyWrapper = React.useMemo(() => (hideCard ? React.Fragment : CardBody), [hideCard])

  const notesAmount = showRemovedNotes ? allNotes.length : allNotes.filter(filterDeletedNotes).length

  return (
    <CardWrapper>
      <CardBodyWrapper className="pb-2">
        {!hideCard && (
          <FormProvider {...methods}>
            <div className="d-flex mb-2">
              <IconWithText
                icon="uil-notes font-18"
                text={title || 'Notatki'}
                wrapperClassNames="text-secondary text-semi-strong"
                textClass="fw-bold font-18 text-nowrap"
              />
              {withActions && <ContentNoteCardActions />}
            </div>
          </FormProvider>
        )}
        {!readOnly && (
          <ContentNoteAdd
            objectId={objectId}
            contentType={contentType}
            noteToEdit={noteToEdit}
            onNotesUpdate={onNotesUpdate}
            onClearNoteToEdit={() => setNoteToEdit(null)}
          />
        )}
        <div style={{ maxHeight: '400px' }} className="overflow-auto light-scrollbar mr-n2 pr-2 mt-2">
          {notesToShow.map(note => (
            <ContentNotesCardContent
              readonly={readOnly}
              key={note.id}
              note={note}
              onNoteChange={onNotesUpdate}
              className="mb-2"
              onNoteToEditChange={setNoteToEdit}
            />
          ))}

          {!notesToShow.length && (
            <NotificationsEmptyMessage image={require('@assets/images/no-notes-placeholder.svg')}>
              Brak notatek <br /> <strong>Dodaj pierwszą</strong>
            </NotificationsEmptyMessage>
          )}
        </div>
      </CardBodyWrapper>
      {hasMoreNotesToShow && (
        <ContentNotesCardFooter
          contentName="notatki"
          allNotesShowed={allNotesShowed}
          allNotesLength={notesAmount}
          onShowAllNotesClick={updateNotesToShow}
        />
      )}
    </CardWrapper>
  )
}
