import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@store/index'
import { NotificationMessage } from '@hyper/notifications-models'

const notificationMessageAdapter = createEntityAdapter<NotificationMessage>()

interface NotificationState {
  notificationMessages: EntityState<NotificationMessage>
}

const initialState: NotificationState = {
  notificationMessages: notificationMessageAdapter.getInitialState(),
}

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    addNotificationMessage(state, action: PayloadAction<NotificationMessage>) {
      notificationMessageAdapter.addOne(state.notificationMessages, action.payload)
    },
    updateNotificationMessage(state, action: PayloadAction<NotificationMessage>) {
      notificationMessageAdapter.updateOne(state.notificationMessages, {
        id: action.payload.id,
        changes: action.payload,
      })
    },
    removeNotificationMessage(state, action: PayloadAction<NotificationMessage>) {
      notificationMessageAdapter.removeOne(state.notificationMessages, action.payload.id)
    },
  },
})

export const { addNotificationMessage, removeNotificationMessage, updateNotificationMessage } =
  notificationSlice.actions

export const { selectAll: notificationMessagesSelector } = notificationMessageAdapter.getSelectors(
  (state: RootState) => state.notificationState.notificationMessages,
)

export default notificationSlice.reducer
