import { createAsyncThunk } from '@reduxjs/toolkit'
import { AsyncThunkParams, RootState } from '@store/index'
import { setDashboardStandardPaginator } from '@store/actions/dashboard-actions'
import {
  PromocodeTouristVoucher,
  PromocodeTouristVoucherDetails,
} from '@modules/promotions/promocode-tourist-voucher/models'
import { commonObjectGet, commonObjectPatch, commonObjectPost, wrapWithError } from '@store/actions/generic-actions'
import { PaginationResponse } from '@models/dashboard'
import { PromocodeTouristVoucherCreateFormInputs } from '@modules/promotions/promocode-tourist-voucher/create-dialog'
import { PromocodeTouristVoucherFiltersParams } from '@modules/promotions/promocode-tourist-voucher/filters'
import { PromocodeTouristVoucherUpdateFormInputs } from '@modules/promotions/promocode-tourist-voucher/info-edit-dialog'

export const getPromocodeTouristVouchers = createAsyncThunk<
  PromocodeTouristVoucher[],
  Partial<PromocodeTouristVoucherFiltersParams>,
  AsyncThunkParams
>('promocodeTouristVouchers/getPromocodeTouristVouchers', async (params, { getState, dispatch }) => {
  const url = getState().appState.appData.urls.promotions.promocode_tourist_vouchers

  const data = await commonObjectGet<PaginationResponse<PromocodeTouristVoucher>>(url, {
    ...params,
    status: params.status?.map(row => row.value),
    seller: params?.seller?.value,
  })
  dispatch(setDashboardStandardPaginator(data))

  return data.results
})

export const updatePromocodeTouristVoucher = createAsyncThunk<
  PromocodeTouristVoucherDetails,
  [PromocodeTouristVoucher, Partial<PromocodeTouristVoucherUpdateFormInputs>]
>(
  'promocodeTouristVouchers/updatePromocodeTouristVoucher',
  async ([promocodeTouristVoucher, payload]) =>
    await wrapWithError(
      commonObjectPatch<PromocodeTouristVoucherDetails>(promocodeTouristVoucher.urls.details, {
        ...payload,
        source_marketing: payload.source_marketing?.value,
        seller: payload.seller?.value,
      }),
    ),
)

export const createPromocodeTouristVoucher = createAsyncThunk<
  PromocodeTouristVoucherDetails,
  PromocodeTouristVoucherCreateFormInputs,
  { state: RootState }
>(
  'promocodeTouristVouchers/createPromocodeTouristVoucher',
  async (payload, { getState }) =>
    await wrapWithError(
      commonObjectPost<PromocodeTouristVoucherDetails>(
        getState().appState.appData.urls.promotions.promocode_tourist_vouchers,
        {
          ...payload,
          source_marketing: payload.source_marketing?.value,
          seller: payload.seller?.value,
        },
      ),
    ),
)

export const getPromocodeTouristVoucher = createAsyncThunk<
  PromocodeTouristVoucherDetails,
  PromocodeTouristVoucher,
  AsyncThunkParams
>(
  'promocodeTouristVouchers/getPromocodeTouristVoucher',
  async promocodeTouristVoucher =>
    await commonObjectGet<PromocodeTouristVoucherDetails>(promocodeTouristVoucher.urls.details),
)
