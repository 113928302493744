import * as React from 'react'
import { ReceptionBookingNewFeedingDayRow } from '@modules/reception/checkin/step-feeding/new-feeding/day-row/reception-booking-new-feeding-day-row'
import { groupByField } from '@helpers/utils'
import { NewFeedingCalculationDetails } from '@modules/reception/checkin/step-feeding/new-feeding/models'
import { setLastPriceChangeReason, setLastPriceRestorationReason } from '@store/slices/new-feeding-slice'
import { useAppDispatch } from '@store/index'

interface Props {
  calculationDetails: NewFeedingCalculationDetails[]
}

export const ReceptionBookingNewFeedingDayRows = ({ calculationDetails }: Props): JSX.Element => {
  const dispatch = useAppDispatch()
  const mealsGroupedByDay = Object.entries(groupByField(calculationDetails, 'date'))

  React.useEffect(
    () => () => {
      dispatch(setLastPriceChangeReason(''))
      dispatch(setLastPriceRestorationReason(''))
    },
    [],
  )

  return (
    <div className="mt-2 px-4">
      {mealsGroupedByDay.map(([date, calculationDetails], index) => (
        <ReceptionBookingNewFeedingDayRow
          key={date}
          date={date}
          calculationDetails={calculationDetails}
          position={{
            first: index === 0,
            last: index === mealsGroupedByDay.length - 1,
          }}
        />
      ))}
    </div>
  )
}
