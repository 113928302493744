import * as React from 'react'
import Table from '@components/table/table'
import { useModal } from '@components/modals/use-modal'
import { CouponType } from '@modules/coupons/models'
import { YesNoBadge } from '@components/badges/yes-no'
import { useResortById } from '@components/hooks/use-resorts-and-accommodation-types-options'

interface Props {
  couponType: CouponType
}

export const CouponTypesTableRow = ({ couponType }: Props): JSX.Element => {
  const [showCouponTypeModal] = useModal('CouponTypeCreateModal', { couponType })

  const resort = useResortById(couponType.configuration.resort)

  return (
    <Table.Row>
      <Table.Cell>{couponType.name}</Table.Cell>
      <Table.Cell>{resort?.name}</Table.Cell>
      <Table.Cell>{`${couponType.configuration.usage_hour_from} - ${couponType.configuration.usage_hour_to}`}</Table.Cell>
      <Table.Cell>{`${couponType.configuration.purchase_hour_from} - ${couponType.configuration.purchase_hour_to}`}</Table.Cell>
      <Table.Cell>
        <YesNoBadge value={couponType.is_active} />
      </Table.Cell>
      <Table.Cell style={{ width: 40 }}>
        <i className="uil-pen font-14 lh-1 cursor-pointer" onClick={showCouponTypeModal} />
      </Table.Cell>
    </Table.Row>
  )
}
