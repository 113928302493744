import * as React from 'react'
import { Card, CardBody } from 'reactstrap'
import { TypedQueryResult } from '@api/base'
import { TableFilters } from '@components/table/table'
import { BookingOffersTable } from '@modules/booking-offers/table'
import { BookingOffer } from '@models/booking-offers'
import { useGetBookingOffersQuery } from '@api/booking-offers'
import { BookingOffersFilters } from '@modules/booking-offers/filters'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { PageTitleWithSize } from '@hyper/page-title-with-size'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'

export interface BookingOffersFiltersParams extends TableFilters {
  accommodation_type?: CustomReactSelectOption
  resort?: CustomReactSelectOption
  days?: CustomReactSelectOption
  date_from?: string
  date_to?: string
}

const defaultFilters: BookingOffersFiltersParams = {
  search: '',
  ordering: 'name',
  page: 1,
  page_size: 25,
}

const emptyList = []

export const BookingOffersView: React.FC = () => {
  const [filters, setFilters] = React.useState<BookingOffersFiltersParams>(defaultFilters)

  const { data: bookingOffers = emptyList, isLoading } = useGetBookingOffersQuery(filters) as TypedQueryResult<
    BookingOffer[]
  >

  useMenuActive(NavigationPath.BookingOffers)

  return (
    <>
      <PageTitleWithSize title="Wyszukiwarka terminów" />

      <Card>
        <CardBody>
          <BookingOffersFilters defaultFilters={defaultFilters} setFilters={setFilters} filters={filters} />
          <BookingOffersTable
            isLoading={isLoading}
            bookingOffers={bookingOffers}
            setFilters={setFilters}
            filters={filters}
          />
        </CardBody>
      </Card>
    </>
  )
}
