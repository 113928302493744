import * as React from 'react'
import { DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap'
import { RefundBookingsTableActionsPackage } from '@modules/accounting/refund/bookings/table-actions-package'
import { ColAuto } from '@hyper/col-auto'
import { RecommendationGift } from '@models/crm'

interface Props {
  selectedRows: RecommendationGift[]
  clearSelectedRows: () => void
}

export const RecommendationGiftsTableActions: React.FC<Props> = ({ selectedRows, clearSelectedRows }) => {
  const isDisabled = selectedRows.filter(row => row.transfer_status !== 'transfer_sent').length === 0

  return (
    <ColAuto className="mt-3">
      <UncontrolledButtonDropdown disabled={isDisabled}>
        <DropdownToggle color="light" caret={true} disabled={isDisabled}>
          <strong>zaznaczone ({selectedRows.length})</strong>
        </DropdownToggle>
        <DropdownMenu>
          <RefundBookingsTableActionsPackage
            clearSelectedRows={clearSelectedRows}
            name="recommendation_gifts"
            selectedRows={selectedRows.filter(row => row.transfer_status === 'initial')}
          />
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </ColAuto>
  )
}
