import * as React from 'react'
import { Button, Col, Row } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'
import { FiltersHousekeepingApplicationFiltersParams } from '@modules/housekeeping/applications/filters'
import { UserPermission } from '@models/dashboard'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'

interface Props {
  filters: FiltersHousekeepingApplicationFiltersParams
  resort: number
  title: string
  url: string
  permissionRequired: UserPermission
}

export const HousekeepingApplicationExportButtons: React.FC<Props> = ({
  permissionRequired,
  filters,
  title,
  url,
  resort,
}) => {
  const user = useAuthenticatedUser()
  const [toggleIsOpen] = useModal('ReportsApplicationOrders', {
    url,
    title,
    dateFrom: filters.created_after,
    dateTo: filters.created_before,
    status: filters.status,
    resort: resort,
  })

  if (!user.hasPerm(permissionRequired)) {
    return null
  }

  return (
    <Row>
      <Col md={12} className="text-right">
        <Button color="secondary" className="text-semi-strong" onClick={toggleIsOpen}>
          <i className="uil-download-alt mr-1" />
          Pobierz
        </Button>
      </Col>
    </Row>
  )
}
