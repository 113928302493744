import * as React from 'react'
import { PageTitle } from '@hyper/page-title'
import { useDocumentTitle } from '@helpers/utils'
import { Button, Card, CardBody } from 'reactstrap'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { TableFilters } from '@components/table/table'
import { CouponsClientDetailsFilters } from '@modules/coupons/list/client-details/coupons-client-details-filters'
import { CouponsClientDetailsTable } from '@modules/coupons/list/client-details/coupons-client-details-table'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { Link, useLocation, useParams } from 'react-router-dom'
import { IconWithText } from '@components/icon-with-text'
import { useAppData } from '@components/hooks/use-app-data'
import { useGetClientCouponItemsQuery } from '@api/coupons'
import { ClientCouponItem } from '@modules/coupons/models'
import { useQueryWithPaginationResponse } from '@components/hooks/use-query-hooks'

interface Params {
  client_id: string
}

export interface CouponsClientDetailsTableFilters extends TableFilters {
  status: CustomReactSelectOption | null
  purchase_date_after: Date | null
  purchase_date_before: Date | null
  expiration_date_after: Date | null
  expiration_date_before: Date | null
  use_date_after: Date | null
  use_date_before: Date | null
}

const defaultFilters: CouponsClientDetailsTableFilters = {
  ordering: '-created',
  page: 1,
  page_size: 10,
  search: '',
  status: null,
  purchase_date_after: null,
  purchase_date_before: null,
  expiration_date_after: null,
  expiration_date_before: null,
  use_date_after: null,
  use_date_before: null,
}

const emptyList = []

export const CouponsClientDetailsView: React.FC = () => {
  const { state } = useLocation()

  const pageTitle = `Lista kuponów klienta ${state?.clientName || ''}`

  const [filters, setFilters] = React.useState<CouponsClientDetailsTableFilters>(defaultFilters)

  const { urls } = useAppData()

  const { client_id } = useParams() as unknown as Params

  useDocumentTitle(pageTitle)
  useMenuActive(NavigationPath.CouponsClient)

  const { data: coupons = emptyList, isLoading } = useQueryWithPaginationResponse<ClientCouponItem[]>(
    useGetClientCouponItemsQuery([`${urls.coupons.list}?client_id=${client_id}`, filters]),
  )

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitle} />
      <Card>
        <CardBody>
          <Link to={NavigationPath.CouponsList}>
            <Button color="light" title="Wróć do listy klientów" className="py-1 mb-3">
              <IconWithText icon="uil-angle-left font-16 lh-1" text="Wróć do listy klientów" />
            </Button>
          </Link>
          <CouponsClientDetailsFilters filters={filters} setFilters={setFilters} />
          <CouponsClientDetailsTable
            isLoading={isLoading}
            filters={filters}
            setFilters={setFilters}
            coupons={coupons}
          />
        </CardBody>
      </Card>
    </>
  )
}
