import * as React from 'react'
import { ImprovementOrder } from '@modules/housekeeping/models'
import * as R from 'ramda'
import { CustomInput } from 'reactstrap'
import { HousekeepingImprovementOrdersRow } from '@modules/housekeeping/cleaning-orders/improvement/row'
import { FormProvider, UseFormReturn } from 'react-hook-form'
import { RootState, useAppSelector } from '@store/index'
import { HousekeepingOrderTableHeaderLocal } from '@modules/housekeeping/common/table-headers/table-header-local'
import { HousekeepingImprovementOrderTableHeaderStatus } from '@modules/housekeeping/cleaning-orders/improvement/table-header-status'
import { HousekeepingImprovementOrderTableHeaderImprovements } from '@modules/housekeeping/cleaning-orders/improvement/table-header-improvements'
import { HousekeepingImprovementsOrderFiltersParams } from '@modules/housekeeping/cleaning-orders/improvement/index'
import { HousekeepingNoOrdersRow } from '@modules/housekeeping/cleaning-orders/common/no-orders'

interface Props {
  selectedOrders: ImprovementOrder[]
  improvementOrders: ImprovementOrder[]
  setSelectedOrders: (orders: ImprovementOrder[]) => void
  methods: UseFormReturn<HousekeepingImprovementsOrderFiltersParams, any>
}

export const HousekeepingImprovementOrdersTable: React.FC<Props> = ({
  improvementOrders,
  selectedOrders,
  setSelectedOrders,
  methods,
}) => {
  const toggleSelectedOrder = (cleaningOrder: ImprovementOrder, add: boolean) => {
    if (!add) {
      setSelectedOrders(selectedOrders.filter(row => row.id !== cleaningOrder.id))
    } else {
      setSelectedOrders([...selectedOrders, cleaningOrder])
    }
  }

  const ordersAllChecked = selectedOrders.length === improvementOrders.length && improvementOrders.length > 0

  const toggleSelectAllOrders = () => setSelectedOrders(ordersAllChecked ? [] : [...improvementOrders])

  const resortDetails = useAppSelector((state: RootState) => state.receptionState.resortDetails)

  return (
    <table className="housekeeping__orders_table table table-striped" role="table">
      <FormProvider {...methods}>
        <thead>
          <tr>
            <th className="border-top-0" style={{ width: 10 }}>
              <CustomInput
                id="toggle-all"
                type="checkbox"
                className="font-18"
                name="toggle-all"
                checked={ordersAllChecked}
                onChange={toggleSelectAllOrders}
              />
            </th>
            <HousekeepingOrderTableHeaderLocal />
            <th className="border-top-0">Firma</th>
            <th className="border-top-0">Wykonawca</th>
            <HousekeepingImprovementOrderTableHeaderStatus />
            <HousekeepingImprovementOrderTableHeaderImprovements resortDetails={resortDetails} />
            <th className="border-top-0" style={{ width: 400 }} />
          </tr>
        </thead>
      </FormProvider>
      {resortDetails && (
        <tbody>
          {!improvementOrders.length ? (
            <HousekeepingNoOrdersRow colSpan={7} />
          ) : (
            <>
              {sorter(improvementOrders).map(improvementOrder => (
                <HousekeepingImprovementOrdersRow
                  resortId={resortDetails?.id}
                  improvementOrder={improvementOrder}
                  key={improvementOrder.id}
                  toggleSelectedOrder={toggleSelectedOrder}
                  isChecked={selectedOrders.includes(improvementOrder)}
                />
              ))}
            </>
          )}
        </tbody>
      )}
    </table>
  )
}

const sorter = R.sortBy(
  (order: ImprovementOrder) =>
    ({
      PENDING: 1,
      IN_PROGRESS: 2,
      FINISHED: 3,
      ARCHIVED: 4,
    })[order.status],
)
