import * as React from 'react'
import { IssueOrderDetails } from '@modules/housekeeping/applications/models'
import { DetailsColRow } from '@hyper/details-col-row'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { IconWithText } from '@components/icon-with-text'
import classNames from 'classnames'

interface Props {
  issueOrder: IssueOrderDetails
}

export const HousekeepingApplicationIssuesPreviewModalTable: React.FC<Props> = ({ issueOrder }) => (
  <>
    <DetailsColRow
      label="Dodane"
      className="text-semi-strong py-1"
      value={
        <IconWithText
          icon="uil uil-calendar-alt"
          iconSize="font-15"
          text={toDefaultDateTimeFormat(issueOrder.created)}
        />
      }
    />
    <DetailsColRow
      label="Lokale"
      className="text-semi-strong py-1"
      value={
        <IconWithText icon="uil uil-estate" iconSize="font-15" text={issueOrder.apartment?.name || 'Część wspólna'} />
      }
    />
    <DetailsColRow
      label="Zgłaszający"
      className="text-semi-strong py-1"
      value={<IconWithText icon="uil uil-user" iconSize="font-15" text={issueOrder.created_by} />}
    />
    <DetailsColRow
      label="Nazwa usterki"
      className="text-semi-strong py-1"
      value={<IconWithText icon="uil uil-bookmark" iconSize="font-15" text={issueOrder.name} />}
    />

    <DetailsColRow
      label="Status"
      className="text-semi-strong py-1"
      value={
        <span
          className={classNames('badge', {
            'badge-dark': issueOrder.status === 'close',
            'badge-success': issueOrder.status === 'open',
          })}
        >
          {issueOrder.status_display}
        </span>
      }
    />
    <DetailsColRow
      className="text-semi-strong py-1"
      label="Wykonawca"
      value={`${toDefaultDateTimeFormat(issueOrder.finished_at)} przez ${issueOrder.finished_by || 'brak'}`}
    />
    <div className="hr hr-grey mb-3" style={{ marginLeft: -15, marginRight: -15 }} />
    <DetailsColRow label="Opis usterki" value={<span className="text-linebreaksbr">{issueOrder.description}</span>} />
    {!!issueOrder.images.length && (
      <>
        <div className="hr hr-grey mt-1 mb-3" style={{ marginLeft: -15, marginRight: -15 }} />
        <DetailsColRow
          label="Zdjęcia"
          value={
            <div className="d-flex ">
              {issueOrder.images.map(image => (
                <a href={image.image} target="_blank" key={image.id} className="mr-1">
                  <img src={image.image} style={{ maxWidth: 150 }} alt="" className="img-thumbnail" />
                </a>
              ))}
            </div>
          }
        />
      </>
    )}
  </>
)
