import * as React from 'react'
import { Card, CardBody } from 'reactstrap'
import { ProductCardTitle } from '@components/products/product-card-title'
import { SubscriptionDetailsInformationRow } from '@modules/subscription/details/information/row'
import { formatIban } from '@helpers/text'
import { HrFinance } from '@modules/hr/common/models'

interface Props {
  financeData: HrFinance
  isReadonly: boolean
  onEdit?: () => void
}

export const HrFinanceDetails = React.forwardRef<HTMLElement, Props>(({ isReadonly, financeData, onEdit }, ref) => (
  <Card innerRef={ref}>
    <CardBody>
      <ProductCardTitle title="Dane finansowe" isEditAllowed={!isReadonly} onEdit={onEdit} />
      <table className="table table-sm mb-0">
        <tbody>
          <SubscriptionDetailsInformationRow name="Nazwa banku">
            {financeData.bank_name}
          </SubscriptionDetailsInformationRow>
          <SubscriptionDetailsInformationRow name={<span className="text-nowrap">Numer konta bankowego</span>}>
            {formatIban(financeData.account_number)}
          </SubscriptionDetailsInformationRow>
          <SubscriptionDetailsInformationRow name="Urząd skarbowy">
            {financeData.tax_office}
          </SubscriptionDetailsInformationRow>
          <SubscriptionDetailsInformationRow name="Oddział NFZ">
            {financeData.national_health_fund_branch}
          </SubscriptionDetailsInformationRow>
        </tbody>
      </table>
    </CardBody>
  </Card>
))
