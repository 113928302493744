import * as React from 'react'
import { FeedingApartmentOption, FeedingApartmentOptionsGuest } from '@modules/feeding/models'
import { FeedingHostGuestsModalRow } from '@modules/feeding/host/guests-modal/row'
import { setFeedingEnter } from '@api/feeding'
import { useHandleAxiosServerErrors } from '@helpers/handle-axios-errors'
import { useFormContext } from 'react-hook-form'
import { FeedingHosFormInputs } from '@modules/feeding/host/feeding-host-with-params-view'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { ControlledFormCheckbox } from '@hyper/forms/controlled-form-checkbox'
import { formatDate, parseISODate } from '@helpers/date-helper'

interface FeedingHostApartmentInfoTableProps {
  option: FeedingApartmentOption
  onApartmentOptionChange: (option: FeedingApartmentOption) => void
  type: 'dinner' | 'breakfast'
}

export const FeedingHostApartmentInfoTable: React.FC<FeedingHostApartmentInfoTableProps> = ({
  option,
  onApartmentOptionChange,
  type,
}) => {
  const { getValues } = useFormContext<FeedingHosFormInputs>()
  const { addSuccessNotification } = useNotificationHook()
  const handleAxiosServerErrors = useHandleAxiosServerErrors()

  const setGuest = (guest: FeedingApartmentOptionsGuest) =>
    onApartmentOptionChange({
      ...option,
      guests: option.guests.map(row => ({ ...(row.id === guest.id ? guest : row) })),
    })

  const feedingAllChecked = option.guests.every(row => row.enter?.enter)
  const values = getValues()

  const toggleFeedingAll = async () => {
    const guests = [...option.guests]
    const formValues = getValues()

    try {
      const enters = await setFeedingEnter(
        guests
          .filter(row => (!feedingAllChecked ? !row.enter?.enter : row.enter?.enter))
          .map(guest => ({
            ...formValues,
            type: formValues.type.value,
            enter: !feedingAllChecked,
            guest: guest.booking_guest_id,
          })),
      )

      onApartmentOptionChange({
        ...option,
        guests: guests.map(row => ({
          ...row,
          enter: enters.find(enter => enter.booking_guest_id === row.booking_guest_id) || row.enter,
        })),
      })
      addSuccessNotification('Zmiany zostały zapisane')
    } catch (error) {
      onApartmentOptionChange({ ...option, guests })
      handleAxiosServerErrors(error)
    }
  }

  const reservationDates =
    option.reservation_date_to && option.reservation_date_from
      ? `${formatDate(parseISODate(option.reservation_date_from), 'd.LL')} -  ${formatDate(parseISODate(option.reservation_date_to), 'd.LL yyyy')}`
      : ''

  return (
    <>
      <h5 className="text-secondary">
        {option.name} {option.reservation_number && `(${option.reservation_number})`} {reservationDates}
      </h5>
      <table className="table table-striped table-bordered">
        <colgroup>
          <col width={10} />
          <col />
          <col width={150} />
          <col width={150} />
          {type === 'breakfast' && <col width={10} />}
        </colgroup>
        <thead>
          <tr>
            <th className="align-middle">
              <ControlledFormCheckbox
                id={`feeding-all-${option.reservation_number}`}
                checked={feedingAllChecked}
                onChange={toggleFeedingAll}
                label=""
              />
            </th>
            <th className="valign-middle">Imię i nazwisko</th>
            <th className="valign-middle">Wiek</th>
            <th className="valign-middle">Wejście</th>
            {type === 'breakfast' && <th />}
          </tr>
        </thead>
        <tbody>
          {option.guests.map(row => (
            <FeedingHostGuestsModalRow
              showButtons={type === 'breakfast'}
              hideResortColumn={true}
              setGuest={setGuest}
              key={row.id}
              guest={row}
              values={values}
            />
          ))}
        </tbody>
      </table>
    </>
  )
}
