import axios from 'axios'
import { formatDate } from '@helpers/date-helper'
import { HouseKeepingUsersReportParams } from '@store/actions/report-actions'

export async function getUserCleaningOrdersReport(url, params: HouseKeepingUsersReportParams) {
  const { data } = await axios.get(url, {
    params: {
      ...params,
      finished_at_after: formatDate(params.finished_at_after),
      finished_at_before: formatDate(params.finished_at_before),
    },
  })
  return data
}
