import * as React from 'react'
import { Button } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'
import { SubscriptionType } from '@modules/subscription/models'
import { useSubscriptionTypeStrategyProvider } from '@modules/subscription/hooks/use-subscription-type-provider'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'

interface Props {
  type: SubscriptionType
}
export const SubscriptionCreateButton: React.FC<Props> = ({ type }) => {
  const { product_name, crud_permissions } = useSubscriptionTypeStrategyProvider(type)
  const label = `Sprzedaj ${product_name}`
  const [toggleModal] = useModal('SubscriptionCreateModal', {
    label,
    type,
  })

  const user = useAuthenticatedUser()

  if (!user.hasPerm(crud_permissions.create)) {
    return null
  }

  return (
    <Button onClick={toggleModal} color="green-dark" type="button" role="subscription-create-button">
      <i className="mdi mdi-plus-circle mr-1" />
      {label}
    </Button>
  )
}
