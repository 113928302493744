import { Path, UseFormReturn } from 'react-hook-form'
import { FieldValues } from 'react-hook-form/dist/types/fields'
import { asDecimal, formatPriceShort } from '@helpers/utils'
import { AvailableGastroProductKind, GastroProductKind } from '@models/products'
import { GastroCardKind } from '@models/promotions'

export const getStatusClassIcon = (status: string): [string, string, string] => {
  const badgeClass = {
    initial: 'badge-info-lighten',
    initial_cancelled: 'badge-info-lighten',
    removed: 'badge-danger-lighten',
    confirmed: 'badge-success-lighten',
    open: 'badge-success-lighten',
    active: 'badge-success-lighten',
    cancelled: 'badge-danger-lighten',
    outdated: 'badge-danger-lighten',
    close: 'badge-danger-lighten',
    used: 'badge-warning-lighten',
  }[status]

  const iconName = {
    initial: 'mdi mdi-timer-sand',
    initial_canceled: 'mdi mdi-cancel',
    cancelled: 'mdi mdi-cancel',
    removed: 'mdi mdi-cancel',
    close: 'mdi mdi-cancel',
    confirmed: 'mdi mdi-coin',
    active: 'mdi mdi-coin',
    open: 'mdi mdi-coin',
    completed: 'uil-check-circle',
  }[status]

  const statusColor = {
    initial: 'blue',
    initial_canceled: 'warning',
    confirmed: 'green',
    open: 'green',
    active: 'green',
    completed: 'black',
    cancelled: 'grey',
    close: 'grey',
  }[status]

  return [badgeClass || '', iconName || '', statusColor || '']
}

export const isGastroCouponDeclaredSellPriceValid = <T extends FieldValues>(
  field: Path<T>,
  methods: UseFormReturn<T>,
  minValue: string,
) => {
  const price = methods.getValues(field)

  if (methods.formState.errors.declared_sell_price) {
    methods.clearErrors(field)
  }

  if (asDecimal(price).lt(minValue)) {
    methods.setError(field, { message: `Minimalna kwota zakupu to ${formatPriceShort(minValue)}` })
    return false
  }

  return true
}

export const isGastroGenericProduct = (kind?: GastroCardKind) => {
  if (!kind) return false

  return AvailableGastroProductKind.includes(kind as GastroProductKind)
}
