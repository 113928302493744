import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { Col } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'
import { CmsResortDetails } from '@modules/cms/models'
import { CmsReservationAppResortMapRow } from '@modules/cms/reservation-app/resort-modal/maps/row'

interface Props {
  resort: CmsResortDetails
}

export const CmsReservationAppResortMapsSectors = ({ resort }: Props): JSX.Element => {
  const [handleAddSector] = useModal('CmsReservationAppResortMapModal', { resort })

  return (
    <Col md={12}>
      {!!resort.map_sectors.length && (
        <ul>
          {resort.map_sectors.map(row => (
            <CmsReservationAppResortMapRow key={row.id} resort={resort} sector={row} />
          ))}
        </ul>
      )}
      <div className="text-right">
        <button className="btn btn-xs btn-primary" onClick={handleAddSector}>
          <IconWithText icon="uil-plus" text="dodaj sektor" />
        </button>
      </div>
    </Col>
  )
}
