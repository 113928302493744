import * as React from 'react'
import { GastroCard, GastroCardDetails } from '@models/promotions'
import { formatPrice } from '@helpers/utils'
import { parseISODate, toDefaultDateFormat } from '@helpers/date-helper'
import { useModal } from '@components/modals/use-modal'
import { Card, CardBody } from 'reactstrap'
import { useReportsAppData } from '@modules/reports/hooks/use-reports-app-data'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { ProductCardTitle } from '@components/products/product-card-title'
import { BadgeWithIcon } from '@components/badges/BadgeWithIcon'
import { getStatusClassIcon } from '@modules/promotions/gastro-cards/utils'
import { ProductSalePageButton } from '@components/products/product-action-button'
import { YesNoBadge } from '@components/badges/yes-no'
import { CopyToClipboard } from '@components/copy-to-clipboard'
import { PackageDetailsRow } from '@modules/package/details/information/row'
import { useAppSelector } from '@store/index'
import { resortsSelector } from '@store/selectors/dashboard'

interface GastroCardSummaryProps {
  gastroCardDetails: GastroCardDetails
}

export const GastroCardSummary: React.FC<GastroCardSummaryProps> = ({ gastroCardDetails }) => {
  const gastroCard: GastroCard = {
    ...(gastroCardDetails as any),
    sell_date: parseISODate(gastroCardDetails.sell_date),
    seller: gastroCardDetails.seller ? String(gastroCardDetails.seller?.id) : undefined,
    client: String(gastroCardDetails.client_id),
    has_invoice: gastroCardDetails.has_invoice,
    is_confirmed: gastroCardDetails.is_confirmed,
  }

  const user = useAuthenticatedUser()

  const [badgeClass, badgeIcon] = getStatusClassIcon(gastroCardDetails.status)

  const [showGastroCardDialog] = useModal('GastroCardDialog', {
    gastroCardClient: gastroCardDetails.client,
    gastroCard,
    isSellerReadonly:
      gastroCardDetails.is_seller_readonly &&
      !user.hasPerm(UserPermission.PromotionsGastroCardCanChangeSellerEvenWww) &&
      !user.hasPerm(UserPermission.PromotionsGastroCardCanChangeSellerInAnyVoucherStatus),
  })

  const { sources } = useReportsAppData()

  const sourceMarketing = React.useMemo(
    () => sources.find(row => row.id === gastroCardDetails.source_marketing),
    [gastroCardDetails, sources],
  )

  const allResorts = useAppSelector(resortsSelector)

  const gastroCardResorts = gastroCard.resorts.reduce((resorts, resortId) => {
    if (gastroCard.resorts.length === allResorts.length) return 'Wszystkie'
    const resort = allResorts.find(appResort => appResort.id === resortId)
    return resort ? `${resorts} ${resorts.length ? ',' : ''} ${resort.name}` : resorts
  }, '')

  const isGastroCardEditAllowed = () => {
    if (gastroCardDetails.is_removed) return false
    if (gastroCardDetails.status === 'initial') return true
    return (
      gastroCardDetails.status === 'confirmed' &&
      user.hasPerm(UserPermission.PromotionsGastroCardCanChangeSellerInAnyVoucherStatus)
    )
  }

  return (
    <Card>
      <CardBody>
        <ProductCardTitle
          title="Szczegóły vouchera"
          adminUrl={gastroCardDetails.urls.admin}
          isEditAllowed={isGastroCardEditAllowed()}
          onEdit={showGastroCardDialog}
        />
        <div className="table-responsive">
          <table className="table table-sm mb-2">
            <tbody>
              <PackageDetailsRow label="Status">
                <BadgeWithIcon icon={badgeIcon} className={badgeClass} title={gastroCardDetails.status_display} />
              </PackageDetailsRow>

              <PackageDetailsRow label="Data ważności">
                {toDefaultDateFormat(gastroCardDetails.active_after)} -{' '}
                {toDefaultDateFormat(gastroCardDetails.expire_after)}
              </PackageDetailsRow>

              <PackageDetailsRow label="Zadeklarowana kwota zakupu">
                {formatPrice(gastroCardDetails.declared_sell_price)}
              </PackageDetailsRow>

              <PackageDetailsRow label="Kwota zakupu">{formatPrice(gastroCardDetails.sell_price)}</PackageDetailsRow>
              <PackageDetailsRow label="Aktywny w ośrodkach">{gastroCardResorts}</PackageDetailsRow>

              <PackageDetailsRow label="Data utworzenia">
                {toDefaultDateFormat(gastroCardDetails.sell_date)}
              </PackageDetailsRow>

              <PackageDetailsRow label="Sprzedawca">
                {gastroCardDetails.seller?.name || 'Nieprzypisany'}
              </PackageDetailsRow>

              <PackageDetailsRow label="Źródło marketingowe">{sourceMarketing?.name || 'brak'}</PackageDetailsRow>

              <PackageDetailsRow label="Kwota pozostała do wykorzystania">
                {formatPrice(gastroCardDetails.wallet.available_amount)}
              </PackageDetailsRow>

              <PackageDetailsRow label="Kwota wykorzystana przez kupującego">
                {formatPrice(gastroCardDetails.wallet.client_used_amount)}
              </PackageDetailsRow>

              <PackageDetailsRow label="Kwota kodów dla znajomych">
                {formatPrice(gastroCardDetails.wallet.vouchers_used_amount)}
              </PackageDetailsRow>

              <PackageDetailsRow label="Dosprzedaż do promocji wyjazdowej">
                <YesNoBadge value={gastroCardDetails.is_departure_voucher_promotion} />
              </PackageDetailsRow>

              {gastroCardDetails.package_wholesale && (
                <PackageDetailsRow label="Pakiet hurtowy główny">
                  {gastroCardDetails.package_wholesale.number}
                  <CopyToClipboard className="ml-1 font-14" value={gastroCardDetails.package_wholesale.number} />
                </PackageDetailsRow>
              )}

              {!!gastroCardDetails.invoice_numbers.length && (
                <PackageDetailsRow label="Numer faktury">
                  {gastroCardDetails.invoice_numbers.map(invoice_number => (
                    <span key={invoice_number}>
                      <a href={`/admin/dashboard/quick-search/?query=${invoice_number}`}>{invoice_number}</a>
                    </span>
                  ))}
                </PackageDetailsRow>
              )}
            </tbody>
          </table>
        </div>
        <div className="mt-2 text-right">
          {!gastroCardDetails.is_started && <ProductSalePageButton saleUrl={gastroCardDetails.urls.sale} />}
        </div>
      </CardBody>
    </Card>
  )
}
