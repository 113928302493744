import { useProductSaleConfirmationModal } from '@components/sale/use-product-sale-confirmation-modal'
import { useCreateEndlessHolidayTransactionMutation } from '@api/endless-holiday'
import { EndlessHolidayCreateFormInputs } from '@modules/endless-holiday/transactions/create/modal'
import { createInvoiceData } from '@components/products/product-invoice-card'
import { useAppData } from '@components/hooks/use-app-data'
import { useModal } from '@components/modals/use-modal'
import { useHandleRtkQueryError } from '@components/hooks/use-handle-rtk-error'

interface Response {
  create: (onCreate: () => void) => (data: EndlessHolidayCreateFormInputs) => Promise<void>
  isLoading: boolean
}

export const useEndlessHolidayCreate = (steps, setStep, setError): Response => {
  const { urls } = useAppData()

  const { showConfirmationPackageCreation } = useProductSaleConfirmationModal()
  const [showDetailsModal] = useModal('EndlessHolidayDetailsModal')

  const [createEndlessHolidayTransaction, { isLoading, error, isError }] = useCreateEndlessHolidayTransactionMutation()
  useHandleRtkQueryError(error, isError, setError, { setStep, steps })

  const create = (onCreate: () => void) => async (data: EndlessHolidayCreateFormInputs) => {
    const gastroProducts = data.products.reduce((products, product) => {
      if (!product.gastro_card) return products
      return [
        ...products,
        { product: product.gastro_card, related_product: product.subscription, amount: product.amount },
      ]
    }, [])

    const transaction = await createEndlessHolidayTransaction({
      url: urls.product_packages.product_package_list,
      payload: {
        client: data.client?.id,
        product_package_set: data.product_package_set?.value,
        products: [
          ...data.products.map(product => ({
            product: product.subscription,
            amount: product.amount,
            related_product: null,
            single_client_date: product.options.some(
              option => option.value === 'yes' && option.context.code === 'single_client_date',
            ),
          })),
          ...gastroProducts,
        ],
        seller: data.seller?.value,
        source_marketing: data.source_marketing?.value,
        ...(!!data.invoice_nip && { ...createInvoiceData(data), invoice: true }),
      },
    }).unwrap()

    showConfirmationPackageCreation(() => showDetailsModal(null, { product: transaction }))

    onCreate()
  }

  return {
    create,
    isLoading,
  }
}
