import * as React from 'react'
import { Col } from 'reactstrap'
import { PromocodeTouristVoucherDetails } from '@modules/promotions/promocode-tourist-voucher/models'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { updatePromocodeTouristVoucherDetails } from '@store/slices/promocode-tourist-voucher-slice'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'

interface Props {
  promocodeTouristVoucherDetails: PromocodeTouristVoucherDetails
}

export const PromocodeTouristVoucherDetailsCancel = ({ promocodeTouristVoucherDetails }: Props): JSX.Element => {
  const dispatch = useAppDispatch()

  const { addSuccessMessage } = useNotificationHook()

  const { isLoading, action: handleAccept } = useApiRequest(async () => {
    dispatch(
      updatePromocodeTouristVoucherDetails(
        await commonObjectDelete<PromocodeTouristVoucherDetails>(promocodeTouristVoucherDetails.urls.details),
      ),
    )
    addSuccessMessage('Sukces', 'Voucher Turystyczny został anulowany')
  })

  return ['confirmed', 'initial'].includes(promocodeTouristVoucherDetails.status) ? (
    <Col md={6}>
      <CommonObjectConfirmAction
        title="Potwierdź anulowanie Voucheru Turystycznego"
        askForReason={false}
        isLoading={isLoading}
        handleAccept={handleAccept}
        message="Czy na pewno chcesz anulować wybrany voucher turystyczny ? Operacja ta jest nieodwołalna"
      >
        <button className="btn btn-outline-danger">Anuluj</button>
      </CommonObjectConfirmAction>
    </Col>
  ) : (
    <Col md={6} />
  )
}
