import * as React from 'react'
import { ButtonWithIcon } from '@components/button-with-icon'
import { GuestAccountPaymentBox } from '@modules/shop/pos/purchase-payments/guest-account-payment-box'
import { Col, Row } from 'reactstrap'
import classNames from 'classnames'
import { useFormContext, useWatch } from 'react-hook-form'
import { PaymentMethods } from '@modules/shop/pos/models'
import { asDecimal } from '@helpers/utils'
import { confirmShopReceipt, updateShopReceipt } from '@store/actions/shop-actions'
import { CardPaymentBox } from '@components/card-payment-box'
import { CashPaymentBox } from '@components/cash-payment-box'
import { PurchasePaymentSuccessBox } from '@modules/shop/pos/purchase-payments/purchase-payment-success-box'
import { CUSTOM_AXIOS_ERROR, useHandleAxiosFormErrors } from '@helpers/handle-axios-errors'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { Apartment } from '@models/apartment'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { PurchaseBandPayment } from '@modules/shop/pos/purchase-payments/band/purchase-band-payment'
import { useApiRequest, useFormRequest } from '@components/hooks/use-api-request'
import { ShopPOSWithParamsFormInputs } from '@modules/shop/pos/shop-pos-with-params'
import { ShopPurchasePaymentKind } from '@modules/shop/pos/purchase-payments/shop-purchase-payment-kind'

interface Props {
  resortId: string
  availablePaymentMethods: PaymentMethods[]
  apartment?: Apartment
}

export const ShopPurchasePayment: React.FC<Props> = ({ availablePaymentMethods, resortId, apartment }) => {
  const [paymentMethod, setPaymentMethod] = React.useState<PaymentMethods | null>(
    apartment ? PaymentMethods.Wallet : null,
  )
  const [saleConfirmationUrl, setSaleConfirmationUrl] = React.useState('')
  const [documentsPrintUrl, setDocumentsPrintUrl] = React.useState('')

  const handleAxiosFormErrors = useHandleAxiosFormErrors()
  const { addErrorMessage } = useNotificationHook()
  const resortDetails = useAppSelector((state: RootState) => state.receptionState.resortDetails)
  const { getValues, setValue, setError, control } = useFormContext<ShopPOSWithParamsFormInputs>()
  const { paymentEnabled, amount, kind } = useWatch({ control })
  const receipt = useAppSelector((state: RootState) => state.shopState.receipt)

  const content_type = useAppSelector((state: RootState) => state.appState.appData.content_types.receipt)
  const dispatch = useAppDispatch()

  const isInvoiceSelected = kind === 'invoice'

  React.useEffect(() => {
    if (isMethodSelected(PaymentMethods.Wallet) && isInvoiceSelected) {
      setPaymentMethod(PaymentMethods.Cash)
    }
  }, [paymentMethod, isInvoiceSelected])

  const changePaymentMethod = async (method: PaymentMethods) => {
    await updateReceipt()
    setValue('apartment', null)
    setPaymentMethod(method)
  }

  const isMethodSelected = (method: PaymentMethods | null) => paymentMethod === method
  const clearPaymentMethod = () => {
    setValue('apartment', null)
    setPaymentMethod(null)
  }

  const { action: updateReceipt } = useFormRequest(async () => {
    const payload = getValues()
    if (receipt) {
      await dispatch(
        await updateShopReceipt([
          receipt.urls.details,
          {
            kind: payload.kind,
            invoice_city: payload.invoice_city,
            invoice_company: payload.invoice_company,
            invoice_postcode: payload.invoice_postcode,
            invoice_street: payload.invoice_street,
            invoice_nip: payload.invoice_nip,
          },
        ]),
      )
    }
  }, setError)

  const handleBandPayment = async (guestId: number) => {
    await handlePayment({
      booking_guest: guestId,
      payment: PaymentMethods.Band,
    })
  }

  const handleGuestAccountPayment = async (apartmentId: number, guestId: number) =>
    await handlePayment({
      payment: PaymentMethods.Wallet,
      apartment: apartmentId,
      guest: guestId,
    })

  const handleCardPayment = async () => await handlePayment({ payment: PaymentMethods.Card })
  const handleCashPayment = async () => await handlePayment({ payment: PaymentMethods.Cash })

  const { isLoading, action: handlePayment } = useApiRequest(async payload => {
    try {
      if (receipt) {
        setSaleConfirmationUrl('')
        setDocumentsPrintUrl('')
        const { payload: receiptDetails } = (await dispatch(
          await confirmShopReceipt([receipt.urls.confirm, payload]),
        )) as any

        setSaleConfirmationUrl(receiptDetails.urls.confirmation_url)
        setDocumentsPrintUrl(receiptDetails.urls.documents_print)
        setPaymentMethod(null)
      }
    } catch (error) {
      const errorData = error.name === CUSTOM_AXIOS_ERROR ? JSON.parse(error.message) : error.response?.data

      if (paymentMethod === PaymentMethods.Band && errorData.booking_guest) {
        addErrorMessage('Wystąpił błąd', errorData.booking_guest)
      } else if (errorData.non_field_errors) {
        addErrorMessage('Wystąpił błąd', errorData.non_field_errors[0])
      } else {
        handleAxiosFormErrors(error, setError, { showGlobalError: false })
      }
    }
  })

  const restToPay = receipt?.required_payment || '0'

  const isPaymentSubmitDisabled = asDecimal(amount).lessThan(asDecimal(restToPay))
  const paymentCompleted = receipt ? asDecimal(receipt.required_payment).lessThanOrEqualTo(asDecimal(0)) : false

  return (
    <Col xl={4} className={classNames({ 'shop-purchase__payment--disabled': !paymentEnabled })}>
      <h5 className="text-secondary">Płatność</h5>
      <div className="border bg-white-smoke-dark p-3">
        {paymentCompleted ? (
          <PurchasePaymentSuccessBox
            confirmationUrl={saleConfirmationUrl}
            documentsPrintUrl={documentsPrintUrl}
            resortId={resortId}
          />
        ) : (
          <>
            <ShopPurchasePaymentKind />
            <p className="text-primary font-weight-bold font-14">Wybierz rodzaj płatności:</p>
            <Row className="mb-2">
              {availablePaymentMethods.includes(PaymentMethods.Cash) && (
                <Col md={6}>
                  <ButtonWithIcon
                    btnClass={classNames('w-100', {
                      'opacity-5': !isMethodSelected(PaymentMethods.Cash) && !isMethodSelected(null),
                    })}
                    icon="uil-money-bill"
                    text="Gotówka"
                    color="primary"
                    handleClick={() => changePaymentMethod(PaymentMethods.Cash)}
                  />
                </Col>
              )}
              {availablePaymentMethods.includes(PaymentMethods.Card) && (
                <Col md={6}>
                  <ButtonWithIcon
                    icon="uil-credit-card"
                    text="Karta"
                    handleClick={() => changePaymentMethod(PaymentMethods.Card)}
                    color="primary"
                    btnClass={classNames('w-100', {
                      'opacity-5': !isMethodSelected(PaymentMethods.Card) && !isMethodSelected(null),
                    })}
                  />
                </Col>
              )}
            </Row>
            {availablePaymentMethods.includes(PaymentMethods.Wallet) && !isInvoiceSelected && (
              <>
                <ButtonWithIcon
                  wrapText={true}
                  icon="uil-estate"
                  text="Zakup na rachunek Gościa lokalu"
                  handleClick={() => changePaymentMethod(PaymentMethods.Wallet)}
                  btnClass={classNames('w-100 mb-2', {
                    'opacity-5': !isMethodSelected(PaymentMethods.Wallet) && !isMethodSelected(null),
                  })}
                  color="secondary"
                />
                {resortDetails?.has_band && (
                  <ButtonWithIcon
                    icon="uil-capture"
                    text="Płatność opaską na lokal"
                    handleClick={() => changePaymentMethod(PaymentMethods.Band)}
                    btnClass={classNames('w-100 mb-2', {
                      'opacity-5': !isMethodSelected(PaymentMethods.Band) && !isMethodSelected(null),
                    })}
                    color="info"
                  />
                )}
              </>
            )}
            {!!paymentMethod && <div className="hr hr-grey-2 my-3" />}
            {isMethodSelected(PaymentMethods.Wallet) && (
              <GuestAccountPaymentBox
                restToPay={restToPay}
                apartment={apartment}
                onConfirm={handleGuestAccountPayment}
                onCancel={clearPaymentMethod}
                resortId={resortId}
              />
            )}
            {!!receipt && isMethodSelected(PaymentMethods.Card) && (
              <CardPaymentBox
                object_id={receipt.id}
                kind="receipt"
                isSentToEserviceDisabled={!asDecimal(amount).eq(restToPay)}
                content_type={content_type}
                restToPay={restToPay}
                onSubmit={handleCardPayment}
                onCancel={clearPaymentMethod}
                isSaving={isLoading}
                isSubmitDisabled={isPaymentSubmitDisabled}
              />
            )}
            {isMethodSelected(PaymentMethods.Cash) && (
              <CashPaymentBox
                restToPay={restToPay}
                onCancel={clearPaymentMethod}
                onSubmit={handleCashPayment}
                isSaving={isLoading}
                isSubmitDisabled={isPaymentSubmitDisabled}
              />
            )}
            {isMethodSelected(PaymentMethods.Band) && (
              <PurchaseBandPayment restToPay={restToPay} onSubmit={handleBandPayment} onCancel={clearPaymentMethod} />
            )}
          </>
        )}
      </div>
    </Col>
  )
}
