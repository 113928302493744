import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { apiQueryFunction } from '@api/base'
import { RootState } from '@store/index'
import { UpSellUser } from '@models/upselling'
import { PaginationResponse } from '@models/dashboard'

export const UPSELL_USER_TAG = 'upSellUser'

interface UpdateUpSellUserParams {
  url: string
  data: any
}

export const upSellUsersApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: '' }),
  reducerPath: 'upSellUsersApi',
  tagTypes: [UPSELL_USER_TAG],
  endpoints: builder => ({
    getUpSellUsers: builder.query<UpSellUser[], void>({
      queryFn: apiQueryFunction((state: RootState) => ({
        method: 'GET',
        url: state.appState.appData.urls.upselling.upselling_users,
        responseTransformer: (response: PaginationResponse<UpSellUser>) => response.results,
      })),
      providesTags: (result: UpSellUser[]) =>
        result
          ? [...result.map(({ id }) => ({ type: UPSELL_USER_TAG, id }) as const), UPSELL_USER_TAG]
          : [UPSELL_USER_TAG],
    }),
    updateUpSelUser: builder.mutation<UpSellUser, UpdateUpSellUserParams>({
      invalidatesTags: [UPSELL_USER_TAG],

      queryFn: apiQueryFunction((state: RootState, { data, url }: UpdateUpSellUserParams) => ({
        data,
        method: 'PUT',
        url: url,
      })),
    }),
  }),
})

export const { useGetUpSellUsersQuery, useUpdateUpSelUserMutation } = upSellUsersApi
