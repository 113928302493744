import * as React from 'react'
import { useHousekeepingTechnicalCompanies } from '@modules/technical-orders/hooks/use-housekeeping-technical-companies'
import { TechnicalOrder } from '@models/technical-orders'

export const useTechnicalOrderInfo = (technicalOrder: TechnicalOrder) => {
  const { companies: housekeeping_companies } = useHousekeepingTechnicalCompanies(technicalOrder.resort_id)

  const company = React.useMemo(
    () => housekeeping_companies.find(row => row.id === technicalOrder.company_id),
    [housekeeping_companies, technicalOrder.company_id],
  )

  return { users: technicalOrder.users, company }
}
