import * as React from 'react'
import classNames from 'classnames'
import { RootState, useAppSelector } from '@store/index'

interface Props {
  width: string
  height: string
}

const TimelineBackground: React.FC<Props> = ({ width, height }) => {
  const isCompactView = useAppSelector((state: RootState) => state.timelineState.isCompactView)
  return (
    <div className={classNames('calendar__background', { 'is-compact': isCompactView })} style={{ width, height }} />
  )
}

export default TimelineBackground
