import * as React from 'react'
import { BarriersBaseList } from '@modules/barriers/base-list'
import { NavigationPath } from '@models/routes'
import { RootState, useAppSelector } from '@store/index'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { BarrierTicketsWebSocketHandler } from '@modules/barriers/web-socket-handler'
import { UserPermission } from '@models/dashboard'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'

export const BarriersSpecialTicketsView = (): JSX.Element => {
  const url = useAppSelector((state: RootState) => state.appState.appData.urls.barriers.tickets_special)
  const user = useAuthenticatedUser()
  useMenuActive(NavigationPath.BarriersSpecialTickets)

  return (
    <>
      <BarriersBaseList
        name="pojazd specjalny"
        fetchUrl={url}
        showOnlyActiveCarsFilter={false}
        menuLink={NavigationPath.BarriersSpecialTickets}
        pageTitle="Pojazdy specjalne"
        canAdd={user.hasPerm(UserPermission.BarrierBarrierticketSpecialCanAdd)}
        canChange={user.hasPerm(UserPermission.BarrierBarrierticketSpecialCanUpdate)}
        canRemove={user.hasPerm(UserPermission.BarrierBarrierticketSpecialCanDelete)}
        canPrint={user.hasPerm(UserPermission.BarrierBarrierticketSpecialCanUpdate)}
      />
      <BarrierTicketsWebSocketHandler kind="special" />
    </>
  )
}
