import * as React from 'react'
import { useAppDispatch } from '@store/index'
import { ReceptionBookingDamage, ReceptionBookingDetails } from '@models/reception'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { setReceptionBookingDetails } from '@store/slices/reception-slice'

interface Props {
  damage: ReceptionBookingDamage
}

export const StepBillDamagesSingleSummaryElementDelete: React.FC<Props> = ({ damage }) => {
  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()

  const { isLoading, action: handleAccept } = useApiRequest(async (reason: string) => {
    dispatch(setReceptionBookingDetails(await commonObjectDelete<ReceptionBookingDetails>(damage.urls.details, reason)))
    addSuccessNotification('Szkoda została skasowana!')
  })

  return (
    <CommonObjectConfirmAction
      title="Potwierdź skasowanie szkodzy"
      message="Czy na pewno chcesz skasować wybraną szkodę ? Operacja ta jest nieodwołalna"
      askForReason={true}
      isLoading={isLoading}
      handleAccept={handleAccept}
    >
      <i className="uil uil-trash-alt font-15 cursor-pointer" data-testid="delete-damage" />
    </CommonObjectConfirmAction>
  )
}
