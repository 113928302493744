import * as React from 'react'
import { BookingRefund } from '@modules/accounting/refund/models'
import { DropdownItem } from 'reactstrap'
import TopBarProgress from 'react-topbar-progress-indicator'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApiRequest } from '@components/hooks/use-api-request'
import { updateAccountingBookingRefund } from '@store/slices/accounting-slice'

interface Props {
  selectedRows: BookingRefund[]
  clearSelectedRows: () => void
}

export const RefundBookingsTableActionsNotification: React.FC<Props> = ({ selectedRows, clearSelectedRows }) => {
  const url = useAppSelector(
    (state: RootState) => state.appState.appData.urls.accounting.bookings_client_data_notification,
  )
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const bookings = selectedRows.filter(row =>
    ['waiting_for_client_data', 'missing_client_data'].includes(row.deposit_transfer_status),
  )

  const { isLoading, action: handleClick } = useApiRequest(
    React.useCallback(async () => {
      clearSelectedRows()
      const bookingRefunds = await commonObjectPost<BookingRefund[]>(url, {
        bookings: bookings.map(row => row.id),
      })
      bookingRefunds.map(async booking => await dispatch(updateAccountingBookingRefund(booking)))
      const numbers = bookingRefunds.map(row => row.reservation_number).join(', ')
      addSuccessMessage('Sukces', `Przypomnienie zostało wysłane do rezerwacji o numerach: ${numbers}`)
    }, [bookings]),
  )

  return (
    <>
      {isLoading && TopBarProgress && <TopBarProgress />}

      <DropdownItem disabled={!bookings.length} onClick={handleClick}>
        <i className="uil uil-fast-mail mr-1 text-bold" />
        Wyślij przypomnienie ({bookings.length})
      </DropdownItem>
    </>
  )
}
