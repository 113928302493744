import * as React from 'react'
import { NotificationEvents } from '@models/dashboard'
import { useAppDispatch } from '@store/index'
import { useWebSocket } from '@components/hooks/use-web-socket'
import { PackageDetails } from '@models/package'
import { usePackageAppData } from '@modules/package/hooks/use-package-app-data'
import { PackageAppData, setPackageAppData, updatePackageDetails } from '@store/slices/package-slice'

export const PackageWebSocketHandler: React.FC = () => {
  const dispatch = useAppDispatch()
  const appData = usePackageAppData()

  useWebSocket<PackageDetails | PackageAppData>(
    (message, payload) => {
      switch (message) {
        case NotificationEvents.PACKAGE_APP_DATA_STATS_UPDATE: {
          dispatch(setPackageAppData({ ...appData, ...(payload as Partial<PackageAppData>) }))

          break
        }
        case NotificationEvents.PACKAGE_CHANGE:
          dispatch(updatePackageDetails(payload as PackageDetails))
          break
      }
    },
    [],
    [NotificationEvents.PACKAGE_APP_DATA_STATS_UPDATE, NotificationEvents.PACKAGE_CHANGE],
  )
  return null
}
