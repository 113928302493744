import * as React from 'react'
import { MediaReportsFiltersParams } from '@modules/reports/media/index'
import { Label, Row } from 'reactstrap'
import { ColAuto } from '@hyper/col-auto'
import { useForm } from 'react-hook-form'
import { SubmitFilter } from '@modules/reception/common/filters/submit-filter'
import * as R from 'ramda'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import Col from 'reactstrap/lib/Col'
import DateRangeInput from '@components/date/date-range-input'
import { useDebounce } from 'rooks'
import { Form } from '@hyper/forms/form'
import { FormSelect } from '@hyper/forms/form-select'

interface MediaReportsFiltersProps {
  filters: MediaReportsFiltersParams
  defaultFilters: MediaReportsFiltersParams
  setFilters: (filters: MediaReportsFiltersParams) => void
}

class FormInputs {
  resort: any
  date_after: Date | undefined
  date_before: Date | undefined
}

export const MediaReportsFilters: React.FC<MediaReportsFiltersProps> = ({ filters, setFilters, defaultFilters }) => {
  const methods = useForm<FormInputs>({
    defaultValues: { ...filters },
  })

  const { reset, watch } = methods
  const user = useAuthenticatedUser()

  const resorts = user.resorts.map(resort => ({
    label: resort.name,
    value: String(resort.id),
  }))

  const handleReset = () => {
    reset()
    setFilters(defaultFilters)
  }

  const onSubmit = async (payload: FormInputs) => setFilters({ ...filters, ...payload })

  const changes = watch()

  const values: MediaReportsFiltersParams = {
    ...filters,
    ...changes,
    resort: changes.resort?.value || filters.resort,
    ordering: filters.ordering,
  }

  const onSubmitDebounced = useDebounce(values => onSubmit(values), 250)
  React.useEffect(() => {
    if (!R.equals(values, filters)) {
      onSubmitDebounced(values)
    }
  }, [values])

  return (
    <Form methods={methods} onSubmit={onSubmit} className="form form-style-filter mb-2">
      <Row>
        <Col className="col-auto" style={{ minWidth: 320 }}>
          <div className="d-flex">
            <Label className="mr-1">Data:</Label>
          </div>

          <DateRangeInput
            isClearable={true}
            wrapperClassName="flex-grow-1"
            inputGroupClassName="form-group"
            startDateName="date_after"
            endDateName="date_before"
          />
        </Col>
        <ColAuto>
          <FormSelect
            options={resorts}
            label="Ośrodek"
            name="resort"
            formPlainProps={{ colStyle: { padding: 0, width: 200 } }}
          />
        </ColAuto>
        <SubmitFilter handleReset={handleReset} />
      </Row>
    </Form>
  )
}
