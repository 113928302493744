import * as React from 'react'
import { ReceptionBookingCheckInStepImprovementsGroup } from '@modules/reception/checkin/step-improvements/reception-booking-check-in-step-improvements-group'
import { formatPriceShort } from '@helpers/utils'
import { BookingImprovementCode, ImprovementAvailability } from '@models/booking'
import classNames from 'classnames'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { receptionBookingDetailsSelector, selectResortDetails } from '@store/selectors/reception'
import { ReceptionBookingHpfTicketImprovementAdd } from '@modules/reception/checkin/step-improvements/tickets/hpf/reception-booking-hpf-ticket-improvement-add'
import { ReceptionBookingAddedHpfTicketImprovementActions } from '@modules/reception/checkin/step-improvements/tickets/hpf/reception-booking-added-hpf-ticket-improvement-actions'
import { ReceptionBookingHpfTicketImprovementInformation } from '@modules/reception/checkin/step-improvements/tickets/hpf/reception-booking-hpf-ticket-improvement-ifnormation'
import { useAppSelector } from '@store/index'

export const HPFTicketImprovementCode = 'hpf_ticket'

const isHpfTicket = <T extends { code: BookingImprovementCode }>(el: T) => el.code === 'hpf_ticket'

interface Props {
  improvementsAvailability: ImprovementAvailability[]
  isReadOnly: boolean
}

export const ReceptionBookingHpfTicketImprovement = ({
  improvementsAvailability,
  isReadOnly,
}: Props): React.ReactNode => {
  const booking = useSelector(receptionBookingDetailsSelector)
  const resort = useAppSelector(selectResortDetails)
  const bookingHpfTicketImprovement = booking.improvements.find(isHpfTicket)
  const resortImprovements = resort.improvements.find(isHpfTicket)

  const hpfTicketAvailability = improvementsAvailability.find(
    improvement => isHpfTicket(improvement) && improvement.is_available,
  )

  const methods = useForm({ defaultValues: { amount: bookingHpfTicketImprovement?.amount ?? 1 } })
  const amount = useWatch({ control: methods.control, name: 'amount' })

  if (!hpfTicketAvailability && !bookingHpfTicketImprovement) return null
  if (hpfTicketAvailability && !bookingHpfTicketImprovement && isReadOnly) return null

  const price = (bookingHpfTicketImprovement ?? hpfTicketAvailability)?.price_brutto ?? '0'

  return (
    <FormProvider {...methods}>
      <ReceptionBookingCheckInStepImprovementsGroup title="Bilety wstępu">
        <div
          className={classNames('reception__booking-check-in__improvement-step__hpf__wrapper rounded py-2 pl-1', {
            'is-added': !!bookingHpfTicketImprovement,
          })}
        >
          <div className="row">
            <ReceptionBookingHpfTicketImprovementInformation
              ticketImprovement={bookingHpfTicketImprovement}
              price={price}
            />

            <div className="col-3">
              {bookingHpfTicketImprovement ? (
                <div className="d-flex align-items-center">
                  <ReceptionBookingAddedHpfTicketImprovementActions
                    amount={amount}
                    canChangeAmount={
                      hpfTicketAvailability?.can_change_amount ?? resortImprovements?.can_change_amount ?? false
                    }
                    ticketImprovement={bookingHpfTicketImprovement}
                    isReadOnly={isReadOnly}
                  />
                  <strong className="text-danger ml-auto d-block font-14">
                    ={formatPriceShort(bookingHpfTicketImprovement.price_brutto ?? '0')}
                  </strong>
                </div>
              ) : (
                <ReceptionBookingHpfTicketImprovementAdd price={price} />
              )}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12 font-11 reception__booking-check-in__improvement-step__hpf__description">
              Bilet całodniowy (1 wybrany dzień) w ramach bieżącego pobytu. <br /> Cena za 1 osobę (dorosły/dziecko).
            </div>
          </div>
        </div>
      </ReceptionBookingCheckInStepImprovementsGroup>
    </FormProvider>
  )
}
