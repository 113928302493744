import * as React from 'react'
import { PackageVipDetails } from '@modules/promotions/package-vip/models'
import { useAppDispatch } from '@store/index'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { updatePackageVipDetails } from '@store/slices/package-vip-slice'
import { UserPermission } from '@models/dashboard'
import { Button } from 'reactstrap'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { useApiRequest } from '@components/hooks/use-api-request'

interface Props {
  packageVipDetails: PackageVipDetails
  onDelete: () => void
}

export const PackageVipDetailsDelete: React.FC<Props> = ({ packageVipDetails, onDelete }) => {
  const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()

  const user = useAuthenticatedUser()

  const { isLoading, action: handleAccept } = useApiRequest(async (reason: string) => {
    dispatch(
      updatePackageVipDetails(await commonObjectDelete<PackageVipDetails>(packageVipDetails.urls.details, reason)),
    )
    addSuccessMessage('Sukces', 'Pakiet VIP został skasowany')
    onDelete()
  })

  const canDelete = user.hasPerm(UserPermission.PromotionsPackageVipCanDelete) && !packageVipDetails.is_removed

  return canDelete ? (
    <CommonObjectConfirmAction isLoading={isLoading} handleAccept={handleAccept} askForReason={true}>
      <Button className="btn-light btn" type="button">
        <i className="uil uil-trash-alt cursor-pointer" title="Skasuj" />
        Usuń
      </Button>
    </CommonObjectConfirmAction>
  ) : null
}
