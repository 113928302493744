import * as React from 'react'
import { ShopPurchaseSummaryRow } from '@modules/shop/pos/purchase-summary/shop-purchase-summary-row'
import { ShopPurchaseSummaryChangeRow } from '@modules/shop/pos/purchase-summary/shop-purchase-summary-change-row'
import { ShopPurchaseTicketRow } from '@modules/shop/pos/purchase-summary/tickets/shop-purchase-ticket-row'
import { EnhancedShopProduct } from '@modules/shop/pos/models'
import { useFormContext } from 'react-hook-form'
import Decimal from 'decimal.js'
import classnames from 'classnames'
import { ShopPOSWithParamsFormInputs } from '@modules/shop/pos/shop-pos-with-params'
import { ProductCalculation } from '@modules/shop/pos/product-calculator-provider'

interface ShopPurchaseSummaryProps {
  ticketFactor: Decimal
  restToPay: string
  removeProduct: (index) => void
  addedProducts: EnhancedShopProduct[]
  packageVipDiscount: boolean
  resortId: number
  removeTicket: (number) => void
  addTicket: ({ value, id }: { value: number; id: string }) => void
  updateProduct: (index: number, product: EnhancedShopProduct) => void
  productsCalculations: ProductCalculation[]
}

export const ShopPurchaseSummary: React.FC<ShopPurchaseSummaryProps> = ({
  addedProducts,
  removeProduct,
  packageVipDiscount,
  ticketFactor,
  removeTicket,
  addTicket,
  restToPay,
  resortId,
  updateProduct,
  productsCalculations,
}) => {
  const { watch } = useFormContext<ShopPOSWithParamsFormInputs>()
  const { paymentCompleted } = watch()

  const orderedProducts = addedProducts.map((product, index) => (
    <ShopPurchaseSummaryRow
      resort={resortId.toString()}
      key={product.listId}
      product={product}
      ticketFactor={ticketFactor}
      index={index}
      removeProduct={removeProduct}
      updateProduct={updateProduct}
      productCalculation={productsCalculations[index]}
    />
  ))

  return (
    <div className={classnames({ 'not-clickable': paymentCompleted })}>
      <h4 className="text-secondary mb-4">Podsumowanie zakupów</h4>
      <table className="table table-sm text-dark w-100 table-borderless">
        <colgroup>
          <col width="40%" />
          <col width="15%" />
          <col width="18%" />
          <col width="10%" />
          <col width="12%" />
          <col width="5%" />
        </colgroup>
        <tbody>
          <tr className="border-bottom">
            <th>Produkt / usługa</th>
            <th>Ilość</th>
            <th>Cena jednostkowa</th>
            <th>Wartość</th>
            <th className="text-right">Ticketów</th>
            <th />
          </tr>
          {orderedProducts}
          {!!addedProducts.length && (
            <ShopPurchaseTicketRow
              removeTicket={removeTicket}
              addTicket={addTicket}
              addedProducts={addedProducts}
              ticketFactor={ticketFactor}
            />
          )}
          <ShopPurchaseSummaryChangeRow
            resortId={resortId}
            packageVipDiscount={packageVipDiscount}
            restToPay={restToPay}
          />
        </tbody>
      </table>
    </div>
  )
}
