import * as React from 'react'
import { PaymentDayReportDetails } from '@models/payments'
import { SaveButton } from '@hyper/button'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { formatDate } from '@helpers/date-helper'
import { useAppDispatch } from '@store/index'
import { updatePaymentDayReportDetails } from '@store/slices/cash-slice'
import { commonObjectPost } from '@store/actions/generic-actions'
import { UserPermission } from '@models/dashboard'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'

interface Props {
  paymentDayReportDetails: PaymentDayReportDetails
}

export const PaymentRegisterCashBoxDayDetailsActionsOpen = ({ paymentDayReportDetails }: Props): JSX.Element | null => {
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()
  const user = useAuthenticatedUser()

  const { isLoading, action: handleClick } = useApiRequest(async () => {
    dispatch(
      updatePaymentDayReportDetails(
        await commonObjectPost<PaymentDayReportDetails>(paymentDayReportDetails.urls.details),
      ),
    )
    addSuccessMessage('Sukces', `Dzień ${formatDate(paymentDayReportDetails.date, 'dd.MM')} został otwarty`)
  })

  return (
    <>
      {paymentDayReportDetails.status === 'not_started' && (
        <SaveButton
          onClick={handleClick}
          isSaving={isLoading}
          label="Otwórz dzien"
          labelSaving="Otwieranie dnia.."
          className="mr-2 btn btn-green"
        />
      )}
      {paymentDayReportDetails.status === 'close' && user.hasPerm(UserPermission.CashPaymentDayReportCanReopen) && (
        <SaveButton
          onClick={handleClick}
          isSaving={isLoading}
          label="Otwórz dzień ponownie"
          labelSaving="Otwieranie dnia.."
          className="mr-2 btn btn-green"
        />
      )}
    </>
  )
}
