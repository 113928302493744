import * as React from 'react'
import { TechnicalOrder } from '@models/technical-orders'
import Table from '@components/table/table'
import { Button } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'
import { TechnicalOrderRejectModalProps } from '@modules/technical-orders/technical-order-reject-modal'
import { useTechnicalOrderAccept } from '@modules/technical-orders/hooks/use-technical-order-accept'

interface Props {
  technicalOrder: TechnicalOrder
}

export const TechnicalOrdersToAcceptTableRowActions: React.FC<Props> = ({ technicalOrder }) => {
  const [handleReject] = useModal('TechnicalOrderRejectModal', {
    technicalOrder,
    url: technicalOrder.urls.reject,
    successMessage: 'Usterka została cofnięta do poprawy',
    title: 'Do poprawy',
  } as TechnicalOrderRejectModalProps)

  const handleAccept = useTechnicalOrderAccept({ technicalOrders: [technicalOrder] })

  return (
    <Table.Cell className="text-right vertical-align-middle text-nowrap">
      <Button color="green" onClick={handleAccept}>
        Akceptuj
      </Button>
      <Button color="danger" className="ml-1" onClick={handleReject}>
        Do poprawy
      </Button>
    </Table.Cell>
  )
}
