import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { ReservationDropdown } from '@modules/reservations/details/common/reservation-dropdown'
import { useModal } from '@components/modals/use-modal'

interface GuestReservationDataActionsProps {
  booking: ReceptionBookingDetails
}

export const ReservationDepositDropdown: React.FC<GuestReservationDataActionsProps> = ({ booking }) => {
  const [showDepositChangeModal] = useModal('ReservationDepositChangeModal', { booking })

  return (
    <ReservationDropdown toggleClassName="ml-1 btn-flat px-2">
      <ReservationDropdown.Item onClick={showDepositChangeModal}>Zmień wymaganą kaucję</ReservationDropdown.Item>
    </ReservationDropdown>
  )
}
