import * as React from 'react'
import { useAppDispatch, useAppSelector } from '@store/index'
import { useApiRequest } from '@components/hooks/use-api-request'
import { getHousekeepingCommonTask } from '@store/actions/housekeeping-actions'
import { HousekeepingCommonTaskType } from '@modules/housekeeping/models'
import { commonTasksSelector } from '@store/slices/housekeeping-slice'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { HousekeepingCommonTasksTable } from '@modules/housekeeping/cleaning-orders/common-tasks/table'

interface Props {
  resortId: number
}

export const HousekeepingCommonTasks = ({ resortId }: Props): JSX.Element => {
  const dispatch = useAppDispatch()

  const { isLoading, action: fetchHousekeepingCommonTasks } = useApiRequest(
    async () =>
      await dispatch(
        getHousekeepingCommonTask([
          {
            resort: resortId,
          },
          HousekeepingCommonTaskType.PRESENT,
        ]),
      ),
  )

  React.useEffect(() => {
    fetchHousekeepingCommonTasks()
  }, [resortId])

  const commonTasks = useAppSelector(commonTasksSelector)

  const filteredCommonTasks = commonTasks.filter(
    row => row.status === 'PENDING' || row.status === 'IN_PROGRESS' || row.status === 'FINISHED',
  )

  return (
    <LoaderPlaceholder content={!isLoading}>
      <HousekeepingCommonTasksTable commonTasks={filteredCommonTasks} />
    </LoaderPlaceholder>
  )
}
