import * as React from 'react'
import { PageTitle } from '@hyper/page-title'
import { useDocumentTitle } from '@helpers/utils'
import { Card, CardBody } from 'reactstrap'
import { ShopWarehouseInvoicesFilters } from '@modules/shop/warehouse/invoices/shop-warehouse-invoices-filters'
import { useAppDispatch, useAppSelector } from '@store/index'
import { ShopWarehouseInvoicesTable } from '@modules/shop/warehouse/invoices/shop-warehouse-invoices-table'
import { ShopWarehouseInvoicesFiltersParams } from '@modules/shop/models'
import { getWarehouseInvoices } from '@store/actions/shop-actions'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useDebounce } from 'rooks'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { shopWarehouseInvoicesSelector } from '@store/slices/shop-slice'

const pageTitle = 'Potwierdzenia przyjęć'

const defaultFilters: ShopWarehouseInvoicesFiltersParams = {
  move__created_after: undefined,
  move__created_before: undefined,
  ordering: '-move__created',
  page: 1,
  page_size: 10,
  provider: '',
  search: '',
  warehouse: '',
}

export const ShopWarehouseInvoicesView: React.FC = () => {
  const [filters, setFilters] = React.useState<ShopWarehouseInvoicesFiltersParams>(defaultFilters)

  const dispatch = useAppDispatch()
  useDocumentTitle(pageTitle)
  useMenuActive(NavigationPath.ShopWarehouseInvoices)

  const invoices = useAppSelector(shopWarehouseInvoicesSelector)

  const { isLoading, action: fetchWarehouseInvoices } = useApiRequest(
    async filters => await dispatch(getWarehouseInvoices(filters)),
  )

  const fetchWarehouseInvoicesDebounced = React.useCallback(useDebounce(fetchWarehouseInvoices, 300), [])
  React.useEffect(() => {
    fetchWarehouseInvoicesDebounced(filters)
  }, [filters])

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitle} />
      <Card className="shop-warehouse-invoices">
        <CardBody>
          <ShopWarehouseInvoicesFilters filters={filters} setFilters={setFilters} defaultFilters={defaultFilters} />

          <ShopWarehouseInvoicesTable
            isLoading={isLoading}
            filters={filters}
            setFilters={setFilters}
            invoices={invoices || []}
          />
        </CardBody>
      </Card>
    </>
  )
}
