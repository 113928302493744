import * as React from 'react'
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import { RodoAgreement } from '@modules/crm/rodo-agreements/models'
import { ButtonWithIcon } from '@components/button-with-icon'
import { useBenefitIssue } from '@modules/crm/rodo-agreements/benefits/use-benefit-issue'
import { SaveButton } from '@hyper/button'
import { IconWithText } from '@components/icon-with-text'

interface Props extends BaseModalProps {
  rodoAgreement: RodoAgreement
}

export const RodoAgreementsIssueConfirmationModal: React.FC<Props> = ({ toggleIsVisible, rodoAgreement }) => {
  const { issueBenefit, isLoading } = useBenefitIssue()

  const handleIssueBenefit = async () => {
    await issueBenefit(rodoAgreement)
    toggleIsVisible()
  }

  return (
    <>
      <ModalHeader className="bg-dark-light text-white rounded-0">Czy benefit został wydany?</ModalHeader>
      <ModalBody>
        Zanim zamkniesz kartę Klienta potwierdź <br /> czy benefit <strong>został wydany</strong> czy{' '}
        <strong>jeszcze nie.</strong>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <ButtonWithIcon color="light" icon="uil-times font-16 lh-1" text="Nie wydany" handleClick={toggleIsVisible} />
        <SaveButton
          className="btn btn-green"
          labelSaving="Proszę czekać..."
          label={<IconWithText text="Benefit wydany" icon="uil-check font-16" />}
          isSaving={isLoading}
          onClick={handleIssueBenefit}
        />
      </ModalFooter>
    </>
  )
}
