import * as React from 'react'
import Table from '@components/table/table'
import { useModal } from '@components/modals/use-modal'
import { CmsAccommodationTypeDetails } from '@modules/cms/models'

interface Props {
  accommodationType: CmsAccommodationTypeDetails
}

export const CmsReservationAppAccommodationTypesRow: React.FC<Props> = ({ accommodationType }) => {
  const [handleEdit] = useModal('CmsReservationAppAccommodationTypeModal', { accommodationType })
  return (
    <Table.Row>
      <Table.Cell>{accommodationType.name}</Table.Cell>
      <Table.Cell>{accommodationType.size}m²</Table.Cell>
      <Table.Cell>
        {accommodationType.attributes.map(attribute => (
          <span key={attribute.id} className="font-18 mr-1">
            <i title={attribute.value} className={attribute.key} />
          </span>
        ))}
      </Table.Cell>
      <Table.Cell className="text-right">
        <button className="action-icon btn-pure" title="Edytuj" onClick={handleEdit}>
          <i className="uil-edit text-muted font-18" />
        </button>
      </Table.Cell>
    </Table.Row>
  )
}
