import * as React from 'react'
import { NavigationTabs, NavigationTabsButton } from '@components/navigation-tabs'
import { ScrollableModalTab, useModalWithScrollableTabs } from '@components/hooks/use-modal-with-scrollable-tabs'

type Tab = 'data' | 'payments' | 'codes' | 'bookings' | 'transfer'

interface Props {
  dataRef: React.RefObject<HTMLElement>
  transferRef: React.RefObject<HTMLElement>
  bookingsRef: React.RefObject<HTMLElement>
  codesRef: React.RefObject<HTMLElement>
  paymentsRef: React.RefObject<HTMLElement>
}

export const SubscriptionDetailsTabs: React.FC<Props> = ({
  dataRef,
  paymentsRef,
  codesRef,
  bookingsRef,
  transferRef,
}) => {
  const [tab, setTab] = React.useState<Tab>('data')

  const tabs: ScrollableModalTab<Tab>[] = [
    { ref: dataRef, name: 'data' },
    { ref: paymentsRef, name: 'payments' },
    { ref: codesRef, name: 'codes' },
    { ref: bookingsRef, name: 'bookings' },
    { ref: transferRef, name: 'transfer' },
  ]

  const { handleTabClick } = useModalWithScrollableTabs<Tab>({ modalId: 'subscription-modal', setTab, tabs })

  const buttons: NavigationTabsButton[] = [
    {
      title: 'Dane podstawowe',
      isActive: tab == 'data',
      onClick: handleTabClick('data'),
    },
    {
      title: 'Płatności',
      isActive: tab == 'payments',
      onClick: handleTabClick('payments'),
    },
    {
      title: 'Kody',
      isActive: tab == 'codes',
      onClick: handleTabClick('codes'),
    },
    {
      title: 'Powiązane rezerwacjie',
      isActive: tab == 'bookings',
      onClick: handleTabClick('bookings'),
    },
    {
      title: 'Historia',
      isActive: tab == 'transfer',
      onClick: handleTabClick('transfer'),
    },
  ]

  return <NavigationTabs buttons={buttons} />
}
