import * as React from 'react'
import { Button, Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { useApiRequest } from '@components/hooks/use-api-request'
import { HistoryBox } from '@modules/promotions/common/history-box'
import { BaseModalProps } from '@components/modals/types'
import { Cafeteria } from '@models/cafeteria'
import { getCafeteriaDetails } from '@store/actions/cafeterias-action'
import { cafeteriaDetailsSelector, clearCafeteriaDetails } from '@store/slices/cafeteria-slice'
import { CafeteriaDetailsNotes } from '@modules/cafeteria/details/notes'
import { CafeteriaDetailsTabs } from '@modules/cafeteria/details/tabs'
import { CafeteriaDetailsInformationBase } from '@modules/cafeteria/details/base-information'
import { CafeteriaDetailsCodes } from '@modules/cafeteria/details/codes'
import { useAppDispatch, useAppSelector } from '@store/index'
import { CafeteriaCancelButton } from '@modules/cafeteria/details/cancel'

interface Props extends BaseModalProps {
  cafeteria: Cafeteria
}

export const CafeteriaDetailsModal: React.FC<Props> = ({ cafeteria, toggleIsVisible }) => {
  const cafeteriaDetails = useAppSelector(cafeteriaDetailsSelector)

  const dataRef = React.useRef<HTMLElement>(null)
  const historyRef = React.useRef<HTMLElement>(null)
  const codesRef = React.useRef<HTMLDivElement>(null)

  const dispatch = useAppDispatch()

  const { isLoading, action: fetch } = useApiRequest(async () => await dispatch(getCafeteriaDetails(cafeteria.id)))

  React.useEffect(() => {
    fetch()

    return () => {
      dispatch(clearCafeteriaDetails())
    }
  }, [])

  console.log({ cafeteriaDetails })

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>
        Kafeteria <span className="text-semi-strong">{cafeteria.name}</span>
      </ModalHeader>
      <ModalBody className="modal-details">
        <LoaderPlaceholder content={!isLoading}>
          {cafeteriaDetails && (
            <>
              <div className="modal-details__tabs-wrapper">
                <CafeteriaDetailsTabs dataRef={dataRef} historyRef={historyRef} codesRef={codesRef} />
              </div>

              <Row>
                <Col md={6}>
                  <CafeteriaDetailsInformationBase cafeteria={cafeteriaDetails} ref={dataRef} />
                </Col>
                <Col md={12}>
                  <CafeteriaDetailsCodes cafeteria={cafeteriaDetails} ref={codesRef} />
                </Col>

                <Col md={6}>
                  <CafeteriaDetailsNotes cafeteria={cafeteriaDetails} />
                </Col>
                <Col md={6}>
                  <HistoryBox history={cafeteriaDetails.history} ref={historyRef} />
                </Col>
              </Row>
            </>
          )}
        </LoaderPlaceholder>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        {cafeteriaDetails && <CafeteriaCancelButton cafeteria={cafeteriaDetails} />}
        <Button className="btn btn-light ml-auto" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
      </ModalFooter>
    </>
  )
}
