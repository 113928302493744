import * as React from 'react'
import { Col, Row } from 'reactstrap'
import { AccountUserDesktopModalDeviceEdit } from '@modules/account/user-desktop-modal/device-edit'
import { useToggle } from '@components/hooks/use-toggle'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { IconWithText } from '@components/icon-with-text'
import { useTabletsList } from '@modules/reception/common/tablet-select/use-tablets-list'
import { useTablet } from '@modules/reception/common/tablet-select/use-tablet'

export const AccountUserDesktopModalSignatureTablet = (): JSX.Element => {
  const user = useAuthenticatedUser()
  const tablets = useTabletsList()
  const [editMode, toggleEditMode] = useToggle()
  const [value, setPinPad, remove] = useTablet()

  const bandReader = React.useMemo(
    () => tablets.find(row => row.id === (value || user.receptionWorkstation?.tablet_id)),
    [tablets, value, user.receptionWorkstation],
  )
  const setDevice = (device: number) => {
    setPinPad(device)
    toggleEditMode()
  }

  return (
    <Row className="mx-0 align-items-center py-2">
      <Col md={5}>
        <strong>Tablet</strong>
      </Col>
      <Col md={6}>
        {!editMode ? (
          <IconWithText
            text={bandReader?.name || 'brak'}
            icon="uil-edit text-dark ml-1 cursor-pointer"
            onClick={toggleEditMode}
          />
        ) : (
          <AccountUserDesktopModalDeviceEdit
            defaultValue={value || user.receptionWorkstation?.tablet_id}
            devices={tablets}
            clearValue={remove}
            toggleEdit={toggleEditMode}
            setDevice={setDevice}
          />
        )}
      </Col>
    </Row>
  )
}
