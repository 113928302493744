import * as React from 'react'
import { clearPaymentRegisters } from '@store/slices/cash-slice'
import { useAppDispatch } from '@store/index'
import { PaymentRegistersFiltersParams } from '@modules/payment-registers/list/filters'
import { add, startOfToday } from 'date-fns'
import { useApiRequest } from '@components/hooks/use-api-request'
import { getPaymentRegisters } from '@store/actions/cash-actions'
import { PaymentRegisterUserCashBoxTransactionsTable } from '@modules/payment-registers/user-cashbox/transactions/transactions-table'
import { PaymentRegisterUserCashboxTransactionsTableFilters } from '@modules/payment-registers/user-cashbox/transactions/transactions-table-filters'
import { PaymentRegisterUserCashboxTransactionsActions } from '@modules/payment-registers/user-cashbox/transactions/actions'
import { Card, CardBody } from 'reactstrap'
import { PaymentDayUserReport } from '@models/payments'
import { parseISODate } from '@helpers/date-helper'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'

interface Props {
  paymentDayUserReport: PaymentDayUserReport
}

export const PaymentRegisterUserCashboxTransactions = ({ paymentDayUserReport }: Props): JSX.Element => {
  const dispatch = useAppDispatch()

  const date = parseISODate(paymentDayUserReport.day_start) || startOfToday()

  const defaultFilters: PaymentRegistersFiltersParams = {
    ordering: '-created',
    page: 1,
    destination_resort: null,
    payment_day_user_report: paymentDayUserReport.id,
    source_resort: null,
    source: null,
    user: null,
    page_size: 10,
    search: '',
    created_after: date,
    created_before: add(date, {
      minutes: 59,
      hours: 23,
      seconds: 59,
    }),
    kind: null,
    type: null,
  }
  const [filters, setFilters] = React.useState<PaymentRegistersFiltersParams>(defaultFilters)

  const { isLoading, action: fetchPaymentRegisters } = useApiRequest(
    async () => await dispatch(getPaymentRegisters(filters)),
  )

  React.useEffect(() => {
    fetchPaymentRegisters()
  }, [filters])

  useDidUpdateEffect(() => {
    setFilters({ ...filters, payment_day_user_report: paymentDayUserReport.id })
  }, [paymentDayUserReport])

  React.useEffect(
    () => () => {
      dispatch(clearPaymentRegisters())
    },
    [],
  )

  return (
    <Card>
      <CardBody>
        <PaymentRegisterUserCashboxTransactionsActions resortId={paymentDayUserReport.resort_id} />
        <PaymentRegisterUserCashboxTransactionsTableFilters
          filters={filters}
          setFilters={setFilters}
          defaultFilters={defaultFilters}
        />
        <PaymentRegisterUserCashBoxTransactionsTable
          date={date}
          isLoading={isLoading}
          filters={filters}
          setFilters={setFilters}
        />
      </CardBody>
    </Card>
  )
}
