import * as React from 'react'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { TopBarLoading } from '@components/topbar-loading'
import { useApiRequest } from '@components/hooks/use-api-request'
import { AccommodationTypeImage, CmsAccommodationTypeDetails } from '@modules/cms/models'
import { DragReorder } from '@components/drag-reorder'

interface Props {
  image: AccommodationTypeImage
  index: number
  moveItem: (sourceId: number, destinationId: number) => void
  setState: (state: CmsAccommodationTypeDetails) => void
  onDrop: () => void
}

export const CmsReservationAppImagesEl: React.FC<Props> = ({ onDrop, image, setState, index, moveItem }) => {
  const { addSuccessNotification } = useNotificationHook()

  const { isLoading, action: handleRemoveImage } = useApiRequest(
    React.useCallback(async () => {
      setState(await commonObjectDelete<CmsAccommodationTypeDetails>(image.urls.details))
      addSuccessNotification('Zdjęcie zostało skasowane')
    }, [image, setState]),
  )

  return (
    <DragReorder
      onDrop={onDrop}
      id={image.id}
      className="card mb-1 mr-1 mb-0 shadow-none border"
      index={index}
      moveItem={moveItem}
    >
      {isLoading && <TopBarLoading />}

      <div className="p-1">
        <div className="d-flex justify-content-between">
          <img style={{ maxWidth: 150 }} src={image.image} className="img-thumbnail" alt="" />
          <i className="uil ml-1 cursor-pointer uil-multiply" onClick={handleRemoveImage} />
        </div>
      </div>
    </DragReorder>
  )
}
