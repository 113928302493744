import * as React from 'react'
import { Button, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { SaveButton } from '@hyper/button'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useForm } from 'react-hook-form'
import { createReportTask } from '@store/actions/report-tasks-actions'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { FormInput } from '@hyper/forms'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { endOfMonth, startOfMonth, startOfToday } from 'date-fns'
import { BaseModalProps } from '@components/modals/types'
import { Form } from '@hyper/forms/form'
import { FormSelect } from '@hyper/forms/form-select'

interface FormInputs {
  invoice_company: string
  number: string
  expire_after_after: Date | null
  expire_after_before: Date | null
  status: CustomReactSelectOption | undefined
}

export const ReportsPackageWholesaleCodeModal: React.FC<BaseModalProps> = ({ toggleIsVisible }) => {
  const dispatch = useAppDispatch()

  const reportUrl = useAppSelector(
    (state: RootState) => state.appState.appData.urls.reports.report_package_wholesale_code_report,
  )

  const methods = useForm<FormInputs>({
    defaultValues: {
      expire_after_after: startOfMonth(startOfToday()),
      expire_after_before: endOfMonth(startOfToday()),
      number: '',
      invoice_company: '',
    },
  })

  const { isLoading, action: onSubmit } = useReportFormRequest(async (payload: FormInputs) => {
    const reportTask = await dispatch(createReportTask([reportUrl, { ...payload, status: payload.status?.value }]))
    toggleIsVisible()

    return reportTask
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Pakiety hurtowe - Kody</ModalHeader>
      <ModalBody>
        <Row>
          <FormInput colSize={5} label="Numer pakietu:" name="number" />
          <FormInput colSize={7} label="Nazwa firmy:" name="invoice_company" />
        </Row>
        <Row>
          <FormSelect options={statusOptions} name="status" label="Status pakietu:" formPlainProps={{ colSize: 5 }} />
          <DateFromToInput
            colSize={7}
            label="Data zamknięcia pakietu"
            startDateName="expire_after_after"
            endDateName="expire_after_before"
          />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" label="Zleć raport" labelSaving="Zlecanie.." isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}

const statusOptions = [
  { value: 'open', label: 'Otwarty' },
  { value: 'close', label: 'Zamknięty' },
]
