import * as React from 'react'
import { getReceptionBookingDetails } from '@store/actions/reception-actions'
import { useAppDispatch, useAppSelector } from '@store/index'
import { SaveButton } from '@hyper/button'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { useModal } from '@components/modals/use-modal'
import { useApiRequest } from '@components/hooks/use-api-request'
import { receptionBookingDetailsSelector } from '@store/selectors/reception'
import { setReceptionBookingDetails } from '@store/slices/reception-slice'

interface Props {
  reservationDetailsUrl: string | null
  label: string
  onFeedingChange: () => void
}

export const FeedingHostApartmentInfoReservationDetails: React.FC<Props> = ({
  label,
  reservationDetailsUrl,
  onFeedingChange,
}) => {
  const dispatch = useAppDispatch()
  const [isOpen, setIsOpen] = React.useState(false)

  const bookingDetails = useAppSelector(receptionBookingDetailsSelector)
  const user = useAuthenticatedUser()

  const [showFeedingModal] = useModal('ReceptionBookingNewFeedingModal')

  const handleFeedingModalClose = () => {
    setIsOpen(false)
    onFeedingChange()
    dispatch(setReceptionBookingDetails(undefined))
  }

  React.useEffect(() => {
    if (isOpen && bookingDetails) {
      showFeedingModal(null, { bookingDetails, onClose: handleFeedingModalClose }, true)
    }
  }, [isOpen, bookingDetails])

  const { isLoading, action: fetchBookingDetails } = useApiRequest(
    async () => await dispatch(getReceptionBookingDetails(reservationDetailsUrl)),
  )

  if (!reservationDetailsUrl || !user.hasPerm(UserPermission.FeedingBookingChange)) {
    return null
  }

  const handleOpen = async () => {
    setIsOpen(true)
    await fetchBookingDetails()
  }

  return (
    <div className="text-right mt-2">
      <SaveButton
        onClick={handleOpen}
        className="btn btn-primary"
        isSaving={isLoading}
        label={label}
        labelSaving="Ładowanie.."
      />
    </div>
  )
}
