import * as React from 'react'
import { Button, Card, CardBody } from 'reactstrap'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { getCrmClients } from '@store/actions/crm-actions'
import { useParams } from 'react-router-dom'
import { useSelectableRows } from '@components/table/use-selectable-rows'
import { ClientUser } from '@models/clients'
import { CrmClientsFilters, CrmClientsFiltersParams } from '@modules/crm/clients/list-view/filters'
import { CrmClientsTable } from '@modules/crm/clients/list-view/table'
import { IconWithText } from '@components/icon-with-text'
import { useModal } from '@components/modals/use-modal'
import { ClientWebSocketHandler } from '@modules/crm/clients/web-socket-handler'
import * as queryString from 'querystring'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { CrmClientsDetailsView } from '@modules/crm/clients/list-view/details'
import { useTableFilters } from '@components/table/use-table-filters'
import { PageTitleWithSize } from '@hyper/page-title-with-size'

const defaultFilters: CrmClientsFiltersParams = {
  source_marketing_id: undefined,
  search: '',
  last_activity: undefined,
  ordering: 'last_name',
  page: 1,
  page_size: 10,
  date_joined_before: undefined,
  date_joined_after: undefined,
  is_blacklisted: false,
}

export const CrmClientsView: React.FC = () => {
  useMenuActive(NavigationPath.CrmClients)

  const clients = useAppSelector((state: RootState) => state.crmState.clients)

  const { client_id } = useParams<'client_id'>()

  const dispatch = useAppDispatch()

  const { ids } = queryString.parse(location.search.replace('?', ''))

  const { isLoading, filters, setFilters } = useTableFilters<CrmClientsFiltersParams>({
    defaultFilters,
    action: async filters => await dispatch(getCrmClients(filters, ids)),
    deps: [],
  })

  const [handleReport] = useModal('ReportsGeneratorClient', { params: filters })

  const { selectableHeader, selectableCell, selectedRows, clearSelectedRows } = useSelectableRows<ClientUser>(clients)

  return (
    <>
      <ClientWebSocketHandler />
      {client_id && <CrmClientsDetailsView id={client_id} />}

      <PageTitleWithSize title="Lista Gości" />
      <Card>
        <CardBody>
          <CrmClientsFilters
            filters={filters}
            defaultFilters={defaultFilters}
            setFilters={setFilters}
            selectedRows={selectedRows}
            clearSelectedRows={clearSelectedRows}
          />
          <CrmClientsTable
            selectableHeader={selectableHeader}
            selectableCell={selectableCell}
            clients={clients}
            selectedRows={selectedRows}
            isLoading={isLoading}
            filters={filters}
            setFilters={setFilters}
          />
          <div className="mt-2 text-right">
            <Button color="secondary" onClick={handleReport}>
              <IconWithText icon="uil-download-alt mr-1" text="Pobierz" />
            </Button>
          </div>
        </CardBody>
      </Card>
    </>
  )
}
