import * as React from 'react'
import { BaseHistory } from '@models/dashboard'
import { Card, CardBody } from 'reactstrap'
import { IconWithText } from '@components/icon-with-text'
import { ContentNotesCardFooter } from '@components/content-notes/content-notes-card-footer'
import { useModal } from '@components/modals/use-modal'
import { ClientHistoryRow } from '@components/client-history/client-history-row'
import { sortObjectListByDate } from '@helpers/utils'
import { compareDesc } from 'date-fns'
import { NotificationsEmptyMessage } from '@components/notification-box/notifications-empty-message'
import classNames from 'classnames'

const MAX_HISTORY_ITEMS = 7

interface Props {
  history: BaseHistory[]
  title?: string
  listModalTitle?: string
  asCard?: boolean
}

export const HistoryBox = React.forwardRef(
  ({ title, history, listModalTitle, asCard = true }: Props, ref: React.RefObject<HTMLElement>): React.ReactNode => {
    const [showHistoryModal] = useModal('HistoryListModal', { history, title: listModalTitle, icons })

    const hasMoreToShow = history.length > MAX_HISTORY_ITEMS
    const historyEntriesToShow = sortObjectListByDate(history, 'created_at', compareDesc).slice(0, MAX_HISTORY_ITEMS)

    return (
      <Card className={classNames({ 'shadow-none mb-0': !asCard })} innerRef={ref}>
        <CardBody className={classNames({ 'px-0 pt-0 pb-2': !asCard })}>
          {asCard && (
            <IconWithText
              icon="uil-history-alt font-20"
              text={title || 'Historia zmian'}
              wrapperClassNames="text-secondary text-semi-strong mb-2"
              textClass="fw-bold font-18 text-nowrap"
            />
          )}
          {historyEntriesToShow.length ? (
            historyEntriesToShow.map((historyEntry, index) => (
              <ClientHistoryRow history={historyEntry} key={index} icons={icons} />
            ))
          ) : (
            <NotificationsEmptyMessage image={require('@assets/images/no-history-placeholder.svg')} className="mt-0">
              Brak <strong>historii</strong>
              <br /> zmian
            </NotificationsEmptyMessage>
          )}
        </CardBody>
        {hasMoreToShow && (
          <ContentNotesCardFooter
            contentName="zmiany"
            allNotesShowed={false}
            allNotesLength={history.length}
            onShowAllNotesClick={showHistoryModal}
          />
        )}
      </Card>
    )
  },
)

const icons: Record<string, string> = {
  add_improvement: 'uil-plus',
  add_payment: 'uil-plus',
  booking_damage_add: 'uil-plus',
  booking_damage_remove: 'uil-trash-alt',
  booking_online_checkin: 'uil-arrow-down-right',
  booking_online_checkout: 'uil-arrow-up-right',
  booking_sign_documents: 'uil-pen',
  canceled: 'uil-times-circle',
  change_apartment: 'uil-edit-alt',
  change_client_data: 'uil-edit-alt',
  change_date: 'uil-edit-alt',
  change_feeding: 'uil-edit-alt',
  change_improvement: 'uil-edit-alt',
  change_payment: 'uil-edit-alt',
  change_seller: 'uil-edit-alt',
  change_source_marketing: 'uil-edit-alt',
  client_add_guest: 'uil-plus',
  client_change_booking_information: 'uil-edit-alt',
  client_change_deposit_return_account_number: 'uil-edit-alt',
  client_change_guest_name: 'uil-edit-alt',
  client_change_guest_type: 'uil-edit-alt',
  client_change_guest_wallet_access: 'uil-edit-alt',
  client_delete_guest: 'uil-trash-alt',
  client_wallet_activate: 'uil-money-insert',
  client_wallet_deactivate: 'uil-money-withdraw',
  client_wallet_item_remove: 'uil-trash-alt',
  client_wallet_remove: 'uil-trash-alt',
  close: 'uil-times-circle',
  completed: 'uil-check-circle',
  confirmed: 'uil-check',
  confirmed_pay: 'uil-invoice',
  confirmed_user: 'uil-user-check',
  convert_accommodation_type: 'uil-exchange',
  created: 'uil-plus',
  default_deposit_amount_changed: 'uil-edit-alt',
  default_discount: 'uil-pricetag-alt',
  delete_improvement: 'uil-trash-alt',
  delete_payment: 'uil-trash-alt',
  deleted: 'uil-trash-alt',
  deposit_return: 'uil-money-withdraw',
  deposit_return_custom_amount: 'uil-money-withdraw',
  deposit_return_transfer_remove: 'uil-trash-alt',
  extend_stay_days: 'uil-arrows-h-alt',
  extra_charge_add: 'uil-plus',
  extra_charge_change: 'uil-edit-alt',
  extra_charge_delete: 'uil-trash-alt',
  feeding_discount: 'uil-pricetag-alt',
  gastro_card_add: 'uil-plus',
  guest_add: 'uil-plus',
  guest_change: 'uil-edit-alt',
  guest_delete: 'uil-trash-alt',
  guest_inside: 'uil-user-exclamation',
  hp_discount: 'uil-pricetag-alt',
  installment: 'uil-money-withdraw',
  keyword_declared_arrival_time_change: 'uil-edit-alt',
  keyword_floor_heating_scenario_changed: 'uil-edit-alt',
  keyword_floor_heating_temperature_changed: 'uil-edit-alt',
  keyword_print_hpf_tickets: 'uil-print',
  keyword_subscription_contract_cancelled: 'uil-times-circle',
  media_change: 'uil-edit-alt',
  move_payment: 'uil-exchange',
  package_vip_assignment: 'uil-plus',
  paid: 'uil-invoice',
  params_change: 'uil-edit-alt',
  payment_deposit_add: 'uil-plus',
  payment_transfer: 'uil-exchange',
  promocode_add: 'uil-plus',
  promocode_delete: 'uil-trash-alt',
  promotion_delete: 'uil-trash-alt',
  remove_apartment: 'uil-trash-alt',
  rodo_agreement: 'uil-file-contract',
  voucher_add: 'uil-plus',
  voucher_remove: 'uil-trash-alt',
  wallet_move: 'uil-exchange',
}
