import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { ReservationDataActions } from '@modules/reservations/details/reservation-data/reservation-data-actions'
import { Alert } from 'reactstrap'
import { formatTime } from '@helpers/date-helper'
import { ReceptionBookingDetails } from '@models/reception'
import { useModal } from '@components/modals/use-modal'
import classNames from 'classnames'
import { isBookingEditable } from '@modules/reservations/details/utils'

interface Props {
  booking: ReceptionBookingDetails
}

export const ReservationDataHeader = ({ booking }: Props): JSX.Element => {
  const [showDeclaredArrivalTime] = useModal('ReservationDeclaredArrivalTimeModal', { booking })

  const canChangeDeclaredArrivalTime = isBookingEditable(booking) && !booking.checked_in

  const isDeclaredArrivalTimeShowed = booking.declared_arrival_time && !booking.checked_in

  return (
    <div className="d-flex align-items-center">
      <IconWithText
        icon="uil-calender font-18"
        text="Dane rezerwacji"
        wrapperClassNames="text-secondary text-semi-strong mb-2 col-4 pl-0"
        textClass="fw-bold font-18 text-nowrap"
      />
      <div
        className={classNames(
          'd-flex col-8 pl-1 pr-0',
          isDeclaredArrivalTimeShowed ? 'justify-content-between' : 'justify-content-end',
        )}
      >
        {isDeclaredArrivalTimeShowed && (
          <Alert color="info" className="btn-tall d-flex align-items-center mb-0 mr-2">
            <IconWithText
              icon="uil-stopwatch font-14"
              textClass="fw-semi-bold font-11"
              text={
                <span>
                  <span>{`Deklarowana godzina przyjazdu: ${formatTime(booking.declared_arrival_time!)}`}</span>
                  {canChangeDeclaredArrivalTime && (
                    <IconWithText
                      icon="uil-edit-alt font-14"
                      text="zmień"
                      wrapperClassNames="text-default ml-4 font-11"
                      onClick={showDeclaredArrivalTime}
                    />
                  )}
                </span>
              }
            />
          </Alert>
        )}
        <ReservationDataActions booking={booking} canChangeDeclaredArrivalTime={canChangeDeclaredArrivalTime} />
      </div>
    </div>
  )
}
