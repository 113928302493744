import * as React from 'react'
import { formatPrice } from '@helpers/utils'
import { useExpandableItems } from '@components/hooks/use-expandable-items'
import classNames from 'classnames'
import { ReceptionBookingCartFeedingGuestMeals } from '@modules/reception/cart/cart-items/feeding/reception-booking-cart-feeding-guest-meals'
import { ControlledFormCheckbox } from '@hyper/forms/controlled-form-checkbox'
import { ReceptionBookingGuest } from '@models/booking'
import { ReceptionBookingDetailsCartItem } from '@models/reception'
import { createCombinedCartItemsPrice } from '@modules/reception/cart/utils'
import { useCartItemGroupSelection } from '@modules/reception/cart/use-cart-item-group-selection'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { useContext } from 'react'
import { CartContext } from '@modules/reception/cart/cart-context'
import { ReceptionBookingCartItemPaidIcon } from '@modules/reception/cart/cart-items/reception-booking-cart-item-paid-icon'

interface Props {
  wrapperClassName?: string
  guest: ReceptionBookingGuest
  feedingForGuest: ReceptionBookingDetailsCartItem[]
}

export const ReceptionBookingCartFeedingGuest = ({ wrapperClassName, guest, feedingForGuest }: Props): JSX.Element => {
  const expansionKey = `feeding-guest-${guest.id}`

  const { allRowCollapsed } = useContext(CartContext)
  const { isExpanded, toggleExpanded, setExpanded, ExpandArrow } = useExpandableItems()
  const { totalPrice, originalTotalPrice } = createCombinedCartItemsPrice(feedingForGuest)

  const { allSelected, someSelected, changeGroup } = useCartItemGroupSelection(feedingForGuest)

  useDidUpdateEffect(() => {
    if (!allRowCollapsed) {
      setTimeout(() => {
        setExpanded(expansionKey, !allRowCollapsed)
      }, 300)
      return
    }

    setExpanded(expansionKey, !allRowCollapsed)
  }, [allRowCollapsed])

  const isEveryPaid = feedingForGuest.every(item => item.is_paid)

  return (
    <li
      className={classNames(
        'border-top px-2 row font-12 d-flex align-items-center reception-booking-cart__expandable is-nested ',
        wrapperClassName,
      )}
    >
      <div className="reception-booking-cart__expandable__item d-flex col-7 px-0">
        <div
          className={classNames('d-flex align-items-center pl-4 py-1 reception-booking-cart__selectable', {
            'is-selected': allSelected,
          })}
        >
          {isEveryPaid ? (
            <ReceptionBookingCartItemPaidIcon>
              <strong>{guest.name}</strong>
            </ReceptionBookingCartItemPaidIcon>
          ) : (
            <ControlledFormCheckbox
              id={guest.id.toString()}
              label={guest.name}
              indeterminate={someSelected}
              checked={allSelected}
              onChange={changeGroup}
            />
          )}

          <ExpandArrow
            isExpanded={isExpanded(expansionKey)}
            onExpand={toggleExpanded(expansionKey)}
            className="lh-0 ml-1"
          />
        </div>
      </div>

      <div className="col-3 text-muted text-strikethrough">{formatPrice(originalTotalPrice)}</div>
      <div className="col-2">{formatPrice(totalPrice)}</div>
      <ReceptionBookingCartFeedingGuestMeals isExpanded={isExpanded(expansionKey)} meals={feedingForGuest} />
    </li>
  )
}
