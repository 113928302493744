import * as React from 'react'
import { Card, CardBody } from 'reactstrap'
import { useAppDispatch } from '@store/index'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { AgreementFilterParams, AgreementListFilter } from '@modules/hr/agreement/list/filter'
import { getAgreements, getHighPriorityAgreements } from '@store/actions/hr-action'
import { clearAgreements } from '@store/slices/hr-slice'
import { useTableFilters } from '@components/table/use-table-filters'
import { AgreementList } from '@modules/hr/agreement/list/list'
import { PageTitleWithSize } from '@hyper/page-title-with-size'

const defaultFilters: AgreementFilterParams = {
  search: '',
  ordering: '-created',
  page: 1,
  page_size: 10,
  resorts: [],
  companies: [],
  job_positions: [],
  statuses: [],
}

export const AgreementListView: React.FC = () => {
  useMenuActive(NavigationPath.HrAgreementList)

  const dispatch = useAppDispatch()

  const { isLoading, filters, setFilters } = useTableFilters<AgreementFilterParams>({
    defaultFilters,
    action: async filters => {
      await dispatch(getAgreements(filters))
      await dispatch(getHighPriorityAgreements(filters))
    },
  })

  useMenuActive(NavigationPath.HrAgreementList)

  React.useEffect(
    () => () => {
      dispatch(clearAgreements())
    },
    [],
  )

  return (
    <>
      <PageTitleWithSize title="Lista umów" />
      <Card>
        <CardBody>
          <AgreementListFilter defaultFilters={defaultFilters} filters={filters} setFilters={setFilters} />
          <AgreementList isLoading={isLoading} filters={filters} setFilters={setFilters} />
        </CardBody>
      </Card>
    </>
  )
}
