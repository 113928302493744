import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { useAppData } from '@components/hooks/use-app-data'

interface Props {
  resortId: number
}

export const ReservationDataResort = ({ resortId }: Props): JSX.Element => {
  const { resorts } = useAppData()

  const resort = resorts.find(resort => resort.id === resortId)

  return (
    <IconWithText
      textClass="font-weight-bold"
      icon="uil-map-marker text-secondary me-2 font-16"
      wrapperClassNames="mb-1"
      text={resort?.name}
    />
  )
}
