import * as React from 'react'
import { PageTitle } from '@hyper/page-title'
import { Card, CardBody } from 'reactstrap'
import { useDocumentTitle } from '@helpers/utils'
import { useAppDispatch, useAppSelector } from '@store/index'
import { useSubscriptionAppData } from '@modules/subscription/hooks/use-subscription-app-data'
import { useApiRequest } from '@components/hooks/use-api-request'
import { AccountUsersListFilter } from '@modules/account/users/list/filter'
import { getGroups, getUsers } from '@store/actions/account-actions'
import { AccountUsersListTable } from '@modules/account/users/list/table'
import { totalSizeSelector } from '@store/selectors/dashboard'
import { useDebounce } from 'rooks'
import { usersSelector } from '@store/slices/account-slice'
import { TableFilters } from '@components/table/table'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { CustomReactSelectOption } from '@components/custom-react-select'

export interface AccountUserListTableFilters extends TableFilters {
  is_superuser: CustomReactSelectOption | undefined
  is_active: CustomReactSelectOption | undefined
  is_worker: CustomReactSelectOption | undefined
  is_staff: CustomReactSelectOption | undefined
  is_resort_manager: CustomReactSelectOption | undefined
  is_receptionist: CustomReactSelectOption | undefined
  groups: CustomReactSelectOption[]
}

const defaultFilters: AccountUserListTableFilters = {
  is_resort_manager: undefined,
  is_superuser: undefined,
  is_active: undefined,
  is_staff: undefined,
  is_worker: undefined,
  is_receptionist: undefined,
  search: '',
  ordering: 'name',
  page: 1,
  groups: [],
  page_size: 10,
}

export const AccountUserListView: React.FC = () => {
  const totalSize = useAppSelector(totalSizeSelector)
  useMenuActive(NavigationPath.AccountUsersList)

  const [filters, setFilters] = React.useState<AccountUserListTableFilters>({
    ...defaultFilters,
  })
  const dispatch = useAppDispatch()
  const users = useAppSelector(usersSelector)

  useSubscriptionAppData()

  const { action: fetchGroups } = useApiRequest(() => {
    dispatch(getGroups({ page_size: 200 }))
  })

  const { isLoading, action: fetchUsers } = useApiRequest(async filters => await dispatch(getUsers(filters)))

  const fetchDebounce = React.useCallback(useDebounce(fetchUsers, 300), [])
  React.useEffect(() => {
    fetchDebounce(filters)
  }, [filters])

  React.useEffect(() => {
    fetchGroups()
  }, [])

  const pageTitle = 'Lista użytkowników'
  const pageTitleWithSize = pageTitle + ` (${totalSize})`
  useDocumentTitle(pageTitleWithSize)

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitleWithSize} />
      <Card>
        <CardBody>
          <div className="text-right mb-2">
            <a href="/admin/account/user/add/" className="btn btn-green-dark">
              <i className="mdi mdi-plus-circle mr-1" />
              Dodaj użytkownika
            </a>
          </div>
          <AccountUsersListFilter defaultFilters={defaultFilters} filters={filters} setFilters={setFilters} />
          <AccountUsersListTable isLoading={isLoading} users={users} filters={filters} setFilters={setFilters} />
        </CardBody>
      </Card>
    </>
  )
}
