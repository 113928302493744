import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { Button, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { useAppDispatch } from '@store/index'
import { useForm } from 'react-hook-form'
import { endOfMonth, startOfMonth, startOfToday } from 'date-fns'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { createReportTask } from '@store/actions/report-tasks-actions'
import { Form } from '@hyper/forms/form'
import { FormSelect } from '@hyper/forms/form-select'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { createSelectOption, extractSelectOptionsValues } from '@helpers/utils'
import { SaveButton } from '@hyper/button'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { useAppData } from '@components/hooks/use-app-data'

interface FormInputs {
  created_after: Date
  created_before: Date
  issue_datetime_after: null
  issue_datetime_before: null
  status: CustomReactSelectOption | null
}

export const ReportGenericVoucherCode: React.FC<BaseModalProps> = ({ toggleIsVisible }) => {
  const { urls } = useAppData()
  const dispatch = useAppDispatch()

  const methods = useForm<FormInputs>({
    mode: 'all',
    defaultValues: {
      created_after: startOfMonth(startOfToday()),
      created_before: endOfMonth(startOfToday()),
      issue_datetime_after: null,
      issue_datetime_before: null,
      status: StatusOptions[0],
    },
  })

  const { isLoading, action: onSubmit } = useReportFormRequest(async (payload: FormInputs) => {
    const reportTask = await dispatch(
      createReportTask([urls.reports.report_task_generic_voucher_code_report, extractSelectOptionsValues(payload)]),
    )
    toggleIsVisible()
    return reportTask
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Raport ulepszonych kodów</ModalHeader>
      <ModalBody>
        <Row>
          <DateFromToInput
            startDateName="created_after"
            endDateName="created_before"
            label="Data rejestracji"
            isClearable={false}
            inputGroupClassName="mb-2"
          />
          <DateFromToInput
            startDateName="issue_datetime_after"
            endDateName="issue_datetime_before"
            label="Data rejestracji"
            inputGroupClassName="mb-2"
          />
          <FormSelect options={StatusOptions} name="status" label="Status kodu" />
        </Row>
      </ModalBody>

      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" label="Zleć raport" labelSaving="Zlecanie.." isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}

const StatusOptions = [
  createSelectOption('Wszystkie', null),
  createSelectOption('Wstępny', 'initial'),
  createSelectOption('Potwierdzony', 'confirmed'),
]
