import * as React from 'react'
import { formatPrice } from '@helpers/utils'
import { FormInput } from '@hyper/forms'

interface Props {
  label: string
  icon: string
  lastReadDate: string
  unity: string
  value: string
  price: string
  hasError: boolean
  handleRefresh: () => void
  editMode: boolean
  inputName: string
}

export const ReceptionBookingCheckInStepDocumentsMetersRow: React.FC<Props> = ({
  label,
  lastReadDate,
  icon,
  value,
  unity,
  price,
  handleRefresh,
  hasError,
  editMode,
  inputName,
}) => (
  <div className="d-flex justify-content-between mb-2">
    <div className="d-flex align-items-center">
      <div className="mr-1 font-18">
        <i className={icon} />
      </div>
      <div className="text-nowrap">
        <strong className="font-14 d-block text-semi-strong">{label}</strong>
        <span className="font-10 text-muted">
          Odczyt: {lastReadDate || 'brak'}
          <i className="uil-sync ml-1 cursor-pointer font-12" onClick={handleRefresh} />
        </span>
      </div>
    </div>
    <div className="d-flex align-items-center text-right">
      {editMode ? (
        <FormInput
          colSize="col-auto"
          colClassName="p-0"
          formGroupClassName="mb-0 d-flex align-items-center flex-row-reverse"
          name={inputName}
          autoComplete="off"
          type="number"
          step="any"
          inputProps={{ style: { width: 120, height: 30 } }}
          label={unity}
          labelProps={{ className: 'mb-0 text-start mr-1', style: { width: 30 } }}
        />
      ) : (
        <div className="mr-1">
          <strong className="font-14 d-block text-dark">
            {value} {unity}
          </strong>
          <span className="font-10 text-muted">wg stawki: {formatPrice(price)}</span>
        </div>
      )}
      {hasError && (
        <div style={{ width: 22 }}>
          <i className="uil-exclamation-triangle text-danger font-18" />
        </div>
      )}
    </div>
  </div>
)
