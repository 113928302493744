import * as React from 'react'
import * as R from 'ramda'
import { useAppDispatch } from '@store/index'
import { useDebouncedValue } from 'rooks'
import { setDashboardStandardPaginator } from '@store/actions/dashboard-actions'

export interface BaseTableFilters {
  ordering: string
  page: number
  page_size: number
  search: string
}

export const useHandleTableFiltersChange = (watch, filters, onSubmit, overwrite = {}) => {
  const dispatch = useAppDispatch()
  const watched = watch()

  const [debouncedSearch] = useDebouncedValue(watched.search, 250)

  const newValues = {
    ...filters,
    ...watched,
    search: debouncedSearch,
    page: filters.page,
    page_size: filters.page_size,
    ordering: filters.ordering,
    ...overwrite,
  }

  React.useEffect(() => {
    if (!R.equals(newValues, filters)) {
      onSubmit({ ...newValues, page: 1 })
      dispatch(setDashboardStandardPaginator())
    }
  }, [newValues, filters])
}
