import * as React from 'react'
import { Col, ModalHeader, Row } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import { useGetCashbackVoucherDetailsQuery } from '@api/cashback'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { ModalBody } from 'reactstrap/lib'
import { CashbackVoucherBaseData } from '@modules/products/cashback-vouchers/cashback-voucher-modal/base-data'
import { GastroCardWalletItems } from '@modules/promotions/gastro-cards/gastro-card-details/gastro-card-wallet-items'
import { TypedQueryResult } from '@api/base'
import { CashbackVoucherDetails } from '@modules/products/cashback-vouchers/models'
import { HistoryBox } from '@modules/promotions/common/history-box'
import { ContentNotesCard } from '@components/content-notes/content-notes-card'
import { RootState, useAppSelector } from '@store/index'
import { ProductEmailNotifications } from '@modules/promotions/common/notification/notifications'
import { CashbackVoucherModalFooter } from '@modules/products/cashback-vouchers/cashback-voucher-modal/footer'
import { useCashbackVouchersAppData } from '@modules/products/cashback-vouchers/hooks/use-cashback-vouchers-app-data'
import { useDetailsModalUnmountParamsClear } from '@components/hooks/use-details-modal'

interface Props extends BaseModalProps {
  id: number
}

export const CashbackVoucherModal: React.FC<Props> = ({ id, toggleIsVisible }) => {
  const contentType = useAppSelector((state: RootState) => state.appState.appData.content_types.cashbackvoucher)
  const { notifications } = useCashbackVouchersAppData()

  useDetailsModalUnmountParamsClear(id)

  const {
    isLoading,
    data: cashbackVoucherDetails,
    refetch: handleRefresh,
  } = useGetCashbackVoucherDetailsQuery(id) as TypedQueryResult<CashbackVoucherDetails>

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>Voucher rePLAY! #{cashbackVoucherDetails?.number}</ModalHeader>
      <ModalBody className="modal-details">
        <LoaderPlaceholder content={!isLoading}>
          <Row>
            {!!cashbackVoucherDetails && (
              <>
                <CashbackVoucherBaseData cashbackVoucherDetails={cashbackVoucherDetails} />
                <Col md={12}>
                  <GastroCardWalletItems wallet={cashbackVoucherDetails.wallet} />
                </Col>
                {cashbackVoucherDetails.voucher && (
                  <Col md={12}>
                    <GastroCardWalletItems wallet={cashbackVoucherDetails.voucher.wallet} title="Zakupy" />
                  </Col>
                )}
                <Col md={6}>
                  <HistoryBox history={cashbackVoucherDetails.history} />
                </Col>
                <Col md={6}>
                  <ContentNotesCard
                    readOnly={false}
                    objectId={cashbackVoucherDetails.id}
                    contentType={contentType}
                    onNotesUpdate={handleRefresh}
                    notes={cashbackVoucherDetails.notes}
                  />
                </Col>
                <Col md={6}>
                  <ProductEmailNotifications
                    history={cashbackVoucherDetails.notifications}
                    notificationEmails={notifications}
                    readOnly={false}
                    onSuccess={handleRefresh}
                    url={cashbackVoucherDetails.urls.notifications}
                    email={cashbackVoucherDetails.client?.email || ''}
                  />
                </Col>
              </>
            )}
          </Row>
        </LoaderPlaceholder>
      </ModalBody>
      {cashbackVoucherDetails && (
        <CashbackVoucherModalFooter
          handleRefresh={handleRefresh}
          toggleIsVisible={toggleIsVisible}
          cashbackVoucherDetails={cashbackVoucherDetails}
        />
      )}
    </>
  )
}
