import * as React from 'react'
import { ReceptionBooking } from '@models/reception'
import { formatTime } from '@helpers/date-helper'

interface ReceptionToCheckInRowArrivalTimeProps {
  booking: ReceptionBooking
}

export const ReceptionToCheckInRowArrivalTime: React.FC<ReceptionToCheckInRowArrivalTimeProps> = ({ booking }) => (
  <>
    <div className="mb-1">Przyjazd o</div>
    <i className="uil uil-clock text-muted" /> {formatTime(booking.declared_arrival_time ?? booking.arrival_time)}
  </>
)
