import * as React from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Button, Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { useAppDispatch } from '@store/index'
import { CustomReactSelectOption, makeDefaultSelectOption } from '@components/custom-react-select'
import {
  ReceptionBookingCheckStep,
  ReceptionBookingCheckSteps,
} from '@modules/reception/common/reception-booking-check-steps'
import { SaveButton } from '@hyper/button'
import { useFormRequest } from '@components/hooks/use-api-request'
import { ClientDetails, ClientUser } from '@models/clients'
import { BaseModalProps } from '@components/modals/types'
import { InvoiceType } from '@models/promotions'
import { startOfToday } from 'date-fns'
import { SubscriptionContractCreateFormClientData } from '@modules/subscription-contract/create/steps/subscription-contract-data'
import { SubscriptionContractCreateFormSummary } from '@modules/subscription-contract/create/steps/summary'
import { createSubscriptionContract, updateSubscriptionContract } from '@store/actions/subscription-contract-actions'
import { useProductSaleConfirmationModal } from '@components/sale/use-product-sale-confirmation-modal'
import { useModal } from '@components/modals/use-modal'
import { unwrapResult } from '@reduxjs/toolkit'
import { SubscriptionContractDetails } from '@modules/subscription-contract/models'
import * as R from 'ramda'
import { useGroupedSources } from '@components/hooks/use-grouped-sources'
import { useSellersOptions } from '@components/hooks/use-grouped-sellers'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { parseISODate } from '@helpers/date-helper'
import { Form } from '@hyper/forms/form'
import { useProductsAppData } from '@modules/products/hooks/use-products-app-data'
import { getPlanOptions } from '@modules/subscription-contract/consts'

interface Props extends BaseModalProps {
  client?: ClientDetails
  subscriptionDetails?: SubscriptionContractDetails
}

export interface SubscriptionContractCreateFormInputs {
  client: ClientUser
  invoice_company: string
  invoice_street: string
  invoice_postcode: string
  invoice_city: string
  invoice_nip: string
  invoice_type: InvoiceType
  seller: CustomReactSelectOption | undefined
  source_marketing: CustomReactSelectOption | undefined
  plan: CustomReactSelectOption | undefined
  sell_date: Date
  auto_cancel_after_date: Date | null
  required_payment_date: Date | null
}

const steps: ReceptionBookingCheckStep[] = [
  { step: 1, description: <>Dane klienta</> },
  { step: 2, description: 'Podsumowanie sprzedaży' },
]

export const SubscriptionContractCreateModal: React.FC<Props> = ({ toggleIsVisible, client, subscriptionDetails }) => {
  const [step, setStep] = React.useState(1)

  const dispatch = useAppDispatch()
  const sources = useGroupedSources()
  const sellers = useSellersOptions()

  const { addSuccessMessage } = useNotificationHook()
  const { showConfirmationPackageCreation } = useProductSaleConfirmationModal()
  const [showSubscriptionContractDetails] = useModal('SubscriptionContractDetailsModal', null, { persist: true })

  const formDefaults = React.useMemo(
    () =>
      subscriptionDetails
        ? R.pick(
            [
              'client',
              'invoice_company',
              'invoice_street',
              'invoice_postcode',
              'invoice_city',
              'invoice_nip',
              'invoice_type',
              'seller',
              'source_marketing',
              'sell_date',
            ],
            subscriptionDetails,
          )
        : {},
    [subscriptionDetails],
  )

  const { subscription_contract_plans } = useProductsAppData()

  const plansOptions = React.useMemo(
    () => getPlanOptions(subscription_contract_plans, subscriptionDetails?.plan !== 'plan_1_old'),
    [subscription_contract_plans],
  )

  const methods = useForm<SubscriptionContractCreateFormInputs>({
    defaultValues: {
      invoice_type: 'company',
      plan: makeDefaultSelectOption(plansOptions, subscriptionDetails?.plan),
      client,
      ...client?.profile,
      ...formDefaults,
      sell_date: formDefaults?.sell_date ? parseISODate(formDefaults.sell_date) : startOfToday(),
      source_marketing: sources.find(row => row.value === subscriptionDetails?.source_marketing),
      seller: sellers.find(row => row.value === subscriptionDetails?.seller_id),
      auto_cancel_after_date: parseISODate(subscriptionDetails?.auto_cancel_after_date),
      required_payment_date: parseISODate(subscriptionDetails?.required_payment_date),
    },
  })

  const { isLoading, action: onSubmit } = useFormRequest(
    async (payload: SubscriptionContractCreateFormInputs) => {
      try {
        if (subscriptionDetails) {
          await dispatch(updateSubscriptionContract([subscriptionDetails, payload]))
          addSuccessMessage('Sukces', 'Dane zostały zmienione.')
        } else {
          const resultAction = await dispatch(createSubscriptionContract(payload))
          const subscription = unwrapResult(resultAction)
          showConfirmationPackageCreation(() => showSubscriptionContractDetails(null, { subscription }))
        }

        toggleIsVisible()
      } catch (error) {
        setStep(1)
        throw error
      }
    },
    methods.setError,
    { showGlobalError: true },
  )

  const handleNextStep = () => setStep(curr => curr + 1)
  const handlePrevStep = () => setStep(curr => Math.max(1, curr - 1))

  const data = useWatch({ control: methods.control })

  const isNextStepDisabled = React.useMemo(() => {
    if (step === 1) return !data.client
  }, [data, step])

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>
        {subscriptionDetails ? 'Edytuj subskrypcję' : 'Sprzedaj subskrypcję'}
      </ModalHeader>
      {!subscriptionDetails && <ReceptionBookingCheckSteps step={step} steps={steps} />}
      <ModalBody className="pb-0">
        {step === 1 && (
          <SubscriptionContractCreateFormClientData
            initialClient={client ?? subscriptionDetails?.client}
            plansOptions={plansOptions}
            subscriptionDetails={subscriptionDetails}
          />
        )}
        {!subscriptionDetails && step === 2 && <SubscriptionContractCreateFormSummary />}
      </ModalBody>

      <ModalFooter style={{ display: 'block' }}>
        <Row>
          <Col md={6} className="p-0">
            {step !== 1 && (
              <Button onClick={handlePrevStep} className="btn btn-light">
                Wstecz
              </Button>
            )}
          </Col>
          <Col md={6} className="text-right p-0">
            {step === 2 || subscriptionDetails ? (
              <SaveButton
                role="submit"
                labelSaving={subscriptionDetails ? 'Zapisywanie...' : 'Sprzedawanie...'}
                label={subscriptionDetails ? 'Zapisz' : 'Sprzedaj'}
                className="btn btn-green"
                isSaving={isLoading}
              />
            ) : (
              <Button onClick={handleNextStep} className="btn btn-green" role="next-step" disabled={isNextStepDisabled}>
                Następny krok
              </Button>
            )}
          </Col>
        </Row>
      </ModalFooter>
    </Form>
  )
}
