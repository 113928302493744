import * as React from 'react'
import { Button, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { endOfMonth, startOfMonth, startOfToday } from 'date-fns'
import { createReportTask } from '@store/actions/report-tasks-actions'
import ModalBody from 'reactstrap/lib/ModalBody'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { SaveButton } from '@hyper/button'
import { FormPlain } from '@hyper/forms'
import Select from 'react-select'
import { useGroupedSellers } from '@components/hooks/use-grouped-sellers'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { BaseModalProps } from '@components/modals/types'

interface FormInputs {
  sell_date_after: Date | null
  sell_date_before: Date | null
  seller: undefined | string | any
}

export const ReportsGeneratorStatsSaleItem: React.FC<BaseModalProps> = ({ toggleIsVisible }) => {
  const reportUrl = useAppSelector(
    (state: RootState) => state.appState.appData.urls.reports.report_task_stats_sale_items,
  )

  const dispatch = useAppDispatch()

  const methods = useForm<FormInputs>({
    mode: 'all',
    defaultValues: {
      sell_date_after: startOfMonth(startOfToday()),
      sell_date_before: endOfMonth(startOfToday()),
      seller: undefined,
    },
  })

  const { isLoading, action: onSubmit } = useReportFormRequest(async (payload: FormInputs) => {
    const reportTask = await dispatch(
      createReportTask([
        reportUrl,
        {
          ...payload,
          seller: payload.seller?.value || null,
        },
      ]),
    )
    toggleIsVisible()
    return reportTask
  }, methods.setError)

  const sellers = useGroupedSellers([])

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggleIsVisible}>Raport sprzedaży</ModalHeader>
        <ModalBody>
          <Row>
            <DateFromToInput
              label="Data utworzenia abonamentu"
              startDateName="sell_date_after"
              endDateName="sell_date_before"
            />

            <FormPlain colSize={6} name="seller">
              <label>Sprzedawca</label>
              <Controller
                name="seller"
                control={methods.control}
                render={({ field }) => <Select placeholder="Wybierz" options={sellers} {...field} />}
              />
            </FormPlain>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="light" type="button" onClick={toggleIsVisible}>
            Zamknij
          </Button>
          <SaveButton className="btn btn-green" label="Zleć raport" labelSaving="Zlecanie.." isSaving={isLoading} />
        </ModalFooter>
      </form>
    </FormProvider>
  )
}
