import * as React from 'react'
import { Col, Row } from 'reactstrap'
import { YesNoWithIcon } from '@components/yes-no-with-icon'

interface Props {
  label: string
  contact_consent?: boolean
  contact_consent_datetime?: string
}

export const ClientDetailsModalClientProfileGuestsRowContactConsent: React.FC<Props> = ({
  label,
  contact_consent = false,
  contact_consent_datetime = '',
}) => (
  <Row className="ml-0">
    <Col md={8} className="p-0 mr-1 d-flex align-items-center">
      <p className="font-11 font-weight-light mb-0">{label}</p>
    </Col>
    <Row className="nowrap d-flex align-items-center">
      <YesNoWithIcon value={contact_consent} iconSize="font-18" textClass="font-11 font-weight-bold" />
      <p className="font-10 font-weight-light mb-0">{contact_consent_datetime}</p>
    </Row>
  </Row>
)
