import * as React from 'react'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap'
import { ColAuto } from '@hyper/col-auto'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { WalletTransaction, WalletTransactionBillItem } from '@models/reception'
import { IconWithText } from '@components/icon-with-text'
import { setReservationWalletTransactions } from '@store/slices/reservations-slice'
import { useAppDispatch } from '@store/index'

interface Props {
  selectedRows: WalletTransactionBillItem[]
  removeUrl: string
  clearSelectedRows: () => void
}

export const ReservationRoomBillTransactionsListModalMultiActions: React.FC<Props> = ({
  clearSelectedRows,
  removeUrl,
  selectedRows,
}) => {
  const isDisabled = selectedRows.length === 0
  const dispatch = useAppDispatch()

  const { addSuccessMessage } = useNotificationHook()

  const { isLoading, action: handleAccept } = useApiRequest(async () => {
    clearSelectedRows()

    const response = await commonObjectDelete<WalletTransaction[]>(removeUrl, null, {
      ids: selectedRows.map(row => row.id),
    })

    dispatch(setReservationWalletTransactions(response))
    addSuccessMessage('Sukces', 'Transakcje zostały skasowane')
  })

  return (
    <ColAuto className="mt-3">
      <UncontrolledButtonDropdown disabled={isDisabled}>
        <DropdownToggle color="light" caret={true} disabled={isDisabled}>
          <strong>zaznaczone ({selectedRows.length})</strong>
        </DropdownToggle>
        <DropdownMenu className="py-0">
          <CommonObjectConfirmAction isLoading={isLoading} handleAccept={handleAccept}>
            <DropdownItem className="py-1 btn-tall">
              <IconWithText icon="uil-trash-alt mr-1 text-bold" text={`Skasuj (${selectedRows.length})`} />
            </DropdownItem>
          </CommonObjectConfirmAction>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </ColAuto>
  )
}
