import { Apartment } from '@models/apartment'
import { CalendarDate, TimelineBooking } from '@store/reducers/timeline-reducers'
import { XYCoord } from 'react-dnd'
import styleHelper from '@helpers/style-helper'
import timelineHelper from '@helpers/timeline-helper'
import store from '@store/index'
import { CSSProperties } from 'react'
import {
  ReservationItemDiff,
  TimelineItemDragObject,
  TimelineItemDragType,
  TimelineResizerDragObject,
} from '@modules/reservations/timeline/timeline-content-droppable'
import { TimelineResizerDirection } from '@modules/reservations/timeline/timeline-item-resizer'
import * as R from 'ramda'
import { addDays, differenceInCalendarDays, parseISO } from 'date-fns'
import dateHelper, { formatDate } from '@helpers/date-helper'

const self = {
  getInitialItemPosition: (
    apartment: Apartment,
    allApartments: Apartment[],
    renderedDates: CalendarDate[],
    date: string,
  ): XYCoord => ({
    x: styleHelper.pxToValue(timelineHelper.getItemLeftOffset(renderedDates, date)),
    y: styleHelper.pxToValue(timelineHelper.getItemTopOffset(apartment, allApartments)),
  }),
  getScrollDiff: (initialScroll: XYCoord): XYCoord => {
    const { x: initialX, y: initialY } = initialScroll
    const scrollX = store.getState().timelineState.scrollPosition
    return {
      x: scrollX - initialX,
      y: window.scrollY - initialY,
    }
  },
  snapToGrid: (position: XYCoord): XYCoord => {
    const { x, y } = position
    const xSnap = timelineHelper.getDayWidthMargin()
    const ySnap = timelineHelper.getRowHeight()
    const snappedX = Math.round(x / xSnap) * xSnap - timelineHelper.getDayWidth() / 2 - timelineHelper.itemsMargin
    const snappedY = Math.round(y / ySnap) * ySnap
    return {
      x: snappedX,
      y: snappedY,
    }
  },
  snapResizerToGrid: (position: number): number => {
    const xSnap = timelineHelper.getDayWidthMargin()
    return Math.round(position / xSnap) * xSnap
  },
  getNewBookingDate: (bookingCord: XYCoord): Date => {
    const daysFromFirst = Math.round(bookingCord.x / timelineHelper.getDayWidthMargin())
    const { month: firstMonth, year: firstYear } = store.getState().timelineState.calendarDates.dates[0]
    const date = parseISO(dateHelper.createDateFormat(firstYear, firstMonth, 1))
    return addDays(date, daysFromFirst)
  },
  getBookingCord: (offsetDiff: XYCoord, initialItemPosition: XYCoord, initialScroll: XYCoord): XYCoord => {
    const { x: scrollDiffX, y: scrollDiffY } = self.getScrollDiff(initialScroll)
    const newPosition: XYCoord = {
      x: initialItemPosition.x + offsetDiff.x + scrollDiffX,
      y: initialItemPosition.y + offsetDiff.y + scrollDiffY,
    }
    return self.snapToGrid(newPosition)
  },
  getBookingStyle: (cord: XYCoord): CSSProperties => ({
    left: styleHelper.valueToPX(cord.x),
    top: styleHelper.valueToPX(cord.y),
  }),
  getResizeDays: (
    offsetDiff: XYCoord,
    initialItemPositionRight: XYCoord,
    initialItemPositionLeft: XYCoord,
    initialScrollPosition: XYCoord,
    direction: TimelineResizerDirection,
    booking: TimelineBooking,
  ): number => {
    const initialItemPosition = direction === 'right' ? initialItemPositionRight : initialItemPositionLeft
    const date = direction === 'right' ? booking.date_to : booking.date_from
    const resizerCord = timelineDragHelper.getBookingCord(offsetDiff, initialItemPosition, initialScrollPosition)
    const days = differenceInCalendarDays(timelineDragHelper.getNewBookingDate(resizerCord), parseISO(date))
    const daysFix = direction === 'right' ? days : -days
    return booking.days_count + daysFix < 1 ? -(booking.days_count - 1) : daysFix
  },
  getNewApartmentID: (cord: XYCoord, allApartments: Apartment[]): number => {
    const newApIndex = Math.floor(cord.y / timelineHelper.getRowHeight())
    const newAp = R.find<Apartment>(ap => R.indexOf(ap, allApartments) === newApIndex)(allApartments)
    return newAp ? newAp.id : 0
  },
  getBookingDiff: (currentCord: XYCoord, booking: TimelineBooking, allApartments: Apartment[]): ReservationItemDiff => {
    const newDateFrom = self.getNewBookingDate(currentCord)
    const newDateTo = addDays(newDateFrom, booking.days_count)
    const newApartmentID = self.getNewApartmentID(currentCord, allApartments)

    return {
      ...(formatDate(newDateTo) !== booking.date_to ? { newDateTo: formatDate(newDateTo) } : {}),
      ...(formatDate(newDateFrom) !== booking.date_from ? { newDateFrom: formatDate(newDateFrom) } : {}),
      ...(booking.apartment.id !== newApartmentID
        ? { newApartmentID: self.getNewApartmentID(currentCord, allApartments) }
        : {}),
    }
  },
  isNewResizerDate: (movedDays: number | undefined): boolean => movedDays !== 0 && movedDays !== undefined,
  getNewResizerDate: (movedDays: number, date): string => formatDate(addDays(parseISO(date), movedDays)),
  shouldShowModal: (
    itemType: TimelineItemDragType,
    dragObject: TimelineItemDragObject | TimelineResizerDragObject,
    currentCord: XYCoord,
    movedDays: number | undefined,
    allApartments: Apartment[],
  ): boolean =>
    itemType === 'RESERVATION_ITEM'
      ? !R.isEmpty(self.getBookingDiff(currentCord, dragObject.booking, allApartments))
      : self.isNewResizerDate(movedDays),
}

const timelineDragHelper = self
export default timelineDragHelper
