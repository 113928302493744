import * as React from 'react'
import { Card, CardBody, Col } from 'reactstrap'

interface ReservationStatsBoxProps {
  title: string
  children: React.ReactChild | React.ReactElement[]
  boxSize: number
}

export const ReservationStatsBox: React.FC<ReservationStatsBoxProps> = ({ title, children, boxSize }) => (
  <Col md={boxSize}>
    <Card className="h-100">
      <CardBody className="d-flex flex-column">
        <strong className="mb-2 d-block text-secondary font-18">{title}</strong>
        {children}
      </CardBody>
    </Card>
  </Col>
)
