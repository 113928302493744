import { Col, Row } from 'reactstrap'
import { formatDate } from '@helpers/date-helper'
import declination from '@helpers/declination'
import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { InvoiceType } from '@models/promotions'

export interface ReservationSummaryProps {
  reservation: ReceptionBookingDetails
  showInvoice: boolean
  invoiceType?: InvoiceType
}

const ReservationSummary = ({ reservation, showInvoice, invoiceType }: ReservationSummaryProps): JSX.Element => (
  <div className="mb-4">
    <h5>Informacje o rezerwacji:</h5>
    <Row className="reservation-create__step-summary__text-light mb-2">
      <Col md={5}>Nr rezerwacji:</Col>
      <Col md={7} className="text-strong">
        {reservation.reservation_number}
      </Col>
    </Row>
    <Row className="reservation-create__step-summary__text-light mb-2">
      <Col md={5}>Pobyt w dniach:</Col>
      <Col md={7} className="text-strong">
        {formatDate(reservation.date_from, 'dd.MM')} - {formatDate(reservation.date_to, 'dd.MM.yyyy')}
      </Col>
    </Row>
    <Row className="reservation-create__step-summary__text-light mb-2">
      <Col md={5}>Długość pobytu:</Col>
      <Col md={7} className="text-strong">
        {reservation.days_count} {declination.night(reservation.days_count)}
      </Col>
    </Row>
    {showInvoice && (
      <Row className="reservation-create__step-summary__text-light">
        <Col md={5}>Faktura VAT:</Col>
        <Col md={7} className="text-strong">
          <div className="mb-1">
            {invoiceType === 'company' ? 'NIP' : 'PESEL'} {reservation.invoice_nip}
          </div>
          <div className="mb-1">{reservation.invoice_company}</div>
          <div className="mb-1">{reservation.invoice_street}</div>
          <div>
            {reservation.invoice_postcode}, {reservation.invoice_city}
          </div>
        </Col>
      </Row>
    )}
  </div>
)

export default ReservationSummary
