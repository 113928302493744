import * as React from 'react'
import { useModal } from '@components/modals/use-modal'
import { commonObjectGet } from '@store/actions/generic-actions'
import { RootState, useAppSelector } from '@store/index'
import { SubscriptionContractDetails } from '@modules/subscription-contract/models'

interface Props {
  id: string
}

export const SubscriptionContractDetailsView = ({ id }: Props): null => {
  const [handleDetails] = useModal('SubscriptionContractDetailsModal', { persist: true })
  const url = useAppSelector(
    (state: RootState) => state.appState.appData.urls.subscription_contract.subscription_contracts,
  )

  const show = async () => {
    const subscription = await commonObjectGet<SubscriptionContractDetails>(`${url}${id}/`)
    handleDetails(null, { subscription }, true)
  }

  React.useEffect(() => {
    show()
  }, [])

  return null
}
