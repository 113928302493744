import * as React from 'react'
import { hasCars } from '@modules/reception/common/tools'
import { ReceptionBookingCheckInPreviewPersonalDataRow } from '@modules/reception/checkin/preview/personal-data/reception-booking-check-in-preview-personal-data-row'
import { ReceptionCheckReminder } from '@modules/reception/common/reception-check-reminder'
import { IconWithText } from '@components/icon-with-text'

export const ReceptionBookingCheckInPreviewPersonalDataParkingRow = ({ bookingDetails }) => {
  if (!hasCars(bookingDetails)) return null
  return (
    <>
      <ReceptionBookingCheckInPreviewPersonalDataRow
        content={
          <ReceptionCheckReminder
            initialValue={false}
            id="parking_card_print"
            errors={{}}
            type="info"
            required={true}
            isClosable
          >
            <span className="font-10">
              Pamiętaj o wydrukowaniu <strong>karty parkingowej</strong>
            </span>
          </ReceptionCheckReminder>
        }
      />
      <ReceptionBookingCheckInPreviewPersonalDataRow
        content={
          <a href={bookingDetails.urls.print_parking} className="text-default text-decoration-none" target="_blank">
            <IconWithText icon="uil-print" text="Drukuj kartę parkingową" textClass="font-11" />
          </a>
        }
      />
    </>
  )
}
