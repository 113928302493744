import * as React from 'react'
import { HandleConfiguration, useHandleAxiosFormErrors } from '@helpers/handle-axios-errors'
import { REQUEST_CANCELED } from '@components/hooks/use-cancelable-promise'
import { useNotificationHook } from '@hyper/use-notification-hook'

type state = 'pending' | 'resolved' | 'rejected' | 'idle'

export const useApiRequest = function <T>(asyncCallable) {
  const [state, setState] = React.useState<state>('idle')
  const [data, setData] = React.useState<T | undefined>(undefined)
  const { addErrorMessage } = useNotificationHook()

  const action = async (...params) => {
    setState('pending')
    try {
      const result = await asyncCallable(...params)
      setData(result)
      setState('resolved')
      return result
    } catch (error) {
      if (error.response?.status !== 500) {
        console.warn(error)
      }
      if (error.response?.status === 403) {
        addErrorMessage('Błąd', 'Brak uprawnień do wykonania tej akcji')
      } else if (error.response?.status === 400) {
        if (error.response.data.non_field_errors) {
          addErrorMessage('Błąd', error.response.data.non_field_errors.join('. '))
        } else {
          const message = Object.values(error.response.data)[0]
          if (message && Array.isArray(message)) {
            addErrorMessage('Błąd', message.join('. '))
          }
        }
      } else if (error.message !== REQUEST_CANCELED) {
        console.log(error)
        addErrorMessage('Błąd', 'Wystąpił błąd podczas pobierania danych')
      }
      setState('rejected')
    }
  }

  return {
    state,
    action,
    data,
    isLoading: state === 'pending',
  }
}

export const useFormRequest = function <T>(
  asyncCallable,
  setError,
  configuration: Partial<HandleConfiguration> = { excludeFields: [], mapFormError: [] },
) {
  const handleAxiosFormErrors = useHandleAxiosFormErrors()

  return useApiRequest<T>(async (...params) => {
    try {
      return await asyncCallable(...params)
    } catch (error) {
      if (configuration.customErrorHandler) {
        configuration.customErrorHandler(error)
        return
      }

      handleAxiosFormErrors(error, setError, { showGlobalError: false, ...configuration })
    }
  })
}
