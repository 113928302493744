import * as React from 'react'
import { Col, Row } from 'reactstrap'
import { ReceptionCheckType } from '@models/reception'
import { ReceptionNotificationButton } from '@modules/reception/common/reception-notification-button'
import { ReceptionDownloadButton } from '@modules/reception/common/reception-download-button'

interface ReceptionButtonsProps {
  date: Date
  ids: number[]
  resortId: number
  type: ReceptionCheckType
}

export const ReceptionButtons: React.FC<ReceptionButtonsProps> = ({ type, ids, date, resortId }) => (
  <Row>
    <Col md={12} className="text-right">
      <div className="btn-group mr-1">
        <ReceptionNotificationButton type={type} date={date} resortId={resortId} />
      </div>
      <div className="btn-group">
        <ReceptionDownloadButton ids={ids} date={date} resortId={resortId} type={type} />
      </div>
    </Col>
  </Row>
)
