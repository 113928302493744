import * as React from 'react'
import { StepReservationBox } from '@modules/reservations/create/step-reservation-box'
import { ReceptionBookingCheck } from '@modules/reception/common/reception-booking-check'
import { FormProvider, useFormContext, useWatch } from 'react-hook-form'
import { ReceptionBookingDetails, ReceptionReminder } from '@models/reception'
import { newUserPredicator, ReceptionCheckReminders } from '@modules/reception/common/reception-check-reminders'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { ReceptionBookingNewFeedingContent } from '@modules/reception/checkin/step-feeding/new-feeding/reception-booking-new-feeding-content'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { useNewFeedingCalculations } from '@modules/reception/checkin/step-feeding/new-feeding/use-new-feeding-calculations'
import { getFeedingChanges } from '@modules/reception/checkin/step-feeding/new-feeding/utils'
import { useCartSave } from '@modules/reception/checkin/step-feeding/new-feeding/use-cart-save'
import { getReceptionBookingDetails } from '@store/actions/reception-actions'
import { useAppDispatch } from '@store/index'
import { useNewFeedingVoucherPayment } from '@modules/reception/checkin/step-feeding/new-feeding/use-new-feeding-voucher-payment'

interface StepFeedingProps {
  nextStep: () => void
  previousStep: () => void
  reservation: ReceptionBookingDetails
}

const reminders: ReceptionReminder[] = [
  {
    id: 'check_feeding',
    isClosable: true,
    children: (
      <>
        Zaproponuj wykup <strong>dodatkowego wyżywienia. </strong>
      </>
    ),
  },
]

const StepFeeding = ({ previousStep, nextStep, reservation }: StepFeedingProps): JSX.Element | null => {
  const methods = useFormContext()
  const user = useAuthenticatedUser()

  const feedings = useWatch({ control: methods.control })
  const dispatch = useAppDispatch()

  const { feedingCalculations, initialFeedingCalculations, fetchFeedingCalculations } = useNewFeedingCalculations(
    reservation,
    methods,
  )

  const feedingChanges = React.useMemo(() => {
    if (!initialFeedingCalculations?.details) return null

    return getFeedingChanges(feedings, initialFeedingCalculations.details)
  }, [feedings, initialFeedingCalculations?.details])

  const { isPaymentByWalletSelected, toggleIsPaymentByWalletSelected } =
    useNewFeedingVoucherPayment(initialFeedingCalculations)

  const { saveCart, isSaving } = useCartSave(
    reservation,
    feedingChanges,
    true,
    fetchFeedingCalculations,
    isPaymentByWalletSelected,
  )

  const handleSave = async () => {
    await saveCart()
    dispatch(getReceptionBookingDetails(reservation.urls.api_details))
    nextStep()
  }

  return (
    <StepReservationBox
      title="4. Wybór wyżywienia"
      footer={
        <ReceptionBookingCheck
          nextStep={nextStep}
          previousStep={previousStep}
          isSaving={isSaving}
          onSave={handleSave}
        />
      }
    >
      <LoaderPlaceholder content={feedingCalculations && initialFeedingCalculations}>
        <FormProvider {...methods}>
          <div className="mb-3">
            <ReceptionCheckReminders required={!newUserPredicator(user)} reminders={reminders} />
          </div>

          {feedingCalculations && initialFeedingCalculations && (
            <ReceptionBookingNewFeedingContent
              booking={reservation}
              toggleIsPaymentByWalletSelected={toggleIsPaymentByWalletSelected}
              isPaymentByWalletSelected={isPaymentByWalletSelected}
              feedingCalculations={feedingCalculations}
              initialFeedingCalculations={initialFeedingCalculations}
            />
          )}
        </FormProvider>
      </LoaderPlaceholder>
    </StepReservationBox>
  )
}

export default StepFeeding
