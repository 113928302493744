import * as React from 'react'
import { Col, Row } from 'reactstrap'

interface Props {
  name: string
  children: React.ReactNode
}

export const ClientDetailsModalBaseDataRow = ({ name, children }: Props): JSX.Element => (
  <Row className="align-items-center my-2">
    <Col md={5}>{name}</Col>
    <Col md={7}>{children}</Col>
  </Row>
)
