import * as React from 'react'
import { ResortTabs } from '@components/resort-tabs'
import { PageTitle } from '@hyper/page-title'
import { useDocumentTitle } from '@helpers/utils'
import { NavigationPath } from '@models/routes'
import { useParams } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { TableFilters } from '@components/table/table'
import { useGetGuestsTechnicalOrdersQuery } from '@api/technical-orders'
import { TypedQueryResult } from '@api/base'
import { TechnicalOrder } from '@models/technical-orders'
import { TechnicalOrdersDetailsView } from '@modules/technical-orders/details'
import { ReceptionTechnicalOrdersFilters } from '@modules/reception-technical-orders/filters'
import { GuestsTechnicalOrderCreateButton } from '@modules/guests-technical-orders/guests-technical-order-create-button'
import { GuestsTechnicalOrdersTable } from '@modules/guests-technical-orders/table'

interface Params {
  resort_id: string
  id?: string
}
const defaultFilters: TableFilters = {
  search: '',
  ordering: 'resort',
  page: 1,
  page_size: 9999,
}
const emptyTable = []
export const GuestsTechnicalOrdersView: React.FC = () => {
  const [filters, setFilters] = React.useState<TableFilters>(defaultFilters)

  const { resort_id, id = '' } = useParams() as unknown as Params
  const resortId = parseInt(resort_id, 10)
  const pageTitle = 'Zgłoszenia'

  const { data = emptyTable } = useGetGuestsTechnicalOrdersQuery({
    resort: resortId,
    ...filters,
  }) as TypedQueryResult<TechnicalOrder[]>

  useDocumentTitle(pageTitle)
  useMenuActive(NavigationPath.GuestsTechnicalOrders)

  const user = useAuthenticatedUser()

  const technicalOrdersNew = React.useMemo(() => data.filter(row => row.status === 'new'), [data])
  const technicalOrders = React.useMemo(() => data.filter(row => row.status !== 'new'), [data])

  return (
    <>
      <TechnicalOrdersDetailsView id={id} kind="guests_technical_order" />
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitle} />
      <Card>
        <CardBody>
          <ResortTabs
            showAll={user.isTechnicalOrderManager}
            resortId={resortId}
            url={NavigationPath.GuestsTechnicalOrdersWithParams}
          >
            <GuestsTechnicalOrderCreateButton resortId={resortId} />
          </ResortTabs>
          <ReceptionTechnicalOrdersFilters setFilters={setFilters} filters={filters} defaultFilters={defaultFilters} />
          <h4 className="text-danger">Nowe zgłoszenia:</h4>
          <GuestsTechnicalOrdersTable
            showUsers={false}
            setFilters={setFilters}
            filters={filters}
            technicalOrders={technicalOrdersNew}
            dataTestId="qa-table-guests-technical-orders-high-priority"
          />
          <h4>Zadania podjęte:</h4>
          <GuestsTechnicalOrdersTable
            showUsers={true}
            setFilters={setFilters}
            filters={filters}
            dataTestId="qa-table-guests-technical-orders-normal-priority"
            technicalOrders={technicalOrders}
          />
        </CardBody>
      </Card>
    </>
  )
}
