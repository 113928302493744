import * as React from 'react'
import { Button } from 'reactstrap'
import { ImprovementOrder, ImprovementOrderDetails } from '@modules/housekeeping/models'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useHandleAxiosServerErrors } from '@helpers/handle-axios-errors'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useModal } from '@components/modals/use-modal'
import { HousekeepingImprovementOrdersRowActionsOrder } from '@modules/housekeeping/cleaning-orders/improvement/actions-order'
import { updateHousekeepingImprovementOrderDetails } from '@store/slices/housekeeping-slice'

interface Props {
  improvementOrder: ImprovementOrder
}

export const HousekeepingImprovementOrdersRowActions: React.FC<Props> = ({ improvementOrder }) => {
  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()
  const handleAxiosServerErrors = useHandleAxiosServerErrors()

  const handleFinish = async () => {
    try {
      dispatch(
        updateHousekeepingImprovementOrderDetails(
          await commonObjectPost<ImprovementOrderDetails>(improvementOrder.urls.finish_working),
        ),
      )

      addSuccessNotification('Zlecenie zostało wykonane!')
    } catch (error) {
      handleAxiosServerErrors(error)
    }
  }

  const handleOrderUpdate = (order: ImprovementOrderDetails) => {
    dispatch(updateHousekeepingImprovementOrderDetails(order))
  }

  const [handleAssign] = useModal('HousekeepingCleaningOrderAssignModal', {
    order: improvementOrder,
    onlyHprCompany: true,
    onOrderUpdate: handleOrderUpdate,
    serviceKind: 'improvement',
  })

  return (
    <>
      <HousekeepingImprovementOrdersRowActionsOrder improvementOrder={improvementOrder} />

      {improvementOrder.status !== 'FINISHED' && (
        <Button color="green" className="mr-1 btn-sm" onClick={handleFinish} disabled={!improvementOrder.users.length}>
          Wykonane
        </Button>
      )}

      {improvementOrder.status === 'PENDING' && !!improvementOrder.users.length && (
        <Button color="warning" className="text-white btn-sm" onClick={handleAssign}>
          Zmień osoby
        </Button>
      )}
    </>
  )
}
