import * as React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { CashCountModalRowInput } from '@modules/payment-registers/cash-count-modal/row-input'
import { useFormContext, useWatch } from 'react-hook-form'
import classNames from 'classnames'
import {
  BanknoteInputs,
  CashCountCurrency,
  cashCountGetTotalAmount,
} from '@modules/payment-registers/cash-count-modal/use-cash-count'
import { asDecimal, formatPriceShort } from '@helpers/utils'

interface Props {
  name: BanknoteInputs
  currency: CashCountCurrency
  getBanknoteImageUrl: (banknote: string, currency: CashCountCurrency) => string
}

export const CashCountModalRow = ({ name, currency, getBanknoteImageUrl }: Props): JSX.Element => {
  const { setValue, getValues, control } = useFormContext()
  const field = `${currency}.${name}`
  const value = useWatch({ control, name: field })

  const handleAdd = () => setValue(field, getValues(field) + 1)

  const totalValue = cashCountGetTotalAmount({ [name]: value })
  const baseAmount = asDecimal(totalValue).div(value).toString()

  return (
    <Col md={4}>
      <Card
        className={classNames('transition-all-500-ease', {
          'opacity-5': value === 0,
        })}
      >
        <CardBody className="p-2">
          <Row>
            <Col md={8}>
              <img src={getBanknoteImageUrl(name, currency)} alt="" onClick={handleAdd} />
            </Col>
            <CashCountModalRowInput name={field} />

            {!!value && (
              <Col md={12} className="mt-1 text-center">
                {baseAmount} {currency} x {value} ={' '}
                <strong>
                  {formatPriceShort(totalValue, true)} {currency}
                </strong>
              </Col>
            )}
          </Row>
        </CardBody>
      </Card>
    </Col>
  )
}
