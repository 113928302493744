import * as React from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { Button, ModalFooter, ModalHeader } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { SaveButton } from '@hyper/button'
import { startOfToday } from 'date-fns'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { CreateImprovementOrderModalForm } from '@modules/housekeeping/cleaning-orders/create-improvement-order/form'
import { commonObjectPost } from '@store/actions/generic-actions'
import { ImprovementOrderDetails } from '@modules/housekeeping/models'
import { useFormRequest } from '@components/hooks/use-api-request'
import { BaseModalProps } from '@components/modals/types'
import { updateHousekeepingImprovementOrderDetails } from '@store/slices/housekeeping-slice'
import { useApartments } from '@components/hooks/use-apartments'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { handleFormSubmit } from '@helpers/forms'
import { getResortDetails } from '@store/actions/reception-actions'
import { formatDate } from '@helpers/date-helper'

interface Props extends BaseModalProps {
  resortId: number
  apartmentId?: number
  onComplete?: () => void
}

export interface CreateImprovementOrderModalFormInputsItem {
  code: CustomReactSelectOption | null
  amount: number
}

export interface CreateImprovementOrderModalFormInputs {
  company: CustomReactSelectOption | null
  apartment: CustomReactSelectOption | null
  date: Date
  users: string[]
  ready_for: string
  comment: string
  items: CreateImprovementOrderModalFormInputsItem[]
}

export const CreateImprovementOrderModal: React.FC<Props> = ({
  onComplete,
  apartmentId,
  resortId,
  toggleIsVisible,
}) => {
  const { addSuccessNotification } = useNotificationHook()

  useApartments(resortId)

  const methods = useForm<CreateImprovementOrderModalFormInputs>({
    defaultValues: {
      apartment: null,
      company: null,
      date: startOfToday(),
      items: [{ code: null, amount: 1 }],
    },
  })
  const dispatch = useAppDispatch()

  const url = useAppSelector((state: RootState) => state.appState.appData.urls.housekeeping.improvement_orders)

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: CreateImprovementOrderModalFormInputs) => {
    dispatch(
      updateHousekeepingImprovementOrderDetails(
        await commonObjectPost<ImprovementOrderDetails>(url, {
          resort: resortId,
          ...payload,
          company: payload.company?.value,
          users: payload.users?.filter(x => x) || [],
          apartment: apartmentId || payload.apartment?.value,
          date: formatDate(payload.date),
          items: payload.items.map(item => ({ amount: item.amount, code: item.code?.value })),
        }),
      ),
    )
    addSuccessNotification('Zlecenia zostały stworzone')
    toggleIsVisible()
    onComplete?.()
  }, methods.setError)

  const date: string = formatDate(useWatch({ control: methods.control, name: 'date' }))

  React.useEffect(() => {
    dispatch(getResortDetails(resortId, undefined, date, date))
  }, [resortId, date])

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleFormSubmit(methods, onSubmit)}>
        <ModalHeader toggle={toggleIsVisible}>
          <span className="text-semi-strong">Zleć</span> <strong>ulepszenie</strong>
        </ModalHeader>
        <ModalBody>
          <CreateImprovementOrderModalForm apartmentId={apartmentId} resortId={resortId} />
        </ModalBody>
        <ModalFooter>
          <Button color="light" type="button" onClick={toggleIsVisible}>
            Zamknij
          </Button>
          <SaveButton className="btn btn-green" label="Zleć" labelSaving="Zlecanie.." isSaving={isLoading} />
        </ModalFooter>
      </form>
    </FormProvider>
  )
}
