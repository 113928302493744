import * as React from 'react'
import { ReceptionBookingNewFeeding } from '@modules/reception/checkin/step-feeding/new-feeding/reception-booking-new-feeding'
import { ReceptionBookingDetails } from '@models/reception'
import { BaseModalProps } from '@components/modals/types'
import { ModalBody, ModalHeader } from 'reactstrap'

interface Props extends BaseModalProps {
  bookingDetails: ReceptionBookingDetails
  onClose: () => void
}

export const ReceptionBookingNewFeedingModal = ({
  bookingDetails,
  onClose,
  toggleIsVisible,
}: Props): React.ReactElement => {
  const handleClose = () => {
    onClose()
    toggleIsVisible()
  }

  return (
    <>
      <ModalHeader>Wyżywienie rezerwacji {bookingDetails.reservation_number}</ModalHeader>
      <ModalBody>
        <ReceptionBookingNewFeeding booking={bookingDetails} onClose={handleClose} />
      </ModalBody>
    </>
  )
}
