import * as React from 'react'
import { useParams } from 'react-router-dom'
import { PageTitle } from '@hyper/page-title'
import { useDocumentTitle } from '@helpers/utils'
import { getResortDetails } from '@store/actions/reception-actions'
import { useAppDispatch, useAppSelector } from '@store/index'
import { HousekeepingCleaningOrderType } from '@modules/housekeeping/models'
import { ResortTabs } from '@components/resort-tabs'
import { Card, CardBody } from 'reactstrap'
import { NavigationPath } from '@models/routes'
import { HousekeepingTabs } from '@modules/housekeeping/cleaning-orders/housekeeping-tabs'
import { HousekeepingCleaningOrdersToOrder } from '@modules/housekeeping/cleaning-orders/to-order'
import { HousekeepingCleaningOrdersAccepted } from '@modules/housekeeping/cleaning-orders/accepted'
import { HousekeepingCleaningOrdersArchive } from '@modules/housekeeping/cleaning-orders/archive'
import { HousekeepingCleaningOrdersOrdered } from '@modules/housekeeping/cleaning-orders/ordered'
import { HousekeepingCleaningOrdersToCheck } from '@modules/housekeeping/cleaning-orders/to-check'
import { HousekeepingCleaningOrdersCleaning } from '@modules/housekeeping/cleaning-orders/cleaning'
import { HousekeepingBeddingOrders } from '@modules/housekeeping/cleaning-orders/bedding'
import { HousekeepingCleaningOrdersWebSocketHandler } from '@modules/housekeeping/cleaning-orders/housekeeping-cleaning-orders-web-socket-handler'
import { useHousekeepingAppData } from '@modules/housekeeping/hooks/use-housekeeping-app-data'
import { resortsMapSelector } from '@store/selectors/dashboard'
import { HousekeepingImprovementOrders } from '@modules/housekeeping/cleaning-orders/improvement'
import { setDashboardMobileView } from '@store/slices/app-slice'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { HousekeepingCommonTasks } from '@modules/housekeeping/cleaning-orders/common-tasks'
import { CreateCleaningOrder } from '@modules/housekeeping/cleaning-orders/create-cleaning-order'
import { CreateImprovementOrder } from '@modules/housekeeping/cleaning-orders/create-improvement-order'

interface Params {
  resort_id: string
  tab: HousekeepingCleaningOrderType
}

export const HousekeepingCleaningOrdersParams: React.FC = () => {
  const { resort_id, tab } = useParams() as unknown as Params
  const resortId = parseInt(resort_id, 10)

  const dispatch = useAppDispatch()
  const resort = useAppSelector(resortsMapSelector)[resortId]

  React.useEffect(() => {
    dispatch(getResortDetails(resortId))
    dispatch(setDashboardMobileView(true))

    return () => {
      dispatch(setDashboardMobileView(false))
    }
  }, [resortId])

  useMenuActive(NavigationPath.HousekeepingCleaningOrders)
  useHousekeepingAppData(resortId)

  const pageTitle = `Housekeeping ${resort?.name || ''}`
  useDocumentTitle(pageTitle)

  const nextHistoryRoute = NavigationPath.HousekeepingCleaningOrdersWithParams.replace(':tab', String(tab))

  return (
    <>
      <HousekeepingCleaningOrdersWebSocketHandler resortId={resortId} tab={tab} />
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitle} />
      <Card className="reception">
        <CardBody>
          <ResortTabs resortId={resortId} url={nextHistoryRoute}>
            {[HousekeepingCleaningOrderType.TO_ORDER, HousekeepingCleaningOrderType.ORDERED].includes(tab) && (
              <CreateCleaningOrder resortId={resortId} />
            )}
            {tab === HousekeepingCleaningOrderType.IMPROVEMENT && <CreateImprovementOrder resortId={resortId} />}
          </ResortTabs>
          <HousekeepingTabs resortId={resortId} tab={tab} />

          {tab === HousekeepingCleaningOrderType.ACCEPTED && <HousekeepingCleaningOrdersAccepted resortId={resortId} />}
          {tab === HousekeepingCleaningOrderType.ARCHIVE && <HousekeepingCleaningOrdersArchive resortId={resortId} />}
          {tab === HousekeepingCleaningOrderType.BEDDING && <HousekeepingBeddingOrders resortId={resortId} />}
          {tab === HousekeepingCleaningOrderType.IMPROVEMENT && <HousekeepingImprovementOrders resortId={resortId} />}
          {tab === HousekeepingCleaningOrderType.CLEANING && <HousekeepingCleaningOrdersCleaning resortId={resortId} />}
          {tab === HousekeepingCleaningOrderType.ORDERED && <HousekeepingCleaningOrdersOrdered resortId={resortId} />}
          {tab === HousekeepingCleaningOrderType.TO_CHECK && <HousekeepingCleaningOrdersToCheck resortId={resortId} />}
          {tab === HousekeepingCleaningOrderType.TO_ORDER && <HousekeepingCleaningOrdersToOrder resortId={resortId} />}
          {tab === HousekeepingCleaningOrderType.COMMON_TASK && <HousekeepingCommonTasks resortId={resortId} />}
        </CardBody>
      </Card>
    </>
  )
}
