import * as React from 'react'
import { ImprovementAvailability } from '@models/booking'
import { asDecimal, formatPriceShort } from '@helpers/utils'
import { parseISODate } from '@helpers/date-helper'
import { ImprovementIcon } from '@components/improvement-icon'
import { FormProvider, useForm } from 'react-hook-form'
import { ReceptionBookingDetails } from '@models/reception'
import { ReceptionBookingCheckInStepImprovementsActionColumns } from '@modules/reception/checkin/step-improvements/reception-booking-check-in-step-improvements-action-columns'
import { ReceptionBookingCheckInStepImprovementError } from '@modules/reception/checkin/step-improvements/reception-booking-check-in-step-improvement-error'

interface FormInputs {
  amount: number
  date_from: Date | null
  date_to: Date | null
}

interface Props {
  price_brutto: string
  totalPriceBrutto: string
  price_brutto_first_item: string
  booking: ReceptionBookingDetails
  improvement: ImprovementAvailability
}

export const ReceptionBookingCheckInStepSingleImprovement: React.FC<Props> = ({
  booking,
  improvement,
  price_brutto,
  price_brutto_first_item,
  totalPriceBrutto,
}) => {
  const [error, setError] = React.useState<string | undefined>(undefined)
  const [improvementPrice, setImprovementPrice] = React.useState<string | undefined>(totalPriceBrutto)

  React.useEffect(() => {
    setImprovementPrice(totalPriceBrutto)
  }, [totalPriceBrutto])

  const handlePriceUpdate = (price: string | undefined) => setImprovementPrice(price)

  const methods = useForm<FormInputs>({
    defaultValues: {
      amount: 1,
      date_from: parseISODate(booking.date_from),
      date_to: parseISODate(booking.date_to),
    },
  })

  const hasTwoPrice =
    price_brutto && asDecimal(price_brutto_first_item).gt(asDecimal(0)) && price_brutto != price_brutto_first_item

  return (
    <FormProvider {...methods}>
      <tr className="font-14 reception__booking-check-in__improvement-step__table__row bg-white-smoke reception__booking-check-in__improvement-step__table__row--inactive">
        <td className="reception__booking-check-in__improvement-step__improvement-col reception__booking-check-in__improvement-step__table__data">
          <div className="d-flex">
            <ImprovementIcon improvementCode={improvement.code} className="text-secondary font-14" />
            <div className="ml-1">
              <span className="text-semi-strong">{improvement.name}</span>
              <span className="text-muted font-11 ml-1">
                {hasTwoPrice ? (
                  <>
                    +{formatPriceShort(price_brutto_first_item)}/{formatPriceShort(price_brutto)}
                  </>
                ) : (
                  <>+{formatPriceShort(price_brutto)}</>
                )}
              </span>
            </div>
          </div>
        </td>
        <ReceptionBookingCheckInStepImprovementsActionColumns
          booking={booking}
          setError={setError}
          onPriceUpdate={handlePriceUpdate}
          improvement={improvement}
          improvementPrice={improvementPrice}
        />
      </tr>
      {error && <ReceptionBookingCheckInStepImprovementError calculationError={error} />}
    </FormProvider>
  )
}
