import * as React from 'react'
import { Label, Row } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { SubmitFilter } from '@modules/reception/common/filters/submit-filter'
import DateRangeInput from '@components/date/date-range-input'
import Col from 'reactstrap/lib/Col'
import { PaymentsReportFiltersParams } from '@modules/reports/models'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { FormInput } from '@hyper/forms'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { useAppData } from '@components/hooks/use-app-data'
import { Form } from '@hyper/forms/form'

interface PaymentsReportFiltersProps {
  filters: PaymentsReportFiltersParams
  setFilters: (filters: PaymentsReportFiltersParams) => void
  defaultFilters: PaymentsReportFiltersParams
}

export const PaymentsReportFilters: React.FC<PaymentsReportFiltersProps> = ({
  setFilters,
  filters,
  defaultFilters,
}) => {
  const methods = useForm<PaymentsReportFiltersParams>({
    defaultValues: filters,
  })

  const { booking_sources: sources } = useAppData()

  const user = useAuthenticatedUser()

  const handleReset = () => {
    methods.reset()
    setFilters(defaultFilters)
  }

  const onSubmit = async (payload: PaymentsReportFiltersParams) => setFilters({ ...filters, ...payload })

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  return (
    <Form methods={methods} onSubmit={onSubmit} className="form form-style-filter mb-2">
      <Row>
        <Col className="col-auto" style={{ minWidth: 320 }}>
          <div className="d-flex">
            <Label className="mr-1">Data</Label>
          </div>

          <DateRangeInput
            isClearable={true}
            wrapperClassName="flex-grow-1"
            inputGroupClassName="form-group"
            startDateName="created_at_after"
            endDateName="created_at_before"
          />
        </Col>

        <FormInput label="Ośrodek" colSize="col-auto" name="booking__resort" type="select">
          <option value="">Wszystkie</option>
          {user.resorts.map(resort => (
            <option value={resort.id} key={resort.id}>
              {resort.name}
            </option>
          ))}
        </FormInput>
        <FormInput label="Źródło" colSize="col-auto" name="source" type="select">
          <option value="">Wszystkie</option>
          {sources.map(source => (
            <option value={source.id} key={source.id}>
              {source.name}
            </option>
          ))}
        </FormInput>

        <SubmitFilter handleReset={handleReset} />
        <SearchFilter />
      </Row>
    </Form>
  )
}
