import { ReceptionBookingDetails } from '@models/reception'
import * as React from 'react'

export interface PaymentSummaryProps {
  reservation: ReceptionBookingDetails
}

const PaymentSummary = ({ reservation }: PaymentSummaryProps) => (
  <>
    <h5>Unikalny link do płatności:</h5>
    <div className="my-2 text-strong">
      <a target="_blank" href={reservation.urls.client_summary_url}>
        {reservation.urls.client_summary_url}
      </a>
    </div>
  </>
)

export default PaymentSummary
