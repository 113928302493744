import * as React from 'react'
import { SubscriptionDetails, SubscriptionTouristVoucher } from '@modules/subscription/models'
import Table from '@components/table/table'
import { useApiRequest } from '@components/hooks/use-api-request'
import { updateSubscriptionDetails } from '@store/slices/subscription-slice'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAppDispatch } from '@store/index'
import { useModal } from '@components/modals/use-modal'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'

interface Props {
  touristVoucher: SubscriptionTouristVoucher
  subscriptionDetails: SubscriptionDetails
}

export const SubscriptionDetailsTouristVouchersRowActions: React.FC<Props> = ({
  touristVoucher,
  subscriptionDetails,
}) => {
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading, action: handleAccept } = useApiRequest(async () => {
    dispatch(updateSubscriptionDetails(await commonObjectDelete<SubscriptionDetails>(touristVoucher.urls.details)))
    addSuccessMessage('Sukces', `Bon turystyczny o kodzie ${touristVoucher.code} zostął skasowany!`)
  })

  const [handleEdit] = useModal('SubscriptionTouristVoucherModal', {
    subscriptionDetails,
    touristVoucher,
  })

  return (
    <Table.Cell className="text-center">
      <i className="uil-pen ml-1 font-14 cursor-pointer" onClick={handleEdit} />
      <CommonObjectConfirmAction handleAccept={handleAccept} isLoading={isLoading}>
        <i className="uil-trash-alt ml-1 font-14 cursor-pointer" />
      </CommonObjectConfirmAction>
    </Table.Cell>
  )
}
