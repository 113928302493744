import * as React from 'react'
import { ReportTask } from '@models/reports'
import classNames from 'classnames'
import Table from '@components/table/table'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { UserReportsTasksTableRowActions } from '@modules/reports/user-reports/user-reports-tasks-table-row/actions'

interface Props {
  reportTask: ReportTask
  isSuperUser: boolean
}

export const UserReportsTasksTableRow: React.FC<Props> = ({ reportTask, isSuperUser }) => (
  <Table.Row>
    <Table.Cell>{toDefaultDateTimeFormat(reportTask.created)}</Table.Cell>
    <Table.Cell>{reportTask.kind_display}</Table.Cell>
    {isSuperUser && <Table.Cell>{reportTask.created_by}</Table.Cell>}
    <Table.Cell>
      <span
        className={classNames('badge font-12 mt-0 mb-0', {
          'badge-info-lighten': reportTask.status === 'waiting',
          'badge-success-lighten': reportTask.status === 'completed',
          'badge-warning-lighten': reportTask.status === 'processing',
          'badge-danger-lighten': reportTask.status === 'error',
        })}
      >
        {reportTask.status_display}
      </span>
    </Table.Cell>
    <UserReportsTasksTableRows reportTask={reportTask} />
    <Table.Cell className="font-10">{reportTask.recipients.join(', ')}</Table.Cell>
    <UserReportsTasksTableRowActions reportTask={reportTask} />
  </Table.Row>
)

interface UserReportsTasksTableRowsProps {
  reportTask: ReportTask
}

const UserReportsTasksTableRows: React.FC<UserReportsTasksTableRowsProps> = ({ reportTask }) => {
  if (reportTask.rows && reportTask.status !== 'completed') {
    return (
      <Table.Cell className="text-nowrap">
        {reportTask.rows} z {reportTask.total_rows || reportTask.rows}
      </Table.Cell>
    )
  }

  return <Table.Cell className="text-nowrap">{reportTask.total_rows || reportTask.rows}</Table.Cell>
}
