import * as React from 'react'
import { ModalBody } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import { useEservicePinPadsList } from '@modules/reception/common/eservice-pin-pad-select/use-eservice-pin-pads-list'

interface Props extends BaseModalProps {
  handlePayment: () => void
  pin_pad_id: number
}

export const ReceptionPinPadPreauthorizationInfoModal: React.FC<Props> = ({
  handlePayment,
  pin_pad_id,
  toggleIsVisible,
}: Props) => {
  const eServicePinPad = useEservicePinPadsList().find(row => row.id === pin_pad_id)

  const handleClick = () => {
    handlePayment()
    toggleIsVisible()
  }

  return (
    <ModalBody className="modal-colored-header bg-info text-center">
      <i className="uil-money-withdraw h1" />
      <h4 className="mt-2">Uwaga!</h4>
      <p className="mt-3">
        Transakcja konieczna do wykonania na terminalu {eServicePinPad?.name}. Potwierdź w celu wywołania płatności.
      </p>
      <button type="button" className="btn btn-light my-2" data-dismiss="modal" onClick={handleClick}>
        Rozumiem
      </button>
    </ModalBody>
  )
}
