import * as React from 'react'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap'
import { Direction } from 'reactstrap/es/Dropdown'
import { IconWithText } from '@components/icon-with-text'

interface DownloadButtonsProps {
  exportPdf?: string
  exportXls?: string
  disabled?: boolean
  direction?: Direction
  className?: string
}

export const DownloadButtons: React.FC<DownloadButtonsProps> = ({
  disabled,
  exportXls,
  exportPdf,
  direction = 'up',
  className,
}) => (
  <UncontrolledButtonDropdown direction={direction} group={false} disabled={disabled} className={className}>
    <DropdownToggle color="secondary" caret={true} className="text-semi-strong" disabled={disabled}>
      <IconWithText icon="uil-download-alt mr-1" text="Pobierz" />
    </DropdownToggle>
    <DropdownMenu>
      {exportXls && (
        <DropdownItem target="_blank" disabled={disabled} href={exportXls}>
          Pobierz XLS
        </DropdownItem>
      )}

      {exportPdf && (
        <DropdownItem target="_blank" disabled={disabled} href={exportPdf}>
          Pobierz PDF
        </DropdownItem>
      )}
    </DropdownMenu>
  </UncontrolledButtonDropdown>
)
