import * as React from 'react'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { FormProvider, useForm } from 'react-hook-form'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useFormRequest } from '@components/hooks/use-api-request'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { CmsAssetModalForm } from '@modules/cms/assets/list/modal/form'
import { createAsset, updateAsset } from '@api/cms'
import { BaseModalProps } from '@components/modals/types'
import { Asset } from '@models/cms-asset'
import { updateAssetDetails } from '@store/slices/assets-slice'

interface Props extends BaseModalProps {
  asset?: Asset
}

export interface CmsAssetFormInputs {
  name: string
  key: string
  category: number
}

export const CmsAssetModal: React.FC<Props> = ({ toggleIsVisible, asset }) => {
  const [file, setFile] = React.useState<File>()

  const url = useAppSelector((state: RootState) => state.appState.appData.urls.cms.assets)
  const methods = useForm<CmsAssetFormInputs>({ defaultValues: { ...asset } })
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: CmsAssetFormInputs) => {
    dispatch(
      updateAssetDetails(
        asset ? await updateAsset(asset.urls.details, payload, file) : await createAsset(url, payload, file),
      ),
    )
    addSuccessMessage('Sukces', asset ? 'Asset został zmieniony' : 'Asset został dodany')
    toggleIsVisible()
  }, methods.setError)

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggleIsVisible}>{asset ? 'Edytuj asset' : 'Utwórz asset'}</ModalHeader>
        <ModalBody>
          <CmsAssetModalForm asset={asset} upload={setFile} file={file} isLoading={isLoading} />
        </ModalBody>
        <ModalFooter>
          <Button color="light" type="button" onClick={toggleIsVisible}>
            Zamknij
          </Button>
          <SaveButton className="btn btn-green" isSaving={isLoading} />
        </ModalFooter>
      </form>
    </FormProvider>
  )
}
