import * as React from 'react'
import Table from '@components/table/table'
import { toDefaultDateFormat, toDefaultDateTimeFormat } from '@helpers/date-helper'
import { ImprovementOrder } from '@modules/housekeeping/models'
import { HousekeepingCleaningOrderOrderedByRow } from '@modules/housekeeping/cleaning-orders/common/ordered-by'
import { HousekeepingOrderWorkersRow } from '@modules/housekeeping/common/workers'
import { getImprovementOrderStatusColor } from '@modules/housekeeping/helpers'
import classNames from 'classnames'

interface Props {
  improvementOrder: ImprovementOrder
}

export const UnitDetailsImprovementOrdersTableRow: React.FC<Props> = ({ improvementOrder }) => (
  <Table.Row>
    <Table.Cell className="vertical-align-middle">{toDefaultDateFormat(improvementOrder.date)}</Table.Cell>
    <Table.Cell className="vertical-align-middle">
      <span className={classNames('badge', `badge-${getImprovementOrderStatusColor(improvementOrder.status)}`)}>
        {improvementOrder.status_display}
      </span>
    </Table.Cell>
    <HousekeepingCleaningOrderOrderedByRow ordered_by={improvementOrder.order_created_by} />
    <HousekeepingOrderWorkersRow order={improvementOrder} />
    <Table.Cell className="vertical-align-middle">
      {improvementOrder.finished_at && toDefaultDateTimeFormat(improvementOrder.finished_at)}
    </Table.Cell>
  </Table.Row>
)
