import * as React from 'react'
import { ColAuto } from '@hyper/col-auto'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useSaleAppData } from '@modules/sale/hooks/use-sale-app-data'
import { useAppData } from '@components/hooks/use-app-data'
import { FormSelect } from '@hyper/forms/form-select'
import { PaymentRegisterTypesOptions } from '@modules/payment-registers/consts'

export const PaymentRegisterFilters = (): JSX.Element => {
  const { payment_register_kinds } = useSaleAppData()
  const paymentRegisterKinds = payment_register_kinds.map(([value, label]) => ({ value, label }))
  const { payment_sources } = useAppData()

  const sourceOptions: CustomReactSelectOption[] = payment_sources.map(row => ({
    value: row.id,
    label: row.name,
  }))
  sourceOptions.push({
    value: 'tickets',
    label: 'Tickety',
  })

  return (
    <>
      <ColAuto>
        <FormSelect
          options={PaymentRegisterTypesOptions}
          name="type"
          label="Typ płatności"
          selectProps={{ isClearable: true }}
          formPlainProps={{ colStyle: { padding: 0, width: 200 } }}
        />
      </ColAuto>
      <ColAuto>
        <FormSelect
          options={paymentRegisterKinds}
          name="kind"
          label="Typ operacji"
          selectProps={{ isClearable: true }}
          formPlainProps={{ colStyle: { padding: 0, width: 200 } }}
        />
      </ColAuto>

      <ColAuto>
        <FormSelect
          options={sourceOptions}
          name="source"
          label="Metoda płatności"
          selectProps={{ isClearable: true }}
          formPlainProps={{ colStyle: { padding: 0, width: 200 } }}
        />
      </ColAuto>
    </>
  )
}
