import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { IconWithText } from '@components/icon-with-text'
import { ReservationDataRow } from '@modules/reservations/details/common/reservation-data-row'
import { CopyToClipboardTooltip } from '@components/copy-to-clipboard-tooltip'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useNotificationHook } from '@hyper/use-notification-hook'

interface Props {
  booking: ReceptionBookingDetails
}

export const ReservationDataSummaryUrl = ({ booking }: Props): JSX.Element => {
  const { addSuccessNotification } = useNotificationHook()
  const shorterBookingSummaryUrl = React.useMemo(
    () => booking.urls.client_summary_url.split('/').slice(-3).join('/'),
    [booking.urls.client_summary_url],
  )

  const { action: resendBookingSummary, isLoading } = useApiRequest(async () => {
    // FIXME provide correct url
    await commonObjectPost(booking.urls.client_summary_url)
    addSuccessNotification('Podsumowanie rezerwacji zostało wysłane.')
  })

  return (
    <ReservationDataRow
      name={<IconWithText icon="uil-link-h font-15 lh-0" text="Strona rezerwacji:" />}
      className="border-bottom pb-2 mr-3 align-items-center"
    >
      <div className="d-flex align-items-center justify-content-between">
        <div className="p-2 d-flex align-items-center justify-content-between reservation-details__summary-page-link">
          <CopyToClipboardTooltip
            className="px-0 reservation-details__summary-page-link__copy-button"
            value={booking.urls.client_summary_url}
            tooltipId={`booking_summary_${booking.id}`}
          >
            <div className="text-truncate reservation-details__summary-page-link__url">{shorterBookingSummaryUrl}</div>
          </CopyToClipboardTooltip>

          <a href={booking.urls.client_summary_url} target="_blank" className="text-default">
            <i className="uil-external-link-alt font-16" />
          </a>
        </div>
        <div className="pr-0 text-right">
          <IconWithText
            onClick={resendBookingSummary}
            isLoading={isLoading}
            loadingText="Wysyłanie..."
            icon="uil-redo"
            text="Wyślij ponownie"
            wrapperClassNames="text-secondary ml-2"
            textClass="font-11 text-nowrap"
          />
        </div>
      </div>
    </ReservationDataRow>
  )
}
