import * as React from 'react'
import { NotificationEvents } from '@models/dashboard'
import { useAppDispatch } from '@store/index'
import { useWebSocket } from '@components/hooks/use-web-socket'
import { PaymentDayReportDetails } from '@models/payments'
import { updatePaymentDayReport } from '@store/slices/cash-slice'

interface Props {
  resortId: number
}

export const CashboxWebSocketHandler: React.FC<Props> = ({ resortId }) => {
  const dispatch = useAppDispatch()

  useWebSocket<PaymentDayReportDetails>(
    (message, payload) => {
      switch (message) {
        case NotificationEvents.PAYMENT_REPORT_DAY_CHANGE:
          if (payload.resort_id === resortId) {
            dispatch(updatePaymentDayReport(payload))
          }

          break
      }
    },
    [],
    [NotificationEvents.PAYMENT_REPORT_DAY_CHANGE],
  )
  return null
}
