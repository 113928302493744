import { InputType } from 'reactstrap/lib/Input'
import * as React from 'react'
import { Col, FormGroup, Input, InputProps, Label, LabelProps } from 'reactstrap'
import classNames from 'classnames'
import { extractInnerRef, setCurrencyFieldModifiers, setPatternFieldModifier } from '@helpers/forms'
import { ColumnProps } from 'reactstrap/es/Col'
import { RegisterOptions } from 'react-hook-form/dist/types/validator'
import * as R from 'ramda'
import { FieldError } from 'react-hook-form'
import { useNewFormContext } from '@hyper/forms/hooks/use-new-form-context'

type FormInputType = 'currency' | InputType

export interface FormInputProps {
  afterInput?: React.ReactNode
  autoComplete?: string
  children?: React.ReactNode
  colClassName?: string
  colSize?: ColumnProps
  colStyle?: React.CSSProperties
  dataTestId?: string
  disabled?: boolean
  errors?: any
  formGroupClassName?: string
  inputClassName?: string
  inputId?: string
  inputProps?: InputProps
  label?: React.ReactNode
  labelProps?: LabelProps
  inputPattern?: RegExp
  max?: number
  min?: number
  multiple?: boolean
  name: string
  onKeyDown?: any
  placeholder?: string
  placeholderIcon?: string
  readOnly?: boolean
  register?: any
  registerParams?: RegisterOptions
  role?: string
  setValue?: (name: string, data: any) => void
  step?: number | string
  tabIndex?: number
  type?: FormInputType
  hideErrors?: boolean
  labelSize?: number
}

export const FormInput: React.FC<FormInputProps> = ({
  registerParams,
  autoComplete,
  multiple,
  disabled,
  onKeyDown,
  name,
  children,
  register,
  inputId,
  label,
  step,
  readOnly,
  errors,
  dataTestId,
  colSize,
  colStyle,
  min,
  max,
  tabIndex,
  type,
  role,
  placeholder,
  afterInput,
  inputProps,
  formGroupClassName,
  labelProps,
  inputClassName,
  colClassName,
  setValue,
  placeholderIcon,
  inputPattern,
  hideErrors,
  labelSize,
}) => {
  const methods = useNewFormContext()

  if (methods) {
    register = methods.register
    setValue = methods.setValue
    errors = methods.formState.errors
  }

  const error = R.path(name.split('.'), errors)

  return (
    <FormPlain
      formGroupClassName={classNames(formGroupClassName, { 'form-input-with-icon__wrapper': !!placeholderIcon })}
      colStyle={colStyle}
      errors={errors}
      name={name}
      colSize={colSize}
      colClassName={colClassName}
      label={label}
      labelProps={labelProps}
      inputId={inputId}
      hideErrors={hideErrors}
      labelSize={labelSize}
    >
      {placeholderIcon && <i className={classNames('form-input-with-icon__icon', placeholderIcon)} />}
      <Input
        data-testid={dataTestId}
        role={role}
        id={inputId || name}
        tabIndex={tabIndex}
        step={step}
        autoComplete={autoComplete}
        multiple={multiple}
        onKeyDown={onKeyDown}
        type={type || 'text'}
        readOnly={readOnly}
        placeholder={placeholder || ''}
        name={name}
        min={min}
        max={max}
        disabled={disabled}
        className={classNames(
          { 'is-invalid': !!error, 'form-input-with-icon__input': !!placeholderIcon, disabled: disabled },
          `form__input--${type || 'text'}`,
          inputClassName,
        )}
        {...inputProps}
        {...extractInnerRef(
          register(name, {
            ...registerParams,
            ...(type === 'currency' && setCurrencyFieldModifiers(name, setValue, registerParams)),
            ...(inputPattern && setPatternFieldModifier(setValue, inputPattern)),
          }),
        )}
      >
        {children}
      </Input>
      {afterInput}
    </FormPlain>
  )
}

export interface FormPlainProps {
  hideErrors?: boolean
  labelProps?: LabelProps
  label?: React.ReactNode
  inputId?: string
  name: string
  errors?: any
  colSize?: ColumnProps | 'col-auto'
  children?: React.ReactNode
  colStyle?: React.CSSProperties
  colClassName?: string
  formGroupClassName?: string
  testId?: string
  labelSize?: number
}

export const FormPlain: React.FC<FormPlainProps> = ({
  name,
  children,
  errors,
  colSize,
  colStyle,
  colClassName,
  formGroupClassName,
  label,
  labelProps,
  inputId,
  hideErrors,
  labelSize,
}) => {
  const methods = useNewFormContext()
  const error = R.path(name.split('.'), methods ? methods.formState.errors : errors)

  colSize = colSize !== 'col-auto' ? colSize || 12 : colClassName

  return (
    <Col md={colSize} style={colStyle || {}} className={colClassName}>
      <FormGroup
        row={!!labelSize}
        className={classNames(
          {
            'text-danger': !!error,
          },
          formGroupClassName,
        )}
      >
        {label && (
          <Label {...labelProps} for={inputId || name} sm={labelSize}>
            {label}
          </Label>
        )}
        {labelSize ? (
          <Col sm={12 - labelSize}>
            {children}

            {!hideErrors && <FormPlainError errors={errors} name={name} />}
          </Col>
        ) : (
          <>
            {children}
            {!hideErrors && <FormPlainError errors={errors} name={name} />}
          </>
        )}
      </FormGroup>
    </Col>
  )
}

interface FormPlainErrorProps {
  name: string
  errors?: any
  className?: string
}

export const FormPlainError: React.FC<FormPlainErrorProps> = ({ name, errors, className }) => {
  const methods = useNewFormContext()

  const formErrors = methods ? methods.formState.errors : errors

  const error = R.path<FieldError>(name.split('.'), formErrors)

  if (error) {
    return <div className={classNames('invalid-feedback d-block', className)}>{error.message}</div>
  }

  return null
}
