import * as React from 'react'
import { CommonTask } from '@modules/housekeeping/models'
import { HousekeepingOrdersRowTotalTimeStarted } from '@modules/housekeeping/common/row-total-time-started'

interface Props {
  commonTask: CommonTask
}

export const HousekeepingCommonTaskArchiveRow: React.FC<Props> = ({ commonTask }) => (
  <tr className="housekeeping__orders_table__row">
    <td className="housekeeping__orders_table__column housekeeping__orders_table__column__first">{commonTask.name}</td>
    <td className="housekeeping__orders_table__column">Ulepszenia</td>
    <td className="housekeeping__orders_table__column">{commonTask.status_display}</td>
    <td className="housekeeping__orders_table__column">{commonTask.user}</td>

    <HousekeepingOrdersRowTotalTimeStarted totalTimeStarted={commonTask.total_time_started} />
    <td className="housekeeping__orders_table__column text-nowrap">-</td>
    <td className="housekeeping__orders_table__column text-nowrap">-</td>
    <td className="housekeeping__orders_table__column text-nowrap">-</td>
    <td className="housekeeping__orders_table__column text-nowrap">-</td>
  </tr>
)
