import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { BandScanningSubmit } from '@components/band-scanning/band-scanning-submit'
import { BandScanningStatus } from '@components/band-scanning/models'
import { ReceptionBookingDetails } from '@models/reception'
import { useBandScanningSubmitProgressText } from '@components/band-scanning/use-band-scanning-submit-progress-text'
import { BandScanningRescanAction } from '@components/band-scanning/band-scanning-rescan-action'

interface Props {
  isScanning: boolean
  onScan: () => void
  onRescan: () => void
  onCancel: () => void
  canRescan: boolean
  globalScanningStatus: BandScanningStatus
  nextScanDate: Date | null
  bookingDetails: ReceptionBookingDetails
  hasBandToScan: boolean
}

export const BandScanningActions = ({
  globalScanningStatus,
  isScanning,
  onScan,
  onRescan,
  canRescan,
  onCancel,
  nextScanDate,
  bookingDetails,
  hasBandToScan,
}: Props): JSX.Element => {
  const { text: submitText, isWithProgress: isNextScanningProgressVisible } = useBandScanningSubmitProgressText(
    nextScanDate,
    isScanning,
  )

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <BandScanningSubmit
          globalScanningStatus={globalScanningStatus}
          isScanning={isScanning}
          onScan={onScan}
          nextScanDate={nextScanDate}
          pulse={hasBandToScan}
          progressText={submitText}
          isWithProgress={isNextScanningProgressVisible}
          disabled={isScanning || !hasBandToScan}
        />
        {isScanning && (
          <IconWithText
            icon="uil-times text-danger font-18 lh-0"
            text="Przerwij"
            textClass="text-danger fw-semi-bold font-11"
            onClick={onCancel}
          />
        )}
      </div>
      {canRescan && !isNextScanningProgressVisible && (
        <BandScanningRescanAction onRescan={onRescan} bookingDetails={bookingDetails} />
      )}
    </div>
  )
}
