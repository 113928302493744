import * as React from 'react'
import { SpinInput } from '@components/spin-input'
import { FormCheckbox } from '@hyper/forms/form-checkbox'
import { useFormContext, useWatch } from 'react-hook-form'

interface Props {
  optionCheckboxName: string
  optionAmountName: string
  title: string
}

export const BookingOptionCreateOptionRow = ({ optionCheckboxName, title, optionAmountName }: Props): JSX.Element => {
  const { control } = useFormContext()

  const isOptionSelected = useWatch({ control, name: optionCheckboxName })

  return (
    <div className="d-flex align-items-center mb-1">
      <FormCheckbox name={optionCheckboxName} label={title} className="col-7" />
      <SpinInput
        inputName={optionAmountName}
        inputProps={{
          min: 1,
          max: 100,
          disabled: !isOptionSelected,
          style: { width: 70 },
        }}
      />
    </div>
  )
}
