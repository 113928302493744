import * as React from 'react'
import { ShopPurchaseAddTicketInputRow } from '@modules/shop/pos/purchase-summary/tickets/shop-purchase-add-ticket-input-row'
import { ShopPurchaseTicketsSummaryRow } from '@modules/shop/pos/purchase-summary/tickets/shop-purchase-tickets-summary-row'
import { ShopPurchaseAddTicketButtonRow } from '@modules/shop/pos/purchase-summary/tickets/shop-purchase-add-ticket-button-row'
import { useFormContext, useWatch } from 'react-hook-form'
import { asDecimal, formatPriceShort, sumDecimalArray } from '@helpers/utils'
import { ShopProduct } from '@models/shop'
import Decimal from 'decimal.js'
import { ShopPOSWithParamsFormInputs } from '@modules/shop/pos/shop-pos-with-params'
import { EnhancedShopProduct } from '@modules/shop/pos/models'

interface ShopPurchaseTicketRowProps {
  ticketFactor: Decimal
  addedProducts: EnhancedShopProduct[]
  removeTicket: (number) => void
  addTicket: ({ value, id }: { value: number; id: string }) => void
}

export const ShopPurchaseTicketRow: React.FC<ShopPurchaseTicketRowProps> = ({
  removeTicket,
  addTicket,
  ticketFactor,
  addedProducts,
}) => {
  const [addingTicketModeEnabled, setAddingTicketModeEnabled] = React.useState(false)

  const { control } = useFormContext<ShopPOSWithParamsFormInputs>()
  const addedTickets = useWatch({ control, name: 'addedTickets' })

  const toggleTicketMode = () => setAddingTicketModeEnabled(state => !state)

  const basketValue = sumDecimalArray(
    addedProducts.map((shopProduct: ShopProduct) => asDecimal(shopProduct.price_brutto)),
  ).toString()

  return (
    <>
      {!!addedTickets?.length && (
        <>
          <tr className="border bg-white-smoke">
            <td colSpan={2} className="text-dark font-weight-bold font-14">
              Wartość koszyka
            </td>
            <td className="text-dark font-weight-bold font-14">{formatPriceShort(basketValue)}</td>
            <td colSpan={3} />
          </tr>
          <ShopPurchaseTicketsSummaryRow tickets={addedTickets} remove={removeTicket} ticketFactor={ticketFactor} />
        </>
      )}
      {addingTicketModeEnabled ? (
        <ShopPurchaseAddTicketInputRow append={addTicket} onCancel={toggleTicketMode} />
      ) : (
        <ShopPurchaseAddTicketButtonRow onClick={toggleTicketMode} />
      )}
    </>
  )
}
