import * as React from 'react'
import { NavigationTabs, NavigationTabsButton } from '@components/navigation-tabs'
import { ScrollableModalTab, useModalWithScrollableTabs } from '@components/hooks/use-modal-with-scrollable-tabs'

type Tab = 'information' | 'payments' | 'codes' | 'notes' | 'history'

interface Props {
  informationRef: React.RefObject<HTMLElement>
  paymentsRef: React.RefObject<HTMLElement>
  codesRef: React.RefObject<HTMLElement>
  notesRef: React.RefObject<HTMLElement>
}

export const PackageDetailsTabs: React.FC<Props> = ({ informationRef, paymentsRef, codesRef, notesRef }) => {
  const [tab, setTab] = React.useState<Tab>('information')

  const tabs: ScrollableModalTab<Tab>[] = [
    { ref: informationRef, name: 'information' },
    { ref: paymentsRef, name: 'payments' },
    { ref: codesRef, name: 'codes' },
    { ref: notesRef, name: 'notes' },
    { ref: notesRef, name: 'history' },
  ]

  const { handleTabClick } = useModalWithScrollableTabs<Tab>({ modalId: 'package-modal', setTab, tabs })

  const buttons: NavigationTabsButton[] = [
    {
      title: 'Dane podstawowe',
      isActive: tab == 'information',
      onClick: handleTabClick('information'),
    },
    {
      title: 'Płatności',
      isActive: tab == 'payments',
      onClick: handleTabClick('payments'),
    },
    {
      title: 'Kody',
      isActive: tab == 'codes',
      onClick: handleTabClick('codes'),
    },
    {
      title: 'Notatki',
      isActive: tab == 'notes',
      onClick: handleTabClick('notes'),
    },
    {
      title: 'Historia',
      isActive: tab == 'history',
      onClick: handleTabClick('history'),
    },
  ]

  return <NavigationTabs buttons={buttons} />
}
