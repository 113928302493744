import * as React from 'react'
import { addSeconds, differenceInSeconds } from 'date-fns'

let intervalId: NodeJS.Timeout

interface Response {
  isWithProgress: boolean
  text: string
}

export const useBandScanningSubmitProgressText = (nextScanDate: Date | null, isScanning: boolean): Response => {
  const [text, setText] = React.useState('')

  const isWithProgress = !!nextScanDate && !isScanning

  React.useEffect(() => {
    if (!nextScanDate) {
      clearInterval(intervalId)
      setText('')
      return
    }

    intervalId = setInterval(() => {
      setText(`Kodowanie kolejnej opaski ${differenceInSeconds(addSeconds(nextScanDate, 1)!, new Date())}s`)
    })

    return () => clearInterval(intervalId)
  }, [nextScanDate])

  return {
    isWithProgress,
    text,
  }
}
