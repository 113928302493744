import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { ReservationListType } from '@modules/reservations/list/models'

export const ReservationListView: React.FC = () => {
  const navigate = useNavigate()

  React.useEffect(() => {
    navigate(NavigationPath.ReservationListWithParams.replace(':type', ReservationListType.Present))
  }, [])

  return null
}
