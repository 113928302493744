import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useToggle } from '@components/hooks/use-toggle'
import { IconWithText } from '@components/icon-with-text'
import { FormInputTime } from '@hyper/forms/form-input-time'
import { FormPlainError } from '@hyper/forms'

export const ReceptionBookingCheckInStepAccessBandsHourFrom = (): JSX.Element => {
  const [edit, toggleEdit] = useToggle()

  const { control } = useFormContext()

  const hourFrom = useWatch({ control, name: 'hour_from' })

  return (
    <div className="mb-4 d-flex align-items-center flex-wrap">
      <span className="mr-1">Ustaw dostępność opasek od:</span>
      {edit ? (
        <>
          <FormInputTime
            name="hour_from"
            colClassName="my-0 px-0"
            formGroupClassName="mb-0"
            colStyle={{ maxWidth: 65 }}
            labelProps={{ className: 'd-none' }}
            hideErrors={true}
          />
          <i className="uil-check cursor-pointer text-muted font-16 ml-1" onClick={toggleEdit} />
        </>
      ) : (
        <IconWithText
          text={hourFrom}
          textClass="fw-bold"
          icon="uil-pen cursor-pointer text-muted pl-1"
          iconSize="font-13"
          onClick={toggleEdit}
          direction="right"
        />
      )}
      <FormPlainError name="hour_from" className="" />
    </div>
  )
}
