import { CustomReactSelectOption } from '@components/custom-react-select'
import { ACCOMMODATION_TYPES } from '@helpers/consts'
import { BaseHousekeepingOrder } from '@modules/housekeeping/models'
import { groupByField } from '@helpers/utils'

interface Filters {
  service_kind: CustomReactSelectOption | null
  company: CustomReactSelectOption | null
  local: CustomReactSelectOption | null
  kind: CustomReactSelectOption | null
  is_vip: boolean
  early_check_in: boolean
  extend_stay: boolean
  collision_locals: boolean
}

export const useHousekeepingTableFilters = () => {
  const omitVip = (order: BaseHousekeepingOrder) => order.kind === 'DEPARTURE'

  const getFiltered = <T extends BaseHousekeepingOrder>(
    cleaningOrders: T[],
    filters: Partial<Filters>,
    nextFilter?: (cleaningOrder: T) => boolean,
  ) => {
    const EARLY_CHECK_IN_TIME = '13:00'

    const hasCollisionLocals = (cleaningOrder: T) =>
      cleaningOrder.extend_stay_time && cleaningOrder.next_booking?.arrival_time === EARLY_CHECK_IN_TIME

    return [...cleaningOrders].filter(
      (cleaningOrder: T) =>
        (!filters.service_kind || cleaningOrder?.service_kind === filters.service_kind?.value) &&
        (!filters.company || cleaningOrder.company_id === filters.company?.value) &&
        (!filters.kind || cleaningOrder.kind === filters.kind?.value) &&
        (!filters.service_kind || cleaningOrder.service_kind === filters.service_kind?.value) &&
        (!filters.local ||
          (filters.local.value === 'apartment' &&
            ACCOMMODATION_TYPES.APARTMENTS.includes(cleaningOrder.apartment.accommodation_type)) ||
          (filters.local.value === 'house' &&
            ACCOMMODATION_TYPES.HOUSES.includes(cleaningOrder.apartment.accommodation_type))) &&
        (!filters.is_vip || (!omitVip(cleaningOrder) && cleaningOrder.next_booking?.has_package_vip)) &&
        (!filters.extend_stay || !!cleaningOrder.extend_stay_time) &&
        (!filters.early_check_in || !!cleaningOrder.early_check_in_time) &&
        (!filters.collision_locals || hasCollisionLocals(cleaningOrder)) &&
        (!nextFilter || !nextFilter(cleaningOrder)),
    )
  }

  const getSorted = <T extends BaseHousekeepingOrder>(orders: T[]) => {
    const { reopenedOrders, restOrders } = orders.reduce(
      (prev, order) =>
        'is_reopened' in order && order.is_reopened
          ? { ...prev, reopenedOrders: [...prev.reopenedOrders, order] }
          : { ...prev, restOrders: [...prev.restOrders, order] },
      { reopenedOrders: [], restOrders: [] },
    )

    const { ARRIVAL = [], DEPARTURE = [], DEPARTURE_ARRIVAL = [] } = groupByField(restOrders, 'kind')

    const sortByLocalName = (a: T, b: T) => a.apartment.name.localeCompare(b.apartment.name, 'pl', { numeric: true })

    const getOrdersByAccommodationType = (orders: T[]) =>
      orders.reduce(
        (prev, order) => {
          if (ACCOMMODATION_TYPES.HOUSES.includes(order.apartment.accommodation_type)) {
            return { ...prev, houses: [...prev.houses, order] }
          }
          if (ACCOMMODATION_TYPES.APARTMENTS.includes(order.apartment.accommodation_type)) {
            return { ...prev, apartments: [...prev.apartments, order] }
          }
          return prev
        },
        { houses: [], apartments: [] },
      )

    const sortByDeclaredArrivalTime = (a: T, b: T) => {
      const aArrivalTime = a.next_booking?.declared_arrival_time
      const bArrivalTime = b.next_booking?.declared_arrival_time

      if (aArrivalTime && bArrivalTime) {
        return aArrivalTime.localeCompare(bArrivalTime)
      }

      return 0
    }

    const getSortedKind = (orders: T[]) => {
      const arrivals = orders.reduce(
        (prev, order) => {
          if (order.next_booking?.has_package_vip && !omitVip(order)) {
            return { ...prev, packageVip: [...prev.packageVip, order] }
          }

          if (order.early_check_in_time) {
            return { ...prev, earlyCheckIn: [...prev.earlyCheckIn, order] }
          }

          return { ...prev, rest: [...prev.rest, order] }
        },
        { packageVip: [], earlyCheckIn: [], rest: [] },
      )
      const groupedPackageVipArrivals = getOrdersByAccommodationType(arrivals.packageVip)
      const groupedEarlyCheckInArrivals = getOrdersByAccommodationType(arrivals.earlyCheckIn)
      const groupedRestArrivals = getOrdersByAccommodationType(arrivals.rest)

      return [
        ...groupedPackageVipArrivals.apartments.toSorted(sortByLocalName),
        ...groupedPackageVipArrivals.houses.toSorted(sortByLocalName),
        ...groupedEarlyCheckInArrivals.apartments.toSorted(sortByLocalName),
        ...groupedEarlyCheckInArrivals.houses.toSorted(sortByLocalName),
        ...groupedRestArrivals.apartments.toSorted(sortByLocalName).toSorted(sortByDeclaredArrivalTime),
        ...groupedRestArrivals.houses.toSorted(sortByLocalName).toSorted(sortByDeclaredArrivalTime),
      ]
    }

    return [
      ...reopenedOrders,
      ...getSortedKind(ARRIVAL),
      ...getSortedKind(DEPARTURE_ARRIVAL),
      ...getSortedKind(DEPARTURE),
    ]
  }

  return { getFiltered, getSorted }
}
