import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@store/index'
import { SubscriptionContract, SubscriptionContractDetails } from '@modules/subscription-contract/models'
import {
  createSubscriptionContract,
  getSubscriptionContractDetails,
  getSubscriptionContracts,
  updateSubscriptionContract,
} from '@store/actions/subscription-contract-actions'

interface SubscriptionContractState {
  subscriptionContracts: EntityState<SubscriptionContract>
  subscriptionContractDetails: SubscriptionContractDetails | undefined
}

const subscriptionContractsAdapter = createEntityAdapter<SubscriptionContract>()

const initialState: SubscriptionContractState = {
  subscriptionContracts: subscriptionContractsAdapter.getInitialState(),
  subscriptionContractDetails: undefined,
}

export const subscriptionContractSlice = createSlice({
  name: 'subscriptionContract',
  initialState,
  reducers: {
    updateSubscriptionContractDetails(state, action: PayloadAction<SubscriptionContractDetails>) {
      if (state.subscriptionContractDetails?.id === action.payload.id) {
        state.subscriptionContractDetails = action.payload
      }
      subscriptionContractsAdapter.upsertOne(state.subscriptionContracts, action.payload)
    },
    clearSubscriptionContracts(state) {
      subscriptionContractsAdapter.setAll(state.subscriptionContracts, [])
    },
  },
  extraReducers: builder => {
    builder.addCase(getSubscriptionContracts.fulfilled, (state, action) => {
      subscriptionContractsAdapter.setAll(state.subscriptionContracts, action.payload)
    })
    builder.addCase(createSubscriptionContract.fulfilled, (state, action) => {
      subscriptionContractsAdapter.upsertOne(state.subscriptionContracts, action.payload)
    })
    builder.addCase(updateSubscriptionContract.fulfilled, (state, action) => {
      state.subscriptionContractDetails = action.payload
      subscriptionContractsAdapter.upsertOne(state.subscriptionContracts, action.payload)
    })
    builder.addCase(getSubscriptionContractDetails.fulfilled, (state, action) => {
      state.subscriptionContractDetails = action.payload
      subscriptionContractsAdapter.upsertOne(state.subscriptionContracts, action.payload)
    })
  },
})
export const { updateSubscriptionContractDetails, clearSubscriptionContracts } = subscriptionContractSlice.actions

export const { selectAll: selectSubscriptionContracts } = subscriptionContractsAdapter.getSelectors(
  (state: RootState) => state.subscriptionContractState.subscriptionContracts,
)
export const selectSubscriptionContractDetails = (state: RootState) =>
  state.subscriptionContractState.subscriptionContractDetails

export default subscriptionContractSlice.reducer
