import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import {
  NewFeedingCalculationDetails,
  NewFeedingCalculations,
} from '@modules/reception/checkin/step-feeding/new-feeding/models'
import { RootState } from '@store/index'
import { createNewFeedingInputName } from '@modules/reception/checkin/step-feeding/new-feeding/utils'

interface State {
  exposedEdgeFeedings: EntityState<NewFeedingCalculationDetails>
  feedingCalculations: NewFeedingCalculations | null
  initialFeedingCalculations: NewFeedingCalculations | null
  lastPriceChangeReason: string
  lastPriceRestorationReason: string
  isDiscountPreviewActive: boolean
}

const edgeFeedingsAdapter = createEntityAdapter<NewFeedingCalculationDetails>({
  selectId: entity => createNewFeedingInputName(entity),
})

const initialState: State = {
  exposedEdgeFeedings: edgeFeedingsAdapter.getInitialState(),
  feedingCalculations: null,
  initialFeedingCalculations: null,
  lastPriceChangeReason: '',
  lastPriceRestorationReason: '',
  isDiscountPreviewActive: false,
}

export const newFeedingSlice = createSlice({
  name: 'newFeeding',
  initialState,
  reducers: {
    addExposedEdgeFeeding(state, action: PayloadAction<NewFeedingCalculationDetails>) {
      edgeFeedingsAdapter.upsertOne(state.exposedEdgeFeedings, action.payload)
    },
    removeExposedEdgeFeeding(state, action: PayloadAction<NewFeedingCalculationDetails>) {
      edgeFeedingsAdapter.removeOne(state.exposedEdgeFeedings, createNewFeedingInputName(action.payload))
    },
    clearExposedEdgeFeedings(state) {
      edgeFeedingsAdapter.setAll(state.exposedEdgeFeedings, [])
    },
    setFeedingCalculations(state, action: PayloadAction<NewFeedingCalculations | null>) {
      state.feedingCalculations = action.payload
    },
    setInitialFeedingCalculations(state, action: PayloadAction<NewFeedingCalculations | null>) {
      state.initialFeedingCalculations = action.payload
    },
    setLastPriceChangeReason(state, action: PayloadAction<string>) {
      state.lastPriceChangeReason = action.payload
    },
    setLastPriceRestorationReason(state, action: PayloadAction<string>) {
      state.lastPriceRestorationReason = action.payload
    },
    toggleIsDiscountPreviewActive(state) {
      state.isDiscountPreviewActive = !state.isDiscountPreviewActive
    },
    setIsDiscountPreviewActive(state, action: PayloadAction<boolean>) {
      state.isDiscountPreviewActive = action.payload
    },
  },
})

export const { selectAll: selectExposedEdgeFeedings } = edgeFeedingsAdapter.getSelectors(
  (state: RootState) => state.newFeedingState.exposedEdgeFeedings,
)

export const selectFeedingCalculations = (state: RootState): NewFeedingCalculations | null =>
  state.newFeedingState.feedingCalculations

export const selectInitialFeedingCalculations = (state: RootState): NewFeedingCalculations | null =>
  state.newFeedingState.initialFeedingCalculations

export const selectLastPriceChangeReason = (state: RootState): string => state.newFeedingState.lastPriceChangeReason

export const selectLastPriceRestorationReason = (state: RootState): string =>
  state.newFeedingState.lastPriceRestorationReason

export const selectIsDiscountPreviewActive = (state: RootState): boolean =>
  state.newFeedingState.isDiscountPreviewActive

export const {
  addExposedEdgeFeeding,
  removeExposedEdgeFeeding,
  clearExposedEdgeFeedings,
  setFeedingCalculations,
  setInitialFeedingCalculations,
  setLastPriceChangeReason,
  setLastPriceRestorationReason,
  setIsDiscountPreviewActive,
} = newFeedingSlice.actions

export default newFeedingSlice.reducer
