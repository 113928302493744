import * as React from 'react'
import { ReceptionBookingCartItemsSummary } from '@modules/reception/cart/cart-items/reception-booking-cart-items-summary'
import { ReceptionBookingCartItemsHeader } from '@modules/reception/cart/cart-items/reception-booking-cart-items-header'
import { ReceptionBookingCartFeeding } from '@modules/reception/cart/cart-items/feeding/reception-booking-cart-feeding'
import {
  ReceptionBookingDetails,
  ReceptionBookingDetailsCart,
  ReceptionBookingDetailsCartItem,
  ReceptionBookingDetailsCartItemKind,
} from '@models/reception'
import { createCartItemFormName } from '@modules/reception/cart/utils'
import { useFormContext } from 'react-hook-form'
import { ReceptionBookingCartPayments } from '@modules/reception/cart/cart-items/payments/reception-booking-cart-payments'

interface Props {
  cart: ReceptionBookingDetailsCart
  bookingDetails: ReceptionBookingDetails
}

export const ReceptionBookingCartItems = ({ cart, bookingDetails }: Props): JSX.Element => {
  const { setValue } = useFormContext()

  const { feeding }: Record<ReceptionBookingDetailsCartItemKind, ReceptionBookingDetailsCartItem[]> = cart.items.reduce(
    (prev, curr) => ({ ...prev, [curr.kind]: [...(prev[curr.kind] ?? []), curr] }),
    { feeding: [] },
  )

  React.useEffect(() => {
    feeding.forEach(item => setValue(createCartItemFormName(item), true))
  }, [])

  return (
    <div className="my-2">
      <ul className="list-unstyled px-2 mt-3">
        <ReceptionBookingCartItemsHeader cartItems={cart.items} />
        {!!feeding.length && <ReceptionBookingCartFeeding feeding={feeding} bookingDetails={bookingDetails} />}
        <ReceptionBookingCartPayments payments={cart.payments} />
      </ul>
      <ReceptionBookingCartItemsSummary items={cart.items} />
    </div>
  )
}
