import { DragDropContext, DragDropContextProps, Droppable, DroppableProps } from 'react-beautiful-dnd'
import * as React from 'react'

interface Props {
  dragDropContextProps: Omit<DragDropContextProps, 'children'>
  droppableProps: Omit<DroppableProps, 'children'>
  children: React.ReactNode
}

export const DraggableTableWrapper = ({ dragDropContextProps, droppableProps, children }: Props): JSX.Element => (
  <DragDropContext {...dragDropContextProps}>
    <Droppable {...droppableProps}>
      {provided => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          {children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  </DragDropContext>
)
