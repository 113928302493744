import { UserPermission } from '@models/dashboard'
import { CustomReactSelectOption, makeDefaultSelectOption } from '@components/custom-react-select'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useAppSelector } from '@store/index'
import { resortOptionsSelector } from '@store/selectors/dashboard'
import * as R from 'ramda'
import { parseISODate } from '@helpers/date-helper'
import { useCountries } from '@components/hooks/use-countries'
import { HrWorkerCreateSteps, HrWorkersCreateFormInputs } from '@modules/hr/workers/create/modal'
import { HrWorkerDetails } from '@modules/hr/workers/models'
import { BaseHrFieldDefaults } from '@modules/hr/consts'

export const useHrWorkerCreateWizardDefaults = (workerDetails?: HrWorkerDetails | null) => {
  const user = useAuthenticatedUser()
  const { countriesOptions } = useCountries()

  const allResortOptions = useAppSelector(resortOptionsSelector)

  const resortOptions = user.hasPerm(UserPermission.HrAgreementCanSelectAnyResort)
    ? allResortOptions
    : (user.resorts.map(userResort =>
        allResortOptions.find(resortOption => resortOption.value === userResort.id),
      ) as CustomReactSelectOption[])

  const fields = HrWorkerCreateSteps.reduce((prev, curr) => [...prev, ...(curr.fields ?? [])], [])

  const defaultValues: HrWorkersCreateFormInputs = {
    ...BaseHrFieldDefaults,
    ...(workerDetails && R.pick<Partial<HrWorkersCreateFormInputs>, HrWorkerDetails>(fields, workerDetails)),
    isFormVisible: !!workerDetails,
    account_number: workerDetails?.account_number || 'PL',
    nationality: workerDetails ? makeDefaultSelectOption(countriesOptions, workerDetails.nationality) : null,
    birthday: workerDetails ? parseISODate(workerDetails.birthday) : null,
    resort: workerDetails
      ? resortOptions.find(option => workerDetails.resorts?.includes(option.value))
      : user.resorts.length === 1
        ? resortOptions[0]
        : null,
  }

  return { defaultValues }
}
