import * as React from 'react'
import { AccommodationTypeImage, CmsAccommodationTypeDetails, CmsResortDetails, ResortImage } from '@modules/cms/models'
import { CmsReservationAppImagesEl } from '@modules/cms/reservation-app/common/images/images-el'
import { reOrderArray } from '@helpers/utils'
import { commonObjectPost } from '@store/actions/generic-actions'

interface Props {
  images: AccommodationTypeImage[] | ResortImage[]
  setState: (state: any) => void
  positionUrl: string
}

const CmsReservationAppImagesDnd = (props: Props) => {
  const [images, setImages] = React.useState<AccommodationTypeImage[] | ResortImage[]>([])

  React.useEffect(() => {
    setImages(props.images)
  }, [props.images])

  const moveItem = (sourceId: number, destinationId: number) => {
    setImages(reOrderArray<AccommodationTypeImage | ResortImage>(images, sourceId, destinationId))
  }

  const onDrop = async () =>
    props.setState(
      await commonObjectPost<CmsResortDetails | CmsAccommodationTypeDetails>(
        props.positionUrl,
        Object.fromEntries(images.map((row, index) => [row.id, index])),
      ),
    )

  return (
    <div className="mt-2 d-flex flex-wrap">
      {images.map((image, i) => (
        <CmsReservationAppImagesEl
          setState={props.setState}
          image={image}
          key={image.id}
          onDrop={onDrop}
          index={i}
          moveItem={moveItem}
        />
      ))}
    </div>
  )
}

export default CmsReservationAppImagesDnd
