import * as R from 'ramda'

let timeout
export function createHandleRemoteTableChange(setFilters: any, filters?) {
  return (type, newState) => {
    if (['search', 'pagination', 'sort'].includes(type)) {
      const ordering = newState.sortField
        ? (newState.sortOrder === 'desc' ? '-' : '') + newState.sortField
        : filters.ordering

      const newValues = {
        ...{
          page_size: newState.sizePerPage || filters.page_size,
          page: newState.page || filters.page,
          ordering,
          search: newState.searchText || filters.search,
        },
      }

      if (!R.equals(newValues, filters)) {
        clearTimeout(timeout)
        timeout = setTimeout(() => setFilters(state => ({ ...state, ...newValues })), 200)
      }
    }
  }
}
