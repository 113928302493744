import * as React from 'react'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { getNotificationsSmsTemplatesData } from '@store/actions/cms-actions'
import { SmsTemplate } from '@modules/cms/models'
import { NotificationTemplatesSmsListRow } from '@modules/cms/notification-templates/sms/row'
import { NotificationTemplatesSmsFilters } from '@modules/cms/notification-templates/sms/filters'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { extractSelectOptionsValues } from '@helpers/utils'
import { useTableFilters } from '@components/table/use-table-filters'

const headerValues: TableHeaderValue<SmsTemplate>[] = [
  { title: 'Nazwa', sortField: 'name' },
  { title: 'Klucz', sortField: 'key' },
  { title: 'Grupa' },
  { title: 'Aktywny?', sortField: 'is_active' },
  { title: '' },
]

export interface NotificationTemplatesSmsListTableFilters extends TableFilters {
  is_active: CustomReactSelectOption | null
  group: CustomReactSelectOption | null
}

const defaultFilters: NotificationTemplatesSmsListTableFilters = {
  ordering: '',
  page: 0,
  page_size: 0,
  search: '',
  is_active: null,
  group: null,
}

export const NotificationTemplatesSmsList: React.FC = () => {
  const dispatch = useAppDispatch()

  const smsTemplates = useAppSelector((state: RootState) => state.cmsState.smsTemplates)

  const { isLoading, filters, setFilters } = useTableFilters<NotificationTemplatesSmsListTableFilters>({
    defaultFilters,
    action: async filters => await dispatch(getNotificationsSmsTemplatesData(extractSelectOptionsValues(filters))),
  })

  return (
    <>
      <NotificationTemplatesSmsFilters setFilters={setFilters} filters={filters} defaultFilters={defaultFilters} />
      <Table
        showPagination={false}
        filters={filters}
        setFilters={setFilters}
        headerValues={headerValues}
        isLoading={isLoading}
        cols={['38%', '35%', '13%', '10%', '4%']}
      >
        {smsTemplates.map(row => (
          <NotificationTemplatesSmsListRow key={row.id} smsTemplate={row} />
        ))}
      </Table>
    </>
  )
}
