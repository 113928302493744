import * as React from 'react'
import classNames from 'classnames'

interface RowProps {
  name: React.ReactNode
  children: React.ReactNode | React.ReactNode[]
  suffix?: string
  borderless?: boolean
}

export const SubscriptionDetailsInformationRow: React.FC<RowProps> = ({
  name,
  children,
  suffix = ':',
  borderless = false,
}) => (
  <tr>
    <td className={classNames({ 'border-top-0': borderless })}>
      {name}
      {suffix}
    </td>
    <td className={classNames('text-right align-middle', { 'border-top-0': borderless })}>{children}</td>
  </tr>
)
