import * as React from 'react'

type UseToggleResponse = [boolean, () => void, (value: boolean) => void]

export const useToggle = (defaultValue = false): UseToggleResponse => {
  const [state, setState] = React.useState(defaultValue)
  const toggleState = React.useCallback((event?: React.SyntheticEvent) => {
    event?.stopPropagation()
    setState(state => !state)
  }, [])

  return [state, toggleState, setState]
}
