import * as React from 'react'
import { BookingRefund } from '@modules/accounting/refund/models'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { commonObjectPost } from '@store/actions/generic-actions'
import { Spinner } from 'reactstrap'
import { useApiRequest } from '@components/hooks/use-api-request'
import { updateAccountingBookingRefund } from '@store/slices/accounting-slice'

interface Props {
  booking: BookingRefund
}

export const RefundBookingsTableCellActionsNotification: React.FC<Props> = ({ booking }) => {
  const url = useAppSelector(
    (state: RootState) => state.appState.appData.urls.accounting.bookings_client_data_notification,
  )
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading, action: handleClick } = useApiRequest(
    React.useCallback(
      async (event: React.SyntheticEvent) => {
        event.stopPropagation()
        const bookingRefund = await commonObjectPost<BookingRefund[]>(url, {
          bookings: [booking.id],
        })
        addSuccessMessage('Sukces', `Przypomnienie zostało wysłane do rezerwacji numer ${booking.reservation_number}`)
        dispatch(updateAccountingBookingRefund(bookingRefund[0]))
      },
      [booking],
    ),
  )

  return isLoading ? (
    <Spinner size="sm" className="mr-2" />
  ) : (
    (['waiting_for_client_data', 'missing_client_data'].includes(booking.deposit_transfer_status) && (
      <span
        className="uil uil-clock text-muted font-16 mr-2 cursor-pointer"
        title="Wyślij prośbę o podanie danych"
        onClick={handleClick}
      />
    )) ||
      null
  )
}
