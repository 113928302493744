import * as React from 'react'
import { PageTitle } from '@hyper/page-title'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { objectToUrlString, useDocumentTitle } from '@helpers/utils'
import { RcpUsersTable } from '@modules/rcp/users/rcp-users-table'
import { UsersFilter } from '@modules/rcp/users/users-filter'
import { DownloadButtons } from '@components/downloads-buttons'
import { TableFilters } from '@components/table/table'
import { startOfToday } from 'date-fns'
import { getRcpUsers } from '@store/actions/rcp-actions'
import { useDebounce } from 'rooks'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { rcpUsersSelector } from '@store/slices/rcp-slice'
import { useApiRequest } from '@components/hooks/use-api-request'

export interface UsersFilters extends TableFilters {
  resort: string | undefined
  work_time: string
  season: string
  month: string
  only_active: boolean
  do_filter: boolean
}

const today = startOfToday()

const defaultFilters: UsersFilters = {
  resort: undefined,
  season: String(today.getFullYear()),
  month: String(today.getMonth() + 1),
  work_time: '',
  only_active: true,
  do_filter: false,
  search: '',
  page_size: 30,
  page: 1,
  ordering: '-d',
}

export const UsersView: React.FC = () => {
  useMenuActive(NavigationPath.Rcp)
  const dispatch = useAppDispatch()
  const [filters, setFilters] = React.useState<UsersFilters>(defaultFilters)
  const users = useAppSelector(rcpUsersSelector)
  const exportPdf = useAppSelector((state: RootState) => state.appState.appData.urls.rcp.export_pdf)
  const exportXls = useAppSelector((state: RootState) => state.appState.appData.urls.rcp.export_xls)

  useDocumentTitle(`Lista pracowników (${users.length})`)

  const { isLoading, action: fetchUsers } = useApiRequest(async filters => await dispatch(getRcpUsers(filters)))

  const fetchUsersDebounced = React.useCallback(useDebounce(fetchUsers, 300), [])
  React.useEffect(() => {
    fetchUsersDebounced(filters)
  }, [filters])

  return (
    <>
      <PageTitle
        breadCrumbItems={[{ label: 'Lista pracowników', path: '', active: true }]}
        title={`Lista pracowników (${users.length})`}
      />
      <Card>
        <CardBody>
          <UsersFilter defaultFilters={defaultFilters} filters={filters} setFilters={setFilters} />

          <RcpUsersTable filters={filters} setFilters={setFilters} isLoading={isLoading} users={users} />
          <Row>
            <Col md={12} className="text-right">
              <DownloadButtons
                exportPdf={`${exportPdf}${objectToUrlString(filters)}`}
                exportXls={`${exportXls}${objectToUrlString(filters)}`}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}
