import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import { HistoryBox } from '@modules/promotions/common/history-box'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectGet } from '@store/actions/generic-actions'
import { useAppDispatch, useAppSelector } from '@store/index'

import { ContentLoader } from '@components/content-loader'
import { RentalProduct } from '@modules/rental/models'
import { selectRentalProductDetails, setRentalProductDetails } from '@store/slices/rental-slice'
import { RentalProductDetailsBaseDataTable } from '@modules/rental/products/modals/details/rental-product-details-base-data-table'

interface Props extends BaseModalProps {
  product: RentalProduct
}

export const RentalProductDetailsModal: React.FC<Props> = ({ toggleIsVisible, product }) => {
  const dispatch = useAppDispatch()

  const productDetails = useAppSelector(selectRentalProductDetails)

  const { action: fetchProductDetails, isLoading } = useApiRequest(async () => {
    dispatch(setRentalProductDetails(await commonObjectGet(product.urls.details)))
  })

  React.useEffect(() => {
    fetchProductDetails()
  }, [])

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>Szczegóły produktu</ModalHeader>
      <ContentLoader isLoading={isLoading}>
        <ModalBody className="pb-0">
          {productDetails && (
            <>
              <RentalProductDetailsBaseDataTable productDetails={productDetails} />
              <HistoryBox history={productDetails.history} />
            </>
          )}
        </ModalBody>
      </ContentLoader>
      <ModalFooter className="d-flex">
        <Button className="btn btn-light text-right" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
      </ModalFooter>
    </>
  )
}
