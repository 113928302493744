import { CustomReactSelectOption } from '@components/custom-react-select'
import { InvoiceType } from '@models/promotions'
import { BaseModalProps } from '@components/modals/types'

export enum ReservationTypes {
  STANDARD = 'standard',
  EMPLOYEE = 'employee',
}

export enum LocalSelectionType {
  FREE = 'free',
  PAYABLE = 'paylable',
}

export interface StepReservationInfoFormInputs {
  accommodation_type: CustomReactSelectOption<string, number> | null
  localSelection: CustomReactSelectOption<string, string> | null
  resort: CustomReactSelectOption<string, number> | null
  apartment: CustomReactSelectOption<string, number> | null
  date_from: Date | null
  date_to: Date | null
  send_notification: boolean
  promocode?: CustomReactSelectOption<string, string>
}

export interface StepClientInfoFormProps {
  email: string | null
  name: string | null
  phone: string | null
  street: string | null
  postcode: string | null
  city: string | null
  invoice_nip: string
  invoice_company: string
  invoice_street: string
  invoice_postcode: string
  invoice_city: string
  invoice_type: InvoiceType
}

export interface ReservationCreateModalProps extends BaseModalProps {
  token?: string
}

export type ReservationCreateFormInputs = StepReservationInfoFormInputs & StepClientInfoFormProps
