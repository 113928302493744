import * as React from 'react'
import { Button, ModalFooter, ModalHeader } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { SaveButton } from '@hyper/button'
import { useAppDispatch } from '@store/index'
import { useForm } from 'react-hook-form'
import { createReportTask } from '@store/actions/report-tasks-actions'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { BaseModalProps } from '@components/modals/types'

interface Props extends BaseModalProps {
  title: string
  url: string
}

export const ReportNotConfigurable: React.FC<Props> = ({ toggleIsVisible, title, url }) => {
  const methods = useForm()

  const dispatch = useAppDispatch()

  const { isLoading, action: onSubmit } = useReportFormRequest(async () => {
    const reportTask = await dispatch(createReportTask([url, {}]))
    toggleIsVisible()
    return reportTask
  }, methods.setError)

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <ModalHeader toggle={toggleIsVisible}>{title}</ModalHeader>
      <ModalBody>Brak parametrów konfiguracji.</ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" label="Zleć raport" labelSaving="Zlecanie.." isSaving={isLoading} />
      </ModalFooter>
    </form>
  )
}
