import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { RootState, useAppSelector } from '@store/index'
import { Country } from '@models/dashboard'
import { DataRow } from '@components/data-row'

export interface PaymentCard {
  id: number
  has_3ds: boolean
  masked_number: string
  expiration_date: string
  country: string
}

interface Props extends BaseModalProps {
  paymentCard: PaymentCard
}

export const PaymentCardDetailsModal: React.FC<Props> = ({ toggleIsVisible, paymentCard }: Props) => {
  const countries = useAppSelector((state: RootState) => state.appState.appData.countries)

  const issuerCountry = countries.find((country: Country) => country.id === paymentCard.country)

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>Szczegóły karty płatniczej</ModalHeader>
      <ModalBody>
        <DataRow title="Numer karty" value={paymentCard.masked_number} />
        <DataRow title="Data ważności" value={toDefaultDateFormat(paymentCard.expiration_date)} />
        <DataRow title="Kraj wydania" value={issuerCountry?.name} />
        <DataRow title="3-D Secure" value={paymentCard.has_3ds ? 'Tak' : 'Nie'} />
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
      </ModalFooter>
    </>
  )
}
