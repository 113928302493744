import * as React from 'react'
import { DashboardLink } from '@components/left-menu/dashboard-link'
import { useLocation, useNavigate } from 'react-router-dom'
import classname from 'classnames'
import { RootState, useAppSelector } from '@store/index'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { DashboardMenu, DashboardMenuItem, UserPermission } from '@models/dashboard'

interface MenuRowProps {
  menu: DashboardMenu
}

export const MenuRow: React.FC<MenuRowProps> = ({ menu }) => {
  const pathname = '/dashboard' + (useLocation().pathname || 'irrelevant')
  const [isOpen, setIsOpen] = React.useState(menu.baseurl ? pathname.startsWith(menu.baseurl) : false)
  const isSingleRowMenu = menu.models.length == 1

  const menuActive = useAppSelector((state: RootState) => state.appState.menuActive)
  const user = useAuthenticatedUser()
  const navigate = useNavigate()

  const goToUrl = React.useCallback((url: string) => {
    if (url.startsWith('/dashboard')) {
      navigate(url.replace('/dashboard', ''))
    } else {
      document.location.href = url
    }
  }, [])

  const handleClick = React.useCallback(
    event => {
      event.preventDefault()
      const openStatus = !isOpen
      setIsOpen(state => !state)
      if (openStatus) {
        if (isSingleRowMenu) {
          goToUrl(menu.models[0].url)
        } else if (menu.url) {
          goToUrl(menu.url)
        }
      }
    },
    [menu, isOpen, isSingleRowMenu],
  )

  React.useEffect(() => {
    // console.log(menu.models, menuActive)
    if (menu.label !== 'Prezes Zarządu' && user.is_superuser && user.hasPerm(UserPermission.IsBoss)) {
      if (menu.models.some(row => row.url.toLowerCase() === `/dashboard${menuActive}`.toLowerCase())) {
        if (!isOpen) {
          setIsOpen(true)
        }
      } else if (isOpen) {
        setIsOpen(false)
      }
    }
  }, [menuActive])

  const isActive = (rowBaseLink: string) => pathname.endsWith(rowBaseLink)

  return (
    <li className={`side-nav-item gui-folder ${isOpen ? 'mm-active' : ''}`}>
      <a className="side-nav-link cursor-pointer" onClick={handleClick}>
        <i className={menu.icon} />
        <span>
          {menu.label}{' '}
          {menu.badge && menu.badge.value > 0 && (
            <span className={`badge ${menu.badge.style} text-normal`}>{menu.badge.value}</span>
          )}
        </span>
        <span className="menu-arrow" />
      </a>
      <ul className={`side-nav-second-level ${isOpen ? '' : 'mm-collapse'}`}>
        {menu.models.map((item: DashboardMenuItem) => (
          <React.Fragment key={item.url}>
            <li key={item.url} className={classname({ 'mm-active': isActive(item.url) })}>
              <DashboardLink href={item.url} className={classname({ 'mm-active': isSingleRowMenu && isOpen })}>
                <span className="title">
                  {item.label}{' '}
                  {item.badge && item.badge.value > 0 && (
                    <span className={`badge ${item.badge.style} text-normal`}>{item.badge.value}</span>
                  )}
                </span>
              </DashboardLink>
            </li>
            {item.separator && <li className="side-nav-separator" />}
          </React.Fragment>
        ))}
      </ul>
    </li>
  )
}
