import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import * as R from 'ramda'
import { useDebounce } from 'rooks'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { SubmitFilter } from '@modules/reception/common/filters/submit-filter'
import { Row } from 'reactstrap'
import { HousekeepingCleaningOrdersCleaningFiltersParams } from '@modules/housekeeping/cleaning-orders/cleaning/index'
import { HousekeepingCleaningOrderBaseFilters } from '@modules/housekeeping/cleaning-orders/common/improvements-filters'
import { OrderTableViewSwitch, TableViewType } from '@modules/technical-orders/common/table-view-switch'

interface Props {
  filters: HousekeepingCleaningOrdersCleaningFiltersParams
  setFilters: (filters: HousekeepingCleaningOrdersCleaningFiltersParams) => void
  defaultFilters: HousekeepingCleaningOrdersCleaningFiltersParams
  tableView: TableViewType
  onTableViewChange: (type: TableViewType) => void
}

export const HousekeepingCleaningOrdersCleaningFilters: React.FC<Props> = ({
  filters,
  setFilters,
  defaultFilters,
  tableView,
  onTableViewChange,
}) => {
  const { setValue, control, reset } = useFormContext<HousekeepingCleaningOrdersCleaningFiltersParams>()
  const values = useWatch({ control })

  const onSubmitDebounced = useDebounce(values => setFilters(values), 250)

  React.useEffect(() => {
    if (!R.equals(values, filters)) {
      onSubmitDebounced(values)
    }
  }, [values])

  const handleReset = () => {
    reset()
    setFilters(defaultFilters)
  }

  return (
    <Row className="align-items-center">
      <HousekeepingCleaningOrderBaseFilters className="ml-2" />
      <div className="d-flex align-items-center ml-auto">
        <OrderTableViewSwitch setTableView={onTableViewChange} tableView={tableView} />
        <SubmitFilter hideLabel handleReset={handleReset} formGroupClassName="mb-0" />
        <SearchFilter hideLabel setValue={setValue} colClassName="col-auto ml-auto" formGroupClassName="mb-0" />
      </div>
    </Row>
  )
}
