import * as React from 'react'
import { GastroCardDetails, GastroWallet, GastroWalletItem } from '@models/promotions'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { formatPrice } from '@helpers/utils'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { useFilteredRows } from '@components/table/use-filtered-rows'
import { useSelectableRows } from '@components/table/use-selectable-rows'
import { GastroCardWalletItemsFilters } from '@modules/promotions/gastro-cards/gastro-card-details/gastro-card-wallet-items/gastro-card-wallet-items-filters'
import { GastroCardWalletItemsSummary } from '@modules/promotions/gastro-cards/gastro-card-details/gastro-card-wallet-items/gastro-card-wallet-items-summary'

interface Props {
  gastroCardDetails?: GastroCardDetails
  wallet: GastroWallet
  title?: string
}

const headerValues: TableHeaderValue<GastroWalletItem>[] = [
  {
    sortField: 'created',
    title: 'Data',
  },
  {
    sortField: 'booking',
    title: 'Rezerwacja',
  },
  {
    sortField: 'booking_guest',
    title: 'Imię i nazwisko gościa',
  },
  {
    sortField: 'amount',
    title: 'Kwota',
  },
  {
    sortField: 'description',
    title: 'Opis',
    search: true,
  },
]

const defaultFilters: TableFilters = {
  search: '',
  page_size: 30,
  page: 1,
  ordering: '-created',
}

export const GastroCardWalletItems: React.FC<Props> = ({ title = 'Transakcje', gastroCardDetails, wallet }) => {
  const [filters, setFilters] = React.useState<TableFilters>(defaultFilters)

  const user = useAuthenticatedUser()
  const canSelectRows = !!gastroCardDetails && user.hasPerm(UserPermission.PromotionsGastroCardCanDeleteItem)

  const filteredItems = useFilteredRows<GastroWalletItem>(wallet.items, headerValues, String(filters.search))

  const { selectableHeader, selectableCell, selectedRows, clearSelectedRows } =
    useSelectableRows<GastroWalletItem>(filteredItems)

  return (
    <Card>
      <CardBody>
        <Row>
          <h4 className="mt-0 text-secondary mb-3">
            {title}
            <a href={wallet.urls.transactions_print} target="_blank">
              <i className="uil-print ml-1 text-secondary" />
            </a>
          </h4>
          <Col md={12} className="mt-2">
            <GastroCardWalletItemsFilters
              selectedRows={selectedRows}
              gastroCardDetails={gastroCardDetails}
              defaultFilters={defaultFilters}
              filters={filters}
              clearSelectedRows={clearSelectedRows}
              setFilters={setFilters}
            />
            <Table
              className="table-hover w-100"
              headerValues={headerValues}
              isLoading={false}
              showPagination={false}
              filters={filters}
              selectableHeader={canSelectRows ? selectableHeader : undefined}
              setFilters={setFilters}
            >
              {filteredItems.map(row => (
                <Table.Row key={row.id}>
                  {canSelectRows && selectableCell(row)}
                  <Table.Cell>{toDefaultDateTimeFormat(row.created)}</Table.Cell>
                  <Table.Cell>{row.booking}</Table.Cell>
                  <Table.Cell>{row.booking_guest}</Table.Cell>
                  <Table.Cell>{formatPrice(row.amount)}</Table.Cell>
                  <Table.Cell>{row.description}</Table.Cell>
                </Table.Row>
              ))}
              {!!filteredItems.length && <GastroCardWalletItemsSummary items={wallet.items} />}
            </Table>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}
