import * as React from 'react'
import { useModal } from '@components/modals/use-modal'
import { useClientDetails } from '@modules/crm/clients/list-view/use-client-details'

interface Props {
  id: string
}

export const CrmClientsDetailsView: React.FC<Props> = ({ id }) => {
  const [handleDetails] = useModal('ClientDetailsModal', { persist: true })

  const { fetchClientDetails } = useClientDetails()

  const show = async () => {
    const client = await fetchClientDetails(parseInt(id, 10))
    handleDetails(null, { client }, true)
  }

  React.useEffect(() => {
    show()
  }, [])

  return null
}
