import * as React from 'react'
import { UnitDetails } from '@models/units'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { TableFilters } from '@components/table/table'
import { TypedQueryResult } from '@api/base'
import { ImprovementOrder } from '@modules/housekeeping/models'
import { useModal } from '@components/modals/use-modal'
import { ButtonWithIcon } from '@components/button-with-icon'
import { useGetImprovementOrdersQuery } from '@api/improvement-orders'
import { UnitDetailsImprovementOrdersFilters } from '@modules/units/details/improvement-orders/filters'
import { UnitDetailsImprovementOrdersTable } from '@modules/units/details/improvement-orders/table'

interface Props {
  unit: UnitDetails
}
const defaultFilters: TableFilters = {
  search: '',
  ordering: '-date',
  page: 1,
  page_size: 10,
}

const defaultEmptyList = []

export const UnitDetailsImprovementOrders: React.FC<Props> = ({ unit }) => {
  const [filters, setFilters] = React.useState<TableFilters>(defaultFilters)

  const { data: improvementOrders = defaultEmptyList, refetch } = useGetImprovementOrdersQuery({
    ...filters,
    apartment: unit.apartment?.id,
  }) as TypedQueryResult<ImprovementOrder[]>

  const [handleClick] = useModal('CreateImprovementOrderModal', {
    resortId: unit.resort_id,
    apartmentId: unit.apartment?.id,
    onComplete: refetch,
  })

  return (
    <Card>
      <CardBody>
        <Row>
          <Col md={6}>
            <h4 className="text-secondary">Ulepszenie</h4>
          </Col>
          <Col md={6} className="text-right">
            <div className="ml-auto mb-1">
              <ButtonWithIcon handleClick={handleClick} icon="uil-plus" text="Zgłoś nowe ulepszenie" color="green" />
            </div>
          </Col>
        </Row>
        <UnitDetailsImprovementOrdersFilters
          defaultFilters={defaultFilters}
          filters={filters}
          setFilters={setFilters}
        />
        <UnitDetailsImprovementOrdersTable
          improvementOrders={improvementOrders}
          filters={filters}
          setFilters={setFilters}
        />
      </CardBody>
    </Card>
  )
}
