import * as React from 'react'
import Table from '@components/table/table'
import { formatPrice } from '@helpers/utils'
import { RootState, useAppSelector } from '@store/index'

export const PaymentRegistersTableSummer = (): JSX.Element => {
  const balance = useAppSelector((state: RootState) => state.cashState.balance)

  return (
    <Table.Row>
      <Table.Cell colSpan={5} className="text-right align-middle package-list__summary-cell">
        <strong className="mr-2">Razem w wybranym okresie:</strong>
      </Table.Cell>
      <Table.Cell className="package-list__summary-cell">
        <div className="text-white package-list__summary-block pt-2">
          Wpłaty
          <strong className="d-block">{formatPrice(balance.total_payin)}</strong>
        </div>
      </Table.Cell>
      <Table.Cell className="package-list__summary-cell">
        <div className="text-white package-list__summary-block pt-2">
          Wypłaty
          <strong className="d-block">{formatPrice(balance.total_payout)}</strong>
        </div>
      </Table.Cell>

      <Table.Cell className="package-list__summary-cell" colSpan={2}>
        <div className="package-list__summary-block" />
      </Table.Cell>
    </Table.Row>
  )
}
