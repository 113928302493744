import * as React from 'react'
import { Button, Col, CustomInput, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { SaveButton } from '@hyper/button'
import { useAppData } from '@components/hooks/use-app-data'
import { useAppDispatch } from '@store/index'
import { useForm } from 'react-hook-form'
import { createReportTask } from '@store/actions/report-tasks-actions'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { gastroCardsStatus } from '@modules/promotions/gastro-cards/gastro-cards-filters'
import { useGroupedSellers } from '@components/hooks/use-grouped-sellers'
import { useGroupedSources } from '@components/hooks/use-grouped-sources'
import { UserPermission } from '@models/dashboard'
import { extractInnerRef } from '@helpers/forms'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { GastroCardsFiltersParams } from '@modules/promotions/gastro-cards/models'
import { BaseModalProps } from '@components/modals/types'
import { Form } from '@hyper/forms/form'
import { FormSelect } from '@hyper/forms/form-select'
import { GastroCardKind } from '@models/promotions'
import { extractSelectOptionsValues } from '@helpers/utils'

interface Props extends BaseModalProps {
  filters: GastroCardsFiltersParams
  kind?: GastroCardKind
}

export const ReportsGeneratorGastroCard: React.FC<Props> = ({ toggleIsVisible, filters, kind = 'normal' }) => {
  const { urls } = useAppData()
  const reportUrl =
    kind === 'gastro_card_boost'
      ? urls.reports.report_task_gastro_card_boost_report
      : urls.reports.report_task_gastro_card
  const dispatch = useAppDispatch()

  const methods = useForm<GastroCardsFiltersParams>({
    mode: 'all',
    defaultValues: { ...filters },
  })

  const user = useAuthenticatedUser()

  const { isLoading, action: onSubmit } = useReportFormRequest(async (payload: GastroCardsFiltersParams) => {
    const reportTask = await dispatch(
      createReportTask([
        reportUrl,
        {
          ...extractSelectOptionsValues(payload),
          kind,
        },
      ]),
    )
    toggleIsVisible()
    return reportTask
  }, methods.setError)

  const sellersOption = useGroupedSellers()
  const sourcesOption = useGroupedSources()

  return (
    <Form onSubmit={onSubmit} methods={methods}>
      <ModalHeader toggle={toggleIsVisible}>
        {kind === 'gastro_coupon'
          ? 'Voucher Strzał w dziesiątkę'
          : kind === 'gastro_card_boost'
            ? 'Doładowanie smaku'
            : 'Podwójna radość'}{' '}
        - Raport sprzedaży
      </ModalHeader>
      <ModalBody>
        <Row>
          <FormSelect
            options={gastroCardsStatus}
            name="status"
            label="Status"
            selectProps={{ isClearable: true, placeholder: 'Wszystkie', isSelectMulti: true }}
          />
          <FormSelect
            options={sellersOption}
            name="seller"
            label="Sprzedawca"
            selectProps={{ isClearable: true, placeholder: 'Wszyscy' }}
          />
          <FormSelect
            options={sourcesOption}
            name="source_marketing"
            label="Źródło"
            selectProps={{ isClearable: true, placeholder: 'Wszyscy' }}
          />

          <DateFromToInput
            label="Data utworzenia pakietu"
            startDateName="sell_date_after"
            endDateName="sell_date_before"
          />
          <DateFromToInput
            label="Data płatności"
            startDateName="payment_date_after"
            endDateName="payment_date_before"
          />
          {user.hasPerm(UserPermission.PromotionsGastroCardCanDelete) && (
            <Col className="text-default col-md-2">
              <label className="d-block">&nbsp;</label>
              <CustomInput
                id="is_removed"
                type="checkbox"
                {...extractInnerRef(methods.register('is_removed'))}
                label="Pokaż skasowane"
              />
            </Col>
          )}
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" label="Zleć raport" labelSaving="Zlecanie..." isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
