import * as React from 'react'
import { UpSellOutdatedBooking } from '@models/upselling'
import { IconWithText } from '@components/icon-with-text'
import { toTextDateFormat } from '@helpers/date-helper'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import classNames from 'classnames'

interface Props {
  booking: UpSellOutdatedBooking
  type: string
}

export const UpsellBookingsOutdatedTableRow = ({ booking, type }: Props): JSX.Element => {
  const navigate = useNavigate()

  const navigateToOutdatedBookings = () => {
    navigate(NavigationPath.UpSellBookingsWithParams.replaceParam(':type', type), {
      state: { date: booking.contact_required_date },
    })
  }

  return (
    <tr onClick={navigateToOutdatedBookings}>
      <td className="align-middle">
        <IconWithText
          icon="uil-calender font-18 mr-2"
          text={toTextDateFormat(booking.contact_required_date)}
          textClass="font-weight-bold"
        />
      </td>
      <td className="text-info align-middle">
        <div
          className={classNames('d-flex align-items-center justify-content-end', {
            'opacity-5': !booking.contact_required,
          })}
        >
          <strong>Wymaga kontaktu</strong>
          <div className="bg-info ml-2 text-white p-1 border rounded px-2">{booking.contact_required}</div>
        </div>
      </td>
      <td className="align-middle" width="25%">
        <div
          className={classNames('d-flex align-items-center justify-content-end', {
            'opacity-5': !booking.contact_required_again,
          })}
        >
          <strong className="text-danger-light">Do ponownego kontaktu</strong>
          <div className="bg-danger-light ml-2 text-white p-1 border rounded px-2">
            {booking.contact_required_again}
          </div>
        </div>
      </td>
      <td className="align-middle text-right" width="5%">
        <i className="uil-angle-right font-24 lh-0" />
      </td>
    </tr>
  )
}
