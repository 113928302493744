import * as React from 'react'
import { Badge, Col, Row } from 'reactstrap'
import classNames from 'classnames'
import { Agreement } from '@modules/hr/agreement/models'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { useAppData } from '@components/hooks/use-app-data'
import { getById, sortObjectListByDate } from '@helpers/utils'
import { useHrAppData } from '@modules/hr/use-hr-app-data'
import { useModal } from '@components/modals/use-modal'
import { AgreementAnnexCard } from '@modules/hr/agreement/details/annexes/annex-card'
import { compareAsc } from 'date-fns'

interface Props {
  agreement: Agreement
  marginLeft?: number
  isActive?: boolean
}

export const WorkerAgreementCard = ({ agreement, isActive, marginLeft = 0 }: Props): JSX.Element => {
  const { job_positions } = useHrAppData()
  const { resorts: appDataResort } = useAppData()

  const [showDetailsModal] = useModal('AgreementDetailsModal', { agreement })

  const resorts = agreement.resorts
    .map(resortId => appDataResort.find(resort => resort.id === resortId)?.name)
    .join(', ')

  const variantClassName = {
    backgroundColor: isActive ? 'bg-white' : 'bg-light-grey',
    badgeColor: isActive ? 'bg-success text-white' : 'bg-white-smoke text-default',
  }

  const jobPosition = getById(job_positions, agreement?.job_position)

  const sortedAnnexes = sortObjectListByDate(agreement.annexes, 'effective_date', compareAsc).toSorted(annex =>
    annex.status === 'active' ? -1 : 1,
  )

  return (
    <>
      <Row className="align-items-center mb-1 mx-0">
        <Col md={11}>
          <Row className="align-items-center flex-nowrap">
            <div
              className={classNames(
                'col-2 border border-right-0 d-flex align-items-center',
                variantClassName.backgroundColor,
              )}
              style={{ marginLeft: marginLeft, height: 55.5 }}
            >
              <Badge className={classNames('px-2 border', variantClassName.badgeColor)}>
                {isActive ? 'Aktywna umowa' : agreement.status_display}
              </Badge>
            </div>
            <Item
              className={variantClassName.backgroundColor}
              title="Numer umowy"
              value={agreement.number}
              style={{ marginLeft: -marginLeft, maxWidth: '150px' }}
            />

            <Item className={variantClassName.backgroundColor} title="Stanowisko" value={jobPosition?.name} />
            <Item
              className={variantClassName.backgroundColor}
              title="Resort"
              value={resorts}
              style={{ minWidth: '200px' }}
            />

            <Item
              className={variantClassName.backgroundColor}
              title="Obowiązuje od"
              value={toDefaultDateFormat(agreement.date_of_start)}
            />
            <Item
              className={variantClassName.backgroundColor}
              title="Obowiązuje do"
              value={toDefaultDateFormat(agreement.date_of_expiration)}
            />
          </Row>
        </Col>
        <Col
          md={1}
          className={classNames(
            'border border-left-0 p-2 d-flex align-items-center justify-content-end',
            variantClassName.backgroundColor,
          )}
          style={{ height: 55.5 }}
        >
          <i className="uil-eye font-16 cursor-pointer" onClick={showDetailsModal} />
        </Col>
      </Row>

      {sortedAnnexes.map((annex, index) => (
        <AgreementAnnexCard
          annex={annex}
          agreement={agreement}
          key={annex.id}
          isSubsection
          isFirstChild={index === 0}
        />
      ))}
    </>
  )
}

const Item = ({ title, value, style = {}, className }) => (
  <div className={classNames('col-2 bg-light-grey p-2 border-top border-bottom', className)} style={style}>
    <small className="d-block font-11">{title}</small>
    <strong className="text-nowrap">{value || '-'}</strong>
  </div>
)
