import * as React from 'react'
import { Button, ModalFooter, ModalHeader } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { SaveButton } from '@hyper/button'
import { useForm } from 'react-hook-form'
import {
  CustomReactSelectOption,
  CustomReactSelectOptionGroup,
  makeDefaultSelectOption,
} from '@components/custom-react-select'
import { BaseModalProps } from '@components/modals/types'
import { createSelectOption, extractSelectOptionsValues } from '@helpers/utils'
import { Form } from '@hyper/forms/form'
import { HousekeepingUser } from '@modules/housekeeping/models'
import { useFormRequest } from '@components/hooks/use-api-request'
import { useAppData } from '@components/hooks/use-app-data'
import * as R from 'ramda'
import { useAppDispatch, useAppSelector } from '@store/index'
import { housekeepingCompaniesSelector, updateHousekeepingUserDetails } from '@store/slices/housekeeping-slice'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { commonObjectPost, commonObjectPut } from '@store/actions/generic-actions'
import { HousekeepingUserModalForm } from '@modules/housekeeping/users/modal/form'

interface FormInputs {
  first_name: string
  last_name: string
  rcp_badge: string | null
  housekeeping_app_language: CustomReactSelectOption | null
  housekeeping_company: CustomReactSelectOption | null
  resort: CustomReactSelectOption
  roles: CustomReactSelectOption[]
  phone: string
  is_active: boolean
  password: string
  confirmed_password: string
}

interface Props extends BaseModalProps {
  user?: HousekeepingUser
}

export const housekeepingRoleOptions: CustomReactSelectOption[] = [
  createSelectOption('Sprzątaczka', 'is_housekeeping_cleaning_company'),
  createSelectOption('Złota rączka', 'is_housekeeping_handyman'),
  createSelectOption('Ogrodnik', 'is_housekeeping_gardener'),
  createSelectOption('Koordynator', 'is_housekeeping_manager'),
  createSelectOption('Pościelowy', 'is_housekeeping_bedding'),
  createSelectOption('Pracownik techniczny', 'is_technical_order_worker'),
  createSelectOption('Dyrektor techniczny', 'is_technical_order_manager'),
]

export const HousekeepingUserModal: React.FC<Props> = ({ toggleIsVisible, user }) => {
  const { resorts, urls, user_languages } = useAppData()
  const { addSuccessNotification } = useNotificationHook()
  const dispatch = useAppDispatch()

  const userLanguages = React.useMemo(
    () => user_languages.map(row => createSelectOption(row[1], row[0])),
    [user_languages],
  )

  const cleaningCompanies = useAppSelector(housekeepingCompaniesSelector)

  const resortOptions = resorts.map(({ id, name }) => createSelectOption(name, id))

  const companiesOptions = React.useMemo(
    () =>
      Object.values<CustomReactSelectOptionGroup>(
        cleaningCompanies.reduce((accumulator, currentValue) => {
          if (!accumulator[currentValue.type]) {
            accumulator[currentValue.type] = {
              label: currentValue.type_display,
              options: [],
            }
          }
          accumulator[currentValue.type].options.push(createSelectOption(currentValue.name, currentValue.id))
          return accumulator
        }, {}),
      ),
    [cleaningCompanies],
  )

  const companiesOptionsAll = companiesOptions
    .map(({ options }: { options: CustomReactSelectOption[] }) => options)
    .flat()

  const methods = useForm<FormInputs>({
    mode: 'all',
    defaultValues: {
      ...(user && R.pick<FormInputs, any>(['first_name', 'last_name', 'is_active', 'phone', 'rcp_badge'], user)),
      roles: housekeepingRoleOptions.filter(row => (user ? user[row.value] : false)),
      resort: resortOptions.find(row => user?.resorts.includes(row.value)),
      housekeeping_company: makeDefaultSelectOption(companiesOptionsAll, user?.housekeeping_company),
      is_active: true,
      language: userLanguages.find(row => row.value === user?.housekeeping_app_language) || userLanguages[1],
      rcp_badge: user?.rcp_badge ?? null,
    },
  })

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    const data = {
      ...payload,
      ...extractSelectOptionsValues<{}, {}>(payload),
      roles: undefined,
      ...Object.fromEntries(
        housekeepingRoleOptions.map(row => [row.value, payload.roles.some(role => role.value === row.value)]),
      ),
      rcp_badge: payload.rcp_badge || null,
    }
    if (user) {
      dispatch(updateHousekeepingUserDetails(await commonObjectPut<HousekeepingUser>(user.urls.details, data)))
      addSuccessNotification('Zmiany zostały zapisane')
    } else {
      dispatch(
        updateHousekeepingUserDetails(await commonObjectPost<HousekeepingUser>(urls.account.housekeeping_users, data)),
      )
      addSuccessNotification('Pracownik został dodany')
    }
    toggleIsVisible()
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>
        {user ? `Edytuj pracownika: ${user.first_name} ${user.last_name}` : 'Dodaj pracownika'}
      </ModalHeader>
      <ModalBody>
        <HousekeepingUserModalForm
          userLanguages={userLanguages}
          user={user}
          companiesOptions={companiesOptions}
          resortOptions={resortOptions}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
