import * as React from 'react'
import { UpSellBookingDetails } from '@models/upselling'
import { UpsellBookingsDetailsSubscriptionProducts } from '@modules/upselling/upsell-booking-details/products/products-card/subscriptions-products'
import { SubscriptionType } from '@modules/subscription/models'
import { UpsellBookingsDetailsGastroProducts } from '@modules/upselling/upsell-booking-details/products/products-card/gastro-products'
import { GastroProductKind } from '@models/products'
import { UpsellBookingsDetailsSubscriptionContracts } from '@modules/upselling/upsell-booking-details/products/products-card/subscriptions-contracts'

interface Props {
  upsellBooking: UpSellBookingDetails
  kind: string
  statusFor: 'active' | 'used'
  onProductsCountChange: (count: number, type: string) => void
}

export const UpsellBookingsDetailsProductRow = ({
  upsellBooking,
  kind,
  statusFor,
  onProductsCountChange,
}: Props): JSX.Element => {
  const statuses = {
    active: {
      subscription: ['initial', 'confirmed'],
      gastro: ['initial', 'confirmed'],
      subscriptionContract: ['initial', 'confirmed', 'initial_resume'],
    },
    used: {
      subscription: ['completed', 'cancelled', 'initial_cancelled'],
      gastro: ['completed', 'cancelled'],
      subscriptionContract: ['completed', 'cancelled', 'cancelling'],
    },
  }[statusFor]

  return (
    <>
      {['DEFAULT', 'WITH_BENEFIT', 'WITH_BENEFIT_20', 'GO_HOLIDAY'].includes(kind) && (
        <UpsellBookingsDetailsSubscriptionProducts
          subscriptions={upsellBooking.client.subscriptions}
          type={kind as SubscriptionType}
          statuses={statuses.subscription}
          onProductsCountChange={onProductsCountChange}
        />
      )}

      {['gastro_coupon', 'gastro_card_boost', 'gastro_card_2024', 'gastro_card_2025'].includes(kind) && (
        <UpsellBookingsDetailsGastroProducts
          gastroCards={upsellBooking.client.gastro_cards}
          kind={kind as GastroProductKind}
          statuses={statuses.gastro}
          onProductsCountChange={onProductsCountChange}
        />
      )}

      {kind === 'subscription_contract' && (
        <UpsellBookingsDetailsSubscriptionContracts
          subscriptionContracts={upsellBooking.client.subscription_contracts}
          statuses={statuses.subscriptionContract}
          onProductsCountChange={onProductsCountChange}
        />
      )}
    </>
  )
}
