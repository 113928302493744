import * as React from 'react'
import { ReceptionBooking } from '@models/reception'
import { Button } from 'reactstrap'
import { useExtendedStay } from '@modules/reception/to-checkout/row/use-extended-stay'

interface Props {
  booking: ReceptionBooking
}

export const ReceptionToCheckOutRowButtonsExtendedStay: React.FC<Props> = ({ booking }) => {
  const { showExtensionStayModal, canExtend } = useExtendedStay(booking)

  return (
    <Button
      onClick={showExtensionStayModal}
      color="secondary"
      className="reception__bookings_table__row__clock-button"
      type="button"
      title="Przedłuż pobyt"
      disabled={!canExtend}
    >
      <i className="uil uil-clock font-16" />
    </Button>
  )
}
