import * as React from 'react'
import { CustomInput } from 'reactstrap'
import { CustomSwitch } from '@components/custom-switch'
import { ReceptionBookingFeedingExpandCol } from '@modules/reception/checkin/step-feeding/reception-booking-feeding-expand-col'
import { ReceptionBookingGuest, ReceptionBookingGuestPromotion } from '@models/booking'
import { BookingGuestFeedingOption, ReceptionBookingDetails, SingleCalculatedFeedingPrice } from '@models/reception'
import classnames from 'classnames'
import classNames from 'classnames'
import declination from '@helpers/declination'
import { PriceLoader } from '@modules/reception/checkin/step-feeding/price-loader'
import { formatPriceShort } from '@helpers/utils'
import * as R from 'ramda'
import { ReceptionBookingFeedingExpanded } from '@modules/reception/checkin/step-feeding/reception-booking-feeding-expanded'
import { FeedingsHandler } from '@modules/reception/checkin/step-feeding/use-feedings-with-defaults'

interface ReceptionBookingFeedingRowProps {
  guests: ReceptionBookingGuest[]
  guest: ReceptionBookingGuest
  feedingOptions: Partial<BookingGuestFeedingOption>[]
  feedingPrices: SingleCalculatedFeedingPrice[] | undefined
  feedingsHandler: FeedingsHandler
  booking: ReceptionBookingDetails
}

const shouldCheckFullDinner = (
  feedingOptions: Partial<BookingGuestFeedingOption>[],
  booking: ReceptionBookingDetails,
): boolean => {
  feedingOptions = feedingOptions.filter(feedingOption => feedingOption.date !== booking.date_to)
  return feedingOptions.filter(feedingOption => feedingOption.dinner).length == feedingOptions.length
}

const shouldCheckFullBreakfast = (
  feedingOptions: Partial<BookingGuestFeedingOption>[],
  booking: ReceptionBookingDetails,
): boolean => {
  feedingOptions = feedingOptions.filter(feedingOption => feedingOption.date !== booking.date_from)

  return feedingOptions.filter(feedingOption => feedingOption.breakfast).length == feedingOptions.length
}

export const ReceptionBookingFeedingRow: React.FC<ReceptionBookingFeedingRowProps> = ({
  guest,
  feedingPrices,
  feedingsHandler,
  feedingOptions,
  booking,
}) => {
  const [expanded, setExpanded] = React.useState(false)
  const toggleExpand = () => setExpanded(!expanded)

  const isFullDinner = shouldCheckFullDinner(feedingOptions, booking)
  const isFullBreakfast = shouldCheckFullBreakfast(feedingOptions, booking)

  const checkboxLabel = feedingOptions.length
    ? `${feedingOptions.length} ${declination.day(feedingOptions.length)}`
    : 'brak'

  const handleDinner = (event: React.ChangeEvent<HTMLInputElement>) =>
    feedingsHandler.setFeedingOptionForGuest(guest.id, event.target.checked, undefined)

  const handleBreakfast = (event: React.ChangeEvent<HTMLInputElement>) =>
    feedingsHandler.setFeedingOptionForGuest(guest.id, undefined, event.target.checked)

  const handleFull = (event: React.ChangeEvent<HTMLInputElement>) =>
    feedingsHandler.setFeedingOptionForGuest(guest.id, event.target.checked, event.target.checked)

  const guestFeedingPrices = (feedingPrices || []).filter(feedingPrice => feedingPrice.guest_id == guest.id)
  const guestTotalPrice = R.sum(guestFeedingPrices.map(feedingPrice => feedingPrice.price_brutto))

  const isFeedingReadOnly = React.useRef<{ [key: string]: boolean }>()

  React.useEffect(() => {
    isFeedingReadOnly.current = {
      [breakfastFullId]: isFullBreakfast,
      [dinnerFullId]: isFullDinner,
      [fullId]: isFullBreakfast && isFullDinner,
    }
  }, [booking.feeding_options])

  const breakfastFullId = `${guest.id}-breakfast-full`
  const dinnerFullId = `${guest.id}-dinner-full`
  const fullId = `${guest.id}-full`

  return (
    <>
      <tr className="border-bottom">
        <td>
          <div>
            <span className="d-block text-dark text-semi-strong">{guest.name}</span>
            <span className="d-block font-12">{guest.type_display}</span>
            <span className="d-block font-12 text-danger d-flex flex-column">
              {guest.promotions.map((promotion: ReceptionBookingGuestPromotion) => (
                <span key={promotion.id}>{promotion.promotion_name}</span>
              ))}
            </span>
          </div>
        </td>
        <td>
          <CustomInput
            id={breakfastFullId}
            type="checkbox"
            className="text-semi-strong font-13 reception__booking-check-in__feeding__checkbox"
            label={checkboxLabel}
            name="breakfast_full"
            disabled={true}
            checked={isFullBreakfast}
            onChange={handleBreakfast}
          />
        </td>
        <td>
          <CustomInput
            id={dinnerFullId}
            type="checkbox"
            className="text-semi-strong font-13 reception__booking-check-in__feeding__checkbox"
            label={checkboxLabel}
            disabled={true}
            name="dinner_full"
            checked={isFullDinner}
            onChange={handleDinner}
          />
        </td>
        <td>
          <CustomSwitch
            id={fullId}
            label="Pełne"
            labelClassName="font-12"
            readOnly={true}
            inputName="full"
            value={isFullBreakfast && isFullDinner}
            handleSwitchChange={handleFull}
          />
        </td>
        <td colSpan={3} className="text-right">
          <ReceptionBookingFeedingExpandCol isExpanded={expanded} toggleExpand={toggleExpand} />
        </td>
        <td className="text-right reception__booking-check-in__feeding__total-price-col">
          <PriceLoader
            price={feedingPrices}
            spinnerClass="text-danger"
            content={<strong className="text-danger">={formatPriceShort(guestTotalPrice)}</strong>}
          />
        </td>
      </tr>
      <tr className={classNames({ 'border-bottom': expanded })}>
        <td colSpan={8} className="p-0">
          <div
            className={classnames('reception__booking-check-in__feeding__details', {
              'reception__booking-check-in__feeding__details--expanded': expanded,
            })}
          >
            <ReceptionBookingFeedingExpanded
              readOnly={{
                full: true,
                breakfast: true,
                dinner: true,
              }}
              prices={guestFeedingPrices}
              booking={booking}
              feedingOptions={feedingOptions}
              setFeedingOptionForGuest={feedingsHandler.setFeedingOptionForGuest}
            />
          </div>
        </td>
      </tr>
    </>
  )
}
