import * as React from 'react'
import Table from '@components/table/table'
import { BenefitProgramDetailsInvite } from '@modules/benefit-program/models'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  benefitProgramInvite: BenefitProgramDetailsInvite
}

export const BenefitProgramInvitesTableRow: React.FC<Props> = ({ benefitProgramInvite }) => (
  <Table.Row>
    <Table.Cell className="vertical-align-middle">{benefitProgramInvite.email}</Table.Cell>
    <Table.Cell className="vertical-align-middle">{toDefaultDateFormat(benefitProgramInvite.sent_at)}</Table.Cell>
    <Table.Cell className="vertical-align-middle">
      <a href={benefitProgramInvite.urls.sale} target="_blank">
        <IconWithText icon="uil-envelope-redo font-14" text="Wyślij ponownie" wrapperClassNames="text-default" />
      </a>
    </Table.Cell>
  </Table.Row>
)
