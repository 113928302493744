import * as React from 'react'
import { TechnicalOrderDetails } from '@models/technical-orders'
import { BaseModalProps } from '@components/modals/types'
import { ModalBody, ModalHeader } from 'reactstrap'
import { TechnicalOrderPreviewModalFooter } from '@modules/technical-orders/technical-order-preview-modal/footer'
import { useLocation, useNavigate } from 'react-router-dom'
import { TechnicalOrderPreviewModalContent } from '@modules/technical-orders/technical-order-preview-modal/content'
import { TechnicalOrderPreviewModalDetails } from '@modules/technical-orders/technical-order-preview-modal/details'
import { TechnicalOrderPreviewModalHistory } from '@modules/technical-orders/technical-order-preview-modal/history'

interface Props extends BaseModalProps {
  technicalOrderDetails: TechnicalOrderDetails
  refetch: () => Promise<{ data: TechnicalOrderDetails }>
}

export const TechnicalOrderPreviewModal: React.FC<Props> = ({ refetch, toggleIsVisible, technicalOrderDetails }) => {
  const [technicalOrder, setTechnicalOrder] = React.useState<TechnicalOrderDetails>(technicalOrderDetails)
  const navigate = useNavigate()
  const location = useLocation()

  React.useEffect(
    () => () => {
      navigate(location.pathname.replace(new RegExp(`/${technicalOrder.id}$`), ''))
    },
    [navigate, location, technicalOrder.id],
  )

  const refresh = async () => {
    const { data } = await refetch()
    setTechnicalOrder(data)
  }

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>Podgląd usterki {technicalOrder.number}</ModalHeader>
      <ModalBody>
        <TechnicalOrderPreviewModalContent technicalOrderDetails={technicalOrder} />
      </ModalBody>
      <div className="hr hr-grey" />
      <ModalBody>
        <TechnicalOrderPreviewModalDetails refetch={refresh} technicalOrderDetails={technicalOrder} />
      </ModalBody>
      <div className="hr hr-grey" />
      <ModalBody>
        <TechnicalOrderPreviewModalHistory technicalOrderDetails={technicalOrder} />
      </ModalBody>
      <TechnicalOrderPreviewModalFooter technicalOrderDetails={technicalOrder} toggleIsVisible={toggleIsVisible} />
    </>
  )
}
