import * as React from 'react'
import Table from '@components/table/table'
import { PackageCode } from '@models/package'
import { PackageDetailsCodesRowNameEdit } from '@modules/package/details/codes/row-name-edit'
import { useToggle } from '@components/hooks/use-toggle'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  packageCode: PackageCode
}

export const PackageDetailsCodesRowName: React.FC<Props> = ({ packageCode }) => {
  const [isEditable, toggleIsEditable] = useToggle()

  return isEditable ? (
    <PackageDetailsCodesRowNameEdit packageCode={packageCode} toggleIsEditable={toggleIsEditable} />
  ) : (
    <Table.Cell className="align-middle" onDoubleClick={toggleIsEditable}>
      {packageCode.name ? (
        <span title="Kliknij dwukrotnie aby zmienić" className="cursor-pointer">
          {packageCode.name}
        </span>
      ) : (
        <IconWithText icon="uil-pen font-11" text="ustaw" onClick={toggleIsEditable} />
      )}
    </Table.Cell>
  )
}
