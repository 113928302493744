import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { Button, Col, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { useAppDispatch } from '@store/index'
import { useForm } from 'react-hook-form'
import { endOfMonth, startOfMonth, startOfToday } from 'date-fns'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { createReportTask } from '@store/actions/report-tasks-actions'
import { Form } from '@hyper/forms/form'
import { FormSelect } from '@hyper/forms/form-select'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { createSelectOption } from '@helpers/utils'
import { SaveButton } from '@hyper/button'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { useAppData } from '@components/hooks/use-app-data'
import { FormCheckbox } from '@hyper/forms/form-checkbox'
import { useSelectWithAllOption } from '@helpers/use-select-with-all-option'

interface FormInputs {
  date_after: Date
  date_before: Date
  accommodation_types: CustomReactSelectOption[]
  resorts: CustomReactSelectOption[]
  group_monthly: boolean
}

export const ReportGenericCourtyardOccupancyModal: React.FC<BaseModalProps> = ({ toggleIsVisible }) => {
  const { urls, resorts, accommodation_types } = useAppData()
  const dispatch = useAppDispatch()

  const methods = useForm<FormInputs>({
    mode: 'all',
    defaultValues: {
      date_after: startOfMonth(startOfToday()),
      date_before: endOfMonth(startOfToday()),
      resorts: [{ label: 'Wszystkie', value: 'all' }],
      accommodation_types: [{ label: 'Wszystkie', value: 'all' }],
      group_monthly: false,
    },
  })

  const { isLoading, action: onSubmit } = useReportFormRequest(async (payload: FormInputs) => {
    const reportTask = await dispatch(
      createReportTask([
        urls.reports.report_task_courtyard_occupancy_report,
        {
          ...payload,
          resorts: selectedResortOptions,
          accommodation_types: selectedAccommodationTypes,
        },
      ]),
    )
    toggleIsVisible()
    return reportTask
  }, methods.setError)

  const resortOptions = resorts.map(resort => createSelectOption(resort.name, resort.id))

  const { options: allResortOptions, selectedOptionsValues: selectedResortOptions } = useSelectWithAllOption(
    resortOptions,
    methods,
    'resorts',
  )

  const availableAccommodationTypes = accommodation_types.filter(accommodationType =>
    resorts.some(
      resort =>
        selectedResortOptions.includes(resort.id) &&
        resort.accommodation_types.some(el => el.id === accommodationType.id),
    ),
  )

  const accommodationTypeOptions = availableAccommodationTypes.map(accommodationType =>
    createSelectOption(accommodationType.name, accommodationType.id),
  )

  const { options: allUnitKindsOptions, selectedOptionsValues: selectedAccommodationTypes } = useSelectWithAllOption(
    accommodationTypeOptions,
    methods,
    'accommodation_types',
  )

  React.useEffect(() => {
    if (!selectedResortOptions.length) {
      methods.setValue('accommodation_types', [])
    }
  }, [selectedResortOptions])

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Raport obłożenia lokali w dziedzińcach</ModalHeader>
      <ModalBody>
        <Row>
          <DateFromToInput
            startDateName="date_after"
            endDateName="date_before"
            label="Termin pobytu"
            isClearable={false}
            inputGroupClassName="mb-2"
          />

          <FormSelect
            options={allResortOptions}
            name="resorts"
            label="Resorty"
            selectProps={{ isMulti: true, isClearable: false }}
          />
          <FormSelect
            options={allUnitKindsOptions}
            name="accommodation_types"
            label="Typy zakwaterowania"
            selectProps={{ isMulti: true, isClearable: false }}
          />
          <Col md={12}>
            <FormCheckbox name="group_monthly" label="Grupuj miesięcznie" />
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton
          className="btn btn-green"
          label="Zleć raport"
          labelSaving="Zlecanie.."
          isSaving={isLoading}
          disabled={!selectedAccommodationTypes.length || !selectedResortOptions.length}
        />
      </ModalFooter>
    </Form>
  )
}
