import * as React from 'react'
import { useAppDispatch, useAppSelector } from '@store/index'
import { useDocumentTitle } from '@helpers/utils'
import { PageTitle } from '@hyper/page-title'
import { Card, CardBody } from 'reactstrap'
import { useApiRequest } from '@components/hooks/use-api-request'
import {
  PackageWholesaleProductListFilter,
  PackageWholesaleProductListFilterParams,
  packageWholesaleProductsStatusOptions,
} from '@modules/package-wholesale/products/list/filter'
import { PackageWholesaleProductListTable } from '@modules/package-wholesale/products/list/table'
import { PackageWholesaleWebSocketHandler } from '@modules/package-wholesale/web-socket-handler'
import { getPackageWholesaleProducts } from '@store/actions/package-wholesale-actions'
import { totalSizeSelector } from '@store/selectors/dashboard'
import { useDebounce } from 'rooks'
import { packageWholesaleProductsSelector } from '@store/slices/package-wholesale-slice'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { usePackageWholesaleAppData } from '@modules/package-wholesale/hooks/use-package-wholesale-app-data'

const defaultFilters: PackageWholesaleProductListFilterParams = {
  search: '',
  ordering: '-created',
  page: 1,
  status: packageWholesaleProductsStatusOptions.find(row => row.value === 'available'),
  page_size: 10,
}

export const PackageWholesaleProductListView: React.FC = () => {
  const totalSize = useAppSelector(totalSizeSelector)
  const dispatch = useAppDispatch()
  const packageWholesaleProducts = useAppSelector(packageWholesaleProductsSelector)
  const [filters, setFilters] = React.useState<PackageWholesaleProductListFilterParams>({ ...defaultFilters })

  usePackageWholesaleAppData()

  const { isLoading, action: fetchProducts } = useApiRequest(
    async filters => await dispatch(getPackageWholesaleProducts(filters)),
  )

  useMenuActive(NavigationPath.PackageWholesaleList)

  const fetchProductsDebounced = React.useCallback(useDebounce(fetchProducts, 300), [])
  React.useEffect(() => {
    fetchProductsDebounced(filters)
  }, [filters])

  const pageTitle = 'Lista produktów'
  const pageTitleWithSize = pageTitle + ` (${totalSize})`
  useDocumentTitle(pageTitleWithSize)

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitleWithSize} />
      <Card>
        <PackageWholesaleWebSocketHandler />
        <CardBody>
          <PackageWholesaleProductListFilter
            filters={filters}
            setFilters={setFilters}
            defaultFilters={defaultFilters}
          />
          <PackageWholesaleProductListTable
            isLoading={isLoading}
            packageWholesaleProducts={packageWholesaleProducts}
            filters={filters}
            setFilters={setFilters}
          />
        </CardBody>
      </Card>
    </>
  )
}
