import * as React from 'react'
import { PageTitle } from '@hyper/page-title'
import { useDocumentTitle } from '@helpers/utils'
import { Card, CardBody } from 'reactstrap'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { CouponsTypesFilters } from '@modules/coupons/types/coupons-types-filters'
import { CouponTypesTable } from '@modules/coupons/types/table/coupons-types-table'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { CouponType } from '@modules/coupons/models'
import { TypedQueryResult } from '@api/base'
import { useGetCouponTypesQuery } from '@api/coupons'
import { BaseTableFilters } from '@components/table/use-handle-table-filters-change'

const pageTitle = 'Kupony — Typy'

export interface CouponTypesFilters extends BaseTableFilters {
  resort: CustomReactSelectOption | null
  is_active: boolean | null
  date_from: Date | null
  date_to: Date | null
}

const defaultFilters: CouponTypesFilters = {
  ordering: '-created',
  page: 1,
  page_size: 10,
  search: '',
  resort: null,
  is_active: null,
  date_from: null,
  date_to: null,
}
const emptyList = []

export const CouponsTypesView: React.FC = () => {
  const [filters, setFilters] = React.useState<CouponTypesFilters>(defaultFilters)

  useDocumentTitle(pageTitle)
  useMenuActive(NavigationPath.CouponsTypes)

  const { data: couponTypes = emptyList, isLoading } = useGetCouponTypesQuery(filters) as TypedQueryResult<CouponType[]>

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitle} />
      <Card>
        <CardBody>
          <CouponsTypesFilters filters={filters} setFilters={setFilters} defaultFilters={defaultFilters} />
          <CouponTypesTable isLoading={isLoading} filters={filters} setFilters={setFilters} couponTypes={couponTypes} />
        </CardBody>
      </Card>
    </>
  )
}
