import * as React from 'react'
import { ReceptionBooking, ReceptionCheckoutValidationResponse } from '@models/reception'
import { formatDate } from '@helpers/date-helper'
import { startOfToday } from 'date-fns'
import { useModal } from '@components/modals/use-modal'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectGet } from '@store/actions/generic-actions'

interface ReceptionBookingCheckOutProps {
  booking: ReceptionBooking
  onClose: () => void
}

export const ReceptionBookingCheckOut: React.FC<ReceptionBookingCheckOutProps> = ({ booking, onClose }) => {
  const today = React.useMemo(() => formatDate(startOfToday()), [startOfToday()])
  const [toggleIsCheckOutOpen] = useModal(
    'ReceptionBookingCheckOutDialog',
    { booking },
    {
      persist: true,
      onClose: React.useCallback(() => {
        onClose()
      }, []),
    },
  )

  const handleConfirm = React.useCallback(() => {
    hideIsConfirmOpen()
    toggleIsCheckOutOpen()
  }, [])

  const [toggleIsConfirmOpen, hideIsConfirmOpen] = useModal(
    'ReceptionBookingCheckOutDateConfirmDialog',
    { booking, handleConfirm },
    { onClose, persist: true },
  )

  const proceedCheckingOut = () => {
    if (booking.date_to === today) {
      toggleIsCheckOutOpen()
    } else {
      toggleIsConfirmOpen()
    }
  }

  const [showCheckOutUnavailableModal] = useModal(
    'ReceptionBookingCheckOutUnavailableModal',
    { booking, onConfirm: proceedCheckingOut },
    { onClose },
  )

  const { action: isCheckoutAvailable } = useApiRequest(
    async () => await commonObjectGet<ReceptionCheckoutValidationResponse>(booking.urls.check_out_validate),
  )

  const showCheckoutModal = async () => {
    const validationResult = (await isCheckoutAvailable()) as ReceptionCheckoutValidationResponse
    if (validationResult.can_checkout) {
      proceedCheckingOut()
    } else {
      showCheckOutUnavailableModal(null, { booking, onConfirm: proceedCheckingOut, data: validationResult })
    }
  }

  React.useEffect(() => {
    showCheckoutModal()
  }, [today])

  return null
}
