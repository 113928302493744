import * as React from 'react'
import { TechnicalOrderDetails } from '@models/technical-orders'
import { TechnicalOrderPreviewModalRow } from '@modules/technical-orders/technical-order-preview-modal/row'
import { ContentNotesCard } from '@components/content-notes/content-notes-card'
import { RootState, useAppSelector } from '@store/index'

interface Props {
  technicalOrderDetails: TechnicalOrderDetails
  refetch: () => void
}

export const TechnicalOrderPreviewModalDetails: React.FC<Props> = ({ refetch, technicalOrderDetails }) => {
  const contentType = useAppSelector((state: RootState) => state.appState.appData.content_types.technicalorder)

  return (
    <>
      <TechnicalOrderPreviewModalRow label="Opis usterki">
        {technicalOrderDetails.description}
      </TechnicalOrderPreviewModalRow>
      <TechnicalOrderPreviewModalRow label="Załączniki">
        <div className="d-flex align-items-center">
          {!technicalOrderDetails.images.length && <span>Brak załączników</span>}
          {technicalOrderDetails.images.map(image => (
            <a href={image.image} target="_blank" key={image.id} className="mr-1 mb-1 border">
              <img src={image.image} width={100} alt="" />
            </a>
          ))}
        </div>
      </TechnicalOrderPreviewModalRow>
      <TechnicalOrderPreviewModalRow label="Notatki">
        <ContentNotesCard
          readOnly={false}
          hideCard={true}
          objectId={technicalOrderDetails.id}
          contentType={contentType}
          onNotesUpdate={refetch}
          notes={technicalOrderDetails.notes}
        />
      </TechnicalOrderPreviewModalRow>
    </>
  )
}
