import * as React from 'react'
import { Button, Col, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { Row } from 'reactstrap/lib'
import { BaseModalProps } from '@components/modals/types'
import { RodoAgreement, RodoAgreementDetails } from '@modules/crm/rodo-agreements/models'
import { RodoAgreementsModalClientData } from '@modules/crm/rodo-agreements/benefits/modal/rodo-agreements-modal-client-data'
import { TypedQueryResult } from '@api/base'
import { RodoAgreementsModalStatuses } from '@modules/crm/rodo-agreements/benefits/modal/rodo-agreements-modal-statuses'
import { RODO_AGREEMENT_TAGS, rodoAgreementsApi, useGetRodoAgreementDetailsQuery } from '@api/rodo-agreements'
import { HistoryBox } from '@modules/promotions/common/history-box'
import { NotificationBox } from '@components/notification-box/notification-box'
import { useAppDispatch } from '@store/index'
import { useModal } from '@components/modals/use-modal'

interface Props extends BaseModalProps {
  rodoAgreement: RodoAgreement
}

export const RodoAgreementsModal: React.FC<Props> = ({ toggleIsVisible, rodoAgreement }) => {
  const { data: details } = useGetRodoAgreementDetailsQuery(
    rodoAgreement.urls.details,
  ) as TypedQueryResult<RodoAgreementDetails>

  const dispatch = useAppDispatch()

  const [showCloseConfirmationModal] = useModal(
    'RodoAgreementsIssueConfirmationModal',
    { rodoAgreement },
    { persist: true },
  )

  const onNotificationSent = React.useCallback(() => {
    dispatch(rodoAgreementsApi.util?.invalidateTags([RODO_AGREEMENT_TAGS.DETAILS]))
  }, [])

  const handleClose = () => {
    if (details && !details.benefit_transfer_date && !!details.phone_confirm_date && !!details.email_confirm_date) {
      showCloseConfirmationModal()
    }

    toggleIsVisible()
  }

  return (
    <>
      <ModalHeader toggle={handleClose}>
        {`${rodoAgreement.first_name} ${rodoAgreement.last_name}`} - informacje o kliencie
      </ModalHeader>
      <ModalBody>
        <LoaderPlaceholder content={details}>
          {details && (
            <Row>
              <RodoAgreementsModalClientData rodoAgreement={details} />
              <RodoAgreementsModalStatuses rodoAgreement={details} />
              <Col md={6}>
                <NotificationBox
                  smsDisabled={false}
                  emailDisabled={false}
                  emailTemplates={details?.available_email_notifications}
                  emailNotifications={details.email_notifications}
                  emailUrl={details.urls.email_notifications}
                  smsTemplates={details?.available_sms_notifications}
                  smsNotifications={details.sms_notifications}
                  smsUrl={details.urls.sms_notifications}
                  onNotificationSent={onNotificationSent}
                />
              </Col>
              <Col md={6}>
                <HistoryBox history={details.history} />
              </Col>
            </Row>
          )}
        </LoaderPlaceholder>
      </ModalBody>
      <ModalFooter className="d-block">
        <Row>
          <Col md={6} className="ml-auto text-right">
            <Button color="light" onClick={handleClose}>
              Wróć do listy klientów
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </>
  )
}
