import { createSelectOption } from '@helpers/utils'
import { Country } from '@models/dashboard'

export const AGREEMENT_WIZARD_STEP_VALIDATE_URLS = {
  1: 'basic_worker_data',
  2: 'address_data',
  3: 'national_health_data',
  4: 'job_position_data',
  5: 'statements_data',
}

export const AgreementStatusOptions = [
  createSelectOption('Szkic (niekompletny)', 'draft_incomplete'),
  createSelectOption('Szkic', 'draft'),
  createSelectOption('Do weryfikacji', 'to_verify'),
  createSelectOption('Zweryfikowana', 'verified'),
  createSelectOption('Aktywna', 'active'),
  createSelectOption('Zakończona', 'completed'),
  createSelectOption('Rezygnacja', 'resignation'),
]

export const HrWorkerStatusOptions = [
  createSelectOption('Zatrudniony', 'hired'),
  createSelectOption('Były pracownik', 'former_employee'),
  createSelectOption('Umowa wygasa', 'contract_expires'),
  createSelectOption('Nie zatrudniony', 'not_employed'),
]

export const getAvailableAgreementsCountriesOptions = (countries: Country[]) =>
  countries.reduce((prev, country) => {
    if (AvailableAgreementsCountries.includes(country.id.toLowerCase())) {
      return [...prev, createSelectOption(country.name, country.id)]
    }
    return prev
  }, [])

export const AvailableAgreementsCountries = [
  'pl',
  'ua',
  'by',
  'az',
  'ru',
  'bg',
  'md',
  'uz',
  'kz',
  'tm',
  'tr',
  'tn',
  'np',
  'ph',
]

export const BaseHrFieldDefaults = {
  different_address: false,
  bank_name: null,
  city: null,
  community: null,
  district: null,
  email: '',
  first_name: '',
  last_name: '',
  national_health_fund_branch: '',
  personal_id_number: '',
  phone: '',
  post: null,
  postcode: '',
  province: null,
  second_name: '',
  registered_city: null,
  registered_community: null,
  registered_district: null,
  registered_post: null,
  registered_province: null,
  registered_postcode: '',
  registered_street: '',
  registered_street_apartment: '',
  registered_street_house: '',
  street: '',
  street_apartment: '',
  street_house: '',
  tax_id: '',
  tax_office: null,
}
