import * as React from 'react'
import { Card, CardBody } from 'reactstrap'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  cardClassName?: string
  cardActions?: React.ReactNode
  title: string
  titleIcon?: string
  children?: React.ReactNode
  id?: string
}

export const ReservationCard = React.forwardRef<HTMLElement, Props>(
  ({ cardClassName, cardActions, title, titleIcon = '', children, id }, ref) => (
    <Card className={cardClassName} innerRef={ref} id={id}>
      <CardBody>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <IconWithText
            icon={titleIcon}
            text={title}
            wrapperClassNames="text-secondary text-semi-strong"
            textClass="fw-bold font-18 text-nowrap"
          />
          {cardActions && cardActions}
        </div>
        {children}
      </CardBody>
    </Card>
  ),
)
