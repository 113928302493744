import * as React from 'react'
import { EditModeType } from '@modules/reservations/timeline/timeline-item-basic'

interface Props {
  onEditModeChange: (editMode: EditModeType | null) => void
  position: any
}

export const TimelineItemEditAbort = ({ onEditModeChange, position }: Props): JSX.Element => (
  <div className="calendar-edit-abort-icon" onClick={() => onEditModeChange(null)} style={position}>
    <i className="uil-times text-white" />
  </div>
)
