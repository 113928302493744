import * as React from 'react'
import { UncontrolledTooltip } from 'reactstrap'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { getBookingDetails } from '@store/actions/timeline-actions'
import { TimelineBooking } from '@store/reducers/timeline-reducers'
import { useCancelablePromise } from '@components/hooks/use-cancelable-promise'
import { selectIsContextMenuVisible } from '@store/selectors/timeline'
import { TimelineItemTooltipDetails } from '@modules/reservations/timeline/item-tooltip/timeline-item-tooltip-details'

interface TimelineItemTooltipProps {
  booking: TimelineBooking
  isExtendedStay: boolean
  isReadOnly: boolean
}

interface TimelineItemTooltipContentProps {
  booking: TimelineBooking
  scheduleUpdate: () => void
  isExtendedStay: boolean
  isReadOnly: boolean
}

const TimelineItemTooltipContent: React.FC<TimelineItemTooltipContentProps> = ({
  booking,
  scheduleUpdate,
  isExtendedStay,
  isReadOnly,
}) => {
  const bookings = useAppSelector((state: RootState) => state.timelineState.bookingsDetails)

  const details = bookings.find(el => el.id === booking.id)

  React.useEffect(() => {
    scheduleUpdate()
  }, [details])

  return (
    <TimelineItemTooltipDetails
      details={details}
      status={booking.status}
      isExtendedStay={isExtendedStay}
      isReadOnly={isReadOnly}
    />
  )
}

const TimelineItemTooltip: React.FC<TimelineItemTooltipProps> = ({ booking, isExtendedStay, isReadOnly }) => {
  const dispatch = useAppDispatch()
  const [tooltipOpen, setTooltipOpen] = React.useState(false)

  const isContextMenuVisible = useAppSelector(selectIsContextMenuVisible)

  useCancelablePromise(
    cancelToken => {
      if (tooltipOpen) {
        dispatch(getBookingDetails(booking.id, booking.urls.api_details, cancelToken))
      }
    },
    [booking.id, tooltipOpen],
  )

  const toggle = () => {
    if (isContextMenuVisible) return
    setTooltipOpen(state => !state)
  }

  React.useEffect(() => {
    if (isContextMenuVisible) setTooltipOpen(false)
  }, [isContextMenuVisible])

  return (
    <UncontrolledTooltip
      placement="top"
      isOpen={tooltipOpen}
      target={`item-tooltip-${booking.id}`}
      toggle={toggle}
      className="calendar-tooltip"
      autohide={false}
      delay={700}
    >
      {({ scheduleUpdate }) => (
        <TimelineItemTooltipContent
          booking={booking}
          scheduleUpdate={scheduleUpdate}
          isExtendedStay={isExtendedStay}
          isReadOnly={isReadOnly}
        />
      )}
    </UncontrolledTooltip>
  )
}

export default TimelineItemTooltip
