import * as React from 'react'
import { Bar, BarChart, CartesianGrid, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { PackageWholesaleDetails } from '@modules/package-wholesale/models'
import { addDays, differenceInCalendarDays } from 'date-fns'
import { formatDate, parseISODate, toDefaultDateFormat } from '@helpers/date-helper'

export interface CounterData {
  date: string
  count: number
}

interface Props {
  counterData: CounterData[]
  packageWholesaleDetails: PackageWholesaleDetails
}

export const PackageWholesaleDetailsAnalysisChart = ({ packageWholesaleDetails, counterData }: Props): JSX.Element => {
  const dataRange = differenceInCalendarDays(
    parseISODate(packageWholesaleDetails.expire_after) as Date,
    parseISODate(packageWholesaleDetails.created) as Date,
  )

  const chartData = Array.from({ length: dataRange + 1 }, (_, i) => {
    const date = addDays(parseISODate(packageWholesaleDetails.created) as Date, i)

    const count = counterData.find((counterData: CounterData) => counterData.date === formatDate(date))?.count ?? 0

    return {
      name: toDefaultDateFormat(date),
      pv: count,
    }
  })

  return (
    <ResponsiveContainer width={1050} height={300}>
      <BarChart
        data={chartData}
        margin={{
          top: 5,
          left: -20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis allowDecimals={false} />
        <Tooltip content={TooltipContent} />
        <ReferenceLine y={0} stroke="#000" />
        <Bar dataKey="pv" fill="#0f2453" name="Ilość wejść:" />
      </BarChart>
    </ResponsiveContainer>
  )
}

const TooltipContent = ({ active, payload, label }) => {
  if (!active) return null
  return (
    <div className="bg-white rounded p-2 border">
      <p className="label mb-1">
        Dnia: <strong>{label}</strong>
      </p>
      <p className="label mb-0">
        Ilość wejść: <strong>{payload[0].value}</strong>
      </p>
    </div>
  )
}
