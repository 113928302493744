import * as React from 'react'
import { formatPrice } from '@helpers/utils'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { ReservationPaymentHistoryRowActions } from '@modules/reservations/details/payment-history/reervation-payment-history-row-actions'
import classnames from 'classnames'
import { IconWithTooltip } from '@components/icon-with-tooltip'
import { ReservationBookingPayment } from '@models/reception'

interface Props {
  bookingPayment: ReservationBookingPayment
}

export const ReservationPaymentHistoryRow = ({ bookingPayment }: Props): JSX.Element => {
  const className = classnames('align-middle', { 'text-strikethrough': bookingPayment.is_removed })

  return (
    <tr>
      <td className={className}>
        {bookingPayment.is_removed && (
          <IconWithTooltip
            icon="uil-info-circle mr-1"
            tooltipMessage={<span className="font-11">Wpłata usunięta</span>}
            tooltipId="test"
            color="text-secondary"
          />
        )}
        {bookingPayment.hide && (
          <IconWithTooltip icon="uil-eye mr-1" tooltipMessage="Wpłata ukryta" tooltipId="hide" color="text-secondary" />
        )}
        {toDefaultDateFormat(bookingPayment.invoice_date)}
      </td>
      <td className={className}>
        {bookingPayment.type_display}
        {bookingPayment.description && <div className="font-10">{bookingPayment.description}</div>}
      </td>
      <td className={className}>
        <span>{bookingPayment.source_display}</span>
      </td>
      <td className={className}>{bookingPayment.user_display}</td>
      <td className={className}>{formatPrice(bookingPayment.amount)}</td>
      <td className="text-right">
        <ReservationPaymentHistoryRowActions bookingPayment={bookingPayment} />
      </td>
    </tr>
  )
}
