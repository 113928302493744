import * as React from 'react'
import { CustomInput } from 'reactstrap'
import { extractInnerRef } from '@helpers/forms'
import { StepVerificationAddVoucherInput } from '@modules/reception/checkin/step-verification/step-verification-voucher/step-verification-add-voucher-input'
import { StepVerificationAddedVouchers } from '@modules/reception/checkin/step-verification/step-verification-voucher/step-verification-added-vouchers'
import { StepVerificationAddVoucherButton } from '@modules/reception/checkin/step-verification/step-verification-voucher/step-verification-add-voucher-button'
import { ReceptionBookingClientWallet, ReceptionBookingDetails, ReceptionBookingGroup } from '@models/reception'
import { useFormContext } from 'react-hook-form'
import classNames from 'classnames'
import { FormPlainError } from '@hyper/forms'
import { TopBarLoading } from '@components/topbar-loading'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useAppDispatch, useAppSelector } from '@store/index'
import { bookingWalletDetailsSelector } from '@store/slices/reservations-slice'
import { getBookingWalletDetails } from '@store/actions/reservation-actions'

interface Props {
  booking: ReceptionBookingDetails
  group: ReceptionBookingGroup
}

export const StepVerificationVoucher: React.FC<Props> = ({ booking, group }) => {
  const walletDetails = useAppSelector(bookingWalletDetailsSelector)
  const dispatch = useAppDispatch()

  const [isAddingVoucherMode, setIsAddingVoucherMode] = React.useState(false)
  const { register, watch } = useFormContext()

  const voucherKey = `voucher-for-group-${group.id}`

  const { isLoading, action: fetchBookingWalletDetails } = useApiRequest(
    async () => await dispatch(getBookingWalletDetails(booking)),
  )

  React.useEffect(() => {
    fetchBookingWalletDetails()
  }, [])

  const addedVouchers =
    walletDetails?.client_wallets.filter(
      (clientWallet: ReceptionBookingClientWallet) => clientWallet.group_id === group.id,
    ) || []

  const toggleIsAddingVoucherMode = () => {
    if (!addedVouchers.length) return
    setIsAddingVoucherMode(state => !state)
  }

  const guestHaveVoucher = watch(voucherKey)

  return (
    <tr>
      <td className="reservation-guests__table__gastro-voucher__first-column">
        {isLoading && <TopBarLoading role="wallet-top-bar-loading" />}
        <div className="d-flex align-items-center">
          <i className="uil-tag-alt mr-1 d-block" />
          <strong className="d-block">Voucher gastro lub voucher prezentowy</strong>
        </div>
      </td>
      <td className="reservation-guests__table__gastro-voucher__second-column">
        <div className="reception__booking-check-in__verification_step__voucher__no-voucher-checkbox pt-1">
          {!addedVouchers.length && (
            <div>
              <CustomInput
                type="checkbox"
                label="Grupa nie posiada vouchera"
                id={`voucher-no-exist-for-group-${group.id}`}
                {...extractInnerRef(
                  register(voucherKey, {
                    required: !walletDetails?.client_wallets.length && 'Zaznacz jeśli grupa nie posiada vouchera',
                  }),
                )}
              />
              <FormPlainError name={voucherKey} />
            </div>
          )}
        </div>
      </td>
      <td colSpan={3} className="px-1">
        <div className={classNames({ 'not-clickable': guestHaveVoucher })}>
          {addedVouchers.length ? (
            <StepVerificationAddedVouchers clientWallets={addedVouchers} />
          ) : (
            <StepVerificationAddVoucherInput
              booking={booking}
              onClose={toggleIsAddingVoucherMode}
              closable={false}
              group={group}
            />
          )}
          {isAddingVoucherMode ? (
            <StepVerificationAddVoucherInput booking={booking} onClose={toggleIsAddingVoucherMode} group={group} />
          ) : (
            <StepVerificationAddVoucherButton onClick={toggleIsAddingVoucherMode} clientWallets={addedVouchers} />
          )}
        </div>
      </td>
    </tr>
  )
}
