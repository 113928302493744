import * as React from 'react'
import { useApiRequest } from '@components/hooks/use-api-request'
import { TopBarLoading } from '@components/topbar-loading'
import { commonObjectGet } from '@store/actions/generic-actions'
import { useModal } from '@components/modals/use-modal'
import { useAppData } from '@components/hooks/use-app-data'
import { RecommendationGift } from '@models/crm'

interface Props {
  recommendation_gift_id: string
}

export const RecommendationGiftDetailsView: React.FC<Props> = ({ recommendation_gift_id }) => {
  const { urls } = useAppData()
  const [handleDetails, hideModal] = useModal('RecommendationGiftDetailsDialog')

  const { isLoading, action: fetchRecommendationGift } = useApiRequest<RecommendationGift>(
    async () => await commonObjectGet<RecommendationGift>(`${urls.crm.recommendation_gifts}${recommendation_gift_id}/`),
  )

  const showRecommendationGiftDetails = async () => {
    const recommendationGift = await fetchRecommendationGift()

    if (recommendationGift) {
      handleDetails(
        null,
        {
          recommendationGift,
        },
        true,
      )
    }
  }

  React.useEffect(() => {
    showRecommendationGiftDetails()
    return () => {
      hideModal()
    }
  }, [recommendation_gift_id])

  return isLoading ? <TopBarLoading /> : null
}
