import * as React from 'react'
import { TechnicalOrdersOrderedTable } from '@modules/technical-orders/technical-orders-ordered/table'
import { DragDropContext } from 'react-beautiful-dnd'
import { ContentLoader } from '@components/content-loader'
import { useTechnicalOrderDnd } from '@modules/technical-orders/technical-orders-ordered/drag-and-drop/use-technical-order-dnd'

interface Props {
  resortId: number
}

export const TechnicalOrdersOrdered: React.FC<Props> = ({ resortId }) => {
  const [hasAppliedFilters, setHasAppliedFilters] = React.useState(false)
  const [highPriorityOrdersFiltered, setHighPriorityOrdersFiltered] = React.useState(false)

  const { onDragEnd, isReordering } = useTechnicalOrderDnd(resortId)

  const isDragDisabled = highPriorityOrdersFiltered || hasAppliedFilters

  return (
    <ContentLoader isLoading={isReordering}>
      <DragDropContext onDragEnd={onDragEnd}>
        <h4 className="text-danger">Zadania priorytetowe:</h4>
        <TechnicalOrdersOrderedTable
          resortId={resortId}
          priority={10}
          dataTestId="qa-table-technical-orders-high-priority"
          onFiltersChange={setHighPriorityOrdersFiltered}
          isDragDisabled={isDragDisabled}
        />
        <h4>Pozostałe zadania:</h4>
        <TechnicalOrdersOrderedTable
          resortId={resortId}
          priority={0}
          dataTestId="qa-table-technical-orders-normal-priority"
          onFiltersChange={setHasAppliedFilters}
          isDragDisabled={isDragDisabled}
        />
      </DragDropContext>
    </ContentLoader>
  )
}
