import * as React from 'react'
import { FormSelect } from '@hyper/forms/form-select'
import { CustomReactSelectOption, makeDefaultSelectOption } from '@components/custom-react-select'
import { IconWithText } from '@components/icon-with-text'
import classNames from 'classnames'
import { getServiceKindIcon } from '@modules/housekeeping/cleaning-orders/common/tools'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { CleaningOrder } from '@modules/housekeeping/models'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { useCleaningOrderUpdate } from '@modules/housekeeping/cleaning-orders/to-order/actions/use-order-update'
import { HousekeepingOrderServiceKindOptions } from '@modules/housekeeping/cleaning-orders/create-cleaning-order/form'
import { ButtonWithIcon } from '@components/button-with-icon'

interface Props {
  cleaningOrder: CleaningOrder
}

export const HousekeepingCleaningOrderSelectServiceKind: React.FC<Props> = ({ cleaningOrder }) => {
  const [isEditing, setIsEditing] = React.useState(false)

  const methods = useForm({
    defaultValues: {
      service_kind: makeDefaultSelectOption(HousekeepingOrderServiceKindOptions, cleaningOrder.service_kind),
    },
  })

  const { updateOrder } = useCleaningOrderUpdate()

  const activeServiceKind = useWatch({ control: methods.control, name: 'service_kind' })

  const updateServiceKind = async () => {
    await updateOrder(cleaningOrder, { service_kind: activeServiceKind?.value }, 'Zmieniono typ')
  }

  useDidUpdateEffect(() => {
    if (activeServiceKind?.value) updateServiceKind()
  }, [activeServiceKind?.value])

  return (
    <td className="housekeeping__orders_table__column" style={{ minWidth: 200, width: 200 }}>
      <FormProvider {...methods}>
        {isEditing ? (
          <FormSelect
            options={HousekeepingOrderServiceKindOptions}
            name="service_kind"
            formPlainProps={{ formGroupClassName: 'mb-0', colClassName: 'px-0' }}
            selectProps={{
              isSearchable: false,
              formatOptionLabel,
              onMenuClose: () => setIsEditing(false),
            }}
          />
        ) : (
          <ButtonWithIcon
            iconWrapperClassName="flex-row-reverse"
            icon="uil-edit-alt ml-2 font-14"
            text={activeServiceKind?.label ?? 'Brak'}
            btnClass="bg-transparent shadow-none border-0 text-default d-flex align-items-center btn btn-hover-muted"
            handleClick={() => setIsEditing(true)}
          />
        )}
      </FormProvider>
    </td>
  )
}

const formatOptionLabel = (data: CustomReactSelectOption) => (
  <button className="bg-transparent border-0 shadow-none">
    <IconWithText
      icon={classNames(getServiceKindIcon(data.value), 'font-18 mr-1')}
      text={data.label}
      textClass="lh-1"
      wrapperClassNames="text-default"
    />
  </button>
)
