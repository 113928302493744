import * as React from 'react'
import { Label } from 'reactstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { ColAuto } from '@hyper/col-auto'
import DateRangeInput from '@components/date/date-range-input'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { CommonTableFilters } from '@components/table/common-table-filters'
import { EcommerceStatsParams } from '@modules/ecommerce/models'

interface Props {
  setFilters: (filters: EcommerceStatsParams) => void
  defaultFilters: EcommerceStatsParams
  filters: EcommerceStatsParams
}

export const EcommerceStatsFilters: React.FC<Props> = ({ filters, setFilters, defaultFilters }) => {
  const methods = useForm<EcommerceStatsParams>({ defaultValues: defaultFilters })

  const onSubmit = (payload: EcommerceStatsParams) => setFilters({ ...filters, ...payload })

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  return (
    <FormProvider {...methods}>
      <CommonTableFilters defaultFilters={defaultFilters} filters={filters} setFilters={setFilters} withSearch={false}>
        <div className="d-flex mb-2">
          <ColAuto>
            <Label>Data utworzenia</Label>
            <DateRangeInput
              startDateName="sell_date_after"
              endDateName="sell_date_before"
              isClearable
              wrapperClassName="is-range"
            />
          </ColAuto>
        </div>
      </CommonTableFilters>
    </FormProvider>
  )
}
