import { RootState, useAppSelector } from '@store/index'
import { AuthenticatedUser, UserPermission } from '@models/dashboard'
import * as React from 'react'
import { Resort } from '@models/booking'
import { formatDate, parseISODate } from '@helpers/date-helper'
import { startOfToday } from 'date-fns'
import { PaymentDayUserReport } from '@models/payments'
import { ReceptionWorkstation } from '@models/reception'
import { useBandReader } from '@modules/reception/common/band-reader-select/use-band-reader'
import { useEservicePinPad } from '@modules/reception/common/eservice-pin-pad-select/use-eservice-pin-pad'
import { useTablet } from '@modules/reception/common/tablet-select/use-tablet'
import { useSellers } from '@components/hooks/use-grouped-sellers'
import { Seller } from '@models/crm'

interface Props {
  authenticatedUser: AuthenticatedUser
  userResorts: Resort[]
  isAuthenticated: boolean
  workStation: ReceptionWorkstation | undefined
  bandReaderId: number | undefined
  eservicePadId: number | undefined
  tabletId: number | undefined
  seller: Seller | undefined
}

export class User {
  private _userResorts: Resort[]
  private _isAuthenticated: boolean
  private _authenticatedUser: AuthenticatedUser
  private _workStation: ReceptionWorkstation | undefined
  private _band_reader_id: number | undefined
  private _eservice_pad_id: number | undefined
  private _tablet_id: number | undefined
  private _seller: Seller | undefined

  constructor({
    workStation,
    authenticatedUser,
    isAuthenticated,
    userResorts,
    bandReaderId,
    eservicePadId,
    tabletId,
    seller,
  }: Props) {
    this._workStation = workStation
    this._authenticatedUser = authenticatedUser
    this._isAuthenticated = isAuthenticated
    this._userResorts = userResorts
    this._band_reader_id = bandReaderId
    this._eservice_pad_id = eservicePadId
    this._tablet_id = tabletId
    this._seller = seller
  }

  hasPerm(permName: UserPermission | UserPermission[]): boolean {
    if (Array.isArray(permName)) {
      return this.is_superuser || permName.some(perm => this._authenticatedUser.permissions.includes(perm))
    } else {
      return this.is_superuser || this._authenticatedUser.permissions.includes(permName)
    }
  }

  get isHijacked(): boolean {
    return this._authenticatedUser.is_hijacked
  }

  get bandReaderId(): number | undefined {
    return this._band_reader_id
  }

  get isTechnicalOrderManager(): boolean {
    return this._authenticatedUser.is_technical_order_manager
  }

  get eservicePadId(): number | undefined {
    return this._eservice_pad_id
  }

  get seller(): Seller | undefined {
    return this._seller
  }

  get tabletId(): number | undefined {
    return this._tablet_id
  }

  get is_superuser(): boolean {
    return this._authenticatedUser.is_superuser
  }

  get isReceptionist(): boolean {
    return this._authenticatedUser.is_receptionist
  }

  get email(): string {
    return this._authenticatedUser.email
  }

  get dateJoined(): Date {
    return parseISODate(this._authenticatedUser.date_joined) || startOfToday()
  }

  get isAuthenticated(): boolean {
    return this._isAuthenticated
  }

  get resorts(): Resort[] {
    return this._userResorts
  }

  get resort_ids(): number[] {
    return this._authenticatedUser.resort_ids
  }

  get resort_ids_with_hq(): number[] {
    // bede sie kiedys za to smazyl w piekle, ale na szybko dodalem id centrali
    return [...this._authenticatedUser.resort_ids, 3]
  }

  get fullname(): string {
    return `${this._authenticatedUser.first_name} ${this._authenticatedUser.last_name}`
  }

  get showPaymentUserOpenDayModal(): boolean {
    if (this.paymentDayUserReport) {
      return this.paymentDayUserReport.status === 'not_started'
    }
    return false
  }

  get showPaymentUserCloseDayModal(): boolean {
    if (this.paymentDayUserReport) {
      return (
        this.paymentDayUserReport.status === 'open' &&
        formatDate(this.paymentDayUserReport.day_start) !== formatDate(startOfToday())
      )
    }
    return false
  }

  get receptionWorkstation(): ReceptionWorkstation | undefined {
    return this._workStation
  }

  get id(): number {
    return this._authenticatedUser.id
  }

  get paymentDayUserReport(): PaymentDayUserReport | null {
    return this._authenticatedUser.payment_day_user_report
  }
}

export const useAuthenticatedUser = (): User => {
  const authenticatedUser = useAppSelector((state: RootState) => state.appState.user)
  const availableResorts = useAppSelector((state: RootState) => state.appState.appData.resorts)
  const workstations = useAppSelector((state: RootState) => state.appState.appData.reception_workstations)

  const workStation: ReceptionWorkstation | undefined = React.useMemo(
    () => workstations.find(row => row.id === authenticatedUser.reception_workstation_id),
    [authenticatedUser.reception_workstation_id, workstations],
  )

  const getDeviceId = (sessionDeviceId, workstationField) =>
    sessionDeviceId ? parseInt(String(sessionDeviceId), 10) : workStation?.[workstationField]

  const bandReaderId = getDeviceId(useBandReader()[0], 'band_reader_id')
  const tabletId = getDeviceId(useTablet()[0], 'tablet_id')
  const eservicePadId = getDeviceId(useEservicePinPad()[0], 'eservice_pad_id')

  const userResorts = React.useMemo(
    () => availableResorts.filter(resort => authenticatedUser.resort_ids.includes(resort.id)),
    [authenticatedUser.resort_ids],
  )

  const sellers = useSellers()

  return React.useMemo(
    () =>
      new User({
        authenticatedUser,
        userResorts,
        isAuthenticated: authenticatedUser.id !== 0,
        workStation,
        bandReaderId,
        eservicePadId,
        tabletId,
        seller: sellers.find(row => row.id === authenticatedUser.seller_id),
      }),
    [authenticatedUser, userResorts, workStation, tabletId, eservicePadId, bandReaderId, sellers],
  )
}
