import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { formatPriceShort } from '@helpers/utils'
import classNames from 'classnames'
import { useModal } from '@components/modals/use-modal'
import { IconWithText } from '@components/icon-with-text'
import { useMetersRefresh } from '@modules/reception/common/reception-booking-check-bill-details/use-meters-refresh'

interface ReceptionBookingCheckBillDetailsMediaProps {
  booking: ReceptionBookingDetails
  defaultIsOpen: boolean
  readOnly?: boolean
  allowMetersChange?: boolean
}

export const ReceptionBookingCheckBillDetailsMedia: React.FC<ReceptionBookingCheckBillDetailsMediaProps> = ({
  booking,
  defaultIsOpen,
  readOnly,
  allowMetersChange,
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [showMetersDetailsDialog] = useModal('ReceptionMetersReadingDialog')
  const { getFreshBooking, isRefreshing } = useMetersRefresh(booking)

  const handleOpen = () => setIsOpen(!isOpen)

  React.useEffect(() => {
    setIsOpen(defaultIsOpen)
  }, [defaultIsOpen])

  const fields = [
    ['media_water_brutto', 'Woda'],
    ['media_hot_water_brutto', 'Ciepła woda'],
    ['media_power_brutto', 'Prąd'],
    ['media_heat_brutto', 'Ogrzewanie'],
  ]

  const hasBillPreview = booking.status === 'confirmed' && booking.checked_in && !booking.checked_out_online

  const showMetersDialog = async () => {
    showMetersDetailsDialog(null, { booking: readOnly ? booking : await getFreshBooking(), allowMetersChange })
  }

  return (
    <>
      <tr className="reception__booking-check-out__bill-step__table__row">
        <td className="reception__booking-check-out__bill-step__table__column text-grey text-semi-strong">
          Media
          {(allowMetersChange || hasBillPreview) && (
            <span className="text-muted ml-1 text-semi-strong cursor-pointer" onClick={showMetersDialog}>
              <span className="font-10">
                {isRefreshing ? (
                  'Ładowanie danych...'
                ) : allowMetersChange ? (
                  <IconWithText icon="uil-pen font-12" text="zmień odczyt" />
                ) : (
                  hasBillPreview && <IconWithText icon="uil-eye" text="zobacz odczyty" />
                )}
              </span>
            </span>
          )}
        </td>
        <td className="reception__booking-check-out__bill-step__table__column text-right reception__booking-check-out__bill-step__table__column--is-costs">
          {formatPriceShort(booking.media_brutto)}
        </td>
        <td className="reception__booking-check-out__bill-step__table__column" />
        {!readOnly && (
          <td className="reception__booking-check-out__bill-step__table__column text-right p-0">
            <i
              onClick={handleOpen}
              className={classNames('font-18 cursor-pointer', {
                'uil-angle-right': !isOpen,
                'uil-angle-down': isOpen,
              })}
            />
          </td>
        )}
      </tr>

      {isOpen && (
        <>
          {fields.map(option => (
            <tr
              key={option[0]}
              className="reception__booking-check-out__bill-step__table__row reception__booking-check-out__bill-step__table__row--is-sub"
            >
              <td className="reception__booking-check-out__bill-step__table__column text-gray reception__booking-check-out__bill-step__table__column--is-sub">
                {option[1]}
              </td>
              <td className="reception__booking-check-out__bill-step__table__column text-right text-gray">
                {formatPriceShort(booking[option[0]])}
              </td>
              <td className="reception__booking-check-out__bill-step__table__column" />
              {!readOnly && <td className="reception__booking-check-out__bill-step__table__column" />}
            </tr>
          ))}
        </>
      )}
    </>
  )
}
