import * as React from 'react'
import { BaseSyntheticEvent } from 'react'
import { BookingExtendedStayKind, ReceptionBooking, ReceptionBookingDetails } from '@models/reception'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { ReceptionBookingExtendedStayContainer } from '@modules/reception/extended-stay/reception-booking-extended-stay-container'
import { FormProvider, useForm } from 'react-hook-form'
import { ReceptionBookingExtendedStayContent } from '@modules/reception/extended-stay/reception-booking-extended-stay-content'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useFormRequest } from '@components/hooks/use-api-request'
import { TopBarLoading } from '@components/topbar-loading'
import { BaseModalProps } from '@components/modals/types'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { useExtendedStay } from '@modules/reception/to-checkout/row/use-extended-stay'
import { useExtendedStayCollision } from '@modules/reception/extended-stay/use-extended-stay-collision'

interface ReceptionBookingExtendedStayDialogProps extends BaseModalProps {
  booking: ReceptionBookingDetails | ReceptionBooking
}

export interface ExtendedStayFormInputs {
  kind: BookingExtendedStayKind
  date_to: Date | null
  custom_apartment_day_price: null | string
  with_custom_stay_price: boolean
}

export const ReceptionBookingExtendedStayDialog: React.FC<ReceptionBookingExtendedStayDialogProps> = ({
  booking,
  toggleIsVisible,
}) => {
  const { addSuccessMessage } = useNotificationHook()
  const { submit } = useExtendedStay(booking)
  const { showCollisionModal, checkExtendingCollision } = useExtendedStayCollision()

  const methods = useForm<ExtendedStayFormInputs>({
    defaultValues: {
      kind: BookingExtendedStayKind.TWELVE_O_CLOCK,
      custom_apartment_day_price: null,
      with_custom_stay_price: false,
    },
  })

  const isDailyExtension = (kind: BookingExtendedStayKind) => kind === BookingExtendedStayKind.DAYS

  const extendStay = async (payload: ExtendedStayFormInputs, event: BaseSyntheticEvent) => {
    await submit(payload)

    const { extendedStayHours } = event.target.dataset

    addSuccessMessage(
      'Rezerwacja została przedłużona!',
      isDailyExtension(payload.kind)
        ? `Rezerwacja została przedłużona do ${toDefaultDateFormat(payload.date_to)}`
        : `Rezerwacja została przedłużona dzisiaj do godziny ${extendedStayHours || ''}`,
    )
    toggleIsVisible()
  }

  const { isLoading, action: onSubmit } = useFormRequest(
    async (payload: ExtendedStayFormInputs, event: BaseSyntheticEvent) => {
      const { collisionBooking } = await checkExtendingCollision('extend_stay_12')

      if (collisionBooking && !isDailyExtension(payload.kind)) {
        showCollisionModal('extend_stay_12', collisionBooking, () => extendStay(payload, event))
        return
      }

      await extendStay(payload, event)
    },
    methods.setError,
    { showGlobalError: true, excludeFields: ['non_field_errors'] },
  )

  return (
    <FormProvider {...methods}>
      <ReceptionBookingExtendedStayContainer
        booking={booking}
        render={({ options, totalPrice, selectedOption, isLoading: isOptionsLoading, onOptionsChange }) => (
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            autoComplete="off"
            data-extended-stay-hours={selectedOption?.hour}
          >
            {isOptionsLoading && <TopBarLoading />}
            <ModalHeader toggle={toggleIsVisible}>
              <span className="text-semi-strong">
                Przedłużenie rezerwacji <strong className="font-18">{booking.reservation_number}</strong>
              </span>
            </ModalHeader>
            <ModalBody>
              <ReceptionBookingExtendedStayContent
                onOptionsChange={onOptionsChange}
                options={options}
                totalPrice={totalPrice}
                booking={booking}
              />
            </ModalBody>
            <ModalFooter>
              <Button color="light" onClick={toggleIsVisible}>
                Anuluj
              </Button>
              <SaveButton
                disabled={!selectedOption?.available}
                className="btn btn-green"
                isSaving={isLoading}
                label="Przedłuż"
                labelSaving="Przedłużanie..."
              />
            </ModalFooter>
          </form>
        )}
      />
    </FormProvider>
  )
}
