import * as React from 'react'
import { ReceptionBookingCheckOutAccessItem } from '@modules/reception/checkout/step-keys/reception-booking-check-out-access-item'
import { ReceptionBookingDetails } from '@models/reception'
import { useForm } from 'react-hook-form'
import { Row } from 'reactstrap'
import { ReceptionBookingCheckOutStepKeysInfoRow } from '@modules/reception/checkout/step-keys/reception-booking-check-out-step-keys-info-row'
import { FormInputNumber } from '@hyper/forms/form-input-number'
import { updateServiceRoomData } from '@store/actions/reception-actions'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { AlertDanger } from '@components/alerts'
import { formatPriceShort } from '@helpers/utils'
import { useFormRequest } from '@components/hooks/use-api-request'
import { Form } from '@hyper/forms/form'

interface FormInputs {
  check_keys: boolean
  check_prices: boolean
  bands_returned: number | string
}

interface ReceptionBookingCheckOutStepBandsProps {
  nextStep: () => void
  booking: ReceptionBookingDetails
  onBandLostChange?: (isBandLost: boolean) => void
  title?: string
}

export const ReceptionBookingCheckOutStepBands: React.FC<ReceptionBookingCheckOutStepBandsProps> = ({
  title = '1. Zwrot wydanych opasek',
  nextStep,
  booking,
  onBandLostChange,
}) => {
  const methods = useForm<FormInputs>({
    defaultValues: {
      bands_returned: '',
    },
  })

  const dispatch = useAppDispatch()

  const lostBandCharge = useAppSelector(
    (state: RootState) => state.appState.appData.configuration.reservation_booking_damage_bands_price_brutto,
  )

  const reminders = [
    {
      id: 'check_bands',
      isClosable: true,
      children: (
        <span>
          Pamiętaj, aby sprawdzić <strong>czy wszystkie opaski zostały zwrócone.</strong>
        </span>
      ),
    },
    {
      id: 'check_prices',
      isClosable: true,
      children: (
        <span>
          Opłaty za zgubienie <strong>opaski ({formatPriceShort(lostBandCharge)})</strong>
        </span>
      ),
    },
  ]

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    const bands_returned =
      typeof payload.bands_returned === 'string' ? parseInt(payload.bands_returned, 10) : payload.bands_returned
    await dispatch(updateServiceRoomData(booking.urls.check_out_step_keys, { bands_returned }))
    nextStep()
  }, methods.setError)

  const selectedBands = parseInt(String(methods.watch('bands_returned')), 10)

  React.useEffect(() => {
    methods.setFocus('bands_returned')
  }, [])

  React.useEffect(() => {
    onBandLostChange?.(selectedBands < booking.service_room_data.bands)
  }, [selectedBands])

  return (
    <Form methods={methods} onSubmit={onSubmit} role="step-keys">
      <ReceptionBookingCheckOutAccessItem
        modalTitle={title}
        reminders={reminders}
        accessItemIcon="icon-opaska"
        nextStep={onSubmit}
        isSaving={isLoading}
      >
        <>
          <strong className="font-18 d-block text-dark mb-3">Odbierz opaski od Gości</strong>
          <ReceptionBookingCheckOutStepKeysInfoRow name="Numer lokalu:" value={booking.apartment.name} />
          <ReceptionBookingCheckOutStepKeysInfoRow
            name="Wydanych opasek:"
            value={booking.service_room_data.bands || 0}
          />
          <Row className="mt-3">
            <FormInputNumber
              label="Ilość zwróconych opasek"
              colSize={5}
              name="bands_returned"
              minValue={0}
              maxValue={booking.service_room_data.bands}
            />
            {selectedBands < (booking.service_room_data.bands || 0) && (
              <AlertDanger>
                <strong>Opłaty za zgubienie jednej opaski to {formatPriceShort(lostBandCharge)}.</strong>
              </AlertDanger>
            )}
          </Row>
        </>
      </ReceptionBookingCheckOutAccessItem>
    </Form>
  )
}
