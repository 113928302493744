import * as React from 'react'
import { TableFilters } from '@components/table/table'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { TechnicalOrdersTodoFilters } from '@modules/technical-orders/technical-orders-todo/filters'
import { Col, Label } from 'reactstrap'

interface Props {
  filters: TableFilters
  defaultFilters: TableFilters
  setFilters: (filters: TableFilters) => void
}

export const TechnicalOrdersDoneTableFilters: React.FC<Props> = ({ filters, defaultFilters, setFilters }) => (
  <TechnicalOrdersTodoFilters
    filters={filters}
    defaultFilters={defaultFilters}
    setFilters={setFilters}
    searchClassName="ml-auto"
  >
    <Col md={6}>
      <div className="d-flex align-items-center flex-wrap">
        <Label>Zakres dat:</Label>
        <div style={{ width: 350 }}>
          <DateFromToInput label="" startDateName="finished_at_after" endDateName="finished_at_before" />
        </div>
      </div>
    </Col>
  </TechnicalOrdersTodoFilters>
)
