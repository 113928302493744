import * as React from 'react'
import { BookingTouristVoucher, ReceptionBookingDetails } from '@models/reception'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAppDispatch } from '@store/index'
import { useApiRequest } from '@components/hooks/use-api-request'
import { updateReceptionBookingDetails } from '@store/actions/reception-actions'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'

interface Props {
  touristVoucher: BookingTouristVoucher
}

export const ReservationTouristVouchersRowRemove = ({ touristVoucher }: Props): JSX.Element => {
  const { addSuccessNotification } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading, action: handleAccept } = useApiRequest(async () => {
    dispatch(
      updateReceptionBookingDetails(await commonObjectDelete<ReceptionBookingDetails>(touristVoucher.urls.details)),
    )
    addSuccessNotification('Bon został skasowany została skasowana')
  })

  return (
    <CommonObjectConfirmAction handleAccept={handleAccept} isLoading={isLoading}>
      <i className="uil-trash-alt cursor-pointer" title="Skasuj" />
    </CommonObjectConfirmAction>
  )
}
