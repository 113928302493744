import * as React from 'react'
import { Button, ModalFooter, ModalHeader } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { SaveButton } from '@hyper/button'
import { useForm } from 'react-hook-form'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { BaseModalProps } from '@components/modals/types'
import { Form } from '@hyper/forms/form'
import { useFormRequest } from '@components/hooks/use-api-request'
import { useAppData } from '@components/hooks/use-app-data'
import * as R from 'ramda'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { commonObjectPost, commonObjectPut } from '@store/actions/generic-actions'
import { ReceptionistUser } from '@models/account'
import { updateReceptionistUserDetails } from '@store/slices/account-slice'
import { ReceptionistUserModalForm } from '@modules/account/receptionist-users/modal/form'
import { useResortSelectOptions } from '@components/hooks/use-resort-select-options'

interface FormInputs {
  first_name: string
  last_name: string
  email: string
  resort: CustomReactSelectOption | undefined
  phone: string
  is_active: boolean
  password: string
  confirmed_password: string
}

interface Props extends BaseModalProps {
  user?: ReceptionistUser
}

export const ReceptionistUserModal: React.FC<Props> = ({ toggleIsVisible, user }) => {
  const { urls } = useAppData()
  const resortOptions = useResortSelectOptions()

  const { addSuccessNotification } = useNotificationHook()
  const dispatch = useAppDispatch()
  const methods = useForm<FormInputs>({
    mode: 'all',
    defaultValues: {
      ...R.pick<FormInputs, any>(['first_name', 'last_name', 'is_active', 'phone'], {
        ...user,
      }),
      resort: user ? resortOptions.find(row => user.resorts.includes(row.value)) : [],
    },
  })

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    const data = {
      ...payload,
      resort: payload.resort?.value,
    }
    if (user) {
      dispatch(updateReceptionistUserDetails(await commonObjectPut<ReceptionistUser>(user.urls.details, data)))
      addSuccessNotification('Zmiany zostały zapisane')
    } else {
      dispatch(
        updateReceptionistUserDetails(await commonObjectPost<ReceptionistUser>(urls.account.receptionist_users, data)),
      )
      addSuccessNotification('Pracownik został dodany')
    }
    toggleIsVisible()
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>
        {user ? `Edytuj pracownika: ${user.first_name} ${user.last_name}` : 'Dodaj pracownika'}
      </ModalHeader>
      <ModalBody>
        <ReceptionistUserModalForm user={user} resortOptions={resortOptions} />
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
