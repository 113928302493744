import * as React from 'react'
import { AgreementDetails } from '@modules/hr/agreement/models'
import { Card, CardBody } from 'reactstrap'
import { AgreementAnnexCard } from '@modules/hr/agreement/details/annexes/annex-card'
import { AgreementAnnexDropdown } from '@modules/hr/agreement/annexes/annex-dropdown'
import { IconWithText } from '@components/icon-with-text'
import { canAddAgreementAnnex } from '@modules/hr/agreement/annexes/helpers'

interface Props {
  agreementDetails: AgreementDetails
}

export const AgreementDetailsAnnexes = React.forwardRef(
  ({ agreementDetails }: Props, ref: React.RefObject<HTMLElement>) => (
    <Card innerRef={ref}>
      <CardBody>
        <div className=" d-flex align-items-center justify-content-between">
          <h4 className="mt-0 text-secondary">Aneksy</h4>
          {canAddAgreementAnnex(agreementDetails) && (
            <AgreementAnnexDropdown
              direction="down"
              agreement={agreementDetails}
              trigger={
                <div className="btn btn-green">
                  <IconWithText icon="uil-plus" text="Dodaj dokument" />
                </div>
              }
            />
          )}
        </div>
        {agreementDetails.annexes.map(annex => (
          <AgreementAnnexCard key={annex.id} annex={annex} agreement={agreementDetails} />
        ))}
        {!agreementDetails.annexes.length && <p className="mb-0">Brak dodanych aneksów do umowy.</p>}
      </CardBody>
    </Card>
  ),
)
