import * as React from 'react'
import { useDocumentTitle } from '@helpers/utils'
import { PageTitle } from '@hyper/page-title'
import { NavigationPath } from '@models/routes'
import { Card, CardBody } from 'reactstrap'
import { CmsAssetCategoriesCreateButton } from '@modules/cms/assets/categories/create-button'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useAppDispatch } from '@store/index'
import { CmsAssetCategoriesTable } from '@modules/cms/assets/categories/table'
import { getAssetCategories } from '@store/actions/asset-actions'

export const CmsAssetCategoriesView = (): JSX.Element => {
  const pageTitle = 'Kategorie assetów'
  useDocumentTitle(pageTitle)
  const dispatch = useAppDispatch()

  const { isLoading, action: fetch } = useApiRequest(async () => {
    await dispatch(getAssetCategories())
  })

  React.useEffect(() => {
    fetch()
  }, [])

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: 'Assety', path: NavigationPath.CmsAssets, active: false },
          { label: pageTitle, path: '', active: true },
        ]}
        title={pageTitle}
      />
      <Card>
        <CardBody>
          <div className="text-right mb-3">
            <CmsAssetCategoriesCreateButton />
          </div>
          <CmsAssetCategoriesTable isLoading={isLoading} />
        </CardBody>
      </Card>
    </>
  )
}
