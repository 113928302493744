import { commonObjectGet } from '@store/actions/generic-actions'
import { ClientDetails } from '@models/clients'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useAppData } from '@components/hooks/use-app-data'

interface Response {
  fetchClientDetails: (clientId: number) => Promise<ClientDetails>
  isFetching: boolean
}

export const useClientDetails = (): Response => {
  const { urls } = useAppData()

  const { action: fetchClientDetails, isLoading } = useApiRequest(
    async (clientId: number) => await commonObjectGet<ClientDetails>(`${urls.clients.clients}${clientId}/`),
  )

  return { fetchClientDetails, isFetching: isLoading }
}
