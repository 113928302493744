import * as React from 'react'
import timelineHelper from '@helpers/timeline-helper'
import { CalendarDate } from '@store/reducers/timeline-reducers'
import dateHelper from '@helpers/date-helper'
import { isThisMonth, parseISO } from 'date-fns'
import { RootState, useAppSelector } from '@store/index'

interface Props {
  renderedDates: CalendarDate[]
  colHeight: string
  colWidth: string
}

const TimelineCols: React.FC<Props> = props => {
  const selectedCols = useAppSelector((state: RootState) => state.timelineState.selectedCols)

  const isTodayPresent = (): boolean =>
    props.renderedDates.some(({ year, month }) => isThisMonth(parseISO(dateHelper.createDateFormat(year, month, 1))))

  const getTodayPos = (): string =>
    timelineHelper.getTodayLeftOffset(props.renderedDates[0].year, props.renderedDates[0].month)

  const getFirstDayPos = (date: CalendarDate): string =>
    timelineHelper.getFirstDayLeftOffset(props.renderedDates[0], date)

  return (
    <div className="calendar-content__cols" data-testid="calendar-col">
      {isTodayPresent() && (
        <div
          className="calendar-content__col is-today"
          style={{ width: props.colWidth, height: props.colHeight, left: getTodayPos() }}
        />
      )}
      {props.renderedDates.map(date => (
        <div
          key={date.month}
          data-testid="calendar-col-first"
          className="calendar-content__col is-first"
          style={{ width: props.colWidth, height: props.colHeight, left: getFirstDayPos(date) }}
        />
      ))}
      {selectedCols.map((date: string) => (
        <div
          key={date}
          data-testid="calendar-col-selected"
          className="calendar-content__col is-selected"
          style={{
            width: props.colWidth,
            height: props.colHeight,
            left: timelineHelper.getSelectedColLeftOffset(
              props.renderedDates[0].year,
              props.renderedDates[0].month,
              date,
            ),
          }}
        />
      ))}
    </div>
  )
}

export default TimelineCols
