import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { ReceptionBookingDetails } from '@models/reception'
import { SaveButton } from '@hyper/button'
import { CustomInput, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useFormRequest } from '@components/hooks/use-api-request'
import { FormInput, FormPlain } from '@hyper/forms'
import { extractInnerRef } from '@helpers/forms'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { useAppDispatch } from '@store/index'
import { updateReceptionBookingDetails } from '@store/actions/reception-actions'
import { commonObjectPut } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'

interface Props extends BaseModalProps {
  booking: ReceptionBookingDetails
}
interface FormInputs {
  code: string
  can_ignore_dates_restrictions: boolean
  allow_split_code: boolean
  ignore_localization: boolean
}
export const ReservationPromocodeModal: React.FC<Props> = ({ booking, toggleIsVisible }) => {
  const methods = useForm<FormInputs>({ defaultValues: { allow_split_code: true } })
  const user = useAuthenticatedUser()
  const dispatch = useAppDispatch()
  const canIgnoreDatesRestrictions = useWatch({ control: methods.control, name: 'can_ignore_dates_restrictions' })
  const { addSuccessMessage } = useNotificationHook()

  const { isLoading, action: onSubmit } = useFormRequest(
    async (payload: FormInputs) => {
      const result = await commonObjectPut<{ text: string; booking: ReceptionBookingDetails }>(
        booking.urls.reservation_booking_promocode,
        payload,
      )
      dispatch(updateReceptionBookingDetails(result.booking))

      addSuccessMessage('Sukces', result.text)
      toggleIsVisible()
    },

    methods.setError,
    { showGlobalError: true },
  )

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggleIsVisible}>Dodaj kod promocyjny</ModalHeader>
        <ModalBody>
          <Row>
            <FormInput label="Kod promocyjny" name="code" />
            {user.hasPerm(UserPermission.BookingCanIgnoreDatesRestrictions) && (
              <FormPlain name="can_ignore_dates_restrictions">
                <CustomInput
                  type="checkbox"
                  {...extractInnerRef(methods.register('can_ignore_dates_restrictions'))}
                  id="can_ignore_dates_restrictions"
                  label="Ignoruj ograniczenia dat"
                />
              </FormPlain>
            )}
            {user.hasPerm(UserPermission.BookingCanIgnoreLocalizationRestrictions) && (
              <FormPlain name="ignore_localization">
                <CustomInput
                  type="checkbox"
                  {...extractInnerRef(methods.register('ignore_localization'))}
                  id="ignore_localization"
                  label="Ignoruj ograniczenia lokalizacji"
                />
              </FormPlain>
            )}
            {user.hasPerm(UserPermission.BookingCanIgnoreDatesRestrictions) && canIgnoreDatesRestrictions && (
              <FormPlain name="allow_split_code">
                <CustomInput
                  type="checkbox"
                  {...extractInnerRef(methods.register('allow_split_code'))}
                  id="allow_split_code"
                  label="Pozwól na dzielenie kodów"
                />
              </FormPlain>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <button type="button" onClick={toggleIsVisible} className="btn btn-light">
            Anuluj
          </button>
          <SaveButton isSaving={isLoading} className="btn btn-green" />
        </ModalFooter>
      </form>
    </FormProvider>
  )
}
