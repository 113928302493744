import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { asDecimal, formatPriceShort } from '@helpers/utils'
import ReceptionBookingCheckBillDetailsImprovements from '@modules/reception/common/reception-booking-check-bill-details/step-bill-details-improvments'
import { ReceptionBookingCheckBillDetailsMedia } from '@modules/reception/common/reception-booking-check-bill-details/step-bill-details-media'
import ReceptionBookingCheckBillDetailsPayments from '@modules/reception/common/reception-booking-check-bill-details/step-bill-details-payments'
import classNames from 'classnames'
import Decimal from 'decimal.js'
import { useToggle } from '@components/hooks/use-toggle'
import StepBillDetailsExtraCharges from '@modules/reception/common/reception-booking-check-bill-details/step-bill-details-extra-charges'

interface Props {
  booking: ReceptionBookingDetails
  isOpen: boolean
  restToPay: Decimal
  readOnly?: boolean
  title: string
  allowMetersChange?: boolean
}

export const ReceptionBookingCheckBillDetails: React.FC<Props> = ({
  booking,
  isOpen,
  restToPay,
  readOnly,
  title,
  allowMetersChange,
}) => {
  const [defaultIsOpen, handleToggleIsOpen] = useToggle(isOpen)

  const finalBrutto = asDecimal(booking.final_brutto)

  return (
    <>
      <div className="d-flex mb-2 justify-content-between align-content-end">
        <strong className="font-14 text-strong">{title}</strong>
        <span className="text-muted cursor-pointer font-10" onClick={handleToggleIsOpen}>
          {defaultIsOpen ? 'zwiń' : 'rozwiń'} wszystko
          <i
            className={classNames('ml-1', {
              'uil-minus': defaultIsOpen,
              'uil-plus': !defaultIsOpen,
            })}
          />
        </span>
      </div>
      <table className="reception__booking-check-out__bill-step__table">
        <tbody>
          <tr className="reception__booking-check-out__bill-step__table__row">
            <td className="reception__booking-check-out__bill-step__table__column text-grey text-semi-strong">Pobyt</td>
            <td className="reception__booking-check-out__bill-step__table__column text-right reception__booking-check-out__bill-step__table__column--is-costs">
              {formatPriceShort(booking.residence_brutto)}
            </td>
            <td width={25} className="reception__booking-check-out__bill-step__table__column" />
            {!readOnly && <td width={25} className="reception__booking-check-out__bill-step__table__column" />}
          </tr>

          <ReceptionBookingCheckBillDetailsImprovements
            booking={booking}
            defaultIsOpen={defaultIsOpen}
            readOnly={readOnly}
          />

          <tr className="reception__booking-check-out__bill-step__table__row">
            <td className="reception__booking-check-out__bill-step__table__column text-grey text-semi-strong">
              Wyżywienie
            </td>
            <td className="reception__booking-check-out__bill-step__table__column text-right reception__booking-check-out__bill-step__table__column--is-costs">
              {formatPriceShort(booking.feedings_2_0_brutto)}
            </td>
            <td className="reception__booking-check-out__bill-step__table__column" />
            {!readOnly && <td className="reception__booking-check-out__bill-step__table__column" />}
          </tr>

          <tr className="reception__booking-check-out__bill-step__table__row">
            <td className="reception__booking-check-out__bill-step__table__column text-grey text-semi-strong">
              Zakupy na pokój
            </td>
            <td className="reception__booking-check-out__bill-step__table__column text-right reception__booking-check-out__bill-step__table__column--is-costs">
              {formatPriceShort(booking.wallet_brutto)}
            </td>
            <td className="reception__booking-check-out__bill-step__table__column" />
            {!readOnly && <td className="reception__booking-check-out__bill-step__table__column" />}
          </tr>
          <ReceptionBookingCheckBillDetailsMedia
            allowMetersChange={allowMetersChange}
            booking={booking}
            defaultIsOpen={defaultIsOpen}
            readOnly={readOnly}
          />

          <StepBillDetailsExtraCharges
            extraCharges={booking.prices.extra_charges}
            defaultIsOpen={defaultIsOpen}
            readOnly={readOnly}
          />

          <tr className="reception__booking-check-out__bill-step__table__row">
            <td className="reception__booking-check-out__bill-step__table__column text-grey text-semi-strong">
              Opłata klimatyczna
            </td>
            <td className="reception__booking-check-out__bill-step__table__column text-right reception__booking-check-out__bill-step__table__column--is-costs">
              {formatPriceShort(booking.climatic_tax_brutto)}
            </td>
            <td className="reception__booking-check-out__bill-step__table__column" />
            {!readOnly && <td className="reception__booking-check-out__bill-step__table__column" />}
          </tr>

          <tr className="reception__booking-check-out__bill-step__table__row">
            <td className="reception__booking-check-out__bill-step__table__column text-grey text-semi-strong">
              Opłata eksploatacyjna za części wspólne
            </td>
            <td className="reception__booking-check-out__bill-step__table__column text-right reception__booking-check-out__bill-step__table__column--is-costs">
              {formatPriceShort(booking.service_charge_brutto)}
            </td>
            <td className="reception__booking-check-out__bill-step__table__column" />
            {!readOnly && <td className="reception__booking-check-out__bill-step__table__column" />}
          </tr>

          {!!booking.damages.length && (
            <tr className="reception__booking-check-out__bill-step__table__row">
              <td className="reception__booking-check-out__bill-step__table__column text-grey text-semi-strong">
                Straty / zniszczenia
              </td>
              <td className="reception__booking-check-out__bill-step__table__column text-right reception__booking-check-out__bill-step__table__column--is-costs">
                {formatPriceShort(booking.damages_brutto)}
              </td>
              <td className="reception__booking-check-out__bill-step__table__column" />
              {!readOnly && <td className="reception__booking-check-out__bill-step__table__column" />}
            </tr>
          )}
          <tr className="reception__booking-check-out__bill-step__table__row bg-platinum">
            <td className="reception__booking-check-out__bill-step__table__column text-grey">Razem</td>
            <td className="reception__booking-check-out__bill-step__table__column text-right reception__booking-check-out__bill-step__table__column--is-costs">
              {formatPriceShort(finalBrutto.toString())}
            </td>
            <td className="reception__booking-check-out__bill-step__table__column" />
            {!readOnly && <td className="reception__booking-check-out__bill-step__table__column" />}
          </tr>

          <ReceptionBookingCheckBillDetailsPayments
            readOnly={readOnly}
            label="Wpłaty"
            isDeposit={false}
            rowClassName="bg-platinum"
            defaultIsOpen={defaultIsOpen}
          />

          <tr className="reception__booking-check-out__bill-step__table__row bg-platinum text-strong">
            <td className="reception__booking-check-out__bill-step__table__column text-grey">Pozostało do zapłaty</td>
            <td className="reception__booking-check-out__bill-step__table__column text-right">
              {formatPriceShort(restToPay.toString())}
            </td>
            <td className="reception__booking-check-out__bill-step__table__column" />
            {!readOnly && <td className="reception__booking-check-out__bill-step__table__column" />}
          </tr>
        </tbody>
      </table>
    </>
  )
}
