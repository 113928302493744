import * as React from 'react'
import Table from '@components/table/table'
import { useModal } from '@components/modals/use-modal'
import { CopyToClipboard } from '@components/copy-to-clipboard'
import { PackageCode, PackageDetails } from '@models/package'

interface Props {
  packageCode: PackageCode
  packageDetails: PackageDetails
}

export const PackageDetailsCodesRowCode: React.FC<Props> = ({ packageCode, packageDetails }) => {
  const [handleEditCode] = useModal('PackageCodeEditModal', { packageCode, packageDetails })

  return (
    <Table.Cell className="align-middle">
      <span onClick={handleEditCode} className="cursor-pointer">
        {packageCode.code}
      </span>
      <CopyToClipboard className="uil-share-alt ml-1" value={packageCode.code} />
    </Table.Cell>
  )
}
