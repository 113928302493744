import * as React from 'react'
import { Button } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'

export const PromocodeTouristVoucherAdd: React.FC = () => {
  const [handleClick] = useModal('PromocodeTouristVoucherCreateDialog')

  return (
    <Button onClick={handleClick} color="green-dark" type="button">
      <i className="mdi mdi-plus-circle mr-1" />
      Dodaj voucher
    </Button>
  )
}
