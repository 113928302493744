import * as React from 'react'
import { FeedingHosFormInputs } from '@modules/feeding/host/feeding-host-with-params-view'
import { FeedingHostGuestsModal } from '@modules/feeding/host/guests-modal/index'
import { BaseModalProps } from '@components/modals/types'

interface Props extends BaseModalProps {
  values: FeedingHosFormInputs
}

export const FeedingHostSpecialGuestsModal: React.FC<Props> = ({ toggleIsVisible, values }) => (
  <FeedingHostGuestsModal values={values} toggleIsVisible={toggleIsVisible} guestsKind="special" />
)
