import * as React from 'react'
import { ReceptionBooking } from '@models/reception'
import { Button, ModalFooter, ModalHeader } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { formatDate } from '@helpers/date-helper'
import { BaseModalProps } from '@components/modals/types'

interface Props extends BaseModalProps {
  booking: ReceptionBooking
  handleConfirm: () => void
}

export const ReceptionBookingCheckInDateConfirmDialog: React.FC<Props> = ({
  booking,
  handleConfirm,
  toggleIsVisible,
}) => {
  const handleConfirmButton = () => {
    toggleIsVisible()
    handleConfirm()
  }

  return (
    <>
      <ModalHeader toggle={toggleIsVisible} className="bg-warning text-white">
        Potwierdź meldowanie gościa
      </ModalHeader>
      <ModalBody>
        <strong className="d-block">
          Data rozpoczęcia pobytu ({formatDate(booking.date_from, 'd MMMM yyyy')}) nie zaczyna się dzisiaj.
        </strong>
        Potwierdź, że chcesz zameldować gościa pomimo innego terminu
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={toggleIsVisible}>
          Anuluj
        </Button>
        <Button className="btn btn-warning" onClick={handleConfirmButton}>
          Potwierdź
        </Button>
      </ModalFooter>
    </>
  )
}
