import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { Button } from 'reactstrap'
import { ReservationDropdown } from '@modules/reservations/details/common/reservation-dropdown'
import { IconWithText } from '@components/icon-with-text'
import { NavigationPath } from '@models/routes'
import { useNavigate } from 'react-router-dom'

interface GuestReservationDataActionsProps {
  booking: ReceptionBookingDetails
}

export const ReservationGuestDataActions: React.FC<GuestReservationDataActionsProps> = ({ booking }) => {
  const navigate = useNavigate()

  const showClientDetails = () =>
    navigate(NavigationPath.CrmClientsWitParams.replace(':client_id', String(booking.client_id)))

  return (
    <div className="d-flex justify-content-end">
      <Button color="light" className="mr-2">
        <i className="uil-edit-alt font-16 lh-initial text-default" />
      </Button>

      <ReservationDropdown>
        <ReservationDropdown.Item onClick={showClientDetails}>
          <IconWithText icon="uil-edit-alt" text="Edytuj dane Gościa" textClass="font-11 fw-semi-bold" />
        </ReservationDropdown.Item>
        <ReservationDropdown.Item onClick={showClientDetails}>
          <IconWithText icon="uil-eye" text="Pokaż profil Gościa" textClass="font-11 fw-semi-bold" />
        </ReservationDropdown.Item>
      </ReservationDropdown>
    </div>
  )
}
