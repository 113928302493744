import * as React from 'react'
import { Button, ModalBody } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import { ReceptionBookingDetails } from '@models/reception'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectGet, commonObjectPost } from '@store/actions/generic-actions'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { receptionBookingDetailsSelector } from '@store/selectors/reception'
import { useAppData } from '@components/hooks/use-app-data'
import { useAppDispatch, useAppSelector } from '@store/index'
import { SaveButton } from '@hyper/button'
import { setReceptionBookingDetails } from '@store/slices/reception-slice'

export interface Props extends BaseModalProps {
  booking: ReceptionBookingDetails
  onSigningComplete: () => void
  tabletId?: number
}

export const ReceptionBookingCheckInStepDocumentsSigningInProgressModal: React.FC<Props> = ({
  booking,
  toggleIsVisible,
  onSigningComplete,
  tabletId,
}) => {
  const [isRefreshed, setIsRefreshed] = React.useState(false)

  const appData = useAppData()
  const dispatch = useAppDispatch()

  const user = useAuthenticatedUser()
  const receptionBookingDetails = useAppSelector(receptionBookingDetailsSelector)

  const { action: closeTabletSignature } = useApiRequest(async () => {
    await commonObjectPost(appData.urls.reception.reset_kiosk, { tablet: tabletId ?? user.tabletId })
    toggleIsVisible()
  })

  const { action: refreshBookingDetails, isLoading: isRefreshing } = useApiRequest(async () => {
    setIsRefreshed(false)
    dispatch(setReceptionBookingDetails(await commonObjectGet<ReceptionBookingDetails>(booking.urls.api_details)))
    setIsRefreshed(true)
  })

  const { action: sendDataToTablet } = useApiRequest(async () => {
    await commonObjectPost<ReceptionBookingDetails>(booking.urls.booking_kiosk_send_to_signature, {
      tablet: tabletId ?? user.tabletId,
    })
  })

  React.useEffect(() => {
    if (receptionBookingDetails.id === booking.id && receptionBookingDetails.booking_signature_url) {
      onSigningComplete()
      toggleIsVisible()
    }
  }, [receptionBookingDetails])

  React.useEffect(() => {
    sendDataToTablet()
  }, [])

  return (
    <ModalBody className="modal-colored-header bg-warning text-center">
      <i className="uil-exclamation-triangle h1" />
      <h5 className="mt-2">
        Oczekiwanie na <br /> podpis Gościa
      </h5>
      <p className="mt-3 text-semi-strong">
        Okno zniknie po zatwierdzeniu <br /> podpisu przez Gościa
      </p>
      <SaveButton
        isSaving={isRefreshing}
        label="Odśwież"
        onClick={refreshBookingDetails}
        labelSaving="Odświeżanie..."
        className="btn bg-transparent border-white mb-2 text-white w-75"
      />
      {isRefreshed && !receptionBookingDetails.booking_signature_url && (
        <p className="text-semi-strong">Podpis nie jest jeszcze dostępny.</p>
      )}
      <Button className="btn bg-transparent border-white mb-2 text-white w-75" onClick={closeTabletSignature}>
        Zamknij okno podpisu
      </Button>
    </ModalBody>
  )
}
