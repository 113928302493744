import * as React from 'react'
import { ExtraCharge, PriceItem } from '@models/reception'
import { formatPriceShort } from '@helpers/utils'
import classNames from 'classnames'

interface Props {
  extraCharges: PriceItem<ExtraCharge>
  defaultIsOpen: boolean
  readOnly?: boolean
}

const ReceptionBookingCheckOutBillExtraCharges: React.FC<Props> = ({ extraCharges, defaultIsOpen, readOnly }) => {
  const [isOpen, setIsOpen] = React.useState(false)

  React.useEffect(() => {
    setIsOpen(defaultIsOpen)
  }, [defaultIsOpen])

  const handleOpen = () => setIsOpen(state => !state)

  return (
    <>
      <tr className="reception__booking-check-out__bill-step__table__row">
        <td className="reception__booking-check-out__bill-step__table__column text-grey text-semi-strong">Dopłaty</td>
        <td className="reception__booking-check-out__bill-step__table__column text-right reception__booking-check-out__bill-step__table__column--is-costs">
          {formatPriceShort(extraCharges.total_price_brutto)}
        </td>
        <td className="reception__booking-check-out__bill-step__table__column" />
        {!readOnly && (
          <td className="reception__booking-check-out__bill-step__table__column text-right p-0">
            {!!extraCharges.items.length && (
              <i
                onClick={handleOpen}
                className={classNames('font-18 cursor-pointer', {
                  'uil-angle-right': !isOpen,
                  'uil-angle-down': isOpen,
                })}
              />
            )}
          </td>
        )}
      </tr>

      {isOpen && (
        <>
          {extraCharges.items.map((extraCharge: ExtraCharge) => (
            <tr
              key={extraCharge.id}
              className="reception__booking-check-out__bill-step__table__row reception__booking-check-out__bill-step__table__row--is-sub"
            >
              <td className="reception__booking-check-out__bill-step__table__column text-gray reception__booking-check-out__bill-step__table__column--is-sub">
                {extraCharge.name}
              </td>
              <td className="reception__booking-check-out__bill-step__table__column text-right text-gray">
                {formatPriceShort(extraCharge.price_brutto)}
              </td>
              <td className="reception__booking-check-out__bill-step__table__column" />
              {!readOnly && <td className="reception__booking-check-out__bill-step__table__column" />}
            </tr>
          ))}
        </>
      )}
    </>
  )
}

export default ReceptionBookingCheckOutBillExtraCharges
