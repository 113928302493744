import * as React from 'react'
import Table from '@components/table/table'
import { PriceBlock } from '@modules/reservations/list/reservation-list-payment-summary'
import { formatPrice } from '@helpers/utils'
import { useSelector } from 'react-redux'
import { selectEcommerceStatsAggregation } from '@store/slices/ecommerce-slice'

export const EcommerceStatsTableFooter = () => {
  const aggregation = useSelector(selectEcommerceStatsAggregation)

  return (
    <Table.Row>
      <Table.Cell colSpan={2} className="text-right align-middle reservation-list__summary-cell">
        <strong className="mr-2">Razem:</strong>
      </Table.Cell>
      <Table.Cell className="reservation-list__summary-cell">
        <PriceBlock title="Wartość brutto (wstępne):" price={formatPrice(aggregation?.initial_price ?? 0)} />
      </Table.Cell>
      <Table.Cell className="reservation-list__summary-cell" />
      <Table.Cell className="reservation-list__summary-cell">
        <PriceBlock title="Wartość brutto (potwierdzone):" price={formatPrice(aggregation?.confirmed_price ?? 0)} />
      </Table.Cell>
      <Table.Cell className="reservation-list__summary-cell" />
      <Table.Cell className="reservation-list__summary-cell">
        <PriceBlock title="Wartość brutto (razem):" price={formatPrice(aggregation?.total_price ?? 0)} />
      </Table.Cell>
    </Table.Row>
  )
}
