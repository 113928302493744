import * as React from 'react'
import { BenefitProgramAgreementFeature } from '@modules/benefit-program/models'
import Table from '@components/table/table'
import { CopyToClipboard } from '@components/copy-to-clipboard'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { BadgeWithIcon } from '@components/badges/BadgeWithIcon'

interface Props {
  companyName: string
  benefitProgramAgreementFeature: BenefitProgramAgreementFeature
}

export const BenefitProgramAgreementDetailsFeaturesTableRow: React.FC<Props> = ({
  benefitProgramAgreementFeature,
  companyName,
}) => (
  <Table.Row>
    <Table.Cell className="vertical-align-middle">{benefitProgramAgreementFeature.kind_display}</Table.Cell>
    <Table.Cell>
      {benefitProgramAgreementFeature.promocode && (
        <>
          {benefitProgramAgreementFeature.promocode.code}
          <CopyToClipboard className="uil-copy ml-1" value={benefitProgramAgreementFeature.promocode.code} />
        </>
      )}
    </Table.Cell>
    <Table.Cell>{companyName}</Table.Cell>
    <Table.Cell>
      {benefitProgramAgreementFeature.promocode &&
        toDefaultDateFormat(benefitProgramAgreementFeature.promocode.active_after)}
    </Table.Cell>
    <Table.Cell>
      {benefitProgramAgreementFeature.promocode &&
        toDefaultDateFormat(benefitProgramAgreementFeature.promocode.expire_after)}
    </Table.Cell>
    <Table.Cell>
      {benefitProgramAgreementFeature.promocode?.is_used ? (
        <>
          <BadgeWithIcon variant="danger" icon="uil-ban" title="Wykorzystany" />
          <span className="font-11">Nr. rez: {benefitProgramAgreementFeature.promocode.bookings.join('')}</span>
        </>
      ) : (
        <BadgeWithIcon variant="success" icon="uil-check" title="Do wykorzystania" />
      )}
    </Table.Cell>
    <Table.Cell />
  </Table.Row>
)
