import * as React from 'react'
import { CleaningOrder, CleaningOrderDetails } from '@modules/housekeeping/models'
import { ButtonWithIcon } from '@components/button-with-icon'
import { useModal } from '@components/modals/use-modal'
import { updateHousekeepingCleaningOrderDetails } from '@store/slices/housekeeping-slice'
import { useAppDispatch } from '@store/index'

interface Props {
  cleaningOrder: CleaningOrder
}

export const HousekeepingCleaningOrderToOrderRowActionsOrder: React.FC<Props> = ({ cleaningOrder }) => {
  const dispatch = useAppDispatch()

  const handleOrderUpdate = (order: CleaningOrderDetails) => {
    dispatch(updateHousekeepingCleaningOrderDetails(order))
  }

  const [showHousekeepingWorkerAssignmentModal] = useModal('HousekeepingCleaningOrderAssignModal', {
    order: cleaningOrder,
    onOrderUpdate: handleOrderUpdate,
  })

  return (
    <ButtonWithIcon
      icon="uil-user-plus font-16"
      text="Przydziel zadanie"
      color="green"
      handleClick={showHousekeepingWorkerAssignmentModal}
    />
  )
}
