import { ApartmentStatus } from '@models/apartment'

export const getApartmentHousekeepingStatusColor = (status: ApartmentStatus | undefined) =>
  ({
    clean: 'badge-green',
    to_complete: 'badge-yellow',
    waiting: 'badge-danger',
    dirty: 'badge-danger',
    cleaning: 'badge-danger',
  })[status || '']
