import declination from '@helpers/declination'
import IconEnvelope from '@components/icons/envelope'
import { formatPhoneNumber, formatPrice } from '@helpers/utils'
import IconChatRightText from '@components/icons/chat-right-text'
import IconTelephoneForward from '@components/icons/telephone-forward'
import TimelineItemTooltipImp from '@modules/reservations/timeline/item-tooltip/timeline-item-tooltip-imp'
import IconJournalText from '@components/icons/journal-text'
import * as React from 'react'
import classNames from 'classnames'
import { BookingStatus } from '@models/booking'
import { ReceptionBookingDetails } from '@models/reception'
import timelineHelper from '@helpers/timeline-helper'
import { isReservedByBooking } from '@modules/reception/checkin/preview/reception-booking-check-in-preview-bill'

interface Props {
  details: ReceptionBookingDetails
  status: BookingStatus
  isExtendedStay: boolean
  numberOfNights: number
  reformatDate: (date: string) => string
}

const TimelineItemTooltipBookingDetails: React.FC<Props> = ({
  details,
  status,
  isExtendedStay,
  reformatDate,
  numberOfNights,
}) => {
  const isBookingBeforeApFee = timelineHelper.isBookingBeforeApFee(details.created_at)

  const bookingStatusMapper = (): string => {
    switch (status) {
      case 'system':
      case 'fake':
      case 'blocked':
        return 'Niedostępny'
      case 'close':
        return 'Rozliczona'
      case 'initial':
        return 'Wstępna'
      case 'confirmed':
        return details.checked_in ? 'Zameldowani' : 'Potwierdzone'
      default:
        return ''
    }
  }

  const isEmployee = details.kind === 'employee'
  const isVip = details.improvements.some(improvement => improvement.code === 'package_vip')

  return (
    <>
      <div className="calendar-tooltip__upper">
        <header className="calendar-tooltip__el">
          <strong>{details.reservation_number} </strong>
          {details.is_package_reservation && '(Pakiet firmowy)'}
          {details.is_subscription_reservation && '(Abonamnetowa)'}
          {!details.is_subscription_reservation && !details.is_package_reservation && !isEmployee && '(Standardowa)'}
          {isEmployee && '(Pracownicza)'}
        </header>
        <div
          className={classNames(
            `calendar-tooltip__status justify-content-between align-items-center d-flex is-${status}`,
            {
              'is-checkedin': details.checked_in,
              'is-extended': isExtendedStay,
              'is-vip': isVip,
              'is-initial-before-ap-fee': details.status === 'initial' && isBookingBeforeApFee,
              'is-confirmed-before-ap-fee': details.status === 'confirmed' && isBookingBeforeApFee,
            },
          )}
        >
          <span>{isExtendedStay ? `Przedłużona do ${details.departure_time}` : bookingStatusMapper()}</span>
          {isVip && (
            <span className="vip-mark" data-testid="vip-mark">
              <img src={require('@assets/images/vip-white.svg')} alt="vip" height="18" />
            </span>
          )}
          {isReservedByBooking(details) && (
            <span
              className={classNames(`calendar-content__status-booking is-in-tooltip is-${details.status}`, {
                'is-checkedin': details.checked_in,
                'is-initial-before-ap-fee': details.status === 'initial' && isBookingBeforeApFee,
                'is-confirmed-before-ap-fee': details.status === 'confirmed' && isBookingBeforeApFee,
                'is-after-vip': isVip,
              })}
            >
              B
            </span>
          )}
        </div>
        <div className="calendar-tooltip__el">
          {reformatDate(details.date_from)} - {reformatDate(details.date_to)} ({numberOfNights}{' '}
          {declination.night(numberOfNights)})
        </div>
        <div className="calendar-tooltip__el">
          {details.guests.length} {declination.person(details.guests.length)}
        </div>
        <div className="calendar-tooltip__el">
          <strong>{details.name}</strong>
        </div>
        <div className="calendar-tooltip__el justify-content-between align-items-center d-flex">
          {details.email}
          <IconEnvelope classNames="calendar-tooltip__icon" />
        </div>
        <div className="calendar-tooltip__el justify-content-between align-items-center d-flex">
          {formatPhoneNumber(details.phone)}
          <div>
            <IconChatRightText classNames="calendar-tooltip__icon" />
            <IconTelephoneForward classNames="calendar-tooltip__icon" />
          </div>
        </div>
      </div>
      <div className="calendar-tooltip__lower">
        <div className="calendar-tooltip__el justify-content-between align-items-center d-flex">
          <strong>Pozostało do zapłaty:</strong>
          <strong>{details.rest_to_pay} zł</strong>
        </div>
        <div className="calendar-tooltip__el justify-content-between align-items-center d-flex">
          <span>Wpłacona kaucja:</span>
          <span>
            <strong>{parseInt(details.total_deposit_payin, 10) > 0 ? 'tak' : 'nie'}</strong> (
            {formatPrice(details.total_deposit_payin)})
          </span>
        </div>
        <div className="calendar-tooltip__el justify-content-between align-items-center d-flex">
          <span>Rabat:</span>
          <span>{details.promocode?.value ? `${details.promocode.value} zł` : 'brak'}</span>
        </div>
        <div className="calendar-tooltip__el justify-content-between align-items-start d-flex">
          <span>Ulepszenia:</span>
          <TimelineItemTooltipImp improvements={details.improvements} />
        </div>
        <div className="calendar-tooltip__el justify-content-between align-items-center d-flex">
          <span>Wyżywienie:</span>
          <span>{details.feeding === 'none' || details.feeding === null ? 'Nie' : 'Tak'}</span>
        </div>
        <div className="calendar-tooltip__el justify-content-between align-items-center d-flex">
          <span>Notatka:</span>
          <IconJournalText classNames={classNames('calendar-tooltip__icon', { 'is-darker': !details.notes.length })} />
        </div>
      </div>
    </>
  )
}

export default TimelineItemTooltipBookingDetails
