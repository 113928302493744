import * as React from 'react'
import { ReservationDropdown } from '@modules/reservations/details/common/reservation-dropdown'
import { CustomSwitch } from '@components/custom-switch'
import { DropdownItem } from 'reactstrap'
import { useFormContext, useWatch } from 'react-hook-form'
import { IconWithText } from '@components/icon-with-text'

export const ContentNoteCardActions = (): React.ReactNode => {
  const { control, setValue } = useFormContext()

  const showRemovedNotes = useWatch({ control, name: 'showRemovedNotes' })

  const toggleShowRemovedNotes = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setValue('showRemovedNotes', !showRemovedNotes)
  }

  return (
    <div className="ml-auto">
      <ReservationDropdown controlled dropdownMenuProps={{ modifiers }}>
        <DropdownItem className="pl-2 py-1" onClick={toggleShowRemovedNotes}>
          <CustomSwitch
            inputName="showRemovedNotes"
            labelClassName="is-small"
            value={showRemovedNotes}
            label={
              <IconWithText
                icon="uil-trash-alt text-secondary font-14 lh-0"
                text="Pokaż usunięte notatki"
                textClass="font-11 font-weight-normal"
              />
            }
          />
        </DropdownItem>
      </ReservationDropdown>
    </div>
  )
}

const modifiers = {
  preventOverflow: {
    enabled: true,
    boundariesElement: 'viewport',
    padding: 50,
  },
} as const
