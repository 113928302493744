import {
  Apartment,
  ApartmentBookingDayInfo,
  ApartmentBookingFilter,
  ApartmentFetchDate,
  RentSessionTag,
} from '@models/apartment'
import { TimelineActionTypes, TimelineActionTypesKeys } from '@store/actions-types/timeline-actions-types'
import { BookingModalData } from '@modules/reservations/timeline/modals/timeline-booking-modal'
import { EditModeType } from '@modules/reservations/timeline/timeline-item-basic'
import { BookingStatus, ReceptionBookingUrls } from '@models/booking'
import {
  BookingExtendedStayKind,
  BookingSource,
  ReceptionBookingDetails,
  ReceptionBookingKind,
} from '@models/reception'

export interface CalendarDate {
  month: number
  year: number
}

export interface CalendarDatesState {
  dates: CalendarDate[]
  position: CalendarDatePosition
}

export interface InitialDragData {
  x: number
  y: number
  renderedDates: CalendarDate[]
}

export type TimelineScrollPosition = number

export interface TimelineDailyStatsPayload {
  resort: string
  accommodation_type: string[]
  date_after: string
  date_before: string
}

export type CalendarDatePosition = 'today' | 'middle' | 'reach-right' | 'reach-left'

export interface TimelineReceptionBookingBasic {
  apartment_id: number
  checked_in: boolean
  created_at: string
  date_from: string
  date_to: string
  days_count: number
  email: string
  extended_stay: boolean
  extended_stay_type?: BookingExtendedStayKind
  kind: ReceptionBookingKind
  id: number
  selected_apartment: number | null
  name: string
  has_package_vip: boolean
  reservation_number: string
  status: BookingStatus
  urls: ReceptionBookingUrls
}

export interface TimelineReceptionBooking extends TimelineReceptionBookingBasic {
  apartment: Apartment
  detailed: boolean
  source: BookingSource | null
}

export type TimelineBooking = TimelineReceptionBooking

export interface TimelineRentSession {
  date_from: string
  date_to: string
  id: number
  keyword: RentSessionTag
}

export interface TimelineRentDays {
  block_reservation: boolean
  date: string
  id: number
}

export interface TimelineUpdateBookingItemPayload {
  url: string
  dateTo: string
  dateFrom: string
  apartmentId: number
}

export interface EditingBookingItem {
  type: EditModeType
  id: number
}

export interface TimelineState {
  bookings: TimelineBooking[]
  rent_days: TimelineRentDays[]
  rent_sessions: TimelineRentSession[]
  bookingsDetails: ReceptionBookingDetails[]
  apartments: Apartment[]
  calendarDates: CalendarDatesState
  dailyStats: ApartmentBookingDayInfo | null
  map: string
  fetchDate: ApartmentFetchDate
  bookingModalData: BookingModalData | null
  bookingModalDataWarning: boolean
  editingBookingItem: EditingBookingItem | null
  scrollPosition: TimelineScrollPosition
  isCompactView: boolean
  isMask: boolean
  isContextMenuShowed: boolean
  initialDragData: InitialDragData | undefined
  activeFilters: ApartmentBookingFilter[]
  selectedCols: string[]
  selectedRows: Apartment[]
}

export const initialState: TimelineState = {
  bookings: [],
  rent_days: [],
  rent_sessions: [],
  bookingsDetails: [],
  apartments: [],
  dailyStats: null,
  calendarDates: {
    dates: [],
    position: 'today',
  },
  map: '',
  fetchDate: {
    date: '',
    hour: '',
  },
  bookingModalData: null,
  bookingModalDataWarning: false,
  editingBookingItem: null,
  scrollPosition: 0,
  isCompactView: true,
  isMask: true,
  initialDragData: undefined,
  isContextMenuShowed: false,
  activeFilters: [],
  selectedCols: [],
  selectedRows: [],
}

function mapBookings(
  bookings: (TimelineReceptionBooking | ReceptionBookingDetails)[],
  aps: Apartment[],
): TimelineBooking[] {
  return bookings.map(bk => ({
    ...bk,
    detailed: 'days' in bk,
    apartment: bk.apartment || aps.find(ap => ap.id === bk.apartment_id),
    kind: 'kind' in bk ? bk.kind : 'standard',
  }))
}

export default (state: TimelineState = initialState, action: TimelineActionTypes): TimelineState => {
  switch (action.type) {
    case TimelineActionTypesKeys.SET_CALENDAR_BOOKINGS:
      return { ...state, bookings: mapBookings(action.payload, state.apartments) }
    case TimelineActionTypesKeys.SET_CALENDAR_RENT_DAYS:
      return { ...state, rent_days: action.payload }
    case TimelineActionTypesKeys.SET_CALENDAR_RENT_SESSIONS:
      return { ...state, rent_sessions: action.payload }
    case TimelineActionTypesKeys.REMOVE_CALENDAR_BOOKING: {
      return { ...state, bookings: state.bookings.filter(row => row.id !== action.payload) }
    }
    case TimelineActionTypesKeys.UPDATE_CALENDAR_BOOKINGS: {
      const isInState = state.bookings.some(bk => bk.id === action.payload.id)
      const updated = state.bookings
        .map(bk => (bk.id === action.payload.id ? action.payload : bk))
        .filter(bk => !!bk.apartment)
      return {
        ...state,
        bookings: mapBookings(isInState ? updated : [...state.bookings, action.payload], state.apartments),
      }
    }
    case TimelineActionTypesKeys.UPDATE_CALENDAR_BOOKING_DETAILS: {
      const isInState = state.bookingsDetails.some(bk => bk.id === action.payload.id)

      return {
        ...state,
        bookingsDetails: (isInState
          ? state.bookingsDetails.map(bk => (bk.id === action.payload.id ? action.payload : bk))
          : [...state.bookingsDetails, action.payload]
        ).filter(bk => !!bk.apartment),
      }
    }

    case TimelineActionTypesKeys.SET_DAY_STATS:
      return { ...state, dailyStats: action.payload }
    case TimelineActionTypesKeys.SET_CALENDAR_APARTMENTS:
      return { ...state, apartments: action.payload }
    case TimelineActionTypesKeys.UPDATE_CALENDAR_APARTMENTS:
      return { ...state, apartments: state.apartments.map(ap => (ap.id === action.payload.id ? action.payload : ap)) }
    case TimelineActionTypesKeys.SET_CALENDAR_DATES:
      return { ...state, calendarDates: action.payload }
    case TimelineActionTypesKeys.SET_RESORT_MAP:
      return { ...state, map: action.payload }
    case TimelineActionTypesKeys.SET_FETCH_DATE:
      return { ...state, fetchDate: action.payload }
    case TimelineActionTypesKeys.SET_BOOKING_MODAL_DATA:
      return { ...state, bookingModalData: action.payload }
    case TimelineActionTypesKeys.SET_BOOKING_MODAL_DATA_WARNING:
      return { ...state, bookingModalDataWarning: action.payload }
    case TimelineActionTypesKeys.SET_EDIT_MODE_FOR_ITEM:
      return { ...state, editingBookingItem: action.payload }
    case TimelineActionTypesKeys.SET_SCROLL_POSITION:
      return { ...state, scrollPosition: action.payload }
    case TimelineActionTypesKeys.SET_COMPACT_CALENDAR:
      return { ...state, isCompactView: action.payload }
    case TimelineActionTypesKeys.SET_INITIAL_DRAG_DATA:
      return { ...state, initialDragData: action.payload }
    case TimelineActionTypesKeys.TOGGLE_TIMELINE_MASK:
      return { ...state, isMask: !state.isMask }
    case TimelineActionTypesKeys.SET_ACTIVE_FILTER: {
      return { ...state, activeFilters: action.payload }
    }
    case TimelineActionTypesKeys.SET_SELECTED_COLS: {
      return { ...state, selectedCols: action.payload }
    }
    case TimelineActionTypesKeys.SET_SELECTED_ROWS: {
      return { ...state, selectedRows: action.payload }
    }
    case TimelineActionTypesKeys.RESET_ACTIVE_FILTER:
      return { ...state, activeFilters: [] }
    case TimelineActionTypesKeys.SET_CONTEXT_MENU_IS_VISIBLE:
      return { ...state, isContextMenuShowed: action.payload }
    default:
      return state
  }
}
