import * as React from 'react'
import { BookingFeeding, ReceptionBookingDetails } from '@models/reception'
import { asDecimal, formatPrice, sumDecimalArray } from '@helpers/utils'
import { ReceptionBookingGuest } from '@models/booking'

interface Props {
  booking: ReceptionBookingDetails
}

export const ReservationBillExpandedFeeding = ({ booking }: Props): JSX.Element => {
  const feedingPriceForGuest = (guest: ReceptionBookingGuest) =>
    sumDecimalArray(
      booking.prices.feeding.items
        .filter((feeding: BookingFeeding) => feeding.guest_id === guest.id)
        .map((feeding: BookingFeeding) => asDecimal(feeding.price_brutto)),
    ).toString()

  return (
    <table className="table table-sm col-9 ml-auto mb-0 font-12">
      <tbody>
        {booking.guests.map((guest: ReceptionBookingGuest) => (
          <tr key={guest.id}>
            <td>{guest.name}</td>
            <td className="fw-semi-bold text-right">+ {formatPrice(feedingPriceForGuest(guest))}</td>
            <td width="50" />
          </tr>
        ))}
      </tbody>
    </table>
  )
}
