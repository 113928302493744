import { CmsActionsTypes, CmsActionTypesKeys } from '@store/actions-types/cms-actions-types'
import { CmsAppData } from '@models/cms'
import { EmailTemplate, EmailTemplateDetails, SmsTemplate } from '@modules/cms/models'

export interface CmsState {
  appData: CmsAppData
  smsTemplates: SmsTemplate[]
  emailTemplates: EmailTemplate[]
  emailTemplateDetails: EmailTemplateDetails | undefined
}

const initialState: CmsState = {
  appData: {
    improvement_groups: [],
    status: 'unknown',
  },
  emailTemplateDetails: undefined,
  emailTemplates: [],
  smsTemplates: [],
}

const cmsState = (state: CmsState = initialState, action: CmsActionsTypes): CmsState => {
  switch (action.type) {
    case CmsActionTypesKeys.SET_APP_DATA:
      return { ...state, appData: action.payload }
    case CmsActionTypesKeys.SET_SMS_TEMPLATES:
      return { ...state, smsTemplates: action.payload }
    case CmsActionTypesKeys.SET_EMAIL_TEMPLATES:
      return { ...state, emailTemplates: action.payload }
    case CmsActionTypesKeys.SET_EMAIL_TEMPLATE_DETAILS:
      return { ...state, emailTemplateDetails: action.payload }
    default:
      return state
  }
}

export default cmsState
