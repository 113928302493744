import * as React from 'react'
import { formatPrice } from '@helpers/utils'
import classNames from 'classnames'
import { ReceptionBookingDetailsCartItem } from '@models/reception'
import { createCombinedCartItemsPrice } from '@modules/reception/cart/utils'

interface Props {
  items: ReceptionBookingDetailsCartItem[]
}

export const ReceptionBookingCartItemsSummary = ({ items }: Props): JSX.Element => {
  const { totalPrice, originalTotalPrice } = createCombinedCartItemsPrice(items)

  return (
    <div className="my-2 d-flex font-weight-bold flex-wrap">
      <SummaryRow
        title="Wartość koszyka:"
        price={totalPrice}
        priceClass="text-secondary font-18"
        titleClass="text-dark font-15 "
      />
      <SummaryRow
        title="Cena przed rabatem:"
        price={originalTotalPrice}
        titleClass="text-muted font-weight-normal font-11"
        priceClass="text-muted font-weight-normal font-12 text-strikethrough"
      />
    </div>
  )
}

const SummaryRow = ({ title, price, titleClass, priceClass }) => (
  <>
    <div className="col-7" />
    <div className={classNames('col-3 text-right', titleClass)}>{title}</div>
    <div className={classNames('col-2', priceClass)}>{formatPrice(price)}</div>
  </>
)
