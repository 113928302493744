import * as React from 'react'
import { getStatsSaleItems } from '@store/actions/report-actions'
import { StatsSaleItemFiltersParams } from '@modules/reports/models'
import { useAppDispatch, useAppSelector } from '@store/index'
import { StatsSaleItemFilters } from '@modules/reports/stats-sale-item/list/stats-sale-item-filters'
import { StatsSaleItemsTable } from '@modules/reports/stats-sale-item/list/stats-sale-items-table'
import { StatsSaleItemType } from '@modules/reports/stats-sale-item/models'
import { endOfMonth, startOfMonth, startOfToday } from 'date-fns'
import { useApiRequest } from '@components/hooks/use-api-request'
import { statsStaleItemsSelector } from '@store/slices/reports-slice'
import { Form } from '@hyper/forms/form'
import { useForm } from 'react-hook-form'
import { extractSelectOptionsValues } from '@helpers/utils'

export interface StatsSaleItemListProps {
  category?: StatsSaleItemType
}

export const StatsSaleItemList: React.FC<StatsSaleItemListProps> = ({ category }) => {
  const defaultFilters: StatsSaleItemFiltersParams = {
    sell_date_after: startOfMonth(startOfToday()),
    sell_date_before: endOfMonth(startOfToday()),
    ordering: '-sell_date',
    page: 1,
    page_size: 10,
    search: '',
    promotion_marketing: undefined,
    source_marketing: undefined,
    seller: undefined,
    category,
  }

  const methods = useForm({ defaultValues: defaultFilters })

  const [filters, setFilters] = React.useState<StatsSaleItemFiltersParams>(defaultFilters)

  const dispatch = useAppDispatch()
  const statsStaleItems = useAppSelector(statsStaleItemsSelector)

  const { isLoading, action: fetchStatsSaleItems } = useApiRequest(
    async () => await dispatch(getStatsSaleItems(extractSelectOptionsValues(filters))),
  )

  React.useEffect(() => {
    fetchStatsSaleItems()
  }, [filters])

  return (
    <Form methods={methods} onSubmit={fetchStatsSaleItems}>
      <StatsSaleItemFilters filters={filters} setFilters={setFilters} defaultFilters={defaultFilters} />

      <StatsSaleItemsTable
        isLoading={isLoading}
        category={category}
        filters={filters}
        setFilters={setFilters}
        statsStaleItems={statsStaleItems}
      />
    </Form>
  )
}
