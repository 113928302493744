import * as React from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { FormInput } from '@hyper/forms'
import { ReceptionBookingDetails } from '@models/reception'
import { parseISODate } from '@helpers/date-helper'
import { BookingImprovementCode, ImprovementAvailability } from '@models/booking'
import { ReceptionBookingCheckInStepImprovementsActionColumns } from '@modules/reception/checkin/step-improvements/reception-booking-check-in-step-improvements-action-columns'
import { ReceptionBookingCheckInStepImprovementError } from '@modules/reception/checkin/step-improvements/reception-booking-check-in-step-improvement-error'
import { ImprovementPriceCalculation } from '@store/actions/reception-actions'
import { ReceptionBookingCheckInImprovementsHelicopterFlight } from '@modules/reception/checkin/step-improvements/helicopter-flight'

interface FromInputs {
  improvementCode: BookingImprovementCode
  amount: number
  date_from: Date | null
  date_to: Date | null
}

interface Props {
  booking: ReceptionBookingDetails
  availableImprovements: ImprovementAvailability[]
  calculatedPrices: ImprovementPriceCalculation[]
  onClose: () => void
}

export const ReceptionBookingCheckInStepAdditionalImprovement: React.FC<Props> = ({
  booking,
  availableImprovements,
  calculatedPrices,
  onClose,
}) => {
  const [error, setError] = React.useState<string | undefined>(undefined)
  const [improvementPrice, setImprovementPrice] = React.useState<string | undefined>(undefined)

  React.useEffect(() => {
    const defaultPrice = calculatedPrices.find(el => el.code === availableImprovements[0].code)
    if (defaultPrice) {
      setImprovementPrice(defaultPrice.price)
    }
  }, [calculatedPrices, availableImprovements])

  const handlePriceUpdate = (price: string | undefined) => setImprovementPrice(price)

  const methods = useForm<FromInputs>({
    defaultValues: {
      improvementCode: availableImprovements[0].code,
      amount: 1,
      date_from: parseISODate(booking.date_from),
      date_to: parseISODate(booking.date_to),
    },
  })

  const improvementCode = useWatch({
    control: methods.control,
    name: 'improvementCode',
  })

  React.useEffect(() => {
    methods.setValue('date_from', parseISODate(booking.date_from))
    methods.setValue('date_to', parseISODate(booking.date_to))
  }, [improvementCode])

  const improvement = React.useMemo(
    () => availableImprovements.find(row => row.code === improvementCode),
    [availableImprovements, improvementCode],
  )

  return (
    <FormProvider {...methods}>
      <table className="table table-borderless mb-0 reception__booking-check-in__improvement-step__table">
        <tbody>
          <tr>
            <td className="reception__booking-check-in__improvement-step__improvement-col pl-0 pb-0">
              <FormInput type="select" name="improvementCode" colClassName="p-0">
                {availableImprovements.map((improvement: ImprovementAvailability) => (
                  <option key={improvement.code} value={improvement.code}>
                    {improvement.name}
                  </option>
                ))}
              </FormInput>
            </td>
            {improvementCode === 'helicopter_flight' ? (
              <ReceptionBookingCheckInImprovementsHelicopterFlight
                onClose={onClose}
                handleDelete={onClose}
                booking={booking}
              />
            ) : (
              improvement && (
                <ReceptionBookingCheckInStepImprovementsActionColumns
                  onPriceUpdate={handlePriceUpdate}
                  booking={booking}
                  improvement={improvement}
                  setError={setError}
                  addButtonDisabled={!!error}
                  onClose={onClose}
                  handleDelete={onClose}
                  improvementPrice={improvementPrice}
                />
              )
            )}
          </tr>
          {error && <ReceptionBookingCheckInStepImprovementError calculationError={error} />}
        </tbody>
      </table>
    </FormProvider>
  )
}
