import * as React from 'react'
import { Card, CardBody, Col } from 'reactstrap'
import { RodoAgreement } from '@modules/crm/rodo-agreements/models'
import { ProductCardTitle } from '@components/products/product-card-title'
import { IconWithText } from '@components/icon-with-text'
import { PackageDetailsRow } from '@modules/package/details/information/row'
import { useModal } from '@components/modals/use-modal'

interface Props {
  rodoAgreement: RodoAgreement
}

export const RodoAgreementsModalClientData = ({ rodoAgreement }: Props): React.ReactNode => {
  const [showEditModal] = useModal('RodoAgreementsModalClientDataEdit', { rodoAgreement })

  return (
    <Col md={6}>
      <Card>
        <CardBody>
          <ProductCardTitle
            title={<IconWithText icon="uil-user" text="Dane Klienta" textClass="fw-semi-bold" />}
            isEditAllowed={rodoAgreement.can_edit}
            onEdit={showEditModal}
          />
          <table className="table w-100 table-sm mb-0">
            <tbody>
              <PackageDetailsRow label="Miejsce wyrażenia zgody">
                {rodoAgreement.rodo_acquiring_point_name}
              </PackageDetailsRow>
              <PackageDetailsRow label="Imię i nazwisko">
                {rodoAgreement.first_name} {rodoAgreement.last_name}
              </PackageDetailsRow>
              <PackageDetailsRow label="Adres e-mail">{rodoAgreement.email}</PackageDetailsRow>
              <PackageDetailsRow label="Numer telefonu">{rodoAgreement.phone}</PackageDetailsRow>
            </tbody>
          </table>
        </CardBody>
      </Card>
    </Col>
  )
}
