import * as React from 'react'
import classNames from 'classnames'
import { EmailNotification, SMSNotification } from '@models/notifications'
import { IconWithTooltip } from '@components/icon-with-tooltip'

interface Props {
  notification: EmailNotification | SMSNotification
  isVisible: boolean
}

export const NotificationHoverMenu = ({ isVisible, notification }: Props): JSX.Element => (
  <div className={classNames('reservation-details__notes-box__actions', { 'is-visible': isVisible })}>
    <IconWithTooltip
      icon="reservation-details__notes-box__actions__item uil-info-circle mx-0 border-0"
      tooltipMessage={<span className="font-11">Wysłane do {notification.send_to}</span>}
      tooltipId={`notification-${notification.id}`}
    />
  </div>
)
