import * as React from 'react'
import { BookingStatsDetails, ReservationStatsTableCells, StatsTableOptions } from '@modules/reservations/models'
import { ReservationStatsTableExpandedRow } from '@modules/reservations/statistics/booking-stats/stats-details/stats-details-table/reservation-stats-table-expanded-row'
import { ReservationStatsAccommodationParkingVipRow } from '@modules/reservations/statistics/booking-stats/stats-details/stats-details-table/reservation-stats-accommodation-parking-vip-row'
import { ReservationStatsOnlineCheckRow } from '@modules/reservations/statistics/booking-stats/stats-details/stats-details-table/reservation-stats-accommodation-online-check-row'

interface ReservationStatsAccommodationRowsProps {
  getCells: (element: string, options?: StatsTableOptions<BookingStatsDetails>) => ReservationStatsTableCells
}

export const ReservationStatsAccommodationRows: React.FC<ReservationStatsAccommodationRowsProps> = ({ getCells }) => (
  <>
    <ReservationStatsTableExpandedRow title="Do Wymeldowania" cells={getCells('departures')} />
    <ReservationStatsTableExpandedRow title="Pozostało do wymeldowania" cells={getCells('departures_left')} />
    <ReservationStatsOnlineCheckRow field="departures_checked_out_online" getCells={getCells} />
    <ReservationStatsTableExpandedRow title="Do Zameldowania" cells={getCells('arrivals')} />
    <ReservationStatsTableExpandedRow title="Pozostało do zameldowania" cells={getCells('arrivals_left')} />
    <ReservationStatsOnlineCheckRow field="arrivals_checked_in_online" getCells={getCells} />
    <ReservationStatsTableExpandedRow title="Osób dorosłych w obiekcie" cells={getCells('adults')} />
    <ReservationStatsTableExpandedRow title="Dzieci w obiekcie" cells={getCells('children')} />
    <ReservationStatsTableExpandedRow title="Niemowląt w obiekcie" cells={getCells('babies')} />
    <ReservationStatsTableExpandedRow title="Zwierząt w obiekcie" cells={getCells('animals_in_object')} />
    <ReservationStatsAccommodationParkingVipRow getCells={getCells} />
    <ReservationStatsTableExpandedRow title="Parking miejsca sprzedane" cells={getCells('bought_parking_spots')} />
    <ReservationStatsTableExpandedRow title="Parking miejsca wolne" cells={getCells('free_parking_spots')} />
    <ReservationStatsTableExpandedRow title="Pakiet VIP (w obiekcie)" cells={getCells('packages_vip')} />
  </>
)
