import * as React from 'react'
import { FormPlain } from '@hyper/forms'
import DatePickerInput from '@components/date/date-picker-input'
import { Label } from 'reactstrap'
import { startOfToday, add, endOfToday } from 'date-fns'

export const ReservationPaymentDeposit = (): JSX.Element => {
  const minDate = add(startOfToday(), { days: -5 })
  return (
    <FormPlain name="payment_date">
      <Label>Data wpłaty</Label>
      <DatePickerInput name="invoice_date" wrapperClassName="flex-grow-1" minDate={minDate} maxDate={endOfToday()} />
    </FormPlain>
  )
}
