import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { ReservationDropdown } from '@modules/reservations/details/common/reservation-dropdown'
import { useModal } from '@components/modals/use-modal'

interface ReservationDataActionsProps {
  booking: ReceptionBookingDetails
}

export const ReservationBistroVouchersActions: React.FC<ReservationDataActionsProps> = ({ booking }) => {
  const [showAddingModal] = useModal('ReservationBistroVoucherAddModal', { booking })
  const [showUseModal] = useModal('ReservationBistroVoucherUseModal', { booking })

  return (
    <div className="d-flex justify-content-end">
      <ReservationDropdown>
        <ReservationDropdown.Item onClick={showAddingModal}>Dodaj voucher</ReservationDropdown.Item>
        <ReservationDropdown.Item onClick={showUseModal}>Zrealizuj voucher</ReservationDropdown.Item>
      </ReservationDropdown>
    </div>
  )
}
