import * as React from 'react'
import { TechnicalOrder } from '@models/technical-orders'
import { useTechnicalOrderPreviewModal } from '@modules/technical-orders/hooks/use-technical-order-preview-modal'
import Table from '@components/table/table'
import { TechnicalOrdersTodoRowNumber } from '@modules/technical-orders/technical-orders-todo/row-number'
import { TechnicalOrdersTodoRowUnit } from '@modules/technical-orders/technical-orders-todo/row-unit'
import { TechnicalOrdersTodoRowName } from '@modules/technical-orders/technical-orders-todo/row-name'
import { TechnicalOrdersTodoRowUserBy } from '@modules/technical-orders/technical-orders-todo/row-user-by'
import { TechnicalOrdersOrderedTableRowNotes } from '@modules/technical-orders/technical-orders-ordered/row-notes'
import { TechnicalOrdersTodoRowStatus } from '@modules/reception-technical-orders/row-status'
import { GuestsTechnicalOrdersTableRowActions } from '@modules/guests-technical-orders/row-actions'
import { TechnicalOrdersTodoRowUsers } from '@modules/technical-orders/technical-orders-ordered/row-users'

interface Props {
  technicalOrder: TechnicalOrder
  showUsers: boolean
}

export const GuestsTechnicalOrdersTableRow: React.FC<Props> = ({ showUsers, technicalOrder }) => {
  const handlePreview = useTechnicalOrderPreviewModal({ technicalOrder })

  return (
    <Table.Row onDoubleClick={handlePreview} className="cursor-pointer">
      <TechnicalOrdersTodoRowNumber technicalOrder={technicalOrder} />
      <TechnicalOrdersTodoRowUnit technicalOrder={technicalOrder} />
      <TechnicalOrdersTodoRowStatus technicalOrder={technicalOrder} />
      <TechnicalOrdersTodoRowName technicalOrder={technicalOrder} />
      <TechnicalOrdersTodoRowUserBy
        user={technicalOrder.created_by}
        booking={technicalOrder.created_by_booking}
        isCreatedByClient={technicalOrder.is_created_by_client}
      />
      {showUsers && <TechnicalOrdersTodoRowUsers technicalOrder={technicalOrder} />}
      <TechnicalOrdersOrderedTableRowNotes technicalOrder={technicalOrder} />
      <GuestsTechnicalOrdersTableRowActions technicalOrder={technicalOrder} />
    </Table.Row>
  )
}
