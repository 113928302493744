import * as React from 'react'
import { CleaningOrder } from '@modules/housekeeping/models'
import { HousekeepingCleaningOrderCleaningRowActions } from '@modules/housekeeping/cleaning-orders/cleaning/actions'
import { HousekeepingCleaningOrdersRowServiceKind } from '@modules/housekeeping/cleaning-orders/common/service-kind'
import { HousekeepingCleaningOrderOrderedByRow } from '@modules/housekeeping/cleaning-orders/common/ordered-by'
import { HousekeepingOrdersRowTotalTimeStartedLive } from '@modules/housekeeping/common/row-total-time-started-live'
import { HousekeepingCleaningOrdersRowKind } from '@modules/housekeeping/cleaning-orders/common/kind'
import { HousekeepingOrdersRowTotalTimeStarted } from '@modules/housekeeping/common/row-total-time-started'
import { IconWithText } from '@components/icon-with-text'
import { HousekeepingCleaningOrderApartment } from '@modules/housekeeping/cleaning-orders/to-order/row-apartment'
import { HousekeepingOrderGroupedRow } from '@modules/housekeeping/cleaning-orders/common/grouped-table/grouped-row'

interface Props {
  cleaningOrders: CleaningOrder[]
}

export const HousekeepingCleaningOrderCleaningGroupedRow = ({ cleaningOrders }: Props): React.ReactNode => (
  <HousekeepingOrderGroupedRow
    cleaningOrders={cleaningOrders}
    rowRenderer={cleaningOrder => (
      <tr className="housekeeping__orders_table__row">
        <HousekeepingCleaningOrderApartment cleaningOrder={cleaningOrder} showCheckedIn={true} showPriority={true} />
        <HousekeepingCleaningOrdersRowKind order={cleaningOrder} />
        <HousekeepingCleaningOrdersRowServiceKind id={cleaningOrder.id} order={cleaningOrder} />
        {cleaningOrder.is_paused ? (
          <HousekeepingOrdersRowTotalTimeStarted totalTimeStarted={cleaningOrder.total_time_started}>
            <IconWithText
              icon="uil-pause font-15"
              text="pauza"
              textClass="text-semi-strong ml-05"
              flexClassName="d-flex"
            />
          </HousekeepingOrdersRowTotalTimeStarted>
        ) : (
          <HousekeepingOrdersRowTotalTimeStartedLive order={cleaningOrder} />
        )}
        <HousekeepingCleaningOrderOrderedByRow
          ordered_at={cleaningOrder.ordered_at}
          ordered_by={cleaningOrder.ordered_by}
        />
        <HousekeepingCleaningOrderCleaningRowActions cleaningOrder={cleaningOrder} />
      </tr>
    )}
  />
)
