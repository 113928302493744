import * as React from 'react'
import { ProductSalePageButton } from '@components/products/product-action-button'
import { SubscriptionContractDetails } from '@modules/subscription-contract/models'

interface Props {
  subscriptionDetails: SubscriptionContractDetails
}

export const SubscriptionContractDetailsActions = ({ subscriptionDetails }: Props): JSX.Element => (
  <div className="mt-2 text-right">
    <span>
      {!subscriptionDetails.is_paid && !subscriptionDetails.read_only && (
        <ProductSalePageButton saleUrl={subscriptionDetails.urls.sale} />
      )}
    </span>
  </div>
)
