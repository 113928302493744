import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { ServiceBand } from '@modules/bands/service-bands/models'
import { Button, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAppDispatch } from '@store/index'
import { useApiRequest, useFormRequest } from '@components/hooks/use-api-request'
import { useForm } from 'react-hook-form'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { updateServiceBand } from '@store/actions/service-band-actions'
import { Form } from '@hyper/forms/form'
import { ServiceBandUpdateForm } from '@modules/bands/service-bands/info-edit-dialog/form'
import { useAppData } from '@components/hooks/use-app-data'
import { createSelectOption } from '@helpers/utils'
import * as R from 'ramda'
import { ClientSelect } from '@components/client-select'
import { useModal } from '@components/modals/use-modal'
import { ClientDetails, ClientUser } from '@models/clients'
import { ContentLoader } from '@components/content-loader'
import { commonObjectGet } from '@store/actions/generic-actions'

interface Props extends BaseModalProps {
  serviceBandDetails: ServiceBand
}

export interface ServiceBandUpdateFormInputs {
  id: number
  initial_funds: number
  owner: number | string
  scopes: CustomReactSelectOption
  date_from: Date
  date_to: Date
}

export const ServiceBandInfoEditDialog: React.FC<Props> = ({ serviceBandDetails, toggleIsVisible }) => {
  const [client, setClient] = React.useState<ClientUser | undefined>()
  const [handleToggleClientDialog] = useModal('ClientDialog', { setClient })

  const { voucher_scopes, urls } = useAppData()
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const scopesOptions = voucher_scopes.map(({ id, name }) => createSelectOption(name, id))

  const methods = useForm<ServiceBandUpdateFormInputs>({
    defaultValues: {
      ...R.pick<ServiceBandUpdateFormInputs, keyof ServiceBandUpdateFormInputs>(
        ['id', 'initial_funds', 'date_from', 'date_to'],
        serviceBandDetails,
      ),
      client: serviceBandDetails.owner?.id,
      scopes: React.useMemo(
        () => scopesOptions.filter(option => serviceBandDetails.scopes.includes(option.value)),
        [scopesOptions, serviceBandDetails],
      ),
    },
  })

  const {
    clearErrors,
    setError,
    formState: { errors },
  } = methods

  const { action: fetchClientDetails, isLoading: isFetchingClientDetails } = useApiRequest(async () => {
    setClient(await commonObjectGet<ClientDetails>(`${urls.clients.clients}${serviceBandDetails.owner.id}/`))
  })

  React.useEffect(() => {
    fetchClientDetails()
    clearErrors('owner')
  }, [])

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: ServiceBandUpdateFormInputs) => {
    dispatch(await updateServiceBand([serviceBandDetails, { ...payload, owner: client?.id }]))
    addSuccessMessage()
    toggleIsVisible()
  }, setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Opaska testowa {serviceBandDetails.name}</ModalHeader>
      <ModalBody>
        <Row>
          <ServiceBandUpdateForm scopeOptions={scopesOptions} />
          <ContentLoader isLoading={isFetchingClientDetails}>
            <ClientSelect
              label="Właściciel"
              setClient={setClient}
              client={client}
              handleToggleClientDialog={handleToggleClientDialog}
              prefetchedClientDetails={true}
              error={errors.owner}
              colSize={12}
            />
          </ContentLoader>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton isSaving={isLoading} className="btn btn-green" />
      </ModalFooter>
    </Form>
  )
}
