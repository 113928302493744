import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { secondsToInterval } from '@helpers/date-helper'
import { CleaningOrder } from '@modules/housekeeping/models'

interface Props {
  cleaningOrder: CleaningOrder | null
}

export const HousekeepingBeddingOrdersCleaningStatusCell = ({ cleaningOrder }: Props): JSX.Element => (
  <td className="housekeeping__orders_table__column">
    {cleaningOrder ? (
      <>
        <p className="mb-1">{cleaningOrder?.status_display}</p>
        {cleaningOrder.status === 'CLEANING' && (
          <IconWithText
            icon="uil-clock lh-initial"
            text={`Czas sprzątania ${secondsToInterval(cleaningOrder.total_time_started)}s`}
            textClass="font-12"
          />
        )}
      </>
    ) : (
      <span>nie zlecone</span>
    )}
  </td>
)
