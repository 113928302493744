import * as React from 'react'
import { ReceptionBooking } from '@models/reception'
import { ReceptionListFiltersParams } from '@modules/reservations/list/reservation-list-with-params'
import Table, { TableHeaderValue } from '@components/table/table'
import { ReservationListPaymentSummary } from '@modules/reservations/list/reservation-list-payment-summary'
import { ReservationListTableRow } from '@modules/reservations/list/reservation-list-table-row'
import { ReservationListTableRowUnclosed } from '@modules/reservations/list/reservation-list-table-row-unclosed'
import { ReservationListType } from '@modules/reservations/list/models'

interface ReservationListTableProps {
  reservations: ReceptionBooking[]
  isLoading: boolean
  setFilters: (filters: ReceptionListFiltersParams) => void
  filters: ReceptionListFiltersParams
  type: ReservationListType
}

const headerValues: TableHeaderValue<ReceptionBooking>[] = [
  { title: 'Data utworzenia', sortField: 'created_at' },
  { title: 'Status', sortField: 'status' },
  { title: 'Pobyt', sortField: 'date_from' },
  { title: 'Nr rezerwacji', sortField: 'reservation_number' },
  { title: 'Ośrodek', sortField: 'resort_id' },
  { title: 'Klient', sortField: 'name' },
  { title: 'Należność' },
  { title: 'Wpłacono' },
  { title: 'Do zapłaty' },
  { title: 'Utworzone przez' },
]

const headerValuesUnclosed: TableHeaderValue<ReceptionBooking>[] = [
  { title: 'Data wymeldowania', sortField: 'date_to' },
  { title: 'Ośrodek', sortField: 'resort_id' },
  { title: 'Pobyt', sortField: 'date_from' },
  { title: 'Nr rezerwacji', sortField: 'reservation_number' },
  { title: 'Klient', sortField: 'name' },
]

export const ReservationListTable: React.FC<ReservationListTableProps> = ({
  reservations,
  setFilters,
  filters,
  isLoading,
  type,
}) => (
  <Table
    className="mb-0 table-hover overflow-hidden"
    filters={filters}
    setFilters={setFilters}
    headerValues={type === ReservationListType.Unclosed ? headerValuesUnclosed : headerValues}
    isLoading={isLoading}
  >
    {reservations.length ? (
      reservations.map(reservation => (
        <React.Fragment key={reservation.id}>
          {type === ReservationListType.Unclosed ? (
            <ReservationListTableRowUnclosed reservation={reservation} />
          ) : (
            <ReservationListTableRow
              reservation={reservation}
              withDetailsPreview={type === ReservationListType.Present || type === ReservationListType.Close}
            />
          )}
        </React.Fragment>
      ))
    ) : (
      <tr>
        <td className="text-center" colSpan={100}>
          Brak danych
        </td>
      </tr>
    )}
    {type !== ReservationListType.Unclosed && !!reservations.length && (
      <ReservationListPaymentSummary key="reservation-list-summary" reservations={reservations} />
    )}
  </Table>
)
