import * as React from 'react'
import { UnitDetails } from '@models/units'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { useAppSelector } from '@store/index'
import { accommodationTypesMapSelector, resortsMapSelector } from '@store/selectors/dashboard'
import { useAppData } from '@components/hooks/use-app-data'

interface Props {
  unit: UnitDetails
}

export const UnitDetailsInfo: React.FC<Props> = ({ unit }) => {
  const accommodationTypesMap = useAppSelector(accommodationTypesMapSelector)
  const accommodationType = accommodationTypesMap[unit.apartment?.accommodation_type_id || 0]
  const resortsMap = useAppSelector(resortsMapSelector)
  const resort = resortsMap[unit.resort_id]
  const { unit_kinds } = useAppData()
  const kind = React.useMemo(() => unit_kinds.find(kind => kind.id === unit.kind_id), [unit.kind_id, unit_kinds])

  return (
    <Card>
      <CardBody>
        <Row>
          <Col md={6}>
            <h4 className="text-secondary">Dane jednostki</h4>
          </Col>
          <Col md={6} className="text-right">
            <Link
              to={NavigationPath.UnitsWithParams.replace(':resort_id', String(unit.resort_id))}
              className="btn btn-light"
            >
              Wróć do listy jednostek
            </Link>
          </Col>
        </Row>
        <Row>
          <Col md={10}>
            <table className="w-100 table table-borderless table-sm mb-0">
              <tbody>
                <tr>
                  <td className="pl-0">Numer lokalu:</td>
                  <td className="text-strong font-16">{unit.apartment?.name || unit.name}</td>
                  <td>Typ lokalu</td>
                  <td className="text-strong font-16">{unit.apartment ? accommodationType.name : kind?.name}</td>
                </tr>
                <tr>
                  <td className="pl-0">Ośrodek:</td>
                  <td className="text-strong font-16">{resort.name}</td>
                  {unit.courtyard && (
                    <>
                      <td>Dziedziniec</td>
                      <td className="text-strong font-16">{unit.courtyard}</td>
                    </>
                  )}
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}
