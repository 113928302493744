import * as React from 'react'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { SubscriptionContractFeature } from '@modules/subscription-contract/models'
import { SubscriptionContractFeaturesTableRow } from '@modules/subscription-contract/details/information/features-table-row'

const headerValues: TableHeaderValue[] = [
  { title: 'LP' },
  { title: 'Nazwa benefitu' },
  { title: 'Kod promocyjny', className: 'text-nowrap' },
  { title: 'Numer rezerwacji' },
  { title: 'Kod aktywny' },
  { title: 'Kod użyty' },
  { title: 'Data ważności', className: 'text-nowrap' },
]

const defaultFilters: TableFilters = {
  search: '',
  ordering: '',
  page: 1,
  page_size: 9999,
}

interface Props {
  features: SubscriptionContractFeature[]
}

export const SubscriptionContractFeaturesTable = ({ features }: Props): JSX.Element => {
  const [filters, setFilters] = React.useState<TableFilters>(defaultFilters)

  return (
    <Table
      maxHeight="330px"
      className="table-hover w-100"
      headerValues={headerValues}
      isLoading={false}
      showPagination={false}
      filters={filters}
      setFilters={setFilters}
    >
      {features.map((feature: SubscriptionContractFeature, index: number) => (
        <SubscriptionContractFeaturesTableRow key={feature.id} lp={index + 1} feature={feature} />
      ))}
    </Table>
  )
}
