import * as React from 'react'
import { ColAuto } from '@hyper/col-auto'
import { Button, FormGroup } from 'reactstrap'
import { useFormContext, useWatch } from 'react-hook-form'
import * as R from 'ramda'

interface SubmitFilterProps {
  handleReset: () => void
  formGroupClassName?: string
  hideLabel?: boolean
}

export const SubmitFilter: React.FC<SubmitFilterProps> = ({ handleReset, hideLabel, formGroupClassName }) => {
  const { control, formState } = useFormContext()
  const formValues = useWatch({ control })

  if (R.equals(formValues, formState.defaultValues)) return null

  return (
    <ColAuto>
      <FormGroup className={formGroupClassName}>
        {!hideLabel && <label className="d-block">&nbsp;</label>}
        <Button color="light" className="ml-1" type="button" onClick={handleReset}>
          Wyczyść
        </Button>
      </FormGroup>
    </ColAuto>
  )
}
