import * as React from 'react'
import { FormSelect } from '@hyper/forms/form-select'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { Button } from 'reactstrap'
import { useFormContext, useWatch } from 'react-hook-form'
import { useModal } from '@components/modals/use-modal'
import { NotificationKind } from '@models/notifications'
import { useNotificationsTemplatePreview } from '@components/notification-box/use-notifications-template-preview'

interface Props {
  name: string
  options: CustomReactSelectOption[]
  placeholder: JSX.Element
  previewUrl?: string
  notificationKind: NotificationKind
  onSend?: () => void
  isDisabled: boolean
}

export const NotificationTemplateSelect = ({
  options,
  name,
  placeholder,
  previewUrl,
  notificationKind,
  onSend,
  isDisabled,
}: Props): React.ReactNode => {
  const [showNotificationPreview] = useModal('NotificationPreviewModal')
  const { control, reset } = useFormContext()
  const selectedTemplate = useWatch({ control, name })

  const { send, isSending } = useNotificationsTemplatePreview(previewUrl, selectedTemplate?.value, notificationKind)

  const handleShowPreview = () => {
    showNotificationPreview(null, {
      previewUrl: previewUrl,
      notificationId: selectedTemplate.value,
      notificationKind,
    })
  }

  const handleSend = async () => {
    await send(() => {
      reset()
      onSend?.()
    })
  }

  const actionsEnabled = selectedTemplate && !isSending && !isDisabled

  return (
    <div className="d-flex">
      <FormSelect
        formPlainProps={{ colSize: 10, colClassName: 'pl-1 pr-1 ml-n1' }}
        options={options}
        name={name}
        selectProps={{
          isDisabled,
          isOptionDisabled: (option: CustomReactSelectOption) => !!option.disabled,
          isClearable: true,
          noOptionsMessage: () => 'Brak szablonów',
          placeholder,
        }}
      />
      {previewUrl && (
        <Button
          className="btn-light btn-tall col-1 p-0"
          disabled={!actionsEnabled}
          style={{ maxHeight: 35 }}
          title="Wyślij wiadomość"
          onClick={handleShowPreview}
        >
          <i className="uil-eye" />
        </Button>
      )}
      <Button
        className="btn-primary btn-tall col-1 p-0 ml-1"
        disabled={!actionsEnabled}
        style={{ maxHeight: 35 }}
        title="Wyślij wiadomość"
        onClick={handleSend}
      >
        <i className={isSending ? 'spinner-border avatar-3xs' : 'uil-message'} />
      </Button>
    </div>
  )
}
