import { RootState, useAppSelector } from '@store/index'
import * as React from 'react'

export const PackagesSummary: React.FC = () => {
  const { package_count, package_created_count, package_done_count, package_code_count } = useAppSelector(
    (state: RootState) => state.packageState.stats,
  )

  return (
    <div className="mb-3">
      <span className="mr-2">
        <strong className="mr-1">Wszystkich sprzedanych pakietów:</strong>
        {package_count}
      </span>
      <span className="mr-2">
        <strong className="mr-1">Suma sprzedanych kodów</strong>
        {package_code_count}
      </span>
      <span className="mr-2">
        <strong className="mr-1">Założonych rezerwacji:</strong>
        {package_created_count}
      </span>
      <span>
        <strong className="mr-1">Odbytych rezerwacji:</strong>
        {package_done_count}
      </span>
    </div>
  )
}
