import * as React from 'react'
import { Card, CardBody, CardTitle } from 'reactstrap'
import { IconWithText } from '@components/icon-with-text'
import { UpSellBookingClient, UpSellBookingDetails } from '@models/upselling'
import { UpsellBookingsDetailsProductsSaleCardRow } from '@modules/upselling/upsell-booking-details/products/sale-card/upsell-bookings-details-products-sale-card-row'
import classNames from 'classnames'
import { ButtonWithIcon } from '@components/button-with-icon'

interface Props {
  upsellBooking: UpSellBookingDetails
  listExpanded: boolean
  toggleListExpanded: () => void
}

export const UpsellBookingsDetailsProductsSaleCard = ({
  upsellBooking,
  listExpanded,
  toggleListExpanded,
}: Props): JSX.Element => {
  const [cardRef, setCardRef] = React.useState<HTMLElement | null>(null)
  const [hasScrollBar, setHasScrollBar] = React.useState(false)
  const products = getTabs(upsellBooking.client).sort((a, b) => a.count - b.count)

  React.useEffect(() => {
    if (cardRef?.scrollHeight) setHasScrollBar(cardRef?.scrollHeight > 350)
  }, [cardRef])

  return (
    <div className="col-4">
      <Card>
        <CardTitle className="py-2 px-3 upsell__products-list__title">
          <IconWithText
            icon="uil-plus font-16"
            text="Sprzedaj produkt"
            wrapperClassNames="text-secondary font-weight-bold"
          />
        </CardTitle>
        <CardBody
          className={classNames('px-3 upsell__products-list__sale py-0', { 'mh-100': listExpanded })}
          innerRef={ref => setCardRef(ref)}
        >
          <table className={classNames('table table-striped table-sm', hasScrollBar ? 'mb-0' : 'mb-2')}>
            <tbody>
              {products.map(product => (
                <UpsellBookingsDetailsProductsSaleCardRow
                  key={product.kind}
                  name={product.name}
                  kind={product.kind}
                  client={upsellBooking.client}
                />
              ))}
            </tbody>
          </table>
        </CardBody>
        {hasScrollBar && (
          <ButtonWithIcon
            btnClass="border-top mt-2 shadow-none"
            iconWrapperClassName="flex-row-reverse"
            color="transparent"
            icon={classNames('ml-2 font-18 lh-1', listExpanded ? 'uil-angle-down' : 'uil-angle-up')}
            text={listExpanded ? 'Zwiń' : 'Pokaż wszystkie produkty'}
            handleClick={toggleListExpanded}
          />
        )}
      </Card>
    </div>
  )
}

const getTabs = (client: UpSellBookingClient) => [
  {
    name: 'Pakiety prywatne',
    kind: 'DEFAULT' as const,
    count: getActiveProductCount(client.subscriptions, 'type', 'DEFAULT'),
  },
  {
    name: 'Pakiety korzyści',
    kind: 'WITH_BENEFIT' as const,
    count: getActiveProductCount(client.subscriptions, 'type', 'WITH_BENEFIT'),
  },
  {
    name: 'Pakiety korzyści 2.0',
    kind: 'WITH_BENEFIT_20' as const,
    count: getActiveProductCount(client.subscriptions, 'type', 'WITH_BENEFIT_20'),
  },
  {
    name: 'GO!Holiday',
    kind: 'GO_HOLIDAY' as const,
    count: getActiveProductCount(client.subscriptions, 'type', 'GO_HOLIDAY'),
  },
  {
    name: 'Strzał w dziesiątkę',
    kind: 'gastro_coupon' as const,
    count: getActiveProductCount(client.gastro_cards, 'kind', 'gastro_coupon'),
  },
  {
    name: 'Doładowanie smaku',
    kind: 'gastro_card_boost' as const,
    count: getActiveProductCount(client.gastro_cards, 'kind', 'gastro_card_boost'),
  },
  {
    name: 'Podwójna radość 2024',
    kind: 'gastro_card_2024' as const,
    count: getActiveProductCount(client.gastro_cards, 'kind', 'gastro_card_2024'),
  },
  {
    name: 'Podwójna radość 2025',
    kind: 'gastro_card_2025' as const,
    count: getActiveProductCount(client.gastro_cards, 'kind', 'gastro_card_2025'),
  },
  {
    name: 'Subskrypcje',
    kind: 'subscription_contract' as const,
    count: getActiveProductCount(client.subscription_contracts),
  },
]

const getActiveProductCount = <T extends { status: string }>(products: T[], field?: keyof T, fieldValue?: string) =>
  products.filter(
    product => ['active', 'confirmed', 'initial'].includes(product.status) && (!field || product[field] === fieldValue),
  ).length
