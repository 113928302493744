import * as React from 'react'
import timelineHelper from '@helpers/timeline-helper'
import { Apartment, RentSessionTag, RentSessionTags } from '@models/apartment'
import { differenceInDays, isWithinInterval, set } from 'date-fns'
import { RootState, useAppSelector } from '@store/index'
import { selectRentSessions } from '@store/selectors/timeline'
import { parseISODate } from '@helpers/date-helper'
import { CalendarDate, TimelineRentSession } from '@store/reducers/timeline-reducers'
import classNames from 'classnames'

interface Props {
  renderedDates: CalendarDate[]
  allApartments: Apartment[]
}

export const TimelineVirtualizeSessions = ({ renderedDates, allApartments }: Props): React.ReactNode => {
  const rentSessions = useAppSelector(selectRentSessions)
  const isCompactView = useAppSelector((state: RootState) => state.timelineState.isCompactView)

  const apartmentsWithSessions = allApartments.reduce((prev, apartment) => {
    const hasSessionTag = apartment.tags.some(tag => RentSessionTags.includes(tag.keyword as RentSessionTag))

    if (!hasSessionTag) return prev

    return [
      ...prev,
      {
        apartment,
        sessions: rentSessions.filter((session: TimelineRentSession) => {
          if (!apartment.tags.some(tag => tag.keyword === session.keyword)) return false
          return rentSessions.some((session: TimelineRentSession) => {
            const now = new Date()

            const firstRenderedDate = renderedDates[0]
            const lastRenderedDate = renderedDates[renderedDates.length - 1]

            const { start, end } = {
              start: set(now, { year: firstRenderedDate.year, month: firstRenderedDate.month - 1, date: 1 }),
              end: set(now, { year: lastRenderedDate.year, month: lastRenderedDate.month - 1, date: 1 }),
            }

            const isInDateFromRange = isWithinInterval(parseISODate(session.date_from) as Date, { start, end })
            const isInDateToRange = isWithinInterval(parseISODate(session.date_to) as Date, { start, end })

            return isInDateFromRange || isInDateToRange
          })
        }),
      },
    ]
  }, [])

  return apartmentsWithSessions.map(({ apartment, sessions }) =>
    sessions.map((session: TimelineRentSession) => (
      <div
        className={classNames('calendar-content__session', { 'is-compact': isCompactView })}
        key={session.id}
        style={{
          left: timelineHelper.getItemLeftOffset(renderedDates, session.date_from),
          width: timelineHelper.getItemWidth(
            differenceInDays(parseISODate(session.date_to) as Date, parseISODate(session.date_from) as Date),
          ),
          top: timelineHelper.getItemTopOffset(apartment, allApartments),
        }}
      />
    )),
  )
}
