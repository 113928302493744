import * as React from 'react'
import { FormSelect } from '@hyper/forms/form-select'
import { Row } from 'reactstrap'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { FormProvider, useForm } from 'react-hook-form'
import { SubmitFilter } from '@modules/reception/common/filters/submit-filter'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { ColAuto } from '@hyper/col-auto'
import { useModal } from '@components/modals/use-modal'
import { ClientDetails } from '@models/clients'
import { ClientDetailsModalPackageVipFilters } from '@modules/crm/clients/details-modal/packages-vip/package-vips'

interface Props {
  filters: ClientDetailsModalPackageVipFilters
  defaultFilters: ClientDetailsModalPackageVipFilters
  onFiltersChange: (filters: ClientDetailsModalPackageVipFilters) => void
  packageVipStatusOptions: CustomReactSelectOption[]
  client: ClientDetails
  onClientRefresh: () => void
}

export const ClientDetailsModalPackageVipsTableFilters = ({
  filters,
  defaultFilters,
  onFiltersChange,
  packageVipStatusOptions,
  onClientRefresh,
  client,
}: Props): JSX.Element => {
  const methods = useForm<ClientDetailsModalPackageVipFilters>({
    defaultValues: filters,
  })

  const handleReset = () => {
    methods.reset()
    onFiltersChange(defaultFilters)
  }

  useHandleTableFiltersChange(methods.watch, filters, onFiltersChange)

  const [showPackageVipModal] = useModal('PackageVipDialog', {
    fetchPackageVips: onClientRefresh,
    packageVipClient: client,
  })

  return (
    <FormProvider {...methods}>
      <Row>
        <FormSelect
          options={packageVipStatusOptions}
          name="statuses"
          label="Status"
          selectProps={{ isSelectMulti: true, isClearable: true }}
          formPlainProps={{ colSize: 4 }}
        />

        <SubmitFilter handleReset={handleReset} />
        <ColAuto className="ml-auto col-auto">
          <button type="button" className="btn btn btn-outline-secondary" onClick={showPackageVipModal}>
            <i className="uil-plus mr-1" />
            Dodaj nowy pakiet VIP
          </button>
        </ColAuto>
      </Row>
    </FormProvider>
  )
}
