import { BaseObj, BaseUrls } from '@models/base'

export interface Seller {
  id: number
  name: string
  department_display: string
  department: 'BOK' | 'BOS' | 'B2B' | 'reception' | 'director' | 'other'
  resort_ids: number[]
  user_id: number | null
  gender: 'female' | 'male'
  gender_display: string
  group: string
  phone: string
  urls: BaseUrls
}
export type RecommendationGiftKind =
  | 'money_transfer_250'
  | 'money_transfer_1000'
  | 'money_transfer_1250'
  | 'money_transfer_1500'

export interface CrmAppData {
  recommendation_gift_kinds: [RecommendationGiftKind, string][]
  client_notifications: [string, string][]
}

export interface RecommendationGift extends BaseObj {
  seller_display: string
  seller: number | null
  number: string
  kind: RecommendationGiftKind
  kind_display: string
  recommending_person_name: string
  recommended_person_name: string
  received_date: string
  transfer_by_day: string
  transfer_amount: string
  account_number: string
  recommended_related_number: string
  recommending_related_number: string
  transfer_enter_date: string
  transfer_date: string
  transfer_status: 'transfer_sent' | 'transfer_in_package' | 'initial'
  transfer_status_display: string
  account_number_formatted: string
  created: string
  urls: BaseUrls
}

export interface RecommendationGifDetails extends RecommendationGift {
  promocode: null
}

export const accountNumberMask = [
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export const PESEL_MASK = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
