import * as React from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useFormRequest } from '@components/hooks/use-api-request'
import { commonObjectPost } from '@store/actions/generic-actions'
import { CartSelection } from '@modules/reception/cart/payment-box/reception-booking-cart-payment-box'
import { ReceptionBookingDetailsCart } from '@models/reception'
import { BaseCartPaymentResponse } from '@modules/reception/cart/models'
import { CardPaymentBox } from '@components/card-payment-box'
import { useSelector } from 'react-redux'
import { RootState } from '@store/index'

interface FormInputs {
  amount: string
}

interface Props {
  onDecline: () => void
  url: string
  cartSelection: CartSelection
  onCartUpdate: (cart: ReceptionBookingDetailsCart) => void
  bookingId: number
}

export const ReceptionBookingCartPaymentCard = ({
  onDecline,
  cartSelection,
  url,
  onCartUpdate,
  bookingId,
}: Props): JSX.Element => {
  const contentType = useSelector((state: RootState) => state.appState.appData.content_types.booking)

  const methods = useForm<FormInputs>({ defaultValues: { amount: cartSelection.amount } })

  const amount = useWatch({ control: methods.control, name: 'amount' })

  const { action: handlePay, isLoading } = useFormRequest(async () => {
    const response = await commonObjectPost<BaseCartPaymentResponse>(url, {
      ...cartSelection,
      amount,
    })

    onCartUpdate(response.cart_details)
    onDecline()
  }, methods.setError)

  return (
    <FormProvider {...methods}>
      <div className="reception-booking-cart__payments__card">
        <CardPaymentBox
          isSaving={isLoading}
          kind="receipt"
          onCancel={onDecline}
          onSubmit={handlePay}
          restToPay={cartSelection.amount}
          content_type={contentType}
          object_id={bookingId}
          abortLabel="Anuluj płatność"
        />
      </div>
    </FormProvider>
  )
}
