import { AsyncThunkParams, RootState } from '@store/index'
import { CancelTokenSource } from 'axios'

import { ShopAppData, ShopWarehouseInvoice, ShopWarehouseInvoicesFiltersParams } from '@modules/shop/models'
import { setDashboardStandardPaginator } from '@store/actions/dashboard-actions'
import { Receipt, ShopProduct } from '@models/shop'
import {
  commonObjectDelete,
  commonObjectGet,
  commonObjectPatch,
  commonObjectPost,
  commonObjectPut,
  wrapWithError,
} from '@store/actions/generic-actions'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { PaginationResponse } from '@models/dashboard'
import { ShopProductPayload } from '@modules/shop/pos/models'

export const deleteShopReceipt = createAsyncThunk<Receipt, Receipt, { state: RootState }>(
  'shop/deleteShopReceipt',
  async receipt => {
    await commonObjectDelete<void>(receipt.urls.details)
    return receipt
  },
)

export const getShopAppData = createAsyncThunk<ShopAppData, void, AsyncThunkParams>(
  'shop/getShopAppData',
  async (_, { getState }) => {
    const appData = getState().shopState.appData
    if (appData.status === 'unknown') {
      return await commonObjectGet<ShopAppData>(getState().appState.appData.urls.shop.app_data)
    }
    return appData
  },
)

interface CreateShopReceiptPayload {
  resort: number
  tickets: number
  items: ShopProductPayload[]
  apartment?: number
}
export const recalculateShopReceipt = createAsyncThunk<Receipt, [string, CreateShopReceiptPayload]>(
  'shop/recalculateShopReceipt',
  async ([url, payload]) => await wrapWithError(commonObjectPatch<Receipt>(url, payload)),
)
export const updateShopReceipt = createAsyncThunk<Receipt, [string, Partial<Receipt>]>(
  'shop/updateShopReceipt',
  async ([url, payload]) => await wrapWithError(commonObjectPut<Receipt>(url, payload)),
)
export const createShopReceipt = createAsyncThunk<Receipt, CreateShopReceiptPayload, { state: RootState }>(
  'shop/createShopReceipt',
  async (payload, { getState }) =>
    await commonObjectPost<Receipt>(getState().appState.appData.urls.shop.receipt_create, payload),
)
export const confirmShopReceipt = createAsyncThunk<Receipt, [string, Partial<ShopProductActionPayload>]>(
  'shop/confirmShopReceipt',
  async ([url, payload]) => await wrapWithError(commonObjectPut<Receipt>(url, payload)),
)

export const getShopReceipt = createAsyncThunk<Receipt, string, { state: RootState }>(
  'shop/getShopReceipt',
  async (token, { getState }) =>
    await commonObjectGet<Receipt>(`${getState().appState.appData.urls.shop.receipts}${token}/`),
)

export const getWarehouseInvoices = createAsyncThunk<
  ShopWarehouseInvoice[],
  Partial<ShopWarehouseInvoicesFiltersParams>,
  AsyncThunkParams
>('shop/getWarehouseInvoices', async (filters, { getState, dispatch }) => {
  const url = getState().appState.appData.urls.shop.documents

  const data = await commonObjectGet<PaginationResponse<ShopWarehouseInvoice>>(url, filters)
  dispatch(setDashboardStandardPaginator({ ...data }))
  return data.results
})

export const getShopProducts = createAsyncThunk<ShopProduct[], string, { state: RootState }>(
  'shop/getShopProducts',
  async (resort, { getState }) => {
    const url = getState().appState.appData.urls.shop.products
    return await commonObjectGet<ShopProduct[]>(url, { resort })
  },
)

export interface ApartmentGuest {
  id: number
  name: string
  limit: string
  apartment: string
}

export async function getGuestsForApartment(resortId: string, roomId: string, url: string): Promise<ApartmentGuest[]> {
  return await commonObjectGet<ApartmentGuest[]>(url, { resort_id: resortId, room_id: roomId, source: 'shop' })
}

export interface ShopProductActionPayload {
  resort: string
  payment: any
  apartment?: number
  guest?: number
  tickets?: number
  items: any
}

export const calculateShopProduct = async (
  payload: ShopProductActionPayload,
  cancelTokenSource: CancelTokenSource,
  url: string,
) => await commonObjectPost<any>(url, payload, cancelTokenSource)
