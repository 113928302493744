import * as React from 'react'
import { ReservationCard } from '@modules/reservations/details/common/reservation-card'
import { ReceptionBookingDetails } from '@models/reception'
import { IconWithText } from '@components/icon-with-text'
import { ReceptionBookingGuest } from '@models/booking'
import { ReceptionBookingCheckInPreviewGuestRow } from '@modules/reception/checkin/preview/guests/reception-booking-check-in-preview-guest-row'

interface Props {
  bookingDetails: ReceptionBookingDetails
  onShowDefaultCheckinModalStep: (step: number) => void
}

export const ReceptionBookingCheckInPreviewGuests = ({
  bookingDetails,
  onShowDefaultCheckinModalStep,
}: Props): JSX.Element => (
  <ReservationCard
    title="Osoby towarzyszące"
    cardClassName="mb-2"
    cardActions={
      <IconWithText
        icon="uil-edit-alt font-14"
        text="zmień"
        textClass="font-12"
        wrapperClassNames="text-muted cursor-pointer"
        flexClassName="align-items-start"
        onClick={() => onShowDefaultCheckinModalStep(2)}
      />
    }
  >
    {bookingDetails.guests.map((guest: ReceptionBookingGuest) => (
      <ReceptionBookingCheckInPreviewGuestRow
        key={guest.id}
        guest={guest}
        onShowDefaultCheckinModalStep={onShowDefaultCheckinModalStep}
      />
    ))}
  </ReservationCard>
)
