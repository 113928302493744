import * as React from 'react'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAppDispatch } from '@store/index'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { ButtonWithIcon } from '@components/button-with-icon'
import { BaseModalProps } from '@components/modals/types'
import { SubscriptionContractDetails } from '@modules/subscription-contract/models'
import { updateSubscriptionContractDetails } from '@store/slices/subscription-contract-slice'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'

interface Props extends BaseModalProps {
  subscriptionContractDetails: SubscriptionContractDetails
}

export const SubscriptionContractDetailsCancel = ({
  toggleIsVisible,
  subscriptionContractDetails,
}: Props): JSX.Element => {
  const { addSuccessNotification } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading: isRemoving, action: handleRemove } = useApiRequest(async reason => {
    dispatch(
      updateSubscriptionContractDetails(
        await commonObjectDelete<SubscriptionContractDetails>(subscriptionContractDetails.urls.details, reason),
      ),
    )
    addSuccessNotification('Subskrypcja została anulowana')
    toggleIsVisible()
  })

  return (
    <CommonObjectConfirmAction
      isLoading={isRemoving}
      handleAccept={handleRemove}
      askForReason={true}
      message={
        <>
          Czy na pewno chcesz anulować subskrypcję nr. {subscriptionContractDetails.number} ? Wszystkie benefity zostaną
          anulowane.
        </>
      }
    >
      <ButtonWithIcon icon="uil-trash-alt" color="danger" text="Anuluj" />
    </CommonObjectConfirmAction>
  )
}
