import * as React from 'react'
import { Card, CardBody } from 'reactstrap'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { PageTitleWithSize } from '@hyper/page-title-with-size'
import { HrWorkerListFilterParams, HrWorkersListFilter } from '@modules/hr/workers/list/filters'
import { HrWorkersListTable } from '@modules/hr/workers/list/table'
import { useQueryWithPaginationResponse } from '@components/hooks/use-query-hooks'
import { useGetHrWorkersQuery } from '@api/agreements'
import { HrWorker } from '@modules/hr/workers/models'

const defaultFilters: HrWorkerListFilterParams = {
  search: '',
  ordering: '-created',
  page: 1,
  page_size: 10,
  resorts: [],
  companies: [],
  job_positions: [],
  status: null,
  agreement_status: null,
  show_removed: false,
}

export const HrWorkersListView: React.FC = () => {
  useMenuActive(NavigationPath.HrWorkersList)
  const [filters, setFilters] = React.useState<HrWorkerListFilterParams>(defaultFilters)

  const {
    data: workers,
    isLoading,
    isFetching,
  } = useQueryWithPaginationResponse<HrWorker[]>(useGetHrWorkersQuery({ ...filters, is_active: !filters.show_removed }))

  return (
    <>
      <PageTitleWithSize title="Lista Pracowników" />
      <Card>
        <CardBody>
          <HrWorkersListFilter defaultFilters={defaultFilters} filters={filters} setFilters={setFilters} />
          <HrWorkersListTable
            isLoading={isLoading || isFetching}
            workers={workers}
            filters={filters}
            setFilters={setFilters}
          />
        </CardBody>
      </Card>
    </>
  )
}
