import * as React from 'react'
import { HrWorkerDetails } from '@modules/hr/workers/models'
import { UserPermission } from '@models/dashboard'
import { HrWorkerDeleteButton } from '@modules/hr/workers/details/delete-button'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { HrWorkerRestoreButton } from '@modules/hr/workers/details/restore-button'

const ActiveAgreementStatusesToRemove = ['draft', 'draft_incomplete', 'completed', 'resignation']

interface Props {
  worker: HrWorkerDetails
  onWorkerRemove: () => void
}

export const HrWorkerProfileActions = ({ worker, onWorkerRemove }: Props): React.ReactNode => {
  const user = useAuthenticatedUser()

  const canRemoveWorker = worker.agreements.every(
    agreement => !ActiveAgreementStatusesToRemove.includes(agreement.status),
  )

  if (worker.is_active) {
    if (!user.hasPerm(UserPermission.HrWorkerProfileCanRemove) || !canRemoveWorker) return null
    return <HrWorkerDeleteButton worker={worker} toggleIsVisible={onWorkerRemove} />
  }

  if (!user.hasPerm(UserPermission.HrWorkerProfileCanRestore)) return null
  return <HrWorkerRestoreButton worker={worker} />
}
