import * as React from 'react'
import classNames from 'classnames'
import { Col, Row } from 'reactstrap'
import { FormInput } from '@hyper/forms'
import { useFormContext, useWatch } from 'react-hook-form'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { IconWithText } from '@components/icon-with-text'

export interface EmailInviteField {
  sent_at: string
  email: string
}

interface Props {
  fieldsAmount: number
  onRemove: () => void
  index: number
  invites: EmailInviteField[]
}

export const EmailInviteRow = ({ onRemove, index, fieldsAmount, invites }: Props): React.ReactNode => {
  const { control } = useFormContext()
  const emails = useWatch({ control, name: 'emails' })

  const invitation = invites.find(invite => invite.email === emails[index]?.value)

  return (
    <Col md={8} className={classNames('ml-auto', { 'mb-1': index < fieldsAmount - 1 })}>
      <Row className="align-items-center">
        <FormInput colSize={9} name={`emails.${index}.value`} formGroupClassName="mb-0" placeholder="adres@email.pl" />
        {fieldsAmount > 1 && <i className="uil-trash-alt font-16 text-muted ml-2 cursor-pointer" onClick={onRemove} />}
      </Row>
      {!!invitation && (
        <div className="font-11">
          <span>Ostatnio wysłany {toDefaultDateTimeFormat(invitation.sent_at)}</span>
          <IconWithText icon="uil-sync font-16" text="Wyślij ponownie" wrapperClassNames="text-secondary ml-3 mt-1" />
        </div>
      )}
    </Col>
  )
}
