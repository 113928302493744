import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { ReceptionBookingDetails } from '@models/reception'
import classnames from 'classnames'

interface Props {
  booking: ReceptionBookingDetails
}

export const ReservationGuestExpandedContent = ({ booking }: Props): JSX.Element => (
  <div className="pl-3 border-top">
    <GuestInfo icon="uil-map-marker" title="Adres zamieszkania:">
      <strong>{booking.street || 'brak ulicy'}</strong>
      <strong className="d-block">
        {booking.postcode || 'brak kodu pocztowego'}, {booking.city || 'brak miasta'}
      </strong>
      <strong>{booking.country || 'brak państwa'}</strong>
    </GuestInfo>

    <GuestInfo icon="uil-postcard" title="Seria i numer dokumentu:">
      <strong className="d-block">
        {booking.service_room_data?.id_card || <span className="fw-semi-bold">brak</span>}
      </strong>
    </GuestInfo>

    <GuestInfo icon="uil-receipt" title="Dane do faktury:" borderless>
      {booking.invoice_nip ? (
        <>
          <strong>NIP: {booking.invoice_nip}</strong>

          <div className="font-12 mt-1">
            <span>{booking.invoice_company}</span>
            <span className="d-block">{booking.street}</span>
            <span>
              {booking.postcode}, {booking.invoice_city}
            </span>
          </div>
        </>
      ) : (
        <span className="fw-semi-bold">Rezerwacja bez faktury</span>
      )}
    </GuestInfo>
  </div>
)

const GuestInfo = ({ title, icon, children, borderless = false }) => (
  <div className={classnames('py-2', { 'border-bottom': !borderless })}>
    <IconWithText
      icon={classnames('font-16 lh-0', icon)}
      text={title}
      flexClassName="d-block"
      wrapperClassNames="mb-1"
    />
    <div className="ml-3">{children}</div>
  </div>
)
