import * as React from 'react'
import { Button, Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import { SaveButton } from '@hyper/button'
import { FormProvider, useForm } from 'react-hook-form'
import { useFormRequest } from '@components/hooks/use-api-request'
import { commonObjectPost, commonObjectPut } from '@store/actions/generic-actions'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { BookingSmartWatchAssignment, ReceptionBookingDetails } from '@models/reception'
import { CustomReactSelect, CustomReactSelectOption } from '@components/custom-react-select'
import { KeyboardScanner } from '@components/keyboard-scanner'
import { getResortDetails, ImprovementActionPayload } from '@store/actions/reception-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { ReceptionBookingDetailsImprovement } from '@models/booking'
import { SmartWatchAddModalRemove } from '@modules/reception/checkin/step-improvements/smart-watch-add-modal/remove'
import { NavigationPath } from '@models/routes'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { setReceptionBookingDetails } from '@store/slices/reception-slice'

interface Props extends BaseModalProps {
  getPayload: () => ImprovementActionPayload
  booking: ReceptionBookingDetails
  smartWatchAssignment?: BookingSmartWatchAssignment
  bookingImprovement?: ReceptionBookingDetailsImprovement
}

interface FormInputs {
  smart_watch: CustomReactSelectOption | undefined
}

export const SmartWatchAddModal: React.FC<Props> = ({
  booking,
  bookingImprovement,
  smartWatchAssignment,
  toggleIsVisible,
  getPayload,
}) => {
  const user = useAuthenticatedUser()
  const canManageSmartWatchUUID = user.hasPerm(UserPermission.BookingCanManageSmartWatchUUID)
  const activeMenu = useAppSelector((state: RootState) => state.appState.menuActive)
  const isAddingFromReservationView = activeMenu.includes(NavigationPath.ReservationListWithParams.replace(':type', ''))

  const smartWatchOptions = (
    useAppSelector((state: RootState) => state.receptionState.resortDetails?.smart_watches) || []
  ).map(row => ({ value: String(row.uid), label: row.uid }))

  const methods = useForm<FormInputs>({
    defaultValues: {
      smart_watch: smartWatchOptions.find(row => row.value === smartWatchAssignment?.uid),
    },
  })
  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()

  const refreshResortDetails = () =>
    dispatch(getResortDetails(booking.resort_id, booking.accommodation_type_id, booking.date_from, booking.date_to))

  React.useEffect(() => {
    refreshResortDetails()
  }, [])

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    const data = {
      smart_watch: payload.smart_watch?.value,
      booking_improvement: bookingImprovement?.id,
      ...getPayload(),
      ...(!smartWatchAssignment && isAddingFromReservationView && { smart_watch_uuid_required: false }),
    }

    dispatch(
      setReceptionBookingDetails(
        smartWatchAssignment
          ? await commonObjectPut<ReceptionBookingDetails>(smartWatchAssignment.urls.details, data)
          : await commonObjectPost<ReceptionBookingDetails>(booking.urls.smart_watch, data),
      ),
    )
    addSuccessNotification('Ulepszenie zostało dodane!')
    toggleIsVisible()
  }, methods.setError)

  const onScanComplete = (uid: string) => {
    methods.setValue(
      'smart_watch',
      smartWatchOptions.find(row => row.value === uid),
    )
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggleIsVisible}>
          {smartWatchAssignment ? 'Edytuj kod zegarka' : 'Dodawanie kodu zegarka'}
        </ModalHeader>
        <ModalBody>
          {canManageSmartWatchUUID ? (
            <Row>
              <Col md={12}>
                <KeyboardScanner onScanComplete={onScanComplete} />
                <CustomReactSelect
                  fieldName="smart_watch"
                  label="Wpisz lub zeskanuj numer UID zegarka:"
                  options={smartWatchOptions}
                  placeholder="Wprowadź numer UID"
                />
              </Col>
            </Row>
          ) : (
            'Numer UID zegarka zostanie dodany podczas meldowania gościa.'
          )}
        </ModalBody>
        <ModalFooter style={{ display: 'block' }}>
          <Row>
            <Col md={6} className="px-0">
              {smartWatchAssignment && (
                <SmartWatchAddModalRemove
                  toggleIsVisible={toggleIsVisible}
                  refreshResortDetails={refreshResortDetails}
                  smartWatchAssignment={smartWatchAssignment}
                />
              )}
            </Col>
            <Col md={6} className="px-0 text-right">
              <Button className="btn-light" onClick={toggleIsVisible}>
                Anuluj
              </Button>
              <SaveButton className="btn btn-green ml-1" isSaving={isLoading} />
            </Col>
          </Row>
        </ModalFooter>
      </form>
    </FormProvider>
  )
}
