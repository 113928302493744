import * as React from 'react'
import { PackageWholesaleDetails } from '@modules/package-wholesale/models'
import { Card, CardBody } from 'reactstrap'
import { CounterData, PackageWholesaleDetailsAnalysisChart } from '@modules/package-wholesale/details/analysis/chart'
import { asDecimal, sumDecimalArray } from '@helpers/utils'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectGet } from '@store/actions/generic-actions'
import { ContentLoader } from '@components/content-loader'

interface Props {
  packageWholesaleDetails: PackageWholesaleDetails
}

export const PackageWholesaleDetailsAnalysis = React.forwardRef(
  ({ packageWholesaleDetails }: Props, ref: React.RefObject<HTMLDivElement>): JSX.Element => {
    const [counterData, setCounterData] = React.useState<CounterData[]>([])

    const { action: fetchAnalysisData, isLoading } = useApiRequest(async () => {
      setCounterData(await commonObjectGet(packageWholesaleDetails?.urls.counter))
    })

    React.useEffect(() => {
      fetchAnalysisData()
    }, [])

    const totalVisitsCount = sumDecimalArray(counterData.map(({ count }) => asDecimal(count))).toString()

    return (
      <Card>
        <CardBody>
          <ContentLoader isLoading={isLoading}>
            <div
              className="d-flex justify-content-between align-items-center"
              ref={ref}
              style={{ scrollMargin: '100px' }}
            >
              <h4 className="mt-0 text-secondary mb-3">Analiza</h4>

              <div>
                <span className="pr-2">
                  Łączna liczba wejść: <strong>{totalVisitsCount}</strong>
                </span>
              </div>
            </div>
            {counterData && (
              <PackageWholesaleDetailsAnalysisChart
                packageWholesaleDetails={packageWholesaleDetails}
                counterData={counterData}
              />
            )}
          </ContentLoader>
        </CardBody>
      </Card>
    )
  },
)
