import * as React from 'react'
import Table from '@components/table/table'
import { useModal } from '@components/modals/use-modal'
import { RentalProductCategory } from '@modules/rental/models'

interface Props {
  productCategory: RentalProductCategory
  index: number
}

export const RentalProductCategoriesTableRow = ({ productCategory, index }: Props): JSX.Element => {
  const [showProductModal] = useModal('RentalProductCategoryCreateModal', { productCategory })

  return (
    <Table.Row key={productCategory.id}>
      <Table.Cell width={70}>{index + 1}</Table.Cell>
      <Table.Cell>{productCategory.name}</Table.Cell>
      <Table.Cell style={{ width: 40 }}>
        <i className="uil-pen font-14 lh-1 cursor-pointer" onClick={showProductModal} />
      </Table.Cell>
    </Table.Row>
  )
}
