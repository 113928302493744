import * as React from 'react'
import { BookingTouristVoucher } from '@models/reception'
import { formatPrice } from '@helpers/utils'
import { ReservationTouristVouchersRowRemove } from '@modules/reservations/details/tourist-vouchers/row-remove'
import { ReservationTouristVouchersRowConfirm } from '@modules/reservations/details/tourist-vouchers/row-confirm'

interface Props {
  touristVoucher: BookingTouristVoucher
}

export const ReservationTouristVouchersRow = ({ touristVoucher }: Props): JSX.Element => (
  <tr>
    <td>{touristVoucher.code}</td>
    <td className="text-nowrap" width={10}>
      {formatPrice(touristVoucher.amount)}
    </td>
    <td className="text-nowrap" width={10}>
      <ReservationTouristVouchersRowConfirm touristVoucher={touristVoucher} />
    </td>
    <td width={10} className="text-nowrap text-right">
      {!touristVoucher.is_confirmed && <ReservationTouristVouchersRowRemove touristVoucher={touristVoucher} />}
    </td>
  </tr>
)
