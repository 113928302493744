import { asDecimal } from '@helpers/utils'
import Decimal from 'decimal.js'
import { CashCount, CashCountFields } from '@modules/payment-registers/cash-count-modal/index'
import { useSaleAppData } from '@modules/sale/hooks/use-sale-app-data'

export type CashCountCurrency = 'EURO' | 'PLN'
export type BanknoteInputs = PLNBanknoteInputs | EUROBanknoteInputs
// eslint-disable-next-line prettier/prettier
export type PLNBanknoteInputs = (typeof PLN_Banknotes)[number]
// eslint-disable-next-line prettier/prettier
export type EUROBanknoteInputs = (typeof EURO_Banknotes)[number]

export const PLN_Banknotes = [
  'bank_note_500',
  'bank_note_200',
  'bank_note_100',
  'bank_note_50',
  'bank_note_20',
  'bank_note_10',
  'coin_500',
  'coin_200',
  'coin_100',
  'coin_50',
  'coin_20',
  'coin_10',
  'coin_5',
  'coin_2',
  'coin_1',
]

export const EURO_Banknotes = [
  'bank_note_500',
  'bank_note_200',
  'bank_note_100',
  'bank_note_50',
  'bank_note_20',
  'bank_note_10',
  'bank_note_5',
  'coin_200',
  'coin_100',
  'coin_50',
  'coin_20',
  'coin_10',
  'coin_5',
  'coin_2',
  'coin_1',
]

export const useCashCount = (cashCountValues: CashCount) => {
  const { euro_exchange_rate } = useSaleAppData()

  const totalCashCount = asDecimal(cashCountGetTotalAmount(cashCountValues.PLN))
    .plus(cashCountGetTotalAmount(cashCountValues.EURO, euro_exchange_rate))
    .toString()

  return {
    totalCashCount,
  }
}

export const generateDefaults = (list: (PLNBanknoteInputs | EUROBanknoteInputs)[]) =>
  list.reduce(
    (values, current) => ({
      ...values,
      [current]: 0,
    }),
    {},
  )

export const cashCountEmptyValues = { PLN: generateDefaults(PLN_Banknotes), EURO: generateDefaults(EURO_Banknotes) }

export const getBanknoteImage = (name: string, currency: CashCountCurrency) =>
  require(`@assets/images/money/${currency}/${name}.png`)

export const cashCountGetTotalAmount = (values: CashCountFields, exchangeRate = '1'): string =>
  Object.entries(values)
    .reduce((sum, [key, amount]) => {
      const factor = key.startsWith('bank_note_') ? '1' : '0.01'
      return sum.plus(asDecimal(asDecimal(key.split('_').at(-1)).mul(asDecimal(factor)).mul(amount)).mul(exchangeRate))
    }, new Decimal(0))
    .toString()
