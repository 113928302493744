import { LoginUIComponent } from '@components/login-ui-component'
import { NavigationPath } from '@models/routes'
import * as React from 'react'
import { Link } from 'react-router-dom'

export const UnauthorizedView: React.FC = () => (
  <LoginUIComponent>
    <h2 className="unauthorized__header">Brak dostępu</h2>
    <p className="unauthorized__text">
      Aby korzystać z serwisu musisz być zalogowanym. Udaj sie do panelu logowania i zaloguj się.
    </p>
    <Link to={NavigationPath.AuthorizationLogin} className="btn btn-secondary btn-block">
      Zaloguj się
    </Link>
  </LoginUIComponent>
)
