import * as React from 'react'
import { PaymentDayUserCloseReport } from '@models/payments'
import { Col } from 'reactstrap'
import { IconWithText } from '@components/icon-with-text'
import { asDecimal, formatPrice } from '@helpers/utils'

interface Props {
  dayCloseReport: PaymentDayUserCloseReport
}

export const UserCashBoxCloseDaySummary = ({ dayCloseReport }: Props): JSX.Element => {
  const totalAmount = React.useMemo(
    () => asDecimal(dayCloseReport.cash_kp_sale_amount).plus(asDecimal(dayCloseReport.cards_kp_sale_amount)).toString(),
    [dayCloseReport],
  )

  return (
    <Col md={12} className="mt-2">
      <div className="d-flex bg-dark-light p-1 pt-0 text-white rounded justify-content-between">
        <div>
          <strong className="d-block font-13 mt-2 mb-1">Łączny przychód:</strong>
          <span className="font-11">Rozliczenie = Sprzedaż (KP) gotówka + Sprzedaż (KP) karta płatnicza</span>
        </div>
        <div className="text-right text-strong mt-2 mb-1 font-13">
          <IconWithText icon="uil-usd-circle" text={formatPrice(totalAmount)} />
        </div>
      </div>
    </Col>
  )
}
