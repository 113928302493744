import * as React from 'react'
import { CleaningOrder } from '@modules/housekeeping/models'
import { HousekeepingCleaningOrderToCheckRowActions } from '@modules/housekeeping/cleaning-orders/to-check/actions'
import { HousekeepingCleaningOrderNextBookingRow } from '@modules/housekeeping/cleaning-orders/common/next-booking'
import { HousekeepingCleaningOrderHasDamageRow } from '@modules/housekeeping/cleaning-orders/common/has-damage'
import { HousekeepingCleaningOrdersRowServiceKind } from '@modules/housekeeping/cleaning-orders/common/service-kind'
import { HousekeepingCleaningOrdersRowKind } from '@modules/housekeeping/cleaning-orders/common/kind'
import { secondsToInterval, toDefaultDateTimeFormat } from '@helpers/date-helper'
import classNames from 'classnames'
import { HousekeepingOrderToCheckBadgeStatus } from '@modules/housekeeping/cleaning-orders/to-check/status-badge'
import { HousekeepingCleaningOrderApartment } from '@modules/housekeeping/cleaning-orders/to-order/row-apartment'
import { HousekeepingOrderGroupedRow } from '@modules/housekeeping/cleaning-orders/common/grouped-table/grouped-row'

interface Props {
  cleaningOrders: CleaningOrder[]
}

export const HousekeepingCleaningOrderToCheckGroupedRow = ({ cleaningOrders }: Props): React.ReactNode => (
  <HousekeepingOrderGroupedRow
    colSpan={9}
    cleaningOrders={cleaningOrders}
    rowRenderer={cleaningOrder => (
      <tr className="housekeeping__orders_table__row">
        <HousekeepingCleaningOrderApartment cleaningOrder={cleaningOrder} showCheckedIn={true} showPriority={true} />
        <HousekeepingCleaningOrdersRowKind order={cleaningOrder} />
        <HousekeepingCleaningOrdersRowServiceKind width={150} id={cleaningOrder.id} order={cleaningOrder} />
        <HousekeepingCleaningOrderNextBookingRow cleaningOrder={cleaningOrder} />
        <HousekeepingOrderToCheckBadgeStatus orders={cleaningOrder.bedding_orders} />
        <HousekeepingOrderToCheckBadgeStatus orders={cleaningOrder.improvement_orders} />
        <td className="housekeeping__orders_table__column" width={150}>
          <span
            className={classNames(
              cleaningOrder.status === 'FINISHED'
                ? cleaningOrder.is_finished_in_assumed_time
                  ? 'text-success'
                  : 'text-danger'
                : '',
            )}
          >
            {secondsToInterval(cleaningOrder.total_time_started)}
          </span>
          <div className="font-11">
            {cleaningOrder.status === 'FINISHED' && (
              <span>Koniec: {toDefaultDateTimeFormat(cleaningOrder.finished_at)}</span>
            )}
          </div>
        </td>
        <HousekeepingCleaningOrderHasDamageRow hasDamage={cleaningOrder.has_damage} />
        <HousekeepingCleaningOrderToCheckRowActions cleaningOrder={cleaningOrder} />
      </tr>
    )}
  />
)
