import * as React from 'react'
import { NavigationTabs, NavigationTabsButton } from '@components/navigation-tabs'
import { ScrollableModalTab, useModalWithScrollableTabs } from '@components/hooks/use-modal-with-scrollable-tabs'

type Tab = 'data' | 'history' | 'options'

interface RefObject<T> {
  readonly current: T | null
}

interface Props {
  dataRef: RefObject<HTMLElement>
  historyRef: RefObject<HTMLElement>
  optionsRef: RefObject<HTMLElement>
}

export const BookingOptionDetailsTabs: React.FC<Props> = ({ dataRef, historyRef, optionsRef }) => {
  const [tab, setTab] = React.useState<Tab>('data')

  const tabs: ScrollableModalTab<Tab>[] = [
    { ref: dataRef, name: 'data' },
    { ref: historyRef, name: 'history' },
    { ref: optionsRef, name: 'options' },
  ]

  const { handleTabClick } = useModalWithScrollableTabs<Tab>({ modalId: 'booking-option-details', setTab, tabs })

  const buttons: NavigationTabsButton[] = [
    { title: 'Dane szczegółowe', isActive: tab == 'data', onClick: handleTabClick('data') },
    { title: 'Opcje dodatkowe', isActive: tab == 'options', onClick: handleTabClick('history') },
    { title: 'Historia i notatki', isActive: tab == 'history', onClick: handleTabClick('history') },
  ]

  return (
    <div className="modal-details__tabs-wrapper">
      <NavigationTabs buttons={buttons} />
    </div>
  )
}
