import * as React from 'react'
import { Button, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { endOfMonth, startOfMonth, startOfToday } from 'date-fns'
import { createReportTask } from '@store/actions/report-tasks-actions'
import ModalBody from 'reactstrap/lib/ModalBody'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { SaveButton } from '@hyper/button'
import { FormInput, FormPlain } from '@hyper/forms'
import Select from 'react-select'
import { useGroupedSellers } from '@components/hooks/use-grouped-sellers'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { BaseModalProps } from '@components/modals/types'

interface Props extends BaseModalProps {
  title: string
  url: string
  showPaymentDate?: boolean
  showPmsPaymentDate?: boolean
}

interface FormInputs {
  created_after: Date | null
  created_before: Date | null
  payment_invoice_date_after: Date | null
  payment_invoice_date_before: Date | null
  payment_created_at_after: Date | null
  payment_created_at_before: Date | null
  source_marketing: undefined | string
  status: undefined | string
  seller: undefined | string | any
}

export const ReportsGeneratorSubscription: React.FC<Props> = ({
  toggleIsVisible,
  title,
  url,
  showPaymentDate,
  showPmsPaymentDate,
}) => {
  const sources = useAppSelector((state: RootState) => state.appState.appData.subscription.sources)
  const statuses = useAppSelector((state: RootState) => state.appState.appData.subscription.statuses)
  const dispatch = useAppDispatch()

  const methods = useForm<FormInputs>({
    mode: 'all',
    defaultValues: {
      created_after: startOfMonth(startOfToday()),
      created_before: endOfMonth(startOfToday()),
      payment_invoice_date_after: null,
      payment_invoice_date_before: null,
      payment_created_at_after: null,
      payment_created_at_before: null,
      seller: undefined,
      status: undefined,
    },
  })

  const { isLoading, action: onSubmit } = useReportFormRequest(async (payload: FormInputs) => {
    const reportTask = await dispatch(
      createReportTask([
        url,
        {
          ...payload,
          status: payload.status || null,
          source_marketing: payload.source_marketing || null,
          seller: payload.seller?.value || null,
        },
      ]),
    )
    toggleIsVisible()

    return reportTask
  }, methods.setError)

  const sellers = useGroupedSellers([])

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggleIsVisible}>{title}</ModalHeader>
        <ModalBody>
          <Row>
            <DateFromToInput
              label="Data utworzenia abonamentu"
              startDateName="created_after"
              endDateName="created_before"
            />
            {showPaymentDate && (
              <DateFromToInput
                label="Data wpłaty"
                startDateName="payment_invoice_date_after"
                endDateName="payment_invoice_date_before"
              />
            )}
            {showPmsPaymentDate && (
              <DateFromToInput
                label="Data dodania wpłaty do PMS"
                startDateName="payment_created_at_after"
                endDateName="payment_created_at_before"
              />
            )}
            <FormInput colSize={6} label="Status" name="status" type="select">
              <option value="">---------</option>
              {statuses.map(option => (
                <option key={option[0]} value={option[0]}>
                  {option[1]}
                </option>
              ))}
            </FormInput>

            <FormPlain colSize={6} name="seller">
              <label>Sprzedawca</label>
              <Controller
                name="seller"
                control={methods.control}
                render={({ field }) => <Select placeholder="Wybierz" options={sellers} {...field} />}
              />
            </FormPlain>

            <FormInput colSize={6} label="Źródło" name="source_marketing" type="select">
              <option value="">---------</option>
              {sources.map(option => (
                <option key={option[0]} value={option[0]}>
                  {option[1]}
                </option>
              ))}
            </FormInput>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="light" type="button" onClick={toggleIsVisible}>
            Zamknij
          </Button>
          <SaveButton className="btn btn-green" label="Zleć raport" labelSaving="Zlecanie.." isSaving={isLoading} />
        </ModalFooter>
      </form>
    </FormProvider>
  )
}
