import * as React from 'react'
import { totalSizeSelector } from '@store/selectors/dashboard'
import { useDocumentTitle } from '@helpers/utils'
import { PageTitle } from '@hyper/page-title'
import { useAppSelector } from '@store/index'

interface Props {
  title: string
}

export const PageTitleWithSize = ({ title }: Props): JSX.Element => {
  const totalSize = useAppSelector(totalSizeSelector)

  const pageTitleWithSize = title + ` (${totalSize})`
  useDocumentTitle(pageTitleWithSize)

  return <PageTitle breadCrumbItems={[{ label: title, path: '', active: true }]} title={pageTitleWithSize} />
}
