import * as React from 'react'
import { PromocodePrefix } from '@models/promocode'
import { useModal } from '@components/modals/use-modal'

interface PromocodePrefixEditIconProps {
  promocodePrefix: PromocodePrefix
}

export const PromocodePrefixEditIcon: React.FC<PromocodePrefixEditIconProps> = ({ promocodePrefix }) => {
  const [handleToggle] = useModal('PromocodePrefixDialog', {
    promocodePrefix,
  })

  return (
    <span className="font-16" onClick={handleToggle}>
      <i className="uil uil-pen cursor-pointer text-muted" title="Edytuj" />
    </span>
  )
}
