import { ReceptionBooking } from '@models/reception'

export const searchByName = (booking: ReceptionBooking, query: string) => {
  if (query.length < 2) {
    return false
  }
  return booking.name.toLocaleLowerCase().includes(query)
}

export const searchByReservationNumber = (booking: ReceptionBooking, query: string) => {
  if (query.length < 2) {
    return false
  }

  return booking.reservation_number?.toLocaleLowerCase().includes(query)
}
export const searchByBandNumber = (booking: ReceptionBooking, query: string) => {
  if (query.length < 10) {
    return false
  }

  return booking.bands_number.find(row => row.toLocaleLowerCase().includes(query))
}
export const searchByApartment = (booking: ReceptionBooking, query: string) =>
  booking.apartment?.name.toLocaleLowerCase().includes(query)

export const searchByBaseBookingParams = (booking: ReceptionBooking, query: string) =>
  searchByName(booking, query) ||
  searchByApartment(booking, query) ||
  searchByEmail(booking, query) ||
  searchByPhone(booking, query) ||
  searchByReservationNumber(booking, query) ||
  searchByBandNumber(booking, query)

export const searchByEmail = (booking: ReceptionBooking, query: string) => {
  if (query.length < 2) {
    return false
  }

  return booking.email.toLocaleLowerCase().includes(query)
}

export const searchByPhone = (booking: ReceptionBooking, query: string) => {
  if (query.length < 2) {
    return false
  }

  return booking.phone?.replace(/ /g, '').toLocaleLowerCase().includes(query.replace(/ /g, ''))
}

export const searchByCarRegisterNumber = (booking: ReceptionBooking, query: string) => {
  if (query.length < 2) {
    return false
  }

  const clean = (value): string => value.toLowerCase().replace(/ /g, '').replace(/0/g, '').replace(/o/g, '')
  query = clean(query)

  return booking.car_register_numbers?.filter(registerNumber => clean(registerNumber).includes(query)).length > 0
}
