import * as React from 'react'
import { useClientWallets } from '@modules/reception/cart/payment-box/use-client-wallets'
import { asDecimal, formatPercentage, formatPrice } from '@helpers/utils'
import { CustomInput, Progress } from 'reactstrap'
import classNames from 'classnames'
import { ReceptionBookingClientWallet, ReceptionBookingDetails } from '@models/reception'
import { IconWithTooltip } from '@components/icon-with-tooltip'
import { parseISODate, toDefaultDateFormat } from '@helpers/date-helper'
import { BookingStatusesWithAvailableFeedingEdition } from '@modules/reception/checkin/step-feeding/new-feeding/utils'
import { isAfter, isToday, startOfToday } from 'date-fns'

const CIEPLICE_RESORT_ID = 9

interface Props {
  booking: ReceptionBookingDetails
  isSomeFeedingAlreadyPaidByWallet: boolean
  isPaymentByWalletSelected: boolean
  onPaymentByWalletSelectionChange: (e) => void
}

export const ReceptionBookingNewFeedingWalletPayment = ({
  isSomeFeedingAlreadyPaidByWallet,
  isPaymentByWalletSelected,
  onPaymentByWalletSelectionChange,
  booking,
}: Props): JSX.Element | null => {
  const { wallets } = useClientWallets()

  const activeWallets = wallets.filter(
    wallet =>
      wallet.is_active &&
      wallet.scopes.some(
        scope => scope === 'feeding' || (scope === 'feeding_cieplice' && booking.resort_id === CIEPLICE_RESORT_ID),
      ) &&
      asDecimal(wallet.available_amount).gt(0),
  )

  if (!activeWallets.length && !isSomeFeedingAlreadyPaidByWallet) return null

  const getPercentageUsage = (wallet: ReceptionBookingClientWallet) =>
    asDecimal(wallet.available_amount).mul(100).div(wallet.start_amount).toFixed(0)

  const bookingDateFrom = parseISODate(booking.date_from) as Date

  const isVoucherPaymentEnabled =
    (isPaymentByWalletSelected || isAfter(startOfToday(), bookingDateFrom) || isToday(bookingDateFrom)) &&
    BookingStatusesWithAvailableFeedingEdition.includes(booking.status)

  return (
    <div className="feeding__voucher-payment-box">
      <div className="d-flex align-items-center">
        {!isVoucherPaymentEnabled && (
          <IconWithTooltip
            icon="uil-exclamation-circle ml-1 font-14"
            tooltipId="pay_by_wallet"
            tooltipPlacement="right"
            tooltipMessage={
              <span className="font-11 text-left">
                <span>
                  Płatność voucherem będzie dostępna najwcześniej pierwszego dnia pobytu tj (
                  {toDefaultDateFormat(booking.date_from)}).
                </span>{' '}
                <br />
              </span>
            }
            color="text-secondary"
          />
        )}

        <CustomInput
          id="pay_by_wallet"
          type="checkbox"
          disabled={!isVoucherPaymentEnabled}
          label="Pobierz środki z vouchera gastro"
          checked={isPaymentByWalletSelected}
          onChange={onPaymentByWalletSelectionChange}
        />
      </div>

      <div className="d-flex flex-column">
        {activeWallets.map((wallet, index) => {
          const amountLeftInPercentage = getPercentageUsage(wallet)
          return (
            <div key={wallet.id} className={classNames({ 'mb-3': index !== activeWallets.length - 1 })}>
              <div className="font-11 mb-2 mr-3">
                <strong className="feeding__voucher ml-0 mr-2" title="Opłacony przez voucher gastronomiczny">
                  <i className="uil-tag-alt" />
                </strong>
                Pozostało do wykorzystania <strong>{formatPrice(wallet.available_amount)}</strong> z{' '}
                {formatPrice(wallet.start_amount)} ({formatPercentage(amountLeftInPercentage)})
              </div>

              <Progress value={amountLeftInPercentage} color="secondary" />
            </div>
          )
        })}
      </div>
    </div>
  )
}
