import * as React from 'react'
import { FormPlain } from '@hyper/forms'
import { Label } from 'reactstrap'
import DatePickerInput from '@components/date/date-picker-input'
import { FormInputMasked } from '@hyper/forms/form-input-masked'
import { accountNumberMask, RecommendationGift } from '@models/crm'

interface Props {
  recommendationGift?: RecommendationGift
}

export const RecommendationGiftDialogMoneyTransfer = ({ recommendationGift }: Props): JSX.Element => (
  <>
    <FormPlain colSize={6} name="transfer_by_day">
      <Label>Termin przelewu do dnia</Label>
      <DatePickerInput name="transfer_by_day" wrapperClassName="flex-grow-1" />
    </FormPlain>
    <FormInputMasked
      placeholder="__ ____ ____ ____ ____ ____ ____"
      colSize={6}
      mask={accountNumberMask}
      name="account_number"
      label="Numer konta"
      readOnly={recommendationGift ? recommendationGift.transfer_status !== 'initial' : false}
    />
  </>
)
