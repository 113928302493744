import * as React from 'react'
import { FiltersHousekeepingApplicationFiltersParams } from '@modules/housekeeping/applications/filters'
import { useAppDispatch, useAppSelector } from '@store/index'
import { getApplicationIssues } from '@store/actions/housekeeping-actions'
import { HousekeepingApplicationIssuesTable } from '@modules/housekeeping/applications/issue/table'
import { useApiRequest } from '@components/hooks/use-api-request'
import { issueOrdersSelector } from '@store/slices/housekeeping-slice'

interface Props {
  filters: FiltersHousekeepingApplicationFiltersParams
  setFilters: (filters: FiltersHousekeepingApplicationFiltersParams) => void
  resortId: number
}

export const HousekeepingApplicationIssuesList: React.FC<Props> = ({ resortId, filters, setFilters }) => {
  const dispatch = useAppDispatch()

  const { isLoading, action: fetchApplicationIssues } = useApiRequest(
    async () => await dispatch(getApplicationIssues([filters, resortId])),
  )

  const issuesOrders = useAppSelector(issueOrdersSelector)

  React.useEffect(() => {
    fetchApplicationIssues()
  }, [resortId, filters])

  return (
    <HousekeepingApplicationIssuesTable
      setFilters={setFilters}
      filters={filters}
      isLoading={isLoading}
      resortId={resortId}
      issuesOrders={issuesOrders}
    />
  )
}
