import * as React from 'react'
import { Input } from 'reactstrap'
import { extractInnerRef } from '@helpers/forms'
import { FormPlainError } from '@hyper/forms'
import { ReceptionNotificationDialogFormInputs } from '@modules/reception/notification'
import { useFormContext } from 'react-hook-form'

interface NotificationContentTemplateProps {
  onChange: () => void
  name: keyof ReceptionNotificationDialogFormInputs
  options: { key: string; label: string }[]
}

export const NotificationContentTemplate: React.FC<NotificationContentTemplateProps> = ({
  onChange,
  name,
  options,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<ReceptionNotificationDialogFormInputs>()

  return (
    <>
      <Input
        type="select"
        onChange={onChange}
        role={`notification-${name}`}
        className={errors ? 'is-invalid' : ''}
        {...extractInnerRef(register(name))}
      >
        <option value="">Wybierz szablon</option>
        {options.map(template => (
          <option value={template.key} key={template.key}>
            {template.label}
          </option>
        ))}
      </Input>
      <FormPlainError name={name} />
    </>
  )
}
