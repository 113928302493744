import { setDashboardStandardPaginator } from '@store/actions/dashboard-actions'
import { commonObjectGet } from '@store/actions/generic-actions'
import { PaginationResponse } from '@models/dashboard'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { CallCenterUser, Group, ReceptionistUser, User } from '@models/account'
import { AccountUserListTableFilters } from '@modules/account/users/list'
import { TableFilters } from '@components/table/table'
import { AsyncThunkParams } from '@store/index'
import { CallCenterUsersTableFilters } from '@modules/account/call-center-users/list'
import { ReceptionistUsersTableFilters } from '@modules/account/receptionist-users/list'

export const getCallCenterUsers = createAsyncThunk<CallCenterUser[], CallCenterUsersTableFilters, AsyncThunkParams>(
  'account/getCallCenterUsers',
  async (params, { getState, dispatch }) => {
    const url = getState().appState.appData.urls.account.call_center_users

    const data = await commonObjectGet<PaginationResponse<CallCenterUser>>(url, {
      ...params,
      is_active: params.is_active?.value,
    })

    dispatch(setDashboardStandardPaginator(data))
    return data.results
  },
)

export const getReceptionistUsers = createAsyncThunk<
  ReceptionistUser[],
  ReceptionistUsersTableFilters,
  AsyncThunkParams
>('account/getReceptionistUsers', async (params, { getState, dispatch }) => {
  const url = getState().appState.appData.urls.account.receptionist_users

  const data = await commonObjectGet<PaginationResponse<ReceptionistUser>>(url, {
    ...params,
    is_active: params.is_active?.value,
  })

  dispatch(setDashboardStandardPaginator(data))
  return data.results
})

export const getUsers = createAsyncThunk<User[], Partial<AccountUserListTableFilters>, AsyncThunkParams>(
  'account/getUsers',
  async (params, { getState, dispatch }) => {
    const url = getState().appState.appData.urls.account.users

    const data = await commonObjectGet<PaginationResponse<User>>(url, {
      ...params,
      is_worker: params.is_worker?.value,
      is_staff: params.is_staff?.value,
      is_superuser: params.is_superuser?.value,
      is_active: params.is_active?.value,
      is_resort_manager: params.is_resort_manager?.value,
      groups: params.groups?.map(row => row.value),
      is_receptionist: params.is_receptionist?.value,
    })

    dispatch(setDashboardStandardPaginator(data))
    return data.results
  },
)
export const getGroups = createAsyncThunk<Group[], Partial<TableFilters>, AsyncThunkParams>(
  'account/getGroups',
  async (params, { getState, dispatch }) => {
    const url = getState().appState.appData.urls.account.groups

    const data = await commonObjectGet<PaginationResponse<Group>>(url, params)

    dispatch(setDashboardStandardPaginator(data))
    return data.results
  },
)
