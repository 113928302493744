import * as React from 'react'
import { Spinner } from 'reactstrap'
import { EventRowSingleEvent } from '@modules/rcp/user/events/event-row-single-event'
import { EventsPairAddNew } from '@modules/rcp/user/events/events-pair-add-new'
import { getUserEventDayDetails } from '@store/actions/rcp-actions'
import { useApiRequest } from '@components/hooks/use-api-request'
import { RcpEvent, RcpEventDay, RcpMachine, RcpUserDetail } from '@models/rcp'
import { useAppDispatch } from '@store/index'

interface EventRowDetailProps {
  machines: RcpMachine[]
  eventDay: RcpEventDay
  hasError: boolean
  user: RcpUserDetail
}

export const EventRowDetail: React.FC<EventRowDetailProps> = ({ user, eventDay, machines, hasError }) => {
  const dispatch = useAppDispatch()

  const { isLoading, action: fetch } = useApiRequest(
    async () => await dispatch(getUserEventDayDetails(eventDay.urls.details)),
  )

  React.useEffect(() => {
    fetch()
  }, [])

  return (
    <>
      {eventDay.events.map((eventsPair: [RcpEvent, RcpEvent]) => (
        <React.Fragment key={eventsPair[0].id + eventsPair[1].id}>
          <EventRowSingleEvent event={eventsPair[0]} machines={machines} date={eventDay.date} hasError={hasError} />
          <EventRowSingleEvent event={eventsPair[1]} machines={machines} date={eventDay.date} hasError={hasError} />
        </React.Fragment>
      ))}
      {isLoading ? (
        <tr className={`rcp__events-table__tr-primary-bottom ${hasError ? 'has-errors' : ''}`}>
          <td colSpan={8} className="text-center">
            <Spinner color="dark" />
          </td>
        </tr>
      ) : (
        <EventsPairAddNew eventDay={eventDay} machines={machines} hasError={hasError} user={user} />
      )}
    </>
  )
}
