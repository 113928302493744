import * as React from 'react'
import { FormInput, FormPlain } from '@hyper/forms'
import { extractInnerRef } from '@helpers/forms'
import { CustomInput } from 'reactstrap'
import { useFormContext } from 'react-hook-form'
import { GuestsTechnicalOrderStatusFormInputs } from '@modules/guests-technical-orders/guests-technical-order-status-modal/index'

export const GuestsTechnicalOrderStatusForm: React.FC = () => {
  const { register } = useFormContext<GuestsTechnicalOrderStatusFormInputs>()
  return (
    <>
      <FormPlain name="action" label="Wybierz jedną z opcji" labelSize={5}>
        <div>
          <CustomInput
            type="radio"
            className="mb-2 mt-2"
            value="mark_as_done"
            {...extractInnerRef(register('action'))}
            id="mark_as_done"
            label="Oznacz jako wykonane"
          />
          <CustomInput
            type="radio"
            className="mb-2"
            value="mark_as_close"
            {...extractInnerRef(register('action'))}
            id="mark_as_close"
            label="Oznacz jako zamknięte"
          />
        </div>
      </FormPlain>
      <FormInput
        formGroupClassName="px-0"
        labelProps={{ className: 'px-0' }}
        labelSize={5}
        name="note"
        type="textarea"
        label={
          <>
            Dodaj komentarz<span className="font-10 text-muted d-block">(opcjonalnie)</span>
          </>
        }
        placeholder="Wpisz swój komentarz"
      />
    </>
  )
}
