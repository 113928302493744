import * as React from 'react'
import { HouseKeepingUserReport } from '@modules/reports/housekeeping-users-report/models'
import { HouseKeepingReportFiltersParams } from '@modules/reports/housekeeping-users-report/index'
import { NavigationPath } from '@models/routes'
import { Link } from 'react-router-dom'
import { secondsToInterval } from '@helpers/date-helper'
import { resortsMapSelector } from '@store/selectors/dashboard'
import Table, { TableHeaderValue } from '@components/table/table'
import { HousekeepingReportTableFooter } from '@modules/reports/housekeeping-users-report/housekeeping-report-table-footer'
import { useAppSelector } from '@store/index'

interface HousekeepingReportTableProps {
  houseKeepingUsersReport: HouseKeepingUserReport
  isLoading: boolean
  filters: HouseKeepingReportFiltersParams
  setFilters: (filters: HouseKeepingReportFiltersParams) => void
}

const headerValues: TableHeaderValue[] = [
  { title: 'RCP ID' },
  { title: 'Imię i nazwisko' },
  { title: 'Ośrodek', sortField: 'resort_id' },
  { title: 'Stanowisko' },
  { title: 'Wykonanych zleceń' },
  { title: 'Czas realizacji zadań w aplikacji', sortField: 'total_time' },
  { title: 'Średnia dzienna ilość przygotowanych lokali', sortField: 'average' },
  { title: 'Lokali przygotowanych w zakładanym czasie', sortField: 'orders_finished_in_assumed_time' },
  { title: 'Czas pracy do wynagrodzenia', sortField: 'total_working_time_to_pay' },
  { title: 'Akcje' },
]

const cols = ['5%', '8%', '7%', '7%', '7%', '12%', '17%', '15%', '17%', '5%']

export const HousekeepingReportTable: React.FC<HousekeepingReportTableProps> = ({
  houseKeepingUsersReport,
  isLoading,
  filters,
  setFilters,
}) => {
  const resortsMap = useAppSelector(resortsMapSelector)

  return (
    <Table
      className="table-hover w-100"
      headerValues={headerValues}
      isLoading={isLoading}
      filters={filters}
      cols={cols}
      setFilters={setFilters}
      footer={
        houseKeepingUsersReport.aggregation && (
          <HousekeepingReportTableFooter cols={cols} reportAggregation={houseKeepingUsersReport.aggregation} />
        )
      }
    >
      {houseKeepingUsersReport.results.map(row => (
        <Table.Row key={row.id}>
          <Table.Cell>{row.rcp_badge}</Table.Cell>
          <Table.Cell>{row.user_name}</Table.Cell>
          <Table.Cell>{resortsMap[row.resort_id]?.name || 'brak'}</Table.Cell>
          <Table.Cell>{row.user_position}</Table.Cell>
          <Table.Cell>{row.orders}</Table.Cell>
          <Table.Cell>{secondsToInterval(row.total_time)}</Table.Cell>
          <Table.Cell>{row.average}</Table.Cell>
          <Table.Cell>{row.orders_finished_in_assumed_time}</Table.Cell>
          <Table.Cell>{secondsToInterval(row.total_working_time_to_pay)}</Table.Cell>
          <Table.Cell className="text-nowrap" style={{ width: 100 }}>
            <Link
              to={NavigationPath.HouseKeepingUserReport.replace(':user_id', String(row.user_id)).replace(
                ':resort_id',
                String(row.resort_id),
              )}
              state={{
                finished_at_before: filters.finished_at_before,
                finished_at_after: filters.finished_at_after,
                resorts: filters.resorts,
              }}
              className="btn btn-secondary btn-xs"
            >
              więcej
            </Link>
          </Table.Cell>
        </Table.Row>
      ))}
    </Table>
  )
}
