import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { Col, Row } from 'reactstrap'
import { ReceptionApartmentHousekeepingStatus } from '@modules/reception/common/reception-apartment-housekeeping-status'
import { parseISODate } from '@helpers/date-helper'
import { ReceptionBookingDetails } from '@models/reception'
import { isToday } from 'date-fns'
import { ReservationStatusLabel } from '@modules/reservations/details/base-data/booking/reservation-status-label'
import { ReceptionToCheckInRowButtons } from '@modules/reception/to-checkin/row/reception-to-check-in-row-buttons'
import { ReceptionToCheckOutRowButtons } from '@modules/reception/to-checkout/row/reception-to-check-out-row-buttons'
import { ReceptionBookingExtensionButton } from '@modules/reception/common/reception-booking-extension-button'
import { ReservationDataStayDates } from '@modules/reservations/details/base-data/booking/reservation-data-stay-dates'
import { ReservationDataResort } from '@modules/reservations/details/base-data/booking/reservation-data-resort'
import { ReservationDataAccommodationType } from '@modules/reservations/details/base-data/booking/reservation-data-accommodation-type'
import { getReservationTypeName } from '@modules/reservations/details/reservation-data/reservation-type'
import { ReservationDataHeader } from '@modules/reservations/details/base-data/booking/reservation-data-header'
import { IconWithTooltip } from '@components/icon-with-tooltip'
import { CopyToClipboardTooltip } from '@components/copy-to-clipboard-tooltip'
import { getReceptionBookingDetails } from '@store/actions/reception-actions'
import { useAppDispatch } from '@store/index'

interface Props {
  booking: ReceptionBookingDetails
}

export const ReservationData = ({ booking }: Props): JSX.Element => {
  const dispatch = useAppDispatch()

  const isToCheckOut =
    booking.checked_in && isToday(parseISODate(booking.date_to) as Date) && booking.status !== 'close'
  const isPending = booking.checked_in && !isToCheckOut && booking.status === 'confirmed'
  const isToCheckIn = !booking.checked_in && booking.status === 'confirmed'

  const hasApartmentSelectImprovement = booking.improvements.some(
    improvement => improvement.code === 'apartment_select',
  )

  const handleApartmentStatusUpdate = async () => {
    await dispatch(getReceptionBookingDetails(booking.urls.api_details))
  }

  return (
    <>
      <ReservationDataHeader booking={booking} />
      <Row className="mt-2">
        <Col md={4}>
          <div>
            Numer rezerwacji:
            <strong className="d-flex align-items-center font-20">
              <CopyToClipboardTooltip
                className="px-0 d-flex align-items-center"
                value={booking.reservation_number}
                tooltipId={`booking-number-copy-${booking.id}`}
                icon="uil-copy text-secondary font-16 ml-1"
              >
                {booking.reservation_number}
              </CopyToClipboardTooltip>
            </strong>
          </div>
          <div className="badge badge-light px-2 d-inline-block font-10 text-default mt-1">
            {getReservationTypeName(booking)}
          </div>
        </Col>
        <Col md={8}>
          <ReservationStatusLabel booking={booking} />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={4}>
          <div className="d-flex align-content-center mb-1">
            <IconWithText
              icon="uil-estate text-secondary me-2 font-16"
              text={`Lokal ${booking.apartment?.name}`}
              textClass="font-weight-bold mr-1"
            />
            {hasApartmentSelectImprovement && (
              <IconWithTooltip
                icon="icon-lokal"
                tooltipMessage={<span className="font-11">Płatny wybór lokalu</span>}
                tooltipId="apartment-select"
                className="mr-1"
              />
            )}
            <div>
              <ReceptionApartmentHousekeepingStatus
                apartment={booking.apartment}
                onStatusUpdate={handleApartmentStatusUpdate}
              />
            </div>
          </div>
          <ReservationDataAccommodationType accommodation_type_id={booking.accommodation_type_id} />
        </Col>
        <Col md={4}>
          <ReservationDataResort resortId={booking.resort_id} />
          <ReservationDataStayDates booking={booking} />
        </Col>
        <Col md={4} className="d-flex justify-content-end">
          {isToCheckIn && <ReceptionToCheckInRowButtons booking={booking} />}
          {isPending && <ReceptionBookingExtensionButton booking={booking} />}
          {isToCheckOut && <ReceptionToCheckOutRowButtons booking={booking} />}
        </Col>
      </Row>
    </>
  )
}
