import * as React from 'react'
import { ClientDetailsModalBaseDataRow } from '@modules/crm/clients/details-modal/base-data/data-row'
import { ClientUser } from '@models/clients'
import { ClientDetailsModalAgreementRow } from '@modules/crm/clients/details-modal/base-data/agreement-row'

interface Props {
  client: ClientUser
  onRuleUpdate?: () => void
}

export const ClientDetailsModalAgreements = ({ client, onRuleUpdate }: Props): JSX.Element => (
  <>
    <ClientDetailsModalBaseDataRow name="Kontakt SMS:">
      <ClientDetailsModalAgreementRow
        keyword="sms_rules"
        client={client}
        acceptationDateClassName="pl-1"
        isDisabled={!client.phone}
        onRuleUpdate={onRuleUpdate}
      />
    </ClientDetailsModalBaseDataRow>
    <ClientDetailsModalBaseDataRow name="Kontakt telefoniczny:">
      <ClientDetailsModalAgreementRow
        keyword="phone_call_rules"
        client={client}
        acceptationDateClassName="pl-1"
        isDisabled={!client.phone}
        onRuleUpdate={onRuleUpdate}
      />
    </ClientDetailsModalBaseDataRow>
    <ClientDetailsModalBaseDataRow name="Kontakt e-mail:">
      <ClientDetailsModalAgreementRow
        keyword="email_rules"
        client={client}
        acceptationDateClassName="pl-1"
        onRuleUpdate={onRuleUpdate}
      />
    </ClientDetailsModalBaseDataRow>
  </>
)
