import * as React from 'react'
import { InputGroup, InputGroupAddon, Label } from 'reactstrap'
import { ClientDetails, ClientUser } from '@models/clients'
import { ClientAdd } from '@modules/promotions/gastro-cards/gastro-card-dialog/client-add'
import { FormPlain } from '@hyper/forms'
import AsyncSelect from 'react-select/async'
import { ClientSelectComponents } from '@components/client-select-components'
import { clientSearchQuery } from '@api/account'
import { commonObjectGet } from '@store/actions/generic-actions'
import { useModal } from '@components/modals/use-modal'
import { AdditionalDetails, ClientBaseData } from '@components/client-select-data'
import { useApiRequest } from '@components/hooks/use-api-request'
import { UserPermission } from '@models/dashboard'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'

interface ClientSelectProps {
  setClient: (client: ClientUser) => void
  onClientDetailsChange?: (client: ClientDetails) => void
  handleToggleClientDialog: () => void
  client?: ClientUser | undefined
  prefetchedClientDetails?: boolean
  error?: any
  colSize?: number
  formGroupClassName?: string
  isDisabled?: boolean
  label?: React.ReactNode
  additionalClientsElementsToShow?: AdditionalDetails[]
  emailsToOmit?: string[]
}

export const ClientSelect: React.FC<ClientSelectProps> = ({
  setClient,
  client,
  prefetchedClientDetails,
  colSize,
  handleToggleClientDialog,
  error,
  formGroupClassName,
  isDisabled,
  onClientDetailsChange,
  label = 'Klient',
  additionalClientsElementsToShow,
  emailsToOmit,
}) => {
  const user = useAuthenticatedUser()
  const onSuggestionsFetchRequested = async (value: string): Promise<ClientUser[]> =>
    await clientSearchQuery(value, emailsToOmit)

  const [clientDetails, setClientDetails] = React.useState<ClientDetails | undefined>()
  const [isCleared, setIsCleared] = React.useState(false)

  const onChange = (data: ClientUser) => {
    setClient(data)
    setIsCleared(!data)

    if (!data) {
      setClientDetails(undefined)
    }
  }

  const { action: fetchClientDetails, isLoading: isFetchingClientDetails } = useApiRequest(async clientUrl => {
    const details = await commonObjectGet<ClientDetails>(clientUrl)
    setClientDetails(details)
    onClientDetailsChange?.(details)
  })

  React.useEffect(() => {
    if (client && !prefetchedClientDetails) {
      fetchClientDetails(client.urls.details)
    }
  }, [client])

  const hasActiveSubscription = clientDetails?.subscription_contracts.some(
    subscriptionContract => subscriptionContract.status === 'confirmed',
  )

  const [showClientEditModal] = useModal('ClientDialog', { client, setClient, activeFields: ['phone'] })

  return (
    <FormPlain colSize={colSize || 6} name="client" formGroupClassName={formGroupClassName} hideErrors={true}>
      {label && <Label for="client-select">{label}</Label>}

      <InputGroup>
        <div className="flex-fill">
          <AsyncSelect
            styles={{
              menu: provided => ({ ...provided, zIndex: 9999 }),
              valueContainer: provided => ({ ...provided, display: 'flex' }),
            }}
            defaultValue={client}
            value={isCleared ? null : client}
            defaultOptions={[]}
            loadOptions={onSuggestionsFetchRequested}
            placeholder="Wpisz adres e-mail"
            isClearable={true}
            inputId="client-select"
            components={ClientSelectComponents}
            onChange={onChange}
            isDisabled={isDisabled}
          />
        </div>
        {!isDisabled && user.hasPerm(UserPermission.GuestsCanAddGuests) && (
          <InputGroupAddon addonType="append">
            <ClientAdd handleToggleClientDialog={handleToggleClientDialog} />
          </InputGroupAddon>
        )}
        <div className="invalid-feedback d-block">{error && error.message}</div>
      </InputGroup>
      {client && (
        <ClientBaseData
          client={client}
          hasActiveSubscription={hasActiveSubscription}
          isFetchingClientDetails={isFetchingClientDetails}
          onShowClientEditModal={showClientEditModal}
          additionalDetails={additionalClientsElementsToShow}
        />
      )}
    </FormPlain>
  )
}
