import * as React from 'react'
import { PromocodeTouristVoucherDetails } from '@modules/promotions/promocode-tourist-voucher/models'
import { Button, Card, CardBody, Col, Row } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { formatDate } from '@helpers/date-helper'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { updatePromocodeTouristVoucherDetails } from '@store/slices/promocode-tourist-voucher-slice'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'

interface Props {
  promocodeTouristVoucherDetails: PromocodeTouristVoucherDetails
}

export const PromocodeTouristVoucherDetailsCodes = ({ promocodeTouristVoucherDetails }: Props): JSX.Element => {
  const [handleAdd] = useModal('PromocodeTouristVoucherCodesAddDialog', {
    promocodeTouristVoucherDetails,
  })
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading, action: handleAccept } = useApiRequest(async () => {
    dispatch(
      updatePromocodeTouristVoucherDetails(
        await commonObjectDelete<PromocodeTouristVoucherDetails>(promocodeTouristVoucherDetails.urls.code),
      ),
    )
    addSuccessMessage()
  })

  return (
    <Card>
      <CardBody>
        <Row>
          <Col md={7}>
            <h4 className="mt-0 text-secondary mb-3">Bony Turystyczne</h4>
          </Col>
          <Col md={5} className="text-right">
            {promocodeTouristVoucherDetails.status === 'initial' &&
              !promocodeTouristVoucherDetails.tourist_voucher_payment_code && (
                <Button className="btn btn-green btn-sm" onClick={handleAdd}>
                  Dodaj kod
                </Button>
              )}
          </Col>
        </Row>
        <table className="table table-centered table-sm table-hover table-striped">
          <thead className="thead-light">
            <tr>
              <th>Data dodania</th>
              <th>Numer bonu</th>
              <th>Kod obsługi płatności</th>
              <th>Dodany przez</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr>
              {promocodeTouristVoucherDetails.tourist_voucher_payment_code ? (
                <>
                  <td>{formatDate(promocodeTouristVoucherDetails.tourist_voucher_payment_code_created_at)}</td>
                  <td>{promocodeTouristVoucherDetails.tourist_voucher_code}</td>
                  <td>{promocodeTouristVoucherDetails.tourist_voucher_payment_code}</td>
                  <td>{promocodeTouristVoucherDetails.tourist_voucher_payment_code_created_by}</td>
                  <td>
                    {promocodeTouristVoucherDetails.status === 'confirmed' && (
                      <CommonObjectConfirmAction
                        title="Potwierdź skasowanie kodu"
                        askForReason={false}
                        isLoading={isLoading}
                        handleAccept={handleAccept}
                        message="Czy na pewno chcesz usunąć kod obsługi płatności?"
                      >
                        <i className="uil-trash-alt text-muted font-14 ml-1 cursor-pointer" />
                      </CommonObjectConfirmAction>
                    )}
                  </td>
                </>
              ) : (
                <td colSpan={5} className="text-center">
                  Brak kodu
                </td>
              )}
            </tr>
          </tbody>
        </table>
      </CardBody>
    </Card>
  )
}
