import * as React from 'react'
import { Card, CardBody } from 'reactstrap'
import { createSelectOption } from '@helpers/utils'
import { useAppDispatch, useAppSelector } from '@store/index'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useDebounce } from 'rooks'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { CafeteriaListFilter, CafeteriaListFilterParams } from '@modules/cafeteria/list/filter'
import { CafeteriaWebSocketHandler } from '@modules/cafeteria/web-socket-handler'
import { getCafeterias } from '@store/actions/cafeterias-action'
import { cafeteriasSelector, clearCafeterias } from '@store/slices/cafeteria-slice'
import { CafeteriaListTable } from '@modules/cafeteria/list/table'
import { useParams } from 'react-router-dom'
import { CafeteriaDetailsView } from '@modules/cafeteria/list/details'
import { PageTitleWithSize } from '@hyper/page-title-with-size'

const CafeteriaStatusOptions = [createSelectOption('Otwarta', 'open'), createSelectOption('Zamknięta', 'close')]

const defaultFilters: CafeteriaListFilterParams = {
  kind: null,
  search: '',
  ordering: '-created',
  page: 1,
  page_size: 10,
  status: [CafeteriaStatusOptions[0]],
  operator: [],
}

export const CafeteriaListView: React.FC = () => {
  const { id } = useParams<'id'>()

  const [filters, setFilters] = React.useState<CafeteriaListFilterParams>(defaultFilters)
  const dispatch = useAppDispatch()

  const cafeterias = useAppSelector(cafeteriasSelector)
  useMenuActive(NavigationPath.CafeteriaList)

  const { isLoading, action: fetchCafeterias } = useApiRequest(async filters => await dispatch(getCafeterias(filters)))

  const fetchCafeteriasDebounced = React.useCallback(useDebounce(fetchCafeterias, 300), [])

  React.useEffect(() => {
    fetchCafeteriasDebounced(filters)
  }, [filters])

  React.useEffect(
    () => () => {
      dispatch(clearCafeterias())
    },
    [],
  )

  return (
    <>
      <PageTitleWithSize title="Lista kafeterii" />
      <Card>
        {id && <CafeteriaDetailsView id={parseInt(id, 10)} />}
        <CafeteriaWebSocketHandler />
        <CardBody>
          <CafeteriaListFilter
            defaultFilters={defaultFilters}
            filters={filters}
            setFilters={setFilters}
            statusOptions={CafeteriaStatusOptions}
          />
          <CafeteriaListTable isLoading={isLoading} cafeterias={cafeterias} filters={filters} setFilters={setFilters} />
        </CardBody>
      </Card>
    </>
  )
}
