import * as React from 'react'
import { ColAuto } from '@hyper/col-auto'
import { Button, Col, FormGroup, Label, Row } from 'reactstrap'
import { FormPlain } from '@hyper/forms'
import { useForm } from 'react-hook-form'
import DatePickerInput from '@components/date/date-picker-input'
import { RecommendationGiftAdd } from '@modules/crm/recommendation-gifts/recommendation-gift-add'
import { RecommendationGiftsFilters } from '@modules/crm/recommendation-gifts/recommendation-gifts-view'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { createSelectOption } from '@helpers/utils'
import { Form } from '@hyper/forms/form'
import { FormSelect } from '@hyper/forms/form-select'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { RecommendationGiftKind } from '@models/crm'
import { useCrmAppData } from '@modules/crm/hooks/use-crm-app-data'
import { useGroupedSellers } from '@components/hooks/use-grouped-sellers'

interface FormInputs {
  received_date: Date | null
  transfer_status: CustomReactSelectOption[]
}

interface Props {
  defaultFilters: RecommendationGiftsFilters
  filters: RecommendationGiftsFilters
  setFilters: (filters: RecommendationGiftsFilters) => void
}

const transferStatusOptions = [
  createSelectOption('Wstępny', 'initial'),
  createSelectOption('Przygotowano przelew', 'transfer_in_package'),
  createSelectOption('Przelew został zrealizowany', 'transfer_sent'),
]

export const RecommendationGiftFilters: React.FC<Props> = ({ filters, setFilters, defaultFilters }) => {
  const methods = useForm<FormInputs>({
    defaultValues: defaultFilters,
  })
  const appData = useCrmAppData()

  const kindOptions: CustomReactSelectOption<string, RecommendationGiftKind>[] = React.useMemo(
    () =>
      appData.recommendation_gift_kinds.map((row: [RecommendationGiftKind, string]) =>
        createSelectOption(row[1], row[0]),
      ) || [],
    [appData],
  )

  const handleReset = () => {
    methods.reset()
    setFilters(defaultFilters)
  }
  const sellers = useGroupedSellers()

  const onSubmit = async (payload: RecommendationGiftsFilters) => setFilters({ ...payload })

  useHandleTableFiltersChange(methods.watch, filters, onSubmit, { search: filters.search })

  return (
    <Row>
      <Col md={10}>
        <Form methods={methods} onSubmit={onSubmit} className="row form form-style-filter">
          <ColAuto className="px-0">
            <FormSelect
              options={transferStatusOptions}
              name="transfer_status"
              label="Status przelewu"
              selectProps={{ isClearable: true, isSelectMulti: true }}
              formPlainProps={{ colStyle: { width: 250 } }}
            />
          </ColAuto>
          <ColAuto className="px-0">
            <FormSelect
              options={sellers}
              name="seller"
              label="Sprzedawca"
              formPlainProps={{ colStyle: { width: 200 } }}
            />
          </ColAuto>
          <ColAuto className="p-0">
            <FormSelect
              options={kindOptions}
              name="kind"
              label="Rodzaj prezentu "
              selectProps={{ isClearable: true, isSelectMulti: true }}
              formPlainProps={{ colStyle: { width: 250 } }}
            />
          </ColAuto>
          <ColAuto>
            <FormPlain colClassName="p-0" name="received_date" errors={{}}>
              <Label>Data otrzymania oświadczenia</Label>
              <DatePickerInput name="received_date" />
            </FormPlain>
          </ColAuto>
          <ColAuto>
            <FormPlain colClassName="p-0" name="transfer_by_day" errors={{}}>
              <Label>Termin przelewu do dnia</Label>
              <DatePickerInput name="transfer_by_day" />
            </FormPlain>
          </ColAuto>
          <ColAuto>
            <FormGroup>
              <label className="d-block">&nbsp;</label>
              <Button color="primary">Filtruj</Button>
              {methods.formState.isDirty && (
                <Button color="light" className="ml-1" type="button" onClick={handleReset}>
                  Wyczyść
                </Button>
              )}
            </FormGroup>
          </ColAuto>
        </Form>
      </Col>
      <Col md={2} className="text-right mt-3">
        <RecommendationGiftAdd />
      </Col>
    </Row>
  )
}
