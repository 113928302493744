export const UpsellProductsForSale = {
  subscription: [
    { title: 'Pakiet prywatny', type: 'DEFAULT' },
    { title: 'Pakiet korzyści', type: 'WITH_BENEFIT' },
    { title: 'Pakiet korzyści 2.0', type: 'WITH_BENEFIT_20' },
    { title: 'GO!Holiday', type: 'GO_HOLIDAY' },
  ],
  gastro: [
    { title: 'Strzał w dziesiątkę', kind: 'gastro_coupon' },
    { title: 'Doładowanie smaku', kind: 'gastro_card_boost' },
    { title: 'Podwójna radość 2024', kind: 'gastro_card_2024' },
    { title: 'Podwójna radość 2025', kind: 'gastro_card_2025' },
  ],
  subscriptionContract: { name: 'Subskrypcje', kind: 'subscription_contract' },
}
