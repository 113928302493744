import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { ReceptionBookingCartPaymentBaseActions } from '@modules/reception/cart/payment-box/payment-options-content/reception-booking-cart-payment-base-actions'
import { asDecimal, formatPrice } from '@helpers/utils'
import { Alert, Progress } from 'reactstrap'
import { ReceptionBookingCartPaymentInfoRow } from '@modules/reception/cart/payment-box/payment-options-content/reception-booking-cart-payment-info-row'
import {
  ReceptionBookingClientWallet,
  ReceptionBookingDetailsCart,
  ReceptionBookingWalletDetails,
} from '@models/reception'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useAppDispatch } from '@store/index'
import { updateBookingWalletDetails } from '@store/slices/reservations-slice'
import { commonObjectPost } from '@store/actions/generic-actions'
import { CartSelection } from '@modules/reception/cart/payment-box/reception-booking-cart-payment-box'
import { BaseCartPaymentResponse } from '@modules/reception/cart/models'

interface PaymentResponse extends BaseCartPaymentResponse {
  wallet_details: ReceptionBookingWalletDetails
}

interface Props {
  onDecline: () => void
  clientWallet: ReceptionBookingClientWallet
  url: string
  cartSelection: CartSelection
  onCartUpdate: (cart: ReceptionBookingDetailsCart) => void
}

export const ReceptionBookingCartPaymentGastroCard = ({
  onDecline,
  clientWallet,
  url,
  cartSelection,
  onCartUpdate,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch()

  const progressValue = asDecimal(clientWallet.available_amount).mul(100).div(clientWallet.start_amount).toString()

  const { isLoading, action: handlePay } = useApiRequest(async () => {
    const response = await commonObjectPost<PaymentResponse>(url, { ...cartSelection, client_wallet: clientWallet.id })

    dispatch(updateBookingWalletDetails(response.wallet_details))
    onCartUpdate(response.cart_details)
    onDecline()
  })

  return (
    <div>
      <Alert className="py-1 px-1 alert-info ">
        <strong className="font-12">Uwaga.</strong>
        <span className="d-block font-12">
          Dokonując zakupu z vouchera <br />
          Gość zapłaci kwotę bez rabatu.
        </span>
      </Alert>
      <IconWithText
        icon="uil-tag-alt font-14"
        text="Płatność voucherem:"
        wrapperClassNames="text-primary font-weight-bold mb-3"
      />
      <ReceptionBookingCartPaymentInfoRow
        className="mb-1"
        title="Voucher:"
        value={<strong>{clientWallet.name}</strong>}
      />
      <ReceptionBookingCartPaymentInfoRow
        className="mb-1"
        title="Numer vouchera:"
        value={<strong>{clientWallet.code}</strong>}
      />
      <ReceptionBookingCartPaymentInfoRow
        className="mb-1"
        title="Pozostało:"
        value={
          <span>
            <strong>{formatPrice(clientWallet.available_amount)}</strong> z {formatPrice(clientWallet.start_amount)}
          </span>
        }
      />

      <Progress value={progressValue} color="secondary" className="mt-1 reception-booking-cart__gastro-progress" />
      <ReceptionBookingCartPaymentBaseActions onDecline={onDecline} isSaving={isLoading} onConfirm={handlePay} />
    </div>
  )
}
