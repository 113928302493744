import * as React from 'react'
import { PromocodePrefix } from '@models/promocode'
import { PromocodePrefixDelete } from '@modules/promocode/promocode-prefixes/promocode-prefix-delete'

interface PromocodePrefixDeleteIconProps {
  promocodePrefix: PromocodePrefix
}

export const PromocodePrefixDeleteIcon: React.FC<PromocodePrefixDeleteIconProps> = ({ promocodePrefix }) => (
  <PromocodePrefixDelete promocodePrefix={promocodePrefix}>
    <span className="font-16 ml-1">
      <i className="uil uil-trash-alt text-muted cursor-pointer" title="Usuń" />
    </span>
  </PromocodePrefixDelete>
)
