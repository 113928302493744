import * as React from 'react'
import { PackageWholesaleCreateModalFormInvoice } from '@modules/package-wholesale/create/form-invoice'
import { PackageWholesaleCreateModalFormPersonalData } from '@modules/package-wholesale/create/form-personal-data'
import { PackageWholesaleCreateModalFormProducts } from '@modules/package-wholesale/create/form-products'
import { Label, Row } from 'reactstrap'
import { FormPlain } from '@hyper/forms'
import DatePickerInput from '@components/date/date-picker-input'
import { startOfToday, startOfYear } from 'date-fns'
import { PackageWholesaleCreateModalFormLeaflet } from '@modules/package-wholesale/create/form-leaflet'
import { PackageWholesaleCreateModalFormPassword } from '@modules/package-wholesale/create/form-password'
import { PackageWholesaleCreateModalSlug } from '@modules/package-wholesale/create/slug'

export const PackageWholesaleCreateModalForm = (): JSX.Element => (
  <>
    <PackageWholesaleCreateModalFormPersonalData />
    <PackageWholesaleCreateModalFormInvoice />
    <PackageWholesaleCreateModalFormProducts />
    <PackageWholesaleCreateModalFormLeaflet />
    <PackageWholesaleCreateModalFormPassword />
    <Row>
      <PackageWholesaleCreateModalSlug />
      <FormPlain colSize={6} name="expire_after" colClassName="pl-3">
        <Label for="expire_after">Data ważności</Label>
        <DatePickerInput minDate={startOfYear(startOfToday())} name="expire_after" />
      </FormPlain>
    </Row>
  </>
)
