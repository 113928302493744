import * as React from 'react'
import Table from '@components/table/table'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { useModal } from '@components/modals/use-modal'
import { TableSellerRow } from '@components/table-seller-row'
import { SubscriptionContract } from '@modules/subscription-contract/models'
import { useReportsAppData } from '@modules/reports/hooks/use-reports-app-data'
import { StatusBadge } from '@modules/promotions/common/status-badge'

interface Props {
  subscription: SubscriptionContract
}

export const SubscriptionContractListTableRow: React.FC<Props> = ({ subscription }) => {
  const { sources } = useReportsAppData()

  const sourceName = React.useMemo(
    () => sources.find(row => row.id === subscription.source_marketing)?.name ?? 'Brak',
    [sources, subscription.source_marketing],
  )

  const [handleDetails] = useModal('SubscriptionContractDetailsModal', {
    subscription,
  })

  return (
    <Table.Row onDoubleClick={handleDetails} className="cursor-pointer" role="details-row">
      <Table.Cell>{subscription.number}</Table.Cell>
      <Table.Cell>{subscription.plan_display}</Table.Cell>
      <Table.Cell>{subscription.client.name}</Table.Cell>
      <Table.Cell>{subscription.client.email}</Table.Cell>
      <Table.Cell>
        <StatusBadge element={subscription} />
      </Table.Cell>
      <Table.Cell>{toDefaultDateTimeFormat(subscription.sell_date)}</Table.Cell>
      <Table.Cell>{sourceName}</Table.Cell>
      <Table.Cell>
        {subscription.subscription_started
          ? toDefaultDateTimeFormat(subscription.subscription_started)
          : 'Nie rozpoczęto'}
      </Table.Cell>
      <Table.Cell>
        <TableSellerRow sellerId={subscription.seller_id} defaultValue="Brak" />
      </Table.Cell>
    </Table.Row>
  )
}
