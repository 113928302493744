import * as React from 'react'
import { Button, Col, FormGroup, Label, Row } from 'reactstrap'
import { PromocodeTouristVoucherAdd } from '@modules/promotions/promocode-tourist-voucher/add'
import { TableFilters } from '@components/table/table'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { useForm } from 'react-hook-form'
import { useGroupedSellers } from '@components/hooks/use-grouped-sellers'
import { ColAuto } from '@hyper/col-auto'
import DateRangeInput from '@components/date/date-range-input'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { Form } from '@hyper/forms/form'
import { promocodeTouristVoucherStatusOptions } from '@modules/promotions/promocode-tourist-voucher/consts'
import { FormSelect } from '@hyper/forms/form-select'

export interface PromocodeTouristVoucherFiltersParams extends TableFilters {
  status: CustomReactSelectOption[]
  seller: CustomReactSelectOption | undefined
  created_date_after: undefined
  created_date_before: undefined
}

interface Props {
  filters: PromocodeTouristVoucherFiltersParams
  defaultFilters: PromocodeTouristVoucherFiltersParams
  setFilters: (filters: PromocodeTouristVoucherFiltersParams) => void
}

export const PromocodeTouristVoucherFilters = ({ filters, setFilters, defaultFilters }: Props): JSX.Element => {
  const methods = useForm<PromocodeTouristVoucherFiltersParams>({
    defaultValues: defaultFilters,
  })

  const onSubmit = (payload: PromocodeTouristVoucherFiltersParams) => setFilters({ ...filters, ...payload })

  const handleReset = () => {
    methods.reset()
    setFilters(defaultFilters)
  }

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  const sellersOption = useGroupedSellers()

  return (
    <Form methods={methods} onSubmit={onSubmit} className="form form-style-filter">
      <Row>
        <Col md={10} className="row">
          <ColAuto>
            <FormSelect
              options={promocodeTouristVoucherStatusOptions}
              name="status"
              label="Status"
              selectProps={{ isClearable: true, isMulti: true }}
              formPlainProps={{ colStyle: { width: 300, padding: 0 } }}
            />
          </ColAuto>
          <ColAuto>
            <FormSelect
              options={sellersOption}
              name="seller"
              label="Sprzedawca"
              selectProps={{ isClearable: true }}
              formPlainProps={{ colStyle: { width: 200, padding: 0 } }}
            />
          </ColAuto>

          <ColAuto>
            <Label>Data utworzenia</Label>
            <DateRangeInput startDateName="created_date_after" endDateName="created_date_before" />
          </ColAuto>

          <ColAuto>
            <FormGroup>
              <label className="d-block">&nbsp;</label>
              <Button color="primary">Filtruj</Button>
              {methods.formState.isDirty && (
                <Button color="light" className="ml-1" type="button" onClick={handleReset}>
                  Wyczyść
                </Button>
              )}
            </FormGroup>
          </ColAuto>
        </Col>

        <Col md={2} className="text-right px-0">
          <PromocodeTouristVoucherAdd />
        </Col>
        <SearchFilter colClassName="col-md-2" />
      </Row>
    </Form>
  )
}
