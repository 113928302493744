import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { ReceptionCheckType } from '@models/reception'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'

export const ReceptionView: React.FC = () => {
  const user = useAuthenticatedUser()

  const navigate = useNavigate()

  React.useEffect(() => {
    navigate(
      NavigationPath.ReceptionWithParams.replace(':resort_id', String(user.resorts[0].id)).replace(
        ':check_type',
        String(ReceptionCheckType.TO_CHECKIN),
      ),
    )
  }, [])

  return null
}
