import * as React from 'react'

interface ImportErrorRowData {
  message: string
}

type ImportErrorRow<T> = {
  [key in keyof T]: ImportErrorRowData[]
}

interface ImportError<T> {
  [key: number]: ImportErrorRow<T>
}

export interface XMLImportErrorResponse<T> {
  errors: ImportError<T>
  labels: T
}

interface Props<T> {
  importError: XMLImportErrorResponse<T> | null
}

export const XMLImportErrorsAnalyzeTable = <T,>({ importError }: Props<T>): JSX.Element | null => {
  if (!importError) return null

  return (
    <div className="px-2 w-100 font-12 mt-n2">
      <p className="fw-semi-bold text-danger">Plik nie jest wypełniony poprawnie</p>
      <p className="mt-2 mb-1 fw-semi-bold">Analiza błędów wgranego pliku</p>
      <table className="table w-100 table-striped">
        <thead>
          <tr>
            <td className="py-1">Wiersz</td>
            <td className="py-1">Pole</td>
            <td className="py-1">Błąd</td>
          </tr>
        </thead>
        <tbody>
          {Object.entries(importError.errors).map(([row, data]: [string, ImportErrorRow<T>], index) => (
            <React.Fragment key={index}>
              {Object.entries(data).map(([field, error]: [string, ImportErrorRowData[]]) => (
                <tr key={row}>
                  <td className="py-1">{row}</td>
                  <td className="py-1">{importError?.labels[field]}</td>
                  <td className="py-1">{error[0].message}</td>
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  )
}
