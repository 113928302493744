import { AsyncThunkParams } from '@store/index'
import { commonObjectGet } from '@store/actions/generic-actions'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Cafeteria, CafeteriaAggregation, CafeteriaAppData, CafeteriaDetails } from '@models/cafeteria'
import { ListDataWithAggregation, PaginationResponseWithAggregation } from '@models/dashboard'
import { setDashboardStandardPaginator } from '@store/actions/dashboard-actions'
import { CafeteriaListFilterParams } from '@modules/cafeteria/list/filter'
import { extractSelectOptionsValues } from '@helpers/utils'

export const getCafeteriaAppData = createAsyncThunk<CafeteriaAppData, void, AsyncThunkParams>(
  'cafeteria/getCafeteriaAppData',
  async (_, { getState }) => {
    const appData = getState().cafeteriaState.appData
    if (appData.status === 'unknown') {
      return await commonObjectGet<CafeteriaAppData>(getState().appState.appData.urls.cafeteria.app_data)
    }
    return appData
  },
)

export const getCafeterias = createAsyncThunk<
  ListDataWithAggregation<Cafeteria[], CafeteriaAggregation>,
  CafeteriaListFilterParams,
  AsyncThunkParams
>('cafeteria/getCafeterias', async (filters, { getState, dispatch }) => {
  const url = getState().appState.appData.urls.cafeteria.cafeterias
  const { status, operator, ...payload } = filters

  const data = await commonObjectGet<PaginationResponseWithAggregation<Cafeteria, CafeteriaAggregation>>(url, {
    ...extractSelectOptionsValues(payload),
    ...(status.length && extractSelectOptionsValues({ status })),
    ...(operator.length && extractSelectOptionsValues({ operator })),
  })

  dispatch(setDashboardStandardPaginator({ ...data }))

  return { results: data.results, aggregation: data.aggregation }
})

export const getCafeteriaDetails = createAsyncThunk<CafeteriaDetails, number, AsyncThunkParams>(
  'cafeteria/getCafeteriaDetails',
  async (id, { getState }) =>
    await commonObjectGet<CafeteriaDetails>(`${getState().appState.appData.urls.cafeteria.cafeterias}${id}/`, id),
)
