import * as React from 'react'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { HousekeepingAppData } from '@modules/housekeeping/models'
import { getHousekeepingAppData } from '@store/actions/housekeeping-actions'
import { useApiRequest } from '@components/hooks/use-api-request'
import { setHouseKeepingAppDataStatus } from '@store/slices/housekeeping-slice'

export const useHousekeepingAppData = (resort?: number): HousekeepingAppData => {
  const appData = useAppSelector((state: RootState) => state.houseKeepingState.appData)
  const dispatch = useAppDispatch()

  const { action: fetch } = useApiRequest(async () => {
    if (resort) {
      dispatch(getHousekeepingAppData(resort))
      dispatch(setHouseKeepingAppDataStatus('loading'))
    }
  })

  React.useEffect(() => {
    fetch()
  }, [resort])

  return appData
}
