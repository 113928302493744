import * as React from 'react'
import { TechnicalOrder } from '@models/technical-orders'
import { TableFilters } from '@components/table/table'
import { TechnicalOrdersTodoOptions } from '@modules/technical-orders/technical-orders-todo/options'
import { useSelectableRows } from '@components/table/use-selectable-rows'
import { TableViewType, OrderTableViewSwitch } from '@modules/technical-orders/common/table-view-switch'
import { TechnicalOrdersOrderedDroppableTable } from '@modules/technical-orders/technical-orders-ordered/drag-and-drop/droppable-table'
import { TypedQueryResult } from '@api/base'
import { useGetTechnicalOrdersQuery } from '@api/technical-orders'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { FormProvider, useForm } from 'react-hook-form'
import { TechnicalOrdersOrderedTableHeaders } from '@modules/technical-orders/technical-orders-ordered/table-header'
import { TechnicalOrdersTodoFilters } from '@modules/technical-orders/technical-orders-todo/filters'
import * as R from 'ramda'
import { TechnicalOrdersOrderedGroupedTable } from '@modules/technical-orders/technical-orders-ordered/grouped-table'

const defaultFilters: TableFilters = {
  search: '',
  ordering: 'resort',
  page: 1,
  page_size: 9999,
}

interface Props {
  priority: 0 | 10
  resortId: number
  isDragDisabled: boolean
  onFiltersChange: (filtersChanged: boolean) => void
  dataTestId?: string
}

const emptyTable = []

export const TechnicalOrdersOrderedTable: React.FC<Props> = ({
  resortId,
  priority,
  isDragDisabled,
  onFiltersChange,
  dataTestId,
}) => {
  const [filters, setFilters] = React.useState<TableFilters>(defaultFilters)
  const [tableView, setTableView] = React.useState<TableViewType>('list')

  const methods = useForm({ defaultValues: defaultFilters })

  const { data: technicalOrders = emptyTable } = useGetTechnicalOrdersQuery({
    resort: resortId,
    status: 'ordered',
    ...filters,
    priority,
  }) as TypedQueryResult<TechnicalOrder[]>

  const { selectableHeader, selectableCell, clearSelectedRows, selectedRows } = useSelectableRows<TechnicalOrder>(
    technicalOrders,
    [],
    {
      tableHeaderClassName: 'bg-white border-top-0 vertical-align-middle',
      selectableCellClassName: 'vertical-align-middle',
    },
  )

  const memoizedSelectableCell = React.useCallback(selectableCell, [selectedRows])

  useDidUpdateEffect(() => {
    const filledFields = R.filter(value => value !== undefined, filters)
    onFiltersChange(!R.equals(filledFields, defaultFilters))
  }, [filters, defaultFilters])

  return (
    <>
      <TechnicalOrdersTodoFilters filters={filters} setFilters={setFilters} defaultFilters={defaultFilters}>
        <TechnicalOrdersTodoOptions tab="ordered" clearSelectedRows={clearSelectedRows} selectedRows={selectedRows} />
        <OrderTableViewSwitch tableView={tableView} setTableView={setTableView} />
      </TechnicalOrdersTodoFilters>
      <FormProvider {...methods}>
        {tableView === 'list' ? (
          <TechnicalOrdersOrderedDroppableTable
            technicalOrders={technicalOrders}
            resortId={resortId}
            priority={priority}
            dataTestId={dataTestId}
            selectableCell={memoizedSelectableCell}
            isDragDisabled={isDragDisabled}
            headers={
              <TechnicalOrdersOrderedTableHeaders
                tableView={tableView}
                selectableHeader={selectableHeader}
                onFiltersChange={setFilters}
                resortId={resortId}
              />
            }
          />
        ) : (
          <TechnicalOrdersOrderedGroupedTable
            technicalOrders={technicalOrders}
            resortId={resortId}
            dataTestId={dataTestId}
            headers={
              <TechnicalOrdersOrderedTableHeaders
                tableView={tableView}
                selectableHeader={selectableHeader}
                onFiltersChange={setFilters}
                resortId={resortId}
              />
            }
          />
        )}
      </FormProvider>
    </>
  )
}
