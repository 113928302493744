import * as React from 'react'
import { ColAuto } from '@hyper/col-auto'
import { Button, FormGroup, Row } from 'reactstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { FormSelect } from '@hyper/forms/form-select'
import { HousekeepingUsersListTableFilters } from '@modules/housekeeping/users/list/index'
import { useAppData } from '@components/hooks/use-app-data'
import { createSelectOption } from '@helpers/utils'
import { useAppSelector } from '@store/index'
import { housekeepingCompaniesSelector } from '@store/slices/housekeeping-slice'
import { HousekeepingCompany } from '@modules/housekeeping/models'
import { housekeepingRoleOptions } from '@modules/housekeeping/users/modal'
import { YesNoOptions } from '@helpers/consts'

interface Props {
  defaultFilters: HousekeepingUsersListTableFilters
  filters: HousekeepingUsersListTableFilters
  setFilters: (filters: HousekeepingUsersListTableFilters) => void
}

export const HousekeepingUsersListTableFilter: React.FC<Props> = ({ defaultFilters, filters, setFilters }) => {
  const methods = useForm<HousekeepingUsersListTableFilters>({
    defaultValues: defaultFilters,
  })

  const handleReset = () => {
    methods.reset()
    setFilters(defaultFilters)
  }

  const housekeepingCompanies = useAppSelector(housekeepingCompaniesSelector)

  const companiesOptions = housekeepingCompanies.map((company: HousekeepingCompany) =>
    createSelectOption(company.name, company.id),
  )

  const { resorts } = useAppData()

  const resortOptions = resorts.map(resort => createSelectOption(resort.name, resort.id))

  const onSubmit = async (payload: HousekeepingUsersListTableFilters) => setFilters({ ...filters, ...payload })

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  return (
    <FormProvider {...methods}>
      <Row>
        <ColAuto className="px-0">
          <FormSelect
            options={resortOptions}
            name="resort"
            label="Ośrodek"
            selectProps={{ isClearable: true }}
            formPlainProps={{ colStyle: { width: 200 } }}
          />
        </ColAuto>
        <ColAuto className="px-0">
          <FormSelect
            options={companiesOptions}
            name="housekeeping_company"
            label="Firma"
            selectProps={{ isClearable: true }}
            formPlainProps={{ colStyle: { width: 200 } }}
          />
        </ColAuto>
        <ColAuto className="px-0">
          <FormSelect
            options={housekeepingRoleOptions}
            name="roles"
            label="Rola"
            selectProps={{ isClearable: true, isSelectMulti: true }}
            formPlainProps={{ colStyle: { width: 200 } }}
          />
        </ColAuto>
        <ColAuto className="px-0">
          <FormSelect
            options={YesNoOptions}
            name="is_active"
            label="Aktywny?"
            selectProps={{ isClearable: true }}
            formPlainProps={{ colStyle: { width: 200 } }}
          />
        </ColAuto>
        <ColAuto>
          <FormGroup>
            <label className="d-block">&nbsp;</label>
            {methods.formState.isDirty && (
              <Button color="light" className="ml-1" type="button" onClick={handleReset}>
                Wyczyść
              </Button>
            )}
          </FormGroup>
        </ColAuto>
        <SearchFilter colClassName="col-md-2" />
      </Row>
    </FormProvider>
  )
}
