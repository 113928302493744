import * as React from 'react'
import { Dropdown, DropdownItem, DropdownToggle } from 'reactstrap'
import { RootState, useAppSelector } from '@store/index'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { useModal } from '@components/modals/use-modal'
import { SearchableDropdownMenu } from '@components/searchable-dropdown-content'
import { PackageType } from '@models/package'
import { IconWithText } from '@components/icon-with-text'
import { useToggle } from '@components/hooks/use-toggle'

export const ReportsGenerator: React.FC = () => {
  const [isOpen, toggleIsOpen] = useToggle()

  const user = useAuthenticatedUser()

  const {
    report_task_issue_order,
    report_task_subscription,
    report_task_subscription_code,
    report_task_package,
    report_task_package_hpi,
    report_task_package_wholesale,
    report_task_fault_order,
    report_task_subscription_payment,
  } = useAppSelector((state: RootState) => state.appState.appData.urls.reports)

  const [handleGenerateSubscriptionPaymentsReport] = useModal('ReportsGeneratorSubscription', {
    url: report_task_subscription_payment,
    showPaymentDate: true,
    showPmsPaymentDate: true,
    title: 'Pakiety prywatne/hurtowe - Wpłaty',
  })
  const [handleGenerateSubscriptionCodesReport] = useModal('ReportsGeneratorSubscription', {
    url: report_task_subscription_code,
    title: 'Pakiety prywatne/hurtowe - Kody',
  })
  const [handleGenerateSubscriptionListReport] = useModal('ReportsGeneratorSubscription', {
    url: report_task_subscription,
    showPaymentDate: true,
    title: 'Pakiety prywatne - Lista',
  })
  const [handleGeneratePaymentMoveReport] = useModal('ReportsGeneratorPaymentMove')
  const [handleGenerateClientReport] = useModal('ReportsGeneratorClient')
  const [handleGenerateReservationReport] = useModal('ReportsGeneratorReservation')
  const [handleGenerateLoadNoteReport] = useModal('ReportsGeneratorLoadNote')
  const [handleGenerateStatsSaleItemReport] = useModal('ReportsGeneratorStatsSaleItem')
  const [handleBookingPaymentReport] = useModal('ReportsGeneratorBookingPayment')
  const [handleShopReport] = useModal('ReportsGeneratorShopProduct')
  const [handleErliCodeReport] = useModal('ReportsGeneratorErliCode')
  const [handlePackageVipReport] = useModal('ReportsGeneratorPackageVip')
  const [handleAlternativeFeedingReport] = useModal('ReportsGeneratorAlternativeFeeding')
  const [handleFeedingReport] = useModal('ReportsGeneratorFeeding')
  const [handleFeedingSpecialGuestReport] = useModal('ReportsGeneratorFeedingSpecialGuest')
  const [handleFeedingSoldMealsReport] = useModal('ReportsGeneratorFeedingSoldMeals')
  const [handleFeedingClientReport] = useModal('ReportsGeneratorFeedingClient')
  const [handlePaymentRegisterReport] = useModal('ReportsPaymentRegisterModal')
  const [handleGastroCardReport] = useModal('ReportsGeneratorGastroCard')
  const [handleShopReceiptReport] = useModal('ReportsGeneratorShopReceipt')
  const [handleShopReceiptProductsReport] = useModal('ReportsGeneratorShopReceiptProducts')
  const [handleFeedingWorkerReport] = useModal('ReportsGeneratorFeedingWorker')
  const [showHousekeepingCleaningOrderReportModal] = useModal('ReportsHousekeepingCleaningOrderModal')
  const [showHousekeepingUserReportModal] = useModal('ReportsHousekeepingUserModal')
  const [showHousekeepingUsersReportModal] = useModal('ReportsHousekeepingUsersModal')
  const [showNewHousekeepingUsersReportModal] = useModal('ReportsHousekeepingUsersModal', {
    urlField: 'report_task_housekeeping_users_report_new',
  })
  const [showBandsReportModal] = useModal('ReportResortBand')
  const [handleFaultOrderReport] = useModal('ReportsApplicationOrders', {
    url: report_task_fault_order,
    title: 'Raport usterek',
  })
  const [handleIssueOrderReport] = useModal('ReportsApplicationOrders', {
    url: report_task_issue_order,
    title: 'Raport zgłoszeń',
  })
  const [handleBookingReservationDiscount] = useModal('ReportsBookingReservationDiscount')
  const [handlePackageHpr] = useModal('ReportsGeneratorPackagesModal', {
    url: report_task_package,
    title: 'Pakiety firmowe HPR - Lista',
  })
  const [handlePackageHpi] = useModal('ReportsGeneratorPackagesModal', {
    url: report_task_package_hpi,
    title: 'Pakiety firmowe HPI - Lista',
  })
  const [handlePackageWholesale] = useModal('ReportsGeneratorPackagesModal', {
    url: report_task_package_wholesale,
    title: 'Pakiety hurtowe - Lista',
  })

  const [showReservationBillReportModal] = useModal('ReportsReservationBillModal')
  const [showReservationBillItemReportModal] = useModal('ReportsReservationBillItemModal')
  const [showApartmentMetersReportModal] = useModal('ReportsApartmentMeterModal')
  const [showCommonMetersReportModal] = useModal('ReportsCommonMetersModal')
  const [showGastroCardVatReportModal] = useModal('ReportsGastroCardVatModal')
  const [showMarketingRODOReportModal] = useModal('ReportsMarketingRODOModal')
  const [showMarketingRODOCancelReportModal] = useModal('ReportsMarketingRODOCanceledModal')
  const [showPackageWholesaleCodeReportModal] = useModal('ReportsPackageWholesaleCodeModal')
  const [showReservationLatePaymentReportModal] = useModal('ReportsReservationLatePaymentModal')
  const [showPromocodeToursitVoucherReportModal] = useModal('ReportPromocodeTouristVoucherModal')
  const [showZooTicketsReportModal] = useModal('ReportZooTicketsModal')
  const [showSuntagoTicketsReportModal] = useModal('ReportSuntagoTicketsModal')
  const [showContractReportModal] = useModal('ReportsContractModal')
  const [showPackageFullCodeHPRReport] = useModal('ReportsPackageFullCodeModal', { packageType: PackageType.HPR })
  const [showPackageFullCodeHPIReport] = useModal('ReportsPackageFullCodeModal', { packageType: PackageType.HPI })
  const [showClimaticReports] = useModal('ReportsClimaticModal', {})
  const [showReportsSubscriptionContractModal] = useModal('ReportsSubscriptionContractModal', {})
  const [showReportsBookingStatisticsModal] = useModal('ReportsBookingStatisticsModal', {})
  const [showPermissionsReportModal] = useModal('ReportsPermissionsModal', {})
  const [showGastroCouponReportModal] = useModal('ReportsGastroCouponModal')
  const [showGastroCardBoostReportModal] = useModal('ReportsGeneratorGastroCard', { kind: 'gastro_card_boost' })
  const [showFloorHeatingReportModal] = useModal('ReportsFloorHeatingModal')
  const [showSMSNotificationsReport] = useModal('ReportSmsNotificationsModal')
  const [showSubscriptionConversionModal] = useModal('ReportSubscriptionConversionModal')
  const [showTechnicalOrdersReport] = useModal('ReportTechnicalOrdersModal')
  const [showGenericVoucherCodesReport] = useModal('ReportGenericVoucherCode')
  const [showCourtyardOccupancyReport] = useModal('ReportGenericCourtyardOccupancyModal')
  const [showCashbackVoucherReport] = useModal('ReportCashbackVoucherModal')

  const reports = React.useMemo(
    () =>
      [
        { name: 'Raport rezerwacji', handler: handleGenerateReservationReport },
        { name: 'Raport klientów', handler: handleGenerateClientReport },
        {
          name: 'Pakiety prywatne - Lista',
          handler: handleGenerateSubscriptionListReport,
          permission: UserPermission.SubscriptionCanGenerateReport,
        },
        {
          name: 'Pakiety prywatne/hurtowe - Kody',
          handler: handleGenerateSubscriptionCodesReport,
          permission: UserPermission.SubscriptionCanGenerateReport,
        },
        {
          name: 'Pakiety prywatne/hurtowe - Wpłaty',
          handler: handleGenerateSubscriptionPaymentsReport,
          permission: UserPermission.SubscriptionCanGenerateReport,
        },
        {
          name: 'Raport przeniesień',
          handler: handleGeneratePaymentMoveReport,
          permission: UserPermission.CashPaymentMoveReportRowCanView,
        },
        {
          name: 'Raport sprzedaży',
          handler: handleGenerateStatsSaleItemReport,
          permission: UserPermission.ReportsCanViewStatsSaleItem,
        },
        {
          name: 'Raport not obciążeniowych',
          handler: handleGenerateLoadNoteReport,
          permission: UserPermission.ReceptionCanExportLoadNoteReport,
        },
        {
          name: 'Raport płatności',
          handler: handleBookingPaymentReport,
          permission: UserPermission.ReportsCanExportPayments,
        },
        {
          name: 'Raport pordukty w sklepie',
          handler: handleShopReport,
          permission: UserPermission.ReportsShopProductCanView,
        },
        {
          name: 'Raport kodów erli',
          handler: handleErliCodeReport,
          permission: UserPermission.ReportsErliPromocodeUsageCanView,
        },
        {
          name: 'Raport pakietów VIP',
          handler: handlePackageVipReport,
          permission: UserPermission.ReportsPackageCode,
        },
        {
          name: 'Raport wyżywienia',
          handler: handleFeedingReport,
          permission: UserPermission.ReportsFeeding,
        },
        {
          name: 'Raport wyżywienia (alternatywny)',
          handler: handleAlternativeFeedingReport,
          permission: UserPermission.ReportsAlternativeFeeding,
        },
        {
          name: 'Raport wyżywienia (klienci zewnętrzni)',
          handler: handleFeedingClientReport,
          permission: UserPermission.ReportsFeedingClient,
        },
        {
          name: 'Raport wyżywienia (pracownicy)',
          handler: handleFeedingWorkerReport,
          permission: UserPermission.ReportsFeedingWorkers,
        },
        {
          name: 'Raport wyżywienia (goście specjalni)',
          handler: handleFeedingSpecialGuestReport,
          permission: UserPermission.ReportsFeedingWorkers,
        },
        {
          name: 'Raport wyżywienia (sprzedane posiłki)',
          handler: handleFeedingSoldMealsReport,
          permission: UserPermission.ReportsFeedingSale,
        },
        {
          name: 'Raport usterek',
          handler: handleFaultOrderReport,
          permission: UserPermission.ReportsFaultOrder,
        },
        {
          name: 'Raport zgłoszeń',
          handler: handleIssueOrderReport,
          permission: UserPermission.ReportsIssueOrder,
        },
        {
          name: 'Raport niewykorzystana promocja',
          handler: handleBookingReservationDiscount,
          permission: UserPermission.ReportsBookingReservationDiscount,
        },
        {
          name: 'Pakiety firmowe HPR - Lista',
          handler: handlePackageHpr,
          permission: UserPermission.ReportsPackage,
        },
        {
          name: 'Pakiety firmowe HPI - Lista',
          handler: handlePackageHpi,
          permission: UserPermission.ReportsPackageHpi,
        },
        {
          name: 'Pakiety hurtowe - Lista',
          handler: handlePackageWholesale,
          permission: UserPermission.ReportsPackageWholesale,
        },
        {
          name: 'Podwójna radość - Raport sprzedaży',
          handler: handleGastroCardReport,
          permission: UserPermission.PromotionsGastroCardCanExport,
        },
        {
          name: 'Pakiety hurtowe - Kody',
          handler: showPackageWholesaleCodeReportModal,
          permission: UserPermission.ReportsPackageWholesaleCode,
        },
        {
          name: 'Podwójna radość - Raport sprzedaży na voucher',
          handler: showGastroCardVatReportModal,
          permission: UserPermission.ReportsGastroCardVat,
        },
        {
          name: 'Raport rejestru płatności',
          handler: handlePaymentRegisterReport,
          permission: UserPermission.CashPaymentRegisterCanView,
        },
        {
          name: 'Sklep - Raport Sprzedaży (paragony)',
          handler: handleShopReceiptReport,
          permission: UserPermission.ShopCanExportReceipts,
        },
        {
          name: 'Sklep - Raport Sprzedaży (produkty)',
          handler: handleShopReceiptProductsReport,
          permission: UserPermission.ReportsReceiptProduct,
        },
        {
          name: 'Housekeeping - Raport sprzątań',
          handler: showHousekeepingCleaningOrderReportModal,
          permission: UserPermission.ReportsHousekeepingCleaningOrders,
        },
        {
          name: 'Housekeeping - Raport zbiorczy',
          handler: showHousekeepingUsersReportModal,
          permission: UserPermission.ReportsHousekeepingUsers,
        },
        {
          name: 'RCP - Raport zbiorczy',
          handler: showNewHousekeepingUsersReportModal,
          permission: UserPermission.ReportsHousekeepingUsers,
        },
        {
          name: 'Housekeeping - Raport pracownika',
          handler: showHousekeepingUserReportModal,
          permission: UserPermission.ReportsHousekeepingUser,
        },
        {
          name: 'Raport opasek - ośrodki',
          handler: showBandsReportModal,
          permission: UserPermission.ReportsResortsBand,
        },
        {
          name: 'Rezerwacje - Płatności na pokój (zbiorczy)',
          handler: showReservationBillReportModal,
          permission: UserPermission.ReportsReservationBill,
        },
        {
          name: 'Raport liczników',
          handler: showApartmentMetersReportModal,
          permission: UserPermission.ReportsApartmentMeter,
        },
        {
          name: 'Raport liczników części wspólnych',
          handler: showCommonMetersReportModal,
          permission: UserPermission.ReportsCommonMeters,
        },
        {
          name: 'Raport "Strzał w dziesiątkę"',
          handler: showGastroCouponReportModal,
          permission: UserPermission.ReportsGastroCoupon,
        },
        {
          name: 'Raport "Doładowanie smaku"',
          handler: showGastroCardBoostReportModal,
          permission: UserPermission.ReportsGastroCardBoost,
        },
        {
          name: 'Raport zgód RODO',
          handler: showMarketingRODOReportModal,
          permission: UserPermission.ReportsMarketingRodo,
        },
        {
          name: 'Raport wycofanych zgód marketingowych (RODO)',
          handler: showMarketingRODOCancelReportModal,
          permission: UserPermission.ReportsMarketingRodoCancel,
        },
        {
          name: 'Rezerwacje - Płatności na pokój (szczegółowy)',
          handler: showReservationBillItemReportModal,
          permission: UserPermission.ReportsReservationBillItem,
        },
        {
          name: 'Rezerwacje - Raport spóźnionych wpłat',
          handler: showReservationLatePaymentReportModal,
          permission: UserPermission.ReportsReservationLatePayment,
        },
        {
          name: 'Raport vouchera turystycznego',
          handler: showPromocodeToursitVoucherReportModal,
          permission: UserPermission.ReportsPromocodeTouristVoucher,
        },
        {
          name: 'Raport biletów do ZOO',
          handler: showZooTicketsReportModal,
          permission: UserPermission.ReportsEntranceTicketZoo,
        },
        {
          name: 'Raport biletów do Suntago',
          handler: showSuntagoTicketsReportModal,
          permission: UserPermission.ReportsEntranceTicketSuntago,
        },
        {
          name: 'Raport kontrakty',
          handler: showContractReportModal,
          permission: UserPermission.ReportsContract,
        },
        {
          name: 'Pakiety firmowe HPR - Kody',
          handler: showPackageFullCodeHPRReport,
          permission: UserPermission.ReportsPackageHprFullCode,
        },
        {
          name: 'Pakiety firmowe HPI - Kody',
          handler: showPackageFullCodeHPIReport,
          permission: UserPermission.ReportsPackageHpiFullCode,
        },
        {
          name: 'Raport opłat klimatycznych',
          handler: showClimaticReports,
          permission: UserPermission.ReportsClimaticTaxReport,
        },
        {
          name: 'Raport subskrypcji',
          handler: showReportsSubscriptionContractModal,
          permission: UserPermission.ReportsSubscriptionContract,
        },
        {
          name: 'Raport rezerwacje - statystyki',
          handler: showReportsBookingStatisticsModal,
          permission: UserPermission.HasAccessToBookingInfo,
        },
        {
          name: 'Raport liczników ogrzewania podłogowego',
          handler: showFloorHeatingReportModal,
          permission: UserPermission.ReportsFloorHeating,
        },
        {
          name: 'Raport ulepszonych kodów',
          handler: showSubscriptionConversionModal,
          permission: UserPermission.ReportsSubscriptionConversionViewReport,
        },
        {
          name: 'Grupy i uprawnienia',
          handler: showPermissionsReportModal,
          permission: UserPermission.ReportsPermission,
        },
        {
          name: 'Raport wysłanych SMS',
          handler: showSMSNotificationsReport,
          permission: UserPermission.ReportsSMS,
        },
        {
          name: 'Raport wykonanych usterek',
          handler: showTechnicalOrdersReport,
          permission: UserPermission.ReportTechnicalOrderReport,
        },
        {
          name: 'Raport voucher Bocian',
          handler: showGenericVoucherCodesReport,
          permission: UserPermission.ReportsGenericVoucherCodeReport,
        },
        {
          name: 'Raport obłożenia lokali w dziedzińcach',
          handler: showCourtyardOccupancyReport,
          permission: UserPermission.ReportsCourtyardOccupancyReport,
        },
        {
          name: 'Raport voucherów rePLAY!',
          handler: showCashbackVoucherReport,
          permission: UserPermission.ReportsCashbackVoucherReport,
        },
      ]
        .filter(item => (item.permission ? user.hasPerm(item.permission) : true))
        .sort((a, b) => a.name.localeCompare(b.name)),
    [user],
  )

  const handleEnter = reportName => {
    const reportToOpen = reports.find(report => report.name === reportName)
    if (reportToOpen) {
      toggleIsOpen()
      reportToOpen.handler()
    }
  }

  return (
    <div className="text-right">
      <Dropdown direction="down" group={true} isOpen={isOpen} toggle={toggleIsOpen}>
        <DropdownToggle color="green" caret={true} className="text-semi-strong">
          <IconWithText icon="uil-plus" text="Nowy raport" />
        </DropdownToggle>
        {isOpen && (
          <SearchableDropdownMenu searchLabel="Nazwa raportu" right={true} onSingleItemEnter={handleEnter}>
            {reports.map(report => (
              <DropdownItem name={report.name} onClick={report.handler} key={report.name}>
                {report.name}
              </DropdownItem>
            ))}
          </SearchableDropdownMenu>
        )}
      </Dropdown>
    </div>
  )
}
