import * as React from 'react'
import { CleaningOrder } from '@modules/housekeeping/models'
import { HousekeepingOrderTableHeaderLocal } from '@modules/housekeeping/common/table-headers/table-header-local'
import { HousekeepingOrderTableHeaderKind } from '@modules/housekeeping/common/table-headers/table-header-kind'
import { HousekeepingOrderTableHeaderServiceKind } from '@modules/housekeeping/common/table-headers/table-header-service-kind'
import { TableViewType } from '@modules/technical-orders/common/table-view-switch'
import { HousekeepingCleaningOrderOrderedGroupedRows } from '@modules/housekeeping/cleaning-orders/ordered/grouped-row'
import { HousekeepingOrderTableHeaderWorker } from '@modules/housekeeping/common/table-headers/table-header-worker'
import { HousekeepingNoOrdersRow } from '@modules/housekeeping/cleaning-orders/common/no-orders'
import { HousekeepingCleaningOrderOrderedRow } from '@modules/housekeeping/cleaning-orders/ordered/draggable-row'

interface HousekeepingCleaningOrdersOrderedTableProps {
  filteredCleaningOrders: CleaningOrder[]
  tableView: TableViewType
  resortId: number
}

export const HousekeepingCleaningOrdersOrderedTable: React.FC<HousekeepingCleaningOrdersOrderedTableProps> = ({
  filteredCleaningOrders,
  tableView,
  resortId,
}) => {
  const isListTableView = tableView === 'list'

  return (
    <table className="housekeeping__orders_table table table-striped">
      <thead>
        <tr>
          {!isListTableView && (
            <th className="border-top-0" style={{ width: 200 }}>
              Zespół
            </th>
          )}
          <HousekeepingOrderTableHeaderLocal />
          <HousekeepingOrderTableHeaderKind />
          <HousekeepingOrderTableHeaderServiceKind />
          {isListTableView && (
            <HousekeepingOrderTableHeaderWorker cleaningOrders={filteredCleaningOrders} resortId={resortId} />
          )}
          <th className="border-top-0">Zlecone przez</th>
          <th className="border-top-0" style={{ width: 10 }} />
        </tr>
      </thead>
      <tbody>
        {filteredCleaningOrders.length ? (
          isListTableView ? (
            filteredCleaningOrders.map((cleaningOrder: CleaningOrder) => (
              <HousekeepingCleaningOrderOrderedRow key={cleaningOrder.id} cleaningOrder={cleaningOrder} />
            ))
          ) : (
            <HousekeepingCleaningOrderOrderedGroupedRows cleaningOrders={filteredCleaningOrders} />
          )
        ) : (
          <HousekeepingNoOrdersRow colSpan={8} />
        )}
      </tbody>
    </table>
  )
}
