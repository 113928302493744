import * as React from 'react'
import { TableFilters } from '@components/table/table'
import { FormSelect } from '@hyper/forms/form-select'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { createSelectOption } from '@helpers/utils'

interface Props {
  setFilters: React.Dispatch<React.SetStateAction<TableFilters>>
}

export const UnitsTableHeadersHousekeepingStatus: React.FC<Props> = ({ setFilters }) => {
  const options = [
    createSelectOption('Do sprzątania', 'dirty'),
    createSelectOption('W trakcie sprzątania', 'cleaning'),
    createSelectOption('Oczekuje na sprawdzenie', 'waiting'),
    createSelectOption('Posprzątany', 'clean'),
  ]
  const handleChange = (option: CustomReactSelectOption) => {
    setFilters(filters => ({ ...filters, apartment__housekeeping_status: option?.value ?? undefined }))
  }
  return (
    <div style={{ minWidth: 200 }}>
      <FormSelect
        options={options}
        name="kind"
        selectProps={{ isClearable: true, placeholder: 'Status czystości', onChange: handleChange }}
        formPlainProps={{ colClassName: 'p-0 m-0', formGroupClassName: 'p-0 m-0 text-normal' }}
      />
    </div>
  )
}
