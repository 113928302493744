import * as React from 'react'
import { useModal } from '@components/modals/use-modal'
import { commonObjectGet } from '@store/actions/generic-actions'
import { RootState, useAppSelector } from '@store/index'
import { SubscriptionDetails, SubscriptionType } from '@modules/subscription/models'

interface Props {
  id: number
  type: SubscriptionType
}

export const SubscriptionDetailsView = ({ id, type }: Props): null => {
  const [handleDetails] = useModal('SubscriptionDetailsModal', { persist: true })
  const url = useAppSelector((state: RootState) => state.appState.appData.urls.subscription.subscriptions).replace(
    'replace-me',
    type,
  )

  const show = async () => {
    const subscription = await commonObjectGet<SubscriptionDetails>(`${url}${id}/`)
    handleDetails(null, { subscription }, true)
  }

  React.useEffect(() => {
    show()
  }, [])

  return null
}
