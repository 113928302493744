import * as React from 'react'
import classNames from 'classnames'
import { ServiceBandStatus, serviceBandStatusMapping } from '@modules/bands/service-bands/models'

interface Props {
  status: ServiceBandStatus
}

const getServiceBandStatusClass = (status: ServiceBandStatus) =>
  ({
    inactive: 'badge-danger',
    active: 'badge-success',
    removed: 'badge-gray',
  })[status] || 'badge-danger'

export const ServiceBandStatusBadge: React.FC<Props> = ({ status }) => (
  <span className={classNames('badge text-white', getServiceBandStatusClass(status))}>
    {serviceBandStatusMapping[status]}
  </span>
)
