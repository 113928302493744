import * as React from 'react'
import { CalendarDate } from '@store/reducers/timeline-reducers'
import { useTimelineSelection } from '@modules/reservations/timeline/use-timeline-selection'
import timelineHelper from '@helpers/timeline-helper'
import styleHelper from '@helpers/style-helper'
import { Apartment } from '@models/apartment'

interface Props {
  apartments: Apartment[]
  renderedDates: CalendarDate[]
}
export const TimelineRows = ({ renderedDates, apartments }: Props): JSX.Element => {
  const { selectedRows } = useTimelineSelection()

  return (
    <>
      {selectedRows.map((apartment: Apartment) => (
        <div
          data-testid="calendar-row"
          key={apartment.id}
          className="calendar-content__row is-selected"
          style={{
            width: timelineHelper.getRowWidth(renderedDates),
            top: timelineHelper.getItemTopOffset(apartment, apartments),
            height: styleHelper.valueToPX(timelineHelper.getRowHeight()),
            left: 0,
          }}
        />
      ))}
    </>
  )
}
