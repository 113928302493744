import * as React from 'react'
import { useAppDispatch } from '@store/index'
import { NotificationEvents } from '@models/dashboard'
import { Apartment } from '@models/apartment'
import { removeBooking, updateApartments, updateBookingDetails, updateBookings } from '@store/actions/timeline-actions'
import { ReceptionBookingDetails } from '@models/reception'
import { useWebSocket } from '@components/hooks/use-web-socket'
import { BaseObj } from '@models/base'

export const TimelineWebSocketHandler: React.FC = () => {
  const dispatch = useAppDispatch()

  useWebSocket<ReceptionBookingDetails | Apartment | BaseObj>(
    (message, payload) => {
      switch (message) {
        case NotificationEvents.BOOKING_CHANGE: {
          const bookingDetails = payload as ReceptionBookingDetails
          dispatch(updateBookings(bookingDetails))
          dispatch(updateBookingDetails(bookingDetails))
          break
        }
        case NotificationEvents.APARTMENT_CHANGE: {
          const apartmentDetails = payload as Apartment
          dispatch(updateApartments(apartmentDetails))
          break
        }
        case NotificationEvents.BOOKING_REMOVE: {
          dispatch(removeBooking(payload.id))
          break
        }
      }
    },
    [],
    [NotificationEvents.BOOKING_CHANGE, NotificationEvents.APARTMENT_CHANGE, NotificationEvents.BOOKING_REMOVE],
  )

  return null
}
