import * as React from 'react'
import classNames from 'classnames'

import { BookingStatus } from '@models/booking'

interface Props {
  status: BookingStatus
  isExtendedStay: boolean
}

const TimelineItemTooltipDetailsPlaceholder: React.FC<Props> = ({ status, isExtendedStay }) => (
  <div>
    <div className="calendar-tooltip__upper">
      <header className="calendar-tooltip__el is-placeholder" />
      <div
        className={classNames(
          `calendar-tooltip__status justify-content-between align-items-center d-flex is-${status}`,
          { 'is-extended': isExtendedStay },
        )}
      />
      <div className="calendar-tooltip__el is-placeholder" />
      <div className="calendar-tooltip__el is-placeholder" />
      <div className="calendar-tooltip__el is-placeholder" />
      <div className="calendar-tooltip__el is-placeholder" />
      <div className="calendar-tooltip__el is-placeholder" />
    </div>
    <div className="calendar-tooltip__lower">
      <div className="calendar-tooltip__el is-placeholder" />
      <div className="calendar-tooltip__el is-placeholder" />
      <div className="calendar-tooltip__el is-placeholder" />
      <div className="calendar-tooltip__el is-placeholder" />
      <div className="calendar-tooltip__el is-placeholder" />
      <div className="calendar-tooltip__el is-placeholder" />
    </div>
  </div>
)

export default TimelineItemTooltipDetailsPlaceholder
