import * as React from 'react'
import Table from '@components/table/table'
import { CmsResortDetails } from '@modules/cms/models'
import { useModal } from '@components/modals/use-modal'
import { DraggableTableRow } from '@components/table/draggable-table-row'

interface Props {
  resort: CmsResortDetails
  index: number
}

export const CmsReservationAppResortsRow: React.FC<Props> = ({ resort, index }) => {
  const [handleEdit] = useModal('CmsReservationAppResortModal', { resort })

  return (
    <DraggableTableRow draggableProps={{ index, draggableId: `${resort.id}` }}>
      <Table.Cell>{resort.name}</Table.Cell>
      <Table.Cell>{resort.localization_kind_display}</Table.Cell>
      <Table.Cell className="text-right">
        <button className="action-icon btn-pure" title="Edytuj" onClick={handleEdit}>
          <i className="uil-edit text-muted font-18" />
        </button>
      </Table.Cell>
    </DraggableTableRow>
  )
}
