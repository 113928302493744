import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { ReceptionBooking, ReceptionBookingDetails } from '@models/reception'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { ModalHeader } from 'reactstrap'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectGet } from '@store/actions/generic-actions'
import { useToggle } from '@components/hooks/use-toggle'
import { ReceptionBookingCheckOutStepKeys } from '@modules/reception/checkout/step-keys'
import { ReceptionBookingCheckOutPreviewDialogContent } from '@modules/reception/checkout/preview/reception-booking-check-out-preview-dialog-content'
import { useModal } from '@components/modals/use-modal'
import { ReceptionBookingCheckOutStepBands } from '@modules/reception/checkout/step-keys/reception-booking-check-out-step-bands'
import classname from 'classnames'

interface Props extends BaseModalProps {
  booking: ReceptionBooking
}

export const ReceptionBookingCheckOutPreviewDialog: React.FC<Props> = ({ booking, toggleIsVisible }) => {
  const [isKeyLost, setIsKeyLost] = React.useState(false)
  const [receptionBookingDetails, setReceptionBookingDetails] = React.useState<ReceptionBookingDetails | null>(null)
  const [isAccessKeyStepVisible, toggleIsAccessKeyStepVisible] = useToggle(true)

  const { action: fetchReceptionBookingDetails } = useApiRequest(async () => {
    setReceptionBookingDetails(await commonObjectGet(booking.urls.api_details))
  })

  const [showDefaultCheckOutDialog] = useModal(
    'ReceptionBookingCheckOutDialog',
    { booking: receptionBookingDetails },
    { persist: true },
  )

  const onShowDefaultCheckoutModalStep = (initialStep = 1) => {
    toggleIsVisible()
    showDefaultCheckOutDialog(null, { booking: receptionBookingDetails, initialStep })
  }

  const handleNextStep = () => {
    isKeyLost ? onShowDefaultCheckoutModalStep(2) : toggleIsAccessKeyStepVisible()
  }

  React.useEffect(() => {
    fetchReceptionBookingDetails()
  }, [])

  return (
    <LoaderPlaceholder content={receptionBookingDetails} className={classname({ 'p-5': !receptionBookingDetails })}>
      <ModalHeader toggle={toggleIsVisible} className="py-2">
        Potwierdź wymeldowanie {booking.name} ({booking.reservation_number})
      </ModalHeader>
      {receptionBookingDetails && (
        <div>
          {isAccessKeyStepVisible ? (
            <div className="reception__booking-check-out-preview__access-keys-return">
              {receptionBookingDetails.bands.length ? (
                <ReceptionBookingCheckOutStepBands
                  title=""
                  nextStep={handleNextStep}
                  booking={receptionBookingDetails}
                  onBandLostChange={setIsKeyLost}
                />
              ) : (
                <ReceptionBookingCheckOutStepKeys
                  title=""
                  nextStep={handleNextStep}
                  booking={receptionBookingDetails}
                  onKeyLostChange={setIsKeyLost}
                />
              )}
            </div>
          ) : (
            <ReceptionBookingCheckOutPreviewDialogContent
              onShowDefaultCheckoutModalStep={onShowDefaultCheckoutModalStep}
              bookingDetails={receptionBookingDetails}
              onPreviewDialogClose={toggleIsVisible}
            />
          )}
        </div>
      )}
    </LoaderPlaceholder>
  )
}
