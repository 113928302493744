import * as React from 'react'
import * as R from 'ramda'
import { RootState, useAppSelector } from '@store/index'
import { DownloadButtons } from '@components/downloads-buttons'
import { RcpEventDay, RcpUserDetail } from '@models/rcp'
import { secondsToInterval } from '@helpers/date-helper'

interface EventsSummaryProps {
  eventsDays: RcpEventDay[]
  user: RcpUserDetail
}

export const EventsSummary: React.FC<EventsSummaryProps> = ({ eventsDays, user }) => {
  const total = R.sum(
    eventsDays.map((eventDay: RcpEventDay) => (eventDay.work_time ? parseInt(String(eventDay.work_time), 10) : 0)),
  )

  const year = useAppSelector((state: RootState) => state.rcpState.userEventsDaysFilter.year)
  const month = useAppSelector((state: RootState) => state.rcpState.userEventsDaysFilter.month)

  const filter = `month=${month}&year=${year}`

  return (
    <>
      <tr>
        <td colSpan={8} className="pt-1" />
      </tr>
      <tr>
        <td className="text-strong text-primary h5 text-right" colSpan={3} style={{ borderTop: 0 }}>
          Podsumowanie godzin w miesiącu:
        </td>
        <td className="text-strong text-primary h5" colSpan={2} style={{ borderTop: 0 }}>
          {secondsToInterval(total)}
        </td>
        <td className="text-right pr-0" colSpan={3} style={{ borderTop: 0 }}>
          <DownloadButtons exportXls={user.urls.export_xls + filter} exportPdf={user.urls.export_pdf + filter} />
        </td>
      </tr>
    </>
  )
}
