import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { Card, CardBody, Row, Col } from 'reactstrap'
import { ReservationData } from '@modules/reservations/details/base-data/booking/reservation-data'
import { ReservationGuest } from '@modules/reservations/details/base-data/guest/reservation-guest'
import { ReservationBaseDataExpandable } from '@modules/reservations/details/base-data/reservation-base-data-expandable'

interface Props {
  booking: ReceptionBookingDetails
}

export const ReservationBaseData = React.forwardRef<HTMLElement, Props>(
  ({ booking }, ref: React.RefObject<HTMLDivElement>) => (
    <Card innerRef={ref} id="summary">
      <CardBody className="pb-2">
        <Row>
          <Col md={8} className="border-right pr-3 pb-3">
            <ReservationData booking={booking} />
          </Col>
          <Col md={4} className="pl-3">
            <ReservationGuest booking={booking} />
          </Col>
        </Row>
        <ReservationBaseDataExpandable booking={booking} />
      </CardBody>
    </Card>
  ),
)
