import * as React from 'react'
import { Button, ModalFooter, ModalHeader } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { BandReaderData, BandScanning } from '@components/band-scanning/band-scanning'
import { BaseModalProps } from '@components/modals/types'

export const ReceptionBandScanDialog: React.FC<BaseModalProps> = ({ toggleIsVisible }) => {
  const [bandReaderData, setBandReaderData] = React.useState<BandReaderData | null>(null)

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>Skanowanie opaski gościa</ModalHeader>
      <ModalBody>
        <BandScanning onBandReaderDataChange={setBandReaderData} bandReaderData={bandReaderData} />
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={toggleIsVisible}>
          Anuluj
        </Button>
        <a href={bandReaderData?.booking.urls.details}>
          <Button color="green" disabled={!bandReaderData}>
            Przejdź do rezerwacji
          </Button>
        </a>
      </ModalFooter>
    </>
  )
}
