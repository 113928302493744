import * as React from 'react'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useApiRequest } from '@components/hooks/use-api-request'
import { getPackageAppData } from '@store/actions/package-actions'
import { PackageAppData, setPackageAppData } from '@store/slices/package-slice'

export const usePackageAppData = (): PackageAppData => {
  const appData = useAppSelector((state: RootState) => state.packageState.appData)
  const dispatch = useAppDispatch()

  const { action: fetchPackageAppData } = useApiRequest(async () => await dispatch(getPackageAppData()))

  React.useEffect(() => {
    if (appData.status === 'unknown') {
      fetchPackageAppData()
      dispatch(setPackageAppData({ ...appData, status: 'loading' }))
    }
  }, [appData.status])

  return appData
}
