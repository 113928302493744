import * as React from 'react'
import { Col, Row } from 'reactstrap'

interface ReceptionBookingCheckOutStepKeysInfoRowProps {
  name: string
  value: number | string
}

export const ReceptionBookingCheckOutStepKeysInfoRow: React.FC<ReceptionBookingCheckOutStepKeysInfoRowProps> = ({
  name,
  value,
}) => (
  <Row className="mb-1 font-14">
    <Col md={4}>{name}</Col>
    <Col md={1}>
      <strong>{value}</strong>
    </Col>
  </Row>
)
