import * as React from 'react'
import { TechnicalOrder } from '@models/technical-orders'
import { IconWithTooltip } from '@components/icon-with-tooltip'
import { toDefaultDateFormat, toDefaultTimeFormat } from '@helpers/date-helper'

interface Props {
  technicalOrder: TechnicalOrder
}

export const TechnicalOrdersTodoRowTechnicalOrderEnterWhereGuestAbsenceRange: React.FC<Props> = ({
  technicalOrder,
}) => (
  <IconWithTooltip
    color="text-danger"
    tooltipId={`technical-order-enter-where-guest-absence-range-${technicalOrder.id}`}
    icon="uil-clock-eight font-16"
    tooltipMessage={
      <div className="font-11">
        <span className="text-body">W godzinach: </span>
        {technicalOrder.technical_order_enter_where_guest_absence_range.map(row => (
          <span className="text-white" key={row.id}>
            {toDefaultTimeFormat(row.date + 'T' + row.time_from)} - {toDefaultTimeFormat(row.date + 'T' + row.time_to)}{' '}
            ({toDefaultDateFormat(row.date)})
            <br />
          </span>
        ))}
      </div>
    }
  />
)
