import * as React from 'react'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectPost } from '@store/actions/generic-actions'
import { NewFeedingCalculations } from '@modules/reception/checkin/step-feeding/new-feeding/models'
import {
  createDefaultNewFeedingFormValues,
  getFeedingChanges,
} from '@modules/reception/checkin/step-feeding/new-feeding/utils'
import { UseFormReturn, useWatch } from 'react-hook-form'
import { useDidUpdate } from 'rooks'
import { ReceptionBookingDetails } from '@models/reception'
import { useAppDispatch } from '@store/index'
import {
  clearExposedEdgeFeedings,
  selectFeedingCalculations,
  selectInitialFeedingCalculations,
  setFeedingCalculations,
  setInitialFeedingCalculations,
} from '@store/slices/new-feeding-slice'
import { useSelector } from 'react-redux'
import { useFeedingWebsocket } from '@modules/reception/checkin/step-feeding/new-feeding/use-feeding-websocket'
import { useNewFeedingCalculationsFetch } from '@modules/reception/checkin/step-feeding/new-feeding/use-new-feeding-calculations-fetch'

type FeedingCalculationState = NewFeedingCalculations | null

interface Response {
  feedingCalculations: FeedingCalculationState
  initialFeedingCalculations: FeedingCalculationState
  fetchFeedingCalculations: () => Promise<void>
}

export const useNewFeedingCalculations = (booking: ReceptionBookingDetails, methods: UseFormReturn): Response => {
  const dispatch = useAppDispatch()

  const feedingCalculations = useSelector(selectFeedingCalculations)
  const initialFeedingCalculations = useSelector(selectInitialFeedingCalculations)

  const isFirstRecalculation = React.useRef(true)

  const feedings = useWatch({ control: methods.control })

  const { fetchCalculations } = useNewFeedingCalculationsFetch()

  const fetchFeedingCalculations = async () => {
    await fetchCalculations(booking, response => methods.reset(createDefaultNewFeedingFormValues(response.details)))
  }

  useFeedingWebsocket(booking.id, fetchFeedingCalculations)

  const { action: onRecalculate } = useApiRequest(async () => {
    if (!initialFeedingCalculations?.details) return

    const changes = getFeedingChanges(feedings, initialFeedingCalculations.details)

    const newFeeding = await commonObjectPost<NewFeedingCalculations>(
      booking.urls.reservation_feeding_calculate,
      changes,
    )

    const newFeedingFormValues = createDefaultNewFeedingFormValues(newFeeding.details)

    const formStateDifference = Object.entries(newFeedingFormValues).reduce((difference, [feedingFormKey, checked]) => {
      if (feedings[feedingFormKey] !== checked) return { ...difference, [feedingFormKey]: checked }
      return difference
    }, {})

    Object.entries(formStateDifference).forEach(([key, value]) => methods.setValue(key, value))
    dispatch(setFeedingCalculations(newFeeding))
  })

  React.useEffect(() => {
    fetchFeedingCalculations()

    return () => {
      dispatch(clearExposedEdgeFeedings())
      dispatch(setInitialFeedingCalculations(null))
      dispatch(setFeedingCalculations(null))
    }
  }, [])

  useDidUpdate(() => {
    if (isFirstRecalculation.current) {
      isFirstRecalculation.current = false
      return
    }
    onRecalculate()
  }, [feedings])

  return {
    feedingCalculations,
    initialFeedingCalculations,
    fetchFeedingCalculations,
  }
}
