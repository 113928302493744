import { RootState, useAppSelector } from '@store/index'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import * as React from 'react'

export const useTabletsList = () => {
  const resortId = useAppSelector((state: RootState) => state.receptionState.resortDetails?.id)
  const tablets = useAppSelector((state: RootState) => state.appState.appData.tablets)

  const user = useAuthenticatedUser()

  return React.useMemo(() => {
    let newTablets = tablets

    if (resortId) {
      newTablets = newTablets.filter(row => row.resort_id === resortId)
    }

    return newTablets.filter(row => user.resort_ids.includes(row.resort_id))
  }, [user, tablets, resortId])
}
