import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { BlockadeResultSuccess } from '@modules/reservations/blockade/models'

interface Props {
  result: BlockadeResultSuccess[]
}

export const ReservationBlockadeSuccessfullyBlockedList = ({ result }: Props): JSX.Element => (
  <ul className="list-unstyled mb-0">
    {result.map(row => (
      <li key={row.apartment.id} className="font-weight-semibold font-16 mb-1 d-flex">
        <IconWithText
          icon="uil-check text-white font-18 lh-initial"
          text={
            <>
              Lokal {row.apartment.name} -{' '}
              <a href={row.booking.urls.details} className="font-14 font-weight-normal text-white">
                (Rezerwacja {row.booking.id})
              </a>
            </>
          }
        />
      </li>
    ))}
  </ul>
)
