import * as React from 'react'
import { formatPrice } from '@helpers/utils'
import { BookingStatsTotals } from '@modules/reservations/models'
import { PercentageChangeIndicator } from '@components/percentage-change-indicator'
import { ReservationStatsBoxRow } from '@modules/reservations/stats-board/reservation-stats-box-row'

interface ReservationStatsBoxPackageProps {
  stats: BookingStatsTotals
}

export const ReservationStatsBoxPackage: React.FC<ReservationStatsBoxPackageProps> = ({ stats }) => (
  <table className="table table-sm mb-0">
    <tbody>
      <ReservationStatsBoxRow
        className="border-top-0"
        name="Liczba pakietów:"
        value={
          <PercentageChangeIndicator
            baseValue={stats.package_today_count}
            valueToCompare={stats.package_yesterday_count}
          >
            {stats.package_count}
          </PercentageChangeIndicator>
        }
      />
      <ReservationStatsBoxRow name="Wartość pakietów:" value={formatPrice(stats.package_total)} />
      <ReservationStatsBoxRow name="Wpłacono:" value={formatPrice(stats.package_payin)} />
      <ReservationStatsBoxRow name="Pakiety odbyte:" value={formatPrice(stats.package_done)} />
      <ReservationStatsBoxRow name="Pakiety założone:" value={formatPrice(stats.package_created)} />
      <ReservationStatsBoxRow name="Pakiety do wykorzystania:" value={formatPrice(stats.package_not_used)} />
      <ReservationStatsBoxRow
        name="Razem:"
        value={formatPrice(stats.package_not_used + stats.package_created + stats.package_done)}
      />
    </tbody>
  </table>
)
