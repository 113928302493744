import * as React from 'react'
import {
  BookingStatsDetails,
  ReservationStatsTableCells,
  StatsCellContent,
  StatsTableOptions,
} from '@modules/reservations/models'
import { ReservationStatsTableExpandedRow } from '@modules/reservations/statistics/booking-stats/stats-details/stats-details-table/reservation-stats-table-expanded-row'
import { IconWithTooltip } from '@components/icon-with-tooltip'

interface Props {
  onGenerateReport: (content: StatsCellContent<BookingStatsDetails>, url: string | undefined) => void
  url: string
  getCells: (
    element: keyof BookingStatsDetails,
    options?: StatsTableOptions<BookingStatsDetails>,
  ) => ReservationStatsTableCells
}

export const ReservationStatsCashToReturnRow = ({ onGenerateReport, url, getCells }: Props): React.ReactNode => {
  const reportDataCells = getCells('cash_to_return', {
    tableCellOption: {
      wrapper: content =>
        content.statsDetails[content.element] === 1 ? (
          <i
            className="uil-download-alt font-14 text-secondary cursor-pointer"
            title="Pobierz raport dzienny"
            onClick={() => onGenerateReport(content, url)}
          />
        ) : (
          <EmptyValue />
        ),
    },
    tableRowSummaryOption: { wrapper: () => <EmptyValue /> },
  })

  return <ReservationStatsTableExpandedRow title={<CashToReturnTitle />} cells={reportDataCells} />
}

const EmptyValue = () => <span>-</span>

const CashToReturnTitle = React.memo(() => (
  <IconWithTooltip
    color="text-secondary"
    icon="uil-exclamation-circle ml-1"
    tooltipId="cash_to_return"
    className="flex-row-reverse"
    tooltipPlacement="right"
    tooltipMessage={
      <span className="font-11 text-left">
        <strong>Szacunkowa wartość </strong>gotówki do zwrotów dla Gości. Dotyczy rezerwacji trwających.
      </span>
    }
  >
    <span>Suma gotówki do wydania</span>
  </IconWithTooltip>
))
