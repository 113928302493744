import * as React from 'react'
import { FormSelect } from '@hyper/forms/form-select'
import { createSelectOption } from '@helpers/utils'
import { useGroupedSellers } from '@components/hooks/use-grouped-sellers'
import { useGroupedSources } from '@components/hooks/use-grouped-sources'
import { useProductsAppData } from '@modules/products/hooks/use-products-app-data'
import { CustomReactSelectOption } from '@components/custom-react-select'

export const EndlessHolidayCreateFormBaseFields = (): React.ReactNode => {
  const { product_package_sets } = useProductsAppData()

  const availableSets = product_package_sets
    .map(({ id, name, status }) => createSelectOption(name, id, status === 'unavailable'))
    .toSorted((a, b) => (a.disabled ? 1 : b.disabled ? -1 : 0))

  const sellers = useGroupedSellers([])
  const sources = useGroupedSources({ withAllOption: false })

  return (
    <div className="mx-n1">
      <div className="dropdown-divider mb-0 mt-3" />
      <div className="bg-grey py-3 px-3">
        <div className="row align-items-center">
          <strong className="col-3">Wybierz zestaw</strong>
          <div className="col-9">
            <FormSelect
              options={availableSets}
              selectProps={{ isOptionDisabled: (option: CustomReactSelectOption) => !!option.disabled }}
              name="product_package_set"
              formPlainProps={{ colSize: 9, colClassName: 'px-0', formGroupClassName: 'mb-0' }}
            />
          </div>
        </div>

        <div className="row align-items-center mt-2">
          <strong className="col-3">Sprzedawca</strong>
          <div className="col-9">
            <FormSelect
              selectProps={{ isClearable: true }}
              options={sellers}
              name="seller"
              formPlainProps={{ colSize: 9, colClassName: 'px-0', formGroupClassName: 'mb-0' }}
            />
          </div>
        </div>

        <div className="row align-items-center mt-2">
          <strong className="col-3">Źródło marketingowe</strong>
          <div className="col-9">
            <FormSelect
              selectProps={{ isClearable: true }}
              options={sources}
              name="source_marketing"
              formPlainProps={{ colSize: 9, colClassName: 'px-0', formGroupClassName: 'mb-0' }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
