import * as React from 'react'
import { CustomFileInput } from '@components/custom-file-input'
import { UploaderAcceptableFile } from '@components/file-uploader'
import { Row } from 'reactstrap'
import { usePackageWholesaleProductData } from '@modules/package-wholesale/use-product-data'
import { packageWholesaleProductsSelector } from '@store/slices/package-wholesale-slice'
import { useFormContext, useWatch } from 'react-hook-form'
import { PackageWholesaleFormInputs } from '@modules/package-wholesale/create/modal'
import { useAppSelector } from '@store/index'

export const PackageWholesaleCreateModalFormLeaflet = (): JSX.Element => {
  const packageWholesaleProducts = useAppSelector(packageWholesaleProductsSelector)

  const { control } = useFormContext<PackageWholesaleFormInputs>()

  const [
    leaflet_subscription,
    leaflet_gastro_card,
    leaflet_gastro_card_boost,
    leaflet_gastro_card_2024,
    leaflet_gastro_card_2025,
    products,
  ] = useWatch({
    control,
    name: [
      'leaflet_subscription',
      'leaflet_gastro_card',
      'leaflet_gastro_card_boost',
      'leaflet_gastro_card_2024',
      'leaflet_gastro_card_2025',
      'products',
    ],
  })

  const { kinds, subscriptionKinds, getSubscriptionLeafletData } = usePackageWholesaleProductData(
    products,
    packageWholesaleProducts,
  )

  return (
    <Row className="mt-2">
      {kinds.includes('subscription') && (
        <>
          {subscriptionKinds.map(kind => {
            const { label, inputName } = getSubscriptionLeafletData(kind)
            return (
              <CustomFileInput
                key={kind.subscription_kind}
                label={label}
                inputName={inputName}
                acceptFiles={UploaderAcceptableFile.PDF}
                canDelete={!!leaflet_subscription}
              />
            )
          })}
        </>
      )}
      {kinds.includes('gastro_card') && (
        <CustomFileInput
          label="Ulotka podwójnej radości"
          inputName="leaflet_gastro_card"
          acceptFiles={UploaderAcceptableFile.PDF}
          canDelete={!!leaflet_gastro_card}
        />
      )}
      {kinds.includes('gastro_card_boost') && (
        <CustomFileInput
          label="Ulotka Doładowania Smaku"
          inputName="leaflet_gastro_card_boost"
          acceptFiles={UploaderAcceptableFile.PDF}
          canDelete={!!leaflet_gastro_card_boost}
        />
      )}
      {kinds.includes('gastro_card_2024') && (
        <CustomFileInput
          label="Ulotka podwójnej radości 2024"
          inputName="leaflet_gastro_card_2024"
          acceptFiles={UploaderAcceptableFile.PDF}
          canDelete={!!leaflet_gastro_card_2024}
        />
      )}
      {kinds.includes('gastro_card_2025') && (
        <CustomFileInput
          label="Ulotka podwójnej radości 2025"
          inputName="leaflet_gastro_card_2025"
          acceptFiles={UploaderAcceptableFile.PDF}
          canDelete={!!leaflet_gastro_card_2025}
        />
      )}
    </Row>
  )
}
