import * as React from 'react'
import { CmsResortDetails, ResortAttraction } from '@modules/cms/models'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { useModal } from '@components/modals/use-modal'
import { DragReorder } from '@components/drag-reorder'
import { useAppDispatch } from '@store/index'
import { setCmsResort } from '@store/slices/cms-reservation-app-slice'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'

interface Props {
  attraction: ResortAttraction
  resort: CmsResortDetails
  index: number
  onDrop: () => void
  moveItem: (sourceId: number, destinationId: number) => void
}

export const CmsReservationAppResortAttractionsEl: React.FC<Props> = ({
  attraction,
  resort,
  index,
  moveItem,
  onDrop,
}) => {
  const [handleEdit] = useModal('CmsReservationAppAttractionModal', { attraction, resort })
  const dispatch = useAppDispatch()

  const { addSuccessMessage } = useNotificationHook()

  const { isLoading, action: handleAccept } = useApiRequest(async () => {
    dispatch(setCmsResort(await commonObjectDelete<CmsResortDetails>(attraction.urls.details)))
    addSuccessMessage('Sukces', 'Wpis został skasowany')
  })

  return (
    <div className="card mb-1 mr-1 mb-0 shadow-none border">
      <DragReorder className="d-flex" index={index} moveItem={moveItem} id={attraction.id} onDrop={onDrop}>
        {attraction.image && (
          <div className="p-1">
            <img src={attraction.image} alt="" className="avatar-md img-thumbnail" />
          </div>
        )}
        <div className="p-1 mt-1">
          <div className="text-strong mb-1">
            {attraction.title}

            <i className="uil-pen ml-1 font-14 cursor-pointer" onClick={handleEdit} />
            <CommonObjectConfirmAction isLoading={isLoading} handleAccept={handleAccept}>
              <i className="uil-multiply ml-1 font-14 cursor-pointer" />
            </CommonObjectConfirmAction>
          </div>
          <div className="text-semi-strong mb-1">{attraction.subtitle}</div>
        </div>
      </DragReorder>
    </div>
  )
}
