import * as React from 'react'
import { Card, CardBody } from 'reactstrap'
import { PackageListFilter, PackageListFilterParams } from '@modules/package/list/filter'
import { useAppDispatch } from '@store/index'
import { getPackages } from '@store/actions/package-actions'
import { PackageListTable } from '@modules/package/list/table'
import { usePackageAppData } from '@modules/package/hooks/use-package-app-data'
import { useParams } from 'react-router-dom'
import { PackageDetailsView } from '@modules/package/list/details-view'
import { packageStatusList, PackageType } from '@models/package'
import { PackageWebSocketHandler } from '../web-socket-handler'
import { HPIPoolInfo } from './hpi-pool-info'
import { PackagesSummary } from './summary'
import { PackageListExportButton } from '@modules/package/list/export'
import { NavigationPath } from '@models/routes'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { useTableFilters } from '@components/table/use-table-filters'
import { usePackageList } from '@modules/promocode/hooks/use-package-list'
import { PageTitleWithSize } from '@hyper/page-title-with-size'

const defaultFilters: PackageListFilterParams = {
  created_after: undefined,
  created_before: undefined,
  kind: [],
  seller: undefined,
  status: packageStatusList.filter(row => ['initial', 'confirmed'].includes(row.value)),
  search: '',
  has_zoo_borysew_tickets: false,
  has_suntago_tickets: false,
  ordering: '-created',
  page: 1,
  page_size: 10,
}

interface Params {
  id?: string
  type: PackageType
}

export const PackageListView: React.FC = () => {
  const { id: package_id, type } = useParams() as unknown as Params

  const dispatch = useAppDispatch()
  const { filters, setFilters, updateFiltersRequest, isLoading } = useTableFilters<PackageListFilterParams>({
    defaultFilters,
    action: async filters => await dispatch(getPackages([filters, type])),
    deps: [type],
  })

  const packages = usePackageList(type)

  const filteredPackages = React.useMemo(
    () =>
      packages.filter(row =>
        filters.status?.length ? filters.status?.some(status => status.value === row.status) : true,
      ),
    [filters.status, packages],
  )

  useMenuActive(NavigationPath.PackageList.replace(':type', type || ''))

  usePackageAppData()

  React.useEffect(() => {
    updateFiltersRequest(filters)
  }, [type])

  const name =
    {
      HPI: 'HPI',
    }[(type || '').toUpperCase()] || ''

  const pageTitle = `Lista pakietów firmowych ${name}`

  return (
    <>
      <PageTitleWithSize title={pageTitle} />
      {[PackageType.HPI].includes(type) && <HPIPoolInfo />}
      <PackagesSummary />
      <Card>
        <PackageWebSocketHandler />
        <CardBody>
          {package_id && <PackageDetailsView package_id={package_id} />}
          <PackageListFilter defaultFilters={defaultFilters} filters={filters} setFilters={setFilters} type={type} />
          <PackageListTable
            isLoading={isLoading}
            packages={filteredPackages}
            filters={filters}
            setFilters={setFilters}
          />
          <PackageListExportButton filters={filters} type={type} />
        </CardBody>
      </Card>
    </>
  )
}
