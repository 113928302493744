import * as React from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'
import { Dispatch, SetStateAction } from 'react'
import classNames from 'classnames'

type ItemId = number | string

interface Props<T> {
  items: T[]
  activeItem: T | undefined
  renderItem: (item: T) => React.ReactNode
  onClick: Dispatch<SetStateAction<ItemId>>
  className?: string
}

export const NavigationItems = <T extends { id: ItemId }>({
  items,
  activeItem,
  renderItem,
  onClick,
  className,
}: Props<T>): JSX.Element => (
  <Nav tabs={true} className={classNames('nav-bordered font-14', className)}>
    {items.map(item => (
      <NavItem key={item.id}>
        <NavLink
          className="cursor-pointer"
          active={activeItem && activeItem.id.toString() === item.id}
          onClick={() => onClick(item.id)}
        >
          {renderItem(item)}
        </NavLink>
      </NavItem>
    ))}
  </Nav>
)
