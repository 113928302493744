import * as React from 'react'
import { ReservationCard } from '@modules/reservations/details/common/reservation-card'
import { BookingSource, ReceptionBookingDetails } from '@models/reception'
import { asDecimal } from '@helpers/utils'
import { calculateRestToPayCheckin } from '@modules/reception/checkout/step-settlement/calculate-rest-to-pay'
import { ReceptionBookingCheckBillDetails } from '@modules/reception/common/reception-booking-check-bill-details'
import { ReceptionBookingCheckInDepositDetails } from '@modules/reception/checkin/step-payments/reception-booking-check-in-deposit-details'

export const isReservedByBooking = <T extends { source: BookingSource | null }>(booking: T) =>
  booking.source === 'booking' || booking.source === 'booking_non_returnable'

interface Props {
  bookingDetails: ReceptionBookingDetails
}

export const ReceptionBookingCheckInPreviewBill = ({ bookingDetails }: Props): JSX.Element => {
  const restToPay = isReservedByBooking(bookingDetails)
    ? asDecimal(bookingDetails.required_climatic_tax)
    : calculateRestToPayCheckin(bookingDetails)

  return (
    <ReservationCard title="Rachunek" cardClassName="mb-2">
      <ReceptionBookingCheckBillDetails
        title="1. Rachunek za pobyt:"
        booking={bookingDetails}
        isOpen={false}
        restToPay={restToPay}
      />
      <div className="mt-3">
        <ReceptionBookingCheckInDepositDetails booking={bookingDetails} />
      </div>
    </ReservationCard>
  )
}
