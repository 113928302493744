import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import classNames from 'classnames'

interface Props {
  children: React.ReactNode
  onReturn: () => void
  className: string
}

export const ReceptionBookingCartSelectedPaymentBox = ({ children, onReturn, className }: Props): JSX.Element => (
  <div className="bg-white-smoke">
    <div className={classNames('border-left border-right py-3', className)}>{children}</div>
    <div className="px-3 border py-2">
      <IconWithText
        icon="uil-angle-left font-24"
        text="Powrót do listy płatności"
        wrapperClassNames="text-secondary font-weight-bold "
        textClass="font-14"
        onClick={onReturn}
      />
    </div>
  </div>
)
