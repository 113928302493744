import * as React from 'react'
import classNames from 'classnames'
import { StickyNavbar } from '@components/sticky-navbar'
import { useScrollableTabs } from '@components/use-scrollable-tabs'

export type ReservationDetailsNavigationTab = 'summary' | 'bill' | 'deposit' | 'wallets' | 'guests'

interface Props {
  summaryRef: React.RefObject<HTMLElement>
  billRef: React.RefObject<HTMLElement>
  depositRef: React.RefObject<HTMLElement>
  walletsRef: React.RefObject<HTMLElement>
  guestsRef: React.RefObject<HTMLElement>
}

export const ReservationDetailsNavigationTabs = ({
  summaryRef,
  guestsRef,
  walletsRef,
  depositRef,
  billRef,
}: Props): React.ReactNode => {
  const [activeTab, setActiveTab] = React.useState('summary')
  useScrollableTabs([summaryRef, billRef, depositRef, walletsRef, guestsRef], setActiveTab)

  const handleTabClick = (tab: ReservationDetailsNavigationTab) => {
    setActiveTab(tab)

    const ref = {
      summary: summaryRef,
      bill: billRef,
      deposit: depositRef,
      wallets: walletsRef,
      guests: guestsRef,
    }[tab]

    if (ref?.current) {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <StickyNavbar>
      <div className="d-flex">
        {Tabs.map(tab => (
          <button
            key={tab.id}
            data-id={tab.id}
            className={classNames(
              'btn shadow-none rounded-0 mr-1',
              activeTab === tab.id ? 'btn-secondary' : 'btn-light',
            )}
            onClick={() => handleTabClick(tab.id)}
          >
            {tab.label}
          </button>
        ))}
      </div>
    </StickyNavbar>
  )
}

const Tabs: { id: ReservationDetailsNavigationTab; label: string }[] = [
  { id: 'summary', label: 'Podsumowanie rezerwacji' },
  { id: 'bill', label: 'Rachunek' },
  { id: 'deposit', label: 'Depozyt' },
  { id: 'wallets', label: 'Vouchery' },
  { id: 'guests', label: 'Goście' },
]
