import * as React from 'react'
import { formatPrice } from '@helpers/utils'

interface Props {
  title: React.ReactNode
  unit: string
  startValue: string
  endValue: string
  currentUse: string
  endUse: string
  price: string
}

export const ReservationBillExpandedMediaRow = ({
  title,
  endUse,
  endValue,
  currentUse,
  startValue,
  unit,
  price,
}: Props): JSX.Element => (
  <tr>
    <td>{title}</td>
    <td>
      <span className="font-11">Początek</span>
      <span className="fw-semi-bold d-block">
        {startValue} {unit}
      </span>
    </td>
    <td>
      <span className="font-11">Koniec</span>
      <span className="fw-semi-bold d-block">
        {endValue} {unit}
      </span>
    </td>
    <td>
      <span className="font-11">Zużycie bieżące</span>
      <span className="fw-semi-bold d-block">
        {currentUse} {unit}
      </span>
    </td>
    <td>
      <span className="font-11">Zużycie końcowe</span>
      <span className="fw-semi-bold d-block">
        {endUse} {unit}
      </span>
    </td>
    <td className="align-middle fw-semi-bold text-right">+ {formatPrice(price)}</td>
    <td width="50" />
  </tr>
)
