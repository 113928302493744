import * as React from 'react'
import { ContentNote } from '@models/dashboard'
import { ReceptionBookingCheckInSingleNote } from '@modules/reception/common/reception-note-popup/reception-booking-check-in-single-note'
import { ReceptionBookingCheckInAddNote } from '@modules/reception/common/reception-note-popup/reception-booking-check-in-add-note'
import { ReceptionBookingDetails } from '@models/reception'

interface ReceptionBookingCheckInNotesProps {
  toggleNotesVisibility: () => void
  booking: ReceptionBookingDetails
}

export const ReceptionBookingCheckInNotes: React.FC<ReceptionBookingCheckInNotesProps> = ({
  toggleNotesVisibility,
  booking,
}) => {
  const [editMode, setEditMode] = React.useState(false)
  const [allNotesVisible, setAllNotesVisible] = React.useState(false)

  const toggleEditMode = () => setEditMode(!editMode)
  const toggleAllNotes = () => setAllNotesVisible(!allNotesVisible)

  const allNotes = booking.notes?.map((note: ContentNote) => (
    <ReceptionBookingCheckInSingleNote note={note} key={note.id} />
  ))

  const moreNotesButton = () => {
    if (booking.notes.length <= 1) return null
    const btnText = allNotesVisible ? 'Najnowsza notatka' : `Wszystkie notatki (${booking.notes.length})`
    return (
      <>
        <div className="text-center my-1 cursor-pointer" onClick={toggleAllNotes} role="button">
          <span className="font-10">{btnText}</span>
        </div>
        <div className="hr hr-white" />
      </>
    )
  }

  return (
    <div className="reception__booking-check-in__notes is-top">
      <div className="pt-2 px-3">
        <div className="d-flex justify-content-between align-items-center text-semi-strong font-10">
          {editMode ? <span>Nowa notatka:</span> : <span>Notatki do rezerwacji:</span>}
          <i className="uil-times font-15 cursor-pointer" onClick={toggleNotesVisibility} />
        </div>
      </div>
      {editMode ? (
        <ReceptionBookingCheckInAddNote
          closeEditMode={toggleEditMode}
          bookingId={booking.id}
          bookingDetailsUrl={booking.urls.api_details}
        />
      ) : (
        <>
          {!!booking.notes.length &&
            (allNotesVisible ? allNotes : <ReceptionBookingCheckInSingleNote note={booking.notes[0]} />)}
          {moreNotesButton()}
          <div className="text-center cursor-pointer bg-dark-grey py-2 font-10" onClick={toggleEditMode} role="button">
            <i className="uil-plus mr-1" /> Dodaj notatkę
          </div>
        </>
      )}
    </div>
  )
}
