import * as React from 'react'
import { ModalBody, ModalHeader } from 'reactstrap'
import { NavigationTabs, NavigationTabsButton } from '@components/navigation-tabs'
import { CmsResortDetails } from '@modules/cms/models'
import { CmsReservationAppImages } from '@modules/cms/reservation-app/common/images'
import { CmsReservationAppResortFaq } from '@modules/cms/reservation-app/resort-modal/faq'
import { CmsReservationAppResortAttractions } from '@modules/cms/reservation-app/resort-modal/attractions'
import { CmsReservationAppResortForm } from '@modules/cms/reservation-app/resort-modal/form'
import { BaseModalProps } from '@components/modals/types'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { cmsResortSelectByIdSelector, setCmsResort } from '@store/slices/cms-reservation-app-slice'
import { CmsReservationAppResortMaps } from '@modules/cms/reservation-app/resort-modal/maps'

interface Props extends BaseModalProps {
  resort: CmsResortDetails
}

type tabType = 'data' | 'images' | 'attributes' | 'faq' | 'attractions' | 'maps'

export const CmsReservationAppResortModal: React.FC<Props> = ({ toggleIsVisible, resort }) => {
  const [tab, setTab] = React.useState<tabType>('data')
  const resortDetails = useAppSelector((state: RootState) => cmsResortSelectByIdSelector(state, resort.id))
  resort = { ...resort, ...resortDetails }

  const dispatch = useAppDispatch()
  const setResortDetails = (value: CmsResortDetails) => dispatch(setCmsResort(value))

  const buttons: NavigationTabsButton[] = [
    {
      title: 'Informacje',
      isActive: tab === 'data',
      onClick: () => setTab('data'),
    },
    {
      title: 'Zdjęcia',
      isActive: tab === 'images',
      onClick: () => setTab('images'),
    },
    {
      title: 'Mapki',
      isActive: tab === 'maps',
      onClick: () => setTab('maps'),
    },
    {
      title: 'FAQ',
      isActive: tab === 'faq',
      onClick: () => setTab('faq'),
    },
    {
      title: 'Atrakcje',
      isActive: tab === 'attractions',
      onClick: () => setTab('attractions'),
    },
  ]
  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>
        Edycja ośrodka <span className="text-semi-strong">{resort.name}</span>
      </ModalHeader>
      <ModalBody className="pb-0">
        <NavigationTabs buttons={buttons} />
      </ModalBody>
      {tab === 'data' && <CmsReservationAppResortForm toggleIsVisible={toggleIsVisible} resort={resort} />}
      {tab === 'maps' && <CmsReservationAppResortMaps toggleIsVisible={toggleIsVisible} resort={resort} />}
      {tab === 'faq' && <CmsReservationAppResortFaq toggleIsVisible={toggleIsVisible} resort={resort} />}
      {tab === 'attractions' && (
        <CmsReservationAppResortAttractions toggleIsVisible={toggleIsVisible} resort={resort} />
      )}
      {tab === 'images' && (
        <CmsReservationAppImages
          positionUrl={resort.urls.resort_images_position}
          images={resort.reservation_images}
          toggleIsVisible={toggleIsVisible}
          id={resort.id}
          url={resort.urls.reservation_images}
          setState={setResortDetails}
        />
      )}
    </>
  )
}
