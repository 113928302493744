import * as React from 'react'
import { Button, Col, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { SaveButton } from '@hyper/button'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useForm } from 'react-hook-form'
import { createReportTask } from '@store/actions/report-tasks-actions'
import { endOfMonth, startOfMonth, startOfToday } from 'date-fns'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { BaseModalProps } from '@components/modals/types'
import { Form } from '@hyper/forms/form'
import { FormCheckbox } from '@hyper/forms/form-checkbox'
import { FormSelect } from '@hyper/forms/form-select'
import { createSelectOption, extractSelectOptionsValues } from '@helpers/utils'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useMultipleResorts } from '@components/hooks/use-multiple-resorts'

interface FormInputs {
  created_after: Date | null
  created_before: Date | null
  stay_after: Date | null
  stay_before: Date | null
  sell_date_after: Date | null
  sell_date_before: Date | null
  date_after: Date | null
  date_before: Date | null
  resorts: CustomReactSelectOption[]
  payment_method: CustomReactSelectOption | null
  department: CustomReactSelectOption | null
  source: CustomReactSelectOption | null
  with_ongoing_bookings: boolean
}

export const ReportsGeneratorFeedingSoldMeals: React.FC<BaseModalProps> = ({ toggleIsVisible }) => {
  const reportUrl = useAppSelector((state: RootState) => state.appState.appData.urls.reports.report_task_feeding_sale)
  const dispatch = useAppDispatch()

  const methods = useForm<FormInputs>({
    mode: 'all',
    defaultValues: {
      resorts: [],
      created_after: null,
      created_before: null,
      stay_after: null,
      stay_before: null,
      sell_date_after: null,
      sell_date_before: null,
      date_after: startOfMonth(startOfToday()),
      date_before: endOfMonth(startOfToday()),
      payment_method: null,
      department: null,
      source: null,
      with_ongoing_bookings: false,
    },
  })

  const { getResortOptions, getSelectedResortsIds } = useMultipleResorts({ methods, field: 'resorts' })
  const resortOptions = React.useMemo(() => getResortOptions({ withAllOption: true }), [])

  const { isLoading, action: onSubmit } = useReportFormRequest(async (payload: FormInputs) => {
    const reportTask = await dispatch(
      createReportTask([
        reportUrl,
        { ...extractSelectOptionsValues(payload), resorts: getSelectedResortsIds(payload.resorts) },
      ]),
    )
    toggleIsVisible()
    return reportTask
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>
        <span className="text-semi-string">Raport wyżywienia</span> <strong>sprzedane posiłki</strong>
      </ModalHeader>
      <ModalBody>
        <Row>
          <FormSelect options={resortOptions} name="resorts" label="Resort:" selectProps={{ isSelectMulti: true }} />
          <DateFromToInput
            label="Data utworzenia rezerwacji"
            startDateName="created_after"
            endDateName="created_before"
          />
          <DateFromToInput label="Termin pobytu" startDateName="stay_after" endDateName="stay_before" />

          <Col>
            <FormCheckbox name="with_ongoing_bookings" label="Uwzględnij również rezerwacje rozpoczęte" />
          </Col>

          <hr className="w-100" />

          <DateFromToInput
            label="Data sprzedaży posiłków"
            startDateName="sell_date_after"
            endDateName="sell_date_before"
          />
          <DateFromToInput
            label="Data posiłku"
            startDateName="date_after"
            endDateName="date_before"
            isClearable={false}
          />

          <hr className="w-100 mt-1" />

          <FormSelect options={PaymentMethodOptions} name="payment_method" label="Sposób płatności:" />
          <FormSelect options={SellerDepartmentOptions} name="department" label="Dział sprzedaży:" />
          <FormSelect options={SourceOptions} name="source" label="Źródło:" />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" label="Zleć raport" labelSaving="Zlecanie.." isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}

const PaymentMethodOptions = [
  createSelectOption('Voucher Gastronomiczny', 'voucher'),
  createSelectOption('Saldo rezerwacji', 'booking'),
]

const SourceOptions = [
  createSelectOption('Rezerwacja', 'booking'),
  createSelectOption('Gość zewnętrzny', 'external_guest'),
  createSelectOption('Pracownik', 'employee'),
]

const SellerDepartmentOptions = [
  createSelectOption('Wszyscy', null),
  createSelectOption('BOK', 'sellers_bok'),
  createSelectOption('BOS', 'sellers_bos'),
  createSelectOption('B2B', 'sellers_b2b'),
  createSelectOption('Recepcja', 'sellers_reception'),
  createSelectOption('Dyrektor', 'sellers_director'),
  createSelectOption('Opiekunowie w resorcie', 'sellers_resort_seller'),
  createSelectOption('Gastronomia', 'sellers_gastronomy'),
]
