import * as React from 'react'
import { NavigationTabsLink } from '@components/navigation-tabs/link'
import classNames from 'classnames'

export interface NavigationTabsButton {
  title: React.ReactNode
  link?: string
  onClick?: () => void
  isActive: boolean
  className?: string
  isSuperuser?: boolean
}

interface NavigationTabsProps {
  buttons: NavigationTabsButton[]
  className?: string
}

export const NavigationTabs: React.FC<NavigationTabsProps> = ({ buttons, className }) => {
  const total = buttons.length
  return (
    <div className={classNames('navigation-tabs mb-3', className)}>
      {buttons.map((button, index) => {
        let className = index === 0 ? 'navigation-tabs__first' : ''
        className = index === total - 1 ? 'navigation-tabs__last' : className
        return <NavigationTabsLink key={index} button={button} className={className} />
      })}
    </div>
  )
}
