import { BaseObj, BaseUrls } from '@models/base'
import { ReceptionBooking } from '@models/reception'
import { ClientUser } from '@models/clients'
import { ContentNote } from '@models/dashboard'
import { Subscription } from '@modules/subscription/models'
import { SubscriptionContract } from '@modules/subscription-contract/models'
import { GastroCard } from '@models/promotions'

export const AvailableUpSellBookingStatus = [
  'not_interested',
  'sold',
  'contact_required_again',
  'contact_required',
] as const

export type UpSellBookingStatus = (typeof AvailableUpSellBookingStatus)[number]

export interface UpSellBookingClient extends ClientUser {
  bookings: ReceptionBooking[]
  subscriptions: Subscription[]
  subscription_contracts: SubscriptionContract[]
  gastro_cards: GastroCard[]
}

interface UpSellBookingUrls extends BaseUrls {
  send_surcharge_info: string
  notes: string
  poll: string
}

export interface UpSellBooking extends BaseObj {
  status: UpSellBookingStatus
  status_display: string
  contact_required_date: string | null
  urls: UpSellBookingUrls
  booking: ReceptionBooking
  client: UpSellBookingClient
  survey: any
}

interface UpSellPollAnswer {
  option_id: number
  poll_id: number
  id: number
}

export interface UpSellBookingDetails extends UpSellBooking {
  notes: ContentNote[]
  upsell_poll_answers: UpSellPollAnswer[]
}

export interface UpSellOutdatedBooking {
  contact_required: number
  contact_required_again: number
  contact_required_date: string
}

export interface UpSellPollOption {
  id: number
  text: string
}

export interface UpSellPoll {
  id: number
  is_active: boolean
  name: string
  options: UpSellPollOption[]
}

export interface UpSellAppData {
  upsell_polls: UpSellPoll[]
}

interface UpSellUserUrls {
  generate_booking: string
}

export interface UpSellUser {
  first_name: string
  id: number
  last_name: string
  urls: UpSellUserUrls
}
