import * as React from 'react'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { getRentalAppData } from '@store/actions/rental-warehouse-actions'
import { RentalAppData } from '@modules/rental/models'
import { setRentalAppData } from '@store/slices/rental-slice'

export const useRentalAppData = (): RentalAppData => {
  const rentalAppData = useAppSelector((state: RootState) => state.rentalState.appData)
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (rentalAppData.status === 'unknown') {
      dispatch(getRentalAppData())
      dispatch(setRentalAppData({ ...rentalAppData, status: 'loading' }))
    }
  }, [])

  return rentalAppData
}
