import * as React from 'react'
import { CardFooter } from 'reactstrap'
import { ButtonWithIcon } from '@components/button-with-icon'
import classnames from 'classnames'

interface Props {
  allNotesShowed: boolean
  allNotesLength: number
  onShowAllNotesClick: () => void
  contentName: string
}

export const ContentNotesCardFooter = ({
  allNotesShowed,
  allNotesLength,
  onShowAllNotesClick,
  contentName,
}: Props): JSX.Element => (
  <CardFooter className="text-center">
    <ButtonWithIcon
      btnClass="bg-transparent border-0 shadow-none text-default py-0 pl-0 font-11"
      icon={classnames('font-16 lh-0', allNotesShowed ? 'uil-angle-down' : 'uil-angle-right')}
      text={
        allNotesShowed ? `Pokaż tylko najnowsze ${contentName}` : `Pokaż wszystkie ${contentName} (${allNotesLength})`
      }
      iconWrapperClassName="flex-row-reverse"
      handleClick={onShowAllNotesClick}
    />
  </CardFooter>
)
