import * as React from 'react'
import { RecommendationGift } from '@models/crm'
import { Button, ModalFooter, ModalHeader } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { DetailsColRow } from '@hyper/details-col-row'
import { useModal } from '@components/modals/use-modal'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { BaseModalProps } from '@components/modals/types'

interface Props extends BaseModalProps {
  recommendationGift: RecommendationGift
}

export const RecommendationGiftDetailsDialog: React.FC<Props> = ({ recommendationGift, toggleIsVisible }) => {
  const [handleToggleEdit] = useModal(
    'RecommendationGiftDialog',
    {
      recommendationGift,
    },
    { persist: true },
  )

  const handleEdit = React.useCallback(() => {
    handleToggleEdit()
    toggleIsVisible()
  }, [])

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>Prezent za polecenie</ModalHeader>
      <ModalBody>
        <DetailsColRow label="Imię i nazwisko osoby polecającej" value={recommendationGift.recommending_person_name} />

        <DetailsColRow label="Imię i nazwisko osoby poleconej" value={recommendationGift.recommended_person_name} />
        <DetailsColRow label="Rodzaj" value={recommendationGift.kind_display} />
        {recommendationGift.seller_display && (
          <DetailsColRow label="Sprzedawca" value={recommendationGift.seller_display} />
        )}

        <DetailsColRow
          label="Data otrzymania oświadczenia"
          value={
            <span className="text-semi-strong">
              <i className="uil uil-calendar-alt font-16 text-muted" />{' '}
              {toDefaultDateFormat(recommendationGift.received_date)}
            </span>
          }
        />

        <DetailsColRow
          label="Termin przelewu do dnia"
          value={
            <span className="text-semi-strong">
              <i className="uil uil-calendar-alt font-16 text-muted" />{' '}
              {toDefaultDateFormat(recommendationGift.transfer_by_day)}
            </span>
          }
        />

        <DetailsColRow label="Numer konta" value={recommendationGift.account_number_formatted} />
        <DetailsColRow label="Numer zakupu (polecającego)" value={recommendationGift.recommending_related_number} />
        <DetailsColRow label="Numer zakupu (poleconego)" value={recommendationGift.recommended_related_number} />

        <DetailsColRow
          label="Data wprowadzenia przelewu"
          value={
            <span className="text-semi-strong">
              <i className="uil uil-calendar-alt font-16 text-muted" />{' '}
              {recommendationGift.transfer_enter_date
                ? toDefaultDateFormat(recommendationGift.transfer_enter_date)
                : 'brak'}
            </span>
          }
        />

        <DetailsColRow
          label="Data wykonania przelewu"
          value={
            <span className="text-semi-strong">
              <i className="uil uil-calendar-alt font-16 text-muted" />{' '}
              {recommendationGift.transfer_date ? toDefaultDateFormat(recommendationGift.transfer_date) : 'brak'}
            </span>
          }
        />
        <DetailsColRow
          label="Status przelewu"
          value={<span className="text-semi-strong">{recommendationGift.transfer_status}</span>}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={handleEdit}>
          Edytuj
        </Button>
        <Button color="green" onClick={toggleIsVisible}>
          Zamknij
        </Button>
      </ModalFooter>
    </>
  )
}
