import { AxiosError } from 'axios'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { Path } from 'react-hook-form'
import { FormInputs } from '@modules/cms/reservation-app/faq-modal'

export interface HandleConfiguration {
  excludeFields?: string[]
  nonFieldErrorsAs?: string
  showGlobalError?: boolean
  arrayPrefix?: string
  customErrorHandler?: (error: AxiosError) => void
  mapFormError: [string, string][]
}

export const CUSTOM_AXIOS_ERROR = 'Custom axios error'

export const useHandleAxiosFormErrors = () => {
  const { addErrorMessage } = useNotificationHook()
  const initialConfiguration: HandleConfiguration = { excludeFields: [], showGlobalError: true, mapFormError: [] }

  return (
    error: AxiosError<Error>,
    setErrors: (key: string, message: { type: string; message: string }) => void,
    configuration: Partial<HandleConfiguration> = initialConfiguration,
  ) => {
    if (!configuration.excludeFields) {
      configuration.excludeFields = []
    }
    if (configuration.nonFieldErrorsAs) {
      configuration.excludeFields.push('non_field_errors')
    }
    if (error.response?.status === 400 || error.name === CUSTOM_AXIOS_ERROR) {
      const data = error.name === CUSTOM_AXIOS_ERROR ? JSON.parse(error.message) : error.response?.data

      if (configuration.mapFormError) {
        configuration.mapFormError.forEach(row => {
          error = _mapFormError(error, row[0], row[1])
        })
      }

      if (data.non_field_errors && configuration.nonFieldErrorsAs) {
        setErrors(configuration.nonFieldErrorsAs, {
          type: 'server',
          message: data.non_field_errors.join('. '),
        })
      } else if (data.non_field_errors && configuration.showGlobalError) {
        addErrorMessage('Błąd', data.non_field_errors.join('. '))
        configuration.excludeFields.push('non_field_errors')
      }

      if (error.response?.data && Array.isArray(error.response.data)) {
        Object.values(error.response.data).forEach(errors => {
          Object.keys(errors).map((key: Path<FormInputs>, index) => {
            if (configuration.excludeFields?.includes(key)) return
            setErrors(configuration.arrayPrefix ? `${configuration.arrayPrefix}.${index}.${key}` : key, {
              type: 'server',
              message: (errors[key] || []).join('. '),
            })
          })
        })
        return
      }

      Object.keys(data).map(key => {
        if (!configuration.excludeFields?.includes(key)) {
          const message = data[key]
          if (typeof message[0] === 'object') {
            handleAxiosFormErrorsNestedObject(key, message, setErrors)
          } else {
            if (Array.isArray(message)) {
              setErrors(key, {
                type: 'server',
                message: (message || []).join('. '),
              })
            } else {
              const localMessage = Object.values(message) as string[]
              if (localMessage && localMessage.length) {
                setErrors(key, {
                  type: 'server',
                  message: (localMessage || []).join('. '),
                })
              }
            }
          }
        }
      })
    } else {
      if (configuration.showGlobalError) {
        if (error.response?.status === 403) {
          addErrorMessage('Błąd', 'Nie masz uprawnień, by wykonać tę czynność.')
          return
        }

        addErrorMessage()
      } else {
        throw error
      }
    }
  }
}

const handleAxiosFormErrorsNestedObject = (fieldName, message, setErrors) => {
  message.map((validation, index) => {
    Object.keys(validation).map(key => {
      const newKey = `${fieldName}.${index}.${key}`
      setErrors(newKey, {
        type: 'server',
        message: Array.isArray(validation[key]) ? validation[key].join('. ') : validation[key],
      })
    })
  })
}

export const _mapFormError = (error: AxiosError<Error>, old_field, new_field) => {
  if (error.response?.status === 400) {
    if (error.response.data[old_field] && !error.response.data[new_field]) {
      error.response.data[new_field] = error.response.data[old_field]
    }
  }
  return error
}

export const useHandleAxiosServerErrors = () => {
  const { addErrorMessage } = useNotificationHook()

  return (error: AxiosError<Error>) => {
    if (error.response?.status === 400) {
      const keys = Object.keys(error.response.data)
      addErrorMessage('Błąd', error.response.data[keys[0]][0])
    } else {
      addErrorMessage()
    }
  }
}

interface ParsedErrorData {
  type: string
  message: string
}

export const setErrorForDynamicKey = (dynamicKey: string, setError: any) => (key: string, data: ParsedErrorData) => {
  setError(dynamicKey, data)
}
