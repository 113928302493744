import * as React from 'react'
import { ApplicationTypes } from '@modules/housekeeping/applications/models'
import { NavigationTabs, NavigationTabsButton } from '@components/navigation-tabs'
import { NavigationPath } from '@models/routes'
import { RootState, useAppSelector } from '@store/index'

interface HousekeepingApplicationsTabProps {
  active: ApplicationTypes
  resortId: number
}

export const HousekeepingApplicationsTab: React.FC<HousekeepingApplicationsTabProps> = ({ resortId, active }) => {
  const aggregation = useAppSelector((state: RootState) => state.houseKeepingState.applicationsAggregation)

  const url = NavigationPath.HousekeepingApplicationsWithParams.replace(':resort_id', String(resortId))
  const buttons: NavigationTabsButton[] = [
    {
      title: `Usterki (${aggregation.faults})`,
      isActive: active === 'faults',
      link: url.replace(':type', 'faults'),
    },
    {
      title: `Zgłoszenia gości (${aggregation.issues})`,
      isActive: active === 'issues',
      link: url.replace(':type', 'issues'),
    },
  ]
  return <NavigationTabs buttons={buttons} />
}
