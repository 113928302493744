import * as React from 'react'
import { ReceptionBooking } from '@models/reception'
import { useModal } from '@components/modals/use-modal'

interface Props {
  booking: ReceptionBooking
  onClose: () => void
}

export const ReceptionBookingCheckInPreview: React.FC<Props> = ({ booking, onClose }) => {
  const [showCheckinPreviewDialog] = useModal(
    'ReceptionBookingCheckInPreviewDialog',
    {
      booking,
    },
    { onClose },
  )

  React.useEffect(() => {
    showCheckinPreviewDialog()
  }, [])

  return null
}
