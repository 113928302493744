import * as React from 'react'
import { Row } from 'reactstrap'
import classNames from 'classnames'
import { formatPrice } from '@helpers/utils'
import { FormInput } from '@hyper/forms'
import { BookingApartmentMediaPayload } from '@store/actions/reception-actions'
import { FakeMeterCalculation, ReceptionBookingDetails } from '@models/reception'
import { parseISODate, toTextDateTimeFormat } from '@helpers/date-helper'
import { calculateMeterCosts, isOutOfDateReadingTime } from '@modules/reception/reception-meters-reading-dialog/tools'
import { useFormContext } from 'react-hook-form'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { commonObjectGet } from '@store/actions/generic-actions'
import { BookingApartmentMeters } from '@models/booking'
import { useApiRequest } from '@components/hooks/use-api-request'
import { TopBarLoading } from '@components/topbar-loading'
import { IconWithTooltip } from '@components/icon-with-tooltip'

interface CalculatedMeter {
  consumption: string
  amount: string
}

interface ReceptionMetersReadingRowProps {
  title: string
  icon: string
  unit: string
  prefix: string
  booking: ReceptionBookingDetails
}

export const ReceptionMetersReadingRow: React.FC<ReceptionMetersReadingRowProps> = ({
  title,
  icon,
  unit,
  prefix,
  booking,
}) => {
  const initialField = `${prefix}_start`
  const finalField = `${prefix}_end`
  const unitCostField = `${prefix}_costs_value`
  const emergencyInputField = `${prefix}_meter_kind`
  const readingStartDatetime = `${prefix}_start_datetime`
  const readingEndDatetime = `${prefix}_end_datetime`
  const { addErrorMessage } = useNotificationHook()

  const { watch, setValue } = useFormContext<BookingApartmentMediaPayload>()

  const serviceRoomData = booking.service_room_data

  const [costs, setCosts] = React.useState<CalculatedMeter>({
    consumption: '',
    amount: '',
  })

  const values = watch()

  const isEmergencyModeActive =
    values[emergencyInputField] === 'fake' || booking.service_room_data[emergencyInputField] === 'fake'

  const isReadOnly =
    values[emergencyInputField] === 'default' ||
    values[emergencyInputField] === 'fake' ||
    values[emergencyInputField] === 'fake_manual'

  React.useEffect(() => {
    setCosts(calculateMeterCosts(values[initialField], values[finalField], serviceRoomData[unitCostField]))
  }, [values[initialField], values[finalField]])

  const { isLoading: isLoadingFakeMeter, action: setFakeMeterData } = useApiRequest(async () => {
    const fakeMeterCalculation = await commonObjectGet<FakeMeterCalculation>(booking.urls.calculate_fake_meter)
    setValue(initialField as any, 0)
    setValue(finalField as any, fakeMeterCalculation[`${prefix}_usage`])
  })

  React.useEffect(() => {
    if (values[emergencyInputField] === 'default') {
      setValue(initialField as any, booking.service_room_data[initialField])
      setValue(finalField as any, booking.service_room_data[finalField])
    } else if (values[emergencyInputField] === 'fake_manual') {
      setFakeMeterData()
    }
  }, [values[emergencyInputField]])

  const { isLoading, action: handleSyncMetersReading } = useApiRequest(async () => {
    if (!isEmergencyModeActive) {
      const meterData = await commonObjectGet<BookingApartmentMeters>(booking.urls.meters)

      const readingTime = parseISODate(meterData[`${prefix}_reading_date`] || null)
      if (!readingTime) {
        addErrorMessage('Błąd odczytu', 'Brak aktualnego odczytu danych')
      } else if (isOutOfDateReadingTime(readingTime)) {
        addErrorMessage('Błąd odczytu', 'Odczyt liczników jest nieaktualny')
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setValue(finalField, meterData[prefix])
        serviceRoomData[readingEndDatetime] = readingTime
      }
    }
  })

  const fakeOptions: [string, string][] = [
    ['default', 'Domyślne działanie systemu'],
    ['fake_manual', 'Tryb ryczałtowy włączony ręcznie'],
    ['custom', 'Własny odczyt'],
  ]

  return (
    <div>
      {isLoadingFakeMeter && <TopBarLoading />}

      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <i className={classNames('font-15', icon)} />
          <strong className="mx-1">{title}</strong>
          {isEmergencyModeActive && (
            <IconWithTooltip
              role="warning"
              icon="uil-exclamation-triangle font-16"
              tooltipMessage={
                <span className="font-11">
                  Błąd licznika w lokalu. <br /> Tryb ryczałtowy włączony
                </span>
              }
              tooltipId={`${prefix}_emergency_mode`}
              color="text-danger"
            />
          )}
        </div>

        <FormInput formGroupClassName="m-0" colSize={5} name={emergencyInputField} type="select">
          {fakeOptions.map(row => (
            <option value={row[0]} key={row[0]}>
              {row[1]}
            </option>
          ))}
        </FormInput>
      </div>
      <table className="table table-borderless mt-1 mb-0">
        <tbody>
          <tr>
            <th className="pl-0">Stan początkowy:</th>
            <th>Stan końcowy:</th>
            <th className="text-right">Zużycie:</th>
            <th className="text-right">Kwota:</th>
          </tr>
          <tr>
            <td className="pl-0 reception__meters-reading__row__values">
              <Row className="d-inline-flex flex-nowrap">
                <FormInput
                  disabled={isReadOnly}
                  colSize={10}
                  name={initialField}
                  autoComplete="off"
                  type="number"
                  step="any"
                  dataTestId={initialField}
                />
                <strong className="d-inline-block mt-2">{unit}</strong>
              </Row>
            </td>
            <td className="reception__meters-reading__row__values">
              <div className="d-inline-flex flex-nowrap">
                <FormInput
                  disabled={isReadOnly}
                  colSize={10}
                  name={finalField}
                  autoComplete="off"
                  type="number"
                  step="any"
                  colClassName="pl-0"
                  dataTestId={finalField}
                />
                <strong className="d-inline-block mt-2">{unit}</strong>
              </div>
            </td>
            <td className="reception__meters-reading__row__values reception__meters-reading__row__summary">
              <strong className="mt-2 d-inline-block">
                {costs.consumption} {unit}
              </strong>
            </td>
            <td className="reception__meters-reading__row__values reception__meters-reading__row__summary">
              <strong className="mt-2 d-inline-block">{formatPrice(costs.amount)}</strong>
            </td>
          </tr>
          <tr>
            <td className="pl-0 pt-0 text-nowrap">
              <span className="font-10">
                Odczyt:{' '}
                {serviceRoomData[readingStartDatetime]
                  ? toTextDateTimeFormat(serviceRoomData[readingStartDatetime])
                  : 'brak'}
              </span>
            </td>
            <td className="pt-0 text-nowrap">
              <span className="font-10">
                Odczyt:{' '}
                {serviceRoomData[readingEndDatetime]
                  ? toTextDateTimeFormat(serviceRoomData[readingEndDatetime])
                  : 'brak'}
              </span>
              <i
                className={classNames('ml-1 uil-sync cursor-pointer', {
                  'reception__meters-reading--synchronize': isLoading,
                })}
                onClick={handleSyncMetersReading}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
