import * as React from 'react'
import { ReservationStatsTableDataCell } from '@modules/reservations/statistics/booking-stats/stats-details/stats-details-table/reservation-stats-table-data-cell'
import { ImprovementsStatsTableCellContent } from '@modules/reservations/statistics/improvements-stats/stats-table/improvements-stats-table-cell-content'
import { ImprovementsStatsDetails, StatsTableOptions } from '@modules/reservations/models'

interface Props {
  statsDetails: ImprovementsStatsDetails
  hoveredDate: string | null
  element: string
  onHoveredElementChange: (date: string) => void
  options?: StatsTableOptions<ImprovementsStatsDetails>
  onReportGenerate: ({ code, date, accommodation_type, resort }) => void
  accommodationType: string | undefined
  resort: string | undefined
  isConsumable: boolean
}

export const ImprovementsStatsTableCell = React.memo(
  ({
    statsDetails,
    options,
    hoveredDate,
    onHoveredElementChange,
    element,
    onReportGenerate,
    accommodationType,
    resort,
    isConsumable,
  }: Props): JSX.Element => {
    const handleReportGenerate = React.useCallback(
      () => onReportGenerate({ code: element, date: statsDetails.day, accommodation_type: accommodationType, resort }),
      [element, statsDetails.day, accommodationType, resort],
    )

    return (
      <ReservationStatsTableDataCell
        hoveredDate={hoveredDate}
        onHoverChange={onHoveredElementChange}
        date={statsDetails.day}
        key={statsDetails.day}
        className={options?.tableCellOption?.className}
      >
        <ImprovementsStatsTableCellContent
          element={element}
          statsDetails={statsDetails}
          options={options}
          onGenerateReport={handleReportGenerate}
          isConsumable={isConsumable}
        />
      </ReservationStatsTableDataCell>
    )
  },
)
