import * as React from 'react'
import classnames from 'classnames'
import { Droppable } from 'react-beautiful-dnd'

interface Props {
  droppableId: string
  dropMessage: string
  subtitle: string
}

export const DraggableTableDropRowPlaceholder = ({ droppableId, dropMessage, subtitle }: Props): JSX.Element => (
  <Droppable droppableId={droppableId}>
    {(provided, snapshot) => (
      <div
        {...provided.droppableProps}
        ref={provided.innerRef}
        className={classnames('housekeeping__ordered-order', {
          'housekeeping__ordered-order--hovered': snapshot.isDraggingOver,
        })}
      >
        {snapshot.isDraggingOver ? (
          <p className="text-center font-16 mb-0">{dropMessage}</p>
        ) : (
          <div>
            <p className="text-secondary text-center font-weight-bold font-20">Lista jest pusta</p>
            <p className="text-center font-16 mb-0">{subtitle}</p>
          </div>
        )}
      </div>
    )}
  </Droppable>
)
