import * as React from 'react'
import { useForm } from 'react-hook-form'
import { Button, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { SaveButton } from '@hyper/button'
import { FormInput, FormPlain } from '@hyper/forms'
import { PaymentMethod, paymentMethods } from '@models/payments'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { asDecimal, formatPrice, sumDecimalArray } from '@helpers/utils'
import { updatePackageVipDetails } from '@store/slices/package-vip-slice'
import Decimal from 'decimal.js'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useFormRequest } from '@components/hooks/use-api-request'
import { BaseModalProps } from '@components/modals/types'
import { startOfToday, subDays } from 'date-fns'
import DatePickerInput from '@components/date/date-picker-input'
import { commonObjectPost } from '@store/actions/generic-actions'
import { PackageVipDetails } from '@modules/promotions/package-vip/models'
import { Form } from '@hyper/forms/form'

interface FormInputs {
  amount: string
  source: string
  description: string
  invoice_date: Date
}

export const PackageVipPaymentsAddDialog: React.FC<BaseModalProps> = ({ toggleIsVisible }) => {
  const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()

  const packageVipDetails = useAppSelector((state: RootState) => state.packageVipState.packageVipDetails)

  const requiredAmount = Decimal.max(
    asDecimal(0),
    asDecimal(packageVipDetails?.price_brutto).minus(
      sumDecimalArray(packageVipDetails?.payments.map(payment => asDecimal(payment.amount)) || []),
    ),
  )

  const methods = useForm<FormInputs>({
    defaultValues: {
      amount: requiredAmount.toString(),
      invoice_date: startOfToday(),
    },
  })

  const { isLoading, action: onSubmit } = useFormRequest(async payload => {
    if (packageVipDetails) {
      const data = await commonObjectPost<PackageVipDetails>(packageVipDetails?.urls.payments, { ...payload })
      await dispatch(updatePackageVipDetails(data))
      addSuccessMessage('Sukces', `Wpłata w kwocie ${formatPrice(payload.amount)} została dodana!`)
      toggleIsVisible()
    }
  }, methods.setError)

  const sources = paymentMethods.filter((source: [PaymentMethod, string]) =>
    [PaymentMethod.form_bank, PaymentMethod.polcard].includes(source[0]),
  )
  const minInvoiceDate = subDays(startOfToday(), 3)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Dodaj wpłatę</ModalHeader>
      <ModalBody>
        <Row>
          <FormInput label="Kwota" colSize={5} name="amount" />
          <FormPlain name="invoice_date" colSize={7}>
            <label>Data wpłaty</label>
            <DatePickerInput maxDate={startOfToday()} minDate={minInvoiceDate} name="invoice_date" />
          </FormPlain>

          <FormInput label="Typ płatności" name="source" type="select">
            {sources.map(source => (
              <option key={source[0]} value={source[0]}>
                {source[1]}
              </option>
            ))}
          </FormInput>

          <FormInput label="Numer transakcji bankowej" name="description" />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton label="Zapisz" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
