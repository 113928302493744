import * as React from 'react'
import { HrWorkerForm } from '@modules/hr/common/form/worker-data'
import { HrWorkersSearch } from '@modules/hr/workers/worker-search'
import { AgreementFormInputs } from '@modules/hr/agreement/create/modal'
import { useFormContext, useWatch } from 'react-hook-form'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { useHrWorkerCreateWizardDefaults } from '@modules/hr/workers/create/use-hr-worker-create-wizard-defaults'
import { AgreementDetails } from '@modules/hr/agreement/models'
import { HrWorkerDetails } from '@modules/hr/workers/models'

interface Props {
  agreement: AgreementDetails | undefined
  workerDetails: HrWorkerDetails | undefined
}

export const AgreementCreateFormWorkerData = ({ agreement, workerDetails }: Props): JSX.Element => {
  const { control, setValue, formState, clearErrors } = useFormContext<AgreementFormInputs>()

  const [selectedWorker, isFormVisible] = useWatch({ control, name: ['selectedWorker', 'isFormVisible'] })
  const { defaultValues } = useHrWorkerCreateWizardDefaults(selectedWorker)
  const hasErrors = Object.keys(formState.errors).length

  const showForm = () => {
    setValue('isFormVisible', true)
  }

  useDidUpdateEffect(() => {
    clearErrors()

    Object.entries(defaultValues).forEach(([key, value]) => {
      setValue(key as keyof AgreementFormInputs, value)
    })

    if (selectedWorker) showForm()
  }, [selectedWorker?.id])

  useDidUpdateEffect(() => {
    if (hasErrors) showForm()
  }, [hasErrors])

  React.useEffect(() => {
    if (workerDetails) {
      setValue('selectedWorker', workerDetails)
    }
  }, [workerDetails])

  return (
    <div>
      {!agreement && !workerDetails && (
        <div className="mb-3">
          <HrWorkersSearch onAddNewWorker={showForm} />
        </div>
      )}
      {isFormVisible && <HrWorkerForm />}
    </div>
  )
}
