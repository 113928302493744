import * as React from 'react'
import { ColAuto } from '@hyper/col-auto'
import { Button, FormGroup, Label, Row } from 'reactstrap'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { FormProvider, useForm } from 'react-hook-form'
import { useGroupedSources } from '@components/hooks/use-grouped-sources'
import { useGroupedSellers } from '@components/hooks/use-grouped-sellers'
import DateRangeInput from '@components/date/date-range-input'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { TableFilters } from '@components/table/table'
import { FormSelect } from '@hyper/forms/form-select'
import { useProductsAppData } from '@modules/products/hooks/use-products-app-data'
import { getPlanOptions, SubscriptionContractStatusOptions } from '@modules/subscription-contract/consts'

export interface SubscriptionContractListFilterParams extends TableFilters {
  status: CustomReactSelectOption[]
  plan: CustomReactSelectOption[]
  source_marketing: CustomReactSelectOption | null
  seller: CustomReactSelectOption | null
  sell_date_after: Date | undefined
  sell_date_before: Date | undefined
}

interface Props {
  defaultFilters: SubscriptionContractListFilterParams
  filters: SubscriptionContractListFilterParams
  setFilters: (filters: SubscriptionContractListFilterParams) => void
  children: React.ReactElement
}

export const SubscriptionContractListFilter: React.FC<Props> = ({ defaultFilters, filters, setFilters, children }) => {
  const methods = useForm<SubscriptionContractListFilterParams>({
    defaultValues: defaultFilters,
  })

  const { subscription_contract_plans } = useProductsAppData()

  const planOptions = React.useMemo(() => getPlanOptions(subscription_contract_plans), [subscription_contract_plans])

  const handleReset = () => {
    methods.reset()
    setFilters(defaultFilters)
  }

  const onSubmit = async (payload: SubscriptionContractListFilterParams) => setFilters({ ...filters, ...payload })

  const handleFilter = async () => {
    await onSubmit(methods.getValues())
  }

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  const sources = useGroupedSources()
  const sellers = useGroupedSellers()

  return (
    <FormProvider {...methods}>
      <Row>
        <ColAuto className="px-0">
          <FormSelect
            options={SubscriptionContractStatusOptions}
            name="status"
            label="Status"
            selectProps={{ isMulti: true }}
            formPlainProps={{ colStyle: { width: 330 } }}
          />
        </ColAuto>

        <ColAuto className="px-0">
          <FormSelect
            options={sellers}
            name="seller"
            label="Sprzedawca"
            formPlainProps={{ colStyle: { width: 200 } }}
          />
        </ColAuto>
        <ColAuto className="px-0">
          <FormSelect
            options={planOptions}
            name="plan"
            label="Plan"
            selectProps={{ isSelectMulti: true, isClearable: true }}
            formPlainProps={{ colStyle: { width: 220 } }}
          />
        </ColAuto>
        <ColAuto className="px-0">
          <FormSelect
            options={sources}
            name="source_marketing"
            label="Źródło"
            formPlainProps={{ colStyle: { width: 200 } }}
          />
        </ColAuto>

        <ColAuto>
          <FormGroup>
            <label className="d-block">&nbsp;</label>
            <Button color="primary" onClick={handleFilter}>
              Filtruj
            </Button>
            {methods.formState.isDirty && (
              <Button color="light" className="ml-1" type="button" onClick={handleReset}>
                Wyczyść
              </Button>
            )}
          </FormGroup>
        </ColAuto>
        {children}
      </Row>
      <Row>
        <ColAuto>
          <Label>Data sprzedaży</Label>
          <DateRangeInput
            isClearable={true}
            startDateName="sell_date_after"
            endDateName="sell_date_before"
            wrapperClassName="is-range"
          />
        </ColAuto>
        <SearchFilter colClassName="col-md-2" />
      </Row>
    </FormProvider>
  )
}
