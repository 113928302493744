import * as React from 'react'
import { useAppDispatch } from '@store/index'
import { ReceptionBookingDetails } from '@models/reception'
import {
  updateReceptionApartment,
  updateReceptionBooking,
  updateReceptionBookingDetails,
} from '@store/actions/reception-actions'
import { NotificationEvents } from '@models/dashboard'
import { Apartment } from '@models/apartment'
import { useWebSocket } from '@components/hooks/use-web-socket'

export const ReceptionWebSocketHandler: React.FC = () => {
  const dispatch = useAppDispatch()

  useWebSocket<ReceptionBookingDetails | Apartment>(
    (message, payload) => {
      switch (message) {
        case NotificationEvents.BOOKING_CHANGE: {
          const bookingDetails = payload as ReceptionBookingDetails
          dispatch(updateReceptionBookingDetails(bookingDetails))
          dispatch(updateReceptionBooking(bookingDetails))
          break
        }
        case NotificationEvents.APARTMENT_CHANGE: {
          const apartmentDetails = payload as Apartment
          dispatch(updateReceptionApartment(apartmentDetails))
          break
        }
      }
    },
    [],
    [NotificationEvents.BOOKING_CHANGE, NotificationEvents.APARTMENT_CHANGE],
  )

  return null
}
