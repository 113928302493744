import { HandleConfiguration, useHandleAxiosFormErrors } from '@helpers/handle-axios-errors'
import * as React from 'react'
import { ReceptionBookingCheckStep, showStepError } from '@modules/reception/common/reception-booking-check-steps'

interface Configuration {
  configuration: Partial<HandleConfiguration>
  steps: ReceptionBookingCheckStep[]
  setStep: (step: number) => void
}

export const useHandleRtkQueryError = (
  error,
  isError,
  setError: (key: string, message: { type: string; message: string }) => void,
  config?: Partial<Configuration>,
) => {
  const handleAxiosFormErrors = useHandleAxiosFormErrors()

  const rtkQueryStepError = (key, message) => {
    if (!config?.setStep || !config?.steps) return
    showStepError(config.setStep, config.steps, error, config.configuration?.nonFieldErrorsAs)
    setError(key, message)
  }

  React.useEffect(() => {
    if (isError) {
      handleAxiosFormErrors(error, config?.steps ? rtkQueryStepError : setError, config?.configuration)
    }
  }, [isError, error, setError])
}
