import * as React from 'react'
import { ReceptionBooking } from '@models/reception'
import classNames from 'classnames'
import { formatDate, parseISODate } from '@helpers/date-helper'

interface ReceptionToCheckOutRowDepartureTimeProps {
  booking: ReceptionBooking
}

export const ReceptionToCheckOutRowDepartureTime: React.FC<ReceptionToCheckOutRowDepartureTimeProps> = ({ booking }) =>
  booking.status === 'close' ? (
    <>
      <div className="mb-1">Wymeldowano o:</div>
      <i className="uil uil-clock font-16 mr-1 text-muted" />
      {formatDate(parseISODate(booking.close_datetime), 'HH:mm')}
    </>
  ) : (
    <>
      <div className="mb-1">Wyjazd o:</div>
      <i
        className={classNames('uil uil-clock font-16 mr-1', {
          'text-muted': !booking.extended_stay,
          'text-strong': booking.extended_stay,
          'text-danger': booking.extended_stay,
        })}
      />
      <strong>{booking.departure_time}</strong>
    </>
  )
