import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { IconWithText } from '@components/icon-with-text'
import { ReservationDataRow } from '@modules/reservations/details/common/reservation-data-row'
import { BadgeWithIcon } from '@components/badges/BadgeWithIcon'
import { ExpandArrow, useExpandableItems } from '@components/hooks/use-expandable-items'
import { Collapse } from 'reactstrap'
import { ReceptionBookingCheckInPreviewPersonalDataSignature } from '@modules/reception/checkin/preview/personal-data/reception-booking-check-in-preview-personal-data-signature'
import classNames from 'classnames'

interface Props {
  booking: ReceptionBookingDetails
}

export const ReservationDataCheckInDocuments = ({ booking }: Props): JSX.Element => {
  const { toggleExpanded, isExpanded } = useExpandableItems<'check-in-documents'>()

  const documentToSignAmount = booking.check_in_documents.length
  const signedDocumentsAmount = booking.booking_documents.length

  const badgeData = {
    icon: signedDocumentsAmount ? 'uil-check-circle' : 'uil-times-circle',
    badge: signedDocumentsAmount ? 'badge badge-success-lighten' : 'badge badge-danger-lighten',
  }

  return (
    <ReservationDataRow
      name={<IconWithText icon="uil-file-check-alt" text="Dokumenty meldunkowe:" />}
      className="border-bottom pb-2 mr-3"
    >
      <div className="d-flex align-items-center">
        <BadgeWithIcon
          icon={badgeData.icon}
          title={signedDocumentsAmount ? `podpisane ${signedDocumentsAmount}` : 'brak'}
          className={badgeData.badge}
        />
        {!!signedDocumentsAmount && (
          <ExpandArrow
            fontSize="font-14"
            className="lh-0"
            isExpanded={isExpanded('check-in-documents')}
            onExpand={toggleExpanded('check-in-documents')}
          />
        )}
      </div>

      {!!signedDocumentsAmount && (
        <Collapse isOpen={isExpanded('check-in-documents')}>
          {booking.booking_signature_url && (
            <div className="d-flex justify-content-between align-items-center">
              <p className="font-11 mb-0">Podpis Gościa:</p>
              <ReceptionBookingCheckInPreviewPersonalDataSignature bookingDetails={booking} withDocuments={false} />
            </div>
          )}

          <p className={classNames('font-11 mb-2', { 'mt-2': !booking.booking_signature_url })}>Podpisane dokumenty:</p>
          {booking.booking_documents.map((document, index) => (
            <div
              key={document.id}
              className={classNames('d-flex justify-content-between align-items-center pt-1', {
                'border-top': index > 0,
                'pb-1': index !== documentToSignAmount - 1,
              })}
            >
              <IconWithText
                icon={classNames(
                  'font-12',
                  document.file_url ? 'uil-file-check-alt text-default' : 'uil-file-times-alt text-danger',
                )}
                text={document.kind_display}
                textClass={classNames('fw-semi-bold font-11', document.file_url ? 'text-secondary' : 'text-danger')}
              />
              {document.file_url && (
                <a href={document.file_url} target="_blank" className="text-default">
                  <IconWithText icon="uil-eye lh-0 font-12" text="Zobacz" textClass="font-10" />
                </a>
              )}
            </div>
          ))}
        </Collapse>
      )}
    </ReservationDataRow>
  )
}
