import * as React from 'react'
import { PaymentRegistersFiltersParams } from '@modules/payment-registers/list/filters'
import { useForm } from 'react-hook-form'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { Row } from 'reactstrap'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { PaymentRegisterFilters } from '@modules/payment-registers/common/filters'
import { Form } from '@hyper/forms/form'

interface Props {
  setFilters: (filters: PaymentRegistersFiltersParams) => void
  filters: PaymentRegistersFiltersParams
  defaultFilters: PaymentRegistersFiltersParams
}

export const PaymentRegisterUserCashboxTransactionsTableFilters = ({
  filters,
  setFilters,
  defaultFilters,
}: Props): JSX.Element => {
  const methods = useForm<PaymentRegistersFiltersParams>({
    defaultValues: defaultFilters,
  })

  const onSubmit = async (payload: PaymentRegistersFiltersParams) => setFilters({ ...filters, ...payload })

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  return (
    <Form methods={methods} onSubmit={onSubmit} className="form form-style-filter mb-2">
      <Row>
        <PaymentRegisterFilters />

        <SearchFilter colClassName="mr-2" />
      </Row>
    </Form>
  )
}
