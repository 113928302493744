import * as React from 'react'
import { FaultOrderDetails } from '@modules/housekeeping/applications/models'
import { FaultOrderExecutor } from '@modules/housekeeping/models'
import { useForm, useWatch } from 'react-hook-form'
import { commonObjectPatch } from '@store/actions/generic-actions'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useDebounce } from 'rooks'
import { updateHousekeepingFaultOrder } from '@store/slices/housekeeping-slice'
import { useApiRequest } from '@components/hooks/use-api-request'

interface FaultOrderCellExecutorProps {
  faultOrder: FaultOrderDetails
  faultOrderExecutors: FaultOrderExecutor[]
}

interface FormInputs {
  executor: string
}

export const FaultOrderCellExecutor: React.FC<FaultOrderCellExecutorProps> = ({ faultOrder, faultOrderExecutors }) => {
  const { control, register } = useForm<FormInputs>({
    defaultValues: {
      executor: faultOrder.executor,
    },
  })
  const dispatch = useAppDispatch()

  const { addSuccessNotification } = useNotificationHook()

  const newExecutor = useWatch({ name: 'executor', control })

  const { action: updateFaultOrder } = useApiRequest(async (executor: string) => {
    dispatch(
      updateHousekeepingFaultOrder(
        await commonObjectPatch<FaultOrderDetails>(faultOrder.urls.details, {
          executor,
        }),
      ),
    )
    addSuccessNotification('Zmiany zostały zapisane!')
  })

  const updateFaultOrderDebounced = useDebounce(updateFaultOrder, 300)

  React.useEffect(() => {
    if (newExecutor !== faultOrder.executor) {
      updateFaultOrderDebounced(newExecutor)
    }
  }, [newExecutor])

  return (
    <select className="form-control form-control-sm w-auto" {...register('executor')}>
      <option value="">Wybierz</option>
      {faultOrderExecutors.map(([value, label]) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </select>
  )
}
