import * as React from 'react'
import { ReceptionApartmentHousekeepingStatus } from '@modules/reception/common/reception-apartment-housekeeping-status'
import { ReceptionBookingDetails } from '@models/reception'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { useAppData } from '@components/hooks/use-app-data'
import { CopyToClipboard } from '@components/copy-to-clipboard'
import { getReceptionBookingDetails } from '@store/actions/reception-actions'
import { useAppDispatch } from '@store/index'

interface ReservationDataRows {
  apartmentData: React.ReactElement
  reservationNumber: React.ReactElement
  createdDate: React.ReactElement
  createdBy: React.ReactElement
  accommodationType: string
  resort: string
}

interface ReservationDataRowsProviderProps {
  render: (element: ReservationDataRows) => React.ReactElement
  booking: ReceptionBookingDetails
}

export const ReservationDataRowsProvider: React.FC<ReservationDataRowsProviderProps> = ({ render, booking }) => {
  const dispatch = useAppDispatch()
  const { resorts, accommodation_types } = useAppData()

  const handleApartmentStatusUpdate = async () => {
    await dispatch(getReceptionBookingDetails(booking.urls.api_details))
  }

  const reservationNumber = (
    <>
      <span className="mr-1 font-weight-bold">{booking.reservation_number}</span>
      <CopyToClipboard className="uil-link-alt text-secondary" value={booking.reservation_number} />
    </>
  )

  const apartmentData = (
    <div className="d-flex align-content-center">
      <strong className="mr-1">{booking.apartment?.name}</strong>
      <ReceptionApartmentHousekeepingStatus
        apartment={booking.apartment}
        onStatusUpdate={handleApartmentStatusUpdate}
      />
    </div>
  )

  const createdBy = <span className="font-weight-bold">{booking.name}</span>
  const createdDate = <span className="font-weight-bold">{toDefaultDateTimeFormat(booking.created_at)}</span>

  const resort = resorts.find(resort => resort.id === booking.resort_id)?.name || 'brak'

  const accommodationType =
    accommodation_types.find(accommodationType => accommodationType.id === booking.accommodation_type_id)?.name ||
    'brak'

  return render({
    apartmentData,
    reservationNumber,
    createdDate,
    createdBy,
    accommodationType,
    resort,
  })
}
