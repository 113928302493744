import { DashboardStandardPaginator } from '@models/dashboard'

export enum DashboardActionTypesKeys {
  SET_STANDARD_PAGINATOR = 'SET_STANDARD_PAGINATOR',
}

export interface SetDashboardStandardPaginatorAction {
  type: DashboardActionTypesKeys.SET_STANDARD_PAGINATOR
  payload: DashboardStandardPaginator
}

export type DashboardActionTypes = SetDashboardStandardPaginatorAction
