import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NotificationEvents } from '@models/dashboard'

interface State {
  payload: any
  message: NotificationEvents | undefined
  channels: string[]
}

const initialState: State = {
  payload: null,
  message: undefined,
  channels: [],
}

export const webSocketSlice = createSlice({
  name: 'webSocket',
  initialState,
  reducers: {
    setWebSocketData(state, action: PayloadAction<any>) {
      state.payload = action.payload.payload
      state.message = action.payload.type
    },
    addChannels(state, action: PayloadAction<string[]>) {
      state.channels = [...state.channels, ...action.payload].sort()
    },
    removeChannels(state, action: PayloadAction<string[]>) {
      state.channels = state.channels.filter(row => !action.payload.includes(row))
    },
  },
})

export const { setWebSocketData, removeChannels, addChannels } = webSocketSlice.actions

export default webSocketSlice.reducer
