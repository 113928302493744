import * as React from 'react'
import { TableFilters } from '@components/table/table'
import { useForm } from 'react-hook-form'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { Form } from '@hyper/forms/form'

interface Props {
  filters: TableFilters
  setFilters: (filters: TableFilters) => void
}

export const PromocodePrefixesFilters: React.FC<Props> = ({ filters, setFilters }) => {
  const methods = useForm<TableFilters>({
    defaultValues: { ...filters },
  })

  const onSubmit = payload => setFilters({ ...filters, ...payload })

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  return (
    <Form onSubmit={onSubmit} methods={methods} className="row form form-style-filter">
      <SearchFilter colClassName="col-md-2" />
    </Form>
  )
}
