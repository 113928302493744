import * as React from 'react'
import { TransferPackageDetails } from '@modules/accounting/refund/models'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { Card, CardBody } from 'reactstrap'

interface Props {
  transferPackage: TransferPackageDetails
}

export const TransferPackagePreviewModalSummary: React.FC<Props> = ({ transferPackage }) => (
  <Card>
    <CardBody>
      <h5 className="header-title mb-1">Szczegóły paczki</h5>
      <div className="table-responsive">
        <table className="table table-sm mb-2">
          <colgroup>
            <col width="40%" />
            <col width="60%" />
          </colgroup>
          <tbody>
            <tr>
              <td>Data utworzenia</td>
              <td>{toDefaultDateTimeFormat(transferPackage.created)}</td>
            </tr>
            <tr>
              <td>Utworzone przez</td>
              <td>{transferPackage.created_by}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td>{transferPackage.status_display}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </CardBody>
  </Card>
)
