import * as React from 'react'
import { BookingTouristVoucher, ReceptionBookingDetails } from '@models/reception'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAppDispatch } from '@store/index'
import { useApiRequest } from '@components/hooks/use-api-request'
import { updateReceptionBookingDetails } from '@store/actions/reception-actions'
import { commonObjectPost } from '@store/actions/generic-actions'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { YesNoBadge } from '@components/badges/yes-no'

interface Props {
  touristVoucher: BookingTouristVoucher
}

export const ReservationTouristVouchersRowConfirm = ({ touristVoucher }: Props): JSX.Element => {
  const { addSuccessNotification } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading, action: handleAccept } = useApiRequest(async () => {
    dispatch(
      updateReceptionBookingDetails(await commonObjectPost<ReceptionBookingDetails>(touristVoucher.urls.confirm)),
    )
    addSuccessNotification('Bon został potwierdzony')
  })

  return touristVoucher.is_confirmed ? (
    <YesNoBadge value={touristVoucher.is_confirmed} />
  ) : (
    <CommonObjectConfirmAction handleAccept={handleAccept} isLoading={isLoading}>
      <YesNoBadge value={touristVoucher.is_confirmed} className="cursor-pointer" />
    </CommonObjectConfirmAction>
  )
}
