import * as React from 'react'
import { TransferPackage, TransferPackageDetails } from '@modules/accounting/refund/models'
import { SaveButton } from '@hyper/button'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApiRequest } from '@components/hooks/use-api-request'
import { BaseModalProps } from '@components/modals/types'
import { updateAccountingTransferPackage } from '@store/slices/accounting-slice'

interface Props extends BaseModalProps {
  transferPackage: TransferPackage
}

export const TransferPackageEditModalGenerateButton: React.FC<Props> = ({ transferPackage, toggleIsVisible }) => {
  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()

  const { isLoading, action: handleOnClick } = useApiRequest(async () => {
    await dispatch(
      updateAccountingTransferPackage(await commonObjectPost<TransferPackageDetails>(transferPackage.urls.generate)),
    )
    toggleIsVisible()
    addSuccessNotification('Paczka została wygenerowana!')
  })

  return (
    <SaveButton
      isSaving={isLoading}
      label="Generuj paczkę"
      labelSaving="Generowanie"
      className="btn btn-green"
      onClick={handleOnClick}
    />
  )
}
