import { getState } from '@store/index'
import { formatDate } from '@helpers/date-helper'
import {
  FeedingHostOptions,
  FeedingApartmentOption,
  FeedingClientDiscount,
  FeedingClientPaymentMethod,
  FeedingEnter,
  FeedingHostStats,
  FeedingSpecialGuestEnter,
  FeedingStartBy,
  FeedingType,
  FeedingWorkerGuest,
} from '@modules/feeding/models'
import { commonObjectGet, commonObjectPost } from '@store/actions/generic-actions'
import { CancelTokenSource } from 'axios'

interface FetchGuestsPayload {
  date: Date
  type: FeedingType
  resort?: string | number
}

export type FeedingClientAge = 'adult' | 'children_5_12' | 'children_3_4' | 'baby'

export interface FeedingClientCalculateGuestPayload {
  name: string
  age: FeedingClientAge
}

export interface FeedingClientCalculatePayload {
  name?: string
  resort: string
  kind: FeedingType
  start_by: FeedingStartBy
  guests: FeedingClientCalculateGuestPayload[]
  date_from: Date
  date_to: Date
  payment_method: FeedingClientPaymentMethod
  discount: FeedingClientDiscount
}

export interface FeedingClientCalculatePayload {
  name?: string
  resort: string
  kind: FeedingType
  start_by: FeedingStartBy
  guests: FeedingClientCalculateGuestPayload[]
  date_from: Date
  date_to: Date
  payment_method: FeedingClientPaymentMethod
  discount: FeedingClientDiscount
}

export interface FeedingClientCalculateResponse {
  total_brutto: number
  guests: {
    adult?: number
    children_5_12?: number
    children_3_4?: number
    baby?: number
  }
}

export const feedingClientCalculate = async (
  payload: FeedingClientCalculatePayload,
  cancelTokenSource: CancelTokenSource,
): Promise<FeedingClientCalculateResponse> => {
  const url = getState().appState.appData.urls.feeding.clients_calculate

  return await commonObjectPost<FeedingClientCalculateResponse>(
    url,
    {
      ...payload,
      name: null,
    },
    cancelTokenSource,
  )
}

export const fetchSpecialGuests = async (payload: FetchGuestsPayload): Promise<FeedingSpecialGuestEnter[]> =>
  await commonObjectGet<FeedingSpecialGuestEnter[]>(getState().appState.appData.urls.feeding.special_guests, payload)

export const fetchFeedingHostStats = async (payload: FetchGuestsPayload): Promise<FeedingHostStats> =>
  await commonObjectGet<FeedingHostStats>(getState().appState.appData.urls.feeding.stats, payload)

export const fetchFeedingHostOptions = async (payload: FetchGuestsPayload): Promise<FeedingHostOptions> =>
  await commonObjectGet<FeedingHostOptions>(getState().appState.appData.urls.feeding.apartments, payload)

interface FetchApartmentGuestsPayload extends FetchGuestsPayload {
  apartment: string
}

export const fetchFeedingApartmentOptions = async (
  payload: FetchApartmentGuestsPayload,
): Promise<FeedingApartmentOption[]> =>
  await commonObjectGet<FeedingApartmentOption[]>(getState().appState.appData.urls.feeding.search, payload)

export const fetchWorkerGuests = async (payload: FetchGuestsPayload): Promise<FeedingWorkerGuest[]> =>
  await commonObjectGet<FeedingWorkerGuest[]>(getState().appState.appData.urls.feeding.worker_guests, payload)

export interface FeedingEnterPayload {
  date: Date
  enter: boolean
  guest: number
  resort: number | string
  type: FeedingType
}

export const setFeedingEnter = async (enters: FeedingEnterPayload[]): Promise<FeedingEnter[]> => {
  const url = getState().appState.appData.urls.feeding.enter

  return await commonObjectPost<FeedingEnter[]>(url, {
    enters: enters.map(row => ({
      ...row,
      date: formatDate(row.date),
    })),
  })
}
