import * as React from 'react'
import { ColAuto } from '@hyper/col-auto'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { formatPrice } from '@helpers/utils'
import { UserPermission } from '@models/dashboard'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { Payment } from '@models/payments'
import { PackageVipDetails } from '@modules/promotions/package-vip/models'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { PackageVipPaymentsAdd } from '@modules/promotions/package-vip/details/payments/package-vip-payments-add'
import { PackageVipPaymentDelete } from '@modules/promotions/package-vip/details/payments/package-vip-payment-delete'
import { PackageVipPaymentsAddCode } from '@modules/promotions/package-vip/details/payments/package-vip-payments-add-code'

interface PackageVipPaymentsProps {
  packageVipDetails: PackageVipDetails
}

export const PackageVipPayments: React.FC<PackageVipPaymentsProps> = ({ packageVipDetails }) => {
  const user = useAuthenticatedUser()

  const canAddPayment =
    !packageVipDetails.is_removed &&
    !packageVipDetails.is_paid &&
    user.hasPerm(UserPermission.PromotionsPackageVipCanAddPayment)

  const canAddCode =
    !packageVipDetails.is_removed &&
    !packageVipDetails.is_paid &&
    user.hasPerm(UserPermission.PromotionsPackageVipCanAddCode)

  const canDeletePayment =
    !packageVipDetails.has_invoice &&
    user.hasPerm(UserPermission.PromotionsPackageVipCanDeletePayment) &&
    packageVipDetails.payment_method === 'transfer'

  return (
    <Card>
      <CardBody>
        <Row>
          <ColAuto>
            <h4 className="mt-0 text-secondary mb-3">Płatności</h4>
          </ColAuto>
          <ColAuto className="text-right ml-auto">
            {canAddPayment && <PackageVipPaymentsAdd />}
            {canAddCode && <PackageVipPaymentsAddCode />}
          </ColAuto>
        </Row>
        <Row>
          <Col md={12} className="mt-2">
            <table className="table table-centered table-sm table-hover table-striped">
              <thead className="thead-light">
                <tr>
                  <th>Data dodania</th>
                  <th>Kwota</th>
                  <th>Rodzaj płatności</th>
                  <th>Numer transakcji bankowej</th>
                  <th>Dodany przez</th>
                  <th style={{ width: 10 }} />
                </tr>
              </thead>
              <tbody>
                {!packageVipDetails.payments.length ? (
                  <tr>
                    <td colSpan={6} className="text-center">
                      Nie dodano żadnych wpłat
                    </td>
                  </tr>
                ) : (
                  packageVipDetails.payments.map((payment: Payment) => (
                    <tr key={payment.id}>
                      <td>{toDefaultDateFormat(payment.invoice_date)}</td>
                      <td>{formatPrice(payment.amount)}</td>
                      <td>{payment.source_display}</td>
                      <td>{payment.description}</td>
                      <td>{payment.user}</td>
                      <td>
                        {canDeletePayment && (
                          <PackageVipPaymentDelete packageVipDetails={packageVipDetails} payment={payment} />
                        )}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}
