import * as React from 'react'
import { PackageVip } from '@modules/promotions/package-vip/models'
import { useModal } from '@components/modals/use-modal'
import { formatPriceShort } from '@helpers/utils'
import { toDefaultDateFormat } from '@helpers/date-helper'
import Table from '@components/table/table'
import { TableSellerRow } from '@components/table-seller-row'
import { StatusBadge } from '@modules/promotions/common/status-badge'

interface PackageVipTableRowProps {
  packageVip: PackageVip
}

export const PackageVipTableRow: React.FC<PackageVipTableRowProps> = ({ packageVip }) => {
  const [showPackageDetailsModal] = useModal('PackageVipDetails', { packageVip })

  return (
    <Table.Row onClick={showPackageDetailsModal} className="cursor-pointer">
      <Table.Cell>{packageVip.number}</Table.Cell>
      <Table.Cell>{packageVip.client?.name}</Table.Cell>
      <Table.Cell>{packageVip.type_display}</Table.Cell>
      <Table.Cell>
        <StatusBadge element={packageVip} />
      </Table.Cell>
      <Table.Cell>{packageVip.payment_method_display}</Table.Cell>
      <Table.Cell>{formatPriceShort(packageVip.price_brutto)}</Table.Cell>
      <Table.Cell>{formatPriceShort(packageVip.required_payment)}</Table.Cell>
      <Table.Cell>{toDefaultDateFormat(packageVip.sell_date)}</Table.Cell>
      <Table.Cell>
        <TableSellerRow
          sellerId={packageVip.seller_id}
          defaultValue={packageVip.seller ? String(packageVip.seller) : ''}
          className="d-block"
        />
        <small className="font-10">{toDefaultDateFormat(packageVip.sell_date)}</small>
      </Table.Cell>
    </Table.Row>
  )
}
