import { IconWithTooltip } from '@components/icon-with-tooltip'
import * as React from 'react'
import { FormInput } from '@hyper/forms'
import { Col } from 'reactstrap'

export const PackageWholesaleCreateModalSlug = (): JSX.Element => (
  <Col md={6} className="d-flex align-items-center pl-0">
    <FormInput
      name="slug"
      placeholder="produkt-hpr"
      type="text"
      label={
        <span>
          Przyjazny link
          <PackageWholesaleSlugTooltip />
        </span>
      }
    />
  </Col>
)

export const PackageWholesaleSlugTooltip = () => (
  <IconWithTooltip
    icon="uil-exclamation-circle ml-1"
    tooltipId="slug-tooltip-id"
    tooltipPlacement="right"
    tooltipMessage={
      <div className="font-11 text-left py-1">
        <p className="mb-1">Adres pod jakim dostępna będzie strona sprzedażowa.</p>

        <p className="mb-0">
          Przykład: <br /> oferta.holidaypark.pl/<strong>produkt</strong>/{' '}
        </p>
      </div>
    }
    color="text-secondary"
  />
)
