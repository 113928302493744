import * as React from 'react'
import { FormCheckbox } from '@hyper/forms/form-checkbox'
import { useFormContext, useWatch } from 'react-hook-form'
import classNames from 'classnames'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'

export const TechnicalOrderAssignFormForwardToTechnicalOrderManager: React.FC = () => {
  const { control, setValue } = useFormContext()

  const users = useWatch({ control, name: 'users' })
  const hasUsers = Object.values(users).some(Boolean)

  const forwardedToTechnicalOrderManager = useWatch({ control, name: 'forwarded_to_technical_order_manager' })

  useDidUpdateEffect(() => {
    if (forwardedToTechnicalOrderManager) {
      setValue('forwarded_to_resort', false)
    }
  }, [forwardedToTechnicalOrderManager])

  return (
    <div className={classNames({ 'not-clickable': hasUsers })}>
      <div className="font-weight-bold mt-3 mb-1">Lub przekaz zadanie</div>
      <FormCheckbox
        uncheckedDefaultValue={false}
        disabled={hasUsers}
        name="forwarded_to_technical_order_manager"
        label="do Dyrektora Technicznego"
        value={true}
        className="mb-1"
      />
    </div>
  )
}
