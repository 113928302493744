import * as React from 'react'
import Table, { TableHeaderValue } from '@components/table/table'
import { CashRegister } from '@models/sale'
import { CashRegisterListTableRow } from '@modules/sale/cash-register/list/table-row'
import { CashRegisterListFilterParams } from '@modules/sale/cash-register/list/filter'
import { CashRegisterListTableSummaryRow } from './table-row-summary'

const headerValues: TableHeaderValue[] = [
  { title: 'Ośrodek' },
  { title: 'Typ' },
  { title: 'Dodane przez' },
  { title: 'Osoba/firma' },
  { title: 'Uwagi' },
  { title: 'Data' },
  { title: 'Kwota' },
  { title: '' },
]

interface Props {
  cashRegisters: CashRegister[]
  isLoading: boolean
  filters: CashRegisterListFilterParams
  setFilters: (filters: CashRegisterListFilterParams) => void
}

export const CashRegisterListTable: React.FC<Props> = ({ isLoading, cashRegisters, filters, setFilters }) => (
  <Table
    className="table-hover w-100"
    headerValues={headerValues}
    isLoading={isLoading}
    filters={filters}
    setFilters={setFilters}
  >
    {cashRegisters.map(cashRegister => (
      <CashRegisterListTableRow key={cashRegister.id} cashRegister={cashRegister} />
    ))}
    {!!cashRegisters.length && <CashRegisterListTableSummaryRow />}
  </Table>
)
