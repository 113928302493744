import { AsyncThunkParams } from '@store/index'
import { commonObjectGet } from '@store/actions/generic-actions'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProductsAppData } from '@models/products'

export const getProductsAppData = createAsyncThunk<ProductsAppData, void, AsyncThunkParams>(
  'products/getProductsAppData',
  async (_, { getState }) => {
    if (getState().productsState.appData.status === 'unknown') {
      return await commonObjectGet<ProductsAppData>(getState().appState.appData.urls.products.app_data)
    }
    return getState().productsState.appData
  },
)
