import * as React from 'react'
import { Button } from 'reactstrap'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { EmailTemplate } from '@modules/cms/models'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectPost } from '@store/actions/generic-actions'
import { languageType } from '@models/cms'

interface Props {
  emailTemplate: EmailTemplate
  language: languageType
}

export const NotificationTemplatesEmailModalTestButton = ({ emailTemplate, language }: Props): JSX.Element => {
  const { addSuccessNotification } = useNotificationHook()
  const user = useAuthenticatedUser()

  const { isLoading, action: handleOnClick } = useApiRequest(async () => {
    await commonObjectPost(emailTemplate.urls.test, { language })
    addSuccessNotification(`Testowa wiadomość została wysłana na adres: ${user.email}`)
  })

  return (
    <Button color="light" type="button" onClick={handleOnClick}>
      {isLoading ? 'Wysyłanie..' : 'Wyślij na e-mail'}
    </Button>
  )
}
