import * as React from 'react'
import { useAppDispatch, useAppSelector } from '@store/index'
import { selectIsDiscountPreviewActive, setIsDiscountPreviewActive } from '@store/slices/new-feeding-slice'

interface Response {
  isDiscountPreviewActive: boolean
}

export const useFeedingDiscountPreview = (): Response => {
  const isDiscountPreviewActive = useAppSelector(selectIsDiscountPreviewActive)

  const dispatch = useAppDispatch()

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Shift' && !event.repeat) {
        dispatch(setIsDiscountPreviewActive(true))
      }
    }

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === 'Shift' && !event.repeat) {
        dispatch(setIsDiscountPreviewActive(false))
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('keyup', handleKeyUp)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('keyup', handleKeyUp)
      dispatch(setIsDiscountPreviewActive(false))
    }
  }, [])

  return {
    isDiscountPreviewActive,
  }
}
