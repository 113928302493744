import * as React from 'react'
import { Input } from 'reactstrap'
import { InputProps } from 'reactstrap/es/Input'
import Spinner from '@hyper/spinner'
import classnames from 'classnames'

interface SpinnerProps {
  classNames: string
  color: string
}

interface InputWithLoader extends InputProps {
  isLoadingVisible: boolean
  spinnerProps?: SpinnerProps
}

export const InputWithLoader: React.FC<InputWithLoader> = ({ isLoadingVisible, spinnerProps, ...props }) => (
  <div
    className={classnames('input-with-loader__container', { 'input-with-loader--active': isLoadingVisible })}
    data-testid="input-with-loader-wrapper"
  >
    <Input {...props} className="input-with-loader" />
    <Spinner className="text-primary input-with-loader__spinner" size="sm" {...spinnerProps} />
  </div>
)
