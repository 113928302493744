import * as React from 'react'
import { PromocodeGroup } from '@models/promocode'
import Table, { TableHeaderValue } from '@components/table/table'
import {
  PromocodeGroupsTableFilters,
  PromocodeListFilterParams,
} from '@modules/promocode/promocode-list/promocode-groups/promocode-groups-table-filters'
import { useAppDispatch, useAppSelector } from '@store/index'
import { promocodeGroupsSelector } from '@store/slices/promocodes-slice'
import { getPromocodeGroups } from '@store/actions/promocode-actions'
import { extractSelectOptionsValues } from '@helpers/utils'
import { useTableFilters } from '@components/table/use-table-filters'
import { PromocodeGroupTableRow } from '@modules/promocode/promocode-list/promocode-groups/promocode-group-table-row'

const defaultFilters: PromocodeListFilterParams = {
  ordering: '-prefix',
  page: 1,
  page_size: 30,
  search: '',
  created_at_after: undefined,
  prefix: '',
  created_at_before: undefined,
  used_kind: '',
}

export const headerValues: TableHeaderValue[] = [
  { title: 'Nazwa' },
  { title: 'Wartość' },
  { title: 'Data utworzenia' },
  { title: 'Data ważności' },
  { title: '' },
]

export const PromocodeGroupsTable = () => {
  const dispatch = useAppDispatch()

  const groups = useAppSelector(promocodeGroupsSelector)

  const action = React.useCallback(
    async filters => await dispatch(getPromocodeGroups(extractSelectOptionsValues(filters))),
    [],
  )

  const { isLoading, filters, setFilters } = useTableFilters({ defaultFilters, action })

  React.useEffect(() => {
    action(defaultFilters)
  }, [])

  return (
    <>
      <PromocodeGroupsTableFilters filters={filters} setFilters={setFilters} defaultFilters={defaultFilters} />

      <Table
        className="table-hover w-100"
        headerValues={headerValues}
        isLoading={isLoading}
        filters={filters}
        setFilters={setFilters}
      >
        {groups.map((group: PromocodeGroup) => (
          <PromocodeGroupTableRow group={group} key={group.id} />
        ))}
      </Table>
    </>
  )
}
