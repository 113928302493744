import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { ReceptionBookingDetails } from '@models/reception'
import { FormProvider, useForm } from 'react-hook-form'
import { useAppDispatch } from '@store/index'
import { updateBookingApartmentMedia } from '@store/actions/reception-actions'
import { ReceptionMetersReadingRow } from '@modules/reception/reception-meters-reading-dialog/reception-meters-reading-row'
import { ReceptionBookingServiceRoomDataMeterKind } from '@models/booking'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useFormRequest } from '@components/hooks/use-api-request'
import { BaseModalProps } from '@components/modals/types'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'

interface ReceptionMetersReadingDialogProps extends BaseModalProps {
  booking: ReceptionBookingDetails
  allowMetersChange?: boolean
}

export interface ReceptionMetersReadingDialogFormInputs {
  water_start: string
  water_end: string
  power_start: string
  power_end: string
  hot_water_start: string
  hot_water_end: string
  heat_start: string
  heat_end: string
  water_meter_kind: ReceptionBookingServiceRoomDataMeterKind
  power_meter_kind: ReceptionBookingServiceRoomDataMeterKind
  hot_water_meter_kind: ReceptionBookingServiceRoomDataMeterKind
  heat_meter_kind: ReceptionBookingServiceRoomDataMeterKind
}

const defaultMeterKind = (
  meter_kind: ReceptionBookingServiceRoomDataMeterKind,
): ReceptionBookingServiceRoomDataMeterKind => {
  if (meter_kind === 'fake' || meter_kind === 'disable_fake') {
    return 'default'
  }
  return meter_kind
}

export const ReceptionMetersReadingDialog: React.FC<ReceptionMetersReadingDialogProps> = ({
  toggleIsVisible,
  booking,
  allowMetersChange,
}) => {
  const methods = useForm<ReceptionMetersReadingDialogFormInputs>({
    defaultValues: {
      ...booking.service_room_data,
      water_meter_kind: defaultMeterKind(booking.service_room_data.water_meter_kind),
      power_meter_kind: defaultMeterKind(booking.service_room_data.power_meter_kind),
      hot_water_meter_kind: defaultMeterKind(booking.service_room_data.hot_water_meter_kind),
      heat_meter_kind: defaultMeterKind(booking.service_room_data.heat_meter_kind),
    },
  })
  const { addSuccessNotification } = useNotificationHook()

  const dispatch = useAppDispatch()

  const { isLoading, action: onSubmit } = useFormRequest(async (reason: string) => {
    await dispatch(updateBookingApartmentMedia(booking.urls.media, { ...methods.getValues(), reason }))
    toggleIsVisible()
    addSuccessNotification('Odczyt został zapisany')
  }, methods.setError)

  return (
    <FormProvider {...methods}>
      <form className="reception__notification_sms_dialog">
        <ModalHeader toggle={toggleIsVisible}>Zmień odczyty liczników</ModalHeader>
        <ModalBody>
          <ReceptionMetersReadingRow title="Licznik wody" icon="uil-tear" unit="m³" booking={booking} prefix="water" />

          {booking.service_room_data.has_hot_water_meter && (
            <>
              <div className="hr hr-grey mb-2" />
              <ReceptionMetersReadingRow
                title="Licznik wody ciepłej"
                icon="uil-raindrops-alt"
                unit="m³"
                prefix="hot_water"
                booking={booking}
              />
            </>
          )}
          <div className="hr hr-grey mb-2" />

          <ReceptionMetersReadingRow
            title="Licznik prądu"
            icon="uil-bolt"
            unit="kWh"
            booking={booking}
            prefix="power"
          />

          <div className="hr hr-grey mb-2" />
          <ReceptionMetersReadingRow
            title="Licznik ogrzewania"
            icon="uil-temperature-half"
            unit="GJ"
            booking={booking}
            prefix="heat"
          />
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={toggleIsVisible}>
            Anuluj
          </Button>
          {allowMetersChange && (
            <CommonObjectConfirmAction
              title="Potwierdź zmianę odczytów"
              handleAccept={reason => onSubmit(reason)}
              askForReason={true}
              isLoading={isLoading}
            >
              <SaveButton type="button" isSaving={isLoading} className="btn btn-green" />
            </CommonObjectConfirmAction>
          )}
        </ModalFooter>
      </form>
    </FormProvider>
  )
}
