export const touristVoucherCodeMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]
export const touristVoucherPaymentCodeMask = [
  /[A-z0-9]/,
  /[A-z0-9]/,
  /[A-z0-9]/,
  /[A-z0-9]/,
  '-',
  /[A-z0-9]/,
  /[A-z0-9]/,
  /[A-z0-9]/,
  /[A-z0-9]/,
  '-',
  /[A-z0-9]/,
  /[A-z0-9]/,
  /[A-z0-9]/,
  /[A-z0-9]/,
  '-',
  /[A-z0-9]/,
  /[A-z0-9]/,
  /[A-z0-9]/,
  /[A-z0-9]/,
]
