import * as React from 'react'
import { NavigationTabs, NavigationTabsButton } from '@components/navigation-tabs'
import { ScrollableModalTab, useModalWithScrollableTabs } from '@components/hooks/use-modal-with-scrollable-tabs'

type Tab = 'data' | 'finance' | 'address' | 'position' | 'annexes'

interface Props {
  dataRef: React.RefObject<HTMLElement>
  financeRef: React.RefObject<HTMLElement>
  addressRef: React.RefObject<HTMLElement>
  positionRef: React.RefObject<HTMLElement>
  annexesRef: React.RefObject<HTMLElement>
}

export const AgreementDetailsModalTabs: React.FC<Props> = ({
  dataRef,
  financeRef,
  addressRef,
  positionRef,
  annexesRef,
}) => {
  const [tab, setTab] = React.useState<Tab>('data')

  const tabs: ScrollableModalTab<Tab>[] = [
    { ref: dataRef, name: 'data' },
    { ref: financeRef, name: 'finance' },
    { ref: addressRef, name: 'address' },
    { ref: positionRef, name: 'position' },
    { ref: annexesRef, name: 'annexes' },
  ]

  const { handleTabClick } = useModalWithScrollableTabs<Tab>({ modalId: 'agreement-modal', setTab, tabs })

  const buttons: NavigationTabsButton[] = [
    {
      title: 'Dane pracownika',
      isActive: tab == 'data',
      onClick: handleTabClick('data'),
    },
    {
      title: 'Dane stanowiska',
      isActive: tab == 'position',
      onClick: handleTabClick('position'),
    },
    {
      title: 'Dane adresowe',
      isActive: tab == 'address',
      onClick: handleTabClick('address'),
    },
    {
      title: 'Dane finansowe',
      isActive: tab == 'finance',
      onClick: handleTabClick('finance'),
    },
    { title: 'Aneksy', isActive: tab == 'annexes', onClick: handleTabClick('annexes') },
  ]

  return <NavigationTabs buttons={buttons} />
}
