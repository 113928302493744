import * as React from 'react'
import { Col, CustomInput, Row } from 'reactstrap'
import { FormInput, FormPlain } from '@hyper/forms'
import { extractInnerRef } from '@helpers/forms'
import { useFormContext } from 'react-hook-form'
import { NotificationTemplatesEmailFormInputs } from '@modules/cms/notification-templates/email/modal/index'
import { NavigationTabs, NavigationTabsButton } from '@components/navigation-tabs'
import { EmailTemplate } from '@modules/cms/models'
import { NotificationVariables } from '@modules/cms/notification-templates/variables'
import { languageType } from '@models/cms'

interface Props {
  emailTemplate: EmailTemplate
  language: languageType
  onLanguageChange: (language: languageType) => void
}

export const NotificationTemplatesEmailModalForm = ({
  emailTemplate,
  language,
  onLanguageChange,
}: Props): JSX.Element => {
  const { register } = useFormContext<NotificationTemplatesEmailFormInputs>()

  const buttons: NavigationTabsButton[] = [
    {
      title: 'Polski',
      isActive: language === 'pl',
      onClick: () => onLanguageChange('pl'),
    },
    {
      title: 'Angielski',
      isActive: language === 'en',
      onClick: () => onLanguageChange('en'),
    },
    {
      title: 'Niemiecki',
      isActive: language === 'de',
      onClick: () => onLanguageChange('de'),
    },
  ]

  return (
    <Row>
      <FormInput label="Nazwa" name="name" />
      <FormInput label="Klucz" name="key" />
      <Col md={12}>
        <NavigationTabs buttons={buttons} />
      </Col>
      {language === 'pl' && (
        <>
          <FormInput label="Tytuł (Polski)" name="subject_pl" />
          <FormInput label="Treść (Polski)" name="content_pl" type="textarea" inputProps={{ rows: 10 }} />
        </>
      )}
      {language === 'en' && (
        <>
          <FormInput label="Tytuł (Angielski)" name="subject_en" />
          <FormInput label="Treść (Angielski)" name="content_en" type="textarea" inputProps={{ rows: 10 }} />
        </>
      )}
      {language === 'de' && (
        <>
          <FormInput label="Tytuł (Niemiecki)" name="subject_de" />
          <FormInput label="Treść (Niemiecki)" name="content_de" type="textarea" inputProps={{ rows: 10 }} />
        </>
      )}
      <NotificationVariables context={emailTemplate.context} />

      <FormPlain name="is_active" colClassName="mb-2">
        <CustomInput
          className="mt-2"
          type="checkbox"
          {...extractInnerRef(register('is_active'))}
          id="is_active"
          label="Powiadomienie aktywne?"
        />
      </FormPlain>
    </Row>
  )
}
