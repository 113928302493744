import * as React from 'react'
import { Button, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { SaveButton } from '@hyper/button'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useForm } from 'react-hook-form'
import { createReportTask } from '@store/actions/report-tasks-actions'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { cashRegisterTypes } from '@modules/sale/cash-register/list/filter'
import { BaseModalProps } from '@components/modals/types'
import { Form } from '@hyper/forms/form'
import { FormSelect } from '@hyper/forms/form-select'

interface Props extends BaseModalProps {
  filters: FormInputs
}

interface FormInputs {
  created_after: Date | null
  created_before: Date | null
  type: CustomReactSelectOption | undefined

  payment_date_after: Date | null
  payment_date_before: Date | null
  is_removed: boolean
  source_marketing: CustomReactSelectOption | undefined
  seller: CustomReactSelectOption | undefined
  resort: CustomReactSelectOption | undefined
  company: CustomReactSelectOption | undefined
  user: CustomReactSelectOption | undefined
}

export const ReportsGeneratorCashRegister: React.FC<Props> = ({ toggleIsVisible, filters }) => {
  const reportUrl = useAppSelector((state: RootState) => state.appState.appData.urls.reports.report_task_gastro_card)
  const dispatch = useAppDispatch()

  const methods = useForm<FormInputs>({
    mode: 'all',
    defaultValues: { ...filters },
  })

  const { isLoading, action: onSubmit } = useReportFormRequest(async (payload: FormInputs) => {
    const reportTask = await dispatch(
      createReportTask([
        reportUrl,
        {
          ...payload,
          type: payload.seller?.value,
          resort: payload.resort?.value,
          company: payload.company?.value,
          user: payload.user?.value,
        },
      ]),
    )
    toggleIsVisible()
    return reportTask
  }, methods.setError)

  const resorts = useAuthenticatedUser().resorts.map(({ id, name }) => ({
    value: id,
    label: name,
  }))

  const users = useAppSelector((state: RootState) => state.saleState.cashRegistersExtraData.users).map(
    ({ id, value }) => ({
      value: id,
      label: value,
    }),
  )
  const companies = useAppSelector((state: RootState) => state.saleState.cashRegistersExtraData.company).map(
    ({ id, value }) => ({
      value: id,
      label: value,
    }),
  )

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Raport kasowy</ModalHeader>
      <ModalBody>
        <Row>
          <FormSelect options={resorts} name="resort" label="Ośrodek" selectProps={{ isClearable: true }} />
          <FormSelect options={cashRegisterTypes} name="type" label="Typ" selectProps={{ isClearable: true }} />
          <FormSelect options={users} name="user" label="Dodane przez" selectProps={{ isClearable: true }} />
          <FormSelect options={companies} name="company" label="Osoba/firma" selectProps={{ isClearable: true }} />
          <DateFromToInput label="Data" startDateName="created_after" endDateName="created_before" />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" label="Zleć raport" labelSaving="Zlecanie.." isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
