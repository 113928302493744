import React from 'react'

export const useUpsellingProducts = <T extends { status: string }>(
  products: T[],
  statuses: string[],
  field: keyof T,
  fieldValue: string,
): T[] =>
  React.useMemo(
    () =>
      products.reduce((acc, product) => {
        if (product[field] !== fieldValue) return acc
        if (statuses.includes(product.status)) return [...acc, product]
        return acc
      }, []),

    [products, statuses, field, fieldValue],
  )
