import { AsyncThunkParams } from '@store/index'
import { commonObjectGet } from '@store/actions/generic-actions'
import { setDashboardStandardPaginator } from '@store/actions/dashboard-actions'
import {
  Subscription,
  SubscriptionAggregation,
  SubscriptionProduct,
  SubscriptionType,
} from '@modules/subscription/models'
import { SubscriptionListFilterParams } from '@modules/subscription/list/filter'
import { ListDataWithAggregation, PaginationResponse, PaginationResponseWithAggregation } from '@models/dashboard'
import { SubscriptionProductListFilterParams } from '@modules/subscription/products/list/filter'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { SubscriptionAppData } from '@store/slices/subscription-slice'
import { extractSelectOptionsValues } from '@helpers/utils'

export const getSubscriptions = createAsyncThunk<
  ListDataWithAggregation<Subscription[], SubscriptionAggregation>,
  [SubscriptionListFilterParams, SubscriptionType],
  AsyncThunkParams
>('subscriptions/getSubscriptions', async ([filters, type], { getState, dispatch }) => {
  const url = getState().appState.appData.urls.subscription.subscriptions.replace('replace-me', type)

  const data = await commonObjectGet<PaginationResponseWithAggregation<Subscription, SubscriptionAggregation>>(
    url,
    extractSelectOptionsValues(filters),
  )

  dispatch(setDashboardStandardPaginator(data))
  return { results: data.results, aggregation: data.aggregation }
})

export const getSubscriptionAppData = createAsyncThunk<SubscriptionAppData, void, AsyncThunkParams>(
  'subscriptions/getSubscriptionAppData',
  async (_, { getState }) => {
    const appData = getState().subscriptionState.appData

    if (appData.status === 'unknown') {
      return await commonObjectGet<SubscriptionAppData>(
        getState().appState.appData.urls.subscription.subscription_app_data,
      )
    }
    return appData
  },
)

export const getSubscriptionProducts = createAsyncThunk<
  SubscriptionProduct[],
  [SubscriptionProductListFilterParams, SubscriptionType],
  AsyncThunkParams
>('subscriptions/getSubscriptionProducts', async ([filters, type], { getState, dispatch }) => {
  const url = getState().appState.appData.urls.subscription.subscription_products.replace('replace-me', type)

  const data = await commonObjectGet<PaginationResponse<SubscriptionProduct>>(url, extractSelectOptionsValues(filters))

  dispatch(setDashboardStandardPaginator(data))
  return data.results
})
