import * as React from 'react'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useAppDispatch } from '@store/index'
import { Asset } from '@models/cms-asset'
import { removeAsset } from '@store/actions/asset-actions'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'

interface Props {
  asset: Asset
}

export const CmsAssetsTableRowRemove = ({ asset }: Props): JSX.Element => {
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading, action: onAccept } = useApiRequest(
    React.useCallback(async () => {
      dispatch(removeAsset(asset))
      addSuccessMessage('Sukces', 'Asset został skasowany')
    }, [asset]),
  )

  return (
    <CommonObjectConfirmAction isLoading={isLoading} handleAccept={onAccept}>
      <i className="uil-trash-alt cursor-pointer font-18 text-muted" title="Skasuj" />
    </CommonObjectConfirmAction>
  )
}
