import * as React from 'react'
import { formatPriceShort } from '@helpers/utils'
import { toDefaultDateFormat } from '@helpers/date-helper'
import Table from '@components/table/table'
import { TableSellerRow } from '@components/table-seller-row'
import { BookingOption } from '@modules/promotions/booking-options/model'
import { BadgeWithIcon } from '@components/badges/BadgeWithIcon'
import { useGetAvailableClientOptionsQuery } from '@api/booking-options'
import { TypedQueryResult } from '@api/base'
import { ClientOptionPrice } from '@models/clients'
import { StatusBadge } from '@modules/promotions/common/status-badge'
import { bruttoToNetto } from '@helpers/price'
import { NavigationPath } from '@models/routes'
import { useDetailsModal } from '@components/hooks/use-details-modal'

interface Props {
  hideClient?: boolean
  option: BookingOption
}

const emptyList = []

export const BookingOptionsTableRow: React.FC<Props> = ({ option, hideClient }) => {
  const { handleClientDetails } = useDetailsModal(
    ['BookingOptionDetailsDialog', { option }],
    NavigationPath.BookingOptions,
    NavigationPath.BookingOptionsWithParams,
    option.id,
  )

  const { data: optionKinds = emptyList } = useGetAvailableClientOptionsQuery() as TypedQueryResult<ClientOptionPrice[]>

  const addedOptions = React.useMemo(
    () =>
      optionKinds.length ? option.option_kinds.map(optionKind => optionKinds.find(el => optionKind === el.kind)) : [],
    [optionKinds, option],
  )

  return (
    <Table.Row onDoubleClick={handleClientDetails} className="cursor-pointer">
      <Table.Cell>{option.invoice?.reservation_number || 'brak'}</Table.Cell>
      {!hideClient && <Table.Cell>{option.client.name}</Table.Cell>}
      <Table.Cell>
        <StatusBadge element={option} />
      </Table.Cell>
      <Table.Cell>{formatPriceShort(bruttoToNetto(option.price_brutto, 23))}</Table.Cell>
      <Table.Cell>{formatPriceShort(option.price_brutto)}</Table.Cell>
      <Table.Cell>
        {addedOptions.map((option: ClientOptionPrice) => (
          <div key={option.id} style={{ margin: 3 }}>
            <BadgeWithIcon icon="uil-check-circle" variant="success" title={option.kind_display} />
          </div>
        ))}
      </Table.Cell>
      <Table.Cell>{option.created_by}</Table.Cell>
      <Table.Cell>
        <TableSellerRow sellerId={option.seller_id} className="d-block" />
        <small className="font-10">{toDefaultDateFormat(option.created)}</small>
      </Table.Cell>
    </Table.Row>
  )
}
