import * as React from 'react'
import { SubscriptionDetails } from '@modules/subscription/models'
import { SubscriptionDetailsInformationRow } from '@modules/subscription/details/information/row'
import { Card, CardBody } from 'reactstrap'
import { NavigationPath } from '@models/routes'
import { Link } from 'react-router-dom'

interface Props {
  subscriptionDetails: SubscriptionDetails
}

export const SubscriptionDetailsInformationClient: React.FC<Props> = ({ subscriptionDetails }) => (
  <Card>
    <CardBody>
      <h4 className="mt-0 text-secondary mb-3">Dane klienta</h4>
      <table className="table table-sm mb-0">
        <tbody>
          <SubscriptionDetailsInformationRow name="Imię i Nazwisko">
            {subscriptionDetails.client_id && !subscriptionDetails.read_only ? (
              <Link
                className="text-primary text-semi-strong"
                to={NavigationPath.CrmClientsWitParams.replace(':client_id', String(subscriptionDetails.client_id))}
              >
                {subscriptionDetails.name}
              </Link>
            ) : (
              subscriptionDetails.name
            )}
          </SubscriptionDetailsInformationRow>
          <SubscriptionDetailsInformationRow name="Adres e-mail">
            {subscriptionDetails.email}
          </SubscriptionDetailsInformationRow>
          <SubscriptionDetailsInformationRow name="Numer telefonu">
            {subscriptionDetails.phone}
          </SubscriptionDetailsInformationRow>
          <SubscriptionDetailsInformationRow name="Miasto">
            <>
              {subscriptionDetails.city} {subscriptionDetails.postcode}
            </>
          </SubscriptionDetailsInformationRow>
          <SubscriptionDetailsInformationRow name="Ulica">
            {subscriptionDetails.street}
          </SubscriptionDetailsInformationRow>
        </tbody>
      </table>
      {subscriptionDetails.invoice_nip && (
        <>
          <h5>Dane faktury</h5>
          <table className="table table-sm mb-0">
            <tbody>
              <SubscriptionDetailsInformationRow
                name={subscriptionDetails.invoice_type === 'company' ? 'Nazwa firmy' : 'Imię i nazwisko'}
              >
                {subscriptionDetails.invoice_company}
              </SubscriptionDetailsInformationRow>
              <SubscriptionDetailsInformationRow name="Adres">
                {subscriptionDetails.invoice_street}
              </SubscriptionDetailsInformationRow>
              <SubscriptionDetailsInformationRow name="Kod pocztowy">
                {subscriptionDetails.invoice_postcode}
              </SubscriptionDetailsInformationRow>
              <SubscriptionDetailsInformationRow name="Miasto">
                {subscriptionDetails.invoice_city}
              </SubscriptionDetailsInformationRow>
              <SubscriptionDetailsInformationRow
                name={subscriptionDetails.invoice_type === 'company' ? 'NIP' : 'PESEL'}
              >
                {subscriptionDetails.invoice_nip}
              </SubscriptionDetailsInformationRow>
            </tbody>
          </table>
        </>
      )}
    </CardBody>
  </Card>
)
