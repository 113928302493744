import * as React from 'react'
import { Col, Row, Spinner } from 'reactstrap'
import { ClientUser } from '@models/clients'
import classnames from 'classnames'
import { BadgeWithIcon } from '@components/badges/BadgeWithIcon'

export type AdditionalDetails = 'subscription_contract' | 'address'

export type ClientBaseData = Partial<Pick<ClientUser, 'name' | 'email' | 'phone' | 'profile'>>

interface Props {
  client: ClientBaseData
  onShowClientEditModal?: () => void
  className?: string
  hasActiveSubscription?: boolean
  isFetchingClientDetails?: boolean
  additionalDetails?: AdditionalDetails[]
  children?: React.ReactNode
}

export const ClientBaseData = ({
  client,
  onShowClientEditModal,
  className,
  hasActiveSubscription,
  isFetchingClientDetails,
  additionalDetails = ['subscription_contract'],
  children,
}: Props): JSX.Element => {
  const withAdditional = (element: AdditionalDetails) => additionalDetails.includes(element)

  return (
    <Row className={classnames('mt-2', className)}>
      <DataCol title="Imię i nazwisko:" value={client.name} />
      <DataCol title="E-mail:" value={client.email} />
      <DataCol
        title="Numer telefonu:"
        value={client.phone || <PhoneDataValue onPhoneChange={onShowClientEditModal} />}
      />

      {withAdditional('subscription_contract') && (
        <DataCol
          title="Status subskrypcji:"
          value={
            isFetchingClientDetails ? (
              <div className="d-flex align-items-center">
                <Spinner color="warning" className="text-primary" size="sm" />
                <span className="ml-1 font-11">Pobieranie...</span>
              </div>
            ) : (
              <BadgeWithIcon
                icon={hasActiveSubscription ? 'mdi-check mdi' : 'mdi-close mdi'}
                title={hasActiveSubscription ? 'aktywna' : 'brak'}
                variant={hasActiveSubscription ? 'success' : 'danger'}
                className="px-1"
              />
            )
          }
        />
      )}

      {withAdditional('address') && client.profile && (
        <DataCol
          title="Adres zamieszkania:"
          value={
            <span>
              {client.profile?.street} <br /> {client.profile?.postcode} {client.profile?.city},{' '}
              {client.profile?.country_display}
            </span>
          }
        />
      )}
      {children}
    </Row>
  )
}

interface DataColProps {
  title: string
  value: React.ReactNode
}

const DataCol = ({ title, value }: DataColProps) => (
  <>
    <Col md={5} className="text-semi-strong mb-1">
      {title}
    </Col>
    <Col md={7}>{value ?? 'brak'}</Col>
  </>
)

interface PhoneDataValueProps {
  onPhoneChange?: () => void
}

const PhoneDataValue = ({ onPhoneChange }: PhoneDataValueProps): JSX.Element => (
  <span>
    brak -{' '}
    <span className="text-primary fw-semi-bold cursor-pointer" onClick={onPhoneChange}>
      ustaw
    </span>
  </span>
)
