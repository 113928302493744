import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { differenceInDays } from 'date-fns'
import { parseISODate } from '@helpers/date-helper'
import { useExtendedStay } from '@modules/reception/to-checkout/row/use-extended-stay'
import classnames from 'classnames'
import declination from '@helpers/declination'
import { useModal } from '@components/modals/use-modal'

interface Props {
  booking: ReceptionBookingDetails
}

export const ReservationPeriodRow: React.FC<Props> = ({ booking }) => {
  const { canExtend, showExtensionStayModal } = useExtendedStay(booking)

  const [handleChangeDate] = useModal('ReservationChangeDatesDialog', {
    booking,
  })

  const numberOfNights = differenceInDays(
    parseISODate(booking.date_to) as Date,
    parseISODate(booking.date_from) as Date,
  )

  return (
    <>
      <span className="font-weight-bold">{`${booking.date_from} - ${booking.date_to}`}</span>
      <span className="mr-1 ml-1">{`(${numberOfNights} ${declination.night(numberOfNights)})`}</span>
      <i className="uil-calendar-alt text-secondary cursor-pointer" onClick={handleChangeDate} />
      <i
        className={classnames('uil-clock text-secondary font-14 ml-1 cursor-pointer', { disabled: !canExtend })}
        onClick={showExtensionStayModal}
      />
    </>
  )
}
