import * as React from 'react'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { EndlessHolidayTransactionsTableRow } from '@modules/endless-holiday/transactions/table-row'
import { ProductPackage, ProductPackageAggregation } from '@modules/endless-holiday/models'
import { EndlessHolidayTransactionFormInputs } from '@modules/endless-holiday/transactions/index'
import { EndlessHolidayTransactionsTableFooter } from '@modules/endless-holiday/transactions/table-footer'
import { ProductPackageSet } from '@models/products'

const headerValues: TableHeaderValue[] = [
  { title: 'Lp.' },
  { sortField: 'name', title: 'Numer' },
  { title: 'Klient' },
  { title: 'Status' },
  { title: 'Zestaw' },
  { title: 'Kwota brutto (pobyty)' },
  { title: 'Kwota brutto (kaucja)' },
  { title: 'Kwota brutto (VG)' },
  { title: 'Sprzedawca' },
  { title: 'Źródło' },
  { title: 'Data utworzenia' },
  { title: '' },
]

interface Props {
  transactions: ProductPackage[]
  setFilters: (filters: EndlessHolidayTransactionFormInputs) => void
  filters: TableFilters
  isLoading: boolean
  aggregation: ProductPackageAggregation | undefined
  productPackageSets: ProductPackageSet[]
}

export const EndlessHolidayTransactionsTable: React.FC<Props> = ({
  setFilters,
  transactions,
  filters,
  isLoading,
  aggregation,
  productPackageSets,
}) => (
  <Table
    className="table-hover w-100"
    headerValues={headerValues}
    isLoading={isLoading}
    filters={filters}
    setFilters={setFilters}
  >
    {transactions.map((transaction: ProductPackage, index: number) => (
      <EndlessHolidayTransactionsTableRow
        key={transaction.id}
        transaction={transaction}
        index={index}
        productPackageSets={productPackageSets}
      />
    ))}
    {!!transactions.length && aggregation && <EndlessHolidayTransactionsTableFooter aggregation={aggregation} />}
  </Table>
)
