import * as React from 'react'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'

interface Props {
  ordered_by: string
  ordered_at?: string
}

export const HousekeepingCleaningOrderOrderedByRow = React.memo(({ ordered_by, ordered_at }: Props) => (
  <td className="housekeeping__orders_table__column">
    {ordered_by}
    <div className="font-12">{ordered_at && `(${toDefaultDateTimeFormat(ordered_at)})`}</div>
  </td>
))
