import { RefundBookingsFiltersParams } from '@modules/accounting/refund/bookings'
import axios from 'axios'
import { PaginationResponse } from '@models/dashboard'
import { BookingRefund, TransferPackage } from '@modules/accounting/refund/models'
import { TransferPackagesFiltersParams } from '@modules/accounting/refund/transfer-packages'

export async function fetchBookingRefunds(
  url: string,
  params: RefundBookingsFiltersParams,
): Promise<PaginationResponse<BookingRefund>> {
  const filters = {
    ...params,
  }
  const { data } = await axios.get(url, {
    params: filters,
  })

  return data
}

export async function fetchTransferPackages(
  url: string,
  params: TransferPackagesFiltersParams,
): Promise<PaginationResponse<TransferPackage>> {
  const filters = {
    ...params,
  }
  const { data } = await axios.get(url, {
    params: filters,
  })

  return data
}
