import * as React from 'react'
import { ButtonWithIcon } from '@components/button-with-icon'
import { useModal } from '@components/modals/use-modal'
import { useProductSaleConfirmationModal } from '@components/sale/use-product-sale-confirmation-modal'
import { Link } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { Button } from 'reactstrap'

export const EndlessHolidayTransactionActions = (): React.ReactNode => {
  const { showConfirmationPackageCreation } = useProductSaleConfirmationModal()

  const [showAgreementCreationModal] = useModal('EndlessHolidayCreateModal', {
    onCreate: () => showConfirmationPackageCreation(handleShowAddAgreementModal),
  })

  const handleShowAddAgreementModal = () => {
    showAgreementCreationModal(null)
  }

  return (
    <div style={{ bottom: 20, right: 0, position: 'absolute' }} className="d-flex ">
      <Link to={NavigationPath.SubscriptionProductPackageList} className="mr-2">
        <Button color="light">Pakiety pobytowe</Button>
      </Link>
      <ButtonWithIcon
        icon="uil-plus lh-0"
        text="Dodaj"
        handleClick={showAgreementCreationModal}
        color="green-dark"
        btnClass="btn-tall"
      />
    </div>
  )
}
