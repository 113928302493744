import { useNotificationHook } from '@hyper/use-notification-hook'
import { useForm } from 'react-hook-form'
import { useFormRequest } from '@components/hooks/use-api-request'
import { saveNote } from '@store/actions/dashboard-actions'
import * as React from 'react'
import { useAppData } from '@components/hooks/use-app-data'
import { ButtonWithIcon } from '@components/button-with-icon'
import { useToggle } from '@components/hooks/use-toggle'
import { ContentNote } from '@models/dashboard'
import { ContentNoteForm } from '@components/content-notes/content-note-form'
import { Form } from '@hyper/forms/form'

interface FormInputs {
  content: string
}

interface Props {
  objectId: number
  contentType: number
  noteToEdit: ContentNote | null
  onClearNoteToEdit: () => void
  onNotesUpdate: (note: ContentNote) => void
}

export const ContentNoteAdd = ({ objectId, contentType, onClearNoteToEdit, onNotesUpdate }: Props) => {
  const [isInputVisible, toggleInputVisible] = useToggle(false)
  const { urls } = useAppData()
  const { addSuccessMessage } = useNotificationHook()

  const methods = useForm<FormInputs>({
    defaultValues: { content: '' },
  })

  const { action: onSubmit, isLoading } = useFormRequest(async (payload: FormInputs) => {
    const note = await saveNote(urls.dashboard.content_note_create, contentType, objectId, payload.content)
    addSuccessMessage('Sukces', 'Notatka została dodana!')
    onNotesUpdate(note)

    toggleInputVisible()
    methods.reset()
  }, methods.setError)

  const handleAbort = () => {
    methods.reset()
    onClearNoteToEdit()
    toggleInputVisible()
  }

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      {isInputVisible ? (
        <ContentNoteForm
          submitText="Dodaj notatkę"
          submitIcon="uil-plus font-12"
          submitColor="outline-light"
          onAbort={handleAbort}
          isLoading={isLoading}
        />
      ) : (
        <ButtonWithIcon
          icon="uil-file-check-alt text-muted font-14 lh-0"
          text="Dodaj notatkę"
          color="transparent"
          btnClass="d-flex border rounded w-100 text-muted font-weight-normal font-12 px-1"
          handleClick={toggleInputVisible}
        />
      )}
    </Form>
  )
}
