import * as React from 'react'
import Table from '@components/table/table'
import { toDefaultDateTimeFormatWithBrake } from '@helpers/date-helper'
import { truncateText } from '@helpers/text'
import { FaultOrderCellExecutor } from '@modules/housekeeping/applications/fault/table-executor'
import { FaultOrderCellActions } from '@modules/housekeeping/applications/fault/table-actions'
import { FaultOrderDetails } from '@modules/housekeeping/applications/models'
import classNames from 'classnames'
import { FaultOrderExecutor } from '@modules/housekeeping/models'

interface Props {
  faultOrder: FaultOrderDetails
  faultOrderExecutors: FaultOrderExecutor[]
}

export const HousekeepingApplicationFaultsTableRow = ({ faultOrder, faultOrderExecutors }: Props): JSX.Element => (
  <Table.Row key={faultOrder.id}>
    <Table.Cell>{faultOrder.number}</Table.Cell>
    <Table.Cell>{toDefaultDateTimeFormatWithBrake(faultOrder.created)}</Table.Cell>
    <Table.Cell className="text-strong">{faultOrder.apartment?.name || 'Część wspólna'}</Table.Cell>
    <Table.Cell>{faultOrder.created_by}</Table.Cell>
    <Table.Cell title={faultOrder.name}>{truncateText(faultOrder.name, 20)}</Table.Cell>
    <Table.Cell>
      <span
        className={classNames('badge', {
          'badge-dark': faultOrder.status === 'close',
          'badge-success': faultOrder.status === 'open',
        })}
      >
        {faultOrder.status_display}
      </span>
    </Table.Cell>
    <Table.Cell>
      {faultOrder.status === 'close'
        ? faultOrder.executor_display
        : faultOrderExecutors.length && (
            <FaultOrderCellExecutor faultOrderExecutors={faultOrderExecutors} faultOrder={faultOrder} />
          )}
    </Table.Cell>
    <Table.Cell>{faultOrder.finished_at ? toDefaultDateTimeFormatWithBrake(faultOrder.finished_at) : ''}</Table.Cell>
    <Table.Cell className="text-nowrap text-right font-16">
      <FaultOrderCellActions faultOrder={faultOrder} />
    </Table.Cell>
  </Table.Row>
)
