import * as React from 'react'
import { SpinInput } from '@components/spin-input'
import { Col, Label, Row } from 'reactstrap'
import { FormPlain } from '@hyper/forms'
import { useFormContext, useWatch } from 'react-hook-form'
import { ReservationBlockadeFormInputs } from '@modules/reservations/blockade/reservation-blockade-modal'
import { AccommodationType } from '@models/booking'
import { createSelectOption } from '@helpers/utils'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { useAvailableApartments } from '@modules/reservations/use-available-apartments'
import { formatDate } from '@helpers/date-helper'
import { FormSelect } from '@hyper/forms/form-select'
import {
  ACCOMMODATION_TYPES,
  HOUSE_85_ACCOMMODATION_TYPE,
  HOUSE_85_WITH_GARDEN_ACCOMMODATION_TYPE,
} from '@helpers/consts'

interface Props {
  accommodationTypes: AccommodationType[]
}

export const ReservationBlockadeViaDatesEmptyOption = ({ accommodationTypes }: Props): JSX.Element => {
  const methods = useFormContext<ReservationBlockadeFormInputs>()

  const { apartments, fetchApartments, isLoading } = useAvailableApartments()

  const [dateFrom, dateTo, resortId, accommodationId] = useWatch({
    control: methods.control,
    name: ['date_from', 'date_to', 'resort.value', 'accommodation_type.value'],
  })

  const accommodationTypeOptions = accommodationTypes.map((accommodationType: AccommodationType) =>
    createSelectOption(accommodationType.name, accommodationType.id),
  )

  const availableApartmentsAmount = React.useMemo(
    () =>
      apartments.filter(apartment => {
        if (!apartment.is_available) return false

        if (
          ACCOMMODATION_TYPES.HOUSES.includes(apartment.accommodation_type_id) &&
          ACCOMMODATION_TYPES.HOUSES.includes(accommodationId)
        ) {
          if (accommodationId === HOUSE_85_WITH_GARDEN_ACCOMMODATION_TYPE) {
            return apartment.has_garden_fixed
          }

          if (accommodationId === HOUSE_85_ACCOMMODATION_TYPE) {
            return !apartment.has_garden_fixed
          }
        }

        return apartment.accommodation_type_id === accommodationId
      }).length,
    [apartments, accommodationId],
  )

  const isFormFilled = [resortId, accommodationId, dateFrom, dateTo].every(Boolean)

  useDidUpdateEffect(() => {
    methods.setValue('accommodation_type', null)
    methods.resetField('apartments_amount')
  }, [formatDate(dateFrom), formatDate(dateTo), resortId])

  useDidUpdateEffect(() => {
    methods.resetField('apartments_amount')
  }, [accommodationId])

  React.useEffect(() => {
    fetchApartments({ resort: resortId, date_from: dateFrom, date_to: dateTo, accommodation_type: accommodationId })
  }, [resortId, accommodationId, dateFrom, dateTo])

  return (
    <Row>
      <FormSelect
        name="accommodation_type"
        options={accommodationTypeOptions}
        formPlainProps={{ colSize: 6 }}
        selectProps={{ placeholder: 'wybierz' }}
        labelProps={{ className: 'w-100' }}
        label={
          <div className="d-flex justify-content-between">
            <span>Typ lokalu:</span>
            {!!availableApartmentsAmount && !isLoading && (
              <span className="font-10 font-weight-light">dostępnych lokali: {availableApartmentsAmount}</span>
            )}
          </div>
        }
      />

      <FormPlain name="apartments_amount" colSize={4} formGroupClassName="mb-0">
        <Label className="d-block" for="apartments_amount">
          Ilość lokali:
        </Label>
        <SpinInput
          inputName="apartments_amount"
          inputProps={{
            min: 1,
            max: availableApartmentsAmount,
            disabled: !availableApartmentsAmount,
          }}
        />
      </FormPlain>
      {availableApartmentsAmount === 0 && isFormFilled && (
        <Col className="mt-2" md={12}>
          Brak dostępnych lokali do zablokowania.
        </Col>
      )}
    </Row>
  )
}
