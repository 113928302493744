import * as React from 'react'
import { formatPrice } from '@helpers/utils'

interface Props {
  priceToPay: string
}

export const ReceptionBookingNewFeedingSummaryFooter = ({ priceToPay }: Props): JSX.Element => (
  <div className="mt-2 text-right">
    <div className="d-flex align-items-center justify-content-end">
      <strong className="font-16 pr-2">Do zapłaty:</strong>
      <strong className="text-secondary font-20">{formatPrice(priceToPay)}</strong>
    </div>
  </div>
)
