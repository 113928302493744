import * as React from 'react'
import { SubscriptionDetails, SubscriptionTouristVoucher } from '@modules/subscription/models'
import Table from '@components/table/table'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAppDispatch } from '@store/index'
import { updateSubscriptionDetails } from '@store/slices/subscription-slice'
import { commonObjectPost } from '@store/actions/generic-actions'
import { YesNoBadge } from '@components/badges/yes-no'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'

interface Props {
  touristVoucher: SubscriptionTouristVoucher
}

export const SubscriptionDetailsTouristVouchersRowConfirmation: React.FC<Props> = ({ touristVoucher }) => {
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading, action: handleAccept } = useApiRequest(async () => {
    dispatch(updateSubscriptionDetails(await commonObjectPost<SubscriptionDetails>(touristVoucher.urls.details)))
    addSuccessMessage('Sukces', `Bon turystyczny o kodzie ${touristVoucher.code} zostął został potwierdzony!`)
  })

  return (
    <Table.Cell className="text-center">
      <CommonObjectConfirmAction
        handleAccept={!touristVoucher.is_confirmed ? handleAccept : undefined}
        isLoading={isLoading}
      >
        <YesNoBadge
          value={touristVoucher.is_confirmed}
          className={!touristVoucher.is_confirmed ? 'cursor-pointer' : ''}
        />
      </CommonObjectConfirmAction>
    </Table.Cell>
  )
}
