import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { Circle } from '@components/circle'
import { asDecimal } from '@helpers/utils'
import { UpSellAppData, UpSellBookingDetails } from '@models/upselling'
import { useGetUpSellAppDataQuery, useUpdateUpSellPollMutation } from '@api/upSellBooking'
import { TypedQueryResult } from '@api/base'
import { UpsellBookingsDetailsPoolOption } from '@modules/upselling/upsell-booking-details/pool/upsell-bookings-details-option'
import { Spinner } from 'reactstrap'

interface Props {
  upsellBooking: UpSellBookingDetails
  isFetching: boolean
}

export const UpsellBookingsDetailsPool = ({ upsellBooking, isFetching }: Props): JSX.Element => {
  const { data: upSellAppData } = useGetUpSellAppDataQuery() as TypedQueryResult<UpSellAppData>
  const [updatePoll, { isLoading }] = useUpdateUpSellPollMutation()

  const activePolls = upSellAppData?.upsell_polls.filter(poll => poll.is_active)
  const answeredPollsAmount = upsellBooking.upsell_poll_answers.reduce((prev, answer) => {
    if ((activePolls ?? []).some(poll => poll.id === answer.poll_id)) {
      return prev + 1
    }
    return prev
  }, 0)

  const surveyProgressValue = asDecimal(answeredPollsAmount)
    .mul(100)
    .div(activePolls?.length ?? 1)
    .toNumber()

  return (
    <div className="mt-3 mx-n3 px-3 bg-white py-3">
      <div className="d-flex justify-content-between align-items-center">
        <IconWithText
          icon="uil-comment-alt-question font-24"
          text={<span>Ankieta {(isFetching || isLoading) && <Spinner size="sm" style={{ borderWidth: 2 }} />}</span>}
          wrapperClassNames="text-secondary fw-semi-bold"
          textClass="font-18"
        />
        <div className="d-flex align-items-center">
          <span className="mr-2">Uzyskane odpowiedzi:</span>
          <div className="position-relative">
            <span className="upsell__survey__item__counter">{`${answeredPollsAmount} / ${activePolls?.length ?? 0}`}</span>
            <Circle progress={surveyProgressValue} size="50px" />
          </div>
        </div>
      </div>
      <div className="mt-3">
        <ul className="list-unstyled ml-2">
          {upSellAppData?.upsell_polls.map((poll, index) => (
            <li key={index} className="mb-2">
              <p className="mb-1">
                {index + 1}. {poll.name}{' '}
              </p>
              <div className="d-flex mb-2">
                {poll.options.map(option => (
                  <UpsellBookingsDetailsPoolOption
                    key={option.id}
                    option={option}
                    pollId={poll.id}
                    upSellBooking={upsellBooking}
                    onPollUpdate={updatePoll}
                  />
                ))}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
