import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { BaseTableFilters } from '@components/table/use-handle-table-filters-change'
import { CommonTableFilters } from '@components/table/common-table-filters'
import { ProductPackageSet } from '@models/products'
import { createSelectOption } from '@helpers/utils'
import { FormSelect } from '@hyper/forms/form-select'

interface Props {
  filters: BaseTableFilters
  defaultFilters: BaseTableFilters
  setFilters: (filters: BaseTableFilters) => void
  productPackageSets: ProductPackageSet[]
}

export const EndlessHolidayInvitationsFilters = ({
  setFilters,
  filters,
  defaultFilters,
  productPackageSets,
}: Props) => {
  const methods = useForm<BaseTableFilters>({ defaultValues: filters })

  const productPackageSetOptions = productPackageSets.map(set => createSelectOption(set.name, set.id))

  return (
    <FormProvider {...methods}>
      <CommonTableFilters
        filters={filters}
        defaultFilters={defaultFilters}
        setFilters={setFilters}
        searchParams={{ hideLabel: true, formGroupClassName: 'mb-0' }}
        searchWrapperParams={{ className: 'col-auto ml-auto' }}
        clearParams={{ className: 'ml-auto' }}
        rowClassName="align-items-center mb-2"
      >
        <FormSelect
          formPlainProps={{ colStyle: { maxWidth: 400 } }}
          selectProps={{ isClearable: true }}
          options={productPackageSetOptions}
          name="product_package_set"
          label="Zestaw"
        />
      </CommonTableFilters>
    </FormProvider>
  )
}
