import * as React from 'react'
import { BeddingOrder } from '@modules/housekeeping/models'
import { HousekeepingBeddingOrdersRow } from '@modules/housekeeping/cleaning-orders/bedding/row'
import * as R from 'ramda'
import { CustomInput } from 'reactstrap'
import { HousekeepingOrderTableHeaderLocal } from '@modules/housekeeping/common/table-headers/table-header-local'
import { HousekeepingBeddingOrderTableHeaderBeddingStatus } from '@modules/housekeeping/cleaning-orders/bedding/table-header-bedding-status'
import { HousekeepingBeddingOrderTableHeaderCleaningStatus } from '@modules/housekeeping/cleaning-orders/bedding/table-header-cleaning-status'
import { HousekeepingNoOrdersRow } from '@modules/housekeeping/cleaning-orders/common/no-orders'

interface HousekeepingBeddingOrdersTableProps {
  selectedOrders: BeddingOrder[]
  beddingOrders: BeddingOrder[]
  setSelectedOrders: (orders: BeddingOrder[]) => void
}

export const HousekeepingBeddingOrdersTable: React.FC<HousekeepingBeddingOrdersTableProps> = ({
  beddingOrders,
  selectedOrders,
  setSelectedOrders,
}) => {
  const sorter = R.sortBy(
    (order: BeddingOrder) =>
      ({
        PENDING: 1,
        IN_PROGRESS: 2,
        FINISHED: 3,
        ARCHIVED: 4,
      })[order.status],
  )

  const toggleSelectedOrder = (cleaningOrder: BeddingOrder, add: boolean) => {
    if (!add) {
      setSelectedOrders(selectedOrders.filter(row => row.id !== cleaningOrder.id))
    } else {
      setSelectedOrders([...selectedOrders, cleaningOrder])
    }
  }

  const ordersAllChecked = selectedOrders.length === beddingOrders.length && beddingOrders.length > 0

  const toggleSelectAllOrders = () => setSelectedOrders(ordersAllChecked ? [] : [...beddingOrders])

  const isOrderChecked = (beddingOrder: BeddingOrder) => selectedOrders.some(order => order.id === beddingOrder.id)

  return (
    <table className="housekeeping__orders_table table table-striped">
      <thead>
        <tr>
          <th className="border-top-0" style={{ width: 10 }}>
            <CustomInput
              id="toggle-all"
              type="checkbox"
              className="font-18"
              name="toggle-all"
              checked={ordersAllChecked}
              onChange={toggleSelectAllOrders}
            />
          </th>
          <HousekeepingOrderTableHeaderLocal />
          <th className="border-top-0" style={{ width: 200 }}>
            Pracownik sprzątający
          </th>
          <HousekeepingBeddingOrderTableHeaderCleaningStatus />
          <th className="border-top-0" style={{ width: 200 }}>
            Pościelowy
          </th>
          <HousekeepingBeddingOrderTableHeaderBeddingStatus />
          <th className="border-top-0" style={{ width: 200 }} />
        </tr>
      </thead>
      <tbody>
        {!beddingOrders.length ? (
          <HousekeepingNoOrdersRow colSpan={7} />
        ) : (
          <>
            {sorter(beddingOrders).map(beddingOrder => (
              <HousekeepingBeddingOrdersRow
                beddingOrder={beddingOrder}
                key={beddingOrder.id}
                toggleSelectedOrder={toggleSelectedOrder}
                isChecked={isOrderChecked(beddingOrder)}
              />
            ))}
          </>
        )}
      </tbody>
    </table>
  )
}
