import { createCombinedDateWithTime, formatDate } from '@helpers/date-helper'
import { isBefore, isSameDay, subDays } from 'date-fns'
import { ReceptionBookingDetails } from '@models/reception'

const DAYS_BEFORE_START_TO_EDIT = 14

interface Response {
  isInEditAllowedWindow: () => boolean
}

export const useEditableTimeRange = (booking: ReceptionBookingDetails): Response => {
  const isInEditAllowedWindow = () => {
    const now = createCombinedDateWithTime(formatDate(new Date()), booking.arrival_time)

    const lastAvailableChangeDate = subDays(
      createCombinedDateWithTime(booking.date_from, booking.arrival_time),
      DAYS_BEFORE_START_TO_EDIT,
    )

    return isBefore(now, lastAvailableChangeDate) || isSameDay(now, lastAvailableChangeDate)
  }

  return {
    isInEditAllowedWindow,
  }
}
