import * as React from 'react'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UpsellBookingHeaderMenu } from '@modules/upselling/common/upsell-booking-header-menu'

interface Props {
  onNavigationBack?: () => void
}

export const UpsellBookingsHeader = ({ onNavigationBack }: Props): JSX.Element => {
  const user = useAuthenticatedUser()

  const [firstName, lastName] = user.fullname.split(' ')

  return (
    <header className="upsell__header">
      <div className="upsell__header__back-icon">
        {onNavigationBack && (
          <button className="btn btn-light border-right h-100" onClick={onNavigationBack}>
            <i className="uil-arrow-left font-18" />
          </button>
        )}
      </div>
      <div className="upsell__header__title">
        <img src={require('@assets/images/logo_sygnet.svg')} alt="" height="30" />
        <h1 className="font-20 ml-2">Opiekun Klienta w Resorcie</h1>
      </div>
      <div className="d-flex align-items-center  bg-light border-left">
        <div className="d-flex py-1 px-3 align-items-center font-13 h-100">
          <i className="uil-user pr-2 font-24" />
          <div>
            <strong className="d-block">
              {firstName} {lastName}
            </strong>
          </div>
        </div>
        <UpsellBookingHeaderMenu />
      </div>
    </header>
  )
}
