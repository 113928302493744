import * as React from 'react'
import { Button, Col, Label, Row } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { ColAuto } from '@hyper/col-auto'
import DateRangeInput from '@components/date/date-range-input'
import { useGroupedSellers } from '@components/hooks/use-grouped-sellers'
import { PackageVipFiltersParams } from '@modules/promotions/package-vip/models'
import { PackageVipAdd } from '@modules/promotions/package-vip/add'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { Form } from '@hyper/forms/form'
import { FormSelect } from '@hyper/forms/form-select'
import { PackageVipStatusesSelectOptions, PackageVipTypeSelectOptions } from '@modules/promotions/package-vip/utils'

interface Props {
  setFilters: (filters: PackageVipFiltersParams) => void
  defaultFilters: PackageVipFiltersParams
  filters: PackageVipFiltersParams
  fetchPackageVips: () => void
}

export const PackageVipFilters: React.FC<Props> = ({ filters, setFilters, defaultFilters, fetchPackageVips }) => {
  const methods = useForm<PackageVipFiltersParams>({
    defaultValues: defaultFilters,
  })

  const onSubmit = (payload: PackageVipFiltersParams) => setFilters({ ...filters, ...payload })

  const handleReset = () => {
    methods.reset()
    setFilters(defaultFilters)
  }

  const sellersOption = useGroupedSellers()

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  return (
    <Form methods={methods} onSubmit={onSubmit} className="form form-style-filter">
      <Row>
        <Col md={10} className="row">
          <ColAuto>
            <Label>Data utworzenia</Label>
            <DateRangeInput startDateName="sell_date_after" endDateName="sell_date_before" />
          </ColAuto>

          <FormSelect
            options={PackageVipStatusesSelectOptions}
            label="Statusy"
            name="status"
            selectProps={{ isSelectMulti: true }}
            formPlainProps={{ colStyle: { maxWidth: 300 } }}
          />

          <FormSelect
            options={sellersOption}
            name="seller"
            label="Sprzedawca"
            selectProps={{ isClearable: true }}
            formPlainProps={{ colStyle: { maxWidth: 200 } }}
          />

          <FormSelect
            options={PackageVipTypeSelectOptions}
            name="type"
            label="Rodzaj"
            selectProps={{ isClearable: true }}
            formPlainProps={{ colStyle: { maxWidth: 150 } }}
          />

          {methods.formState.isDirty && (
            <div className="mt-label">
              <Button color="light" type="button" onClick={handleReset}>
                Wyczyść
              </Button>
            </div>
          )}
        </Col>
        <Col md={2} className="text-right px-0 mt-label">
          <PackageVipAdd fetchPackageVips={fetchPackageVips} />
        </Col>
        <SearchFilter colClassName="col-md-2" />
      </Row>
    </Form>
  )
}
