import * as React from 'react'
import { Row } from 'reactstrap'
import { useFormContext, useWatch } from 'react-hook-form'
import { PackageCreateFormInputs } from '@modules/package/create/modal'
import { FormInput } from '@hyper/forms'
import { GusButton } from '@components/gus-button'
import { CompanyDataFromGus } from '@models/sale'

export const PackageCreateFormInvoice: React.FC = () => {
  const { setValue, control } = useFormContext<PackageCreateFormInputs>()

  const handleGus = (companyData: CompanyDataFromGus) => {
    setValue('invoice_company', companyData.name)
    setValue('invoice_street', companyData.street_address)
    setValue('invoice_postcode', companyData.postal_code)
    setValue('invoice_city', companyData.city)
  }

  const invoice_nip = useWatch({ control, name: 'invoice_nip' })
  const getNip = () => invoice_nip

  return (
    <Row className="bg-grey form-transparent">
      <div className="hr hr-grey mb-2" />
      <>
        <FormInput
          label="NIP"
          colSize={4}
          name="invoice_nip"
          afterInput={<GusButton setCompanyData={handleGus} getNip={getNip} />}
        />
        <FormInput label="Nazwa firmy" colSize={8} name="invoice_company" />
        <FormInput label="Ulica" colSize={4} name="invoice_street" />
        <FormInput label="Kod pocztowy" colSize={4} name="invoice_postcode" />
        <FormInput label="Miasto" colSize={4} name="invoice_city" />
      </>
    </Row>
  )
}
