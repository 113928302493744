import * as React from 'react'
import { Button } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'

export const RecommendationGiftAdd: React.FC = () => {
  const [handleToggleDialog] = useModal('RecommendationGiftDialog')

  return (
    <Button onClick={handleToggleDialog} color="green-dark" type="button" role="recommendation-gift-add">
      <i className="mdi mdi-plus-circle mr-1" />
      Dodaj prezent
    </Button>
  )
}
