import * as React from 'react'
import { selectResortDetails } from '@store/selectors/reception'
import { useClickableResortMap } from '@modules/reservations/create/local-selection-map/use-clickable-resort-map'
import { AccommodationType, ResortDetails } from '@models/booking'
import { LocalSelectionMapApartmentsList } from '@modules/reservations/create/local-selection-map/local-selection-map-apartments-list'
import { LocalSectorsList } from '@modules/reservations/create/local-selection-map/local-sectors-list'
import { ACCOMMODATION_TYPES } from '@helpers/consts'
import { AvailableApartment } from '@models/reservation-create'
import { ContentLoader } from '@components/content-loader'
import { formatPrice } from '@helpers/utils'
import svgPanZoom from 'svg-pan-zoom'
import { Button } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import styleHelper from '@helpers/style-helper'
import { useAppSelector } from '@store/index'

interface Props extends BaseModalProps {
  onMoveToNextStep: () => void
  apartments: AvailableApartment[]
  accommodation: AccommodationType | undefined
  onApartmentSelect: (apartmentId: number | null) => void
  defaultSelectedApartmentId: number | null
  submitLabel?: string
}

export const LocalSelectionMapModal: React.FC<Props> = ({
  apartments,
  accommodation,
  onApartmentSelect,
  defaultSelectedApartmentId,
  toggleIsVisible,
  submitLabel = 'Potwierdź wybór lokalu',
}) => {
  const [isLoading, setIsLoading] = React.useState(true)
  const [map, setMap] = React.useState('')
  const [maximumListHeight, setMaximumListHeight] = React.useState(0)
  const [mapDocument, setMapDocument] = React.useState<Document | null>(null)
  const [selectedApartmentId, setSelectedApartmentId] = React.useState<number | null>(defaultSelectedApartmentId)
  const [mapApartments, setMapApartments] = React.useState<AvailableApartment[]>([])

  const [panZoom, setPanZoom] = React.useState<SvgPanZoom.Instance | null>(null)

  const [mapHeight, setMapHeight] = React.useState('auto')
  const [mapWrapperHeight, setMapWrapperHeight] = React.useState('auto')

  const resortDetails = useAppSelector(selectResortDetails) as ResortDetails

  const { onSectorSelection } = useClickableResortMap({
    mapDocument,
    apartments,
    onMapChange: setMap,
    resortDetails,
    onLoadingVisibilityChange: setIsLoading,
    selectedApartmentId,
    onApartmentSelect: setSelectedApartmentId,
    onMapApartmentsChange: setMapApartments,
  })

  React.useEffect(() => {
    setMap(resortDetails.map_svg)
  }, [resortDetails.map_svg])

  const handleLocalSelectionSave = async () => {
    onApartmentSelect(selectedApartmentId)
    toggleIsVisible()
  }

  const handleShowMainResortMap = () => {
    const mainSectorsMap = resortDetails.map_svg
    setMap(mainSectorsMap)
  }

  const handleImageLoad = (event: React.ChangeEvent<HTMLObjectElement>) => {
    setMapWrapperHeight(mapHeight)
    const restItemHeight = 300
    setMaximumListHeight(event.target.clientHeight - restItemHeight)
    setMapDocument(event.target.contentDocument)

    setMapHeight(styleHelper.valueToPX(event.target.contentDocument?.documentElement.scrollHeight || 0))

    setMapWrapperHeight(`${event.target.contentDocument?.documentElement.scrollHeight}px`)

    function beforePan(oldPan, newPan): SvgPanZoom.PointModifier {
      const clientWidth = event.target.contentDocument?.documentElement.scrollWidth ?? 0,
        clientHeight = event.target.contentDocument?.documentElement.scrollHeight ?? 0,
        sizes = this.getSizes(),
        leftLimit = -((sizes.viewBox.x + sizes.viewBox.width) * sizes.realZoom) + clientWidth,
        rightLimit = sizes.width - clientWidth - sizes.viewBox.x * sizes.realZoom,
        topLimit = -((sizes.viewBox.y + sizes.viewBox.height) * sizes.realZoom) + clientHeight,
        bottomLimit = sizes.height - clientHeight - sizes.viewBox.y * sizes.realZoom

      return {
        x: Math.max(leftLimit, Math.min(rightLimit, newPan.x)),
        y: Math.max(topLimit, Math.min(bottomLimit, Math.min(newPan.y, 0))),
      }
    }

    if (panZoom) {
      panZoom.destroy()
    }

    const initializedPanZoom = svgPanZoom('#local-selection', {
      beforePan,
      zoomScaleSensitivity: 0.4,
      mouseWheelZoomEnabled: false,
      dblClickZoomEnabled: false,
      preventMouseEventsDefault: false,
      minZoom: 1,
    })

    setPanZoom(initializedPanZoom)
  }

  const mapAvailableApartments = mapApartments.filter((apartment: AvailableApartment) => apartment.is_available)
  const allAvailableApartments = apartments.filter((apartment: AvailableApartment) => apartment.is_available)

  const hasGarden =
    ACCOMMODATION_TYPES.APARTMENTS.includes(apartments[0]?.accommodation_type_id) &&
    mapAvailableApartments.some(apartment => apartment.has_garden_fixed)

  const handleZoomIn = () => {
    panZoom?.zoomIn()
  }

  const handleZoomOut = () => {
    panZoom?.zoomOut()
  }

  React.useEffect(() => {
    if (map) setMapHeight('auto')
  }, [map])

  React.useEffect(() => {
    if (mapHeight !== 'auto') {
      setMapWrapperHeight(styleHelper.valueToPX(parseInt(mapHeight.replace('px', ''), 10)))
    }
  }, [mapHeight])

  return (
    <>
      <button className="local-selection-modal__close" onClick={toggleIsVisible}>
        <i className="uil-times text-white font-18" />
      </button>
      <ContentLoader isLoading={isLoading} className="content-loader--opacity">
        <div className="d-flex flex-md-row flex-column">
          <div className="col-md-8 col-12 position-relative px-0">
            <div style={{ height: mapWrapperHeight }} className="local-selection-modal__map__wrapper">
              {map && (
                <object
                  style={{ height: mapHeight }}
                  id="local-selection"
                  type="image/svg+xml"
                  className="local-selection-modal__map"
                  data={map}
                  onLoad={handleImageLoad}
                />
              )}
            </div>

            <div className="local-selection-modal__zoom-buttons__wrapper bg-light">
              <button className="btn btn-light shadow-none" onClick={handleZoomIn}>
                <i className="uil-plus" />
              </button>
              <hr className="local-selection-modal__zoom-buttons__spacer" />
              <button className="btn btn-light shadow-none" onClick={handleZoomOut}>
                <i className="uil-minus" />
              </button>
            </div>
          </div>

          <div className="col-md-4 col-12 p-3 d-flex flex-column">
            <h4 className="text-primary mt-2">
              Wybrany typ lokalu to <span className="text-lowercase">{accommodation?.name}</span>
            </h4>

            {hasGarden && (
              <div className="font-12 text-darker-gray mb-3 mt-2 bg-white-smoke py-1 px-2 border rounded d-flex">
                <img src={require('@assets/images/grass_icon.svg')} alt="Ogródek" height="10" className="mr-1" />
                <span>
                  W apartamentach z ogrodem obowiązuje dopłata
                  <strong> w wysokości {formatPrice(20)} za dzień </strong>pobytu.
                </span>
              </div>
            )}

            {!!resortDetails.map_svg_sectors.length && map === resortDetails.map_svg ? (
              <LocalSectorsList
                apartments={allAvailableApartments}
                resortDetails={resortDetails}
                maximumListHeight={maximumListHeight}
                onSectorSelection={onSectorSelection}
              />
            ) : (
              <LocalSelectionMapApartmentsList
                apartments={mapAvailableApartments}
                maximumListHeight={maximumListHeight}
                selectedApartmentId={selectedApartmentId}
                onApartmentSelect={setSelectedApartmentId}
              />
            )}
            {!!resortDetails.map_svg_sectors.length && map !== resortDetails.map_svg && (
              <button
                className="btn btn-primary w-100 fw-semi-bold btn-tall mb-2 text-white"
                onClick={handleShowMainResortMap}
              >
                Pokaż pozostałe sektory
              </button>
            )}
            <div className="d-flex">
              <Button className="btn btn-light mr-3" type="button" onClick={toggleIsVisible}>
                Anuluj
              </Button>
              <Button
                className="btn btn-green fw-bold text-white btn-tall w-100 shadow-none"
                disabled={!selectedApartmentId}
                onClick={handleLocalSelectionSave}
              >
                <span>{submitLabel}</span>
              </Button>
            </div>
          </div>
        </div>
      </ContentLoader>
    </>
  )
}
