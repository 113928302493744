import * as React from 'react'
import { Button, CustomInput, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { SaveButton } from '@hyper/button'
import { useForm } from 'react-hook-form'
import { BaseModalProps } from '@components/modals/types'
import { SubscriptionDetails } from '@modules/subscription/models'
import { objectToUrlString } from '@helpers/utils'
import { FormPlain } from '@hyper/forms'
import { extractInnerRef } from '@helpers/forms'
import { Form } from '@hyper/forms/form'

interface FormInputs {
  stay: boolean
}

interface Props extends BaseModalProps {
  subscriptionDetails: SubscriptionDetails
}

export const SubscriptionGenerateProforma: React.FC<Props> = ({ toggleIsVisible, subscriptionDetails }) => {
  const methods = useForm<FormInputs>({
    mode: 'all',
    defaultValues: {
      stay: true,
    },
  })

  const onSubmit = (payload: FormInputs) =>
    (document.location.href = subscriptionDetails.urls.proforma_invoice + '?' + objectToUrlString(payload))

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Generuj fakturę pro forma</ModalHeader>
      <ModalBody>
        <Row>
          <FormPlain name="stay">
            <CustomInput type="checkbox" {...extractInnerRef(methods.register('stay'))} id="stay" label="Pobyt" />
          </FormPlain>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" label="Generuj" isSaving={false} />
      </ModalFooter>
    </Form>
  )
}
