import * as React from 'react'
import { SubscriptionCode, SubscriptionDetails } from '@modules/subscription/models'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap'
import { IconWithText } from '@components/icon-with-text'
import { useModal } from '@components/modals/use-modal'
import { useSubscriptionTypeStrategyProvider } from '@modules/subscription/hooks/use-subscription-type-provider'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'

interface Props {
  selectedRows: SubscriptionCode[]
  clearSelectedRows: () => void
  subscription: SubscriptionDetails
}

export const SubscriptionDetailsCodesActions: React.FC<Props> = ({ subscription, selectedRows, clearSelectedRows }) => {
  const [handleConvertClick] = useModal('SubscriptionDetailsCodesConvertModal', {
    codes: selectedRows,
    onClose: clearSelectedRows,
    subscription,
  })

  const [handleGlobalMergeClick] = useModal('SubscriptionDetailsCodesGlobalMergeModal', {
    code: selectedRows[0],
    onClose: clearSelectedRows,
    subscription,
  })

  const {
    crud_permissions: { update },
    allow_merge_code,
  } = useSubscriptionTypeStrategyProvider(subscription.type)

  const user = useAuthenticatedUser()

  const handleConvert = (event: React.SyntheticEvent) => {
    if (selectedRows.length > 1) {
      handleConvertClick(event)
    }
  }
  const handleGlobalConvert = (event: React.SyntheticEvent) => {
    if (selectedRows.length === 1) {
      handleGlobalMergeClick(event)
    }
  }

  if (!user.hasPerm(update) || !allow_merge_code) {
    return null
  }

  return (
    <UncontrolledButtonDropdown>
      <DropdownToggle color="light" caret={true}>
        <strong>Zaznaczone ({selectedRows.length})</strong>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={handleConvert} disabled={selectedRows.length <= 1}>
          <IconWithText icon="uil-exchange" wrapperClassNames="text-bold" text="Połącz kody" />
        </DropdownItem>
        <DropdownItem onClick={handleGlobalConvert} disabled={selectedRows.length !== 1}>
          <IconWithText icon="uil-compress-arrows" wrapperClassNames="text-bold" text="Połącz kody z innym pakietem" />
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  )
}
