import * as React from 'react'
import { TechnicalOrder } from '@models/technical-orders'
import Table from '@components/table/table'
import { useAppSelector } from '@store/index'
import { resortsMapSelector } from '@store/selectors/dashboard'

interface Props {
  technicalOrder: TechnicalOrder
}

export const TechnicalOrdersTodoRowResort: React.FC<Props> = ({ technicalOrder }) => {
  const resort = useAppSelector(resortsMapSelector)[technicalOrder.resort_id]

  return (
    <Table.Cell className="vertical-align-middle" style={{ width: 150 }}>
      {resort.name}
    </Table.Cell>
  )
}
