import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { ReceptionBookingNewFeedingFastSelection } from '@modules/reception/checkin/step-feeding/new-feeding/fast-selection/reception-booking-new-feeding-fast-selection'
import { ReceptionBookingNewFeedingGuests } from '@modules/reception/checkin/step-feeding/new-feeding/guest/reception-booking-new-feeding-guests'
import { ReceptionBookingNewFeedingSummary } from '@modules/reception/checkin/step-feeding/new-feeding/summary/reception-booking-new-feeding-summary'
import { ReceptionBookingNewFeedingWalletPayment } from '@modules/reception/checkin/step-feeding/new-feeding/reception-booking-new-feeding-wallet-payment'
import { NewFeedingCalculations } from '@modules/reception/checkin/step-feeding/new-feeding/models'

interface Props {
  booking: ReceptionBookingDetails
  isPaymentByWalletSelected: boolean
  toggleIsPaymentByWalletSelected: () => void
  feedingCalculations: NewFeedingCalculations
  initialFeedingCalculations: NewFeedingCalculations
}

export const ReceptionBookingNewFeedingContent = ({
  booking,
  isPaymentByWalletSelected,
  toggleIsPaymentByWalletSelected,
  feedingCalculations,
  initialFeedingCalculations,
}: Props): JSX.Element => {
  const isSomeFeedingAlreadyPaidByWallet = !!initialFeedingCalculations?.details.some(
    calculation => calculation.is_paid_by_wallet,
  )

  const hasUnclosedCart = !!booking.cart && Object.values(booking.cart).some(item => !!item.paid)

  return (
    <>
      <ReceptionBookingNewFeedingFastSelection
        booking={booking}
        feedingCalculations={feedingCalculations}
        initialCalculationDetails={initialFeedingCalculations.details}
      />
      <ReceptionBookingNewFeedingGuests
        hasUnclosedCart={hasUnclosedCart}
        booking={booking}
        calculationDetails={feedingCalculations.details}
        initialCalculationDetails={initialFeedingCalculations.details}
      />
      <ReceptionBookingNewFeedingWalletPayment
        booking={booking}
        isPaymentByWalletSelected={isPaymentByWalletSelected}
        onPaymentByWalletSelectionChange={toggleIsPaymentByWalletSelected}
        isSomeFeedingAlreadyPaidByWallet={isSomeFeedingAlreadyPaidByWallet}
      />
      <ReceptionBookingNewFeedingSummary
        booking={booking}
        calculations={feedingCalculations}
        isPaymentByWalletSelected={isPaymentByWalletSelected}
      />
    </>
  )
}
