import * as React from 'react'
import Table from '@components/table/table'
import { Cafeteria } from '@models/cafeteria'
import { formatPrice } from '@helpers/utils'
import { useCafeteriaAppData } from '@modules/cafeteria/hooks/use-cafeteria-app-data'
import { useModal } from '@components/modals/use-modal'
import { StatusBadge } from '@modules/promotions/common/status-badge'

interface Props {
  cafeteria: Cafeteria
}

export const CafeteriaListTableRow: React.FC<Props> = ({ cafeteria }) => {
  const [handleDetails] = useModal('CafeteriaDetailsModal', { cafeteria })

  const { cafeteria_operators } = useCafeteriaAppData()

  const operator = React.useMemo(
    () => cafeteria_operators.find(row => row.id == cafeteria.operator)?.name || '',
    [cafeteria.operator, cafeteria_operators],
  )

  return (
    <Table.Row onDoubleClick={handleDetails} className="cursor-pointer" role="details-row">
      <Table.Cell>{cafeteria.name}</Table.Cell>
      <Table.Cell>{operator}</Table.Cell>
      <Table.Cell>
        <StatusBadge element={cafeteria} />
      </Table.Cell>
      <Table.Cell>{cafeteria.kind_display}</Table.Cell>
      <Table.Cell>{formatPrice(cafeteria.residence_brutto)}</Table.Cell>
      <Table.Cell>{cafeteria.codes_amount}</Table.Cell>
    </Table.Row>
  )
}
