import * as React from 'react'
import { CleaningOrderDetailsHousekeepingNote } from '@modules/housekeeping/models'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'

interface Props {
  note: CleaningOrderDetailsHousekeepingNote
}

export const OrderNotesTooltipDetailsRow: React.FC<Props> = ({ note }) => (
  <div className="reception__notes-tooltip__note">
    <div className="reception__notes-tooltip__note__info">
      <div className="reception__notes-tooltip__note__info__row">
        <i className="uil-calendar-alt text-secondary mr-1 " /> {toDefaultDateTimeFormat(note.created)}
      </div>
      <div className="reception__notes-tooltip__note__info__row">
        <i className="uil-user text-secondary mr-1 " />
        {note.created_by}
      </div>
    </div>
    <div className="reception__notes-tooltip__note__content text-linebreaksbr">
      {note.content}
      {note.image && (
        <a href={note.image} target="_blank" className="d-block mt-1">
          <img className="d-block" src={note.image} width={100} alt="" />
        </a>
      )}
    </div>
  </div>
)
