import * as React from 'react'
import { ReceptionBooking } from '@models/reception'
import { Button, ModalFooter, ModalHeader } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { formatDate } from '@helpers/date-helper'
import { BaseModalProps } from '@components/modals/types'

interface Props extends BaseModalProps {
  booking: ReceptionBooking
  handleConfirm: () => void
}

export const ReceptionBookingCheckOutDateConfirmDialog: React.FC<Props> = ({
  booking,
  toggleIsVisible,
  handleConfirm,
}) => (
  <>
    <ModalHeader toggle={toggleIsVisible} className="bg-warning text-white">
      Potwierdź wymeldowanie gościa
    </ModalHeader>
    <ModalBody>
      <strong className="d-block mb-1">
        Data zakończenia pobytu ({formatDate(booking.date_to, 'd MMMM yyyy')}) nie kończy się dzisiaj.
      </strong>
      <span>Potwierdź, że chcesz wymeldować gościa pomimo innego terminu.</span>
    </ModalBody>
    <ModalFooter>
      <Button color="light" onClick={toggleIsVisible}>
        Anuluj
      </Button>
      <Button className="btn btn-warning" onClick={handleConfirm}>
        Potwierdź
      </Button>
    </ModalFooter>
  </>
)
