import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { CmsAccommodationTypeDetails, CmsImprovement, CmsResortDetails, TextBlock } from '@modules/cms/models'
import { RootState } from '@store/index'
import {
  getCmsAccommodationTypes,
  getCmsResortImprovements,
  getCmsResorts,
  getCmsTextBlocks,
} from '@store/actions/cms-reservation-app-actions'

interface CmsReservationAppState {
  resorts: EntityState<CmsResortDetails>
  accommodationTypes: EntityState<CmsAccommodationTypeDetails>
  textBlocks: EntityState<TextBlock>
  improvements: EntityState<CmsImprovement>
}

const resortsAdapter = createEntityAdapter<CmsResortDetails>()

const accommodationTypesAdapter = createEntityAdapter<CmsAccommodationTypeDetails>()
const textBlocksAdapter = createEntityAdapter<TextBlock>()
const improvementsAdapter = createEntityAdapter<CmsImprovement>()

const initialState: CmsReservationAppState = {
  resorts: resortsAdapter.getInitialState(),
  accommodationTypes: accommodationTypesAdapter.getInitialState(),
  textBlocks: textBlocksAdapter.getInitialState(),
  improvements: improvementsAdapter.getInitialState(),
}

export const cmsReservationAppSlice = createSlice({
  name: 'cmsReservationApp',
  initialState,
  reducers: {
    setCmsAccommodationTypes(state, action: PayloadAction<CmsAccommodationTypeDetails[]>) {
      accommodationTypesAdapter.setAll(state.accommodationTypes, action.payload)
    },
    setCmsAccommodationType(state, action: PayloadAction<CmsAccommodationTypeDetails>) {
      accommodationTypesAdapter.upsertOne(state.accommodationTypes, action.payload)
    },

    setCmsResorts(state, action: PayloadAction<CmsResortDetails[]>) {
      resortsAdapter.setAll(state.resorts, action.payload)
    },
    setCmsResort(state, action: PayloadAction<CmsResortDetails>) {
      resortsAdapter.upsertOne(state.resorts, action.payload)
    },
    setCmsTextBlock(state, action: PayloadAction<TextBlock>) {
      textBlocksAdapter.upsertOne(state.textBlocks, action.payload)
    },
    setCmsResortImprovement(state, action: PayloadAction<CmsImprovement>) {
      improvementsAdapter.upsertOne(state.improvements, action.payload)
    },
    setCmsResortImprovements(state, action: PayloadAction<CmsImprovement[]>) {
      improvementsAdapter.upsertMany(state.improvements, action.payload)
    },
  },
  extraReducers: builder => {
    builder.addCase(getCmsResorts.fulfilled, (state, action) => {
      resortsAdapter.setAll(state.resorts, action.payload)
    })
    builder.addCase(getCmsAccommodationTypes.fulfilled, (state, action) => {
      accommodationTypesAdapter.setAll(state.accommodationTypes, action.payload)
    })
    builder.addCase(getCmsTextBlocks.fulfilled, (state, action) => {
      textBlocksAdapter.setAll(state.textBlocks, action.payload)
    })
    builder.addCase(getCmsResortImprovements.fulfilled, (state, action) => {
      improvementsAdapter.setAll(state.improvements, action.payload)
    })
  },
})

export const {
  setCmsResorts,
  setCmsResort,
  setCmsAccommodationType,
  setCmsTextBlock,
  setCmsResortImprovement,
  setCmsResortImprovements,
} = cmsReservationAppSlice.actions

export const { selectAll: cmsResortsSelector, selectById: cmsResortSelectByIdSelector } = resortsAdapter.getSelectors(
  (state: RootState) => state.cmsReservationAppState.resorts,
)
export const { selectAll: cmsAccommodationTypesSelector } = accommodationTypesAdapter.getSelectors(
  (state: RootState) => state.cmsReservationAppState.accommodationTypes,
)
export const { selectAll: cmsTextBlocksSelector } = textBlocksAdapter.getSelectors(
  (state: RootState) => state.cmsReservationAppState.textBlocks,
)

export const { selectAll: cmsResortImprovementsSelector } = improvementsAdapter.getSelectors(
  (state: RootState) => state.cmsReservationAppState.improvements,
)

export default cmsReservationAppSlice.reducer
