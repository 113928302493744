import * as React from 'react'
import { ProductCardTitle } from '@components/products/product-card-title'
import { BookingOptionDetails } from '@modules/promotions/booking-options/model'
import { Card, CardBody } from 'reactstrap'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { BookingOptionDetailsOptionsRow } from '@modules/promotions/booking-options/details/booking-option-details-options-row'
import { useGetAvailableClientOptionsQuery } from '@api/booking-options'
import { TypedQueryResult } from '@api/base'
import { ClientOption, ClientOptionPrice } from '@models/clients'

const headerValues: TableHeaderValue[] = [
  { title: 'LP' },
  { title: 'Nazwa opcji', sortField: 'name' },
  { title: 'Użyty', sortField: 'is_used' },
  { title: 'Numer rezerwacji' },
]

const defaultFilters: TableFilters = { search: '', ordering: '-is_used', page: 1, page_size: 9999 }

interface Props {
  optionDetails: BookingOptionDetails
}

const emptyList = []
export const BookingOptionDetailsOptions = React.forwardRef<HTMLDivElement, Props>(({ optionDetails }, ref) => {
  const [filters, setFilters] = React.useState(defaultFilters)
  const { data: optionsList = emptyList } = useGetAvailableClientOptionsQuery() as TypedQueryResult<ClientOptionPrice[]>

  const options: ClientOption[] = React.useMemo(
    () =>
      optionDetails.status !== 'initial'
        ? optionDetails.options
        : optionDetails.option_kinds.map(
            (row, id) =>
              ({
                id,
                amount: 100,
                amount_left: 100,
                kind_display: optionsList.find(option => option.kind === row)?.kind_display || row,
                kind: row,
                is_paid: false,
                is_used: false,
                rest_to_pay: '0',
                price_brutto: '0',
                bookings: [],
                urls: {
                  admin: '',
                },
              }) as ClientOption,
          ),
    [optionDetails],
  )

  return (
    <Card innerRef={ref}>
      <CardBody>
        <ProductCardTitle title="Opcje dodatkowe" isEditAllowed={false} />
        <Table
          maxHeight="340px"
          className="table-hover table-sm w-100"
          headerValues={headerValues}
          filters={filters}
          showPagination={false}
          setFilters={setFilters}
        >
          {options.map((option, index) => (
            <BookingOptionDetailsOptionsRow key={option.id} index={index + 1} option={option} />
          ))}
        </Table>
      </CardBody>
    </Card>
  )
})
