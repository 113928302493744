import * as React from 'react'
import { asDecimal, formatPrice, sumDecimalArray } from '@helpers/utils'
import Table from '@components/table/table'
import { PaymentRegister } from '@models/payments'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  paymentRegisters: PaymentRegister[]
}

export const TransactionListModalTableFooter: React.FC<Props> = ({ paymentRegisters }) => {
  const totalPayment = React.useMemo(
    () =>
      sumDecimalArray(
        paymentRegisters.filter(row => row.status === 'success').map(row => asDecimal(row.amount)),
      ).toString(),
    [paymentRegisters],
  )

  return (
    <Table.Row>
      <Table.Cell colSpan={4} className="text-right align-middle reservation-list__summary-cell">
        <strong className="mr-2">Razem {paymentRegisters.length} transakcji:</strong>
      </Table.Cell>

      <Table.Cell colSpan={2} className="reservation-list__summary-cell">
        <div className="px-4 py-2 text-white reservation-list__summary-block">
          <IconWithText
            icon="uil-usd-circle"
            text={formatPrice(totalPayment)}
            wrapperClassNames="text-strong font-14"
          />
        </div>
      </Table.Cell>
    </Table.Row>
  )
}
