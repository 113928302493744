import * as React from 'react'
import classNames from 'classnames'
import { IconWithText } from '@components/icon-with-text'
import { CleaningOrderBeddingOrderStatus, CleaningOrderImprovementOrderStatus } from '@modules/housekeeping/models'

interface Props {
  orders: (CleaningOrderBeddingOrderStatus | CleaningOrderImprovementOrderStatus)[]
}

export const HousekeepingOrderToCheckBadgeStatus = ({ orders }: Props): JSX.Element => (
  <td className="housekeeping__orders_table__column" width={150}>
    {!orders.length && <span className={classNames('badge px-1 badge-gray ')}>nie dotyczy</span>}
    {orders.map(order => (
      <span key={order.id} className={classNames('badge px-1', OrderStatus[order.status].badge)}>
        <IconWithText icon={OrderStatus[order.status].icon} text={order.status_display} />
      </span>
    ))}
  </td>
)

const OrderStatus = {
  NEW: { badge: 'badge-danger-lighten', icon: 'uil-user-times' },
  PENDING: { badge: 'badge-warning-lighten', icon: 'uil-hourglass' },
  IN_PROGRESS: { badge: 'badge-info-lighten', icon: 'uil-spinner-alt' },
  FINISHED: { badge: 'badge-success-lighten', icon: 'uil-check-circle' },
  ARCHIVED: { badge: 'badge-light', icon: 'uil-archive' },
}
