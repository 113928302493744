import * as React from 'react'
import { CmsImprovement } from '@modules/cms/models'
import Table from '@components/table/table'
import { useModal } from '@components/modals/use-modal'
import { resortsMapSelector } from '@store/selectors/dashboard'
import { CmsImprovementGroup } from '@models/cms'
import { DraggableTableRow } from '@components/table/draggable-table-row'
import { useAppSelector } from '@store/index'

interface Props {
  improvement: CmsImprovement
  index: number
  improvement_groups: CmsImprovementGroup[]
}

export const CmsReservationAppImprovementsRow: React.FC<Props> = ({ index, improvement, improvement_groups }) => {
  const [handleEdit] = useModal('CmsReservationAppImprovementModal', { improvement })
  const resortsMap = useAppSelector(resortsMapSelector)

  const groupName = React.useMemo(
    () => improvement_groups.find(row => row.id == improvement.group_id)?.name || '',
    [improvement_groups, improvement.group_id],
  )

  return (
    <DraggableTableRow draggableProps={{ index, draggableId: `${improvement.id}` }}>
      <Table.Cell>{improvement.name}</Table.Cell>
      <Table.Cell>{resortsMap[improvement.resort_id]?.name}</Table.Cell>
      <Table.Cell>{groupName}</Table.Cell>

      <Table.Cell className="text-right">
        <button className="action-icon btn-pure" title="Edytuj" onClick={handleEdit}>
          <i className="uil-edit text-muted font-18" />
        </button>
      </Table.Cell>
    </DraggableTableRow>
  )
}
