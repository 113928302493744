import * as React from 'react'
import {
  BookingExtendedStayKind,
  BookingExtendedStayOption,
  ReceptionBooking,
  ReceptionBookingDetails,
} from '@models/reception'
import { useFormContext, useWatch } from 'react-hook-form'
import { DatePickerForm } from '@modules/reception/common/filters/date-picker-form'
import DateHelper, { formatDate, parseISODate } from '@helpers/date-helper'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { commonObjectGet } from '@store/actions/generic-actions'
import { useApiRequest } from '@components/hooks/use-api-request'
import { ReceptionBookingExtendedStayOption } from '@modules/reception/extended-stay/reception-booking-extended-stay-option'
import { addDays } from 'date-fns'
import { UserPermission } from '@models/dashboard'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useDebouncedValue } from 'rooks'
import { ReceptionBookingExtendedStayOptionDaysCustomPrice } from '@modules/reception/extended-stay/reception-booking-extended-stay-option-days-custom-price'

interface ReceptionBookingExtendedStayOptionProps {
  option: BookingExtendedStayOption
  booking: ReceptionBookingDetails | ReceptionBooking
  onOptionsChange: (options: BookingExtendedStayOption[]) => void
}

export const ReceptionBookingExtendedStayOptionDays: React.FC<ReceptionBookingExtendedStayOptionProps> = ({
  option,
  booking,
  onOptionsChange,
}) => {
  const user = useAuthenticatedUser()
  const inputDateInitialized = React.useRef(false)
  const { control, setValue } = useFormContext()

  const [extendedTo, withCustomStayPrice, kind, customApartmentDayPrice] = useWatch({
    control,
    name: ['date_to', 'with_custom_stay_price', 'kind', 'custom_apartment_day_price'],
  })

  const [debouncedCustomPrice] = useDebouncedValue(customApartmentDayPrice, 500)

  const { action: fetchExtensionPrice, isLoading } = useApiRequest(async () => {
    onOptionsChange(
      await commonObjectGet(booking.urls.extended_stay, {
        date_to: formatDate(extendedTo),
        ...(withCustomStayPrice && customApartmentDayPrice && { custom_apartment_day_price: customApartmentDayPrice }),
      }),
    )
  })

  const availableDates = React.useMemo(
    () =>
      option.kind === BookingExtendedStayKind.DAYS && option.available
        ? DateHelper.getEachDayFromRange(
            formatDate(addDays(parseISODate(booking.date_to) as Date, 1)),
            option.available_date_to ?? booking.date_to,
          ).map(date => parseISODate(date))
        : [],
    [booking.date_to, option.available_date_to, option.kind],
  )

  useDidUpdateEffect(() => {
    if (inputDateInitialized.current) {
      fetchExtensionPrice()
      return
    }

    inputDateInitialized.current = true
  }, [formatDate(extendedTo), debouncedCustomPrice])

  React.useEffect(() => {
    if (availableDates.length && !extendedTo) {
      setValue('date_to', availableDates[0])
    }
  }, [availableDates])

  return (
    <>
      <ReceptionBookingExtendedStayOption option={option} isPriceCalculating={isLoading}>
        <DatePickerForm
          isDisabled={!option.available}
          name="date_to"
          control={control}
          minDate={availableDates[0]}
          maxDate={availableDates.at(-1)}
          formGroupClassName="mb-0"
        />
      </ReceptionBookingExtendedStayOption>
      {kind === BookingExtendedStayKind.DAYS && user.hasPerm(UserPermission.BookingCanChangeExtendedStayPrice) && (
        <ReceptionBookingExtendedStayOptionDaysCustomPrice originalPriceBrutto={option.original_price_brutto} />
      )}
    </>
  )
}
