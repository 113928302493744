import * as React from 'react'
import { getReceptionBookingDetails } from '@store/actions/reception-actions'
import { BookingExtendedStayOption, ReceptionBooking } from '@models/reception'
import { useAppDispatch } from '@store/index'
import { commonObjectGet } from '@store/actions/generic-actions'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useFormContext } from 'react-hook-form'

interface ReceptionBookingExtendedStayActions {
  options: BookingExtendedStayOption[] | undefined
  onOptionsChange: (options: BookingExtendedStayOption[]) => void
  totalPrice: number
  selectedOption: BookingExtendedStayOption | undefined
  isLoading: boolean
}

interface ReceptionBookingExtendedStayContainerProps {
  render: (element: ReceptionBookingExtendedStayActions) => React.ReactElement
  booking: ReceptionBooking
}

export const ReceptionBookingExtendedStayContainer: React.FC<ReceptionBookingExtendedStayContainerProps> = ({
  render,
  booking,
}) => {
  const [options, setOptions] = React.useState<BookingExtendedStayOption[] | undefined>(undefined)

  const { watch } = useFormContext()

  const { isLoading, action: fetchExtendedStayOptions } = useApiRequest(async () =>
    setOptions(await commonObjectGet<BookingExtendedStayOption[]>(booking.urls.extended_stay)),
  )

  React.useEffect(() => {
    fetchExtendedStayOptions()
  }, [])

  const selectedKind = watch('kind')

  const selectedOption: BookingExtendedStayOption | undefined = React.useMemo(
    () => (options ? options.find(option => option.kind === selectedKind) : undefined),
    [selectedKind, options],
  )

  const dispatch = useAppDispatch()
  React.useEffect(() => {
    dispatch(getReceptionBookingDetails(booking.urls.api_details))
  }, [])

  const totalPrice = selectedOption?.price_brutto || 0

  return render({
    options,
    onOptionsChange: setOptions,
    totalPrice,
    selectedOption,
    isLoading,
  })
}
