import * as React from 'react'
import { Button, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { SaveButton } from '@hyper/button'
import { useAppDispatch } from '@store/index'
import { FormProvider, useForm } from 'react-hook-form'
import { FormSelect } from '@hyper/forms/form-select'
import { createReportTask } from '@store/actions/report-tasks-actions'
import { endOfMonth, startOfMonth, startOfToday } from 'date-fns'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { BaseModalProps } from '@components/modals/types'
import { extractSelectOptionsValues } from '@helpers/utils'
import { useAppData } from '@components/hooks/use-app-data'
import { useApartments } from '@components/hooks/use-apartments'

export interface FloorHeatingReportFormInputs {
  date_from: Date
  date_to: Date
}

export const ReportsFloorHeatingModal: React.FC<BaseModalProps> = ({ toggleIsVisible }) => {
  const { urls } = useAppData()
  const dispatch = useAppDispatch()
  const { apartments } = useApartments(9)

  const ciepliceApartmentOptions = apartments.map(apartment => ({
    label: `C${apartment.name}`,
    value: `C${apartment.name}`,
  }))

  const methods = useForm<FloorHeatingReportFormInputs>({
    defaultValues: {
      date_from: startOfMonth(startOfToday()),
      date_to: endOfMonth(startOfToday()),
    },
  })

  const { isLoading, action: handleSubmit } = useReportFormRequest(async () => {
    methods.clearErrors()
    const payload = {
      ...extractSelectOptionsValues<FloorHeatingReportFormInputs, {}>(methods.getValues()),
    }

    const reportTask = await dispatch(createReportTask([urls.reports.report_task_floor_heating_report, payload]))
    toggleIsVisible()
    return reportTask
  }, methods.setError)

  return (
    <FormProvider {...methods}>
      <ModalHeader toggle={toggleIsVisible}>Raport liczników ogrzewania podłogowego</ModalHeader>
      <ModalBody>
        <Row>
          <FormSelect
            label="Lokale"
            options={ciepliceApartmentOptions}
            name="apartment_names"
            selectProps={{
              placeholder: 'Wybierz maksymalnie 5 lokali',
              isClearable: true,
              isSearchable: true,
              isMulti: true,
            }}
          />
        </Row>
        <Row>
          <DateFromToInput label="Przedział dat" startDateName="date_from" endDateName="date_to" />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton
          className="btn btn-green"
          label="Zleć raport"
          labelSaving="Zlecanie..."
          isSaving={isLoading}
          onClick={handleSubmit}
        />
      </ModalFooter>
    </FormProvider>
  )
}
