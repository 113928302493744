import { useApiRequest } from '@components/hooks/use-api-request'
import { PackageDetails } from '@models/package'
import { getPackageDetails } from '@store/actions/package-actions'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import * as React from 'react'
import { ContentNotesCard } from '@components/content-notes/content-notes-card'

interface Props {
  packageDetails: PackageDetails
}

export const PackageDetailsNotes: React.FC<Props> = ({ packageDetails }) => {
  const contentType = useAppSelector((state: RootState) => state.appState.appData.content_types.package)

  const dispatch = useAppDispatch()

  const { action: handleRefresh } = useApiRequest(
    async () => await dispatch(getPackageDetails(packageDetails.urls.details)),
  )

  return (
    <div data-testid="package-details-notes">
      <ContentNotesCard
        readOnly={false}
        objectId={packageDetails.id}
        contentType={contentType}
        onNotesUpdate={handleRefresh}
        notes={packageDetails.notes}
      />
    </div>
  )
}
