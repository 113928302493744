import * as React from 'react'
import { Button, Row } from 'reactstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { TableFilters } from '@components/table/table'
import { CafeteriaCreateButton } from '@modules/cafeteria/create'
import { ColAuto } from '@hyper/col-auto'
import { FormSelect } from '@hyper/forms/form-select'
import { subscriptionKindOptions } from '@modules/subscription/models'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { createSelectOption } from '@helpers/utils'
import { useCafeteriaAppData } from '@modules/cafeteria/hooks/use-cafeteria-app-data'

export interface CafeteriaListFilterParams extends TableFilters {
  kind: null | CustomReactSelectOption
  status: CustomReactSelectOption[]
  operator: CustomReactSelectOption[]
}

interface Props {
  defaultFilters: CafeteriaListFilterParams
  filters: CafeteriaListFilterParams
  setFilters: (filters: CafeteriaListFilterParams) => void
  statusOptions: CustomReactSelectOption[]
}

export const CafeteriaListFilter: React.FC<Props> = ({ defaultFilters, filters, setFilters, statusOptions }) => {
  const user = useAuthenticatedUser()
  const { cafeteria_operators } = useCafeteriaAppData()

  const cafeteriaOperatorOptions = cafeteria_operators.map(operator => createSelectOption(operator.name, operator.id))

  const methods = useForm<CafeteriaListFilterParams>({
    defaultValues: defaultFilters,
  })

  const handleReset = () => {
    methods.reset()
    setFilters(defaultFilters)
  }

  const onSubmit = async (payload: CafeteriaListFilterParams) => setFilters({ ...filters, ...payload })

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  return (
    <FormProvider {...methods}>
      <Row>
        <FormSelect
          options={statusOptions}
          name="status"
          label="Status"
          selectProps={{ isSelectMulti: true, isClearable: true }}
          formPlainProps={{ colSize: 2 }}
        />

        <FormSelect
          options={cafeteriaOperatorOptions}
          name="operator"
          label="Operator"
          selectProps={{ isSelectMulti: true, isClearable: true }}
          formPlainProps={{ colSize: 2 }}
        />

        <FormSelect
          options={subscriptionKindOptions}
          name="kind"
          label="Rodzaj pakietu"
          selectProps={{ isClearable: true }}
          formPlainProps={{ colSize: 2 }}
        />
        {methods.formState.isDirty && (
          <ColAuto>
            <Button color="light" className="mt-label" type="button" onClick={handleReset}>
              Wyczyść
            </Button>
          </ColAuto>
        )}
        <ColAuto className="ml-auto px-2">
          <label className="d-block">&nbsp;</label>
          {user.hasPerm(UserPermission.ProductsCafeteriaCanCreate) && <CafeteriaCreateButton />}
        </ColAuto>
      </Row>
      <Row>
        <SearchFilter colClassName="col-md-2" />
      </Row>
    </FormProvider>
  )
}
