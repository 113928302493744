import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { ReceptionBookingDetailsFeeding } from '@modules/reception/details/reception-booking-details-feeding'
import { BaseModalProps } from '@components/modals/types'

interface Props extends BaseModalProps {
  onSave: () => void
  bookingDetails: ReceptionBookingDetails
}

export const FeedingHostApartmentInfoReservationDetailsDialog: React.FC<Props> = ({
  onSave,
  bookingDetails,
  toggleIsVisible,
}) => (
  <>
    <ModalHeader toggle={toggleIsVisible}>
      <span className="text-semi-strong">Szczegóły wyżywienia rezerwacji</span>{' '}
      <strong>#{bookingDetails.reservation_number}</strong>
    </ModalHeader>
    <ModalBody>
      <ReceptionBookingDetailsFeeding onSave={onSave} booking={bookingDetails} />
    </ModalBody>
    <ModalFooter>
      <Button color="light" onClick={toggleIsVisible}>
        Zamknij
      </Button>
    </ModalFooter>
  </>
)
