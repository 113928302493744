import * as React from 'react'
import { ReceptionBookingDetailsImprovement } from '@models/booking'
import classNames from 'classnames'
import { asDecimal, formatPriceShort } from '@helpers/utils'
import { formatDate, parseISODate } from '@helpers/date-helper'
import { ImprovementIcon } from '@components/improvement-icon'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { ReceptionBookingDetails } from '@models/reception'
import { deleteBookingImprovement } from '@store/actions/reception-actions'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { commonObjectPut } from '@store/actions/generic-actions'
import { useApiRequest } from '@components/hooks/use-api-request'
import { PriceLoader } from '@modules/reception/checkin/step-feeding/price-loader'
import { SpinInput } from '@components/spin-input'
import { ReceptionBookingCheckInImprovementDateInput } from '@modules/reception/checkin/step-improvements/reception-booking-check-in-improvement-date-input'
import { useCancelablePromise } from '@components/hooks/use-cancelable-promise'
import { BookingImprovementsAddedRowSmartWatch } from '@modules/reception/checkin/step-improvements/booking-improvements-added/rows-smart-watch'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { setReceptionBookingDetails } from '@store/slices/reception-slice'

interface FormInputs {
  amount: number
  date_from: Date | null
  date_to: Date | null
}

interface Row {
  booking: ReceptionBookingDetails
  bookingImprovement: ReceptionBookingDetailsImprovement
}

export const BookingImprovementsAddedRow: React.FC<Row> = ({ booking, bookingImprovement }) => {
  const dispatch = useAppDispatch()
  const { addSuccessNotification, addErrorMessage } = useNotificationHook()
  const resortImprovements =
    useAppSelector((state: RootState) => state.receptionState.resortDetails?.improvements) || []

  const improvement = React.useMemo(
    () => resortImprovements.find(row => row.code === bookingImprovement.code),
    [bookingImprovement.code, resortImprovements],
  )

  const methods = useForm<FormInputs>({
    defaultValues: {
      amount: bookingImprovement.amount || 1,
      date_from: parseISODate(bookingImprovement.date_from),
      date_to: parseISODate(bookingImprovement.date_to),
    },
  })

  const { isLoading: isDeleteLoading, action: handleDelete } = useApiRequest(async (deleteReason: string) => {
    await dispatch(deleteBookingImprovement(bookingImprovement.urls.details, deleteReason))
    addSuccessNotification('Ulepszenie zostało usunięte!')
  })

  const areValuesChanged = (date_to, date_from, amount) =>
    bookingImprovement.date_to !== formatDate(date_to) ||
    bookingImprovement.date_from !== formatDate(date_from) ||
    amount !== bookingImprovement.amount

  const { action: updateImprovement } = useApiRequest(async cancelToken => {
    const { amount, date_to, date_from } = methods.getValues()

    if (!areValuesChanged(date_to, date_from, amount || 1)) {
      return
    }

    try {
      await dispatch(
        setReceptionBookingDetails(
          await commonObjectPut<ReceptionBookingDetails>(
            bookingImprovement.urls.details,
            [
              {
                amount: amount || 1,
                date_from: formatDate(date_from),
                date_to: formatDate(date_to),
                code: bookingImprovement.code,
              },
            ],
            cancelToken,
          ),
        ),
      )
      addSuccessNotification('Ulepszenie zostało zmienione!')
    } catch (error) {
      methods.reset({
        amount: bookingImprovement.amount || 1,
        date_from: parseISODate(bookingImprovement.date_from),
        date_to: parseISODate(bookingImprovement.date_to),
      })
      addErrorMessage('Wystąpił błąd', error.response.data[0].amount[0])
    }
  })

  const changes = useWatch({
    control: methods.control,
    name: ['amount', 'date_from', 'date_to'],
  })

  useCancelablePromise(cancelToken => updateImprovement(cancelToken), [...changes])

  const hasTwoPrice =
    asDecimal(bookingImprovement.original_single_price_brutto_first_item).gt(asDecimal(0)) &&
    bookingImprovement.original_single_price_brutto_first_item != bookingImprovement.original_single_price_brutto

  return (
    <FormProvider {...methods}>
      <tr className={classNames('font-14 reception__booking-check-in__improvement-step__table__row')}>
        <td className="reception__booking-check-in__improvement-step__improvement-col reception__booking-check-in__improvement-step__table__data">
          <div className="d-flex">
            <ImprovementIcon improvementCode={bookingImprovement.code} className="text-secondary font-14" />
            <div className="mx-1">
              <span className="text-semi-strong mr-1">{bookingImprovement.name}</span>
              <span className="text-muted font-11">
                {hasTwoPrice ? (
                  <>
                    +{formatPriceShort(bookingImprovement.original_single_price_brutto_first_item)}/
                    {formatPriceShort(bookingImprovement.original_single_price_brutto)}
                  </>
                ) : (
                  <>+{formatPriceShort(bookingImprovement.original_single_price_brutto)}</>
                )}
              </span>
              {['smartwatch_promotion', 'smartwatch'].includes(bookingImprovement.code) && (
                <BookingImprovementsAddedRowSmartWatch booking={booking} bookingImprovement={bookingImprovement} />
              )}
            </div>
          </div>
        </td>
        <td className={classNames('reception__booking-check-in__improvement-step__amount-col', {})}>
          <SpinInput inputName="amount" inputProps={{ min: 1, disabled: !improvement?.can_change_amount || false }} />
        </td>
        <td>
          <ReceptionBookingCheckInImprovementDateInput
            booking={booking}
            calculateEveryDay={improvement?.calculate_every_day || false}
            disabled={!improvement?.can_change_days || true}
          />
        </td>
        <td className="reception__booking-check-in__improvement-step__table__data" />

        <td className="text-right reception__booking-check-in__improvement-step__table__data">
          {improvement?.can_remove && (
            <CommonObjectConfirmAction
              title="Potwierdź usunięcie udogodnienia"
              handleAccept={handleDelete}
              isLoading={isDeleteLoading}
              message="Czy na pewno chcesz usunąć udogodnienie?"
              askForReason={true}
            >
              <i className="uil-trash-alt cursor-pointer font-18 grey-400 mr-3" role="delete-improvement" />
            </CommonObjectConfirmAction>
          )}
          <span className="text-danger text-semi-strong d-inline-block reception__booking-check-in__improvement-step__price-col">
            <PriceLoader
              price={bookingImprovement.price_brutto}
              content={<span>={formatPriceShort(bookingImprovement.price_brutto)}</span>}
            />
          </span>
        </td>
      </tr>
    </FormProvider>
  )
}
