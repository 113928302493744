import * as React from 'react'
import { Card, CardBody, CustomInput } from 'reactstrap'
import { AgreementDetails, AgreementDocument, AgreementDocumentKey } from '@modules/hr/agreement/models'
import { useModal } from '@components/modals/use-modal'
import { FormCheckbox } from '@hyper/forms/form-checkbox'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { DocumentSelectionOptions } from '@modules/hr/agreement/details/document-selection-options'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { useAgreementDownloadCheck } from '@modules/hr/agreement/details/use-agreement-download-check'

interface FormInputs {
  documents: { [key: string]: boolean }
  all: boolean
}

interface Props {
  agreementDetails: AgreementDetails
  isReadonly: boolean
}

export const AgreementDetailsDocuments = React.forwardRef<HTMLElement, Props>(
  ({ isReadonly, agreementDetails }, ref) => {
    const { canDownload } = useAgreementDownloadCheck(agreementDetails)
    const [onEdit] = useModal('AgreementEditModal', { step: 5, agreementDetails })

    const handleEdit = (chunk: AgreementDocumentKey) => () => {
      onEdit(null, { agreementDetails, chunk })
    }

    const methods = useForm<FormInputs>({
      defaultValues: {
        all: false,
        documents: agreementDetails.documents.reduce((prev, document) => ({ ...prev, [document.key]: false }), {}),
      },
    })

    const allSelected = useWatch({ control: methods.control, name: 'all' })
    const selectedDocuments = useWatch({ control: methods.control, name: 'documents' })

    const handleSelectAll = event => {
      agreementDetails.documents.forEach(document => {
        methods.setValue(`documents.${document.key}`, event.target.checked)
      })
    }

    useDidUpdateEffect(() => {
      methods.setValue('all', Object.values(selectedDocuments).every(Boolean))
    }, [selectedDocuments])

    return (
      <Card innerRef={ref}>
        <FormProvider {...methods}>
          <CardBody>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h4 className="mb-0 text-secondary mt-0">Dokumenty</h4>
              {canDownload && <DocumentSelectionOptions agreementDetails={agreementDetails} />}
            </div>

            <table className="table table-sm mb-0">
              <thead>
                <tr>
                  <th className="border-0" style={{ width: '5%' }}>
                    <CustomInput
                      id="all"
                      type="checkbox"
                      checked={allSelected}
                      onChange={handleSelectAll}
                      className="form-checkbox no-label"
                    />
                  </th>
                  <th className="border-0" style={{ width: '80%' }}>
                    Nazwa
                  </th>
                  <th className="border-0" style={{ width: '15%' }} />
                </tr>
              </thead>
              <tbody>
                {agreementDetails.documents.map(document => (
                  <TableRow
                    key={document.name}
                    onEdit={handleEdit(document.key)}
                    document={document}
                    isReadonly={!['statements'].includes(document.key) || isReadonly}
                    withDownload={canDownload}
                  />
                ))}
              </tbody>
            </table>
          </CardBody>
        </FormProvider>
      </Card>
    )
  },
)

const TableRow = ({
  document,
  isReadonly,
  onEdit,
  withDownload,
}: {
  document: AgreementDocument
  isReadonly: boolean
  withDownload: boolean
  onEdit: () => void
}) => (
  <tr>
    <td>
      <FormCheckbox name={`documents.${document.key}`} />
    </td>
    <td>{document.name}</td>
    <td className="text-right">
      {!isReadonly && <i className="text-muted mr-2 uil-edit-alt cursor-pointer" onClick={onEdit} title="Edytuj" />}

      {withDownload && (
        <a href={document.url} target="_blank">
          <i className="text-muted uil-download-alt" title="Pobierz" />
        </a>
      )}
    </td>
  </tr>
)
