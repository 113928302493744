import * as React from 'react'
import { Col } from 'reactstrap'
import { useFormContext } from 'react-hook-form'
import { FormPlainError } from '@hyper/forms'
import { RootState, useAppSelector } from '@store/index'
import { CreateImprovementOrderModalFormInputs } from '@modules/housekeeping/cleaning-orders/create-improvement-order/create-improvement-order-modal'
import { useHousekeepingAppData } from '@modules/housekeeping/hooks/use-housekeeping-app-data'
import { createSelectOption } from '@helpers/utils'
import { FormSelect } from '@hyper/forms/form-select'

interface Props {
  index: number
  remove: (index: number) => void
}

export const CreateImprovementOrderModalFormImprovementsRow: React.FC<Props> = ({ index, remove }) => {
  const { register } = useFormContext<CreateImprovementOrderModalFormInputs>()
  const resortDetails = useAppSelector((state: RootState) => state.receptionState.resortDetails)
  const housekeepingAppData = useHousekeepingAppData(resortDetails?.id || 0)

  const improvements =
    resortDetails?.improvements.filter(row => housekeepingAppData.improvements_codes.includes(row.code)) || []

  const handleRemove = () => remove(index)

  const improvementOptions = React.useMemo(
    () => improvements.map(improvement => createSelectOption(improvement.name, improvement.code)),
    [improvements],
  )

  return (
    <>
      <FormSelect options={improvementOptions} name={`items.${index}.code`} formPlainProps={{ colSize: 7 }} />
      <Col md={3} className="mb-1">
        <input
          type="number"
          min={1}
          className="form-control form-control-sm bg-white"
          style={{ minHeight: 35 }}
          {...register(`items.${index}.amount`, { valueAsNumber: true })}
        />
        <FormPlainError name={`items.${index}.amount`} />
      </Col>
      <Col md={2} className="text-center">
        <i
          className="uil uil-trash-alt cursor-pointer font-18 text-muted pt-1 d-block"
          title="Skasuj"
          onClick={handleRemove}
        />
      </Col>
    </>
  )
}
