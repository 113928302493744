import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import { FormInput } from '@hyper/forms'
import { SaveButton } from '@hyper/button'
import { Form } from '@hyper/forms/form'
import { useFormRequest } from '@components/hooks/use-api-request'
import { useForm } from 'react-hook-form'
import { CleaningOrder, CleaningOrderDetails } from '@modules/housekeeping/models'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useAppDispatch } from '@store/index'
import { updateHousekeepingCleaningOrderDetails } from '@store/slices/housekeeping-slice'
import { useNotificationHook } from '@hyper/use-notification-hook'

interface FormInputs {
  content: string
}

interface Props extends BaseModalProps {
  order: CleaningOrder
}

export const HousekeepingOrderNoteModal: React.FC<Props> = ({ toggleIsVisible, order }) => {
  const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()

  const methods = useForm<FormInputs>({ defaultValues: { content: '' } })

  const { action: handleSubmit, isLoading } = useFormRequest(async (payload: FormInputs) => {
    dispatch(
      updateHousekeepingCleaningOrderDetails(await commonObjectPost<CleaningOrderDetails>(order.urls.notes, payload)),
    )
    toggleIsVisible()
    addSuccessMessage('Sukces', 'Notatka została dodana!')
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={handleSubmit}>
      <ModalHeader toggle={toggleIsVisible}>
        <span className="text-semi-strong">Dodaj notatkę do zlecenia</span>
      </ModalHeader>
      <ModalBody>
        <FormInput label="Treść notatki" name="content" type="textarea" colClassName="px-0" />
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton isSaving={isLoading} className="btn btn-green" />
      </ModalFooter>
    </Form>
  )
}
