import * as React from 'react'
import { ReceptionBookingDetails, ReceptionBookingDetailsCart } from '@models/reception'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectDelete, commonObjectGet } from '@store/actions/generic-actions'
import { useIntervalWhen } from 'rooks'

const USER_IN_CART_ACTIVITY_REFRESH_PERIOD_MS = 10000

interface Response {
  cart: ReceptionBookingDetailsCart | null
  setCart: (cart: ReceptionBookingDetailsCart) => void
  isLoading: boolean
}

export const useReceptionBookingCart = (bookingDetails: ReceptionBookingDetails): Response => {
  const [cart, setCart] = React.useState<ReceptionBookingDetailsCart | null>(null)

  const { action: fetchCart, isLoading } = useApiRequest(async () => {
    setCart(await commonObjectGet(bookingDetails.urls.cart))
  })

  const { action: clearUserInCartActivity } = useApiRequest(async () => {
    if (!cart) return
    await commonObjectDelete(cart.urls.in_progress)
  })

  const { action: extendUserInCartActivity } = useApiRequest(async () => {
    if (!cart) return
    await commonObjectGet(cart.urls.in_progress)
  })

  useIntervalWhen(extendUserInCartActivity, USER_IN_CART_ACTIVITY_REFRESH_PERIOD_MS, !!cart)

  React.useEffect(() => {
    fetchCart()
  }, [])

  React.useEffect(
    () => () => {
      clearUserInCartActivity()
    },
    [cart?.id],
  )

  return { cart, isLoading, setCart }
}
