import * as React from 'react'
import { Alert } from 'reactstrap'

export const ReceptionBookingNewFeedingSummaryManualPriceChangeAlert = (): JSX.Element => (
  <Alert className="py-1 px-1 alert-info ">
    <strong className="font-12">Uwaga! </strong>
    <span className="font-12">
      Dokonano ręcznej edycji ceny jednostkowej wybranych posiłków. Nadanie dodatkowych rabatów i/lub połączenie
      posiłków w Bestseller nie będą miały dla nich zastosowania. W tym celu należy najpierw wycofać wprowadzone zmiany
      (przez osoby uprawnione)
    </span>
  </Alert>
)
