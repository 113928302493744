import * as React from 'react'
import { usePackageWholesaleAppData } from '@modules/package-wholesale/hooks/use-package-wholesale-app-data'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { PackageWholesaleProductFormInputs } from '@modules/package-wholesale/products/create/modal'
import { IconWithText } from '@components/icon-with-text'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { Col } from 'reactstrap'
import { PackageWholesaleProductCreateFormSubscriptionAdditionalOptionRow } from '@modules/package-wholesale/products/create/additional-option-row'
import { createSelectOption } from '@helpers/utils'

export const PackageWholesaleProductCreateFormSubscriptionAdditionalOptions = (): JSX.Element => {
  const packageWholesaleAppData = usePackageWholesaleAppData()
  const { control } = useFormContext<PackageWholesaleProductFormInputs>()

  const { append, fields, remove, update } = useFieldArray({ control, name: 'options' })

  const availableOptions =
    packageWholesaleAppData.available_options?.map(option => createSelectOption(option.name, option.kind)) || []

  const handleAppend = (kind: CustomReactSelectOption | null = null) => {
    append({ kind, price_brutto: '', description: '' })
  }

  const addedOptions = useWatch({ control, name: 'options' })

  const notAddedOptions = availableOptions.filter(
    availableOption => !addedOptions.some(addedOption => availableOption.value === addedOption.kind?.value),
  )

  React.useEffect(() => {
    if (!fields.length) {
      handleAppend()
    }
  }, [fields])

  const canAddNextOne = !!addedOptions.at(-1)?.kind && !!notAddedOptions.length

  const isRemovable = (index: number) => {
    const option = addedOptions[index]
    if (!option) return false

    return !!option.kind?.value || !!option.description || !!option.price_brutto
  }

  const handleRemove = (index: number) => {
    if (addedOptions.length === 1) {
      update(index, { kind: null, description: '', price_brutto: '' })
    } else {
      remove(index)
    }
  }

  return (
    <Col className="mb-3">
      {fields.map((field, index) => (
        <PackageWholesaleProductCreateFormSubscriptionAdditionalOptionRow
          availableOptions={notAddedOptions}
          key={field.id}
          index={index}
          isRemovable={isRemovable(index)}
          onDelete={handleRemove}
        />
      ))}

      {canAddNextOne && (
        <IconWithText
          flexClassName="d-block"
          icon="uil-plus font-16 lh-initial"
          text="Dodaj kolejną opcję dodatkową"
          textClass="font-12 text-semi-strong"
          wrapperClassNames="text-secondary"
          onClick={() => handleAppend()}
        />
      )}
    </Col>
  )
}
