import * as React from 'react'
import { PackageWholesaleProduct } from '@modules/package-wholesale/models'
import { SubscriptionKind } from '@modules/subscription/models'

interface CumulatedSubscriptionKinds {
  subscription_kind: SubscriptionKind
  subscription_kind_display: string
}

export const usePackageWholesaleProductData = (
  addedProductsIds: (string | number)[],
  packageWholesaleProducts: PackageWholesaleProduct[],
) => {
  const addedProducts = React.useMemo(
    () =>
      addedProductsIds.map(productId =>
        packageWholesaleProducts.find(product => product.id === parseInt(productId.toString(), 10)),
      ),
    [packageWholesaleProducts, addedProductsIds],
  )

  const kinds = [...new Set(addedProducts.map(el => el?.kind))]

  const subscriptionKinds = React.useMemo(
    () =>
      addedProducts
        .filter(product => product?.kind === 'subscription')
        .reduce((cumulated: CumulatedSubscriptionKinds[], product: PackageWholesaleProduct) => {
          if (cumulated.some(e => e.subscription_kind === product.subscription_kind)) return cumulated
          return [
            ...cumulated,
            {
              subscription_kind: product.subscription_kind,
              subscription_kind_display: product.subscription_kind_display,
            },
          ]
        }, []),
    [addedProducts],
  )

  const getSubscriptionLeafletData = (kind: CumulatedSubscriptionKinds) => {
    let inputName = 'leaflet_subscription'

    if (kind.subscription_kind === 'brown') inputName = 'leaflet_subscription_brown'
    if (kind.subscription_kind === 'silver') inputName = 'leaflet_subscription_silver'
    if (kind.subscription_kind === 'gold') inputName = 'leaflet_subscription_gold'

    return {
      label:
        kind.subscription_kind === 'gray' ? 'Ulotka pobytów' : `Ulotka pobytów (${kind.subscription_kind_display})`,
      inputName,
    }
  }

  return {
    kinds,
    subscriptionKinds,
    getSubscriptionLeafletData,
  }
}
