import { toast, ToastContent } from 'react-toastify'
import * as React from 'react'
import { useAppDispatch, useAppSelector } from '@store/index'
import { v4 as uuidv4 } from 'uuid'
import {
  addNotificationMessage,
  notificationMessagesSelector,
  removeNotificationMessage,
} from '@store/slices/notification-slice'
import { ToastOptions } from 'react-toastify/dist/types'
import { NotificationMessage, NotificationMessageModalConfig, Variant } from './notifications-models'

export const buildErrorMessage = (
  title = 'Błąd',
  message = 'Przepraszamy wystąpił błąd, spróbuj ponownie później.',
) => ({
  title,
  message,
  variant: Variant.Error,
  id: uuidv4(),
})

const buildToast = (message, icon, role): [ToastContent, ToastOptions] => [
  <>
    <i className={`${icon} font-14`} /> {message}
  </>,
  {
    role: role,
    position: toast.POSITION.TOP_RIGHT,
    hideProgressBar: true,
  },
]

export const useNotificationHook = () => {
  const messages = useAppSelector(notificationMessagesSelector)
  const dispatch = useAppDispatch()

  const addMessage = (message: NotificationMessage) => dispatch(addNotificationMessage(message))

  const addErrorMessage = (
    title = 'Błąd',
    message: React.ReactNode = 'Przepraszamy wystąpił błąd, spróbuj ponownie później.',
    action?,
    onBeforeClose?,
    buttonText?,
  ) => {
    addMessage({ title, action, onBeforeClose, message, variant: Variant.Error, id: uuidv4(), buttonText })
  }

  const addSuccessMessage = (
    title: React.ReactNode = 'Sukces',
    message: React.ReactNode = 'Zmiany zostały zapisane pomyślnie!',
    action?,
    onBeforeClose?,
    modalConfig?: NotificationMessageModalConfig,
  ) => {
    addMessage({
      title,
      action,
      onBeforeClose,
      message,
      variant: Variant.Success,
      id: uuidv4(),
      modalConfig,
    })
  }

  const addWarningMessage = (
    title: React.ReactNode = 'Uwaga',
    message: React.ReactNode = 'Czy na pewno chcesz wykonać tę akcję?',
    action?,
    onBeforeClose?,
    modalConfig?: NotificationMessageModalConfig,
  ) => {
    addMessage({
      title,
      action,
      onBeforeClose,
      message,
      variant: Variant.Warning,
      id: uuidv4(),
      modalConfig,
    })
  }

  const addSuccessNotification = (message: string) => {
    const [content, options] = buildToast(message, 'uil-check', 'success-modal')
    return toast.success(content, options)
  }

  const addErrorNotification = (message: string) => {
    const [content, options] = buildToast(message, 'uil-ban', 'error-modal')

    toast.error(content, options)
  }

  const addInfoNotification = (message: string) => {
    const [content, options] = buildToast(message, 'uil-info-circle', 'info-modal')

    toast.info(content, options)
  }

  const removeMessage = (messageToRemove: NotificationMessage) => dispatch(removeNotificationMessage(messageToRemove))

  return {
    removeMessage,
    addInfoNotification,
    addErrorNotification,
    addSuccessNotification,
    addSuccessMessage,
    addWarningMessage,
    addErrorMessage,
    messages,
  }
}
