import * as React from 'react'
import { Col } from 'reactstrap'

interface Props {
  context: object
}

export const NotificationVariables = ({ context }: Props): JSX.Element | null => {
  const variables = Object.entries(context)
  if (!variables.length) {
    return null
  }

  return (
    <Col md={12}>
      <strong className="mb-1 d-block">Dostępne zmienne:</strong>
      <ul>
        {variables.map(([key, name]) => (
          <li key={key}>
            <code>
              {'{{ '}
              {key}
              {' }}'}
            </code>{' '}
            - {name}
          </li>
        ))}
      </ul>
    </Col>
  )
}
