import * as React from 'react'
import { ClientDetails } from '@models/clients'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { ClientDetailsModalBaseDataBlackList } from '@modules/crm/clients/details-modal/base-data/black-list'
import { ColAuto } from '@hyper/col-auto'
import { IconWithText } from '@components/icon-with-text'
import { ClientDetailsModalBaseDataTable } from '@modules/crm/clients/details-modal/base-data/table'
import { ClientDetailsModalBaseDataForm } from '@modules/crm/clients/details-modal/base-data/form'
import { ClientDetailsModalBaseDataServiceCharge } from '@modules/crm/clients/details-modal/base-data/skip-service-charge'
import { ClientDetailsModalAgreements } from '@modules/crm/clients/details-modal/base-data/agreements'
import { ClientDetailsModalBaseDataServicesRulesObjections } from '@modules/crm/clients/details-modal/base-data/services-rules-objections'

interface Props {
  client: ClientDetails
}

export const ClientDetailsModalBaseData: React.FC<Props> = ({ client }) => {
  const [isEditMode, setIsEditMode] = React.useState(false)

  const toggleEditMode = () => setIsEditMode(state => !state)

  return (
    <Col md={6}>
      <Card>
        <CardBody>
          <Row>
            <ColAuto>
              <h4 className="mt-0  text-secondary mb-3">Dane konta</h4>
            </ColAuto>
            {!isEditMode && (
              <ColAuto className="col-auto ml-auto">
                <IconWithText
                  icon="uil-edit-alt font-16"
                  text="zmień"
                  wrapperClassNames="cursor-pointer text-secondary"
                  textClass="font-10 text-semi-strong"
                  onClick={toggleEditMode}
                />
              </ColAuto>
            )}
          </Row>
          {!isEditMode && <ClientDetailsModalBaseDataTable client={client} />}
          {isEditMode && <ClientDetailsModalBaseDataForm client={client} toggleEditMode={toggleEditMode} />}
          {isEditMode && <div className="hr hr-grey mb-1 mt-3" />}
          <ClientDetailsModalAgreements client={client} />
          <div className="hr hr-grey my-1" />
          <ClientDetailsModalBaseDataBlackList client={client} />
          <ClientDetailsModalBaseDataServiceCharge client={client} />
          <ClientDetailsModalBaseDataServicesRulesObjections client={client} />
        </CardBody>
      </Card>
    </Col>
  )
}
