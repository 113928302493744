import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Row } from 'reactstrap/lib'
import { FeedingSpecialGuestEnter, FeedingWorkerGuest } from '@modules/feeding/models'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { FeedingHosFormInputs } from '@modules/feeding/host/feeding-host-with-params-view'
import { fetchSpecialGuests, fetchWorkerGuests } from '@api/feeding'
import { FeedingHostGuestsModalRow } from '@modules/feeding/host/guests-modal/row'
import { FormInput } from '@hyper/forms'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { caseInsensitiveFilter, extractSelectOptionsValues } from '@helpers/utils'
import { BaseModalProps } from '@components/modals/types'
import { useApiRequest } from '@components/hooks/use-api-request'

interface Props extends BaseModalProps {
  guestsKind: 'worker' | 'special'
  values: FeedingHosFormInputs
}

interface FormInputs {
  query: string
}

export const FeedingHostGuestsModal: React.FC<Props> = ({ toggleIsVisible, guestsKind, values }) => {
  const [guests, setGuests] = React.useState<FeedingSpecialGuestEnter[] | FeedingWorkerGuest[] | undefined>(undefined)

  const methods = useForm<FormInputs>({ defaultValues: { query: '' } })

  const { action: fetch } = useApiRequest(async () => {
    setGuests(
      guestsKind === 'special'
        ? await fetchSpecialGuests(extractSelectOptionsValues(values))
        : await fetchWorkerGuests(extractSelectOptionsValues(values)),
    )
  })

  React.useEffect(() => {
    fetch()
  }, [])

  const setGuest = (guest: FeedingSpecialGuestEnter | FeedingWorkerGuest) =>
    setGuests((guests || []).map(row => ({ ...(guest.id === row.id ? guest : row) })))

  const query = useWatch({ name: 'query', control: methods.control })

  const filteredGuests = (guests || []).filter(row => caseInsensitiveFilter(row.name)(query))

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>
        <span className="text-semi-strong">{guestsKind === 'special' ? 'Goście specjalni' : 'Pracownicy'}</span>{' '}
        <strong>{toDefaultDateFormat(values.date)}</strong>
      </ModalHeader>
      <ModalBody>
        <LoaderPlaceholder content={guests}>
          <>
            <Row>
              <FormProvider {...methods}>
                <FormInput placeholder="Znajdź gościa" name="query" />
              </FormProvider>
            </Row>
            <table className="table table-striped table-bordered">
              <colgroup>
                <col width={10} />
                <col />
                <col width={10} />
                <col width={10} />
              </colgroup>
              <tbody>
                {filteredGuests.map(row => (
                  <FeedingHostGuestsModalRow values={values} setGuest={setGuest} guest={row} key={row.id} />
                ))}
              </tbody>
            </table>
          </>
        </LoaderPlaceholder>
      </ModalBody>
      <ModalFooter>
        <Button color="green" onClick={toggleIsVisible}>
          Zamknij
        </Button>
      </ModalFooter>
    </>
  )
}
