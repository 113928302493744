import * as React from 'react'
import { useAppDispatch, useAppSelector } from '@store/index'
import { deletePromocodeGroup } from '@store/actions/promocode-actions'
import { PromocodeGroup } from '@models/promocode'
import { PromocodeGroupTableRowName } from '@modules/promocode/promocode-list/promocode-groups/promocode-group-table-row-name'
import { yearsPronunciation } from '@helpers/pronunciation'
import { useModal } from '@components/modals/use-modal'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { promocodePrefixesSelector } from '@store/slices/promocodes-slice'
import { IconWithText } from '@components/icon-with-text'
import { NavigationPath } from '@models/routes'
import { Link } from 'react-router-dom'
import { useApiRequest } from '@components/hooks/use-api-request'
import { PromocodeGroupRowCreated } from '@modules/promocode/promocode-list/promocode-groups/promocode-group-created'

interface PromocodeGroupTableRowProps {
  group: PromocodeGroup
}

export const PromocodeGroupTableRow: React.FC<PromocodeGroupTableRowProps> = ({ group }) => {
  const dispatch = useAppDispatch()

  const prefixes = useAppSelector(promocodePrefixesSelector)
  const [handleDetails] = useModal('PromocodeGroupDetailsDialog', { promocodeGroup: group })
  const [handleEdit] = useModal('PromocodeGroupEditDialog', { promocodeGroup: group })

  const prefix = prefixes?.find(p => p.id === group.prefix_id)

  const { isLoading, action: handleRemove } = useApiRequest(async () => {
    dispatch(await deletePromocodeGroup(group))
  })

  return (
    <tr>
      <td className="text-strong">
        <PromocodeGroupTableRowName group={group} />
      </td>
      <td className="text-nowrap">
        {prefix && (
          <IconWithText text={prefix.promo_value_display} icon="uil-usd-circle mr-1 text-muted font-18 text-strong" />
        )}
      </td>
      <td className="text-nowrap ">
        <PromocodeGroupRowCreated group={group} />
      </td>
      <td className="text-nowrap ">
        <i className="uil-calendar-alt mr-1 text-muted font-18 text-strong" />
        {group.relative_date_years
          ? `+ ${group.relative_date_years} ${yearsPronunciation(group.relative_date_years)}`
          : group.expire_after
            ? `Ważny do ${group.expire_after}`
            : 'Bezterminowy'}
      </td>
      <td className="text-right text-strong text-nowrap ">
        <i className="text-muted font-18 mr-2 uil-eye cursor-pointer" onClick={handleDetails} />
        <i className="text-muted font-18 mr-2 uil-edit cursor-pointer" onClick={handleEdit} />
        <CommonObjectConfirmAction handleAccept={handleRemove} isLoading={isLoading}>
          <i className="mr-2 text-muted font-18 uil-trash-alt cursor-pointer" />
        </CommonObjectConfirmAction>
        <Link to={NavigationPath.PromoCodePromoCodesList.replace(':group_id', String(group.id))}>
          <i className="mdi mdi-chevron-right text-dark text-strong font-18 cursor-pointer" />
        </Link>
      </td>
    </tr>
  )
}
