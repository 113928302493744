import * as React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { EmailInviteField, EmailInviteRow } from '@components/email-invite/email-invite-row'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  invites?: EmailInviteField[]
  title: string
  subtitle: string
}

export const EmailInvite = ({ invites = [], title, subtitle }: Props): JSX.Element => {
  const { control } = useFormContext()

  const { fields, append, remove } = useFieldArray({ control, name: 'emails' })

  const handleAppend = () => {
    append({ value: '' })
  }

  return (
    <>
      <h5 className="text-secondary mt-0">{title}</h5>
      <p className="col-7 px-0">{subtitle}</p>
      <div className="row">
        <strong className="col-4">Adres e-mail</strong>
        {fields.map((field, index) => (
          <EmailInviteRow
            invites={invites}
            key={field.id}
            fieldsAmount={fields.length}
            onRemove={() => remove(index)}
            index={index}
          />
        ))}
      </div>

      <div className="col-8 ml-auto mt-2">
        <IconWithText
          icon="uil-plus font-16"
          text="Dodaj kolejny adres e-mail"
          wrapperClassNames="text-secondary"
          onClick={handleAppend}
        />
      </div>
    </>
  )
}

export const handleEmailInvitationErrors = setError => (key, params) => {
  let fieldKey = key

  if (key.startsWith('emails') && key.endsWith('0')) {
    const formIndex = key.split('.')[1]
    fieldKey = `emails.${formIndex}.value`
  }

  setError(fieldKey, params)
}
