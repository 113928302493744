import * as React from 'react'
import { SubscriptionDetails } from '@modules/subscription/models'
import { SubscriptionSingleModalBase } from '@modules/subscription/options/subscription-single-modal-base'
import { BaseModalProps } from '@components/modals/types'
import { RootState, useAppSelector } from '@store/index'

interface Props extends BaseModalProps {
  subscriptionDetails: SubscriptionDetails
}

export const SubscriptionUpgradeToPurpleModal: React.FC<Props> = ({ subscriptionDetails, toggleIsVisible }) => {
  const { subscription_upgrade_to_purple_prices: options } = useAppSelector(
    (state: RootState) => state.appState.appData.settings,
  )

  return (
    <SubscriptionSingleModalBase
      title="Dodaj jednorazowy dostęp do lokali specjalnych"
      message="Dostęp do lokali specjalnych został dodany"
      description="Opcja dostępu do lokali specjalnych"
      toggleIsVisible={toggleIsVisible}
      amounts={options}
      subscriptionDetails={subscriptionDetails}
      url={subscriptionDetails.urls.subscription_upgrade_to_purple}
    />
  )
}
