import * as React from 'react'
import { ImprovementIcon } from '@components/improvement-icon'
import { ReceptionBookingDetailsImprovement } from '@models/booking'

interface Props {
  improvements: ReceptionBookingDetailsImprovement[]
}

const TimelineItemTooltipImp: React.FC<Props> = ({ improvements }) => (
  <div className="d-flex flex-wrap ml-1">
    {improvements.map(imp => (
      <ImprovementIcon key={imp.id} improvementCode={imp.code} className="calendar-tooltip__icon" />
    ))}
  </div>
)

export default TimelineItemTooltipImp
