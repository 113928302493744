import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { Button, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useForm } from 'react-hook-form'
import { endOfMonth, startOfMonth, startOfToday } from 'date-fns'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { createReportTask } from '@store/actions/report-tasks-actions'
import { ReportFormat, ReportGenerateFormatDropdownButton } from '@components/report-generate-format-dropdown-button'
import { Form } from '@hyper/forms/form'
import { FormSelect } from '@hyper/forms/form-select'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useMultipleResorts } from '@components/hooks/use-multiple-resorts'
import { createSelectOption } from '@helpers/utils'

interface FormInputs {
  finished_at_after: Date
  finished_at_before: Date
  resorts: CustomReactSelectOption[]
  report_format: ReportFormat
}

interface Props extends BaseModalProps {
  resorts?: CustomReactSelectOption[]
  finished_at_after: Date
  finished_at_before: Date
  urlField?: 'report_task_housekeeping_users_report' | 'report_task_housekeeping_users_report_new'
}

export const ReportsHousekeepingUsersModal: React.FC<Props> = ({
  toggleIsVisible,
  resorts,
  finished_at_after,
  finished_at_before,
  urlField = 'report_task_housekeeping_users_report',
}) => {
  const reportUrl = useAppSelector((state: RootState) => state.appState.appData.urls.reports[urlField])

  const dispatch = useAppDispatch()

  const methods = useForm<FormInputs>({
    mode: 'all',
    defaultValues: {
      finished_at_after: finished_at_after || startOfMonth(startOfToday()),
      finished_at_before: finished_at_before || endOfMonth(startOfToday()),
      report_format: 'xlsx',
      resorts: resorts?.length ? resorts : [createSelectOption('Wszystkie', 'all')],
    },
  })

  const { getResortOptions, getSelectedResortsIds } = useMultipleResorts({ methods, field: 'resorts' })
  const resortOptions = React.useMemo(() => getResortOptions({ withAllOption: true }), [])

  const { isLoading, action: onSubmit } = useReportFormRequest(async (payload: FormInputs) => {
    const reportTask = await dispatch(
      createReportTask([reportUrl, { ...payload, resorts: getSelectedResortsIds(payload.resorts) }]),
    )
    toggleIsVisible()
    return reportTask
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Housekeeping - Raport zbiorczy</ModalHeader>
      <ModalBody>
        <Row>
          <DateFromToInput
            isClearable={false}
            label="Data"
            startDateName="finished_at_after"
            endDateName="finished_at_before"
          />
          <FormSelect options={resortOptions} name="resorts" label="Ośrodek" selectProps={{ isMulti: true }} />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <ReportGenerateFormatDropdownButton onSubmit={onSubmit} isLoading={isLoading} />
      </ModalFooter>
    </Form>
  )
}
