import * as React from 'react'
import { GastroCard } from '@models/promotions'
import Table from '@components/table/table'
import { formatPrice } from '@helpers/utils'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { useModal } from '@components/modals/use-modal'
import { TableSellerRow } from '@components/table-seller-row'
import { TableUserRow } from '@components/table-user-row'
import { StatusBadge } from '@modules/promotions/common/status-badge'

interface Props {
  gastroCard: GastroCard
}

export const ClientDetailsModalGastroCardsRow: React.FC<Props> = ({ gastroCard }) => {
  const [toggleGastroCard] = useModal('GastroCardDetailsDialog', { gastroCard })

  return (
    <Table.Row key={gastroCard.id} className="cursor-pointer" onClick={toggleGastroCard}>
      <Table.Cell>{gastroCard.number}</Table.Cell>
      <Table.Cell>{gastroCard.product_name ?? ''}</Table.Cell>
      <Table.Cell>{formatPrice(gastroCard.sell_price)}</Table.Cell>
      <Table.Cell>
        <StatusBadge element={gastroCard} />
      </Table.Cell>
      <Table.Cell className="text-strong">{formatPrice(gastroCard.rest_to_use)}</Table.Cell>
      <Table.Cell>
        <TableSellerRow sellerId={gastroCard.seller_id} defaultValue={gastroCard.seller} className="d-block" />
      </Table.Cell>
      <Table.Cell>
        <TableUserRow userId={gastroCard.created_by_id} defaultValue={gastroCard.created_by} className="d-block" />
        <small className="font-10">{toDefaultDateFormat(gastroCard.created)}</small>
      </Table.Cell>
    </Table.Row>
  )
}
