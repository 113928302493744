import * as React from 'react'
import { ReceptionBookingDetails, ReceptionBookingGroup } from '@models/reception'
import { StepGuestsCarsAddParking } from '@modules/reception/checkin/step-guests/cars/step-guests-cars-add-parking'
import { StepGuestsCarsList } from '@modules/reception/checkin/step-guests/cars/step-guests-cars-list'
import classnames from 'classnames'
import { ParkingKind, ReceptionBookingDetailsImprovement } from '@models/booking'
import * as R from 'ramda'
import { IconWithText } from '@components/icon-with-text'

interface StepGuestsCarsProps {
  booking: ReceptionBookingDetails
  hideLabel?: boolean
  group: ReceptionBookingGroup
  borderless?: boolean
  kind?: ParkingKind
}

export const StepGuestsCars: React.FC<StepGuestsCarsProps> = ({
  group,
  booking,
  hideLabel,
  borderless,
  kind = 'parking2',
}) => {
  const maxCars = R.sum(
    booking.improvements
      .filter((improvement: ReceptionBookingDetailsImprovement) => improvement.code === kind)
      .map(improvement => improvement.amount),
  )

  const isParkingNormal = kind === 'parking2'
  const isParkingLarge = kind === 'parking_large'

  const parkingName = {
    parking2: '',
    parking_vip: 'gwarantowanym',
    parking_large: 'dla dużych pojazdów',
  }

  return (
    <tr className="reservation-guests__table__cars">
      {!hideLabel && (
        <td className="reservation-guests__table__column-cars reservation-guests__table__column-cars__first">
          <IconWithText
            icon="uil-car mr-1 font-14 align-self-start"
            text={<strong>Auta na parkingu {parkingName[kind]}</strong>}
          />
        </td>
      )}

      {!maxCars && (isParkingNormal || isParkingLarge) ? (
        <>
          <td className={classnames('reservation-guests__table__column-cars', { 'border-0': borderless })} colSpan={2}>
            <strong>Brak wykupionego ulepszenia parkingu {parkingName[kind]}</strong>
          </td>
          <td
            className={classnames(
              'reservation-guests__table__column-cars reservation-guests__table__column-cars__last text-right p-1 text-nowrap',
              { 'border-0': borderless },
            )}
            colSpan={4}
          >
            <StepGuestsCarsAddParking booking={booking} kind={kind} />
          </td>
        </>
      ) : (
        <td
          colSpan={4}
          className={classnames(
            'reservation-guests__table__column-cars reservation-guests__table__column-cars__last py-1',
            { 'border-0': borderless },
          )}
        >
          <StepGuestsCarsList booking={booking} maxCars={maxCars} group={group} kind={kind} />
        </td>
      )}
    </tr>
  )
}
