import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useModal } from '@components/modals/use-modal'
import { BookingExtendedStayKind, ReceptionBooking, ReceptionBookingDetails } from '@models/reception'
import { updateReceptionBooking, updateReceptionBookingDetails } from '@store/actions/reception-actions'
import { commonObjectPost } from '@store/actions/generic-actions'
import { formatDate } from '@helpers/date-helper'
import { ExtendedStayFormInputs } from '@modules/reception/extended-stay/reception-booking-extended-stay-dialog'

export const useExtendedStay = (booking: ReceptionBooking) => {
  const resortDetails = useAppSelector((state: RootState) => state.receptionState.resortDetails)

  const [showExtensionStayModal] = useModal(
    resortDetails?.has_band && booking.checked_in
      ? 'ReceptionBookingExtendedStayWizardDialog'
      : 'ReceptionBookingExtendedStayDialog',
    { booking },
    { persist: true },
  )

  const dispatch = useAppDispatch()

  const submit = async (payload: ExtendedStayFormInputs) => {
    const isDailyExtension = payload.kind === BookingExtendedStayKind.DAYS

    const updatedBooking = await commonObjectPost<ReceptionBookingDetails>(booking.urls.extended_stay, {
      kind: payload.kind,
      ...(isDailyExtension && {
        date_to: formatDate(payload.date_to),
        ...(payload.with_custom_stay_price && { custom_apartment_day_price: payload.custom_apartment_day_price }),
      }),
    })

    await dispatch(updateReceptionBooking(updatedBooking))
    await dispatch(updateReceptionBookingDetails(updatedBooking))
  }

  return {
    showExtensionStayModal,
    canExtend: booking.can_extend_stay,
    submit,
  }
}
