import * as React from 'react'
import { ReceptionBooking, ReceptionCheckType } from '@models/reception'
import { ReceptionToCheckOutRow } from '@modules/reception/to-checkout/row/reception-to-check-out-row'
import { ReceptionToCheckOutFiltersParams } from '@modules/reception/to-checkout/reception-to-checkout'
import { searchByBaseBookingParams } from '@modules/reception/common/search'
import * as R from 'ramda'
import { ReceptionButtons } from '@modules/reception/common/reception-buttons'
import { formatDate } from '@helpers/date-helper'
import { BookingStatus } from '@models/booking'
import { hasVip } from '@helpers/improvements'
import { receptionBookingsListSorter } from '@modules/reception/common/tools'

interface ReceptionToCheckOutListProps {
  bookings: ReceptionBooking[]
  filters: ReceptionToCheckOutFiltersParams
  resortId: number
}

const allCheckoutsFilter = (filters: ReceptionToCheckOutFiltersParams) => (booking: ReceptionBooking) => {
  const statuses: BookingStatus[] = ['close', 'confirmed']
  return filters.all_checkouts || (booking.date_to === formatDate(filters.date_to) && statuses.includes(booking.status))
}

const onlyVipFilter = (filters: ReceptionToCheckOutFiltersParams) => (booking: ReceptionBooking) =>
  !filters.only_vip || hasVip(booking)

const bookingsFilter = (bookings: ReceptionBooking[], filters: ReceptionToCheckOutFiltersParams) =>
  [...bookings].filter((booking: ReceptionBooking) => {
    if (filters.search && filters.search.trim()) {
      const query = filters.search.trim().toLocaleLowerCase()
      if (!searchByBaseBookingParams(booking, query)) {
        return false
      }
    }
    return true
  })

export const ReceptionToCheckOutList: React.FC<ReceptionToCheckOutListProps> = ({ resortId, bookings, filters }) => {
  const [filteredBookings, setFilteredBookings] = React.useState<ReceptionBooking[]>(bookings)

  React.useEffect(() => {
    setFilteredBookings(
      receptionBookingsListSorter(
        bookingsFilter(bookings.filter(allCheckoutsFilter(filters)).filter(onlyVipFilter(filters)), filters),
        filters,
        [R.ascend(R.prop('date_to')), R.descend(R.prop('checked_in'))],
      ),
    )
  }, [filters, bookings])

  const ids = filteredBookings.map(booking => booking.id)

  return (
    <>
      <table className="reception__bookings_table table table-striped">
        <tbody>
          {!filteredBookings.length ? (
            <tr>
              <td className="text-center">Brak rezerwacji</td>
            </tr>
          ) : (
            <>
              {filteredBookings.map(booking => (
                <ReceptionToCheckOutRow
                  date={filters.date_to}
                  booking={booking}
                  key={`${booking.id}-${booking.apartment?.id}`}
                />
              ))}
            </>
          )}
        </tbody>
      </table>
      <ReceptionButtons date={filters.date_to} ids={ids} resortId={resortId} type={ReceptionCheckType.TO_CHECKOUT} />
    </>
  )
}
