import * as React from 'react'
import { HousekeepingCleaningHighPriorityCleaningOrdersTable } from '@modules/housekeeping/cleaning-orders/ordered/normal-orders/housekeeping-cleaning-high-priority-cleaning-orders-table'
import { HousekeepingCleaningNormalCleaningOrdersTable } from '@modules/housekeeping/cleaning-orders/ordered/high-priority-orders/housekeeping-cleaning-normal-cleaning-orders-table'
import { CleaningOrder } from '@modules/housekeeping/models'
import { groupByPriority } from '@modules/housekeeping/cleaning-orders/ordered/utils'

interface Props {
  cleaningOrders: CleaningOrder[]
  resortId: number
}

export const HousekeepingCleaningOrdersOrderedDroppableTables = ({ cleaningOrders, resortId }: Props): JSX.Element => {
  const { normalOrders, highPriorityOrders } = React.useMemo(() => groupByPriority(cleaningOrders), [cleaningOrders])

  return (
    <>
      <HousekeepingCleaningHighPriorityCleaningOrdersTable cleaningOrders={highPriorityOrders} resortId={resortId} />
      <HousekeepingCleaningNormalCleaningOrdersTable cleaningOrders={normalOrders} resortId={resortId} />
    </>
  )
}
