import * as React from 'react'
import { Collapse } from 'reactstrap'
import { BaseHistory, BaseHistoryParamsChange } from '@models/dashboard'
import classNames from 'classnames'
import { ClientHistoryChangeValuesRow } from '@components/client-history/changes/client-history-change-values-row'
import { ClientHistoryChangeTitleRow } from '@components/client-history/changes/client-history-change-title-row'

interface Props {
  isExpanded: boolean
  history: Omit<BaseHistory, 'keyword'>
}

export const ClientHistoryChanges = ({ isExpanded, history }: Props): JSX.Element => (
  <Collapse isOpen={isExpanded} className="col-12 px-0">
    <div className="py-2">
      {history.message && (
        <div className="border border-info rounded p-1 mb-1 position-relative">
          <div className="reservation-details__history__changes__box__message">Wiadomość</div>
          <div className="fw-semi-bold font-11">Treść</div>
          <div className="text-pre-wrap font-11">{history.message}</div>
        </div>
      )}
      {history.params.changes && (
        <div className="reservation-details__history__changes__box position-relative">
          <div className={classNames('reservation-details__history__changes__box__old')}>Było</div>
          <div className={classNames('reservation-details__history__changes__box__new')}>Jest</div>
          <table className="reservation-details__history__changes__box__table">
            <tbody>
              {history.params.changes.map((change: BaseHistoryParamsChange, index: number) => (
                <>
                  <ClientHistoryChangeTitleRow change={change} isFirst={index === 0} />
                  <ClientHistoryChangeValuesRow change={change} />
                </>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  </Collapse>
)
