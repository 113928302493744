import * as React from 'react'
import { getStatusClassIcon } from '@modules/promotions/gastro-cards/utils'
import classNames from 'classnames'

interface ElementProps {
  status: string
  status_display: string
}

interface Props<T> {
  element: T
}

export const StatusBadge = <T extends ElementProps>({ element }: Props<T>): JSX.Element => {
  const [badgeClass, badgeIcon] = React.useMemo(() => getStatusClassIcon(element.status), [element.status])

  return (
    <span className={classNames('badge', badgeClass)}>
      {badgeIcon && <i className={classNames(badgeIcon, 'mr-1')} />}
      {element.status_display}
    </span>
  )
}
