import * as React from 'react'
import { useModal } from '@components/modals/use-modal'
import { PackageWholesaleProduct } from '@modules/package-wholesale/models'

interface TableRowEditProps {
  packageWholesaleProduct: PackageWholesaleProduct
}

export const PackageWholesaleProductListTableRowEdit: React.FC<TableRowEditProps> = ({ packageWholesaleProduct }) => {
  const [toggleModal] = useModal('PackageWholesaleProductUpdateModal', {
    packageWholesaleProduct,
  })

  return (
    <span className="font-16" onClick={toggleModal} role="edit-button">
      <i className="uil uil-pen cursor-pointer text-muted mr-1" title="Edytuj" />
    </span>
  )
}
