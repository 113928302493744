import * as React from 'react'
import { connect } from 'react-redux'
import { RootState } from '@store/index'
import { Apartment, ApartmentBookingFilter, ApartmentFetchDate } from '@models/apartment'
import { TimelineContentView } from '@modules/reservations/timeline/timeline-content'
import { TimelineWebSocketHandler } from '@modules/reservations/timeline/timeline-web-socket-handler'
import { CalendarDatesState, TimelineBooking } from '@store/reducers/timeline-reducers'
import { eachDayOfInterval, getDaysInMonth, setDate } from 'date-fns'
import { formatDate, parseISODate } from '@helpers/date-helper'
import { groupByField } from '@helpers/utils'

interface Props {
  apartments: Apartment[]
  fetchDate: ApartmentFetchDate
  resort: string
  accommodationTypeIds: string[]
  activeFilters: ApartmentBookingFilter[]
  calendarDates: CalendarDatesState
  bookings: TimelineBooking[]
}

export class TimelineComponent extends React.PureComponent<Props> {
  public render() {
    return (
      <div className="calendar__wrapper" data-testid="calendar-wrapper">
        <TimelineWebSocketHandler />
        <TimelineContentView
          accommodationTypeIds={this.props.accommodationTypeIds}
          resort={this.props.resort}
          apartments={this.filteredApartments}
          filters={this.props.activeFilters}
        />
      </div>
    )
  }

  get blockedApartmentIds(): number[] {
    const dates = this.props.calendarDates.dates
    if (!dates.length) return []

    const start = setDate(new Date(dates[0].year, dates[0].month - 1, 1), 1)

    const lastDate = new Date(dates[dates.length - 1].year, dates[dates.length - 1].month - 1, 1)
    const end = setDate(lastDate, getDaysInMonth(lastDate))

    const eachCalendarRangeDay = eachDayOfInterval({ start, end }).map((date: Date) => formatDate(date))

    const blockedReservations = this.props.bookings.filter((booking: TimelineBooking) => booking.status === 'blocked')

    const reservationGroupedByApartmentId = groupByField(blockedReservations, 'apartment_id')

    const blockedLocals = Object.entries(reservationGroupedByApartmentId).reduce(
      (apartmentsToHide, [apartmentId, bookings]) => {
        const dates = bookings.reduce(
          (allDates, booking) => [
            ...allDates,
            ...eachDayOfInterval({
              start: parseISODate(booking.date_from) as Date,
              end: parseISODate(booking.date_to) as Date,
            }).map((date: Date) => formatDate(date)),
          ],
          [],
        )

        if (eachCalendarRangeDay.every(el => dates.includes(el))) {
          return apartmentsToHide.add(parseInt(apartmentId, 10))
        }
        return apartmentsToHide
      },
      new Set<number>(),
    )

    return Array.from(blockedLocals)
  }

  get filteredApartments(): Apartment[] {
    let apartments = [...this.props.apartments]

    if (!this.props.activeFilters.includes('show_excluded_from_sale')) {
      apartments = apartments.filter(
        apartment => (apartment.available_for_sale && !apartment.is_virtual) || apartment.temporary_not_available,
      )
    }

    if (!this.props.activeFilters.includes('show_temporary_excluded_from_sale')) {
      apartments = apartments.filter(apartment => !apartment.temporary_not_available)
    }

    if (this.props.activeFilters.includes('animals')) {
      apartments = apartments.filter(apartment =>
        this.props.activeFilters.includes('animals') ? apartment.no_animals : true,
      )
    }

    if (this.props.activeFilters.includes('special_local')) {
      apartments = apartments.filter(apartment =>
        this.props.activeFilters.includes('special_local')
          ? apartment.tags.some(tag => tag.keyword === 'for_client_with_benefit')
          : true,
      )
    }

    if (this.props.activeFilters.includes('with_garden')) {
      apartments = apartments.filter(apartment =>
        this.props.activeFilters.includes('with_garden') ? apartment.has_garden : true,
      )
    }

    if (this.props.activeFilters.includes('without_garden')) {
      apartments = apartments.filter(apartment =>
        this.props.activeFilters.includes('without_garden') ? !apartment.has_garden : true,
      )
    }

    if (this.props.activeFilters.includes('hide_unavailable')) {
      apartments = apartments.filter(apartment => !this.blockedApartmentIds.includes(apartment.id))
    }

    return apartments
  }
}

const mapStateToProps = (state: RootState) => ({
  apartments: state.timelineState.apartments,
  fetchDate: state.timelineState.fetchDate,
  activeFilters: state.timelineState.activeFilters,
  calendarDates: state.timelineState.calendarDates,
  bookings: state.timelineState.bookings,
})

const Timeline = connect(mapStateToProps)(TimelineComponent)

export default Timeline
