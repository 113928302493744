import * as React from 'react'
import { PaymentDayReportDetails, PaymentDayUserReport } from '@models/payments'
import { Card, CardBody } from 'reactstrap'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { PaymentRegisterReceptionRow } from '@modules/payment-registers/cashbox-day-details/payment-register-reception/row'
import { groupByField } from '@helpers/utils'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  paymentDayReportDetails: PaymentDayReportDetails
}

const defaultFilters: TableFilters = {
  ordering: '',
  page: 1,
  page_size: 10,
  search: '',
}

const headerValues: TableHeaderValue<PaymentDayUserReport>[] = [
  {
    sortField: 'user_display',
    title: 'Recepcjonista',
  },
  {
    title: 'Stan początkowy',
    sortField: 'start_amount',
  },
  {
    title: 'Sprzedaż (KP)',
    sortField: 'amount',
  },
  {
    title: (
      <IconWithText
        icon="uil-info-circle font-16"
        text="W kasetce"
        tooltip="Stan początkowy kasetki + Sprzedaż (KP) gotówka + Zasilenia z sejfu - Wypłaty (KW) gotówka"
      />
    ),
    sortField: 'amount_sale_cash',
  },
  { title: '' },
  {
    title: (
      <IconWithText
        icon="uil-info-circle font-16"
        text="Zamknięcie dnia"
        tooltip="Gotówka przekazana z kasetki do sejfu"
      />
    ),
  },
  {
    title: (
      <IconWithText
        icon="uil-info-circle font-16"
        text="Pozostało w kasetce"
        tooltip="Gotówka pozostała w kasetce po zamknięciu zmiany"
      />
    ),
  },
  {
    title: '',
  },
]

export const PaymentRegisterReception = ({ paymentDayReportDetails }: Props): JSX.Element => {
  const [filters, setFilters] = React.useState<TableFilters>(defaultFilters)
  const userReports = groupByField<PaymentDayUserReport>(
    paymentDayReportDetails.user_reports.filter(row => row.status !== 'not_started'),
    'user_display',
  )

  return (
    <Card>
      <CardBody>
        <h4 className="text-secondary mb-3">Rozliczenie recepcji</h4>

        <Table
          className="w-100"
          headerValues={headerValues}
          isLoading={false}
          striped={false}
          filters={filters}
          showPagination={false}
          setFilters={setFilters}
        >
          {Object.entries<PaymentDayUserReport[]>(userReports).map(([user, reports], index) => (
            <PaymentRegisterReceptionRow key={user} user={user} reports={reports} index={index} />
          ))}
        </Table>
      </CardBody>
    </Card>
  )
}
