import { Apartment } from '@models/apartment'
import { BookingImprovementCode } from '@models/booking'
import { BaseObj, BaseUrls } from '@models/base'
import { createSelectOption } from '@helpers/utils'

interface HousekeepingUserUrls extends BaseUrls {
  acquire: string
  change_password: string
}
export interface HousekeepingUser extends BaseObj {
  housekeeping_app_language: string
  urls: HousekeepingUserUrls
  email: string
  first_name: string
  last_name: string
  rcp_badge: string
  resorts: number[]
  housekeeping_company: number
  phone: string
  is_active: boolean
  last_login: string
  is_housekeeping_handyman: boolean
  is_housekeeping_cleaning_company: boolean
  is_housekeeping_gardener: boolean
  is_housekeeping_manager: boolean
  is_housekeeping_bedding: boolean
}
export enum HousekeepingBeddingOrderType {
  PRESENT = 'present',
  ARCHIVED = 'archived',
}

export enum HousekeepingImprovementOrderType {
  PRESENT = 'present',
  ARCHIVED = 'archived',
}

export enum HousekeepingCommonTaskType {
  PRESENT = 'present',
  ARCHIVED = 'archived',
}

export enum HousekeepingCleaningOrderType {
  TO_ORDER = 'to_order',
  ORDERED = 'ordered',
  CLEAR = 'clear',
  ARCHIVE = 'archive',
  ACCEPTED = 'accepted',
  TO_CHECK = 'to_check',
  CLEANING = 'cleaning',
  COMMON_TASK = 'common_task',
  BEDDING = 'bedding',
  IMPROVEMENT = 'improvement',
}

export interface CleaningOrdersAggregation {
  bedding: number
  cleaning: number
  to_check: number
  ordered: number
  improvements: number
  accepted: number
  archive: number
  to_order: number
}

export interface CleaningOrderBooking {
  improvements: BookingImprovementCode[]
  id: number
  checked_in: boolean
  has_package_vip: boolean
  declared_arrival_time: string | null
  reservation_number: string
  name: string
  date_from: string
  arrival_time: string
  date_to: string
  urls: BaseUrls
}

interface CleaningOrderUrls {
  accept: string
  assign: string
  details: string
  finish_working: string
  notes: string
  pause_working: string
  position: string
  reopen: string
  revert_status: string
  start_working: string
  stop_working: string
}

type CommonTaskStatus = 'PENDING' | 'IN_PROGRESS' | 'FINISHED' | 'ARCHIVED' | 'ACCEPTED'

interface CommonTaskUrls {
  accept: string
  finish_working: string
  stop_working: string
  pause_working: string
  start_working: string
  details: string
}

export interface CommonTask {
  name: string
  finished_at: string
  work_started_at: string
  id: number
  resort: number
  status: CommonTaskStatus
  status_display: string
  total_time_started: number
  user_id: number
  user: string
  is_paused: boolean
  special_working_time: string | null
  urls: CommonTaskUrls
}

export type CleaningOrderServiceKind = 'REFRESH' | 'CLEANING'
export type HskOrderKind = 'DEPARTURE_ARRIVAL' | 'ARRIVAL' | 'DEPARTURE'
export type CleaningOrderStatus = 'PENDING' | 'CLEANING' | 'REOPENED' | 'FINISHED' | 'ACCEPTED' | 'ARCHIVED'

export const cleaningOrderServiceKindOptions = [
  createSelectOption('odświeżenie', 'REFRESH'),
  createSelectOption('sprzątanie', 'CLEANING'),
]

export const cleaningOrderStatusSelectOptions = [
  createSelectOption('oczekuje na rozpoczęcie', 'PENDING'),
  createSelectOption('w trakcie sprzątania', 'CLEANING'),
  createSelectOption('do poprawy', 'REOPENED'),
  createSelectOption('skończone', 'FINISHED'),
  createSelectOption('sprawdzone', 'ACCEPTED'),
]

export const improvementOrderStatusSelectOptions = [
  createSelectOption('do zlecenia', 'NEW'),
  createSelectOption('zlecone', 'PENDING'),
  createSelectOption('w toku', 'IN_PROGRESS'),
  createSelectOption('zakończony', 'FINISHED'),
  createSelectOption('archiwum', 'ARCHIVED'),
]

export interface OrderCheckedInBooking {
  id: number
  reservation_number: string
}

export interface BaseHousekeepingOrder {
  service_kind?: CleaningOrderServiceKind
  service_kind_display?: string
  company_id: number
  apartment: Apartment
  kind: HskOrderKind
  kind_display: string
  extend_stay_time: string | null
  early_check_in_time: string | null
  next_booking?: CleaningOrderBooking | null
}

export type CleaningOrderBeddingOrderStatus = Pick<BeddingOrder, 'id' | 'status' | 'status_display'>
export type CleaningOrderImprovementOrderStatus = Pick<ImprovementOrder, 'id' | 'status' | 'status_display'>

export interface CleaningOrder extends BaseHousekeepingOrder {
  accepted_at: string
  accepted_by: string
  bedding_orders: CleaningOrderBeddingOrderStatus[]
  checked_in_booking: OrderCheckedInBooking | null
  created_at: string
  date: string
  finished_at: string
  has_damage: boolean
  id: number
  improvement_orders: CleaningOrderImprovementOrderStatus[]
  is_finished_in_assumed_time: boolean
  is_paused: boolean
  is_reopened: boolean
  notes_count: number
  order_created_by: string
  ordered_at: string
  ordered_by: string
  position: number
  priority: number
  resort: number
  status: CleaningOrderStatus
  status_display: string
  total_time_started: number
  urls: CleaningOrderUrls
  users: number[]
  weight: string
}

interface BeddingOrderUrls {
  assign: string
  start_working: string
  stop_working: string
  finish_working: string
}

export enum ServiceKindDisplay {
  REFRESH = 'odświeżenie',
  CLEANING = 'sprzątanie',
}

type BeddingOrderStatus = 'PENDING' | 'IN_PROGRESS' | 'FINISHED' | 'ARCHIVED'

export interface BeddingOrder extends BaseHousekeepingOrder {
  accepted_at: string
  accepted_by: string
  checked_in_booking: OrderCheckedInBooking | null
  cleaning_order: CleaningOrder | null
  finished_at: string
  id: number
  ordered_at: string
  ordered_by: string
  resort: number
  status: BeddingOrderStatus
  status_display: string
  total_time_started: number
  urls: BeddingOrderUrls
  users: number[]
}

export type BeddingOrderDetails = BeddingOrder

export type ImprovementOrderStatus = 'NEW' | 'PENDING' | 'IN_PROGRESS' | 'FINISHED' | 'ARCHIVED'

interface ImprovementOrderUrls {
  assign: string
  start_working: string
  stop_working: string
  finish_working: string
}

export interface ImprovementOrderItem {
  amount: number
  code: BookingImprovementCode
  id: number
  name: string
}

export interface ImprovementOrder extends BaseHousekeepingOrder {
  accepted_at: string
  accepted_by: string
  checked_in_booking: OrderCheckedInBooking | null
  created_at: string
  date: string
  finished_at: string
  id: number
  items: ImprovementOrderItem[]
  order_created_by: string
  ordered_at: string
  ordered_by: string
  resort: number
  status: ImprovementOrderStatus
  status_display: string
  total_time_started: number
  urls: ImprovementOrderUrls
  users: number[]
}

export type ImprovementOrderDetails = ImprovementOrder

export interface CleaningOrderDetailsHousekeepingNote {
  id: number
  created: string
  created_by: string
  content: string
  image: string | null
}

export interface CleaningOrderDetails extends CleaningOrder {
  notes: CleaningOrderDetailsHousekeepingNote[]
}

export interface HousekeepingCleaningCompanyWorker {
  id: number
  name: string
  email: string
  company_id: number
  is_housekeeping_handyman: boolean
  is_housekeeping_gardener: boolean
  is_housekeeping_manager: boolean
  is_housekeeping_bedding: boolean
  is_technical_order_worker: boolean
}
export interface HousekeepingCompany extends BaseObj {
  name: string
  type: 'technical_company' | 'cleaning_company'
  type_display: string
}
export interface HousekeepingCompanyDetails extends HousekeepingCompany {
  is_hpr_company: boolean
  workers: HousekeepingCleaningCompanyWorker[]
}

export type FaultOrderExecutor = [string, string]

export interface HousekeepingAppData {
  housekeeping_users: HousekeepingCleaningCompanyWorker[]
  status: 'unknown' | 'loading' | 'ready'
  resort: undefined | number
  issue_order_status: [string, string][]
  fault_order_status: [string, string][]
  fault_order_executors: FaultOrderExecutor[]
  housekeeping_companies: HousekeepingCompanyDetails[]
  improvements_codes: BookingImprovementCode[]
}

export interface ApplicationImage {
  id: number
  image: string
  urls: BaseUrls
}

export interface ReorderedTasks {
  resort: number
  orders: CleaningOrder[]
}
