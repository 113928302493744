import * as React from 'react'
import { useForm } from 'react-hook-form'
import { resetPasswordLink } from '@api/dashboard'
import { LoginUIComponent } from '@components/login-ui-component'
import { Row } from 'reactstrap'
import { FormInput } from '@hyper/forms'
import { SaveButton } from '@hyper/button'
import { useFormRequest } from '@components/hooks/use-api-request'
import { Form } from '@hyper/forms/form'

interface FormInputs {
  email: string
}

export const AuthorizationResetPasswordLinkView: React.FC = () => {
  const [isComplete, setIsComplete] = React.useState(false)
  const methods = useForm<FormInputs>()
  const { isLoading, action: onSubmit } = useFormRequest(async (data: FormInputs) => {
    await resetPasswordLink(data)
    setIsComplete(true)
  }, methods.setError)

  return (
    <LoginUIComponent>
      <h2 className="unauthorized__header">Resetowanie hasła </h2>
      {isComplete ? (
        <p>Wysłaliśmy dalsze instrukcję na podany adres email.</p>
      ) : (
        <Form methods={methods} onSubmit={onSubmit}>
          <p className="unauthorized__text">
            Wpisz swój adres e-mail używany do logowania, wyślemy na niego dalsze instrukcje.
          </p>
          <Row>
            <FormInput
              type="email"
              name="email"
              label="Adres e-mail:"
              placeholder="Wpisz adres e-mail"
              registerParams={{ required: true }}
            />
          </Row>
          <SaveButton
            role="login"
            labelSaving="Resetowanie..."
            isSaving={isLoading}
            label="Resetuj hasło"
            type="submit"
            className="btn btn-secondary btn-block"
          />
        </Form>
      )}
    </LoginUIComponent>
  )
}
