import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import Decimal from 'decimal.js'
import { ReceptionBookingCheckPaymentsBoxCheckout } from '@modules/reception/common/reception-booking-check-payments-box-checkout/step-payments-box-checkout'
import { AlertDanger } from '@components/alerts'

interface Props {
  booking: ReceptionBookingDetails
  restToPay: Decimal
  isOnlyClimaticTaxLeft: boolean
}

export const ReceptionBookingCheckOutStepSettlementPayment: React.FC<Props> = ({
  booking,
  restToPay,
  isOnlyClimaticTaxLeft,
}) => (
  <>
    <strong className="d-block text-secondary font-15 mb-2">Płatność</strong>
    <ReceptionBookingCheckPaymentsBoxCheckout
      booking={booking}
      restToPay={restToPay}
      title="Płatność końcowa"
      paymentParams={{ payment_place: 'checkout' }}
    >
      {isOnlyClimaticTaxLeft && (
        <AlertDanger className="py-1 mt-2 mb-0 font-12 px-1">Płatność za opłatę klimatyczną.</AlertDanger>
      )}
    </ReceptionBookingCheckPaymentsBoxCheckout>
  </>
)
