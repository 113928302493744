import * as React from 'react'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { AgreementListTableRow } from '@modules/hr/agreement/list/table-row'
import { useAppSelector } from '@store/index'
import { selectAgreements } from '@store/slices/hr-slice'

interface Props {
  filters: TableFilters
  setFilters: (filters: TableFilters) => void
  headers: TableHeaderValue[]
}

export const AgreementListTable: React.FC<Props> = ({ filters, setFilters, headers }) => {
  const agreements = useAppSelector(selectAgreements)

  return (
    <Table className="table-hover w-100 mb-0" headerValues={headers} filters={filters} setFilters={setFilters}>
      {agreements.map(agreement => (
        <AgreementListTableRow key={agreement.id} agreement={agreement} />
      ))}
    </Table>
  )
}
