import * as React from 'react'
import { formatPriceShort, getById, groupByField } from '@helpers/utils'
import { useExpandableItems } from '@components/hooks/use-expandable-items'
import { ReceptionBookingNewFeedingSummaryGuestListItem } from '@modules/reception/checkin/step-feeding/new-feeding/summary/reception-booking-new-feeding-summary-guest-list-item'
import { ReceptionBookingDetails } from '@models/reception'
import { NewFeedingCalculationPrices } from '@modules/reception/checkin/step-feeding/new-feeding/models'
import { selectIsDiscountPreviewActive } from '@store/slices/new-feeding-slice'
import { useAppSelector } from '@store/index'
import { ReceptionBookingNewFeedingPrice } from '@modules/reception/checkin/step-feeding/new-feeding/reception-booking-new-price'

interface Props {
  booking: ReceptionBookingDetails
  prices: NewFeedingCalculationPrices
}

export const ReceptionBookingNewFeedingSummaryList = ({ booking, prices }: Props): JSX.Element => {
  const isDiscountPreviewActive = useAppSelector(selectIsDiscountPreviewActive)
  const { isExpanded, toggleExpanded } = useExpandableItems()

  const guests = React.useMemo(() => Object.entries(groupByField(prices.items, 'guest_id')), [prices.items])

  const getGuestById = React.useCallback(
    (guestId: string) => getById(booking.guests, guestId),
    [booking.guests, prices.items],
  )

  return (
    <>
      <div className="mb-2 d-flex justify-content-between">
        <strong>Koszt wyżywienie łącznie</strong>
        <ReceptionBookingNewFeedingPrice
          className="font-weight-bold"
          price={isDiscountPreviewActive ? prices.total_discount_amount : prices.total_price_brutto}
        />
      </div>
      <ul className="list-unstyled">
        {guests.map(([guestId, priceItems]) => (
          <ReceptionBookingNewFeedingSummaryGuestListItem
            key={guestId}
            isExpanded={isExpanded(guestId)}
            onExpand={toggleExpanded(guestId)}
            priceItems={priceItems}
            guest={getGuestById(guestId)}
          />
        ))}
      </ul>
      <div className="text-secondary border-top border-bottom d-flex justify-content-between py-1 font-12 font-weight-semibold">
        <span>Opłacone wyżywienie Kuponem Gastronomicznym</span>
        <span>{formatPriceShort(prices.total_price_brutto_confirmed_by_wallet)}</span>
      </div>
      <div className="text-secondary border-bottom d-flex justify-content-between py-1 font-12 font-weight-semibold">
        <span>Koszt dobranych posiłków</span>
        <span>{formatPriceShort(prices.cart_rest_to_pay)}</span>
      </div>
      <div className="text-secondary border-bottom d-flex justify-content-between py-1 font-12 font-weight-semibold">
        <span>Zwrot za usunięte posiłki na Kupon Gastronomiczny</span>
        <span>{formatPriceShort(prices.return_to_wallet)}</span>
      </div>
    </>
  )
}
