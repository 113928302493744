import * as React from 'react'
import { ReceptionBookingDetails, ReceptionReminder } from '@models/reception'
import ModalBody from 'reactstrap/lib/ModalBody'
import { newUserPredicator, ReceptionCheckReminders } from '@modules/reception/common/reception-check-reminders'
import { FormProvider, useForm } from 'react-hook-form'
import { StepGuestsList } from '@modules/reception/checkin/step-guests/step-guests-list'
import { hasCars } from '@modules/reception/common/tools'
import { ReceptionBookingCheck } from '../../common/reception-booking-check'
import { useAppDispatch } from '@store/index'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { setReceptionBookingDetails } from '@store/slices/reception-slice'

interface ReceptionBookingCheckInStepGuestsProps {
  nextStep: () => void
  previousStep: () => void
  booking: ReceptionBookingDetails
}

interface FormInputs {
  check_cars: boolean
}

export const ReceptionBookingCheckInStepGuests: React.FC<ReceptionBookingCheckInStepGuestsProps> = ({
  booking,
  previousStep,
  nextStep,
}) => {
  const user = useAuthenticatedUser()

  const methods = useForm<FormInputs>({
    defaultValues: {
      check_cars: newUserPredicator(user),
    },
  })
  const onSubmit = () => nextStep()
  const dispatch = useAppDispatch()

  const reminders: ReceptionReminder[] = []
  if (hasCars(booking)) {
    reminders.push({
      id: 'check_cars',
      isClosable: true,
      children: (
        <>
          Zwróć uwagę czy <strong>wszystkie auta zostały przypisane</strong> do pobytu.
        </>
      ),
    })
  }

  const updateState = (bookingDetails: ReceptionBookingDetails) => dispatch(setReceptionBookingDetails(bookingDetails))

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} role="step-guests">
        <ModalBody>
          <h4>2. Uzupełnij gości w lokalu</h4>

          <ReceptionCheckReminders reminders={reminders} required={!newUserPredicator(user)} />

          <StepGuestsList updateState={updateState} guests={booking.guests} booking={booking} />
        </ModalBody>
        <ReceptionBookingCheck nextStep={nextStep} previousStep={previousStep} />
      </form>
    </FormProvider>
  )
}
