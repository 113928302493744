import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { CustomInput, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { useForm, useWatch } from 'react-hook-form'
import { CashCountModalRow } from '@modules/payment-registers/cash-count-modal/row'
import { ColAuto } from '@hyper/col-auto'
import { asDecimal, formatPrice } from '@helpers/utils'
import { extractInnerRef } from '@helpers/forms'
import {
  BanknoteInputs,
  cashCountGetTotalAmount,
  EURO_Banknotes,
  generateDefaults,
  getBanknoteImage,
  PLN_Banknotes,
} from '@modules/payment-registers/cash-count-modal/use-cash-count'
import { useSaleAppData } from '@modules/sale/hooks/use-sale-app-data'
import { Form } from '@hyper/forms/form'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useAppData } from '@components/hooks/use-app-data'
import axios from 'axios'

export type CashCountFields = {
  [key in BanknoteInputs]: number
}

export type CashCount = {
  EURO: CashCountFields
  PLN: CashCountFields
}

interface FormInputs extends CashCount {
  hasEURO: boolean
}

interface Props extends BaseModalProps {
  setCashCountValues?: (values: CashCount) => void
  cashCountValues?: CashCount
  resort: number
  allowDownloadReport: boolean
}

export const CashCountModal: React.FC<Props> = ({
  toggleIsVisible,
  setCashCountValues,
  cashCountValues,
  resort,
  allowDownloadReport,
}) => {
  const { euro_exchange_rate } = useSaleAppData()
  const { urls } = useAppData()

  const methods = useForm<FormInputs>({
    defaultValues: {
      EURO: { ...generateDefaults(EURO_Banknotes) },
      PLN: { ...generateDefaults(PLN_Banknotes) },
      ...cashCountValues,
      hasEURO: false,
    },
  })

  const onSubmit = (cashCount: CashCount) => {
    setCashCountValues?.(cashCount)
    toggleIsVisible()
  }

  const { action: handleDownloadReport } = useApiRequest(async () => {
    const response = await axios.post(
      urls.payment.count_money_report,
      { resort, ...methods.getValues() },
      { responseType: 'blob' },
    )
    const temp = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = temp
    link.setAttribute('download', 'Protokol_inwentaryzacji_kasy.pdf')
    link.click()
  })

  const handleReset = () => methods.reset()

  const values = methods.watch()

  const EUROAmount = asDecimal(cashCountGetTotalAmount(values.EURO, euro_exchange_rate)).toString()
  const PLNAmount = cashCountGetTotalAmount(values.PLN)
  const totalAmount = asDecimal(EUROAmount).plus(PLNAmount).toString()

  const isEuroCountSelected = useWatch({ control: methods.control, name: 'hasEURO' })

  React.useEffect(() => {
    if (asDecimal(EUROAmount).gt(0) && !isEuroCountSelected) {
      methods.setValue('hasEURO', true)
    }
  }, [EUROAmount, isEuroCountSelected])

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Przelicz gotówkę</ModalHeader>
      <ModalBody className="bg-grey">
        <Row className="px-4 py-3">
          {PLN_Banknotes.map(banknote => (
            <CashCountModalRow key={banknote} name={banknote} getBanknoteImageUrl={getBanknoteImage} currency="PLN" />
          ))}
          <div className="w-100 position-relative d-flex justify-content-center my-2">
            <hr style={{ borderColor: '#979797', position: 'absolute', left: 0, top: -8, zIndex: 0, width: '100%' }} />
            <div className="bg-grey px-5 d-inline-block" style={{ zIndex: 1 }}>
              <CustomInput
                type="checkbox"
                id="count-euro"
                value={true}
                label="Przelicz EURO (€)"
                {...extractInnerRef(methods.register('hasEURO'))}
              />
            </div>
          </div>
          {isEuroCountSelected && (
            <div className="mt-3 d-flex flex-wrap">
              {EURO_Banknotes.map(banknote => (
                <CashCountModalRow
                  key={banknote}
                  name={banknote}
                  getBanknoteImageUrl={getBanknoteImage}
                  currency="EURO"
                />
              ))}
            </div>
          )}
        </Row>
      </ModalBody>
      <ModalFooter style={{ display: 'block' }}>
        <Row className="align-items-center justify-content-between">
          <ColAuto className="col-auto mr-auto px-0">
            <button type="button" onClick={handleReset} className="btn btn-light">
              Wyczyść
            </button>
          </ColAuto>

          <div className="text-center font-14 m-auto d-flex">
            <strong className="mr-3">Przeliczona gotówka</strong>
            <i className="uil-usd-circle mr-1" />
            {formatPrice(PLNAmount)}
            {isEuroCountSelected && (
              <span className="d-flex">
                <span className="mx-3">+</span>
                <i className="uil-euro-circle mr-1" />
                <div className="d-flex flex-column align-items-start mr-2">
                  <div>{formatPrice(EUROAmount)}</div>
                  <div className="font-10">(kurs {formatPrice(euro_exchange_rate)})</div>
                </div>
                =<strong className="ml-3">{formatPrice(totalAmount)}</strong>
              </span>
            )}
          </div>

          <ColAuto className="col-auto ml-auto px-0">
            {allowDownloadReport && (
              <button type="button" onClick={handleDownloadReport} className="btn btn-outline-secondary mr-1">
                Pobierz raport
              </button>
            )}
            <button type="button" onClick={toggleIsVisible} className="btn btn-light">
              Zamknij
            </button>
            {!!setCashCountValues && (
              <button type="submit" className="ml-1 btn btn-green">
                Zapisz kwotę i zamknij
              </button>
            )}
          </ColAuto>
        </Row>
      </ModalFooter>
    </Form>
  )
}
