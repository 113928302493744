import * as React from 'react'
import Table, { TableHeaderValue } from '@components/table/table'
import { EcommerceStatsItem, EcommerceStatsParams } from '@modules/ecommerce/models'
import { formatPriceShort } from '@helpers/utils'
import { EcommerceStatsTableFooter } from '@modules/ecommerce/stats/ecommerce-stats-table-footer'

const headerValues: TableHeaderValue<EcommerceStatsItem>[] = [
  { title: 'Oferta' },
  { sortField: 'initial_amount', title: 'Ilość (wstępne)' },
  { sortField: 'initial_price', title: 'Wartość brutto (wstępne)' },
  { sortField: 'confirmed_amount', title: 'Ilość (potwierdzone)' },
  { sortField: 'confirmed_price', title: 'Wartość brutto (potwierdzone)' },
  { sortField: 'total_amount', title: 'Ilość (razem)' },
  { sortField: 'total_price', title: 'Wartość brutto (razem)' },
]

interface Props {
  isLoading: boolean
  filters: EcommerceStatsParams
  setFilters: (filters: EcommerceStatsParams) => void
  items: EcommerceStatsItem[]
}

export const EcommerceStatsTable: React.FC<Props> = ({ items, setFilters, filters, isLoading }) => (
  <Table
    className="table-hover w-100"
    headerValues={headerValues}
    isLoading={isLoading}
    filters={filters}
    setFilters={setFilters}
  >
    {items.map((item, index) => (
      <Table.Row key={index}>
        <Table.Cell>{item.name}</Table.Cell>
        <Table.Cell>{item.initial_amount}</Table.Cell>
        <Table.Cell>{formatPriceShort(item.initial_price)}</Table.Cell>
        <Table.Cell>{item.confirmed_amount}</Table.Cell>
        <Table.Cell>{formatPriceShort(item.confirmed_price)}</Table.Cell>
        <Table.Cell>{item.total_amount}</Table.Cell>
        <Table.Cell>{formatPriceShort(item.total_price)}</Table.Cell>
      </Table.Row>
    ))}

    {!!items.length && <EcommerceStatsTableFooter />}
  </Table>
)
