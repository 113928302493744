import * as React from 'react'
import { Subscription, SubscriptionProduct } from '@modules/subscription/models'
import { useModal } from '@components/modals/use-modal'
import Table from '@components/table/table'
import { formatPrice } from '@helpers/utils'
import { BadgeWithIcon } from '@components/badges/BadgeWithIcon'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { useSubscriptionOptions } from '@modules/subscription/hooks/use-subscription-options'
import { StatusBadge } from '@modules/promotions/common/status-badge'

interface Props {
  subscription: Subscription
  products: SubscriptionProduct[]
}

export const PackageWholesaleDetailsSubscriptionsRow = ({ subscription, products }: Props): JSX.Element => {
  const [handleDetails] = useModal('SubscriptionDetailsModal', {
    subscription,
  })

  const productName = React.useMemo(
    () => (products.find(row => row.id === subscription.product_id)?.name || '').replace('Produkt PH: ', ''),
    [products, subscription],
  )

  const { hasOption } = useSubscriptionOptions(subscription)

  return (
    <Table.Row onDoubleClick={handleDetails} className="cursor-pointer" role="details-row">
      <Table.Cell>{subscription.number}</Table.Cell>
      <Table.Cell>
        {subscription.name} <br /> <span className="font-10">{subscription.email}</span>
      </Table.Cell>
      <Table.Cell>
        <StatusBadge element={subscription} />
      </Table.Cell>
      <Table.Cell>{formatPrice(subscription.total_price_brutto)}</Table.Cell>
      <Table.Cell>{formatPrice(subscription.required_payment)}</Table.Cell>
      <Table.Cell>{productName}</Table.Cell>
      <Table.Cell>{subscription.type_value}</Table.Cell>
      <Table.Cell>
        {hasOption('client_data') && <BadgeWithIcon icon="uil-check" title="Zmiana danych" variant="success" />}
        {hasOption('localization') && <BadgeWithIcon icon="uil-check" title="Konwersja kodów" variant="success" />}
        {hasOption('zoo_borysew') && <BadgeWithIcon icon="uil-check" title="Bilety do Zoo Borysew" variant="success" />}
        {hasOption('suntago') && <BadgeWithIcon icon="uil-check" title="Bilety do Suntago" variant="success" />}
        {hasOption('single_client_date') && <BadgeWithIcon icon="uil-check" title="Zmiana daty" variant="success" />}
      </Table.Cell>
      <Table.Cell>
        <small className="font-10">{toDefaultDateTimeFormat(subscription.created)}</small>
      </Table.Cell>
    </Table.Row>
  )
}
