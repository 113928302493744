import * as React from 'react'
import { useForm } from 'react-hook-form'
import { Label, Row } from 'reactstrap'
import Col from 'reactstrap/lib/Col'
import DateRangeInput from '@components/date/date-range-input'
import { SubmitFilter } from '@modules/reception/common/filters/submit-filter'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { ColAuto } from '@hyper/col-auto'
import { FormInput } from '@hyper/forms'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { Form } from '@hyper/forms/form'

interface FiltersHousekeepingApplicationFiltersProps {
  filters: FiltersHousekeepingApplicationFiltersParams
  setFilters: (filters: FiltersHousekeepingApplicationFiltersParams) => void
  defaultFilters: FiltersHousekeepingApplicationFiltersParams
}

export interface FiltersHousekeepingApplicationFiltersParams {
  created_after: Date | undefined
  created_before: Date | undefined
  status: string
  search: string
  ordering: string
  page_size: number
  page: number
}

export const FiltersHousekeepingApplicationFilters: React.FC<FiltersHousekeepingApplicationFiltersProps> = ({
  filters,
  defaultFilters,
  setFilters,
}) => {
  const methods = useForm<FiltersHousekeepingApplicationFiltersParams>({
    defaultValues: filters,
  })

  const handleReset = () => {
    methods.reset()
    setFilters(defaultFilters)
  }

  const onSubmit = async (payload: FiltersHousekeepingApplicationFiltersParams) =>
    setFilters({ ...filters, ...payload })

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  return (
    <Form methods={methods} onSubmit={onSubmit} className="form form-style-filter mb-2">
      <Row>
        <Col className="col-auto" style={{ minWidth: 320 }}>
          <div className="d-flex">
            <Label className="mr-1">Zakres data</Label>
          </div>

          <DateRangeInput
            isClearable={true}
            wrapperClassName="flex-grow-1"
            inputGroupClassName="form-group"
            startDateName="created_after"
            endDateName="created_before"
          />
        </Col>
        <ColAuto>
          <FormInput name="status" label="Status" type="select">
            <option value="">Wszystkie</option>
            <option value="open">Otwarte</option>
            <option value="close">Zamknięte</option>
          </FormInput>
        </ColAuto>

        <SubmitFilter handleReset={handleReset} />
        <SearchFilter />
      </Row>
    </Form>
  )
}
