import * as React from 'react'
import { BaseHistoryParamsChange } from '@models/dashboard'

interface Props {
  change: BaseHistoryParamsChange
}

export const ClientHistoryChangeValuesRow = ({ change }: Props) => (
  <tr>
    <ValueCell value={change.old_value_display} />
    <ValueCell value={change.new_value_display} />
  </tr>
)

const ValueCell = ({ value }) => (
  <td className="align-top px-1 pb-1">
    <div className="font-11">
      <div>{formatChange(value)}</div>
    </div>
  </td>
)

const formatChange = change => {
  const stringifiesValue = JSON.stringify(change, null, 2)
  return stringifiesValue
    ? stringifiesValue
        .replace(/[{}]|"/g, '')
        .split(',')
        .map(field => (
          <span key={field}>
            {field} <br />
          </span>
        ))
    : ''
}
