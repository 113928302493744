import * as React from 'react'

interface Props {
  hasDamage: boolean
}

export const HousekeepingCleaningOrderHasDamageRow = React.memo(({ hasDamage }: Props) => (
  <td className="housekeeping__orders_table__column text-center font-16">
    {hasDamage ? <i className="uil-check text-success" /> : <i className="text-danger uil-multiply" />}
  </td>
))
