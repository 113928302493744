import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { useAppDispatch } from '@store/index'
import { FormProvider, useForm } from 'react-hook-form'
import { StepGuestsList } from '@modules/reception/checkin/step-guests/step-guests-list'
import { setReceptionBookingDetails } from '@store/slices/reception-slice'

interface Props {
  booking: ReceptionBookingDetails
  hideVouchers?: boolean
  hideCars?: boolean
}

export const ReceptionBookingDetailsGuests: React.FC<Props> = ({ hideCars, hideVouchers, booking }) => {
  const dispatch = useAppDispatch()
  const updateState = (bookingDetails: ReceptionBookingDetails) => dispatch(setReceptionBookingDetails(bookingDetails))

  // Fejkowy formularz by działało :)
  const methods = useForm()

  return (
    <FormProvider {...methods}>
      <StepGuestsList
        updateState={updateState}
        guests={booking.guests}
        booking={booking}
        hideCars={hideCars}
        hideVouchers={hideVouchers}
      />
    </FormProvider>
  )
}
