import * as React from 'react'
import { ReservationCard } from '@modules/reservations/details/common/reservation-card'
import { ReceptionBookingDetails } from '@models/reception'
import { useModal } from '@components/modals/use-modal'
import { ReservationBillMainRow } from '@modules/reservations/details/bill/reservation-bill-main-row'
import { ReservationBillExpandedImprovement } from '@modules/reservations/details/bill/improvements/reservation-bill-expanded-improvemnets'
import { formatDate, parseISODate, toDefaultDateFormat } from '@helpers/date-helper'
import { formatDistanceStrict } from 'date-fns'
import { pl } from 'date-fns/locale'
import { ReservationBillExpandedFeeding } from '@modules/reservations/details/bill/feeding/reservation-bill-expanded-feeding'
import { ReservationBillExpandedDiscounts } from '@modules/reservations/details/bill/discounts/reservation-bill-expanded-discount'
import { ReservationBillExpandedTouristVouchers } from '@modules/reservations/details/bill/tourist-voucher/reservation-bill-expanded-tourist-vouchers'
import { ReservationBillExpandedAdditionalCharges } from '@modules/reservations/details/bill/additional-charges/reservation-bill-expanded-additional-charges'
import { asDecimal } from '@helpers/utils'
import { ReservationBillSummary } from '@modules/reservations/details/bill/summary/reservation-bill-summary'
import { ReservationBillExpandedExtraCharges } from '@modules/reservations/details/bill/extra-charges/reservation-bill-expanded-extra-charges'
import { useExpandableItems } from '@components/hooks/use-expandable-items'
import { ReservationBillExpandedMedia } from '@modules/reservations/details/bill/bill-media/reservation-bill-expanded-media'
import { useBookingFeedingAvailability } from '@components/hooks/use-booking-feeding-availability'
import { ReservationBillExpandedDamage } from '@modules/reservations/details/bill/damages/reservation-bill-expanded-damages'
import { ReservationBillExpandedStayDays } from '@modules/reservations/details/bill/stay-days/reservation-bill-expanded-stay-days'

type CollapsableRow =
  | 'improvements'
  | 'media'
  | 'feeding'
  | 'stay_days'
  | 'discounts'
  | 'tourist_voucher'
  | 'additional_charges'
  | 'extra_charges'
  | 'damages'

interface Props {
  booking: ReceptionBookingDetails
}

export const ReservationBill = React.forwardRef<HTMLElement, Props>(({ booking }, ref): JSX.Element => {
  const [showMetersModal] = useModal('ReceptionMetersReadingDialog', { booking })
  const [showDetailsModal] = useModal('ReceptionBookingDetailsModal', null, { persist: true })

  const { toggleExpanded, isExpanded } = useExpandableItems<CollapsableRow>()

  const showDetailsFor = (tab: 'improvements' | 'feedingNew') => () => {
    showDetailsModal(null, { booking, tabs: ['improvements', 'feedingNew'], initialTab: tab, clearOnUnmount: false })
  }

  const additionalChargesPrice = asDecimal(booking.prices.climatic.total_price_brutto)
    .plus(booking.prices.service_charge_brutto)
    .toFixed()

  const { isAvailable: hasFeedingAvailable } = useBookingFeedingAvailability(booking)

  return (
    <ReservationCard title="Rachunek rezerwacji" ref={ref} id="bill">
      <table className="table table-sm">
        <colgroup>
          <col width="25%" />
        </colgroup>
        <tbody>
          <ReservationBillMainRow
            title="Pobyt w dniach"
            subtitle={`${formatDate(booking.date_from, 'dd.MM')} - ${toDefaultDateFormat(
              booking.date_to,
            )} (${formatDistanceStrict(parseISODate(booking.date_to) as Date, parseISODate(booking.date_from) as Date, {
              locale: pl,
            })})`}
            price={booking.prices.residence.total_price_brutto}
            pricePrefix="+"
            isExpanded={isExpanded('stay_days')}
            onExpand={toggleExpanded('stay_days')}
          >
            <ReservationBillExpandedStayDays days={booking.days} />
          </ReservationBillMainRow>
          <ReservationBillMainRow
            title="Szkody"
            subtitle={`Ilość szkód: ${booking.damages.length}`}
            price={booking.damages_brutto}
            pricePrefix="+"
            onExpand={booking.damages.length ? toggleExpanded('damages') : undefined}
            isExpanded={isExpanded('damages')}
          >
            <ReservationBillExpandedDamage damages={booking.damages} />
          </ReservationBillMainRow>
          <ReservationBillMainRow
            title="Ulepszenia"
            subtitle={`łącznie (${booking.prices.improvements.items.length})`}
            price={booking.prices.improvements.total_price_brutto}
            editLabel="edytuj ulepszenia"
            onEdit={showDetailsFor('improvements')}
            onExpand={booking.improvements.length ? toggleExpanded('improvements') : undefined}
            isExpanded={isExpanded('improvements')}
            pricePrefix="+"
          >
            <ReservationBillExpandedImprovement improvements={booking.prices.improvements} />
          </ReservationBillMainRow>
          {hasFeedingAvailable && (
            <ReservationBillMainRow
              title="Wyżywienie"
              subtitle={`dla ${booking.guests.length} ${booking.guests.length > 1 ? 'osób' : 'osoby'}`}
              price={booking.prices.feeding.total_price_brutto}
              editLabel="edytuj wyżywienie"
              onEdit={showDetailsFor('feedingNew')}
              onExpand={booking.feeding_options.length ? toggleExpanded('feeding') : undefined}
              isExpanded={isExpanded('feeding')}
              pricePrefix="+"
            >
              <ReservationBillExpandedFeeding booking={booking} />
            </ReservationBillMainRow>
          )}
          <ReservationBillMainRow
            title="Media"
            subtitle="zużycie"
            price={booking.media_brutto}
            editLabel="zmień odczyty"
            onEdit={showMetersModal}
            onExpand={toggleExpanded('media')}
            isExpanded={isExpanded('media')}
            pricePrefix="+"
          >
            <ReservationBillExpandedMedia booking={booking} />
          </ReservationBillMainRow>

          <ReservationBillMainRow
            title="Opłaty"
            price={additionalChargesPrice}
            onExpand={toggleExpanded('additional_charges')}
            isExpanded={isExpanded('additional_charges')}
            pricePrefix="+"
          >
            <ReservationBillExpandedAdditionalCharges prices={booking.prices} />
          </ReservationBillMainRow>

          {!!booking.prices.extra_charges.items.length && (
            <ReservationBillMainRow
              title="Dopłaty"
              price={booking.prices.extra_charges.total_price_brutto}
              onExpand={toggleExpanded('extra_charges')}
              isExpanded={isExpanded('extra_charges')}
              pricePrefix="+"
            >
              <ReservationBillExpandedExtraCharges extraCharges={booking.prices.extra_charges} />
            </ReservationBillMainRow>
          )}

          {booking.warranty && (
            <ReservationBillMainRow
              title="Opcja"
              subtitle="rezygnacji"
              price={booking.prices.warranty_price_brutto}
              pricePrefix="+"
            />
          )}

          {booking.prices.discounts && !!booking.prices.discounts.items.length && (
            <ReservationBillMainRow
              title="Rabaty"
              price={booking.prices.discounts?.total_price_brutto}
              onEdit={showMetersModal}
              onExpand={toggleExpanded('discounts')}
              isExpanded={isExpanded('discounts')}
              pricePrefix="-"
            >
              <ReservationBillExpandedDiscounts discounts={booking.prices.discounts} />
            </ReservationBillMainRow>
          )}

          {booking.prices.tourist_vouchers && !!booking.prices.tourist_vouchers.items.length && (
            <ReservationBillMainRow
              title="Bon turystyczny"
              price={booking.prices.tourist_vouchers.total_price_brutto}
              onEdit={showMetersModal}
              onExpand={toggleExpanded('tourist_voucher')}
              isExpanded={isExpanded('tourist_voucher')}
              pricePrefix="-"
            >
              <ReservationBillExpandedTouristVouchers touristVouchers={booking.prices.tourist_vouchers} />
            </ReservationBillMainRow>
          )}
          <ReservationBillSummary restToPay={booking.rest_to_pay} />
        </tbody>
      </table>
    </ReservationCard>
  )
})
