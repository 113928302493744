import * as React from 'react'
import Table, { TableFilters } from '@components/table/table'
import { asDecimal, formatPriceShort } from '@helpers/utils'
import { useGetBenefitProgramsAggregationQuery } from '@api/benefit-programs'
import { TypedQueryResult } from '@api/base'
import { BenefitProgramAggregation } from '@modules/benefit-program/models'

interface Props {
  filters: TableFilters
}

const emptyValue: BenefitProgramAggregation = {
  active_apartment_agreements_sum: '0',
  active_house_agreements_sum: '0',
  total_monthly_sum: '0',
}

export const BenefitProgramTableFooter: React.FC<Props> = ({ filters }) => {
  const { data: aggregation = emptyValue } = useGetBenefitProgramsAggregationQuery({
    params: filters,
  }) as TypedQueryResult<BenefitProgramAggregation>

  const totalAgreements = asDecimal(aggregation.active_apartment_agreements_sum).plus(
    asDecimal(aggregation.active_house_agreements_sum),
  )

  return (
    <Table.Row>
      <Table.Cell colSpan={3} className="text-right align-middle reservation-list__summary-cell">
        <strong className="mr-2">Razem:</strong>
      </Table.Cell>
      <Table.Cell className="reservation-list__summary-cell">
        <div className="p-2 text-white reservation-list__summary-block" style={{ height: 80 }}>
          <strong>{totalAgreements.toString()}</strong>
          <span className="d-block mb-1 font-11 mt-1">
            Domek: {aggregation.active_house_agreements_sum}
            <br />
            Apartament: {aggregation.active_apartment_agreements_sum}
          </span>
        </div>
      </Table.Cell>
      <Table.Cell className="reservation-list__summary-cell" colSpan={5}>
        <div className="p-2 text-white reservation-list__summary-block" style={{ height: 80 }}>
          <strong>{formatPriceShort(aggregation.total_monthly_sum)}</strong>
          <span className="d-block mb-1 font-11 mt-1">miesięcznie</span>
        </div>
      </Table.Cell>
    </Table.Row>
  )
}
