import * as React from 'react'
import { TechnicalOrder } from '@models/technical-orders'
import Table from '@components/table/table'
import { CSSProperties } from 'react'
import { TechnicalOrdersNotes } from '@modules/technical-orders/technical-orders-ordered/row-notes-content'

interface Props {
  technicalOrder: TechnicalOrder
  styles?: CSSProperties
}

export const TechnicalOrdersOrderedTableRowNotes: React.FC<Props> = ({ technicalOrder, styles = {} }) => (
  <Table.Cell className="vertical-align-middle text-right text-nowrap" style={{ width: 70, ...styles }}>
    <TechnicalOrdersNotes technicalOrder={technicalOrder} />
  </Table.Cell>
)
