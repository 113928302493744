import * as React from 'react'
import { BenefitProgramAgreementDetails } from '@modules/benefit-program/models'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { BenefitProgramAgreementDetailsFeaturesTableRow } from '@modules/benefit-program/agreements/details/features/table-row'

const defaultFilters: TableFilters = {
  ordering: '',
  page: 0,
  page_size: 10,
  search: '',
  skip_paginator: false,
}

const headers: TableHeaderValue[] = [
  { title: 'Nazwa benefitu' },
  { title: 'Numer kodu' },
  { title: 'Pracodawca' },
  { title: 'Do użycia od' },
  { title: 'Ważny do' },
  { title: 'Status' },
  { title: '' },
]

interface Props {
  benefitProgramAgreement: BenefitProgramAgreementDetails
}

export const BenefitProgramAgreementDetailsFeaturesTable: React.FC<Props> = ({ benefitProgramAgreement }) => {
  const [filters, setFilters] = React.useState<TableFilters>(defaultFilters)

  return (
    <Table
      className="table-hover w-100"
      headerValues={headers}
      isLoading={false}
      filters={filters}
      setFilters={setFilters}
      showPagination={false}
    >
      {benefitProgramAgreement.features.map(benefitProgramAgreementFeature => (
        <BenefitProgramAgreementDetailsFeaturesTableRow
          key={benefitProgramAgreementFeature.id}
          companyName={benefitProgramAgreement.benefit_program.name}
          benefitProgramAgreementFeature={benefitProgramAgreementFeature}
        />
      ))}
    </Table>
  )
}
