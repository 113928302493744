import * as React from 'react'
import { FormPlain } from '@hyper/forms'
import { Label, LabelProps } from 'reactstrap'
import { Controller } from 'react-hook-form'
import MaskedInput, { MaskedInputProps } from 'react-text-mask'
import { noop } from '@helpers/utils'
import { useNewFormContext } from '@hyper/forms/hooks/use-new-form-context'
import classNames from 'classnames'

interface Props {
  control?: any
  errors?: any
  name: string
  label?: string
  colSize?: number

  labelProps?: LabelProps
  colStyle?: React.CSSProperties
  colClassName?: string
  formGroupClassName?: string
  disabled?: boolean
  readOnly?: boolean
  placeholder?: string
  mask: (string | RegExp)[]
  hideErrors?: boolean
  inputClassName?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  inputProps?: Omit<MaskedInputProps, 'mask'>
}

export const FormInputMasked: React.FC<Props> = ({
  disabled,
  colSize,
  label,
  control,
  errors,
  name,
  mask,
  placeholder,
  labelProps,
  readOnly,
  colStyle,
  colClassName,
  formGroupClassName,
  hideErrors,
  inputClassName,
  onChange,
  inputProps,
}) => {
  const methods = useNewFormContext()

  if (methods) {
    control = methods.control
    errors = methods.formState.errors
  }

  return (
    <FormPlain
      colSize={colSize || 12}
      name={name}
      errors={errors}
      colStyle={colStyle}
      colClassName={colClassName}
      formGroupClassName={formGroupClassName}
      hideErrors={hideErrors}
    >
      <Label {...labelProps}>{label || ''}</Label>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <MaskedInput
            mask={mask}
            type="text"
            autoComplete="off"
            placeholder={placeholder}
            disabled={disabled}
            className={classNames('form-control', inputClassName)}
            readOnly={readOnly}
            {...field}
            {...(onChange && { onChange })}
            {...inputProps}
            ref={() => {
              field.ref({ focus: noop })
            }}
          />
        )}
      />
    </FormPlain>
  )
}
