import {
  EnhancedFloorHeatingMeasurement,
  FloorHeatingMeasurement,
  FloorHeatingWindowState,
} from '@modules/floor-heating/models'

export const getEnhancedFloorMeasurements = (
  measurements: FloorHeatingMeasurement[],
  windowsState: FloorHeatingWindowState[],
) =>
  measurements.reduce((addedMeasurements: EnhancedFloorHeatingMeasurement[], measurement: FloorHeatingMeasurement) => {
    const window = windowsState.find(state => state.apartment_name === measurement.apartment_name)

    return [
      ...addedMeasurements,
      {
        ...measurement,
        windows_open: !!window?.details?.any_window_opened,
      },
    ]
  }, [])
