import * as React from 'react'
import { ReceptionCheckType } from '@models/reception'
import { RootState, useAppSelector } from '@store/index'
import { NavigationTabs, NavigationTabsButton } from '@components/navigation-tabs'
import { NavigationPath } from '@models/routes'

class ReceptionCheckTypesProps {
  resortId: number
  checkType: string
}

export const ReceptionCheckTypes: React.FC<ReceptionCheckTypesProps> = ({ checkType, resortId }) => {
  const receptionBookingsAggregation = useAppSelector(
    (state: RootState) => state.receptionState.receptionBookingsAggregation,
  )

  const url = NavigationPath.ReceptionWithParams.replace(':resort_id', String(resortId))

  const buttons: NavigationTabsButton[] = [
    {
      isActive: checkType === ReceptionCheckType.TO_CHECKIN,
      title: `Do zameldowania (${receptionBookingsAggregation?.to_checkin || 0})`,
      link: url.replace(':check_type', String(ReceptionCheckType.TO_CHECKIN)),
    },
    {
      isActive: checkType === ReceptionCheckType.COMPLETE_CHECKIN,
      title: `Zameldowani (${receptionBookingsAggregation?.complete_checkin || 0})`,
      link: url.replace(':check_type', String(ReceptionCheckType.COMPLETE_CHECKIN)),
    },
    {
      isActive: checkType === ReceptionCheckType.TO_CHECKOUT,
      title: `Do wymeldowania (${receptionBookingsAggregation?.to_checkout || 0})`,
      link: url.replace(':check_type', String(ReceptionCheckType.TO_CHECKOUT)),
    },
  ]

  return <NavigationTabs buttons={buttons} />
}
