import * as React from 'react'
import { ImprovementOrder } from '@modules/housekeeping/models'
import { HousekeepingOrderWorkersRow } from '@modules/housekeeping/common/workers'
import { HousekeepingOrdersRowTotalTimeStarted } from '@modules/housekeeping/common/row-total-time-started'
import { HousekeepingCleaningOrderOrderedByRow } from '@modules/housekeeping/cleaning-orders/common/ordered-by'

interface Props {
  improvementOrder: ImprovementOrder
}

export const HousekeepingImprovementOrderArchiveRow: React.FC<Props> = ({ improvementOrder }) => (
  <tr className="housekeeping__orders_table__row">
    <td className="housekeeping__orders_table__column housekeeping__orders_table__column__first">
      {improvementOrder.apartment.name}
    </td>
    <td className="housekeeping__orders_table__column">Ulepszenia</td>
    <td className="housekeeping__orders_table__column">{improvementOrder.status_display}</td>
    <HousekeepingOrderWorkersRow order={improvementOrder} />

    <HousekeepingOrdersRowTotalTimeStarted totalTimeStarted={improvementOrder.total_time_started} />
    <HousekeepingCleaningOrderOrderedByRow
      ordered_at={improvementOrder.ordered_at}
      ordered_by={improvementOrder.ordered_by}
    />
    <td className="housekeeping__orders_table__column text-nowrap">-</td>
    <td className="housekeeping__orders_table__column text-nowrap">-</td>
  </tr>
)
