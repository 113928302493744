import { ReceptionBooking, ReceptionBookingDetails } from '@models/reception'
import { CustomReactSelectOption } from '@components/custom-react-select'
import * as R from 'ramda'

export const hasCars = (booking: ReceptionBookingDetails): boolean => booking.cars.length !== 0

export const receptionBookingsListSorter = (
  bookings: ReceptionBooking[],
  filters: {
    sort_direction: CustomReactSelectOption
    sort_by: CustomReactSelectOption
  },
  additionalSorters: any[] = [],
) => {
  const direction = filters.sort_direction.value === 'asc' ? R.ascend : R.descend

  const byApartmentNumber = a => parseInt(a.apartment?.name.replace(/\D/g, '') || 0, 10)

  return R.sortWith<ReceptionBooking>([
    ...additionalSorters,
    direction(
      filters.sort_by.value === 'apartment.name' ? byApartmentNumber : R.path(filters.sort_by.value.split('.')),
    ),
  ])(bookings)
}
