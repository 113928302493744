import { ModalConfig, ModalParams, ModalTypes } from '@components/modals/types'
import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@store/index'

interface ModalItem {
  modal: ModalTypes
  params: ModalParams
  isOpen: boolean
  config?: ModalConfig
}

const modalAdapter = createEntityAdapter<ModalItem>({ selectId: modalItem => modalItem.modal })

export interface ModalState {
  modals: EntityState<ModalItem>
}

const initialState: ModalState = {
  modals: modalAdapter.getInitialState(),
}

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setShowModal(state, action: PayloadAction<ModalItem>) {
      modalAdapter.addOne(state.modals, action.payload)
    },
    setHideModal(state, action: PayloadAction<{ modal: ModalTypes }>) {
      modalAdapter.removeOne(state.modals, action.payload.modal)
    },
    setModalIsOpen(
      state,
      action: PayloadAction<{
        modal: ModalTypes
        isOpen: boolean
      }>,
    ) {
      const modal = modalAdapter.getSelectors().selectById(state.modals, action.payload.modal)

      if (modal) {
        modalAdapter.upsertOne(state.modals, { ...modal, isOpen: action.payload.isOpen })
      }
    },
  },
})
export const { setModalIsOpen, setHideModal, setShowModal } = modalSlice.actions
export const { selectAll: modalsSelector, selectById: findModalSelector } = modalAdapter.getSelectors(
  (state: RootState) => state.modalState.modals,
)
export default modalSlice.reducer
