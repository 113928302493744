import * as React from 'react'
import { GastroCard, GastroCardKind } from '@models/promotions'
import { GastroCardsFiltersParams } from '@modules/promotions/gastro-cards/models'
import Table, { TableHeaderValue } from '@components/table/table'
import { GastroCardsTableRow } from '@modules/promotions/gastro-cards/gastro-cards-table-row'
import { GastroCardsTableFooter } from '@modules/promotions/gastro-cards/gastro-cards-table-footer'
import { useReportsAppData } from '@modules/reports/hooks/use-reports-app-data'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { GastroGenericProductFiltersParams } from '@modules/promotions/gastro-generic-product/gastro-generic-product-view'

export type GastroFilters = GastroGenericProductFiltersParams | GastroCardsFiltersParams

interface Props {
  isLoading: boolean
  filters: GastroFilters
  setFilters: (filters) => void
  gastroCards: GastroCard[]
  selectableHeader: React.ReactElement
  selectableCell: (gastroCard: GastroCard) => React.ReactElement
  kind?: GastroCardKind
  headers?: TableHeaderValue[]
  basePath: string
  pathWithParams: string
}

const headersValues = [
  {
    sortField: 'code',
    title: 'Kod',
  },
  {
    sortField: 'client__first_name',
    title: 'Imię i nazwisko',
  },
  {
    sortField: 'status',
    title: 'Status',
  },
  {
    sortField: 'sell_price',
    title: 'Kwota zakupu',
  },
  {
    sortField: 'rest_to_use',
    title: 'Pozostało do wykorzystania',
  },
  {
    sortField: 'declared_sell_price',
    title: 'Deklarowana kwota zakupu',
  },
  {
    title: 'Źródło marketingowe',
    sortField: 'source_marketing',
  },
  {
    sortField: 'sell_date',
    title: 'Data utworzenia',
  },
  {
    sortField: 'seller__name',
    title: 'Sprzedawca',
  },
].filter(el => !!el.title) as TableHeaderValue[]

export const GastroCardsTable: React.FC<Props> = ({
  gastroCards,
  setFilters,
  filters,
  selectableHeader,
  isLoading,
  selectableCell,
  kind,
  basePath,
  pathWithParams,
  headers = headersValues,
}) => {
  const { sources } = useReportsAppData()

  const user = useAuthenticatedUser()

  const canDeleteWithInvoice = user.hasPerm(UserPermission.PromotionsGastroCardCanDeleteInvoice)
  const canDelete = user.hasPerm(UserPermission.PromotionsGastroCardCanDelete)

  const tableHeaders = React.useMemo(
    () =>
      kind === 'gastro_card_compensation'
        ? headers.filter(row => !['Bonus', 'Kwota zakupu', 'Typ', 'Źródło'].includes(String(row.title)))
        : headers.filter(row => row.title !== 'Miejsce wykorzystania'),
    [headers, kind],
  )

  return (
    <Table
      className="table-hover w-100"
      headerValues={tableHeaders}
      isLoading={isLoading}
      filters={filters}
      setFilters={setFilters}
      selectableHeader={selectableHeader}
    >
      <>
        {gastroCards.map(gastroCard => (
          <GastroCardsTableRow
            gastroCard={gastroCard}
            key={gastroCard.id}
            sources={sources}
            canDelete={canDelete}
            canDeleteWithInvoice={canDeleteWithInvoice}
            kind={kind}
            basePath={basePath}
            pathWithParams={pathWithParams}
          >
            {selectableCell(gastroCard)}
          </GastroCardsTableRow>
        ))}
        {!!gastroCards.length && <GastroCardsTableFooter />}
      </>
    </Table>
  )
}
