import * as React from 'react'
import classNames from 'classnames'

interface Props {
  value: boolean
  textYes?: string
  textNo?: string
  onClick?: () => void
  className?: string
}

export const YesNoBadge: React.FC<Props> = ({ className, value, textYes, textNo, onClick }) => (
  <span
    className={classNames(className, {
      'badge badge-success-lighten': value,
      'badge badge-danger-lighten': !value,
      'cursor-pointer': !!onClick,
    })}
    onClick={onClick}
  >
    <i
      className={classNames('mr-1', {
        'uil-ban': !value,
        'uil-check-circle': value,
      })}
    />
    {value ? textYes || 'tak' : textNo || 'nie'}
  </span>
)
