import * as React from 'react'

import dateHelper from '@helpers/date-helper'
import classNames from 'classnames'
import TimelineDayTooltip from '@modules/reservations/timeline/day-tooltip/timeline-day-tooltip'
import { TimelineMonthTitleView } from '@modules/reservations/timeline/timeline-month-title'
import { CalendarDate } from '@store/reducers/timeline-reducers'
import { RootState, useAppSelector } from '@store/index'
import { useTimelineSelection } from '@modules/reservations/timeline/use-timeline-selection'

interface Props {
  resort: string
  date: CalendarDate
  accommodationTypeIds: string[]
  isSecondToLast: boolean
  isSecondToFirst: boolean
  isMiddle: boolean
  onScrollFix: (ref: React.RefObject<HTMLDivElement>, pos: ScrollLogicalPosition) => void
}

const TimelineDaysNavbar: React.FC<Props> = ({
  date,
  resort,
  accommodationTypeIds,
  isSecondToLast,
  isSecondToFirst,
  isMiddle,
  onScrollFix,
}) => {
  const daysNavbarRef = React.useRef<HTMLDivElement>(null)

  const calendarDates = useAppSelector((state: RootState) => state.timelineState.calendarDates)
  const isCompactView = useAppSelector((state: RootState) => state.timelineState.isCompactView)

  const { isColSelected, updateSelectedCols } = useTimelineSelection()

  React.useEffect(() => {
    if (calendarDates.position === 'reach-right' && isSecondToLast) {
      onScrollFix(daysNavbarRef, 'end')
    } else if (calendarDates.position === 'reach-left' && isSecondToFirst) {
      onScrollFix(daysNavbarRef, 'start')
    } else if (calendarDates.position === 'middle' && isMiddle) {
      onScrollFix(daysNavbarRef, 'center')
    }
  }, [calendarDates, isSecondToFirst, isSecondToLast])

  const getDayDate = (day: number): string => dateHelper.createDateFormat(date.year, date.month, day)

  const isFirstDay = (day: number) => parseFloat(dateHelper.getDayName(date.year, date.month, day, 'd')) === 1

  return (
    <div className="d-flex flex-column calendar__days-wrapper-el" ref={daysNavbarRef}>
      <TimelineMonthTitleView calendarDate={date} />
      <div className="d-flex">
        {dateHelper.daysArray(date.month, date.year).map(day => (
          <React.Fragment key={day}>
            <div
              id={`day-tooltip-${getDayDate(day)}`}
              className={classNames('calendar__day-wrapper cursor-pointer', {
                'is-weekend': dateHelper.isWeekendDay(date.year, date.month, day),
                'is-today': dateHelper.isToday(date.year, date.month, day),
                'is-first': isFirstDay(day),
                'is-compact': isCompactView,
                'is-selected': isColSelected(day, date.month, date.year),
              })}
              onClick={() => updateSelectedCols(day, date.month, date.year)}
            >
              <span className={classNames('calendar__day-name', { 'is-compact': isCompactView })}>
                {dateHelper.getDayName(date.year, date.month, day)}
              </span>
              <span className={classNames('calendar__day-date', { 'is-compact': isCompactView })}>
                {dateHelper.getFormat00(day)}
              </span>
              <span className={classNames('calendar__month-name', { 'is-compact': isCompactView })}>
                {dateHelper.getMonthName(date.year, date.month, 'LLL')}
              </span>
            </div>
            <TimelineDayTooltip accommodationTypeIds={accommodationTypeIds} resortId={resort} date={getDayDate(day)} />
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

export default TimelineDaysNavbar
