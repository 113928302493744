import * as React from 'react'
import { PromocodeGroup } from '@models/promocode'
import { useAppDispatch } from '@store/index'
import { Button, Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { SaveButton } from '@hyper/button'
import { FormPlain } from '@hyper/forms'
import { FormInputNumber } from '@hyper/forms/form-input-number'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useFormRequest } from '@components/hooks/use-api-request'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useModal } from '@components/modals/use-modal'
import { BaseModalProps } from '@components/modals/types'
import { Form } from '@hyper/forms/form'
import { updatePromocodeGroupDetails } from '@store/slices/promocodes-slice'

interface Props extends BaseModalProps {
  promocodeGroup: PromocodeGroup
}

interface FormInputs {
  amount: number
}

export const PromocodeGroupGenerateCodesDialog: React.FC<Props> = ({ promocodeGroup, toggleIsVisible }) => {
  const dispatch = useAppDispatch()
  const methods = useForm<FormInputs>({
    defaultValues: {
      amount: 5,
    },
  })
  const { addSuccessMessage } = useNotificationHook()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    dispatch(updatePromocodeGroupDetails(await commonObjectPost<PromocodeGroup>(promocodeGroup.urls.codes, payload)))
    addSuccessMessage('Sukces', `Wygenerowano ${payload.amount} nowych kodów.!`)
    toggleIsVisible()
  }, methods.setError)

  const [handleEdit] = useModal('PromocodeGroupEditDialog', { promocodeGroup }, { persist: true })

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Generowanie nowych kodów w {promocodeGroup.name}</ModalHeader>
      <ModalBody>
        <Row>
          <FormPlain colSize={6} name="amount">
            <label className="d-block mt-3">Liczba kodów do wygenerowania</label>
          </FormPlain>

          <FormInputNumber colSize={6} name="amount" />
        </Row>
      </ModalBody>

      <ModalFooter style={{ display: 'block' }}>
        <Row>
          <Col md={6}>
            <Button color="light" type="button" onClick={handleEdit}>
              <i className="uil uil-edit text-muted mr-1 cursor-pointer" />
              Edytuj
            </Button>
          </Col>
          <Col md={6} className="text-right">
            <Button color="light" type="button" onClick={toggleIsVisible} className="mr-1">
              Anuluj
            </Button>
            <SaveButton className="btn btn-green" isSaving={isLoading} label="Generuj" labelSaving="Generowanie.." />
          </Col>
        </Row>
      </ModalFooter>
    </Form>
  )
}
