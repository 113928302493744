import * as React from 'react'
import { useModal } from '@components/modals/use-modal'
import { useGetTechnicalOrderDetailsQuery } from '@api/technical-orders'
import { useAppData } from '@components/hooks/use-app-data'
import { TechnicalOrderKind } from '@models/technical-orders'

interface Props {
  id: string
  kind: TechnicalOrderKind
}

export const TechnicalOrdersDetailsView: React.FC<Props> = ({ id, kind }) => {
  const { urls } = useAppData()

  const [handleDetails, hidePreview] = useModal('TechnicalOrderPreviewModal')
  const [handleGetDetails] = useGetTechnicalOrderDetailsQuery()

  const showPreview = React.useCallback(
    async (id: string) => {
      const detailsUrls: Record<TechnicalOrderKind, string> = {
        technical_order: urls.technical_orders.technical_orders,
        guests_technical_order: urls.technical_orders.guests_technical_orders,
        reception_technical_order: urls.technical_orders.reception_technical_orders,
      }
      const url = detailsUrls[kind]

      const { unwrap, refetch } = handleGetDetails(`${url}${id}/`, false)
      const technicalOrderDetails = await unwrap()
      handleDetails(null, { technicalOrderDetails, refetch }, true)
    },
    [handleGetDetails, urls],
  )

  React.useEffect(() => {
    if (id) {
      showPreview(id)

      return () => {
        hidePreview()
      }
    }
  }, [id])

  return null
}
