import * as React from 'react'
import { Collapse } from 'reactstrap'
import { formatPrice, groupByField } from '@helpers/utils'
import { useExpandableItems } from '@components/hooks/use-expandable-items'
import { ReceptionBookingCartFeedingGuest } from '@modules/reception/cart/cart-items/feeding/reception-booking-cart-feeding-guest'
import { ControlledFormCheckbox } from '@hyper/forms/controlled-form-checkbox'
import classNames from 'classnames'
import { ReceptionBookingDetails, ReceptionBookingDetailsCartItem } from '@models/reception'
import { ReceptionBookingGuest } from '@models/booking'
import { createCombinedCartItemsPrice } from '@modules/reception/cart/utils'
import { useCartItemGroupSelection } from '@modules/reception/cart/use-cart-item-group-selection'
import { useContext } from 'react'
import { CartContext } from '@modules/reception/cart/cart-context'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { ReceptionBookingCartItemPaidIcon } from '@modules/reception/cart/cart-items/reception-booking-cart-item-paid-icon'

interface Props {
  feeding: ReceptionBookingDetailsCartItem[]
  bookingDetails: ReceptionBookingDetails
}

export const ReceptionBookingCartFeeding = ({ feeding, bookingDetails }: Props): JSX.Element => {
  const { allRowCollapsed } = useContext(CartContext)
  const { isExpanded, toggleExpanded, ExpandArrow, setExpanded } = useExpandableItems()

  const groupedByGuests = groupByField(feeding, 'booking_guest')

  const { totalPrice, originalTotalPrice } = createCombinedCartItemsPrice(feeding)
  const { allSelected, someSelected, changeGroup } = useCartItemGroupSelection(feeding)

  const guests = Object.keys(groupedByGuests).map(
    guestId => bookingDetails.guests.find(guest => guest.id === parseInt(guestId, 10)) as ReceptionBookingGuest,
  )

  useDidUpdateEffect(() => {
    setExpanded('feeding', !allRowCollapsed)
  }, [allRowCollapsed])

  const isEveryItemPaid = feeding.length && feeding.every(item => item.is_paid)

  return (
    <li className="row border-bottom align-items-center reception-booking-cart__expandable">
      <div
        className={classNames('col-7 d-flex py-1 align-items-center reception-booking-cart__selectable', {
          'is-selected': allSelected,
        })}
      >
        {isEveryItemPaid ? (
          <ReceptionBookingCartItemPaidIcon>
            <strong>Wyżywienie</strong>
          </ReceptionBookingCartItemPaidIcon>
        ) : (
          <ControlledFormCheckbox
            id="feeding"
            label="Wyżywienie"
            indeterminate={someSelected}
            onChange={changeGroup}
            checked={allSelected}
          />
        )}
        <ExpandArrow isExpanded={isExpanded('feeding')} onExpand={toggleExpanded('feeding')} className="lh-0 ml-1" />
      </div>
      <div className="col-3 font-weight-bold text-muted text-strikethrough">{formatPrice(originalTotalPrice)}</div>
      <div className="col-2 font-weight-bold">{formatPrice(totalPrice)}</div>
      <Collapse isOpen={isExpanded('feeding')} className="w-100 overflow-hidden">
        <ul className="list-unstyled">
          {guests.map((guest, index) => (
            <ReceptionBookingCartFeedingGuest key={index} guest={guest} feedingForGuest={groupedByGuests[guest.id]} />
          ))}
        </ul>
      </Collapse>
    </li>
  )
}
