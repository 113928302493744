import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { ReservationCard } from '@modules/reservations/details/common/reservation-card'
import { YesNoBadge } from '@components/badges/yes-no'
import { formatIban } from '@helpers/text'
import { ButtonWithIcon } from '@components/button-with-icon'
import { useModal } from '@components/modals/use-modal'
import { ReservationDataRow } from '@modules/reservations/details/common/reservation-data-row'

interface Props {
  booking: ReceptionBookingDetails
}

export const ReservationDepositReturn = ({ booking }: Props): JSX.Element => {
  const [handleClick] = useModal('ReservationDepositReturnModal', { booking })

  return (
    <ReservationCard
      title="Dane do zwrotu"
      // cardActions={
      //   booking.deposit_transfer_required &&
      //   booking.deposit_transfer_status === 'missing_client_data' && (
      //     <ButtonWithIcon text="Edytuj" icon="uil-edit-alt" handleClick={handleClick} color="light" />
      //   )
      // }
      cardActions={<ButtonWithIcon text="Edytuj" icon="uil-edit-alt" handleClick={handleClick} color="light" />}
    >
      <ReservationDataRow name="Numer konta:">
        <strong>{formatIban(booking.online_checkin_data?.deposit_return_account_number || '') || 'brak'}</strong>
      </ReservationDataRow>
      <ReservationDataRow name="Właściciel:">
        <strong>{booking.online_checkin_data?.deposit_return_name || 'brak'}</strong>
      </ReservationDataRow>
      <ReservationDataRow name="Ulica:">
        <strong>{booking.online_checkin_data?.deposit_return_address || 'brak'}</strong>
      </ReservationDataRow>
      <ReservationDataRow name="Miasto:">
        <strong>{booking.online_checkin_data?.deposit_return_city || 'brak'}</strong>
      </ReservationDataRow>
      <ReservationDataRow name="Kod pocztowy:">
        <strong>{booking.online_checkin_data?.deposit_return_postcode || 'brak'}</strong>
      </ReservationDataRow>
      <ReservationDataRow name="Zwrot kaucji wymagany:">
        <YesNoBadge value={booking.deposit_transfer_required} />
      </ReservationDataRow>
    </ReservationCard>
  )
}
