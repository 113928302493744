import * as React from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumb, BreadcrumbItem, Col, Row } from 'reactstrap'

interface Item {
  active: boolean
  label: string
  path: string
}

interface Props {
  title: string
  breadCrumbItems: Item[]
}

export const PageTitle = ({ title, breadCrumbItems }: Props): JSX.Element => (
  <Row>
    <Col>
      <div className="page-title-box">
        <div className="page-title-right">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">Strona Główna</Link>
            </BreadcrumbItem>
            {breadCrumbItems.map((item, index) => (
              <BreadcrumbItem active={item.active} key={index}>
                {!item.active ? <Link to={item.path}>{item.label}</Link> : item.label}
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        </div>
        <h4 className="page-title">{title}</h4>
      </div>
    </Col>
  </Row>
)
