import * as React from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownItemProps,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from 'reactstrap'
import classnames from 'classnames'
import { DropdownMenuProps } from 'reactstrap/es/DropdownMenu'
import { useClickOutside } from '@components/hooks/use-click-outside'

interface Props {
  children: React.ReactNode
  toggleClassName?: string
  dropdownMenuProps?: DropdownMenuProps
  controlled?: boolean
  wrapperClassName?: string
}

export const ReservationDropdown = ({
  children,
  toggleClassName,
  dropdownMenuProps,
  controlled,
  wrapperClassName,
}: Props) => {
  const DropdownElement = controlled ? Dropdown : UncontrolledButtonDropdown
  const [dropdownOpen, setDropdownOpen] = React.useState(false)

  const dropdownRef = React.useRef(null)

  const toggle = () => {
    if (dropdownOpen || !controlled) return

    setDropdownOpen(prevState => !prevState)
  }

  const handleToggleClick = () => {
    if (!dropdownOpen || !controlled) return
    closeDropdown()
  }

  const closeDropdown = () => {
    setDropdownOpen(false)
  }

  useClickOutside(dropdownRef, closeDropdown)

  return (
    <div ref={dropdownRef} className={wrapperClassName}>
      <DropdownElement {...(controlled && { isOpen: dropdownOpen, toggle })}>
        <DropdownToggle color="light rounded" className={toggleClassName} onClick={handleToggleClick}>
          <i className="uil-ellipsis-v lh-initial text-default" />
        </DropdownToggle>
        <DropdownMenu className="py-0" positionFixed={true} {...dropdownMenuProps}>
          {children}
        </DropdownMenu>
      </DropdownElement>
    </div>
  )
}

type ReservationDropdownProps = DropdownItemProps

ReservationDropdown.Item = ({
  onClick = undefined,
  children,
  className = 'pl-2 py-1',
  ...props
}: ReservationDropdownProps) => (
  <DropdownItem onClick={onClick} className={classnames(className)} style={{ minHeight: '32px' }} {...props}>
    {children}
  </DropdownItem>
)
