import * as React from 'react'
import { TechnicalOrderTableUnitHeader } from '@modules/technical-orders/common/table-unit-header'
import { TechnicalOrderTableTypeHeader } from '@modules/technical-orders/common/table-type-header'
import { TechnicalOrderTableUsersHeader } from '@modules/technical-orders/common/table-users-header'

interface Props {
  resortId: number
  onFiltersChange: (...params) => void
  tableView: 'grid' | 'list'
  selectableHeader: React.ReactNode
}

export const TechnicalOrdersOrderedTableHeaders: React.FC<Props> = ({
  resortId,
  onFiltersChange,
  tableView,
  selectableHeader,
}) => (
  <thead>
    <tr>
      <HeadCell width={40} maxWidth={40} shouldRender={tableView === 'list'} />
      {tableView === 'list' && selectableHeader}
      <HeadCell width={200} content="Zespół" shouldRender={tableView === 'grid'} />
      <HeadCell width={150} content="Nr zadania" />
      <HeadCell width={150} content="Lokalizacja" shouldRender={!resortId} />
      <HeadCell
        width={130}
        content={<TechnicalOrderTableUnitHeader resortId={resortId} setFilters={onFiltersChange} />}
      />
      <HeadCell width={150} content="Najbliższy pobyt" />
      <HeadCell width={250} content={<TechnicalOrderTableTypeHeader setFilters={onFiltersChange} />} />
      <HeadCell
        shouldRender={tableView !== 'grid'}
        width={200}
        content={<TechnicalOrderTableUsersHeader setFilters={onFiltersChange} resortId={resortId} />}
      />
      <HeadCell width={150} content="Zgłaszający" />
      <HeadCell width={150} content="Zlecający" />
      <HeadCell width={70} />
      <HeadCell />
    </tr>
  </thead>
)

const HeadCell = ({
  width,
  maxWidth,
  content,
  shouldRender = true,
}: {
  width?: number
  maxWidth?: number
  content?: React.ReactNode
  shouldRender?: boolean
}) => {
  if (!shouldRender) return null

  return (
    <th className="valign-middle border-top-0" style={{ width, maxWidth }}>
      {content}
    </th>
  )
}
