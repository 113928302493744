import * as React from 'react'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { AccommodationType } from '@models/booking'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectPost } from '@store/actions/generic-actions'
import { CmsResortDetails } from '@modules/cms/models'
import { CmsReservationAppResortsRow } from '@modules/cms/reservation-app/resorts-row'
import { DraggableTableWrapper } from '@components/table/draggable-table-wrapper'
import { useTimeoutWhen } from 'rooks'
import { reOrderArray } from '@helpers/utils'
import { getCmsResorts } from '@store/actions/cms-reservation-app-actions'
import { cmsResortsSelector, setCmsResorts } from '@store/slices/cms-reservation-app-slice'

const headerValues: TableHeaderValue<AccommodationType>[] = [
  {
    title: 'Nazwa',
  },
  {
    title: 'Lokalizacja',
  },
  { title: '' },
]

export const CmsReservationAppResorts: React.FC = () => {
  const [isPositionUpdating, setPositionUpdating] = React.useState(false)
  useTimeoutWhen(() => setPositionUpdating(false), 300, isPositionUpdating)

  const [filters, setFilters] = React.useState<TableFilters>({
    ordering: '',
    page: 0,
    page_size: 0,
    search: '',
  })
  const positionUrl = useAppSelector((state: RootState) => state.appState.appData.urls.cms.resorts_position)
  const dispatch = useAppDispatch()

  const { isLoading, action: fetch } = useApiRequest(async () => await dispatch(getCmsResorts()))

  const resorts = useAppSelector(cmsResortsSelector)

  const { action: handleDrop } = useApiRequest(async data => {
    setPositionUpdating(true)
    const source = data.source.index
    const destination = data.destination.index

    dispatch(
      setCmsResorts(
        await commonObjectPost<CmsResortDetails[]>(
          positionUrl,
          reOrderArray(resorts, source, destination)
            .map((node, index) => ({ ...node, position: index }))
            .reduce((nodes, node) => ({ ...nodes, [node.id]: node.position }), {}),
        ),
      ),
    )
  })

  React.useEffect(() => {
    fetch()
  }, [])

  return (
    <DraggableTableWrapper
      dragDropContextProps={{ onDragEnd: handleDrop }}
      droppableProps={{ droppableId: 'droppable-page-nodes' }}
    >
      <Table
        showPagination={false}
        filters={filters}
        setFilters={setFilters}
        headerValues={headerValues}
        isLoading={isLoading || isPositionUpdating}
      >
        {resorts.map((row, index) => (
          <CmsReservationAppResortsRow key={row.id} index={index} resort={row} />
        ))}
      </Table>
    </DraggableTableWrapper>
  )
}
