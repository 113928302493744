import * as React from 'react'
import { useDetailsModal } from '@components/hooks/use-details-modal'
import { NavigationPath } from '@models/routes'
import { ProductPackageDetails } from '@modules/endless-holiday/models'

interface Props {
  details: ProductPackageDetails | undefined
}

export const EndlessHolidayViewParamsTransactionDetails = ({ details }: Props): React.ReactNode => {
  const { handleClientDetails } = useDetailsModal(
    ['EndlessHolidayDetailsModal', { product: details }],
    NavigationPath.EndlessHoliday,
    NavigationPath.EndlessHolidayWithParams,
    details!.id,
  )

  React.useEffect(() => {
    if (details) {
      handleClientDetails()
    }
  }, [])

  return null
}
