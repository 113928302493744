import * as React from 'react'
import { ImprovementOrder } from '@modules/housekeeping/models'
import { CustomInput } from 'reactstrap'
import { HousekeepingOrderWorkersRow } from '@modules/housekeeping/common/workers'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { HousekeepingImprovementOrdersRowActions } from '@modules/housekeeping/cleaning-orders/improvement/actions'
import { HousekeepingImprovementOrdersRowImprovements } from '@modules/housekeeping/cleaning-orders/improvement/row-improvements'
import { FormProvider, useForm } from 'react-hook-form'
import { HousekeepingOrderCompanyRow } from '@modules/housekeeping/common/company'
import { HousekeepingOrderRowSelectWorkers } from '@modules/housekeeping/cleaning-orders/common/housekeeping-order-row-select-workers'
import { HousekeepingOrderRowSelectCompany } from '@modules/housekeeping/cleaning-orders/common/housekeeping-order-row-select-company'
import { CustomReactSelectOption, makeDefaultSelectOption } from '@components/custom-react-select'
import { createSelectOption } from '@helpers/utils'
import { IconWithText } from '@components/icon-with-text'
import { useHousekeepingCleaningCompanies } from '@modules/housekeeping/users/use-housekeeping-cleaning-companies'

interface Props {
  improvementOrder: ImprovementOrder
  isChecked: boolean
  toggleSelectedOrder: (order: ImprovementOrder, add: boolean) => void
  resortId: number
}

export interface HousekeepingImprovementOrderRowFormInputs {
  users: string[]
  company: CustomReactSelectOption | null
}

export const HousekeepingImprovementOrdersRow: React.FC<Props> = ({
  improvementOrder,
  isChecked,
  toggleSelectedOrder,
  resortId,
}) => {
  const housekeeping_companies = useHousekeepingCleaningCompanies(resortId)

  const methods = useForm<HousekeepingImprovementOrderRowFormInputs>({
    defaultValues: {
      company: makeDefaultSelectOption(
        housekeeping_companies.map(company => createSelectOption(company.name, company.id)),
        improvementOrder.company_id,
      ),
    },
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    toggleSelectedOrder(improvementOrder, event.target.checked)

  return (
    <FormProvider {...methods}>
      <tr className="housekeeping__orders_table__row">
        <td className="housekeeping__orders_table__column housekeeping__orders_table__column__first">
          <CustomInput
            checked={isChecked}
            onChange={handleChange}
            type="checkbox"
            id={`improvement-order-row-${improvementOrder.id}`}
            value={improvementOrder.id}
          />
        </td>
        <td className="housekeeping__orders_table__column text-semi-strong">
          {improvementOrder.apartment.name}
          {!!improvementOrder.checked_in_booking && (
            <IconWithText
              icon="uil-chat-bubble-user"
              text="Goście w środku"
              wrapperClassNames="d-block text-dark mt-1 w-100"
            />
          )}
        </td>
        {improvementOrder.status === 'NEW' && !improvementOrder.users.length ? (
          <>
            <td className="housekeeping__orders_table__column">
              <HousekeepingOrderRowSelectCompany resortId={improvementOrder.resort} />
            </td>
            <td className="housekeeping__orders_table__column">
              <HousekeepingOrderRowSelectWorkers resortId={improvementOrder.resort} />
            </td>
          </>
        ) : (
          <>
            <HousekeepingOrderCompanyRow order={improvementOrder} resortId={improvementOrder.resort} />
            <HousekeepingOrderWorkersRow order={improvementOrder} />
          </>
        )}
        <td className="housekeeping__orders_table__column">
          {improvementOrder.status_display}
          {improvementOrder.status === 'FINISHED' && (
            <div className="font-11">({toDefaultDateTimeFormat(improvementOrder.finished_at)})</div>
          )}
        </td>

        <td className="housekeeping__orders_table__column text-semi-strong">
          <HousekeepingImprovementOrdersRowImprovements id={improvementOrder.id} items={improvementOrder.items} />
        </td>

        <td className="housekeeping__orders_table__column text-nowrap text-right">
          <HousekeepingImprovementOrdersRowActions improvementOrder={improvementOrder} />
        </td>
      </tr>
    </FormProvider>
  )
}
