import * as React from 'react'
import { TechnicalOrderFormOptionsEnterTimeRangeRow } from '@modules/technical-orders/technical-order-modal/form-enter-time-range-row'
import { useFieldArray, useFormContext } from 'react-hook-form'

interface Props {}

interface FormInputs {
  technical_order_enter_where_guest_absence_range: {
    date: string
    time_from: string
    time_to: string
  }[]
}
export const TechnicalOrderFormOptionsEnterTimeRange: React.FC<Props> = () => {
  const { control } = useFormContext<FormInputs>()
  const { fields, append } = useFieldArray({
    control,
    name: 'technical_order_enter_where_guest_absence_range',
  })

  React.useEffect(() => {
    if (fields.length === 0) {
      append({ date: '', time_from: '', time_to: '' })
    }
  }, [fields.length])

  return (
    <div className="ml-3">
      {fields.map((item, index) => (
        <TechnicalOrderFormOptionsEnterTimeRangeRow key={index} index={index} />
      ))}
    </div>
  )
}
