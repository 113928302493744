import * as React from 'react'
import { sortByPosition } from '@helpers/utils'
import { AvailableApartment } from '@models/reservation-create'
import { LocalSelectionMapItemsList } from '@modules/reservations/create/local-selection-map/local-selection-map-items-list'
import { ResortDetails, ResortMapSector } from '@models/booking'

interface Props {
  apartments: AvailableApartment[]
  resortDetails: ResortDetails
  maximumListHeight: number
  onSectorSelection
}

export const LocalSectorsList = ({
  resortDetails,
  maximumListHeight,
  onSectorSelection,
  apartments,
}: Props): JSX.Element => {
  const renderSectorName = (sector: ResortMapSector) => sector.display_name

  const sectorsSortedByPosition = React.useMemo(() => {
    const availableSectors = resortDetails.map_svg_sectors.filter(sector =>
      apartments.some(apartment => apartment.sector === sector.id),
    )

    return sortByPosition(availableSectors)
  }, [resortDetails.map_svg_sectors, apartments])

  return (
    <LocalSelectionMapItemsList
      items={sectorsSortedByPosition}
      onItemSelection={onSectorSelection}
      maximumListHeight={maximumListHeight}
      title="Wskaż sektor na mapie lub z listy poniżej:"
      textRenderer={renderSectorName}
      emptyListElement={<p className="font-size-sm text-darker-gray">Brak dostępnych sektorów</p>}
    />
  )
}
