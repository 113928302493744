import * as React from 'react'
import { SubscriptionDetails } from '@modules/subscription/models'
import { useForm } from 'react-hook-form'
import { Button, ModalFooter, ModalHeader } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useFormRequest } from '@components/hooks/use-api-request'
import ModalBody from 'reactstrap/lib/ModalBody'
import { SubscriptionEditModalForm } from '@modules/subscription/edit-modal/form'
import { useGroupedSources } from '@components/hooks/use-grouped-sources'
import { useSellersOptions } from '@components/hooks/use-grouped-sellers'
import { useGroupedPromotions } from '@components/hooks/use-grouped-promotions'
import { commonObjectPut } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { updateSubscriptionDetails } from '@store/slices/subscription-slice'
import { useAppDispatch } from '@store/index'
import { BaseModalProps } from '@components/modals/types'
import { ClientUser } from '@models/clients'
import * as R from 'ramda'
import { useSubscriptionOptions } from '@modules/subscription/hooks/use-subscription-options'
import { Form } from '@hyper/forms/form'
import { parseISODate } from '@helpers/date-helper'

interface Props extends BaseModalProps {
  subscriptionDetails: SubscriptionDetails
}

export interface SubscriptionEditModalFormInputs {
  client: ClientUser | null
  clients: ClientUser[]
  invoice_company: string
  invoice_street: string
  invoice_postcode: string
  invoice_city: string
  invoice_nip: string
  deposit_amount: string
  seller: CustomReactSelectOption | undefined
  source_marketing: CustomReactSelectOption | undefined
  promotion_marketing: CustomReactSelectOption | undefined
  recommender_client: number | undefined
  allow_subscription_code_localization_convert: boolean
  allow_change_client_data: boolean
  created: string
}

export const SubscriptionEditModal: React.FC<Props> = ({ subscriptionDetails, toggleIsVisible }) => {
  const sources = useGroupedSources()
  const sellers = useSellersOptions()
  const promotions = useGroupedPromotions()
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()
  const { hasOption } = useSubscriptionOptions(subscriptionDetails)

  const subscriptionDefaultValues = React.useMemo(
    () =>
      R.pick(
        [
          'client',
          'clients',
          'invoice_company',
          'invoice_street',
          'invoice_postcode',
          'invoice_city',
          'invoice_nip',
          'deposit_amount',
          'recommender_client',
          'created',
        ],
        subscriptionDetails,
      ),
    [subscriptionDetails],
  )

  const methods = useForm<SubscriptionEditModalFormInputs>({
    defaultValues: {
      ...subscriptionDefaultValues,
      allow_subscription_code_localization_convert: hasOption('localization'),
      allow_change_client_data: hasOption('client_data'),
      with_zoo_borysew_tickets: hasOption('zoo_borysew'),
      source_marketing: sources.find(row => row.value === subscriptionDetails.source_marketing_id),
      seller: sellers.find(row => row.value === subscriptionDetails.seller_id),
      promotion_marketing: promotions.find(row => row.value === subscriptionDetails.promotion_marketing_id),
      auto_cancel_after_date: parseISODate(subscriptionDetails.auto_cancel_after_date),
      required_payment_date: parseISODate(subscriptionDetails.required_payment_date),
    },
  })

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: SubscriptionEditModalFormInputs) => {
    dispatch(
      updateSubscriptionDetails(
        await commonObjectPut<SubscriptionDetails>(subscriptionDetails.urls.details, {
          ...payload,
          client: payload.client?.id,
          clients: payload.clients.map(client => client.id),
          seller: payload.seller?.value,
          source_marketing: payload.source_marketing?.value,
          promotion_marketing: payload.promotion_marketing?.value,
        }),
      ),
    )
    addSuccessMessage('Sukces', 'Pakiet prywatny został zmieniony!')
    toggleIsVisible()
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>
        Edytuj pakiet prywatny <span className="text-semi-strong">{subscriptionDetails.number}</span>
      </ModalHeader>
      <ModalBody>
        {subscriptionDetails && <SubscriptionEditModalForm subscriptionDetails={subscriptionDetails} />}
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
