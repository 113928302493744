import * as React from 'react'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { ButtonWithIcon } from '@components/button-with-icon'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { HrWorkerDetails } from '@modules/hr/workers/models'
import { useRestoreHrWorkerMutation } from '@api/agreements'

interface Props {
  worker: HrWorkerDetails
}

export const HrWorkerRestoreButton = ({ worker }: Props): JSX.Element => {
  const { addSuccessNotification } = useNotificationHook()

  const [restoreWorker] = useRestoreHrWorkerMutation()

  const { isLoading, action: handleRemove } = useApiRequest(async (reason: string) => {
    await restoreWorker({ worker, reason }).unwrap()
    addSuccessNotification('Pracownik został przywrócony')
  })

  return (
    <CommonObjectConfirmAction isLoading={isLoading} handleAccept={handleRemove} askForReason>
      <ButtonWithIcon icon="uil-redo" color="info" text="Przywróć" />
    </CommonObjectConfirmAction>
  )
}
