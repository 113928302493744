import * as React from 'react'
import onScan from 'onscan.js'

interface KeyboardScannerProps {
  onScanComplete: (number: string) => void
}

export const KeyboardScanner: React.FC<KeyboardScannerProps> = ({ onScanComplete }) => {
  React.useEffect(() => {
    onScan.attachTo(document, {
      onScan: ean => onScanComplete(ean.replace(/m$/, '')),
      avgTimeByChar: 100,
    })

    return () => onScan.detachFrom(document)
  }, [])

  return null
}
