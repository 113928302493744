import * as React from 'react'
import { ColAuto } from '@hyper/col-auto'
import { Button, Row } from 'reactstrap'
import { SearchFilter, SearchFilterProps } from '@modules/reception/common/filters/search-filter'
import { BaseTableFilters, useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { useFormContext } from 'react-hook-form'
import * as R from 'ramda'

export interface CommonTableFiltersProps<T> {
  filters: T
  defaultFilters: T
  setFilters: (filters: T) => void
}

interface Props<T> extends CommonTableFiltersProps<T> {
  children?: React.ReactNode
  rowClassName?: string
  searchParams?: Partial<SearchFilterProps>
  clearParams?: React.HTMLAttributes<HTMLButtonElement>
  searchWrapperParams?: React.HTMLAttributes<HTMLDivElement>
  withSearch?: boolean
}

export const CommonTableFilters = <T extends BaseTableFilters>({
  children,
  filters,
  setFilters,
  defaultFilters,
  rowClassName,
  withSearch = true,
  clearParams,
  searchWrapperParams,
  searchParams,
}: Props<T>): JSX.Element => {
  const { reset, watch, formState } = useFormContext<T>()

  const handleReset = () => {
    reset()
    setFilters(defaultFilters)
  }

  const onSubmit = async (payload: T) => setFilters({ ...filters, ...payload })

  useHandleTableFiltersChange(watch, filters, onSubmit)

  const isDirty = React.useMemo(() => !R.equals(formState.defaultValues, filters), [filters, formState.defaultValues])

  return (
    <Row className={rowClassName}>
      {children}
      <ColAuto>
        {isDirty && (
          <Button color="light" className="ml-1 mt-label" type="button" onClick={handleReset} {...clearParams}>
            Wyczyść
          </Button>
        )}
      </ColAuto>
      {withSearch && (
        <div className="col-3 ml-auto" {...searchWrapperParams}>
          <SearchFilter colClassName="pr-0" {...searchParams} />
        </div>
      )}
    </Row>
  )
}
