import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { PromocodeTouristVoucherDetails } from '@modules/promotions/promocode-tourist-voucher/models'
import { Button, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAppDispatch } from '@store/index'
import { useFormRequest } from '@components/hooks/use-api-request'
import { useForm } from 'react-hook-form'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { updatePromocodeTouristVoucher } from '@store/actions/promocode-tourist-voucher-actions'
import { FormInput } from '@hyper/forms'
import { useGroupedSellers, useSellersOptions } from '@components/hooks/use-grouped-sellers'
import { useGroupedSources } from '@components/hooks/use-grouped-sources'
import { FormSelect } from '@hyper/forms/form-select'
import { Form } from '@hyper/forms/form'

interface Props extends BaseModalProps {
  promocodeTouristVoucherDetails: PromocodeTouristVoucherDetails
}

export interface PromocodeTouristVoucherUpdateFormInputs {
  source_marketing: CustomReactSelectOption
  seller: CustomReactSelectOption
  tourist_voucher_amount: string
}

export const PromocodeTouristVoucherInfoEditDialog: React.FC<Props> = ({
  promocodeTouristVoucherDetails,
  toggleIsVisible,
}) => {
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const sellers = useGroupedSellers([])
  const sellersList = useSellersOptions()
  const sources = useGroupedSources()

  const methods = useForm<PromocodeTouristVoucherUpdateFormInputs>({
    defaultValues: {
      tourist_voucher_amount: promocodeTouristVoucherDetails.tourist_voucher_amount,
      seller: sellersList.find(row => row.value === promocodeTouristVoucherDetails.seller_id),
      source_marketing: sources.find(row => row.value === promocodeTouristVoucherDetails.source_marketing_id),
    },
  })
  const { isLoading, action: onSubmit } = useFormRequest(async (payload: PromocodeTouristVoucherUpdateFormInputs) => {
    dispatch(await updatePromocodeTouristVoucher([promocodeTouristVoucherDetails, payload]))
    toggleIsVisible()
    addSuccessMessage()
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Voucher turystyczny {promocodeTouristVoucherDetails.code}</ModalHeader>
      <ModalBody>
        <Row>
          <FormSelect options={sellers} name="seller" label="Sprzedawca" selectProps={{ isClearable: true }} />
          <FormSelect options={sources} name="source_marketing" label="Źródło" selectProps={{ isClearable: true }} />

          {promocodeTouristVoucherDetails.status === 'initial' && (
            <FormInput label="Kwota Bonu turystycznego" type="number" name="tourist_voucher_amount" />
          )}
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton isSaving={isLoading} className="btn btn-green" />
      </ModalFooter>
    </Form>
  )
}
