import * as React from 'react'
import { AgreementFormInputs } from '@modules/hr/agreement/create/modal'
import { useFormContext } from 'react-hook-form'
import { PackageSummarySection, PackageSummarySectionDataRow } from '@modules/package/create/package-summary-section'
import { Row } from 'reactstrap'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { useHrAppData } from '@modules/hr/use-hr-app-data'
import { formatPrice, getById } from '@helpers/utils'
import { HrWorkerSummaryData } from '@modules/hr/workers/create/steps/hr-worker-summary-data'

export const AgreementCreateFormSummary = (): JSX.Element => {
  const { companies, job_positions, agents } = useHrAppData()
  const methods = useFormContext<AgreementFormInputs>()

  const data = methods.getValues()

  const selectedCompany = getById(companies, data.company?.value)
  const selectedJobPosition = getById(job_positions, data.job_position?.value)
  const agent = getById(agents, data.agent?.value)

  return (
    <div className="pb-2">
      <h4 className="mt-0 text-secondary">Podsumowanie umowy</h4>
      <HrWorkerSummaryData />
      <Row>
        <PackageSummarySection title="Dane stanowiska" className="col-8">
          <PackageSummarySectionDataRow title="Resort:">{data.resort?.label}</PackageSummarySectionDataRow>
          <PackageSummarySectionDataRow title="Spółka:">{selectedCompany?.name ?? ''}</PackageSummarySectionDataRow>
          <PackageSummarySectionDataRow title="Pełnomocnik:">{agent?.full_name ?? ''}</PackageSummarySectionDataRow>
          <PackageSummarySectionDataRow title="Stanowisko:">
            {selectedJobPosition?.name ?? ''}
          </PackageSummarySectionDataRow>
          <PackageSummarySectionDataRow
            title={
              <span>
                Stawka godzinowa <small>(brutto)</small>:
              </span>
            }
          >
            {data.hourly_rate ? formatPrice(data.hourly_rate) : ''}
          </PackageSummarySectionDataRow>
          <PackageSummarySectionDataRow title="Okres trwania umowy:">
            {toDefaultDateFormat(data.date_of_start)} - {toDefaultDateFormat(data.date_of_expiration)}
          </PackageSummarySectionDataRow>
          <PackageSummarySectionDataRow title="Data zawarcia umowy:">
            {toDefaultDateFormat(data.date_of_conclusion)}
          </PackageSummarySectionDataRow>
        </PackageSummarySection>
      </Row>
    </div>
  )
}
