import * as React from 'react'
import { Button } from 'reactstrap'
import { BeddingOrder, BeddingOrderDetails } from '@modules/housekeeping/models'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useHandleAxiosServerErrors } from '@helpers/handle-axios-errors'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useModal } from '@components/modals/use-modal'
import { updateHousekeepingBeddingOrderDetails } from '@store/slices/housekeeping-slice'

interface HousekeepingBeddingOrdersRowActionsProps {
  beddingOrder: BeddingOrder
}

export const HousekeepingBeddingOrdersRowActions: React.FC<HousekeepingBeddingOrdersRowActionsProps> = ({
  beddingOrder,
}) => {
  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()
  const handleAxiosServerErrors = useHandleAxiosServerErrors()

  const handleFinish = async () => {
    try {
      dispatch(
        updateHousekeepingBeddingOrderDetails(
          await commonObjectPost<BeddingOrderDetails>(beddingOrder.urls.finish_working),
        ),
      )

      addSuccessNotification('Zlecenie zostało wykonane!')
    } catch (error) {
      handleAxiosServerErrors(error)
    }
  }

  const handleOrderUpdate = (order: BeddingOrderDetails) => {
    dispatch(updateHousekeepingBeddingOrderDetails(order))
  }

  const [handleAssign] = useModal('HousekeepingCleaningOrderAssignModal', {
    order: beddingOrder,
    onlyHprCompany: true,
    onOrderUpdate: handleOrderUpdate,
    serviceKind: 'bedding',
    workerRoles: ['bedding'],
  })

  return (
    <>
      {beddingOrder.status !== 'FINISHED' && (
        <Button color="green" onClick={handleFinish} className="btm-sm" disabled={!beddingOrder.users.length}>
          Wykonane
        </Button>
      )}

      {beddingOrder.status === 'PENDING' && (
        <Button color="warning" className="text-white mx-1 btn-sm" onClick={handleAssign}>
          Zmień osoby
        </Button>
      )}
    </>
  )
}
