import * as React from 'react'
import { RefundBookingsFiltersParams } from '@modules/accounting/refund/bookings/index'
import { useForm } from 'react-hook-form'
import { ColAuto } from '@hyper/col-auto'
import { FormInput } from '@hyper/forms'
import { Button, FormGroup, Label } from 'reactstrap'
import DateRangeInput from '@components/date/date-range-input'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { Form } from '@hyper/forms/form'

interface RefundBookingsFiltersProps {
  filters: RefundBookingsFiltersParams
  defaultFilters: RefundBookingsFiltersParams
  setFilters: any
}

export const RefundBookingsFilters: React.FC<RefundBookingsFiltersProps> = ({
  setFilters,
  defaultFilters,
  filters,
}) => {
  const methods = useForm<RefundBookingsFiltersParams>({
    defaultValues: defaultFilters,
  })

  const user = useAuthenticatedUser()

  const onSubmit = payload => setFilters({ ...filters, ...payload })

  const handleReset = () => {
    methods.reset()
    setFilters(defaultFilters)
  }

  useHandleTableFiltersChange(methods.watch, filters, onSubmit, { search: filters.search })

  return (
    <Form methods={methods} onSubmit={onSubmit} className="row form form-style-filter">
      <FormInput label="Ośrodek" colClassName="p-0 col-auto mx-1" colSize="col-auto" name="resort" type="select">
        <option value="">Wszystkie</option>
        {user.resorts.map(resort => (
          <option value={resort.id} key={resort.id}>
            {resort.name}
          </option>
        ))}
      </FormInput>
      <FormInput
        colClassName="p-0 col-auto mx-1"
        colSize="col-auto"
        type="select"
        label="Status"
        name="deposit_transfer_status"
      >
        <option value="">Wszystkie</option>
        <option value="missing_client_data">Brak danych klienta</option>
        <option value="waiting_for_client_data">Oczekuje na dane do przelewu</option>
        <option value="client_data_complete">Uzupełniono dane klienta</option>
        <option value="transfer_in_package">Przygotowano przelew</option>
        <option value="transfer_sent">Przelew został zrealizowany</option>
      </FormInput>

      <FormInput colClassName="p-0 col-auto mx-1" type="select" colSize="col-auto" label="Typ zwrotu" name="type">
        <option value="">Wszystkie</option>
        <option value="p24">Przelewy24</option>
        <option value="bank">Przelew tradycyjny</option>
      </FormInput>

      <ColAuto>
        <Label>Data wyjazdu</Label>
        <DateRangeInput startDateName="date_to_after" endDateName="date_to_before" />
      </ColAuto>

      <ColAuto>
        <FormGroup>
          <label className="d-block">&nbsp;</label>
          <Button color="primary">Filtruj</Button>
          {methods.formState.isDirty && (
            <Button color="light" className="ml-1" type="button" onClick={handleReset}>
              Wyczyść
            </Button>
          )}
        </FormGroup>
      </ColAuto>
    </Form>
  )
}
