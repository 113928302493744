import * as React from 'react'
import { NotificationEvents } from '@models/dashboard'
import { useAppDispatch } from '@store/index'
import { useWebSocket } from '@components/hooks/use-web-socket'
import { PaymentRegister } from '@models/payments'
import { addPaymentRegister } from '@store/slices/cash-slice'

interface Props {
  onEvent?: () => void
}
export const PaymentRegisterWebSocketHandler: React.FC<Props> = ({ onEvent }) => {
  const dispatch = useAppDispatch()

  useWebSocket<PaymentRegister>(
    (message, payload) => {
      switch (message) {
        case NotificationEvents.PAYMENT_REGISTER_CHANGE:
          dispatch(addPaymentRegister(payload))
          onEvent?.()

          break
      }
    },
    [],
    [NotificationEvents.PAYMENT_REGISTER_CHANGE],
  )
  return null
}
