import * as React from 'react'
import DateRangeInput from '@components/date/date-range-input'
import classnames from 'classnames'
import dateHelper, { dateWithShortTextMonth, formatDate, parseISODate } from '@helpers/date-helper'
import declination from '@helpers/declination'
import { ReceptionBookingDetails } from '@models/reception'
import { useFormContext, useWatch } from 'react-hook-form'

interface FormInputs {
  date_from: Date | null
  date_to: Date | null
}

interface Props {
  booking: ReceptionBookingDetails
  disabled: boolean
  calculateEveryDay: boolean
}

export const ReceptionBookingCheckInImprovementDateInput: React.FC<Props> = ({
  booking,
  disabled,
  calculateEveryDay,
}) => {
  const { control } = useFormContext<FormInputs>()

  const [date_from, date_to] = useWatch({
    control,
    name: ['date_from', 'date_to'],
  })

  const isEntireStay = booking.date_from === formatDate(date_from) && booking.date_to === formatDate(date_to)

  const [minDate, maxDate] = React.useMemo(
    () => [parseISODate(booking.date_from), parseISODate(booking.date_to)],
    [booking.date_from, booking.date_to],
  )

  const placeholder = () => {
    if (!isEntireStay) {
      return providePlaceholderWithDates()
    }

    const days = dateHelper.getEachDayFromRange(booking.date_from, booking.date_to).length - (calculateEveryDay ? 0 : 1)

    return (
      <div className="d-flex justify-content-center align-items-center line-g text-semi-strong align-baseline">
        Cały pobyt ({days} {declination.day(days)})
      </div>
    )
  }

  const providePlaceholderWithDates = () => {
    let element = `${formatDate(date_from, 'dd MMM')} - ${formatDate(date_to, dateWithShortTextMonth)}`

    const dateFrom = formatDate(date_from)
    const dateTo = formatDate(date_to)

    if (dateFrom === dateTo) element = formatDate(date_from, dateWithShortTextMonth)
    return (
      <div className="d-flex justify-content-between align-baseline">
        {element}
        <i className="ml-1 uil-angle-down font-18 d-flex" />
      </div>
    )
  }

  return (
    <DateRangeInput
      startDateName="date_from"
      endDateName="date_to"
      minDate={minDate}
      maxDate={maxDate}
      dateFormat="dd LLL"
      inputGroupClassName={classnames({ 'not-clickable': disabled })}
      iconWrapperClassName={classnames('reception__booking-check-in__improvement-step__date-range__icon', {
        'border-0': isEntireStay,
      })}
      wrapperClassName="reception__booking-check-in__improvement-step__date-range__picker"
      inputClassName={classnames('pl-0 text-semi-strong', { 'border-0': isEntireStay })}
      placeholderComponent={placeholder()}
    />
  )
}
