import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { BenefitProgramAgreementKind, BenefitProgramDetails } from '@modules/benefit-program/models'
import { useForm } from 'react-hook-form'
import { useCreateOrUpdateBenefitProgramMutation } from '@api/benefit-programs'
import { useHandleRtkQueryError } from '@components/hooks/use-handle-rtk-error'
import { Form } from '@hyper/forms/form'
import { Button, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { FormInput } from '@hyper/forms'
import { SaveButton } from '@hyper/button'
import { FormSelect } from '@hyper/forms/form-select'
import { benefitProgramAgreementKindsOptions } from '@modules/benefit-program/constants'
import { useNotificationHook } from '@hyper/use-notification-hook'

interface FormInputs {
  name: string
  phone: string
  email: string
  kind: BenefitProgramAgreementKind
}
interface Props extends BaseModalProps {
  benefitProgram: BenefitProgramDetails
}

export const BenefitProgramAgreementsCreateModal: React.FC<Props> = ({ benefitProgram, toggleIsVisible }) => {
  const methods = useForm<FormInputs>()
  const { addSuccessMessage } = useNotificationHook()

  const [createOrUpdateBenefitProgram, { error, isError, isLoading }] = useCreateOrUpdateBenefitProgramMutation()
  useHandleRtkQueryError(error, isError, methods.setError)

  const onSubmit = async (payload: FormInputs) => {
    await createOrUpdateBenefitProgram({
      data: payload,
      url: benefitProgram.urls.create_agreement,
      method: 'POST',
    }).unwrap()
    addSuccessMessage('Sukces', 'Umowa została dodana')

    toggleIsVisible()
  }

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Dodaj umowę</ModalHeader>
      <ModalBody>
        <Row>
          <FormInput name="name" label="Imię i nazwisko" colSize={12} />
          <FormInput name="email" label="Adres e-mail" colSize={6} />
          <FormInput name="phone" label="Telefon" colSize={6} />
          <FormSelect
            options={benefitProgramAgreementKindsOptions}
            name="kind"
            label="Rodzaj"
            formPlainProps={{ colSize: 6 }}
          />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
