import * as React from 'react'
import { SubscriptionDetails } from '@modules/subscription/models'
import { SubscriptionSingleModalBase } from '@modules/subscription/options/subscription-single-modal-base'
import { BaseModalProps } from '@components/modals/types'

interface Props extends BaseModalProps {
  subscriptionDetails: SubscriptionDetails
}

export const SubscriptionChangeDataModal: React.FC<Props> = ({ subscriptionDetails, toggleIsVisible }) => (
  <SubscriptionSingleModalBase
    title="Dodaj możliwość zmian danych w pakiecie"
    message="Zmiany danych została dodana!"
    amounts={[subscriptionDetails.product?.change_client_data_price_brutto || '0']}
    description="Opcja zmiany danych"
    toggleIsVisible={toggleIsVisible}
    subscriptionDetails={subscriptionDetails}
    url={subscriptionDetails.urls.subscription_client_data_change}
  />
)
