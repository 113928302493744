import * as React from 'react'
import { SubscriptionCode, SubscriptionDetails } from '@modules/subscription/models'
import Table from '@components/table/table'
import { parseISODate, toDefaultDateFormat } from '@helpers/date-helper'
import { SubscriptionDetailsCodesRowActions } from '@modules/subscription/details/code-rows-actions'
import { SubscriptionDetailsCodesRowCode } from '@modules/subscription/details/code-rows-code'
import { YesNoBadge } from '@components/badges/yes-no'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useSubscriptionTypeStrategyProvider } from '@modules/subscription/hooks/use-subscription-type-provider'

interface Props {
  subscriptionCode: SubscriptionCode
  index: number
  readOnly?: boolean
  children: React.ReactNode
  subscriptionDetails: SubscriptionDetails
}

export const SubscriptionDetailsCodesRow: React.FC<Props> = ({
  children,
  subscriptionCode,
  index,
  subscriptionDetails,
  readOnly = false,
}) => {
  const user = useAuthenticatedUser()
  const { crud_permissions } = useSubscriptionTypeStrategyProvider(subscriptionDetails.type)

  const createdYear = parseISODate(subscriptionDetails.created)?.getFullYear() || 0
  return (
    <Table.Row>
      {!subscriptionCode.is_used &&
      ['localization', 'default'].includes(subscriptionCode.kind) &&
      !subscriptionCode.is_splitted ? (
        children
      ) : (
        <Table.Cell />
      )}
      <Table.Cell style={{ width: 10 }}>
        <a href={subscriptionCode.urls.admin} target="_blank">
          {index}.
        </a>
      </Table.Cell>
      <SubscriptionDetailsCodesRowCode
        subscriptionDetails={subscriptionDetails}
        createdYear={createdYear}
        subscriptionCode={subscriptionCode}
      />
      <Table.Cell>{toDefaultDateFormat(subscriptionCode.expire_after)}</Table.Cell>
      <Table.Cell>
        {subscriptionCode.is_splitted ? (
          <>
            tak ({subscriptionCode.splitted_used} z {subscriptionCode.splitted_available})
          </>
        ) : (
          'nie'
        )}
      </Table.Cell>
      <Table.Cell>
        <YesNoBadge value={subscriptionCode.is_used} />
      </Table.Cell>
      <Table.Cell>{subscriptionCode.localization_kind_display}</Table.Cell>
      <Table.Cell>{subscriptionCode.kind_display}</Table.Cell>
      {readOnly ? (
        <Table.Cell />
      ) : (
        <SubscriptionDetailsCodesRowActions
          canUpdate={user.hasPerm(crud_permissions.update)}
          canRenew={user.hasPerm(crud_permissions.renew)}
          subscriptionCode={subscriptionCode}
        />
      )}
    </Table.Row>
  )
}
