import * as React from 'react'
import { Button, Col, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { Row } from 'reactstrap/lib'
import { BaseModalProps } from '@components/modals/types'
import { createProductCardClient, ProductClientCard } from '@components/products/product-client-card'
import { createInvoiceData, ProductInvoiceCard } from '@components/products/product-invoice-card'
import { useGetBookingOptionDetailsQuery } from '@api/booking-options'
import { BookingOption, BookingOptionDetails } from '@modules/promotions/booking-options/model'
import { TypedQueryResult } from '@api/base'
import { RootState, useAppSelector } from '@store/index'
import { BookingOptionDetailsBaseData } from '@modules/promotions/booking-options/details/booking-option-details-base-data'
import { BookingOptionDetailsTabs } from '@modules/promotions/booking-options/details/booking-option-details-tabs'
import { BookingOptionDetailsOptions } from '@modules/promotions/booking-options/details/booking-option-details-options'
import { ContentNotesCard } from '@components/content-notes/content-notes-card'
import { ProductEmailNotifications } from '@modules/promotions/common/notification/notifications'
import { HistoryBox } from '@modules/promotions/common/history-box'
import { BookingOptionDetailsDelete } from '@modules/promotions/booking-options/details/booking-option-details-delete'
import { useProductsAppData } from '@modules/products/hooks/use-products-app-data'
import { GenericProductPayments } from '@modules/generic-product-payments'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { useDetailsModalUnmountParamsClear } from '@components/hooks/use-details-modal'

interface Props extends BaseModalProps {
  option: BookingOption
}

export const BookingOptionDetailsDialog: React.FC<Props> = ({ toggleIsVisible, option }) => {
  useDetailsModalUnmountParamsClear(option.id)

  const { data: optionDetails, refetch } = useGetBookingOptionDetailsQuery(
    option?.urls.details,
  ) as TypedQueryResult<BookingOptionDetails>
  const { client_option_purchase_mails } = useProductsAppData()
  const user = useAuthenticatedUser()

  const contentType = useAppSelector((state: RootState) => state.appState.appData.content_types.clientoptionpurchase)

  const dataRef = React.useRef<HTMLDivElement>(null)
  const historyRef = React.useRef<HTMLDivElement>(null)
  const optionsRef = React.useRef<HTMLDivElement>(null)

  const canAddPayment = user.hasPerm(UserPermission.ClientOptionPurchaseCanAddPayment)

  return (
    <>
      <ModalHeader className="bg-light" toggle={toggleIsVisible}>
        Szczegóły opcji do rezerwacji
      </ModalHeader>
      <ModalBody role="modal-body" className="modal-details">
        <LoaderPlaceholder content={optionDetails}>
          {optionDetails && (
            <>
              <BookingOptionDetailsTabs dataRef={dataRef} historyRef={historyRef} optionsRef={optionsRef} />
              <Row>
                <Col md={6}>
                  <BookingOptionDetailsBaseData optionDetails={optionDetails} ref={dataRef} />
                </Col>
                <Col md={6}>
                  {optionDetails.client && (
                    <ProductClientCard
                      clientData={createProductCardClient(optionDetails.client)}
                      canViewClientDetails={true}
                    />
                  )}
                  <ProductInvoiceCard invoiceData={createInvoiceData(optionDetails)} />
                </Col>
                <Col md={12}>
                  <GenericProductPayments
                    onSuccess={refetch}
                    canDeletePayment={canAddPayment}
                    canAddPayment={canAddPayment && optionDetails.status === 'initial'}
                    addUrl={optionDetails.urls.payments}
                    payments={optionDetails.payments}
                    defaultAmount={optionDetails.price_brutto}
                  />
                </Col>

                <Col md={12}>
                  <BookingOptionDetailsOptions optionDetails={optionDetails} ref={optionsRef} />
                </Col>
                <Col md={6}>
                  <ProductEmailNotifications
                    onSuccess={refetch}
                    history={optionDetails.notifications}
                    notificationEmails={client_option_purchase_mails}
                    url={optionDetails.urls.notifications}
                    email={optionDetails.client?.email || ''}
                  />
                </Col>
                <Col md={6}>
                  <ContentNotesCard
                    readOnly={false}
                    objectId={optionDetails.id}
                    contentType={contentType}
                    onNotesUpdate={refetch}
                    notes={optionDetails.notes}
                  />
                  <HistoryBox history={optionDetails.history} ref={historyRef} />
                </Col>
              </Row>
            </>
          )}
        </LoaderPlaceholder>
      </ModalBody>
      <ModalFooter>
        {option.status === 'initial' && (
          <BookingOptionDetailsDelete option={option} toggleIsVisible={toggleIsVisible} />
        )}
        <Button className="ml-auto" color="light" onClick={toggleIsVisible}>
          Zamknij
        </Button>
      </ModalFooter>
    </>
  )
}
