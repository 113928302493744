import * as React from 'react'
import { formatDate } from '@helpers/date-helper'
import { FeedingClient } from '@modules/feeding/models'
import { FeedingClientsFiltersParams } from '@modules/feeding/clients/index'
import { formatPriceShort } from '@helpers/utils'
import { resortsMapSelector } from '@store/selectors/dashboard'
import Table, { TableHeaderValue } from '@components/table/table'
import { FeedingClientsTableFooter } from './table-footer'
import { FeedingClientsExportButtons } from '@modules/feeding/clients/export-buttons'
import { useAppSelector } from '@store/index'

interface Props {
  isLoading: boolean
  clients: FeedingClient[]
  setFilters: (filters: FeedingClientsFiltersParams) => void
  filters: FeedingClientsFiltersParams
}

const headerValues: TableHeaderValue<FeedingClient>[] = [
  {
    sortField: 'name',
    title: 'Imię i nazwisko',
  },
  {
    sortField: 'resort',
    title: 'Ośrodek',
  },
  {
    sortField: 'kind_display',
    title: 'Rodzaj',
  },

  {
    sortField: 'start_by_display',
    title: 'Pierwszy posiłek',
  },
  {
    sortField: 'date_from',
    title: 'Data',
  },
  {
    sortField: 'discount_display',
    title: 'Rabat',
  },
  {
    sortField: 'price_brutto',
    title: 'Cena brutto',
  },
  {
    sortField: 'payment_method',
    title: 'Metoda płatności',
  },
]

export const FeedingClientsTable: React.FC<Props> = ({ setFilters, clients, filters, isLoading }) => {
  const resortsMap = useAppSelector(resortsMapSelector)

  return (
    <>
      <Table
        className="table-hover w-100"
        headerValues={headerValues}
        isLoading={isLoading}
        filters={filters}
        setFilters={setFilters}
      >
        {clients.map(client => (
          <Table.Row key={client.id}>
            <Table.Cell>{client.name}</Table.Cell>
            <Table.Cell>{resortsMap[client.resort_id]?.name}</Table.Cell>
            <Table.Cell>{client.kind_display}</Table.Cell>
            <Table.Cell>{client.start_by_display}</Table.Cell>
            <Table.Cell>
              {formatDate(client.date_from, 'dd.MM')}-{formatDate(client.date_to, 'dd.MM.yyyy')}
            </Table.Cell>
            <Table.Cell>{client.discount_display}</Table.Cell>
            <Table.Cell>{formatPriceShort(client.price_brutto)}</Table.Cell>
            <Table.Cell>{client.payment_method_display}</Table.Cell>
          </Table.Row>
        ))}
        {!!clients.length && <FeedingClientsTableFooter clients={clients} />}
      </Table>
      <FeedingClientsExportButtons filters={filters} />
    </>
  )
}
