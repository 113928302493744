import * as React from 'react'
import { FeedingHostStats } from '@modules/feeding/models'
import { fetchFeedingHostStats } from '@api/feeding'
import { extractSelectOptionsValues } from '@helpers/utils'
import { useWatch } from 'react-hook-form'

interface Response {
  refresh: () => void
  stats: FeedingHostStats | undefined
}

export const useHostFeedingStats = (control): Response => {
  const [stats, setStats] = React.useState<FeedingHostStats | undefined>(undefined)

  const [date, type, resort, apartment, category] = useWatch({
    control,
    name: ['date', 'type', 'resort', 'apartment', 'category'],
  })

  const refreshStats = async () => {
    if (date && type && resort) {
      setStats(await fetchFeedingHostStats(extractSelectOptionsValues({ date, type, resort })))
    }
  }

  React.useEffect(() => {
    refreshStats()
  }, [date, type, resort, apartment, category])

  const refresh = () => {
    setStats(undefined)
    refreshStats()
  }

  return {
    stats,
    refresh,
  }
}
