import * as React from 'react'
import { useApiRequest } from '@components/hooks/use-api-request'
import { TopBarLoading } from '@components/topbar-loading'
import { commonObjectGet } from '@store/actions/generic-actions'
import { useModal } from '@components/modals/use-modal'
import { GastroCardDetails, GastroCardKind } from '@models/promotions'
import { useAppData } from '@components/hooks/use-app-data'
import { GastroProductKind } from '@models/products'
import { isGastroGenericProduct } from '@modules/promotions/gastro-cards/utils'
import { getGastroProductApiUrl } from '@modules/promotions/gastro-generic-product/utils'

interface Props {
  gastro_card_id: string
  kind: GastroCardKind
}

export const GastroCardDetailsView: React.FC<Props> = ({ gastro_card_id, kind }) => {
  const { urls } = useAppData()

  const url = isGastroGenericProduct(kind)
    ? getGastroProductApiUrl(kind as GastroProductKind, urls)
    : urls.promotions.gastro_cards

  const [handleDetails, hideModal] = useModal('GastroCardDetailsDialog', {}, { persist: true })

  const { isLoading, action: fetchGastroCard } = useApiRequest<GastroCardDetails>(
    async () => await commonObjectGet<GastroCardDetails>(`${url}${gastro_card_id}/`),
  )

  const showGastroCardDetails = async () => {
    const gastroCard = await fetchGastroCard()

    if (gastroCard) {
      handleDetails(null, { gastroCard }, true)
    }
  }

  React.useEffect(() => {
    showGastroCardDetails()
    return () => {
      hideModal()
    }
  }, [gastro_card_id])

  return isLoading ? <TopBarLoading /> : null
}
