import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { FeedingHosFormInputs } from '@modules/feeding/host/feeding-host-with-params-view'
import { fetchFeedingApartmentOptions } from '@api/feeding'
import { FeedingApartmentOption } from '@modules/feeding/models'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { useApiRequest } from '@components/hooks/use-api-request'
import { extractSelectOptionsValues } from '@helpers/utils'
import { FeedingHostApartmentInfoBox } from '@modules/feeding/host/apartment-info/box'
import { Alert } from 'reactstrap'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  onStatsRefresh: () => void
}

export const FeedingHostApartmentInfo = ({ onStatsRefresh }: Props) => {
  const [apartmentOptions, setApartmentOptions] = React.useState<FeedingApartmentOption[]>([])

  const { watch } = useFormContext<FeedingHosFormInputs>()

  const [apartment, date, type, resort, category] = watch(['apartment', 'date', 'type', 'resort', 'category'])

  const { isLoading, action: refreshFeedingOptions } = useApiRequest(async () => {
    if (apartment && date && type.value) {
      setApartmentOptions(
        await fetchFeedingApartmentOptions(
          extractSelectOptionsValues({ apartment, date, type, resort, booking_id: apartment.context.booking_id }),
        ),
      )
    }
  })

  React.useEffect(() => {
    refreshFeedingOptions()
  }, [apartment, date, type.value, resort])

  React.useEffect(() => {
    setApartmentOptions([])
  }, [resort, category, apartment])

  return (
    <LoaderPlaceholder content={!isLoading}>
      {apartmentOptions.map((option, index) => (
        <React.Fragment key={option.reservation_number}>
          {index > 0 && (
            <Alert className="p-2 alert-info mt-3">
              <IconWithText
                icon="uil-exclamation-circle font-18"
                text={
                  <span>
                    <strong className="pr-2">Uwaga!</strong>
                    Poniższa lista pochodzi <strong>z innej rezerwacji!</strong>
                  </span>
                }
              />
            </Alert>
          )}

          <FeedingHostApartmentInfoBox
            onRefresh={refreshFeedingOptions}
            onStatsRefresh={onStatsRefresh}
            apartmentOption={option}
          />
        </React.Fragment>
      ))}
    </LoaderPlaceholder>
  )
}
