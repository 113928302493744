import * as React from 'react'
import { Col, Row } from 'reactstrap'
import { CustomReactSelect } from '@components/custom-react-select'
import { FormInput } from '@hyper/forms'
import { IconWithText } from '@components/icon-with-text'
import { useFormContext, useWatch } from 'react-hook-form'
import { useHousekeepingAppData } from '@modules/housekeeping/hooks/use-housekeeping-app-data'
import { IssueOrderDetails } from '@modules/housekeeping/applications/models'
import { apartmentsSelectSelector } from '@store/selectors/timeline'
import { ApplicationImageUploader } from '@modules/housekeeping/applications/images-uploader'
import { HousekeepingApplicationIssueCreateModalFormInputs } from '@modules/housekeeping/applications/issue/create-modal/index'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectGet } from '@store/actions/generic-actions'
import { createSelectOption } from '@helpers/utils'
import { useAppData } from '@components/hooks/use-app-data'
import { createFilter } from 'react-select'
import { useAppSelector } from '@store/index'
import { PaginationResponse } from '@models/dashboard'
import { BookingAutocompleteResponse } from '@components/booking-autosuggest'

interface Props {
  resortId: number
  issueOrder?: IssueOrderDetails
}

export const HousekeepingApplicationIssueCreateModalForm: React.FC<Props> = ({ resortId, issueOrder }) => {
  const [reservations, setReservations] = React.useState<BookingAutocompleteResponse[]>([])

  const availableApartments = useAppSelector(apartmentsSelectSelector)
  const appData = useAppData()
  const housekeepingAppData = useHousekeepingAppData(resortId)

  const { setValue, control } = useFormContext<HousekeepingApplicationIssueCreateModalFormInputs>()

  const selectedApartment = useWatch({ control, name: 'apartment' })

  const apartmentOptions = React.useMemo(
    () => [{ value: null, label: 'Cześć wspólna' }, ...availableApartments],
    [availableApartments],
  )

  const reservationOptions = React.useMemo(
    () =>
      reservations.map(reservation =>
        createSelectOption(
          `${reservation.reservation_number} ${reservation.name} ${reservation.checked_in ? '(Zameldowana)' : ''}`,
          reservation.id,
        ),
      ),
    [reservations],
  )

  const { action: fetchReservations } = useApiRequest(async (apartmentId: string) => {
    const { results } = await commonObjectGet<PaginationResponse<BookingAutocompleteResponse>>(
      appData.urls.housekeeping.issue_order_bookings,
      {
        apartment: apartmentId,
      },
    )
    setReservations(results)
  })

  React.useEffect(() => {
    if (!issueOrder) return

    const apartment = issueOrder.apartment
      ? apartmentOptions.find(row => parseInt(row.value || '0', 10) === issueOrder.apartment?.id)
      : apartmentOptions[0]

    setValue('apartment', apartment)
  }, [issueOrder, apartmentOptions])

  React.useEffect(() => {
    if (!issueOrder) return

    const booking = issueOrder.reservation_number
      ? reservations.find(row => row.reservation_number === issueOrder.reservation_number)
      : null

    setValue('booking', reservationOptions.find(option => option.value === booking?.id) ?? null)
  }, [issueOrder, reservationOptions])

  useDidUpdateEffect(() => {
    setValue('booking', null)

    if (selectedApartment?.value) {
      fetchReservations(selectedApartment.value)
    }
  }, [selectedApartment?.value])

  return (
    <>
      <Row>
        <Col md={12}>
          <CustomReactSelect
            isDisabled={!!issueOrder}
            fieldName="apartment"
            label="Lokal"
            options={apartmentOptions}
            filterOption={createFilter({ stringify: option => option.label })}
            placeholder={<IconWithText icon="uil-search" text="Numer lokalu" />}
          />
        </Col>
        <Col md={12} className="my-2">
          <CustomReactSelect
            isDisabled={!!issueOrder || !selectedApartment?.value}
            fieldName="booking"
            label="Numer rezerwacji"
            options={reservationOptions}
            placeholder={<IconWithText icon="uil-search" text="Numer rezerwacji" />}
            noOptionsMessage={() => 'Brak rezerwacji dla podanego lokalu'}
          />
        </Col>
        <FormInput name="name" label="Nazwa zgłoszenia" />
        {issueOrder?.id && (
          <FormInput name="status" label="Status" type="select">
            <option value="">wybierz</option>
            {housekeepingAppData.issue_order_status.map(([value, label]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </FormInput>
        )}
      </Row>
      <Row>
        <Col sm={12} className="p-0">
          <div className="hr hr-grey mb-2" />
        </Col>
      </Row>
      <Row>
        <FormInput name="description" type="textarea" label="Opis zgłoszenia" />
      </Row>
      <ApplicationImageUploader defaultImages={issueOrder?.images || []} />
    </>
  )
}
