import * as React from 'react'
import { useAppDispatch } from '@store/index'
import { Promocode } from '@models/promocode'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { deletePromocodes } from '@store/slices/promocodes-slice'

interface PromocodeCancelProps {
  promocode: Promocode
}

export const PromocodeCodeCancel: React.FC<PromocodeCancelProps> = ({ promocode }) => {
  const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()

  const { isLoading, action: handleAccept } = useApiRequest(async () => {
    await commonObjectDelete(promocode.urls.details)
    dispatch(deletePromocodes([promocode.id]))
    addSuccessMessage('Sukces', 'Kod został anulowany')
  })

  return (
    <CommonObjectConfirmAction
      title="Potwierdź skasowanie kodu"
      handleAccept={handleAccept}
      isLoading={isLoading}
      message="Czy na pewno chcesz skasować wybrany kod ? Operacja ta jest nieodwołalna"
    >
      <i className="uil-trash-alt cursor-pointer font-18 text-muted" title="Anuluj" />
    </CommonObjectConfirmAction>
  )
}
