import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { IconWithText } from '@components/icon-with-text'
import { ReservationDataRow } from '@modules/reservations/details/common/reservation-data-row'
import { useModal } from '@components/modals/use-modal'
import { isBookingEditable } from '@modules/reservations/details/utils'

interface Props {
  booking: ReceptionBookingDetails
}

export const ReservationDataTags = ({ booking }: Props): JSX.Element => {
  const [handleAddTags] = useModal('ReservationMarketingModal', { booking })

  const isEditEnabled = isBookingEditable(booking)

  return (
    <ReservationDataRow
      name={<IconWithText icon="uil-label-alt font-15 lh-0" text="Tagi:" />}
      className="border-bottom pb-2 mr-3"
    >
      <div className="d-flex justify-content-between">
        {booking.tags.length ? (
          <>
            <div>
              {booking.tags.map(tag => (
                <span className="badge badge-info mr-1" key={tag}>
                  {tag}
                </span>
              ))}
            </div>
            {isEditEnabled && (
              <i className="uil-pen font-15 text-secondary cursor-pointer lh-0" onClick={handleAddTags} />
            )}
          </>
        ) : (
          <>
            <span className="fw-semi-bold">brak</span>
            {isEditEnabled && (
              <i className="uil-plus font-16 text-secondary cursor-pointer lh-0" onClick={handleAddTags} />
            )}
          </>
        )}
      </div>
    </ReservationDataRow>
  )
}
