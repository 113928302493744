import * as React from 'react'
import { CafeteriaCode } from '@models/cafeteria'
import Table from '@components/table/table'
import { CopyToClipboard } from '@components/copy-to-clipboard'
import { YesNoBadge } from '@components/badges/yes-no'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { RootState, useAppSelector } from '@store/index'

interface Props {
  code: CafeteriaCode
  children: React.ReactNode
}

export const CafeteriaDetailsCodesRow = ({ code, children }: Props): JSX.Element => {
  const quickSearchUrl = useAppSelector((state: RootState) => state.appState.appData.urls.dashboard.quick_search_url)
  return (
    <Table.Row>
      {children}
      <Table.Cell className="vertical-align-middle">
        {code.code} <CopyToClipboard value={code.code} />
        {code.related_booking && (
          <a href={quickSearchUrl + '?query=' + code.related_booking} target="_blank" className="d-block font-11">
            {code.related_booking}
          </a>
        )}
      </Table.Cell>
      <Table.Cell className="vertical-align-middle">
        <YesNoBadge value={code.is_used} />
      </Table.Cell>
      <Table.Cell className="vertical-align-middle">
        <YesNoBadge value={code.is_active} />
      </Table.Cell>
      <Table.Cell className="vertical-align-middle">
        {code.bookings.map(row => (
          <a key={row} href={quickSearchUrl + '?query=' + row} target="_blank">
            {row}
          </a>
        ))}
      </Table.Cell>
      <Table.Cell className="vertical-align-middle">{toDefaultDateFormat(code.expire_after)}</Table.Cell>
    </Table.Row>
  )
}
