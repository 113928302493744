import * as React from 'react'
import { UserPermission } from '@models/dashboard'
import { Col, Row } from 'reactstrap'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useModal } from '@components/modals/use-modal'
import { ButtonWithIcon } from '@components/button-with-icon'
import { GastroCardKind } from '@models/promotions'
import { GastroFilters } from '@modules/promotions/gastro-cards/gastro-cards-table'

interface GastroCardsExportButtonsProps {
  filters: GastroFilters
  kind: GastroCardKind
}

export const GastroCardsExportButtons: React.FC<GastroCardsExportButtonsProps> = ({ filters, kind }) => {
  const user = useAuthenticatedUser()

  const [handleClick] = useModal('ReportsGeneratorGastroCard', { filters, kind })

  if (!user.hasPerm(UserPermission.PromotionsGastroCardCanExport)) {
    return null
  }

  return (
    <Row>
      <Col md={12} className="text-right">
        <ButtonWithIcon handleClick={handleClick} icon="uil-download-alt" color="secondary" text="Eksportuj" />
      </Col>
    </Row>
  )
}
