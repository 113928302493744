import * as React from 'react'
import { ClientDetails } from '@models/clients'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useAppDispatch } from '@store/index'
import { updateClientDetails } from '@store/actions/crm-actions'
import { commonObjectPatch } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { YesNoBadge } from '@components/badges/yes-no'
import { ClientDetailsModalBaseDataRow } from '@modules/crm/clients/details-modal/base-data/data-row'

interface Props {
  client: ClientDetails
}

export const ClientDetailsModalBaseDataBlackList: React.FC<Props> = ({ client }) => {
  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()

  const { isLoading, action: handleRemove } = useApiRequest(async () => {
    dispatch(
      updateClientDetails(
        await commonObjectPatch<ClientDetails>(client.profile.urls.details, {
          is_blacklisted: !client.profile.is_blacklisted,
        }),
      ),
    )
    addSuccessNotification('Zmiany zostały zapisane!')
  })

  return (
    <ClientDetailsModalBaseDataRow name="Czarna lista:">
      <CommonObjectConfirmAction
        title={client.profile.is_blacklisted ? 'Skasuj z czarnej listy' : 'Dodaj do czarnej listy'}
        isLoading={isLoading}
        handleAccept={handleRemove}
        message={
          client.profile.is_blacklisted
            ? `Czy na pewno chcesz skasować ${client.name} z czarnej listy?`
            : `Czy na pewno chcesz dodać ${client.name} do czarnej listy?`
        }
      >
        <YesNoBadge value={client.profile.is_blacklisted} className="cursor-pointer" />
      </CommonObjectConfirmAction>
    </ClientDetailsModalBaseDataRow>
  )
}
