import { GenericVoucherStatus } from '@modules/generic-vouchers/models'

export const getGenericVoucherStatusColor = (status: GenericVoucherStatus) => {
  switch (status) {
    case 'confirmed':
      return 'badge-success-lighten'
    case 'initial':
      return 'badge-info-lighten'
  }
}
