import * as React from 'react'
import { Button, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { SaveButton } from '@hyper/button'
import { useAppDispatch } from '@store/index'
import { FormProvider, useForm } from 'react-hook-form'
import { createReportTask } from '@store/actions/report-tasks-actions'
import { endOfMonth, startOfMonth, startOfToday } from 'date-fns'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { FormInputTime } from '@hyper/forms/form-input-time'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { BaseModalProps } from '@components/modals/types'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { FormSelect } from '@hyper/forms/form-select'
import { extractSelectOptionsValues } from '@helpers/utils'
import { useAppData } from '@components/hooks/use-app-data'
import { ContentLoader } from '@components/content-loader'
import { useCommonMetersData } from '@modules/reports/user-reports/reports-generator-common-meters/use-common-meters-data'

export interface CommonMetersReportFormInputs {
  location_ids: CustomReactSelectOption[]
  device_ids: CustomReactSelectOption[]
  start_date: Date
  end_date: Date
  start_hour: string | null
  end_hour: string | null
}

export const ReportsCommonMetersModal: React.FC<BaseModalProps> = ({ toggleIsVisible }) => {
  const { urls } = useAppData()
  const dispatch = useAppDispatch()

  const methods = useForm<CommonMetersReportFormInputs>({
    defaultValues: {
      location_ids: [],
      device_ids: [],
      start_date: startOfMonth(startOfToday()),
      end_date: endOfMonth(startOfToday()),
      start_hour: null,
      end_hour: null,
    },
  })

  const {
    locationOptions,
    deviceOptions,
    selectedLocationsIds,
    selectedDevicesIds,
    isFetchingResorts,
    isFetchingDevices,
  } = useCommonMetersData(methods)

  const { isLoading, action: handleSubmit } = useReportFormRequest(async () => {
    methods.clearErrors()
    const payload = {
      ...extractSelectOptionsValues<CommonMetersReportFormInputs, {}>(methods.getValues()),
      location_ids: selectedLocationsIds,
      device_ids: selectedDevicesIds,
    }

    const reportTask = await dispatch(createReportTask([urls.reports.report_task_common_meters_report, payload]))
    toggleIsVisible()
    return reportTask
  }, methods.setError)

  return (
    <FormProvider {...methods}>
      <ContentLoader isLoading={isFetchingResorts}>
        <ModalHeader toggle={toggleIsVisible}>Raport liczników części wspólnych</ModalHeader>
        <ModalBody>
          <Row>
            <FormSelect
              options={locationOptions}
              name="location_ids"
              selectProps={{ isSelectMulti: true }}
              label="Ośrodki:"
            />
            <FormSelect
              options={deviceOptions}
              name="device_ids"
              selectProps={{
                isSelectMulti: true,
                isDisabled: isFetchingResorts || isFetchingDevices,
              }}
              label="Obszary:"
            />
            <DateFromToInput label="Przedział dat" startDateName="start_date" endDateName="end_date" />
            <FormInputTime name="start_hour" label="Godzina początkowa:" />
            <FormInputTime name="end_hour" label="Godzina końcowa:" />
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="light" type="button" onClick={toggleIsVisible}>
            Zamknij
          </Button>
          <SaveButton
            className="btn btn-green"
            label="Zleć raport"
            labelSaving="Zlecanie..."
            isSaving={isLoading}
            onClick={handleSubmit}
          />
        </ModalFooter>
      </ContentLoader>
    </FormProvider>
  )
}
