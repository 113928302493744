import * as React from 'react'
import { ReservationCard } from '@modules/reservations/details/common/reservation-card'
import { ReceptionBookingDetails } from '@models/reception'

interface Props {
  bookingDetails: ReceptionBookingDetails
}

export const ReceptionBookingCheckInPreviewSpecialRequests = ({ bookingDetails }: Props): JSX.Element => (
  <ReservationCard title="Prośby specjalne" cardClassName="mb-2">
    <p>{bookingDetails.requests || 'Brak'}</p>
  </ReservationCard>
)
