import * as React from 'react'
import { SubscriptionDetails } from '@modules/subscription/models'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useApiRequest } from '@components/hooks/use-api-request'
import { ContentNotesCard } from '@components/content-notes/content-notes-card'
import { setSubscriptionDetails } from '@store/slices/subscription-slice'
import { commonObjectGet } from '@store/actions/generic-actions'

interface Props {
  subscriptionDetails: SubscriptionDetails
}

export const SubscriptionDetailsNotes: React.FC<Props> = ({ subscriptionDetails }) => {
  const contentType = useAppSelector((state: RootState) => state.appState.appData.content_types.subscription)

  const dispatch = useAppDispatch()

  const { action: handleRefresh } = useApiRequest(async () =>
    dispatch(setSubscriptionDetails(await commonObjectGet<SubscriptionDetails>(subscriptionDetails.urls.details))),
  )

  return (
    <ContentNotesCard
      readOnly={subscriptionDetails.read_only}
      objectId={subscriptionDetails.id}
      contentType={contentType}
      onNotesUpdate={handleRefresh}
      notes={subscriptionDetails.notes}
    />
  )
}
