import * as React from 'react'
import { useApiRequest } from '@components/hooks/use-api-request'
import { TopBarLoading } from '@components/topbar-loading'
import { commonObjectGet } from '@store/actions/generic-actions'
import { RootState, useAppSelector } from '@store/index'
import { useModal } from '@components/modals/use-modal'
import { PackageWholesaleDetails } from '@modules/package-wholesale/models'

interface Props {
  package_wholesale_id: string
}

export const PackageWholesaleDetailsView: React.FC<Props> = ({ package_wholesale_id }) => {
  const packageWholesalesUrl = useAppSelector(
    (state: RootState) => state.appState.appData.urls.package_wholesale.package_wholesales,
  )
  const [handleDetails, hideModal] = useModal('PackageWholesaleDetailsModal')

  const { isLoading, action: fetch } = useApiRequest<PackageWholesaleDetails>(
    async () => await commonObjectGet<PackageWholesaleDetails>(`${packageWholesalesUrl}${package_wholesale_id}/`),
  )

  const showPackageWholesaleDetails = async () => {
    const packageWholesale = await fetch()

    if (packageWholesale) {
      handleDetails(
        null,
        {
          packageWholesale,
        },
        true,
      )
    }
  }

  React.useEffect(() => {
    showPackageWholesaleDetails()
    return () => {
      hideModal()
    }
  }, [package_wholesale_id])

  return isLoading ? <TopBarLoading /> : null
}
