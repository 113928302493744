import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { PaymentDayUserReport } from '@models/payments'
import { useForm } from 'react-hook-form'
import { Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { asDecimal } from '@helpers/utils'
import { SaveButton } from '@hyper/button'
import { useFormRequest } from '@components/hooks/use-api-request'
import { FormInput } from '@hyper/forms'
import { ButtonWithIcon } from '@components/button-with-icon'
import { useModal } from '@components/modals/use-modal'
import { useAppDispatch } from '@store/index'
import { updatePaymentDayUserReport } from '@store/actions/cash-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { CashCount } from '@modules/payment-registers/cash-count-modal'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { cashCountEmptyValues, useCashCount } from '@modules/payment-registers/cash-count-modal/use-cash-count'
import { Form } from '@hyper/forms/form'

interface Props extends BaseModalProps {
  report: PaymentDayUserReport
}

interface FormInputs {
  start_amount: number
}

export const PaymentRegisterReceptionCasketStartModal: React.FC<Props> = ({ report, toggleIsVisible }) => {
  const methods = useForm<FormInputs>({
    defaultValues: { start_amount: asDecimal(report.start_amount).toNumber() },
  })
  const [cashCountValues, setCashCountValues] = React.useState<CashCount>(cashCountEmptyValues)
  const { totalCashCount } = useCashCount(cashCountValues)

  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    await dispatch(updatePaymentDayUserReport([report, payload]))
    addSuccessNotification('Zmiany zostały zapisane')
    toggleIsVisible()
  }, methods.setError)

  const [handleCashCount] = useModal('CashCountModal', {
    setCashCountValues,
    cashCountValues,
  })

  useDidUpdateEffect(() => {
    methods.setValue('start_amount', Number(totalCashCount))
  }, [cashCountValues])

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Zmień stan początkowy kasetki</ModalHeader>
      <ModalBody>
        <Row>
          <FormInput
            label="Wpisz wartość"
            colSize={6}
            registerParams={{ valueAsNumber: true }}
            name="start_amount"
            step="0.01"
            type="number"
          />
          <Col md={6}>
            <label className="d-block">&nbsp;</label>
            <ButtonWithIcon
              icon="uil-money-withdraw"
              color="outline-secondary"
              text="Przelicz gotówkę"
              handleClick={handleCashCount}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <button type="button" onClick={toggleIsVisible} className="btn mr-1 btn-light">
          Anuluj
        </button>
        <SaveButton isSaving={isLoading} className="btn btn-green" />
      </ModalFooter>
    </Form>
  )
}
