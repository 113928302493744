import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import { FormProvider, useForm } from 'react-hook-form'
import { useApiRequest, useFormRequest } from '@components/hooks/use-api-request'
import { SaveButton } from '@hyper/button'
import { FormInput } from '@hyper/forms'
import { FormSelect } from '@hyper/forms/form-select'
import { CustomReactSelectOption, makeDefaultSelectOption } from '@components/custom-react-select'
import { useAppDispatch } from '@store/index'
import { useAppData } from '@components/hooks/use-app-data'
import { useRentalAppData } from '@modules/rental/use-rental-app-data'
import { getRentalCategoriesOptions, getRentalWarehousesOptions } from '@modules/rental/consts'
import { RentalProduct } from '@modules/rental/models'
import { createRentalProduct, removeRentalProduct, updateRentalProduct } from '@store/actions/rental-warehouse-actions'

interface Props extends BaseModalProps {
  product?: RentalProduct
}

export interface RentalProductFormInputs {
  name: string
  code: string
  category: CustomReactSelectOption | null
  warehouse?: CustomReactSelectOption | null
}

export const RentalProductCreateModal: React.FC<Props> = ({ toggleIsVisible, product }) => {
  const { urls } = useAppData()
  const { product_categories, warehouses } = useRentalAppData()

  const warehousesOptions = getRentalWarehousesOptions(warehouses)
  const categoriesOptions = getRentalCategoriesOptions(product_categories)

  const methods = useForm<RentalProductFormInputs>({
    mode: 'onChange',
    defaultValues: {
      name: product?.name ?? '',
      code: product?.code ?? '',
      category: makeDefaultSelectOption(categoriesOptions, product?.category),
      warehouse: makeDefaultSelectOption(warehousesOptions, product?.warehouse),
    },
  })
  const dispatch = useAppDispatch()

  const { action: handleSubmit, isLoading } = useFormRequest(async (payload: RentalProductFormInputs) => {
    await dispatch(
      product
        ? updateRentalProduct([product.urls.details, payload])
        : createRentalProduct([urls.rental.products, payload]),
    )
    toggleIsVisible()
  }, methods.setError)

  const { action: handleDelete, isLoading: isDeleting } = useApiRequest(async () => {
    if (!product) return
    await dispatch(removeRentalProduct(product))
  })

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <ModalHeader toggle={toggleIsVisible}>{product ? 'Edytuj produkt' : 'Dodaj produkt'}</ModalHeader>
        <ModalBody className="pb-0">
          <Row>
            <FormInput name="name" label="Nazwa" />
            <FormInput name="code" label="Kod produktowy" />
            <FormSelect options={categoriesOptions} name="category" label="Kategoria" formPlainProps={{ colSize: 6 }} />
            <FormSelect options={warehousesOptions} name="warehouse" label="Magazyn" formPlainProps={{ colSize: 6 }} />
          </Row>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          {product && (
            <SaveButton isSaving={isDeleting} className="btn btn-danger mr-1" label="Usuń" onClick={handleDelete} />
          )}
          <div className="ml-auto">
            <Button className="btn btn-light mr-2" type="button" onClick={toggleIsVisible}>
              Anuluj
            </Button>
            <SaveButton label="Zapisz" className="btn-green btn" isSaving={isLoading} />
          </div>
        </ModalFooter>
      </form>
    </FormProvider>
  )
}
