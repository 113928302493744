import * as React from 'react'
import { ShopPurchaseTicketSummarySingleRow } from '@modules/shop/pos/purchase-summary/tickets/shop-purchase-ticket-summary-single-row'
import { ShopTicket } from '@modules/shop/pos/models'
import { asDecimal, formatNumberWithThousandSeparator, formatPriceShort, sumDecimalArray } from '@helpers/utils'
import Decimal from 'decimal.js'

interface ShopPurchaseTicketsSummaryRowProps {
  tickets: ShopTicket[]
  ticketFactor: Decimal
  remove: (index: number) => void
}

export const ShopPurchaseTicketsSummaryRow: React.FC<ShopPurchaseTicketsSummaryRowProps> = ({
  remove,
  tickets,
  ticketFactor,
}) => {
  const summedTickets = sumDecimalArray(tickets.map((ticket: ShopTicket) => asDecimal(ticket.value)))
  const ticketValuesSum = formatNumberWithThousandSeparator(summedTickets.toString())
  const ticketPrice = formatPriceShort(summedTickets.mul(ticketFactor).toString())

  const addedTickets = tickets.map((ticket: ShopTicket, index: number) => (
    <ShopPurchaseTicketSummarySingleRow
      handleRemoveTicket={() => remove(index)}
      key={ticket.id}
      ticket={ticket}
      ticketFactor={ticketFactor}
    />
  ))

  return (
    <tr>
      <td colSpan={2} />
      <td colSpan={3} className="p-0">
        <table className="table w-100 mb-0">
          <tbody>
            {addedTickets}
            <tr className="border bg-white-smoke font-weight-bold font-12">
              <td>
                <span>Wartość ticketów razem:</span>
              </td>
              <td colSpan={3}>{`${ticketValuesSum} (-${ticketPrice})`}</td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  )
}
