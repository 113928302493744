import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { ClientCouponItem } from '@modules/coupons/models'
import { BaseModalProps } from '@components/modals/types'
import { CouponItemDetailsBaseDataCard } from '@modules/coupons/list/client-details/modal/details/coupon-item-details-base-data-card'
import { CouponItemDetailsTabs } from '@modules/coupons/list/client-details/modal/details/tabs'

// TODO is this details modal required? Same data is displayed in the table!

interface Props extends BaseModalProps {
  coupon: ClientCouponItem
}

export const CouponItemDetailsModal: React.FC<Props> = ({ coupon, toggleIsVisible }) => {
  const dataRef = React.useRef<HTMLDivElement>(null)

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>Kupon {coupon.code}</ModalHeader>
      <ModalBody role="modal-body" className="modal-details">
        <CouponItemDetailsTabs dataRef={dataRef} />
        <CouponItemDetailsBaseDataCard coupon={coupon} />
      </ModalBody>
      <ModalFooter className="d-block">
        <Row>
          <Button color="light" onClick={toggleIsVisible}>
            Zamknij
          </Button>
        </Row>
      </ModalFooter>
    </>
  )
}
