import * as React from 'react'
import { BookingExtendedStayDaysOption, BookingExtendedStayKind } from '@models/reception'
import { Col, Row } from 'reactstrap'
import { asDecimal, formatPriceShort } from '@helpers/utils'
import { useFormContext, useWatch } from 'react-hook-form'
import { ExtendedStayFormInputs } from '@modules/reception/extended-stay/reception-booking-extended-stay-dialog'

interface ReceptionBookingExtendedStayOptionProps {
  option: BookingExtendedStayDaysOption
}

export const ReceptionBookingExtendedStayOptionDaysPriceDetails: React.FC<ReceptionBookingExtendedStayOptionProps> = ({
  option,
}) => {
  const { control } = useFormContext<ExtendedStayFormInputs>()
  const [selectedKind, withCustomStayPrice] = useWatch({ control, name: ['kind', 'with_custom_stay_price'] })

  const isCustomDayEntered = selectedKind === BookingExtendedStayKind.DAYS && withCustomStayPrice

  const rows = {
    apartment_price: isCustomDayEntered
      ? 'Pobyt'
      : `Pobyt z rabatem (${Number(option.price_details.apartment_discount).toFixed(0)}%)`,
    climatic_tax: 'Opłata klimatyczna',
    improvements_price: 'Ulepszenia',
    service_charge: 'Opłata eksploatacyjna',
  }

  const optionWithPrices = Object.entries(rows).filter(([field]) => asDecimal(option.price_details[field]).gt(0))
  console.log(optionWithPrices)

  return (
    <>
      {optionWithPrices.map(([field, name]) => (
        <Row className="align-items-center" key={field} data-testid="daily-extension-price-row">
          <Col md={10} className="text-right pr-0">
            <span className="font-10 font-weight-semibold">{name}</span>
          </Col>
          <Col key={field} md={2} className="text-right font-10 pl-0">
            <strong className="text-danger">{formatPriceShort(option.price_details[field])}</strong>
          </Col>
        </Row>
      ))}
    </>
  )
}
