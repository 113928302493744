import * as React from 'react'

interface Props {
  colSpan?: number
}

export const HousekeepingNoOrdersRow = ({ colSpan }: Props) => (
  <tr>
    <td colSpan={colSpan} className="text-center">
      Brak zleceń
    </td>
  </tr>
)
