import * as React from 'react'
import * as R from 'ramda'
import { BaseModalProps } from '@components/modals/types'
import { AgreementDetails, AgreementDocumentKey } from '@modules/hr/agreement/models'
import { Form } from '@hyper/forms/form'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useFormRequest } from '@components/hooks/use-api-request'
import { commonObjectPut } from '@store/actions/generic-actions'
import { createSelectOption, extractSelectOptionsValues } from '@helpers/utils'
import { updateAgreementDetails } from '@store/slices/hr-slice'
import { AgreementFormInputs } from '@modules/hr/agreement/create/modal'
import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '@store/index'
import { HrWorkerForm } from '@modules/hr/common/form/worker-data'
import { HrAddressForm } from '@modules/hr/common/form/addresses'
import { HrHealthcareAndFinanceForm } from '@modules/hr/common/form/hr-health-care-and-finance-data'
import { AgreementCreateFormJobData } from '@modules/hr/agreement/create/steps/job-data/job-data'
import { AgreementCreateFormStatements } from '@modules/hr/agreement/create/steps/statements'
import { CustomReactSelectOption, makeDefaultSelectOption } from '@components/custom-react-select'
import { useCountries } from '@components/hooks/use-countries'
import { parseISODate } from '@helpers/date-helper'
import { AGREEMENT_WIZARD_STEP_VALIDATE_URLS } from '@modules/hr/consts'
import { UserPermission } from '@models/dashboard'
import { resortOptionsSelector } from '@store/selectors/dashboard'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useHrAppData } from '@modules/hr/use-hr-app-data'
import { agreementCreateSteps } from '@modules/hr/agreement/create/steps/steps'
import { AgreementFormAlerts } from '@modules/hr/agreement/agreement-form-alerts'

interface Props extends BaseModalProps {
  agreementDetails: AgreementDetails
  step: number
  chunk: AgreementDocumentKey
}

export const AgreementEditModal: React.FC<Props> = ({ toggleIsVisible, agreementDetails, step, chunk }) => {
  const dispatch = useAppDispatch()

  const fields = agreementCreateSteps.find(row => row.step === step)?.fields || []

  const { countriesOptions } = useCountries()

  const allResortOptions = useAppSelector(resortOptionsSelector)
  const user = useAuthenticatedUser()
  const hrAppData = useHrAppData()

  const companiesOptions = hrAppData.companies.map(el => createSelectOption(el.name, el.id))
  const jobPositionOptions = hrAppData.job_positions.map(el => createSelectOption(el.name, el.id))
  const agentOptions = hrAppData.agents.map(el => createSelectOption(el.full_name, el.id))

  const resortOptions = user.hasPerm(UserPermission.HrAgreementCanSelectAnyResort)
    ? allResortOptions
    : (user.resorts.map(userResort =>
        allResortOptions.find(resortOption => resortOption.value === userResort.id),
      ) as CustomReactSelectOption[])

  const methods = useForm<AgreementFormInputs>({
    defaultValues: {
      ...R.pick<Partial<AgreementFormInputs>, AgreementDetails>(fields, agreementDetails),
      ...(step === 1 && {
        nationality: makeDefaultSelectOption(countriesOptions, agreementDetails.nationality),
        birthday: parseISODate(agreementDetails.birthday),
      }),
      ...(step === 3 && {
        account_number: agreementDetails?.account_number || 'PL',
      }),
      ...(step === 4 && {
        company: makeDefaultSelectOption(companiesOptions, agreementDetails.company),
        job_position: makeDefaultSelectOption(jobPositionOptions, agreementDetails.job_position),
        agent: makeDefaultSelectOption(agentOptions, agreementDetails.agent),
        resort: resortOptions.find(option => agreementDetails.resorts.includes(option.value)),
        date_of_expiration: parseISODate(agreementDetails.date_of_expiration),
        date_of_start: parseISODate(agreementDetails.date_of_start),
      }),
      ...(step === 5 && {
        nationality: makeDefaultSelectOption(countriesOptions, agreementDetails.nationality),
      }),
    },
  })

  const { isLoading, action: onSubmit } = useFormRequest(
    async (payload: AgreementFormInputs) => {
      const { resort, ...data } = payload
      dispatch(
        updateAgreementDetails(
          await commonObjectPut<AgreementDetails>(agreementDetails.urls[AGREEMENT_WIZARD_STEP_VALIDATE_URLS[step]], {
            ...extractSelectOptionsValues(data),
            ...(fields.includes('resorts') && { resorts: resort ? [resort.value] : [] }),
            save_history: true,
          }),
        ),
      )
      toggleIsVisible()
    },
    methods.setError,
    { showGlobalError: true },
  )

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Edycja umowy {agreementDetails.number}</ModalHeader>
      <ModalBody>
        {step === 1 && <HrWorkerForm />}
        {step === 2 && <HrAddressForm addressData={agreementDetails} />}
        {step === 3 && <HrHealthcareAndFinanceForm data={agreementDetails} />}
        {step === 4 && <AgreementCreateFormJobData resortOptions={resortOptions} agreementDetails={agreementDetails} />}
        {step === 5 && <AgreementCreateFormStatements chunk={chunk} />}
        <AgreementFormAlerts />
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton role="submit" className="btn btn-green" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
