import * as React from 'react'

export function useWindowScroll(debounceTime: number) {
  let timeout
  const [scrollY, setScrollY] = React.useState(0)
  const [scrollX, setScrollX] = React.useState(0)

  React.useEffect(() => {
    const onScroll = () => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        setScrollX(window.pageXOffset)
        setScrollY(window.pageYOffset)
      }, debounceTime)
    }
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [window.scrollY, window.scrollX])

  return {
    scrollY,
    scrollX,
  }
}
