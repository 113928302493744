import * as React from 'react'
import { Button, Col, Row } from 'reactstrap'
import DateRangeInput from '@components/date/date-range-input'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useFormContext } from 'react-hook-form'
import { ColAuto } from '@hyper/col-auto'
import * as R from 'ramda'
import { useSellers } from '@components/hooks/use-grouped-sellers'
import { FormSelect } from '@hyper/forms/form-select'

interface FormInputs {
  sell_date_before: Date
  sell_date_after: Date
  seller: CustomReactSelectOption | null
}

interface Props {
  resortId: number
  defaultValues: FormInputs
}

export const ReceptionSellersFilter: React.FC<Props> = ({ defaultValues, resortId }) => {
  const [filterApplied, setFilterApplied] = React.useState(false)

  const sellers = useSellers()

  const sellerOptions = sellers
    .filter(seller => seller.resort_ids.includes(resortId) && seller.department === 'reception')
    .map(seller => ({
      label: seller.name,
      value: String(seller.id),
    }))

  const { watch, reset } = useFormContext<FormInputs>()

  const { sell_date_after, sell_date_before, seller } = watch()

  React.useEffect(() => {
    if (!R.equals(defaultValues, { sell_date_after, sell_date_before, seller })) {
      setFilterApplied(true)
      return
    }
    setFilterApplied(false)
  }, [sell_date_after, sell_date_before, seller])

  const handleReset = () => reset()

  return (
    <Row className="mb-3">
      <ColAuto>
        <label>Data:</label>
        <DateRangeInput
          inputGroupClassName="flex-nowrap"
          wrapperClassName="w-100"
          startDateName="sell_date_after"
          endDateName="sell_date_before"
        />
      </ColAuto>
      <FormSelect options={sellerOptions} name="seller" label="Recepcjonista:" formPlainProps={{ colSize: 2 }} />
      {filterApplied && (
        <Col className="d-flex align-items-end">
          <Button data-testid="button-clear" color="light" onClick={handleReset}>
            Wyczyść
          </Button>
        </Col>
      )}
    </Row>
  )
}
