import * as React from 'react'
import Table from '@components/table/table'
import { PackageEntranceTicket } from '@models/package'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { CopyToClipboard } from '@components/copy-to-clipboard'

interface Props {
  ticket: PackageEntranceTicket
  index: number
  withRelatedCodeField: boolean
}

export const PackageDetailsEntranceCodesRow: React.FC<Props> = ({ ticket, index, withRelatedCodeField }) => (
  <Table.Row>
    <Table.Cell className="align-middle">{index}.</Table.Cell>
    <Table.Cell className="align-middle">
      <code className="text-default">{ticket.code}</code>
      <CopyToClipboard className="uil-share-alt ml-1" value={ticket.code} />
    </Table.Cell>

    {withRelatedCodeField && (
      <Table.Cell className="align-middle">
        <code className="text-default">{ticket.related_code || 'brak'}</code>
        {ticket.related_code && <CopyToClipboard className="uil-share-alt ml-1" value={ticket.related_code} />}
      </Table.Cell>
    )}

    <Table.Cell className="align-middle">{ticket.status_display}</Table.Cell>
    <Table.Cell className="align-middle">
      {ticket.used_at ? toDefaultDateTimeFormat(ticket.used_at) : 'Nie wykorzystano'}
    </Table.Cell>
    <Table.Cell className="align-middle">{toDefaultDateTimeFormat(ticket.expire_after)}</Table.Cell>
    <Table.Cell className="align-middle text-right">
      <button type="button" className="btn btn-xs btn-green">
        <a href={ticket.urls.print} className="text-white" target="_blank">
          Drukuj bilet
        </a>
      </button>
    </Table.Cell>
  </Table.Row>
)
