import * as React from 'react'
import { ReceptionBooking } from '@models/reception'
import { useModal } from '@components/modals/use-modal'
import classNames from 'classnames'

interface ReceptionNotificationEmailIconProps {
  booking: ReceptionBooking
}

export const ReceptionNotificationEmailIcon: React.FC<ReceptionNotificationEmailIconProps> = ({ booking }) => {
  const [handleToggle] = useModal('ReceptionNotificationEmailDialog', {
    booking,
  })

  if (!booking.email) {
    return null
  }

  return (
    <i
      className={classNames('uil-envelope text-secondary font-16 cursor-pointer', {
        'not-clickable': !booking.email_notifications_enabled,
      })}
      title="Wyślij wiadomość e-mail"
      data-testid="booking-email-notification"
      onClick={handleToggle}
    />
  )
}
