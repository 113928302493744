import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import { UpSellBooking, UpSellBookingStatus } from '@models/upselling'
import { UpsellBookingsStatusChangeToReconnectBox } from '@modules/upselling/upsell-bookings/status-change/upsell-bookings-status-change-to-reconnect-box'
import { useForm } from 'react-hook-form'
import { startOfToday } from 'date-fns'
import { UpsellBookingsStatusChangeButton } from '@modules/upselling/upsell-bookings/status-change/upsell-bookings-status-change-button'
import { formatDate, getNextBusinessDay, parseISODate } from '@helpers/date-helper'
import { useUpdateUpSellBookingMutation } from '@api/upSellBooking'
import { SaveButton } from '@hyper/button'
import { Form } from '@hyper/forms/form'
import { useHandleRtkQueryError } from '@components/hooks/use-handle-rtk-error'

export interface UpsellBookingsStatusChangeFormInputs {
  contact_required_date: Date
  status: UpSellBookingStatus
}

interface Props extends BaseModalProps {
  initialFilters: any
  upsellBooking: UpSellBooking
}

export const UpsellBookingsStatusChangeModal: React.FC<Props> = ({ toggleIsVisible, upsellBooking }) => {
  const variant = Statuses[upsellBooking.status].color ?? 'text-dark'

  const availableStatuses = Object.entries(Statuses).reduce(
    (statuses, [status, data]) => (status !== upsellBooking.status ? [...statuses, { status, ...data }] : statuses),
    [],
  )

  const methods = useForm<UpsellBookingsStatusChangeFormInputs>({
    defaultValues: {
      contact_required_date: upsellBooking.contact_required_date
        ? (parseISODate(upsellBooking.contact_required_date) as Date)
        : getNextBusinessDay(startOfToday()),
      status: upsellBooking.status,
    },
  })

  const [updateUpSellBooking, { isLoading, error, isError }] = useUpdateUpSellBookingMutation()
  useHandleRtkQueryError(error, isError, methods.setError)

  const handleSubmit = async (payload: UpsellBookingsStatusChangeFormInputs) => {
    await updateUpSellBooking({
      url: upsellBooking.urls.details,
      data: {
        status: payload.status,
        ...(payload.status === 'contact_required_again' && {
          contact_required_date: formatDate(payload.contact_required_date),
        }),
      },
    }).unwrap()

    toggleIsVisible()
  }

  return (
    <Form methods={methods} onSubmit={handleSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Zmień status kontaktu {upsellBooking.client.name}</ModalHeader>
      <ModalBody>
        <div className="d-flex align-items-center flex-wrap">
          <div className="col-4 fw-semi-bold">Aktualny status:</div>
          <div className="col-8">
            {upsellBooking.status === 'contact_required_again' ? (
              <UpsellBookingsStatusChangeToReconnectBox />
            ) : (
              <UpsellBookingsStatusChangeButton
                status={upsellBooking.status}
                color={variant}
                title={upsellBooking.status_display}
              />
            )}
          </div>
        </div>
        <div className="col-12">
          <i className="d-block uil-arrow-circle-down font-24" />
        </div>
        <div className="d-flex">
          <div className="col-4 fw-semi-bold mt-2">Zmień na:</div>
          <div className="col-8 d-flex flex-column">
            {availableStatuses.map(el => (
              <React.Fragment key={el.status}>
                {el.status === 'contact_required_again' ? (
                  <UpsellBookingsStatusChangeToReconnectBox />
                ) : (
                  <UpsellBookingsStatusChangeButton
                    status={el.status as UpSellBookingStatus}
                    color={el.color}
                    title={el.title}
                  />
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="d-block">
        <Row className="d-flex justify-content-between">
          <Button onClick={toggleIsVisible} className="btn btn-light mr-2">
            Anuluj
          </Button>
          <SaveButton className="btn btn-green" isSaving={isLoading} />
        </Row>
      </ModalFooter>
    </Form>
  )
}

const Statuses: Record<UpSellBookingStatus, { color: string; title: string }> = {
  contact_required: { color: 'text-info', title: 'Wymaga kontaktu' },
  contact_required_again: { color: 'text-danger-light', title: 'Wymaga ponownego kontaktu' },
  sold: { color: 'text-purple', title: 'Sprzedany' },
  not_interested: { color: 'text-dark', title: 'Niezainteresowany' },
}
