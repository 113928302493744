import * as React from 'react'
import { ReceptionBookingDamage } from '@models/reception'
import { formatPriceShort } from '@helpers/utils'
import { StepBillDamagesSingleSummaryElementDelete } from '@modules/reception/checkout/step-bill/step-bill-damages-single-summary-element-delete'

interface Props {
  damage: ReceptionBookingDamage
}

export const StepBillDamagesSingleSummaryElement: React.FC<Props> = ({ damage }) => (
  <li>
    <div className="d-flex justify-content-between align-items-center">
      <span className="d-block">{damage.name}</span>
      <div className="text-danger text-semi-strong">
        <span className="mr-2">{formatPriceShort(damage.price_brutto)}</span>
        <StepBillDamagesSingleSummaryElementDelete damage={damage} />
      </div>
    </div>
    <div className="hr hr-grey my-1" />
  </li>
)
