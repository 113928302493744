import * as React from 'react'
import { FormSelect } from '@hyper/forms/form-select'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { createSelectOption } from '@helpers/utils'
import { useTechnicalOrdersAppData } from '@modules/technical-orders/hooks/use-technical-orders-app-data'
import { TableFilters } from '@components/table/table'

interface Props {
  setFilters: React.Dispatch<React.SetStateAction<TableFilters>>
}

export const TechnicalOrderTableAcceptedByHeader: React.FC<Props> = ({ setFilters }) => {
  const { accepted_by_users } = useTechnicalOrdersAppData()

  const unitKindsOptions: CustomReactSelectOption<string, null | number>[] = React.useMemo(() => {
    const results: CustomReactSelectOption<string, null | number>[] = accepted_by_users.map(row =>
      createSelectOption(`${row.first_name} ${row.last_name}`, row.id),
    )
    results.push(createSelectOption('Nie wymaga akceptacji', null))
    return results
  }, [accepted_by_users])

  const handleChange = React.useCallback((option: CustomReactSelectOption) => {
    setFilters(filters => ({
      ...filters,
      accepted_by: option?.value ?? undefined,
      accepted_by__isnull: option?.value === null ? true : undefined,
    }))
  }, [])

  return (
    <FormSelect
      options={unitKindsOptions}
      name="unit"
      selectProps={{ isClearable: true, placeholder: 'Zaakceptował', onChange: handleChange }}
      formPlainProps={{ colClassName: 'p-0 m-0', formGroupClassName: 'p-0 m-0 text-normal' }}
    />
  )
}
