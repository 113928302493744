import * as React from 'react'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApiRequest } from '@components/hooks/use-api-request'
import { setAppDataAndUser } from '@store/slices/app-slice'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useNavigate } from 'react-router-dom'
import { DashboardAppDataWithUser } from '@models/dashboard'

interface Props {
  acquire: string
  returnUrl: string
}

interface Response extends DashboardAppDataWithUser {
  redirect_to: string
}

export const AccountUsersListTableRowAcquire = ({ acquire, returnUrl }: Props): JSX.Element | null => {
  const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()
  const authenticatedUser = useAuthenticatedUser()
  const navigate = useNavigate()

  const { action: handleOnClick } = useApiRequest(async () => {
    const response = await commonObjectPost<Response>(acquire, { return_url: `/dashboard${returnUrl}` })
    dispatch(setAppDataAndUser(response))

    if (response.redirect_to.startsWith('/dashboard/')) {
      navigate(response.redirect_to.replace('/dashboard/', '/'))
    } else {
      document.location.href = response.redirect_to
    }

    addSuccessMessage('Sukces', 'Zalogowano jako użytkownik')
  })

  return authenticatedUser.is_superuser ? (
    <i title="Zaloguj się jako" className="uil-eye cursor-pointer text-muted font-18 mr-1" onClick={handleOnClick} />
  ) : null
}
