import { CustomReactSelectOption } from '@components/custom-react-select'
import DateRangeInput from '@components/date/date-range-input'
import { useFormRequest } from '@components/hooks/use-api-request'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { formatDate } from '@helpers/date-helper'
import { SaveButton } from '@hyper/button'
import { FormPlain } from '@hyper/forms'
import { commonObjectPost } from '@store/actions/generic-actions'
import { RootState, useAppSelector } from '@store/index'
import { startOfToday, subDays } from 'date-fns'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { Label, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { useNotificationHook } from '@hyper/use-notification-hook'

import { BaseModalProps } from '@components/modals/types'
import { FormSelect } from '@hyper/forms/form-select'
import { Form } from '@hyper/forms/form'

interface Props extends BaseModalProps {
  date_from?: Date
  date_to?: Date
}

interface FormInputs {
  resort: CustomReactSelectOption
  date_from: Date
  date_to: Date
}

export const CashRegisterReportModal: React.FC<Props> = ({ date_from, date_to, toggleIsVisible }) => {
  const resorts = useAuthenticatedUser().resorts.map(({ id, name }) => ({ value: id, label: name }))
  const today = startOfToday()
  const { addSuccessMessage } = useNotificationHook()

  const methods = useForm<FormInputs>({
    defaultValues: {
      date_from: date_from || subDays(today, 1),
      date_to: date_to || today,
    },
  })

  const url = useAppSelector((state: RootState) => state.appState.appData.urls.sale.cash_box_print)
  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    await commonObjectPost(url, {
      ...payload,
      date_from: formatDate(payload.date_from),
      date_to: formatDate(payload.date_to),
      resort: payload.resort?.value,
    })
    addSuccessMessage('Sukces', 'Raport został zlecony do generowania!')

    toggleIsVisible()
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Drukuj raport kasowy</ModalHeader>
      <ModalBody>
        <Row>
          <FormSelect options={resorts} name="resort" label="Kasa" />

          <FormPlain name="date_from">
            <Label>Zakres dat</Label>
            <DateRangeInput startDateName="date_from" endDateName="date_to" />
          </FormPlain>
        </Row>
      </ModalBody>
      <ModalFooter>
        <SaveButton isSaving={isLoading} label="Drukuj" labelSaving="Drukuj" />
      </ModalFooter>
    </Form>
  )
}
