import * as React from 'react'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { TechnicalOrder } from '@models/technical-orders'
import { UnitDetailsTechnicalOrdersTableRow } from '@modules/units/details/technical-orders/row'

const headerValues: TableHeaderValue[] = [
  { title: 'Numer zadania', sortField: 'number' },
  { title: 'Status', sortField: 'status' },
  { title: 'Typ', sortField: 'technical_order_type' },
  { title: 'Zgłaszający', sortField: 'created_by__name' },
  { title: 'Wykonawca', sortField: 'users' },
  { title: 'Data zakończenia', sortField: 'finished_at' },
]

interface Props {
  technicalOrders: TechnicalOrder[]
  filters: TableFilters
  setFilters: React.Dispatch<React.SetStateAction<TableFilters>>
}

export const UnitDetailsTechnicalOrdersTable: React.FC<Props> = ({ technicalOrders, filters, setFilters }) => (
  <Table
    className="table-hover w-100"
    headerValues={headerValues}
    isLoading={false}
    filters={filters}
    setFilters={setFilters}
  >
    {technicalOrders.map(technicalOrder => (
      <UnitDetailsTechnicalOrdersTableRow key={technicalOrder.id} technicalOrder={technicalOrder} />
    ))}
  </Table>
)
