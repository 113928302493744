import * as React from 'react'
import { useParams } from 'react-router-dom'
import { useDocumentTitle } from '@helpers/utils'
import { PageTitle } from '@hyper/page-title'
import { Card, CardBody } from 'reactstrap'
import { NavigationTabs, NavigationTabsButton } from '@components/navigation-tabs'
import { NavigationPath } from '@models/routes'
import { NotificationTemplatesSmsList } from '@modules/cms/notification-templates/sms'
import { NotificationTemplatesEmailList } from '@modules/cms/notification-templates/email'
import { useMenuActive } from '@components/hooks/use-menu-active'

export type NotificationTemplatesType = 'sms' | 'email'

interface Params {
  type: NotificationTemplatesType
}

export const NotificationTemplatesView: React.FC = () => {
  const { type: currentType } = useParams<'type'>() as Params

  const pageTitle = 'Szablony powiadomień'
  useDocumentTitle(pageTitle)
  useMenuActive(NavigationPath.NotificationTemplates)

  const buttons: NavigationTabsButton[] = [
    {
      title: 'SMS',
      isActive: currentType === 'sms',
      link: NavigationPath.NotificationTemplates.replace(':type', 'sms'),
    },
    {
      title: 'E-Mail',
      isActive: currentType === 'email',
      link: NavigationPath.NotificationTemplates.replace(':type', 'email'),
    },
  ]

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitle} />
      <Card>
        <CardBody>
          <NavigationTabs buttons={buttons} />
          {currentType === 'sms' && <NotificationTemplatesSmsList />}
          {currentType === 'email' && <NotificationTemplatesEmailList />}
        </CardBody>
      </Card>
    </>
  )
}
