import { PromotionsActionsTypes, PromotionsActionTypesKeys } from '@store/actions-types/promotions-actions-types'
import { ErliPromocode } from '@modules/promotions/erli-promocode/models'

export interface PromotionsAppData {
  status: 'unknown' | 'loading' | 'ready'
  package_vip_notifications: [string, string][]
  gastro_card_notifications: [string, string][]
  subscription_contract_notifications: [string, string][]
  promocode_tourist_voucher_notifications: [string, string][]
  booking_option_notifications: [string, string][]
}

export interface PromotionsState {
  appData: PromotionsAppData
  erliPromocodes: ErliPromocode[] | undefined
}

const initialState: PromotionsState = {
  appData: {
    subscription_contract_notifications: [],
    status: 'unknown',
    gastro_card_notifications: [],
    package_vip_notifications: [],
    booking_option_notifications: [],
    promocode_tourist_voucher_notifications: [],
  },
  erliPromocodes: undefined,
}

const promotionsState = (state: PromotionsState = initialState, action: PromotionsActionsTypes): PromotionsState => {
  switch (action.type) {
    case PromotionsActionTypesKeys.SET_PROMOTIONS_APP_DATA:
      return { ...state, appData: action.payload }
    case PromotionsActionTypesKeys.SET_ERLI_PROMOCODES:
      return { ...state, erliPromocodes: action.payload }
    default:
      return state
  }
}

export default promotionsState
