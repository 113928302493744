import * as React from 'react'
import { Card } from 'reactstrap'
import { PackageWholesaleDetails } from '@modules/package-wholesale/models'
import { useToggle } from '@components/hooks/use-toggle'
import { PackageWholesaleDetailsBaseInformationTable } from '@modules/package-wholesale/details/base/table'
import { PackageWholesaleDetailsBaseInformationForm } from '@modules/package-wholesale/details/base/form'

interface Props {
  packageWholesaleDetails: PackageWholesaleDetails
}

export const PackageWholesaleDetailsBaseInformation: React.FC<Props> = ({ packageWholesaleDetails }) => {
  const [isEditing, toggleEdit] = useToggle()

  return (
    <Card>
      {isEditing ? (
        <PackageWholesaleDetailsBaseInformationForm
          toggleEdit={toggleEdit}
          packageWholesaleDetails={packageWholesaleDetails}
        />
      ) : (
        <PackageWholesaleDetailsBaseInformationTable
          toggleEdit={toggleEdit}
          packageWholesaleDetails={packageWholesaleDetails}
        />
      )}
    </Card>
  )
}
