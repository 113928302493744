import * as React from 'react'
import { getHoursMinutesFromInterval, secondsToInterval } from '@helpers/date-helper'

interface Props {
  label: string
  amount: number
  time: number
}

export const HouseKeepingUserReportStatsTableRow = ({ label, amount, time }: Props): JSX.Element => (
  <tr className="mb-2">
    <td className="text-left text-semi-strong py-1" width="40%">
      {label}:
    </td>
    <td className="text-strong text-right  py-1" width="10%">
      {amount}
    </td>
    <td className="text-right text-semi-strong  py-1" width="25%">
      w czasie
    </td>
    <td className="text-strong text-right py-1" width="25%">
      <i className="uil-clock mr-1" /> {getHoursMinutesFromInterval(secondsToInterval(time))}
    </td>
  </tr>
)
