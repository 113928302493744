import * as React from 'react'
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledButtonDropdown } from 'reactstrap'
import { IconWithText } from '@components/icon-with-text'
import { useFormContext } from 'react-hook-form'

export type ReportFormat = 'xlsx' | 'pdf'

interface FormInputs {
  report_format: ReportFormat
}

interface Props {
  isLoading: boolean
  onSubmit: (params) => void
}

export const ReportGenerateFormatDropdownButton = ({ isLoading, onSubmit }: Props): JSX.Element => {
  const { getValues } = useFormContext<FormInputs>()

  const handleFormatChange = (format: ReportFormat) => () => {
    onSubmit({ ...getValues(), report_format: format })
  }

  return (
    <UncontrolledButtonDropdown group={false} className="shadow-none d-block">
      <Button className="shadow-none btn btn-green rounded-right-0">
        {isLoading ? (
          <span className="d-flex align-items-center">
            <Spinner className="mr-2" size="sm" />
            Generowanie...
          </span>
        ) : (
          <IconWithText icon="uil-print" text="Generuj raport XLSX" />
        )}
      </Button>
      {!isLoading && <DropdownToggle split={true} color="green" className="rounded-left-0 shadow-none pl-0" />}
      <DropdownMenu>
        <DropdownItem onClick={handleFormatChange('pdf')}>Generuj raport PDF</DropdownItem>
        <DropdownItem onClick={handleFormatChange('xlsx')}>Generuj raport XLSX</DropdownItem>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  )
}
