import * as React from 'react'
import { CafeteriaDetails } from '@models/cafeteria'
import { Card, CardBody } from 'reactstrap'
import { ProductCardTitle } from '@components/products/product-card-title'
import { SubscriptionDetailsInformationRow } from '@modules/subscription/details/information/row'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { formatPrice } from '@helpers/utils'
import { useCafeteriaAppData } from '@modules/cafeteria/hooks/use-cafeteria-app-data'
import { RootState, useAppSelector } from '@store/index'
import { StatusBadge } from '@modules/promotions/common/status-badge'

interface Props {
  cafeteria: CafeteriaDetails
}

export const CafeteriaDetailsInformationBase = React.forwardRef<HTMLElement, Props>(({ cafeteria }, ref) => {
  const { cafeteria_operators } = useCafeteriaAppData()
  const accommodationTypes = useAppSelector((state: RootState) => state.appState.appData.accommodation_types)
  const operator = React.useMemo(
    () => cafeteria_operators.find(row => row.id == cafeteria.operator)?.name || '',
    [cafeteria.operator, cafeteria_operators],
  )

  const accommodation = React.useMemo(
    () =>
      accommodationTypes
        .filter(row => cafeteria.accommodation_types.includes(row.id))
        .map(row => row.name)
        .join(', '),
    [accommodationTypes, cafeteria.accommodation_types],
  )

  return (
    <Card innerRef={ref}>
      <CardBody>
        <ProductCardTitle title="Szczegóły" isEditAllowed={false} adminUrl={cafeteria.urls.admin} />
        <table className="table table-sm mb-0">
          <tbody>
            <SubscriptionDetailsInformationRow name="Nazwa">{cafeteria.name}</SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="Status">
              <StatusBadge element={cafeteria} />
            </SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="Operator">{operator}</SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="Rodzaj">
              {cafeteria.kind_display}
            </SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="Kwota">
              {formatPrice(cafeteria.residence_brutto)}
            </SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="Data utworzenia">
              {toDefaultDateFormat(cafeteria.created)}
            </SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="Ilość dni">{cafeteria.max_days}</SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="Termin płatności kaucji">
              {cafeteria.deposit_payment_deadline_display}
            </SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="Lokalizacja">
              {cafeteria.localization_kind_display}
            </SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="Zakwaterowanie">{accommodation}</SubscriptionDetailsInformationRow>
          </tbody>
        </table>
      </CardBody>
    </Card>
  )
})
