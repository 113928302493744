import * as React from 'react'
import { CustomReactSelect } from '@components/custom-react-select'
import { Col, Label, Row } from 'reactstrap'
import { FormPlain } from '@hyper/forms'
import { useFormContext, useWatch } from 'react-hook-form'
import { AccommodationType } from '@models/booking'
import { createSelectOption } from '@helpers/utils'
import { AvailableApartment } from '@models/reservation-create'
import { ReservationBlockadeFormInputs } from '@modules/reservations/blockade/reservation-blockade-modal'

interface Props {
  onDelete: (index: number) => void
  isRemovable?: boolean
  index: number
  onMapClick?: () => void
  accommodationTypes: AccommodationType[]
  apartments: AvailableApartment[]
}

export const ReservationBlockadeLocalRow = ({
  onDelete,
  index,
  isRemovable = true,
  onMapClick,
  accommodationTypes,
  apartments,
}: Props) => {
  const methods = useFormContext<ReservationBlockadeFormInputs>()

  const filledRows = useWatch({ control: methods.control, name: 'selected_local_rows' })

  const accommodationTypeOptions = accommodationTypes.map((accommodationType: AccommodationType) =>
    createSelectOption(accommodationType.name, accommodationType.id),
  )

  const apartmentOptions = apartments.map((availableApartment: AvailableApartment) =>
    createSelectOption(availableApartment.display_name, availableApartment.id),
  )

  const shouldDisplayLabel = index < 1

  return (
    <Row>
      <FormPlain name="local_kind" colSize={6} formGroupClassName="mb-1" hideErrors={true}>
        {shouldDisplayLabel && <Label for={`selected_local_rows.${index}.accommodation`}>Typ lokalu:</Label>}
        <CustomReactSelect
          isDisabled={!!filledRows[index].local_number}
          fieldName={`selected_local_rows.${index}.accommodation`}
          options={accommodationTypeOptions}
          placeholder="wybierz"
          isSearchable={false}
        />
      </FormPlain>
      <FormPlain name="local_number" colSize={4} formGroupClassName="mb-1" hideErrors={true}>
        {shouldDisplayLabel && <Label for={`selected_local_rows.${index}.local_number`}>Numer lokalu:</Label>}
        <CustomReactSelect
          isDisabled={!apartments.length}
          fieldName={`selected_local_rows.${index}.local_number`}
          options={apartmentOptions}
          placeholder="wybierz"
        />
      </FormPlain>
      {isRemovable && (
        <i
          className="uil-trash-alt font-18 align-self-end mb-2 pb-1 opacity-75 cursor-pointer"
          onClick={() => onDelete(index)}
        />
      )}
      {!!onMapClick && !!apartments.length && (
        <i className="uil-map font-18 align-self-end mb-2 pb-1 ml-2 opacity-75 cursor-pointer" onClick={onMapClick} />
      )}

      {apartments.length === 0 && !!filledRows[index].accommodation && (
        <Col className="my-1" md={12}>
          Brak dostępnych lokali do zablokowania.
        </Col>
      )}
    </Row>
  )
}
