import * as React from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'
import { Link, NavLink as RRNavLink, useMatch } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { RcpUserDetail } from '@models/rcp'

interface TabsProps {
  user?: RcpUserDetail
}

export const Tabs: React.FC<TabsProps> = ({ user }) => {
  if (!user) {
    return null
  }

  const items = [{ to: NavigationPath.RcpUserEvents, label: 'Ewidencja czasu pracy' }]

  return (
    <Nav tabs={true} className="nav-bordered mb-3">
      {items.map(row => (
        <NavItem key={row.to}>
          <NavLink
            tag={RRNavLink}
            to={row.to.replace(':id', String(user.id))}
            // eslint-disable-next-line react-hooks/rules-of-hooks
            active={useMatch(row.to) !== null}
          >
            {row.label}
          </NavLink>
        </NavItem>
      ))}
      <NavItem className="ml-auto">
        <Link style={{ padding: '.28rem .8rem' }} to={NavigationPath.Rcp} className="btn btn-light btn-sm">
          Wróć do listy
        </Link>
      </NavItem>
    </Nav>
  )
}
