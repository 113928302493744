import * as React from 'react'
import { ButtonWithIcon } from '@components/button-with-icon'
import { CartPaymentMethod } from '@modules/reception/cart/models'

interface Props {
  onPaymentSelection: (method: CartPaymentMethod) => void
  isCashAvailable: boolean
  isCreditCardAvailable: boolean
}

export const ReceptionBookingCartPaymentBasic = ({
  onPaymentSelection,
  isCashAvailable,
  isCreditCardAvailable,
}: Props): JSX.Element => (
  <div className="px-3 bg-white-smoke border pt-3 pb-2">
    <strong className="font-16">Płatność standardowa:</strong>
    {isCashAvailable && (
      <ButtonWithIcon
        icon="uil-money-bill lh-initial font-14"
        text="Gotówka"
        color="primary"
        btnClass="d-block w-100 text-left mt-1 font-13"
        handleClick={() => onPaymentSelection('cash')}
      />
    )}
    {isCreditCardAvailable && (
      <ButtonWithIcon
        icon="uil-credit-card lh-initial font-14"
        text="Karta"
        color="primary"
        btnClass="d-block w-100 text-left mt-1 font-13"
        handleClick={() => onPaymentSelection('card')}
      />
    )}
  </div>
)
