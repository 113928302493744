import * as React from 'react'
import Table, { TableHeaderValue } from '@components/table/table'
import { AccountUsersListTableRow } from '@modules/account/users/list/table-row'
import { User } from '@models/account'
import { AccountUserListTableFilters } from '@modules/account/users/list/index'

const headerValues: TableHeaderValue<User>[] = [
  { title: 'Imię i nazwisko', sortField: 'last_name' },
  { title: 'E-mail', sortField: 'email' },
  { title: 'Numer telefonu', sortField: 'phone' },
  { title: 'Data dodania', sortField: 'date_joined' },
  { title: 'Ostatnie logowanie', sortField: 'last_login' },
  { title: 'Aktywny?' },
  { title: 'Logowanie PIN' },
  { title: '' },
]

interface Props {
  users: User[]
  isLoading: boolean
  filters: AccountUserListTableFilters
  setFilters: (filters: AccountUserListTableFilters) => void
}

export const AccountUsersListTable: React.FC<Props> = ({ isLoading, users, filters, setFilters }) => (
  <Table
    className="table-hover w-100"
    headerValues={headerValues}
    isLoading={isLoading}
    filters={filters}
    setFilters={setFilters}
  >
    {users.map(user => (
      <AccountUsersListTableRow key={user.id} user={user} />
    ))}
  </Table>
)
