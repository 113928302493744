import * as React from 'react'
import { TransferPackageDetails } from '@modules/accounting/refund/models'
import { DropdownItem } from 'reactstrap'
import TopBarProgress from 'react-topbar-progress-indicator'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useModal } from '@components/modals/use-modal'
import { useApiRequest } from '@components/hooks/use-api-request'
import { BaseObj } from '@models/base'
import { useAppData } from '@components/hooks/use-app-data'

interface Props {
  selectedRows: BaseObj[]
  name: 'bookings' | 'recommendation_gifts'
  clearSelectedRows: () => void
}

export const RefundBookingsTableActionsPackage: React.FC<Props> = ({ selectedRows, name, clearSelectedRows }) => {
  const { urls } = useAppData()

  const [toggleEditModal] = useModal(
    'TransferPackageEditModal',
    {},
    {
      persist: true,
    },
  )

  const { isLoading, action: handleGeneratePackage } = useApiRequest(async () => {
    clearSelectedRows()
    const transferPackage = await commonObjectPost<TransferPackageDetails>(urls.accounting.transfer_package_create, {
      [name]: selectedRows.map(row => row.id),
      kind: name,
    })
    toggleEditModal(null, { transferPackage })
  })

  return (
    <>
      {isLoading && TopBarProgress && <TopBarProgress />}

      <DropdownItem disabled={!selectedRows.length} onClick={handleGeneratePackage}>
        <i className="uil uil-truck mr-1 text-bold" />
        Dodaj do paczki ({selectedRows.length})
      </DropdownItem>
    </>
  )
}
