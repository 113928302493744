import * as React from 'react'
import { CleaningOrder } from '@modules/housekeeping/models'
import { HousekeepingCleaningOrderCleaningRow } from '@modules/housekeeping/cleaning-orders/cleaning/row'
import { HousekeepingOrderTableHeaderLocal } from '@modules/housekeeping/common/table-headers/table-header-local'
import { HousekeepingOrderTableHeaderKind } from '@modules/housekeeping/common/table-headers/table-header-kind'
import { HousekeepingOrderTableHeaderServiceKind } from '@modules/housekeeping/common/table-headers/table-header-service-kind'
import { HousekeepingOrderTableHeaderWorker } from '@modules/housekeeping/common/table-headers/table-header-worker'
import { TableViewType } from '@modules/technical-orders/common/table-view-switch'
import { HousekeepingCleaningOrderCleaningGroupedRow } from '@modules/housekeeping/cleaning-orders/cleaning/grouped-row'
import { HousekeepingNoOrdersRow } from '@modules/housekeeping/cleaning-orders/common/no-orders'

interface Props {
  cleaningOrders: CleaningOrder[]
  tableView: TableViewType
  resortId: number
}

export const HousekeepingCleaningOrdersCleaningTable: React.FC<Props> = ({ cleaningOrders, tableView, resortId }) => {
  const isListView = tableView === 'list'

  return (
    <table className="housekeeping__orders_table table table-striped">
      <thead>
        <tr>
          {!isListView && (
            <th className="border-top-0" style={{ width: 200 }}>
              Zespół
            </th>
          )}
          <HousekeepingOrderTableHeaderLocal />
          <HousekeepingOrderTableHeaderKind />
          <HousekeepingOrderTableHeaderServiceKind />
          <th className="border-top-0" style={{ width: 150 }}>
            Czas pracy
          </th>
          {isListView && <HousekeepingOrderTableHeaderWorker cleaningOrders={cleaningOrders} resortId={resortId} />}
          <th className="border-top-0">Zlecone przez</th>
          <th className="border-top-0" style={{ width: 500 }} />
        </tr>
      </thead>
      <tbody>
        {!cleaningOrders.length ? (
          <HousekeepingNoOrdersRow colSpan={8} />
        ) : isListView ? (
          <>
            {cleaningOrders.map(cleaningOrder => (
              <HousekeepingCleaningOrderCleaningRow
                cleaningOrder={cleaningOrder}
                key={cleaningOrder.id}
                tableView={tableView}
              />
            ))}
          </>
        ) : (
          <HousekeepingCleaningOrderCleaningGroupedRow cleaningOrders={cleaningOrders} />
        )}
      </tbody>
    </table>
  )
}
