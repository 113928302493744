import Table from '@components/table/table'
import * as React from 'react'
import { Seller } from '@models/crm'
import { useModal } from '@components/modals/use-modal'

interface SellerListRowProps {
  seller: Seller
}

export const SellerListRow: React.FC<SellerListRowProps> = ({ seller }) => {
  const [addSeller] = useModal('AddSellerModal', { seller })

  return (
    <Table.Row onClick={addSeller} className="cursor-pointer">
      <Table.Cell>{seller.name}</Table.Cell>
      <Table.Cell>{seller.gender_display}</Table.Cell>
      <Table.Cell>{seller.department_display}</Table.Cell>
      <Table.Cell>{seller.group}</Table.Cell>
      <Table.Cell>{seller.phone}</Table.Cell>
    </Table.Row>
  )
}
