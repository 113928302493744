import * as React from 'react'
import { useModal } from '@components/modals/use-modal'
import { Link } from 'react-router-dom'
import { NavigationPath } from '@models/routes'

export const CmsAssetCreateButton = (): JSX.Element => {
  const [handleClick] = useModal('CmsAssetModal')

  return (
    <div className="text-right mb-3">
      <Link to={NavigationPath.CmsAssetCategories} className="btn btn-light mr-2">
        Lista kategorii
      </Link>
      <button onClick={handleClick} className="btn btn-green">
        Dodaj asset
      </button>
    </div>
  )
}
