import * as React from 'react'
import { ClientDetails } from '@models/clients'
import { ClientDetailsModalClientProfilePersonalData } from '@modules/crm/clients/details-modal/client-profile/client-profile-personal-data'
import { Col } from 'reactstrap'
import { ClientDetailsModalClientProfileCompanyData } from '@modules/crm/clients/details-modal/client-profile/client-profile-company-data'
import { ClientDetailsModalClientProfileGuests } from '@modules/crm/clients/details-modal/client-profile/guests'

interface Props {
  client: ClientDetails
}

export const ClientDetailsModalClientProfile: React.FC<Props> = ({ client }) => (
  <Col md={6}>
    <ClientDetailsModalClientProfilePersonalData client={client} />
    <ClientDetailsModalClientProfileCompanyData client={client} />
    <ClientDetailsModalClientProfileGuests client={client} />
  </Col>
)
