import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { SaveButton } from '@hyper/button'
import { FeedingSpecialGuestDialogForm } from '@modules/feeding/special-guests/dialog/form'
import { FeedingSpecialGuest } from '@modules/feeding/models'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { commonObjectPost, commonObjectPut } from '@store/actions/generic-actions'
import { formatDate, parseISODate } from '@helpers/date-helper'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useFormRequest } from '@components/hooks/use-api-request'
import { BaseModalProps } from '@components/modals/types'
import { updateFeedingSpecialGuestDetails } from '@store/slices/feeding-slice'
import { Form } from '@hyper/forms/form'

interface FeedingSpecialGuestDialogProps extends BaseModalProps {
  specialGuest?: FeedingSpecialGuest
}

export interface FeedingSpecialGuestDialogFormInputs {
  name: string
  resorts: string[]
  date_to: Date | null
  is_active: boolean
}

export const FeedingSpecialGuestDialog: React.FC<FeedingSpecialGuestDialogProps> = ({
  toggleIsVisible,
  specialGuest,
}) => {
  const url = useAppSelector((state: RootState) => state.appState.appData.urls.feeding.special_guests_list)
  const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()

  const methods = useForm<FeedingSpecialGuestDialogFormInputs>({
    defaultValues: specialGuest
      ? {
          ...specialGuest,
          resorts: specialGuest.resorts.map(row => String(row)),
          date_to: parseISODate(specialGuest.date_to),
        }
      : undefined,
  })

  const { isLoading, action: onSubmit } = useFormRequest(async (params: FeedingSpecialGuestDialogFormInputs) => {
    const payload = { ...params, date_to: formatDate(params.date_to) }

    await dispatch(
      updateFeedingSpecialGuestDetails(
        specialGuest
          ? await commonObjectPut<FeedingSpecialGuest>(specialGuest.urls.details, payload)
          : await commonObjectPost<FeedingSpecialGuest>(url, payload),
      ),
    )
    addSuccessMessage('Sukces', `Wyżywienie dla ${payload.name} zapisane`)
    toggleIsVisible()
  }, methods.setError)

  return (
    <Form onSubmit={onSubmit} methods={methods}>
      <ModalHeader toggle={toggleIsVisible}>
        {specialGuest ? (
          <>
            <span className="text-semi-strong">Edytuj gościa</span> <strong>{specialGuest.name}</strong>{' '}
          </>
        ) : (
          'Dodaj gość specjalny'
        )}
      </ModalHeader>
      <ModalBody>
        <FeedingSpecialGuestDialogForm />
      </ModalBody>
      <ModalFooter style={{ borderTop: 0 }}>
        <Button color="light" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
