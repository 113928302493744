import { CashRegister, SaleAppData } from '@models/sale'
import { CashRegistersExtraData } from '@store/reducers/sale-reducers'

export enum SaleActionTypesKeys {
  SET_APP_DATA = 'SET_CASH_REGISTER_APP_DATA',
  SET_CASH_REGISTERS = 'SET_CASH_REGISTERS',
  SET_CASH_REGISTERS_DATA = 'SET_CASH_REGISTERS_EXTRA_DATA',
}

export interface SetSaleAppDataAction {
  type: SaleActionTypesKeys.SET_APP_DATA
  payload: SaleAppData
}

export interface SetCashRegistersAction {
  type: SaleActionTypesKeys.SET_CASH_REGISTERS
  payload: CashRegister[]
}

export interface SetCashRegistersExtraDataAction {
  type: SaleActionTypesKeys.SET_CASH_REGISTERS_DATA
  payload: CashRegistersExtraData
}

export type SaleActionTypes = SetSaleAppDataAction | SetCashRegistersAction | SetCashRegistersExtraDataAction
