import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectPost } from '@store/actions/generic-actions'
import { TopBarLoading } from '@components/topbar-loading'
import { useAppDispatch } from '@store/index'
import { setAppDataAndUser } from '@store/slices/app-slice'
import { useAppData } from '@components/hooks/use-app-data'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useNavigate } from 'react-router-dom'
import { DashboardAppDataWithUser } from '@models/dashboard'
import { NavigationPath } from '@models/routes'

interface Response extends DashboardAppDataWithUser {
  return_url: string
}
export const ProfileDropdownRelease = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const { urls } = useAppData()
  const { addSuccessMessage } = useNotificationHook()
  const navigate = useNavigate()

  const { isLoading, action: handleOnClick } = useApiRequest(async () => {
    const response = await commonObjectPost<Response>(urls.dashboard.hijack_release)
    dispatch(setAppDataAndUser(response))
    addSuccessMessage('Sukces', 'Użytkownik został zwolniony')

    const returnUrl = response.return_url || NavigationPath.AccountUsersList
    if (returnUrl.startsWith('/dashboard/')) {
      navigate(returnUrl.replace('/dashboard/', '/'))
    } else {
      document.location.href = returnUrl
    }
  })

  return (
    <>
      {isLoading && <TopBarLoading />}
      <div className="dropdown-item notify-item" onClick={handleOnClick}>
        <IconWithText icon="uil uil-exchange-alt cursor-pointer" text="Uwolnij" />
      </div>
    </>
  )
}
