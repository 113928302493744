import * as React from 'react'
import { ReceptionBookingDetailsImprovement } from '@models/booking'
import { ReceptionBookingDetails } from '@models/reception'
import { IconWithText } from '@components/icon-with-text'
import { useModal } from '@components/modals/use-modal'
import { useFormContext } from 'react-hook-form'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'

interface Props {
  booking: ReceptionBookingDetails
  bookingImprovement: ReceptionBookingDetailsImprovement
}

export const BookingImprovementsAddedRowSmartWatch = ({ bookingImprovement, booking }: Props): JSX.Element => {
  const user = useAuthenticatedUser()
  const isSmartwatchUUIDEditAllowed = user.hasPerm(UserPermission.BookingCanManageSmartWatchUUID)

  const smartWatchAssignment = React.useMemo(
    () => booking.smart_watches.find(row => row.booking_improvement_id === bookingImprovement.id),
    [booking.smart_watches, bookingImprovement],
  )
  const { getValues } = useFormContext()

  const [handleOnClick] = useModal('SmartWatchAddModal', {
    smartWatchAssignment,
    booking,
    bookingImprovement,
    getPayload: getValues,
  })

  return (
    <div className="mt-1 font-11">
      UID: <strong> {smartWatchAssignment?.uid || 'nie wpisany'}</strong>
      {isSmartwatchUUIDEditAllowed && (
        <IconWithText
          icon="uil-pen"
          wrapperClassNames="text-secondary text-semi-strong ml-1 cursor-pointer"
          onClick={handleOnClick}
          text={smartWatchAssignment ? 'zmień' : 'dodaj'}
        />
      )}
    </div>
  )
}
