import * as React from 'react'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useModal } from '@components/modals/use-modal'
import { useAppData } from '@components/hooks/use-app-data'
import { RootState, useAppSelector } from '@store/index'
import { Receipt } from '@models/shop'
import { useLocation } from 'react-router-dom'
import { NavigationPath } from '@models/routes'

export const useUnfinishedShopReceiptCheck = () => {
  const { receipt_unfinished } = useAppData()
  const user = useAuthenticatedUser()

  const location = useLocation()

  const [showSaleNotCompletedModal] = useModal('ShopReceiptNotFulfilledModal', {}, { persist: true })

  const storedReceipt = useAppSelector((state: RootState) => state.shopState.receipt)

  const filterReceipt = (receipt: Receipt | undefined | null): Receipt | null => {
    if (!receipt) return null
    return receipt.status === 'unfinished' && receipt.user === user.id ? receipt : null
  }

  React.useEffect(() => {
    const unfinished = filterReceipt(storedReceipt ?? receipt_unfinished)

    if (
      unfinished &&
      !location.pathname.includes(unfinished.token) &&
      location.pathname !== NavigationPath.ShopPOSWithParams.replace(':resort_id', unfinished.resort.toString())
    ) {
      showSaleNotCompletedModal(null, { receipt: unfinished }, true)
    }
  }, [receipt_unfinished, location.pathname])
}
