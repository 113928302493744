import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { Button, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { FormInput } from '@hyper/forms'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { FormProvider, useForm } from 'react-hook-form'
import { endOfMonth, startOfMonth, startOfToday } from 'date-fns'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { createReportTask } from '@store/actions/report-tasks-actions'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { HousekeepingUser } from '@models/reports'
import { useReportsAppData } from '@modules/reports/hooks/use-reports-app-data'
import { ReportFormat, ReportGenerateFormatDropdownButton } from '@components/report-generate-format-dropdown-button'
import { Form } from '@hyper/forms/form'
import { FormSelect } from '@hyper/forms/form-select'

interface FormInputs {
  finished_at_after: Date
  finished_at_before: Date
  user_id: CustomReactSelectOption | null
  resort: number | null
  report_format: ReportFormat
}

interface Props extends BaseModalProps {
  finished_at_after: Date
  finished_at_before: Date
  housekeepingUser: HousekeepingUser
  resort: number
}

export const ReportsHousekeepingUserModal: React.FC<Props> = ({
  toggleIsVisible,
  finished_at_after,
  finished_at_before,
  housekeepingUser,
  resort,
}) => {
  const reportUrl = useAppSelector(
    (state: RootState) => state.appState.appData.urls.reports.report_task_housekeeping_user_report,
  )

  const { housekeeping_users } = useReportsAppData()

  const dispatch = useAppDispatch()

  const user = useAuthenticatedUser()

  const methods = useForm<FormInputs>({
    defaultValues: {
      finished_at_after: finished_at_after || startOfMonth(startOfToday()),
      finished_at_before: finished_at_before || endOfMonth(startOfToday()),
      user_id: housekeepingUser ? { value: housekeepingUser.id, label: housekeepingUser.name } : null,
      resort: resort || null,
      report_format: 'xlsx',
    },
  })

  const { isLoading, action: onSubmit } = useReportFormRequest(async (payload: FormInputs) => {
    const reportTask = await dispatch(createReportTask([reportUrl, { ...payload, user_id: payload.user_id?.value }]))
    toggleIsVisible()
    return reportTask
  }, methods.setError)

  const users = React.useMemo(
    () => housekeeping_users.map((user: HousekeepingUser) => ({ value: user.id, label: user.name })),
    [housekeeping_users],
  )

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Housekeeping - Raport pracownika</ModalHeader>
      <ModalBody>
        <Row>
          <DateFromToInput
            isClearable={false}
            label="Data"
            startDateName="finished_at_after"
            endDateName="finished_at_before"
          />
          <FormInput colSize={6} name="resort" label="Ośrodek" type="select">
            <option value="">Wszystkie</option>
            {user.resorts.map(resort => (
              <option value={resort.id} key={resort.id}>
                {resort.name}
              </option>
            ))}
          </FormInput>
          <FormProvider {...methods}>
            <FormSelect label="Pracownik" options={users} formPlainProps={{ colSize: 6 }} name="user_id" />
          </FormProvider>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <ReportGenerateFormatDropdownButton isLoading={isLoading} onSubmit={onSubmit} />
      </ModalFooter>
    </Form>
  )
}
