import * as React from 'react'
import { PaymentDayUserReport } from '@models/payments'
import Table from '@components/table/table'
import { formatPrice } from '@helpers/utils'
import { useModal } from '@components/modals/use-modal'
import { PaymentRegistersFiltersParams } from '@modules/payment-registers/list/filters'
import { PaymentRegisterReceptionRowSingleWork } from '@modules/payment-registers/cashbox-day-details/payment-register-reception/row-single-work'
import { PaymentRegisterReceptionRowSingleStartAmount } from '@modules/payment-registers/cashbox-day-details/payment-register-reception/row-single-start-amount'
import { PaymentRegisterReceptionRowCashActions } from '@modules/payment-registers/cashbox-day-details/payment-register-reception/row-actions'
import { PaymentRegisterReceptionReportCell } from '@modules/payment-registers/cashbox-day-details/payment-register-reception/cell-report'
import { UncontrolledTooltip } from 'reactstrap'

interface Props {
  report: PaymentDayUserReport
  tableRowColor: string
}

export const PaymentRegisterReceptionRowSingle = ({ report, tableRowColor }: Props): JSX.Element => {
  const [handleOpenModal] = useModal('TransactionListModal', {
    title: `pracownik ${report.user_display}`,
    params: { payment_day_user_report: report.id } as Partial<PaymentRegistersFiltersParams>,
  })

  return (
    <Table.Row className={tableRowColor}>
      <Table.Cell>
        <PaymentRegisterReceptionRowSingleWork report={report} />
      </Table.Cell>
      <Table.Cell>
        {formatPrice(report.start_amount)}
        <PaymentRegisterReceptionRowSingleStartAmount report={report} />
      </Table.Cell>
      <Table.Cell>
        <strong id={`reception-revenue-${report.id}`}>{formatPrice(report.amount_sale)}</strong>
        <UncontrolledTooltip placement="bottom" target={`reception-revenue-${report.id}`}>
          <div className="font-11">
            Gotówka: <strong>{formatPrice(report.amount_sale_cash)}</strong> <br />
            Karta: <strong>{formatPrice(report.amount_sale_cards)}</strong>
          </div>
        </UncontrolledTooltip>
      </Table.Cell>
      <Table.Cell>{formatPrice(report.cashbox_amount || 0)}</Table.Cell>
      <Table.Cell>{report.status === 'open' && <PaymentRegisterReceptionRowCashActions report={report} />}</Table.Cell>
      <Table.Cell>{report.status === 'close' && formatPrice(report.safe_amount || 0)}</Table.Cell>
      <Table.Cell>{report.status === 'close' && formatPrice(report.cashbox_amount_after_day_end || 0)}</Table.Cell>
      <Table.Cell>
        <PaymentRegisterReceptionReportCell printUrl={report.urls.print} />
        <i className="uil uil-eye cursor-pointer font-16" onClick={handleOpenModal} title="Podgląd" />
      </Table.Cell>
    </Table.Row>
  )
}
