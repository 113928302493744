import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { PaymentUserOpenDayModalReceptionWorkstation } from '@modules/payment-registers/payment-user-open-day-modal/reception-workstation'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useAppData } from '@components/hooks/use-app-data'
import { useAppDispatch, useAppSelector } from '@store/index'
import { resortsMapSelector } from '@store/selectors/dashboard'
import { useForm } from 'react-hook-form'
import { useFormRequest } from '@components/hooks/use-api-request'
import { setAuthenticatedUser } from '@store/slices/app-slice'
import { commonObjectPut } from '@store/actions/generic-actions'
import { AuthenticatedUser } from '@models/dashboard'
import { SaveButton } from '@hyper/button'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { AccountUserDesktopModalBandReader } from '@modules/account/user-desktop-modal/band-reader'
import { AccountUserDesktopModalEservicePinPadReader } from '@modules/account/user-desktop-modal/eservice-pin-pad-reader'
import { AccountUserDesktopModalSignatureTablet } from '@modules/account/user-desktop-modal/signature-tablet'
import { useModal } from '@components/modals/use-modal'
import { Form } from '@hyper/forms/form'

interface FormInputs {
  reception_workstation: CustomReactSelectOption | undefined
}

export const AccountUserDesktopModal: React.FC<BaseModalProps> = ({ toggleIsVisible }) => {
  const user = useAuthenticatedUser()
  const appData = useAppData()
  const { reception_workstations } = appData
  const resortsMap = useAppSelector(resortsMapSelector)
  const dispatch = useAppDispatch()
  const { addSuccessNotification, addErrorMessage } = useNotificationHook()
  const [handlePaymentUserCloseDayModal] = useModal(
    'UserCashboxCloseDayModal',
    {},
    {
      persist: true,
    },
  )

  const receptionWorkstationsOptions: CustomReactSelectOption[] = reception_workstations
    .filter(row => user.resort_ids.includes(row.resort_id) && resortsMap[row.resort_id])
    .map(row => ({
      value: row.id,
      label: `${row.name} (${resortsMap[row.resort_id].name})`,
    }))

  const methods = useForm<FormInputs>({
    defaultValues: {
      reception_workstation: receptionWorkstationsOptions.find(row => row.value === user.receptionWorkstation?.id),
    },
  })

  const { isLoading, action: onSubmit } = useFormRequest(
    async (payload: FormInputs) => {
      if (payload.reception_workstation?.value === user.receptionWorkstation?.id) {
        await handleAction(payload)
      } else if (user.paymentDayUserReport && user.receptionWorkstation) {
        addErrorMessage(
          'Uwaga',
          `Przed zmianą stanowiska zamknij swoją obecną zmianę na ${user.receptionWorkstation.name}`,
          undefined,
          () => {
            toggleIsVisible()

            handlePaymentUserCloseDayModal(null, {
              paymentDayUserReport: user.paymentDayUserReport,
              allowClose: true,
            })
          },
          'Rozumiem',
        )
      } else {
        await handleAction(payload)
      }
    },

    methods.setError,
    { showGlobalError: true },
  )

  const handleAction = async (payload: FormInputs) => {
    dispatch(
      setAuthenticatedUser(
        await commonObjectPut<AuthenticatedUser>(appData.urls.account.details, {
          ...payload,
          reception_workstation: payload.reception_workstation?.value,
        }),
      ),
    )
    addSuccessNotification('Zmiany zostały zapisane')
    toggleIsVisible()
  }

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Konfiguracja stanowiska</ModalHeader>
      <ModalBody>
        <PaymentUserOpenDayModalReceptionWorkstation receptionWorkstationsOptions={receptionWorkstationsOptions} />
        <AccountUserDesktopModalBandReader />
        <AccountUserDesktopModalEservicePinPadReader />
        <AccountUserDesktopModalSignatureTablet />
      </ModalBody>
      <ModalFooter>
        <button type="button" onClick={toggleIsVisible} className="btn btn-light">
          Anuluj
        </button>
        <SaveButton isSaving={isLoading} className="btn btn-green" />
      </ModalFooter>
    </Form>
  )
}
