import * as React from 'react'
import { FormInput } from '@hyper/forms'
import { FormProvider, useForm } from 'react-hook-form'
import { StepBillDamagesSummary } from '@modules/reception/checkout/step-bill/step-bill-damages-summary'
import { Col, Row } from 'reactstrap'
import { ReceptionBookingDetails } from '@models/reception'
import { SaveButton } from '@hyper/button'
import { useAppDispatch } from '@store/index'
import { handleEnterPress } from '@helpers/utils'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useFormRequest } from '@components/hooks/use-api-request'
import { commonObjectPost } from '@store/actions/generic-actions'
import { setReceptionBookingDetails } from '@store/slices/reception-slice'

interface Props {
  booking: ReceptionBookingDetails
}

interface FormInputs {
  name: string
  price_brutto: string
}

export const ReceptionBookingCheckOutStepBillDamages: React.FC<Props> = ({ booking }) => {
  const methods = useForm<FormInputs>({
    defaultValues: {
      price_brutto: '0',
      name: '',
    },
  })

  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()

  const { isLoading, action: handleAdd } = useFormRequest(async (payload: FormInputs) => {
    dispatch(setReceptionBookingDetails(await commonObjectPost<ReceptionBookingDetails>(booking.urls.damages, payload)))
    addSuccessNotification('Szkoda została dodana!')
    methods.reset()
  }, methods.setError)

  const saveButtonLabel = (
    <>
      <i className="uil-plus mr-1" />
      Dodaj
    </>
  )

  return (
    <FormProvider {...methods}>
      <div className="bg-grey rounded px-3 py-2 border-gray">
        <strong className="d-block mb-3 font-14 text-dark">Uzupełnij straty podczas pobytu</strong>
        <FormInput
          label="Wpisz obciążenie"
          name="name"
          onKeyDown={handleEnterPress.bind(methods.handleSubmit(handleAdd))}
          colClassName="p-0"
        />
        <strong className="d-block mb-05">Wpisz kwotę obciążeniową</strong>
        <Row>
          <FormInput
            colSize={6}
            type="number"
            autoComplete="off"
            dataTestId="priceButton"
            step={0.01}
            name="price_brutto"
            onKeyDown={handleEnterPress.bind(methods.handleSubmit(handleAdd))}
          />

          <Col md={6} className="text-right">
            <SaveButton
              isSaving={isLoading}
              label={saveButtonLabel}
              labelSaving="Dodawanie..."
              onClick={methods.handleSubmit(handleAdd)}
              className="btn bg-success text-white d-block ml-auto"
            />
          </Col>
        </Row>
        <StepBillDamagesSummary damages={booking.damages} />
      </div>
    </FormProvider>
  )
}
