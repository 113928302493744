import { ReceptionBookingCheckStep } from '@modules/reception/common/reception-booking-check-steps'
import * as React from 'react'

export const agreementCreateSteps: ReceptionBookingCheckStep[] = [
  {
    step: 1,
    description: <>Podstawowe dane osobowe</>,
    fields: [
      'first_name',
      'second_name',
      'last_name',
      'email',
      'nationality',
      'tax_id',
      'phone',
      'birthday',
      'personal_id_number',
    ],
  },
  {
    step: 2,
    description: <>Adres zameldowania</>,
    fields: [
      'different_address',
      'registered_street',
      'registered_street_house',
      'registered_street_apartment',
      'registered_city',
      'registered_district',
      'registered_postcode',
      'registered_post',
      'registered_community',
      'registered_province',
      'street',
      'street_house',
      'street_apartment',
      'city',
      'district',
      'postcode',
      'post',
      'community',
      'province',
    ],
  },
  {
    step: 3,
    description: <>Fundusz zdrowia oraz dane finansowe</>,
    fields: ['national_health_fund_branch', 'account_number', 'bank_name', 'tax_office'],
  },
  {
    step: 4,
    description: <>Stanowisko, wybór spółki i stawki</>,
    fields: ['job_position', 'company', 'hourly_rate', 'resorts'],
  },
  {
    step: 5,
    description: <>Oświadczenia</>,
    fields: [
      'statement_under_employment_contract',
      'statement_under_employment_contract_name',
      'statement_health_insurance',
      'statement_non_agricultural',
      'statement_maternity_leave',
      'statement_maternity_leave_date_from',
      'statement_maternity_leave_date_to',
      'statement_student_status',
      'statement_retirement_pension',
      'statement_retirement_pension_number',
      'statement_retirement_pension_organization',
      'statement_disability',
      'statement_disability_level',
      'statement_optional_insurance',
      'statement_foreign',
      'statement_under_employment_lower_salary',
      'statement_over_employment_contract_second',
      'statement_over_employment_contract_second_salary',
    ],
  },
  {
    step: 6,
    description: 'Podsumowanie',
  },
]
