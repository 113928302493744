import * as React from 'react'
import { Button, Col, Row } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { ButtonWithIcon } from '@components/button-with-icon'

interface Props {
  resortId: number
}
export const PaymentRegisterUserCashboxTransactionsActions = ({ resortId }: Props): JSX.Element | null => {
  const user = useAuthenticatedUser()

  const [handlePaymentUserOpenDayModal] = useModal('PaymentUserOpenDayModal', {
    paymentDayUserReport: user.paymentDayUserReport,
  })
  const [handleCloseDay] = useModal('UserCashboxCloseDayModal', {
    allowClose: true,
    paymentDayUserReport: user.paymentDayUserReport,
  })

  const paymentDayUserReport = user.paymentDayUserReport
  const [handleCashCount] = useModal('CashCountModal', { resort: resortId })

  if (!paymentDayUserReport) {
    return null
  }

  return (
    <Row className="mb-4">
      <Col md={12} className="text-right">
        {paymentDayUserReport.status === 'not_started' && (
          <Button className="mr-2" color="green" onClick={handlePaymentUserOpenDayModal}>
            Otwórz dzień
          </Button>
        )}

        {paymentDayUserReport.status === 'open' && (
          <Button className="mr-2" color="danger" onClick={handleCloseDay}>
            Zamknij dzień
          </Button>
        )}
        <ButtonWithIcon
          icon="uil-money-withdraw"
          color="outline-secondary"
          text="Przelicz gotówkę"
          handleClick={handleCashCount}
        />
      </Col>
    </Row>
  )
}
