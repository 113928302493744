import { TypedQueryResult } from '@api/base'
import { BenefitProgramAppData } from '@modules/benefit-program/models'
import { useGetBenefitProgramAppDataQuery } from '@api/benefit-programs'

const emptyAppData: BenefitProgramAppData = {
  benefit_program_advisors: [],
}

export const useBenefitProgramAppData = (): BenefitProgramAppData => {
  const { data: appData = emptyAppData } = useGetBenefitProgramAppDataQuery() as TypedQueryResult<BenefitProgramAppData>

  return appData
}
