import * as React from 'react'
import { ModalBody } from 'reactstrap'
import { formatPriceShort } from '@helpers/utils'
import classnames from 'classnames'
import { useFormContext } from 'react-hook-form'
import { receptionBookingDetailsSelector } from '@store/selectors/reception'
import { useAppSelector } from '@store/index'

interface StepReservationBoxProps {
  title: string
  children: React.ReactElement
  footer: React.ReactElement
}

interface FormInputs {
  date_from: Date | null
  date_to: Date | null
}

export const StepReservationBox: React.FC<StepReservationBoxProps> = ({ title, children, footer }) => {
  const { watch } = useFormContext<FormInputs>()
  const reservation = useAppSelector(receptionBookingDetailsSelector)
  const isActive = watch().date_from && watch().date_to

  return (
    <>
      <ModalBody>
        <h5 className="text-secondary">{title}</h5>
        <div className="mt-3">{children}</div>
      </ModalBody>
      <ModalBody
        className={classnames('bg-grey text-strong d-flex justify-content-end', {
          'opacity-5 pe-none': !isActive,
        })}
      >
        <span className="font-14 mr-2 align-self-end">Do zapłaty łącznie:</span>

        <span className="font-18 text-secondary lh-initial">
          {formatPriceShort(reservation ? reservation.final_brutto : '0')}
        </span>
      </ModalBody>
      {footer}
    </>
  )
}
