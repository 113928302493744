import * as React from 'react'
import { Col, Row } from 'reactstrap'
import { FormInput } from '@hyper/forms'
import { FileUploader } from '@components/file-uploader'
import { Asset } from '@models/cms-asset'
import { assetCategoriesSelector } from '@store/slices/assets-slice'
import { useAppSelector } from '@store/index'

interface Props {
  isLoading: boolean
  upload: (file: File) => void
  file: File | undefined
  asset?: Asset
}

export const CmsAssetModalForm = ({ upload, isLoading, file, asset }: Props): JSX.Element => {
  const categories = useAppSelector(assetCategoriesSelector)

  const isImage = (filename: string) =>
    filename.endsWith('.jpg') || filename.endsWith('.jpeg') || filename.endsWith('.png')

  return (
    <Row>
      <FormInput label="Nazwa" colSize={12} name="name" />
      <FormInput label="Klucz" colSize={12} name="key" />
      <FormInput label="Kategoria" type="select" colSize={12} name="category" registerParams={{ valueAsNumber: true }}>
        {categories.map(category => (
          <option value={category.id} key={category.id}>
            {category.name}
          </option>
        ))}
      </FormInput>
      <Col md={12}>
        {file ? (
          <strong className="d-block mb-2">Załączono plik: {file.name}</strong>
        ) : (
          asset?.file && (
            <>
              <strong className="d-block mb-2">
                Zapisany plik <a href={asset.file}>{asset.filename}</a>
              </strong>
              {isImage(asset.filename) && (
                <img style={{ maxWidth: 150 }} src={asset.file} className="img-thumbnail mb-2" alt="" />
              )}
            </>
          )
        )}
        <FileUploader
          maxFiles={1}
          isLoading={isLoading}
          upload={upload}
          label="Przeciągnij i upuść plik lub kliknij by dodać plik"
        />
      </Col>
    </Row>
  )
}
