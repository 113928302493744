import * as React from 'react'
import { useParams } from 'react-router-dom'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { PageTitle } from '@hyper/page-title'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { ReservationDetailsWebSocketHandler } from '@modules/reservations/details/reservation-details-web-socket-handler'
import { ReservationNotifications } from '@modules/reservations/details/notification-history/reservation-notifications'
import { Col, Row } from 'reactstrap'
import { ReservationBaseData } from '@modules/reservations/details/base-data/reservation-base-data'
import { NavigationPath } from '@models/routes'
import { ReservationListType } from '@modules/reservations/list/models'
import { useDocumentTitle } from '@helpers/utils'
import { useReceptionAppData } from '@modules/reception/hooks/use-reception-app-data'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { getReceptionBookingDetails } from '@store/actions/reception-actions'
import { HistoryBox } from '@modules/promotions/common/history-box'
import { ContentNotesCard } from '@components/content-notes/content-notes-card'
import { ReservationDepositReturn } from '@modules/reservations/details/deposit-return'
import { ReservationExtraCharges } from '@modules/reservations/details/extra-charges'
import { ReservationTouristVouchers } from '@modules/reservations/details/tourist-vouchers'
import { ReservationPromotions } from '@modules/reservations/details/reservation-promotions/reservation-promotions'
import { ReservationBill } from '@modules/reservations/details/bill/reservation-bill'
import { ReservationBistroVouchers } from '@modules/reservations/details/bistro-vouchers/reservation-bistro-vouchers'
import { ReservationRoomPurchases } from '@modules/reservations/details/reservation-room-bill/reservation-room-purchases-bill'
import { ReservationPaymentHistory } from '@modules/reservations/details/payment-history/reservation-payment-history'
import { ReservationLoadNotes } from '@modules/reservations/details/load-notes/reservation-load-notes'
import { setReceptionBookingDetails } from '@store/slices/reception-slice'
import { ContentNote } from '@models/dashboard'
import { ReservationDeposit } from '@modules/reservations/details/deposit/reservation-deposit'
import { ReservationVouchers } from '@modules/reservations/details/vouchers/reservation-vouchers'
import { ReservationDetailsNavigationTabs } from '@modules/reservations/details/reservation-details-navigation-tabs'

const getBreadcrumbsItems = (pageTitle: string) => [
  {
    label: 'Lista rezerwacji',
    path: NavigationPath.ReservationListWithParams.replace(':type', ReservationListType.Present),
    active: false,
  },
  { label: pageTitle, path: '', active: true },
]

export const ReservationDetailsView: React.FC = () => {
  const { booking_id } = useParams<'booking_id'>()
  const dispatch = useAppDispatch()

  const bookingDetails = useAppSelector((state: RootState) => state.receptionState.receptionBookingDetails)
  const bookingDetailsUrl = useAppSelector((state: RootState) => state.appState.appData.urls.reception.booking_details)
  const contentType = useAppSelector((state: RootState) => state.appState.appData.content_types.booking)

  const { isLoading, action: fetchBookingDetails } = useApiRequest(
    async () => await dispatch(getReceptionBookingDetails(`${bookingDetailsUrl}${booking_id}/`)),
  )

  React.useEffect(() => {
    fetchBookingDetails()

    return () => {
      dispatch(setReceptionBookingDetails(undefined))
    }
  }, [])

  useReceptionAppData()
  useMenuActive(NavigationPath.ReservationListWithParams.replace(':type', 'present'))

  const pageTitle = bookingDetails ? `Rezerwacja ${bookingDetails.reservation_number}` : 'Rezerwacje'
  useDocumentTitle(pageTitle)

  const summaryRef = React.useRef<HTMLDivElement>(null)
  const guestsRef = React.useRef<HTMLDivElement>(null)
  const billRef = React.useRef<HTMLDivElement>(null)
  const depositRef = React.useRef<HTMLDivElement>(null)
  const walletsRef = React.useRef<HTMLDivElement>(null)

  const handleNoteUpdate = (updatedNote: ContentNote) => {
    if (!bookingDetails) return

    dispatch(
      setReceptionBookingDetails({
        ...bookingDetails,
        notes: bookingDetails.notes.map(note => (note.id === updatedNote.id ? updatedNote : note)),
      }),
    )
  }

  return (
    <>
      <ReservationDetailsWebSocketHandler />
      <PageTitle breadCrumbItems={getBreadcrumbsItems(pageTitle)} title={pageTitle} />

      <LoaderPlaceholder content={!isLoading || bookingDetails}>
        {bookingDetails && (
          <div className="reservation-details">
            <ReservationDetailsNavigationTabs
              summaryRef={summaryRef}
              billRef={billRef}
              depositRef={depositRef}
              guestsRef={guestsRef}
              walletsRef={walletsRef}
            />
            <Row>
              <Col md={9}>
                <ReservationBaseData booking={bookingDetails} ref={summaryRef} />
                <ReservationBill booking={bookingDetails} ref={billRef} />
                <ReservationDeposit booking={bookingDetails} ref={depositRef} />
                <ReservationVouchers booking={bookingDetails} ref={walletsRef} />
                <ReservationPaymentHistory booking={bookingDetails} />
                <ReservationExtraCharges booking={bookingDetails} />
                <ReservationPromotions booking={bookingDetails} />
                <ReservationRoomPurchases booking={bookingDetails} />
                {/*<ReservationWallets booking={bookingDetails} ref={walletsRef} />*/}
                <ReservationBistroVouchers booking={bookingDetails} />
                {!!bookingDetails.tourist_vouchers.length && <ReservationTouristVouchers booking={bookingDetails} />}
                <ReservationLoadNotes booking={bookingDetails} />
                <ReservationDepositReturn booking={bookingDetails} />
              </Col>

              <Col md={3}>
                <ContentNotesCard
                  objectId={bookingDetails.id}
                  contentType={contentType}
                  notes={bookingDetails.notes}
                  onNotesUpdate={handleNoteUpdate}
                  readOnly={false}
                  withActions
                />
                <ReservationNotifications booking={bookingDetails} />
                <HistoryBox
                  history={bookingDetails.history}
                  listModalTitle={`Historia zmian rezerwacji ${bookingDetails.reservation_number}`}
                />
              </Col>
            </Row>
          </div>
        )}
      </LoaderPlaceholder>
    </>
  )
}
