import * as React from 'react'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { ErliPromocodeFiltersParams } from '@modules/promotions/erli-promocode/index'
import { ErliPromocode } from '@modules/promotions/erli-promocode/models'
import { ErliPromocodeButton } from '@modules/promotions/erli-promocode/erli-promocode-button'
import Table, { TableHeaderValue } from '@components/table/table'

interface ErliPromocodeTableProps {
  isLoading: boolean
  erliPromocodes: ErliPromocode[]
  setFilters: (filters: ErliPromocodeFiltersParams) => void
  filters: ErliPromocodeFiltersParams
}

const headerValues: TableHeaderValue<ErliPromocode>[] = [
  {
    sortField: 'code',
    title: 'Kod',
  },
  {
    sortField: 'created',
    title: 'Data',
  },
  {
    sortField: 'status_display',
    title: 'Status',
  },

  {
    sortField: 'reservation_number',
    title: 'Numer rezerwacji',
  },
  {
    title: '',
  },
]

export const ErliPromocodeTable: React.FC<ErliPromocodeTableProps> = ({
  setFilters,
  erliPromocodes,
  filters,
  isLoading,
}) => (
  <Table
    className="table-hover w-100"
    headerValues={headerValues}
    isLoading={isLoading}
    filters={filters}
    setFilters={setFilters}
  >
    {erliPromocodes.map(promocode => (
      <Table.Row key={promocode.id}>
        <Table.Cell>{promocode.code}</Table.Cell>
        <Table.Cell>{toDefaultDateFormat(promocode.created)}</Table.Cell>
        <Table.Cell>{promocode.status_display}</Table.Cell>
        <Table.Cell>
          <a href={promocode.reservation_url} target="_blank">
            {promocode.reservation_number}
          </a>
        </Table.Cell>
        <Table.Cell>
          <ErliPromocodeButton erliPromocode={promocode} />
        </Table.Cell>
      </Table.Row>
    ))}
  </Table>
)
