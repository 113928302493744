import { CrmActionsTypes, CrmActionTypesKeys } from '@store/actions-types/crm-actions-types'
import { Seller } from '@models/crm'
import { ClientDetails, ClientUser } from '@models/clients'

export interface CrmState {
  sellers: Seller[]
  sellersStatus: 'unknown' | 'ready' | 'loading'
  clients: ClientUser[]
  clientDetails: ClientDetails | undefined
}

const initialState: CrmState = {
  sellers: [],
  sellersStatus: 'unknown',
  clients: [],
  clientDetails: undefined,
}

const crmState = (state: CrmState = initialState, action: CrmActionsTypes): CrmState => {
  switch (action.type) {
    case CrmActionTypesKeys.SET_SELLERS:
      return { ...state, sellers: action.payload }
    case CrmActionTypesKeys.SET_SELLERS_STATUS:
      return { ...state, sellersStatus: action.payload }
    case CrmActionTypesKeys.SET_CLIENTS:
      return { ...state, clients: action.payload }
    case CrmActionTypesKeys.SET_CLIENT_DETAILS:
      return { ...state, clientDetails: action.payload }
    default:
      return state
  }
}

export default crmState
