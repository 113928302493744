import { TableFilters } from '@components/table/table'

export interface ShopWarehouseInvoice {
  id: number
  date: string
  move_created: string
  number: string
  provider_id: number
  warehouse_id: number
  report_url: string
}

export interface ShopWarehouse {
  id: number
  name: string
}

export interface ShopWarehouseProviders {
  id: number
  name: string
}

export interface ShopAppData {
  status: 'unknown' | 'ready' | 'loading'
  tickets_gross_value: number
  warehouses: ShopWarehouse[]
  providers: ShopWarehouseProviders[]
}

export interface ShopWarehouseInvoicesFiltersParams extends TableFilters {
  move__created_after: Date | undefined
  move__created_before: Date | undefined
  provider: string
  warehouse: string
}

export enum PaymentMethods {
  Cash = 'cash',
  Card = 'card',
  Wallet = 'wallet',
}
