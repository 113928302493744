import * as React from 'react'
import { GastroCardDetails, GastroWalletItem } from '@models/promotions'
import { Button, ModalFooter, ModalHeader } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { FormInput } from '@hyper/forms'
import { SaveButton } from '@hyper/button'
import { useForm } from 'react-hook-form'
import { useAppDispatch } from '@store/index'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useFormRequest } from '@components/hooks/use-api-request'
import { BaseModalProps } from '@components/modals/types'
import { getGastroCardDetail } from '@store/actions/gastro-card-actions'
import { Form } from '@hyper/forms/form'

interface GastroCardWalletItemsMoveProps extends BaseModalProps {
  items: GastroWalletItem[]
  gastroCardDetails: GastroCardDetails
  handleMoveClose: () => void
}

interface FormInputs {
  new_client_wallet: string
  reason: string
  kind: 'gastro_card' | 'client_wallet'
}

export const GastroCardWalletItemsMove: React.FC<GastroCardWalletItemsMoveProps> = ({
  gastroCardDetails,
  items,
  handleMoveClose,
  toggleIsVisible,
}) => {
  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()

  const methods = useForm<FormInputs>()

  const { isLoading, action: onSubmit } = useFormRequest(
    async payload => {
      dispatch(
        getGastroCardDetail(
          await commonObjectPost<GastroCardDetails>(gastroCardDetails.wallet.urls.items_batch_move, {
            ...payload,
            items: items.map(row => row.id),
          }),
        ),
      )
      addSuccessNotification('Pozycje zostały przeniesione')
      handleMoveClose()
      toggleIsVisible()
    },
    methods.setError,
    { nonFieldErrorsAs: 'new_client_wallet' },
  )

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Przenieść transakcje</ModalHeader>
      <ModalBody>
        <FormInput type="select" name="kind">
          <option value="gastro_card">Voucher gastro</option>
          <option value="client_wallet">Voucher prezentowy</option>
        </FormInput>
        <FormInput label="Numer vouchera" name="new_client_wallet" />
        <FormInput label="Powód" name="reason" />
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton label="Zapisz" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
