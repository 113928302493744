import * as React from 'react'
import Table from '@components/table/table'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { useModal } from '@components/modals/use-modal'
import { User } from '@models/account'
import { AccountUsersListTableRowAcquire } from './table-row-acquire'
import { YesNoBadge } from '@components/badges/yes-no'
import { AccountUsersListTableRowActive } from '@modules/account/users/list/table-row-active'
import { NavigationPath } from '@models/routes'

interface Props {
  user: User
}

export const AccountUsersListTableRow: React.FC<Props> = ({ user }) => {
  const [handleSetPinCode] = useModal('AccountUserSetPinCodeModal', {
    user,
  })

  return (
    <Table.Row>
      <Table.Cell>
        {user.first_name} {user.last_name}
      </Table.Cell>
      <Table.Cell>{user.email}</Table.Cell>
      <Table.Cell>{user.phone}</Table.Cell>
      <Table.Cell>{toDefaultDateTimeFormat(user.date_joined)}</Table.Cell>
      <Table.Cell>{toDefaultDateTimeFormat(user.last_login)}</Table.Cell>
      <AccountUsersListTableRowActive user={user} />
      <Table.Cell>
        <YesNoBadge value={!!user.pin_code} />
      </Table.Cell>
      <Table.Cell className="text-right">
        {user.is_receptionist && (
          <button className="btn btn-primary btn-xs mr-1" onClick={handleSetPinCode}>
            ustaw pin
          </button>
        )}
        {user.is_active && (
          <AccountUsersListTableRowAcquire returnUrl={NavigationPath.AccountUsersList} acquire={user.urls.acquire} />
        )}
        <a href={user.urls.admin}>
          <i className="uil-pen cursor-pointer text-muted font-18 mr-1" />
        </a>
      </Table.Cell>
    </Table.Row>
  )
}
