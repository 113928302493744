import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { BaseTableFilters } from '@components/table/use-handle-table-filters-change'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { IconWithText } from '@components/icon-with-text'
import { FormSelect } from '@hyper/forms/form-select'
import { CommonTableFilters } from '@components/table/common-table-filters'
import { FormCheckbox } from '@hyper/forms/form-checkbox'
import { useResortSelectOptions } from '@components/hooks/use-resort-select-options'
import { createSelectOption } from '@helpers/utils'

export interface BarriersBaseListFilterParams extends BaseTableFilters {
  resorts: CustomReactSelectOption[]
  kind: CustomReactSelectOption | null
  only_active_cars: boolean
}

interface Props {
  defaultFilters: BarriersBaseListFilterParams
  filters: BarriersBaseListFilterParams
  setFilters: (filters: BarriersBaseListFilterParams) => void
  showOnlyActiveCarsFilter: boolean
}

export const BarriersBaseListFilter: React.FC<Props> = ({
  showOnlyActiveCarsFilter,
  defaultFilters,
  filters,
  setFilters,
}) => {
  const methods = useForm<BarriersBaseListFilterParams>({
    defaultValues: defaultFilters,
  })

  const resortOptions = useResortSelectOptions()

  return (
    <FormProvider {...methods}>
      <CommonTableFilters
        filters={filters}
        defaultFilters={defaultFilters}
        setFilters={setFilters}
        searchParams={{ hideLabel: true, formGroupClassName: 'mb-0' }}
        searchWrapperParams={{ className: 'col-auto ml-auto' }}
        clearParams={{ className: 'mt-0 ml-3' }}
        rowClassName="align-items-end"
      >
        <FormSelect
          options={resortOptions}
          name="resorts"
          label="Ośrodek"
          selectProps={{ isClearable: true, isSelectMulti: true }}
          formPlainProps={{ colStyle: { maxWidth: 270 }, formGroupClassName: 'mb-0' }}
        />
        <FormSelect
          options={KindOptions}
          name="kind"
          label="Typ"
          selectProps={{ isClearable: true }}
          formPlainProps={{ colStyle: { maxWidth: 270 }, formGroupClassName: 'mb-0' }}
        />
        {showOnlyActiveCarsFilter && (
          <FormCheckbox
            name="only_active_cars"
            label="Tylko aktywne pojazdy"
            className="align-self-start mt-label pt-1"
          />
        )}
      </CommonTableFilters>

      <IconWithText
        icon="uil-info-circle"
        text="Synchronizacja ze szlabanami co 15 minut"
        wrapperClassNames="text-info font-11 align-self-center my-2"
      />
    </FormProvider>
  )
}

const KindOptions = [
  createSelectOption('Pojazd uprawniony', 'authorized'),
  createSelectOption('Klient zewnętrzny (sklep)', 'shop'),
  createSelectOption('Klient wewnętrzny (sklep)', 'shop_booking'),
  createSelectOption('Klient wewnętrzny (rezerwacja)', 'booking'),
  createSelectOption('Pojazd specjalny', 'special'),
]
