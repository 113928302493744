import * as React from 'react'
import { Col, Row } from 'reactstrap'
import { FormInput } from '@hyper/forms'
import { IconWithText } from '@components/icon-with-text'
import { useFormContext } from 'react-hook-form'
import { useHousekeepingAppData } from '@modules/housekeeping/hooks/use-housekeeping-app-data'
import { FaultOrderDetails } from '@modules/housekeeping/applications/models'
import { apartmentsSelectSelector } from '@store/selectors/timeline'
import { ApplicationImageUploader } from '@modules/housekeeping/applications/images-uploader'
import { HousekeepingApplicationFaultsCreateModalFormInputs } from '@modules/housekeeping/applications/fault/create-modal/index'
import { FormSelect } from '@hyper/forms/form-select'
import { useAppSelector } from '@store/index'

interface Props {
  resortId: number
  faultOrder?: FaultOrderDetails
}

export const HousekeepingApplicationFaultsCreateModalForm: React.FC<Props> = ({ resortId, faultOrder }) => {
  const { setValue } = useFormContext<HousekeepingApplicationFaultsCreateModalFormInputs>()

  const apartments = [{ value: null, label: 'Cześć wspólna' }, ...useAppSelector(apartmentsSelectSelector)]

  React.useEffect(() => {
    let selectedValue
    if (faultOrder) {
      if (faultOrder.apartment) {
        selectedValue = apartments.find(row => parseInt(row.value || '0', 10) === faultOrder.apartment?.id)
      } else {
        selectedValue = apartments.find(row => row.value === null)
      }
      setValue('apartment', selectedValue || '')
    }
  }, [faultOrder, apartments])

  const appData = useHousekeepingAppData(resortId)

  return (
    <>
      <Row>
        <FormSelect
          options={apartments}
          name="apartment"
          label="Lokal"
          selectProps={{ placeholder: <IconWithText icon="uil-search" text="Numer lokalu" /> }}
        />

        <FormInput name="name" label="Nazwa usterki" />
        {faultOrder?.id && (
          <FormInput name="status" label="Status" type="select">
            <option value="">wybierz</option>
            {appData.fault_order_status.map(([value, label]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </FormInput>
        )}
        {faultOrder?.id && (
          <FormInput name="executor" label="Wykonawca" type="select">
            <option value="">wybierz</option>
            {appData.fault_order_executors.map(([value, label]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </FormInput>
        )}
      </Row>
      <Row>
        <Col sm={12} className="p-0">
          <div className="hr hr-grey mb-3" />
        </Col>
      </Row>
      <Row>
        <FormInput name="description" type="textarea" label="Opis usterki" />
        {faultOrder?.id && <FormInput name="description_after" type="textarea" label="Opis po wykonaniu" />}
      </Row>
      <ApplicationImageUploader defaultImages={faultOrder?.images || []} />
    </>
  )
}
