import * as React from 'react'
import { Row } from 'reactstrap'
import { FormInput } from '@hyper/forms'

export const RodoAgreementsAcquiringPointCreateModalThanksData = () => (
  <>
    <h5 className="text-secondary mb-3">Konfiguracja strony podziękowania</h5>
    <Row>
      <FormInput
        name="name"
        label="Akapit I:"
        type="textarea"
        labelProps={{ className: 'col-5 pl-0' }}
        formGroupClassName="d-flex align-items-center"
        inputClassName="col-6"
      />
    </Row>
    <hr className="mx-n2" />
    <h5 className="text-secondary mb-3">Konfiguracja strony potwierdzenia</h5>
    <Row>
      <FormInput
        name="name"
        label="Tytuł:"
        type="textarea"
        labelProps={{ className: 'col-5 pl-0' }}
        formGroupClassName="d-flex align-items-center"
        inputClassName="col-6"
      />
      <FormInput
        name="slug"
        label="Akapit I:"
        type="textarea"
        labelProps={{ className: 'col-5 pl-0' }}
        formGroupClassName="d-flex align-items-center"
        inputClassName="col-6"
      />
      <FormInput
        name="slug"
        label="Podsumowanie:"
        labelProps={{ className: 'col-5 pl-0' }}
        formGroupClassName="d-flex align-items-center"
        inputClassName="col-6"
        type="textarea"
      />
    </Row>
    <hr className="mx-n2" />
    <h5 className="text-secondary mb-3">Konfiguracja strony końcowej</h5>
    <Row>
      <FormInput
        name="slug"
        label="Akapit I:"
        labelProps={{ className: 'col-5 pl-0' }}
        formGroupClassName="d-flex align-items-center"
        inputClassName="col-6"
        type="textarea"
      />
      <FormInput
        name="slug"
        label="Podsumowanie:"
        labelProps={{ className: 'col-5 pl-0' }}
        formGroupClassName="d-flex align-items-center"
        inputClassName="col-6"
        type="textarea"
      />
    </Row>
  </>
)
