import { Subscription, SubscriptionOptionKind } from '@modules/subscription/models'

interface Response {
  hasOption: (optionKind: SubscriptionOptionKind) => boolean
}

export const useSubscriptionOptions = (subscription: Subscription): Response => {
  const hasOption = (optionKind: SubscriptionOptionKind) =>
    subscription.options.some(option => option.kind === optionKind)

  return {
    hasOption,
  }
}
