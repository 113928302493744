import * as React from 'react'
import { ReceptionReminder } from '@models/reception'
import { ReceptionCheckReminder } from '@modules/reception/common/reception-check-reminder'
import { User } from '@components/hooks/use-authenticated-user'
import { differenceInDays } from 'date-fns'
import { useFormContext } from 'react-hook-form'

interface ReceptionCheckRemindersProps {
  reminders: ReceptionReminder[]
  required: boolean
}

export const ReceptionCheckReminders: React.FC<ReceptionCheckRemindersProps> = ({ reminders, required }) => {
  const {
    getValues,
    register,
    formState: { errors },
  } = useFormContext()
  return (
    <>
      {reminders.map(reminder => (
        <ReceptionCheckReminder
          initialValue={getValues(reminder.id)}
          errors={errors}
          key={reminder.id}
          required={required}
          id={reminder.id}
          register={register}
          type={reminder.type}
          isClosable={reminder.isClosable}
        >
          {reminder.children}
        </ReceptionCheckReminder>
      ))}
    </>
  )
}

export const newUserPredicator = (user: User) => differenceInDays(new Date(), user.dateJoined) > 21
