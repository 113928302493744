import * as React from 'react'
import { Label, Row } from 'reactstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { ColAuto } from '@hyper/col-auto'
import { Link } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { SubscriptionProductCreateButton } from '@modules/subscription/products/create'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { TableFilters } from '@components/table/table'
import { useSubscriptionKinds } from '@modules/subscription/hooks/use-subscription-kinds'
import { packageWholesaleProductsStatusOptions } from '@modules/package-wholesale/products/list/filter'
import { FormSelect } from '@hyper/forms/form-select'
import { SubscriptionType } from '@modules/subscription/models'

export interface SubscriptionProductListFilterParams extends TableFilters {
  kind: CustomReactSelectOption[]
  status: undefined | CustomReactSelectOption
}

interface Props {
  type: SubscriptionType
  defaultFilters: SubscriptionProductListFilterParams
  filters: SubscriptionProductListFilterParams
  setFilters: (filters: SubscriptionProductListFilterParams) => void
}

export const SubscriptionProductListFilter: React.FC<Props> = ({ type, defaultFilters, filters, setFilters }) => {
  const methods = useForm<SubscriptionProductListFilterParams>({
    defaultValues: defaultFilters,
  })
  const { subscriptionKindOptions } = useSubscriptionKinds()

  const onSubmit = async (payload: SubscriptionProductListFilterParams) => setFilters({ ...filters, ...payload })

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  const redirectPaths =
    {
      WITH_BENEFIT: NavigationPath.SubscriptionWithBenefitList,
      WITH_BENEFIT_20: NavigationPath.SubscriptionWithBenefit20List,
      GO_HOLIDAY: NavigationPath.SubscriptionGoHolidayList,
    }[type] || NavigationPath.SubscriptionList

  return (
    <FormProvider {...methods}>
      <Row className="mb-2">
        <ColAuto>
          <Label className="d-block">&nbsp;</Label>

          <Link to={redirectPaths} className="btn btn-light">
            Wróć do listy
          </Link>
        </ColAuto>
        <ColAuto className="ml-auto">
          <Label className="d-block">&nbsp;</Label>

          <SubscriptionProductCreateButton type={type} />
        </ColAuto>
      </Row>
      <Row>
        <ColAuto className="px-0">
          <FormSelect
            options={packageWholesaleProductsStatusOptions}
            name="status"
            label="Status"
            formPlainProps={{ colStyle: { width: 200 } }}
          />
        </ColAuto>

        <ColAuto className="px-0">
          <FormSelect
            options={subscriptionKindOptions}
            name="kind"
            label="Rodzaj pakietu"
            selectProps={{ isSelectMulti: true }}
            formPlainProps={{ colStyle: { width: 330 } }}
          />
        </ColAuto>
        <SearchFilter />
      </Row>
    </FormProvider>
  )
}
