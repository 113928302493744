import * as React from 'react'
import { useHousekeepingAppData } from '@modules/housekeeping/hooks/use-housekeeping-app-data'
import { FormCheckbox } from '@hyper/forms/form-checkbox'
import { TechnicalOrderAssignFormForwardToTechnicalOrderManager } from '@modules/technical-orders/technical-order-assign-modal/form-forward-to-technical-order-manager'

interface Props {
  resortId: number
}

export const TechnicalOrderAssignFormResort: React.FC<Props> = ({ resortId }) => {
  const { housekeeping_users } = useHousekeepingAppData(resortId)

  const users = React.useMemo(
    () => housekeeping_users.filter(row => row.is_housekeeping_handyman),
    [housekeeping_users],
  )

  return (
    <>
      {users.map(worker => (
        <span key={worker.id} title={worker.email}>
          <FormCheckbox name={`users.user__${worker.id}`} label={worker.name} className="mb-1" />
        </span>
      ))}
      <TechnicalOrderAssignFormForwardToTechnicalOrderManager />
    </>
  )
}
