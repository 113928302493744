import * as React from 'react'
import { ReceptionBookingDetails, ReceptionBookingFeedingAvailability } from '@models/reception'
import { commonObjectGet } from '@store/actions/generic-actions'

interface Response {
  isAvailable: boolean
}

export const useBookingFeedingAvailability = (booking: ReceptionBookingDetails | undefined): Response => {
  const [feedingAvailability, setFeedingAvailability] = React.useState<null | ReceptionBookingFeedingAvailability>(null)

  const fetchFeedingAvailability = async () => {
    if (booking) {
      setFeedingAvailability(
        await commonObjectGet<ReceptionBookingFeedingAvailability>(booking.urls.feeding_availability),
      )
    }
  }

  React.useEffect(() => {
    fetchFeedingAvailability()
  }, [booking])

  const isAvailable = React.useMemo(
    () =>
      feedingAvailability
        ? Object.values(feedingAvailability).some(priceData => !!Object.values(priceData).length)
        : false,
    [feedingAvailability],
  )

  return { isAvailable }
}
