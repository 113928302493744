import * as React from 'react'
import classNames from 'classnames'

const NAVBAR_HEIGHT = 70

interface Props {
  children: React.ReactNode
}

export const StickyNavbar = ({ children }: Props): JSX.Element => {
  const [isSticky, setIsSticky] = React.useState(false)
  const ref = React.useRef<HTMLDivElement>(null)

  const handleScroll = () => {
    if (!ref.current) return

    const sticky = ref.current.offsetTop
    setIsSticky(window.scrollY + NAVBAR_HEIGHT === sticky)
  }

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <div
      ref={ref}
      className={classNames('position-sticky py-1', { 'bg-white mx-n2 px-2 border-top border-bottom': isSticky })}
      style={{ top: NAVBAR_HEIGHT, zIndex: 1, transition: 'background 100ms linear' }}
    >
      {children}
    </div>
  )
}
