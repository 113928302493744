import * as React from 'react'
import { ModalBody, ModalHeader } from 'reactstrap'
import { CmsReservationAppAccommodationTypeForm } from '@modules/cms/reservation-app/accommodation-type-modal/form'
import { NavigationTabs, NavigationTabsButton } from '@components/navigation-tabs'
import { CmsReservationAppAccommodationTypeAttributes } from '@modules/cms/reservation-app/accommodation-type-modal/attributes'
import { CmsReservationAppImages } from '@modules/cms/reservation-app/common/images'
import { CmsAccommodationTypeDetails } from '@modules/cms/models'
import { BaseModalProps } from '@components/modals/types'
import { useAppDispatch } from '@store/index'
import { setCmsAccommodationType } from '@store/slices/cms-reservation-app-slice'

interface Props extends BaseModalProps {
  accommodationType: CmsAccommodationTypeDetails
}

type tabType = 'data' | 'images' | 'attributes' | 'rooms'

export const CmsReservationAppAccommodationTypeModal: React.FC<Props> = ({ toggleIsVisible, accommodationType }) => {
  const [tab, setTab] = React.useState<tabType>('data')
  const dispatch = useAppDispatch()
  const setAccommodationTypeDetails = (value: CmsAccommodationTypeDetails) => dispatch(setCmsAccommodationType(value))

  const buttons: NavigationTabsButton[] = [
    {
      title: 'Informacje',
      isActive: tab === 'data',
      onClick: () => setTab('data'),
    },
    {
      title: 'Zdjęcia',
      isActive: tab === 'images',
      onClick: () => setTab('images'),
    },
    {
      title: 'Atrybuty',
      isActive: tab === 'attributes',
      onClick: () => setTab('attributes'),
    },
  ]

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>
        Edycja zakwaterowania{' '}
        <span className="text-semi-strong">
          {accommodationType.name} {accommodationType.size}m²
        </span>
      </ModalHeader>
      <ModalBody className="pb-0">
        <NavigationTabs buttons={buttons} />
      </ModalBody>

      {tab === 'data' && (
        <CmsReservationAppAccommodationTypeForm
          toggleIsVisible={toggleIsVisible}
          accommodationTypeDetails={accommodationType}
          setAccommodationTypeDetails={setAccommodationTypeDetails}
        />
      )}
      {tab === 'attributes' && (
        <CmsReservationAppAccommodationTypeAttributes
          toggleIsVisible={toggleIsVisible}
          accommodationTypeDetails={accommodationType}
          setAccommodationTypeDetails={setAccommodationTypeDetails}
        />
      )}
      {tab === 'images' && (
        <CmsReservationAppImages
          positionUrl={accommodationType.urls.accommodation_types_images_position}
          images={accommodationType.accommodation_type_images}
          toggleIsVisible={toggleIsVisible}
          id={accommodationType.id}
          url={accommodationType.urls.accommodation_types_images}
          setState={setAccommodationTypeDetails}
        />
      )}
    </>
  )
}
