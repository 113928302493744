import * as React from 'react'

interface Props {
  children: React.ReactNode
}

export const ReceptionBookingCartItemPaidIcon = ({ children }: Props): JSX.Element => (
  <div className="d-flex align-items-center">
    <div className="reception-booking-cart__expandable__item__paid-icon">
      <i className="uil-check text-white" />
    </div>
    {children}
  </div>
)
