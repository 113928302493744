import {
  CalculatedFeedingPrice,
  ReceptionAppData,
  ReceptionBooking,
  ReceptionBookingDetails,
  ReceptionBookingsAggregationState,
} from '@models/reception'
import { ResortDetails } from '@models/booking'
import { AccommodationTypeAvailable, AvailableApartment, CalculationPrices } from '@models/reservation-create'
import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { getAvailableApartments, getReceptionAppData } from '@store/actions/reception-actions'
import { createSelector } from 'reselect'
import { RootState } from '@store/index'

interface ReceptionState {
  accommodationTypesAvailable: EntityState<AccommodationTypeAvailable>
  apartments: EntityState<AvailableApartment>
  appData: ReceptionAppData
  bookings: EntityState<ReceptionBooking>
  calculatedFeedingPrices: EntityState<CalculatedFeedingPrice>
  calculation: CalculationPrices | undefined
  receptionBookingDetails: ReceptionBookingDetails | undefined
  receptionBookingsAggregation: ReceptionBookingsAggregationState | undefined
  receptionDateFilter: Date | undefined
  receptionResortFilter: number | undefined
  resortDetails: ResortDetails | undefined
}

const bookingsAdapter = createEntityAdapter<ReceptionBooking>()
const apartmentsAdapter = createEntityAdapter<AvailableApartment>()
const accommodationTypesAvailableAdapter = createEntityAdapter<AccommodationTypeAvailable>()
const calculatedFeedingPricesAdapter = createEntityAdapter<CalculatedFeedingPrice>({
  selectId: (entity: CalculatedFeedingPrice) => entity.group_id,
})

const initialState: ReceptionState = {
  apartments: apartmentsAdapter.getInitialState(),
  accommodationTypesAvailable: accommodationTypesAvailableAdapter.getInitialState(),
  appData: {
    apartment_damages: [],
    apartment_items: [],
    discount_notes: [],
    discounts: [],
    sms_templates: [],
    status: 'unknown',
  },
  bookings: bookingsAdapter.getInitialState(),
  calculation: undefined,
  calculatedFeedingPrices: calculatedFeedingPricesAdapter.getInitialState(),
  receptionBookingDetails: undefined,
  receptionBookingsAggregation: undefined,
  receptionDateFilter: undefined,
  receptionResortFilter: undefined,
  resortDetails: undefined,
}

export const receptionSlice = createSlice({
  name: 'reception',
  initialState,
  reducers: {
    setReceptionAppData(state, action: PayloadAction<ReceptionAppData>) {
      state.appData = action.payload
    },
    setReceptionDateFilter(state, action: PayloadAction<Date>) {
      state.receptionDateFilter = action.payload
    },
    setReceptionResortFilter(state, action: PayloadAction<number>) {
      state.receptionResortFilter = action.payload
    },
    setReceptionBookingsAggregation(state, action: PayloadAction<ReceptionBookingsAggregationState>) {
      state.receptionBookingsAggregation = action.payload
    },
    setResortDetails(state, action: PayloadAction<ResortDetails>) {
      state.resortDetails = action.payload
    },
    setReceptionBookingDetails(state, action: PayloadAction<ReceptionBookingDetails | undefined>) {
      state.receptionBookingDetails = action.payload
    },
    setReceptionBookings(state, action: PayloadAction<ReceptionBooking[]>) {
      bookingsAdapter.setAll(state.bookings, action.payload)
    },
    updateSingleReceptionBooking(state, action: PayloadAction<ReceptionBooking>) {
      bookingsAdapter.updateOne(state.bookings, { id: action.payload.id, changes: action.payload })
    },
    addReceptionBooking(state, action: PayloadAction<ReceptionBooking>) {
      bookingsAdapter.addOne(state.bookings, action.payload)
    },
    removeReceptionBooking(state, action: PayloadAction<ReceptionBooking>) {
      bookingsAdapter.removeOne(state.bookings, action.payload.id)
    },
    setAccommodationTypeAvailable(state, action: PayloadAction<AccommodationTypeAvailable[]>) {
      accommodationTypesAvailableAdapter.setAll(state.accommodationTypesAvailable, action.payload)
    },
    setCalculatedFeedingPrices(state, action: PayloadAction<CalculatedFeedingPrice>) {
      calculatedFeedingPricesAdapter.upsertOne(state.calculatedFeedingPrices, action.payload)
    },
  },
  extraReducers: builder => {
    builder.addCase(getReceptionAppData.fulfilled, (state, action) => {
      state.appData = { ...action.payload, status: 'ready' }
    })

    builder.addCase(getAvailableApartments.fulfilled, (state, action) => {
      apartmentsAdapter.setAll(state.apartments, action.payload)
    })
  },
})

export const {
  removeReceptionBooking,
  setAccommodationTypeAvailable,
  setCalculatedFeedingPrices,
  setReceptionAppData,
  setReceptionBookingDetails,
  setReceptionBookings,
  setReceptionBookingsAggregation,
  setReceptionDateFilter,
  setReceptionResortFilter,
  setResortDetails,
  updateSingleReceptionBooking,
  addReceptionBooking,
} = receptionSlice.actions

export const { selectAll: selectCalculatedFeedingPrices } = calculatedFeedingPricesAdapter.getSelectors(
  (state: RootState) => state.receptionState.calculatedFeedingPrices,
)

export const { selectAll: selectReceptionBookings } = bookingsAdapter.getSelectors(
  (state: RootState) => state.receptionState.bookings,
)

export const { selectAll: selectAccommodationTypesAvailable } = accommodationTypesAvailableAdapter.getSelectors(
  (state: RootState) => state.receptionState.accommodationTypesAvailable,
)

export const { selectAll: selectApartments } = apartmentsAdapter.getSelectors(
  (state: RootState) => state.receptionState.apartments,
)

export const selectReservationAvailableApartments = createSelector(selectApartments, apartments =>
  apartments.filter(row => row.is_available),
)

export const selectReceptionAppData = (state: RootState) => state.receptionState.appData

export const selectReservationsForAccommodationType = createSelector(
  selectApartments,
  (apartments: AvailableApartment[]) => (accommodationTypeId: number | undefined) =>
    apartments.filter((apartment: AvailableApartment) => apartment.accommodation_type_id === accommodationTypeId),
)

export default receptionSlice.reducer
