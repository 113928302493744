import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { FormInput, FormPlain, FormPlainError } from '@hyper/forms'
import { CustomInput, Row } from 'reactstrap'
import { PackageWholesaleProductFormInputs } from '@modules/package-wholesale/products/create/modal'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { PackageWholesaleProductCreateFormImage } from '@modules/package-wholesale/products/create/form-image'
import { gastroCardKinds, PackageWholesaleProductDetails } from '@modules/package-wholesale/models'
import { startOfToday, startOfYear } from 'date-fns'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { PackageWholesaleProductCreateFormSubscription } from '@modules/package-wholesale/products/create/form-subscription'
import { extractInnerRef } from '@helpers/forms'
import { PackageWholesaleProductCreateFormGastroCard } from '@modules/package-wholesale/products/create/form-gastro-card'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { packageWholesaleProductsStatusOptions } from '@modules/package-wholesale/products/list/filter'
import { FormSelect } from '@hyper/forms/form-select'
import { defaultFrontendDate } from '@helpers/date-helper'

interface Props {
  isLoading: boolean
  image: File | undefined
  setImage: (image: File | undefined) => void
  packageWholesaleProductDetails?: PackageWholesaleProductDetails
  accommodationTypeOptions: CustomReactSelectOption[]
  localizationKindOptions: CustomReactSelectOption[]
  depositAmountOptions: CustomReactSelectOption[]
  defaultsDaysOptions: CustomReactSelectOption[]
}

export const PackageWholesaleProductCreateForm: React.FC<Props> = ({
  image,
  setImage,
  isLoading,
  packageWholesaleProductDetails,
  accommodationTypeOptions,
  depositAmountOptions,
  localizationKindOptions,
  defaultsDaysOptions,
}) => {
  const user = useAuthenticatedUser()

  const { register, control, setValue } = useFormContext<PackageWholesaleProductFormInputs>()
  const kind = useWatch({ control, name: 'kind' })

  const isGastroCard = gastroCardKinds.includes(kind)
  const isSubscription = kind === 'subscription'

  const isExpireAfterDisabled = !user.hasPerm(UserPermission.PackageWholesaleCanSetExpireAfter) || isGastroCard

  React.useEffect(() => {
    if (isGastroCard) {
      setValue('expire_after_years', 1)
    }
  }, [isGastroCard, setValue])

  return (
    <Row>
      <FormPlain name="kind">
        <label className="mb-2">Rodzaj</label>
        <div className="d-flex mb-2">
          <CustomInput
            className="mr-2"
            type="radio"
            value="subscription"
            {...extractInnerRef(register('kind'))}
            id="kind_subscription"
            label="Pobyt"
          />
          <CustomInput
            className="mr-2"
            type="radio"
            value="gastro_card"
            {...extractInnerRef(register('kind'))}
            id="kind_gastro_card"
            label="Podwójna radość"
          />
          <CustomInput
            className="mr-2"
            type="radio"
            value="gastro_card_boost"
            {...extractInnerRef(register('kind'))}
            id="kind_gastro_card_boost"
            label="Doładowanie smaku"
          />
          <CustomInput
            className="mr-2"
            type="radio"
            value="gastro_card_2024"
            {...extractInnerRef(register('kind'))}
            id="kind_gastro_card_2024"
            label="Podwójna radość 2024"
          />
        </div>
        <div className="d-flex mb-2">
          <CustomInput
            className="mr-2"
            type="radio"
            value="gastro_card_2025"
            {...extractInnerRef(register('kind'))}
            id="kind_gastro_card_2025"
            label="Podwójna radość 2025"
          />
        </div>
      </FormPlain>
      <FormInput label="Nazwa produktu" name="name" />
      <DateFromToInput
        dateFormat={defaultFrontendDate}
        minDate={startOfYear(startOfToday())}
        label="Dostępny w sprzedaży"
        startDateName="available_from"
        endDateName="available_to"
        colSize={isSubscription ? 5 : 8}
      />

      <FormInput
        label="Data ważności w latach"
        colSize={4}
        name="expire_after_years"
        type="text"
        disabled={isExpireAfterDisabled}
      />

      <FormSelect
        name="status"
        label="Status"
        formPlainProps={{ colSize: 4 }}
        options={packageWholesaleProductsStatusOptions}
      />

      {kind === 'gastro_card' && <PackageWholesaleProductCreateFormGastroCard factor={2} />}
      {kind === 'gastro_card_boost' && <PackageWholesaleProductCreateFormGastroCard factor={1.4} />}
      {['gastro_card_2024', 'gastro_card_2025'].includes(kind) && (
        <PackageWholesaleProductCreateFormGastroCard factor={1.9} />
      )}

      {isSubscription && (
        <PackageWholesaleProductCreateFormSubscription
          localizationKindOptions={localizationKindOptions}
          depositAmountOptions={depositAmountOptions}
          accommodationTypeOptions={accommodationTypeOptions}
          defaultsDaysOptions={defaultsDaysOptions}
        />
      )}

      <PackageWholesaleProductCreateFormImage
        isLoading={isLoading}
        image={image}
        setImage={setImage}
        packageWholesaleProductDetails={packageWholesaleProductDetails}
      />
      <FormPlainError name="image" />
    </Row>
  )
}
