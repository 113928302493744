import * as React from 'react'
import { SubscriptionDetails } from '@modules/subscription/models'
import { useModal } from '@components/modals/use-modal'

interface Props {
  subscriptionDetails: SubscriptionDetails
}

export const SubscriptionDetailsPaymentsOptions: React.FC<Props> = ({ subscriptionDetails }) => {
  const [handleCreatePayment] = useModal('SubscriptionPaymentCreateModal', { subscriptionDetails })

  return (
    <div className="text-right">
      {!subscriptionDetails.is_paid && (
        <button type="button" className="btn btn-green btn-sm" onClick={handleCreatePayment}>
          Opłać abonament
        </button>
      )}
    </div>
  )
}
