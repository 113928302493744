import { PackageDetails } from '@models/package'
import * as React from 'react'
import { Card } from 'reactstrap'
import { PackageDetailsClientInformationForm } from '@modules/package/details/information/client/form'
import { PackageDetailsClientInformationTable } from '@modules/package/details/information/client/table'
import { useToggle } from 'rooks'

interface Props {
  packageDetails: PackageDetails
}

export const PackageDetailsClientInformation: React.FC<Props> = ({ packageDetails }) => {
  const [isEditing, setEdit] = useToggle()
  const toggleEdit = () => {
    setEdit(edit => !edit)
  }

  return (
    <Card data-testid="package-details-client-info">
      {isEditing ? (
        <PackageDetailsClientInformationForm toggleEdit={toggleEdit} packageDetails={packageDetails} />
      ) : (
        <PackageDetailsClientInformationTable toggleEdit={toggleEdit} packageDetails={packageDetails} />
      )}
    </Card>
  )
}
