import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { useFormRequest } from '@components/hooks/use-api-request'
import { StepReservationBox } from '@modules/reservations/create/step-reservation-box'
import { ReceptionBookingCheck } from '@modules/reception/common/reception-booking-check'
import GuestInfo from '@modules/reservations/create/step-client-info/guest-info'
import { useAppDispatch } from '@store/index'
import { updateUnfinishedReservation } from '@store/actions/reception-actions'
import { Col, Row } from 'reactstrap'
import { InvoiceForm } from '@components/invoice-form'
import { ClientUser } from '@models/clients'
import { createInvoiceData } from '@components/products/product-invoice-card'
import { Form } from '@hyper/forms/form'
import { ReservationCreateFormInputs, StepClientInfoFormProps } from '@modules/reservations/create/models'
import { StepClientBox } from '@modules/reservations/create/client-box'
import { extractSelectOptionsValues } from '@helpers/utils'

interface StepClientInfoProps {
  nextStep: () => void
  previousStep: () => void
  client: ClientUser | undefined
  setClient: (client: ClientUser | undefined) => void
}

const StepClientInfo = ({ nextStep, previousStep, client, setClient }: StepClientInfoProps) => {
  const clientInitialValue = React.useRef<ClientUser | undefined>(client)

  const dispatch = useAppDispatch()
  const methods = useFormContext<ReservationCreateFormInputs>()

  const { isLoading, action: onSubmit } = useFormRequest(
    async ({
      invoice_nip,
      invoice_company,
      invoice_street,
      invoice_postcode,
      invoice_city,
      invoice_type,
      ...clientData
    }: StepClientInfoFormProps) => {
      const payload = {
        ...clientData,
        ...(invoice_nip
          ? {
              invoice: true,
              invoice_nip,
              invoice_company,
              invoice_street,
              invoice_postcode,
              invoice_city,
              invoice_type,
            }
          : {}),
      }
      await dispatch(updateUnfinishedReservation(extractSelectOptionsValues(payload)))

      nextStep()
    },
    methods.setError,
  )

  const invoiceData = React.useMemo(
    () => (client?.profile ? createInvoiceData(client?.profile) : undefined),
    [client?.profile],
  )

  const handleClientChange = async (client: ClientUser | undefined) => {
    setClient(client)

    if (client) await dispatch(updateUnfinishedReservation({ client: client?.id }))
  }

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <StepReservationBox
        title="2. Informacje o rezerwującym"
        footer={
          <ReceptionBookingCheck nextStep={nextStep} previousStep={previousStep} isSaving={isLoading} type="submit" />
        }
      >
        <>
          <Row>
            <Col md={12} className="px-0">
              <StepClientBox client={client} setClient={handleClientChange} isDisabled={!!clientInitialValue.current} />
            </Col>
            <Col md={12} className="mb-1">
              <InvoiceForm client={client} invoiceData={invoiceData} label="Klient chce otrzymać fakturę VAT" />
            </Col>
          </Row>
          <GuestInfo />
        </>
      </StepReservationBox>
    </Form>
  )
}

export default StepClientInfo
