import * as React from 'react'
import Table, { TableHeaderValue } from '@components/table/table'
import { SubscriptionContractListTableRow } from '@modules/subscription-contract/list/table-row'
import { SubscriptionContract } from '@modules/subscription-contract/models'
import { SubscriptionContractListFilterParams } from '@modules/subscription-contract/list/filter'

const headerValues: TableHeaderValue[] = [
  { title: 'Numer', sortField: 'number' },
  { title: 'Plan', sortField: 'plan' },
  { title: 'Imię i nazwisko', sortField: 'name' },
  { title: 'E-mail', sortField: 'email' },
  { title: 'Status', sortField: 'status' },
  { title: 'Data sprzedaży' },
  { title: 'Źródło marketingowe' },
  { title: 'Data rozpoczęcia subskrypcji' },
  { title: 'Sprzedawca', sortField: 'seller' },
]

interface Props {
  subscriptions: SubscriptionContract[]
  isLoading: boolean
  filters: SubscriptionContractListFilterParams
  setFilters: (filters: SubscriptionContractListFilterParams) => void
}

export const SubscriptionContractListTable: React.FC<Props> = ({ isLoading, subscriptions, filters, setFilters }) => (
  <Table
    className="table-hover w-100"
    headerValues={headerValues}
    isLoading={isLoading}
    filters={filters}
    setFilters={setFilters}
  >
    {subscriptions.map(subscription => (
      <SubscriptionContractListTableRow key={subscription.id} subscription={subscription} />
    ))}
  </Table>
)
