import * as React from 'react'
import { Button, Row } from 'reactstrap'
import { useFormContext } from 'react-hook-form'
import { ButtonWithIcon } from '@components/button-with-icon'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { isAfter } from 'date-fns'
import * as R from 'ramda'
import DateRangeInput from '@components/date/date-range-input'
import { ColAuto } from '@hyper/col-auto'
import { FormSelect } from '@hyper/forms/form-select'

export interface StatsBoxDetailsFiltersFormInput {
  resort: CustomReactSelectOption | null
  accommodation_type: CustomReactSelectOption | null
  date_after: Date
  date_before: Date
}

interface ReservationStatsBoxDetailsFiltersProps {
  resortOptions: CustomReactSelectOption[]
  accommodationTypeOptions: CustomReactSelectOption[]
  onFilter: () => void
  defaultValues: StatsBoxDetailsFiltersFormInput
}

export const CommonStatsBoxDetailsFilters: React.FC<ReservationStatsBoxDetailsFiltersProps> = ({
  resortOptions,
  accommodationTypeOptions,
  onFilter,
  defaultValues,
}) => {
  const [filterApplied, setFilterApplied] = React.useState(false)
  const { setValue, watch } = useFormContext<StatsBoxDetailsFiltersFormInput>()

  const { date_after, date_before, resort, accommodation_type } = watch()

  const handleReset = () => {
    setValue('date_before', defaultValues.date_before)
    setValue('date_after', defaultValues.date_after)
    setValue('resort', defaultValues.resort)
    setValue('accommodation_type', defaultValues.accommodation_type)
    onFilter()
  }

  React.useEffect(() => {
    if (isAfter(date_after, date_before)) {
      setValue('date_before', date_after)
    }
  }, [date_after, date_before])

  React.useEffect(() => {
    const filterUpdated = !R.equals(defaultValues, { date_after, date_before, resort, accommodation_type })
    if (filterApplied !== filterUpdated) {
      setFilterApplied(filterUpdated)
    }
  }, [date_after, date_before, resort, accommodation_type])

  return (
    <Row className="mt-3 mx-0">
      <FormSelect
        formPlainProps={{ colSize: 2 }}
        name="resort"
        label="Pokaż podsumowanie z:"
        options={resortOptions}
        selectProps={{
          isOptionDisabled: (option: CustomReactSelectOption) => !!option.disabled,
        }}
      />
      <FormSelect
        formPlainProps={{ colSize: 2 }}
        label="Rodzaj zakwaterowania:"
        name="accommodation_type"
        options={accommodationTypeOptions}
        selectProps={{
          isOptionDisabled: (option: CustomReactSelectOption) => !!option.disabled,
        }}
      />
      <ColAuto>
        <label>Zakres dat:</label>
        <DateRangeInput
          wrapperClassName="flex-grow-1"
          inputGroupClassName="form-group"
          startDateName="date_after"
          endDateName="date_before"
        />
      </ColAuto>

      <Button color="primary" type="button" onClick={onFilter} className="mr-2 btn-tall mt-label ">
        Filtruj
      </Button>
      {filterApplied && (
        <ButtonWithIcon
          icon="uil-times"
          text="Wyczyść"
          handleClick={handleReset}
          color="light"
          btnClass="btn-tall mt-label"
        />
      )}
    </Row>
  )
}
