import * as React from 'react'
import { BookingAutocomplete, BookingAutocompleteResponse } from '@components/booking-autosuggest'
import { Alert } from 'reactstrap'
import classnames from 'classnames'
import { IconWithText } from '@components/icon-with-text'
import { useFormContext, useWatch } from 'react-hook-form'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectGet } from '@store/actions/generic-actions'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { useAppData } from '@components/hooks/use-app-data'
import { ClientBaseData } from '@components/client-select-data'
import { GastroGenericProductDialogFormInputs } from '@modules/promotions/gastro-generic-product/dialog/gastro-generic-product-dialog'

const AlertVariant = {
  error: 'alert-danger',
  info: 'alert-warning',
  default: 'alert-info',
}

interface BookingCheckResponse {
  available: boolean
  code: 'error' | 'info' | null
  message: string | null
}

interface Props {
  onSubmitDisabledChange: (disabled: boolean) => void
  isDisabled: boolean
  children?: React.ReactNode
}

export const GastroGenericProductDialogBookingSelect = ({
  onSubmitDisabledChange,
  isDisabled,
  children,
}: Props): JSX.Element => {
  const [bookingCheckResponse, setBookingCheckResponse] = React.useState<BookingCheckResponse | null>(null)
  const { setValue, control } = useFormContext<GastroGenericProductDialogFormInputs>()

  const bookingData = useWatch({ control, name: 'booking' })

  const appData = useAppData()

  const handleDataChange = (data: BookingAutocompleteResponse | null) => {
    setValue('booking', data)
  }

  const { action: checkBooking } = useApiRequest(async () => {
    onSubmitDisabledChange(true)

    const response = await commonObjectGet<BookingCheckResponse>(
      appData.urls.promotions.gastro_coupon_validate_booking,
      { booking: bookingData?.id },
    )

    setBookingCheckResponse(response)
    onSubmitDisabledChange(!response.available)
  })

  useDidUpdateEffect(() => {
    if (bookingData) {
      checkBooking()
      return
    }

    setBookingCheckResponse(null)
  }, [bookingData?.reservation_number])

  return (
    <>
      <BookingAutocomplete
        type="present"
        placeholder="Wpisz imię i nazwisko, numer rezerwacji lub numer lokalu"
        name="booking"
        label="Rezerwacja"
        onDataChange={handleDataChange}
        isDisabled={isDisabled}
        mode="gastro_coupon"
      />

      {bookingCheckResponse?.message && (
        <Alert className={classnames('py-1 px-1 mb-1 mx-2', AlertVariant[bookingCheckResponse.code ?? 'default'])}>
          <IconWithText
            icon="uil-exclamation-circle font-15 align-self-start"
            text={bookingCheckResponse?.message}
            textClass="font-12"
          />
        </Alert>
      )}

      {bookingData && (
        <ClientBaseData client={bookingData} className="mx-1 mb-2" additionalDetails={[]}>
          {children}
        </ClientBaseData>
      )}
    </>
  )
}
