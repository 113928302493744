import * as React from 'react'
import { ColAuto } from '@hyper/col-auto'
import { Label } from 'reactstrap'
import { SubscriptionContractCreateButton } from '@modules/subscription-contract/create'

export const SubscriptionContractListFilterButtons = (): JSX.Element => (
  <ColAuto className="ml-auto px-0">
    <Label className="d-block">&nbsp;</Label>

    <SubscriptionContractCreateButton />
  </ColAuto>
)
