import * as React from 'react'
import { Button, ModalFooter, ModalHeader } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { SaveButton } from '@hyper/button'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { FormProvider, useForm } from 'react-hook-form'
import { endOfMonth, startOfMonth, startOfToday } from 'date-fns'
import { createReportTask } from '@store/actions/report-tasks-actions'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { BaseModalProps } from '@components/modals/types'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { createSelectOption } from '@helpers/utils'
import { FormSelect } from '@hyper/forms/form-select'

const statusOptions = [
  createSelectOption('Aktywny', 'active'),
  createSelectOption('Wykorzystany', 'used'),
  createSelectOption('Anulowany', 'canceled'),
]

const offerKindOptions = [
  createSelectOption('Pakiety firmowe', 'hpi'),
  createSelectOption('Pakiety hurtowe', 'package_wholesale'),
  createSelectOption('Pakiety prywatne', 'subscription'),
]

interface FormInputs {
  created_after: Date
  created_before: Date
  expire_after_after: Date | null
  expire_after_before: Date | null
  used_at_after: Date | null
  used_at_before: Date | null
  offer_kind: CustomReactSelectOption | null
  status: CustomReactSelectOption | null
}

export const ReportZooTicketsModal: React.FC<BaseModalProps> = ({ toggleIsVisible }) => {
  const reportUrl = useAppSelector(
    (state: RootState) => state.appState.appData.urls.reports.report_task_entrance_ticket_zoo_report,
  )

  const dispatch = useAppDispatch()

  const methods = useForm<FormInputs>({
    mode: 'all',
    defaultValues: {
      created_after: startOfMonth(startOfToday()),
      created_before: endOfMonth(startOfToday()),
      expire_after_after: null,
      expire_after_before: null,
      used_at_after: null,
      used_at_before: null,
      offer_kind: null,
      status: null,
    },
  })

  const { isLoading, action: onSubmit } = useReportFormRequest(async () => {
    methods.clearErrors()
    const payload = methods.getValues()
    const reportTask = await dispatch(
      createReportTask([
        reportUrl,
        { ...payload, offer_kind: payload.offer_kind?.value, status: payload.status?.value },
      ]),
    )
    toggleIsVisible()
    return reportTask
  }, methods.setError)

  return (
    <FormProvider {...methods}>
      <ModalHeader toggle={toggleIsVisible}>Bilety do ZOO</ModalHeader>
      <ModalBody>
        <FormSelect
          options={offerKindOptions}
          name="offer_kind"
          label="Rodzaj oferty"
          selectProps={{ isClearable: true }}
        />
        <DateFromToInput
          label="Data sprzedaży"
          startDateName="created_after"
          endDateName="created_before"
          isClearable={true}
          className="px-0"
        />
        <FormSelect
          options={statusOptions}
          name="status"
          label="Status"
          formPlainProps={{ formGroupClassName: 'mb-3' }}
          selectProps={{ isClearable: true }}
        />
        <DateFromToInput
          label="Termin ważności biletu"
          startDateName="expire_after_after"
          endDateName="expire_after_before"
          isClearable={true}
          className="px-0"
        />
        <DateFromToInput
          label="Data wykorzystania"
          startDateName="used_at_after"
          endDateName="used_at_before"
          isClearable={true}
          className="px-0"
        />
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton
          className="btn btn-green"
          label="Zleć raport"
          labelSaving="Zlecanie.."
          isSaving={isLoading}
          onClick={onSubmit}
        />
      </ModalFooter>
    </FormProvider>
  )
}
