import * as React from 'react'
import { CleaningOrder, CleaningOrderDetails, HousekeepingCleaningOrderType } from '@modules/housekeeping/models'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { FormInput } from '@hyper/forms'
import { SaveButton } from '@hyper/button'
import { getHousekeepingCleaningOrders } from '@store/actions/housekeeping-actions'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useFormRequest } from '@components/hooks/use-api-request'
import { BaseModalProps } from '@components/modals/types'
import { updateHousekeepingCleaningOrderDetails } from '@store/slices/housekeeping-slice'
import { Form } from '@hyper/forms/form'

interface HousekeepingCleaningOrderToCheckReopenProps extends BaseModalProps {
  cleaningOrder: CleaningOrder
}

interface FormInputs {
  comment: string
}

export const HousekeepingCleaningOrderToCheckReopen: React.FC<HousekeepingCleaningOrderToCheckReopenProps> = ({
  cleaningOrder,
  toggleIsVisible,
}) => {
  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()

  const methods = useForm<FormInputs>()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    dispatch(
      updateHousekeepingCleaningOrderDetails(
        await commonObjectPost<CleaningOrderDetails>(cleaningOrder.urls.reopen, payload),
      ),
    )
    dispatch(
      getHousekeepingCleaningOrders([
        {
          resort: cleaningOrder.resort,
        },
        HousekeepingCleaningOrderType.TO_CHECK,
      ]),
    )
    addSuccessNotification('Zlecenia zostały zlecone do poprawy!')
    toggleIsVisible()
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>
        <span className="text-semi-strong">Zlecenie sprzątania lokalu</span>{' '}
        <strong className="text-strong">{cleaningOrder.apartment.name}</strong>
      </ModalHeader>
      <ModalBody>
        <FormInput label="Komentarz" name="comment" type="textarea" />
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={toggleIsVisible}>
          Anuluj
        </Button>
        <SaveButton label="Zapisz" className="ml-1 btn-green btn" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
