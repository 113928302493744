import * as React from 'react'
import { useAppData } from '@components/hooks/use-app-data'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { Col, Row } from 'reactstrap'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  allowLogout?: boolean
}
export const LoginAsLoginAs = ({ allowLogout = true }: Props): JSX.Element => {
  const logout = useAppData().urls.account.logout
  const user = useAuthenticatedUser()

  return (
    <Row className="mx-0 align-items-center">
      <Col md={5}>
        <strong>Jesteś zalogowany jako:</strong>
      </Col>
      <Col md={3}>
        <IconWithText icon="uil-user" text={user.fullname} />
      </Col>
      {allowLogout && (
        <Col md={4} className="text-right">
          <a href={logout}>
            <IconWithText
              direction="right"
              icon="uil-signout ml-2"
              text="Zmień konto"
              wrapperClassNames="text-secondary text-semi-strong"
            />
          </a>
        </Col>
      )}
    </Row>
  )
}
