import * as React from 'react'
import { SubscriptionProduct } from '@modules/subscription/models'
import Table, { TableHeaderValue } from '@components/table/table'
import { SubscriptionProductListFilterParams } from '@modules/subscription/products/list/filter'
import { formatPrice } from '@helpers/utils'
import { accommodationTypesMapSelector } from '@store/selectors/dashboard'
import { SubscriptionProductListTableRowEdit } from '@modules/subscription/products/list/table-row-edit'
import { useSelectableRows } from '@components/table/use-selectable-rows'
import { SubscriptionProductListTableActions } from '@modules/subscription/products/list/table-actionts'
import { YesNoBadge } from '@components/badges/yes-no'
import { useAppSelector } from '@store/index'

const headerValues: TableHeaderValue[] = [
  { title: 'Nazwa', sortField: 'name' },
  { title: 'Status', sortField: 'status' },
  { title: 'Prefiks', sortField: 'prefix' },
  { title: 'Rodzaj', sortField: 'kind' },
  { title: 'Data ważności', sortField: 'default_expire_years' },
  { title: 'Zakwaterowanie', sortField: 'accommodation_type_id' },
  { title: 'Ilość przyjazdów' },
  { title: 'Rodzaj klienta', sortField: 'client_kind_display' },
  { title: 'Cena brutto', sortField: 'price_brutto' },
  { title: '' },
]

interface Props {
  subscriptionProducts: SubscriptionProduct[]
  isLoading: boolean
  filters: SubscriptionProductListFilterParams
  setFilters: (filters: SubscriptionProductListFilterParams) => void
}

export const SubscriptionProductListTable: React.FC<Props> = ({
  isLoading,
  subscriptionProducts,
  filters,
  setFilters,
}) => {
  const accommodationTypesMap = useAppSelector(accommodationTypesMapSelector)
  const { selectableHeader, selectableCell, selectedRows, clearSelectedRows } =
    useSelectableRows<SubscriptionProduct>(subscriptionProducts)

  return (
    <>
      <SubscriptionProductListTableActions selectedRows={selectedRows} clearSelectedRows={clearSelectedRows} />
      <Table
        className="table-hover w-100"
        headerValues={headerValues}
        selectableHeader={selectableHeader}
        isLoading={isLoading}
        filters={filters}
        setFilters={setFilters}
      >
        {subscriptionProducts.map(subscriptionProduct => (
          <Table.Row key={subscriptionProduct.id}>
            {selectableCell(subscriptionProduct)}
            <Table.Cell>{subscriptionProduct.name}</Table.Cell>
            <Table.Cell>
              <YesNoBadge
                value={subscriptionProduct.status === 'available'}
                textYes={subscriptionProduct.status_display}
                textNo={subscriptionProduct.status_display}
              />
            </Table.Cell>
            <Table.Cell>{subscriptionProduct.prefix}</Table.Cell>
            <Table.Cell>
              {subscriptionProduct.kind_display}{' '}
              {subscriptionProduct.kind_version && `(${subscriptionProduct.kind_version_display})`}
            </Table.Cell>
            <Table.Cell>{subscriptionProduct.default_expire_years} lat</Table.Cell>
            <Table.Cell>{accommodationTypesMap[subscriptionProduct.accommodation_type_id]?.name}</Table.Cell>
            <Table.Cell>
              morze: {subscriptionProduct.local_sea} / góry: {subscriptionProduct.local_mountains} / uniwersalne:{' '}
              {subscriptionProduct.local_universal}
            </Table.Cell>
            <Table.Cell>{subscriptionProduct.client_kind_display}</Table.Cell>
            <Table.Cell>{formatPrice(subscriptionProduct.price_brutto)}</Table.Cell>
            <Table.Cell>
              <SubscriptionProductListTableRowEdit subscriptionProduct={subscriptionProduct} />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table>
    </>
  )
}
