import { useAppDispatch } from '@store/index'
import { setDashboardStandardPaginator } from '@store/actions/dashboard-actions'
import { TypedQueryResult } from '@api/base'

type Response<T, A> = TypedQueryResult<T> & { data: T[]; aggregation?: A }

interface Options {
  withAggregation?: boolean
}

export const useQueryWithPaginationResponse = <T, A = unknown>(
  queryResponse: TypedQueryResult<any>,
  options?: Options,
): Response<T, A> => {
  const dispatch = useAppDispatch()

  const { data, ...queryMethods } = queryResponse

  if (data && typeof data.count !== 'undefined' && typeof data.results !== 'undefined') {
    dispatch(setDashboardStandardPaginator(data))
  }

  return {
    data: data?.results ?? [],
    ...(options?.withAggregation && { aggregation: data?.aggregation ?? {} }),
    ...queryMethods,
  } as Response<T, A>
}
