import * as React from 'react'
import { FormPlain, FormPlainProps } from '@hyper/forms'
import { Label } from 'reactstrap'
import { useFormContext, useWatch } from 'react-hook-form'
import { FileUploader, FileUploaderAccept } from '@components/file-uploader'
import classnames from 'classnames'

interface Props {
  label: React.ReactNode
  inputName: string
  acceptFiles?: FileUploaderAccept
  formPlainProps?: Partial<FormPlainProps>
  fileUploaderClassName?: string
  canDelete?: boolean
}

export const CustomFileInput = ({
  label,
  inputName,
  acceptFiles,
  canDelete,
  formPlainProps,
  fileUploaderClassName,
}: Props): JSX.Element => {
  const {
    setValue,
    formState: { errors },
    control,
  } = useFormContext()

  const handleRemoveFile = () => {
    setValue(inputName, null)
  }

  const handleSave = (file: File) => {
    setValue(inputName, file)
  }

  const file = useWatch({ control, name: inputName })

  return (
    <FormPlain name={inputName} errors={errors} colSize={6} {...formPlainProps}>
      <Label htmlFor={inputName} className="w-100">
        {label}
      </Label>
      <div className="d-flex custom-file-uploader align-items-center">
        <FileUploader
          labelClassName="font-10"
          label="Upuść plik tutaj lub kliknij aby wybrać"
          className={classnames('w-100', fileUploaderClassName)}
          isLoading={false}
          upload={handleSave}
          accept={acceptFiles}
          file={file}
          maxFiles={1}
          withIcon={false}
        />
        {canDelete && <i className="uil-trash-alt font-15 ml-2 cursor-pointer d-block" onClick={handleRemoveFile} />}
      </div>
    </FormPlain>
  )
}

interface PreviewProps {
  url: string | null
}

CustomFileInput.Link = ({ url }: PreviewProps) => {
  if (!url) return null

  const getName = () => {
    const split = url.split('/')
    return split[split.length - 1]
  }

  return (
    <div className="d-inline-flex align-items-center text-truncate">
      <a href={url} target="_blank">
        {getName()}
      </a>
    </div>
  )
}
