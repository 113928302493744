import * as React from 'react'

import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { getShopAppData } from '@store/actions/shop-actions'
import { ShopAppData } from '@modules/shop/models'
import { setShopAppData } from '@store/slices/shop-slice'

export const useShopAppData = (): ShopAppData => {
  const appData = useAppSelector((state: RootState) => state.shopState.appData)
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (appData.status === 'unknown') {
      dispatch(getShopAppData())
      dispatch(setShopAppData({ ...appData, status: 'loading' }))
    }
  }, [])

  return appData
}
