import * as React from 'react'
import { ReceptionBooking } from '@models/reception'
import Table from '@components/table/table'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { ReceptionRowDates } from '@modules/reception/common/reception-row-dates'
import { RootState, useAppSelector } from '@store/index'
import { resortsMapSelector } from '@store/selectors/dashboard'

interface Props {
  reservation: ReceptionBooking
}

export const ReservationListTableRowUnclosed: React.FC<Props> = ({ reservation }) => {
  const accommodationTypes = useAppSelector((state: RootState) => state.appState.appData.accommodation_types)
  const resortsMap = useAppSelector(resortsMapSelector)

  const accommodationType = React.useMemo(
    () =>
      accommodationTypes.find(accommodationType => accommodationType.id === reservation.accommodation_type_id)?.name ||
      'brak',
    [accommodationTypes, reservation.accommodation_type_id],
  )

  return (
    <Table.Row key={reservation.id} className="cursor-pointer">
      <Table.Cell>
        <a href={reservation.urls.details} className="table__link-row" data-testid="reservation-details-link">
          {toDefaultDateFormat(reservation.date_to)}
        </a>
      </Table.Cell>
      <Table.Cell>
        {resortsMap[reservation.resort_id]?.name || 'brak'}
        <br />
        {accommodationType}
      </Table.Cell>
      <Table.Cell>
        <ReceptionRowDates booking={reservation} showYear={true} />
      </Table.Cell>
      <Table.Cell>
        {reservation.reservation_number}
        {reservation.discount != 0 ? (
          <>
            <br />
            {reservation.discount_kind} ({String(reservation.discount).replace('.00', '')}%)
          </>
        ) : null}
      </Table.Cell>

      <Table.Cell>
        {reservation.name}
        <br />
        <span className="font-11">{reservation.email}</span>
      </Table.Cell>
    </Table.Row>
  )
}
