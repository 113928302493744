import { PackageDetails } from '@models/package'
import * as React from 'react'
import { PackageDetailsRow } from '@modules/package/details/information/row'
import { formatDate, toDefaultDateFormat, toDefaultDateTimeFormat } from '@helpers/date-helper'
import { CardBody, Row } from 'reactstrap'
import { ColAuto } from '@hyper/col-auto'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { IconWithText } from '@components/icon-with-text'
import { PackageDetailsBaseInformationCancel } from '@modules/package/details/information/base/cancel'
import { useModal } from '@components/modals/use-modal'
import { UserPermission } from '@models/dashboard'
import { useApiRequest } from '@components/hooks/use-api-request'
import Spinner from '@hyper/spinner'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { YesNoBadge } from '@components/badges/yes-no'
import { formatPrice } from '@helpers/utils'
import { EntranceTicketPriceInformationTooltip } from '@modules/subscription/options/subscription-entrance-ticket/entrance-ticket-price-information-tooltip'
import { CustomFileInput } from '@components/custom-file-input'
import { StatusBadge } from '@modules/promotions/common/status-badge'

interface Props {
  packageDetails: PackageDetails
  toggleEdit: () => void
}

export const PackageDetailsBaseInformationTable: React.FC<Props> = ({ packageDetails, toggleEdit }) => {
  const user = useAuthenticatedUser()

  const { addSuccessMessage } = useNotificationHook()

  const { isLoading, action: handleRefresh } = useApiRequest(async () => {
    if (packageDetails.invoice_booking) {
      await commonObjectPost(packageDetails.invoice_booking.urls.resend)
      addSuccessMessage('Sukces', 'Synchronizacja została ponowiona')
    }
  })

  const isHPI = packageDetails.package_type === 'HPI'

  return (
    <CardBody>
      <Row>
        <ColAuto>
          <h4 className="mt-0 text-secondary mb-3">
            Szczegóły pakietu
            {user.is_superuser && (
              <a className="ml-1 h6 btn-pure text-primary" href={packageDetails.urls.admin}>
                (admin)
              </a>
            )}
          </h4>
        </ColAuto>
        <ColAuto className="col-auto ml-auto">
          <IconWithText
            icon="uil-edit-alt font-16"
            text="zmień"
            wrapperClassNames="cursor-pointer text-secondary"
            textClass="font-10 text-semi-strong"
            onClick={toggleEdit}
          />
        </ColAuto>
      </Row>
      <table className="table table-sm mb-0">
        <tbody>
          <PackageDetailsRow label="Status">
            <StatusBadge element={packageDetails} />
          </PackageDetailsRow>
          <PackageDetailsRow label="Prefix">{packageDetails.prefix || 'brak'}</PackageDetailsRow>
          <PackageDetailsRow label="Opcja sprzątania">
            {packageDetails.with_cleaning_option ? 'Tak' : 'Nie'}
          </PackageDetailsRow>
          <PackageDetailsRow label="Cena">{packageDetails.price_brutto}</PackageDetailsRow>
          <PackageDetailsRow label="Termin płatności kaucji">
            {packageDetails.deposit_payment_deadline_display}
          </PackageDetailsRow>
          {isHPI && (
            <PackageDetailsRow label="Bilety do Zoo Borysew w cenie">
              <WithEditableParams
                packageDetails={packageDetails}
                element={
                  packageDetails.with_zoo_borysew_tickets ? (
                    <span>
                      Tak ({formatPrice(packageDetails.zoo_borysew_tickets_single_price_brutto)})
                      <EntranceTicketPriceInformationTooltip />
                    </span>
                  ) : (
                    'Nie'
                  )
                }
              />
            </PackageDetailsRow>
          )}
          <PackageDetailsRow label="Kwota kaucji">{packageDetails.deposit_amount}</PackageDetailsRow>
          <PackageDetailsRow label="Ilość domków (morze)">
            <WithEditableParams packageDetails={packageDetails} element={packageDetails.houses_sea} />
          </PackageDetailsRow>
          <PackageDetailsRow label="Ilość apartamentów (morze)">
            <WithEditableParams packageDetails={packageDetails} element={packageDetails.apartments_sea} />
          </PackageDetailsRow>
          <PackageDetailsRow label="Ilość domków (góry)">
            <WithEditableParams packageDetails={packageDetails} element={packageDetails.houses_mountains} />
          </PackageDetailsRow>
          <PackageDetailsRow label="Ilość apartamentów (góry)">
            <WithEditableParams packageDetails={packageDetails} element={packageDetails.apartments_mountains} />
          </PackageDetailsRow>
          {isHPI && (
            <>
              <PackageDetailsRow label="Pierwszy samochód">
                <YesNoBadge value={packageDetails.with_first_car_parking} />
              </PackageDetailsRow>
              <PackageDetailsRow label="Drugi samochód">
                <YesNoBadge value={packageDetails.with_second_car_parking} />
              </PackageDetailsRow>
            </>
          )}
          <PackageDetailsRow label="Ręczników">{packageDetails.rent_towel_amount}</PackageDetailsRow>
          <PackageDetailsRow label="Sprzedawca">{packageDetails.seller ?? 'Brak'}</PackageDetailsRow>
          <PackageDetailsRow label="Rodzaj pakietu">
            {packageDetails.kind_display} {packageDetails.kind_version && ` (${packageDetails.kind_version_display})`}
          </PackageDetailsRow>
          <PackageDetailsRow label="Data ważności">{formatDate(packageDetails.expire_after)}</PackageDetailsRow>
          <PackageDetailsRow label="Utworzone przez">
            {`${packageDetails.created_by}, ${toDefaultDateTimeFormat(packageDetails.created)}`}
          </PackageDetailsRow>
          <PackageDetailsRow label="Ilość dób">{packageDetails.defaults_days}</PackageDetailsRow>
          <PackageDetailsRow label="Data ważności">
            {toDefaultDateFormat(packageDetails.expire_after)}
          </PackageDetailsRow>
          {!!packageDetails.invoice_number && (
            <PackageDetailsRow label="Numer faktury">
              <>
                <span>
                  {packageDetails.invoice_number}

                  {user.hasPerm(UserPermission.CanResendToSage) &&
                    !!packageDetails.invoice_booking &&
                    (isLoading ? (
                      <Spinner size="xs" className="inline-spinner ml-1" />
                    ) : (
                      <span
                        onClick={handleRefresh}
                        title={`Ponów symchronizację, ostatnia synchronizacja: ${toDefaultDateTimeFormat(
                          packageDetails.invoice_booking?.last_synchronization || null,
                        )}`}
                        className="uil uil-refresh text-muted font-14 ml-1 cursor-pointer"
                      />
                    ))}
                </span>
                {packageDetails.payment_date && (
                  <small className="font-10 d-block mt-2">
                    {`Termin płatności: ${toDefaultDateFormat(packageDetails.payment_date)}`}
                  </small>
                )}
              </>
            </PackageDetailsRow>
          )}

          <PackageDetailsRow label="Instrukcja korzystania z vouchera">
            <CustomFileInput.Link url={packageDetails.instruction} />
          </PackageDetailsRow>
        </tbody>
      </table>
      {packageDetails.status !== 'cancelled' && (
        <div className="mt-2 text-right">
          <PackageDetailsBaseInformationCancel packageData={packageDetails} />
        </div>
      )}
    </CardBody>
  )
}

const WithEditableParams = ({ packageDetails, element }) => {
  const [handleEditParams] = useModal('PackageParamsEditModal', { packageDetails })
  const isParamsEditable = packageDetails.status === 'initial'

  return (
    <>
      {element}
      {isParamsEditable && <i className="uil-pen text-muted ml-1 font-14 cursor-pointer" onClick={handleEditParams} />}
    </>
  )
}
