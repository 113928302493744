import * as React from 'react'
import { Button, Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useSellersOptions } from '@components/hooks/use-grouped-sellers'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { ClientUser } from '@models/clients'
import { useGroupedSources } from '@components/hooks/use-grouped-sources'
import { PackageVip, PackageVipDetails } from '@modules/promotions/package-vip/models'
import { commonObjectPost, commonObjectPut } from '@store/actions/generic-actions'
import { useAppDispatch } from '@store/index'
import { parseISODate } from '@helpers/date-helper'
import { useFormRequest } from '@components/hooks/use-api-request'
import { CustomReactSelectOption, makeDefaultSelectOption } from '@components/custom-react-select'
import { BaseModalProps } from '@components/modals/types'
import { InvoiceType } from '@models/promotions'
import {
  ReceptionBookingCheckStep,
  ReceptionBookingCheckSteps,
  showStepError,
} from '@modules/reception/common/reception-booking-check-steps'
import { PackageVipDialogDataStep } from '@modules/promotions/package-vip/add/package-vip-dialog-data-step'
import { PackageVipDialogSummaryStep } from '@modules/promotions/package-vip/add/package-vip-dialog-summary-step'
import { useProductSaleConfirmationModal } from '@components/sale/use-product-sale-confirmation-modal'
import { useModal } from '@components/modals/use-modal'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAppData } from '@components/hooks/use-app-data'
import * as R from 'ramda'
import { addPackageVip, updatePackageVipDetails } from '@store/slices/package-vip-slice'
import { extractSelectOptionsValues } from '@helpers/utils'
import { PackageVipTypeSelectOptions } from '@modules/promotions/package-vip/utils'

interface PackageVipAddModalProps extends BaseModalProps {
  fetchPackageVips?: () => void
  packageVip?: PackageVip
  packageVipClient?: ClientUser
}

export interface PackageVipAddFormInputs {
  type: CustomReactSelectOption
  source_marketing: CustomReactSelectOption
  seller: any
  sell_date: Date
  auto_cancel_after_date: Date | null
  required_payment_date: Date | null
  client: ClientUser
  invoice_nip: string
  invoice_company: string
  invoice_street: string
  invoice_postcode: string
  invoice_city: string
  invoice_type: InvoiceType
}

const steps: ReceptionBookingCheckStep[] = [
  {
    step: 1,
    description: 'Parametry sprzedaży',
    fields: ['client', 'sell_date'],
  },
  {
    step: 2,
    description: 'Podsumowanie sprzedaży',
  },
]

export const PackageVipDialog: React.FC<PackageVipAddModalProps> = ({
  toggleIsVisible,
  packageVip,
  fetchPackageVips,
  packageVipClient,
}) => {
  const [step, setStep] = React.useState(1)
  const [client, setClient] = React.useState<ClientUser | undefined>(packageVipClient)
  const { urls } = useAppData()

  const dispatch = useAppDispatch()
  const sourcesSelectOptions = useGroupedSources()

  const { addSuccessMessage } = useNotificationHook()
  const { showConfirmationPackageCreation } = useProductSaleConfirmationModal()
  const [showPackageVipDetails] = useModal('PackageVipDetails', {}, { persist: true })

  const packageVipDefaults = React.useMemo(
    () =>
      packageVip
        ? {
            ...R.pick(
              [
                'client',
                'invoice_nip',
                'invoice_company',
                'invoice_street',
                'invoice_postcode',
                'invoice_city',
                'invoice_type',
              ],
              packageVip,
            ),
            type: makeDefaultSelectOption(PackageVipTypeSelectOptions, packageVip.type),
            auto_cancel_after_date: parseISODate(packageVip.auto_cancel_after_date),
            required_payment_date: parseISODate(packageVip.required_payment_date),
          }
        : {},
    [packageVip],
  )

  const methods = useForm<PackageVipAddFormInputs>({
    defaultValues: {
      type: PackageVipTypeSelectOptions[1],
      ...packageVipDefaults,
      sell_date: parseISODate(packageVip?.sell_date || null),
      source_marketing: sourcesSelectOptions.find(row => row.value === packageVip?.source_marketing),
      seller: makeDefaultSelectOption(useSellersOptions(), packageVip?.seller_id),
    },
  })

  React.useEffect(() => {
    methods.clearErrors('client')
  }, [client])

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: PackageVipAddFormInputs) => {
    try {
      if (packageVip) {
        dispatch(
          updatePackageVipDetails(
            await commonObjectPut<PackageVipDetails>(packageVip.urls.details, {
              ...payload,
              client: client?.id,
              seller: payload.seller?.value,
              source_marketing: payload.source_marketing?.value,
            }),
          ),
        )
        addSuccessMessage('Sukces', 'Dane zostały zmienione.')
      } else {
        const packageVip = await commonObjectPost<PackageVipDetails>(urls.promotions.package_vip, {
          ...extractSelectOptionsValues(payload),
          client: client?.id,
        })
        dispatch(addPackageVip(packageVip))

        showConfirmationPackageCreation(() => showPackageVipDetails(null, { packageVip }))
      }
      await fetchPackageVips?.()
      toggleIsVisible()
    } catch (error) {
      showStepError(setStep, steps, error)
      throw error
    }
  }, methods.setError)

  const handlePrevStep = () => setStep(curr => Math.max(1, curr - 1))
  const handleNextStep = () => setStep(curr => curr + 1)

  const data = useWatch({ control: methods.control })

  const isNextStepDisabled = React.useMemo(() => {
    if (step === 1) return !client || !data.sell_date
  }, [data, step])

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggleIsVisible}>{packageVip ? 'Edytuj pakiet VIP' : 'Dodaj Pakiet VIP'}</ModalHeader>
        {!packageVip && <ReceptionBookingCheckSteps step={step} steps={steps} />}
        <ModalBody className="pb-0">
          {step === 1 && (
            <PackageVipDialogDataStep
              client={client}
              onClientSet={setClient}
              sourcesSelectOptions={sourcesSelectOptions}
              packageVip={packageVip}
            />
          )}
          {step === 2 && <PackageVipDialogSummaryStep client={client} />}
        </ModalBody>
        <ModalFooter className="d-block">
          <Row>
            <Col md={6} className="p-0">
              {step !== 1 && (
                <Button onClick={handlePrevStep} className="btn btn-light">
                  Wstecz
                </Button>
              )}
            </Col>
            <Col md={6} className="text-right p-0">
              {packageVip || step === 2 ? (
                <SaveButton
                  role="submit"
                  labelSaving={packageVip ? 'Zapisywanie...' : 'Sprzedawanie...'}
                  label={packageVip ? 'Zapisz' : 'Sprzedaj'}
                  className="btn btn-green"
                  isSaving={isLoading}
                />
              ) : (
                <Button
                  onClick={handleNextStep}
                  className="btn btn-green"
                  role="next-step"
                  disabled={isNextStepDisabled}
                >
                  Następny krok
                </Button>
              )}
            </Col>
          </Row>
        </ModalFooter>
      </form>
    </FormProvider>
  )
}
