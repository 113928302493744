import * as React from 'react'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { CouponTypeConfiguration } from '@modules/coupons/models'
import { BaseTableFilters } from '@components/table/use-handle-table-filters-change'
import { CouponTypesConfigurationTableRow } from '@modules/coupons/types-configuration/table/coupon-types-configuration-table-row'

interface Props {
  couponTypesConfigurations: CouponTypeConfiguration[]
  setFilters: (filters: BaseTableFilters) => void
  filters: TableFilters
  isLoading: boolean
}

const headerValues: TableHeaderValue[] = [
  { sortField: 'name', title: 'Nazwa' },
  { title: 'Lokalizacja' },
  { title: 'Do zakupu w godzinach' },
  { title: 'Do wykorzystania w godzinach' },
  { title: 'Punkty sprzedaży' },
  { title: '' },
]

export const CouponTypesConfigurationTable: React.FC<Props> = ({
  setFilters,
  couponTypesConfigurations,
  filters,
  isLoading,
}) => (
  <Table
    className="table-hover w-100"
    headerValues={headerValues}
    isLoading={isLoading}
    filters={filters}
    setFilters={setFilters}
  >
    {couponTypesConfigurations.map((couponTypeConfiguration: CouponTypeConfiguration) => (
      <CouponTypesConfigurationTableRow
        key={couponTypeConfiguration.id}
        couponTypeConfiguration={couponTypeConfiguration}
      />
    ))}
  </Table>
)
