import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { resortsMapSelector } from '@store/selectors/dashboard'
import { useModal } from '@components/modals/use-modal'
import { useAppSelector } from '@store/index'

interface StepGuestsBandsProps {
  booking: ReceptionBookingDetails
}

export const StepGuestsBands: React.FC<StepGuestsBandsProps> = ({ booking }) => {
  const resort = useAppSelector(resortsMapSelector)[booking.resort_id]

  const [toggleScanOpen] = useModal('ScanningDialog', { booking })

  if (!resort?.has_band || !booking.checked_in) {
    return null
  }

  const releasedBands = booking.bands.filter(band => band.state === 'RELEASED')

  return (
    <tr className="reservation-guests__table__cars">
      <td className="reservation-guests__table__column-cars reservation-guests__table__column-cars__first">
        <i className="uil-watch-alt mr-1 font-14" />

        <strong>Wydane opaski: {releasedBands.length}</strong>
      </td>
      <td className="reservation-guests__table__column-cars" colSpan={2} />
      <td className="reservation-guests__table__column-cars">
        <div className="text-semi-strong text-muted cursor-pointer" onClick={toggleScanOpen}>
          <i className="uil-capture mr-1 font-14" /> skanuj opaski
        </div>
      </td>
      <td className="reservation-guests__table__column-cars" />
    </tr>
  )
}
