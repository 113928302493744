import { BaseUrls } from '@models/base'

export interface ShopProduct {
  available_amount: number
  barcode_ean: string
  code: string
  id: number
  image: string | null
  in_warehouse: boolean
  index: string
  kind: 'service' | 'product'
  type: 'parking' | 'standard'
  kind_display: string
  name: string
  price_brutto: string
}

interface ReceiptProduct {
  product: number
  amount: number
  discount: string
}

interface ReceiptUrls extends BaseUrls {
  confirmation_url: string
  documents_print: string
  confirm: string
}

export interface Receipt {
  created: string
  id: number
  invoice_city: string
  invoice_company: string
  invoice_nip: string
  invoice_postcode: string
  invoice_street: string
  kind: 'receipt' | 'invoice'
  products: ReceiptProduct[]
  required_payment: string
  resort: number
  status: 'completed' | 'unfinished'
  tickets: number
  token: string
  total_price_brutto: string
  urls: ReceiptUrls
  user: number
}

export interface ShopProductWebsocketEvent extends ShopProduct {
  resort_id: number
}

export enum WalletScope {
  CAFE = 'cafe',
  BISTRO = 'bistro',
  RECEPTION = 'reception',
  SHOP = 'shop',
  VENDING_MACHINES = 'vending_machines',
}
