import * as React from 'react'
import { ClientDetails } from '@models/clients'
import { useForm } from 'react-hook-form'
import { useFormRequest } from '@components/hooks/use-api-request'
import { Col, Label, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { FormInput } from '@hyper/forms'
import { useAppDispatch, useAppSelector } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { setClientDetails } from '@store/actions/crm-actions'
import { commonObjectPatch } from '@store/actions/generic-actions'
import { useGroupedSources } from '@components/hooks/use-grouped-sources'
import { CustomReactSelectOption, makeDefaultSelectOption } from '@components/custom-react-select'
import { tagsOptionsSelector } from '@store/selectors/dashboard'
import { FormSelect } from '@hyper/forms/form-select'
import { createSelectOption } from '@helpers/utils'
import { Form } from '@hyper/forms/form'

interface Props {
  client: ClientDetails
  toggleEditMode: () => void
}

interface FormInputs {
  client_kind: CustomReactSelectOption | null
  source_marketing: CustomReactSelectOption | null
  tags: any[]
  account_number: string
}

export const clientKindOptions = [
  createSelectOption('Klient indywidualny', 'individual'),
  createSelectOption('Klient firmowy', 'company'),
]

export const ClientDetailsModalBaseDataForm: React.FC<Props> = ({ toggleEditMode, client }) => {
  const sources = useGroupedSources()
  const tags = useAppSelector(tagsOptionsSelector)

  const methods = useForm<FormInputs>({
    defaultValues: {
      account_number: client.profile.account_number,
      client_kind: makeDefaultSelectOption(clientKindOptions, client.profile.client_kind),
      source_marketing: makeDefaultSelectOption(sources, client.profile.source_marketing_id ?? undefined),
      tags: tags.filter(row => client.profile.tags.includes(row.label as string)),
    },
  })

  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    dispatch(
      setClientDetails(
        await commonObjectPatch<ClientDetails>(client.profile.urls.details, {
          ...payload,
          client_kind: payload.client_kind?.value,
          source_marketing: payload.source_marketing?.value,
          tags: payload.tags.map(row => row.value),
        }),
      ),
    )
    addSuccessNotification('Zmiany zostały zapisane')
    toggleEditMode()
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <Row>
        <FormSelect
          options={clientKindOptions}
          name="client_kind"
          label="Rodzaj klienta"
          formPlainProps={{ formGroupClassName: 'row' }}
          labelProps={{ className: 'col-4 mt-1' }}
          selectProps={{ className: 'col-8' }}
        />

        <FormSelect
          options={tags}
          name="tags"
          label="Tagi"
          formPlainProps={{ formGroupClassName: 'row' }}
          labelProps={{ className: 'col-4 mt-1' }}
          selectProps={{ className: 'col-8', isSelectMulti: true }}
        />

        <FormSelect
          options={sources}
          name="source_marketing"
          label="Źródło marketingowe"
          formPlainProps={{ formGroupClassName: 'row' }}
          labelProps={{ className: 'col-4 mt-1' }}
          selectProps={{ className: 'col-8' }}
        />

        <Col md={12}>
          <Row>
            <Label className="col-md-4">Numer konta do zwrotów</Label>
            <FormInput name="account_number" colSize={8} />
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="text-right">
          <button type="button" className="btn btn-light mr-1" onClick={toggleEditMode}>
            Anuluj
          </button>
          <SaveButton isSaving={isLoading} label="Zapisz dane" className="btn btn-green" />
        </Col>
      </Row>
    </Form>
  )
}
