import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectGet } from '@store/actions/generic-actions'
import * as React from 'react'
import { useAppData } from '@components/hooks/use-app-data'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { useWatch } from 'react-hook-form'
import { createSelectOption } from '@helpers/utils'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { CommonMetersReportFormInputs } from '@modules/reports/user-reports/reports-generator-common-meters/index'
import { useSelectWithAllOption } from '@helpers/use-select-with-all-option'

export interface Location {
  id: number
  caption: string
}

export interface Device {
  id: number
  name: string
  comment: string
}

interface Response {
  deviceOptions: CustomReactSelectOption[]
  locationOptions: CustomReactSelectOption[]
  selectedLocationsIds: (string | number)[]
  selectedDevicesIds: (string | number)[]
  isFetchingDevices: boolean
  isFetchingResorts: boolean
}

export const useCommonMetersData = (methods): Response => {
  const { urls } = useAppData()

  const [devices, setDevices] = React.useState<Device[]>([])
  const [locations, setLocations] = React.useState<Location[]>([])

  const locationOptions = locations.map((resort: Location) => createSelectOption(resort.caption, resort.id))

  const deviceOptions = devices.map((device: Device) =>
    createSelectOption(`${device.comment} - ${device.name}`, device.id),
  )

  const selectedLocations = useWatch<CommonMetersReportFormInputs>({ control: methods.control, name: 'location_ids' })

  const { options: devicesOptionsWithAll, selectedOptionsValues: selectedDevicesIds } = useSelectWithAllOption(
    deviceOptions,
    methods,
    'device_ids',
  )

  const { options: locationOptionsWithAll, selectedOptionsValues: selectedLocationsIds } = useSelectWithAllOption(
    locationOptions,
    methods,
    'location_ids',
  )

  const { action: fetchResorts, isLoading: isFetchingResorts } = useApiRequest(async () => {
    setLocations(
      (await commonObjectGet<[number, string][]>(urls.meter.common_meters_locations)).map(([id, caption]) => ({
        id,
        caption,
      })),
    )
  })

  const { action: fetchDevices, isLoading: isFetchingDevices } = useApiRequest(async () => {
    setDevices(
      (
        await commonObjectGet<[number, string, string][]>(urls.meter.common_meters_devices, {
          location_id: selectedLocationsIds,
        })
      ).map(([id, name, comment]) => ({ id, name, comment })),
    )
  })

  React.useEffect(() => {
    fetchResorts()
  }, [])

  useDidUpdateEffect(() => {
    methods.setValue('device_ids', [])
    fetchDevices()
  }, [selectedLocations])

  return {
    locationOptions: locationOptionsWithAll,
    deviceOptions: devicesOptionsWithAll,
    selectedLocationsIds,
    selectedDevicesIds,
    isFetchingDevices,
    isFetchingResorts,
  }
}
