import * as React from 'react'
import ModalBody from 'reactstrap/lib/ModalBody'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { ReceptionBookingDetails, ReceptionReminder } from '@models/reception'
import { newUserPredicator, ReceptionCheckReminders } from '@modules/reception/common/reception-check-reminders'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { ReceptionBookingNewFeedingContent } from '@modules/reception/checkin/step-feeding/new-feeding/reception-booking-new-feeding-content'
import { ReceptionBookingCheck } from '@modules/reception/common/reception-booking-check'
import { useCartSave } from '@modules/reception/checkin/step-feeding/new-feeding/use-cart-save'
import { useNewFeedingCalculations } from '@modules/reception/checkin/step-feeding/new-feeding/use-new-feeding-calculations'
import { getFeedingChanges } from '@modules/reception/checkin/step-feeding/new-feeding/utils'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { useNewFeedingVoucherPayment } from '@modules/reception/checkin/step-feeding/new-feeding/use-new-feeding-voucher-payment'
import { updateBookingDetails } from '@store/actions/reception-actions'
import { commonObjectGet } from '@store/actions/generic-actions'
import { useAppDispatch } from '@store/index'

const reminders: ReceptionReminder[] = [
  {
    id: 'check_additional_improvements_and_feeding',
    isClosable: true,
    children: (
      <>
        Zaproponuj wykup
        <strong> dodatkowego wyżywienia.</strong>
      </>
    ),
  },
]

interface ReceptionBookingCheckInStepImprovementsProps {
  booking: ReceptionBookingDetails
  previousStep: () => void
  nextStep: () => void
  title: string
}

export const ReceptionBookingCheckInStepFeeding: React.FC<ReceptionBookingCheckInStepImprovementsProps> = ({
  booking,
  nextStep,
  previousStep,
  title,
}) => {
  const methods = useForm()
  const user = useAuthenticatedUser()
  const dispatch = useAppDispatch()

  const feedings = useWatch({ control: methods.control })

  const { feedingCalculations, initialFeedingCalculations, fetchFeedingCalculations } = useNewFeedingCalculations(
    booking,
    methods,
  )

  const { isPaymentByWalletSelected, toggleIsPaymentByWalletSelected } =
    useNewFeedingVoucherPayment(initialFeedingCalculations)

  const feedingChanges = React.useMemo(() => {
    if (!initialFeedingCalculations?.details) return null

    return getFeedingChanges(feedings, initialFeedingCalculations.details)
  }, [feedings, initialFeedingCalculations?.details])

  const { saveCart, isSaving } = useCartSave(
    booking,
    feedingChanges,
    true,
    fetchFeedingCalculations,
    isPaymentByWalletSelected,
  )

  const handleSave = async () => {
    await saveCart()
    const updatedDetails = await commonObjectGet<ReceptionBookingDetails>(booking.urls.api_details)
    dispatch(updateBookingDetails(updatedDetails))

    nextStep()
  }

  return (
    <FormProvider {...methods}>
      <ModalBody>
        <LoaderPlaceholder content={feedingCalculations && initialFeedingCalculations}>
          <ReceptionCheckReminders reminders={reminders} required={!newUserPredicator(user)} />
          <h4>{title}</h4>
          {feedingCalculations && initialFeedingCalculations && (
            <ReceptionBookingNewFeedingContent
              booking={booking}
              toggleIsPaymentByWalletSelected={toggleIsPaymentByWalletSelected}
              isPaymentByWalletSelected={isPaymentByWalletSelected}
              feedingCalculations={feedingCalculations}
              initialFeedingCalculations={initialFeedingCalculations}
            />
          )}
        </LoaderPlaceholder>
      </ModalBody>
      <ReceptionBookingCheck nextStep={nextStep} previousStep={previousStep} onSave={handleSave} isSaving={isSaving} />
    </FormProvider>
  )
}
