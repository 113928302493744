import * as React from 'react'
import { asDecimal, formatPrice, sumDecimalArray } from '@helpers/utils'
import Table from '@components/table/table'
import { PaymentRegister } from '@models/payments'
import Decimal from 'decimal.js'

interface Props {
  paymentRegisters: PaymentRegister[]
}

export const CashboxSafeModalTableFooter: React.FC<Props> = ({ paymentRegisters }) => {
  const paymentIn = paymentRegisters.filter(row => row.type === 'KP')
  const paymentOut = paymentRegisters.filter(row => row.type === 'KW')

  const totalPaymentIn = React.useMemo(() => sumDecimalArray(paymentIn.map(row => asDecimal(row.amount))), [paymentIn])
  const totalPaymentOut = React.useMemo(
    () => sumDecimalArray(paymentOut.map(row => Decimal.abs(asDecimal(row.amount)))),
    [paymentOut],
  )

  return (
    <Table.Row>
      <Table.Cell colSpan={4} className="text-right align-middle reservation-list__summary-cell">
        <strong className="mr-2">Razem:</strong>
      </Table.Cell>
      <Table.Cell className="reservation-list__summary-cell text-nowrap">
        <ValueBLock title={`${paymentOut.length} wypłat`} price={formatPrice(totalPaymentOut.toString())} />
      </Table.Cell>

      <Table.Cell colSpan={2} className="reservation-list__summary-cell text-nowrap">
        <ValueBLock title={`${paymentIn.length} wpłaty`} price={formatPrice(totalPaymentIn.toString())} />
      </Table.Cell>
    </Table.Row>
  )
}

const ValueBLock = ({ title, price }) => (
  <div className="px-4 py-2 text-white reservation-list__summary-block">
    <strong>{price}</strong>
    <span className="d-block mb-1 font-11 mt-1">{title}</span>
  </div>
)
