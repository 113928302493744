import * as React from 'react'
import { Button } from 'reactstrap'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { useModal } from '@components/modals/use-modal'
import { GastroCardDetails } from '@models/promotions'

interface Props {
  gastroCardDetails: GastroCardDetails
}

export const GastroCardCodeCreate: React.FC<Props> = ({ gastroCardDetails }) => {
  const user = useAuthenticatedUser()

  const [handleToggleDialog] = useModal('GastroCardCodeCreateDialog', { gastroCardDetails })

  return user.hasPerm(UserPermission.PromotionsGastroCardCanCreateCode) ? (
    <Button color="green-dark" onClick={handleToggleDialog}>
      Dodaj kod
    </Button>
  ) : null
}
