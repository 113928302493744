import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { BaseModalProps } from '@components/modals/types'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { ButtonWithIcon } from '@components/button-with-icon'
import { ReceptionBookingNewFeedingDiscountListRow } from '@modules/reception/checkin/step-feeding/new-feeding/discounts/reception-booking-new-feeding-discount-list-row'
import { ReceptionBookingNewFeedingDiscountForm } from '@modules/reception/checkin/step-feeding/new-feeding/discounts/reception-booking-new-feeding-discount-form'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectGet } from '@store/actions/generic-actions'
import { ContentLoader } from '@components/content-loader'
import { FeedingDiscount } from '@modules/reception/checkin/step-feeding/new-feeding/models'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'

interface Props extends BaseModalProps {
  bookingDetails: ReceptionBookingDetails
  onFeedingChange: (feedingValues) => void
}

export const ReceptionBookingNewFeedingDiscountModal = ({
  bookingDetails,
  toggleIsVisible,
  onFeedingChange,
}: Props): React.ReactElement => {
  const user = useAuthenticatedUser()
  const [discounts, setDiscounts] = React.useState<FeedingDiscount[]>([])
  const [isEditFormVisible, setIsEditFormVisible] = React.useState(false)
  const [discountToEdit, setDiscountToEdit] = React.useState<FeedingDiscount | null>(null)

  const { action: fetchDiscounts, isLoading } = useApiRequest(async () => {
    setDiscounts(await commonObjectGet<FeedingDiscount[]>(bookingDetails.urls.reservation_feeding_discounts))
  })

  React.useEffect(() => {
    fetchDiscounts()
  }, [])

  const handleDiscountUpsert = (newDiscount: FeedingDiscount) => {
    setDiscounts(currentDiscounts => {
      const currentDiscount = currentDiscounts.find(discount => discount.id === newDiscount.id)

      if (currentDiscount) {
        return currentDiscounts.map(discount => (discount.id === newDiscount.id ? newDiscount : discount))
      }

      return [...currentDiscounts, newDiscount]
    })
  }

  const { individualDiscounts, generalDiscounts } = discounts.reduce(
    (prev, curr) =>
      curr.for_all_guests
        ? { ...prev, generalDiscounts: [...prev.generalDiscounts, curr] }
        : { ...prev, individualDiscounts: [...prev.individualDiscounts, curr] },
    { generalDiscounts: [], individualDiscounts: [] },
  )

  const onDiscountChange = (discount: FeedingDiscount) => {
    setIsEditFormVisible(true)
    setDiscountToEdit(discount)
  }

  const onDiscountChangeAbort = () => {
    setIsEditFormVisible(false)
    setDiscountToEdit(null)
  }

  const onDiscountRemove = (discountToRemove: FeedingDiscount) => {
    setDiscounts(discounts => discounts.filter(discount => discount.id !== discountToRemove.id))
  }

  const getDiscountGuestList = (discountId: number) => {
    const discount = discounts.find(discount => discount.id === discountId)
    if (!discount) return []

    return discount.booking_guests.map(
      guestId => bookingDetails.guests.find(bookingGuest => bookingGuest.id === guestId)?.name ?? '',
    )
  }

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>Rabatowanie wyżywienia {bookingDetails.reservation_number}</ModalHeader>
      <ModalBody>
        <ContentLoader isLoading={isLoading}>
          {!!discounts.length && (
            <>
              {!!generalDiscounts.length && (
                <List title="Dodane rabaty globalne">
                  {generalDiscounts.map((discount: FeedingDiscount) => (
                    <ReceptionBookingNewFeedingDiscountListRow
                      bookingDetails={bookingDetails}
                      key={discount.id}
                      title={discount.name}
                      discount={discount}
                      onEdit={onDiscountChange}
                      onRemove={onDiscountRemove}
                      onFeedingChange={onFeedingChange}
                      isAnyInEditMode={isEditFormVisible}
                      canRemove={user.hasPerm(UserPermission.FeedingCanRemoveDiscount)}
                      canEdit={user.hasPerm(UserPermission.FeedingCanChangeDiscount)}
                    />
                  ))}
                </List>
              )}

              {!!individualDiscounts.length && (
                <List title="Dodane rabaty indywidualne">
                  {individualDiscounts.map((discount: FeedingDiscount) => (
                    <ReceptionBookingNewFeedingDiscountListRow
                      bookingDetails={bookingDetails}
                      key={discount.id}
                      title={
                        <span>
                          {discount.name} <br />
                          <small>{getDiscountGuestList(discount.id).join(', ')}</small>
                        </span>
                      }
                      discount={discount}
                      onEdit={onDiscountChange}
                      onRemove={onDiscountRemove}
                      onFeedingChange={onFeedingChange}
                      isAnyInEditMode={isEditFormVisible}
                      canRemove={user.hasPerm(UserPermission.FeedingCanRemoveDiscount)}
                      canEdit={user.hasPerm(UserPermission.FeedingCanChangeDiscount)}
                    />
                  ))}
                </List>
              )}
            </>
          )}

          {!discounts.length && !isEditFormVisible && (
            <h4 className="mt-3 text-center font-14 fw-semi-bold">Brak dodanych rabatów na wyżywienie.</h4>
          )}

          {isEditFormVisible ? (
            <ReceptionBookingNewFeedingDiscountForm
              onFeedingChange={onFeedingChange}
              bookingDetails={bookingDetails}
              discount={discountToEdit}
              onClearForm={onDiscountChangeAbort}
              onDiscountUpsert={handleDiscountUpsert}
            />
          ) : (
            user.hasPerm(UserPermission.FeedingCanAddDiscount) && (
              <ButtonWithIcon
                icon="uil-plus"
                text="Dodaj nowy rabat"
                color="primary"
                btnClass="mx-auto d-block mt-4"
                handleClick={() => setIsEditFormVisible(true)}
              />
            )
          )}
        </ContentLoader>
      </ModalBody>
      {!isEditFormVisible && (
        <ModalFooter className="d-flex justify-content-end" onClick={toggleIsVisible}>
          <Button color="light">Zamknij</Button>
        </ModalFooter>
      )}
    </>
  )
}

const List = ({ title, children }: { title: string; children: React.ReactNode }) => (
  <>
    <h4 className="text-secondary mt-0">{title}</h4>
    <ul className="list-unstyled mt-3 mb-4">{children}</ul>
  </>
)
