import * as React from 'react'
import { Button, ModalBody } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'

interface Props extends BaseModalProps {
  onAbort: () => void
  onSaveDraft: () => void
}

export const AgreementCreationDiscardModal = ({ onAbort, onSaveDraft, toggleIsVisible }: Props): JSX.Element => {
  const handleAbort = () => {
    onAbort()
    toggleIsVisible()
  }

  const handleSaveDraft = () => {
    onSaveDraft()
    toggleIsVisible()
  }

  return (
    <ModalBody className="py-4 bg-warning rounded text-white">
      <i className="uil-exclamation-triangle text-center w-100 d-block" style={{ fontSize: 50 }} />
      <h3 className="font-15 text-center">Przerwanie procesu tworzenia umowy</h3>
      <p className="text-center font-13 fw-semi-bold">Proszę wybrać odpowiednią akcję</p>
      <div className="col-10 mx-auto mt-3">
        <Button className="w-100" color="light" onClick={handleAbort}>
          Odrzucam dokument
        </Button>
        <Button className="w-100 mt-2" color="primary" onClick={handleSaveDraft}>
          Zapisuję szkic
        </Button>
      </div>
    </ModalBody>
  )
}
