import * as React from 'react'
import { ReceptionBookingBox } from '@modules/reception/checkin/step-payments/boxes/reception-booking-box'
import { asDecimal, formatPriceShort } from '@helpers/utils'
import { SaveButton } from '@hyper/button'
import { useForm } from 'react-hook-form'
import { bookingPaymentPreAuthorizationCheckout } from '@store/actions/reception-actions-check'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import Decimal from 'decimal.js'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useFormRequest } from '@components/hooks/use-api-request'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { ReceptionPinPad } from '@modules/reception/common/pin-pad'
import { UserPermission } from '@models/dashboard'
import { ReceptionPinPadRepeatablePayments } from '@modules/reception/common/pin-pad/reception-pin-pad-repeatable-payments'
import { commonObjectGet } from '@store/actions/generic-actions'
import { ReceptionBookingDetails, ReservationBookingPayment } from '@models/reception'
import { useEservicePinPadsList } from '@modules/reception/common/eservice-pin-pad-select/use-eservice-pin-pads-list'
import { setReceptionBookingDetails } from '@store/slices/reception-slice'

interface Props {
  payment: ReservationBookingPayment
  restToPay: Decimal
  booking: ReceptionBookingDetails
  onReturn: () => void
}

interface FormInputs {
  amount: string
}

export const ReceptionBookingPreAuthorizationBox: React.FC<Props> = ({ payment, booking, onReturn, restToPay }) => {
  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()

  const {
    setError,
    formState: { errors },
  } = useForm<FormInputs>()

  const amount: Decimal = React.useMemo(() => Decimal.min(restToPay, asDecimal(payment.amount)), [payment, restToPay])
  const user = useAuthenticatedUser()

  const { isLoading, action: handleSaveClick } = useFormRequest(async () => {
    await dispatch(bookingPaymentPreAuthorizationCheckout(payment.urls.pre_authorization_checkout, amount.toString()))
    addSuccessNotification('Preautoryzacja została oznaczona jako potwierdzona')
    onReturn()
  }, setError)

  const onEserviceSuccess = async () =>
    dispatch(setReceptionBookingDetails(await commonObjectGet<ReceptionBookingDetails>(booking.urls.api_details)))

  const singleLine = (name: string, value: JSX.Element) => (
    <div className="mb-1 d-flex justify-content-between font-11">
      <span>{name}</span>
      <div className="text-strong">{value}</div>
    </div>
  )

  const isAlreadyPaid = !restToPay.equals(new Decimal(0))
  const isCancelPreauthorizationDisabled =
    isAlreadyPaid && !user.hasPerm(UserPermission.EserviceCreditCardPreauthorizationCanCancel)

  const isEServiceAvailable = user.hasPerm(UserPermission.EserviceCanUsePinPad) && user.eservicePadId
  const pinPad = useEservicePinPadsList().find(row => row.id === payment.pin_pad_id)
  const content_type = useAppSelector((state: RootState) => state.appState.appData.content_types.booking)

  return (
    <ReceptionBookingBox title="Realizacja preautoryzacji">
      <>
        {singleLine('Kwota do pobrania ', <strong>{formatPriceShort(amount.toString())}</strong>)}
        {singleLine(
          'Kod pin preautoryzacji: ',
          <strong className="letter-spacing-2">{payment.code_preauth || 'brak'}</strong>,
        )}
        {singleLine(
          'Nr karty: ',
          <strong className="letter-spacing-2">**** **** **** {payment.last_card_numbers}</strong>,
        )}
        {!!pinPad && singleLine('Terminal: ', <strong className="letter-spacing-2">{pinPad.name}</strong>)}
        <span className="d-block mt-3 font-10 alert alert-info mb-1 align-items-center d-flex p-1">
          <i className="uil-exclamation-circle mx-1 font-16" />
          <div>
            Wprowadź kod preautoryzacji <strong>na terminalu</strong>
          </div>
        </span>

        {errors.amount && <div className="invalid-feedback d-block">{errors.amount.message}</div>}

        {isEServiceAvailable && !!payment.pin_pad_id ? (
          <>
            <div className="mt-2">
              <ReceptionPinPadRepeatablePayments
                object_id={booking.id}
                content_type={content_type}
                amount={amount.toString()}
                eServicePinPad={pinPad}
                kind="booking_deposit_preauthorization_checkout"
                preauth_payment={payment.id}
                onPaymentConfirm={handleSaveClick}
                isConfirming={isLoading}
                isDisabled={!isAlreadyPaid}
                onEserviceSuccess={onEserviceSuccess}
              />
            </div>
            <div className="mt-2 d-flex justify-content-between">
              <ReceptionPinPad
                content_type={content_type}
                object_id={booking.id}
                isDisabled={isCancelPreauthorizationDisabled}
                className="btn-outline-danger"
                amount={amount.toString()}
                title="Odwołaj preautoryzacje"
                kind="booking_deposit_preauthorization_cancel"
                preauth_payment={payment.id}
              />
            </div>
          </>
        ) : (
          <>
            <SaveButton
              isSaving={isLoading}
              className="btn btn-success mt-1 w-100"
              label="Potwierdź rozliczenie preautoryzacji"
              labelSaving="Potwierdzanie..."
              onClick={handleSaveClick}
            />
            <span className="font-11 mt-2 d-block">
              Rozlicz preautoryzację ręcznie. Wykonaj na tym samym terminalu, na którym zablokowano kaucję.
            </span>
          </>
        )}
      </>
    </ReceptionBookingBox>
  )
}
