import * as React from 'react'

import { Apartment } from '@models/apartment'
import classNames from 'classnames'
import timelineHelper from '@helpers/timeline-helper'
import { CalendarDate, TimelineBooking } from '@store/reducers/timeline-reducers'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import TimelineItemResizerView from '@modules/reservations/timeline/timeline-item-resizer'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { setEditModeForItem } from '@store/actions/timeline-actions'
import { isReservedByBooking } from '@modules/reception/checkin/preview/reception-booking-check-in-preview-bill'
import { ReceptionBookingKind } from '@models/reception'

interface Props {
  booking: TimelineBooking
  apartment: Apartment
  allApartments: Apartment[]
  isFiltered: boolean
  renderedDates: CalendarDate[]
  isCompactView: boolean
  isJoined: boolean
  onEditModeChange: (editMode: EditModeType | null) => void
  editMode: EditModeType | null
}

export type EditModeType = 'item' | 'resizer' | null

const BlockedBookingKindMapper: Partial<Record<ReceptionBookingKind, string>> = {
  booking: 'B',
  cafeteria: 'K',
  event: 'E',
  employee: 'P',
  other: 'I',
}

const TimelineItemBasicView: React.FC<Props> = ({
  apartment,
  booking,
  isFiltered,
  renderedDates,
  allApartments,
  isCompactView,
  isJoined,
  editMode,
  onEditModeChange,
}) => {
  const dispatch = useAppDispatch()
  const editingBookingItem = useAppSelector((state: RootState) => state.timelineState.editingBookingItem)
  const isBookingBeforeApFee = timelineHelper.isBookingBeforeApFee(booking.created_at)

  const [, drag, preview] = useDrag({
    type: 'RESERVATION_ITEM',
    item: { booking },
    canDrag: () => editMode === 'item' && !!editingBookingItem && timelineHelper.isBookingEditable(booking),
  })

  React.useEffect(() => {
    if (!!editMode && editingBookingItem?.id !== booking.id) {
      onEditModeChange(null)
    }
  }, [editingBookingItem])

  React.useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [])

  React.useEffect(() => {
    const payload = editMode
      ? { id: booking.id, type: editMode }
      : editingBookingItem?.id !== booking.id
        ? editingBookingItem
        : null

    dispatch(setEditModeForItem(payload))
  }, [editMode])

  const disableEditMode = (): void => {
    onEditModeChange(null)
  }

  const getLastName = (booking: TimelineBooking): string => {
    if (booking.status === 'blocked') {
      return BlockedBookingKindMapper[booking.kind] ?? ''
    }
    return booking.name.split(' ')[1]
  }

  const mapEditMode = (): string => (editMode === 'item' ? 'Przesuń rezerwację' : 'Zmień długość')

  return (
    <div
      ref={drag}
      id={`item-tooltip-${booking.id}`}
      data-testid="timeline-item"
      className={classNames(`calendar-content__item is-${booking.status}`, editMode, {
        'is-checkedin': booking.checked_in,
        'is-employee': booking.kind === 'employee',
        'is-confirmed-before-ap-fee': booking.status === 'confirmed' && isBookingBeforeApFee,
        'is-filtered': isFiltered,
        'is-edit': !!editMode,
        'is-compact': isCompactView,
      })}
      style={{
        width: timelineHelper.getItemWidth(booking.days_count),
        left: timelineHelper.getItemLeftOffset(renderedDates, booking.date_from),
        top: timelineHelper.getItemTopOffset(apartment, allApartments),
      }}
    >
      {editMode === 'resizer' && (
        <TimelineItemResizerView direction="left" booking={booking} disableEditMode={disableEditMode} />
      )}
      <span className="calendar-content__item-text">
        {editMode ? mapEditMode() : getLastName(booking)}
        {isReservedByBooking(booking) && (
          <span
            className={classNames(`calendar-content__status-booking is-${booking.status}`, {
              'is-checkedin': booking.checked_in,
              'is-initial-before-ap-fee': booking.status === 'initial' && isBookingBeforeApFee,
              'is-confirmed-before-ap-fee': booking.status === 'confirmed' && isBookingBeforeApFee,
            })}
          >
            B
          </span>
        )}
        {booking.selected_apartment && <i className="icon-lokal calendar-content__icon" />}
        {booking.has_package_vip && <i className="icon-vip calendar-content__icon" />}
        {isJoined && <i className="uil-link calendar-content__icon" />}
      </span>
      {editMode === 'resizer' && (
        <TimelineItemResizerView direction="right" booking={booking} disableEditMode={disableEditMode} />
      )}
    </div>
  )
}

export default TimelineItemBasicView
