import * as React from 'react'
import { PageTitle } from '@hyper/page-title'
import { Card, CardBody } from 'reactstrap'
import { useDocumentTitle } from '@helpers/utils'
import { useAppDispatch, useAppSelector } from '@store/index'
import { useApiRequest } from '@components/hooks/use-api-request'
import { totalSizeSelector } from '@store/selectors/dashboard'
import { useDebounce } from 'rooks'
import { TableFilters } from '@components/table/table'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { useModal } from '@components/modals/use-modal'
import { ButtonWithIcon } from '@components/button-with-icon'
import { ReceptionistUsersListTableFilter } from '@modules/account/receptionist-users/list/filter'
import { ReceptionistUsersListTable } from '@modules/account/receptionist-users/list/table'
import { receptionistUsersSelector } from '@store/slices/account-slice'
import { getReceptionistUsers } from '@store/actions/account-actions'
import { CustomReactSelectOption } from '@components/custom-react-select'

export interface ReceptionistUsersTableFilters extends TableFilters {
  is_active: CustomReactSelectOption | undefined
}

const defaultFilters: ReceptionistUsersTableFilters = {
  is_active: undefined,
  search: '',
  ordering: 'email',
  page: 1,
  page_size: 10,
}

export const ReceptionistUsersListView: React.FC = () => {
  const totalSize = useAppSelector(totalSizeSelector)
  useMenuActive(NavigationPath.ReceptionistUsers)

  const [filters, setFilters] = React.useState<ReceptionistUsersTableFilters>({
    ...defaultFilters,
  })
  const dispatch = useAppDispatch()
  const users = useAppSelector(receptionistUsersSelector)

  const { isLoading, action: fetchUsers } = useApiRequest(
    async filters => await dispatch(getReceptionistUsers(filters)),
  )

  const fetchDebounce = React.useCallback(useDebounce(fetchUsers, 300), [])
  React.useEffect(() => {
    fetchDebounce(filters)
  }, [filters])

  const pageTitle = 'Pracownicy recepcji'
  const pageTitleWithSize = pageTitle + ` (${totalSize})`
  useDocumentTitle(pageTitleWithSize)
  const [handleAdd] = useModal('ReceptionistUserModal')

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitleWithSize} />
      <Card>
        <CardBody>
          <div className="text-right mb-2">
            <ButtonWithIcon color="green" handleClick={handleAdd} icon="mdi mdi-plus-circle" text="Dodaj pracownika" />
          </div>
          <ReceptionistUsersListTableFilter defaultFilters={defaultFilters} filters={filters} setFilters={setFilters} />
          <ReceptionistUsersListTable isLoading={isLoading} users={users} filters={filters} setFilters={setFilters} />
        </CardBody>
      </Card>
    </>
  )
}
