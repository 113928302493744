import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { ReceptionBooking, ReceptionBookingGroup } from '@models/reception'
import { useAppDispatch, useAppSelector } from '@store/index'
import { getReceptionBookingDetails } from '@store/actions/reception-actions'
import { StepGuestsCars } from '@modules/reception/checkin/step-guests/cars'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { receptionBookingDetailsSelector } from '@store/selectors/reception'
import { BaseModalProps } from '@components/modals/types'
import { setReceptionBookingDetails } from '@store/slices/reception-slice'

interface ReceptionParkingNumbersDialogProps extends BaseModalProps {
  booking: ReceptionBooking
}

export const ReceptionParkingNumbersDialog: React.FC<ReceptionParkingNumbersDialogProps> = ({
  toggleIsVisible,
  booking,
}) => {
  const dispatch = useAppDispatch()

  const bookingDetails = useAppSelector(receptionBookingDetailsSelector)

  React.useEffect(() => {
    dispatch(setReceptionBookingDetails(undefined))
    dispatch(getReceptionBookingDetails(booking.urls.api_details))
  }, [])

  const guestCars = () => {
    if (!bookingDetails) return null

    return bookingDetails?.groups.map((group: ReceptionBookingGroup) => (
      <React.Fragment key={group.id}>
        <tr className="border-0">
          <td className="border-0 font-15" colSpan={3}>
            <span>
              Grupa: <b>{group.name}</b> - samochody
            </span>
          </td>
        </tr>
        <ParkingKindRow group={group} booking={bookingDetails} title="Parking zwykły" kind="parking2" />
        <ParkingKindRow group={group} booking={bookingDetails} title="Parking gwarantowany" kind="parking_vip" />
        <ParkingKindRow
          group={group}
          booking={bookingDetails}
          title="Parking dla dużych pojazdów"
          kind="parking_large"
        />
      </React.Fragment>
    ))
  }

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>
        <span className="text-semi-strong">Edycja numerów</span>
        <strong> rejestracyjnych</strong>
      </ModalHeader>
      <ModalBody>
        <LoaderPlaceholder content={bookingDetails}>
          <table className="table mb-1">
            <tbody>{guestCars()}</tbody>
          </table>
        </LoaderPlaceholder>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={toggleIsVisible}>
          Zamknij
        </Button>
      </ModalFooter>
    </>
  )
}

const ParkingKindRow = ({ title, booking, group, kind }) => (
  <>
    <tr className="border-top">
      <td className="font-15 text-semi-strong pb-0 border-0">{title}</td>
    </tr>
    <StepGuestsCars hideLabel={true} booking={booking} group={group} borderless kind={kind} />
  </>
)
