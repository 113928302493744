import * as React from 'react'
import { PaymentDayReport } from '@models/payments'
import { Col } from 'reactstrap'
import { formatDate, parseISODate } from '@helpers/date-helper'
import { set, startOfToday } from 'date-fns'
import { PaymentRegisterCashboxWithMonthBaseData } from '@modules/payment-registers/cashbox/month/base-data'
import { useToggle } from 'rooks'
import classNames from 'classnames'
import { PaymentRegisterCashboxWithMonthDays } from '@modules/payment-registers/cashbox/month/days'

interface Props {
  month: string
  rows: PaymentDayReport[]
}

export const PaymentRegisterCashboxWithMonth = ({ month, rows }: Props): JSX.Element => {
  const date = set(startOfToday(), {
    year: rows.length ? parseISODate(rows[0].date)?.getFullYear() : startOfToday().getFullYear(),
    month: parseInt(month, 10),
  })
  const [isOpen, toggleIsOpen] = useToggle()

  return (
    <>
      <Col
        md={1}
        onClick={toggleIsOpen}
        className={classNames(
          'text-strong text-dark font-14 payment-cashbox__day-rows payment-cashbox__day-rows__month py-2 text-nowrap cursor-pointer',
          {
            'bg-grey-light': isOpen,
          },
        )}
      >
        {formatDate(date, 'LLLL YYY')}
      </Col>
      {isOpen ? (
        <PaymentRegisterCashboxWithMonthDays rows={rows} />
      ) : (
        <PaymentRegisterCashboxWithMonthBaseData rows={rows} toggleIsOpen={toggleIsOpen} />
      )}
    </>
  )
}
