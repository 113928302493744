import * as React from 'react'
import { PageTitle } from '@hyper/page-title'
import { useDocumentTitle } from '@helpers/utils'
import { Card, CardBody } from 'reactstrap'
import { CouponTypeConfiguration } from '@modules/coupons/models'
import { TypedQueryResult } from '@api/base'
import { useGetCouponTypesConfigurationsQuery } from '@api/coupons'
import { BaseTableFilters } from '@components/table/use-handle-table-filters-change'
import { CouponsTypesConfigurationsFilters } from '@modules/coupons/types-configuration/coupons-types-configuration-filters'
import { CouponTypesConfigurationTable } from '@modules/coupons/types-configuration/table/coupons-types-configuration-table'

const pageTitle = 'Kupony — Typy Konfiguracja'

const defaultFilters: BaseTableFilters = {
  ordering: '-created',
  page: 1,
  page_size: 10,
  search: '',
}

const emptyList = []

export const CouponsTypesConfigurationView: React.FC = () => {
  useDocumentTitle(pageTitle)

  const [filters, setFilters] = React.useState<BaseTableFilters>(defaultFilters)

  const { data: couponTypesConfigurations = emptyList, isLoading } = useGetCouponTypesConfigurationsQuery(
    filters,
  ) as TypedQueryResult<CouponTypeConfiguration[]>

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitle} />
      <Card>
        <CardBody>
          <CouponsTypesConfigurationsFilters
            filters={filters}
            setFilters={setFilters}
            defaultFilters={defaultFilters}
          />
          <CouponTypesConfigurationTable
            isLoading={isLoading}
            filters={filters}
            setFilters={setFilters}
            couponTypesConfigurations={couponTypesConfigurations}
          />
        </CardBody>
      </Card>
    </>
  )
}
