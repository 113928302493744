import * as React from 'react'
import { ExpandArrow } from '@components/hooks/use-expandable-items'
import { Collapse } from 'reactstrap'
import { ControlledFormCheckbox } from '@hyper/forms/controlled-form-checkbox'
import { asDecimal } from '@helpers/utils'
import { useToggle } from '@components/hooks/use-toggle'
import { useFormContext } from 'react-hook-form'
import { StepGuestsGuestFormInputs } from '@modules/reception/checkin/step-guests/step-guests-guest-form/models'
import { FieldPath } from 'react-hook-form/dist/types/path'
import { ExpandableScope } from '@modules/reception/checkin/step-guests/step-guests-guest-form/wallet/scopes-list-item'

interface Props {
  title: string
  isExpanded: boolean
  toggleExpanded: () => void
  children: React.ReactNode
  scope: ExpandableScope
  scopePaths: FieldPath<StepGuestsGuestFormInputs>[]
  totalScopesAmount: number
  selectedScopesAmount: number
}

export const StepGuestsWalletScopeHeaderRow = ({
  title,
  children,
  scopePaths,
  isExpanded,
  toggleExpanded,
  scope,
  totalScopesAmount,
  selectedScopesAmount,
}: Props): JSX.Element => {
  const { setValue } = useFormContext<StepGuestsGuestFormInputs>()

  const [scopeSelected, toggleScopeSelected, setScopeSelected] = useToggle(selectedScopesAmount === totalScopesAmount)
  const hasAnySelected = asDecimal(selectedScopesAmount).gt(0) && asDecimal(selectedScopesAmount).lt(totalScopesAmount)

  const handleScopeChange = () => {
    scopePaths.map(scope => {
      setValue(scope, !scopeSelected, { shouldDirty: true })
    })

    toggleScopeSelected()
  }

  React.useEffect(() => {
    setScopeSelected(asDecimal(selectedScopesAmount).gt(0) && selectedScopesAmount === totalScopesAmount)
  }, [totalScopesAmount, selectedScopesAmount])

  return (
    <tr className="position-relative">
      <td colSpan={2} className="px-0 pb-0 border-bottom-0 border-top-0 table-list-indicator__wrapper">
        <div className="d-flex align-items-center justify-content-between px-3 pb-2 border-bottom">
          <ControlledFormCheckbox
            id={scope}
            checked={scopeSelected}
            indeterminate={hasAnySelected}
            label={title}
            onChange={handleScopeChange}
          />
          <div className="text-right">
            <span className="pr-2 font-11">
              Wybrane {selectedScopesAmount} z {totalScopesAmount}
            </span>
            <ExpandArrow isExpanded={isExpanded} fontSize="font-15" onExpand={toggleExpanded} />
          </div>
        </div>
        <Collapse isOpen={isExpanded}>{children}</Collapse>
      </td>
    </tr>
  )
}
