import * as React from 'react'
import { ModalBody } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import { BlockadeResult } from '@modules/reservations/blockade/models'
import { ReservationBlockadeSuccessfullyBlockedList } from '@modules/reservations/blockade/result/reseration-blockade-succesfully-blocked-list'
import { ReservationBlockadeNotBlockedList } from '@modules/reservations/blockade/result/reseration-blockade-not-blocked-list'

interface Props extends BaseModalProps {
  blockadeResult: BlockadeResult
}

export const ReservationBlockadeSuccessModal: React.FC<Props> = ({ toggleIsVisible, blockadeResult }: Props) => (
  <ModalBody className="modal-colored-header bg-success text-center" style={{ width: '350px' }}>
    <i className="uil-check h1" style={{ fontSize: '55px' }} />
    <p className="mt-2 text-semi-strong font-18">Podsumowanie blokady:</p>

    <ReservationBlockadeSuccessfullyBlockedList result={blockadeResult.success} />
    {blockadeResult.error && <ReservationBlockadeNotBlockedList result={blockadeResult} />}

    <button type="button" className="btn btn-light mb-2 mt-3" data-dismiss="modal" onClick={toggleIsVisible}>
      Rozumiem
    </button>
  </ModalBody>
)
