import * as React from 'react'
import { Col, Row, UncontrolledTooltip } from 'reactstrap'
import { newUserPredicator, ReceptionCheckReminders } from '@modules/reception/common/reception-check-reminders'
import { useFormContext } from 'react-hook-form'
import { ReceptionReminder } from '@models/reception'
import { LocalSelectionType, ReservationCreateFormInputs } from '@modules/reservations/create/models'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useAppDispatch, useAppSelector } from '@store/index'
import { AvailableApartment } from '@models/reservation-create'
import { getResortDetails } from '@store/actions/reception-actions'
import { IconWithText } from '@components/icon-with-text'
import { differenceInDays } from 'date-fns'
import { parseISODate } from '@helpers/date-helper'
import { asDecimal, formatPrice } from '@helpers/utils'
import { useModal } from '@components/modals/use-modal'
import { selectResortDetails } from '@store/selectors/reception'
import { ResortDetails } from '@models/booking'
import { localSelectionOptions } from '@modules/reservations/create/consts'
import { FormSelect } from '@hyper/forms/form-select'
import { CustomReactSelectOption } from '@components/custom-react-select'

export const isForClientWithBenefit = (apartment: AvailableApartment) =>
  apartment.tags.some(tag => tag.keyword === 'for_client_with_benefit')

interface StepReservationApartmentSelectionProps {
  isStepActive: (name: keyof ReservationCreateFormInputs) => boolean
  availableApartments: AvailableApartment[]
}

export const StepReservationApartmentSelection: React.FC<StepReservationApartmentSelectionProps> = ({
  isStepActive,
  availableApartments,
}) => {
  const { watch, resetField, setValue } = useFormContext<ReservationCreateFormInputs>()
  const dispatch = useAppDispatch()

  const user = useAuthenticatedUser()

  const { resort, accommodation_type, date_to, date_from, localSelection, apartment } = watch()

  const stayLength = differenceInDays(parseISODate(date_to) as Date, parseISODate(date_from) as Date)

  const reminders: ReceptionReminder[] =
    date_from && date_to
      ? [
          {
            id: 'check_payable_local_selection',
            isClosable: true,
            children: (
              <>
                Zaproponuj możliwość samodzielnego wybrania lokalu.{' '}
                <strong>Koszt to {formatPrice(asDecimal('19.90').mul(stayLength).toString())}</strong> (19.90zł x{' '}
                {stayLength} doby)
              </>
            ),
          },
        ]
      : []

  const apartmentOptions: CustomReactSelectOption[] = availableApartments.map((apartment: AvailableApartment) => ({
    label: apartment.display_name,
    value: apartment.id,
    context: apartment,
  }))

  const isPayable = React.useMemo(() => localSelection?.value === LocalSelectionType.PAYABLE, [localSelection])

  React.useEffect(() => {
    if (!isPayable) {
      resetField('apartment')
    }
  }, [isPayable])

  const resortDetails = useAppSelector(selectResortDetails) as ResortDetails

  React.useEffect(() => {
    if (resort?.value && (!resortDetails || resortDetails.id !== resort.value)) {
      dispatch(getResortDetails(resort?.value))
    }
  }, [resort?.value])

  const isActive = isStepActive('date_from') && isStepActive('date_to') && isStepActive('accommodation_type')

  const accommodationType = React.useMemo(() => {
    const selectedResort = user.resorts.find(el => el.id === resort?.value)
    if (!selectedResort) return undefined
    return selectedResort.accommodation_types.find(el => el.id === accommodation_type?.value)
  }, [user.resorts, accommodation_type, resort])

  const handleApartmentSelection = (selectedApartmentId: number | null) => {
    setValue('apartment', apartmentOptions.find(apartment => apartment.value === selectedApartmentId) ?? null)
  }

  const [showLocalsMap] = useModal('LocalSelectionMapModal')

  const handleShowLocalsMap = () => {
    showLocalsMap(null, {
      apartments: availableApartments,
      accommodation: accommodationType,
      defaultSelectedApartmentId: apartment?.value,
      onApartmentSelect: handleApartmentSelection,
    })
  }

  return (
    <>
      <FormSelect
        formPlainProps={{ colSize: 6 }}
        label="Wybór lokalu:"
        options={localSelectionOptions}
        name="localSelection"
        selectProps={{
          placeholder: 'Wybierz metodę wyboru lokalu',
          isDisabled: !isActive,
          noOptionsMessage: () => 'Brak wolnych lokali',
        }}
      />

      {isPayable && (
        <FormSelect
          formPlainProps={{ colSize: 6 }}
          options={apartmentOptions}
          name="apartment"
          selectProps={{
            placeholder: 'Wybierz lokal',
            isDisabled: !isActive,
            noOptionsMessage: () => 'Brak wolnych lokali',
            formatOptionLabel: ApartmentOption,
          }}
          label={
            <div className="d-flex justify-content-between">
              <span>Dostępne lokale:</span>
              <IconWithText
                icon="uil-map-marker font-12"
                text="Pokaż na mapie"
                wrapperClassNames="font-11 font-weight-normal align-self-end ml-2"
                onClick={handleShowLocalsMap}
              />
            </div>
          }
        />
      )}
      {!isPayable && (
        <Row className="mt-2 w-100 m-0">
          <Col md={12}>
            <ReceptionCheckReminders reminders={reminders} required={!newUserPredicator(user)} />
          </Col>
        </Row>
      )}
    </>
  )
}

const ApartmentOption = ({ label, context }: { label: string; context: AvailableApartment }) => {
  const [localNumber, floor] = label.split(' – ')

  return (
    <div className="d-flex align-items-center py-1">
      <strong className="col-1 font-14 px-0">{localNumber}</strong>
      {floor && <strong className="font-12 opacity-75 pl-1">{floor}</strong>}
      <div className="ml-auto d-flex align-items-center">
        {context.no_animals && (
          <>
            <i className="uil-flower text-warning font-16 lh-initial pr-2" id={`animals-${localNumber}`} />
            <UncontrolledTooltip target={`animals-${localNumber}`} placement="top">
              <div className="d-flex align-items-center">
                <div className="text-center">
                  <strong className="font-12">Lokal bez zwierząt</strong>
                  <span className="font-11 d-block">(dla alergików)</span>
                </div>
              </div>
            </UncontrolledTooltip>
          </>
        )}

        {context.has_garden_fixed && (
          <>
            <img
              src={require('@assets/images/grass_icon.svg')}
              alt="Ogródek"
              height="10"
              className="pr-2"
              id={`garden-${localNumber}`}
            />

            <UncontrolledTooltip placement="top" target={`garden-${localNumber}`}>
              <strong className="font-12">Lokal z ogrodem</strong>
            </UncontrolledTooltip>
          </>
        )}

        {isForClientWithBenefit(context) && (
          <>
            <i
              className="uil-star font-18 mr-1 calendar__apartment-extras__description-row is-special-local h-auto"
              id={`local-for-subscription-owner-${localNumber}`}
            />
            <UncontrolledTooltip placement="top" target={`local-for-subscription-owner-${localNumber}`}>
              <strong className="font-12">Lokal specjalny</strong>
            </UncontrolledTooltip>
          </>
        )}
      </div>
    </div>
  )
}
