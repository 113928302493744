import * as React from 'react'
import { Label, Row } from 'reactstrap'
import { FormInput } from '@hyper/forms'
import { useForm } from 'react-hook-form'
import { SubmitFilter } from '@modules/reception/common/filters/submit-filter'
import DateRangeInput from '@components/date/date-range-input'
import Col from 'reactstrap/lib/Col'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { ShopWarehouseInvoicesFiltersParams } from '@modules/shop/models'
import { useShopAppData } from '@modules/shop/hooks/use-shop-app-data'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { Form } from '@hyper/forms/form'

interface ShopWarehouseInvoicesFiltersProps {
  filters: ShopWarehouseInvoicesFiltersParams
  setFilters: (filters: ShopWarehouseInvoicesFiltersParams) => void
  defaultFilters: ShopWarehouseInvoicesFiltersParams
}

export const ShopWarehouseInvoicesFilters: React.FC<ShopWarehouseInvoicesFiltersProps> = ({
  setFilters,
  filters,
  defaultFilters,
}) => {
  const { providers, warehouses: resortWarehouses } = useShopAppData()

  const methods = useForm<ShopWarehouseInvoicesFiltersParams>({
    defaultValues: filters,
  })

  const handleReset = () => {
    methods.reset()
    setFilters(defaultFilters)
  }

  const onSubmit = async (payload: ShopWarehouseInvoicesFiltersParams) => setFilters({ ...filters, ...payload })

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  useShopAppData()

  return (
    <Form methods={methods} onSubmit={onSubmit} className="form form-style-filter mb-2">
      <Row>
        <FormInput label="Magazyn:" colSize="col-auto" type="select" name="warehouse">
          <option value="">Wszystkie</option>
          {resortWarehouses?.map(resort => (
            <option value={resort.id} key={resort.id}>
              {resort.name}
            </option>
          ))}
        </FormInput>

        <FormInput label="Dostawca:" colSize="col-auto" type="select" name="provider">
          <option value="">Wszystkie</option>
          {providers?.map(provider => (
            <option value={provider.id} key={provider.id}>
              {provider.name}
            </option>
          ))}
        </FormInput>

        <Col className="col-auto" style={{ minWidth: 320 }}>
          <div className="d-flex">
            <Label className="mr-1">Data dostawy:</Label>
          </div>

          <DateRangeInput
            isClearable={true}
            wrapperClassName="flex-grow-1"
            inputGroupClassName="form-group"
            startDateName="move__created_after"
            endDateName="move__created_before"
          />
        </Col>

        <SubmitFilter handleReset={handleReset} />

        <SearchFilter />
      </Row>
    </Form>
  )
}
