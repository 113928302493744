import * as React from 'react'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useAppDispatch, useAppSelector } from '@store/index'
import { selectReceptionAppData } from '@store/slices/reception-slice'
import { createSelectOption } from '@helpers/utils'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { Control, Path, useWatch } from 'react-hook-form'
import { UseFormSetValue } from 'react-hook-form/dist/types/form'
import { FieldValues } from 'react-hook-form/dist/types'
import { getReceptionAppData } from '@store/actions/reception-actions'

interface Response {
  templateOptions: CustomReactSelectOption[]
}

interface Props<T extends FieldValues> {
  control: Control<T>
  setValue: UseFormSetValue<T>
}

export const useSmsTemplates = <T extends FieldValues>({ setValue, control }: Props<T>): Response => {
  const { sms_templates } = useAppSelector(selectReceptionAppData)

  const dispatch = useAppDispatch()

  const template = useWatch({ control, name: 'template' as Path<T> })

  const templateOptions = sms_templates.map(smsTemplate => createSelectOption(smsTemplate.name, smsTemplate.content_pl))

  useDidUpdateEffect(() => {
    setValue('content' as Path<T>, template?.value)
  }, [template?.value])

  React.useEffect(() => {
    dispatch(getReceptionAppData({ forceFetching: true }))
  }, [])

  return {
    templateOptions,
  }
}
