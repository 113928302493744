import * as React from 'react'
import classnames from 'classnames'

interface LoginUIProps {
  children: React.ReactNode | JSX.Element
}
export const LoginUIComponent: React.FC<LoginUIProps> = ({ children }) => {
  const randomBgInt = React.useMemo(() => Math.floor(Math.random() * 15) + 1, [])
  return (
    <div className={classnames('unauthorized', `unauthorized__bg_${randomBgInt}`)}>
      <nav className="unauthorized__nav">
        <img className="unauthorized__logo" src={require('@assets/images/logo_holiday_color.svg')} alt="" />
        {children}
      </nav>
    </div>
  )
}
