import * as React from 'react'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useModal } from '@components/modals/use-modal'
import { useUpsellBookingDetailsRefresh } from '@modules/upselling/upsell-booking-details/use-upsellbooking-details-refresh'
import { ClientUser } from '@models/clients'
import { useSubscriptionTypeStrategyProvider } from '@modules/subscription/hooks/use-subscription-type-provider'
import { SubscriptionType } from '@modules/subscription/models'
import { ButtonWithIcon } from '@components/button-with-icon'
import { UpsellBookingsDetailsProductsSaleBaseRow } from '@modules/upselling/upsell-booking-details/products/sale-card/upsell-bookings-details-products-sale-base-row'

interface Props {
  type: SubscriptionType
  client: ClientUser
  name: string
}

export const UpsellBookingsSubscriptionProductSaleRow = ({ type, client, name }: Props): JSX.Element | null => {
  const user = useAuthenticatedUser()

  const { product_name, crud_permissions } = useSubscriptionTypeStrategyProvider(type)
  const { refresh } = useUpsellBookingDetailsRefresh()

  const [showSubscriptionSaleModal] = useModal(
    'SubscriptionCreateModal',
    { label: `Sprzedaj ${product_name}`, type, client },
    { onClose: refresh, persist: true },
  )

  if (!user.hasPerm(crud_permissions.create)) {
    return null
  }

  return (
    <UpsellBookingsDetailsProductsSaleBaseRow name={name}>
      <ButtonWithIcon
        icon="uil-plus font-16 lh-1"
        text="Sprzedaj"
        color="btn btn-green btn-tall"
        handleClick={showSubscriptionSaleModal}
      />
    </UpsellBookingsDetailsProductsSaleBaseRow>
  )
}
