import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { ReservationDropdown } from '@modules/reservations/details/common/reservation-dropdown'
import { useModal } from '@components/modals/use-modal'
import { BookingPaymentType } from '@models/booking'

interface ReservationDataActionsProps {
  booking: ReceptionBookingDetails
}

export const ReservationPaymentHistoryActions: React.FC<ReservationDataActionsProps> = ({ booking }) => {
  const [showPaymentAddingModal] = useModal('ReservationPaymentAddModal')

  const handleModalShow = (paymentKind: BookingPaymentType) => () => {
    showPaymentAddingModal(null, { booking, paymentKind })
  }

  return (
    <div className="d-flex justify-content-end">
      <ReservationDropdown>
        <ReservationDropdown.Item onClick={handleModalShow('installment')}>Dodaj zaliczkę</ReservationDropdown.Item>
        <ReservationDropdown.Item onClick={handleModalShow('deposit')}>Pobierz kaucję</ReservationDropdown.Item>
        <ReservationDropdown.Item onClick={handleModalShow('climatic')}>Opłata klimatyczna</ReservationDropdown.Item>
      </ReservationDropdown>
    </div>
  )
}
