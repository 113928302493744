import * as React from 'react'
import { useModal } from '@components/modals/use-modal'
import { PackageType } from '@models/package'
import { useParams } from 'react-router-dom'
import { ButtonWithIcon } from '@components/button-with-icon'

interface Params {
  type: PackageType
}

export const PackageCreateButton: React.FC = () => {
  const { type } = useParams<'type'>() as Params

  const [toggleModal] = useModal('PackageCreateModal', { type })

  return (
    <ButtonWithIcon
      icon="mdi mdi-plus-circle mr-1"
      text="Sprzedaj pakiet firmowy"
      handleClick={toggleModal}
      color="green-dark"
    />
  )
}
