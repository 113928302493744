import * as React from 'react'
import { Input } from 'reactstrap'
import { useFormContext } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'
import { extractInnerRef } from '@helpers/forms'
import { ShopTicket } from '@modules/shop/pos/models'
import { asDecimal, handleEnterPress } from '@helpers/utils'
import { ShopPOSWithParamsFormInputs } from '@modules/shop/pos/shop-pos-with-params'
import { useDeferredValue } from 'react'

const MAXIMUM_TICKET_AMOUNT = 99_999_999

interface ShopPurchaseAddTicketInputRowProps {
  onCancel: () => void
  append: (ticket: ShopTicket) => void
}

const ticketValidationMessage = 'Nieprawidłowa wartość'

export const ShopPurchaseAddTicketInputRow: React.FC<ShopPurchaseAddTicketInputRowProps> = ({ append, onCancel }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
  } = useFormContext<ShopPOSWithParamsFormInputs>()

  const onSubmit = (payload: ShopPOSWithParamsFormInputs) => {
    append({ value: payload.ticket, id: uuidv4() })

    onCancel()
  }

  const ticket = useDeferredValue(getValues('ticket'))

  const handleTicketAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChangeAvailable = asDecimal(event.target.value).lte(MAXIMUM_TICKET_AMOUNT)

    setValue('ticket', isChangeAvailable ? parseInt(event.target.value, 10) : ticket)
  }

  return (
    <tr>
      <td colSpan={6} className="align-center">
        <div className="d-flex align-items-center justify-content-end">
          <span className="d-inline-block font-weight-bold">Wartość ticketu:</span>
          <Input
            type="number"
            step={1}
            min={0}
            {...extractInnerRef(
              register('ticket', {
                valueAsNumber: true,
                required: {
                  value: true,
                  message: ticketValidationMessage,
                },
                min: {
                  value: 1,
                  message: ticketValidationMessage,
                },
                onChange: handleTicketAmountChange,
              }),
            )}
            className="shop-purchase__summary__add-ticket__input mx-2"
            onKeyDown={handleEnterPress.bind(handleSubmit(onSubmit))}
          />
          <i
            className="uil-check font-18 text-success cursor-pointer"
            onClick={handleSubmit(onSubmit)}
            data-testid="add-ticket"
          />
          <i className="uil-times font-18 text-danger cursor-pointer" onClick={onCancel} />
        </div>
        {errors.ticket && <p className="text-danger font-12 text-right my-1 mr-4 pr-2">{errors.ticket.message}</p>}
      </td>
    </tr>
  )
}
