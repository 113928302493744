import {
  NewFeedingCalculationDetails,
  NewFeedingKind,
} from '@modules/reception/checkin/step-feeding/new-feeding/models'
import { getIntValue, groupByField } from '@helpers/utils'
import { endOfDay, isAfter, isToday, startOfToday } from 'date-fns'
import { parseISODate } from '@helpers/date-helper'

export const FEEDING_BESTSELLER_MEAL_AMOUNT = 5
export const FeedingInputNameSeparator = '__'
export const BookingStatusesWithAvailableFeedingEdition = ['initial', 'confirmed', 'unfinished']

export const createNewFeedingInputName = (feedingCalculations: NewFeedingCalculationDetails) =>
  `${feedingCalculations.booking_guest}${FeedingInputNameSeparator}${feedingCalculations.date}${FeedingInputNameSeparator}${feedingCalculations.kind}`

export const createDefaultNewFeedingFormValues = (feedingCalculations: NewFeedingCalculationDetails[]) =>
  feedingCalculations.reduce(
    (previousValue, currentValue) => ({
      ...previousValue,
      [createNewFeedingInputName(currentValue)]: currentValue.checked,
    }),
    {},
  )

export const getCalculationDetailsWithoutEdgeMeals = (
  calculationDetails: NewFeedingCalculationDetails[],
  exposedEdgeFeedings: NewFeedingCalculationDetails[],
  kind?: NewFeedingKind,
) =>
  Object.values(groupByField(calculationDetails, 'booking_guest')).reduce(
    (feedingCalculations, meals) => [
      ...feedingCalculations,
      ...meals.filter((detail, index) => {
        const exposedEdgeMeal = exposedEdgeFeedings.find(
          meal => createNewFeedingInputName(meal) === createNewFeedingInputName(detail),
        )

        const isFirstMeal = index === 0
        const isLastMeal = index === meals.length - 1

        if ((kind ?? detail.kind) === 'breakfast') return !isFirstMeal || (isFirstMeal && exposedEdgeMeal)
        if ((kind ?? detail.kind) === 'dinner') return !isLastMeal || (isLastMeal && exposedEdgeMeal)
      }),
    ],
    [],
  )

export const getSelectedMealsAmount = (calculationDetails: NewFeedingCalculationDetails[]) =>
  calculationDetails.reduce(
    (prev, current) => {
      if (current.kind === 'breakfast' && current.checked)
        return { ...prev, breakfastsAmount: prev.breakfastsAmount + 1 }
      if (current.kind === 'dinner' && current.checked) return { ...prev, dinnerAmounts: prev.dinnerAmounts + 1 }

      return prev
    },
    { breakfastsAmount: 0, dinnerAmounts: 0 },
  )

export const getMealsAmount = (
  calculationDetails: NewFeedingCalculationDetails[],
  exposedEdgeMeals: NewFeedingCalculationDetails[],
) =>
  Object.values(groupByField(calculationDetails, 'booking_guest')).reduce(
    (prev, meals) => {
      const guestMeals = meals.reduce(
        (previousValue, currentValue, currentIndex) => {
          const exposedEdgeMeal = exposedEdgeMeals.find(
            meal => createNewFeedingInputName(meal) === createNewFeedingInputName(currentValue),
          )

          if (
            isMealUpcoming(currentValue) &&
            currentValue.kind === 'breakfast' &&
            (currentIndex !== 0 || (currentIndex === 0 && !!exposedEdgeMeal))
          )
            return { ...previousValue, breakfastAmount: previousValue.breakfastAmount + 1 }
          if (
            isMealUpcoming(currentValue) &&
            currentValue.kind === 'dinner' &&
            (currentIndex !== meals.length - 1 || (currentIndex === meals.length - 1 && !!exposedEdgeMeal))
          )
            return { ...previousValue, dinnerAmount: previousValue.dinnerAmount + 1 }
          return previousValue
        },
        { breakfastAmount: 0, dinnerAmount: 0 },
      )

      return {
        breakfastAmount: prev.breakfastAmount + guestMeals.breakfastAmount,
        dinnerAmount: prev.dinnerAmount + guestMeals.dinnerAmount,
      }
    },
    { breakfastAmount: 0, dinnerAmount: 0 },
  )

export const createFeedingPayload = (
  booking_guest: string | number,
  date: string,
  kind: NewFeedingKind | string,
): SelectedFeedingReturn => ({
  booking_guest: getIntValue(booking_guest),
  date,
  kind: kind as NewFeedingKind,
})

interface ExtractedFeedingFromKey extends SelectedFeedingReturn {
  checked: boolean
}

const extractFeedingPayloadFromFormKeys = (feedings): ExtractedFeedingFromKey[] =>
  Object.entries(feedings).reduce((extractedFeedings, feeding) => {
    const [key, checked] = feeding
    const [booking_guest, date, kind] = key.split(FeedingInputNameSeparator)
    return [...extractedFeedings, { ...createFeedingPayload(booking_guest, date, kind), checked: !!checked }]
  }, [])

interface SelectedFeedingReturn {
  booking_guest: number
  date: string
  kind: NewFeedingKind
}

export const getSelectedFeedings = (feedings: { [key: string]: boolean }): SelectedFeedingReturn[] =>
  extractFeedingPayloadFromFormKeys(feedings).reduce(
    (selectedFeedings, feeding) =>
      feeding.checked
        ? [...selectedFeedings, createFeedingPayload(feeding.booking_guest, feeding.date, feeding.kind)]
        : selectedFeedings,
    [],
  )

export interface NewFeedingChanges {
  add_feedings: SelectedFeedingReturn[]
  delete_feedings: SelectedFeedingReturn[]
}

export const getFeedingChanges = (
  feedings,
  initialFeedingDetails: NewFeedingCalculationDetails[],
): NewFeedingChanges => {
  const feedingPayload = extractFeedingPayloadFromFormKeys(feedings)

  return feedingPayload.reduce(
    (previousValue, feeding) => {
      const initialFeeding = initialFeedingDetails.find(
        initialFeedingElement =>
          initialFeedingElement.date === feeding.date &&
          initialFeedingElement.booking_guest === feeding.booking_guest &&
          initialFeedingElement.kind === feeding.kind,
      )

      if (!initialFeeding || initialFeeding.checked === feeding.checked) {
        return previousValue
      }

      if (initialFeeding.checked && !feeding.checked) {
        return {
          ...previousValue,
          delete_feedings: [
            ...previousValue.delete_feedings,
            createFeedingPayload(feeding.booking_guest, feeding.date, feeding.kind),
          ],
        }
      }

      if (!initialFeeding.checked && feeding.checked) {
        return {
          ...previousValue,
          add_feedings: [
            ...previousValue.add_feedings,
            createFeedingPayload(feeding.booking_guest, feeding.date, feeding.kind),
          ],
        }
      }

      return previousValue
    },
    { add_feedings: [], delete_feedings: [] },
  )
}

export const getFeedingCounts = (
  feedingCalculations: NewFeedingCalculationDetails[],
  exposedEdgeMeals: NewFeedingCalculationDetails[],
  addedFeedings,
) =>
  feedingCalculations.reduce(
    (previousValue, currentValue, currentIndex) => {
      const exposedEdgeMeal = exposedEdgeMeals.find(
        meal => createNewFeedingInputName(meal) === createNewFeedingInputName(currentValue),
      )

      const formValue = addedFeedings[createNewFeedingInputName(currentValue)]

      const isLastElement = currentIndex === feedingCalculations.length - 1
      const isFirstElement = currentIndex === 0

      return {
        ...previousValue,
        [currentValue.kind]: {
          available:
            (currentValue.kind === 'breakfast' && (!isFirstElement || (isFirstElement && !!exposedEdgeMeal))) ||
            (currentValue.kind === 'dinner' && (!isLastElement || (isLastElement && !!exposedEdgeMeal)))
              ? previousValue[currentValue.kind].available + 1
              : previousValue[currentValue.kind].available,
          added: previousValue[currentValue.kind].added + (formValue ? 1 : 0),
        },
      }
    },
    { breakfast: { added: 0, available: 0 }, dinner: { added: 0, available: 0 } },
  )

export const isFeedingBestsellerFulfilled = (
  feedingCalculations: NewFeedingCalculationDetails[],
  addedFeedings: any,
) => {
  const { breakfast, dinner } = getFeedingCounts(feedingCalculations, [], addedFeedings)

  const breakfastCount = Math.min(breakfast.added, FEEDING_BESTSELLER_MEAL_AMOUNT)
  const dinnerCount = Math.min(dinner.added, FEEDING_BESTSELLER_MEAL_AMOUNT)

  const isFulfilled =
    breakfastCount === FEEDING_BESTSELLER_MEAL_AMOUNT && dinnerCount === FEEDING_BESTSELLER_MEAL_AMOUNT

  return {
    breakfastCount,
    dinnerCount,
    isFulfilled,
  }
}

export const isEmptyFeedingState = (initialFeedingCalculations: NewFeedingCalculationDetails[]): boolean =>
  !initialFeedingCalculations.some((feedingCalculation: NewFeedingCalculationDetails) => feedingCalculation.checked)

export const getFeedingKindAvailability = (feedingCalculationDetails: NewFeedingCalculationDetails[]) =>
  feedingCalculationDetails.reduce(
    (prev, calculationDetail) => {
      if (!isMealUpcoming(calculationDetail)) return prev

      if (calculationDetail.kind === 'breakfast') {
        return { ...prev, isBreakfastAvailable: prev.isBreakfastAvailable || calculationDetail.available }
      }

      if (calculationDetail.kind === 'dinner') {
        return { ...prev, isDinnerAvailable: prev.isDinnerAvailable || calculationDetail.available }
      }

      return prev
    },
    { isBreakfastAvailable: false, isDinnerAvailable: false },
  )

export const isMealUneaten = (calculation: NewFeedingCalculationDetails) => {
  if (!calculation.checked || calculation.used) return false

  const now = new Date()
  const mealDate = parseISODate(calculation.date) as Date

  return isAfter(now, endOfDay(mealDate))
}

export const isMealUpcoming = (calculation: NewFeedingCalculationDetails) => {
  const mealDate = parseISODate(calculation.date) as Date
  const today = startOfToday()

  return isAfter(mealDate, today) || isToday(mealDate)
}
