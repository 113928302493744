import * as React from 'react'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { getHousekeepingCleaningOrders } from '@store/actions/housekeeping-actions'
import { HousekeepingCleaningOrderType } from '@modules/housekeeping/models'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { HousekeepingCleaningOrdersCleaningTable } from '@modules/housekeeping/cleaning-orders/cleaning/table'
import { useApiRequest } from '@components/hooks/use-api-request'
import { cleaningOrdersSelector } from '@store/slices/housekeeping-slice'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { FormProvider, useForm } from 'react-hook-form'
import { HousekeepingCleaningOrdersCleaningFilters } from '@modules/housekeeping/cleaning-orders/cleaning/filters'
import { useHousekeepingTableFilters } from '@modules/housekeeping/hooks/use-housekeeping-table-filters'
import { searchCleaningOrders, filterSelectedWorkers } from '@modules/housekeeping/cleaning-orders/common/filtering'
import { useHousekeepingCleaningCompanies } from '@modules/housekeeping/users/use-housekeeping-cleaning-companies'
import { TableViewType } from '@modules/technical-orders/common/table-view-switch'

export interface HousekeepingCleaningOrdersCleaningFiltersParams {
  service_kind: CustomReactSelectOption | null
  local: CustomReactSelectOption | null
  kind: CustomReactSelectOption | null
  user: CustomReactSelectOption | null
  search: string
  is_vip: false
  early_check_in: false
  extend_stay: false
}

const defaultFilters: HousekeepingCleaningOrdersCleaningFiltersParams = {
  service_kind: null,
  user: null,
  local: null,
  kind: null,
  search: '',
  is_vip: false,
  early_check_in: false,
  extend_stay: false,
}

interface Props {
  resortId: number
}

export const HousekeepingCleaningOrdersCleaning: React.FC<Props> = ({ resortId }) => {
  const [tableView, setTableView] = React.useState<TableViewType>('list')
  const cleaningOrders = useAppSelector(cleaningOrdersSelector)
  const workers = useAppSelector((state: RootState) => state.houseKeepingState.cleaningWorkers)

  const [filters, setFilters] = React.useState<HousekeepingCleaningOrdersCleaningFiltersParams>(defaultFilters)

  const { getFiltered, getSorted } = useHousekeepingTableFilters()
  const housekeeping_companies = useHousekeepingCleaningCompanies(resortId)

  const dispatch = useAppDispatch()

  const methods = useForm<HousekeepingCleaningOrdersCleaningFiltersParams>({
    defaultValues: filters,
  })

  const { isLoading, action: fetchHousekeepingCleaningOrders } = useApiRequest(
    async () =>
      await dispatch(getHousekeepingCleaningOrders([{ resort: resortId }, HousekeepingCleaningOrderType.CLEANING])),
  )

  React.useEffect(() => {
    fetchHousekeepingCleaningOrders()
  }, [resortId])

  const filteredCleaningOrders = searchCleaningOrders(
    getSorted(
      getFiltered(
        cleaningOrders.filter(row => row.status === 'CLEANING'),
        filters,
        filterSelectedWorkers(filters),
      ),
    ),
    filters.search,
    workers,
    housekeeping_companies,
  )

  return (
    <LoaderPlaceholder content={!isLoading}>
      <FormProvider {...methods}>
        <HousekeepingCleaningOrdersCleaningFilters
          tableView={tableView}
          onTableViewChange={setTableView}
          setFilters={setFilters}
          filters={filters}
          defaultFilters={defaultFilters}
        />
        <HousekeepingCleaningOrdersCleaningTable
          resortId={resortId}
          cleaningOrders={filteredCleaningOrders}
          tableView={tableView}
        />
      </FormProvider>
    </LoaderPlaceholder>
  )
}
