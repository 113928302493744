import * as React from 'react'
import { ColAuto } from '@hyper/col-auto'
import { ButtonWithIcon } from '@components/button-with-icon'
import { useModal } from '@components/modals/use-modal'

export const CrmClientsFiltersCreateClientButton: React.FC = () => {
  const [handleClick] = useModal('ClientCreateModal')

  return (
    <ColAuto className="ml-auto col-auto">
      <label className="d-block">&nbsp;</label>
      <ButtonWithIcon
        icon="uil-plus mr-1"
        text="Dodaj gościa"
        btnClass="btn btn-green"
        handleClick={handleClick}
        role="create-guest"
      />
    </ColAuto>
  )
}
