import * as React from 'react'
import { ReceptionBooking } from '@models/reception'
import { ReceptionNotificationSmsIcon } from '@modules/reception/common/reception-notification-sms-icon'
import { ReceptionNotificationEmailIcon } from '@modules/reception/common/reception-notification-email-icon'
import { useModal } from '@components/modals/use-modal'

interface ReceptionRowNameProps {
  booking: ReceptionBooking
}

export const ReceptionRowName: React.FC<ReceptionRowNameProps> = ({ booking }) => {
  const [handleClick] = useModal('ReceptionBookingDetailsModal', {
    booking,
  })

  return (
    <>
      <strong className="d-block mb-1">
        {booking.name}
        <i className="uil-eye cursor-pointer font-16 text-muted ml-1" onClick={handleClick} />
      </strong>
      {booking.phone}

      <ReceptionNotificationSmsIcon booking={booking} />
      <ReceptionNotificationEmailIcon booking={booking} />
    </>
  )
}
