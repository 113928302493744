import * as React from 'react'
import * as classnames from 'classnames'
import { Col, Row } from 'reactstrap'
import { NewFeedingCalculationPriceItem } from '@modules/reception/checkin/step-feeding/new-feeding/models'
import { ReceptionBookingNewFeedingPrice } from '@modules/reception/checkin/step-feeding/new-feeding/reception-booking-new-price'

interface Props {
  priceItem: NewFeedingCalculationPriceItem
  isDiscountPreviewActive: boolean
}

export const ReceptionBookingNewFeedingSummaryPriceItem = ({
  priceItem,
  isDiscountPreviewActive,
}: Props): JSX.Element => (
  <li className="d-flex justify-content-between font-12" key={priceItem.keyword}>
    <Row className="w-100 align-items-center mx-0 mt-1">
      <Col md={4} className="pr-0">
        {priceItem.name}{' '}
      </Col>
      <Col md={2}>
        {priceItem.is_hb && <span className="badge badge-light font-12 px-2 text-default bg-platinum">Bestseller</span>}
      </Col>
      <Col className={classnames('text-right pr-0', { 'font-weight-bold': priceItem.is_hb })}>
        <ReceptionBookingNewFeedingPrice
          price={isDiscountPreviewActive ? priceItem.discount_amount : priceItem.price_brutto}
        />
      </Col>
    </Row>
  </li>
)
