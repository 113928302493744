import * as React from 'react'
import Table from '@components/table/table'
import { SubscriptionContractPeriod, SubscriptionContractPeriodInvoice } from '@modules/subscription-contract/models'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import classNames from 'classnames'

interface Props {
  period: SubscriptionContractPeriod
}

export const PaymentTableRowPayment = ({ period }: Props): JSX.Element => (
  <Table.Cell className="vertical-align-middle">
    {['completed', 'unknown'].includes(period.payment_status) ? (
      <ul className="list-unstyled p-0 m-0">
        {period.invoices.map((invoice: SubscriptionContractPeriodInvoice) => (
          <li className="mb-1" key={invoice.id}>
            {toDefaultDateTimeFormat(invoice.created_at)}
          </li>
        ))}
      </ul>
    ) : (
      <span
        className={classNames('badge', {
          'badge-success-lighten': period.payment_status === 'completed',
          'badge-danger-lighten': period.payment_status === 'rejected',
          'badge-warning-lighten': period.payment_status === 'ordered',
        })}
      >
        {period.payment_status_display}
      </span>
    )}
  </Table.Cell>
)
