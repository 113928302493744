import * as React from 'react'
import { withTooltip } from '@components/with-tooltip'
import { IconWithText, Direction } from '@components/icon-with-text'

interface IconWithTooltipProps {
  icon: string
  tooltipMessage: string | React.ReactElement
  tooltipId: string
  color?: string
  tooltipPlacement?: string
  tooltipDisabled?: boolean
  className?: string
  children?: React.ReactNode
  role?: React.AriaRole
  direction?: Direction
}

export const IconWithTooltip: React.FC<IconWithTooltipProps> = ({
  icon,
  tooltipMessage,
  tooltipPlacement = 'bottom',
  tooltipDisabled,
  tooltipId,
  children,
  color,
  className,
  role,
  direction = 'left',
}) => {
  const customIcon = () => (
    <IconWithText
      icon={`${color} ${icon}`}
      text={children}
      wrapperClassNames={className}
      role={role}
      direction={direction}
    />
  )

  const EnhancedIcon = withTooltip()(customIcon)

  return (
    <EnhancedIcon
      tooltipId={tooltipId}
      tooltipMessage={tooltipMessage}
      tooltipPlacement={tooltipPlacement}
      tooltipDisabled={tooltipDisabled}
    />
  )
}
