import * as React from 'react'
import { ClientDetails } from '@models/clients'
import { FormInput } from '@hyper/forms'
import { useForm } from 'react-hook-form'
import { Col, Row } from 'reactstrap'
import { useFormRequest } from '@components/hooks/use-api-request'
import { SaveButton } from '@hyper/button'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { updateClientDetails } from '@store/actions/crm-actions'
import { commonObjectPatch } from '@store/actions/generic-actions'
import * as R from 'ramda'
import { languageList, languageType } from '@models/cms'
import { FormSelect } from '@hyper/forms/form-select'
import { useCountries } from '@components/hooks/use-countries'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { AlertDanger } from '@components/alerts'
import { Form } from '@hyper/forms/form'

interface Props {
  client: ClientDetails
  toggleEditMode: () => void
}

interface FormInputs {
  first_name: string
  last_name: string
  phone: string
  email: string
  street: string
  city: string
  country: CustomReactSelectOption
  postcode: string
  language: languageType
}

export const ClientDetailsModalClientProfilePersonalDataForm: React.FC<Props> = ({ toggleEditMode, client }) => {
  const { countriesOptions, defaultCountryOption } = useCountries(client.profile.country)

  const methods = useForm<FormInputs>({
    defaultValues: {
      ...R.pick<FormInputs, any>(
        ['postcode', 'country', 'city', 'street', 'email', 'phone', 'last_name', 'first_name'],
        {
          ...client.profile,
          ...client,
        },
      ),
      country: defaultCountryOption,
    },
  })
  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    dispatch(
      updateClientDetails(
        await commonObjectPatch<ClientDetails>(client.profile.urls.details, {
          ...payload,
          country: payload.country.value,
          phone: payload.phone ?? '',
        }),
      ),
    )
    addSuccessNotification('Zmiany zostały zapisane')
    toggleEditMode()
  }, methods.setError)

  return (
    <Form className="form" methods={methods} onSubmit={onSubmit}>
      <Row>
        <FormInput
          labelProps={{ className: 'col-md-4' }}
          formGroupClassName="align-items-center row"
          inputClassName="col-md-8"
          label="Imię"
          name="first_name"
        />
        <FormInput
          labelProps={{ className: 'col-md-4' }}
          formGroupClassName="align-items-center row"
          inputClassName="col-md-8"
          label="Nazwisko"
          name="last_name"
        />
        <FormInput
          labelProps={{ className: 'col-md-4' }}
          formGroupClassName="align-items-center row"
          inputClassName="col-md-8"
          label="Telefon"
          name="phone"
        />
        <FormInput
          labelProps={{ className: 'col-md-4' }}
          formGroupClassName="align-items-center row"
          inputClassName="col-md-8"
          label="E-mail"
          name="email"
          disabled={!client.can_change_email}
        />

        {!client.can_change_email && (
          <AlertDanger
            className="p-1 col-8 ml-auto align-items-center"
            iconClassName="font-11 lh-initial"
            wrapperClassName="lh-initial"
          >
            <p className="font-10 mb-0 lh-initial">Nie masz uprawnień, aby edytować adres email tego użytkownika.</p>
          </AlertDanger>
        )}

        <FormInput
          labelProps={{ className: 'col-md-4' }}
          formGroupClassName="align-items-center row"
          inputClassName="col-md-8"
          label="Adres"
          name="street"
        />
        <Col md={4} />
        <FormInput colSize={3} colClassName="px-0" name="postcode" />
        <FormInput colSize={5} colClassName="pr-0" name="city" />
        <Col md={4} />
        <FormSelect options={countriesOptions} name="country" formPlainProps={{ colSize: 8, colClassName: 'px-0' }} />
        <FormInput
          labelProps={{ className: 'col-md-4' }}
          formGroupClassName="align-items-center row"
          inputClassName="col-md-8"
          label="Język gościa"
          name="language"
          type="select"
        >
          {languageList.map(row => (
            <option value={row.value} key={row.value}>
              {row.label}
            </option>
          ))}
        </FormInput>
      </Row>
      <Row>
        <Col sm={12} className="p-0">
          <div className="hr hr-grey mb-3" />
        </Col>
      </Row>
      <Row>
        <Col md={12} className="text-right px-0">
          <button type="button" className="btn btn-light mr-1" onClick={toggleEditMode}>
            Anuluj
          </button>
          <SaveButton isSaving={isLoading} label="Zapisz dane" className="btn btn-green" />
        </Col>
      </Row>
    </Form>
  )
}
