import * as React from 'react'
import { IconWithTooltip } from '@components/icon-with-tooltip'
import { SaveButton } from '@hyper/button'
import { useFormRequest } from '@components/hooks/use-api-request'
import { commonObjectPost } from '@store/actions/generic-actions'
import { PackageWholesaleDetails } from '@modules/package-wholesale/models'
import { useFormContext } from 'react-hook-form'
import { MassNotificationsModalFormInputs } from '@modules/package-wholesale/details/notification/mass-notifications/mass-notifications-modal'
import { useNotificationHook } from '@hyper/use-notification-hook'

interface Props {
  notificationTestUrl: string
}

export const PackageWholesaleDetailsMassNotificationsModalPreviewButton = ({
  notificationTestUrl,
}: Props): JSX.Element => {
  const methods = useFormContext<MassNotificationsModalFormInputs>()

  const { addInfoNotification } = useNotificationHook()

  const { isLoading: isSendingPreviews, action: sendPreviewEmail } = useFormRequest(async () => {
    const { subscriptions, content, subject } = methods.getValues()
    methods.clearErrors()

    await commonObjectPost<PackageWholesaleDetails>(notificationTestUrl, {
      subscriptions,
      content,
      subject,
    })

    addInfoNotification('Wiadomość została wysłana na Twój adres email.')
  }, methods.setError)

  return (
    <SaveButton
      isSaving={isSendingPreviews}
      labelSaving="Wysyłanie..."
      onClick={sendPreviewEmail}
      className="btn btn-light"
      label={
        <>
          <IconWithTooltip
            icon="uil-exclamation-circle text-secondary font-15"
            tooltipPlacement="top"
            tooltipMessage={
              <span className="p-1 font-weight-semibold font-11">
                Pzegląd wiadomości zostanie wysłany na Twój adres email.
              </span>
            }
            tooltipId="email-preview"
            color="primary"
          />
          <span className="ml-1">Wyślij testowo</span>
        </>
      }
    />
  )
}
