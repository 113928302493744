import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { Button, ModalFooter, ModalHeader } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { BaseModalProps } from '@components/modals/types'
import { ReceptionBookingCartSummaryBox } from '@modules/reception/cart/reception-booking-cart-summary-box'
import { ReceptionBookingCartPaymentBox } from '@modules/reception/cart/payment-box/reception-booking-cart-payment-box'
import { ContentLoader } from '@components/content-loader'
import { useReceptionBookingCart } from '@modules/reception/cart/use-cart'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { CartContextProvider } from '@modules/reception/cart/cart-context'
import { SaveButton } from '@hyper/button'
import { IconWithText } from '@components/icon-with-text'
import { useApiRequest } from '@components/hooks/use-api-request'
import {
  BaseCartPaymentResponse,
  CartPaymentMethod,
  ReceptionBookingCartFormInputs,
} from '@modules/reception/cart/models'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useTimeoutWhen } from 'rooks'

interface Props extends BaseModalProps {
  bookingDetails: ReceptionBookingDetails
  onCartFinalization?: () => void
}

export const ReceptionBookingCartModal: React.FC<Props> = ({ bookingDetails, toggleIsVisible, onCartFinalization }) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState<CartPaymentMethod | null>(null)
  const { cart, isLoading, setCart } = useReceptionBookingCart(bookingDetails)

  const [isSaleCompleted, setIsSaleCompleted] = React.useState(false)
  useTimeoutWhen(() => setIsSaleCompleted(false), 5000, isSaleCompleted)

  const methods = useForm<ReceptionBookingCartFormInputs>()
  const formCartItems = useWatch({ control: methods.control }) as ReceptionBookingCartFormInputs

  const { action: handleCartFinalization, isLoading: isCartFinalizing } = useApiRequest(async () => {
    if (!cart) return
    const { cart_details } = await commonObjectPost<BaseCartPaymentResponse>(cart.urls.finalize)
    setCart(cart_details)
    setIsSaleCompleted(true)
    onCartFinalization?.()
  })

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>
        <span className="text-semi-strong">Koszyk zakupowy dla rezerwacji</span>
        <strong>#{bookingDetails.reservation_number}</strong>
      </ModalHeader>
      <ModalBody>
        <ContentLoader isLoading={isLoading}>
          {cart && (
            <CartContextProvider>
              <div className="row">
                <FormProvider {...methods}>
                  <ReceptionBookingCartSummaryBox
                    isSaleCompleted={isSaleCompleted}
                    cart={cart}
                    setCart={setCart}
                    isPaymentSelected={!!selectedPaymentMethod}
                    bookingDetails={bookingDetails}
                  />
                </FormProvider>
                <ReceptionBookingCartPaymentBox
                  bookingDetails={bookingDetails}
                  cart={cart}
                  formCartItems={formCartItems}
                  onPaymentMethodChange={setSelectedPaymentMethod}
                  onCartUpdate={setCart}
                  selectedPaymentMethod={selectedPaymentMethod}
                />
              </div>
            </CartContextProvider>
          )}
        </ContentLoader>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <Button color="light" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton
          className="btn btn-success"
          labelSaving="Proszę czekać..."
          onClick={handleCartFinalization}
          isSaving={isCartFinalizing}
          disabled={!cart?.items.length || !!selectedPaymentMethod}
          label={<IconWithText icon="uil-check font-15" text="Finalizuj płatność" />}
        />
      </ModalFooter>
    </>
  )
}
