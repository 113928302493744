import * as React from 'react'
import { CleaningOrder } from '@modules/housekeeping/models'
import { HousekeepingCleaningOrderToOrderRow } from '@modules/housekeeping/cleaning-orders/to-order/row'
import { CustomInput } from 'reactstrap'
import { FormProvider, UseFormReturn } from 'react-hook-form'
import { HousekeepingToOrderFiltersParams } from '@modules/housekeeping/cleaning-orders/to-order/index'
import { HousekeepingOrderTableHeaderLocal } from '@modules/housekeeping/common/table-headers/table-header-local'
import { HousekeepingOrderTableHeaderKind } from '@modules/housekeeping/common/table-headers/table-header-kind'
import { HousekeepingOrderTableHeaderServiceKind } from '@modules/housekeeping/common/table-headers/table-header-service-kind'
import { HousekeepingNoOrdersRow } from '@modules/housekeeping/cleaning-orders/common/no-orders'

interface Props {
  cleaningOrders: CleaningOrder[]
  selectedCleaningOrders: CleaningOrder[]
  methods: UseFormReturn<HousekeepingToOrderFiltersParams, unknown>
  setSelectedCleaningOrders: (cleaningOrders: CleaningOrder[]) => void
}

export const HousekeepingCleaningOrdersToOrderTable: React.FC<Props> = ({
  cleaningOrders,
  setSelectedCleaningOrders,
  methods,
  selectedCleaningOrders,
}) => {
  const toggleSelectedCleaningOrder = (cleaningOrder: CleaningOrder, add: boolean) => {
    if (!add) {
      setSelectedCleaningOrders(selectedCleaningOrders.filter(row => row.id !== cleaningOrder.id))
    } else {
      setSelectedCleaningOrders([...selectedCleaningOrders, cleaningOrder])
    }
  }

  const ordersAllChecked = selectedCleaningOrders.length === cleaningOrders.length && cleaningOrders.length > 0

  const toggleSelectAllOrders = () => setSelectedCleaningOrders(ordersAllChecked ? [] : [...cleaningOrders])

  return (
    <table className="housekeeping__orders_table table table-striped">
      <colgroup>
        <col width="1%" />
        <col width="15%" />
        <col width="19%" />
        <col width="10%" />
        <col width="25%" />
        <col width="305px" />
      </colgroup>
      <thead>
        <FormProvider {...methods}>
          <tr>
            <th style={{ width: 10 }} className="border-top-0">
              <CustomInput
                id="toggle-all"
                type="checkbox"
                className="font-18"
                name="toggle-all"
                checked={ordersAllChecked}
                onChange={toggleSelectAllOrders}
              />
            </th>
            <HousekeepingOrderTableHeaderLocal />
            <HousekeepingOrderTableHeaderKind />
            <th className="border-top-0">Deklarowany przyjazd</th>
            <HousekeepingOrderTableHeaderServiceKind />
            <th className="border-top-0">Najbliższa rezerwacja</th>
            <th className="border-top-0" />
          </tr>
        </FormProvider>
      </thead>
      <tbody>
        {!cleaningOrders.length ? (
          <HousekeepingNoOrdersRow colSpan={9} />
        ) : (
          <>
            {cleaningOrders.map(cleaningOrder => (
              <HousekeepingCleaningOrderToOrderRow
                isChecked={selectedCleaningOrders.includes(cleaningOrder)}
                toggleSelectedCleaningOrder={toggleSelectedCleaningOrder}
                cleaningOrder={cleaningOrder}
                key={cleaningOrder.id}
              />
            ))}
          </>
        )}
      </tbody>
    </table>
  )
}
