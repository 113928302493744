import { CustomReactSelectOption } from '@components/custom-react-select'

export interface CmsImprovementGroup {
  id: number
  name: string
  subtitle: string
}

export interface CmsAppData {
  status: 'unknown' | 'ready' | 'loading'
  improvement_groups: CmsImprovementGroup[]
}

export type languageType = 'en' | 'pl' | 'de'

export const languageList: CustomReactSelectOption[] = [
  { value: 'pl', label: 'Polski' },
  { value: 'en', label: 'Angielski' },
  { value: 'de', label: 'Niemiecki' },
]
