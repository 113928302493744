import * as React from 'react'
import classNames from 'classnames'
import { useFormContext } from 'react-hook-form'

interface Props {
  index: number
  remove: (index: number) => void
}

export const CmsReservationAppAccommodationTypeAttributesRow: React.FC<Props> = ({ remove, index }) => {
  const handleRemove = React.useCallback(() => remove(index), [remove, index])

  const { watch, register } = useFormContext()

  const icon = watch(`attributes.${index}.key`)

  return (
    <>
      <tr>
        <td style={{ width: '10%' }} className="valign-middle text-center">
          <i className={classNames('font-18', icon)} />
        </td>
        <td style={{ width: '40%' }} className="valign-middle">
          <input type="text" {...register(`attributes.${index}.key`)} className="form-control form-control-sm" />
        </td>
        <td style={{ width: '40%' }} className="valign-middle">
          <input type="text" {...register(`attributes.${index}.value`)} className="form-control form-control-sm" />
        </td>
        <td style={{ width: '10%' }} className="text-center valign-middle">
          <button className="btn btn-danger btn-xs" onClick={handleRemove} type="button">
            <i className="uil-multiply " />
          </button>
        </td>
      </tr>
      <tr>
        <td className="border-top-0" />
        <td colSpan={2} className="border-top-0">
          <textarea
            {...register(`attributes.${index}.description`)}
            className="form-control form-control-sm width-100"
            rows={1}
            placeholder="Opis do tooltipu"
          />
        </td>
        <td className="border-top-0" />
      </tr>
    </>
  )
}
