import { asDecimal } from '@helpers/utils'
import { ImprovementAvailability } from '@models/booking'

export const getVoucherTotalPrice = (
  amount: number,
  subscriptionImprovementAvailability: ImprovementAvailability,
): string => {
  const every_second = Math.floor(amount / 2)
  const every_first = amount ? amount - every_second : 0

  return asDecimal(subscriptionImprovementAvailability.price_brutto_first_item)
    .mul(every_first)
    .add(asDecimal(subscriptionImprovementAvailability.price_brutto).mul(every_second))
    .toString()
}
