import * as React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { resetBookingModalData, setBookingModalWarning } from '@store/actions/timeline-actions'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'

const TimelineWarningBookingModal: React.FC = () => {
  const [isOpen, setOpen] = React.useState(false)
  const dispatch = useAppDispatch()
  const modalDataWarning = useAppSelector((state: RootState) => state.timelineState.bookingModalDataWarning)

  React.useEffect(() => {
    setOpen(modalDataWarning)
  }, [modalDataWarning])

  const toggleModal = () => {
    if (isOpen) {
      dismiss()
    }
    setOpen(!isOpen)
  }

  const dismiss = async (): Promise<void> => {
    await dispatch(resetBookingModalData())
    await dispatch(setBookingModalWarning(false))
  }

  const proceed = async (): Promise<void> => {
    await dispatch(setBookingModalWarning(false))
  }

  return (
    <Modal
      size="sm"
      isOpen={isOpen}
      toggle={toggleModal}
      contentClassName="calendar-warning-modal__wrapper"
      className="calendar-warning-modal"
    >
      <ModalBody>
        <object
          data={require('@assets/images/warning-icon.svg')}
          type="image/svg+xml"
          className="calendar-warning-modal__icon"
        >
          Ostrzeżenie
        </object>
        <header className="calendar-warning-modal__header">Nie można przenieść wybranej rezerwacji</header>
        <p className="calendar-warning-modal__text">Rezerwacja ma wykupioną opcję płatnego wyboru lokalu</p>
        <div className="calendar-warning-modal__btn-wrapper">
          <button className="btn calendar-warning-modal__btn" onClick={dismiss}>
            Rozumiem
          </button>
          <button className="btn calendar-warning-modal__btn" onClick={proceed}>
            Zmień mimo to
          </button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default TimelineWarningBookingModal
