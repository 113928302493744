import * as React from 'react'
import { useForm } from 'react-hook-form'
import { Button, Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { SaveButton } from '@hyper/button'
import { useApiRequest, useFormRequest } from '@components/hooks/use-api-request'
import { BaseModalProps } from '@components/modals/types'
import {
  commonObjectDelete,
  commonObjectPatch,
  commonObjectPost,
  commonObjectPut,
} from '@store/actions/generic-actions'
import { useModal } from '@components/modals/use-modal'
import { useProductSaleConfirmationModal } from '@components/sale/use-product-sale-confirmation-modal'
import { extractSelectOptionsValues } from '@helpers/utils'
import { AgreementDetails } from '@modules/hr/agreement/models'
import {
  selectAgreementDetails,
  removeAgreement,
  updateAgreementDetails,
  upsertAgreement,
} from '@store/slices/hr-slice'
import { Form } from '@hyper/forms/form'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { ReceptionBookingCheckSteps } from '@modules/reception/common/reception-booking-check-steps'
import { HrAddressForm } from '@modules/hr/common/form/addresses'
import { AgreementCreateFormSummary } from '@modules/hr/agreement/create/steps/summary'
import { AgreementCreateFormJobData } from '@modules/hr/agreement/create/steps/job-data/job-data'
import {
  HrHealthCareAndFinanceData,
  HrHealthcareAndFinanceForm,
} from '@modules/hr/common/form/hr-health-care-and-finance-data'
import { AgreementCreateFormStatements } from '@modules/hr/agreement/create/steps/statements'
import { AGREEMENT_WIZARD_STEP_VALIDATE_URLS } from '@modules/hr/consts'
import { ContentLoader } from '@components/content-loader'
import { UserPermission } from '@models/dashboard'
import { resortOptionsSelector } from '@store/selectors/dashboard'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useAgreementWizardDefaults } from '@modules/hr/agreement/create/use-agreement-wizard-defaults'
import * as R from 'ramda'
import { HrWorkersCreateFormInputs } from '@modules/hr/workers/create/modal'
import { agreementCreateSteps } from '@modules/hr/agreement/create/steps/steps'
import { AgreementFormAlerts } from '@modules/hr/agreement/agreement-form-alerts'
import { AgreementCreateFormWorkerData } from '@modules/hr/agreement/create/steps/base-data'
import { HrWorkerDetails } from '@modules/hr/workers/models'
import { HrAddress } from '@modules/hr/common/models'
import { AGREEMENTS_API_TAG, AGREEMENTS_API_TAG_IDS, agreementsApi } from '@api/agreements'

export interface AgreementFormInputs extends HrWorkersCreateFormInputs {
  //job data
  job_position: CustomReactSelectOption | null
  company: CustomReactSelectOption | null
  agent: CustomReactSelectOption | null
  hourly_rate: string
  resort: CustomReactSelectOption | null
  date_of_start: Date | null
  date_of_expiration: Date | null

  // statements data
  statement_under_employment_contract: string
  statement_under_employment_contract_name: string
  statement_health_insurance: string
  statement_non_agricultural: string
  statement_maternity_leave: string
  statement_maternity_leave_date_from: Date
  statement_maternity_leave_date_to: Date
  statement_student_status: string
  statement_retirement_pension: string
  statement_retirement_pension_number: string
  statement_retirement_pension_organization: string
  statement_disability: string
  statement_disability_level: string
  statement_optional_insurance: string
  statement_foreign: string
  statement_under_employment_lower_salary: string
  statement_over_employment_contract_second: string
  statement_over_employment_contract_second_salary: string

  date_of_conclusion: string
  selectedWorker: HrWorkerDetails | null
  isFormVisible: boolean
}

interface Props extends BaseModalProps {
  agreement?: AgreementDetails
  workerDetails?: HrWorkerDetails
}

export const AgreementCreateModal: React.FC<Props> = ({ toggleIsVisible, agreement, workerDetails }) => {
  const agreementsUrl = useAppSelector((state: RootState) => state.appState.appData.urls.hr.agreements)
  const agreementDetails = useAppSelector(selectAgreementDetails)
  const allResortOptions = useAppSelector(resortOptionsSelector)
  const user = useAuthenticatedUser()
  const dispatch = useAppDispatch()

  const [step, setStep] = React.useState(1)

  const [showAgreementDetails] = useModal('AgreementDetailsModal', null, { persist: true })

  const { showConfirmationPackageCreation } = useProductSaleConfirmationModal({
    title: 'Umowa dodana',
    backText: 'Wróć do listy umów',
    detailsText: 'Zobacz szczegóły umowy',
    content: (
      <>
        Umowa została dodana <br /> do listy umów
      </>
    ),
  })

  const resortOptions = user.hasPerm(UserPermission.HrAgreementCanSelectAnyResort)
    ? allResortOptions
    : (user.resorts.map(userResort =>
        allResortOptions.find(resortOption => resortOption.value === userResort.id),
      ) as CustomReactSelectOption[])

  const { defaultValues } = useAgreementWizardDefaults(agreement)

  const methods = useForm<AgreementFormInputs>({ mode: 'onChange', defaultValues, shouldUnregister: false })

  const handleCreationFinish = () => {
    showConfirmationPackageCreation(() => showAgreementDetails(null, { agreement: agreementDetails }))
    dispatch(upsertAgreement(agreementDetails))
    toggleIsVisible()
  }

  const handlePrevStep = () => {
    setStep(curr => Math.max(1, curr - 1))
    methods.clearErrors()
  }

  const handleNextStep = () => setStep(curr => curr + 1)

  const { isLoading: isValidating, action: validateForm } = useFormRequest(async () => {
    const { resort, ...data } = R.omit(['national_health_fund_branch'], methods.getValues())
    const formData = extractSelectOptionsValues<{}, {}>(data)
    methods.clearErrors()

    let updatedAgreement: AgreementDetails | null = null

    if (step === 1 && !agreement && !agreementDetails) {
      updatedAgreement = await commonObjectPost<AgreementDetails>(agreementsUrl, formData)
    } else {
      updatedAgreement = await commonObjectPut<AgreementDetails>(
        agreementDetails?.urls[AGREEMENT_WIZARD_STEP_VALIDATE_URLS[step]],
        {
          ...formData,
          ...(step === 5 && { status: 'draft' }),
          resorts: resort ? [resort.value] : [],
        },
      )
    }

    dispatch(updateAgreementDetails(updatedAgreement))
    handleNextStep()
  }, methods.setError)

  const { action: removeUnfinishedAgreement, isLoading: isClearingUnfinishedAgreement } = useApiRequest(async () => {
    if (agreement || agreementDetails) {
      const url = agreement?.urls.details || agreementDetails?.urls.details
      const id = agreement?.id || agreementDetails?.id
      await commonObjectDelete(url ?? '')
      dispatch(removeAgreement(id))
      dispatch(updateAgreementDetails(null))
    }

    toggleIsVisible()
  })

  const { action: saveAgreementDraft } = useFormRequest(async () => {
    if (agreementDetails || agreement) {
      const url = agreement?.urls.details || agreementDetails?.urls.details
      const { resort, account_number, ...data } = R.omit(['national_health_fund_branch'], methods.getValues())
      const formData = extractSelectOptionsValues<{}, {}>(data)

      const filledData = R.reject(value => value === null || value === undefined || value === '', formData)

      const details = await commonObjectPatch(url ?? '', {
        ...filledData,
        ...(account_number !== 'PL' && account_number !== '' && { account_number }),
        resorts: resort ? [resort.value] : [],
        save_history: true,
      })

      dispatch(upsertAgreement(details))
    }

    toggleIsVisible()
  }, methods.setError)

  const [showCreationDiscardModal] = useModal(
    'AgreementCreationDiscardModal',
    { onSaveDraft: saveAgreementDraft, onAbort: removeUnfinishedAgreement },
    { persist: true },
  )

  const handleModalClose = async () => {
    if (agreementDetails) {
      showCreationDiscardModal()
    } else {
      toggleIsVisible()
    }
  }

  React.useEffect(
    () => () => {
      dispatch(updateAgreementDetails(null))
      dispatch(agreementsApi.util?.invalidateTags([AGREEMENTS_API_TAG, AGREEMENTS_API_TAG_IDS.DETAILS]))
    },
    [],
  )

  const extractedFormValues = extractSelectOptionsValues(methods.getValues())

  return (
    <Form methods={methods} onSubmit={handleCreationFinish}>
      <ContentLoader isLoading={isClearingUnfinishedAgreement}>
        <ModalHeader toggle={handleModalClose}>Dodaj umowę</ModalHeader>
        <ReceptionBookingCheckSteps step={step} steps={agreementCreateSteps} />
        <ModalBody>
          {step === 1 && <AgreementCreateFormWorkerData agreement={agreement} workerDetails={workerDetails} />}
          {step === 2 && <HrAddressForm addressData={extractedFormValues as HrAddress} />}
          {step === 3 && <HrHealthcareAndFinanceForm data={extractedFormValues as HrHealthCareAndFinanceData} />}
          {step === 4 && agreementDetails && (
            <AgreementCreateFormJobData resortOptions={resortOptions} agreementDetails={agreementDetails} />
          )}
          {step === 5 && <AgreementCreateFormStatements />}
          {step === 6 && <AgreementCreateFormSummary />}
          <AgreementFormAlerts />
        </ModalBody>
      </ContentLoader>

      <ModalFooter style={{ display: 'block' }}>
        <Row>
          <Col md={6} className="p-0">
            {step !== 1 && (
              <Button onClick={handlePrevStep} className="btn btn-light">
                Wstecz
              </Button>
            )}
          </Col>
          <Col md={6} className="text-right p-0">
            {step === 6 ? (
              <Button className="btn btn-green" onClick={handleCreationFinish}>
                Zakończ
              </Button>
            ) : (
              <SaveButton
                className="btn btn-green"
                isSaving={isValidating}
                onClick={validateForm}
                labelSaving="Sprawdzanie danych..."
                label="Następny krok"
              />
            )}
          </Col>
        </Row>
      </ModalFooter>
    </Form>
  )
}
