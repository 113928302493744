import * as React from 'react'
import { SubscriptionDetails } from '@modules/subscription/models'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { useFilteredRows } from '@components/table/use-filtered-rows'
import { useSortedRows } from '@components/table/use-sorted-rows'
import { Payment } from '@models/payments'
import { SubscriptionDetailsPaymentsRow } from '@modules/subscription/details/payments-row'
import { SubscriptionDetailsPaymentsOptions } from '@modules/subscription/details/payments-options'
import { useSubscriptionTypeStrategyProvider } from '@modules/subscription/hooks/use-subscription-type-provider'
import { asDecimal } from '@helpers/utils'

interface Props {
  subscriptionDetails: SubscriptionDetails
}

const headerValues: TableHeaderValue<Payment>[] = [
  { title: 'Data wpłaty', sortField: 'invoice_date', search: true },
  { title: 'Kwota', sortField: 'amount', search: true },
  { title: 'Źródło', sortField: 'source_display', search: true },
  { title: 'Dodane', sortField: 'user', search: true },
  { title: 'Opis', sortField: 'description', search: true },
]
const defaultFilters: TableFilters = {
  search: '',
  ordering: '-invoice_date',
  page: 1,
  page_size: 9999,
}

export const SubscriptionDetailsPayments: React.FC<Props> = ({ subscriptionDetails }) => {
  const [filters, setFilters] = React.useState<TableFilters>(defaultFilters)

  const setValue = (_, search) => setFilters({ ...filters, search })

  const payments = React.useMemo(
    () => subscriptionDetails.payments.filter(row => !asDecimal(row.amount).equals(asDecimal(0))),
    [subscriptionDetails.payments],
  )
  const filteredRows = useFilteredRows<Payment>(payments, headerValues, String(filters.search))
  const sortedRows = useSortedRows<Payment>(filteredRows, filters.ordering)
  const { can_add_manual_payment } = useSubscriptionTypeStrategyProvider(subscriptionDetails.type)

  return (
    <Card>
      <CardBody>
        <Row className="align-items-center">
          <Col className="col-auto col-md-5">
            <h4 className="text-secondary">Wpłaty</h4>
          </Col>
          <SearchFilter hideLabel={true} setValue={setValue} colClassName="col-md-5" formGroupClassName="mb-0" />
        </Row>
        <hr className="mt-1" />
        <Table
          className="table-hover table-sm w-100 mb-0"
          headerValues={headerValues}
          isLoading={false}
          showPagination={false}
          emptyText="Brak wpłat"
          filters={filters}
          setFilters={setFilters}
        >
          {sortedRows.map(payment => (
            <SubscriptionDetailsPaymentsRow payment={payment} key={payment.id} />
          ))}
        </Table>
        {can_add_manual_payment &&
          !subscriptionDetails.read_only &&
          subscriptionDetails.status !== 'cancelled' &&
          subscriptionDetails.type !== 'BOOKING_IMPROVEMENT' && (
            <SubscriptionDetailsPaymentsOptions subscriptionDetails={subscriptionDetails} />
          )}
      </CardBody>
    </Card>
  )
}
