import * as React from 'react'
import { ReceptionBooking } from '@models/reception'
import Table from '@components/table/table'
import { toDefaultDateTimeFormatWithBrake } from '@helpers/date-helper'
import { BookingStatus, cancelledBookingStatus } from '@models/booking'
import { ReceptionRowDates } from '@modules/reception/common/reception-row-dates'
import { asDecimal, formatPrice } from '@helpers/utils'
import Decimal from 'decimal.js'
import { RootState, useAppSelector } from '@store/index'
import { resortsMapSelector } from '@store/selectors/dashboard'
import { TableUserRow } from '@components/table-user-row'
import { ReservationListTableRowStatus } from '@modules/reservations/list/common/status'
import { useModal } from '@components/modals/use-modal'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'

interface Props {
  reservation: ReceptionBooking
  withDetailsPreview?: boolean
}

export const ReservationListTableRow: React.FC<Props> = ({ reservation, withDetailsPreview }) => {
  const accommodationTypes = useAppSelector((state: RootState) => state.appState.appData.accommodation_types)
  const resortsMap = useAppSelector(resortsMapSelector)
  const user = useAuthenticatedUser()

  const accommodationType = React.useMemo(
    () =>
      accommodationTypes.find(accommodationType => accommodationType.id === reservation.accommodation_type_id)?.name ||
      'brak',
    [accommodationTypes, reservation.accommodation_type_id],
  )

  const [showDetailsModal] = useModal('ReceptionBookingDetailsModal', {
    booking: reservation,
    tabs: ['improvements', 'feeding', 'data', 'guests', 'feedingNew'],
  })

  const handleShowDetails = event => {
    event.stopPropagation()
    event.preventDefault()
    showDetailsModal()
  }

  const isDetailsPreviewVisible =
    withDetailsPreview &&
    user.hasPerm(UserPermission.BookingCanViewDetails) &&
    (['initial', 'confirmed', 'initial_waiting', 'close'].some((status: BookingStatus) =>
      reservation.status.includes(status),
    ) ||
      reservation.checked_in)

  return (
    <Table.Row key={reservation.id} className="cursor-pointer">
      <Table.Cell>
        <a href={reservation.urls.details} className="table__link-row" data-testid="reservation-details-link">
          {toDefaultDateTimeFormatWithBrake(reservation.created_at)}
        </a>
      </Table.Cell>
      <Table.Cell>
        <ReservationListTableRowStatus booking={reservation} />
        {cancelledBookingStatus.includes(reservation.status) && (
          <>
            przez <br /> <TableUserRow userId={reservation.canceled_by_id} defaultValue="" />
          </>
        )}
      </Table.Cell>
      <Table.Cell>
        <ReceptionRowDates booking={reservation} showYear={true} />
      </Table.Cell>
      <Table.Cell>
        {reservation.reservation_number}
        {reservation.discount != 0 ? (
          <>
            <br />
            {reservation.discount_kind} ({String(reservation.discount).replace('.00', '')}%)
          </>
        ) : null}
        {isDetailsPreviewVisible && (
          <i
            className="uil-eye cursor-pointer font-16 text-muted ml-1 position-relative "
            style={{ zIndex: 1, position: 'relative' }}
            onClick={handleShowDetails}
          />
        )}
      </Table.Cell>
      <Table.Cell>
        {resortsMap[reservation.resort_id]?.name || 'brak'}
        <br />
        {accommodationType}
      </Table.Cell>
      <Table.Cell>
        {reservation.name}
        <br />
        <span className="font-11">{reservation.email}</span>
      </Table.Cell>
      <Table.Cell>
        {formatPrice(asDecimal(reservation.final_brutto).plus(asDecimal(reservation.climatic_tax_brutto)).toString())}
      </Table.Cell>
      <Table.Cell>
        {formatPrice(
          Decimal.max(
            asDecimal(0),
            asDecimal(reservation.final_brutto)
              .plus(asDecimal(reservation.climatic_tax_brutto))
              .minus(Decimal.max(asDecimal(reservation.rest_to_pay))),
          ).toString(),
        )}
      </Table.Cell>
      <Table.Cell>{formatPrice(reservation.rest_to_pay)}</Table.Cell>
      <Table.Cell>
        <TableUserRow userId={reservation.created_by_id} defaultValue="Samodzielnie na stronie">
          {reservation.data?.created_in === 'reservation-app-new' && (
            <span className="ml-1 font-10">nowy system rezerwacji</span>
          )}
          {reservation.data?.created_in === 'dashboard-app' && <span className="ml-1 font-10">panel</span>}
        </TableUserRow>
      </Table.Cell>
    </Table.Row>
  )
}
