import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { CustomInput } from 'reactstrap'
import { FormPlainError } from '@hyper/forms/index'
import { CustomInputProps } from 'reactstrap/es/CustomInput'
import classNames from 'classnames'

type Value = boolean | string | number

interface Props extends Omit<CustomInputProps, 'type' | 'id' | 'value'> {
  name: string
  value?: Value
  defaultValue?: string | number
  id?: string | number
  label?: React.ReactNode
  helpText?: React.ReactNode
  uncheckedDefaultValue?: any
}

export const FormCheckbox = ({
  name,
  value = true,
  defaultValue,
  label,
  helpText,
  uncheckedDefaultValue = null,
  ...inputProps
}: Props): React.ReactElement => {
  const { setValue, control } = useFormContext()

  if (typeof value === 'boolean') {
    uncheckedDefaultValue = false
  }

  const handleClick = event => {
    setValue(name, value === undefined ? event.target.checked : event.target.checked ? value : uncheckedDefaultValue)
  }

  const id = `${name}${inputProps.id ? `-${inputProps.id}` : ''}`
  const formValue: Value = useWatch({ control, name })
  return (
    <>
      <CustomInput
        {...inputProps}
        id={id}
        type="checkbox"
        onChange={handleClick}
        label={label}
        className={classNames({ 'form-checkbox no-label': !label }, inputProps.className)}
        checked={typeof formValue === 'boolean' ? formValue : formValue === value}
      />
      <FormPlainError name={name} />
      {helpText}
    </>
  )
}
