import * as React from 'react'
import { HousekeepingCleaningOrderType } from '@modules/housekeeping/models'
import { RootState, useAppSelector } from '@store/index'
import { NavigationTabs, NavigationTabsButton } from '@components/navigation-tabs'
import { NavigationPath } from '@models/routes'

interface HousekeepingTabsProps {
  resortId: number
  tab: HousekeepingCleaningOrderType
}

export const HousekeepingTabs: React.FC<HousekeepingTabsProps> = ({ resortId, tab }) => {
  const aggregation = useAppSelector((state: RootState) => state.houseKeepingState.cleaningOrdersAggregation)

  const url = NavigationPath.HousekeepingCleaningOrdersWithParams.replace(':resort_id', String(resortId))

  const buttons: NavigationTabsButton[] = [
    {
      title: `Zleć Sprzątanie (${aggregation.to_order})`,
      isActive: tab === HousekeepingCleaningOrderType.TO_ORDER,
      link: url.replace(':tab', String(HousekeepingCleaningOrderType.TO_ORDER)),
    },
    {
      title: `Zleć Ulepszenia (${aggregation.improvements})`,
      isActive: tab === HousekeepingCleaningOrderType.IMPROVEMENT,
      link: url.replace(':tab', String(HousekeepingCleaningOrderType.IMPROVEMENT)),
    },
    {
      title: `Zlecone (${aggregation.ordered})`,
      isActive: tab === HousekeepingCleaningOrderType.ORDERED,
      link: url.replace(':tab', String(HousekeepingCleaningOrderType.ORDERED)),
    },
    {
      title: `W toku (${aggregation.cleaning})`,
      isActive: tab === HousekeepingCleaningOrderType.CLEANING,
      link: url.replace(':tab', String(HousekeepingCleaningOrderType.CLEANING)),
    },
    {
      title: `Pościelowy (${aggregation.bedding})`,
      isActive: tab === HousekeepingCleaningOrderType.BEDDING,
      link: url.replace(':tab', String(HousekeepingCleaningOrderType.BEDDING)),
    },
    {
      title: `Do sprawdzenia (${aggregation.to_check})`,
      isActive: tab === HousekeepingCleaningOrderType.TO_CHECK,
      link: url.replace(':tab', String(HousekeepingCleaningOrderType.TO_CHECK)),
    },
    {
      title: `Czyste (${aggregation.accepted})`,
      isActive: tab === HousekeepingCleaningOrderType.ACCEPTED,
      link: url.replace(':tab', String(HousekeepingCleaningOrderType.ACCEPTED)),
    },
    {
      title: 'Wspólne',
      isActive: tab === HousekeepingCleaningOrderType.COMMON_TASK,
      link: url.replace(':tab', String(HousekeepingCleaningOrderType.COMMON_TASK)),
    },
    {
      title: <span className="uil-archive" title="Archiwum" />,
      isActive: tab === HousekeepingCleaningOrderType.ARCHIVE,
      className: 'w-auto',
      link: url.replace(':tab', String(HousekeepingCleaningOrderType.ARCHIVE)),
    },
  ]

  return <NavigationTabs buttons={buttons} />
}
