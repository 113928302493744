import * as React from 'react'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { ReceptionBookingGuest } from '@models/booking'
import classNames from 'classnames'

interface Props {
  guest: ReceptionBookingGuest
  onRescan: () => void
}

export const BandScanningGuestRescan = ({ guest, onRescan }: Props): JSX.Element => (
  <div className={classNames('band__scanning-guest__rescan')} title="Koduj opaskę ponownie">
    <CommonObjectConfirmAction
      handleAccept={onRescan}
      message={
        <div>
          <strong className="d-block mb-1">Dotychczasowe kodowanie opaski zostanie wyczyszczone.</strong>
          <span>
            Czy potwierdzasz usunięcie kodowania opaski dla <strong>{guest.name}?</strong>
          </span>
        </div>
      }
    >
      <div className="text-white py-1 px-2 d-flex align-items-center justify-content-center flex-column">
        <i className="uil-redo font-12 lh-0 d-block" />
        <span className="font-10 text-center">
          Koduj <br />
          ponownie
        </span>
      </div>
    </CommonObjectConfirmAction>
  </div>
)
