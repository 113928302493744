import * as React from 'react'
import { NotificationEvents } from '@models/dashboard'
import { useWebSocket } from '@components/hooks/use-web-socket'
import { SubscriptionContractDetails } from '@modules/subscription-contract/models'
import { useAppDispatch } from '@store/index'
import { updateSubscriptionContractDetails } from '@store/slices/subscription-contract-slice'

export const SubscriptionContractWebSocketHandler: React.FC = () => {
  const dispatch = useAppDispatch()

  useWebSocket<SubscriptionContractDetails>(
    (message, payload) => {
      switch (message) {
        case NotificationEvents.SUBSCRIPTION_CONTRACT_CHANGE:
          dispatch(updateSubscriptionContractDetails(payload as SubscriptionContractDetails))
          break
      }
    },
    [],
    [NotificationEvents.SUBSCRIPTION_CONTRACT_CHANGE],
  )
  return null
}
