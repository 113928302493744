import * as React from 'react'
import { Promocode, PromocodeDetails } from '@models/promocode'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { RootState, useAppSelector } from '@store/index'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectGet } from '@store/actions/generic-actions'
import { ContentLoader } from '@components/content-loader'
import { BaseModalProps } from '@components/modals/types'

interface Props extends BaseModalProps {
  promocode: Promocode
}

export const PromocodeCodeUsageDetailsDialog: React.FC<Props> = ({ promocode, toggleIsVisible }) => {
  const quick_search_url = useAppSelector((state: RootState) => state.appState.appData.urls.dashboard.quick_search_url)

  const [promocodeDetails, setPromocodeDetails] = React.useState<PromocodeDetails>()

  const { isLoading, action: fetch } = useApiRequest(async () => {
    setPromocodeDetails(await commonObjectGet<PromocodeDetails>(promocode.urls.details))
  })

  React.useEffect(() => {
    fetch()
  }, [])

  const logs = promocodeDetails?.logs || []

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>Szczegóły kodu: {promocode.code}</ModalHeader>
      <ModalBody>
        <ContentLoader isLoading={isLoading}>
          <>
            {logs
              .filter(log => log.kind === 'used')
              .map(log => (
                <div key={log.id} className="mb-1">
                  Użyty: {log.created_at} {log.created_by && `(${log.created_by})`} <br />
                  Nr. Rezerwacji:{' '}
                  <a href={`${quick_search_url}?q=${log.booking || log.description}`}>
                    {log.booking || log.description}
                  </a>
                </div>
              ))}
          </>
        </ContentLoader>
      </ModalBody>
      <ModalFooter>
        <Button color="green" onClick={toggleIsVisible}>
          Zamknij
        </Button>
      </ModalFooter>
    </>
  )
}
