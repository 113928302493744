import * as React from 'react'
import { ReceptionBooking } from '@models/reception'
import { Button } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'

interface Props {
  booking: ReceptionBooking
}

export const ReceptionCompleteCheckInRowActions: React.FC<Props> = ({ booking }) => {
  const [handleBandsModal] = useModal('ScanningDialog', { booking })

  return (
    <Button
      color="secondary"
      onClick={handleBandsModal}
      className="reception__bookings_table__row__checkout-button ml-1"
      type="button"
    >
      <i className="uil-watch-alt font-18" />
    </Button>
  )
}
