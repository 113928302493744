import { commonObjectPost } from '@store/actions/generic-actions'
import store from '@store/index'
import { PackageWholesaleProductFormInputs } from '@modules/package-wholesale/products/create/modal'
import {
  gastroCardKinds,
  PackageWholesaleDetails,
  PackageWholesaleProductDetails,
} from '@modules/package-wholesale/models'
import axios from 'axios'
import { multiPartHeaders } from '@api/base'
import * as R from 'ramda'

export const packageWholesaleProductCreate = async (
  image: File | undefined,
  payload: PackageWholesaleProductFormInputs,
): Promise<PackageWholesaleProductDetails> => {
  const url = store.getState().appState.appData.urls.package_wholesale.products

  const { data } = await axios.post(url, createPackageWholesaleProductPayload(image, payload), {
    headers: multiPartHeaders,
  })

  return data
}

const createPackageWholesaleProductPayload = (image: File | undefined, payload: PackageWholesaleProductFormInputs) => {
  const formData = new FormData()

  const requiredParams = [
    'kind',
    'name',
    'available_from',
    'available_to',
    'expire_after_years',
    'price_brutto',
    'status',
  ]

  const subscriptionParamsKeys = [
    ...requiredParams,
    'accommodation_type',
    'deposit_amount',
    'defaults_days',
    'subscription_code_name_kind',
    'discount',
    'localization_kind',
    'options',
    'deposit_payment_deadline',
    'standard_price_brutto',
    'subscription_kind',
    'subscription_kind_version',
    'with_cleaning_option',
    'with_client_change_data_option',
    'with_grill',
    'with_parking',
    'with_towel',
  ]

  const isGastroProduct = gastroCardKinds.includes(payload.kind)

  const params = isGastroProduct ? requiredParams : subscriptionParamsKeys

  Object.entries(
    R.pick(params, {
      ...payload,
      subscription_kind: payload.subscription_kind?.value || '',
      subscription_kind_version: payload.subscription_kind_version?.value || '',
      status: payload.status?.value,
      accommodation_type: payload.accommodation_type?.value || '',
      localization_kind: payload.localization_kind?.value || '',
      deposit_amount: payload.deposit_amount?.value ?? '',
      defaults_days: payload.defaults_days?.value ?? '',
    }),
  ).forEach(([key, value]) => {
    if (key === 'options' && Array.isArray(value)) {
      if (!value[0].kind?.value) {
        formData.append(key, '')
        return
      }

      value.forEach((option, index) => {
        formData.append(`${key}[${index}]kind`, option.kind.value)
        formData.append(`${key}[${index}]price_brutto`, option.price_brutto)
        formData.append(`${key}[${index}]description`, option.description)
      })
      return
    }

    if (!['image', 'options'].includes(key)) {
      formData.append(key, String(value))
    }
  })

  if (image) {
    formData.append('image', image)
  }

  return formData
}

export const packageWholesaleProductUpdate = async (
  url: string,
  image: File | undefined,
  payload: PackageWholesaleProductFormInputs,
): Promise<PackageWholesaleProductDetails> => {
  const { data } = await axios.put(url, createPackageWholesaleProductPayload(image, payload), {
    headers: multiPartHeaders,
  })

  return data
}

export const packageWholesaleCreate = async (payload: FormData): Promise<PackageWholesaleDetails> => {
  const url = store.getState().appState.appData.urls.package_wholesale.package_wholesales
  return await commonObjectPost<PackageWholesaleDetails>(url, payload)
}
