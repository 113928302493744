import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { Button, ModalFooter, ModalHeader } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { SaveButton } from '@hyper/button'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useFormRequest } from '@components/hooks/use-api-request'
import { PackageWholesaleDetails } from '@modules/package-wholesale/models'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { HtmlEditorLazy } from '@components/html-editor/html-editor-lazy'
import {
  PackageWholesaleDetailsMassNotificationsFilters,
  PackageWholesaleDetailsMassNotificationsStatusOptions,
} from '@modules/package-wholesale/details/notification/mass-notifications/mass-notifications-filters'
import { PackageWholesaleDetailsMassNotificationsClients } from '@modules/package-wholesale/details/notification/mass-notifications/mass-notifications-clients'
import { commonObjectPost } from '@store/actions/generic-actions'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { FormInput } from '@hyper/forms'
import { PackageWholesaleDetailsMassNotificationsModalPreviewButton } from '@modules/package-wholesale/details/notification/mass-notifications/mass-notifications-modal-preview-button'
import classnames from 'classnames'
import { useToggle } from '@components/hooks/use-toggle'
import { ContentLoader } from '@components/content-loader'
import { PackageWholesaleDetailsMassNotificationsFiles } from '@modules/package-wholesale/details/notification/mass-notifications/files'
import { useAppDispatch } from '@store/index'
import { updatePackageWholesaleDetails } from '@store/slices/package-wholesale-slice'

interface MassNotificationsModalFormFilters {
  status: CustomReactSelectOption
}

export interface MassNotificationsModalFormInputs {
  filters: MassNotificationsModalFormFilters | null
  subscriptions: string[]
  content: string
  subject: string
}

interface Props extends BaseModalProps {
  packageWholesaleDetails: PackageWholesaleDetails
}

export const PackageWholesaleDetailsMassNotificationsModal: React.FC<Props> = ({
  toggleIsVisible,
  packageWholesaleDetails,
}) => {
  const dispatch = useAppDispatch()

  const [files, setFiles] = React.useState<File[]>([])
  const [isEditorLoaded, toggleIsEditorLoaded] = useToggle(false)
  const methods = useForm<MassNotificationsModalFormInputs>({
    defaultValues: {
      subscriptions: [],
      content: '',
      subject: '',
      filters: {
        status: PackageWholesaleDetailsMassNotificationsStatusOptions[0],
      },
    },
  })

  const { addSuccessMessage } = useNotificationHook()

  const { isLoading, action: onSubmit } = useFormRequest(async () => {
    const { subscriptions, content, subject } = methods.getValues()

    const formData = new FormData()
    subscriptions.forEach(row => {
      formData.append('subscriptions', row)
    })
    formData.append('content', content)
    formData.append('subject', subject)

    files.forEach(file => {
      formData.append('attachments', file)
    })

    dispatch(
      updatePackageWholesaleDetails(
        await commonObjectPost<PackageWholesaleDetails>(packageWholesaleDetails.urls.mass_email_notification, formData),
      ),
    )

    addSuccessMessage('Sukces', 'Wiadomość została wysłana do wybranych Klientów.')
    toggleIsVisible()
  }, methods.setError)

  const [content, subscriptions] = useWatch({
    control: methods.control,
    name: ['content', 'subscriptions'],
  })

  const handleContentChange = value => {
    methods.setValue('content', value)
  }

  return (
    <FormProvider {...methods}>
      <ModalHeader className="px-2" toggle={toggleIsVisible}>
        Wyślij wiadomość e-mail do klientów
      </ModalHeader>
      <ModalBody className="px-0">
        <ContentLoader isLoading={!isEditorLoaded}>
          <PackageWholesaleDetailsMassNotificationsFilters />
          <hr className="my-3" />
          <PackageWholesaleDetailsMassNotificationsClients subscriptions={packageWholesaleDetails.subscriptions} />
          <hr className="mt-1" />
          <div className="px-2">
            <FormInput colClassName="px-0" name="subject" label="Tytuł wiadomości" />
            <h5 className={classnames('font-13 fw-bold mb-1', { 'text-danger': methods.formState.errors.subject })}>
              Treść wiadomości e-mail
            </h5>
            <HtmlEditorLazy
              className="mt-0"
              setContent={handleContentChange}
              contentHtml={content}
              name="content"
              onInit={toggleIsEditorLoaded}
            />
            <PackageWholesaleDetailsMassNotificationsFiles files={files} setFiles={setFiles} />
          </div>
        </ContentLoader>
      </ModalBody>
      <ModalFooter style={{ display: 'block' }}>
        <div className="px-0 d-flex justify-content-between">
          <PackageWholesaleDetailsMassNotificationsModalPreviewButton
            notificationTestUrl={packageWholesaleDetails.urls.mass_email_notification_test}
          />
          <div className="text-right">
            <Button color="light" onClick={toggleIsVisible}>
              Anuluj
            </Button>
            <SaveButton
              label="Wyślij e-mail"
              disabled={!packageWholesaleDetails || !subscriptions.length}
              role="notification-submit"
              className="btn btn-green ml-1"
              labelSaving="Wysyłanie..."
              isSaving={isLoading}
              onClick={onSubmit}
            />
          </div>
        </div>
      </ModalFooter>
    </FormProvider>
  )
}
