import * as React from 'react'
import { Col } from 'reactstrap'

interface ReceptionBookingCheckInStepAccessInfoBlockProps {
  icon: string
  title: string
  children: React.ReactNode
}

export const ReceptionBookingCheckInStepAccessInfoBlock: React.FC<ReceptionBookingCheckInStepAccessInfoBlockProps> = ({
  icon,
  title,
  children,
}) => (
  <Col md={6} className="d-flex reception__booking-check-in__access-step__info__block p-0 mb-3">
    <div className="reception__booking-check-in__access-step__info__icon">
      <i className={icon} />
    </div>
    <div className="reception__booking-check-in__access-step__info__text">
      <strong className="d-block mb-2 font-14">{title}</strong>
      {children}
    </div>
  </Col>
)
