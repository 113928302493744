import * as React from 'react'
import { useToggle } from '@components/hooks/use-toggle'
import { IconWithText } from '@components/icon-with-text'
import { NewFeedingCalculationDetails } from '@modules/reception/checkin/step-feeding/new-feeding/models'
import { ReceptionBookingNewFeedingDayRowCheckbox } from '@modules/reception/checkin/step-feeding/new-feeding/day-row/reception-booking-new-feeding-day-row-checkbox'
import { createNewFeedingInputName } from '@modules/reception/checkin/step-feeding/new-feeding/utils'
import { useAppDispatch } from '@store/index'
import { addExposedEdgeFeeding, removeExposedEdgeFeeding } from '@store/slices/new-feeding-slice'

interface Props {
  isEdge: boolean
  edgeText: string
  calculation: NewFeedingCalculationDetails
}

export const ReceptionBookingNewFeedingDayRowCheckboxVariant = ({
  isEdge,
  edgeText,
  calculation,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch()
  const [isEdgeVisible, toggleEdgeVisible] = useToggle(!calculation.checked)

  React.useEffect(() => {
    if (calculation.checked) {
      dispatch(addExposedEdgeFeeding(calculation))
    }
  }, [])

  const handleShowEdgeMeal = () => {
    toggleEdgeVisible()
    dispatch(addExposedEdgeFeeding(calculation))
  }

  const handleHideEdgeMeal = () => {
    toggleEdgeVisible()
    dispatch(removeExposedEdgeFeeding(calculation))
  }

  if (isEdge && isEdgeVisible)
    return (
      <IconWithText
        icon="uil-plus font-16 lh-0"
        textClass="font-12 text-nowrap"
        text={edgeText}
        onClick={handleShowEdgeMeal}
      />
    )

  return (
    <ReceptionBookingNewFeedingDayRowCheckbox name={createNewFeedingInputName(calculation)} calculation={calculation}>
      {isEdge && !isEdgeVisible && !calculation.checked && (
        <i className="uil-trash-alt cursor-pointer" onClick={handleHideEdgeMeal} />
      )}
    </ReceptionBookingNewFeedingDayRowCheckbox>
  )
}
