import * as React from 'react'
import { Row } from 'reactstrap'
import { useGroupedSellers } from '@components/hooks/use-grouped-sellers'
import { FormSelect } from '@hyper/forms/form-select'

export const PackageCreateFormInformation: React.FC = () => {
  const sellers = useGroupedSellers([])

  return (
    <Row>
      <FormSelect options={sellers} name="seller" label="Sprzedawca" formPlainProps={{ colSize: 6 }} />
    </Row>
  )
}
