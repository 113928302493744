import * as React from 'react'
import { Button } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'
import { SubscriptionType } from '@modules/subscription/models'

interface Props {
  type: SubscriptionType
}

export const SubscriptionProductCreateButton: React.FC<Props> = ({ type }) => {
  const [toggleModal] = useModal('SubscriptionProductCreateModal', { type })

  return (
    <Button onClick={toggleModal} color="green-dark" type="button" role="subscription-product-create-button">
      <i className="mdi mdi-plus-circle mr-1" />
      Dodaj produkt
    </Button>
  )
}
