import * as React from 'react'
import { differenceInDays, format, isWithinInterval, startOfToday } from 'date-fns'
import { parseISODate } from '@helpers/date-helper'
import { ReceptionBooking } from '@models/reception'
import declination from '@helpers/declination'

interface ReceptionRowDatesProps {
  booking: ReceptionBooking
  showYear?: boolean
}

export const ReceptionRowDates: React.FC<ReceptionRowDatesProps> = ({ booking, showYear }) => {
  const dateFrom = React.useMemo(
    () => parseISODate(booking.date_from + ' ' + booking.arrival_time) || 0,
    [booking.date_from],
  )
  const dateTo = React.useMemo(
    () => parseISODate(booking.date_to + ' ' + booking.departure_time) || 0,
    [booking.date_to],
  )

  const timeLeft = React.useMemo(() => {
    let isPresent = false

    try {
      isPresent =
        booking.date_from == booking.date_to
          ? false
          : isWithinInterval(startOfToday(), {
              start: dateFrom,
              end: dateTo,
            })
    } catch (error) {
      console.warn(error)
      return ''
    }

    const numberOfNights = differenceInDays(
      parseISODate(booking.date_to) as Date,
      isPresent ? startOfToday() : (parseISODate(booking.date_from) as Date),
    )

    return isPresent
      ? `(${declination.left(numberOfNights)} ${numberOfNights} ${declination.night(numberOfNights)})`
      : `(${numberOfNights} ${declination.night(numberOfNights)})`
  }, [dateFrom, dateTo])

  const dateRange = React.useMemo(
    () => `${format(dateFrom, 'dd.MM')}-${format(dateTo, showYear ? 'dd.MM.yyyy' : 'dd.MM')}`,
    [dateTo, dateFrom, showYear],
  )

  return (
    <>
      <div className="d-block mb-1">{dateRange}</div>
      {timeLeft}
    </>
  )
}
