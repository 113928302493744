import * as React from 'react'
import { GastroCardDialogFormInputs } from '@modules/promotions/gastro-cards/gastro-card-dialog/gastro-card-dialog'
import { UserPermission } from '@models/dashboard'
import { FormInput, FormPlain } from '@hyper/forms'
import { useFormContext } from 'react-hook-form'
import { CustomInput, Label } from 'reactstrap'
import DatePickerInput from '@components/date/date-picker-input'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { min, startOfToday } from 'date-fns'
import dateHelper from '@helpers/date-helper'
import { GastroCard } from '@models/promotions'
import { useGroupedSources } from '@components/hooks/use-grouped-sources'
import { useGroupedSellers } from '@components/hooks/use-grouped-sellers'
import { extractInnerRef } from '@helpers/forms'
import { FormSelect } from '@hyper/forms/form-select'
import { CustomReactSelectOption } from '@components/custom-react-select'

interface GastroCardDialogFormProps {
  gastroCard?: GastroCard
  isSellerReadonly: boolean
  resortOptions: CustomReactSelectOption[]
}

export const GastroCardDialogForm: React.FC<GastroCardDialogFormProps> = ({
  gastroCard,
  isSellerReadonly,
  resortOptions,
}) => {
  const user = useAuthenticatedUser()
  const sellersOptions = useGroupedSellers([])

  const sources = useGroupedSources()
  const { control, register } = useFormContext<GastroCardDialogFormInputs>()

  const isDeclaredSellPriceDisabled = Boolean(
    gastroCard && !user.hasPerm(UserPermission.PromotionsGastroCardCanChangeDeclaredSellPrice),
  )

  const minDate = min([dateHelper.parseISODate(gastroCard?.sell_date || startOfToday()), new Date()])

  const canUpdateYears = user.hasPerm(UserPermission.PromotionsGastroCardCanUpdateExpireDate)

  return (
    <>
      {user.hasPerm(UserPermission.PromotionsGastroCardCanChangeSellerInInitialVoucherStatus) && (
        <FormSelect
          options={sellersOptions}
          name="seller"
          label="Sprzedawca"
          formPlainProps={{ colSize: 6 }}
          selectProps={{ isClearable: true, isDisabled: isSellerReadonly }}
        />
      )}

      <FormPlain colSize={6} name="sell_date">
        <Label>Data utworzenia pakietu</Label>
        <DatePickerInput
          control={control}
          name="sell_date"
          wrapperClassName="flex-grow-1"
          inputGroupClassName={gastroCard?.is_confirmed ? 'disabled' : ''}
          isDisabled={gastroCard?.is_confirmed}
          minDate={minDate}
        />
      </FormPlain>

      <FormInput
        colSize={6}
        label="Wykorzystanie vouchera od"
        type="select"
        name="active_after_kind"
        disabled={(gastroCard && !canUpdateYears) || gastroCard?.is_confirmed}
      >
        <option value="today">Data zakupu</option>
        <option value="next_year">Następnego roku</option>
      </FormInput>

      {gastroCard && (
        <FormInput
          label="Czas ważności w latach"
          colSize={6}
          name="expire_after_years"
          type="number"
          min={0}
          disabled={!canUpdateYears || gastroCard.is_confirmed}
        />
      )}

      <FormInput
        disabled={isDeclaredSellPriceDisabled || gastroCard?.is_confirmed}
        colSize={6}
        label="Zadeklarowana kwota zakupu"
        name="declared_sell_price"
      />
      <FormSelect
        options={sources}
        name="source_marketing"
        formPlainProps={{ colSize: 6 }}
        label="Źródło"
        selectProps={{ isClearable: true, isDisabled: gastroCard?.is_confirmed }}
      />
      <FormSelect
        options={resortOptions}
        formPlainProps={{ colSize: 6 }}
        selectProps={{ isSelectMulti: true, isClearable: true, isDisabled: gastroCard?.is_confirmed }}
        name="resorts"
        label="Aktywny w ośrodkach"
      />
      <FormPlain name="is_departure_voucher_promotion">
        <CustomInput
          type="checkbox"
          id="is_departure_voucher_promotion"
          label={<span className="text-semi-strong cursor-pointer">Dosprzedaż do promocji wyjazdowej</span>}
          value={true}
          disabled={gastroCard?.is_confirmed}
          {...extractInnerRef(register('is_departure_voucher_promotion'))}
        />
      </FormPlain>
    </>
  )
}
