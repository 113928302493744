import * as React from 'react'
import { asDecimal, formatPrice } from '@helpers/utils'
import { useAppSelector } from '@store/index'
import { selectIsDiscountPreviewActive } from '@store/slices/new-feeding-slice'

interface Props {
  price: string
  className?: string
}

export const ReceptionBookingNewFeedingPrice = ({ price, className }: Props): React.ReactNode => {
  const isDiscountPreviewActive = useAppSelector(selectIsDiscountPreviewActive)
  const isPriceGreaterThanZero = asDecimal(price).greaterThan(0)

  return (
    <span className={className}>
      {isDiscountPreviewActive && isPriceGreaterThanZero && '-'}
      {formatPrice(price)}
    </span>
  )
}
