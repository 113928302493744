import * as React from 'react'
import { TechnicalOrder } from '@models/technical-orders'
import Table from '@components/table/table'
import { Button } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'

interface Props {
  technicalOrder: TechnicalOrder
}

export const TechnicalOrdersTodoRowAssignRow: React.FC<Props> = ({ technicalOrder }) => {
  const user = useAuthenticatedUser()

  const [handleClick] = useModal('TechnicalOrderAssignModal', { technicalOrders: [technicalOrder] })

  return (
    <Table.Cell className="text-right vertical-align-middle text-nowrap">
      {technicalOrder.forwarded_to_technical_order_manager && !user.isTechnicalOrderManager ? (
        <span className="badge badge-dark">
          <i className="uil uil-arrow-up-right mr-1" />
          Przekazane do Działu Technicznego
        </span>
      ) : technicalOrder.forwarded_to_resort && user.isTechnicalOrderManager ? (
        <span className="badge badge-dark">
          <i className="uil uil-arrow-up-right mr-1" />
          Przekazane do Resortu
        </span>
      ) : (
        <Button onClick={handleClick} className="btn btn-green">
          Przydziel zadanie
        </Button>
      )}
    </Table.Cell>
  )
}
