import * as React from 'react'
import Table from '@components/table/table'
import { PackageCode } from '@models/package'
import { useForm } from 'react-hook-form'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAppDispatch } from '@store/index'
import { useFormRequest } from '@components/hooks/use-api-request'
import { commonObjectPatch } from '@store/actions/generic-actions'
import { updatePackageDetails } from '@store/slices/package-slice'
import { Form } from '@hyper/forms/form'
import { FormInput } from '@hyper/forms'
import { InlineConfirmReject } from '@components/inline-confirm-reject'

interface Props {
  toggleIsEditable: () => void
  packageCode: PackageCode
}

interface FormInputs {
  name: string
}

export const PackageDetailsCodesRowNameEdit = ({ toggleIsEditable, packageCode }: Props): JSX.Element => {
  const methods = useForm<FormInputs>({ defaultValues: packageCode })

  const { addSuccessNotification } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    dispatch(updatePackageDetails(await commonObjectPatch(packageCode.urls.details, payload)))
    addSuccessNotification(`Kod ${packageCode.code} został zmieniony!`)
    toggleIsEditable()
  }, methods.setError)

  return (
    <Table.Cell>
      <Form methods={methods} onSubmit={onSubmit} className="d-flex">
        <FormInput
          name="name"
          formGroupClassName="mb-0"
          colClassName="px-0"
          colSize="col-auto"
          inputClassName="rounded-right-0"
        />
        <InlineConfirmReject
          onReject={toggleIsEditable}
          onConfirm={methods.handleSubmit(onSubmit)}
          isLoading={isLoading}
        />
      </Form>
    </Table.Cell>
  )
}
