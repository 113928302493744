import * as React from 'react'
import { ReservationDropdown } from '@modules/reservations/details/common/reservation-dropdown'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useAppDispatch } from '@store/index'
import { updateBookingPaymentDetails } from '@store/slices/reservations-slice'
import { ReservationBookingPayment } from '@models/reception'
import { commonObjectDelete, commonObjectPost } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useModal } from '@components/modals/use-modal'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'

interface Props {
  bookingPayment: ReservationBookingPayment
}
export const ReservationPaymentHistoryRowActions = ({ bookingPayment }: Props): JSX.Element => {
  const user = useAuthenticatedUser()
  const { addSuccessNotification } = useNotificationHook()
  const dispatch = useAppDispatch()

  const [showSynchronizationStatusModal] = useModal('ReservationPaymentSynchronizationStatusModal', { bookingPayment })
  const [showPaymentTransferModal] = useModal('ReservationPaymentTransferModal')

  const { isLoading: isRemoveLoading, action: handleRemove } = useApiRequest(async () => {
    dispatch(
      updateBookingPaymentDetails(await commonObjectDelete<ReservationBookingPayment>(bookingPayment.urls.details)),
    )
    addSuccessNotification('Płatność została oznaczona jako skasowana')
  })

  const { isLoading: isRestoreLoading, action: handleRestore } = useApiRequest(async () => {
    dispatch(
      updateBookingPaymentDetails(await commonObjectPost<ReservationBookingPayment>(bookingPayment.urls.restore)),
    )
    addSuccessNotification('Płatność została oznaczona jako przywrócona')
  })

  return (
    <ReservationDropdown toggleClassName="p-0">
      {!bookingPayment.is_removed ? (
        <ReservationDropdown.Item className="p-0">
          <CommonObjectConfirmAction handleAccept={handleRemove} isLoading={isRemoveLoading}>
            <div className="pl-2 py-1">Usuń</div>
          </CommonObjectConfirmAction>
        </ReservationDropdown.Item>
      ) : (
        <ReservationDropdown.Item className="p-0">
          <CommonObjectConfirmAction handleAccept={handleRestore} isLoading={isRestoreLoading}>
            <div className="pl-2 py-1">Przywróć</div>
          </CommonObjectConfirmAction>
        </ReservationDropdown.Item>
      )}
      {user.is_superuser && (
        <ReservationDropdown.Item href={bookingPayment.urls.admin} target="_blank">
          Edytuj
        </ReservationDropdown.Item>
      )}
      <ReservationDropdown.Item onClick={showSynchronizationStatusModal}>
        Sprawdź status synchronizacji
      </ReservationDropdown.Item>
      <ReservationDropdown.Item onClick={showPaymentTransferModal}>Przenieś płatność</ReservationDropdown.Item>
    </ReservationDropdown>
  )
}
