import * as React from 'react'
import { Button, Col, Row } from 'reactstrap'
import { FeedingWorkerFiltersParams } from '@modules/feeding/workers/index'
import { useModal } from '@components/modals/use-modal'

interface Props {
  filters: FeedingWorkerFiltersParams
}

export const FeedingWorkersExportButtons: React.FC<Props> = ({ filters }) => {
  const [toggleIsOpen] = useModal('ReportsGeneratorFeedingWorker', {
    dateFrom: filters.date_from,
    dateTo: filters.date_to,
    resort: filters.resorts,
  })

  return (
    <Row>
      <Col md={12} className="text-right">
        <Button color="secondary" className="text-semi-strong" onClick={toggleIsOpen}>
          <i className="uil-download-alt mr-1" />
          Pobierz
        </Button>
      </Col>
    </Row>
  )
}
