import Decimal from 'decimal.js'
import { asDecimal, sumDecimalArray } from '@helpers/utils'
import { ReceptionBookingDetails } from '@models/reception'
import * as React from 'react'
import { getReceptionBookingPayments } from '@modules/reception/common/reception-booking-check-bill-details/tools'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApiRequest } from '@components/hooks/use-api-request'
import { getReceptionBookingsAggregation, updateBookingDetails } from '@store/actions/reception-actions'
import { commonObjectPatch, commonObjectPost } from '@store/actions/generic-actions'
import { useAppDispatch, useAppSelector } from '@store/index'
import { reservationBookingPaymentsVisibleSelector } from '@store/slices/reservations-slice'
import { getBookingPayments } from '@store/actions/reservation-actions'

export interface CheckOutPayload {
  invoice_note: string
}
export const useReceptionCheckOut = (booking: ReceptionBookingDetails) => {
  const bookingPayments = useAppSelector(reservationBookingPaymentsVisibleSelector)

  const dispatch = useAppDispatch()
  const { action: fetchPayments } = useApiRequest(async () => dispatch(await getBookingPayments(booking)))

  React.useEffect(() => {
    fetchPayments()
  }, [booking.id])

  const totalDeposit: Decimal = React.useMemo(
    () => sumDecimalArray(getReceptionBookingPayments(bookingPayments, true).map(payment => asDecimal(payment.amount))),
    [bookingPayments],
  )

  const restToPay = asDecimal(booking.rest_to_pay)
  const restToPayWithoutClimatic = asDecimal(restToPay).minus(booking.required_climatic_tax)

  const depositToReturn = Decimal.max(
    asDecimal(0),
    booking.status === 'close' ? asDecimal(booking.deposit_to_return) : totalDeposit.minus(restToPayWithoutClimatic),
  )

  const isPaid = booking.is_paid

  const { addSuccessMessage } = useNotificationHook()

  const { isLoading: isConfirming, action: confirmCheckedOut } = useApiRequest(async () => {
    await commonObjectPost<ReceptionBookingDetails>(booking.urls.check_status, { action: 'check-out-finished' })
    dispatch(getReceptionBookingsAggregation())
    addSuccessMessage(
      'Wymeldowanie zakończone',
      `Wymeldowanie rezerwacji o numerze ${booking.reservation_number} (${booking.name}) zostało zakończone!`,
    )
  })

  const { isLoading: isCheckingOut, action: checkOut } = useApiRequest(async (payload: CheckOutPayload) => {
    if (isPaid) {
      await dispatch(
        updateBookingDetails(
          await commonObjectPatch<ReceptionBookingDetails>(booking.urls.check_out_step_complete, payload),
        ),
      )
      return true
    }
  })

  const totalDepositPayIn = sumDecimalArray(
    bookingPayments
      .filter(
        payment =>
          ['deposit', 'deposit_return', 'deposit_move'].includes(payment.type) &&
          payment.source !== 'creditcard_preauth',
      )
      .map(payment => asDecimal(payment.amount)),
  )

  const restToPaymentWithDeposit = Decimal.max(
    restToPay.minus(booking.required_climatic_tax).minus(totalDepositPayIn),
    asDecimal(0),
  ).plus(booking.required_climatic_tax)

  const isOnlyClimaticTaxLeft = restToPaymentWithDeposit.equals(booking.required_climatic_tax)

  return {
    depositToReturn,
    totalDeposit,
    restToPay,
    restToPayWithoutClimatic,
    restToPaymentWithDeposit,
    isOnlyClimaticTaxLeft,
    isPaid,
    confirmCheckedOut,
    isConfirming,
    isCheckingOut,
    checkOut,
  }
}
