import * as React from 'react'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap'
import { IconWithText } from '@components/icon-with-text'
import { useModal } from '@components/modals/use-modal'
import { SubscriptionProduct } from '@modules/subscription/models'

interface Props {
  selectedRows: SubscriptionProduct[]
  clearSelectedRows: () => void
}

export const SubscriptionProductListTableActions: React.FC<Props> = ({ selectedRows, clearSelectedRows }) => {
  const isDisabled = !selectedRows.length

  const [handleChangeStatus] = useModal('SubscriptionProductStatusModal', {
    products: selectedRows,
    clearSelectedRows,
  })

  return (
    <div className="mb-1">
      <UncontrolledButtonDropdown disabled={isDisabled}>
        <DropdownToggle color="light" caret={true} disabled={isDisabled}>
          <strong>zaznaczone ({selectedRows.length})</strong>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem>
            <IconWithText
              onClick={handleChangeStatus}
              icon="uil uil-label-alt text-bold"
              textClass="text-semi-strong"
              text="Zmień status produktu"
            />
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </div>
  )
}
