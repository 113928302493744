import { HouseKeepingCleaningOrder } from '@modules/reports/housekeeping-users-report/models'
import { HousekeepingUser } from '@models/reports'
import * as React from 'react'
import { IconWithTooltip } from '@components/icon-with-tooltip'
import classnames from 'classnames'
import Table from '@components/table/table'

interface CooperationListProps {
  cleaningOrder: HouseKeepingCleaningOrder
  user: HousekeepingUser
}

export const HousekeepingUserReportApartmentCell = ({ cleaningOrder, user }: CooperationListProps) => {
  const userNames = cleaningOrder.users
    .filter((hskUser: HousekeepingUser) => hskUser.id !== user.id)
    .map((hskUser: HousekeepingUser) => hskUser.name)

  return (
    <Table.Cell>
      <IconWithTooltip
        className="flex-row-reverse"
        color="default"
        tooltipMessage={
          <span>
            Przygotowany z:
            <ul className="pl-2 mt-1 mb-0">
              {userNames.map(userName => (
                <li key={userName}>{userName}</li>
              ))}
            </ul>
          </span>
        }
        tooltipId={`payment-tooltip-${cleaningOrder.id}`}
        tooltipPlacement="right"
        icon={classnames('uil-exclamation-circle ml-1', { 'd-none': cleaningOrder.users.length <= 1 })}
        tooltipDisabled={cleaningOrder.users.length <= 1}
      >
        {cleaningOrder.apartment}
      </IconWithTooltip>
    </Table.Cell>
  )
}
