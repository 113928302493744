import * as React from 'react'
import { CleaningOrder } from '@modules/housekeeping/models'
import { HousekeepingCleaningOrderToCheckRowActions } from '@modules/housekeeping/cleaning-orders/to-check/actions'
import { HousekeepingCleaningOrderNextBookingRow } from '@modules/housekeeping/cleaning-orders/common/next-booking'
import { HousekeepingCleaningOrderHasDamageRow } from '@modules/housekeeping/cleaning-orders/common/has-damage'
import { HousekeepingCleaningOrdersRowServiceKind } from '@modules/housekeeping/cleaning-orders/common/service-kind'
import { HousekeepingOrderWorkersRow } from '@modules/housekeeping/common/workers'
import { HousekeepingCleaningOrdersRowKind } from '@modules/housekeeping/cleaning-orders/common/kind'
import { secondsToInterval, toDefaultDateTimeFormat } from '@helpers/date-helper'
import classNames from 'classnames'
import { HousekeepingOrderToCheckBadgeStatus } from '@modules/housekeeping/cleaning-orders/to-check/status-badge'
import { HousekeepingCleaningOrderApartment } from '@modules/housekeeping/cleaning-orders/to-order/row-apartment'
import { TableViewType } from '@modules/technical-orders/common/table-view-switch'

interface HousekeepingCleaningOrderToCheckRowProps {
  cleaningOrder: CleaningOrder
  tableView: TableViewType
}

export const HousekeepingCleaningOrderToCheckRow: React.FC<HousekeepingCleaningOrderToCheckRowProps> = ({
  cleaningOrder,
  tableView,
}) => (
  <tr className="housekeeping__orders_table__row">
    <HousekeepingCleaningOrderApartment cleaningOrder={cleaningOrder} showCheckedIn={true} showPriority={true} />
    <HousekeepingCleaningOrdersRowKind order={cleaningOrder} />
    <HousekeepingCleaningOrdersRowServiceKind width={150} id={cleaningOrder.id} order={cleaningOrder} />
    <HousekeepingCleaningOrderNextBookingRow cleaningOrder={cleaningOrder} />
    <HousekeepingOrderToCheckBadgeStatus orders={cleaningOrder.bedding_orders} />
    <HousekeepingOrderToCheckBadgeStatus orders={cleaningOrder.improvement_orders} />
    {tableView === 'list' && <HousekeepingOrderWorkersRow order={cleaningOrder} />}
    <td className="housekeeping__orders_table__column" width={150}>
      <span
        className={classNames(
          cleaningOrder.status === 'FINISHED'
            ? cleaningOrder.is_finished_in_assumed_time
              ? 'text-success'
              : 'text-danger'
            : '',
        )}
      >
        {secondsToInterval(cleaningOrder.total_time_started)}
      </span>
      <div className="font-11">
        {cleaningOrder.status === 'FINISHED' && (
          <span>Koniec: {toDefaultDateTimeFormat(cleaningOrder.finished_at)}</span>
        )}
      </div>
    </td>
    <HousekeepingCleaningOrderHasDamageRow hasDamage={cleaningOrder.has_damage} />
    <HousekeepingCleaningOrderToCheckRowActions cleaningOrder={cleaningOrder} />
  </tr>
)
