import * as React from 'react'
import { CustomInput, Label, Row } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { SubmitFilter } from '@modules/reception/common/filters/submit-filter'
import DateRangeInput from '@components/date/date-range-input'
import Col from 'reactstrap/lib/Col'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { ErliPromocodeFiltersParams } from '@modules/promotions/erli-promocode/index'
import { FormPlain } from '@hyper/forms'
import { extractInnerRef } from '@helpers/forms'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { Form } from '@hyper/forms/form'

interface ErliPromocodeFiltersProps {
  filters: ErliPromocodeFiltersParams
  setFilters: (filters: ErliPromocodeFiltersParams) => void
  defaultFilters: ErliPromocodeFiltersParams
}

export const ErliPromocodeFilters: React.FC<ErliPromocodeFiltersProps> = ({ setFilters, filters, defaultFilters }) => {
  const methods = useForm<ErliPromocodeFiltersParams>({
    defaultValues: filters,
  })

  const handleReset = () => {
    methods.reset()
    setFilters(defaultFilters)
  }

  const onSubmit = async (payload: ErliPromocodeFiltersParams) => setFilters({ ...filters, ...payload })

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  return (
    <Form methods={methods} onSubmit={onSubmit} className="form form-style-filter mb-2">
      <Row>
        <Col className="col-auto" style={{ minWidth: 320 }}>
          <div className="d-flex">
            <Label className="mr-1">Data utworzenia</Label>
          </div>

          <DateRangeInput
            isClearable={true}
            wrapperClassName="flex-grow-1"
            inputGroupClassName="form-group"
            startDateName="created_after"
            endDateName="created_before"
          />
        </Col>
        <FormPlain colSize={6} name="valid_forever" errors={{}}>
          <label className="d-block mt-1">&nbsp;</label>
          <CustomInput
            className="mt-2"
            type="checkbox"
            {...extractInnerRef(methods.register('is_used'))}
            id="is_used"
            label="Wykorzystany?"
          />
        </FormPlain>
        <SubmitFilter handleReset={handleReset} />
        <SearchFilter />
      </Row>
    </Form>
  )
}
