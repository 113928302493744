import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { FormInput } from '@hyper/forms'
import { useFormRequest } from '@components/hooks/use-api-request'
import { useAppDispatch } from '@store/index'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useForm } from 'react-hook-form'
import { BaseModalProps } from '@components/modals/types'
import { updateReceptionBookingDetails } from '@store/actions/reception-actions'
import { ReceptionBookingDetails } from '@models/reception'
import { Form } from '@hyper/forms/form'

interface Props extends BaseModalProps {
  booking: ReceptionBookingDetails
}

interface FormInputs {
  name: string
  price_brutto: string
}

export const ReservationExtraChargesModal: React.FC<Props> = ({ booking, toggleIsVisible }) => {
  const methods = useForm<FormInputs>()
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    dispatch(
      updateReceptionBookingDetails(
        await commonObjectPost<ReceptionBookingDetails>(booking.urls.reservation_booking_extra_charges, payload),
      ),
    )

    addSuccessMessage('Sukces', 'Dopłata została dodana')
    toggleIsVisible()
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Dodaj dopłatę</ModalHeader>
      <ModalBody>
        <Row>
          <FormInput colSize={12} label="Nazwa" name="name" />
          <FormInput colSize={12} label="Cena brutto" name="price_brutto" />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={toggleIsVisible}>
          Zamknij
        </Button>

        <SaveButton role="submit" className="btn btn-green" label="Zapisz" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
