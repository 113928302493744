import * as React from 'react'
import styleHelper from '@helpers/style-helper'
import { setScrollPosition } from '@store/actions/timeline-actions'
import { TimelineScrollPosition } from '@store/reducers/timeline-reducers'
import { connect } from 'react-redux'
import timelineHelper from '@helpers/timeline-helper'

interface ComponentProps {
  children: React.ReactNode
  onReachRight: () => void
  onReachLeft: () => void
  infiniteScrollerRef: React.RefObject<HTMLDivElement>
  dayWrapperRef: React.RefObject<HTMLDivElement>
  width: string
}

interface DispatchProps {
  setScrollPosition: typeof setScrollPosition
}

type Props = ComponentProps & DispatchProps

export class TimelineScrollerComponent extends React.Component<Props, {}> {
  private timeout

  public componentDidMount(): void {
    if (this.props.infiniteScrollerRef.current) {
      const { scrollLeft } = this.props.infiniteScrollerRef.current
      this.setScroll(scrollLeft)
    }
  }

  public handleScroll = (): void => {
    if (!this.props.infiniteScrollerRef.current) {
      return
    }

    const { scrollLeft, offsetWidth } = this.props.infiniteScrollerRef.current
    const rightEdge = styleHelper.pxToValue(this.props.width)
    const scrolledRight = scrollLeft + offsetWidth

    if (scrolledRight + timelineHelper.itemsMargin >= rightEdge) {
      this.props.onReachRight()
    } else if (!scrollLeft) {
      this.props.onReachLeft()
    }

    this.setScroll(scrollLeft)
  }

  public setScroll = (left: number): void => {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => this.props.setScrollPosition(left), 100)

    if (this.props.dayWrapperRef.current) {
      this.props.dayWrapperRef.current.scrollLeft = left
    }
  }

  public render() {
    return (
      <div
        data-testid="calendar-scroller"
        className="calendar__scroller"
        ref={this.props.infiniteScrollerRef}
        onScroll={this.handleScroll}
      >
        {this.props.children}
      </div>
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch): DispatchProps => ({
  setScrollPosition: (payload: TimelineScrollPosition) => dispatch(setScrollPosition(payload)),
})

const TimelineScroller = connect(mapStateToProps, mapDispatchToProps)(TimelineScrollerComponent)

export default TimelineScroller
