import * as React from 'react'
import { RecommendationGift } from '@models/crm'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { removeRecommendationGift } from '@store/slices/recommendation-gift-slice'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { useAppDispatch } from '@store/index'

interface RecommendationGiftDeleteIconProps {
  recommendationGiftDelete: RecommendationGift
}

export const RecommendationGiftDeleteIcon: React.FC<RecommendationGiftDeleteIconProps> = ({
  recommendationGiftDelete,
}) => {
  const { addSuccessMessage } = useNotificationHook()

  const dispatch = useAppDispatch()

  const { isLoading, action: handleAccept } = useApiRequest(
    React.useCallback(async () => {
      await commonObjectDelete(recommendationGiftDelete.urls.details)

      addSuccessMessage('Sukces', 'Prezent został skasowany')
      dispatch(removeRecommendationGift(recommendationGiftDelete))
    }, [recommendationGiftDelete]),
  )

  return (
    <CommonObjectConfirmAction
      title="Potwierdź skasowanie prezentu."
      handleAccept={handleAccept}
      isLoading={isLoading}
      message="Czy na pewno chcesz skasować wybrany prezent ? Operacja ta jest nieodwracalna."
    >
      <span className="font-16 ml-1">
        <i className="uil uil-trash-alt text-muted cursor-pointer" title="Usuń" />
      </span>
    </CommonObjectConfirmAction>
  )
}
