import * as React from 'react'
import { GenericVoucherDetails, GenericVoucherKind } from '@modules/generic-vouchers/models'
import { BaseModalProps } from '@components/modals/types'
import { Button, Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { GenericVoucherDetailsClient } from '@modules/generic-vouchers/details/client'
import { ContentNotesCard } from '@components/content-notes/content-notes-card'
import { useAppData } from '@components/hooks/use-app-data'
import { HistoryBox } from '@modules/promotions/common/history-box'
import { ProductEmailNotifications } from '@modules/promotions/common/notification/notifications'
import { useGenericVoucherStrategyProvider } from '@modules/generic-vouchers/hooks/use-generic-voucher-strategy-provider'
import { GenericVoucherDetailsInformation } from '@modules/generic-vouchers/details/information'
import { useGetGenericVoucherDetailsQuery } from '@api/generic-vouchers'
import { TypedQueryResult } from '@api/base'
import { LoaderPlaceholder } from '@components/loader-placeholder'

interface Props extends BaseModalProps {
  id: string
  kind: GenericVoucherKind
}

export const GenericVoucherDetailsModal: React.FC<Props> = ({ id, kind, toggleIsVisible }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const contentType = useAppData().content_types.genericvoucher
  const genericVoucherStrategy = useGenericVoucherStrategyProvider(kind)

  const {
    data: genericVoucherDetails,
    isLoading,
    refetch,
  } = useGetGenericVoucherDetailsQuery({
    kind,
    id,
  }) as TypedQueryResult<GenericVoucherDetails>

  React.useEffect(
    () => () => {
      navigate(location.pathname.replace(new RegExp(`/${id}/$`), '/'))
    },
    [navigate, location, id],
  )

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>
        Klient {genericVoucherDetails?.client_data.first_name} {genericVoucherDetails?.client_data.last_name} #
        {genericVoucherDetails?.number}{' '}
      </ModalHeader>
      <ModalBody>
        <LoaderPlaceholder content={!isLoading}>
          {!!genericVoucherDetails && (
            <Row>
              <Col md={6}>
                <GenericVoucherDetailsClient clientData={genericVoucherDetails.client_data} />
                <ContentNotesCard
                  objectId={genericVoucherDetails.id}
                  contentType={contentType}
                  notes={genericVoucherDetails.notes}
                  onNotesUpdate={refetch}
                  readOnly={false}
                />
                <ProductEmailNotifications
                  url={genericVoucherDetails.urls.notifications}
                  notificationEmails={genericVoucherStrategy.email_notifications}
                  email={genericVoucherDetails.client_data.email}
                  onSuccess={refetch}
                  history={genericVoucherDetails.notifications}
                />
              </Col>
              <Col md={6}>
                <GenericVoucherDetailsInformation genericVoucher={genericVoucherDetails} />
                <HistoryBox history={genericVoucherDetails.history} />
              </Col>
            </Row>
          )}
        </LoaderPlaceholder>
      </ModalBody>

      <ModalFooter className="d-flex justify-content-between">
        <Button className="ml-auto btn btn-light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
      </ModalFooter>
    </>
  )
}
