import * as React from 'react'
import { FormPlain } from '@hyper/forms'
import { useFormContext } from 'react-hook-form'
import { Label, Row } from 'reactstrap'
import DatePickerInput from '@components/date/date-picker-input'
import { CreateImprovementOrderModalFormImprovements } from './form-improvements'
import { CreateImprovementOrderModalFormInputs } from '@modules/housekeeping/cleaning-orders/create-improvement-order/create-improvement-order-modal'
import { FormHousekeepingOrderWorkerAssignField } from '@modules/housekeeping/cleaning-orders/common/form-housekeeping-order-worker-assign-field'
import { apartmentsSelectSelector } from '@store/selectors/timeline'
import { FormSelect } from '@hyper/forms/form-select'
import { useAppSelector } from '@store/index'

interface Props {
  resortId: number
  apartmentId?: number
}

export const CreateImprovementOrderModalForm: React.FC<Props> = ({ apartmentId, resortId }) => {
  const { control } = useFormContext<CreateImprovementOrderModalFormInputs>()

  const apartments = useAppSelector(apartmentsSelectSelector)

  return (
    <>
      <Row>
        {!apartmentId && (
          <FormSelect options={apartments} name="apartment" label="Lokal" formPlainProps={{ colSize: 6 }} />
        )}
        <FormPlain name="date" colSize={apartmentId ? 12 : 6}>
          <Label>Data</Label>
          <DatePickerInput control={control} name="date" />
        </FormPlain>
      </Row>

      <CreateImprovementOrderModalFormImprovements />
      <Row className="mx-0">
        <FormHousekeepingOrderWorkerAssignField label="Firma sprzątająca" resortId={resortId} />
      </Row>
    </>
  )
}
