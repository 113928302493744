import * as React from 'react'
import { ButtonWithIcon } from '@components/button-with-icon'
import { useModal } from '@components/modals/use-modal'
import { useProductSaleConfirmationModal } from '@components/sale/use-product-sale-confirmation-modal'

export const EndlessHolidayInvitationAddButton = (): React.ReactNode => {
  const { showConfirmationPackageCreation } = useProductSaleConfirmationModal()

  const [showAgreementCreationModal] = useModal('EndlessHolidayInvitationCreateModal', {
    onCreate: () => showConfirmationPackageCreation(handleShowAddAgreementModal),
  })

  const handleShowAddAgreementModal = () => {
    showAgreementCreationModal(null)
  }

  return (
    <div style={{ bottom: 20, right: 0, position: 'absolute' }}>
      <ButtonWithIcon
        icon="uil-plus lh-0"
        text="Dodaj"
        handleClick={showAgreementCreationModal}
        color="green-dark"
        btnClass="btn-tall"
      />
    </div>
  )
}
