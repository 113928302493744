import * as React from 'react'
import { PackageWholesaleProduct } from '@modules/package-wholesale/models'
import Table from '@components/table/table'
import { formatPrice } from '@helpers/utils'
import { PackageWholesaleProductListTableRowEdit } from '@modules/package-wholesale/products/list/table-row-edit'
import { PackageWholesaleProductListTableRowRemove } from '@modules/package-wholesale/products/list/table-row-remove'
import { accommodationTypesMapSelector } from '@store/selectors/dashboard'
import { yearsPronunciation } from '@helpers/pronunciation'
import { YesNoBadge } from '@components/badges/yes-no'
import { useAppSelector } from '@store/index'

interface Props {
  packageWholesaleProduct: PackageWholesaleProduct
  children: React.ReactNode
}

export const PackageWholesaleProductListTableRow = ({ packageWholesaleProduct, children }: Props): JSX.Element => {
  const accommodationTypesMap = useAppSelector(accommodationTypesMapSelector)

  return (
    <Table.Row>
      {children}
      <Table.Cell>{packageWholesaleProduct.name}</Table.Cell>
      <Table.Cell>
        <YesNoBadge
          value={packageWholesaleProduct.status === 'available'}
          textYes={packageWholesaleProduct.status_display}
          textNo={packageWholesaleProduct.status_display}
        />
      </Table.Cell>
      <Table.Cell>
        {packageWholesaleProduct.kind_display}{' '}
        {packageWholesaleProduct.kind === 'subscription' && (
          <>
            ({packageWholesaleProduct.subscription_kind_display}
            {packageWholesaleProduct.subscription_kind_version &&
              ` - ${packageWholesaleProduct.subscription_kind_version_display}`}
            )
          </>
        )}
      </Table.Cell>
      <Table.Cell>
        {packageWholesaleProduct.kind === 'subscription' &&
          accommodationTypesMap[packageWholesaleProduct.accommodation_type_id]?.name}
      </Table.Cell>
      <Table.Cell>
        {packageWholesaleProduct.expire_after_years} {yearsPronunciation(packageWholesaleProduct.expire_after_years)}
      </Table.Cell>
      <Table.Cell>
        {packageWholesaleProduct.kind === 'subscription' && packageWholesaleProduct.localization_kind_display}
      </Table.Cell>
      <Table.Cell>
        {packageWholesaleProduct.with_cleaning_option && (
          <span className="badge badge-success-lighten mr-1">sprzątanie</span>
        )}
        {packageWholesaleProduct.with_parking && <span className="badge badge-success-lighten mr-1">parking</span>}
        {packageWholesaleProduct.with_towel && <span className="badge badge-success-lighten mr-1">ręczniki</span>}
        {packageWholesaleProduct.with_grill && <span className="badge badge-success-lighten mr-1">grill</span>}
        {packageWholesaleProduct.with_client_change_data_option && (
          <span className="badge badge-success-lighten">zmiana danych</span>
        )}
      </Table.Cell>
      <Table.Cell>
        {packageWholesaleProduct.kind === 'subscription' && formatPrice(packageWholesaleProduct.price_brutto)}
        {packageWholesaleProduct.kind === 'gastro_card' &&
          (packageWholesaleProduct.price_brutto ? formatPrice(packageWholesaleProduct.price_brutto) : 'brak')}
      </Table.Cell>

      <Table.Cell className="text-right">
        <PackageWholesaleProductListTableRowEdit packageWholesaleProduct={packageWholesaleProduct} />
        <PackageWholesaleProductListTableRowRemove packageWholesaleProduct={packageWholesaleProduct} />
      </Table.Cell>
    </Table.Row>
  )
}
