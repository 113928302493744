import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { receptionBookingPaymentsAdd, ReceptionBookingPaymentsAddPayload } from '@store/actions/reception-actions'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import Decimal from 'decimal.js'
import { CardPaymentBox } from '@components/card-payment-box'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useFormRequest } from '@components/hooks/use-api-request'
import { asDecimal } from '@helpers/utils'
import { commonObjectGet } from '@store/actions/generic-actions'
import { setReceptionBookingDetails } from '@store/slices/reception-slice'

interface Props {
  booking: ReceptionBookingDetails
  restToPay: Decimal
  handleCancel: () => void
  paymentParams: Partial<ReceptionBookingPaymentsAddPayload>
}

interface FormInputs {
  amount: string
}

export const ReceptionBookingCheckPaymentsBoxCheckoutCard: React.FC<Props> = ({
  restToPay,
  handleCancel,
  booking,
  paymentParams,
}) => {
  const { addSuccessNotification } = useNotificationHook()

  const methods = useForm<FormInputs>({ mode: 'all' })
  const dispatch = useAppDispatch()

  const watchedAmount = useWatch({ control: methods.control, name: 'amount' })

  React.useEffect(() => {
    if (watchedAmount && asDecimal(watchedAmount).greaterThan(restToPay)) {
      methods.setValue('amount', restToPay.toString())
    }
  }, [watchedAmount])

  const context: Partial<ReceptionBookingPaymentsAddPayload> = {
    source: 'creditcard',
    type: 'installment',
    ...paymentParams,
  }

  const { isLoading, action: handleSubmit } = useFormRequest(
    async () => {
      await dispatch(
        receptionBookingPaymentsAdd(booking.urls.payments, {
          amount: methods.getValues('amount'),
          ...context,
        }),
      )
      addSuccessNotification('Wpłata została dodana!')
    },
    methods.setError,
    { nonFieldErrorsAs: 'amount' },
  )

  const content_type = useAppSelector((state: RootState) => state.appState.appData.content_types.booking)

  const onEserviceSuccess = async () =>
    dispatch(setReceptionBookingDetails(await commonObjectGet<ReceptionBookingDetails>(booking.urls.api_details)))

  return (
    <FormProvider {...methods}>
      <CardPaymentBox
        content_type={content_type}
        object_id={booking.id}
        kind="booking_advance_with_climatic"
        restToPay={restToPay.toString()}
        onSubmit={handleSubmit}
        context={context}
        onEserviceSuccess={onEserviceSuccess}
        onCancel={handleCancel}
        isSaving={isLoading}
      />
    </FormProvider>
  )
}
