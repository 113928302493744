import * as React from 'react'
import Table, { TableHeaderValue } from '@components/table/table'
import { ReceptionistUser, User } from '@models/account'
import { ReceptionistUsersListTableRow } from '@modules/account/receptionist-users/list/table-row'
import { ReceptionistUsersTableFilters } from '@modules/account/receptionist-users/list/index'

const headerValues: TableHeaderValue<User>[] = [
  { title: 'Imię i nazwisko', sortField: 'last_name' },
  { title: 'Login', sortField: 'email' },
  { title: 'Ośrodek' },
  { title: 'Telefon' },
  { title: 'Aktywny?' },
  { title: 'Ostatnie logowanie', sortField: 'last_login' },
  { title: '' },
]

interface Props {
  users: ReceptionistUser[]
  isLoading: boolean
  filters: ReceptionistUsersTableFilters
  setFilters: (filters: ReceptionistUsersTableFilters) => void
}

export const ReceptionistUsersListTable: React.FC<Props> = ({ isLoading, users, filters, setFilters }) => (
  <Table
    className="table-hover w-100"
    headerValues={headerValues}
    isLoading={isLoading}
    filters={filters}
    setFilters={setFilters}
  >
    {users.map(user => (
      <ReceptionistUsersListTableRow key={user.id} user={user} />
    ))}
  </Table>
)
