import * as React from 'react'
import Table from '@components/table/table'

import { TechnicalOrder } from '@models/technical-orders'
import { TechnicalOrdersTodoRowNextBooking } from '@modules/technical-orders/technical-orders-todo/row-next-booking'
import { TechnicalOrdersTodoRowUnit } from '@modules/technical-orders/technical-orders-todo/row-unit'
import { TechnicalOrdersTodoRowName } from '@modules/technical-orders/technical-orders-todo/row-name'
import { TechnicalOrdersTodoRowNumber } from '@modules/technical-orders/technical-orders-todo/row-number'
import { TechnicalOrdersTodoRowResort } from '@modules/technical-orders/technical-orders-todo/row-resort'
import { TechnicalOrdersTodoRowUserBy } from '@modules/technical-orders/technical-orders-todo/row-user-by'
import { useTechnicalOrderPreviewModal } from '@modules/technical-orders/hooks/use-technical-order-preview-modal'
import { TechnicalOrdersTodoRowAssignRow } from '@modules/technical-orders/technical-orders-todo/row-assign'
import { TechnicalOrdersOrderedTableRowNotes } from '@modules/technical-orders/technical-orders-ordered/row-notes'

interface Props {
  technicalOrder: TechnicalOrder
  children: React.ReactNode
  showResort: boolean
}

export const TechnicalOrdersTodoRow: React.FC<Props> = ({ children, showResort, technicalOrder }) => {
  const handlePreview = useTechnicalOrderPreviewModal({ technicalOrder })

  return (
    <Table.Row onDoubleClick={handlePreview} className="cursor-pointer">
      {children}
      <TechnicalOrdersTodoRowNumber technicalOrder={technicalOrder} showCreatedAt={true} />
      {showResort && <TechnicalOrdersTodoRowResort technicalOrder={technicalOrder} />}
      <TechnicalOrdersTodoRowUnit technicalOrder={technicalOrder} showPriority={!!technicalOrder.priority} />
      <TechnicalOrdersTodoRowName technicalOrder={technicalOrder} />
      <TechnicalOrdersTodoRowUserBy user={technicalOrder.created_by} booking={technicalOrder.created_by_booking} />
      <TechnicalOrdersTodoRowNextBooking nextBooking={technicalOrder.next_booking} />
      <TechnicalOrdersOrderedTableRowNotes technicalOrder={technicalOrder} />
      <TechnicalOrdersTodoRowAssignRow technicalOrder={technicalOrder} />
    </Table.Row>
  )
}
