import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { ReservationCard } from '@modules/reservations/details/common/reservation-card'
import { ReservationTouristVouchersRow } from '@modules/reservations/details/tourist-vouchers/row'

interface Props {
  booking: ReceptionBookingDetails
}

export const ReservationTouristVouchers = ({ booking }: Props): JSX.Element => (
  <ReservationCard title="Bony turystyczne">
    <table className="table table-sm">
      <thead>
        <tr>
          <th className="border-0 col-5">Kod</th>
          <th className="border-0">Kwota</th>
          <th className="border-0">Potwierdzony</th>
          <th className="border-0" />
        </tr>
      </thead>
      <tbody>
        {booking.tourist_vouchers.map(row => (
          <ReservationTouristVouchersRow key={row.id} touristVoucher={row} />
        ))}
      </tbody>
    </table>
  </ReservationCard>
)
