import * as React from 'react'
import { ReceptionBand } from '@models/reception'
import { useFormContext } from 'react-hook-form'
import { BandScannerFormInputs } from '@components/band-scanning/band-scanner'

interface Props {
  bookingBands: ReceptionBand[]
}

export const BandScanningGuestsListHeader = ({ bookingBands }: Props): JSX.Element => {
  const { setValue } = useFormContext<BandScannerFormInputs>()

  const selectAllBands = () => {
    const bands = bookingBands.reduce((prev, curr) => ({ ...prev, [`band__${curr.id}`]: true }), {})
    setValue('bands', bands)
  }

  const releasedBands = bookingBands.filter(band => band.state === 'RELEASED').length

  return (
    <div className="d-flex align-items-center">
      <i className="cursor-pointer uil-corner-left-down font-20 mr-2" onClick={selectAllBands} />
      <div>
        <strong className="d-block font-15 mb-1 cursor-pointer" onClick={selectAllBands}>
          Zakoduj dla wszystkich
        </strong>
        <span className="font-12">
          Zakodowanych opasek {releasedBands} z {bookingBands.length}
        </span>
      </div>
    </div>
  )
}
