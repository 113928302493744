import * as React from 'react'
import { NavigationTabs, NavigationTabsButton } from '@components/navigation-tabs'
import { ScrollableModalTab, useModalWithScrollableTabs } from '@components/hooks/use-modal-with-scrollable-tabs'

type Tab = 'data' | 'payments' | 'notifications' | 'history' | 'notifications' | 'benefit'

interface Props {
  dataRef: React.RefObject<HTMLElement>
  benefitRef: React.RefObject<HTMLElement>
  notificationRef: React.RefObject<HTMLElement>
  paymentsRef: React.RefObject<HTMLElement>
  historyRef: React.RefObject<HTMLElement>
}

export const SubscriptionContractDetailsTabs: React.FC<Props> = ({
  dataRef,
  benefitRef,
  paymentsRef,
  notificationRef,
  historyRef,
}) => {
  const [tab, setTab] = React.useState<Tab>('data')

  const tabs: ScrollableModalTab<Tab>[] = [
    { ref: dataRef, name: 'data' },
    { ref: benefitRef, name: 'benefit' },
    { ref: historyRef, name: 'history' },
    { ref: notificationRef, name: 'notifications' },
    { ref: paymentsRef, name: 'payments' },
  ]

  const { handleTabClick } = useModalWithScrollableTabs<Tab>({ modalId: 'subscription-contract-modal', setTab, tabs })

  const buttons: NavigationTabsButton[] = [
    {
      title: 'Dane podstawowe',
      isActive: tab == 'data',
      onClick: handleTabClick('data'),
    },
    {
      title: 'Benefity',
      isActive: tab == 'benefit',
      onClick: handleTabClick('benefit'),
    },
    {
      title: 'Wpłaty',
      isActive: tab == 'payments',
      onClick: handleTabClick('payments'),
    },
    {
      title: 'Historie',
      isActive: tab == 'history',
      onClick: handleTabClick('history'),
    },
    {
      title: 'Powiadomienia',
      isActive: tab == 'notifications',
      onClick: handleTabClick('notifications'),
    },
  ]

  return <NavigationTabs buttons={buttons} />
}
