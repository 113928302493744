import * as React from 'react'
import { ReceptionBooking, ReceptionBookingDetails } from '@models/reception'
import { ModalBody } from 'reactstrap'
import { useAppDispatch, useAppSelector } from '@store/index'
import { getReceptionBookingDetails, getResortDetails } from '@store/actions/reception-actions'
import {
  ReceptionBookingCheckStep,
  ReceptionBookingCheckSteps,
} from '@modules/reception/common/reception-booking-check-steps'
import { ReceptionBookingCheckInStepVerification } from '@modules/reception/checkin/step-verification'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { ReceptionBookingCheckInStepGuests } from '@modules/reception/checkin/step-guests'
import { ReceptionBookingCheckInStepDocuments } from '@modules/reception/checkin/step-documents'
import { ReceptionBookingCheckInStepAccess } from '@modules/reception/checkin/step-access'
import { AlertDanger } from '@components/alerts'
import { ReceptionBookingCheckInStepPayments } from '@modules/reception/checkin/step-payments'
import { ReceptionCheckHeader } from '@modules/reception/common/reception-check-header'
import { ReceptionBookingCheckInStepImprovements } from '@modules/reception/checkin/step-improvements'
import { ReceptionBookingCheckInStepFeeding } from '@modules/reception/checkin/step-feeding'
import { useStepsWithFeeding } from '@modules/reception/checkin/use-steps-with-feeding'
import { useBookingFeedingAvailability } from '@components/hooks/use-booking-feeding-availability'
import { receptionBookingDetailsSelector } from '@store/selectors/reception'
import { BaseModalProps } from '@components/modals/types'
import { commonObjectPost } from '@store/actions/generic-actions'

interface Props extends BaseModalProps {
  booking: ReceptionBooking
  initialStep?: number
}

const checkInSteps: ReceptionBookingCheckStep[] = [
  {
    step: 1,
    description: (
      <>
        Weryfikacja <br /> rezerwacji
      </>
    ),
  },
  {
    step: 2,
    description: (
      <>
        Mieszkańcy <br /> lokalu
      </>
    ),
  },
  {
    step: 3,
    description: (
      <>
        Wydruk <br /> potwierdzeń
      </>
    ),
  },
  {
    step: 4,
    description: (
      <>
        Ulepszenia <br />
      </>
    ),
  },
  {
    step: 5,
    description: <>Wyżywienie</>,
  },
  {
    step: 6,
    description: (
      <>
        Podsumowanie <br /> i płatności
      </>
    ),
  },
  {
    step: 7,
    description: (
      <>
        Wydanie <br /> dostępu
      </>
    ),
  },
]

export const ReceptionBookingCheckInDialog: React.FC<Props> = ({ booking, toggleIsVisible, initialStep }) => {
  const [step, setStep] = React.useState(initialStep || 1)
  const dispatch = useAppDispatch()

  const handleIsOpen = async () => {
    toggleIsVisible()
    await commonObjectPost<ReceptionBookingDetails>(bookingDetails?.urls.check_status, { action: 'check-in-cancel' })
  }

  const bookingDetails = useAppSelector(receptionBookingDetailsSelector)

  const { isAvailable: hasFeedingAvailable } = useBookingFeedingAvailability(bookingDetails)

  const { dynamicSteps, getDynamicStep } = useStepsWithFeeding(checkInSteps, hasFeedingAvailable, 5)

  React.useEffect(() => {
    if (bookingDetails?.id) {
      commonObjectPost<ReceptionBookingDetails>(bookingDetails.urls.check_status, { action: 'check-in-started' })
    }
  }, [bookingDetails?.id])

  React.useEffect(() => {
    dispatch(getReceptionBookingDetails(booking.urls.api_details, { action: 'check-in' }))
    dispatch(getResortDetails(booking.resort_id, booking.accommodation_type_id, booking.date_from, booking.date_to))
  }, [])

  const previousStep = () => setStep(Math.max(step - 1, 1))
  const nextStep = () => setStep(step + 1)

  return (
    <>
      <ReceptionCheckHeader
        title={
          <>
            <span className="text-semi-strong">Proces meldowania rezerwacji</span>{' '}
            <strong>{bookingDetails?.reservation_number}</strong>
          </>
        }
        handleIsOpen={handleIsOpen}
        bookingDetails={bookingDetails}
      />
      <LoaderPlaceholder content={bookingDetails && typeof hasFeedingAvailable !== 'undefined'} className="p-2">
        <ReceptionBookingCheckSteps step={step} steps={dynamicSteps} />
        {bookingDetails?.checked_in && (
          <ModalBody>
            <AlertDanger>Uwaga, rezerwacja została już zameldowana!</AlertDanger>
          </ModalBody>
        )}

        {bookingDetails && step === 1 && (
          <ReceptionBookingCheckInStepVerification nextStep={nextStep} booking={bookingDetails} />
        )}
        {bookingDetails && step === 2 && (
          <ReceptionBookingCheckInStepGuests previousStep={previousStep} nextStep={nextStep} booking={bookingDetails} />
        )}
        {bookingDetails && step === 3 && (
          <ReceptionBookingCheckInStepDocuments
            previousStep={previousStep}
            nextStep={nextStep}
            booking={bookingDetails}
          />
        )}
        {bookingDetails && step === 4 && (
          <ReceptionBookingCheckInStepImprovements
            previousStep={previousStep}
            nextStep={nextStep}
            booking={bookingDetails}
          />
        )}
        {bookingDetails && hasFeedingAvailable && step === 5 && (
          <ReceptionBookingCheckInStepFeeding
            title={`${step}. Wybór wyżywienia`}
            previousStep={previousStep}
            nextStep={nextStep}
            booking={bookingDetails}
          />
        )}
        {bookingDetails && step === getDynamicStep(6) && (
          <ReceptionBookingCheckInStepPayments
            title={`${getDynamicStep(6)}. Podsumowanie i płatności`}
            previousStep={previousStep}
            nextStep={nextStep}
            booking={bookingDetails}
          />
        )}
        {bookingDetails && step === getDynamicStep(7) && (
          <ReceptionBookingCheckInStepAccess
            title={`${getDynamicStep(7)}. Wydanie dostępu`}
            previousStep={previousStep}
            nextStep={nextStep}
            handleIsOpen={handleIsOpen}
            booking={bookingDetails}
          />
        )}
      </LoaderPlaceholder>
    </>
  )
}
