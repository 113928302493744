import * as React from 'react'
import { PaymentDayReport } from '@models/payments'
import { Col, Row, UncontrolledTooltip } from 'reactstrap'
import { formatDate, parseISODate } from '@helpers/date-helper'
import { formatPrice } from '@helpers/utils'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { isToday } from 'date-fns'

interface Props {
  row: PaymentDayReport
}

export const PaymentRegisterCashboxWithMonthDaysRow = ({ row }: Props): JSX.Element => {
  const hasAlert = row.status === 'open' && !isToday(parseISODate(row.date) || 0)

  return (
    <Row
      className={classNames('py-2 payment-cashbox__day-rows__details_row align-items-center bg-grey-light', {
        'border-left-danger': hasAlert,
      })}
    >
      <Col
        md={2}
        className={classNames({
          'text-danger text-strong': hasAlert,
        })}
      >
        {formatDate(row.date, 'dd.MM')}
      </Col>
      <Col md={2}>
        <span className="font-11 d-block">Sprzedaż w recepcji</span>
        <strong className="font-14" id={`reception-revenue-${row.id}`}>
          {formatPrice(row.reception_revenue)}
        </strong>
        <UncontrolledTooltip placement="bottom" target={`reception-revenue-${row.id}`}>
          <div className="font-11">
            Gotówka: <strong>{formatPrice(row.reception_revenue_cash)}</strong> <br />
            Karta: <strong>{formatPrice(row.reception_revenue_cards)}</strong>
          </div>
        </UncontrolledTooltip>
      </Col>
      <Col md={2}>
        <span className="font-11 d-block">Utarg w punktach</span>
        <strong className="font-14">{formatPrice(row.points_revenue)}</strong>
      </Col>
      <Col md={2}>
        <span className="font-11 d-block">Przychód razem</span>
        <strong className="font-14">{formatPrice(row.total_revenue)}</strong>
      </Col>
      <Col md={1} className="text-center">
        <i className="uil-print cursor-pointer font-16" />
      </Col>
      <Col md={2}>
        <Link
          to={NavigationPath.PaymentRegisterCashboxDayDetails.replace(':resort_id', String(row.resort_id)).replace(
            ':day_id',
            String(row.id),
          )}
          className="btn btn-light"
        >
          Karta rozliczenia dnia
        </Link>
      </Col>
    </Row>
  )
}
