import * as React from 'react'
import { useParams } from 'react-router-dom'
import { ReceptionWebSocketHandler } from '@modules/reception/reception-web-socket-handler'
import { getReceptionBookingDetails, getReceptionBookingUrl, getResortDetails } from '@store/actions/reception-actions'
import { useAppDispatch, useAppSelector } from '@store/index'
import { useModal } from '@components/modals/use-modal'
import { receptionBookingDetailsSelector } from '@store/selectors/reception'

export const ReceptionBookingCheckInView: React.FC = () => {
  const { booking_id } = useParams<'booking_id'>()
  const dispatch = useAppDispatch()

  const booking = useAppSelector(receptionBookingDetailsSelector)

  const [toggleIsOpen] = useModal('ReceptionBookingCheckInDialog', {
    initialStep: 4,
  })

  const fetchBooking = async () => {
    const bookingDetails = await dispatch(getReceptionBookingDetails(getReceptionBookingUrl(booking_id || '')))
    toggleIsOpen(null, {
      booking: bookingDetails,
    })
  }

  React.useEffect(() => {
    if (booking) {
      dispatch(getResortDetails(booking.resort_id, booking.accommodation_type_id))
    }
  }, [booking])

  React.useEffect(() => {
    fetchBooking()
  }, [])

  return <ReceptionWebSocketHandler />
}
