import * as React from 'react'
import TimelineDaysNavbar from '@modules/reservations/timeline/timeline-days-navbar'
import { CalendarDate } from '@store/reducers/timeline-reducers'
import classNames from 'classnames'
import styleHelper from '@helpers/style-helper'
import store from '@store/index'

interface Props {
  calendarDates: CalendarDate[]
  resort: string
  accommodationTypeIds: string[]
  isSticky: boolean
  infiniteScrollerRef: React.RefObject<HTMLDivElement>
  dayWrapperRef: React.RefObject<HTMLDivElement>
}

const TimelineDaysNavbarWrapper: React.FC<Props> = ({
  calendarDates,
  resort,
  accommodationTypeIds,
  isSticky,
  infiniteScrollerRef,
  dayWrapperRef,
}) => {
  React.useEffect(() => {
    if (dayWrapperRef.current) {
      dayWrapperRef.current.scrollLeft = store.getState().timelineState.scrollPosition
    }
  }, [isSticky])

  const isSecondToLast = (arr: any[], index: number): boolean => index === arr.length - 2

  const isSecondToFirst = (arr: any[], index: number): boolean => index === 1

  const isMiddle = (arr: any[], index: number): boolean => index === Math.floor(calendarDates.length / 2)

  const getWidth = (): string =>
    isSticky && infiniteScrollerRef.current ? styleHelper.valueToPX(infiniteScrollerRef.current.offsetWidth) : '100%'

  const onScrollFix = (dayNavbarRef: React.RefObject<HTMLDivElement>, inlinePos: ScrollLogicalPosition): void => {
    if (dayNavbarRef.current) {
      dayNavbarRef.current.scrollIntoView({ inline: inlinePos, block: 'nearest' })

      if (isSticky && infiniteScrollerRef.current && dayWrapperRef.current) {
        infiniteScrollerRef.current.scrollLeft = dayWrapperRef.current.scrollLeft
      }
    }
  }

  return (
    <div
      className={classNames('d-flex calendar__days-wrapper', { 'is-sticky': isSticky })}
      style={{ width: getWidth() }}
      ref={dayWrapperRef}
    >
      {calendarDates.map((date, i) => (
        <TimelineDaysNavbar
          key={i}
          date={date}
          resort={resort}
          accommodationTypeIds={accommodationTypeIds}
          isSecondToLast={isSecondToLast(calendarDates, i)}
          isSecondToFirst={isSecondToFirst(calendarDates, i)}
          isMiddle={isMiddle(calendarDates, i)}
          onScrollFix={onScrollFix}
        />
      ))}
    </div>
  )
}

export default TimelineDaysNavbarWrapper
