import * as React from 'react'
import { GastroCard, GastroCardKind } from '@models/promotions'
import { formatPrice } from '@helpers/utils'
import { toDefaultDateFormat } from '@helpers/date-helper'
import Table from '@components/table/table'
import { Source } from '@models/reports'
import { TableSellerRow } from '@components/table-seller-row'
import { CopyToClipboard } from '@components/copy-to-clipboard'
import { StatusBadge } from '@modules/promotions/common/status-badge'
import { isGastroGenericProduct } from '@modules/promotions/gastro-cards/utils'
import classnames from 'classnames'
import {
  getGastroGenericProductTag,
  getGastroProductPercentageBonus,
} from '@modules/promotions/gastro-generic-product/utils'
import { selectProductsAppData } from '@store/slices/products-slice'
import { useAppSelector } from '@store/index'
import { useDetailsModal } from '@components/hooks/use-details-modal'
import { gastroGenericScopes } from '@modules/promotions/gastro-generic-product/dialog/gastro-generic-product-dialog-data-step'

interface GastroCardsTableRowProps {
  gastroCard: GastroCard
  sources: Source[]
  canDeleteWithInvoice: boolean
  children: React.ReactNode
  canDelete: boolean
  kind?: GastroCardKind
  basePath: string
  pathWithParams: string
}

export const GastroCardsTableRow: React.FC<GastroCardsTableRowProps> = ({
  gastroCard,
  canDeleteWithInvoice,
  canDelete,
  sources,
  children,
  kind,
  basePath,
  pathWithParams,
}) => {
  const { gastro_card_tags } = useAppSelector(selectProductsAppData)

  const { handleClientDetails } = useDetailsModal(
    ['GastroCardDetailsDialog', { gastroCard }],
    basePath,
    pathWithParams,
    gastroCard.id,
  )

  const source = React.useMemo(
    () => sources.find(row => row.id === gastroCard.source_marketing)?.name || 'brak',
    [sources, gastroCard.source_marketing],
  )

  const isDeleteAble = (gastroCard.has_invoice && canDeleteWithInvoice) || (!gastroCard.has_invoice && canDelete)

  const percentageBonus = getGastroProductPercentageBonus(gastroCard)

  const scopes = React.useMemo(
    () =>
      (gastroCard.wallet?.scopes ?? []).reduce((prev, curr) => {
        const scope = gastroGenericScopes.find(row => row.scope === curr)
        return scope ? `${prev} ${prev ? ', ' : ''} ${scope.scope_display}` : prev
      }, ''),
    [gastroCard.wallet?.scopes],
  )

  return (
    <Table.Row onClick={handleClientDetails} className="cursor-pointer">
      {isDeleteAble && ['initial', 'confirmed'].includes(gastroCard.status) ? children : <Table.Cell />}
      <Table.Cell className="text-nowrap">
        {gastroCard.code} <CopyToClipboard className="ml-1 font-14" value={gastroCard.code} />
      </Table.Cell>
      <Table.Cell>{gastroCard.client?.name}</Table.Cell>
      <Table.Cell>
        <StatusBadge element={gastroCard} />
      </Table.Cell>
      {kind !== 'gastro_card_compensation' && <Table.Cell>{formatPrice(gastroCard.sell_price)}</Table.Cell>}
      <Table.Cell>{formatPrice(gastroCard.rest_to_use)}</Table.Cell>
      <Table.Cell>{formatPrice(gastroCard.declared_sell_price)}</Table.Cell>
      {isGastroGenericProduct(kind) && (
        <>
          {kind !== 'gastro_card_compensation' && <Table.Cell style={{ width: 100 }}>{percentageBonus}</Table.Cell>}
          {kind !== 'gastro_card_compensation' && (
            <Table.Cell>
              <ul className="list-unstyled">
                {gastroCard.tags?.length
                  ? gastroCard.tags.map((tag, index) => (
                      <li className={classnames({ 'mt-1': index > 0 })} key={tag}>
                        {getGastroGenericProductTag(tag, gastro_card_tags)?.name}
                      </li>
                    ))
                  : 'Brak'}
              </ul>
            </Table.Cell>
          )}
        </>
      )}
      {kind !== 'gastro_card_compensation' && (
        <Table.Cell>
          <span className="text-nowrap">{source}</span>
        </Table.Cell>
      )}
      {kind === 'gastro_card_compensation' && <Table.Cell>{scopes}</Table.Cell>}
      <Table.Cell>{toDefaultDateFormat(gastroCard.sell_date)}</Table.Cell>
      <Table.Cell>
        <TableSellerRow sellerId={gastroCard.seller_id} defaultValue={gastroCard.seller} className="d-block" />
        <small className="font-10">{toDefaultDateFormat(gastroCard.sell_date)}</small>
      </Table.Cell>
    </Table.Row>
  )
}
