import * as React from 'react'
import { Col, Row } from 'reactstrap'
import { ReceptionBookingCheckInStepPaymentsBoxPreauthorized } from '@modules/reception/checkin/step-payments/step-payments-box-preauthorized'
import { asDecimal, formatPriceShort } from '@helpers/utils'
import { ReceptionBookingCheckPaymentsBoxCheckoutCash } from '@modules/reception/common/reception-booking-check-payments-box-checkout/step-payments-box-checkout-cash'
import { ReceptionBookingDetails } from '@models/reception'
import { ButtonWithIcon } from '@components/button-with-icon'
import classNames from 'classnames'

interface Props {
  booking: ReceptionBookingDetails
  className?: string
}

export const ReceptionBookingCheckInStepPaymentsBoxDeposit: React.FC<Props> = ({ booking, className }) => {
  const [paymentMethod, setPaymentMethod] = React.useState<'cash' | 'card' | undefined>(undefined)

  const togglePayByCash = () => setPaymentMethod('cash')
  const togglePayByCard = () => setPaymentMethod('card')
  const handleCancel = () => setPaymentMethod(undefined)

  const isPaid = React.useMemo(
    () => asDecimal(booking.required_deposit_amount).lessThanOrEqualTo(asDecimal(0)),
    [booking.required_deposit_amount],
  )

  return (
    <div className={classNames('rounded p-3 bg-platinum', className)}>
      {isPaid ? (
        <div className="text-success text-center text-strong font-18 pb-1">
          <i className="uil-check d-block font-36" /> Kaucja została opłacona w całości
        </div>
      ) : (
        <>
          <strong className="d-block mb-2">
            Kaucja do wpłaty:
            <span className="text-danger"> {formatPriceShort(booking.required_deposit_amount)}</span>
          </strong>

          <Row className="mb-3">
            <Col md={6}>
              <ButtonWithIcon
                btnClass={classNames('w-100', {
                  'opacity-5': paymentMethod === 'card',
                })}
                icon="uil-money-bill"
                text="Gotówka"
                color="primary"
                handleClick={togglePayByCash}
              />
            </Col>
            <Col md={6}>
              <ButtonWithIcon
                btnClass={classNames('w-100', {
                  'opacity-5': paymentMethod === 'cash',
                })}
                icon="uil-receipt-alt"
                text="Karta"
                color="primary"
                handleClick={togglePayByCard}
              />
            </Col>
          </Row>
          {!!paymentMethod && <div className="hr hr-grey-2 mb-2" />}

          {paymentMethod === 'cash' && (
            <ReceptionBookingCheckPaymentsBoxCheckoutCash
              booking={booking}
              handleCancel={handleCancel}
              restToPay={asDecimal(booking.required_deposit_amount)}
              paymentParams={{ type: 'deposit' }}
            />
          )}
          {paymentMethod === 'card' && (
            <ReceptionBookingCheckInStepPaymentsBoxPreauthorized handleCancel={handleCancel} booking={booking} />
          )}
        </>
      )}
    </div>
  )
}
