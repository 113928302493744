import * as React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { NotificationMessageModalConfig } from '@hyper/notifications-models'

interface Props {
  isOpen: boolean
  handleClose: () => void
  message?: React.ReactNode
  title?: React.ReactNode
  buttonText?: string
  action?: (onHide: () => void, messageId: string) => React.ReactNode
  onBeforeClose?: () => void
  modalConfig?: NotificationMessageModalConfig
  messageId: string
}

export const WarningModal: React.FC<Props> = ({
  isOpen,
  handleClose,
  message,
  title,
  buttonText,
  onBeforeClose,
  action,
  messageId,
  modalConfig = {
    isModalCloseAllowed: true,
  },
}) => {
  const [isShow, setIsShow] = React.useState(isOpen)

  const handleHide = () => {
    onBeforeClose?.()
    setIsShow(false)
  }

  return (
    <Modal
      size="sm"
      isOpen={isShow}
      toggle={handleHide}
      onClosed={handleClose}
      role="success-modal"
      backdrop={!modalConfig.isModalCloseAllowed ? 'static' : undefined}
      keyboard={modalConfig.isModalCloseAllowed}
      data-testid="success-modal"
    >
      <ModalBody className="modal-colored-header bg-warning text-center py-3">
        <i className="uil-exclamation-triangle h1" style={{ fontSize: '55px' }} />
        <h4 className="mt-2" role="warning-modal-title">
          {title}
        </h4>
        <div className="my-3 text-semi-strong" data-testid="warning-modal-message">
          {message}
        </div>
        {action?.(handleHide, messageId) ?? (
          <button
            type="button"
            className="btn btn-light my-2"
            data-dismiss="modal"
            onClick={handleHide}
            data-testid="warning-modal-ok"
          >
            {buttonText || 'OK'}
          </button>
        )}
      </ModalBody>
    </Modal>
  )
}
