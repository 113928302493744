import * as React from 'react'
import { formatPriceShort } from '@helpers/utils'
import { Col } from 'reactstrap'

interface Props {
  voucherOwner: string
  amount: number
}

export const StepVerificationVoucherSummaryRow: React.FC<Props> = ({ voucherOwner, amount }) => (
  <Col className="font-11 mb-2">
    <div>
      <span>Voucher przypisany do adresu: </span>
      <strong className="text-dark">{voucherOwner}</strong>
    </div>
    <div>
      <span>Pozostała kwota: </span>
      <strong className="text-dark">{formatPriceShort(amount)}</strong>
    </div>
  </Col>
)
