import * as React from 'react'
import { Col, Row } from 'reactstrap'
import { FormInput } from '@hyper/forms'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectGet } from '@store/actions/generic-actions'
import { asDecimal, formatPrice } from '@helpers/utils'
import { ContentLoader } from '@components/content-loader'
import { ButtonWithIcon } from '@components/button-with-icon'
import { useModal } from '@components/modals/use-modal'
import { CashCount } from '@modules/payment-registers/cash-count-modal'
import { cashCountEmptyValues, useCashCount } from '@modules/payment-registers/cash-count-modal/use-cash-count'
import { useFormContext, useWatch } from 'react-hook-form'

interface SystemCashAmount {
  safe_end_amount: string
}

interface Props {
  providerUrl: string
  resortId: number
}

export const PaymentCloseDaySafeAmount = ({ providerUrl, resortId }: Props): JSX.Element => {
  const [safeEndAmount, setSafeEndAmount] = React.useState<string>()
  const [cashCountValues, setCashCountValues] = React.useState<CashCount>(cashCountEmptyValues)
  const { totalCashCount } = useCashCount(cashCountValues)

  const { isLoading, action: fetch } = useApiRequest(async () => {
    const response = await commonObjectGet<SystemCashAmount>(providerUrl)
    setSafeEndAmount(response.safe_end_amount)
  })

  React.useEffect(() => {
    fetch()
  }, [])

  const [handleCashCount] = useModal('CashCountModal', {
    setCashCountValues,
    cashCountValues,
    resort: resortId,
    allowDownloadReport: true,
  })

  const { setValue, control } = useFormContext()

  React.useEffect(() => {
    setValue('safe_amount', String(totalCashCount))
  }, [cashCountValues])

  const safeAmount = useWatch({ control, name: 'safe_amount' })

  const diff = asDecimal(safeAmount).minus(asDecimal(safeEndAmount || 0))

  return (
    <ContentLoader isLoading={isLoading}>
      <Row className="mx-0 align-items-center">
        <Col md={5}>
          <strong>Stan sejfu według systemu:</strong>
        </Col>
        <Col md={6}>
          <div className="d-flex align-items-center">
            <i className="uil-usd-circle text-muted font-16" />
            <div>
              <Col md={12}>{formatPrice(safeEndAmount || 0)}</Col>
            </div>
          </div>
        </Col>
      </Row>
      <div className="hr hr-grey my-2" />
      <Row className="mx-0">
        <Col md={5} className="align-self-center">
          <strong>Rzeczywisty stan sejfu:</strong>
        </Col>
        <Col md={7} className="align-items-center d-flex">
          <i className="uil-usd-circle text-muted font-16" />
          <FormInput colSize={5} name="safe_amount" type="currency" formGroupClassName="m-0" colClassName="pr-0" />
          <strong className="ml-1">zł</strong>
          <ButtonWithIcon
            icon="uil-money-withdraw"
            color="outline-secondary"
            text="Przelicz gotówkę"
            handleClick={handleCashCount}
            btnClass="ml-auto"
          />
        </Col>
      </Row>
      {diff.gt(asDecimal(0)) && (
        <Row>
          <Col md={7} className="ml-auto mt-1" style={{ paddingLeft: '43px' }}>
            <span className="font-12">
              Nadwyżka: <span className="font-weight-semibold">+{formatPrice(diff.toString())}</span>
            </span>
          </Col>
        </Row>
      )}
    </ContentLoader>
  )
}
