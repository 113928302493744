import * as React from 'react'
import { Card, CardBody } from 'reactstrap'
import { useSubscriptionAppData } from '@modules/subscription/hooks/use-subscription-app-data'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { useTableFilters } from '@components/table/use-table-filters'
import { PageTitleWithSize } from '@hyper/page-title-with-size'
import { FloorHeatingListFilter, FloorHeatingListFilterParams } from '@modules/floor-heating/list/filter'
import { FloorHeatingListTable } from '@modules/floor-heating/list/table'
import { useFloorHeatingMeasurements } from '@modules/floor-heating/list/use-floor-heating-measurements'
import { useFloorHeatingAppData } from '@modules/floor-heating/use-floor-heating-app-data'

const defaultFilters: FloorHeatingListFilterParams = {
  search: '',
  ordering: '-apartment_name',
  page: 1,
  page_size: 10,
}

export const FloorHeatingListView: React.FC = () => {
  useSubscriptionAppData()
  useMenuActive(NavigationPath.FloorHeating)

  const { fetchMeasurements, measurements } = useFloorHeatingMeasurements()
  const { isLoading: isFetchingFloorHeatingAppData } = useFloorHeatingAppData()

  const { isLoading, filters, setFilters } = useTableFilters<FloorHeatingListFilterParams>({
    defaultFilters,
    action: fetchMeasurements,
  })

  return (
    <>
      <PageTitleWithSize title="Ogrzewanie podłogowe" />
      <Card>
        <CardBody>
          <FloorHeatingListFilter defaultFilters={defaultFilters} filters={filters} setFilters={setFilters} />
          <FloorHeatingListTable
            isLoading={isLoading || isFetchingFloorHeatingAppData}
            measurements={measurements}
            filters={filters}
            setFilters={setFilters}
          />
        </CardBody>
      </Card>
    </>
  )
}
