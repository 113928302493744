import * as React from 'react'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'
import axios from 'axios'

export function SmallSearchbar({ quickSearchUrl }) {
  const [isOpen, setIsOpen] = React.useState(false)

  function toggleDropdown() {
    setIsOpen(!isOpen)
  }

  async function onSubmitSearchHandler(event) {
    event.preventDefault()

    const query = event.target.elements.query.value

    const { data } = await axios.get(quickSearchUrl, {
      params: { query },
      headers: { 'X-Requested-With': 'XMLHttpRequest' },
    })
    document.location.href = data.redirect_url
  }

  return (
    <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
      <DropdownToggle
        data-toggle="dropdown"
        tag="button"
        className="nav-link notification-list__search-toggle"
        onClick={toggleDropdown}
      >
        <i className="uil-search noti-icon" />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu dropdown-menu-animated dropdown-lg p-0">
        <form className="p-3" role="search" onSubmit={onSubmitSearchHandler}>
          <input type="text" className="form-control" name="query" placeholder="Wpisz ..." />
        </form>
      </DropdownMenu>
    </Dropdown>
  )
}
