import * as React from 'react'
import { Spinner } from 'reactstrap'
import classNames from 'classnames'
import { BaseSyntheticEvent } from 'react'
import { SpinnerProps } from 'reactstrap/es/Spinner'

export interface Props {
  isSaving: boolean
  label?: React.ReactNode
  labelSaving?: React.ReactNode
  className?: string
  role?: string
  onClick?: (event?: BaseSyntheticEvent) => void
  disabled?: boolean
  type?: 'button' | 'submit'
  spinnerProps?: SpinnerProps
}

export const SaveButton: React.FC<Props> = ({
  type,
  isSaving,
  label,
  labelSaving,
  className,
  onClick,
  role,
  disabled,
  spinnerProps,
}) => (
  <button
    onClick={onClick}
    role={role || 'submit'}
    type={type || 'submit'}
    data-testid="save-button"
    disabled={isSaving ? true : disabled}
    className={classNames('text-nowrap', className, { 'btn-primary btn': !className })}
  >
    {isSaving ? (
      <>
        <Spinner size="sm" {...spinnerProps} className={classNames(spinnerProps?.className, 'mr-1')} />
        {labelSaving || 'Zapisywanie..'}
      </>
    ) : (
      label || 'Zapisz'
    )}
  </button>
)
