import * as React from 'react'
import { ReservationListType } from '@modules/reservations/list/models'
import { NavigationTabs, NavigationTabsButton } from '@components/navigation-tabs'
import { NavigationPath } from '@models/routes'

interface ReservationTypesProps {
  type: ReservationListType
}

export const ReservationTypes: React.FC<ReservationTypesProps> = ({ type }) => {
  const buttons: NavigationTabsButton[] = [
    {
      title: 'Wstępne i potwierdzone',
      isActive: type === ReservationListType.Present,
      link: NavigationPath.ReservationListWithParams.replace(':type', String(ReservationListType.Present)),
    },
    {
      title: 'Rozliczone',
      isActive: type === ReservationListType.Close,
      link: NavigationPath.ReservationListWithParams.replace(':type', String(ReservationListType.Close)),
    },
    {
      title: 'Nierozliczone',
      isActive: type === ReservationListType.Unclosed,
      link: NavigationPath.ReservationListWithParams.replace(':type', String(ReservationListType.Unclosed)),
    },
    {
      title: 'Anulowane',
      isActive: type === ReservationListType.Cancelled,
      link: NavigationPath.ReservationListWithParams.replace(':type', String(ReservationListType.Cancelled)),
    },
    {
      title: 'Niedokończone',
      isActive: type === ReservationListType.Unfinished,
      link: NavigationPath.ReservationListWithParams.replace(':type', String(ReservationListType.Unfinished)),
    },
  ]

  return <NavigationTabs buttons={buttons} />
}
