import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { useForm } from 'react-hook-form'
import { parseISODate } from '@helpers/date-helper'
import { RootState, useAppSelector } from '@store/index'
import classNames from 'classnames'
import { SpinInput } from '@components/spin-input'
import DatePickerInput from '@components/date/date-picker-input'
import { useModal } from '@components/modals/use-modal'

interface Props {
  booking: ReceptionBookingDetails
  onClose: () => void
  handleDelete: () => void
}

class FormInputs {
  amount: number
  date_from: Date | null
  date_to: Date | null
}

export const ReceptionBookingCheckInImprovementsHelicopterFlight = ({
  booking,
  onClose,
  handleDelete,
}: Props): JSX.Element | null => {
  const improvement = (
    useAppSelector((state: RootState) => state.receptionState.resortDetails?.improvements) || []
  ).find(improvement => improvement.code === 'helicopter_flight')

  const methods = useForm<FormInputs>({
    defaultValues: {
      amount: 1,
      date_from: parseISODate(booking.date_from),
      date_to: parseISODate(booking.date_to),
    },
  })

  const [handleOpenModal] = useModal('BookingImprovementAddByCodeModal')

  if (!improvement) {
    return null
  }

  const handleAdd = (event: React.SyntheticEvent) => {
    handleOpenModal(event, {
      ...methods.getValues(),
      improvementCode: improvement.code,
      booking,
      onClose,
    })
  }

  return (
    <>
      <td
        className={classNames('reception__booking-check-in__improvement-step__amount-col', {
          'reception__booking-check-in__improvement-step--inactive-col': false,
        })}
      >
        <SpinInput inputName="amount" inputProps={{ min: 1, disabled: true }} />
      </td>
      <td>
        <div className="d-flex justify-content-between align-items-center">
          <DatePickerInput
            minDate={parseISODate(booking.date_from)}
            maxDate={parseISODate(booking.date_to)}
            control={methods.control}
            name="date_from"
          />
          <i
            className="uil-trash-alt cursor-pointer font-18 grey-400"
            onClick={handleDelete}
            role="delete-improvement"
          />
        </div>
      </td>
      <td className="text-right">
        <button
          onClick={handleAdd}
          type="button"
          className="btn btn-secondary text-nowrap reception__booking-check-in__improvement-step__price-button"
        >
          Dodaj
        </button>
      </td>
    </>
  )
}
