import { AsyncThunkParams } from '@store/index'
import { commonObjectGet } from '@store/actions/generic-actions'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { PaginationResponse } from '@models/dashboard'
import { setDashboardStandardPaginator } from '@store/actions/dashboard-actions'
import { Agreement, AgreementDetails, HRAppData } from '@modules/hr/agreement/models'
import { extractSelectOptionsValues } from '@helpers/utils'
import { AgreementFilterParams } from '@modules/hr/agreement/list/filter'
import * as R from 'ramda'

export const getAgreements = createAsyncThunk<Agreement[], AgreementFilterParams, AsyncThunkParams>(
  'hr/getAgreements',
  async (filters, { getState, dispatch }) => {
    const url = getState().appState.appData.urls.hr.agreements

    const data = await commonObjectGet<PaginationResponse<Agreement>>(url, {
      ...extractSelectOptionsValues(filters),
      high_priority: false,
    })
    dispatch(setDashboardStandardPaginator({ ...data }))
    return data.results
  },
)

export const getHighPriorityAgreements = createAsyncThunk<Agreement[], AgreementFilterParams, AsyncThunkParams>(
  'hr/getHighPriorityAgreements',
  async (filters, { getState }) => {
    const data = await commonObjectGet<PaginationResponse<Agreement>>(getState().appState.appData.urls.hr.agreements, {
      ...R.omit(['ordering', 'page', 'page_size'], extractSelectOptionsValues(filters)),
      high_priority: true,
    })

    return data.results
  },
)

export const getAgreementDetails = createAsyncThunk<AgreementDetails, number, AsyncThunkParams>(
  'hr/getAgreementDetails',
  async (id, { getState }) =>
    await commonObjectGet<AgreementDetails>(`${getState().appState.appData.urls.hr.agreements}${id}/`, id),
)

export const getHRAppData = createAsyncThunk<HRAppData, void, AsyncThunkParams>(
  'hr/getAppData',
  async (_, { getState }) => await commonObjectGet<HRAppData>(getState().appState.appData.urls.hr.app_data),
)
