import * as React from 'react'
import { GenericVoucherDetails } from '@modules/generic-vouchers/models'
import { Card, CardBody } from 'reactstrap'
import { ProductCardTitle } from '@components/products/product-card-title'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { SubscriptionDetailsInformationRow } from '@modules/subscription/details/information/row'
import classNames from 'classnames'
import { getGenericVoucherStatusColor } from '@modules/generic-vouchers/helpers'
import { toDefaultDateFormat, toDefaultDateTimeFormat } from '@helpers/date-helper'
import { YesNoBadge } from '@components/badges/yes-no'
import { GenericVoucherIssueVoucherButton } from '@modules/generic-vouchers/common/issue-voucher-button'

interface Props {
  genericVoucher: GenericVoucherDetails
}

export const GenericVoucherDetailsInformation: React.FC<Props> = ({ genericVoucher }) => {
  const user = useAuthenticatedUser()
  const promocodeAssigns = genericVoucher.promocode_assigns.at(-1)

  return (
    <Card>
      <CardBody>
        <ProductCardTitle
          title="Szczegóły Oferty"
          adminUrl={genericVoucher.urls.admin}
          isEditAllowed={user.is_superuser}
        />
        <table className="table table-sm mb-0">
          <tbody>
            <SubscriptionDetailsInformationRow name="Status">
              <span className={classNames('badge', getGenericVoucherStatusColor(genericVoucher.status))}>
                {genericVoucher.status_display}
              </span>
            </SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="Numer oferty">
              {genericVoucher.number}
            </SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="Data rejestracji">
              {toDefaultDateTimeFormat(genericVoucher.created)}
            </SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="Voucher Wydany">
              <GenericVoucherIssueVoucherButton genericVoucher={genericVoucher} />
            </SubscriptionDetailsInformationRow>
            {promocodeAssigns?.promocode && (
              <SubscriptionDetailsInformationRow name="Numer vouchera">
                <a href={user.is_superuser ? promocodeAssigns.promocode.urls.admin : '#'} target="_blank">
                  {promocodeAssigns?.promocode?.code}
                </a>
              </SubscriptionDetailsInformationRow>
            )}
            <SubscriptionDetailsInformationRow name="Voucher użyty">
              <YesNoBadge value={!!promocodeAssigns?.promocode?.is_used} />
            </SubscriptionDetailsInformationRow>
            {promocodeAssigns?.issue_datetime && promocodeAssigns?.promocode ? (
              <SubscriptionDetailsInformationRow name="Data ważności vouchera">
                {toDefaultDateFormat(promocodeAssigns?.promocode?.expire_after)}
              </SubscriptionDetailsInformationRow>
            ) : (
              <SubscriptionDetailsInformationRow name="Data ważności vouchera">brak</SubscriptionDetailsInformationRow>
            )}
          </tbody>
        </table>
      </CardBody>
    </Card>
  )
}
