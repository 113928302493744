import * as React from 'react'
import Table from '@components/table/table'
import { useModal } from '@components/modals/use-modal'
import { FeedingSpecialGuest } from '@modules/feeding/models'
import { resortsMapSelector } from '@store/selectors/dashboard'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { useAppSelector } from '@store/index'

interface Props {
  specialGuest: FeedingSpecialGuest
}

export const TableRowFeedingSpecialGuestTableRow: React.FC<Props> = ({ specialGuest }) => {
  const resortsMap = useAppSelector(resortsMapSelector)

  const [toggleEdit] = useModal('FeedingSpecialGuestDialog', { specialGuest })

  const resorts = React.useMemo(
    () => specialGuest.resorts.map(x => resortsMap[x]?.name || '').join(', '),
    [resortsMap, specialGuest],
  )

  return (
    <Table.Row onClick={toggleEdit}>
      <Table.Cell>{specialGuest.name}</Table.Cell>
      <Table.Cell>{resorts}</Table.Cell>
      <Table.Cell>{toDefaultDateFormat(specialGuest.date_to)}</Table.Cell>
      <Table.Cell>{specialGuest.is_active ? 'tak' : 'nie'}</Table.Cell>
    </Table.Row>
  )
}
