import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import classNames from 'classnames'

interface Props {
  booking: ReceptionBookingDetails
}

export const ReservationStatusLabel = ({ booking }: Props): JSX.Element => {
  const statusMapper = () => {
    if (booking.checked_in && booking.status === 'confirmed') return 'checked-in'

    switch (booking.status) {
      case 'system':
      case 'fake':
      case 'blocked':
      case 'unfinished':
        return 'Niedostępny'
      case 'initial':
      case 'initial_waiting':
        return 'initial'
      case 'confirmed':
        return 'confirmed'
      case 'confirmed_canceled':
      case 'initial_cancelled':
      case 'unfinished_canceled':
      case 'close':
        return 'finished'
      default:
        return ''
    }
  }

  return (
    <div className="reservation-details__label__wrapper">
      <StatusLabel
        status="initial"
        booking={booking}
        date={booking.created_at}
        isActive={statusMapper() === 'initial'}
      />
      <StatusLabel
        status="confirmed"
        date={booking.created_at}
        booking={booking}
        isActive={statusMapper() === 'confirmed'}
      />

      <StatusLabel
        status="checked-in"
        date={booking.created_at}
        booking={booking}
        isActive={statusMapper() === 'checked-in'}
      />

      <StatusLabel
        status="finished"
        date={booking.close_datetime}
        booking={booking}
        isActive={statusMapper() === 'finished'}
      />
    </div>
  )
}

const StatusLabel = ({
  status,
  date,
  booking,
  isActive,
}: {
  status: 'initial' | 'confirmed' | 'checked-in' | 'finished'
  date: string | null
  booking: ReceptionBookingDetails
  isActive: boolean
}) => {
  const statusUpperCaseFirstCharacter =
    status === 'checked-in' && booking.status === 'confirmed'
      ? 'Zameldowana'
      : booking.status_display.charAt(0).toUpperCase() + booking.status_display.slice(1)

  return (
    <div className={classNames('reservation-details__label', { 'is-active': isActive }, `is-${status}`)}>
      {isActive && (
        <>
          <span className="font-14 fw-semi-bold d-block mr-2">{statusUpperCaseFirstCharacter}</span>
          {date && <span className="font-10 flex-grow-1">od {toDefaultDateTimeFormat(date)}</span>}
          {booking.extended_stay && <div className="reservation-details__label__extended" />}
          {!!booking.package_vip && (
            <span className="reservation-details__label__vip" data-testid="vip-mark">
              <img src={require('@assets/images/vip-white.svg')} alt="vip" height="24" />
            </span>
          )}
        </>
      )}
    </div>
  )
}
