import * as React from 'react'
import { FormInput } from '@hyper/forms'
import { Col } from 'reactstrap'

interface Props {
  index: number
  remove: (index: number) => void
}

export const GuestsRowGuestsFeedingClientsCreateDialogGuestsRow: React.FC<Props> = ({ index, remove }) => {
  const handleRemove = () => remove(index)

  return (
    <>
      <Col md={6}>
        <FormInput name={`guests.${index}.name`} label="Imię i nazwisko" />
      </Col>
      <Col md={5}>
        <FormInput name={`guests.${index}.age`} label="Wiek" type="select">
          <option value="adult">Dorosły i młodzież</option>
          <option value="children_5_12">Dziecko od 5 do 12 lat</option>
          <option value="children_3_4">Dziecko od 2 do 5 lat</option>
          <option value="baby">Dziecko do 2 lat</option>
        </FormInput>
      </Col>
      <Col md={1}>
        <div className="action-icon text-center pt-3">
          <i className="uil uil-trash-alt cursor-pointer font-18 text-muted" title="Skasuj" onClick={handleRemove} />
        </div>
      </Col>
    </>
  )
}
