import * as React from 'react'
import { ReceptionBooking, ReceptionCheckType } from '@models/reception'
import { ReceptionCompleteCheckInRow } from '@modules/reception/complete-checkin/row/reception-complete-check-in-row'
import { ReceptionCompleteCheckinFiltersParams } from '@modules/reception/complete-checkin/reception-complete-checkin'
import { searchByBaseBookingParams, searchByCarRegisterNumber } from '@modules/reception/common/search'
import * as R from 'ramda'
import { filterByFeeding } from '@modules/reception/common/filters/feeding-filter'
import { ReceptionButtons } from '@modules/reception/common/reception-buttons'
import { startOfToday } from 'date-fns'
import { RootState, useAppSelector } from '@store/index'
import { hasVip } from '@helpers/improvements'
import { receptionBookingsListSorter } from '@modules/reception/common/tools'

interface ReceptionCompleteCheckinListProps {
  bookings: ReceptionBooking[]
  filters: ReceptionCompleteCheckinFiltersParams
  resortId: number
}

const onlyVipFilter = (filters: ReceptionCompleteCheckinFiltersParams) => (booking: ReceptionBooking) =>
  !filters.only_vip || hasVip(booking)

const bookingsFilter = (bookings: ReceptionBooking[], filters: ReceptionCompleteCheckinFiltersParams) =>
  [...bookings].filter((booking: ReceptionBooking) => {
    if (filters.search && filters.search.trim()) {
      const query = filters.search.trim().toLocaleLowerCase()
      if (!searchByBaseBookingParams(booking, query) && !searchByCarRegisterNumber(booking, query)) {
        return false
      }
    }
    return !(filters.feeding && !filterByFeeding(booking, filters.feeding))
  })

export const ReceptionCompleteCheckinList: React.FC<ReceptionCompleteCheckinListProps> = ({
  resortId,
  bookings,
  filters,
}) => {
  const [filteredBookings, setFilteredBookings] = React.useState<ReceptionBooking[]>(bookings)
  React.useEffect(() => {
    setFilteredBookings(
      receptionBookingsListSorter(bookingsFilter(bookings.filter(onlyVipFilter(filters)), filters), filters, [
        R.descend(R.prop('status')),
      ]),
    )
  }, [filters, bookings])

  const ids = filteredBookings.map(booking => booking.id)

  const resortDetails = useAppSelector((state: RootState) => state.receptionState.resortDetails)

  return (
    <>
      <table className="reception__bookings_table table table-striped">
        <tbody>
          {!filteredBookings.length ? (
            <tr>
              <td className="text-center">Brak rezerwacji</td>
            </tr>
          ) : (
            <>
              {filteredBookings.map(booking => (
                <ReceptionCompleteCheckInRow
                  hasBands={resortDetails?.has_band || false}
                  booking={booking}
                  key={`${booking.id}-${booking.apartment?.id}`}
                />
              ))}
            </>
          )}
        </tbody>
      </table>
      <ReceptionButtons
        ids={ids}
        date={startOfToday()}
        resortId={resortId}
        type={ReceptionCheckType.COMPLETE_CHECKIN}
      />
    </>
  )
}
