import { useModal } from '@components/modals/use-modal'
import * as React from 'react'
import { Button } from 'reactstrap'

interface Props {
  className?: string
}
export const CashRegisterStateListModalToggle = ({ className }: Props): JSX.Element => {
  const [handleOpenModal] = useModal('CashRegisterStateListModal')

  return (
    <Button className={className} color="primary" onClick={handleOpenModal}>
      Stan kas
    </Button>
  )
}
