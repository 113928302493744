import * as React from 'react'
import { useBenefitProgramAppData } from '@modules/benefit-program/hooks/use-benefit-program-app-data'

interface Props {
  advisorId: number | null
}

export const BenefitProgramAdvisor: React.FC<Props> = ({ advisorId }) => {
  const { benefit_program_advisors } = useBenefitProgramAppData()

  return React.useMemo(() => {
    const result = benefit_program_advisors.find(row => row.id === advisorId)
    return result ? `${result.first_name} ${result.last_name}` : ''
  }, [advisorId, benefit_program_advisors])
}
