import * as React from 'react'
import { CleaningOrder } from '@modules/housekeeping/models'
import { Button } from 'reactstrap'
import { HousekeepingCleaningOrderAcceptedByRow } from '@modules/housekeeping/cleaning-orders/common/accepted-by'
import { HousekeepingCleaningOrdersRowServiceKind } from '@modules/housekeeping/cleaning-orders/common/service-kind'
import { HousekeepingCleaningOrderOrderedByRow } from '@modules/housekeeping/cleaning-orders/common/ordered-by'
import { HousekeepingOrderWorkersRow } from '@modules/housekeeping/common/workers'
import { HousekeepingOrdersRowTotalTimeStarted } from '@modules/housekeeping/common/row-total-time-started'
import { HousekeepingCleaningOrdersRowKind } from '@modules/housekeeping/cleaning-orders/common/kind'
import { OrderNotes } from '@modules/housekeeping/cleaning-orders/common/row-notes'
import { useModal } from '@components/modals/use-modal'

interface HousekeepingCleaningOrderAcceptedRowProps {
  cleaningOrder: CleaningOrder
}

export const HousekeepingCleaningOrderAcceptedRow: React.FC<HousekeepingCleaningOrderAcceptedRowProps> = ({
  cleaningOrder,
}) => {
  const [toggleIsDetailsOpen] = useModal('HousekeepingCleaningOrderDetailsModal', {
    cleaningOrder,
  })

  return (
    <tr className="housekeeping__orders_table__row">
      <td className="housekeeping__orders_table__column text-semi-strong housekeeping__orders_table__column__first">
        {cleaningOrder.apartment.name}
      </td>
      <HousekeepingCleaningOrdersRowKind order={cleaningOrder} />
      <HousekeepingCleaningOrdersRowServiceKind id={cleaningOrder.id} order={cleaningOrder} />
      <HousekeepingCleaningOrderOrderedByRow
        ordered_at={cleaningOrder.ordered_at}
        ordered_by={cleaningOrder.ordered_by}
      />
      <HousekeepingOrderWorkersRow order={cleaningOrder} />
      <HousekeepingOrdersRowTotalTimeStarted totalTimeStarted={cleaningOrder.total_time_started} />
      <HousekeepingCleaningOrderAcceptedByRow
        accepted_at={cleaningOrder.accepted_at}
        accepted_by={cleaningOrder.accepted_by}
      />

      <td className="housekeeping__orders_table__column text-nowrap text-right">
        <OrderNotes order={cleaningOrder} />

        <Button color="green" onClick={toggleIsDetailsOpen}>
          Szczegóły
        </Button>
      </td>
    </tr>
  )
}
