import * as React from 'react'
import Table from '@components/table/table'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { useModal } from '@components/modals/use-modal'
import { AccountUsersListTableRowAcquire } from '@modules/account/users/list/table-row-acquire'
import { CallCenterUser } from '@models/account'
import { CallCenterUsersListTableRowActive } from '@modules/account/call-center-users/list/table-row-active'
import { useAppData } from '@components/hooks/use-app-data'
import { NavigationPath } from '@models/routes'

interface Props {
  user: CallCenterUser
}

export const CallCenterUsersListTableRow: React.FC<Props> = ({ user }) => {
  const [handleEdit] = useModal('CallCenterUserModal', { user })
  const [handleChangePassword] = useModal('AccountUserChangePassword', { url: user.urls.change_password })
  const { call_center_groups } = useAppData()

  const groups = React.useMemo(
    () =>
      user.groups
        .map(row => call_center_groups.find(group => group.id === row))
        .map(row => row?.name || '')
        .join(', '),
    [call_center_groups, user.groups],
  )

  return (
    <Table.Row>
      <Table.Cell>
        {user.first_name} {user.last_name}
      </Table.Cell>
      <Table.Cell>{user.email}</Table.Cell>
      <Table.Cell>{groups}</Table.Cell>
      <Table.Cell>{user.phone}</Table.Cell>
      <CallCenterUsersListTableRowActive user={user} />
      <Table.Cell>{toDefaultDateTimeFormat(user.last_login)}</Table.Cell>
      <Table.Cell className="text-right">
        <i
          className="uil-keyhole-square-full cursor-pointer text-muted font-18 mr-2"
          title="Zmień hasło"
          onClick={handleChangePassword}
        />
        {user.is_active && (
          <AccountUsersListTableRowAcquire returnUrl={NavigationPath.CallCenterUsers} acquire={user.urls.acquire} />
        )}

        <i className="uil-pen cursor-pointer text-muted font-18" onClick={handleEdit} />
      </Table.Cell>
    </Table.Row>
  )
}
