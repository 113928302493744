import * as React from 'react'
import { CSSProperties } from 'react'
import classNames from 'classnames'
import store, { RootState, useAppDispatch, useAppSelector } from '@store/index'
import timelineDragHelper from '@helpers/timeline-drag-helper'
import timelineHelper from '@helpers/timeline-helper'
import { XYCoord } from 'react-dnd'
import { TimelineResizerDragObject } from '@modules/reservations/timeline/timeline-content-droppable'
import { Apartment } from '@models/apartment'
import { CalendarDate } from '@store/reducers/timeline-reducers'
import styleHelper from '@helpers/style-helper'
import { setInitialDragData } from '@store/actions/timeline-actions'

interface Props {
  offsetDiff: XYCoord
  item: TimelineResizerDragObject
  apartments: Apartment[]
}

const TimelineItemResizerDragger: React.FC<Props> = props => {
  const dispatch = useAppDispatch()
  const [initialScroll, setInitialScroll] = React.useState<XYCoord>({ x: 0, y: 0 })
  const [initialDates, setInitialDates] = React.useState<CalendarDate[]>([])
  const isCompactView = useAppSelector((state: RootState) => state.timelineState.isCompactView)
  const renderedDates = useAppSelector((state: RootState) => state.timelineState.calendarDates.dates)

  React.useEffect(() => {
    dispatch(
      setInitialDragData({
        ...initialScroll,
        renderedDates: initialDates,
      }),
    )
  }, [initialDates, initialScroll])

  React.useEffect(() => {
    setInitialScroll({
      y: window.scrollY,
      x: store.getState().timelineState.scrollPosition,
    })
    setInitialDates(renderedDates)
  }, [])

  if (!initialDates.length) {
    return null
  }

  const initialItemPositionLeft = timelineDragHelper.getInitialItemPosition(
    props.item.booking.apartment,
    props.apartments,
    initialDates,
    props.item.booking.date_from,
  )

  const initialItemPositionRight = timelineDragHelper.getInitialItemPosition(
    props.item.booking.apartment,
    props.apartments,
    initialDates,
    props.item.booking.date_to,
  )

  const itemPositionLeft = timelineDragHelper.getInitialItemPosition(
    props.item.booking.apartment,
    props.apartments,
    renderedDates,
    props.item.booking.date_from,
  )

  const getLeftResizerLeftProp = (initialLeft: string, movedDays: number): string => {
    const val = styleHelper.pxToValue(initialLeft)
    return styleHelper.valueToPX(val + timelineHelper.getDayWidthMargin() * -movedDays)
  }

  const getResizeBookingStyle = (): CSSProperties => {
    const days = timelineDragHelper.getResizeDays(
      props.offsetDiff,
      initialItemPositionRight,
      initialItemPositionLeft,
      initialScroll,
      props.item.direction,
      props.item.booking,
    )
    const { left, top } = timelineDragHelper.getBookingStyle(itemPositionLeft)
    return {
      top,
      width: timelineHelper.getItemWidth(props.item.booking.days_count + days),
      ...(props.item.direction === 'right' ? { left } : { left: getLeftResizerLeftProp(left as string, days) }),
    }
  }

  return (
    <div
      className={classNames('calendar-content__item is-dragged', { 'is-compact': isCompactView })}
      style={getResizeBookingStyle()}
    />
  )
}

export default TimelineItemResizerDragger
