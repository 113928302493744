import * as React from 'react'
import { GastroCard } from '@models/promotions'
import { DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap'
import { GastroCardTableOptionsRemove } from '@modules/promotions/gastro-cards/gastro-card-table-options-remove'

interface Props {
  selectedRows: GastroCard[]
  clearSelectedRows: () => void
  fetchGastroCards: () => void
}

export const GastroCardTableOptions = ({ selectedRows, clearSelectedRows, fetchGastroCards }: Props): JSX.Element => {
  const isDisabled = !selectedRows.length

  const handleRemoved = () => {
    clearSelectedRows()
    fetchGastroCards()
  }

  return (
    <UncontrolledButtonDropdown disabled={isDisabled}>
      <DropdownToggle color="light" caret={true} disabled={isDisabled}>
        <strong>zaznaczone ({selectedRows.length})</strong>
      </DropdownToggle>
      <DropdownMenu>
        <GastroCardTableOptionsRemove items={selectedRows} onRemoved={handleRemoved}>
          <i className="uil uil-trash-alt mr-1 text-danger text-bold" />
          Skasuj
        </GastroCardTableOptionsRemove>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  )
}
