import * as React from 'react'
import { Col } from 'reactstrap'

const boxes: ReceptionBookingCheckOutStepThanksBoxProps[] = [
  {
    title: 'Wydaj Gościom paczki',
    icon: 'uil-gift',
    options: [
      <>Mamy dla Państwa mały upominek. Mamy nadzieję, że umili on Państwu drogę do domu.</>,
      <>Proszę przyjąć tę małą paczkę z przekąskami dla całej rodziny w ramach podziękowań od całego zespołu HP&R.</>,
      <>Przygotowaliśmy dla Państwa prezent pożegnalny na drogę od wszystkich pracowników Ośrodka.</>,
    ],
  },
  {
    title: 'Zaproponuj voucher na kolejne wakacje',
    icon: 'uil-pricetag-alt',
    options: [
      <>
        Mamy dla Państwa vouchery prezentowe uprawniające do zniżek na kolejne pobyty w HP&R. Czekają na Państwa rabaty
        nawet do -65%, aż na 3 lata do przodu.
      </>,
      <>Zachęcamy do zapoznania się z ofertami promocyjnymi przygotowanymi specjalnie dla naszych stałych gości.</>,
      <>
        Największe rabaty czekają na Subskrybentów. Zachęcamy do zapoznania się z warunkami Subskrypcji na naszej
        stronie internetowej.
      </>,
    ],
  },
  {
    title: 'Opowiedz o planach na przyszłe lata',
    icon: 'uil-redo',
    options: [
      <>W przyszłym roku nasze ośrodki zostaną rozbudowane o kolejne domki i apartamenty.</>,
      <>
        Zachęcamy do śledzenia rozwoju naszego najnowszego ośrodka - Uzdrowisko Cieplice Zdrój, który będzie jednym z
        największych obiektów w Europie.
      </>,
      <>
        Przypominamy, że nasze resorty w: Pobierowie, Rowach i Uzdrowisku Cieplice Zdrój są czynne również zimą. Czekamy
        na Państwa przez cały rok!
      </>,
    ],
  },
  {
    title: 'Pożegnanie',
    icon: 'uil-bag',
    options: [
      <>
        Serdecznie dziękujemy Państwu za przybycie. Życzymy bezpiecznej podróży do domu i zapraszamy ponownie. <br /> Do
        zobaczenia!
      </>,
    ],
  },
]

interface ReceptionBookingCheckOutStepThanksBoxProps {
  title: string
  icon: string
  options: React.ReactNode[]
}

export const ReceptionBookingCheckOutStepThanksBoxes: React.FC<{}> = () => (
  <div className="pt-2">
    {boxes.map(box => (
      <Col key={box.title} className="d-flex reception__booking-check-out__thanks-step__info__block p-0 mb-1 mt-1">
        <div className="reception__booking-check-out__thanks-step__info__icon">
          <i className={box.icon} />
        </div>
        <div className="reception__booking-check-out__thanks-step__info__text">
          <strong className="d-block mb-2 font-14">{box.title}</strong>
          <ul className="list-unstyled">
            {box.options.map((option, index) => (
              <li key={index} className="d-flex reception__booking-check-out__thanks-step__info__text__item">
                <i className="uil-check mr-1 text-success font-16" />
                <div>{option}</div>
              </li>
            ))}
          </ul>
        </div>
      </Col>
    ))}
  </div>
)
