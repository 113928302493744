import * as React from 'react'
import { GastroCard, GastroCardDetails } from '@models/promotions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { DropdownItem } from 'reactstrap'

interface Props {
  onRemoved: () => void
  items: GastroCard[]
  children: React.ReactNode
}

export const GastroCardTableOptionsRemove = ({ onRemoved, items, children }: Props): JSX.Element => {
  const { addSuccessNotification } = useNotificationHook()

  const { isLoading, action: handleRemove } = useApiRequest(async (reason: string) => {
    for (const gastroCard of items) {
      await commonObjectDelete<GastroCardDetails>(gastroCard.urls.details, reason)
    }
    addSuccessNotification('Pakiety zostały skasowane')
    onRemoved()
  })

  return (
    <CommonObjectConfirmAction
      handleAccept={handleRemove}
      title="Potwierdź operacje"
      isLoading={isLoading}
      as={DropdownItem}
      message="Czy na pewno chcesz skasować wybraną pozycję?"
      askForReason={true}
    >
      {children}
    </CommonObjectConfirmAction>
  )
}
