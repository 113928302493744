import * as React from 'react'
import { Label, Row } from 'reactstrap'
import { FormProvider, useForm } from 'react-hook-form'
import DateRangeInput from '@components/date/date-range-input'
import { promocodePrefixesSelector } from '@store/slices/promocodes-slice'
import { BaseTableFilters } from '@components/table/use-handle-table-filters-change'
import { useAppDispatch, useAppSelector } from '@store/index'
import { CommonTableFilters } from '@components/table/common-table-filters'
import { PromocodeCreate } from '@modules/promocode/promocode-list/promocode-create'
import { Link } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { FormSelect } from '@hyper/forms/form-select'
import { createSelectOption } from '@helpers/utils'
import { ColAuto } from '@hyper/col-auto'
import { useApiRequest } from '@components/hooks/use-api-request'
import { getPromocodePrefixes } from '@store/actions/promocode-actions'

export interface PromocodeListFilterParams extends BaseTableFilters {
  used_kind: string
  created_at_before: null | Date | undefined
  created_at_after: null | Date | undefined
  prefix: string | number | undefined
}

interface Props {
  defaultFilters: PromocodeListFilterParams
  filters: PromocodeListFilterParams
  setFilters: (filters: PromocodeListFilterParams) => void
}

export const PromocodeGroupsTableFilters: React.FC<Props> = ({ filters, setFilters, defaultFilters }) => {
  const dispatch = useAppDispatch()
  const prefixes = useAppSelector(promocodePrefixesSelector)
  const prefixesOptions = prefixes.map(prefix => createSelectOption(prefix.name, prefix.id))

  const { action: fetchPromocodePrefixes } = useApiRequest(() => {
    dispatch(getPromocodePrefixes({ ...filters, page_size: 9999 }))
  })

  React.useEffect(() => {
    fetchPromocodePrefixes()
  }, [])

  const methods = useForm<PromocodeListFilterParams>({ defaultValues: filters })

  return (
    <FormProvider {...methods}>
      <Row>
        <Link to={NavigationPath.PromoCodePrefixes} className="btn btn-light mr-2 btn-tall ml-auto">
          Lista prefiksów
        </Link>
        <PromocodeCreate />
      </Row>
      <CommonTableFilters defaultFilters={defaultFilters} filters={filters} setFilters={setFilters}>
        <FormSelect formPlainProps={{ colSize: 2 }} options={prefixesOptions} name="prefix" label="Prefiks" />
        <FormSelect formPlainProps={{ colSize: 2 }} options={UsedKindOptions} name="used_kind" label="Rodzaj" />
        <ColAuto>
          <Label>Data utworzenia</Label>
          <DateRangeInput
            startDateName="created_at_after"
            endDateName="created_at_before"
            wrapperClassName="is-range"
          />
        </ColAuto>
      </CommonTableFilters>
    </FormProvider>
  )
}

const UsedKindOptions = [createSelectOption('Jednorazowe', 'disposable'), createSelectOption('Wielorazowe', 'reusable')]
