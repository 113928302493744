import * as React from 'react'

export const useClickOutside = <T extends React.MutableRefObject<HTMLElement | null | undefined>>(
  ref: T,
  action: () => void,
) => {
  const handleMouseUp = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      action()
    }
  }

  React.useEffect(() => {
    document.addEventListener('mouseup', handleMouseUp)
    return () => document.removeEventListener('mouseup', handleMouseUp)
  })
}
