import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { FormInput } from '@hyper/forms'
import { Row } from 'reactstrap'
import { useSubscriptionAppData } from '@modules/subscription/hooks/use-subscription-app-data'
import { SubscriptionProductCreateFormInputs } from '@modules/subscription/products/create/modal'
import { UserPermission } from '@models/dashboard'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useSubscriptionKinds } from '@modules/subscription/hooks/use-subscription-kinds'
import { EntranceTicketPriceInformationTooltip } from '@modules/subscription/options/subscription-entrance-ticket/entrance-ticket-price-information-tooltip'
import { FormSelect } from '@hyper/forms/form-select'
import { packageWholesaleProductsStatusOptions } from '@modules/package-wholesale/products/list/filter'
import { SubscriptionTypeStrategy } from '@store/slices/subscription-slice'
import { SubscriptionProductCreateFormCodeFeatures } from '@modules/subscription/products/create/form-code-features'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useAppData } from '@components/hooks/use-app-data'

interface Props {
  subscriptionTypeStrategy: SubscriptionTypeStrategy
  defaultsDaysOptions: CustomReactSelectOption[]
}

export const SubscriptionProductCreateForm: React.FC<Props> = ({ subscriptionTypeStrategy }) => {
  const { control } = useFormContext<SubscriptionProductCreateFormInputs>()

  const { client_kinds } = useSubscriptionAppData()
  const user = useAuthenticatedUser()
  const { subscriptionKindOptions, getSubscriptionKindVersionOptions } = useSubscriptionKinds()
  const { deposit_payment_deadline } = useAppData()
  const { subscription_code_name_kinds } = useSubscriptionAppData()

  const kind = useWatch({ control, name: 'kind' })
  const subscriptionKindVersionOptions = getSubscriptionKindVersionOptions(kind)

  return (
    <>
      <Row>
        <FormInput label="Nazwa produktu" name="name" colSize={9} />
        <FormSelect
          name="status"
          label="Status"
          options={packageWholesaleProductsStatusOptions}
          formPlainProps={{ colSize: 3 }}
        />
        <FormSelect
          options={subscriptionKindOptions}
          name="kind"
          label="Rodzaj pakietu"
          formPlainProps={{ colSize: 3 }}
        />
        {!!subscriptionKindVersionOptions.length && (
          <FormSelect
            options={subscriptionKindVersionOptions}
            name="kind_version"
            label="Wersja rodzaju"
            formPlainProps={{ colSize: 3 }}
          />
        )}
        <FormInput label="Kody morze" name="local_sea" colSize={3} registerParams={{ valueAsNumber: true }} />
        <FormInput label="Kody góry" name="local_mountains" colSize={3} registerParams={{ valueAsNumber: true }} />
        {subscriptionTypeStrategy.has_universal_localization_code && (
          <FormInput
            label="Kody uniwersalne"
            name="local_universal"
            colSize={3}
            registerParams={{ valueAsNumber: true }}
          />
        )}
        <FormInput label="Zakwaterowanie" name="accommodation_type" colSize={3} type="select">
          <option value="">wybierz</option>
          <option value={1}>domek</option>
          <option value={2}>apartament</option>
        </FormInput>
        <FormInput label="Rodzaj klienta" name="client_kind" colSize={5} type="select">
          <option value={undefined}>wybierz</option>
          {client_kinds.map(value => (
            <option value={value[0]} key={value[0]}>
              {value[1]}
            </option>
          ))}
        </FormInput>
        <FormInput label="Prefiks kodów" name="prefix" colSize={12} />
        <FormInput label="Cena brutto" name="price_brutto" colSize={4} />
        {subscriptionTypeStrategy.allow_change_defaults_days && (
          <FormInput name="defaults_days" label="Długość pobytu (doby)" colSize={4} />
        )}
        <FormInput
          disabled={!user.hasPerm(UserPermission.SubscriptionCanSetExpireAfter)}
          label="Data ważności w latach"
          name="default_expire_years"
          colSize={4}
        />
        <FormInput
          label="Cena brutto (zmiany lokalizacji)"
          name="code_localization_convert_price_brutto"
          colSize={4}
          type="number"
        />
        <FormInput
          label="Cena brutto (zmiany danych)"
          name="change_client_data_price_brutto"
          colSize={4}
          type="number"
        />
        <FormInput
          label={
            <span>
              Cena brutto (biletu do Zoo)
              <EntranceTicketPriceInformationTooltip />
            </span>
          }
          name="zoo_borysew_tickets_single_price_brutto"
          colSize={4}
          type="number"
        />
        <FormInput
          label={
            <span>
              Cena brutto (biletu do Suntago)
              <EntranceTicketPriceInformationTooltip />
            </span>
          }
          name="suntago_tickets_single_price_brutto"
          colSize={4}
          type="number"
        />
        <FormInput label="Rodzaj kodów" name="subscription_code_name_kind" colSize={4} type="select">
          {subscription_code_name_kinds.map(([value, label]) => (
            <option value={value} key={value}>
              {label}
            </option>
          ))}
        </FormInput>
        <FormInput label="Kwota kaucji" name="deposit_amount" colSize={4} type="select">
          <option value="0">0 zł</option>
          <option value="500">500 zł</option>
          <option value="750">750 zł</option>
          <option value="899">899 zł</option>
          <option value="999">999 zł</option>
          <option value="1000">1000zł</option>
        </FormInput>
        <FormInput label="Termin wpłaty kaucji" name="deposit_payment_deadline" colSize={4} type="select">
          {deposit_payment_deadline.map(([value, label]) => (
            <option value={value} key={value}>
              {label}
            </option>
          ))}
          <option value="">Domyślne działanie</option>
        </FormInput>
      </Row>
      {subscriptionTypeStrategy.can_add_subscription_code_feature && (
        <SubscriptionProductCreateFormCodeFeatures subscriptionTypeStrategy={subscriptionTypeStrategy} />
      )}
    </>
  )
}
