import * as React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { RootState, useAppSelector } from '@store/index'
import { useFormContext } from 'react-hook-form'
import { ShopPOSWithParamsFormInputs } from '@modules/shop/pos/shop-pos-with-params'
import { EnhancedShopProduct } from '@modules/shop/pos/models'
import { shopProductsSelectorSelector } from '@store/slices/shop-slice'

interface Props {
  addProduct: (product: EnhancedShopProduct) => void
  removeProducts: () => void
  removeTickets: () => void
  addTicket: ({ value, id }: { value: number; id: string }) => void
}

export const ShopReceiptDetails = ({ addProduct, removeProducts, removeTickets, addTicket }: Props): null => {
  const receipt = useAppSelector((state: RootState) => state.shopState.receipt)
  const { setValue } = useFormContext<ShopPOSWithParamsFormInputs>()
  const products = useAppSelector(shopProductsSelectorSelector)

  React.useEffect(() => {
    if (receipt && products) {
      removeProducts()
      removeTickets()

      receipt.products.map(row => {
        const product = products.find(p => p.id === row.product)
        if (product) {
          addProduct({ ...product, amount: row.amount, listId: uuidv4(), params: row.params ?? null })
        }
      })
      if (receipt.tickets) {
        addTicket({ value: receipt.tickets, id: uuidv4() })
      }

      const fields = ['kind', 'invoice_company', 'invoice_street', 'invoice_postcode', 'invoice_city', 'invoice_nip']

      fields.forEach(field => {
        setValue(field as any, receipt[field])
      })

      setValue('paymentEnabled', true)
    }
  }, [receipt, products])

  return null
}
