import * as React from 'react'
import { Col } from 'reactstrap'
import { PaymentDayReport } from '@models/payments'

interface Props {
  rows: PaymentDayReport[]
  toggleIsOpen: (param: any) => void
}

export const PaymentRegisterCashboxWithMonthBaseData = ({ rows, toggleIsOpen }: Props): JSX.Element => {
  const openDays = rows.filter(row => row.status === 'open').length

  return (
    <>
      <Col md={10} className="payment-cashbox__day-rows cursor-pointer" onClick={toggleIsOpen} />
      <Col md={1} className="payment-cashbox__day-rows py-1 cursor-pointer" onClick={toggleIsOpen}>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <span className="d-block font-10">Otwartch dni</span>
            <strong>{openDays}</strong>
          </div>
          <i className="uil-angle-right-b cursor-pointer font-16" />
        </div>
      </Col>
    </>
  )
}
