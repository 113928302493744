import * as React from 'react'
import { useForm } from 'react-hook-form'
import { CardBody, Col, Row } from 'reactstrap'
import * as R from 'ramda'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useFormRequest } from '@components/hooks/use-api-request'
import { commonObjectPatch } from '@store/actions/generic-actions'
import { FormInput } from '@hyper/forms'
import { SaveButton } from '@hyper/button'
import { PackageWholesaleDetails } from '@modules/package-wholesale/models'
import { updatePackageWholesaleDetails } from '@store/slices/package-wholesale-slice'
import { Form } from '@hyper/forms/form'

interface FormInputs {
  name: string
  email: string
  phone: string
  invoice_company: string
  invoice_nip: string
  invoice_city: string
  invoice_street: string
  invoice_postcode: string
  display_name: string
}

interface Props {
  packageWholesaleDetails: PackageWholesaleDetails
  toggleEdit: () => void
}

export const PackageWholesaleDetailsClientInformationForm: React.FC<Props> = ({
  packageWholesaleDetails,
  toggleEdit,
}) => {
  const methods = useForm<FormInputs>({
    defaultValues: R.pick<FormInputs, keyof FormInputs>(
      [
        'name',
        'email',
        'phone',
        'invoice_company',
        'invoice_nip',
        'invoice_city',
        'invoice_street',
        'invoice_postcode',
        'display_name',
      ],
      packageWholesaleDetails,
    ),
  })

  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    dispatch(
      updatePackageWholesaleDetails(
        await commonObjectPatch<PackageWholesaleDetails>(packageWholesaleDetails.urls.details, payload),
      ),
    )
    addSuccessNotification('Zmiany zostały zapisane')
    toggleEdit()
  }, methods.setError)

  const renderFormInput = React.useCallback(
    (name: string, label: string) => (
      <FormInput
        labelProps={{ className: 'col-md-4' }}
        formGroupClassName="align-items-center row"
        inputClassName="col-md-8"
        label={label}
        name={name}
      />
    ),
    [],
  )

  return (
    <CardBody>
      <Form methods={methods} className="form" onSubmit={onSubmit}>
        <h4 className="mt-0 text-secondary mb-3">Dane klienta</h4>
        {renderFormInput('name', 'Osoba kontaktowa')}
        {renderFormInput('email', 'Adres email')}
        {renderFormInput('phone', 'Nr telefonu')}
        <h4 className="text-secondary mb-3">Dane faktury</h4>
        {renderFormInput('invoice_company', 'Nazwa firmy')}
        {renderFormInput('invoice_nip', 'NIP')}
        {renderFormInput('invoice_street', 'Ulica')}
        {renderFormInput('invoice_city', 'Miasto')}
        {renderFormInput('invoice_postcode', 'Kod pocztowy')}
        <h4 className="text-secondary mb-3">Strona sprzedaży</h4>
        {renderFormInput('display_name', 'Nazwa wyświetlana')}

        <Row>
          <Col md={12} className="text-right px-0">
            <button type="button" className="btn btn-light mr-1" onClick={toggleEdit}>
              Anuluj
            </button>
            <SaveButton isSaving={isLoading} label="Zapisz dane" className="btn btn-green" />
          </Col>
        </Row>
      </Form>
    </CardBody>
  )
}
