import * as React from 'react'
import { PageTitle } from '@hyper/page-title'
import { Card, CardBody } from 'reactstrap'
import { useParams } from 'react-router-dom'
import { ResortTabs } from '@components/resort-tabs'
import { NavigationPath } from '@models/routes'
import { EnhancedShopProduct, ShopTicket } from '@modules/shop/pos/models'
import { ShopProduct } from '@models/shop'
import { ShopContainer } from '@modules/shop/pos/shop-container'
import { PaymentMethods } from '@modules/shop/models'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { ShopWebSocketHandler } from '@modules/shop/pos/shop-web-socket-handler'
import { useAppDispatch } from '@store/index'
import { getResortDetails } from '@store/actions/reception-actions'
import { getShopReceipt } from '@store/actions/shop-actions'
import { clearShopReceipt } from '@store/slices/shop-slice'
import { setReceptionBookingDetails } from '@store/slices/reception-slice'

const pageTitle = 'Sprzedaj produkt'

interface Params {
  resort_id: string
  token?: string
}

export type ShopPaymentKind = 'invoice' | 'receipt'

export interface ShopPOSWithParamsPaymentKindFormInputs {
  kind: ShopPaymentKind
  invoice_nip: string
  invoice_company: string
  invoice_street: string
  invoice_postcode: string
  invoice_city: string
}

export interface ShopPOSWithParamsFormInputs extends ShopPOSWithParamsPaymentKindFormInputs {
  ticket: number
  selectedProduct: ShopProduct | null
  selectedProductOption: CustomReactSelectOption | null

  addedProducts: EnhancedShopProduct[]
  addedTickets: ShopTicket[]
  paymentEnabled: boolean
  amount: string
  onlyAvailable: boolean
  paymentCompleted: boolean
  apartment: CustomReactSelectOption | null
  guest: CustomReactSelectOption | null
}

export const ShopPOSWithParamsView: React.FC = () => {
  const { resort_id, token } = useParams() as unknown as Params
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    dispatch(getResortDetails(resort_id))
  }, [resort_id])

  React.useEffect(() => {
    dispatch(setReceptionBookingDetails(undefined))
  }, [])

  React.useEffect(() => {
    if (token) {
      dispatch(getShopReceipt(token))
    } else {
      dispatch(clearShopReceipt())
    }
  }, [token])

  return (
    <>
      <ShopWebSocketHandler resortId={parseInt(resort_id, 10)} />
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitle} />
      <Card>
        <CardBody>
          <ResortTabs resortId={parseInt(resort_id, 10)} url={NavigationPath.ShopPOSWithParams} />
          <ShopContainer
            resort_id={resort_id}
            availablePaymentMethods={[PaymentMethods.Card, PaymentMethods.Cash, PaymentMethods.Wallet]}
          />
        </CardBody>
      </Card>
    </>
  )
}
