import * as React from 'react'
import { Button, ModalFooter, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import ModalBody from 'reactstrap/lib/ModalBody'
import { FormInput } from '@hyper/forms'
import { FormProvider, useForm } from 'react-hook-form'
import { useFormRequest } from '@components/hooks/use-api-request'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { CmsResortDetails } from '@modules/cms/models'
import { BaseModalProps } from '@components/modals/types'
import { commonObjectPatch } from '@store/actions/generic-actions'
import { setCmsResort } from '@store/slices/cms-reservation-app-slice'
import { useAppDispatch } from '@store/index'

interface Props extends BaseModalProps {
  resort: CmsResortDetails
}

export interface CmsReservationAppResortFormInputs {
  name: string
  size: string
  description: string
  short_name_language_miejscownik: string
  short_name_language_dopelniacz: string
  map_description: string
  reservation_description: string
  features: string[]
  longitude: string
  latitude: string
  address_street: string
  address_postcode: string
  address_city: string
}

export const CmsReservationAppResortForm: React.FC<Props> = ({ toggleIsVisible, resort }) => {
  const methods = useForm<CmsReservationAppResortFormInputs>({ defaultValues: resort })
  const { addSuccessNotification } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: CmsReservationAppResortFormInputs) => {
    dispatch(
      setCmsResort(
        await commonObjectPatch<CmsResortDetails>(resort.urls.cms_details, {
          ...resort,
          ...payload,
          resort_map: undefined,
        }),
      ),
    )
    addSuccessNotification('Zmiany zostały zapisane')
  }, methods.setError)

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalBody>
          <Row>
            <FormInput colSize={12} label="Nazwa" name="name" />

            <FormInput label="Kod pocztowy" colSize={3} name="address_postcode" />
            <FormInput label="Miasto" colSize={4} name="address_city" />
            <FormInput label="Ulica" colSize={5} name="address_street" />

            <FormInput label="Link do google maps" name="google_maps_url" />
            <FormInput label="Szerokość geograficzna" colSize={4} name="latitude" />
            <FormInput label="Długość geograficzna" colSize={4} name="longitude" />
            <FormInput label="Odległość od morza" colSize={4} name="distance_from_sea" />
            <FormInput label="Opis w widoku rezerwacji" type="textarea" name="reservation_description" />

            <FormInput label="Opis nad mapką" type="textarea" name="map_description" />
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="light" type="button" onClick={toggleIsVisible}>
            Zamknij
          </Button>
          <SaveButton className="btn btn-green" isSaving={isLoading} />
        </ModalFooter>
      </form>
    </FormProvider>
  )
}
