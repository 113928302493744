import { CashRegister, SaleAppData } from '@models/sale'
import { SaleActionTypes, SaleActionTypesKeys } from '@store/actions-types/sale-actions-types'

export interface CashRegistersExtraData {
  total_amount: string
  users: {
    id: number
    value: string
  }[]
  company: {
    id: string
    value: string
  }[]
}

export interface SaleState {
  appData: SaleAppData
  cashRegisters: CashRegister[]
  cashRegistersExtraData: CashRegistersExtraData
}

const initialState: SaleState = {
  appData: {
    sale_points: [],
    euro_exchange_rate: '4.00',
    payment_register_kinds: [],
    resorts: [],
    status: 'unknown',
    workers: [],
  },
  cashRegisters: [],
  cashRegistersExtraData: {
    total_amount: '',
    users: [],
    company: [],
  },
}

const saleState = (state: SaleState = initialState, action: SaleActionTypes): SaleState => {
  switch (action.type) {
    case SaleActionTypesKeys.SET_APP_DATA:
      return { ...state, appData: action.payload }
    case SaleActionTypesKeys.SET_CASH_REGISTERS:
      return { ...state, cashRegisters: action.payload }
    case SaleActionTypesKeys.SET_CASH_REGISTERS_DATA:
      return { ...state, cashRegistersExtraData: action.payload }
    default:
      return state
  }
}

export default saleState
