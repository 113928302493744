import * as React from 'react'
import Table, { TableHeaderValue } from '@components/table/table'
import { PackageListFilterParams } from '@modules/package/list/filter'
import { PackageListTableRow } from '@modules/package/list/table-row'
import { Package } from '@models/package'
import { PackageListTableSummaryRow } from '@modules/package/list/table-row-summary'

const headerValues: TableHeaderValue[] = [
  { title: 'Numer', sortField: 'number' },
  { title: 'Firma' },
  { title: 'NIP' },
  { title: 'Status', sortField: 'status' },
  { title: 'Numer faktury' },
  { title: 'Data płatności', sortField: 'confirmed_at' },
  { title: 'Sprzedawca', sortField: 'seller' },
  { title: 'Cena netto' },
  { title: 'Cena brutto' },
  { title: 'Domki (morze)' },
  { title: 'Apart. (morze)' },
  { title: 'Domki (góry)' },
  { title: 'Apart. (góry)' },
  { title: '' },
]

interface Props {
  packages: Package[]
  isLoading: boolean
  filters: PackageListFilterParams
  setFilters: (filters: PackageListFilterParams) => void
}

export const PackageListTable: React.FC<Props> = ({ isLoading, packages, filters, setFilters }) => (
  <Table
    className="table-hover w-100 mb-0"
    headerValues={headerValues}
    isLoading={isLoading}
    filters={filters}
    scrollable={true}
    setFilters={setFilters}
  >
    {packages.map(packageData => (
      <PackageListTableRow key={packageData.id} packageData={packageData} />
    ))}
    {!!packages.length && <PackageListTableSummaryRow />}
  </Table>
)
