import * as React from 'react'
import { Col, Row } from 'reactstrap'
import { FormInput } from '@hyper/forms'
import { useFormContext, useWatch } from 'react-hook-form'

export const BenefitProgramCreateFormParams: React.FC = () => {
  const { setValue, control } = useFormContext()
  const invoiceCompany = useWatch({ control: control, name: 'invoice_company' })

  React.useEffect(() => {
    if (invoiceCompany) {
      setValue('name', invoiceCompany)
    }
  }, [invoiceCompany])

  return (
    <div className="px-2">
      <h4 className="text-secondary fw-semi-bold mb-3">Parametry sprzedaży</h4>

      <Row className="align-items-center">
        <Col md={4} className="font-weight-bold">
          Plan Apartament
        </Col>
        <FormInput name="benefit_apartment_price_employer" label="Cena dla pracodwacy" colSize={4} type="currency" />
        <FormInput name="benefit_apartment_price_worker" label="Cena dla pracownika" colSize={4} type="currency" />
      </Row>

      <Row className="align-items-center">
        <Col md={4} className="font-weight-bold">
          Plan Domek
        </Col>
        <FormInput name="benefit_house_price_employer" label="Cena dla pracodawcy" colSize={4} type="currency" />
        <FormInput name="benefit_house_price_worker" label="Cena dla pracownika" colSize={4} type="currency" />
      </Row>
    </div>
  )
}
