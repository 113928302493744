import * as React from 'react'
import classnames from 'classnames'
import { Spinner } from 'reactstrap'
import { ReceptionBookingGuest } from '@models/booking'
import { ReceptionBookingGroup } from '@models/reception'

interface StepGuestGroupDroppableGhostProps {
  isHovered: boolean
  isGuestUpdating: boolean
  guest: ReceptionBookingGuest | undefined
  targetGroup: ReceptionBookingGroup
}

export const StepGuestGroupDroppableGhost: React.FC<StepGuestGroupDroppableGhostProps> = ({
  isGuestUpdating,
  isHovered,
  guest,
  targetGroup,
}) => (
  <div
    role="drop-guest-indicator"
    className={classnames('reservation-guests__group__ghost-guest', {
      'reservation-guests__group__ghost-guest--hovered': isHovered || isGuestUpdating,
    })}
  >
    {isGuestUpdating || !guest ? (
      <div className="d-flex justify-content-center align-items-center">
        <span className="d-block p-1">
          Trwa przenoszenie gościa do grupy <strong> {targetGroup.name} </strong>
        </span>
        <Spinner className="text-primary ml-2 font-15" size="sm" role="guest-move-status" />
      </div>
    ) : (
      <span className=" d-block p-1">
        Przenieś gościa <strong>{guest?.name}</strong> do grupy <strong>{targetGroup.name}</strong>
      </span>
    )}
  </div>
)
