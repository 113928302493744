import { createSelectOption } from '@helpers/utils'
import { RentalProductCategory, RentalProductWarehouse } from '@modules/rental/models'

export const rentalProductStatusOptions = [
  createSelectOption('Dostępny', 'available'),
  createSelectOption('Wypożyczony', 'rented'),
  createSelectOption('Wycofany', 'withdrawn'),
]

export const getRentalCategoriesOptions = (categories: RentalProductCategory[]) =>
  categories.map((category: RentalProductCategory) => createSelectOption(category.name, category.id))

export const getRentalWarehousesOptions = (warehouses: RentalProductWarehouse[]) =>
  warehouses.map((warehouse: RentalProductWarehouse) => createSelectOption(warehouse.name, warehouse.id))
