import { AsyncThunkParams } from '@store/index'
import { Promocode, PromocodeEmailTemplate, PromocodeGroup, PromocodePrefix } from '@models/promocode'
import { formatDate } from '@helpers/date-helper'
import { setDashboardStandardPaginator } from '@store/actions/dashboard-actions'
import { TableFilters } from '@components/table/table'
import { commonObjectDelete, commonObjectGet } from '@store/actions/generic-actions'
import { PaginationResponse } from '@models/dashboard'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { PromocodeListFilterParams } from '@modules/promocode/promocode-list/promocode-groups/promocode-groups-table-filters'
import { PromocodeCodesTableFormInputs } from '@modules/promocode/promocode-list/promocode-codes/promocode-codes-table'

export const getPromocodeEmailTemplates = createAsyncThunk<PromocodeEmailTemplate[], number, AsyncThunkParams>(
  'promocodes/getPromocodeEmailTemplates',
  async (promocode, { getState }) =>
    await commonObjectGet<PromocodeEmailTemplate[]>(getState().appState.appData.urls.rent.promocode_email_templates, {
      promocode,
    }),
)

export const getPromocodeCodes = createAsyncThunk<
  Promocode[],
  Partial<PromocodeCodesTableFormInputs>,
  AsyncThunkParams
>('promocodes/getPromocodeCodes', async (filters, { getState, dispatch }) => {
  const url = getState().appState.appData.urls.rent.promocode_list

  const data = await commonObjectGet<PaginationResponse<Promocode>>(url, filters)
  dispatch(setDashboardStandardPaginator(data))

  return data.results
})
export const getPromocodePrefixes = createAsyncThunk<PromocodePrefix[], Partial<TableFilters>, AsyncThunkParams>(
  'promocodes/getPromocodePrefixes',
  async (filters, { getState, dispatch }) => {
    const url = getState().appState.appData.urls.rent.promocode_prefixes

    const data = await commonObjectGet<PaginationResponse<PromocodePrefix>>(url, filters)
    dispatch(setDashboardStandardPaginator(data))

    return data.results
  },
)

export const deletePromocodeGroup = createAsyncThunk<number, PromocodeGroup, AsyncThunkParams>(
  'promocodes/deletePromocodeGroups',
  async promocodeGroup => {
    await commonObjectDelete(promocodeGroup.urls.details)

    return promocodeGroup.id
  },
)

export const getPromocodeGroups = createAsyncThunk<
  PromocodeGroup[],
  Partial<PromocodeListFilterParams> | undefined,
  AsyncThunkParams
>('promocodes/getPromocodeGroups', async (params, { getState, dispatch }) => {
  const data = await commonObjectGet<PaginationResponse<PromocodeGroup>>(
    getState().appState.appData.urls.rent.promocode_groups,
    {
      ...params,
      created_at_after: formatDate(params?.created_at_after),
      created_at_before: formatDate(params?.created_at_before),
    },
  )
  dispatch(setDashboardStandardPaginator(data))

  return data.results
})
export const deletePromocodePrefixes = createAsyncThunk<number[], number[], AsyncThunkParams>(
  'promocodes/deletePromocodePrefixes',
  async (ids, { getState }) => {
    await commonObjectDelete(getState().appState.appData.urls.rent.promocode_prefixes, null, { ids })
    return ids
  },
)
