import * as React from 'react'
import { PromocodeTouristVoucherDetails } from '@modules/promotions/promocode-tourist-voucher/models'
import { Card, CardBody } from 'reactstrap'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { formatPrice } from '@helpers/utils'
import { TableSellerRow } from '@components/table-seller-row'
import { useModal } from '@components/modals/use-modal'
import { useReportsAppData } from '@modules/reports/hooks/use-reports-app-data'
import { BadgeWithIcon } from '@components/badges/BadgeWithIcon'
import { getStatusClassIcon } from '@modules/promotions/gastro-cards/utils'
import { ProductCardTitle } from '@components/products/product-card-title'

interface Props {
  promocodeTouristVoucherDetails: PromocodeTouristVoucherDetails
}

export const PromocodeTouristVoucherDetailsInfo = ({ promocodeTouristVoucherDetails }: Props): JSX.Element => {
  const [badgeClass, badgeIcon] = getStatusClassIcon(promocodeTouristVoucherDetails.status)

  const { sources } = useReportsAppData()

  const sourceMarketing = React.useMemo(
    () => sources.find(row => row.id === promocodeTouristVoucherDetails.source_marketing_id)?.name || '',
    [sources, promocodeTouristVoucherDetails.source_marketing_id],
  )

  const [handleShowDialog] = useModal('PromocodeTouristVoucherInfoEditDialog', {
    promocodeTouristVoucherDetails,
  })

  return (
    <Card>
      <CardBody>
        <ProductCardTitle title="Szczegóły vouchera" isEditAllowed={true} onEdit={handleShowDialog} />
        <div className="table-responsive">
          <table className="table table-sm mb-2">
            <colgroup>
              <col width="40%" />
              <col width="60%" />
            </colgroup>
            <tbody>
              <tr>
                <td>Status</td>
                <td>
                  <BadgeWithIcon
                    icon={badgeIcon}
                    title={promocodeTouristVoucherDetails.status_display}
                    className={badgeClass}
                  />
                </td>
              </tr>
              <tr>
                <td>Data ważności</td>
                <td>
                  {promocodeTouristVoucherDetails.expire_after
                    ? toDefaultDateTimeFormat(promocodeTouristVoucherDetails.expire_after)
                    : ''}
                </td>
              </tr>
              <tr>
                <td>Kwota vouchera</td>
                <td>{formatPrice(promocodeTouristVoucherDetails.tourist_voucher_amount)}</td>
              </tr>
              <tr>
                <td>Data utworzenia</td>
                <td>{toDefaultDateTimeFormat(promocodeTouristVoucherDetails.created_date)}</td>
              </tr>
              <tr>
                <td>Utworzony przez</td>
                <td>{promocodeTouristVoucherDetails.created_by}</td>
              </tr>
              <tr>
                <td>Sprzedawca</td>
                <td>
                  <TableSellerRow
                    sellerId={promocodeTouristVoucherDetails.seller_id}
                    defaultValue=""
                    className="d-block"
                  />
                </td>
              </tr>
              <tr>
                <td>Źródło marketingowe</td>
                <td>{sourceMarketing || 'brak'}</td>
              </tr>
              {!!promocodeTouristVoucherDetails.invoices.length && (
                <tr>
                  <td>Numer faktury</td>
                  <td>
                    {promocodeTouristVoucherDetails.invoices.map(invoice => (
                      <strong key={invoice.id} className="mr-1">
                        <a href={`/admin/dashboard/quick-search/?query=${invoice.reservation_number}`}>
                          {invoice.reservation_number}
                        </a>
                      </strong>
                    ))}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  )
}
