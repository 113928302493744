import * as React from 'react'
import { Row } from 'reactstrap'
import { InvoiceForm } from '@components/invoice-form'
import { useFormContext, useWatch } from 'react-hook-form'
import { useGroupedSellers } from '@components/hooks/use-grouped-sellers'
import { FormSelect } from '@hyper/forms/form-select'
import { useBenefitProgramAdvisorsOptions } from '@modules/benefit-program/hooks/use-benefit-program-advisors-options'
import { BenefitProgramUsers } from '@modules/benefit-program/create/form-users'

export const BenefitProgramCreateFormBaseData: React.FC = () => {
  const { setValue, control } = useFormContext()
  const advisors = useBenefitProgramAdvisorsOptions()
  const invoiceCompany = useWatch({ control: control, name: 'invoice_company' })
  const sellers = useGroupedSellers([])

  React.useEffect(() => {
    if (invoiceCompany) {
      setValue('name', invoiceCompany)
    }
  }, [invoiceCompany])

  return (
    <>
      <h4 className="text-secondary fw-semi-bold mb-3 px-2">Dane podstawowe</h4>
      <div className="bg-grey border-top">
        <InvoiceForm
          hideInvoiceCheckbox={true}
          allowChangeInvoiceType={false}
          initHasInvoice={true}
          wrapperClassName="mx-0 border-0 px-0"
        />
        <BenefitProgramUsers />
      </div>
      <div className="px-2 border-top">
        <h4 className="text-secondary fw-semi-bold mt-3 mb-2">Dane sprzedażowe</h4>
        <Row>
          <FormSelect options={sellers} name="seller" label="Sprzedawca" formPlainProps={{ colSize: 6 }} />
          <FormSelect options={advisors} name="advisor" label="Opiekun" formPlainProps={{ colSize: 6 }} />
        </Row>
      </div>
    </>
  )
}
