import * as React from 'react'
import { ControlledFormCheckbox } from '@hyper/forms/controlled-form-checkbox'
import { useCartItemGroupSelection } from '@modules/reception/cart/use-cart-item-group-selection'
import { ReceptionBookingDetailsCartItem } from '@models/reception'
import classnames from 'classnames'

interface Props {
  cartItems: ReceptionBookingDetailsCartItem[]
}

export const ReceptionBookingCartItemsHeader = ({ cartItems }: Props): JSX.Element => {
  const { allSelected, someSelected, changeGroup } = useCartItemGroupSelection(cartItems)

  const isEveryItemPaid = cartItems.every(item => item.is_paid)

  return (
    <li className="row border-bottom">
      <div
        className={classnames('col-7 reception-booking-cart__selectable py-1', {
          'is-selected': allSelected && !isEveryItemPaid,
        })}
      >
        {!isEveryItemPaid && (
          <ControlledFormCheckbox
            id="all"
            label="Zaznacz wszystkie"
            indeterminate={someSelected}
            onChange={changeGroup}
            checked={allSelected}
          />
        )}
      </div>
      <div className="col-3 font-weight-bold text-muted">Cena przed rabatem</div>
      <div className="col-2 font-weight-bold">Cena</div>
    </li>
  )
}
