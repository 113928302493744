import { useApiRequest } from '@components/hooks/use-api-request'
import * as React from 'react'
import { useAppDispatch } from '@store/index'
import { ContentNotesCard } from '@components/content-notes/content-notes-card'
import { useAppData } from '@components/hooks/use-app-data'
import { getCafeteriaDetails } from '@store/actions/cafeterias-action'
import { CafeteriaDetails } from '@models/cafeteria'

interface Props {
  cafeteria: CafeteriaDetails
}

export const CafeteriaDetailsNotes: React.FC<Props> = ({ cafeteria }) => {
  const { cafeteria: contentType } = useAppData().content_types

  const dispatch = useAppDispatch()

  const { action: handleRefresh } = useApiRequest(async () => await dispatch(getCafeteriaDetails(cafeteria.id)))

  return (
    <div data-testid="package-details-notes">
      <ContentNotesCard
        readOnly={false}
        objectId={cafeteria.id}
        contentType={contentType}
        onNotesUpdate={handleRefresh}
        notes={cafeteria.notes}
      />
    </div>
  )
}
