import * as React from 'react'
import { GastroWalletItem } from '@models/promotions'
import Table from '@components/table/table'
import { asDecimal, formatPriceShort, sumDecimalArray } from '@helpers/utils'
import { SummaryBlock } from '@modules/package/list/table-row-summary'

interface GastroCardWalletItemsRemoveProps {
  items: GastroWalletItem[]
}

export const GastroCardWalletItemsSummary: React.FC<GastroCardWalletItemsRemoveProps> = ({ items }) => {
  const total_price_brutto = React.useMemo(
    () => sumDecimalArray(items.map(row => asDecimal(row.amount))).toString(),
    [items],
  )

  return (
    <Table.Row>
      <Table.Cell colSpan={4} className="text-right align-middle package-list__summary-cell" />
      <Table.Cell className="package-list__summary-cell">
        <SummaryBlock value={formatPriceShort(total_price_brutto)} className="border-right" />
      </Table.Cell>
      <Table.Cell className="text-right align-middle package-list__summary-cell" />
    </Table.Row>
  )
}
