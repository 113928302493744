import * as React from 'react'
import { PaymentDayReport } from '@models/payments'
import { Col } from 'reactstrap'
import { PaymentRegisterCashboxWithMonthDaysRow } from '@modules/payment-registers/cashbox/month/days-row'

interface Props {
  rows: PaymentDayReport[]
}

export const PaymentRegisterCashboxWithMonthDays = ({ rows }: Props): JSX.Element => (
  <Col md={11} className="payment-cashbox__day-rows">
    {rows.map(row => (
      <PaymentRegisterCashboxWithMonthDaysRow key={row.id} row={row} />
    ))}
  </Col>
)
