import * as React from 'react'
import { EmailTemplateDetails } from '@modules/cms/models'
import { NotificationTemplatesEmailModalAttachmentsListRow } from '@modules/cms/notification-templates/email/modal/attachments-list-row'

interface Props {
  emailTemplateDetails: EmailTemplateDetails
}

export const NotificationTemplatesEmailModalAttachmentsList = ({ emailTemplateDetails }: Props): JSX.Element | null => {
  if (!emailTemplateDetails.attachments.length) {
    return null
  }

  return (
    <div className="mt-2 mb-2">
      <strong className="d-block mb-2">Lista załączników</strong>
      <ul>
        {emailTemplateDetails.attachments.map(attachment => (
          <NotificationTemplatesEmailModalAttachmentsListRow key={attachment.id} attachment={attachment} />
        ))}
      </ul>
    </div>
  )
}
