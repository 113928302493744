import * as React from 'react'
import { RecommendationGift } from '@models/crm'
import { UserPermission } from '@models/dashboard'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useModal } from '@components/modals/use-modal'

interface RecommendationGiftEditIconProps {
  recommendationGift: RecommendationGift
}

export const RecommendationGiftEditIcon: React.FC<RecommendationGiftEditIconProps> = ({ recommendationGift }) => {
  const [handleToggle] = useModal('RecommendationGiftDialog', {
    recommendationGift,
  })

  const user = useAuthenticatedUser()

  if (!user.hasPerm(UserPermission.CrmRecommendationGiftCanChange)) {
    return null
  }

  return (
    <span className="font-16 ml-1" onClick={handleToggle} role="edit">
      <i className="uil uil-pen cursor-pointer text-muted" title="Edytuj" />
    </span>
  )
}
