import * as React from 'react'
import { AnnexedFieldItem } from '@modules/hr/agreement/models'
import { IconWithTooltip } from '@components/icon-with-tooltip'
import { Direction } from '@components/icon-with-text'
import { getAgreementAnnexNumber } from '@modules/hr/agreement/annexes/helpers'

interface Props {
  change: AnnexedFieldItem | null | undefined
  originalValue: string | undefined
  formatter?: (value) => React.ReactNode
  id: string
  tooltipPosition?: Direction
  showOriginalValue?: boolean
}

export const AgreementAnnexChange = ({
  change,
  originalValue,
  formatter,
  id,
  tooltipPosition,
  showOriginalValue,
}: Props): React.ReactNode => {
  if (!change && !originalValue) return '-'

  const valueInTooltipToShow = showOriginalValue ? originalValue : change?.value

  return (
    <div>
      {change ? (
        <IconWithTooltip
          direction={tooltipPosition ? tooltipPosition : 'left'}
          tooltipMessage={
            <div className="font-11 text-left p-1">
              <p className="text-nowrap mb-1">
                {showOriginalValue ? 'Zmiana w dokumencie:' : 'Aktualna wartość z dokumentu:'}
              </p>
              <span className="text-nowrap">Aneks {getAgreementAnnexNumber(change.number)}</span>
            </div>
          }
          tooltipId={`payment-tooltip-${id}`}
          tooltipPlacement="right"
          icon="uil-exclamation-circle ml-1 text-info font-16"
        >
          {formatter ? formatter(valueInTooltipToShow) : valueInTooltipToShow}
        </IconWithTooltip>
      ) : (
        <span>{formatter ? formatter(originalValue) : originalValue}</span>
      )}
    </div>
  )
}
