import * as React from 'react'

export enum CircleProgressSizes {
  SMALL = '30px',
  MEDIUM = '53px',
}

interface Props {
  progress: number
  className?: string
  size?: CircleProgressSizes
  children?: React.ReactElement
}

export const CircleProgress = ({
  progress,
  className,
  size = CircleProgressSizes.MEDIUM,
  children,
}: Props): JSX.Element => {
  const strokeDashoffset = 600 - (600 * progress) / 100
  return (
    <div
      className={className}
      style={{
        width: size,
        height: size,
        minHeight: size,
        minWidth: size,
        borderRadius: '50%',
        backgroundColor: '#535457',
      }}
    >
      <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
        <svg width="200px" height="200px" viewBox="0 0 160 160">
          <defs>
            <g id="lines" stroke="white" strokeWidth="3px">
              <line x1=" 71" x2=" 79" />
              <line x1="-71" x2="-79" />
              <line y1=" 71" y2=" 79" />
              <line y1="-71" y2="-79" />
            </g>
          </defs>
          <g transform="translate(80 80)">
            <use href="#lines" transform="rotate(0)" />
            <use href="#lines" transform="rotate(10)" />
            <use href="#lines" transform="rotate(20)" />
            <use href="#lines" transform="rotate(30)" />
            <use href="#lines" transform="rotate(40)" />
            <use href="#lines" transform="rotate(50)" />
            <use href="#lines" transform="rotate(60)" />
            <use href="#lines" transform="rotate(70)" />
            <use href="#lines" transform="rotate(80)" />
          </g>
        </svg>
        <circle
          cx="100"
          cy="100"
          r="93"
          fill="transparent"
          stroke="#b99153"
          strokeLinecap="round"
          strokeWidth="14"
          strokeDasharray="600"
          strokeDashoffset={strokeDashoffset}
          transform="rotate(-90 100 100)"
        />
      </svg>

      {children}
    </div>
  )
}
