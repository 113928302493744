import * as React from 'react'
import { CleaningOrder, CleaningOrderDetails } from '@modules/housekeeping/models'
import { commonObjectGet } from '@store/actions/generic-actions'
import { Tooltip } from 'reactstrap'
import { OrderNotesTooltipDetails } from './row-notes-tooltip-details'
import { useHandleAxiosServerErrors } from '@helpers/handle-axios-errors'
import classNames from 'classnames'

interface OrderNotesProps {
  order: CleaningOrder
  onClick?: () => void
}

export const OrderNotes: React.FC<OrderNotesProps> = ({ order, onClick }) => {
  const [orderDetails, setOrderDetails] = React.useState<CleaningOrderDetails | undefined>(undefined)
  const [tooltipOpen, setTooltipOpen] = React.useState(false)
  const handleAxiosServerErrors = useHandleAxiosServerErrors()

  const iconStyles = { opacity: !order.notes_count && !onClick ? 0.3 : 1 }
  const tooltipId = `notes-tooltip-${order.id}`
  const fetchBookingDetails = async () => {
    try {
      setOrderDetails(await commonObjectGet<CleaningOrderDetails>(order.urls.details))
    } catch (error) {
      handleAxiosServerErrors(error)
    }
  }

  const toggle = () => {
    const isTooltipOpen = !tooltipOpen

    if (isTooltipOpen && (!orderDetails || order.notes_count !== orderDetails.notes_count)) {
      fetchBookingDetails()
    }

    setTooltipOpen(isTooltipOpen)
  }

  return (
    <>
      {!!order.notes_count && (
        <Tooltip
          placement="top"
          isOpen={tooltipOpen && !!orderDetails}
          target={tooltipId}
          toggle={toggle}
          className="housekeeping__notes-tooltip"
          autohide={false}
        >
          {orderDetails && <OrderNotesTooltipDetails cleaningOrder={orderDetails} />}
        </Tooltip>
      )}
      <div
        className={classNames('d-inline-flex align-items-center', order.notes_count ? 'text-default' : 'text-muted')}
      >
        <i
          style={iconStyles}
          id={tooltipId}
          className={classNames('uil-file-check-alt font-16 mr-1', {
            'cursor-pointer': onClick,
          })}
          {...(onClick && { onClick })}
        />
        <span className="mr-4">({order.notes_count})</span>
      </div>
    </>
  )
}
