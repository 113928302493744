import * as React from 'react'
import { StatsSaleItemType } from '@modules/reports/stats-sale-item/models'
import { NavigationTabs, NavigationTabsButton } from '@components/navigation-tabs'
import { NavigationPath } from '@models/routes'

interface StatsSaleItemTypesProps {
  type: StatsSaleItemType
}

export const StatsSaleItemTypes: React.FC<StatsSaleItemTypesProps> = ({ type }) => {
  const url = NavigationPath.ReportsStatsSaleItem

  const buttons: NavigationTabsButton[] = [
    {
      title: 'Dashboard',
      isActive: type == StatsSaleItemType.Dashboard,
      link: url.replace(':type', String(StatsSaleItemType.Dashboard)),
    },
    {
      title: 'Rezerwacje',
      isActive: type == StatsSaleItemType.Booking,
      link: url.replace(':type', String(StatsSaleItemType.Booking)),
    },
    {
      title: 'Lista',
      isActive: type == StatsSaleItemType.List,
      link: url.replace(':type', String(StatsSaleItemType.List)),
    },
  ]

  return <NavigationTabs buttons={buttons} />
}
