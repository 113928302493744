import { createAsyncThunk } from '@reduxjs/toolkit'
import { AsyncThunkParams, RootState } from '@store/index'
import { setDashboardStandardPaginator } from '@store/actions/dashboard-actions'
import { ServiceBand } from '@modules/bands/service-bands/models'
import { commonObjectGet, commonObjectPost, commonObjectPatch, wrapWithError } from '@store/actions/generic-actions'
import { PaginationResponse } from '@models/dashboard'
import { ServiceBandCreateFormInputs } from '@modules/bands/service-bands/create-dialog'
import { ServiceBandUpdateFormInputs } from '@modules/bands/service-bands/info-edit-dialog'
import { ServiceBandFiltersParams } from '@modules/bands/service-bands/filters'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { extractSelectOptionsValues } from '@helpers/utils'

export const getServiceBands = createAsyncThunk<ServiceBand[], Partial<ServiceBandFiltersParams>, AsyncThunkParams>(
  'serviceBands/getServiceBands',
  async (params, { getState, dispatch }) => {
    const url = getState().appState.appData.urls.bands.service_bands
    const data = await commonObjectGet<PaginationResponse<ServiceBand>>(url, extractSelectOptionsValues(params))
    dispatch(setDashboardStandardPaginator(data))

    return data.results
  },
)

export const updateServiceBand = createAsyncThunk<ServiceBand, [ServiceBand, Partial<ServiceBandUpdateFormInputs>]>(
  'serviceBands/updateServiceBand',
  async ([serviceBand, payload]) =>
    await wrapWithError(commonObjectPatch<ServiceBand>(serviceBand.urls.details, extractSelectOptionsValues(payload))),
)

export const createServiceBand = createAsyncThunk<ServiceBand, ServiceBandCreateFormInputs, { state: RootState }>(
  'serviceBands/createServiceBand',
  async (payload, { getState }) =>
    await wrapWithError(
      commonObjectPost<ServiceBand>(
        getState().appState.appData.urls.bands.service_bands,
        extractSelectOptionsValues(payload),
      ),
    ),
)

export const getServiceBand = createAsyncThunk<ServiceBand, ServiceBand, AsyncThunkParams>(
  'serviceBands/getServiceBand',
  async serviceBand => await commonObjectGet<ServiceBand>(serviceBand.urls.details),
)

export const deleteServiceBand = createAsyncThunk<number, ServiceBand, AsyncThunkParams>(
  'serviceBands/deleteServiceBand',
  async serviceBand => {
    await commonObjectDelete<ServiceBand>(serviceBand.urls.details)
    return serviceBand.id
  },
)
