import * as React from 'react'
import { useDocumentTitle } from '@helpers/utils'
import { PageTitle } from '@hyper/page-title'
import { useAppDispatch, useAppSelector } from '@store/index'
import { getUserReportTasks } from '@store/actions/report-tasks-actions'
import { Card, CardBody } from 'reactstrap'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { UserReportsTasksTable } from '@modules/reports/user-reports/user-reports-tasks-table'
import { UserReportsWebSocketHandler } from '@modules/reports/user-reports/user-reports-web-socket-handler'
import { ReportsGenerator } from '@modules/reports/user-reports/reports-generator'
import TopBarProgress from 'react-topbar-progress-indicator'
import { TableFilters } from '@components/table/table'
import { useApiRequest } from '@components/hooks/use-api-request'
import { totalSizeSelector } from '@store/selectors/dashboard'
import { selectReportTasks } from '@store/slices/report-task-slice'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { ReportListFilters } from '@modules/reports/user-reports/reports-list-filters'
import { CustomReactSelectOption } from '@components/custom-react-select'

export interface ReportListFilterParams extends TableFilters {
  created_by?: CustomReactSelectOption | null
  kind?: CustomReactSelectOption | null
  created_after?: Date | null
  created_before?: Date | null
}

const defaultFilters: ReportListFilterParams = {
  ordering: '-created',
  page: 1,
  page_size: 10,
  search: '',
  created_by: null,
  kind: null,
  created_after: null,
  created_before: null,
}

export const UserReportsView: React.FC = () => {
  const dispatch = useAppDispatch()
  const [filters, setFilters] = React.useState<ReportListFilterParams>(defaultFilters)

  const { isLoading, action: fetchUserReports } = useApiRequest(
    async () =>
      await dispatch(
        getUserReportTasks({ ...filters, kind: filters.kind?.value, created_by: filters.created_by?.value }),
      ),
  )

  React.useEffect(() => {
    fetchUserReports()
  }, [filters])

  useMenuActive(NavigationPath.ReportsUserReport)

  const reportTasks = useAppSelector(selectReportTasks)

  const totalSize = useAppSelector(totalSizeSelector)

  const pageTitle = `Raporty użytkownika (${totalSize})`
  useDocumentTitle(pageTitle)

  return (
    <>
      <UserReportsWebSocketHandler />
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitle} />

      <Card>
        <CardBody>
          {isLoading && TopBarProgress && <TopBarProgress />}
          <div className="d-flex justify-content-between align-items-center mb-3">
            <ReportListFilters filters={filters} setFilters={setFilters} defaultFilters={defaultFilters} />
            <ReportsGenerator />
          </div>

          <LoaderPlaceholder content={reportTasks}>
            <UserReportsTasksTable
              isLoading={isLoading}
              filters={filters}
              setFilters={setFilters}
              reportTasks={reportTasks || []}
            />
          </LoaderPlaceholder>
        </CardBody>
      </Card>
    </>
  )
}
