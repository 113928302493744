import * as React from 'react'
import { ButtonWithIcon } from '@components/button-with-icon'
import { asDecimal, formatPrice } from '@helpers/utils'
import { CartPaymentMethod } from '@modules/reception/cart/models'
import { useSelector } from 'react-redux'
import { receptionBookingDetailsSelector } from '@store/selectors/reception'
import classNames from 'classnames'

interface Props {
  onPaymentSelection: (method: CartPaymentMethod) => void
  isDisabled: boolean
}

export const ReceptionBookingCartPaymentDeposit = ({ onPaymentSelection, isDisabled }: Props): JSX.Element => {
  const bookingDetails = useSelector(receptionBookingDetailsSelector)

  const paymentLimit = asDecimal(bookingDetails.prices.deposit_payments_total_amount)
    .minus(bookingDetails.prices.deposit_charge)
    .toString()

  return (
    <div className={classNames('px-3 bg-white-smoke border border-top-0 py-2', { 'not-clickable': isDisabled })}>
      <strong className="font-16">Płatność kaucją:</strong>
      <span className="d-block font-12 mt-1">
        Dostępne środki <strong>{formatPrice(paymentLimit)}</strong> z{' '}
        {formatPrice(bookingDetails.prices.deposit_payments_total_amount)}
      </span>
      <ButtonWithIcon
        icon="uil-money-bill lh-initial font-14"
        text="Dolicz do rachunku"
        color="secondary"
        btnClass="d-block w-100 text-left mt-1 font-13"
        handleClick={() => onPaymentSelection('wallet')}
      />
      <ButtonWithIcon
        icon="uil-credit-card lh-initial font-14"
        text="Płatność opaską"
        color="info"
        btnClass="d-block w-100 text-left mt-1 font-13"
        handleClick={() => onPaymentSelection('band')}
      />
    </div>
  )
}
