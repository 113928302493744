import * as React from 'react'
import { Button } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'

export const PackageVipPaymentsAdd: React.FC = () => {
  const [handleToggleDialog] = useModal('PackageVipPaymentsAddDialog')

  return (
    <Button color="green-dark" onClick={handleToggleDialog}>
      Dodaj wpłatę
    </Button>
  )
}
