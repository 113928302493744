import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { IssueOrderDetails } from '@modules/housekeeping/applications/models'
import { HousekeepingApplicationIssuesPreviewModalTable } from '@modules/housekeeping/applications/issue/preview-modal/table'
import { BaseModalProps } from '@components/modals/types'

interface Props extends BaseModalProps {
  issueOrder: IssueOrderDetails
  handleEdit: () => void
}

export const HousekeepingApplicationIssuesPreviewModal: React.FC<Props> = ({
  toggleIsVisible,
  issueOrder,
  handleEdit,
}) => (
  <>
    <ModalHeader toggle={toggleIsVisible}>
      <span className="text-semi-strong">Szczegóły zgłoszenia</span> <strong>{issueOrder.number}</strong>
    </ModalHeader>
    <ModalBody>
      <HousekeepingApplicationIssuesPreviewModalTable issueOrder={issueOrder} />
    </ModalBody>
    <ModalFooter>
      <Button
        color="light"
        type="button"
        onClick={() => {
          handleEdit()
          toggleIsVisible()
        }}
      >
        Edytuj
      </Button>
      <Button color="green" type="button" onClick={toggleIsVisible}>
        Zamknij
      </Button>
    </ModalFooter>
  </>
)
