import * as React from 'react'
import { Card, CardBody } from 'reactstrap'
import { ProductCardTitle } from '@components/products/product-card-title'
import { SubscriptionDetailsInformationRow } from '@modules/subscription/details/information/row'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { useCountries } from '@components/hooks/use-countries'
import { getById } from '@helpers/utils'
import { HrBaseData } from '@modules/hr/common/models'

interface Props {
  data: HrBaseData
  isReadonly: boolean
  children?: React.ReactNode
  onEdit?: () => void
}

export const HrBaseDetails = React.forwardRef<HTMLElement, Props>(({ isReadonly, data, children, onEdit }, ref) => {
  const { countries } = useCountries()
  const nationality = getById(countries, data.nationality)

  return (
    <Card innerRef={ref}>
      <CardBody>
        <ProductCardTitle title="Dane podstawowe" isEditAllowed={!isReadonly} onEdit={onEdit} />
        <table className="table table-sm mb-0">
          <tbody>
            {children}
            <SubscriptionDetailsInformationRow name="Imię i nazwisko">
              {data.first_name} {data.second_name} {data.last_name}
            </SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="Obywatelstwo">
              {nationality?.name ?? ''}
            </SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="Data urodzenia">
              {toDefaultDateFormat(data.birthday)}
            </SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="PESEL">{data.tax_id}</SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="Numer dowodu osobistego">
              {data.personal_id_number}
            </SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="Adres e-mail">
              {data.email || 'brak'}
            </SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="Numer telefonu">
              {data.phone || 'brak'}
            </SubscriptionDetailsInformationRow>
          </tbody>
        </table>
      </CardBody>
    </Card>
  )
})
