import * as React from 'react'
import { RecommendationGift, RecommendationGiftKind } from '@models/crm'
import { Button, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useForm } from 'react-hook-form'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { parseISODate } from '@helpers/date-helper'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { commonObjectPost, commonObjectPut } from '@store/actions/generic-actions'
import { useFormRequest } from '@components/hooks/use-api-request'
import { BaseModalProps } from '@components/modals/types'
import { addRecommendationGiftDetails } from '@store/slices/recommendation-gift-slice'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { RecommendationGiftDialogBase } from '@modules/crm/recommendation-gifts/dialog/base'
import { RecommendationGiftDialogMoneyTransfer } from '@modules/crm/recommendation-gifts/dialog/money-transfer'
import { createSelectOption, extractSelectOptionsValues, getTextWithoutWhitespaces } from '@helpers/utils'
import { Form } from '@hyper/forms/form'
import { FormSelect } from '@hyper/forms/form-select'
import { useCrmAppData } from '@modules/crm/hooks/use-crm-app-data'
import { useSellersOptions } from '@components/hooks/use-grouped-sellers'

interface Props extends BaseModalProps {
  recommendationGift?: RecommendationGift
}

export interface RecommendationGiftDialogFormInputs {
  recommending_person_name: string
  recommended_person_name: string
  received_date: Date | null
  transfer_by_day: Date | null
  account_number: string
  seller: CustomReactSelectOption | undefined
  kind: CustomReactSelectOption<string, RecommendationGiftKind> | undefined
  recommending_related_number: string
}

export const RecommendationGiftDialog: React.FC<Props> = ({ recommendationGift, toggleIsVisible }) => {
  const sellersList = useSellersOptions()

  const methods = useForm<RecommendationGiftDialogFormInputs>({
    defaultValues: {
      ...recommendationGift,
      seller: recommendationGift ? sellersList.find(row => row.value === recommendationGift.seller) : undefined,
      kind: undefined,
      received_date: recommendationGift ? parseISODate(recommendationGift.received_date) : undefined,
      transfer_by_day: recommendationGift ? parseISODate(recommendationGift.transfer_by_day) : undefined,
    },
  })
  const appData = useCrmAppData()
  const options: CustomReactSelectOption<string, RecommendationGiftKind>[] = React.useMemo(
    () =>
      appData.recommendation_gift_kinds.map((row: [RecommendationGiftKind, string]) =>
        createSelectOption(row[1], row[0]),
      ) || [],
    [appData],
  )

  const { addSuccessMessage } = useNotificationHook()
  const createUrl = useAppSelector((state: RootState) => state.appState.appData.urls.crm.recommendation_gifts)

  const dispatch = useAppDispatch()

  const { isLoading, action: onSubmit } = useFormRequest(async (data: RecommendationGiftDialogFormInputs) => {
    dispatch(
      addRecommendationGiftDetails(
        recommendationGift
          ? await commonObjectPut<RecommendationGift>(recommendationGift.urls.details, {
              ...extractSelectOptionsValues(data),
              account_number: getTextWithoutWhitespaces(data.account_number ?? ''),
            })
          : await commonObjectPost<RecommendationGift>(createUrl, {
              ...extractSelectOptionsValues(data),
              account_number: getTextWithoutWhitespaces(data.account_number ?? ''),
            }),
      ),
    )
    addSuccessMessage()
    toggleIsVisible()
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>
        {recommendationGift ? 'Edytuj prezent' : 'Dodaj prezent za polecenie'}
      </ModalHeader>
      <ModalBody>
        <Row>
          {!recommendationGift && <FormSelect options={options} name="kind" label="Rodzaj prezentu" />}
          <RecommendationGiftDialogBase />
          <RecommendationGiftDialogMoneyTransfer recommendationGift={recommendationGift} />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={toggleIsVisible}>
          Zamknij
        </Button>

        <SaveButton role="submit" className="btn btn-green" label="Zapisz" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
