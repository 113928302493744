import * as React from 'react'
import Table, { TableHeaderValue } from '@components/table/table'
import { ClientUser } from '@models/clients'
import { CrmClientsTableRow } from '@modules/crm/clients/list-view/table-row'
import { CrmClientsFiltersParams } from '@modules/crm/clients/list-view/filters'

interface Props {
  filters: CrmClientsFiltersParams
  setFilters: (filters: CrmClientsFiltersParams) => void
  isLoading: boolean
  clients: ClientUser[]
  selectableHeader: React.ReactElement
  selectableCell: (row: any) => React.ReactElement
  selectedRows: ClientUser[]
}

const headerValues: TableHeaderValue<ClientUser>[] = [
  { title: 'Imię i nazwisko', sortField: 'last_name' },
  { title: 'Rodzaj Gościa' },
  { title: 'Numer telefonu', sortField: 'phone' },
  { title: 'E-mail', sortField: 'email' },
  { title: 'Czarna lista' },
  { title: 'Zgoda telefoniczna' },
  { title: 'Zgoda SMS' },
  { title: 'Zgoda e-mail' },
]

export const CrmClientsTable: React.FC<Props> = ({
  isLoading,
  selectableCell,
  filters,
  selectableHeader,
  clients,
  setFilters,
  selectedRows,
}) => (
  <Table
    className="table-hover w-100"
    headerValues={headerValues}
    isLoading={isLoading}
    filters={filters}
    setFilters={setFilters}
    selectableHeader={selectableHeader}
  >
    {clients.map(client => (
      <CrmClientsTableRow
        isSelected={selectedRows.some(row => row.id === client.id)}
        key={client.id}
        client={client}
        selectableCell={selectableCell(client)}
      />
    ))}
  </Table>
)
