import * as React from 'react'
import { Apartment, ApartmentFetchDate } from '@models/apartment'
import TimelineMap from '@modules/reservations/timeline/timeline-map'
import classNames from 'classnames'
import { UncontrolledTooltip } from 'reactstrap'
import { RootState } from '@store/index'
import { connect } from 'react-redux'
import { setSelectedRows } from '@store/actions/timeline-actions'
import * as R from 'ramda'
import { TimelineSidebarApartmentLabel } from '@modules/reservations/timeline/timeline-sidebar-apartment-label'

interface ComponentProps {
  resort: string
  apartments: Apartment[]
}

interface ReduxProps {
  fetchDate: ApartmentFetchDate
  selectedRows: Apartment[]
  isCompactView: boolean
}

interface DispatchProps {
  setSelectedRows: (apartments: Apartment[]) => void
}

type Props = ComponentProps & ReduxProps & DispatchProps

export class TimelineSidebarViewComponent extends React.PureComponent<Props> {
  public handleAddSelectedRows = (apartment: Apartment) => {
    const { selectedRows, apartments, setSelectedRows } = this.props

    if (selectedRows.some(el => el.id === apartment.id)) {
      setSelectedRows([])
      return
    }

    if (selectedRows.length === 0) {
      setSelectedRows([...selectedRows, apartment])
    } else if (selectedRows.length === 1) {
      const positions = [...selectedRows, apartment].map(apartment => apartment.position)
      const apartmentPositionRange = R.range(R.min(...positions), R.max(...positions) + 1)
      const selectedApartments = apartments.filter(apartment => apartmentPositionRange.includes(apartment.position))
      setSelectedRows(selectedApartments)
    }
  }

  public isRowSelected = (apartment: Apartment) =>
    this.props.selectedRows.some((row: Apartment) => row.id === apartment.id)

  public render() {
    return (
      <div id="timeline-sidebar" className="d-flex flex-column calendar__timeline-sidebar">
        <div className="calendar__day-name-icon">
          <TimelineMap resort={this.props.resort} />
        </div>
        <div className="d-flex flex-column position-relative calendar__apartments">
          {this.props.apartments.map(apartment => (
            <div
              className={classNames('calendar__apartment-name d-flex cursor-pointer', {
                'is-compact': this.props.isCompactView,
                'is-selected': this.isRowSelected(apartment),
              })}
              key={apartment.id}
              data-apartment-id={apartment.id}
              onClick={() => this.handleAddSelectedRows(apartment)}
            >
              <TimelineSidebarApartmentLabel apartment={apartment} />
              <span>{apartment.name}</span>

              <div id={`apartment-status-${apartment.id}`} className="calendar__apartment-status-wrapper">
                <div className={`calendar__apartment-status is-${apartment.housekeeping_status || ''}`} />
              </div>
              <UncontrolledTooltip target={`apartment-status-${apartment.id}`} placement="top">
                <div className="text-center">
                  <div className="d-flex align-items-center justify-content-center mb-1">
                    <span
                      className={`calendar__apartment-status d-inline-block mr-1 is-${
                        apartment.housekeeping_status || ''
                      }`}
                    />
                    <strong>{apartment.housekeeping_status_display}</strong>
                  </div>
                  <div>
                    stan na {this.props.fetchDate.date} / godz. {this.props.fetchDate.hour}
                  </div>
                </div>
              </UncontrolledTooltip>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch): DispatchProps => ({
  setSelectedRows: (apartments: Apartment[]) => dispatch(setSelectedRows(apartments)),
})

const mapStateToProps = (state: RootState) => ({
  fetchDate: state.timelineState.fetchDate,
  isCompactView: state.timelineState.isCompactView,
  selectedRows: state.timelineState.selectedRows,
})

const TimelineSidebarView = connect(mapStateToProps, mapDispatchToProps)(TimelineSidebarViewComponent)

export default TimelineSidebarView
