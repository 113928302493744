import * as React from 'react'

import { Apartment } from '@models/apartment'
import classNames from 'classnames'
import timelineHelper from '@helpers/timeline-helper'
import { CalendarDate, TimelineBooking } from '@store/reducers/timeline-reducers'

interface Props {
  booking: TimelineBooking
  apartment: Apartment
  allApartments: Apartment[]
  isFiltered: boolean
  renderedDates: CalendarDate[]
  isCompactView: boolean
}

const TimelineItemExtenderView: React.FC<Props> = ({
  apartment,
  booking,
  isFiltered,
  renderedDates,
  allApartments,
  isCompactView,
}) => {
  const isBookingBeforeApFee = timelineHelper.isBookingBeforeApFee(booking.created_at)
  return (
    <div
      className={classNames(`calendar-content__item is-extended is-${booking.status}`, {
        'is-checkedin': booking.checked_in,
        'is-filtered': isFiltered,
        'is-compact': isCompactView,
        'is-initial-before-ap-fee': booking.status === 'initial' && isBookingBeforeApFee,
        'is-confirmed-before-ap-fee': booking.status === 'confirmed' && isBookingBeforeApFee,
      })}
      style={{
        width: timelineHelper.getDayWidth(),
        left: timelineHelper.getItemExtendedOffset(renderedDates, booking.date_to),
        top: timelineHelper.getItemTopOffset(apartment, allApartments),
      }}
    />
  )
}

export default TimelineItemExtenderView
