import * as React from 'react'
import { ErliPromocode } from '@modules/promotions/erli-promocode/models'
import { Button } from 'reactstrap'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { updateErliPromocodeDetails } from '@store/actions/promotions-actions'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApiRequest } from '@components/hooks/use-api-request'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'

interface ErliPromocodeButtonProps {
  erliPromocode: ErliPromocode
}

export const ErliPromocodeButton: React.FC<ErliPromocodeButtonProps> = ({ erliPromocode }) => {
  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()

  const { isLoading, action: handleAccept } = useApiRequest(async reason => {
    dispatch(updateErliPromocodeDetails(await commonObjectDelete<ErliPromocode>(erliPromocode.urls.details, reason)))
    addSuccessNotification('Kod został oznaczony jako anulowany')
  })

  return (
    <div className="text-right">
      {erliPromocode.status === 'available' && (
        <CommonObjectConfirmAction isLoading={isLoading} handleAccept={handleAccept}>
          <Button color="danger" size="sm">
            Anuluj
          </Button>
        </CommonObjectConfirmAction>
      )}
    </div>
  )
}
