import * as React from 'react'
import { NavigationTabs, NavigationTabsButton } from '@components/navigation-tabs'

export type PromocodesTab = 'groups' | 'codes'

interface Props {
  tab: PromocodesTab
  onTabChange: (tab: PromocodesTab) => void
}

export const PromocodesTabs = ({ tab, onTabChange }: Props) => {
  const buttons: NavigationTabsButton[] = [
    {
      title: 'Grupy kodów',
      isActive: tab === 'groups',
      onClick: () => onTabChange('groups'),
    },
    {
      title: 'Lista kodów',
      isActive: tab === 'codes',
      onClick: () => onTabChange('codes'),
    },
  ]

  return <NavigationTabs buttons={buttons} />
}
