import * as React from 'react'
import { IconWithTooltip } from '@components/icon-with-tooltip'

export const EntranceTicketPriceInformationTooltip = (): JSX.Element => (
  <IconWithTooltip
    icon="uil-exclamation-circle ml-1"
    tooltipId="tooltip-id"
    tooltipPlacement="right"
    tooltipMessage={<span className="font-11 text-left">Zestaw składa się z 4 sztuk.</span>}
    color="text-secondary"
  />
)
