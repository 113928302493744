import * as React from 'react'
import { ContentNote } from '@models/dashboard'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'

interface ReceptionRowNotesTooltipNoteProps {
  note: ContentNote
}

export const ReceptionRowNotesTooltipNote: React.FC<ReceptionRowNotesTooltipNoteProps> = ({ note }) => (
  <div className="reception__notes-tooltip__note">
    <div className="reception__notes-tooltip__note__info">
      <div className="reception__notes-tooltip__note__info__row">
        <i className="uil-calendar-alt text-secondary mr-1 " /> {toDefaultDateTimeFormat(note.created)}
      </div>
      <div className="reception__notes-tooltip__note__info__row">
        <i className="uil-user text-secondary mr-1 " />
        {note.created_by}
      </div>
    </div>
    <div className="reception__notes-tooltip__note__content text-linebreaksbr">{note.content}</div>
  </div>
)
