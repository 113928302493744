import * as React from 'react'
import { PaymentDayUserCloseReport, PaymentDayUserReport } from '@models/payments'
import { UserCashBoxCloseDayCashSummary } from '@modules/payment-registers/user-cashbox/close-day-modal/cash/summary'
import { UserCashBoxCloseDayCashTransactions } from '@modules/payment-registers/user-cashbox/close-day-modal/cash/transactions'

interface Props {
  dayCloseReport: PaymentDayUserCloseReport
  readOnly: boolean
  paymentDayUserReport: PaymentDayUserReport
}

export const UserCashBoxCloseDayCash = ({ paymentDayUserReport, readOnly, dayCloseReport }: Props): JSX.Element => (
  <div className="rounded bg-platinum w-50 mr-2">
    <UserCashBoxCloseDayCashTransactions paymentDayUserReport={paymentDayUserReport} dayCloseReport={dayCloseReport} />
    <div className="hr hr-grey-2 mb-1" />
    <UserCashBoxCloseDayCashSummary
      readOnly={readOnly}
      dayCloseReport={dayCloseReport}
      resortId={paymentDayUserReport.resort_id}
    />
  </div>
)
