import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { BlockadeResult } from '@modules/reservations/blockade/models'

interface Props {
  result: BlockadeResult
}

export const ReservationBlockadeNotBlockedList = ({ result }: Props): JSX.Element => (
  <>
    <hr className="mt-2" />
    <ul className="list-unstyled">
      {result.errors.map(row => (
        <li key={row.apartment.id} className="font-weight-semibold font-16 mb-1 text-start d-flex">
          <IconWithText
            icon="uil-times text-danger lh-initial font-18"
            text={
              <span>
                Lokal {row.apartment.name} - <span className="font-14 font-weight-normal">(Nie zablokowano)</span>
              </span>
            }
          />
        </li>
      ))}
    </ul>
    <hr className="mt-2" />
    <p className="mt-2 text-semi-strong font-16">{result.error}</p>
  </>
)
