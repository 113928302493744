import * as React from 'react'
import { ServiceBand } from '@modules/bands/service-bands/models'
import { Card, CardBody } from 'reactstrap'
import { toDefaultDateFormat, toDefaultDateTimeFormat } from '@helpers/date-helper'
import { formatPrice } from '@helpers/utils'
import { useModal } from '@components/modals/use-modal'
import { ProductCardTitle } from '@components/products/product-card-title'
import { VoucherScope } from '@models/dashboard'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { ServiceBandStatusBadge } from '@modules/bands/service-bands/common/status'

interface Props {
  serviceBandDetails: ServiceBand
  scopes: VoucherScope[]
}

export const ServiceBandDetailsInfo = ({ serviceBandDetails, scopes }: Props): JSX.Element => {
  const [showDialog] = useModal('ServiceBandInfoEditDialog', {
    serviceBandDetails,
  })

  const user = useAuthenticatedUser()
  const canUpdate = user.hasPerm(UserPermission.ServiceBandCanUpdate) && !serviceBandDetails.is_removed
  const handleShowDialog = canUpdate ? showDialog : undefined

  const selectedScopes = serviceBandDetails.scopes.map(
    scope => scopes.find(voucherScope => voucherScope.id === scope)?.name,
  )

  return (
    <Card>
      <CardBody>
        <ProductCardTitle title="Szczegóły opaski testowej" isEditAllowed={canUpdate} onEdit={handleShowDialog} />
        <div className="table-responsive">
          <table className="table table-striped">
            <tbody>
              <tr>
                <td>Nazwa</td>
                <td>{serviceBandDetails.name}</td>
              </tr>
              <tr>
                <td>Limit środków</td>
                <td>{formatPrice(serviceBandDetails.initial_funds)}</td>
              </tr>
              <tr>
                <td>Ośrodek</td>
                <td>{serviceBandDetails.resort}</td>
              </tr>
              <tr>
                <td>Kategorie</td>
                <td>{selectedScopes.join(', ') ?? 'Brak'}</td>
              </tr>
              <tr>
                <td>Właściciel</td>
                <td>
                  {serviceBandDetails.owner.first_name} {serviceBandDetails.owner.last_name}
                </td>
              </tr>
              <tr>
                <td>Utworzono</td>
                <td>
                  {serviceBandDetails.user.first_name} {serviceBandDetails.user.last_name} (
                  {toDefaultDateTimeFormat(serviceBandDetails.created)})
                </td>
              </tr>
              <tr>
                <td>Data ważności</td>
                <td>
                  {toDefaultDateFormat(serviceBandDetails.date_from)} -{' '}
                  {toDefaultDateFormat(serviceBandDetails.date_to)}
                </td>
              </tr>
              <tr>
                <td>Status</td>
                <td>
                  <ServiceBandStatusBadge status={serviceBandDetails.status} />
                  {serviceBandDetails.status === 'removed' && (
                    <>
                      <br />
                      przez {serviceBandDetails.removed_by.first_name} {serviceBandDetails.removed_by.last_name}
                      <br />
                      {toDefaultDateTimeFormat(serviceBandDetails.deleted_at)}
                    </>
                  )}
                </td>
              </tr>
              <tr>
                <td>Historia transakcji</td>
                <td>
                  <a href={serviceBandDetails.urls.booking_change}>Przejdź do rezerwacji</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  )
}
