import * as React from 'react'
import { ReceptionBookingCheckInStepAdditionalImprovement } from '@modules/reception/checkin/step-improvements/reception-booking-check-in-step-additional-improvement'
import { ReceptionBookingCheckInImprovementsSummary } from '@modules/reception/checkin/step-improvements/reception-booking-check-in-improvements-summary'
import classNames from 'classnames'
import { useToggle } from '@components/hooks/use-toggle'
import { ReceptionBookingDetails } from '@models/reception'
import { ImprovementPriceCalculation } from '@store/actions/reception-actions'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { ImprovementAvailability } from '@models/booking'
import { VipImprovementsCodes } from '@modules/reception/checkin/step-improvements/vip/reception-booking-check-in-improvements-vip'
import { HPFTicketImprovementCode } from '@modules/reception/checkin/step-improvements/tickets/hpf/reception-booking-hpf-ticket-improvement'
import { SubscriptionBookingImprovementCode } from '@modules/reception/checkin/step-improvements/improvement-subscription/reception-booking-improvement-subscription'

interface Props {
  booking: ReceptionBookingDetails
  calculatedPrices: ImprovementPriceCalculation[]
  improvementsAvailability: ImprovementAvailability[]
}

export const BookingImprovementsAvailableAdditionalAdd = ({
  booking,
  calculatedPrices,
  improvementsAvailability,
}: Props): JSX.Element => {
  const [isAddingImprovementMode, toggleIsAddingImprovementMode] = useToggle()
  const user = useAuthenticatedUser()

  const availableImprovements = improvementsAvailability.reduce((improvements, improvement) => {
    if (
      VipImprovementsCodes.includes(improvement.code) ||
      improvement.code === HPFTicketImprovementCode ||
      improvement.code === SubscriptionBookingImprovementCode
    ) {
      return improvements
    }

    if (
      ['wine_premium', 'wine_standard'].includes(improvement.code) &&
      !user.hasPerm(UserPermission.BookingCanAddWineImprovement)
    ) {
      return improvements
    }

    return [...improvements, improvement]
  }, [])

  return (
    <>
      <div
        className={classNames('d-flex justify-content-center border-bottom border-dark border-1', {
          'mt-2': !isAddingImprovementMode,
        })}
      />
      {isAddingImprovementMode && !!availableImprovements.length && (
        <ReceptionBookingCheckInStepAdditionalImprovement
          booking={booking}
          availableImprovements={availableImprovements}
          onClose={toggleIsAddingImprovementMode}
          calculatedPrices={calculatedPrices}
        />
      )}
      <ReceptionBookingCheckInImprovementsSummary
        booking={booking}
        toggleIsAddingImprovementMode={toggleIsAddingImprovementMode}
        isAddingImprovementMode={isAddingImprovementMode}
      />
    </>
  )
}
