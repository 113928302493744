import * as React from 'react'
import { Button, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useForm } from 'react-hook-form'
import { endOfMonth, startOfMonth, startOfToday } from 'date-fns'
import { createReportTask } from '@store/actions/report-tasks-actions'
import ModalBody from 'reactstrap/lib/ModalBody'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { SaveButton } from '@hyper/button'
import { useGroupedSellers } from '@components/hooks/use-grouped-sellers'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { BaseModalProps } from '@components/modals/types'
import { packageStatusList, PackageType } from '@models/package'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { Form } from '@hyper/forms/form'
import { FormSelect } from '@hyper/forms/form-select'

interface Props extends BaseModalProps {
  packageType: PackageType
}

interface FormInputs {
  created_after: Date | null
  created_before: Date | null
  status: CustomReactSelectOption | undefined
  seller: CustomReactSelectOption | undefined
}

export const ReportsPackageFullCodeModal: React.FC<Props> = ({ toggleIsVisible, packageType }) => {
  const dispatch = useAppDispatch()

  const isPackageHPI = packageType === PackageType.HPI

  const { report_task_package_hpi_full_code, report_task_package_full_code } = useAppSelector(
    (state: RootState) => state.appState.appData.urls.reports,
  )

  const methods = useForm<FormInputs>({
    mode: 'all',
    defaultValues: {
      created_after: startOfMonth(startOfToday()),
      created_before: endOfMonth(startOfToday()),
      seller: undefined,
      status: undefined,
    },
  })

  const { isLoading, action: onSubmit } = useReportFormRequest(async (payload: FormInputs) => {
    const reportTask = await dispatch(
      createReportTask([
        isPackageHPI ? report_task_package_hpi_full_code : report_task_package_full_code,
        {
          ...payload,
          status: payload.status?.value || null,
          seller: payload.seller?.value || null,
        },
      ]),
    )
    toggleIsVisible()

    return reportTask
  }, methods.setError)

  const sellers = useGroupedSellers([])

  return (
    <Form onSubmit={onSubmit} methods={methods}>
      <ModalHeader toggle={toggleIsVisible}>
        {isPackageHPI ? 'Raport pakietów firmowych HPI - Kody' : 'Raport pakietów firmowych HPR - Kody'}
      </ModalHeader>
      <ModalBody>
        <Row>
          <DateFromToInput label="Data utworzenia" startDateName="created_after" endDateName="created_before" />

          <FormSelect options={packageStatusList} name="status" label="Status" formPlainProps={{ colSize: 6 }} />

          <FormSelect
            options={sellers}
            name="seller"
            label="Sprzedawca"
            formPlainProps={{ colStyle: { width: 200 } }}
          />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" label="Zleć raport" labelSaving="Zlecanie.." isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
