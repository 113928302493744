import * as React from 'react'
import { useModal } from '@components/modals/use-modal'
import { useAppDispatch } from '@store/index'
import { setReceptionBookingDetails } from '@store/slices/reception-slice'

interface Props {
  className?: string
  children?: React.ReactNode
}

export const DashboardLinkReservationsCreate = ({ children, className }: Props): JSX.Element => {
  const dispatch = useAppDispatch()

  const [handleOpen] = useModal(
    'ReservationCreateModal',
    {},
    {
      persist: true,
      onClose: React.useCallback(() => {
        dispatch(setReceptionBookingDetails(undefined))
      }, []),
    },
  )

  return (
    <a href="#" onClick={handleOpen}>
      <span className={className}>{children}</span>
    </a>
  )
}
