import * as React from 'react'
import AsyncSelect from 'react-select/async'
import { OptionProps, SelectComponentsConfig, SingleValueProps } from 'react-select'
import { useAppData } from '@components/hooks/use-app-data'
import { useFormContext, useWatch } from 'react-hook-form'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectGet } from '@store/actions/generic-actions'
import { PaginationResponse } from '@models/dashboard'
import { FormPlain } from '@hyper/forms'
import { getReactSelectStyles } from '@components/custom-react-select'

type BookingAutocompleteMode = 'gastro_coupon' | 'technical_order' | 'shop'
type BookingAutocompleteType = 'present' | 'issue_orders'

export interface BookingAutocompleteResponse {
  date_from: string
  date_to: string
  checked_in: boolean
  apartment_name: string
  id: number
  name: string
  reservation_number: string
  email: string
  phone: string
}

interface Filters {
  apartment?: string
  resort: string
}

interface Props {
  type?: BookingAutocompleteType
  name: string
  label: React.ReactNode
  placeholder?: string
  onDataChange?: (data: BookingAutocompleteResponse | null) => void
  isDisabled?: boolean
  labelSize?: number
  mode?: BookingAutocompleteMode
  resort?: number
  filters?: Partial<Filters>
  valueAsId?: boolean
  defaultValue?: BookingAutocompleteResponse
  fetchOnMount?: boolean
}

export const BookingAutocomplete: React.FC<Props> = ({
  type,
  name,
  valueAsId,
  placeholder,
  filters,
  label,
  labelSize = 12,
  onDataChange,
  isDisabled,
  mode,
  resort,
  defaultValue,
  fetchOnMount,
}) => {
  const [availableBookings, setAvailableBookings] = React.useState<BookingAutocompleteResponse[]>(() =>
    defaultValue ? [defaultValue] : [],
  )
  const { setValue, formState, clearErrors, control } = useFormContext()
  const { urls } = useAppData()

  const onChange = (data: BookingAutocompleteResponse | null) => {
    clearErrors(name)

    if (onDataChange) {
      onDataChange?.(data)
      return
    }

    setValue(name, valueAsId ? data?.id ?? null : data?.reservation_number ?? null)
  }

  const { action: fetchBookings } = useApiRequest(async search => {
    const url = type === 'present' ? urls.rent.booking_autocomplete : urls.housekeeping.issue_order_bookings

    const { results } = await commonObjectGet<PaginationResponse<BookingAutocompleteResponse>>(url, {
      search,
      ...filters,
      ...(type && { type }),
      ...(mode && { mode }),
      ...(resort && { resort }),
    })
    setAvailableBookings(results)
    return results
  })

  const bookingNumber = useWatch({ control, name })
  const value = availableBookings.find(availableBooking => availableBooking.reservation_number === bookingNumber)

  React.useEffect(() => {
    if (fetchOnMount) {
      fetchBookings(bookingNumber)
    }
  }, [])

  return (
    <FormPlain errors={formState.errors} name={name} labelSize={labelSize} label={label}>
      <AsyncSelect
        defaultOptions={[]}
        loadOptions={fetchBookings}
        placeholder={placeholder ?? 'Wpisz numer rezerwacji'}
        isClearable={true}
        components={SelectComponents}
        onChange={onChange}
        styles={selectStyles}
        loadingMessage={() => 'Ładowanie rezerwacji...'}
        value={value}
        isDisabled={isDisabled}
      />
    </FormPlain>
  )
}

const SelectComponents: SelectComponentsConfig<any, false, any> = {
  Option: (props: OptionProps<any, false, any>) => (
    <div className="async-select__option" ref={props.innerRef} {...props.innerProps}>
      {props.data.name} ({props.data.email})
      <span className="d-block mt-1">
        Rezerwacja: {props.data.reservation_number}, Lokal: {props.data.apartment_name}
      </span>
    </div>
  ),
  SingleValue: (props: SingleValueProps<any>) => (
    <div>
      {props.data.name} ({props.data.reservation_number})
    </div>
  ),
  NoOptionsMessage: () => (
    <div className="text-center" style={{ padding: '8px' }}>
      Nie znaleziono rezerwacji.
    </div>
  ),
  IndicatorSeparator: () => null,
}

const selectStyles = {
  ...getReactSelectStyles({ hasError: false }),
  placeholder: provided => ({ ...provided, position: 'absolute' }),
  valueContainer: provided => ({ ...provided, display: 'flex' }),
}
