import * as React from 'react'
import { BookingGuestFeedingOption, CalculatedFeedingPrice, ReceptionBookingDetails } from '@models/reception'
import { ReceptionBookingGuest } from '@models/booking'
import { ReceptionBookingFeedingRow } from '@modules/reception/checkin/step-feeding/reception-booking-feeding-row'
import { FeedingsHandler } from '@modules/reception/checkin/step-feeding/use-feedings-with-defaults'
import { getCalculatedFeedingPrices } from '@store/actions/reception-actions'
import { formatPriceShort } from '@helpers/utils'
import { useAppDispatch, useAppSelector } from '@store/index'
import { useCancelablePromise } from '@components/hooks/use-cancelable-promise'
import { CancelTokenSource } from 'axios'
import classNames from 'classnames'
import { selectCalculatedFeedingPrices } from '@store/slices/reception-slice'

interface ReceptionBookingFeedingListProps {
  booking: ReceptionBookingDetails
  feedingsHandler: FeedingsHandler
  guests: ReceptionBookingGuest[]
  groupId: number
  isSingleGroup: boolean
}

const getFeedingPricesForGroup = (
  feedingPrices: CalculatedFeedingPrice[],
  groupId: number,
): CalculatedFeedingPrice | undefined => feedingPrices.find(data => data.group_id === groupId)

export const ReceptionBookingFeedingList: React.FC<ReceptionBookingFeedingListProps> = ({
  booking,
  feedingsHandler,
  guests,
  groupId,
  isSingleGroup,
}) => {
  const calculatedPrices = useAppSelector(selectCalculatedFeedingPrices)
  const calculatedFeedingPrice = getFeedingPricesForGroup(calculatedPrices, groupId)

  const [breakfast, setBreakfast] = React.useState(false)
  const [dinner, setDinner] = React.useState(false)
  const readOnly = booking.status === 'close'
  const dispatch = useAppDispatch()

  const rows = guests.map((guest: ReceptionBookingGuest) => {
    const feedingForGuest = feedingsHandler.getFeedingsForGuest(guest.id)
    return (
      <ReceptionBookingFeedingRow
        booking={booking}
        key={guest.id}
        guest={guest}
        guests={guests}
        feedingPrices={calculatedFeedingPrice?.rows}
        feedingOptions={feedingForGuest}
        feedingsHandler={feedingsHandler}
      />
    )
  })

  const calculateNewPrices = async (cancelToken: CancelTokenSource) => {
    const payload = feedingsHandler.feedingOptions.filter(option => guests.some(guest => guest.id === option.guest_id))
    dispatch(getCalculatedFeedingPrices(booking.urls.feeding_calculator, payload, groupId, cancelToken))
  }

  useCancelablePromise(
    cancelToken => {
      calculateNewPrices(cancelToken)
    },
    [feedingsHandler.feedingOptions],
  )

  React.useEffect(() => {
    if (feedingsHandler.feedingOptions.every((feedingOption: BookingGuestFeedingOption) => !feedingOption.breakfast))
      setBreakfast(false)
    if (feedingsHandler.feedingOptions.every((feedingOption: BookingGuestFeedingOption) => !feedingOption.dinner))
      setDinner(false)
    if (feedingsHandler.feedingOptions.every((feedingOption: BookingGuestFeedingOption) => feedingOption.breakfast))
      setBreakfast(true)
    if (feedingsHandler.feedingOptions.every((feedingOption: BookingGuestFeedingOption) => feedingOption.dinner))
      setDinner(true)
  }, [feedingsHandler.feedingOptions])

  const guestsIds = booking.guests.map((guest: ReceptionBookingGuest) => guest.id)

  const handleBreakfast = () => {
    if (!readOnly) {
      feedingsHandler.setFeedingOptionForGuest(guestsIds, undefined, !breakfast)
      setBreakfast(!breakfast)
    }
  }

  const handleDinner = () => {
    if (!readOnly) {
      feedingsHandler.setFeedingOptionForGuest(guestsIds, !dinner, undefined)
      setDinner(!dinner)
    }
  }

  return (
    <>
      <table className="table table-borderless">
        <tbody>
          <tr className="border-bottom">
            <th className="reception__booking-check-in__feeding__name-col" />
            <th className="reception__booking-check-in__feeding__bb-col cursor-pointer" onClick={handleBreakfast}>
              Śniadanie
            </th>
            <th className="reception__booking-check-in__feeding__hb-col cursor-pointer" onClick={handleDinner}>
              Obiadokolacje
            </th>
            <th className="reception__booking-check-in__feeding__fb-col">Pełne wyżywienie</th>
            <th colSpan={4} />
          </tr>
          {rows}
        </tbody>
      </table>
      <div className="d-flex justify-content-between">
        <strong className={classNames('d-block font-13', isSingleGroup ? 'font-14' : 'font-13')}>
          Wyżywienie grupy:
        </strong>
        <strong className={classNames('d-block text-danger', isSingleGroup ? 'font-18' : 'font-16')}>
          {formatPriceShort(calculatedFeedingPrice?.total_price_brutto || '0')}
        </strong>
      </div>
    </>
  )
}
