import * as React from 'react'
import { FormSelect } from '@hyper/forms/form-select'
import { CustomReactSelectOption } from '@components/custom-react-select'

interface Props {
  options: CustomReactSelectOption[]
  name: string
  placeholder: string
}

export const HousekeepingOrderTableHeaderStatus = ({ options, name, placeholder }: Props): JSX.Element => (
  <th className="py-1 border-top-0 fw-semi-bold pl-0" style={{ width: 200 }}>
    <FormSelect
      options={options}
      selectProps={{ placeholder, isClearable: true, isSearchable: false }}
      name={name}
      formPlainProps={{ formGroupClassName: 'mb-0', colClassName: 'px-0' }}
    />
  </th>
)
