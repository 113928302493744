import { AsyncThunkParams, RootState } from '@store/index'

import {
  BeddingOrder,
  CleaningOrder,
  CleaningOrdersAggregation,
  CommonTask,
  HousekeepingAppData,
  HousekeepingBeddingOrderType,
  HousekeepingCompany,
  HousekeepingCleaningOrderType,
  HousekeepingCommonTaskType,
  HousekeepingImprovementOrderType,
  HousekeepingUser,
  ImprovementOrder,
} from '@modules/housekeeping/models'
import { HousekeepingCleaningOrdersPayload, HousekeepingOrdersPayload } from '@api/housekeeping'
import { setDashboardStandardPaginator } from '@store/actions/dashboard-actions'
import {
  ApplicationsAggregation,
  FaultOrderDetails,
  IssueOrderDetails,
} from '@modules/housekeeping/applications/models'
import { FiltersHousekeepingApplicationFiltersParams } from '@modules/housekeeping/applications/filters'
import { commonObjectGet } from '@store/actions/generic-actions'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { PaginationResponse } from '@models/dashboard'
import { HousekeepingUsersListTableFilters } from '@modules/housekeeping/users/list'

interface HousekeepingAggregation<T> {
  orders: T[]
  aggregation: CleaningOrdersAggregation
}

export const getHousekeepingCommonTask = createAsyncThunk<
  HousekeepingAggregation<CommonTask>,
  [HousekeepingCleaningOrdersPayload, HousekeepingCommonTaskType],
  AsyncThunkParams
>(
  'housekeeping/getHousekeepingCommonTask',
  async ([payload, type], { getState }) =>
    await commonObjectGet<HousekeepingAggregation<CommonTask>>(
      getState().appState.appData.urls.housekeeping.common_tasks_list,
      { ...payload, type },
    ),
)

export const getHousekeepingCleaningOrders = createAsyncThunk<
  HousekeepingAggregation<CleaningOrder>,
  [HousekeepingCleaningOrdersPayload, HousekeepingCleaningOrderType],
  AsyncThunkParams
>(
  'housekeeping/getHousekeepingCleaningOrders',
  async ([payload, type], { getState }) =>
    await commonObjectGet<HousekeepingAggregation<CleaningOrder>>(
      getState().appState.appData.urls.housekeeping.cleaning_orders_list,
      { ...payload, type },
    ),
)

export const getHousekeepingBeddingOrders = createAsyncThunk<
  HousekeepingAggregation<BeddingOrder>,
  [HousekeepingOrdersPayload, HousekeepingBeddingOrderType],
  AsyncThunkParams
>(
  'housekeeping/getHousekeepingBeddingOrders',
  async ([payload, type], { getState }) =>
    await commonObjectGet<HousekeepingAggregation<BeddingOrder>>(
      getState().appState.appData.urls.housekeeping.bedding_orders_list,
      { ...payload, type },
    ),
)

export const getHousekeepingCleaningOrdersAggregation = createAsyncThunk<
  CleaningOrdersAggregation,
  number,
  { state: RootState }
>(
  'packages/getHousekeepingCleaningOrdersAggregation',
  async (resort, { getState }) =>
    await commonObjectGet<CleaningOrdersAggregation>(
      getState().appState.appData.urls.housekeeping.cleaning_orders_aggregation,
      { resort },
    ),
)
export const getHousekeepingAppData = createAsyncThunk<HousekeepingAppData, number | undefined, AsyncThunkParams>(
  'packages/getHousekeepingAppData',
  async (resort, { getState }) => {
    const currentAppData = getState().houseKeepingState.appData
    if (currentAppData.status === 'loading' || currentAppData.resort === resort) {
      return currentAppData
    }

    const newAppData = await commonObjectGet<HousekeepingAppData>(
      getState().appState.appData.urls.housekeeping.app_data,
      { resort },
    )
    return { ...newAppData, resort }
  },
)

export const getApplicationFaults = createAsyncThunk<
  FaultOrderDetails[],
  [FiltersHousekeepingApplicationFiltersParams, number],
  AsyncThunkParams
>('housekeeping/getApplicationFaults', async ([payload, resort], { getState, dispatch }) => {
  const data = await commonObjectGet<PaginationResponse<FaultOrderDetails>>(
    getState().appState.appData.urls.housekeeping.fault_orders,
    { ...payload, resort },
  )
  dispatch(setDashboardStandardPaginator(data))

  return data.results
})

export const getApplicationsAggregation = createAsyncThunk<
  ApplicationsAggregation,
  [FiltersHousekeepingApplicationFiltersParams, number],
  AsyncThunkParams
>(
  'housekeeping/getApplicationsAggregation',
  async ([payload, resort], { getState }) =>
    await commonObjectGet<ApplicationsAggregation>(
      getState().appState.appData.urls.housekeeping.applications_aggregation,
      { ...payload, resort },
    ),
)
interface HousekeepingUsersResponse extends PaginationResponse<HousekeepingUser> {
  aggregation: {
    companies: HousekeepingCompany[]
  }
}
export const getHousekeepingUsers = createAsyncThunk<
  [HousekeepingUser[], HousekeepingCompany[]],
  HousekeepingUsersListTableFilters,
  AsyncThunkParams
>('housekeeping/getHousekeepingUsers', async (params, { getState, dispatch }) => {
  const data = await commonObjectGet<HousekeepingUsersResponse>(
    getState().appState.appData.urls.account.housekeeping_users,
    {
      ...params,
      resort: params.resort?.value,
      housekeeping_company: params.housekeeping_company?.value,
      is_active: params.is_active?.value,
      ...Object.fromEntries(params.roles.map(row => [row.value, true])),
    },
  )
  dispatch(setDashboardStandardPaginator(data))

  return [data.results, data.aggregation.companies]
})

export const getApplicationIssues = createAsyncThunk<
  IssueOrderDetails[],
  [FiltersHousekeepingApplicationFiltersParams, number],
  AsyncThunkParams
>('housekeeping/getApplicationIssues', async ([payload, resort], { getState, dispatch }) => {
  const data = await commonObjectGet<PaginationResponse<IssueOrderDetails>>(
    getState().appState.appData.urls.housekeeping.issue_orders,
    { ...payload, resort },
  )
  dispatch(setDashboardStandardPaginator(data))

  return data.results
})

export const getHousekeepingImprovementOrders = createAsyncThunk<
  HousekeepingAggregation<ImprovementOrder>,
  [HousekeepingOrdersPayload, HousekeepingImprovementOrderType],
  AsyncThunkParams
>(
  'housekeeping/getHousekeepingImprovementOrders',
  async ([payload, type], { getState }) =>
    await commonObjectGet<HousekeepingAggregation<ImprovementOrder>>(
      getState().appState.appData.urls.housekeeping.improvement_orders_provider,
      { ...payload, type },
    ),
)
