import * as React from 'react'
import { ShopTicket } from '@modules/shop/pos/models'
import Decimal from 'decimal.js'
import { asDecimal, formatNumberWithThousandSeparator, formatPriceShort } from '@helpers/utils'
import { useFormContext, useWatch } from 'react-hook-form'
import { ShopPOSWithParamsFormInputs } from '@modules/shop/pos/shop-pos-with-params'

interface ShopPurchaseTicketSummarySingleRowProps {
  ticket: ShopTicket
  ticketFactor: Decimal
  handleRemoveTicket: () => void
}

export const ShopPurchaseTicketSummarySingleRow: React.FC<ShopPurchaseTicketSummarySingleRowProps> = ({
  ticket,
  ticketFactor,
  handleRemoveTicket,
}) => {
  const ticketValue = formatNumberWithThousandSeparator(ticket.value)
  const ticketPrice = formatPriceShort(asDecimal(ticket.value).mul(ticketFactor).toString())
  const { control } = useFormContext<ShopPOSWithParamsFormInputs>()

  const paymentEnabled = useWatch({
    control,
    name: 'paymentEnabled',
  })
  return (
    <tr className="font-12 text-muted">
      <td className="py-1">Wartość ticketu</td>
      <td className="py-1">{`${ticketValue} (-${ticketPrice})`}</td>
      <td className="text-right py-1">
        {!paymentEnabled && (
          <i
            className="uil-trash-alt font-14 cursor-pointer"
            onClick={handleRemoveTicket}
            data-testid="remove-ticket"
          />
        )}
      </td>
    </tr>
  )
}
