import * as React from 'react'
import { Collapse } from 'reactstrap'
import { ReceptionBookingDetailsCartItem } from '@models/reception'
import { ReceptionBookingCartFeedingGuestMeal } from '@modules/reception/cart/cart-items/feeding/reception-booking-cart-feeding-guest-meal'

interface Props {
  isExpanded: boolean
  meals: ReceptionBookingDetailsCartItem[]
}

export const ReceptionBookingCartFeedingGuestMeals = ({ isExpanded, meals }: Props): JSX.Element => (
  <Collapse isOpen={isExpanded} className="w-100">
    {meals.map((meal: ReceptionBookingDetailsCartItem) => (
      <ReceptionBookingCartFeedingGuestMeal key={meal.id} meal={meal} />
    ))}
  </Collapse>
)
