import * as React from 'react'
import { toTextDateFormat } from '@helpers/date-helper'
import { NewFeedingKind } from '@modules/reception/checkin/step-feeding/new-feeding/models'

interface Props {
  kind: NewFeedingKind
  related_date: string
}

export const ReceptionBookingNewFeedingPriceTooltipBestseller = ({ kind, related_date }: Props): JSX.Element => (
  <div className="p-2 border-bottom border-gray d-flex align-items-center justify-content-lg-between">
    <p className="mb-0 pl-1 text-left">
      Posiłek łączony w Bestseller <br />z {kind === 'breakfast' ? 'Obiadokolacja' : 'Śniadanie'}{' '}
      {toTextDateFormat(related_date)}
    </p>
    <span className="badge badge-light mr-1 font-12">Bestseller</span>
  </div>
)
