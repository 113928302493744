import * as React from 'react'
import { ReceptionBookingGuestWalletLimits } from '@models/booking'
import { UncontrolledTooltip } from 'reactstrap'
import { IconWithText } from '@components/icon-with-text'
import { formatPriceShort } from '@helpers/utils'
import classNames from 'classnames'
import {
  ResortAvailableScope,
  useResortWalletGroupedScope,
} from '@modules/reception/checkin/step-guests/step-guests-guest-form/wallet/use-resort-wallet-scope'

interface Props {
  walletLimits: ReceptionBookingGuestWalletLimits[]
  guestId: number
  resortId: number
  wrapperClassName?: string
}

export const StepGuestsWalletScopesPreview = ({
  walletLimits,
  guestId,
  wrapperClassName,
  resortId,
}: Props): JSX.Element => {
  const { availableShopScopes, availableGastroScopes, availableVendingMachinesScopes, availableParkAndFunScopes } =
    useResortWalletGroupedScope(resortId)

  const getScopes = React.useCallback(
    (items: ResortAvailableScope[]) =>
      items.reduce((prev, scope) => {
        const wallet = walletLimits.find(wallet => wallet.scope === scope.key)
        return wallet ? [...prev, wallet] : prev
      }, []),
    [walletLimits],
  )

  const shopScopes = getScopes(availableShopScopes)
  const gastroScopes = getScopes(availableGastroScopes)
  const vendingMachinesScopes = getScopes(availableVendingMachinesScopes)
  const parkAndFunScopes = getScopes(availableParkAndFunScopes)

  return (
    <div className={classNames('mt-1', wrapperClassName)}>
      {!!availableGastroScopes.length && (
        <ScopeRow
          title="Punkty Gastronomiczne"
          tooltipId={`scopes-gastro-${guestId}`}
          amounts={`${gastroScopes.length}/${availableGastroScopes.length}`}
          scopes={gastroScopes}
          tooltipTitle={
            <span>
              Dostęp do zakupów w <strong>Punktach Gastronomiczny:</strong>
            </span>
          }
        />
      )}

      {!!availableVendingMachinesScopes.length && (
        <ScopeRow
          title="Automaty"
          tooltipId={`scopes-vending-machines-${guestId}`}
          amounts={`${vendingMachinesScopes.length}/${availableVendingMachinesScopes.length}`}
          scopes={vendingMachinesScopes}
          tooltipTitle={
            <span>
              Dostęp do zakupów w <strong>Automatach:</strong>
            </span>
          }
          style={{ marginBlock: '3px' }}
        />
      )}

      {!!availableShopScopes.length && (
        <ScopeRow
          title="Sklep"
          tooltipId={`scopes-shop-${guestId}`}
          amounts={`${shopScopes.length}/${availableShopScopes.length}`}
          scopes={shopScopes}
          tooltipTitle={
            <span>
              Dostęp do zakupów w <strong>Sklepie:</strong>
            </span>
          }
        />
      )}

      {!!availableParkAndFunScopes.length && (
        <ScopeRow
          title="Holiday Park & Fun "
          tooltipId={`scopes-parkandfun-${guestId}`}
          amounts={`${parkAndFunScopes.length}/${availableParkAndFunScopes.length}`}
          scopes={parkAndFunScopes}
          style={{ marginBlock: '3px' }}
          tooltipTitle={
            <span>
              Dostęp do <strong>Holiday Park & Fun:</strong>
            </span>
          }
        />
      )}
    </div>
  )
}

interface ScopeRowParams extends React.HTMLAttributes<HTMLDivElement> {
  title: string
  tooltipId: string
  tooltipTitle: React.ReactNode
  scopes: ReceptionBookingGuestWalletLimits[]
  amounts: string
}

const ScopeRow = ({ title, amounts, tooltipId, tooltipTitle, scopes, ...props }: ScopeRowParams) => (
  <div {...props}>
    <div id={tooltipId}>
      <strong>[{amounts}]</strong> {title}
    </div>
    <UncontrolledTooltip placement="bottom" target={tooltipId} style={{ maxWidth: 350 }} innerClassName="pt-2">
      <div className="font-11">
        {scopes.length ? (
          <>
            <div className="mb-2 text-left">{tooltipTitle}</div>
            {scopes.map(scope => (
              <IconWithText
                key={scope.scope}
                icon="uil-check font-14 lh-0"
                text={
                  <strong>
                    {scope.scope_display}{' '}
                    {!!scope.limit && <span className="fw-semi-bold">(limit {formatPriceShort(scope.limit)})</span>}
                  </strong>
                }
                wrapperClassNames="font-weight-bold mb-1"
                flexClassName="d-block text-left"
              />
            ))}
          </>
        ) : (
          <div className="mb-1 text-left fw-semi-bold">Dostęp nie został nadany</div>
        )}
      </div>
    </UncontrolledTooltip>
  </div>
)
