import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { ReceptionBookingCheckOutStepThanksBoxReceiptPrint } from '@modules/reception/checkout/step-thanks/receipt-print'
import { DocumentsLanguageSelect } from '@modules/reception/common/documents-language-select'

interface Props {
  receptionBookingDetails: ReceptionBookingDetails
}

export const ReceptionBookingCheckOutStepThanksBoxReceipt: React.FC<Props> = ({ receptionBookingDetails }) => (
  <div className=" reception__booking-check-out__receipt-box mb-3 rounded bg-primary text-white p-3">
    <div className="text-strong font-14">Rachunek końcowy dla Gościa:</div>

    <div className="d-flex align-items-end justify-content-between mt-2">
      <div className="col-md-5 px-0">
        <DocumentsLanguageSelect label="Rachunek w języku:" readOnly />
      </div>

      <div className="d-flex flex-wrap justify-content-end ml-2">
        <ReceptionBookingCheckOutStepThanksBoxReceiptPrint booking={receptionBookingDetails} />
      </div>
    </div>
  </div>
)
