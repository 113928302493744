import * as React from 'react'
import { IssueOrderDetails } from '@modules/housekeeping/applications/models'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useModal } from '@components/modals/use-modal'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { useApiRequest } from '@components/hooks/use-api-request'

interface IssueOrderCellActionsProps {
  issueOrder: IssueOrderDetails
}

export const IssueOrderCellActions: React.FC<IssueOrderCellActionsProps> = ({ issueOrder }) => {
  const { addSuccessMessage } = useNotificationHook()

  const { isLoading: isDeleting, action: handleRemoveEvent } = useApiRequest(async () => {
    await commonObjectDelete(issueOrder.urls.details)
    addSuccessMessage('Sukces', 'Zgłoszenie została skasowana')
  })

  const [handleEdit] = useModal('HousekeepingApplicationIssueCreateModal', {
    issueOrder,
    resortId: issueOrder.resort_id,
  })

  const [handlePreview] = useModal('HousekeepingApplicationIssuesPreviewModal', {
    issueOrder,
    handleEdit,
  })

  return (
    <>
      <span onClick={handlePreview}>
        <i className="uil uil-eye cursor-pointer ml-2 text-muted" title="Podgląd" />
      </span>
      <span onClick={handleEdit}>
        <i className="uil uil-pen cursor-pointer ml-2 text-muted" title="Edytuj" />
      </span>

      <CommonObjectConfirmAction isLoading={isDeleting} handleAccept={handleRemoveEvent}>
        <i className="uil uil-trash-alt cursor-pointer ml-2 text-muted" title="Skasuj" />
      </CommonObjectConfirmAction>
    </>
  )
}
