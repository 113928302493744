import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import { useForm } from 'react-hook-form'
import { SaveButton } from '@hyper/button'
import { useFormRequest } from '@components/hooks/use-api-request'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { ReceptionBookingDetails, WalletTransaction } from '@models/reception'
import { BookingAutocomplete } from '@components/booking-autosuggest'
import { FormInput } from '@hyper/forms'
import { Form } from '@hyper/forms/form'

interface Props extends BaseModalProps {
  transaction: WalletTransaction
  booking: ReceptionBookingDetails
}

interface FormInputs {
  new_booking: string
  reason: string
}

export const ReservationPaymentTransferModal: React.FC<Props> = ({ toggleIsVisible }) => {
  const methods = useForm<FormInputs>()
  // const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    console.log('call transfer action', payload)
    addSuccessMessage()
    toggleIsVisible()
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Przenieś płatność</ModalHeader>
      <ModalBody>
        <Row>
          <BookingAutocomplete type="present" name="new_booking" label="Numer rezerwacji" />
          <FormInput type="textarea" label="Powód" name="reason" inputProps={{ rows: 3 }} />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton
          role="submit"
          className="btn btn-green"
          label="Przenieś"
          isSaving={isLoading}
          labelSaving="Przenoszenie"
        />
      </ModalFooter>
    </Form>
  )
}
