import * as React from 'react'
import { UpSellBooking } from '@models/upselling'
import Table from '@components/table/table'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'
import { UPSELL_BOOKING_TAG, upSellBookingApi } from '@api/upSellBooking'
import { createApiTag } from '@api/query-helper'
import { useAppDispatch } from '@store/index'

interface Props {
  upsellBooking: UpSellBooking
}

export const UpSellBookingsTableRowActions: React.FC<Props> = ({ upsellBooking }) => {
  const dispatch = useAppDispatch()

  const refetchData = () => {
    dispatch(upSellBookingApi.util?.invalidateTags([createApiTag(UPSELL_BOOKING_TAG, upsellBooking.id)]))
  }

  const [showStatusChangeModal] = useModal('UpsellBookingsStatusChangeModal', { upsellBooking })
  const [showDetailsModal] = useModal(
    'ReceptionBookingDetailsModal',
    { booking: upsellBooking.booking, initialTab: 'improvements', tabs: ['improvements', 'feedingNew'] },
    { onClose: refetchData },
  )

  return (
    <Table.Cell className="text-right align-middle">
      <UncontrolledDropdown direction="left">
        <DropdownToggle color="transparent" onClick={e => e.preventDefault()}>
          <i className="uil-ellipsis-v font-16" />
        </DropdownToggle>
        <DropdownMenu className="p-0">
          <DropdownItem className="fw-semi-bold py-2 border-bottom" onClick={showStatusChangeModal}>
            Zmień status
          </DropdownItem>
          <DropdownItem className="fw-semi-bold py-2" onClick={showDetailsModal}>
            Szybka sprzedaż
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </Table.Cell>
  )
}
