import * as React from 'react'
import { BadgeWithIcon } from '@components/badges/BadgeWithIcon'
import { IconWithText } from '@components/icon-with-text'
import { CopyToClipboard } from '@components/copy-to-clipboard'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { ReceptionBooking } from '@models/reception'

interface Props {
  withTitle?: boolean
  withProfilePreview?: boolean
  booking: ReceptionBooking
  clientId: number
}

export const ReservationGuestData = ({ withTitle, withProfilePreview, booking, clientId }: Props): JSX.Element => {
  const hasActiveSubscription = true

  const navigate = useNavigate()

  const showGuestProfile = () => navigate(NavigationPath.CrmClientsWitParams.replace(':client_id', String(clientId)))

  return (
    <div className="mt-2">
      {withTitle && <span>Imię i nazwisko:</span>}
      <div className="d-flex align-items-center">
        <strong className="d-flex align-items-center font-20">{booking.name}</strong>
        {hasActiveSubscription && (
          <BadgeWithIcon
            icon="mdi-check mdi"
            title="subskrybent"
            variant="success"
            className="px-1 h-fit-content ml-3"
          />
        )}
      </div>

      {withProfilePreview && (
        <IconWithText icon="uil-eye lh-0" text="Pokaż profil Gościa" textClass="font-11" onClick={showGuestProfile} />
      )}

      <IconWithText
        icon="uil-envelope-alt text-secondary font-16 lh-0"
        text={
          <>
            {booking.email}
            <CopyToClipboard className="ml-1" value={booking.email} />
          </>
        }
        textClass="font-weight-bold"
        flexClassName="d-block"
        wrapperClassNames="mt-3 mb-1"
      />

      <IconWithText
        icon="uil-comment-alt-message text-secondary font-16 lh-0"
        text={booking.phone ? booking.phone : 'brak numeru telefonu'}
        textClass="font-weight-bold"
      />
    </div>
  )
}
