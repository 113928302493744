import * as React from 'react'
import { useCopy } from '@components/hooks/use-copy'
import { Tooltip } from 'reactstrap'
import classNames from 'classnames'

interface Props {
  value: string
  children: React.ReactNode
  tooltipId: string
  className?: string
  icon?: string
}

export const CopyToClipboardTooltip: React.FC<Props> = ({ children, value, className, icon, ...params }) => {
  const { handleCopy, isCopied } = useCopy(value)

  return (
    <>
      <button
        className={classNames('border-0 shadow-none bg-transparent text-default', className)}
        onClick={handleCopy}
        id={params.tooltipId}
      >
        {children}
        {icon && <i className={icon} />}
      </button>
      <Tooltip placement="bottom" isOpen={isCopied} target={params.tooltipId} className="tooltip-no-arrow">
        <i className="mdi mdi-check-circle mr-1" />
        <span className="font-11">Skopiowano</span>
      </Tooltip>
    </>
  )
}
