import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { FormInput, FormPlain } from '@hyper/forms'
import { CustomInput, Row } from 'reactstrap'
import { extractInnerRef } from '@helpers/forms'
import { FormInputMasked } from '@hyper/forms/form-input-masked'
import { accountNumberMask } from '@models/crm'

export const ReservationDepositReturnModalForm = (): JSX.Element => {
  const { register } = useFormContext()

  return (
    <Row>
      <FormInputMasked
        placeholder="__ ____ ____ ____ ____ ____ ____"
        colSize={12}
        mask={accountNumberMask}
        name="deposit_return_account_number"
        label="Numer konta"
      />
      <FormInput label="Właściciel konta" name="deposit_return_name" />
      <FormInput label="Ulica" name="deposit_return_address" />
      <FormInput label="Kod pocztowy" name="deposit_return_postcode" />
      <FormInput label="Miasto" name="deposit_return_city" />
      <FormPlain name="deposit_transfer_required">
        <CustomInput
          type="checkbox"
          id="deposit_transfer_required"
          label={<span className="text-semi-strong cursor-pointer">Zwrot kaucji wymagany?</span>}
          value={true}
          {...extractInnerRef(register('deposit_transfer_required'))}
        />
      </FormPlain>
    </Row>
  )
}
