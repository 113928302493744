import * as React from 'react'

export interface ScrollableModalTab<T> {
  ref: React.RefObject<HTMLElement>
  name: T
}

interface UseModalWithScrollableTabsProps<T> {
  modalId: string
  setTab: (tab: T) => void
  tabs: ScrollableModalTab<T>[]
}

export function useModalWithScrollableTabs<T>({ modalId, setTab, tabs }: UseModalWithScrollableTabsProps<T>) {
  const scrollableElement = document.getElementById(modalId)?.parentElement

  const handleEvent = (listenerOption: 'addEventListener' | 'removeEventListener') => {
    if (scrollableElement) {
      scrollableElement[listenerOption]('scroll', handleScroll)
    }
  }

  const handleScroll = e => {
    const scrollableWrapper = e.target

    tabs.some((tab: ScrollableModalTab<T>) => {
      if (isScrolledIntoView(tab.ref.current, scrollableWrapper)) {
        setTab(tab.name)
        return true
      }
    })
  }

  React.useEffect(() => {
    handleEvent('addEventListener')

    return () => {
      handleEvent('removeEventListener')
    }
  }, [scrollableElement])

  const handleTabClick = (clickedTab: T) => () => {
    const element = tabs.find((tab: ScrollableModalTab<T>) => tab.name === clickedTab)
    if (!element) return
    element.ref.current?.scrollIntoView({ behavior: 'smooth' })
    setTab(element.name)
  }

  return {
    handleTabClick,
  }
}

function isScrolledIntoView(destinationElement: HTMLElement | null, scrollableWrapper: HTMLElement) {
  if (!destinationElement) return false
  const { top, bottom } = destinationElement.getBoundingClientRect()
  return top >= 0 && top < 200 && bottom <= scrollableWrapper.clientHeight
}
