import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'

export const UpSellBookingsDefaultView: React.FC = () => {
  const navigate = useNavigate()

  React.useEffect(() => {
    navigate(NavigationPath.UpSellBookingsWithParams.replaceParam(':type', 'planned'))
  }, [])

  return null
}
