import * as React from 'react'
import { ColAuto } from '@hyper/col-auto'
import { FormPlain } from '@hyper/forms'
import { CustomInput, Input, Label } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { ReceptionCompleteCheckinFiltersParams } from '@modules/reception/complete-checkin/reception-complete-checkin'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { SubmitFilter } from '@modules/reception/common/filters/submit-filter'
import { SortByFilter } from '@modules/reception/common/filters/sort-by-filter'
import { extractInnerRef } from '@helpers/forms'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { Form } from '@hyper/forms/form'
import { createSelectOption } from '@helpers/utils'

interface ReceptionCompleteCheckinFiltersProps {
  defaultFilters: ReceptionCompleteCheckinFiltersParams
  filters: ReceptionCompleteCheckinFiltersParams
  setFilters: (filters: ReceptionCompleteCheckinFiltersParams) => void
  totalVip: number
}

export const ReceptionCompleteCheckinFilters: React.FC<ReceptionCompleteCheckinFiltersProps> = ({
  filters,
  setFilters,
  defaultFilters,
  totalVip,
}) => {
  const methods = useForm<ReceptionCompleteCheckinFiltersParams>({
    defaultValues: filters,
  })

  const onSubmit = (payload: ReceptionCompleteCheckinFiltersParams) => {
    setFilters(payload)
  }

  const handleReset = () => {
    methods.reset()
    setFilters(defaultFilters)
  }

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  return (
    <Form methods={methods} onSubmit={onSubmit} className="row form form-style-filter mb-1">
      <SortByFilter options={sortByOptions} />
      <ColAuto>
        <FormPlain colClassName="p-0" name="sort_by" errors={{}}>
          <Label>Wyżywienie:</Label>
          <Input type="select" {...extractInnerRef(methods.register('feeding'))}>
            <option value="all">Wszystkie</option>
            <option value="breakfast">Śniadanie</option>
            <option value="dinner">Obiadokolacje</option>
            <option value="breakfast_with_dinner">Śniadanie + Obiadokolacje</option>
            <option value="with_feeding">Wykupione</option>
            <option value="without_feeding">Niewykupione</option>
          </Input>
        </FormPlain>
      </ColAuto>
      <ColAuto>
        <FormPlain colClassName="p-0" name="only_vip" errors={{}}>
          <label className="d-block mt-1">&nbsp;</label>
          <CustomInput
            id="only_vip"
            className="mt-2 text-default"
            type="checkbox"
            label={`Tylko VIP (${totalVip})`}
            {...extractInnerRef(methods.register('only_vip'))}
          />
        </FormPlain>
      </ColAuto>
      <SubmitFilter handleReset={handleReset} />
      <SearchFilter />
    </Form>
  )
}

const sortByOptions = [
  createSelectOption('Data wyjazdu', 'date_to'),
  createSelectOption('Imię i nazwisko', 'name'),
  createSelectOption('Długość pobytu', 'days_count'),
  createSelectOption('Numer lokalu', 'apartment.name'),
]
