import * as React from 'react'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap'
import { useApiRequest } from '@components/hooks/use-api-request'
import { IconWithText } from '@components/icon-with-text'
import { useFormContext, useWatch } from 'react-hook-form'
import { AgreementDetails } from '@modules/hr/agreement/models'
import { downloadBlobFile } from '@store/actions/generic-actions'

interface Props {
  agreementDetails: AgreementDetails
}

export const DocumentSelectionOptions = ({ agreementDetails }: Props): JSX.Element => {
  const { control, reset } = useFormContext()

  const selectedRows = useWatch({ control, name: 'documents' })

  const selectedRowsCount = Object.values(selectedRows).filter(Boolean).length

  const { action: handlePrint, isLoading } = useApiRequest(async () => {
    const documentsSelectedToDownload = Object.entries(selectedRows).reduce(
      (documents, [key, isSelected]) => (isSelected ? [...documents, key] : documents),
      [],
    )

    await downloadBlobFile(agreementDetails.urls.download_selected, { types: documentsSelectedToDownload })

    reset()
  })

  return (
    <UncontrolledButtonDropdown disabled={!selectedRowsCount} className="btn-tall">
      <DropdownToggle color="light" caret={!isLoading} disabled={!selectedRowsCount || isLoading}>
        {isLoading ? 'Proszę czekać...' : <strong>zaznaczone ({selectedRowsCount})</strong>}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={handlePrint}>
          <IconWithText icon="uil-download-alt" text="Pobierz wybrane" />
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  )
}
