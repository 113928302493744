import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { AgreementFormInputs } from '@modules/hr/agreement/create/modal'
import { AlertDanger } from '@components/alerts'
import { getById } from '@helpers/utils'
import { useHrAppData } from '@modules/hr/use-hr-app-data'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'

export const AgreementFormAlerts = (): React.ReactNode => {
  const user = useAuthenticatedUser()
  const { job_positions } = useHrAppData()

  const { control } = useFormContext<AgreementFormInputs>()
  const [hourlyRate, selectedJobPosition] = useWatch({ control, name: ['hourly_rate', 'job_position'] })

  const position = getById(job_positions, selectedJobPosition?.value)

  const isSalaryOutOfRange = hourlyRate && selectedJobPosition && position && hourlyRate > position?.earnings_to

  if (!user.hasPerm(UserPermission.HrAgreementCanSetAnySalary)) return null
  if (!isSalaryOutOfRange) return null

  return (
    <div className="mt-3">
      <hr className="mx-n2" />
      <AlertDanger className="mb-0">
        Wprowadzona stawka godzinowa jest poza zakresem dla wybranego stanowiska. Stawka zostanie poddana weryfikacji.
      </AlertDanger>
    </div>
  )
}
