import { TableFilters } from '@components/table/table'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { Label, Row } from 'reactstrap'
import { ColAuto } from '@hyper/col-auto'
import DateRangeInput from '@components/date/date-range-input'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { useSaleAppData } from '@modules/sale/hooks/use-sale-app-data'
import { paymentRegisterUsersSelector } from '@store/slices/cash-slice'
import { PaymentRegisterUser } from '@models/payments'
import * as R from 'ramda'
import { PaymentRegisterFilters } from '@modules/payment-registers/common/filters'
import { Form } from '@hyper/forms/form'
import { createSelectOption } from '@helpers/utils'
import { FormSelect } from '@hyper/forms/form-select'
import { useAppSelector } from '@store/index'

export interface PaymentRegistersFiltersParams extends TableFilters {
  payment_day_report?: number
  created_after: Date | undefined
  created_before: Date | undefined
  source_resort: CustomReactSelectOption | null
  destination_resort: CustomReactSelectOption | null
  type: CustomReactSelectOption | null
  kind: CustomReactSelectOption | null
  user: CustomReactSelectOption | null
  source: CustomReactSelectOption | null
  payment_day_user_report?: number | number[]
  view?: 'manager-view' | 'cashbox' | 'payment-register-view'
}

interface Props {
  setFilters: (filters: PaymentRegistersFiltersParams) => void
  filters: PaymentRegistersFiltersParams
  defaultFilters: PaymentRegistersFiltersParams
}

export const PaymentRegistersFilters = ({ defaultFilters, filters, setFilters }: Props): JSX.Element => {
  const methods = useForm<PaymentRegistersFiltersParams>({
    defaultValues: defaultFilters,
  })

  const user = useAuthenticatedUser()
  const paymentRegisterUsers = useAppSelector(paymentRegisterUsersSelector)

  const paymentRegisterUsersOptions = groupedUsers(paymentRegisterUsers)

  const { resorts } = useSaleAppData()

  const resortOptions = resorts
    .filter(row => user.resort_ids_with_hq.includes(row.id))
    .filter(row => row.name !== 'Holidaypark')
    .map(resort => createSelectOption(resort.name, resort.id))

  const onSubmit = async (payload: PaymentRegistersFiltersParams) => setFilters({ ...filters, ...payload })

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  return (
    <Form methods={methods} onSubmit={onSubmit} className="form form-style-filter mb-2">
      <Row>
        <ColAuto>
          <FormSelect
            options={resortOptions}
            name="destination_resort"
            label="Lokalizacja"
            selectProps={{ isClearable: true }}
            formPlainProps={{ colStyle: { padding: 0, width: 200 } }}
          />
        </ColAuto>
        <PaymentRegisterFilters />

        <ColAuto>
          <FormSelect
            options={paymentRegisterUsersOptions}
            name="user"
            label="Dodane przez"
            selectProps={{ isClearable: true }}
            formPlainProps={{ colStyle: { padding: 0, width: 200 } }}
          />
        </ColAuto>
      </Row>
      <Row>
        <ColAuto>
          <Label>Data transakcji</Label>
          <DateRangeInput startDateName="created_after" endDateName="created_before" />
        </ColAuto>
        <SearchFilter />
      </Row>
    </Form>
  )
}

const groupedUsers = (users: PaymentRegisterUser[]) => {
  const grouped = R.groupBy<PaymentRegisterUser>(R.prop('group'))(
    users.map(row => ({
      value: row.id,
      label: row.value,
      group: row.group || 'Nieprzypisany',
    })),
  )
  const options: any[] = []

  Object.keys(grouped).map(key => {
    options.push({
      label: key,
      options: R.sort<PaymentRegisterUser>(R.ascend(R.prop('label')))(grouped[key]),
    })
  })

  return options
}
