import * as React from 'react'
import { CleaningOrder } from '@modules/housekeeping/models'
import { useHousekeepingWorkerData } from '@modules/housekeeping/cleaning-orders/common/use-housekeeping-worker-data'
import { ACCOMMODATION_TYPES } from '@helpers/consts'

export interface HousekeepingGropedOrder {
  users: number[]
  orders: CleaningOrder[]
}

interface Props {
  groupedOrder: HousekeepingGropedOrder
}

export const HousekeepingOrderGroupedTeamCell = ({ groupedOrder }: Props): JSX.Element => {
  const { workers, company } = useHousekeepingWorkerData(
    groupedOrder.users,
    groupedOrder.orders[0].resort,
    groupedOrder.orders[0].company_id,
  )

  const orderCounts = groupedOrder.orders.reduce(
    (acc, order) => {
      if (ACCOMMODATION_TYPES.HOUSES.includes(order.apartment.accommodation_type)) {
        return { ...acc, houses: acc.houses + 1, total: acc.total + 1 }
      }

      return { ...acc, apartments: acc.apartments + 1, total: acc.total + 1 }
    },
    { houses: 0, apartments: 0, total: 0 },
  )

  return (
    <td className="border-right border-bottom">
      {workers.map(worker => (
        <div key={worker.id} className="font-weight-bold">
          {worker.name}
        </div>
      ))}
      <small className="font-11 d-block">{company?.name}</small>
      <div className="my-1 fw-semi-bold">zlecone: {orderCounts.total}</div>
      <div className="font-11 fw-semi-bold">apartamentów: {orderCounts.apartments}</div>
      <div className="font-11 fw-semi-bold">domków: {orderCounts.houses}</div>
    </td>
  )
}
