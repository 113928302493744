import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { TechnicalOrder } from '@models/technical-orders'
import { SaveButton } from '@hyper/button'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useCreateOrUpdateGuestsTechnicalOrderMutation } from '@api/technical-orders'
import { useHandleRtkQueryError } from '@components/hooks/use-handle-rtk-error'
import { useForm } from 'react-hook-form'
import { Form } from '@hyper/forms/form'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { GuestsTechnicalOrderStatusFormNew } from '@modules/guests-technical-orders/guests-technical-order-status-modal/form-new'
import { GuestsTechnicalOrderStatusForm } from '@modules/guests-technical-orders/guests-technical-order-status-modal/form'

interface Props extends BaseModalProps {
  technicalOrder: TechnicalOrder
}

export interface GuestsTechnicalOrderStatusFormInputs {
  action: 'close' | 'forward_to_reception_technical_order' | 'open' | 'mark_as_close' | 'mark_as_done'
}

export const GuestsTechnicalOrderStatusModal: React.FC<Props> = ({ technicalOrder, toggleIsVisible }) => {
  const methods = useForm<GuestsTechnicalOrderStatusFormInputs>()

  const { addSuccessNotification } = useNotificationHook()

  const [createOrUpdateTechnicalOrder, { error, isError, isLoading }] = useCreateOrUpdateGuestsTechnicalOrderMutation()

  const onSubmit = async (payload: GuestsTechnicalOrderStatusFormInputs) => {
    await createOrUpdateTechnicalOrder({
      data: payload,
      id: technicalOrder.id,
      url: technicalOrder.urls.switch_status,
    }).unwrap()
    addSuccessNotification('Status został zmieniony')
    toggleIsVisible()
  }

  useHandleRtkQueryError(error, isError, methods.setError)
  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Zmień status zgłoszenia {technicalOrder.number}</ModalHeader>
      <ModalBody>
        {technicalOrder.status === 'new' ? <GuestsTechnicalOrderStatusFormNew /> : <GuestsTechnicalOrderStatusForm />}
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Anuluj
        </Button>
        <SaveButton className="btn btn-green" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
