import * as React from 'react'
import { useParams } from 'react-router-dom'
import { useDocumentTitle } from '@helpers/utils'
import { RootState, useAppSelector } from '@store/index'
import { NavigationPath } from '@models/routes'
import { PageTitle } from '@hyper/page-title'
import { ResortTabs } from '@components/resort-tabs'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { FeedingHostFilters } from '@modules/feeding/host/feeding-host-filters'
import { FormProvider, useForm } from 'react-hook-form'
import { FeedingHostApartmentInfo } from '@modules/feeding/host/apartment-info'
import { FeedingHostStatsBox } from '@modules/feeding/host/stats-box'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { startOfToday } from 'date-fns'
import { BaseFeedingOptions } from '@modules/feeding/consts'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useHostFeedingStats } from '@modules/feeding/host/stats-box/use-host-feeding-stats'

interface Params {
  resort_id: string
}

export interface FeedingHosFormInputs {
  date: Date
  type: CustomReactSelectOption
  apartment: CustomReactSelectOption | null
  resort: string
  filter: string
  category: CustomReactSelectOption | null
}

export const FeedingHostWithParamsView: React.FC = () => {
  const { resort_id } = useParams() as unknown as Params
  const resortId = parseInt(resort_id, 10)
  const user = useAuthenticatedUser()

  const methods = useForm<FeedingHosFormInputs>({
    defaultValues: {
      date: startOfToday(),
      type: new Date().getHours() >= 12 ? BaseFeedingOptions[1] : BaseFeedingOptions[0],
      resort: resort_id,
      filter: '',
    },
  })

  useMenuActive(NavigationPath.FeedingHost)

  React.useEffect(() => {
    methods.setValue('apartment', null)
    methods.setValue('category', null)
    methods.setValue('resort', resort_id)
    methods.setValue('filter', '')
  }, [resort_id])

  const { stats, refresh: refreshStats } = useHostFeedingStats(methods.control)

  const resorts = useAppSelector((state: RootState) => state.appState.appData.resorts)

  const resort = React.useMemo(() => resorts.find(r => r.id === resortId), [resorts, resortId])

  const pageTitle = `Panel wyżywienia ${resort?.name || ''}`
  useDocumentTitle(pageTitle)

  return (
    <FormProvider {...methods}>
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitle} />
      <Card>
        <CardBody>
          <ResortTabs
            resortId={resortId}
            url={NavigationPath.FeedingHostWithParams}
            resorts={user.resorts.filter(row => row.has_feeding)}
          />
          <FeedingHostFilters />
          <Row className="mt-2">
            <Col md={7}>
              <FeedingHostApartmentInfo onStatsRefresh={refreshStats} />
            </Col>
            <Col md={5}>
              <FeedingHostStatsBox stats={stats} onRefresh={refreshStats} />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </FormProvider>
  )
}
