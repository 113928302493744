import * as React from 'react'
import {
  FeedingTooltipDetails,
  NewFeedingCalculationDetails,
} from '@modules/reception/checkin/step-feeding/new-feeding/models'
import { createNewFeedingInputName, isMealUneaten } from '@modules/reception/checkin/step-feeding/new-feeding/utils'
import { Tooltip } from 'reactstrap'
import { useToggle } from '@components/hooks/use-toggle'
import classNames from 'classnames'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectGet } from '@store/actions/generic-actions'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { ContentLoader } from '@components/content-loader'
import { asDecimal } from '@helpers/utils'

interface Props {
  calculation: NewFeedingCalculationDetails
  iconClassName?: string
  children: (details: FeedingTooltipDetails | null) => React.ReactNode
}

export const ReceptionBookingNewFeedingPriceTooltipBase = React.memo(
  ({ calculation, iconClassName, children }: Props): JSX.Element => {
    const [isOpen, toggleIsOpen] = useToggle()
    const id = React.useMemo(() => `tooltip-${createNewFeedingInputName(calculation)}`, [calculation])
    const [details, setDetails] = React.useState<FeedingTooltipDetails | null>(null)

    const tooltipRef = React.useRef<HTMLDivElement>(null)

    let showTimer: NodeJS.Timer
    let closeTimer: NodeJS.Timer

    const handleMouseLeave = () => {
      runCloseTooltipTimer()
      clearTimeout(showTimer)
    }

    const handleMouseEnter = () => {
      runShowTooltipTimer()
      clearTimeout(closeTimer)
    }

    const handleMouseEnterOnTooltip = () => {
      clearTimeout(closeTimer)
    }

    const runShowTooltipTimer = () => {
      showTimer = setTimeout(() => {
        if (!isOpen) toggleIsOpen()
      }, 400)
    }

    const runCloseTooltipTimer = () => {
      closeTimer = setTimeout(() => {
        if (isOpen) toggleIsOpen()
      }, 300)
    }

    const isPaid = calculation.confirmed

    const { action: fetchHistory, isLoading } = useApiRequest(async () => {
      if (!calculation.urls.details || !isOpen) return
      setDetails(await commonObjectGet(calculation.urls.details))
    })

    useDidUpdateEffect(() => {
      fetchHistory()
    }, [isOpen])

    const isUneaten = isMealUneaten(calculation)

    const variantKey = React.useMemo(() => {
      if (isUneaten) return 'uneaten'
      if (calculation.used) return 'used'
      if (isPaid && !asDecimal(calculation.amount_paid).eq(calculation.price_brutto)) return 'installment'
      return isPaid ? 'paid' : 'unpaid'
    }, [calculation])

    const tooltipVariant = {
      paid: { iconColor: 'text-success', color: 'bg-success', title: 'Opłacony' },
      unpaid: { iconColor: 'text-danger', color: 'bg-danger', title: 'Niepotwierdzony' },
      installment: { iconColor: 'text-secondary', color: 'bg-secondary', title: 'Dodany' },
      used: { iconColor: 'text-success', color: 'bg-dark-light', title: 'Wykorzystany' },
      uneaten: { iconColor: 'text-danger', color: 'bg-warning text-dark', title: 'Przepadnięty' },
    }[variantKey]

    return (
      <>
        <i
          className={classNames(iconClassName, tooltipVariant.iconColor)}
          id={id}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
        <Tooltip
          target={id}
          isOpen={isOpen}
          placement="right"
          popperClassName="ml-1"
          innerClassName="p-0 light-scrollbar feeding__details-tooltip"
        >
          <div ref={tooltipRef} onMouseEnter={handleMouseEnterOnTooltip} onMouseLeave={runCloseTooltipTimer}>
            <div className={classNames('w-100 px-2 py-1 fw-semi-bold text-left font-11', tooltipVariant.color)}>
              {tooltipVariant.title}
            </div>
            <ContentLoader isLoading={isLoading}>{children(details)}</ContentLoader>
          </div>
        </Tooltip>
      </>
    )
  },
)
