import * as React from 'react'
import { formatPrice } from '@helpers/utils'
import { ReservationRoomBillRowActions } from '@modules/reservations/details/reservation-room-bill/reservation-room-bill-row-actions'
import { ReceptionBookingDetails, WalletTransaction } from '@models/reception'
import { ReceptionBookingGuest } from '@models/booking'

interface Props {
  transaction: WalletTransaction
  booking: ReceptionBookingDetails
}

export const ReservationRoomBillRow = ({ transaction, booking }: Props): JSX.Element => {
  transaction.guests

  const group = React.useMemo(
    () =>
      booking.groups.find(
        group =>
          group.id ===
          booking.guests.find((guest: ReceptionBookingGuest) =>
            transaction.guests.some(guestId => guestId === guest.id),
          )?.group_id,
      ),
    [transaction.guests, booking.guests, booking.groups],
  )

  return (
    <tr>
      <td className="align-middle">{group?.name}</td>
      <td className="align-middle pl-3">{formatPrice(transaction.available)}</td>
      <td className="align-middle text-right">{formatPrice(transaction.used)}</td>
      <td className="align-middle text-right">
        <ReservationRoomBillRowActions transaction={transaction} booking={booking} />
      </td>
    </tr>
  )
}
