import * as React from 'react'
import Table from '@components/table/table'
import { formatPrice } from '@helpers/utils'
import { RootState, useAppSelector } from '@store/index'
import { CashRegister } from '@models/sale'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useApiRequest } from '@components/hooks/use-api-request'

interface Props {
  cashRegister: CashRegister
}

export const CashRegisterListTableRow: React.FC<Props> = ({ cashRegister }) => {
  const resorts = useAppSelector((state: RootState) => state.appState.appData.resorts)
  const resortName = resorts.find(({ id }) => id === cashRegister.resort_id)?.name || ''
  const { addSuccessNotification } = useNotificationHook()
  const { action: handlePrint } = useApiRequest(async () => {
    await commonObjectPost(cashRegister.urls.print)
    addSuccessNotification('Drukowanie zostało zlecone')
  })

  return (
    <Table.Row role="details-row">
      <Table.Cell>{resortName}</Table.Cell>
      <Table.Cell>{cashRegister.type_display}</Table.Cell>
      <Table.Cell>{cashRegister.user}</Table.Cell>
      <Table.Cell>{cashRegister.company}</Table.Cell>
      <Table.Cell>{cashRegister.description}</Table.Cell>
      <Table.Cell>{toDefaultDateFormat(cashRegister.created)}</Table.Cell>
      <Table.Cell>{formatPrice(cashRegister.amount)}</Table.Cell>
      <Table.Cell>
        <i className="text-muted font-14 cursor-pointer uil-print" onClick={handlePrint} />
      </Table.Cell>
    </Table.Row>
  )
}
