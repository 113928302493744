import * as React from 'react'
import { GastroCard } from '@models/promotions'
import Table from '@components/table/table'
import { useModal } from '@components/modals/use-modal'
import { formatPrice } from '@helpers/utils'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { StatusBadge } from '@modules/promotions/common/status-badge'

interface Props {
  gastroCard: GastroCard
}

export const PackageWholesaleDetailsGastroCardsRow = ({ gastroCard }: Props): JSX.Element => {
  const [handleDetails] = useModal('GastroCardDetailsDialog', {
    gastroCard,
  })

  return (
    <Table.Row onDoubleClick={handleDetails} className="cursor-pointer" role="details-row">
      <Table.Cell>{gastroCard.number}</Table.Cell>
      <Table.Cell>
        {gastroCard.client?.first_name} {gastroCard.client?.last_name} <br />{' '}
        <span className="font-10">{gastroCard.client?.email}</span>
      </Table.Cell>
      <Table.Cell>
        <StatusBadge element={gastroCard} />
      </Table.Cell>
      <Table.Cell>{formatPrice(gastroCard.declared_sell_price)}</Table.Cell>
      <Table.Cell>{toDefaultDateTimeFormat(gastroCard.created)}</Table.Cell>
    </Table.Row>
  )
}
