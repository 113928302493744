import * as React from 'react'
import { PaymentRegister } from '@models/payments'
import { formatPrice, formatPriceShort } from '@helpers/utils'
import Table from '@components/table/table'
import { toDefaultTimeFormat } from '@helpers/date-helper'
import { IconWithText } from '@components/icon-with-text'
import { PaymentRegisterTableRowUser } from '@modules/payment-registers/common/payment_register_table_row_user'
import { PaymentCashBoxAmount } from '@modules/payment-registers/user-cashbox/payment-cashbox-amount'

interface Props {
  paymentRegister: PaymentRegister
  lp: number
}

export const PaymentRegisterCashboxDayTransactionsTableRow = ({ lp, paymentRegister }: Props): JSX.Element => {
  let url
  if (paymentRegister.kind === 'reception_shop') {
    const receiptId = paymentRegister.description.split('#')[1].trim().replace(')', '')
    url = `/admin/shop/receipt/${receiptId}/change/`
  }

  const description = paymentRegister.description || paymentRegister.kind_display

  return (
    <Table.Row role="details-row">
      <Table.Cell>{lp}.</Table.Cell>

      <Table.Cell>
        <IconWithText icon="uil-clock font-16" text={toDefaultTimeFormat(paymentRegister.created)} />
      </Table.Cell>
      <Table.Cell>{paymentRegister.type_display}</Table.Cell>
      <Table.Cell>{url ? <a href={url}>{description}</a> : description}</Table.Cell>
      <Table.Cell>
        {paymentRegister.booking_reservation_number && (
          <a href={`/admin/dashboard/quick-search/?query=${paymentRegister.booking_reservation_number}`}>
            {paymentRegister.booking_reservation_number}
          </a>
        )}
      </Table.Cell>
      <PaymentRegisterTableRowUser paymentRegister={paymentRegister} />
      <Table.Cell>
        <PaymentCashBoxAmount
          paymentRegister={paymentRegister}
          inTicketsAmountTooltipContent={
            <>
              <span className="text-semi-strong">{formatPrice(paymentRegister.amount)}</span>
              <span className="font-10 d-block">
                wykorzystano: {formatPriceShort(paymentRegister.amount_in_tickets, true)} ticketów
              </span>
            </>
          }
        />
      </Table.Cell>
      <Table.Cell>
        {paymentRegister.status === 'success' ? (
          <IconWithText
            icon="uil-check"
            text={paymentRegister.status_display}
            wrapperClassNames="text-success text-semi-strong"
          />
        ) : (
          <IconWithText
            icon="uil-multiply"
            text={paymentRegister.status_display}
            wrapperClassNames="text-danger text-semi-strong"
          />
        )}
      </Table.Cell>
    </Table.Row>
  )
}
