import * as React from 'react'
import { asDecimal, formatPriceShort, sumDecimalArray } from '@helpers/utils'
import { ButtonWithIcon } from '@components/button-with-icon'
import { useFormContext, useWatch } from 'react-hook-form'
import { ShopPOSWithParamsFormInputs } from '@modules/shop/pos/shop-pos-with-params'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useApiRequest } from '@components/hooks/use-api-request'
import { SaveButton } from '@hyper/button'
import { IconWithText } from '@components/icon-with-text'
import { ShopTicket } from '@modules/shop/pos/models'
import { createShopReceipt, deleteShopReceipt } from '@store/actions/shop-actions'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { PayloadAction } from '@reduxjs/toolkit'
import { Receipt } from '@models/shop'
import { getShopPurchaseItemsPayload } from '@modules/shop/pos/purchase-payments/utils'
import { parsePayload } from '@store/actions/generic-actions'

interface Props {
  restToPay: string
  packageVipDiscount: boolean
  resortId: number
}

export const ShopPurchaseSummaryChangeRow: React.FC<Props> = ({ packageVipDiscount, resortId, restToPay }) => {
  const navigate = useNavigate()
  const receipt = useAppSelector((state: RootState) => state.shopState.receipt)

  const { setValue, control, getValues } = useFormContext<ShopPOSWithParamsFormInputs>()
  const [addedProducts, paymentEnabled] = useWatch({
    control,
    name: ['addedProducts', 'paymentEnabled'],
  })

  const dispatch = useAppDispatch()

  const { action: handleEnablePayment, isLoading: isPaymentLoading } = useApiRequest(async () => {
    const items = getShopPurchaseItemsPayload(getValues('addedProducts'))

    const tickets = sumDecimalArray(
      getValues('addedTickets').map((addedTicket: ShopTicket) => asDecimal(addedTicket.value)),
    ).toNumber()

    const { payload: receipt } = (await dispatch(
      createShopReceipt({
        resort: resortId,
        tickets,
        items: items.map(item => ({ ...item, params: item.params ? parsePayload(item.params) : null })),
      }),
    )) as PayloadAction<Receipt>

    navigate(
      NavigationPath.ShopPOSWithParamsAndToken.replace(':resort_id', String(resortId)).replace(':token', receipt.token),
    )

    setValue('paymentEnabled', true)
  })

  const handleDisablePayment = async () => {
    if (receipt) {
      dispatch(await deleteShopReceipt(receipt))
    }
    navigate(NavigationPath.ShopPOSWithParams.replace(':resort_id', String(resortId)))

    setValue('apartment', null)
    setValue('paymentEnabled', false)
  }

  return (
    <tr className="border bg-white-smoke">
      <td colSpan={packageVipDiscount ? 2 : 3} className="text-dark font-18 font-weight-bold align-middle">
        Pozostało do zapłaty:
      </td>
      {packageVipDiscount && (
        <td className="text-danger font-14 font-weight-bold align-middle">Uwzględnia rabat dla VIP -20%</td>
      )}
      <td className="text-danger font-18 font-weight-bold align-middle">
        {formatPriceShort(receipt ? receipt.required_payment : restToPay)}
      </td>
      <td colSpan={2} className="align-middle text-right">
        {!paymentEnabled ? (
          <SaveButton
            className="btn-success btn"
            isSaving={isPaymentLoading}
            label={<IconWithText icon="uil-check" text="Do kasy" />}
            onClick={handleEnablePayment}
            disabled={!addedProducts?.length || paymentEnabled}
          />
        ) : (
          <ButtonWithIcon
            disabled={receipt?.status === 'completed'}
            icon="uil-cancel"
            text="Edytuj"
            handleClick={handleDisablePayment}
            color="success"
          />
        )}
      </td>
    </tr>
  )
}
