import * as React from 'react'
import { FormPlain } from '@hyper/forms'
import { Label } from 'reactstrap'
import { CustomFileInput } from '@components/custom-file-input'
import { FileUploader, UploaderAcceptableFile } from '@components/file-uploader'
import { useFormContext, useWatch } from 'react-hook-form'
import classnames from 'classnames'

interface Props {
  inputName: any
  label?: string
  labelClassName?: string
  contentClassName?: string
}

export const FileFormElementRow = ({
  inputName,
  label = 'Ulotka pobytów',
  contentClassName,
  labelClassName,
}: Props): JSX.Element => {
  const { control, setValue } = useFormContext()

  const [file, fileUrl] = useWatch({
    control,
    name: [inputName, `${inputName}_url`],
  })

  const handleRemoveFile = () => {
    setValue(`${inputName}_url` as any, null)
    setValue(inputName, undefined)
  }

  const handleUpload = (file: File) => {
    setValue(inputName, file)
  }

  return (
    <FormPlain name={inputName} formGroupClassName="d-flex align-items-center">
      <Label htmlFor={inputName} className={classnames('mb-0 pl-0', labelClassName)}>
        {label}
      </Label>
      <div className={classnames('d-flex align-items-center pl-0', contentClassName)}>
        {fileUrl ? (
          <CustomFileInput.Link url={fileUrl} />
        ) : (
          <div className="custom-file-uploader">
            <FileUploader
              labelClassName="font-10"
              label="Upuść plik tutaj lub kliknij aby wybrać"
              className="w-100"
              isLoading={false}
              upload={handleUpload}
              accept={UploaderAcceptableFile.PDF}
              file={file}
              maxFiles={1}
              withIcon={false}
            />
          </div>
        )}
        {(file || fileUrl) && (
          <i className="uil-trash-alt font-15 ml-2 cursor-pointer d-block" onClick={handleRemoveFile} />
        )}
      </div>
    </FormPlain>
  )
}
