import * as React from 'react'
import { Label, Row } from 'reactstrap'
import { FormInput, FormPlain } from '@hyper/forms'
import DateRangeInput from '@components/date/date-range-input'
import { formatDistanceStrict, setHours } from 'date-fns'
import { pl } from 'date-fns/locale'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useFormContext } from 'react-hook-form'
import { FeedingClientsCreateDialogFormInputs } from '@modules/feeding/clients/create/index'
import { useFeedingAppData } from '@modules/feeding/hooks/use-feeding-app-data'
import { InvoiceForm } from '@components/invoice-form'
import { createSelectOption } from '@helpers/utils'
import { FormSelect } from '@hyper/forms/form-select'
import { Resort } from '@models/booking'
import { FeedingKindsOptions } from '@modules/feeding/consts'

interface Props {
  onlyCard: boolean
}

export const GuestsFeedingClientsCreateDialogForm: React.FC<Props> = ({ onlyCard }) => {
  const { watch } = useFormContext<FeedingClientsCreateDialogFormInputs>()

  const feedingAppData = useFeedingAppData()

  const user = useAuthenticatedUser()
  const [dateFrom, _dateTo, kind] = watch(['date_from', 'date_to', 'kind'])
  const dateTo = setHours(_dateTo, 23)

  const paymentMethods = !onlyCard
    ? feedingAppData.payment_methods
    : feedingAppData.payment_methods.filter(row => row[0] === 'card')

  const paymentOptions = paymentMethods.map(method => createSelectOption(method[1], method[0]))

  const discountOptions = [createSelectOption('Brak', 0), createSelectOption('50%', 50)]
  const startByOptions = feedingAppData.start_by.map(startBy => createSelectOption(startBy[1], startBy[0]))
  const resortOptions = user.resorts
    .filter(resort => resort.has_feeding)
    .map((resort: Resort) => createSelectOption(resort.name, resort.id))

  return (
    <div className="mb-3">
      <Row>
        <FormInput label="Imię i nazwisko" colSize={12} name="name" />

        <FormPlain colSize={6} name="date_from">
          <Label>Data</Label>
          <DateRangeInput
            isClearable={false}
            wrapperClassName="flex-grow-1"
            inputGroupClassName="form-group"
            startDateName="date_from"
            endDateName="date_to"
          />
          <small>
            Liczba dni: {formatDistanceStrict(dateTo, dateFrom, { roundingMethod: 'ceil', unit: 'day', locale: pl })}
          </small>
        </FormPlain>
        <FormSelect options={resortOptions} name="resort" label="Ośrodek" formPlainProps={{ colSize: 6 }} />

        <FormSelect
          options={paymentOptions}
          name="payment_method"
          label="Metoda płatności"
          formPlainProps={{ colSize: 4 }}
        />
        <FormSelect options={discountOptions} name="discount" label="Rabat" formPlainProps={{ colSize: 4 }} />
        <FormSelect options={FeedingKindsOptions} name="kind" label="Rodzaj" formPlainProps={{ colSize: 4 }} />

        {kind?.value === 'breakfast_with_dinner' && (
          <FormSelect
            options={startByOptions}
            label="Pierwszy posiłek"
            name="start_by"
            formPlainProps={{ colSize: 4 }}
          />
        )}
      </Row>
      <InvoiceForm />
    </div>
  )
}
