import * as React from 'react'
import { BookingRefund } from '@modules/accounting/refund/models'
import { DropdownItem } from 'reactstrap'
import TopBarProgress from 'react-topbar-progress-indicator'
import { useAppDispatch } from '@store/index'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApiRequest } from '@components/hooks/use-api-request'
import { updateAccountingBookingRefund } from '@store/slices/accounting-slice'

interface Props {
  selectedRows: BookingRefund[]
  clearSelectedRows: () => void
}

export const RefundBookingsTableActionsMarkAsSent: React.FC<Props> = ({ selectedRows, clearSelectedRows }) => {
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const bookings = selectedRows.filter(row =>
    ['p24_initial', 'transfer_in_package', 'transfer_sent', 'p24_error'].includes(row.deposit_transfer_status),
  )

  const { isLoading, action: handleClick } = useApiRequest(async () => {
    for (const booking of bookings) {
      await dispatch(updateAccountingBookingRefund(await commonObjectPost<BookingRefund>(booking.urls.sent)))
    }
    const numbers = bookings.map(row => row.reservation_number).join(', ')
    addSuccessMessage('Sukces', `Zwroty zostało oznaczony jako wykonany dla rezerwacji: ${numbers}`)
    clearSelectedRows()
  })

  return (
    <>
      {isLoading && TopBarProgress && <TopBarProgress />}

      <DropdownItem disabled={!bookings.length} onClick={handleClick}>
        <i className="uil uil-check-square mr-1 text-bold" />
        Oznacz jako wysłany ({bookings.length})
      </DropdownItem>
    </>
  )
}
