import * as React from 'react'
import { Collapse } from 'reactstrap'
import { useExpandableItems } from '@components/hooks/use-expandable-items'
import { useFormContext } from 'react-hook-form'
import { AgreementFormInputs } from '@modules/hr/agreement/create/modal'
import classNames from 'classnames'

interface Props {
  kind: string
  label: React.ReactNode
  children: React.ReactNode
  fields: (keyof AgreementFormInputs)[]
  borderless?: boolean
}

export const AgreementCreateFormCollapsableStatements = ({
  children,
  kind,
  label,
  fields,
  borderless,
}: Props): JSX.Element => {
  const { formState } = useFormContext<AgreementFormInputs>()

  const { toggleExpanded, isExpanded, setIsExpanded, ExpandArrow } = useExpandableItems()

  React.useEffect(() => {
    const hasError = fields.some(field => formState.errors[field])
    if (!isExpanded(kind) && hasError) {
      setIsExpanded(kind)
    }
  }, [isExpanded(kind), fields])

  return (
    <div className={classNames('w-100', { 'border-bottom': !borderless })}>
      <div
        className="d-flex justify-content-between align-items-center bg-grey px-2 py-1 cursor-pointer"
        onClick={toggleExpanded(kind)}
      >
        <strong>{label}</strong>
        <ExpandArrow isExpanded={isExpanded(kind)} className="ml-5" />
      </div>
      <Collapse isOpen={isExpanded(kind)}>
        <div className="mt-3 bg-white">{children}</div>
      </Collapse>
    </div>
  )
}
