import * as React from 'react'
import { FormInput } from '@hyper/forms'
import { SaveButton } from '@hyper/button'
import { useForm } from 'react-hook-form'
import { Col } from 'reactstrap'
import { useFormRequest } from '@components/hooks/use-api-request'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { IconWithText } from '@components/icon-with-text'
import { Form } from '@hyper/forms/form'

interface Props {
  url: string
  notificationEmails: [string, string][]
  email: string
  onSuccess?: () => void
}

interface FormInputs {
  notification: string | undefined
}

export const ProductEmailNotificationsForm: React.FC<Props> = ({ onSuccess, email, url, notificationEmails }) => {
  const methods = useForm<FormInputs>({ defaultValues: { notification: undefined } })
  const { addSuccessMessage } = useNotificationHook()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    await commonObjectPost(url, payload)
    onSuccess?.()

    addSuccessMessage('Sukces', `Wiadomość została wysłana na adres: ${email}`)
  }, methods.setError)

  return (
    <Form methods={methods} className="form-inline row mt-2" onSubmit={onSubmit}>
      <FormInput colSize={8} name="notification" type="select" inputClassName="w-100">
        <option value={undefined}>Wybierz</option>

        {notificationEmails.map(notification => (
          <option key={notification[0]} value={notification[0]}>
            {notification[1]}
          </option>
        ))}
      </FormInput>
      <Col md={4} className="text-right">
        <SaveButton
          className="btn py-1 btn-primary"
          isSaving={isLoading}
          label={
            <IconWithText
              wrapperClassNames="flex-row-reverse"
              icon="uil-message ml-1 mr-0"
              text="Wyślij"
              textClass="ml-1"
            />
          }
          labelSaving="Wysyłanie.."
        />
      </Col>
    </Form>
  )
}
