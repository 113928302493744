import * as React from 'react'
import { PageTitle } from '@hyper/page-title'
import { useDocumentTitle } from '@helpers/utils'
import { NavigationPath } from '@models/routes'
import { Row } from 'reactstrap'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { BookingStatsBoard } from '@modules/reservations/models'
import { commonObjectGet } from '@store/actions/generic-actions'
import { RootState, useAppSelector } from '@store/index'
import { useHandleAxiosServerErrors } from '@helpers/handle-axios-errors'
import { DownloadButtons } from '@components/downloads-buttons'
import { ReservationStatsBoxReservation } from '@modules/reservations/stats-board/reservation-stats-box-reservation'
import { ReservationStatsBoxSubscription } from '@modules/reservations/stats-board/reservation-stats-box-subscription'
import { ReservationStatsBoxPackage } from '@modules/reservations/stats-board/reservation-stats-box-package'
import { ReservationStatsBox } from '@modules/reservations/stats-board/reservation-stats-box'
import { useMenuActive } from '@components/hooks/use-menu-active'

export const ReservationStatsBoardView: React.FC = () => {
  const [stats, setStats] = React.useState<BookingStatsBoard | undefined>(undefined)
  const handleAxiosServerErrors = useHandleAxiosServerErrors()

  const pageTitle = 'Statystyki'

  const url = useAppSelector((state: RootState) => state.appState.appData.urls.rent.booking_stats_board)

  useMenuActive(NavigationPath.ReservationStatsBoard)
  useDocumentTitle(pageTitle)

  const fetchStats = async () => {
    try {
      setStats(await commonObjectGet<BookingStatsBoard>(url))
    } catch (error) {
      handleAxiosServerErrors(error)
    }
  }
  React.useEffect(() => {
    fetchStats()
  }, [])

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          {
            label: 'Lista rezerwacji',
            path: NavigationPath.ReservationList,
            active: false,
          },
          { label: pageTitle, path: '', active: true },
        ]}
        title={pageTitle}
      />
      <LoaderPlaceholder content={stats}>
        {stats && (
          <Row>
            <ReservationStatsBox title="Rezerwacje ogółem:" boxSize={4}>
              <ReservationStatsBoxReservation stats={stats.totals} />
              <DownloadButtons exportXls="empty" direction="down" className="align-self-end mt-auto" />
            </ReservationStatsBox>
            <ReservationStatsBox title="Abonamenty:" boxSize={4}>
              <ReservationStatsBoxSubscription stats={stats.totals} />
              <DownloadButtons exportXls="empty" direction="down" className="align-self-end mt-auto" />
            </ReservationStatsBox>
            <ReservationStatsBox title="Pakiety:" boxSize={4}>
              <ReservationStatsBoxPackage stats={stats.totals} />
              <DownloadButtons exportXls="empty" direction="down" className="align-self-end mt-auto" />
            </ReservationStatsBox>
          </Row>
        )}
      </LoaderPlaceholder>
    </>
  )
}
