import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { formatDate } from '@helpers/date-helper'
import { differenceInDays, parseISO } from 'date-fns'
import { AccommodationTypeAvailableDateRange } from '@models/reservation-create'
import * as R from 'ramda'
import { ReservationCreateFormInputs } from '@modules/reservations/create/models'
import { selectAccommodationTypesAvailable } from '@store/slices/reception-slice'
import { useAppSelector } from '@store/index'

const StepReservationInfoDateProposal = (): JSX.Element | null => {
  const { watch, setValue } = useFormContext<ReservationCreateFormInputs>()
  const { resort, accommodation_type, date_from } = watch()

  const receptionAccommodationTypesAvailable = useAppSelector(selectAccommodationTypesAvailable)

  const accommodationsAvailability = receptionAccommodationTypesAvailable.find(
    acc => acc.resort_id === resort?.value && acc.accommodation_type_id === accommodation_type?.value,
  )

  if (!accommodationsAvailability || !date_from) {
    return null
  }

  const { available_dates, available_dates_full_match } = accommodationsAvailability
  const dates = [...available_dates, ...available_dates_full_match].filter(
    (dateObj, i, arr) => R.indexOf(dateObj, arr) === i,
  )

  const fixDate = ({ date_from, date_to }: AccommodationTypeAvailableDateRange): void => {
    setValue('date_from', parseISO(date_from))
    setValue('date_to', parseISO(date_to))
  }

  if (!dates.length) return null

  return (
    <div className="reception__booking-check-in__notes is-bottom py-2 font-11">
      <div className="px-3 font-weight-bold mb-1">Podpowiedź wolnych terminów</div>
      {dates.map(date => (
        <div className="cursor-pointer" key={date.date_from} onClick={() => fixDate(date)}>
          <div className="px-3">
            <span className="font-weight-bold text-gold-light mr-2">
              {differenceInDays(parseISO(date.date_from), date_from)}d
            </span>{' '}
            {formatDate(date.date_from, 'dd.MM')} - {formatDate(date.date_to, 'dd.MM')}
            <i className="uil uil-estate text-gold-light ml-1" />
          </div>
          <hr className="my-1" />
        </div>
      ))}
    </div>
  )
}

export default StepReservationInfoDateProposal
