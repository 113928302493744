import * as React from 'react'
import { SubscriptionDetails } from '@modules/subscription/models'
import { SubscriptionDetailsCancelButton } from '@modules/subscription/details/cancel/cancel-button'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { BaseModalProps } from '@components/modals/types'

interface Props extends BaseModalProps {
  subscriptionDetails: SubscriptionDetails
}

export const SubscriptionDetailsCancel = ({ subscriptionDetails, toggleIsVisible }: Props): JSX.Element | null => {
  const user = useAuthenticatedUser()

  return subscriptionDetails.status === 'initial' || user.hasPerm(UserPermission.SubscriptionCanCancel) ? (
    <SubscriptionDetailsCancelButton toggleIsVisible={toggleIsVisible} subscriptionDetails={subscriptionDetails} />
  ) : null
}
