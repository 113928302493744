import * as React from 'react'
import { FormCheckbox } from '@hyper/forms/form-checkbox'
import classNames from 'classnames'
import { BandScanningWaiting } from '@components/band-scanning/guests-list/statuses/band-scanning-waiting'
import { BandScanningInProgress } from '@components/band-scanning/guests-list/statuses/band-scanning-in-progress'
import { BandScanningSuccess } from '@components/band-scanning/guests-list/statuses/band-scanning-success'
import { BandScanningError } from '@components/band-scanning/guests-list/statuses/band-scanning-error'
import { ReceptionBookingGuest } from '@models/booking'
import { ReceptionBand } from '@models/reception'
import { BandScanningResult, BandScanningStatus } from '@components/band-scanning/models'
import { BandScanningGuestRescan } from '@components/band-scanning/guests-list/band-scanning-guest-rescan'

interface Props {
  band: ReceptionBand
  guest: ReceptionBookingGuest | undefined
  status: BandScanningStatus
  isChangeDisabled: boolean
  scanningResult: BandScanningResult | undefined | null
  onRescan: (bandId: number) => void
}

export const BandScanningGuest = React.forwardRef(
  (
    { band, guest, status, isChangeDisabled, scanningResult, onRescan }: Props,
    ref: React.RefObject<HTMLDivElement>,
  ): JSX.Element => {
    const label = <BaseLabel guest={guest} />

    return (
      <div
        ref={ref}
        className={classNames('border px-2 py-1 band__scanning-guest', {
          'is-waiting': status === 'waiting',
          'is-scanning': status === 'scanning',
          'is-error': status === 'error',
          'is-success': status === 'success',
        })}
      >
        {status === 'idle' && (
          <FormCheckbox disabled={isChangeDisabled} name={`bands.band__${band.id}`} label={label} />
        )}
        {status === 'waiting' && <BandScanningWaiting label={label} />}
        {status === 'scanning' && <BandScanningInProgress label={label} />}
        {status === 'success' && <BandScanningSuccess band={band} label={label} />}
        {status === 'error' && <BandScanningError label={label} scanningResult={scanningResult} />}
        {guest && status === 'success' && !isChangeDisabled && (
          <BandScanningGuestRescan guest={guest} onRescan={() => onRescan(band.id)} />
        )}
      </div>
    )
  },
)

const BaseLabel = ({ guest }: { guest: ReceptionBookingGuest | undefined }) => (
  <div className="ml-2">
    <span className="d-block fw-semi-bold band__scanning-guest__name text-nowrap">{guest?.name}</span>
    <span className="font-weight-normal font-11">{guest?.type_display}</span>
  </div>
)
