import * as React from 'react'
import { Logo } from '@components/left-menu/logo'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { MenuRow } from '@components/left-menu/menu-row'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'
import { setDashboardMobileView, setDashboardSidebar } from '@store/slices/app-slice'
import { DashboardMenu } from '@models/dashboard'

interface MenuProps {
  menu: DashboardMenu
}

const Menu: React.FC<MenuProps> = ({ menu }) => <MenuRow menu={menu} />

export const LeftMenu: React.FC = () => {
  let showTimeout
  let hideTimeout
  const dispatch = useAppDispatch()
  const location = useLocation()

  const [isOverlayHidden, setOverlayHidden] = React.useState<boolean>(true)
  const [isOverlayTransparent, setOverlayTransparent] = React.useState<boolean>(true)
  const isSidebar = useAppSelector((state: RootState) => state.appState.isSidebar)
  const isMobileView = useAppSelector((state: RootState) => state.appState.isMobileView)
  const toggleSidebar = React.useCallback(() => dispatch(setDashboardSidebar(!isSidebar)), [isSidebar])
  const toggleMobileView = React.useCallback(() => {
    if (isMobileView) {
      dispatch(setDashboardSidebar(false))
    }
    dispatch(setDashboardMobileView(!isMobileView))
  }, [isMobileView])

  React.useEffect(() => {
    if (isSidebar) {
      dispatch(setDashboardSidebar(false))
    }
  }, [location])

  React.useEffect(() => {
    if (isSidebar) {
      setOverlayHidden(false)
      showTimeout = setTimeout(() => {
        setOverlayTransparent(false)
      }, 100)
    } else {
      setOverlayTransparent(true)
      hideTimeout = setTimeout(() => {
        setOverlayHidden(true)
      }, 800)
    }

    return () => {
      clearTimeout(showTimeout)
      clearTimeout(hideTimeout)
    }
  }, [isSidebar])

  const menus = useAppSelector((state: RootState) => state.appState.appData.menu)

  return (
    <>
      <div
        className={classNames('left-side-menu', { 'is-shown': isSidebar, 'is-mobile-view': isMobileView })}
        role="sidebar"
      >
        <Logo />
        <div className="h-100 left-sidebar__scrollbar" id="left-side-menu-container">
          <ul className="metismenu side-nav" id="menu-bar">
            {menus.map((menu: DashboardMenu) => (
              <Menu key={menu.label} menu={menu} />
            ))}
            <li className="side-nav-item d-none d-md-block">
              <button className="btn-pure side-nav-link" onClick={toggleMobileView}>
                <i className={isMobileView ? 'uil-web-section-alt' : 'uil-window-maximize'} />
                <span>{isMobileView ? 'Przypnij menu' : 'Zwiń menu'}</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div className="left-side-menu__mobile-overlay is-transparent is-hidden" />
      <div
        className={classNames(
          'left-side-menu__mobile-overlay',
          { 'is-transparent': isOverlayTransparent },
          { 'is-hidden': isOverlayHidden },
        )}
        onClick={toggleSidebar}
      />
    </>
  )
}
