import * as React from 'react'
import { Button, CustomInput, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import { BadgeWithIcon } from '@components/badges/BadgeWithIcon'
import { IconWithText } from '@components/icon-with-text'
import { UpsellBookingsDateFiltersStatuses } from '@modules/upselling/upsell-bookings/filters/modal/upsell-bookings-date-filters-modal-statuses-buttons'
import { UpsellBookingsFilter } from '@modules/upselling/upsell-bookings/filters/use-upsell-bookings-filters'
import { FormProvider, useForm, useWatch } from 'react-hook-form'

interface Props extends BaseModalProps {
  initialFilters: UpsellBookingsFilter[]
  onFiltersChange: (updatedFilters: UpsellBookingsFilter[]) => void
}

export const UpsellBookingsDateFiltersModal: React.FC<Props> = ({
  toggleIsVisible,
  initialFilters = [],
  onFiltersChange,
}) => {
  const methods = useForm<{ filters: UpsellBookingsFilter[] }>({ defaultValues: { filters: initialFilters } })
  const formFilters = useWatch({ control: methods.control, name: 'filters' })

  const filters = {
    only_without_improvement: 'Tylko bez ulepszeń',
    only_without_feedings: 'Tylko bez wyżywienia',
    not_interested: 'Niezainteresowany',
    sold: 'Sprzedany',
    contact_required_again: 'Do ponownego kontaktu',
    contact_required: 'Wymaga kontaktu',
  }

  const handleSubmit = () => {
    onFiltersChange(formFilters)
    toggleIsVisible()
  }

  const handleClearFilters = () => {
    methods.setValue('filters', [])
  }

  const toggleActiveFilter = (filter: UpsellBookingsFilter) => () => {
    if (formFilters.includes(filter)) {
      methods.setValue(
        'filters',
        formFilters.filter(el => el !== filter),
      )
      return
    }

    methods.setValue('filters', [...formFilters, filter])
  }

  const isButtonFiltered = (filter: UpsellBookingsFilter) => formFilters.includes(filter)

  React.useEffect(() => {}, [])

  return (
    <FormProvider {...methods}>
      <ModalHeader toggle={toggleIsVisible}>Zaawansowane filtrowanie</ModalHeader>
      <ModalBody className="px-0 pb-0">
        <div className="px-2">
          <div className="d-flex justify-content-between">
            <h5 className="text-secondary my-0 font-weight-semibold">Aktywne filtry:</h5>
            {!!formFilters.length && (
              <IconWithText
                icon="uil-times font-14"
                text="Wyczyść filtry"
                textClass="font-12"
                onClick={handleClearFilters}
              />
            )}
          </div>
          <div className="d-flex flex-wrap">
            {!formFilters.length && <span className="mt-2">Brak wybranych filtrów.</span>}
            {formFilters.map(filterKey => (
              <BadgeWithIcon
                key={filterKey}
                className="d-inline-flex flex-row-reverse p-1 align-items-center text-default mr-2 mt-2 cursor-pointer"
                icon="mdi-close mdi ml-2 font-12"
                title={filters[filterKey]}
                variant="light"
                onClick={toggleActiveFilter(filterKey)}
              />
            ))}
          </div>
        </div>
        <div className="mt-2 p-2 border-top bg-grey-light">
          <div className="mt-2">
            <strong className="text-default mb-1 d-block">Status:</strong>
            <UpsellBookingsDateFiltersStatuses
              isButtonActive={isButtonFiltered}
              toggleActiveFilter={toggleActiveFilter}
            />
          </div>
          <div className="d-flex mt-2">
            <CustomInput
              className="mt-2 mr-4"
              type="checkbox"
              id="only_without_improvement"
              label="Pokaż tylko bez ulepszeń"
              checked={formFilters.includes('only_without_improvement' as UpsellBookingsFilter)}
              onClick={toggleActiveFilter('only_without_improvement' as UpsellBookingsFilter)}
            />
            <CustomInput
              className="mt-2 mr-4"
              type="checkbox"
              id="only_without_feedings"
              label="Pokaż tylko bez wyżywienia"
              checked={formFilters.includes('only_without_feedings' as UpsellBookingsFilter)}
              onClick={toggleActiveFilter('only_without_feedings' as UpsellBookingsFilter)}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="d-block">
        <Row className="justify-content-end">
          <Button onClick={toggleIsVisible} className="btn btn-light mr-2">
            Anuluj
          </Button>
          <Button className="btn btn-green" onClick={handleSubmit}>
            Zastosuj
          </Button>
        </Row>
      </ModalFooter>
    </FormProvider>
  )
}
