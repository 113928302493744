import * as React from 'react'
import { TechnicalOrder } from '@models/technical-orders'
import Table from '@components/table/table'
import { Button } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'
import { useCreateOrUpdateTechnicalOrderMutation } from '@api/technical-orders'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { TechnicalOrderRejectModalProps } from '@modules/technical-orders/technical-order-reject-modal'
import { useTechnicalOrderPause } from '@modules/technical-orders/hooks/use-technical-order-pause'

interface Props {
  technicalOrder: TechnicalOrder
}

export const TechnicalOrdersInProgressTableRowActions: React.FC<Props> = ({ technicalOrder }) => {
  const [handleClose] = useModal('TechnicalOrderCloseModal', {
    technicalOrders: [technicalOrder],
  })
  const { addSuccessNotification } = useNotificationHook()

  const [updateTechnicalOrder] = useCreateOrUpdateTechnicalOrderMutation()

  const handlePause = useTechnicalOrderPause({ technicalOrders: [technicalOrder] })

  const handleStart = React.useCallback(async () => {
    await updateTechnicalOrder({
      data: {},
      url: technicalOrder.urls.start,
    }).unwrap()
    addSuccessNotification('Zadanie zostało wznowione')
  }, [technicalOrder, updateTechnicalOrder])

  const [handleRevoke] = useModal('TechnicalOrderRejectModal', {
    technicalOrder,
    url: technicalOrder.urls.revoke,
    successMessage: 'Usterka została cofnięta',
    title: 'Cofnij usterkę',
  } as TechnicalOrderRejectModalProps)

  return (
    <Table.Cell className="text-right vertical-align-middle text-nowrap">
      <Button color="green" onClick={handleClose}>
        Zamknij
      </Button>
      {!technicalOrder.is_paused ? (
        <Button color="warning" className="ml-1 text-white px-0" onClick={handlePause} style={{ width: 67 }}>
          Pauza
        </Button>
      ) : (
        <Button color="green" className="ml-1 text-white px-0" onClick={handleStart} style={{ width: 67 }}>
          Start
        </Button>
      )}
      <Button color="danger" className="ml-1" onClick={handleRevoke}>
        Cofnij
      </Button>
    </Table.Cell>
  )
}
