import * as React from 'react'
import { asDecimal, formatPriceShort, handleEnterPress } from '@helpers/utils'
import { Input } from 'reactstrap'
import { extractInnerRef } from '@helpers/forms'
import { SaveButton } from '@hyper/button'
import { useFormContext } from 'react-hook-form'
import Decimal from 'decimal.js'

interface FormInputs {
  amount: string
}

interface CashPaymentBoxProps {
  restToPay: string
  isSaving: boolean
  isSubmitDisabled?: boolean
  onSubmit: () => void
  onCancel: () => void
}

export const CashPaymentBox: React.FC<CashPaymentBoxProps> = ({
  restToPay,
  isSaving,
  onSubmit,
  onCancel,
  isSubmitDisabled,
}) => {
  const [calculatedChange, setCalculatedChange] = React.useState('0')

  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<FormInputs>()

  const watchedAmount = watch('amount')

  const isChange = asDecimal(watchedAmount).greaterThan(restToPay)

  React.useEffect(() => {
    if (watchedAmount && asDecimal(watchedAmount).greaterThan(asDecimal(100000))) {
      setValue('amount', restToPay.toString())
    }
    calculateChange(watchedAmount)
  }, [watchedAmount])

  const calculateChange = newAmount => {
    if (!isChange) {
      setCalculatedChange('0')
      return
    }

    const change = Decimal.min(asDecimal(newAmount).minus(restToPay), newAmount).toFixed(2).toString()
    setCalculatedChange(change)
  }

  const handleSubmit = () => {
    calculateChange(watchedAmount)
    onSubmit()
  }

  return (
    <>
      <div className="d-flex justify-content-between mb-2 align-items-center">
        <strong className="text-primary font-14">
          <i className="uil-money-bill mr-2" />
          Do zapłaty gotówką:
        </strong>
        <strong className="text-strong text-danger font-14">{formatPriceShort(restToPay)}</strong>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <span>Gotówka od gościa:</span>
        <Input
          type="number"
          step={0.01}
          disabled={isSaving}
          onKeyDown={handleEnterPress.bind(handleSubmit)}
          autoComplete="off"
          className="w-50 shop-purchase__amount text-right "
          data-testid="amount-input"
          {...extractInnerRef(register('amount'))}
        />
      </div>
      {errors.amount && <div className="invalid-feedback d-block mb-1">{errors.amount.message}</div>}
      <div className="d-flex justify-content-between align-items-center mb-2">
        <strong className="font-14">Reszta dla Gościa:</strong>
        <strong className="text-strong text-success font-18">{formatPriceShort(calculatedChange)}</strong>
      </div>
      <div className="reception__payment-box-buttons">
        <button className="btn btn-light shadow-sm" type="button" onClick={onCancel}>
          <i className="uil-multiply mr-1" /> Anuluj
        </button>
        <SaveButton
          type="button"
          onClick={handleSubmit}
          disabled={isSubmitDisabled}
          isSaving={isSaving}
          className="btn btn-secondary shadow-sm flex-fill"
          label={
            <>
              <i className="uil-check mr-1" />
              Zatwierdź płatność
            </>
          }
          labelSaving="Zatwierdzanie..."
        />
      </div>
    </>
  )
}
