import * as React from 'react'

interface Props {
  label: string
  children: React.ReactNode
}

export const PackageDetailsRow: React.FC<Props> = ({ label, children }) => (
  <tr>
    <td>{label}:</td>
    <td className="text-right align-middle">{children}</td>
  </tr>
)
