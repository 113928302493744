import * as React from 'react'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { getHousekeepingCleaningOrders } from '@store/actions/housekeeping-actions'
import { HousekeepingCleaningOrderType } from '@modules/housekeeping/models'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { HousekeepingCleaningOrdersAcceptedTable } from '@modules/housekeeping/cleaning-orders/accepted/table'
import { searchClearingOrders } from '@modules/housekeeping/cleaning-orders/common/filtering'
import { HousekeepingCleaningOrdersAcceptedFilters } from '@modules/housekeeping/cleaning-orders/accepted/filters'
import { FormProvider, useForm } from 'react-hook-form'
import { HousekeepingToOrderFiltersParams } from '@modules/housekeeping/cleaning-orders/to-order'
import { useApiRequest } from '@components/hooks/use-api-request'
import { cleaningOrdersSelector } from '@store/slices/housekeeping-slice'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useHousekeepingTableFilters } from '@modules/housekeeping/hooks/use-housekeeping-table-filters'

interface HousekeepingCleaningOrdersAcceptedProps {
  resortId: number
}

export interface HousekeepingCleaningOrdersAcceptedFiltersParams {
  kind: CustomReactSelectOption | null
  service_kind: CustomReactSelectOption | null
  search: string
}

export const HousekeepingCleaningOrdersAccepted: React.FC<HousekeepingCleaningOrdersAcceptedProps> = ({ resortId }) => {
  const workers = useAppSelector((state: RootState) => state.houseKeepingState.cleaningWorkers)
  const defaultFilters: HousekeepingCleaningOrdersAcceptedFiltersParams = {
    search: '',
    kind: null,
    service_kind: null,
  }
  const [filters, setFilters] = React.useState<HousekeepingCleaningOrdersAcceptedFiltersParams>(defaultFilters)

  const methods = useForm<HousekeepingToOrderFiltersParams>({
    defaultValues: filters,
  })

  const dispatch = useAppDispatch()
  const { isLoading, action: fetchHousekeepingCleaningOrders } = useApiRequest(
    async () =>
      await dispatch(
        getHousekeepingCleaningOrders([
          {
            resort: resortId,
          },
          HousekeepingCleaningOrderType.ACCEPTED,
        ]),
      ),
  )

  React.useEffect(() => {
    fetchHousekeepingCleaningOrders()
  }, [resortId])

  const cleaningOrders = useAppSelector(cleaningOrdersSelector)
  const { getFiltered } = useHousekeepingTableFilters()

  const filteredCleaningOrders = getFiltered(
    searchClearingOrders(
      cleaningOrders.filter(row => row.status === 'ACCEPTED'),
      filters.search,
      workers,
    ),
    filters,
  )

  return (
    <FormProvider {...methods}>
      <HousekeepingCleaningOrdersAcceptedFilters
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
      />

      <LoaderPlaceholder content={!isLoading}>
        <HousekeepingCleaningOrdersAcceptedTable cleaningOrders={filteredCleaningOrders} />
      </LoaderPlaceholder>
    </FormProvider>
  )
}
