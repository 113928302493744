import * as React from 'react'
import { useHousekeepingAppData } from '@modules/housekeeping/hooks/use-housekeeping-app-data'

export const useHousekeepingCleaningCompanies = (resortId?: number) => {
  const { housekeeping_companies } = useHousekeepingAppData(resortId)

  return React.useMemo(
    () => housekeeping_companies.filter(company => company.type === 'cleaning_company'),
    [housekeeping_companies],
  )
}
