import * as React from 'react'
import { ImprovementsStatsDetails, StatsCellContent } from '@modules/reservations/models'
import { ImprovementsStatsCell } from '@modules/reservations/statistics/improvements-stats/stats-table/improvements-stats-table-cell-content-item'
import { formatDate, toDefaultDateFormat } from '@helpers/date-helper'

interface Props extends StatsCellContent<ImprovementsStatsDetails> {
  onGenerateReport: () => void
  isConsumable: boolean
}

export const ImprovementsStatsTableCellContent = ({
  element,
  statsDetails,
  options,
  onGenerateReport,
  isConsumable,
}: Props): JSX.Element => {
  const elementData = statsDetails.stats[element]

  const data = React.useMemo(
    () => (
      <div className="d-flex align-items-end">
        <div className="d-flex flex-column align-items-start">
          <ImprovementsStatsCell
            tooltipId={`${element}-${statsDetails.day}-in`}
            statsItem={elementData}
            kind="in"
            onClick={onGenerateReport}
            tooltipMessage={
              <TooltipMessage title="Liczba planowanych przyjazdów" day={statsDetails.day} amount={elementData.in} />
            }
          />
          {!isConsumable && (
            <ImprovementsStatsCell
              tooltipId={`${element}-${statsDetails.day}-out`}
              statsItem={elementData}
              kind="out"
              onClick={onGenerateReport}
              tooltipMessage={
                <TooltipMessage title="Liczba planowanych wyjazdów" day={statsDetails.day} amount={elementData.out} />
              }
            />
          )}
        </div>
        {!isConsumable && (
          <ImprovementsStatsCell
            tooltipId={`${element}-${statsDetails.day}-today`}
            statsItem={elementData}
            kind="today"
            onClick={onGenerateReport}
            tooltipMessage={
              <TooltipMessage
                title="Obecnie"
                day={statsDetails.day}
                amount={elementData.today}
                afterAmountText={
                  <p className="mb-1">
                    W tym przyjazdy i wyjazdy w dniu {toDefaultDateFormat(statsDetails.day)} (
                    {formatDate(statsDetails.day, 'EEEE')})
                  </p>
                }
              />
            }
          />
        )}
      </div>
    ),
    [elementData],
  )

  return options?.tableCellOption?.wrapper?.({ element, statsDetails, options }) || data
}

interface TooltipMessageProps {
  title: string
  day: string
  amount: number
  afterAmountText?: React.ReactNode
}

const TooltipMessage = ({ title, day, amount, afterAmountText }: TooltipMessageProps) => (
  <div className="font-10">
    {title} <br />
    {toDefaultDateFormat(day)} ({formatDate(day, 'EEEE')})<strong className="d-block my-1 font-14">{amount}</strong>
    {afterAmountText}
    <span className="text-white">Kliknij aby pobrać listę</span>
  </div>
)
