import * as React from 'react'
import { UpSellBooking } from '@models/upselling'
import Table from '@components/table/table'
import { getUpsellBookingStatusColor } from '@modules/upselling/upsell-bookings/helpers'
import classNames from 'classnames'
import { ReceptionRowDates } from '@modules/reception/common/reception-row-dates'
import { ReceptionToCheckInRowImprovements } from '@modules/reception/to-checkin/row/reception-to-check-in-row-improvements'
import { UpSellBookingsTableRowActions } from '@modules/upselling/upsell-bookings/table/row-actions'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'

interface Props {
  upsellBooking: UpSellBooking
  type: string
}

export const UpSellBookingsTableRow: React.FC<Props> = ({ upsellBooking, type }) => {
  const navigate = useNavigate()

  const handleShowDetails = (event: React.MouseEvent<HTMLElement>) => {
    if (['I', 'BUTTON'].includes((event.target as HTMLElement).tagName)) return

    navigate(NavigationPath.UpSellBookingDetails.replaceParam(':booking_id', upsellBooking.id), { state: { type } })
  }

  return (
    <Table.Row onClick={handleShowDetails}>
      <Table.Cell className="align-middle">
        <span className={classNames('badge', `badge-${getUpsellBookingStatusColor(upsellBooking.status)}`)}>
          {upsellBooking.status_display}
        </span>
      </Table.Cell>
      <Table.Cell className="align-middle">
        <ReceptionRowDates booking={upsellBooking.booking} showYear={true} />
      </Table.Cell>
      <Table.Cell className="align-middle">{upsellBooking.client.name}</Table.Cell>
      <Table.Cell className="align-middle">{upsellBooking.booking.reservation_number}</Table.Cell>
      <Table.Cell className="align-middle">
        <div className="mb-1">{upsellBooking.booking.apartment.name}</div>
        <ReceptionToCheckInRowImprovements booking={upsellBooking.booking} showLabel={false} />
      </Table.Cell>
      <UpSellBookingsTableRowActions upsellBooking={upsellBooking} />
    </Table.Row>
  )
}
