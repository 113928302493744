import * as React from 'react'
import { ExtraCharge, PriceItem } from '@models/reception'
import { formatPrice } from '@helpers/utils'

interface Props {
  extraCharges: PriceItem<ExtraCharge>
}

export const ReservationBillExpandedExtraCharges = ({ extraCharges }: Props): JSX.Element => (
  <table className="table table-sm col-9 ml-auto mb-0 font-12 table-striped">
    <tbody>
      {extraCharges.items.map((extraCharge: ExtraCharge) => (
        <tr key={extraCharge.id}>
          <td>{extraCharge.name}</td>
          <td className="fw-semi-bold text-right">+ {formatPrice(extraCharge.price_brutto)}</td>
          <td width="50" />
        </tr>
      ))}
    </tbody>
  </table>
)
