import * as React from 'react'
import { FormInput, FormInputProps } from '@hyper/forms'
import classNames from 'classnames'

export const DocumentsLanguageSelect: React.FC<Partial<FormInputProps>> = formInputProps => (
  <FormInput
    colClassName={classNames('p-0', { 'pe-none opacity-5': formInputProps.readOnly })}
    type="select"
    label="Drukuj dokumenty w języku:"
    name="language"
    {...formInputProps}
  >
    <option value="pl">polskim</option>
    <option value="en">angielskim</option>
    <option value="de">niemieckim</option>
  </FormInput>
)
