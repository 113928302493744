import * as React from 'react'
import { useForm } from 'react-hook-form'
import { CustomInput, FormGroup, Label, Row } from 'reactstrap'
import { SubmitFilter } from '@modules/reception/common/filters/submit-filter'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { TableFilters } from '@components/table/table'
import { ColAuto } from '@hyper/col-auto'
import DateRangeInput from '@components/date/date-range-input'
import { extractInnerRef } from '@helpers/forms'
import { ClientUser } from '@models/clients'
import { CrmClientsOptions } from '@modules/crm/clients/list-view/options'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useGroupedSources } from '@components/hooks/use-grouped-sources'
import { CrmClientsFiltersCreateClientButton } from '@modules/crm/clients/list-view/filters-create-client-button'
import { startOfToday } from 'date-fns'
import { Form } from '@hyper/forms/form'
import { FormSelect } from '@hyper/forms/form-select'

interface Props {
  filters: CrmClientsFiltersParams
  setFilters: (filters: CrmClientsFiltersParams) => void
  defaultFilters: CrmClientsFiltersParams
  selectedRows: ClientUser[]
  clearSelectedRows: () => void
}

export interface CrmClientsFiltersParams extends TableFilters {
  date_joined_after: Date | undefined
  date_joined_before: Date | undefined
  is_blacklisted: boolean
  source_marketing_id: any
  last_activity: any
}

export const lastActivity: CustomReactSelectOption[] = [
  { value: 'last_12_months', label: 'w ciągu 12 miesięcy' },
  { value: 'last_24_months', label: 'w ciągu 24 miesięcy' },
  { value: 'last_36_months', label: 'w ciągu 36 miesięcy' },
  { value: 'not_in_last_12_months', label: 'brak przez 12 miesięcy' },
  { value: 'not_in_last_24_months', label: 'brak przez 24 miesięcy' },
  { value: 'not_in_last_36_months', label: 'brak przez 36 miesięcy' },
  { value: 'not_in_over_36_months', label: 'brak powyżej 36 miesięcy' },
]
export const CrmClientsFilters: React.FC<Props> = ({
  filters,
  defaultFilters,
  selectedRows,
  clearSelectedRows,
  setFilters,
}) => {
  const methods = useForm<CrmClientsFiltersParams>({
    defaultValues: filters,
  })

  const handleReset = () => {
    methods.reset()
    setFilters(defaultFilters)
  }

  const onSubmit = async (payload: CrmClientsFiltersParams) => setFilters({ ...filters, ...payload })
  const sources = useGroupedSources()

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  return (
    <Form onSubmit={onSubmit} className="form form-style-filter" methods={methods}>
      <Row>
        <ColAuto>
          <Label>Utworzenie konta</Label>
          <DateRangeInput
            minDate={new Date(2016, 3, 1)}
            maxDate={startOfToday()}
            startDateName="date_joined_after"
            endDateName="date_joined_before"
            wrapperClassName="is-range"
          />
        </ColAuto>

        <FormSelect
          options={sources}
          name="source_marketing_id"
          label="Źródło marketingowe"
          selectProps={{ isClearable: true }}
          formPlainProps={{ colSize: 2 }}
        />

        <FormSelect
          options={lastActivity}
          name="last_activity"
          label="Ostatnia aktywność"
          selectProps={{ isClearable: true }}
          formPlainProps={{ colSize: 3 }}
        />

        <SubmitFilter handleReset={handleReset} />
        <CrmClientsFiltersCreateClientButton />
      </Row>
      <Row className="mt-2">
        <ColAuto>
          <CrmClientsOptions selectedRows={selectedRows} clearSelectedRows={clearSelectedRows} />
        </ColAuto>
        <ColAuto>
          <FormGroup>
            <CustomInput
              type="checkbox"
              {...extractInnerRef(methods.register('is_blacklisted'))}
              id="is_blacklisted"
              className="text-default mt-2"
              label="Pokaż tylko Gości dodanych do czarnej listy"
            />
          </FormGroup>
        </ColAuto>
        <SearchFilter hideLabel={true} colClassName="col-md-2" />
      </Row>
    </Form>
  )
}
