import * as React from 'react'
import { useForm } from 'react-hook-form'
import { Button, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useFormRequest } from '@components/hooks/use-api-request'
import { CmsResortDetails, ResortFaq } from '@modules/cms/models'
import { FormInput } from '@hyper/forms'
import { commonObjectPost, commonObjectPut } from '@store/actions/generic-actions'
import { BaseModalProps } from '@components/modals/types'
import { setCmsResort } from '@store/slices/cms-reservation-app-slice'
import { Form } from '@hyper/forms/form'

interface Props extends BaseModalProps {
  faq?: ResortFaq
  resort: CmsResortDetails
}

export interface FormInputs {
  title: string
  subtitle: string
  description: string
}

export const CmsReservationAppFaqModal: React.FC<Props> = ({ toggleIsVisible, faq, resort }) => {
  const methods = useForm<FormInputs>({
    defaultValues: faq,
  })
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()
  const url = useAppSelector((state: RootState) => state.appState.appData.urls.cms.resorts_faqs)

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    const resortDetails = await (!faq
      ? commonObjectPost<CmsResortDetails>(url, { ...payload, resort: resort.id })
      : commonObjectPut<CmsResortDetails>(faq.urls.details, payload))

    addSuccessMessage('Sukces', !faq ? 'Wpis został dodany' : 'Wpis został zmieniony')
    dispatch(setCmsResort(resortDetails))
    toggleIsVisible()
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>
        {!faq ? (
          'Dodaj wpis'
        ) : (
          <>
            Edytuj wpis
            <span className="text-semi-strong">{faq.title}</span>
          </>
        )}
      </ModalHeader>
      <ModalBody className="pb-0">
        <Row>
          <FormInput label="Pytanie" name="title" />
          <FormInput label="Krótka odpowiedź (wstęp)" name="subtitle" />
          <FormInput label="Odpowiedź (pełna)" name="description" type="textarea" />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>

        <SaveButton className="btn btn-green" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
