import * as React from 'react'
import { ClientDetails } from '@models/clients'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useApiRequest } from '@components/hooks/use-api-request'
import { getClientDetails } from '@store/actions/crm-actions'
import { ContentNotesCard } from '@components/content-notes/content-notes-card'

interface Props {
  client: ClientDetails
}

export const ClientDetailsModalNotes: React.FC<Props> = ({ client }) => {
  const dispatch = useAppDispatch()

  const contentType = useAppSelector((state: RootState) => state.appState.appData.content_types.user)

  const { action: handleRefresh } = useApiRequest(async () => await dispatch(getClientDetails(client.urls.details)))

  return (
    <ContentNotesCard
      readOnly={false}
      objectId={client.id}
      contentType={contentType}
      onNotesUpdate={handleRefresh}
      notes={client.notes}
      title={`Notatki o ${client.name}`}
    />
  )
}
