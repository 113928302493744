import * as React from 'react'
import { ButtonWithIcon } from '@components/button-with-icon'
import { useModal } from '@components/modals/use-modal'

interface Props {
  resortId: number
  unitId?: number
}

export const TechnicalOrderCreateButton: React.FC<Props> = ({ resortId, unitId }) => {
  const [handleClick] = useModal('TechnicalOrderModal', { resortId, unitId })
  return (
    <div className="ml-auto mb-1">
      <ButtonWithIcon handleClick={handleClick} icon="uil-plus" text="Zgłoś nową usterkę" color="green" />
    </div>
  )
}
