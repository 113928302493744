import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { Form } from '@hyper/forms/form'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { useForm, useWatch } from 'react-hook-form'
import { SaveButton } from '@hyper/button'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { TechnicalOrderForm } from '@modules/technical-orders/technical-order-modal/form'
import { CustomReactSelectOption, makeDefaultSelectOption } from '@components/custom-react-select'
import { useCreateOrUpdateTechnicalOrderMutation } from '@api/technical-orders'
import { useHandleRtkQueryError } from '@components/hooks/use-handle-rtk-error'
import { TechnicalOrderDetails } from '@models/technical-orders'
import * as R from 'ramda'
import { useResortSelectOptions } from '@components/hooks/use-resort-select-options'
import { useTechnicalOrderTypesSelectOptions } from '@modules/technical-orders/hooks/use-technical-order-types-select-options'
import { useUnitsSelectOptions } from '@modules/technical-orders/hooks/use-units-select-options'
import { useHousekeepingTechnicalCompanies } from '@modules/technical-orders/hooks/use-housekeeping-technical-companies'
import { getDefaultSelectedUsers, getSelectedUsersPayload } from '@modules/technical-orders/helpers'
import { parseISODate } from '@helpers/date-helper'

export interface TechnicalOrderFormInputs {
  name: string
  technicalOrderEnterWhereGuestAbsenceRange: boolean
  description: string
  resort?: CustomReactSelectOption
  unit?: CustomReactSelectOption
  technical_order_type?: CustomReactSelectOption
  priority: number
  users: { [key: string]: boolean }
  is_supervisor_acceptance_required: boolean
  enter_where_guest_absence: boolean
  images: number[]
  company?: CustomReactSelectOption
  assignUsers: boolean
}

interface Props extends BaseModalProps {
  unitId?: number
  resortId?: number
  technicalOrderDetails?: TechnicalOrderDetails
}

export const TechnicalOrderModal: React.FC<Props> = ({ unitId, resortId, toggleIsVisible, technicalOrderDetails }) => {
  const resortOptions = useResortSelectOptions()
  const technicalOrderTypesOptions = useTechnicalOrderTypesSelectOptions()

  resortId = resortId || (resortOptions.length === 1 ? resortOptions[0].value : undefined)

  const { companiesOptions } = useHousekeepingTechnicalCompanies(technicalOrderDetails?.resort_id)

  const methods = useForm<TechnicalOrderFormInputs>({
    mode: 'onBlur',
    defaultValues: {
      is_supervisor_acceptance_required: false,
      enter_where_guest_absence: false,
      technicalOrderEnterWhereGuestAbsenceRange:
        !!technicalOrderDetails?.technical_order_enter_where_guest_absence_range.length,
      ...R.pick<TechnicalOrderFormInputs, keyof TechnicalOrderFormInputs>(
        ['name', 'description', 'enter_where_guest_absence', 'is_supervisor_acceptance_required'],
        { ...technicalOrderDetails },
      ),
      technical_order_enter_where_guest_absence_range:
        technicalOrderDetails?.technical_order_enter_where_guest_absence_range.map(row => ({
          ...row,
          date: parseISODate(row.date),
        })),
      created_by_booking: technicalOrderDetails?.created_by_booking?.reservation_number,
      users: getDefaultSelectedUsers(technicalOrderDetails),
      company: makeDefaultSelectOption(companiesOptions, technicalOrderDetails?.company_id),
      assignUsers: !!technicalOrderDetails?.users.length || false,
      priority: technicalOrderDetails?.priority === 10,
      images: technicalOrderDetails?.images.map(row => row.id) || [],
      resort: resortOptions.find(row => row.value === (resortId || technicalOrderDetails?.resort_id)),
      technical_order_type: technicalOrderTypesOptions.find(
        row => row.value === technicalOrderDetails?.technical_order_type_id,
      ),
    },
  })

  const selectedResort = useWatch({
    control: methods.control,
    name: 'resort',
  })

  const { options: unitsOptions, getSelectedOption } = useUnitsSelectOptions(selectedResort?.value || 0)

  React.useEffect(() => {
    if (technicalOrderDetails?.unit_id) {
      methods.setValue('unit', getSelectedOption(technicalOrderDetails.unit_id))
    } else if (unitId) {
      methods.setValue('unit', getSelectedOption(unitId))
    }
  }, [technicalOrderDetails?.unit_id, unitsOptions, getSelectedOption, unitId])

  const { addSuccessNotification } = useNotificationHook()

  const [createOrUpdateTechnicalOrder, { error, isError, isLoading }] = useCreateOrUpdateTechnicalOrderMutation()

  const onSubmit = async (payload: TechnicalOrderFormInputs) => {
    await createOrUpdateTechnicalOrder({
      data: { ...payload, priority: payload.priority ? 10 : 0, users: getSelectedUsersPayload(payload.users) },
      id: technicalOrderDetails?.id,
    }).unwrap()
    addSuccessNotification(technicalOrderDetails ? 'Zmiany zostały zapisane' : 'Usterka została dodana')
    toggleIsVisible()
  }

  useHandleRtkQueryError(error, isError, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>
        {technicalOrderDetails ? `Edytuj usterkę ${technicalOrderDetails.number}` : 'Dodaj nową usterkę'}
      </ModalHeader>
      <ModalBody>
        <TechnicalOrderForm
          resortReadOnly={!!unitId}
          unitReadOnly={!!unitId}
          showOptions={true}
          showTechnicalOrderTypes={true}
          showAssignUsers={true}
          images={technicalOrderDetails?.images || []}
          unitsOptions={unitsOptions}
          resortOptions={resortOptions}
          initialCompany={technicalOrderDetails?.company_id}
          technicalOrderTypesOptions={technicalOrderTypesOptions}
          showIsSupervisorAcceptanceRequired={true}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton
          className="btn btn-green"
          label={technicalOrderDetails ? 'Zapisz zmiany' : 'Dodaj usterkę'}
          isSaving={isLoading}
        />
      </ModalFooter>
    </Form>
  )
}
