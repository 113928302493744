import * as React from 'react'
import Table from '@components/table/table'
import { useModal } from '@components/modals/use-modal'
import { CmsAssetCategoriesTableRowRemove } from '@modules/cms/assets/categories/table-row-remove'
import { AssetCategory } from '@models/cms-asset'

interface Props {
  category: AssetCategory
}

export const CmsAssetCategoriesTableRow = ({ category }: Props): JSX.Element => {
  const [handleEdit] = useModal('CmsAssetCategoryModal', { category })
  return (
    <Table.Row>
      <Table.Cell>{category.name}</Table.Cell>
      <Table.Cell className="text-right">
        <i className="uil-pen cursor-pointer text-muted font-18 mr-2" onClick={handleEdit} />
        <CmsAssetCategoriesTableRowRemove category={category} />
      </Table.Cell>
    </Table.Row>
  )
}
