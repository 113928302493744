import * as React from 'react'
import { ApartmentBookingFilter } from '@models/apartment'
import classNames from 'classnames'

interface Props {
  isButtonActive: (key: ApartmentBookingFilter) => boolean
  toggleActiveFilter: (key: ApartmentBookingFilter) => () => void
  isCompact: boolean
}

export const TimelineLocalFilterButtons = ({ isButtonActive, toggleActiveFilter, isCompact }: Props): JSX.Element => (
  <div className="d-flex">
    <button
      title="Lokale z ogrodem"
      onClick={toggleActiveFilter('with_garden')}
      className={classNames('btn calendar__button is-local-with-garden', {
        'is-filtered': isButtonActive('with_garden'),
      })}
    >
      <img src={require('@assets/images/grass_icon.svg')} alt="Ogródek" height="10" />
      {!isCompact && <span className="pl-1 text-default">Tylko z ogródkiem</span>}
    </button>
    <button
      title="Lokale bez ogrodu"
      onClick={toggleActiveFilter('without_garden')}
      className={classNames('mx-1 btn calendar__button is-local-without-garden', {
        'is-filtered': isButtonActive('without_garden'),
      })}
    >
      <img src={require('@assets/images/grass-icon-crossed.svg')} alt="Ogródek" height={15} />
      {!isCompact && <span className="pl-1">Tylko bez ogródka</span>}
    </button>
    <button
      title="Lokale specjalne"
      onClick={toggleActiveFilter('special_local')}
      className={classNames('btn calendar__button is-special-local', {
        'is-filtered': isButtonActive('special_local'),
      })}
    >
      <i className="uil-star" />
      {!isCompact && <span className="pl-1">Specjalne</span>}
    </button>
    <button
      title="Lokale dla alergików"
      onClick={toggleActiveFilter('animals')}
      className={classNames('btn mx-1 calendar__button is-local-without-animals', {
        'is-filtered': isButtonActive('animals'),
      })}
    >
      <i className="uil-flower text-white" />
      {!isCompact && <span className="pl-1">Bez zwierząt</span>}
    </button>
  </div>
)
