import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { ClientSelect } from '@components/client-select'
import { ClientUser } from '@models/clients'
import { useModal } from '@components/modals/use-modal'
import { FormInput } from '@hyper/forms'
import {
  EndlessHolidayInvitationCreateFormInputs,
  EndlessHolidayCreateIndividualEmailKey,
} from '@modules/endless-holiday/invites/create/modal'
import { EndlessHolidayCreateFormBaseFields } from '@modules/endless-holiday/common/create-modal-base-fields'

interface Props {
  initialClient: ClientUser | undefined
}

export const EndlessHolidayInvitationCreateFormClientData = ({ initialClient }: Props) => {
  const [client, setClient] = React.useState<ClientUser | undefined>(initialClient)
  const [handleToggleClientDialog] = useModal('ClientDialog', { setClient })

  const { formState, setValue, control } = useFormContext<EndlessHolidayInvitationCreateFormInputs>()

  const individualEmail = useWatch({ control, name: EndlessHolidayCreateIndividualEmailKey })

  React.useEffect(() => {
    setValue('client', client)
  }, [client])

  return (
    <div className="px-1">
      <ClientSelect
        label={<span className="text-secondary mt-0 fw-semi-bold">Wyszukaj klienta</span>}
        setClient={setClient}
        client={client}
        handleToggleClientDialog={handleToggleClientDialog}
        error={formState.errors.client}
        colSize={8}
        formGroupClassName="px-0"
        isDisabled={!!individualEmail}
      />

      <FormInput
        disabled={!!client}
        colSize={8}
        name={EndlessHolidayCreateIndividualEmailKey}
        formGroupClassName="mb-0 "
        placeholder="Wpisz adres e-mail"
        label={<span className="fw-semi-bold">lub wpisz nowy adres e-mail</span>}
      />
      <EndlessHolidayCreateFormBaseFields />
    </div>
  )
}
