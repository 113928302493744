import * as React from 'react'
import { NavigationTabs, NavigationTabsButton } from '@components/navigation-tabs'
import { ScrollableModalTab, useModalWithScrollableTabs } from '@components/hooks/use-modal-with-scrollable-tabs'

type Tab = 'notes' | 'history' | 'information' | 'subscriptions' | 'analysis'

interface Props {
  informationRef: React.RefObject<HTMLElement>
  subscriptionsRef: React.RefObject<HTMLElement>
  notesRef: React.RefObject<HTMLElement>
  analysisRef: React.RefObject<HTMLElement>
}

export const PackageWholesaleDetailsTabs: React.FC<Props> = ({
  subscriptionsRef,
  notesRef,
  informationRef,
  analysisRef,
}) => {
  const [tab, setTab] = React.useState<Tab>('notes')

  const tabs: ScrollableModalTab<Tab>[] = [
    { ref: informationRef, name: 'information' },
    { ref: notesRef, name: 'notes' },
    { ref: notesRef, name: 'history' },
    { ref: subscriptionsRef, name: 'subscriptions' },
    { ref: analysisRef, name: 'analysis' },
  ]

  const { handleTabClick } = useModalWithScrollableTabs<Tab>({ modalId: 'package-wholesale-modal', setTab, tabs })

  const buttons: NavigationTabsButton[] = [
    {
      title: 'Dane podstawowe',
      isActive: tab == 'information',
      onClick: handleTabClick('information'),
    },
    {
      title: 'Pakiety prywatne',
      isActive: tab == 'subscriptions',
      onClick: handleTabClick('subscriptions'),
    },
    {
      title: 'Analiza',
      isActive: tab == 'analysis',
      onClick: handleTabClick('analysis'),
    },
    {
      title: 'Notatki',
      isActive: tab == 'notes',
      onClick: handleTabClick('notes'),
    },
    {
      title: 'Historia',
      isActive: tab == 'history',
      onClick: handleTabClick('history'),
    },
  ]

  return <NavigationTabs buttons={buttons} />
}
