import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { IconWithText } from '@components/icon-with-text'
import { formatDate, parseISODate } from '@helpers/date-helper'
import { UserCashBoxCloseDayCards } from '@modules/payment-registers/user-cashbox/close-day-modal/cards'
import { UserCashBoxCloseDayCash } from '@modules/payment-registers/user-cashbox/close-day-modal/cash'
import { useApiRequest, useFormRequest } from '@components/hooks/use-api-request'
import { PaymentDayUserCloseReport, PaymentDayUserReport } from '@models/payments'
import { commonObjectGet, commonObjectPut } from '@store/actions/generic-actions'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { useForm } from 'react-hook-form'
import { UserCashBoxCloseDaySummary } from '@modules/payment-registers/user-cashbox/close-day-modal/summary'
import { CashCount } from '@modules/payment-registers/cash-count-modal'
import { startOfToday } from 'date-fns'
import { useCashBoxCloseDayConfirmationModal } from '@modules/payment-registers/user-cashbox/close-day-modal/confirmation/use-cashbox-close-day-confirmation-modal'
import { AuthenticatedUser } from '@models/dashboard'
import { Form } from '@hyper/forms/form'

interface Props extends BaseModalProps {
  paymentDayUserReport: PaymentDayUserReport
  allowClose: boolean
  title?: string
}

export interface UserCashboxCloseDayFormInputs {
  cashbox_amount_user: number
  cards_amount_user: number
  cash_values: CashCount
  cards_difference_reason: string
  cash_difference_reason: string
}

export const UserCashboxCloseDayModal: React.FC<Props> = ({
  title,
  allowClose,
  toggleIsVisible,
  paymentDayUserReport,
}) => {
  const [dayCloseReport, setDayCloseReport] = React.useState<PaymentDayUserCloseReport>()

  const methods = useForm<UserCashboxCloseDayFormInputs>({
    defaultValues: {
      cashbox_amount_user: 0,
      cards_amount_user: 0,
    },
  })

  const { showConfirmationModal } = useCashBoxCloseDayConfirmationModal()

  const date = parseISODate(paymentDayUserReport.day_start) || startOfToday()

  const { isLoading: isReportLoading, action: fetchDayReport } = useApiRequest(async () =>
    setDayCloseReport(await commonObjectGet<PaymentDayUserCloseReport>(paymentDayUserReport.urls.close)),
  )

  const { isLoading, action: onSubmit } = useFormRequest(
    async (payload: UserCashboxCloseDayFormInputs) => {
      const authenticatedUser = await commonObjectPut<AuthenticatedUser>(paymentDayUserReport.urls.close, {
        ...payload,
        cashbox_amount_user: payload.cashbox_amount_user || 0,
        cards_amount_user: payload.cards_amount_user || 0,
      })
      showConfirmationModal(paymentDayUserReport, authenticatedUser)
      toggleIsVisible()
    },
    methods.setError,
    { showGlobalError: true },
  )

  React.useEffect(() => {
    fetchDayReport()
  }, [])

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={allowClose ? toggleIsVisible : undefined}>
        {title} Zamknij dzień {formatDate(date, 'dd.MM')}
      </ModalHeader>
      <ModalBody>
        <LoaderPlaceholder content={!isReportLoading}>
          {dayCloseReport && (
            <Row>
              <Col md={12} className="d-flex justify-content-between">
                <UserCashBoxCloseDayCash
                  readOnly={false}
                  paymentDayUserReport={paymentDayUserReport}
                  dayCloseReport={dayCloseReport}
                />
                <UserCashBoxCloseDayCards
                  readOnly={false}
                  paymentDayUserReport={paymentDayUserReport}
                  dayCloseReport={dayCloseReport}
                />
              </Col>
              <UserCashBoxCloseDaySummary dayCloseReport={dayCloseReport} />
            </Row>
          )}
        </LoaderPlaceholder>
      </ModalBody>
      <ModalFooter style={{ display: 'block' }}>
        <Row>
          <Col md={6} className="px-0">
            {allowClose && (
              <button type="button" onClick={toggleIsVisible} className="btn mr-1 btn-light">
                Zamknij okno
              </button>
            )}
          </Col>
          <Col md={6} className="text-right px-0">
            <SaveButton
              isSaving={isLoading}
              className="btn btn-danger"
              label={<IconWithText icon="uil-lock" text="Zamknij dzień" />}
            />
          </Col>
        </Row>
      </ModalFooter>
    </Form>
  )
}
