import * as React from 'react'
import { useForm } from 'react-hook-form'
import { Button, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { SaveButton } from '@hyper/button'
import { FormInput, FormPlain } from '@hyper/forms'
import { PaymentMethod, paymentMethods as paymentMethodsList } from '@models/payments'
import { formatPrice } from '@helpers/utils'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useFormRequest } from '@components/hooks/use-api-request'
import { BaseModalProps } from '@components/modals/types'
import DatePickerInput from '@components/date/date-picker-input'
import { startOfToday, subDays } from 'date-fns'
import { commonObjectPost } from '@store/actions/generic-actions'
import { Form } from '@hyper/forms/form'

interface FormInputs {
  amount: string
  source: string
  description: string
  invoice_date: Date
}

interface Props extends BaseModalProps {
  url: string
  amount: string
  onSuccess?: () => void
  paymentMethods?: PaymentMethod[]
}

export const GenericProductPaymentsAddDialog: React.FC<Props> = ({
  paymentMethods,
  onSuccess,
  amount,
  url,
  toggleIsVisible,
}) => {
  const { addSuccessMessage } = useNotificationHook()

  const methods = useForm<FormInputs>({
    defaultValues: {
      amount,
      invoice_date: startOfToday(),
    },
  })

  const { isLoading, action: onSubmit } = useFormRequest(
    async (payload: FormInputs) => {
      await commonObjectPost(url, payload)
      onSuccess?.()
      addSuccessMessage('Sukces', `Wpłata w kwocie ${formatPrice(payload.amount)} została dodana!`)
      toggleIsVisible()
    },
    methods.setError,
    {
      showGlobalError: true,
    },
  )

  const options = paymentMethods || [PaymentMethod.form_bank, PaymentMethod.polcard]

  const isCash = options.includes(PaymentMethod.cash)
  const sources = paymentMethodsList.filter((source: [PaymentMethod, string]) => options.includes(source[0]))

  const minInvoiceDate = subDays(startOfToday(), 3)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Dodaj wpłatę</ModalHeader>
      <ModalBody>
        <Row>
          <FormInput colSize={5} label="Kwota" name="amount" />
          <FormPlain name="invoice_date" colSize={7}>
            <label>Data wpłaty</label>
            <DatePickerInput maxDate={startOfToday()} minDate={minInvoiceDate} name="invoice_date" />
          </FormPlain>
          <FormInput label="Typ płatności" name="source" type="select">
            {sources.map(source => (
              <option key={source[0]} value={source[0]}>
                {source[1]}
              </option>
            ))}
          </FormInput>
          <FormInput label={isCash ? 'Opis' : 'Numer transakcji bankowej'} name="description" />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton label="Zapisz" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
