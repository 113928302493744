import * as React from 'react'
import { PackageWholesale } from '@modules/package-wholesale/models'
import Table from '@components/table/table'
import classNames from 'classnames'
import { useSellers } from '@components/hooks/use-grouped-sellers'
import { useModal } from '@components/modals/use-modal'
import { bruttoToNetto } from '@helpers/price'
import { formatPrice } from '@helpers/utils'
import { toDefaultDateFormat } from '@helpers/date-helper'

interface Props {
  packageWholesale: PackageWholesale
}

export const PackageWholesaleListTableRow = ({ packageWholesale }: Props): JSX.Element => {
  const statusColor = packageWholesale.status === 'open' ? 'badge-success-lighten' : 'badge-danger-lighten'
  const sellersList = useSellers()

  const sellers = React.useMemo(
    () =>
      sellersList
        .filter(row => packageWholesale.sellers_ids.includes(row.id))
        .map(row => row.name)
        .join(', ') || 'brak',
    [sellersList, packageWholesale.sellers_ids],
  )

  const [handleDetails] = useModal('PackageWholesaleDetailsModal', { packageWholesale })
  return (
    <Table.Row onDoubleClick={handleDetails} className="cursor-pointer" role="details-row">
      <Table.Cell>{packageWholesale.number}</Table.Cell>
      <Table.Cell>{packageWholesale.invoice_company}</Table.Cell>
      <Table.Cell>{packageWholesale.invoice_nip}</Table.Cell>
      <Table.Cell>
        {packageWholesale.kinds_display.map(row => (
          <span className="d-block" key={row}>
            {row}
          </span>
        ))}
      </Table.Cell>
      <Table.Cell>
        <span className={classNames('badge', statusColor)}>{packageWholesale.status_display}</span>
      </Table.Cell>
      <Table.Cell>{sellers}</Table.Cell>
      <Table.Cell>{toDefaultDateFormat(packageWholesale.expire_after)}</Table.Cell>
      <Table.Cell>{formatPrice(bruttoToNetto(packageWholesale.total_price_brutto, 8))}</Table.Cell>
      <Table.Cell>{formatPrice(packageWholesale.gastro_card_total_price_brutto)}</Table.Cell>
      <Table.Cell>
        {packageWholesale.houses_sea} / {packageWholesale.houses_sea_all}
      </Table.Cell>
      <Table.Cell>
        {packageWholesale.apartments_sea} / {packageWholesale.apartments_sea_all}
      </Table.Cell>
      <Table.Cell>
        {packageWholesale.houses_mountains} / {packageWholesale.houses_mountains_all}
      </Table.Cell>
      <Table.Cell>
        {packageWholesale.apartments_mountains} / {packageWholesale.apartments_mountains_all}
      </Table.Cell>
    </Table.Row>
  )
}
