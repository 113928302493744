import * as React from 'react'
import { RootState, useAppSelector } from '@store/index'
import { useForm } from 'react-hook-form'
import Col from 'reactstrap/lib/Col'
import { CustomInput, Label, Row } from 'reactstrap'
import DateRangeInput from '@components/date/date-range-input'
import { bookingStatusList } from '@models/booking'
import { SubmitFilter } from '@modules/reception/common/filters/submit-filter'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { ReceptionListFiltersParams } from '@modules/reservations/list/reservation-list-with-params'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { ReservationListType } from '@modules/reservations/list/models'
import { extractInnerRef } from '@helpers/forms'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { Form } from '@hyper/forms/form'
import { FormSelect } from '@hyper/forms/form-select'

interface FormInputs {
  accommodation_type: CustomReactSelectOption[] | undefined
  date_from?: Date
  date_to?: Date
  is_package_reservation: boolean
  is_paid: boolean
  is_subscription_reservation: boolean
  resort: CustomReactSelectOption[] | undefined
  status: CustomReactSelectOption[] | undefined
  type?: ReservationListType
}

interface ReservationListFiltersProps {
  defaultFilters: ReceptionListFiltersParams
  filters: ReceptionListFiltersParams
  setFilters: (filters: ReceptionListFiltersParams) => void
  statuses: string[]
  type?: ReservationListType
}

export const ReservationListFilters: React.FC<ReservationListFiltersProps> = ({
  filters,
  defaultFilters,
  setFilters,
  statuses,
  type,
}) => {
  const accommodationTypes = useAppSelector((state: RootState) => state.appState.appData.accommodation_types)
  const user = useAuthenticatedUser()

  const methods = useForm<FormInputs>({
    defaultValues: defaultFilters,
  })

  const { register } = methods

  const handleReset = () => {
    methods.reset()
    setFilters(defaultFilters)
  }

  const onSubmit = async (payload: FormInputs) => setFilters({ ...filters, ...payload, page: 1 })

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  const bookingStatuses = React.useMemo(() => bookingStatusList.filter(row => statuses.includes(row[0])), [statuses])

  const resortsOptions: CustomReactSelectOption[] = user.resorts.map(resort => ({
    value: resort.id,
    label: resort.name,
  }))

  const bookingStatusOptions: CustomReactSelectOption[] = bookingStatuses.map(row => ({ value: row[0], label: row[1] }))
  const accommodationTypesOptions: CustomReactSelectOption[] = accommodationTypes.map(row => ({
    value: row.id,
    label: row.name,
  }))

  return (
    <Form methods={methods} onSubmit={onSubmit} className="form form-style-filter mb-2">
      <Row>
        <FormSelect
          options={resortsOptions}
          name="resort"
          label="Ośrodek"
          selectProps={{ isSelectMulti: true, isClearable: true }}
          formPlainProps={{ colSize: 'col-auto', colStyle: { width: 250 } }}
        />

        {type !== ReservationListType.Unclosed && (
          <FormSelect
            options={accommodationTypesOptions}
            name="accommodation_type"
            label="Zakwaterowanie"
            selectProps={{ isSelectMulti: true, isClearable: true }}
            formPlainProps={{ colSize: 'col-auto', colStyle: { width: 200 } }}
          />
        )}

        <Col className="col-auto" style={{ minWidth: 250 }}>
          <div className="d-flex">
            <Label className="mr-1">Zakres dat:</Label>
          </div>

          <DateRangeInput
            isClearable={true}
            wrapperClassName="flex-grow-1"
            inputGroupClassName="form-group"
            startDateName="date_from"
            endDateName="date_to"
          />
        </Col>

        {type !== ReservationListType.Unclosed && bookingStatuses.length > 1 && (
          <FormSelect
            options={bookingStatusOptions}
            name="status"
            label="Status"
            selectProps={{ isSelectMulti: true, isClearable: true }}
            formPlainProps={{ colSize: 'col-auto', colStyle: { width: 250 } }}
          />
        )}
        <SubmitFilter handleReset={handleReset} />
        <SearchFilter />
      </Row>

      {type !== ReservationListType.Unclosed && (
        <Row>
          <Col className="mb-2 text-semi-strong col-auto">
            <CustomInput
              type="checkbox"
              id="is_paid"
              label={<span className="text-semi-strong">Tylko opłacone</span>}
              {...extractInnerRef(register('is_paid'))}
            />
          </Col>

          <Col className="mb-2 text-semi-strong col-auto">
            <CustomInput
              type="checkbox"
              id="is_subscription_reservation"
              {...extractInnerRef(register('is_subscription_reservation'))}
              label={<span className="text-semi-strong">Tylko z Pakietów Prywatnych</span>}
            />
          </Col>
          <Col className="mb-2 text-semi-strong col-auto">
            <CustomInput
              type="checkbox"
              id="is_package_reservation"
              label={<span className="text-semi-strong">Tylko z Pakietów Firmowych</span>}
              {...extractInnerRef(register('is_package_reservation'))}
            />
          </Col>
        </Row>
      )}
    </Form>
  )
}
