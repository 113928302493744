import * as React from 'react'
import { ContentNote } from '@models/dashboard'
import { ReceptionBookingCheckInNoteInfo } from '@modules/reception/common/reception-note-popup/reception-booking-check-in-note-info'

interface ReceptionBookingCheckInSingleNoteProps {
  note: ContentNote
}

export const ReceptionBookingCheckInSingleNote: React.FC<ReceptionBookingCheckInSingleNoteProps> = ({ note }) => (
  <>
    <div className="py-2 px-3">
      <ReceptionBookingCheckInNoteInfo created={note.created} createdBy={note.created_by} />
      <span className="d-block reception__booking-check-in__notes__content mt-2 font-10">{note.content}</span>
    </div>
    <div className="hr hr-white" />
  </>
)
