import * as React from 'react'
import { ReceptionBookingDamage } from '@models/reception'
import { formatPrice } from '@helpers/utils'

interface Props {
  damages: ReceptionBookingDamage[]
}

export const ReservationBillExpandedDamage = ({ damages }: Props): JSX.Element => (
  <table className="table table-sm col-9 ml-auto mb-0 font-12">
    <tbody>
      {damages.map((damage: ReceptionBookingDamage) => (
        <tr key={damage.id}>
          <td>{damage.name}</td>
          <td className="fw-semi-bold text-right">+ {formatPrice(damage.price_brutto)}</td>
          <td width="50" />
        </tr>
      ))}
    </tbody>
  </table>
)
