import { useAppDispatch } from '@store/index'
import { UPSELL_BOOKING_TAG_DETAILS, upSellBookingApi } from '@api/upSellBooking'

export const useUpsellBookingDetailsRefresh = () => {
  const dispatch = useAppDispatch()

  const refresh = () => {
    dispatch(upSellBookingApi.util?.invalidateTags([UPSELL_BOOKING_TAG_DETAILS]))
  }

  return { refresh }
}
