import * as React from 'react'
import { PageTitleWithSize } from '@hyper/page-title-with-size'
import { Card, CardBody } from 'reactstrap'
import { TableFilters } from '@components/table/table'
import { TypedQueryResult } from '@api/base'
import { BenefitProgram } from '@modules/benefit-program/models'
import { BenefitProgramListFilters } from '@modules/benefit-program/list/filters'
import { BenefitProgramTable } from '@modules/benefit-program/list/table'
import { useGetBenefitProgramsQuery } from '@api/benefit-programs'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { CustomReactSelectOption } from '@components/custom-react-select'

export interface BenefitProgramTableFilters extends TableFilters {
  status: CustomReactSelectOption[]
}

const defaultFilters: BenefitProgramTableFilters = {
  search: '',
  ordering: 'status',
  page: 1,
  page_size: 25,
  status: [],
}
const emptyList = []

export const BenefitProgramListView: React.FC = () => {
  const [filters, setFilters] = React.useState<BenefitProgramTableFilters>(defaultFilters)

  const { data: benefitPrograms = emptyList, isLoading } = useGetBenefitProgramsQuery({
    params: filters,
  }) as TypedQueryResult<BenefitProgram[]>

  useMenuActive(NavigationPath.BenefitProgramList)

  return (
    <>
      <PageTitleWithSize title="Pracodawcy" />
      <Card>
        <CardBody>
          <BenefitProgramListFilters defaultFilters={defaultFilters} filters={filters} setFilters={setFilters} />
          <BenefitProgramTable
            benefitPrograms={benefitPrograms}
            isLoading={isLoading}
            filters={filters}
            setFilters={setFilters}
          />
        </CardBody>
      </Card>
    </>
  )
}
