import { Seller } from '@models/crm'
import { ClientDetails, ClientUser } from '@models/clients'

export enum CrmActionTypesKeys {
  SET_SELLERS = 'SET_SELLERS',
  SET_CLIENTS = 'SET_CLIENTS',
  SET_CLIENT_DETAILS = 'SET_CLIENT_DETAILS',
  SET_SELLERS_STATUS = 'SET_SELLERS_STATUS',
}

export interface SetSellersAction {
  type: CrmActionTypesKeys.SET_SELLERS
  payload: Seller[]
}

export interface SetSellersStatusAction {
  type: CrmActionTypesKeys.SET_SELLERS_STATUS
  payload: 'unknown' | 'ready' | 'loading'
}

export interface SetClientsAction {
  type: CrmActionTypesKeys.SET_CLIENTS
  payload: ClientUser[]
}

export interface SetClientDetailsAction {
  type: CrmActionTypesKeys.SET_CLIENT_DETAILS
  payload: ClientDetails | undefined
}

export type CrmActionsTypes = SetSellersAction | SetClientsAction | SetClientDetailsAction | SetSellersStatusAction
