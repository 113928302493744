import * as React from 'react'

import Table from '@components/table/table'
import { Promocode } from '@models/promocode'
import { useAppSelector } from '@store/index'
import { promocodeGroupsSelector } from '@store/slices/promocodes-slice'
import { getById } from '@helpers/utils'
import { PromocodeCodeActiveStatus } from '@modules/promocode/promocode-list/promocode-codes/row/promocode-code-active-status'
import { PromocodeCodeSend } from '@modules/promocode/promocode-list/promocode-codes/row/promocode-code-send'
import { PromocodeCodeCancel } from '@modules/promocode/promocode-list/promocode-codes/row/promocode-code-cancel'
import { IconWithText } from '@components/icon-with-text'
import { useModal } from '@components/modals/use-modal'
import { toDefaultDateFormat } from '@helpers/date-helper'

interface Props {
  code: Promocode
}

export const PromocodeCodesTableRow = ({ code }: Props) => {
  const groups = useAppSelector(promocodeGroupsSelector)
  const [showPromocodeUsageDetails] = useModal('PromocodeGroupCodeRowUsedDialog', { promocode: code })

  const group = getById(groups, code.group_id)

  return (
    <Table.Row>
      <Table.Cell>{code.code}</Table.Cell>
      <Table.Cell>{group?.name ?? 'brak'}</Table.Cell>
      <Table.Cell>
        {code.is_used ? (
          <span className="badge badge-danger-lighten h5 mt-0 mb-0 cursor-pointer" onClick={showPromocodeUsageDetails}>
            <IconWithText icon="uil-ban" text="użyty" />
          </span>
        ) : (
          <PromocodeCodeActiveStatus promocode={code} />
        )}
      </Table.Cell>
      <Table.Cell>{toDefaultDateFormat(code.expire_after)}</Table.Cell>
      <Table.Cell className="text-right">
        <PromocodeCodeSend promocode={code} />
        {!code.is_used && <PromocodeCodeCancel promocode={code} />}
      </Table.Cell>
    </Table.Row>
  )
}
