import * as React from 'react'
import { useDocumentTitle } from '@helpers/utils'
import { Card, CardBody } from 'reactstrap'
import { useParams } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { ResortTabs } from '@components/resort-tabs'
import { PageTitle } from '@hyper/page-title'
import { HousekeepingApplicationsTab } from '@modules/housekeeping/applications/tabs'
import { ApplicationTypes } from '@modules/housekeeping/applications/models'
import { HousekeepingApplicationsAddButton } from '@modules/housekeeping/applications/add-button'
import {
  FiltersHousekeepingApplicationFilters,
  FiltersHousekeepingApplicationFiltersParams,
} from '@modules/housekeeping/applications/filters'
import { startOfToday, subDays } from 'date-fns'
import { HousekeepingApplicationFaultsList } from '@modules/housekeeping/applications/fault'
import { useHousekeepingAppData } from '@modules/housekeeping/hooks/use-housekeeping-app-data'
import { useAppDispatch } from '@store/index'
import { getApplicationsAggregation } from '@store/actions/housekeeping-actions'
import { HousekeepingApplicationIssuesList } from '@modules/housekeeping/applications/issue'
import { HousekeepingApplicationsWebSocketHandler } from '@modules/housekeeping/applications/applications-web-socket-handler'
import { useDebounce } from 'rooks'
import { useMenuActive } from '@components/hooks/use-menu-active'

interface Params {
  resort_id: string
  type: ApplicationTypes
}

const defaultFilters: FiltersHousekeepingApplicationFiltersParams = {
  created_after: subDays(startOfToday(), 30),
  created_before: startOfToday(),
  status: '',
  search: '',
  ordering: '-created',
  page: 1,
  page_size: 10,
}

export const HousekeepingApplicationsWithParamsView: React.FC = () => {
  const pageTitle = 'Zgłoszenia / Usterki'
  useDocumentTitle(pageTitle)

  const [filters, setFilters] = React.useState<FiltersHousekeepingApplicationFiltersParams>(defaultFilters)
  const { resort_id, type: currentType } = useParams() as unknown as Params
  const [type, setType] = React.useState(currentType)

  const resortId = parseInt(resort_id, 10)
  const dispatch = useAppDispatch()
  useMenuActive(NavigationPath.HousekeepingApplications)

  const url = NavigationPath.HousekeepingApplicationsWithParams.replace(':type', currentType)

  const fetchApplicationsAggregation = () => dispatch(getApplicationsAggregation([filters, resortId]))

  React.useEffect(() => {
    if (type !== currentType) {
      setFilters({ ...defaultFilters })
      setType(currentType)
    }
  }, [currentType])

  const fetchApplicationsAggregationDebounced = React.useCallback(useDebounce(fetchApplicationsAggregation, 300), [
    resortId,
    type,
  ])
  React.useEffect(() => {
    fetchApplicationsAggregationDebounced(filters)
  }, [filters, resortId, type])

  useHousekeepingAppData(resortId)

  return (
    <>
      <HousekeepingApplicationsWebSocketHandler filters={filters} resortId={resortId} />
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitle} />
      <Card>
        <CardBody>
          <ResortTabs resortId={resortId} url={url}>
            <HousekeepingApplicationsAddButton resortId={resortId} />
          </ResortTabs>
          <HousekeepingApplicationsTab active={type} resortId={resortId} />
          <FiltersHousekeepingApplicationFilters
            filters={filters}
            defaultFilters={defaultFilters}
            setFilters={setFilters}
          />
          {type === 'faults' && (
            <HousekeepingApplicationFaultsList resortId={resortId} filters={filters} setFilters={setFilters} />
          )}
          {type === 'issues' && (
            <HousekeepingApplicationIssuesList resortId={resortId} filters={filters} setFilters={setFilters} />
          )}
        </CardBody>
      </Card>
    </>
  )
}
