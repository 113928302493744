import * as React from 'react'
import { CircleProgress } from '@components/circle-progress'
import classnames from 'classnames'
import { useCounter, useTimeout } from 'rooks'
import { useToggle } from '@components/hooks/use-toggle'

interface CounterProps {
  className?: string
}

interface Response {
  Counter: (params: CounterProps) => JSX.Element
  stopCounter: () => void
  startCounter: () => void
  clearCounter: () => void
}

interface Props {
  actionSecond: number
  onTimeElapsed: () => void
  runOnInit?: boolean
}

export const useTimeCounter = ({ actionSecond, onTimeElapsed, runOnInit }: Props): Response => {
  const [isCounting, toggleIsCounting] = useToggle()
  const { decrement, value, reset: resetCounter } = useCounter(actionSecond)

  const { start: startTimeout, clear: clearTimeout, stop: stopTimeout } = useTimeout(decrement, 1000)

  React.useEffect(() => {
    if (runOnInit) {
      toggleIsCounting()
      startTimeout()
    }
  }, [])

  React.useEffect(() => {
    if (!isCounting) {
      clearTimeout()
      return
    }

    startTimeout()
    if (value === 0) onTimeElapsed()
    if (value === -1) handleStop()
  }, [value, isCounting])

  const handleStop = () => {
    resetCounter()
    clearTimeout()
  }

  const progressValue = 100 - (value * 100) / actionSecond

  const Counter = ({ className }: CounterProps): JSX.Element => (
    <CircleProgress progress={progressValue} className={className}>
      <div className="time-counter__wrapper" onClick={toggleIsCounting}>
        {value}s
        <i className={classnames('time-counter__action font-12', isCounting ? 'uil-pause' : 'uil-play')} />
      </div>
    </CircleProgress>
  )

  return {
    Counter,
    stopCounter: stopTimeout,
    startCounter: startTimeout,
    clearCounter: handleStop,
  }
}
