import * as React from 'react'
import { Button, ModalFooter, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import ModalBody from 'reactstrap/lib/ModalBody'
import { FormInput } from '@hyper/forms'
import { useForm } from 'react-hook-form'
import { useFormRequest } from '@components/hooks/use-api-request'
import { commonObjectPut } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { CmsAccommodationTypeDetails } from '@modules/cms/models'
import { BaseModalProps } from '@components/modals/types'
import { Form } from '@hyper/forms/form'

interface Props extends BaseModalProps {
  accommodationTypeDetails: CmsAccommodationTypeDetails
  setAccommodationTypeDetails: (accommodationTypesDetails: CmsAccommodationTypeDetails) => void
}

interface FormInputs {
  name: string
  size: string
  description: string
  short_name_language_miejscownik: string
  short_name_language_dopelniacz: string
}

export const CmsReservationAppAccommodationTypeForm: React.FC<Props> = ({
  toggleIsVisible,
  setAccommodationTypeDetails,
  accommodationTypeDetails,
}) => {
  const methods = useForm<FormInputs>({ defaultValues: accommodationTypeDetails })
  const { addSuccessNotification } = useNotificationHook()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    setAccommodationTypeDetails(
      await commonObjectPut<CmsAccommodationTypeDetails>(accommodationTypeDetails.urls.cms_details, {
        ...accommodationTypeDetails,
        ...payload,
      }),
    )
    addSuccessNotification('Zmiany zostały zapisane')
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalBody>
        <Row>
          <FormInput colSize={6} label="Nazwa" name="name" />
          <FormInput colSize={6} label="Rozmiar" name="size" />

          <FormInput colSize={6} label="Krótka nazwa - miejscownik" name="short_name_language_miejscownik" />
          <FormInput colSize={6} label="Krótka nazwa - dopełniacz" name="short_name_language_dopelniacz" />

          <FormInput
            label="Opis (widoczny na liście dostępnych rodzajów zakwaterowań)"
            name="description"
            type="textarea"
          />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
