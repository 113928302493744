import * as React from 'react'
import { useModal } from '@components/modals/use-modal'
import { ButtonWithIcon } from '@components/button-with-icon'

export const RentalProductCategoriesTableActions = (): JSX.Element => {
  const [showProductCategoryModal] = useModal('RentalProductCategoryCreateModal')

  return (
    <div className="ml-2 mb-2">
      <ButtonWithIcon
        icon="uil-plus"
        text="Dodaj kategorię"
        handleClick={showProductCategoryModal}
        color="green-dark"
      />
    </div>
  )
}
