import * as React from 'react'
import { TechnicalOrderAssignFormTechnicalManager } from '@modules/technical-orders/technical-order-assign-modal/form-technical-manager'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { TechnicalOrderAssignFormResort } from '@modules/technical-orders/technical-order-assign-modal/form-resort'

interface Props {
  resortId: number
  initialCompany?: number
}

export const TechnicalOrderAssignForm: React.FC<Props> = ({ resortId, initialCompany }) => {
  const user = useAuthenticatedUser()

  return user.isTechnicalOrderManager ? (
    <TechnicalOrderAssignFormTechnicalManager resortId={resortId} initialCompany={initialCompany} />
  ) : (
    <TechnicalOrderAssignFormResort resortId={resortId} />
  )
}
