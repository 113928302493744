import * as React from 'react'
import { TechnicalOrdersTodoFilters } from '@modules/technical-orders/technical-orders-todo/filters'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { ColAuto } from '@hyper/col-auto'
import { FormSelect } from '@hyper/forms/form-select'
import { TableFilters } from '@components/table/table'
import { technicalOrderStatus } from '@modules/technical-orders/constants'
import { useTechnicalOrderTypesSelectOptions } from '@modules/technical-orders/hooks/use-technical-order-types-select-options'

interface Props {
  filters: TableFilters
  setFilters: React.Dispatch<React.SetStateAction<TableFilters>>
  defaultFilters: TableFilters
}

export const UnitDetailsTechnicalOrdersFilters: React.FC<Props> = ({ filters, setFilters, defaultFilters }) => {
  const technicalOrderTypesOptions = useTechnicalOrderTypesSelectOptions()

  return (
    <TechnicalOrdersTodoFilters
      hideLabel={false}
      filters={filters}
      searchClassName="ml-auto"
      setFilters={setFilters}
      defaultFilters={defaultFilters}
    >
      <ColAuto>
        <FormSelect
          options={technicalOrderStatus}
          name="status"
          label="Status"
          selectProps={{ isClearable: true, isSelectMulti: true }}
          formPlainProps={{ colStyle: { padding: 0, width: 200 } }}
        />
      </ColAuto>
      <ColAuto>
        <FormSelect
          options={technicalOrderTypesOptions}
          name="technical_order_type"
          label="Typ usterki"
          selectProps={{ isClearable: true, isSelectMulti: true }}
          formPlainProps={{ colStyle: { padding: 0, width: 200 } }}
        />
      </ColAuto>
      <DateFromToInput
        colSize={' '}
        className="col-auto"
        label="Data zgłoszenia"
        startDateName="created_after"
        endDateName="created_before"
      />
    </TechnicalOrdersTodoFilters>
  )
}
