import * as React from 'react'
import { FormInput } from '@hyper/forms'
import { DropdownItem, DropdownMenu } from 'reactstrap'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { DropdownMenuProps } from 'reactstrap/es/DropdownMenu'
import classNames from 'classnames'
import { handleEnterPress } from '@helpers/utils'

interface FormInputs {
  search: string
}

interface Props extends DropdownMenuProps {
  children: React.ReactNode
  searchLabel: string
  onSingleItemEnter?: (itemName: string) => void
}

export const SearchableDropdownMenu = ({ searchLabel, children, onSingleItemEnter, ...props }: Props): JSX.Element => {
  const methods = useForm<FormInputs>({
    defaultValues: {
      search: '',
    },
  })

  const search = useWatch({ control: methods.control, name: 'search' })

  const filteredChildren = React.useMemo(
    () =>
      React.Children.toArray(children).filter((el: any) =>
        search && el.props.name ? el.props.name.toLowerCase().includes(search.toLowerCase()) : true,
      ),
    [children, search],
  )

  const handleEnter = () => {
    if (filteredChildren.length === 1) {
      onSingleItemEnter?.((filteredChildren[0] as any).props.name)
    }
  }

  return (
    <FormProvider {...methods}>
      <DropdownMenu {...props} className={classNames('searchable-dropdown-menu', props.className)}>
        <DropdownItem header>
          <FormInput
            inputProps={{ autoFocus: true }}
            autoComplete="off"
            name="search"
            label={searchLabel}
            colClassName="px-0"
            formGroupClassName="mb-1"
            onKeyDown={handleEnterPress.bind(handleEnter)}
          />
        </DropdownItem>
        {filteredChildren}
      </DropdownMenu>
    </FormProvider>
  )
}
