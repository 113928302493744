import * as React from 'react'
import Table, { TableHeaderValue } from '@components/table/table'
import { CashbackVouchersTableRow } from '@modules/products/cashback-vouchers/table-row'
import {
  CashbackVouchersTableFilters,
  CashbackVouchersTableFiltersParams,
} from '@modules/products/cashback-vouchers/filters'
import { useGetCashbackVoucherQuery } from '@api/cashback'
import { TypedQueryResult } from '@api/base'
import { CashbackVoucher } from '@modules/products/cashback-vouchers/models'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { CashbackVoucherStatus } from '@modules/products/cashback-vouchers/models'
import { createSelectOption } from '@helpers/utils'

const headers: TableHeaderValue[] = [
  { title: 'Numer', sortField: 'number' },
  { title: 'Status', sortField: 'status' },
  { title: 'Klient', sortField: 'client__email' },
  { title: 'Numer rezerwacji źródłowej', sortField: 'source_booking__reservation_number' },
  { title: 'Kwota', sortField: 'amount' },
  { title: 'Numer rezerwacji przypisanej', sortField: 'assigned_booking__reservation_number' },
  { title: 'Termin ważności', sortField: 'expire_after' },
]
export const cashbackVoucherStatusOptions: CustomReactSelectOption<string, CashbackVoucherStatus>[] = [
  createSelectOption('Aktywny', 'active_assign'),
  createSelectOption('Aktywny oczekujący', 'active'),
  createSelectOption('Generowany', 'generate'),
  createSelectOption('Nieaktywny', 'cancelled'),
  createSelectOption('Wykorzystany', 'used'),
  createSelectOption('Przeterminowany', 'outdated'),
]
const defaultFilters: CashbackVouchersTableFiltersParams = {
  search: '',
  ordering: 'resort',
  page: 1,
  page_size: 20,
  expire_after_before: null,
  expire_after_after: null,
  status: [cashbackVoucherStatusOptions[0], cashbackVoucherStatusOptions[1], cashbackVoucherStatusOptions[2]],
}

const emptyList = []

export const CashbackVouchersTable: React.FC = () => {
  const [filters, setFilters] = React.useState<CashbackVouchersTableFiltersParams>(defaultFilters)

  const { data: cashbackVouchers = emptyList, isLoading } = useGetCashbackVoucherQuery(filters) as TypedQueryResult<
    CashbackVoucher[]
  >

  return (
    <>
      <CashbackVouchersTableFilters filters={filters} defaultFilters={defaultFilters} setFilters={setFilters} />
      <Table
        className="table-hover w-100"
        headerValues={headers}
        isLoading={isLoading}
        filters={filters}
        setFilters={setFilters}
      >
        {cashbackVouchers.map(cashbackVoucher => (
          <CashbackVouchersTableRow key={cashbackVoucher.id} cashbackVoucher={cashbackVoucher} />
        ))}
      </Table>
    </>
  )
}
