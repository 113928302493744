import * as React from 'react'
import {
  PrintableDocument,
  ReceptionBookingCheckInStepDocumentsPrints,
} from '@modules/reception/checkin/step-documents/step-documents-print'
import { ReceptionBookingDetails } from '@models/reception'
import { ReceptionBookingCheckInStepDocumentsSignature } from '@modules/reception/checkin/step-documents/step-documents-signature'
import { CustomInput } from 'reactstrap'
import { extractInnerRef } from '@helpers/forms'
import { useFormContext, useWatch } from 'react-hook-form'
import { ReceptionBookingCheckInStepDocumentsFormInputs } from '@modules/reception/checkin/step-documents/index'
import { FormPlain } from '@hyper/forms'
import { User } from '@components/hooks/use-authenticated-user'
import { useModal } from '@components/modals/use-modal'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useAppDispatch } from '@store/index'
import { DocumentsLanguageSelect } from '@modules/reception/common/documents-language-select'
import { hasCars } from '@modules/reception/common/tools'
import { UserPermission } from '@models/dashboard'
import { setReceptionBookingDetails } from '@store/slices/reception-slice'

interface Props {
  booking: ReceptionBookingDetails
  onRulesFetch: () => void
  user: User
}

export const ReceptionBookingCheckInStepDocumentsKind = ({ booking, onRulesFetch, user }: Props): JSX.Element => {
  const { control, register, setValue } = useFormContext<ReceptionBookingCheckInStepDocumentsFormInputs>()

  const dispatch = useAppDispatch()

  const onSendToTablet = async (tabletId: number) => {
    await handleResetSignature(tabletId)
    closeTabletSelectionModal()
  }

  const [showTabletSelectionModal, closeTabletSelectionModal] = useModal('TabletSelectionModal', { onSendToTablet })
  const [showSigningInProgressModal] = useModal('ReceptionBookingCheckInStepDocumentsSigningInProgressModal', {
    booking,
    onSigningComplete: onRulesFetch,
  })

  const removeSignature = async () => {
    const receptionBookingDetails = await commonObjectPost<ReceptionBookingDetails>(
      booking.urls.booking_reset_signature,
    )
    dispatch(setReceptionBookingDetails(receptionBookingDetails))
  }

  const { action: handleRemoveSignature, isLoading: isRemoving } = useApiRequest(async () => {
    await removeSignature()
  })

  const { action: handleResetSignature, isLoading: isResetting } = useApiRequest(async (tabletId: number) => {
    await removeSignature()
    showSigningInProgressModal(null, { booking, onSigningComplete: onRulesFetch, tabletId }, true)
  })

  const isSignatureAvailable = !!user.tabletId && user.hasPerm(UserPermission.ReceptionKioskCanUseKiosk)
  const isSignatureSelected = useWatch({ control, name: 'document_kind' }) === 'signature'
  const language = useWatch({ control, name: 'language' })

  React.useEffect(() => {
    if (!isSignatureAvailable) {
      setValue('document_kind', 'print')
    }
  }, [user.tabletId])

  return (
    <div>
      <p className="fw-bold mb-2">Podpis dokumentów:</p>

      <div className="mb-2">
        <DocumentsLanguageSelect label="Dokumenty w języku:" readOnly />
      </div>

      <FormPlain name="document_kind">
        {isSignatureAvailable && (
          <>
            <div className="d-flex align-items-center">
              <CustomInput
                type="radio"
                value="signature"
                id="signature"
                label="Za pomocą tabletu"
                className="font-weight-normal"
                {...extractInnerRef(register('document_kind'))}
              />
              {isSignatureSelected && (
                <i className="uil-cog font-16 ml-2 cursor-pointer" onClick={showTabletSelectionModal} />
              )}
            </div>

            {isSignatureSelected && (
              <ReceptionBookingCheckInStepDocumentsSignature
                booking={booking}
                isSignatureResetting={isResetting}
                isSignatureRemoving={isRemoving}
                onSignatureRemove={handleRemoveSignature}
                onSignatureReset={handleResetSignature}
                onShowSigningInProgressModal={showSigningInProgressModal}
              />
            )}
            <hr className="my-2" />
          </>
        )}
        <CustomInput
          type="radio"
          value="print"
          id="print"
          label="Drukuj dokumenty"
          className="font-weight-normal mb-2"
          disabled={!!booking.booking_signature_url}
          {...extractInnerRef(register('document_kind'))}
        />
      </FormPlain>

      {!isSignatureSelected && (
        <ReceptionBookingCheckInStepDocumentsPrints booking={booking} showLanguageSelect={false} />
      )}

      {isSignatureSelected && hasCars(booking) && (
        <PrintableDocument title="Karta parkingowa" url={`${booking.urls.print_parking}?language=${language}`} />
      )}
    </div>
  )
}
