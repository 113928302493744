import { BaseUrls } from '@models/base'
import { BaseHistory } from '@models/dashboard'

export enum PaymentMethod {
  form_bank = 'form_bank',
  installment = 'installment',
  payu = 'payu',
  cash = 'cash',
  creditcard = 'creditcard',
  polcard = 'polcard',
}

interface PaymentUrls {
  details: string
  admin: string
}

export interface Payment {
  amount: string
  description: string
  id: number
  invoice_date: string
  source: string
  source_display: string
  urls: PaymentUrls
  user: string
}

export const paymentMethods = [
  [PaymentMethod.form_bank, 'Blankiet bankowy'],
  [PaymentMethod.installment, 'Raty'],
  [PaymentMethod.payu, 'PayU'],
  [PaymentMethod.cash, 'Gotówka w kasie'],
  [PaymentMethod.creditcard, 'Karta kredytowa'],
  [PaymentMethod.polcard, 'Polcard'],
]

export interface PaymentRegisterUser {
  id: number
  value: string
  group: string
}

export interface PaymentRegisterBalance {
  after: string
  before: string
  total_payin: string
  total_payout: string
}

interface PaymentRegisterUrls {
  print: string | null
}

export type PaymentRegisterKind =
  | 'reservation_payment'
  | 'deposit'
  | 'deposit_return'
  | 'climatic_tax'
  | 'safebox_payout'
  | 'safebox_payout_end_day'
  | 'safebox_payin'
  | 'reception_shop'
  | 'gastro_coupon'
  | 'feeding_client'
  | 'feeding_worker'
  | 'load_note'
  | 'PP'
  | 'WP'
  | 'WB'
  | 'W2'
  | 'W3'
  | 'P2'
  | 'P3'
  | 'W4'
  | 'W5'
  | 'P4'
  | 'P5'
  | 'receptionist_payout'
  | 'receptionist_payin'
  | 'receptionist_payin_safebox'
  | 'receptionist_payin_casket'
  | 'receptionist_payout_safebox'
  | 'receptionist_payout_casket'
  | 'point_revenue'
  | 'balance_fix'

export interface PaymentRegister {
  amount: string
  amount_in_tickets: string
  booking_id: number
  booking_reservation_number: string
  created: string
  created_by: string
  created_by_id: number
  description: string
  destination_resort_id: number
  id: number
  is_removed: boolean
  kind: PaymentRegisterKind
  kind_display: string
  reception_workstation_id: number
  source: string
  source_display: string
  source_resort_id: number
  status: 'rejected' | 'success'
  status_display: string
  type: 'KP' | 'KW'
  type_display: string
  urls: PaymentRegisterUrls
  user?: string
  user_id: number | null
}

interface PaymentDayReportUrls extends BaseUrls {
  close: string
  print: string
  provider: string
}

export interface PaymentDayReport {
  safe_amount: string
  status: 'not_started' | 'open' | 'close'
  resort_id: number
  date: string
  id: number
  status_display: string
  total_revenue: string
  points_revenue: string
  reception_revenue: string
  reception_revenue_cash: string
  reception_revenue_cards: string
  urls: PaymentDayReportUrls
}

interface PaymentDayPointReportUrls extends BaseUrls {
  print: string
}

export interface PaymentDayPointReport {
  id: number
  point_id: number
  amount: string
  author_id: number
  created: string
  author_display: string
  payment_day_report_id: number
  urls: PaymentDayPointReportUrls
}

interface PaymentDayUserReportUrls extends BaseUrls {
  open: string
  close: string
  operations: string
  print: string | null
}

export interface PaymentDayUserReport {
  id: number
  resort_id: number
  day_start: string | null
  day_end: string | null
  user_id: number
  start_amount: string
  cashbox_amount: string
  cashbox_amount_after_day_end: string
  amount: string
  amount_sale: string
  amount_sale_cards: string
  amount_sale_cash: string
  amount_cards: string
  amount_cash: string
  created: string
  reception_workstation_id: number
  user_display: string
  eservice_pad_id: number
  payment_day_report_id: number
  urls: PaymentDayUserReportUrls
  status: 'close' | 'open' | 'not_started'
  status_display: string
  safe_amount: string
}

interface PaymentDayReportDetailsUrls extends PaymentDayReportUrls {
  points: string
  print: string
}

export interface PaymentDayReportDetails extends PaymentDayReport {
  user_reports: PaymentDayUserReport[]
  point_reports: PaymentDayPointReport[]
  urls: PaymentDayReportDetailsUrls
  history: BaseHistory[]
}

export interface PaymentRegisterPoint {
  is_active: boolean
  id: number
  name: string
  resort_id: number
  kp_id: string
  kw_id: string
}

interface PaymentCashRegisterWorker {
  id: number
  code: string
  name: string
  is_active: boolean
}

export interface PaymentAppData {
  status: 'unknown' | 'loading' | 'ready'
  payment_register_points: PaymentRegisterPoint[]
  payment_cash_register_workers: PaymentCashRegisterWorker[]
  payment_balance_types: string[]
}

export interface PaymentDayUserCloseReport {
  cash_start_amount: string
  cash_kp_count: number
  cash_kp_amount: string
  cash_kp_sale_count: number
  cash_kp_sale_amount: string
  cash_kw_count: number
  cash_kw_amount: string
  cash_total: number

  cards_kp_count: number
  cards_kp_amount: string
  cards_kp_sale_count: number
  cards_kp_sale_amount: string
  cards_kw_count: number
  cards_kw_amount: string
  cards_total: number
}

export interface SafeboxStats {
  total_amount: string
}
