import * as React from 'react'
import { PackageSummarySection, PackageSummarySectionDataRow } from '@modules/package/create/package-summary-section'
import { useFormContext } from 'react-hook-form'
import { PackageVipAddFormInputs } from './package-vip-dialog'
import { ClientDetails, ClientUser } from '@models/clients'
import { Row } from 'reactstrap'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { useReportsAppData } from '@modules/reports/hooks/use-reports-app-data'
import { Source } from '@models/reports'
import { commonObjectGet } from '@store/actions/generic-actions'
import { ProductSaleSummaryInvoice } from '@components/sale/product-sale-summary-invoice'

interface Props {
  client: ClientUser | undefined
}

export const PackageVipDialogSummaryStep = ({ client }: Props): JSX.Element => {
  const [clientDetails, setClientDetails] = React.useState<ClientDetails | null>(null)
  const methods = useFormContext<PackageVipAddFormInputs>()
  const data = methods.getValues()

  const { sources } = useReportsAppData()

  const sourceMarketing = data.source_marketing
    ? sources.find((source: Source) => source.id === data.source_marketing.value)?.name
    : 'Brak'

  const fetchClientDetails = async clientUrl => setClientDetails(await commonObjectGet<ClientDetails>(clientUrl))

  React.useEffect(() => {
    if (client) {
      fetchClientDetails(client.urls.details)
    }
  }, [client])

  return (
    <div className="pb-2">
      <h4 className="mt-0 text-secondary">Podsumowanie sprzedaży</h4>
      <Row>
        <PackageSummarySection title="Dane klienta" className="col-6">
          <PackageSummarySectionDataRow title="Imię i nazwisko:" content={client?.name} />
          <PackageSummarySectionDataRow title="Adres e-mail:" content={client?.email} />
          <PackageSummarySectionDataRow title="Numer telefonu:" content={client?.phone} />
          <PackageSummarySectionDataRow title="Posiadane vouchery:" content={clientDetails?.packages_vip.length ?? 0} />
          <PackageSummarySectionDataRow title="Źródło:" content={sourceMarketing} />
          <PackageSummarySectionDataRow title="Rodzaj:" content={data.type.label} />
          <PackageSummarySectionDataRow title="Data sprzedaży:" content={toDefaultDateFormat(data.sell_date)} />
          {data.auto_cancel_after_date && (
            <PackageSummarySectionDataRow
              title="Auto anulowanie po:"
              content={toDefaultDateFormat(data.auto_cancel_after_date)}
            />
          )}
        </PackageSummarySection>
        {data.invoice_nip && <ProductSaleSummaryInvoice />}
      </Row>
      <div className="dropdown-divider" />
      <Row>
        <PackageSummarySection title="Informacje handlowe" className="col-12">
          <PackageSummarySectionDataRow title="Sprzedawca:" content={data.seller?.label ?? 'Brak'} />
        </PackageSummarySection>
      </Row>
      <div className="dropdown-divider" />
      <Row>
        <PackageSummarySection title="Link do płatności" className="col-12">
          <p className="opacity-75">
            Link do opłacenia sprzedaży zostanie wysłany na adres e-mail Gościa{' '}
            <strong className="text-dark">{client?.email}</strong>
          </p>
        </PackageSummarySection>
      </Row>
    </div>
  )
}
