import * as React from 'react'
import Table, { TableHeaderValue } from '@components/table/table'
import { HrWorkersListTableRow } from '@modules/hr/workers/list/table-row'
import { HrWorker } from '@modules/hr/workers/models'
import { HrWorkerListFilterParams } from '@modules/hr/workers/list/filters'

const headerValues: TableHeaderValue[] = [
  { title: 'Imię i nazwisko', sortField: 'first_name' },
  { title: 'PESEL' },
  { title: 'Resorty' },
  { title: 'Spółka' },
  { title: 'Stanowisko' },
  { title: 'Status' },
  { title: '' },
]

interface Props {
  workers: HrWorker[]
  isLoading: boolean
  filters: HrWorkerListFilterParams
  setFilters: (filters: HrWorkerListFilterParams) => void
}

export const HrWorkersListTable: React.FC<Props> = ({ isLoading, workers, filters, setFilters }) => (
  <Table
    className="table-hover w-100"
    headerValues={headerValues}
    isLoading={isLoading}
    filters={filters}
    setFilters={setFilters}
  >
    {workers.map(worker => (
      <HrWorkersListTableRow key={worker.id} worker={worker} />
    ))}
  </Table>
)
