import * as React from 'react'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { TableFilters } from '@components/table/table'
import { FormProvider, useForm } from 'react-hook-form'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { Col, Row } from 'reactstrap'
import classNames from 'classnames'

interface Props {
  filters: TableFilters
  defaultFilters: TableFilters
  setFilters: React.Dispatch<React.SetStateAction<TableFilters>>
  children?: React.ReactNode
  searchClassName?: string
  hideLabel?: boolean
}

export const TechnicalOrdersTodoFilters: React.FC<Props> = ({
  searchClassName,
  children,
  filters,
  defaultFilters,
  hideLabel = true,
  setFilters,
}) => {
  const methods = useForm({
    defaultValues: defaultFilters,
  })

  const onSubmit = async payload => setFilters({ ...filters, ...payload })

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  return (
    <Row>
      <FormProvider {...methods}>
        {children}
        <Col className={classNames('text-right col-auto', searchClassName)}>
          <SearchFilter hideLabel={hideLabel} />
        </Col>
      </FormProvider>
    </Row>
  )
}
