import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { BaseTableFilters } from '@components/table/use-handle-table-filters-change'
import { FormSelect } from '@hyper/forms/form-select'
import { useHrAppData } from '@modules/hr/use-hr-app-data'
import { createSelectOption } from '@helpers/utils'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { AgreementStatusOptions, HrWorkerStatusOptions } from '@modules/hr/consts'
import { useMultipleResorts } from '@components/hooks/use-multiple-resorts'
import { CommonTableFilters } from '@components/table/common-table-filters'
import { Col } from 'reactstrap'
import { ButtonWithIcon } from '@components/button-with-icon'
import { useModal } from '@components/modals/use-modal'
import { UserPermission } from '@models/dashboard'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { FormCheckbox } from '@hyper/forms/form-checkbox'

export interface HrWorkerListFilterParams extends BaseTableFilters {
  date_of_start_before?: Date
  date_of_start_after?: Date
  date_of_termination_after?: Date
  date_of_termination_before?: Date
  resorts: CustomReactSelectOption[]
  companies: CustomReactSelectOption[]
  agreement_status: CustomReactSelectOption | null
  status: CustomReactSelectOption | null
  job_positions: CustomReactSelectOption[]
  show_removed: boolean
}

interface Props {
  defaultFilters: HrWorkerListFilterParams
  filters: HrWorkerListFilterParams
  setFilters: (filters: HrWorkerListFilterParams) => void
}

export const HrWorkersListFilter: React.FC<Props> = ({ defaultFilters, filters, setFilters }) => {
  const user = useAuthenticatedUser()
  const { companies, job_positions } = useHrAppData()
  const [showCreationModal] = useModal('HrWorkerCreateModal')

  const methods = useForm<HrWorkerListFilterParams>({ defaultValues: defaultFilters })

  const { getResortOptions } = useMultipleResorts({ methods, field: 'resorts' })
  const resortOptions = getResortOptions({ withAllOption: false })
  const companiesOptions = companies.map(company => createSelectOption(company.name, company.id))
  const jobPositionOptions = job_positions.map(jobPosition => createSelectOption(jobPosition.name, jobPosition.id))

  return (
    <FormProvider {...methods}>
      <Col md={12} className="px-0 text-right">
        {user.hasPerm(UserPermission.HrWorkerProfileCanAdd) && (
          <ButtonWithIcon
            icon="uil-plus font-16"
            text="Dodaj pracownika"
            color="green"
            btnClass="btn-tall ml-auto"
            handleClick={showCreationModal}
          />
        )}
      </Col>
      <CommonTableFilters
        filters={filters}
        defaultFilters={defaultFilters}
        setFilters={setFilters}
        searchWrapperParams={{ className: 'col-auto ml-auto' }}
        clearParams={{ className: 'mt-label ml-auto' }}
        rowClassName="flex-wrap"
      >
        <FormSelect
          options={HrWorkerStatusOptions}
          name="status"
          label="Status"
          formPlainProps={{ colSize: 'auto', colStyle: { width: 200 } }}
        />
        <FormSelect
          options={AgreementStatusOptions}
          name="agreement_status"
          label="Status umowy"
          formPlainProps={{ colSize: 'auto', colStyle: { width: 200 } }}
        />
        <FormSelect options={companiesOptions} name="companies" label="Spółka" formPlainProps={{ colSize: 2 }} />
        <FormSelect
          options={resortOptions}
          name="resorts"
          label="Resort"
          selectProps={{ isSelectMulti: true }}
          formPlainProps={{ colStyle: { maxWidth: 255 } }}
        />
        <FormSelect
          options={jobPositionOptions}
          name="job_positions"
          label="Stanowisko"
          selectProps={{ isSelectMulti: true }}
          formPlainProps={{ colStyle: { maxWidth: 255 } }}
        />
        {user.hasPerm(UserPermission.HrWorkerProfileCanViewRemoved) && (
          <FormCheckbox name="show_removed" label="Pokaż usuniętych" className="mt-label pt-1 ml-1" />
        )}
      </CommonTableFilters>
    </FormProvider>
  )
}
