import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { ReservationCard } from '@modules/reservations/details/common/reservation-card'
import { ReservationNotificationsActions } from '@modules/reservations/details/notification-history/reservation-notifications-actions'
import {
  ReservationNotificationTab,
  ReservationNotificationsTabs,
} from '@modules/reservations/details/notification-history/reservation-notifications-tabs'
import { FormProvider, useForm } from 'react-hook-form'
import { NotificationEmailMessages } from '@components/notification-box/notification-email-messages'
import { NotificationSmsMessages } from '@components/notification-box/notification-sms-messages'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { commonObjectGet } from '@store/actions/generic-actions'
import { useApiRequest } from '@components/hooks/use-api-request'
import { NotificationTemplates } from '@models/notifications'
import { useAppDispatch } from '@store/index'
import { getReceptionBookingDetails } from '@store/actions/reception-actions'

interface FormInputs {
  email_template: CustomReactSelectOption | null
  sms_template: CustomReactSelectOption | null
}

interface ReservationNotificationHistoryProps {
  booking: ReceptionBookingDetails
}

export const ReservationNotifications: React.FC<ReservationNotificationHistoryProps> = ({ booking }) => {
  const [notificationTemplates, setNotificationTemplates] = React.useState<NotificationTemplates | null>(null)
  const [activeTab, setActiveTab] = React.useState<ReservationNotificationTab>('email')

  const methods = useForm<FormInputs>({ defaultValues: { email_template: null, sms_template: null } })

  const dispatch = useAppDispatch()

  const isEmailTabSelected = activeTab === 'email'

  const { action: fetchNotifications } = useApiRequest(async () => {
    const response = await commonObjectGet<NotificationTemplates>(booking.urls.notifications)
    setNotificationTemplates(response)
  })

  React.useEffect(() => {
    fetchNotifications()
  }, [])

  const { action: refetchBooking } = useApiRequest(async () => {
    dispatch(getReceptionBookingDetails(booking.urls.api_details))
  })

  return (
    <ReservationCard
      titleIcon="uil-envelope-check font-20"
      title="Wysłane powiadomienia"
      cardActions={<ReservationNotificationsActions booking={booking} />}
    >
      <FormProvider {...methods}>
        <ReservationNotificationsTabs booking={booking} activeTab={activeTab} setActiveTab={setActiveTab} />
        {isEmailTabSelected ? (
          <NotificationEmailMessages
            isDisabled={!booking.email_notifications_enabled}
            onSend={refetchBooking}
            previewUrl={booking.urls.notifications}
            notificationTemplates={notificationTemplates?.email ?? []}
            notifications={booking.email_notifications}
          />
        ) : (
          <NotificationSmsMessages
            isDisabled={!booking.sms_notifications_enabled}
            onSend={refetchBooking}
            previewUrl={booking.urls.notifications}
            notificationTemplates={notificationTemplates?.sms ?? []}
            notifications={booking.sms_notifications}
          />
        )}
      </FormProvider>
    </ReservationCard>
  )
}
