import { ReceptionBookingCheckStep } from '@modules/reception/common/reception-booking-check-steps'
import * as React from 'react'

export const useStepsWithFeeding = (
  steps: ReceptionBookingCheckStep[],
  hasFeedingAvailable: boolean,
  feedingStep: number,
) => {
  const [dynamicSteps, setDynamicSteps] = React.useState<ReceptionBookingCheckStep[]>(steps)

  const calculateSteps = () => {
    const newSteps = steps.reduce((cum: ReceptionBookingCheckStep[], step: ReceptionBookingCheckStep) => {
      if (step.step === feedingStep) {
        return [...cum]
      }

      if (step.step > feedingStep) {
        return [...cum, { ...step, step: step.step - 1 }]
      }

      return [...cum, step]
    }, [])
    setDynamicSteps(newSteps)
  }

  const getDynamicStep = (step: number) => (hasFeedingAvailable ? step : step - 1)

  React.useEffect(() => {
    if (hasFeedingAvailable !== undefined && !hasFeedingAvailable) {
      calculateSteps()
      return
    }

    if (hasFeedingAvailable) {
      setDynamicSteps(steps)
    }
  }, [hasFeedingAvailable])

  return { dynamicSteps, getDynamicStep }
}
