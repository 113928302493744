import * as React from 'react'
import { NotificationEvents } from '@models/dashboard'
import { useAppDispatch } from '@store/index'
import { useWebSocket } from '@components/hooks/use-web-socket'
import { Seller } from '@models/crm'
import { updateSellerDetails } from '@store/actions/crm-actions'

export const SellerWebSocketHandler: React.FC = () => {
  const dispatch = useAppDispatch()

  useWebSocket<Seller>(
    (message, payload) => {
      switch (message) {
        case NotificationEvents.SELLER_CHANGE:
          dispatch(updateSellerDetails(payload as Seller))
          break
      }
    },
    [],
    [NotificationEvents.SELLER_CHANGE],
  )
  return null
}
