import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { apiQueryFunction } from '@api/base'
import { extractSelectOptionsValues } from '@helpers/utils'
import { PaginationResponse } from '@models/dashboard'
import { RootState } from '@store/index'
import { CleaningOrder, CleaningOrderStatus } from '@modules/housekeeping/models'

type CleaningOrdersQueryParams = Partial<{ apartment: number; status: CleaningOrderStatus }>

export const CLEANING_ORDER_TAG = 'cleaningOrder'

export const cleaningOrdersApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: '' }),
  reducerPath: 'cleaningOrdersApi',
  tagTypes: [CLEANING_ORDER_TAG],
  endpoints: builder => ({
    getCleaningOrders: builder.query<CleaningOrder[], CleaningOrdersQueryParams>({
      queryFn: apiQueryFunction((state: RootState, data: CleaningOrdersQueryParams) => ({
        params: { ...(extractSelectOptionsValues(data) || {}) },
        method: 'GET',
        url: state.appState.appData.urls.housekeeping.cleaning_orders,
        responseTransformer: (response: PaginationResponse<CleaningOrder>) => response.results,
      })),
      providesTags: (result: CleaningOrder[]) =>
        result
          ? [...result.map(({ id }) => ({ type: CLEANING_ORDER_TAG, id }) as const), CLEANING_ORDER_TAG]
          : [CLEANING_ORDER_TAG],
    }),
  }),
})

export const { useGetCleaningOrdersQuery } = cleaningOrdersApi
