import { useReportsAppData } from '@modules/reports/hooks/use-reports-app-data'

export const useGroupedPromotions = () => {
  const { promotion_marketing: promotions } = useReportsAppData()

  const sourceList = (promotions || []).map(source => ({
    label: source.name,
    value: source.id,
  }))

  return [{ label: 'Wszystkie', value: null }, ...sourceList]
}
