import * as React from 'react'
import { CustomInput } from 'reactstrap'
import { extractInnerRef } from '@helpers/forms'
import { FormPlain } from '@hyper/forms'
import { useFormContext, useWatch } from 'react-hook-form'
import { ShopPurchasePaymentInvoice } from '@modules/shop/pos/purchase-payments/shop-purchase-payment-invoice'
import { ShopPOSWithParamsPaymentKindFormInputs } from '@modules/shop/pos/shop-pos-with-params'

export const ShopPurchasePaymentKind = () => {
  const methods = useFormContext<ShopPOSWithParamsPaymentKindFormInputs>()

  const isInvoiceSelected = useWatch({ control: methods.control, name: 'kind' }) === 'invoice'

  return (
    <div>
      <FormPlain name="kind" formGroupClassName="mb-3" colClassName="px-0">
        <label className="text-primary font-weight-bold font-14 d-block mb-2">Forma potwierdzenia płatności:</label>
        <CustomInput
          className="d-inline-block mr-2"
          type="radio"
          value="receipt"
          {...extractInnerRef(methods.register('kind'))}
          id="receipt"
          label="Paragon"
        />
        <CustomInput
          className="d-inline-block"
          type="radio"
          value="invoice"
          {...extractInnerRef(methods.register('kind'))}
          id="invoice"
          label="Faktura"
        />
      </FormPlain>
      {isInvoiceSelected && <ShopPurchasePaymentInvoice />}
    </div>
  )
}
