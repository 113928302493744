import * as React from 'react'
import { NotificationEvents } from '@models/dashboard'
import { useAppDispatch } from '@store/index'
import { useWebSocket } from '@components/hooks/use-web-socket'
import { ReportTask } from '@models/reports'
import { updateUserReportTask } from '@store/slices/report-task-slice'

export const UserReportsWebSocketHandler: React.FC = () => {
  const dispatch = useAppDispatch()

  useWebSocket<ReportTask>(
    (message, payload) => {
      switch (message) {
        case NotificationEvents.REPORT_TASK_CHANGE:
          dispatch(updateUserReportTask(payload))
          break
      }
    },
    [],
    [NotificationEvents.REPORT_TASK_CHANGE],
  )

  return null
}
