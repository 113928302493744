import * as React from 'react'
import { Button, Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { FormInput } from '@hyper/forms'
import { useFormRequest } from '@components/hooks/use-api-request'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form'
import { BaseModalProps } from '@components/modals/types'
import { FormSelect } from '@hyper/forms/form-select'
import { ReceptionBookingDetails } from '@models/reception'
import { createSelectOption } from '@helpers/utils'
import { IconWithText } from '@components/icon-with-text'
import classNames from 'classnames'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { updateReceptionBookingDetails } from '@store/actions/reception-actions'
import { useAppDispatch } from '@store/index'
import { commonObjectPost } from '@store/actions/generic-actions'
import { getBookingLoadNotes } from '@store/actions/reservation-actions'

export const paymentMethodOptions = [
  createSelectOption('Gotówka w kasie', 'cash'),
  createSelectOption('Karta kredytowa', 'creditcard'),
]

interface FormNote {
  name: string
  price_brutto: string
}

interface FormInputs {
  name: string
  items: FormNote[]
  description: string
  source: CustomReactSelectOption | null
}

interface Props extends BaseModalProps {
  booking: ReceptionBookingDetails
}

export const ReservationLoadNoteAddModal: React.FC<Props> = ({ toggleIsVisible, booking }) => {
  const methods = useForm<FormInputs>({
    defaultValues: { name: '', description: '', items: [], source: null },
  })
  const { addSuccessMessage } = useNotificationHook()

  const dispatch = useAppDispatch()

  const notes = useWatch({ control: methods.control, name: 'items' })

  const { isLoading, action: onSubmit } = useFormRequest(async () => {
    const { source, ...payload } = methods.getValues()

    await dispatch(
      updateReceptionBookingDetails(
        await commonObjectPost<ReceptionBookingDetails>(booking.urls.load_notes, {
          ...payload,
          source: source?.value,
        }),
      ),
    )

    addSuccessMessage('Sukces', 'Nota została dodana')
    dispatch(await getBookingLoadNotes(booking))
    toggleIsVisible()
  }, methods.setError)

  const { append, fields, remove, update } = useFieldArray({ control: methods.control, name: 'items' })

  const handleAppendNewPosition = () => {
    append({ name: '', price_brutto: '' })
  }

  const handleRemove = (index: number) => {
    notes.length > 1 ? remove(index) : update(index, { name: '', price_brutto: '' })
  }

  React.useEffect(() => {
    handleAppendNewPosition()
  }, [])

  const canBeDeleted = (index: number) => {
    if (!notes.length) return false
    return (index === 0 && (!!notes[index].name || !!notes[index].price_brutto)) || index > 0
  }

  const canAddNextNote = React.useMemo(() => {
    const lastNote = notes.at(-1)
    return !!lastNote?.price_brutto && !!lastNote?.name
  }, [notes])

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggleIsVisible}>Dodaj notę</ModalHeader>
        <ModalBody>
          {fields.map((field, index) => (
            <Row key={field.id}>
              <FormInput colSize={8} name={`items.${index}.name`} {...(index === 0 && { label: 'Nazwa' })} />
              <Col md={4} className="d-flex">
                <Row>
                  <FormInput
                    colSize={9}
                    colClassName="pr-0"
                    name={`items.${index}.price_brutto`}
                    type="currency"
                    {...(index === 0 && { label: 'Kwota' })}
                  />
                  <Col
                    md={3}
                    style={{ marginTop: index === 0 ? '32px' : '9px' }}
                    className={classNames({ 'not-clickable': !canBeDeleted(index) })}
                  >
                    <i className="uil-trash-alt cursor-pointer font-15" onClick={() => handleRemove(index)} />
                  </Col>
                </Row>
              </Col>
            </Row>
          ))}
          <IconWithText
            icon="uil-plus"
            text="Dodaj kolejną pozycję"
            textClass="font-13 fw-semi-bold"
            onClick={handleAppendNewPosition}
            wrapperClassNames={classNames('mb-3', { 'not-clickable': !canAddNextNote })}
          />
          <Row>
            <FormInput label="Opis dodatkowy" name="description" type="textarea" />
            <FormSelect name="source" label="Forma płatności" options={paymentMethodOptions} />
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={toggleIsVisible}>
            Zamknij
          </Button>

          <SaveButton
            role="submit"
            className="btn btn-green"
            label="Dodaj"
            labelSaving="Dodawanie..."
            isSaving={isLoading}
          />
        </ModalFooter>
      </form>
    </FormProvider>
  )
}
