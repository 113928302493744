import * as React from 'react'
import { Col, UncontrolledTooltip } from 'reactstrap'
import { notificationContentVariables } from '@modules/reception/common/reception-notification-content'

export const ReceptionNotificationContentInfo: React.FC = () => {
  const tooltipId = 'reception-notification-content-info-tooltip'

  return (
    <Col md={6} className="font-12 text-semi-strong text-right" id={tooltipId}>
      Jak używać zmiennych?
      <i className="ml-1 uil-info-circle font-16" />
      <UncontrolledTooltip placement="top" target={tooltipId}>
        <div className="font-12">
          <strong className="d-block mb-1">Dostępnę są następujące zmienne</strong>

          <ul className="text-left">
            {notificationContentVariables.map(variable => (
              <li key={variable}>@{variable}</li>
            ))}
          </ul>
        </div>
      </UncontrolledTooltip>
    </Col>
  )
}
