import * as React from 'react'

const TimelineScrollButtons: React.FC = () => {
  const scrollToTop = (): void => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const scrollToBottom = (): void => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
  }

  return (
    <>
      <button
        type="button"
        className="btn btn-pure font-11 cursor-pointer calendar__scroll-top-btn"
        onClick={scrollToTop}
      >
        <i className="uil-arrow-up font-18 position-absolute calendar__scroll-top-btn-icon" />
      </button>
      <button
        type="button"
        className="btn btn-pure font-11 cursor-pointer calendar__scroll-bottom-btn"
        onClick={scrollToBottom}
      >
        <i className="uil-arrow-down font-18 position-absolute calendar__scroll-top-btn-icon" />
      </button>
    </>
  )
}

export default TimelineScrollButtons
