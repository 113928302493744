import * as React from 'react'
import { SaveButton } from '@hyper/button'
import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useFormRequest } from '@components/hooks/use-api-request'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useToggle } from '@components/hooks/use-toggle'
import { RcpEventDay, RcpMachine, RcpUserDetail } from '@models/rcp'
import classNames from 'classnames'
import { rcpUserEventsDaysFilter, updateUserEventDayDetails } from '@store/slices/rcp-slice'
import { FormInputTime } from '@hyper/forms/form-input-time'

const initialState: FormInputs = {
  check_in_place: '1',
  check_in_time: '',
  check_out_place: '1',
  check_out_time: '',
}

interface Props {
  user: RcpUserDetail
  eventDay: RcpEventDay
  machines: RcpMachine[]
  hasError: boolean
}

interface FormInputs {
  check_in_time: string
  check_out_time: string
  check_in_place: string
  check_out_place: string
}

export const EventsPairAddNew: React.FC<Props> = ({ machines, eventDay, hasError }) => {
  const [isOpen, toggleIsOpen] = useToggle()
  const {
    register,
    control,
    getValues,
    formState: { errors },
    setError,
  } = useForm<FormInputs>({
    defaultValues: initialState,
  })
  const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()
  const filters = useAppSelector(rcpUserEventsDaysFilter)

  const { isLoading, action: handleSave } = useFormRequest(async () => {
    dispatch(
      updateUserEventDayDetails(
        await commonObjectPost<RcpEventDay>(eventDay.urls.details, { ...getValues() }, undefined, filters),
      ),
    )
    addSuccessMessage()
    toggleIsOpen()
  }, setError)

  return (
    <>
      {isOpen && (
        <>
          <tr
            className={classNames('rcp__events-table__edit-row rcp__events-table__tr-primary-middle', {
              'has-errors': hasError,
            })}
          >
            <td>Start:</td>
            <td>
              <FormInputTime
                name="check_in_time"
                colStyle={{ maxWidth: 90 }}
                colClassName="my-0 px-0"
                control={control}
              />
              {errors.check_in_time && <div className="invalid-feedback d-block">{errors.check_in_time?.message}</div>}
            </td>
            <td>
              <span className="action-icon">
                {' '}
                <i className=" uil-corner-right-down text-dark" />
              </span>
            </td>
            <td>Miejsce:</td>
            <td>
              <select style={{ maxWidth: 200 }} {...register('check_in_place')} className="form-control">
                {machines.map(machine => (
                  <option key={machine.id} value={machine.id}>
                    {' '}
                    {machine.name}{' '}
                  </option>
                ))}
              </select>
              {errors.check_in_place && (
                <div className="invalid-feedback d-block">{errors.check_in_place?.message}</div>
              )}
            </td>
            <td>
              <span className="action-icon">
                {' '}
                <i className=" uil-corner-right-down text-dark" />
              </span>
            </td>
            <td />

            <td />
          </tr>
          <tr
            className={`rcp__events-table__edit-row rcp__events-table__tr-primary-middle ${
              hasError ? 'has-errors' : ''
            }`}
          >
            <td>Koniec:</td>
            <td />
            <td>
              <FormInputTime
                name="check_out_time"
                colStyle={{ maxWidth: 90 }}
                colClassName="my-0 px-0"
                control={control}
              />
              {errors.check_out_time && (
                <div className="invalid-feedback d-block">{errors.check_out_time?.message}</div>
              )}
            </td>
            <td>Miejsce:</td>
            <td />
            <td>
              <select {...register('check_out_place')} style={{ maxWidth: 200 }} className="form-control">
                {machines.map(machine => (
                  <option key={machine.id} value={machine.id}>
                    {' '}
                    {machine.name}{' '}
                  </option>
                ))}
              </select>
              {errors.check_out_place && (
                <div className="invalid-feedback d-block">{errors.check_out_place?.message}</div>
              )}
            </td>
            <td>
              <SaveButton className="btn-green btn btn-sm" label="Dodaj" onClick={handleSave} isSaving={isLoading} />
            </td>
            <td />
          </tr>
        </>
      )}
      <tr className={`rcp__events-table__tr-primary-bottom ${hasError ? 'has-errors' : ''}`}>
        <td className="text-center" colSpan={8}>
          <button title="Dodaj zdarzenie" className="text-dark h5 btn-pure" onClick={toggleIsOpen}>
            + Dodaj nowy start i koniec
          </button>
        </td>
      </tr>
    </>
  )
}
