import {
  ReceptionBooking,
  ReceptionBookingDetails,
  ReceptionBookingLoadNote,
  ReceptionBookingWalletDetails,
  ReservationBookingPayment,
  ReservationBookingVoucher,
  WalletTransaction,
} from '@models/reception'
import { AsyncThunkParams } from '@store/index'
import { ReceptionListFiltersParams } from '@modules/reservations/list/reservation-list-with-params'
import { setDashboardStandardPaginator } from '@store/actions/dashboard-actions'
import { commonObjectGet, wrapWithError } from '@store/actions/generic-actions'
import { PaginationResponse } from '@models/dashboard'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getBookingList = createAsyncThunk<
  ReceptionBooking[],
  Partial<ReceptionListFiltersParams>,
  AsyncThunkParams
>('reservations/getBookingList', async (filters, { getState, dispatch }) => {
  const { is_paid, is_package_reservation, is_subscription_reservation, ...filtersToAdd } = filters

  const data = await commonObjectGet<PaginationResponse<ReceptionBooking>>(
    getState().appState.appData.urls.rent.booking_list,
    {
      ...filtersToAdd,
      accommodation_type: filters.accommodation_type?.map(row => row.value),
      status: filters.status?.map(row => row.value),
      resort: filters.resort?.map(row => row.value),
      ...(is_package_reservation && { is_package_reservation }),
      ...(is_paid && { is_paid }),
      ...(is_subscription_reservation && { is_subscription_reservation }),
    },
  )

  dispatch(setDashboardStandardPaginator(data))

  return data.results
})

export const getWalletTransactions = createAsyncThunk<WalletTransaction[], ReceptionBookingDetails, AsyncThunkParams>(
  'reservations/getWalletTransactions',
  async booking => await wrapWithError(commonObjectGet<WalletTransaction[]>(booking.urls.wallet_transactions)),
)
export const getBookingPayments = createAsyncThunk<
  ReservationBookingPayment[],
  ReceptionBookingDetails,
  AsyncThunkParams
>(
  'reservations/getBookingPayments',
  async booking => await wrapWithError(commonObjectGet<ReservationBookingPayment[]>(booking.urls.booking_payments)),
)
export const getBookingLoadNotes = createAsyncThunk<
  ReceptionBookingLoadNote[],
  ReceptionBookingDetails,
  AsyncThunkParams
>(
  'reservations/getBookingLoadNotes',
  async booking => await wrapWithError(commonObjectGet<ReceptionBookingLoadNote[]>(booking.urls.load_notes)),
)
export const getBookingWalletDetails = createAsyncThunk<
  ReceptionBookingWalletDetails,
  ReceptionBookingDetails,
  AsyncThunkParams
>(
  'reservations/getBookingWalletDetails',
  async booking =>
    await wrapWithError(commonObjectGet<ReceptionBookingWalletDetails>(booking.urls.client_wallet_details)),
)
export const getBookingVouchers = createAsyncThunk<
  ReservationBookingVoucher[],
  ReceptionBookingDetails,
  AsyncThunkParams
>(
  'reservations/getBookingVouchers',
  async booking =>
    await wrapWithError(commonObjectGet<ReceptionBookingWalletDetails>(booking.urls.reservation_booking_vouchers)),
)
