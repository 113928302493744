import * as React from 'react'
import ModalBody from 'reactstrap/lib/ModalBody'
import { ReceptionBookingCheck } from '@modules/reception/common/reception-booking-check'
import { BookingExtendedStayKind, ReceptionBooking } from '@models/reception'
import { ReceptionBookingExtendedStayContainer } from '@modules/reception/extended-stay/reception-booking-extended-stay-container'
import { ReceptionBookingExtendedStayContent } from '@modules/reception/extended-stay/reception-booking-extended-stay-content'
import { FormProvider, useForm } from 'react-hook-form'
import { useFormRequest } from '@components/hooks/use-api-request'
import { ExtendedStayFormInputs } from '@modules/reception/extended-stay/reception-booking-extended-stay-dialog'
import { useExtendedStay } from '@modules/reception/to-checkout/row/use-extended-stay'
import { useExtendedStayCollision } from '@modules/reception/extended-stay/use-extended-stay-collision'

interface ReceptionBookingExtendedStayTimeProps {
  nextStep: () => void
  booking: ReceptionBooking
}

export const ReceptionBookingExtendedStayTime: React.FC<ReceptionBookingExtendedStayTimeProps> = ({
  nextStep,
  booking,
}) => {
  const { submit } = useExtendedStay(booking)
  const { checkExtendingCollision, showCollisionModal } = useExtendedStayCollision()

  const methods = useForm<ExtendedStayFormInputs>({
    defaultValues: {
      kind: BookingExtendedStayKind.TWELVE_O_CLOCK,
    },
  })

  const extendStay = async (payload: ExtendedStayFormInputs) => {
    await submit(payload)
    nextStep()
  }

  const { isLoading, action: onSubmit } = useFormRequest(
    async (payload: ExtendedStayFormInputs) => {
      const { collisionBooking } = await checkExtendingCollision('extend_stay_12')

      if (collisionBooking) {
        showCollisionModal('extend_stay_12', collisionBooking, async () => {
          await extendStay(payload)
        })
        return
      }

      await extendStay(payload)
    },
    methods.setError,
    { showGlobalError: true, excludeFields: ['non_field_errors'] },
  )

  return (
    <FormProvider {...methods}>
      <ReceptionBookingExtendedStayContainer
        booking={booking}
        render={({ options, totalPrice, selectedOption, onOptionsChange }) => (
          <form onSubmit={methods.handleSubmit(onSubmit)} data-extended-stay-hours={selectedOption?.hour}>
            <ModalBody>
              <h4>1. Wybierz czas przedłużenia</h4>
              <ReceptionBookingExtendedStayContent
                options={options}
                totalPrice={totalPrice}
                booking={booking}
                onOptionsChange={onOptionsChange}
              />
            </ModalBody>
            <ReceptionBookingCheck isSaving={isLoading} nextStep={nextStep} />
          </form>
        )}
      />
    </FormProvider>
  )
}
