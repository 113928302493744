import { ThunkAction } from 'redux-thunk'
import { RootState } from '@store/index'
import { getState } from '@store/index'
import { ReceptionBookingDetails, ReceptionBookingWalletDetails } from '@models/reception'
import { commonObjectPost } from '@store/actions/generic-actions'
import { Action } from '@reduxjs/toolkit'
import { setReceptionBookingDetails } from '@store/slices/reception-slice'

export function bookingPaymentPreAuthorizationCheckout(
  url,
  amount: string,
): ThunkAction<Promise<void>, RootState, null, Action> {
  return async dispatch => {
    dispatch(setReceptionBookingDetails(await commonObjectPost<ReceptionBookingDetails>(url, { amount })))
  }
}

export async function bookingClientWalletAssign(
  booking: number,
  gastro_card: string,
  group_id: number,
): Promise<ReceptionBookingWalletDetails> {
  const url = getState().appState.appData.urls.promotions.client_wallet_assigment
  return commonObjectPost<ReceptionBookingWalletDetails>(url, { booking, gastro_card, group_id, is_active: true })
}

export async function bookingPackageVipPayByCode(bookingId: number, code: string): Promise<ReceptionBookingDetails> {
  const url = getState().appState.appData.urls.promotions.package_vip_booking_improvement
  return commonObjectPost<ReceptionBookingDetails>(url, {
    booking: bookingId,
    code,
  })
}
