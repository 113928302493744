import * as React from 'react'
import { FormCheckbox } from '@hyper/forms/form-checkbox'
import { FormInput } from '@hyper/forms'
import { useFormContext } from 'react-hook-form'
import { StepGuestsGuestFormInputs } from '@modules/reception/checkin/step-guests/step-guests-guest-form/models'
import classNames from 'classnames'
import { FormScope } from '@modules/reception/checkin/step-guests/step-guests-guest-form/wallet/scopes-list-item'

interface Props {
  scopes: FormScope[]
}

export const StepGuestsWalletScopeRow = ({ scopes }: Props): JSX.Element => {
  const { setValue, getValues } = useFormContext<StepGuestsGuestFormInputs>()

  return (
    <div className="pl-4 pt-2 border-bottom table-list-indicator__wrapper">
      {scopes.map(scope => (
        <div className="d-flex align-items-center justify-content-between w-75 " key={scope.scopeFormName}>
          <FormCheckbox
            name={scope.scopeFormName}
            label={scope.title}
            className={classNames('table-list-indicator__element', { 'mb-1': scope.limitFormName === undefined })}
            checked={!!getValues(scope.scopeFormName)}
          />
          {scope.limitFormName && (
            <FormInput
              name={scope.limitFormName}
              colSize={7}
              placeholder="Limit dzienny: np 50 zł"
              formGroupClassName="mb-1"
              type="currency"
              setValue={setValue}
              disabled={!getValues(scope.scopeFormName)}
            />
          )}
        </div>
      ))}
    </div>
  )
}
