import * as React from 'react'
import classnames from 'classnames'

interface ReceptionBookingCheckInStepImprovementsGroupProps {
  title: string
  children: React.ReactNode
  tableClassName?: string
}

export const ReceptionBookingCheckInStepImprovementsGroup: React.FC<
  ReceptionBookingCheckInStepImprovementsGroupProps
> = ({ title, children, tableClassName }) => (
  <table
    className={classnames(
      'table table-borderless reception__booking-check-in__improvement-step__table mb-1',
      tableClassName,
    )}
  >
    <tbody>
      <tr>
        <td className="pl-0 pb-0">
          <strong className="font-13 text-muted">{title}</strong>
        </td>
      </tr>
      {children}
    </tbody>
  </table>
)
