import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { PaymentRegistersFiltersParams } from '@modules/payment-registers/list/filters'
import { TransactionListModalTable } from '@modules/payment-registers/transaction-list-modal/table'
import { useApiRequest } from '@components/hooks/use-api-request'
import { PaymentRegisterResponse } from '@store/actions/cash-actions'
import { PaymentRegister } from '@models/payments'
import { commonObjectGet } from '@store/actions/generic-actions'
import { useAppData } from '@components/hooks/use-app-data'

interface Props extends BaseModalProps {
  title: string
  params: PaymentRegistersFiltersParams
}

export const TransactionListModal: React.FC<Props> = ({ params, toggleIsVisible, title }) => {
  const defaultFilters: PaymentRegistersFiltersParams = {
    ...params,
    ordering: '-created',
    page: 1,
    source: null,
    page_size: 10,
    search: '',
  }
  const [filters, setFilters] = React.useState<PaymentRegistersFiltersParams>(defaultFilters)
  const [paymentRegisters, setPaymentRegisters] = React.useState<PaymentRegister[]>([])
  const url = useAppData().urls.payment.payment_registers

  const { isLoading, action: fetchPaymentRegisters } = useApiRequest(async () =>
    setPaymentRegisters(
      (
        await commonObjectGet<PaymentRegisterResponse>(url, {
          ...filters,
          source: params.source?.value,
          user: params.user?.value,
          type: params.type?.value,
          page_size: 9999999,
          status: 'success',
        })
      ).results,
    ),
  )

  React.useEffect(() => {
    fetchPaymentRegisters()
  }, [filters])

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>
        Listra transakcji <strong>{title}</strong>
      </ModalHeader>
      <ModalBody>
        <TransactionListModalTable
          paymentRegisters={paymentRegisters}
          filters={filters}
          setFilters={setFilters}
          isLoading={isLoading}
        />
      </ModalBody>
      <ModalFooter>
        <button type="button" onClick={toggleIsVisible} className="btn btn-light">
          Zamknij
        </button>
      </ModalFooter>
    </>
  )
}
