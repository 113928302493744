import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import { FormProvider, useForm } from 'react-hook-form'
import { useApiRequest, useFormRequest } from '@components/hooks/use-api-request'
import { SaveButton } from '@hyper/button'
import { FormInput } from '@hyper/forms'
import { FormSelect } from '@hyper/forms/form-select'
import { CustomReactSelectOption, makeDefaultSelectOption } from '@components/custom-react-select'
import { useAppDispatch } from '@store/index'
import { useAppData } from '@components/hooks/use-app-data'
import { createSelectOption } from '@helpers/utils'
import { RentalWarehouse } from '@modules/rental/models'
import {
  createRentalWarehouse,
  removeRentalWarehouse,
  updateRentalWarehouse,
} from '@store/actions/rental-warehouse-actions'

interface Props extends BaseModalProps {
  warehouse?: RentalWarehouse
}

export interface RentalWarehouseFormInputs {
  name: string
  resort: CustomReactSelectOption | null
}

export const RentalWarehouseCreateModal: React.FC<Props> = ({ toggleIsVisible, warehouse }) => {
  const dispatch = useAppDispatch()

  const { resorts, urls } = useAppData()

  const resortOptions = resorts.map(resort => createSelectOption(resort.name, resort.id))

  const methods = useForm<RentalWarehouseFormInputs>({
    mode: 'onChange',
    defaultValues: {
      name: warehouse?.name ?? '',
      resort: makeDefaultSelectOption(resortOptions, warehouse?.resort),
    },
  })

  const { action: handleSubmit, isLoading } = useFormRequest(async (payload: RentalWarehouseFormInputs) => {
    await dispatch(
      warehouse
        ? updateRentalWarehouse([warehouse.urls.details, payload])
        : createRentalWarehouse([urls.rental.warehouses, payload]),
    )
    toggleIsVisible()
  }, methods.setError)

  const { action: handleDelete, isLoading: isDeleting } = useApiRequest(async () => {
    if (!warehouse) return
    await dispatch(removeRentalWarehouse(warehouse))
    toggleIsVisible()
  })

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <ModalHeader toggle={toggleIsVisible}>{warehouse ? 'Edytuj magazyn' : 'Dodaj magazyn'}</ModalHeader>
        <ModalBody className="pb-0">
          <Row>
            <FormInput name="name" label="Nazwa" />
            <FormSelect options={resortOptions} name="resort" label="Ośrodek" />
          </Row>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          {warehouse && (
            <SaveButton isSaving={isDeleting} className="btn btn-danger mr-1" label="Usuń" onClick={handleDelete} />
          )}
          <div className="ml-auto">
            <Button className="btn btn-light mr-2" type="button" onClick={toggleIsVisible}>
              Anuluj
            </Button>
            <SaveButton label="Zapisz" className="btn-green btn" isSaving={isLoading} />
          </div>
        </ModalFooter>
      </form>
    </FormProvider>
  )
}
