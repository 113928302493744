import * as React from 'react'
import { ReceptionBookingDetailsImprovement } from '@models/booking'
import { ImprovementIcon } from '@components/improvement-icon'
import { formatPriceShort } from '@helpers/utils'
import { useApiRequest } from '@components/hooks/use-api-request'
import { deleteBookingImprovement } from '@store/actions/reception-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAppDispatch } from '@store/index'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'

interface Props {
  improvement: ReceptionBookingDetailsImprovement
}

export const ReceptionBookingSubscriptionImprovementAddedRow = ({ improvement }: Props): React.ReactNode => {
  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()

  const { isLoading: isDeleteLoading, action: handleDelete } = useApiRequest(async (deleteReason: string) => {
    await dispatch(deleteBookingImprovement(improvement.urls.details, deleteReason))
    addSuccessNotification('Ulepszenie zostało usunięte!')
  })

  return (
    <tr key={improvement.id} className="d-flex align-items-center justify-content-between">
      <td className="d-flex align-items-center">
        <ImprovementIcon improvementCode={improvement.code} className="text-secondary font-16 mr-1" />
        <div>
          <span className="font-14 fw-semi-bold">{improvement.name}</span>
          <br />
          <span>
            {improvement.amount}x voucher {improvement.guest_name}
          </span>
        </div>
      </td>
      <td className="d-flex pr-0">
        <CommonObjectConfirmAction
          title="Potwierdź usunięcie udogodnienia"
          handleAccept={handleDelete}
          isLoading={isDeleteLoading}
          message="Czy na pewno chcesz usunąć udogodnienie?"
          askForReason={true}
        >
          <i className="uil-trash-alt cursor-pointer font-18 grey-400 mr-4" role="delete-improvement" />
        </CommonObjectConfirmAction>

        <div className="text-danger fw-semi-bold font-14 reception__booking-check-in__improvement-step__price-col">
          ={formatPriceShort(improvement.price_brutto)}
        </div>
      </td>
    </tr>
  )
}
