import { createHandleRemoteTableChange } from '@helpers/tables/paginator'
import { TableFilters } from '@components/table/table'

export enum SortOrders {
  DESC = 'desc',
  ASC = 'asc',
}

export const useSortedTable = (setFilters, filters: TableFilters) => {
  const handleSort = (sortField, sortOrder?) => {
    let order = SortOrders.DESC
    if (filters.ordering.startsWith('-') && filters.ordering.split('-')[1] === sortField) {
      order = SortOrders.ASC
      sortField.replace('-', '')
    }
    createHandleRemoteTableChange(setFilters, filters)('sort', { sortField, sortOrder: sortOrder || order })
  }

  const getSortingOrder = (field: string | undefined): SortOrders | undefined => {
    if (!field) return undefined

    if (filters.ordering.startsWith('-')) {
      return filters.ordering.split('-')[1] === field ? SortOrders.DESC : undefined
    }

    return filters.ordering === field ? SortOrders.ASC : undefined
  }

  return { handleSort, getSortingOrder }
}
