import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { FormProvider, useForm } from 'react-hook-form'
import Decimal from 'decimal.js'
import { useAppDispatch } from '@store/index'
import { receptionBookingPaymentsAdd, ReceptionBookingPaymentsAddPayload } from '@store/actions/reception-actions'
import { CashPaymentBox } from '@components/cash-payment-box'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useFormRequest } from '@components/hooks/use-api-request'
import { asDecimal } from '@helpers/utils'

interface FormInputs {
  amount: string
}

interface Props {
  booking: ReceptionBookingDetails
  restToPay: Decimal
  handleCancel: () => void
  paymentParams: Partial<ReceptionBookingPaymentsAddPayload>
}

export const ReceptionBookingCheckPaymentsBoxCheckoutCash: React.FC<Props> = ({
  restToPay,
  handleCancel,
  booking,
  paymentParams,
}) => {
  const methods = useForm<FormInputs>({
    defaultValues: {
      amount: '0',
    },
  })
  const { addSuccessNotification } = useNotificationHook()

  const dispatch = useAppDispatch()

  const { isLoading, action: handleSubmit } = useFormRequest(
    async () => {
      const amount = methods.getValues('amount')

      await dispatch(
        receptionBookingPaymentsAdd(booking.urls.payments, {
          amount: asDecimal(amount).greaterThan(restToPay) ? restToPay.toString() : amount.toString(),
          source: 'cash',
          ...paymentParams,
        }),
      )

      addSuccessNotification('Wpłata została dodana!')
      methods.setValue('amount', '')
    },
    methods.setError,
    { nonFieldErrorsAs: 'amount' },
  )

  return (
    <FormProvider {...methods}>
      <CashPaymentBox
        restToPay={restToPay.toString()}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        isSaving={isLoading}
      />
    </FormProvider>
  )
}
