import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useGroupedAccommodationTypes } from '@modules/reservations/use-grouped-accommodation-types'

export const ReservationsTimelineDefault: React.FC = () => {
  const navigate = useNavigate()
  const user = useAuthenticatedUser()

  const firstUserResort = user.resorts[0]

  const { groupedAccommodationTypes } = useGroupedAccommodationTypes(firstUserResort)

  React.useEffect(() => {
    navigate(
      NavigationPath.ReservationsWithParams.replace(':resort_id', String(firstUserResort.id)).replace(
        ':accommodation_type_id',
        groupedAccommodationTypes[0].id,
      ),
    )
  }, [])

  return null
}
