import * as React from 'react'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import {
  SubscriptionProductCreateFormInputs,
  SubscriptionProductCreateFormInputsCodeFeature,
} from '@modules/subscription/products/create/modal'
import { Col, Row } from 'reactstrap'

import { SubscriptionProductCreateFormCodeFeaturesField } from '@modules/subscription/products/create/form-code-features-field'
import { SubscriptionTypeStrategy } from '@store/slices/subscription-slice'

interface Props {
  subscriptionTypeStrategy: SubscriptionTypeStrategy
}
export const SubscriptionProductCreateFormCodeFeatures: React.FC<Props> = ({ subscriptionTypeStrategy }) => {
  const { control } = useFormContext<SubscriptionProductCreateFormInputs>()

  const subscriptionCodesAmount = useWatch({
    control,
    name: ['local_sea', 'local_mountains', 'local_universal'],
  }).reduce((a, v) => (a = a + v), 0)

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'code_features',
  })

  React.useEffect(() => {
    if (subscriptionCodesAmount > fields.length) {
      append(
        [...Array(subscriptionCodesAmount - fields.length).keys()].map(
          () => ({}) as SubscriptionProductCreateFormInputsCodeFeature,
        ),
      )
    } else if (subscriptionCodesAmount < fields.length) {
      remove([...Array(fields.length - subscriptionCodesAmount).keys()].map(index => fields.length - 1 - index))
    }
  }, [subscriptionCodesAmount])

  if (!fields.length) {
    return null
  }

  return (
    <Row>
      <Col md={12}>
        <table className="table w-100 table-striped table-sm table-bordered">
          <thead>
            <tr>
              <th>Kod</th>
              <th>Rok działania (n+1)</th>
              {subscriptionTypeStrategy.allow_add_gastro_gift_to_product && <th>Voucher Gastronomiczny</th>}
              {subscriptionTypeStrategy.allow_add_package_vip_to_product && <th>Pakiet VIP</th>}
            </tr>
          </thead>
          <tbody>
            {fields.map((field, index) => (
              <SubscriptionProductCreateFormCodeFeaturesField
                index={index}
                key={index}
                subscriptionTypeStrategy={subscriptionTypeStrategy}
              />
            ))}
          </tbody>
        </table>
      </Col>
    </Row>
  )
}
