import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import { useForm, useWatch } from 'react-hook-form'
import { SaveButton } from '@hyper/button'
import { FormInput } from '@hyper/forms'
import { CustomReactSelectOption, makeDefaultSelectOption } from '@components/custom-react-select'
import { CouponType, CouponTypeConfiguration } from '@modules/coupons/models'
import { FormSelect } from '@hyper/forms/form-select'
import { useResortsAndAccommodationTypesOptions } from '@components/hooks/use-resorts-and-accommodation-types-options'
import { YesNoOptions } from '@helpers/consts'
import { useHandleRtkQueryError } from '@components/hooks/use-handle-rtk-error'
import { useCreateOrUpdateCouponTypeMutation, useGetCouponTypesConfigurationsQuery } from '@api/coupons'
import { Form } from '@hyper/forms/form'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { createSelectOption } from '@helpers/utils'
import { TypedQueryResult } from '@api/base'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'

interface Props extends BaseModalProps {
  couponType?: CouponType
}

export interface CouponsTypeFormInputs {
  name: string
  resort: CustomReactSelectOption | null
  configuration: CustomReactSelectOption | null
  is_active: CustomReactSelectOption
}

export const CouponTypeCreateModal: React.FC<Props> = ({ toggleIsVisible, couponType }) => {
  const { getResortOptions } = useResortsAndAccommodationTypesOptions()
  const { addSuccessNotification } = useNotificationHook()

  const resortOptions = getResortOptions().filter(option => option.value)

  const { data: couponTypesConfigurations = [] } = useGetCouponTypesConfigurationsQuery({
    skip_paginator: true,
  }) as TypedQueryResult<CouponTypeConfiguration[]>

  const allCouponTypeConfigurationOptions = couponTypesConfigurations.map(configuration =>
    createSelectOption(configuration.name, configuration.id),
  )

  const methods = useForm<CouponsTypeFormInputs>({
    mode: 'onChange',
    defaultValues: {
      name: couponType?.name ?? '',
      configuration: makeDefaultSelectOption(allCouponTypeConfigurationOptions, couponType?.configuration.id),
      resort: makeDefaultSelectOption(resortOptions, couponType?.configuration?.resort),
      is_active: makeDefaultSelectOption(YesNoOptions, couponType?.is_active) ?? YesNoOptions[0],
    },
  })

  const [createOrUpdateCouponType, { error, isError, isLoading }] = useCreateOrUpdateCouponTypeMutation()
  useHandleRtkQueryError(error, isError, methods.setError)

  const onSubmit = async (payload: CouponsTypeFormInputs) => {
    await createOrUpdateCouponType({ url: couponType?.urls.details, data: payload }).unwrap()
    addSuccessNotification('Zmiany zostały zapisane')
    toggleIsVisible()
  }

  const selectedResort = useWatch({ control: methods.control, name: 'resort' })

  const couponTypeConfigurationOptions = couponTypesConfigurations.reduce((acc, configuration) => {
    if (configuration.resort === selectedResort?.value) {
      return [...acc, createSelectOption(configuration.name, configuration.id)]
    }
    return acc
  }, [])

  useDidUpdateEffect(() => {
    methods.setValue('configuration', null)
  }, [selectedResort])

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>{couponType ? 'Edytuj typ kuponu' : 'Dodaj typ kuponu'}</ModalHeader>
      <ModalBody className="pb-0">
        <Row>
          <FormInput name="name" label="Nazwa:" />
          <FormSelect options={resortOptions} name="resort" label="Lokalizacja:" />
          <FormSelect
            options={couponTypeConfigurationOptions}
            name="configuration"
            label="Konfiguracja typu:"
            selectProps={{ isDisabled: !selectedResort?.value }}
          />
          <FormSelect options={YesNoOptions} name="is_active" label="Aktywny" formPlainProps={{ colSize: 4 }} />
        </Row>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <div className="ml-auto">
          <Button className="btn btn-light mr-2" type="button" onClick={toggleIsVisible}>
            Anuluj
          </Button>
          <SaveButton label="Zapisz" className="btn-green btn" isSaving={isLoading} />
        </div>
      </ModalFooter>
    </Form>
  )
}
