import * as React from 'react'
import Table from '@components/table/table'
import { TechnicalOrder } from '@models/technical-orders'
import { useTechnicalOrdersAppData } from '@modules/technical-orders/hooks/use-technical-orders-app-data'
import classNames from 'classnames'

interface Props {
  technicalOrder: TechnicalOrder
  width?: number
}

export const TechnicalOrdersTodoRowName: React.FC<Props> = ({ technicalOrder, width = 250 }) => {
  const { technical_order_types } = useTechnicalOrdersAppData()

  const technicalOrderType = React.useMemo(
    () => technical_order_types.find(u => u.id === technicalOrder.technical_order_type_id),
    [technical_order_types, technicalOrder.technical_order_type_id],
  )

  return (
    <Table.Cell className="vertical-align-middle" style={{ width }}>
      {technicalOrder.name}
      <span
        className={classNames('d-block font-10', {
          'text-strong': !technicalOrderType,
        })}
      >
        {technicalOrder.kind !== 'guests_technical_order' && (technicalOrderType?.name || 'Typ usterki do wskazania')}
      </span>
    </Table.Cell>
  )
}
