import * as React from 'react'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { StatsSaleItemFiltersParams } from '@modules/reports/models'
import { StatsSaleItem } from '@models/reports'
import { formatPrice } from '@helpers/utils'
import { StatsSaleItemType } from '@modules/reports/stats-sale-item/models'
import { resortsMapSelector } from '@store/selectors/dashboard'
import Table, { TableHeaderValue } from '@components/table/table'
import { useAppSelector } from '@store/index'

interface StatsSaleItemsTableProps {
  statsStaleItems: StatsSaleItem[]
  setFilters: (filters: StatsSaleItemFiltersParams) => void
  filters: StatsSaleItemFiltersParams
  category?: StatsSaleItemType
  isLoading: boolean
}

const headerValuesItems: TableHeaderValue[] = [
  {
    sortField: 'sell_date',
    title: 'Data sprzedaży',
  },
  {
    sortField: 'quantity',
    title: 'Ilość sztuk',
  },
  {
    sortField: 'seller',
    title: 'Sprzedawca',
  },
  {
    sortField: 'category',
    title: 'Kategoria',
  },
  {
    sortField: 'resort__name',
    title: 'Ośrodek',
  },
  {
    sortField: 'name',
    title: 'Produkt',
  },
  {
    sortField: 'total_price_brutto',
    title: 'Kwota brutto',
  },
]

const headerValuesBooking: TableHeaderValue[] = [
  {
    sortField: 'sell_date',
    title: 'Data sprzedaży',
  },
  {
    sortField: 'seller',
    title: 'Sprzedawca',
  },
  {
    sortField: 'resort__name',
    title: 'Ośrodek',
  },
  {
    sortField: 'number',
    title: 'Numer rezerwacji',
  },
  {
    sortField: 'promotion_marketing',
    title: 'Promocja marketingowa',
  },
  {
    sortField: 'source_marketing',
    title: 'Źródło marketingowa',
  },
  {
    sortField: 'recommender_client',
    title: 'Polecający',
  },
  {
    sortField: 'total_price_brutto',
    title: 'Kwota brutto',
  },
]

export const StatsSaleItemsTable: React.FC<StatsSaleItemsTableProps> = ({
  setFilters,
  statsStaleItems,
  filters,
  category,
  isLoading,
}) => {
  const resortsMap = useAppSelector(resortsMapSelector)

  if (category === StatsSaleItemType.Booking) {
    return (
      <Table
        className="table-hover w-100"
        headerValues={headerValuesBooking}
        isLoading={isLoading}
        filters={filters}
        setFilters={setFilters}
      >
        {statsStaleItems.map(item => (
          <Table.Row key={item.id}>
            <Table.Cell>{toDefaultDateFormat(item.sell_date)}</Table.Cell>
            <Table.Cell>{formatPrice(item.seller)}</Table.Cell>
            <Table.Cell>{resortsMap[item.resort_id]?.name || 'brak'}</Table.Cell>
            <Table.Cell>{formatPrice(item.number)}</Table.Cell>
            <Table.Cell>{formatPrice(item.promotion_marketing)}</Table.Cell>
            <Table.Cell>{formatPrice(item.source_marketing)}</Table.Cell>
            <Table.Cell>{formatPrice(item.recommender_client)}</Table.Cell>
            <Table.Cell>{formatPrice(item.total_price_brutto)}</Table.Cell>
          </Table.Row>
        ))}
      </Table>
    )
  }

  return (
    <Table
      className="table-hover w-100"
      headerValues={headerValuesItems}
      isLoading={isLoading}
      filters={filters}
      setFilters={setFilters}
    >
      {statsStaleItems.map(item => (
        <Table.Row key={item.id}>
          <Table.Cell>{toDefaultDateFormat(item.sell_date)}</Table.Cell>
          <Table.Cell>{formatPrice(item.quantity)}</Table.Cell>
          <Table.Cell>{formatPrice(item.seller)}</Table.Cell>
          <Table.Cell>{formatPrice(item.category)}</Table.Cell>
          <Table.Cell>{resortsMap[item.resort_id]?.name || 'brak'}</Table.Cell>
          <Table.Cell>{formatPrice(item.name)}</Table.Cell>
          <Table.Cell>{formatPrice(item.total_price_brutto)}</Table.Cell>
        </Table.Row>
      ))}
    </Table>
  )
}
