import * as React from 'react'
import { FormInput } from '@hyper/forms'
import { FormHousekeepingOrderWorkerAssignField } from '@modules/housekeeping/cleaning-orders/common/form-housekeeping-order-worker-assign-field'
import { FormSelect } from '@hyper/forms/form-select'
import { IconWithText } from '@components/icon-with-text'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { createSelectOption } from '@helpers/utils'
import { FormCheckbox } from '@hyper/forms/form-checkbox'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { Row } from 'reactstrap'
import { useFormContext, useWatch } from 'react-hook-form'
import { CreateCleaningOrderModalFormInputs } from '@modules/housekeeping/cleaning-orders/create-cleaning-order/create-cleaning-order-modal'

export const HousekeepingOrderServiceKindOptions = [
  createSelectOption('Sprzątanie', 'CLEANING'),
  createSelectOption('Odświeżenie', 'REFRESH'),
]

interface Props {
  apartmentId?: number
  isReadonly: boolean
  apartments: CustomReactSelectOption[]
  resortOptions: CustomReactSelectOption[]
}

export const CreateCleaningOrderModalForm: React.FC<Props> = ({
  apartments,
  apartmentId,
  isReadonly,
  resortOptions,
}) => {
  const user = useAuthenticatedUser()

  const hasMultipleResorts = user?.resorts.length > 1
  const { control } = useFormContext<CreateCleaningOrderModalFormInputs>()

  const [withWorkerAssign, selectedResort] = useWatch({ control, name: ['with_worker_assign', 'resort.value'] })

  return (
    <>
      <FormSelect
        options={resortOptions}
        name="resort"
        label="Ośrodek"
        labelSize={4}
        selectProps={{ isDisabled: !hasMultipleResorts || isReadonly }}
      />

      <FormSelect
        labelSize={4}
        options={apartments}
        name="apartment"
        label="Lokal"
        selectProps={{
          placeholder: <IconWithText icon="uil-search" text="Numer lokalu" />,
          isDisabled: !!apartmentId || isReadonly,
        }}
      />

      <FormSelect
        labelSize={4}
        options={HousekeepingOrderServiceKindOptions}
        name="service_kind"
        label="Typ zadania"
        selectProps={{
          placeholder: <IconWithText icon="uil-search" text="Lokal numer 48" />,
          isDisabled: !!apartmentId,
        }}
      />
      <div className="col-12">
        <FormCheckbox
          className="col-8 ml-auto pl-4"
          name="high_priority"
          label={
            <IconWithText
              direction="right"
              icon="uil-rocket text-danger font-16 ml-1"
              text="Zadanie pilne"
              textClass="fw-bold"
            />
          }
        />
      </div>
      <hr className="w-100 my-3" />
      <FormInput labelSize={4} name="comment" label="Notatki" type="textarea" placeholder="Dodaj notatkę" />
      <hr className="w-100 mt-2 mb-3" />

      <Row className="w-100 mx-0">
        <strong className="col-4">Przydziel to zadanie</strong>
        <div className="col-8">
          <FormCheckbox
            name="with_worker_assign"
            label="Chcę przydzielić zadanie"
            helpText={<span className="font-10 text-muted d-block ml-3">(opcjonalnie)</span>}
          />
          {withWorkerAssign && (
            <div className="mt-2">
              <FormHousekeepingOrderWorkerAssignField label="Firma sprzątająca" resortId={selectedResort} />
            </div>
          )}
        </div>
      </Row>
    </>
  )
}
