import * as React from 'react'
import { formatPrice } from '@helpers/utils'
import { CashRegisterAddModalToggle } from '@modules/sale/cash-register/list/add-modal/toggle'
import { SafeboxStats } from '@models/payments'

interface Props {
  resortId: number
  safeboxStats: SafeboxStats
  onSuccess: () => void
}

export const CashboxSafeModalActions = ({ resortId, safeboxStats, onSuccess }: Props): JSX.Element => (
  <div className="d-flex align-items-center justify-content-end mb-2">
    <CashRegisterAddModalToggle
      addPoints={false}
      mode="deposit"
      className="mr-2"
      resortId={resortId}
      onSuccess={onSuccess}
    />
    <CashRegisterAddModalToggle
      addPoints={false}
      mode="withdraw"
      className="mr-2"
      resortId={resortId}
      onSuccess={onSuccess}
    />
    <div className="px-4 py-3 bg-dark-light text-white text-right col-auto ml-left">
      <h4 className="my-0">Stan sejfu: {formatPrice(safeboxStats.total_amount)}</h4>
    </div>
  </div>
)
