import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import { FormProvider, useForm } from 'react-hook-form'
import { SaveButton } from '@hyper/button'
import { useFormRequest } from '@components/hooks/use-api-request'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { ReceptionBookingDetails, WalletTransaction } from '@models/reception'
import { useAppDispatch } from '@store/index'
import { updateReceptionBookingDetails } from '@store/actions/reception-actions'
import { BookingAutocomplete } from '@components/booking-autosuggest'
import { getWalletTransactions } from '@store/actions/reservation-actions'

interface Props extends BaseModalProps {
  transaction: WalletTransaction
  booking: ReceptionBookingDetails
}

interface FormInputs {
  new_booking: string
}

export const ReservationRoomBillTransferModal: React.FC<Props> = ({ booking, toggleIsVisible, transaction }) => {
  const methods = useForm<FormInputs>()
  const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    dispatch(
      updateReceptionBookingDetails(await commonObjectPost<ReceptionBookingDetails>(transaction.urls.move, payload)),
    )
    dispatch(await getWalletTransactions(booking))

    addSuccessMessage()
    toggleIsVisible()
  }, methods.setError)

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggleIsVisible}>Przenieś rachunek</ModalHeader>
        <ModalBody>
          <Row>
            <BookingAutocomplete type="present" name="new_booking" label="Numer rezerwacji" />
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={toggleIsVisible}>
            Zamknij
          </Button>
          <SaveButton
            role="submit"
            className="btn btn-green"
            label="Przenieś"
            isSaving={isLoading}
            labelSaving="Przenoszenie"
          />
        </ModalFooter>
      </form>
    </FormProvider>
  )
}
