import * as React from 'react'
import * as R from 'ramda'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useDebounce, usePreviousImmediate } from 'rooks'

interface FiltersWithPage {
  page?: number
}

interface UseTableFiltersProps<T, R> {
  defaultFilters: T
  action: (filters: T) => Promise<R>
  deps?: React.DependencyList
}

interface UseTableFiltersResponse<T> {
  setFilters: (filters: T) => void
  updateFiltersRequest: (filters: T) => void
  isLoading: boolean
  filters: T
}

export const useTableFilters = <T extends FiltersWithPage, R = unknown>({
  defaultFilters,
  action,
  deps,
}: UseTableFiltersProps<T, R>): UseTableFiltersResponse<T> => {
  const [filters, setFilters] = React.useState<T>(defaultFilters)

  const { isLoading, action: updateFiltersRequest } = useApiRequest((filters: T) => action(filters))

  const previousFilters = usePreviousImmediate(filters)

  const debouncedUpdate = useDebounce(updateFiltersRequest, 400)
  const memoizedUpdate = React.useCallback(debouncedUpdate, [...(deps ?? []), action])

  React.useEffect(() => {
    if (!R.hasIn('page', filters)) {
      memoizedUpdate(filters)
    }

    const previousFiltersWithoutPage = R.omit('page', previousFilters)
    const currentFiltersWithoutPage = R.omit('page', filters)
    const shouldSetFirstPage =
      !R.equals(previousFiltersWithoutPage, currentFiltersWithoutPage) && filters?.page === previousFilters?.page
    const newFilters = shouldSetFirstPage ? { ...filters, page: 1 } : filters

    memoizedUpdate(newFilters)
  }, [filters])

  return {
    setFilters,
    updateFiltersRequest,
    isLoading,
    filters,
  }
}
