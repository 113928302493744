import * as React from 'react'
import { ScanningStatus } from '@components/band-scanning/band-scanning'
import classNames from 'classnames'
import { KeyboardScanner } from '@components/keyboard-scanner'
import { commonObjectGet } from '@store/actions/generic-actions'
import { RootState } from '@store/index'
import { useSelector } from 'react-redux'
import { BandDetails } from '@models/band'
import { WalletScope } from '@models/shop'
import { formatPriceShort } from '@helpers/utils'
import { formatDate } from '@helpers/date-helper'

interface Props {
  bandDetails: BandDetails | undefined
  onBandDetailsChange: (bandDetails: BandDetails | undefined) => void
}

export const PurchaseBandPaymentContent: React.FC<Props> = ({ bandDetails, onBandDetailsChange }) => {
  const [scanningStatus, setScanningStatus] = React.useState(ScanningStatus.SCANNING)
  const url = useSelector((state: RootState) => state.appState.appData.urls.bands.band_details)

  const onScanComplete = async external_id => {
    onBandDetailsChange(undefined)
    try {
      const result = await commonObjectGet<BandDetails>(url, { external_id, scope: WalletScope.SHOP })
      onBandDetailsChange(result)
      setScanningStatus(ScanningStatus.FOUND)
    } catch (error) {
      setScanningStatus(ScanningStatus.NOT_FOUND)
    }
  }

  const bandIcon = icon => (
    <div className="position-relative">
      <i className="icon-opaska text-success shop-purchase__band-reader__external-icon" />
      <i className={classNames('shop-purchase__band-reader__internal-icon', icon)} />
    </div>
  )

  const info = (icon, text) => (
    <>
      {bandIcon(icon)}
      <span className="d-block text-muted font-weight-bold font-14 ml-3">{text}</span>
    </>
  )

  const guestDataRow = (name, value) => (
    <div className="mb-2 d-flex flex-wrap">
      <span className="mr-1">{name}</span>
      <span className="text-semi-strong">{value}</span>
    </div>
  )

  return (
    <div className="d-flex align-items-center">
      <KeyboardScanner onScanComplete={onScanComplete} />
      {scanningStatus === ScanningStatus.SCANNING && info('uil-capture', 'Skanuję opaskę')}
      {scanningStatus === ScanningStatus.NOT_FOUND && info('uil-times', 'Nie znaleziono opaski')}
      {scanningStatus === ScanningStatus.FOUND && !!bandDetails && (
        <>
          <div>{bandIcon('uil-check')}</div>
          <div className="ml-3 w-50">
            {guestDataRow('Numer lokalu:', bandDetails.booking.apartment.name)}
            {guestDataRow('Imię i nazwisko:', <a href={bandDetails.booking.urls.details}>{bandDetails.guest.name}</a>)}
            {guestDataRow(
              'Data pobytu:',
              <>
                {formatDate(bandDetails.booking.date_from, 'dd.MM')}-{formatDate(bandDetails.booking.date_to, 'dd.MM')}
              </>,
            )}
            {guestDataRow('Dostępne środki', formatPriceShort(bandDetails.value_limit))}
          </div>
        </>
      )}
    </div>
  )
}
