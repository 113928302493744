import * as React from 'react'
import classNames from 'classnames'

interface Props {
  className?: string
  icon: string
  title?: string
  variant?: 'success' | 'danger' | 'info' | 'warning' | 'danger-dark' | 'light'
  onClick?: () => void
}

export const BadgeWithIcon: React.FC<Props> = ({ className, icon, title, variant, onClick }) => (
  <span
    className={classNames(className, 'badge', {
      'bg-danger text-white': variant === 'danger-dark',
      'badge-success-lighten': variant === 'success',
      'badge-danger-lighten': variant === 'danger',
      'badge-info-lighten': variant === 'info',
      'badge-warning-lighten': variant === 'warning',
      'badge-light': variant === 'light',
    })}
    onClick={onClick}
  >
    <i className={classNames(icon, { 'mr-1': title })} />
    {title}
  </span>
)
