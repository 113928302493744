import * as React from 'react'
import { UpsellBookingDetailsReservationBookingData } from '@modules/upselling/upsell-booking-details/reservation-data/reservation-booking-data'
import { UpsellBookingDetailsReservationGuestData } from '@modules/upselling/upsell-booking-details/reservation-data/guest-data'
import { UpsellBookingDetailsReservationExpandableGuests } from '@modules/upselling/upsell-booking-details/reservation-data/expandable-guests'
import { UpsellBookingDetailsActions } from '@modules/upselling/upsell-booking-details/reservation-data/actions-row'
import { UpSellBookingDetails } from '@models/upselling'

interface Props {
  upsellBooking: UpSellBookingDetails
  onSaleClick: () => void
}

export const UpsellBookingDetailsReservationData = ({ upsellBooking, onSaleClick }: Props): JSX.Element => (
  <div className="bg-white pt-3 mx-n3">
    <div className="row px-3">
      <UpsellBookingDetailsReservationBookingData upsellBookingDetails={upsellBooking} />
      <UpsellBookingDetailsReservationGuestData booking={upsellBooking.booking} client={upsellBooking.client} />
      <UpsellBookingDetailsActions upsellBooking={upsellBooking} onSaleClick={onSaleClick} />
    </div>
    <UpsellBookingDetailsReservationExpandableGuests
      guests={upsellBooking.booking.guests}
      resortId={upsellBooking.booking.resort_id}
    />
  </div>
)
