import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { FormInput } from '@hyper/forms'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { asDecimal, formatPrice } from '@helpers/utils'
import { ReceptionBookingCartPaymentBaseActions } from '@modules/reception/cart/payment-box/payment-options-content/reception-booking-cart-payment-base-actions'
import { useFormRequest } from '@components/hooks/use-api-request'
import { commonObjectPost } from '@store/actions/generic-actions'
import { CartSelection } from '@modules/reception/cart/payment-box/reception-booking-cart-payment-box'
import { ReceptionBookingDetailsCart } from '@models/reception'
import { BaseCartPaymentResponse } from '@modules/reception/cart/models'

interface FormInputs {
  amount: string
}

interface Props {
  onDecline: () => void
  url: string
  cartSelection: CartSelection
  onCartUpdate: (cart: ReceptionBookingDetailsCart) => void
}

export const ReceptionBookingCartPaymentCash = ({
  onDecline,
  cartSelection,
  url,
  onCartUpdate,
}: Props): JSX.Element => {
  const methods = useForm<FormInputs>({ defaultValues: { amount: cartSelection.amount } })

  const amount = useWatch({ control: methods.control, name: 'amount' })

  const change = asDecimal(amount || 0).gt(cartSelection.amount)
    ? asDecimal(amount || 0)
        .minus(cartSelection.amount)
        .toString()
    : 0

  const { action: handlePay, isLoading } = useFormRequest(async () => {
    const response = await commonObjectPost<BaseCartPaymentResponse>(url, {
      ...cartSelection,
      amount: asDecimal(amount).minus(change).toString(),
    })

    onCartUpdate(response.cart_details)
    onDecline()
  }, methods.setError)

  const isEnteredPriceCorrect = asDecimal(amount || 0).gte(cartSelection.amount)

  return (
    <FormProvider {...methods}>
      <div className="d-flex justify-content-between align-items-center font-weight-bold mb-3">
        <IconWithText icon="uil-money-bill font-14" text="Płatność gotówką:" wrapperClassNames="text-primary" />
        <span className="text-danger">{formatPrice(cartSelection.amount)}</span>
      </div>
      <FormInput
        label="Przyjęta gotówka"
        name="amount"
        type="currency"
        formGroupClassName="row align-items-center px-0"
        labelProps={{ className: 'col-7 px-0 mb-0' }}
        inputClassName="col-5 text-right"
        autoComplete="off"
      />
      <div className="d-flex justify-content-between align-items-center font-weight-bold">
        <strong>Reszta dla Gościa:</strong>
        <span className="text-success">{formatPrice(change)}</span>
      </div>
      <ReceptionBookingCartPaymentBaseActions
        isSaving={isLoading}
        onDecline={onDecline}
        onConfirm={handlePay}
        confirmDisabled={!isEnteredPriceCorrect}
      />
    </FormProvider>
  )
}
