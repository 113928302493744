import * as React from 'react'
import { formatPrice } from '@helpers/utils'

interface Props {
  price: string
}

export const ReceptionBookingCartPaymentBoxPrice = ({ price }: Props): JSX.Element => (
  <div className="bg-dark-light rounded py-2 mt-3 pl-3 border-radius-bottom-0">
    <strong className="text-white font-18">
      Do zapłaty:
      <span className="font-20"> {formatPrice(price)}</span>
    </strong>
  </div>
)
