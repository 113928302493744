import * as React from 'react'
import {
  ReceptionBooking,
  ReceptionCheckoutValidationResponse,
  ReceptionCheckoutValidationSubscription,
} from '@models/reception'
import { Button, ModalFooter, ModalHeader } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { BaseModalProps } from '@components/modals/types'
import { ReceptionBookingCheckOutSubscriptionBookingImprovementRow } from '@modules/reception/checkout/checkout-unavailable/reception-booking-check-out-subscription-booking-improvement-row'
import { SaveButton } from '@hyper/button'
import { commonObjectGet } from '@store/actions/generic-actions'
import { useApiRequest } from '@components/hooks/use-api-request'

interface Props extends BaseModalProps {
  booking: ReceptionBooking
  data: ReceptionCheckoutValidationResponse
  onConfirm: () => void
}

export const ReceptionBookingCheckOutUnavailableModal: React.FC<Props> = ({
  booking,
  toggleIsVisible,
  onConfirm,
  data,
}) => {
  const [checkoutData, setCheckoutData] = React.useState<ReceptionCheckoutValidationResponse>(data)

  const { action: refresh, isLoading: isRefreshing } = useApiRequest(async () => {
    setCheckoutData(await commonObjectGet<ReceptionCheckoutValidationResponse>(booking.urls.check_out_validate))
  })

  const handleConfirm = () => {
    onConfirm()
    toggleIsVisible()
  }

  const { ownerSubscription, guestSubscriptions } = checkoutData.subscriptions.reduce(
    (prev, subscription) => {
      if (subscription.subscription.email === booking.email) return { ...prev, ownerSubscription: subscription }
      return { ...prev, guestSubscriptions: [...prev.guestSubscriptions, subscription] }
    },
    { ownerSubscription: null, guestSubscriptions: [] },
  )

  console.log({ checkoutData })

  return (
    <>
      <ModalHeader className="bg-danger text-white">Uwaga</ModalHeader>
      <ModalBody className="overflow-hidden">
        <strong className="d-block mb-1">
          Właściciel rezerwacji posiada niezaakceptowane regulaminy związane z deklaracją zakupu Voucherów Super Start.
        </strong>

        {ownerSubscription && (
          <>
            <p className="mt-3 mb-0">
              {!ownerSubscription.has_rules_accepted && (
                <span>
                  Poproś <strong>właściciela</strong>, aby zaakceptował regulaminy teraz lub usuń ulepszenie.
                </span>
              )}
            </p>
            <ul className="list-unstyled">
              <ReceptionBookingCheckOutSubscriptionBookingImprovementRow
                onRefresh={refresh}
                booking={booking}
                key={ownerSubscription.subscription.id}
                validationSubscription={ownerSubscription}
              />
            </ul>
          </>
        )}
        {!!guestSubscriptions.length && (
          <div>
            <hr className="mt-0 mb-2 mx-n3" />
            <p className="mb-0">
              Goście z tej rezerwacji, którzy również zadeklarowali chęć zakupu. <br />
            </p>
            <ul className="list-unstyled">
              {guestSubscriptions.map((validationSubscription: ReceptionCheckoutValidationSubscription) => (
                <ReceptionBookingCheckOutSubscriptionBookingImprovementRow
                  onRefresh={refresh}
                  booking={booking}
                  key={validationSubscription.subscription.id}
                  validationSubscription={validationSubscription}
                />
              ))}
              {!checkoutData.subscriptions.length && <li>Brak ulepszeń do zaakceptowania. Możesz przejść dalej.</li>}
            </ul>
          </div>
        )}
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <SaveButton
          isSaving={isRefreshing}
          label="Odśwież"
          onClick={refresh}
          labelSaving="Odświeżanie..."
          className="btn btn-light"
          spinnerProps={{ className: 'avatar-3xs' }}
        />

        <div>
          <Button color="light" onClick={toggleIsVisible}>
            Anuluj
          </Button>
          <Button
            className="btn btn-green ml-1"
            onClick={handleConfirm}
            disabled={!ownerSubscription?.has_rules_accepted}
          >
            Gotowe
          </Button>
        </div>
      </ModalFooter>
    </>
  )
}
