import {
  DashboardActionTypesKeys,
  SetDashboardStandardPaginatorAction,
} from '@store/actions-types/dashboard-actions-types'
import { ContentNote, DashboardStandardPaginator } from '@models/dashboard'
import { commonObjectPatch, commonObjectPost } from '@store/actions/generic-actions'
import axios from 'axios'
import { multiPartHeaders } from '@api/base'

export async function saveNote(
  url: string,
  contentType: number,
  objectId: number,
  content: string,
): Promise<ContentNote> {
  return await commonObjectPost<ContentNote>(url, {
    content_type: contentType,
    object_id: objectId,
    content,
  })
}

export async function updateNote(url: string, payload: Partial<ContentNote>): Promise<ContentNote> {
  return await commonObjectPatch<ContentNote>(url, payload)
}

export const createDocuments = async (payload: { files: File[]; description?: string; url: string }): Promise<void> => {
  payload.files.forEach(file => {
    const formData = new FormData()

    formData.append('file', file)
    formData.append('file_name', file.name)

    if (payload.description) {
      formData.append('description', payload.description)
    }

    axios.post(payload.url, formData, {
      headers: multiPartHeaders,
    })
  })
}

export const emptyPaginator = {
  count: 0,
  next: null,
  previous: null,
  page_link: '',
}

export function setDashboardStandardPaginator(
  payload?: Partial<DashboardStandardPaginator> | undefined,
): SetDashboardStandardPaginatorAction {
  return {
    type: DashboardActionTypesKeys.SET_STANDARD_PAGINATOR,
    payload: {
      count: payload?.count || 0,
      next: payload?.next || null,
      previous: payload?.previous || null,
      page_link: payload?.page_link || '',
    },
  }
}
