import * as React from 'react'
import { PackageVipDetails } from '@modules/promotions/package-vip/models'
import { useAppDispatch } from '@store/index'
import { FormProvider, useForm } from 'react-hook-form'
import { updatePackageVipDetails } from '@store/slices/package-vip-slice'
import { Button, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { SaveButton } from '@hyper/button'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useFormRequest } from '@components/hooks/use-api-request'
import { BaseModalProps } from '@components/modals/types'
import { commonObjectPost } from '@store/actions/generic-actions'
import { BookingAutocomplete } from '@components/booking-autosuggest'

interface PackageVipAssignmentDialogProps extends BaseModalProps {
  packageVipDetails: PackageVipDetails
}

interface FormInputs {
  booking: string
}

export const PackageVipAssignmentDialog: React.FC<PackageVipAssignmentDialogProps> = ({
  packageVipDetails,
  toggleIsVisible,
}) => {
  const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()

  const methods = useForm<FormInputs>()

  const { isLoading, action: onSubmit } = useFormRequest(
    async payload => {
      const data = await commonObjectPost<PackageVipDetails>(packageVipDetails.urls.package_vip_assignment, {
        ...payload,
        package_vip: packageVipDetails.id,
      })
      dispatch(updatePackageVipDetails(data))
      addSuccessMessage('Sukces', 'Rezerwacja została przypisana')
      toggleIsVisible()
    },
    methods.setError,
    { showGlobalError: true },
  )

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggleIsVisible}>Przypisz rezerwacje</ModalHeader>
        <ModalBody>
          <Row>
            <BookingAutocomplete type="present" name="booking" label="Numer rezerwacji" />
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={toggleIsVisible}>
            Zamknij
          </Button>
          <SaveButton label="Zapisz" isSaving={isLoading} />
        </ModalFooter>
      </form>
    </FormProvider>
  )
}
