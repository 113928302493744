import * as React from 'react'
import { CleaningOrder, CleaningOrderDetails, HousekeepingCleaningOrderType } from '@modules/housekeeping/models'
import { Button } from 'reactstrap'
import { getHousekeepingCleaningOrders } from '@store/actions/housekeeping-actions'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { OrderNotes } from '@modules/housekeeping/cleaning-orders/common/row-notes'
import { useModal } from '@components/modals/use-modal'
import { useApiRequest } from '@components/hooks/use-api-request'
import { updateHousekeepingCleaningOrderDetails } from '@store/slices/housekeeping-slice'

interface HousekeepingCleaningOrderToCheckRowActionsProps {
  cleaningOrder: CleaningOrder
}

export const HousekeepingCleaningOrderToCheckRowActions: React.FC<HousekeepingCleaningOrderToCheckRowActionsProps> = ({
  cleaningOrder,
}) => {
  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()

  const [toggleIsDetailsOpen] = useModal('HousekeepingCleaningOrderDetailsModal', {
    cleaningOrder,
  })
  const [handleReopen] = useModal('HousekeepingCleaningOrderToCheckReopen', {
    cleaningOrder,
  })

  const { action: handleAccept } = useApiRequest(async () => {
    await dispatch(
      updateHousekeepingCleaningOrderDetails(await commonObjectPost<CleaningOrderDetails>(cleaningOrder.urls.accept)),
    )
    await dispatch(
      getHousekeepingCleaningOrders([
        {
          resort: cleaningOrder.resort,
        },
        HousekeepingCleaningOrderType.TO_CHECK,
      ]),
    )

    addSuccessNotification('Zlecenie zostało zaakceptowane')
  })

  return (
    <td className="housekeeping__orders_table__column text-nowrap text-right" style={{ width: 400 }}>
      <OrderNotes order={cleaningOrder} />

      <Button color="green" onClick={handleAccept} className="btm-sm">
        Akceptuj
      </Button>

      <Button color="danger" onClick={handleReopen} className="mx-1 btn-sm">
        Do poprawy
      </Button>

      <Button color="light" onClick={toggleIsDetailsOpen} className="btn-sm">
        Szczegóły
      </Button>
    </td>
  )
}
