import * as React from 'react'
import { FormCheckbox } from '@hyper/forms/form-checkbox'
import { useFormContext, useWatch } from 'react-hook-form'
import classNames from 'classnames'
import { useAppSelector } from '@store/index'
import { resortsMapSelector } from '@store/selectors/dashboard'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'

interface Props {
  resortId: number
}

export const TechnicalOrderAssignFormForwardToResort: React.FC<Props> = ({ resortId }) => {
  const { setValue, control } = useFormContext()
  const resort = useAppSelector(resortsMapSelector)[resortId]

  const users = useWatch({ control, name: 'users' })
  const hasUsers = Object.values(users).some(Boolean)

  const forwardedToResort = useWatch({ control, name: 'forwarded_to_resort' })

  useDidUpdateEffect(() => {
    if (forwardedToResort) {
      setValue('company', null)
      setValue('users', {})
      setValue('forwarded_to_technical_order_manager', false)
    }
  }, [forwardedToResort])

  return (
    <div className={classNames({ 'not-clickable': hasUsers })}>
      <div className="font-weight-bold mt-3 mb-1">Lub przekaz zadanie</div>
      <FormCheckbox
        disabled={hasUsers}
        name="forwarded_to_resort"
        label={`do Resortu ${resort?.name}`}
        value={true}
        className="mb-1"
      />
    </div>
  )
}
