import * as React from 'react'
import { Button, CustomInput, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useFormRequest } from '@components/hooks/use-api-request'
import { useForm } from 'react-hook-form'
import { useNotificationHook } from '@hyper/use-notification-hook'
import ModalBody from 'reactstrap/lib/ModalBody'
import { FormInput, FormPlain } from '@hyper/forms'
import { commonObjectPatch } from '@store/actions/generic-actions'
import { useAppDispatch } from '@store/index'
import { PackageCode, PackageDetails } from '@models/package'
import * as R from 'ramda'
import { extractInnerRef } from '@helpers/forms'
import { BaseModalProps } from '@components/modals/types'
import { updatePackageDetails } from '@store/slices/package-slice'
import { Form } from '@hyper/forms/form'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'

interface Props extends BaseModalProps {
  packageCode: PackageCode
  packageDetails: PackageDetails
}

interface FormInputs {
  name: string
  description: string
  is_active: boolean
  kind: 'apartment' | 'house' | 'both'
}

export const PackageCodeEditModal: React.FC<Props> = ({ packageCode, toggleIsVisible, packageDetails }) => {
  const methods = useForm<FormInputs>({
    defaultValues: R.pick<FormInputs, keyof FormInputs>(['name', 'description', 'is_active', 'kind'], packageCode),
  })
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()
  const user = useAuthenticatedUser()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    dispatch(updatePackageDetails(await commonObjectPatch(packageCode.urls.details, payload)))
    addSuccessMessage('Sukces', `Kod ${packageCode.code} został zmieniony!`)
    toggleIsVisible()
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>
        Edycja kodu <span className="text-semi-strong">{packageCode.code}</span>
      </ModalHeader>
      <ModalBody>
        <Row>
          {user.hasPerm(
            packageDetails.package_type === 'HPI'
              ? UserPermission.PackageHpiCanChangeCodeName
              : UserPermission.PackageCanChangeCodeName,
          ) && <FormInput label="Imię i nazwisko" name="name" type="text" />}
          <FormInput label="Opis" name="description" type="text" />
          {user.hasPerm(
            packageDetails.package_type === 'HPI'
              ? UserPermission.PackageHpiCanChange
              : UserPermission.PackageCanChange,
          ) && (
            <FormInput label="Rodzaj" name="kind" type="select">
              <option value="apartment">Apartament</option>
              <option value="house">Domek</option>
              <option value="both">Wszystkie typy</option>
            </FormInput>
          )}
          {user.hasPerm(
            packageDetails.package_type === 'HPI'
              ? UserPermission.PackageHpiCanChangeCodeActive
              : UserPermission.PackageCanChangeCodeActive,
          ) && (
            <FormPlain name="is_active" colClassName="mb-2">
              <CustomInput
                className="mt-2"
                type="checkbox"
                {...extractInnerRef(methods.register('is_active'))}
                id="is_active"
                label="Aktywny?"
              />
            </FormPlain>
          )}
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>

        <SaveButton className="btn btn-green" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
