import * as React from 'react'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { NotificationBarWebSocketHandler } from '@components/topbar/notification-bar/notification-bar-web-socket-handler'
import { SystemNotificationRow } from '@components/topbar/notification-bar/system-notification-row'
import { systemNotificationRead, systemNotificationRemove } from '@store/actions/app-actions'
import classNames from 'classnames'
import { systemNotificationsSelector } from '@store/slices/app-slice'
import { compareDesc } from 'date-fns'
import { parseISODate } from '@helpers/date-helper'

export const NotificationBar: React.FC = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  const dispatch = useAppDispatch()

  const systemNotificationMassApiUrl = useAppSelector(
    (state: RootState) => state.appState.appData.urls.dashboard.system_notification_mass_api,
  )
  const systemNotifications = useAppSelector(systemNotificationsSelector)

  const sortedSystemNotifications = React.useMemo(
    () =>
      systemNotifications.sort((a, b) => compareDesc(parseISODate(a.created) as Date, parseISODate(b.created) as Date)),
    [systemNotifications],
  )
  const unreadNotifications = React.useMemo(
    () => systemNotifications.filter(notification => !notification.read),
    [systemNotifications],
  )

  const handleRemoveAll = () => dispatch(systemNotificationRemove(systemNotificationMassApiUrl))

  const handleReadAll = () => dispatch(systemNotificationRead([systemNotificationMassApiUrl, false]))

  const toggleDropdown = () => setIsOpen(!isOpen)

  return (
    <div className="notification-bar">
      <NotificationBarWebSocketHandler />
      <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
        <DropdownToggle
          data-toggle="dropdown"
          tag="button"
          className="nav-link dropdown-toggle arrow-none btn btn-link"
          onClick={toggleDropdown}
        >
          <i
            className={classNames('uil-bell noti-icon', {
              'text-dark': !!unreadNotifications.length,
            })}
          />

          {!!unreadNotifications.length && (
            <span className="noti-icon-badge">
              {unreadNotifications.length > 9 ? ';)' : unreadNotifications.length}
            </span>
          )}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-animated topbar-dropdown-menu profile-dropdown" right={true}>
          <div>
            <div className="dropdown-item noti-title">
              <h5 className="m-0">
                <span className="float-right">
                  <span className="text-dark">
                    {unreadNotifications.length ? (
                      <small onClick={handleReadAll} className="cursor-pointer">
                        Oznacz jako przeczytane
                      </small>
                    ) : (
                      <small onClick={handleRemoveAll} className="cursor-pointer">
                        Wyczyść
                      </small>
                    )}
                  </span>
                </span>
                Powiadomienia
              </h5>
            </div>
            <div className="notification-bar__wrapper">
              {sortedSystemNotifications.map(systemNotification => (
                <SystemNotificationRow key={systemNotification.id} systemNotification={systemNotification} />
              ))}
              {!systemNotifications.length && <p className="text-center pt-2">Brak powiadomień ;-(</p>}
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}
