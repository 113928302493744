import { ThunkAction } from 'redux-thunk'
import { RootState } from '@store/index'
import { commonObjectGet } from '@store/actions/generic-actions'
import {
  CmsActionTypesKeys,
  SetCmsAppDataAction,
  SetCmsEmailTemplateDetailsAction,
  SetCmsEmailTemplatesAction,
  SetCmsSmsTemplatesAction,
} from '@store/actions-types/cms-actions-types'
import { CmsAppData } from '@models/cms'
import { EmailTemplate, EmailTemplateDetails, SmsTemplate } from '@modules/cms/models'
import { TableFilters } from '@components/table/table'

export function getCmsAppData(): ThunkAction<Promise<CmsAppData>, RootState, null, SetCmsAppDataAction> {
  return async (dispatch, getState) => {
    const url = getState().appState.appData.urls.cms.app_data
    const appData = getState().cmsState.appData
    if (appData.status === 'unknown') {
      dispatch(setCmsAppData({ ...appData, status: 'loading' }))

      const data = await commonObjectGet<CmsAppData>(url)

      dispatch(setCmsAppData({ ...data, status: 'ready' }))
      return data
    }
    return appData
  }
}

export function setCmsAppData(payload: CmsAppData): SetCmsAppDataAction {
  return { type: CmsActionTypesKeys.SET_APP_DATA, payload }
}

export function getNotificationsSmsTemplatesData(
  params: TableFilters,
): ThunkAction<Promise<void>, RootState, null, SetCmsSmsTemplatesAction> {
  return async (dispatch, getState) => {
    const url = getState().appState.appData.urls.notifications.sms_templates

    const data = await commonObjectGet<SmsTemplate[]>(url, params)

    dispatch(setNotificationsSmsTemplates(data))
  }
}

export function setNotificationsSmsTemplates(payload: SmsTemplate[]): SetCmsSmsTemplatesAction {
  return { type: CmsActionTypesKeys.SET_SMS_TEMPLATES, payload }
}

export function getNotificationsEmailTemplatesData(
  params: TableFilters,
): ThunkAction<Promise<void>, RootState, null, SetCmsEmailTemplatesAction> {
  return async (dispatch, getState) => {
    const url = getState().appState.appData.urls.notifications.email_templates

    const data = await commonObjectGet<EmailTemplate[]>(url, params)

    dispatch(setNotificationsEmailTemplates(data))
  }
}

export function setNotificationsEmailTemplates(payload: EmailTemplate[]): SetCmsEmailTemplatesAction {
  return { type: CmsActionTypesKeys.SET_EMAIL_TEMPLATES, payload }
}

export function updateSmsTemplateDetails(
  payload: SmsTemplate,
): ThunkAction<Promise<void>, RootState, null, SetCmsSmsTemplatesAction> {
  return async (dispatch, getState) => {
    const smsTemplates = getState().cmsState.smsTemplates

    if (!smsTemplates.some(row => row.id === payload.id)) {
      dispatch(setNotificationsSmsTemplates([payload, ...smsTemplates]))
    } else {
      dispatch(setNotificationsSmsTemplates(smsTemplates.map(row => (row.id === payload.id ? payload : row))))
    }
  }
}

export function updateEmailTemplateDetails(
  payload: EmailTemplateDetails,
): ThunkAction<Promise<void>, RootState, null, SetCmsEmailTemplatesAction | SetCmsEmailTemplateDetailsAction> {
  return async (dispatch, getState) => {
    const emailTemplates = getState().cmsState.emailTemplates
    const emailTemplateDetails = getState().cmsState.emailTemplateDetails
    if (payload.id === emailTemplateDetails?.id) {
      dispatch(setEmailTemplateDetails(payload))
    }

    if (!emailTemplates.some(row => row.id === payload.id)) {
      dispatch(setNotificationsEmailTemplates([payload, ...emailTemplates]))
    } else {
      dispatch(setNotificationsEmailTemplates(emailTemplates.map(row => (row.id === payload.id ? payload : row))))
    }
  }
}

export function getEmailTemplateDetails(
  url: string,
): ThunkAction<Promise<void>, RootState, null, SetCmsEmailTemplateDetailsAction> {
  return async dispatch => {
    const emailTemplateDetails = await commonObjectGet<EmailTemplateDetails>(url)
    dispatch(setEmailTemplateDetails(emailTemplateDetails))
  }
}

export function setEmailTemplateDetails(payload: EmailTemplateDetails | undefined): SetCmsEmailTemplateDetailsAction {
  return { type: CmsActionTypesKeys.SET_EMAIL_TEMPLATE_DETAILS, payload }
}
