import * as React from 'react'
import { FormInput, FormPlain } from '@hyper/forms'
import { useTechnicalOrdersAppData } from '@modules/technical-orders/hooks/use-technical-orders-app-data'
import { extractInnerRef } from '@helpers/forms'
import { CustomInput, LabelProps } from 'reactstrap'
import { TechnicalOrderCloseFormInputs } from '@modules/technical-orders/technical-order-close-modal/index'
import { useFormContext } from 'react-hook-form'
import { ApplicationImageUploader } from '@modules/housekeeping/applications/images-uploader'

export const TechnicalOrderCloseFormRejectReason: React.FC = () => {
  const { technical_order_reject_reason } = useTechnicalOrdersAppData()
  const { register } = useFormContext<TechnicalOrderCloseFormInputs>()
  const labelProps: LabelProps = { className: 'px-0' }
  const labelSize = 5
  return (
    <>
      <FormPlain
        name="technical_order_reject_reason"
        label="Wybierz powód odrzucenia:"
        formGroupClassName="px-0"
        labelSize={labelSize}
        labelProps={labelProps}
      >
        <div className="mt-2">
          {technical_order_reject_reason.map(reason => (
            <div className="mb-1" key={reason.id}>
              <CustomInput
                type="radio"
                value={reason.id}
                {...extractInnerRef(register('technical_order_reject_reason'))}
                id={`technical_order_reject_reason-${reason.id}`}
                label={reason.name}
              />
            </div>
          ))}
        </div>
      </FormPlain>
      <FormInput
        formGroupClassName="px-0"
        labelProps={labelProps}
        labelSize={labelSize}
        name="note"
        type="textarea"
        label={
          <>
            Dodaj komentarz<span className="font-10 text-muted d-block">(opcjonalnie)</span>
          </>
        }
        placeholder="Wpisz swój komentarz"
      />
      <FormPlain
        label="Załączniki"
        labelProps={labelProps}
        labelSize={labelSize}
        name="images"
        formGroupClassName="mb-0"
      >
        <ApplicationImageUploader defaultImages={[]} />
      </FormPlain>
    </>
  )
}
