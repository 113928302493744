import * as React from 'react'
import { BookingTouristVoucher, PriceItem } from '@models/reception'
import { formatPrice } from '@helpers/utils'

interface Props {
  touristVouchers: PriceItem<BookingTouristVoucher>
}

export const ReservationBillExpandedTouristVouchers = ({ touristVouchers }: Props): JSX.Element => (
  <table className="table table-sm col-9 ml-auto mb-0 font-12 table-striped">
    <tbody>
      {touristVouchers.items.map((voucher: BookingTouristVoucher) => (
        <tr key={voucher.name}>
          <td>{voucher.name}</td>
          <td className="fw-semi-bold text-right">- {formatPrice(voucher.price_brutto)}</td>
          <td width="50" />
        </tr>
      ))}
    </tbody>
  </table>
)
