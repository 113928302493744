import { useApiRequest } from '@components/hooks/use-api-request'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import * as React from 'react'
import { PackageWholesaleDetails } from '@modules/package-wholesale/models'
import { getPackageWholesaleDetails } from '@store/actions/package-wholesale-actions'
import { ContentNotesCard } from '@components/content-notes/content-notes-card'

interface Props {
  packageWholesaleDetails: PackageWholesaleDetails
}

export const PackageWholesaleDetailsNotes: React.FC<Props> = ({ packageWholesaleDetails }) => {
  const contentType = useAppSelector((state: RootState) => state.appState.appData.content_types.packagewholesale)

  const dispatch = useAppDispatch()

  const { action: handleRefresh } = useApiRequest(
    async () => await dispatch(getPackageWholesaleDetails(packageWholesaleDetails.urls.details)),
  )

  return (
    <ContentNotesCard
      readOnly={false}
      objectId={packageWholesaleDetails.id}
      contentType={contentType}
      onNotesUpdate={handleRefresh}
      notes={packageWholesaleDetails.notes}
    />
  )
}
