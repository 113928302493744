import * as React from 'react'
import { Card, CardBody } from 'reactstrap'
import { UpsellBookingsTabs, UpsellingTab } from '@modules/upselling/common/upsell-bookings-tabs'
import { UpSellBookingDetails } from '@models/upselling'
import { UpsellBookingsDetailsProductRow } from '@modules/upselling/upsell-booking-details/products/products-card/upsell-bookings-details-product-row'
import { UpsellProductsForSale } from '@modules/upselling/upsell-booking-details/products/consts'
import classNames from 'classnames'
import { asDecimal } from '@helpers/utils'

type UpsellBookingProductsTab = 'active' | 'used'

const Tabs = [
  { name: 'Aktywne produkty', kind: 'active' as const, icon: 'uil-shopping-bag' },
  { name: 'Wykorzystane', kind: 'used' as const, icon: 'uil-file-check-alt' },
]

interface Props {
  upsellBooking: UpSellBookingDetails
  listExpanded: boolean
}

export const UpsellBookingsDetailsProductsListCard = ({ upsellBooking, listExpanded }: Props): JSX.Element => {
  const [showedProductsCount, setShowedProductsCount] = React.useState({})

  const [activeTab, setActiveTab] = React.useState<UpsellingTab<UpsellBookingProductsTab>>(Tabs[0])

  const handleTabsChange = (tab: UpsellingTab<string>) => {
    setActiveTab(tab as UpsellingTab<UpsellBookingProductsTab>)
  }

  const handleProductsCountChange = React.useCallback(
    (kind: string) => (count: number, type: string) => {
      setShowedProductsCount(prev => ({ ...prev, [kind]: { ...prev[kind], [type]: count } }))
    },
    [],
  )

  const totalShowedProductsCount = React.useMemo(() => countTotal(showedProductsCount), [showedProductsCount])

  return (
    <div className="col-8 pb-3">
      <Card className="h-100">
        <UpsellBookingsTabs
          activeTab={activeTab}
          tabs={Tabs}
          onTabsChange={handleTabsChange}
          className="upsell__products-list__tabs"
        />

        <CardBody className={classNames('pt-2 pb-0 upsell__products-list__products', { 'mh-100': listExpanded })}>
          <table className="table table-sm table-striped mb-0">
            <tbody>
              {UpsellProductsForSale.subscription.map(product => (
                <UpsellBookingsDetailsProductRow
                  key={product.type}
                  upsellBooking={upsellBooking}
                  kind={product.type}
                  statusFor={activeTab.kind}
                  onProductsCountChange={handleProductsCountChange('subscription')}
                />
              ))}
              {UpsellProductsForSale.gastro.map(product => (
                <UpsellBookingsDetailsProductRow
                  key={product.kind}
                  upsellBooking={upsellBooking}
                  kind={product.kind}
                  statusFor={activeTab.kind}
                  onProductsCountChange={handleProductsCountChange('gastro')}
                />
              ))}
              <UpsellBookingsDetailsProductRow
                upsellBooking={upsellBooking}
                kind={UpsellProductsForSale.subscriptionContract.kind}
                statusFor={activeTab.kind}
                onProductsCountChange={handleProductsCountChange('subscriptionContract')}
              />
              {totalShowedProductsCount === 0 && (
                <tr>
                  <td className="text-center py-2 text-muted" colSpan={3}>
                    Brak {activeTab.kind === 'active' ? 'aktywnych produktów' : 'produktów'}{' '}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </CardBody>
      </Card>
    </div>
  )
}

const countTotal = (obj: object) =>
  Object.values(obj).reduce((total: number, value) => {
    if (typeof value === 'object' && value !== null) {
      return asDecimal(total).plus(countTotal(value)).toNumber()
    }

    if (typeof value === 'number') {
      return total + value
    }

    return total
  }, 0)
