import * as React from 'react'
import { Button } from 'reactstrap'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectGet } from '@store/actions/generic-actions'
import { PaymentDayReportDetails } from '@models/payments'
import { ContentLoader } from '@components/content-loader'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { updateNotificationMessage } from '@store/slices/notification-slice'
import { useAppDispatch } from '@store/index'

export interface CashBoxCloseConfirmationModalProps {
  onDayClose: () => void
  onHide: () => void
  messageId: string
  paymentDayReportDetailsUrl: string
}

export const CashBoxCloseConfirmationModal = ({
  onDayClose,
  onHide,
  messageId,
  paymentDayReportDetailsUrl,
}: CashBoxCloseConfirmationModalProps) => {
  const { messages } = useNotificationHook()

  const [paymentDayReportDetails, setPaymentDayReportDetails] = React.useState<PaymentDayReportDetails | null>(null)

  const message = React.useMemo(() => messages.find(message => message.id === messageId), [messages, messageId])

  const dispatch = useAppDispatch()

  const handleReportGenerate = () => {
    if (!message) return
    dispatch(updateNotificationMessage({ ...message, modalConfig: { isModalCloseAllowed: true } }))
  }

  const { isLoading, action: fetchPaymentDayReportDetails } = useApiRequest(async () => {
    setPaymentDayReportDetails(await commonObjectGet<PaymentDayReportDetails>(paymentDayReportDetailsUrl))
  })

  React.useEffect(() => {
    fetchPaymentDayReportDetails()
  }, [])

  const handleClose = async () => {
    onDayClose()
    onHide()
  }

  return (
    <ContentLoader isLoading={isLoading}>
      <div className="col-10 mx-auto mt-3">
        <Button
          className="w-100"
          color="light"
          onClick={handleClose}
          disabled={!message?.modalConfig?.isModalCloseAllowed}
        >
          Gotowe
        </Button>
        {paymentDayReportDetails?.urls.print && (
          <a href={paymentDayReportDetails.urls.print} target="_blank" className="text-white">
            <Button className="w-100 mt-1" color="primary" onClick={handleReportGenerate}>
              Pobierz raport zmiany
            </Button>
          </a>
        )}
      </div>
    </ContentLoader>
  )
}
