import * as React from 'react'
import { TableFilters } from '@components/table/table'
import { FormSelect } from '@hyper/forms/form-select'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useAppData } from '@components/hooks/use-app-data'
import { createSelectOption } from '@helpers/utils'

interface Props {
  setFilters: React.Dispatch<React.SetStateAction<TableFilters>>
}

export const UnitsTableHeadersType: React.FC<Props> = ({ setFilters }) => {
  const { unit_kinds } = useAppData()

  const options = React.useMemo(() => unit_kinds.map(row => createSelectOption(row.name, row.id)), [unit_kinds])
  const handleChange = (option: CustomReactSelectOption) => {
    setFilters(filters => ({ ...filters, kind: option?.value ?? undefined }))
  }
  return (
    <div style={{ minWidth: 200 }}>
      <FormSelect
        options={options}
        name="kind"
        selectProps={{ isClearable: true, placeholder: 'Typ', onChange: handleChange }}
        formPlainProps={{ colClassName: 'p-0 m-0', formGroupClassName: 'p-0 m-0 text-normal' }}
      />
    </div>
  )
}
