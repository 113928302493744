import * as React from 'react'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { Button } from 'reactstrap'

interface Result {
  showConfirmationPackageCreation: (onShowDetails?: () => void) => void
}

interface Params {
  backText: string
  detailsText: string
  title: string
  content: React.ReactNode
  withDetails?: boolean
}

export const useProductSaleConfirmationModal = (params?: Partial<Params>): Result => {
  const { addSuccessMessage } = useNotificationHook()

  const content = (
    <p>
      {params?.content || (
        <>
          Sprzedaż została dodana <br /> do listy produktów
        </>
      )}
    </p>
  )

  const action = (hide, showDetails) => {
    const handleShowDetails = () => {
      showDetails()
      hide()
    }

    return (
      <div className="col-10 mx-auto mt-3">
        <Button className="w-100" color="light" onClick={hide}>
          {params?.backText || 'Wróć do sprzedaży'}
        </Button>
        {params?.withDetails !== false && (
          <Button className="w-100 mt-1" color="primary" onClick={handleShowDetails}>
            {params?.detailsText || 'Szczegóły produktu'}
          </Button>
        )}
      </div>
    )
  }

  const showConfirmationModal = showDetailsAction => {
    addSuccessMessage(params?.title || 'Produkt został sprzedany', content, onHide => action(onHide, showDetailsAction))
  }

  return {
    showConfirmationPackageCreation: showConfirmationModal,
  }
}
