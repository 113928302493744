import * as React from 'react'
import { ReservationCard } from '@modules/reservations/details/common/reservation-card'
import { ReceptionBookingDetails } from '@models/reception'
import { ReservationDataRow } from '@modules/reservations/details/common/reservation-data-row'
import { useModal } from '@components/modals/use-modal'
import { IconWithTooltip } from '@components/icon-with-tooltip'
import { ReservationPromotionsPromocodeRemove } from '@modules/reservations/details/reservation-promotions/reservation-promotions-promocode-remove'
import { asDecimal } from '@helpers/utils'
import { useReportsAppData } from '@modules/reports/hooks/use-reports-app-data'
import { ReservationDataItem } from '@modules/reservations/details/reservation-data/reservation-data-item'

interface Props {
  booking: ReceptionBookingDetails
}

export const ReservationPromotions = React.forwardRef(({ booking }: Props, ref: React.RefObject<HTMLElement>) => {
  const [showHPPromotionModal] = useModal('ReservationHprDiscountModal', { booking })
  const { promotion_marketing: promotions } = useReportsAppData()
  const [showPromotionModal] = useModal('ReservationPromocodeModal', { booking })
  const [handleMarketing] = useModal('ReservationMarketingModal', { booking })

  const promotion = React.useMemo(
    () => promotions.find(row => row.id === booking.promotion_marketing_id)?.name || 'brak',
    [promotions, booking.promotion_marketing_id],
  )

  const discount = asDecimal(booking.discount).gt(0) && (
    <ReservationDataItem onChange={showHPPromotionModal}>
      <span className="font-weight-bold mr-1">{booking.discount}%</span>
      <IconWithTooltip
        icon="uil-exclamation-circle"
        tooltipId="reservation-discount"
        tooltipMessage={
          <span className="font-11">
            Zniżka nałożona przez {booking.discount_by}, powód: {booking.discount_note}
          </span>
        }
        color="text-secondary"
      />
    </ReservationDataItem>
  )

  return (
    <ReservationCard title="Promocje i rabaty:" ref={ref}>
      <ReservationDataRow name="Promocja marketingowa:">
        <ReservationDataItem onChange={handleMarketing}>
          <strong>{promotion}</strong>
        </ReservationDataItem>
      </ReservationDataRow>
      <ReservationDataRow name="Rabat HP:">
        {discount || <ReservationDataItem onChange={showHPPromotionModal} />}
      </ReservationDataRow>

      <ReservationDataRow name="Kod promocyjny:">
        {booking.promocode ? (
          <ReservationPromotionsPromocodeRemove booking={booking} />
        ) : (
          <ReservationDataItem onChange={showPromotionModal} />
        )}
      </ReservationDataRow>
    </ReservationCard>
  )
})
