import * as React from 'react'
import { UserPermission } from '@models/dashboard'
import { createSelectOption } from '@helpers/utils'
import { useAppData } from '@components/hooks/use-app-data'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { AccommodationType, Resort } from '@models/booking'

const defaultOption = createSelectOption('Wszystkie', '')

interface Response {
  getResortOptions: (
    permission?: UserPermission | null,
    selectedAccommodationType?: number,
  ) => CustomReactSelectOption[]
  getAccommodationTypesOptions: (selectedResort?: number) => CustomReactSelectOption[]
}

export const useResortsAndAccommodationTypesOptions = (): Response => {
  const { resorts: appDataResort, accommodation_types: allAccommodationTypes } = useAppData()
  const user = useAuthenticatedUser()

  const getResortOptions = React.useCallback(
    (canViewAllResortPermission: UserPermission, selectedAccommodationType?: number) => {
      const availableResorts = (user.hasPerm(canViewAllResortPermission) ? appDataResort : user.resorts).map(resort =>
        createSelectOption(
          resort.name,
          resort.id,
          !resort.accommodation_types.some(accommodationType =>
            selectedAccommodationType ? accommodationType.id === selectedAccommodationType : true,
          ),
        ),
      )

      return availableResorts.length > 1 ? [defaultOption, ...availableResorts] : availableResorts
    },
    [user, appDataResort],
  )

  const getAccommodationTypesOptions = (selectedResort?: number) => {
    const resort = appDataResort.find(el => el.id === selectedResort)

    return [
      defaultOption,
      ...allAccommodationTypes.map((accommodationType: AccommodationType) =>
        createSelectOption(
          accommodationType.name,
          accommodationType.id,
          resort && resort.accommodation_types.every(el => el.id !== accommodationType.id),
        ),
      ),
    ]
  }

  return {
    getResortOptions,
    getAccommodationTypesOptions,
  }
}

export const useResortById = (resortId: number): Resort | undefined => {
  const { resorts } = useAppData()

  return resorts.find(resort => resort.id === resortId)
}
