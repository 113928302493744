import * as React from 'react'
import { PageTitle } from '@hyper/page-title'
import { Card, CardBody } from 'reactstrap'
import { useDocumentTitle } from '@helpers/utils'
import { NavigationPath } from '@models/routes'
import { Link, useParams } from 'react-router-dom'
import { useMenuActive } from '@components/hooks/use-menu-active'
import * as queryString from 'querystring'
import { PromocodeCodesTable } from '@modules/promocode/promocode-list/promocode-codes/promocode-codes-table'

const pageTitle = 'Lista kodów'

export const PromoCodeCodesView: React.FC = () => {
  useDocumentTitle(pageTitle)
  useMenuActive(NavigationPath.PromoCodePromoCodes)

  const { group_id } = useParams<'group_id'>()

  const { q } = queryString.parse(location.search.replace('?', ''))

  return (
    <>
      <PageTitle
        breadCrumbItems={[{ label: 'Lista kodów', path: NavigationPath.PromoCodePromoCodes, active: false }]}
        title={pageTitle}
      />
      <Card>
        <CardBody>
          <PromocodeCodesTable
            groupId={group_id}
            search={q as string}
            additionalFilters={
              <Link to={NavigationPath.PromoCodePromoCodes} className="btn btn-light btn-tall">
                Wróć do listy
              </Link>
            }
          />
        </CardBody>
      </Card>
    </>
  )
}
