import * as React from 'react'
import { useModal } from '@components/modals/use-modal'
import { ReceptionBookingDetails } from '@models/reception'
import { IconWithText } from '@components/icon-with-text'
import { ImprovementTooltip, useTooltipImprovementsGrouped } from '@modules/reception/common/improvement-tooltip'
import { ImprovementIcon } from '@components/improvement-icon'
import { ReservationDataRow } from '@modules/reservations/details/common/reservation-data-row'
import { isBookingEditable } from '@modules/reservations/details/utils'

interface Props {
  booking: ReceptionBookingDetails
}

export const ReservationDataImprovements = ({ booking }: Props): JSX.Element => {
  const { improvementsGrouped, improvements } = useTooltipImprovementsGrouped({ elements: booking.improvements })

  const [showDetailsModal] = useModal('ReceptionBookingDetailsModal', null, { persist: true })

  const showDetailsFor = (tab: 'improvements' | 'feedingNew') => () => {
    showDetailsModal(null, { booking, initialTab: tab, tabs: ['improvements', 'feedingNew'], clearOnUnmount: false })
  }

  const isEditEnabled = isBookingEditable(booking)

  return (
    <ReservationDataRow
      name={<IconWithText icon="uil-star font-15 lh-0" text="Ulepszenia:" />}
      className="border-bottom pb-2 mr-3"
    >
      <div className="d-flex justify-content-between">
        {improvements.length ? (
          <>
            <ImprovementTooltip tooltipId={`improvements-${booking.id}`} improvementsGrouped={improvementsGrouped}>
              {booking.has_package_vip && (
                <span className="mr-1" title="Pakiet VIP">
                  <ImprovementIcon improvementCode="package_vip" className="text-secondary" />
                </span>
              )}
              {improvementsGrouped.map((improvement, i) => (
                <ImprovementIcon improvementCode={improvement.code} className="text-secondary mr-1" key={i} />
              ))}
            </ImprovementTooltip>
            {isEditEnabled && (
              <i
                className="uil-pen font-15 text-secondary cursor-pointer lh-0"
                onClick={showDetailsFor('improvements')}
              />
            )}
          </>
        ) : (
          <>
            <span className="fw-semi-bold">brak</span>
            {isEditEnabled && (
              <i
                className="uil-plus font-16 text-secondary cursor-pointer lh-0"
                onClick={showDetailsFor('improvements')}
              />
            )}
          </>
        )}
      </div>
    </ReservationDataRow>
  )
}
