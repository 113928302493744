import * as React from 'react'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { createSelectOption } from '@helpers/utils'
import { CustomReactSelectOption } from '@components/custom-react-select'

export const useResortSelectOptions = (): CustomReactSelectOption[] => {
  const resorts = useAuthenticatedUser().resorts

  return React.useMemo(() => resorts.map(({ id, name }) => createSelectOption(name, id)), [resorts])
}
