import * as React from 'react'
import { useForm } from 'react-hook-form'
import { ColAuto } from '@hyper/col-auto'
import { FormPlain } from '@hyper/forms'
import { CustomInput } from 'reactstrap'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { ReceptionToCheckinFiltersParams } from '@modules/reception/to-checkin/reception-to-checkin'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { SubmitFilter } from '../common/filters/submit-filter'
import { SortByFilter } from '@modules/reception/common/filters/sort-by-filter'
import { formatDate } from '@helpers/date-helper'
import { extractInnerRef } from '@helpers/forms'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { DatePickerForm } from '../common/filters/date-picker-form'
import { Form } from '@hyper/forms/form'
import { createSelectOption } from '@helpers/utils'
import { selectReceptionBookings, setReceptionDateFilter } from '@store/slices/reception-slice'

interface ReceptionToCheckinFiltersProps {
  defaultFilters: ReceptionToCheckinFiltersParams
  filters: ReceptionToCheckinFiltersParams
  setFilters: (filters: ReceptionToCheckinFiltersParams) => void
  totalVip: number
}

export const ReceptionToCheckinFilters: React.FC<ReceptionToCheckinFiltersProps> = ({
  filters,
  defaultFilters,
  setFilters,
  totalVip,
}) => {
  const methods = useForm<ReceptionToCheckinFiltersParams>({
    defaultValues: filters,
  })
  const dispatch = useAppDispatch()

  const receptionDateFilter = useAppSelector((state: RootState) => state.receptionState.receptionDateFilter)
  const bookings = useAppSelector(selectReceptionBookings)

  const initialBookingsAmount = React.useMemo(
    () => bookings.filter(booking => ['initial', 'initial_waiting'].includes(booking.status)).length || 0,
    [bookings],
  )

  React.useEffect(() => {
    const filterDateFrom = methods.getValues('date_from')
    if (filterDateFrom && receptionDateFilter && formatDate(filterDateFrom) !== formatDate(receptionDateFilter)) {
      methods.setValue('date_from', receptionDateFilter)
    }
  }, [])

  const onSubmit = (payload: ReceptionToCheckinFiltersParams) => {
    setFilters(payload)

    if (payload.date_from && payload.date_from !== receptionDateFilter) {
      dispatch(setReceptionDateFilter(payload.date_from))
    }
  }

  const handleReset = () => {
    methods.reset()
    setFilters(defaultFilters)
  }

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  return (
    <Form methods={methods} onSubmit={onSubmit} className="row form form-style-filter">
      <DatePickerForm name="date_from" control={methods.control} label="Rozpoczęcie rezerwacji:" />
      <SortByFilter options={sortByOptions} />
      <ColAuto>
        <FormPlain colClassName="p-0" name="only_vip" errors={{}}>
          <label className="d-block mt-1">&nbsp;</label>
          <CustomInput
            id="only_vip"
            className="mt-2 text-default"
            type="checkbox"
            label={`Tylko VIP (${totalVip})`}
            {...extractInnerRef(methods.register('only_vip'))}
          />
        </FormPlain>
      </ColAuto>

      <ColAuto>
        <FormPlain colClassName="p-0" name="valid_forever" errors={{}}>
          <label className="d-block mt-1">&nbsp;</label>
          <CustomInput
            className="mt-2 text-default"
            role="filters-initial"
            type="checkbox"
            {...extractInnerRef(methods.register('initial'))}
            id="initial"
            label={`Wstępne rezerwacje (${initialBookingsAmount})`}
          />
        </FormPlain>
      </ColAuto>

      <SubmitFilter handleReset={handleReset} />
      <SearchFilter />
    </Form>
  )
}

const sortByOptions = [
  createSelectOption('Godzina przyjazdu', 'arrival_time'),
  createSelectOption('Imię i nazwisko', 'name'),
  createSelectOption('Długość pobytu', 'days_count'),
  createSelectOption('Numer lokalu', 'apartment.name'),
]
