import * as React from 'react'
import { TechnicalOrder } from '@models/technical-orders'
import Table from '@components/table/table'
import { TechnicalOrdersTodoRowNumber } from '@modules/technical-orders/technical-orders-todo/row-number'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { TechnicalOrdersTodoRowUsers } from '@modules/technical-orders/technical-orders-ordered/row-users'
import { TechnicalOrdersTodoRowUserBy } from '@modules/technical-orders/technical-orders-todo/row-user-by'
import { TechnicalOrdersTodoRowName } from '@modules/technical-orders/technical-orders-todo/row-name'
import { TechnicalOrdersTodoRowStatus } from '@modules/guests-technical-orders/row-status'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'

interface Props {
  technicalOrder: TechnicalOrder
}

export const UnitDetailsTechnicalOrdersTableRow: React.FC<Props> = ({ technicalOrder }) => {
  const navigate = useNavigate()

  const handleDetails = React.useCallback(() => {
    navigate(
      NavigationPath.TechnicalOrdersWithParamsAndId.replace(':id', String(technicalOrder.id))
        .replace(':resort_id', String(technicalOrder.resort_id))
        .replace(':type', 'todo'),
    )
  }, [technicalOrder.id, technicalOrder.result])

  return (
    <Table.Row onDoubleClick={handleDetails} className="cursor-pointer" role="details-row">
      <TechnicalOrdersTodoRowNumber technicalOrder={technicalOrder} showCreatedAt={true} />
      <TechnicalOrdersTodoRowStatus technicalOrder={technicalOrder} />
      <TechnicalOrdersTodoRowName technicalOrder={technicalOrder} />
      <TechnicalOrdersTodoRowUserBy user={technicalOrder.created_by} booking={technicalOrder.created_by_booking} />
      <TechnicalOrdersTodoRowUsers technicalOrder={technicalOrder} />
      <Table.Cell className="vertical-align-middle">
        {technicalOrder.finished_at && toDefaultDateTimeFormat(technicalOrder.finished_at)}
      </Table.Cell>
    </Table.Row>
  )
}
