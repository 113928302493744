import { useFormContext, useWatch } from 'react-hook-form'
import { createCartItemFormName } from '@modules/reception/cart/utils'
import { ReceptionBookingDetailsCartItem } from '@models/reception'

export const useCartItemGroupSelection = (items: ReceptionBookingDetailsCartItem[]) => {
  const { control, setValue } = useFormContext()

  const fields = useWatch({ control })

  const selectedItems = items.filter(item => fields[createCartItemFormName(item)])

  const allSelected = selectedItems.length === items.length
  const someSelected = !allSelected && !!selectedItems.length

  const changeGroup = () => {
    if (allSelected) {
      items.forEach(item => setValue(createCartItemFormName(item), false))
      return
    }

    items.forEach(item => setValue(createCartItemFormName(item), true))
  }

  return {
    allSelected,
    someSelected,
    changeGroup,
  }
}
