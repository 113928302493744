import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { Form } from '@hyper/forms/form'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { useForm, useWatch } from 'react-hook-form'
import { SaveButton } from '@hyper/button'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { TechnicalOrderForm } from '@modules/technical-orders/technical-order-modal/form'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useCreateOrUpdateReceptionTechnicalOrderMutation } from '@api/technical-orders'
import { useHandleRtkQueryError } from '@components/hooks/use-handle-rtk-error'
import { TechnicalOrderDetails } from '@models/technical-orders'
import * as R from 'ramda'
import { useResortSelectOptions } from '@components/hooks/use-resort-select-options'
import { useTechnicalOrderTypesSelectOptions } from '@modules/technical-orders/hooks/use-technical-order-types-select-options'
import { useUnitsSelectOptions } from '@modules/technical-orders/hooks/use-units-select-options'

export interface ReceptionTechnicalOrderFormInputs {
  name: string
  technicalOrderEnterWhereGuestAbsenceRange: boolean
  description: string
  resort?: CustomReactSelectOption
  unit?: CustomReactSelectOption
  technical_order_type?: CustomReactSelectOption
  priority: number
  enter_where_guest_absence: boolean
  images: number[]
  company?: CustomReactSelectOption
  users: { [key: string]: boolean }
}

interface Props extends BaseModalProps {
  resortId?: number
  technicalOrderDetails?: TechnicalOrderDetails
}

export const ReceptionTechnicalOrderModal: React.FC<Props> = ({ resortId, toggleIsVisible, technicalOrderDetails }) => {
  const resortOptions = useResortSelectOptions()
  const technicalOrderTypesOptions = useTechnicalOrderTypesSelectOptions()

  const methods = useForm<ReceptionTechnicalOrderFormInputs>({
    mode: 'onBlur',
    defaultValues: {
      enter_where_guest_absence: false,
      technicalOrderEnterWhereGuestAbsenceRange:
        !!technicalOrderDetails?.technical_order_enter_where_guest_absence_range.length,
      ...R.pick<ReceptionTechnicalOrderFormInputs, keyof ReceptionTechnicalOrderFormInputs>(
        ['name', 'description', 'enter_where_guest_absence', 'technical_order_enter_where_guest_absence_range'],
        { ...technicalOrderDetails },
      ),
      priority: technicalOrderDetails?.priority === 10,
      images: technicalOrderDetails?.images.map(row => row.id) || [],
      resort: resortOptions.find(row => row.value === (resortId || technicalOrderDetails?.resort_id)),
      technical_order_type: technicalOrderTypesOptions.find(
        row => row.value === technicalOrderDetails?.technical_order_type_id,
      ),
    },
  })

  const selectedResort = useWatch({
    control: methods.control,
    name: 'resort',
  })

  const { options: unitsOptions, getSelectedOption } = useUnitsSelectOptions(selectedResort?.value || 0)

  React.useEffect(() => {
    if (technicalOrderDetails?.unit_id) {
      methods.setValue('unit', getSelectedOption(technicalOrderDetails.unit_id))
    }
  }, [technicalOrderDetails?.unit_id, unitsOptions, getSelectedOption])

  const { addSuccessNotification } = useNotificationHook()

  const [createOrUpdateTechnicalOrder, { error, isError, isLoading }] =
    useCreateOrUpdateReceptionTechnicalOrderMutation()

  const onSubmit = async (payload: ReceptionTechnicalOrderFormInputs) => {
    await createOrUpdateTechnicalOrder({
      data: { ...payload, priority: payload.priority ? 10 : 0 },
      id: technicalOrderDetails?.id,
    }).unwrap()
    addSuccessNotification(technicalOrderDetails ? 'Zmiany zostały zapisane' : 'Usterka została dodana')
    toggleIsVisible()
  }

  useHandleRtkQueryError(error, isError, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>
        {technicalOrderDetails ? `Edytuj usterkę ${technicalOrderDetails.number}` : 'Dodaj nową usterkę'}
      </ModalHeader>
      <ModalBody>
        <TechnicalOrderForm
          showOptions={true}
          showTechnicalOrderTypes={true}
          showAssignUsers={false}
          images={technicalOrderDetails?.images || []}
          unitsOptions={unitsOptions}
          showCreatedByBooking={true}
          resortOptions={resortOptions}
          initialCompany={technicalOrderDetails?.company_id}
          technicalOrderTypesOptions={technicalOrderTypesOptions}
          showIsSupervisorAcceptanceRequired={false}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Anuluj
        </Button>
        <SaveButton
          className="btn btn-green"
          label={technicalOrderDetails ? 'Zapisz zmiany' : 'Dodaj zgłoszenie'}
          isSaving={isLoading}
        />
      </ModalFooter>
    </Form>
  )
}
