import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import * as R from 'ramda'
import { useDebounce } from 'rooks'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { SubmitFilter } from '@modules/reception/common/filters/submit-filter'
import { Row } from 'reactstrap'
import { HousekeepingImprovementsOrderFiltersParams } from '@modules/housekeeping/cleaning-orders/improvement/index'

interface Props {
  filters: HousekeepingImprovementsOrderFiltersParams
  setFilters: (filters: HousekeepingImprovementsOrderFiltersParams) => void
  defaultFilters: HousekeepingImprovementsOrderFiltersParams
}

export const HousekeepingImprovementsOrdersFilters: React.FC<Props> = ({ filters, setFilters, defaultFilters }) => {
  const methods = useFormContext<HousekeepingImprovementsOrderFiltersParams>()

  const values = useWatch({ control: methods.control })

  const onSubmitDebounced = useDebounce(values => setFilters(values), 250)

  React.useEffect(() => {
    if (!R.equals(values, filters)) {
      onSubmitDebounced(values)
    }
  }, [values])

  const handleReset = () => {
    methods.reset()
    setFilters(defaultFilters)
  }

  return (
    <Row className="d-flex mr-0 justify-content-between w-100">
      <SubmitFilter hideLabel handleReset={handleReset} formGroupClassName="mb-0" />
      <SearchFilter setValue={methods.setValue} hideLabel formGroupClassName="mb-0" />
    </Row>
  )
}
