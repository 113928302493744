import * as React from 'react'
import { formatPrice } from '@helpers/utils'
import { BookingStatsTotals } from '@modules/reservations/models'
import { PercentageChangeIndicator } from '@components/percentage-change-indicator'
import { ReservationStatsBoxRow } from '@modules/reservations/stats-board/reservation-stats-box-row'

interface ReservationStatsBoxReservationProps {
  stats: BookingStatsTotals
}

export const ReservationStatsBoxReservation: React.FC<ReservationStatsBoxReservationProps> = ({ stats }) => (
  <table className="table mb-0 table-sm ">
    <tbody>
      <ReservationStatsBoxRow
        className="border-top-0"
        name="Liczba rezerwacji:"
        value={
          <PercentageChangeIndicator
            baseValue={stats.reservation_today_count}
            valueToCompare={stats.reservation_yesterday_count}
          >
            {stats.reservation_count}
          </PercentageChangeIndicator>
        }
      />
      <ReservationStatsBoxRow
        name="Wartość rezerwacji:"
        value={
          <PercentageChangeIndicator
            baseValue={stats.reservation_today_amount}
            valueToCompare={stats.reservation_yesterday_amount}
          >
            {formatPrice(stats.reservation_amount)}
          </PercentageChangeIndicator>
        }
      />
      <ReservationStatsBoxRow name="Wpłacono rezerwacji:" value={formatPrice(stats.reservation_payin)} />
      <ReservationStatsBoxRow name="Pakiety i abo. odbyte:" value={formatPrice(stats.package_subscription_done)} />
      <ReservationStatsBoxRow name="Pakiety i abo. założone:" value={formatPrice(stats.package_subscription_created)} />
      <ReservationStatsBoxRow
        name="Pakiety i abo. do wykorzystania:"
        value={formatPrice(stats.package_subscription_not_used)}
      />
    </tbody>
  </table>
)
