import * as React from 'react'
import { ReceptionFeedingGuestSelect } from '@modules/reception/checkin/step-feeding/reception-feeding-guest-select'
import { ReceptionBookingGuest } from '@models/booking'
import { ReceptionBookingDetails } from '@models/reception'
import { NewFeedingCalculationDetails } from '@modules/reception/checkin/step-feeding/new-feeding/models'
import { useFormContext, useWatch } from 'react-hook-form'
import {
  FeedingInputNameSeparator,
  isEmptyFeedingState,
  isMealUpcoming,
} from '@modules/reception/checkin/step-feeding/new-feeding/utils'

interface Props {
  initialCalculationDetails: NewFeedingCalculationDetails[]
  booking: ReceptionBookingDetails
  guest: ReceptionBookingGuest
}

export const ReceptionBookingNewFeedingGuestRowCollapsableActions = ({
  initialCalculationDetails,
  guest,
  booking,
}: Props): React.ReactNode => {
  const { control, setValue } = useFormContext()

  const feedings = useWatch({ control })

  const handleGuestSelection = (guestIds: number[]) => {
    const feedingToCopy = Object.entries(feedings).reduce(
      (prev, [feedingKey, isSelected]) =>
        feedingKey.startsWith(guest.id.toString())
          ? { ...prev, [feedingKey.replace(`${guest.id}${FeedingInputNameSeparator}`, '')]: isSelected }
          : prev,
      [],
    )

    guestIds.forEach(guestId => {
      Object.entries(feedingToCopy).forEach(([feedingDayKey, isSelected]) => {
        setValue(`${guestId}${FeedingInputNameSeparator}${feedingDayKey}`, isSelected)
      })
    })
  }

  const guestToConfigurationCopy = booking.guests.filter(bookingGuest => bookingGuest.id !== guest.id)

  const isFeedingCopyingAvailable = React.useMemo(
    () =>
      isEmptyFeedingState(initialCalculationDetails) &&
      initialCalculationDetails.every(isMealUpcoming) &&
      guest.type !== 'baby',
    [initialCalculationDetails, guest.type],
  )

  if (!isFeedingCopyingAvailable) return null

  return (
    <div className="d-flex justify-content-between align-items-center mx-3 pb-3">
      <ReceptionFeedingGuestSelect
        label="Kopiuj konfigurację dla"
        guests={guestToConfigurationCopy}
        onSelectedGuestsChange={handleGuestSelection}
        feedingsForGuestId={guest.id}
      />
    </div>
  )
}
