import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { apiQueryFunction } from '@api/base'
import { extractSelectOptionsValues } from '@helpers/utils'
import { PaginationResponse } from '@models/dashboard'
import { RootState } from '@store/index'
import { Unit, UnitDetails } from '@models/units'

type QueryParams = Partial<{ resort: number }>

export const UNIT_TAG = 'unit'

export const unitsApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: '' }),
  reducerPath: 'unitsApi',
  tagTypes: [UNIT_TAG],
  endpoints: builder => ({
    getUnits: builder.query<Unit[], QueryParams>({
      queryFn: apiQueryFunction((state: RootState, data: QueryParams) => ({
        params: { ...(extractSelectOptionsValues(data) || {}), resort: data.resort || undefined },
        method: 'GET',
        url: state.appState.appData.urls.housekeeping.units,
        responseTransformer: (response: PaginationResponse<Unit>) => response.results,
      })),
      providesTags: (result: Unit[]) =>
        result ? [...result.map(({ id }) => ({ type: UNIT_TAG, id }) as const), UNIT_TAG] : [UNIT_TAG],
    }),

    getUnitDetails: builder.query<UnitDetails, string>({
      queryFn: apiQueryFunction((_, url: string) => ({ method: 'GET', url })),
    }),
  }),
})

export const { useGetUnitDetailsQuery, useGetUnitsQuery } = unitsApi
