import * as React from 'react'
import { SubscriptionDetails } from '@modules/subscription/models'
import { Button, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useFormRequest } from '@components/hooks/use-api-request'
import { useForm } from 'react-hook-form'
import { commonObjectPut } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAppDispatch } from '@store/index'
import { updateSubscriptionDetails } from '@store/slices/subscription-slice'
import { Payment } from '@models/payments'
import { FormInput } from '@hyper/forms'
import { BaseModalProps } from '@components/modals/types'
import { Form } from '@hyper/forms/form'

interface Props extends BaseModalProps {
  payment: Payment
}

export interface FormInputs {
  amount: string
}

export const SubscriptionPaymentUpdateModal: React.FC<Props> = ({ payment, toggleIsVisible }) => {
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const methods = useForm<FormInputs>({
    defaultValues: payment,
  })

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    dispatch(updateSubscriptionDetails(await commonObjectPut<SubscriptionDetails>(payment.urls.details, payload)))
    addSuccessMessage('Sukces', 'Wpłata została skorygowane!')
    toggleIsVisible()
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Edytuj wpłatę</ModalHeader>
      <ModalBody>
        <Row>
          <FormInput label="Kwota" name="amount" />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={toggleIsVisible}>
          Zamknij
        </Button>

        <SaveButton role="submit" className="btn btn-green" label="Zapisz" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
