import * as React from 'react'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { TimelineBooking } from '@store/reducers/timeline-reducers'

export type TimelineResizerDirection = 'left' | 'right'

interface Props {
  direction: TimelineResizerDirection
  booking: TimelineBooking
  disableEditMode: () => void
}

const TimelineItemResizerView: React.FC<Props> = ({ direction, booking, disableEditMode }) => {
  const [, drag, preview] = useDrag({
    item: { direction, booking },
    type: 'RESERVATION_ITEM_RESIZER',
    end: disableEditMode,
  })

  React.useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [])

  const handleClick = event => {
    event.stopPropagation()
  }

  return (
    <div
      ref={drag}
      data-testid="item-resizer"
      className={`calendar-content__item-resizer is-${direction}`}
      draggable={true}
      onClick={handleClick}
    />
  )
}

export default TimelineItemResizerView
