import * as React from 'react'
import { ReceptionBooking } from '@models/reception'
import { useModal } from '@components/modals/use-modal'

interface ReceptionCompleteCheckInRowParkingProps {
  booking: ReceptionBooking
}

export const ReceptionCompleteCheckInRowParking: React.FC<ReceptionCompleteCheckInRowParkingProps> = ({ booking }) => {
  const [toggleParkingNumberDialogVisibility] = useModal('ReceptionParkingNumbersDialog', { booking })

  return (
    <div onClick={toggleParkingNumberDialogVisibility} className="cursor-pointer">
      <div className="mb-1">
        Parking: <i className="uil-pen ml-1 font-12 text-secondary" />
      </div>
      {!booking.car_register_numbers.length ? <strong>-</strong> : ''}
      {booking.car_register_numbers.map((registerNumber, i) => (
        <strong key={i} className="d-block mb-1">
          {registerNumber}
        </strong>
      ))}
    </div>
  )
}
