import * as React from 'react'
import { SmsTemplate } from '@modules/cms/models'
import Table from '@components/table/table'
import { YesNoBadge } from '@components/badges/yes-no'
import { useModal } from '@components/modals/use-modal'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'

const GroupName = {
  reception: 'Recepcja',
}

interface Props {
  smsTemplate: SmsTemplate
}

export const NotificationTemplatesSmsListRow: React.FC<Props> = ({ smsTemplate }) => {
  const [handleEdit] = useModal('NotificationTemplatesSmsModal', { smsTemplate })
  const user = useAuthenticatedUser()

  return (
    <Table.Row>
      <Table.Cell>{smsTemplate.name}</Table.Cell>
      <Table.Cell>
        <code>{smsTemplate.key}</code>
      </Table.Cell>
      <Table.Cell>{GroupName[smsTemplate.group] || '-'}</Table.Cell>
      <Table.Cell>
        <YesNoBadge value={smsTemplate.is_active} />
      </Table.Cell>
      <Table.Cell className="text-right">
        {user.hasPerm(UserPermission.NotificationCanManage) && (
          <button className="action-icon btn-pure" title="Edytuj" onClick={handleEdit}>
            <i className="uil-edit text-muted font-18" />
          </button>
        )}
      </Table.Cell>
    </Table.Row>
  )
}
