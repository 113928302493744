import React from 'react'
import { Row, Col } from 'reactstrap'

interface Props {
  label: React.ReactNode | string
  children: React.ReactNode
}

export const TechnicalOrderPreviewModalRow: React.FC<Props> = ({ children, label }) => (
  <Row className="mb-2">
    <Col md={4} className="text-strong">
      {label}
    </Col>
    <Col md={8} className="text-semi-strong">
      {children}
    </Col>
  </Row>
)
