import * as React from 'react'
import { getApartmentList } from '@store/actions/timeline-actions'
import { useAppDispatch, useAppSelector } from '@store/index'
import { Apartment } from '@models/apartment'
import { useApiRequest } from '@components/hooks/use-api-request'
import { selectAvailableApartments } from '@store/selectors/timeline'

interface UseApartments {
  apartments: Apartment[]
  isLoading: boolean
  fetchApartments: () => Promise<void>
}

export const useApartments = (
  resortId?: string | number,
  accommodationIds?: string[],
  autoFetch = true,
): UseApartments => {
  const apartments = useAppSelector(selectAvailableApartments)
  const dispatch = useAppDispatch()

  const { isLoading, action: fetch } = useApiRequest(
    React.useCallback(
      async () => await dispatch(getApartmentList(resortId, accommodationIds)),
      [resortId, accommodationIds],
    ),
  )

  React.useEffect(() => {
    if (autoFetch) {
      fetch()
    }
  }, [resortId, accommodationIds])

  return { apartments, isLoading, fetchApartments: fetch }
}
