import * as React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { PaymentTable } from '@modules/subscription-contract/details/payements/payments-table'
import { SubscriptionContractDetails } from '@modules/subscription-contract/models'

interface Props {
  subscriptionDetails: SubscriptionContractDetails
}

export const SubscriptionContractPeriodicRow = React.forwardRef<HTMLDivElement, Props>(
  ({ subscriptionDetails }, ref) => (
    <div ref={ref}>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <h4 className="mt-0 text-secondary mb-3">Wpłaty</h4>
              <PaymentTable subscriptionDetails={subscriptionDetails} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  ),
)
