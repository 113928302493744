import * as React from 'react'
import { Col } from 'reactstrap'
import classnames from 'classnames'
import { ReceptionBookingClientWallet } from '@models/reception'

interface StepVerificationAddVoucherButtonProps {
  onClick: () => void
  clientWallets: ReceptionBookingClientWallet[]
}

export const StepVerificationAddVoucherButton: React.FC<StepVerificationAddVoucherButtonProps> = ({
  onClick,
  clientWallets,
}) => (
  <Col className="px-0 mt-1">
    <div
      className={classnames('text-secondary d-inline-block', {
        'opacity-5': !clientWallets.length,
        'cursor-pointer': clientWallets.length,
      })}
      onClick={onClick}
    >
      <i className="uil-plus mr-1" />
      <span className="font-12">Dodaj kolejny kod</span>
    </div>
  </Col>
)
