import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectGet } from '@store/actions/generic-actions'
import { NewFeedingCalculations } from '@modules/reception/checkin/step-feeding/new-feeding/models'
import { setFeedingCalculations, setInitialFeedingCalculations } from '@store/slices/new-feeding-slice'
import { ReceptionBookingDetails } from '@models/reception'
import { useAppDispatch } from '@store/index'

interface Response {
  fetchCalculations: (
    booking: ReceptionBookingDetails,
    action: (response: NewFeedingCalculations) => void,
  ) => Promise<void>
  isFetching: boolean
}

export const useNewFeedingCalculationsFetch = (): Response => {
  const dispatch = useAppDispatch()

  const { action: fetchCalculations, isLoading: isFetching } = useApiRequest(
    async (booking: ReceptionBookingDetails, action: (response: NewFeedingCalculations) => void) => {
      const response = await commonObjectGet<NewFeedingCalculations>(booking.urls.reservation_feeding_calculate)
      action(response)

      dispatch(setFeedingCalculations(response))
      dispatch(setInitialFeedingCalculations(response))
    },
  )

  return {
    fetchCalculations,
    isFetching,
  }
}
