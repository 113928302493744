import * as React from 'react'
import { Button, Col, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { PaymentDayReportDetails } from '@models/payments'
import { PaymentRegisterCashBoxDayDetailsActionsOpen } from '@modules/payment-registers/cashbox-day-details/actions-open'
import classnames from 'classnames'
import { CashRegisterReportModalToggle } from '@modules/sale/cash-register/list/report-modal/toggle'
import { useModal } from '@components/modals/use-modal'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'

interface Props {
  paymentDayReportDetails: PaymentDayReportDetails
}

export const PaymentRegisterCashboxDayDetailsActions = ({ paymentDayReportDetails }: Props): JSX.Element => {
  const [handleCloseDay] = useModal('PaymentCloseDayModal', {
    paymentDayReport: paymentDayReportDetails,
  })

  const user = useAuthenticatedUser()

  return (
    <Row className="mb-4">
      <Col sm={6}>
        <Link
          to={NavigationPath.PaymentRegisterCashboxWithParam.replace(
            ':resort_id',
            String(paymentDayReportDetails.resort_id),
          )}
          className="btn btn-light"
        >
          Wróc do listy
        </Link>
      </Col>
      <Col md={6} className="text-right">
        <PaymentRegisterCashBoxDayDetailsActionsOpen paymentDayReportDetails={paymentDayReportDetails} />

        {paymentDayReportDetails.status === 'open' && user.hasPerm(UserPermission.CashPaymentDayReportCanClose) && (
          <Button className="mr-2" color="danger" onClick={handleCloseDay}>
            Zamknij dzień
          </Button>
        )}
        <a
          href={paymentDayReportDetails.urls.print}
          target="_blank"
          className={classnames({ 'not-clickable': paymentDayReportDetails.status !== 'close' })}
        >
          <Button color="primary">Drukuj raport kasowy</Button>
        </a>
        <CashRegisterReportModalToggle className="ml-2" color="green" label="Drukuj raport z ERP" />
      </Col>
    </Row>
  )
}
