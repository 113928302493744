import * as React from 'react'
import { FormInput } from '@hyper/forms'
import { Row } from 'reactstrap'
import { FormSelect } from '@hyper/forms/form-select'
import { CustomReactSelectOption } from '@components/custom-react-select'

interface Props {
  countriesOptions: CustomReactSelectOption[]
}

export const ClientCreateModalFormAddress: React.FC<Props> = ({ countriesOptions }) => (
  <Row>
    <FormInput colSize={6} label="Adres zamieszkania" name="street" />
    <FormInput colSize={6} label="Kod pocztowy" name="postcode" />
    <FormInput colSize={6} label="Miejscowość:" name="city" />
    <FormSelect label="Kraj" options={countriesOptions} name="country" formPlainProps={{ colSize: 6 }} />
  </Row>
)
