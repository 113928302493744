import * as React from 'react'
import { ButtonWithIcon } from '@components/button-with-icon'
import { useModal } from '@components/modals/use-modal'

interface Props {
  resortId: number
}
export const GuestsTechnicalOrderCreateButton: React.FC<Props> = ({ resortId }) => {
  const [handleClick] = useModal('GuestsTechnicalOrderModal', { resortId })
  return (
    <div className="ml-auto mb-1">
      <ButtonWithIcon handleClick={handleClick} icon="uil-plus" text="Dodaj zgłoszenie" color="green" />
    </div>
  )
}
