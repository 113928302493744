import { createSelector } from 'reselect'

import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@store/index'
import {
  ReceptionBooking,
  ReceptionBookingLoadNote,
  ReceptionBookingWalletDetails,
  ReservationBookingPayment,
  ReservationBookingVoucher,
  WalletTransaction,
} from '@models/reception'
import {
  getBookingList,
  getBookingLoadNotes,
  getBookingPayments,
  getBookingVouchers,
  getBookingWalletDetails,
  getWalletTransactions,
} from '@store/actions/reservation-actions'

interface ReservationState {
  bookingVouchers: EntityState<ReservationBookingVoucher>
  reservations: EntityState<ReceptionBooking>
  bookingLoadNotes: EntityState<ReceptionBookingLoadNote>
  bookingPayments: EntityState<ReservationBookingPayment>
  walletTransactions: EntityState<WalletTransaction>
  bookingWalletDetails: ReceptionBookingWalletDetails | null
}

const reservationsAdapter = createEntityAdapter<ReceptionBooking>()
const reservationWalletAdapter = createEntityAdapter<WalletTransaction>()
const bookingPaymentAdapter = createEntityAdapter<ReservationBookingPayment>()
const bookingLoadNotesAdapter = createEntityAdapter<ReceptionBookingLoadNote>()
const bookingVouchersAdapter = createEntityAdapter<ReservationBookingVoucher>()

const initialState: ReservationState = {
  bookingWalletDetails: null,
  reservations: reservationsAdapter.getInitialState(),
  walletTransactions: reservationWalletAdapter.getInitialState(),
  bookingPayments: bookingPaymentAdapter.getInitialState(),
  bookingLoadNotes: bookingLoadNotesAdapter.getInitialState(),
  bookingVouchers: bookingVouchersAdapter.getInitialState(),
}

export const reservationsSlice = createSlice({
  name: 'reservations',
  initialState,
  reducers: {
    setReservationWalletTransactions(state, action: PayloadAction<WalletTransaction[]>) {
      reservationWalletAdapter.setAll(state.walletTransactions, action.payload)
    },
    updateBookingPaymentDetails(state, action: PayloadAction<ReservationBookingPayment>) {
      bookingPaymentAdapter.upsertOne(state.bookingPayments, action.payload)
    },
    updateBookingWalletDetails(state, action: PayloadAction<ReceptionBookingWalletDetails>) {
      state.bookingWalletDetails = action.payload
    },
    removeBookingVoucher(state, action: PayloadAction<number>) {
      bookingVouchersAdapter.removeOne(state.bookingVouchers, action.payload)
    },
    updateReceptionBookingVoucherDetails(state, action: PayloadAction<ReservationBookingVoucher>) {
      bookingVouchersAdapter.upsertOne(state.bookingVouchers, action.payload)
    },
  },
  extraReducers: builder => {
    builder.addCase(getBookingVouchers.fulfilled, (state, action) => {
      bookingVouchersAdapter.setAll(state.bookingVouchers, action.payload)
    })
    builder.addCase(getBookingWalletDetails.fulfilled, (state, action) => {
      state.bookingWalletDetails = action.payload
    })
    builder.addCase(getBookingList.fulfilled, (state, action) => {
      reservationsAdapter.setAll(state.reservations, action.payload)
    })
    builder.addCase(getBookingPayments.fulfilled, (state, action) => {
      bookingPaymentAdapter.setAll(state.bookingPayments, action.payload)
    })
    builder.addCase(getWalletTransactions.fulfilled, (state, action) => {
      reservationWalletAdapter.setAll(state.walletTransactions, action.payload)
    })
    builder.addCase(getBookingLoadNotes.fulfilled, (state, action) => {
      bookingLoadNotesAdapter.setAll(state.bookingLoadNotes, action.payload)
    })
  },
})
export const { selectAll: bookingLoadNotesSelector } = bookingLoadNotesAdapter.getSelectors(
  (state: RootState) => state.reservationsState.bookingLoadNotes,
)
export const { selectAll: reservationsBookingsSelector } = reservationsAdapter.getSelectors(
  (state: RootState) => state.reservationsState.reservations,
)
export const { selectAll: reservationBookingPaymentsSelector } = bookingPaymentAdapter.getSelectors(
  (state: RootState) => state.reservationsState.bookingPayments,
)

export const { selectAll: bookingVouchersSelector } = bookingVouchersAdapter.getSelectors(
  (state: RootState) => state.reservationsState.bookingVouchers,
)

export const reservationBookingPaymentsVisibleSelector = createSelector(
  reservationBookingPaymentsSelector,
  (payments: ReservationBookingPayment[]): ReservationBookingPayment[] =>
    payments.filter(row => !row.hide && !row.is_removed),
)

export const { selectAll: selectReservationWalletTransactions } = reservationWalletAdapter.getSelectors(
  (state: RootState) => state.reservationsState.walletTransactions,
)

export const {
  updateReceptionBookingVoucherDetails,
  removeBookingVoucher,
  setReservationWalletTransactions,
  updateBookingPaymentDetails,
  updateBookingWalletDetails,
} = reservationsSlice.actions

export const bookingWalletDetailsSelector = (state: RootState): ReceptionBookingWalletDetails | null =>
  state.reservationsState.bookingWalletDetails
export default reservationsSlice.reducer
