import * as React from 'react'
import { Col } from 'reactstrap'
import { StepVerificationVoucherSummaryRow } from '@modules/reception/checkin/step-verification/step-verification-voucher/step-verification-voucher-summary-row'
import { ReceptionBookingClientWallet } from '@models/reception'
import { ReservationVoucherRemove } from '@modules/reservations/details/vouchers/reservation-voucher-remove'

interface Props {
  voucher: ReceptionBookingClientWallet
}

export const StepVerificationAddedVouchersRow: React.FC<Props> = ({ voucher }) => (
  <>
    <Col md={12} className="mb-2">
      <div className="border px-2 py-1 rounded reception__booking-check-in__verification_step__voucher__added position-relative">
        <span>{voucher.code || voucher.number}</span>
        <ReservationVoucherRemove wallet={voucher}>
          <i className="uil-trash-alt text-danger font-16 cursor-pointer reception__booking-check-in__verification_step__voucher__code-input__action-icon" />
        </ReservationVoucherRemove>
      </div>
    </Col>
    <StepVerificationVoucherSummaryRow voucherOwner={voucher.client} amount={voucher.available_amount} />
  </>
)
