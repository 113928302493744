import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { ModalBody, ModalFooter } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { ButtonWithIcon } from '@components/button-with-icon'
import { IconWithText } from '@components/icon-with-text'
import { ReceptionBookingCheckInPreviewPersonalData } from '@modules/reception/checkin/preview/personal-data/reception-booking-check-in-preview-personal-data'
import { ReceptionBookingCheckInPreviewGuests } from '@modules/reception/checkin/preview/guests/reception-booking-check-in-preview-guests'
import { ReceptionBookingCheckInPreviewImprovements } from '@modules/reception/checkin/preview/reception-booking-check-in-preview-improvements'
import { ReceptionBookingCheckInPreviewSpecialRequests } from '@modules/reception/checkin/preview/reception-booking-check-in-preview-special-requests'
import { ReceptionBookingCheckInPreviewBill } from '@modules/reception/checkin/preview/reception-booking-check-in-preview-bill'
import { useModal } from '@components/modals/use-modal'
import { ReceptionBookingCheckInPreviewPersonalDataDocument } from '@modules/reception/checkin/preview/personal-data/reception-booking-check-in-preview-personal-data-document'

interface Props {
  bookingDetails: ReceptionBookingDetails
  onPreviewDialogClose: () => void
  onNextStep: () => void
}

export const ReceptionBookingCheckInPreviewDialogContent: React.FC<Props> = ({
  bookingDetails,
  onNextStep,
  onPreviewDialogClose,
}) => {
  const [showDefaultCheckInDialog] = useModal(
    'ReceptionBookingCheckInDialog',
    { booking: bookingDetails },
    { persist: true },
  )

  const onShowDefaultCheckinModalStep = (initialStep = 1) => {
    onPreviewDialogClose()
    showDefaultCheckInDialog(null, { booking: bookingDetails, initialStep })
  }

  return (
    <>
      <ModalBody className="reception__booking-check-in-preview__content">
        <div className="row">
          <div className="col-6 pr-1">
            <ReceptionBookingCheckInPreviewPersonalData bookingDetails={bookingDetails}>
              <ReceptionBookingCheckInPreviewPersonalDataDocument bookingDetails={bookingDetails} />
            </ReceptionBookingCheckInPreviewPersonalData>
            <ReceptionBookingCheckInPreviewGuests
              bookingDetails={bookingDetails}
              onShowDefaultCheckinModalStep={onShowDefaultCheckinModalStep}
            />
          </div>
          <div className="col-6 pl-1">
            <ReceptionBookingCheckInPreviewImprovements
              bookingDetails={bookingDetails}
              onShowDefaultCheckinModalStep={onShowDefaultCheckinModalStep}
            />
            <ReceptionBookingCheckInPreviewSpecialRequests bookingDetails={bookingDetails} />
            <ReceptionBookingCheckInPreviewBill bookingDetails={bookingDetails} />
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <ButtonWithIcon
          icon="uil-edit-alt font-15"
          text="Popraw dane"
          color="light"
          type="button"
          btnClass="btn-tall"
          handleClick={() => onShowDefaultCheckinModalStep()}
        />
        <SaveButton
          className="btn btn-green btn-tall"
          label={
            <IconWithText
              icon="uil-check font-16"
              text={`Potwierdź i wydaj ${bookingDetails.bands.length ? 'opaski' : 'klucze'}`}
              wrapperClassNames="text-white"
            />
          }
          labelSaving="Potwierdzanie.."
          isSaving={false}
          onClick={onNextStep}
        />
      </ModalFooter>
    </>
  )
}
