import * as React from 'react'
import { FeedingWorker } from '@modules/feeding/models'
import { PriceBlock } from '@modules/reservations/list/reservation-list-payment-summary'
import { asDecimal, formatPrice, sumDecimalArray } from '@helpers/utils'
import Table from '@components/table/table'

interface Props {
  workers: FeedingWorker[]
}

export const FeedingWorkerTableFooter: React.FC<Props> = ({ workers }) => {
  const totalBrutto = sumDecimalArray(workers.map(row => asDecimal(row.price_brutto)))

  return (
    <Table.Row>
      <Table.Cell colSpan={4} className="text-right align-middle reservation-list__summary-cell">
        <strong className="mr-2">Razem w wybranym okresie:</strong>
      </Table.Cell>
      <Table.Cell colSpan={3} className="reservation-list__summary-cell">
        <PriceBlock title="Cena brutto:" price={formatPrice(totalBrutto.toString())} />
      </Table.Cell>
    </Table.Row>
  )
}
