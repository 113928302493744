import * as React from 'react'
import { InputProps, LabelProps } from 'reactstrap'
import { FormInputMasked } from '@hyper/forms/form-input-masked'
import { useNewFormContext } from '@hyper/forms/hooks/use-new-form-context'
import { useWatch } from 'react-hook-form'

interface Props {
  control?: any
  errors?: any
  name: string
  label?: string
  colSize?: number

  inputProps?: InputProps
  labelProps?: LabelProps
  colStyle?: React.CSSProperties
  colClassName?: string
  formGroupClassName?: string
  disabled?: boolean
  hideErrors?: boolean
  withSeconds?: boolean
}

export const FormInputTime: React.FC<Props> = ({
  disabled,
  colSize,
  label,
  control,
  errors,
  name,
  labelProps,
  colStyle,
  colClassName,
  formGroupClassName,
  hideErrors,
  withSeconds,
}) => {
  const methods = useNewFormContext()

  if (methods) {
    control = methods.control
    errors = methods.formState.errors
  }

  const value = useWatch({ control, name })

  const secondHourDigit = value?.split(':')?.[0].split('')?.[0] === '2' ? /[0-3]/ : /[0-9]/

  const mask = withSeconds
    ? [/[0-2]/, secondHourDigit, ':', /[0-5]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]
    : [/[0-2]/, secondHourDigit, ':', /[0-5]/, /[0-9]/]

  const placeholder = withSeconds ? '__:__:__' : '__:__'

  return (
    <FormInputMasked
      disabled={disabled}
      control={control}
      colSize={colSize}
      formGroupClassName={formGroupClassName}
      colClassName={colClassName}
      errors={errors}
      labelProps={labelProps}
      colStyle={colStyle}
      name={name}
      label={label}
      mask={mask}
      placeholder={placeholder}
      hideErrors={hideErrors}
    />
  )
}
