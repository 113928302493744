import * as React from 'react'
import { ClientUser } from '@models/clients'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { ReservationCreateFormInputs } from '@modules/reservations/create/models'
import { useFormContext } from 'react-hook-form'
import { ClientSelect } from '@components/client-select'
import { AlertDanger } from '@components/alerts'
import { Col } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'

interface Props {
  client: ClientUser | undefined
  setClient: (client: ClientUser | undefined) => void
  isDisabled: boolean
  colSize?: number
}

export const StepClientBox: React.FC<Props> = ({ client, setClient, isDisabled, colSize = 7 }) => {
  const [handleToggleClientDialog] = useModal('ClientDialog', { setClient })

  const {
    formState: { errors },
    clearErrors,
    setValue,
  } = useFormContext<ReservationCreateFormInputs>()

  useDidUpdateEffect(() => {
    clearErrors()
    setValue('email', client?.email ?? '')
    setValue('name', client?.name ?? '')
    setValue('phone', client?.phone ?? '')
    setValue('street', client?.profile?.street ?? '')
    setValue('postcode', client?.profile?.postcode ?? '')
    setValue('city', client?.profile?.city ?? '')
  }, [client])

  return (
    <>
      <ClientSelect
        colSize={colSize}
        client={client}
        handleToggleClientDialog={handleToggleClientDialog}
        setClient={setClient}
        error={errors.email}
        isDisabled={isDisabled}
        additionalClientsElementsToShow={['address', 'subscription_contract']}
      />
      {client && !client.phone && (
        <Col md={12}>
          <AlertDanger>
            <strong className="font-11">Proszę uzupełnić numer telefonu klienta!</strong>
          </AlertDanger>
        </Col>
      )}
    </>
  )
}
