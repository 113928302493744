import * as React from 'react'
import { useForm } from 'react-hook-form'
import { ReceptionBookingDetails } from '@models/reception'
import { Row } from 'reactstrap'
import { FormInputNumber } from '@hyper/forms/form-input-number'
import { AlertDanger } from '@components/alerts'
import { updateServiceRoomData } from '@store/actions/reception-actions'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { ReceptionBookingCheckOutAccessItem } from '@modules/reception/checkout/step-keys/reception-booking-check-out-access-item'
import { ReceptionBookingCheckOutStepKeysInfoRow } from '@modules/reception/checkout/step-keys/reception-booking-check-out-step-keys-info-row'
import { formatPriceShort } from '@helpers/utils'
import { newUserPredicator } from '@modules/reception/common/reception-check-reminders'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useFormRequest } from '@components/hooks/use-api-request'
import { Form } from '@hyper/forms/form'

interface Props {
  nextStep: () => void
  booking: ReceptionBookingDetails
  title?: string
  onKeyLostChange?: (isLost: boolean) => void
}

interface FormInputs {
  check_keys: boolean
  check_prices: boolean
  keys_returned: number | string
}

export const ReceptionBookingCheckOutStepKeys: React.FC<Props> = ({
  nextStep,
  booking,
  title = '1. Zwrot wydanych kluczy',
  onKeyLostChange,
}) => {
  const dispatch = useAppDispatch()
  const user = useAuthenticatedUser()
  const methods = useForm<FormInputs>({
    defaultValues: {
      keys_returned: '',
      check_keys: newUserPredicator(user),
      check_prices: newUserPredicator(user),
    },
  })

  const selectedKeys = parseInt(String(methods.watch('keys_returned')), 10)

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    const keys_returned =
      typeof payload.keys_returned === 'string' ? parseInt(payload.keys_returned, 10) : payload.keys_returned
    await dispatch(updateServiceRoomData(booking.urls.check_out_step_keys, { keys_returned }))
    nextStep()
  }, methods.setError)

  const lostKeyCharge = useAppSelector(
    (state: RootState) => state.appState.appData.configuration.reservation_booking_damage_keys_price_brutto,
  )

  const reminders = [
    {
      id: 'check_keys',
      isClosable: true,
      children: (
        <span>
          Pamiętaj, aby sprawdzić <strong>czy wszystkie klucze zostały zwrócone.</strong>
        </span>
      ),
    },
    {
      id: 'check_prices',
      isClosable: true,
      children: (
        <span>
          Opłaty za zgubienie <strong>kluczy ({formatPriceShort(lostKeyCharge)}).</strong>
        </span>
      ),
    },
  ]

  const isKeyLost = selectedKeys < (booking.service_room_data.keys || 0)

  React.useLayoutEffect(() => {
    methods.setFocus('keys_returned')
  }, [])

  React.useEffect(() => {
    onKeyLostChange?.(isKeyLost)
  }, [isKeyLost])

  return (
    <Form methods={methods} onSubmit={onSubmit} role="step-keys">
      <ReceptionBookingCheckOutAccessItem
        modalTitle={title}
        reminders={reminders}
        accessItemIcon="icon-klucz"
        nextStep={nextStep}
        isSaving={isLoading}
        smallIcon={true}
      >
        <>
          <strong className="font-18 d-block text-dark mb-3">Odbierz klucze od Gości</strong>
          <ReceptionBookingCheckOutStepKeysInfoRow name="Numer lokalu:" value={booking.apartment.name} />
          <ReceptionBookingCheckOutStepKeysInfoRow
            name="Wydanych kluczy:"
            value={booking.service_room_data.keys || 0}
          />
          <Row className="mt-3">
            <FormInputNumber
              label="Ilość zwróconych kluczy"
              colSize={5}
              name="keys_returned"
              minValue={0}
              maxValue={booking.service_room_data.keys || 5}
            />
            {isKeyLost && (
              <AlertDanger className="py-1 ml-2">
                <span className="fw-semi-bold font-11">
                  Opłata za zgubienie jednej pary kluczy to {formatPriceShort(lostKeyCharge)}.
                </span>
              </AlertDanger>
            )}
          </Row>
        </>
      </ReceptionBookingCheckOutAccessItem>
    </Form>
  )
}
