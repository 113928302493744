import * as React from 'react'
import classnames from 'classnames'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { setCalendarDates, setCompactCalendar } from '@store/actions/timeline-actions'
import timelineHelper from '@helpers/timeline-helper'

const TimelineCompactSwitcher: React.FC<{}> = () => {
  const isCompactView = useAppSelector((state: RootState) => state.timelineState.isCompactView)
  const dispatch = useAppDispatch()

  const toggleCompact = React.useCallback(() => {
    ;(async (): Promise<void> => {
      await dispatch(setCompactCalendar(!isCompactView))
      await dispatch(setCalendarDates(timelineHelper.getDefaultCalendarDate(), 'today'))
    })()
  }, [isCompactView])

  return (
    <div className="timeline-switcher" onClick={toggleCompact} role="switch">
      <div className={classnames('timeline-switcher__el', { 'is-active': !isCompactView })}>
        <i className="uil uil-th" />
      </div>
      <div className={classnames('timeline-switcher__el', { 'is-active': isCompactView })}>
        <i className="uil uil-table" />
      </div>
    </div>
  )
}

export default TimelineCompactSwitcher
