import * as React from 'react'
import Spinner from '@hyper/spinner'
import classNames from 'classnames'

interface Props {
  content: any
  children?: JSX.Element | React.ReactNode
  hideText?: boolean
  className?: string
}

export const LoaderPlaceholder: React.FC<Props> = ({ content, hideText, children, className }) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>
    {!content ? (
      <div className={classNames('text-center', className)} role="loader">
        <Spinner className="text-primary" />
        {!hideText && <div className="mt-2">Proszę czekać, trwa pobieranie danych...</div>}
      </div>
    ) : (
      children
    )}
  </>
)
