import * as React from 'react'
import { ImprovementOrder, ImprovementOrderDetails } from '@modules/housekeeping/models'
import { useFormContext } from 'react-hook-form'
import { HousekeepingImprovementOrderRowFormInputs } from '@modules/housekeeping/cleaning-orders/improvement/row'
import { commonObjectPut } from '@store/actions/generic-actions'
import { SaveButton } from '@hyper/button'
import classNames from 'classnames'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useFormRequest } from '@components/hooks/use-api-request'
import { updateHousekeepingImprovementOrderDetails } from '@store/slices/housekeeping-slice'

interface Props {
  improvementOrder: ImprovementOrder
}

export const HousekeepingImprovementOrdersRowActionsOrder: React.FC<Props> = ({ improvementOrder }) => {
  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()
  const { getValues, setError, watch } = useFormContext<HousekeepingImprovementOrderRowFormInputs>()

  const { isLoading, action: handleOrder } = useFormRequest(async () => {
    const payload = getValues()

    dispatch(
      updateHousekeepingImprovementOrderDetails(
        await commonObjectPut<ImprovementOrderDetails>(improvementOrder.urls.assign, {
          company: payload.company?.value,
          users: payload.users?.filter(x => x) || [],
        }),
      ),
    )
    addSuccessNotification('Zlecenie zostało przypisane!')
  }, setError)

  const values = watch()

  const isDisabled = !values.company

  return improvementOrder.status === 'NEW' && !improvementOrder.users.length ? (
    <SaveButton
      disabled={isDisabled}
      className={classNames('btn btn-sm mr-1 housekeeping__orders_table__row__checkin-button', {
        'btn-light': isDisabled,
        'btn-green': !isDisabled,
      })}
      type="button"
      onClick={handleOrder}
      isSaving={isLoading}
      label="Zleć"
      labelSaving="Zlecanie.."
    />
  ) : null
}
