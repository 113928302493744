import * as React from 'react'
import { PackageWholesaleProduct, PackageWholesaleProductDetails } from '@modules/package-wholesale/models'
import { useApiRequest } from '@components/hooks/use-api-request'
import { PackageWholesaleProductCreateModal } from '@modules/package-wholesale/products/create/modal'
import { commonObjectGet } from '@store/actions/generic-actions'
import { BaseModalProps } from '@components/modals/types'
import { useSubscriptionKinds } from '@modules/subscription/hooks/use-subscription-kinds'

interface Props extends BaseModalProps {
  packageWholesaleProduct: PackageWholesaleProduct
}

export const PackageWholesaleProductUpdateModal: React.FC<Props> = ({ toggleIsVisible, packageWholesaleProduct }) => {
  const { data: packageWholesaleProductDetails, action: fetch } = useApiRequest<PackageWholesaleProductDetails>(
    async () => await commonObjectGet<PackageWholesaleProductDetails>(packageWholesaleProduct.urls.details),
  )

  const { appDataStatus } = useSubscriptionKinds()

  React.useEffect(() => {
    fetch()
  }, [packageWholesaleProduct])

  if (!packageWholesaleProductDetails || appDataStatus !== 'ready') {
    return null
  }

  return (
    <PackageWholesaleProductCreateModal
      packageWholesaleProductDetails={packageWholesaleProductDetails}
      toggleIsVisible={toggleIsVisible}
    />
  )
}
