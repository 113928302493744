import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { useFloatingScrollbar } from '@components/hooks/use-floating-scrollbar'

interface ReservationStatsTableProps {
  children: React.ReactNode
}

export const CommonStatsTable: React.FC<ReservationStatsTableProps> = ({ children }) => {
  const tableRef = React.useRef<HTMLTableElement>(null)
  const { tableContainerRef, FloatingScrollbar } = useFloatingScrollbar()

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const moveToTopButtonWrapperStyles = React.useMemo(() => {
    if (!tableRef.current || !tableContainerRef.current) return { width: '100%' }

    return { width: Math.min(tableRef.current.scrollWidth, tableContainerRef.current.clientWidth) }
  }, [tableRef?.current?.scrollWidth, tableContainerRef?.current?.clientWidth])

  return (
    <>
      <div className="reservation-stats__container mt-4" ref={tableContainerRef}>
        <table className="table table-borderless table-striped w-auto mb-0" ref={tableRef}>
          {children}
        </table>
      </div>
      <FloatingScrollbar />
      <div className="reservation-stats__move-top-button" style={moveToTopButtonWrapperStyles}>
        <IconWithText
          icon="uil-arrow-up font-18"
          wrapperClassNames="cursor-pointer"
          textClass="font-11 text-semi-strong user-select-none"
          text="wróć na góre"
          onClick={moveToTop}
        />
      </div>
    </>
  )
}
