import * as React from 'react'
import {
  BookingStatsParams,
  ImprovementsStatsDetails,
  StatsAppDataImprovement,
  StatsTableOptions,
} from '@modules/reservations/models'
import { ReservationStatsTableExpandableRow } from '@modules/reservations/statistics/booking-stats/stats-details/stats-details-table/reservation-stats-table-expandable-row'
import { CommonStatsTable } from '@modules/reservations/statistics/common/common-stats-table'
import { ImprovementsStatsTableExpandedRow } from '@modules/reservations/statistics/improvements-stats/stats-table/improvements-stats-table-expanded-row'
import { ImprovementsStatsTableCell } from '@modules/reservations/statistics/improvements-stats/stats-table/improvements-stats-table-cell'
import { ImprovementStatsTableHeader } from '@modules/reservations/statistics/improvements-stats/stats-table/improvements-stats-table-header'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { createReportTask } from '@store/actions/report-tasks-actions'
import { noop, sortByPosition } from '@helpers/utils'
import { useAppData } from '@components/hooks/use-app-data'
import { useAppDispatch } from '@store/index'

const createImprovementStatsKind = (previous, current, key) => ({ ...previous, [key]: [...previous[key], current] })

interface ImprovementStatsKind {
  improvements_with_warehouse: StatsAppDataImprovement[]
  improvements_without_warehouse: StatsAppDataImprovement[]
}

interface ReservationStatsTableProps {
  details: ImprovementsStatsDetails[]
  improvements: StatsAppDataImprovement[]
  filters: Partial<BookingStatsParams>
}

export const ImprovementsStatsTable: React.FC<ReservationStatsTableProps> = ({ details, improvements, filters }) => {
  const [hoveredDate, setHoveredDate] = React.useState<null | string>(null)

  const handleHoveredElementChange = React.useCallback(date => setHoveredDate(date), [])

  const { urls } = useAppData()
  const dispatch = useAppDispatch()

  const { action: generateReport } = useReportFormRequest(
    async payload => {
      const reportTask = await dispatch(
        createReportTask([urls.reports.report_task_booking_improvement_stats_report, payload]),
      )

      return reportTask
    },
    noop,
    'notification',
  )

  const handleReportGeneration = React.useCallback(generateReport, [filters])

  const getTableCells = React.useCallback(
    (improvement: StatsAppDataImprovement, options?: StatsTableOptions<ImprovementsStatsDetails>) =>
      details.reduce(
        (cum: React.ReactNode[], statsDetails: ImprovementsStatsDetails) => [
          ...cum,
          <ImprovementsStatsTableCell
            key={`${improvement.code}-${statsDetails.day}`}
            statsDetails={statsDetails}
            hoveredDate={hoveredDate === statsDetails.day ? hoveredDate : null}
            element={improvement.code}
            onHoveredElementChange={handleHoveredElementChange}
            options={options}
            onReportGenerate={handleReportGeneration}
            resort={filters.resort}
            accommodationType={filters.accommodation_type}
            isConsumable={improvement.consumable}
          />,
        ],
        [],
      ),
    [details, hoveredDate, filters],
  )

  const statisticImprovements = React.useMemo(
    () => [
      ...new Set(
        details.reduce(
          (previousValue, currentValue) => [
            ...previousValue,
            ...Object.keys(currentValue.stats).reduce((addedImprovements, key) => {
              const improvement = improvements.find(improvement => improvement.code === key)
              return improvement ? [...addedImprovements, improvement] : addedImprovements
            }, []),
          ],
          [],
        ),
      ),
    ],
    [details, improvements],
  )

  const { improvements_with_warehouse, improvements_without_warehouse } = React.useMemo(
    () =>
      statisticImprovements.reduce(
        (previousValue, currentValue): ImprovementStatsKind =>
          currentValue.is_warehouse_item
            ? createImprovementStatsKind(previousValue, currentValue, 'improvements_with_warehouse')
            : createImprovementStatsKind(previousValue, currentValue, 'improvements_without_warehouse'),
        { improvements_with_warehouse: [], improvements_without_warehouse: [] },
      ),
    [statisticImprovements],
  )

  const rowsWithWarehouse = React.useMemo(() => {
    const sorted = sortByPosition(improvements_with_warehouse)
    return sorted.map(improvement => (
      <ImprovementsStatsTableExpandedRow
        key={improvement.id}
        title={improvement.name}
        cells={getTableCells(improvement)}
      />
    ))
  }, [improvements_with_warehouse, hoveredDate])

  const rowsWithoutWarehouse = React.useMemo(() => {
    const sorted = sortByPosition(improvements_without_warehouse)
    return sorted.map(improvement => (
      <ImprovementsStatsTableExpandedRow
        key={improvement.id}
        title={improvement.name}
        cells={getTableCells(improvement)}
      />
    ))
  }, [improvements_without_warehouse, hoveredDate])

  const days = React.useMemo(() => details.map((statsDetails: ImprovementsStatsDetails) => statsDetails.day), [details])

  return (
    <CommonStatsTable>
      <ImprovementStatsTableHeader
        details={details}
        handleHoveredElementChange={handleHoveredElementChange}
        hoveredDate={hoveredDate}
      />
      <tbody>
        <ReservationStatsTableExpandableRow
          title="Ze stanem magazynowym"
          days={days}
          onHoveredElementChange={handleHoveredElementChange}
          hoveredDate={hoveredDate}
          lastCell={null}
        >
          {rowsWithWarehouse}
        </ReservationStatsTableExpandableRow>
        <ReservationStatsTableExpandableRow
          title="Pozostałe ulepszenia"
          days={days}
          onHoveredElementChange={handleHoveredElementChange}
          hoveredDate={hoveredDate}
          lastCell={null}
        >
          {rowsWithoutWarehouse}
        </ReservationStatsTableExpandableRow>
      </tbody>
    </CommonStatsTable>
  )
}
