import * as React from 'react'
import { PageTitle } from '@hyper/page-title'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { Card, CardBody } from 'reactstrap'
import { EcommerceStatsFilters } from '@modules/ecommerce/stats/ecommerce-stats-filters'
import { useAppDispatch, useAppSelector } from '@store/index'
import { useTableFilters } from '@components/table/use-table-filters'
import { EcommerceStatsTable } from '@modules/ecommerce/stats/ecommerce-stats-table'
import { EcommerceStatsParams } from '@modules/ecommerce/models'
import { getEcommerceStats } from '@store/actions/ecommerce-actions'
import { selectEcommerceStats } from '@store/slices/ecommerce-slice'

const defaultFilters: EcommerceStatsParams = {
  ordering: '-name',
  page: 1,
  page_size: 30,
  search: '',
  sell_date_after: null,
  sell_date_before: null,
}

export const EcommerceStatsView: React.FC = () => {
  useMenuActive(NavigationPath.EcommerceStats)

  const items = useAppSelector(selectEcommerceStats)

  const dispatch = useAppDispatch()

  const action = React.useCallback(async filters => await dispatch(getEcommerceStats(filters)), [])

  const { isLoading, filters, setFilters } = useTableFilters<EcommerceStatsParams>({
    defaultFilters,
    action,
  })

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: 'Ecommerce ', path: '', active: true }]} title="E-Sklep - Statystyki" />
      <Card>
        <CardBody>
          <EcommerceStatsFilters filters={filters} setFilters={setFilters} defaultFilters={defaultFilters} />
          <EcommerceStatsTable isLoading={isLoading} filters={filters} setFilters={setFilters} items={items} />
        </CardBody>
      </Card>
    </>
  )
}
