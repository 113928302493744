import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { CouponsTypesTableActions } from '@modules/coupons/types/table/coupons-types-table-actions'
import { Label } from 'reactstrap'
import DateRangeInput from '@components/date/date-range-input'
import { ColAuto } from '@hyper/col-auto'
import { FormSelect } from '@hyper/forms/form-select'
import { useAppData } from '@components/hooks/use-app-data'
import { createSelectOption } from '@helpers/utils'
import { YesNoOptions } from '@helpers/consts'
import { CouponTypesFilters } from '@modules/coupons/types/index'
import { CommonTableFilters } from '@components/table/common-table-filters'

interface Props {
  filters: CouponTypesFilters
  defaultFilters: CouponTypesFilters
  setFilters: (filters: CouponTypesFilters) => void
}

export const CouponsTypesFilters = ({ setFilters, filters, defaultFilters }: Props) => {
  const methods = useForm<CouponTypesFilters>({ defaultValues: filters })
  const { resorts } = useAppData()
  const resortOptions = resorts.map(resort => createSelectOption(resort.name, resort.id))

  return (
    <FormProvider {...methods}>
      <CouponsTypesTableActions />
      <CommonTableFilters
        filters={filters}
        defaultFilters={defaultFilters}
        setFilters={setFilters}
        searchWrapperParams={{ className: 'col-auto ml-auto' }}
      >
        <FormSelect
          options={resortOptions}
          name="resort"
          label="Lokalizacja"
          selectProps={{ isClearable: true }}
          formPlainProps={{ colSize: 'auto', colStyle: { width: 260 } }}
        />
        <ColAuto>
          <Label>Data dostępności:</Label>
          <DateRangeInput
            startDateName="date_from"
            endDateName="date_to"
            isClearable={true}
            wrapperClassName="is-range"
          />
        </ColAuto>
        <FormSelect
          options={YesNoOptions}
          name="is_active"
          label="Aktywny"
          selectProps={{ isClearable: true }}
          formPlainProps={{ colSize: 'auto', colStyle: { width: 140 } }}
        />
      </CommonTableFilters>
    </FormProvider>
  )
}
