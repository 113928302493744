import * as React from 'react'
import { Col, Row } from 'reactstrap'
import { useFormContext, useWatch } from 'react-hook-form'
import { BenefitProgramCreateInputs } from '@modules/benefit-program/create/index'
import classNames from 'classnames'
import { IconWithText } from '@components/icon-with-text'
import { useBenefitProgramAdvisorsOptions } from '@modules/benefit-program/hooks/use-benefit-program-advisors-options'
import { useSellers } from '@components/hooks/use-grouped-sellers'

export const BenefitProgramCreateFormSummary: React.FC = () => {
  const advisors = useBenefitProgramAdvisorsOptions()
  const sellers = useSellers()

  const { control } = useFormContext<BenefitProgramCreateInputs>()
  const formData = useWatch({ control: control })

  const selectedAdvisor = advisors.find(advisor => advisor.value === formData.advisor?.value)
  const selectedSeller = sellers.find(seller => seller.id === formData.seller?.value)

  return (
    <div className="px-2">
      <h4 className="text-secondary fw-semi-bold mb-3">Podsumowanie</h4>

      <h5 className="fw-semi-bold mb-2">Dane kontaktowe:</h5>
      <DataRow label="NIP:" value={formData.invoice_nip} />
      <DataRow label="Nazwa firmy:" value={formData.invoice_company} />
      <DataRow
        label="Adres:"
        value={
          <div>
            <div className="mb-1">{formData.invoice_street} </div>
            {formData.invoice_postcode}, {formData.invoice_city}
          </div>
        }
      />

      <DataRow
        className="mt-3"
        label="Osoba reprezentująca:"
        value={formData.users?.map((representative, index) => (
          <div key={index} className={classNames({ 'mb-3': index < (formData.users?.length ?? 0) - 1 })}>
            <div>{representative.name}</div>
            <div className="my-1">{representative.email}</div>
            <div>{representative.phone}</div>
          </div>
        ))}
      />

      <div className="border-top mx-n2 px-2 mt-3 pt-2">
        <h5 className="fw-semi-bold mb-2">Dane sprzedażowe:</h5>
        <DataRow
          label="Sprzedawca:"
          value={
            selectedSeller ? (
              <div>
                <div>{selectedSeller.name}</div>
                <div className="my-1">{selectedSeller.phone}</div>
              </div>
            ) : (
              'Brak wybranego sprzedawcy'
            )
          }
        />
        <DataRow
          className="mt-3"
          label="Opiekun:"
          value={
            selectedAdvisor ? (
              <div>
                <div>{selectedAdvisor?.value}</div>
                <div className="my-1">{selectedAdvisor?.value}</div>
                <div>{formData.name}</div>
              </div>
            ) : (
              'Brak wybranego opiekuna'
            )
          }
        />
      </div>

      <div className="border-top mx-n2 px-2 mt-3 pt-2">
        <h5 className="fw-semi-bold mb-2">Parametry sprzedaży:</h5>
        <Row>
          <IconWithText
            wrapperClassNames="col-4 fw-semi-bold"
            icon="uil-check text-secondary align-self-start font-18"
            text={
              <div>
                Plan Apartament
                <div className="mt-2">
                  <div className="mb-1">Cena dla pracodawcy: {formData.benefit_apartment_price_employer}</div>
                  Cena dla pracownika: {formData.benefit_apartment_price_worker}
                </div>
              </div>
            }
          />

          <IconWithText
            wrapperClassNames="col-8 fw-semi-bold"
            icon="uil-check text-secondary align-self-start font-18"
            text={
              <div>
                Plan Domek
                <div className="mt-2">
                  <div className="mb-1">Cena dla pracodawcy: {formData.benefit_house_price_employer}</div>
                  Cena dla pracownika: {formData.benefit_house_price_worker}
                </div>
              </div>
            }
          />
        </Row>
      </div>
    </div>
  )
}

const DataRow = ({ label, value, className }: { label: string; value: React.ReactNode; className?: string }) => (
  <Row className={classNames('mb-1', className)}>
    <Col md={4} className="text-muted">
      {label}
    </Col>
    <Col md={8} className="fw-semi-bold">
      {value}
    </Col>
  </Row>
)
