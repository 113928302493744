import { Middleware } from '@reduxjs/toolkit'

export const throwMiddleware: Middleware = () => next => action => {
  next(action)
  if (action?.error) {
    if (action.error.name === 'ConditionError') return

    throw action.error
  }
}
