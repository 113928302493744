import * as React from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Button, Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { packageCreate } from '@api/package'
import {
  ReceptionBookingCheckStep,
  ReceptionBookingCheckSteps,
  showStepError,
} from '@modules/reception/common/reception-booking-check-steps'
import { PackageCreateFormClientData } from '@modules/package/create/steps/client-data'
import { PackageCreateFormParams } from '@modules/package/create/steps/params'
import { PackageCreateFormInformation } from '@modules/package/create/steps/information'
import { SaveButton } from '@hyper/button'
import { useFormRequest } from '@components/hooks/use-api-request'
import { ClientDetails } from '@models/clients'
import { PackageType } from '@models/package'
import { BaseModalProps } from '@components/modals/types'
import { parseISODate } from '@helpers/date-helper'
import { startOfToday } from 'date-fns'
import { updatePackageDetails } from '@store/slices/package-slice'
import { PackageCreateFormSummary } from '@modules/package/create/form-summary'
import { useModal } from '@components/modals/use-modal'
import { useProductSaleConfirmationModal } from '@components/sale/use-product-sale-confirmation-modal'
import { usePackageAppData } from '@modules/package/hooks/use-package-app-data'
import { Form } from '@hyper/forms/form'
import { DepositPaymentDeadline } from '@models/dashboard'

interface Props extends BaseModalProps {
  client?: ClientDetails
  type: PackageType
}

export interface PackageCreateFormInputs {
  apartment_single_price: string
  apartments_mountains: number
  apartments_sea: number
  default_code_activate: boolean
  deposit_amount: string
  email: string
  defaults_days: number
  email_notifications: string
  expire_after: Date
  house_single_price: string
  houses_mountains: number
  prefix: string

  houses_sea: number
  invoice_city: string
  invoice_company: string
  invoice_custom_number: number
  invoice_nip: string
  instruction: File | null
  invoice_postcode: string
  invoice_street: string
  kind: CustomReactSelectOption | undefined
  kind_version: CustomReactSelectOption | undefined
  name: string
  package_source_type: 'DEFAULT' | 'HPI'
  phone: string
  price_brutto: number
  rent_towel_amount: number
  deposit_payment_deadline: DepositPaymentDeadline
  seller: CustomReactSelectOption | undefined
  send_email: boolean
  with_cleaning_option: boolean
  with_first_car_parking: boolean
  with_second_car_parking: boolean
  with_zoo_borysew_tickets: boolean
  zoo_borysew_tickets_single_price_brutto: string
  with_suntago_tickets: boolean
  suntago_tickets_single_price_brutto: string
}

const steps: ReceptionBookingCheckStep[] = [
  {
    step: 1,
    description: <>Dane klienta</>,
    fields: [
      'name',
      'phone',
      'email',
      'email-notifications',
      'invoice_nip',
      'invoice_company',
      'invoice_street',
      'invoice_postcode',
      'invoice_city',
      'invoice_custom_number',
    ],
  },
  {
    step: 2,
    description: <>Parametry sprzedaży</>,
    fields: [
      'house_single_price',
      'defaults_days',
      'prefix',
      'apartment_single_price',
      'houses_sea',
      'apartments_sea',
      'houses_mountains',
      'apartments_mountains',
      'with_cleaning_option',
      'rent_towel_amount',
      'deposit_payment_deadline',
      'default_code_activate',
      'package_type_source',
      'kind',
      'kind_version',
      'expire_after',
      'deposit_amount',
    ],
  },
  {
    step: 3,
    description: <>Informacje handlowe</>,
    fields: ['seller'],
  },
  {
    step: 4,
    description: 'Podsumowanie sprzedaży',
  },
]

export const PackageCreateModal: React.FC<Props> = ({ toggleIsVisible, type }) => {
  const [step, setStep] = React.useState(1)

  const expireAfter = useAppSelector((state: RootState) => state.packageState.appData.default_package_expire_after)
  const dispatch = useAppDispatch()

  const { showConfirmationPackageCreation } = useProductSaleConfirmationModal()
  const { prices } = usePackageAppData()

  const [showPackageDetailsModal] = useModal('PackageDetailsModal')

  const methods = useForm<PackageCreateFormInputs>({
    defaultValues: {
      defaults_days: 6,
      deposit_payment_deadline: '12_months',
      expire_after: parseISODate(expireAfter) || startOfToday(),
      houses_sea: 0,
      apartments_sea: 0,
      houses_mountains: 0,
      apartments_mountains: 0,
      with_cleaning_option: false,
      rent_towel_amount: 0,
      send_email: false,
      zoo_borysew_tickets_single_price_brutto: prices.package_zoo_borysew_tickets_default_price_brutto,
      suntago_tickets_single_price_brutto: prices.package_zoo_borysew_tickets_default_price_brutto,
    },
  })

  const { isLoading, action: onSubmit } = useFormRequest(
    async (payload: PackageCreateFormInputs) => {
      try {
        const packageDetails = await packageCreate(payload, type)
        showConfirmationPackageCreation(() => showPackageDetailsModal(null, { packageData: packageDetails }))
        toggleIsVisible()
        dispatch(updatePackageDetails(packageDetails))
      } catch (error) {
        showStepError(setStep, steps, error)

        throw error
      }
    },
    methods.setError,
    { showGlobalError: true },
  )

  const handlePrevStep = () => setStep(curr => Math.max(1, curr - 1))
  const handleNextStep = () => setStep(curr => curr + 1)

  const data = useWatch({ control: methods.control })

  const isNextStepDisabled = React.useMemo(() => {
    if (step === 1) return !data.name || !data.email
  }, [data, step])

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Sprzedaj pakiet firmowy</ModalHeader>
      <ReceptionBookingCheckSteps step={step} steps={steps} />
      <ModalBody className="pb-0">
        {step === 1 && <PackageCreateFormClientData type={type} />}
        {step === 2 && <PackageCreateFormParams type={type} />}
        {step === 3 && <PackageCreateFormInformation />}
        {step === 4 && <PackageCreateFormSummary type={type} />}
      </ModalBody>

      <ModalFooter style={{ display: 'block' }}>
        <Row>
          <Col md={6} className="p-0">
            {step !== 1 && (
              <Button onClick={handlePrevStep} className="btn btn-light">
                Wstecz
              </Button>
            )}
          </Col>
          <Col md={6} className="text-right p-0">
            {step === 4 ? (
              <SaveButton
                role="submit"
                labelSaving="Sprzedawanie..."
                label="Sprzedaj"
                className="btn btn-green"
                isSaving={isLoading}
              />
            ) : (
              <Button onClick={handleNextStep} className="btn btn-green" role="next-step" disabled={isNextStepDisabled}>
                Następny krok
              </Button>
            )}
          </Col>
        </Row>
      </ModalFooter>
    </Form>
  )
}
