import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'

interface Props extends BaseModalProps {
  onConfirm: () => void
}

export const BandExtensionInformationModal: React.FC<Props> = ({ toggleIsVisible, onConfirm }) => {
  const handleConfirm = () => {
    onConfirm()
    toggleIsVisible()
  }

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>Kodowanie opasek</ModalHeader>
      <ModalBody>Dodanie tego ulepszenia wymaga ponownego zakodowania opasek Gości</ModalBody>
      <ModalFooter>
        <Button className="btn-light" onClick={toggleIsVisible}>
          Anuluj
        </Button>
        <Button className="btn-green border-0 shadow-none" onClick={handleConfirm}>
          Dodaj ulepszenie
        </Button>
      </ModalFooter>
    </>
  )
}
