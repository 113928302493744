import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { Button, ModalFooter } from 'reactstrap'
import { CashbackVoucherDetails } from '@modules/products/cashback-vouchers/models'
import { useUpdateCashbackVoucherMutation } from '@api/cashback'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApiRequest } from '@components/hooks/use-api-request'

interface Props extends BaseModalProps {
  cashbackVoucherDetails: CashbackVoucherDetails
  handleRefresh: () => void
}

export const CashbackVoucherModalFooter: React.FC<Props> = ({
  handleRefresh,
  cashbackVoucherDetails,
  toggleIsVisible,
}) => {
  const { addSuccessMessage } = useNotificationHook()

  const [handleGenerate] = useUpdateCashbackVoucherMutation()

  const { action: handleGenerateVoucher } = useApiRequest(async () => {
    await handleGenerate({
      url: cashbackVoucherDetails.urls.generate,
    }).unwrap()
    handleRefresh()
    addSuccessMessage('Sukces', 'Voucher został wygenerowany')
  })

  return (
    <ModalFooter>
      {cashbackVoucherDetails.status === 'cancelled' && (
        <Button onClick={handleGenerateVoucher} color="green" className="mr-auto" type="button">
          Generuj
        </Button>
      )}

      <Button color="light" type="button" onClick={toggleIsVisible}>
        Zamknij
      </Button>
    </ModalFooter>
  )
}
