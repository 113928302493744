import * as React from 'react'
import { LoginUIComponent } from '@components/login-ui-component'
import { Link } from 'react-router-dom'
import { NavigationPath } from '@models/routes'

export const LogoutView: React.FC = () => (
  <LoginUIComponent>
    <h2 className="unauthorized__header">Wylogowano</h2>
    <p className="unauthorized__text">
      <Link to={NavigationPath.AuthorizationLogin} className="btn btn-secondary">
        Wróć do logowania
      </Link>
    </p>
  </LoginUIComponent>
)
