import * as React from 'react'
import { Label, Row } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { SubmitFilter } from '@modules/reception/common/filters/submit-filter'
import DateRangeInput from '@components/date/date-range-input'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { FeedingWorkerFiltersParams } from '@modules/feeding/workers/index'
import { ColAuto } from '@hyper/col-auto'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { Form } from '@hyper/forms/form'
import { Resort } from '@models/booking'
import { createSelectOption } from '@helpers/utils'
import { FormSelect } from '@hyper/forms/form-select'

interface FeedingWorkerFiltersProps {
  filters: FeedingWorkerFiltersParams
  setFilters: (filters: FeedingWorkerFiltersParams) => void
  defaultFilters: FeedingWorkerFiltersParams
}

export const FeedingWorkerFilters: React.FC<FeedingWorkerFiltersProps> = ({ setFilters, filters, defaultFilters }) => {
  const methods = useForm<FeedingWorkerFiltersParams>({
    defaultValues: filters,
  })

  const handleReset = () => {
    methods.reset()
    setFilters(defaultFilters)
  }

  const onSubmit = async (payload: FeedingWorkerFiltersParams) => setFilters({ ...filters, ...payload })
  const user = useAuthenticatedUser()

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  const resortOptions = user.resorts.map((resort: Resort) => createSelectOption(resort.name, resort.id))

  return (
    <Form methods={methods} onSubmit={onSubmit} className="form form-style-filter mb-2">
      <Row>
        <FormSelect
          options={resortOptions}
          name="resorts"
          label="Ośrodek"
          formPlainProps={{ colSize: 3 }}
          selectProps={{ isClearable: true }}
        />

        <ColAuto style={{ minWidth: 320 }}>
          <div className="d-flex">
            <Label className="mr-1">Data</Label>
          </div>

          <DateRangeInput
            isClearable={true}
            wrapperClassName="is-range"
            inputGroupClassName="form-group"
            startDateName="date_from"
            endDateName="date_to"
          />
        </ColAuto>
        <SubmitFilter handleReset={handleReset} />
        <SearchFilter colClassName="col-md-2" />
      </Row>
    </Form>
  )
}
