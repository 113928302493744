import * as React from 'react'
import { Form } from '@hyper/forms/form'
import { useForm, useWatch } from 'react-hook-form'
import { useFormRequest } from '@components/hooks/use-api-request'
import DateRangeInput from '@components/date/date-range-input'
import { Alert, Button, CustomInput, Label } from 'reactstrap'
import { FormSelect } from '@hyper/forms/form-select'
import { FormInput, FormPlain, FormPlainError } from '@hyper/forms'
import { SaveButton } from '@hyper/button'
import { IconWithText } from '@components/icon-with-text'
import { extractInnerRef } from '@helpers/forms'
import { FormCheckbox } from '@hyper/forms/form-checkbox'
import classnames from 'classnames'
import {
  asDecimal,
  createSelectOption,
  extractSelectOptionsValues,
  formatPercentage,
  formatPrice,
} from '@helpers/utils'
import { CustomReactSelectOption, makeDefaultSelectOption } from '@components/custom-react-select'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { ReceptionBookingDetails } from '@models/reception'
import { parseISODate, toDefaultDateFormat } from '@helpers/date-helper'
import { commonObjectPost, commonObjectPut } from '@store/actions/generic-actions'
import { FeedingDiscount } from '@modules/reception/checkin/step-feeding/new-feeding/models'
import { FeedingKindsOptions } from '@modules/feeding/consts'
import { useNewFeedingCalculationsFetch } from '@modules/reception/checkin/step-feeding/new-feeding/use-new-feeding-calculations-fetch'
import { createDefaultNewFeedingFormValues } from '@modules/reception/checkin/step-feeding/new-feeding/utils'

interface FormInputs {
  date_from: Date
  date_to: Date
  feedingFor: 'all' | 'selected'
  kind: CustomReactSelectOption
  feeding_kind: CustomReactSelectOption
  value: string
  booking_guests: { [key: number]: { value: boolean } }
  reason: string
}

interface Props {
  discount: FeedingDiscount | null
  onClearForm: () => void
  onDiscountUpsert: (discount: FeedingDiscount) => void
  bookingDetails: ReceptionBookingDetails
  onFeedingChange: (feedingValues) => void
}

export const ReceptionBookingNewFeedingDiscountForm = ({
  discount,
  bookingDetails,
  onClearForm,
  onDiscountUpsert,
  onFeedingChange,
}: Props): React.ReactElement => {
  const { fetchCalculations } = useNewFeedingCalculationsFetch()

  const methods = useForm<FormInputs>({
    defaultValues: {
      feedingFor: discount?.for_all_guests || !discount ? 'all' : 'selected',
      kind: makeDefaultSelectOption(DiscountKindOptions, discount?.kind) ?? DiscountKindOptions[0],
      value: discount?.current_value ?? '0',
      feeding_kind: makeDefaultSelectOption(FeedingKindsOptions, discount?.feeding_kind) ?? FeedingKindsOptions[2],
      booking_guests:
        bookingDetails?.guests.reduce(
          (prev, curr) => ({
            ...prev,
            [curr.id]: { value: discount?.booking_guests.some(guest => guest === curr.id) },
          }),
          {},
        ) ?? [],
      date_from: parseISODate(discount?.date_from ?? null) as Date,
      date_to: parseISODate(discount?.date_to ?? null) as Date,
      reason: '',
    },
  })

  const [feedingFor, discountKind, discountValue, feedingKind] = useWatch({
    control: methods.control,
    name: ['feedingFor', 'kind.value', 'value', 'feeding_kind.value'],
  })

  const isFeedingForSelectedGuestChosen = feedingFor === 'selected'

  const { action, isLoading } = useFormRequest(async () => {
    methods.clearErrors()
    const { feedingFor, booking_guests, ...payload }: FormInputs = methods.getValues()

    const guestsPayload =
      feedingFor === 'all'
        ? { for_all_guests: true }
        : {
            booking_guests: Object.entries(booking_guests).reduce(
              (prev, [guestId, value]) => [...prev, ...(value.value ? [guestId] : [])],
              [],
            ),
          }

    const requestData = extractSelectOptionsValues({ ...guestsPayload, ...payload })

    let newDiscount: FeedingDiscount

    if (discount) {
      newDiscount = await commonObjectPut<FeedingDiscount>(discount.urls.details, requestData)
    } else {
      newDiscount = await commonObjectPost<FeedingDiscount>(
        bookingDetails.urls.reservation_feeding_discounts,
        requestData,
      )
    }

    await fetchCalculations(bookingDetails, response =>
      onFeedingChange(createDefaultNewFeedingFormValues(response.details)),
    )

    onDiscountUpsert(newDiscount)

    onClearForm()
  }, methods.setError)

  useDidUpdateEffect(() => {
    methods.setValue('booking_guests', [])
  }, [feedingFor])

  const dateFrom = useWatch({ control: methods.control, name: 'date_from' })
  const dateTo = useWatch({ control: methods.control, name: 'date_to' })

  return (
    <Form methods={methods} onSubmit={methods.handleSubmit(action)}>
      <div className="bg-grey-light mx-n2 px-2">
        <hr className="mx-n2" />
        <div>
          <h5 className="mb-3">1. Wprowadź wartości rabatowania</h5>
          <div className="row align-items-center">
            <Label className="col-4">Rabatowanie w dniach</Label>
            <DateRangeInput
              dateFormat="dd LLLL"
              isClearable={false}
              startDateName="date_from"
              endDateName="date_to"
              inputGroupClassName="col-8"
              wrapperClassName="is-full-width"
              placeholder="Całe wyżywienie"
              inputClassName="text-dark"
              placeholderClassName="text-dark"
              minDate={parseISODate(bookingDetails.date_from)}
              maxDate={parseISODate(bookingDetails.date_to)}
            />
          </div>
          <FormSelect
            options={FeedingKindsOptions}
            name="feeding_kind"
            label="Rabat obejmiuje"
            labelProps={{ className: 'col-4 pl-0' }}
            selectProps={{ className: 'col-8' }}
            formPlainProps={{ formGroupClassName: 'row mt-2 align-items-center' }}
          />
          <FormSelect
            options={DiscountKindOptions}
            name="kind"
            label="Wartość rabatu"
            labelProps={{ className: 'col-4 pl-0' }}
            selectProps={{ className: 'col-8' }}
            formPlainProps={{ formGroupClassName: 'row mt-2 align-items-center' }}
          />
          <div className="col-8 ml-auto d-flex flex-wrap mb-2">
            <FormInput
              name="value"
              colSize={4}
              colClassName="pl-0"
              type="currency"
              hideErrors
              formGroupClassName="mb-0"
            />
            <strong className="pt-2">{discountKind === 'percentage' ? '%' : 'zł'}</strong>
            <FormPlainError name="value" className="w-100" />

            {feedingKind && discountKind && asDecimal(discountValue).gt(0) && (
              <Alert className="py-1 px-1 alert-info col-12 mt-2">
                <span className="d-block font-12">
                  Nałożenie rabatu spowoduje obniżenie ceny <strong>{feedingDeclination[feedingKind]}</strong>
                  <span className="text-nowrap">
                    {dateFrom && dateTo
                      ? ` w dniach ${toDefaultDateFormat(dateFrom)} - ${toDefaultDateFormat(dateTo)}`
                      : ' podczas całego pobytu'}{' '}
                    o {discountKind === 'percentage' ? formatPercentage(discountValue) : formatPrice(discountValue)}
                  </span>
                </span>
              </Alert>
            )}
          </div>
        </div>

        <hr className="mx-n2 mt-0" />

        <div>
          <h5 className="mb-2">2. Kogo dotyczy rabat</h5>
          <p>Wskaż Gości rezerwacji, którzy mają być objęci rabatem:</p>
          <FormPlain
            name="feedingFor"
            formGroupClassName={classnames('mb-0', { 'pb-2': !isFeedingForSelectedGuestChosen })}
            colClassName="px-0"
          >
            <CustomInput
              className="d-inline-block mr-2"
              type="radio"
              value="all"
              {...extractInnerRef(methods.register('feedingFor'))}
              id="all"
              label="Wszyscy Goście"
            />
            <CustomInput
              className="d-inline-block"
              type="radio"
              value="selected"
              {...extractInnerRef(methods.register('feedingFor'))}
              id="selected"
              label="Wybrane osoby"
            />
            {isFeedingForSelectedGuestChosen && (
              <div className="mt-2 pb-2">
                {bookingDetails.guests.map(guest => (
                  <React.Fragment key={guest.id}>
                    <hr className="mx-n2 my-1" />
                    <FormCheckbox
                      name={`booking_guests.${guest.id}.value`}
                      value={guest.id}
                      label={
                        <span>
                          {guest.name} <small className="font-10 pl-2">{guest.type_display}</small>
                        </span>
                      }
                    />
                  </React.Fragment>
                ))}
                <FormPlainError name="booking_guests" />
              </div>
            )}
          </FormPlain>
        </div>
        <hr className="mx-n2 mt-0" />
        <FormInput name="reason" colClassName="px-0 mt-2" placeholder="Wpisz uzasadnienie (wymagane)" />
      </div>
      <hr className="mx-n2 mt-0" />

      <div className="d-flex justify-content-between">
        <Button color="light" onClick={onClearForm}>
          Anuluj
        </Button>
        <SaveButton
          isSaving={isLoading}
          label={<IconWithText text={discount ? 'Zapisz rabat' : 'Zastosuj rabat'} icon="uil-check font-15" />}
          className="btn btn-green"
          onClick={action}
        />
      </div>
    </Form>
  )
}

const DiscountKindOptions = [createSelectOption('Procentowo', 'percentage'), createSelectOption('Kwotowo', 'value')]

const feedingDeclination = {
  breakfast: 'każdego śniadania',
  dinner: 'każdej obiadokolacji',
  breakfast_with_dinner: 'każdego śniadania oraz obiadokolacji',
}
