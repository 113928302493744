import * as React from 'react'
import { StepReservationBox } from '@modules/reservations/create/step-reservation-box'
import { ReceptionBookingCheck } from '@modules/reception/common/reception-booking-check'
import { FormProvider, useFormContext } from 'react-hook-form'
import { useApiRequest, useFormRequest } from '@components/hooks/use-api-request'
import { receptionBookingDetailsSelector } from '@store/selectors/reception'
import { commonObjectPatch } from '@store/actions/generic-actions'
import { ClientDetails } from '@models/clients'
import ReservationSummary from '@modules/reservations/create/step-summary/reservation-summary'
import ImprovementsSummary from '@modules/reservations/create/step-summary/improvements-summary'
import ClientSummary from '@modules/reservations/create/step-summary/client-summary'
import InfoSummary from '@modules/reservations/create/step-summary/info-summary'
import PaymentSummary from '@modules/reservations/create/step-summary/payment-summary'
import { FormCheckbox } from '@hyper/forms/form-checkbox'
import { ReservationCreateFormInputs } from '@modules/reservations/create/models'
import { useAppSelector } from '@store/index'

export interface StepSummaryProps {
  previousStep: () => void
  closeModal: () => void
  stepNumber: number
}

const StepSummary = ({ previousStep, closeModal, stepNumber }: StepSummaryProps): JSX.Element | null => {
  const methods = useFormContext<ReservationCreateFormInputs>()
  const reservation = useAppSelector(receptionBookingDetailsSelector)

  const { invoice_nip, invoice_type } = methods.watch()

  const { action: finishReservation } = useApiRequest(async () => {
    if (reservation.urls) {
      await commonObjectPatch<ClientDetails>(reservation.urls.finish, {
        send_notification: methods.getValues('send_notification'),
      })
      closeModal()
      document.location.href = reservation.urls.details
    }
  })

  const { isLoading: isSubmitting, action: onSubmit } = useFormRequest(async () => {
    await finishReservation()
  }, methods.setError)

  return (
    <StepReservationBox
      title={`${stepNumber}. Podsumowanie rezerwacji`}
      footer={
        <ReceptionBookingCheck
          nextLabel="Zrobione"
          previousStep={previousStep}
          isSaving={isSubmitting}
          onSave={onSubmit}
        />
      }
    >
      <FormProvider {...methods}>
        <div className="d-flex">
          <div className="w-50 pr-3">
            <ReservationSummary reservation={reservation} showInvoice={!!invoice_nip} invoiceType={invoice_type} />

            <ImprovementsSummary improvements={reservation.improvements} />
          </div>

          <div className="w-50">
            <ClientSummary reservation={reservation} />
          </div>
        </div>
        <hr />
        <div className="d-flex">
          <div className="w-50 pr-3">
            <InfoSummary reservation={reservation} />
          </div>
          <div className="w-50">
            <PaymentSummary reservation={reservation} />
          </div>
        </div>
        <hr />
        <h5>Potwierdzenie wstępnej rezerwacji</h5>
        <FormCheckbox name="send_notification" label="Przesyłane na adres e-mail" />
      </FormProvider>
    </StepReservationBox>
  )
}

export default StepSummary
