import * as React from 'react'
import { asDecimal, formatNumberWithThousandSeparator, formatPriceShort } from '@helpers/utils'
import { EnhancedShopProduct } from '@modules/shop/pos/models'
import Decimal from 'decimal.js'
import { SpinInput } from '@components/spin-input'
import { useFormContext, useWatch } from 'react-hook-form'
import { ShopPOSWithParamsFormInputs } from '@modules/shop/pos/shop-pos-with-params'
import { useShopParking } from '@modules/shop/pos/product-selection/product-configuration-modals/use-shop-parking'
import { ProductCalculation } from '@modules/shop/pos/product-calculator-provider'

interface ShopPurchaseSummaryRowProps {
  product: EnhancedShopProduct
  ticketFactor: Decimal
  index: number
  removeProduct: (index: number) => void
  updateProduct: (index: number, product: EnhancedShopProduct) => void
  productCalculation: ProductCalculation | undefined
  resort: string
}

export const ShopPurchaseSummaryRow: React.FC<ShopPurchaseSummaryRowProps> = ({
  index,
  removeProduct,
  product,
  ticketFactor,
  updateProduct,
  productCalculation,
  resort,
}) => {
  const handleRemove = () => removeProduct(index)
  const { control, setValue } = useFormContext<ShopPOSWithParamsFormInputs>()
  const { updateParking } = useShopParking(resort)

  const paymentEnabled = useWatch({
    control,
    name: 'paymentEnabled',
  })

  const isCorrectAmount = asDecimal(product.amount).gt(0)

  const totalProductPrice = isCorrectAmount ? asDecimal(product.price_brutto).mul(product.amount).toString() : '0'
  const price = productCalculation?.priceBrutto ?? totalProductPrice

  const equivalentTicketPrice = isCorrectAmount
    ? formatNumberWithThousandSeparator(asDecimal(price).div(ticketFactor).toString())
    : '0'

  const handleBlur = () => {
    if (isNaN(product.amount)) setValue(`addedProducts.${index}.amount`, 1)
  }

  const handleShowParkingConfiguration = () => {
    updateParking(updateProduct, product, index)
  }

  const isParking = product.type === 'parking'

  return (
    <tr className="border-bottom">
      <td className="align-middle">
        {product.name} {isParking && product.params?.register_car_number.toUpperCase()}
      </td>
      <td className="align-middle">
        <SpinInput
          inputName={`addedProducts.${index}.amount`}
          inputProps={{
            onBlur: handleBlur,
            disabled: paymentEnabled || isParking,
            min: 1,
            max: product.in_warehouse ? product.available_amount : undefined,
            tabIndex: -1,
            autoFocus: false,
          }}
        />
      </td>
      <td className="text-dark font-weight-bold align-middle">{formatPriceShort(product.price_brutto)}</td>
      <td className="text-dark font-weight-bold align-middle">
        {formatPriceShort(productCalculation?.priceBrutto ?? totalProductPrice)}
      </td>
      <td className="align-middle text-right">
        <span className="d-inline-block mr-1">lub {equivalentTicketPrice}</span>
        <i className="uil-ticket" />
      </td>
      <td className="text-right align-middle">
        {!paymentEnabled && (
          <div className="d-flex">
            <i className="uil-trash-alt cursor-pointer font-16" onClick={handleRemove} data-testid="remove-product" />
            {product.params && (
              <i
                className="uil-eye cursor-pointer font-16 ml-1"
                onClick={handleShowParkingConfiguration}
                data-testid="remove-product"
              />
            )}
          </div>
        )}
      </td>
    </tr>
  )
}
