import { createAsyncThunk } from '@reduxjs/toolkit'
import { AsyncThunkParams, RootState } from '@store/index'
import { commonObjectGet, commonObjectPatch, commonObjectPost, wrapWithError } from '@store/actions/generic-actions'
import { PaginationResponse } from '@models/dashboard'
import {
  PaymentAppData,
  PaymentDayPointReport,
  PaymentDayReport,
  PaymentDayReportDetails,
  PaymentDayUserReport,
  PaymentRegister,
  PaymentRegisterUser,
} from '@models/payments'
import { PaymentRegistersFiltersParams } from '@modules/payment-registers/list/filters'
import { setDashboardStandardPaginator } from '@store/actions/dashboard-actions'
import { extractSelectOptionsValues } from '@helpers/utils'
import { CustomReactSelectOption } from '@components/custom-react-select'

export interface PaymentRegisterResponse extends PaginationResponse<PaymentRegister> {
  aggregation: {
    users: PaymentRegisterUser[]
    balance_after: string
    balance_before: string
    total_payin: '0'
    total_payout: '0'
  }
}

interface GetPaymentRegistersFilter extends PaymentRegistersFiltersParams {
  kinds: CustomReactSelectOption[]
}

export const getPaymentRegisters = createAsyncThunk<
  PaymentRegisterResponse,
  Partial<GetPaymentRegistersFilter>,
  AsyncThunkParams
>('cash/getPaymentRegisters', async (filters, { getState, dispatch }) => {
  const data = await commonObjectGet<PaymentRegisterResponse>(
    getState().appState.appData.urls.payment.payment_registers,
    extractSelectOptionsValues(filters),
  )

  dispatch(setDashboardStandardPaginator({ ...data }))

  return data
})

export const getPaymentDayReports = createAsyncThunk<
  PaymentDayReport[],
  { year: string; resort: string },
  { state: RootState }
>(
  'cash/getPaymentDayReports',
  async (filters, { getState }) =>
    await commonObjectGet<PaymentDayReport[]>(getState().appState.appData.urls.payment.payment_day_report, filters),
)

export const getPaymentDayReportDetails = createAsyncThunk<PaymentDayReportDetails, string, AsyncThunkParams>(
  'cash/getPaymentDayReportDetails',
  async (id, { getState }) =>
    await commonObjectGet<PaymentDayReportDetails>(
      getState().appState.appData.urls.payment.payment_day_report + `${id}/`,
    ),
)

export const getPaymentAppData = createAsyncThunk<PaymentAppData, void, AsyncThunkParams>(
  'cash/getPaymentAppData',
  async (_, { getState }) => {
    if (getState().cashState.appData.status === 'unknown') {
      return await commonObjectGet<PaymentAppData>(getState().appState.appData.urls.payment.payment_app_data)
    }
    return getState().cashState.appData
  },
)
export const updatePaymentDayPointReport = createAsyncThunk<
  PaymentDayReportDetails,
  [PaymentDayPointReport, { amount: string }]
>(
  'cash/updatePaymentDayPointReport',
  async ([paymentDayReport, payload]) =>
    await wrapWithError(commonObjectPatch<PaymentDayReportDetails>(paymentDayReport.urls.details, payload)),
)

export const createPaymentDayPointReport = createAsyncThunk<
  PaymentDayReportDetails,
  [PaymentDayReportDetails, { amount: string; point: number }]
>(
  'cash/createPaymentDayPointReport',
  async ([paymentDayReport, payload]) =>
    await wrapWithError(commonObjectPost<PaymentDayReportDetails>(paymentDayReport.urls.points, payload)),
)

export const updatePaymentDayUserReport = createAsyncThunk<
  PaymentDayReportDetails,
  [PaymentDayUserReport, Partial<{ start_amount: number; eservice_pad: number }>]
>(
  'cash/updatePaymentDayUserReport',
  async ([paymentDayUserReport, payload]) =>
    await wrapWithError(commonObjectPatch<PaymentDayReportDetails>(paymentDayUserReport.urls.details, payload)),
)
