import * as React from 'react'
import { ClientSelect } from '@components/client-select'
import { useFormContext } from 'react-hook-form'
import { Row } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'
import { ClientOptionPrice, ClientUser } from '@models/clients'
import { useGroupedSellers } from '@components/hooks/use-grouped-sellers'
import { InvoiceForm } from '@components/invoice-form'
import { FormSelect } from '@hyper/forms/form-select'
import { BookingOption } from '@modules/promotions/booking-options/model'
import { formatPriceShort } from '@helpers/utils'
import { useGetAvailableClientOptionsQuery } from '@api/booking-options'
import { TypedQueryResult } from '@api/base'
import { BookingOptionCreateOptionRow } from '@modules/promotions/booking-options/create/booking-option-create-option-row'

interface Props {
  client: ClientUser | undefined
  onClientSet: (client: ClientUser | undefined) => void
  optionDetails?: BookingOption
  showClient: boolean
}

const emptyTable = []

export const BookingOptionCreateDialogDataStep = ({
  showClient,
  onClientSet,
  client,
  optionDetails,
}: Props): JSX.Element => {
  const [handleToggleClientDialog] = useModal('ClientDialog', { setClient: onClientSet })
  const { data: options = emptyTable } = useGetAvailableClientOptionsQuery() as TypedQueryResult<ClientOptionPrice[]>

  const methods = useFormContext()

  const sellers = useGroupedSellers([])

  React.useEffect(() => {
    options.forEach(option => {
      const key = `options.${option.kind}.amount`
      if (!methods.getValues(key)) {
        methods.setValue(key, 1)
      }
    })
  }, [options])

  return (
    <>
      <Row>
        {showClient && (
          <ClientSelect
            setClient={onClientSet}
            client={client}
            handleToggleClientDialog={handleToggleClientDialog}
            error={methods.formState.errors.client}
            isDisabled={!!optionDetails}
            colSize={7}
          />
        )}
        <FormSelect
          options={sellers}
          name="seller"
          formPlainProps={{ colSize: showClient ? 5 : 6 }}
          label="Sprzedawca"
        />

        <div className="col-12">
          <h5 className="font-13">Opcje:</h5>
          {options.map(option => (
            <BookingOptionCreateOptionRow
              key={option.kind}
              optionAmountName={`options.${option.kind}.amount`}
              optionCheckboxName={`options.${option.kind}.checked`}
              title={`${option.kind_display} ${formatPriceShort(option.price_brutto)}`}
            />
          ))}
        </div>
      </Row>

      {optionDetails?.status === 'initial' && <InvoiceForm client={client} invoiceData={optionDetails} />}
    </>
  )
}
