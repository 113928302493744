import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { formatDate } from '@helpers/date-helper'
import { SaveButton } from '@hyper/button'
import { LoginAsLoginAs } from '@modules/payment-registers/payment-user-open-day-modal/login-as'
import { useForm } from 'react-hook-form'
import { useAppDispatch } from '@store/index'
import { useFormRequest } from '@components/hooks/use-api-request'
import { commonObjectPut } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { PaymentDayReport, PaymentDayReportDetails } from '@models/payments'
import { Form } from '@hyper/forms/form'
import { updatePaymentDayReportDetails } from '@store/slices/cash-slice'
import { IconWithText } from '@components/icon-with-text'
import { PaymentCloseDaySafeAmount } from '@modules/payment-registers/payment-close-day-modal/safe-amount'

export interface FormInputs {
  safe_amount: number
}

interface Props extends BaseModalProps {
  paymentDayReport: PaymentDayReport
}

export const PaymentCloseDayModal: React.FC<Props> = ({ paymentDayReport, toggleIsVisible }) => {
  const dispatch = useAppDispatch()
  const { addSuccessMessage, addErrorMessage } = useNotificationHook()

  const methods = useForm<FormInputs>({ defaultValues: { safe_amount: 0 } })

  const { isLoading, action: onSubmit } = useFormRequest(
    async (payload: FormInputs) => {
      try {
        const paymentDayReportDetails = await commonObjectPut<PaymentDayReportDetails>(
          paymentDayReport.urls.close,
          payload,
        )
        dispatch(updatePaymentDayReportDetails(paymentDayReportDetails))

        addSuccessMessage('Dzień został poprawnie zamknięty', '')
      } catch (e) {
        if (e.response?.data?.non_field_errors) {
          addErrorMessage('Nie można w tej chwili zamknąć dnia', e.response.data.non_field_errors)
        } else {
          throw e
        }
      }

      toggleIsVisible()
    },

    methods.setError,
  )

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader>Zamknij dzień ({formatDate(paymentDayReport.date, 'dd.MM')})</ModalHeader>
      <ModalBody className="px-0">
        <LoginAsLoginAs allowLogout={false} />
        <div className="hr hr-grey my-2" />
        <PaymentCloseDaySafeAmount providerUrl={paymentDayReport.urls.provider} resortId={paymentDayReport.resort_id} />
      </ModalBody>
      <ModalFooter>
        <button type="button" onClick={toggleIsVisible} className="btn mr-1 btn-light">
          Anuluj
        </button>
        <SaveButton
          isSaving={isLoading}
          label={<IconWithText text="Zamknij dzień" icon="uil uil-lock" />}
          className="btn btn-danger"
          labelSaving="Zamykanie dnia"
        />
      </ModalFooter>
    </Form>
  )
}
