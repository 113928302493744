import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@store/index'
import { RecommendationGifDetails, RecommendationGift } from '@models/crm'
import { getRecommendationGifts } from '@store/actions/recommendation-gift-actions'

interface RecommendationGiftState {
  recommendationGifts: EntityState<RecommendationGift>
  recommendationGiftDetails: undefined | RecommendationGifDetails
}

const recommendationGiftsAdapter = createEntityAdapter<RecommendationGift>()

const initialState: RecommendationGiftState = {
  recommendationGifts: recommendationGiftsAdapter.getInitialState(),
  recommendationGiftDetails: undefined,
}

const recommendationGiftSlice = createSlice({
  name: 'recommendationGift',
  initialState,
  reducers: {
    removeRecommendationGift(state, action: PayloadAction<RecommendationGift>) {
      recommendationGiftsAdapter.removeOne(state.recommendationGifts, action.payload.id)
    },
    addRecommendationGiftDetails(state, action: PayloadAction<RecommendationGift>) {
      recommendationGiftsAdapter.upsertOne(state.recommendationGifts, action.payload)
    },
    updateRecommendationGiftDetails(state, action: PayloadAction<RecommendationGifDetails>) {
      state.recommendationGiftDetails = action.payload
      recommendationGiftsAdapter.updateOne(state.recommendationGifts, {
        id: action.payload.id,
        changes: action.payload,
      })
    },
  },
  extraReducers: builder => {
    builder.addCase(getRecommendationGifts.fulfilled, (state, action) => {
      recommendationGiftsAdapter.setAll(state.recommendationGifts, action.payload)
    })
  },
})

export const { selectAll: recommendationGiftsSelector } = recommendationGiftsAdapter.getSelectors(
  (state: RootState) => state.recommendationGiftState.recommendationGifts,
)
export const { addRecommendationGiftDetails, updateRecommendationGiftDetails, removeRecommendationGift } =
  recommendationGiftSlice.actions
export default recommendationGiftSlice.reducer
