import * as React from 'react'
import { CleaningOrderDetailsHousekeepingNote } from '@modules/housekeeping/models'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'

interface Props {
  note: CleaningOrderDetailsHousekeepingNote
}

export const HousekeepingCleaningOrderDetailsNotesRow: React.FC<Props> = ({ note }) => (
  <div className="inbox-item">
    <p className="inbox-item-author">{note.created_by}</p>
    <p>{note.content}</p>
    <p className="inbox-item-date text-right">({toDefaultDateTimeFormat(note.created)})</p>
    {note.image && (
      <p className="inbox-item-text">
        <a href={note.image} target="_blank">
          <img src={note.image} width={80} />
        </a>
      </p>
    )}
  </div>
)
