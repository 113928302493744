import * as React from 'react'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { TableFilters } from '@components/table/table'
import { FormProvider, useForm } from 'react-hook-form'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { Col, Row } from 'reactstrap'

interface Props {
  filters: TableFilters
  defaultFilters: TableFilters
  setFilters: React.Dispatch<React.SetStateAction<TableFilters>>
}

export const UnitsFilters: React.FC<Props> = ({ filters, defaultFilters, setFilters }) => {
  const methods = useForm({
    defaultValues: defaultFilters,
  })

  const onSubmit = async payload => setFilters({ ...filters, ...payload })

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  return (
    <Row>
      <FormProvider {...methods}>
        <Col className="text-right col-auto ml-auto">
          <SearchFilter hideLabel={true} />
        </Col>
      </FormProvider>
    </Row>
  )
}
