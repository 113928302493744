import * as React from 'react'
import { Row } from 'reactstrap'
import { FormInput } from '@hyper/forms'
import { FormSelect } from '@hyper/forms/form-select'
import { paymentMethodOptions } from '@modules/reservations/details/load-notes/modals/reservation-load-note-add-modal'
import { ReservationPaymentDeposit } from '@modules/reservations/details/payment-history/modals/payment/rservation-payment-deposit'
import { ReservationPaymentInstalment } from '@modules/reservations/details/payment-history/modals/payment/rservation-payment-installment'
import { BookingPaymentType } from '@models/booking'

interface Props {
  paymentKind: BookingPaymentType
  canOmitSage?: boolean
}

export const ReservationPaymentForm = ({ paymentKind, canOmitSage }: Props): JSX.Element => (
  <Row>
    <FormInput colSize={6} colClassName="pr-0" name="amount" type="currency" label="Kwota" />
    <FormSelect formPlainProps={{ colSize: 6 }} name="source" label="Forma płatności" options={paymentMethodOptions} />
    {paymentKind === 'deposit' && <ReservationPaymentDeposit />}
    {paymentKind === 'installment' && <ReservationPaymentInstalment canOmitSage={canOmitSage} />}
    <FormInput label="Opis dodatkowy" name="description" type="textarea" />
  </Row>
)
