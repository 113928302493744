import * as React from 'react'
import Table from '@components/table/table'
import { asDecimal, formatPrice } from '@helpers/utils'
import { ProductPackageAggregation } from '@modules/endless-holiday/models'

interface Props {
  aggregation: ProductPackageAggregation
}

export const EndlessHolidayTransactionsTableFooter = ({ aggregation }: Props): React.ReactNode => {
  const total = asDecimal(aggregation.gastro_card_total_price_brutto_sum)
    .add(aggregation.subscription_total_price_brutto_sum)
    .add(aggregation.subscription_total_deposit_sum)
    .toString()

  return (
    <Table.Row>
      <Table.Cell colSpan={4} className="text-right align-middle package-list__summary-cell">
        <strong className="mr-2">Razem w wybranym okresie:</strong>
      </Table.Cell>

      <Table.Cell className="package-list__summary-cell">
        <div className="text-white package-list__summary-block text-strong pt-2">
          <div className="font-weight-normal mb-1">Łączna kwota (pobyty):</div>
          {formatPrice(aggregation.subscription_total_price_brutto_sum)}
        </div>
      </Table.Cell>
      <Table.Cell className="package-list__summary-cell">
        <div className="text-white package-list__summary-block text-strong pt-2">
          <div className="font-weight-normal mb-1">Łączna kwota (kaucja):</div>
          {formatPrice(aggregation.subscription_total_deposit_sum)}
        </div>
      </Table.Cell>
      <Table.Cell className="package-list__summary-cell">
        <div className="text-white package-list__summary-block text-strong pt-2">
          <div className="font-weight-normal mb-1">Łączna kwota (VG):</div>
          {formatPrice(aggregation.gastro_card_total_price_brutto_sum)}
        </div>
      </Table.Cell>
      <Table.Cell className="package-list__summary-cell">
        <div className="text-white package-list__summary-block text-strong pt-2">
          <div className="font-weight-normal mb-1">Łączna kwota (razem):</div>
          {formatPrice(total)}
        </div>
      </Table.Cell>
      <Table.Cell className="package-list__summary-cell">
        <div className="text-white package-list__summary-block text-strong pt-2">
          <div className="font-weight-normal mb-1">Pozostało do zapłaty (razem):</div>
          {formatPrice(aggregation.initial_total_price_brutto)}
        </div>
      </Table.Cell>

      <Table.Cell colSpan={3} className="package-list__summary-cell" />
    </Table.Row>
  )
}
