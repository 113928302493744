import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { packagesSelector } from '@store/slices/package-slice'
import { Package, PackageType } from '@models/package'
import { UserPermission } from '@models/dashboard'
import { Seller } from '@models/crm'
import { useSellers } from '@components/hooks/use-grouped-sellers'
import { useAppSelector } from '@store/index'

const filterBySeller = (packages: Package[], sellerId: number | undefined): Package[] => {
  if (!sellerId) return []
  return packages.filter((singlePackage: Package) => singlePackage.seller_id === sellerId)
}

export const usePackageList = (type: PackageType) => {
  const user = useAuthenticatedUser()
  const packages = useAppSelector(packagesSelector).filter(row => row.package_type.toLocaleLowerCase() === type)
  const sellers = useSellers()

  if (
    (type === 'hpr' && user.hasPerm(UserPermission.PackageCanViewAll)) ||
    (type === 'hpi' && user.hasPerm(UserPermission.PackageHpiCanViewAll))
  ) {
    return packages
  }

  if (
    (type === 'hpr' && user.hasPerm(UserPermission.PackageCanViewGroup)) ||
    (type === 'hpi' && user.hasPerm(UserPermission.PackageHpiCanViewGroup))
  ) {
    return packages.filter(
      row => row.seller_id === user.seller?.id || isUserOwnerGroup(row.seller_id, sellers, user.seller),
    )
  }

  return filterBySeller(packages, user.seller?.id)
}

const isUserOwnerGroup = (sellerId: number, sellers: Seller[], userSeller: Seller | undefined) => {
  const seller = sellers.find(row => row.id === sellerId)

  return seller?.group === userSeller?.group
}
