import * as React from 'react'
import Table, { TableHeaderValue } from '@components/table/table'
import { PackageWholesaleProductListFilterParams } from '@modules/package-wholesale/products/list/filter'
import { PackageWholesaleProduct } from '@modules/package-wholesale/models'
import { PackageWholesaleProductListTableRow } from '@modules/package-wholesale/products/list/table-row'
import { useSelectableRows } from '@components/table/use-selectable-rows'
import { PackageWholesaleProductListTableActions } from '@modules/package-wholesale/products/list/table-actions'

const headerValues: TableHeaderValue[] = [
  { title: 'Nazwa', sortField: 'name' },
  { title: 'Status', sortField: 'status' },
  { title: 'Typ produktu', sortField: 'kind' },
  { title: 'Zakwaterowanie', sortField: 'accommodation_type_id' },
  { title: 'Okres ważności kodu', sortField: 'expire_after_years' },
  { title: 'Lokalizacja', sortField: 'localization_kind' },
  { title: 'Opcje' },
  { title: 'Cena brutto', sortField: 'price_brutto' },
  { title: '' },
]

interface Props {
  packageWholesaleProducts: PackageWholesaleProduct[]
  isLoading: boolean
  filters: PackageWholesaleProductListFilterParams
  setFilters: (filters: PackageWholesaleProductListFilterParams) => void
}

export const PackageWholesaleProductListTable: React.FC<Props> = ({
  isLoading,
  packageWholesaleProducts,
  filters,
  setFilters,
}) => {
  const { selectableHeader, selectableCell, selectedRows, clearSelectedRows } =
    useSelectableRows<PackageWholesaleProduct>(packageWholesaleProducts)

  return (
    <>
      <PackageWholesaleProductListTableActions selectedRows={selectedRows} clearSelectedRows={clearSelectedRows} />
      <Table
        className="table-hover w-100"
        headerValues={headerValues}
        selectableHeader={selectableHeader}
        isLoading={isLoading}
        filters={filters}
        setFilters={setFilters}
      >
        {packageWholesaleProducts.map(packageWholesaleProduct => (
          <PackageWholesaleProductListTableRow
            key={packageWholesaleProduct.id}
            packageWholesaleProduct={packageWholesaleProduct}
          >
            {selectableCell(packageWholesaleProduct)}
          </PackageWholesaleProductListTableRow>
        ))}
      </Table>
    </>
  )
}
