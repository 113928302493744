import * as React from 'react'
import { PaymentRegistersFiltersParams } from '@modules/payment-registers/list/filters'
import { useForm } from 'react-hook-form'
import { paymentRegisterUsersOptionsSelector } from '@store/selectors/payment'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { Row } from 'reactstrap'
import { ColAuto } from '@hyper/col-auto'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { PaymentRegisterFilters } from '@modules/payment-registers/common/filters'
import { Form } from '@hyper/forms/form'
import { FormSelect } from '@hyper/forms/form-select'
import { useAppSelector } from '@store/index'

interface Props {
  setFilters: (filters: PaymentRegistersFiltersParams) => void
  filters: PaymentRegistersFiltersParams
  defaultFilters: PaymentRegistersFiltersParams
}

export const PaymentRegisterCashboxDayTransactionsTableFilters = ({
  filters,
  setFilters,
  defaultFilters,
}: Props): JSX.Element => {
  const methods = useForm<PaymentRegistersFiltersParams>({
    defaultValues: defaultFilters,
  })
  const paymentRegisterUsers = useAppSelector(paymentRegisterUsersOptionsSelector)

  const onSubmit = async (payload: PaymentRegistersFiltersParams) => setFilters({ ...filters, ...payload })

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  return (
    <Form methods={methods} onSubmit={onSubmit} className="form form-style-filter mb-2">
      <Row>
        <PaymentRegisterFilters />

        <ColAuto>
          <FormSelect
            options={paymentRegisterUsers}
            name="user"
            label="Dodane przez"
            selectProps={{ isClearable: true }}
            formPlainProps={{ colStyle: { width: 200 } }}
          />
        </ColAuto>

        <SearchFilter />
      </Row>
    </Form>
  )
}
