import * as React from 'react'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { RcpEvent, RcpEventDay } from '@models/rcp'
import { useAppDispatch, useAppSelector } from '@store/index'
import { rcpUserEventsDaysFilter, updateUserEventDayDetails } from '@store/slices/rcp-slice'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { useApiRequest } from '@components/hooks/use-api-request'

interface EventRowSingleEventRemoveProps {
  event: RcpEvent
}

export const EventRowSingleEventRemove: React.FC<EventRowSingleEventRemoveProps> = ({ event }) => {
  const dispatch = useAppDispatch()
  const filters = useAppSelector(rcpUserEventsDaysFilter)
  const { addSuccessMessage } = useNotificationHook()

  const { isLoading, action: handleRemoveEvent } = useApiRequest(async () => {
    dispatch(
      updateUserEventDayDetails(
        await commonObjectDelete<RcpEventDay>(event.urls.delete, null, null, undefined, filters),
      ),
    )
    addSuccessMessage('Sukces', 'Zdarzenie zostało pomyślne skasowane!')
  })

  return (
    <CommonObjectConfirmAction isLoading={isLoading} handleAccept={handleRemoveEvent}>
      <button className="action-icon btn-pure" title="Skasuj zdarzenie">
        <i className="uil-trash-alt text-muted font-18" />
      </button>
    </CommonObjectConfirmAction>
  )
}
