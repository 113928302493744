import { Col, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap'
import * as R from 'ramda'
import * as React from 'react'
import DateRangeComponent from '@components/date/date-range'
import { formatDate, parseISODate } from '@helpers/date-helper'
import { useFormContext, useWatch } from 'react-hook-form'

interface ReservationDateSelectionProps {
  availableDates: string[]
}

export const ReservationDateSelection: React.FC<ReservationDateSelectionProps> = ({ availableDates }) => {
  const {
    register,
    setValue,
    formState: { errors },
    control,
  } = useFormContext()
  const [startDate, endDate] = useWatch({ control, name: ['date_from', 'date_to'] })

  React.useEffect(() => {
    register('date_from')
    register('date_to')
  }, [register])

  const setDate = ({ selection }) => {
    setValue('date_from', formatDate(selection.startDate))
    setValue('date_to', formatDate(selection.endDate))
  }

  const minDate = React.useMemo(() => parseISODate(availableDates[0]), [availableDates])
  const maxDate = React.useMemo(() => parseISODate(R.last<string>(availableDates) || ''), [availableDates])

  return (
    <Row className="mb-3 mt-2">
      <Col md={12}>
        <InputGroup className="form-group">
          <InputGroupAddon addonType="prepend">
            <InputGroupText className="bg-transparent border-right-0">
              <i className="uil-calendar-alt text-muted " />{' '}
            </InputGroupText>
          </InputGroupAddon>
          <DateRangeComponent
            startingDate={parseISODate(startDate)}
            endingDate={parseISODate(endDate)}
            minDate={minDate}
            maxDate={maxDate}
            onChange={setDate}
            placeholder="Data pobytu"
            isInvalid={!!(errors?.date_to || errors?.date_from)}
            wrapperClassName="flex-grow-1"
          />
        </InputGroup>
        <div className="invalid-feedback d-block mb-1">
          {String(errors?.date_from?.message || errors?.date_to?.message || '')}
        </div>
      </Col>
    </Row>
  )
}
