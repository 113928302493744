import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { asDecimal } from '@helpers/utils'
import { ReceptionBookingSettlementDepositReturn } from '@modules/reception/checkout/step-settlement/step-settlement-deposit-return'
import { ReceptionBookingPreAuthorizationBox } from '@modules/reception/checkin/step-payments/boxes/reception-booking-pre-authorization-box'
import { useAppDispatch, useAppSelector } from '@store/index'
import { reservationBookingPaymentsVisibleSelector } from '@store/slices/reservations-slice'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectGet } from '@store/actions/generic-actions'
import { ReceptionBookingSettlementDepositReturnConfirmBox } from '@modules/reception/checkout/step-settlement/step-settlement-deposit-return-confirm-box'
import { ContentLoader } from '@components/content-loader'
import { getBookingPayments } from '@store/actions/reservation-actions'

type ReturnOptionAction =
  | 'CASH_RETURN_CHECKOUT'
  | 'CASH_FULL_USE_CHECKOUT'
  | 'TRANSFER_RETURN_CHECKOUT'
  | 'TRANSFER_FULL_USE_CHECKOUT'
  | 'PRE_AUTHORIZATION_CHECKOUT'
  | 'CREDITCARD_FULL_USE_CHECKOUT'
  | 'CREDITCARD_RETURN_CHECKOUT'

interface Props {
  booking: ReceptionBookingDetails
  setIsNextStepDisabled: (value: boolean) => void
}

interface ReturnOption {
  booking_payment_id: number
  action: ReturnOptionAction
  return_amount: string
  use_amount: string
}

export const ReceptionBookingSettlementDepositReturns = ({
  booking,
  setIsNextStepDisabled,
}: Props): JSX.Element | null => {
  const [depositPaymentOptions, setDepositPaymentOptions] = React.useState<ReturnOption[]>([])

  const dispatch = useAppDispatch()
  const bookingPayments = useAppSelector(reservationBookingPaymentsVisibleSelector)

  const { isLoading, action: fetchOptions } = useApiRequest(async () =>
    setDepositPaymentOptions(await commonObjectGet<ReturnOption[]>(booking.urls.booking_payments_deposit_checkout)),
  )

  React.useEffect(() => {
    fetchOptions()
  }, [])

  React.useEffect(() => {
    if (!depositPaymentOptions.length) {
      setIsNextStepDisabled(false)
      return
    }

    setIsNextStepDisabled(
      !depositPaymentOptions.every(row =>
        [
          'TRANSFER_RETURN_CHECKOUT',
          'TRANSFER_FULL_USE_CHECKOUT',
          'CASH_FULL_USE_CHECKOUT',
          'CREDITCARD_FULL_USE_CHECKOUT',
        ].includes(row.action),
      ),
    )
  }, [depositPaymentOptions])

  const onReturn = async () => {
    dispatch(await getBookingPayments(booking))
    fetchOptions()
  }

  const availablePaymentOptions = depositPaymentOptions.reduce((cum, option) => {
    const payment = bookingPayments.find(payment => payment.id === option.booking_payment_id)
    return [...cum, { option, payment }]
  }, [])

  return (
    <ContentLoader isLoading={isLoading}>
      {availablePaymentOptions.map(({ option, payment }) => {
        if (!payment) return null

        if (option.action === 'TRANSFER_RETURN_CHECKOUT') {
          return <ReceptionBookingSettlementDepositReturn key={option.booking_payment_id} payment={payment} />
        }

        if (option.action === 'CASH_RETURN_CHECKOUT' || option.action === 'CREDITCARD_RETURN_CHECKOUT') {
          return (
            <ReceptionBookingSettlementDepositReturnConfirmBox
              onReturn={onReturn}
              depositToReturn={asDecimal(option.return_amount)}
              payment={payment}
              key={option.booking_payment_id}
            />
          )
        }

        if (option.action === 'PRE_AUTHORIZATION_CHECKOUT') {
          return (
            <ReceptionBookingPreAuthorizationBox
              key={option.booking_payment_id}
              onReturn={onReturn}
              restToPay={asDecimal(option.use_amount)}
              payment={payment}
              booking={booking}
            />
          )
        }

        return null
      })}
    </ContentLoader>
  )
}
