import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { ContextMenu } from '@components/context-menu'
import { CleaningOrder } from '@modules/housekeeping/models'
import { useOrderPriorityChange } from '@modules/housekeeping/cleaning-orders/to-order/actions/use-order-priority-change'

export interface EdgeCleaningOrders {
  last: CleaningOrder
  first: CleaningOrder
}

interface Props {
  cleaningOrder: CleaningOrder
}

export const HousekeepingCleaningOrderOrderedRowContextMenuItems = ({ cleaningOrder }: Props): JSX.Element => {
  const { changeOrderPriority } = useOrderPriorityChange()

  const handlePriorityChange = (priority: 0 | 1) => () => changeOrderPriority(cleaningOrder, priority)

  return (
    <>
      {cleaningOrder.priority === 0 && (
        <ContextMenu.Item onClick={handlePriorityChange(1)}>
          <IconWithText icon="uil-stopwatch mr-2 text-danger" text="Oznacz jako priorytetowe" textClass="font-12" />
        </ContextMenu.Item>
      )}

      {cleaningOrder.priority === 1 && (
        <ContextMenu.Item onClick={handlePriorityChange(0)}>
          <IconWithText icon="uil-stopwatch-slash text-muted mr-2" text="Oznacz  jako normalne" textClass="font-12" />
        </ContextMenu.Item>
      )}
    </>
  )
}
