import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { getCallCenterUsers, getGroups, getReceptionistUsers, getUsers } from '@store/actions/account-actions'
import { RootState } from '@store/index'
import { CallCenterUser, Group, ReceptionistUser, User } from '@models/account'

interface AccountState {
  groups: EntityState<Group>
  users: EntityState<User>
  callCenterUsers: EntityState<CallCenterUser>
  receptionistUsers: EntityState<ReceptionistUser>
}

const groupsAdapter = createEntityAdapter<Group>()
const usersAdapter = createEntityAdapter<User>()
const callCenterUsersAdapter = createEntityAdapter<CallCenterUser>()
const receptionistUsersAdapter = createEntityAdapter<ReceptionistUser>()

const initialState: AccountState = {
  groups: groupsAdapter.getInitialState(),
  users: usersAdapter.getInitialState(),
  callCenterUsers: callCenterUsersAdapter.getInitialState(),
  receptionistUsers: receptionistUsersAdapter.getInitialState(),
}

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    updateUserDetails(state, action: PayloadAction<User>) {
      usersAdapter.upsertOne(state.users, action.payload)
    },
    updateCallCenterUserDetails(state, action: PayloadAction<CallCenterUser>) {
      callCenterUsersAdapter.upsertOne(state.callCenterUsers, action.payload)
    },
    updateReceptionistUserDetails(state, action: PayloadAction<ReceptionistUser>) {
      receptionistUsersAdapter.upsertOne(state.receptionistUsers, action.payload)
    },
  },
  extraReducers: builder => {
    builder.addCase(getUsers.fulfilled, (state, action) => {
      usersAdapter.setAll(state.users, action.payload)
    })
    builder.addCase(getGroups.fulfilled, (state, action) => {
      groupsAdapter.setAll(state.groups, action.payload)
    })
    builder.addCase(getCallCenterUsers.fulfilled, (state, action) => {
      callCenterUsersAdapter.setAll(state.callCenterUsers, action.payload)
    })
    builder.addCase(getReceptionistUsers.fulfilled, (state, action) => {
      receptionistUsersAdapter.setAll(state.receptionistUsers, action.payload)
    })
  },
})

export const { updateUserDetails, updateCallCenterUserDetails, updateReceptionistUserDetails } = accountSlice.actions

export const { selectAll: groupsSelector } = groupsAdapter.getSelectors((state: RootState) => state.accountState.groups)
export const { selectAll: usersSelector } = usersAdapter.getSelectors((state: RootState) => state.accountState.users)
export const { selectAll: callCenterUsersSelector } = callCenterUsersAdapter.getSelectors(
  (state: RootState) => state.accountState.callCenterUsers,
)
export const { selectAll: receptionistUsersSelector } = receptionistUsersAdapter.getSelectors(
  (state: RootState) => state.accountState.receptionistUsers,
)

export default accountSlice.reducer
