import * as React from 'react'
import { FormInput, FormPlain } from '@hyper/forms'
import { handleEnterPress } from '@helpers/utils'
import { Col, Label } from 'reactstrap'
import { CustomReactSelect, CustomReactSelectOption } from '@components/custom-react-select'
import DatePickerInput from '@components/date/date-picker-input'
import { startOfToday } from 'date-fns'
import { useFormContext } from 'react-hook-form'
import { StepGuestsGuestFormInputs } from '@modules/reception/checkin/step-guests/step-guests-guest-form/models'
import { ageOptions } from '@modules/reception/checkin/step-guests/step-guests-guest-form/consts'

export interface Props {
  groupOptions: CustomReactSelectOption[]
}

export const StepGuestsGuestFormForm = ({ groupOptions }: Props): JSX.Element => {
  const { watch, control, handleSubmit } = useFormContext<StepGuestsGuestFormInputs>()
  const selectType: CustomReactSelectOption = watch('type')

  return (
    <>
      <FormInput
        colSize={4}
        label="Imię i nazwisko gościa:"
        name="name"
        onKeyDown={handleEnterPress.bind(handleSubmit)}
      />

      <Col md={4}>
        <CustomReactSelect fieldName="type" options={ageOptions} label="Wiek osoby" />
      </Col>
      {selectType.value !== 'adult' ? (
        <FormPlain colSize={4} name="birthday">
          <Label>Data urodzenia</Label>
          <DatePickerInput control={control} name="birthday" wrapperClassName="flex-grow-1" maxDate={startOfToday()} />
        </FormPlain>
      ) : (
        <Col md={4} />
      )}

      {selectType?.value === 'adult' && (
        <>
          <FormInput colSize={4} label="Numer telefonu:" name="phone" onKeyDown={handleEnterPress.bind(handleSubmit)} />
          <FormInput colSize={4} label="Adres e-mail:" name="email" onKeyDown={handleEnterPress.bind(handleSubmit)} />
        </>
      )}
      <Col md={4}>
        <CustomReactSelect fieldName="groupOption" options={groupOptions} label="Grupa" />
      </Col>
    </>
  )
}
