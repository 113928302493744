import * as React from 'react'
import { EservicePinPadAction } from '@models/eservice'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectDelete, commonObjectGet, commonObjectPost } from '@store/actions/generic-actions'
import { formatPriceShort } from '@helpers/utils'
import { useWebSocket } from '@components/hooks/use-web-socket'
import { NotificationEvents } from '@models/dashboard'
import { PinPadKind } from '@modules/reception/common/pin-pad/index'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useIntervalWhen } from 'rooks'
import { useModal } from '@components/modals/use-modal'
import { reservationBookingPaymentsSelector } from '@store/slices/reservations-slice'
import { getBookingPayments } from '@store/actions/reservation-actions'

interface UsePinPadProps {
  amount: string
  onError?: (title?: string) => void
  onSuccess?: () => void
  onCancel?: () => void
  onAbort?: () => void
  kind: PinPadKind
  preauth_payment?: number
  object_id: number
  content_type: number
  context?: any
}

export const usePinPad = ({
  amount,
  kind,
  preauth_payment,
  onError,
  onSuccess,
  onCancel,
  onAbort,
  object_id,
  context,
  content_type,
}: UsePinPadProps) => {
  const [pinPadAction, setPinPadAction] = React.useState<EservicePinPadAction | undefined>()
  const [isLoading, setIsLoading] = React.useState(false)
  const url = useAppSelector((state: RootState) => state.appState.appData.urls.eservice.pin_pad_actions)
  const { addSuccessNotification } = useNotificationHook()
  const user = useAuthenticatedUser()
  const [start, setStart] = React.useState(false)

  const receptionBookingDetails = useAppSelector((state: RootState) => state.receptionState.receptionBookingDetails)

  const { action: handlePaymentCancel } = useApiRequest(async () => {
    if (pinPadAction) {
      setStart(false)
      setIsLoading(false)
      await commonObjectDelete(pinPadAction.urls.details)
      addSuccessNotification('Operacja została anulowana!')
      onAbort?.()
    }
  })

  const { action: fetchPinPadActionDetails } = useApiRequest(async () => {
    if (pinPadAction) {
      handleResult(await commonObjectGet<EservicePinPadAction>(pinPadAction.urls.details))
    }
  })

  const handleResult = (result: EservicePinPadAction | null) => {
    if (!result) {
      return
    }

    if (result.result === 'ERROR') {
      onError?.(result.error_message || 'Wystąpił błąd terminala!')
      setIsLoading(false)
      setStart(false)
    } else if (result.result === 'SUCCESS') {
      onSuccess ? onSuccess() : addSuccessNotification(`Wpłata o kwocie ${formatPriceShort(amount)} została dodana`)
      setIsLoading(false)
      setStart(false)
    }
  }

  useWebSocket<EservicePinPadAction>(
    (message, payload) => {
      switch (message) {
        case NotificationEvents.ESERVICE_PIN_PAD_ACTION_CHANGE:
          if (payload.uuid === pinPadAction?.uuid) {
            handleResult(payload)
          }
      }
    },
    [pinPadAction?.uuid],
    [NotificationEvents.ESERVICE_PIN_PAD_ACTION_CHANGE],
  )

  const { action: handlePaymentAction } = useApiRequest(async () => {
    setIsLoading(true)
    setStart(true)
    setPinPadAction(
      await commonObjectPost<EservicePinPadAction>(url, {
        pin_pad: user.eservicePadId,
        amount,
        object_id,
        content_type,
        kind,
        preauth_payment,
        context,
      }),
    )
  })

  const bookingPayments = useAppSelector(reservationBookingPaymentsSelector).filter(row => !row.hide)

  const dispatch = useAppDispatch()
  const { action: fetchPayments } = useApiRequest(async () => {
    if (receptionBookingDetails) {
      dispatch(await getBookingPayments(receptionBookingDetails))
    }
  })

  React.useEffect(() => {
    fetchPayments()
  }, [receptionBookingDetails])

  // FIXME, to należy przenieść z tego modala
  const bookingPayment = bookingPayments.find(row => row.id === preauth_payment)
  const [showInfoModal] = useModal('ReceptionPinPadPreauthorizationInfoModal', {
    pin_pad_id: bookingPayment?.pin_pad_id,
    handlePayment: handlePaymentAction,
  })

  const handlePayment = () => {
    if (bookingPayment && bookingPayment.pin_pad_id && bookingPayment.pin_pad_id != user.eservicePadId) {
      showInfoModal()
    } else {
      handlePaymentAction()
    }
  }

  useIntervalWhen(
    () => {
      fetchPinPadActionDetails()
    },
    30000,
    start,
  )

  const handleCancel = () => {
    if (isLoading) {
      handlePaymentCancel()
      return
    }

    onCancel?.()
  }

  return {
    handleCancel,
    handlePayment,
    isLoading,
  }
}
