import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectGet } from '@store/actions/generic-actions'
import { NotificationTemplate } from '@models/notifications'
import * as React from 'react'

interface Template {
  email: NotificationTemplate[]
  sms: NotificationTemplate[]
}

interface Response {
  fetchNotificationTemplates: (url?: string) => Promise<void>
  isLoading: boolean
  notificationTemplates: Template
}

interface Params {
  url?: string
  fetchOnMount?: boolean
}

export const useNotificationTemplates = ({ url, fetchOnMount = true }: Params = {}): Response => {
  const [notificationTemplates, setNotificationTemplates] = React.useState<Template>({ email: [], sms: [] })

  const { action: fetchNotificationTemplates, isLoading } = useApiRequest(async (fetchUrl?: string) => {
    const templatesFetchUrl = fetchUrl ?? url
    if (!templatesFetchUrl) return
    const response = await commonObjectGet<Template>(templatesFetchUrl)
    setNotificationTemplates(response)
  })

  React.useEffect(() => {
    if (fetchOnMount) {
      fetchNotificationTemplates()
    }
  }, [url])

  return {
    notificationTemplates,
    fetchNotificationTemplates,
    isLoading,
  }
}
