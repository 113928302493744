import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { PaginationResponse } from '@models/dashboard'
import { apiQueryFunction } from '@api/base'
import { RootState } from '@store/index'
import { UpSellAppData, UpSellBooking, UpSellBookingDetails, UpSellOutdatedBooking } from '@models/upselling'
import { UpsellBookingsFormInputs } from '@modules/upselling/upsell-bookings'
import { UpsellBookingsOutdatedFormInputs } from '@modules/upselling/upsell-booking-outdated'
import { createApiTag, provideListTags } from '@api/query-helper'

interface UpSellPollUpdatePayload {
  url: string
  data: { id: number; option_id: number; poll_id: number }
}

export const UPSELL_BOOKING_TAG = 'upSellingBooking'
export const UPSELL_BOOKING_TAG_OUTDATED = 'upSellingBookingOutdated'
export const UPSELL_BOOKING_TAG_DETAILS = 'upSellingBookingDetails'
export const UPSELL_BOOKING_TAG_APP_DATA = 'upSellingBookingAppData'

export enum UPSELL_BOOKING_IDS {
  CURRENT = 'CURRENT',
  OUTDATED = 'OUTDATED',
  DETAILS = 'DETAILS',
}

export const upSellBookingApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: '' }),
  reducerPath: 'upSellBooking',
  tagTypes: [UPSELL_BOOKING_TAG, UPSELL_BOOKING_TAG_OUTDATED, UPSELL_BOOKING_TAG_DETAILS, UPSELL_BOOKING_TAG_APP_DATA],
  endpoints: builder => ({
    getUpSellAppData: builder.query<UpSellAppData, void>({
      queryFn: apiQueryFunction((state: RootState) => ({
        method: 'GET',
        url: state.appState.appData.urls.upselling.app_data,
      })),
      providesTags: [UPSELL_BOOKING_TAG_APP_DATA],
    }),
    updateUpSellPoll: builder.mutation<UpSellBooking, UpSellPollUpdatePayload>({
      invalidatesTags: [UPSELL_BOOKING_TAG_DETAILS],
      queryFn: apiQueryFunction((_, { url, data }: UpSellPollUpdatePayload) => ({
        url,
        method: 'POST',
        data,
      })),
    }),

    getUpSellBookings: builder.query<PaginationResponse<UpSellBooking>, Partial<UpsellBookingsFormInputs>>({
      queryFn: apiQueryFunction((state: RootState, params: Partial<UpsellBookingsFormInputs>) => ({
        method: 'GET',
        url: state.appState.appData.urls.upselling.upselling_bookings,
        params,
      })),
      providesTags: data => provideListTags(UPSELL_BOOKING_TAG, UPSELL_BOOKING_IDS.CURRENT)(data?.results),
    }),

    getUpSellOutdatedBookings: builder.query<UpSellOutdatedBooking[], Partial<UpsellBookingsOutdatedFormInputs>>({
      queryFn: apiQueryFunction((state: RootState, params: Partial<UpsellBookingsOutdatedFormInputs>) => ({
        method: 'GET',
        url: state.appState.appData.urls.upselling.upselling_bookings_outdated,
        params,
      })),
      providesTags: createApiTag(UPSELL_BOOKING_TAG_OUTDATED, UPSELL_BOOKING_IDS.OUTDATED),
    }),

    updateUpSellBooking: builder.mutation<UpSellBooking, { url: string; data: Partial<UpSellBooking> }>({
      invalidatesTags: [createApiTag(UPSELL_BOOKING_TAG, UPSELL_BOOKING_IDS.CURRENT), UPSELL_BOOKING_TAG_DETAILS],
      queryFn: apiQueryFunction((_, { url, data }: { url: string; data: Partial<UpSellBooking> }) => ({
        url,
        method: 'PATCH',
        data,
      })),
    }),

    getUpsellBookingDetails: builder.query<UpSellBookingDetails, string>({
      queryFn: apiQueryFunction((_, url: string) => ({
        method: 'GET',
        url,
      })),
      providesTags: [UPSELL_BOOKING_TAG_DETAILS],
    }),
  }),
})

export const {
  useGetUpSellAppDataQuery,
  useGetUpSellBookingsQuery,
  useGetUpSellOutdatedBookingsQuery,
  useUpdateUpSellBookingMutation,
  useLazyGetUpsellBookingDetailsQuery,
  useUpdateUpSellPollMutation,
} = upSellBookingApi
