import * as React from 'react'
import { useToggle } from '@components/hooks/use-toggle'
import { noop } from '@helpers/utils'

interface CartContext {
  allRowCollapsed: boolean
  toggleAllRowCollapsed: () => void
}

export const CartContextInitialValue = {
  allRowCollapsed: true,
  toggleAllRowCollapsed: noop,
}

export const CartContext = React.createContext<CartContext>(CartContextInitialValue)

export const CartContextProvider = ({ children }) => {
  const [allRowCollapsed, toggleAllRowCollapsed] = useToggle(true)

  return (
    <CartContext.Provider value={{ allRowCollapsed: allRowCollapsed, toggleAllRowCollapsed }}>
      {children}
    </CartContext.Provider>
  )
}
