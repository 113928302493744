import * as React from 'react'
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { EnhancedFloorHeatingMeasurement } from '@modules/floor-heating/models'
import { useFloorHeatingAppData } from '@modules/floor-heating/use-floor-heating-app-data'
import { formatTemperature, sortObjectListByDate } from '@helpers/utils'
import { compareAsc } from 'date-fns'

interface Props {
  measurements: EnhancedFloorHeatingMeasurement[]
}

export const FloorHeatingDetailsHistoryChart = ({ measurements }: Props): JSX.Element => {
  const { appData } = useFloorHeatingAppData()

  const data = React.useMemo(() => sortObjectListByDate(measurements, 'date_timestamp', compareAsc), [measurements])

  return (
    <ResponsiveContainer width="100%" height={400} className="my-3">
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="5 5" />
        <XAxis dataKey="date_timestamp" />
        <YAxis domain={['dataMin - 3', 'dataMax + 3']} allowDataOverflow={true} width={20} />
        <Tooltip content={TooltipContent} />
        <Legend
          wrapperStyle={{
            paddingTop: '10px',
          }}
        />
        <Line
          type="monotone"
          name={appData?.available_zones.zone_7_temperature ?? 'Strefa 7'}
          dataKey="zone_7_temperature"
          stroke="#8884d8"
        />
        <Line
          type="monotone"
          name={appData?.available_zones.zone_8_temperature ?? 'Strefa 8'}
          dataKey="zone_8_temperature"
          stroke="#82ca9d"
        />
        <Line
          type="monotone"
          name={appData?.available_zones.zone_9_temperature ?? 'Strefa 9'}
          dataKey="zone_9_temperature"
          stroke="#0078ff"
        />
        <Line
          type="monotone"
          name={appData?.available_zones.zone_a_temperature ?? 'Strefa A'}
          dataKey="zone_a_temperature"
          stroke="#ff00eb"
        />
      </LineChart>
    </ResponsiveContainer>
  )
}

interface TooltipPropsPayload {
  name: string
  value: number
  stroke: string
}

interface TooltipProps {
  label: string
  payload: TooltipPropsPayload[]
}

const TooltipContent = (props: TooltipProps) => (
  <div className="border bg-white-smoke rounded p-2">
    <strong className="text-center d-block">{props.label}</strong>
    <hr className="border-gray mx-n2 my-1" />
    {Object.entries(props.payload).map(([key, value]: [string, TooltipPropsPayload]) => (
      <div key={key} style={{ marginTop: 5 }}>
        <strong style={{ color: value.stroke }}>{value.name}</strong>: {formatTemperature(value.value)}
      </div>
    ))}
  </div>
)
