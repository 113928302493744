import { commonObjectGet } from '@store/actions/generic-actions'
import {
  EnhancedFloorHeatingMeasurement,
  FloorHeatingMeasurement,
  FloorHeatingWindowState,
} from '@modules/floor-heating/models'
import * as React from 'react'
import { useAppData } from '@components/hooks/use-app-data'
import { getEnhancedFloorMeasurements } from '@modules/floor-heating/utils'
import { PaginationResponse } from '@models/dashboard'
import { useAppDispatch } from '@store/index'
import { setDashboardStandardPaginator } from '@store/actions/dashboard-actions'
import { FloorHeatingListFilterParams } from '@modules/floor-heating/list/filter'

interface Response {
  measurements: EnhancedFloorHeatingMeasurement[]
  fetchMeasurements: (filters: FloorHeatingListFilterParams) => Promise<void>
}

export const useFloorHeatingMeasurements = (): Response => {
  const appData = useAppData()
  const dispatch = useAppDispatch()

  const [measurements, setMeasurements] = React.useState<FloorHeatingMeasurement[]>([])
  const [windowsState, setWindowsState] = React.useState<FloorHeatingWindowState[]>([])

  const fetchMeasurements = async (filters: FloorHeatingListFilterParams) => {
    const floorHeatingMeasurements = await commonObjectGet<PaginationResponse<FloorHeatingMeasurement>>(
      appData.urls.meter.floor_heating_measurements_list,
      filters,
    )

    dispatch(setDashboardStandardPaginator(floorHeatingMeasurements))
    const apartmentIds = [...new Set(floorHeatingMeasurements.results.map(measurement => measurement.apartment_name))]

    const windowsData = await commonObjectGet<FloorHeatingWindowState[]>(
      appData.urls.meter.floor_heating_windows_list,
      { apartment_name: apartmentIds },
    )

    setMeasurements(floorHeatingMeasurements.results)
    setWindowsState(windowsData)
  }

  const enhancedMeasurements = React.useMemo(
    () => getEnhancedFloorMeasurements(measurements, windowsState),
    [measurements, windowsState],
  )

  return {
    fetchMeasurements,
    measurements: enhancedMeasurements,
  }
}
