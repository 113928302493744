import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { useFeedingAppData } from '@modules/feeding/hooks/use-feeding-app-data'
import { FeedingWorkersDialogFormInputs } from '@modules/feeding/workers/dialog/index'
import { addDays, differenceInDays } from 'date-fns'
import { TotalPriceRowGuestsFeedingClientsCreateDialogTotalPriceRow } from '@modules/feeding/clients/create/total-price-row'
import { asDecimal } from '@helpers/utils'

export const FeedingWorkersDialogTotalPrice: React.FC = () => {
  const { watch } = useFormContext<FeedingWorkersDialogFormInputs>()

  const feedingAppData = useFeedingAppData()

  const [selectedProduct, dateFrom, dateTo] = watch(['product', 'date_from', 'date_to'])
  const product = feedingAppData.feeding_worker_products.find(
    row => row.id === parseInt(selectedProduct?.value || '0', 10),
  )

  const days = differenceInDays(addDays(dateTo, 1), dateFrom)

  if (!product || days <= 0) {
    return null
  }

  const totalPriceBrutto = asDecimal(product.price_brutto).mul(asDecimal(days))

  return (
    <div className="text-right font-16 mt-2">
      <TotalPriceRowGuestsFeedingClientsCreateDialogTotalPriceRow
        label="Do zapłaty"
        value={totalPriceBrutto.toString()}
      />
    </div>
  )
}
