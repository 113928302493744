import * as React from 'react'
import { ReservationDataRow } from '@modules/reservations/details/common/reservation-data-row'
import { IconWithText } from '@components/icon-with-text'
import { BadgeWithIcon } from '@components/badges/BadgeWithIcon'
import { ReceptionBookingDetails } from '@models/reception'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { updateReceptionBookingDetails } from '@store/actions/reception-actions'
import { commonObjectPut } from '@store/actions/generic-actions'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import classnames from 'classnames'
import { isBookingEditable } from '@modules/reservations/details/utils'

interface Props {
  booking: ReceptionBookingDetails
}

export const ReservationDataWarranty = ({ booking }: Props): JSX.Element => {
  const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()

  const { isLoading, action: handleWarrantyChange } = useApiRequest(async () => {
    const updatedBooking = await commonObjectPut<ReceptionBookingDetails>(booking.urls.reservation_booking_details, {
      warranty: !booking.warranty,
    })

    await dispatch(updateReceptionBookingDetails(updatedBooking))
    addSuccessMessage()
  })

  const isEditEnabled = isBookingEditable(booking)

  return (
    <ReservationDataRow
      name={<IconWithText icon="uil-times-circle" text="Opcja rezygnacji:" />}
      className={classnames('border-bottom pb-2 mr-3', { 'pe-none': !isEditEnabled })}
    >
      <CommonObjectConfirmAction
        title="Potwierdź zmianę opcji rezygnacji."
        handleAccept={handleWarrantyChange}
        isLoading={isLoading}
        message={`Czy na pewno chcesz ${booking.warranty ? 'usunąć' : 'dodać'} opcję rezygnacji?`}
      >
        {booking.warranty ? (
          <BadgeWithIcon
            icon="uil-check-circle"
            title="dodana"
            className="badge badge-success-lighten cursor-pointer"
          />
        ) : (
          <BadgeWithIcon icon="uil-times-circle" title="brak" className="badge badge-danger-lighten cursor-pointer" />
        )}
      </CommonObjectConfirmAction>
    </ReservationDataRow>
  )
}
