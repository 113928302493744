import * as React from 'react'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { asDecimal, formatPriceShort } from '@helpers/utils'
import { IconWithText } from '@components/icon-with-text'
import { useFormContext } from 'react-hook-form'
import { useFormRequest } from '@components/hooks/use-api-request'
import { commonObjectGet } from '@store/actions/generic-actions'
import { GastroCard } from '@models/promotions'
import { useAppData } from '@components/hooks/use-app-data'
import { Progress } from 'reactstrap'
import Spinner from '@hyper/spinner'

interface Props {
  selectedVoucher: CustomReactSelectOption
}

export const ReservationAddVoucherModalRow: React.FC<Props> = ({ selectedVoucher }) => {
  const { urls } = useAppData()
  const { setValue, setError, clearErrors } = useFormContext()

  const restToSpendPercentage = selectedVoucher?.context
    ? asDecimal(selectedVoucher.context.rest_to_use).mul(100).div(selectedVoucher.context.promotion_amount).toString()
    : '0'

  const handleClear = () => {
    setValue('gastro_card', null)
  }

  const { action: fetchCodeDetails, isLoading } = useFormRequest(async (code: string) => {
    try {
      const codeDetails = await commonObjectGet<GastroCard>(urls.promotions.client_wallet_code_details, { code })
      setValue('gastro_card', { ...selectedVoucher, context: codeDetails })
    } catch (error) {
      if (error.response.status === 404) {
        setValue('gastro_card', null)
        setError('gastro_card', { message: 'Nie znaleziono kodu' })
      } else {
        throw error
      }
    }
  }, setError)

  React.useEffect(() => {
    clearErrors()
    if (!selectedVoucher.context) {
      fetchCodeDetails(selectedVoucher.value)
    }
  }, [selectedVoucher?.context])

  return (
    <div className="col-8 ml-auto mb-3">
      {isLoading ? (
        <div>
          <Spinner className="avatar-3xs" />
          <span className="ml-1">Pobieram dane...</span>
        </div>
      ) : (
        <>
          <IconWithText
            wrapperClassNames="w-100"
            icon="uil-tag-alt"
            textClass="w-100"
            text={
              <div className="d-flex justify-content-between w-100">
                <div className="font-11">
                  <strong className="text-dark">{selectedVoucher.context?.product_name}</strong>
                  <span className="ml-1">{selectedVoucher.context?.code}</span>
                </div>
                <i className="uil-trash-alt cursor-pointer" onClick={handleClear} />
              </div>
            }
          />
          {selectedVoucher.context && (
            <>
              <div className="font-11 my-1">
                Środki do wykorzystania: <strong>{formatPriceShort(selectedVoucher.context.rest_to_use)}</strong> z{' '}
                {formatPriceShort(selectedVoucher.context.promotion_amount)}
              </div>
              <Progress value={restToSpendPercentage} color="secondary" className="mb-2" style={{ height: 6 }} />
            </>
          )}
        </>
      )}
    </div>
  )
}
