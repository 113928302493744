import * as React from 'react'
import { Transfer, TransferPackageDetails } from '@modules/accounting/refund/models'
import { SaveButton } from '@hyper/button'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApiRequest } from '@components/hooks/use-api-request'

interface TransferPackageEditTransferRemoveButtonProps {
  transfer: Transfer
  setTransferPackageDetails: (TransferPackageDetails) => void
}

export const TransferPackageEditTransferRemoveButton: React.FC<TransferPackageEditTransferRemoveButtonProps> = ({
  transfer,
  setTransferPackageDetails,
}) => {
  const { addSuccessNotification } = useNotificationHook()

  const { isLoading, action: handleRemove } = useApiRequest(async () => {
    setTransferPackageDetails(await commonObjectDelete<TransferPackageDetails>(transfer.urls.details))
    addSuccessNotification('Przelew został skasowany z paczki')
  })

  return (
    <SaveButton
      isSaving={isLoading}
      onClick={handleRemove}
      label="Usuń z paczki"
      labelSaving="Usuwanie.."
      type="button"
      className="mr-1 btn btn-danger"
    />
  )
}
