import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import {
  AuthenticatedUser,
  DashboardAppData,
  DashboardAppDataWithUser,
  DashboardCheckStats,
  SystemNotification,
} from '@models/dashboard'
import { emptyAppData } from '@store/reducers/dashboard-reducers'
import { getInitData, systemNotificationRead, systemNotificationRemove } from '@store/actions/app-actions'
import { deleteShopReceipt } from '@store/actions/shop-actions'
import { RootState } from '@store/index'

interface AppState {
  menuActive: string
  appData: DashboardAppData
  user: AuthenticatedUser
  system_notifications: EntityState<SystemNotification>
  check_stats: DashboardCheckStats[]
  isMobileView: boolean
  isSidebar: boolean
  isAuthenticated: boolean
  isFailure: boolean
}

const systemNotificationsAdapter = createEntityAdapter<SystemNotification>()

export const emptyUser: AuthenticatedUser = {
  id: 0,
  is_technical_order_manager: false,
  is_technical_order_worker: false,
  is_hijacked: false,
  date_joined: '1988-05-28',
  first_name: '',
  last_name: '',
  is_receptionist: false,
  email: '',
  permissions: [],
  seller_id: 0,
  resort_ids: [],
  system_notifications: [],
  is_superuser: false,
  payment_day_user_report: null,
  reception_workstation_id: null,
  band_reader_id: null,
  eservice_pad_id: null,
  tablet_id: null,
}

const initialState: AppState = {
  appData: emptyAppData,
  menuActive: '',
  isAuthenticated: false,
  system_notifications: systemNotificationsAdapter.getInitialState(),
  check_stats: [],
  user: emptyUser,
  isMobileView: false,
  isSidebar: true,
  isFailure: false,
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setMenuActive(state, action: PayloadAction<string>) {
      state.menuActive = action.payload
    },
    setAppData(state, action: PayloadAction<DashboardAppData>) {
      state.appData = action.payload
    },
    updateUserSystemNotifications(state, action: PayloadAction<SystemNotification>) {
      systemNotificationsAdapter.upsertOne(state.system_notifications, action.payload)
    },
    setAuthenticatedUser(state, action: PayloadAction<AuthenticatedUser>) {
      if (!state.user.id || state.user.id === action.payload.id) {
        state.user = action.payload
      }
      systemNotificationsAdapter.setAll(state.system_notifications, action.payload.system_notifications)
    },
    updateDashboardCheckStats(state, action: PayloadAction<DashboardCheckStats[]>) {
      state.check_stats = action.payload
    },
    setDashboardSidebar(state, action: PayloadAction<boolean>) {
      state.isSidebar = action.payload
    },
    setDashboardMobileView(state, action: PayloadAction<boolean>) {
      state.isMobileView = action.payload
    },
    setDashboardFailureStatus(state, action: PayloadAction<boolean>) {
      state.isFailure = action.payload
    },
    setAuthStatus(state, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload
      if (!action.payload) {
        state.isFailure = false
        state.appData.status = 'unknown'
      }
    },
    setIsFailure(state) {
      state.isFailure = true
      state.isAuthenticated = false
      state.appData.status = 'unknown'
    },
    setAppDataAndUser(state, action: PayloadAction<DashboardAppDataWithUser>) {
      state.appData = action.payload
      if (action.payload.user) {
        if (!state.user.id || state.user.id === action.payload.user.id) {
          state.user = action.payload.user
          systemNotificationsAdapter.setAll(state.system_notifications, action.payload.user.system_notifications)
        }
      }
      state.appData.status = 'ready'

      state.isAuthenticated = true
      state.isFailure = false
    },
  },
  extraReducers: builder => {
    builder.addCase(getInitData.rejected, state => {
      state.isFailure = true
      state.isAuthenticated = false
      state.appData.status = 'unknown'
    })
    builder.addCase(getInitData.fulfilled, (state, action) => {
      if (state.appData.status != 'ready') {
        state.appData = action.payload
        if (action.payload.user) {
          if (!state.user.id || state.user.id === action.payload.user.id) {
            state.user = action.payload.user
          }
          systemNotificationsAdapter.setAll(state.system_notifications, action.payload.user.system_notifications)
        }
        state.appData.status = 'ready'

        state.isAuthenticated = true
        state.isFailure = false
      }
    })
    builder.addCase(systemNotificationRemove.fulfilled, (state, action) => {
      systemNotificationsAdapter.setAll(state.system_notifications, action.payload.system_notifications)
    })
    builder.addCase(systemNotificationRead.fulfilled, (state, action) => {
      systemNotificationsAdapter.setAll(state.system_notifications, action.payload.system_notifications)
    })
    builder.addCase(deleteShopReceipt.fulfilled, (state, action) => {
      if (action.payload.token === state.appData.receipt_unfinished?.token) {
        state.appData = { ...state.appData, receipt_unfinished: null }
      }
    })
  },
})

export const {
  setMenuActive,
  setAuthenticatedUser,
  updateUserSystemNotifications,
  setAppData,
  updateDashboardCheckStats,
  setDashboardMobileView,
  setDashboardSidebar,
  setAuthStatus,
  setIsFailure,
  setAppDataAndUser,
} = appSlice.actions

export const { selectAll: systemNotificationsSelector } = systemNotificationsAdapter.getSelectors(
  (state: RootState) => state.appState.system_notifications,
)

export default appSlice.reducer
