import * as React from 'react'
import { CmsResortDetails, CmsResortDetailsMapSector } from '@modules/cms/models'
import { useModal } from '@components/modals/use-modal'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { useAppDispatch } from '@store/index'
import { setCmsResort } from '@store/slices/cms-reservation-app-slice'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'

interface Props {
  sector: CmsResortDetailsMapSector
  resort: CmsResortDetails
}

export const CmsReservationAppResortMapRow = ({ sector, resort }: Props): JSX.Element => {
  const [handleEdit] = useModal('CmsReservationAppResortMapModal', { resortSectorMap: sector, resort })
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading, action: handleRemoveAccept } = useApiRequest(async () => {
    dispatch(setCmsResort(await commonObjectDelete<CmsResortDetails>(sector.urls.details)))
    addSuccessMessage('Sukces', 'Sektor został skasowany')
  })

  return (
    <li>
      {sector.display_name} (
      <a href={sector.map} target="_blank">
        {sector.name}
      </a>
      )
      <i className="uil-edit text-muted font-14 ml-1 cursor-pointer" onClick={handleEdit} />
      <CommonObjectConfirmAction isLoading={isLoading} handleAccept={handleRemoveAccept}>
        <i className="uil-trash-alt text-muted font-14 ml-1 cursor-pointer" />
      </CommonObjectConfirmAction>
    </li>
  )
}
