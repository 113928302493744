import * as React from 'react'
import { BeddingOrder } from '@modules/housekeeping/models'
import { HousekeepingOrderWorkersRow } from '@modules/housekeeping/common/workers'
import { HousekeepingOrdersRowTotalTimeStarted } from '@modules/housekeeping/common/row-total-time-started'
import { HousekeepingCleaningOrderOrderedByRow } from '@modules/housekeeping/cleaning-orders/common/ordered-by'

interface Props {
  beddingOrder: BeddingOrder
}

export const HousekeepingBeddingOrderArchiveRow: React.FC<Props> = ({ beddingOrder }) => (
  <tr className="housekeeping__orders_table__row">
    <td className="housekeeping__orders_table__column housekeeping__orders_table__column__first">
      {beddingOrder.apartment.name}
    </td>
    <td className="housekeeping__orders_table__column">Pościelowy</td>
    <td className="housekeeping__orders_table__column">{beddingOrder.status_display}</td>
    <HousekeepingOrderWorkersRow order={beddingOrder} />

    <HousekeepingOrdersRowTotalTimeStarted totalTimeStarted={beddingOrder.total_time_started} />
    <HousekeepingCleaningOrderOrderedByRow ordered_at={beddingOrder.ordered_at} ordered_by={beddingOrder.ordered_by} />
    <td className="housekeeping__orders_table__column text-nowrap">-</td>
    <td className="housekeeping__orders_table__column text-nowrap">-</td>
  </tr>
)
