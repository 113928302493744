import * as React from 'react'
import classnames from 'classnames'
import { ReservationStatsTableCells } from '@modules/reservations/models'
import { ReservationStatsPlanningReportRow } from '@modules/reservations/statistics/booking-stats/stats-details/stats-details-table/reservation-stats-planning-report-row'

interface ReservationStatsPlanningReportRowsProps {
  housesDataCells: ReservationStatsTableCells
  apartmentsDataCells: ReservationStatsTableCells
  lightGray?: boolean
}

export const ReservationStatsPlanningReportRows: React.FC<ReservationStatsPlanningReportRowsProps> = ({
  housesDataCells,
  apartmentsDataCells,
  lightGray,
}) => (
  <>
    <ReservationStatsPlanningReportRow
      rowClassName={classnames({ 'reservation-stats__row__report--bg-light': lightGray })}
      dataCells={housesDataCells}
      title="domki"
    />
    <ReservationStatsPlanningReportRow
      rowClassName={classnames({ 'reservation-stats__row__report--bg-light': lightGray })}
      dataCells={apartmentsDataCells}
      title="apartamenty"
    />
  </>
)
