import * as React from 'react'
import { Button, Row } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { UserPermission } from '@models/dashboard'
import { ColAuto } from '@hyper/col-auto'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useGroupedSellers } from '@components/hooks/use-grouped-sellers'
import { useGroupedSources } from '@components/hooks/use-grouped-sources'
import { GastroCardAdd } from '@modules/promotions/gastro-cards/gastro-card-add'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { GastroCardTableOptions } from '@modules/promotions/gastro-cards/gastro-card-table-options'
import { GastroCard, GastroCardKind, GastroCardStatus } from '@models/promotions'
import { useParams } from 'react-router-dom'
import { GastroCardDetailsView } from '@modules/promotions/gastro-cards/details-view'
import { Form } from '@hyper/forms/form'
import { FormSelect } from '@hyper/forms/form-select'
import { GastroFilters } from '@modules/promotions/gastro-cards/gastro-cards-table'
import { isGastroGenericProduct } from '@modules/promotions/gastro-cards/utils'
import { useAppSelector } from '@store/index'
import { selectProductsAppData } from '@store/slices/products-slice'
import { createSelectOption } from '@helpers/utils'
import { GastroGenericProductTag } from '@models/products'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { FormCheckbox } from '@hyper/forms/form-checkbox'

interface Props {
  children?: React.ReactNode
  setFilters: (filters) => void
  defaultFilters: GastroFilters
  filters: GastroFilters
  fetchGastroCards: () => void
  selectedRows: GastroCard[]
  clearSelectedRows: () => void
  kind: GastroCardKind
}

export const gastroCardsStatus: CustomReactSelectOption<string, GastroCardStatus>[] = [
  { value: 'initial', label: 'Wstępny' },
  { value: 'initial_canceled', label: 'Wstępny (anulowany)' },
  { value: 'confirmed', label: 'Potwierdzony' },
  { value: 'cancelled', label: 'Anulowany' },
  { value: 'completed', label: 'Zakończony' },
]

export const GastroCardsFilters: React.FC<Props> = ({
  setFilters,
  fetchGastroCards,
  selectedRows,
  clearSelectedRows,
  children,
  filters,
  defaultFilters,
  kind,
}) => {
  const user = useAuthenticatedUser()
  const { id: gastro_card_id } = useParams<'id'>()

  const { gastro_card_tags } = useAppSelector(selectProductsAppData)

  const methods = useForm<GastroFilters>({
    defaultValues: defaultFilters,
  })

  const onSubmit = async (payload: GastroFilters) => setFilters({ ...filters, ...payload })

  const handleReset = () => {
    methods.reset()
    setFilters(defaultFilters)
  }

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  const sellersOption = useGroupedSellers()
  const sourcesOption = useGroupedSources()

  const tagOptions = gastro_card_tags.map((tag: GastroGenericProductTag) => createSelectOption(tag.name, tag.id))

  const isGastroCardCompensation = kind === 'gastro_card_compensation'

  return (
    <Form methods={methods} onSubmit={onSubmit} className="form form-style-filter">
      {gastro_card_id && <GastroCardDetailsView gastro_card_id={gastro_card_id} kind={kind} />}

      <Row>
        <FormSelect
          options={gastroCardsStatus}
          name="status"
          label="Status"
          selectProps={{ isClearable: true, isSelectMulti: true }}
          formPlainProps={{ colStyle: { maxWidth: 250 } }}
        />
        <FormSelect
          options={sellersOption}
          name="seller"
          label="Sprzedawca"
          selectProps={{ isClearable: true }}
          formPlainProps={{ colStyle: { maxWidth: 200 } }}
        />
        {isGastroGenericProduct(kind) && !isGastroCardCompensation && (
          <FormSelect
            options={tagOptions}
            name="tags"
            label="Typ"
            selectProps={{ isClearable: true }}
            formPlainProps={{ colStyle: { maxWidth: 230 } }}
          />
        )}

        {!isGastroCardCompensation && (
          <FormSelect
            options={sourcesOption}
            name="source_marketing"
            label="Źródło"
            selectProps={{ isClearable: true }}
            formPlainProps={{ colStyle: { maxWidth: 200 } }}
          />
        )}

        <DateFromToInput
          startDateName="sell_date_after"
          endDateName="sell_date_before"
          label="Data utworzenia"
          colSize={3}
          style={{ minWidth: 360, maxWidth: 360, width: 360 }}
        />

        <DateFromToInput
          startDateName="payment_date_after"
          endDateName="payment_date_before"
          label="Data wpłaty"
          colSize={3}
          style={{ minWidth: 360, maxWidth: 360, width: 360 }}
        />

        {user.hasPerm(UserPermission.PromotionsGastroCardCanDelete) && kind !== 'gastro_coupon' && (
          <ColAuto className="align-self-end mb-3 mx-2">
            <FormCheckbox name="is_removed" label="Pokaż skasowane" />
          </ColAuto>
        )}

        {methods.formState.isDirty && (
          <ColAuto>
            <Button color="light" className="mt-label" type="button" onClick={handleReset}>
              Wyczyść
            </Button>
          </ColAuto>
        )}

        <ColAuto className="mt-label ml-auto">{children ? children : <GastroCardAdd kind={kind} />}</ColAuto>
      </Row>

      <Row className="mt-2">
        <ColAuto>
          <GastroCardTableOptions
            clearSelectedRows={clearSelectedRows}
            selectedRows={selectedRows}
            fetchGastroCards={fetchGastroCards}
          />
        </ColAuto>
        <SearchFilter hideLabel={true} colClassName="col-md-2" />
      </Row>
    </Form>
  )
}
