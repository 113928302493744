import { Agreement, AgreementDetails } from '@modules/hr/agreement/models'
import { useAppDispatch, useAppSelector } from '@store/index'
import { selectAgreementDetails, clearAgreementDetails } from '@store/slices/hr-slice'
import * as React from 'react'
import { useApiRequest } from '@components/hooks/use-api-request'
import { getAgreementDetails } from '@store/actions/hr-action'

interface Response {
  agreementDetails: AgreementDetails | null
  fetch: () => Promise<void>
  refresh: () => Promise<void>
  isLoading: boolean
}

interface Params {
  agreement: Agreement
  immediateFetch?: boolean
  withClear?: boolean
}

export const useAgreementDetails = ({ agreement, immediateFetch = true, withClear = true }: Params): Response => {
  const agreementDetails = useAppSelector(selectAgreementDetails)
  const dispatch = useAppDispatch()

  const refresh = async () => {
    await dispatch(getAgreementDetails(agreement.id))
  }

  const { isLoading, action: fetch } = useApiRequest(async () => {
    await refresh()
  })

  React.useEffect(() => {
    if (immediateFetch) {
      fetch()
    }

    return () => {
      if (withClear) {
        dispatch(clearAgreementDetails())
      }
    }
  }, [])

  return { agreementDetails, refresh, fetch, isLoading }
}
