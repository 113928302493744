import * as React from 'react'
import { PackageDetailsRow } from '@modules/package/details/information/row'
import { CardBody, Row } from 'reactstrap'
import { ColAuto } from '@hyper/col-auto'
import { IconWithText } from '@components/icon-with-text'
import { PackageWholesaleDetails } from '@modules/package-wholesale/models'

interface Props {
  packageWholesaleDetails: PackageWholesaleDetails
  toggleEdit: () => void
}

export const PackageWholesaleDetailsClientInformationTable: React.FC<Props> = ({
  packageWholesaleDetails,
  toggleEdit,
}) => (
  <CardBody>
    <Row>
      <ColAuto>
        <h4 className="text-secondary mb-2 mt-2">Dane klienta</h4>
      </ColAuto>
      {packageWholesaleDetails.status === 'open' && (
        <ColAuto className="col-auto ml-auto">
          <IconWithText
            icon="uil-edit-alt font-16"
            text="zmień"
            wrapperClassNames="cursor-pointer text-secondary"
            textClass="font-10 text-semi-strong"
            onClick={toggleEdit}
          />
        </ColAuto>
      )}
    </Row>
    <table className="table table-sm mb-0">
      <tbody>
        <PackageDetailsRow label="Osoba kontaktowa">{packageWholesaleDetails.name}</PackageDetailsRow>
        <PackageDetailsRow label="Adres email">{packageWholesaleDetails.email}</PackageDetailsRow>
        <PackageDetailsRow label="Nr telefonu">{packageWholesaleDetails.phone}</PackageDetailsRow>
      </tbody>
    </table>
    <h4 className="text-secondary mb-2 mt-2">Dane firmy</h4>
    <table className="table table-sm mb-0">
      <tbody>
        <PackageDetailsRow label="Nazwa firmy">{packageWholesaleDetails.invoice_company}</PackageDetailsRow>
        <PackageDetailsRow label="NIP">{packageWholesaleDetails.invoice_nip}</PackageDetailsRow>
        <PackageDetailsRow label="Ulica">{packageWholesaleDetails.invoice_street}</PackageDetailsRow>
        <PackageDetailsRow label="Miasto">{`${packageWholesaleDetails.invoice_city}, ${packageWholesaleDetails.invoice_postcode}`}</PackageDetailsRow>
      </tbody>
    </table>
    <h4 className="text-secondary mb-2 mt-2">Strona sprzedaży</h4>
    <table className="table table-sm mb-0">
      <tbody>
        <PackageDetailsRow label="Nazwa wyswietlana">
          {packageWholesaleDetails.display_name || 'brak'}
        </PackageDetailsRow>
      </tbody>
    </table>
  </CardBody>
)
