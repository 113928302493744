import * as React from 'react'
import { FormInput } from '@hyper/forms'
import { handleEnterPress } from '@helpers/utils'
import { FormProvider, useForm } from 'react-hook-form'
import { useFormRequest } from '@components/hooks/use-api-request'
import {
  NewFeedingCalculationDetails,
  NewFeedingCalculations,
} from '@modules/reception/checkin/step-feeding/new-feeding/models'
import { commonObjectPut } from '@store/actions/generic-actions'
import { useAppDispatch, useAppSelector } from '@store/index'
import {
  selectLastPriceChangeReason,
  setFeedingCalculations,
  setInitialFeedingCalculations,
  setLastPriceChangeReason,
} from '@store/slices/new-feeding-slice'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { useModal } from '@components/modals/use-modal'

interface FormInputs {
  fixed_price_brutto: string
}

interface Props {
  onEditionClose: () => void
  calculation: NewFeedingCalculationDetails
}

export const ReceptionBookingNewFeedingSingleMealPriceEdit = ({ onEditionClose, calculation }: Props): JSX.Element => {
  const dispatch = useAppDispatch()
  const methods = useForm<FormInputs>()

  const lastPriceChangeReason = useAppSelector(selectLastPriceChangeReason)

  const [showConfirmationModal, hideConfirmationModal] = useModal('ConfirmModal', {}, { persist: true })

  const { action: updateFeeding, isLoading } = useFormRequest(async (reason: string) => {
    if (!calculation.urls.fixed_price) return

    const newFeedingCalculations = await commonObjectPut<NewFeedingCalculations>(calculation.urls.fixed_price, {
      reason,
      ...methods.getValues(),
    })

    dispatch(setLastPriceChangeReason(reason))
    dispatch(setInitialFeedingCalculations(newFeedingCalculations))
    dispatch(setFeedingCalculations(newFeedingCalculations))
    hideConfirmationModal()
    onEditionClose()
  }, [])

  const handleEditionAbort = event => {
    event.preventDefault()
    onEditionClose()
  }

  const showPriceChangeConfirmationModal = () => {
    showConfirmationModal(null, {
      askForReason: true,
      title: 'Edycja ceny wyżywienia',
      onAccept: updateFeeding,
      defaultReason: lastPriceChangeReason,
    })
  }
  return (
    <FormProvider {...methods}>
      <div className="border rounded mr-2">
        <FormInput
          name="fixed_price_brutto"
          type="currency"
          setValue={methods.setValue}
          colClassName="px-0"
          formGroupClassName="mb-0 d-flex align-items-center"
          onKeyDown={handleEnterPress.bind(showPriceChangeConfirmationModal)}
          disabled={isLoading}
          inputProps={{
            autoFocus: true,
            style: { height: 23, paddingLeft: 5, paddingRight: 5, fontSize: 13, width: 45, border: 'none' },
          }}
          afterInput={
            <div className="d-flex ml-1 align-items-center mr-1">
              <CommonObjectConfirmAction
                askForReason={true}
                message=""
                title="Edycja ceny wyżywienia"
                handleAccept={updateFeeding}
                defaultReason={lastPriceChangeReason}
              >
                <i className="uil-check font-15 text-success lh-1 cursor-pointer" />
              </CommonObjectConfirmAction>
              <i className="uil-times font-15 text-danger ml-1 lh-1" onClick={handleEditionAbort} />
            </div>
          }
        />
      </div>
    </FormProvider>
  )
}
