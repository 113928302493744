import * as React from 'react'
import Decimal from 'decimal.js'
import { asDecimal } from '@helpers/utils'

interface PercentageChangeIndicatorProps {
  baseValue: Decimal.Value
  valueToCompare: Decimal.Value
  precision?: number
  children?: React.ReactElement | string | number
}

export const PercentageChangeIndicator: React.FC<PercentageChangeIndicatorProps> = ({
  baseValue,
  valueToCompare,
  precision = 2,
  children,
}) => {
  const value = (valueToCompare && asDecimal(baseValue).div(valueToCompare).mul(100).sub(100).toFixed(precision)) || 0

  const isIncrease = asDecimal(value).greaterThan(0)

  return (
    <div className="flex-nowrap d-flex align-items-center justify-content-end">
      {!asDecimal(value).equals(0) && (
        <span
          className={`${
            isIncrease ? 'text-success' : 'text-danger'
          } font-12 text-semi-strong mr-1 d-flex align-items-end justify-content-center`}
        >
          <i className={`${isIncrease ? 'uil-arrow-up' : 'uil-arrow-down'}  font-15 font-weight-bold`} /> {value}%
        </span>
      )}
      {children}
    </div>
  )
}
