import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { Button, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useForm } from 'react-hook-form'
import { endOfMonth, startOfMonth, startOfToday } from 'date-fns'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { createReportTask } from '@store/actions/report-tasks-actions'
import { ReportFormat } from '@components/report-generate-format-dropdown-button'
import { Form } from '@hyper/forms/form'
import { FormSelect } from '@hyper/forms/form-select'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useMultipleResorts } from '@components/hooks/use-multiple-resorts'
import { createSelectOption } from '@helpers/utils'
import { SaveButton } from '@hyper/button'

interface FormInputs {
  date_after: Date
  date_before: Date
  resorts: CustomReactSelectOption[]
  report_format: ReportFormat
}

interface Props extends BaseModalProps {
  resorts?: CustomReactSelectOption[]
  date_after: Date
  date_before: Date
}

export const ReportSmsNotificationsModal: React.FC<Props> = ({ toggleIsVisible, resorts, date_after, date_before }) => {
  const reportUrl = useAppSelector((state: RootState) => state.appState.appData.urls.reports.report_task_sms_report)

  const dispatch = useAppDispatch()

  const methods = useForm<FormInputs>({
    mode: 'all',
    defaultValues: {
      date_after: date_after || startOfMonth(startOfToday()),
      date_before: date_before || endOfMonth(startOfToday()),
      resorts: resorts?.length ? resorts : [createSelectOption('Wszystkie', 'all')],
    },
  })

  const { getResortOptions, getSelectedResortsIds } = useMultipleResorts({ methods, field: 'resorts' })
  const resortOptions = React.useMemo(() => getResortOptions({ withAllOption: true }), [])

  const { isLoading, action: onSubmit } = useReportFormRequest(async (payload: FormInputs) => {
    const reportTask = await dispatch(
      createReportTask([reportUrl, { ...payload, resorts: getSelectedResortsIds(payload.resorts) }]),
    )
    toggleIsVisible()
    return reportTask
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Wysłane wiadomości SMS</ModalHeader>
      <ModalBody>
        <Row>
          <DateFromToInput isClearable={false} label="Data" startDateName="date_after" endDateName="date_before" />
          <FormSelect options={resortOptions} name="resorts" label="Ośrodek" selectProps={{ isMulti: true }} />
        </Row>
      </ModalBody>

      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" label="Zleć raport" labelSaving="Zlecanie.." isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
