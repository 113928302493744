import * as React from 'react'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { TechnicalOrdersDoneTableRow } from '@modules/technical-orders/technical-orders-done/row'
import { useGetTechnicalOrdersQuery } from '@api/technical-orders'
import { TechnicalOrdersDoneTableFilters } from '@modules/technical-orders/technical-orders-done/filters'
import { add, startOfToday } from 'date-fns'
import { TechnicalOrderTableUnitHeader } from '@modules/technical-orders/common/table-unit-header'
import { TechnicalOrderTableTypeHeader } from '@modules/technical-orders/common/table-type-header'
import { TechnicalOrderTableUsersHeader } from '@modules/technical-orders/common/table-users-header'
import { filterTableHeaders } from '@modules/technical-orders/helpers'
import { TypedQueryResult } from '@api/base'
import { TechnicalOrder } from '@models/technical-orders'
import { TechnicalOrderTableAcceptedByHeader } from '@modules/technical-orders/common/table-unit-accepted-by'

interface Props {
  resortId: number
}

interface TechnicalOrdersDoneTableTableFilters extends TableFilters {
  finished_at_before: string
  finished_at_after: string
}

const defaultFilters: TechnicalOrdersDoneTableTableFilters = {
  search: '',
  ordering: '-finished_at',
  page: 1,
  page_size: 25,
  finished_at_after: add(startOfToday(), { days: -7 }).toISOString(),
  finished_at_before: add(startOfToday(), { days: 1 }).toISOString(),
}

const emptyTable = []

export const TechnicalOrdersDoneTable: React.FC<Props> = ({ resortId }) => {
  const [filters, setFilters] = React.useState<TableFilters>(defaultFilters)

  const headers: TableHeaderValue[] = React.useMemo(
    () =>
      filterTableHeaders(
        [
          { title: 'Nr zadania', sortField: 'number' },
          { title: 'Lokalizacja', sortField: 'resort_id' },
          { title: <TechnicalOrderTableUnitHeader resortId={resortId} setFilters={setFilters} /> },
          { title: 'Czas pracy' },
          { title: <TechnicalOrderTableTypeHeader setFilters={setFilters} /> },
          { title: <TechnicalOrderTableUsersHeader setFilters={setFilters} resortId={resortId} /> },
          { title: <TechnicalOrderTableAcceptedByHeader setFilters={setFilters} /> },
          { title: '' },
          { title: ' ' },
        ],
        resortId,
      ),
    [resortId],
  )

  const { data: technicalOrders = emptyTable } = useGetTechnicalOrdersQuery({
    resort: resortId,
    status: 'done',
    ...filters,
  }) as TypedQueryResult<TechnicalOrder[]>

  return (
    <>
      <TechnicalOrdersDoneTableFilters setFilters={setFilters} defaultFilters={defaultFilters} filters={filters} />
      <Table
        headerClassName="bg-white border-top-0 vertical-align-middle"
        className="table-hover w-100 mb-0"
        headerValues={headers}
        isLoading={false}
        filters={filters}
        setFilters={setFilters}
        cols={['10%', '10%', '10%', '10%', '10%', '10%', '3%', '10%']}
      >
        {technicalOrders.map(technicalOrder => (
          <TechnicalOrdersDoneTableRow key={technicalOrder.id} showResort={!resortId} technicalOrder={technicalOrder} />
        ))}
      </Table>
    </>
  )
}
