import * as React from 'react'
import Table from '@components/table/table'
import { formatPrice } from '@helpers/utils'
import { bruttoToNetto } from '@helpers/price'
import { useAppSelector } from '@store/index'
import { selectPackageWholesaleAggregation } from '@store/slices/package-wholesale-slice'

export const PackageWholesaleListTableFooter = (): JSX.Element => {
  const aggregation = useAppSelector(selectPackageWholesaleAggregation)

  return (
    <Table.Row>
      <Table.Cell colSpan={7} className="text-right align-middle package-list__summary-cell">
        <strong className="mr-2">Razem w wybranym okresie:</strong>
      </Table.Cell>
      <Table.Cell className="package-list__summary-cell">
        <div className="text-white package-list__summary-block text-strong">
          {formatPrice(bruttoToNetto(aggregation?.total_price_brutto_sum ?? '0', 8))} /{' '}
          {formatPrice(bruttoToNetto(aggregation?.total_price_brutto_all_sum ?? '0', 8))}
        </div>
      </Table.Cell>
      <Table.Cell className="package-list__summary-cell">
        <div className="text-white package-list__summary-block text-strong">
          {formatPrice(aggregation?.gastro_card_total_price_brutto_sum ?? 0)} /{' '}
          {formatPrice(aggregation?.gastro_card_total_price_brutto_all_sum ?? 0)}
        </div>
      </Table.Cell>
      <Table.Cell className="package-list__summary-cell">
        <div className="text-white package-list__summary-block text-strong">
          {aggregation?.houses_sea_sum} / {aggregation?.houses_sea_all_sum}
        </div>
      </Table.Cell>
      <Table.Cell className="package-list__summary-cell">
        <div className="text-white package-list__summary-block text-strong">
          {aggregation?.apartments_sea_sum} / {aggregation?.apartments_sea_all_sum}
        </div>
      </Table.Cell>
      <Table.Cell className="package-list__summary-cell">
        <div className="text-white package-list__summary-block text-strong">
          {aggregation?.houses_mountains_sum} / {aggregation?.houses_mountains_all_sum}
        </div>
      </Table.Cell>
      <Table.Cell className="package-list__summary-cell">
        <div className="text-white package-list__summary-block text-strong">
          {aggregation?.apartments_mountains_sum} / {aggregation?.apartments_mountains_all_sum}
        </div>
      </Table.Cell>
      <Table.Cell className="package-list__summary-cell" />
    </Table.Row>
  )
}
