import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { BookingRefund } from '@modules/accounting/refund/models'
import { Button, Col, CustomInput, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useFormRequest } from '@components/hooks/use-api-request'
import { commonObjectPost } from '@store/actions/generic-actions'
import { updateAccountingBookingRefund } from '@store/slices/accounting-slice'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useForm, useWatch } from 'react-hook-form'
import { formatPrice } from '@helpers/utils'
import { FormInput, FormPlain } from '@hyper/forms'
import { extractInnerRef } from '@helpers/forms'
import { Form } from '@hyper/forms/form'

interface Props extends BaseModalProps {
  booking: BookingRefund
}

interface FormInputs {
  custom_deposit_to_return: boolean
  deposit_to_return_custom_amount: string
  deposit_to_return_custom_amount_reason: string
}

export const Przelewy24SendDialog: React.FC<Props> = ({ toggleIsVisible, booking }) => {
  const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()
  const methods = useForm<FormInputs>({
    defaultValues: {
      deposit_to_return_custom_amount: booking.deposit_to_return,
      custom_deposit_to_return: false,
    },
  })

  const { isLoading, action: onSubmit } = useFormRequest(
    async (payload: FormInputs) => {
      await dispatch(updateAccountingBookingRefund(await commonObjectPost<BookingRefund>(booking.urls.refund, payload)))
      addSuccessMessage('Sukces', `Rezerwacja o ${booking.reservation_number} została zlecona do zwrotu`)
    },
    methods.setError,
    {
      showGlobalError: true,
    },
  )

  const customDepositToReturn = useWatch({ control: methods.control, name: 'custom_deposit_to_return' })

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Potwierdź operacje</ModalHeader>
      <ModalBody className="pb-0">
        <Row>
          <Col md={12}>
            <p>Potwierdź zlecenie zwrotu w kwocie {formatPrice(booking.deposit_to_return)}</p>
          </Col>
          <FormPlain name="date_from_range" colSize={12}>
            <CustomInput
              type="checkbox"
              {...extractInnerRef(methods.register('custom_deposit_to_return'))}
              id="custom_deposit_to_return"
              label="Chcę zwrócić inną kwotę"
            />
          </FormPlain>
          <FormInput
            colSize={4}
            name="deposit_to_return_custom_amount"
            label="Własna kwota zwrotu"
            step="0.01"
            disabled={!customDepositToReturn}
            type="number"
          />
          <FormInput
            colSize={8}
            name="deposit_to_return_custom_amount_reason"
            disabled={!customDepositToReturn}
            label="Powód zmienionej kwoty"
            type="text"
          />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Anuluj
        </Button>

        <SaveButton className="btn btn-green" label="Potwierdź" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
