import * as React from 'react'
import { FormPlain } from '@hyper/forms'
import { Label } from 'reactstrap'
import { ColAuto } from '@hyper/col-auto'
import DatePickerInput from '@components/date/date-picker-input'

interface DateFilterProps {
  name: string
  control: any
  label?: string
  formGroupClassName?: string
  minDate?: Date | null
  maxDate?: Date | null
  isDisabled?: boolean
}

export const DatePickerForm: React.FC<DateFilterProps> = ({
  control,
  name,
  label,
  formGroupClassName,
  minDate,
  maxDate,
  isDisabled,
}) => (
  <ColAuto>
    <FormPlain colClassName="p-0" name={name} errors={{}} formGroupClassName={formGroupClassName}>
      {label && <Label>{label}</Label>}
      <DatePickerInput
        isDisabled={isDisabled}
        errors={{}}
        control={control}
        name={name}
        minDate={minDate}
        maxDate={maxDate}
      />
    </FormPlain>
  </ColAuto>
)
