import * as React from 'react'
import { useAppDispatch } from '@store/index'
import { setMenuActive } from '@store/slices/app-slice'

export const useMenuActive = (menu: string) => {
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    dispatch(setMenuActive(menu))

    return () => {
      dispatch(setMenuActive(''))
    }
  }, [menu])
}
