import * as React from 'react'
import { FormInput } from '@hyper/forms'
import { Row } from 'reactstrap'
import { FormSelect } from '@hyper/forms/form-select'
import { FormInputIbanAccountNumber } from '@hyper/forms/form-input-iban-account-number'
import { IconWithTooltip } from '@components/icon-with-tooltip'
import { useAddressesData } from '@modules/hr/agreement/use-addresses-data'
import { createSelectOption } from '@helpers/utils'
import { useFormContext } from 'react-hook-form'
import { AgreementFormInputs } from '@modules/hr/agreement/create/modal'
import { makeDefaultSelectOption } from '@components/custom-react-select'

export interface HrHealthCareAndFinanceData {
  postcode: string
  tax_office: string
  national_health_fund_branch: string
  post: string
}

interface Props {
  data?: HrHealthCareAndFinanceData | null
}

export const HrHealthcareAndFinanceForm = ({ data }: Props): JSX.Element => {
  const { setValue } = useFormContext<AgreementFormInputs>()

  const { addressData } = useAddressesData({
    autoFetch: !!data?.postcode,
    code: data?.postcode,
  })

  const taxOfficesOptions = (addressData?.tax_offices || []).map(taxOffice =>
    createSelectOption(taxOffice.name, taxOffice.name),
  )

  React.useEffect(() => {
    if (data?.post && data.tax_office && taxOfficesOptions.length) {
      setValue('tax_office', makeDefaultSelectOption(taxOfficesOptions, data.tax_office))
    }
  }, [addressData?.tax_offices, data?.tax_office])

  React.useEffect(() => {
    setValue('national_health_fund_branch', addressData?.health_branch.name ?? '')
  }, [addressData?.health_branch?.name])

  return (
    <Row>
      <FormInput
        name="national_health_fund_branch"
        colSize={12}
        readOnly={true}
        label={
          <div>
            <span>Oddział Narodowego Funduszu Zdrowia</span>
            {!data?.postcode && (
              <IconWithTooltip
                icon="uil-exclamation-circle ml-1"
                tooltipId="agreement-missing-address-health-branch"
                tooltipPlacement="right"
                tooltipMessage={
                  <div className="font-11 text-left">
                    Oddział NFZ uzupełni się automatycznie po uzupełnieniu danych adresowych.
                  </div>
                }
                color="text-secondary"
              />
            )}
          </div>
        }
      />
      <FormInputIbanAccountNumber formName="account_number" />
      <FormInput colSize={6} label="Nazwa banku" name="bank_name" />
      <FormSelect
        options={taxOfficesOptions}
        name="tax_office"
        formPlainProps={{ colSize: 6 }}
        label={
          <div>
            <span>Urząd skarbowy</span>
            {!data?.postcode && (
              <IconWithTooltip
                icon="uil-exclamation-circle ml-1"
                tooltipId="agreement-missing-address-tax-office"
                tooltipPlacement="right"
                tooltipMessage={
                  <div className="font-11 text-left">
                    Dostępne urzędy skarbowe będą widoczne po uzupełnieniu danych adresowych.
                  </div>
                }
                color="text-secondary"
              />
            )}
          </div>
        }
      />
    </Row>
  )
}
