import { CustomReactSelectOption } from '@components/custom-react-select'

export const HOUSE_85_ACCOMMODATION_TYPE = 1
export const HOUSE_85_WITH_GARDEN_ACCOMMODATION_TYPE = 4

export const APARTMENT_40_ACCOMMODATION_TYPE = 2
export const APARTMENT_55_ACCOMMODATION_TYPE = 3
export const APARTMENT_40_WITH_GARDEN_ACCOMMODATION_TYPE = 5

export const ACCOMMODATION_TYPES = {
  HOUSES: [HOUSE_85_ACCOMMODATION_TYPE, HOUSE_85_WITH_GARDEN_ACCOMMODATION_TYPE],
  APARTMENTS: [
    APARTMENT_40_ACCOMMODATION_TYPE,
    APARTMENT_55_ACCOMMODATION_TYPE,
    APARTMENT_40_WITH_GARDEN_ACCOMMODATION_TYPE,
  ],
}

export const YesNoOptions: CustomReactSelectOption[] = [
  { label: 'Tak', value: true },
  { label: 'Nie', value: false },
]
