import * as React from 'react'
import { CleaningOrder } from '@modules/housekeeping/models'
import { CustomInput } from 'reactstrap'
import { HousekeepingCleaningOrderSelectServiceKind } from '@modules/housekeeping/cleaning-orders/to-order/select-service-kind'
import { HousekeepingCleaningOrderNextBookingRow } from '@modules/housekeeping/cleaning-orders/common/next-booking'
import { HousekeepingCleaningOrdersRowKind } from '@modules/housekeeping/cleaning-orders/common/kind'
import { HousekeepingCleaningOrderToOrderRowActions } from '@modules/housekeeping/cleaning-orders/to-order/actions'
import { HousekeepingCleaningOrderApartment } from '@modules/housekeeping/cleaning-orders/to-order/row-apartment'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { formatTime } from '@helpers/date-helper'

interface Props {
  cleaningOrder: CleaningOrder
  isChecked: boolean
  toggleSelectedCleaningOrder: (cleaningOrder: CleaningOrder, add: boolean) => void
}

export interface HousekeepingCleaningOrderToOrderRowFormInputs {
  users: string[]
  company: CustomReactSelectOption | null
  service_kind: CustomReactSelectOption | null
}

export const HousekeepingCleaningOrderToOrderRow: React.FC<Props> = ({
  toggleSelectedCleaningOrder,
  cleaningOrder,
  isChecked,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    toggleSelectedCleaningOrder(cleaningOrder, event.target.checked)

  return (
    <tr className="housekeeping__orders_table__row">
      <td className="housekeeping__orders_table__column housekeeping__orders_table__column__first">
        <CustomInput
          checked={isChecked}
          onChange={handleChange}
          type="checkbox"
          id={cleaningOrder.id}
          value={cleaningOrder.id}
        />
      </td>
      <HousekeepingCleaningOrderApartment cleaningOrder={cleaningOrder} showCheckedIn={true} showPriority={true} />
      <HousekeepingCleaningOrdersRowKind order={cleaningOrder} />
      <td className="housekeeping__orders_table__column">
        {cleaningOrder.next_booking?.declared_arrival_time
          ? formatTime(cleaningOrder.next_booking.declared_arrival_time)
          : cleaningOrder.early_check_in_time
            ? formatTime(cleaningOrder.early_check_in_time)
            : cleaningOrder.next_booking?.arrival_time ?? '-'}
      </td>
      <HousekeepingCleaningOrderSelectServiceKind cleaningOrder={cleaningOrder} />
      <HousekeepingCleaningOrderNextBookingRow cleaningOrder={cleaningOrder} />
      <HousekeepingCleaningOrderToOrderRowActions cleaningOrder={cleaningOrder} />
    </tr>
  )
}
