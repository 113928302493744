import * as React from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useFormRequest } from '@components/hooks/use-api-request'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAppDispatch } from '@store/index'
import { BaseModalProps } from '@components/modals/types'
import {
  ReceptionBookingCheckStep,
  ReceptionBookingCheckSteps,
} from '@modules/reception/common/reception-booking-check-steps'
import { ReceptionBookingImprovementSubscriptionModalForm } from '@modules/reception/checkin/step-improvements/improvement-subscription/modal/reception-booking-improvement-subscription-modal-form'
import { ReceptionBookingImprovementSubscriptionModalSummary } from '@modules/reception/checkin/step-improvements/improvement-subscription/modal/reception-booking-improvement-subscription-modal-summary'
import { ClientUser } from '@models/clients'
import { useSelector } from 'react-redux'
import { receptionBookingDetailsSelector } from '@store/selectors/reception'
import { ImprovementAvailability } from '@models/booking'
import { setReceptionBookingDetails } from '@store/slices/reception-slice'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useClientDetails } from '@modules/crm/clients/list-view/use-client-details'
import { ContentLoader } from '@components/content-loader'

const steps: ReceptionBookingCheckStep[] = [
  { step: 1, description: 'Osoby zamawiające' },
  { step: 2, description: 'Potwierdzenie' },
]

interface ImprovementSubscription {
  client: ClientUser | null
  amount: number
}

export interface ReceptionBookingImprovementSubscriptionFormInputs {
  vouchers: ImprovementSubscription[]
}

interface Props extends BaseModalProps {
  subscriptionImprovementAvailability: ImprovementAvailability
}

export const ReceptionBookingImprovementSubscriptionModal: React.FC<Props> = ({
  subscriptionImprovementAvailability,
  toggleIsVisible,
}) => {
  const booking = useSelector(receptionBookingDetailsSelector)

  const [step, setStep] = React.useState(1)
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()
  const { fetchClientDetails, isFetching } = useClientDetails()

  const methods = useForm<ReceptionBookingImprovementSubscriptionFormInputs>({
    defaultValues: { vouchers: [{ client: null, amount: 1 }] },
  })

  const { isLoading, action: onSubmit } = useFormRequest(async () => {
    const payload = methods.getValues()

    dispatch(
      setReceptionBookingDetails(
        await commonObjectPost(
          booking.urls.reservation_subscription_booking_improvement,
          payload.vouchers.map(voucher => ({ client: voucher.client?.id, amount: voucher.amount })),
        ),
      ),
    )
    addSuccessMessage('Sukces', 'Informacja o zakupie voucherów została wysłana')
    toggleIsVisible()
  }, methods.setError)

  const isFirstStep = step === 1
  const vouchers = useWatch({ control: methods.control, name: 'vouchers' })

  const onNextStep = async () => {
    isFirstStep ? setStep(2) : await onSubmit(methods.getValues())
  }

  const onPrevStep = () => {
    isFirstStep ? toggleIsVisible() : setStep(1)
  }

  const setDefaultClient = async () => {
    const client = await fetchClientDetails(booking.client_id)
    methods.setValue('vouchers', [{ client, amount: 1 }])
  }

  React.useEffect(() => {
    if (vouchers.length === 1 && !vouchers[0].client) {
      setDefaultClient()
    }
  }, [])

  return (
    <FormProvider {...methods}>
      <ModalHeader className="border-bottom-0" toggle={toggleIsVisible}>
        Dodaj Voucher Super Start
      </ModalHeader>
      <ReceptionBookingCheckSteps step={step} steps={steps} />
      <ContentLoader isLoading={isFetching}>
        <ModalBody className="pb-0">
          {step === 1 && (
            <ReceptionBookingImprovementSubscriptionModalForm
              subscriptionImprovementAvailability={subscriptionImprovementAvailability}
            />
          )}
          {step === 2 && (
            <ReceptionBookingImprovementSubscriptionModalSummary
              subscriptionImprovementAvailability={subscriptionImprovementAvailability}
            />
          )}
        </ModalBody>
      </ContentLoader>
      <ModalFooter>
        <Button color="light" type="button" onClick={onPrevStep}>
          {isFirstStep ? 'Zamknij' : 'Wróć'}
        </Button>
        <SaveButton
          type="button"
          className="btn btn-green"
          isSaving={isLoading}
          disabled={vouchers.some(voucher => !voucher.client)}
          onClick={onNextStep}
          label={isFirstStep ? 'Następny krok' : 'Wyślij'}
        />
      </ModalFooter>
    </FormProvider>
  )
}
