import * as React from 'react'
import Table from '@components/table/table'
import { FloorHeatingAvailableZones } from '@modules/floor-heating/models'
import { FloorHeatingDetailsHistoryTableRow } from '@modules/floor-heating/details/history/history-table-row'
import { useFloorHeatingAppData } from '@modules/floor-heating/use-floor-heating-app-data'
import { useFloorHeatingHistoryData } from '@modules/floor-heating/details/history/use-floor-heating-history-data'
import {
  FloorHeatingDetailsHistoryFilterParams,
  FloorHeatingDetailsHistoryFilters,
} from '@modules/floor-heating/details/history/history-filters'
import { startOfToday, subDays } from 'date-fns'

const defaultFilters: FloorHeatingDetailsHistoryFilterParams = {
  search: '',
  ordering: '-created',
  page: 1,
  page_size: 10,
  date_from: subDays(startOfToday(), 14),
  date_to: startOfToday(),
}

interface Props {
  apartmentId: string | undefined
}

export const FloorHeatingDetailsHistoryTable = ({ apartmentId }: Props): JSX.Element => {
  const { appData } = useFloorHeatingAppData()
  const headerValues = getHeaderValues(appData?.available_zones)
  const { measurements, filters, setFilters } = useFloorHeatingHistoryData(defaultFilters, true, apartmentId)

  return (
    <>
      <FloorHeatingDetailsHistoryFilters
        defaultFilters={defaultFilters}
        filters={filters}
        setFilters={setFilters}
        maxDate={startOfToday()}
      />
      <Table className="table-hover w-100 mt-3" headerValues={headerValues} filters={filters} setFilters={setFilters}>
        {measurements.map(measurement => (
          <FloorHeatingDetailsHistoryTableRow key={measurement.date_timestamp} measurement={measurement} />
        ))}
      </Table>
    </>
  )
}

const getHeaderValues = (availableZones: FloorHeatingAvailableZones | undefined) => [
  { title: 'Data odczytu' },
  { title: 'Goście w środku' },
  { title: 'Odczyt poprawny' },
  { title: availableZones?.zone_7_temperature ?? 'Strefa 7' },
  { title: availableZones?.zone_8_temperature ?? 'Strefa 8' },
  { title: availableZones?.zone_9_temperature ?? 'Strefa 9' },
  { title: availableZones?.zone_a_temperature ?? 'Strefa A' },
  { title: 'Błąd' },
]
