import * as React from 'react'
import { ButtonWithIcon } from '@components/button-with-icon'
import { LocalizationKind, PackageDetails } from '@models/package'
import { RootState, useAppSelector } from '@store/index'
import { commonObjectPost } from '@store/actions/generic-actions'
import { ReportTask } from '@models/reports'
import { useReportRequest } from '@modules/reports/hooks/use-report-request'

interface Props {
  packageDetails: PackageDetails
  localizationKind?: LocalizationKind
  btnClassName?: string
}

export const PackageDetailsCodesDownload = ({ packageDetails, localizationKind, btnClassName }: Props): JSX.Element => {
  const { report_task_package_hpi_code, report_task_package_code } = useAppSelector(
    (state: RootState) => state.appState.appData.urls.reports,
  )

  const { isLoading, action: handleClick } = useReportRequest(
    async () => ({
      payload: await commonObjectPost<ReportTask>(
        {
          HPI: report_task_package_hpi_code,
          HPR: report_task_package_code,
        }[packageDetails.package_type],
        {
          package: packageDetails.id,
          localization_kind: localizationKind,
          force_create_task: true,
        },
      ),
    }),
    'notification',
  )

  return (
    <ButtonWithIcon
      isLoading={isLoading}
      handleClick={handleClick}
      icon="uil-download-alt"
      btnSize="sm"
      btnClass={btnClassName}
      color="secondary"
      text="Pobierz"
    />
  )
}
