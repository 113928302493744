import * as React from 'react'
import { Row } from 'reactstrap'
import { useGroupedSources } from '@components/hooks/use-grouped-sources'
import { FormSelect } from '@hyper/forms/form-select'

export const ClientCreateModalFormMarketing: React.FC = () => {
  const sources = useGroupedSources()

  return (
    <Row>
      <FormSelect
        options={sources}
        name="source_marketing"
        label="Źródło marketingowe"
        formPlainProps={{ colSize: 6 }}
      />
    </Row>
  )
}
