import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import classNames from 'classnames'
import classnames from 'classnames'

export interface UpsellingTab<T> {
  name: string
  icon?: string
  kind: T
  count?: number
}

interface Props<T> {
  tabs: UpsellingTab<T>[]
  activeTab: UpsellingTab<T>
  onTabsChange: (tab: UpsellingTab<T>) => void
  className?: string
}

export const UpsellBookingsTabs = React.forwardRef(
  <T extends string>(
    { tabs, onTabsChange, activeTab, className }: Props<T>,
    ref: React.RefObject<HTMLUListElement>,
  ): React.ReactNode => (
    <ul className={classNames('nav nav-tabs border-bottom-0 upsell__tabs', className)} ref={ref}>
      {tabs.map(tab => (
        <li className="nav-item" key={tab.kind} onClick={() => onTabsChange(tab)}>
          <a
            className={classnames('py-2 px-4 nav-link d-flex align-items-center', {
              active: activeTab.name === tab.name,
            })}
          >
            <IconWithText icon={classNames(tab.icon, { 'mr-2': tab.icon })} text={tab.name} />
            {tab.count !== undefined && (
              <span
                className={classNames(
                  'badge ml-2 py-1 px-1',
                  activeTab.name === tab.name ? 'badge-grey text-secondary' : 'badge-light text-default',
                )}
              >
                {tab.count}
              </span>
            )}
          </a>
        </li>
      ))}
    </ul>
  ),
)
