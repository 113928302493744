import * as React from 'react'
import { PaymentRegister } from '@models/payments'
import { formatPrice } from '@helpers/utils'
import Table from '@components/table/table'
import { IconWithText } from '@components/icon-with-text'
import { toDefaultTimeFormat } from '@helpers/date-helper'
import { PaymentCashBoxAmount } from '@modules/payment-registers/user-cashbox/payment-cashbox-amount'

interface Props {
  paymentRegister: PaymentRegister
}

export const PaymentRegisterUserCashBoxTransactionsTableRow = ({ paymentRegister }: Props): JSX.Element => (
  <Table.Row role="details-row">
    <Table.Cell>
      <IconWithText icon="uil-clock font-16" text={toDefaultTimeFormat(paymentRegister.created)} />
    </Table.Cell>
    <Table.Cell>{paymentRegister.description || paymentRegister.kind_display}</Table.Cell>
    <Table.Cell>{paymentRegister.type_display}</Table.Cell>
    <Table.Cell>
      {paymentRegister.booking_reservation_number && (
        <a href={`/admin/dashboard/quick-search/?query=${paymentRegister.booking_reservation_number}`}>
          {paymentRegister.booking_reservation_number}
        </a>
      )}
    </Table.Cell>
    <Table.Cell>
      <PaymentCashBoxAmount
        paymentRegister={paymentRegister}
        inTicketsAmountTooltipContent={
          <span className="text-semi-strong">{formatPrice(paymentRegister.amount_in_tickets)}</span>
        }
      />
    </Table.Cell>
    <Table.Cell>
      {paymentRegister.status === 'success' ? (
        <IconWithText
          icon="uil-check"
          text={paymentRegister.status_display}
          wrapperClassNames="text-success text-semi-strong"
        />
      ) : (
        <IconWithText
          icon="uil-multiply"
          text={paymentRegister.status_display}
          wrapperClassNames="text-danger text-semi-strong"
        />
      )}
    </Table.Cell>
  </Table.Row>
)
