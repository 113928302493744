import { useReservationDetailsPreview } from '@modules/reservations/details/use-reservation-details-preview'
import { useParams } from 'react-router-dom'

type Params = 'booking_id' | 'tab'

export const ReservationDetailsPreviewView = (): null => {
  const { booking_id, tab } = useParams<Params>()

  useReservationDetailsPreview(booking_id, tab)

  return null
}
