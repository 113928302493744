import * as React from 'react'
import { useParams } from 'react-router-dom'
import { GenericVoucher, GenericVoucherKind } from '@modules/generic-vouchers/models'
import { useGenericVoucherStrategyProvider } from '@modules/generic-vouchers/hooks/use-generic-voucher-strategy-provider'
import { PageTitleWithSize } from '@hyper/page-title-with-size'
import { Card, CardBody } from 'reactstrap'
import { GenericVoucherListFilters } from '@modules/generic-vouchers/list/filters'
import { TableFilters } from '@components/table/table'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useGetGenericVouchersQuery } from '@api/generic-vouchers'
import { TypedQueryResult } from '@api/base'
import { GenericVoucherTable } from '@modules/generic-vouchers/list/table'
import { GenericVoucherDetails } from '@modules/generic-vouchers/list/details'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'

export interface GenericVoucherFiltersParams extends TableFilters {
  status: CustomReactSelectOption[]
  created_before?: Date
  created_after?: Date
  issue_date_before?: Date
  issue_date_after?: Date
}

const defaultFilters: GenericVoucherFiltersParams = {
  search: '',
  status: [],
  ordering: 'number',
  page: 1,
  page_size: 25,
}
const emptyList = []

export const GenericVoucherListView: React.FC = () => {
  const [filters, setFilters] = React.useState<GenericVoucherFiltersParams>(defaultFilters)
  const { kind = 'bocian', id = '' } = useParams<{ kind: GenericVoucherKind; id: string }>()

  const { data: genericVouchers = emptyList, isLoading } = useGetGenericVouchersQuery({
    params: filters,
    kind,
  }) as TypedQueryResult<GenericVoucher[]>

  const genericVoucherStrategy = useGenericVoucherStrategyProvider(kind)

  useMenuActive(NavigationPath.GenericVoucherList.replace(':kind', kind))

  return (
    <>
      <PageTitleWithSize title={genericVoucherStrategy.name} />
      {id && <GenericVoucherDetails id={id} kind={kind} />}
      <Card>
        <CardBody>
          <GenericVoucherListFilters defaultFilters={defaultFilters} filters={filters} setFilters={setFilters} />
          <GenericVoucherTable
            genericVouchers={genericVouchers}
            isLoading={isLoading}
            filters={filters}
            setFilters={setFilters}
          />
        </CardBody>
      </Card>
    </>
  )
}
