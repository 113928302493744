import * as React from 'react'
import { useSellers } from '@components/hooks/use-grouped-sellers'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  userId: number | null
  className?: string
  defaultValue?: React.ReactNode
  children?: React.ReactNode
}

export const TableUserRow = ({ userId, className, defaultValue, children }: Props): JSX.Element => {
  const sellers = useSellers()

  const soldBy = React.useMemo(() => {
    if (!userId) {
      return defaultValue || ''
    }

    const seller = sellers.find(row => row.user_id === userId)

    if (seller) {
      return <IconWithText icon="font-15 uil-user-square" text={`${seller.name} (${seller.department_display})`} />
    }

    return defaultValue || ''
  }, [sellers, userId])

  return (
    <span className={className}>
      {soldBy}
      {children}
    </span>
  )
}
