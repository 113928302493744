import * as React from 'react'
import { BookingRefund } from '@modules/accounting/refund/models'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAppDispatch } from '@store/index'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useApiRequest } from '@components/hooks/use-api-request'
import { updateAccountingBookingRefund } from '@store/slices/accounting-slice'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'

interface Props {
  booking: BookingRefund
}

export const RefundBookingsTableCellActionsReturn: React.FC<Props> = ({ booking }) => {
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading, action: onAccept } = useApiRequest(
    React.useCallback(async () => {
      const bookingRefund = await commonObjectPost<BookingRefund>(booking.urls.sent)
      await dispatch(updateAccountingBookingRefund(bookingRefund))
      addSuccessMessage('Sukces', `Rezerwacja o ${booking.reservation_number} została oznaczona jako zwrócona`)
    }, [booking]),
  )

  return ['transfer_in_package', 'transfer_sent'].includes(booking.deposit_transfer_status) ? null : (
    <CommonObjectConfirmAction isLoading={isLoading} handleAccept={onAccept}>
      <span className="uil uil-check-square text-muted font-16 cursor-pointer" title="Oznacz zwort jako wykonany" />
    </CommonObjectConfirmAction>
  )
}
