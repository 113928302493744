import * as React from 'react'
import { Col, Row } from 'reactstrap'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useToggle } from '@components/hooks/use-toggle'
import { AccountUserDesktopModalDeviceEdit } from '@modules/account/user-desktop-modal/device-edit'
import { useEservicePinPadsList } from '@modules/reception/common/eservice-pin-pad-select/use-eservice-pin-pads-list'
import { useEservicePinPad } from '@modules/reception/common/eservice-pin-pad-select/use-eservice-pin-pad'
import { IconWithText } from '@components/icon-with-text'
import { ReceptionWorkstationChangePinPadWarningModal } from '@modules/payment-registers/reception-workstation-change-pin-pad-warrning-modal'

export const AccountUserDesktopModalEservicePinPadReader = (): JSX.Element => {
  const user = useAuthenticatedUser()
  const [editMode, toggleEditMode] = useToggle()
  const pinPads = useEservicePinPadsList()
  const [value, setPinPad, remove] = useEservicePinPad()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showPinPadWarning, _, setShowPinPadWarning] = useToggle(false)
  const [tempDeviceId, setTempDeviceId] = React.useState<number>()
  const eservicePinPad = React.useMemo(
    () => pinPads.find(row => row.id === (value || user.receptionWorkstation?.eservice_pad_id)),
    [pinPads, value, user.receptionWorkstation],
  )

  const handleAccept = async (deviceId: number) => {
    setTempDeviceId(deviceId)
    if (user.eservicePadId && deviceId !== user.eservicePadId) {
      setShowPinPadWarning(true)
    } else {
      await handleClosePinPadWarning()
    }
  }

  const handleClosePinPadWarning = async () => {
    if (tempDeviceId) {
      await setPinPad(tempDeviceId)
      setShowPinPadWarning(true)
    }
    toggleEditMode()
  }

  const handleClose = async () => await handleClosePinPadWarning()

  return (
    <Row className="mx-0 align-items-center py-2">
      <Col md={5}>
        <strong>Czytnik eService</strong>
      </Col>
      <Col md={6}>
        {showPinPadWarning && <ReceptionWorkstationChangePinPadWarningModal handleClose={handleClose} />}

        {!editMode ? (
          <IconWithText
            text={eservicePinPad?.name || 'brak'}
            icon="uil-edit text-dark ml-1 cursor-pointer"
            onClick={toggleEditMode}
          />
        ) : (
          <AccountUserDesktopModalDeviceEdit
            defaultValue={value || user.receptionWorkstation?.eservice_pad_id}
            devices={pinPads}
            clearValue={remove}
            toggleEdit={toggleEditMode}
            setDevice={handleAccept}
          />
        )}
      </Col>
    </Row>
  )
}
