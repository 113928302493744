import * as React from 'react'
import { ApartmentBookingDayInfo } from '@models/apartment'
import { format, isToday, parseISO } from 'date-fns'
import { pl } from 'date-fns/locale'
import { ACCOMMODATION_TYPES } from '@helpers/consts'

interface Props {
  stats: ApartmentBookingDayInfo
  date: string
  accommodationTypeIds: string[]
}

const TimelineDayTooltipDetails: React.FC<Props> = ({ stats, date, accommodationTypeIds }) => {
  const apartmentName = accommodationTypeIds.some(id => ACCOMMODATION_TYPES.HOUSES.includes(parseInt(id, 10)))
    ? 'domków'
    : 'apartamentów'

  return (
    <div className="calendar-day-tooltip__day">
      <header className="text-center calendar-day-tooltip__header">
        <strong className="d-block text-capitalize">
          {isToday(parseISO(date)) && 'Dziś'} {format(parseISO(date), 'cccc', { locale: pl })}
        </strong>
        <span>{format(parseISO(date), 'd LLLL yyyy', { locale: pl })}</span>
      </header>
      <hr className="calendar-day-tooltip__hr" />
      <div>
        <div className="d-flex justify-content-between">
          <span>Wolnych {apartmentName}:</span>
          <strong>{stats.stats.available_apartments}</strong>
        </div>
        <div className="d-flex justify-content-between">
          <span>Wyjazdo-przyjazdów:</span>
          <strong>{stats.stats.day_in_out}</strong>
        </div>
        <div className="d-flex justify-content-between">
          <span>Przyjazdów:</span>
          <strong>{stats.stats.day_in}</strong>
        </div>
        <div className="d-flex justify-content-between">
          <span>Wyjazdów:</span>
          <strong>{stats.stats.day_out}</strong>
        </div>
        <div className="d-flex justify-content-between">
          <span>Dorosłych:</span>
          <strong>{stats.stats.adults}</strong>
        </div>
        <div className="d-flex justify-content-between">
          <span>Dzieci:</span>
          <strong>{stats.stats.children}</strong>
        </div>
        <div className="d-flex justify-content-between">
          <span>Niemowląt:</span>
          <strong>{stats.stats.babies}</strong>
        </div>
        <div className="d-flex justify-content-between">
          <span>Śniadania:</span>
          <strong>{stats.stats.breakfasts}</strong>
        </div>
        <div className="d-flex justify-content-between">
          <span>Obiadokolacje:</span>
          <strong>{stats.stats.dinners}</strong>
        </div>
      </div>
    </div>
  )
}

export default TimelineDayTooltipDetails
