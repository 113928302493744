import {
  BookingGuestFeedingOption,
  CalculatedFeedingPrice,
  CalculatedFeedingPriceResponse,
  ReceptionAppData,
  ReceptionBooking,
  ReceptionBookingDetails,
  ReceptionBookingsWithAggregations,
  ReceptionCheckType,
} from '@models/reception'

import { ThunkAction } from 'redux-thunk'
import { AsyncThunkParams, RootState } from '@store/index'
import axios, { CancelTokenSource } from 'axios'
import { formatDate } from '@helpers/date-helper'
import {
  BookingImprovementCode,
  BookingPaymentSource,
  BookingPaymentType,
  BookingStatus,
  ReceptionBookingServiceRoomDataMeterKind,
  ResortDetails,
} from '@models/booking'
import { Apartment } from '@models/apartment'
import {
  commonObjectDelete,
  commonObjectGet,
  commonObjectPatch,
  commonObjectPost,
} from '@store/actions/generic-actions'
import {
  AccommodationTypeAvailable,
  AccommodationTypeAvailablePayload,
  AvailableApartment,
} from '@models/reservation-create'
import { Action, createAsyncThunk } from '@reduxjs/toolkit'

import {
  addReceptionBooking,
  setAccommodationTypeAvailable,
  setCalculatedFeedingPrices,
  setReceptionBookingDetails,
  setReceptionBookings,
  setReceptionBookingsAggregation,
  setResortDetails,
  updateSingleReceptionBooking,
} from '@store/slices/reception-slice'
import { ClientUser } from '@models/clients'

interface ReceptionAppDataFetchParams {
  forceFetching: boolean
}

export const getReceptionAppData = createAsyncThunk<
  ReceptionAppData,
  ReceptionAppDataFetchParams | undefined,
  AsyncThunkParams
>('reception/getReceptionAppData', async (params, { getState }) => {
  if (getState().receptionState.appData.status === 'unknown' || params?.forceFetching) {
    return await commonObjectGet<ReceptionAppData>(getState().appState.appData.urls.reception.app_data)
  }
  return getState().receptionState.appData
})

export function getResortDetails(
  resortId: number | string,
  accommodation_type?: number,
  date_from?: string,
  date_to?: string,
): ThunkAction<Promise<void>, RootState, null, Action> {
  return async (dispatch, getState) => {
    const url =
      getState().appState.appData.resorts.find(r => r.id === parseInt(String(resortId), 10))?.urls.details || ''

    dispatch(setResortDetails(await commonObjectGet<ResortDetails>(url, { accommodation_type, date_from, date_to })))
  }
}

export function updateBookingDetails(data: ReceptionBookingDetails): ThunkAction<void, RootState, null, Action> {
  return async dispatch => {
    dispatch(setReceptionBookingDetails(data))
    dispatch(updateSingleReceptionBooking(data))
    return data
  }
}

export const getReceptionBookingUrl = (idOrToken: string | number) => `/dashboard-api/reception/booking/${idOrToken}/`

export function getReceptionBookingDetails(
  url,
  params = {},
): ThunkAction<Promise<ReceptionBookingDetails>, RootState, null, Action> {
  return async dispatch => {
    const bookingDetails = await commonObjectGet<ReceptionBookingDetails>(url, params)
    if (bookingDetails) {
      dispatch(setReceptionBookingDetails(bookingDetails))
    }
    return bookingDetails
  }
}

export interface ReceptionBookingPaymentsAddPayload {
  amount: string
  type: BookingPaymentType
  source: BookingPaymentSource
  code_preauth: string
  with_deposit: boolean
  with_climatic_tax: boolean
  last_card_numbers: string
  with_booking_damage: boolean
  payment_place?: 'checkin' | 'checkout'
}

export function receptionBookingPaymentsAdd(
  url: string,
  payload: Partial<ReceptionBookingPaymentsAddPayload>,
): ThunkAction<Promise<void>, RootState, null, Action> {
  return async dispatch => {
    await dispatch(setReceptionBookingDetails(await commonObjectPost<ReceptionBookingDetails>(url, payload)))
  }
}

interface UpdateServiceRoomData {
  keys: number
  keys_returned: number
  bands: number
  bands_returned: number
  id_card: string
  car_register_numbers: string[]
}

export function updateServiceRoomData(
  url: string,
  payload: Partial<UpdateServiceRoomData>,
): ThunkAction<Promise<void>, RootState, null, Action> {
  return async dispatch => {
    const data = await commonObjectPatch<ReceptionBookingDetails>(url, payload)
    await dispatch(setReceptionBookingDetails(data))
    if (data) {
      await dispatch(updateReceptionBooking(data))
    }
  }
}

export interface BookingApartmentMediaPayload {
  water_start: string
  water_end: string
  power_start: string
  power_end: string
  hot_water_start: string
  hot_water_end: string
  heat_start: string
  heat_end: string
  power_meter_kind: ReceptionBookingServiceRoomDataMeterKind
  water_meter_kind: ReceptionBookingServiceRoomDataMeterKind
  reason: string
}

export function updateBookingApartmentMedia(
  url: string,
  apartmentMedia: BookingApartmentMediaPayload,
): ThunkAction<Promise<void>, RootState, null, Action> {
  return async dispatch => {
    const { data } = await axios.put(url, apartmentMedia)
    await dispatch(setReceptionBookingDetails(data))
  }
}

export function getReceptionBookingsAggregation(): ThunkAction<Promise<void>, RootState, null, Action> {
  return async (dispatch, getState) => {
    const { receptionState } = getState()
    if (!receptionState.receptionDateFilter || !receptionState.receptionResortFilter) return

    const url = getState().appState.appData.urls.reception.bookings_aggregation

    const { data } = await axios.get(url, {
      params: {
        booking_date: formatDate(receptionState.receptionDateFilter),
        resort: receptionState.receptionResortFilter,
      },
    })
    dispatch(setReceptionBookingsAggregation(data))
  }
}

export function updateReceptionApartment(payload: Apartment): ThunkAction<Promise<void>, RootState, null, Action> {
  return async (dispatch, getState) => {
    const bookings = getState().receptionState.bookings
    const booking = bookings.entities[payload.id]

    if (booking) {
      dispatch(updateSingleReceptionBooking({ ...booking, apartment: payload }))
    }
  }
}

export function updateReceptionBookingDetails(
  payload: ReceptionBookingDetails,
): ThunkAction<Promise<void>, RootState, null, Action> {
  return async (dispatch, getState) => {
    const bookingDetails = getState().receptionState.receptionBookingDetails

    if (bookingDetails?.id === payload.id) {
      dispatch(setReceptionBookingDetails(payload))
    }
  }
}

export function updateReceptionBooking(payload: ReceptionBooking): ThunkAction<Promise<void>, RootState, null, Action> {
  return async (dispatch, getState) => {
    const bookings = getState().receptionState.bookings || []
    const receptionResortFilter = getState().receptionState.receptionResortFilter || 0

    const statuses: BookingStatus[] = ['initial', 'confirmed', 'close']

    if (receptionResortFilter === payload.resort_id && statuses.includes(payload.status)) {
      const booking = bookings.entities[payload.id]
      if (booking) {
        dispatch(updateSingleReceptionBooking(payload))
        return
      }

      dispatch(addReceptionBooking(payload))
    }
  }
}

export function getReceptionBookings(
  resort: number,
  booking_date: string,
  check_status: ReceptionCheckType,
  all_checkouts?: boolean,
): ThunkAction<Promise<void>, RootState, null, Action> {
  return async (dispatch, getState) => {
    const url = getState().appState.appData.urls.reception.bookings
    const data = await commonObjectGet<ReceptionBookingsWithAggregations>(url, {
      resort,
      booking_date,
      check_status,
      all_checkouts,
    })

    await dispatch(setReceptionBookings(data.bookings))
    await dispatch(setReceptionBookingsAggregation(data.aggregation))
  }
}

export function updateBookingImprovement(
  url: string,
  payload: ImprovementActionPayload[],
): ThunkAction<Promise<void>, RootState, null, Action> {
  return bookingImprovementAction('put', url, payload)
}

export function createBookingImprovement(
  url: string,
  payload: ImprovementActionPayload[],
): ThunkAction<Promise<void>, RootState, null, Action> {
  return bookingImprovementAction('post', url, payload)
}

function bookingImprovementAction(
  method: string,
  url: string,
  payload: ImprovementActionPayload[],
): ThunkAction<Promise<void>, RootState, null, Action> {
  return async dispatch => {
    const { data } = await axios[method](url, payload)
    await dispatch(setReceptionBookingDetails(data))
  }
}

export function deleteBookingImprovement(
  url: string,
  reason: string,
): ThunkAction<Promise<void>, RootState, null, Action> {
  return async dispatch => {
    await dispatch(setReceptionBookingDetails(await commonObjectDelete<ReceptionBookingDetails>(url, reason)))
  }
}

export function updateFeeding(url: string, payload: any): ThunkAction<Promise<void>, RootState, null, Action> {
  return async dispatch => {
    dispatch(setReceptionBookingDetails(await commonObjectPost<ReceptionBookingDetails>(url, payload)))
  }
}

export interface ImprovementActionPayload {
  code: BookingImprovementCode
  date_from?: string
  date_to?: string
  amount: number
}

export interface ImprovementPriceCalculation {
  code: BookingImprovementCode
  price: string
  original_single_price_brutto: string
  original_single_price_brutto_first_item: string
}

export async function calculateImprovementPrice(
  url: string,
  payload: ImprovementActionPayload[],
): Promise<{ items: ImprovementPriceCalculation[] }> {
  return await commonObjectPost<{ items: ImprovementPriceCalculation[] }>(url, payload)
}

interface UpdateGuestGroupPayload {
  name: string
}

export function removeGuestGroup(url: string): ThunkAction<Promise<void>, RootState, null, Action> {
  return updateGuestGroup('delete', url)
}

export function addGuestGroup(
  url: string,
  payload: UpdateGuestGroupPayload,
): ThunkAction<Promise<void>, RootState, null, Action> {
  return updateGuestGroup('post', url, payload)
}

export function editGuestGroup(
  url: string,
  payload: UpdateGuestGroupPayload,
): ThunkAction<Promise<void>, RootState, null, Action> {
  return updateGuestGroup('put', url, payload)
}

export function updateGuestGroup(
  method: string,
  url: string,
  payload?: UpdateGuestGroupPayload,
): ThunkAction<Promise<void>, RootState, null, Action> {
  return async dispatch => {
    const { data } = await axios[method](url, payload)
    dispatch(setReceptionBookingDetails(data))
  }
}

export function calculateReservationPrice(
  resort: number,
  accommodation_type: number,
  date_from: Date,
  date_to: Date,
  apartment?: number,
  client?: ClientUser,
  code?: string,
  cancelToken?: CancelTokenSource,
): ThunkAction<Promise<void>, RootState, null, Action> {
  return async (dispatch, getState) => {
    const url = getState().appState.appData.urls.reservation.reservation_calculate_price

    const data = await commonObjectPost<ReceptionBookingDetails>(
      url,
      {
        resort,
        accommodation_type,
        date_from: formatDate(date_from),
        date_to: formatDate(date_to),
        ...(apartment ? { selected_apartment: apartment } : {}),
        client: client?.id,
        code,
      },
      cancelToken,
    )

    dispatch(setReceptionBookingDetails(data))
  }
}

export interface FetchAvailableApartmentsParams {
  resort: number
  accommodation_type: number
  date_from: Date
  date_to: Date
  code: string
  client?: number
}

export const getAvailableApartments = createAsyncThunk<
  AvailableApartment[],
  FetchAvailableApartmentsParams,
  AsyncThunkParams
>(
  'reception/getAvailableApartments',
  async ({ resort, accommodation_type, date_from, date_to, code, client }, { getState }) =>
    await commonObjectGet<AvailableApartment[]>(getState().appState.appData.urls.reservation.available_apartments, {
      client,
      resort,
      accommodation_type,
      code,
      date_from: formatDate(date_from),
      date_to: formatDate(date_to),
    }),
)

export function checkAccommodationTypeAvailable(
  { resort, date_from, date_to, client, code }: AccommodationTypeAvailablePayload,
  cancelToken: CancelTokenSource,
): ThunkAction<Promise<void>, RootState, null, Action> {
  return async (dispatch, getState) => {
    const url = getState().appState.appData.urls.reservation.reservation_check_accommodation_type

    const data = await commonObjectPost<AccommodationTypeAvailable[]>(
      url,
      {
        resort,
        date_from: formatDate(date_from),
        date_to: formatDate(date_to),
        client: client?.id,
        code,
      },
      cancelToken,
    )

    dispatch(setAccommodationTypeAvailable(data))
  }
}

export function createUnfinishedReservation(payload): ThunkAction<Promise<void>, RootState, null, Action> {
  return async (dispatch, getState) => {
    const url = getState().appState.appData.urls.reservation.reservation_create_unfinished_booking

    const data = await commonObjectPost<ReceptionBookingDetails>(url, payload)

    dispatch(setReceptionBookingDetails(data))
  }
}

export function updateUnfinishedReservation(payload): ThunkAction<Promise<void>, RootState, null, Action> {
  return async (dispatch, getState) => {
    const url = getState().receptionState.receptionBookingDetails?.urls.update

    if (!url) {
      return
    }

    const data = await commonObjectPatch<ReceptionBookingDetails>(url, payload)

    dispatch(setReceptionBookingDetails(data))
  }
}

export function getReservationCreateDetails(
  token: string,
): ThunkAction<Promise<ReceptionBookingDetails>, RootState, null, Action> {
  return async (dispatch): Promise<ReceptionBookingDetails> => {
    const data = await commonObjectGet<ReceptionBookingDetails>(getReceptionBookingUrl(token))

    dispatch(setReceptionBookingDetails(data))
    return data
  }
}

export function getCalculatedFeedingPrices(
  url: string,
  payload: Partial<BookingGuestFeedingOption>[],
  group: number,
  cancelToken: CancelTokenSource,
): ThunkAction<Promise<void>, RootState, null, Action> {
  return async (dispatch): Promise<void> => {
    try {
      const data = await commonObjectPost<CalculatedFeedingPriceResponse>(
        url,
        {
          options: payload,
          group,
        },
        cancelToken,
      )

      const calculatedFeedingPrice: CalculatedFeedingPrice = {
        total_price_brutto: data.total_price_brutto,
        rows: data.rows.map(row => ({
          date: row.dates[0],
          guest_id: row.guest_id,
          price_brutto: row.price_brutto,
          single_price_brutto: row.single_price_brutto,
        })),
        group_id: group,
      }

      dispatch(setCalculatedFeedingPrices(calculatedFeedingPrice))
    } catch (e) {
      // Dummy
    }
  }
}
