import * as React from 'react'
import { Col, Row } from 'reactstrap'
import { useFormContext, useWatch } from 'react-hook-form'
import {
  createDefaultNewFeedingFormValues,
  getCalculationDetailsWithoutEdgeMeals,
  getFeedingCounts,
  isMealUpcoming,
} from '@modules/reception/checkin/step-feeding/new-feeding/utils'
import {
  NewFeedingCalculationDetails,
  NewFeedingKind,
} from '@modules/reception/checkin/step-feeding/new-feeding/models'
import { useAppSelector } from '@store/index'
import { selectExposedEdgeFeedings } from '@store/slices/new-feeding-slice'
import classnames from 'classnames'

interface Props {
  calculationDetails: NewFeedingCalculationDetails[]
}

export const ReceptionBookingNewFeedingGuestRowHeader = ({ calculationDetails }: Props): JSX.Element | null => {
  const exposedEdgeFeedings = useAppSelector(selectExposedEdgeFeedings)
  const { setValue, control } = useFormContext()

  const addedFeedings = useWatch({ control })
  const { breakfast, dinner } = getFeedingCounts(calculationDetails, exposedEdgeFeedings, addedFeedings)

  const isFastCheckForKindEnabled = (kind: NewFeedingKind) => {
    const calculationForKinds = getCalculationDetailsWithoutEdgeMeals(
      calculationDetails,
      exposedEdgeFeedings,
      kind,
    ).filter(meal => meal.kind === kind)

    const isEveryFieldEditable = calculationForKinds.every(meal => meal.can_edit)
    const isEveryFieldChecked = calculationForKinds.every(meal => meal.checked)
    const isSomeFieldInAcceptableDateRange = calculationForKinds.every(isMealUpcoming)

    return !isEveryFieldChecked && isEveryFieldEditable && isSomeFieldInAcceptableDateRange
  }

  const handleMealKindSelection = (kind: NewFeedingKind) => () => {
    if (!isFastCheckForKindEnabled(kind)) return

    const feedings = getCalculationDetailsWithoutEdgeMeals(calculationDetails, exposedEdgeFeedings, kind).reduce(
      (previous, feeding) =>
        feeding.kind === kind
          ? [...previous, { ...feeding, ...(isMealUpcoming(feeding) && { checked: true }) }]
          : previous,
      [],
    )

    Object.entries(createDefaultNewFeedingFormValues(feedings)).forEach(([key, value]) => setValue(key, value))
  }

  return (
    <Row>
      <Col md={3} />
      <Col md={5} className="pl-3">
        <HeaderElement
          name={`Śniadania ${breakfast.added}/${breakfast.available}`}
          onClick={handleMealKindSelection('breakfast')}
          isFastSelectionEnabled={isFastCheckForKindEnabled('breakfast')}
        />
      </Col>
      <Col md={4} className="pl-0">
        <HeaderElement
          name={`Obiadokolacje ${dinner.added}/${dinner.available}`}
          onClick={handleMealKindSelection('dinner')}
          isFastSelectionEnabled={isFastCheckForKindEnabled('dinner')}
        />
      </Col>
    </Row>
  )
}

const HeaderElement = ({ name, onClick, isFastSelectionEnabled }) => (
  <button
    className={classnames('d-flex btn-pure text-default', { 'pe-none': !isFastSelectionEnabled })}
    onClick={onClick}
  >
    <i className="uil-corner-left-down font-22 mr-1" />
    <div>
      <p className="font-13 text-dark fw-semi-bold mb-0 text-left">{name}</p>
      {isFastSelectionEnabled && <span className="font-12">zaznacz wszystkie</span>}
    </div>
  </button>
)
