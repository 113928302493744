import * as React from 'react'
import { BarriersBaseList } from '@modules/barriers/base-list'
import { NavigationPath } from '@models/routes'
import { RootState, useAppSelector } from '@store/index'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { BarrierTicketsWebSocketHandler } from '@modules/barriers/web-socket-handler'
import { UserPermission } from '@models/dashboard'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'

export const BarriersTicketsView = (): JSX.Element => {
  const url = useAppSelector((state: RootState) => state.appState.appData.urls.barriers.tickets)
  const user = useAuthenticatedUser()
  useMenuActive(NavigationPath.BarriersTickets)

  return (
    <>
      <BarriersBaseList
        name="pojazd uprawiony"
        showOnlyActiveCarsFilter={true}
        fetchUrl={url}
        menuLink={NavigationPath.BarriersTickets}
        pageTitle="Pojazdy uprawnione"
        canAdd={user.hasPerm(UserPermission.BarrierBarrierticketCanAdd)}
        canChange={user.hasPerm(UserPermission.BarrierBarrierticketCanUpdate)}
        canRemove={user.hasPerm(UserPermission.BarrierBarrierticketCanDelete)}
        canPrint={user.hasPerm(UserPermission.BarrierBarrierticketCanView)}
      />
      <BarrierTicketsWebSocketHandler kind="authorized" />
    </>
  )
}
