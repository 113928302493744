import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { BookingImprovementCode } from '@models/booking'
import { useForm } from 'react-hook-form'
import { useAppDispatch } from '@store/index'
import { useFormRequest } from '@components/hooks/use-api-request'
import { Button, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { FormInput } from '@hyper/forms'
import { SaveButton } from '@hyper/button'
import { ReceptionBookingDetails } from '@models/reception'
import { commonObjectPost } from '@store/actions/generic-actions'
import { Form } from '@hyper/forms/form'
import { setReceptionBookingDetails } from '@store/slices/reception-slice'

interface Props extends BaseModalProps {
  amount: number
  date_from: Date
  date_to: Date
  onClose: () => void

  improvementCode: BookingImprovementCode
  booking: ReceptionBookingDetails
}
interface FormInputs {
  code: string
}

export const BookingImprovementAddByCodeModal: React.FC<Props> = ({
  booking,
  date_from,
  date_to,
  onClose,
  improvementCode,
  toggleIsVisible,
}) => {
  const methods = useForm<FormInputs>()

  const dispatch = useAppDispatch()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    dispatch(
      setReceptionBookingDetails(
        await commonObjectPost<ReceptionBookingDetails>(booking.urls.booking_improvement_paid_by_code, {
          code: payload.code,
          date_from,
          date_to,
          improvement_code: improvementCode,
        }),
      ),
    )
    onClose()
    toggleIsVisible()
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Dodanie ulepszenia</ModalHeader>
      <ModalBody>
        <Row className="position-relative">
          <FormInput placeholder="Wprowadź kod" label="Kod pakietowy" name="code" inputClassName="pr-4" />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Anuluj
        </Button>
        <SaveButton type="submit" className="btn btn-green" label="Dodaj" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
