import * as React from 'react'
import { FormInput, FormPlain } from '@hyper/forms'
import { Label } from 'reactstrap'
import DatePickerInput from '@components/date/date-picker-input'
import { startOfToday } from 'date-fns'
import { useGroupedSellers } from '@components/hooks/use-grouped-sellers'
import { useGroupedSources } from '@components/hooks/use-grouped-sources'
import { FormInputMasked } from '@hyper/forms/form-input-masked'
import { touristVoucherCodeMask } from '@modules/promotions/promocode-tourist-voucher/types'
import { FormSelect } from '@hyper/forms/form-select'

export const PromocodeTouristVoucherCreateForm: React.FC = () => {
  const sellers = useGroupedSellers([])

  const sources = useGroupedSources()

  return (
    <>
      <FormSelect
        label="Sprzedawca"
        options={sellers}
        name="seller"
        selectProps={{ isClearable: true }}
        formPlainProps={{ colSize: 6 }}
      />

      <FormPlain colSize={6} name="created_date">
        <Label>Data utworzenia vouchera</Label>
        <DatePickerInput name="created_date" wrapperClassName="flex-grow-1" minDate={startOfToday()} />
      </FormPlain>

      <FormSelect
        label="Źródło"
        options={sources}
        name="source_marketing"
        selectProps={{ isClearable: true }}
        formPlainProps={{ colSize: 6 }}
      />

      <FormInputMasked
        placeholder="____-____-____-____"
        colSize={6}
        mask={touristVoucherCodeMask}
        name="tourist_voucher_code"
        label="Kod Bonu Turystycznego"
      />

      <FormInput label="Kwota Bonu turystycznego" type="number" name="tourist_voucher_amount" colSize={6} />
    </>
  )
}
