import * as React from 'react'
import { FormProvider } from 'react-hook-form'
import { CleaningOrder } from '@modules/housekeeping/models'
import { HousekeepingCleaningOrdersOrderedFilters } from '@modules/housekeeping/cleaning-orders/ordered/filters'
import { HousekeepingCleaningOrdersOrderedTable } from '@modules/housekeeping/cleaning-orders/ordered/table'
import { useHousekeepingCleaningOrderedOrders } from '@modules/housekeeping/cleaning-orders/ordered/use-housekeeping-cleaning-ordered-orders'
import { TableViewType } from '@modules/technical-orders/common/table-view-switch'

interface Props {
  cleaningOrders: CleaningOrder[]
  resortId: number
}

export const HousekeepingCleaningNormalCleaningOrdersTable = ({ cleaningOrders, resortId }: Props): JSX.Element => {
  const [tableView, setTableView] = React.useState<TableViewType>('list')

  const { methods, filteredOrders, onFilterChange, filters, defaultFilters } = useHousekeepingCleaningOrderedOrders(
    resortId,
    cleaningOrders,
  )

  return (
    <FormProvider {...methods}>
      <h4 className="fw-semi-bold">Pozostałe zadania</h4>
      <HousekeepingCleaningOrdersOrderedFilters
        filters={filters}
        setFilters={onFilterChange}
        defaultFilters={defaultFilters}
        ordersLength={cleaningOrders.length}
        tableView={tableView}
        onTableViewChange={setTableView}
      />
      <HousekeepingCleaningOrdersOrderedTable
        resortId={resortId}
        filteredCleaningOrders={filteredOrders}
        tableView={tableView}
      />
    </FormProvider>
  )
}
