import * as React from 'react'
import { Unit } from '@models/units'
import Table from '@components/table/table'
import { UnitsTableRowType } from './row-type'
import { getApartmentHousekeepingStatusColor } from '@modules/reception/helpers'
import classNames from 'classnames'
import { TechnicalOrdersTodoRowNextBooking } from '@modules/technical-orders/technical-orders-todo/row-next-booking'
import { NavigationPath } from '@models/routes'
import { useNavigate } from 'react-router-dom'
import { UncontrolledTooltip } from 'reactstrap'

interface Props {
  unit: Unit
}

export const UnitsTableRow: React.FC<Props> = ({ unit }) => {
  const navigate = useNavigate()
  const handleClick = React.useCallback(() => {
    const url = NavigationPath.UnitsWithParamsAndId.replace(':id', String(unit.id)).replace(
      ':resort_id',
      String(unit.resort_id),
    )
    navigate(url)
  }, [unit.id, unit.resort_id])

  const tooltipId = `unit-technical-orders-tooltip-${unit.id}`

  return (
    <Table.Row className="cursor-pointer" onClick={handleClick}>
      <UnitsTableRowType unit={unit} />
      <Table.Cell className="vertical-align-middle">{!!unit.apartment && unit.name}</Table.Cell>
      <Table.Cell className="vertical-align-middle">
        {!!unit.apartment && (
          <span
            className={classNames('badge', getApartmentHousekeepingStatusColor(unit.apartment.housekeeping_status))}
          >
            <i className="mdi mdi-checkbox-blank-circle text-white" /> {unit.apartment.housekeeping_status_display}
          </span>
        )}
      </Table.Cell>
      <Table.Cell className="vertical-align-middle">
        <UncontrolledTooltip placement="top" target={tooltipId}>
          <div className="font-11 text-left">
            Otwarte zadania: <strong>{unit.technical_order_open_count}</strong> <br />
            Wszystkie zgłoszone usterki: <strong>{unit.technical_order_count}</strong>
          </div>
        </UncontrolledTooltip>

        <div id={tooltipId} className="d-inline">
          {unit.technical_order_open_count} / {unit.technical_order_count}
        </div>
      </Table.Cell>
      <TechnicalOrdersTodoRowNextBooking nextBooking={unit.next_booking} />
      <Table.Cell className="vertical-align-middle" />
    </Table.Row>
  )
}
