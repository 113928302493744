import * as React from 'react'
import { SubscriptionDetails, SubscriptionOptionKind } from '@modules/subscription/models'
import { SubscriptionSingleModalBase } from '@modules/subscription/options/subscription-single-modal-base'
import { BaseModalProps } from '@components/modals/types'
import { useSubscriptionOptionPriceList } from '@modules/subscription/hooks/useSubscriptionOptionPriceList'
import { accommodationTypesMapSelector } from '@store/selectors/dashboard'
import { useAppSelector } from '@store/index'

interface Props extends BaseModalProps {
  subscriptionDetails: SubscriptionDetails
  optionKind: SubscriptionOptionKind
}

export const SubscriptionCodeKindConvertModal: React.FC<Props> = ({
  subscriptionDetails,
  toggleIsVisible,
  optionKind,
}) => {
  const accommodationTypesMap = useAppSelector(accommodationTypesMapSelector)

  const options = useSubscriptionOptionPriceList({
    subscriptionKind: subscriptionDetails.kind,
    accommodationType: accommodationTypesMap[subscriptionDetails.accommodation_type_id]?.type || 'house',
  })

  const option = options.find(option => option.option_kind === optionKind)

  const title = {
    single_code_kind_convert_to_gold: 'Złoty',
    single_code_kind_convert_to_silver: 'Srebrny',
    single_code_kind_convert_to_platinum: 'Platynowy',
  }[optionKind]

  return (
    <SubscriptionSingleModalBase
      isDisabled={!option}
      title={`Konwersja kodu na ${title || ''}`}
      message="Konwersja kodu została dodana"
      amounts={[option?.price_brutto || '0']}
      description="Opcja konwersji kodu"
      toggleIsVisible={toggleIsVisible}
      subscriptionDetails={subscriptionDetails}
      additionalData={{ kind: optionKind }}
      url={subscriptionDetails.urls.subscription_single_code_convert}
    />
  )
}
