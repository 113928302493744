import * as React from 'react'
import { Button, CustomInput, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { PromocodeGroup, PromocodePrefix } from '@models/promocode'
import { useForm, useWatch } from 'react-hook-form'
import { FormInput, FormPlain } from '@hyper/forms'
import { FormInputNumber } from '@hyper/forms/form-input-number'
import DatePickerInput from '@components/date/date-picker-input'
import { extractInnerRef } from '@helpers/forms'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useFormRequest } from '@components/hooks/use-api-request'
import { promocodePrefixesSelector, updatePromocodeGroupDetails } from '@store/slices/promocodes-slice'
import { Form } from '@hyper/forms/form'
import { BaseModalProps } from '@components/modals/types'
import { endOfToday, format } from 'date-fns'
import { commonObjectPost } from '@store/actions/generic-actions'

interface FormInputs {
  group_name: string
  amount: number
  prefix: string | null
  description: string
  relative_date: boolean
  valid_forever: boolean
  relative_date_years: number | undefined
  expire_after: Date | undefined
  only_one_code: boolean
}

export const PromocodeCreateDialog: React.FC<BaseModalProps> = ({ toggleIsVisible }) => {
  const methods = useForm<FormInputs>({
    defaultValues: {
      prefix: undefined,
      group_name: '',
      amount: 10,
      description: '',
      relative_date_years: 1,
      relative_date: false,
      valid_forever: false,
      expire_after: endOfToday(),
      only_one_code: false,
    },
  })
  const url = useAppSelector((state: RootState) => state.appState.appData.urls.rent.promocode_create)

  const dispatch = useAppDispatch()

  const [relativeDate, validForever] = useWatch({ control: methods.control, name: ['relative_date', 'valid_forever'] })

  const { addSuccessMessage } = useNotificationHook()

  const prefixes = useAppSelector(promocodePrefixesSelector)

  const { isLoading, action: onSubmit } = useFormRequest(async (data: FormInputs) => {
    dispatch(
      updatePromocodeGroupDetails(
        await commonObjectPost<PromocodeGroup>(url, {
          ...data,
          relative_date_years: relativeDate ? data.relative_date_years : null,
          expire_after: data.valid_forever ? null : data.expire_after ? format(data.expire_after, 'yyyy-MM-dd') : null,
        }),
      ),
    )
    addSuccessMessage()

    toggleIsVisible()
  }, methods.setError)

  const prefix = useWatch({ control: methods.control, name: 'prefix' })
  const selectedPrefix = prefixes.find(row => row.id === parseInt(prefix || '0', 10))
  const onlyOneCode = useWatch({ control: methods.control, name: 'only_one_code' })

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Generuj grupę kodów</ModalHeader>
      <ModalBody>
        <Row>
          <FormInput colSize={6} label="Prefiks" type="select" name="prefix">
            <option value={undefined}>Wybierz prefiks kodów</option>

            {prefixes.map((prefix: PromocodePrefix) => (
              <option key={prefix.id} value={prefix.id}>
                {prefix.name}
              </option>
            ))}
          </FormInput>

          <FormInput colSize={6} placeholder="Wpisz nazwę" label="Nazwa grupy" name="group_name" />

          {relativeDate && (
            <FormInput
              colSize={6}
              label="Czas ważności"
              type="select"
              name="relative_date_years"
              disabled={validForever}
            >
              <option value={1}>Rok</option>
              <option value={2}>2 lata</option>
              <option value={3}>3 lata</option>
              <option value={4}>4 lata</option>
              <option value={5}>5 lat</option>
            </FormInput>
          )}

          {!relativeDate && (
            <FormPlain colSize={6} name="expire_after" label="Ważny do">
              <DatePickerInput
                inputGroupClassName={validForever ? 'disabled' : ''}
                control={methods.control}
                name="expire_after"
                wrapperClassName="flex-grow-1"
              />
            </FormPlain>
          )}

          <FormPlain colSize={6} name="valid_forever">
            <label className="d-block mt-1">&nbsp;</label>
            <CustomInput
              className="mt-2"
              type="checkbox"
              {...extractInnerRef(methods.register('valid_forever'))}
              id="valid_forever"
              label="Bezterminowo"
            />
          </FormPlain>
          <FormPlain colSize={6} name="relative_date">
            <CustomInput
              type="checkbox"
              {...extractInnerRef(methods.register('relative_date'))}
              id="relative_date"
              label="Czas ważności relatywny"
              name="relative_date"
            />
          </FormPlain>
        </Row>

        <div className="hr hr-grey mb-2" style={{ marginLeft: -15, marginRight: -15 }} />

        <Row>
          {selectedPrefix?.is_disposable === false && (
            <FormPlain colSize={12} name="only_one_code">
              <CustomInput
                type="checkbox"
                {...extractInnerRef(methods.register('only_one_code'))}
                id="only_one_code"
                label="Wygeneruj tylko jeden kod na podstawie prefiksu"
                name="only_one_code"
              />
            </FormPlain>
          )}
          {!onlyOneCode && (
            <>
              <FormPlain colSize={6} name="amount">
                <label className="d-block mt-3">Liczba kodów do wygenerowania</label>
              </FormPlain>

              <FormInputNumber colSize={6} name="amount" />
            </>
          )}

          <FormInput type="textarea" label="Opis" name="description" />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" label="Generuj" labelSaving="Generowanie" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
