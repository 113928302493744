import * as React from 'react'
import { PaymentDayUserReport } from '@models/payments'
import { ButtonWithIcon } from '@components/button-with-icon'
import { useModal } from '@components/modals/use-modal'

interface Props {
  report: PaymentDayUserReport
}

export const PaymentRegisterReceptionRowCashActions = ({ report }: Props): JSX.Element => {
  const [handleOpen] = useModal('PaymentDayUserReportOperationModal')

  const handlePayIn = () => handleOpen(null, { report, kind: 'receptionist_payin' })
  const handlePayOut = () => handleOpen(null, { report, kind: 'receptionist_payout' })
  const [handleCloseDay] = useModal('UserCashboxCloseDayModal', {
    allowClose: true,
    title: `${report.user_display}:`,
    paymentDayUserReport: report,
  })

  return (
    <>
      <ButtonWithIcon icon="uil-plus" text="zasil" color="success" btnClass="btn-xs mr-1" handleClick={handlePayIn} />
      <ButtonWithIcon
        icon="uil-minus"
        text="wypłać"
        color="warning"
        btnClass="btn-xs mr-1"
        handleClick={handlePayOut}
      />
      <ButtonWithIcon
        icon="uil-money-insert"
        text="zamknij zmianę"
        color="danger"
        btnClass="btn-xs"
        handleClick={handleCloseDay}
      />
    </>
  )
}
