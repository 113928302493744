import * as React from 'react'
import { Collapse } from 'reactstrap'
import { useExpandableItems } from '@components/hooks/use-expandable-items'
import { UpsellBookingsDateFilter } from '@modules/upselling/upsell-bookings/filters/upsell-bookings-date-filter'
import { IconWithText } from '@components/icon-with-text'
import { useFormContext, useWatch } from 'react-hook-form'
import { UpsellBookingsStatusChangeFormInputs } from '@modules/upselling/upsell-bookings/status-change/upsell-bookings-status-change-modal'
import classNames from 'classnames'

export const UpsellBookingsStatusChangeToReconnectBox = (): React.ReactNode => {
  const { setValue, control } = useFormContext<UpsellBookingsStatusChangeFormInputs>()
  const activeStatus = useWatch({ control, name: 'status' })
  const { isExpanded, setExpanded } = useExpandableItems<'reconnect-box'>()

  const handleExpand = () => {
    setValue('status', 'contact_required_again')
    setExpanded('reconnect-box', true)
  }

  React.useEffect(() => {
    setExpanded('reconnect-box', activeStatus === 'contact_required_again')
  }, [activeStatus])

  return (
    <div
      className={classNames('btn btn-outline-light mb-1 bg-white w-100', {
        'opacity-5': activeStatus !== 'contact_required_again',
      })}
      onClick={handleExpand}
    >
      <strong className="text-danger-light">Do ponownego kontaktu</strong>
      <Collapse isOpen={isExpanded('reconnect-box')}>
        <div className="my-1 d-flex justify-content-center">
          <div>
            <IconWithText icon="uil-clock-five font-16" text="Ponowny kontakt w dniu:" />
            <UpsellBookingsDateFilter name="contact_required_date" />
          </div>
        </div>
      </Collapse>
    </div>
  )
}
