import * as React from 'react'
import { NotificationEvents } from '@models/dashboard'
import { useAppDispatch } from '@store/index'
import { useWebSocket } from '@components/hooks/use-web-socket'
import { PaymentDayReportDetails, PaymentRegister } from '@models/payments'
import { addPaymentRegister, updatePaymentDayReport, updatePaymentDayReportDetails } from '@store/slices/cash-slice'
import { formatDate } from '@helpers/date-helper'
import { usePaymentAppData } from '@modules/payment-registers/hooks/use-payment-app-data'

interface Props {
  resortId: number
  date: string
}

export const CashboxDayDetailsWebSocketHandler: React.FC<Props> = ({ resortId, date }) => {
  const dispatch = useAppDispatch()
  const { payment_balance_types } = usePaymentAppData()

  useWebSocket<PaymentRegister | PaymentDayReportDetails>(
    (message, payload) => {
      switch (message) {
        case NotificationEvents.PAYMENT_REPORT_DAY_CHANGE:
          if ((payload as PaymentDayReportDetails).resort_id === resortId) {
            dispatch(updatePaymentDayReport(payload as PaymentDayReportDetails))
            dispatch(updatePaymentDayReportDetails(payload as PaymentDayReportDetails))
          }

          break
        case NotificationEvents.PAYMENT_REGISTER_CHANGE:
          if (
            (payload as PaymentRegister).destination_resort_id === resortId &&
            formatDate((payload as PaymentRegister).created) === date &&
            !payment_balance_types.includes((payload as PaymentRegister).kind)
          ) {
            dispatch(addPaymentRegister(payload as PaymentRegister))
          }

          break
      }
    },
    [],
    [NotificationEvents.PAYMENT_REPORT_DAY_CHANGE, NotificationEvents.PAYMENT_REGISTER_CHANGE],
  )
  return null
}
