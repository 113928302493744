import * as React from 'react'
import { useBandReadersList } from '@modules/reception/common/band-reader-select/use-band-readers-list'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'

interface Props {
  className?: string
}

export const BandReaderSelect: React.FC<Props> = ({ className } = { className: 'font-11' }) => {
  const bandReaders = useBandReadersList()
  const user = useAuthenticatedUser()

  const currentReader = React.useMemo(
    () => bandReaders.find(bandReader => bandReader.id === user.bandReaderId),
    [bandReaders, user.bandReaderId],
  )

  return (
    <div className={className}>
      Wybrany czytnik: <strong>{currentReader?.name || 'brak'}</strong>
    </div>
  )
}
