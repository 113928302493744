import * as React from 'react'
import { BookingExtraCharge, ReceptionBookingDetails } from '@models/reception'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { formatPrice } from '@helpers/utils'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAppDispatch } from '@store/index'
import { updateReceptionBookingDetails } from '@store/actions/reception-actions'
import { commonObjectDelete } from '@store/actions/generic-actions'

interface Props {
  extraCharge: BookingExtraCharge
}

export const ReservationExtraChargesRow = ({ extraCharge }: Props): JSX.Element => {
  const { addSuccessNotification } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading, action: handleAccept } = useApiRequest(async () => {
    dispatch(updateReceptionBookingDetails(await commonObjectDelete<ReceptionBookingDetails>(extraCharge.urls.details)))
    addSuccessNotification('Dopłata została skasowana')
  })

  return (
    <tr>
      <td>{extraCharge.name}</td>
      <td className="text-nowrap" width={150}>
        {toDefaultDateTimeFormat(extraCharge.created_at)}
      </td>
      <td className="text-nowrap" width={10}>
        {formatPrice(extraCharge.price_brutto)}
      </td>
      <td>
        <CommonObjectConfirmAction handleAccept={handleAccept} isLoading={isLoading}>
          <i className="uil-trash-alt cursor-pointer" title="Skasuj" />
        </CommonObjectConfirmAction>
      </td>
    </tr>
  )
}
