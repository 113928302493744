import * as React from 'react'
import { NotificationEvents } from '@models/dashboard'
import { GastroCardDetails, GastroCardKind } from '@models/promotions'
import { useAppDispatch } from '@store/index'
import { useWebSocket } from '@components/hooks/use-web-socket'
import { updateGastroCardDetails } from '@store/slices/gastro-card-slice'

interface Props {
  kind: GastroCardKind
}

export const GastroCardWebSocketHandler: React.FC<Props> = ({ kind }) => {
  const dispatch = useAppDispatch()

  useWebSocket<GastroCardDetails>(
    (message, payload) => {
      switch (message) {
        case NotificationEvents.GASTRO_CARD_CHANGE:
          if (payload.kind === kind) {
            dispatch(updateGastroCardDetails(payload))
          }

          break
      }
    },
    [],
    [NotificationEvents.GASTRO_CARD_CHANGE],
  )
  return null
}
