import { CashbackVoucherStatus } from '@modules/products/cashback-vouchers/models'

export const getCashbackVoucherStatusColor = (status: CashbackVoucherStatus) => {
  switch (status) {
    case 'generate':
      return 'dark-lighten'
    case 'active':
      return 'info-lighten'
    case 'active_assign':
      return 'success-lighten'
    case 'cancelled':
      return 'warning-lighten'
    case 'outdated':
      return 'danger-lighten'
    case 'used':
      return 'danger-lighten'
  }
}
