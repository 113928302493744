import * as React from 'react'
import Table, { TableHeaderValue } from '@components/table/table'
import { User } from '@models/account'
import { HousekeepingUsersListTableFilters } from '@modules/housekeeping/users/list/index'
import { HousekeepingUser } from '@modules/housekeeping/models'
import { HousekeepingUsersListTableRow } from '@modules/housekeeping/users/list/table-row'

const headerValues: TableHeaderValue<User>[] = [
  { title: 'Imię i nazwisko', sortField: 'last_name' },
  { title: 'Login', sortField: 'email' },
  { title: 'RCP Id', sortField: 'rcp_badge' },
  { title: 'Rola' },
  { title: 'Ośrodek' },
  { title: 'Firma' },
  { title: 'Telefon' },
  { title: 'Aktywny?' },
  { title: 'Ostatnie logowanie', sortField: 'last_login' },
  { title: '' },
]

interface Props {
  users: HousekeepingUser[]
  isLoading: boolean
  filters: HousekeepingUsersListTableFilters
  setFilters: (filters: HousekeepingUsersListTableFilters) => void
}

export const HousekeepingUsersListTable: React.FC<Props> = ({ isLoading, users, filters, setFilters }) => (
  <Table
    className="table-hover w-100"
    headerValues={headerValues}
    isLoading={isLoading}
    filters={filters}
    setFilters={setFilters}
  >
    {users.map(user => (
      <HousekeepingUsersListTableRow key={user.id} user={user} />
    ))}
  </Table>
)
