import * as React from 'react'
import { useExpandableItems } from '@components/hooks/use-expandable-items'
import { IconWithText } from '@components/icon-with-text'
import { Collapse } from 'reactstrap'
import { ReceptionBookingGuest } from '@models/booking'
import classNames from 'classnames'
import { StepGuestsWalletScopesPreview } from '@modules/reception/checkin/step-guests/step-guests-guest-form/wallet/scopes-preview'

interface Props {
  guests: ReceptionBookingGuest[]
  resortId: number
}

export const UpsellBookingDetailsReservationExpandableGuests = ({ guests, resortId }: Props): JSX.Element => {
  const { ExpandArrow, isExpanded, toggleExpanded } = useExpandableItems()

  return (
    <div className="border-top border-bottom pb-2 w-100 px-3 col-12 mt-3">
      <Collapse isOpen={isExpanded('guests')}>
        <div className="py-2">
          <IconWithText
            icon="uil-users-alt font-24"
            text="Lista Gości lokalu"
            wrapperClassNames="text-secondary fw-semi-bold"
            textClass="font-18"
          />
        </div>
        <table className="table w-100 table-striped table-borderless mb-0">
          <tbody>
            {guests.map((guest, index) => (
              <tr key={guest.id} className={classNames({ 'border-bottom': index === guests.length - 1 })}>
                <td className="align-middle fw-semi-bold" width="50%">
                  {guest.name}
                </td>
                <td className="pt-1 align-middle">
                  <StepGuestsWalletScopesPreview
                    resortId={resortId}
                    wrapperClassName="d-flex align-items-center justify-content-between"
                    walletLimits={guest.wallet_limits}
                    guestId={guest.id}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Collapse>
      <button
        className="d-flex align-items-center justify-content-center pt-2 w-100 unsty bg-transparent shadow-none border-0 text-default"
        onClick={toggleExpanded('guests')}
      >
        <IconWithText
          icon="uil-users-alt font-18"
          text="Pokaż listę Gości lokalu"
          textClass="font-14 fw-semi-bold"
          wrapperClassNames="mr-1"
        />
        <ExpandArrow isExpanded={isExpanded('guests')} />
      </button>
    </div>
  )
}
