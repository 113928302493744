import {
  BeddingOrder,
  CleaningOrder,
  CommonTask,
  HousekeepingCompanyDetails,
  HousekeepingCleaningCompanyWorker,
  ImprovementOrder,
} from '@modules/housekeeping/models'
import { caseInsensitiveFilter } from '@helpers/utils'

import * as R from 'ramda'
import { CustomReactSelectOption } from '@components/custom-react-select'

export const searchClearingOrders = (
  orders: CleaningOrder[],
  search: string,
  workers: HousekeepingCleaningCompanyWorker[],
): CleaningOrder[] =>
  searchHousekeepingOrder({
    orders,
    search,
    nextCondition: (row, trimSearch) => filterByWorker(row.users, trimSearch, workers),
    searchBy: ['apartment.name', 'accepted_by', 'ordered_by'],
  })

export const searchBeddingOrders = (
  orders: BeddingOrder[],
  search: string,
  workers: HousekeepingCleaningCompanyWorker[],
  searchBy: string[],
): BeddingOrder[] =>
  searchHousekeepingOrder({
    orders,
    search,
    nextCondition: (row, trimSearch) => filterByWorker(row.users, trimSearch, workers),
    searchBy,
  })

export const searchImprovementOrders = (
  orders: ImprovementOrder[],
  search: string,
  workers: HousekeepingCleaningCompanyWorker[],
): ImprovementOrder[] =>
  searchHousekeepingOrder({
    orders,
    search,
    nextCondition: (row, trimSearch) => filterByWorker(row.users, trimSearch, workers),
    searchBy: ['apartment.name', 'accepted_by', 'ordered_by'],
  })

export const searchCommonTaskOrders = (orders: CommonTask[], search: string): CommonTask[] =>
  searchHousekeepingOrder({
    orders,
    search,
    searchBy: ['name'],
  })

export const searchOrderedOrders = (
  orders: CleaningOrder[],
  search: string,
  workers: HousekeepingCleaningCompanyWorker[],
  companies: HousekeepingCompanyDetails[],
): CleaningOrder[] =>
  searchHousekeepingOrder({
    orders,
    search,
    nextCondition: (row, trimSearch) =>
      filterByWorker(row.users, trimSearch, workers) || filterByCompany(row.company_id, trimSearch, companies),
    searchBy: ['apartment.name', 'company_id'],
  })

export const searchCleaningOrders = (
  orders: CleaningOrder[],
  search: string,
  workers: HousekeepingCleaningCompanyWorker[],
  companies: HousekeepingCompanyDetails[],
): CleaningOrder[] =>
  searchHousekeepingOrder({
    orders,
    search,
    nextCondition: (row, trimSearch) =>
      filterByWorker(row.users, trimSearch, workers) || filterByCompany(row.company_id, trimSearch, companies),
    searchBy: ['apartment.name'],
  })

export const filterSelectedWorkers = (filters: { user: CustomReactSelectOption | null }) => (row: CleaningOrder) =>
  !!filters.user && !row.users.includes(parseInt(filters.user.value, 10))

export const searchImprovementsOrders = (
  orders: ImprovementOrder[],
  search: string,
  workers: HousekeepingCleaningCompanyWorker[],
): ImprovementOrder[] =>
  searchHousekeepingOrder({
    orders,
    search,
    nextCondition: (row, trimSearch) => filterByWorker(row.users, trimSearch, workers),
    searchBy: ['apartment.name', 'status_display'],
  })

interface SearchOrderParams<T> {
  orders: T[]
  search: string
  searchBy: string[]
  nextCondition?: (row: T, search: string) => boolean
}

const searchHousekeepingOrder = <T extends {}>({
  orders,
  search,
  searchBy,
  nextCondition,
}: SearchOrderParams<T>): T[] => {
  const trimmedSearch = (search || '').trim()
  if (!trimmedSearch) return orders

  const splitPaths = searchBy.map(el => el.split('.'))

  return orders.filter(row =>
    splitPaths.some(
      path =>
        caseInsensitiveFilter(R.paths([path], row)[0]?.toString())(trimmedSearch) ||
        (nextCondition?.(row, trimmedSearch) ?? false),
    ),
  )
}

const filterByWorker = (users: number[], search: string, workers: HousekeepingCleaningCompanyWorker[]) =>
  caseInsensitiveFilter(users.map(userId => workers.find(w => w.id === userId)?.name || '').join(' '))(search)

const filterByCompany = (company_id: number, search: string, companies: HousekeepingCompanyDetails[]) =>
  caseInsensitiveFilter(companies.find(company => company.id === company_id)?.name)(search)
