import * as React from 'react'
import { ReservationDropdown } from '@modules/reservations/details/common/reservation-dropdown'
import { useModal } from '@components/modals/use-modal'
import { ReceptionBookingDetails, WalletTransaction } from '@models/reception'

interface Props {
  transaction: WalletTransaction
  booking: ReceptionBookingDetails
}

export const ReservationRoomBillRowActions = ({ transaction, booking }: Props): JSX.Element => {
  const [showTransactionsModal] = useModal('ReservationRoomBillTransactionsListModal', {
    transactionId: transaction.id,
    booking,
  })
  const [showTransferModal] = useModal('ReservationRoomBillTransferModal', { transaction, booking })

  return (
    <ReservationDropdown toggleClassName="p-0">
      <ReservationDropdown.Item onClick={showTransactionsModal}>Transakcje</ReservationDropdown.Item>
      <ReservationDropdown.Item>
        <a href={transaction.urls.print} className="text-default" target="_blank">
          Drukuj
        </a>
      </ReservationDropdown.Item>
      <ReservationDropdown.Item onClick={showTransferModal}>Przenieś</ReservationDropdown.Item>
    </ReservationDropdown>
  )
}
