import * as React from 'react'
import { Button, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { FormProvider, useForm } from 'react-hook-form'
import { endOfMonth, startOfMonth, startOfToday } from 'date-fns'
import { createReportTask } from '@store/actions/report-tasks-actions'
import ModalBody from 'reactstrap/lib/ModalBody'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { SaveButton } from '@hyper/button'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { BaseModalProps } from '@components/modals/types'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { createSelectOption } from '@helpers/utils'
import { Resort } from '@models/booking'
import { FormSelect } from '@hyper/forms/form-select'
import { CustomReactSelectOption } from '@components/custom-react-select'

interface FormInputs {
  date_to_after: Date
  date_to_before: Date
  resort: CustomReactSelectOption | null
}

export const ReportsClimaticModal: React.FC<BaseModalProps> = ({ toggleIsVisible }) => {
  const dispatch = useAppDispatch()
  const { resorts } = useAuthenticatedUser()

  const { report_task_climatic_tax_report } = useAppSelector((state: RootState) => state.appState.appData.urls.reports)

  const methods = useForm<FormInputs>({
    defaultValues: {
      date_to_after: startOfMonth(startOfToday()),
      date_to_before: endOfMonth(startOfToday()),
      resort: null,
    },
  })

  const { isLoading, action: onSubmit } = useReportFormRequest(async () => {
    methods.clearErrors()
    const payload: FormInputs = methods.getValues()

    const reportTask = await dispatch(
      createReportTask([
        report_task_climatic_tax_report,
        {
          ...payload,
          resort: payload.resort?.value || null,
        },
      ]),
    )
    toggleIsVisible()

    return reportTask
  }, methods.setError)

  const resortOptions = resorts.map((resort: Resort) => createSelectOption(resort.name, resort.id))

  return (
    <FormProvider {...methods}>
      <ModalHeader toggle={toggleIsVisible}>Raport opłat klimatycznych</ModalHeader>
      <ModalBody>
        <Row>
          <DateFromToInput
            label="Data zakończenia rezerwacji"
            startDateName="date_to_after"
            endDateName="date_to_before"
          />

          <FormSelect name="resort" options={resortOptions} label="Ośrodek" />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton
          className="btn btn-green"
          label="Zleć raport"
          labelSaving="Zlecanie.."
          isSaving={isLoading}
          onClick={onSubmit}
        />
      </ModalFooter>
    </FormProvider>
  )
}
