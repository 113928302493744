import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import { TimelineBooking } from '@store/reducers/timeline-reducers'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { SaveButton } from '@hyper/button'
import { toTextDateFormat } from '@helpers/date-helper'
import { getAccommodationTypeName } from '@helpers/utils'
import { useAppDispatch } from '@store/index'
import { removeBooking } from '@store/actions/timeline-actions'

interface Props extends BaseModalProps {
  booking: TimelineBooking
}

export const TimelineBlockadeRemoveModal: React.FC<Props> = ({ toggleIsVisible, booking }) => {
  const dispatch = useAppDispatch()

  const { action: handleSubmit, isLoading } = useApiRequest(async () => {
    if (!booking.urls.delete_block_apartment) return

    await commonObjectDelete(booking.urls.delete_block_apartment)
    dispatch(removeBooking(booking.id))
    toggleIsVisible()
  })

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>Potwierdź usunięcie blokady</ModalHeader>
      <ModalBody className="col-10">
        <strong className="mb-2 d-block">Czy na pewno chcesz usunąć wybraną blokadę?</strong>
        <div className="d-flex justify-content-between mb-2 align-items-center">
          <span>Termin blokady:</span>
          <span>
            {toTextDateFormat(booking.date_from)} - {toTextDateFormat(booking.date_to)}
          </span>
        </div>
        <div className="d-flex justify-content-between">
          <span>Lokal:</span>
          <span>
            {getAccommodationTypeName(booking.apartment.accommodation_type)} nr. {booking.apartment.name}
          </span>
        </div>
      </ModalBody>
      <ModalFooter className="d-block">
        <Row className="justify-content-end">
          <Button onClick={toggleIsVisible} className="btn btn-light mr-2">
            Anuluj
          </Button>
          <SaveButton isSaving={isLoading} className="btn btn-green" onClick={handleSubmit} label="Potwierdź" />
        </Row>
      </ModalFooter>
    </>
  )
}
