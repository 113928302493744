import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { createReportTask, getUserReportTasks } from '@store/actions/report-tasks-actions'
import { ReportAggregation, ReportGeneratorError, ReportTask } from '@models/reports'
import { RootState } from '@store/index'

interface ReportState {
  reportTasks: EntityState<ReportTask>
  lastReportTask: ReportTask | undefined
  reportAggregation: ReportAggregation | undefined
  reportGenerationError: ReportGeneratorError | undefined
}

const reportTasksAdapter = createEntityAdapter<ReportTask>({
  sortComparer: (a: ReportTask, b: ReportTask) => b.id - a.id,
})

const initialState: ReportState = {
  reportTasks: reportTasksAdapter.getInitialState(),
  lastReportTask: undefined,
  reportAggregation: undefined,
  reportGenerationError: undefined,
}

const reportTaskSlice = createSlice({
  name: 'reportTask',
  initialState,
  reducers: {
    setLastReportTask(state, action: PayloadAction<ReportTask | undefined>) {
      state.lastReportTask = action.payload
    },
    updateUserReportTask(state, action: PayloadAction<ReportTask>) {
      reportTasksAdapter.upsertOne(state.reportTasks, action.payload)
    },
    clearReportTaskGenerationError(state) {
      state.reportGenerationError = undefined
    },
  },
  extraReducers: builder => {
    builder.addCase(getUserReportTasks.fulfilled, (state, action) => {
      const { results, aggregation } = action.payload

      state.reportAggregation = aggregation
      reportTasksAdapter.setAll(state.reportTasks, results)
    })
    builder.addCase(createReportTask.fulfilled, (state, action) => {
      reportTasksAdapter.upsertOne(state.reportTasks, action.payload)
      state.lastReportTask = action.payload
      state.reportGenerationError = undefined
    })
    builder.addCase(createReportTask.rejected, (state, action) => {
      state.reportGenerationError = action.payload as ReportGeneratorError
    })
  },
})

export const { selectAll: selectReportTasks } = reportTasksAdapter.getSelectors(
  (state: RootState) => state.reportTaskState.reportTasks,
)

export const selectLastReportTask = (state: RootState) => state.reportTaskState.lastReportTask
export const selectReportAggregation = (state: RootState) => state.reportTaskState.reportAggregation
export const selectReportGenerationError = (state: RootState) => state.reportTaskState.reportGenerationError

export const { updateUserReportTask, setLastReportTask, clearReportTaskGenerationError } = reportTaskSlice.actions
export default reportTaskSlice.reducer
