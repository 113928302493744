import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { ReservationCard } from '@modules/reservations/details/common/reservation-card'
import { ButtonWithIcon } from '@components/button-with-icon'
import { ReservationVoucher } from '@modules/reservations/details/vouchers/reservation-voucher'
import { useModal } from '@components/modals/use-modal'
import { useAppDispatch, useAppSelector } from '@store/index'
import { bookingWalletDetailsSelector } from '@store/slices/reservations-slice'
import { useApiRequest } from '@components/hooks/use-api-request'
import { getBookingWalletDetails } from '@store/actions/reservation-actions'

interface Props {
  booking: ReceptionBookingDetails
}

export const ReservationVouchers = React.forwardRef(
  ({ booking }: Props, ref: React.RefObject<HTMLDivElement>): React.ReactNode => {
    const walletDetails = useAppSelector(bookingWalletDetailsSelector)
    const [showAddVoucherModal] = useModal('ReservationAddVoucherModal', { booking })

    const dispatch = useAppDispatch()

    const { action: fetchBookingWalletDetails } = useApiRequest(
      async () => await dispatch(getBookingWalletDetails(booking)),
    )

    React.useEffect(() => {
      fetchBookingWalletDetails()
    }, [])

    return (
      <ReservationCard
        titleIcon="uil-pricetag-alt font-20"
        title="Vouchery"
        ref={ref}
        cardActions={
          <div>
            {!walletDetails?.client_wallets.length && (
              <>
                <strong className="text-dark font-12 text-default">
                  Rezerwacja nie posiada żadnego podpiętego vouchera
                </strong>
                <img src={require('@assets/images/from-to-dotted.svg')} alt="" height="15" className="mx-5" />
              </>
            )}
            <ButtonWithIcon
              btnClass="btn-flat"
              icon="uil-plus font-14"
              text="Dodaj voucher"
              color="secondary"
              handleClick={showAddVoucherModal}
            />
          </div>
        }
      >
        {walletDetails?.client_wallets.map(wallet => (
          <ReservationVoucher key={wallet.id} wallet={wallet} booking={booking} />
        ))}
      </ReservationCard>
    )
  },
)
