import { useModal } from '@components/modals/use-modal'
import * as React from 'react'
import { Button } from 'reactstrap'

interface Props {
  className?: string
  label?: string
  color: 'success' | 'green'
}
export const CashRegisterReportModalToggle = ({
  color,
  className,
  label = 'Drukuj raport kasowy',
}: Props): JSX.Element => {
  const [handleOpenModal] = useModal('CashRegisterReportModal')

  return (
    <Button className={className} color={color} onClick={handleOpenModal}>
      {label}
    </Button>
  )
}
