import * as React from 'react'
import { NotificationEvents } from '@models/dashboard'
import { useAppDispatch } from '@store/index'
import { useWebSocket } from '@components/hooks/use-web-socket'
import { RentalProduct } from '@modules/rental/models'
import { handleRentalProductAddEvent, handleRentalProductChangeEvent } from '@store/slices/rental-slice'

export const RentalProductWebSocketHandler: React.FC = () => {
  const dispatch = useAppDispatch()

  useWebSocket<RentalProduct>(
    (message, payload) => {
      switch (message) {
        case NotificationEvents.RENTAL_PRODUCT_CHANGE:
          dispatch(handleRentalProductChangeEvent(payload))
          break
        case NotificationEvents.RENTAL_PRODUCT_ADD:
          dispatch(handleRentalProductAddEvent(payload))
          break
      }
    },
    [],
    [NotificationEvents.RENTAL_PRODUCT_CHANGE, NotificationEvents.RENTAL_PRODUCT_ADD],
  )
  return null
}
