import { useGetCrmAppDataQuery } from '@api/crm'
import { CrmAppData } from '@models/crm'
import { useAppData } from '@components/hooks/use-app-data'

const emptyAppData: CrmAppData = {
  recommendation_gift_kinds: [],
  client_notifications: [],
}

export const useCrmAppData = (): CrmAppData => {
  const { urls } = useAppData()
  const { data: appData = emptyAppData } = useGetCrmAppDataQuery(urls.crm.app_data)
  return appData
}
