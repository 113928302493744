import * as React from 'react'
import { format, isToday, parseISO } from 'date-fns'
import { pl } from 'date-fns/locale'

interface Props {
  date: string
}

const TimelineDayTooltipDetailsPlaceholder: React.FC<Props> = ({ date }) => (
  <div className="calendar-day-tooltip__day">
    <header className="text-center calendar-day-tooltip__header">
      <strong className="d-block text-capitalize">
        {isToday(parseISO(date)) && 'Dziś'} {format(parseISO(date), 'cccc', { locale: pl })}
      </strong>
      <span>{format(parseISO(date), 'd LLLL yyyy', { locale: pl })}</span>
    </header>
    <hr className="calendar-day-tooltip__hr" />
    <div>
      {[...Array(8).keys()].map(item => (
        <div key={item} className="calendar-tooltip__el is-placeholder" data-testid="calendar-tooltip-placeholder" />
      ))}
    </div>
  </div>
)

export default TimelineDayTooltipDetailsPlaceholder
