import * as React from 'react'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { ButtonWithIcon } from '@components/button-with-icon'
import { ProductPackageDetails } from '@modules/endless-holiday/models'
import { useDeleteEndlessHolidayTransactionMutation } from '@api/endless-holiday'

interface Props {
  productDetails: ProductPackageDetails
}

export const EndlessHolidayDetailsModalDeleteButton: React.FC<Props> = ({ productDetails }) => {
  const { addSuccessMessage } = useNotificationHook()

  const [deleteEndlessHolidayTransactionMutation, { isLoading }] = useDeleteEndlessHolidayTransactionMutation()

  const handleRemove = async (reason: string) => {
    await deleteEndlessHolidayTransactionMutation({ url: productDetails.urls.details, data: { reason } }).unwrap()
    addSuccessMessage('Sukces', 'Produkt został skasowany')
  }

  return (
    <div className="mr-auto">
      <CommonObjectConfirmAction isLoading={isLoading} handleAccept={handleRemove} askForReason={true}>
        <ButtonWithIcon icon="uil-trash-alt" color="danger" text="Usuń" />
      </CommonObjectConfirmAction>
    </div>
  )
}
