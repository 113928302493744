import * as React from 'react'
import { EmailTemplateAttachment, EmailTemplateDetails } from '@modules/cms/models'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { useAppDispatch } from '@store/index'
import { updateEmailTemplateDetails } from '@store/actions/cms-actions'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'

interface Props {
  attachment: EmailTemplateAttachment
}

export const NotificationTemplatesEmailModalAttachmentsListRow = ({ attachment }: Props): JSX.Element => {
  const dispatch = useAppDispatch()

  const { addSuccessMessage } = useNotificationHook()

  const { isLoading, action: handleAccept } = useApiRequest(async () => {
    dispatch(updateEmailTemplateDetails(await commonObjectDelete<EmailTemplateDetails>(attachment.urls.details)))
    addSuccessMessage('Sukces', 'Wpis został skasowany')
  })

  return (
    <li key={attachment.id}>
      <a href={attachment.file}>{attachment.filename}</a>

      <CommonObjectConfirmAction isLoading={isLoading} handleAccept={handleAccept}>
        <i className="uil uil-trash-alt ml-2 font-16 text-muted cursor-pointer" />
      </CommonObjectConfirmAction>
    </li>
  )
}
