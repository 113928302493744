import * as React from 'react'
import { formatPriceShort } from '@helpers/utils'
import { SaveButton } from '@hyper/button'
import { PriceLoader } from '@modules/reception/checkin/step-feeding/price-loader'
import classnames from 'classnames'

interface CheckInAddImprovementButtonProps {
  improvementPrice: string | undefined
  isSaving: boolean
  onClick?: (event?: React.MouseEvent) => void
  disabled?: boolean
  label?: string
  btnClass?: string
}

export const ReceptionBookingCheckInAddImprovementButton: React.FC<CheckInAddImprovementButtonProps> = ({
  improvementPrice,
  isSaving,
  onClick,
  disabled,
  label,
  btnClass,
}) => {
  const saveButtonLabel = (
    <>
      <i className="uil-plus mr-1" />
      <div className="d-inline-block">
        <span className="mr-1">{label || 'Dodaj'}</span>
        <PriceLoader
          spinnerClass="inline-spinner"
          spinnerColor="white"
          price={improvementPrice}
          content={<span className="d-inline-block">(+{formatPriceShort(improvementPrice || 0)})</span>}
        />
      </div>
    </>
  )

  return (
    <SaveButton
      disabled={disabled}
      onClick={onClick}
      isSaving={isSaving}
      labelSaving="Dodawanie..."
      label={saveButtonLabel}
      className={classnames(
        'btn btn-secondary text-nowrap reception__booking-check-in__improvement-step__price-button',
        btnClass,
      )}
      role="add-improvement-button"
      type="button"
    />
  )
}
