import * as React from 'react'
import { ColAuto } from '@hyper/col-auto'
import { Label } from 'reactstrap'
import { Link } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { SubscriptionCreateButton } from '@modules/subscription/create'

export const SubscriptionListFilterButtons = (): JSX.Element => (
  <ColAuto className="ml-auto px-0">
    <Label className="d-block">&nbsp;</Label>

    <Link to={NavigationPath.SubscriptionProductList} className="btn btn-light mr-2">
      Lista produktów
    </Link>
    <SubscriptionCreateButton type="DEFAULT" />
  </ColAuto>
)
