import { EnhancedShopProduct, ShopProductPayload } from '@modules/shop/pos/models'

export const getShopPurchaseItemsPayload = (addedProducts: EnhancedShopProduct[]): ShopProductPayload[] =>
  addedProducts
    .map(row => ({
      product: row.id,
      amount: row.amount,
      discount: 0,
      params: row.params,
    }))
    .filter(row => !!row)
