import * as React from 'react'
import { FeedingApartmentOptionsGuest, FeedingSpecialGuestEnter, FeedingWorkerGuest } from '@modules/feeding/models'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { RootState, useAppSelector } from '@store/index'
import { CustomInput } from 'reactstrap'
import { FeedingHosFormInputs } from '@modules/feeding/host/feeding-host-with-params-view'
import { setFeedingEnter } from '@api/feeding'
import { FeedingHostGuestsModalRowButtons } from '@modules/feeding/host/guests-modal/row-buttons'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useHandleAxiosServerErrors } from '@helpers/handle-axios-errors'

interface Props {
  hideResortColumn?: boolean
  showButtons?: boolean
  values: FeedingHosFormInputs
  guest: FeedingSpecialGuestEnter | FeedingWorkerGuest | FeedingApartmentOptionsGuest
  setGuest: (guest: FeedingSpecialGuestEnter | FeedingWorkerGuest | FeedingApartmentOptionsGuest) => void
}

export const FeedingHostGuestsModalRow: React.FC<Props> = ({
  hideResortColumn,
  guest,
  showButtons,
  setGuest,
  values,
}) => {
  const resorts = useAppSelector((state: RootState) => state.appState.appData.resorts)
  const { addSuccessNotification } = useNotificationHook()
  const handleAxiosServerErrors = useHandleAxiosServerErrors()

  const resort = React.useMemo(() => resorts.find(row => row.id === guest.enter?.resort_id), [guest])

  const toggleEnter = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const enters = await setFeedingEnter([
        {
          ...values,
          type: values.type.value,
          enter: event.target.checked,
          guest: guest.booking_guest_id,
        },
      ])

      setGuest({ ...guest, enter: enters[0] })
      addSuccessNotification('Zmiany zostały zapisane')
    } catch (error) {
      setGuest({ ...guest })

      handleAxiosServerErrors(error)
    }
  }

  return (
    <tr key={guest.id}>
      <td className="valign-middle">
        <CustomInput
          id={`feeding_${guest.id}`}
          type="checkbox"
          className="font-18"
          name={`feeding_${guest.id}`}
          checked={guest.enter?.enter || false}
          onChange={toggleEnter}
        />
      </td>
      <td className="valign-middle">
        <label htmlFor={`feeding_${guest.id}`} className="cursor-pointer font-16 text-nowrap d-block mb-0">
          {guest.name}
        </label>
      </td>
      <td className="valign-middle">{guest.type_display}</td>
      {guest.enter?.enter ? (
        <>
          <td className="valign-middle text-nowrap">
            {guest.enter?.last_enter_at && toDefaultDateTimeFormat(guest.enter?.last_enter_at)}
          </td>
          {!hideResortColumn && <td className="valign-middle text-nowrap">{resort?.name}</td>}
        </>
      ) : (
        <td colSpan={hideResortColumn ? 1 : 2} />
      )}
      {showButtons && <FeedingHostGuestsModalRowButtons guest={guest} />}
    </tr>
  )
}
