import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import ReceptionBookingCheckBillDetailsPayments from '@modules/reception/common/reception-booking-check-bill-details/step-bill-details-payments'
import { formatPriceShort } from '@helpers/utils'
import Decimal from 'decimal.js'

interface Props {
  booking: ReceptionBookingDetails
  depositToReturn: Decimal
}

export const ReceptionBookingSettlementDeposit: React.FC<Props> = ({ depositToReturn }) => (
  <div className="mt-3">
    <strong className="font-14 text-strong d-block mb-2">Szczegóły kaucji:</strong>
    <table className="reception__booking-check-out__bill-step__table">
      <tbody>
        <ReceptionBookingCheckBillDetailsPayments
          readOnly={false}
          label="Wpłacona kaucja"
          isDeposit={true}
          defaultIsOpen={false}
        />
        <tr className="reception__booking-check-out__bill-step__table__row bg-platinum text-strong">
          <td className="reception__booking-check-out__bill-step__table__column text-grey">Pozostało kwota kaucji</td>
          <td className="reception__booking-check-out__bill-step__table__column text-right">
            {formatPriceShort(depositToReturn.toString())}
          </td>
          <td className="reception__booking-check-out__bill-step__table__column" />
          <td className="reception__booking-check-out__bill-step__table__column" width={25} />
        </tr>
      </tbody>
    </table>
  </div>
)
