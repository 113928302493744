import * as React from 'react'
import { Button } from 'reactstrap/lib'
import { UserPermission } from '@models/dashboard'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useModal } from '@components/modals/use-modal'
import { FeedingHostStats } from '@modules/feeding/models'

interface Props {
  stats: FeedingHostStats | undefined
  onRefresh: () => void
}

export const FeedingHostStatsBox = ({ stats, onRefresh }: Props) => {
  const user = useAuthenticatedUser()

  const [handleIsWorkerOpen] = useModal('FeedingWorkersDialog', { onlyCard: true })

  const [feedingClientsToggleIsOpen] = useModal('FeedingClientsCreateDialog')

  return (
    <>
      <h5 className="text-secondary">
        Statystyki wejść (
        <span className="cursor-pointer text-primary" onClick={onRefresh}>
          odśwież
        </span>
        )
      </h5>
      <div className="border bg-white-smoke p-3">
        <table className="table table-bordered table-striped">
          <tbody>
            <tr>
              <td className="text-semi-strong">Weszło gości:</td>
              <td className="text-right text-strong">
                {stats?.enters_count || 0} ({stats?.percent}%)
              </td>
            </tr>
            <tr>
              <td className="text-semi-strong">Pozostało gości:</td>
              <td className="text-right text-strong">
                {stats?.left} ({100 - (stats?.percent || 0)}%)
              </td>
            </tr>
            <tr>
              <td className="text-semi-strong">Przysługuje:</td>
              <td className="text-right text-strong">{stats?.total || 0}</td>
            </tr>
            <tr>
              <td className="text-semi-strong">Weszło goście specjalni:</td>
              <td className="text-right text-strong">{stats?.special_guest_enters || 0}</td>
            </tr>
            <tr>
              <td className="text-semi-strong">Weszło pracownicy:</td>
              <td className="text-right text-strong">{stats?.worker_guest_enters || 0}</td>
            </tr>
          </tbody>
        </table>

        <div className="mt-2 text-right">
          {user.hasPerm(UserPermission.FeedingChangeFeedingClient) && user.is_superuser && (
            <Button color="green" onClick={feedingClientsToggleIsOpen} className="mr-1">
              Sprzedaż zewnętrzna
            </Button>
          )}
          {user.hasPerm(UserPermission.FeedingChangeFeedingWorkerGuest) && (
            <Button color="green" onClick={handleIsWorkerOpen}>
              Sprzedaż pracownikowi
            </Button>
          )}
        </div>
      </div>
    </>
  )
}
