import { SubscriptionKind, SubscriptionOptionPriceList } from '@modules/subscription/models'
import { AccommodationTypeKind } from '@models/booking'
import { useSubscriptionAppData } from '@modules/subscription/hooks/use-subscription-app-data'

export const useSubscriptionOptionPriceList = ({
  accommodationType,
  subscriptionKind,
}: {
  accommodationType: AccommodationTypeKind
  subscriptionKind: SubscriptionKind
}): SubscriptionOptionPriceList[] => {
  const { subscription_option_price_list } = useSubscriptionAppData()

  return subscription_option_price_list.filter(
    row => row.accommodation_type_kind === accommodationType && row.kind === subscriptionKind,
  )
}
