import * as React from 'react'
import { Button } from 'reactstrap'
import { ButtonWithIcon } from '@components/button-with-icon'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { useModal } from '@components/modals/use-modal'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectPost } from '@store/actions/generic-actions'
import { UpSellBookingDetails } from '@models/upselling'

interface Props {
  upsellBooking: UpSellBookingDetails
  onSaleClick: () => void
}

export const UpsellBookingDetailsActions = ({ upsellBooking, onSaleClick }: Props): JSX.Element => {
  const [showAddNoteModal] = useModal('UpsellBookingAddNoteModal', { upsellBooking })
  const [showStatusChangeModal] = useModal('UpsellBookingsStatusChangeModal', { upsellBooking })

  const { action: sendSurchargeInfo, isLoading: isSendingSurchargeInfo } = useApiRequest(async () => {
    await commonObjectPost(upsellBooking.urls.send_surcharge_info)
  })

  return (
    <div className="col-12 d-flex justify-content-between align-items-center mt-3">
      <div className="d-flex">
        <ButtonWithIcon icon="uil-plus font-16 lh-1" text="Sprzedaj" color="btn btn-green" handleClick={onSaleClick} />

        <ButtonWithIcon
          color="info"
          icon="uil-analysis"
          text="Zmień status zlecenia"
          btnClass="mx-btn-tall mx-2"
          handleClick={showStatusChangeModal}
        />
        <ButtonWithIcon
          color="light"
          icon="uil-notes"
          text="Dodaj notatkę"
          btnClass="mx-btn-tall btn-outline border"
          handleClick={showAddNoteModal}
        />
      </div>

      <div className="d-flex">
        <CommonObjectConfirmAction
          handleAccept={sendSurchargeInfo}
          isLoading={isSendingSurchargeInfo}
          title="Rachunek do sprzedaży"
          message="Czy na pewno chcesz wysłać do Gościa wiadomość e-mail zawierającą link z rachunkiem sprzedaży?"
        >
          <Button color="secondary btn-tall ml-2">
            <i className="uil-bill font-16 lh-0" />
          </Button>
        </CommonObjectConfirmAction>
      </div>
    </div>
  )
}
