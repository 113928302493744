import * as React from 'react'
import { useDocumentTitle } from '@helpers/utils'
import { PageTitle } from '@hyper/page-title'
import { Card, CardBody } from 'reactstrap'
import { useAppDispatch, useAppSelector } from '@store/index'
import { Button } from 'reactstrap/lib'
import { FeedingWorkerTable } from '@modules/feeding/workers/table'
import { getFeedingWorkers } from '@store/actions/feeding-actions'
import { FeedingWorkerFilters } from '@modules/feeding/workers/filters'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { useModal } from '@components/modals/use-modal'
import { TableFilters } from '@components/table/table'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useDebounce } from 'rooks'
import { feedingWorkersSelector } from '@store/slices/feeding-slice'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { CustomReactSelectOption } from '@components/custom-react-select'

const pageTitle = 'Wyżywienie pracownicy'

export interface FeedingWorkerFiltersParams extends TableFilters {
  date_from?: Date
  date_to?: Date
  resorts: CustomReactSelectOption | null
}

export const FeedingWorkerView: React.FC = () => {
  const defaultFilters: FeedingWorkerFiltersParams = {
    ordering: '-created_at',
    page: 1,
    resorts: null,
    page_size: 10,
    search: '',
    date_to: undefined,
    date_from: undefined,
  }
  const [filters, setFilters] = React.useState<FeedingWorkerFiltersParams>(defaultFilters)

  useDocumentTitle(pageTitle)
  const user = useAuthenticatedUser()

  const dispatch = useAppDispatch()
  useMenuActive(NavigationPath.FeedingWorker)

  const { isLoading, action: fetchFeedingWorkers } = useApiRequest(async (filters: FeedingWorkerFiltersParams) => {
    const { resorts, ...restFilters } = filters
    await dispatch(getFeedingWorkers({ ...restFilters, ...(resorts?.value && { resorts: resorts.value }) }))
  })

  const [handleIsOpen] = useModal('FeedingWorkersDialog')

  const fetchFeedingWorkersDebounced = React.useCallback(useDebounce(fetchFeedingWorkers, 300), [])
  React.useEffect(() => {
    fetchFeedingWorkersDebounced(filters)
  }, [filters])

  const workers = useAppSelector(feedingWorkersSelector)

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitle} />

      <Card>
        <CardBody>
          {user.hasPerm(UserPermission.FeedingChangeFeedingWorkerGuest) && (
            <div className="text-right">
              <Button color="green" onClick={handleIsOpen}>
                Sprzedaj wyżywienie
              </Button>
            </div>
          )}
          <FeedingWorkerFilters defaultFilters={defaultFilters} setFilters={setFilters} filters={filters} />

          <FeedingWorkerTable isLoading={isLoading} filters={filters} setFilters={setFilters} workers={workers} />
        </CardBody>
      </Card>
    </>
  )
}
