import * as React from 'react'
import { ReceptionBooking, ReceptionBookingDetails, ReceptionCheckoutValidationSubscription } from '@models/reception'
import { Col, UncontrolledTooltip } from 'reactstrap'
import { IconWithText } from '@components/icon-with-text'
import classNames from 'classnames'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectDelete, commonObjectGet, commonObjectPost } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { SaveButton } from '@hyper/button'

const SubscriptionImprovementHouseCreationNotification = 'subscription_booking_improvement_house_create'

interface Props {
  booking: ReceptionBooking
  validationSubscription: ReceptionCheckoutValidationSubscription
  onRefresh: () => void
}

export const ReceptionBookingCheckOutSubscriptionBookingImprovementRow = ({
  booking,
  validationSubscription,
  onRefresh,
}: Props): React.ReactNode => {
  const { addSuccessMessage } = useNotificationHook()

  const totalVouchers =
    validationSubscription.subscription.local_sea +
    validationSubscription.subscription.local_mountains +
    validationSubscription.subscription.local_universal

  const { action: sendNotification, isLoading: isSendingNotification } = useApiRequest(async () => {
    await commonObjectPost(validationSubscription.subscription.urls.notifications, {
      notification: SubscriptionImprovementHouseCreationNotification,
    })

    addSuccessMessage('Gotowe', `Wiadomość została wysłana na adres: ${validationSubscription.subscription.email}`)
  })

  const { action: removeImprovement, isLoading: isRemoving } = useApiRequest(async () => {
    const bookingDetails = await commonObjectGet<ReceptionBookingDetails>(booking.urls.api_details)

    const bookingImprovement = bookingDetails.improvements.find(
      improvement => improvement.id === validationSubscription.booking_improvement,
    )

    if (!bookingImprovement) return
    await commonObjectDelete(bookingImprovement.urls.details, 'Brak akceptacji regulaminów.')
    onRefresh()
  })

  return (
    <li className={classNames('mt-2', { 'text-green': validationSubscription.has_rules_accepted })}>
      <div className="row align-items-center">
        <Col md={4}>
          <UncontrolledTooltip
            placement="bottom"
            target={`subscription-${validationSubscription.subscription.id}`}
            className="tooltip-no-arrow"
          >
            <GuestData validationSubscription={validationSubscription} />
          </UncontrolledTooltip>
          <GuestData validationSubscription={validationSubscription} />
        </Col>
        <Col md={2} className="text-nowrap">
          {totalVouchers}x voucher
        </Col>
        <Col md={6} className="d-flex ml-auto justify-content-end">
          {validationSubscription.has_rules_accepted ? (
            <IconWithText icon="uil-check font-16" text="Regulaminy zaakceptowane" />
          ) : (
            <>
              <SaveButton
                isSaving={isRemoving}
                label="Usuń"
                labelSaving="Usuwanie"
                className="btn btn-danger font-11 btn-flat px-1 d-flex align-items-center"
                onClick={removeImprovement}
                spinnerProps={{ className: 'avatar-3xs' }}
              />
              <SaveButton
                isSaving={isSendingNotification}
                onClick={sendNotification}
                label="Wyślij regulaminy"
                labelSaving="Wysyłanie..."
                className="btn btn-light px-1 ml-2 btn-flat font-11 d-flex align-items-cente"
                spinnerProps={{ className: 'avatar-3xs' }}
              />
            </>
          )}
        </Col>
      </div>
    </li>
  )
}

const GuestData = ({ validationSubscription }) => (
  <div id={`subscription-${validationSubscription.subscription.id}`} className="text-left cursor-default">
    <div className="text-truncate">{validationSubscription.subscription.name}</div>
    <small className="d-block text-truncate">{validationSubscription.subscription.email}</small>
  </div>
)
