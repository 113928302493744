// @types/react-date-range are not valid

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import { DateRange } from 'react-date-range'

import * as React from 'react'
import classNames from 'classnames'
import { pl } from 'date-fns/locale'
import { dateWithShortTextMonth, formatDate } from '@helpers/date-helper'
import { isSameYear } from 'date-fns'
import { DateRangeNavigatorRenderer, DateRangeNavigatorYears } from '@components/date/data-range-navigator-renderer'
import classnames from 'classnames'

interface Props {
  startingDate: Date | null
  endingDate: Date | null
  minDate?: Date | null
  maxDate?: Date | null
  onChange: (range) => void
  placeholder: string
  placeholderClassName?: string
  placeholderComponent?: React.ReactElement | null
  className?: string
  inputClassName?: string
  wrapperClassName?: string
  isInvalid?: boolean
  isClearable?: boolean
  dateFormat?: string
  availableDates?: string[]
  onRangeFocusChange?: (rangeFocus) => void
  navigatorYearsRenderer?: DateRangeNavigatorYears
}

interface Range {
  startingDate: Date | null
  endingDate: Date | null
  minDate?: Date | null
}

function getState({ startingDate, endingDate, minDate }: Range) {
  return [
    {
      startDate: startingDate || minDate || new Date(),
      endDate: endingDate || minDate || new Date(),
      key: 'selection',
    },
  ]
}

const DateRangeComponent: React.FC<Props> = ({
  startingDate,
  endingDate,
  minDate,
  maxDate,
  onChange,
  className,
  inputClassName,
  wrapperClassName,
  placeholder,
  placeholderClassName,
  placeholderComponent,
  isInvalid,
  isClearable,
  dateFormat = 'dd LLLL yyyy',
  availableDates,
  onRangeFocusChange,
  navigatorYearsRenderer,
}) => {
  const rangeRef = React.useRef<HTMLDivElement>(null)
  const [isDateRange, setDateRange] = React.useState(false)
  const [range, setRange] = React.useState(getState({ startingDate, endingDate, minDate }))

  const onPageClick = (event: MouseEvent) => {
    if (isDateRange && !rangeRef.current?.contains(event.target as Node)) {
      setDateRange(false)
    }
  }

  React.useEffect(() => {
    setRange(getState({ startingDate, endingDate, minDate }))
  }, [startingDate, endingDate, minDate])

  React.useEffect(() => {
    if (isDateRange) {
      document.body.addEventListener('click', onPageClick)
    }

    return () => document.body.removeEventListener('click', onPageClick)
  }, [isDateRange])

  const toggleShowDateRange = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation()
    setDateRange(!isDateRange)
  }

  const handleClearDate = (): void => {
    onChange({
      selection: {
        startDate: null,
        endDate: null,
        key: 'selection',
      },
    })
  }

  const isSameYears = (): boolean => !!startingDate && !!endingDate && isSameYear(startingDate, endingDate)

  const isDayDisabled = (date: Date): boolean => !!availableDates && !availableDates.includes(formatDate(date))

  const placeholderElement = () => {
    if (placeholderComponent) return placeholderComponent
    if (startingDate && endingDate) {
      return (
        <span>
          {formatDate(startingDate, isSameYears() && !dateFormat ? 'dd LLLL' : dateFormat)} -{' '}
          {formatDate(endingDate, dateFormat)}
        </span>
      )
    }

    return <span className={classnames('date-range__placeholder', placeholderClassName)}>{placeholder}</span>
  }

  return (
    <div className={`date-range ${wrapperClassName || ''}`} role="date-range">
      <span
        className={classNames('form-control date-range__date', inputClassName, { 'is-invalid': isInvalid })}
        onClick={toggleShowDateRange}
      >
        {placeholderElement()}
      </span>
      {isClearable && (!!startingDate || !!endingDate) && (
        <span className="date-range__clear" onClick={handleClearDate} />
      )}
      {isDateRange && (
        <div className="date-range__wrapper" ref={rangeRef} data-testid="date-range-selection">
          <DateRange
            navigatorRenderer={DateRangeNavigatorRenderer(navigatorYearsRenderer)}
            dateDisplayFormat={dateWithShortTextMonth}
            locale={pl}
            ranges={range}
            onChange={onChange}
            className={className || ''}
            minDate={minDate || undefined}
            maxDate={maxDate || undefined}
            disabledDay={isDayDisabled}
            onRangeFocusChange={onRangeFocusChange}
          />
          <div className="text-right bg-white px-2 pb-2">
            <button className="btn-sm btn btn-secondary" type="button" onClick={toggleShowDateRange}>
              zamknij
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default DateRangeComponent
