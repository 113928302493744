import * as React from 'react'
import { PriceBlock } from '@modules/reservations/list/reservation-list-payment-summary'
import { formatPrice } from '@helpers/utils'
import Table from '@components/table/table'
import { useAppSelector } from '@store/index'
import { selectGastroCardAggregation } from '@store/slices/gastro-card-slice'

export const GastroCardsTableFooter: React.FC = () => {
  const aggregation = useAppSelector(selectGastroCardAggregation)

  return (
    <Table.Row>
      <Table.Cell colSpan={4} className="text-right align-middle reservation-list__summary-cell">
        <strong className="mr-2">Razem w wybranym okresie:</strong>
      </Table.Cell>
      <Table.Cell className="reservation-list__summary-cell">
        <PriceBlock title="Kwota zakupu:" price={formatPrice(aggregation?.sell_price_sum ?? 0)} />
      </Table.Cell>
      <Table.Cell className="reservation-list__summary-cell">
        <PriceBlock title="Pozostało do wykorzystania" price={formatPrice(aggregation?.rest_to_use_sum ?? 0)} />
      </Table.Cell>
      <Table.Cell className="reservation-list__summary-cell">
        <PriceBlock title="Deklarowana kwota zakupu" price={formatPrice(aggregation?.declared_sell_price_sum ?? 0)} />
      </Table.Cell>
      <Table.Cell colSpan={5} className="reservation-list__summary-cell" />
    </Table.Row>
  )
}
