import * as React from 'react'
import { Col, FormGroup, Input, Label } from 'reactstrap'
import { formatPriceShort } from '@helpers/utils'
import { BookingExtendedStayOption } from '@models/reception'
import { extractInnerRef } from '@helpers/forms'
import { PriceLoader } from '@modules/reception/checkin/step-feeding/price-loader'
import { useFormContext } from 'react-hook-form'

interface ReceptionBookingExtendedStayOptionProps {
  option: BookingExtendedStayOption
  isPriceCalculating?: boolean
  children?: React.ReactNode
}

export const ReceptionBookingExtendedStayOption: React.FC<ReceptionBookingExtendedStayOptionProps> = ({
  option,
  isPriceCalculating,
  children,
}) => {
  const { register } = useFormContext()

  return (
    <FormGroup tag="fieldset" row={true}>
      <Col md={10}>
        <FormGroup check={true} disabled={!option.available} className="d-flex align-items-center">
          <Label check={true} className="cursor-pointer">
            <Input
              type="radio"
              value={option.kind}
              {...extractInnerRef(register('kind'))}
              disabled={!option.available}
            />
            <span className="text-semi-strong d-block" style={{ paddingTop: 5 }}>
              {option.name}
            </span>
          </Label>
          {children}
        </FormGroup>
      </Col>
      <Col md={2} className="pt-1 text-semi-strong text-right">
        <PriceLoader
          spinnerClass="inline-spinner"
          isLoading={isPriceCalculating}
          price={option.price_brutto}
          content={<span> +{formatPriceShort(option.price_brutto)}</span>}
        />
      </Col>
    </FormGroup>
  )
}
