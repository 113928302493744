import * as React from 'react'
import { ReservationDataRow } from '@modules/reservations/details/common/reservation-data-row'
import { IconWithText } from '@components/icon-with-text'
import { BadgeWithIcon } from '@components/badges/BadgeWithIcon'
import { toDefaultDateFormat, toDefaultDateTimeFormat } from '@helpers/date-helper'
import { useModal } from '@components/modals/use-modal'
import { ReceptionBookingDetails } from '@models/reception'
import classNames from 'classnames'
import { isBookingEditable } from '@modules/reservations/details/utils'

interface Props {
  booking: ReceptionBookingDetails
}

export const ReservationDataAutoCancel = ({ booking }: Props): JSX.Element => {
  const [showAutoCancelChangeModal] = useModal('ReservationDisableAutoCancelModal', { booking })

  const isEditEnabled = isBookingEditable(booking)

  return (
    <>
      <ReservationDataRow
        name={<IconWithText icon="uil-clock" text="Anulowanie rezerwacji wstępnej:" />}
        className="border-bottom pb-2 mr-3"
      >
        <span className="font-11">po {toDefaultDateTimeFormat(booking.auto_cancel_after)}</span>
      </ReservationDataRow>
      <ReservationDataRow
        name={<IconWithText icon="uil-times-square" text="Zablokuj auto-anulowanie:" />}
        className="border-bottom pb-2 mr-3"
      >
        {booking.disable_auto_cancel ? (
          <>
            <BadgeWithIcon
              icon="uil-check-circle"
              title="tak"
              className={classNames('badge badge-success-lighten cursor-pointer', { 'pe-none': !isEditEnabled })}
              onClick={showAutoCancelChangeModal}
            />
            {booking.disable_auto_cancel_date && (
              <span className="font-11 pl-2">do {toDefaultDateFormat(booking.disable_auto_cancel_date)}</span>
            )}
          </>
        ) : (
          <BadgeWithIcon
            icon="uil-times-circle"
            title="nie"
            className={classNames('badge badge-danger-lighten cursor-pointer', { 'pe-none': !isEditEnabled })}
            onClick={showAutoCancelChangeModal}
          />
        )}
      </ReservationDataRow>
    </>
  )
}
