import * as React from 'react'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useForm } from 'react-hook-form'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { FaultOrderDetails, FaultOrderExecutor, FaultOrderStatus } from '@modules/housekeeping/applications/models'
import { HousekeepingApplicationFaultsCreateModalForm } from '@modules/housekeeping/applications/fault/create-modal/form'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { commonObjectPost, commonObjectPut } from '@store/actions/generic-actions'
import { useApartments } from '@components/hooks/use-apartments'
import { useFormRequest } from '@components/hooks/use-api-request'
import { BaseModalProps } from '@components/modals/types'
import { updateHousekeepingFaultOrder } from '@store/slices/housekeeping-slice'
import * as R from 'ramda'
import { Form } from '@hyper/forms/form'

interface HousekeepingApplicationFaultsCreateModalProps extends BaseModalProps {
  resortId: number
  faultOrder?: FaultOrderDetails
}

export interface HousekeepingApplicationFaultsCreateModalFormInputs {
  apartment: any
  name: string
  status: FaultOrderStatus
  created_by: any
  executor: FaultOrderExecutor
  description: string
  description_after: string
  images: number[]
}

export const HousekeepingApplicationFaultsCreateModal: React.FC<HousekeepingApplicationFaultsCreateModalProps> = ({
  resortId,
  toggleIsVisible,
  faultOrder,
}) => {
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()
  const url = useAppSelector((state: RootState) => state.appState.appData.urls.housekeeping.fault_orders)

  useApartments(resortId)

  const methods = useForm<HousekeepingApplicationFaultsCreateModalFormInputs>({
    defaultValues: {
      ...(faultOrder && {
        ...R.pick(
          ['apartment', 'name', 'status', 'created_by', 'executor', 'description', 'description_after', 'images'],
          faultOrder,
        ),
      }),
      images: faultOrder?.images.map(image => image.id) || [],
    },
  })
  const { isLoading, action: onSubmit } = useFormRequest(
    async (payload: HousekeepingApplicationFaultsCreateModalFormInputs) => {
      let faultOrderDetails
      if (faultOrder) {
        faultOrderDetails = await commonObjectPut<FaultOrderDetails>(faultOrder.urls.details, {
          ...payload,
          resort: resortId,
          apartment: payload.apartment?.value,
        })
        addSuccessMessage('Sukces', `Usterka o numerze ${faultOrder.number} została zmieniona!`)
      } else {
        faultOrderDetails = await commonObjectPost<FaultOrderDetails>(url, {
          ...payload,
          resort: resortId,
          apartment: payload.apartment?.value,
        })
        addSuccessMessage('Sukces', `Usterka o numerze ${faultOrderDetails.number} została zgłoszona!`)
      }
      dispatch(updateHousekeepingFaultOrder(faultOrderDetails))
      toggleIsVisible()
    },
    methods.setError,
  )

  return (
    <Form onSubmit={methods.handleSubmit(onSubmit)} methods={methods}>
      <ModalHeader toggle={toggleIsVisible}>
        {faultOrder ? (
          <>
            <span className="text-semi-strong">Edycja usterki</span> <strong>{faultOrder.number}</strong>
          </>
        ) : (
          'Nowa usterka'
        )}
      </ModalHeader>
      <ModalBody>
        <HousekeepingApplicationFaultsCreateModalForm faultOrder={faultOrder} resortId={resortId} />
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
