import * as React from 'react'
import { PageTitle } from '@hyper/page-title'
import { useDocumentTitle } from '@helpers/utils'
import { Card, CardBody } from 'reactstrap'
import { StatsSaleItemList } from '@modules/reports/stats-sale-item/list'
import { StatsSaleItemTypes } from '@modules/reports/stats-sale-item/stats-sale-item-types'
import { useParams } from 'react-router-dom'
import { StatsSaleItemType } from '@modules/reports/stats-sale-item/models'
import { StatsSaleItemDashboard } from '@modules/reports/stats-sale-item/dashboard'
import { useReportsAppData } from '@modules/reports/hooks/use-reports-app-data'

const pageTitle = 'Raport sprzedaży'

interface Params {
  type: StatsSaleItemType
}

export const StatsSaleItemView: React.FC = () => {
  const { type } = useParams() as unknown as Params

  useReportsAppData()

  useDocumentTitle(pageTitle)

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitle} />
      <Card>
        <CardBody>
          <StatsSaleItemTypes type={type} />
          {type === StatsSaleItemType.List && <StatsSaleItemList />}
          {type === StatsSaleItemType.Booking && <StatsSaleItemList category={StatsSaleItemType.Booking} />}
        </CardBody>
      </Card>
      {type === StatsSaleItemType.Dashboard && <StatsSaleItemDashboard />}
    </>
  )
}
