import * as React from 'react'
import { commonObjectGet } from '@store/actions/generic-actions'
import { useModal } from '@components/modals/use-modal'
import { useAppSelector } from '@store/index'
import { receptionBookingDetailsSelector } from '@store/selectors/reception'

export type CollisionCode = 'extend_stay_12' | 'early_check_in_13'

interface CollisionCheckResponse {
  reservation_number: string | null
}

interface CheckExtendingCollisionResponse {
  collisionBooking: string | null
}

interface Response {
  showCollisionModal: (code: CollisionCode, collisionBooking: string, onConfirm: () => void) => void
  checkExtendingCollision: (code: CollisionCode) => Promise<CheckExtendingCollisionResponse>
}

export const useExtendedStayCollision = (): Response => {
  const [showModal] = useModal('ReceptionBookingExtendedStayCollisionWarningModal')
  const bookingDetails = useAppSelector(receptionBookingDetailsSelector)

  const checkExtendingCollision = async (code: CollisionCode) => {
    const { reservation_number } = await commonObjectGet<CollisionCheckResponse>(
      bookingDetails.urls.reservation_collision,
      { code },
    )

    return { collisionBooking: reservation_number }
  }

  const showCollisionModal = (code: CollisionCode, collisionBooking: string, onConfirm) => {
    const isEarlyCheckIn = code === 'early_check_in_13'

    showModal(null, {
      onConfirm,
      title: isEarlyCheckIn ? 'Wcześniejsze zameldowanie' : 'Późniejsze wymeldowanie',
      content: isEarlyCheckIn ? (
        <EarlyCheckInMessage number={collisionBooking} />
      ) : (
        <ExtendedStayMessage number={collisionBooking} />
      ),
    })
  }

  return {
    checkExtendingCollision,
    showCollisionModal,
  }
}

const EarlyCheckInMessage = ({ number }) => (
  <span className="d-block fw-semi-bold">
    Uwaga! W tym dniu pierwsza rezerwacja ({number}) <strong>kończy się o 12:00</strong>. <br />
    Jesteś pewien, że chcesz dodać wcześniejsze zameldowanie?
  </span>
)

const ExtendedStayMessage = ({ number }) => (
  <span>
    Uwaga! Nadchodząca rezerwacja ({number}) <strong>rozpoczyna się o 13:00</strong>. <br />
    Jesteś pewien, że chcesz dodać późniejsze wymeldowanie?
  </span>
)
