import * as React from 'react'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { useDocumentTitle } from '@helpers/utils'
import { PageTitle } from '@hyper/page-title'
import { useParams } from 'react-router-dom'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { useAppDispatch, useAppSelector } from '@store/index'
import { paymentDayReportDetailsSelector } from '@store/slices/cash-slice'
import { formatDate } from '@helpers/date-helper'
import { useApiRequest } from '@components/hooks/use-api-request'
import { getPaymentDayReportDetails } from '@store/actions/cash-actions'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { PaymentRegisterPointList } from '@modules/payment-registers/cashbox-day-details/payment-register-point-list'
import { PaymentRegisterCashboxDayDetailsActions } from '@modules/payment-registers/cashbox-day-details/actions'
import { PaymentRegisterReception } from '@modules/payment-registers/cashbox-day-details/payment-register-reception'
import { PaymentRegisterCashboxDayTransactions } from '@modules/payment-registers/cashbox-day-details/transactions'
import { CashboxDayDetailsWebSocketHandler } from '@modules/payment-registers/cashbox-day-details/cashbox-day-details-web-socket-handler'
import { HistoryBox } from '@modules/promotions/common/history-box'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { resortsMapSelector } from '@store/selectors/dashboard'

interface Params {
  resort_id: string
  day_id: string
}

export const PaymentRegisterCashboxDayDetailsView = (): JSX.Element => {
  const { day_id, resort_id } = useParams() as unknown as Params
  const dispatch = useAppDispatch()
  const resortId = parseInt(resort_id, 10)
  const user = useAuthenticatedUser()

  const resortsMap = useAppSelector(resortsMapSelector)
  const paymentDayReportDetails = useAppSelector(paymentDayReportDetailsSelector)

  useMenuActive(NavigationPath.PaymentRegisterCashbox)
  const pageTitle = `Kasa dzień ${
    paymentDayReportDetails?.date ? formatDate(paymentDayReportDetails.date, 'dd.MM') : ''
  } (${resortsMap[resortId]?.name})`

  const { isLoading, action: fetch } = useApiRequest(async () => await dispatch(getPaymentDayReportDetails(day_id)))

  React.useEffect(() => {
    fetch()
  }, [])

  useDocumentTitle(pageTitle)

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          {
            label: 'Kasa',
            path: NavigationPath.PaymentRegisterCashboxWithParam.replace(':resort_id', resort_id),
            active: false,
          },
          { label: pageTitle, path: '', active: true },
        ]}
        title={pageTitle}
      />
      <Card>
        <CardBody>
          {paymentDayReportDetails && (
            <CashboxDayDetailsWebSocketHandler date={paymentDayReportDetails.date} resortId={resortId} />
          )}

          {paymentDayReportDetails && (
            <>
              <PaymentRegisterCashboxDayDetailsActions paymentDayReportDetails={paymentDayReportDetails} />
              <PaymentRegisterCashboxDayTransactions
                paymentDayReportDetails={paymentDayReportDetails}
                resortId={resortId}
              />
            </>
          )}
          <LoaderPlaceholder content={!isLoading} />
        </CardBody>
      </Card>
      {paymentDayReportDetails && (
        <>
          <PaymentRegisterReception paymentDayReportDetails={paymentDayReportDetails} />
          <PaymentRegisterPointList paymentDayReportDetails={paymentDayReportDetails} />
          <Row>
            <Col md={6}>
              {user.hasPerm(UserPermission.CashPaymentDayReportCanViewHistory) && (
                <HistoryBox history={paymentDayReportDetails.history} title="Historia zmian karty dnia" />
              )}
            </Col>
          </Row>
        </>
      )}
    </>
  )
}
