import * as React from 'react'
import classNames from 'classnames'
import { ReceptionBooking } from '@models/reception'
import { getBookingStatusClass } from '@modules/reservations/list/utils'

interface Props {
  booking: ReceptionBooking
}

export const ReservationListTableRowStatus = React.memo(({ booking: booking }: Props) => (
  <span className={classNames('badge text-white', getBookingStatusClass(booking.status, booking.checked_in))}>
    {booking.checked_in && booking.status !== 'close' ? 'zameldowana' : booking.status_display}
  </span>
))
