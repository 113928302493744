import { UpSellBookingStatus } from '@models/upselling'

export const getUpsellBookingStatusColor = (status: UpSellBookingStatus) => {
  switch (status) {
    case 'not_interested':
      return 'dark-lighten'
    case 'sold':
      return 'success-lighten'
    case 'contact_required_again':
      return 'danger-lighten'
    default:
      return 'info-lighten'
  }
}
