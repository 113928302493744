import * as React from 'react'
import Table from '@components/table/table'
import { useModal } from '@components/modals/use-modal'
import { useUpsellBookingDetailsRefresh } from '@modules/upselling/upsell-booking-details/use-upsellbooking-details-refresh'
import { SubscriptionContract } from '@modules/subscription-contract/models'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'

interface Props {
  subscriptionContracts: SubscriptionContract[]
  statuses: string[]
  onProductsCountChange: (count: number, type: string) => void
}

export const UpsellBookingsDetailsSubscriptionContracts = ({
  subscriptionContracts,
  statuses,
  onProductsCountChange,
}: Props): JSX.Element => {
  const { refresh } = useUpsellBookingDetailsRefresh()
  const [showSubscriptionDetailsModal] = useModal('SubscriptionContractDetailsModal', {}, { onClose: refresh })

  const handleShowSubscriptionDetailsClick = (subscription: SubscriptionContract) => () => {
    showSubscriptionDetailsModal(null, { subscription })
  }

  const products = React.useMemo(
    () =>
      subscriptionContracts.filter((subscriptionContract: SubscriptionContract) =>
        statuses.includes(subscriptionContract.status),
      ),
    [subscriptionContracts, statuses],
  )

  React.useEffect(() => {
    onProductsCountChange(products.length, 'subscription_contract')
  }, [products.length])

  return (
    <>
      {products.map((subscriptionContract: SubscriptionContract) => (
        <Table.Row key={subscriptionContract.id}>
          <Table.Cell className="align-middle">
            <span className="fw-semi-bold">Subskrypcja</span> <br />
            <small>Numer {subscriptionContract.number}</small>
          </Table.Cell>
          <Table.Cell className="align-middle">
            <span className="fw-semi-bold">
              {['confirmed', 'initial'].includes(subscriptionContract.status)
                ? subscriptionContract.subscription_started
                  ? `Aktywna od ${toDefaultDateTimeFormat(subscriptionContract.subscription_started)}`
                  : 'Nie rozpoczęto'
                : 'Nieaktywna'}
            </span>
          </Table.Cell>
          <Table.Cell className="align-middle">{subscriptionContract.plan_display}</Table.Cell>

          <Table.Cell
            className="text-right align-middle"
            onClick={handleShowSubscriptionDetailsClick(subscriptionContract)}
          >
            <i className="font-18 mr-2 uil-eye" />
          </Table.Cell>
        </Table.Row>
      ))}
    </>
  )
}
