import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { BaseTableFilters } from '@components/table/use-handle-table-filters-change'
import { CommonTableFilters, CommonTableFiltersProps } from '@components/table/common-table-filters'
import { Label } from 'reactstrap'
import DateRangeInput from '@components/date/date-range-input'

export interface FloorHeatingDetailsHistoryFilterParams extends BaseTableFilters {
  date_from: Date
  date_to: Date
}

interface Props extends CommonTableFiltersProps<FloorHeatingDetailsHistoryFilterParams> {
  maxDate?: Date
  minDate?: Date
}

export const FloorHeatingDetailsHistoryFilters: React.FC<Props> = ({
  defaultFilters,
  filters,
  setFilters,
  maxDate,
  minDate,
}) => {
  const methods = useForm<FloorHeatingDetailsHistoryFilterParams>({ defaultValues: defaultFilters })

  return (
    <FormProvider {...methods}>
      <CommonTableFilters
        defaultFilters={defaultFilters}
        filters={filters}
        setFilters={setFilters}
        rowClassName="justify-content-end"
        withSearch={false}
      >
        <div className="mr-auto ml-2">
          <Label>Zakres dat</Label>
          <DateRangeInput
            isClearable={false}
            startDateName="date_from"
            endDateName="date_to"
            wrapperClassName="is-range"
            maxDate={maxDate}
            minDate={minDate}
          />
        </div>
      </CommonTableFilters>
    </FormProvider>
  )
}
