import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import { useForm, useWatch } from 'react-hook-form'
import { SaveButton } from '@hyper/button'
import { useFormRequest } from '@components/hooks/use-api-request'
import { FormInput } from '@hyper/forms'
import { ReservationBlockadeViaDates } from '@modules/reservations/blockade/via-dates/reservation-blockade-via-dates'
import { CustomReactSelect, CustomReactSelectOption, makeDefaultSelectOption } from '@components/custom-react-select'
import { Resort } from '@models/booking'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useAppData } from '@components/hooks/use-app-data'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { useModal } from '@components/modals/use-modal'
import { BlockadeDatesKind, KindOptions, SelectedLocalRow } from '@modules/reservations/blockade/models'
import { Form } from '@hyper/forms/form'
import { FormSelect } from '@hyper/forms/form-select'

export interface ReservationBlockadeFormInputs {
  date_from: Date | null
  date_to: Date | null
  blockade_dates_kind: BlockadeDatesKind
  notes: string
  accommodation_type: CustomReactSelectOption | null
  apartments_amount: number
  selected_local_rows: SelectedLocalRow[]
  resort: CustomReactSelectOption
  kind: CustomReactSelectOption | null
}

interface Props extends BaseModalProps {
  resortId: string
}

export const ReservationBlockadeModal: React.FC<Props> = ({ resortId, toggleIsVisible }): JSX.Element => {
  const { resorts } = useAuthenticatedUser()
  const appData = useAppData()

  const methods = useForm<ReservationBlockadeFormInputs>({
    defaultValues: {
      blockade_dates_kind: 'empty',
      date_from: null,
      date_to: null,
      kind: makeDefaultSelectOption(KindOptions, 'booking'),
      notes: '',
      apartments_amount: 0,
      accommodation_type: null,
      selected_local_rows: [],
      resort: { value: resortId, label: resorts.find((el: Resort) => el.id === parseInt(resortId, 10))?.name },
    },
  })

  const watchedFields = useWatch({ control: methods.control })

  const [showSuccessModal] = useModal('ReservationBlockadeSuccessModal', null, { persist: true })

  const { action: handleSubmit, isLoading } = useFormRequest(async () => {
    methods.clearErrors()

    const {
      accommodation_type,
      apartments_amount,
      date_from,
      date_to,
      blockade_dates_kind,
      notes,
      selected_local_rows,
      resort,
      kind,
    } = watchedFields

    const basePayload = { date_from, date_to, notes, resort: resort?.value, kind: kind?.value }
    let result = null

    if (blockade_dates_kind === 'empty') {
      result = await commonObjectPost(appData.urls.rent.apartment_block, {
        apartments_amount,
        accommodation_type: accommodation_type?.value,
        apartment_block_kind: blockade_dates_kind,
        ...basePayload,
      })
    }

    if (blockade_dates_kind === 'selected') {
      result = await commonObjectPost(appData.urls.rent.apartment_block, {
        apartments: selected_local_rows?.map(row => row.local_number?.value) ?? [],
        apartment_block_kind: blockade_dates_kind,
        ...basePayload,
      })
    }

    showSuccessModal(null, { blockadeResult: result })

    toggleIsVisible()
  }, methods.setError)

  useDidUpdateEffect(() => {
    methods.resetField('notes')
    methods.resetField('accommodation_type')
    methods.resetField('apartments_amount')
    methods.resetField('selected_local_rows')
  }, [watchedFields.blockade_dates_kind])

  const accommodationTypes = React.useMemo(() => {
    const accommodationTypesForResort = resorts.find(
      (el: Resort) => el.id === parseInt(watchedFields.resort?.value, 10),
    )
    return accommodationTypesForResort?.accommodation_types ?? []
  }, [watchedFields.resort?.value])

  const resortOptions = React.useMemo(
    () => resorts.map(resort => ({ value: resort.id, label: resort.name })),
    [resorts],
  )

  const isSubmitEnabled = React.useMemo(() => {
    const {
      notes,
      date_from,
      date_to,
      blockade_dates_kind,
      accommodation_type,
      apartments_amount,
      selected_local_rows,
    } = watchedFields

    if (!notes && !date_to && !date_from) return false

    if (blockade_dates_kind === 'empty') {
      return accommodation_type && !!apartments_amount
    }

    return !!selected_local_rows?.every(el => !!el.local_number && el.accommodation)
  }, [watchedFields])

  return (
    <Form methods={methods} onSubmit={handleSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Dodaj blokadę</ModalHeader>
      <ModalBody>
        <div className="mb-3" style={{ width: 395 }}>
          <CustomReactSelect options={resortOptions} label="Ośrodek:" fieldName="resort" />
        </div>
        <ReservationBlockadeViaDates accommodationTypes={accommodationTypes} />
        <FormSelect
          name="kind"
          options={KindOptions}
          selectProps={{ isClearable: true }}
          formPlainProps={{ colClassName: 'px-0', colStyle: { width: 395 } }}
          label="Rodzaj blokady"
        />
        <FormInput
          formGroupClassName="mt-3"
          colClassName="px-0"
          name="notes"
          type="textarea"
          label="Notatka:"
          placeholder="Powód blokady"
          inputProps={{ rows: 3 }}
        />
      </ModalBody>
      <ModalFooter>
        <Button className="btn btn-light" type="button" onClick={toggleIsVisible}>
          Anuluj
        </Button>
        <SaveButton
          label="Zastosuj"
          className="btn-green btn"
          onClick={handleSubmit}
          isSaving={isLoading}
          disabled={!isSubmitEnabled}
        />
      </ModalFooter>
    </Form>
  )
}
