import * as React from 'react'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { ClientCouponItem } from '@modules/coupons/models'
import { CouponsClientDetailsTableRow } from '@modules/coupons/list/client-details/coupons-client-details-table-row'
import { CouponsClientDetailsTableFilters } from '@modules/coupons/list/client-details/index'

interface Props {
  coupons: ClientCouponItem[]
  setFilters: (filters: CouponsClientDetailsTableFilters) => void
  filters: TableFilters
  isLoading: boolean
}

const headerValues: TableHeaderValue[] = [
  { title: 'Kod' },
  { title: 'Status' },
  { title: 'Typ kuponu' },
  { title: 'Wartość kuponu' },
  { title: 'Metoda płatności' },
  { title: 'Faktura' },
  { title: 'Data zakupu' },
  { title: 'Data ważności' },
  { title: 'Data wykorzystania' },
  { title: 'Nr rezerwacji' },
  { title: '' },
]

export const CouponsClientDetailsTable: React.FC<Props> = ({ setFilters, coupons, filters, isLoading }) => (
  <Table
    className="table-hover w-100"
    headerValues={headerValues}
    isLoading={isLoading}
    filters={filters}
    setFilters={setFilters}
  >
    {coupons.map((coupon: ClientCouponItem) => (
      <CouponsClientDetailsTableRow key={coupon.id} coupon={coupon} />
    ))}
  </Table>
)
