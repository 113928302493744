import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { ButtonWithIcon } from '@components/button-with-icon'
import { ContentNotesCardContent } from '@components/content-notes/content-notes-card-content'
import { useModal } from '@components/modals/use-modal'
import { FormSelect } from '@hyper/forms/form-select'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { UpSellBookingDetails } from '@models/upselling'
import { createSelectOption } from '@helpers/utils'
import { CustomReactSelectOption } from '@components/custom-react-select'

interface FormInputs {
  notesKind: CustomReactSelectOption
}

interface Props {
  upsellBooking: UpSellBookingDetails
}

export const UpsellBookingsDetailsNotes = ({ upsellBooking }: Props): JSX.Element => {
  const [showAddNoteModal] = useModal('UpsellBookingAddNoteModal', { upsellBooking })

  const methods = useForm<FormInputs>({ defaultValues: { notesKind: NotesKindOptions[0] } })

  const selectedNotesKind = useWatch({ control: methods.control, name: 'notesKind' })
  const notesToShow = selectedNotesKind.value === 'booking' ? upsellBooking.booking.notes : upsellBooking.notes

  return (
    <FormProvider {...methods}>
      <div className="mt-3 mx-n3 px-3 bg-white pt-3">
        <div className="d-flex justify-content-between">
          <div>
            <IconWithText
              icon="uil-notes font-24"
              text="Dodane notatki"
              wrapperClassNames="text-secondary fw-semi-bold"
              textClass="font-18"
            />
            <FormSelect options={NotesKindOptions} name="notesKind" formPlainProps={{ colClassName: 'px-0 mt-2' }} />
          </div>

          {selectedNotesKind.value === 'upselling' && (
            <ButtonWithIcon
              icon="uil-plus font-15 lh-1"
              text="Dodaj notatkę"
              color="light"
              btnClass="btn-tall"
              handleClick={showAddNoteModal}
            />
          )}
        </div>
        <div className="mt-3">
          {notesToShow.map(note => (
            <ContentNotesCardContent className="mb-2" readonly={false} key={note.id} note={note} />
          ))}
          {!notesToShow.length && <p className="text-center text-muted mt-2 pb-4">Brak dodanych notatek</p>}
        </div>
      </div>
    </FormProvider>
  )
}

const NotesKindOptions = [
  createSelectOption('Notatki zlecenia', 'upselling'),
  createSelectOption('Notatki rezerwacji', 'booking'),
]
