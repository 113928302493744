import * as React from 'react'
import { FormInput, FormPlain } from '@hyper/forms'
import { Col, CustomInput, Label, Row } from 'reactstrap'
import { useFormContext, useWatch } from 'react-hook-form'
import { PackageCreateFormInputs } from '@modules/package/create/modal'
import { usePackageAppData } from '@modules/package/hooks/use-package-app-data'
import { formatPrice, formatPriceShort } from '@helpers/utils'
import { extractInnerRef } from '@helpers/forms'
import DatePickerInput from '@components/date/date-picker-input'
import { PackageType } from '@models/package'
import { useDebounce } from 'rooks'
import { calculatePrice } from '@api/package'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { startOfToday } from 'date-fns'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useFormRequest } from '@components/hooks/use-api-request'
import { ContentLoader } from '@components/content-loader'
import { useSubscriptionKinds } from '@modules/subscription/hooks/use-subscription-kinds'
import { EntranceTicketPriceInformationTooltip } from '@modules/subscription/options/subscription-entrance-ticket/entrance-ticket-price-information-tooltip'
import { FormSelect } from '@hyper/forms/form-select'
import { UploaderAcceptableFile } from '@components/file-uploader'
import { CustomFileInput } from '@components/custom-file-input'
import { useAppData } from '@components/hooks/use-app-data'

interface Props {
  type: PackageType
}

export interface ParamsToCalculate {
  kind?: CustomReactSelectOption
  package_type: PackageType
  houses_sea: number
  apartments_sea: number
  houses_mountains: number
  apartments_mountains: number
  with_cleaning_option: boolean
  rent_towel_amount: number
  house_single_price: number | string
  apartment_single_price: number | string
  with_zoo_borysew_tickets: boolean
  with_first_car_parking: boolean
  with_second_car_parking: boolean
  zoo_borysew_tickets_single_price_brutto: string
  defaults_days: number
}

export const PackageCreateFormParams: React.FC<Props> = ({ type }) => {
  const [priceBrutto, setPriceBrutto] = React.useState(0)

  const appData = usePackageAppData()
  const user = useAuthenticatedUser()

  const isHPI = type === PackageType.HPI

  const { setValue, control, register, getValues, clearErrors, setError } = useFormContext<PackageCreateFormInputs>()

  const defaultsDays = useWatch({ control, name: 'defaults_days' })

  const prices = appData.prices.values.filter(row => row.type === (isHPI ? 'HPI' : 'HPR') && row.days === defaultsDays)

  const pricesApartments = prices.filter(row => row.accommodation_type === 'apartments')
  const pricesHouses = prices.filter(row => row.accommodation_type === 'houses')

  const paramsToCalculate = useWatch({
    control,
    name: [
      'defaults_days',
      'houses_sea',
      'apartments_sea',
      'houses_mountains',
      'apartments_mountains',
      'with_cleaning_option',
      'rent_towel_amount',
      'house_single_price',
      'apartment_single_price',
      'with_zoo_borysew_tickets',
      'with_first_car_parking',
      'with_second_car_parking',
      'kind',
      'zoo_borysew_tickets_single_price_brutto',
      'suntago_tickets_single_price_brutto',
      'with_suntago_tickets',
    ],
  })

  const { deposit_payment_deadline } = useAppData()

  const { subscriptionKindOptions, getSubscriptionKindVersionOptions } = useSubscriptionKinds()
  const [kind, instruction] = useWatch({ control, name: ['kind', 'instruction'] })
  const subscriptionKindVersionOptions = getSubscriptionKindVersionOptions(kind)

  const { isLoading, action: calculate } = useFormRequest(
    async () => {
      const payload = getValues()
      const accommodationFields = ['houses_sea', 'houses_mountains', 'apartments_sea', 'apartments_mountains']

      const isAccommodationFilled = accommodationFields.some(field => !!payload[field])

      if (!isAccommodationFilled || !payload.kind) return

      clearErrors()
      setPriceBrutto(await calculatePrice({ ...payload, package_type: type }))
    },
    setError,
    { showGlobalError: true },
  )

  const debounceCalculatePrice = useDebounce(async () => {
    await calculate()
  }, 250)

  useDidUpdateEffect(() => {
    debounceCalculatePrice()
  }, [paramsToCalculate])

  const includedZooTickets = useWatch({ control, name: 'with_zoo_borysew_tickets' })
  const includedSuntagoTickets = useWatch({ control, name: 'with_suntago_tickets' })

  useDidUpdateEffect(() => {
    setValue('apartment_single_price', '')
    setValue('house_single_price', '')
  }, [defaultsDays])

  return (
    <Row>
      <FormInput
        label="Ilość dób"
        colSize={4}
        type="select"
        name="defaults_days"
        registerParams={{ valueAsNumber: true }}
      >
        <option value={6}>6 dób</option>
        <option value={5}>5 dób</option>
        <option value={2}>2 doby</option>
      </FormInput>
      <FormInput label="Cena za domek" colSize={4} type="select" name="house_single_price">
        {pricesHouses.map(price => (
          <option key={price.id} value={price.price_brutto}>
            {formatPriceShort(price.price_netto)}
          </option>
        ))}
      </FormInput>
      <FormInput label="Cena za apartament" colSize={4} type="select" name="apartment_single_price">
        {pricesApartments.map(price => (
          <option key={price.id} value={price.price_brutto}>
            {formatPriceShort(price.price_netto)}
          </option>
        ))}
      </FormInput>
      <FormInput label="Ilość voucherów na domek (morze)" colSize={6} name="houses_sea" />
      <FormInput label="Ilość voucherów na apartament (morze)" colSize={6} name="apartments_sea" />
      <FormInput label="Ilość voucherów na domek (góry)" colSize={6} name="houses_mountains" />
      <FormInput label="Ilość voucherów na apartament (góry)" colSize={6} name="apartments_mountains" />
      <FormSelect
        options={subscriptionKindOptions}
        name="kind"
        label="Rodzaj pakietu"
        formPlainProps={{ colSize: 4 }}
      />
      {!!subscriptionKindVersionOptions.length && (
        <FormSelect
          options={subscriptionKindVersionOptions}
          name="kind_version"
          label="Wersja rodzaju"
          formPlainProps={{ colSize: 4 }}
        />
      )}
      <FormPlain colSize={4} name="Cena brutto">
        <ContentLoader isLoading={isLoading}>
          <label className="d-block">Cena brutto</label>
          <strong className="text-semi-strong form-control border-0 pl-0">{formatPrice(priceBrutto || 0)}</strong>
        </ContentLoader>
      </FormPlain>
      <FormInput label="Prefiks kodów" name="prefix" colSize={4} />
      <FormInput label="Kwota kaucji" colSize={6} type="select" name="deposit_amount">
        {appData.deposit_amount.map(deposit => (
          <option key={deposit} value={deposit}>
            {formatPriceShort(deposit)}
          </option>
        ))}
      </FormInput>
      <FormPlain colSize={6} name="expire_after">
        <Label>Data ważności</Label>
        <DatePickerInput
          minDate={startOfToday()}
          isDisabled={!user.hasPerm(UserPermission.PackageCanSetExpireAfter)}
          control={control}
          name="expire_after"
          wrapperClassName="flex-grow"
        />
      </FormPlain>
      <FormInput label="Dolicz ilość kompletów ręczników" colSize={6} name="rent_towel_amount" />
      <CustomFileInput
        label="Instrukcja korzystania z vouchera"
        inputName="instruction"
        acceptFiles={UploaderAcceptableFile.PDF}
        canDelete={!!instruction}
      />
      <FormPlain colSize={6} name="default_code_activate">
        <CustomInput
          className="mt-2"
          type="checkbox"
          id="default_code_activate"
          label="Kody domyślnie są aktywne?"
          {...extractInnerRef(register('default_code_activate'))}
        />
      </FormPlain>
      <FormPlain colSize={6} name="with_cleaning_option">
        <CustomInput
          className="mt-2"
          type="checkbox"
          id="with_cleaning_option"
          label="Dolicz sprzątanie"
          {...extractInnerRef(register('with_cleaning_option'))}
        />
      </FormPlain>
      {isHPI && (
        <FormPlain colSize={6} name="with_first_car_parking">
          <CustomInput
            className="mt-2"
            type="checkbox"
            id="with_first_car_parking"
            label="Pierwszy parking w cenie"
            {...extractInnerRef(register('with_first_car_parking'))}
          />
        </FormPlain>
      )}{' '}
      {isHPI && (
        <FormPlain colSize={6} name="with_second_car_parking">
          <CustomInput
            className="mt-2"
            type="checkbox"
            id="with_second_car_parking"
            label="Drugi parking w cenie"
            {...extractInnerRef(register('with_second_car_parking'))}
          />
        </FormPlain>
      )}
      {type === PackageType.HPI && (
        <div>
          <Col md={8} className="d-flex">
            <FormPlain name="with_zoo_borysew_tickets" colClassName="px-0">
              <CustomInput
                className="mt-2"
                type="checkbox"
                id="with_zoo_borysew_tickets"
                label={
                  <span>
                    Dolicz bilety do Zoo Borysew
                    <EntranceTicketPriceInformationTooltip />
                  </span>
                }
                {...extractInnerRef(register('with_zoo_borysew_tickets'))}
              />
            </FormPlain>
            <FormInput
              disabled={!includedZooTickets}
              colClassName="pl-0"
              colSize={4}
              name="zoo_borysew_tickets_single_price_brutto"
              placeholder="Cena biletów"
              step="0.01"
              type="number"
            />
          </Col>
          <Col md={8} className="d-flex">
            <FormPlain name="with_suntago_tickets" colClassName="px-0">
              <CustomInput
                className="mt-2"
                type="checkbox"
                id="with_suntago_tickets"
                label={
                  <span>
                    Dolicz bilety do Suntago
                    <EntranceTicketPriceInformationTooltip />
                  </span>
                }
                {...extractInnerRef(register('with_suntago_tickets'))}
              />
            </FormPlain>
            <FormInput
              disabled={!includedSuntagoTickets}
              colClassName="pl-0"
              colSize={4}
              name="suntago_tickets_single_price_brutto"
              placeholder="Cena biletów"
              step="0.01"
              type="number"
            />
          </Col>
        </div>
      )}
      <FormInput label="Termin wpłaty kaucji" name="deposit_payment_deadline" colSize={4} type="select">
        {deposit_payment_deadline.map(([value, label]) => (
          <option value={value} key={value}>
            {label}
          </option>
        ))}
        <option value="">Domyślne działanie</option>
      </FormInput>
      {type === PackageType.HPR && user.hasPerm(UserPermission.PackageCanCreateHpiSourcePackage) && (
        <FormInput label="Pakiet źródlowy" type="select" colSize={6} name="package_source_type">
          <option value="DEFAULT">BRAK</option>
          <option value="HPI">HPI</option>
        </FormInput>
      )}
    </Row>
  )
}
