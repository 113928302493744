import * as React from 'react'
import { ReportTask } from '@models/reports'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAppDispatch } from '@store/index'
import { commonObjectPost } from '@store/actions/generic-actions'
import { updateUserReportTask } from '@store/slices/report-task-slice'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'

interface Props {
  reportTask: ReportTask
}

export const UserReportsTasksTableRowActionsRestart = ({ reportTask }: Props): JSX.Element | null => {
  const dispatch = useAppDispatch()
  const user = useAuthenticatedUser()

  const { addSuccessMessage } = useNotificationHook()

  const handleAccept = async () => {
    dispatch(updateUserReportTask(await commonObjectPost<ReportTask>(reportTask.urls.details)))
    addSuccessMessage('Sukces', 'Raport został ponowiony')
  }

  return user.hasPerm(UserPermission.ReportsCanRestartReportTask) ? (
    <CommonObjectConfirmAction handleAccept={handleAccept}>
      <i className="uil uil-refresh ml-1 cursor-pointer text-muted" />
    </CommonObjectConfirmAction>
  ) : null
}
