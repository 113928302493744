import * as React from 'react'
import { ReceptionBooking, ReceptionBookingDetails } from '@models/reception'
import { useAppDispatch, useAppSelector } from '@store/index'
import { getReceptionBookingDetails, getResortDetails } from '@store/actions/reception-actions'
import { ModalBody } from 'reactstrap'
import {
  ReceptionBookingCheckStep,
  ReceptionBookingCheckSteps,
} from '@modules/reception/common/reception-booking-check-steps'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { ReceptionBookingCheckOutStepThanks } from '@modules/reception/checkout/step-thanks'
import { ReceptionBookingCheckOutStepSettlement } from '@modules/reception/checkout/step-settlement'
import { ReceptionBookingCheckOutStepBill } from '@modules/reception/checkout/step-bill'
import { ReceptionBookingCheckOutStepKeys } from '@modules/reception/checkout/step-keys'
import { AlertDanger } from '@components/alerts'
import { ReceptionBookingCheckOutStepBands } from '@modules/reception/checkout/step-keys/reception-booking-check-out-step-bands'
import { ReceptionCheckHeader } from '@modules/reception/common/reception-check-header'
import { receptionBookingDetailsSelector } from '@store/selectors/reception'
import { BaseModalProps } from '@components/modals/types'
import { commonObjectPost } from '@store/actions/generic-actions'
import { isBefore } from 'date-fns'
import { parseISODate } from '@helpers/date-helper'
import { useModal } from '@components/modals/use-modal'

interface ReceptionBookingCheckOutDialogProps extends BaseModalProps {
  booking: ReceptionBooking
  initialStep?: number
}

const checkInSteps: ReceptionBookingCheckStep[] = [
  {
    step: 1,
    description: (
      <>
        Zwrot <br /> opasek/kluczyka
      </>
    ),
  },
  {
    step: 2,
    description: (
      <>
        Rachunek <br /> informacyjny
      </>
    ),
  },
  {
    step: 3,
    description: (
      <>
        Rozliczenie <br /> rachunku
      </>
    ),
  },
  {
    step: 4,
    title: "Pamięci Marka Watney'a!",
    description: (
      <>
        Pożegnanie <br /> z marsem
      </>
    ),
  },
]

export const ReceptionBookingCheckOutDialog: React.FC<ReceptionBookingCheckOutDialogProps> = ({
  booking,
  initialStep,
  toggleIsVisible,
}) => {
  const [step, setStep] = React.useState(initialStep || 1)

  const [showShorteningStayConfirmationModal] = useModal('ReceptionBookingCheckOutShorteningStayConfirmDialog')
  const bookingDetails = useAppSelector(receptionBookingDetailsSelector)

  const dispatch = useAppDispatch()

  const isBeforeCheckoutDate = bookingDetails
    ? isBefore(new Date(), parseISODate(bookingDetails.date_to) as Date)
    : false

  const handleIsOpen = async () => {
    await commonObjectPost<ReceptionBookingDetails>(bookingDetails?.urls.check_status, { action: 'check-out-cancel' })
    toggleIsVisible()
  }

  React.useEffect(() => {
    if (bookingDetails?.urls.check_status) {
      commonObjectPost<ReceptionBookingDetails>(bookingDetails.urls.check_status, { action: 'check-out-started' })
    }
  }, [bookingDetails?.urls.check_status])

  React.useEffect(() => {
    dispatch(getReceptionBookingDetails(booking.urls.api_details, { action: 'check-out' }))
    dispatch(getResortDetails(booking.resort_id, booking.accommodation_type_id))
  }, [])

  const previousStep = () => setStep(Math.max(step - 1, 1))

  const nextStep = () => {
    if (step === 1 && isBeforeCheckoutDate && bookingDetails) {
      showShorteningStayConfirmationModal(null, {
        bookingDetails,
        onConfirm: () => {
          setStep(state => state + 1)
        },
      })

      return
    }

    setStep(step + 1)
  }

  return (
    <>
      <ReceptionCheckHeader
        title={`Proces wymeldowania rezerwacji ${booking.reservation_number}`}
        handleIsOpen={handleIsOpen}
        bookingDetails={bookingDetails}
      />
      <ReceptionBookingCheckSteps step={step} steps={checkInSteps} />
      {bookingDetails?.status === 'close' && (
        <ModalBody>
          <AlertDanger className="text-strong">Uwaga, rezerwacja została już wymeldowana!</AlertDanger>
        </ModalBody>
      )}
      <LoaderPlaceholder content={bookingDetails} className="p-2">
        {bookingDetails &&
          step === 1 &&
          (bookingDetails.bands.length ? (
            <ReceptionBookingCheckOutStepBands nextStep={nextStep} booking={bookingDetails} />
          ) : (
            <ReceptionBookingCheckOutStepKeys nextStep={nextStep} booking={bookingDetails} />
          ))}

        {bookingDetails && step === 2 && (
          <ReceptionBookingCheckOutStepBill previousStep={previousStep} nextStep={nextStep} booking={bookingDetails} />
        )}
        {bookingDetails && step === 3 && (
          <ReceptionBookingCheckOutStepSettlement
            previousStep={previousStep}
            nextStep={nextStep}
            booking={bookingDetails}
          />
        )}
        {bookingDetails && step === 4 && (
          <ReceptionBookingCheckOutStepThanks
            booking={bookingDetails}
            nextStep={nextStep}
            handleIsOpen={handleIsOpen}
          />
        )}
      </LoaderPlaceholder>
    </>
  )
}
