import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { FeedingHostApartmentInfoTable } from '@modules/feeding/host/apartment-info/table'
import { FeedingHostApartmentInfoReservationDetails } from '@modules/feeding/host/apartment-info/feeding-details'
import { FeedingApartmentOption } from '@modules/feeding/models'

interface Props {
  apartmentOption: FeedingApartmentOption
  onRefresh: () => void
  onStatsRefresh: () => void
}

export const FeedingHostApartmentInfoBox = ({ apartmentOption, onRefresh, onStatsRefresh }: Props) => {
  const [option, setOption] = React.useState(apartmentOption)

  const { control } = useFormContext()
  const [type] = useWatch({ control, name: ['type'] })

  const onChange = (updatedOption: FeedingApartmentOption) => {
    setOption(updatedOption)
    onStatsRefresh()
  }

  return (
    <>
      {option.status === 'not_found' && <div className="alert alert-danger">W tym dniu nie ma rezerwacji</div>}
      {option.status === 'not_confirmed' && (
        <>
          <h5 className="text-secondary">
            {option.name} ({option.reservation_number})
          </h5>

          <div className="alert alert-danger">
            Rezerwacja nie została potwierdzona, należy ją potwierdzić na recepcji
          </div>
        </>
      )}

      {option.status === 'not_feeding' && (
        <>
          <h5 className="text-secondary">
            {option.name} ({option.reservation_number})
          </h5>
          <div className="alert alert-danger">
            Rezerwacja {option.reservation_number} nie posiada wykupionego wyżywienia
          </div>
        </>
      )}

      {option.status === 'found' && (
        <FeedingHostApartmentInfoTable onApartmentOptionChange={onChange} option={option} type={type.value} />
      )}

      {option.reservation_details_url && (
        <FeedingHostApartmentInfoReservationDetails
          onFeedingChange={onRefresh}
          label={option.status === 'not_feeding' ? 'Sprzedaj wyżywienie' : 'Szczegóły wyżywienia'}
          reservationDetailsUrl={option.reservation_details_url}
        />
      )}
    </>
  )
}
