import * as React from 'react'
import { BookingApartmentMeters } from '@models/booking'
import { ReceptionBookingDetails } from '@models/reception'
import { ReceptionBookingCheckInStepDocumentsMetersRow } from '@modules/reception/checkin/step-documents/step-documents-meters-row'
import { Button } from 'reactstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { commonObjectPatch } from '@store/actions/generic-actions'
import { useApiRequest } from '@components/hooks/use-api-request'
import { IconWithText } from '@components/icon-with-text'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'

interface UpdatableMeters {
  water: string
  hot_water: string
  power: string
  heat: string
}

interface ReceptionBookingCheckInStepDocumentsMetersProps {
  booking: ReceptionBookingDetails
  meters: BookingApartmentMeters
  onMetersRefresh: () => void
}

interface FormInputs {
  water_start: string
  hot_water_start: string
  heat_start: string
  power_start: string
}

export const ReceptionBookingCheckInStepDocumentsMeters: React.FC<ReceptionBookingCheckInStepDocumentsMetersProps> = ({
  booking,
  meters,
  onMetersRefresh,
}) => {
  const [metersToShow, setMetersToShow] = React.useState<UpdatableMeters | undefined>(undefined)
  const [isEditMode, setIsEditMode] = React.useState(false)

  const toggleEditMode = () => setIsEditMode(!isEditMode)

  const methods = useForm<FormInputs>({
    shouldUnregister: false,
    defaultValues: {
      water_start: meters?.water,
      hot_water_start: meters?.hot_water,
      heat_start: meters?.heat,
      power_start: meters?.power,
    },
  })

  React.useEffect(() => {
    if (meters) {
      setMetersToShow({
        water: meters.water,
        hot_water: meters.hot_water,
        power: meters.power,
        heat: meters.heat,
      })
      methods.setValue('water_start', meters.water)
      methods.setValue('hot_water_start', meters.hot_water)
      methods.setValue('power_start', meters.power)
      methods.setValue('heat_start', meters.heat)
    }
  }, [meters])

  const { isLoading, action: onSubmit } = useApiRequest(async reason => {
    const { water_start, hot_water_start, heat_start, power_start } = methods.getValues()
    await saveMeterData(water_start, hot_water_start, power_start, heat_start, reason)
    setMetersToShow({ water: water_start, hot_water: hot_water_start, heat: heat_start, power: power_start })

    toggleEditMode()
  })

  const saveMeterData = async (
    water_start: string,
    hot_water_start: string,
    power_start: string,
    heat_start,
    reason: string,
  ) => {
    await commonObjectPatch<ReceptionBookingDetails>(booking.urls.check_in_step_documents, {
      water_start,
      hot_water_start,
      power_start,
      heat_start,
      reason,
    })
  }

  return (
    <FormProvider {...methods}>
      <div className="reception__booking-check-in__documents-step__meters">
        <div className="d-flex align-center justify-content-between mb-3">
          <strong className="font-14 text-gray">Stan liczników</strong>
          {!isEditMode && (
            <div
              className="d-flex justify-content-end align-items-center text-semi-strong cursor-pointer"
              onClick={toggleEditMode}
            >
              <span className="font-10 text-gray d-block">edytuj stan</span>
              <i className="uil-pen ml-1 font-14" />
            </div>
          )}
        </div>
        {!!meters && !!metersToShow && (
          <>
            <ReceptionBookingCheckInStepDocumentsMetersRow
              label="Licznik wody"
              icon="uil-tear"
              lastReadDate={meters.water_reading_date}
              value={metersToShow.water}
              unity="m³"
              price={meters.water_costs}
              handleRefresh={onMetersRefresh}
              hasError={!meters.water_is_up_to_date}
              editMode={isEditMode}
              inputName="water_start"
            />

            {booking.service_room_data.has_hot_water_meter && (
              <ReceptionBookingCheckInStepDocumentsMetersRow
                label="Licznik ciepłej wody"
                icon="uil-raindrops"
                lastReadDate={meters.hot_water_reading_date}
                value={metersToShow.hot_water}
                unity="m³"
                price={meters.hot_water_costs}
                handleRefresh={onMetersRefresh}
                hasError={!meters.hot_water_is_up_to_date}
                editMode={isEditMode}
                inputName="hot_water_start"
              />
            )}

            <ReceptionBookingCheckInStepDocumentsMetersRow
              label="Licznik prądu"
              icon="uil-bolt"
              lastReadDate={meters.power_reading_date}
              value={metersToShow.power}
              unity="kWh"
              price={meters.power_costs}
              handleRefresh={onMetersRefresh}
              hasError={!meters.power_is_up_to_date}
              editMode={isEditMode}
              inputName="power_start"
            />
            {booking.service_room_data.has_heat_meter && (
              <ReceptionBookingCheckInStepDocumentsMetersRow
                label="Licznik ogrzewania"
                icon="uil-temperature"
                lastReadDate={meters.heat_reading_date}
                value={metersToShow.heat}
                unity="GJ"
                price={meters.heat_costs}
                handleRefresh={onMetersRefresh}
                hasError={!meters.heat_is_up_to_date}
                editMode={isEditMode}
                inputName="heat_start"
              />
            )}
            {isEditMode && (
              <div className="d-flex justify-content-end">
                <Button color="light" onClick={toggleEditMode} className="mr-1">
                  Anuluj
                </Button>
                <CommonObjectConfirmAction
                  title="Potwierdź zmianę odczytów"
                  handleAccept={reason => onSubmit(reason)}
                  isLoading={isLoading}
                  askForReason={true}
                >
                  <Button className="btn btn-success">
                    <IconWithText icon="uil-check mr-1" text="Potwierdź" />
                  </Button>
                </CommonObjectConfirmAction>
              </div>
            )}
          </>
        )}
      </div>
    </FormProvider>
  )
}
