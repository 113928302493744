import DatePickerInput from '@components/date/date-picker-input'
import { useFormRequest } from '@components/hooks/use-api-request'
import { SaveButton } from '@hyper/button'
import { FormInput, FormPlain } from '@hyper/forms'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { PackageDetails } from '@models/package'
import { commonObjectPut } from '@store/actions/generic-actions'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { useAppDispatch } from '@store/index'
import { Button, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import { updatePackageDetails } from '@store/slices/package-slice'
import { Form } from '@hyper/forms/form'

interface FormInputs {
  payment_date: string
  custom_invoice_position: string
  send_email_notification: boolean
}

interface Props extends BaseModalProps {
  packageDetails: PackageDetails
}

export const PackageInvoiceCreateModal: React.FC<Props> = ({ toggleIsVisible, packageDetails }) => {
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const methods = useForm<FormInputs>()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    dispatch(updatePackageDetails(await commonObjectPut<PackageDetails>(packageDetails.urls.invoice_create, payload)))
    addSuccessMessage('Sukces', 'Wystawiono fakturę')
    toggleIsVisible()
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Wystaw fakturę</ModalHeader>
      <ModalBody>
        <Row>
          <FormPlain name="payment_date">
            <label>Termin płatności</label>
            <DatePickerInput name="payment_date" />
          </FormPlain>
          <FormInput name="custom_invoice_position" label="Własna nazwa pozycji na fakturze" />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton role="submit" className="btn btn-green" label="Zapisz" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
