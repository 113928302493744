import { RootState, useAppSelector } from '@store/index'
import * as React from 'react'
import { useHousekeepingTableFilters } from '@modules/housekeeping/hooks/use-housekeeping-table-filters'
import { useForm } from 'react-hook-form'
import { searchOrderedOrders, filterSelectedWorkers } from '@modules/housekeeping/cleaning-orders/common/filtering'
import { CleaningOrder } from '@modules/housekeeping/models'
import { UseFormReturn } from 'react-hook-form/dist/types'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { equals } from 'ramda'
import { useHousekeepingCleaningCompanies } from '@modules/housekeeping/users/use-housekeeping-cleaning-companies'

export interface HousekeepingCleaningOrdersOrderedFiltersParams {
  service_kind: CustomReactSelectOption | null
  company: CustomReactSelectOption | null
  local: CustomReactSelectOption | null
  kind: CustomReactSelectOption | null
  user: CustomReactSelectOption | null
  search: string
  is_vip: boolean
  early_check_in: boolean
  extend_stay: boolean
}

const defaultFilters: HousekeepingCleaningOrdersOrderedFiltersParams = {
  service_kind: null,
  company: null,
  local: null,
  kind: null,
  user: null,
  search: '',
  is_vip: false,
  early_check_in: false,
  extend_stay: false,
}

interface Response {
  methods: UseFormReturn<HousekeepingCleaningOrdersOrderedFiltersParams, any>
  onFilterChange: (filters: HousekeepingCleaningOrdersOrderedFiltersParams) => void
  filteredOrders: CleaningOrder[]
  defaultFilters: HousekeepingCleaningOrdersOrderedFiltersParams
  filters: HousekeepingCleaningOrdersOrderedFiltersParams
  filterChanged: boolean
}

export const useHousekeepingCleaningOrderedOrders = (resortId: number, cleaningOrders: CleaningOrder[]): Response => {
  const [filteredCleaningOrders, setFilteredCleaningOrders] = React.useState<CleaningOrder[]>([])
  const [filters, setFilters] = React.useState<HousekeepingCleaningOrdersOrderedFiltersParams>(defaultFilters)

  const workers = useAppSelector((state: RootState) => state.houseKeepingState.cleaningWorkers)

  const methods = useForm<HousekeepingCleaningOrdersOrderedFiltersParams>({
    defaultValues: filters,
  })

  const { getFiltered, getSorted } = useHousekeepingTableFilters()

  const housekeeping_companies = useHousekeepingCleaningCompanies(resortId)

  React.useEffect(() => {
    setFilteredCleaningOrders(
      searchOrderedOrders(
        getSorted(
          getFiltered(
            cleaningOrders.filter(row => row.company_id && (row.status === 'PENDING' || row.status === 'REOPENED')),
            filters,
            filterSelectedWorkers(filters),
          ),
        ),
        filters.search,
        workers,
        housekeeping_companies,
      ),
    )
  }, [cleaningOrders, filters])

  const filterChanged = React.useMemo(() => !equals(defaultFilters, filters), [filters])

  return {
    methods,
    onFilterChange: setFilters,
    filteredOrders: filteredCleaningOrders,
    defaultFilters,
    filters,
    filterChanged,
  }
}
