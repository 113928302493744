import * as React from 'react'
import Table from '@components/table/table'
import { Agreement } from '@modules/hr/agreement/models'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { useModal } from '@components/modals/use-modal'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import classNames from 'classnames'
import { useHrAppData } from '@modules/hr/use-hr-app-data'
import { formatPrice, getById } from '@helpers/utils'
import { useAppData } from '@components/hooks/use-app-data'
import { useAgreementDetails } from '@modules/hr/agreement/details/use-agreement-details'
import { useAgreementDownloadCheck } from '@modules/hr/agreement/details/use-agreement-download-check'
import { AgreementAnnexDropdown } from '@modules/hr/agreement/annexes/annex-dropdown'
import { canAddAgreementAnnex } from '../annexes/helpers'
import { AgreementAnnexChange } from '@modules/hr/agreement/details/annexes/annex-change'

interface Props {
  agreement: Agreement
}

export const AgreementListTableRow: React.FC<Props> = ({ agreement }) => {
  const user = useAuthenticatedUser()
  const { resorts } = useAppData()
  const { companies, job_positions } = useHrAppData()

  const [showDetailsModal] = useModal('AgreementDetailsModal', { agreement })
  const [showCreationModal] = useModal('AgreementCreateModal', { agreement })

  const company = getById(companies, agreement.company)
  const jobPosition = getById(job_positions, agreement.job_position)
  const agreementResorts =
    agreement.resorts.map(agreementResort => getById(resorts, agreementResort)?.name).join(', ') || '-'

  const { refresh } = useAgreementDetails({ agreement, immediateFetch: false })

  const handleEditionContinuation = async () => {
    await refresh()
    showCreationModal()
  }

  const { canDownload } = useAgreementDownloadCheck(agreement)
  const isAnnexAddingAvailable = React.useMemo(() => canAddAgreementAnnex(agreement), [agreement.status])

  return (
    <Table.Row onDoubleClick={showDetailsModal} className="cursor-pointer" role="details-row">
      <Table.Cell>{agreement.number}</Table.Cell>
      <Table.Cell>
        <span
          className={classNames({
            'badge badge-success': agreement.status === 'completed',
            'badge badge-success-lighten': agreement.status === 'active',
            'badge badge-warning': agreement.status === 'verified',
            'badge badge-warning-lighten': agreement.status === 'to_verify',
            'badge badge-light-lighten text-default': agreement.status === 'draft_incomplete',
            'badge badge-light': agreement.status === 'draft',
            'badge badge-danger-lighten': agreement.status === 'resignation',
          })}
        >
          {agreement.status_display}
        </span>
      </Table.Cell>
      <Table.Cell>{company?.name ?? '-'}</Table.Cell>
      <Table.Cell style={{ maxWidth: 200 }}>{agreementResorts}</Table.Cell>
      <Table.Cell>
        {agreement.first_name} {agreement.last_name}
      </Table.Cell>
      <Table.Cell>{jobPosition?.name ?? '-'}</Table.Cell>
      <Table.Cell>
        <AgreementAnnexChange
          id={`hourly-rate-${agreement.id}`}
          change={agreement.annexed_fields.hourly_rate}
          originalValue={agreement.hourly_rate}
          formatter={formatPrice}
          tooltipPosition="right"
        />
      </Table.Cell>
      <Table.Cell>{toDefaultDateFormat(agreement.date_of_start) || '-'}</Table.Cell>
      <Table.Cell>
        <AgreementAnnexChange
          change={agreement.annexed_fields.date_of_expiration}
          originalValue={agreement.date_of_expiration}
          formatter={toDefaultDateFormat}
          id={`date-of-expiration-${agreement.id}`}
          tooltipPosition="right"
        />
      </Table.Cell>

      <Table.Cell className="text-right text-nowrap">
        {user.hasPerm(UserPermission.HrAgreementCanView) && (
          <i
            className="text-muted font-16 mr-2 uil-edit-alt cursor-pointer"
            onClick={showDetailsModal}
            title="Podgląd/Edycja"
          />
        )}
        {canDownload && (
          <a href={agreement.urls.preview} target="_blank">
            <i className="text-muted font-18 uil-download-alt" title="Pobierz" />
          </a>
        )}
        {agreement.status === 'draft_incomplete' && user.hasPerm(UserPermission.HrAgreementCanEdit) && (
          <i
            className="text-muted font-18 uil-file-edit-alt ml-2 cursor-pointer"
            title="Kontynuuj uzupełnianie"
            onClick={handleEditionContinuation}
          />
        )}
        {isAnnexAddingAvailable && (
          <AgreementAnnexDropdown agreement={agreement} trigger={<i className="uil-ellipsis-v ml-1" />} />
        )}
      </Table.Cell>
    </Table.Row>
  )
}
