import * as React from 'react'
import { NotificationTemplateSelect } from '@components/notification-box/notification-template-select'
import { IconWithText } from '@components/icon-with-text'
import { NotificationMessage } from '@components/notification-box/notification-message'
import { NotificationMessages } from '@components/notification-box/notification-messages'
import { NotificationsEmptyMessage } from '@components/notification-box/notifications-empty-message'
import { createSelectOption } from '@helpers/utils'
import { EmailNotification, NotificationTemplate } from '@models/notifications'

interface Props {
  notificationTemplates: NotificationTemplate[]
  notifications: EmailNotification[]
  previewUrl?: string
  onSend?: () => void
  isDisabled: boolean
}

export const NotificationEmailMessages = ({
  notificationTemplates,
  notifications,
  previewUrl,
  onSend,
  isDisabled,
}: Props): React.ReactNode => {
  const templateOptions = notificationTemplates.map(template =>
    createSelectOption(template.name, template.id, template.is_active === undefined ? false : !template.is_active),
  )

  return (
    <div>
      <NotificationTemplateSelect
        onSend={onSend}
        isDisabled={isDisabled || !templateOptions.length}
        previewUrl={previewUrl}
        name="email_template"
        notificationKind="email"
        options={templateOptions}
        placeholder={
          <IconWithText
            icon="uil-envelope font-15"
            text="Wybierz treść z szablonu"
            wrapperClassNames="text-muted"
            textClass="font-12"
          />
        }
      />
      <NotificationMessages
        noMessageComponent={NoMessageComponent}
        contentName="powiadomienia"
        notifications={notifications}
        renderNotification={notification => (
          <NotificationMessage
            notificationKind="email"
            key={notification.id}
            title={notification.kind_display}
            notification={notification}
            icon="uil-envelope"
          />
        )}
      />
    </div>
  )
}

const NoMessageComponent = (
  <NotificationsEmptyMessage image={require('@assets/images/no-email-placeholder.svg')}>
    Brak powiadomień <br /> e-mail
  </NotificationsEmptyMessage>
)
