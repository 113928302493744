import * as React from 'react'
import { FormPlain } from '@hyper/forms'
import { extractInnerRef } from '@helpers/forms'
import { CustomInput } from 'reactstrap'
import { useFormContext, useWatch } from 'react-hook-form'
import { ReceptionTechnicalOrderStatusFormCloseResult } from '@modules/reception-technical-orders/reception-technical-order-status-modal/form-close-result'
import { ReceptionTechnicalOrderStatusFormInputs } from '@modules/reception-technical-orders/reception-technical-order-status-modal/index'
import { ReceptionTechnicalOrderStatusFormUsers } from '@modules/reception-technical-orders/reception-technical-order-status-modal/form-users'

interface Props {
  resortId: number
}

export const ReceptionTechnicalOrderStatusForm: React.FC<Props> = ({ resortId }) => {
  const { control, register } = useFormContext<ReceptionTechnicalOrderStatusFormInputs>()

  const action = useWatch({ control, name: 'action' })

  return (
    <>
      <FormPlain name="action" label="Wybierz jedną z opcji" labelSize={5}>
        <div>
          <div>
            <CustomInput
              type="radio"
              className="mb-2 mt-2"
              value="forward_to_technical_order"
              {...extractInnerRef(register('action'))}
              id="forward_to_technical_order"
              label="Przekaż do Zespołu Technicznego Resortu"
            />
            {action === 'forward_to_technical_order' && <ReceptionTechnicalOrderStatusFormUsers resortId={resortId} />}
          </div>
          <CustomInput
            type="radio"
            className="mb-2"
            value="forward_to_guests_technical_order"
            {...extractInnerRef(register('action'))}
            id="forward_to_guests_technical_order"
            label='Przenieś do "Zgłoszenia Gości"'
          />
          <CustomInput
            type="radio"
            value="close"
            className="mb-2"
            {...extractInnerRef(register('action'))}
            id="close"
            label="Oznacz jako zamknięte"
          />
        </div>
      </FormPlain>
      <ReceptionTechnicalOrderStatusFormCloseResult />
    </>
  )
}
