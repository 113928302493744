import * as React from 'react'
import { ClientUser } from '@models/clients'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap'
import { IconWithText } from '@components/icon-with-text'
import { CrmClientsOptionsBlackListBase } from '@modules/crm/clients/list-view/options/options-black-list-base'

interface Props {
  selectedRows: ClientUser[]
  clearSelectedRows: () => void
}

export const CrmClientsOptions: React.FC<Props> = ({ selectedRows, clearSelectedRows }) => {
  const isDisabled = !selectedRows.length

  return (
    <div className="mb-1">
      <UncontrolledButtonDropdown disabled={isDisabled}>
        <DropdownToggle color="light" caret={true} disabled={isDisabled}>
          <strong>zaznaczone ({selectedRows.length})</strong>
        </DropdownToggle>
        <DropdownMenu>
          <CrmClientsOptionsBlackListBase
            selectedRows={selectedRows}
            clearSelectedRows={clearSelectedRows}
            icon="uil-thumbs-down text-danger"
            label="Dodaj do czarnej listy"
            is_blacklisted={true}
            message={`Czy na pewno chcesz dodać ${selectedRows.length} do czarnej listy?`}
            title="Dodaj do czarnej listy"
          />

          <CrmClientsOptionsBlackListBase
            selectedRows={selectedRows}
            clearSelectedRows={clearSelectedRows}
            icon="uil-thumbs-up text-success"
            label="Usuń z czarnej listy"
            is_blacklisted={false}
            message={`Czy na pewno chcesz skasować ${selectedRows.length} z czarnej listy?`}
            title="Skasuj z czarnej listy"
          />
          <DropdownItem>
            <IconWithText icon="uil uil-label-alt text-bold" textClass="text-semi-strong" text="Dodaj Tag" />
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </div>
  )
}
