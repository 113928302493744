import * as React from 'react'
import { ResortTabs } from '@components/resort-tabs'
import { PageTitle } from '@hyper/page-title'
import { useDocumentTitle } from '@helpers/utils'
import { NavigationPath } from '@models/routes'
import { resortsMapSelector } from '@store/selectors/dashboard'
import { useAppSelector } from '@store/index'
import { useParams } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { TableFilters } from '@components/table/table'
import { TypedQueryResult } from '@api/base'
import { useGetUnitsQuery } from '@api/units'
import { Unit } from '@models/units'
import { UnitsFilters } from './filters'
import { UnitsTable } from './table'

interface Params {
  resort_id: string
}

const defaultFilters: TableFilters = {
  search: '',
  ordering: 'kind__position,position',
  page: 1,
  page_size: 25,
}
const emptyTable = []

export const UnitsView: React.FC = () => {
  const { resort_id } = useParams() as unknown as Params
  const [filters, setFilters] = React.useState<TableFilters>(defaultFilters)

  const resortId = parseInt(resort_id, 10)
  const resort = useAppSelector(resortsMapSelector)[resortId]
  const pageTitle = `Lista jednostek ${resort?.name || ''}`

  const { data: units = emptyTable, isLoading } = useGetUnitsQuery({
    resort: resortId,
    ...filters,
  }) as TypedQueryResult<Unit[]>

  useDocumentTitle(pageTitle)
  useMenuActive(NavigationPath.Units)

  const user = useAuthenticatedUser()

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitle} />
      <Card>
        <CardBody>
          <ResortTabs showAll={user.isTechnicalOrderManager} resortId={resortId} url={NavigationPath.UnitsWithParams} />
          <UnitsFilters setFilters={setFilters} defaultFilters={defaultFilters} filters={filters} />
          <UnitsTable
            isLoading={isLoading}
            dataTestId="table-units"
            units={units}
            setFilters={setFilters}
            filters={filters}
          />
        </CardBody>
      </Card>
    </>
  )
}
