import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { IconWithText } from '@components/icon-with-text'
import { ButtonWithIcon } from '@components/button-with-icon'
import { ReservationDepositDropdown } from '@modules/reservations/details/deposit/reservation-deposit-dropdown'
import { ReservationDepositRestToSpend } from '@modules/reservations/details/deposit/reservation-deposit-rest-to-spend'
import { ReservationDepositRestToPay } from '@modules/reservations/details/deposit/reservation-deposit-rest-to-pay'
import { VirtualPosLink } from '@modules/reservations/details/common/virtual-pos-link'
import { isBookingEditable } from '@modules/reservations/details/utils'
import { useAppSelector } from '@store/index'
import { reservationBookingPaymentsSelector } from '@store/slices/reservations-slice'
import { ReservationDepositReturnedLabel } from '@modules/reservations/details/deposit/labels/reservation-deposit-returned-label'
import { useModal } from '@components/modals/use-modal'

interface Props {
  booking: ReceptionBookingDetails
  isDepositFullyPaid: boolean
}

export const ReservationDepositProgress = ({ booking, isDepositFullyPaid }: Props): JSX.Element => {
  const [showBookingDepositPaymentModal] = useModal('ReservationDepositPaymentModal', { booking })
  const bookingPayments = useAppSelector(reservationBookingPaymentsSelector)
  const returnedDepositPayments = bookingPayments.filter(payment => payment.type === 'deposit_return')

  const isBookingActive = isBookingEditable(booking)

  return (
    <div>
      {!!returnedDepositPayments.length && (
        <ReservationDepositReturnedLabel returnedDepositPayments={returnedDepositPayments} />
      )}
      {isDepositFullyPaid ? (
        <ReservationDepositRestToSpend
          prices={booking.prices}
          isDepositReturned={!isBookingActive && !!returnedDepositPayments.length}
          isBookingActive={isBookingActive}
        />
      ) : (
        <ReservationDepositRestToPay prices={booking.prices} />
      )}
      {isBookingActive && (
        <div className="d-flex">
          <div className="d-flex">
            <IconWithText
              icon="uil-receipt font-16"
              textClass="font-11"
              text="historia zakupów"
              wrapperClassNames="mr-3"
            />
            <VirtualPosLink booking={booking} />
          </div>
          <div className="ml-auto d-flex">
            {isDepositFullyPaid ? (
              <ButtonWithIcon
                icon="uil-usd-circle font-14 lh-1"
                text="Zwiększ kaucję"
                color="primary"
                btnClass="btn-flat shadow-none"
              />
            ) : (
              <ButtonWithIcon
                icon="uil-usd-circle font-14 lh-1"
                text="Pobierz kaucję"
                color="danger"
                btnClass="btn-flat shadow-none"
                handleClick={showBookingDepositPaymentModal}
              />
            )}
            <ReservationDepositDropdown booking={booking} />
          </div>
        </div>
      )}
    </div>
  )
}
