import * as React from 'react'
import { StatsSaleItemDashboardStateRecentlySold } from '@modules/reports/models'
import { Card, CardBody, Col, Table } from 'reactstrap'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { formatPrice } from '@helpers/utils'

interface Props {
  data: StatsSaleItemDashboardStateRecentlySold[]
}

export const StatsSaleItemDashboardRecentlySold: React.FC<Props> = ({ data }) => (
  <Col md={4}>
    <Card className="h-100">
      <CardBody>
        <h4 className="header-title mb-2">Ostatnio sprzedane</h4>

        <Table hover responsive className="mb-0 mt-2">
          <tbody>
            {data.map(row => (
              <tr key={row.id}>
                <td>
                  <h5 className="font-14 mb-1 font-weight-normal">
                    {row.seller} ({row.seller_group})
                  </h5>

                  <span className="text-muted font-13">{toDefaultDateFormat(row.sell_date)}</span>
                </td>
                <td>
                  <h5 className="font-14 mb-1 font-weight-normal">{formatPrice(row.total_price_brutto)}</h5>
                  <span className="text-muted font-13">Cena</span>
                </td>
                <td>
                  <h5 className="font-14 mb-1 font-weight-normal">{row.name}</h5>
                  <span className="text-muted font-13">Produkt</span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  </Col>
)
