import * as React from 'react'
import { FormInput } from '@hyper/forms'
import Spinner from '@hyper/spinner'

interface Props {
  isLoading: boolean
}

export const RodoAgreementsSearch = ({ isLoading }: Props): JSX.Element => (
  <div>
    <h4 className="text-secondary">Wyszukaj zgody RODO:</h4>
    <div className="d-flex align-items-center">
      <FormInput
        name="search"
        placeholder="Wpisz imię i nazwisko, adres e-mail lub numer telefonu"
        colClassName="px-0"
        formGroupClassName="border rounded bg-white flex-nowrap align-items-center mb-0"
        placeholderIcon="uil-user font-14 lh-0 ml-1"
        inputClassName="border-0 bg-transparent py-0"
        colSize={5}
      />
      {isLoading && <Spinner size="xs" color="muted" className="ml-2" />}
    </div>
  </div>
)
