import * as React from 'react'
import { GastroCardDetails } from '@models/promotions'
import { NotificationBox } from '@components/notification-box/notification-box'
import { useNotificationTemplates } from '@components/notification-box/use-notification-templates'

interface Props {
  gastroCardDetails: GastroCardDetails
  onNotificationSent: () => void
  readOnly: boolean
}

export const GastroCardGenericNotifications: React.FC<Props> = ({
  onNotificationSent,
  gastroCardDetails,
  readOnly,
}) => {
  const { notificationTemplates } = useNotificationTemplates({
    url: readOnly ? '' : gastroCardDetails.urls.notifications,
  })

  return (
    <NotificationBox
      emailTemplates={notificationTemplates.email}
      emailNotifications={gastroCardDetails.notifications}
      emailUrl={gastroCardDetails.urls.notifications}
      onNotificationSent={onNotificationSent}
    />
  )
}
