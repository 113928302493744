import * as React from 'react'
import Table from '@components/table/table'
import { getTechnicalOrderStatusColor } from '@modules/technical-orders/helpers'
import classNames from 'classnames'
import { TechnicalOrder } from '@models/technical-orders'

interface Props {
  technicalOrder: TechnicalOrder
  width?: number
}

export const TechnicalOrdersTodoRowStatus: React.FC<Props> = ({ technicalOrder, width }) => (
  <Table.Cell className="vertical-align-middle" style={{ width }}>
    <span className={classNames('badge text-lowercase', `badge-${getTechnicalOrderStatusColor(technicalOrder)}`)}>
      {technicalOrder.status_display}
    </span>
  </Table.Cell>
)
