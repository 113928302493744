import * as React from 'react'
import { ReservationStatsTableExpandableRow } from './reservation-stats-table-expandable-row'
import {
  BookingStats,
  BookingStatsDetails,
  ReservationStatsTableCells,
  StatsCellContent,
  StatsCellSummary,
  StatsTableOptions,
} from '@modules/reservations/models'
import dateHelper from '@helpers/date-helper'
import { asDecimal } from '@helpers/utils'
import { ReservationStatsTableHeader } from '@modules/reservations/statistics/common/common-stats-table-header'
import { ReservationStatsTableDataCell } from '@modules/reservations/statistics/booking-stats/stats-details/stats-details-table/reservation-stats-table-data-cell'
import { ReservationStatsTableSummaryRow } from '@modules/reservations/statistics/booking-stats/stats-details/stats-details-table/reservation-stats-table-summary-row'
import { ReservationStatsAccommodationRows } from '@modules/reservations/statistics/booking-stats/stats-details/stats-details-table/reservation-stats-accommodation-rows'
import { ReservationStatsFeedingRows } from '@modules/reservations/statistics/booking-stats/stats-details/stats-details-table/reservation-stats-feeding-rows'
import { ReservationStatsPlanningRows } from '@modules/reservations/statistics/booking-stats/stats-details/stats-details-table/reservation-stats-planning-rows'
import { ReservationStatsOccupancyRows } from '@modules/reservations/statistics/booking-stats/stats-details/stats-details-table/reservation-stats-occupancy-rows'
import { CommonStatsTable } from '@modules/reservations/statistics/common/common-stats-table'

interface ReservationStatsTableProps {
  stats: BookingStats
}

export const ReservationStatsTable: React.FC<ReservationStatsTableProps> = ({ stats }) => {
  const [hoveredDate, setHoveredDate] = React.useState<null | string>(null)

  const handleHoveredElementChange = React.useCallback(date => setHoveredDate(date), [])

  const headers = React.useMemo(
    () =>
      stats.details.map((statsDetails: BookingStatsDetails) => (
        <ReservationStatsTableHeader
          hoveredDate={hoveredDate}
          onHoverChange={handleHoveredElementChange}
          key={statsDetails.date}
          date={dateHelper.parseISODate(statsDetails.date)}
        />
      )),
    [stats.details, hoveredDate],
  )

  const renderStatsCellContent = (content: StatsCellContent<BookingStatsDetails>) => {
    const data = (
      <>
        {asDecimal(content.statsDetails[content.element]).toFixed(0)}
        {content.options?.tableCellOption?.unit}
      </>
    )

    return content.options?.tableCellOption?.wrapper?.(content) || data
  }

  const renderStatsCellSummary = (content: StatsCellSummary<BookingStatsDetails>) => {
    const data = (
      <div>
        {content.summaryDetails[content.element].toString()}
        {content.options?.tableRowSummaryOption?.unit}
      </div>
    )

    return content.options?.tableRowSummaryOption?.wrapper?.(content) || data
  }

  const getTableCells = React.useCallback(
    (element: keyof BookingStatsDetails, options?: StatsTableOptions<BookingStatsDetails>) =>
      stats.details.reduce(
        (cum: ReservationStatsTableCells, statsDetails: BookingStatsDetails) => ({
          data: [
            ...cum.data,
            <ReservationStatsTableDataCell
              hoveredDate={hoveredDate === statsDetails.date ? hoveredDate : null}
              onHoverChange={handleHoveredElementChange}
              date={statsDetails.date}
              key={statsDetails.date}
              className={options?.tableCellOption?.className}
            >
              {renderStatsCellContent({ element, statsDetails, options })}
            </ReservationStatsTableDataCell>,
          ],
          summary: {
            value: stats.summary[element].toString(),
            component: (
              <ReservationStatsTableSummaryRow className={options?.tableRowSummaryOption?.className}>
                {renderStatsCellSummary({ element, summaryDetails: stats.summary, options })}
              </ReservationStatsTableSummaryRow>
            ),
          },
        }),
        { data: [], summary: { value: '0', component: null } },
      ),
    [stats, hoveredDate],
  )

  const days = React.useMemo(
    () => stats.details.map((statsDetails: BookingStatsDetails) => statsDetails.date),
    [stats.details],
  )

  return (
    <CommonStatsTable>
      <thead>
        <tr>
          <th className="bg-white reservation-stats__col__first" />
          {headers}
          <td className="bg-white reservation-stats__col__last" />
        </tr>
      </thead>
      <tbody>
        <ReservationStatsTableExpandableRow
          title="Zakwaterowanie"
          days={days}
          onHoveredElementChange={handleHoveredElementChange}
          hoveredDate={hoveredDate}
        >
          <ReservationStatsAccommodationRows getCells={getTableCells} />
        </ReservationStatsTableExpandableRow>
        <ReservationStatsTableExpandableRow
          title="Wyżywienie"
          days={days}
          onHoveredElementChange={handleHoveredElementChange}
          hoveredDate={hoveredDate}
        >
          <ReservationStatsFeedingRows getCells={getTableCells} />
        </ReservationStatsTableExpandableRow>
        <ReservationStatsTableExpandableRow
          title="Planowanie"
          days={days}
          onHoveredElementChange={handleHoveredElementChange}
          hoveredDate={hoveredDate}
        >
          <ReservationStatsPlanningRows getCells={getTableCells} />
        </ReservationStatsTableExpandableRow>
        <ReservationStatsTableExpandableRow
          title="Obłożenie"
          days={days}
          onHoveredElementChange={handleHoveredElementChange}
          hoveredDate={hoveredDate}
        >
          <ReservationStatsOccupancyRows getCells={getTableCells} />
        </ReservationStatsTableExpandableRow>
      </tbody>
    </CommonStatsTable>
  )
}
