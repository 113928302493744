import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { formatDate } from '@helpers/date-helper'
import { SaveButton } from '@hyper/button'
import { LoginAsLoginAs } from '@modules/payment-registers/payment-user-open-day-modal/login-as'
import { useForm } from 'react-hook-form'
import { PaymentUserOpenDayModalStartAmount } from '@modules/payment-registers/payment-user-open-day-modal/start-amount'
import { PaymentUserOpenDayModalReceptionWorkstation } from '@modules/payment-registers/payment-user-open-day-modal/reception-workstation'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useAppData } from '@components/hooks/use-app-data'
import { resortsMapSelector } from '@store/selectors/dashboard'
import { useAppDispatch, useAppSelector } from '@store/index'
import { useFormRequest } from '@components/hooks/use-api-request'
import { commonObjectPut } from '@store/actions/generic-actions'
import { AuthenticatedUser } from '@models/dashboard'
import { setAuthenticatedUser } from '@store/slices/app-slice'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { asDecimal } from '@helpers/utils'
import { PaymentDayUserReport } from '@models/payments'
import { Form } from '@hyper/forms/form'

export interface PaymentUserOpenDayFormInputs {
  start_amount: number
  reception_workstation: CustomReactSelectOption | undefined
}
interface Props extends BaseModalProps {
  paymentDayUserReport: PaymentDayUserReport
}

export const PaymentUserOpenDayModal: React.FC<Props> = ({ paymentDayUserReport, toggleIsVisible }) => {
  const { reception_workstations } = useAppData()
  const resortsMap = useAppSelector(resortsMapSelector)
  const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()
  const logout = useAppData().urls.account.logout

  const user = useAuthenticatedUser()

  const receptionWorkstationsOptions: CustomReactSelectOption[] = reception_workstations
    .filter(row => user.resort_ids.includes(row.resort_id) && resortsMap[row.resort_id])
    .map(row => ({
      value: row.id,
      label: `${row.name} (${resortsMap[row.resort_id].name})`,
    }))

  const methods = useForm<PaymentUserOpenDayFormInputs>({
    defaultValues: {
      start_amount: asDecimal(user.paymentDayUserReport?.start_amount || 0).toNumber(),
      reception_workstation: receptionWorkstationsOptions.find(row => row.value === user.receptionWorkstation?.id),
    },
  })

  const { isLoading, action: onSubmit } = useFormRequest(
    async (payload: PaymentUserOpenDayFormInputs) => {
      dispatch(
        setAuthenticatedUser(
          await commonObjectPut<AuthenticatedUser>(paymentDayUserReport.urls.open, {
            ...payload,
            reception_workstation: payload.reception_workstation?.value,
          }),
        ),
      )
      addSuccessMessage('Sukces', `Dzień ${formatDate(user.paymentDayUserReport?.day_start)} został otwarty!`)
      toggleIsVisible()
    },
    methods.setError,
    { showGlobalError: true },
  )

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader>Otwórz dzień ({formatDate(user.paymentDayUserReport?.created, 'dd.MM')})</ModalHeader>
      <ModalBody className="px-0">
        <LoginAsLoginAs />
        <div className="hr hr-grey my-2" />
        <PaymentUserOpenDayModalStartAmount />
        <div className="hr hr-grey my-2" />
        <PaymentUserOpenDayModalReceptionWorkstation receptionWorkstationsOptions={receptionWorkstationsOptions} />
      </ModalBody>
      <ModalFooter style={{ display: 'block' }}>
        <Row>
          <Col md={6} className="px-0">
            <a href={logout} className="btn mr-1 btn-light">
              Wyloguj
            </a>
          </Col>
          <Col md={6} className="text-right px-0">
            <SaveButton
              isSaving={isLoading}
              label="Otwórz dzień"
              className="btn btn-green"
              labelSaving="Otwieranie dnia"
            />
          </Col>
        </Row>
      </ModalFooter>
    </Form>
  )
}
