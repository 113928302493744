import * as React from 'react'
import classNames from 'classnames'

/**
 * Spinner
 */
export function Spinner({
  className = '',
  children = null,
  type = 'bordered',
  size = 'lg',
  color = 'primary',
  role = 'status',
}) {
  return (
    <div
      role={role}
      className={classNames(
        { 'spinner-border': type === 'bordered', 'spinner-grow': type === 'grow' },
        `text-${color}`,
        { [`avatar-${size}`]: size },
        className,
      )}
    >
      {children}
    </div>
  )
}

export default Spinner
