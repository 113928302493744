import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { FormInput } from '@hyper/forms'
import { GusButton } from '@components/gus-button'
import { CompanyDataFromGus } from '@models/sale'
import { ShopPOSWithParamsPaymentKindFormInputs } from '@modules/shop/pos/shop-pos-with-params'

export const ShopPurchasePaymentInvoice = (): JSX.Element => {
  const { getValues, setValue } = useFormContext<ShopPOSWithParamsPaymentKindFormInputs>()

  const handleCompanyDataSet = (companyData: CompanyDataFromGus) => {
    setValue('invoice_nip', companyData.tax_id)
    setValue('invoice_company', companyData.name)
    setValue('invoice_street', companyData.street_address)
    setValue('invoice_postcode', companyData.postal_code)
    setValue('invoice_city', companyData.city)
  }

  const getNip = () => getValues('invoice_nip')

  return (
    <div className="mb-3">
      <div className="d-flex">
        <FormInput label="NIP:" name="invoice_nip" colSize="7" colClassName="pl-0" formGroupClassName="mb-2" />
        <GusButton
          className="col-5 pr-0 pt-3"
          loaderClassName="pt-1"
          setCompanyData={handleCompanyDataSet}
          getNip={getNip}
          button={<button className="btn btn-secondary w-100">Pobierz dane z GUS</button>}
        />
      </div>
      <FormInput label="Nazwa firmy:" name="invoice_company" colClassName="px-0" formGroupClassName="mb-2" />
      <FormInput label="Adres firmy:" name="invoice_street" colClassName="px-0" formGroupClassName="mb-2" />
      <div className="d-flex">
        <FormInput
          label="Kod pocztowy:"
          name="invoice_postcode"
          colSize={5}
          colClassName="pl-0"
          formGroupClassName="mb-2"
        />
        <FormInput label="Miejscowość:" name="invoice_city" colSize={7} colClassName="pr-0" formGroupClassName="mb-2" />
      </div>
      <div className="hr hr-grey-2 mt-2" />
    </div>
  )
}
