import * as React from 'react'
import { Button, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { SaveButton } from '@hyper/button'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { useForm } from 'react-hook-form'
import { endOfMonth, startOfMonth, startOfToday } from 'date-fns'
import { createReportTask } from '@store/actions/report-tasks-actions'
import { FormInput } from '@hyper/forms'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { BaseModalProps } from '@components/modals/types'
import { useAppData } from '@components/hooks/use-app-data'
import { Form } from '@hyper/forms/form'
import { PaymentSource } from '@models/dashboard'
import { useCafeteriaAppData } from '@modules/cafeteria/hooks/use-cafeteria-app-data'

interface ReportsGeneratorBookingPaymentProps extends BaseModalProps {
  kind?: 'normal' | 'climatic'
}

interface FormInputs {
  created_at_after: Date | null
  created_at_before: Date | null
}

export const ReportsGeneratorBookingPayment: React.FC<ReportsGeneratorBookingPaymentProps> = ({
  toggleIsVisible,
  kind,
}) => {
  const reportUrl = useAppSelector(
    (state: RootState) => state.appState.appData.urls.reports.report_task_booking_payment,
  )
  const { payment_sources } = useAppData()
  const { cafeteria_operators } = useCafeteriaAppData()
  const paymentSources = payment_sources.filter((row: PaymentSource) => row.id !== 'cafeteria')

  const dispatch = useAppDispatch()
  const user = useAuthenticatedUser()

  const methods = useForm<FormInputs>({
    mode: 'all',
    defaultValues: {
      created_at_after: startOfMonth(startOfToday()),
      created_at_before: endOfMonth(startOfToday()),
    },
  })

  const { isLoading, action: onSubmit } = useReportFormRequest(async (payload: FormInputs) => {
    const reportTask = await dispatch(
      createReportTask([
        reportUrl,
        {
          kind: kind || 'normal',
          ...payload,
        },
      ]),
    )
    toggleIsVisible()
    return reportTask
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Raport płatności</ModalHeader>
      <ModalBody>
        <Row>
          <DateFromToInput label="Data dodania" startDateName="created_at_after" endDateName="created_at_before" />
          <FormInput colSize={6} name="resort" label="Ośrodek" type="select">
            <option value="">Wszystkie</option>
            {user.resorts.map(resort => (
              <option value={resort.id} key={resort.id}>
                {resort.name}
              </option>
            ))}
          </FormInput>
          <FormInput colSize={6} name="source" label="Źródło " type="select">
            <option value="">Wszystkie</option>
            {paymentSources.map(payment => (
              <option key={payment.id} value={payment.id}>
                {payment.name}
              </option>
            ))}
            <option value="cafeteria">Kafeteria</option>
            {cafeteria_operators.map(row => (
              <option key={row.id} value={`cafeteria__${row.key}`}>
                {' '}
                - {row.name}
              </option>
            ))}
          </FormInput>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" label="Zleć raport" labelSaving="Zlecanie.." isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
