import * as React from 'react'
import { Button } from 'reactstrap'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { FeedingDiscount } from '@modules/reception/checkin/step-feeding/new-feeding/models'
import { Form } from '@hyper/forms/form'
import { SaveButton } from '@hyper/button'
import { FormInput } from '@hyper/forms'
import { useForm } from 'react-hook-form'
import { ReceptionBookingDetails } from '@models/reception'
import { useNewFeedingCalculationsFetch } from '@modules/reception/checkin/step-feeding/new-feeding/use-new-feeding-calculations-fetch'
import { createDefaultNewFeedingFormValues } from '@modules/reception/checkin/step-feeding/new-feeding/utils'

interface FormInputs {
  reason: string
}

interface Props {
  onRemove: (discount: FeedingDiscount) => void
  onFeedingChange: (feedingValues) => void
  onClose: () => void
  discount: FeedingDiscount
  bookingDetails: ReceptionBookingDetails
}

export const ReceptionBookingNewFeedingDiscountRemoveConfirmation = ({
  onRemove,
  discount,
  onClose,
  bookingDetails,
  onFeedingChange,
}: Props): React.ReactElement => {
  const methods = useForm<FormInputs>()

  const { fetchCalculations } = useNewFeedingCalculationsFetch()

  const { action: handleRemove, isLoading: isDeleting } = useApiRequest(async (payload: FormInputs) => {
    await commonObjectDelete(discount.urls.details, payload)

    await fetchCalculations(bookingDetails, response =>
      onFeedingChange(createDefaultNewFeedingFormValues(response.details)),
    )

    onRemove(discount)
    onClose()
  })

  return (
    <Form methods={methods} onSubmit={methods.handleSubmit(handleRemove)}>
      <div className="d-flex flex-column align-items-center">
        <Button className="d-block w-100" color="light" onClick={onClose} type="button">
          Pozostaw rabatowanie
        </Button>
        <SaveButton
          className="d-block w-100 mt-2 btn bg-transparent border-white text-white shadow-none"
          isSaving={isDeleting}
          label="Usuń mimo to"
          labelSaving="Usuwanie..."
        />

        <FormInput
          name="reason"
          type="textarea"
          inputProps={{ rows: 2, className: 'resize-none font-12 mt-2' }}
          placeholder="Proszę podać powód"
          colClassName="px-0"
        />
      </div>
    </Form>
  )
}
