import * as React from 'react'

import { TableHeaderValue } from '@components/table/table'

export function useFilteredRows<T>(rows: T[], headerValues: TableHeaderValue<T>[], search: string): T[] {
  const searchFields = React.useMemo(
    () => headerValues.filter(row => row.search).map(row => row.sortField),
    [headerValues],
  )

  const query = React.useMemo(() => search.trim().toLocaleLowerCase(), [search])

  return React.useMemo(
    () =>
      query
        ? rows.filter(
            (row: T) => !!searchFields.find(field => (row[String(field)] || '').toLowerCase().includes(query)),
          )
        : rows,
    [query, headerValues, rows],
  )
}
