import * as React from 'react'
import { useFormRequest } from '@components/hooks/use-api-request'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useAppDispatch } from '@store/index'
import { CardBody, Col, CustomInput, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { FormInput } from '@hyper/forms'
import { PackageWholesaleDetails } from '@modules/package-wholesale/models'
import { updatePackageWholesale } from '@store/actions/package-wholesale-actions'
import { extractInnerRef } from '@helpers/forms'
import { parseISO, startOfToday, startOfYear } from 'date-fns'
import DatePickerInput from '@components/date/date-picker-input'
import { formatDate } from '@helpers/date-helper'
import { usePackageWholesaleProductList } from '@modules/package-wholesale/use-product-list'
import { usePackageWholesaleProductData } from '@modules/package-wholesale/use-product-data'
import { FormSelect } from '@hyper/forms/form-select'
import { useGroupedSellers, useSellersOptions } from '@components/hooks/use-grouped-sellers'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { PackageWholesaleSlugTooltip } from '@modules/package-wholesale/create/slug'
import { FileFormElementRow } from '@modules/promotions/common/file-form-element-row'

const appendLeaflet = (formData, field, leaflet, leafletUrl) => {
  if (leaflet) formData.append(field, leaflet || '')

  if (!leafletUrl && !leaflet) formData.append(field, '')
}

interface Props {
  packageWholesaleDetails: PackageWholesaleDetails
  toggleEdit: () => void
}

export interface PackageWholesaleDetailsBaseFormInputs {
  products: number[]
  expire_after: Date
  discount: number | null | string
  password: string

  leaflet_subscription_url: string | null
  leaflet_subscription_brown_url: string | null
  leaflet_subscription_silver_url: string | null
  leaflet_subscription_gold_url: string | null

  leaflet_subscription: File | undefined
  leaflet_subscription_brown: File | undefined
  leaflet_subscription_silver: File | undefined
  leaflet_subscription_gold: File | undefined

  leaflet_gastro_card_url: string | null
  leaflet_gastro_card: File | undefined

  leaflet_gastro_card_boost_url: string | null
  leaflet_gastro_card_boost: File | undefined

  leaflet_gastro_card_2024_url: string | null
  leaflet_gastro_card_2024: File | undefined

  leaflet_gastro_card_2025_url: string | null
  leaflet_gastro_card_2025: File | undefined

  sellers: CustomReactSelectOption[]
  slug: string
}

export const PackageWholesaleDetailsBaseInformationForm: React.FC<Props> = ({
  packageWholesaleDetails,
  toggleEdit,
}) => {
  const dispatch = useAppDispatch()
  const sellers = useGroupedSellers([])
  const sellersList = useSellersOptions()

  const user = useAuthenticatedUser()

  const methods = useForm<PackageWholesaleDetailsBaseFormInputs>({
    defaultValues: {
      expire_after: parseISO(packageWholesaleDetails.expire_after),
      products: packageWholesaleDetails.products,
      discount: packageWholesaleDetails.discount,
      password: packageWholesaleDetails.password ?? '',

      leaflet_subscription_url: packageWholesaleDetails.leaflet_subscription,
      leaflet_subscription_brown_url: packageWholesaleDetails.leaflet_subscription_brown,
      leaflet_subscription_silver_url: packageWholesaleDetails.leaflet_subscription_silver,
      leaflet_subscription_gold_url: packageWholesaleDetails.leaflet_subscription_gold,

      leaflet_subscription: undefined,
      leaflet_subscription_brown: undefined,
      leaflet_subscription_silver: undefined,
      leaflet_subscription_gold: undefined,

      leaflet_gastro_card_url: packageWholesaleDetails.leaflet_gastro_card,
      leaflet_gastro_card: undefined,

      leaflet_gastro_card_boost_url: packageWholesaleDetails.leaflet_gastro_card_boost,
      leaflet_gastro_card_boost: undefined,

      leaflet_gastro_card_2024_url: packageWholesaleDetails.leaflet_gastro_card_2024,
      leaflet_gastro_card_2024: undefined,

      leaflet_gastro_card_2025_url: packageWholesaleDetails.leaflet_gastro_card_2025,
      leaflet_gastro_card_2025: undefined,

      slug: packageWholesaleDetails.slug,
      sellers: sellersList.filter(row => packageWholesaleDetails.sellers_ids.includes(row.value)),
    },
  })

  const { control, setError, register } = methods

  const { addSuccessNotification } = useNotificationHook()

  const { isLoading, action: handleSubmit } = useFormRequest(async () => {
    const payload = methods.getValues()

    const formData = new FormData()
    payload.sellers.forEach(value => {
      formData.append('sellers', value.value)
    })

    payload.products.forEach(productId => {
      formData.append('products', String(productId))
    })

    formData.append('expire_after', formatDate(payload.expire_after))
    formData.append('discount', String(payload.discount))
    formData.append('password', String(payload.password))
    formData.append('slug', payload.slug ? String(payload.slug) : '')

    appendLeaflet(formData, 'leaflet_subscription', payload.leaflet_subscription, payload.leaflet_subscription_url)
    appendLeaflet(
      formData,
      'leaflet_subscription_brown',
      payload.leaflet_subscription_brown,
      payload.leaflet_subscription_brown_url,
    )

    appendLeaflet(
      formData,
      'leaflet_subscription_silver',
      payload.leaflet_subscription_silver,
      payload.leaflet_subscription_silver_url,
    )

    appendLeaflet(
      formData,
      'leaflet_subscription_gold',
      payload.leaflet_subscription_gold,
      payload.leaflet_subscription_gold_url,
    )

    appendLeaflet(formData, 'leaflet_gastro_card', payload.leaflet_gastro_card, payload.leaflet_gastro_card_url)

    appendLeaflet(
      formData,
      'leaflet_gastro_card_boost',
      payload.leaflet_gastro_card_boost,
      payload.leaflet_gastro_card_boost_url,
    )

    await dispatch(updatePackageWholesale([packageWholesaleDetails.urls.details, formData]))
    addSuccessNotification('Zmiany zostały zapisane')
    toggleEdit()
  }, setError)

  const selectedProducts = useWatch({ control, name: 'products' })

  const { products, isProductDisabled, isProductSelected } = usePackageWholesaleProductList(control)

  const { kinds, subscriptionKinds, getSubscriptionLeafletData } = usePackageWholesaleProductData(
    selectedProducts,
    products,
  )

  return (
    <CardBody>
      <FormProvider {...methods}>
        <h4 className="mt-0 text-secondary mb-3">Szczegóły pakietu</h4>
        <Row className="mt-2 align-items-center">
          <Col md={4}>
            <label>Data ważności</label>
          </Col>
          <Col md={8} className="px-0">
            <DatePickerInput minDate={startOfYear(startOfToday())} name="expire_after" />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md={4}>
            <label>Produkty</label>
          </Col>
          <Col md={8} className="px-0">
            {products.map(product => (
              <div className="mb-2" key={product.id}>
                <CustomInput
                  type="checkbox"
                  id={`product-${product.id}`}
                  label={<span className="text-semi-strong cursor-pointer">{product.name}</span>}
                  value={product.id}
                  defaultChecked={isProductSelected(product)}
                  disabled={isProductDisabled(product)}
                  {...extractInnerRef(register('products'))}
                />
              </div>
            ))}
          </Col>
        </Row>
        <Row className="mt-2">
          {subscriptionKinds.map(kind => (
            <FileFormElementRow
              label={getSubscriptionLeafletData(kind).label}
              inputName={getSubscriptionLeafletData(kind).inputName}
              key={kind.subscription_kind}
              labelClassName="col-4"
              contentClassName="col-8"
            />
          ))}

          {kinds.includes('gastro_card') && (
            <FileFormElementRow
              inputName="leaflet_gastro_card"
              label="Ulotka podwójnej radości"
              labelClassName="col-4"
              contentClassName="col-8"
            />
          )}

          {kinds.includes('gastro_card_boost') && (
            <FileFormElementRow
              inputName="leaflet_gastro_card_boost"
              label="Ulotka doładowania smaku"
              labelClassName="col-4"
              contentClassName="col-8"
            />
          )}
        </Row>
        {kinds.includes('subscription') && (
          <Row className="mt-2">
            <FormInput
              type="number"
              label="Rabat"
              name="discount"
              max={100}
              min={0}
              labelProps={{ className: 'pl-0 col-4' }}
              formGroupClassName="d-flex align-items-center pl-0"
              afterInput={<span className="ml-1">%</span>}
              inputProps={{ className: 'col-4' }}
            />
          </Row>
        )}
        <Row className="mt-2">
          <FormInput
            label="Hasło"
            name="password"
            labelProps={{ className: 'pl-0 col-4' }}
            formGroupClassName="d-flex align-items-center pl-0"
            inputProps={{
              className: 'col-4',
              disabled: !user.hasPerm(UserPermission.PackageWholesaleCanSetPassword),
            }}
          />
        </Row>
        <Row className="mt-2">
          <FormInput
            label={
              <span>
                Przyjazny link <PackageWholesaleSlugTooltip />
              </span>
            }
            name="slug"
            labelProps={{ className: 'pl-0 col-4' }}
            formGroupClassName="d-flex align-items-center pl-0 flex-wrap"
            inputProps={{ className: 'col-8' }}
          />
        </Row>
        <FormSelect
          options={sellers}
          name="sellers"
          selectProps={{ isSelectMulti: true, isClearable: true, className: 'col-8 px-0' }}
          label="Sprzedawca"
          labelProps={{ className: 'col-4 pl-0' }}
          formPlainProps={{ colClassName: 'px-0', formGroupClassName: 'd-flex align-items-center' }}
        />

        <Row>
          <Col md={12} className="text-right px-0">
            <button type="button" className="btn btn-light mr-1" onClick={toggleEdit}>
              Anuluj
            </button>
            <SaveButton isSaving={isLoading} label="Zapisz dane" className="btn btn-green" onClick={handleSubmit} />
          </Col>
        </Row>
      </FormProvider>
    </CardBody>
  )
}
