import * as React from 'react'
import { CleaningOrder } from '@modules/housekeeping/models'
import classNames from 'classnames'
import { useOrderPriorityChange } from '@modules/housekeeping/cleaning-orders/to-order/actions/use-order-priority-change'
import Spinner from '@hyper/spinner'

interface Props {
  cleaningOrder: CleaningOrder
  isCompact?: boolean
}

export const HousekeepingCleaningOrderRowActionsSetPriority: React.FC<Props> = ({ cleaningOrder, isCompact }) => {
  const { isLoading, changeOrderPriority } = useOrderPriorityChange()

  const handleOrderPriorityChange = async () => {
    await changeOrderPriority(cleaningOrder, cleaningOrder.priority === 0 ? 1 : 0)
  }

  return (
    <button
      disabled={isLoading}
      className={classNames(
        'border d-inline-flex align-items-center justify-content-center py-1 px-2 rounded mr-1 bg-transparent btn',
        { 'text-danger border-danger': !!cleaningOrder.priority },
        { 'text-muted border-muted': !cleaningOrder.priority },
        isCompact ? 'btn-sm' : 'btn-tall',
      )}
      onClick={handleOrderPriorityChange}
    >
      {isLoading ? (
        <Spinner size={isCompact ? '3xs' : 'xxs'} color="muted" />
      ) : (
        <i className={classNames('uil-rocket lh-0', isCompact ? 'font-14' : 'font-16')} />
      )}
    </button>
  )
}
