import { FloorHeatingDetailsHistoryFilterParams } from '@modules/floor-heating/details/history/history-filters'
import { useTableFilters } from '@components/table/use-table-filters'
import { useFloorHeatingDetails } from '@modules/floor-heating/details/use-floor-heating-details'

export const useFloorHeatingHistoryData = (
  defaultFilters: FloorHeatingDetailsHistoryFilterParams,
  withPaginator: boolean,
  apartmentId: string | undefined,
) => {
  const { fetchMeasurements, measurements } = useFloorHeatingDetails(apartmentId, withPaginator)

  const { isLoading, filters, setFilters } = useTableFilters<FloorHeatingDetailsHistoryFilterParams>({
    defaultFilters,
    action: fetchMeasurements,
  })

  return {
    isLoading,
    measurements,
    setFilters,
    filters,
  }
}
