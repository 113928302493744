import * as React from 'react'
import { Button } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'

export const RodoAgreementsAcquiringPointsAddButton = (): React.ReactNode => {
  // const user = useAuthenticatedUser()

  const [showGastroCouponDialog] = useModal('RodoAgreementsAcquiringPointCreateModal')

  // if (!user.hasPerm(false)) return null

  return (
    <Button
      onClick={showGastroCouponDialog}
      color="green-dark"
      type="button"
      className="ml-auto btn-tall d-block mt-label"
    >
      <i className="uil-plus mr-1 font-14" />
      Dodaj formularz
    </Button>
  )
}
