import { ShopProduct } from '@models/shop'
import { ShopParkingParams } from '@modules/shop/pos/product-selection/product-configuration-modals/shop-parking-configuration-modal'

export interface ShopProductPayload {
  amount: number
  product: number
  discount: number
  params: ShopParkingParams | null
}

export interface EnhancedShopProduct extends ShopProduct {
  listId: string
  amount: number
  params: ShopParkingParams | null
}

export interface ShopTicket {
  value: number
  id: string
}

export enum PaymentMethods {
  Cash = 'cash',
  Card = 'card',
  Wallet = 'wallet',
  Band = 'band',
}
