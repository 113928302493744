import * as React from 'react'
import { PackageVipDetails } from '@modules/promotions/package-vip/models'
import { PackageVipCommon } from '@modules/promotions/package-vip/details/package-vip-common'
import { Card, CardBody } from 'reactstrap'
import { PackageVipActions } from '@modules/promotions/package-vip/details/package-vip-actions'

interface PackageVipDetailsSummaryProps {
  packageVipDetails: PackageVipDetails
}

export const PackageVipDetailsSummary: React.FC<PackageVipDetailsSummaryProps> = ({ packageVipDetails }) => (
  <Card>
    <CardBody>
      <PackageVipCommon packageVipDetails={packageVipDetails} />
      <PackageVipActions packageVipDetails={packageVipDetails} />
    </CardBody>
  </Card>
)
