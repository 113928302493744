import * as React from 'react'
import { ReceptionBooking, ReceptionBookingClientWallet } from '@models/reception'
import { IconWithText } from '@components/icon-with-text'
import { Progress, UncontrolledTooltip } from 'reactstrap'
import { asDecimal, formatPercentage, formatPrice } from '@helpers/utils'
import { CopyToClipboardTooltip } from '@components/copy-to-clipboard-tooltip'
import { VirtualPosLink } from '@modules/reservations/details/common/virtual-pos-link'
import { ReservationVoucherDropdown } from '@modules/reservations/details/vouchers/reservation-voucher-dropdown'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useModal } from '@components/modals/use-modal'
import { GastroCardDetails } from '@models/promotions'
import { commonObjectGet } from '@store/actions/generic-actions'
import { IconWithLoader } from '@components/icon-with-loader'

interface Props {
  wallet: ReceptionBookingClientWallet
  booking: ReceptionBooking
}

export const ReservationVoucher = ({ wallet, booking }: Props): JSX.Element => {
  const [showVoucherDetailsModal] = useModal('GastroCardDetailsDialog', {}, { persist: true })
  const restToSpendPercentage = asDecimal(wallet.available_amount).mul(100).div(wallet.start_amount).toString()

  const { action: fetchGastroCardDetails, isLoading: isFetchingGastroCardDetails } = useApiRequest(
    async () => await commonObjectGet<GastroCardDetails>(wallet.urls.gastro_card_details),
  )

  const handleDetailsClick = async () => {
    const gastroCard = await fetchGastroCardDetails()
    showVoucherDetailsModal(null, { gastroCard })
  }

  return (
    <div className="row mb-3">
      <div className="col-4 d-flex">
        <div className="d-flex" id={`wallet-details-${wallet.code}`}>
          <i className="uil-tag-alt d-block mr-1 font-14" />
          <div>
            <span className="d-block fw-semi-bold">{wallet.name}</span>
            {wallet.code && (
              <CopyToClipboardTooltip value={wallet.code} tooltipId={wallet.code} className="px-0">
                <IconWithText
                  icon="uil-copy ml-1 font-14"
                  text={wallet.code}
                  wrapperClassNames="flex-row-reverse font-11"
                />
              </CopyToClipboardTooltip>
            )}
            <button
              className="text-default bg-transparent shadow-none border-0 p-0"
              onClick={handleDetailsClick}
              disabled={isFetchingGastroCardDetails}
            >
              <IconWithLoader icon="uil-eye font-14" isLoading={isFetchingGastroCardDetails} />
            </button>
          </div>
        </div>
      </div>
      <div className="col-8">
        <div className="mb-1 d-flex justify-content-between">
          <div>
            Pozostało do wykorzystania <strong>{formatPrice(wallet.available_amount)} </strong> z{' '}
            {formatPrice(wallet.start_amount)} ({formatPercentage(restToSpendPercentage)})
          </div>
          {!wallet.is_active && (
            <>
              <i className="uil-info-circle text-danger" id={`inactivity-state-${wallet.code}`} />
              <UncontrolledTooltip
                placement="top"
                target={`inactivity-state-${wallet.code}`}
                className="cursor-default"
              >
                <span className="font-11">Voucher jest nieaktywny</span>
              </UncontrolledTooltip>
            </>
          )}
        </div>
        <Progress value={restToSpendPercentage} color="secondary" className="mb-2" style={{ height: 10 }} />
        <div className="d-flex">
          <div className="d-flex">
            <IconWithText
              icon="uil-receipt font-16"
              textClass="font-11"
              text="historia zakupów"
              wrapperClassNames="mr-3"
            />
            {wallet.is_active && <VirtualPosLink booking={booking} />}
          </div>
          <ReservationVoucherDropdown wallet={wallet} />
        </div>
      </div>
    </div>
  )
}
