import * as React from 'react'
import { RodoAgreement } from '@modules/crm/rodo-agreements/models'
import { IconWithText } from '@components/icon-with-text'
import classNames from 'classnames'
import { useModal } from '@components/modals/use-modal'

interface Props {
  rodoAgreement: RodoAgreement
  children: React.ReactNode
  nameClass: string
  rowClassName: string
}

export const RodoAgreementsListRow = ({ rodoAgreement, children, nameClass, rowClassName }: Props): JSX.Element => {
  const [showDetailsModal] = useModal('RodoAgreementsModal', { rodoAgreement })

  return (
    <tr className={rowClassName}>
      <td>
        <strong
          className={classNames('d-block font-15', nameClass)}
        >{`${rodoAgreement.first_name} ${rodoAgreement.last_name}`}</strong>
        <span className="font-12">{rodoAgreement.rodo_acquiring_point_name}</span>
      </td>
      <AgreementStatusCell
        isConfirmed={!!rodoAgreement.email_confirm_date}
        title={rodoAgreement.email_confirm_date ? 'Potwierdzony e-mail' : 'Brak potwierdzenia e-mail'}
        text={rodoAgreement.email}
      />
      <AgreementStatusCell
        isConfirmed={!!rodoAgreement.phone_confirm_date}
        title={rodoAgreement.phone_confirm_date ? 'Potwierdzony SMS' : 'Brak potwierdzenia SMS'}
        text={rodoAgreement.phone}
      />
      <td>
        <i className="uil-eye font-16 cursor-pointer" onClick={showDetailsModal} />
      </td>
      {children}
    </tr>
  )
}

const AgreementStatusCell = ({ title, text, isConfirmed }) => (
  <td>
    <IconWithText
      icon={classNames(
        'align-self-start',
        isConfirmed ? 'font-20 lh-0 uil-check text-green-dark' : 'uil-hourglass text-dark',
      )}
      text={
        <span>
          <strong className={classNames('d-block', isConfirmed ? 'text-green-dark' : 'text-dark')}>{title}</strong>
          <div className="font-12">{text}</div>
        </span>
      }
    />
  </td>
)
