import * as React from 'react'
import { Row } from 'reactstrap'
import { ReceptionBookingClientWallet } from '@models/reception'
import { StepVerificationAddedVouchersRow } from '@modules/reception/checkin/step-verification/step-verification-voucher/step-verification-added-vouchers-row'

interface Props {
  clientWallets: ReceptionBookingClientWallet[]
}

export const StepVerificationAddedVouchers: React.FC<Props> = ({ clientWallets }) => (
  <Row>
    {clientWallets.map(voucher => (
      <StepVerificationAddedVouchersRow key={voucher.id} voucher={voucher} />
    ))}
  </Row>
)
