import * as React from 'react'
import { PageTitle } from '@hyper/page-title'
import { useDocumentTitle } from '@helpers/utils'
import { Card, CardBody } from 'reactstrap'
import { useAppDispatch, useAppSelector } from '@store/index'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useDebounce } from 'rooks'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { getRentalProducts } from '@store/actions/rental-warehouse-actions'
import { RentalProductFiltersParams } from '@modules/rental/models'
import { RentalProductsFilters } from '@modules/rental/products/rental-products-filters'
import { RentalProductsTable } from '@modules/rental/products/rental-products-table'
import { selectRentalProducts } from '@store/slices/rental-slice'
import { RentalProductWebSocketHandler } from '@modules/rental/web-socket-handler'

const pageTitle = 'Lista produktów'

const defaultFilters: RentalProductFiltersParams = {
  ordering: '-created',
  page: 1,
  page_size: 10,
  search: '',
  status: null,
  category: null,
  warehouse: null,
}

export const RentalProductsView: React.FC = () => {
  const [filters, setFilters] = React.useState<RentalProductFiltersParams>(defaultFilters)

  const dispatch = useAppDispatch()
  useDocumentTitle(pageTitle)
  useMenuActive(NavigationPath.RentalProducts)

  const products = useAppSelector(selectRentalProducts)

  const { isLoading, action: fetchProducts } = useApiRequest(
    async filters => await dispatch(getRentalProducts(filters)),
  )

  const fetchProductsDebounced = React.useCallback(useDebounce(fetchProducts, 300), [])

  React.useEffect(() => {
    fetchProductsDebounced(filters)
  }, [filters])

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitle} />
      <RentalProductWebSocketHandler />
      <Card>
        <CardBody>
          <RentalProductsFilters filters={filters} setFilters={setFilters} defaultFilters={defaultFilters} />
          <RentalProductsTable isLoading={isLoading} filters={filters} setFilters={setFilters} products={products} />
        </CardBody>
      </Card>
    </>
  )
}
