import * as React from 'react'
import { useForm } from 'react-hook-form'
import { extractInnerRef } from '@helpers/forms'
import { BookingCar, ReceptionBookingDetails, ReceptionBookingGroup } from '@models/reception'
import { useAppDispatch } from '@store/index'
import { commonObjectDelete, commonObjectPost, commonObjectPut } from '@store/actions/generic-actions'
import { updateReceptionBooking } from '@store/actions/reception-actions'
import { handleEnterPress } from '@helpers/utils'
import { FormPlainError } from '@hyper/forms'
import { InputWithLoader } from '@components/input-with-loader'
import { setErrorForDynamicKey, useHandleAxiosFormErrors } from '@helpers/handle-axios-errors'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApiRequest } from '@components/hooks/use-api-request'
import { setReceptionBookingDetails } from '@store/slices/reception-slice'

interface StepGuestsCarsListRowProps {
  car: BookingCar
  index: number
  booking: ReceptionBookingDetails
  group: ReceptionBookingGroup
}

interface FormInputs {
  [key: string]: string
}

export const StepGuestsCarsListRow: React.FC<StepGuestsCarsListRowProps> = ({ booking, car, index, group }) => {
  const registerNumberKey = `register_number_${group.id}`

  const dispatch = useAppDispatch()
  const handleEditMode = () => {
    dispatch(
      setReceptionBookingDetails({
        ...booking,
        cars: [...booking.cars.map(row => (row.id === car.id ? { ...car, editMode: !car.editMode } : row))].filter(
          row => row.register_number,
        ),
      }),
    )
  }
  const handleAxiosFormErrors = useHandleAxiosFormErrors()
  const { addSuccessNotification } = useNotificationHook()

  const {
    register,
    getValues,
    setError,
    reset,
    formState: { errors },
  } = useForm<FormInputs>({
    defaultValues: {
      [registerNumberKey]: car.register_number,
    },
    shouldUnregister: true,
  })

  const { isLoading: isLoadingDelete, action: handleDelete } = useApiRequest(async () => {
    if (car.urls.details) {
      const response = await commonObjectDelete<ReceptionBookingDetails>(car.urls.details)
      await dispatch(setReceptionBookingDetails(response))
      await dispatch(updateReceptionBooking(response))
      addSuccessNotification('Numer rejestracyjny został skasowany!')
    } else {
      dispatch(
        setReceptionBookingDetails({
          ...booking,
          cars: booking.cars.filter(row => !row.editMode),
        }),
      )
    }
  })

  const { isLoading: isLoadingSave, action: handleSave } = useApiRequest(async () => {
    const payload = {
      ...car,
      register_number: getValues(registerNumberKey),
    }

    try {
      if (car.urls.details) {
        const result = await commonObjectPut<ReceptionBookingDetails>(car.urls.details, payload)
        await dispatch(setReceptionBookingDetails(result))
        await dispatch(updateReceptionBooking(result))

        addSuccessNotification('Numer rejestracyjny został zmieniony!')
      } else {
        const result = await commonObjectPost<ReceptionBookingDetails>(booking.urls.car, payload)
        await dispatch(setReceptionBookingDetails(result))
        await dispatch(updateReceptionBooking(result))
        addSuccessNotification('Numer rejestracyjny został dodany!')
      }
      reset()
    } catch (error) {
      handleAxiosFormErrors(error, setErrorForDynamicKey(registerNumberKey, setError), { showGlobalError: false })
    }
  })

  return (
    <div className="d-flex justify-content-between align-items-center w-100 py-1">
      {!car.editMode ? (
        <strong>
          {index}. {car.register_number || 'brak'}
        </strong>
      ) : (
        <div className="d-flex flex-column">
          <InputWithLoader
            isLoadingVisible={isLoadingSave || isLoadingDelete}
            type="text"
            style={{ width: 200 }}
            placeholder="Podaj numer rejestracyjny"
            role="car-number-input"
            onKeyDown={handleEnterPress.bind(handleSave)}
            className="form-control form-control-sm"
            {...extractInnerRef(register(registerNumberKey))}
          />
          <FormPlainError errors={errors} name={registerNumberKey} />
        </div>
      )}
      <div className="d-flex align-items-center">
        {!car.editMode ? (
          <>
            <i
              className="uil-edit-alt font-16 text-muted mr-2 cursor-pointer"
              title="Edytuj"
              onClick={handleEditMode}
              role="cars-edit"
            />
            <i
              className="uil-trash-alt font-16 text-muted cursor-pointer"
              title="Skasuj"
              onClick={handleDelete}
              role="cars-delete"
            />
          </>
        ) : (
          <>
            <i
              className="uil-check font-16 cursor-pointer text-success mr-2"
              role="car-save-button"
              onClick={handleSave}
            />
            <i
              className="uil-times font-16 cursor-pointer text-danger"
              role="car-delete-button"
              onClick={handleEditMode}
            />
          </>
        )}
      </div>
    </div>
  )
}
