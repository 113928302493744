import * as React from 'react'
import { endOfMonth, startOfMonth, startOfToday } from 'date-fns'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import {
  getHousekeepingBeddingOrders,
  getHousekeepingCleaningOrders,
  getHousekeepingCommonTask,
  getHousekeepingImprovementOrders,
} from '@store/actions/housekeeping-actions'
import {
  HousekeepingBeddingOrderType,
  HousekeepingCleaningOrderType,
  HousekeepingCommonTaskType,
  HousekeepingImprovementOrderType,
} from '@modules/housekeeping/models'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { HousekeepingArchiveFilters } from '@modules/housekeeping/cleaning-orders/archive/filters'
import { HousekeepingCleaningOrdersArchiveTable } from '@modules/housekeeping/cleaning-orders/archive/table'
import {
  searchBeddingOrders,
  searchClearingOrders,
  searchCommonTaskOrders,
  searchImprovementOrders,
} from '@modules/housekeeping/cleaning-orders/common/filtering'
import { useApiRequest } from '@components/hooks/use-api-request'
import {
  beddingOrdersSelector,
  cleaningOrdersSelector,
  commonTasksSelector,
  improvementOrdersSelector,
} from '@store/slices/housekeeping-slice'

interface HousekeepingCleaningOrdersArchiveProps {
  resortId: number
}

export interface HousekeepingArchiveFiltersParams {
  date_after: Date
  date_before: Date
  search: string
}

export const HousekeepingCleaningOrdersArchive: React.FC<HousekeepingCleaningOrdersArchiveProps> = ({ resortId }) => {
  const defaultFilters: HousekeepingArchiveFiltersParams = {
    date_after: startOfMonth(startOfToday()),
    date_before: endOfMonth(startOfToday()),
    search: '',
  }
  const [filters, setFilters] = React.useState<HousekeepingArchiveFiltersParams>(defaultFilters)

  const dispatch = useAppDispatch()
  const { isLoading, action: fetch } = useApiRequest(async () => {
    await dispatch(
      getHousekeepingCleaningOrders([
        {
          resort: resortId,
          date_after: filters.date_after,
          date_before: filters.date_before,
        },
        HousekeepingCleaningOrderType.ARCHIVE,
      ]),
    )
    await dispatch(
      getHousekeepingBeddingOrders([
        {
          resort: resortId,
          date_after: filters.date_after,
          date_before: filters.date_before,
        },
        HousekeepingBeddingOrderType.ARCHIVED,
      ]),
    )
    await dispatch(
      getHousekeepingImprovementOrders([
        {
          resort: resortId,
          date_after: filters.date_after,
          date_before: filters.date_before,
        },
        HousekeepingImprovementOrderType.ARCHIVED,
      ]),
    )
    await dispatch(
      getHousekeepingCommonTask([
        {
          resort: resortId,
          date_after: filters.date_after,
          date_before: filters.date_before,
        },
        HousekeepingCommonTaskType.ARCHIVED,
      ]),
    )
  })

  React.useEffect(() => {
    fetch()
  }, [filters.date_after, filters.date_before, resortId])

  const cleaningOrders = useAppSelector(cleaningOrdersSelector)
  const commonTaskOrders = useAppSelector(commonTasksSelector)
  const improvementOrders = useAppSelector(improvementOrdersSelector)
  const beddingOrders = useAppSelector(beddingOrdersSelector)
  const workers = useAppSelector((state: RootState) => state.houseKeepingState.cleaningWorkers)

  const filteredCleaningOrders = searchClearingOrders(
    cleaningOrders.filter(row => row.status === 'ARCHIVED'),
    filters.search,
    workers,
  )
  const filteredBeddingOrders = searchBeddingOrders(
    beddingOrders.filter(row => row.status === 'ARCHIVED'),
    filters.search,
    workers,
    ['apartment.name', 'accepted_by', 'ordered_by'],
  )

  const filteredImprovementOrders = searchImprovementOrders(
    improvementOrders.filter(row => row.status === 'ARCHIVED'),
    filters.search,
    workers,
  )
  const filteredCommonTaskOrders = searchCommonTaskOrders(
    commonTaskOrders.filter(row => row.status === 'ARCHIVED'),
    filters.search,
  )

  return (
    <>
      <HousekeepingArchiveFilters filters={filters} setFilters={setFilters} defaultFilters={defaultFilters} />
      <LoaderPlaceholder content={!isLoading}>
        <HousekeepingCleaningOrdersArchiveTable
          cleaningOrders={filteredCleaningOrders}
          beddingOrders={filteredBeddingOrders}
          commonTaskOrders={filteredCommonTaskOrders}
          improvementOrders={filteredImprovementOrders}
        />
      </LoaderPlaceholder>
    </>
  )
}
