import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@store/index'
import { Promocode, PromocodeDetails, PromocodeEmailTemplate, PromocodeGroup, PromocodePrefix } from '@models/promocode'
import {
  deletePromocodeGroup,
  deletePromocodePrefixes,
  getPromocodeCodes,
  getPromocodeEmailTemplates,
  getPromocodeGroups,
  getPromocodePrefixes,
} from '@store/actions/promocode-actions'

interface PromocodesState {
  promocodePrefixes: EntityState<PromocodePrefix>
  promocodeGroups: EntityState<PromocodeGroup>
  promocodeCodes: EntityState<Promocode>
  emailTemplates: PromocodeEmailTemplate[]
}

const promocodePrefixesAdapter = createEntityAdapter<PromocodePrefix>()
const promocodeGroupsAdapter = createEntityAdapter<PromocodeGroup>()
const promocodeCodesAdapter = createEntityAdapter<Promocode>()

const initialState: PromocodesState = {
  promocodePrefixes: promocodePrefixesAdapter.getInitialState(),
  promocodeGroups: promocodeGroupsAdapter.getInitialState(),
  promocodeCodes: promocodeCodesAdapter.getInitialState(),
  emailTemplates: [],
}

export const promocodesSlice = createSlice({
  name: 'promocodes',
  initialState,
  reducers: {
    updatePromocodePrefixDetails(state, action: PayloadAction<PromocodePrefix>) {
      promocodePrefixesAdapter.upsertOne(state.promocodePrefixes, action.payload)
    },
    updatePromocodeGroupDetails(state, action: PayloadAction<PromocodeGroup>) {
      promocodeGroupsAdapter.upsertOne(state.promocodeGroups, action.payload)
    },
    updatePromocodeCodeDetails(state, action: PayloadAction<PromocodeDetails>) {
      promocodeCodesAdapter.upsertOne(state.promocodeCodes, action.payload)
    },
    deletePromocodes(state, action: PayloadAction<number[]>) {
      promocodeCodesAdapter.removeMany(state.promocodeCodes, action.payload)
    },
    clearPromocodes(state) {
      promocodeCodesAdapter.setAll(state.promocodeCodes, [])
    },
  },
  extraReducers: builder => {
    builder.addCase(getPromocodePrefixes.fulfilled, (state, action) => {
      promocodePrefixesAdapter.setAll(state.promocodePrefixes, action.payload)
    })
    builder.addCase(getPromocodeGroups.fulfilled, (state, action) => {
      promocodeGroupsAdapter.setAll(state.promocodeGroups, action.payload)
    })
    builder.addCase(getPromocodeCodes.fulfilled, (state, action) => {
      promocodeCodesAdapter.setAll(state.promocodeCodes, action.payload)
    })
    builder.addCase(deletePromocodePrefixes.fulfilled, (state, action) => {
      promocodePrefixesAdapter.removeMany(state.promocodePrefixes, action.payload)
    })
    builder.addCase(getPromocodeEmailTemplates.fulfilled, (state, action) => {
      state.emailTemplates = action.payload
    })
    builder.addCase(deletePromocodeGroup.fulfilled, (state, action) => {
      promocodeGroupsAdapter.removeOne(state.promocodeGroups, action.payload)
    })
  },
})

export const {
  updatePromocodePrefixDetails,
  updatePromocodeGroupDetails,
  updatePromocodeCodeDetails,
  deletePromocodes,
  clearPromocodes,
} = promocodesSlice.actions

export const { selectAll: promocodePrefixesSelector } = promocodePrefixesAdapter.getSelectors(
  (state: RootState) => state.promocodesState.promocodePrefixes,
)
export const { selectAll: promocodeGroupsSelector } = promocodeGroupsAdapter.getSelectors(
  (state: RootState) => state.promocodesState.promocodeGroups,
)
export const { selectAll: promocodeCodesSelector } = promocodeCodesAdapter.getSelectors(
  (state: RootState) => state.promocodesState.promocodeCodes,
)
export default promocodesSlice.reducer
