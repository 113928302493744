import * as React from 'react'
import * as z from 'zod'
import * as R from 'ramda'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useFormRequest } from '@components/hooks/use-api-request'
import { FormInput } from '@hyper/forms'
import { BaseModalProps } from '@components/modals/types'
import { Form } from '@hyper/forms/form'
import { addDays, endOfToday, isSameDay, max, startOfToday } from 'date-fns'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { EnhancedShopProduct } from '@modules/shop/pos/models'
import { FormInputPhone } from '@hyper/forms/form-input-phone'
import { parseISODate, toDefaultDateFormat } from '@helpers/date-helper'
import { FormFieldResolvers } from '@helpers/validators'
import { BookingAutocomplete, BookingAutocompleteResponse } from '@components/booking-autosuggest'

export interface ShopParkingParams {
  date_from: Date
  date_to: Date
  register_car_number: string
  phone: string
  booking_id: number | null
  reservation_number: string
}

interface Props extends BaseModalProps {
  onChange: (params: ShopParkingParams) => void
  product: EnhancedShopProduct
  resort: number
}

export const ShopParkingConfigurationModal: React.FC<Props> = ({ toggleIsVisible, onChange, product, resort }) => {
  const methods = useForm<ShopParkingParams>({
    defaultValues: {
      date_from: parseISODate(product.params?.date_from) ?? startOfToday(),
      date_to: parseISODate(product.params?.date_to) ?? endOfToday(),
      register_car_number: product.params?.register_car_number ?? '',
      booking_id: product.params?.booking_id ?? null,
      reservation_number: product.params?.reservation_number ?? '',
      phone: product.params?.phone ?? '',
    },
    resolver: zodResolver(schema),
  })

  const formValues = methods.watch()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: ShopParkingParams) => {
    onChange(payload)
    toggleIsVisible()
  }, methods.setError)

  const isFormFilled = Object.values(R.pick(['phone', 'register_car_number'], formValues)).every(value => !!value)

  const handleDataChange = (data: BookingAutocompleteResponse | null) => {
    methods.setValue('reservation_number', data?.reservation_number ?? '')
    methods.setValue('booking_id', data?.id ?? null)
    methods.setValue('phone', data?.phone ?? '')

    const now = new Date()
    methods.setValue('date_from', data ? max([parseISODate(data?.date_from) as Date, now]) : startOfToday())
    methods.setValue('date_to', data ? max([parseISODate(data?.date_to) as Date, now]) : endOfToday())
  }

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Konfiguracja parkingu</ModalHeader>
      <ModalBody className="pb-0 pt-1">
        <Row>
          <BookingAutocomplete
            fetchOnMount={!!product.params}
            isDisabled={!!product.params}
            resort={resort}
            type="present"
            placeholder="Wpisz imię i nazwisko, numer rezerwacji lub numer lokalu"
            name="reservation_number"
            label={
              <span>
                Rezerwacja <small className="font-12">(opcjonalnie dla gości wewnętrznych)</small>
              </span>
            }
            onDataChange={handleDataChange}
            mode="shop"
          />
          <hr className="w-100 mt-1" />
          <DateFromToInput
            label="Data dostępu"
            startDateName="date_from"
            endDateName="date_to"
            minDate={startOfToday()}
            colSize={12}
            isClearable={false}
            inputGroupClassName="mb-3"
          />
          <FormInput
            label="Numer rejestracyjny"
            name="register_car_number"
            colSize={6}
            inputPattern={/\p{L}|\s|\p{N}/u}
            inputClassName="text-uppercase"
            registerParams={{ required: true }}
          />
          <FormInputPhone name="phone" colSize={6} label="Numer telefonu" />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Anuluj
        </Button>
        <SaveButton className="btn btn-green" isSaving={isLoading} disabled={!isFormFilled} />
      </ModalFooter>
    </Form>
  )
}

const schema = z
  .object({
    date_from: z.date(),
    date_to: z.date(),
    register_car_number: z.string().min(1),
    phone: FormFieldResolvers.phone,
    booking_id: z.number().nullable(),
    reservation_number: z.string().optional(),
  })
  .refine(
    ({ date_from, date_to, booking_id }) => !(booking_id && isSameDay(date_from, date_to)),
    ({ date_from }) => ({
      message: `Gość wewnętrzny, minimalny zakres to jedna doba tj. ${toDefaultDateFormat(date_from)} - ${toDefaultDateFormat(addDays(date_from, 1))}`,
      path: ['date_to'],
    }),
  )
