import * as React from 'react'
import { Button, Label, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import { SaveButton } from '@hyper/button'
import { Agreement } from '@modules/hr/agreement/models'
import { Form } from '@hyper/forms/form'
import { useForm } from 'react-hook-form'
import { FormPlain } from '@hyper/forms'
import DatePickerInput from '@components/date/date-picker-input'
import { addMonths, min, startOfMonth, startOfToday } from 'date-fns'
import { useCreateAgreementAnnexMutation } from '@api/agreements'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useHandleRtkQueryError } from '@components/hooks/use-handle-rtk-error'
import { useAgreementDetails } from '@modules/hr/agreement/details/use-agreement-details'
import { getMaximumAgreementAnnexDate } from '@modules/hr/agreement/annexes/helpers'

interface FormInputs {
  effective_date: Date
}

interface Props extends BaseModalProps {
  agreement: Agreement
}

export const AgreementWithdrawalAnnexModal = ({ toggleIsVisible, agreement }: Props): JSX.Element => {
  const { refresh } = useAgreementDetails({ agreement, withClear: false, immediateFetch: false })
  const { addSuccessMessage } = useNotificationHook()
  const [createAgreementAnnex, { isLoading, error, isError }] = useCreateAgreementAnnexMutation()

  const methods = useForm<FormInputs>({
    defaultValues: {
      effective_date: min([getMaximumAgreementAnnexDate(agreement), startOfMonth(addMonths(startOfToday(), 1))]),
    },
  })

  useHandleRtkQueryError(error, isError, methods.setError)

  const onSubmit = async (payload: FormInputs) => {
    await createAgreementAnnex({
      url: agreement.urls.create_annex,
      payload: { ...payload, document_type: 'resignation', agreement: agreement.id },
    }).unwrap()

    refresh()
    addSuccessMessage('Sukces', 'Dokument rezygnacji został dodany.')

    toggleIsVisible()
  }

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Rezygnacja z umowy nr. {agreement.number}</ModalHeader>
      <ModalBody className="rounded">
        <FormPlain colClassName="p-0" name="effective_date">
          <Label>Data zakończenia umowy</Label>
          <DatePickerInput
            name="effective_date"
            minDate={startOfToday()}
            maxDate={getMaximumAgreementAnnexDate(agreement)}
          />
        </FormPlain>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <Button color="light" onClick={toggleIsVisible}>
          Anuluj
        </Button>
        <SaveButton isSaving={isLoading} className="btn btn-green" label="Zapisz" />
      </ModalFooter>
    </Form>
  )
}
