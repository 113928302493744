import * as React from 'react'
import { Card, CardBody } from 'reactstrap'
import { useAppDispatch, useAppSelector } from '@store/index'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { SubscriptionContractListFilterButtons } from '@modules/subscription-contract/list/filters-buttons'
import { SubscriptionContractListTable } from '@modules/subscription-contract/list/table'
import { getSubscriptionContracts } from '@store/actions/subscription-contract-actions'
import { clearSubscriptionContracts, selectSubscriptionContracts } from '@store/slices/subscription-contract-slice'
import {
  SubscriptionContractListFilter,
  SubscriptionContractListFilterParams,
} from '@modules/subscription-contract/list/filter'
import { SubscriptionContractWebSocketHandler } from '@modules/subscription-contract/web-socket-handler'
import { SubscriptionContractStatusOptions } from '@modules/subscription-contract/consts'
import { SubscriptionContractDetailsView } from '@modules/subscription-contract/list/details'
import { useParams } from 'react-router-dom'
import { PageTitleWithSize } from '@hyper/page-title-with-size'
import { useTableFilters } from '@components/table/use-table-filters'

const defaultFilters: SubscriptionContractListFilterParams = {
  sell_date_after: undefined,
  sell_date_before: undefined,
  plan: [],
  seller: null,
  source_marketing: null,
  status: SubscriptionContractStatusOptions.filter(row =>
    ['initial', 'confirmed', 'initial_resume'].includes(row.value),
  ),
  search: '',
  ordering: '-created',
  page: 1,
  page_size: 10,
}

export const SubscriptionContractListView: React.FC = () => {
  const { id } = useParams<'id'>()

  const dispatch = useAppDispatch()
  const { isLoading, filters, setFilters } = useTableFilters<SubscriptionContractListFilterParams>({
    defaultFilters,
    action: async filters => await dispatch(getSubscriptionContracts(filters)),
  })

  const statuses = filters.status.map(row => row.value)
  const subscriptionContracts = useAppSelector(selectSubscriptionContracts).filter(row =>
    statuses.length ? statuses.includes(row.status) : true,
  )
  useMenuActive(NavigationPath.SubscriptionContractList)

  React.useEffect(
    () => () => {
      dispatch(clearSubscriptionContracts())
    },
    [],
  )

  return (
    <>
      <PageTitleWithSize title="Lista subskrypcji" />
      <Card>
        <SubscriptionContractWebSocketHandler />
        {id && <SubscriptionContractDetailsView id={id} />}
        <CardBody>
          <SubscriptionContractListFilter defaultFilters={defaultFilters} filters={filters} setFilters={setFilters}>
            <SubscriptionContractListFilterButtons />
          </SubscriptionContractListFilter>
          <SubscriptionContractListTable
            isLoading={isLoading}
            subscriptions={subscriptionContracts}
            filters={filters}
            setFilters={setFilters}
          />
        </CardBody>
      </Card>
    </>
  )
}
