import { RootState } from '@store/index'
import { setDashboardStandardPaginator } from '@store/actions/dashboard-actions'
import {
  commonObjectDelete,
  commonObjectGet,
  commonObjectPost,
  commonObjectPut,
  wrapWithError,
} from '@store/actions/generic-actions'
import { PaginationResponse } from '@models/dashboard'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { BarrierTicket } from '@models/barriers'
import { BarrierTicketFormInputs } from '@modules/barriers/barrier-ticket-modal'
import { BarriersBaseListFilterParams } from '@modules/barriers/base-list/filter'

export const getBarrierTickets = createAsyncThunk<
  BarrierTicket[],
  [string, Partial<BarriersBaseListFilterParams>],
  { state: RootState }
>('barriers/getBarrierTickets', async ([url, params], { dispatch }) => {
  const data = await commonObjectGet<PaginationResponse<BarrierTicket>>(url, params)

  dispatch(setDashboardStandardPaginator(data))
  return data.results
})

export const removeBarrierTickets = createAsyncThunk<BarrierTicket, BarrierTicket>(
  'barriers/removeBarrierTickets',
  async ticket => {
    await wrapWithError(commonObjectDelete(ticket.urls.details))

    return ticket
  },
)

export const createBarrierTickets = createAsyncThunk<BarrierTicket, [string, BarrierTicketFormInputs]>(
  'barriers/createBarrierTickets',
  async ([url, payload]) =>
    await wrapWithError(
      commonObjectPost<BarrierTicket>(url, {
        ...payload,
        resorts: Array.isArray(payload.resorts) ? payload.resorts : [payload.resorts],
      }),
    ),
)
export const updateBarrierTickets = createAsyncThunk<BarrierTicket, [string, BarrierTicketFormInputs]>(
  'barriers/updateBarrierTickets',
  async ([url, payload]) =>
    await wrapWithError(
      commonObjectPut<BarrierTicket>(url, {
        ...payload,
        resorts: Array.isArray(payload.resorts) ? payload.resorts : [payload.resorts],
      }),
    ),
)
