import React from 'react'
import * as R from 'ramda'
import { createSelectOption } from '@helpers/utils'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { UseFormReturn, useWatch } from 'react-hook-form'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'

interface Response {
  options: CustomReactSelectOption[]
  selectedOptionsValues: (number | string)[]
}

export const useSelectWithAllOption = (
  selectOptions: CustomReactSelectOption[],
  methods: UseFormReturn<any, any>,
  field: string,
): Response => {
  const selectedOptions: CustomReactSelectOption[] | null = useWatch({ control: methods.control, name: field })

  const options = [...(selectOptions.length ? [createSelectOption('Wszystkie', 'all')] : []), ...selectOptions]

  const setSelectionToAll = () => {
    if (!R.equals(selectedOptions, [options[0]])) {
      methods.setValue(field, [options[0]])
    }
  }

  useDidUpdateEffect(() => {
    if (!selectedOptions?.length) return

    const hasAll = selectedOptions?.some(isAllOption)
    const hasAnother = selectedOptions?.some(isAnotherOption)
    const hasAllLastAdded = selectedOptions?.at(-1)?.value === 'all'

    if (hasAnother && hasAllLastAdded) {
      setSelectionToAll()
      return
    }

    if (!hasAll) return
    hasAnother ? methods.setValue(field, selectedOptions.filter(isAnotherOption)) : setSelectionToAll()
  }, [selectedOptions])

  const selectedOptionsValues = React.useMemo(() => {
    const hasAllSelected = selectedOptions?.some((option: CustomReactSelectOption) => option.value === 'all')
    return (hasAllSelected ? selectOptions : selectedOptions || []).map(option => option.value)
  }, [selectOptions, selectedOptions])

  return {
    options,
    selectedOptionsValues,
  }
}

const isAllOption = (option: CustomReactSelectOption) => option.value === 'all'
const isAnotherOption = (option: CustomReactSelectOption) => option.value !== 'all'
