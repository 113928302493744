import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { ContextMenu } from '@components/context-menu'
import classNames from 'classnames'
import { useTimeoutWhen } from 'rooks'
import { Tooltip } from 'reactstrap'
import {
  NewFeedingCalculationDetails,
  NewFeedingCalculations,
} from '@modules/reception/checkin/step-feeding/new-feeding/models'
import { createNewFeedingInputName } from '@modules/reception/checkin/step-feeding/new-feeding/utils'
import { ReceptionBookingNewFeedingSingleMealPriceEdit } from '@modules/reception/checkin/step-feeding/new-feeding/day-row/context-menu/new-feeding-single-meal-price-edit'
import { UserPermission } from '@models/dashboard'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useApiRequest } from '@components/hooks/use-api-request'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { useAppDispatch, useAppSelector } from '@store/index'
import {
  selectLastPriceRestorationReason,
  setFeedingCalculations,
  setInitialFeedingCalculations,
  setLastPriceRestorationReason,
} from '@store/slices/new-feeding-slice'

interface Props {
  children: React.ReactNode
  calculation: NewFeedingCalculationDetails
}

export const ReceptionBookingNewFeedingCheckboxLabelContextMenu = ({
  children,
  calculation,
}: Props): React.ReactNode => {
  const user = useAuthenticatedUser()
  const tooltipId = `context-menu-tooltip-${createNewFeedingInputName(calculation)}`
  const dispatch = useAppDispatch()

  const defaultRestorationReason = useAppSelector(selectLastPriceRestorationReason)

  const [isMouseOver, setIsMouseOver] = React.useState(false)
  const [isContextMenuTipVisible, setIsContextMenuTipVisible] = React.useState(false)
  const [isPriceEditionVisible, setIsPriceEditionVisible] = React.useState(false)

  useTimeoutWhen(() => setIsContextMenuTipVisible(true), 1000, isMouseOver)
  useTimeoutWhen(() => setIsContextMenuTipVisible(false), 200, !isMouseOver)

  const handlePriceChange = ({ syntheticEvent }) => {
    syntheticEvent.preventDefault()
    setIsPriceEditionVisible(true)
  }

  const handleMouseOverToggle = (value: boolean) => () => {
    if (!value) setIsMouseOver(value)
    if (!isDisabled) setIsMouseOver(value)
  }

  const isDisabled =
    !user.hasPerm(UserPermission.BookingFeedingCanChangeFixedPrice) ||
    !calculation.urls.fixed_price ||
    calculation.is_paid_by_wallet

  const { action: removeFixedPrice } = useApiRequest(async reason => {
    if (!calculation.urls.fixed_price) return
    const newFeedingCalculations = await commonObjectDelete<NewFeedingCalculations>(
      calculation.urls.fixed_price,
      reason,
    )

    dispatch(setLastPriceRestorationReason(reason))
    dispatch(setInitialFeedingCalculations(newFeedingCalculations))
    dispatch(setFeedingCalculations(newFeedingCalculations))
  })

  return (
    <ContextMenu
      isDisabled={isDisabled}
      portal={true}
      menuItems={
        <>
          <ContextMenu.Item onClick={handlePriceChange}>
            <IconWithText icon="uil-edit-alt" text="Edytuj cenę" textClass="font-11" />
          </ContextMenu.Item>
          {!!calculation.fixed_price_brutto && (
            <ContextMenu.Item className="">
              <CommonObjectConfirmAction
                askForReason={true}
                message=""
                title="Przywrócenie wyliczonej ceny wyżywienia"
                handleAccept={removeFixedPrice}
                defaultReason={defaultRestorationReason}
              >
                <IconWithText icon="uil-history-alt" text="Przywróć wyliczoną cenę" textClass="font-11" />
              </CommonObjectConfirmAction>
            </ContextMenu.Item>
          )}
        </>
      }
    >
      <Tooltip placement="left" isOpen={isContextMenuTipVisible} target={tooltipId} autohide={true}>
        <IconWithText
          icon="uil-mouse font-18"
          textClass="font-11"
          wrapperClassNames="py-1"
          text={
            <span>
              Kliknij prawym przyciskiem myszy, aby <strong>edytować cenę posiłku</strong>
            </span>
          }
        />
      </Tooltip>
      {isPriceEditionVisible ? (
        <ReceptionBookingNewFeedingSingleMealPriceEdit
          onEditionClose={() => setIsPriceEditionVisible(false)}
          calculation={calculation}
        />
      ) : (
        <div
          id={tooltipId}
          className={classNames('feeding__checkbox-label', {
            'is-hovered': isContextMenuTipVisible || !!calculation.fixed_price_brutto,
          })}
          onMouseEnter={handleMouseOverToggle(true)}
          onMouseLeave={handleMouseOverToggle(false)}
        >
          {children}
        </div>
      )}
    </ContextMenu>
  )
}
