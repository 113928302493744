import * as React from 'react'
import { FormInput, FormPlain } from '@hyper/forms'
import { Label, Row } from 'reactstrap'
import DateRangeInput from '@components/date/date-range-input'
import { formatDistanceStrict, setHours } from 'date-fns'
import { pl } from 'date-fns/locale'
import { useFormContext } from 'react-hook-form'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useFeedingAppData } from '@modules/feeding/hooks/use-feeding-app-data'
import { FeedingWorkersDialogFormInputs } from '@modules/feeding/workers/dialog/index'
import { InvoiceForm } from '@components/invoice-form'
import { FormSelect } from '@hyper/forms/form-select'
import { createSelectOption } from '@helpers/utils'
import { Resort } from '@models/booking'
import { ShopProduct } from '@models/shop'

interface Props {
  onlyCard?: boolean
}

export const FeedingWorkersDialogForm: React.FC<Props> = ({ onlyCard }) => {
  const { watch } = useFormContext<FeedingWorkersDialogFormInputs>()

  const user = useAuthenticatedUser()
  const [dateFrom, _dateTo] = watch(['date_from', 'date_to'])
  const dateTo = setHours(_dateTo, 23)
  const feedingAppData = useFeedingAppData()

  const paymentMethods = !onlyCard
    ? feedingAppData.payment_methods
    : feedingAppData.payment_methods.filter(row => row[0] === 'card')

  const paymentMethodsOptions = paymentMethods.map(paymentMethod =>
    createSelectOption(paymentMethod[1], paymentMethod[0]),
  )

  const departmentOptions = feedingAppData.departments.map(department =>
    createSelectOption(department[1], department[0]),
  )

  const resortOptions = user.resorts.map((resort: Resort) => createSelectOption(resort.name, resort.id))
  const productOptions = feedingAppData.feeding_worker_products.map((product: ShopProduct) =>
    createSelectOption(product.name, product.id),
  )

  return (
    <>
      <Row>
        <FormInput label="Imię i nazwisko" name="name" />
        <FormPlain name="date_from" colSize={6}>
          <Label>Data</Label>
          <DateRangeInput
            isClearable={false}
            wrapperClassName="flex-grow-1"
            inputGroupClassName="form-group"
            startDateName="date_from"
            endDateName="date_to"
          />
          <small>
            Liczba dni: {formatDistanceStrict(dateTo, dateFrom, { roundingMethod: 'ceil', unit: 'day', locale: pl })}
          </small>
        </FormPlain>

        <FormSelect
          options={paymentMethodsOptions}
          name="payment"
          label="Metoda płatności"
          formPlainProps={{ colSize: 6 }}
        />

        <FormSelect options={departmentOptions} name="department" label="Dział" formPlainProps={{ colSize: 6 }} />
        <FormSelect options={resortOptions} name="cashbox" label="Kasa" formPlainProps={{ colSize: 6 }} />
        <FormSelect options={productOptions} name="product" label="Produkt" formPlainProps={{ colSize: 6 }} />

        <FormSelect
          options={resortOptions}
          name="resorts"
          label="Ośrodek"
          formPlainProps={{ colSize: 6 }}
          selectProps={{ isMulti: true }}
        />
      </Row>
      <InvoiceForm />
    </>
  )
}
