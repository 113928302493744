import * as React from 'react'
import { PaymentRegister } from '@models/payments'
import Table from '@components/table/table'
import { toDefaultDateTimeFormatWithBrake } from '@helpers/date-helper'
import { formatPrice, formatPriceShort } from '@helpers/utils'
import { resortsMapSelector } from '@store/selectors/dashboard'
import { IconWithText } from '@components/icon-with-text'
import { PaymentCashBoxAmount } from '@modules/payment-registers/user-cashbox/payment-cashbox-amount'
import { useAppSelector } from '@store/index'

interface Props {
  paymentRegister: PaymentRegister
}

export const PaymentRegistersTableRow = ({ paymentRegister }: Props): JSX.Element => {
  const resort = useAppSelector(resortsMapSelector)[paymentRegister.source_resort_id]

  let url
  if (paymentRegister.kind === 'reception_shop') {
    const receiptId = paymentRegister.description.split('#')[1].replace(')', '').trim()
    url = `/admin/shop/receipt/${receiptId}/change/`
  }

  const description = paymentRegister.description || paymentRegister.kind_display

  return (
    <Table.Row role="details-row">
      <Table.Cell>{toDefaultDateTimeFormatWithBrake(paymentRegister.created, 'd-block font-10')}</Table.Cell>
      <Table.Cell>{paymentRegister.type_display}</Table.Cell>
      <Table.Cell>{url ? <a href={url}>{description}</a> : description}</Table.Cell>
      <Table.Cell>
        {paymentRegister.booking_reservation_number && (
          <a href={`/admin/dashboard/quick-search/?query=${paymentRegister.booking_reservation_number}`}>
            {paymentRegister.booking_reservation_number}
          </a>
        )}
      </Table.Cell>
      <Table.Cell>{resort?.name || 'centrala'}</Table.Cell>
      <Table.Cell>{paymentRegister.user}</Table.Cell>
      <Table.Cell>
        <PaymentCashBoxAmount
          paymentRegister={paymentRegister}
          inTicketsAmountTooltipContent={
            <>
              <span className="text-semi-strong">{formatPrice(paymentRegister.amount)}</span>
              <span className="font-10 d-block">
                wykorzystano: {formatPriceShort(paymentRegister.amount_in_tickets, true)} ticketów
              </span>
            </>
          }
        />
      </Table.Cell>
      <Table.Cell>
        {paymentRegister.status === 'success' ? (
          <IconWithText
            icon="uil-check"
            text={paymentRegister.status_display}
            wrapperClassNames="text-success text-semi-strong"
          />
        ) : (
          <IconWithText
            icon="uil-multiply"
            text={paymentRegister.status_display}
            wrapperClassNames="text-danger -text-semi-strong"
          />
        )}
      </Table.Cell>
      <Table.Cell>
        <i className="uil-print text-muted font-18 cursor-pointer" />
      </Table.Cell>
    </Table.Row>
  )
}
