import * as React from 'react'
import { CleaningOrder } from '@modules/housekeeping/models'
import { HousekeepingCleaningOrderCleaningRowActions } from '@modules/housekeeping/cleaning-orders/cleaning/actions'
import { HousekeepingCleaningOrdersRowServiceKind } from '@modules/housekeeping/cleaning-orders/common/service-kind'
import { HousekeepingCleaningOrderOrderedByRow } from '@modules/housekeeping/cleaning-orders/common/ordered-by'
import { HousekeepingOrdersRowTotalTimeStartedLive } from '@modules/housekeeping/common/row-total-time-started-live'
import { HousekeepingCleaningOrdersRowKind } from '@modules/housekeeping/cleaning-orders/common/kind'
import { HousekeepingOrdersRowTotalTimeStarted } from '@modules/housekeeping/common/row-total-time-started'
import { IconWithText } from '@components/icon-with-text'
import { HousekeepingOrderAssignedWorkers } from '@modules/housekeeping/cleaning-orders/common/assigned-workers'
import { HousekeepingCleaningOrderApartment } from '@modules/housekeeping/cleaning-orders/to-order/row-apartment'
import { TableViewType } from '@modules/technical-orders/common/table-view-switch'

interface HousekeepingCleaningOrderCleaningRowProps {
  cleaningOrder: CleaningOrder
  tableView: TableViewType
}

export const HousekeepingCleaningOrderCleaningRow: React.FC<HousekeepingCleaningOrderCleaningRowProps> = ({
  cleaningOrder,
  tableView,
}) => (
  <tr className="housekeeping__orders_table__row">
    <HousekeepingCleaningOrderApartment cleaningOrder={cleaningOrder} showCheckedIn={true} showPriority={true} />
    <HousekeepingCleaningOrdersRowKind order={cleaningOrder} />
    <HousekeepingCleaningOrdersRowServiceKind id={cleaningOrder.id} order={cleaningOrder} />
    {cleaningOrder.is_paused ? (
      <HousekeepingOrdersRowTotalTimeStarted totalTimeStarted={cleaningOrder.total_time_started}>
        <IconWithText icon="uil-pause font-15" text="pauza" textClass="text-semi-strong ml-05" flexClassName="d-flex" />
      </HousekeepingOrdersRowTotalTimeStarted>
    ) : (
      <HousekeepingOrdersRowTotalTimeStartedLive order={cleaningOrder} />
    )}
    {tableView === 'list' && <HousekeepingOrderAssignedWorkers cleaningOrder={cleaningOrder} />}

    <HousekeepingCleaningOrderOrderedByRow
      ordered_at={cleaningOrder.ordered_at}
      ordered_by={cleaningOrder.ordered_by}
    />
    <HousekeepingCleaningOrderCleaningRowActions cleaningOrder={cleaningOrder} />
  </tr>
)
