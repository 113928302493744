import * as React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { formatPrice } from '@helpers/utils'
import { Payment, PaymentMethod } from '@models/payments'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { GenericProductPaymentsAdd } from '@modules/generic-product-payments/add'
import { GenericProductPaymentsDelete } from '@modules/generic-product-payments/delete'

interface Props {
  payments: Payment[]
  canDeletePayment: boolean
  canAddPayment: boolean
  addUrl: string
  onSuccess?: () => void
  defaultAmount?: string
  paymentMethods?: PaymentMethod[]
}

export const GenericProductPayments: React.FC<Props> = ({
  addUrl,
  onSuccess,
  payments,
  canAddPayment,
  canDeletePayment,
  defaultAmount,
  paymentMethods,
}) => (
  <Card>
    <CardBody>
      <Row>
        <Col md={5}>
          <h4 className="mt-0 text-secondary mb-3">Płatności</h4>
        </Col>
        <Col md={7} className="text-right">
          {canAddPayment && (
            <GenericProductPaymentsAdd
              defaultAmount={defaultAmount}
              url={addUrl}
              onSuccess={onSuccess}
              paymentMethods={paymentMethods}
            />
          )}
        </Col>
        <Col md={12} className="mt-2">
          <table className="table table-centered table-sm table-hover table-striped">
            <thead className="thead-light">
              <tr>
                <th>Data dodania</th>
                <th>Kwota</th>
                <th>Rodzaj płatności</th>
                <th>Numer transakcji bankowej</th>
                <th>Dodany przez</th>
                <th style={{ width: 10 }} />
              </tr>
            </thead>
            <tbody>
              {!payments.length ? (
                <tr>
                  <td colSpan={6} className="text-center">
                    Nie dodano żadnych wpłat
                  </td>
                </tr>
              ) : (
                payments.map((payment: Payment) => (
                  <tr key={payment.id}>
                    <td>{toDefaultDateFormat(payment.invoice_date)}</td>
                    <td>{formatPrice(payment.amount)}</td>
                    <td>{payment.source_display}</td>
                    <td>{payment.description}</td>
                    <td>{payment.user}</td>
                    <td>
                      {canDeletePayment && <GenericProductPaymentsDelete onSuccess={onSuccess} payment={payment} />}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </Col>
      </Row>
    </CardBody>
  </Card>
)
