import * as React from 'react'
import { Button, Label, Row } from 'reactstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { TableFilters } from '@components/table/table'
import { ColAuto } from '@hyper/col-auto'
import DateRangeInput from '@components/date/date-range-input'
import { AgreementCreateButton } from '@modules/hr/agreement/create'
import { UserPermission } from '@models/dashboard'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { FormSelect } from '@hyper/forms/form-select'
import { useHrAppData } from '@modules/hr/use-hr-app-data'
import { createSelectOption } from '@helpers/utils'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { AgreementStatusOptions } from '@modules/hr/consts'
import { useMultipleResorts } from '@components/hooks/use-multiple-resorts'

interface Props {
  defaultFilters: AgreementFilterParams
  filters: AgreementFilterParams
  setFilters: (filters: AgreementFilterParams) => void
}

export interface AgreementFilterParams extends TableFilters {
  date_of_start_before?: Date
  date_of_start_after?: Date
  date_of_termination_after?: Date
  date_of_termination_before?: Date
  resorts: CustomReactSelectOption[]
  companies: CustomReactSelectOption[]
  statuses: CustomReactSelectOption[]
  job_positions: CustomReactSelectOption[]
}

export const AgreementListFilter: React.FC<Props> = ({ defaultFilters, filters, setFilters }) => {
  const { companies, job_positions } = useHrAppData()
  const methods = useForm<AgreementFilterParams>({ defaultValues: defaultFilters })
  const user = useAuthenticatedUser()
  const { getResortOptions } = useMultipleResorts({ methods, field: 'resorts' })
  const resortOptions = getResortOptions({ withAllOption: false })

  const handleReset = () => {
    methods.reset()
    setFilters(defaultFilters)
  }

  const onSubmit = async (payload: AgreementFilterParams) => setFilters({ ...filters, ...payload })

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  const companiesOptions = companies.map(company => createSelectOption(company.name, company.id))
  const jobPositionOptions = job_positions.map(jobPosition => createSelectOption(jobPosition.name, jobPosition.id))

  return (
    <FormProvider {...methods}>
      <Row>
        <FormSelect
          options={companiesOptions}
          name="companies"
          label="Spółka"
          selectProps={{ isSelectMulti: true }}
          formPlainProps={{ colStyle: { maxWidth: 300 } }}
        />

        <FormSelect
          options={AgreementStatusOptions}
          name="statuses"
          label="Status"
          selectProps={{ isSelectMulti: true }}
          formPlainProps={{ colStyle: { maxWidth: 180 } }}
        />

        <FormSelect
          options={resortOptions}
          name="resorts"
          label="Resort"
          selectProps={{ isSelectMulti: true }}
          formPlainProps={{ colStyle: { maxWidth: 255 } }}
        />
        <FormSelect
          options={jobPositionOptions}
          name="job_positions"
          label="Stanowisko"
          selectProps={{ isSelectMulti: true }}
          formPlainProps={{ colStyle: { maxWidth: 275 } }}
        />
        <ColAuto>
          <Row>
            <ColAuto>
              <Label>Data rozpoczęcia umowy</Label>
              <DateRangeInput
                isClearable={true}
                startDateName="date_of_start_after"
                endDateName="date_of_start_before"
                dateFormat="dd.LL.yyyy"
              />
            </ColAuto>
            <ColAuto>
              <Label>Data zakończenia umowy</Label>
              <DateRangeInput
                isClearable={true}
                startDateName="date_of_termination_after"
                endDateName="date_of_termination_before"
                dateFormat="dd.LL.yyyy"
              />
            </ColAuto>
            <ColAuto>
              {methods.formState.isDirty && (
                <Button color="light" className="mt-label" type="button" onClick={handleReset}>
                  <i className="mdi mdi-close " />
                </Button>
              )}
            </ColAuto>
          </Row>
        </ColAuto>
      </Row>
      <Row className="align-items-center mb-2">
        <SearchFilter colClassName="col-auto" formGroupClassName="mb-0" hideLabel />
        {user.hasPerm(UserPermission.HrAgreementCanEdit) && <AgreementCreateButton />}
      </Row>
    </FormProvider>
  )
}
