import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { ShopPOSWithParamsFormInputs } from '@modules/shop/pos/shop-pos-with-params'

interface ShopPurchaseAddTicketButtonRowProps {
  onClick: () => void
}

export const ShopPurchaseAddTicketButtonRow: React.FC<ShopPurchaseAddTicketButtonRowProps> = ({ onClick }) => {
  const { control } = useFormContext<ShopPOSWithParamsFormInputs>()

  const paymentEnabled = useWatch({
    control,
    name: 'paymentEnabled',
  })

  return (
    <tr>
      <td colSpan={5} className="text-right">
        {!paymentEnabled && (
          <div
            className="text-secondary font-weight-bold d-inline-flex align-items-center cursor-pointer justify-content-end"
            onClick={onClick}
          >
            <i className="uil-plus" />
            <span className="d-inline-block mx-1">Dodaj ticket</span>
            <i className="uil-ticket font-14" />
          </div>
        )}
      </td>
      <td />
    </tr>
  )
}
