import * as React from 'react'
import { FormInput } from '@hyper/forms'
import { FormProvider, useForm } from 'react-hook-form'
import { handleEnterPress } from '@helpers/utils'
import { ReceptionBookingDetails, ReceptionBookingGroup } from '@models/reception'
import { bookingClientWalletAssign } from '@store/actions/reception-actions-check'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useFormRequest } from '@components/hooks/use-api-request'
import { SaveButton } from '@hyper/button'
import { IconWithText } from '@components/icon-with-text'
import { useAppDispatch } from '@store/index'
import { updateBookingWalletDetails } from '@store/slices/reservations-slice'

interface FormInputs {
  voucherCode: string
}

interface Props {
  onClose: () => void
  closable?: boolean
  booking: ReceptionBookingDetails
  group: ReceptionBookingGroup
}

export const StepVerificationAddVoucherInput: React.FC<Props> = ({ onClose, booking, closable = true, group }) => {
  const methods = useForm<FormInputs>()
  const { addSuccessNotification } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading, action: handleAdd } = useFormRequest(
    async () => {
      await dispatch(
        updateBookingWalletDetails(
          await bookingClientWalletAssign(booking.id, methods.getValues('voucherCode'), group.id),
        ),
      )
      addSuccessNotification('Voucher został przypisany do grupy')
      onClose()
    },
    methods.setError,
    {
      showGlobalError: true,
      mapFormError: [['gastro_card', 'voucherCode']],
    },
  )

  return (
    <FormProvider {...methods}>
      <div className="d-flex justify-content-between">
        <div className="position-relative">
          <FormInput
            colClassName="px-0 reception__booking-check-in__verification_step__voucher__code"
            formGroupClassName="mb-0 position-relative"
            placeholder="Wpisz kod"
            name="voucherCode"
            inputClassName="cursor-default"
            onKeyDown={handleEnterPress.bind(handleAdd)}
          />
          {closable && (
            <i
              className="uil-times text-danger font-16 cursor-pointer reception__booking-check-in__verification_step__voucher__code-input__action-icon"
              onClick={onClose}
            />
          )}
        </div>

        <SaveButton
          type="button"
          onClick={handleAdd}
          className="btn btn-secondary btn-sm reception__booking-check-in__verification_step__voucher__add-button"
          label={<IconWithText icon="uil-plus" text="Dodaj voucher" />}
          isSaving={isLoading}
        />
      </div>
    </FormProvider>
  )
}
