import { NotificationEvents } from '@models/dashboard'
import { useWebSocket } from '@components/hooks/use-web-socket'

interface FeedingWebsocketEvent {
  id: number
}

export const useFeedingWebsocket = (bookingId: number, onFeedingChange: () => void) => {
  useWebSocket<FeedingWebsocketEvent>(
    (message, payload) => {
      switch (message) {
        case NotificationEvents.FEEDING_CHANGE: {
          const updateData = payload as FeedingWebsocketEvent

          if (updateData.id === bookingId) {
            onFeedingChange()
          }
          break
        }
      }
    },
    [],
    [NotificationEvents.FEEDING_CHANGE],
  )

  return null
}
