import * as React from 'react'
import { useToggle } from '@components/hooks/use-toggle'
import { Modal, ModalBody } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { usePinPadReport } from '@modules/reception/common/pin-pad/use-pin-pad-report'
import { useNotificationHook } from '@hyper/use-notification-hook'

interface Props {
  handleClose: () => void
}

export const ReceptionWorkstationChangePinPadWarningModal: React.FC<Props> = ({ handleClose }) => {
  const [isShow, toggleIsShow] = useToggle(true)
  const { addSuccessNotification } = useNotificationHook()

  const [isLoading, handlePrintReport, eservicePinPad] = usePinPadReport({})

  const onClick = async () => {
    await handlePrintReport()
    addSuccessNotification('Wydruk został zlecony')
    toggleIsShow()
  }

  return (
    <Modal size="sm" isOpen={isShow} toggle={toggleIsShow} onClosed={handleClose} role="error-modal">
      <ModalBody className="modal-colored-header bg-danger text-center">
        <i className="uil-ban h1" />
        <h4 className="mt-2">Uwaga</h4>
        <p className="mt-3">Przed wyborem nowego terminala płatniczego - zamknij terminal {eservicePinPad?.name}</p>
        <SaveButton
          isSaving={isLoading}
          onClick={onClick}
          label="Wydrukuj raport wysyłki."
          labelSaving="Wysyłanie.."
          className="btn btn-light my-2"
        />
      </ModalBody>
    </Modal>
  )
}
