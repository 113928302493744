import * as React from 'react'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { UpSellUser } from '@models/upselling'
import { UpSellUsersTableRow } from '@modules/upselling/upsell-users/table-row'

interface Props {
  upSellUsers: UpSellUser[]
  setFilters: React.Dispatch<React.SetStateAction<TableFilters>>
  filters: TableFilters
  isLoading: boolean
}

const headers: TableHeaderValue[] = [
  { title: 'Imię', sortField: 'first_name' },
  { title: 'Nazwisko', sortField: 'last_name' },
  { title: '' },
]

export const UpSellUsersTable: React.FC<Props> = ({ isLoading, filters, setFilters, upSellUsers }) => (
  <Table
    headerClassName="bg-white border-top-0 vertical-align-middle"
    className="table-hover w-100"
    headerValues={headers}
    isLoading={isLoading}
    filters={filters}
    setFilters={setFilters}
  >
    {upSellUsers.map(upSellUser => (
      <UpSellUsersTableRow key={upSellUser.id} upSellUser={upSellUser} />
    ))}
  </Table>
)
