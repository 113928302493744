import * as React from 'react'
import { formatPriceShort } from '@helpers/utils'
import { ButtonWithIcon } from '@components/button-with-icon'
import { useModal } from '@components/modals/use-modal'
import { ImprovementAvailability } from '@models/booking'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  subscriptionImprovementAvailability: ImprovementAvailability
}

export const ReceptionBookingImprovementSubscriptionAdd = ({
  subscriptionImprovementAvailability,
}: Props): React.ReactNode => {
  const [showVoucherCreateModal] = useModal('ReceptionBookingImprovementSubscriptionModal', {
    subscriptionImprovementAvailability,
  })

  return (
    <div>
      <div className="bg-secondary rounded pl-1 pr-2 py-2 shadow-sm d-flex align-items-center mb-2 position-relative overflow-hidden">
        <IconWithText icon="uil-tag-alt font-16" text="Super Start" wrapperClassNames="text-white" />
        <div className="text-white font-12 reception__booking-check-in__improvement-step__subscription-voucher__information">
          <div>Cena za voucher:</div>
          <div>
            {formatPriceShort(subscriptionImprovementAvailability.price_brutto_first_item)} /{' '}
            {formatPriceShort(subscriptionImprovementAvailability.price_brutto)}
          </div>
        </div>
        <div className="ml-auto">
          <ButtonWithIcon
            handleClick={showVoucherCreateModal}
            icon="uil-plus"
            text={`Dodaj (+${formatPriceShort(subscriptionImprovementAvailability.price_brutto_first_item)})`}
            btnClass="bg-primary rounded shadow-none border-0 reception__booking-check-in__improvement-step__subscription-voucher__add-button"
          />

          <i className="uil-tag-alt reception__booking-check-in__improvement-step__subscription-voucher__watermark" />
        </div>
      </div>
    </div>
  )
}
