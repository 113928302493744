import * as React from 'react'
import { useDocumentTitle } from '@helpers/utils'
import { useAppDispatch } from '@store/index'
import { useApiRequest } from '@components/hooks/use-api-request'
import { PageTitle } from '@hyper/page-title'
import { NavigationPath } from '@models/routes'
import { Card, CardBody } from 'reactstrap'
import { CmsAssetCreateButton } from '@modules/cms/assets/list/create-button'
import { CmsAssetsTable } from '@modules/cms/assets/list/table'
import { getAssetCategories, getAssets } from '@store/actions/asset-actions'

export const CmsAssetsView = (): JSX.Element => {
  const pageTitle = 'Assety'
  useDocumentTitle(pageTitle)
  const dispatch = useAppDispatch()

  const { isLoading, action: fetch } = useApiRequest(async () => {
    await dispatch(getAssetCategories())
    await dispatch(getAssets())
  })

  React.useEffect(() => {
    fetch()
  }, [])

  return (
    <>
      <PageTitle
        breadCrumbItems={[{ label: pageTitle, path: NavigationPath.CmsAssets, active: true }]}
        title={pageTitle}
      />
      <Card>
        <CardBody>
          <div className="text-right mb-3">
            <CmsAssetCreateButton />
          </div>
          <CmsAssetsTable isLoading={isLoading} />
        </CardBody>
      </Card>
    </>
  )
}
