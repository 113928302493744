import * as React from 'react'
import { Card, CardBody } from 'reactstrap'
import { ProductCardTitle } from '@components/products/product-card-title'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { SubscriptionDetailsInformationRow } from '@modules/subscription/details/information/row'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { BenefitProgramDetails } from '@modules/benefit-program/models'
import { BenefitProgramDetailsUsers } from '@modules/benefit-program/details/users'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  benefitProgram: BenefitProgramDetails
}

export const BenefitProgramDetailsInformation: React.FC<Props> = ({ benefitProgram }) => {
  const user = useAuthenticatedUser()

  return (
    <Card>
      <CardBody>
        <ProductCardTitle
          title={<IconWithText icon="uil-user font-20" text="Dane firmy" />}
          adminUrl={benefitProgram.urls.admin}
          isEditAllowed={user.is_superuser}
        />
        <table className="table table-sm mb-0">
          <tbody>
            <SubscriptionDetailsInformationRow name="Numer oferty" borderless={true}>
              {benefitProgram.number}
            </SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="Nazwa firmy">
              {benefitProgram.name}
            </SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="NIP">
              {benefitProgram.invoice_nip}
            </SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="Adres">
              {benefitProgram.invoice_street} {benefitProgram.invoice_postcode} {benefitProgram.invoice_city}
            </SubscriptionDetailsInformationRow>

            <SubscriptionDetailsInformationRow name="Data utworzenia">
              {toDefaultDateTimeFormat(benefitProgram.created)}
            </SubscriptionDetailsInformationRow>

            <BenefitProgramDetailsUsers benefitProgram={benefitProgram} />
          </tbody>
        </table>
      </CardBody>
    </Card>
  )
}
