import * as React from 'react'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { BenefitProgram } from '@modules/benefit-program/models'
import { BenefitProgramTableRow } from '@modules/benefit-program/list/table-row'
import { BenefitProgramTableFooter } from '@modules/benefit-program/list/table-footer'

interface Props {
  benefitPrograms: BenefitProgram[]
  setFilters: React.Dispatch<React.SetStateAction<TableFilters>>
  filters: TableFilters
  isLoading: boolean
}

const headers: TableHeaderValue[] = [
  { title: 'Numer', sortField: 'number' },
  { title: 'Nazwa', sortField: 'name' },
  { title: 'Status', sortField: 'status' },
  { title: 'Benefity' },
  { title: 'Wartość' },
  { title: 'Sprzedawca' },
  { title: 'Opiekun' },
  { title: '' },
]

export const BenefitProgramTable: React.FC<Props> = ({ isLoading, filters, setFilters, benefitPrograms }) => (
  <Table
    className="table-hover w-100"
    headerValues={headers}
    isLoading={isLoading}
    filters={filters}
    setFilters={setFilters}
  >
    {benefitPrograms.map(benefitProgram => (
      <BenefitProgramTableRow key={benefitProgram.id} benefitProgram={benefitProgram} />
    ))}
    {!!benefitPrograms.length && <BenefitProgramTableFooter filters={filters} />}
  </Table>
)
