import * as React from 'react'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { FormProvider, useForm } from 'react-hook-form'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { IssueOrderDetails, IssueOrderStatus } from '@modules/housekeeping/applications/models'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { commonObjectPost, commonObjectPut } from '@store/actions/generic-actions'
import { HousekeepingApplicationIssueCreateModalForm } from '@modules/housekeeping/applications/issue/create-modal/form'
import { useApartments } from '@components/hooks/use-apartments'
import { useFormRequest } from '@components/hooks/use-api-request'
import { BaseModalProps } from '@components/modals/types'
import { updateHousekeepingIssueOrder } from '@store/slices/housekeeping-slice'
import * as R from 'ramda'
import { CustomReactSelectOption } from '@components/custom-react-select'

interface HousekeepingApplicationIssueCreateModalProps extends BaseModalProps {
  resortId: number
  issueOrder?: IssueOrderDetails
}

export interface HousekeepingApplicationIssueCreateModalFormInputs {
  apartment: CustomReactSelectOption | null
  booking: CustomReactSelectOption | null
  images: number[]
  name: string
  status: IssueOrderStatus
  created_by: any
  description: string
}

export const HousekeepingApplicationIssueCreateModal: React.FC<HousekeepingApplicationIssueCreateModalProps> = ({
  resortId,
  toggleIsVisible,
  issueOrder,
}) => {
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()
  const url = useAppSelector((state: RootState) => state.appState.appData.urls.housekeeping.issue_orders)

  const methods = useForm<HousekeepingApplicationIssueCreateModalFormInputs>({
    defaultValues: {
      apartment: null,
      booking: null,
      ...(issueOrder && R.pick(['apartment', 'images', 'name', 'status', 'created_by', 'description'], issueOrder)),
      images: issueOrder?.images.map(row => row.id) || [],
    },
  })

  useApartments(resortId)

  const { isLoading, action: onSubmit } = useFormRequest(
    async (payload: HousekeepingApplicationIssueCreateModalFormInputs) => {
      let issueOrderDetails
      if (issueOrder) {
        issueOrderDetails = await commonObjectPut<IssueOrderDetails>(issueOrder.urls.details, {
          ...payload,
          resort: resortId,
          apartment: payload.apartment?.value,
          booking: payload.booking?.value,
        })
        addSuccessMessage('Sukces', `Zgłoszenie o numerze ${issueOrder.number} została zmieniona!`)
      } else {
        issueOrderDetails = await commonObjectPost<IssueOrderDetails>(url, {
          ...payload,
          resort: resortId,
          apartment: payload.apartment?.value,
          booking: payload.booking?.value,
        })
        addSuccessMessage('Sukces', `Zgłoszenie o numerze ${issueOrderDetails.number} została zgłoszona!`)
      }
      await dispatch(updateHousekeepingIssueOrder(issueOrderDetails))
      toggleIsVisible()
    },
    methods.setError,
  )

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggleIsVisible}>
          {issueOrder ? (
            <>
              <span className="text-semi-strong">Edycja zgłoszenia</span> <strong>{issueOrder.number}</strong>
            </>
          ) : (
            'Nowe zgłoszenie'
          )}
        </ModalHeader>
        <ModalBody>
          <HousekeepingApplicationIssueCreateModalForm issueOrder={issueOrder} resortId={resortId} />
        </ModalBody>
        <ModalFooter>
          <Button color="light" type="button" onClick={toggleIsVisible}>
            Zamknij
          </Button>
          <SaveButton className="btn btn-green" isSaving={isLoading} />
        </ModalFooter>
      </form>
    </FormProvider>
  )
}
