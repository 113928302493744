import * as React from 'react'
import { SubscriptionDetails, SubscriptionTouristVoucher } from '@modules/subscription/models'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { useFilteredRows } from '@components/table/use-filtered-rows'
import { useSortedRows } from '@components/table/use-sorted-rows'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { formatPriceShort } from '@helpers/utils'
import { useModal } from '@components/modals/use-modal'
import { SubscriptionDetailsTouristVouchersRowConfirmation } from '@modules/subscription/details/tourist-vouchers-row-confirmation'
import { SubscriptionDetailsTouristVouchersRowActions } from './tourist-vouchers-row-actions'

interface Props {
  subscriptionDetails: SubscriptionDetails
}
const headerValues: TableHeaderValue<SubscriptionTouristVoucher>[] = [
  { title: 'Kod', sortField: 'code', search: true },
  { title: 'Kwota', sortField: 'amount', search: true },
  { title: 'Dodane', sortField: 'created_by', search: true },
  { title: 'Potwierdzony', sortField: 'is_confirmed' },
  { title: '' },
]
const defaultFilters: TableFilters = {
  search: '',
  ordering: '-invoice_date',
  page: 1,
  page_size: 9999,
}

export const SubscriptionDetailsTouristVouchers: React.FC<Props> = ({ subscriptionDetails }) => {
  const [filters, setFilters] = React.useState<TableFilters>(defaultFilters)

  const setValue = (_, search) => setFilters({ ...filters, search })

  const [handleCreate] = useModal('SubscriptionTouristVoucherModal', {
    subscriptionDetails,
  })

  const filteredRows = useFilteredRows<SubscriptionTouristVoucher>(
    subscriptionDetails.tourist_vouchers,
    headerValues,
    String(filters.search),
  )
  const sortedRows = useSortedRows<SubscriptionTouristVoucher>(filteredRows, filters.ordering)

  return (
    <Card>
      <CardBody>
        <Row className="align-items-center">
          <Col className="col-auto col-md-5">
            <h4 className="text-secondary">Bony turystyczne</h4>
          </Col>
          <SearchFilter hideLabel={true} setValue={setValue} colClassName="col-md-5" formGroupClassName="mb-0" />
        </Row>
        <hr className="mt-1" />
        <Table
          className="table-hover table-sm w-100 mb-0"
          headerValues={headerValues}
          isLoading={false}
          showPagination={false}
          emptyText="Brak bonów"
          filters={filters}
          setFilters={setFilters}
        >
          {sortedRows.map(touristVoucher => (
            <Table.Row key={touristVoucher.id}>
              <Table.Cell>{touristVoucher.code}</Table.Cell>
              <Table.Cell>{formatPriceShort(touristVoucher.amount)}</Table.Cell>
              <Table.Cell>{touristVoucher.created_by}</Table.Cell>
              <SubscriptionDetailsTouristVouchersRowConfirmation touristVoucher={touristVoucher} />
              {touristVoucher.is_confirmed ? (
                <Table.Cell />
              ) : (
                <SubscriptionDetailsTouristVouchersRowActions
                  touristVoucher={touristVoucher}
                  subscriptionDetails={subscriptionDetails}
                />
              )}
            </Table.Row>
          ))}
        </Table>
        {!subscriptionDetails.read_only && !subscriptionDetails.is_paid && (
          <div className="text-right">
            <button type="button" className="btn btn-green btn-sm" onClick={handleCreate}>
              Dodaj bon
            </button>
          </div>
        )}
      </CardBody>
    </Card>
  )
}
