import * as React from 'react'
import { parseISODate, toDefaultDateFormat, toDefaultDateTimeFormat } from '@helpers/date-helper'
import { useNotificationHook } from '@hyper/use-notification-hook'
import classnames from 'classnames'
import { FeedingDiscount } from '@modules/reception/checkin/step-feeding/new-feeding/models'
import { formatPercentage, formatPrice } from '@helpers/utils'
import { ReceptionBookingDetails } from '@models/reception'
import { ReceptionBookingNewFeedingDiscountRemoveConfirmation } from '@modules/reception/checkin/step-feeding/new-feeding/discounts/reception-booking-new-feeding-discount-remove-confirmation'
import { UncontrolledTooltip } from 'reactstrap'
import { IconWithText } from '@components/icon-with-text'

const getTooltipId = (discount: FeedingDiscount) => `discount-${discount.id}`

interface Props {
  title: React.ReactNode
  discount: FeedingDiscount
  onEdit: (discount: FeedingDiscount) => void
  onRemove: (discount: FeedingDiscount) => void
  isAnyInEditMode: boolean
  bookingDetails: ReceptionBookingDetails
  onFeedingChange: (feedingChanges) => void
  canRemove: boolean
  canEdit: boolean
}

export const ReceptionBookingNewFeedingDiscountListRow = ({
  title,
  discount,
  onEdit,
  onRemove,
  canRemove,
  canEdit,
  isAnyInEditMode,
  bookingDetails,
  onFeedingChange,
}: Props): React.ReactElement => {
  const { addWarningMessage } = useNotificationHook()

  const discountValue =
    discount.kind === 'percentage' ? formatPercentage(discount.current_value) : formatPrice(discount.current_value)

  const discountDate = `${toDefaultDateFormat(discount.date_from ?? bookingDetails.date_from)} - ${toDefaultDateFormat(discount.date_to ?? bookingDetails.date_to)}`

  const showRemoveWarningModal = () => {
    addWarningMessage(
      <>
        Czy na pewno chcesz <br /> usunąć rabatowanie?
      </>,
      'Usunięcie rabatowania będzie miało wpływ na cenę wyżywienia',
      onClose => (
        <ReceptionBookingNewFeedingDiscountRemoveConfirmation
          onFeedingChange={onFeedingChange}
          bookingDetails={bookingDetails}
          onClose={onClose}
          onRemove={onRemove}
          discount={discount}
        />
      ),
    )
  }

  return (
    <li className="row align-items-center fw-semi-bold mb-1">
      <div className="col-5">{title}</div>
      <div className="col-4">{discountDate}</div>
      <div className="col-2 d-flex align-items-center">
        <span id={getTooltipId(discount)}>
          <i className="uil-info-circle font-14 text-danger" />
        </span>

        <UncontrolledTooltip placement="top" target={getTooltipId(discount)}>
          <div className="overflow-hidden mx-n2 px-2">
            <div className="font-11 my-1" style={{ width: 150 }}>
              <div className="d-flex justify-content-between">
                <div className="fw-semi-bold">Rabat:</div>
                <div className="badge badge-danger-lighten font-10">{discountValue}</div>
              </div>
              <hr className="my-1 mx-n3 border-gray" />
              <div className="text-left">
                <div className="fw-semi-bold">Uzasadnienie rabatu:</div>
                <div className="font-10 opacity-75">{discount.reason || 'brak'}</div>
              </div>

              {(discount.created_by || discount.modified_by) && <hr className="my-1 mx-n3 border-gray" />}

              {discount.created_by && (
                <div className="mt-1 text-left">
                  <IconWithText icon="font-12 uil-plus ml-n1" text="Dodanie rabatu" textClass="font-11" />
                  <p className="mb-0 text-start mt-1 border-left text-left opacity-75 pl-1 font-10">
                    {discount.created_by.name} ({discount.created_by.department_display}) <br />{' '}
                    {toDefaultDateTimeFormat(parseISODate(discount.created))}
                  </p>
                </div>
              )}

              {discount.modified_by && (
                <div className="mt-2 text-left">
                  <IconWithText icon="font-12 uil-pen ml-n1" text="Edycja rabatu" textClass="font-11" />
                  <p className="mb-0 text-start mt-1 border-left text-left opacity-75 pl-1 font-10">
                    {discount.modified_by.name} ({discount.modified_by.department_display}) <br />{' '}
                    {toDefaultDateTimeFormat(parseISODate(discount.modified))}
                  </p>
                </div>
              )}
            </div>
          </div>
        </UncontrolledTooltip>
        <div className="badge badge-danger-lighten font-10 ml-2">{discountValue}</div>
      </div>
      <div className={classnames('col-1 text-nowrap text-right', { disabled: isAnyInEditMode })}>
        {canEdit && <i className="uil-pen font-14 pr-1 cursor-pointer" onClick={() => onEdit(discount)} />}
        {canRemove && <i className="uil-trash-alt font-14 cursor-pointer" onClick={showRemoveWarningModal} />}
      </div>
    </li>
  )
}
