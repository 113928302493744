import * as React from 'react'
import { FormSelect } from '@hyper/forms/form-select'
import { HousekeepingOrderServiceKindOptions } from '@modules/housekeeping/cleaning-orders/create-cleaning-order/form'

export const HousekeepingOrderTableHeaderServiceKind = (): JSX.Element => (
  <th className="py-1 border-top-0 fw-semi-bold" style={{ width: 200 }}>
    <FormSelect
      options={HousekeepingOrderServiceKindOptions}
      selectProps={{ placeholder: 'Typ zadania', isClearable: true, isSearchable: false }}
      name="service_kind"
      formPlainProps={{ formGroupClassName: 'mb-0', colClassName: 'px-0' }}
    />
  </th>
)
