import * as React from 'react'
import { CleaningOrder } from '@modules/housekeeping/models'
import { HousekeepingCleaningOrderOrderedRowActions } from '@modules/housekeeping/cleaning-orders/ordered/actions'
import { HousekeepingCleaningOrderOrderedContextMenuTableCells } from '@modules/housekeeping/cleaning-orders/ordered/context-menu/context-menu-table-cells'

interface Props {
  cleaningOrder: CleaningOrder
}

export const HousekeepingCleaningOrderOrderedRow: React.FC<Props> = ({ cleaningOrder }) => (
  <tr>
    <td colSpan={8} className="p-0">
      <HousekeepingCleaningOrderOrderedContextMenuTableCells cleaningOrder={cleaningOrder} />
    </td>
    <HousekeepingCleaningOrderOrderedRowActions cleaningOrder={cleaningOrder} />
  </tr>
)
