import * as React from 'react'
import { ClientSelect } from '@components/client-select'
import { UserPermission } from '@models/dashboard'
import { FormPlain } from '@hyper/forms'
import { useFormContext } from 'react-hook-form'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { Label, Row } from 'reactstrap'
import DatePickerInput from '@components/date/date-picker-input'
import { useModal } from '@components/modals/use-modal'
import { ClientUser } from '@models/clients'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useGroupedSellers } from '@components/hooks/use-grouped-sellers'
import { PackageVipAddFormInputs } from '@modules/promotions/package-vip/add/package-vip-dialog'
import { InvoiceForm } from '@components/invoice-form'
import { PackageVip } from '@modules/promotions/package-vip/models'
import { FormSelect } from '@hyper/forms/form-select'
import { PackageVipTypeSelectOptions } from '@modules/promotions/package-vip/utils'

interface Props {
  client: ClientUser | undefined
  onClientSet: (client: ClientUser | undefined) => void
  sourcesSelectOptions: CustomReactSelectOption[]
  packageVip: PackageVip | undefined
}

export const PackageVipDialogDataStep = ({
  onClientSet,
  client,
  sourcesSelectOptions,
  packageVip,
}: Props): JSX.Element => {
  const user = useAuthenticatedUser()

  const methods = useFormContext<PackageVipAddFormInputs>()

  const [handleToggleClientDialog] = useModal('ClientDialog', { setClient: onClientSet })

  const sellers = useGroupedSellers([])

  return (
    <>
      <Row>
        <ClientSelect
          setClient={onClientSet}
          client={client}
          handleToggleClientDialog={handleToggleClientDialog}
          error={methods.formState.errors.client}
        />
        {user.hasPerm(UserPermission.PromotionsPackageVipCanChangeSeller) && (
          <FormSelect options={sellers} name="seller" formPlainProps={{ colSize: 6 }} label="Sprzedawca" />
        )}
        <FormSelect
          options={sourcesSelectOptions}
          name="source_marketing"
          label="Źródło"
          formPlainProps={{ colSize: 6 }}
        />
        <FormPlain colSize={6} name="sell_date">
          <Label>Data sprzedaży</Label>
          <DatePickerInput
            isDisabled={!!packageVip}
            control={methods.control}
            name="sell_date"
            wrapperClassName="flex-grow-1"
          />
        </FormPlain>
        <FormSelect
          options={PackageVipTypeSelectOptions}
          name="type"
          label="Rodzaj"
          formPlainProps={{ colSize: 6 }}
          selectProps={{ isDisabled: !!packageVip }}
        />

        {packageVip && (
          <FormPlain name="auto_cancel_after_date" colSize={6}>
            <label>Auto anulowanie po</label>
            <DatePickerInput
              name="auto_cancel_after_date"
              isClearable={true}
              wrapperClassName="flex-grow-1"
              isDisabled={!user.hasPerm(UserPermission.PromotionsSubscriptionCanChangeAutoCancelAfterDate)}
            />
          </FormPlain>
        )}
      </Row>
      {!packageVip && <InvoiceForm client={client} invoiceData={undefined} />}
    </>
  )
}
