import * as React from 'react'
import { useForm } from 'react-hook-form'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { subscriptionProductCreate, subscriptionProductUpdate } from '@api/subscription'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAppDispatch } from '@store/index'
import { SubscriptionProductCreateForm } from '@modules/subscription/products/create/form'
import { SubscriptionProduct, SubscriptionType } from '@modules/subscription/models'
import { useFormRequest } from '@components/hooks/use-api-request'
import { BaseModalProps } from '@components/modals/types'
import { useSubscriptionAppData } from '@modules/subscription/hooks/use-subscription-app-data'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { IconWithText } from '@components/icon-with-text'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useSubscriptionKinds } from '@modules/subscription/hooks/use-subscription-kinds'
import { Form } from '@hyper/forms/form'
import { packageWholesaleProductsStatusOptions } from '@modules/package-wholesale/products/list/filter'
import { updateSubscriptionProductDetails } from '@store/slices/subscription-slice'
import { useSubscriptionTypeStrategyProvider } from '@modules/subscription/hooks/use-subscription-type-provider'
import { PackageVipType } from '@modules/promotions/package-vip/models'
import { createSelectOption } from '@helpers/utils'

interface Props extends BaseModalProps {
  subscriptionProduct?: SubscriptionProduct
  type: SubscriptionType
}

export interface SubscriptionProductCreateFormInputsCodeFeature {
  gastro_gift: string
  years: string
  package_vip: '' | PackageVipType
}
export interface SubscriptionProductCreateFormInputs {
  status: CustomReactSelectOption | undefined
  name: string
  code_features: SubscriptionProductCreateFormInputsCodeFeature[]
  local_mountains: number
  local_universal: number
  local_sea: number
  price_brutto: string
  change_client_data_price_brutto: string
  code_localization_convert_price_brutto: string
  zoo_borysew_tickets_single_price_brutto: string
  suntago_tickets_single_price_brutto: string
  accommodation_type: string
  client_kind: string
  product: number
  kind: CustomReactSelectOption | undefined
  defaults_days: number | undefined
  kind_version: CustomReactSelectOption | undefined
  deposit_amount: string
}
const defaultsDaysOptions: CustomReactSelectOption[] = [createSelectOption('6 dób', 6), createSelectOption('5 dób', 5)]

export const SubscriptionProductCreateModal: React.FC<Props> = ({ toggleIsVisible, type, subscriptionProduct }) => {
  const { products_defaults } = useSubscriptionAppData()
  const { subscriptionKindOptions, subscriptionKindVersionOptions } = useSubscriptionKinds()
  const subscriptionTypeStrategy = useSubscriptionTypeStrategyProvider(subscriptionProduct?.type || type)

  const methods = useForm<SubscriptionProductCreateFormInputs>({
    defaultValues: {
      local_mountains: 0,
      local_universal: 0,
      local_sea: 0,

      code_localization_convert_price_brutto: products_defaults.code_localization_convert_price_brutto,
      change_client_data_price_brutto: products_defaults.change_client_data_price_brutto,
      zoo_borysew_tickets_single_price_brutto: products_defaults.zoo_borysew_tickets_single_price_brutto,
      suntago_tickets_single_price_brutto: products_defaults.suntago_tickets_single_price_brutto,
      deposit_amount: '750',
      ...subscriptionProduct,
      kind: subscriptionProduct
        ? subscriptionKindOptions.find(row => row.value === subscriptionProduct.kind)
        : undefined,
      kind_version: subscriptionProduct
        ? subscriptionKindVersionOptions.find(row => row.value === subscriptionProduct.kind_version)
        : undefined,

      code_features:
        subscriptionProduct?.code_features.map(row => ({
          ...row,
          package_vip: row.package_vip || '',
          gastro_gift: row.gastro_gift || '',
          years: row.years.join(','),
        })) || [],
      accommodation_type: String(subscriptionProduct?.accommodation_type_id || ''),
      status: packageWholesaleProductsStatusOptions.find(row => row.value === subscriptionProduct?.status),
    },
  })
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()
  const user = useAuthenticatedUser()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: SubscriptionProductCreateFormInputs) => {
    const code_features = payload.code_features.map(row => ({
      ...row,
      package_vip: row.package_vip || null,
      years: row.years ? row.years.split(',') : [],
    }))
    const subscriptionProductDetails = await (!subscriptionProduct
      ? subscriptionProductCreate({ ...payload, code_features }, type)
      : subscriptionProductUpdate(subscriptionProduct.urls.details, { ...payload, code_features }))

    addSuccessMessage(
      'Sukces',
      !subscriptionProduct
        ? `Produkt "${subscriptionProductDetails.name}" został dodany`
        : `Produkt "${subscriptionProductDetails.name}" został zmieniony`,
    )
    toggleIsVisible()
    dispatch(updateSubscriptionProductDetails(subscriptionProductDetails))
  }, methods.setError)

  return (
    <Form onSubmit={onSubmit} methods={methods}>
      <ModalHeader toggle={toggleIsVisible}>
        {!subscriptionProduct ? (
          'Dodaj produkt'
        ) : (
          <>
            Edytuj produkt <span className="text-semi-strong">{subscriptionProduct.name}</span>
          </>
        )}
      </ModalHeader>
      <ModalBody className="pb-0">
        <SubscriptionProductCreateForm
          subscriptionTypeStrategy={subscriptionTypeStrategy}
          defaultsDaysOptions={defaultsDaysOptions}
        />
      </ModalBody>
      <ModalFooter>
        {subscriptionProduct && user.is_superuser && (
          <a href={subscriptionProduct?.urls.admin} className="mr-auto">
            <IconWithText icon="uil-pen" text="Edytuj" wrapperClassNames="text-strong text-secondary cursor-pointer" />
          </a>
        )}
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>

        <SaveButton className="btn btn-green" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
