import * as React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { ReceptionBookingDetails } from '@models/reception'
import { ReceptionBookingCheckInStepAccessBands } from '@modules/reception/checkin/step-access/reception-booking-check-in-step-access-bands'
import { ServiceBand } from '@modules/bands/service-bands/models'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectGet } from '@store/actions/generic-actions'
import { ProductCardTitle } from '@components/products/product-card-title'
import { setReceptionBookingDetails } from '@store/slices/reception-slice'
import { receptionBookingDetailsSelector } from '@store/selectors/reception'
import { useAppDispatch, useAppSelector } from '@store/index'

interface Props {
  serviceBand: ServiceBand
}

export const ServiceBandAssign: React.FC<Props> = ({ serviceBand }) => {
  const dispatch = useAppDispatch()
  const receptionBookingDetails = useAppSelector(receptionBookingDetailsSelector)
  const updateState = (bookingDetails: ReceptionBookingDetails) => dispatch(setReceptionBookingDetails(bookingDetails))

  const { isLoading, action: fetchBooking } = useApiRequest(async () => {
    dispatch(setReceptionBookingDetails(await commonObjectGet<ReceptionBookingDetails>(serviceBand.urls.booking)))
  })

  React.useEffect(() => {
    fetchBooking()
  }, [])

  return (
    <Card>
      <CardBody>
        <ProductCardTitle title="Przypisz opaskę" isEditAllowed={false} />
        <LoaderPlaceholder content={!isLoading}>
          <Row>
            <Col md={12}>
              {receptionBookingDetails && (
                <ReceptionBookingCheckInStepAccessBands booking={receptionBookingDetails} updateState={updateState} />
              )}
            </Col>
          </Row>
        </LoaderPlaceholder>
      </CardBody>
    </Card>
  )
}
