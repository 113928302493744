import * as React from 'react'
import { SaveButton } from '@hyper/button'
import { IconWithText } from '@components/icon-with-text'
import { ButtonWithIcon } from '@components/button-with-icon'
import { usePinPad } from '@modules/reception/common/pin-pad/use-pin-pad'
import classNames from 'classnames'

export type PinPadKind =
  | 'booking_advance'
  | 'booking_deposit_preauthorization'
  | 'booking_deposit_preauthorization_checkout'
  | 'booking_deposit_preauthorization_cancel'
  | 'booking_advance_with_climatic'
  | 'receipt'

interface Props {
  abortLabel?: string
  amount: string
  children?: (handlePayment, handleCancel, isLoading) => JSX.Element | JSX.Element[] | null
  className?: string
  content_type: number
  context?: any
  isDisabled?: boolean
  kind: PinPadKind
  object_id: number
  onAbort?: () => void
  onCancel?: () => void
  onError?: () => void
  onSuccess?: () => void
  preauth_payment?: number
  title?: string
}

export const ReceptionPinPad = ({
  abortLabel = 'Anuluj',
  amount,
  children,
  className = 'btn-secondary',
  content_type,
  context,
  isDisabled,
  kind,
  object_id,
  onAbort,
  onCancel,
  onError,
  onSuccess,
  preauth_payment,
  title = 'Wyślij na terminal',
}: Props): JSX.Element | null => {
  const { handlePayment, handleCancel, isLoading } = usePinPad({
    amount,
    kind,
    preauth_payment,
    onCancel,
    onError,
    onSuccess,
    onAbort,
    object_id,
    content_type,
    context,
  })

  return (
    <>
      {(isLoading || onCancel) && (
        <ButtonWithIcon
          btnClass="shadow-sm reception__payment-box-buttons--cancel"
          color="light"
          icon="uil-multiply"
          text={abortLabel}
          type="button"
          handleClick={handleCancel}
          disabled={isDisabled}
        />
      )}

      <SaveButton
        type="button"
        onClick={handlePayment}
        isSaving={isLoading && !isDisabled}
        className={classNames('btn shadow-sm flex-fill reception__payment-box-buttons--submit', className)}
        label={<IconWithText icon="uil-transaction" text={title} />}
        labelSaving="Użyj terminala..."
        disabled={isDisabled}
      />
      {children?.(handlePayment, handleCancel, isLoading)}
    </>
  )
}
