import * as React from 'react'
import { Label, Row } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { SubmitFilter } from '@modules/reception/common/filters/submit-filter'
import DateRangeInput from '@components/date/date-range-input'
import Col from 'reactstrap/lib/Col'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { FeedingClientsFiltersParams } from '@modules/feeding/clients/index'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { Form } from '@hyper/forms/form'

interface FeedingClientsFiltersProps {
  filters: FeedingClientsFiltersParams
  setFilters: (filters: FeedingClientsFiltersParams) => void
  defaultFilters: FeedingClientsFiltersParams
}

export const FeedingClientsFilters: React.FC<FeedingClientsFiltersProps> = ({
  setFilters,
  filters,
  defaultFilters,
}) => {
  const methods = useForm<FeedingClientsFiltersParams>({
    defaultValues: filters,
  })

  const handleReset = () => {
    methods.reset()
    setFilters(defaultFilters)
  }

  const onSubmit = async (payload: FeedingClientsFiltersParams) => setFilters({ ...filters, ...payload })

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  return (
    <Form onSubmit={onSubmit} className="form form-style-filter mb-2" methods={methods}>
      <Row>
        <Col className="col-auto" style={{ minWidth: 320 }}>
          <div className="d-flex">
            <Label className="mr-1">Data</Label>
          </div>

          <DateRangeInput
            isClearable={true}
            wrapperClassName="flex-grow-1"
            inputGroupClassName="form-group"
            startDateName="date_from"
            endDateName="date_to"
          />
        </Col>

        <SubmitFilter handleReset={handleReset} />
        <SearchFilter colClassName="col-md-2" />
      </Row>
    </Form>
  )
}
