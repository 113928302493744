import * as React from 'react'
import { Card } from 'reactstrap'
import { useToggle } from '@components/hooks/use-toggle'
import { PackageWholesaleDetails } from '@modules/package-wholesale/models'
import { PackageWholesaleDetailsClientInformationTable } from '@modules/package-wholesale/details/client/table'
import { PackageWholesaleDetailsClientInformationForm } from '@modules/package-wholesale/details/client/form'

interface Props {
  packageWholesaleDetails: PackageWholesaleDetails
}

export const PackageWholesaleDetailsClientInformation: React.FC<Props> = ({ packageWholesaleDetails }) => {
  const [isEditing, toggleEdit] = useToggle()

  return (
    <Card>
      {isEditing ? (
        <PackageWholesaleDetailsClientInformationForm
          toggleEdit={toggleEdit}
          packageWholesaleDetails={packageWholesaleDetails}
        />
      ) : (
        <PackageWholesaleDetailsClientInformationTable
          toggleEdit={toggleEdit}
          packageWholesaleDetails={packageWholesaleDetails}
        />
      )}
    </Card>
  )
}
