import IconFunnel from '@components/icons/funnel'
import * as React from 'react'
import { ApartmentBookingFilter } from '@models/apartment'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { setActiveFilter, setCalendarDates, toggleTimelineMask } from '@store/actions/timeline-actions'
import { useModal } from '@components/modals/use-modal'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { CustomInput, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap'
import timelineHelper from '@helpers/timeline-helper'
import { ButtonWithIcon } from '@components/button-with-icon'
import { TimelineFilterButtons } from '@modules/reservations/timeline/filters/timeline-filter-buttons'
import { IconWithText } from '@components/icon-with-text'
import { UserPermission } from '@models/dashboard'
import { setReceptionBookingDetails } from '@store/slices/reception-slice'
import { TimelineLocalFilterButtons } from '@modules/reservations/timeline/filters/timeline-local-filter-buttons'

interface Props {
  resortId: string
}

const TimelineActions = ({ resortId }: Props) => {
  const dispatch = useAppDispatch()
  const user = useAuthenticatedUser()
  const activeFilters = useAppSelector((state: RootState) => state.timelineState.activeFilters)

  const [handleCreateReservation] = useModal(
    'ReservationCreateModal',
    {},
    {
      persist: true,
      onClose: React.useCallback(() => {
        dispatch(setReceptionBookingDetails(undefined))
      }, []),
    },
  )

  const [handleAddBlockade] = useModal('ReservationBlockadeModal', { resortId })

  const toggleActiveFilter = (filter: ApartmentBookingFilter) => () => {
    if (activeFilters.includes(filter)) {
      dispatch(setActiveFilter(activeFilters.filter(el => el !== filter)))
      return
    }

    dispatch(setActiveFilter([...activeFilters, filter]))
  }

  const isButtonFiltered = (filter: ApartmentBookingFilter) => !!activeFilters.length && !activeFilters.includes(filter)

  const [showTimelineFiltersModal] = useModal('TimelineFiltersModal')

  const isMask = useAppSelector((state: RootState) => state.timelineState.isMask)

  const toggleMask = (): void => {
    dispatch(toggleTimelineMask())
  }

  React.useEffect(() => {
    setTodayDate()
  }, [])

  const setTodayDate = (): void => {
    dispatch(setCalendarDates(timelineHelper.getDefaultCalendarDate(), 'today'))
  }

  const canViewActions =
    user.hasPerm(UserPermission.CanCreateReservation) || user.hasPerm(UserPermission.ApartmentCanBlockApartment)

  return (
    <div className="btn-toolbar justify-content-between align-items-center" role="toolbar">
      <div className="mt-1 mb-1 d-flex align-items-center">
        {canViewActions && (
          <UncontrolledButtonDropdown>
            <DropdownToggle color="green" caret={true}>
              <IconWithText icon="uil-plus font-16 lh-initial" text="Dodaj" textClass="pr-2" />
            </DropdownToggle>
            <DropdownMenu className="py-0">
              {user.hasPerm(UserPermission.CanCreateReservation) && (
                <TimelineActions.DropdownItem text="Nową rezerwację" onClick={handleCreateReservation} />
              )}
              {user.hasPerm(UserPermission.ApartmentCanBlockApartment) && (
                <TimelineActions.DropdownItem text="Blokadę lokalu" onClick={handleAddBlockade} />
              )}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        )}
        <ButtonWithIcon
          btnClass="btn btn-light calendar__today-button position-relative ml-1 mr-3"
          handleClick={setTodayDate}
          icon="uil-arrow-down-left calendar__today-button-icon"
          text="Dziś"
        />
        <CustomInput
          type="checkbox"
          id="timeline-mask"
          label="Odblokuj zakończone"
          checked={!isMask}
          onChange={toggleMask}
        />
      </div>
      <div className="d-flex align-items-center">
        <div className="border-right mr-1">
          <small className="text-muted font-10">Filtruj lokale</small>
          <TimelineLocalFilterButtons
            toggleActiveFilter={toggleActiveFilter}
            isButtonActive={isButtonFiltered}
            isCompact={true}
          />
        </div>
        <div className="ml-1">
          <small className="text-muted font-10">Filtruj rezerwacje</small>
          <div className="d-flex">
            <TimelineFilterButtons
              isButtonActive={isButtonFiltered}
              toggleActiveFilter={toggleActiveFilter}
              filters={['unavailable', 'confirmed', 'initial', 'checkedin']}
            />
            <button type="button" className="btn btn-pure d-block" onClick={showTimelineFiltersModal}>
              <IconFunnel /> Filtry
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TimelineActions

TimelineActions.DropdownItem = ({ onClick, text }) => (
  <DropdownItem onClick={onClick} className="pl-2 py-2">
    <IconWithText icon="uil-plus font-16 lh-initial" text={text} wrapperClassNames="text-semi-strong" />
  </DropdownItem>
)
