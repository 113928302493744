import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { ReservationDropdown } from '@modules/reservations/details/common/reservation-dropdown'
import { Button } from 'reactstrap'
import { IconWithText } from '@components/icon-with-text'
import { useModal } from '@components/modals/use-modal'

interface ReservationDataActionsProps {
  booking: ReceptionBookingDetails
  canChangeDeclaredArrivalTime: boolean
}

export const ReservationDataActions: React.FC<ReservationDataActionsProps> = ({
  booking,
  canChangeDeclaredArrivalTime,
}) => {
  const [showDeclaredArrivalTime] = useModal('ReservationDeclaredArrivalTimeModal', { booking })

  const handlCancelReservation = () => {
    console.log('Cancel reservation', booking)
  }

  return (
    <div className="d-flex justify-content-end">
      <Button color="light" className="mr-2 btn-tall">
        <i className="uil-edit-alt font-16 lh-initial text-default" />
      </Button>
      <ReservationDropdown>
        {canChangeDeclaredArrivalTime && (
          <ReservationDropdown.Item onClick={showDeclaredArrivalTime}>
            <IconWithText
              icon="uil-stopwatch"
              text={
                booking.declared_arrival_time
                  ? 'Zmień deklarowaną godzinę przyjazdu'
                  : 'Ustaw deklarowaną godzinę przyjazdu'
              }
              textClass="font-11 fw-semi-bold"
            />
          </ReservationDropdown.Item>
        )}
        <ReservationDropdown.Item onClick={handlCancelReservation}>
          <IconWithText
            icon="uil-check text-success"
            text="Potwierdź rezerwację"
            textClass="font-11 text-success fw-semi-bold"
          />
        </ReservationDropdown.Item>
        <ReservationDropdown.Item onClick={handlCancelReservation}>
          <IconWithText
            icon="uil-times text-danger"
            text="Anuluj rezerwację"
            textClass="font-11 text-danger fw-semi-bold"
          />
        </ReservationDropdown.Item>
      </ReservationDropdown>
    </div>
  )
}
