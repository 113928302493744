import { FeedingAppData, FeedingClient, FeedingSpecialGuest, FeedingWorker } from '@modules/feeding/models'
import { AsyncThunkParams } from '@store/index'
import { setDashboardStandardPaginator } from '@store/actions/dashboard-actions'
import { FeedingClientsFiltersParams } from '@modules/feeding/clients'
import { FeedingWorkerFiltersParams } from '@modules/feeding/workers'
import { commonObjectGet } from '@store/actions/generic-actions'
import { TableFilters } from '@components/table/table'
import { PaginationResponse } from '@models/dashboard'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getFeedingClients = createAsyncThunk<FeedingClient[], FeedingClientsFiltersParams, AsyncThunkParams>(
  'feedings/getFeedingClients',
  async (filters, { getState, dispatch }) => {
    const data = await commonObjectGet<PaginationResponse<FeedingClient>>(
      getState().appState.appData.urls.feeding.clients,
      filters,
    )

    dispatch(setDashboardStandardPaginator(data))

    return data.results
  },
)

export const getFeedingSpecialGuests = createAsyncThunk<FeedingSpecialGuest[], TableFilters, AsyncThunkParams>(
  'feedings/getFeedingSpecialGuests',
  async (filters, { getState, dispatch }) => {
    const data = await commonObjectGet<PaginationResponse<FeedingSpecialGuest>>(
      getState().appState.appData.urls.feeding.special_guests_list,
      filters,
    )

    dispatch(setDashboardStandardPaginator(data))

    return data.results
  },
)

export const getFeedingWorkers = createAsyncThunk<FeedingWorker[], FeedingWorkerFiltersParams, AsyncThunkParams>(
  'feedings/getFeedingWorkers',
  async (filters, { getState, dispatch }) => {
    const data = await commonObjectGet<PaginationResponse<FeedingWorker>>(
      getState().appState.appData.urls.feeding.workers_list,
      filters,
    )

    dispatch(setDashboardStandardPaginator(data))

    return data.results
  },
)

export const getFeedingAppData = createAsyncThunk<FeedingAppData, void, AsyncThunkParams>(
  'feeding/getPackageAppData',
  async (_, { getState }) => {
    if (getState().feedingState.appData.status === 'unknown') {
      return await commonObjectGet<FeedingAppData>(getState().appState.appData.urls.feeding.app_data)
    }
    return getState().feedingState.appData
  },
)
