import * as React from 'react'
import { ReceptionBookingCheck } from '@modules/reception/common/reception-booking-check'
import { StepReservationBox } from '@modules/reservations/create/step-reservation-box'
import { FormProvider, useFormContext } from 'react-hook-form'
import { StepReservationApartmentBaseInformation } from '@modules/reservations/create/step-reservation-info/step-reservation-apartment-base-information'
import { ReservationCreateFormInputs, StepReservationInfoFormInputs } from '@modules/reservations/create/models'
import { useFormRequest } from '@components/hooks/use-api-request'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import StepReservationInfoDateProposal from '@modules/reservations/create/step-reservation-info/date-proposal'
import { formatDate } from '@helpers/date-helper'
import { Resort } from '@models/booking'
import { CalendarPayload } from '@models/reservation-create'
import { commonObjectGet } from '@store/actions/generic-actions'
import { CancelTokenSource } from 'axios'
import { createUnfinishedReservation } from '@store/actions/reception-actions'
import { receptionBookingDetailsSelector } from '@store/selectors/reception'
import { useCancelablePromise } from '@components/hooks/use-cancelable-promise'
import { handleFormSubmit } from '@helpers/forms'
import { setAccommodationTypeAvailable } from '@store/slices/reception-slice'
import { ClientUser } from '@models/clients'

interface Props {
  nextStep: () => void
  resorts: Resort[]
  client: ClientUser | undefined
  setClient: (client: ClientUser | undefined) => void
}

export const StepReservationInfo: React.FC<Props> = ({ nextStep, resorts, client, setClient }) => {
  const [isDateAvailable, setAvailableDate] = React.useState<boolean>(true)
  const [isSubmitDisabled, setIsSubmitDisabled] = React.useState(false)
  const methods = useFormContext<ReservationCreateFormInputs>()
  const dispatch = useAppDispatch()
  const reservation = useAppSelector(receptionBookingDetailsSelector)
  const calendarUrl = useAppSelector((state: RootState) => state.appState.appData.urls.reservation.calendar)

  const watched = methods.watch()

  const { isLoading, action: onSubmit } = useFormRequest(
    async (payload: StepReservationInfoFormInputs) => {
      await dispatch(
        createUnfinishedReservation({
          resort: payload.resort?.value,
          selected_apartment: payload.apartment?.value,
          accommodation_type: payload.apartment?.context.accommodation_type_id || payload.accommodation_type?.value,
          date_from: formatDate(payload.date_from),
          date_to: formatDate(payload.date_to),
          client: client?.id,
          code: watched.promocode?.value,
        }),
      )
      nextStep()
    },
    methods.setError,
    { nonFieldErrorsAs: 'date_from', mapFormError: [['code', 'promocode']] },
  )

  useCancelablePromise(
    cancelToken => {
      if (watched.resort && watched.accommodation_type && watched.date_to && watched.date_from) {
        const payload: CalendarPayload = {
          date_from: watched.date_from ? formatDate(watched.date_from) : undefined,
          date_to: watched.date_to ? formatDate(watched.date_to) : undefined,
          accommodation_type: watched.accommodation_type.value,
          resort: watched.resort.value,
          calendar_type: 'start',
          client: client?.id,
          code: watched.promocode?.value,
        }
        fetchAvailableDates(payload, cancelToken)
      }
    },
    [watched.date_from, watched.date_to, watched.resort, watched.accommodation_type, watched.promocode],
  )

  const fetchAvailableDates = async (payload: CalendarPayload, cancelToken: CancelTokenSource) => {
    try {
      const dates = await commonObjectGet<string[]>(calendarUrl, payload, cancelToken)
      setAvailableDate(
        dates.some(date => date === formatDate(watched.date_to)) &&
          dates.some(date => date === formatDate(watched.date_from)),
      )
    } catch (e) {
      console.error(e)
    }
  }

  React.useEffect(() => {
    if (watched.resort?.value !== reservation?.resort_id) {
      methods.resetField('accommodation_type')
      methods.resetField('apartment')
      methods.resetField('localSelection')
      dispatch(setAccommodationTypeAvailable([]))
    }
  }, [watched.resort])

  const isStepActive = (formInput: string) => !!watched[formInput]

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleFormSubmit(methods, onSubmit)}>
        <StepReservationBox
          title="1. Informacje o rezerwacji"
          footer={
            <ReceptionBookingCheck
              nextStep={nextStep}
              isSaving={isLoading}
              nextLabelSaving="Sprawdzanie terminu..."
              isDisabled={!watched.date_from || !watched.date_to || isSubmitDisabled}
              type="submit"
            />
          }
        >
          <StepReservationApartmentBaseInformation
            client={client}
            setClient={setClient}
            resorts={resorts}
            isStepActive={isStepActive}
            onSubmitDisabledChange={setIsSubmitDisabled}
          />
        </StepReservationBox>

        {!isDateAvailable && !!watched.date_from && !!watched.date_to && <StepReservationInfoDateProposal />}
      </form>
    </FormProvider>
  )
}
