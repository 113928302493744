import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { IconWithText } from '@components/icon-with-text'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useAppDispatch } from '@store/index'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { updateReceptionBookingDetails } from '@store/actions/reception-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'

interface Props {
  booking: ReceptionBookingDetails
}

export const ReservationPromotionsPromocodeRemove = ({ booking }: Props): JSX.Element => {
  const dispatch = useAppDispatch()
  const { addSuccessMessage, addErrorMessage } = useNotificationHook()
  const { isLoading, action: handleAccept } = useApiRequest(async () => {
    try {
      const response = await commonObjectDelete<ReceptionBookingDetails>(booking.urls.reservation_booking_promocode)
      dispatch(updateReceptionBookingDetails(response))
      addSuccessMessage('Sukces', 'Kod został skasowany z rezerwacji')
    } catch (error) {
      addErrorMessage('Błąd', error.response.data.message)
    }
  })

  return (
    <div>
      <span className="badge text-white badge-info">{booking.promocode?.code}</span>
      <CommonObjectConfirmAction handleAccept={handleAccept} isLoading={isLoading}>
        <IconWithText icon="uil-minus" text="skasuj" wrapperClassNames="text-danger font-11 ml-2 cursor-pointer" />
      </CommonObjectConfirmAction>
    </div>
  )
}
