import * as React from 'react'
import { Button } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'

interface PackageVipAddProps {
  fetchPackageVips: () => void
}

export const PackageVipAdd: React.FC<PackageVipAddProps> = ({ fetchPackageVips }) => {
  const [togglePackageVipDialog] = useModal('PackageVipDialog', {
    fetchPackageVips,
  })

  return (
    <Button onClick={togglePackageVipDialog} color="green-dark" type="button">
      <i className="mdi mdi-plus-circle mr-1" />
      Dodaj pakiet
    </Button>
  )
}
