import * as React from 'react'
import { TechnicalOrder } from '@models/technical-orders'
import Table from '@components/table/table'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useModal } from '@components/modals/use-modal'
import { Button } from 'reactstrap'
import { useTechnicalOrderStart } from '@modules/technical-orders/hooks/use-technical-order-start'

interface Props {
  technicalOrder: TechnicalOrder
}

export const TechnicalOrdersOrderedTableRowActions: React.FC<Props> = ({ technicalOrder }) => {
  const user = useAuthenticatedUser()

  const [handleAssign] = useModal('TechnicalOrderAssignModal', { technicalOrders: [technicalOrder], onlyAssign: true })
  const onClick = useTechnicalOrderStart({ technicalOrders: [technicalOrder] })

  return (
    <Table.Cell className="text-right vertical-align-middle text-nowrap">
      <Button onClick={onClick} className="btn btn-green">
        Start
      </Button>

      {(!technicalOrder.forwarded_to_technical_order_manager || user.isTechnicalOrderManager) && (
        <Button onClick={handleAssign} className="btn btn-green ml-1">
          Zmień wykonawcę
        </Button>
      )}
    </Table.Cell>
  )
}
