import * as React from 'react'
import { Button, ModalFooter, ModalHeader } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { BaseModalProps } from '@components/modals/types'
import { ReportGeneratorError } from '@models/reports'
import { useAppDispatch, useAppSelector } from '@store/index'
import { modalsSelector, setModalIsOpen } from '@store/slices/modal-slice'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { createReportTask } from '@store/actions/report-tasks-actions'
import { SaveButton } from '@hyper/button'
import { noop } from '@helpers/utils'

interface Props extends BaseModalProps {
  reportGenerationError: ReportGeneratorError
}

export const RegenerateUserReportConfirmationModal = ({ toggleIsVisible, reportGenerationError }: Props) => {
  const modalItems = useAppSelector(modalsSelector)
  const dispatch = useAppDispatch()

  const { isLoading, action: onSubmit } = useReportFormRequest(async () => {
    const reportTask = await dispatch(
      createReportTask([
        reportGenerationError.context.url,
        { ...(reportGenerationError.context.payload as any), force_create_task: true },
      ]),
    )
    toggleIsVisible()
    return reportTask
  }, noop)

  const handleConfirm = () => {
    onSubmit()
  }

  React.useEffect(() => {
    const lastReportModal = [...modalItems.reverse()].find(
      modal => modal.modal !== 'RegenerateUserReportConfirmationModal',
    )

    if (!lastReportModal) return
    dispatch(setModalIsOpen({ modal: lastReportModal.modal, isOpen: false }))
  }, [])

  return (
    <>
      <ModalHeader toggle={toggleIsVisible} className="bg-warning text-dark py-2 rounded-0">
        Ponowienie generowania raportu
      </ModalHeader>
      <ModalBody>
        <span className="d-block fw-semi-bold">
          {reportGenerationError.error.response?.data.non_field_errors.join('. ')}
        </span>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={toggleIsVisible}>
          Anuluj
        </Button>

        <SaveButton
          isSaving={isLoading}
          label="Ponawiam"
          labelSaving="Generowanie..."
          className="btn btn-warning"
          onClick={handleConfirm}
        />
      </ModalFooter>
    </>
  )
}
