import * as React from 'react'
import { ButtonWithIcon } from '@components/button-with-icon'
import { PackageType } from '@models/package'
import { useModal } from '@components/modals/use-modal'
import { RootState, useAppSelector } from '@store/index'
import { PackageListFilterParams } from '@modules/package/list/filter'

interface Props {
  type: PackageType
  filters?: PackageListFilterParams
}

export const PackageListExportButton: React.FC<Props> = ({ type, filters }) => {
  const { report_task_package, report_task_package_hpi, report_task_package_wholesale } = useAppSelector(
    (state: RootState) => state.appState.appData.urls.reports,
  )

  const urls = {
    hpi: report_task_package_hpi,
    wholesale: report_task_package_wholesale,
  }

  const titles = {
    hpi: 'pakietów HPI',
    wholesale: 'pakietów hurtowych',
  }

  const [handlePackageHpi] = useModal('ReportsGeneratorPackagesModal', {
    url: urls[type] ?? report_task_package,
    title: titles[type] ?? 'pakietów HPR',
    filters,
  })

  return (
    <div className="text-right" onClick={handlePackageHpi}>
      <ButtonWithIcon icon="uil-download-alt" color="secondary" text="Pobierz" />
    </div>
  )
}
