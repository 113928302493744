import React from 'react'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectPost } from '@store/actions/generic-actions'
import { NewFeedingCalculations } from '@modules/reception/checkin/step-feeding/new-feeding/models'
import { ReceptionBookingDetails } from '@models/reception'
import { useAppSelector } from '@store/index'
import { selectExposedEdgeFeedings, selectInitialFeedingCalculations } from '@store/slices/new-feeding-slice'
import { asDecimal, sumDecimalArray } from '@helpers/utils'
import { getCalculationDetailsWithoutEdgeMeals } from '@modules/reception/checkin/step-feeding/new-feeding/utils'
import { differenceInDays } from 'date-fns'
import { parseISODate } from '@helpers/date-helper'

interface Response {
  totalPrice: string
  pricePerDay: string
  isCalculating: boolean
}

export const useFastSelectionFullFeedingPrice = (booking: ReceptionBookingDetails): Response => {
  const initialFeedingCalculations = useAppSelector(selectInitialFeedingCalculations)
  const exposedFeedings = useAppSelector(selectExposedEdgeFeedings)
  const [fullFeedingTotalPrice, setFullFeedingTotalPrice] = React.useState('0')

  const { action: fetchCalculations, isLoading } = useApiRequest(async () => {
    const availableCalculationDetails = (initialFeedingCalculations?.details ?? []).filter(
      calculation => calculation.available,
    )

    const data = await commonObjectPost<NewFeedingCalculations>(booking.urls.reservation_feeding_calculate, {
      add_feedings: getCalculationDetailsWithoutEdgeMeals(availableCalculationDetails, exposedFeedings).reduce(
        (feedings, calculation) => [
          ...feedings,
          { booking_guest: calculation.booking_guest, date: calculation.date, kind: calculation.kind },
        ],
        [],
      ),
    })

    setFullFeedingTotalPrice(
      sumDecimalArray(
        getCalculationDetailsWithoutEdgeMeals(data.details, exposedFeedings).map(calculation =>
          asDecimal(calculation.price_brutto),
        ),
      ).toString(),
    )
  })

  const pricePerDay = asDecimal(fullFeedingTotalPrice)
    .div(differenceInDays(parseISODate(booking.date_to) as Date, parseISODate(booking.date_from) as Date))
    .toString()

  React.useEffect(() => {
    fetchCalculations()
  }, [exposedFeedings])

  return { totalPrice: fullFeedingTotalPrice, pricePerDay, isCalculating: isLoading }
}
