import * as React from 'react'
import { TechnicalOrderOrderedDroppableIds } from '@modules/technical-orders/technical-orders-ordered/drag-and-drop/consts'
import { DraggableTableDropRowPlaceholder } from '@components/table/draggable-table-drop-row-placeholder'

interface Props {
  priority: number
}

export const TechnicalOrdersOrderedDroppableEmptyRowPlaceholder = ({ priority }: Props): JSX.Element => (
  <tbody>
    <tr>
      <td colSpan={100} className="p-0">
        <DraggableTableDropRowPlaceholder
          droppableId={
            priority > 0
              ? TechnicalOrderOrderedDroppableIds.TECHNICAL_ORDER_HIGH_PRIORITY_EMPTY
              : TechnicalOrderOrderedDroppableIds.TECHNICAL_ORDER_NORMAL_PRIORITY_EMPTY
          }
          dropMessage="Upuść aby dodać"
          subtitle="Brak zadań"
        />
      </td>
    </tr>
  </tbody>
)
