import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { ReservationGuestDataActions } from '@modules/reservations/details/base-data/guest/reservation-guest-data-actions'
import { ReceptionBookingDetails } from '@models/reception'

import { ReservationGuestData } from '@modules/reservations/details/base-data/guest/reservation-guest-data'

interface Props {
  booking: ReceptionBookingDetails
}

export const ReservationGuest = ({ booking }: Props): JSX.Element => (
  <div className="w-100">
    <div className="d-flex justify-content-between align-items-center">
      <IconWithText
        icon="uil-user font-18"
        text="Dane Gościa"
        wrapperClassNames="text-secondary text-semi-strong mb-2"
        textClass="fw-bold font-18 text-nowrap"
      />
      <ReservationGuestDataActions booking={booking} />
    </div>
    <div>
      <ReservationGuestData booking={booking} withProfilePreview clientId={booking.client_id} />
    </div>
  </div>
)
