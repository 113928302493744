import * as React from 'react'
import { BeddingOrder, CleaningOrder, ImprovementOrder } from '@modules/housekeeping/models'
import { getById } from '@helpers/utils'
import { useHousekeepingCleaningCompanies } from '@modules/housekeeping/users/use-housekeeping-cleaning-companies'

interface Props {
  order: CleaningOrder | BeddingOrder | ImprovementOrder | null
  resortId: number
}

export const HousekeepingOrderCompanyRow: React.FC<Props> = ({ resortId, order }) => {
  const companies = useHousekeepingCleaningCompanies(resortId)
  const company = getById(companies, order?.company_id)

  return <td className="housekeeping__orders_table__column">{company?.name || ''}</td>
}
