import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { TopBarLoading } from '@components/topbar-loading'
import { useAppDispatch, useAppSelector } from '@store/index'
import { CancelTokenSource } from 'axios'
import { calculateReservationPrice, checkAccommodationTypeAvailable } from '@store/actions/reception-actions'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useCancelablePromise } from '@components/hooks/use-cancelable-promise'
import { receptionBookingDetailsSelector } from '@store/selectors/reception'
import { compareAsc } from 'date-fns'
import { setErrorForDynamicKey, useHandleAxiosFormErrors } from '@helpers/handle-axios-errors'
import { StepReservationInfoFormInputs } from '@modules/reservations/create/models'
import { ClientUser } from '@models/clients'

interface Props {
  client: ClientUser | undefined
}

export const CalculateStepReservationInfoCalculate: React.FC<Props> = ({ client }) => {
  const reservation = useAppSelector(receptionBookingDetailsSelector)
  const { watch, setError } = useFormContext<StepReservationInfoFormInputs>()
  const dispatch = useAppDispatch()

  const handleAxiosFormErrors = useHandleAxiosFormErrors()

  const { resort, accommodation_type, date_from, date_to, apartment, promocode } = watch()
  const { isLoading: isFetchingCalculation, action: fetchCalculation } = useApiRequest(
    async (cancelToken: CancelTokenSource) => {
      try {
        if (
          !reservation &&
          resort &&
          accommodation_type &&
          date_from &&
          date_to &&
          compareAsc(date_from, date_to) !== 0
        ) {
          await dispatch(
            calculateReservationPrice(
              resort.value,
              accommodation_type.value,
              date_from,
              date_to,
              apartment?.value,
              client,
              promocode?.value,
              cancelToken,
            ),
          )
        }
      } catch (error) {
        handleAxiosFormErrors(error, setErrorForDynamicKey('date_from', setError), { showGlobalError: false })
      }
    },
  )
  const { isLoading: isFetchingAccommodations, action: fetchAccommodationTypes } = useApiRequest(
    async (cancelToken: CancelTokenSource) => {
      if (date_to && date_from && resort && compareAsc(date_from, date_to) !== 0) {
        await dispatch(
          checkAccommodationTypeAvailable(
            { date_to, date_from, resort: resort.value, client, code: promocode?.value },
            cancelToken,
          ),
        )
      }
    },
  )

  useCancelablePromise(fetchCalculation, [resort, accommodation_type, date_from, date_to, apartment, client])
  useCancelablePromise(
    cancelToken => {
      if (resort && date_from && date_to) {
        fetchAccommodationTypes(cancelToken)
      }
    },
    [resort, date_from, date_to, client],
  )

  return isFetchingCalculation || isFetchingAccommodations ? <TopBarLoading role="calculation-top-bar" /> : null
}
