import * as React from 'react'
import { CustomInput } from 'reactstrap'
import { CustomSwitch } from '@components/custom-switch'
import { formatPriceShort } from '@helpers/utils'
import { BookingGuestFeedingOption } from '@models/reception'
import { formatDate } from '@helpers/date-helper'
import { PriceLoader } from '@modules/reception/checkin/step-feeding/price-loader'

export interface FeedingExpandedRowReadOnly {
  dinner: boolean
  breakfast: boolean
  full: boolean
}

interface ReceptionBookingFeedingExpandedRowProps {
  feedingOption: BookingGuestFeedingOption
  price: number
  isFirstDay: boolean
  isLastDay: boolean
  readOnly?: FeedingExpandedRowReadOnly

  setFeedingOptionForGuest: (
    guestId: number,
    dinner: boolean | undefined,
    breakfast: boolean | undefined,
    dates?: string[],
  ) => void
}

export const ReceptionBookingFeedingExpandedRow: React.FC<ReceptionBookingFeedingExpandedRowProps> = ({
  feedingOption,
  price,
  readOnly,
  isFirstDay,
  isLastDay,
  setFeedingOptionForGuest,
}) => {
  const prefix = `${feedingOption.guest_id}-${feedingOption.date}`

  const breakfastInput = `${prefix}-breakfast`
  const dinnerInput = `${prefix}-dinner`
  const fullInput = `${prefix}-full`

  const handleDinner = (event: React.ChangeEvent<HTMLInputElement>) =>
    setFeedingOptionForGuest(feedingOption.guest_id, event.target.checked, undefined, [feedingOption.date])

  const handleBreakfast = (event: React.ChangeEvent<HTMLInputElement>) =>
    setFeedingOptionForGuest(feedingOption.guest_id, undefined, event.target.checked, [feedingOption.date])

  const handleFull = (event: React.ChangeEvent<HTMLInputElement>) =>
    setFeedingOptionForGuest(feedingOption.guest_id, event.target.checked, event.target.checked, [feedingOption.date])

  return (
    <tr>
      <td className="reception__booking-check-in__feeding__name-col pb-0">
        <div className="d-flex justify-content-end">
          <span className="text-muted font-11 d-inline-block mr-1">{formatDate(feedingOption.date, 'EEEEEE')}</span>
          <span className="text-semi-strong font-12">{formatDate(feedingOption.date, 'd.MM')}</span>
        </div>
      </td>
      <td className="reception__booking-check-in__feeding__bb-col pb-0">
        <CustomInput
          id={breakfastInput}
          type="checkbox"
          className="text-semi-strong font-13"
          name={breakfastInput}
          checked={feedingOption.breakfast}
          onChange={handleBreakfast}
          disabled={isFirstDay || readOnly?.breakfast || !feedingOption.is_breakfast_change_allowed}
        />
      </td>
      <td className="reception__booking-check-in__feeding__hb-col pb-0">
        <CustomInput
          disabled={isLastDay || readOnly?.dinner || !feedingOption.is_dinner_change_allowed}
          id={dinnerInput}
          type="checkbox"
          className="text-semi-strong font-13 pb-0"
          name={dinnerInput}
          checked={feedingOption.dinner}
          onChange={handleDinner}
        />
      </td>
      <td className="reception__booking-check-in__feeding__fb-col pb-0">
        <CustomSwitch
          readOnly={
            readOnly?.full ||
            (isLastDay || isFirstDay
              ? !feedingOption.is_dinner_change_allowed || !feedingOption.is_breakfast_change_allowed
              : !feedingOption.is_dinner_change_allowed && !feedingOption.is_breakfast_change_allowed)
          }
          handleSwitchChange={handleFull}
          label="Pełne"
          value={
            isLastDay || isFirstDay
              ? feedingOption.breakfast || feedingOption.dinner
              : feedingOption.breakfast && feedingOption.dinner
          }
          id={fullInput}
          inputName={fullInput}
          labelClassName="font-12"
        />
      </td>
      <td className="reception__booking-check-in__feeding__unit-price-col text-semi-strong pb-0" colSpan={3}>
        <PriceLoader price={price} content={<span>{formatPriceShort(price)}</span>} />
      </td>
    </tr>
  )
}
