import { GastroCard, GastroCardAggregation, GastroCardDetails } from '@models/promotions'
import { AsyncThunkParams, RootState } from '@store/index'
import { setDashboardStandardPaginator } from '@store/actions/dashboard-actions'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { commonObjectGet, commonObjectPost, wrapWithError } from '@store/actions/generic-actions'
import { GastroCardsFiltersParams } from '@modules/promotions/gastro-cards/models'
import { ListDataWithAggregation, PaginationResponseWithAggregation } from '@models/dashboard'
import { extractSelectOptionsValues } from '@helpers/utils'
import { getGastroProductApiUrl } from '@modules/promotions/gastro-generic-product/utils'

export const getGastroCardDetail = createAsyncThunk<GastroCardDetails, GastroCard | GastroCardDetails>(
  'gastroCard/getGastroCardDetail',
  async gastroCard => await commonObjectGet<GastroCardDetails>(gastroCard.urls.details),
)

export const getGastroCards = createAsyncThunk<
  ListDataWithAggregation<GastroCard[], GastroCardAggregation>,
  [Partial<GastroCardsFiltersParams>, string | undefined],
  AsyncThunkParams
>('gastroCard/getGastroCards', async ([params, url], { dispatch, getState }) => {
  const gastroCardsUrl = url || getState().appState.appData.urls.promotions.gastro_cards

  const data = await commonObjectGet<PaginationResponseWithAggregation<GastroCard, GastroCardAggregation>>(
    gastroCardsUrl,
    extractSelectOptionsValues(params),
  )

  dispatch(setDashboardStandardPaginator(data))
  return { results: data.results, aggregation: data.aggregation }
})

export const createGastroCards = createAsyncThunk<GastroCardDetails, any, { state: RootState }>(
  'gastroCard/createGastroCards',
  async (data, store) => {
    const urls = store.getState().appState.appData.urls
    const { gastro_product_kind, ...payload } = data

    const url =
      payload.kind === 'gastro_coupon'
        ? getGastroProductApiUrl(gastro_product_kind, urls)
        : urls.promotions.gastro_cards

    return await wrapWithError(commonObjectPost<GastroCardDetails>(url, payload))
  },
)
