import * as React from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useFormRequest } from '@components/hooks/use-api-request'
import { updateClientDetails } from '@store/actions/crm-actions'
import { commonObjectPost, commonObjectPut } from '@store/actions/generic-actions'
import { ClientDetails, ClientProfileGuest } from '@models/clients'
import { Button, Col, Label, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { FormInput, FormPlain } from '@hyper/forms'
import DatePickerInput from '@components/date/date-picker-input'
import { startOfToday } from 'date-fns'
import { formatDate, parseISODate } from '@helpers/date-helper'
import { BaseModalProps } from '@components/modals/types'
import { Form } from '@hyper/forms/form'

interface Props extends BaseModalProps {
  client: ClientDetails
  guest?: ClientProfileGuest
}

export interface FormInputs {
  name: string
  phone: string
  email: string
  birthday: Date | undefined
  type: 'adult' | 'child'
}

export const ClientProfileGuestModal: React.FC<Props> = ({ toggleIsVisible, client, guest }) => {
  const methods = useForm<FormInputs>({
    defaultValues: {
      ...guest,
      birthday: parseISODate(guest?.birthday) || undefined,
      type: guest?.type === 'adult' ? 'adult' : 'child',
    },
  })
  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    dispatch(
      updateClientDetails(
        !guest
          ? await commonObjectPost<ClientDetails>(client.urls.guests, {
              ...payload,
              birthday: payload.type === 'adult' ? undefined : formatDate(payload.birthday),
            })
          : await commonObjectPut<ClientDetails>(guest.urls.details, {
              ...payload,
              birthday: payload.type === 'adult' ? undefined : formatDate(payload.birthday),
            }),
      ),
    )
    addSuccessNotification(!guest ? 'Osoba towarzysząca została dodana' : 'Osoba towarzysząca została zmieniona')
    toggleIsVisible()
  }, methods.setError)

  const type = useWatch({ control: methods.control, name: 'type' })

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>
        {!guest ? 'Dodaj osobę towarzyszącą' : `Edytuj osobę towarzyszącą ${guest.name}`}
      </ModalHeader>
      <ModalBody>
        <Row>
          <FormInput colSize={6} label="Imię i nazwisko" name="name" />
          <Col md={6} />
          <FormInput colSize={6} label="Adres email" name="email" />
          <FormInput colSize={6} label="Numer telefonu" name="phone" />
          <FormInput type="select" colSize={6} label="Wiek" name="type">
            <option value="adult">Dorosły</option>
            <option value="child">Dziecko</option>
          </FormInput>
          {type !== 'adult' && (
            <FormPlain colSize={6} name="birthday">
              <Label>Data urodzenia</Label>
              <DatePickerInput name="birthday" wrapperClassName="flex-grow-1" maxDate={startOfToday()} />
            </FormPlain>
          )}
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Anuluj
        </Button>
        <SaveButton className="btn btn-green" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
