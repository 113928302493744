import { CmsAppData } from '@models/cms'
import { EmailTemplate, EmailTemplateDetails, SmsTemplate } from '@modules/cms/models'

export enum CmsActionTypesKeys {
  SET_APP_DATA = 'SET_APP_DATA',
  SET_EMAIL_TEMPLATES = 'SET_EMAIL_TEMPLATES',
  SET_EMAIL_TEMPLATE_DETAILS = 'SET_EMAIL_TEMPLATE_DETAILS',
  SET_SMS_TEMPLATES = 'SET_SMS_TEMPLATES',
}

export interface SetCmsAppDataAction {
  type: CmsActionTypesKeys.SET_APP_DATA
  payload: CmsAppData
}

export interface SetCmsEmailTemplateDetailsAction {
  type: CmsActionTypesKeys.SET_EMAIL_TEMPLATE_DETAILS
  payload: EmailTemplateDetails | undefined
}

export interface SetCmsEmailTemplatesAction {
  type: CmsActionTypesKeys.SET_EMAIL_TEMPLATES
  payload: EmailTemplate[]
}

export interface SetCmsSmsTemplatesAction {
  type: CmsActionTypesKeys.SET_SMS_TEMPLATES
  payload: SmsTemplate[]
}

export type CmsActionsTypes =
  | SetCmsAppDataAction
  | SetCmsEmailTemplatesAction
  | SetCmsSmsTemplatesAction
  | SetCmsEmailTemplateDetailsAction
