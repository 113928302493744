import * as React from 'react'
import {
  CartPaymentMethodContext,
  ReceptionBookingCartPaymentMethods,
} from '@modules/reception/cart/payment-box/payment-options/reception-booking-cart-payment-methods'
import { ReceptionBookingCartSelectedPaymentBox } from '@modules/reception/cart/payment-box/reception-booking-cart-selected-payment-box'
import { ReceptionBookingCartPayment } from '@modules/reception/cart/payment-box/payment-options-content/reception-booking-cart-payment'
import { ReceptionBookingClientWallet, ReceptionBookingDetails, ReceptionBookingDetailsCart } from '@models/reception'
import { ReceptionBookingCartPaymentBoxPrice } from '@modules/reception/cart/payment-box/reception-booking-cart-payment-box-price'
import {
  createCombinedCartItemsPrice,
  getSelectedBookingCartItems,
  isGastroVoucherCartPayment,
} from '@modules/reception/cart/utils'
import { asDecimal } from '@helpers/utils'
import { useClientWallets } from '@modules/reception/cart/payment-box/use-client-wallets'
import { CartPaymentMethod, ReceptionBookingCartFormInputs } from '@modules/reception/cart/models'
import classNames from 'classnames'
import { useAnimatedPriceChange } from '@components/hooks/use-animated-price-change'

export interface CartSelection {
  items: number[]
  amount: string
}

interface Props {
  onPaymentMethodChange: (paymentMethod: CartPaymentMethod | null) => void
  selectedPaymentMethod: CartPaymentMethod | null
  cart: ReceptionBookingDetailsCart
  formCartItems: ReceptionBookingCartFormInputs
  onCartUpdate: (cart: ReceptionBookingDetailsCart) => void
  bookingDetails: ReceptionBookingDetails
}

export const ReceptionBookingCartPaymentBox = ({
  selectedPaymentMethod,
  onPaymentMethodChange,
  cart,
  formCartItems,
  onCartUpdate,
  bookingDetails,
}: Props): JSX.Element => {
  const [paymentContext, setPaymentContext] = React.useState<CartPaymentMethodContext | null>(null)
  const { wallets } = useClientWallets()

  const selectedItems = getSelectedBookingCartItems(cart.items, formCartItems)

  const combinedCartPrice = createCombinedCartItemsPrice(getSelectedBookingCartItems(cart.items, formCartItems))

  const cartSelection: CartSelection = selectedItems.reduce(
    (cumulatedPayload, cartItem) => {
      const amount =
        selectedPaymentMethod && isGastroVoucherCartPayment(selectedPaymentMethod)
          ? cartItem.price_brutto_without_discount
          : cartItem.price_brutto

      return {
        items: [...cumulatedPayload.items, cartItem.id],
        amount: asDecimal(cumulatedPayload.amount).plus(amount).toString(),
      }
    },
    { items: [], amount: '0' },
  )

  const leftDeposit = asDecimal(bookingDetails.prices.deposit_amount).minus(bookingDetails.prices.deposit_charge)

  const isWalletPaymentDisabled = selectedItems.some(item => item.kind !== 'feeding')
  const isDepositPaymentDisabled = asDecimal(cartSelection.amount).gt(leftDeposit)

  const handlePaymentMethodChange = (paymentMethod: CartPaymentMethod | null, context?: CartPaymentMethodContext) => {
    setPaymentContext(context ?? null)
    onPaymentMethodChange(paymentMethod)
  }

  const availableWallets = wallets.filter(
    (wallet: ReceptionBookingClientWallet) =>
      wallet.is_active && ['gastro_card', 'gastro_coupon'].includes(wallet.voucher_kind),
  )

  const price = useAnimatedPriceChange(
    isGastroVoucherCartPayment(selectedPaymentMethod)
      ? combinedCartPrice.originalTotalPrice
      : combinedCartPrice.totalPrice,
  )

  return (
    <div className="col-4">
      <div>
        <strong className="text-secondary font-18">Wybierz formę płatności</strong>
        <ReceptionBookingCartPaymentBoxPrice price={price} />
        {selectedPaymentMethod ? (
          <ReceptionBookingCartSelectedPaymentBox
            className={classNames({ 'px-3': selectedPaymentMethod !== 'band' })}
            onReturn={() => onPaymentMethodChange(null)}
          >
            <ReceptionBookingCartPayment
              selectedPayment={selectedPaymentMethod}
              onPaymentMethodChange={onPaymentMethodChange}
              cartSelection={cartSelection}
              cartUrls={cart.urls}
              onCartUpdate={onCartUpdate}
              paymentContext={paymentContext}
              bookingId={bookingDetails.id}
            />
          </ReceptionBookingCartSelectedPaymentBox>
        ) : (
          <ReceptionBookingCartPaymentMethods
            availablePaymentMethods={cart.available_payment_methods}
            onPaymentSelection={handlePaymentMethodChange}
            wallets={availableWallets}
            isDisabled={asDecimal(cartSelection.amount).lte(0)}
            isWalletDisabled={isWalletPaymentDisabled}
            isDepositDisabled={isDepositPaymentDisabled}
            combinedCartPrice={combinedCartPrice}
          />
        )}
      </div>
    </div>
  )
}
