import * as React from 'react'
import { TechnicalOrderResult } from '@models/technical-orders'
import { TechnicalOrderCloseFormResult } from '@modules/technical-orders/technical-order-close-modal/form-result'
import { TechnicalOrderCloseFormRejectReason } from '@modules/technical-orders/technical-order-close-modal/form-reject-reason'

interface Props {
  result: TechnicalOrderResult
}

export const TechnicalOrderCloseForm: React.FC<Props> = ({ result }) => (
  <>
    <TechnicalOrderCloseFormResult result={result} />
    {result === 'rejected' && <TechnicalOrderCloseFormRejectReason />}
  </>
)
