import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { ReservationBaseDataRows } from '@modules/reservations/details/reservation-data/reservation-base-data-rows'
import { ReceptionBookingDetailsDataButtons } from '@modules/reception/details/reception-booking-details-data-buttons'
import { ReceptionBookingDetailsRules } from '@modules/reception/details/reception-booking-details-rules'
import { ClientProfileRule } from '@models/clients'
import { useClientRules } from '@components/hooks/use-client-rules'
import { LoaderPlaceholder } from '@components/loader-placeholder'

interface ReceptionBookingDetailsDataProps {
  booking: ReceptionBookingDetails
}

export const ReceptionBookingDetailsData: React.FC<ReceptionBookingDetailsDataProps> = ({ booking }) => {
  const [clientProfileRules, setClientProfileRules] = React.useState<ClientProfileRule | null>(null)
  const { fetchClientRules } = useClientRules(booking.urls.client_rules)

  const fetchRules = async () => {
    setClientProfileRules(await fetchClientRules())
  }

  React.useEffect(() => {
    fetchRules()
  }, [])

  return (
    <LoaderPlaceholder content={clientProfileRules}>
      <ReceptionBookingDetailsDataButtons booking={booking} />
      <ReservationBaseDataRows booking={booking} />
      {clientProfileRules && <ReceptionBookingDetailsRules clientProfileRule={clientProfileRules} />}
    </LoaderPlaceholder>
  )
}
