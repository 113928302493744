import * as React from 'react'
import { formatPriceShort } from '@helpers/utils'

interface Props {
  label: string
  value: number | undefined | string
}

export const TotalPriceRowGuestsFeedingClientsCreateDialogTotalPriceRow: React.FC<Props> = ({ value, label }) => {
  if (!value) {
    return null
  }

  return (
    <div>
      <span className="text-semi-strong">{label}:</span> <strong>{formatPriceShort(value)}</strong>
    </div>
  )
}
