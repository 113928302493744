import * as React from 'react'
import { TableFilters, TableHeaderValue } from '@components/table/table'
import { AgreementHighPriorityListTable } from '@modules/hr/agreement/list/high-priority-table'
import { AgreementListTable } from '@modules/hr/agreement/list/table'
import { ContentLoader } from '@components/content-loader'

const headerValues: TableHeaderValue[] = [
  { title: 'Numer umowy', sortField: 'number' },
  { title: 'Status', sortField: 'status' },
  { title: 'Spółka', sortField: 'company__name' },
  { title: 'Resorty' },
  { title: 'Imię i nazwisko', sortField: 'first_name' },
  { title: 'Stanowisko', sortField: 'job_position__name' },
  { title: 'Stawka godzinowa', sortField: 'hourly_rate' },
  { title: 'Data rozpoczęcia umowy', sortField: 'date_of_start' },
  { title: 'Data zakończenia umowy', sortField: 'date_of_expiration' },
  { title: '' },
]

interface Props {
  isLoading: boolean
  filters: TableFilters
  setFilters: (filters: TableFilters) => void
}

export const AgreementList: React.FC<Props> = ({ isLoading, filters, setFilters }) => (
  <ContentLoader isLoading={isLoading}>
    <AgreementHighPriorityListTable headers={headerValues} filters={filters} setFilters={setFilters} />
    <AgreementListTable headers={headerValues} filters={filters} setFilters={setFilters} />
  </ContentLoader>
)
