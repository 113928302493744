import * as React from 'react'
import { NavigationItems } from '@components/nav-items'
import { ReceptionBookingDetails } from '@models/reception'

export type ReservationNotificationTab = 'email' | 'sms'

const AvailableTabs = [
  { id: 'email', title: 'E-mail' },
  { id: 'sms', title: 'SMS' },
]

interface Props {
  booking: ReceptionBookingDetails
  activeTab: ReservationNotificationTab
  setActiveTab: (tab: ReservationNotificationTab) => void
}

export const ReservationNotificationsTabs = ({ booking, activeTab, setActiveTab }: Props): React.ReactNode => {
  const activeItem = AvailableTabs.find(tab => tab.id === activeTab)

  const isNotificationMuted = item => {
    if (item.id === 'sms') return !booking.sms_notifications_enabled
    if (item.id === 'email') return !booking.email_notifications_enabled
    return false
  }

  return (
    <NavigationItems
      className="mb-2 mt-n2"
      items={AvailableTabs}
      activeItem={activeItem}
      renderItem={item => <NavigationTab isMuted={isNotificationMuted(item)} {...item} />}
      onClick={setActiveTab}
    />
  )
}

const NavigationTab = ({ isMuted, title }) => (
  <div>
    {title}
    {isMuted && <i className="uil-bell-slash ml-1" />}
  </div>
)
