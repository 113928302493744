import { createSelectOption } from '@helpers/utils'
import * as React from 'react'
import { SubscriptionKind } from '@modules/subscription/models'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useProductsAppData } from '@modules/products/hooks/use-products-app-data'

export const useSubscriptionKinds = () => {
  const {
    subscription_kind: subscriptionKinds,
    subscription_kind_versions: subscriptionKindVersions,
    status,
  } = useProductsAppData()

  const subscriptionKindVersionOptions = React.useMemo(
    () =>
      subscriptionKindVersions.map(row => ({
        label: row.display_name,
        value: row.id,
        kind: row.kind,
      })),
    [subscriptionKindVersions],
  )

  const subscriptionKindOptions = React.useMemo(
    () => subscriptionKinds.map(row => createSelectOption<string, SubscriptionKind>(row[1], row[0])),
    [subscriptionKinds],
  )

  const getSubscriptionKindVersionOptions = React.useCallback(
    (subscriptionKind: CustomReactSelectOption | undefined | null) =>
      subscriptionKindVersionOptions.filter(row => (subscriptionKind ? row.kind === subscriptionKind.value : false)),
    [subscriptionKindVersionOptions],
  )

  return {
    subscriptionKindOptions,
    subscriptionKindVersionOptions,
    getSubscriptionKindVersionOptions,
    appDataStatus: status,
  }
}
