import * as React from 'react'
import { CleaningOrder, CleaningOrderDetails } from '@modules/housekeeping/models'
import { Button, Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { commonObjectGet } from '@store/actions/generic-actions'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { HousekeepingCleaningOrderDetailsSummary } from '@modules/housekeeping/cleaning-orders/details/summary'
import { HousekeepingCleaningOrderDetailsNotes } from '@modules/housekeeping/cleaning-orders/details/notes'
import { BaseModalProps } from '@components/modals/types'

interface HousekeepingCleaningOrderDetailsModalProps extends BaseModalProps {
  cleaningOrder: CleaningOrder
}

export const HousekeepingCleaningOrderDetailsModal: React.FC<HousekeepingCleaningOrderDetailsModalProps> = ({
  cleaningOrder,
  toggleIsVisible,
}) => {
  const [cleaningOrderDetails, setCleaningOrderDetails] = React.useState<CleaningOrderDetails | undefined>(undefined)

  const fetchCleaningORderDetails = async () =>
    setCleaningOrderDetails(await commonObjectGet<CleaningOrderDetails>(cleaningOrder.urls.details))

  React.useEffect(() => {
    fetchCleaningORderDetails()
  }, [])

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>
        <span className="text-semi-strong">Szczegóły zlecenia sprzątania lokalu</span>{' '}
        <strong className="text-strong">{cleaningOrder.apartment.name}</strong>
      </ModalHeader>
      <ModalBody>
        <LoaderPlaceholder content={cleaningOrderDetails}>
          {cleaningOrderDetails && (
            <Row>
              <Col md={4}>
                <HousekeepingCleaningOrderDetailsSummary cleaningOrderDetails={cleaningOrderDetails} />
              </Col>
              <Col md={8}>
                <HousekeepingCleaningOrderDetailsNotes cleaningOrderDetails={cleaningOrderDetails} />
              </Col>
            </Row>
          )}
        </LoaderPlaceholder>
      </ModalBody>
      <ModalFooter>
        <Button color="green" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
      </ModalFooter>
    </>
  )
}
