import * as React from 'react'
import { ReceptionBooking } from '@models/reception'
import { formatPriceShort } from '@helpers/utils'

interface ReceptionRowRestToPayProps {
  booking: ReceptionBooking
}

export const ReceptionRowRestToPay: React.FC<ReceptionRowRestToPayProps> = ({ booking }) => (
  <>
    <div className="mb-1">Do zapłaty:</div>

    <strong>{formatPriceShort(booking.rest_to_pay)}</strong>
  </>
)
