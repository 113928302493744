import * as React from 'react'
import { Button, Col, Input, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { getPromocodeEmailTemplates } from '@store/actions/promocode-actions'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { Promocode, PromocodeDetails } from '@models/promocode'
import { FormInput } from '@hyper/forms'
import { useForm } from 'react-hook-form'
import { HtmlEditorLazy } from '@components/html-editor/html-editor-lazy'
import { extractInnerRef } from '@helpers/forms'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useFormRequest } from '@components/hooks/use-api-request'
import { Form } from '@hyper/forms/form'
import { BaseModalProps } from '@components/modals/types'
import { updatePromocodeCodeDetails } from '@store/slices/promocodes-slice'

interface Props extends BaseModalProps {
  promocode: Promocode
}

interface FormInputs {
  name: string
  email: string
  notification: string
}

export const PromocodeCodeSendDialog: React.FC<Props> = ({ promocode, toggleIsVisible }) => {
  const [content, setContent] = React.useState('')
  const [contentHtml, setContentHtml] = React.useState('')
  const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()

  const methods = useForm<FormInputs>({
    defaultValues: {
      name: promocode.name,
      email: '',
      notification: '',
    },
  })

  const notification = methods.watch('notification')

  const emailTemplates = useAppSelector((state: RootState) => state.promocodesState.emailTemplates)

  React.useEffect(() => {
    if (notification) {
      const html = emailTemplates.find(template => template.name === notification)?.html || ''
      setContentHtml(html)
      setContent(html)
    }
  }, [notification])

  React.useEffect(() => {
    dispatch(getPromocodeEmailTemplates(promocode.id))
  }, [])

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    dispatch(
      updatePromocodeCodeDetails(
        await commonObjectPost<PromocodeDetails>(promocode.urls.send, {
          ...payload,
          content,
          notification: payload.notification === 'Brak' ? null : payload.notification,
        }),
      ),
    )
    addSuccessMessage('Wiadomość e-mail została wysłana', ' ')
    toggleIsVisible()
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Wiadomość e-mail</ModalHeader>
      <ModalBody>
        <Row>
          <FormInput colSize={6} label="Imię i nazwisko" name="name" />
          <FormInput colSize={6} label="Adres e-mail" name="email" />
        </Row>

        <HtmlEditorLazy setContent={setContent} disabled={!notification} contentHtml={contentHtml} />
      </ModalBody>
      <ModalFooter style={{ display: 'block' }}>
        <Row>
          <Col md={6} className="pl-0">
            <Input type="select" {...extractInnerRef(methods.register('notification'))}>
              <option value="">Wybierz</option>
              {emailTemplates.map(value => (
                <option value={value.name} key={value.name}>
                  {value.label}
                </option>
              ))}
            </Input>
          </Col>
          <Col md={6} className="text-right pr-0">
            <Button color="light" onClick={toggleIsVisible}>
              Anuluj
            </Button>
            <SaveButton
              label="Wyślij e-mail"
              disabled={!notification}
              labelSaving="Wysyłanie"
              className="btn ml-1 btn-green"
              isSaving={isLoading}
            />
          </Col>
        </Row>
      </ModalFooter>
    </Form>
  )
}
