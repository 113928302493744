import * as React from 'react'
import { useAppDispatch } from '@store/index'
import { NotificationEvents } from '@models/dashboard'
import { useWebSocket } from '@components/hooks/use-web-socket'
import { Receipt, ShopProductWebsocketEvent } from '@models/shop'
import { getShopProducts } from '@store/actions/shop-actions'
import { setShopReceipt } from '@store/slices/shop-slice'

interface Props {
  resortId: number
}

export const ShopWebSocketHandler: React.FC<Props> = ({ resortId }) => {
  const dispatch = useAppDispatch()

  useWebSocket<ShopProductWebsocketEvent | Receipt>(
    (message, payload) => {
      switch (message) {
        case NotificationEvents.RECEIPT_CHANGE: {
          dispatch(setShopReceipt(payload as Receipt))
          break
        }
        case NotificationEvents.PRODUCT_CHANGE: {
          const productDetails = payload as ShopProductWebsocketEvent

          if (productDetails.resort_id === resortId) {
            dispatch(getShopProducts(String(resortId)))
          }
          break
        }
      }
    },
    [],
    [NotificationEvents.RECEIPT_CHANGE, NotificationEvents.PRODUCT_CHANGE],
  )

  return null
}
