import { AsyncThunkParams, RootState } from '@store/index'
import { commonObjectGet, commonObjectPatch, wrapWithError } from '@store/actions/generic-actions'
import { setDashboardStandardPaginator } from '@store/actions/dashboard-actions'
import { ListDataWithAggregation, PaginationResponse, PaginationResponseWithAggregation } from '@models/dashboard'
import {
  PackageWholesale,
  PackageWholesaleAggregation,
  PackageWholesaleAppData,
  PackageWholesaleDetails,
  PackageWholesaleProduct,
} from '@modules/package-wholesale/models'
import { PackageWholesaleProductListFilterParams } from '@modules/package-wholesale/products/list/filter'
import { PackageWholesaleListFilterParams } from '@modules/package-wholesale/list/filter'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { PackageWholesaleDetailsBaseFormInputs } from '@modules/package-wholesale/details/base/form'
import { extractSelectOptionsValues } from '@helpers/utils'

interface GetPackageWholesaleProductsParams extends PackageWholesaleProductListFilterParams {
  noPagination?: boolean
}

export const getPackageWholesaleProducts = createAsyncThunk<
  PackageWholesaleProduct[],
  Partial<GetPackageWholesaleProductsParams> | undefined,
  AsyncThunkParams
>('packageWholesale/getPackageWholesaleProducts', async (filters, { getState, dispatch }) => {
  const url = getState().appState.appData.urls.package_wholesale.products

  const data = await commonObjectGet<PaginationResponse<PackageWholesaleProduct>>(
    url,
    extractSelectOptionsValues(filters || {}),
  )

  if (!filters?.noPagination) {
    dispatch(setDashboardStandardPaginator(data))
  }
  return data.results
})

export const getPackageWholesales = createAsyncThunk<
  ListDataWithAggregation<PackageWholesale[], PackageWholesaleAggregation>,
  PackageWholesaleListFilterParams,
  AsyncThunkParams
>('packageWholesale/getPackageWholesales', async (filters, { getState, dispatch }) => {
  const url = getState().appState.appData.urls.package_wholesale.package_wholesales

  const data = await commonObjectGet<PaginationResponseWithAggregation<PackageWholesale, PackageWholesaleAggregation>>(
    url,
    extractSelectOptionsValues(filters),
  )

  dispatch(setDashboardStandardPaginator(data))
  return { results: data.results, aggregation: data.aggregation }
})

export const getPackageWholesaleDetails = createAsyncThunk<PackageWholesaleDetails, string, { state: RootState }>(
  'packageWholesale/getPackageWholesaleDetails',
  async url => await commonObjectGet<PackageWholesaleDetails>(url),
)

export const getPackageWholesaleAppData = createAsyncThunk<PackageWholesaleAppData, void, AsyncThunkParams>(
  'packageWholesale/getPackageWholesaleAppData',
  async (_, { getState }) => {
    const appData = getState().packageWholesaleState.appData
    if (appData.status === 'unknown') {
      return await commonObjectGet<PackageWholesaleAppData>(getState().appState.appData.urls.package_wholesale.app_data)
    }
    return appData
  },
)

export const updatePackageWholesale = createAsyncThunk<
  PackageWholesaleDetails,
  [string, PackageWholesaleDetailsBaseFormInputs | FormData]
>(
  'packageWholesale/updatePackageWholesale',
  async ([url, payload]) => await wrapWithError(commonObjectPatch<PackageWholesaleDetails>(url, payload)),
)
