import * as React from 'react'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectGet } from '@store/actions/generic-actions'
import { AvailableApartment } from '@models/reservation-create'
import { formatDate } from '@helpers/date-helper'
import { useAppData } from '@components/hooks/use-app-data'

interface FetchAvailableApartmentsParams {
  accommodation_type?: number
  resort: number
  date_from: Date | null
  date_to: Date | null
}

interface UseAvailableApartmentsResponse {
  isLoading: boolean
  fetchApartments: (params: FetchAvailableApartmentsParams) => void
  apartments: AvailableApartment[]
}

export const useAvailableApartments = (): UseAvailableApartmentsResponse => {
  const [apartments, setApartments] = React.useState<AvailableApartment[]>([])

  const appData = useAppData()

  const { action: fetchApartments, isLoading } = useApiRequest(async (params: FetchAvailableApartmentsParams) => {
    if (Object.values(params).every(Boolean)) {
      setApartments(
        await commonObjectGet<AvailableApartment[]>(appData.urls.reservation.available_apartments, {
          resort: params.resort,
          date_from: formatDate(params.date_from),
          date_to: formatDate(params.date_to),
          mode: 'block',
          ...(params.accommodation_type && { accommodation_type: params.accommodation_type }),
        }),
      )
      return
    }

    setApartments([])
  })

  return {
    isLoading,
    fetchApartments,
    apartments,
  }
}
