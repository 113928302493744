import * as React from 'react'
import { FormInput } from '@hyper/forms'
import { SubscriptionTypeStrategy } from '@store/slices/subscription-slice'

interface Props {
  index: number
  subscriptionTypeStrategy: SubscriptionTypeStrategy
}
export const SubscriptionProductCreateFormCodeFeaturesField: React.FC<Props> = ({
  subscriptionTypeStrategy,
  index,
}) => (
  <tr key={index}>
    <td>{index + 1}.</td>
    <td>
      <FormInput
        name={`code_features.${index}.years`}
        formGroupClassName="mb-0"
        colClassName="p-0"
        inputClassName="form-control-sm"
      />
    </td>
    {subscriptionTypeStrategy.allow_add_gastro_gift_to_product && (
      <td>
        <FormInput
          type="number"
          colClassName="p-0"
          placeholder=""
          name={`code_features.${index}.gastro_gift`}
          formGroupClassName="mb-0"
          inputClassName="form-control-sm"
        />
      </td>
    )}
    {subscriptionTypeStrategy.allow_add_package_vip_to_product && (
      <td>
        <FormInput
          type="select"
          colClassName="p-0"
          name={`code_features.${index}.package_vip`}
          formGroupClassName="mb-0"
          inputClassName="form-control-sm"
        >
          <option value="">Brak</option>
          <option value="standard">Standardowy</option>
          <option value="winter">Zimowy</option>
        </FormInput>
      </td>
    )}
  </tr>
)
