import * as React from 'react'
import { useForm } from 'react-hook-form'
import { Button, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAppDispatch } from '@store/index'
import { useFormRequest } from '@components/hooks/use-api-request'
import { SmsTemplate } from '@modules/cms/models'
import { FormInput } from '@hyper/forms'
import { commonObjectPost, commonObjectPut } from '@store/actions/generic-actions'
import { updateSmsTemplateDetails } from '@store/actions/cms-actions'
import { BaseModalProps } from '@components/modals/types'
import { Form } from '@hyper/forms/form'
import { useAppData } from '@components/hooks/use-app-data'
import { CustomReactSelectOption, makeDefaultSelectOption } from '@components/custom-react-select'
import { FormSelect } from '@hyper/forms/form-select'
import { createSelectOption, extractSelectOptionsValues } from '@helpers/utils'
import { FormCheckbox } from '@hyper/forms/form-checkbox'

export const SmsTemplateGroupOptions = [createSelectOption('Recepcja', 'reception')]

interface Props extends BaseModalProps {
  smsTemplate?: SmsTemplate
}

export interface FormInputs {
  name: string
  key: string
  content_pl: string
  content_de: string
  content_en: string
  is_active: boolean
  group: CustomReactSelectOption | null
}

export const NotificationTemplatesSmsModal: React.FC<Props> = ({ toggleIsVisible, smsTemplate }) => {
  const methods = useForm<FormInputs>({
    defaultValues: {
      name: smsTemplate?.name ?? '',
      key: smsTemplate?.key ?? '',
      content_pl: smsTemplate?.content_pl ?? '',
      content_de: smsTemplate?.content_de ?? '',
      content_en: smsTemplate?.content_en ?? '',
      is_active: smsTemplate?.is_active ?? false,
      group: makeDefaultSelectOption(SmsTemplateGroupOptions, smsTemplate?.group),
    },
  })

  const { addSuccessMessage } = useNotificationHook()
  const appData = useAppData()
  const dispatch = useAppDispatch()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    let response: SmsTemplate
    const date = extractSelectOptionsValues(payload)

    if (smsTemplate) {
      response = await commonObjectPut<SmsTemplate>(smsTemplate.urls.details, date)
    } else {
      response = await commonObjectPost<SmsTemplate>(appData.urls.notifications.sms_templates, date)
    }

    dispatch(updateSmsTemplateDetails(response))
    addSuccessMessage('Sukces', `Powiadomienie zostało ${smsTemplate ? 'zmienione' : 'dodane'}!`)

    toggleIsVisible()
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>
        {smsTemplate ? `Edytuj szablon ${smsTemplate.name}` : 'Dodaj szablon SMS'}
      </ModalHeader>
      <ModalBody className="pb-0">
        <Row>
          <FormInput label="Nazwa" name="name" />
          <FormInput label="Klucz" name="key" />
          <FormInput label="Treść (Polski)" name="content_pl" type="textarea" />
          <FormInput label="Treść (Angielski)" name="content_en" type="textarea" />
          <FormInput label="Treść (Niemiecki)" name="content_de" type="textarea" />
          <FormSelect
            label="Grupa"
            options={SmsTemplateGroupOptions}
            name="group"
            selectProps={{ isClearable: true }}
          />
          <FormCheckbox id="active" name="is_active" label="Powiadomienie aktywne" className="mx-2 mb-3 mt-2" />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>

        <SaveButton className="btn btn-green" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
