import * as React from 'react'
import { GenericVoucher } from '@modules/generic-vouchers/models'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { ButtonWithIcon } from '@components/button-with-icon'
import { UserPermission } from '@models/dashboard'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { useUpdateGenericVoucherMutation } from '@api/generic-vouchers'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useNotificationHook } from '@hyper/use-notification-hook'

interface Props {
  genericVoucher: GenericVoucher
}

export const GenericVoucherIssueVoucherButton: React.FC<Props> = ({ genericVoucher }) => {
  const promocodeAssigns = genericVoucher.promocode_assigns.at(-1)
  const user = useAuthenticatedUser()
  const { addSuccessNotification } = useNotificationHook()

  const [updateGenericVoucher, { isLoading }] = useUpdateGenericVoucherMutation()

  const { action: handleAccept } = useApiRequest(async () => {
    await updateGenericVoucher({ url: genericVoucher.urls.issue_promocodes, method: 'POST' }).unwrap()
    addSuccessNotification('Voucher został wydany')
  })

  return promocodeAssigns?.issue_datetime ? (
    toDefaultDateFormat(promocodeAssigns.issue_datetime)
  ) : (
    <CommonObjectConfirmAction
      title="Czy na pewno chcesz wydać voucher?"
      message={
        <>
          Uwaga!
          <br />
          Wydanie vouchera jest nieodwracalne.
        </>
      }
      handleAccept={user.hasPerm(UserPermission.GenericVoucherBocianCanIssuePromocodes) ? handleAccept : undefined}
      isLoading={isLoading}
    >
      <ButtonWithIcon
        icon="uil-check"
        color="green"
        text="Wydaj"
        disabled={!user.hasPerm(UserPermission.GenericVoucherBocianCanIssuePromocodes)}
      />
    </CommonObjectConfirmAction>
  )
}
