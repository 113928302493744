import * as React from 'react'

interface Props {
  progress: number
  className?: string
  size?: string
}

export const Circle = ({ progress, className, size = '30px' }: Props): JSX.Element => {
  const strokeDashoffset = 600 - (600 * progress) / 100
  return (
    <div className={className} style={{ width: size, height: size }}>
      <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
        <circle cx="100" cy="100" r="93" fill="transparent" stroke="#97979733" strokeLinecap="round" strokeWidth="14" />
        <circle
          cx="100"
          cy="100"
          r="93"
          fill="transparent"
          stroke="#b99153"
          strokeLinecap="round"
          strokeWidth="14"
          strokeDasharray="600"
          strokeDashoffset={strokeDashoffset}
          transform="rotate(-90 100 100)"
        />
      </svg>
    </div>
  )
}
