import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  children?: React.ReactNode
  onChange: () => void
}

export const ReservationDataItem = ({ children, onChange }: Props): JSX.Element => (
  <>
    {children || <strong>brak</strong>}
    <IconWithText icon="uil-pen" text="ustaw" wrapperClassNames="text-secondary font-11 ml-1" onClick={onChange} />
  </>
)
