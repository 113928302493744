import * as React from 'react'
import { SubscriptionPaymentMoveHistory } from '@modules/subscription/models'
import Table from '@components/table/table'
import { formatPrice } from '@helpers/utils'

interface Props {
  index: number
  history: SubscriptionPaymentMoveHistory
}

export const SubscriptionDetailsPaymentsTransferRow: React.FC<Props> = ({ index, history }) => (
  <Table.Row>
    <Table.Cell>{index}.</Table.Cell>
    <Table.Cell>{history.message}</Table.Cell>
    <Table.Cell>{history.old_reservation}</Table.Cell>
    <Table.Cell>{history.new_reservation}</Table.Cell>
    <Table.Cell>{formatPrice(history.params.amount)}</Table.Cell>
    <Table.Cell>{history.params.source}</Table.Cell>
  </Table.Row>
)
