import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { ReceptionBookingCheckInStepImprovementsGroup } from '@modules/reception/checkin/step-improvements/reception-booking-check-in-step-improvements-group'
import { ReceptionBookingCheckInImprovementsVip } from '@modules/reception/checkin/step-improvements/vip/reception-booking-check-in-improvements-vip'
import { BookingImprovementsAdded } from '@modules/reception/checkin/step-improvements/booking-improvements-added'
import { BookingImprovementsAvailable } from '@modules/reception/checkin/step-improvements/booking-improvements-available'
import { BookingImprovementCode, ImprovementAvailability } from '@models/booking'
import { getImprovementsAvailability } from '@api/reception'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { ReceptionBookingHpfTicketImprovement } from '@modules/reception/checkin/step-improvements/tickets/hpf/reception-booking-hpf-ticket-improvement'
import { useApiRequest } from '@components/hooks/use-api-request'

interface Props {
  booking: ReceptionBookingDetails
  disabledImprovements?: BookingImprovementCode[]
  children?: (improvementAvailability: ImprovementAvailability[]) => React.ReactNode
}

export const ReceptionBookingCheckInStepImprovementsList: React.FC<Props> = ({
  booking,
  disabledImprovements,
  children,
}) => {
  const [improvementsAvailability, setImprovementsAvailability] = React.useState<ImprovementAvailability[]>([])

  const { action: fetchImprovementsAvailability } = useApiRequest(async () => {
    setImprovementsAvailability(await getImprovementsAvailability(booking.urls.improvement_availability))
  })

  React.useEffect(() => {
    fetchImprovementsAvailability()
  }, [])

  return (
    <LoaderPlaceholder content={improvementsAvailability.length}>
      <h4 className="text-dark text-strong">Ulepszenia:</h4>
      {children?.(improvementsAvailability)}
      <ReceptionBookingHpfTicketImprovement
        improvementsAvailability={improvementsAvailability}
        isReadOnly={!!disabledImprovements?.includes('hpf_ticket')}
      />
      <ReceptionBookingCheckInImprovementsVip
        booking={booking}
        improvementsAvailability={improvementsAvailability}
        refetchAvailability={fetchImprovementsAvailability}
      />
      <ReceptionBookingCheckInStepImprovementsGroup title="Aktywne ulepszenia:">
        <BookingImprovementsAdded booking={booking} />
      </ReceptionBookingCheckInStepImprovementsGroup>
      <BookingImprovementsAvailable booking={booking} improvementsAvailability={improvementsAvailability} />
    </LoaderPlaceholder>
  )
}
