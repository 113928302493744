import * as React from 'react'
import { formatDate, toDefaultDateTimeFormat } from '@helpers/date-helper'
import { FeedingWorker } from '@modules/feeding/models'
import { formatPriceShort } from '@helpers/utils'
import { FeedingWorkerFiltersParams } from '@modules/feeding/workers/index'
import { FeedingWorkersExportButtons } from '@modules/feeding/workers/export-buttons'
import { resortsMapSelector } from '@store/selectors/dashboard'
import Table, { TableHeaderValue } from '@components/table/table'
import { FeedingWorkerTableFooter } from '@modules/feeding/workers/table-footer'
import { useAppSelector } from '@store/index'

interface FeedingWorkerTableProps {
  isLoading: boolean
  workers: FeedingWorker[]
  setFilters: (filters: FeedingWorkerFiltersParams) => void
  filters: FeedingWorkerFiltersParams
}

const headerValues: TableHeaderValue<FeedingWorker>[] = [
  {
    sortField: 'name',
    title: 'Imię i nazwisko',
  },
  {
    sortField: 'product',
    title: 'Posiłek',
  },
  {
    sortField: 'resort',
    title: 'Ośrodek',
  },
  {
    sortField: 'date_from',
    title: 'Data',
  },
  {
    sortField: 'price_brutto',
    title: 'Cena brutto',
  },
  {
    sortField: 'payment',
    title: 'Metoda płatności',
  },
  {
    sortField: 'created_at',
    title: 'Data utworzenia',
  },
]

export const FeedingWorkerTable: React.FC<FeedingWorkerTableProps> = ({ setFilters, workers, filters, isLoading }) => {
  const resortsMap = useAppSelector(resortsMapSelector)

  const getResort = React.useCallback(
    (worker: FeedingWorker) => worker.resorts.map(x => resortsMap[x]?.name || '').join(', '),
    [resortsMap],
  )

  return (
    <>
      <Table
        className="table-hover w-100"
        headerValues={headerValues}
        isLoading={isLoading}
        filters={filters}
        setFilters={setFilters}
      >
        {workers.map(worker => (
          <Table.Row key={worker.id}>
            <Table.Cell>{worker.name}</Table.Cell>
            <Table.Cell>{worker.product}</Table.Cell>
            <Table.Cell>{getResort(worker)}</Table.Cell>
            <Table.Cell>
              {formatDate(worker.date_from, 'dd.MM')}-{formatDate(worker.date_to, 'dd.MM.yyyy')}
            </Table.Cell>
            <Table.Cell>{formatPriceShort(worker.price_brutto)}</Table.Cell>
            <Table.Cell>{worker.payment_display}</Table.Cell>
            <Table.Cell>{toDefaultDateTimeFormat(worker.created_at)}</Table.Cell>
          </Table.Row>
        ))}
        {workers.length && <FeedingWorkerTableFooter workers={workers} />}
      </Table>
      <FeedingWorkersExportButtons filters={filters} />
    </>
  )
}
