import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@store/index'
import {
  createPaymentDayPointReport,
  getPaymentAppData,
  getPaymentDayReportDetails,
  getPaymentDayReports,
  getPaymentRegisters,
  updatePaymentDayPointReport,
  updatePaymentDayUserReport,
} from '@store/actions/cash-actions'
import {
  PaymentAppData,
  PaymentDayReport,
  PaymentDayReportDetails,
  PaymentRegister,
  PaymentRegisterBalance,
  PaymentRegisterUser,
} from '@models/payments'

interface CashState {
  balance: PaymentRegisterBalance
  appData: PaymentAppData
  paymentRegisters: EntityState<PaymentRegister>
  paymentRegisterUsers: EntityState<PaymentRegisterUser>
  paymentDayReports: EntityState<PaymentDayReport>
  paymentDayReportDetails: PaymentDayReportDetails | undefined
}

const paymentRegistersAdapter = createEntityAdapter<PaymentRegister>()
const paymentRegisterUsersAdapter = createEntityAdapter<PaymentRegisterUser>()
const paymentDayReportsAdapter = createEntityAdapter<PaymentDayReport>()

const initialState: CashState = {
  balance: {
    after: '0',
    before: '0',
    total_payin: '0',
    total_payout: '0',
  },
  appData: {
    payment_balance_types: [],
    payment_register_points: [],
    payment_cash_register_workers: [],
    status: 'unknown',
  },
  paymentRegisters: paymentRegistersAdapter.getInitialState(),
  paymentRegisterUsers: paymentRegisterUsersAdapter.getInitialState(),
  paymentDayReports: paymentDayReportsAdapter.getInitialState(),
  paymentDayReportDetails: undefined,
}

export const cashSlice = createSlice({
  name: 'cash',
  initialState,
  reducers: {
    updatePaymentDayReport(state, action: PayloadAction<PaymentDayReportDetails>) {
      paymentDayReportsAdapter.upsertOne(state.paymentDayReports, action.payload)
    },
    updatePaymentDayReportDetails(state, action: PayloadAction<PaymentDayReportDetails>) {
      if (state.paymentDayReportDetails?.id === action.payload.id) {
        state.paymentDayReportDetails = action.payload
      }
    },
    addPaymentRegister(state, action: PayloadAction<PaymentRegister>) {
      paymentRegistersAdapter.upsertOne(state.paymentRegisters, action.payload)
    },
    setPaymentAppData(state, action: PayloadAction<PaymentAppData>) {
      state.appData = action.payload
    },
    clearPaymentRegisters(state) {
      paymentRegistersAdapter.removeAll(state.paymentRegisters)
    },
  },
  extraReducers: builder => {
    builder.addCase(getPaymentRegisters.fulfilled, (state, action) => {
      const { results, aggregation } = action.payload

      state.balance = {
        after: aggregation.balance_after,
        before: aggregation.balance_before,
        total_payin: aggregation.total_payin,
        total_payout: aggregation.total_payout,
      }

      paymentRegisterUsersAdapter.setAll(state.paymentRegisterUsers, aggregation.users)
      paymentRegistersAdapter.setAll(state.paymentRegisters, results)
    })
    builder.addCase(getPaymentDayReports.fulfilled, (state, action) => {
      paymentDayReportsAdapter.setAll(state.paymentDayReports, action.payload)
    })
    builder.addCase(getPaymentDayReportDetails.pending, state => {
      state.paymentDayReportDetails = undefined
    })
    builder.addCase(getPaymentDayReportDetails.fulfilled, (state, action) => {
      state.paymentDayReportDetails = action.payload
      paymentDayReportsAdapter.upsertOne(state.paymentDayReports, action.payload)
    })
    builder.addCase(updatePaymentDayPointReport.fulfilled, (state, action) => {
      state.paymentDayReportDetails = action.payload
      paymentDayReportsAdapter.upsertOne(state.paymentDayReports, action.payload)
    })
    builder.addCase(createPaymentDayPointReport.fulfilled, (state, action) => {
      state.paymentDayReportDetails = action.payload
      paymentDayReportsAdapter.upsertOne(state.paymentDayReports, action.payload)
    })
    builder.addCase(updatePaymentDayUserReport.fulfilled, (state, action) => {
      state.paymentDayReportDetails = action.payload
      paymentDayReportsAdapter.upsertOne(state.paymentDayReports, action.payload)
    })
    builder.addCase(getPaymentAppData.fulfilled, (state, action) => {
      state.appData = { ...action.payload, status: 'ready' }
    })
  },
})

export const { selectAll: paymentRegistersSelector } = paymentRegistersAdapter.getSelectors(
  (state: RootState) => state.cashState.paymentRegisters,
)
export const { selectAll: paymentRegisterUsersSelector } = paymentRegisterUsersAdapter.getSelectors(
  (state: RootState) => state.cashState.paymentRegisterUsers,
)
export const { selectAll: paymentDayReportsSelector } = paymentDayReportsAdapter.getSelectors(
  (state: RootState) => state.cashState.paymentDayReports,
)

export const paymentDayReportDetailsSelector = (state: RootState) => state.cashState.paymentDayReportDetails

export const {
  setPaymentAppData,
  addPaymentRegister,
  updatePaymentDayReport,
  updatePaymentDayReportDetails,
  clearPaymentRegisters,
} = cashSlice.actions

export default cashSlice.reducer
