import * as React from 'react'
import { PurchaseBandPaymentContent } from '@modules/shop/pos/purchase-payments/band/purchase-band-payment-content'
import { BandDetails } from '@models/band'
import { CartSelection } from '@modules/reception/cart/payment-box/reception-booking-cart-payment-box'
import { ReceptionBookingDetailsCart } from '@models/reception'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectPost } from '@store/actions/generic-actions'
import { BaseCartPaymentResponse } from '@modules/reception/cart/models'
import { ReceptionBookingCartPaymentBaseActions } from '@modules/reception/cart/payment-box/payment-options-content/reception-booking-cart-payment-base-actions'

interface Props {
  onDecline: () => void
  url: string
  cartSelection: CartSelection
  onCartUpdate: (cart: ReceptionBookingDetailsCart) => void
}

export const ReceptionBookingCartPaymentBand = ({
  onCartUpdate,
  cartSelection,
  url,
  onDecline,
}: Props): JSX.Element => {
  const [bandDetails, setBandDetails] = React.useState<BandDetails | undefined>(undefined)

  const { action: handlePay, isLoading } = useApiRequest(async () => {
    const response = await commonObjectPost<BaseCartPaymentResponse>(url, cartSelection)
    onCartUpdate(response.cart_details)
    onDecline()
  })

  return (
    <>
      <PurchaseBandPaymentContent onBandDetailsChange={setBandDetails} bandDetails={bandDetails} />
      <div className="px-3">
        <ReceptionBookingCartPaymentBaseActions
          confirmDisabled={!bandDetails}
          onDecline={onDecline}
          isSaving={isLoading}
          onConfirm={handlePay}
        />
      </div>
    </>
  )
}
