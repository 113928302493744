import * as React from 'react'
import { Button, Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useForm } from 'react-hook-form'
import { ReceptionBookingDetails } from '@models/reception'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApiRequest, useFormRequest } from '@components/hooks/use-api-request'
import { BaseModalProps } from '@components/modals/types'
import { updateReceptionBookingDetails } from '@store/actions/reception-actions'
import { commonObjectGet, commonObjectPut } from '@store/actions/generic-actions'
import { useAppDispatch } from '@store/index'
import { useModal } from '@components/modals/use-modal'
import { ClientSelect } from '@components/client-select'
import { ClientDetails, ClientUser } from '@models/clients'
import { ContentLoader } from '@components/content-loader'
import { AlertDanger } from '@components/alerts'
import classnames from 'classnames'
import { useAppData } from '@components/hooks/use-app-data'
import { Form } from '@hyper/forms/form'

interface Props extends BaseModalProps {
  booking: ReceptionBookingDetails
  onSuccess?: () => void
}

export const ReservationClientChangeDialog: React.FC<Props> = ({ toggleIsVisible, booking, onSuccess }) => {
  const [client, setClient] = React.useState<ClientUser | undefined>()

  const handleClientChange = (client: ClientUser) => {
    methods.setValue('client', client?.id)
    setClient(client)
  }

  const [handleToggleClientDialog] = useModal('ClientDialog', { setClient: handleClientChange })

  const { urls } = useAppData()

  const methods = useForm()
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading, action: onSubmit } = useFormRequest(async () => {
    dispatch(
      updateReceptionBookingDetails(
        await commonObjectPut<ReceptionBookingDetails>(booking.urls.reservation_booking_details, {
          client: client?.id,
        }),
      ),
    )

    onSuccess?.()

    addSuccessMessage()
    toggleIsVisible()
  }, methods.setError)

  const { action: fetchClientDetails, isLoading: isFetchingClientDetails } = useApiRequest(async () => {
    setClient(await commonObjectGet<ClientDetails>(`${urls.clients.clients}${booking.client_id}/`))
  })

  React.useEffect(() => {
    fetchClientDetails()
  }, [])

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Edycja rezerwacji #{booking.reservation_number}</ModalHeader>
      <ModalBody>
        <ContentLoader isLoading={isFetchingClientDetails}>
          <Row>
            {!booking.subscription_allow_change_client_data && (
              <Col>
                <AlertDanger
                  className="p-1 col-12 align-items-center"
                  iconClassName="font-13 lh-initial"
                  wrapperClassName="lh-initial mx-2"
                >
                  <p className="font-12 mb-0 lh-initial">Opcja zmiany danych nie została wykupiona.</p>
                </AlertDanger>
              </Col>
            )}

            <ClientSelect
              colSize={12}
              setClient={handleClientChange}
              client={client}
              handleToggleClientDialog={handleToggleClientDialog}
              formGroupClassName={classnames({ 'not-clickable': !booking.subscription_allow_change_client_data })}
              prefetchedClientDetails={true}
              error={methods.formState.errors.client}
            />
          </Row>
        </ContentLoader>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton isSaving={isLoading} className="btn btn-green" disabled={!client} />
      </ModalFooter>
    </Form>
  )
}
