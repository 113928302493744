import * as React from 'react'
import { PaymentRegistersFiltersParams } from '@modules/payment-registers/list/filters'
import { useAppDispatch } from '@store/index'
import { useApiRequest } from '@components/hooks/use-api-request'
import { getPaymentRegisters } from '@store/actions/cash-actions'
import { PaymentRegisterCashboxDayTransactionsTable } from '@modules/payment-registers/cashbox-day-details/transactions/table'
import { PaymentRegisterCashboxDayTransactionsTableFilters } from '@modules/payment-registers/cashbox-day-details/transactions/table/filters'
import { PaymentDayReportDetails } from '@models/payments'
import { clearPaymentRegisters } from '@store/slices/cash-slice'
import { createSelectOption } from '@helpers/utils'

interface Props {
  resortId: number
  paymentDayReportDetails: PaymentDayReportDetails
}

export const PaymentRegisterCashboxDayTransactions = ({ paymentDayReportDetails, resortId }: Props): JSX.Element => {
  const defaultFilters: PaymentRegistersFiltersParams = {
    ordering: '-created',
    page: 1,
    destination_resort: createSelectOption(resortId, resortId),
    source_resort: createSelectOption(resortId, resortId),
    source: null,
    user: null,
    page_size: 10,
    search: '',
    created_after: undefined,
    created_before: undefined,
    type: null,
    kind: null,
  }
  const [filters, setFilters] = React.useState<PaymentRegistersFiltersParams>(defaultFilters)

  const dispatch = useAppDispatch()

  const { isLoading, action: fetchPaymentRegisters } = useApiRequest(
    async () =>
      await dispatch(
        getPaymentRegisters({ ...filters, payment_day_report: paymentDayReportDetails.id, view: 'manager-view' }),
      ),
  )

  React.useEffect(() => {
    fetchPaymentRegisters()
  }, [filters])

  React.useEffect(
    () => () => {
      dispatch(clearPaymentRegisters())
    },
    [],
  )

  return (
    <>
      <PaymentRegisterCashboxDayTransactionsTableFilters
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
      />
      <PaymentRegisterCashboxDayTransactionsTable filters={filters} isLoading={isLoading} setFilters={setFilters} />
    </>
  )
}
