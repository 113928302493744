import * as React from 'react'
import { SubscriptionProduct } from '@modules/subscription/models'
import { SubscriptionDetailsInformationRow } from '@modules/subscription/details/information/row'

interface Props {
  subscriptionProduct: SubscriptionProduct
}

export const SubscriptionDetailsInformationBaseInPrice: React.FC<Props> = ({ subscriptionProduct }) => (
  <SubscriptionDetailsInformationRow name="Dodatki">
    {subscriptionProduct.with_cleaning_option && <span className="badge badge-success-lighten mr-1">sprzątanie</span>}
    {subscriptionProduct.with_parking && <span className="badge badge-success-lighten mr-1">parking</span>}
    {subscriptionProduct.with_towel && <span className="badge badge-success-lighten mr-1">ręczniki</span>}
    {subscriptionProduct.with_grill && <span className="badge badge-success-lighten mr-1">grill</span>}
    {subscriptionProduct.with_client_change_data_option && (
      <span className="badge badge-success-lighten">zmiana danych</span>
    )}
  </SubscriptionDetailsInformationRow>
)
