import * as React from 'react'
import { FormSelect } from '@hyper/forms/form-select'
import { ButtonWithIcon } from '@components/button-with-icon'
import classNames from 'classnames'
import { SpinInput } from '@components/spin-input'
import { FormRadio, FormRadioGroup } from '@hyper/forms/form-radio'
import { createSelectOption, formatPriceShort } from '@helpers/utils'
import { ProductPackageSetProduct } from '@modules/endless-holiday/models'
import { useFormContext, useWatch } from 'react-hook-form'
import { EndlessHolidayCreateFormInputs } from '@modules/endless-holiday/transactions/create/modal'
import { FieldPath } from 'react-hook-form/dist/types/path'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { EndlessHolidayFormProductDataPrice } from '@modules/endless-holiday/transactions/create/steps/products/product-price'

type FormDynamicPath = FieldPath<EndlessHolidayCreateFormInputs>

interface Props {
  onRemove: () => void
  className?: string
  index: number
  getYearOptions: (accommodationType: number) => CustomReactSelectOption[]
  gastroProducts: ProductPackageSetProduct[]
  getSubscriptionProducts: (year: number, accommodationType: string) => ProductPackageSetProduct[]
}

export const EndlessHolidayFormProductDataRow = ({
  onRemove,
  className,
  index,
  getYearOptions,
  gastroProducts,
  getSubscriptionProducts,
}: Props) => {
  const createFormKey = (key: string) => `products.${index}.${key}`

  const { control, setValue } = useFormContext<EndlessHolidayCreateFormInputs>()
  const [selectedYear, selectedAccommodationType] = useWatch({
    control,
    name: [createFormKey('year') as FormDynamicPath, createFormKey('accommodation_type') as FormDynamicPath],
  })

  const getSubscriptionOptions = () => {
    const subscriptionProducts = getSubscriptionProducts(selectedYear?.value, selectedAccommodationType?.value) || []

    return subscriptionProducts.map(product => ({
      value: product.id,
      name: { gold: 'Złoty', silver: 'Srebrny', brown: 'Brązowy' }[product.subscription_kind] || product.name,
    }))
  }

  const availableSubscriptions = React.useMemo(() => {
    if (!selectedYear) return []
    return getSubscriptionOptions()
  }, [selectedYear?.value, selectedAccommodationType?.value])

  const availableGastroCardOptions =
    selectedYear?.value === 2025 ? gastroProducts.filter(product => product.price_brutto !== '1000.00') : gastroProducts

  const yearOptions = React.useMemo(
    () => getYearOptions(selectedAccommodationType?.value),
    [selectedAccommodationType?.value],
  )

  useDidUpdateEffect(() => {
    setValue(createFormKey('gastro_card') as FormDynamicPath, '')

    const subscriptionOptions = getSubscriptionOptions()
    if (subscriptionOptions.length) {
      setValue(createFormKey('subscription') as FormDynamicPath, getSubscriptionOptions()[0].value.toString())
    }
  }, [selectedYear?.value, selectedAccommodationType?.value])

  useDidUpdateEffect(() => {
    setValue(createFormKey('year') as FormDynamicPath, getYearOptions(selectedAccommodationType?.value)[0])
  }, [selectedAccommodationType?.value])

  return (
    <div className={classNames('bg-grey p-2', className)}>
      <Row title="Zakwaterowanie:">
        <div className="col-5">
          <FormSelect
            selectProps={{ isSearchable: false }}
            options={EndlessHolidayAvailableAccommodationTypeOptions}
            name={createFormKey('accommodation_type')}
            formPlainProps={{ formGroupClassName: 'mb-0' }}
          />
        </div>
        <div className="col-3">
          <ButtonWithIcon
            icon="uil-trash-alt"
            text="Usuń z listy"
            color="outline-danger"
            btnClass="btn btn-outline-danger"
            handleClick={onRemove}
          />
        </div>
      </Row>
      <Row title="Rok:" className="mt-2">
        <div className="col-5">
          <FormSelect
            selectProps={{ isSearchable: false }}
            options={yearOptions}
            name={createFormKey('year')}
            formPlainProps={{ formGroupClassName: 'mb-0' }}
          />
        </div>
      </Row>
      <Row title="Rodzaj pakietu:" className="mt-2">
        {!availableSubscriptions.length && <span className="col-5 mx-2 py-1">Brak dostępnych pakietów.</span>}
        <FormRadioGroup
          className="mb-0"
          name={createFormKey('subscription')}
          formPlainProps={{ colSize: 5, colClassName: 'mx-2', formGroupClassName: 'mb-0' }}
        >
          {availableSubscriptions.map(product => (
            <FormRadio
              disabled={!selectedAccommodationType || !selectedYear}
              className="mr-4"
              key={product.value}
              name={createFormKey('subscription')}
              value={product.value}
              label={product.name}
            />
          ))}
        </FormRadioGroup>
      </Row>
      <Row title="Wariant vouchera:" className="mt-2">
        <FormRadioGroup
          className="mb-0"
          name={createFormKey('gastro_card')}
          formPlainProps={{ colSize: 7, colClassName: 'mx-2', formGroupClassName: 'mb-0' }}
        >
          <FormRadio name={createFormKey('gastro_card')} value="" label="Bez vouchera" />
          {availableGastroCardOptions.map(product => (
            <FormRadio
              className="ml-4"
              key={product.id}
              name={createFormKey('gastro_card')}
              value={product.id}
              label={formatPriceShort(product.price_brutto)}
            />
          ))}
        </FormRadioGroup>
      </Row>
      <Row title="Opcja zmiany daty:" className="mt-2">
        <div className="col-5">
          <FormSelect
            selectProps={{ isSearchable: false }}
            options={EndlessHolidayOptions}
            name={`${createFormKey('options')}.0`}
            formPlainProps={{ formGroupClassName: 'mb-0' }}
          />
        </div>
      </Row>
      <Row title="Ilość pakietów:" className="mt-2">
        <div className="col-5 mx-2">
          <SpinInput inputName={createFormKey('amount')} inputProps={{ min: 1, max: 100 }} />
        </div>
      </Row>
      <EndlessHolidayFormProductDataPrice index={index} />
    </div>
  )
}

const Row = ({ title, children, className }: { title: string; className?: string; children: React.ReactNode }) => (
  <div className={classNames('row align-items-center', className)}>
    <div className="col-4 fw-semi-bold">{title}</div>
    {children}
  </div>
)

export const EndlessHolidayAvailableAccommodationTypeOptions = [
  createSelectOption('Domek', 1),
  createSelectOption('Apartament', 2),
]

export const EndlessHolidayOptions = [
  createSelectOption('Tak', 'yes', false, { code: 'single_client_date' }),
  createSelectOption('Nie', 'no', false, { code: 'single_client_date' }),
]
