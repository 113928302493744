import * as React from 'react'
import { ContentLoader } from '@components/content-loader'
import { UpSellOutdatedBooking } from '@models/upselling'
import { UpsellBookingsOutdatedTableRow } from '@modules/upselling/upsell-booking-outdated/table/upsell-bookings-outdated-table-row'

interface Props {
  isLoading: boolean
  bookings: UpSellOutdatedBooking[]
  type: string
}

export const UpsellBookingsOutdatedTable = ({ bookings, isLoading, type }: Props): JSX.Element => (
  <div>
    <h4 className="text-secondary my-3">Zaległe kontakty</h4>
    <ContentLoader isLoading={isLoading}>
      <table className="table w-100">
        {bookings.map(booking => (
          <UpsellBookingsOutdatedTableRow key={booking.contact_required_date} booking={booking} type={type} />
        ))}
      </table>
      {!bookings.length && <div className="text-center fw-semi-bold">Brak danych</div>}
    </ContentLoader>
  </div>
)
