import * as React from 'react'
import { asDecimal, formatPriceShort } from '@helpers/utils'
import { IconWithText } from '@components/icon-with-text'
import classnames from 'classnames'
import { PackageSummarySectionDataRow } from '@modules/package/create/package-summary-section'
import { useFormContext } from 'react-hook-form'
import { SubscriptionCreateFormInputs } from '@modules/subscription/create/modal'
import { SubscriptionProduct } from '@modules/subscription/models'

interface Props {
  selectedProduct: SubscriptionProduct
}

export const SubscriptionCreateFormSummaryAdditionalOption = ({ selectedProduct }: Props): JSX.Element => {
  const methods = useFormContext<SubscriptionCreateFormInputs>()

  const data = methods.getValues()

  const totalZooTicketPrice = asDecimal(selectedProduct.zoo_borysew_tickets_single_price_brutto)
    .mul(data?.zoo_borysew_tickets_packs_amount ?? 1)
    .toString()

  const totalSuntagoTicketPrice = asDecimal(selectedProduct.suntago_tickets_single_price_brutto)
    .mul(data?.suntago_tickets_packs_amount ?? 1)
    .toString()

  const totalPrice = asDecimal(selectedProduct.price_brutto)
    .plus(data.allow_change_client_data ? selectedProduct.change_client_data_price_brutto : 0)
    .plus(
      data.allow_subscription_code_localization_convert ? selectedProduct.code_localization_convert_price_brutto : 0,
    )
    .plus(data.with_zoo_borysew_tickets ? totalZooTicketPrice : 0)
    .plus(data.with_suntago_tickets ? totalSuntagoTicketPrice : 0)
    .toString()

  return (
    <>
      <PackageSummarySectionDataRow
        title="Termin płatności kaucji:"
        content={selectedProduct.deposit_payment_deadline_display}
        titleFlex="col-3"
        contentFlex="col-9"
      />
      <PackageSummarySectionDataRow
        title="Dodatkowe opcje:"
        content={
          <ul className="list-unstyled mb-0">
            {data.allow_change_client_data && (
              <li>
                <span>{formatPriceShort(selectedProduct.change_client_data_price_brutto)}</span>
                <IconWithText
                  wrapperClassNames="ml-2"
                  text="możliwość zmiany danych"
                  textClass="font-weight-normal"
                  icon={classnames(
                    'font-16 lh-0',
                    data.allow_change_client_data ? 'uil-check text-secondary' : 'uil-times text-muted',
                  )}
                />
              </li>
            )}

            {data.allow_subscription_code_localization_convert && (
              <li>
                <span>{formatPriceShort(selectedProduct.code_localization_convert_price_brutto)}</span>
                <IconWithText
                  wrapperClassNames="ml-2"
                  text="możliwość zmiany lokalizacji"
                  textClass="font-weight-normal"
                  icon={classnames(
                    'font-16 lh-0',
                    data.allow_change_client_data ? 'uil-check text-secondary' : 'uil-times text-muted',
                  )}
                />
              </li>
            )}

            {data.with_zoo_borysew_tickets && (
              <li>
                <span>{formatPriceShort(totalZooTicketPrice)}</span>
                <IconWithText
                  wrapperClassNames="ml-2"
                  text="bilety wstępu do ZOO Borysew"
                  textClass="font-weight-normal"
                  icon={classnames(
                    'font-16 lh-0',
                    data.allow_change_client_data ? 'uil-check text-secondary' : 'uil-times text-muted',
                  )}
                />
              </li>
            )}

            {data.with_suntago_tickets && (
              <li>
                <span>{formatPriceShort(totalSuntagoTicketPrice)}</span>
                <IconWithText
                  wrapperClassNames="ml-2"
                  text="bilety wstępu do Suntago"
                  textClass="font-weight-normal"
                  icon={classnames(
                    'font-16 lh-0',
                    data.allow_change_client_data ? 'uil-check text-secondary' : 'uil-times text-muted',
                  )}
                />
              </li>
            )}
          </ul>
        }
        titleFlex="col-3"
        contentFlex="col-9"
      />
      <PackageSummarySectionDataRow
        title="Razem (produkt + opcje):"
        content={formatPriceShort(totalPrice)}
        titleFlex="col-3"
        contentFlex="col-9"
      />
    </>
  )
}
