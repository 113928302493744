import * as React from 'react'
import { PaymentRegister } from '@models/payments'
import Table from '@components/table/table'
import { toDefaultDateTimeFormatWithBrake } from '@helpers/date-helper'
import { PaymentRegisterTableRowUser } from '@modules/payment-registers/common/payment_register_table_row_user'
import { PaymentCashBoxAmount } from '@modules/payment-registers/user-cashbox/payment-cashbox-amount'
import { formatPrice } from '@helpers/utils'

interface Props {
  paymentRegister: PaymentRegister
}

export const TransactionListModalTableRow = ({ paymentRegister }: Props): JSX.Element => (
  <Table.Row role="details-row">
    <Table.Cell>{toDefaultDateTimeFormatWithBrake(paymentRegister.created, 'd-block font-11')}</Table.Cell>
    <Table.Cell>{paymentRegister.description || paymentRegister.kind_display}</Table.Cell>
    <Table.Cell>
      {paymentRegister.booking_reservation_number && (
        <a href={`/admin/dashboard/quick-search/?query=${paymentRegister.booking_reservation_number}`}>
          {paymentRegister.booking_reservation_number}
        </a>
      )}
    </Table.Cell>
    <PaymentRegisterTableRowUser paymentRegister={paymentRegister} />
    <Table.Cell>
      <PaymentCashBoxAmount
        paymentRegister={paymentRegister}
        inTicketsAmountTooltipContent={
          <span className="text-semi-strong">{formatPrice(paymentRegister.amount_in_tickets)}</span>
        }
      />
    </Table.Cell>
    <Table.Cell />
  </Table.Row>
)
