import { v4 as uuidv4 } from 'uuid'
import { ProductPos } from '@modules/reception/pos/models'
import { commonObjectGet, commonObjectPost } from '@store/actions/generic-actions'

export const getAccount = async (resort: string, apartment: string, source: string, cancelToken): Promise<any> =>
  await commonObjectGet<any>(
    '/api/wallet/account/',
    {
      resort_id: resort,
      room_number: apartment,
      source,
    },
    cancelToken,
  )

export const debitAccount = async (
  resort: string,
  guest: number,
  apartment: string,
  products: ProductPos[],
  source: string,
) =>
  await commonObjectPost('/api/wallet/debit/', {
    resort_id: resort,
    guest_id: guest,
    source: source,
    room_number: apartment,
    bill_id: uuidv4(),
    items: products.map(product => ({ ...product, unit: 'szt' })),
    employee_name: 'Wirtualny Sprzedawca',
  })
