import * as React from 'react'
import { useSelector } from 'react-redux'
import { receptionBookingDetailsSelector } from '@store/selectors/reception'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useAppDispatch, useAppSelector } from '@store/index'
import { bookingWalletDetailsSelector } from '@store/slices/reservations-slice'
import { getBookingWalletDetails } from '@store/actions/reservation-actions'
import { ReceptionBookingClientWallet } from '@models/reception'

interface Response {
  wallets: ReceptionBookingClientWallet[]
}

export const useClientWallets = (): Response => {
  const walletDetails = useAppSelector(bookingWalletDetailsSelector)
  const bookingDetails = useSelector(receptionBookingDetailsSelector)

  const dispatch = useAppDispatch()

  const { action: fetchBookingWalletDetails } = useApiRequest(
    async () => await dispatch(getBookingWalletDetails(bookingDetails)),
  )

  React.useEffect(() => {
    fetchBookingWalletDetails()
  }, [])

  return { wallets: walletDetails?.client_wallets ?? [] }
}
