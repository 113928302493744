import * as React from 'react'
import { Col, Row } from 'reactstrap'
import { useBandReadersList } from '@modules/reception/common/band-reader-select/use-band-readers-list'
import { AccountUserDesktopModalDeviceEdit } from '@modules/account/user-desktop-modal/device-edit'
import { useBandReader } from '@modules/reception/common/band-reader-select/use-band-reader'
import { useToggle } from '@components/hooks/use-toggle'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { IconWithText } from '@components/icon-with-text'

export const AccountUserDesktopModalBandReader = (): JSX.Element => {
  const user = useAuthenticatedUser()
  const band_readers = useBandReadersList()
  const [editMode, toggleEditMode] = useToggle()
  const [value, setPinPad, remove] = useBandReader()

  const bandReader = React.useMemo(
    () => band_readers.find(row => row.id === (value || user.receptionWorkstation?.band_reader_id)),
    [band_readers, value, user.receptionWorkstation],
  )

  const setDevice = async (device: number) => {
    await setPinPad(device)
    toggleEditMode()
  }

  return (
    <Row className="mx-0 align-items-center py-2">
      <Col md={5}>
        <strong>Czytnik salto</strong>
      </Col>
      <Col md={6}>
        {!editMode ? (
          <IconWithText
            text={bandReader?.name || 'brak'}
            icon="uil-edit text-dark ml-1 cursor-pointer"
            onClick={toggleEditMode}
          />
        ) : (
          <AccountUserDesktopModalDeviceEdit
            defaultValue={value || user.receptionWorkstation?.band_reader_id}
            devices={band_readers}
            clearValue={remove}
            toggleEdit={toggleEditMode}
            setDevice={setDevice}
          />
        )}
      </Col>
    </Row>
  )
}
