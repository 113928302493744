import * as React from 'react'
import Table from '@components/table/table'
import { BookingOffer } from '@models/booking-offers'
import { formatPrice } from '@helpers/utils'
import { useAppSelector } from '@store/index'
import { accommodationTypesMapSelector, resortsMapSelector } from '@store/selectors/dashboard'
import { ReceptionBookingDetails } from '@models/reception'
import { useSaleBookingOfferMutation } from '@api/booking-offers'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { SaveButton } from '@hyper/button'
import { toDefaultDateFormat } from '@helpers/date-helper'

interface Props {
  bookingOffer: BookingOffer
}

export const BookingOffersTableRow: React.FC<Props> = ({ bookingOffer }) => {
  const resortsMap = useAppSelector(resortsMapSelector)
  const accommodationTypesMap = useAppSelector(accommodationTypesMapSelector)
  const navigate = useNavigate()

  const [saleBooking, { isLoading }] = useSaleBookingOfferMutation()

  const handleClick = async () => {
    const booking: ReceptionBookingDetails = await saleBooking(bookingOffer.urls.sale).unwrap()

    navigate(NavigationPath.ReservationCreateWithParams.replace(':token', booking.token))
  }

  return (
    <Table.Row>
      <Table.Cell>{resortsMap[bookingOffer.resort_id].name}</Table.Cell>
      <Table.Cell>{accommodationTypesMap[bookingOffer.accommodation_type_id].name}</Table.Cell>
      <Table.Cell>
        {toDefaultDateFormat(bookingOffer.date_from)} - {toDefaultDateFormat(bookingOffer.date_to)}
      </Table.Cell>
      <Table.Cell>{bookingOffer.days} doby</Table.Cell>
      <Table.Cell>{formatPrice(bookingOffer.price_brutto)}</Table.Cell>
      <Table.Cell className="text-right">
        <SaveButton
          onClick={handleClick}
          isSaving={isLoading}
          label="Rezerwuj"
          labelSaving="Rezerwuje"
          className="btn btn-green btn-sm"
        />
      </Table.Cell>
    </Table.Row>
  )
}
