import * as React from 'react'
import Table, { TableHeaderValue } from '@components/table/table'
import { CouponsClientsTableRow } from '@modules/coupons/list/clients/coupons-clients-table-row'
import { CouponClient } from '@modules/coupons/models'
import { BaseTableFilters } from '@components/table/use-handle-table-filters-change'

interface Props {
  couponClients: CouponClient[]
  setFilters: (filters: BaseTableFilters) => void
  filters: BaseTableFilters
  isLoading: boolean
}

const headerValues: TableHeaderValue[] = [
  { title: 'Klient' },
  { title: 'Ilość kuponów' },
  { title: 'Wartość brutto' },
  { title: 'Kwota niewykorzystana' },
  { title: '' },
]

export const CouponsClientsTable: React.FC<Props> = ({ setFilters, couponClients, filters, isLoading }) => (
  <Table
    className="table-hover w-100"
    headerValues={headerValues}
    isLoading={isLoading}
    filters={filters}
    setFilters={setFilters}
  >
    {couponClients.map((couponClient: CouponClient) => (
      <CouponsClientsTableRow key={couponClient.client_id} couponClient={couponClient} />
    ))}
  </Table>
)
