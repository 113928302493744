import * as React from 'react'
import { ReceptionBooking } from '@models/reception'
import { formatDate } from '@helpers/date-helper'
import { startOfToday } from 'date-fns'
import { useModal } from '@components/modals/use-modal'

interface ReceptionBookingCheckInProps {
  booking: ReceptionBooking
  onClose: () => void
}

export const ReceptionBookingCheckIn: React.FC<ReceptionBookingCheckInProps> = ({ booking, onClose }) => {
  const today = React.useMemo(() => formatDate(startOfToday()), [startOfToday()])

  const [toggleIsCheckInOpen] = useModal(
    'ReceptionBookingCheckInDialog',
    { booking },
    {
      persist: true,
      onClose: React.useCallback(() => {
        onClose()
      }, []),
    },
  )

  const handleConfirm = React.useCallback(() => {
    toggleIsConfirmOpen()
    toggleIsCheckInOpen(true)
  }, [])

  const [toggleIsConfirmOpen] = useModal(
    'ReceptionBookingCheckInDateConfirmDialog',
    { booking, handleConfirm },
    { onClose },
  )

  React.useEffect(() => {
    if (booking.date_from === today) {
      toggleIsCheckInOpen()
    } else {
      toggleIsConfirmOpen()
    }
  }, [])

  return null
}
