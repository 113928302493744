import * as React from 'react'
import { ReceptionBooking, ReceptionCheckType } from '@models/reception'
import { ReceptionToCheckInRow } from '@modules/reception/to-checkin/row/reception-to-check-in-row'
import { ReceptionToCheckinFiltersParams } from '@modules/reception/to-checkin/reception-to-checkin'
import { searchByBaseBookingParams } from '@modules/reception/common/search'
import * as R from 'ramda'
import { ReceptionButtons } from '@modules/reception/common/reception-buttons'
import { formatDate } from '@helpers/date-helper'
import { BookingStatus } from '@models/booking'
import { RootState, useAppSelector } from '@store/index'
import { hasVip } from '@helpers/improvements'
import { receptionBookingsListSorter } from '@modules/reception/common/tools'

interface ReceptionToCheckinListProps {
  bookings: ReceptionBooking[]
  filters: ReceptionToCheckinFiltersParams
  resortId: number
}

const onlyVipFilter = (filters: ReceptionToCheckinFiltersParams) => (booking: ReceptionBooking) =>
  !filters.only_vip || hasVip(booking)

const bookingsFilter = (bookings: ReceptionBooking[], filters: ReceptionToCheckinFiltersParams) =>
  [...bookings].filter((booking: ReceptionBooking) => {
    if (!filters.initial && booking.status !== 'confirmed') {
      return false
    }
    if (filters.search && filters.search.trim()) {
      const query = filters.search.trim().toLocaleLowerCase()
      if (!searchByBaseBookingParams(booking, query)) {
        return false
      }
    }
    return true
  })

export const ReceptionToCheckinList: React.FC<ReceptionToCheckinListProps> = ({ resortId, bookings, filters }) => {
  const [filteredBookings, setFilteredBookings] = React.useState<ReceptionBooking[]>(bookings)
  const resortDetails = useAppSelector((state: RootState) => state.receptionState.resortDetails)

  const statuses: BookingStatus[] = ['initial', 'confirmed', 'initial_waiting']

  React.useEffect(() => {
    setFilteredBookings(
      receptionBookingsListSorter(
        bookingsFilter(
          bookings
            .filter(booking => booking.date_from === formatDate(filters.date_from) && statuses.includes(booking.status))
            .filter(onlyVipFilter(filters)),
          filters,
        ),
        filters,
        [R.descend(R.prop('status'))],
      ),
    )
  }, [filters, bookings])

  const ids = filteredBookings.filter(booking => booking).map(booking => booking.id)

  return (
    <>
      <table className="reception__bookings_table table table-striped">
        <tbody>
          {!filteredBookings.length ? (
            <tr>
              <td className="text-center">Brak rezerwacji</td>
            </tr>
          ) : (
            filteredBookings.map(booking => (
              <ReceptionToCheckInRow
                hasBands={resortDetails?.has_band || false}
                booking={booking}
                key={`${booking.id}-${booking.apartment?.id}`}
              />
            ))
          )}
        </tbody>
      </table>
      <ReceptionButtons ids={ids} date={filters.date_from} resortId={resortId} type={ReceptionCheckType.TO_CHECKIN} />
    </>
  )
}
