import * as React from 'react'
import { NotificationEvents } from '@models/dashboard'
import { useAppDispatch } from '@store/index'
import { useWebSocket } from '@components/hooks/use-web-socket'
import { PaymentRegister } from '@models/payments'
import { addPaymentRegister } from '@store/slices/cash-slice'
import { usePaymentAppData } from '@modules/payment-registers/hooks/use-payment-app-data'

interface Props {
  resortId: number
}

export const CashboxSafeModalWebSocketHandler: React.FC<Props> = ({ resortId }) => {
  const dispatch = useAppDispatch()
  const { payment_balance_types } = usePaymentAppData()

  useWebSocket<PaymentRegister>(
    (message, payload) => {
      switch (message) {
        case NotificationEvents.PAYMENT_REGISTER_CHANGE:
          if (
            payload.destination_resort_id === resortId &&
            payment_balance_types.includes((payload as PaymentRegister).kind)
          ) {
            dispatch(addPaymentRegister(payload))
          }
          break
      }
    },
    [],
    [NotificationEvents.PAYMENT_REGISTER_CHANGE],
  )
  return null
}
