import * as React from 'react'
import { SpinInput } from '@components/spin-input'
import { FormPlainError } from '@hyper/forms'
import { Col } from 'reactstrap'
import { formatTemperature } from '@helpers/utils'
import { CustomSwitch } from '@components/custom-switch'
import { useToggle } from '@components/hooks/use-toggle'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { useFormContext } from 'react-hook-form'

export const FloorHeatingDetailsTemperatureParameterChange = (): JSX.Element => {
  const { setValue } = useFormContext()

  const [isGlobal, toggleIsGlobal] = useToggle(true)

  useDidUpdateEffect(() => {
    setValue('isGlobalSetting', isGlobal)
  }, [isGlobal])

  return (
    <>
      <div className="d-block alert alert-info mb-1 font-11 align-items-center d-flex p-1 w-100 fw-semi-bold mx-2 mb-3">
        <i className="uil-exclamation-circle mr-1 font-15" />
        <div>
          Zakres temperatur to {formatTemperature(18)} - {formatTemperature(25)}
        </div>
      </div>
      <Col md={12} className="mb-3">
        <CustomSwitch label="Ustaw dla poszczególnych stref" value={!isGlobal} handleSwitchChange={toggleIsGlobal} />
      </Col>

      <Col md={3} className="mb-2">
        <label className="d-block">{isGlobal ? 'Temperatura' : 'Strefa 7'}</label>
        <SpinInput inputName="zone_7_temperature" inputProps={{ min: 18, max: 25, style: { width: 100 } }} />
        <FormPlainError name="zone_7_temperature" className="mt-2" />
      </Col>

      {!isGlobal && (
        <>
          <Col md={3}>
            <label className="d-block">Strefa 8</label>
            <SpinInput inputName="zone_8_temperature" inputProps={{ min: 18, max: 25, style: { width: 100 } }} />
            <FormPlainError name="zone_8_temperature" className="mt-2" />
          </Col>
          <Col md={3}>
            <label className="d-block">Strefa 9</label>
            <SpinInput inputName="zone_9_temperature" inputProps={{ min: 18, max: 25, style: { width: 100 } }} />
            <FormPlainError name="zone_9_temperature" className="mt-2" />
          </Col>
          <Col md={3}>
            <label className="d-block">Strefa A</label>
            <SpinInput inputName="zone_a_temperature" inputProps={{ min: 18, max: 25, style: { width: 100 } }} />
            <FormPlainError name="zone_a_temperature" className="mt-2" />
          </Col>
        </>
      )}
    </>
  )
}
