import * as React from 'react'
import { Button } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'

export const PackageWholesaleProductCreateButton: React.FC = () => {
  const [toggleModal] = useModal('PackageWholesaleProductCreateModal')

  return (
    <Button onClick={toggleModal} color="green-dark" type="button" role="package-wholesale-product-create-button">
      <i className="mdi mdi-plus-circle mr-1" />
      Dodaj produkt
    </Button>
  )
}
