import * as React from 'react'
import { Button, Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import { SaveButton } from '@hyper/button'
import { useApiRequest } from '@components/hooks/use-api-request'
import { ReservationBookingPayment, WalletTransaction } from '@models/reception'
import { commonObjectGet, commonObjectPost } from '@store/actions/generic-actions'
import { ContentLoader } from '@components/content-loader'
import { toDefaultDateFormat } from '@helpers/date-helper'

interface Props extends BaseModalProps {
  transaction: WalletTransaction
  bookingPayment: ReservationBookingPayment
}

interface SynchronizationStatus {
  last_synchronization: string | null
  symfonia_id: number | null
  id_faktura_zaliczka: number | null
  can_resend: boolean
  is_fake: boolean
  status: string
}

export const ReservationPaymentSynchronizationStatusModal: React.FC<Props> = ({ toggleIsVisible, bookingPayment }) => {
  const [synchronizationStatus, setSynchronizationStatus] = React.useState<SynchronizationStatus>()
  const { isLoading, action: fetch } = useApiRequest(async () => {
    setSynchronizationStatus(await commonObjectGet<SynchronizationStatus>(bookingPayment.urls.synchronization_status))
  })

  const { isLoading: isResendLoading, action: resend } = useApiRequest(async () => {
    setSynchronizationStatus(await commonObjectPost<SynchronizationStatus>(bookingPayment.urls.synchronization_status))
  })

  React.useEffect(() => {
    fetch()
  }, [])

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>Status synchronizacji</ModalHeader>
      <ModalBody>
        <ContentLoader isLoading={isLoading || isResendLoading}>
          {!!synchronizationStatus && (
            <Row>
              <Col md={12}>
                Data synchronizacji z symfonia handel: {toDefaultDateFormat(synchronizationStatus.last_synchronization)}
              </Col>
              <Col md={12}>Identyfikator w symfonia handel: {synchronizationStatus.symfonia_id}</Col>
              <Col md={12}>Id faktura zaliczka: {synchronizationStatus.id_faktura_zaliczka}</Col>
              <Col md={12}>{synchronizationStatus.status}</Col>
              <Col md={12} className="mt-2 text-center">
                <SaveButton
                  onClick={resend}
                  isSaving={isResendLoading}
                  disabled={synchronizationStatus.can_resend}
                  label="Wyślij ponownie"
                  labelSaving="Synchronizowanie.."
                />
              </Col>
            </Row>
          )}
        </ContentLoader>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={toggleIsVisible}>
          Zamknij
        </Button>
      </ModalFooter>
    </>
  )
}
