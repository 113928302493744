import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { apiQueryFunction } from '@api/base'
import { extractSelectOptionsValues } from '@helpers/utils'
import { PaginationResponse } from '@models/dashboard'
import { RootState } from '@store/index'
import { TableFilters } from '@components/table/table'
import { createApiTag, provideListTags } from '@api/query-helper'
import {
  EndlessHolidayInvitation,
  ProductPackage,
  ProductPackageDetails,
  ProductPackageSetDetails,
} from '@modules/endless-holiday/models'
import { EndlessHolidayCreateFormInputs } from '@modules/endless-holiday/transactions/create/modal'

interface EndlessHolidayCreationPayload {
  url: string
  payload: {
    emails: string
    product_package_set: number
    seller: number
  }[]
}

interface EndlessHolidayTransactionCreationPayload {
  url: string
  payload: EndlessHolidayCreateFormInputs
}

const ENDLESS_HOLIDAY_TRANSACTION_TAG = 'ENDLESS_HOLIDAY_TRANSACTION'
const ENDLESS_HOLIDAY_TRANSACTION_TAG_DETAILS = 'ENDLESS_HOLIDAY_TRANSACTION_DETAILS'

const ENDLESS_HOLIDAY_INVITATION_TAG = 'ENDLESS_HOLIDAY_INVITATION'

const ENDLESS_HOLIDAY_PRODUCT_PACKAGE_SET_DETAILS = 'ENDLESS_HOLIDAY_PRODUCT_PACKAGE_SET_DETAILS'

export const endlessHolidayApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: '' }),
  reducerPath: 'endlessHolidayApi',
  tagTypes: [
    ENDLESS_HOLIDAY_TRANSACTION_TAG,
    ENDLESS_HOLIDAY_INVITATION_TAG,
    ENDLESS_HOLIDAY_TRANSACTION_TAG_DETAILS,
    ENDLESS_HOLIDAY_PRODUCT_PACKAGE_SET_DETAILS,
  ],
  endpoints: builder => ({
    getEndlessHolidayTransactions: builder.query<PaginationResponse<ProductPackage>, TableFilters>({
      queryFn: apiQueryFunction((state: RootState, data: TableFilters) => ({
        params: { ...(extractSelectOptionsValues(data) || {}) },
        method: 'GET',
        url: state.appState.appData.urls.product_packages.product_package_list,
      })),
      providesTags: ({ results }: PaginationResponse<ProductPackage>) =>
        provideListTags(ENDLESS_HOLIDAY_TRANSACTION_TAG, ENDLESS_HOLIDAY_TRANSACTION_TAG)(results),
    }),

    deleteEndlessHolidayTransaction: builder.mutation({
      queryFn: apiQueryFunction((state: RootState, { url, data }: { url: string; data: any }) => ({
        data: extractSelectOptionsValues(data),
        method: 'DELETE',
        url,
      })),
      invalidatesTags: [ENDLESS_HOLIDAY_TRANSACTION_TAG_DETAILS],
    }),

    getEndlessHolidayTransactionDetails: builder.query<ProductPackageDetails, string>({
      queryFn: apiQueryFunction((_, url: string) => ({
        method: 'GET',
        url,
      })),
      providesTags: (result: ProductPackageDetails) => [
        createApiTag(ENDLESS_HOLIDAY_TRANSACTION_TAG_DETAILS, result.id),
      ],
    }),

    getEndlessHolidayInvitations: builder.query<PaginationResponse<EndlessHolidayInvitation>, TableFilters>({
      queryFn: apiQueryFunction((state: RootState, data: TableFilters) => ({
        params: { ...(extractSelectOptionsValues(data) || {}) },
        method: 'GET',
        url: state.appState.appData.urls.product_packages.product_package_invite_list,
      })),
      providesTags: ({ results }: PaginationResponse<EndlessHolidayInvitation>) =>
        provideListTags(ENDLESS_HOLIDAY_INVITATION_TAG, ENDLESS_HOLIDAY_INVITATION_TAG)(results),
    }),
    createEndlessHolidayInvite: builder.mutation<EndlessHolidayInvitation, EndlessHolidayCreationPayload>({
      invalidatesTags: [ENDLESS_HOLIDAY_INVITATION_TAG],
      queryFn: apiQueryFunction((_, { url, payload }: EndlessHolidayCreationPayload) => ({
        data: payload,
        method: 'POST',
        url,
      })),
    }),
    createEndlessHolidayTransaction: builder.mutation<ProductPackageDetails, EndlessHolidayTransactionCreationPayload>({
      invalidatesTags: [ENDLESS_HOLIDAY_TRANSACTION_TAG],
      queryFn: apiQueryFunction((_, { url, payload }: EndlessHolidayTransactionCreationPayload) => ({
        data: payload,
        method: 'POST',
        url,
      })),
    }),
    getProductPackageSetDetails: builder.query<ProductPackageSetDetails, string>({
      queryFn: apiQueryFunction((_, url: string) => ({
        method: 'GET',
        url,
      })),
      providesTags: (result: ProductPackageSetDetails) => [
        createApiTag(ENDLESS_HOLIDAY_PRODUCT_PACKAGE_SET_DETAILS, result.id),
      ],
    }),
  }),
})

export const {
  useGetEndlessHolidayTransactionsQuery,
  useGetEndlessHolidayTransactionDetailsQuery,
  useGetEndlessHolidayInvitationsQuery,
  useCreateEndlessHolidayInviteMutation,
  useCreateEndlessHolidayTransactionMutation,
  useDeleteEndlessHolidayTransactionMutation,
  useGetProductPackageSetDetailsQuery,
} = endlessHolidayApi
