import * as React from 'react'
import { TransferPackage } from '@modules/accounting/refund/models'
import { TransferPackagesTableTableCellActionsSent } from '@modules/accounting/refund/transfer-packages/table-cell-actions-sent'
import { TransferPackagesTableTableCellActionsDownload } from '@modules/accounting/refund/transfer-packages/table-cell-actions-download'
import { TransferPackagesTableTableCellActionsExport } from '@modules/accounting/refund/transfer-packages/table-cell-actions-export'
import { TransferPackagesTableTableCellActionsDetails } from '@modules/accounting/refund/transfer-packages/table-cell-actions-details'
import { TransferPackagesTableTableCellActionsEdit } from '@modules/accounting/refund/transfer-packages/table-cell-actions-edit'

interface TransferPackagesTableTableCellActionsProps {
  transferPackage: TransferPackage
}

export const TransferPackagesTableTableCellActions: React.FC<TransferPackagesTableTableCellActionsProps> = ({
  transferPackage,
}) => (
  <>
    <TransferPackagesTableTableCellActionsDetails transferPackage={transferPackage} />
    <TransferPackagesTableTableCellActionsEdit transferPackage={transferPackage} />
    <TransferPackagesTableTableCellActionsExport transferPackage={transferPackage} />
    <TransferPackagesTableTableCellActionsDownload transferPackage={transferPackage} />
    <TransferPackagesTableTableCellActionsSent transferPackage={transferPackage} />
  </>
)
