import { asDecimal } from '@helpers/utils'
import { EndlessHolidayProduct } from '@modules/endless-holiday/transactions/create/modal'
import { useEndlessHolidayProducts } from '@modules/endless-holiday/transactions/create/use-endless-holiday-products'

const STAY_DATE_CHANGE_OPTION_PRICE = 99

interface Response {
  getProductPrice: (product: EndlessHolidayProduct) => number
}

export const useEndlessHolidayPrice = (): Response => {
  const { getProduct, packageData } = useEndlessHolidayProducts()

  const getProductPrice = (product: EndlessHolidayProduct) => {
    const getPrice = (productPrice?: string | number) => asDecimal(productPrice ?? 0).mul(product.amount)

    const subscription = getProduct(product.subscription)
    const gastroCard = getProduct(product.gastro_card)
    const options = product.options.filter(option => option.value === 'yes')

    const subscriptionPrice = getPrice(subscription?.first_price_brutto)
    const gastroCardPrice = getPrice(gastroCard?.first_price_brutto)
    const optionsPrice = options.length ? getPrice(STAY_DATE_CHANGE_OPTION_PRICE.toString()) : 0
    const depositPrices = getPrice(subscription?.deposit_amount)

    const additionalCosts = getPrice(!product.gastro_card ? packageData?.no_gastro_card_additional_fee : 0)

    return asDecimal(subscriptionPrice)
      .plus(gastroCardPrice)
      .plus(optionsPrice)
      .plus(additionalCosts)
      .plus(depositPrices)
      .toNumber()
  }

  return { getProductPrice }
}
