import * as React from 'react'
import { ReservationCard } from '@modules/reservations/details/common/reservation-card'
import { BookingCar, ReceptionBookingDetails, ReceptionBookingDetailsImprovementDetails } from '@models/reception'
import { IconWithText } from '@components/icon-with-text'

const isParking = (improvement: ReceptionBookingDetailsImprovementDetails) => improvement.code === 'parking2'

interface Props {
  bookingDetails: ReceptionBookingDetails
  onShowDefaultCheckinModalStep: (step?: number) => void
}

export const ReceptionBookingCheckInPreviewImprovements = ({
  bookingDetails,
  onShowDefaultCheckinModalStep,
}: Props): JSX.Element => (
  <ReservationCard
    title="Dodane ulepszenia"
    cardClassName="mb-2"
    cardActions={
      <IconWithText
        icon="uil-plus font-14"
        text="dodaj"
        textClass="font-12"
        wrapperClassNames="text-muted cursor-pointer"
        flexClassName="align-items-start"
        onClick={() => onShowDefaultCheckinModalStep(4)}
      />
    }
  >
    {!bookingDetails.improvements.length && <span>Brak</span>}
    {bookingDetails.improvements.map((improvement: ReceptionBookingDetailsImprovementDetails) => (
      <div className="d-flex mb-1 justify-content-between font-12" key={improvement.id}>
        <div>{improvement.name}</div>
        <div className="d-flex justify-content-end">
          {isParking(improvement) &&
            bookingDetails.cars.map((car: BookingCar) => (
              <IconWithText
                key={car.id}
                icon="uil-edit-alt ml-1 mr-0"
                text={car.register_number}
                textClass="d-block"
                wrapperClassNames="mr-3 cursor-pointer"
                flexClassName="flex-row-reverse d-flex"
                onClick={() => onShowDefaultCheckinModalStep(2)}
              />
            ))}
          <span className="text-strong">x{improvement.amount}</span>
        </div>
      </div>
    ))}
  </ReservationCard>
)
