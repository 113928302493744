import * as React from 'react'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { BenefitProgramDetailsInvite } from '@modules/benefit-program/models'
import { BenefitProgramInvitesTableRow } from '@modules/benefit-program/invites/table-row'

const defaultFilters: TableFilters = {
  ordering: 'number',
  search: '',
  page: 1,
  page_size: 10,
}

const headers: TableHeaderValue[] = [{ title: 'Adres e-mail' }, { title: 'Data wysłania' }, { title: '' }]

interface Props {
  benefitProgramInvites: BenefitProgramDetailsInvite[]
}

export const BenefitProgramInvitesTable = ({ benefitProgramInvites }: Props): React.ReactNode => {
  const [filters, setFilters] = React.useState<TableFilters>(defaultFilters)

  return (
    <Table
      className="table-hover w-100 mb-0"
      headerValues={headers}
      filters={filters}
      setFilters={setFilters}
      cols={['30%', '22%']}
      showPagination={false}
    >
      {benefitProgramInvites.map((benefitProgramInvite: BenefitProgramDetailsInvite) => (
        <BenefitProgramInvitesTableRow key={benefitProgramInvite.id} benefitProgramInvite={benefitProgramInvite} />
      ))}
    </Table>
  )
}
