import * as React from 'react'
import {
  FeedingTooltipDetails,
  NewFeedingCalculationDetails,
} from '@modules/reception/checkin/step-feeding/new-feeding/models'
import { ReceptionBookingNewFeedingPriceTooltipBase } from '@modules/reception/checkin/step-feeding/new-feeding/tooltip/reception-booking-new-feeding-price-tooltip-base'
import { ReceptionBookingNEwFeedingPriceTooltipContent } from '@modules/reception/checkin/step-feeding/new-feeding/tooltip/reception-booking-new-feeding-price-tooltip-content'

interface Props {
  calculation: NewFeedingCalculationDetails
  iconClassName?: string
}

export const ReceptionBookingNewFeedingPriceTooltip = React.memo(
  ({ calculation, iconClassName }: Props): JSX.Element => (
    <ReceptionBookingNewFeedingPriceTooltipBase calculation={calculation} iconClassName={iconClassName}>
      {(details: FeedingTooltipDetails | null) => (
        <ReceptionBookingNEwFeedingPriceTooltipContent details={details} calculation={calculation} />
      )}
    </ReceptionBookingNewFeedingPriceTooltipBase>
  ),
)
