import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useForm } from 'react-hook-form'
import { FormInput } from '@hyper/forms'
import { ReceptionBookingDetails, ReceptionBookingGroup } from '@models/reception'
import { addGuestGroup, editGuestGroup, removeGuestGroup } from '@store/actions/reception-actions'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useHandleAxiosServerErrors } from '@helpers/handle-axios-errors'
import { useFormRequest } from '@components/hooks/use-api-request'
import { BaseModalProps } from '@components/modals/types'
import { Form } from '@hyper/forms/form'

interface FormInputs {
  name: string
}

interface StepGuestGroupDialogProps extends BaseModalProps {
  group: ReceptionBookingGroup | null
  booking: ReceptionBookingDetails
}

export const StepGuestGroupDialog: React.FC<StepGuestGroupDialogProps> = ({ toggleIsVisible, group, booking }) => {
  const methods = useForm<FormInputs>({
    defaultValues: {
      name: group?.name || '',
    },
  })
  const { addSuccessNotification } = useNotificationHook()
  const handleAxiosServerErrors = useHandleAxiosServerErrors()

  const [isDeleting, setIsDeleting] = React.useState(false)

  const dispatch = useAppDispatch()

  const handleGroupDelete = async () => {
    if (!group) return

    setIsDeleting(true)
    try {
      await dispatch(removeGuestGroup(group.urls.details))
      addSuccessNotification(`Grupa ${group.name} została usunięta.`)
      toggleIsVisible()
    } catch (error) {
      handleAxiosServerErrors(error)
    } finally {
      setIsDeleting(false)
    }
  }

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    if (!group) {
      await dispatch(addGuestGroup(booking.urls.group, { name: payload.name }))
      addSuccessNotification('Grupa została dodana.')
    } else {
      await dispatch(editGuestGroup(group.urls.details, { name: payload.name }))
      addSuccessNotification('Informacje o grupie zostały zmienione.')
    }

    toggleIsVisible()
  }, methods.setError)

  const modalInformation = {
    title: group ? 'Edytowanie grupy' : 'Dodawanie grupy',
    label: group ? 'Zapisz' : 'Dodaj',
    savingLabel: group ? 'Zapisywanie...' : 'Dodawanie...',
  }

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>{modalInformation.title}</ModalHeader>
      <ModalBody>
        <Row>
          <FormInput placeholder="Podaj nazwę" label="Nazwa grupy" name="name" />
        </Row>
      </ModalBody>
      <ModalFooter>
        {group && (
          <SaveButton
            className="btn btn-outline-danger"
            onClick={handleGroupDelete}
            isSaving={isDeleting}
            labelSaving="Usuwanie..."
            label="Usuń"
            type="button"
            role="delete-group"
          />
        )}
        <Button type="button" color="light" onClick={toggleIsVisible}>
          Anuluj
        </Button>
        <SaveButton
          className="btn btn-green"
          label={modalInformation.label}
          labelSaving={modalInformation.savingLabel}
          isSaving={isLoading}
        />
      </ModalFooter>
    </Form>
  )
}
