import * as React from 'react'
import { ShopProduct } from '@models/shop'
import { CustomReactSelect } from '@components/custom-react-select'
import { components, createFilter } from 'react-select'
import { useFormContext, useWatch } from 'react-hook-form'
import { ShopPOSWithParamsFormInputs } from '@modules/shop/pos/shop-pos-with-params'
import { ShopProductSelectOption } from '@modules/shop/pos/product-selection/shop-product-select-option'
import { FixedSizeList as List } from 'react-window'
import { EnhancedShopProduct } from '@modules/shop/pos/models'

interface ShopProductSelectProps {
  products: ShopProduct[]
  handleProductAdd: (product: ShopProduct) => void
}

interface FilterConfig {
  value: ShopProduct
}

export const ShopProductSelect: React.FC<ShopProductSelectProps> = ({ products, handleProductAdd }) => {
  const { control, setValue, getValues } = useFormContext<ShopPOSWithParamsFormInputs>()

  const [selectedProductOption, paymentEnabled] = useWatch({
    control,
    name: ['selectedProductOption', 'paymentEnabled'],
  })

  React.useEffect(() => {
    if (!selectedProductOption) return
    setValue('selectedProduct', selectedProductOption.value)
  }, [selectedProductOption])

  const options = products.map((product: ShopProduct) => ({
    value: product,
    label: <ShopProductSelectOption product={product} />,
  }))

  React.useEffect(() => {
    if (selectedProductOption) {
      handleProductAdd(getValues('selectedProductOption')?.value || null)
      setValue('selectedProductOption', null)
    }
  }, [selectedProductOption])

  const isOptionDisabled = ({ value }: { value: EnhancedShopProduct }) =>
    value.in_warehouse && value.available_amount < 1

  return (
    <div className="product-select__wrapper">
      <CustomReactSelect
        isOptionDisabled={isOptionDisabled}
        isDisabled={paymentEnabled}
        isClearable={true}
        fieldName="selectedProductOption"
        options={options}
        className="product-select"
        components={selectComponents}
        filterOption={createFilter<FilterConfig>({
          stringify: option => `${option.data.value.name} ${option.data.value.barcode_ean}`,
        })}
        selectStyles={selectStyles}
        placeholder="Wybierz produkt z listy"
        label="Produkt lub usługa:"
        noOptionsMessage={() => 'Brak produktu'}
      />
    </div>
  )
}

const selectComponents = {
  IndicatorSeparator: () => null,
  SingleValue: props => (
    <components.SingleValue {...props}>
      <span className="text-default text-semi-strong">{props.data.label.props.product.name}</span>
    </components.SingleValue>
  ),
  MenuList: props => {
    const rows = props.children

    const Row = ({ index, style }) => <div style={style}>{rows[index] || rows}</div>

    const listHeight = rows.length ? Math.min(rows.length * 100, 300) : 40

    if (!rows.length) {
      return (
        <div className="d-flex justify-content-center align-items-center my-2">
          <span className="text-default text-semi-strong">Brak produktu</span>
        </div>
      )
    }

    return (
      <List
        className="product-select__list"
        width={350}
        height={listHeight}
        itemSize={100}
        itemCount={rows.length || 0}
      >
        {Row}
      </List>
    )
  },
}

const selectStyles = {
  input: provided => ({ ...provided, color: '#6c757d', fontWeight: 500 }),
  control: provided => ({
    ...provided,
    minHeight: '35px',
    height: '36px',
    border: 'none',
    '&:hover': { cursor: 'pointer' },
    boxShadow: 'inset 0px 0px 0px 1px #dee2e6',
  }),
  option: (provided, { isSelected, isDisabled }) => ({
    ...provided,
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #dee2e6',
    backgroundColor: isSelected ? '#dee2e6' : null,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#dee2e6',
    },
    color: isSelected ? 'text-dark' : 'text-muted',
    ...(isSelected && {
      boxShadow: 'inset 0px 1px 0 white, inset 0px -1px 0 white',
    }),
    ...(isDisabled && {
      '&:hover': { cursor: 'default' },
      opacity: '0.5',
      pointerEvents: 'none',
    }),
  }),
}
