import * as React from 'react'
import { Col, Label, Row } from 'reactstrap'
import { FormSelect } from '@hyper/forms/form-select'
import { useHrAppData } from '@modules/hr/use-hr-app-data'
import { createSelectOption, getById } from '@helpers/utils'
import { FormPlain } from '@hyper/forms'
import { useFormContext, useWatch } from 'react-hook-form'
import { AgreementDetails } from '@modules/hr/agreement/models'
import { AgreementFormInputs } from '@modules/hr/agreement/create/modal'
import { startOfToday } from 'date-fns'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import DatePickerInput from '@components/date/date-picker-input'
import { UserPermission } from '@models/dashboard'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { parseISODate } from '@helpers/date-helper'
import { AgreementHourlyRateInput } from '@modules/hr/agreement/create/steps/job-data/hourly-rate-input'

interface Props {
  resortOptions: CustomReactSelectOption[]
  agreementDetails: AgreementDetails
}

export const AgreementCreateFormJobData = ({ resortOptions, agreementDetails }: Props): JSX.Element => {
  const { job_positions, companies, agents } = useHrAppData()
  const user = useAuthenticatedUser()

  const { control, setValue } = useFormContext<AgreementFormInputs>()

  const [selectedJobPosition, selectedCompany, dateOfStart, resort] = useWatch({
    control,
    name: ['job_position', 'company', 'date_of_start', 'resort'],
  })

  const companiesOptions = companies.map(company => createSelectOption(company.name, company.id))

  const agentsOptions = agents.reduce((options, agent) => {
    if (!resort) return []

    return resort.value === agent.resort ||
      (agent.is_hr_agent && user.hasPerm(UserPermission.HrAgreementCanSelectHRAgents))
      ? [...options, createSelectOption(agent.full_name, agent.id)]
      : options
  }, [])

  const jobOptions = job_positions.reduce((options, job) => {
    if (!selectedCompany) return options
    if (job.company === selectedCompany?.value) return [...options, createSelectOption(job.name, job.id)]
    return options
  }, [])

  const position = getById(job_positions, selectedJobPosition?.value)

  useDidUpdateEffect(() => {
    setValue('job_position', null)
    setValue('hourly_rate', '')
  }, [selectedCompany?.value])

  useDidUpdateEffect(() => {
    setValue('agent', null)
  }, [resort?.value])

  const isReadOnly =
    ['verified', 'to_verify'].includes(agreementDetails.status) &&
    !user.hasPerm(UserPermission.HrAgreementCanEditDuringVerification)

  const isAgreementDateReadOnly =
    isReadOnly &&
    agreementDetails.status === 'verified' &&
    !user.hasPerm(UserPermission.HrAgreementCanEditStartDateInVerifiedStatus) &&
    !user.hasPerm(UserPermission.HrAgreementCanEditDuringVerification)

  const getMinStartDate = () => {
    if (user.hasPerm(UserPermission.HrAgreementCanSetRetroactiveDate)) return null

    if (agreementDetails.status === 'verified') {
      return parseISODate(agreementDetails.date_of_start) as Date
    }

    return startOfToday()
  }

  return (
    <Row>
      <FormSelect
        formPlainProps={{ colSize: 6 }}
        label="Resort"
        name="resort"
        options={resortOptions}
        selectProps={{ isDisabled: isReadOnly }}
      />
      <FormSelect
        formPlainProps={{ colSize: 6 }}
        label="Spółka"
        name="company"
        options={companiesOptions}
        selectProps={{ isDisabled: isReadOnly }}
      />
      <FormSelect
        formPlainProps={{ colSize: 4 }}
        label="Pełnomocnik"
        name="agent"
        options={agentsOptions}
        selectProps={{ isDisabled: !resort || isReadOnly }}
      />
      <FormSelect
        formPlainProps={{ colSize: 4 }}
        label="Stanowisko"
        name="job_position"
        options={jobOptions}
        selectProps={{ isDisabled: !selectedCompany?.value || isReadOnly }}
      />

      <AgreementHourlyRateInput
        position={position}
        isDisabled={!selectedJobPosition?.value || isReadOnly}
        colClassName="col-4"
      />

      <hr className="w-100" />

      <FormPlain colSize={6} name="date_of_conclusion">
        <Label>Data rozpoczęcia umowy</Label>
        <DatePickerInput
          name="date_of_start"
          wrapperClassName="flex-grow-1"
          minDate={getMinStartDate()}
          dateFormat="dd LLLL yyyy"
          isDisabled={isAgreementDateReadOnly}
          withInput
        />
      </FormPlain>

      <FormPlain colSize={6} name="date_of_conclusion">
        <Label>Data zakończenia umowy</Label>
        <DatePickerInput
          name="date_of_expiration"
          wrapperClassName="flex-grow-1"
          minDate={dateOfStart ? dateOfStart : startOfToday()}
          dateFormat="dd LLLL yyyy"
          isDisabled={isAgreementDateReadOnly}
          withInput
        />
      </FormPlain>

      <hr className="w-100" />
      <Col md={6}>
        <strong>Opis stanowiska:</strong>
        <div
          className="border rounded bg-light-grey p-2 mt-1 font-12 text-pre-wrap"
          dangerouslySetInnerHTML={{
            __html: position?.description ?? (selectedJobPosition ? 'Brak opisu stanowiska' : 'Wybierz stanowisko'),
          }}
        />
      </Col>

      <Col md={6}>
        <strong>Zakres obowiązków:</strong>
        <div
          className="border rounded bg-light-grey p-2 mt-1 font-12 text-pre-wrap"
          dangerouslySetInnerHTML={{
            __html:
              position?.responsibilities ??
              (selectedJobPosition ? 'Brak przypisanego zakresu obowiązków' : 'Wybierz stanowisko'),
          }}
        />
      </Col>
    </Row>
  )
}
