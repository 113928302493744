import * as React from 'react'
import { FormSelect } from '@hyper/forms/form-select'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { ImprovementIcon } from '@components/improvement-icon'
import { BookingImprovementCode, ResortDetails } from '@models/booking'
import { createSelectOption } from '@helpers/utils'

const CLEANING_ORDER_IMPROVEMENTS_CODES: BookingImprovementCode[] = [
  'grill',
  'chair',
  'towel',
  'bathtub',
  'bed',
  'game_console',
  'cosmetics',
  'wine',
  'fridge_magnet',
  'fruits',
  'sweets',
  'towel_vip',
  'microwave',
  'beauty_set',
  'wine_standard',
]

interface Props {
  resortDetails: ResortDetails | undefined
}

export const HousekeepingImprovementOrderTableHeaderImprovements = ({ resortDetails }: Props): JSX.Element => {
  const improvementOptions: CustomReactSelectOption[] =
    resortDetails?.improvements
      .filter(row => CLEANING_ORDER_IMPROVEMENTS_CODES.includes(row.code))
      .map(row => createSelectOption(row.name, row.code)) || []

  return (
    <th className="py-1 border-top-0 fw-semi-bold pl-0" style={{ width: 300 }}>
      <FormSelect
        options={improvementOptions}
        selectProps={{
          placeholder: 'Wykupione ulepszenia',
          isClearable: true,
          isSearchable: false,
          formatOptionLabel,
          isMulti: true,
        }}
        name="improvements"
        formPlainProps={{ formGroupClassName: 'mb-0', colClassName: 'px-0' }}
      />
    </th>
  )
}

const formatOptionLabel = ({ label, value }) => (
  <div className="d-flex align-items-center">
    <ImprovementIcon improvementCode={value} className="text-secondary mr-1" />
    {label}
  </div>
)
