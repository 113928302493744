import * as React from 'react'
import { Col, Row } from 'reactstrap'
import { FormInput } from '@hyper/forms'
import { CustomSwitch } from '@components/custom-switch'
import { useFormContext, useWatch } from 'react-hook-form'

export const RodoAgreementsAcquiringPointCreateModalBaseData = () => (
  <>
    <h5 className="text-secondary mb-3">Konfiguracja formularza</h5>
    <Row>
      <FormInput
        name="name"
        label="Nazwa formularza:"
        labelProps={{ className: 'col-5 pl-0' }}
        formGroupClassName="d-flex align-items-center"
        inputClassName="col-6"
      />
      <FormInput
        name="slug"
        label="Identyfikator formularza:"
        labelProps={{ className: 'col-5 pl-0' }}
        formGroupClassName="d-flex align-items-center"
        inputClassName="col-6"
      />
      <Col md={12}>
        <Row>
          <Col md={5} className="font-weight-bold">
            Status:
          </Col>
          <Col md={6} className="pl-0">
            <CustomSwitch label="Aktywny" />
          </Col>
        </Row>
      </Col>
    </Row>
    <hr className="mx-n2" />
    <h5 className="text-secondary mb-3">Wygląd formularza</h5>
    <Row>
      <ColorInput label="Kolor podstawowy:" name="primary_color" />
      <ColorInput label="Kolor dodatkowy:" name="secondary_color" />
      <FormInput
        type="file"
        name="logo"
        label="Logo:"
        placeholder="#FF0000"
        labelProps={{ className: 'col-5 pl-0' }}
        formGroupClassName="d-flex align-items-center"
        inputClassName="col-6 pl-0"
      />
      <FormInput
        type="file"
        name="image"
        label="Obrazek:"
        placeholder="#FF0000"
        labelProps={{ className: 'col-5 pl-0' }}
        formGroupClassName="d-flex align-items-center"
        inputClassName="col-6 pl-0"
      />
      <FormInput
        type="textarea"
        name="form_title"
        label="Tytuł formularza:"
        labelProps={{ className: 'col-5 pl-0' }}
        formGroupClassName="d-flex align-items-center"
        inputClassName="col-6"
      />
      <FormInput
        type="textarea"
        name="introductory_text"
        label="Akapit I:"
        labelProps={{ className: 'col-5 pl-0' }}
        formGroupClassName="d-flex align-items-center"
        inputClassName="col-6"
      />
      <FormInput
        type="textarea"
        name="accompanying_text"
        label="Akapit II:"
        labelProps={{ className: 'col-5 pl-0' }}
        formGroupClassName="d-flex align-items-center"
        inputClassName="col-6"
      />
      <FormInput
        name="button_text"
        label="Treść przycisku:"
        labelProps={{ className: 'col-5 pl-0' }}
        formGroupClassName="d-flex align-items-center"
        inputClassName="col-6"
      />
    </Row>
    <hr className="mx-n2" />
    <h5 className="text-secondary mb-3">Regulamin</h5>
    <Row>
      <FormInput
        name="promotion_rules"
        label="Identyfikator regulaminu"
        placeholder="regulamin-2020"
        labelProps={{ className: 'col-5 pl-0' }}
        formGroupClassName="d-flex align-items-center"
        inputClassName="col-6"
      />
    </Row>
  </>
)

const ColorInput = ({ name, label }) => {
  const { control } = useFormContext()
  const color = useWatch({ control, name })

  return (
    <div className="col-12 px-0 d-flex">
      <FormInput
        name={name}
        label={label}
        placeholder="#FF0000"
        labelProps={{ className: 'col-5 pl-0' }}
        formGroupClassName="d-flex align-items-center"
        inputClassName="col-5"
        afterInput={<div className="border rounded ml-3" style={{ width: 20, height: 20, backgroundColor: color }} />}
      />
    </div>
  )
}
