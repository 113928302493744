import * as React from 'react'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { PaymentReport, PaymentsReportFiltersParams } from '@modules/reports/models'
import { formatPrice } from '@helpers/utils'
import { resortsMapSelector } from '@store/selectors/dashboard'
import Table, { TableHeaderValue } from '@components/table/table'
import { useAppSelector } from '@store/index'

interface Props {
  isLoading: boolean
  paymentsReport: PaymentReport[]
  setFilters: (filters: PaymentsReportFiltersParams) => void
  filters: PaymentsReportFiltersParams
}

const headerValues: TableHeaderValue[] = [
  {
    sortField: 'booking__resort__name',
    title: 'Ośrodek',
  },
  {
    sortField: 'created_at',
    title: 'Data',
  },
  {
    sortField: 'amount',
    title: 'Kwota',
  },

  {
    sortField: 'booking__reservation_number',
    title: 'Numer rezerwacji',
  },
  {
    sortField: 'type',
    title: 'Typ',
  },
  {
    sortField: 'source',
    title: 'Źródło',
  },
  {
    sortField: 'booking__name',
    title: 'Imię i nazwisko',
  },
  {
    sortField: 'user',
    title: 'Dodane przez',
  },
]

export const PaymentsReportsTable: React.FC<Props> = ({ setFilters, paymentsReport, filters, isLoading }) => {
  const resortsMap = useAppSelector(resortsMapSelector)

  return (
    <Table
      className="table-hover w-100"
      headerValues={headerValues}
      isLoading={isLoading}
      filters={filters}
      setFilters={setFilters}
    >
      {paymentsReport.map(report => (
        <Table.Row key={report.id}>
          <Table.Cell>{resortsMap[report.resort_id]?.name || 'brak'}</Table.Cell>
          <Table.Cell>{toDefaultDateFormat(report.created_at)}</Table.Cell>
          <Table.Cell>{formatPrice(report.amount)}</Table.Cell>
          <Table.Cell>{report.reservation_number}</Table.Cell>
          <Table.Cell>{report.type}</Table.Cell>
          <Table.Cell>{report.source}</Table.Cell>
          <Table.Cell>{report.name}</Table.Cell>
          <Table.Cell>{report.user}</Table.Cell>
        </Table.Row>
      ))}
    </Table>
  )
}
