import * as React from 'react'
import { useForm } from 'react-hook-form'
import { Button, Col, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import ModalBody from 'reactstrap/lib/ModalBody'
import { useAppDispatch, useAppSelector } from '@store/index'
import { HtmlEditorLazy } from '@components/html-editor/html-editor-lazy'
import { getReceptionBookingDetails } from '@store/actions/reception-actions'
import { NotificationContentTemplate } from '@components/notification-content-template'
import { ReceptionBooking, ReceptionBookingDetails, ReceptionEmailNotifications } from '@models/reception'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useFormRequest } from '@components/hooks/use-api-request'
import { receptionBookingDetailsSelector } from '@store/selectors/reception'
import { BaseModalProps } from '@components/modals/types'
import { commonObjectPost } from '@store/actions/generic-actions'
import { Form } from '@hyper/forms/form'

interface ReceptionNotificationEmailDialogProps extends BaseModalProps {
  booking: ReceptionBooking
  emailSentSuccessfully?: (updatedBookingData: ReceptionBookingDetails) => void
}

interface FormInputs {
  template: string
}

export const ReceptionNotificationEmailDialog: React.FC<ReceptionNotificationEmailDialogProps> = ({
  booking,
  toggleIsVisible,
  emailSentSuccessfully = null,
}) => {
  const [content, setContent] = React.useState('')
  const dispatch = useAppDispatch()
  const methods = useForm<FormInputs>({
    defaultValues: {
      template: '',
    },
    mode: 'all',
  })
  const { addSuccessMessage } = useNotificationHook()

  const email_notifications = [] as ReceptionEmailNotifications[]
  const bookingDetails = useAppSelector(receptionBookingDetailsSelector)

  const templateName = methods.watch('template')

  React.useEffect(() => {
    dispatch(getReceptionBookingDetails(booking.urls.api_details))
  }, [])

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    const updatedBookingData = await commonObjectPost<ReceptionBookingDetails>(
      bookingDetails?.urls.notification_email,
      {
        ...payload,
        template: payload.template || undefined,
        content,
      },
    )
    toggleIsVisible()
    addSuccessMessage('Wiadomość EMAIL została wysłana', `Wiadomość EMAIL do ${booking.name} została poprawnie wysłana`)
    emailSentSuccessfully?.(updatedBookingData)
  }, methods.setError)

  const handleChangeTemplate = () => {
    const template = (email_notifications || []).find(t => t.key === templateName)

    if (template) {
      setContent(template.content)
    }
  }

  return (
    <Form methods={methods} onSubmit={onSubmit} className="reception__notification_email_dialog">
      <ModalHeader toggle={toggleIsVisible}>
        Wiadomość e-mail do {booking.name} ({booking.email})
      </ModalHeader>
      <LoaderPlaceholder content={bookingDetails}>
        <ModalBody>
          <HtmlEditorLazy className="mt-0" setContent={setContent} contentHtml={content} />
        </ModalBody>
        <ModalFooter style={{ display: 'block' }}>
          <Row>
            <Col md={5} className="pl-0">
              <NotificationContentTemplate
                onChange={handleChangeTemplate}
                name="template"
                options={email_notifications || []}
              />
            </Col>
            <Col md={7} className="text-right pr-0">
              <Button color="light" onClick={toggleIsVisible}>
                Anuluj
              </Button>

              <SaveButton
                label="Wyślij e-mail"
                role="notification-submit"
                className="btn btn-green ml-1"
                labelSaving="Wysyłanie..."
                isSaving={isLoading}
              />
            </Col>
          </Row>
        </ModalFooter>
      </LoaderPlaceholder>
    </Form>
  )
}
