import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useFormRequest } from '@components/hooks/use-api-request'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAppDispatch } from '@store/index'
import { BaseModalProps } from '@components/modals/types'
import { BarrierTicket } from '@models/barriers'
import { createBarrierTickets, updateBarrierTickets } from '@store/actions/barriers-actions'
import { parseISODate } from '@helpers/date-helper'
import { BarrierTicketModalForm } from '@modules/barriers/barrier-ticket-modal/form'
import { startOfToday, startOfTomorrow } from 'date-fns'

interface Props extends BaseModalProps {
  ticket?: BarrierTicket
  name: string
  createUrl: string
}

export interface BarrierTicketFormInputs {
  description: string
  register_number: string
  range: string
  resorts: string[]
  datetime_to: Date | undefined
  datetime_from: Date | undefined
  phone: string
}

export const BarrierTicketModal: React.FC<Props> = ({ createUrl, name, ticket, toggleIsVisible }) => {
  const methods = useForm<BarrierTicketFormInputs>({
    defaultValues: {
      range: ticket ? '0' : '1',
      ...ticket,
      resorts: ticket?.resorts.map(row => String(row)) || [],
      datetime_to: parseISODate(ticket?.datetime_to) || startOfTomorrow(),
      datetime_from: parseISODate(ticket?.datetime_from) || startOfToday(),
      phone: ticket?.phone || '',
    },
  })
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading, action: onSubmit } = useFormRequest(async payload => {
    await dispatch(
      ticket ? updateBarrierTickets([ticket.urls.details, payload]) : createBarrierTickets([createUrl, payload]),
    )
    addSuccessMessage('Sukces', ticket ? 'Zmiany zostały zapisane' : 'Pojazd został dodany')
    toggleIsVisible()
  }, methods.setError)

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <ModalHeader toggle={toggleIsVisible}>{ticket ? `Edytuj ${name}` : `Dodaj ${name}`}</ModalHeader>
      <ModalBody>
        <FormProvider {...methods}>
          <BarrierTicketModalForm ticket={ticket} />
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>

        <SaveButton className="btn btn-green" isSaving={isLoading} />
      </ModalFooter>
    </form>
  )
}
