import * as React from 'react'
import { Button, Col, Row } from 'reactstrap'
import { asDecimal, formatPriceShort } from '@helpers/utils'
import { ReceptionBookingDetails } from '@models/reception'
import { ReceptionBookingDetailsImprovement } from '@models/booking'

interface CheckInImprovementsSummaryProps {
  booking: ReceptionBookingDetails
  toggleIsAddingImprovementMode: () => void
  isAddingImprovementMode: boolean
}

export const ReceptionBookingCheckInImprovementsSummary: React.FC<CheckInImprovementsSummaryProps> = ({
  booking,
  toggleIsAddingImprovementMode,
  isAddingImprovementMode,
}) => {
  const price = React.useMemo(
    () => formatPriceShort(asDecimal(booking.improvements_brutto).minus(booking.feeding_price_brutto).toString()),
    [booking],
  )

  const lengthImprovementsWithoutFeeding = booking.improvements.filter(
    (improvement: ReceptionBookingDetailsImprovement) => !improvement.code.startsWith('feeding__'),
  ).length

  return (
    <Row className="mt-2">
      <Col md={3} className="d-flex align-items-center" data-testid="improvements-amount">
        <strong>
          Ulepszenia razem: <strong className="text-success">{lengthImprovementsWithoutFeeding}</strong>
        </strong>
      </Col>
      <Col md={6} className="d-flex justify-content-center">
        <Button
          color="secondary"
          onClick={toggleIsAddingImprovementMode}
          className="d-flex align-items-center"
          disabled={isAddingImprovementMode}
        >
          <i className="uil-plus-circle mr-1" />
          <span>Dodaj ulepszenie</span>
        </Button>
      </Col>
      <Col md={3} className="d-flex justify-content-end align-items-center">
        <strong className="font-18 text-danger">{price}</strong>
      </Col>
    </Row>
  )
}
