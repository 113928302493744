import * as React from 'react'
import { Button, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { SaveButton } from '@hyper/button'
import { useAppDispatch } from '@store/index'
import { useForm } from 'react-hook-form'
import { endOfMonth, startOfMonth, startOfToday } from 'date-fns'
import { createReportTask } from '@store/actions/report-tasks-actions'
import { useReportFormRequest } from '@modules/reports/hooks/use-report-request'
import { BaseModalProps } from '@components/modals/types'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useSaleAppData } from '@modules/sale/hooks/use-sale-app-data'
import { Form } from '@hyper/forms/form'
import { FormSelect } from '@hyper/forms/form-select'
import { useAppData } from '@components/hooks/use-app-data'
import { createSelectOption } from '@helpers/utils'

interface FormInputs {
  created_at_after: Date
  created_at_before: Date
  bill_source: CustomReactSelectOption | undefined
  resort: CustomReactSelectOption | undefined
  seller: CustomReactSelectOption | undefined
  automatic: CustomReactSelectOption | undefined
}

export const ReportsReservationBillItemModal: React.FC<BaseModalProps> = ({ toggleIsVisible }) => {
  const { voucher_scopes, urls } = useAppData()

  const { sale_points } = useSaleAppData()

  const salePoints: CustomReactSelectOption[] = sale_points.map(row => ({
    label: row.seller,
    value: row.seller,
  }))

  const dispatch = useAppDispatch()
  const user = useAuthenticatedUser()

  const methods = useForm<FormInputs>({
    mode: 'all',
    defaultValues: {
      created_at_after: startOfMonth(startOfToday()),
      created_at_before: endOfMonth(startOfToday()),
      resort: undefined,
      automatic: undefined,
      bill_source: undefined,
    },
  })

  const { isLoading, action: onSubmit } = useReportFormRequest(async (payload: FormInputs) => {
    const reportTask = await dispatch(
      createReportTask([
        urls.reports.report_task_reservation_bill_item_report,
        {
          ...payload,
          resort: payload.resort?.value,
          seller: payload.seller?.value,
          automatic: payload.automatic?.value,
          bill_source: payload.bill_source?.value,
        },
      ]),
    )
    toggleIsVisible()
    return reportTask
  }, methods.setError)

  const resortsOptions: CustomReactSelectOption[] = user.resorts.map(resort => ({
    value: resort.id,
    label: resort.name,
  }))

  const bilSourceOptions: CustomReactSelectOption[] = voucher_scopes.map(scope =>
    createSelectOption(scope.name, scope.id),
  )

  const automaticOptions: CustomReactSelectOption[] = [
    { label: 'Tak', value: true },
    { label: 'Nie', value: false },
  ]

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Rezerwacje - Płatności na pokój (szczegółowy)</ModalHeader>
      <ModalBody>
        <Row>
          <DateFromToInput
            colSize={12}
            label="Data utworzenia"
            startDateName="created_at_after"
            endDateName="created_at_before"
          />
          <FormSelect
            options={bilSourceOptions}
            name="bill_source"
            label="Kategoria zakupów"
            selectProps={{ isClearable: true }}
            formPlainProps={{ colSize: 6, formGroupClassName: 'mb-3' }}
          />
          <FormSelect
            options={salePoints}
            name="seller"
            label="Miejsce sprzedaży"
            formPlainProps={{ colSize: 6, formGroupClassName: 'mb-3' }}
          />

          <FormSelect
            options={resortsOptions}
            name="resort"
            label="Ośrodek"
            formPlainProps={{ colSize: 6, formGroupClassName: 'mb-3' }}
          />
          <FormSelect
            options={automaticOptions}
            name="automatic"
            label="Bezobsługowe"
            selectProps={{ isClearable: true }}
            formPlainProps={{ colSize: 6, formGroupClassName: 'mb-3' }}
          />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" label="Zleć raport" labelSaving="Zlecanie.." isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
