import * as React from 'react'
import { TechnicalOrder } from '@models/technical-orders'
import { IconWithTooltip } from '@components/icon-with-tooltip'

interface Props {
  technicalOrder: TechnicalOrder
}

export const TechnicalOrdersTodoRowEnterWhereGuestAbsence: React.FC<Props> = ({ technicalOrder }) => (
  <>
    {technicalOrder.enter_where_guest_absence && (
      <IconWithTooltip
        icon="uil-signout font-16"
        color="text-danger"
        tooltipMessage={<span className="font-11">Wejście pod nieobecność gościa</span>}
        tooltipId={`technical-order-enter-where-guest-absence-tooltip-${technicalOrder.id}`}
      />
    )}
    {!!technicalOrder.next_booking?.checked_in && (
      <IconWithTooltip
        icon="uil-chat-bubble-user font-16"
        color="text-danger"
        tooltipMessage={
          <span className="font-11">
            {technicalOrder.next_booking?.checked_in ? (
              <span className="text-muted">
                <span className="text-white">Trwa pobyt</span> Gości
              </span>
            ) : (
              'Goście nieobecni'
            )}
          </span>
        }
        tooltipId={`technical-order-enter-where-guest-absence-range-tooltip-${technicalOrder.id}`}
      />
    )}
  </>
)
