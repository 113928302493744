import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { ReceptionBookingCartPaymentBaseActions } from '@modules/reception/cart/payment-box/payment-options-content/reception-booking-cart-payment-base-actions'
import { ReceptionBookingCartPaymentInfoRow } from '@modules/reception/cart/payment-box/payment-options-content/reception-booking-cart-payment-info-row'
import { formatDate, parseISODate } from '@helpers/date-helper'
import declination from '@helpers/declination'
import { differenceInDays } from 'date-fns'
import { FormSelect } from '@hyper/forms/form-select'
import { CartSelection } from '@modules/reception/cart/payment-box/reception-booking-cart-payment-box'
import { ReceptionBookingDetailsCart } from '@models/reception'
import { useSelector } from 'react-redux'
import { receptionBookingDetailsSelector } from '@store/selectors/reception'
import { useAppData } from '@components/hooks/use-app-data'
import { Resort } from '@models/booking'
import { ACCOMMODATION_TYPES } from '@helpers/consts'
import { useApiRequest, useFormRequest } from '@components/hooks/use-api-request'
import { commonObjectGet, commonObjectPost } from '@store/actions/generic-actions'
import { BaseCartPaymentResponse } from '@modules/reception/cart/models'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { ApartmentGuest } from '@store/actions/shop-actions'
import { createSelectOption } from '@helpers/utils'

interface FormInputs {
  guest: CustomReactSelectOption | null
}

interface Props {
  onDecline: () => void
  url: string
  cartSelection: CartSelection
  onCartUpdate: (cart: ReceptionBookingDetailsCart) => void
}

export const ReceptionBookingCartPaymentWallet = ({
  onDecline,
  url,
  onCartUpdate,
  cartSelection,
}: Props): JSX.Element => {
  const { resorts, urls } = useAppData()
  const bookingDetails = useSelector(receptionBookingDetailsSelector)
  const [guests, setGuests] = React.useState<ApartmentGuest[]>([])

  const methods = useForm<FormInputs>({ defaultValues: { guest: null } })

  const bookingResort = resorts.find((resort: Resort) => resort.id === bookingDetails.resort_id)

  const numberOfNights = differenceInDays(
    parseISODate(bookingDetails.date_to) as Date,
    parseISODate(bookingDetails.date_from) as Date,
  )

  const guest = useWatch({ control: methods.control, name: 'guest' })
  const guestsOptions = guests.map((wallet: ApartmentGuest) => createSelectOption(wallet.name, wallet.id))

  const { action: handlePay, isLoading } = useFormRequest(async () => {
    const response = await commonObjectPost<BaseCartPaymentResponse>(url, {
      ...cartSelection,
      guest: guest?.value,
    })

    onCartUpdate(response.cart_details)
    onDecline()
  }, methods.setError)

  const { action: fetchGuests } = useApiRequest(async () => {
    setGuests(
      await commonObjectGet<any>(urls.wallet.account, {
        booking_id: bookingDetails.id,
        resort_id: bookingDetails.resort_id,
        source: 'reservation',
      }),
    )
  })

  React.useEffect(() => {
    fetchGuests()
  }, [])

  return (
    <FormProvider {...methods}>
      <IconWithText
        icon="uil-estate font-14"
        text="Zakup na rachunek Gościa lokalu:"
        wrapperClassNames="text-primary mb-3 font-weight-bold"
      />
      <ReceptionBookingCartPaymentInfoRow
        className="mb-1"
        title="Pobyt:"
        value={
          <span>
            <strong>
              {formatDate(bookingDetails.date_from, 'dd.MM')} - {formatDate(bookingDetails.date_to, 'dd.MM.yyyy')}
            </strong>
            <span>
              {' '}
              ({numberOfNights} {declination.night(numberOfNights)})
            </span>
          </span>
        }
      />
      <ReceptionBookingCartPaymentInfoRow
        className="mb-1"
        title="Nr rezerwacji:"
        value={<strong>{bookingDetails.reservation_number}</strong>}
      />
      <ReceptionBookingCartPaymentInfoRow
        className="mb-1"
        title="Ośrodek:"
        value={<strong>{bookingResort?.name}</strong>}
      />
      <ReceptionBookingCartPaymentInfoRow
        title="Lokal:"
        value={
          <span>
            <strong>{bookingDetails.apartment.name}</strong> (
            {ACCOMMODATION_TYPES.APARTMENTS.includes(bookingDetails.apartment_id) ? 'apartament' : 'domek'})
          </span>
        }
      />

      <FormSelect
        options={guestsOptions}
        name="guest"
        label="Kto dokonuje zakupu"
        formPlainProps={{ colClassName: 'px-0 font-weight-normal mt-3' }}
      />

      <ReceptionBookingCartPaymentBaseActions
        confirmDisabled={!guest}
        onDecline={onDecline}
        isSaving={isLoading}
        onConfirm={handlePay}
      />
    </FormProvider>
  )
}
