import * as React from 'react'
import { Tooltip } from 'reactstrap'
import { ImprovementIcon } from '@components/improvement-icon'
import { ImprovementOrderItem } from '@modules/housekeeping/models'
import { useToggle } from '@components/hooks/use-toggle'
import { asDecimal, groupByField, sumDecimalArray } from '@helpers/utils'

interface Props {
  tooltipId: string
  children: React.ReactNode
  improvementsGrouped: ImprovementOrderItem[]
}

export const ImprovementTooltip = ({ children, tooltipId, improvementsGrouped }: Props): JSX.Element => {
  const [isOpen, toggleIsOpen] = useToggle()
  return (
    <>
      <div className="d-inline-flex align-items-center" id={tooltipId} onClick={toggleIsOpen}>
        {children}
      </div>
      <Tooltip
        autohide={true}
        isOpen={isOpen}
        placement="top"
        target={tooltipId}
        toggle={toggleIsOpen}
        innerClassName="font-11"
        style={{ maxWidth: 300 }}
      >
        <div className="text-center mb-2">Wykupione ulepszenia</div>
        {improvementsGrouped.map((improvement, i) => (
          <div className="mb-1 text-left" key={i}>
            <ImprovementIcon improvementCode={improvement.code} className="text-secondary" /> {improvement.amount}x{' '}
            <strong>{improvement.name}</strong>
          </div>
        ))}
      </Tooltip>
    </>
  )
}

interface ImprovementsGroupedProps {
  elements: ImprovementOrderItem[]
}

export const useTooltipImprovementsGrouped = ({ elements }: ImprovementsGroupedProps) => {
  const improvements = elements
    .filter(item => !item.code.startsWith('feeding__'))
    .filter(item => !['package_vip', 'washing'].includes(item.code))

  const grouped = groupByField(improvements, 'code')

  const improvementsGrouped = Object.values(grouped).map((rows: ImprovementOrderItem[]) => ({
    ...rows[0],
    amount: sumDecimalArray(rows.map(el => asDecimal(el.amount))).toNumber(),
  }))

  return {
    improvementsGrouped,
    improvements,
  }
}
