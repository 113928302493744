import * as React from 'react'
import { useTimeoutWhen } from 'rooks'

export const useHoverMenu = (isReadonly: boolean) => {
  const [mouseOver, setMouseOver] = React.useState(false)
  const [isMenuVisible, setIsMenuVisible] = React.useState(false)

  const showMenu = () => setIsMenuVisible(true)
  const hideMenu = () => setIsMenuVisible(false)

  const handleMouseEnter = () => {
    if (isReadonly) return
    setMouseOver(true)
  }
  const handleMouseLeave = () => setMouseOver(false)

  useTimeoutWhen(showMenu, 1000, mouseOver)
  useTimeoutWhen(hideMenu, 0, !mouseOver)

  const reset = () => {
    setIsMenuVisible(false)
    setMouseOver(false)
  }

  return {
    isMenuVisible,
    handleMouseEnter,
    handleMouseLeave,
    reset,
  }
}
