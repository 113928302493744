import * as React from 'react'
import { SubscriptionContractDetails } from '@modules/subscription-contract/models'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApiRequest } from '@components/hooks/use-api-request'
import { updateSubscriptionContractDetails } from '@store/slices/subscription-contract-slice'
import { commonObjectPost } from '@store/actions/generic-actions'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { ButtonWithIcon } from '@components/button-with-icon'
import { useAppDispatch } from '@store/index'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'

interface Props {
  subscriptionContractDetails: SubscriptionContractDetails
}

export const SubscriptionContractDetailsResume = ({ subscriptionContractDetails }: Props): JSX.Element | null => {
  const { addSuccessNotification } = useNotificationHook()
  const user = useAuthenticatedUser()
  const dispatch = useAppDispatch()

  const { isLoading: isRemoving, action: handleRemove } = useApiRequest(async reason => {
    dispatch(
      updateSubscriptionContractDetails(
        await commonObjectPost<SubscriptionContractDetails>(subscriptionContractDetails.urls.resume, { reason }),
      ),
    )
    addSuccessNotification('Subskrypcja została wznowiona')
  })

  if (
    !user.hasPerm(UserPermission.PromotionsSubscriptionContractCanResume) ||
    !subscriptionContractDetails.can_resume
  ) {
    return null
  }

  return (
    <CommonObjectConfirmAction
      isLoading={isRemoving}
      handleAccept={handleRemove}
      askForReason={true}
      message={
        <>
          {subscriptionContractDetails.status === 'cancelling'
            ? `Czy na pewno chcesz przerwać proces anulowania subskrypcji nr. ${subscriptionContractDetails.number} ?`
            : `Czy na pewno chcesz wznowić subskrypcję nr. ${subscriptionContractDetails.number} ?`}
          Wszystkie benefity zostaną przywrócone.
        </>
      }
    >
      <ButtonWithIcon
        icon="uil-repeat"
        color="info"
        text={subscriptionContractDetails.status === 'cancelling' ? 'Zrezygnuj z anulowania' : 'Wznów'}
      />
    </CommonObjectConfirmAction>
  )
}
