import * as React from 'react'
import { SubscriptionListFilter, SubscriptionListFilterParams } from '@modules/subscription/list/filter'
import { subscriptionStatusList } from '@modules/subscription/models'
import { useAppDispatch, useAppSelector } from '@store/index'
import { totalSizeSelector } from '@store/selectors/dashboard'
import { useSubscriptionAppData } from '@modules/subscription/hooks/use-subscription-app-data'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { useApiRequest } from '@components/hooks/use-api-request'
import { getSubscriptions } from '@store/actions/subscription-actions'
import { useDebounce } from 'rooks'
import { useDocumentTitle } from '@helpers/utils'
import { PageTitle } from '@hyper/page-title'
import { Card, CardBody } from 'reactstrap'
import { SubscriptionWebSocketHandler } from '@modules/subscription/web-socket-handler'
import { SubscriptionListTable } from '@modules/subscription/list/table'
import { PackageWholesalePackagesFilterButtons } from '@modules/package-wholesale/packages/filter-buttons'
import { clearSubscriptions, subscriptionsSelector } from '@store/slices/subscription-slice'
import { SubscriptionDetailsView } from '@modules/subscription/list/details'
import { useParams } from 'react-router-dom'

const defaultFilters: SubscriptionListFilterParams = {
  created_after: undefined,
  accommodation_type: null,
  created_before: undefined,
  seller: undefined,
  source_marketing: undefined,
  kind: [],
  option_kinds: [],
  status: subscriptionStatusList.filter(row => ['initial', 'confirmed'].includes(row.value)),
  search: '',
  ordering: '-created',
  page: 1,
  page_size: 10,
}
export const PackageWholesalePackagesView = (): JSX.Element => {
  const totalSize = useAppSelector(totalSizeSelector)
  const { id } = useParams<'id'>()

  const [filters, setFilters] = React.useState<SubscriptionListFilterParams>({
    ...defaultFilters,
  })
  const dispatch = useAppDispatch()
  const subscriptions = useAppSelector(subscriptionsSelector).filter(row =>
    filters.status?.length ? filters.status?.some(status => status.value === row.status) : true,
  )
  useSubscriptionAppData()
  useMenuActive(NavigationPath.PackageWholesaleList)

  const { isLoading, action: fetchSubscriptions } = useApiRequest(
    async filters => await dispatch(getSubscriptions([filters, 'WHOLESALE'])),
  )

  const fetchSubscriptionsDebounced = React.useCallback(useDebounce(fetchSubscriptions, 300), [])
  React.useEffect(() => {
    fetchSubscriptionsDebounced(filters)
  }, [filters])

  React.useEffect(
    () => () => {
      dispatch(clearSubscriptions())
    },
    [],
  )

  const pageTitle = 'Lista sprzedanych pakietów hurtowych'
  const pageTitleWithSize = pageTitle + ` (${totalSize})`
  useDocumentTitle(pageTitleWithSize)

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitleWithSize} />
      <Card>
        <SubscriptionWebSocketHandler type="WHOLESALE" />
        {id && <SubscriptionDetailsView type="WHOLESALE" id={parseInt(id, 10)} />}

        <CardBody>
          <SubscriptionListFilter
            defaultFilters={defaultFilters}
            filters={filters}
            setFilters={setFilters}
            type="WHOLESALE"
          >
            <PackageWholesalePackagesFilterButtons />
          </SubscriptionListFilter>
          <SubscriptionListTable
            type="WHOLESALE"
            isLoading={isLoading}
            subscriptions={subscriptions}
            filters={filters}
            setFilters={setFilters}
          />
        </CardBody>
      </Card>
    </>
  )
}
