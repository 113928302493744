import * as React from 'react'
import { NotificationEvents } from '@models/dashboard'
import { useAppDispatch } from '@store/index'
import { getApplicationsAggregation } from '@store/actions/housekeeping-actions'
import { useWebSocket } from '@components/hooks/use-web-socket'
import { FaultOrderDetails, IssueOrderDetails } from '@modules/housekeeping/applications/models'
import { FiltersHousekeepingApplicationFiltersParams } from '@modules/housekeeping/applications/filters'
import { updateHousekeepingFaultOrder, updateHousekeepingIssueOrder } from '@store/slices/housekeeping-slice'

interface Props {
  resortId: number
  filters: FiltersHousekeepingApplicationFiltersParams
}

export const HousekeepingApplicationsWebSocketHandler: React.FC<Props> = ({ filters, resortId }) => {
  const dispatch = useAppDispatch()

  useWebSocket<FaultOrderDetails | IssueOrderDetails>(
    (message, payload) => {
      switch (message) {
        case NotificationEvents.FAULT_ORDER_CHANGE:
          dispatch(getApplicationsAggregation([filters, resortId]))
          if (payload.resort_id === resortId) {
            dispatch(updateHousekeepingFaultOrder(payload as FaultOrderDetails))
          }
          break

        case NotificationEvents.ISSUE_ORDER_CHANGE:
          dispatch(getApplicationsAggregation([filters, resortId]))
          if (payload.resort_id === resortId) {
            dispatch(updateHousekeepingIssueOrder(payload as IssueOrderDetails))
          }
          break
      }
    },
    [resortId],
    [NotificationEvents.ISSUE_ORDER_CHANGE, NotificationEvents.FAULT_ORDER_CHANGE],
  )
  return null
}
