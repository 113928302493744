import { BookingImprovementCode, BookingStatus } from '@models/booking'
import { BaseUrls } from '@models/base'

export type ApartmentStatus = 'dirty' | 'cleaning' | 'waiting' | 'clean' | 'to_complete'

export type ApartmentBookingFilter =
  | BookingStatus
  | 'unavailable'
  | 'checkedin'
  | 'animals'
  | 'extended'
  | 'employee'
  | 'hide_unavailable'
  | 'only_joined'
  | 'show_excluded_from_sale'
  | 'show_temporary_excluded_from_sale'
  | 'with_garden'
  | 'without_garden'
  | 'special_local'

export const RentSessionTags = ['session_01_09_2024', 'session_01_04_2025'] as const

export type RentSessionTag = (typeof RentSessionTags)[number]

export type ApartmentTagKeyword = 'for_subscriber' | 'for_client_with_benefit' | RentSessionTag

export interface ApartmentTag {
  keyword: ApartmentTagKeyword
  expire_after: string | null
}

export interface Apartment {
  accommodation_type: number
  available: boolean
  available_for_sale: boolean
  has_garden_fixed: boolean
  has_garden: boolean
  housekeeping_last_cleaning: string
  housekeeping_status: ApartmentStatus
  housekeeping_status_display: string
  temporary_not_available: boolean
  id: number
  is_virtual: boolean
  name: string
  no_animals: boolean
  position: number
  urls: BaseUrls
  tags: ApartmentTag[]
  with_garden_from: string | null
}

interface ApartmentDetailsUrls extends BaseUrls {
  housekeeping_status: string
}

export interface ApartmentDetails extends Apartment {
  id: number
  urls: ApartmentDetailsUrls
}

export interface ApartmentFetchDate {
  date: string
  hour: string
}

export interface ApartmentItem {
  id: number
  name: string
  accommodation_type: number
  price_brutto: string
}

export interface ApartmentDamage {
  id: number
  name: string
  accommodation_type: number
  section: string
}

export interface ApartmentBookingImprovement {
  name: string
  code: BookingImprovementCode
}

export interface ApartmentBookingPromocode {
  value: number
  residence_brutto_minus: number
  residence_brutto: number
  code: string
  text: string
}

export interface ApartmentBookingDayStats {
  adults: number
  available_apartments: number
  breakfasts: number
  children: number
  babies: number
  day_in: number
  day_in_out: number
  day_out: number
  dinners: number
}

export interface ApartmentBookingDayInfo {
  date: string
  stats: ApartmentBookingDayStats
}
