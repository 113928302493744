import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import { useForm } from 'react-hook-form'
import { SaveButton } from '@hyper/button'
import { FormInput } from '@hyper/forms'
import { CustomReactSelectOption, makeDefaultSelectOption } from '@components/custom-react-select'
import { CouponTypeConfiguration } from '@modules/coupons/models'
import { FormSelect } from '@hyper/forms/form-select'
import { FormInputTime } from '@hyper/forms/form-input-time'
import { useResortsAndAccommodationTypesOptions } from '@components/hooks/use-resorts-and-accommodation-types-options'
import { useHandleRtkQueryError } from '@components/hooks/use-handle-rtk-error'
import { useCreateOrUpdateCouponTypeConfigurationMutation } from '@api/coupons'
import { Form } from '@hyper/forms/form'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { PointOfSaleTypes } from '@modules/coupons/consts'

export interface CouponsTypeConfigurationFormInputs {
  name: string
  resort: CustomReactSelectOption | null
  point_of_sale: CustomReactSelectOption | null
  gopos_company_id: string
  gopos_organization_id: string
  gopos_product_id: string
  gopos_points_of_sale: string
  usage_hour_from: string
  usage_hour_to: string
  purchase_hour_from: string
  purchase_hour_to: string
}

interface Props extends BaseModalProps {
  couponTypeConfiguration?: CouponTypeConfiguration
}

export const CouponTypeConfigurationCreateModal: React.FC<Props> = ({ toggleIsVisible, couponTypeConfiguration }) => {
  const { getResortOptions } = useResortsAndAccommodationTypesOptions()
  const { addSuccessNotification } = useNotificationHook()

  const resortOptions = getResortOptions().filter(option => option.value)

  const methods = useForm<CouponsTypeConfigurationFormInputs>({
    mode: 'onChange',
    defaultValues: {
      name: couponTypeConfiguration?.name ?? '',
      gopos_company_id: couponTypeConfiguration?.gopos_company_id ?? '',
      gopos_organization_id: couponTypeConfiguration?.gopos_organization_id ?? '',
      gopos_product_id: couponTypeConfiguration?.gopos_product_id ?? '',
      gopos_points_of_sale: couponTypeConfiguration?.gopos_points_of_sale
        ? couponTypeConfiguration?.gopos_points_of_sale.join(', ')
        : '',
      resort: makeDefaultSelectOption(resortOptions, couponTypeConfiguration?.resort),
      point_of_sale: makeDefaultSelectOption(PointOfSaleTypes, couponTypeConfiguration?.point_of_sale),
      usage_hour_from: couponTypeConfiguration?.usage_hour_from ?? '00:00',
      usage_hour_to: couponTypeConfiguration?.usage_hour_to ?? '23:59',
      purchase_hour_from: couponTypeConfiguration?.purchase_hour_from ?? '00:00',
      purchase_hour_to: couponTypeConfiguration?.purchase_hour_to ?? '23:59',
    },
  })

  const [createOrUpdateCouponType, { error, isError, isLoading }] = useCreateOrUpdateCouponTypeConfigurationMutation()
  useHandleRtkQueryError(error, isError, methods.setError)

  const onSubmit = async (payload: CouponsTypeConfigurationFormInputs) => {
    await createOrUpdateCouponType({
      url: couponTypeConfiguration?.urls.details,
      data: { ...payload, gopos_points_of_sale: payload.gopos_points_of_sale.split(',').filter(Boolean) },
    }).unwrap()
    addSuccessNotification('Zmiany zostały zapisane')
    toggleIsVisible()
  }

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>
        {couponTypeConfiguration ? 'Edytuj konfigurację typu' : 'Dodaj konfigurację typu'}
      </ModalHeader>
      <ModalBody className="pb-0">
        <Row>
          <FormInput name="name" label="Nazwa:" />
          <FormSelect options={resortOptions} name="resort" label="Lokalizacja:" />
          <FormInput name="gopos_company_id" label="Identyfikator firmy w GoPos:" />
          <FormInput name="gopos_organization_id" label="Identyfikator organizacji w GoPos:" />
          <FormInput name="gopos_product_id" label="Identyfikator produktu GoPos" />

          <FormInput
            name="gopos_points_of_sale"
            label="Punkty sprzedaży GoPos:"
            placeholder="Przykładowo: 1,2,3 lub 1"
          />

          <FormSelect options={PointOfSaleTypes} name="point_of_sale" label="Typ punktu sprzedaży" />
          <FormInputTime name="purchase_hour_from" label="Zakup od godziny:" colSize={6} />
          <FormInputTime name="purchase_hour_to" label="Zakup do godziny:" colSize={6} />
          <FormInputTime name="usage_hour_from" label="Wykorzystanie od godziny:" colSize={6} />
          <FormInputTime name="usage_hour_to" label="Wykorzystanie do godziny:" colSize={6} />
        </Row>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <div className="ml-auto">
          <Button className="btn btn-light mr-2" type="button" onClick={toggleIsVisible}>
            Anuluj
          </Button>
          <SaveButton label="Zapisz" className="btn-green btn" isSaving={isLoading} />
        </div>
      </ModalFooter>
    </Form>
  )
}
