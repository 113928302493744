import * as React from 'react'
import { useDocumentTitle } from '@helpers/utils'
import { PageTitle } from '@hyper/page-title'
import { Card, CardBody } from 'reactstrap'
import TopBarProgress from 'react-topbar-progress-indicator'
import { MediaReportsFilters } from '@modules/reports/media/media-reports-filters'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { endOfMonth, startOfMonth, startOfToday } from 'date-fns'
import { MediaReportItem } from '@models/reports'
import { MediaReportsTable } from '@modules/reports/media/table'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { useAppData } from '@components/hooks/use-app-data'
import { commonObjectGet } from '@store/actions/generic-actions'

export interface MediaReportsFiltersParams {
  resort: string
  date_after: Date | undefined
  date_before: Date | undefined
  ordering?: string | undefined
}

export const MediaReports: React.FC = () => {
  const defaultFilters: MediaReportsFiltersParams = {
    resort: String(useAuthenticatedUser().resorts[0].id),
    date_after: startOfMonth(startOfToday()),
    date_before: endOfMonth(startOfToday()),
    ordering: '-date',
  }
  const [filters, setFilters] = React.useState<MediaReportsFiltersParams>(defaultFilters)
  const [mediaReportItems, setMediaReportItems] = React.useState<MediaReportItem[] | undefined>(undefined)

  const appData = useAppData()

  const { isLoading, action: fetchMediaReportItems } = useApiRequest(async () =>
    setMediaReportItems(await commonObjectGet<MediaReportItem[]>(appData.urls.reports.media_report_items, filters)),
  )
  useMenuActive(NavigationPath.MediaReport)

  React.useEffect(() => {
    fetchMediaReportItems()
  }, [filters])

  const pageTitle = 'Raport mediów'
  useDocumentTitle(pageTitle)

  return (
    <>
      <PageTitle breadCrumbItems={[{ label: pageTitle, path: '', active: true }]} title={pageTitle} />
      <Card>
        <CardBody>
          {isLoading && TopBarProgress && <TopBarProgress />}
          <MediaReportsFilters filters={filters} setFilters={setFilters} defaultFilters={defaultFilters} />

          <LoaderPlaceholder content={mediaReportItems}>
            <MediaReportsTable mediaReportItems={mediaReportItems || []} />
          </LoaderPlaceholder>
        </CardBody>
      </Card>
    </>
  )
}
