import * as React from 'react'
import { Row } from 'reactstrap'
import { PackageSummarySection, PackageSummarySectionDataRow } from '@modules/package/create/package-summary-section'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { useFormContext } from 'react-hook-form'
import { GastroCardDialogFormInputs } from '@modules/promotions/gastro-cards/gastro-card-dialog/gastro-card-dialog'
import { commonObjectGet } from '@store/actions/generic-actions'
import { ClientDetails, ClientUser } from '@models/clients'
import { asDecimal, formatPrice } from '@helpers/utils'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { useReportsAppData } from '@modules/reports/hooks/use-reports-app-data'
import { Source } from '@models/reports'
import { ProductSaleSummaryInvoice } from '@components/sale/product-sale-summary-invoice'
import { startOfToday } from 'date-fns'

interface Props {
  client: ClientUser | undefined
}

export const GastroCardDialogSummaryStep = ({ client }: Props): JSX.Element => {
  const [clientDetails, setClientDetails] = React.useState<ClientDetails | null>(null)

  const { sources } = useReportsAppData()

  const methods = useFormContext<GastroCardDialogFormInputs>()
  const data = methods.getValues()

  const fetchClientDetails = async clientUrl => setClientDetails(await commonObjectGet<ClientDetails>(clientUrl))

  React.useEffect(() => {
    if (client) {
      fetchClientDetails(client.urls.details)
    }
  }, [client])

  const sourceMarketing = data.source_marketing
    ? sources.find((source: Source) => source.id === data.source_marketing.value)?.name
    : 'Brak'

  const activeAfter =
    data.active_after_kind === 'today'
      ? 'dzisiaj'
      : data.active_after_kind === 'next_year'
        ? startOfToday().getFullYear() + 1
        : data.active_after_kind.replace('year_', '')

  const amountToSpend = asDecimal(data.declared_sell_price).mul(2).toString()
  const resorts = data.resorts.map(row => row.label).join(', ')

  return (
    <div className="pb-2">
      <h4 className="mt-0 text-secondary">Podsumowanie sprzedaży</h4>
      <LoaderPlaceholder content={clientDetails}>
        <Row>
          <PackageSummarySection title="Dane klienta" className={data.invoice_nip ? 'col-6' : 'col-8'}>
            <PackageSummarySectionDataRow title="Imię i nazwisko:" content={clientDetails?.name} />
            <PackageSummarySectionDataRow title="Adres e-mail:" content={clientDetails?.email} />
            <PackageSummarySectionDataRow title="Numer telefonu:" content={clientDetails?.phone} />
            <PackageSummarySectionDataRow
              title="Posiadane vouchery:"
              content={clientDetails?.gastro_cards.length ?? 0}
            />
            <PackageSummarySectionDataRow title="Aktywny w ośrodkach" content={resorts} />
            <PackageSummarySectionDataRow title="Data utworzenia:" content={toDefaultDateFormat(data.sell_date)} />
            <PackageSummarySectionDataRow title="Dostępny od:" content={activeAfter} />
            <PackageSummarySectionDataRow
              title="Kwota zakupu:"
              content={
                <>
                  {formatPrice(asDecimal(data.declared_sell_price).toString())}{' '}
                  <span className="font-weight-normal font-12">(do wykorzystania {formatPrice(amountToSpend)})</span>
                </>
              }
            />
          </PackageSummarySection>
          {data.invoice_nip && <ProductSaleSummaryInvoice />}
        </Row>
        <div className="dropdown-divider" />
        <Row>
          <PackageSummarySection title="Informacje handlowe" className="col-12">
            <Row>
              <PackageSummarySectionDataRow title="Sprzedawca:" content={data.seller?.label} className="col-6" />
              <PackageSummarySectionDataRow title="Źródło:" content={sourceMarketing} className="col-6" />
            </Row>
          </PackageSummarySection>
        </Row>
        <div className="dropdown-divider" />
        <Row>
          <PackageSummarySection title="Link do płatności" className="col-12">
            <p className="opacity-75">
              Link do opłacenia sprzedaży zostanie wysłany na adres e-mail Gościa{' '}
              <strong className="text-dark">{clientDetails?.email}</strong>
            </p>
          </PackageSummarySection>
        </Row>
      </LoaderPlaceholder>
    </div>
  )
}
