import * as React from 'react'
import { BookingRefund } from '@modules/accounting/refund/models'
import { useModal } from '@components/modals/use-modal'

interface Props {
  booking: BookingRefund
}

export const RefundBookingsTableCellActionsReturnP24: React.FC<Props> = ({ booking }) => {
  const [handleClick] = useModal('Przelewy24SendDialog', { booking })

  if (booking.deposit_transfer_status === 'p24_initial') {
    return (
      <span
        className="uil uil-transaction text-muted font-16 cursor-pointer"
        title="Zleć zwrot p24"
        onClick={handleClick}
      />
    )
  } else if (booking.deposit_transfer_status === 'p24_pending') {
    return <span className="text-info">Zlecony</span>
  } else if (booking.deposit_transfer_status === 'p24_completed') {
    return <span className="text-success">Zwrócony</span>
  } else if (booking.deposit_transfer_status === 'p24_error') {
    return <span className="text-danger">Błąd</span>
  }
  return null
}
