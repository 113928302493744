import * as React from 'react'
import { RodoAgreement } from '@modules/crm/rodo-agreements/models'
import { sortObjectListByDate } from '@helpers/utils'
import { RodoAgreementsListRow } from '@modules/crm/rodo-agreements/benefits/list/rodo-agreements-list-row'
import { RodoAgreementsConfirmedIssueRewardCell } from '@modules/crm/rodo-agreements/benefits/list/confirmed/rodo-agreements-confirmed-issue-reward-cell'

interface Props {
  rodoAgreements: RodoAgreement[]
}

export const RodoAgreementsConfirmedList = ({ rodoAgreements }: Props): JSX.Element => {
  const sortedConfirmedClients = React.useMemo(() => {
    const { completed, notCompleted } = rodoAgreements.reduce(
      (previous, client) =>
        client.benefit_transfer_date
          ? { ...previous, completed: [...previous.completed, client] }
          : { ...previous, notCompleted: [...previous.notCompleted, client] },
      { completed: [], notCompleted: [] },
    )

    return [...notCompleted, ...sortObjectListByDate(completed, 'benefit_received_date')]
  }, [rodoAgreements])

  return (
    <div>
      <h4 className="text-green-dark fw-semi-bold">Dane potwierdzone:</h4>
      <table className="table table-sm w-100 table-borderless client-rodo__confirmed__table">
        <tbody>
          {sortedConfirmedClients.map(rodoAgreement => (
            <RodoAgreementsListRow
              key={rodoAgreement.id}
              rodoAgreement={rodoAgreement}
              nameClass="text-green-dark"
              rowClassName="client-rodo__confirmed__table__row"
            >
              <RodoAgreementsConfirmedIssueRewardCell rodoAgreement={rodoAgreement} />
            </RodoAgreementsListRow>
          ))}
        </tbody>
      </table>
    </div>
  )
}
