import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import { ReceptionBookingLoadNote, ReceptionBookingLoadNoteItem } from '@models/reception'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectGet } from '@store/actions/generic-actions'
import { ContentLoader } from '@components/content-loader'
import { asDecimal, formatPrice, sumDecimalArray } from '@helpers/utils'

interface Props extends BaseModalProps {
  note: ReceptionBookingLoadNote
}

export const ReservationLoadNotePreviewModal: React.FC<Props> = ({ toggleIsVisible, note }) => {
  const [noteItems, setNoteItems] = React.useState<ReceptionBookingLoadNoteItem[]>([])

  const { action: fetchItems, isLoading } = useApiRequest(async () => {
    setNoteItems((await commonObjectGet<{ items: ReceptionBookingLoadNoteItem[] }>(note.urls.details)).items)
  })

  React.useEffect(() => {
    fetchItems()
  }, [])

  const total = sumDecimalArray(noteItems.map(el => asDecimal(el.total_price_brutto))).toString()

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>Podgląd noty</ModalHeader>
      <ModalBody>
        <ContentLoader isLoading={isLoading}>
          <table className="table table-striped">
            <thead>
              <tr>
                <td className="border-top-0 fw-semi-bold">Nazwa</td>
                <td className="border-top-0 fw-semi-bold">Sztuk</td>
                <td className="border-top-0 fw-semi-bold">Kwota za sztukę</td>
                <td className="border-top-0 fw-semi-bold">Razem</td>
              </tr>
            </thead>
            <tbody>
              {noteItems.map(noteItem => (
                <ItemRow key={noteItem.id} noteItem={noteItem} />
              ))}
            </tbody>
            <tfoot>
              <tr className="font-14 fw-semi-bold">
                <td colSpan={2} />
                <td className="border-left border-light border-bottom bg-grey">Razem</td>
                <td className="border-bottom border-light bg-grey">{formatPrice(total)}</td>
              </tr>
            </tfoot>
          </table>
        </ContentLoader>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={toggleIsVisible}>
          Zamknij
        </Button>
      </ModalFooter>
    </>
  )
}

const ItemRow = ({ noteItem }: { noteItem: ReceptionBookingLoadNoteItem }) => (
  <tr>
    <td>{noteItem.name}</td>
    <td>{noteItem.amount}</td>
    <td>{formatPrice(noteItem.price_brutto)}</td>
    <td>{formatPrice(noteItem.total_price_brutto)}</td>
  </tr>
)
