import * as React from 'react'
import { IconWithTooltip } from '@components/icon-with-tooltip'
import { asDecimal, formatPrice } from '@helpers/utils'
import classNames from 'classnames'
import { PaymentRegister } from '@models/payments'

interface Props {
  paymentRegister: PaymentRegister
  inTicketsAmountTooltipContent: React.ReactNode
}

export const PaymentCashBoxAmount = ({ paymentRegister, inTicketsAmountTooltipContent }: Props): JSX.Element => {
  const isInTicketsAmount = React.useMemo(
    () => asDecimal(paymentRegister.amount_in_tickets).greaterThan(asDecimal(0)),
    [paymentRegister.amount_in_tickets],
  )
  const tooltipMessage = <span className="font-11">Metoda płatności: {paymentRegister.source_display}</span>

  const paymentColor = paymentRegister.type === 'KP' ? 'text-success' : 'text-danger'

  if (isInTicketsAmount) {
    return (
      <IconWithTooltip
        color="default"
        icon="uil-book"
        tooltipMessage={tooltipMessage}
        tooltipId={`payment-tooltip-${paymentRegister.id}`}
        tooltipPlacement="top"
      >
        {inTicketsAmountTooltipContent}
      </IconWithTooltip>
    )
  }

  return (
    <IconWithTooltip
      color={paymentColor}
      icon={paymentRegister.source === 'cash' ? 'uil-money-bill' : 'uil-credit-card'}
      tooltipMessage={tooltipMessage}
      tooltipId={`payment-tooltip-${paymentRegister.id}`}
      tooltipPlacement="top"
    >
      <span className={classNames('text-semi-strong', paymentColor)}>{formatPrice(paymentRegister.amount)}</span>
    </IconWithTooltip>
  )
}
