import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { PaginationResponse } from '@models/dashboard'
import {
  CashbackVoucher,
  CashbackVoucherDetails,
  CashbackVouchersAppData,
} from '@modules/products/cashback-vouchers/models'
import { CashbackVouchersTableFiltersParams } from '@modules/products/cashback-vouchers/filters'
import { apiQueryFunction } from '@api/base'
import { RootState } from '@store/index'
import { extractSelectOptionsValues } from '@helpers/utils'

export const CASHBACK_VOUCHER_TAG = 'cashbackVoucher'
type UpdateCashbackVoucherParams = { data: any; url?: string }

export const cashbackApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: '' }),
  reducerPath: 'cashbackApi',
  tagTypes: [CASHBACK_VOUCHER_TAG],
  endpoints: builder => ({
    getCashbackVoucher: builder.query<CashbackVoucher[], Partial<CashbackVouchersTableFiltersParams>>({
      queryFn: apiQueryFunction((state: RootState, params: Partial<CashbackVouchersTableFiltersParams>) => ({
        method: 'GET',
        url: state.appState.appData.urls.cashback.cashback_vouchers,
        params: { ...params, status: params.status?.map(({ value }) => value) },
        responseTransformer: (response: PaginationResponse<CashbackVoucher>) => response.results,
      })),
      providesTags: (result: CashbackVoucher[]) =>
        result
          ? [...result.map(({ id }) => ({ type: CASHBACK_VOUCHER_TAG, id }) as const), CASHBACK_VOUCHER_TAG]
          : [CASHBACK_VOUCHER_TAG],
    }),
    getCashbackVoucherDetails: builder.query<CashbackVoucherDetails, string>({
      queryFn: apiQueryFunction((state: RootState, id: string) => ({
        method: 'GET',
        url: `${state.appState.appData.urls.cashback.cashback_vouchers}${id}/`,
      })),
    }),

    getCashbackVouchersAppData: builder.query<CashbackVouchersAppData, void>({
      queryFn: apiQueryFunction((state: RootState) => ({
        method: 'GET',
        url: state.appState.appData.urls.cashback.app_data,
      })),
    }),

    updateCashbackVoucher: builder.mutation<CashbackVoucherDetails, UpdateCashbackVoucherParams>({
      invalidatesTags: [CASHBACK_VOUCHER_TAG],

      queryFn: apiQueryFunction((state: RootState, { url, data }: UpdateCashbackVoucherParams) => ({
        data: extractSelectOptionsValues(data),
        method: 'POST',
        url,
      })),
    }),
  }),
})

export const {
  useGetCashbackVoucherQuery,
  useGetCashbackVoucherDetailsQuery,
  useUpdateCashbackVoucherMutation,
  useGetCashbackVouchersAppDataQuery,
} = cashbackApi
