import * as React from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '@store/index'
import { commonObjectPost } from '@store/actions/generic-actions'
import { CleaningOrderDetails } from '@modules/housekeeping/models'
import { Button, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import { SaveButton } from '@hyper/button'
import {
  CreateCleaningOrderModalForm,
  HousekeepingOrderServiceKindOptions,
} from '@modules/housekeeping/cleaning-orders/create-cleaning-order/form'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApartments } from '@components/hooks/use-apartments'
import { useFormRequest } from '@components/hooks/use-api-request'
import { BaseModalProps } from '@components/modals/types'
import { updateHousekeepingCleaningOrderDetails } from '@store/slices/housekeeping-slice'
import { CustomReactSelectOption, makeDefaultSelectOption } from '@components/custom-react-select'
import { apartmentsSelectSelector } from '@store/selectors/timeline'
import { useResortSelectOptions } from '@components/hooks/use-resort-select-options'
import { extractSelectOptionsValues } from '@helpers/utils'
import { useAppData } from '@components/hooks/use-app-data'
import { Form } from '@hyper/forms/form'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'

interface Props extends BaseModalProps {
  onComplete?: () => void
  resortId: number
  apartmentId?: number
  isReadonly?: boolean
}

export interface CreateCleaningOrderModalFormInputs {
  apartment: CustomReactSelectOption | null
  users: string[]
  service_kind: CustomReactSelectOption | null
  comment: string
  company: CustomReactSelectOption | null
  resort: CustomReactSelectOption | null
  with_worker_assign: boolean
}

export const CreateCleaningOrderModal: React.FC<Props> = ({
  resortId,
  onComplete,
  toggleIsVisible,
  apartmentId,
  isReadonly = false,
}) => {
  const dispatch = useAppDispatch()
  const { urls } = useAppData()

  const { addSuccessNotification } = useNotificationHook()
  const apartments = useAppSelector(apartmentsSelectSelector)
  const resortOptions = useResortSelectOptions()

  const methods = useForm<CreateCleaningOrderModalFormInputs>({
    defaultValues: {
      apartment: makeDefaultSelectOption(apartments, apartmentId?.toString()),
      company: null,
      users: [],
      service_kind: HousekeepingOrderServiceKindOptions[0],
      comment: '',
      resort: makeDefaultSelectOption(resortOptions, resortId),
      with_worker_assign: false,
    },
  })

  const selectedResort = useWatch({ control: methods.control, name: 'resort.value' })
  useApartments(selectedResort)

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: CreateCleaningOrderModalFormInputs) => {
    dispatch(
      updateHousekeepingCleaningOrderDetails(
        await commonObjectPost<CleaningOrderDetails>(urls.housekeeping.cleaning_order_create, {
          ...extractSelectOptionsValues(payload),
          users: payload.users?.filter(x => x) || [],
          apartment: apartmentId ? apartmentId : payload.apartment?.value,
        }),
      ),
    )
    addSuccessNotification('Zlecenia zostały stworzone')
    toggleIsVisible()
    onComplete?.()
  }, methods.setError)

  useDidUpdateEffect(() => {
    methods.setValue('apartment', null)
  }, [selectedResort])

  React.useEffect(() => {
    if (!apartmentId) return
    methods.setValue('apartment', makeDefaultSelectOption(apartments, apartmentId?.toString()))
  }, [apartments, apartmentId])

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible} className="text-semi-strong">
        Zleć dodatkowe sprzątanie
      </ModalHeader>
      <ModalBody>
        <Row>
          <CreateCleaningOrderModalForm
            isReadonly={isReadonly}
            apartments={apartments}
            apartmentId={apartmentId}
            resortOptions={resortOptions}
          />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton className="btn btn-green" label="Zleć" labelSaving="Zlecanie.." isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
