import * as React from 'react'
import Table from '@components/table/table'
import { User } from '@models/account'
import { CleaningOrderBooking } from '@modules/housekeeping/models'

interface Props {
  user: User | null
  booking?: CleaningOrderBooking | null
  width?: number
  isCreatedByClient?: boolean
}

export const TechnicalOrdersTodoRowUserBy: React.FC<Props> = ({ isCreatedByClient, user, booking, width = 200 }) => (
  <Table.Cell className="vertical-align-middle" style={{ width }}>
    {getTechnicalOrdersTodoRowUserBy({ isCreatedByClient, user, booking })}
  </Table.Cell>
)

export const getTechnicalOrdersTodoRowUserBy = ({ isCreatedByClient = false, user, booking }: Props) => (
  <>
    {user && (
      <>
        {isCreatedByClient && 'Gość '}
        {user.first_name} {user.last_name}
        <span className="font-10 d-block">
          {booking ? (
            <a href={booking.urls.details} className="text-primary text-strong">
              ({booking.reservation_number})
            </a>
          ) : (
            user.is_receptionist && 'Recepcja'
          )}
        </span>
      </>
    )}
    {isCreatedByClient && (
      <strong className="text-danger font-10 d-block" title="Klient zgłosił w aplikacji">
        Zgłoszenie klienta
      </strong>
    )}
  </>
)
