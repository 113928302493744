import {
  PromotionsActionTypesKeys,
  SetErliPromocodesAction,
  SetPromotionsAppDataAction,
} from '@store/actions-types/promotions-actions-types'
import { ThunkAction } from 'redux-thunk'
import { AsyncThunkParams, RootState } from '@store/index'
import { setDashboardStandardPaginator } from '@store/actions/dashboard-actions'
import { SetDashboardStandardPaginatorAction } from '@store/actions-types/dashboard-actions-types'
import {
  PackageVip,
  PackageVipAggregation,
  PackageVipDetails,
  PackageVipFiltersParams,
} from '@modules/promotions/package-vip/models'
import { ErliPromocodeFiltersParams } from '@modules/promotions/erli-promocode'
import { ErliPromocode } from '@modules/promotions/erli-promocode/models'
import { commonObjectGet } from '@store/actions/generic-actions'
import { PromotionsAppData } from '@store/reducers/promotions-reducers'
import { ListDataWithAggregation, PaginationResponse, PaginationResponseWithAggregation } from '@models/dashboard'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { extractSelectOptionsValues } from '@helpers/utils'
import { PackageVipStatusesSelectOptions } from '@modules/promotions/package-vip/utils'

export function getPromotionsAppData(): ThunkAction<Promise<void>, RootState, null, SetPromotionsAppDataAction> {
  return async (dispatch, getState) => {
    const appData = getState().promotionsState.appData
    if (appData.status === 'unknown') {
      await dispatch(setPromotionsAppData({ ...appData, status: 'loading' }))

      const data = await commonObjectGet<PromotionsAppData>(getState().appState.appData.urls.promotions.app_data)
      dispatch(
        setPromotionsAppData({
          ...data,
          status: 'ready',
        }),
      )
    }
  }
}

export function setPromotionsAppData(payload: PromotionsAppData): SetPromotionsAppDataAction {
  return {
    payload,
    type: PromotionsActionTypesKeys.SET_PROMOTIONS_APP_DATA,
  }
}

export const getPackagesVip = createAsyncThunk<
  ListDataWithAggregation<PackageVip[], PackageVipAggregation>,
  PackageVipFiltersParams,
  AsyncThunkParams
>('package-vips/getPackagesVip', async (params, { getState, dispatch }) => {
  const url = getState().appState.appData.urls.promotions.package_vip

  const payload = { ...params, status: params.status?.length ? params.status : PackageVipStatusesSelectOptions }

  const data = await commonObjectGet<PaginationResponseWithAggregation<PackageVip, PackageVipAggregation>>(
    url,
    extractSelectOptionsValues(payload),
  )

  dispatch(setDashboardStandardPaginator(data))

  return { results: data.results, aggregation: data.aggregation }
})

export const getPackageVipDetails = createAsyncThunk<PackageVipDetails, string>(
  'package-vips/getPackageVipDetails',
  async url => await commonObjectGet<PackageVipDetails>(url),
)

export function getErliPromocodes(
  params?: ErliPromocodeFiltersParams,
): ThunkAction<Promise<void>, RootState, null, SetErliPromocodesAction | SetDashboardStandardPaginatorAction> {
  return async (dispatch, getState) => {
    const url = getState().appState.appData.urls.erli.promocode_list

    const data = await commonObjectGet<PaginationResponse<ErliPromocode>>(url, params)

    dispatch(setErliPromocodes(data.results))
    dispatch(setDashboardStandardPaginator({ ...data }))
  }
}

export function setErliPromocodes(payload: ErliPromocode[] | undefined): SetErliPromocodesAction {
  return { type: PromotionsActionTypesKeys.SET_ERLI_PROMOCODES, payload }
}

export function updateErliPromocodeDetails(
  payload: ErliPromocode,
): ThunkAction<Promise<void>, RootState, null, SetErliPromocodesAction> {
  return async (dispatch, getState) => {
    const promocodes = getState().promotionsState.erliPromocodes || []

    if (!promocodes.some(row => row.id === payload.id)) {
      promocodes.push(payload)
    }

    dispatch(
      setErliPromocodes(
        promocodes.map(row => ({
          ...(row.id === payload.id ? payload : row),
        })),
      ),
    )
  }
}
