import * as React from 'react'
import { SubscriptionDetails, SubscriptionPaymentMoveHistory } from '@modules/subscription/models'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { useFilteredRows } from '@components/table/use-filtered-rows'
import { useSortedRows } from '@components/table/use-sorted-rows'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { SubscriptionDetailsPaymentsTransferRow } from '@modules/subscription/details/payments-transfer-row'

interface Props {
  subscriptionDetails: SubscriptionDetails
}

const defaultFilters: TableFilters = {
  search: '',
  ordering: '-is_used',
  page: 1,
  page_size: 9999,
}

const headerValues: TableHeaderValue<SubscriptionPaymentMoveHistory>[] = [
  { title: 'LP' },
  { title: 'Opis', sortField: 'message', search: true },
  { title: 'Stara rezerwacja', sortField: 'old_reservation', search: true },
  { title: 'Nowa rezerwacja', sortField: 'new_reservation', search: true },
  { title: 'Kwota', sortField: 'params.amount', search: true },
  { title: 'Forma', sortField: 'params.source', search: true },
]

export const SubscriptionDetailsPaymentsTransfer = React.forwardRef<HTMLElement, Props>(
  ({ subscriptionDetails }, ref) => {
    const [filters, setFilters] = React.useState<TableFilters>(defaultFilters)

    const setValue = (_, search) => setFilters({ ...filters, search })

    const filteredRows = useFilteredRows<SubscriptionPaymentMoveHistory>(
      subscriptionDetails.payments_move_history,
      headerValues,
      String(filters.search),
    )
    const sortedRows = useSortedRows<SubscriptionPaymentMoveHistory>(filteredRows, filters.ordering)

    return (
      <Card innerRef={ref}>
        <CardBody>
          <Row className="align-items-center">
            <Col className="col-auto col-md-8">
              <span className="h4 text-secondary">Historia przeniesień płatności</span>
            </Col>
            <SearchFilter hideLabel={true} setValue={setValue} colClassName="col-md-2" />
          </Row>
          <Table
            className="table-hover w-100"
            headerValues={headerValues}
            isLoading={false}
            showPagination={false}
            filters={filters}
            setFilters={setFilters}
          >
            {sortedRows.map((history, index) => (
              <SubscriptionDetailsPaymentsTransferRow index={index + 1} history={history} key={history.id} />
            ))}
          </Table>
        </CardBody>
      </Card>
    )
  },
)
