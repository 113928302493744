import * as React from 'react'
import { FormSelect } from '@hyper/forms/form-select'
import { FormCheckbox } from '@hyper/forms/form-checkbox'
import { useFormContext, useWatch } from 'react-hook-form'
import { FormPlainError } from '@hyper/forms'
import { getById } from '@helpers/utils'
import { useHousekeepingTechnicalCompanies } from '@modules/technical-orders/hooks/use-housekeeping-technical-companies'
import { TechnicalOrderAssignFormForwardToResort } from '@modules/technical-orders/technical-order-assign-modal/form-forward-to-resort'

interface Props {
  resortId: number
  initialCompany?: number
}

export const TechnicalOrderAssignFormTechnicalManager: React.FC<Props> = ({ resortId, initialCompany }) => {
  const { control, setValue } = useFormContext()
  const { companiesOptions, companies } = useHousekeepingTechnicalCompanies(resortId)

  const company = useWatch({ control, name: 'company' })
  const forwardedToResort = useWatch({ control, name: 'forwarded_to_resort' })

  React.useEffect(() => {
    if (company && company.value != initialCompany) {
      setValue('users', {})
    }
  }, [company, initialCompany])

  React.useEffect(() => {
    if (initialCompany) {
      setValue(
        'company',
        companiesOptions.find(company => company.value === initialCompany),
      )
    }
  }, [initialCompany, companiesOptions])

  const workers = React.useMemo(
    () => (getById(companies, company?.value)?.workers ?? []).filter(row => row.is_technical_order_worker),
    [companies, company?.value],
  )

  return (
    <>
      <FormSelect
        label="Wybierz firmę"
        name="company"
        options={companiesOptions}
        selectProps={{ isDisabled: !!forwardedToResort }}
        formPlainProps={{ colClassName: 'px-0' }}
      />
      {!!company && (
        <>
          <div className="font-weight-bold mt-3 mb-1">Wybierz pracowników</div>
          {workers.map(worker => (
            <span key={worker.id} title={worker.email}>
              <FormCheckbox name={`users.user__${worker.id}`} label={worker.name} className="mb-1" />
            </span>
          ))}

          {!workers.length && 'Brak pracowników do wyboru'}
        </>
      )}
      <FormPlainError name="users" />
      <TechnicalOrderAssignFormForwardToResort resortId={resortId} />
    </>
  )
}
