import * as React from 'react'
import { ClientDetails } from '@models/clients'
import { RootState, useAppSelector } from '@store/index'
import { formatIban } from '@helpers/text'
import { ClientDetailsModalBaseDataRow } from '@modules/crm/clients/details-modal/base-data/data-row'

interface Props {
  client: ClientDetails
}

export const ClientDetailsModalBaseDataTable: React.FC<Props> = ({ client }) => {
  const sources = useAppSelector((state: RootState) => state.reportsState.appData?.sources) || []

  const sourceMarketing = React.useMemo(
    () => sources.find(row => row.id === client.profile.source_marketing_id)?.name || '',
    [sources, client.profile.source_marketing_id],
  )

  const formattedAccountNumber = React.useMemo(
    () => formatIban(client.profile.account_number),
    [client.profile.account_number],
  )

  return (
    <>
      <ClientDetailsModalBaseDataRow name="Rodzaj Gościa:">
        {client.profile.client_kind_display}
      </ClientDetailsModalBaseDataRow>
      <ClientDetailsModalBaseDataRow name="Tagi:">
        {client.profile.tags.map(tag => (
          <span className="badge badge-light mr-1" key={tag}>
            {tag}
          </span>
        ))}
        {!client.profile.tags.length && 'brak'}
      </ClientDetailsModalBaseDataRow>
      <ClientDetailsModalBaseDataRow name="Źródło marketingowe:">
        {sourceMarketing || 'brak'}
      </ClientDetailsModalBaseDataRow>
      <div className="hr hr-grey my-1" />
      <ClientDetailsModalBaseDataRow name="Numer konta do zwrotów:">
        {client.profile.account_number ? formattedAccountNumber : 'brak'}
      </ClientDetailsModalBaseDataRow>
      <div className="hr hr-grey" />
    </>
  )
}
