import * as React from 'react'
import { CustomReactSelect } from '@components/custom-react-select'
import { Col, Row } from 'reactstrap'

export const PackageWholesaleDetailsMassNotificationsFilters = (): JSX.Element => (
  <Row className="px-2">
    <Col md={4}>
      <CustomReactSelect
        fieldName="filters.status"
        options={PackageWholesaleDetailsMassNotificationsStatusOptions}
        label="Status"
        isSearchable={false}
      />
    </Col>
  </Row>
)

export const PackageWholesaleDetailsMassNotificationsStatusOptions = [
  { value: 'all', label: 'Wszystkie' },
  { value: 'initial', label: 'Wstępny' },
  { value: 'confirmed', label: 'Potwierdzony' },
]
