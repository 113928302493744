import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectPost } from '@store/actions/generic-actions'
import { NewFeedingCalculations } from '@modules/reception/checkin/step-feeding/new-feeding/models'
import { useModal } from '@components/modals/use-modal'
import { ReceptionBookingDetails } from '@models/reception'
import { NewFeedingChanges } from '@modules/reception/checkin/step-feeding/new-feeding/utils'
import * as React from 'react'
import { ReceptionBookingNewFeedingOpenCartButton } from '@modules/reception/checkin/step-feeding/new-feeding/reception-booking-new-feeding-open-cart-button'
import { useAppDispatch } from '@store/index'
import { setFeedingCalculations, setInitialFeedingCalculations } from '@store/slices/new-feeding-slice'
import { getBookingWalletDetails } from '@store/actions/reservation-actions'

const ErrorHeaders = {
  API_ERROR_CODE: 'api-error-code',
}

const ErrorCodes = {
  CART_WITH_PENDING_PAYMENT: 'cart_with_pending_payment',
}

export const useCartSave = (
  booking: ReceptionBookingDetails,
  feedingChanges: NewFeedingChanges | null,
  canSaveWithoutCartProcessing: boolean,
  onCartFinalization: () => Promise<void>,
  payByWallet: boolean,
) => {
  const dispatch = useAppDispatch()
  const { addErrorMessage, addSuccessNotification } = useNotificationHook()

  const [showBookingCart] = useModal('ReceptionBookingCartModal')

  const showBookingCartModal = () => {
    showBookingCart(null, { onCartFinalization, bookingDetails: booking })
  }

  const showNotification = () => {
    addErrorMessage(
      'Koszyk otwarty',
      'W koszyku pozostały niezakończone transakcje. Do czasu ich sfinalizowania dalsza edycja wyżywienia nie jest możliwa.',
      (onHide: () => void) => (
        <ReceptionBookingNewFeedingOpenCartButton booking={booking} onShowCart={onHide} className="my-1 px-4" />
      ),
    )
  }

  const { isLoading: isSaving, action: saveCart } = useApiRequest(async () => {
    if (!feedingChanges) return

    try {
      const response = await commonObjectPost<NewFeedingCalculations>(booking.urls.reservation_feeding_sale, {
        ...feedingChanges,
        pay_by_wallet: payByWallet,
      })

      dispatch(setFeedingCalculations(response))
      dispatch(setInitialFeedingCalculations(response))
      dispatch(getBookingWalletDetails(booking))

      if (canSaveWithoutCartProcessing) {
        addSuccessNotification('Zmiany zostały zapisane.')
      } else {
        showBookingCartModal()
      }
    } catch (error) {
      if (!isAnotherCartOpenError(error)) throw error

      showNotification()
    }
  })

  return { saveCart, isSaving }
}

const isAnotherCartOpenError = error =>
  error.response.status === 400 &&
  error.response.headers[ErrorHeaders.API_ERROR_CODE] === ErrorCodes.CART_WITH_PENDING_PAYMENT
