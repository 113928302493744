import * as React from 'react'
import { Col, Row } from 'reactstrap'
import { ClientSelect } from '@components/client-select'
import { GastroCardDialogForm } from '@modules/promotions/gastro-cards/gastro-card-dialog/form'
import { useModal } from '@components/modals/use-modal'
import { useFormContext } from 'react-hook-form'
import { GastroCardDialogFormInputs } from '@modules/promotions/gastro-cards/gastro-card-dialog/gastro-card-dialog'
import { ClientUser } from '@models/clients'
import { GastroCard } from '@models/promotions'
import { InvoiceForm } from '@components/invoice-form'
import { CustomReactSelectOption } from '@components/custom-react-select'

interface Props {
  isSellerReadonly: boolean | undefined
  client: ClientUser | undefined
  setClient: (client: ClientUser) => void
  gastroCard: GastroCard | undefined
  resortOptions: CustomReactSelectOption[]
}

export const GastroCardDialogDataStep = ({
  isSellerReadonly,
  client,
  setClient,
  gastroCard,
  resortOptions,
}: Props): JSX.Element => {
  const [handleToggleClientDialog] = useModal('ClientDialog', { setClient })

  const {
    formState: { errors },
  } = useFormContext<GastroCardDialogFormInputs>()

  return (
    <Row>
      <ClientSelect
        setClient={setClient}
        client={client}
        handleToggleClientDialog={handleToggleClientDialog}
        error={errors.client}
        isDisabled={gastroCard?.is_confirmed}
      />
      {!gastroCard && (
        <Col md={12} className="mb-2">
          <InvoiceForm client={client} invoiceData={undefined} />
        </Col>
      )}
      <GastroCardDialogForm
        gastroCard={gastroCard}
        isSellerReadonly={isSellerReadonly || false}
        resortOptions={resortOptions}
      />
    </Row>
  )
}
