import * as React from 'react'
import { pinCodeLogInUser } from '@api/dashboard'
import { useForm } from 'react-hook-form'
import { LoginUIComponent } from '@components/login-ui-component'
import { FormInput } from '@hyper/forms'
import { SaveButton } from '@hyper/button'
import { Col, Row } from 'reactstrap'
import * as queryString from 'querystring'
import { Link } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { useFormRequest } from '@components/hooks/use-api-request'
import { Form } from '@hyper/forms/form'

interface FormInputs {
  pin_code: string
}

export const PinCodeLoginView: React.FC = () => {
  const { next } = queryString.parse(location.search.replace('?', ''))

  const methods = useForm<FormInputs>()

  const { isLoading, action: onSubmit } = useFormRequest(async (data: FormInputs) => {
    try {
      const response = await pinCodeLogInUser(data)
      window.location.href = next && !Array.isArray(next) ? next : response.next_url
    } catch (error) {
      if (error.response?.status === 403) {
        methods.setError('pin_code', {
          type: 'server',
          message: 'Podany kod PIN jest nieprawidłowy',
        })
      } else {
        throw error
      }
    }
  }, methods.setError)

  return (
    <LoginUIComponent>
      <h2 className="unauthorized__header">Logowanie do systemu PMS</h2>
      <p className="unauthorized__text">Zaloguj się używając przypisanych do Ciebie danych logowania.</p>
      <Form methods={methods} onSubmit={onSubmit}>
        <Row>
          <FormInput type="text" name="pin_code" label="Kod pin" placeholder="Wpisz swój kod pin" />
          <Col md={12} className="text-right pb-2">
            <Link to={NavigationPath.AuthorizationLogin} className="text-muted">
              Zaloguj się używająć loginu i hasła
            </Link>
          </Col>
        </Row>
        <SaveButton
          role="login"
          labelSaving="Logowanie..."
          isSaving={isLoading}
          label="Zaloguj się"
          type="submit"
          className="btn btn-secondary btn-block"
        />
      </Form>
    </LoginUIComponent>
  )
}
