import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { apiQueryFunction } from '@api/base'

import {
  TechnicalOrder,
  TechnicalOrderAggregation,
  TechnicalOrderDetails,
  TechnicalOrderStatus,
} from '@models/technical-orders'
import { extractSelectOptionsValues } from '@helpers/utils'
import { PaginationResponse, PaginationResponseWithAggregation } from '@models/dashboard'
import { RootState } from '@store/index'
import { formatDate } from '@helpers/date-helper'

type TechnicalOrdersQueryParams = Partial<{
  resort: number
  status: TechnicalOrderStatus
  priority: number
  users: string
}>
type TechnicalOrdersAggregationQueryParams = { resort: number }
type CreateOrUpdateTechnicalOrderParams = { data: any; url?: string; id?: number; partial?: boolean }

export const TECHNICAL_ORDER_TAG = 'technicalOrder'

export const technicalOrdersApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: '' }),
  reducerPath: 'technicalOrdersApi',
  tagTypes: [TECHNICAL_ORDER_TAG],
  endpoints: builder => ({
    // guests technical orders
    getGuestsTechnicalOrders: builder.query<TechnicalOrder[], TechnicalOrdersQueryParams>({
      queryFn: apiQueryFunction((state: RootState, data: TechnicalOrdersQueryParams) => ({
        params: { ...(extractSelectOptionsValues(data) || {}), resort: data.resort || undefined },
        method: 'GET',
        url: state.appState.appData.urls.technical_orders.guests_technical_orders,
        responseTransformer: (response: PaginationResponse<TechnicalOrder>) => response.results,
      })),
      providesTags: (result: TechnicalOrder[]) =>
        result
          ? [...result.map(({ id }) => ({ type: TECHNICAL_ORDER_TAG, id }) as const), TECHNICAL_ORDER_TAG]
          : [TECHNICAL_ORDER_TAG],
    }),
    createOrUpdateGuestsTechnicalOrder: builder.mutation<TechnicalOrderDetails, CreateOrUpdateTechnicalOrderParams>({
      invalidatesTags: [TECHNICAL_ORDER_TAG],

      queryFn: apiQueryFunction((state: RootState, { data, partial, id, url }: CreateOrUpdateTechnicalOrderParams) => {
        const technicalOrdersUrl = state.appState.appData.urls.technical_orders.guests_technical_orders

        return {
          data: extractSelectOptionsValues(data),
          method: id || url ? (partial ? 'PATCH' : 'PUT') : 'POST',
          url: url ? url : id ? `${technicalOrdersUrl}${id}/` : technicalOrdersUrl,
        }
      }),
    }),
    // reception technical orders
    getReceptionTechnicalOrders: builder.query<TechnicalOrder[], TechnicalOrdersQueryParams>({
      queryFn: apiQueryFunction((state: RootState, data: TechnicalOrdersQueryParams) => ({
        params: { ...(extractSelectOptionsValues(data) || {}), resort: data.resort || undefined },
        method: 'GET',
        url: state.appState.appData.urls.technical_orders.reception_technical_orders,
        responseTransformer: (response: PaginationResponse<TechnicalOrder>) => response.results,
      })),
      providesTags: (result: TechnicalOrder[]) =>
        result
          ? [...result.map(({ id }) => ({ type: TECHNICAL_ORDER_TAG, id }) as const), TECHNICAL_ORDER_TAG]
          : [TECHNICAL_ORDER_TAG],
    }),
    createOrUpdateReceptionTechnicalOrder: builder.mutation<TechnicalOrderDetails, CreateOrUpdateTechnicalOrderParams>({
      invalidatesTags: [TECHNICAL_ORDER_TAG],

      queryFn: apiQueryFunction((state: RootState, { data, partial, id, url }: CreateOrUpdateTechnicalOrderParams) => {
        const technicalOrdersUrl = state.appState.appData.urls.technical_orders.reception_technical_orders

        return {
          data: extractSelectOptionsValues(data),
          method: id || url ? (partial ? 'PATCH' : 'PUT') : 'POST',
          url: url ? url : id ? `${technicalOrdersUrl}${id}/` : technicalOrdersUrl,
        }
      }),
    }),
    // technical orders
    getTechnicalOrderAppData: builder.query({
      queryFn: apiQueryFunction((state: RootState) => ({
        method: 'GET',
        url: state.appState.appData.urls.technical_orders.app_data,
      })),
    }),
    getTechnicalOrders: builder.query<TechnicalOrder[], TechnicalOrdersQueryParams>({
      queryFn: apiQueryFunction((state: RootState, data: TechnicalOrdersQueryParams) => ({
        params: {
          ...(extractSelectOptionsValues(data) || {}),
          resort: data.resort || undefined,
          users: data?.users || undefined,
        },
        method: 'GET',
        url: state.appState.appData.urls.technical_orders.technical_orders,
        responseTransformer: (response: PaginationResponseWithAggregation<TechnicalOrder, TechnicalOrderAggregation>) =>
          response.results,
      })),
      providesTags: (result: TechnicalOrder[]) =>
        result
          ? [...result.map(({ id }) => ({ type: TECHNICAL_ORDER_TAG, id }) as const), TECHNICAL_ORDER_TAG]
          : [TECHNICAL_ORDER_TAG],
    }),
    getTechnicalOrdersAggregation: builder.query<TechnicalOrderAggregation, TechnicalOrdersAggregationQueryParams>({
      queryFn: apiQueryFunction((state: RootState, data: TechnicalOrdersAggregationQueryParams) => ({
        params: { resort: data.resort || undefined },
        method: 'GET',
        url: state.appState.appData.urls.technical_orders.technical_orders,
        responseTransformer: (response: PaginationResponseWithAggregation<TechnicalOrder, TechnicalOrderAggregation>) =>
          response.aggregation,
      })),
      providesTags: [TECHNICAL_ORDER_TAG],
    }),
    getTechnicalOrderDetails: builder.query<TechnicalOrderDetails, string>({
      queryFn: apiQueryFunction((_, url: string) => ({ method: 'GET', url })),
    }),
    createOrUpdateTechnicalOrder: builder.mutation<TechnicalOrderDetails, CreateOrUpdateTechnicalOrderParams>({
      invalidatesTags: [TECHNICAL_ORDER_TAG],

      queryFn: apiQueryFunction((state: RootState, { data, partial, id, url }: CreateOrUpdateTechnicalOrderParams) => {
        const technicalOrdersUrl = state.appState.appData.urls.technical_orders.technical_orders

        return {
          data: extractSelectOptionsValues({
            ...data,
            technical_order_enter_where_guest_absence_range: data.technical_order_enter_where_guest_absence_range?.map(
              row => ({ ...row, date: formatDate(row.date) }),
            ),
          }),
          method: id || url ? (partial ? 'PATCH' : 'PUT') : 'POST',
          url: url ? url : id ? `${technicalOrdersUrl}${id}/` : technicalOrdersUrl,
        }
      }),
    }),
  }),
})

export const {
  useGetTechnicalOrdersAggregationQuery,
  useGetTechnicalOrdersQuery,
  useGetTechnicalOrderAppDataQuery,
  useCreateOrUpdateTechnicalOrderMutation,
  useCreateOrUpdateReceptionTechnicalOrderMutation,
  useLazyGetTechnicalOrderDetailsQuery: useGetTechnicalOrderDetailsQuery,
  useGetReceptionTechnicalOrdersQuery,
  useGetGuestsTechnicalOrdersQuery,
  useCreateOrUpdateGuestsTechnicalOrderMutation,
} = technicalOrdersApi
