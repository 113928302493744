import * as React from 'react'
import { ReceptionBooking } from '@models/reception'
import { ClientDetails } from '@models/clients'
import { Card, CardBody, Col } from 'reactstrap'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { useFilteredRows } from '@components/table/use-filtered-rows'
import { ClientDetailsModalBookingsRow } from '@modules/crm/clients/details-modal/bookings/bookings-row'
import { ClientDetailsModalBookingsFilters } from '@modules/crm/clients/details-modal/bookings/filters'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { bookingStatusList } from '@models/booking'
import { createSelectOption } from '@helpers/utils'

const initialStatuses = ['initial', 'confirmed', 'checked_in', 'close']

const bookingStatusOptions = bookingStatusList.map(([status, statusDisplay]) =>
  createSelectOption(statusDisplay, status),
)

const isCheckedIn = (booking: ReceptionBooking) => booking.checked_in && booking.status === 'confirmed'

const defaultFilters = {
  search: '',
  ordering: 'date_from',
  page: 1,
  page_size: 9999,
  statuses: bookingStatusOptions.filter(option => initialStatuses.includes(option.value)),
}

interface Props {
  client: ClientDetails
}

export interface ClientDetailsBookingsFilters extends TableFilters {
  statuses: CustomReactSelectOption[]
}

const headerValues: TableHeaderValue<ReceptionBooking>[] = [
  { sortField: 'reservation_number', title: 'Numer rezerwacji', search: true },
  { sortField: 'status', title: 'Status' },
  { sortField: 'resort_id', title: 'Ośrodek' },
  { sortField: 'date_from', title: 'Data pobytu' },
  { title: 'Cena' },
]

export const ClientDetailsModalBookings: React.FC<Props> = ({ client }) => {
  const [filters, setFilters] = React.useState<ClientDetailsBookingsFilters>(defaultFilters)

  const rows = useFilteredRows<ReceptionBooking>(client.bookings, headerValues, String(filters.search))

  const bookings = React.useMemo(
    () =>
      rows.filter(
        booking =>
          !filters.statuses.length ||
          filters.statuses.some(option =>
            option.value === 'checked_in'
              ? isCheckedIn(booking)
              : option.value === booking.status && !isCheckedIn(booking),
          ),
      ),
    [rows, filters.statuses],
  )

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <h4 className="mb-2 text-secondary">Rezerwacje</h4>
          <ClientDetailsModalBookingsFilters
            bookingStatusOptions={bookingStatusOptions}
            filters={filters}
            onFiltersChange={setFilters}
            defaultFilters={defaultFilters}
          />
          <Table
            className="table-hover w-100"
            headerValues={headerValues}
            showPagination={false}
            isLoading={false}
            filters={filters}
            emptyText="Brak rezerwacji"
            setFilters={setFilters}
          >
            {bookings.map((booking: ReceptionBooking) => (
              <ClientDetailsModalBookingsRow booking={booking} key={booking.id} />
            ))}
          </Table>
        </CardBody>
      </Card>
    </Col>
  )
}
