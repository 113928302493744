import * as React from 'react'
import { TechnicalOrdersTodoFilters } from '@modules/technical-orders/technical-orders-todo/filters'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { ColAuto } from '@hyper/col-auto'
import { FormSelect } from '@hyper/forms/form-select'
import { TableFilters } from '@components/table/table'
import { cleaningOrderServiceKindOptions, cleaningOrderStatusSelectOptions } from '@modules/housekeeping/models'

interface Props {
  filters: TableFilters
  setFilters: React.Dispatch<React.SetStateAction<TableFilters>>
  defaultFilters: TableFilters
}

export const UnitDetailsCleaningOrdersFilters: React.FC<Props> = ({ filters, setFilters, defaultFilters }) => (
  <TechnicalOrdersTodoFilters
    hideLabel={false}
    filters={filters}
    searchClassName="ml-auto"
    setFilters={setFilters}
    defaultFilters={defaultFilters}
  >
    <ColAuto>
      <FormSelect
        options={cleaningOrderStatusSelectOptions}
        name="status"
        label="Status"
        selectProps={{ isClearable: true, isSelectMulti: true }}
        formPlainProps={{ colStyle: { padding: 0, width: 200 } }}
      />
    </ColAuto>
    <ColAuto>
      <FormSelect
        options={cleaningOrderServiceKindOptions}
        name="service_kind"
        label="Typ Zadania"
        selectProps={{ isClearable: true, isSelectMulti: true }}
        formPlainProps={{ colStyle: { padding: 0, width: 200 } }}
      />
    </ColAuto>
    <DateFromToInput
      colSize={' '}
      className="col-auto"
      label="Data zgłoszenia"
      startDateName="created_after"
      endDateName="created_before"
    />
  </TechnicalOrdersTodoFilters>
)
