import * as React from 'react'
import { ReceptionBookingBox } from '@modules/reception/checkin/step-payments/boxes/reception-booking-box'
import { ReservationBookingPayment } from '@models/reception'

interface Props {
  payment: ReservationBookingPayment
}

export const ReceptionBookingSettlementDepositReturn: React.FC<Props> = ({ payment }) => (
  <ReceptionBookingBox title="Zwrot pozostałej kwoty dla Gościa">
    {payment.source === 'p24' && (
      <AlertContent returnPeriod="do 14 dni automatycznie (za pomocą przelewy24), na konto z którego była opłacona rezerwacja." />
    )}

    {(payment.source === 'transfer' || payment.source === 'form_bank') && (
      <AlertContent returnPeriod="do 14 dni przelewem. Wyślemy SMS i EMAILA z linkiem do podania numeru konta." />
    )}
  </ReceptionBookingBox>
)

const AlertContent = ({ returnPeriod }: { returnPeriod: string }) => (
  <div className="font-11 alert alert-success-lighten">
    <i className="uil-volume-up mr-2" />
    <strong>Zwrot kaucji</strong> nastąpi w ciągu <strong>{returnPeriod}</strong>
  </div>
)
