import * as React from 'react'
import { GastroCardDetails } from '@models/promotions'
import { Button } from 'reactstrap'
import { useAppDispatch } from '@store/index'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { commonObjectDelete } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { updateGastroCardDetails } from '@store/slices/gastro-card-slice'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useGastroProductProvider } from '@modules/products/hooks/use-gastro-product-provider'
import { GastroProductKind } from '@models/products'

interface GastroCardDetailsDeleteProps {
  gastroCardDetails: GastroCardDetails
  onCancel: () => void
}

export const GastroCardDetailsCancel: React.FC<GastroCardDetailsDeleteProps> = ({ gastroCardDetails, onCancel }) => {
  const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()

  const user = useAuthenticatedUser()

  const { isLoading, action: handleAccept } = useApiRequest(async (reason: string) => {
    if (gastroCardDetails.urls.status) {
      dispatch(
        updateGastroCardDetails(await commonObjectDelete<GastroCardDetails>(gastroCardDetails.urls.status, reason)),
      )
      addSuccessMessage('Sukces', `${gastroCardDetails.product_name} został anulowany!`)
      onCancel()
    }
  })

  const { crud_permissions } = useGastroProductProvider(gastroCardDetails.kind as GastroProductKind)

  const canCancel = user.hasPerm(crud_permissions.cancel) && gastroCardDetails.status !== 'cancelled'

  return canCancel ? (
    <CommonObjectConfirmAction isLoading={isLoading} handleAccept={handleAccept}>
      <Button className="btn-light btn" type="button">
        <i className="uil uil-trash-alt cursor-pointer" title="Anuluj" />
        Anuluj
      </Button>
    </CommonObjectConfirmAction>
  ) : null
}
