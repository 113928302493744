import * as React from 'react'
import Table, { TableHeaderValue } from '@components/table/table'
import { PackageWholesale } from '@modules/package-wholesale/models'
import { PackageWholesaleListFilterParams } from '@modules/package-wholesale/list/filter'
import { PackageWholesaleListTableRow } from '@modules/package-wholesale/list/table-row'
import { PackageWholesaleListTableFooter } from '@modules/package-wholesale/list/table-footer'

const headerValues: TableHeaderValue<PackageWholesale>[] = [
  { title: 'Numer', sortField: 'number' },
  { title: 'Firma', sortField: 'invoice_company' },
  { title: 'NIP', sortField: 'invoice_nip' },
  { title: 'Typ pakietu', sortField: 'kinds' },
  { title: 'Status', sortField: 'status' },
  { title: 'Sprzedawca', sortField: 'seller_id' },
  { title: 'Data dezaktywacji', sortField: 'expire_after' },
  { title: 'Cena netto wpłacona (pobyty)' },
  { title: 'Cena brutto wpłacona (VG)' },
  { title: 'Domki (morze)' },
  { title: 'Apart. (morze)' },
  { title: 'Domki (góry)' },
  { title: 'Apart. (góry)' },
]

interface Props {
  packageWholesales: PackageWholesale[]
  isLoading: boolean
  filters: PackageWholesaleListFilterParams
  setFilters: (filters: PackageWholesaleListFilterParams) => void
}

export const PackageWholesaleListTable: React.FC<Props> = ({ isLoading, packageWholesales, filters, setFilters }) => (
  <Table
    className="table-hover w-100"
    headerValues={headerValues}
    isLoading={isLoading}
    filters={filters}
    setFilters={setFilters}
  >
    {packageWholesales.map(packageWholesale => (
      <PackageWholesaleListTableRow key={packageWholesale.id} packageWholesale={packageWholesale} />
    ))}
    {!!packageWholesales.length && <PackageWholesaleListTableFooter />}
  </Table>
)
