import * as React from 'react'
import { TransferPackage } from '@modules/accounting/refund/models'
import { TransferPackagesFiltersParams } from '@modules/accounting/refund/transfer-packages/index'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { formatPriceShort } from '@helpers/utils'
import { TransferPackagesTableTableCellActions } from '@modules/accounting/refund/transfer-packages/table-cell-actions'
import Table, { TableHeaderValue } from '@components/table/table'

interface TransferPackagesTableProps {
  transferPackages: TransferPackage[]
  filters: TransferPackagesFiltersParams
  isLoading: boolean
  setFilters: (filters: TransferPackagesFiltersParams) => void
}

const headerValues: TableHeaderValue[] = [
  {
    sortField: 'status',
    title: 'Status',
  },
  {
    sortField: 'kind',
    title: 'Rodzaj',
  },
  {
    sortField: 'created',
    title: 'Data utworzenia',
  },
  {
    sortField: 'sent_at',
    title: 'Data wysłania',
  },
  {
    sortField: 'total_amount',
    title: 'Kwota',
  },
  {
    title: '',
  },
]

export const TransferPackagesTable: React.FC<TransferPackagesTableProps> = ({
  filters,
  setFilters,
  isLoading,
  transferPackages,
}) => (
  <Table
    className="table-hover w-100"
    headerValues={headerValues}
    isLoading={isLoading}
    filters={filters}
    setFilters={setFilters}
  >
    {transferPackages.map(item => (
      <Table.Row key={item.id}>
        <Table.Cell>{item.status_display}</Table.Cell>
        <Table.Cell>{item.kind_display}</Table.Cell>
        <Table.Cell>
          {toDefaultDateTimeFormat(item.created)} <br />
          {item.created_by}
        </Table.Cell>
        <Table.Cell>
          {item.sent_at && (
            <>
              {toDefaultDateTimeFormat(item.sent_at)} <br />
              {item.sent_by}
            </>
          )}
        </Table.Cell>
        <Table.Cell>{formatPriceShort(item.total_amount)}</Table.Cell>
        <Table.Cell className="text-nowrap" style={{ width: 10 }}>
          <TransferPackagesTableTableCellActions transferPackage={item} />
        </Table.Cell>
      </Table.Row>
    ))}
  </Table>
)
