import * as React from 'react'
import { FormInput, FormPlain } from '@hyper/forms'
import { FormSelect } from '@hyper/forms/form-select'
import { FormCheckbox } from '@hyper/forms/form-checkbox'
import { ApplicationImageUploader } from '@modules/housekeeping/applications/images-uploader'
import { Col, Row } from 'reactstrap'
import { ModalBodyHr } from '@components/modals/modal-body-hr'
import { CustomReactSelectOption, CustomReactSelectOptionGroup } from '@components/custom-react-select'
import { ApplicationImage } from '@modules/housekeeping/models'
import { useFormContext, useWatch } from 'react-hook-form'
import { TechnicalOrderAssignForm } from '@modules/technical-orders/technical-order-assign-modal/form'
import { TechnicalOrderFormInputs } from '@modules/technical-orders/technical-order-modal/index'
import { TechnicalOrderFormOptions } from '@modules/technical-orders/technical-order-modal/form-options'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { useAppData } from '@components/hooks/use-app-data'
import { TechnicalOrderFormBookings } from '@modules/technical-orders/technical-order-modal/form-bookings'

interface Props {
  resortOptions: CustomReactSelectOption[]
  technicalOrderTypesOptions: CustomReactSelectOption[]
  unitsOptions: CustomReactSelectOptionGroup[]
  images: ApplicationImage[]
  initialCompany?: number
  showIsSupervisorAcceptanceRequired: boolean
  showAssignUsers: boolean
  showTechnicalOrderTypes: boolean
  nameLabel?: string
  showOptions: boolean
  unitLabel?: string
  showCreatedByBooking?: boolean
  descriptionLabel?: string
  resortReadOnly?: boolean
  unitReadOnly?: boolean
}

export const TechnicalOrderForm: React.FC<Props> = ({
  resortReadOnly,
  unitReadOnly,
  nameLabel = 'Nazwa usterki',
  unitLabel = 'Miejsce wystąpenia usterki',
  showOptions,
  showCreatedByBooking,
  images,
  initialCompany,
  unitsOptions,
  resortOptions,
  technicalOrderTypesOptions,
  showIsSupervisorAcceptanceRequired,
  showAssignUsers,
  descriptionLabel = 'Opis usterki',
  showTechnicalOrderTypes,
}) => {
  const { setValue, control } = useFormContext<TechnicalOrderFormInputs>()
  const selectedResort = useWatch({ control, name: 'resort' })
  const selectedUnit = useWatch({ control, name: 'unit' })
  const assignUsers = useWatch({ control, name: 'assignUsers' })

  const { units } = useAppData()

  const apartmentId = React.useMemo(
    () => units.filter(row => row.id === selectedUnit?.value)?.[0]?.apartment_id || undefined,
    [selectedUnit, units],
  )

  useDidUpdateEffect(() => {
    if (!assignUsers) {
      setValue('users', {})
    }
  }, [assignUsers])

  return (
    <>
      <FormSelect
        options={resortOptions}
        name="resort"
        label="Ośrodek"
        labelSize={4}
        selectProps={{ isDisabled: resortReadOnly }}
      />
      <FormSelect
        options={unitsOptions}
        name="unit"
        label={unitLabel}
        labelSize={4}
        selectProps={{ isDisabled: !selectedResort || unitReadOnly }}
      />
      {showCreatedByBooking && <TechnicalOrderFormBookings apartment={apartmentId} selectedResort={selectedResort} />}
      <FormInput name="name" label={nameLabel} labelSize={4} />
      {showTechnicalOrderTypes && (
        <FormSelect
          options={technicalOrderTypesOptions}
          name="technical_order_type"
          label="Typ usterki"
          labelSize={4}
          selectProps={{ isClearable: true }}
        />
      )}
      {showOptions && (
        <Row>
          <Col sm={4} />
          <Col sm={8}>
            <TechnicalOrderFormOptions
              wrapperClassName="ml-1"
              showIsSupervisorAcceptanceRequired={showIsSupervisorAcceptanceRequired}
            />
          </Col>
        </Row>
      )}
      <ModalBodyHr colClassName="mt-0 mb-2 px-0" />
      <FormInput name="description" type="textarea" label={descriptionLabel} labelSize={4} />
      <FormPlain label="Załączniki" labelSize={4} name="images" formGroupClassName="mb-0">
        <ApplicationImageUploader defaultImages={images} />
      </FormPlain>
      {showAssignUsers && (
        <>
          <ModalBodyHr colClassName="mt-0 px-0" />
          <FormPlain labelSize={4} label="Przydziel to zadanie" name="assignUsers">
            <FormCheckbox
              name="assignUsers"
              label="Chcę przydzielić to zadanie do pracownika"
              className="mt-2"
              helpText={<span className="font-10 text-muted d-block ml-3">(opcjonalnie)</span>}
            />
          </FormPlain>
          {assignUsers && (
            <Row>
              <Col sm={4} />
              <Col sm={8}>
                <TechnicalOrderAssignForm resortId={selectedResort?.value || 0} initialCompany={initialCompany} />
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  )
}
