import * as React from 'react'
import { useModal } from '@components/modals/use-modal'
import { commonObjectGet } from '@store/actions/generic-actions'
import { Cafeteria } from '@models/cafeteria'
import { RootState, useAppSelector } from '@store/index'

interface Props {
  id: number
}

export const CafeteriaDetailsView = ({ id }: Props): null => {
  const [handleDetails] = useModal('CafeteriaDetailsModal')
  const url = useAppSelector((state: RootState) => state.appState.appData.urls.cafeteria.cafeterias)

  const show = async () => {
    const cafeteria = await commonObjectGet<Cafeteria>(`${url}${id}/`)
    handleDetails(null, { cafeteria }, true)
  }

  React.useEffect(() => {
    show()
  }, [])

  return null
}
