import * as React from 'react'
import classname from 'classnames'
import { ReservationStatsTableDataCell } from '@modules/reservations/statistics/booking-stats/stats-details/stats-details-table/reservation-stats-table-data-cell'

interface Props {
  title: string
  children: React.ReactNode
  days: string[]
  lastCell?: React.ReactNode
  hoveredDate: string | null
  onHoveredElementChange: (date: string) => void
}

export const ReservationStatsTableExpandableRow = ({
  title,
  children,
  days,
  lastCell = <td className="reservation-stats__col__last" />,
  hoveredDate,
  onHoveredElementChange,
}: Props): JSX.Element => {
  const [expanded, setExpanded] = React.useState(true)
  const toggleExpanded = () => setExpanded(state => !state)

  const emptyCells = React.useMemo(
    () =>
      days.map((day: string) => (
        <ReservationStatsTableDataCell
          hoveredDate={hoveredDate}
          onHoverChange={onHoveredElementChange}
          date={day}
          key={day}
        />
      )),
    [days, hoveredDate],
  )

  return (
    <>
      <tr className="cursor-pointer" onClick={toggleExpanded} data-testid="toggle-row-visibility">
        <td className="text-dark font-weight-semibold reservation-stats__col__first">
          {title}
          <i
            className={classname('uil-angle-up ml-1 font-16', 'reservation-stats__row__icon', {
              'reservation-stats__row__icon--expanded': expanded,
            })}
          />
        </td>
        {emptyCells}
        {lastCell}
      </tr>
      {expanded && children}
    </>
  )
}
