import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useForm } from 'react-hook-form'
import { BaseModalProps } from '@components/modals/types'
import { ReceptionBookingDetails } from '@models/reception'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useFormRequest } from '@components/hooks/use-api-request'
import { Form } from '@hyper/forms/form'
import { commonObjectPost } from '@store/actions/generic-actions'
import { startOfToday } from 'date-fns'
import { useAppDispatch } from '@store/index'
import { getBookingPayments } from '@store/actions/reservation-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { formatPrice } from '@helpers/utils'
import Decimal from 'decimal.js'
import { ReservationPaymentForm } from '@modules/reservations/details/payment-history/modals/payment/reservation-payment-add-form'
import { ReservationPaymentModalHeader } from '@modules/reservations/details/payment-history/modals/payment/reservation-payment-edit-modal'
import { BookingPaymentType } from '@models/booking'

interface Props extends BaseModalProps {
  booking: ReceptionBookingDetails
  paymentKind: BookingPaymentType
}

interface FormInputs {
  source: CustomReactSelectOption | null
  amount: string
  invoice_date: Date | null
}

export const ReservationPaymentAddModal: React.FC<Props> = ({ toggleIsVisible, booking, paymentKind }) => {
  const methods = useForm<FormInputs>()

  React.useEffect(() => {
    if (paymentKind === 'deposit') {
      methods.setValue('amount', booking.deposit)
      methods.setValue('invoice_date', startOfToday())
    }

    if (paymentKind === 'installment') {
      methods.setValue('amount', Decimal.max(booking.rest_to_pay, 0).toString())
      methods.setValue('invoice_date', startOfToday())
    }

    if (paymentKind === 'climatic') methods.setValue('amount', booking.climatic_tax_brutto)
  }, [])

  const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()

  const { action: handleSubmit, isLoading } = useFormRequest(async (payload: FormInputs) => {
    await commonObjectPost(booking.urls.booking_payments, {
      ...payload,
      type: paymentKind,
      source: payload.source?.value,
    })
    dispatch(await getBookingPayments(booking))
    toggleIsVisible()
    addSuccessMessage('Sukces', `Wpłata w kwocie ${formatPrice(payload.amount)} została dodana!`)
  }, methods.setError)

  return (
    <Form onSubmit={handleSubmit} methods={methods}>
      <ModalHeader toggle={toggleIsVisible}>Dodaj {ReservationPaymentModalHeader[paymentKind]}</ModalHeader>
      <ModalBody>
        <ReservationPaymentForm paymentKind={paymentKind} canOmitSage={true} />
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton
          role="submit"
          className="btn btn-green"
          label="Dodaj"
          labelSaving="Dodawanie..."
          isSaving={isLoading}
        />
      </ModalFooter>
    </Form>
  )
}
