import * as React from 'react'
import { ReceptionBookingImprovementSubscriptionAdd } from '@modules/reception/checkin/step-improvements/improvement-subscription/reception-booking-improvement-subscription-add'
import { ImprovementAvailability } from '@models/booking'
import { ReceptionBookingDetails } from '@models/reception'
import { ReceptionBookingCheckInStepImprovementsGroup } from '@modules/reception/checkin/step-improvements/reception-booking-check-in-step-improvements-group'
import { ReceptionBookingSubscriptionImprovementAddedRow } from '@modules/reception/checkin/step-improvements/improvement-subscription/reception-booking-improvement-subscription-added-row'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { useAppData } from '@components/hooks/use-app-data'

export const SubscriptionBookingImprovementCode = 'subscription_booking_improvement'

interface Props {
  improvementsAvailability: ImprovementAvailability[]
  booking: ReceptionBookingDetails
}

export const ReceptionBookingSubscriptionImprovement = ({
  booking,
  improvementsAvailability,
}: Props): React.ReactNode => {
  const { configuration } = useAppData()
  const user = useAuthenticatedUser()

  const subscriptionImprovementAvailability =
    configuration.vzk_enabled &&
    improvementsAvailability.find(
      improvement => improvement.code === SubscriptionBookingImprovementCode && improvement.is_available,
    )

  const bookingSubscriptionImprovements = booking.improvements.filter(
    improvement => improvement.code === SubscriptionBookingImprovementCode,
  )

  return (
    <div>
      {user.hasPerm(UserPermission.BookingCanAddSubscriptionBookingImprovement) &&
        subscriptionImprovementAvailability && (
          <ReceptionBookingImprovementSubscriptionAdd
            subscriptionImprovementAvailability={subscriptionImprovementAvailability}
          />
        )}
      {!!bookingSubscriptionImprovements.length && (
        <ReceptionBookingCheckInStepImprovementsGroup title="Dodane vouchery Super Start">
          {bookingSubscriptionImprovements.map(subscriptionImprovement => (
            <ReceptionBookingSubscriptionImprovementAddedRow
              key={subscriptionImprovement.id}
              improvement={subscriptionImprovement}
            />
          ))}
        </ReceptionBookingCheckInStepImprovementsGroup>
      )}
    </div>
  )
}
