import * as React from 'react'
import { PackageVip } from '@modules/promotions/package-vip/models'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { Button, Col, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { getPackageVipDetails } from '@store/actions/promotions-actions'
import { Row } from 'reactstrap/lib'
import { PackageVipPayments } from '@modules/promotions/package-vip/details/payments'
import { PackageVipDetailsSummary } from '@modules/promotions/package-vip/details/package-vip-details-summary'
import { PackageVipDetailsDelete } from '@modules/promotions/package-vip/details/package-vip-details-delete'
import { HistoryBox } from '@modules/promotions/common/history-box'
import { PackageVipDetailsTabs } from '@modules/promotions/package-vip/details/tabs'
import { BaseModalProps } from '@components/modals/types'
import { ProductEmailNotifications } from '@modules/promotions/common/notification/notifications'
import { usePromotionsAppData } from '@modules/promotions/hooks/use-promotions-app-data'
import { createProductCardClient, ProductClientCard } from '@components/products/product-client-card'
import { createInvoiceData, ProductInvoiceCard } from '@components/products/product-invoice-card'
import { ContentNotesCard } from '@components/content-notes/content-notes-card'
import { setPackageVipDetails } from '@store/slices/package-vip-slice'

interface PackageVipDetailsProps extends BaseModalProps {
  packageVip: PackageVip
}

export const PackageVipDetails: React.FC<PackageVipDetailsProps> = ({ toggleIsVisible, packageVip }) => {
  const dispatch = useAppDispatch()
  const { package_vip_notifications } = usePromotionsAppData()

  const dataRef = React.useRef<HTMLDivElement>(null)
  const historyRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    dispatch(setPackageVipDetails(null))
    handleRefresh()
  }, [])

  const handleRefresh = () => dispatch(getPackageVipDetails(packageVip.urls.details))

  const packageVipDetails = useAppSelector((state: RootState) => state.packageVipState.packageVipDetails)
  const contentType = useAppSelector((state: RootState) => state.appState.appData.content_types.packagevip)

  return (
    <>
      <ModalHeader className="bg-light" toggle={toggleIsVisible}>
        Pakiet VIP #{packageVip.number}
      </ModalHeader>
      <ModalBody role="modal-body" className="modal-details">
        <LoaderPlaceholder content={packageVipDetails}>
          {packageVipDetails && (
            <>
              <PackageVipDetailsTabs dataRef={dataRef} historyRef={historyRef} />

              <Row>
                <Col md={6}>
                  <div ref={dataRef} />
                  <PackageVipDetailsSummary packageVipDetails={packageVipDetails} />

                  {packageVipDetails.kind === 'standard' && (
                    <PackageVipPayments packageVipDetails={packageVipDetails} />
                  )}

                  <ProductEmailNotifications
                    onSuccess={handleRefresh}
                    history={packageVipDetails.notifications}
                    notificationEmails={package_vip_notifications}
                    url={packageVipDetails.urls.notifications}
                    email={packageVipDetails.client?.email || ''}
                  />
                </Col>
                <Col md={6}>
                  {packageVipDetails.client && (
                    <ProductClientCard
                      clientData={createProductCardClient(packageVipDetails.client)}
                      canViewClientDetails={!packageVipDetails.is_removed}
                    />
                  )}
                  <ProductInvoiceCard invoiceData={createInvoiceData(packageVipDetails)} />
                  <ContentNotesCard
                    readOnly={packageVipDetails.is_removed}
                    objectId={packageVipDetails.id}
                    contentType={contentType}
                    onNotesUpdate={handleRefresh}
                    notes={packageVipDetails.notes}
                  />
                  <HistoryBox history={packageVipDetails.history} ref={historyRef} />
                </Col>
              </Row>
            </>
          )}
        </LoaderPlaceholder>
      </ModalBody>
      <ModalFooter className="d-block">
        <Row>
          <Col md={6}>
            {packageVipDetails && (
              <PackageVipDetailsDelete packageVipDetails={packageVipDetails} onDelete={toggleIsVisible} />
            )}
          </Col>
          <Col md={6} className="text-right">
            <Button color="light" onClick={toggleIsVisible}>
              Zamknij
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </>
  )
}
