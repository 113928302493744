import * as React from 'react'
import { Button } from 'reactstrap'
import { IconWithText } from '@components/icon-with-text'
import { useModal } from '@components/modals/use-modal'
import { ReceptionBookingDetails } from '@models/reception'
import classNames from 'classnames'

interface Props {
  booking: ReceptionBookingDetails
  onShowCart?: () => void
  className?: string
}

export const ReceptionBookingNewFeedingOpenCartButton = ({ booking, onShowCart, className }: Props): JSX.Element => {
  const [showBookingCart] = useModal('ReceptionBookingCartModal', { bookingDetails: booking }, { persist: true })

  const handleShow = () => {
    showBookingCart()
    onShowCart?.()
  }

  return (
    <Button className={classNames('btn btn-warning', className)} onClick={handleShow}>
      <IconWithText icon="uil-shopping-cart-alt font-15" text="Pokaż koszyk" />
    </Button>
  )
}
