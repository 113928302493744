import * as React from 'react'
import Table, { TableHeaderValue } from '@components/table/table'
import { ServiceBand } from '@modules/bands/service-bands/models'
import { ServiceBandFiltersParams } from '@modules/bands/service-bands/filters'
import { ServiceBandTableRow } from '@modules/bands/service-bands/table-row'

interface Props {
  isLoading: boolean
  filters: ServiceBandFiltersParams
  setFilters: (filters: ServiceBandFiltersParams) => void
  serviceBands: ServiceBand[]
}

const headerValues: TableHeaderValue<ServiceBand>[] = [
  {
    sortField: 'name',
    title: 'Nazwa',
  },
  {
    sortField: 'owner',
    title: 'Właściciel',
  },
  {
    sortField: 'status',
    title: 'Status',
  },
  {
    sortField: 'resort',
    title: 'Ośrodek',
  },
  {
    sortField: 'initial_funds',
    title: 'Limit środków',
  },
  {
    title: 'Ważna od',
  },
  {
    title: 'Ważna do',
  },
  {
    title: 'Akcje',
  },
]

export const ServiceBandTable: React.FC<Props> = ({ serviceBands, setFilters, filters, isLoading }) => (
  <Table
    className="table-hover w-100"
    headerValues={headerValues}
    isLoading={isLoading}
    filters={filters}
    setFilters={setFilters}
  >
    {serviceBands.map(serviceBand => (
      <ServiceBandTableRow key={serviceBand.id} serviceBand={serviceBand} />
    ))}
  </Table>
)
