import * as React from 'react'
import { useForm } from 'react-hook-form'
import { ReceptionBookingCheck } from '@modules/reception/common/reception-booking-check'
import { newUserPredicator, ReceptionCheckReminders } from '@modules/reception/common/reception-check-reminders'
import { ReceptionBookingDetails, ReceptionReminder } from '@models/reception'
import { Col, ModalBody, Row } from 'reactstrap'
import { ReceptionBookingCheckBillDetails } from '@modules/reception/common/reception-booking-check-bill-details'
import { ReceptionBookingCheckOutStepBillDamages } from '@modules/reception/checkout/step-bill/step-bill-damages'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { asDecimal } from '@helpers/utils'
import { Form } from '@hyper/forms/form'
import { useApiRequest, useFormRequest } from '@components/hooks/use-api-request'
import { getBookingPayments } from '@store/actions/reservation-actions'
import { useAppDispatch } from '@store/index'
import { ReceptionBookingCheckOutStepBillCashbackVoucher } from '@modules/reception/checkout/step-bill/step-bill-cashback-voucher'
import { updateBookingDetails } from '@store/actions/reception-actions'
import { commonObjectGet, commonObjectPatch } from '@store/actions/generic-actions'

interface Props {
  nextStep: () => void
  previousStep: () => void
  booking: ReceptionBookingDetails
}

const reminders: ReceptionReminder[] = [
  {
    id: 'check_damages',
    isClosable: true,
    children: (
      <>
        Pamiętaj, aby zaznaczyć wszystkie <strong>straty i zniszczenia</strong> w lokalu.
      </>
    ),
  },
  {
    id: 'check_meters',
    isClosable: true,
    children: <>Informacja o sprawdzeniu liczników ale nie mam pomysłu co napisać :)</>,
  },
]

interface FormInputs {
  check_damages: boolean
  check_meters: boolean
  cashback_voucher_accepted: boolean
  check_out_step_shorten: boolean
}

export const ReceptionBookingCheckOutStepBill: React.FC<Props> = ({ previousStep, nextStep, booking }) => {
  const user = useAuthenticatedUser()
  const methods = useForm<FormInputs>({
    defaultValues: {
      check_meters: newUserPredicator(user),
      check_damages: newUserPredicator(user),
      check_out_step_shorten: false,
    },
  })
  const dispatch = useAppDispatch()

  const { action: fetchPayments } = useApiRequest(async () => {
    if (booking) {
      dispatch(await getBookingPayments(booking))
    }
  })

  const { action: fetchBooking } = useApiRequest(async () => {
    dispatch(
      updateBookingDetails(await commonObjectGet<ReceptionBookingDetails>(booking.urls.check_out_step_information)),
    )
  })

  React.useEffect(() => {
    fetchBooking()
  }, [])

  React.useEffect(() => {
    fetchPayments()
  }, [booking])

  const restToPay = asDecimal(booking.rest_to_pay)

  const { action: onSubmit } = useFormRequest(
    async (payload: FormInputs) => {
      dispatch(
        updateBookingDetails(
          await commonObjectPatch<ReceptionBookingDetails>(booking.urls.check_out_step_bill, payload),
        ),
      )
      nextStep()
    },
    [methods.setError],
  )

  return (
    <Form methods={methods} onSubmit={onSubmit} role="step-bill" className="reception__booking-check-out__bill-step">
      <ModalBody className="pb-0">
        <h4>2. Rachunek informacyjny</h4>
        <ReceptionCheckReminders reminders={reminders} required={!newUserPredicator(user)} />
        <Row className="pb-2 mt-2">
          <Col md={6}>
            <ReceptionBookingCheckBillDetails
              booking={booking}
              title="Szczegóły rachunku:"
              isOpen={true}
              restToPay={restToPay}
              allowMetersChange={true}
            />
          </Col>
          <Col md={6}>
            <ReceptionBookingCheckOutStepBillDamages booking={booking} />
            <ReceptionBookingCheckOutStepBillCashbackVoucher booking={booking} />
          </Col>
        </Row>
      </ModalBody>
      <ReceptionBookingCheck nextStep={nextStep} previousStep={previousStep} isDisabled={!methods.formState.isValid} />
    </Form>
  )
}
