import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { useFilteredRows } from '@components/table/use-filtered-rows'
import { useSortedRows } from '@components/table/use-sorted-rows'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { formatPriceShort } from '@helpers/utils'
import { PackageDetails } from '@models/package'
import { Payment } from '@models/payments'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import * as React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { PackageDetailsPaymentsListActions } from '@modules/package/details/payments/list/actions'

const headerValues: TableHeaderValue<Payment>[] = [
  { title: 'Data wpłaty', sortField: 'invoice_date', search: true },
  { title: 'Kwota', sortField: 'amount', search: true },
  { title: 'Źródło', sortField: 'source_display', search: true },
  { title: 'Dodane', sortField: 'user', search: true },
  { title: 'Opis', sortField: 'description', search: true },
]
const defaultFilters: TableFilters = {
  search: '',
  ordering: '-invoice_date',
  page: 1,
  page_size: 9999,
}

interface Props {
  packageDetails: PackageDetails
}

export const PackageDetailsPaymentsList: React.FC<Props> = ({ packageDetails }) => {
  const [filters, setFilters] = React.useState<TableFilters>(defaultFilters)

  const setValue = (_, search) => setFilters({ ...filters, search })

  const filteredRows = useFilteredRows<Payment>(packageDetails.payments, headerValues, String(filters.search))
  const sortedRows = useSortedRows<Payment>(filteredRows, filters.ordering)

  return (
    <Card data-testid="package-details-payments-list">
      <CardBody>
        <Row className="align-items-center">
          <Col className="col-auto col-md-5">
            <h4 className="text-secondary">Wpłaty</h4>
          </Col>
          <SearchFilter hideLabel={true} setValue={setValue} colClassName="col-md-5" formGroupClassName="mb-0" />
        </Row>
        <hr className="mt-1" />
        <Table
          className="table-hover table-sm w-100 mb-0"
          headerValues={headerValues}
          isLoading={false}
          showPagination={false}
          emptyText="Brak wpłat"
          filters={filters}
          setFilters={setFilters}
        >
          {sortedRows.map(payment => (
            <Table.Row key={payment.id}>
              <Table.Cell>{toDefaultDateFormat(payment.invoice_date)}</Table.Cell>
              <Table.Cell>
                <a href={payment.urls.admin} target="_blank">
                  {formatPriceShort(payment.amount)}
                </a>
              </Table.Cell>
              <Table.Cell>{payment.source_display}</Table.Cell>
              <Table.Cell>{payment.user}</Table.Cell>
              <Table.Cell>{payment.description}</Table.Cell>
            </Table.Row>
          ))}
        </Table>
        {packageDetails.status !== 'cancelled' && <PackageDetailsPaymentsListActions packageDetails={packageDetails} />}
      </CardBody>
    </Card>
  )
}
