import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { SaveButton } from '@hyper/button'
import { FormInput } from '@hyper/forms'
import { ButtonWithIcon } from '@components/button-with-icon'
import { useModal } from '@components/modals/use-modal'
import { useFormRequest } from '@components/hooks/use-api-request'
import { useAppDispatch } from '@store/index'
import { createPaymentDayPointReport, updatePaymentDayPointReport } from '@store/actions/cash-actions'
import { PaymentDayPointReport, PaymentDayReportDetails, PaymentRegisterPoint } from '@models/payments'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { CashCount } from '@modules/payment-registers/cash-count-modal'
import { cashCountEmptyValues, useCashCount } from '@modules/payment-registers/cash-count-modal/use-cash-count'
import { Form } from '@hyper/forms/form'

interface FormInputs {
  amount: string
}

interface Props extends BaseModalProps {
  paymentDayReportDetails: PaymentDayReportDetails
  point: PaymentRegisterPoint
  paymentDayPointReport?: PaymentDayPointReport
}

export const PaymentRegisterPointModal: React.FC<Props> = ({
  paymentDayPointReport,
  paymentDayReportDetails,
  point,
  toggleIsVisible,
}) => {
  const methods = useForm<FormInputs>({
    defaultValues: paymentDayPointReport,
  })

  const [cashCountValues, setCashCountValues] = React.useState<CashCount>(cashCountEmptyValues)
  const { totalCashCount } = useCashCount(cashCountValues)

  const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()
  const { isLoading, action: onSubmit } = useFormRequest(
    async (payload: FormInputs) => {
      paymentDayPointReport
        ? await dispatch(updatePaymentDayPointReport([paymentDayPointReport, payload]))
        : await dispatch(createPaymentDayPointReport([paymentDayReportDetails, { ...payload, point: point.id }]))

      addSuccessMessage('Sukces', paymentDayPointReport ? 'Wpłata została zmieniona' : 'Wpłata została dodana')
      toggleIsVisible()
    },
    methods.setError,
    { showGlobalError: true },
  )

  const [handleCashCount] = useModal('CashCountModal', {
    setCashCountValues,
    cashCountValues,
    resort: point.resort_id,
  })

  React.useEffect(() => {
    methods.setValue('amount', totalCashCount)
  }, [cashCountValues])

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>
        {paymentDayPointReport ? 'Edytuj utarg' : 'Wpłać utarg'} {point.name}
      </ModalHeader>
      <ModalBody>
        <Row>
          <FormInput
            label={paymentDayPointReport ? 'Edytuj utarg' : 'Wpłać utarg'}
            colSize={6}
            name="amount"
            type="currency"
            setValue={methods.setValue}
          />
          <Col md={6}>
            <label className="d-block">&nbsp;</label>
            <ButtonWithIcon
              icon="uil-money-withdraw"
              color="outline-secondary"
              text="Przelicz gotówkę"
              handleClick={handleCashCount}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <button type="button" onClick={toggleIsVisible} className="btn mr-1 btn-light">
          Anuluj
        </button>
        <SaveButton
          isSaving={isLoading}
          className="btn btn-green"
          label={paymentDayPointReport ? 'Edytuj utarg (KP)' : 'Wpłać utarg (KP)'}
        />
      </ModalFooter>
    </Form>
  )
}
