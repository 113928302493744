import { FormInput } from '@hyper/forms'
import * as React from 'react'
import { ButtonWithIcon } from '@components/button-with-icon'
import classNames from 'classnames'

interface Props {
  onAbort: () => void
  submitIcon: string
  submitText: string
  submitColor: string
  className?: string
  isLoading: boolean
}

export const ContentNoteForm = ({ onAbort, submitText, submitColor, submitIcon, className, isLoading }: Props) => (
  <div className={classNames('border rounded', className)}>
    <FormInput
      name="content"
      placeholderIcon="uil-file-check-alt text-muted font-14 pt-1"
      colClassName="px-0"
      type="textarea"
      inputProps={{ rows: 3, autoFocus: true }}
      inputClassName="border-0 resize-none"
      formGroupClassName="mb-0"
    />
    <div className="d-flex m-1 justify-content-end">
      <ButtonWithIcon
        icon="uil-times font-12"
        text="Anuluj"
        color="outline-danger"
        btnClass="d-flex align-items-center font-11 px-1 mr-2 btn-flat"
        handleClick={onAbort}
      />

      <ButtonWithIcon
        type="submit"
        icon={submitIcon}
        text={submitText}
        color={submitColor}
        isLoading={isLoading}
        btnClass="d-flex align-items-center font-11 px-1 btn-flat"
        spinnerColor="text-muted"
      />
    </div>
  </div>
)
