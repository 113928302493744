import * as React from 'react'
import { CompanyDataFromGus } from '@models/sale'
import { Spinner } from 'reactstrap'
import classNames from 'classnames'
import { useApiRequest } from '@components/hooks/use-api-request'
import { getCompanyDataFromGus } from '@store/actions/sale-actions'

interface GusButtonProps {
  getNip: () => string
  button?: React.ReactElement
  setCompanyData: (data: CompanyDataFromGus) => void
  className?: string
  loaderClassName?: string
}

export const GusButton: React.FC<GusButtonProps> = ({ getNip, setCompanyData, button, className, loaderClassName }) => {
  const { isLoading, action: fetch } = useApiRequest(async nip => setCompanyData(await getCompanyDataFromGus(nip)))

  const handleGus = async event => {
    event.preventDefault()
    const nip = getNip()

    if (nip) {
      fetch(nip)
    }
  }

  return (
    <div onClick={handleGus} className={className}>
      {isLoading ? (
        <div className={classNames(loaderClassName, 'mt-1')}>
          <Spinner className="text-primary" size="sm" />
          <span className="ml-1">Pobieranie...</span>
        </div>
      ) : button ? (
        button
      ) : (
        <div className="text-strong text-primary cursor-pointer mt-1">
          <i className="uil-search" />
          &nbsp; Pobierz dane z GUS
        </div>
      )}
    </div>
  )
}
