import { asDecimal } from '@helpers/utils'

export const bruttoToNetto = (priceBrutto: string, tax: 8 | 23): string => {
  const taxValue = {
    8: '1.08',
    23: '1.23',
  }[tax]
  try {
    return asDecimal(priceBrutto).div(asDecimal(taxValue)).toString()
  } catch (e) {
    return ''
  }
}

export const nettoToBrutto = (priceNetto: string, tax: 8 | 23) => {
  const taxValue = {
    8: '1.08',
    23: '1.23',
  }[tax]
  try {
    return asDecimal(priceNetto).mul(asDecimal(taxValue)).toString()
  } catch (e) {
    return ''
  }
}
