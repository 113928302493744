import * as React from 'react'
import { ReceptionBookingDetails, ReservationBookingPayment } from '@models/reception'
import { ReservationCard } from '@modules/reservations/details/common/reservation-card'
import { ReservationPaymentHistoryActions } from '@modules/reservations/details/payment-history/reservation-payment-history-actions'
import { ReservationPaymentHistoryRow } from '@modules/reservations/details/payment-history/reservation-payment-history-row'
import { reservationBookingPaymentsSelector } from '@store/slices/reservations-slice'
import { useApiRequest } from '@components/hooks/use-api-request'
import { getBookingPayments } from '@store/actions/reservation-actions'
import { useAppDispatch, useAppSelector } from '@store/index'
import { ContentLoader } from '@components/content-loader'
import { useToggle } from '@components/hooks/use-toggle'
import { CustomSwitch } from '@components/custom-switch'

interface Props {
  booking: ReceptionBookingDetails
}

export const ReservationPaymentHistory = ({ booking }: Props): JSX.Element => {
  const [showHidden, toggleHide] = useToggle()
  const bookingPayments = useAppSelector(reservationBookingPaymentsSelector)

  const dispatch = useAppDispatch()
  const { action: fetchPayments, isLoading } = useApiRequest(async () => {
    dispatch(await getBookingPayments(booking))
  })

  React.useEffect(() => {
    fetchPayments()
  }, [])

  const payments = React.useMemo(
    () => bookingPayments.filter((payment: ReservationBookingPayment) => showHidden || !payment.hide),
    [bookingPayments, showHidden],
  )

  const hiddenCount = bookingPayments.filter((payment: ReservationBookingPayment) => payment.hide).length

  return (
    <ReservationCard title="Historia wpłat" cardActions={<ReservationPaymentHistoryActions booking={booking} />}>
      <ContentLoader isLoading={isLoading}>
        {!!hiddenCount && (
          <CustomSwitch
            labelClassName="text-semi-strong"
            label={`Pokaż ukryte (${hiddenCount})`}
            handleSwitchChange={toggleHide}
            value={showHidden}
          />
        )}
        {payments.length ? (
          <table className="table table-sm table-striped mt-2">
            <thead>
              <tr>
                <th className="text-nowrap border-0">Data dodania</th>
                <th className="text-nowrap border-0">Typ</th>
                <th className="text-nowrap border-0">Metoda płatności</th>
                <th className="text-nowrap border-0">Dodane przez</th>
                <th className="text-nowrap border-0">Kwota</th>
                <th className="text-nowrap border-0" />
              </tr>
            </thead>
            <tbody>
              {payments.map(row => (
                <ReservationPaymentHistoryRow key={row.id} bookingPayment={row} />
              ))}
            </tbody>
          </table>
        ) : (
          <p className="text-center"> Brak wpłat</p>
        )}
      </ContentLoader>
    </ReservationCard>
  )
}
