import * as React from 'react'
import { BaseModalProps } from '@components/modals/types'
import { FormProvider, useForm } from 'react-hook-form'
import { Button, Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { FormInput, FormPlain } from '@hyper/forms'
import { SaveButton } from '@hyper/button'
import { CmsResortDetails, CmsResortDetailsMapSector } from '@modules/cms/models'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAppDispatch } from '@store/index'
import { useFormRequest } from '@components/hooks/use-api-request'
import { setCmsResort } from '@store/slices/cms-reservation-app-slice'
import { FileUploader, UploaderAcceptableFile } from '@components/file-uploader'
import { createResortMapSector, updateResortMapSector } from '@api/cms'

interface Props extends BaseModalProps {
  resortSectorMap?: CmsResortDetailsMapSector
  resort: CmsResortDetails
}

export interface CmsReservationAppResortMapFormInputs {
  name: string
  display_name: string
}

export const CmsReservationAppResortMapModal: React.FC<Props> = ({ resortSectorMap, resort, toggleIsVisible }) => {
  const methods = useForm<CmsReservationAppResortMapFormInputs>({ defaultValues: resortSectorMap })
  const dispatch = useAppDispatch()
  const [resortMap, setResortMap] = React.useState<File>()

  const { addSuccessMessage } = useNotificationHook()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: CmsReservationAppResortMapFormInputs) => {
    dispatch(
      setCmsResort(
        await (!resortSectorMap
          ? createResortMapSector(resort.urls.map_sectors, resortMap, payload)
          : updateResortMapSector(resortSectorMap.urls.details, resortMap, payload)),
      ),
    )

    addSuccessMessage('Sukces', !resortSectorMap ? 'Sektor został dodany' : 'Sektor został zmieniony')

    toggleIsVisible()
  }, methods.setError)

  const upload = file => {
    setResortMap(file)
    methods.clearErrors()
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggleIsVisible}>{!resortSectorMap ? 'Dodaj sektor' : 'Edytuj sektor'}</ModalHeader>
        <ModalBody className="pb-0">
          <Row>
            <FormInput label="Nazwa sektora" name="display_name" />
            <FormInput label="Identyfikator sektora" name="name" />
            <FormPlain name="map_svg">
              <label className="d-block">Mapka SVG</label>
              {(resortMap || resortSectorMap?.map) && (
                <div className="mt-1">
                  <img
                    src={resortMap ? URL.createObjectURL(resortMap) : resortSectorMap?.map}
                    className="avatar-xl img-thumbnail"
                  />
                </div>
              )}
            </FormPlain>
            <Col md={12} className="mb-3">
              <FileUploader
                isLoading={isLoading}
                upload={upload}
                maxFiles={1}
                file={resortMap}
                accept={UploaderAcceptableFile.Images}
                label="Przeciągnij i upuść zdjęcie lub kliknij by dodać mapkę"
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="light" type="button" onClick={toggleIsVisible}>
            Zamknij
          </Button>

          <SaveButton className="btn btn-green" isSaving={isLoading} />
        </ModalFooter>
      </form>
    </FormProvider>
  )
}
