import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { apiQueryFunction } from '@api/base'
import { extractSelectOptionsValues } from '@helpers/utils'
import { PaginationResponse } from '@models/dashboard'
import { RootState } from '@store/index'
import { ImprovementOrder, ImprovementOrderStatus } from '@modules/housekeeping/models'

type ImprovementOrdersQueryParams = Partial<{ apartment: number; status: ImprovementOrderStatus }>

export const IMPROVEMENT_ORDER_TAG = 'improvementOrder'

export const improvementOrdersApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: '' }),
  reducerPath: 'improvementOrdersApi',
  tagTypes: [IMPROVEMENT_ORDER_TAG],
  endpoints: builder => ({
    getImprovementOrders: builder.query<ImprovementOrder[], ImprovementOrdersQueryParams>({
      queryFn: apiQueryFunction((state: RootState, data: ImprovementOrdersQueryParams) => ({
        params: { ...(extractSelectOptionsValues(data) || {}) },
        method: 'GET',
        url: state.appState.appData.urls.housekeeping.improvement_orders,
        responseTransformer: (response: PaginationResponse<ImprovementOrder>) => response.results,
      })),
      providesTags: (result: ImprovementOrder[]) =>
        result
          ? [...result.map(({ id }) => ({ type: IMPROVEMENT_ORDER_TAG, id }) as const), IMPROVEMENT_ORDER_TAG]
          : [IMPROVEMENT_ORDER_TAG],
    }),
  }),
})

export const { useGetImprovementOrdersQuery } = improvementOrdersApi
