import * as React from 'react'
import Table, { TableHeaderValue } from '@components/table/table'
import { Cafeteria } from '@models/cafeteria'
import { CafeteriaListTableRow } from '@modules/cafeteria/list/table-row'
import { CafeteriaListFilterParams } from '@modules/cafeteria/list/filter'
import { CafeteriaListTableFooter } from '@modules/cafeteria/list/table-footer'

const headerValues: TableHeaderValue[] = [
  { title: 'Nazwa' },
  { title: 'Operator' },
  { title: 'Status' },
  { title: 'Rodzaj' },
  { title: 'Kwota pobytu' },
  { title: 'Ilość kodów' },
]

interface Props {
  cafeterias: Cafeteria[]
  isLoading: boolean
  filters: CafeteriaListFilterParams
  setFilters: React.Dispatch<React.SetStateAction<CafeteriaListFilterParams>>
}

export const CafeteriaListTable: React.FC<Props> = ({ isLoading, cafeterias, filters, setFilters }) => (
  <Table
    className="table-hover w-100"
    headerValues={headerValues}
    isLoading={isLoading}
    filters={filters}
    setFilters={setFilters}
  >
    {cafeterias.map(cafeteria => (
      <CafeteriaListTableRow key={cafeteria.id} cafeteria={cafeteria} />
    ))}
    {!!cafeterias.length && <CafeteriaListTableFooter />}
  </Table>
)
