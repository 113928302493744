import * as React from 'react'
import { ReceptionBookingDetails, ReceptionReminder } from '@models/reception'
import { ReceptionCheckReminders } from '@modules/reception/common/reception-check-reminders'
import { useAppDispatch, useAppSelector } from '@store/index'
import { StepGuestsList } from '@modules/reception/checkin/step-guests/step-guests-list'
import { receptionBookingDetailsSelector } from '@store/selectors/reception'
import { setReceptionBookingDetails } from '@store/slices/reception-slice'

const reminders: ReceptionReminder[] = [
  {
    id: 'optional',
    type: 'info',
    children: (
      <>
        <strong>Możesz pominąć tę sekcję i przejść dalej.</strong> Informacje o Gościach będzie można uzupełnić podczas
        meldowania.
      </>
    ),
  },
  {
    id: 'quicker-checkin',
    type: 'success',
    children: (
      <>
        Jeśli podasz dane teraz to <strong>skrócisz czas meldowania na miejscu</strong> i klient będzie mógł szybciej
        cieszyć się urlopem.
      </>
    ),
  },
]

const GuestInfo = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const reservation = useAppSelector(receptionBookingDetailsSelector)
  const updateState = (reservation: ReceptionBookingDetails) => dispatch(setReceptionBookingDetails(reservation))

  return (
    <>
      <h5 className="text-secondary">Informacje o Gościach (opcjonalnie)</h5>
      <ReceptionCheckReminders reminders={reminders} required={false} />
      {reservation && <StepGuestsList updateState={updateState} guests={reservation.guests} booking={reservation} />}
    </>
  )
}

export default GuestInfo
