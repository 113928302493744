import * as React from 'react'
import * as Sentry from '@sentry/browser'

interface ErrorBoundaryState {
  isError: boolean
  errorInfo: string
  errorMessage: string
}

interface ErrorBoundaryProps {
  children: React.ReactNode
}

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props) {
    super(props)
    this.state = { isError: false, errorInfo: '', errorMessage: '' }
  }

  static getDerivedStateFromError() {
    return { isError: true }
  }

  public componentDidCatch(error, errorInfo) {
    this.setState({ errorInfo: errorInfo.componentStack, errorMessage: error.message })
    Sentry.captureException(error)
  }

  public render() {
    if (this.state.isError) {
      return (
        <div className="error-boundary" data-testid="error-boundary">
          <div className="error-boundary__eyes-wrapper">
            <div className="error-boundary__eye" />
            <div className="error-boundary__eye" />
          </div>
          <div className="error-boundary__text">
            Ups! Coś poszło nie tak. <br />
            Skontaktuj się z obsługą.
          </div>
          <div className="error-boundary__subtext">
            {this.state.errorMessage} <br />
            {this.state.errorInfo}
          </div>
        </div>
      )
    }

    return this.props.children
  }
}
