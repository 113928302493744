import * as React from 'react'
import { MediaReportItem } from '@models/reports'
import { formatPrice } from '@helpers/utils'
import * as R from 'ramda'

interface MediaReportsTableProps {
  mediaReportItems: MediaReportItem[]
}

export const MediaReportsTable: React.FC<MediaReportsTableProps> = ({ mediaReportItems }) => (
  <table className="table table-sm table-bordered table-striped">
    <thead>
      <tr>
        <td rowSpan={2}>Apartament</td>
        <td colSpan={5} className="text-center">
          Prąd
        </td>
        <td colSpan={5} className="text-center">
          Woda zimna
        </td>
        <td colSpan={4} className="text-center">
          Woda ciepła
        </td>
        <td colSpan={4} className="text-center">
          Ogrzewanie
        </td>
      </tr>
      <tr className="font-11">
        <td>Poczatek (kWh)</td>
        <td>Koniec (kWh)</td>
        <td>Zużycie (kWh)</td>
        <td>Koszt (zł)</td>
        <td>Tryb ryczałtowy</td>

        <td>Poczatek (m³)</td>
        <td>Koniec (m³)</td>
        <td>Zużycie (m³)</td>
        <td>Koszt (zł)</td>
        <td>Tryb ryczałtowy</td>

        <td>Poczatek (m³)</td>
        <td>Koniec (m³)</td>
        <td>Zużycie (m³)</td>
        <td>Koszt (zł)</td>

        <td>Poczatek (Gj)</td>
        <td>Koniec (Gj)</td>
        <td>Zużycie (Gj)</td>
        <td>Koszt (zł)</td>
      </tr>
    </thead>
    <tbody>
      {mediaReportItems.map(item => (
        <tr key={item.id}>
          <td>{item.apartment}</td>
          <td>{item.power_start}</td>
          <td>{item.power_end}</td>
          <td>{item.power_total}</td>
          <td>{formatPrice(item.power_costs)}</td>
          <td>{item.power_fake_meter ? 'tak' : 'nie'}</td>

          <td>{item.water_start}</td>
          <td>{item.water_end}</td>
          <td>{item.water_total}</td>
          <td>{formatPrice(item.water_costs)}</td>
          <td>{item.water_fake_meter ? 'tak' : 'nie'}</td>

          <td>{item.hot_water_start}</td>
          <td>{item.hot_water_end}</td>
          <td>{item.hot_water_total}</td>
          <td>{formatPrice(item.hot_water_costs)}</td>

          <td>{item.heat_start}</td>
          <td>{item.heat_end}</td>
          <td>{item.heat_total}</td>
          <td>{formatPrice(item.heat_costs)}</td>
        </tr>
      ))}
    </tbody>
    <tfoot>
      <tr>
        <td />
        <td />
        <td />
        <td />
        <td />
        <td>{formatPrice(R.sum(mediaReportItems.map(row => parseFloat(row.power_costs))))}</td>
        <td />
        <td />
        <td />
        <td />
        <td>{formatPrice(R.sum(mediaReportItems.map(row => parseFloat(row.water_costs))))}</td>
        <td />
        <td />
        <td />
        <td>{formatPrice(R.sum(mediaReportItems.map(row => parseFloat(row.hot_water_costs))))}</td>
        <td />
        <td />
        <td />
        <td>{formatPrice(R.sum(mediaReportItems.map(row => parseFloat(row.heat_costs))))}</td>
      </tr>
    </tfoot>
  </table>
)
