import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { ContentLoader } from '@components/content-loader'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { commonObjectGet } from '@store/actions/generic-actions'
import { useApiRequest } from '@components/hooks/use-api-request'
import { useBandReadersList } from '@modules/reception/common/band-reader-select/use-band-readers-list'
import { Col, Row } from 'reactstrap'
import { BandReaderSelect } from '@modules/reception/common/band-reader-select'
import { ReceptionBookingCheckInStepAccessBandsList } from '@modules/reception/checkin/step-access/reception-booking-check-in-step-access-bands-list'
import { ReceptionBookingCheckInStepAccessBandsNotConfigured } from '@modules/reception/checkin/step-access/reception-booking-check-in-step-access-bands-not-configured'
import { BandScanner } from '@components/band-scanning/band-scanner'

interface ReceptionBookingCheckInStepAccessBandsProps {
  booking: ReceptionBookingDetails
  updateState: (booking: ReceptionBookingDetails) => void
}

export const ReceptionBookingCheckInStepAccessBands: React.FC<ReceptionBookingCheckInStepAccessBandsProps> = ({
  updateState,
  booking,
}) => {
  const bandReaders = useBandReadersList()

  const { action, isLoading } = useApiRequest(async () => {
    updateState(await commonObjectGet<ReceptionBookingDetails>(booking.urls.check_in_step_bands))
  })

  React.useEffect(() => {
    action()
  }, [])

  const user = useAuthenticatedUser()

  const currentReader = React.useMemo(
    () => bandReaders.find(bandReader => bandReader.id === user.bandReaderId),
    [bandReaders, user.bandReaderId],
  )

  if (!currentReader) return <ReceptionBookingCheckInStepAccessBandsNotConfigured />

  return (
    <ContentLoader isLoading={isLoading}>
      {currentReader.new_scan_flow ? (
        <BandScanner booking={booking} />
      ) : (
        <Row className="mb-2">
          <Col
            md={5}
            className="reception__booking-check-in__access-step__key-icon d-flex justify-content-center align-items-center"
          >
            <i className="icon-opaska text-success" />
          </Col>
          <Col md={7}>
            <strong className="font-18 mt-3 d-block text-dark">Przyłóż do czytnika i wydaj opaski:</strong>
            <span className="d-block mt-2 font-12 text-semi-strong pb-1">
              Opaski dla Gości do lokalu {booking.apartment.name}
            </span>
            <BandReaderSelect className="pb-1" />
            <ReceptionBookingCheckInStepAccessBandsList bandReaderId={user.bandReaderId || 0} booking={booking} />
          </Col>
        </Row>
      )}
    </ContentLoader>
  )
}
