import * as React from 'react'
import { useAppData } from '@components/hooks/use-app-data'
import AsyncSelect from 'react-select/async'
import { commonObjectGet } from '@store/actions/generic-actions'
import { OptionProps, SelectComponentsConfig } from 'react-select'
import { StylesConfig } from 'react-select/dist/declarations/src/styles'
import Spinner from '@hyper/spinner'
import { NavigationPath } from '@models/routes'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { SearchbarDetails } from '@components/topbar/searchbar-details'

export interface SearchResult {
  id: number
  name: string
  model: 'subscription' | 'gastrocard' | 'package' | 'packagewholesale' | 'packagevip'
  model_name: string
  url: string
  number: string
  allow_open_details_modal: boolean
}

const buildUrl = (data: SearchResult) => NavigationPath.Home + `?id=${data.id}&model=${data.model}`

export const Searchbar = (): JSX.Element => {
  const { urls } = useAppData()
  const [isLoading, setIsLoading] = React.useState(false)
  const [isSubmit, setIsSubmit] = React.useState(false)
  const [value, setValue] = React.useState('')
  const [results, setResults] = React.useState<SearchResult[]>([])
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const searchId = searchParams.get('id')
  const searchModel = searchParams.get('model')

  const onChange = (data: SearchResult) => {
    if (!data.allow_open_details_modal) {
      document.location.href = data.url
    } else {
      navigate(buildUrl(data))
    }
  }

  const onSuggestionsFetchRequested = async (query: string): Promise<SearchResult[]> => {
    const newQuery = query.replace(/^#+/, '')
    setValue(newQuery)
    setResults([])
    setIsLoading(true)
    const response = await searchQuery(newQuery, urls.dashboard.global_search)
    setIsLoading(false)
    setResults(response)
    return response
  }

  const onSubmitSearchHandler = async event => {
    event.preventDefault()

    if (results.length === 1) {
      if (results[0].allow_open_details_modal) {
        navigate(buildUrl(results[0]))
      } else {
        document.location.href = results[0].url
      }
    } else {
      onSubmitNoResult(event)
    }
  }

  const onSubmitNoResult = async event => {
    setIsSubmit(true)
    event.preventDefault()

    const data = await commonObjectGet<{ redirect_url: string }>(urls.dashboard.quick_search_url, {
      query: value.replace(/^#+/, ''),
    })

    document.location.href = data.redirect_url
  }
  const NoOptionsMessage = () => (
    <div className="text-center p-2 cursor-pointer" onClick={onSubmitSearchHandler}>
      Brak wyników, <strong>szukaj wszędzie</strong>
    </div>
  )

  return (
    <div className="app-search dropdown">
      {searchId && searchModel && <SearchbarDetails model={searchModel} id={searchId} />}
      <form method="get" role="search" onSubmit={onSubmitSearchHandler}>
        <div className="input-group">
          <AsyncSelect
            cacheOptions={false}
            styles={selectStyles}
            name="query"
            closeMenuOnSelect={false}
            loadOptions={onSuggestionsFetchRequested}
            placeholder="Wpisz ..."
            components={{ ...SearchBarSelectComponents, NoOptionsMessage }}
            onChange={onChange}
            value={value}
          />
          <span className="mdi mdi-magnify search-icon" />
          <div className="input-group-append">
            <button className="btn btn-secondary" type="submit" disabled={isSubmit || isLoading}>
              {isSubmit ? (
                <>
                  <Spinner size="xs" color="white" className="inline-spinner mr-1" /> Wyszukuje
                </>
              ) : (
                'Szukaj'
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export const SearchBarSelectComponents: SelectComponentsConfig<any, false, any> = {
  Option: (props: OptionProps<SearchResult, false, any>) => (
    <div className="app-search__option" ref={props.innerRef} {...props.innerProps}>
      <a href={props.data.allow_open_details_modal ? `/dashboard/${buildUrl(props.data)}` : props.data.url}>
        <p className="font-weight-bold mb-1">{props.data.model_name}</p>
        {props.data.number} {props.data.name}
      </a>
    </div>
  ),
  SingleValue: () => null,

  LoadingMessage: () => (
    <div className="text-center p-2">
      <Spinner size="xs" color="secondary" className="inline-spinner mr-1" /> Szukam!
    </div>
  ),
}

const minimalValueLength = 3
const searchQuery = async (query, url): Promise<SearchResult[]> => {
  if (!query || query.length < minimalValueLength) {
    return []
  }

  try {
    return await commonObjectGet<SearchResult[]>(url, { query })
  } catch (e) {
    return []
  }
}

const selectStyles: StylesConfig = {
  menu: provided => ({ ...provided, zIndex: 99999 }),
  menuList: provided => ({ ...provided, paddingBlock: 0 }),
  valueContainer: provided => ({
    ...provided,
    display: 'flex',
    '&:hover': { cursor: 'text' },
  }),
  control: provided => ({
    ...provided,
    width: 190,
    background: '#f1f3fa',
    border: 0,
    boxShadow: 'none',
    '&:hover': { borderColor: '#cccccc', boxShadow: 'none', cursor: 'text' },
    '&:focus': { borderColor: 'none', boxShadow: 'none' },
  }),
  placeholder: provided => ({
    ...provided,
    position: 'absolute',
    paddingLeft: 30,
    fontSize: '14px',
  }),
  input: provided => ({ ...provided, paddingLeft: 30 }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorsContainer: () => ({ display: 'none' }),
}
