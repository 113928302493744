import * as React from 'react'
import { RecommendationGift } from '@models/crm'
import Table from '@components/table/table'
import { RecommendationGiftsTableRow } from '@modules/crm/recommendation-gifts/recommendation-gifts-table-row'
import { RecommendationGiftsFilters } from '@modules/crm/recommendation-gifts/recommendation-gifts-view'
import { useSelectableRows } from '@components/table/use-selectable-rows'
import { Row } from 'reactstrap'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { RecommendationGiftsTableActions } from '@modules/crm/recommendation-gifts/recommendation-gifts-table-actions'

interface Props {
  isLoading: boolean
  recommendationGifts: RecommendationGift[]
  filters: RecommendationGiftsFilters
  setFilters: (filters: RecommendationGiftsFilters) => void
}

const headerValues = [
  {
    sortField: 'number',
    title: 'Numer',
  },
  {
    sortField: 'recommending_person_name',
    title: 'Imię i nazwisko polecającej',
  },
  {
    sortField: 'recommending_related_number',
    title: 'Numer zakupu (polecającego)',
  },
  {
    sortField: 'recommended_person_name',
    title: 'Imię i nazwisko poleconej',
  },
  {
    sortField: 'recommended_related_number',
    title: 'Numer zakupu (poleconego)',
  },
  {
    sortField: 'account_number',
    title: 'Numer konta',
  },
  {
    sortField: 'transfer_by_day',
    title: 'Termin przelewu do dnia',
  },
  {
    title: 'Wartość prezentu',
    sortField: 'transfer_amount',
  },
  {
    sortField: 'transfer_status',
    title: 'Status przelewu',
  },
  {
    title: '',
  },
]

export const RecommendationGiftsTable: React.FC<Props> = ({ isLoading, recommendationGifts, filters, setFilters }) => {
  const { selectableHeader, selectableCell, selectedRows, clearSelectedRows } = useSelectableRows<RecommendationGift>(
    recommendationGifts,
    recommendationGifts.filter(row => row.transfer_status === 'transfer_sent').map(row => row.id),
  )
  const handleSearchChange = (field, value) => {
    setFilters({ ...filters, search: value })
  }

  return (
    <div data-testid="qa-recommendation-gift-table">
      <Row className="mx-0">
        <RecommendationGiftsTableActions selectedRows={selectedRows} clearSelectedRows={clearSelectedRows} />
        <SearchFilter setValue={handleSearchChange} />
      </Row>
      <Table
        className="table-hover w-100"
        headerValues={headerValues}
        isLoading={isLoading}
        selectableHeader={selectableHeader}
        filters={filters}
        setFilters={setFilters}
      >
        {recommendationGifts.map(recommendationGift => (
          <RecommendationGiftsTableRow
            selectableCell={selectableCell}
            recommendationGift={recommendationGift}
            key={recommendationGift.id}
          />
        ))}
      </Table>
    </div>
  )
}
