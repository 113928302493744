import * as React from 'react'
import { CleaningOrder } from '@modules/housekeeping/models'
import { toDefaultDateFormat } from '@helpers/date-helper'

interface Props {
  cleaningOrder: CleaningOrder
}

export const HousekeepingCleaningOrderNextBookingRow = React.memo(({ cleaningOrder }: Props) => (
  <td className="housekeeping__orders_table__column" width={170}>
    {cleaningOrder.next_booking ? (
      <a href={cleaningOrder.next_booking.urls.details} target="_blank" className="text-body">
        {toDefaultDateFormat(cleaningOrder.next_booking.date_from)}
        <br />
        <small className="font-11">({cleaningOrder.next_booking.reservation_number})</small>
      </a>
    ) : (
      'brak'
    )}
  </td>
))
