import * as React from 'react'
import { FormSelect } from '@hyper/forms/form-select'
import { Row } from 'reactstrap'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { FormProvider, useForm } from 'react-hook-form'
import { SubmitFilter } from '@modules/reception/common/filters/submit-filter'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { ClientDetailsModalSubscriptionsFilters } from '@modules/crm/clients/details-modal/subscriptions/subscriptions'
import { ColAuto } from '@hyper/col-auto'
import { useModal } from '@components/modals/use-modal'
import { ClientDetails } from '@models/clients'

interface Props {
  filters: ClientDetailsModalSubscriptionsFilters
  defaultFilters: ClientDetailsModalSubscriptionsFilters
  onFiltersChange: (filters: ClientDetailsModalSubscriptionsFilters) => void
  subscriptionStatusOptions: CustomReactSelectOption[]
  client: ClientDetails
}

export const ClientDetailsModalSubscriptionsTableFilters = ({
  filters,
  defaultFilters,
  onFiltersChange,
  subscriptionStatusOptions,
  client,
}: Props): JSX.Element => {
  const [showAddSubscriptionModal] = useModal('SubscriptionCreateModal', { client })

  const methods = useForm<ClientDetailsModalSubscriptionsFilters>({
    defaultValues: filters,
  })

  const handleReset = () => {
    methods.reset()
    onFiltersChange(defaultFilters)
  }

  useHandleTableFiltersChange(methods.watch, filters, onFiltersChange)

  return (
    <FormProvider {...methods}>
      <Row>
        <FormSelect
          options={subscriptionStatusOptions}
          name="statuses"
          label="Status"
          selectProps={{ isSelectMulti: true, isClearable: true }}
          formPlainProps={{ colSize: 5 }}
        />

        <SubmitFilter handleReset={handleReset} />
        <ColAuto className="ml-auto col-auto align-self-end mb-2">
          <button type="button" className="btn btn btn-outline-secondary" onClick={showAddSubscriptionModal}>
            <i className="uil-plus mr-1" />
            Dodaj nowy pakiet
          </button>
        </ColAuto>
      </Row>
    </FormProvider>
  )
}
