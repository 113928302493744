import * as React from 'react'
import { useModal } from '@components/modals/use-modal'
import { ButtonWithIcon } from '@components/button-with-icon'
import { RentalProductFiltersParams } from '@modules/rental/models'

interface Props {
  filters: RentalProductFiltersParams
}

export const RentalProductTableActions = ({ filters }: Props): JSX.Element => {
  const [showProductModal] = useModal('RentalProductCreateModal')
  const [showProductsImportModal] = useModal('RentalProductsImportModal', { filters })

  return (
    <div className="ml-auto mr-2">
      <ButtonWithIcon
        icon="uil-file-import font-15 lh-initial"
        text="Importuj produkty"
        handleClick={showProductsImportModal}
        color="green-dark"
        btnClass="mr-1"
      />
      <ButtonWithIcon icon="uil-plus" text="Dodaj produkt" handleClick={showProductModal} color="green-dark" />
    </div>
  )
}
