import * as React from 'react'
import { useAppDispatch, useAppSelector } from '@store/index'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { AccommodationType } from '@models/booking'
import { CmsReservationAppAccommodationTypesRow } from '@modules/cms/reservation-app/accommodation-types-row'
import { useApiRequest } from '@components/hooks/use-api-request'
import { cmsAccommodationTypesSelector } from '@store/slices/cms-reservation-app-slice'
import { getCmsAccommodationTypes } from '@store/actions/cms-reservation-app-actions'

const headerValues: TableHeaderValue<AccommodationType>[] = [
  {
    title: 'Nazwa',
  },
  {
    title: 'Rozmiar',
  },

  {
    title: 'Atrybuty',
  },

  { title: '' },
]

export const CmsReservationAppAccommodationTypes: React.FC = () => {
  const [filters, setFilters] = React.useState<TableFilters>({
    ordering: '',
    page: 0,
    page_size: 0,
    search: '',
  })
  const accommodationTypes = useAppSelector(cmsAccommodationTypesSelector)
  const dispatch = useAppDispatch()

  const { isLoading, action: fetch } = useApiRequest(async () => await dispatch(getCmsAccommodationTypes()))

  React.useEffect(() => {
    fetch()
  }, [])

  return (
    <Table
      showPagination={false}
      filters={filters}
      setFilters={setFilters}
      headerValues={headerValues}
      isLoading={isLoading}
    >
      {accommodationTypes.map(row => (
        <CmsReservationAppAccommodationTypesRow key={row.id} accommodationType={row} />
      ))}
    </Table>
  )
}
