import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import { FormProvider, useForm } from 'react-hook-form'
import { useApiRequest, useFormRequest } from '@components/hooks/use-api-request'
import { SaveButton } from '@hyper/button'
import { FormInput } from '@hyper/forms'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useAppDispatch } from '@store/index'
import { useAppData } from '@components/hooks/use-app-data'
import {
  createRentalProductCategory,
  removeRentalProductCategory,
  updateRentalProductCategory,
} from '@store/actions/rental-warehouse-actions'
import { RentalProductCategory } from '@modules/rental/models'

interface Props extends BaseModalProps {
  productCategory?: RentalProductCategory
}

export interface RentalProductCategoryFormInputs {
  name: string
  resort: CustomReactSelectOption | null
}

export const RentalProductCategoryCreateModal: React.FC<Props> = ({ toggleIsVisible, productCategory }) => {
  const dispatch = useAppDispatch()

  const { urls } = useAppData()

  const methods = useForm<RentalProductCategoryFormInputs>({
    mode: 'onChange',
    defaultValues: {
      name: productCategory?.name ?? '',
    },
  })

  const { action: handleSubmit, isLoading } = useFormRequest(async (payload: RentalProductCategoryFormInputs) => {
    await dispatch(
      productCategory
        ? updateRentalProductCategory([productCategory.urls.details, payload])
        : createRentalProductCategory([urls.rental.product_categories, payload]),
    )
    toggleIsVisible()
  }, methods.setError)

  const { action: handleDelete, isLoading: isDeleting } = useApiRequest(async () => {
    if (!productCategory) return
    await dispatch(removeRentalProductCategory(productCategory))
    toggleIsVisible()
  })

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <ModalHeader toggle={toggleIsVisible}>{productCategory ? 'Edytuj kategorię' : 'Dodaj kategorię'}</ModalHeader>
        <ModalBody className="pb-0">
          <Row>
            <FormInput name="name" label="Nazwa" />
          </Row>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          {productCategory && (
            <SaveButton isSaving={isDeleting} className="btn btn-danger mr-1" label="Usuń" onClick={handleDelete} />
          )}
          <div className="ml-auto">
            <Button className="btn btn-light mr-2" type="button" onClick={toggleIsVisible}>
              Anuluj
            </Button>
            <SaveButton label="Zapisz" className="btn-green btn" isSaving={isLoading} />
          </div>
        </ModalFooter>
      </form>
    </FormProvider>
  )
}
