import * as React from 'react'
import { BookingCar, ReceptionBookingDetails, ReceptionBookingGroup } from '@models/reception'
import { StepGuestsCarsListAddCarButton } from '@modules/reception/checkin/step-guests/cars/step-guests-cars-list-add-car-button'
import { StepGuestsCarsListRow } from '@modules/reception/checkin/step-guests/cars/step-guests-cars-list-row'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { StepGuestsCarsAddParking } from '@modules/reception/checkin/step-guests/cars/step-guests-cars-add-parking'
import classnames from 'classnames'
import { ParkingKind } from '@models/booking'
import { setReceptionBookingDetails } from '@store/slices/reception-slice'

interface StepGuestsCarsListProps {
  booking: ReceptionBookingDetails
  maxCars: number
  group: ReceptionBookingGroup
  kind: ParkingKind
}

export const StepGuestsCarsList: React.FC<StepGuestsCarsListProps> = ({ booking, maxCars, group, kind }) => {
  const dispatch = useAppDispatch()
  const resortDetails = useAppSelector((state: RootState) => state.receptionState.resortDetails)
  const amountOfCarsAddedForKind = booking.cars.filter((car: BookingCar) => car.kind === kind).length

  const parkingImprovementLimit = resortDetails?.improvements.find(row => row.code === kind)?.limit || 0

  const cars = booking.cars.filter(car => car.group_id === group.id && car.kind === kind)
  const isEditMode = cars.some(row => row.editMode)

  const canAddParking =
    !isEditMode && kind === 'parking2' && maxCars < parkingImprovementLimit && amountOfCarsAddedForKind - maxCars === 0

  const canAddNextParking = !isEditMode && cars.length < maxCars

  const handleAddCar = async (event?: React.SyntheticEvent) => {
    event?.preventDefault()
    if (maxCars > cars.length) {
      await dispatch(
        setReceptionBookingDetails({
          ...booking,
          cars: [
            ...booking.cars,
            {
              id: amountOfCarsAddedForKind,
              guest_id: null,
              group_id: group.id,
              register_number: '',
              urls: { details: '' },
              editMode: true,
              kind,
            },
          ],
        }),
      )
    }
  }

  return (
    <div className="d-flex justify-content-between flex-wrap">
      {!cars.length ? (
        <div
          className={classnames('d-inline-flex align-items-center', { 'w-100': amountOfCarsAddedForKind < maxCars })}
        >
          <strong>Brak dodanego auta</strong>
          <StepGuestsCarsListAddCarButton
            maxCars={maxCars}
            handleAddCar={handleAddCar}
            carsAmount={amountOfCarsAddedForKind}
          />
        </div>
      ) : (
        <>
          {cars.map((car, index) => (
            <StepGuestsCarsListRow booking={booking} key={car.id} index={index + 1} car={car} group={group} />
          ))}
          {canAddNextParking && (
            <div className="d-flex pt-1 ml-auto">
              <StepGuestsCarsListAddCarButton
                handleAddCar={handleAddCar}
                maxCars={maxCars}
                carsAmount={amountOfCarsAddedForKind}
              />
            </div>
          )}
        </>
      )}
      {canAddParking && (
        <div className="text-right ml-auto">
          <StepGuestsCarsAddParking booking={booking} kind={kind} />
        </div>
      )}
    </div>
  )
}
