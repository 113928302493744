import { RecommendationGiftsFilters } from '@modules/crm/recommendation-gifts/recommendation-gifts-view'
import { AppDispatch, RootState } from '@store/index'
import { commonObjectGet } from '@store/actions/generic-actions'
import { PaginationResponse } from '@models/dashboard'
import { RecommendationGift } from '@models/crm'
import { setDashboardStandardPaginator } from '@store/actions/dashboard-actions'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { extractSelectOptionsValues } from '@helpers/utils'

export const getRecommendationGifts = createAsyncThunk<
  RecommendationGift[],
  Partial<RecommendationGiftsFilters>,
  { dispatch: AppDispatch; state: RootState }
>('recommendationGift/getRecommendationGifts', async (filters, { dispatch, getState }) => {
  const url = getState().appState.appData.urls.crm.recommendation_gifts

  const data = await commonObjectGet<PaginationResponse<RecommendationGift>>(url, extractSelectOptionsValues(filters))

  dispatch(setDashboardStandardPaginator(data))
  return data.results
})
