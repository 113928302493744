import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { ReceptionBookingCheckOutStepThanksBoxReceiptPrint } from '@modules/reception/checkout/step-thanks/receipt-print'
import { DocumentsLanguageSelect } from '@modules/reception/common/documents-language-select'
import { Col, Row } from 'reactstrap'
import { ReceptionBookingCheckInStepDocumentsPrints } from '@modules/reception/checkin/step-documents/step-documents-print'

interface Props {
  booking: ReceptionBookingDetails
}

export const ReceptionBookingDetailsDocuments = ({ booking }: Props): JSX.Element | null => {
  if (booking.status !== 'close') {
    return (
      <Row>
        <Col md={6}>
          <ReceptionBookingCheckInStepDocumentsPrints booking={booking} />
        </Col>
      </Row>
    )
  }

  return (
    <>
      <div className="col-md-4 px-0">
        <DocumentsLanguageSelect label="Rachunek w języku:" readOnly />
      </div>
      <div className="d-flex">
        <ReceptionBookingCheckOutStepThanksBoxReceiptPrint booking={booking} />
      </div>
    </>
  )
}
