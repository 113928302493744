import * as React from 'react'
import { useForm } from 'react-hook-form'
import { Button, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useFormRequest } from '@components/hooks/use-api-request'
import { TextBlock } from '@modules/cms/models'
import { FormInput } from '@hyper/forms'
import { commonObjectPut } from '@store/actions/generic-actions'
import { BaseModalProps } from '@components/modals/types'
import { useAppDispatch } from '@store/index'
import { setCmsTextBlock } from '@store/slices/cms-reservation-app-slice'
import { Form } from '@hyper/forms/form'

interface Props extends BaseModalProps {
  textBlock: TextBlock
}

export interface FormInputs {
  content: number
  subtitle: string
  name: string
}

export const CmsReservationAppTextBlockModal: React.FC<Props> = ({ toggleIsVisible, textBlock }) => {
  const methods = useForm<FormInputs>({
    defaultValues: textBlock,
  })
  const dispatch = useAppDispatch()
  const { addSuccessMessage } = useNotificationHook()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    await dispatch(setCmsTextBlock(await commonObjectPut<TextBlock>(textBlock.urls.details, payload)))

    addSuccessMessage('Sukces', 'Wpis został zmieniony')
    toggleIsVisible()
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>
        Edytuj wpis <span className="text-semi-strong">{textBlock.name}</span>
      </ModalHeader>
      <ModalBody className="pb-0">
        <Row>
          <FormInput label="Nazwa" name="name" />
          <FormInput label="Pod tytuł" name="subtitle" />
          <FormInput label="Treść" name="content" type="textarea" />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>

        <SaveButton className="btn btn-green" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
