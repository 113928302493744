import * as React from 'react'
import { FormPlain } from '@hyper/forms'
import { Input, InputGroup, InputGroupAddon, InputGroupText, InputProps, Label, LabelProps } from 'reactstrap'
import classNames from 'classnames'
import { extractInnerRef } from '@helpers/forms'
import { useFormContext } from 'react-hook-form'

interface FormInputNumber {
  name: string
  label?: string
  getValue?: (name: string) => string | number
  colSize?: number
  minValue?: number
  maxValue?: number
  inputProps?: InputProps
  labelProps?: LabelProps
  colStyle?: React.CSSProperties
  colClassName?: string
  formGroupClassName?: string
  onChange?: (value: number) => void
  disabled?: boolean
}

export const FormInputNumber: React.FC<FormInputNumber> = ({
  disabled,
  colSize,
  label,
  name,
  minValue,
  maxValue,
  inputProps,
  labelProps,
  colStyle,
  colClassName,
  formGroupClassName,
  onChange,
}) => {
  const {
    setValue,
    getValues: getValue,
    register,
    formState: { errors },
  } = useFormContext()

  const set = (value: number) => {
    if (disabled) {
      return
    }

    const formValue = getValue ? getValue(name) || 0 : 0

    let newValue = typeof formValue === 'string' ? parseInt(formValue, 10) + value : formValue + value

    if (typeof minValue !== 'undefined') {
      newValue = Math.max(newValue, minValue)
    }
    if (maxValue) {
      newValue = Math.min(newValue, maxValue)
    }
    if (setValue) {
      setValue(name, newValue)
    }

    if (onChange) {
      onChange(newValue)
    }
  }
  const handlePlus = () => set(+1)
  const handleMinus = () => set(-1)

  return (
    <FormPlain
      colSize={colSize || 12}
      name={name}
      errors={errors}
      colStyle={colStyle}
      colClassName={colClassName}
      formGroupClassName={formGroupClassName}
    >
      <Label {...labelProps}>{label || ''}</Label>
      <InputGroup>
        <InputGroupAddon addonType="prepend">
          <InputGroupText
            className={classNames('bg-primary text-strong text-white', {
              'opacity-5': disabled,
              'cursor-pointer': !disabled,
            })}
            onClick={handleMinus}
            role={`${name}-minus`}
          >
            <i className="uil-minus" />
          </InputGroupText>
        </InputGroupAddon>

        <Input
          {...extractInnerRef(
            register(name, {
              valueAsNumber: true,
            }),
          )}
          disabled={disabled}
          type="number"
          min={minValue}
          max={maxValue}
          className={classNames('form__input__number d-flex align-items-center justify-content-center text-center', {
            'is-invalid': !!errors[name],
          })}
          {...inputProps}
        />

        <InputGroupAddon addonType="append">
          <InputGroupText
            className={classNames('bg-primary text-strong text-white', {
              'opacity-5': disabled,
              'cursor-pointer': !disabled,
            })}
            onClick={handlePlus}
            role={`${name}-plus`}
          >
            <i className="uil-plus" />
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
    </FormPlain>
  )
}
