import * as React from 'react'
import {
  HousekeepingGropedOrder,
  HousekeepingOrderGroupedTeamCell,
} from '@modules/housekeeping/cleaning-orders/common/grouped-table/grouped-team-cell'
import { CleaningOrder } from '@modules/housekeeping/models'
import * as R from 'ramda'

interface Props {
  cleaningOrders: CleaningOrder[]
  rowRenderer: (cleaningOrder: CleaningOrder) => React.ReactNode
  colSpan?: number
}

export const HousekeepingOrderGroupedRow = ({ cleaningOrders, rowRenderer, colSpan = 6 }: Props): React.ReactNode => {
  const groupedOrders = React.useMemo(
    () =>
      cleaningOrders.reduce((acc: HousekeepingGropedOrder[], order: CleaningOrder) => {
        const usersGroup = acc.find(el => R.equals(el.users, order.users))
        if (usersGroup) {
          return acc.map(el => (R.equals(el.users, order.users) ? { ...el, orders: [...el.orders, order] } : el))
        }

        return [...acc, { users: order.users, orders: [order] }]
      }, []),
    [cleaningOrders],
  )

  return groupedOrders.map((groupedOrder: HousekeepingGropedOrder) => (
    <tr className="bg-inherit" key={groupedOrder.users.join('-')}>
      <HousekeepingOrderGroupedTeamCell groupedOrder={groupedOrder} />
      <td colSpan={colSpan} className="p-0 border-bottom">
        <table className="table table-borderless w-100 mb-0">
          <tbody>{groupedOrder.orders.map((cleaningOrder: CleaningOrder) => rowRenderer(cleaningOrder))}</tbody>
        </table>
      </td>
    </tr>
  ))
}
